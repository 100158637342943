import { Component, OnInit, OnDestroy } from '@angular/core';

import { ConfigService } from '../../shared/services/config.service';
import { CrudService } from '../../shared/services/crud.service';
import { Message } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
  selector: 'app-ficha-estoque-detalhe',
  templateUrl: './ficha-estoque-detalhe.component.html',
  styleUrls: [
    './ficha-estoque-detalhe.component.scss'
  ],
  providers: [CrudService]
})
export class FichaEstoqueDetalheComponent implements OnInit, OnDestroy {
  msgs: Message[] = [];
  msgsModal: Message[] = [];

  title: string;
  subtitle: string;

  lista: Array<any>;

  data: any;
  doc: any;
  operacao: any;
  vu: any;
  qtdEntrada: any;
  vlrEntrada: any;
  qtdSaida: any;
  vlrSaida: any;
  qtdTotal: any;
  vlrTotal: any;
  cstMedioDetail: any;

  //export excel
  colslista: any[]

  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService
  ) {}

  ngOnInit() {
    Promise.resolve(null).then(() => (this.toolbarService.hidden = true));

    this.title = 'ficha de estoque';
    this.subtitle = 'item: AI9996004350 descrição: SISTEMA DE APLICACAO DE VAPOR. medida: UN';

    this.lista = [
      {
        data: '02/09/2018',
        doc: 'I0001',
        operacao: 'Inventario Inicial',
        vu: '4053,75',
        qtdEntrada: '5',
        vlrEntrada: '20268,75',
        qtdSaida: '',
        vlrSaida: '-',
        qtdTotal: '5',
        vlrTotal: '20268,75',
        cstMedioDetail: '4053,75'
      },
      {
        data: '21/09/2018',
        doc: 'C0001',
        operacao: 'Compra F-326',
        vu: '4053,75',
        qtdEntrada: '5',
        vlrEntrada: '20268,75',
        qtdSaida: '',
        vlrSaida: '-',
        qtdTotal: '10',
        vlrTotal: '40537,47',
        cstMedioDetail: '4053,75'
      }
    ];

    this.colslista = [
      { field: 'data', header: 'DATA' },
      { field: 'doc', header: 'DOC' },
      { field: 'operacao', header: 'OPERAÇÃO' },
      { field: 'vu', header: 'V.U.' },
      { field: 'qtdEntrada', header: 'QTDE. ENT.' },
      { field: 'vlrEntrada', header: 'VL. ENT.' },
      { field: 'qtdSaida', header: 'QTDE. SAÍDA' },
      { field: 'vlrSaida', header: 'VL. SAÍDA' },
      { field: 'qtdTotal', header: 'QTDE. TOTAL' },
      { field: 'vlrTotal', header: 'VL. TOTAL' },
      { field: 'cstMedioDetail', header: 'CTO. MÉDIO' }
    ];
  }

  ngOnDestroy() {
    this.toolbarService.clear();
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() {


  }
}
