import { isNullOrUndefined } from "util";

export class Campanha{

  id                : number;
  nome              : string;


  constructor(campanha?: Campanha | number) {
    if (!isNullOrUndefined(campanha)) {
      if (typeof campanha === 'number') {
        this.id = campanha;
      } else {
        this.id         = campanha.id;
        this.nome       = campanha.nome;

      }
    }
  }

}