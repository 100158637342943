import { AuthUser } from 'app/proof/model/auth-user.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { ComissaoBase } from 'app/financeiro/model/comissao-base.model';


export class ComissaoBaseEmpresa {
  id: number;
  comissaoBase: ComissaoBase;
  empresa: Empresa;
  codigo: string;
  descricao: string;
  valueFormula: string;
  paymentFormula: string;
  dtInicial: Date;
  dtFinal: Date;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;

  constructor(comissaoBaseEmpresa?: ComissaoBaseEmpresa | number) {
    if (comissaoBaseEmpresa !== undefined && comissaoBaseEmpresa !== null) {
      if (typeof comissaoBaseEmpresa === 'number') {
        this.id = comissaoBaseEmpresa;
      } else {
        this.id = comissaoBaseEmpresa.id;
        this.comissaoBase = comissaoBaseEmpresa.comissaoBase;
        this.empresa = comissaoBaseEmpresa.empresa;
        this.codigo = comissaoBaseEmpresa.codigo;
        this.descricao = comissaoBaseEmpresa.descricao;
        this.valueFormula = comissaoBaseEmpresa.valueFormula;
        this.paymentFormula = comissaoBaseEmpresa.paymentFormula;
        this.dtInicial = comissaoBaseEmpresa.dtInicial;
        this.dtFinal = comissaoBaseEmpresa.dtFinal;
        this.createTimestamp = comissaoBaseEmpresa.createTimestamp;
        this.changeUser = comissaoBaseEmpresa.changeUser;
        this.changeTimestamp = comissaoBaseEmpresa.changeTimestamp;
      }
    }
  }
}
