import { Empresa } from 'app/cadastro/model/empresa.model';
import { PedidoTipo } from 'app/financeiro/model/pedido-tipo.model';
import { Participante } from 'app/cadastro/model/participante.model';
import { PedidoStatus } from 'app/financeiro/model/pedido-status.model';
import { PedidoItem } from 'app/financeiro/model/pedido-item.model';
import { PedidoParcela } from 'app/financeiro/model/pedido-parcela.model';
import { PedidoAprovacao } from 'app/financeiro/model/pedido-aprovacao.model';
import { FormaPagamento } from 'app/util/model/forma-pagamento.model';
import { ModNf } from 'app/util/model/mod-nf.model';
import { OperacaoRegra } from 'app/cadastro/model/operacao-regra.model';
import { Nfe } from 'app/financeiro/model/nfe.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { TipoIncoterm } from '../../util/model/tipo-incoterm.model';
import { EmpresaConta } from '../../cadastro/model/empresa-conta.model';
import { Moeda } from '../../util/model/moeda.model';
import { NfeFinalidade } from './nfe-finalidade.model';
import { NfePresencial } from './nfe-presencial.model';
import { TipoFrete } from '../../util/model/tipo-frete-model';
import { Uf } from '../../util/model/uf.model';
import { PedidoNfeRelac } from './pedido-nfe-relac.model';
import { Item } from 'app/cadastro/model/item.model';

export class Pedido {
  id: number;
  empresa: Empresa;
  codigo: string;
  codigoControle: string;
  metodoEnvio: string;
  codigoOrcamento: string;
  tipoIncoterm: TipoIncoterm;
  dadosAdicionais: string;
  enderecoEntrega: string;
  enderecoCobranca: string;
  pedidoTipo: PedidoTipo;
  participante: Participante;
  dtEntrega: Date;
  pedidoStatus: PedidoStatus;
  pedidoNfeRelac: PedidoNfeRelac;
  formaPagamento: FormaPagamento;
  operacaoRegra: OperacaoRegra;
  dtFaturamentoAutomatico: Date;
  faturaAutomatico: boolean;
  contrato: boolean;
  dtFinalContrato: Date;
  empresaConta: EmpresaConta;
  nfeFinalidade: NfeFinalidade;
  nfePresencial: NfePresencial;
  modNf: ModNf;
  serie: string;
  numNf: string;
  dtEmissao: Date;
  valorFrete: any;
  valorSeguro: any;
  valorDesconto: any;
  valorOutrasDespesas: any;
  valorImpostoImportacao: any;
  valorIpi: any;
  valorIcmsSt: any;
  valorBaseCalculoIcms: any;
  valorIcms: any;
  valorIcmsSubst: any;
  valorIcmsUfRemetente: any;
  valorFcp: any;
  valorIcmsUfDestino: any;
  valorTotalTributos: any;
  valorInss: any;
  valorIr: any;
  valorCsll: any;
  valorPis: any;
  valorCofins: any;
  valorIss: any;
  valorOutrasRetencoes: any;
  valorTotal: any;
  moeda: Moeda;
  txMoeda: any;
  parent: Pedido;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;
  valorIssRetido: any;
  consumidorFinal: boolean;
  transportador: Participante;
  tipoFrete: TipoFrete;
  ufPlaca: Uf;
  codigoAntt: String;
  placaVeiculo: String;
  qVol: number;
  especieVol: String;
  marcaVol: String;
  numeracaoVol: number;
  pesoL: any;
  pesoB: any;
  chvNfe: String;
  nfeReferenciada: String;

  pedidoItem: Array<PedidoItem>;
  pedidoParcela: Array<PedidoParcela>;
  pedidoAprovacao: Array<PedidoAprovacao>;

  // alterado gkc - 27.11
  embarqueUf: Uf;
  embarqueLocal: String;
  embarqueDescricao: String;

  tipoPagamento: number;

  valorPisFaturamento: any;
  valorCofinsFaturamento: any;

  itemFaturamento: Item;

  numeroInvoice: string;
  awb: string;
  dataInvoice: Date;
  dataInicial: Date;
  dataFinal: Date;
  dataAwb: Date;
  converterTaxa: boolean;

  dataUltimaAtualizacao: Date;

  pedidoVinculado: number;

  codModDoc: string;
  numSeqEcf: string;
  numContOrdem: string;

  participanteEmpresaId: number;

  localPrestacao: number;

  //Para ASAAS
  temFaturaAsaas: boolean = false
  temPagamento: boolean = false

  constructor(pedido?: Pedido | number) {
    if (pedido !== undefined && pedido !== null) {
      if (typeof pedido === 'number') {
        this.id = pedido;
      } else {
        this.id = pedido.id;
        this.empresa = pedido.empresa;
        this.codigo = pedido.codigo;
        this.codigoControle = pedido.codigoControle;
        this.metodoEnvio = pedido.metodoEnvio;
        this.codigoOrcamento = pedido.codigoOrcamento;
        this.tipoIncoterm = pedido.tipoIncoterm;
        this.dadosAdicionais = pedido.dadosAdicionais;
        this.enderecoEntrega = pedido.enderecoEntrega;
        this.enderecoCobranca = pedido.enderecoCobranca;
        this.pedidoTipo = pedido.pedidoTipo;
        this.participante = pedido.participante;
        this.dtEntrega = pedido.dtEntrega;
        this.pedidoStatus = pedido.pedidoStatus;
        this.pedidoNfeRelac = pedido.pedidoNfeRelac;
        this.formaPagamento = pedido.formaPagamento;
        this.operacaoRegra = pedido.operacaoRegra;
        this.dtFaturamentoAutomatico = pedido.dtFaturamentoAutomatico;
        this.faturaAutomatico = pedido.faturaAutomatico;
        this.contrato = pedido.contrato;
        this.dtFinalContrato = pedido.dtFinalContrato;
        this.empresaConta = pedido.empresaConta;
        this.modNf = pedido.modNf;
        this.serie = pedido.serie;
        this.numNf = pedido.numNf;
        this.dtEmissao = pedido.dtEmissao;
        this.valorFrete = pedido.valorFrete;
        this.valorSeguro = pedido.valorSeguro;
        this.valorDesconto = pedido.valorDesconto;
        this.valorFcp = pedido.valorFcp;
        this.valorIcmsUfDestino = pedido.valorIcmsUfDestino;
        this.valorTotalTributos = pedido.valorTotalTributos;
        this.valorOutrasDespesas = pedido.valorOutrasDespesas;
        this.valorImpostoImportacao = pedido.valorImpostoImportacao;
        this.valorIpi = pedido.valorIpi;
        this.valorIcmsSt = pedido.valorIcmsSt;
        this.valorBaseCalculoIcms = pedido.valorBaseCalculoIcms;
        this.valorIcms = pedido.valorIcms;
        this.valorIcmsSubst = pedido.valorIcmsSubst;
        this.valorIcmsUfRemetente = pedido.valorIcmsUfRemetente;
        this.valorInss = pedido.valorInss;
        this.valorIr = pedido.valorIr;
        this.valorCsll = pedido.valorCsll;
        this.valorPis = pedido.valorPis;
        this.valorCofins = pedido.valorCofins;
        this.valorIss = pedido.valorIss;
        this.valorOutrasRetencoes = pedido.valorOutrasRetencoes;
        this.valorTotal = pedido.valorTotal;
        this.moeda = pedido.moeda;
        this.txMoeda = pedido.txMoeda;
        this.parent = pedido.parent;
        this.createTimestamp = pedido.createTimestamp;
        this.changeUser = pedido.changeUser;
        this.changeTimestamp = pedido.changeTimestamp;
        this.pedidoItem = pedido.pedidoItem;
        this.pedidoParcela = pedido.pedidoParcela;
        this.pedidoAprovacao = pedido.pedidoAprovacao;
        this.valorIssRetido = pedido.valorIssRetido;
        this.consumidorFinal = pedido.consumidorFinal;
        this.nfeFinalidade = pedido.nfeFinalidade;
        this.nfePresencial = pedido.nfePresencial;
        this.transportador = pedido.transportador;
        this.tipoFrete = pedido.tipoFrete;
        this.ufPlaca = pedido.ufPlaca;
        this.codigoAntt = pedido.codigoAntt;
        this.placaVeiculo = pedido.placaVeiculo;
        this.qVol = pedido.qVol;
        this.especieVol = pedido.especieVol;
        this.marcaVol = pedido.marcaVol;
        this.numeracaoVol = pedido.numeracaoVol;
        this.pesoL = pedido.pesoL;
        this.pesoB = pedido.pesoB;
        this.chvNfe = pedido.chvNfe;
        this.nfeReferenciada = pedido.nfeReferenciada;
        // local de exportação
        this.embarqueUf = pedido.embarqueUf;
        this.embarqueLocal = pedido.embarqueLocal;
        this.embarqueDescricao = pedido.embarqueDescricao;
        this.tipoPagamento = pedido.tipoPagamento;
        this.valorPisFaturamento = pedido.valorPisFaturamento;
        this.valorCofinsFaturamento = pedido.valorCofinsFaturamento;
        this.itemFaturamento = pedido.itemFaturamento;
        this.numeroInvoice = pedido.numeroInvoice;
        this.awb = pedido.awb;
        this.dataInvoice = pedido.dataInvoice;
        this.dataInicial = pedido.dataInicial;
        this.dataFinal = pedido.dataFinal;
        this.dataAwb = pedido.dataAwb;
        this.converterTaxa = pedido.converterTaxa;
        this.dataUltimaAtualizacao = pedido.dataUltimaAtualizacao;
        this.pedidoVinculado = pedido.pedidoVinculado;
        this.codModDoc = pedido.codModDoc;
        this.numSeqEcf = pedido.numSeqEcf;
        this.numContOrdem = pedido.numContOrdem;
        this.participanteEmpresaId = pedido.participanteEmpresaId;
        this.localPrestacao = pedido.localPrestacao;
        this.temPagamento = pedido.temPagamento
        this.temFaturaAsaas = pedido.temFaturaAsaas
      }
    }
  }
}
