export class TipoIncoterm {
  id: number;
  tipo: string;
  codigo: string;
  descricao: string;

  constructor(tipoIncoterm?: TipoIncoterm | number) {
    if (tipoIncoterm !== undefined && tipoIncoterm !== null) {
      if (typeof tipoIncoterm === 'number') {
        this.id = tipoIncoterm;
      } else {
        this.id = tipoIncoterm.id;
        this.tipo = tipoIncoterm.tipo;
        this.codigo = tipoIncoterm.codigo;
        this.descricao = tipoIncoterm.descricao;
      }
    }
  }
}
