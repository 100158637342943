import { AuthUser } from 'app/proof/model/auth-user.model';


export class LocalidadeGrupo {

  id: number;
  descricao: string;
  changeUser: AuthUser;
  changeTimestamp: Date;

constructor(localidadeGrupo?: LocalidadeGrupo | number) {
    if (localidadeGrupo !== undefined && localidadeGrupo !== null) {
      if (typeof localidadeGrupo === 'number') {
        this.id = localidadeGrupo;
      } else {
        this.id = localidadeGrupo.id;
        this.descricao = localidadeGrupo.descricao;
        this.changeTimestamp = localidadeGrupo.changeTimestamp;
        this.changeUser = localidadeGrupo.changeUser;
      }
    }
  }
}
