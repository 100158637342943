import { ParticipanteEmpresa } from './participante-empresa.model';
import { InstituicaoFinanceira } from 'app/util/model/instituicao-financeira.model';

export class ParticipanteDadoBancario {
  id: number;
  banco: InstituicaoFinanceira;
  tipoConta: string;
  agencia: string;
  contaNumero: string;
  contaDv: string;
  contaPrincipal: boolean;
  participanteEmpresa: ParticipanteEmpresa;
  tipo_pix: number;
  chave_pix: string;

  constructor(participanteDadoBancario?: ParticipanteDadoBancario | number) {
    if (participanteDadoBancario !== undefined && participanteDadoBancario !== null) {
      if (typeof participanteDadoBancario === 'number') {
        this.id = participanteDadoBancario;
      } else {
        this.id = participanteDadoBancario.id;
        this.tipoConta = participanteDadoBancario.tipoConta;
        this.agencia = participanteDadoBancario.agencia;
        this.contaNumero = participanteDadoBancario.contaNumero;
        this.contaDv = participanteDadoBancario.contaDv;
        this.participanteEmpresa = participanteDadoBancario.participanteEmpresa;
        this.contaPrincipal = participanteDadoBancario.contaPrincipal;
        this.chave_pix = participanteDadoBancario.chave_pix;
        this.tipo_pix = participanteDadoBancario.tipo_pix;
      }
    }
  }
}
