import { Empresa} from '../../cadastro/model/empresa.model';

export class CadEcf {
  id: number;
  escrRetif:  string;
  numRecAnt: string;
  dtIni: string;
  dtFin: string;
  tipoEcf: string;
  empresa: Empresa = new Empresa();

  constructor(cadecf?: CadEcf | number) {
    if (cadecf !== undefined && cadecf !== null) {
      if (typeof cadecf === 'number') {
        this.id = cadecf;
      } else {
        this.id = cadecf.id;
        this.escrRetif = cadecf.escrRetif;
        this.numRecAnt = cadecf.numRecAnt;
        this.dtIni = cadecf.dtIni;
        this.dtFin = cadecf.dtFin;
        this.tipoEcf = cadecf.tipoEcf;
        this.empresa = cadecf.empresa || new Empresa();

      }
    }
  }
}
