import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlanoContasRfb } from '../model/plano-contas-rfb.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { PlanoGerencial } from '../model/plano-gerencial.model';
import { PlanoGerencialContas } from '../model/plano-gerencial-contas.model';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from '../../shared/services/http.service';
import { UtilService } from '../../shared/services/util.service';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { CodNaturezaConta } from '../../util/model/cod-natureza-conta.model';
import { SelectItem, Message, MessageService } from 'primeng/api';
import { ConfigService } from '../../shared/services/config.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { Moeda } from '../../util/model/moeda.model';
import { PlanoContas } from '../model/plano-contas.model';
import { PlanoContasService } from '../../shared/services/plano-contas.service';
import { PlanoGerencialContasVinculacao } from '../model/plano-gerencial-contas-vinculacao.model';
import { CadCcus } from '../model/cad-ccus.model';
import { CadProj } from '../model/cad-proj.model';
import { Idioma } from '../../util/model/idioma.model';
import { PlanoGerencialContasInternacionalizacao } from '../model/plano-gerencial-contas-internacionalizacao.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { isNullOrUndefined } from 'util';
import { View } from 'app/cadastro/model/view.model';


@Component({
  selector: 'app-planogerencialcontas',
  templateUrl: './planogerencialcontas.component.html',
  styleUrls: ['./planogerencialcontas.component.scss'
  ],
  providers: [CrudService]
})
export class PlanoGerencialContasComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public planogerencialcontas: PlanoGerencialContas = new PlanoGerencialContas();

  planoGerencial: PlanoGerencial = new PlanoGerencial();

  private sessionSubscription: Subscription;
  private beforeViewSubscription: Subscription;

  public contasForm: FormGroup;

  entityFilter: string = null;

  newPc: boolean;
  editPc: boolean;
  viewPc: boolean;

  newCpg: boolean;
  editCpg: boolean;
  viewCpg: boolean;
  viewAbas: boolean;

  msgs: Message[];
  msgsAdv: Message[];


  title: String;
  subtitle: String;
  opcoesPlanoGerencial: SelectItem[];
  opcoesNatureza: SelectItem[];
  opcoesIndicador: SelectItem[];
  opcoesNivel: SelectItem[];
  opcoesEmpresa: SelectItem[];
  opcoesContas: SelectItem[];
  opcoesMoeda: SelectItem[];
  opcoesView: SelectItem[];
  opcoesIdioma: SelectItem[];
  opcoesCentroCusto: SelectItem[];
  opcoesProjeto: SelectItem[];
  contasGerenciais: any;
  planosGerenciais: any;

  idSelecionado: Array<any> = new Array();
  idContaSelecionado: Array<any> = new Array();

  contaSelecionada = false;
  planoContasList: Array<PlanoContas> = new Array();
  planoContasSelectedList: Array<PlanoContas> = new Array();
  planoContasGerencialRelacList: Array<number> = new Array();
  contasInternacionalizacao: Array<PlanoGerencialContasInternacionalizacao> = new Array();

  private planoContasService: PlanoContasService = new PlanoContasService('gerencial');

  centroCusto: CadCcus = new CadCcus();
  cadProj: CadProj = new CadProj();
  idioma: Idioma = new Idioma();

  modalPlanoGerencial: boolean;
  modalContaPlanoGerencial: boolean;
  modalInternacionalizacao: boolean;
  cloneModal: boolean;
  empresaClone: Empresa;

  contadorLinha: number = 0;
  contadorLinhaList: number = 0;

  //export excel
  colsplanosGerenciais: any[]
  colscontasGerenciais: any[];

  planoContasGerencialInternacionalizacao: PlanoGerencialContasInternacionalizacao = new PlanoGerencialContasInternacionalizacao();


  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private messageService: MessageService) {
    super(crudService);
  }

  ngOnInit() {
    // TODO: REVIEW (IMPORTANT!)
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.planogerencialcontas = new PlanoGerencialContas();
    this.planogerencialcontas.planoGerencial = new PlanoGerencial();
    this.planogerencialcontas.codNaturezaConta = new CodNaturezaConta();
    this.planogerencialcontas.ctaSup = new PlanoGerencialContas();
    this.planoGerencial = new PlanoGerencial();
    this.planoGerencial.niveis = 1;
    this.planoGerencial.moeda = new Moeda();
    this.planoGerencial.moeda.id = 0;
    this.planoGerencial.view = new View();
    this.planoGerencial.view.id = 0;
    this.idSelecionado = [];
    this.idContaSelecionado = [];
    this.viewAbas = false;
    this.centroCusto = new CadCcus();
    this.cadProj = new CadProj();
    this.idioma = new Idioma();
    this.contasInternacionalizacao = new Array();
    this.planoContasGerencialInternacionalizacao = new PlanoGerencialContasInternacionalizacao();
    this.planoContasGerencialInternacionalizacao.idioma = new Idioma();
    this.cloneModal = false;
    this.empresaClone = new Empresa();




    this.title = 'PLANO GERENCIAL';
    this.subtitle = 'CADASTRE E CONTROLE O PLANO GERENCIAL E SUAS CONTAS';
    this.contasGerenciais = [];
    this.planosGerenciais = [];
    this.viewPc = true;
    this.viewCpg = false;
    this.newPc = false;
    this.newCpg = false;
    this.editCpg = false;
    this.editPc = false;
    this.modalPlanoGerencial = false;
    this.modalContaPlanoGerencial = false;
    this.modalInternacionalizacao = false;

    // Promise.resolve(null).then(() => this.toolbarRefresh());

    this.opcoesIndicador = [];
    this.opcoesIndicador.push({ label: 'Nenhum', value: null });
    this.opcoesIndicador.push({ label: 'A', value: 'A' });
    this.opcoesIndicador.push({ label: 'S', value: 'S' });

    this.opcoesNivel = [];
    this.opcoesNivel.push({ label: '1', value: 1 });
    this.opcoesNivel.push({ label: '2', value: 2 });
    this.opcoesNivel.push({ label: '3', value: 3 });
    this.opcoesNivel.push({ label: '4', value: 4 });
    this.opcoesNivel.push({ label: '5', value: 5 });
    this.opcoesNivel.push({ label: '6', value: 6 });

    this.httpService.wait();
    this.httpService.get('/empresa?$select=id,cnpj,nomeFantasia')
      .subscribe(ref => this.loadEmpresa(ref.value),
      error => this.httpService.handleError(error, () => this.empresaChanged()),
      () => this.httpService.done());


    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.empresaChanged(); });

    this.colsplanosGerenciais = [
      { field: 'id', header: 'ID' },
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'nome', header: 'NOME' },
      { field: 'moeda.codigo', header: 'MOEDA' }
    ];

    this.colscontasGerenciais = [
      { field: 'codCta', header: 'CÓDIGO' },
      { field: 'descrCta', header: 'DESCRIÇÃO' },
      { field: 'nivel', header: 'NÍVEL' },
      { field: 'indCta', header: 'INDICADOR' }
    ];

    this.contadorLinha = 0;
    this.contadorLinhaList = 0;
  }

  loadNatureza(value: Array<CodNaturezaConta>) {

    this.opcoesNatureza = [];
    this.opcoesNatureza.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesNatureza.push({ label: element.cod + ' - ' + element.descricao, value: element.id });
    });
  }

  loadPlanoGerencial(value: Array<PlanoGerencial>) {

    this.opcoesPlanoGerencial = [];
    this.opcoesPlanoGerencial.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesPlanoGerencial.push({ label: element.codigo + ' - ' + element.nome, value: element.id });
    });
  }

  save() {

    const listasEnviar = {
      planoGerencial: this.planogerencialcontas.planoGerencial.id,
      codCta: this.planogerencialcontas.codCta,
      descCta: this.planogerencialcontas.descrCta,
      nivel: this.planogerencialcontas.nivel,
      codNatureza: this.planogerencialcontas.codNaturezaConta.id,
      ctaSup: this.planogerencialcontas.ctaSup.id,
      indCta: this.planogerencialcontas.indCta,
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/ecd/cadastrar-conta-gerencial', listasEnviar)
      .subscribe(result => this.afterSaveConta(),
        error => this.httpService.handleError(error, () => this.save()),
        () => this.httpService.done());

  }


  editarContaTable(event: any) {

  }

  gravarPlanoGerencial() {
    const listasEnviar = {
      codigo: this.planoGerencial.codigo,
      nome: this.planoGerencial.nome,
      niveis: this.planoGerencial.niveis,
      moeda: this.planoGerencial.moeda.id,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id

    };


    this.httpService.wait();
    this.httpService.post('/custom/ecd/cadastrar-plano-gerencial', listasEnviar)
      .subscribe(result => this.ngOnInit(),
        error => this.httpService.handleError(error, () => this.save()),
        () => this.httpService.done());
  }

  atualizarPlanoGerencial() {
    const listasEnviar = {
      codigo: this.planoGerencial.codigo,
      nome: this.planoGerencial.nome,
      niveis: this.planoGerencial.niveis,
      moeda: this.planoGerencial.moeda.id,
      usuario: this.sessionService.loggedUser.id,
      plano: this.planoGerencial.id

    };


    this.httpService.wait();
    this.httpService.post('/custom/ecd/atualizar-plano-gerencial', listasEnviar)
      .subscribe(result => this.ngOnInit(),
        error => this.httpService.handleError(error, () => this.save()),
        () => this.httpService.done());
  }

  cancelarEdicaoPlanoGerencial() {
    this.ngOnInit();
  }

  editarConta() {
    const listasEnviar = {
      planoGerencial: this.planoGerencial.id,
      codCta: this.planogerencialcontas.codCta,
      descCta: this.planogerencialcontas.descrCta,
      nivel: this.planogerencialcontas.nivel,
      codNatureza: this.planogerencialcontas.codNaturezaConta.id,
      ctaSup: this.planogerencialcontas.ctaSup.id,
      indCta: this.planogerencialcontas.indCta,
      usuario: this.sessionService.loggedUser.id,
      contaGerencial: this.planogerencialcontas.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/ecd/editar-conta-gerencial', listasEnviar)
      .subscribe(result => this.afterSaveConta(),
        error => this.httpService.handleError(error, () => this.save()),
        () => this.httpService.done());
  }

  deletarConta() {
    if (confirm('Deseja realmente excluir esta conta?')) {

      this.httpService.wait();
      this.httpService.post('/custom/ecd/excluir-conta-gerencial(' + this.planogerencialcontas.id + ')')
        .subscribe(result => this.afterSaveConta(),
          error => this.httpService.handleError(error, () => this.save()),
          () => this.httpService.done());

    }
  }

  procurarContaSuperior() {

    if(!isNullOrUndefined(this.planoGerencial.id) &&
    !isNullOrUndefined(this.planogerencialcontas.nivel) &&
    !isNullOrUndefined(this.planogerencialcontas.codNaturezaConta.id)){
      this.httpService.wait();
      this.httpService.get('/custom/ecd/conta-gerencial-sup(' + this.planoGerencial.id + ',' +
        + this.planogerencialcontas.nivel + ',' + this.planogerencialcontas.codNaturezaConta.id + ')')
        .subscribe(ref => this.loadContas(ref),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());
    }

  }

  loadContas(value: Array<any>) {

    this.opcoesContas = [];
    this.opcoesContas.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesContas.push({ label: element.codCta + ' - ' + element.descrCta, value: element.id });
    });
  }

  loadMoedas(value: Array<any>) {

    this.opcoesMoeda = [];
    this.opcoesMoeda.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesMoeda.push({ label: element.codigo, value: element });
    });
  }

  loadViews(value: Array<any>) {

    this.opcoesView = [];
    this.opcoesView.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesView.push({ label: element.nome, value: element });
    });
  }

  loadIdioma(value: Array<any>) {

    this.opcoesIdioma = [];
    this.opcoesIdioma.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesIdioma.push({ label: element.codigo, value: element.id });
    });
    this.toolbarRefresh();
  }

  clonarPedido() {

    const listasEnviar = {
      planoGerencial: this.idSelecionado[0],
      empresaClone: this.empresaClone.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/ecd/clonar-plano-gerencial', listasEnviar)
      .subscribe(result => this.ngOnInit(),
        error => this.httpService.handleError(error, () => this.save()),
        () => this.httpService.done());

  }

  loadEmpresa(value: Array<any>) {

    this.opcoesEmpresa = [];
    this.opcoesEmpresa.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesEmpresa.push({ label: element.cnpj + ' - ' + element.nomeFantasia, value: element });
    });
  }


  loadCentroCusto(value: Array<any>) {

    this.opcoesCentroCusto = [];
    this.opcoesCentroCusto.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesCentroCusto.push({ label: element.codCcus + ' - ' + element.descrCcus, value: element.id });
    });
  }

  loadProjeto(value: Array<any>) {

    this.opcoesProjeto = [];
    this.opcoesProjeto.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesProjeto.push({ label: element.codProj + ' - ' + element.descrProj, value: element.id });
    });
  }


  empresaChanged() {

    if (this.dblinkedSessionService.hasEmpresaSelected) {

    this.httpService.wait();
    this.httpService.get('/planogerencial?$select=id,codigo,nome,niveis,moeda,view&$' +
    'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
      .subscribe(ref => this.planosGerenciais = ref.value,
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/codnaturezaconta?$select=id,cod,descricao')
      .subscribe(ref => this.loadNatureza(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/planogerencial?$select=id,codigo,nome')
      .subscribe(ref => this.loadPlanoGerencial(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/moeda?$select=id,codigo')
      .subscribe(ref => this.loadMoedas(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

        this.httpService.wait();
    this.httpService.get('/view?$select=id,nome')
      .subscribe(ref => this.loadViews(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/idioma?$select=id,codigo,descricao')
      .subscribe(ref => this.loadIdioma(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());


      }
  }

  onRowSelect(event: any) {

    this.viewPc = false;
    this.viewCpg = true;
    this.newPc = false;
    this.newCpg = false;
    this.editCpg = false;
    this.editPc = false;


    this.title = 'EDITAR PLANO GERENCIAL';
    this.subtitle = 'EDITANDO PLANO GERENCIAL: ' + event.codigo + ' - ' + event.nome;
    this.planoGerencial = event;

    console.log(this.planoGerencial);

    this.httpService.wait();
    this.httpService.get('/planogerencialcontas?$select=id,codCta,descrCta,' +
      'codNaturezaConta/id,indCta,nivel,ctaSup/id&$filter=planoGerencial/id eq ' + this.planoGerencial.id +
      '&$orderby=codCta')
      .subscribe(ref => {
        this.contasGerenciais = ref.value;

        if(this.contasGerenciais !== null || this.contasGerenciais !== undefined){
          this.contadorLinha = this.contasGerenciais.length;
        }
          else{
              this.contadorLinha = 0;
        }

        this.toolbarRefreshPcg();

      },
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/cadccus?$select=id,codCcus,descrCcus&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
          '&$orderby=codCcus').subscribe(cc => this.loadCentroCusto(cc.value),
            error => this.httpService.handleError(error, () => this.onRowSelect(event)),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/cadproj?$select=id,codProj,descrProj&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
          '&$orderby=codProj').subscribe(cc => this.loadProjeto(cc.value),
            error => this.httpService.handleError(error, () => this.onRowSelect(event)),
            () => this.httpService.done());

  }

  afterSaveConta() {

    this.idContaSelecionado = new Array();
    this.viewPc = false;
    this.viewCpg = true;
    this.newPc = false;
    this.newCpg = false;
    this.editCpg = false;
    this.editPc = false;
    this.modalContaPlanoGerencial = false;
    this.idSelecionado = [];
    this.modalInternacionalizacao = false;

    this.title = 'EDITAR PLANO GERENCIAL';
    this.subtitle = 'EDITANDO PLANO GERENCIAL: ' + this.planoGerencial.codigo + ' - ' + this.planoGerencial.nome;

    this.httpService.wait();
    this.httpService.get('/planogerencialcontas?$select=id,codCta,descrCta,' +
      'codNaturezaConta/id,indCta,nivel,ctaSup/id&$filter=planoGerencial/id eq ' + this.planoGerencial.id +
      '&$orderby=codCta')
      .subscribe(ref => {
        this.contasGerenciais = ref.value;


        this.httpService.wait();
        this.httpService.get('/cadccus?$select=id,codCcus,descrCcus&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
          '&$orderby=codCcus').subscribe(cc => this.loadCentroCusto(cc.value),
            error => this.httpService.handleError(error, () => this.ngOnInit()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/cadproj?$select=id,codProj,descrProj&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
          '&$orderby=codProj').subscribe(cc => this.loadProjeto(cc.value),
            error => this.httpService.handleError(error, () => this.ngOnInit()),
            () => this.httpService.done());

        this.toolbarRefreshPcg();

      },
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());
  }

  onRowSelectConta(event: any) {

   this.viewPc = false;
    this.viewCpg = true;
    this.newPc = false;
    this.newCpg = false;
    this.editCpg = false;
    this.editPc = false;
    this.viewAbas = true;

    this.planogerencialcontas = event;

    this.title = 'EDITAR CONTA DO PLANO GERENCIAL';
    this.subtitle = 'EDITANDO CONTA: ' + this.planogerencialcontas.codCta + ' - ' + this.planogerencialcontas.descrCta;

    if (!event.ctaSup) {
      this.planogerencialcontas.ctaSup = new PlanoGerencialContas();
    }

    this.httpService.wait();
    this.httpService.get('/planogerencialcontasinternacionalizacao?$select=id,codCta,descrCta,idioma&$filter='
      + 'planoGerencialContas/id eq ' + event.id + '&$orderby=idioma/codigo').subscribe(cc => this.contasInternacionalizacao = cc.value,
        error => this.httpService.handleError(error, () => this.onRowSelectConta(event)),
        () => this.httpService.done());


    this.httpService.wait();
    this.httpService.get('/custom/ecd/planosvinculados', [this.dblinkedSessionService.empresa.id,
    event.id])
      .subscribe(listaReferenciados => {

        this.httpService.wait();
        this.httpService.get('/custom/ecd/planocontasgerencial', [this.dblinkedSessionService.empresa.id,
        this.dblinkedSessionService.periodo.year.value, this.planoGerencial.id])
          .subscribe(planoContasList => {
            this.contaSelecionada = false;

            this.planoContasList = planoContasList.value;

            this.planoContasList.forEach(pc => {
              pc.selected = false;
            });

            this.planoContasList
              .filter(p => listaReferenciados.findIndex(s => s.id === p.id) > -1)
              .forEach(p => {
                p.planoContasGerencialRelacList
                  .forEach(relac => this.planoContasGerencialRelacList.push(relac.id));
              });
            this.planoContasSelectedList = listaReferenciados;
          },
            error => this.httpService.handleError(error,
              () => this.onRowSelectConta(event)),
            () => this.httpService.done());
      },
        error => this.httpService.handleError(error, () => this.onRowSelectConta(event)),
        () => this.httpService.done());


  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.dblinkedSessionService.hidePeriods();
    // this.toolbarService.clear();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  private toolbarRefresh() {
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.ngOnInit());
    this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.empresaChanged());
    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.empresaChanged());
    this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.empresaChanged());
    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Adicionar Plano Gerencial', () => this.novoPlanoGerencial());
    /*this.toolbarService.clear();
    this.toolbarService.add('back', false, 'class-back-button', 'undo', 'Voltar', () => this.ngOnInit());
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Adicionar Plano Gerencial', () => this.novoPlanoGerencial());*/
  }

  private toolbarEditPg() {
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.ngOnInit());
    this.toolbarService.alterarStatus('export', false, 'Clonar Plano', () => this.clonarPlanoModal());
    this.toolbarService.alterarStatus('remove', false, 'Excluir Plano', () => this.excluirPlanoGerencial());
    this.toolbarService.alterarStatus('edit', false, 'Editar', () => this.editarPlanoGerencial());
    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Adicionar Plano Gerencial', () => this.novoPlanoGerencial());
    /*this.toolbarService.clear();
    this.toolbarService.add('back', false, 'class-back-button', 'undo', 'Voltar', () => this.ngOnInit());
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', false, 'class-remove-button', 'delete', 'Excluir Plano', () => this.excluirPlanoGerencial());
    this.toolbarService.add('edit', false, 'class-edit-button', 'edit', 'Editar', () => this.editarPlanoGerencial());
    this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Adicionar Plano Gerencial', () => this.novoPlanoGerencial());*/
  }

  private clonarPlanoModal() {
    this.cloneModal = true;
  }

  private toolbarEditPcg() {
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.ngOnInit());
    this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.empresaChanged());
    this.toolbarService.alterarStatus('remove', false, 'Excluir Conta', () => this.excluirContaPlanoGerencial());
    this.toolbarService.alterarStatus('edit', false, 'Editar', () => this.editarContaPlanoGerencial());
    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO',
                                'Adicionar Conta Gerencial', () => this.novaContaPlanoGerencial());
    /*this.toolbarService.clear();
    this.toolbarService.add('back', false, 'class-back-button', 'undo', 'Voltar', () => this.ngOnInit());
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', false, 'class-remove-button', 'delete', 'Excluir Conta', () => this.excluirContaPlanoGerencial());
    this.toolbarService.add('edit', false, 'class-edit-button', 'edit', 'Editar', () => this.editarContaPlanoGerencial());
    this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Adicionar Conta Gerencial', () => this.novaContaPlanoGerencial());*/
  }

  private toolbarRefreshPcg() {
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.ngOnInit());
    this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.empresaChanged());
    this.toolbarService.alterarStatus('remove', true, 'Excluir Conta', () => this.excluirContaPlanoGerencial());
    this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.editarContaPlanoGerencial());
    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO',
                                'Adicionar Conta Plano Gerencial', () => this.novaContaPlanoGerencial());
    /*this.toolbarService.clear();
    this.toolbarService.add('back', false, 'class-back-button', 'undo', 'Voltar', () => this.ngOnInit());
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Adicionar Conta Plano Gerencial',
      () => this.novaContaPlanoGerencial());*/
  }


  alteracaoIndicador(event: any) {

  }

  novoPlanoGerencial() {
    this.editPc = false;
    this.modalPlanoGerencial = true;
  }

  novaContaPlanoGerencial() {
    this.editCpg = false;
    this.modalContaPlanoGerencial = true;
    this.planogerencialcontas = new PlanoGerencialContas();
    this.planogerencialcontas.codNaturezaConta = new CodNaturezaConta();
    this.planogerencialcontas.ctaSup = new PlanoGerencialContas();
    this.planogerencialcontas.planoGerencial = this.planoGerencial;
    this.planogerencialcontas.nivel = 1;

    this.opcoesNivel = [];

    for (let index = 1; index <= this.planoGerencial.niveis; index++) {
      this.opcoesNivel.push({ label: index.toString(), value: index });
    }


  }

  editarPlanoGerencial() {
    this.editPc = true;
    this.modalPlanoGerencial = true;
    this.httpService.wait();
    this.httpService.get('/planogerencial?$select=id,codigo,nome,niveis,moeda,view&$filter=id eq ' + this.idSelecionado)
      .subscribe(ref => { console.log(ref.value[0]);this.modalPlanoGerencial = true; this.planoGerencial = ref.value[0]; },
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());
  }

  editarContaPlanoGerencial() {
    this.editCpg = true;
    this.modalContaPlanoGerencial = true;
    this.httpService.wait();
    this.httpService.get('/planogerencialcontas?$select=id,codCta,descrCta,' +
      'codNaturezaConta/id,indCta,nivel,ctaSup/id&$filter=id eq ' + this.idContaSelecionado)
      .subscribe(ref => {
        this.modalContaPlanoGerencial = true;

        this.planogerencialcontas = ref.value[0];

        if (!ref.value[0].ctaSup) {
          this.planogerencialcontas.ctaSup = new PlanoGerencialContas();
        }

        this.procurarContaSuperior();
      },
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());
  }

  excluirContaPlanoGerencial() {

    if (confirm('Deseja excluir esta linha?')) {

      this.httpService.wait();
      this.httpService.delete('/planogerencialcontas', this.idContaSelecionado.toString())
        .subscribe(() => this.afterSaveConta(),
          error => this.httpService.handleError(error, () => this.excluirContaPlanoGerencial()),
          () => this.httpService.done());
    }

  }

  excluirPlanoGerencial() {

    if (confirm('Deseja excluir este plano?')) {

      this.httpService.wait();
      this.httpService.delete('/planogerencial', this.idSelecionado.toString())
        .subscribe(() => this.ngOnInit(),
          error => this.httpService.handleError(error, () => this.excluirPlanoGerencial()),
          () => this.httpService.done());
    }

  }


  selectCheckbox(id: any, event: any) {

    const index = this.idSelecionado.findIndex(c => c === id);
    if (event.selected) {
      this.idSelecionado.splice(index, 1);
      event.selected = true;
    } else {
      this.idSelecionado.push(id);
      event.selected = false;
    }

    if (this.idSelecionado.length > 0 && this.idSelecionado.length < 2) {
      this.toolbarEditPg();
    } else {
      this.toolbarRefresh();
    }
  }

  selectCheckboxConta(id: any, event: any) {

    this.viewAbas = false;

    console.log(id);
    console.log(event);

    const index = this.idContaSelecionado.findIndex(c => c === id);
    if (event.selected) {
      this.idContaSelecionado.splice(index, 1);
      event.selected = true;
    } else {
      this.idContaSelecionado.push(id);
      event.selected = false;
    }

    if (this.idContaSelecionado.length > 0 && this.idContaSelecionado.length < 2) {
      this.toolbarEditPcg();
    } else {
      this.toolbarRefreshPcg();
    }
  }

  planoContasListStyleClass(planoContas: PlanoContas): string {
    let classes = '';
    if (planoContas.indCta === 'S') {
      classes += 'bold ';
    }
    return classes.trim();
  }

  check(planoContas: PlanoContas, selected: boolean) {
    this.checkVerif(this.planoContasList, planoContas, selected);
  }

  checkVerif(planoContasFull: Array<PlanoContas>,
    planoContas: PlanoContas,
    selected: boolean) {
    planoContas.selected = selected;

    let set = false;

    planoContasFull.forEach(plano => {
      if (plano.id === planoContas.id) {
        set = true;
      } else if (set) {
        if (plano.nivel > planoContas.nivel) {
          plano.selected = planoContas.selected;
        } else {
          set = false;
        }
      }
    });

    this.check_parents(planoContasFull, planoContas);
  }

  check_parents(planoContasFull: Array<PlanoContas>, planoContas: PlanoContas = null) {
    if (planoContas.parent && planoContas.parent.id) {
      let all = false;

      const parent = planoContasFull.find(p => p.id === planoContas.parent.id);

      planoContasFull.filter(plano => plano.id !== parent.id &&
        plano.nivel === planoContas.nivel &&
        parent.children.findIndex(c => c === plano.id) > -1)
        .forEach(plano => {
          if (!plano.selected) {
            all = false;
          }
        });

      parent.selected = all;

      this.check_parents(planoContasFull, parent);
    }
  }


  planoContasDisabled(planoContas: PlanoContas): boolean {
    return this.planoContasService.disabled(this.planoContasSelectedList, planoContas);
  }

  private dataPlanoContasVinculacaoRelac(planoGerencialContas: PlanoGerencialContas, planoContas: PlanoContas,
    cadCcus: CadCcus, cadProj: CadProj) {
    const data = {
      id: undefined,
      planoContas: { id: planoContas.id },
      planoGerencialContas: { id: planoGerencialContas.id },
      cadCcus: { id: (cadCcus.id === null) ? null : cadCcus.id },
      cadProj: { id: (cadProj.id === null) ? null : cadProj.id }
    };

    return data;
  }


  add() {

    if (this.planogerencialcontas.indCta === 'A') {

      const newItems = this.toAdd(this.planoContasList, this.planoContasSelectedList);

      newItems.forEach((planoContas, i) => {

        const planoContasRefRelac = this.dataPlanoContasVinculacaoRelac(this.planogerencialcontas, planoContas,
          this.centroCusto, this.cadProj);

        this.httpService.wait();
        this.httpService.post('/custom/ecd/planocontasvinculacaorelac',
          planoContasRefRelac)
          .subscribe(id => {
            if (!id.error) {

              this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: id.message });

            } else {

              this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: id.message });

            }

            if (i === newItems.length - 1) {
              this.onRowSelectConta(this.planogerencialcontas);
            }
          },
            error => this.httpService.handleError(error, () => this.add()),
            () => this.httpService.done());
      });
    } else {
      alert('Selecione uma conta gerencial analítica para vincular!');
    }
  }

  toAdd(planoContasFull: Array<PlanoContas>, planoContasSelecionados: Array<PlanoContas>): Array<PlanoContas> {
    const result: Array<PlanoContas> = new Array();

    planoContasFull.filter(plano => plano.selected &&
      plano.indCta === 'A')
      .forEach(plano => {
        result.push(new PlanoContas(plano));
      });

    return result;
  }

  remove() {
    if (confirm('Deseja excluir esta vinculação?')) {
      const oldItems = this.planoContasService.toRemove(this.planoContasList, this.planoContasSelectedList);

      const ids = [];

      oldItems.forEach((item, i) => {
        ids.push(item.id);
      });

      const listasEnviar = {
        ids: ids
      };

      this.httpService.wait();
      this.httpService.post('/custom/ecd/remover-vinculacao', listasEnviar)
        .subscribe(() => {
          this.onRowSelectConta(this.planogerencialcontas);
        },
          error => this.httpService.handleError(error, () => this.remove()),
          () => this.httpService.done());
    }
  }

  hideMessage() {

  }

  hideMessageAdv() {

  }

  salvarInternacionalizacao() {

    const listasEnviar = {
      codCta: this.planoContasGerencialInternacionalizacao.codCta,
      descrCta: this.planoContasGerencialInternacionalizacao.descrCta,
      id: this.planoContasGerencialInternacionalizacao.id,
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/ecd/atualizar-idioma-conta-gerencial', listasEnviar)
      .subscribe(result => this.afterSaveConta(),
        error => this.httpService.handleError(error, () => this.save()),
        () => this.httpService.done());
  }

  onRowSelectInter(event: any) {
    this.modalInternacionalizacao = true;
    this.planoContasGerencialInternacionalizacao = event.data;
  }

  onFilter(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }

  onFilterPlanoContasList(event: any) {
    this.contadorLinhaList = event.filteredValue.length;
  }

}
