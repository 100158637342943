import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { isNullOrUndefined } from 'util';
import { Formulario } from 'app/eventos/model/formulario.model';
import { Message, MessageService } from 'primeng/api';


@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss'],
  providers: [CrudService]
})
export class FormularioComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  pt_BR: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;

  modalFormulario           : boolean = false;
  listaFormulario           : Array<any> = new Array();
  idLista                   : Array<any> = new Array();

  msgs          : Message[] = [];
  msgsModal     : Message[] = [];
  checkedAll    = false;

  formulario                       = new Formulario();
  editFormulario                   : boolean = false;
  btnSalvarDisabled                : boolean = false;
  btnCancelarDisabled              : boolean = false;
  colslistaFormulario              : any[];


  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title              = 'FORMULÁRIO';
    this.subtitle           = 'CADASTRO DE FORMULÁRIO';
    this.titleService.title = 'FORMULÁRIO';


    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();


    this.colslistaFormulario = [
      { field: 'nome', header: 'FORMULÁRIO' }
    ];

    this.modalFormulario      = false;
    this.checkedAll         = false;
    this.msgs               = [];
    this.msgsModal          = [];

    this.listaFormulario = new Array();
    this.formulario       = new Formulario();
    this.editFormulario   = false;
    this.idLista        = new Array();


    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.listar();

      });

  }

 listar(){

  this.modalFormulario      = false;
  this.checkedAll         = false;

  this.listaFormulario  = new Array();
  this.formulario       = new Formulario();
  this.editFormulario   = false;
  this.idLista        = new Array();
  this.toolbarEnable();

  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-lista-formularios')
    .subscribe(retorno => {

      this.listaFormulario = new Array();
      retorno.forEach(i =>{
        this.listaFormulario.push(i);
      })
    },
      error => this.httpService.handleError(error, () => this.listar()),
      () => this.httpService.done());

 }


  hideMessage() {




  }

  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;

    this.idLista = new Array();

    this.listaFormulario.forEach(i=> {
      if (!this.checkedAll) {
        i.selected = false;
        this.idLista.slice(i.id, 1);
      } else {
        i.selected = true;
        this.idLista.push(i.id);
      }
    });


    this.configuraToolBar();
  }

  configuraToolBar() {
    if (isNullOrUndefined(this.idLista) || this.idLista.length === 0) {
     this.toolbarService.alterarStatus('remove', true, 'Excluir Formulário', () => this.excluirFormulario());
     this.toolbarService.alterarStatus('add', false, 'Nova Formulário', ()  => this.showModalNovoFormulario());
     this.toolbarService.alterarStatus('export', true, 'Editar Formulário', () =>() => this.showModalEditarFormulario());
    } else {
      if (this.idLista.length === 1) {
        this.toolbarService.alterarStatus('remove', false, 'Excluir Formulário', () => this.excluirFormulario());
        this.toolbarService.alterarStatus('add', true, 'Nova Formulário', ()  => this.showModalNovoFormulario());
        this.toolbarService.alterarStatus('export', false, 'Editar Formulário', ()  => this.showModalEditarFormulario());


      } else if(this.idLista.length > 1)  {
        this.toolbarService.alterarStatus('remove', false, 'Excluir Formulário', () => this.excluirFormulario());
        this.toolbarService.alterarStatus('add', true, 'Nova Formulário', () => this.showModalNovoFormulario());
        this.toolbarService.alterarStatus('export', true, 'Editar Formulário', () => this.showModalEditarFormulario());
      }
    }

  }



  selectCheckbox(id: any, event: any) {

    if (event.selected) {
       event.selected = true;
       const index = this.idLista.findIndex(i => i === id);
       this.idLista.splice(index,1);

    } else {
      this.idLista.push(id);
    }

    this.configuraToolBar();

  }



  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

      Promise.resolve(null).then(() => this.toolbarService.hidden = false);
      this.toolbarService.ocultar('voltar');
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.ocultar('more_vert');
      this.toolbarService.ocultar('print');
      this.toolbarService.ocultar('edit');

      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
        () => this.ngOnInit()
      );
      this.toolbarService.ocultar('save');

     // this.toolbarService.replace('more_vert', true, 'class-edit-button', 'more_vert', 'Opções');
      this.toolbarService.ocultar('pesquisa');

      //this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir Pedido',     );
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.replace('export', true, 'class-edit-button', 'edit', 'Editar Formulário', () => this.showModalEditarFormulario() );
     // this.toolbarService.replace('edit', true, 'class-edit-button', 'list', 'Alterar Títulos Selecionados');

      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo Formulário',    () => this.showModalNovoFormulario()    );


  }



  showModalEditarFormulario() {
    this.modalFormulario = true;
    this.hideMessage();
    this.editFormulario = true;


    this.formulario = new Formulario();

    let i =  this.listaFormulario.findIndex(c=> c.id === this.idLista[0] );

    this.formulario.id    = this.listaFormulario[i].id;
    this.formulario.nome  = this.listaFormulario[i].nome;


  }

  showModalNovoFormulario() {
    this.formulario             = new Formulario();
    this.modalFormulario = true;
    this.editFormulario  = false;
    this.hideMessage();

  }

  validacao(){

    if (isNullOrUndefined( this.formulario.nome) || this.formulario.nome.length < 3 ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A campanha deve ter o mínimo de 3 caracteres!'
      });
      return true;

    }


    return false;
  }


  ngOnDestroy(): void {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  salvarFormulario(){

    if (this.validacao()) {
      return;
    }



    const listaEnviar = {
       formulario     : this.formulario,
       usuarioId      : this.sessionService.loggedUser.id,
       IsNovoRegistro : true
    };


    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-formulario', listaEnviar)
      .subscribe(result => {

        if (result[0].error) {
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        }

        this.listar();
      },
        error => this.httpService.handleError(error, () => this.salvarFormulario()),
        () => this.httpService.done());
  }

  editarFormulario(){

    if (this.validacao()) {
      return;
    }


    const listaEnviar = {
      id              : this.idLista[0],
      formulario      : this.formulario,
      usuarioId       : this.sessionService.loggedUser.id,
      IsNovoRegistro  : false
   };

   this.httpService.wait();
   this.httpService.post('/custom/polozi/salvar-formulario', listaEnviar)
     .subscribe(result => {
      if (result[0].error === true ) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      }
      this.listar();
     },
       error => this.httpService.handleError(error, () => this.salvarFormulario()),
       () => this.httpService.done());


  }


 excluirFormulario(){
   if (confirm('Deseja excluir o(s) formulário(s) selecionado(s)?')) {

    const listaEnviar = {
      ids              : this.idLista

   };

   this.httpService.wait();
   this.httpService.post('/custom/polozi/deletar-formulario', listaEnviar)
     .subscribe(result => {
      if (result[0].error ) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      }
      this.listar();
     },
       error => this.httpService.handleError(error, () => this.excluirFormulario()),
       () => this.httpService.done());


   }
 }




}
