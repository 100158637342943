import { Requisicao } from './requisicao.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadProj } from 'app/contabil/model/cad-proj.model';



export class RequisicaoItemRateio {

  id: number | string;
  item_id: number;
  requisicao: Requisicao;
  cadCcus: CadCcus;
  cadProj: CadProj;
  porcentagem: number;

  constructor(requisicaoItemRateio?: RequisicaoItemRateio | number) {
    if (requisicaoItemRateio !== undefined && requisicaoItemRateio !== null) {
      if (typeof requisicaoItemRateio === 'number') {
        this.id = requisicaoItemRateio;
      } else {
        this.id = requisicaoItemRateio.id;
        this.item_id = requisicaoItemRateio.item_id;
        this.requisicao = requisicaoItemRateio.requisicao;
        this.cadCcus = requisicaoItemRateio.cadCcus;
        this.cadProj = requisicaoItemRateio.cadProj;
        this.porcentagem = requisicaoItemRateio.porcentagem;
      }
    }
  }
}
