import { Component, OnInit, OnDestroy, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Message, SelectItem } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TipoBaixa } from '../../util/model/tipo-baixa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';

import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';

@Component({
  selector: 'app-posicao-cr-cp',
  templateUrl: './posicao-cr-cp.component.html',
  styleUrls: ['./posicao-cr-cp.component.scss'
  ],
  providers: [CrudService]
})
export class PosicaoCrCpComponent implements OnInit, OnDestroy {
  @ViewChildren(ToolbarService) panels;
  private sessionSubscription: Subscription;
  title: string;
  subtitle: string;
  recebimentos: Array<any> = new Array();
  historicos: Array<any> = new Array();
  baixas: Array<any> = new Array();
  parametro: string;

  modalEdit: boolean;
  modalDetalhe: boolean;
  valorVencimento: number;
  valorRecebido: number;
  valorAberto: number;
  todasSelecionadas: boolean;
  modalEfetuarBaixa: boolean;
  opcoesContas: SelectItem[];
  contaSelecionada: EmpresaConta;

  criarHistoricoCobranca: boolean;
  criarBaixaFinanceira: boolean;
  editarHistorico: boolean;
  editarBaixa: boolean;

  empresaConta: EmpresaConta;

  valorRecebidoBaixa: number;
  valorMultaBaixa: number;
  valorJurosBaixa: number;
  valorDescontoBaixa: number;
  valorTotalBaixa: number;

  nfeFinId: number;
  nfbId: number;
  nfhId: number;

  nomeTitulo: string;
  nfTitulo: string;
  dataVencimentoTitulo: string;
  parcelaTitulo: string;
  dataVencimento: Date;
  dataHistorico: Date;
  historico: string;

  tiposBaixa: Array<TipoBaixa> = new Array();
  tipoBaixa: TipoBaixa;

  contasBancarias: Array<EmpresaConta> = new Array();
  conta: EmpresaConta;

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService
  ) {}

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    Promise.resolve(null).then(() => this.toolbarRefresh());
    this.recebimentos = new Array<any>();
    this.modalEdit = false;
    this.modalDetalhe = false;
    this.todasSelecionadas = false;
    this.modalEfetuarBaixa = false;
    this.valorVencimento = 0.00;
    this.valorRecebido = 0.00;
    this.valorAberto = 0.00;
    this.contaSelecionada = new EmpresaConta();
    this.tipoBaixa = new TipoBaixa();

    this.valorRecebidoBaixa = 0.00;
    this.valorMultaBaixa = 0.00;
    this.valorJurosBaixa = 0.00;
    this.valorDescontoBaixa = 0.00;
    this.valorTotalBaixa = 0.00;
    this.nfeFinId = 0;
    this.nfbId = 0;
    this.nfhId = 0;

    this.nomeTitulo = '';
    this.nfTitulo = '';
    this.dataVencimentoTitulo = '';
    this.parcelaTitulo = '';
    this.historico = '';
    this.dataVencimento = new Date();
    this.dataHistorico = new Date();

    this.criarHistoricoCobranca = false;
    this.criarBaixaFinanceira = false;
    this.editarHistorico = false;
    this.editarBaixa = false;
    this.activatedRoute.params.subscribe(params => {
      if (params['tipo']) {
        this.parametro = params.tipo;
        if (params.tipo === 'cr') {
          this.title = 'POSIÇÃO DO CONTAS À RECEBER';
          this.subtitle = 'Posição do Contas à receber no período selecionado';
          this.titleService.title = 'Posição do Contas à receber';
          this.changePeriod();
        } else {
          this.title = 'POSIÇÃO DO CONTAS À PAGAR';
          this.subtitle = 'Posição do Contas à pagar no período selecionado';
          this.titleService.title = 'Posição do Contas à pagar';
          this.changePeriod();
       }
      }
    });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.changePeriod(); });
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.toolbarService.clear();
    this.sessionSubscription.remove(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  changePeriod() {

    if (this.dblinkedSessionService.hasEmpresaSelected &&
        this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

          this.httpService.wait();
      this.httpService.get('/tipobaixa?$select=id,descricao'
        + '&$orderby=descricao')
        .subscribe(ref => this.loadTipoBaixa(ref.value),
          error => this.httpService.handleError(error, () => this.changePeriod()),
          () => this.httpService.done());

          this.httpService.wait();
      this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,instituicaoFinanceira'
        + '&$orderby=nome')
        .subscribe(ref => this.loadContasBancarias(ref.value),
          error => this.httpService.handleError(error, () => this.changePeriod()),
          () => this.httpService.done());

          this.httpService.wait();
      this.httpService.get('/custom/financeiro/titulos-empresa-periodo',
      [this.dblinkedSessionService.empresa.id,
      this.dblinkedSessionService.periodo.month.value, this.dblinkedSessionService.periodo.year.value, this.parametro])
        .subscribe(ref => {
          this.recebimentos = ref;

          this.valorVencimento = 0.00;
    this.valorRecebido = 0.00;
    this.valorAberto = 0.00;
    this.recebimentos.forEach(element => {

      this.valorVencimento = this.valorVencimento + element.valor;
      if (element.saldo > 0.00) {
        this.valorAberto = this.valorAberto + element.saldo;
        this.valorRecebido = this.valorRecebido + (element.valor - element.saldo);
      } else if (element.saldo === 0.00) {
        this.valorRecebido = this.valorRecebido + (element.valor);
      }

    });
        },
          error => this.httpService.handleError(error, () => this.changePeriod()),
          () => this.httpService.done());

        }
    }

    loadTipoBaixa(array: Array<TipoBaixa>) {

      this.tiposBaixa = new Array();

      array.forEach(tipoBaixa => {
        this.tiposBaixa.push(tipoBaixa);
      });

    }

    loadContasBancarias(array: Array<EmpresaConta>) {

      this.contasBancarias = new Array();

      array.forEach(cc => {
        this.contasBancarias.push(cc);
      });

    }

  onRowSelect(event: any) {

    this.modalEfetuarBaixa = true;

    this.valorRecebidoBaixa = this.utilService.parseMoney(event.data.valor_titulo - event.data.valor_total_baixas);
    this.valorMultaBaixa = this.utilService.parseMoney(0.00);
    this.valorJurosBaixa = this.utilService.parseMoney(0.00);
    this.valorDescontoBaixa = this.utilService.parseMoney(0.00);
    this.valorTotalBaixa = this.utilService.parseMoney(event.data.valor_titulo - event.data.valor_total_baixas);

    this.nomeTitulo = event.data.nome;
    this.nfTitulo = event.data.nf;
    this.dataVencimentoTitulo = event.data.data;
    this.parcelaTitulo = event.data.parcela;
    this.dataVencimento = event.data.data;
    this.nfeFinId = event.data.id;

    this.criarHistoricoCobranca = false;
    this.criarBaixaFinanceira = true;
    this.editarHistorico = false;
    this.editarBaixa = false;

    this.httpService.wait();
    this.httpService.get('/custom/financeiro/buscar-baixas',
      [event.data.id])
        .subscribe(b => {
          this.baixas = b;
        }, error => this.httpService.handleError(error, () => this.onRowSelect(event)),
          () => this.httpService.done());

          this.httpService.wait();
          this.httpService.get('/custom/financeiro/buscar-historico-cobranca',
          [event.data.id])
            .subscribe(h => {
              this.historicos = h;
            }, error => this.httpService.handleError(error, () => this.onRowSelect(event)),
              () => this.httpService.done());



  }

  calcularValorBaixa() {
    this.valorTotalBaixa = this.utilService.parseMoney(this.valorRecebidoBaixa)
    +  this.utilService.parseMoney(this.valorMultaBaixa) +
    this.utilService.parseMoney(this.valorJurosBaixa) - this.utilService.parseMoney(this.valorDescontoBaixa);
  }

  check(form: any) {
    this.toolbarService.clear();
  }
  onFilter(event: any) {
    this.valorVencimento = 0.00;
    this.valorRecebido = 0.00;
    this.valorAberto = 0.00;
    event.filteredValue.forEach(element => {

      this.valorVencimento = this.valorVencimento + element.valor;
      if (element.saldo > 0.00) {
        this.valorRecebido = this.valorRecebido + element.saldo;
      } else if (element.saldo === 0.00) {
          this.valorAberto = this.valorAberto + element.saldo;
      }

    });
  }

  private toolbarRefresh() {
    this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  exibirBaixa(event: any) {
    this.criarHistoricoCobranca = false;
    this.criarBaixaFinanceira = false;
    this.editarHistorico = false;
    this.editarBaixa = true;

    // console.log(event);

    this.valorDescontoBaixa = event.desconto;
    this.valorJurosBaixa = event.juros;
    this.valorMultaBaixa = event.multa;
    this.valorRecebidoBaixa = event.valor;
    this.dataVencimento = event.data_baixa;
    this.nfbId = event.nfbId;
    this.conta = event.empresaConta;
    this.tipoBaixa = event.tipoBaixa;
    this.calcularValorBaixa();
  }

  exibirHistorico(event: any) {
    // console.log(event);

    this.criarHistoricoCobranca = false;
    this.criarBaixaFinanceira = false;
    this.editarHistorico = true;
    this.editarBaixa = false;

    this.nfhId = event.nfhId;
    this.historico = event.descricao;
    this.dataHistorico = event.data;

  }

  baixar() {
    const listasEnviar = {
      id: this.nfeFinId,
      valorBaixa: this.valorRecebidoBaixa,
      valorMulta: this.valorMultaBaixa,
      valorJuros: this.valorJurosBaixa,
      valorDesconto: this.valorDescontoBaixa,
      dataBaixa: this.dataVencimento,
      tipoBaixa: this.tipoBaixa.id,
      empresaConta: this.conta.id,
      usuario: this.sessionService.loggedUser.id
    };

   this.httpService.wait();
    this.httpService.post('/custom/financeiro/baixar-titulo', listasEnviar)
      .subscribe(result => this.atualizarVoltar(),
        error => this.httpService.handleError(error, () => this.baixar()),
        () => this.httpService.done());

  }

  alterarBaixa() {
    const listasEnviar = {
      id: this.nfbId,
      valorBaixa: this.valorRecebidoBaixa,
      valorMulta: this.valorMultaBaixa,
      valorJuros: this.valorJurosBaixa,
      valorDesconto: this.valorDescontoBaixa,
      dataBaixa: this.dataVencimento,
      usuario: this.sessionService.loggedUser.id
    };

   this.httpService.wait();
    this.httpService.post('/custom/financeiro/alterar-baixa', listasEnviar)
      .subscribe(result => this.atualizarVoltar(),
        error => this.httpService.handleError(error, () => this.alterarBaixa()),
        () => this.httpService.done());

  }

  alterarHistorico() {
    const listasEnviar = {
      id: this.nfhId,
      historico: this.historico,
      dataHistorico: this.dataHistorico,
      usuario: this.sessionService.loggedUser.id
    };

   this.httpService.wait();
    this.httpService.post('/custom/financeiro/alterar-historico', listasEnviar)
      .subscribe(result => this.atualizarVoltar(),
        error => this.httpService.handleError(error, () => this.alterarHistorico()),
        () => this.httpService.done());

  }

  excluirBaixa() {
    if (confirm('Deseja excluir esta baixa?')) {

      this.httpService.wait();
      this.httpService.delete('/nfefinbaixa', this.nfbId.toString())
        .subscribe(() => this.atualizarVoltar(),
          error => this.httpService.handleError(error, () => this.excluirBaixa()),
          () => this.httpService.done());

    }
  }

  excluirHistorico() {
    if (confirm('Deseja excluir este historico?')) {

      this.httpService.wait();
      this.httpService.delete('/nfefinhistorico', this.nfhId.toString())
        .subscribe(() => this.atualizarVoltar(),
          error => this.httpService.handleError(error, () => this.excluirHistorico()),
          () => this.httpService.done());

    }
  }

  gravarHistorico() {
    const listasEnviar = {
      id: this.nfeFinId,
      historico: this.historico,
      dataHistorico: this.dataHistorico,
      usuario: this.sessionService.loggedUser.id
    };

   this.httpService.wait();
    this.httpService.post('/custom/financeiro/gravar-historico', listasEnviar)
      .subscribe(result => this.atualizarVoltar(),
        error => this.httpService.handleError(error, () => this.gravarHistorico()),
        () => this.httpService.done());
  }

  result(event: any) {
    console.log(event);
  }

  criarHistorico() {
    this.criarHistoricoCobranca = true;
    this.criarBaixaFinanceira = false;
    this.editarHistorico = false;
    this.editarBaixa = false;
  }

  criarBaixa() {
    this.criarHistoricoCobranca = false;
    this.criarBaixaFinanceira = true;
    this.editarHistorico = false;
    this.editarBaixa = false;
  }

  cancelar() {
    this.atualizarVoltar();
  }

  routerOnDeactivate(next, prev) {
    this.panels.forEach(cmp => cmp.cleanUp());
  }

  atualizarVoltar() {
    this.activatedRoute.params.subscribe(params => {
      if (params['tipo']) {
        this.parametro = params.tipo;
        if (params.tipo === 'cr') {
          this.title = 'POSIÇÃO DO CONTAS À RECEBER';
          this.subtitle = 'Posição do Contas à receber no período selecionado';
          this.titleService.title = 'Posição do Contas à receber';
          this.changePeriod();
        } else {
          this.title = 'POSIÇÃO DO CONTAS À PAGAR';
          this.subtitle = 'Posição do Contas à pagar no período selecionado';
          this.titleService.title = 'Posição do Contas à pagar';
          this.changePeriod();
       }
      }
    });
  }

}
