import { Empresa } from 'app/cadastro/model/empresa.model';

export class MovParteBCsll {
  id: number;
  cod: string;
  descricao: string;
  saldoInicial: number;
  natureza: string;
  data: Date;
  empresa: Empresa;

  constructor(movParteBCsll?: MovParteBCsll | number) {
    if (movParteBCsll !== undefined && movParteBCsll !== null) {
      if (typeof movParteBCsll === 'number') {
        this.id = movParteBCsll;
      } else {
        this.id = movParteBCsll.id;
        this.cod = movParteBCsll.cod;
        this.descricao = movParteBCsll.descricao;
        this.saldoInicial = movParteBCsll.saldoInicial;
        this.natureza = movParteBCsll.natureza;
        this.data = movParteBCsll.data;
        this.empresa = movParteBCsll.empresa;
      }
    }
  }
}
