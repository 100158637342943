import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild
} from '@angular/core';

import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: [
    './eventos.component.scss'
  ],
  providers: [CrudService]
})
export class ReinfEventosComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private messageService: MessageService
  ) {}

  private sessionSubscription: Subscription; // change session event;

  // *msgs;
  msgs: Message[] = [];
  msgsModal: Message[] = [];
  // this.messageService.add({
  //   severity: 'success',key: 'messageLinnks',
  //   summary: 'Sucesso',
  //   detail: 'Informações cadastradas.'
  // });

  // *data;
  defaultDate: Date;
  pt_BR: any;

  // *toolbar child;
  childDownload: Array<ToolbarButton>;
  childNovo: Array<ToolbarButton>;

  // *anexo;
  @ViewChild('file')
  fileInput: ElementRef;
  files: Array<any> = null;

  title: string;
  subtitle: string;

  // tabela;
  checkAllBtn: Boolean = false;
  idsSelecionados: any[] = [];
  lista: any[] = [];
  id: number;
  periodo: Date;
  ambiente: string;
  servico: string;
  contratante: string;
  prestadorCnpj: string;
  prestadorNome: string;
  vlrTotalBruto: number;
  vlrTotalBaseRet: number;
  vlrTotalRetPrinc: number;

  // modal consulta fechamento;
  modalR1000: Boolean = false;

  // modal 2010;
  modalR2010: Boolean = false;

  // modal 2098;
  modalR2098: Boolean = false;

  // modal 2099;
  modalR2099: Boolean = false;
  rbEvtServTm: string;
  rbEvtServPr: string;
  rbEvtAssDespRec: string;
  rbEvtAssDespRep: string;
  rbEvtComProd: string;
  rbEvtCPRB: string;
  rbEvtAquis: string;

  // modal 4020;
  modalR4020: Boolean = false;

  // modal consulta fechamento;
  modalConsultaFechamento: Boolean = false;

  // modal exportar arquivos;
  modalExport: Boolean = false;
  modalExportLabel: string;

  // modal shared;
  rbAmbiente: any;
  colsLista : any[] = [];

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    Promise.resolve(null).then(() => (this.toolbarService.hidden = false)); // gilson;
    Promise.resolve(null).then(() => this.toolbarRefresh());

    this.title = 'REINF';
    this.subtitle =
      'Escrituração Fiscal Digital de Retenções e Outras Informações Fiscais';

    // Data;
    // ------------------------------------------
    this.defaultDate = new Date();
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.colsLista = [
      { field: 'periodo', header: 'PERÍODO' },
      { field: 'status', header: 'STATUS' },
      { field: 'ambiente', header: 'AMBIENTE' },
      { field: 'prestadorNome', header: 'PRESTADOR NOME' },
      { field: 'prestadorCnpj', header: 'PRESTADOR CNPJ' },
      { field: 'contratante', header: 'CONTRATANTE' },
      { field: 'servico', header: 'SERVIÇO' },
      { field: 'vlrTotalBruto', header: 'VLR TOTAL BRUTO' },
      { field: 'vlrTotalBaseRet', header: 'VLR TOTAL BASE RET' },
      { field: 'vlrTotalRetPrinc', header: 'VLR TOTAL RET PRINC' }
    ];

    // ------------------------------------------

    // modal;
    this.modalRefresh();

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
        this.changeSession();
          }
      }
    );
  }

  ngOnDestroy() {
    this.dblinkedSessionService._showPeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  cancelar() {
    this.modalRefresh();
  }

  private changeSession() {

      this.toolbarRefresh();
      this.getR2010();
      this.toolbarR2098(false);
      this.toolbarR2099(false);

  }

  selectCheckbox(event: any) {

    this.idsSelecionados.push(event.data.id)

    this.getIdsSelecionados();
  }

  unselectCheckbox(event: any){
    const index = this.idsSelecionados.findIndex(c => c === event.data.id);
    this.idsSelecionados.splice(index, 1);

    this.getIdsSelecionados();
  }


  checkAll() {
    this.checkAllBtn = !this.checkAllBtn;

    this.idsSelecionados = [];

    if (this.checkAllBtn) {
      this.lista.forEach(rst => {
        rst.selected = true;
        this.idsSelecionados.push(rst.id);
      });
    } else {
      this.lista.forEach(rst => {
        rst.selected = false;
      });
    }

    this.getIdsSelecionados();
  }

  download() {
    const map = {
      ambiente: this.rbAmbiente,
      empresaId: this.dblinkedSessionService.empresa.id,
      month: this.dblinkedSessionService.periodo.month.value,
      year: this.dblinkedSessionService.periodo.year.value
    };

    if (this.modalExportLabel === 'PDF') {
      this.httpService.wait();
      this.httpService
        .post('/custom/reinf/download-pdf', map)
        .subscribe(
          result => this.downloadCallback(result),
          error => this.httpService.handleError(error, () => this.download()),
          () => this.httpService.done()
        );
    }

    if (this.modalExportLabel === 'XML') {
      this.httpService.wait();
      this.httpService
        .post('/custom/reinf/download-zip', map)
        .subscribe(
          result => this.downloadCallback(result),
          error => this.httpService.handleError(error, () => this.download()),
          () => this.httpService.done()
        );
    }
  }

  private downloadCallback(result: any) {
    this.modalRefresh();

    if (result.response === 'success') {
      window.open(
        this.httpService.url('/custom/file/' + result.file + '/reinf'),
        '_blank'
      );
    } else {
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Erro',
        detail: result.msg
      });
    }
  }

  private downloadR2010() {
    window.open(
      this.httpService.url(
        'https://s3.amazonaws.com/aws-website-downloads-e72og/REINF/Modelo+R-2010.xlsx'
      ),
      '_blank'
    );
  }

  private evtCallback(result: any) {
    this.modalRefresh();

    result.forEach(element => {
      this.messageService.add({
        severity: element.response === 'success' ? 'success' : 'error',
        summary: element.response === 'success' ? 'Sucesso' : 'Erro',
        detail: element.msg
      });
    });

    this.getR2010();
  }

  evtConsultaFechamento() {
    const map = {
      ambiente: this.rbAmbiente,
      empresaId: this.dblinkedSessionService.empresa.id,
      month: this.dblinkedSessionService.periodo.month.value,
      year: this.dblinkedSessionService.periodo.year.value
    };

    this.httpService.wait();
    this.httpService
      .post('/custom/reinf/consulta-lote', map)
      .subscribe(
        result => this.evtCallback(result),
        error =>
          this.httpService.handleError(error, () =>
            this.evtConsultaFechamento()
          ),
        () => this.httpService.done()
      );
  }

  evtR1000() {
    this.httpService.wait();
    this.httpService
      .post(
        '/custom/reinf/evt-r1000(' +
          this.rbAmbiente +
          ',' +
          this.dblinkedSessionService.empresa.id +
          ')'
      )
      .subscribe(
        result => this.evtCallback(result),
        error => this.httpService.handleError(error, () => this.evtR1000()),
        () => this.httpService.done()
      );
  }

  evtR2098() {
    const map = {
      empresaId: this.dblinkedSessionService.empresa.id,
      empresaCnpj: this.dblinkedSessionService.empresa.cnpj,
      month: this.dblinkedSessionService.periodo.month.value,
      year: this.dblinkedSessionService.periodo.year.value,
      ambiente: this.rbAmbiente
    };
    this.httpService.wait();
    this.httpService
      .post('/custom/reinf/evt-r2098', map)
      .subscribe(
        result => this.evtCallback(result),
        error => this.httpService.handleError(error, () => this.evtR2099()),
        () => this.httpService.done()
      );
  }

  evtR2099() {
    const map = {
      empresaId: this.dblinkedSessionService.empresa.id,
      empresaCnpj: this.dblinkedSessionService.empresa.cnpj,
      month: this.dblinkedSessionService.periodo.month.value,
      year: this.dblinkedSessionService.periodo.year.value,
      ambiente: this.rbAmbiente,
      evtServTm: this.rbEvtServTm,
      evtServPr: this.rbEvtServPr,
      evtAssDespRec: this.rbEvtAssDespRec,
      evtAssDespRep: this.rbEvtAssDespRep,
      evtComProd: this.rbEvtComProd,
      evtCPRB: this.rbEvtCPRB,
      evtAquis: this.rbEvtAquis
    };
    this.httpService.wait();
    this.httpService
      .post('/custom/reinf/evt-r2099', map)
      .subscribe(
        result => this.evtCallback(result),
        error => this.httpService.handleError(error, () => this.evtR2099()),
        () => this.httpService.done()
      );
  }

  private evtRemoveContribuinte() {
    if (
      confirm(
        'Deseja remover as informações do contribuinte do ambiente de produção restrita?'
      )
    ) {
      this.httpService.wait();
      this.httpService
        .post(
          '/custom/reinf/evt-remove-contribuinte(' +
            this.dblinkedSessionService.empresa.id +
            ',' +
            this.dblinkedSessionService.empresa.cnpj +
            ')'
        )
        .subscribe(
          result => this.evtCallback(result),
          error =>
            this.httpService.handleError(error, () =>
              this.evtRemoveContribuinte()
            ),
          () => this.httpService.done()
        );
    }
  }

  fileChange() {
    this.files = this.fileInput.nativeElement.files;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  private getIdsSelecionados() {
    if (this.idsSelecionados.length === 0) {
      this.toolbarDetalhe(true);
    } else {
      this.toolbarDetalhe(false);
    }
  }

  private getR2010() {
    this.httpService.wait();
    this.httpService
      .post(
        '/custom/reinf/get-evt-r2010(' +
          this.dblinkedSessionService.empresa.id +
          ')'
      )
      .subscribe(
        result => (this.lista = result),
        error => this.httpService.handleError(error, () => this.getR2010()),
        () => this.httpService.done()
      );
  }

  hideMessage() {


  }

  private modalRefresh() {
    this.modalExport = false;
    this.modalR1000 = false;
    this.modalR2010 = false;
    this.modalR2098 = false;
    this.modalR2099 = false;
    this.modalConsultaFechamento = false;

    // modal R-2010;
    this.files = null;
    //this.fileInput.nativeElement.value = '';

    // modal R-2099;
    this.rbEvtServTm = 'S';
    this.rbEvtServPr = 'N';
    this.rbEvtAssDespRec = 'N';
    this.rbEvtAssDespRep = 'N';
    this.rbEvtComProd = 'N';
    this.rbEvtCPRB = 'N';
    this.rbEvtAquis = 'N';

    // modal shared;
    this.rbAmbiente = '2';
  }

  private showModalR1000() {
    this.modalR1000 = true;
  }

  private showModalR2010() {
    this.modalR2010 = true;
  }

  private showModalR4020() {
    this.modalR4020 = true;
  }

  private showModalR2098() {
    this.modalR2098 = true;
  }

  private showModalR2099() {
    this.modalR2099 = true;
  }

  private showmodalConsultaFechamento() {
    this.modalConsultaFechamento = true;
  }

  private showModalExport() {
    this.modalExport = true;
  }

  private toolbarDetalhe(boo: boolean) {
    boo
      ? this.toolbarService.alterarStatus(
          'export',
          true,
          'Relatório analítico',
          () => this.toolbarDetalheCallback()
        )
      : this.toolbarService.alterarStatus(
          'export',
          false,
          'Relatório analítico',
          () => this.toolbarDetalheCallback()
        );
  }

  private toolbarDetalheCallback() {
    window.open('reinf/eventos-detalhe/' + this.idsSelecionados, '_blank');
  }

  private toolbarDownload() {
    this.childDownload = new Array();
    this.childDownload.push(
      {
        icon: '',
        label: 'Modelo R-2010',
        callback: () => this.downloadR2010()
      },
      {
        icon: '',
        label: 'Relatório consolidado do período',
        callback: () => this.toolbarExportPdfCallback()
      },
      {
        icon: '',
        label: 'Arquivos processados no período',
        callback: () => this.toolbarExportXmlCallback()
      }
    );

    this.toolbarService.get('download').child = this.childDownload;
    this.toolbarService.alterarStatus(
      'download',
      false,
      'Download de arquivos',
      () => ''
    );
  }

  private toolbarNovo() {
    this.childNovo = new Array();
    this.childNovo.push(
      {
        icon: '',
        label: 'Evento R-1000',
        callback: () => this.showModalR1000()
      },
      {
        icon: '',
        label: 'Evento R-2010',
        callback: () => this.showModalR2010()
      },
      {
        icon: '',
        label: 'Evento R-4020',
        callback: () => this.showModalR4020()
      }

    );

    this.toolbarService.get('add').child = this.childNovo;
    this.toolbarService.alterarStatus('add', false, '', () => '');
  }

  private toolbarExportPdfCallback() {
    this.modalExportLabel = 'PDF';
    this.showModalExport();
  }

  private toolbarExportXmlCallback() {
    this.modalExportLabel = 'XML';
    this.showModalExport();
  }

  private toolbarR2098(boo: boolean) {
    boo
      ? this.toolbarService.alterarStatus(
          'atualizar',
          true,
          'Reabrir período',
          () => this.showModalR2098()
        )
      : this.toolbarService.alterarStatus(
          'atualizar',
          false,
          'Reabrir período',
          () => this.showModalR2098()
        );
  }

  private toolbarR2099(boo: boolean) {

    boo
      ? this.toolbarService.alterarStatus('check', true, 'Fechar período', () =>
          this.showModalR2099()
        )
      : this.toolbarService.alterarStatus(
          'check',
          false,
          'Fechar período',
          () => this.showModalR2099()
        );
  }

  // Lista de btns (app.component.ts);
  private toolbarRefresh() {

    try {
        this.toolbarService.ocultar('voltar');
        this.toolbarService.ocultar('info');
        this.toolbarService.ocultar('msg');
        this.toolbarService.ocultar('cancel');
        this.toolbarService.ocultar('print');
        this.toolbarService.ocultar('save');
        this.toolbarService.ocultar('edit');

        this.toolbarService.alterarStatus(
          'pesquisa',
          false,
          'Consultar fechamento do período.',
          () => this.showmodalConsultaFechamento()
        );

        this.toolbarService.alterarStatus(
          'remove',
          false,
          'Remover as informações do contribuinte do ambiente de produção restrita.',
          () => this.evtRemoveContribuinte()
        );

        this.toolbarDownload();
        this.toolbarNovo();
    } catch (error) {
      console.log(error);
    }


  }

  upload() {
    if (this.files === null) {
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Erro',
        detail: 'Nenhum arquivo anexado.'
      });
    } else {
      const fileName = this.fileInput.nativeElement.value;
      console.log(fileName);
      const spt = fileName.split('.');
      console.log(spt);
      const ext = spt[spt.length - 1];
      console.log(ext);

      if (ext === 'xls' || ext === 'xlsx') {
        const data: FormData = new FormData();
        data.append('file', this.files[0]);
        //data.append('type', this.modalR4020 ? 'reinf-evt4020' : 'reinf-evt2010');
        data.append('type', 'reinf-evt4020');
        data.append(
          'empresaId',
          this.dblinkedSessionService.empresa.id.toString()
        );
        data.append('ambiente', this.rbAmbiente);

        this.httpService.wait();
        this.httpService.post('/custom/upload', data).subscribe(
          result => {
            this.modalRefresh();
            this.messageService.add({
              severity: result.response === 'success' ? 'success' : 'error',
              summary: result.response === 'success' ? 'Sucesso' : 'Erro',
              detail: result.msg
            });
            this.getR2010();
          },
          error => this.httpService.handleError(error, () => this.upload()),
          () => this.httpService.done()
        );
      } else {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'Erro',
          detail: 'Extensões válidas: xls e xlsx.'
        });
      }
    }
  }

  tabChange(event: any){

    if(event.index ==1){
      alert('4020')
    }

  }
}
