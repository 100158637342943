import { Component, OnInit, OnDestroy } from '@angular/core';
import { Empresa } from '../../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { PeriodoTaxa } from 'app/orcamento/model/taxa.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Periodo } from '../../model/periodo.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import exportExcel from 'app/util/processing/exportExcel';

declare var $: any;


@Component({
  selector: 'app-taxa-moeda',
  templateUrl: './taxa-moeda.component.html',
  styleUrls: ['./taxa-moeda.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class TaxaMoedaComponent implements OnInit, OnDestroy {
  currentStateMoeda = 'initial';
  changeStateMoeda() {
    this.currentStateMoeda = this.currentStateMoeda === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  pt: any;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  msgs: Message[];
  msgsModal: Message[];
  modalState: boolean = false;
  boolCriarPeriodoTaxa: boolean = false;
  boolEditarPeriodoTaxa: boolean = false;
  checkedAll: boolean = false;
  listaIdsSelecionados: Array<any> = [];
  selectedRates: Array<any> = [];
  OpcoesMoeda: SelectItem[];
  periodoTaxa: PeriodoTaxa;
  periodoIdSelecionado: number;
  moedaId: number = 0;
  dataInicial: Date;
  dataFinal: Date;
  idMoeda: number;
  listaTaxas: Array<any> = [];
  listaTaxasFiltradas: Array<any> = [];
  editando: boolean = false;
  padraoRegex: string;
  colslistalistaTaxas: any[] = [];


  listaPeriodoMeses = [];
  meses: Array<Periodo>;
  selectedPeriodos: Array<Periodo>;

  constructor(
    private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private messageService: MessageService) {
  }

  public toolbarMain = new Toolbar();
  toolbarMainIni = [
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.carregarTaxa() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirTaxa() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.showModalNovaTaxa() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.exportAll() }
  ]

  ngOnInit(): void {
    this.toolbarMain.setAll(this.toolbarMainIni)

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    // this.listaIdsSelecionados = new Array<any>();
    this.selectedRates = new Array<any>();
    this.modalState = false;
    this.boolCriarPeriodoTaxa = false;
    this.boolEditarPeriodoTaxa = false;
    this.checkedAll = false;
    this.titlePrincipal = 'Configurações Orçamento';
    this.subtitle = 'Taxa Moeda';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.periodoTaxa = new PeriodoTaxa();


    this.editando = false;

    this.colslistalistaTaxas = [
      { field: 'descricao_moeda', header: 'DESCRIÇÃO' },
      { field: 'taxa', header: 'TAXA' },
      { field: 'ano', header: 'PERÍODO' }
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };


  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

      // this.listaIdsSelecionados = new Array<any>();
      this.selectedRates = new Array<any>();
      this.modalState = false;
      this.boolCriarPeriodoTaxa = false;
      this.boolEditarPeriodoTaxa = false;
      this.checkedAll = false;
      this.periodoTaxa = new PeriodoTaxa();

      this.editando = false;

      this.dataInicial = null;
      this.dataFinal = null;
      this.idMoeda = 0;
      this.periodoTaxa.taxa = null;

      this.carregarMoedas();
      this.carregarOrcamentoPeriodoTaxas();
      this.handleSelect();

      // this.toolbarEnable();
      // this.checkToolBar();
      this.modalState = false;
      this.boolCriarPeriodoTaxa = false;
      this.boolEditarPeriodoTaxa = false;

    }
  }


  carregarMoedas() {
    this.httpService.wait();
    this.httpService.get('/custom/orcamento/get-moedas')
      .subscribe(ref => {
        this.OpcoesMoeda = [];
        this.OpcoesMoeda.push({ label: 'Selecione', value: 0 });
        ref.forEach(element => {
          this.OpcoesMoeda.push({ label: element.simbolo + ' - ' + element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.carregarMoedas()),
        () => this.httpService.done());
  }


  carregarOrcamentoPeriodoTaxas() {

    if (this.dblinkedSessionService.empresa.id !== null && this.dblinkedSessionService.empresa.id !== undefined) {
      this.httpService.wait();
      this.httpService.get('/custom/orcamento/get-taxas(' + this.dblinkedSessionService.empresa.id + ',' + this.dblinkedSessionService.periodo.year.value + ')')
        .subscribe(ref => {

          this.listaTaxas = [];
          this.listaTaxasFiltradas = [];
          ref.forEach(element => {
            element.taxa = this.utilService.parseDecimalString6(element.taxa);

            if (element.periodo_mes === '01' || element.periodo_mes === '1') {
              element.periodo_mes = 'Janeiro';
            }
            if (element.periodo_mes === '02' || element.periodo_mes === '2') {
              element.periodo_mes = 'Fevereiro';
            }
            if (element.periodo_mes === '03' || element.periodo_mes === '3') {
              element.periodo_mes = 'Março';
            }
            if (element.periodo_mes === '04' || element.periodo_mes === '4') {
              element.periodo_mes = 'Abril';
            }
            if (element.periodo_mes === '05' || element.periodo_mes === '5') {
              element.periodo_mes = 'Maio';
            }
            if (element.periodo_mes === '06' || element.periodo_mes === '6') {
              element.periodo_mes = 'Junho';
            }
            if (element.periodo_mes === '07' || element.periodo_mes === '7') {
              element.periodo_mes = 'Julho';
            }
            if (element.periodo_mes === '08' || element.periodo_mes === '8') {
              element.periodo_mes = 'Agosto';
            }
            if (element.periodo_mes === '09' || element.periodo_mes === '9') {
              element.periodo_mes = 'Setembro';
            }
            if (element.periodo_mes === '10') {
              element.periodo_mes = 'Outubro';
            }
            if (element.periodo_mes === '11') {
              element.periodo_mes = 'Novembro';
            }
            if (element.periodo_mes === '12' || element.periodo_mes === '0') {
              element.periodo_mes = 'Dezembro';
            }
            this.listaTaxas.push(element);
            this.listaTaxasFiltradas.push(element);
          });
        },
          error => this.httpService.handleError(error, () => this.carregarOrcamentoPeriodoTaxas()),
          () => this.httpService.done());
      this.verificarPeriodoExistente();
    }
  }


  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {
    this.msgs = [];
    this.msgsModal = [];
  }

  // private toolbarEnable() {
  //   this.toolbarService.ocultar('voltar');
  //   this.toolbarService.ocultar('download');
  //   this.toolbarService.ocultar('cancel');
  //   this.toolbarService.ocultar('check');
  //   this.toolbarService.ocultar('info');
  //   this.toolbarService.ocultar('msg');
  //   this.toolbarService.ocultar('more_vert');
  //   this.toolbarService.ocultar('export');
  //   this.toolbarService.ocultar('print');
  //   this.toolbarService.ocultar('save');
  //   this.toolbarService.ocultar('pesquisa');
  //   // this.toolbarService.ocultar('edit');
  //   // this.toolbarService.ocultar('add');
  //   // this.toolbarService.ocultar('remove');
  //   this.toolbarService.ocultar('atualizar');
  // }


  criarNovaTaxa() {
    if (this.validacao(this.boolCriarPeriodoTaxa = true)) {
      return;
    }
    if (this.selectedPeriodos.length > 0) {
      const listaEnviar = this.selectedPeriodos.map((periodo) => {
        return {
          moedaId: this.idMoeda,
          taxa: this.utilService.parseDecimal6(this.periodoTaxa.taxa),
          data: periodo.dtMesRef,
          empresaId: this.empresa.id,
          usuario: this.sessionService.loggedUser.id
        };
      });
      this.httpService.wait();
      this.httpService.post('/custom/orcamento/criar-taxa-orcamento', listaEnviar)
        .subscribe(rst => {
          this.displayMessage(rst);
          this.selectedPeriodos = [];
          this.change();
        },
          error => this.httpService.handleError(error, () => this.criarNovaTaxa()),
          () => this.httpService.done());
    } else {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Nenhum período selecionado.' });
    }
  }

  handleSelect(): void {
    this.toolbarMain.setVisible("btn1", this.selectedRates.length  === 1 ? true : false);
    this.toolbarMain.setVisible("btn2", this.selectedRates.length  >= 1 ? true : false);
  }

  selectTodos() {
    this.checkedAll = !this.checkedAll;

    this.listaTaxasFiltradas.forEach(e => {
      if (this.checkedAll) {
        const i = this.listaTaxasFiltradas.findIndex(p => p === e);
        this.listaTaxasFiltradas[i].selected = true;
        this.handleSelect();

      } else {
        this.listaTaxasFiltradas.forEach(e => {
          e.selected = false;
          const i = this.listaTaxasFiltradas.findIndex(p => p === e);
        this.handleSelect();
      });
      }
    });
  }

  excluirTaxa() {
    if(this.selectedRates.length > 0) {
      let idList = [];
      this.selectedRates.forEach(item => {
        idList.push(item.id);
      });
      if (confirm(`Você tem CERTEZA que deseja excluir o${idList.length > 1 ? 's' : ''} registro${idList.length > 1 ? 's' : ''} selecionado${idList.length > 1 ? 's' : ''}?`)) {

        const listaEnviar = {
          taxaId: idList
        };

        this.httpService.wait();
        this.httpService.post('/custom/orcamento/excluir-taxa-orcamento', listaEnviar)
          .subscribe(rst => {
            this.displayMessage(rst);
            this.change();
          },
            error => this.httpService.handleError(error, () => this.excluirTaxa()),
            () => this.httpService.done());
      }
    }
  }


  carregarTaxa() {
    this.modalState = true;
    this.boolCriarPeriodoTaxa = false;
    this.boolEditarPeriodoTaxa = true;
    this.editando = true;

    this.httpService.wait();
    this.httpService.get('/custom/orcamento/get-taxa-selecionada-pelo-id(' + this.selectedRates[0].id + ')')
      .subscribe(ref => {

        this.periodoTaxa.id = ref.id;
        this.periodoTaxa.periodo = ref.periodo_id;
        this.idMoeda = ref.moeda_id;
        this.periodoTaxa.taxa = this.utilService.parseDecimalString6(ref.taxa);
        this.dataInicial = ref.periodo_data_inicial;
        this.dataFinal = ref.periodo_data_final;
      },
        error => this.httpService.handleError(error, () => this.carregarTaxa()),
        () => this.httpService.done());
  }


  editarTaxa() {
    if (confirm('Tem CERTEZA que deseja modificar o registro? Esta alteração pode impactar diretamente nos dados do orçamento.')) {
      if (this.validacao(this.boolCriarPeriodoTaxa = false)) {
        return;
      }
    }

    const listaEnviar = {
      taxaId: this.periodoTaxa.id,
      moedaId: this.idMoeda,
      taxa: this.utilService.parseDecimal6(this.periodoTaxa.taxa),
      periodoId: this.periodoTaxa.periodo,
      empresaId: this.empresa.id,
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/editar-taxa-orcamento', listaEnviar)
      .subscribe(rst => {
        this.displayMessage(rst);
        this.change();
      },
        error => this.httpService.handleError(error, () => this.editarTaxa()),
        () => this.httpService.done());
  }

  cancelar() {
    this.modalState = false;
    this.boolCriarPeriodoTaxa = false;
    this.boolEditarPeriodoTaxa = false;
  }

  validacao(criarTaxa) {

    if (this.idMoeda === null || this.idMoeda === undefined || this.idMoeda === 0) {
      //this.exibirMensagem({ error: true, mensagem: 'Favor selecionar a moeda!' });
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Favor selecionar a moeda!' });
      return true;
    }

    if (this.periodoTaxa.taxa === null || this.periodoTaxa.taxa === undefined) {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Favor inserir a taxa!' });
      //this.exibirMensagem({ error: true, mensagem: 'Favor inserir a taxa!' });
      return true;
    }

    if (!(/^[0-9]{1,4}(\,[0-9]{1,6})?$/.test(this.periodoTaxa.taxa))) {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Taxa inválida! Seguir o seguinte formato: 0,000001'});
      //this.exibirMensagem({ error: true, mensagem: 'Taxa inválida! Seguir o seguinte formato: 0,000001' });
      return true;
    }

    if (this.periodoTaxa.taxa === null || this.periodoTaxa.taxa === undefined) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor inserir a taxa!'
      });
      return true;
    }

    if (!(/^[0-9]{1,4}(\,[0-9]{1,6})?$/.test(this.periodoTaxa.taxa))) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Taxa inválida! Seguir o seguinte formato: 0,000001'
      });
      return true;
    }
    if (criarTaxa) {
      if (this.selectedPeriodos.length === 0) {
        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail:  'Para continuar, escolha um período.'});
        //this.exibirMensagem({ error: true, mensagem: 'Para continuar, escolha um período.' });
        return true;
      }
    }
    return false;
  }

  verificarPeriodoExistente() {
    this.httpService.wait();
    this.httpService.get('/custom/orcamento/get-periodo-anos(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(ref => {

        this.meses = [];
        if (ref.length === 0) {
          this.displayMessage(ref);
        } else {

          ref.forEach(periodo => {

            const fullDate1 = new Date(`${periodo.ano_inicial}-${periodo.mes_incial}-1`);
            const fullDate2 = new Date(`${periodo.ano_final}-${periodo.mes_final}-1`);

            let comparingMonth = true;
            let count = periodo.idInicio;
            let seqMes = 1;
            while (comparingMonth === true) {
              if (fullDate1 <= fullDate2) {
                this.meses.push({
                  id: count,
                  dataFormatada: `${this.pt.monthNames[fullDate1.getMonth()]}/${fullDate1.getFullYear()}`,
                  seqMes: seqMes,
                  dtMesRef: new Date(fullDate1.getFullYear(), fullDate1.getMonth(), 1),
                  empresa: this.dblinkedSessionService.empresa,
                  ano: fullDate1.getFullYear(),
                });
                fullDate1.setMonth(fullDate1.getMonth() + 1);
                seqMes++;
                count++;
              } else {
                comparingMonth = false;
              }
            }
          });
        }
      }
      );
    this.httpService.done();
  }

  /*exibirMensagem(value: any) {
    if (!value.error) {
      this.msgs = [];
      this.msgs.push({ severity: 'success', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.msgs = [];
      this.msgs.push({ severity: 'warn', summary: 'Erro', detail: value.mensagem });
    }
  }*/


  showModalNovaTaxa() {
    this.modalState = true;
    this.boolCriarPeriodoTaxa = true;
    this.periodoTaxa = new PeriodoTaxa();
  }

  onFilter(event) {
    this.checkedAll = false;

    this.listaTaxas.forEach(tx => {
      tx.selected = false;
    });

    this.listaTaxasFiltradas = new Array();
    this.listaTaxasFiltradas = event.filteredValue;
  }

  /*Show Hide Taxa Moeda*/
  isCardMoeda: boolean = true;
  ShowHideMoeda() {
    this.isCardMoeda = !this.isCardMoeda;
  }

  closeDialog() {
    this.modalState = false;
    this.boolCriarPeriodoTaxa = false;
    this.boolEditarPeriodoTaxa = false;
    this.change();
  }

  //novo método para exibir resposta
  displayMessage(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  exportAll(): void {
    const orderList = ['descricao', "taxa", "periodo"]
    let exportList = [];
    this.listaTaxas.forEach(item => {
      exportList.push({
        descricao: item.descricao_moeda,
        taxa: item.taxa,
        periodo: item.periodo_ano
      });
    });
    exportExcel(exportList, "Taxa Moeda", orderList);
  }
}
