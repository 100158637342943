import { TbCsllN660 } from 'app/ecf/model/tb-csll-n660.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { TbIndRelacao } from 'app/ecf/model/tb-ind-relacao.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';

export class MovParteADeducoesCsll {
  id: number;
  data: Date;
  hist: string;
  valor: number;
  tbCsllN660: TbCsllN660 = new TbCsllN660();
  planoContas?: PlanoContas = new PlanoContas();
  cadCcus?: CadCcus = new CadCcus();
  tbIndRelacao?: TbIndRelacao = new TbIndRelacao();
  cadEcd: CadEcd;

  constructor(movParteADeducoesCsll?: MovParteADeducoesCsll | number) {
    if (movParteADeducoesCsll !== undefined && movParteADeducoesCsll !== null) {
      if (typeof movParteADeducoesCsll === 'number') {
        this.id = movParteADeducoesCsll;
      } else {
        this.id = movParteADeducoesCsll.id;
        this.data = movParteADeducoesCsll.data ? new Date(movParteADeducoesCsll.data) : null;
        this.hist = movParteADeducoesCsll.hist;
        this.valor = movParteADeducoesCsll.valor;
        this.tbCsllN660 = movParteADeducoesCsll.tbCsllN660 || new TbCsllN660();
        this.planoContas = movParteADeducoesCsll.planoContas || new PlanoContas();
        this.tbIndRelacao = movParteADeducoesCsll.tbIndRelacao || new TbIndRelacao();
        this.cadEcd = movParteADeducoesCsll.cadEcd || new CadEcd();



      }
    }
  }
}
