import { IndSitEsp } from 'app/util/model/ind-sit-esp.model';
import { IndNire } from 'app/util/model/ind-nire.model';
import { IndFinEsc } from 'app/util/model/ind-fin-esc.model';
import { IndGrandePorte } from 'app/util/model/ind-grande-porte.model';
import { TipoEcd } from 'app/util/model/tipo-ecd.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { IndSitIniPer } from 'app/util/model/ind-sit-ini-per.model';
import { Status } from 'app/util/model/status.model';
import { AuthUser } from 'app/proof/model/auth-user.model';

export class CadEcd {
  id: number;
  indSitEsp: IndSitEsp = new IndSitEsp();
  indNire: IndNire = new IndNire();
  indSitIniPer: IndSitIniPer = new IndSitIniPer();
  indFinEsc: IndFinEsc = new IndFinEsc();
  codHashSub: string;
  indGrandePorte: IndGrandePorte = new IndGrandePorte();
  tipoEcd: TipoEcd = new TipoEcd();
  codScp: string;
  empresa: Empresa = new Empresa();
  dtIni: Date;
  dtFin: Date;
  status: Status;
  statusOrcamento: Status;
  apuracaoUser: AuthUser;
  apuracaoDatetime: Date;

  constructor(cadecd?: CadEcd | number) {
    if (cadecd !== undefined && cadecd !== null) {
      if (typeof cadecd === 'number') {
        this.id = cadecd;
      } else {
        this.id = cadecd.id;
        this.indSitEsp = cadecd.indSitEsp || new IndSitEsp();
        this.indNire = cadecd.indNire || new IndNire();
        this.indSitIniPer = cadecd.indSitIniPer || new IndSitIniPer();
        this.indFinEsc = cadecd.indFinEsc || new IndFinEsc();
        this.codHashSub = cadecd.codHashSub;
        this.indGrandePorte = cadecd.indGrandePorte || new IndGrandePorte();
        this.tipoEcd = cadecd.tipoEcd || new TipoEcd();
        this.codScp = cadecd.codScp;
        this.empresa = cadecd.empresa || new Empresa();
        this.dtIni = cadecd.dtIni;
        this.dtFin = cadecd.dtFin;
        this.statusOrcamento = cadecd.statusOrcamento;
        this.apuracaoUser = cadecd.apuracaoUser || new AuthUser();
        this.apuracaoDatetime = cadecd.apuracaoDatetime;
      }
    }
  }
}
