import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

import { Util } from 'app/shared/common/util';

import { ConfigService } from './config.service';
import { JsogService } from './jsog.service';
import { ToolbarService } from './toolbar.service';
import { JsonHeader } from 'app/shared/model/json-header.model';
import { List } from 'app/shared/model/list.model';

import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Injectable()
export class HttpService {
  private loadingThreads = 0;
  private offlineTimeout: any = null;

  private _offline = false;
  public offline = true;
  public loading = false;

  public authenticated = false;
  public authenticate: Subject<boolean> = new Subject();

  public OFFLINE = 0;
  public OK = 200;
  public CREATED = 201;
  public ACCEPTED = 202;
  public NO_CONTENT = 204;
  public UNAUTHENTICATED = 401;
  public NOT_FOUND = 404;

  public jsonHeader: JsonHeader;

  public connected = new Subject();

  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private jsogService: JsogService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.offline = this.configService.useSocket;
    this.jsonHeader = new JsonHeader(configService);
  }

  jsonParse(data: any): any {
    return this.jsogService.decode(data);
  }

  jsonStringify(data: any): string {
    return this.jsogService.stringify(data);
  }

  handleData(response: any): void {
    if (
      response !== null &&
      (response.status < 200 || response.status >= 300)
    ) {
      throw new Error('Response status: ' + response.status);
    }

    this.goOnline();

    if (response === null || response.status === this.NO_CONTENT) {
      return null;
    }

    return this.jsonParse(response) || new Array();
  }

  handleError(
    error: any,
    offlineCallback?: Function,
    errorCallback?: Function,
    application?: string
  ): any {
    return this._handleError(
      null,
      null,
      error,
      offlineCallback,
      null,
      errorCallback,
      application
    );
  }
  handleCrudError(
    crudTitle: string,
    source: Array<DataControl>,
    error: any,
    offlineCallback?: Function,
    errorCallback?: Function,
    application?: string
  ): any {
    return this._handleError(
      source,
      null,
      error,
      offlineCallback,
      crudTitle,
      errorCallback,
      application
    );
  }
  handleFormError(
    formTitle: string,
    formGroup: FormGroup,
    error: any,
    offlineCallback?: Function,
    errorCallback?: Function,
    application?: string
  ): any {
    return this._handleError(
      null,
      formGroup,
      error,
      offlineCallback,
      formTitle,
      errorCallback,
      application
    );
  }
  private _handleError(
    source: Array<DataControl>,
    formGroup: FormGroup,
    error: any,
    offlineCallback?: Function,
    title?: string,
    errorCallback?: Function,
    application?: string
  ): any {
    this.done();

    switch (parseInt(error.status, 10)) {
      case this.OK:
      case this.OFFLINE:
        if (!this._offline) {
          this.goOffline(application, offlineCallback);
        }

        return Observable.throw('Não foi possível conectar-se ao servidor.');

      case this.UNAUTHENTICATED:
        let err = null;

        if (this.authenticated) {
          // TODO: REVIEW LOGIN (update user permissions => redirect to login (?))
          err = this.handleMessage(
            source,
            formGroup,
            'Usuário não possui permissão para executar esta ação.',
            errorCallback,
            title
          );
        }

        this.router.navigate([
          this.configService.loginPath.substr(1),
          {
            // url: 'this.loginUrlHandle(this.router.url)',
            error: JSON.stringify(err || Observable.throw(error.status)),
            login: true
          }
        ]);

        return err || Observable.throw(error.status);

      case this.NOT_FOUND:
        /*let url: string = this.router.url;
        if (url.lastIndexOf('/') > 0) {
          url = url.substr(0, url.lastIndexOf('/'));
        }
        this.router.navigate ([url]);*/

        return this.handleMessage(
          source,
          formGroup,
          'URL inválida.',
          errorCallback,
          title
        );

      default:
        return this.handleMessage(
          source,
          formGroup,
          error,
          errorCallback,
          title
        );
    }
  }
  private handleMessage(
    source: Array<DataControl>,
    formGroup: FormGroup,
    error: any,
    errorCallback: Function,
    title: string
  ): any {
    let errorMessage =
      typeof error === 'string' ? error : this.httpErrorMessage(error);

    if (typeof errorCallback === 'function') {
      errorCallback(errorMessage);
    } else {
      if (source && errorMessage) {
        if (errorMessage.trim().startsWith('Duplicate entry')) {
          const values = errorMessage
            .replace('Duplicate entry', '')
            .trim()
            .split(' for key ');
          let value = values[0].trim().substr(1, values[0].trim().length - 2);
          const constraintName = values[1]
            .trim()
            .substr(1, values[1].trim().length - 2);

          let label = '';
          if (!source) {
            // TODO: Use formGroup
          } else {
            const constraintControl = source.find(
              control => control.constraintName === constraintName
            );
            label = constraintControl
              ? constraintControl.label
              : constraintName;

            if (constraintControl && constraintControl.getFormControl()) {
              value = constraintControl.getFormControl().value;
            } else if (formGroup) {
              value = formGroup.get(constraintControl.key).value;
            }
          }

          if (title && title.trim().length > 1) {
            switch (
              title
                .toLowerCase()
                .trim()
                .substr(0, 2)
            ) {
              case 'o ':
                title = title
                  .toLowerCase()
                  .trim()
                  .substr(1);
                break;
              case 'a ':
                break;
              default:
                title = ' ' + title.toLowerCase().trim();
            }
          } else {
            title = ' registro';
          }

          if (label) {
            errorMessage =
              'Já existe um' +
              title +
              ' com valor "' +
              value +
              '" para o campo ' +
              label.trim() +
              '.';
          }
        }
      }

      alert(errorMessage); // TODO: Use Angular Material dialog
    }

    return Observable.throw(errorMessage);
  }

  loginUrlHandle(url: string): string {
    if (
      url === undefined ||
      url === null ||
      url.trim() === '' ||
      url.trim().indexOf(this.configService.logoutPath) === 1 ||
      url.trim().indexOf(this.configService.loginPath) === 1
    ) {
      url = '/';
    }

    return url;
  }

  get(path: string, key?: any, application?: string): Observable<any> {
    return this.httpClient
      .get(
        this.url(path, key, application),
        this.jsonHeader.options(application)
      )
      .map(result => this.handleData(result));
  }

  post(
    path: string,
    data?: FormData | any,
    application?: string
  ): Observable<any> {
    if (!(data instanceof FormData)) {
      data = this.jsonStringify(data);
    }

    return this.httpClient
      .post(
        this.rootUrl(path, null, application),
        data,
        this.jsonHeader.options(application, data)
      )
      .map(result => this.handleData(result));
  }

  // criado por heitor 26/11/2018;
  // post para gravação no banco | workflow;
  postdb(path: string, data: any | any, application?: string): Observable<any> {
    // if (!(data instanceof FormData)) {
    data = this.jsonStringify(data);
    // }

    return this.httpClient
      .post(
        this.rootUrl(path, null, application),
        data,
        this.jsonHeader.options(application, data)
      )
      .map(result => this.handleData(result));
  }

  put(
    path: string,
    key: any,
    data: any,
    application?: string
  ): Observable<any> {
    return this.httpClient.put(
      this.rootUrl(path, key, application),
      this.jsonStringify(data),
      this.jsonHeader.options(application, this.jsonStringify(data))
    );
  }

  patch(
    path: string,
    key: any,
    data: any,
    application?: string
  ): Observable<any> {
    return this.httpClient.patch(
      this.rootUrl(path, key, application),
      this.jsonStringify(data),
      this.jsonHeader.options(application, this.jsonStringify(data))
    );
  }

  delete(path: string, key?: any, application?: string): Observable<any> {
    return this.httpClient.delete(
      this.rootUrl(path, key, application),
      this.jsonHeader.options(application)
    );
  }

  url(path: string, key?: any, application?: string): string {
    let url: string = path;
    let params = '';

    if (url.indexOf('?') > -1) {
      params = url.substr(url.indexOf('?'));
      url = url.substr(0, url.indexOf('?'));
    }

    if (url.indexOf('http') !== 0) {
      url =
        Util.fixUrlPart(
          this.configService.apiUrls[
            application || this.configService.defaultApplication
          ]
        ) + Util.fixUrlPart(url);
    }

    url = Util.fixUrlPart(url);

    if (url.lastIndexOf('/') === url.length - 1) {
      url = url.trim().substr(0, url.length - 1);
    }

    if (key !== undefined && key !== null) {
      if (Util.isArray(key)) {
        url += '(';

        for (let i = 0; i < key.length; i++) {
          url += key[i];
          if (i + 1 < key.length) {
            url += ',';
          }
        }

        url += ')';
      } else {
        url += '(' + key + ')';
      }
    }

    url += params;

    return url;
  }

  rootUrl(path: string, key?: any, application?: string): string {
    const url = this.url(path, key, application);
    return url.indexOf('?') > -1 ? url.substr(0, url.indexOf('?')) : url;
  }

  setHeaderAuthorization(token: string) {
    this.jsonHeader.authorizationToken = token;

    const oldAuthenticated: boolean = this.authenticated;
    this.authenticated = token !== undefined && token !== null;
    if (oldAuthenticated !== this.authenticated) {
      this.authenticate.next(this.authenticated);
    }
  }

  wait(count: number = 1) {
    if (this.loadingThreads < 0) {
      this.loadingThreads = 0;
    }
    this.loadingThreads += count;
    if (this.configService.production) {
      this.loading = true;
    } else {
      setTimeout(() => (this.loading = true), 1);
    }
  }

  done(count: number = 1) {
    this.loadingThreads -= count;
    if (this.loadingThreads < 1) {
      if (this.configService.production) {
        this.loading = false;
      } else {
        setTimeout(() => (this.loading = false), 1);
      }
    }
  }

  goOnline(callback?: Function): void {
    if (!this._offline && !this.offline) {
      return;
    }

    clearTimeout(this.offlineTimeout);
    this.offline = false;
    this._offline = false;
    this.connected.next(true);

    if (callback && typeof callback === 'function') {
      callback();
    }
  }

  goOffline(application: string, callback?: Function): void {
    if (this._offline) {
      return;
    }

    this._offline = true;
    this.connected.next(false);

    this._goOffline(application, callback);
  }
  private _goOffline(application: string, callback?: Function): void {
    this._offline = true;
    this.offline = true;

    clearTimeout(this.offlineTimeout);
    this.offlineTimeout = setTimeout(
      () => this.checkOffline(application, callback),
      this.configService.onlineCheckInterval
    );
  }

  checkOffline(application: string, callback?: Function): void {
    this.httpClient
      .get(
        this.url(this.configService.proofAuthUrl),
        this.jsonHeader.options(application, null, 'text')
      )
      .subscribe(
        data => this.goOnline(callback),
        error => this._goOffline(application, callback)
      );
  }

  httpErrorMessage(httpErrorMessage: any, defaultMessage?: string): string {
    const _defaultMessage = 'Erro desconhecido.';

    if (typeof httpErrorMessage === 'string') {
      return httpErrorMessage;
    }

    try {
      return (
        (httpErrorMessage.error ? httpErrorMessage.error.message : null) ||
        defaultMessage ||
        _defaultMessage
      );
    } catch (exception) {
      if (
        typeof httpErrorMessage._body === 'string' &&
        httpErrorMessage._body.trim() !== ''
      ) {
        return httpErrorMessage._body;
      }

      return defaultMessage || httpErrorMessage.status !== undefined
        ? 'Erro #' + httpErrorMessage.status
        : _defaultMessage;
    }
  }

  loadLists(lists: Array<List>, callback: Function = null) {
    this.loadList(
      lists[0].url,
      lists[0].destination,
      lists.length > 1
        ? () => this.loadLists(lists.slice(1), callback)
        : typeof callback === 'function'
        ? callback()
        : null
    );
  }

  // TODO: Initialize (new) array
  loadList(url: string, list: Array<any>, callback: Function = null) {
    this.wait();
    this.get(url).subscribe(
      result => {
        list.length = 0;
        result.value.forEach(value => {
          list.push(value);
        });

        if (typeof callback === 'function') {
          callback();
        }
      },
      error =>
        this.handleError(error, () => this.loadList(url, list, callback)),
      () => this.done()
    );
}
}
