import { ToolbarOption } from "./toolbarOption";

export class Toolbar {

  /*
    Estrutura inicial do botão
    { icon: '',  text: '', tooltip: '', visible: false, disabled: false, color: ( 'green', 'default', 'red' ), onClick: () => {} }

    Estrutura inicial do dropdown
    { icon: '', text: '', tooltip: '', visible: false, disabled: false, color: ( 'green', 'default', 'red' ), dropdown: true,
      options: [
        { label: '', callback: () => {} },
      ]
    },
  */

  buttons: Array<ToolbarOption>;

  constructor() { }

  //define todos os valores iniciais da toolbar
  setAll(arr) {
    this.buttons = new Array()
    this.buttons = arr.map((item, index) => {
      let option = new ToolbarOption(item);
      if (option.key.length == 0) option.key = `btn${index + 1}`;
      return option;
    });
  }

  //define o texto de um botao específico
  setText(key: any, text: string) {
    this.buttons.forEach((btn) => {
      if (btn.key == key) {
        btn.text = text;
      }
    });
  };

  //define a visibilidade de um botao específico
  setVisible(key: any, visible: boolean) {
    this.buttons.forEach((btn) => {
      if (btn.key == key) {
        btn.visible = visible;
      }
    });
  };

  //define se um botão específico está habilitado
  setDisabled(key: any, disabled: boolean) {
    this.buttons.forEach((btn) => {
      if (btn.key == key) {
        btn.disabled = disabled;
      }
    });
  };

  //define o método de um botão específico
  setClick(key: any, callback: Function) {
    this.buttons.forEach((btn) => {
      if (btn.key == key) {
        btn.onClick = () => {
          callback()
        };
      }
    });
  };

  //define o icone de um botão específico
  setIcon(key: any, icon: string) {
    this.buttons.forEach((btn) => {
      if (btn.key == key) {
        btn.icon = icon
      }
    });
  };

  //define o tooltip de um botão específico
  setTooltip(key: any, tooltip: string) {
    this.buttons.forEach((btn) => {
      if (btn.key == key) {
        btn.tooltip = tooltip
      }
    });
  };

  // altera as opcoes do botao caso seja um dropdown
  setOptions(key: any, opt: any) {
    this.buttons.forEach(btn => {
      if (btn.key == key) {
        btn.options = opt
      }
    });
  };

  // altera todas as propriedades do botão
  setProps(key: any, props: any) {
    let position = null;
    this.buttons.forEach((btn, index) => {
      if (btn.key == key) {
        position = index
      }
    });
    if (position != null) this.buttons[position] = new ToolbarOption({ ...props, key })
  };

  setOptionClick(key: string, optionKey: string, callback: any) {
    this.buttons.forEach(btn => {
      if (btn.key == key) {
        if (btn.options)
          btn.options.forEach(option => {
            if (option.optionKey == optionKey) {
              option.callback = () => {
                callback()
              };
            }
          })
      }
    });
  };

  setOptionVisible(key: string, optionKey: string, visible: boolean) {
    this.buttons.forEach(btn => {
      if (btn.key == key) {
        if (btn.options)
          btn.options.forEach(option => {
            if (option.optionKey == optionKey) {
              option.visible = visible;
            }
          })
      }
    });
  };

  //retorna os botões
  getButtons() {
    return this.buttons;
  };

}
