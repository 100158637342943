import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CountryService {

  constructor(private http: HttpClient) { }

  getCountries(): Observable<any> {
    return this.http.get("https://raw.githubusercontent.com/cristianoascari/paises-no-formato-JSON/master/countriesJson_ptBR.json");
  }

}
