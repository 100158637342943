import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { SessionService } from 'app/shared/services/session.service'
import { SidebarService } from 'app/shared/services/sidebar.service'
import { MessageService, SelectItem } from 'primeng/api'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { HttpService } from 'app/shared/services/http.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { Subscription } from 'rxjs'
import { PysocketService } from 'app/shared/services/socket/pysocket.service'
import { Method } from 'app/shared/services/socket/protocol/Method'
import { Action } from 'app/shared/services/socket/protocol/Action'
import { Service } from 'app/shared/services/socket/protocol/Service'
import { ConfigService } from 'app/shared/services/config.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({
      })),
      state('final', style({
        opacity: '0',
        height: '0',
        visibility: 'collapse'
      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class SidebarComponent implements OnInit {

  private sessionSubscription: Subscription
  private messagesSubscription: Subscription

  comment: String = ''
  filterPlaceholder: String

  contentFilter
  tooltipApprove
  tooltipRefuse

  userId: number
  originSelected: number
  itensSelected: number = 0
  tab: number
  msgEdited: number
  clicksComment: number = 0
  clicksActivities: number = 0
  showingOff: number = 5
  totalValue: number
  cardApprover: number

  display: boolean = false
  showPreview: boolean = false
  showComments: boolean = false
  showAttachments: boolean = false
  showActivity: boolean = false
  isWriteMessage: boolean = false
  isNewFile: boolean = false
  approvIsSelected: boolean = false
  isCollapseAll: boolean = false
  isEditMessage: boolean = false
  isLoading: boolean = false
  btnLoading: boolean = false
  mentiosIsDoc: boolean = false
  withComments: boolean = true
  onlyApprov: boolean = false
  canApprove: boolean = false
  notFoundCard: boolean = false
  isFinancial: boolean = false
  isGeneratePdf: boolean = false

  rowsSelected: Array<any> = new Array()
  cloneRowsSelected: Array<any> = new Array()
  cardsSelected: Array<any> = new Array()
  filterSelected: Array<any> = new Array()
  messages: Array<any> = new Array()
  attachments: Array<any> = new Array()
  activities: Array<any> = new Array()
  cloneMessages: Array<any> = new Array()
  cloneAttachments: Array<any> = new Array()
  cloneActivities: Array<any> = new Array()
  currentAttachmentState: Array<any> = new Array()
  attachmentState: Array<any> = new Array()
  currentActivityState: Array<any> = new Array()
  activityState: Array<any> = new Array()
  currentPreviewState: Array<any> = new Array()
  previewState: Array<any> = new Array()
  itensApprov: Array<any> = new Array()
  documentList: Array<any> = new Array()
  mentionOptions: Array<any> = new Array()
  mentionList: Array<any> = new Array()
  itemApportionment: Array<any> = new Array()
  newRows: Array<any> = new Array()
  approverSelected: Array<any> = new Array()

  filterOptions: SelectItem[]
  sections: SelectItem[]
  origins: SelectItem[]

  file: File = null

  cursorInfo: any

  @ViewChild('file') fileInput: ElementRef
  @ViewChild('fileChoosen') fileName: ElementRef<HTMLSpanElement>
  @ViewChild("backdrop") $backdrop: ElementRef<HTMLDivElement>
  @ViewChild("highlights") $highlights: ElementRef<HTMLDivElement>
  @ViewChild("textarea") $textarea: ElementRef<HTMLTextAreaElement>
  @ViewChild("comments") $comments: ElementRef<HTMLDivElement>

  constructor(
    private sidebarService: SidebarService,
    private sessionService: SessionService,
    private messageService: MessageService,
    private httpService: HttpService,
    private dblinkedSessionService: DblinkedSessionService,
    private socketService: PysocketService,
    private configService: ConfigService,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.initialize()
      }
    })

    this.sidebarService.display.subscribe(item => this.display = item)

    this.sidebarService.rowsSelected.subscribe(item => {
      this.cardsSelected = item

      this.isLoading = true
      if (this.cardsSelected.length > 0) this.getInformations()

      this.messages = new Array()
      this.itemApportionment = new Array()
      this.attachments.forEach(item => {
        item.files = new Array()
      })
      this.cloneAttachments.forEach(item => {
        item.files = new Array()
      })
      this.clicksComment = 0
      this.clicksActivities = 0

      this.documentList = new Array()
    })

    this.messagesSubscription = this.socketService.socketData.subscribe((response) => {
      if (response.service === Service.APPROVAL_CENTER) {
        switch (response.action) {
          case Action.COMMENT:
            this.onComments(response)
            break
          case Action.ATTACHMENT:
            this.onAttachments(response)
            break
          case Action.ACTIVITY:
            this.onActivities(response)
            break
          case Action.APPROVE:
            if (response.method === Method.GET) this.onApprovements(response)
            break
        }
      }
    })
  }

  initialize(): void {
    this.userId = this.sessionService.loggedUser.id
    this.changeTab(0)
    this.getOrigins()
    this.sidebarService.isReady.next(true)
  }

  async getInformations() {
    const payload = {
      listCardId: this.cardsSelected
    }

    this.isFinancial = false

    try {
      await this.httpService.post('/custom/workflow/detalhe-card-aprovacao', payload).toPromise().then(res => {
        if (!res.error) {
          this.rowsSelected = res
          this.cloneRowsSelected = this.rowsSelected
          this.changeTab(this.sidebarService.tabSelected)
          this.notFoundCard = false
          this.formatInformations()

        } else this.notFoundCard = true
      })

    } catch (err) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Ocorreu um erro ao carregar a aprovação! Contate o suporte.' })
    }

    this.isLoading = false
    this.btnLoading = false
  }

  async approve(isApprove: boolean): Promise<void> {
    if (this.rowsSelected.length === 1) {
      this.itensApprov.push(this.rowsSelected[0].cardAprovacaoId)
    }

    if (this.validationApprov()) {
      return
    }

    const payload = {
      userId: this.userId,
      aprovar: isApprove,
      empresaId: this.rowsSelected[0].empresaId,
      listTodoCardId: this.itensApprov
    }

    try {
      await this.httpService.post('/custom/workflow/aprovar-recusar-card', payload).toPromise().then(res => {

        this.exibirMensagem(res);

        if (!res.error) {
          let aux = []

          res.listStatusCard.forEach(item => {
            aux.push(item.todoCardAprovacaoId)
            const payload = {
              service: Service.APPROVAL_CENTER,
              action: Action.APPROVE,
              method: Method.PUT,
              content: {
                message: isApprove ? 'aprovada' : 'recusada',
                status: item.newStatus,
                stage: item.newEtapa,
                userId: this.userId,
                cardId: item.todoCardAprovacaoId,
                userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome
              }
            }

            this.socketService.send(payload)
          })

          const payload = {
            service: Service.APPROVAL_CENTER,
            action: Action.APPROVE,
            method: Method.GET,
            content: {
              listCardId: aux,
            }
          }

          this.socketService.send(payload)
          this.socketService.sendNotification(res.listNotificacaoId)

        }
      })
    } catch (err) {
      this.btnLoading = false
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Não foi possível realizar a operação. Contate o suporte!' })
    }

    this.itensApprov = new Array()
    this.approverSelected = new Array()
    this.itensSelected = 0
    this.totalValue = 0
    this.rowsSelected.forEach(item => {
      item.isSelected = false
    })
  }

  getComments(): void {
    this.messages = new Array()

    const payload = {
      service: Service.APPROVAL_CENTER,
      action: Action.COMMENT,
      method: Method.GET,
      content: {
        cardId: this.rowsSelected[0].id
      }
    }

    this.socketService.send(payload)
  }

  saveComment(): void {
    if (this.validationComment()) {
      return
    }

    const payload = {
      service: Service.APPROVAL_CENTER,
      action: Action.COMMENT,
      method: Method.POST,
      content: {
        cardId: this.rowsSelected[0].id,
        message: this.comment,
        userId: this.userId,
        userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
      }
    }

    this.socketService.send(payload)

    this.comment = ''
  }

  updateComment(): void {
    if (this.validationComment()) {
      return
    }

    const payload = {
      service: Service.APPROVAL_CENTER,
      action: Action.COMMENT,
      method: Method.PUT,
      content: {
        id: this.msgEdited,
        message: this.comment,
        userId: this.userId,
        cardId: this.rowsSelected[0].id,
        userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
      }
    }

    this.socketService.send(payload)

    this.isEditMessage = false
    this.msgEdited = null
    this.comment = ''
  }

  deleteComment(event: any): void {
    if (confirm('O comentário será exclúido do histórico da aprovação')) {
      const payload = {
        service: Service.APPROVAL_CENTER,
        action: Action.COMMENT,
        method: Method.DELETE,
        content: {
          id: event.id,
          userId: this.userId,
          cardId: this.rowsSelected[0].id,
          userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
        }
      }

      this.socketService.send(payload)
    }
  }

  getFiles(): void {
    const payload = {
      service: Service.APPROVAL_CENTER,
      action: Action.ATTACHMENT,
      method: Method.GET,
      content: {
        cardId: this.rowsSelected[0].id,
      }
    }

    this.socketService.send(payload)
  }

  async saveFile(): Promise<void> {
    if (this.validationAttachment()) {
      return
    }

    const payload: FormData = new FormData()
    payload.append('file', this.file)
    payload.append('type', 'workflow')
    payload.append('projetoId', this.rowsSelected[0].empresaId.toString())
    payload.append('categoria', 'aprovacao')
    payload.append('cardId', this.rowsSelected[0].id)
    payload.append('fileName', this.fileNameValidation(this.file.name))

    this.btnLoading = true

    try {
      await this.httpService.post('/custom/upload', payload).toPromise().then((res) => {
        const payload = {
          service: Service.APPROVAL_CENTER,
          action: Action.ATTACHMENT,
          method: Method.POST,
          content: {
            origemId: this.originSelected,
            path: res.path,
            name: this.fileNameValidation(this.file.name),
            userId: this.userId,
            cardId: this.rowsSelected[0].id,
            userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
          }
        }

        this.socketService.send(payload)
      })

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Seu arquivo foi anexado!' })

      if(this.file.name !== this.fileNameValidation(this.file.name)) this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Atenção: ', detail: 'O nome do seu arquivo foi alterado! Para se adequar ao sistema: ' + this.fileNameValidation(this.file.name)})

      this.btnLoading = false
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Seu arquivo não foi anexado corretamente! Contate o suporte' })
    }

    this.originSelected = null
    this.file = null
    this.fileName.nativeElement.innerHTML = ''
  }

  async deleteFile(event: any) {
    if (confirm('O arquivo será exclúido do histórico da aprovação')) {
      const payload = {
        projetoId: this.rowsSelected[0].empresaId,
        cardId: this.rowsSelected[0].id,
        anexoNome: event.name,
        anexoCategoria: 'aprovacao'
      }

      this.btnLoading = true

      try {
        await this.httpService.post('/custom/workflow/delete-anexo-card', payload).toPromise().then(res => {
          const payload = {
            service: Service.APPROVAL_CENTER,
            action: Action.ATTACHMENT,
            method: Method.DELETE,
            content: {
              id: event.id,
              userId: this.userId,
              cardId: this.rowsSelected[0].id,
              userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
            }
          }

          this.socketService.send(payload)
        })

        this.btnLoading = false

        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Seu arquivo foi removido!' })

      } catch (err) {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Seu arquivo não foi removido corretamente! Contate o suporte' })
      }
    }
  }

  getActivities(): void {
    this.activities = new Array()
    this.cloneActivities = new Array()

    const payload = {
      service: Service.APPROVAL_CENTER,
      action: Action.ACTIVITY,
      method: Method.GET,
      content: {
        cardId: this.rowsSelected[0].id,
      }
    }

    this.socketService.send(payload)
  }

  async getUsers(): Promise<void> {
    if (this.rowsSelected.length === 1) {
      try {
        await this.httpService.get('/custom/usuario/todos-usuarios-empresa/' + this.rowsSelected[0].empresaId).toPromise().then(res => {
          this.mentionList = new Array()
          res.users.forEach(item => {
            this.mentionList.push({
              name: item.alias,
              id: item.id
            })
          })
        })
      } catch (err) {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Ocorreu um erro ao carregar os usuários! Contate o suporte.' })
      }
    }

  }

  async getOrigins(): Promise<void> {
    try {
      await this.httpService.get('/custom/workflow/origem').toPromise().then(res => {
        this.attachments = new Array()
        this.cloneAttachments = new Array()
        this.origins = new Array()

        res.forEach(item => {
          this.attachments.unshift({
            section: item.descricao,
            id: item.id,
            files: []
          })
          this.cloneAttachments.unshift({
            section: item.descricao,
            id: item.id,
            files: []
          })
          this.origins.unshift({
            label: item.descricao, value: item.id
          })
        })
      })

      this.attachments.forEach(item => {
        this.currentAttachmentState.push({ label: 'initial' })
        this.attachmentState.push({ label: true })
      })

    } catch (err) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Ocorreu um erro ao carregar a aprovação! Contate o suporte.' })
    }
  }

  downloadAttachment(event: any): void {
    window.open(this.httpService.url('/custom/file/' + this.rowsSelected[0].empresaId + '/' + this.rowsSelected[0].id + '/aprovacao/' + event.name + '/workflow'), '_blank')
  }

  formatInformations(): void {
    if (this.rowsSelected.length === 1) {
      this.totalValue = this.rowsSelected[0].valor
      this.tooltipApprove = this.rowsSelected[0].destinoAprov
      this.tooltipRefuse = this.rowsSelected[0].destinoRecusa
      this.isFinancial = (this.rowsSelected[0].origem === 'FINANCEIRO' || this.rowsSelected[0].isFinancial)
      this.attachments.forEach(item => item.files = [] )
      this.cloneAttachments.forEach(item => item.files = [] )
      this.getFiles()

      if (this.rowsSelected[0].aprovadores.find(line => line.authUserId === this.userId)) this.canApprove = false
      else this.canApprove = true

    } else {
      this.rowsSelected.forEach(item => {
        this.currentPreviewState.push({ label: 'final' })
        this.previewState.push({ label: false })

        this.totalValue = 0
      })
      this.tooltipApprove = 'Aprovar card(s)'
      this.tooltipRefuse = 'Recusar card(s)'
      this.canApprove = true
    }

    this.rowsSelected.forEach(item => {
      if (item.aprovadores && item.aprovadores.length > 0) {
        item.labelAprovador = item.aprovadores.filter(line => line.substituto === false).length == 1 ? item.aprovadores[0].fullName : 'Vários'
      } else item.labelAprovador = 'Sem aprovador'
    })

    this.getUsers()
  }

  onComments(data: any): void {
    if (this.rowsSelected.length > 0) {
      if (this.rowsSelected[0].id === data.content.cardId) {
        switch (data.method) {
          case 'get':
            this.messages.push(data.content)
            break
          case 'post':
            this.messages.unshift(data.content)
            break
          case 'put':
            this.messages.forEach(msg => {
              if (msg.id === data.content.id) {
                msg.message = data.content.message
              }
            })
            break
          case 'delete':
            this.messages = this.messages.filter(line => line.id !== data.content.id)
            break
        }

        this.cloneMessages = this.messages
      }
    }
    if (this.tab === 1) setTimeout(() => {
      this.$comments.nativeElement.scrollTop = this.$comments.nativeElement.scrollHeight
    }, 10)
  }

  onAttachments(data: any): void {
    if (this.rowsSelected.length > 0) {
      if (this.rowsSelected[0].id === data.content.cardId) {
        switch (data.method) {
          case 'get':
            this.attachments.forEach(item => {
              if (data.content.origem_id === item.id) {
                item.files.push({
                  id: data.content.id,
                  path: data.content.path,
                  name: data.content.name
                })
              }
            })

            this.cloneAttachments.forEach(item => {
              if (data.content.origem_id === item.id) {
                item.files.push({
                  id: data.content.id,
                  path: data.content.path,
                  name: data.content.name
                })
              }
            })

            this.documentList.push({
              id: data.content.id,
              name: data.content.name,
            })
            break
          case 'post':
            this.attachments.forEach(item => {
              if (data.content.origemId === item.id) {
                item.files.unshift({
                  id: data.content.id,
                  path: data.content.path,
                  name: data.content.name
                })
              }
            })

            this.cloneAttachments.forEach(item => {
              if (data.content.origemId === item.id) {
                item.files.unshift({
                  id: data.content.id,
                  path: data.content.path,
                  name: data.content.name
                })
              }
            })

            this.documentList.push({
              id: data.content.id,
              name: data.content.name,
            })
            break
          case 'delete':
            this.attachments.forEach(item => {
              item.files = item.files.filter(line => line.id !== data.content.id)
            })

            this.cloneAttachments.forEach(item => {
              item.files = item.files.filter(line => line.id !== data.content.id)
            })

            this.documentList = this.documentList.filter(line => line.id !== data.content.id)
            break
        }
      }
    }
  }

  onActivities(data: any): void {
    if (this.rowsSelected.length > 0) {
      if (this.rowsSelected[0].id === data.content.cardId) {
        if (data.method === 'get') this.activities.push(data.content)
        else this.activities.unshift(data.content)

        this.cloneActivities = this.activities
      }
    }
  }

  async onApprovements(data: any): Promise<void> {
    await this.getRows(data.content.listCardId)

    if (this.display) {
      if (this.newRows.length > 0) {
        this.getInformations()
      } else {
        this.sidebarService.open(false)
        this.sidebarService.clearRow()
      }
    }
  }

  async getRows(data: any): Promise<void> {
    const payload = {
      listCardId: data
    }

    try {
      await this.httpService.post('/custom/workflow/card-aprovacao/' + this.dblinkedSessionService.empresa.id + '/' + this.sessionService.loggedUser.id, payload).toPromise().then(res => {
        this.newRows = res
      })
    } catch (err) {
      this.btnLoading = false
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar as aprovações! Contate o suporte' })
    }
  }

  async downloadPdf(data: number): Promise<void> {
    this.isGeneratePdf = true

    try {
      this.httpService.get('/custom/rms/impressao-rms(' + data + ',' + false + ')').toPromise().then(res => {
        if (!res.error) window.open(this.httpService.url('/custom/file/' + res.file + '/print_rms'), '_blank');
        else this.exibirMensagem(res);

        this.isGeneratePdf = false
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao gerar o PDF! Contate o suporte' })
    }
  }

  selectApprov(event: any): void {
    this.rowsSelected.forEach(item => {
      if (item.cardAprovacaoId === event) {
        item.isSelected = !item.isSelected

        if (item.isSelected) {
          this.itensSelected++
          this.itensApprov.push(item.cardAprovacaoId)
          this.totalValue += item.valor

          item.aprovadores.forEach(elem => {
            this.approverSelected.push(elem.authUserId)
          })
        } else {
          this.itensSelected--
          this.itensApprov.splice(this.itensApprov.indexOf(item.cardAprovacaoId), 1)
          this.totalValue -= item.valor

          item.aprovadores.forEach(elem => {
            this.approverSelected.splice(this.approverSelected.indexOf(elem.authUserId), 1)
          })
        }
      }
    })

    if (this.approverSelected.length > 0) {
      let count: number = 0
      if(this.approverSelected.includes(this.userId)) {
        this.approverSelected.forEach(item => { if(item === this.userId) count ++ })
        if(count === this.itensSelected) this.canApprove = false
        else this.canApprove = true
      } else this.canApprove = true
    } else this.canApprove = true
  }

  selectItemApprov(event: any): void {
    this.rowsSelected[0].itens.forEach(item => {
      if (item.id === event.id) {
        item.isSelected = !item.isSelected

        if (item.isSelected) this.itemApportionment = item.rateio
        else this.itemApportionment = []

      } else item.isSelected = false
    })
  }

  showMore() {
    this.showingOff += 5
  }

  showLess() {
    if (this.showingOff > 5) this.showingOff -= 5
  }

  possibleApprove(): boolean {
    if (this.rowsSelected.length >= 1) {
      if (this.rowsSelected[0].status === 'APROVADO' || this.rowsSelected[0].status === 'PEDIDO CRIADO' || this.rowsSelected[0].status === 'RECUSADO') return false
      else {
        if (this.rowsSelected.length === 1) {
          if (this.rowsSelected[0].aprovadores.find(line => line.authUserId === this.userId)) return true
          else return false
        } else return true
      }
    }
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0]
    this.fileName.nativeElement.innerHTML = this.fileNameValidation(this.file.name)
  }

  editMessage(event: any): void {
    this.isWriteMessage = true
    this.isEditMessage = true
    this.msgEdited = event.id
    this.comment = event.message
  }

  closeMessage(): void {
    this.isWriteMessage = false
    this.isEditMessage = false
    this.comment = ''
  }

  expandSidebar(): void {
    localStorage.setItem("rows", JSON.stringify(this.cardsSelected))
    window.open('centralweb/work-flow/sidebar-expandida', '_blank')
  }

  onHide(): void {
    this.sidebarService.open(false)
    this.sidebarService.clearRow()
    this.isWriteMessage = false
    this.isNewFile = false
    this.approverSelected = new Array()
    this.itensSelected = 0
  }

  fileNameValidation(name) {
    let replacedName = name.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z. ])/g, '')
    replacedName = replacedName.replaceAll(" ", "_")

    return replacedName
  }

  isUser(userName: string): string {
    let profile = userName.toLocaleUpperCase().split(" ")
    let name: Array<string> = new Array()
    const nameAddons: Array<string> = ['DA', 'DAS', 'DE', 'DI', 'DO', 'DOS']

    profile.forEach(item => { if(item && !nameAddons.includes(item)) name.push(item) })

    return name[0][0] + name[1][0]
  }

  changeTab(type: number): void {
    this.isCollapseAll = false

    switch (type) {
      case 0:
        this.showPreview = true
        this.showComments = false
        this.showAttachments = false
        this.showActivity = false
        this.tab = 0
        this.isCollapseAll = true
        this.filterPlaceholder = 'Pesquise pelo titulo e número dos cards'
        break
      case 1:
        this.showPreview = false
        this.showComments = true
        this.showAttachments = false
        this.showActivity = false
        this.tab = 1
        setTimeout(() => {
          this.$comments.nativeElement.scrollTop = this.$comments.nativeElement.scrollHeight
        }, 5)
        if (this.clicksComment === 0) this.getComments()
        this.clicksComment++
        this.filterPlaceholder = 'Pesquise por mensagens'
        break
      case 2:
        this.showPreview = false
        this.showComments = false
        this.showAttachments = true
        this.showActivity = false
        this.tab = 2
        this.filterPlaceholder = 'Pesquise por anexos'
        break
      case 3:
        this.showPreview = false
        this.showComments = false
        this.showAttachments = false
        this.showActivity = true
        this.tab = 3
        if (this.clicksActivities === 0) this.getActivities()
        this.clicksActivities++
        this.filterPlaceholder = 'Pesquise por atividades'
        break
    }
  }

  cardAttachmentsState(type): void {
    this.currentAttachmentState[type].label = this.currentAttachmentState[type].label === 'initial' ? 'final' : 'initial'
    this.attachmentState[type].label = !this.attachmentState[type].label
  }

  cardActivityState(type): void {
    this.currentActivityState[type].label = this.currentActivityState[type].label === 'initial' ? 'final' : 'initial'
    this.activityState[type].label = !this.activityState[type].label
  }

  cardPreviewState(type): void {
    this.currentPreviewState[type].label = this.currentPreviewState[type].label === 'initial' ? 'final' : 'initial'
    this.previewState[type].label = !this.previewState[type].label
  }

  collapseAll(tab: number): void {
    this.isCollapseAll = !this.isCollapseAll

    let currentLabelState, labelState

    if (this.isCollapseAll) {
      currentLabelState = 'final'
      labelState = false
    } else {
      currentLabelState = 'initial'
      labelState = true
    }

    switch (tab) {
      case 0:
        this.currentPreviewState.forEach(item => {
          item.label = currentLabelState
        })
        this.previewState.forEach(item => {
          item.label = labelState
        })
        break
      case 2:
        this.currentAttachmentState.forEach(item => {
          item.label = currentLabelState
        })
        this.attachmentState.forEach(item => {
          item.label = labelState
        })
        break
      case 3:
        this.currentActivityState.forEach(item => {
          item.label = currentLabelState
        })
        this.activityState.forEach(item => {
          item.label = labelState
        })
        break
    }
  }

  validationApprov(): boolean {
    if (this.itensApprov.length <= 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nenhuma aprovação foi selecionada!' })
      return true
    }
  }

  validationComment(): boolean {
    if ((this.comment === null || this.comment === undefined) || this.comment.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Seu comentário não pode ser vazio!' })
      return true
    }
  }

  validationAttachment(): boolean {
    if (this.originSelected === null || this.originSelected === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nenhuma seção foi selecionada!' })
      return true
    }

    if (this.file === null || this.file === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nenhum anexo foi selecionada!' })
      return true
    }

    if (this.file.size >= 10000000) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'O tamanho do arquivo não pode ser maior que 10 Mb' })
      return true
    }
  }

  onFilter(tab: number): void {
    switch (tab) {
      case 0:
        if (this.contentFilter) {
          this.cloneRowsSelected = this.rowsSelected.filter(line => line.titulo.toLowerCase().normalize("NFD").includes(this.contentFilter.toLowerCase().normalize("NFD")) || line.id.toString().includes(this.contentFilter))
        } else {
          this.cloneRowsSelected = this.rowsSelected
        }
        break
      case 1:
        if (this.contentFilter) {
          this.messages = this.cloneMessages.filter(line => line.message.toLowerCase().normalize("NFD").includes(this.contentFilter.toLowerCase().normalize("NFD")))
        } else {
          this.messages = this.cloneMessages
        }
        break
      case 2:
        if (this.contentFilter) {
          this.attachments.forEach(item => {
            this.cloneAttachments.forEach(elem => {
              if (item.id === elem.id) {
                item.files = elem.files.filter(line => line.name.toLowerCase().normalize("NFD").includes(this.contentFilter.toLowerCase().normalize("NFD")))
              }
            })
          })
        } else {
          this.attachments.forEach(item => {
            this.cloneAttachments.forEach(elem => {
              if (item.id === elem.id) {
                item.files = elem.files
              }
            })
          })
        }
        break
      case 3:
        if (this.contentFilter) {
          this.activities = this.cloneActivities.filter(line => line.action.toLowerCase().normalize("NFD").includes(this.contentFilter.toLowerCase().normalize("NFD")))
        } else {
          this.activities = this.cloneActivities
        }
        break
    }
  }

  showMessages(): void {
    if (this.withComments) this.activities = this.cloneActivities.filter(line => line.key !== 'comment')
    else this.activities = this.cloneActivities

    this.withComments = !this.withComments
  }

  onlyApprovs(): void {
    this.onlyApprov = !this.onlyApprov

    if (this.onlyApprov) this.activities = this.cloneActivities.filter(line => line.key === 'approve')
    else this.activities = this.cloneActivities
  }

  convertDate(dateTime): String {
    const date = new Date(dateTime)
    const today = new Date()

    const fullDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    const fullToday = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
    const yesterday = (today.getDate() - 1) + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()

    if (fullDate === fullToday) return 'Hoje às ' + date.getHours() + 'hr' + date.getMinutes()
    else if (fullDate === yesterday) return 'Ontem às ' + date.getHours() + 'hr' + date.getMinutes()
    else return fullDate + ' às ' + date.getHours() + 'hr' + date.getMinutes()
  }

  convertTimestamp(timestamp): String {
    const date = new Date(timestamp)

    date.setHours(date.getHours() + 3)

    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)

    const fullDate = day + '/' + month + '/' + date.getFullYear()

    return fullDate
  }

  verifyKey(keyEvent): void {

    let showUserMentions = false, showDocMentions = false

    const userRgx = new RegExp(/(\s\@\w*$)|(^\@\w*$)/g),
      docRgx = new RegExp(/(\s\#\w*$)|(^\#\w*$)/g),
      position = keyEvent.target.selectionStart,
      inputValue = this.comment.slice(0, position)

    showUserMentions = inputValue.match(userRgx) !== null
    showDocMentions = inputValue.match(docRgx) !== null

    this.mentionControler(showUserMentions || showDocMentions, position, showDocMentions)

  }

  compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  mentionControler(visible, position = 0, isDoc = false): void {
    const optionsElement: Element = document.getElementById('mentionOptions'),
      userRgx = new RegExp(/\@\w*$/g),
      docRgx = new RegExp(/\#\w*$/g);
    if (visible) {
      let flag = '',
        userList = this.mentionList,
        docList = this.documentList,
        match = this.comment.slice(0, position).match(isDoc ? docRgx : userRgx);

      if (match !== null) {
        flag = match[0].slice(1, match[0].length).toUpperCase();
        if (isDoc)
          docList = this.documentList.filter(item => item.name.toUpperCase().includes(flag))
        else
          userList = this.mentionList.filter(item => item.name.toUpperCase().includes(flag))
      }

      this.mentionOptions = isDoc ? docList.sort(this.compare) : userList.sort(this.compare);
      this.mentiosIsDoc = isDoc;
      this.cursorInfo = { position };
      optionsElement.classList.remove('hidden')
    } else {
      optionsElement.classList.add('hidden')
    }
  }

  onSelectMention(id): void {
    try {
      let { position } = this.cursorInfo,
        userRgx = new RegExp(/\@\w*/g),
        docRgx = new RegExp(/\#\w*/g)

      const selectedUser = `${(this.mentiosIsDoc ? this.documentList : this.mentionList)
        .filter((option) => option.id == id)[0].name
        }`

      const usedSlice = this.comment.slice(0, position)
      const restSlice = this.comment.slice(position, this.comment.length)

      const matches = Array.from(usedSlice.matchAll(this.mentiosIsDoc ? docRgx : userRgx))

      const currentMatch = matches[matches.length - 1]

      const startPosition = currentMatch.index + 1
      const endPosition = startPosition + currentMatch[0].length - 1

      const startText = usedSlice.slice(0, startPosition)
      const endText = usedSlice.slice(endPosition, usedSlice.length)

      this.comment = `${startText}${selectedUser}${endText} ${restSlice}`

    } catch (err) {
      console.log(err)
    }
    this.mentionControler(false)
  }

  get highlightedText(): String {
    return this.applyHighlights(this.comment)
  }

  applyHighlights(text): String {
    let userHighlight = "<span class='user' style='color: #0074DD; font-size:13px; font-weight: bolder;'>$&</span>",
      docHighlight = "<span class='doc' style='color: #f5461b; font-size:13px; font-weight: bolder;'>$&</span>",
      response = '',
      highlightNodes: NodeListOf<ChildNode>
    if (text != '') {
      response = text
      if (response) {
        this.mentionList.forEach(x => response = response.replace(new RegExp(`@${x.name}`, 'g'), userHighlight))
        this.documentList.forEach(x => response = response.replace(new RegExp(`#${x.name}`, 'g'), docHighlight))
      }

      if (this.$highlights) {
        highlightNodes = this.$highlights.nativeElement.childNodes
        if (highlightNodes.length > 0) {
          highlightNodes.forEach((node: any) => {
            const style = "padding: 1px 2px; border-radius: 4px; font-size:0.85rem;"
            switch (node.className) {
              case "user":
                node.style = style + "background: #0074DD40"
                break
              case "doc":
                node.style = style + "background: #f5461b80"
                break
            }
          })
        }
      }
    }

    return response
  }

  handleScroll(): void {
    var scrollTop = this.$textarea.nativeElement.scrollTop
    this.$backdrop.nativeElement.scrollTop = scrollTop

    var scrollLeft = this.$textarea.nativeElement.scrollLeft
    this.$backdrop.nativeElement.scrollLeft = scrollLeft
  }

  exibirMensagem(value: any) {
    let msgs = (value.mensagem || value.message || value.response || value.msg);

    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: msgs });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: msgs });
    }
  }
}
