import { AccountState, LoginAccount, Representative, SettingsLevy, Terms } from "../interfaces/matera"

export function resetRepresentativeMatera(): Representative {
  let representative: Representative = {
    nome: null,
    cpf: null,
    email: null,
    nome_mae: null,
    nascimento: null,
    telefone: null,
    endereco: {
      logradouro: null,
      numero: null,
      cidade: null,
      uf: null,
      cep: null,
      bairro: null,
      complemento: null
    }
  }

  return {...representative}
}

export function resetSettingsLevy(): SettingsLevy {
  let settingsLevy: SettingsLevy = {
    empresa_conta_id: null,
    fornecedor_padrao_id: null,
    operacao_regra_contabil_id: null,
    email: null,
    valor_juros: null,
    tipo_juros_id: null,
    valor_multa: null,
    tipo_multa_id: null,
    valor_desconto: null,
    tipo_desconto_id: null,
    valor_abatimento: null,
    tipo_abatimento_id: null,
  }

  return {...settingsLevy}
}

export function resetAccountState(): AccountState {
  let accountState: AccountState = {
    status: null,
    accountNumber: null,
    accountId: null,
    branch: null,
    create_timestamp: null,
    keyType: null,
    key: null,
    keyStatus: null
  }

  return {...accountState}
}

export function resetLoginAccount(): LoginAccount {
  let loginAccount: LoginAccount = {
    accountId: null,
  }

  return {...loginAccount}
}

export function resetTerms(): Terms {
  let terms: Terms = {
    timestamp: null,
    latitude: null,
    longitude: null,
    precisao: null,
    ip: null,
  }

  return {...terms}
}