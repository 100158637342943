import { Component, OnInit } from '@angular/core';
import { CadParticipante } from '../model/cad-participante.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'app-cad-participante',
  templateUrl: './cad-participante.component.html',
  styleUrls: ['./cad-participante.component.scss'],
  providers: [CrudService]
})
export class CadParticipanteComponent extends DataTableBasicComponent implements OnInit {
  public cadParticipante: CadParticipante = new CadParticipante();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('Código', 'codPart'),
      new DataTableColumn('Nome', 'nome'),
      new DataTableColumn('País', 'codPais'),
      new DataTableColumn('CNPJ', 'cnpj'),
      new DataTableColumn('CPF', 'cpf'),
      new DataTableColumn('Nit', 'nit'),
      new DataTableColumn('UF', 'uf'),
      new DataTableColumn('IE', 'ie'),
      new DataTableColumn('IE ST', 'ieSt'),
      new DataTableColumn('Cod Mun', 'codMun'),
      new DataTableColumn('Insc Mun', 'im'),
      new DataTableColumn('Suframa', 'suframa'),
      new DataTableColumn('Cad Ecd', 'cadEcd.id')

    ];

    this.controls = [
      new DataControl('Código', 'codPart')
        .setRequired()
        .setMaxLength(255),
    new DataControl('Nome', 'nome')
        .setRequired()
        .setMaxLength(255),
    new DataControl('País', 'codPais')
        .setRequired()
        .setMaxLength(5),
    new DataControl('CNPJ', 'cnpj')
        .setRequired()
        .setMaxLength(14),
    new DataControl('CPF', 'cpf')
        .setRequired()
        .setMaxLength(11),
    new DataControl('Nit', 'nit')
        .setRequired()
        .setMaxLength(2),
    new DataControl('UF', 'uf')
        .setRequired()
        .setMaxLength(2),
    new DataControl('IE', 'ie')
        .setRequired()
        .setMaxLength(255),
    new DataControl('IE ST', 'ieSt')
        .setRequired()
        .setMaxLength(255),
    new DataControl('Cod Mun', 'codMun')
        .setRequired()
        .setMaxLength(7),
    new DataControl('Insc Mun', 'im')
        .setRequired()
        .setMaxLength(255),
    new DataControl('Suframa', 'suframa')
        .setRequired()
        .setMaxLength(9),
   new DataControl('Cad Ecd', 'cadEcd')
        .setTypeSelect()
        .setOptions({ url: '/cadecd?$select=id&$orderby=id', value: 'id', label: 'id' })

    ];
  }
}
