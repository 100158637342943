import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ProjetoDbl } from 'app/cadastro/model/projeto-dbl.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { ConfigService } from 'app/shared/services/config.service';
import { Empresa } from '../../cadastro/model/empresa.model';
import { MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';

import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-projeto-dbl',
  templateUrl: './projeto-dbl.component.html',
  styleUrls: ['./projeto-dbl.component.scss'],
  providers: [CrudService]
})
export class ProjetoDblComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public projetodbl: ProjetoDbl = new ProjetoDbl();
  public registrosProjeto: Array<ProjetoDbl> = new Array();

  private sessionSubscription: Subscription;
  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;

  entityFilter: string = null;
  codigo: string;
  descricao: string;
  editarConta: boolean;
  novaConta: boolean;
  ids: Array<any> = new Array();

  title = 'Projeto DBL';
  subtitle = "Projeto";

  buttonToolbar = this.getButtonToolbarContent();
  toolbarMainIni = this.getToolbarMainIniContent();

  public toolbarMain = new Toolbar();

  constructor(private crudService: CrudService,
              private httpService: HttpService,
              private sessionService: SessionService,
              private configService: ConfigService,
              private messageService: MessageService,
              private toolbarService: ToolbarService,
              private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  ngOnInit() {
    this.toolbarService.hidden = true;
    this.toolbarMain.setAll(this.toolbarMainIni);

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
      );

    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
                                                                         () => { this.empresaChanged(); });

  }

  carregar() {
    this.title = 'Projeto DBL';
    this.subtitle = 'Projeto';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();

    this.carregaBotoesToolbarInicial();
    this.limpaCamposForm();

    this.loadRegistros();
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar';

    this.carregaBotoesToolbarFormNovo();
  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService
      .get(`/projetodbl?$select=cod,descricao&$count=true&$skip=0&$orderby=cod`)
      .subscribe(
        (retorno) => {
          this.registrosProjeto = new Array();
          retorno.value.forEach(item => {
            this.registrosProjeto.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar';
    this.editarConta = true;

    this.carregaBotoesToolbarFormEditar();
    this.limpaCamposForm();

    this.httpService.wait();
    this.httpService.get(`/projetodbl(${this.ids[0]})?$select=cod,descricao&$count=true`)
      .subscribe(item => {
        this.codigo = item.value.cod;
        this.descricao = item.value.descricao;
      },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      cod: this.codigo,
      descricao: this.descricao,
    };

    this.httpService.wait();
    this.httpService.post('/projetodbl', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      cod: this.codigo,
      descricao: this.descricao,
    };

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/projetodbl', key, jsonData, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
      error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
      () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      this.httpService.wait();
      this.httpService.delete('/projetodbl', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Registro excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());
    }
  }

  selectCheckboxContainer(data){
    setTimeout(() => {
      document.getElementById('checkboxContainer'+data.id).click();
    },100);
  };

  selectCheckbox(event: any) {
    this.ids.push(event.data.id);
    event.selected = false;
    this.filtraFuncoesToolbar();
  }

  unselectCheckbox(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.filtraFuncoesToolbar();
  }

  filtraFuncoesToolbar() {
    if (this.ids.length === 1) {
      this.toolbarMain.setDisabled(this.buttonToolbar.excluir, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.editar, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
    }else {
      this.toolbarMain.setDisabled(this.buttonToolbar.excluir, true);
      this.toolbarMain.setDisabled(this.buttonToolbar.editar, true);
      this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
    }
  }

  validacao(): boolean {
    if ((this.codigo === null || this.codigo === undefined) || this.codigo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório' });
      return true;
    }
    if ((this.descricao === null || this.descricao === undefined) || this.descricao.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição é Obrigatória' });
      return true;
    }

    return false;
  }

  private getButtonToolbarContent(){
    return {
      voltar: 'btn1',
      excluir: 'btn2',
      editar: 'btn3',
      adicionarNovo: 'btn4',
      salvarNovo: 'btn5',
      salvarAlteracao: 'btn6',
    }
  }

  private getToolbarMainIniContent(){
    return [
      { icon: 'undo', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: true, color: 'default', onClick: () => this.carregar() },
      { icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: true, disabled: true, color: 'red', onClick: () => this.excluirRegistro() },
      { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: true, disabled: true, color: 'default', onClick: () => this.loadEditarRegistro() },
      { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.criarNovo() },
      { icon: 'check', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: true, disabled: false, color: 'green', onClick: () => this.salvarNovoRegistro() },
      { icon: 'check', text: 'Salvar', tooltip: 'Salvar Alteração', visible: true, disabled: false, color: 'green', onClick: () => this.salvarAlteracaoRegistro() },
    ];
  }

  private carregaBotoesToolbarFormEditar(){
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, true);

    this.toolbarMain.setDisabled(this.buttonToolbar.voltar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.salvarAlteracao, false);
  }

  private carregaBotoesToolbarFormNovo(){
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, true);

    this.toolbarMain.setDisabled(this.buttonToolbar.voltar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.salvarNovo, false);
  }

  private carregaBotoesToolbarInicial(){
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.excluir, true);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, true);

    this.toolbarMain.setDisabled(this.buttonToolbar.excluir, true);
    this.toolbarMain.setDisabled(this.buttonToolbar.editar, true);
    this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
  }

  private ocultaBotoesToolbar(){
    this.toolbarMain.setVisible(this.buttonToolbar.voltar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.excluir, false);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, false);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, false);
  }

  private limpaCamposForm() {
    this.codigo = '';
    this.descricao = '';
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.columns = [
        new DataTableColumn('CÓDIGO', 'cod'),
        new DataTableColumn('DESCRIÇÃO', 'descricao')
      ];

      this.controls = [
           new DataControl('Código', 'cod')
            .setRequired()
            .setMinLength(3)
            .setMaxLength(45),
        new DataControl('Descrição', 'descricao')
            .setRequired()
            .setMinLength(3)
            .setMaxLength(255)
      ];
    }
  }



  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
