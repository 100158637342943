import { HttpClient } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import LogManagement from 'app/shared/common/log-management/log-control'
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types'
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component'
import { Toolbar } from 'app/shared/model/toolbar.model'
import { CnpjPipe } from 'app/shared/pipes/cnpj.pipe'
import { ConfigService } from 'app/shared/services/config.service'
import { CrudService } from 'app/shared/services/crud.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpService } from 'app/shared/services/http.service'
import { SessionService } from 'app/shared/services/session.service'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { UtilService } from 'app/shared/services/util.service'
import deepCopy from 'app/util/processing/deepcopy'
import { MessageService, SelectItem } from 'primeng/api'
import { Subscription } from 'rxjs/Subscription'
import { Empresa } from '../../cadastro/model/empresa.model'
import { ImpostoRegraFiscal } from '../../cadastro/model/imposto-regra-fiscal.model'
import { OperacaoRegraFiscal } from '../../cadastro/model/operacao-regra-fiscal.model'
import { CstIcmsTabelaB } from '../../util/model/cst-icms-tabela-b.model'
import { TipoEntradaSaida } from '../../util/model/tipo-entrada-saida.model'
import { OperacaoRegraFiscalDetalheCfopRelac } from '../model/operacao-regra-fiscal-detalhe-cfop-relac.model'
import { OperacaoRegraFiscalDetalhe } from '../model/operacao-regra-fiscal-detalhe.model'
import { CodigosCBenef } from 'app/util/model/codigos-c-benef.model'
import exportExcel from 'app/util/processing/exportExcel'


@Component({
  selector: 'app-operacao-regra-fiscal',
  templateUrl: './operacao-regra-fiscal.component.html',
  styleUrls: ['./operacao-regra-fiscal.component.scss'],
  providers: [CrudService]
})
export class OperacaoRegraFiscalComponent extends DataTableBasicComponent implements OnInit, OnDestroy {


  private sessionSubscription: Subscription


  private cnpjPipe: CnpjPipe = new CnpjPipe()


  // variaveis referentes as operações
  operacaoRegraFiscal: OperacaoRegraFiscal
  operacaoRegraFiscalDetalheIcms: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalheIpi: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalheIcmsSt: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalhePis: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalheCofins: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalheIrrf: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalhePcc: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalheIssqn: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalheInss: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalheSimples: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalheIi: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalheCide: OperacaoRegraFiscalDetalhe
  operacaoRegraFiscalDetalheIssRet: OperacaoRegraFiscalDetalhe
  opcoesOperacao: SelectItem[]
  opcoesCodBenef: SelectItem[]
  opcoesCsosn: SelectItem[]
  opcoesCodEnqIpi: SelectItem[]
  opcoesModBcIcms: SelectItem[]


  operacaoRegraFiscalEditar: OperacaoRegraFiscal
  opcoesImposto: Array<SelectItem> = new Array()
  opcoesCstIpi: Array<SelectItem> = new Array()
  opcoesCstPis: Array<SelectItem> = new Array()
  opcoesCstCofins: Array<SelectItem> = new Array()
  opcoesRegimeIncidencia: Array<SelectItem> = new Array()
  opcoesCfop: Array<SelectItem> = new Array()
  opcoesCstIcmsTabelaB: Array<SelectItem> = new Array()

  regras: Array<OperacaoRegraFiscal> = new Array()
  regrasFiscaisSelecionadas: Array<OperacaoRegraFiscal> = new Array();
  clearArray: Array<any> = new Array()
  opcoesStatusRegraFiscalShowInativos: SelectItem[] = [{ label: 'Ativo', value: true }, { label: 'Inativo', value: false }];
  opcoesStatusRegraFiscal: SelectItem[] = [{ label: 'Ativo', value: true }];

  pt: any
  title: String
  subtitle: String
  filter_query_ativo: String = ""

  formulario: boolean
  incideImposto: boolean
  somaIpiBc: boolean
  relacaoCfopCst: boolean
  cstCofins: boolean
  cstPis: boolean
  cstIpi: boolean
  regimeIncidencia: boolean
  inscritoMunicipio: boolean
  regimeTributario: number
  showInativos: boolean = false
  checaStatusLote: boolean = true;

  editar: boolean
  disabledSave: boolean

  clonagem: boolean = false
  clonagemNaPropriaEmpresa: boolean = false
  empresaRealizaAlteracoes: boolean = false
  cadastroGrupoEmpresas: boolean = false
  empresasGrupo: SelectItem[] = new Array()
  empresasGrupoSelecionadas: Array<any> = new Array()
  placeHolderEmpresasSelecionadas: String = 'Nenhuma empresa selecionada'
  modalGrupoEmpresas: boolean = false
  tituloModalGrupoEmpresas: string = ''
  descricaoModalGrupoEmpresas: string = ''
  operacaoRegraClonagemNovoCodigo: string = ''
  operacaoRegraClonagemNovoTitulo: string = ''

  colunasExportar = ["codigo", "titulo", "dtInicialFormatada", "dtFinalFormatada", "statusRegra"]

  constructor(private configService: ConfigService,
    private crudService: CrudService,
    private httpService: HttpService,
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private toolbarService: ToolbarService,
    private messageService: MessageService) {
    super(crudService)
  }

  private LogManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  public toolbarRegraFiscal = new Toolbar()

  toolbarMainRegraFiscal = [
    {
      key: 'btn-clone-expanded', icon: 'content_copy', text: '', tooltip: 'Clonar', visible: false, disabled: false, color: 'default', dropdown: true,
      options: [
        { label: 'Clonar no grupo de empresas', callback: () => this.setarVariaveisClonagem(false) },
        { optionKey: 'btn-unique-clone', label: 'Clonar somente na empresa atual', callback: () => this.setarVariaveisClonagem(true) },
      ]
    },
    { key: 'reload', icon: 'replay', text: 'Atualizar', tooltip: 'Atualizar Relatório', visible: false, disabled: false, color: 'default', onClick: () => this.empresaChanged() },
    { key: 'back', icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.cancelarEdicao() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.editarRegra() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.cadastroGrupoEmpresas ? this.excluirRegraGrupoEmpresa() : this.excluirRegra() },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarRegra() },
    { key: 'saveNew', icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoSalvamento() },
    { key: 'saveAlteration', icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoSalvamento() },
    { key: 'clone', icon: 'content_copy', text: 'Clonar Regra', tooltip: 'Clonar Regra', visible: false, disabled: false, color: 'default', onClick: () => this.setarVariaveisClonagem(true) },
    { key: 'btn-able-disable', icon: 'edit', text: 'Ativar/Desativar', tooltip: 'Ativar ou Desativar em lote', visible: false, disabled: false, color: 'default', onClick: () => this.batchEdit(this.regrasFiscaisSelecionadas[this.regrasFiscaisSelecionadas.length-1].ativo) }
  ]

  ngOnInit() {
    // TODO: REVIEW (IMPORTANT!)
    this.toolbarRegraFiscal.setAll(this.toolbarMainRegraFiscal)
    this.toolbarService.hidden = true
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.dblinkedSessionService.hidePeriods();


    this.title = 'OPERAÇÃO REGRA FISCAL';
    this.subtitle = 'CADASTRE E CONTROLE AS REGRAS FISCAIS';
    this.formulario = false;
    this.editar = false;
    this.disabledSave = false;

    this.operacaoRegraFiscal = new OperacaoRegraFiscal();
    this.operacaoRegraFiscal.empresa = new Empresa();
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList = new Array();
    this.operacaoRegraFiscal.tipoEntradaSaida = new TipoEntradaSaida();

    this.operacaoRegraFiscal.codigosCbenef = new CodigosCBenef();

    this.operacaoRegraFiscalEditar = new OperacaoRegraFiscal();
    this.operacaoRegraFiscalEditar.empresa = new Empresa();
    this.operacaoRegraFiscalEditar.operacaoRegraFiscalDetalheList = new Array();

    this.operacaoRegraFiscalEditar.codigosCbenef = new CodigosCBenef();

    this.operacaoRegraFiscalDetalheIcms = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIpi = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIcmsSt = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalhePis = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheCofins = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIrrf = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalhePcc = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIssqn = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheInss = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheSimples = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIi = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheCide = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIssRet = new OperacaoRegraFiscalDetalhe();

    this.operacaoRegraFiscal.dadosAdicionais = '';


    this.incideImposto = false;
    this.somaIpiBc = false;
    this.relacaoCfopCst = false;
    this.cstCofins = false;
    this.cstPis = false;
    this.cstIpi = false;
    this.regimeIncidencia = false;
    this.inscritoMunicipio = false;
    this.regimeTributario = 0;

    this.operacaoRegraClonagemNovoCodigo = ''
    this.operacaoRegraClonagemNovoTitulo = ''
    this.clonagemNaPropriaEmpresa = false

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.empresaChanged()
        this.carregarDadosGrupoEmpresa()
      }
    );
  }


  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }


  empresaChanged() {

    this.formulario = false;
    this.editar = false;
    this.disabledSave = false;

    this.operacaoRegraFiscal = new OperacaoRegraFiscal();
    this.operacaoRegraFiscal.empresa = new Empresa();
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList = new Array();
    this.operacaoRegraFiscal.tipoEntradaSaida = new TipoEntradaSaida();
    this.operacaoRegraFiscal.codigosCbenef = new CodigosCBenef();
    this.regrasFiscaisSelecionadas = new Array()
    this.clearArray = new Array()


    this.operacaoRegraFiscalEditar = new OperacaoRegraFiscal();
    this.operacaoRegraFiscalEditar.empresa = new Empresa();
    this.operacaoRegraFiscalEditar.operacaoRegraFiscalDetalheList = new Array();
    this.operacaoRegraFiscalEditar.codigosCbenef = new CodigosCBenef();

    this.operacaoRegraFiscalDetalheIcms = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIpi = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIcmsSt = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalhePis = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheCofins = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIrrf = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalhePcc = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIssqn = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheInss = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheSimples = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIi = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheCide = new OperacaoRegraFiscalDetalhe();
    this.operacaoRegraFiscalDetalheIssRet = new OperacaoRegraFiscalDetalhe();

    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIcms);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIpi);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIcmsSt);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalhePis);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheCofins);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIrrf);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalhePcc);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIssqn);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheInss);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheSimples);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIi);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheCide);
    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIssRet);


    this.incideImposto = false;
    this.somaIpiBc = false;
    this.relacaoCfopCst = false;
    this.cstCofins = false;
    this.cstPis = false;
    this.cstIpi = false;
    this.regimeIncidencia = false;
    this.inscritoMunicipio = false;
    this.modalGrupoEmpresas = false
    this.clonagem = false
    this.clonagemNaPropriaEmpresa = false

    this.operacaoRegraClonagemNovoCodigo = ''
    this.operacaoRegraClonagemNovoTitulo = ''

    if(this.showInativos == false){
      this.filter_query_ativo = " and ativo eq true "
    }
    else{
      this.filter_query_ativo = " "
    }

    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.changeToolbar()

      this.httpService.wait();
      this.httpService.get('/codigoscbenef?$select=id,codigo,descricao&$orderby=codigo')
        .subscribe(ref => this.loadCodigosCBenef(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/tipoentradasaida?$select=id,descricao&$orderby=descricao')
        .subscribe(ref => this.loadOperacao(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/operacaoregrafiscal?$select=id,codigo,titulo,dtInicial,dtFinal,tipoEntradaSaida,ativo,codigosCbenef'
        + '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + this.filter_query_ativo +
        '&$orderby=codigo')
        .subscribe(ref => this.loadRegras(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/impostoregrafiscal?$select=id,nome,params' +
        '&$orderby=nome')
        .subscribe(ref => this.loadImpostos(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());
      this.httpService.wait();
      this.httpService.get('/custom/cadastro/get-regime-tributario(' + this.dblinkedSessionService.empresa.id + ')')
        .subscribe(ref => {
          this.regimeTributario = ref.error ? 0 : ref.regime;
        },
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/financeiro/buscar-sufixo-cfop()')
        .subscribe(ref => this.loadSufixoCfop(ref),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/cstipi?$select=id,cst,descricao' +
        '&$orderby=cst')
        .subscribe(ref => this.loadCstIpi(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/cstpiscofins?$select=id,cst,descricao' +
        '&$orderby=cst')
        .subscribe(ref => this.loadCstPisCofins(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/csticmstabelab?$select=id,cst,descricao' +
        '&$orderby=cst')
        .subscribe(ref => this.loadCstIcmsTabelaB(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/regimeincidencia?$select=id,nome' +
        '&$orderby=nome')
        .subscribe(ref => this.loadRegimeIncidencia(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());
      this.httpService.wait();
      this.httpService.get('/csosn?$select=id,codigo,descricao&$orderby=codigo')
        .subscribe(codE => {
          this.opcoesCsosn = [];
          this.opcoesCsosn.push({ label: 'Selecione CSOSN', value: null });
          codE.value.forEach(csosn => {
            this.opcoesCsosn.push({ label: csosn.codigo + ' - ' + csosn.descricao, value: csosn.codigo });
          });
        },
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.adicionarTodasEmpresasGrupoNaSelecao()
    }
  }

  loadCodigosEnquadramento(){
    if(this.operacaoRegraFiscal.tipoEntradaSaida.id !== null && this.operacaoRegraFiscal.tipoEntradaSaida.id !== undefined){
      this.httpService.wait();
      this.httpService.get('/custom/cadastro/buscar-infos-comuns', [this.dblinkedSessionService.empresa.id, 3, this.operacaoRegraFiscal.tipoEntradaSaida.id == 1 ? 0 : 1])
        .subscribe(
          result => {
            this.opcoesCodEnqIpi = [];
            this.opcoesModBcIcms = [];
            const relacTypeOption = {'CODENQIPI': this.opcoesCodEnqIpi, 'MODBCICMS': this.opcoesModBcIcms};

            for(let line of result) {
              const optionList = relacTypeOption[line.tipo?.toUpperCase()];
              if(!optionList) continue;

              optionList.push({ label: line.desc, value: line.id });
            }
          }, error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());
        }
  }

  loadRegras(value: Array<OperacaoRegraFiscal>) {
    this.regras = value;
    this.regras.forEach(regraFiscal => {
      regraFiscal.dtInicialFormatada = this.converteDataParaFormatoBrasileiro(this.adicionaUmDia(regraFiscal.dtInicial)),
      regraFiscal.dtFinalFormatada = this.converteDataParaFormatoBrasileiro(this.adicionaUmDia(regraFiscal.dtFinal)),
      regraFiscal.statusRegra = regraFiscal.ativo ? 'Ativo' : 'Inativo'
    })
  }

  onRowSelectRegra(value: any) {
    this.operacaoRegraFiscalEditar = value.data;
    this.operacaoRegraFiscal = value.data;

    this.checaStatusLote = this.regrasFiscaisSelecionadas.every(x => x.ativo === value.data.ativo)

    if(this.regrasFiscaisSelecionadas.length > 1) this.operacaoRegraFiscalEditar = null
    this.changeToolbar()
  }

  onRowUnselect(event) {
    this.checaStatusLote = this.regrasFiscaisSelecionadas.every(x => x.ativo === true)
    if (this.checaStatusLote === false) this.checaStatusLote = this.regrasFiscaisSelecionadas.every(x => x.ativo === false)

    if(this.regrasFiscaisSelecionadas.length === 1){
      this.operacaoRegraFiscalEditar = this.regrasFiscaisSelecionadas[0]
    } else {
      this.operacaoRegraFiscalEditar = null;
    }
    this.changeToolbar()
  }

  selecionarTudo() {
    this.checaStatusLote = this.regrasFiscaisSelecionadas.every(x => x.ativo === true)
    if (this.checaStatusLote === false) this.checaStatusLote = this.regrasFiscaisSelecionadas.every(x => x.ativo === false)

    this.changeToolbar()
  }

  changeToolbar() {
    const condicaoTelaInicialEOperacaoSelecionada = !this.formulario && !this.editar && (this.operacaoRegraFiscalEditar && this.operacaoRegraFiscalEditar.id) && this.empresaRealizaAlteracoes
    const condicaoPaginaInicialUmSelecionado = this.regrasFiscaisSelecionadas.length === 1
    const condicaoPaginaInicialMaisDeUmSelecionado = this.regrasFiscaisSelecionadas.length >= 1

    this.toolbarRegraFiscal.setVisible('add', !this.formulario && !this.editar && this.empresaRealizaAlteracoes)
    this.toolbarRegraFiscal.setVisible('back', this.formulario || this.editar)
    this.toolbarRegraFiscal.setVisible('delete', condicaoTelaInicialEOperacaoSelecionada && condicaoPaginaInicialUmSelecionado)
    this.toolbarRegraFiscal.setVisible('edit', condicaoTelaInicialEOperacaoSelecionada && condicaoPaginaInicialUmSelecionado)
    this.toolbarRegraFiscal.setVisible('reload', !this.formulario && !this.editar)
    this.toolbarRegraFiscal.setVisible('saveAlteration', this.formulario && this.editar)
    this.toolbarRegraFiscal.setVisible('saveNew', this.formulario && !this.editar)
    this.toolbarRegraFiscal.setVisible('clone', condicaoTelaInicialEOperacaoSelecionada && condicaoPaginaInicialUmSelecionado && !this.cadastroGrupoEmpresas)

    this.toolbarRegraFiscal.setVisible('btn-clone-expanded', condicaoPaginaInicialMaisDeUmSelecionado && this.cadastroGrupoEmpresas)
    this.toolbarRegraFiscal.setOptionVisible('btn-clone-expanded', 'btn-unique-clone', condicaoPaginaInicialUmSelecionado)

    this.toolbarRegraFiscal.setVisible('btn-able-disable', condicaoPaginaInicialMaisDeUmSelecionado && this.checaStatusLote && this.empresaRealizaAlteracoes)
  }


  loadImpostos(value: Array<any>) {
    this.opcoesImposto = new Array();

    this.opcoesImposto.push({ label: 'Selecione o Imposto', value: null });

    value.forEach(element => {
      this.opcoesImposto.push({
        label: element.nome,
        value: element
      });
    });
  }


  loadSufixoCfop(value: any) {
    this.opcoesCfop = new Array();

    this.opcoesCfop.push({ label: 'Selecione o Sufixo do CFOP', value: null });

    value.forEach(element => {
      this.opcoesCfop.push({
        label: element.sufixo,
        value: element.sufixo
      });
    });
  }


  loadRegimeIncidencia(value: Array<any>) {
    this.opcoesRegimeIncidencia = new Array();

    this.opcoesRegimeIncidencia.push({ label: 'Selecione o Regime de Incidência', value: null });

    value.forEach(element => {
      this.opcoesRegimeIncidencia.push({
        label: element.nome,
        value: element
      });
    });
  }


  loadCstIpi(value: Array<any>) {
    this.opcoesCstIpi = new Array();

    this.opcoesCstIpi.push({ label: 'Selecione a CST', value: null });

    value.forEach(element => {
      this.opcoesCstIpi.push({
        label: element.cst + ' - ' + element.descricao,
        value: element
      });
    });
  }


  loadCstIcmsTabelaB(value: Array<any>) {
    this.opcoesCstIcmsTabelaB = new Array();

    this.opcoesCstIcmsTabelaB.push({ label: 'Selecione a CST', value: null });

    value.forEach(element => {
      this.opcoesCstIcmsTabelaB.push({
        label: element.cst + ' - ' + element.descricao,
        value: element
      });
    });
  }


  loadCstPisCofins(value: Array<any>) {
    this.opcoesCstPis = new Array();
    this.opcoesCstCofins = new Array();

    this.opcoesCstPis.push({ label: 'Selecione a CST', value: null });

    value.forEach(element => {
      this.opcoesCstPis.push({
        label: element.cst + ' - ' + element.descricao,
        value: element
      });
    });

    this.opcoesCstCofins = deepCopy(this.opcoesCstPis)
  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  private criarRegra() {
    this.clonagem = false
    this.formulario = true;
    this.editar = false;
    this.operacaoRegraFiscal.ativo = true

    this.httpService.wait();
    this.httpService.get('/impostoregrafiscal?$select=id,nome,params' +
      '&$orderby=nome')
      .subscribe(ref => this.loadImpostosCriar(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

    this.changeToolbar()
  }


  loadImpostosCriar(value: any) {

    this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList = new Array();

    value.forEach(element => {
      if (element.nome === 'CIDE') {
        this.operacaoRegraFiscalDetalheCide = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalheCide.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalheCide.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalheCide.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalheCide.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheCide);
      } else if (element.nome === 'ICMS') {
        this.operacaoRegraFiscalDetalheIcms = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalheIcms.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalheIcms.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalheIcms.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalheIcms.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscalDetalheIcms.operacaoRegraFiscalDetalheCfopRelacList = new Array();


        for (let i = 0; i < 1; i++) {
          const operacaoDetalhe = new OperacaoRegraFiscalDetalheCfopRelac();
          operacaoDetalhe.operacaoRegraFiscalDetalhe = new OperacaoRegraFiscalDetalhe();
          operacaoDetalhe.operacaoRegraFiscalDetalhe = this.operacaoRegraFiscalDetalheIcms;
          operacaoDetalhe.cfopDest = '';
          operacaoDetalhe.cfopOrig = '';
          operacaoDetalhe.cstIcmsTabelaB = new CstIcmsTabelaB();
          this.operacaoRegraFiscalDetalheIcms.operacaoRegraFiscalDetalheCfopRelacList.push(operacaoDetalhe);
        }

        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIcms);


      } else if (element.nome === 'IPI') {
        this.operacaoRegraFiscalDetalheIpi = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalheIpi.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalheIpi.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalheIpi.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalheIpi.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIpi);

      } else if (element.nome === 'ICMS-ST') {
        this.operacaoRegraFiscalDetalheIcmsSt = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalheIcmsSt.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalheIcmsSt.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalheIcmsSt.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalheIcmsSt.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIcmsSt);
      } else if (element.nome === 'II') {
        this.operacaoRegraFiscalDetalheIi = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalheIi.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalheIi.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalheIi.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalheIi.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIi);
      } else if (element.nome === 'IRRF') {
        this.operacaoRegraFiscalDetalheIrrf = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalheIrrf.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalheIrrf.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalheIrrf.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalheIrrf.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIrrf);
      } else if (element.nome === 'PCC') {
        this.operacaoRegraFiscalDetalhePcc = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalhePcc.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalhePcc.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalhePcc.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalhePcc.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalhePcc);
      } else if (element.nome === 'INSS') {
        this.operacaoRegraFiscalDetalheInss = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalheInss.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalheInss.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalheInss.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalheInss.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheInss);
      } else if (element.nome === 'Simples') {
        this.operacaoRegraFiscalDetalheSimples = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalheSimples.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalheSimples.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalheSimples.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalheSimples.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheSimples);
      } else if (element.nome === 'PIS') {
        this.operacaoRegraFiscalDetalhePis = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalhePis.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalhePis.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalhePis.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalhePis.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalhePis);
      } else if (element.nome === 'COFINS') {
        this.operacaoRegraFiscalDetalheCofins = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalheCofins.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalheCofins.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalheCofins.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalheCofins.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheCofins);
      } else if (element.nome === 'ISSQN') {
        this.operacaoRegraFiscalDetalheIssqn = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalheIssqn.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalheIssqn.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalheIssqn.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalheIssqn.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIssqn);
      } else if (element.nome === 'ISS RET') {
        this.operacaoRegraFiscalDetalheIssRet = new OperacaoRegraFiscalDetalhe();
        this.operacaoRegraFiscalDetalheIssRet.impostoRegraFiscal = new ImpostoRegraFiscal();
        this.operacaoRegraFiscalDetalheIssRet.impostoRegraFiscal = element;
        this.operacaoRegraFiscalDetalheIssRet.operacaoRegraFiscal = new OperacaoRegraFiscal();
        this.operacaoRegraFiscalDetalheIssRet.operacaoRegraFiscal = this.operacaoRegraFiscal;
        this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(this.operacaoRegraFiscalDetalheIssRet);
      }
    });
  }


  loadOperacao(value: Array<TipoEntradaSaida>) {
    this.opcoesOperacao = [];
    this.opcoesOperacao.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesOperacao.push({ label: element.descricao, value: element.id });
    });
  }

  loadCodigosCBenef(value: Array<CodigosCBenef>) {
    this.opcoesCodBenef = [];
    this.opcoesCodBenef.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesCodBenef.push({ label: (element.codigo + " - " + element.descricao), value: element.id });
    });
  }


  incluirLinhaCfop() {
    alert('ok');
  }


  excluirRegra() {
    if (confirm('Esta regra será excluída, deseja continuar?')) {

      let operacaoRegraFiscalLog = {'id': this.operacaoRegraFiscal.id,
                                    'titulo': this.operacaoRegraFiscal.titulo,
                                    'objeto': this.operacaoRegraFiscal}

      this.httpService.wait();
      this.httpService.delete('/operacaoregrafiscal', this.operacaoRegraFiscal.id.toString())
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: '',
            detail: 'Projeto excluído com sucesso!'
          });
          this.empresaChanged();

          this.LogManagement.registerLog(
            LogModule.REGISTRATION,
            LogScreen.FISCAL_RULE_OPERATION,
            '/operacao-regra-fiscal',
            LogAction.DELETE,
            operacaoRegraFiscalLog.id,
            operacaoRegraFiscalLog.titulo,
            `A regra fiscal ${operacaoRegraFiscalLog.titulo} foi excluida.`,
            operacaoRegraFiscalLog.objeto
          )

        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: '',
            detail: 'Falha na exclusão. Tente novamente!'
          });
        }
      },
        // .subscribe(() => this.empresaChanged(),
          error => this.httpService.handleError(error, () => this.excluirRegra()),
          () => this.httpService.done());
    }
  }


  verificaParametros(event: any) {
    if (event.value !== null && (event.value.params !== null && event.value.params !== undefined)) {
      if (event.value.params.indexOf('incideImposto') >= 0) {
        this.incideImposto = true;
      } else {
        this.incideImposto = false;
      }
      if (event.value.params.indexOf('somaIpiBc') >= 0) {
        this.somaIpiBc = true;
      } else {
        this.somaIpiBc = false;
      }
      if (event.value.params.indexOf('relacaoCfopCst') >= 0) {
        this.relacaoCfopCst = true;
        if (!this.editar) {
          for (let i = 1; i < 4; i++) {
            const operacaoDetalhe = new OperacaoRegraFiscalDetalhe();
            operacaoDetalhe.operacaoRegraFiscal = new OperacaoRegraFiscal();
            operacaoDetalhe.operacaoRegraFiscal = this.operacaoRegraFiscal;
            this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(operacaoDetalhe);
          }
        }
      } else {
        this.relacaoCfopCst = false;
      }
      if (event.value.params.indexOf('regimeIncidencia') >= 0) {
        this.regimeIncidencia = true;
      } else {
        this.regimeIncidencia = false;
      }
      if (event.value.params.indexOf('cstIpi') >= 0) {
        this.cstIpi = true;
      } else {
        this.cstIpi = false;
      }
      if (event.value.params.indexOf('cstPis') >= 0) {
        this.cstPis = true;
      } else {
        this.cstPis = false;
      }
      if (event.value.params.indexOf('cstCofins') >= 0) {
        this.cstCofins = true;
      } else {
        this.cstCofins = false;
      }
      if (event.value.params.indexOf('inscritoMunicipio') >= 0) {
        this.inscritoMunicipio = true;
      } else {
        this.inscritoMunicipio = false;
      }
    } else {
      this.incideImposto = false;
      this.somaIpiBc = false;
      this.relacaoCfopCst = false;
      this.cstCofins = false;
      this.cstPis = false;
      this.cstIpi = false;
      this.regimeIncidencia = false;
      this.inscritoMunicipio = false;
    }
  }


  verificaParametrosEditar(event: any) {
    if (event !== null && (event.params !== null && event.params !== undefined)) {
      if (event.params.indexOf('incideImposto') >= 0) {
        this.incideImposto = true;
      } else {
        this.incideImposto = false;
      }
      if (event.params.indexOf('somaIpiBc') >= 0) {
        this.somaIpiBc = true;
      } else {
        this.somaIpiBc = false;
      }
      if (event.params.indexOf('relacaoCfopCst') >= 0) {
        this.relacaoCfopCst = true;
        if (!this.editar) {
          for (let i = 1; i < 4; i++) {
            const operacaoDetalhe = new OperacaoRegraFiscalDetalhe();
            operacaoDetalhe.operacaoRegraFiscal = new OperacaoRegraFiscal();
            operacaoDetalhe.operacaoRegraFiscal = this.operacaoRegraFiscal;
            this.operacaoRegraFiscal.operacaoRegraFiscalDetalheList.push(operacaoDetalhe);
          }
        }
      } else {
        this.relacaoCfopCst = false;
      }
      if (event.params.indexOf('regimeIncidencia') >= 0) {
        this.regimeIncidencia = true;
      } else {
        this.regimeIncidencia = false;
      }
      if (event.params.indexOf('cstIpi') >= 0) {
        this.cstIpi = true;
      } else {
        this.cstIpi = false;
      }
      if (event.params.indexOf('cstPis') >= 0) {
        this.cstPis = true;
      } else {
        this.cstPis = false;
      }
      if (event.params.indexOf('cstCofins') >= 0) {
        this.cstCofins = true;
      } else {
        this.cstCofins = false;
      }
      if (event.params.indexOf('inscritoMunicipio') >= 0) {
        this.inscritoMunicipio = true;
      } else {
        this.inscritoMunicipio = false;
      }
    } else {
      this.incideImposto = false;
      this.somaIpiBc = false;
      this.relacaoCfopCst = false;
      this.cstCofins = false;
      this.cstPis = false;
      this.cstIpi = false;
      this.regimeIncidencia = false;
      this.inscritoMunicipio = false;
    }
  }


  removerLinhaCfop(value: any) {
    if (confirm('Deseja excluir esta regra?')) {
      const index = this.operacaoRegraFiscalDetalheIcms.operacaoRegraFiscalDetalheCfopRelacList.findIndex(c => c === value);
      this.operacaoRegraFiscalDetalheIcms.operacaoRegraFiscalDetalheCfopRelacList.splice(index, 1);
    }
  }


  editarRegra() {
    this.clonagem = false
    this.formulario = true;
    this.editar = true;

    this.httpService.wait();
    this.httpService.get('custom/cadastro/get-operacao-fiscal', [this.operacaoRegraFiscalEditar.id]).subscribe(
      retorno => {
        this.operacaoRegraFiscal = retorno;
        this.loadCodigosEnquadramento();

        retorno.operacaoRegraFiscalDetalheList.forEach(element => {
          if (element.impostoRegraFiscal.id === 1) {
            this.operacaoRegraFiscalDetalheIcms = element;
            this.operacaoRegraFiscalDetalheIcms.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalheIcms.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 2) {
            this.operacaoRegraFiscalDetalheIpi = element;
            this.operacaoRegraFiscalDetalheIpi.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalheIpi.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 3) {
            this.operacaoRegraFiscalDetalhePis = element;
            this.operacaoRegraFiscalDetalhePis.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalhePis.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 4) {
            this.operacaoRegraFiscalDetalheCofins = element;
            this.operacaoRegraFiscalDetalheCofins.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalheCofins.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 5) {
            this.operacaoRegraFiscalDetalheIcmsSt = element;
            this.operacaoRegraFiscalDetalheIcmsSt.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalheIcmsSt.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 6) {
            this.operacaoRegraFiscalDetalheIi = element;
            this.operacaoRegraFiscalDetalheIi.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalheIi.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 7) {
            this.operacaoRegraFiscalDetalheCide = element;
            this.operacaoRegraFiscalDetalheCide.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalheCide.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 8) {
            this.operacaoRegraFiscalDetalheIrrf = element;
            this.operacaoRegraFiscalDetalheIrrf.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalheIrrf.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 9) {
            this.operacaoRegraFiscalDetalheIssqn = element;
            this.operacaoRegraFiscalDetalheIssqn.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalheIssqn.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 10) {
            this.operacaoRegraFiscalDetalhePcc = element;
            this.operacaoRegraFiscalDetalhePcc.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalhePcc.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 11) {
            this.operacaoRegraFiscalDetalheInss = element;
            this.operacaoRegraFiscalDetalheInss.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalheInss.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 12) {
            this.operacaoRegraFiscalDetalheSimples = element;
            this.operacaoRegraFiscalDetalheSimples.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalheSimples.operacaoRegraFiscal = this.operacaoRegraFiscal;
          } else if (element.impostoRegraFiscal.id === 16) {
            this.operacaoRegraFiscalDetalheIssRet = element;
            this.operacaoRegraFiscalDetalheIssRet.operacaoRegraFiscal = new OperacaoRegraFiscal();
            this.operacaoRegraFiscalDetalheIssRet.operacaoRegraFiscal = this.operacaoRegraFiscal;
          }
        })
      },
      error => this.httpService.handleError(error, () => this.editarRegra()),
      () => this.httpService.done()
    )

    this.changeToolbar()
  }


  salvarAlteracao() {
    this.operacaoRegraFiscal.editar = true;
    this.operacaoRegraFiscal.empresa = new Empresa();
    this.operacaoRegraFiscal.empresa.id = this.dblinkedSessionService.empresa.id;

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/salvar-operacao-fiscal', this.operacaoRegraFiscal)
      .subscribe(result => {
        if (!result.error) {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message });

          this.LogManagement.registerLog(
            LogModule.REGISTRATION,
            LogScreen.FISCAL_RULE_OPERATION,
            '/operacao-regra-fiscal',
            LogAction.EDIT,
            this.operacaoRegraFiscal.id,
            this.operacaoRegraFiscal.titulo,
            `A regra fiscal ${this.operacaoRegraFiscal.titulo} foi alterada.`,
            this.operacaoRegraFiscal
          )

          this.empresaChanged()
        } else {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message });
        }

      },
        error => this.httpService.handleError(error, () => this.salvarAlteracao()),
        () => this.httpService.done());
  }

  salvarRegra() {
    this.operacaoRegraFiscal.editar = false;
    this.operacaoRegraFiscal.empresa = new Empresa();
    this.operacaoRegraFiscal.empresa.id = this.dblinkedSessionService.empresa.id;

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/salvar-operacao-fiscal', this.operacaoRegraFiscal)
      .subscribe(result => {
        if (!result.error) {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message });

          this.LogManagement.registerLog(
            LogModule.REGISTRATION,
            LogScreen.FISCAL_RULE_OPERATION,
            '/operacao-regra-fiscal',
            LogAction.CREATE,
            result["operacaoFiscalId"],
            this.operacaoRegraFiscal.titulo,
            `A regra fiscal ${this.operacaoRegraFiscal.titulo} foi criada.`,
            this.operacaoRegraFiscal
          )

          this.empresaChanged();
        } else {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message });
        }

      },
        error => this.httpService.handleError(error, () => this.salvarAlteracao()),
        () => this.httpService.done());
  }


  cancelarEdicao() {
    if (confirm('Todas as alterações serão perdidas! Deseja continuar?')) this.empresaChanged()
  }


  validacao(): boolean {
    if ((this.operacaoRegraFiscal.codigo === null || this.operacaoRegraFiscal.codigo === undefined) || this.operacaoRegraFiscal.codigo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório' });
      return true
    }
    if (this.operacaoRegraFiscal.codigo.trim().length > 10){
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'O Código deve conter até 10 caracteres' });
      return true
    }
    if ((this.operacaoRegraFiscal.titulo === null || this.operacaoRegraFiscal.titulo === undefined) || this.operacaoRegraFiscal.titulo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Título é Obrigatório' });
      return true
    }
    if (this.operacaoRegraFiscal.titulo.length > 50) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Alerta: ', detail: "O título não pode conter mais que 50 carateres (atualmente " + this.operacaoRegraFiscal.titulo.length + ")." })
      return true
    }
    if ((this.operacaoRegraFiscal.titulo === null || this.operacaoRegraFiscal.titulo === undefined) || this.operacaoRegraFiscal.titulo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Título é Obrigatório' });
      return true
    }
    if ((this.operacaoRegraFiscal.tipoEntradaSaida.id === null || this.operacaoRegraFiscal.tipoEntradaSaida.id === undefined)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Operação é Obrigatório' });
      return true
    }
    return false
  }


  public adicionarLinha() {

    const relac = new OperacaoRegraFiscalDetalheCfopRelac();
    relac.cfopOrig = '';
    relac.cfopDest = '';
    relac.cstIcmsTabelaB = new CstIcmsTabelaB();
    relac.operacaoRegraFiscalDetalhe = new OperacaoRegraFiscalDetalhe();
    relac.operacaoRegraFiscalDetalhe = this.operacaoRegraFiscalDetalheIcms;
    this.operacaoRegraFiscalDetalheIcms.operacaoRegraFiscalDetalheCfopRelacList.push(relac);

  }

  hideMessage() { }

  async carregarDadosGrupoEmpresa() {
    if (this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.hasEmpresaSelected) {
      await this.verificarSeEmpresaPodeRealizarModificacoes()
      if (this.empresaRealizaAlteracoes) await this.getHabilitarCadastoGrupoEmpresas()
      this.changeToolbar()
    }
  }


  async verificarSeEmpresaPodeRealizarModificacoes() {
    const companyId = this.dblinkedSessionService.empresa.id
    this.empresaRealizaAlteracoes = false

    try {
      await this.httpService.get(`/custom/cadastro/verifica-grupo-empresa-permite-alteracao(${companyId})`).toPromise()
      .then(res => this.empresaRealizaAlteracoes = res.permissoes.permiteAlteracaoRegraFiscal)
      .catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }


  async getHabilitarCadastoGrupoEmpresas() {
    const empresaId = this.dblinkedSessionService.empresa.id

    this.httpService.wait()
    this.httpService.get(`/empresa?$select=cadastroGrupoEmpresa&$filter=id eq ${empresaId}`).subscribe(
      res => {
        this.cadastroGrupoEmpresas = res.value[0].cadastroGrupoEmpresa ? true : false

        if(this.cadastroGrupoEmpresas) this.buscarEmpresasGrupo()
      },
      error => this.httpService.handleError(error, () => this.getHabilitarCadastoGrupoEmpresas()),
      () => this.httpService.done()
    )
  }


  buscarEmpresasGrupo() {
    const empresaId = this.dblinkedSessionService.empresa.id

    this.httpService.wait()
    this.httpService.get(`/empresagrupocadastrorelac?$select=id,empresaRelac/nomeFantasia&$filter=empresa/id eq ` + empresaId).subscribe(
      res => {
        this.empresasGrupo = []
        this.empresasGrupoSelecionadas = []

        this.empresasGrupoSelecionadas.push(empresaId)

        res.value.forEach(company => {
          this.empresasGrupo.push({
            label: company.empresaRelac.nomeFantasia,
            value: company.empresaRelac.id
          })

          this.empresasGrupoSelecionadas.push(company.empresaRelac.id)
        })
      },
      error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro ao buscar as empresas do grupo' }),
      () => {
        this.configurarPlaceHolderSelecaoEmpresas()
        this.httpService.done()
      }
    )
  }

  configurarPlaceHolderSelecaoEmpresas() {
    this.placeHolderEmpresasSelecionadas = null
    if(this.empresasGrupoSelecionadas.length > 0) {
      let nomesEmpresas = []

      this.empresasGrupo.forEach(company => {
        if(this.empresasGrupoSelecionadas.indexOf(company.value) !== -1) nomesEmpresas.push(company.label)
      })

      if(nomesEmpresas.length > 0) this.placeHolderEmpresasSelecionadas = nomesEmpresas.join(', ')
    }
  }


  determinarMetodoSalvamento(clonagem = false) {
    if(clonagem && this.cadastroGrupoEmpresas && !this.clonagemNaPropriaEmpresa) {
      this.clonagem = true

      this.modalGrupoEmpresas = true
      this.tituloModalGrupoEmpresas = 'Criação de regra fiscal em várias empresas'
      this.descricaoModalGrupoEmpresas = 'Em quais outras empresas você deseja clonar esta regra fiscal?'
    } else if ((clonagem && this.clonagemNaPropriaEmpresa) || (clonagem && !this.cadastroGrupoEmpresas)) {
      this.clonagem = true

      this.modalGrupoEmpresas = true
      this.tituloModalGrupoEmpresas = 'Clonagem de regra fiscal'
      this.descricaoModalGrupoEmpresas = ''
    } else {
      const dadosInvalidos = this.validacao()

      if(dadosInvalidos) return

      if(this.cadastroGrupoEmpresas) {
        this.modalGrupoEmpresas = true
        this.tituloModalGrupoEmpresas = this.formulario && this.editar ? 'Edição de regra fiscal em várias empresas' : 'Criação de regra fiscal em várias empresas'
        this.descricaoModalGrupoEmpresas = this.formulario && this.editar ? 'Em quais outras empresas você deseja editar esta regra fiscal?' : 'Em quais outras empresas você deseja criar esta regra fiscal?'
      } else if(this.formulario && this.editar) {
        this.salvarAlteracao()
      } else {
        this.salvarRegra()
      }
    }
  }

  salvarRegraGrupoEmpresa() {
    const empresaId = this.dblinkedSessionService.empresa.id
    const userId = this.sessionService.loggedUser.id

    this.operacaoRegraFiscal.editar = false
    this.operacaoRegraFiscal.empresa = new Empresa()
    this.operacaoRegraFiscal.empresa.id = empresaId

    const payload = {
      operacaoRegraFiscal: this.operacaoRegraFiscal,
      empresaCriadoraId: empresaId,
      empresasGrupo: this.empresasGrupoSelecionadas,
      userId: userId
    }

    this.httpService.wait();
    this.httpService.post('/custom/grupo-empresa/criar-operacao-fiscal-grupo-empresa', payload).subscribe(
      response => this.exibirRespostaRequisicao(response),
      error => this.httpService.handleError(error, () => this.salvarAlteracao()),
      () => this.httpService.done()
    )
  }

  editarRegraGrupoEmpresa() {
    const empresaId = this.dblinkedSessionService.empresa.id
    const userId = this.sessionService.loggedUser.id
    const regraFiscalId = this.operacaoRegraFiscal.id

    this.operacaoRegraFiscal.editar = false
    this.operacaoRegraFiscal.empresa = new Empresa()
    this.operacaoRegraFiscal.empresa.id = empresaId

    const payload = {
      operacaoRegraFiscal: this.operacaoRegraFiscal,
      empresaCriadoraId: empresaId,
      empresasGrupo: this.empresasGrupoSelecionadas,
      userId: userId,
      regraFiscalOriginalId: regraFiscalId
    }

    this.httpService.wait();
    this.httpService.post('/custom/grupo-empresa/editar-operacao-fiscal-grupo-empresa', payload).subscribe(
      response => this.exibirRespostaRequisicao(response),
      error => this.httpService.handleError(error, () => this.salvarAlteracao()),
      () => this.httpService.done()
    )

  }

  excluirRegraGrupoEmpresa() {
    this.adicionarTodasEmpresasGrupoNaSelecao()

    const regraFiscalId = this.operacaoRegraFiscalEditar.id

    const payload = {
      operacaoRegraFiscal: regraFiscalId,
      empresasGrupo: this.empresasGrupoSelecionadas,
    }

    this.httpService.wait();
    this.httpService.post('/custom/grupo-empresa/excluir-operacao-fiscal-grupo-empresa', payload).subscribe(
      response => this.exibirRespostaRequisicao(response),
      error => this.httpService.handleError(error, () => this.excluirRegraGrupoEmpresa()),
      () => this.httpService.done()
    )
  }

  validarDadosClonagemNaPropriaEmpresa(): boolean {
    let dadosValidos = true

    if(this.operacaoRegraClonagemNovoCodigo === null || this.operacaoRegraClonagemNovoCodigo === undefined || this.operacaoRegraClonagemNovoCodigo.trim() === '' || this.operacaoRegraClonagemNovoCodigo.length === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Atenção!', detail: 'O código da nova operação é obrigatório' })
      dadosValidos = false
    }

    if(this.operacaoRegraClonagemNovoTitulo === null || this.operacaoRegraClonagemNovoTitulo === undefined || this.operacaoRegraClonagemNovoTitulo.trim() === '' || this.operacaoRegraClonagemNovoTitulo.length === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Atenção!', detail: 'A descrição da nova operação é obrigatória' })
      dadosValidos = false
    }

    return dadosValidos
  }

  clonarRegraGrupoEmpresa() {
    const empresaId = this.dblinkedSessionService.empresa.id
    const userId = this.sessionService.loggedUser.id

    let empresasSelecionadasFiltrada = this.empresasGrupoSelecionadas.filter(id => (id !== empresaId))

    if(this.clonagemNaPropriaEmpresa) {
      const dadosValidos = this.validarDadosClonagemNaPropriaEmpresa()

      if(!dadosValidos) return

      empresasSelecionadasFiltrada = new Array()
      empresasSelecionadasFiltrada.push(empresaId)
    }


    let regrasFiscaisIds = new Array()
    this.regrasFiscaisSelecionadas.forEach(regraFiscal => {
      regrasFiscaisIds.push(regraFiscal.id)
    })

    const payload = {
      empresasGrupo: empresasSelecionadasFiltrada,
      empresaClonadoraId: empresaId,
      userId: userId,
      novoCodigoOperacaoRegra: this.operacaoRegraClonagemNovoCodigo,
      novoTituloOperacaoRegra: this.operacaoRegraClonagemNovoTitulo,
      clonagemNaPropriaEmpresa: this.clonagemNaPropriaEmpresa,
      regrasFiscaisIds,
    }

    this.httpService.wait();
    this.httpService.post('/custom/grupo-empresa/clonar-operacao-fiscal-grupo-empresa', payload).subscribe(
      response => this.exibirRespostaRequisicao(response),
      error => this.httpService.handleError(error, () => this.clonarRegraGrupoEmpresa()),
      () => this.httpService.done()
    )
  }

  exibirRespostaRequisicao(response) {
    if (!response.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: response.message })
      this.empresaChanged()
    } else this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: response.message })
  }

  adicionarTodasEmpresasGrupoNaSelecao() {
    const empresaId = this.dblinkedSessionService.empresa.id

    this.empresasGrupoSelecionadas = new Array()

    this.empresasGrupoSelecionadas.push(empresaId)
    this.empresasGrupo.forEach(empresa => this.empresasGrupoSelecionadas.push(empresa.value))
  }

  batchEdit(status : boolean) {
    let message
    this.regrasFiscaisSelecionadas[this.regrasFiscaisSelecionadas.length-1].ativo === true ? message = 'Deseja desativar todas as regras selecionadas ?' : message = 'Deseja ativar todas as regras selecionadas ?'

    if(confirm(message)){
      let payload
      let updateStatus
      let listRegrasId = new Array()
      status === true ? updateStatus = false : updateStatus = true

      for ( let regras of this.regrasFiscaisSelecionadas) listRegrasId.push(regras.id)

      payload = {
          userId: this.sessionService.loggedUser.id,
          regrasId: listRegrasId,
          status: updateStatus
        }


      this.httpService.wait()
      this.httpService.post('/custom/cadastro/altera-status-regras-fiscais', payload)
        .subscribe(
            response => this.exibirRespostaRequisicao(response),
            error => this.httpService.handleError(error, () => this.batchEdit(updateStatus)),
            () => this.httpService.done()
        )
      this.empresaChanged()
    }
  }

  setarVariaveisClonagem(clonagemNaPropriaEmpresa: boolean) {
    this.clonagemNaPropriaEmpresa = clonagemNaPropriaEmpresa
    this.determinarMetodoSalvamento(true)
  }

  exportarExcel() {
    exportExcel(this.regras, 'operacao-regra-fiscal', this.colunasExportar)
  }

  converteDataParaFormatoBrasileiro(milisegundos: string) {
    if (milisegundos == '' || milisegundos == null) {
      return null
    }

    const d = new Date(milisegundos);

    return this.utilService.dateToStringDate(d);
  }

  adicionaUmDia = (milisegundos) => {
    if (milisegundos == '' || milisegundos == null) {
      return null
    }
    return milisegundos + 86400000
  };
}
