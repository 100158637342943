import { Component, OnInit, OnDestroy } from '@angular/core';
import { CadHistPadrao } from '../model/cad-hist-padrao.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from '../../shared/services/http.service';
import { SessionService } from '../../shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { ConfigService } from 'app/shared/services/config.service';
import { Empresa } from '../../cadastro/model/empresa.model';
import { MessageService } from 'primeng/api';
import { UtilService } from 'app/shared/services/util.service';

import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-cad-hist-padrao',
  templateUrl: './cad-hist-padrao.component.html',
  styleUrls: ['./cad-hist-padrao.component.scss'],
  providers: [CrudService]
})

export class CadHistPadraoComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public cadHistPadrao: CadHistPadrao = new CadHistPadrao();
  public registrosCadHist: Array<CadHistPadrao> = new Array();
  entityFilter: string = null;

  selectCadHist: Array<any> = new Array()

  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeViewSubscription: Subscription;
  private beforeEditSubscription: Subscription;
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;
  ids: Array<any> = new Array();
  codigo: string;
  descricao: string;
  dataInicial: string;
  dataFinal: string;

  title = 'Cadastro Histórico Padrão';
  subtitle = "Histórico Padrão";



  public toolbarCadHist = new Toolbar();


 toolbarMainCadHist = [
  { key: "voltar", icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
  { key: "edit", icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
  { key: "delete", icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
  { key: "add", icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
  { key: "saveNew", icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
  { key: "save", icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]



  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private configService: ConfigService,
    private messageService: MessageService,
    private utilService: UtilService,
    private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  ngOnInit() {
    this.toolbarService.hidden = true
    this.toolbarCadHist.setAll(this.toolbarMainCadHist)
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.carregar()
        }
      }
    );
  }

  carregar() {
    this.title = 'CADASTRO HISTÓRICO PADRÃO';
    this.subtitle = 'HISTÓRICO PADRÃO';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.selectCadHist = []

    this.limpaCamposForm();
    this.loadRegistros();

    this.toolbarCadHist.setVisible('voltar', false)
    this.toolbarCadHist.setVisible('edit', false)
    this.toolbarCadHist.setVisible('delete', false)
    this.toolbarCadHist.setVisible('add', true)
    this.toolbarCadHist.setVisible('saveNew', false)
    this.toolbarCadHist.setVisible('save', false)
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar';

    this.toolbarCadHist.setVisible('voltar', true)
    this.toolbarCadHist.setVisible('edit', false)
    this.toolbarCadHist.setVisible('delete', false)
    this.toolbarCadHist.setVisible('add', false)
    this.toolbarCadHist.setVisible('saveNew', true)
    this.toolbarCadHist.setVisible('save', false)
  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService
      .get(`/cadhistpadrao?$select=codHist,descrHist,dtInicial,dtFinal&$filter=empresa/id eq
            ${this.dblinkedSessionService.empresa.id} and period(dtInicial,dtFinal) eq
            ${this.dblinkedSessionService.periodo.year.value}
            &$orderby=codHist`)
      .subscribe(
        (retorno) => {
          this.registrosCadHist = new Array();
          retorno.value.forEach(item => {
            this.registrosCadHist.push({
              ...item,
              dtInicialFormatoBrasil: this.converteDataParaFormatoBrasileiro(item.dtInicial),
              dtFinalFormatoBrasil: this.converteDataParaFormatoBrasileiro(item.dtFinal),
            });
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar';
    this.editarConta = true;

    this.toolbarCadHist.setVisible('voltar', true)
    this.toolbarCadHist.setVisible('edit', false)
    this.toolbarCadHist.setVisible('delete', true)
    this.toolbarCadHist.setVisible('add', false)
    this.toolbarCadHist.setVisible('saveNew', false)
    this.toolbarCadHist.setVisible('save', true)

    this.selectCadHist = []

    this.limpaCamposForm();
    this.httpService.wait();
    this.httpService.get(`/cadhistpadrao(${this.ids[0]})?$select=codHist,descrHist,dtInicial,dtFinal&$count=true`)
      .subscribe(plano => {
        this.codigo = plano.value.codHist;
        this.descricao = plano.value.descrHist;
        this.dataInicial = this.adicionaUmDia(plano.value.dtInicial);
        this.dataInicial = this.converteDataParaFormatoBrasileiro(this.dataInicial);
        this.dataFinal = this.adicionaUmDia(plano.value.dtFinal);
        this.dataFinal = this.converteDataParaFormatoBrasileiro(this.dataFinal);
      },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      codHist: this.codigo,
      descrHist: this.descricao,
      dtInicial: this.dataInicial,
      dtFinal: this.dataFinal,
      empresa: {
        id: this.dblinkedSessionService.empresa.id
      },
    };

    this.httpService.wait();
    this.httpService.post('/cadhistpadrao?$select=codHist,descrHist,dtInicial,dtFinal&$count=true&$top=50', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const listasEnviar = {
      codHist: this.codigo,
      descrHist: this.descricao,
      dtInicial: this.converteDataParaFullText(this.dataInicial),
      dtFinal: this.converteDataParaFullText(this.dataFinal),
    };

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/cadhistpadrao', key, listasEnviar, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      this.httpService.wait();
      this.httpService.delete('/cadhistpadrao', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Registro excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());
    }
  }

  onRowSelect(event: any) {
    this.ids = new Array()
    this.ids.push(event.data.id);
    event.selected = false;
    this.changeToolbar()
  }

  onRowUnselect(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.changeToolbarUn()
  }

  changeToolbar() {
    this.toolbarCadHist.setVisible('edit', true)
    this.toolbarCadHist.setVisible('delete', true)
  }

  changeToolbarUn(){
    this.toolbarCadHist.setVisible('edit', false)
    this.toolbarCadHist.setVisible('delte', false)
  }


  validacao(): boolean {
    if ((this.codigo === null || this.codigo === undefined) || this.codigo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório' });
      return true;
    }
    if ((this.descricao === null || this.descricao === undefined) || this.descricao.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição é Obrigatória' });
      return true;
    }
    if ((this.dataInicial === null || this.dataInicial === undefined) || this.dataInicial.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data Inicial é Obrigatória' });
      return true;
    }
    if ((this.dataFinal === null || this.dataFinal === undefined) || this.dataFinal.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data Final é Obrigatória' });
      return true;
    }
    return false;
  }





  private converteDataParaFormatoBrasileiro(milisegundos: string) {// dd/mm/YYYY
    const d = new Date(milisegundos);

    return this.utilService.dateToStringDate(d);
  }

  private adicionaUmDia = (milisegundos) => milisegundos + 86400000;

  private converteDataParaFullText(data) {
    let novoFormato;

    if (typeof data === 'object') {//GMT... (recebe GMT quando usuário altera o campo)
      novoFormato = new Date(data);
    } else {// dd/mm/YYYY (recebe brasileiro quando campo não foi alterado)
      let [dia, mes, ano] = data.split('/');
      novoFormato = new Date(ano, mes - 1, dia);
    }

    return novoFormato;
  }

  private limpaCamposForm() {
    this.codigo = '';
    this.descricao = '';
    this.dataInicial = '';
    this.dataFinal = '';
  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    // this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }


  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
