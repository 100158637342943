import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Empresa } from '../../cadastro/model/empresa.model';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { OperacaoRegra } from '../model/operacao-regra.model';
import { MessageService } from 'primeng/api';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { TipoEntradaSaida } from 'app/util/model/tipo-entrada-saida.model';
import { OperacaoRegraFiscal } from '../model/operacao-regra-fiscal.model';
import { OperacaoRegraContabil } from '../model/operacao-regra-contabil.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { EmpresaAlmoxarifado } from '../model/empresa-almoxarifado.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';
import LogManagement from 'app/shared/common/log-management/log-control';
import { IndNatFrt } from 'app/util/model/ind-nat-frt.model';
import { BcCalculoCredito } from 'app/util/model/bc-calculo-credito.model';
import exportExcel from 'app/util/processing/exportExcel'

@Component({
  selector: 'app-operacao-regra',
  templateUrl: './operacao-regra.component.html',
  styleUrls: ['./operacao-regra.component.scss'],
  providers: [CrudService]
})

export class OperacaoRegraComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  // Boolean
  editarConta: boolean = false
  novaConta: boolean = false
  usesEyeMobile: boolean = false
  modalCloneOperacao: boolean = false
  showInativos: boolean = false
  checaStatusLote: boolean = true
  singleClone: boolean = false

  codigoClone: number = null

  // String
  title: string = 'OPERAÇÃO REGRA';
  subtitle: string = "OPERAÇÃO REGRA";
  descricaoClone: string = null
  filter_query_ativo: string = ""

  //Array
  registroRegra: Array<OperacaoRegra> = new Array();
  ids: Array<any> = new Array();
  lojasRelacionar: Array<any> = new Array()
  lojasRelacionadas: Array<any> = new Array()
  clearArray: Array<any> = new Array()
  regrasSelecionadas: Array<OperacaoRegra> = new Array()

  //Dropdown e multiselect
  opcoesAtivo: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
  opcoesEntrada: SelectItem[] = [{ label: 'Entrada', value: "Entrada" }, { label: 'Saída', value: "Saída" }];
  opcoesParent: SelectItem[] = []
  opcoesRegraFiscal: SelectItem[] = []
  opcoesRegraContabil: SelectItem[] = []
  opcoesContaContabil: SelectItem[] = []
  opcoesAlmox: SelectItem[] = []
  opcoesOperacaoRegraShowInativos: SelectItem[] = [{ label: 'Ativo', value: true }, { label: 'Inativo', value: false }];
  opcoesOperacaoRegra: SelectItem[] = [{ label: 'Ativo', value: true }];
  opcoesIndNatFrt: SelectItem[] = [];
  opcoesBcCalculoCredito: SelectItem[] = [];

  colunasExportar: Array<any> = ["cod", "descricao", "tipoEntradaSaidaDescricao", "geraFinanceiroLabel", "controlaEstoqueLabel", "dtInicialFormatoBrasil", "dtFinalFormatoBrasil", "statusRegra"]

  //Toolbar
  public toolbarOperacaoRegra = new Toolbar();
  toolbarMainOperacaoRegra = [
    {
      key: 'btn-clone-expanded', icon: 'content_copy', text: '', tooltip: 'Clonar', visible: true, disabled: false, color: 'default', dropdown: true,
      options: [
        { label: 'Clonar no grupo de empresas', callback: () => this.setDataToCloneInCurrentCompany(false) },
        { optionKey: 'btn-unique-clone', label: 'Clonar somente na empresa atual', callback: () => this.setDataToCloneInCurrentCompany(true) },
      ]
    },
    { key: 'btn-back', icon: 'navigate_before', text: 'Voltar', tooltip: '', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { key: 'btn-delete', icon: 'delete', text: 'Remover', tooltip: '', visible: false, disabled: false, color: 'default', onClick: () => this.cadastroGrupoEmpresa ? this.excluirOperacaoRegraGrupoEmpresa() : this.excluirRegistro() },
    { key: 'btn-clone', icon: 'content_copy', text: 'Clonar', tooltip: 'Clonar', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoParaSalvar(true) },
    { key: 'btn-save-new', icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoParaSalvar() },
    { key: 'btn-alteration', icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoParaSalvar() },
    { key: 'btn-add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { key: 'btn-able-disable', icon: 'edit', text: 'Ativar/Desativar', tooltip: 'Ativar ou Desativar em lote', visible: false, disabled: false, color: 'default', onClick: () => this.batchEdit(this.regrasSelecionadas[this.regrasSelecionadas.length-1].ativo) }
  ]

  //Classes
  operacaoRegra: OperacaoRegra = new OperacaoRegra()

  empresaRealizaAlteracoes: boolean = false
  cadastroGrupoEmpresa: boolean = false
  empresasGrupo: SelectItem[] = new Array()
  empresasGrupoSelecionadas: Array<any> = new Array()
  clonagemGrupoEmpresas: boolean = false
  modalGrupoEmpresas: boolean = false
  tituloModalGrupoEmpresas: string = ''
  descricaoModalGrupoEmpresas: string = ''
  codigoNovaRegraClonagemGrupoEmpresa: string = ''
  descricaoNovaRegraClonagemGrupoEmpresa: string = ''

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private utilService: UtilService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpClient: HttpClient
  ) {}

  private LogManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  ngOnInit() {
    this.toolbarService.hidden = true
    this.toolbarOperacaoRegra.setAll(this.toolbarMainOperacaoRegra)
    this.modalCloneOperacao = false
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.carregarDadosGrupoEmpresa()
          this.loadItems()
          this.loadRegraFiscal()
          this.loadRegraContabil()
          this.loadContaContabilEstoque()
          this.loadAlmoxarifado()
          this.checkEyeMobileUser()
          this.carregar()
          this.loadBcCalculoCredito()
          this.loadIndNatFrt()
        }
      }
    );
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  salvarRegistro() {
    if (this.novaConta) {
      this.salvarNovoRegistro()

    } else {
      this.salvarAlteracaoRegistro()
    }
  }

  //Método para abrir o modal para clonar uma nova regra
  openModalClonaRegra(){
    this.modalCloneOperacao = true
    this.codigoClone = null
    this.descricaoClone = null
  }

  // Método para clonar uma nova regra de operação
  async clonarRegistro (){
    const isValid = this.validateInput(this.codigoClone, this.descricaoClone)
    if(isValid){

        const allCodes = this.registroRegra.map((operation: any) => {
          return operation.cod.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z._ ])/g, '').toLowerCase().trim()
        })

        if(allCodes.includes(this.codigoClone.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z._ ])/g, '').toLowerCase().trim())){
          const message = {
            error: true,
            message: `O código informado já existe`
          };

          return this.exibirMensagem(message);
        }

        const data = {   //dados do codigo novo e da descrição nova
            userId: this.sessionService.loggedUser.id,
            codigoClone: this.codigoClone.toString(),
            descricaoClone: this.descricaoClone,
            idOpRegraOriginal: this.ids[0]
        }

        this.httpService.wait();
        const response = await this.httpService.post('custom/cadastro/clonar-operacao-regra', data).toPromise()
        this.exibirMensagem(response)
        this.httpService.done();
        this.carregar();
      } else {
        return;
      }
  }

  carregar() {
    this.ids = new Array()
    this.clearArray = new Array()
    this.regrasSelecionadas = new Array()

    this.resetarVariaveis()
    this.loadRegistros()
    this.changeToolbar()
    this.adicionarTodasEmpresasGrupoNaSelecao()
  }

  resetarVariaveis() {
    this.novaConta = false
    this.editarConta = false
    this.modalCloneOperacao = false
    this.singleClone = false
    this.clonagemGrupoEmpresas = false
    this.modalGrupoEmpresas = false
    this.tituloModalGrupoEmpresas = ''
    this.descricaoModalGrupoEmpresas = ''
    this.codigoNovaRegraClonagemGrupoEmpresa = ''
    this.descricaoNovaRegraClonagemGrupoEmpresa = ''
  }

  loadItems() {
    this.httpService.get(`tipoentradasaida?$select=id,descricao`)
      .subscribe(ref => {
        this.opcoesParent = [];
        this.opcoesParent.push({ label: 'Selecione', value: null });
        ref.value.forEach(element => {
          this.opcoesParent.push({
            label: element.descricao,
            value: element.id
          });
        });
      }, error => {
        console.log(error)
        this.exibirMensagem({ error: true, mensagem: "Erro ao buscar os tipos de operação, contate o suporte: " + error.message })
      }
      );
  }

  loadRegraFiscal() {
    this.httpService.get('/operacaoregrafiscal?$select=id,codigo,ativo&$filter=empresa/id eq '
      + this.dblinkedSessionService.empresa.id + '&$orderby=codigo')
      .subscribe(ref => {
        this.opcoesRegraFiscal = [];
        this.opcoesRegraFiscal.push({ label: 'Selecione', value: null }, { label: 'Nenhum', value: 0 });
        ref.value.forEach(element => {
          this.opcoesRegraFiscal.push({
            label: element.codigo,
            value: element.id
          });
        });
      }, error => {
        console.log(error)
        this.exibirMensagem({ error: true, mensagem: "Erro ao buscar as regras fiscais, contate o suporte: " + error.message })
      }
      );
  }

  loadRegraContabil() {
    this.httpService.get('/operacaoregracontabil?$select=id,descricao,tipoRegraContabil/id&$filter=empresa/id eq '
      + this.dblinkedSessionService.empresa.id + '&$orderby=descricao')
      .subscribe(ref => {
        this.opcoesRegraContabil = [];
        this.opcoesRegraContabil.push({ label: 'Selecione', value: null }, { label: 'Nenhum', value: 0 });
        ref.value.forEach(element => {
          if (element.tipoRegraContabil.id === 1 || element.tipoRegraContabil.id === 2 || element.tipoRegraContabil.id === 5) {
            this.opcoesRegraContabil.push({
              label: (element.id + ' - ' + element.descricao),
              value: element.id
            });
          }
        });
      }, error => {
        console.log(error)
        this.exibirMensagem({ error: true, mensagem: "Erro ao buscar as regras contabeis, contate o suporte: " + error.message })
      }
      );
  }

  loadContaContabilEstoque() {
    this.httpService.get('/planocontas?$select=id,codCta,descrCta&$'
      + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' and indCta eq ' + '\'' + 'A' + '\'' + '&$orderby=codCta')
      .subscribe(ref => {
        this.opcoesContaContabil = [];
        this.opcoesContaContabil.push({ label: 'Selecione', value: null }, { label: 'Nenhum', value: 0 });
        ref.value.forEach(element => {
          this.opcoesContaContabil.push({
            label: `${element.codCta} - ${element.descrCta}`,
            value: element.id
          });
        });
      }, error => {
        console.log(error)
        this.exibirMensagem({ error: true, mensagem: "Erro ao buscar as contas contabeis, contate o suporte: " + error.message })
      }
      );
  }

  loadAlmoxarifado() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        filiais: this.dblinkedSessionService.filiaisSelecionadas.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "estoque/almoxarifados", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res)
          } else {
            this.opcoesAlmox = []
            this.opcoesAlmox.push({ label: 'Selecione', value: null }, { label: 'Nenhum', value: null })
            res["data"].forEach(row => this.opcoesAlmox.push(row))
          }

          this.httpService.done()
        },
        (err) => {
          this.httpService.done()
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar a lista dos almoxarifados, contate o suporte: " + err.message })
        }
      )
  }

  criarNovo() {
    this.novaConta = true;
    this.operacaoRegra = new OperacaoRegra()
    this.operacaoRegra.tipoEntradaSaida = new TipoEntradaSaida()
    this.operacaoRegra.operacaoRegraFiscal = new OperacaoRegraFiscal()
    this.operacaoRegra.operacaoRegraContabil = new OperacaoRegraContabil()
    this.operacaoRegra.contaContabilEstoque = new PlanoContas()
    this.operacaoRegra.almoxarifadoOrigem = new EmpresaAlmoxarifado()
    this.operacaoRegra.almoxarifadoDestino = new EmpresaAlmoxarifado()
    this.operacaoRegra.almoxarifadoReserva = new EmpresaAlmoxarifado()
    this.operacaoRegra.operacaoRegraFiscalEntrada = new OperacaoRegraFiscal()
    this.operacaoRegra.operacaoRegraContabilEntrada = new OperacaoRegraContabil()
    this.operacaoRegra.indNatFrt = new IndNatFrt()
    this.operacaoRegra.bcCalculoCredito = new BcCalculoCredito();
    this.operacaoRegra.empresa = new Empresa()
    this.operacaoRegra.empresa.id = this.dblinkedSessionService.empresa.id
    this.operacaoRegra.ativo = true
    this.subtitle = 'Cadastrar Operação Regra';

    this.changeToolbar()
  }

  loadRegistros() {
    if(this.showInativos == false){
      this.filter_query_ativo = " and ativo eq true "
    }
    else{
      this.filter_query_ativo = " "
    }

    this.httpService.wait()
    this.httpService.get(`/operacaoregra?$select=cod,descricao,tipoEntradaSaida/descricao,geraFinanceiro,controlaEstoque,dtInicial,dtFinal,utilizaCustoMedio,utilizaPrecoVenda,criacaoOp,ativo,bloqueiaFiscal&$filter=empresa/id eq
    ${this.dblinkedSessionService.empresa.id} ` + this.filter_query_ativo + `&$orderby=cod`)
      .subscribe(
        retorno => {
          this.registroRegra = new Array();
          retorno.value.forEach(item => {
            this.registroRegra.push({
              ...item,
              dtInicialFormatoBrasil: this.converteDataParaFormatoBrasileiro(this.adicionaUmDia(item.dtInicial)),
              dtFinalFormatoBrasil: this.converteDataParaFormatoBrasileiro(this.adicionaUmDia(item.dtFinal)),
              tipoEntradaSaidaDescricao: item.tipoEntradaSaida?.descricao,
              geraFinanceiroLabel: item.geraFinanceiro ? 'Sim' : 'Não',
              controlaEstoqueLabel: item.controlaEstoque ? 'Sim' : 'Não',
              statusRegra: item.ativo ? 'Ativo' : 'Inativo'
            });
          })
          this.httpService.done()
        },
        error => {
          console.log(error)
          this.exibirMensagem({ error: true, mensagem: "Erro ao buscar as operações, contate o suporte: " + error.message })
          this.httpService.done()
        }
      );
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  loadEditarRegistro() {
    this.subtitle = 'Editar Operação Regra';

    this.httpService.wait()
    this.httpService.get(`/operacaoregra(${this.ids[0]})?$select=tipoEntradaSaida/id,cod,descricao,operacaoRegraFiscal/id,operacaoRegraContabil/id,geraFinanceiro,dtInicial,dtFinal,adto,controlaEstoque,contaContabilEstoque/id,transfEstoque,permiteAlteracaoAlmoxarifado,almoxarifadoOrigem/id,almoxarifadoDestino/id,alteraCusto,dadosAdicionais,almoxarifadoReserva/id,utilizaReserva,utilizaCustoMedio,utilizaPrecoVenda,criacaoOp,transfFilial,operacaoRegraFiscalEntrada/id,operacaoRegraContabilEntrada/id,grupoEmpresaOperacaoRegraOrigem/id,ativo,indNatFrt/id,bcCalculoCredito/id,bloqueiaFiscal,utilizaBasePcXml`)

      .subscribe(res => {
        this.operacaoRegra = new OperacaoRegra()
        this.operacaoRegra.id = res.value.id
        this.operacaoRegra.alteraCusto = res.value.alteraCusto
        this.operacaoRegra.controlaEstoque = res.value.controlaEstoque
        this.operacaoRegra.descricao = res.value.descricao
        this.operacaoRegra.transfEstoque = res.value.transfEstoque
        this.operacaoRegra.adto = res.value.adto
        this.operacaoRegra.geraFinanceiro = res.value.geraFinanceiro
        this.operacaoRegra.cod = res.value.cod
        this.operacaoRegra.dadosAdicionais = res.value.dadosAdicionais
        this.operacaoRegra.utilizaReserva = res.value.utilizaReserva;
        this.operacaoRegra.utilizaCustoMedio = res.value.utilizaCustoMedio;
        this.operacaoRegra.utilizaPrecoVenda = res.value.utilizaPrecoVenda;
        this.operacaoRegra.transfFilial = res.value.transfFilial;
        this.operacaoRegra.criacaoOp = res.value.criacaoOp;
        this.operacaoRegra.ativo = res.value.ativo;
        this.operacaoRegra.bloqueiaFiscal = res.value.bloqueiaFiscal;
        this.operacaoRegra.utilizaBasePcXml = res.value.utilizaBasePcXml;

        this.operacaoRegra.permiteAlteracaoAlmoxarifado = res.value.permiteAlteracaoAlmoxarifado
        res.value.dtInicial ? this.operacaoRegra.dtInicial = new Date(this.adicionaUmDia(res.value.dtInicial)) : this.operacaoRegra.dtInicial = null
        res.value.dtFinal ? this.operacaoRegra.dtFinal = new Date(this.adicionaUmDia(res.value.dtFinal)) : this.operacaoRegra.dtFinal = null

        this.operacaoRegra.operacaoRegraContabil = new OperacaoRegraContabil()
        res.value.operacaoRegraContabil ? this.operacaoRegra.operacaoRegraContabil.id = res.value.operacaoRegraContabil.id : this.operacaoRegra.operacaoRegraContabil.id = null
        this.operacaoRegra.almoxarifadoOrigem = new EmpresaAlmoxarifado()
        res.value.almoxarifadoOrigem ? this.operacaoRegra.almoxarifadoOrigem.id = res.value.almoxarifadoOrigem.id : this.operacaoRegra.almoxarifadoOrigem.id = null
        this.operacaoRegra.tipoEntradaSaida = new TipoEntradaSaida()
        res.value.tipoEntradaSaida ? this.operacaoRegra.tipoEntradaSaida.id = res.value.tipoEntradaSaida.id : this.operacaoRegra.tipoEntradaSaida.id = null
        this.operacaoRegra.contaContabilEstoque = new PlanoContas()
        res.value.contaContabilEstoque ? this.operacaoRegra.contaContabilEstoque.id = res.value.contaContabilEstoque.id : this.operacaoRegra.contaContabilEstoque.id = null
        this.operacaoRegra.operacaoRegraFiscal = new OperacaoRegraFiscal()
        res.value.operacaoRegraFiscal ? this.operacaoRegra.operacaoRegraFiscal.id = res.value.operacaoRegraFiscal.id : this.operacaoRegra.operacaoRegraFiscal.id = null
        this.operacaoRegra.almoxarifadoDestino = new EmpresaAlmoxarifado()
        res.value.almoxarifadoDestino ? this.operacaoRegra.almoxarifadoDestino.id = res.value.almoxarifadoDestino.id : this.operacaoRegra.almoxarifadoDestino.id = null
        this.operacaoRegra.almoxarifadoReserva = new EmpresaAlmoxarifado()
        res.value.almoxarifadoReserva ? this.operacaoRegra.almoxarifadoReserva.id = res.value.almoxarifadoReserva.id : this.operacaoRegra.almoxarifadoReserva.id = null

        this.operacaoRegra.operacaoRegraContabilEntrada = new OperacaoRegraContabil()
        res.value.operacaoRegraContabilEntrada ? this.operacaoRegra.operacaoRegraContabilEntrada.id = res.value.operacaoRegraContabilEntrada.id : this.operacaoRegra.operacaoRegraContabilEntrada.id = null
        this.operacaoRegra.operacaoRegraFiscalEntrada = new OperacaoRegraFiscal()
        res.value.operacaoRegraFiscalEntrada ? this.operacaoRegra.operacaoRegraFiscalEntrada.id = res.value.operacaoRegraFiscalEntrada.id : this.operacaoRegra.operacaoRegraFiscalEntrada.id = null

        this.operacaoRegra.indNatFrt = new IndNatFrt()
        res.value.indNatFrt ? this.operacaoRegra.indNatFrt.id = res.value.indNatFrt.id : this.operacaoRegra.indNatFrt.id = null

        this.operacaoRegra.bcCalculoCredito = new BcCalculoCredito()
        res.value.bcCalculoCredito ? this.operacaoRegra.bcCalculoCredito.id = res.value.bcCalculoCredito.id : this.operacaoRegra.bcCalculoCredito.id = null

        this.operacaoRegra.empresa = new Empresa()
        this.operacaoRegra.empresa.id = this.dblinkedSessionService.empresa.id

        this.operacaoRegra.grupoEmpresaOperacaoRegraOrigemId = res.value.grupoEmpresaOperacaoRegraOrigem !== null && res.value.grupoEmpresaOperacaoRegraOrigem !== undefined ? res.value.grupoEmpresaOperacaoRegraOrigem.id : null

        this.editarConta = true;
        this.httpService.done()

        //Para usuários da EyeMobile
        if (this.usesEyeMobile) this.listarLojas()

        this.changeToolbar()
      },
        err => {
          console.log(err)
          this.exibirMensagem({ error: true, mensagem: "Erro ao carregar a operação, contate o suporte: " + err.message })
          this.httpService.done()
        })
  }

  salvarNovoRegistro() {
    if (this.validacao()) return

    if (!this.operacaoRegra.operacaoRegraFiscal.id) this.operacaoRegra.operacaoRegraFiscal = null
    if (!this.operacaoRegra.operacaoRegraContabil.id) this.operacaoRegra.operacaoRegraContabil = null
    if (!this.operacaoRegra.contaContabilEstoque.id) this.operacaoRegra.contaContabilEstoque = null
    if (!this.operacaoRegra.almoxarifadoOrigem.id) this.operacaoRegra.almoxarifadoOrigem = null
    if (!this.operacaoRegra.almoxarifadoDestino.id) this.operacaoRegra.almoxarifadoDestino = null
    if (!this.operacaoRegra.almoxarifadoReserva.id) this.operacaoRegra.almoxarifadoReserva = null
    if (!this.operacaoRegra.operacaoRegraFiscalEntrada.id) this.operacaoRegra.operacaoRegraFiscalEntrada = null
    if (!this.operacaoRegra.operacaoRegraContabilEntrada.id) this.operacaoRegra.operacaoRegraContabilEntrada = null
    if (!this.operacaoRegra.indNatFrt.id) this.operacaoRegra.indNatFrt = null
    if (!this.operacaoRegra.bcCalculoCredito.id) this.operacaoRegra.bcCalculoCredito = null

    this.httpService.wait();
    this.httpService.post('/operacaoregra', this.operacaoRegra)
      .subscribe(result => {
        if (!(result === null || result === undefined)) {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Operação cadastrada com sucesso!' });

          this.LogManagement.registerLog(
            LogModule.REGISTRATION,
            LogScreen.OPERATION_RULE,
            '/operacao-regra',
            LogAction.CREATE,
            result.id,
            this.operacaoRegra.cod,
            `A operação regra (${this.operacaoRegra.cod}) foi criada com sucesso.`,
            this.operacaoRegra
          )

          this.carregar();
          if (this.usesEyeMobile) this.salvarLojaRelac(result.id)
        } else {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente' });
          return;
        }
        this.httpService.done()
      }, error => {
        console.log(error)
        this.exibirMensagem({ error: true, mensagem: "Erro ao salvar a operação, contate o suporte: " + error.message })
        this.httpService.done()
      }
      );
  }

  filtrarEdicao(listasEnviar) {
    if (this.operacaoRegra.empresa != null) {
      delete listasEnviar['empresa']
    }
  }
  salvarAlteracaoRegistro() {
    // if (this.validacao()) {
    //   return;
    // }

    if (!this.operacaoRegra.operacaoRegraFiscal.id) this.operacaoRegra.operacaoRegraFiscal.id = 0
    if (!this.operacaoRegra.operacaoRegraContabil.id) this.operacaoRegra.operacaoRegraContabil.id = 0
    if (!this.operacaoRegra.contaContabilEstoque.id) this.operacaoRegra.contaContabilEstoque.id = 0
    if (!this.operacaoRegra.almoxarifadoOrigem.id) this.operacaoRegra.almoxarifadoOrigem.id = 0
    if (!this.operacaoRegra.almoxarifadoDestino.id) this.operacaoRegra.almoxarifadoDestino.id = 0
    if (!this.operacaoRegra.almoxarifadoReserva.id) this.operacaoRegra.almoxarifadoReserva.id = 0
    if (!this.operacaoRegra.operacaoRegraFiscalEntrada.id) this.operacaoRegra.operacaoRegraFiscalEntrada.id = 0
    if (!this.operacaoRegra.operacaoRegraContabilEntrada.id) this.operacaoRegra.operacaoRegraContabilEntrada.id = 0
    if (!this.operacaoRegra.indNatFrt.id) this.operacaoRegra.indNatFrt = null
    if (!this.operacaoRegra.bcCalculoCredito.id) this.operacaoRegra.bcCalculoCredito = null
    this.filtrarEdicao(this.operacaoRegra)
    this.httpService.wait()
    this.httpService.patch('/operacaoregra', this.ids[0], this.operacaoRegra)
      .subscribe(
        result => {
          if (!(result === null || result === undefined)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração. Tente novamente' });
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Operação alterada com sucesso!' });

            this.LogManagement.registerLog(
              LogModule.REGISTRATION,
              LogScreen.OPERATION_RULE,
              '/operacao-regra',
              LogAction.EDIT,
              this.operacaoRegra.id,
              this.operacaoRegra.cod,
              `A operação regra (${this.operacaoRegra.cod}) foi alterada.`,
              this.operacaoRegra
            )

            if (this.usesEyeMobile) this.salvarLojaRelac(this.ids[0])
            this.carregar();
            this.editarConta = false
          }
          this.httpService.done()
        },
        error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Error', detail: 'Erro ao salvar as alterações, contatar o suporte:' + error.message })
      );
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      let opsExcluidas = []
      this.regrasSelecionadas.forEach(regra => opsExcluidas.push({'id': regra.id, 'codigo': regra.cod,'tipo': regra.tipoEntradaSaida.descricao, 'objeto': regra}))
      this.httpService.wait();
      this.httpService.delete('/operacaoregra', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Operação excluída com sucesso!'
            });
            this.carregar();
            this.ids = [];

            opsExcluidas.forEach(regra => this.LogManagement.registerLog(
              LogModule.REGISTRATION,
              LogScreen.OPERATION_RULE,
              '/operacao-regra',
              LogAction.DELETE,
              regra.id,
              regra.codigo,
              `A operação regra (${regra.codigo}) foi excluido.`,
              regra.objeto
            ))

          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
          this.httpService.done()
        },
          error => {
            console.log(error)
            this.exibirMensagem({ error: true, mensagem: "Erro ao excluir as operações, contate o suporte: " + error.message })
            this.httpService.done()
          });
    }
  }

  selectCheckbox(event: any) {
    if(this.ids.length === 0) this.ids = new Array()

    this.ids.push(event.data.id);
    this.regrasSelecionadas.push(event.data)
    console.log(this.regrasSelecionadas)
    this.checaStatusLote = this.regrasSelecionadas.every(x => x.ativo === event.data.ativo)

    event.selected = false;
    this.changeToolbar()
  }

  unselectCheckbox(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);

    const indexRegrasSelect = this.regrasSelecionadas.findIndex(c => c === event.data.id);
    this.regrasSelecionadas.splice(indexRegrasSelect, 1);

    this.checaStatusLote = this.regrasSelecionadas.every(x => x.ativo === true)
    if (this.checaStatusLote === false) this.checaStatusLote = this.regrasSelecionadas.every(x => x.ativo === false)


    event.selected = true;

    this.changeToolbar()
  }

  selecionarTudo() {
    this.ids = this.clearArray.map((operacao: any) => {return operacao.id})
    this.changeToolbar()
  }

  changeToolbar() {
    const condicaoTelaInicial = !this.novaConta && !this.editarConta
    const condicaoTelaInicialComPermissaoDeRealizarAlteracoes = condicaoTelaInicial && this.empresaRealizaAlteracoes
    const condicaoTelaInicialComUmaSelecao = condicaoTelaInicialComPermissaoDeRealizarAlteracoes && this.ids.length === 1
    const condicaoTelaInicialComUmaSelecaoEGrupoEmpresa = condicaoTelaInicialComUmaSelecao && this.cadastroGrupoEmpresa
    const condicaoTelaInicialComUmaSelecaoSemGrupoEmpresa = condicaoTelaInicialComUmaSelecao && !this.cadastroGrupoEmpresa
    const condicaoTelaInicialMaisDeUmaSelecao = condicaoTelaInicialComPermissaoDeRealizarAlteracoes && this.ids.length >= 1
    const condicaoTelaEdicao = !this.novaConta && this.editarConta && !condicaoTelaInicial
    const condicaoTelaNovaConta = this.novaConta && !this.editarConta && !condicaoTelaInicial

    this.toolbarOperacaoRegra.setVisible('btn-back', !condicaoTelaInicial)
    this.toolbarOperacaoRegra.setVisible('btn-add', condicaoTelaInicialComPermissaoDeRealizarAlteracoes)

    this.toolbarOperacaoRegra.setVisible('btn-clone', condicaoTelaInicialComUmaSelecaoSemGrupoEmpresa)
    this.toolbarOperacaoRegra.setVisible('btn-clone-expanded', condicaoTelaInicialMaisDeUmaSelecao && this.cadastroGrupoEmpresa)
    this.toolbarOperacaoRegra.setOptionVisible('btn-clone-expanded', 'btn-unique-clone', condicaoTelaInicialComUmaSelecaoEGrupoEmpresa)

    this.toolbarOperacaoRegra.setVisible('btn-edit', condicaoTelaInicialComUmaSelecao)
    this.toolbarOperacaoRegra.setVisible('btn-delete', condicaoTelaInicialComUmaSelecao)
    this.toolbarOperacaoRegra.setVisible('btn-save-new', condicaoTelaNovaConta)
    this.toolbarOperacaoRegra.setVisible('btn-alteration', condicaoTelaEdicao)
    this.toolbarOperacaoRegra.setVisible('btn-able-disable', condicaoTelaInicialMaisDeUmaSelecao && this.checaStatusLote)
  }

  validacao(): boolean {
    if (this.operacaoRegra.tipoEntradaSaida === null || this.operacaoRegra.tipoEntradaSaida === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Tipo (Entrada/Saída) é obrigatório' });
      return true
    }

    if ((this.operacaoRegra.cod === null || this.operacaoRegra.cod === undefined) || this.operacaoRegra.cod.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é obrigatório' });
      return true
    }
    if ((this.operacaoRegra.descricao === null || this.operacaoRegra.descricao === undefined) || this.operacaoRegra.descricao.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição é obrigatória' });
      return true
    }

    if (this.operacaoRegra.dtInicial === null || this.operacaoRegra.dtInicial === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data Inicial é obrigatória' });
      return true
    }

    if (this.operacaoRegra.utilizaCustoMedio && this.operacaoRegra.utilizaPrecoVenda) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Apenas uma das opções Utiliza Custo Médio/Utiliza preço de venda poderá estar selecionada ' });
      return true
    }

    let canSave = true
    this.registroRegra.forEach(row => {
      if (row.cod === this.operacaoRegra.cod && this.operacaoRegra.id != row.id) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Esse código já existe em uma outra operação.' });
        canSave = false
        return
      }
    })

    if (!canSave) return true

    return false
  }


  converteDataParaFormatoBrasileiro(milisegundos: string) {
    if (milisegundos == '' || milisegundos == null) {
      return null
    }

    const d = new Date(milisegundos);

    return this.utilService.dateToStringDate(d);
  }

  adicionaUmDia = (milisegundos) => {
    if (milisegundos == '' || milisegundos == null) {
      return null
    }
    return milisegundos + 86400000
  };

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  checkEyeMobileUser() {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }

    this.httpClient.get(this.configService.octaremoteUrl + '/cadastro/empresa-familia?familiaId=1', httpOptions).subscribe(
      res => {
        if (res['codigo'] === 200) {
          if (res['data'].includes(this.dblinkedSessionService.empresa.id)) this.usesEyeMobile = true
        }
        else this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg: ', detail: res['message'] })
      },
      error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: "Erro ao verificar se a empresa usa EyeMobile,contate o suporte: " + error.message })
    )
  }

  listarLojas() {
    let operacao_id = this.editarConta ? this.ids[0] : 0
    this.lojasRelacionar = new Array()
    this.lojasRelacionadas = new Array()
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    this.httpService.wait()
    this.httpClient.get(this.configService.octaremoteUrl + `/eyemobile/lojas-relac?empresaId=${this.dblinkedSessionService.empresa.id}&operacaoId=${operacao_id}`, httpOptions)
      .subscribe(
        res => {
          this.lojasRelacionar = res['data'].lidisponiveis
          this.lojasRelacionadas = res['data'].livinculadas
          this.httpService.done()
        },
        error => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: "Erro ao obter a relação de lojas com operação, contate o suporte: " + error.message })
          this.httpService.done()
        }
      )
  }

  salvarLojaRelac(operacaoId: number) {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    const infos = {
      operacaoId: operacaoId,
      lilojas: this.lojasRelacionadas
     }
    this.httpClient.post(this.configService.octaremoteUrl + '/eyemobile/lojas-relac', infos, httpOptions)
      .subscribe(
        res => {
          if (res["codigo"] === 500) {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: res["message"] })
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: res["message"] })
          }

        },
        error => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: "Erro ao salvar a relação de lojas com operação, contate o suporte: " + error.message })
        }
      )
  }

 // Método de validação de input do usuário, utilzado para o modal da clonagem
  validateInput(cod: number|string, description: string): boolean {
    if (!cod) {
      const message = {
        error: true,
        message: `O campo 'Código' é obrigatório.`
      };

      this.exibirMensagem(message);
      return false;

    } else if (!description) {

      const message = {
        error: true,
        message: `O campo 'Descrição' é obrigatório.`
      };

      this.exibirMensagem(message);
      return false;
    }
    return true;
  }


  async carregarDadosGrupoEmpresa() {
    if (this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.hasEmpresaSelected) {
      await this.verificarSeEmpresaPodeRealizarModificacoes()
      if (this.empresaRealizaAlteracoes) await this.getCadastroGrupoEmpresas()
      this.changeToolbar()
    }
  }

  async getCadastroGrupoEmpresas() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.get(`/empresa?$select=cadastroGrupoEmpresa&$filter=id eq ${companyId}`).subscribe(
      res => {
        this.cadastroGrupoEmpresa = res.value[0].cadastroGrupoEmpresa ? true : false

        if(this.cadastroGrupoEmpresa) this.getEmpresasGrupo()
      },
      error => this.httpService.handleError(error, () => this.getCadastroGrupoEmpresas())
    )
  }


  getEmpresasGrupo() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.get(`/empresagrupocadastrorelac?$select=id,empresaRelac/nomeFantasia&$filter=empresa/id eq ` + companyId).subscribe(
      res => {
        this.empresasGrupo = []
        this.empresasGrupoSelecionadas = []

        this.empresasGrupoSelecionadas.push(companyId)

        res.value.forEach(company => {
          this.empresasGrupo.push({
            label: company.empresaRelac.nomeFantasia,
            value: company.empresaRelac.id
          })

          this.empresasGrupoSelecionadas.push(company.empresaRelac.id)
        })
      },
      error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro ao buscar as empresas do grupo' })
    )
  }


  async verificarSeEmpresaPodeRealizarModificacoes() {
    const companyId = this.dblinkedSessionService.empresa.id
    this.empresaRealizaAlteracoes = false

    try {
      await this.httpService.get(`/custom/cadastro/verifica-grupo-empresa-permite-alteracao(${companyId})`).toPromise()
      .then(res => this.empresaRealizaAlteracoes = res.permissoes.permiteAlteracaoOperacaoRegra)
      .catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }

  adicionarTodasEmpresasGrupoNaSelecao() {
    const empresaId = this.dblinkedSessionService.empresa.id

    this.empresasGrupoSelecionadas = new Array()

    this.empresasGrupoSelecionadas.push(empresaId)
    this.empresasGrupo.forEach(empresa => this.empresasGrupoSelecionadas.push(empresa.value))
  }

  determinarMetodoParaSalvar(isClone: boolean = false): void {
    let validationFields: boolean = false

    if(!isClone) validationFields = this.validacao()

    if (this.cadastroGrupoEmpresa) this.adicionarTodasEmpresasGrupoNaSelecao()

    if (isClone && this.cadastroGrupoEmpresa && !this.singleClone) {
      this.clonagemGrupoEmpresas = true
      this.modalGrupoEmpresas = true
      this.tituloModalGrupoEmpresas = 'Clonagem de regra operação em várias empresas'
      this.descricaoModalGrupoEmpresas = 'Em quais outras empresas você deseja clonar esta regra operação?'
    } else if (!isClone && this.cadastroGrupoEmpresa && !validationFields) {
      this.clonagemGrupoEmpresas = false
      this.modalGrupoEmpresas = true
      this.tituloModalGrupoEmpresas = this.novaConta ? 'Criação de regra operação em várias empresas' : 'Edição de regra operação em várias empresas'
      this.descricaoModalGrupoEmpresas = this.novaConta ? 'Em quais outras empresas você deseja criar regra operação?' : 'Em quais outras empresas você deseja editar esta regra operação?'
    } else if ((isClone && !this.cadastroGrupoEmpresa) || this.singleClone) this.openModalClonaRegra()
    else if (!validationFields) {
      if(this.novaConta) this.salvarNovoRegistro()
      else this.salvarAlteracaoRegistro()
    }
  }

  criarOperacaoRegraGrupoEmpresa() {
    const tipoEntradaSaidaId = this.operacaoRegra.tipoEntradaSaida !== null ? this.operacaoRegra.tipoEntradaSaida.id : null
    const regraFiscalId = this.operacaoRegra.operacaoRegraFiscal !== null && this.operacaoRegra.operacaoRegraFiscal !== undefined ? this.operacaoRegra.operacaoRegraFiscal.id : null
    const regraContabilId = this.operacaoRegra.operacaoRegraContabil !== null && this.operacaoRegra.operacaoRegraContabil !== undefined ? this.operacaoRegra.operacaoRegraContabil.id : null
    const planoContasId = this.operacaoRegra.contaContabilEstoque !== null && this.operacaoRegra.contaContabilEstoque !== undefined ? this.operacaoRegra.contaContabilEstoque.id : null
    const empresasGrupoIds = this.empresasGrupoSelecionadas
    const regraFiscalEntradaId = this.operacaoRegra.operacaoRegraFiscalEntrada !== null && this.operacaoRegra.operacaoRegraFiscalEntrada !== undefined ? this.operacaoRegra.operacaoRegraFiscalEntrada.id : null
    const regraContabilEntradaId = this.operacaoRegra.operacaoRegraContabilEntrada !== null && this.operacaoRegra.operacaoRegraContabilEntrada !== undefined ? this.operacaoRegra.operacaoRegraContabilEntrada.id : null

    const payload = {
      empresasGrupo: empresasGrupoIds,
      empresaCriadoraId: this.dblinkedSessionService.empresa.id,
      userId: this.sessionService.loggedUser.id,
      tipoEntradaSaidaId,
      codigoOperacaoRegra: this.operacaoRegra.cod,
      decricaoOperacaoRegra: this.operacaoRegra.descricao,
      dadosAdicionais: this.operacaoRegra.dadosAdicionais,
      regraFiscalId,
      regraContabilId,
      gerarFinanceiro: this.operacaoRegra.geraFinanceiro,
      dataInicial: this.operacaoRegra.dtInicial,
      dataFinal: this.operacaoRegra.dtFinal,
      adiantamento: this.operacaoRegra.adto,
      controleEstoque: this.operacaoRegra.controlaEstoque,
      planoContasId,
      transferenciaDeEstoque: this.operacaoRegra.transfEstoque,
      permiteAlteracaoAlmoxarifado: this.operacaoRegra.permiteAlteracaoAlmoxarifado,
      alteraCusto: this.operacaoRegra.alteraCusto,
      utilizaCustoMedio: this.operacaoRegra.utilizaCustoMedio,
      utilizaPrecoVenda: this.operacaoRegra.utilizaPrecoVenda,
      transfFilial: this.operacaoRegra.transfFilial,
      utilizaAlmoxarifadoReserva: this.operacaoRegra.utilizaReserva,
      criacaoOrdemProducao: this.operacaoRegra.criacaoOp,
      regraFiscalEntradaId,
      regraContabilEntradaId,
      ativo: this.operacaoRegra.ativo,
      bloqueiaFiscal: this.operacaoRegra.bloqueiaFiscal
    }

    this.httpService.wait()
    this.httpService.post('/custom/grupo-empresa/criar-operacao-regra-grupo-empresa', payload)
      .subscribe(
        response => {
          this.exibirMensagem(response)
          this.carregar()
        },
        error => this.httpService.handleError(error, () => this.criarOperacaoRegraGrupoEmpresa()),
        () => this.httpService.done()
      )
  }

  editarOperacaoRegraGrupoEmpresa(){
    const tipoEntradaSaidaId = this.operacaoRegra.tipoEntradaSaida !== null ? this.operacaoRegra.tipoEntradaSaida.id : null
    const regraFiscalId = this.operacaoRegra.operacaoRegraFiscal !== null && this.operacaoRegra.operacaoRegraFiscal !== undefined ? this.operacaoRegra.operacaoRegraFiscal.id : null
    const regraContabilId = this.operacaoRegra.operacaoRegraContabil !== null && this.operacaoRegra.operacaoRegraContabil !== undefined ? this.operacaoRegra.operacaoRegraContabil.id : null
    const planoContasId = this.operacaoRegra.contaContabilEstoque !== null && this.operacaoRegra.contaContabilEstoque !== undefined ? this.operacaoRegra.contaContabilEstoque.id : null
    const empresasGrupoIds = this.empresasGrupoSelecionadas
    const regraFiscalEntradaId = this.operacaoRegra.operacaoRegraFiscalEntrada !== null && this.operacaoRegra.operacaoRegraFiscalEntrada !== undefined ? this.operacaoRegra.operacaoRegraFiscalEntrada.id : null
    const regraContabilEntradaId = this.operacaoRegra.operacaoRegraContabilEntrada !== null && this.operacaoRegra.operacaoRegraContabilEntrada !== undefined ? this.operacaoRegra.operacaoRegraContabilEntrada.id : null

    const payload = {
      regraFiscalId,
      planoContasId,
      regraContabilId,
      tipoEntradaSaidaId,
      regraFiscalEntradaId,
      regraContabilEntradaId,
      operacaoRegraId: this.operacaoRegra.id,
      ativo: this.operacaoRegra.ativo,
      grupoEmpresaOperacaoRegraOrigemId: this.operacaoRegra.grupoEmpresaOperacaoRegraOrigemId,
      empresasGrupo: empresasGrupoIds,
      empresaCriadoraId: this.dblinkedSessionService.empresa.id,
      userId: this.sessionService.loggedUser.id,
      codigoOperacaoRegra: this.operacaoRegra.cod,
      decricaoOperacaoRegra: this.operacaoRegra.descricao,
      dadosAdicionais: this.operacaoRegra.dadosAdicionais,
      gerarFinanceiro: this.operacaoRegra.geraFinanceiro,
      dataInicial: this.operacaoRegra.dtInicial,
      dataFinal: this.operacaoRegra.dtFinal,
      adiantamento: this.operacaoRegra.adto,
      controleEstoque: this.operacaoRegra.controlaEstoque,
      transferenciaDeEstoque: this.operacaoRegra.transfEstoque,
      permiteAlteracaoAlmoxarifado: this.operacaoRegra.permiteAlteracaoAlmoxarifado,
      alteraCusto: this.operacaoRegra.alteraCusto,
      utilizaCustoMedio: this.operacaoRegra.utilizaCustoMedio,
      utilizaPrecoVenda: this.operacaoRegra.utilizaPrecoVenda,
      transfFilial: this.operacaoRegra.transfFilial,
      utilizaAlmoxarifadoReserva: this.operacaoRegra.utilizaReserva,
      criacaoOrdemProducao: this.operacaoRegra.criacaoOp,
      bloqueiaFiscal: this.operacaoRegra.bloqueiaFiscal
    }

    this.httpService.wait()
    this.httpService.post('/custom/grupo-empresa/editar-operacao-regra-grupo-empresa', payload)
      .subscribe(
        response => {
          this.exibirMensagem(response)
          this.carregar()
        },
        error => this.httpService.handleError(error, () => this.editarOperacaoRegraGrupoEmpresa()),
        () => this.httpService.done()
      )
  }

  excluirOperacaoRegraGrupoEmpresa(){
    const confirmacaoExclusao = confirm("Deseja excluir este registro? Essa ação afetará as outras empresas do grupo")

    if(!confirmacaoExclusao) return

    this.adicionarTodasEmpresasGrupoNaSelecao()
    const operacaoRegraId = this.ids[0]

    const payload = {
      empresasGrupo: this.empresasGrupoSelecionadas,
      operacaoRegraId,
    }

    this.httpService.wait()
    this.httpService.post('/custom/grupo-empresa/excluir-operacao-regra-grupo-empresa', payload)
      .subscribe(
        response => {
          this.exibirMensagem(response)
          this.carregar()
        },
        error => this.httpService.handleError(error, () => this.excluirOperacaoRegraGrupoEmpresa()),
        () => this.httpService.done()
      )
  }

  clonarRegraGrupoEmpresa(){
    const empresaId = this.dblinkedSessionService.empresa.id

    const empresasSelecionadas = this.empresasGrupoSelecionadas.filter(empresaSelecionadaId => empresaSelecionadaId !== empresaId)

    if(empresasSelecionadas.length <= 0) return this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: 'Selecione ao menos uma empresa para clonar a regra'})

    const operacoesRegrasOriginaisIds = this.ids
    const empresaClonadoraId = empresaId
    const empresasGrupo = empresasSelecionadas
    const userId = this.sessionService.loggedUser.id

    const payload = { empresasGrupo, operacoesRegrasOriginaisIds, empresaClonadoraId, userId }

    this.httpService.wait()
    this.httpService.post('/custom/grupo-empresa/clonar-operacao-regra-grupo-empresa', payload).subscribe(
      response => {
        this.exibirMensagem(response)
        this.carregar()
      },
      error => this.httpService.handleError(error, () => this.excluirOperacaoRegraGrupoEmpresa()),
      () => this.httpService.done()
    )
  }

  batchEdit(status : boolean) {
      let message
      this.regrasSelecionadas[this.regrasSelecionadas.length-1].ativo === true ? message = 'Deseja desativar todas as regras selecionadas ?' : message = 'Deseja ativar todas as regras selecionadas ?'
      if(confirm(message)){
        let payload
        let updateStatus
        let listRegrasId = new Array()
        status === true ? updateStatus = false : updateStatus = true

        for ( let regras of this.ids) listRegrasId.push(regras)

        payload = {
            userId: this.sessionService.loggedUser.id,
            regrasId: listRegrasId,
            status: updateStatus
          }


        this.httpService.wait()
        this.httpService.post('/custom/cadastro/altera-status-regras', payload)
          .subscribe(
              response => this.exibirMensagem(response),
              error => this.httpService.handleError(error, () => this.batchEdit(updateStatus)),
              () => this.httpService.done()
          )
        this.carregar()
      }
    }

    setDataToCloneInCurrentCompany(singleClone: boolean) {
      this.singleClone = singleClone
      this.determinarMetodoParaSalvar(true)
    }

    loadIndNatFrt() {
      this.httpService.get('/indnatfrt?$select=id,codigo,descricao&$orderby=codigo')
        .subscribe(ref => {
          this.opcoesIndNatFrt = [];
          this.opcoesIndNatFrt.push({ label: 'Selecione', value: null }, { label: 'Nenhum', value: 0 });
          ref.value.forEach(element => {
              this.opcoesIndNatFrt.push({
                label: (element.codigo + ' - ' + element.descricao),
                value: element.id
              });
          });
        }, error => {
          console.log(error)
          this.exibirMensagem({ error: true, mensagem: "Erro ao buscar o indicador de natureza de frete, contate o suporte: " + error.message })
        }
        );
    }

    loadBcCalculoCredito() {
      this.httpService.get('/bccalculocredito?$select=id,codigo,descricao&$orderby=codigo')
        .subscribe(ref => {
          this.opcoesBcCalculoCredito = [];
          this.opcoesBcCalculoCredito.push({ label: 'Selecione', value: null }, { label: 'Nenhum', value: 0 });
          ref.value.forEach(element => {
              this.opcoesBcCalculoCredito.push({
                label: (element.codigo + ' - ' + element.descricao),
                value: element.id
              });
          });
        }, error => {
          console.log(error)
          this.exibirMensagem({ error: true, mensagem: "Erro ao buscar a Base de Calculo de Credito, contate o suporte: " + error.message })
        }
        );
    }

    exportarExcel() {
      exportExcel(this.registroRegra, 'operacao-regra', this.colunasExportar)
    }
}
