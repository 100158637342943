import { OnInit, OnDestroy } from '@angular/core';

import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from 'app/shared/services/session.service';
import { UtilService } from 'app/shared/services/util.service';
import { isSameDay, isSameMonth} from 'date-fns';
import { Subject } from 'rxjs/Subject';
import { CrudService } from 'app/shared/services/crud.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgModule } from '@angular/core';

declare var $: any;

import { Component, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import { ConfigService } from 'app/shared/services/config.service';


import { Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-calendario',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'calendario.component.html',
  styleUrls: ['./calendario.component.scss'
  ],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CalendarioComponent implements OnInit, OnDestroy{

  // @Input() view: string;

  // @Input() viewDate: Date;

  // @Input() locale: string = 'pt';

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();

  // Functions animated show close box card
  currentState = 'initial';
  currentStateTable = 'initial';
  ctrlClick: boolean = false;
  calendarioUrl: string;

  changeState() {
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }


  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;
  private sessionSubscription: Subscription;
  activeDayIsOpen: boolean;
  locale: string = 'pt';
  linkCalendario: string;
  corRecebimentoPendente: string = '#488aff';
  corRecebimentoVencido: string = '#488add';
  corRecebimentoBaixaParcial: string = '#488abb';
  corRecebimentoBaixado: string = '#488a99';

  corPagamentoPendente: string = '#ff2222';
  corPagamentoVencido: string = '#dd2222';
  corPagamentoBaixaParcial: string = '#bb2222';
  corPagamentoBaixado: string = '#992222';

  corDesconhecida: string = '#999999'



  constructor(
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private configService: ConfigService,
    private crudService: CrudService,
    private router: Router,
    private location: Location,
    private messageService: MessageService
  ) { }

  //para o título abrir em uma nova janela com ctrl+click
  @HostListener('window:keydown.control', ['$event'])
  @HostListener('window:keyup.control', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.type == "keydown"){
      this.ctrlClick = true;
    }
    else if (event.type == "keyup"){
      this.ctrlClick = false;
    }
  }


  view: string = 'month';
  msgs: Message[];
  viewDate: Date = new Date(Date.now());
  listaEventos: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();



  ngOnInit(){

    /*HIDE SIDEBAR FULLSCREEN (DO NOT REMOVE)*/
    $(document).ready(() => {
      $(".btn-full").click(
        function() {
          $(".mat-drawer-side").toggleClass('d-none');
          }
        );
    });

    this.calendarioUrl = this.configService.defaultUrlApiUpload;
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.defaultDate = new Date();
    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
            let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
        // formatando meses com dois digitos
        let mes = this.defaultDate.getMonth() > 8 ?
                    (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
        this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
        this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

          if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
            mes = this.dblinkedSessionService.periodo.month.value > 8 ?
              this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
            const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
            this.defaultDate = new Date(dt);
            // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
            lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
            this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
            this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();
          }
          this.atualizarPagina();
          this.toolbarEnable();
        }
    });
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }
// FIM DO BOILERPLATE


  eventClicked({ event }: { event: CalendarEvent }): void {
    let url = '';
    if ([this.corPagamentoPendente, this.corPagamentoVencido,
      this.corPagamentoBaixado, this.corPagamentoBaixaParcial].includes(event.color.primary)){
      //eh pagamento
        url = '/receb-pagto/cp/baixar/' + event.id;
    }
    else if ([this.corRecebimentoPendente, this.corRecebimentoVencido,
    this.corRecebimentoBaixado, this.corRecebimentoBaixaParcial].includes(event.color.primary)){
      //eh recebimento
        url = '/receb-pagto/cr/baixar/' + event.id;
    }

    if (url.length > 4){
      this.router.navigateByUrl(url);
    }
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      }
      else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
      // this.refresh.next()
    }
  }


  getEventos(){
    if (this.dataIniSearch !== null && this.dataFinSearch !== null) {
      this.httpService.wait();
      this.httpService.get(this.configService.defaultUrlApiUpload + '/custom/financeiro/calendario/get-eventos',
      [this.dblinkedSessionService.empresa.id])
        .subscribe(
          ret => {
            this.listaEventos = [];

            if (ret["erro"] === true){
              this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: ret["msg"] });
            }
            else{
              this.calendarioUrl = this.configService.defaultUrlApiUpload + '/custom/financeiro/calendario/exportar-eventos/' + ret[0].empresa_hash;
              ret.forEach(element => {
                let compensaFuso = new Date(element.dt_vencimento);
                // compensaFuso.setHours(compensaFuso.getHours() + 3)
                let cor = "";
                let url = "";
                if (element.rorp == "recebimento"){
                  if (element.status == "PENDENTE"){
                    cor = this.corRecebimentoPendente;
                  }
                  else if (element.status == "VENCIDO"){
                    cor = this.corRecebimentoVencido;
                  }
                  else if (element.status == "BAIXADO"){
                    cor = this.corRecebimentoBaixado;
                  }
                  else if (element.status == "BAIXA PARCIAL"){
                    cor = this.corRecebimentoBaixaParcial;
                  }
                  else {
                    cor = this.corDesconhecida;
                  }
                }
                else if (element.rorp == "pagamento"){
                  if (element.status == "PENDENTE"){
                    cor = this.corPagamentoPendente;
                  }
                  else if (element.status == "VENCIDO"){
                    cor = this.corPagamentoVencido;
                  }
                  else if (element.status == "BAIXADO"){
                    cor = this.corPagamentoBaixado;
                  }
                  else if (element.status == "BAIXA PARCIAL"){
                    cor = this.corPagamentoBaixaParcial;
                  }
                  else {
                    cor = this.corDesconhecida;
                  }
                }

                this.listaEventos.push({
                  id: element.id,
                  title: "R$: " + String(element.valor) + " - " + String(element.descricao) + " - " + String(element.fantasia) + " - " + String(element.n_documento),
                  start: new Date(compensaFuso),
                  color: {
                    primary: cor,
                    secondary: '#D1E8FF'
                    }
                  });
                });
            }
          },
          error => this.httpService.handleError(error),
          () => {
            this.httpService.done();
            this.refresh.next();
          });
    }
  }


  // PARA CARREGAR O MODAL DE PAGAMENTOS
  // FIM DE MODAL DE PAGAMENTOS

  atualizarPagina(){
    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() > 8 ?
                    (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() > 8 ?
                    (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
                            this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
                            this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }
    this.viewDate = new Date(Date.now());
    this.linkCalendario = this.configService.defaultUrlApiUpload + "/custom/financeiro/calendario/exportar-eventos(" + this.dblinkedSessionService.empresa.id.toString() + ")";
    this.getEventos();
  }

  toolbarEnable() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('add');
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('remove');
  }

   /*Show Hide Calendar*/
   isCardCalendar: boolean = true;
   ShowHIdeCardCalendar(){
       this.isCardCalendar = !this.isCardCalendar;
   }

   /*FullScreen Calendar*/
   fullCalendar: boolean = false;
   fullScreenCalendar(){
       this.fullCalendar = !this.fullCalendar;
   }

}
