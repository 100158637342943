import { Empresa } from "app/cadastro/model/empresa.model";

export class BeneficioFiscal {
  id: number;
  cod: string;
  nome: string;
  empresa: Empresa;

  constructor(beneficioFiscal?: BeneficioFiscal | number) {
    if (beneficioFiscal !== undefined && beneficioFiscal !== null) {
      if (typeof beneficioFiscal === 'number') {
        this.id = beneficioFiscal;
      } else {
        this.id = beneficioFiscal.id;
        this.cod = beneficioFiscal.cod;
        this.nome = beneficioFiscal.nome;
        this.empresa = beneficioFiscal.empresa;
      }
    }
  }
}
