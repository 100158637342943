import { Empresa } from "app/cadastro/model/empresa.model";
import { Moeda } from "app/util/model/moeda.model";
import { StatusOrcamento } from "app/util/model/status-orcamento.model";
import { isNullOrUndefined } from "util";
import { ConfiguracaoImportacao } from "./configuracao-importacao.model";
import { OpcaoMoedaVersao } from "./opcao-moeda-versao.model";

export class Versao {
  id : number;
  ano : number;
  configVersao : ConfiguracaoImportacao;
  descricao : string;
  empresa : Empresa;
  moeda : Moeda;
  statusOrcamento : StatusOrcamento;
  taxaBudgetId: OpcaoMoedaVersao;
  taxaRealizadoId: OpcaoMoedaVersao;

constructor(versao?: Versao | number){

  if (!isNullOrUndefined(versao)) {
    if (typeof  versao === 'number') {
      this.id = versao;

    } else {
      this.id = versao.id;
      this.ano = versao.ano;
      this.configVersao = versao.configVersao;
      this.descricao = versao.descricao;
      this.empresa = versao.empresa;
      this.moeda = versao.moeda;
      this.statusOrcamento = versao.statusOrcamento;
      this.taxaBudgetId = versao.taxaBudgetId;
      this.taxaRealizadoId = versao.taxaRealizadoId;
    }


  }


}



}
