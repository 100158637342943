import { Moeda } from '../../util/model/moeda.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { View } from 'app/cadastro/model/view.model';

export class PlanoGerencial {
  id: number;
  codigo: string;
  nome: string;
  niveis: number;
  moeda: Moeda;
  empresa: Empresa;
  view: View;

  constructor(planoGerencial?: PlanoGerencial | number) {
    if (planoGerencial !== undefined && planoGerencial !== null) {
      if (typeof planoGerencial === 'number') {
        this.id = planoGerencial;
      } else {
        this.id = planoGerencial.id;
        this.codigo = planoGerencial.codigo;
        this.nome = planoGerencial.nome;
        this.niveis = planoGerencial.niveis;
        this.moeda = planoGerencial.moeda;
        this.empresa = planoGerencial.empresa;
        this.view = planoGerencial.view;
      }
    }
  }
}
