import { ConfigPattern } from "../interfaces/tray"

export function resetConfigPattern(): ConfigPattern {
  let configPattern: ConfigPattern = {
    baseurl: null,
    codigo: null,
    cliente_padrao_id: null,
    operacao_padrao_id: null
  }

  return {...configPattern}
}