import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Message, MessageService } from 'primeng/api';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-relacionar-campanha-carteira',
  templateUrl: './relacionar-campanha-carteira.component.html',
  styleUrls: ['./relacionar-campanha-carteira.component.scss'],
})
export class RelacionarCampanhaCarteiraComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  msgs: Message[] = [];

  titlePrincipal: string;
  subtitle: string;

  listaCarteiras: Array<any> = new Array();


  opcoesCampanhas  : SelectItem[];
  campanhaId : number = 0;

  listaCarteirasRelacionadas       : Array<any> = new Array();
  listaCarteirasSemRelacionamento  : Array<any> = new Array();



  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private dblinkedSessionService: DblinkedSessionService,
    private datePipe: DatePipe,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    // requisição

    this.titlePrincipal = 'CONFIGURAÇÕES';
    this.subtitle = 'RELACIONAR CAMPANHA CARTEIRA';
    this.titleService.title = 'Campanha carteira';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.campanhaId   = 0;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });
    }

  ngOnDestroy() {
    // this.toolbarService.clear();
    // this.toolbarService.hidden = false;
    // this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  data() {
    // this.dtCorte = new Date(this.timezonePipe.transform(this.dtCorte));
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

        this.listaCarteiras                 = new Array();

        this.listaCarteirasRelacionadas      = new Array();
        this.listaCarteirasSemRelacionamento = new Array();


        this.httpService.wait();
        this.httpService.get('custom/polozi/get-lista-campanhas')
          .subscribe(retorno => {
            this.opcoesCampanhas = [];
            this.opcoesCampanhas.push({ label: 'Selecione', value: 0 });

            retorno.forEach(element => {
              this.opcoesCampanhas.push({ label: element.nome, value: element.id });
            });

          }, error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());



      // renderizando a toolbar
      setTimeout(() => {
        this.toolbarEdit();
      }, 500);
    }
  }

  onFilterRms(event: any) {
    console.log('event: ' + event);
  }

  export() {
    // alert('export');
  }

  pesquisa() {
    // alert('pesquisa');
  }


 hideMessage() {

  }

  private toolbarEdit() {
    const todos = this.buttons.slice();
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('save');
    // this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('export');

    // this.toolbarService.alterarStatus('pesquisa', true, 'Detalhes Rateio', () => this.pesquisa());
    // this.toolbarService.alterarStatus('download', true, 'Download Anexos', () => this.export());
    // this.toolbarService.alterarStatus('voltar', true, 'Voltar', () => this.ngOnInit());
    // this.toolbarService.alterarStatus('atualizar', true, 'Atualizar RMS', () => this.change());
    // this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.ngOnInit());
    // this.toolbarService.alterarStatus('save', true, 'Salvar requisição', () => this.ngOnInit());
    //this.toolbarService.alterarStatus('edit', true, 'Editar requisição', () => this.ngOnInit());


    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Relacionamento', () => this.salvarRelacionamento());
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  salvarRelacionamento(){

    if (this.validacao()) {
      return;
    }



    if (confirm('Deseja realmente salvar este relacionamento?')) {
      const listaEnviar = {
        campanhaId                      : this.campanhaId,
        listaCarteirasRelacionadas       : this.listaCarteirasRelacionadas,
        listaCarteirasSemRelacionamento  : this.listaCarteirasSemRelacionamento,
        empresaId                       : this.empresa.id

      };


      this.httpService.wait();
      this.httpService.post('/custom/polozi/salvar-relacionamento-campanha-carteira', listaEnviar)
        .subscribe(result => {
          let existeErro = false;
          result.forEach(element => {
            if(element.error){
              existeErro = true;
              this.messageService.add({
                severity: element.severity,
                summary: 'MSG: ',
                detail: element.message
              });
            }
          });
          if(!existeErro){
            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Alteração salva com sucesso!'
            });
          }

          this.change();
          this.campanhaId = 0;

        },
          error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
          () => this.httpService.done());
    }

    // this.carteiraId = 0;

    // this.change();
  }

  validacao(){


    if (isNullOrUndefined( this.campanhaId) || this.campanhaId === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um usuário'
      });
      return true;
    }


    if (isNullOrUndefined(this.empresa.id) || this.empresa.id === 0 ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma empresa'
      });
      return true;
    }


    return false;

  }

  tabChange(event: any){
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Relacionamento', () => this.salvarRelacionamento());
  }

  buscarCarteiras(){
    this.listaCarteirasRelacionadas        = new Array();
    this.listaCarteirasSemRelacionamento   = new Array();


    if(this.campanhaId > 0){

      this.httpService.wait();
      this.httpService.get('custom/polozi/get-lista-campanha-carteiras',
        [this.campanhaId  ])
        .subscribe(retorno => {
          console.log(retorno);
          this.listaCarteirasRelacionadas        = new Array();
          this.listaCarteirasSemRelacionamento   = new Array();
          retorno.forEach(element => {
            if (element.relacionado) {
              this.listaCarteirasRelacionadas.push(element);
            } else {
              this.listaCarteirasSemRelacionamento.push(element);
            }

          });

        }, error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done() );

    }


  }





}
