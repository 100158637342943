export class Grupo {
  id: number;
  descricao: string;
  bloqueiaApuracao: boolean;

  constructor(grupo?: Grupo | number) {
    if (grupo !== undefined && grupo !== null) {
      if (typeof grupo === 'number') {
        this.id = grupo;
      } else {
        this.id = grupo.id;
        this.descricao = grupo.descricao;
        this.bloqueiaApuracao = grupo.bloqueiaApuracao;
      }
    }
  }
}
