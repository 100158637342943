import { isNullOrUndefined } from "util";

export class FormularioMotivoReagendamento{

  id                : number;
  descricao         : string;


  constructor(formularioMotivoReagendamento?: FormularioMotivoReagendamento | number) {
    if (!isNullOrUndefined(formularioMotivoReagendamento)) {
      if (typeof formularioMotivoReagendamento === 'number') {
        this.id = formularioMotivoReagendamento;
      } else {
        this.id         = formularioMotivoReagendamento.id;
        this.descricao  = formularioMotivoReagendamento.descricao;

      }
    }
  }

}