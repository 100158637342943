import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import LogManagement from 'app/shared/common/log-management/log-control';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import exportExcel from 'app/util/processing/exportExcel';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '../../../../node_modules/@angular/router';
import { TitleService } from '../../shared/services/title.service';

@Component({
  selector: 'app-notas-emitidas',
  templateUrl: './notas-emitidas.component.html',
  styleUrls: ['./notas-emitidas.component.scss'
  ],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class NotasEmitidasComponent extends DataTableBasicComponent
  implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;
  title: String;
  subtitle: String;
  nfseEmitidas: Array<any> = new Array();

  nfeEmitidas: Array<any> = new Array();

  msgCancelamentoNfe: String;
  modalCancelarNfe: boolean;
  modalCartaCorrecao: boolean;
  msgCartaCorrecaoNfe: String;

  modalPeriodo: boolean;
  validacaoError: boolean;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;
  defaultDate: Date;

  todasSelecionadas: boolean;
  idNfses: Array<any> = new Array();
  list2: Array<any> = new Array();
  nfse: Array<any> = new Array();
  valorAtual: number;
  valorSelecionados: number;
  private valor: number;
  nfeExport: Array<number> = new Array()


  msgs: Message[];
  msgsModal: Message[];

  statusPendente: boolean = true;
  statusEscriturada: boolean = false;
  statusCancelada: boolean = false;

  xmlPendente: Array<any> = new Array();
  xmlEscriturado: Array<any> = new Array();
  xmlCancelado: Array<any> = new Array();

  xml: Array<any> = new Array();

  //filter variables
  //table pending
  public filterValueDataEmissao: string;
  public filterValueRazaoSocial: string;
  public filterValueCnpjCpf: string;
  public filterValueNumeroDoc: string;
  public filterValueChave: string;
  public filterValueModelo: string;
  public filterValueValor: string;
  public filterValueStatus: string;

  //table carrying
  public filterValueEmissao2: string;
  public filterValueRazaoSocial2: string;
  public filterValueCnpj2: string;
  public filterValueCodigoControle2: string;
  public filterValuePedido2: string;
  public filterValueNumeroDoc2: string;
  public filterValueModelo2: string;
  public filterValueValor2: string;
  public filterValueUsuario2: string;
  public filterValueStatus2: string;

  //table canceled
  public filterValueData3: string;
  public filterValueRazaoSocial3: string;
  public filterValueCnpj3: string;
  public filterValueNumeroDoc3: string;
  public filterValueValor3: string;
  public filterValueStatus3: string;

  colPendentes = ['emissao', 'nome', 'cpfCnpj', 'numNf', 'chave', 'modelo', 'valor', 'status']
  colEmitidas = ['emissao', 'nome', 'cpfCnpj', 'codigoControle', 'pedido', 'numNf', 'modelo', 'valor', 'usuario', 'status']
  colCanceladas = ['emissao', 'nome', 'cpfCnpj', 'numNf', 'valor', 'status']

  private logManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  constructor(
    private configService: ConfigService,
    private crudService: CrudService,
    private httpService: HttpService,
    private titleService: TitleService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private toolbarService: ToolbarService,
    private router: Router,
    private messageService: MessageService,
    private httpClient: HttpClient
  ) {
    super(crudService);
  }

  ngOnInit() {
    // this.toolbarService.clear();
    this.title = 'ESCRITURAÇÃO DOCUMENTO DE SAÍDA';
    this.subtitle = 'ESCRITURAÇÃO DOCUMENTO DE SAÍDA';
    this.titleService.title = 'Escrituração de documento';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.todasSelecionadas = false;
    this.msgCancelamentoNfe = '';
    this.modalCancelarNfe = false;
    this.modalCartaCorrecao = false;
    this.msgCartaCorrecaoNfe = '';


    this.xmlPendente = new Array();
    this.xmlEscriturado = new Array();
    this.xmlCancelado = new Array();

    this.defaultDate = new Date();

    this.idNfses = new Array();
    this.valorAtual = 0.00;
    this.valorSelecionados = 0.00;

    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;
    this.modalPeriodo = false;

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.listarNfse();
      }
    );
  }

  ngOnDestroy() {
    // this.toolbarService.clear();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  listarNfse() {
    this.msgCancelamentoNfe = '';
    this.modalCancelarNfe = false;
    this.idNfses = new Array();
    this.valorAtual = 0.00;

    if (
      this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected &&
      this.dblinkedSessionService.periodo.month
    ) {
      this.nfseEmitidas = new Array();

      this.nfeEmitidas = new Array();

      this.alterarPeriodo();

      this.dataIni = null;
      this.dataFin = null;
      this.modalPeriodo = false;
      this.todasSelecionadas = false;

      if (!this.validacaoError) {
        this.httpService.wait();
        this.httpService
          .get('/custom/dbl/buscar-nfse', [
            this.dblinkedSessionService.empresa.cnpj,
            this.dataIniSearch,
            this.dataFinSearch,
            this.dblinkedSessionService.empresa.id
          ])
          .subscribe(
            ref => {
              this.loadNfse(ref);
            },
            error => this.httpService.handleError(error, () => this.listarNfse()),
            () => this.httpService.done()
          );
      }
    }
  }

  loadNfse(value: any) {
    this.valorAtual = 0.00;
    this.nfseEmitidas = value;

    this.xmlEscriturado = new Array();
    this.xmlCancelado = new Array();
    this.xmlPendente = new Array();

    value.forEach(element => {
      if (element.statusNfId === 1) {
        this.xmlEscriturado.push(element);
      } else if (element.statusNfId === 50) {
        this.xmlCancelado.push(element);
      } else if (element.statusNfId === 0) {
        this.xmlPendente.push(element);
        this.valor = this.utilService.parseMoney((element.valor));
        this.valorAtual = this.valorAtual + this.valor;
      }

    });
    this.toolbarEdit();
    // this.toolbarRefresh();

  }

  loadNfe(value: any) {

    this.nfeEmitidas = value;

    console.log(value);

    const alphaNumeric = this.nfseEmitidas.concat(value);

    const concatenar = this.nfseEmitidas.concat(value);

    // this.nfseEmitidas.merge .push(value);
    value.forEach(element => {
      this.valor = this.utilService.parseMoney((element.valor));
      this.valorAtual = this.valorAtual + this.valor;
    });

    this.nfseEmitidas = alphaNumeric;

    this.nfseEmitidas = concatenar;

  }

  selectCheckbox(id: any, event: any, msg = true) {

    if (event.statusNfId == 0) {
      if (event.selected && msg) alert('Somente Notas Emitidas poderão ser exportadas!');
      return;
    }

    if ((event.modelo === 'NFSE' || event.modelo === 'NFS' || event.modelo === '100')) {
      this.todasSelecionadas = false;
      if (!event.selected) {
        const index = this.idNfses.findIndex(c => c === id);
        this.idNfses.splice(index, 1);
      } else {
        this.idNfses.push(id);
      }


      if (this.idNfses.length > 0) {
        this.toolbarEdit();
        this.toolbarService.alterarStatus('print', (event.statusNfId === 51),
          'Visualizar Nfs', () => this.visualizarNf());
          this.toolbarService.alterarStatus('export', false, 'Exportar XML NFS', () => this.exportXmlNfs());
        if (this.idNfses.length === 1) {
          this.toolbarService.alterarStatus('cancel', ((!(event.statusNfId !== 1 || event.statusNfId !== 52))),
            'Cancelar NFS', () => this.cancelarNfse());

        }
      } else {
        this.toolbarEdit();
      }
    }

    if (event.modelo === '55') {

      this.todasSelecionadas = false;


      if (!event.selected) {
        const index = this.idNfses.findIndex(nfId => nfId === id);
        this.idNfses.splice(index, 1);
        const index2 = this.nfeExport.findIndex(nfId => nfId === id);
        this.nfeExport.splice(index2, 1);
      } else {
        this.idNfses.push(id);
        this.nfeExport.push(id)
      }

      this.toolbarEdit();
      if (this.idNfses.length > 0) {
        this.toolbarService.alterarStatus('export', false, 'Exportar', () => this.export());
        // this.toolbarService.alterarStatus('print', (event.statusNfId !== 1 && event.statusNfId !== 50),
        //   'Visualizar Nfe', () => this.visualizarNf());
        if (this.idNfses.length === 1) {
        this.toolbarService.alterarStatus('print', (event.statusNfId !== 1 && event.statusNfId !== 50),
          'Visualizar Nfe', () => this.visualizarNf());
        this.toolbarService.alterarStatus('edit', (event.statusNfId !== 1 && event.statusNfId !== 50),
          'Carta de Correção', () => this.modalCartaCorrecaoNfe());
        this.toolbarService.alterarStatus('cancel', (event.statusNfId !== 1),
          'Cancelar NFe', () => this.modalCancelarNfeOpen());
        this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo');
        }
      }

    }

    if (event.modelo === '21') {

      this.todasSelecionadas = false;

      if (!event.selected) {
        const index = this.idNfses.findIndex(nfId => nfId === id);
        this.idNfses.splice(index, 1);
      } else {
        this.idNfses.push(id);
      }

      if (this.idNfses.length > 0 && this.idNfses.length > 1) {
        this.toolbarEdit();
        this.toolbarService.alterarStatus('print', (event.statusNfId !== 1 && event.statusNfId !== 50),
          'Visualizar Nfe', () => this.visualizarNfComunic());
      } else if (this.idNfses.length === 1) {
        this.toolbarService.alterarStatus('print', (event.statusNfId !== 1 && event.statusNfId !== 50),
          'Visualizar Nfe', () => this.visualizarNfComunic());
        this.toolbarService.alterarStatus('cancel', (event.statusNfId !== 1),
          'Cancelar NFe', () => this.cancelarNfComunic());
        this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo');
      } else {
        this.toolbarEdit();
      }

    }

    if (event.modelo === '02') {
      if (!event.selected) {
        const index = this.idNfses.findIndex(nfId => nfId === id);
        this.idNfses.splice(index, 1);
      } else {
        this.idNfses.push(id);
      }
    }
  }


  selectTodos(event: any) {
    this.nfse = this.nfseEmitidas;
    //this.valorSelecionados = 0.00;
    if (event) {
      this.nfse.forEach(form => {
        this.selectCheckbox(form.nfseId, { ...form, selected: true }, false)
      });
    } else {
      this.idNfses = new Array();
    }
    if (this.idNfses.length > 0) {
      this.toolbarEdit();
    }
  }

  rowSelected(event: any) {
    if (event.pedido && event.pedidoStatus === 4) {
      this.router.navigateByUrl('/pedido/' + 'pv/' + event.nfseId);
    } else if (event.pedido && event.pedidoStatus === 11) {
      this.router.navigateByUrl('/pedido-pv-direto/' + 'pv/' + event.nfseId);
    }
  }

  visualizarNfse() {
    this.idNfses.forEach(nfseId => {

      this.httpService.wait();
      this.httpService.get('/custom/enotas/impressao-nfs-pdf(' + nfseId + ')')
        .subscribe(sucesso => { this.download(sucesso); },
          error => this.httpService.handleError(error, () => this.visualizarNfse()),
          () => this.httpService.done());


    });
    this.listarNfse();
  }

  visualizarNf() {
    console.log(this.idNfses)
    this.idNfses.forEach(nfseId => {
      this.xmlPendente.forEach(element => {
        if (element.nfseId == nfseId && element.modelo === '55') {

          this.idNfses.forEach(nfeId => {
            this.httpService.wait();
            this.httpService.get('/custom/nfe/gerar-danfe(' + nfseId + ')')
              .subscribe(result => this.exportedDanfe(result),
                error => this.httpService.handleError(error, () => this.visualizarNfe()),
                () => this.httpService.done());
          });

        } else if (element.nfseId == nfseId && element.modelo === 'NFS') {

          this.httpService.wait();
          this.httpService.get('/custom/enotas/impressao-nfs-pdf(' + nfseId + ')')
            .subscribe(sucesso => { this.download(sucesso); return; },
              error => this.httpService.handleError(error, () => this.visualizarNfse()),
              () => this.httpService.done());

        }

      });

      this.xmlEscriturado.forEach(element => {

        if (element.nfseId == nfseId && element.modelo === '55') {

          this.idNfses.forEach(nfeId => {
            this.httpService.wait();
            this.httpService.get('/custom/nfe/gerar-danfe(' + nfseId + ')')
              .subscribe(result => this.exportedDanfe(result),
                error => this.httpService.handleError(error, () => this.visualizarNfe()),
                () => this.httpService.done());
          });

        } else if (element.nfseId == nfseId && element.modelo === 'NFS') {

          this.httpService.wait();
          this.httpService.get('/custom/enotas/impressao-nfs-pdf(' + nfseId + ')')
            .subscribe(sucesso => { this.download(sucesso); return; },
              error => this.httpService.handleError(error, () => this.visualizarNfse()),
              () => this.httpService.done());

        }
      });

      this.xmlCancelado.forEach(element => {

        if (element.nfseId == nfseId && element.modelo === '55') {

          this.idNfses.forEach(nfeId => {
            this.httpService.wait();
            this.httpService.get('/custom/nfe/gerar-danfe(' + nfseId + ')')
              .subscribe(result => this.exportedDanfe(result),
                error => this.httpService.handleError(error, () => this.visualizarNfe()),
                () => this.httpService.done());
          });

        } else if (element.nfseId == nfseId && element.modelo === 'NFS') {

          this.httpService.wait();
          this.httpService.get('/custom/enotas/impressao-nfs-pdf(' + nfseId + ')')
            .subscribe(sucesso => { this.download(sucesso); return; },
              error => this.httpService.handleError(error, () => this.visualizarNfse()),
              () => this.httpService.done());

        }
      });

    });
  }


  download(result: any) {
    if (!result.error) {
      if (result.file) {
        window.open(this.httpService.url('/custom/file/' + result.file + '/nfs-pdf'), '_blank');
      } else {
        window.open(result.key, '_blank');
      }
    } else {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Advertência:',
        detail: 'Nota não foi emitida pela Linnks Tecnologia, não é possível realizar a impressão'
      });
    }
  }

  cancelarNfse() {
    if (confirm('Deseja realmente cancelar esta Nfse?')) {


      this.idNfses.forEach(nfseId => {

        this.httpService.wait();
        this.httpService.get('/custom/enotas/nfse-cancel(' + nfseId + ')')
          .subscribe(sucesso => {
            console.log(sucesso);

            this.messageService.add({ severity: sucesso.error ? 'warn' : 'success', summary: '', detail: sucesso.message });
            this.listarNfse();
          },
            error => this.httpService.handleError(error, () => this.cancelarNfse()),
            () => this.httpService.done());
      });
    }
  }

  atualizarStatusNfse() {
    this.idNfses.forEach(nfseId => {
      this.httpService.wait();
      this.httpService.get('/custom/service/nfse(' + nfseId + ')')
        .subscribe(sucesso => this.listarNfse(),
          error => this.httpService.handleError(error, () => this.atualizarStatusNfse()),
          () => this.httpService.done());
    });
  }

  visualizarNfe() {
    this.idNfses.forEach(nfeId => {
      this.httpService.wait();
      this.httpService.get('/custom/nfe/gerar-danfe(' + nfeId + ')')
        .subscribe(result => this.exportedDanfe(result),
          error => this.httpService.handleError(error, () => this.visualizarNfe()),
          () => this.httpService.done());
    });
  }

  exportedDanfe(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/danfe'), '_blank');
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result.message });
    }
  }

  modalCancelarNfeOpen() {

    this.msgCancelamentoNfe = '';
    this.modalCancelarNfe = true;
  }

  modalCartaCorrecaoNfe() {

    this.msgCartaCorrecaoNfe = '';
    this.modalCartaCorrecao = true;
  }

  cancelarNfe() {
    if ((this.msgCancelamentoNfe === undefined || this.msgCancelamentoNfe.length < 15)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Advertência:',
        detail: 'O motivo de cancelamento deverá ser informado com no mínimo 15 caracteres.'
      });
      return;
    }
    if (confirm('Deseja realmente cancelar esta Nota?')) {
      this.modalCancelarNfe = false;

      this.idNfses.forEach(nfeId => {



        this.httpService.wait();

        this.httpService.get('/custom/nfe/cancelar-nfe', [nfeId, this.msgCancelamentoNfe.replace(",", ".")])
          .subscribe(result => {

            if (result.error === 'true') {

              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Impossibilidade de Cancelamento: ', detail: result.message });
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Cancelamento registrado: ', detail: result.message });
              this.listarNfse();
            }
          },
            error => this.httpService.handleError(error, () => this.cancelarNfe()),
            () => this.httpService.done());
      });
    }
  }

  /*MODAL ALTERAR PERÍODO */
  openModalPeriodo() {
    this.modalPeriodo = true;
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
  }

  closeModalPeriodo() {
    this.modalPeriodo = false;
    this.dataIni = null;
    this.dataFin = null;
  }

  enviarCartaCorrecao() {


    if ((this.msgCartaCorrecaoNfe === undefined || this.removerCaractereEspecial(this.msgCartaCorrecaoNfe).length < 5)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Advertência:',
        detail: 'O motivo da carta de correção deverá ser informado e com no mínimo 5 caracteres, não pode conter caracteres especiais'
      });
      return;
    }
    if (confirm('Deseja realmente enviar esta Carta de Correção?')) {
      this.modalCancelarNfe = false;

      this.idNfses.forEach(nfeId => {
        this.httpService.wait();
        this.httpService.get('/custom/nfe/enviar-carta-correcao-nfe', [nfeId, this.msgCartaCorrecaoNfe])
          .subscribe(result => {

            if (result.error) {

              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'MSG: ', detail: result.message });
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: result.message });
              this.listarNfse();
            }
          },
            error => this.httpService.handleError(error, () => this.enviarCartaCorrecao()),
            () => this.httpService.done());
      });
    }
  }



  hideMessage() {

  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  onFilter(event: any) {
    this.valorAtual = 0.00;
    event.filteredValue.forEach(element => {
      this.valor = this.utilService.parseMoney((element.valor));
      this.valorAtual = this.valorAtual + this.valor;
    });
  }

  private toolbarEdit() {
    this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Ações');
    this.toolbarService.add('cancel', true, 'class-other-button', 'block', 'Cancelar',);
    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.listarNfse());
    this.toolbarService.alterarStatus('atualizar', false, 'Atualizar Relatório', () => this.listarNfse());
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.listarNfse());
    this.toolbarService.replace('export', this.nfeExport.length === 0, 'class-other-button', 'description', 'Exportar', () => this.export());
    this.toolbarService.alterarStatus('print', true, 'Visualizar Nfe', () => this.visualizarNf());
    this.toolbarService.alterarStatus('cancel', true, 'Cancelar NFe', () => this.modalCancelarNfeOpen());
    this.toolbarService.alterarStatus('edit', true, 'Carta de Correção', () => this.modalCartaCorrecaoNfe());
    this.toolbarService.replace('alterar', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());
    this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo');
    this.loadAcoes();
  }


  alterarPeriodo() {
    this.validacaoError = false;
    const mes = this.dblinkedSessionService.periodo.month.value > 8 ?
      this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
    const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
    this.defaultDate = new Date(dt);

    let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
    // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
    lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
    this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() >= 9 ?
        (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() >= 9 ?
        (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
        this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
        this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }
    const d1 = new Date(this.dataIniSearch);
    const d2 = new Date(this.dataFinSearch);
    if (d1 > d2) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Aviso:', detail: 'Data incial maior que a final. Verifique!' });
      this.validacaoError = true;
      return;
    }
  }


  showTelaNotasFiscais(tipo) {
    this.statusPendente = false;
    this.statusEscriturada = false;
    this.statusCancelada = false;
    this.todasSelecionadas = false;

    this.valorAtual = 0.00;


    if (tipo === 'TELA_1') {
      this.statusPendente = true;
      this.xml = this.xmlPendente;
    } else if (tipo === 'TELA_2') {
      this.statusEscriturada = true;
      this.xml = this.xmlEscriturado;
    } else if (tipo === 'TELA_3') {
      this.statusCancelada = true;
      this.xml = this.xmlCancelado;
    }

    this.xml.forEach(element => {
      this.valor = this.utilService.parseMoney((element.valor));
      this.valorAtual = this.valorAtual + this.valor;
    });


  }

  visualizarPedidos(event: any) {

  }


  /*Show Hide Card Notas Fiscais*/
  isCardNotasFiscais: boolean = true;
  ShowHIdeNotasFiscais() {
    this.isCardNotasFiscais = !this.isCardNotasFiscais;
  }

  currentStateNotasFiscais = 'initial';
  changeStateNotasFiscais() {
    this.currentStateNotasFiscais = this.currentStateNotasFiscais === 'initial' ? 'final' : 'initial';
  }
  selectAll(event: any) {
    this.selectTodos(event.checked)
    if (event.checked === true) {
      this.valorSelecionados = this.valorAtual;
    } else {
      this.valorSelecionados = 0.00;
    }
  }

  onRowSelect(event: any) {
    this.selectCheckbox(event.data.nfseId, { ...event.data, selected: true })
    this.valorSelecionados = this.valorSelecionados + this.utilService.parseDecimal(event.data.valor);
  }

  onRowUnselect(event: any) {
    this.selectCheckbox(event.data.nfseId, { ...event.data, selected: false })
    this.valorSelecionados = this.valorSelecionados - this.utilService.parseDecimal(event.data.valor);
  }

  loadAcoes() {

    const childActions: Array<ToolbarButton> = new Array();


    childActions.push({
      icon: '',
      label: 'Reprocessar Contabilidade NF',
      callback: () => this.reprocessarNf('contabilidade')
    });

    childActions.push({
      icon: '',
      label: 'Reprocessar Estoque NF',
      callback: () => this.reprocessarEstoque()
    });

    childActions.push({
      icon: '',
      label: 'Reprocessar Financeiro NF',
      callback: () => this.reprocessarNf('financeiro')
    });

    childActions.push({
      icon: '',
      label: 'Enviar NF por e-mail',
      callback: () => this.enviarEmail()
    });



    this.toolbarService.get('more_vert').child = childActions;
  }

  reprocessarNf(param: String) {


    if (confirm('Deseja realmente reprocessar o ' + param + ' destas Notas?')) {


      const listasEnviar = {
        empresaId: this.dblinkedSessionService.empresa.id,
        list1: this.idNfses,
        list2: this.list2,
        usuario: this.sessionService.loggedUser.id,
        mes: this.dblinkedSessionService.periodo.month.value,
        ano: this.dblinkedSessionService.periodo.year.value,
        param: param,
        tipoEntradaSaidaId: 2
      };


      this.httpService.wait();
      this.httpService.post('/custom/nfe/reprocessar-nf', listasEnviar)
        .subscribe(res => {

          this.msgs = [];

          res.forEach(element => {
            if (element.error) {
              this.messageService.add({
                severity: 'warn', key: 'messageLinnks',
                detail: element.message
              });
            } else {
              this.messageService.add({
                severity: 'success', key: 'messageLinnks',
                detail: element.message
              });
            }

          });

        },
          error => this.httpService.handleError(error),
          () => this.httpService.done());

    }

  }


  visualizarNfComunic() {
    if (confirm('Deseja realmente imprimir esta Nf?')) {
      this.idNfses.forEach(nfseId => {
        this.httpService.wait();
        this.httpService.get('/custom/nfe/impressao-nfe-21(' + nfseId + ')')
          .subscribe(sucesso => { this.downloadNf21(sucesso); },
            error => this.httpService.handleError(error, () => this.visualizarNfse()),
            () => this.httpService.done());
      });

    }
  }

  downloadNf21(result: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/nfmod21'), '_blank');
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
    }
  }

  cancelarNfComunic() {
    if (confirm('Deseja realmente cancelar esta NF MOD 21?')) {
      this.idNfses.forEach(nfseId => {

        this.httpService.wait();
        this.httpService.get('/custom/nfe/nf21-cancel(' + nfseId + ')')
          .subscribe(sucesso => {

            this.messageService.add({
              severity: sucesso.error ? 'warn' : 'success', key: 'messageLinnks', summary: '', detail: sucesso.message
            });
            this.listarNfse();
          },
            error => this.httpService.handleError(error, () => this.cancelarNfComunic()),
            () => this.httpService.done());
      });

    }
  }

  enviarEmail() {


    if (confirm('Deseja realmente enviar estas NF´s por e-mail?')) {


      const listasEnviar = {
        empresaId: this.dblinkedSessionService.empresa.id,
        list1: this.idNfses,
        list2: this.list2,
        usuario: this.sessionService.loggedUser.id
      };


      this.httpService.wait();
      this.httpService.post('/custom/nfe/enviar-email', listasEnviar)
        .subscribe(res => {

          this.msgs = [];

          res.forEach(element => {
            if (element.error) {
              this.msgs.push({ severity: 'warn', summary: 'Advertência', detail: element.message });
            } else {
              this.msgs.push({ severity: 'success', summary: 'Sucesso', detail: element.message });
            }

          });

        },
          error => this.httpService.handleError(error),
          () => this.httpService.done());

    }

  }

  exportExcel(table: Array<any>, title: string, cols: Array<any>) {
    exportExcel(table, title, cols)
  }

  removerCaractereEspecial(string) {
    return string.replace(/[^a-zA-Z0-9]/g, "");
  }

  exportXmlNfs() {
    this.httpService.wait();
    this.httpService.post('/custom/enotas/export-nfs',
      {
        type: 'nfs',
        operationId: 1, // TODO: Review (ver emissão própria e outros)
        cnpj: this.dblinkedSessionService.empresa.cnpj.toString(),
        empresaId: this.dblinkedSessionService.empresa.id,
        ids: this.idNfses
      })
      .subscribe(result => this.exported(result),
        error => this.httpService.handleError(error,
          () => this.export(),
          (message: any) => this.displayErrorMessage(message)),
        () => this.httpService.done());
  }

  export() {
    this.httpService.wait();
    this.httpService.post('/custom/xml/export',
      {
        type: 'nfe',
        operationId: 1, // TODO: Review (ver emissão própria e outros)
        cnpj: this.dblinkedSessionService.empresa.cnpj.toString(),
        ids: this.idNfses
      })
      .subscribe(result => this.exported(result),
        error => this.httpService.handleError(error,
          () => this.export(),
          (message: any) => this.displayErrorMessage(message)),
        () => this.httpService.done());
  }

  exported(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file), '_blank');
    } else {
      alert(result.message); // TODO: Use Material dialog
    }

    this.idNfses = new Array()
  }

  displayErrorMessage(error: String | any) {
    alert(typeof error === 'string' ? error : error.message);
  }

  reprocessarEstoque() {
    const infos = {
      liNfeId: this.idNfses,
      userId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait()
    let url = '/custom/estoque/v2/reprocessar-estoque'
    this.httpService.post(url, infos)
      .subscribe(
        result => {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result.message })
          this.httpService.done()
          //Gravando no log
          this.idNfses.forEach(nfeId => {
            let xml = this.xmlEscriturado.find(row => row.nfseId === nfeId)

            this.logManagement.registerLog(
              LogModule.PURCHASES,
              LogScreen.INVOICES,
              url,
              LogAction.EDIT,
              nfeId,
              xml.numNf,
              "Reprocessou o estoque da NF " + xml.numNf,
              infos
            )
          })

          this.listarNfse()

        },
        (error: HttpErrorResponse) => {
          if (error.status === 400) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: error.error.message })
            this.downloadLog(error.error.file)
          }
          else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: "Erro no API ao reprocessar o estoque, contate o suporte: " + error.message })
          this.httpService.done()
          //Gravando no log
          this.idNfses.forEach(nfeId => {
            let xml = this.xmlEscriturado.find(row => row.nfseId === nfeId)

            this.logManagement.registerLog(
              LogModule.PURCHASES,
              LogScreen.INVOICES,
              url,
              LogAction.EDIT,
              nfeId,
              xml.numNf,
              "Reprocessou o estoque da NF " + xml.numNf,
              infos
            )
          })

          this.listarNfse()
        })
  }

  downloadLog(file: string) {
      window.open(this.httpService.url("/custom/file/" + file),"_blank")
  }

}
