import { TipoArquivo } from '../../util/model/tipo-arquivo.model';

export class TipoDocumento {
    id: number;
    cod: string;
    descricao: string;
    tipoArquivo: TipoArquivo;

    constructor(tipodocumento?: TipoDocumento | number) {
      if (tipodocumento !== undefined && tipodocumento !== null) {
        if (typeof tipodocumento === 'number') {
          this.id = tipodocumento;
        } else {
          this.id = tipodocumento.id;
          this.cod = tipodocumento.cod;
          this.descricao = tipodocumento.descricao;
          this.tipoArquivo = tipodocumento.tipoArquivo;
        }
      }
    }
  }
