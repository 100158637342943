import { Empresa } from "app/cadastro/model/empresa.model";
import { MunicipioIbge } from "app/util/model/municipio-ibge.model";
import { Vendedor } from "./vendedor.model";
import { isNullOrUndefined } from "util";
import { Seminarista } from "./seminarista.model";
import { AuthUser } from "app/proof/model/auth-user.model";
import { Uf } from "app/util/model/uf.model";
import { PalestraIngresso } from "../model/palestra-ingresso.model";
import { PalestraCarteiraPorcentagem } from "app/eventos/model/palestra-carteira-porcentagem.model";

export class Palestra{
  id                  : number;
  empresa             : Empresa;
  palestrante         : Seminarista;
  municipioibge       : MunicipioIbge;
  endereco            : string;
  palestraAberta      : boolean;
  agendadaPalestrante : boolean;
  strempresa          : string;
  data                : Date;
  vendedor            : Vendedor;
  numeroPresentes     : number;
  captureLink         : string;
  descontoFolha       : boolean;
  observacao          : Text;
  criarNovaPalestra   : boolean;
  changeUser          : AuthUser;
  uf                  : Uf;
  codigo              :string;
  palestraIngresso    : Array<PalestraIngresso>;
  workshop            : boolean;
  palestraCarteiraPorcentagem : Array<PalestraCarteiraPorcentagem>;




  constructor(palestra?: Palestra | number) {
    if (!isNullOrUndefined(palestra)) {
      if (typeof palestra === 'number') {
        this.id = palestra;
      } else {
        this.id                   = palestra.id;
        this.empresa              = palestra.empresa;
        this.palestrante          = palestra.palestrante;
        this.municipioibge        = palestra.municipioibge;
        this.endereco             = palestra.endereco;
        this.palestraAberta       = palestra.palestraAberta;
        this.agendadaPalestrante  = palestra.agendadaPalestrante;
        this.strempresa           = palestra.strempresa;
        this.data                 = palestra.data;
        this.vendedor             = palestra.vendedor;
        this.numeroPresentes      = palestra.numeroPresentes;
        this.captureLink          = palestra.captureLink;
        this.descontoFolha        = palestra.descontoFolha;
        this.observacao           = palestra.observacao;
        this.criarNovaPalestra    = palestra.criarNovaPalestra;
        this.changeUser           = palestra.changeUser;
        this.uf                   = palestra.uf;
        this.codigo               = palestra.codigo;
        this.palestraIngresso     = palestra.palestraIngresso;
        this.workshop             = palestra.workshop;
        this.palestraCarteiraPorcentagem = palestra.palestraCarteiraPorcentagem;


      }



    }


}



}