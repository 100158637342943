import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { EmpresaCnae } from 'app/cadastro/model/empresa-cnae.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-empresa-cnae',
  templateUrl: './empresa-cnae.component.html',
  styleUrls: ['./empresa-cnae.component.scss'],
  providers: [CrudService]
})
export class EmpresaCnaeComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) { }

  public empresaCnae: EmpresaCnae = new EmpresaCnae();
  private sessionSubscription: Subscription; // change session event;
  private beforeSaveSubscription: Subscription;
  showLista: boolean;
  showEdit: boolean;
  showNew: boolean;
  msgs: Message[];

  entityFilter: string = null;
  lista: Array<EmpresaCnae> = new Array();
  title = '';
  subtitle = '';
  colsLista: any[] = [];


  ngOnInit() {

    Promise.resolve(null).then(() => (this.toolbarService.hidden = false));
    Promise.resolve(null).then(() => this.refreshToolbar());

    this.title = 'Código Municipal de Serviço';
    this.subtitle = 'cadastro de Código Municipal de Serviço para a empresa';
    this.showLista = true;

    this.colsLista = [
      { field: 'cnae', header: 'CNAE' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'retidoPrestador', header: 'ISS PRESTADOR' }
    ];

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.empresaChanged();
      }
    );

  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }
  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.title = 'Código Municipal de Serviço';
      this.subtitle = 'cadastro de Código Municipal de Serviço para a empresa';
      this.showLista = true;
      this.showEdit = false;
      this.showNew = false;
      this.loadCnae();

    }
  }

  private loadCnae() {
    this.lista = new Array();

    this.httpService.wait();
    this.httpService
      .get(
        '/empresacnae?$select=' +
        'id,' +
        'cnae,' +
        'descricao,retidoPrestador' +
        '&$filter=empresa/id eq ' +
        this.dblinkedSessionService.empresa.id +
        '&$orderby=cnae'
      )
      .subscribe(
        rst => {
          this.lista = rst.value;
        },
        error => this.httpService.handleError(error, () => this.loadCnae()),
        () => this.httpService.done()
      );
  }

  loadComponent(component: string, rowValue: any) {
    this.empresaCnae = new EmpresaCnae();

    // inicializando variáveis para validação posterior;
    this.empresaCnae.cnae = null;
    this.empresaCnae.descricao = null;
    this.empresaCnae.retidoPrestador = null;

    // show;
    this.showLista = component === 'lista' ? true : false;
    this.showEdit = component === 'edit' ? true : false;
    this.showNew = component === 'new' ? true : false;

    if (component === 'new') {
      this.subtitle = 'cadastro de Código Municipal de Serviço';
      this.empresaCnae.retidoPrestador = false;
    } else if (component === 'lista') {
      this.subtitle = 'Códigos Municipais de Serviço cadastrados';

    } else if (component === 'edit') {
      this.subtitle = 'edição de Código Municipal de Serviço';

      this.empresaCnae = rowValue;
    }

  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  refreshToolbar() {
    this.toolbarService.ocultar('voltar');
    // this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('edit');
    // this.toolbarService.ocultar('add');

    this.alterarStatusAtualizar(true);

    this.toolbarService.replace(
      'add',
      false,
      'class-new-button',
      'NOVO',
      'Adicionar',
      () => {
        this.loadComponent('new', null);
      }
    );
  }

  private alterarStatusAtualizar(boo: boolean) {
    this.toolbarService.alterarStatus('atualizar', !boo, 'Atualizar', () => {
      this.empresaChanged();
    });
  }

  excluir() {
    if (confirm('Deseja realmente excluir este Código Municipal de Serviço?')) {
      const map = {
        id: this.empresaCnae.id
      };

      this.httpService.wait();
      this.httpService
        .post('/custom/cadastro/excluir-cnae', map)
        .subscribe(
          result => {
            this.empresaChanged();

            this.messageService.add({ severity: result.severity, summary: result.summary, detail: result.detail });
          },
          error =>
            this.httpService.handleError(error, () => this.excluir()),
          () => this.httpService.done()
        );
    }
  }

  salvar() {
    if (this.showEdit) {
      const map = {
        cnae: this.empresaCnae.cnae,
        descricao: this.empresaCnae.descricao,
        id: this.empresaCnae.id,
        idUser: this.sessionService.loggedUser.id,
        retidoPrestador: this.empresaCnae.retidoPrestador
      };

      this.httpService.wait();
      this.httpService
        .post('/custom/cadastro/update-cnae', map)
        .subscribe(
          () => {
            this.empresaChanged();

            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Cnae alterado com Sucesso!' });
          },
          error =>
            this.httpService.handleError(error, () => this.salvar()),
          () => this.httpService.done()
        );
    } else {
      const map = {
        cnae: this.empresaCnae.cnae,
        descricao: this.empresaCnae.descricao,
        idUser: this.sessionService.loggedUser.id,
        empresa: this.dblinkedSessionService.empresa.id,
        retidoPrestador: this.empresaCnae.retidoPrestador
      };

      this.httpService.wait();
      this.httpService
        .post('/custom/cadastro/criar-cnae', map)
        .subscribe(
          () => {
            this.empresaChanged();

            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Cnae criado com Sucesso!' });
          },
          error =>
            this.httpService.handleError(error, () => this.salvar()),
          () => this.httpService.done()
        );
    }
  }

  hideMessage() {
    this.msgs = [];
  }

}