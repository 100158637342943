import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EmpresaAlmoxarifado } from 'app/cadastro/model/empresa-almoxarifado.model';
import { Item } from 'app/cadastro/model/item.model';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios-estoque.component.html',
  styleUrls: ['./relatorios-estoque.component.scss'],
  providers: [CrudService]
})
export class RelatorioEstoqueComponent implements OnInit, OnDestroy {


  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  defaultDate: Date;
  dataIni: Date;
  dataFin: Date;
  msgs = [];
  msgsModal = [];
  novoRelatorio: boolean;
  descenglish: boolean;

  // MODAL DO RELATORIO
  modalPosicaoEstoque: boolean = false;
  modalKardex: boolean = false;
  verificaEstoque: Item;
  itemEstoqueSelecionado: Item;
  verificaItemEstoque: boolean = false;
  opcoesAlmoxarifado: Array<any> = new Array();
  opcoesItens: Array<any> = new Array();
  opcoesGruposEstoque: Array<any> = new Array();
  codigo: String;
  descricao: String;
  parent: number;

  almoxarifados: Array<EmpresaAlmoxarifado> = new Array();

  almoxarifadoSelecionados: Array<any> = new Array();
  itensSelecionados: Array<any> = new Array();
  gruposSelecionados: Array<any> = new Array();

  meses: any[];
  mesIni: number;
  mesFin: number;

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private httpClient: HttpClient

  ) {}

  ngOnInit(): void {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'RELATÓRIOS';
    this.subtitle = 'Relatório Estoque';
    this.titleService.title = 'Relatório ESTOQUE';

    this.defaultDate = new Date();
    this.dataIni = new Date();
    this.dataFin = new Date();
    this.mesIni = 0;
    this.mesFin = 0;
    this.descenglish = false;

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => setTimeout(() => { this.listar()}, 1000))
  }

  periodosLoad() {
    this.meses = [];
    this.meses.push({ label: 'Selecione', value: 0 });
    this.meses.push({ label: 'Janeiro', value: 1 });
    this.meses.push({ label: 'Fevereiro', value: 2 });
    this.meses.push({ label: 'Março', value: 3 });
    this.meses.push({ label: 'Abril', value: 4 });
    this.meses.push({ label: 'Maio', value: 5 });
    this.meses.push({ label: 'Junho', value: 6 });
    this.meses.push({ label: 'Julho', value: 7 });
    this.meses.push({ label: 'Agosto', value: 8 });
    this.meses.push({ label: 'Setembro', value: 9 });
    this.meses.push({ label: 'Outubro', value: 10 });
    this.meses.push({ label: 'Novembro', value: 11 });
    this.meses.push({ label: 'Dezembro', value: 12 });
  }

  listar() {
    if ((this.dblinkedSessionService.hasEmpresaSelected) &&
      (this.dblinkedSessionService.hasEmpresaSelected) && (this.dblinkedSessionService.periodo.month)) {
      this.periodosLoad();
      this.validarDtIniDtFin();
      this.loadAlmoxarifado();
      this.loadItens();
      this.loadGrupoEstoque();
    }
  }

  ngOnDestroy(): void {
    this.toolbarService.clear();
    this.toolbarService.hidden = false
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  showDialogRelatorioEstoque(tipo) {
    if (tipo === 'KARDEX') {
      this.modalKardex = true;

    } else if (tipo === 'POSICAO_ESTOQUE') {
      this.modalPosicaoEstoque = true;
    }
  }


 almoxarifadoEstoque() {

  const listasEnviar = {
    almoxarifadoSelecionados: this.almoxarifadoSelecionados,
    itensSelecionados:        this.itensSelecionados,
    empresa:                  this.dblinkedSessionService.empresa.id,
    modalPosicaoEstoque:      this.modalPosicaoEstoque,
    modalKardex:              this.modalKardex,
    mesIni:                   this.mesIni,
    mesFin:                   this.mesFin,
    ano:                      this.dblinkedSessionService.periodo.year.value,
    dtIni:                    this.dataIni,
    dtFin:                    this.dataFin,
    descenglish:              this.descenglish

  };

  this.httpService.wait();
    this.httpService.post('/custom/estoque/impressao-estoque', listasEnviar)
    .subscribe(result => this.download(result),
        error => this.httpService.handleError(error, () => this.almoxarifadoEstoque()),
        () => this.httpService.done());
  }

  download(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/estoque'), '_blank');
    } else {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: result.message });
    }
  }

  loadAlmoxarifado() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        filiais: this.dblinkedSessionService.filiaisSelecionadas.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "estoque/almoxarifados", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res)
          } else {
            this.opcoesAlmoxarifado = []
            this.opcoesAlmoxarifado.push({ label: 'Selecione', value: null }, { label: 'Nenhum', value: null })
            res["data"].forEach(row => this.opcoesAlmoxarifado.push(row))
          }

          this.httpService.done()
        },
        (err) => {
          this.httpService.done()
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar a lista dos almoxarifados, contate o suporte: " + err.message })
        }
      )
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  loadItens() {
    this.httpService.wait();
    this.httpService
      .get('/item?$select=id,codigo,descricao&$filter=empresa/id eq ' +
        (this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id) +
        ' and changeUser/id ne 1 and verificaEstoque eq true&$orderby=codigo')
      .subscribe(
        item => this.loadItem(item.value),
        error => this.httpService.handleError(error, () => this.loadAlmoxarifado()),
        () => this.httpService.done()
      );
  }

  loadGrupoEstoque() {
    this.httpService.wait();
    this.httpService
      .get('/grupoestoque?$select=id,codigo,descricao&$filter=empresa/id eq ' +
      (this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id) +
        ' &$orderby=codigo')
      .subscribe(
        item => this.loadGrupo(item.value),
        error => this.httpService.handleError(error, () => this.loadAlmoxarifado()),
        () => this.httpService.done()
      );
  }

  private loadItem(value: Array<any>) {
    this.opcoesItens = [];
    value.forEach(element => {

      this.opcoesItens.push({
        label: element.codigo + ' - ' + element.descricao,
        value: element.id
      });
    });
  }

  private loadGrupo(value: Array<any>) {
    this.opcoesGruposEstoque = [];
    value.forEach(element => {

      this.opcoesGruposEstoque.push({
        label: element.codigo + ' - ' + element.descricao,
        value: element.id
      });
    });
  }

  validarDtIniDtFin(){
      const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
                this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
      const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
      this.defaultDate = new Date(dt);
      const date = new Date(dt);
      this.dataIni = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dataFin = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
}
