import { Injectable } from '@angular/core';
import { Router, CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { LoginService } from './login.service';

@Injectable()
export class AuthService implements CanActivate, CanDeactivate<any> {
  constructor(private configService: ConfigService,
              private httpService: HttpService,
              private loginService: LoginService,
              private router: Router) {
  }

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot,
              routerStateSnapshot: RouterStateSnapshot) {
    if (!this.configService.crowdAuth && routerStateSnapshot.url.indexOf('/crowd') === 0) {
      this.router.navigateByUrl(this.configService.loginPath);

      return false;
    }

    if (!this.httpService.authenticated && !this.loginService.authCheck()) {
      this.router.navigate([this.configService.loginPath.substr(1), { url: this.httpService.loginUrlHandle(routerStateSnapshot.url) }]);

      return false;
    }

    return true;
  }

  canDeactivate(component: any,
                activatedRouteSnapshot: ActivatedRouteSnapshot,
                routerStateSnapshot: RouterStateSnapshot) {
    if (component.editing) {
      // TODO: Call cancel (with confirm)
    }

    return component.editing !== true;
  }
}
