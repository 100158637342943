import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { isNullOrUndefined } from 'util';
import {ConfiguracoesApis} from 'app/cadastro/model/configuracoes-apis.model';
import { Util } from 'app/shared/common/util';

@Component({
  selector: 'configuracoes-apis',
  templateUrl: './configuracoes-apis.component.html',
  styleUrls: [
    './configuracoes-apis.component.scss'
  ]
})
export class ConfiguracoesAPIsComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {}

  private sessionSubscription: Subscription; // change session event;

  title = '';
  subtitle = '';



  // components;
  tiposLista = [];
  showLista : boolean = false;
  showNew : boolean = false;
  showEdit : boolean = false;
  codigo : string = '';
  chaveAPI: string  = '';
  descricao: string  = '';
  email: string  = '';
  usuario: string  = '';
  senha : string = '';
  maxParcela :number = 0;
  opcoesContaBancaria: SelectItem[];
  contaSelecionadaId  : number = 0;
  split         : boolean = false;
  marketplace   :boolean = false;
  ativo         : boolean = false;
  boleto        : boolean = false;
  cartaoCredito : boolean = false;
  assinatura    : boolean = false;

  public isRequeridoChave   : boolean = false;
  public isRequeridoEmail   : boolean = false;
  public isRequeridoUsuario : boolean = false;
  public isRequeridoSenha   : boolean = false;

  tipoSelecionadoId : number = 0;

  // dropdowns;

  plataformaId :number = 0;
  plataformaList    : SelectItem[] = [];
  opcoesPlataforma  : Array<any> = new Array();

  // msgs;
  msgs: Message[] = [];


  // date;
  defaultDate: Date;
  pt_BR: any;


  ngOnInit() {
    this.dblinkedSessionService.hidePeriods();
    Promise.resolve(null).then(() => (this.toolbarService.hidden = false));
    Promise.resolve(null).then(() => this.refreshToolbar());
    this.title = 'Configurações de APIs';

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
      }
    );

    this.defaultDate = new Date();
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };


  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  loadComponent(component: string, rowValue: any) {
    // inicializando variáveis para validação posterior;

    // show;
    this.comboPlataformaPagamentos();
    this.showLista = component === 'lista' ? true : false;
    this.showEdit = component === 'edit' ? true : false;
    this.showNew = component === 'new' ? true : false;
    this.loadConfiguracoesAPIs();
    if (component === 'new') {
      this.loadContasBancarias();
      this.subtitle = 'nova configuração';
      this.tipoSelecionadoId = 0;
      this.chaveAPI = "";
      this.codigo = "";
      this.plataformaId = 0;
      this.descricao = '';
      this.email = '';
      this.usuario = '';
      this.senha = '';
      this.maxParcela = 0;
      this.opcoesContaBancaria = [];
      this.contaSelecionadaId = 0;
      this.split          = false;
      this.marketplace    = false;
      this.ativo          = false;
      this.boleto         = false;
      this.cartaoCredito  = false;
      this.assinatura     = false;
      this.checarCamposValidacao(0);

    } else if (component === 'lista') {
      this.subtitle = 'configurações de APIs';

      // console.log(this.tiposLista);
    } else if (component === 'edit'){
      // console.log('esse é o rowValue');
      // console.log(rowValue);
      this.loadContasBancarias();
      this.tipoSelecionadoId = rowValue["id"];
      this.chaveAPI = rowValue["chaveAPI"];
      this.codigo = rowValue["codigo"];
      this.plataformaId = rowValue["plataformaPagamentosId"];
      this.descricao = rowValue["descricao"];
      this.email = rowValue["email"];
      this.usuario = rowValue["usuario"];
      this.senha = rowValue["senha"];
      this.maxParcela = rowValue["maxParcela"];
      this.opcoesContaBancaria = rowValue["opcoesContaBancaria"];
      this.contaSelecionadaId = rowValue["contaSelecionadaId"];
      this.split          = rowValue["split"];
      this.marketplace    = rowValue["marketplace"];
      this.ativo          = rowValue["ativo"];
      this.boleto         = rowValue["boleto"];
      this.assinatura     = rowValue["assinatura"];
      this.cartaoCredito  = rowValue["cartaoCredito"];
      if (!isNullOrUndefined(this.plataformaId)) {
        this.checarCamposValidacao(this.plataformaId);
      }

    }
  }

  loadConfiguracoesAPIs(){
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-configuracoes-apis('+ this.dblinkedSessionService.empresa.id + ')')
      .subscribe(
        ret => {
          console.log(ret);
          this.tiposLista = [];
          ret.forEach(element => {
            this.tiposLista.push(element);
          });
        },
        error => {
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
          this.httpService.done();
        },
        () => this.httpService.done());
  }

  salvar(edit: boolean){


    if (this.validacao()) {
      return;
    }

    let listasEnviar = {
      codigo: this.codigo,
      chaveAPI: this.chaveAPI,
      plataformaId: this.plataformaId,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuarioId: this.sessionService.loggedUser.id,
      descricao: this.descricao,
      email: this.email,
      usuario: this.usuario,
      senha: this.senha,
      maxParcela: this.maxParcela,
      contaBancariaId: this.contaSelecionadaId,
      split         : this.split,
      marketplace   : this.marketplace,
      ativo         : this.ativo,
      boleto        : this.boleto,
      cartaoCredito : this.cartaoCredito,
      assinatura    : this.assinatura

    };



    this.httpService.wait();
    //criamos um novo pagamento se edit === true
    if (edit === true){
      let url = '';
      if (this.showNew === true){
        url = '/custom/cadastro/post-configuracoes-apis';
      } else if (this.showEdit === true){
        listasEnviar["id"] = this.tipoSelecionadoId;
        console.log(this.tiposLista["id"])
        url = '/custom/cadastro/put-configuracoes-apis';
      }
      this.httpService.post(url, listasEnviar)
      .subscribe(
        (res) => {
          console.log(res);
          this.loadComponent('lista', null);
          if (res.error === false ) {
            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: 'Sucesso',
              detail: res.message
            });
          } else {
            this.messageService.add({
              severity: 'error',key: 'messageLinnks',
              summary: 'Erro',
              detail: res.message
            });
          }

        },
        error => {
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao cadastrar." });
          this.httpService.done();
        },
        () => {
          this.httpService.done();
        }
      );
    // se edit === false, deletamos o tipo
    } else if (edit === false){
      if (confirm('Deseja deletar essa configuração?')) {
          let listasEnviar = {id: this.tipoSelecionadoId};
          this.httpService.post('/custom/cadastro/delete-configuracoes-apis', listasEnviar)
          .subscribe(
            (res) => {
              this.loadComponent('lista', null);
              if (res["error"] === false) {
                this.messageService.add({
                  severity: 'success',key: 'messageLinnks',
                  summary: 'Sucesso',
                  detail: 'Configuração excluída com sucesso.'
                });
              } else{
                this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao excluir." });
              }
            },
            error => {
              this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao excluir." });
              this.httpService.done();
            },
            () => {
              this.httpService.done();
            }
          );
      }

    }


  }

  private changeSession() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.loadComponent('lista', null);
      this.comboPlataformaPagamentos();

    }
  }

  private alterarStatusAtualizar(boo: boolean) {
    this.toolbarService.alterarStatus('atualizar', !boo, 'Atualizar', () => {
      this.changeSession();
    });
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() {

  }

  // app.component.ts;
  private refreshToolbar() {
    this.toolbarService.ocultar('voltar');
    // this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('edit');
    // this.toolbarService.ocultar('add');
    //this.alterarStatusAtualizar(true);
    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar', () => this.changeSession());
    this.toolbarService.replace(
      'add',
      false,
      'class-new-button',
      'NOVO',
      'Adicionar',
      () => {
        this.loadComponent('new', null);
      }
    );
  }

  loadContasBancarias() {
    this.httpService.wait();
    this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,instituicaoFinanceira,tipoConta/id'
      + '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id
      + '&$orderby=nome')
      .subscribe(ref => {
        this.opcoesContaBancaria = [];
        this.opcoesContaBancaria.push({ label: 'Nenhum', value: null });
        ref.value.forEach(conta => {
          this.opcoesContaBancaria.push({ label: conta.nome, value: conta.id });
        });
      },
      error => {
        this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar contas bancárias." });
        this.httpService.done();
      },
      () => this.httpService.done());


  }

  comboPlataformaPagamentos(){
  this.httpService.wait();
  this.httpService.get('/custom/cadastro/get-combo-plataforma-pagamento')
    .subscribe(
      ret => {

        this.plataformaList = [];
        this.opcoesPlataforma = new Array();
        this.plataformaList.push({value: 0, label: 'Selecione uma plataforma'});
        ret.forEach(element => {
          this.plataformaList.push({value: element.id, label: element.descricao});
          this.opcoesPlataforma.push(element);
        });
      },
      error => {
        console.log(error);
        this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
        this.httpService.done();
      },
      () => this.httpService.done());

  }

  validacao(){

    if (!this.dblinkedSessionService.hasEmpresaSelected ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecionar uma empresa!'
      });
      return true;

    }

    if (isNullOrUndefined( this.plataformaId) || this.plataformaId === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma plataforma!'
      });
      return true;
    }

    this.checarCamposValidacao(this.plataformaId);

    if (isNullOrUndefined( this.codigo) || ( !isNullOrUndefined(this.codigo) && this.codigo.trim() === '')) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar um código!'
      });
      return true;
    }



    if (this.isRequeridoChave ) {
      if (isNullOrUndefined(this.chaveAPI) || (!isNullOrUndefined(this.chaveAPI) && this.chaveAPI.trim() === '') ) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor digitar a chave da api!'
        });
        return true;
      }
    }

    if (isNullOrUndefined( this.descricao) || (!isNullOrUndefined(this.descricao) && this.descricao.trim() === '')) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar uma descrição!'
      });
      return true;
    }

    if (this.isRequeridoEmail) {
      if (isNullOrUndefined(this.email) || (!isNullOrUndefined(this.email) &&  this.email.trim() === '') ) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor digitar o email!'
        });
        return true;
      }

      if (!Util.validacaoEmail(this.email)) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Email inválido. Verifique!'
        });
        return true;

      }

    }

    if (this.isRequeridoUsuario ) {
      if (isNullOrUndefined(this.usuario) || (!isNullOrUndefined(this.usuario) && this.usuario.trim() === '') ) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor digitar o usuário!'
        });
        return true;
      }
    }

    if (this.isRequeridoSenha ) {
      if (isNullOrUndefined(this.senha) || (!isNullOrUndefined(this.senha) && this.senha.trim() === '') ) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor digitar a senha!'
        });
        return true;
      }
    }

    if (isNullOrUndefined( this.descricao) || (!isNullOrUndefined(this.descricao) && this.descricao.trim() === '')) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar uma descrição!'
      });
      return true;
    }

    return false;
  }

  checarCamposValidacao(plataformaId: number){
    this.isRequeridoChave    = false;
    this.isRequeridoEmail    = false;
    this.isRequeridoUsuario  = false;
    this.isRequeridoSenha    = false;

    if (!isNullOrUndefined(plataformaId) && plataformaId > 0 ) {
      let index = this.opcoesPlataforma.findIndex(element => element.id === plataformaId);
      this.isRequeridoChave    = this.opcoesPlataforma[index].chave;
      this.isRequeridoEmail    = this.opcoesPlataforma[index].email;
      this.isRequeridoUsuario  = this.opcoesPlataforma[index].usuario;
      this.isRequeridoSenha    = this.opcoesPlataforma[index].senha;

    }
  }

}
