import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Item } from 'app/cadastro/model/item.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { UnidadeMedida } from 'app/util/model/unidade-medida.model';
import { ItemTipo } from 'app/util/model/item-tipo.model';
import { Tipi } from 'app/util/model/tipi.model';
import { ItemGenero } from 'app/util/model/item-genero.model';
import { CestNcmSegmento } from 'app/util/model/cest-ncm-segmento.model';
import { Nbs } from 'app/util/model/nbs.model';
import { Lst } from 'app/util/model/lst.model';
import { Operacao } from 'app/util/model/operacao.model';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  providers: [CrudService]
})
export class ItemComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public item: Item = new Item();

  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private afterSaveSubscription: Subscription;
  private beforeEditSubscription: Subscription;
  private beforeViewSubscription: Subscription;

  ncmToSearch = '';
  selectedNcm = '';
  codigo = '';
  verificacaoItemFornecedor: any;

  constructor(
    private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService
  ) {
    super(crudService);
  }

  ngOnInit() {
    this.empresaSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.empresaChanged,
      () => {
        this.empresaChanged();
      }
    );

    this.verificacaoItemFornecedor = '';

    this.beforeViewSubscription = this.crudService.beforeAdd.subscribe((item: Item) => {

      item.codigo = 'O código será gerado automaticamente pelo sistema';


      const parentControlVerificaEstoque = this.controls.find(control => control.key === 'verificaEstoque');
      parentControlVerificaEstoque.setHidden(true);

      const parentControlOperacao = this.controls.find(control => control.key === 'operacao');
      parentControlOperacao.setHidden(true);

      const parentControlNBS = this.controls.find(control => control.key === 'nbs');
      parentControlNBS.setHidden(true);

      const parentControlLST = this.controls.find(control => control.key === 'lst');
      parentControlLST.setHidden(true);

      const parentControlUnMedida = this.controls.find(control => control.key === 'unidadeMedida');
      parentControlUnMedida.setHidden(true);

      const parentControlEan13 = this.controls.find(control => control.key === 'ean13');
      parentControlEan13.setHidden(true);

      const parentControlDun14 = this.controls.find(control => control.key === 'dun14');
      parentControlDun14.setHidden(true);

      const parentControlQtdeDun14 = this.controls.find(control => control.key === 'qtdeDun14');
      parentControlQtdeDun14.setHidden(true);

      const parentControlNcmToSearch = this.controls.find(control => control.key === 'ncmToSearch');
      parentControlNcmToSearch.setHidden(true);

      const parentControlTipi = this.controls.find(control => control.key === 'tipi');
      parentControlTipi.setHidden(true);

      const parentControlItemGerero = this.controls.find(control => control.key === 'itemGenero');
      parentControlItemGerero.setHidden(true);

      const parentControlAliqIcms = this.controls.find(control => control.key === 'aliquotaIcms');
      parentControlAliqIcms.setHidden(true);

      const parentControlCestNcmSegmento = this.controls.find(control => control.key === 'cestNcmSegmento');
      parentControlCestNcmSegmento.setHidden(true);


    });

    this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((item: Item) => this.beforeSave(item));
    this.beforeEditSubscription = this.crudService.beforeEdit.subscribe((item: Item) => this.beforeEdit(item));
  }

  private beforeEdit(item: Item) {
      this.codigo = item.codigo;
  }

  private beforeSave(item: Item) {

    item.codigo = this.codigo;

    let err = false;
    if (this.crudService.form.get('itemTipo').value === null || this.crudService.form.get('itemTipo').value === undefined)  {
      err = true;
    }

    if (this.verificacaoItemFornecedor.length > 0) {
      if (this.verificacaoItemFornecedor[0].codigo !== item.codigo) {
      this.beforeSaveSubscription.unsubscribe();
      this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((i: Item) => this.beforeSave(i));

      const message = 'Item Fornecedor: ' + this.verificacaoItemFornecedor[0].itemFornecedor
      + ' já cadastrado no código ' + this.verificacaoItemFornecedor[0].codigo;
      alert (message);
      throw message;
    }
    } else if (item.dtInicial.toString() === 'Invalid Date') {
      this.beforeSaveSubscription.unsubscribe();
      this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((i: Item) => this.beforeSave(i));

      const message = 'Por favor, preencha corretamente o campo Data Inicial';
      alert (message);
      throw message;
    } else if (err) {
      this.beforeSaveSubscription.unsubscribe();
      this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((i: Item) => this.beforeSave(i));

      const message = 'Por favor, preencha corretamente o campo Tipo do Item';
      alert (message);
      throw message;
    } else {

    if (this.crudService.form.get('itemTipo').value) {
      item.itemTipo = new ItemTipo(this.crudService.form.get('itemTipo').value);
      if (item.itemTipo.id === 10) {
        item.unidadeMedida = new UnidadeMedida(2);
      } else if (this.crudService.form.get('unidadeMedida').value) {
        item.unidadeMedida = new UnidadeMedida(this.crudService.form.get('unidadeMedida').value);
      }

    }
    if (this.crudService.form.get('operacao').value) {
      item.operacao = new Operacao(this.crudService.form.get('operacao').value);
    }

    item.empresa = new Empresa(this.empresa.id);

    if (this.crudService.form.get('tipi').value) {
      item.tipi = new Tipi(this.crudService.form.get('tipi').value);
    }
    if (this.crudService.form.get('itemGenero').value) {
      item.itemGenero = new ItemGenero(this.crudService.form.get('itemGenero').value);
    }
    if (this.crudService.form.get('cestNcmSegmento').value) {
      item.cestNcmSegmento = new CestNcmSegmento(this.crudService.form.get('cestNcmSegmento').value);
    }
    if (this.crudService.form.get('nbs').value) {
      item.nbs = new Nbs(this.crudService.form.get('nbs').value);
    }
    if (this.crudService.form.get('lst').value) {
      item.lst = new Lst(this.crudService.form.get('lst').value);
    }

    if (this.crudService.form.get('descricao').value) {
      item.descricao = this.crudService.form.get('descricao').value;
    }

    if (this.crudService.form.get('descricaoEn').value) {
      item.descricaoEn = this.crudService.form.get('descricaoEn').value;
    }

    item.codigo = this.codigo;
  }
 }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
    this.sessionService.destroySubscribe(this.afterSaveSubscription);
    this.sessionService.destroySubscribe(this.beforeEditSubscription);
    this.sessionService.destroySubscribe(this.beforeViewSubscription);
    this.verificacaoItemFornecedor = '';
  }

  empresaChanged() {


    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.item.empresa = this.empresa;


     this.columns = [
        new DataTableColumn('CÓDIGO', 'codigo', 'itemCodigo'),
        new DataTableColumn('DESCRIÇÃO', 'descricao', 'itemDesc').setCssClass('formataDescricao'),
        new DataTableColumn('DESCRIÇÃO INGLÊS', 'descricaoEn', 'itemDescEn'),
        new DataTableColumn('UN', 'unidadeMedida.descricao', 'itemUN'),
        new DataTableColumn('NCM', 'tipi.ncm'),
        new DataTableColumn('Código Fornecedor', 'itemFornecedor')

      ];

      this.controls = [

        new DataControl('Tipo', 'itemTipo')
          .setTypeSelect()
          .setOptions({
            url: '/itemtipo?$select=id,codigo,descricao&$orderby=codigo',
            value: 'id',
            label: ['codigo', ' - ', 'descricao']
          })
          .setOnChange((dataControl: DataControl, tipoItem: any) => {
            if (tipoItem) {

              const TIPO_ITEM_SERVICO = 10;

              const parentControlNBS = this.controls.find(control => control.key === 'nbs');
              const parentFormControlNBS = this.crudService.form.get('nbs');

              const parentControlVerificaEstoque = this.controls.find(control => control.key === 'verificaEstoque');

              const parentControlLST = this.controls.find(control => control.key === 'lst');
              const parentFormControlLST = this.crudService.form.get('lst');

              if ((tipoItem.id === TIPO_ITEM_SERVICO) || (tipoItem.id === 9)) {
                parentControlVerificaEstoque.setHidden(true);
                parentControlVerificaEstoque.setRequired(false);
              } else {
                parentControlVerificaEstoque.setHidden(false);
                parentControlVerificaEstoque.setRequired(false);
              }


              if (tipoItem.id === TIPO_ITEM_SERVICO) {

                if (parentControlNBS.options.values.length === 0) {
                  this.crudService.fillOptions(parentControlNBS, '/nbs?$select=id,codigo,descricao&$orderby=codigo,descricao');
                }

                parentControlNBS.setHidden(false);
                parentControlNBS.setRequired(false);

                if (parentControlLST.options.values.length === 0) {
                  this.crudService.fillOptions(parentControlLST, '/lst?$select=id,codigo,descricao&$orderby=codigo,descricao');
                }

                parentControlLST.setHidden(false);
                parentControlLST.setRequired();

                const parentControlOperacao = this.controls.find(control => control.key === 'operacao');
                parentControlOperacao.setHidden(false);
                parentControlOperacao.setRequired();

                const parentControlUnMedida = this.controls.find(control => control.key === 'unidadeMedida');
                parentControlUnMedida.setHidden(true);
                parentControlUnMedida.setRequired(false);

                const parentControlEan13 = this.controls.find(control => control.key === 'ean13');
                parentControlEan13.setHidden(true);
                parentControlEan13.setRequired(false);

                const parentControlDun14 = this.controls.find(control => control.key === 'dun14');
                parentControlDun14.setHidden(true);
                parentControlDun14.setRequired(false);

                const parentControlQtdeDun14 = this.controls.find(control => control.key === 'qtdeDun14');
                parentControlQtdeDun14.setHidden(true);
                parentControlQtdeDun14.setRequired(false);

                const parentControlNcmToSearch = this.controls.find(control => control.key === 'ncmToSearch');
                parentControlNcmToSearch.setHidden(true);
                parentControlNcmToSearch.setRequired(false);

                const parentControlTipi = this.controls.find(control => control.key === 'tipi');
                parentControlTipi.setHidden(true);
                parentControlTipi.setRequired(false);

                const parentControlItemGerero = this.controls.find(control => control.key === 'itemGenero');
                parentControlItemGerero.setHidden(true);
                parentControlItemGerero.setRequired(false);

                const parentControlAliqIcms = this.controls.find(control => control.key === 'aliquotaIcms');
                parentControlAliqIcms.setHidden(true);
                parentControlAliqIcms.setRequired(false);

                const parentControlCestNcmSegmento = this.controls.find(control => control.key === 'cestNcmSegmento');
                parentControlCestNcmSegmento.setHidden(true);
                parentControlCestNcmSegmento.setRequired(false);

              } else {

                if (this.crudService.isNew) {

                  dataControl.setDisabled(false);

                this.httpService.wait();
                this.httpService.get('/custom/cadastro/codigo-item('
                + tipoItem.id + ',' + this.dblinkedSessionService.empresa.id + ',' + 0 + ')')
                .subscribe(ref => {this.codigo = ref.codigo; },
                error => this.httpService.handleError(error, () => this.empresaChanged()),
                () => this.httpService.done());

                } else {
                  dataControl.setDisabled(true);
                  dataControl.setReadOnly(true);
                  dataControl.setHidden(true);

                }

                parentControlNBS.setHidden(true);
                parentControlNBS.setRequired(false);

                parentControlLST.setHidden(true);
                parentControlLST.setRequired(false);

                const parentControlUnMedida = this.controls.find(control => control.key === 'unidadeMedida');
                parentControlUnMedida.setHidden(false);
                parentControlUnMedida.setRequired();

                const parentControlOperacao = this.controls.find(control => control.key === 'operacao');
                parentControlOperacao.setHidden(true);
                parentControlOperacao.setRequired(false);
                parentControlOperacao.setValue(null);


                const parentControlEan13 = this.controls.find(control => control.key === 'ean13');
                parentControlEan13.setHidden(false);
                parentControlEan13.setRequired(false);

                const parentControlDun14 = this.controls.find(control => control.key === 'dun14');
                parentControlDun14.setHidden(true);
                parentControlDun14.setRequired(false);

                const parentControlQtdeDun14 = this.controls.find(control => control.key === 'qtdeDun14');
                parentControlQtdeDun14.setHidden(true);
                parentControlQtdeDun14.setRequired(false);

                const parentControlNcmToSearch = this.controls.find(control => control.key === 'ncmToSearch');
                parentControlNcmToSearch.setHidden(false);

                if (tipoItem.id === 1 || tipoItem.id === 2 || tipoItem.id === 3 || tipoItem.id === 4
                  || tipoItem.id === 5  || tipoItem.id === 11) {
                    parentControlNcmToSearch.setRequired();
                } else {
                  parentControlNcmToSearch.setRequired(false);
                }

                const parentControlTipi = this.controls.find(control => control.key === 'tipi');
                parentControlTipi.setHidden(false);

                if (tipoItem.id === 1 || tipoItem.id === 2 || tipoItem.id === 3 || tipoItem.id === 4
                  || tipoItem.id === 5  || tipoItem.id === 11) {
                  parentControlTipi.setRequired(false);
                } else {
                  parentControlTipi.setRequired(false);
                }

                const parentControlItemGerero = this.controls.find(control => control.key === 'itemGenero');
                parentControlItemGerero.setHidden(true);
                parentControlItemGerero.setRequired(false);

                const parentControlAliqIcms = this.controls.find(control => control.key === 'aliquotaIcms');
                parentControlAliqIcms.setHidden(false);
                parentControlAliqIcms.setRequired(false);

                const parentControlCestNcmSegmento = this.controls.find(control => control.key === 'cestNcmSegmento');
                parentControlCestNcmSegmento.setHidden(false);
                parentControlCestNcmSegmento.setRequired(false);

              }
            }
          }),

          new DataControl('Código', 'codigo')
          .setReadOnly(true)
          .setTypeText()
          .setMinLength(0)
          .setRequired(false),

          new DataControl('Operação', 'operacao')
          .setTypeSelect()
          .setOptions({ url: '/operacao', value: 'id', label: ['descricao']
          })
          .setOnChange((dataControl: DataControl, operacao: any) => {
            if (operacao) {

              if (this.crudService.isNew && (operacao.id === 0 || operacao.id === 1)) {

            this.httpService.wait();
            this.httpService.get('/custom/cadastro/codigo-item('
            + 10 + ',' + this.dblinkedSessionService.empresa.id + ',' + operacao.id + ')')
            .subscribe(ref => {
              this.codigo = ref.codigo;
            },
            error => this.httpService.handleError(error, () => this.empresaChanged()),
            () => this.httpService.done());
          }
          }

          }),

          new DataControl('Descrição', 'descricao')
          .setMinLength(1),

          new DataControl('Descrição EN', 'descricaoEn')
          .setMinLength(1),

          new DataControl('Informações Adicionais', 'informacoesAdicionais')
          .setMinLength(1)
          .setMaxLength(256),

          new DataControl('Item Fornecedor', 'itemFornecedor')
          .setMinLength(1)
          .setMaxLength(255)
          .setOnChange((dataControl: DataControl, itemFornecedor: any) => {

            if (itemFornecedor !== null && itemFornecedor !== undefined) {

              const listasEnviar = {
                empresaId: this.dblinkedSessionService.empresa.id,
                itemFornecedor: itemFornecedor
              };

              this.httpService.wait();
              this.httpService.post('/custom/cadastro/codigo-item-fornecedor', listasEnviar)
              .subscribe(ref => {this.verificacaoItemFornecedor = ref; },
                error => this.httpService.handleError(error, () => this.empresaChanged()),
                () => this.httpService.done());

            }
          }),

          new DataControl('UN', 'unidadeMedida')
          .setTypeSelect()
          .setOptions({ url: '/unidademedida?$filter=unidadePadrao eq true', value: 'id', label: ['codigo']
          }),

          new DataControl('Cod. LST', 'lst').setTypeSelect().setOptions({
            value: 'id',
            label: ['codigo', ' - ', 'descricao']
          }),

          new DataControl('Cod. NBS', 'nbs').setTypeSelect().setOptions({
            value: 'id',
            label: ['codigo', ' - ', 'descricao']
          }),

        new DataControl('Cód. Barras DUN-14', 'dun14')
          .setMinLength(14)
          .setMaxLength(14),

        new DataControl('Qtde DUN-14', 'qtdeDun14')
          .setTypeNumber()
          .setMin(0),

        new DataControl('Capítulo Tipi', 'ncmToSearch')
          .setTypeSelect()
          .setOptions({
            url: '/custom/financeiro/capitulos-tipi',
            value: 'ncm',
            label: ['ncm', ' - ', 'descricao']
          })
          .setTransient(true)
          .setOnChange((dataControl: DataControl, value: any) => {

             if (value !== null) {
                if (value.ncm) {
                const parentControlTipi = this.controls.find(control => control.key === 'tipi');
                const parentFormControlTipi = this.crudService.form.get('tipi');

                this.crudService.fillOptions(parentControlTipi, '/custom/financeiro/tipi-por-capitulo(' + value.ncm + ')');

               parentFormControlTipi.enable();
                parentControlTipi.setRequired();
              }
            }

          }),

        new DataControl('NCM', 'tipi')
          .setDisabled()
          .setTypeSelect()
          .setOptions({
            url: '/tipi?$select=id,ncm,ex,descricao&$orderby=ncm',
            value: 'id',
            label: ['ncm', ' - ', 'descricao']
          })
          .setOnChange((dataControl: DataControl, tipiId: any) => {

            const codCestControl = this.controls.find(control => control.key === 'cestNcmSegmento');
            const codCestFormControl = this.crudService.form.get('cestNcmSegmento');

            const teste = this.controls.find(control => control.key === 'tipi');

            codCestControl.setDisabled(tipiId === '');

            if (tipiId && this.crudService.form.get('itemTipo').value === 10) {

              this.crudService.fillOptions(codCestControl, '/custom/financeiro/codcest-por-tipi(' + tipiId.id + ')');

              if (codCestControl.options.values.length > 0) {
                codCestFormControl.enable();
                codCestControl.setRequired();
              } else {
                codCestFormControl.disable();
                codCestControl.setRequired(false);
              }

            } else {

              const cestControl = this.controls.find(control => control.key === 'cestNcmSegmento');
              const cestFormControl = this.crudService.form.get('cestNcmSegmento');

              cestFormControl.disable();
              cestControl.setRequired(false);
              this.crudService.resetOptions(cestControl);

            }
          }),
        new DataControl('Cod. Gen Item', 'itemGenero')
          .setTypeSelect()
          .setOptions({
            url: '/itemgenero?$select=id,codigo,descricao&$orderby=codigo,descricao',
            value: 'id',
            label: ['codigo', ' - ', 'descricao']
          }),

        new DataControl('Aliq. ICMS', 'aliquotaIcms')
          .setTypeNumber()
          .setMaxLength(15),

        new DataControl('Cod. Cest', 'cestNcmSegmento')
          .setDisabled(this.selectedNcm === '')
          .setTypeSelect()
          .setOptions({
            value: 'id',
            label: ['cest.codigo', ' - ', 'cest.descricao']
          }),

          new DataControl('Cód. Barras EAN-13', 'ean13')
          .setMinLength(13)
          .setMaxLength(13),

          new DataControl('Valor Custo', 'valorCusto')
          .setTypeMoney(),

          new DataControl('Valor Venda', 'valorVenda')
          .setTypeMoney(),

          new DataControl('Controla Estoque', 'verificaEstoque')
            .setTypeCheckBox(),

        new DataControl('Data Inicial', 'dtInicial')
          .setRequired()
          .setTypeDate(),

        new DataControl('Data Final', 'dtFinal')
        .setTypeDate(),

        new DataControl('Cnae Serviço', 'cnaeServico')
          .setMaxLength(10)
      ];
    }
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
