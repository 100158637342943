import { ImpostoRegraFiscal } from 'app/cadastro/model/imposto-regra-fiscal.model';
import { CstIpi } from 'app/util/model/cst-ipi.model';
import { OperacaoRegraFiscalDetalhe } from 'app/cadastro/model/operacao-regra-fiscal-detalhe.model';
import { CstPiscofins } from 'app/util/model/cst-piscofins.model';
import { RegimeIncidencia } from 'app/util/model/regime-incidencia.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { TipoArquivo } from '../../util/model/tipo-arquivo.model';
import { TipoEntradaSaida } from '../../util/model/tipo-entrada-saida.model';
import { CodigosCBenef } from 'app/util/model/codigos-c-benef.model';

export class OperacaoRegraFiscal {
  id: number;
  empresa: Empresa;
  codigo: string;
  titulo: string;
  descricao: string;
  dtInicial: Date;
  dtFinal: Date;
  dtInicialFormatada: string;
  dtFinalFormatada: string;
  statusRegra: string;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;
  tipoEntradaSaida: TipoEntradaSaida;
  dadosAdicionais: string;
  ativo: boolean

  operacaoRegraFiscalDetalheList: Array<OperacaoRegraFiscalDetalhe>;
  editar: boolean;

  codigosCbenef: CodigosCBenef;

  constructor(operacaoRegraFiscal?: OperacaoRegraFiscal | number) {
    if (operacaoRegraFiscal !== undefined && operacaoRegraFiscal !== null) {
      if (typeof operacaoRegraFiscal === 'number') {
        this.id = operacaoRegraFiscal;
      } else {
        this.id = operacaoRegraFiscal.id;
        this.empresa = operacaoRegraFiscal.empresa;
        this.codigo = operacaoRegraFiscal.codigo;
        this.titulo = operacaoRegraFiscal.titulo;
        this.descricao = operacaoRegraFiscal.descricao;
        this.dtInicial = operacaoRegraFiscal.dtInicial;
        this.dtFinal = operacaoRegraFiscal.dtFinal;
        this.createTimestamp = operacaoRegraFiscal.changeTimestamp;
        this.changeUser = operacaoRegraFiscal.changeUser;
        this.changeTimestamp = operacaoRegraFiscal.changeTimestamp;
        this.tipoEntradaSaida = operacaoRegraFiscal.tipoEntradaSaida;
        this.operacaoRegraFiscalDetalheList = operacaoRegraFiscal.operacaoRegraFiscalDetalheList;
        this.editar = operacaoRegraFiscal.editar;
        this.dadosAdicionais = operacaoRegraFiscal.dadosAdicionais;
        this.ativo = operacaoRegraFiscal.ativo
        this.codigosCbenef = operacaoRegraFiscal.codigosCbenef;
        this.dtInicialFormatada = operacaoRegraFiscal.dtInicialFormatada;
        this.dtFinalFormatada = operacaoRegraFiscal.dtFinalFormatada;
        this.statusRegra = operacaoRegraFiscal.statusRegra;
      }
    }
  }
}
