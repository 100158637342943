import { Selected } from './../../shared/components/data-table/data-table.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { MessageService, SelectItem } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { isNullOrUndefined } from 'util';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Funcionario } from '../model/funcionario.model';
import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { Cargo } from '../model/cargo.model';
import { Pais } from 'app/util/model/pais.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Area } from 'app/orcamento/model/area-model';
import { Dependente } from '../model/dependente.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-cad-func',
  templateUrl: './cad-func.component.html',
  styleUrls: ['./cad-func.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadFuncComponent implements OnInit, OnDestroy {
  /////////////////////////////
  // Refator Simon Lallement //
  // 08/12/2021              //
  /////////////////////////////

  private sessionSubscription: Subscription

  //String
  title: string = "FUNCIONÁRIOS"
  subtitle: string = "Cadastro de funcionários"
  currentStateFunc: string = 'initial'
  currentStateDep: string = 'initial'
  nomeEdit: string = null
  filtroInativos: string = 'ocultar'

  //Boolean
  isCardFunc: boolean = true
  isCardDep: boolean = true
  rowSelected: boolean = false
  modalFunc: boolean = false
  editFunc: boolean = false
  modalDep: boolean = false
  editDep: boolean = false

  //Array
  colsFuncLista: Array<any> = [
    { field: 'codigo', header: 'CÓDIGO' },
    { field: 'nome', header: 'NOME' },
    { field: 'departamento', header: 'DEPARTAMENTO' },
    { field: 'titulacao', header: 'TITULAÇÃO' },
    { field: 'cargo', header: 'CARGO' },
    { field: 'telefone', header: 'TELEFONE' },
    { field: 'cpf', header: 'CPF' },
    { field: 'rg', header: 'RG' },
    { field: 'admissao', header: 'DATA ADMISSÃO' },
    { field: 'demissao', header: 'DATA DEMISSÃO' },
    { field: 'experiencia', header: 'DATA EXPERIÊNCIA' },
    { field: 'nascimento', header: 'DATA NASCIMENTO' },
    { field: 'idade', header: 'IDADE' },
    { field: 'banco', header: 'BANCO' },
    { field: 'agencia', header: 'AGÊNCIA' },
    { field: 'conta', header: 'CONTA' },
    { field: 'email', header: 'EMAIL' },
    { field: 'ativo', header: 'ATIVO' },
  ]
  funcLista: Array<any> = new Array()
  colsDependenteLista: Array<any> = [
    { field: 'nome', header: 'NOME DEPENDENTE' },
    { field: 'tipo', header: 'TIPO' },
    { field: 'nascimento', header: 'DATA NASCIMENTO' },
    { field: 'idade', header: 'IDADE' }
  ]
  dependenteLista: Array<any> = new Array()
  funcListFiltro: Array<any> = new Array()


  //Dropdown e multiselect
  gerenteList: SelectItem[] = []
  areaList: SelectItem[] = []
  cargoList: SelectItem[] = []
  paisList: SelectItem[] = []
  cidadeList: SelectItem[] = []
  sexoList: SelectItem[] = [
    { label: "Não informado", value: null },
    { label: "Feminino", value: "Feminino" },
    { label: "Masculino", value: "Masculino" }]
  tipoDepList: SelectItem[] = [
    { label: "Não informado", value: null },
    { label: "Cônjuge", value: "Cônjuge" },
    { label: "Filho/Filha", value: "Filho/Filha" }
  ];
  opcoesAtivo: SelectItem[] = [{ label: 'Todos', value: '' }, { label: 'Sim', value: true }, { label: 'Não', value: false }];


  //Classes
  funcionario: Funcionario = new Funcionario()
  dependente: Dependente = new Dependente()

  //Number
  funcSelected: number = null

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private messageService: MessageService
  ) { }


  // Toolbar
  public toolbarMain = new Toolbar()
  public toolbarMainDep = new Toolbar()

  toolbarMainIni = [
    { icon: 'more_vert', text: '', tooltip: 'Opções', visible: true, disabled: false, color: 'default', dropdown: true, onClick: () => this.filterInativos(),
    options: [ { label: 'Ocultar Inativos', callback:() =>  this.filterInativos() }] },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.dt.exportCSV() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => { } },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => { } },
    { icon: 'add', text: 'Novo', tooltip: 'Novo Funcionário', visible: true, disabled: false, color: 'default', onClick: () => this.openAddFunc() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideSelectItem()
        this.changeStateFunc()
      }
    },
  ]

  toolbarMainIniDep = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.dt1.exportCSV() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => { } },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => { } },
    { icon: 'add', text: 'Novo', tooltip: 'Novo Dependente', visible: true, disabled: false, color: 'default', onClick: () => this.openAddDep() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideSelectItemDep()
        this.changeStateDep()
      }
    },
  ]


  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarMainDep.setAll(this.toolbarMainIniDep)
    this.toolbarService.hidden = true
    this.instanciarFuncionario()
    this.instanciarDependente()
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.getFuncionarios()
          this.getArea()
          this.getCargos()
          this.getCidade()
          this.getPais()
        }
      });
  }

  // Hide Cards
  isCardSelectItem: boolean = true;
  ShowHideSelectItem() {
    this.isCardSelectItem = !this.isCardSelectItem;
    this.toolbarMain.setIcon('btn6', this.isCardSelectItem ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn6', this.isCardSelectItem ? 'Ver menos' : 'Ver mais')
  }

  isCardSelectItemDep: boolean = true;
  ShowHideSelectItemDep() {
    this.isCardSelectItemDep = !this.isCardSelectItemDep;
    this.toolbarMainDep.setIcon('btn5', this.isCardSelectItemDep ? 'expand_less' : 'expand_more')
    this.toolbarMainDep.setTooltip('btn5', this.isCardSelectItemDep ? 'Ver menos' : 'Ver mais')
  }

  // Exports
  @ViewChild('dt') dt: Table
  exportMov() {
    this.dt.exportCSV()
  }
  @ViewChild('dt1') dt1: Table
  exportMov1() {
    this.dt1.exportCSV()
  }

  filterInativos() {
    switch (this.filtroInativos) {
      case 'mostrar':
        this.funcLista = this.funcListFiltro
        this.toolbarMainIni[0].options[0].label='Ocultar Inativos'
        this.filtroInativos='ocultar'

      break
      case 'ocultar':
        this.funcLista = this.funcListFiltro.filter(line => line.ativo === true)
        this.toolbarMainIni[0].options[0].label='Mostrar Inativos'
        this.filtroInativos='mostrar'
      break
    }
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  instanciarDependente() {
    this.dependente = new Dependente()
    this.dependente.funcionario = new Funcionario()
  }

  editDependente() {
    if (this.validacaoDep()) {
      return
    }

    const info = {
      user_id: this.sessionService.loggedUser.id,
      dependente: this.dependente
    }

    this.httpService.wait();
    this.httpService.put(this.configService.defaultUrlApiUpload + 'rh/dependentes', null, info)
      .subscribe(
        res => {
          if (!res["error"]) {
            this.modalDep = false
            this.getDependente()
          }
          this.exibirMensagem(res)
          this.httpService.done()
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Error no API ao editar o dependente, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  deleteDependente(row) {
    if (confirm("Confirmar exclusão de dependente?")) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'depenid': [row.id].toString()
        })
      };

      this.httpService.wait();
      this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/dependentes', httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res)
            this.httpService.done()
            if (!res["error"]) {
              this.getDependente()
            }
          },
          err => {
            this.exibirMensagem({ error: true, mensagem: "Error no API ao excluir o dependente, contate o suporte: " + err.message });
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  getCargos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/cargos', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }
          this.cargoList = [];
          res["data"].forEach(row => this.cargoList.push({ label: row["codigo"] ? row["codigo"] + " - " + row["descricao"] : row["descricao"], value: row["id"] }))
          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Error no API ao buscar os cargos, contate o suporte: " + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getArea() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/area', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }

          this.areaList = [{ label: "Não informada", value: null }]
          res["data"].forEach(line => this.areaList.push({ label: line["descricao"], value: line["id"] }))
          this.httpService.done()
        },
        err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API ao obter as áreas, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  getPais() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'util/pais', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }

          this.paisList = [{ label: "Não informado", value: null }];
          res["data"].forEach(row => this.paisList.push({ label: row["nome"], value: row["id"] }))
          this.httpService.done()
        },
        err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API ao obter os paises, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  getCidade() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'util/cidade', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }

          this.cidadeList = [{ label: "Não informada", value: null }];
          res["data"].forEach(row => this.cidadeList.push({ label: row["nome"], value: row["id"] }))
          this.httpService.done()
        },
        err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API ao obter os municípios, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  validacaoFunc(): boolean {
    if (isNullOrUndefined(this.funcionario.codigo)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Código' é obrigatório." })
      return true

    } else if (isNullOrUndefined(this.funcionario.nome)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Nome' é obrigatório." })
      return true

    } else if (isNullOrUndefined(this.funcionario.cargo.id)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Cargo' é obrigatório." })
      return true
    }

    return false
  }

  addFuncionario() {
    if (this.validacaoFunc()) return

    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      funcionario: this.funcionario,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'rh/funcionarios', infos)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
          if (!res["error"]) {
            this.getFuncionarios()
            this.modalFunc = false
          }
        },
        err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API ao salvar o funcionário, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  getFuncionarios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    }

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/funcionarios', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }

          this.funcLista = new Array();
          this.gerenteList = [];
          this.gerenteList.push({ label: "Não informado", value: null });
          res["data"].forEach(row => {
            this.funcLista.push(row)
            this.funcListFiltro = this.funcLista
            this.gerenteList.push({ label: row["nome"], value: row["id"] })
          })
          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao buscar os funcionários, contate o suporte: " + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  instanciarFuncionario() {
    this.funcionario = new Funcionario()
    this.funcionario.cargo = new Cargo()
    this.funcionario.pais = new Pais()
    this.funcionario.empresa = new Empresa()
    this.funcionario.cidade = new MunicipioIbge()
    this.funcionario.gerente = new Funcionario()
    this.funcionario.area = new Area()
  }

  openAddFunc() {

    this.modalFunc = true
    this.editFunc = false
    this.instanciarFuncionario()
  }

  onRowSelectFunc(event) {
    this.toolbarMain.setClick('btn3', () => this.openEditFuncionario(event))
    this.toolbarMain.setClick('btn4', () => this.deleteFuncionario(event))

    this.changeToolbbar();

    this.funcSelected = event.id
    this.nomeEdit = event.nome
    this.rowSelected = true
    this.getDependente();
  }
  onRowSelectDep(event) {
    this.toolbarMainDep.setClick('btn2', () => this.openEditDependente(event))
    this.toolbarMainDep.setClick('btn3', () => this.deleteDependente(event))

    this.dependente.id = event.id
    this.dependente.nome = event.nome
    event.nascimento ? this.dependente.nascimento = new Date(event.nascimento + "-0300") : this.dependente.nascimento = null
    this.dependente.tipo = event.tipo
    this.changeToolbarDep()

  }

  onRowUnselectFunc() {
    this.changeToolbarF();
    this.rowSelected = false
  }

  onRowUnselectDep() {
   this.changeToolbarDepF()
  }

  changeToolbarF() {
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', false)
  }

  changeToolbbar() {
    this.toolbarMain.setVisible('btn3', true)
    this.toolbarMain.setVisible('btn4', true)
  }

  changeToolbarDep() {
    this.toolbarMainDep.setVisible('btn2', true)
    this.toolbarMainDep.setVisible('btn3', true)

  }

  changeToolbarDepF() {
    this.toolbarMainDep.setVisible('btn2', false)
    this.toolbarMainDep.setVisible('btn3', false)

  }


  openEditFuncionario(row) {
    this.editFunc = true
    this.instanciarFuncionario()
    this.modalFunc = true
    this.funcionario.codigo = row.codigo
    this.funcionario.nome = row.nome
    row.cargoid ? this.funcionario.cargo.id = row.cargoid : this.funcionario.cargo = new Cargo()
    row.nascimento ? this.funcionario.nascimento = new Date(row.nascimento + "-0300") : this.funcionario.nascimento = null
    row.admissao ? this.funcionario.admissao = new Date(row.admissao + "-0300") : this.funcionario.admissao = null
    row.demissao ? this.funcionario.demissao = new Date(row.demissao + "-0300") : this.funcionario.demissao = null
    this.funcionario.titulacao = row.titulacao
    this.funcionario.telefone = row.telefone
    this.funcionario.cpf = row.cpf
    this.funcionario.rg = row.rg
    this.funcionario.banco = row.banco
    this.funcionario.agencia = row.agencia
    this.funcionario.conta = row.conta
    this.funcionario.email = row.email
    this.funcionario.atuacao = row.atuacao
    row.paisid ? this.funcionario.pais.id = row.paisid : this.funcionario.pais = new Pais()
    row.cidadeid ? this.funcionario.cidade.id = row.cidadeid : this.funcionario.cidade = new MunicipioIbge()
    this.funcionario.sexo = row.sexo
    row.gerenteid ? this.funcionario.gerente.id = row.gerenteid : this.funcionario.gerente = new Funcionario()
    this.funcionario.graduacao = row.graduacao
    this.funcionario.especializacao = row.especializacao
    row.carreira ? this.funcionario.carreira = new Date(row.carreira + "-0300") : this.funcionario.carreira = null
    row.areaid ? this.funcionario.area.id = row.areaid : this.funcionario.area = new Area()
    this.funcionario.ativo = row.ativo
    this.funcionario.id = row.id
  }

  deleteFuncionario(row) {
    if (confirm("Confirmar exclusão do funcionário?")) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'funcid': [row.id].toString(),
          'userid': this.sessionService.loggedUser.id.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/funcionarios', httpOptions)
        .subscribe(
          res => {
            if (!res["error"]) {
              this.getFuncionarios()
            }
            this.exibirMensagem(res)
            this.httpService.done()
          },
          err => {
            this.exibirMensagem({ error: true, mensagem: "Erro ao excluir o funcionário, contate o suporte: " + err.message });
            console.log(err);
            this.httpService.done();
          }
        )
    }
  }

  editFuncionario() {
    if (this.validacaoFunc()) {
      return
    }

    const infos = {
      funcionario: this.funcionario,
      user_id: this.sessionService.loggedUser.id,
      empresa_id: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait();
    this.httpService.put(this.configService.defaultUrlApiUpload + 'rh/funcionarios', null, infos)
      .subscribe(
        res => {
          if (!res["error"]) {
            this.modalFunc = false
            this.getFuncionarios()
            this.editFunc = false
          }
          this.exibirMensagem(res)
          this.httpService.done()
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao editar o funcionário, contate o suporte: " + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }



  getDependente() {
    this.toolbarMainDep.setVisible('btn2', false)
    this.toolbarMainDep.setVisible('btn3', false)

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'funcid': this.funcSelected.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/dependentes', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }

          this.dependenteLista = new Array()
          res["data"].forEach(row => this.dependenteLista.push(row))
          this.httpService.done()
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao buscar os dependentes, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  validacaoDep(): boolean {
    if (isNullOrUndefined(this.dependente.nome)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Nome' é obrigatório." })
      return true

    } else if (isNullOrUndefined(this.dependente.nascimento)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Nascimento' é obrigatório." })
      return true
    }

    return false
  }

  addDependente() {
    if (this.validacaoDep()) {
      return
    }
    const info = {
      dependente: this.dependente,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'rh/dependentes', info)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
          if (!res["error"]) {
            this.getDependente()
            this.modalDep = false
          }
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao salvar o dependente, contate o suporte: " + err.message });
          console.log(err);
          this.httpService.done();
        }
      );

  }

  openEditDependente(row) {
    this.modalDep = true
    this.editDep = true
    this.instanciarDependente()
    this.dependente.id = row.id
    this.dependente.nome = row.nome
    row.nascimento ? this.dependente.nascimento = new Date(row.nascimento + "-0300") : this.dependente.nascimento = null
    this.dependente.tipo = row.tipo
    this.dependente.funcionario.id = this.funcSelected

  }

  openAddDep() {
    this.modalDep = true
    this.editDep = false
    this.instanciarDependente()
    this.dependente.funcionario.id = this.funcSelected
  }

  changePais() {
    if (this.funcionario.pais.id && this.funcionario.pais.id != 105) {
      this.funcionario.cidade = new MunicipioIbge();
    }
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  changeStateFunc() {
    this.currentStateFunc = this.currentStateFunc === 'initial' ? 'final' : 'initial'
  }

  changeStateDep() {
    this.currentStateDep = this.currentStateDep === 'initial' ? 'final' : 'initial';
  }

}