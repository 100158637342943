import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { Subscription } from 'rxjs/Subscription';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { Message, MessageService } from 'primeng/api';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';

@Component({
  selector: 'app-import-f600',
  templateUrl: './import-f600.component.html',
  styleUrls: ['./import-f600.component.scss'],
})

export class ImportacaoF600Component implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;

  private sessionSubscription: Subscription;

  file: File;
  replace: boolean;
  title: any;

  defaultDate: Date;

  msgs: Message[] = null;

  input_file: any;

  mes: any;

  validated_file: boolean;

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private httpService: HttpService,
    private utilService: UtilService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.title = 'IMPORTAR';
    this.titleService.title = 'Importar arquivo CSV com os dados de F600';
    // this.toolbarService.clear();
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.validated_file = false;
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.defaultDate = new Date();

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
          let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
          // formatando meses com dois digitos
          this.mes = this.defaultDate.getMonth() >= 9 ?
            (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);

          if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
            this.mes = this.dblinkedSessionService.periodo.month.value >= 9 ?
              this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
            const dt = this.dblinkedSessionService.periodo.year.value + '-' + this.mes + '-01 12:00:00';
            this.defaultDate = new Date(dt);
            // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
            lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
          }
        }
      });
  }

  ngOnDestroy() {
    // this.toolbarService.clear();
    this.toolbarService.hidden = false;
  }

  // Método chamado quando o usuário seleciona um arquivo para importação.
  fileChange() {

    this.file = this.fileInput.nativeElement.files[0];
    if (this.file.name.substr(this.file.name.length - 4) != '.csv') {
      this.validated_file = false;
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Arquivo inválido. Favor importar um arquivo .csv' });
    } else {
      this.validated_file = true;
    }
  }

  // Método chamado quando o usuário importa um arquivo CSV
  importar() {

    // Verificar se o arquivo a ser importado é do tipo CSV
    if (this.validated_file === true) {

      const data: FormData = new FormData();
      data.append('file', this.file);
      data.append('type', 'f600');
      data.append('month', this.mes.toString());
      data.append('year', this.dblinkedSessionService.periodo.year.value.toString());
      data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
      data.append('usuarioId', this.sessionService.loggedUser.id.toString());
      data.append('cnpj', this.dblinkedSessionService.empresa.cnpj);

      this.httpService.wait();
      this.httpService.post('/custom/upload', data)
                      .subscribe(result => { console.log(result);

                      let resposta = result["result"];


                      // Caso o retorno tenha produzido algum erro, exibir a mensagem ao usuário.
                      if (resposta["error"] === true) {
                        this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: resposta["message"] });
                      } else {

                        // Caso a operação seja bem-sucedida e todos os registros sejam inseridos.
                        if (resposta["codigoRetorno"] === 0) {
                          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: resposta["message"] });

                        // Caso algum registro não tenha sido inserido com sucesso na tabela F600.
                        } else {
                          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: resposta["message"] });
                          if (resposta["file"] != null) {
                            window.open(this.httpService.url('/custom/file/' + resposta["file"] + '/keep'), '_blank');
                          }
                        }
                      }

                      this.validated_file = false;
                    },

                    error => this.httpService.handleError(error,
                    () => this.importar()),
                    () => this.httpService.done());
    }
  }

  private uploaded(result?: any) {
    this.messageService.add({
      severity: result.error ? 'error' : 'success',
      detail: result.message || 'Upload efetuado com sucesso!'
    });
  }

  get importDisabled(): boolean {
    return !this.dblinkedSessionService.hasEmpresaSelected || !this.file || !this.validated_file;
  }

  hideMessage() {
    this.file = null;

  }
}
