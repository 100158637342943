import { Component, OnInit, OnDestroy } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService } from 'primeng/api';
import { isNullOrUndefined } from 'util';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-config-cad-ccus-cad-proj',
  templateUrl: './config-cad-ccus-cad-proj.component.html',
  styleUrls: ['./config-cad-ccus-cad-proj.component.scss'],
})
export class ConfigCadCCusCadProjComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = [];

  titlePrincipal: string;
  subtitle: string;


  centrosDeCustos: SelectItem[];
  centroDeCustoId: number;
  listaProjetosMapeados: Array<any> = new Array();
  listaProjetosAMapear: Array<any> = new Array();

  picklistState: boolean = true
  toolbarMain: Toolbar = new Toolbar();

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
    this.toolbarService.hidden = true;
  }

  ngOnInit(): void {

    this.toolbarMain.setAll([
      { icon: 'save', text: 'Salvar', visible: true, disabled: false, color: 'green', tooltip: 'Salvar relacionamento', onClick: () => this.salvarRelacionamento() },
    ])

    this.titlePrincipal = 'CONFIGURAÇÕES';
    this.subtitle = 'PROJETOS CENTRO DE CUSTO';
    this.titleService.title = 'Projetos para Centro de custo';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.centroDeCustoId = 0;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }


  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

      this.listaProjetosMapeados = new Array();
      this.listaProjetosAMapear = new Array();

      this.centrosDeCustos = [];
      this.centrosDeCustos.push({ label: 'Selecione o Centro de Custo', value: 0 });

      this.httpService.wait();
      this.httpService.get('custom/cadastro/get-centros_custos_relacionados', [this.dblinkedSessionService.empresa.id])
        .subscribe(retorno => {
          retorno.forEach(element => {
            this.centrosDeCustos.push({ label: element.codCcus + ' - ' + element.descrCcus, value: element.id });
          });

        }, error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
    }
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  salvarRelacionamento() {

    if (this.validacao()) {
      return;
    }


    if (confirm('Deseja realmente salvar este relacionamento?')) {
      const req = {
        centroDeCustoId: this.centroDeCustoId,
        // listaProjetosAMapear    : this.listaProjetosAMapear,
        listaProjetosMapeados: this.listaProjetosMapeados,
        empresaId: this.empresa.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/cadastro/salvar-relacionamento-centro-custo-projetos', req)
        .subscribe(result => {
          if (result.error) {
            this.messageService.add({
              severity: result.severity,
              summary: result.summary,
              detail: result.detail
            });
          } else {
            this.messageService.add({
              severity: result.severity,
              summary: result.summary,
              detail: result.detail
            });
          }
          this.change();
          this.centroDeCustoId = 0;
        },
          error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
          () => this.httpService.done());
    }
  }

  validacao() {


    if (isNullOrUndefined(this.centroDeCustoId) || this.centroDeCustoId === 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um Centro de Custo'
      });
      return true;
    }


    if (isNullOrUndefined(this.empresa.id) || this.empresa.id === 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma empresa'
      });
      return true;
    }

    return false;

  }

  buscarProjetos() {

    this.listaProjetosMapeados = new Array();
    this.listaProjetosAMapear = new Array();

    this.picklistState = false

    if (this.centroDeCustoId > 0) {
      this.httpService.wait();
      this.httpService.get('custom/cadastro/get-projetos', [this.dblinkedSessionService.empresa.id, this.centroDeCustoId])
        .subscribe(retorno => {
          retorno.forEach(element => {
            if (element.relac) {
              this.listaProjetosMapeados.push(element);
            } else {
              this.listaProjetosAMapear.push(element);
            }
          });
          this.picklistState = true
        }, error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
    } else {
      this.picklistState = true
    }
  }

}
