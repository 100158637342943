import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { ConfigService } from "app/shared/services/config.service";
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service";
import { HttpService } from "app/shared/services/http.service";
import { SessionService } from "app/shared/services/session.service";
import { UtilService } from "app/shared/services/util.service";
import { Message, MessageService } from "primeng/api";
import { isNullOrUndefined } from "util";


@Component({
  selector: 'app-nfe-fin-edit',
  templateUrl: './nfe-fin-edit.component.html',
  styleUrls: ['./nfe-fin-edit.component.scss'],
  animations: []
})

export class NfeFinEditComponent implements OnInit, OnDestroy {

  @Input() nfeFinId : any;
  @Input() userId   : any;

  idNfesBaixa: Array<any> = new Array();

  checkPerm         : boolean;
  editPerm         : boolean;
  parcelas          : Array<any>
  part              : String;
  codPed            : String;
  numDoc            : String;
  codigoControle    : String;

  constructor(
    private configService: ConfigService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {


  }

  ngOnInit() {

    setTimeout(() => {
      this.empresaChanged()
    }, 2000);


  }

  ngOnDestroy() {

  }


  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.editPerm = false;

      this.httpService.wait();
      this.httpService.get('/custom/permissoes/get-permissao-wf('+ this.dblinkedSessionService.empresa.id+',' + this.userId + ',' + ('crcp') +')')
      .subscribe(ret => this.verificaPermissao(ret),
      error => this.httpService.handleError(error, () => this.empresaChanged()),
      () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/financeiro/get-financeiro-id('+this.nfeFinId+','+ this.dblinkedSessionService.empresa.id + ')')
      .subscribe(ret => this.getFinanceiroId(ret),
      error => this.httpService.handleError(error, () => this.empresaChanged()),
      () => this.httpService.done());

    }

  }

  verificaPermissao(event: any){
    this.checkPerm = event.check;

  }

  getFinanceiroId(event: any){

    this.editPerm = false;
    this.parcelas = new Array();

    console.log(event);

    this.part = !isNullOrUndefined(event.cab.codPart) ? event.cab.codPart : '';
    this.codPed  = !isNullOrUndefined(event.cab.codPedido) ? event.cab.codPedido : '';
    this.numDoc  = !isNullOrUndefined(event.cab.numDoc) ? event.cab.numDoc : '';
    this.codigoControle  = !isNullOrUndefined(event.cab.codigoControle) ? event.cab.codigoControle : '';


    event.detal.forEach(element => {
      this.parcelas.push(element);
    });


  }

  hideMessage(){

  }

  salvar(event: any){
    if (confirm('Deseja realmente salvar este título?')) {

      this.idNfesBaixa = new Array();
      this.idNfesBaixa.push(event.nfeFinId);

    console.log(event);
    const listasEnviar = {
      data: this.utilService.utcDate(event.dtExtrato),
      dataPrevisao: null,
      banco: null,
      id: this.idNfesBaixa,
      usuario: this.sessionService.loggedUser.id,
      campoAlteracao: 'alteraData',
      codigoBarra: event.codigoBarra
    };

    this.httpService.wait();
    this.httpService.post('/custom/financeiro/alterar_data_banco', listasEnviar)
      .subscribe(result => {
        this.messageService.add({severity: 'success',key: 'messageLinnks', summary: '', detail: 'Operação Realizada com Sucesso!' });
        this.buscarInfosTitulo(event.nfeFinId);
       },
        error => this.httpService.handleError(error, () => this.salvar(event)),
        () => this.httpService.done());
  }
  }

  baixar(event: any){

    if (confirm('Deseja realmente baixar este item?')) {

      this.idNfesBaixa = new Array();
      this.idNfesBaixa.push(event.nfeFinId);


    const listasEnviar = {
      id: (this.idNfesBaixa),
      usuario: this.sessionService.loggedUser.id
    };
    this.httpService.wait();
    this.httpService.post('/custom/financeiro/baixar-lote-titulo', listasEnviar)
      .subscribe(result => {

        if (result[0].error === true) {
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: result[0].message});
        } else {
          this.messageService.add({severity: 'success',key: 'messageLinnks', summary: '', detail: result[0].message});
          this.buscarInfosTitulo(event.nfeFinId);
        }
      },
        error => this.httpService.handleError(error, () => this.baixar(event)),
        () => this.httpService.done());
    }
  }

  buscarInfosTitulo(nfeFinId: number){
    this.httpService.wait();
      this.httpService.get('/custom/financeiro/get-financeiro-id('+nfeFinId+','+ this.dblinkedSessionService.empresa.id + ')')
      .subscribe(ret => this.getFinanceiroId(ret),
      error => this.httpService.handleError(error, () => this.empresaChanged()),
      () => this.httpService.done());
  }

  editar(){

    if(!this.checkPerm){
      this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: '', detail: 'Você não tem permissão para editar financeiro, somente leitura'});
      this.editPerm = false;
    } else {
      this.editPerm = true;
    }
  }

  desfazerBaixa(event: any){
    if (confirm('Deseja realmente desfazer esta baixa?')) {

          let isAlterar = false;

      this.httpService.wait();
      this.httpService.post('/custom/financeiro/deletar-baixa(' + event.nfeFinBaixaId + ',' + isAlterar + ')')
        .subscribe(result => {

          if (result[0].error === true) {
            this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: result[0].message});
            this.buscarInfosTitulo(event.nfeFinId);
          } else {
            this.messageService.add({severity: 'success',key: 'messageLinnks', summary: '', detail: result[0].message});
            this.buscarInfosTitulo(event.nfeFinId);
          }
        },
          error => this.httpService.handleError(error, () => this.desfazerBaixa(event)),
          () => this.httpService.done());

  }
}

}
