import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { Periodicidade } from 'app/util/model/periodicidade.model';

export class CodigosObrigacoes {
    id: number;
    cod: string;
    descricao: string;
    tipoArquivo: TipoArquivo;
    periodicidade: Periodicidade;
    observacaoRfb: string;

    constructor(codigosobrigacoes?: CodigosObrigacoes | number) {
      if (codigosobrigacoes !== undefined && codigosobrigacoes !== null) {
        if (typeof codigosobrigacoes === 'number') {
          this.id = codigosobrigacoes;
        } else {
          this.id = codigosobrigacoes.id;
          this.cod = codigosobrigacoes.cod;
          this.descricao = codigosobrigacoes.descricao;
          this.tipoArquivo = codigosobrigacoes.tipoArquivo;
          this.periodicidade = codigosobrigacoes.periodicidade;
          this.observacaoRfb = codigosobrigacoes.observacaoRfb;
        }
      }
    }
  }
