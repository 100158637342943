import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { isNullOrUndefined } from 'util';
import {GetNetPagamento} from 'app/getnet/model/getnet-pagamento-cartao-credito.model'
import {Eventos} from 'app/eventos/model/eventos.model';
import {GetNetPagamentoHistorico} from  'app/getnet/model/getnet-pagamento-historico.model';
import { GetNetPagamentoParcela } from '../model/getnet-pagamento-parcela.model';
import { isDate } from 'moment';
import { Message, MessageService } from 'primeng/api';


@Component({
  selector: 'app-getnet-pagamento-cartao-credito',
  templateUrl: './getnet-pagamento-cartao-credito.component.html',
  styleUrls: ['./getnet-pagamento-cartao-credito.component.scss'],
  providers: [CrudService]
})
export class GetnetPagamentoCartaoCreditoComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;


  listaGetnetPagamento  : Array<any> = new Array();
  idLista           : Array<any> = new Array();

  checkedAll = false;
  strDescricaoTipoIngresso          : string;

  btnSalvarDisabled                 : boolean = false;
  btnCancelarDisabled               : boolean = false;
  modalInformacoes                  : boolean = false;
  getNetPagamento                   = new GetNetPagamento();

  evento                            = new Eventos();
  isPodeEstornar                    : boolean = false;
  modalEstorno                      : boolean = false;
  valorEstorno                      : number = 0.00;
  selectedIndex                     : number = 0;
  justificativaEstorno              : string = '';
  listaHistorico                    : Array<any> = new Array();
  opcoesComboStatus                 : Array<any> = new Array();
  opcoesComboRecorrencia            : Array<any> = new Array();

  modalPeriodo : boolean = false;
  dataIni: Date;
  dataFin: Date;

  getNetPagamentoHistorico = new GetNetPagamentoHistorico();
  getNetPagamentoparcela   = new GetNetPagamentoParcela();

  getNetParcelaId          : number = 0;
  getnetPlanId             : string = '';
  subscriptionId           : string = '';
  parcelaCompleto          : string = '';
  isPodeAlterarData        : boolean = false;
  colsListaGetnetPagamento : any[] = [];
  colsListaHistorico       : any[] = [];

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'GETNET PAGAMENTO CARTÃO DE CRÉDITO';
    this.subtitle = '';
    this.titleService.title = 'GETNET PAGAMENTO CARTÃO DE CRÉDITO';

    this.checkedAll = false;
    this.modalInformacoes = false;

    this.listaGetnetPagamento = new Array();

    this.idLista        = new Array();
    this.selectedIndex  = 0;
    this.modalEstorno   = false;
    this.modalInformacoes = false;
    this.justificativaEstorno = '';
    this.getnetPlanId = '';
    this.subscriptionId = '';

    this.colsListaGetnetPagamento =[
      { field: 'nome', header: 'NOME' },
      { field: 'cpf', header: 'CPF' },
      { field: 'telefone', header: 'TELEFONE' },
      { field: 'descricaoEventoCompleto', header: 'EVENTO' },
      { field: 'quantidade', header: 'QTD INGRESSO(S)' },
      { field: 'parcelaCompleto', header: 'PARCELA(S)' },
      { field: 'valorTotal', header: 'VL TOTAL' },
      { field: 'valor', header: 'VL PAGO' },
      { field: 'pagarEm', header: 'PAGAR EM' },
      { field: 'recorrenciaPt', header: 'RECORRÊNCIA' },
      { field: 'statusDescricaoPt', header: 'STATUS' },
    ];

    this.colsListaHistorico = [
      { field: 'horaBrasil', header: 'DT REQ.' },
      { field: 'authorizedAt', header: 'PAGO EM' },
      { field: 'paymentId', header: 'PAGAMENTO ID' },
      { field: 'transactionId', header: 'TRANSAÇÃO ID' },
      { field: 'valor', header: 'VL PAGO' },
      { field: 'cancelRequestAt', header: 'CANCELADO EM' },
      { field: 'cancelRequestId', header: 'CANCEL ID' },
      { field: 'valorEstorno', header: 'VL ESTORNO' },
      { field: 'justificativaEstorno', header: 'JUST. ESTORNO' },
      { field: 'descricaoStatus', header: 'STATUS' },
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
          && this.dblinkedSessionService.periodo.year) {


            const ano = this.dblinkedSessionService.periodo.year.value;
            const lastDay = new Date(ano, this.dblinkedSessionService.periodo.month.value + 0, 0); // ultimo dia do mês
            // formatando meses com dois digitos
            let mes = this.dblinkedSessionService.periodo.month.value > 9 ?
              (+this.dblinkedSessionService.periodo.month.value  ) :'0'  + (+this.dblinkedSessionService.periodo.month.value );

            this.dataIniSearch = ano + '-' + mes + '-01';
            this.dataFinSearch = ano + '-' + mes + '-' + lastDay.getDate();

            this.listar();
            setTimeout(() => {
              this.toolbarEnable();
            }, 500);

          }


      });

  }

 listar(){
  this.getNetPagamentoHistorico = new GetNetPagamentoHistorico();
  this.checkedAll = false;
  this.listaGetnetPagamento = new Array();
  this.idLista          = new Array();
  this.listaHistorico   = new Array();
  this.getNetParcelaId  = 0;

  this.httpService.wait();
  this.httpService.get('/custom/getnet/get-lista-pagamentos-getnet-cartao-credito(' + [this.dblinkedSessionService.empresa.id, this.dataIniSearch , this.dataFinSearch] + ')')
    .subscribe(retorno => {
      this.listaGetnetPagamento = new Array();
      this.opcoesComboStatus    = new Array();
      this.opcoesComboRecorrencia = new Array();
      retorno.forEach(i =>{
        this.listaGetnetPagamento.push(i);

        var index = 0;

        if (this.opcoesComboStatus !== null || this.opcoesComboStatus !== undefined) {
          index = this.opcoesComboStatus.findIndex(e => e.value === i.statusDescricaoPt);
        }

        if (index === -1) {
          this.opcoesComboStatus.push({label: i.statusDescricaoPt , value: i.statusDescricaoPt});
        }

        this.opcoesComboStatus.sort((a,b) => a.label.localeCompare(b.label));

        index = 0;

        if (this.opcoesComboRecorrencia !== null || this.opcoesComboRecorrencia !== undefined) {
          index = this.opcoesComboRecorrencia.findIndex(e => e.value === i.recorrenciaPt);
        }

        if (index === -1) {
          this.opcoesComboRecorrencia.push({label: i.recorrenciaPt , value: i.recorrenciaPt});
        }

        this.opcoesComboRecorrencia.sort((a,b) => a.label.localeCompare(b.label));

      })

    },
      error => this.httpService.handleError(error, () => this.listar()),
      () => this.httpService.done());

 }


  hideMessage() {



  }

  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;

    this.idLista = new Array();

    this.listaGetnetPagamento.forEach(i=> {
      if (!this.checkedAll) {
        i.selected = false;
        this.idLista.slice(i.id, 1);
      } else {
        i.selected = true;
        this.idLista.push(i.id);
      }
    });


    this.configuraToolBar();
  }

  configuraToolBar() {



    if (isNullOrUndefined(this.idLista) || this.idLista.length === 0) {
    // this.toolbarService.alterarStatus('remove', true, 'Excluir Consultor', () => this.excluirConsultor());
     //this.toolbarService.alterarStatus('add', false, 'Novo Consultor', ()  => this.showModalNovoConsultor());
     //this.toolbarService.alterarStatus('export', true, 'Editar Consultor', () =>() => this.showModalEditarConsultor());
    } else {
      if (this.idLista.length === 1) {
      //  this.toolbarService.alterarStatus('remove', false, 'Excluir Consultor', () => this.excluirConsultor());
       // this.toolbarService.alterarStatus('add', true, 'Novo Consultor', ()  => this.showModalNovoConsultor());
       // this.toolbarService.alterarStatus('export', false, 'Editar Consultor', ()  => this.showModalEditarConsultor());


      } else if(this.idLista.length > 1)  {
        //this.toolbarService.alterarStatus('remove', false, 'Excluir Consultor', () => this.excluirConsultor());
        // this.toolbarService.alterarStatus('add', true, 'Novo Consultor', () => this.showModalNovoConsultor());
        // this.toolbarService.alterarStatus('export', true, 'Editar Consultor', () => this.showModalEditarConsultor());
      }
    }

  }

  selectCheckbox(id: any, event: any) {

    if (event.selected) {
       event.selected = true;
       const index = this.idLista.findIndex(i => i === id);
       this.idLista.splice(index,1);

    } else {
      this.idLista.push(id);
    }

    this.configuraToolBar();

  }


  onFilterEvento(event: any) {
  }

  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('edit');


    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.listar()
    );



    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('save');

    this.toolbarService.replace('edit', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());

    this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo Registro',    () => ''    );

  }



  ngOnDestroy(): void {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }



  showModalInformacoes(value : any){
    console.log(value);


    this.parcelaCompleto = '';

    this.selectedIndex = 0;
    this.isPodeEstornar = false;
    this.justificativaEstorno = '';

    this.getNetPagamento = new GetNetPagamento();
    this.getNetPagamento.evento = new Eventos();
    this.getNetPagamentoHistorico = new GetNetPagamentoHistorico();
    this.getNetPagamentoparcela   = new GetNetPagamentoParcela();

    this.evento          = new Eventos();
    this.getNetPagamento.id = value.id;
    this.getNetPagamento.nome = value.nome;
    this.getNetPagamento.cpf  = value.cpf;
    this.getNetPagamento.telefone = value.telefone;
    this.getNetPagamento.quantidade = value.quantidade;
    this.getNetPagamento.valorTotal = value.valorTotal;
    this.getNetPagamento.evento.id  = value.eventoId;
    this.getNetPagamento.eventoId   = value.eventoId;
    this.getNetPagamento.eventoDescricao = value.descricaoEventoCompleto;
    this.getNetPagamento.cartaoNome = value.cartaoNome;
    this.getNetPagamento.ultimoDigitoCartao = value.ultimoDigitoCartao;
    this.getNetPagamento.mensagem           = value.mensagem;
    this.getNetPagamento.quantidade         = value.quantidade;
    this.getNetPagamento.parcela            = value.parcela;
    this.getNetPagamento.valorPago          = value.valor;
    this.getNetPagamento.paymentId          = value.paymentId;
    this.isPodeEstornar = value.isPodeEstornar;
    this.getNetPagamentoHistorico.id = value.pagamentoHistoricoId;
    this.getNetPagamentoHistorico.valorEstorno = value.valorEstorno;
    this.getNetPagamentoHistorico.descriptionDetail = value.descriptionDetail;
    this.getNetPagamentoHistorico.getNetPagamentoParcelaId = value.pagamentoParcelaId;
    this.getNetParcelaId = value.pagamentoParcelaId;
    this.getnetPlanId = value.getnetPlanId;
    this.subscriptionId = value.subscriptionId;
    this.parcelaCompleto = value.parcelaCompleto;
    this.isPodeAlterarData = value.isPodeAlterarData;
    //parcela
    this.getNetPagamentoparcela.id = value.pagamentoParcelaId;
    this.getNetPagamentoparcela.valorTotal = value.valorTotal;
    this.getNetPagamentoparcela.paymentId  = value.paymentId;

    try {


      var dataPagarEm = new Date();
      var dataString = '';
      dataString = value.pagarEm;

      if (!isNullOrUndefined(dataString) && dataString !== '' ) {
        dataPagarEm.setDate( parseInt( dataString.substring(0, 2)));

        dataPagarEm.setMonth( parseInt(dataString.substring(3, 5) ) - 1);
        dataPagarEm.setFullYear( parseInt( dataString.substring(6, 10)));

        this.getNetPagamentoparcela.pagarEm = new Date(dataPagarEm);
      }


    } catch (error) {

    }

    this.modalInformacoes = true;

  }


  estornarPagamento(){
    this.valorEstorno         = 0.00;
    this.modalEstorno         = true;
    this.valorEstorno         = this.getNetPagamento.valorTotal;
    this.justificativaEstorno = '';
  }

  salvarEstorno(){

    if (this.validacaoEstorno()) {
      return;
    }

    const listaEnviar = {
      parcelaId             :  this.getNetParcelaId,
      getNetPagamento       : this.getNetPagamento,
      valorEstorno          : this.valorEstorno,
      usuarioId             : this.sessionService.loggedUser.id,
      justificativaEstorno  : this.justificativaEstorno,

   };


   this.httpService.wait();
   this.httpService.post('/custom/getnet/salvar-estorno-getnet', listaEnviar)
     .subscribe(result => {
       this.modalEstorno         = false;
       this.modalInformacoes     = false;

       if (result.error) {
         this.messageService.add({
           severity: 'error',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result.message
         });
       } else {
         this.messageService.add({
           severity: 'success',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result.message
          });
       }

       this.listar();
     },
       error => this.httpService.handleError(error, () => this.salvarEstorno()),
       () => this.httpService.done());



  }
  verificarValorEstornado(){

  }

  tabChange(event: any){


    switch (event.index) {
      case 1:
        this.listaHistorico = new Array();
        this.getHistoricoPagamento();

        break;

      default:
        break;
    }


  }

  getHistoricoPagamento(){

    this.httpService.wait();
    this.httpService.get('/custom/getnet/get-lista-historico-pagamentos(' + [this.getNetPagamento.id, this.getNetPagamentoHistorico.getNetPagamentoParcelaId] + ')')
      .subscribe(retorno => {

        this.listaHistorico = new Array();
        retorno.forEach(i =>{
          this.listaHistorico.push(i);
        })
      },
        error => this.httpService.handleError(error, () => this.getHistoricoPagamento()),
        () => this.httpService.done());


  }


  openModalPeriodo() {

    this.modalPeriodo = true;
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
  }

  periodoChange() {

    if (isNullOrUndefined(this.dataIni) || this.dataIni.toString() ==='') {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Favor selecionar uma data inicial. Verifique!' });
      return;

    }

    if (isNullOrUndefined(this.dataFin) || this.dataFin.toString() ==='') {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Favor selecionar uma data final. Verifique!' });
      return;
    }

    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() +1 > 9 ?
                    (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() +1 > 9 ?
                    (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 9) ?
                    this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
                    this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }
    const d1 = new Date(this.dataIniSearch);
    const d2 = new Date(this.dataFinSearch);
    if (d1 > d2) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Data incial maior que a final. Verifique!' });
      return;
    }


    this.httpService.wait();
    this.httpService.get('/custom/getnet/get-lista-pagamentos-getnet-cartao-credito(' + [this.dblinkedSessionService.empresa.id, this.dataIniSearch , this.dataFinSearch] + ')')
      .subscribe(retorno => {

        this.listaGetnetPagamento = new Array();
        this.opcoesComboStatus    = new Array();
        this.opcoesComboRecorrencia = new Array();
        retorno.forEach(i =>{
          this.listaGetnetPagamento.push(i);

          var index = 0;

          if (this.opcoesComboStatus !== null || this.opcoesComboStatus !== undefined) {
            index = this.opcoesComboStatus.findIndex(e => e.value === i.statusDescricaoPt);
          }

          if (index === -1) {
            this.opcoesComboStatus.push({label: i.statusDescricaoPt , value: i.statusDescricaoPt});
          }

          this.opcoesComboStatus.sort((a,b) => a.label.localeCompare(b.label));

          index = 0;

          if (this.opcoesComboRecorrencia !== null || this.opcoesComboRecorrencia !== undefined) {
            index = this.opcoesComboRecorrencia.findIndex(e => e.value === i.recorrenciaPt);
          }

          if (index === -1) {
            this.opcoesComboRecorrencia.push({label: i.recorrenciaPt , value: i.recorrenciaPt});
          }

          this.opcoesComboRecorrencia.sort((a,b) => a.label.localeCompare(b.label));


        })



      },
        error => this.httpService.handleError(error, () => this.periodoChange()),
        () => this.httpService.done());

  }


  validacaoEstorno(){

    if(isNullOrUndefined( this.justificativaEstorno) ||  this.justificativaEstorno.toString() === ''){

        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor digitar uma justificativa!'
        });
        return true;

    }

    return false;
  }

  salvarEdicaoParcelaPagamento(){

    if (this.validacaoEdicaoParcela()) {
      return;
    }

    const listaEnviar = {

      getNetPagamentoparcela : this.getNetPagamentoparcela,
       usuarioId      : this.sessionService.loggedUser.id,
       IsNovoRegistro : false
    };


    this.httpService.wait();
    this.httpService.post('/custom/getnet/editar-parcela-pagamento', listaEnviar)
      .subscribe(result => {
        this.modalInformacoes = false;
        if (result[0].error) {
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        }

        this.listar();
      },
        error => this.httpService.handleError(error, () => this.salvarEdicaoParcelaPagamento()),
        () => this.httpService.done());
  }

  validacaoEdicaoParcela(){
    console.log(this.getNetPagamentoparcela.pagarEm);
    if (isNullOrUndefined( this.getNetPagamentoparcela.pagarEm) || this.getNetPagamentoparcela.pagarEm.toString() === '' ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor fornecer uma data para a cobrança'
      });
      return true;

    } else{
      if (isDate(this.getNetPagamentoparcela.pagarEm)) {

        var dataAtual  = new Date();
        var dataCobrar = new Date();

        dataCobrar.setDate(this.getNetPagamentoparcela.pagarEm.getDate());
        dataCobrar.setMonth(this.getNetPagamentoparcela.pagarEm.getMonth());
        dataCobrar.setFullYear(this.getNetPagamentoparcela.pagarEm.getFullYear());

        if (!(dataAtual < dataCobrar) ) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'A data de cobrança não pode ser uma data inferior a data de amanhã'
          });
          return true;
        }

      } else {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor preencher com uma data válida'
        });
        return true;
      }

    }

    if (isNullOrUndefined( this.getNetPagamentoparcela.valorTotal) || this.getNetPagamentoparcela.valorTotal.toString() === '' ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar um valor'
      });
      return true;

    } else{
      var valorPagamento = Number.parseFloat( this.getNetPagamentoparcela.valorTotal.toString().replace(",","."));
      if ( valorPagamento < 1) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'O valor à ser pago não pode ser menor que 1'
        });
        return true;
      }
    }


    return false;
  }

  onFilter(event: any){

  }

}
