export class Justificativas {
    id: number;
    descricao: string;

    constructor(justificativas?: Justificativas | number) {
      if (justificativas !== undefined && justificativas !== null) {
        if (typeof justificativas === 'number') {
          this.id = justificativas;
        } else {
          this.id = justificativas.id;
          this.descricao = justificativas.descricao;
        }
      }
    }
  }
