

export class LocalidadeGrupoMunicipioPK {

  localidadeGrupoId: number;
  municipioIbgeId: number;


  constructor(localidadeGrupoMunicipioPK?: LocalidadeGrupoMunicipioPK) {
    if (localidadeGrupoMunicipioPK !== undefined && localidadeGrupoMunicipioPK !== null) {
      this.localidadeGrupoId = localidadeGrupoMunicipioPK.localidadeGrupoId;
      this.municipioIbgeId = localidadeGrupoMunicipioPK.municipioIbgeId;
    }
  }
}
