import { CodNaturezaConta } from '../../util/model/cod-natureza-conta.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { PlanoContasRefRelac } from './plano-contas-ref-relac.model';
import { TbIrpjM300Relac } from '../../ecf/model/tb-irpj-m300-relac.model';
import { TbCsllM350Relac } from '../../ecf/model/tb-csll-m350-relac.model';
import { PlanoGerencialContasVinculacao } from './plano-gerencial-contas-vinculacao.model';

export class PlanoContas {
  id: number;
  dtAlt: Date;
  codNaturezaConta: CodNaturezaConta;
  indCta: string;
  nivel: number;
  codCta: string;
  descrCta: string;
  empresa: Empresa;
  selected = false;
  tbIrpjM300Relac: TbIrpjM300Relac;
  tbCsllM350Relac: TbCsllM350Relac;
  planoContasRefRelacList: Array<PlanoContasRefRelac>;
  planoContasGerencialRelacList: Array<PlanoGerencialContasVinculacao>;
  dtInicial: Date;
  dtFinal: Date;
  permiteLancamentoManual: boolean;
  aceitaRateio: boolean;
  ativo: boolean;

  descrEn: string;
  descrEs: string;

  parent: PlanoContas;
  children: Array<number>;
  label: string;

  grupoEmpresaContaOrigem: PlanoContas;

  contaAutomatica: boolean;


  constructor(plano?: PlanoContas | number) {
    if (plano !== undefined && plano !== null) {
      if (typeof plano === 'number') {
        this.id = plano;
      } else {
        this.id = plano.id;
        this.dtAlt = plano.dtAlt;
        this.codNaturezaConta = plano.codNaturezaConta;
        this.indCta = plano.indCta;
        this.nivel = plano.nivel;
        this.codCta = plano.codCta;
        this.descrCta = plano.descrCta;
        this.empresa = plano.empresa;
        this.selected = plano.selected || false;
        this.tbIrpjM300Relac = plano.tbIrpjM300Relac;
        this.tbCsllM350Relac = plano.tbCsllM350Relac;
        this.planoContasRefRelacList = plano.planoContasRefRelacList;
        this.planoContasGerencialRelacList = plano.planoContasGerencialRelacList;
        this.dtInicial = plano.dtInicial;
        this.dtFinal = plano.dtFinal;
        this.ativo = plano.ativo;
        this.permiteLancamentoManual = plano.permiteLancamentoManual;
        this.aceitaRateio = plano.aceitaRateio;
        this.descrEs = plano.descrEs;
        this.descrEn = plano.descrEn;

        this.parent = plano.parent;
        this.children = plano.children;

        this.grupoEmpresaContaOrigem = plano.grupoEmpresaContaOrigem;
        this.contaAutomatica = plano.contaAutomatica;
      }
    }
  }
}
