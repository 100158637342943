import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { Destinatarios } from '../model/destinatarios.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { CrudService } from 'app/shared/services/crud.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from '../../../../node_modules/primeng/api';
import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { Util } from 'app/shared/common/util';

@Component({
  selector: 'app-destinatarios',
  templateUrl: './destinatarios.component.html',
  styleUrls: ['./destinatarios.component.scss'
  ],
  providers: [CrudService]
})

export class DestinatariosComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  sessionSubscription: Subscription;
  destinatario: Destinatarios = new Destinatarios();

  msgs: Message[] = [];
  msgsModal: Message[] = [];


  mainTitle: string;
  subtitle: string;
  novaRequisicao: boolean;
  todosSelecionados: boolean;
  alterando: boolean;
  empresaId: any;

  mapTipoArquivos: SelectItem[];
  vetTipoArquivo: Array<any> = new Array();
  destsFiltrados: Array<any> = new Array();
  destsSelecionados: Array<any> = new Array();
  mapDestinatarios: Array<any> = new Array();

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private toolbarService: ToolbarService,
    private configService: ConfigService,
    private messageService: MessageService) {
    super(crudService);
  }

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.dblinkedSessionService.hidePeriods();

    this.mainTitle = 'Destinatários';
    this.subtitle = 'Configuração de Destinatários da CentralWeb';
    this.mapTipoArquivos = [];
    this.vetTipoArquivo = [];
    this.mapDestinatarios = [];
    this.destsFiltrados = [];
    this.destsSelecionados = [];
    this.todosSelecionados = false;
    this.alterando = false;


    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged, () => {
        this.empresaChanged();
      });
  }

  hideMessage() {

  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  selectDestinatario(event: any) {
    this.destsSelecionados.push(event.data);
    this.toolbarEdit();
  }

  unselectDestinatario(event: any) {
    let index = this.destsSelecionados.indexOf(event.data);
    this.destsSelecionados.splice(index, 1);

    this.toolbarEdit();
  }

  selectTodos(event: any) {
    if (this.destsFiltrados.length > 0)
      this.destsSelecionados = this.destsFiltrados;
    else
      this.destsSelecionados = this.mapDestinatarios;

    if (this.destsSelecionados.length === 0) {
      this.destsSelecionados.forEach(dest => { dest.selected = !dest.selected; this.destsSelecionados.push(dest) });
      this.todosSelecionados = !this.todosSelecionados;
      return;
    } else {
      this.destsSelecionados.forEach(dest => {
        event.checked ? dest.selected = true : dest.selected = false;
        // if(!dest.selected) {
        //   dest.selected = !dest.selected;
        if (dest.selected) {
          this.destsSelecionados.push(dest);
        }
        // }
      });
      this.todosSelecionados = !this.todosSelecionados;
    }
    console.log(this.destsSelecionados)
  }

  onFilter(event: any) {
    this.destsFiltrados = new Array();
    event.filteredValue.forEach(element => {
      this.destsFiltrados.push(element);
    });
  }

  loadTipoArquivos() {
    this.httpService.get('/custom/centralweb/get-tipo-arquivo()')
      .subscribe(result => result.forEach(item =>
        this.mapTipoArquivos.push({ label: item.nome, value: item.id })
      ),
        error => this.httpService.handleError(error, () => console.log(error)),
        () => this.httpService.done);
  }

  loadTipoArquivoDestinatario() {
    this.httpService.get('/custom/centralweb/get-tipo-arquivo-destinatario(' + this.destinatario.id + ')')
      .subscribe(result => result.forEach(item =>
        this.vetTipoArquivo.push(item.tipoArquivoId)
      ),
        error => this.httpService.handleError(error, () => console.log(error)),
        () => this.httpService.done);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.empresaId = this.dblinkedSessionService.empresa.id;
      this.vetTipoArquivo = [];
      this.mapDestinatarios = [];
      this.novaRequisicao = false;
      this.alterando = false;

      this.destsSelecionados = new Array();

      this.httpService.get('/custom/centralweb/get-destinatarios(' + this.empresaId + ')')
        .subscribe(result => {
          result.forEach(item => {
            this.mapDestinatarios.push(item);
          });
        },
          error => this.httpService.handleError(error, () => console.log(error)),
          () => this.httpService.done);

    }

    setTimeout(() => {
      this.toolbarEdit();
    }, 500);
  }

  novo() {
    this.novaRequisicao = true;
    this.destinatario = new Destinatarios();
    this.destinatario.id = 0;

    //só fazer a consulta uma vez
    this.mapTipoArquivos.length === 0 ? this.loadTipoArquivos() : "";
    this.toolbarEdit();
  }

  salvarDestinatario() {
    let strTipoArquivo = this.vetTipoArquivo[0] + "";

    for (let i = 1, tam = this.vetTipoArquivo.length; i < tam; i++) {
      strTipoArquivo += ("," + this.vetTipoArquivo[i]);
    }

    const map = {
      id: this.destinatario.id,
      nome: this.destinatario.nome,
      email: this.destinatario.mail,
      empresa: this.empresaId,
      arquivos: strTipoArquivo
    }

    if (map.nome == undefined || map.email == undefined || map.empresa == undefined || strTipoArquivo.length == 0) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Mensagem: ', detail: 'não deixe campos em branco' });
      return;
    }

    if (!Util.validacaoEmail(this.destinatario.mail)) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Mensagem: ', detail: 'E-mail não é válido' });
      return;
    }

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/inserir-destinatario', map)
      .subscribe(result => {

        this.messageService.add({
          severity: 'success', key: 'messageLinnks',
          summary: 'Mensagem: ',
          detail: result.message
        });
        this.empresaChanged();
      },
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());
  }

  alterarDestinatario(event: any) {

    this.novaRequisicao = true;
    this.alterando = true;

    this.destinatario.nome = event.nome;
    this.destinatario.id = event.id;
    this.destinatario.mail = event.email; //o parametro é do map que vem do java, que vem como "email"

    this.mapTipoArquivos.length === 0 ? this.loadTipoArquivos() : "";
    this.loadTipoArquivoDestinatario();
    this.toolbarEdit();
  }

  deletarDestinatarios(dests: any[]) {
    if (confirm('Deseja realmente excluir este(s) Destinatário(s)?')) {
      let str = dests[0].id;
      for (var i = 1, tam = dests.length; i < tam; i++) {
        str += "," + dests[i].id;
      }

      const map = {
        arquivos: str
      }

      this.httpService.wait();
      this.httpService.post('/custom/centralweb/deletar-destinatarios', map)
        .subscribe(result => {

          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'Mensagem: ',
            detail: result.message
          });
          this.empresaChanged();
        },
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.toolbarEdit();
    }
  }

  private toolbarEdit() {
    const todos = this.buttons.slice();
    if (todos.length !== 0) {
      // this.toolbarService.replace('atualizar', false, //   'class-first-button', 'refresh', 'Atualizar destinatário', () => this.empresaChanged()); // this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir'); // this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('pesquisa');
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('export');
      this.toolbarService.ocultar('print');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');

      this.toolbarService.alterarStatus('voltar', !(this.novaRequisicao), 'Voltar', () => {
        if (confirm('Deseja sair sem salvar as alterações?'))
          this.empresaChanged();
      });
      this.toolbarService.alterarStatus('atualizar', this.novaRequisicao, 'Atualizar', () => this.empresaChanged());
      this.toolbarService.alterarStatus('edit', !(this.destsSelecionados.length === 1), 'Atualizar', () => this.alterarDestinatario(this.destsSelecionados[0]));
      this.toolbarService.replace('remove', !(this.destsSelecionados.length > 0), 'class-remove-button', 'delete', 'Excluir', () => this.deletarDestinatarios(this.destsSelecionados));
      this.toolbarService.alterarStatus('save', !this.novaRequisicao, 'Salvar destinatário', () => this.salvarDestinatario());
      this.toolbarService.replace('add', (this.novaRequisicao), 'class-new-button', 'NOVO', 'Adicionar um novo destinatário', () => this.novo());
    }
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}

