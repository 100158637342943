import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { ItemGrupo } from 'app/cadastro/model/item-grupo.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';

@Component({
  selector: 'app-item-grupo',
  templateUrl: './item-grupo.component.html',
  styleUrls: ['./item-grupo.component.scss'],
  providers: [CrudService]
})
export class ItemGrupoComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public itemGrupo: ItemGrupo = new ItemGrupo();

  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;

  entityFilter: string = null;

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  ngOnInit() {

    this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((itemGrupo: ItemGrupo) => {
      itemGrupo.empresa = new Empresa(this.dblinkedSessionService.empresa.id);

    });

    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
      () => { this.empresaChanged(); });

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.entityFilter = 'empresa/id eq ' + this.dblinkedSessionService.empresa.id;

      this.columns = [
        new DataTableColumn('Código', 'codigo'),
        new DataTableColumn('Descrição', 'descricao'),
        new DataTableColumn('Porcentagem Limite Desconto', 'porcentagemLimiteDesconto'),
        new DataTableColumn('Data Inicial', 'dtInicial').setTypeDate(),
        new DataTableColumn('Data Final', 'dtFinal').setTypeDate()
      ];

      this.controls = [
        new DataControl('Código', 'codigo')
          .setRequired()
          .setMaxLength(255),
          new DataControl('Descrição', 'descricao')
          .setRequired()
          .setMaxLength(255),
          new DataControl('Porcentagem Limite Desconto', 'porcentagemLimiteDesconto')
          .setTypeNumber(),
          new DataControl('Itens', 'item')
          .setTypeMultiSelect()
          .setOptions({ url: '/item?$select=id,descricao&$filter=empresa/id eq '
          + this.dblinkedSessionService.empresa.id + '&$orderby=descricao', value: 'id', label: 'descricao' }),
          new DataControl('Data Inicial', 'dtInicial')
          .setRequired()
          .setTypeDate(),
        new DataControl('Data Final', 'dtFinal')
          .setTypeDate()
      ];
    }
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }
}
