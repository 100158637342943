import { Component, OnInit } from '@angular/core';
import { TbCodMoeda } from '../model/tb-cod-moeda.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'siscoserv-moeda',
  templateUrl: './moeda.component.html',
  styleUrls: ['./moeda.component.scss'],
  providers: [CrudService]
})
export class MoedaComponent extends DataTableBasicComponent implements OnInit {
  public tbCodMoeda: TbCodMoeda = new TbCodMoeda();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('CÓDIGO', 'cod'),
      new DataTableColumn('NOME', 'nome'),
      new DataTableColumn('PAÍS', 'paisOrig'),
      new DataTableColumn('CÓDIGO*', 'valueAlias')
    ];

    this.controls = [
      new DataControl('Código', 'cod').setReadOnly(),
      new DataControl('Nome', 'nome').setReadOnly(),
      new DataControl('País', 'paisOrig').setReadOnly(),
      new DataControl('Código*', 'valueAlias')
    ];
  }
}
