import { Component, OnInit } from '@angular/core';
import { CadEcd } from '../model/cad-ecd.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'app-cad-ecd',
  templateUrl: './cad-ecd.component.html',
  styleUrls: ['./cad-ecd.component.scss'],
  providers: [CrudService]
})
export class CadEcdComponent extends DataTableBasicComponent implements OnInit {
  public cadEcd: CadEcd = new CadEcd();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
    new DataTableColumn('Ind Sit Esp', 'indSitEsp.descricao'),
    new DataTableColumn('Ind Nire', 'indNire.descricao'),
    new DataTableColumn('Ind Sit Esp Per', 'indSitIniPer.descricao'),
    new DataTableColumn('Ind Fin Esc', 'indFinEsc.descricao'),
    new DataTableColumn('Cod Hash', 'codHashSub'),
    new DataTableColumn('Ind Grande Porte', 'indGrandePorte.descricao'),
    new DataTableColumn('Tipo Ecd', 'tipoEcd.descricao'),
    new DataTableColumn('Cod Scp', 'codScp'),
    new DataTableColumn('Empresa', 'empresa.razaoSocial'),
    new DataTableColumn('Data Ini', 'dtIni').setTypeDate(),
    new DataTableColumn('Data Fim', 'dtFin').setTypeDate()
    ];

    this.controls = [
    new DataControl('Ind Sit Esp', 'indSitEsp')
        .setTypeSelect()
        .setOptions({ url: '/indsitesp', value: 'id', label: 'descricao' }),
    new DataControl('Ind Nire', 'indNire')
        .setTypeSelect()
        .setOptions({ url: '/indnire', value: 'id', label: 'descricao' }),
    new DataControl('Ind Sit Esp Per', 'indSitIniPer')
        .setTypeSelect()
        .setOptions({ url: '/indsitiniper', value: 'id', label: 'descricao' }),
    new DataControl('Ind Fin Esc', 'indFinEsc')
        .setTypeSelect()
        .setOptions({ url: '/indfinesc', value: 'id', label: 'descricao' }),
    new DataControl('Cod Hash', 'codHashSub')
        .setRequired()
        .setMinLength(2)
        .setMaxLength(255),
    new DataControl('Ind Grande Porte', 'indGrandePorte')
        .setTypeSelect()
        .setOptions({ url: '/indgrandeporte', value: 'id', label: 'descricao' }),
    new DataControl('Tipo Ecd', 'tipoEcd')
        .setTypeSelect()
        .setOptions({ url: '/tipoecd', value: 'id', label: 'descricao' }),
    new DataControl('Cod Scp', 'codScp')
        .setRequired()
        .setMinLength(2)
        .setMaxLength(255),
    new DataControl('Empresa', 'empresa')
        .setTypeSelect()
        .setOptions({ url: '/empresa', value: 'id', label: 'razaoSocial' }),
    new DataControl('Data Ini', 'dtIni')
        .setTypeDate()
        .setRequired(),
    new DataControl('Data Fim', 'dtFin')
        .setTypeDate()
        .setRequired()

    ];
  }
}
