import { SelectItem } from './../../shared/common/select-item.interface';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Feriados } from '../../util/model/feriados.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
  selector: 'app-feriados',
  templateUrl: './feriados.component.html',
  styleUrls: ['./feriados.component.scss'],
  providers: [CrudService]
})
export class FeriadosComponent extends DataTableBasicComponent implements OnInit {
  public feriados: Feriados = new Feriados();
  public registroFeriados: Array<Feriados> = new Array();

  ids: Array<any> = new Array();
  select: Array<any> = new Array();

  //Boolean
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;
  checked: boolean = false;

  //Any
  data: any;
  tipo: any;
  descricao: any;
  empresas: any;

  //Select
  opcoesTipo: SelectItem[];
  opcoesEmpresa: SelectItem[];

  msgs: Message[];

  title = 'FERIADOS';
  subtitle = "FERIADOS";
  
  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeViewSubscription: Subscription;
  private beforeEditSubscription: Subscription;

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private utilService: UtilService,
    private configService: ConfigService,
    ) {
    super(crudService);
  }

  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirRegistro() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'green', onClick: () => this.criarNovo() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'green', onClick: () => this.salvarNovoRegistro() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'green', onClick: () => this.salvarAlteracaoRegistro() },
  ]


  ngOnInit() {
    // TODO: REVIEW (IMPORTANT!)
    this.toolbarService.hidden = true
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,() => {
      if(this.dblinkedSessionService.hasEmpresaSelected){
        this.carregar()
      }
    })
  }

  carregar() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.title = 'FERIADOS';
    this.subtitle = 'FERIADO';

    this.ids = new Array();
    this.select = new Array()
    this.limpaCamposForm();
    this.loadRegistros();
    this.loadEmpresa();

    this.novaConta = false;
    this.editarConta = false;

    this.toolbarMain.setVisible('btn1', false)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', true)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', false)
  }

  criarNovo() {
    this.title = 'CADASTRAR FERIADO';
    this.loadTipo();
    this.loadEmpresa();
    this.novaConta = true;

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', true)
    this.toolbarMain.setVisible('btn6', false)
  }

  loadTipo(){
    this.opcoesTipo = [];
    this.opcoesTipo.push({label: 'Selecione', value: null });
    this.httpService.get(`tipoferiado?$select=id,tipo&$orderby=tipo`)
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesTipo.push({
            label: element.tipo,
            value: element.id
          });
        });
      }, error => this.httpService.handleError(error, () => this.httpService.done()));

  }

  loadEmpresa() {
    this.httpService.get(`empresa?$filter=id eq ${this.dblinkedSessionService.empresa.id} &$select=id,razaoSocial&$orderby=razaoSocial`)
    .subscribe(
      (retorno) => {
          this.opcoesEmpresa = new Array();
          this.opcoesEmpresa.push({label: 'Selecione', value: 0 });
          retorno.value.forEach(item => {
            this.opcoesEmpresa.push({
              label: item.razaoSocial,
              value: item.id,
            });
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadRegistros() {
    this.httpService.wait()
    this.httpService
      .get(`/feriados?$select=data,tipoFeriado/tipo,descricao&$count=true&$top=50&$filter=empresa/id eq ${this.dblinkedSessionService.empresa.id} or%20tipoFeriado/id%20eq%201&$orderby=data`)
      .subscribe(
        (retorno) => {
          this.registroFeriados = new Array();
          retorno.value.forEach(item => {
            this.registroFeriados.push({
              ...item,
              dtInicialFormatoBrasil: this.converteDataParaFormatoBrasileiro(
                this.adicionaUmDia(item.data)
              ),
            });
          })
          this.httpService.done()
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'EDITAR FERIADO';

    this.loadTipo();
    this.loadEmpresa();
    this.limpaCamposForm();

    this.editarConta = true;

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', true)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', true)

    this.httpService.wait()
    this.httpService.get(`/feriados(${this.ids[0]})?$select=data,descricao,tipoFeriado/id,tipoFeriado/tipo,empresa/id,empresa/razaoSocial&$count=true`)
    .subscribe(plano => {
      this.tipo = plano.value.tipoFeriado.id;
      this.data = this.adicionaUmDia(plano.value.data);
      this.data = this.converteDataParaFormatoBrasileiro(this.data);
      this.descricao = plano.value.descricao;
      this.empresas = plano.value.empresa.id;
      
    },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
     data: this.data,
     descricao: this.descricao,
     tipoFeriado: {id: this.tipo},
     empresa: {id: this.empresas},
    };
    
   
    this.httpService.wait();
    this.httpService.post('/feriados', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  filtrarVazio(listasEnviar){
    if(this.empresas != null || this.empresas != ''){
      delete listasEnviar['empresa']
    }
  }

  salvarAlteracaoRegistro() {
    const listasEnviar = {
      id: this.ids[0],
      data: this.converteDataParaFormatoAmericano(this.data),
      descricao: this.descricao,
      tipoFeriado: {id: this.tipo},
      empresa: {id: this.empresas},
    };
    this.filtrarVazio(listasEnviar);
   

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/feriados', key, listasEnviar, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do projeto!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar o registro selecionado? ')) {
      const listasEnviar = {
      };
      this.httpService.wait();
      this.httpService.delete('/feriados', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Projeto excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());
    }
  }

  onRowSelect(event: any) {
    this.ids = new Array();
    this.ids.push(event.data.id);

    const choosen = this.select[this.select.length - 1];
    this.select.length = 0;
    this.select.push(choosen);
    this.change()
  }

  onRowUnselect(event: any) {
    this.select = new Array();
    this.ids = new Array();
    this.change();
  }

  change(){
    const selectionSize = this.select.length;
    this.toolbarMain.setVisible('btn2', selectionSize >=1); 
    this.toolbarMain.setVisible('btn3', selectionSize >=1); 
  }

  validacao(): boolean {
    if ((this.data === null || this.data === undefined)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data é Obrigatório' });
      return true;
    }
    if ((this.descricao === null || this.descricao === undefined) || this.descricao.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição é Obrigatória' });
      return true;
    } 
    if ((this.tipo === null || this.tipo === undefined) || this.tipo.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Tipo Feriado é Obrigatório' });
      return true;
    } 
    if ((this.empresas === null || this.empresas === undefined) || this.empresas.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Empresa é Obrigatória' });
      return true;
    } 
    return false;
  }

  private converteDataParaFormatoBrasileiro(milisegundos: string) {
    const d = new Date(milisegundos);

    return this.utilService.dateToStringDate(d);
  }

  private adicionaUmDia = (milisegundos) => milisegundos + 86400000;

  private converteDataParaFormatoAmericano(data) {
    let formatoBrasileiro;

    if (typeof data === 'object') {//GMT...
      const d = new Date(data);
      formatoBrasileiro = this.utilService.dateToStringDate(d);
    } else {//dd-mm-YYYY
      formatoBrasileiro = data;
    }

    const [dia, mes, ano] = formatoBrasileiro.split('/');

    return `${ano}-${mes}-${dia}`;
  }

  private limpaCamposForm() {
    this.data = null
    this.descricao = '';
    this.tipo = '';
    this.empresas = '';
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }
  get loading(): boolean {
    return this.httpService.loading;
  }
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
