// TODO: Create http interceptor

import { HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'app/shared/services/config.service';

export class JsonHeader {
  private headers: HttpHeaders = new HttpHeaders();

  requestOptions: any = {
    headers: this.headers,
    body: ''
  };

  constructor(private configService: ConfigService) {
  }

  options(application?: string, body?: FormData | any, responseType?: string): any {
    let headers: HttpHeaders = this.headers;

    if (body instanceof FormData) {
      headers = headers.delete('Content-Type');
      headers = headers.delete('Accept');
    } else {
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('Accept', 'application/json');
    }

    headers = headers.set('Application', application ? application : this.configService.defaultApplication);

    const options: any = {
      headers: headers,
      body: body !== undefined && body !== null ? body : ''
    };

    if (responseType) {
      options['responseType'] = responseType;
    }

    return options;
  }

  set authorizationToken(token: string) {
    let headers: HttpHeaders = this.headers;

    if (token !== undefined && token !== null) {
      headers = headers.set('authorization', 'Bearer ' + token);
    } else {
      headers = headers.delete('authorization');
    }

    this.headers = headers;
    this.requestOptions.headers = headers;
  }
}
