import { Obrigacao } from 'app/centralweb/model/obrigacao.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { TipoDocumento } from 'app/util/model/tipo-documento.model';
import { Justificativas } from 'app/util/model/justificativas.model';
import { EnvioDestinatarios } from 'app/centralweb/model/envio-destinatarios.model';

export class Envio {
  id: number;
  obrigacao: Obrigacao;
  dtEnvio: Date;
  retificado: boolean;
  usuario: Usuario;
  dtFatoGerador: Date;
  vlPrincipal: Number;
  vlMulta: Number;
  vlJuros: Number;
  vlSaldoRecolher: Number;
  vlSaldoCredor: Number;
  vlImposto1: Number;
  vlImposto2: Number;
  vlImposto3: Number;
  vlImposto4: Number;
  tipoDocumento: TipoDocumento;
  justificativa: Justificativas;
  detalhesJustificativa: string;
  mensagem: string;
  outrosDestinatarios: string;
  desconsiderar: false;

  envioDestinatariosList: Array<EnvioDestinatarios>;

  constructor(envio?: Envio | number) {
    if (envio !== undefined && envio !== null) {
      if (typeof envio === 'number') {
        this.id = envio;
      } else {
        this.id = envio.id;
        this.obrigacao = envio.obrigacao;
        this.dtEnvio = envio.dtEnvio;
        this.retificado = envio.retificado;
        this.usuario = envio.usuario;
        this.dtFatoGerador = envio.dtFatoGerador;
        this.vlPrincipal = envio.vlPrincipal;
        this.vlMulta = envio.vlMulta;
        this.vlJuros = envio.vlJuros;
        this.vlSaldoRecolher = envio.vlSaldoRecolher;
        this.vlSaldoCredor = envio.vlSaldoCredor;
        this.vlImposto1 = this.vlImposto1;
        this.vlImposto2 = this.vlImposto2;
        this.vlImposto3 = this.vlImposto3;
        this.vlImposto4 = this.vlImposto4;
        this.tipoDocumento = this.tipoDocumento;
        this.justificativa = this.justificativa;
        this.detalhesJustificativa = this.detalhesJustificativa;
        this.mensagem = this.mensagem;
        this.outrosDestinatarios = this.outrosDestinatarios;
        this.envioDestinatariosList = this.envioDestinatariosList;
        this.desconsiderar = this.desconsiderar;
      }
    }
  }
}
