import { Component, OnInit } from '@angular/core';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { CadAglutRelac } from 'app/contabil/model/cad-aglut-relac.model';

@Component({
  selector: 'app-cad-aglut-relac',
  templateUrl: './cad-aglut-relac.component.html',
  styleUrls: ['./cad-aglut-relac.component.scss'],
  providers: [CrudService]
})
export class CadAglutRelacComponent extends DataTableBasicComponent implements OnInit {
  public cadAglutRelac: CadAglutRelac = new CadAglutRelac();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('Cod Aglut', 'cadAglut.cod'),
      new DataTableColumn('Conta Contábil', 'planoContas.codCta'),
      new DataTableColumn('Centro de Custo', 'cadCcus.codCcus')
    ];

    this.controls = [
      new DataControl('Cod Aglut', 'cadAglut')
          .setTypeSelect()
          .setOptions({ url: '/cadaglut', value: 'id', label: 'cod' }),
      new DataControl('Conta Contábil', 'planoContas')
          .setTypeSelect()
          .setOptions({ url: '/custom/ecd/planocontas', value: 'id', label: 'codCta' }),
      new DataControl('Centro de Custo', 'cadCcus')
          .setTypeSelect()
          .setOptions({ url: '/cadccus?$filter=ativo eq true&$orderby=codCcus', value: 'id', label: 'codCcus' })
    ];
  }
}
