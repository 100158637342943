import { AuthUser } from 'app/proof/model/auth-user.model';
import { TodoCard } from './todo-card.model';
import { TodoCardChecklistAtividades } from './todo-card-checklist-atividades.model';

export class TodoCardChecklist {
  id: number;
  todoCard: TodoCard;
  titulo: string;
  usuario: AuthUser;
  checklistAtividades: Array<TodoCardChecklistAtividades>;
  // apenas para exibir a porcentagem do andamento
  porcentagem: any;

  constructor(todoCardChecklist?: TodoCardChecklist | number) {
    if (todoCardChecklist !== undefined && todoCardChecklist !== null) {
      if (typeof todoCardChecklist === 'number') {
        this.id = todoCardChecklist;
      } else {
        this.id = todoCardChecklist.id;
        this.todoCard = todoCardChecklist.todoCard;
        this.titulo = todoCardChecklist.titulo;
        this.usuario = todoCardChecklist.usuario;
        this.checklistAtividades = todoCardChecklist.checklistAtividades;
        this.porcentagem = todoCardChecklist.porcentagem;
      }
    }
  }
}
