import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Config } from '../../app.config';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TbIrpjM300 } from '../../ecf/model/tb-irpj-m300.model';
import { TbIrpjM300Relac } from '../../ecf/model/tb-irpj-m300-relac.model';
import { PlanoContas } from '../../contabil/model/plano-contas.model';
import { PlanoContasService } from 'app/shared/services/plano-contas.service';
import { TitleService } from 'app/shared/services/title.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
    selector: 'app-teste',
    templateUrl: './plano-contas-ref-relac-irpj.component.html',
    styleUrls: ['./plano-contas-ref-relac-irpj.component.scss'],
})

export class PlanoContasRefRelacIrpjComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  tbIrpjM300List: Array<TbIrpjM300> = new Array();

  tbIrpjM300RelacList: Array<TbIrpjM300Relac> = new Array();
  tbIrpjM300Relac: TbIrpjM300Relac = new TbIrpjM300Relac();

  planoContasList: Array<PlanoContas> = new Array();
  planoContasSelectedList: Array<PlanoContas> = new Array();

  opcoesCentroCusto: Array<SelectItem> = new Array();
  cadCcusId: number;
  titlePrincipal: string;
  subtitle: string;

  contadorLinha: number = 0;
  contadorLinhaSelected: number = 0;
  contadorLinhaMapeadas: number = 0;

  private planoContasService: PlanoContasService = new PlanoContasService('irpj');

  constructor(private configService: ConfigService,
              private httpService: HttpService,
              private toolbarService: ToolbarService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private titleService: TitleService) {
    this.titleService.title = 'Mapeamento IRPJ: Adição/Exclusão (M300)';
  }

  ngOnInit() {
    this.titlePrincipal = ' REFERENCIAR IRPJ';
    this.subtitle = 'RELACIONE O PLANO DE CONTAS DA EMPRESA COM AS CONTAS DE ADIÇÃO E EXCLUSÃO DE IRPJ';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    // Promise.resolve(null).then(() => this.toolbarRefresh());
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
                                                                 () => { this.periodChanged(); });

    this.contadorLinha = 0;
    this.contadorLinhaSelected = 0;
    this.contadorLinhaMapeadas = 0;
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  periodChanged() {
    if (!this.dblinkedSessionService.hasPeriodoSelected || !this.dblinkedSessionService.hasEmpresaSelected) {
      return;
    }

    this.back();

    this.opcoesCentroCusto = new Array();
    this.opcoesCentroCusto.push({ label: '(Nenhum)', value: null });
    this.httpService.wait();
    this.httpService.get('/cadccus?$select=id,codCcus,descrCcus' +
                                 '&$filter=ativo eq true and empresa/id eq ' + this.dblinkedSessionService.empresa.id +
                                         ' and period(dtInicial,dtFinal) eq ' + this.year +
                                 '&$orderby=codCcus')
                    .subscribe(cadCcusList => {
                                 cadCcusList.value.forEach(cadCcus => {
                                   this.opcoesCentroCusto.push({ label: cadCcus.codCcus + ' - ' + cadCcus.descrCcus,
                                                                 value: cadCcus.id,
                                                                 data: cadCcus });
                                 });
                               },
                               error => this.httpService.handleError(error, () => this.periodChanged()),
                               () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/custom/ecd/tb-irpj-m300', [this.year, this.dblinkedSessionService.empresa.id])
                  .subscribe(tbIrpjM300List => {
                               this.tbIrpjM300List = tbIrpjM300List;

                               this.setTbIrpjM300ListUsed();

                               if(this.tbIrpjM300List !== null || this.tbIrpjM300List !== undefined){
                                this.contadorLinha = this.tbIrpjM300List.length;
                                }
                                  else{
                                      this.contadorLinha = 0;
                                }
                             },
                             error => this.httpService.handleError(error, () => this.periodChanged()),
                             () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/custom/ecd/planocontas', [this.dblinkedSessionService.empresa.id , this.year])
                    .subscribe(planoContas => {
                      this.planoContasList = planoContas.value;
                      if(this.planoContasList !== null || this.planoContasList !== undefined){
                            this.contadorLinha = this.planoContasList.length;
                        }
                          else{
                              this.contadorLinha = 0;
                        }
                      } ,
                            error => this.httpService.handleError(error, () => this.periodChanged()),
                            () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/tbirpjm300relac?$select=cadCcus/id,tbIrpjM300Adicao/cod,tbIrpjM300Adicao/descricao,' +
                                                  'tbIrpjM300Exclusao/cod,tbIrpjM300Exclusao/descricao,' +
                                                  'planoContas/codCta,planoContas/descrCta,planoContas/indCta,planoContas/nivel' +
                                          '&$filter=planoContas/ativo eq true' +
                                                  ' and planoContas/empresa/id eq ' + this.dblinkedSessionService.empresa.id +
                                                  ' and period(dtInicial,dtFinal) eq ' + this.year +
                                          '&$orderby=tbIrpjM300Adicao/cod')
                    .subscribe(tbIrpjM300RelacList => {
                                 this.tbIrpjM300RelacList = tbIrpjM300RelacList.value;

                                 this.setTbIrpjM300ListUsed();
                               },
                               error => this.httpService.handleError(error, () => this.periodChanged()),
                               () => this.httpService.done());
  }

  private setTbIrpjM300ListUsed() {
    this.tbIrpjM300List.forEach(tbIrpjM300 => {
      tbIrpjM300.used = false;
    });

    this.tbIrpjM300RelacList.forEach(tbIrpjM300Relac => {
      this.tbIrpjM300List.forEach(tbIrpjM300 => {
        if (tbIrpjM300.id === tbIrpjM300Relac.tbIrpjM300Adicao.id ||
            tbIrpjM300.id === tbIrpjM300Relac.tbIrpjM300Exclusao.id) {
          tbIrpjM300.used = true;
        }
      });
    });
  }

  selectTbIrpjM300(event: any) {
    if (event.data.tipo === 'E') {
      switch (event.data.tipoLanc) {
        case 'A':
          this.tbIrpjM300Relac.tbIrpjM300Adicao = this.clone(event.data);
          break;
        case 'E':
          this.tbIrpjM300Relac.tbIrpjM300Exclusao = this.clone(event.data);
          break;
      }
    }

    this.cadCcusId = null;

    this.loadTbIrpjM300();
  }

  editTbIrpjM300Relac(event: any) {
    this.tbIrpjM300Relac = this.clone(event.data);

    this.cadCcusId = this.tbIrpjM300Relac.cadCcus ? this.tbIrpjM300Relac.cadCcus.id : null;

    this.loadTbIrpjM300();
  }

  loadTbIrpjM300() {
    const tbIrpjM300Relac = this.tbIrpjM300Relac;

    if (tbIrpjM300Relac.tbIrpjM300Adicao && tbIrpjM300Relac.tbIrpjM300Adicao.id &&
      tbIrpjM300Relac.tbIrpjM300Exclusao && tbIrpjM300Relac.tbIrpjM300Exclusao.id) {
      this.httpService.wait();
      this.httpService.get('/planocontas?$select=codCta,descrCta,indCta,nivel,tbIrpjM300Relac/cadCcus/id,' +
                                                'tbIrpjM300Relac/tbIrpjM300Adicao/id,tbIrpjM300Relac/tbIrpjM300Exclusao/id' +
                                       '&$filter=ativo eq true' +
                                               ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id +
                                               ' and tbIrpjM300Relac/tbIrpjM300Adicao/id eq ' + tbIrpjM300Relac.tbIrpjM300Adicao.id +
                                               ' and tbIrpjM300Relac/tbIrpjM300Exclusao/id eq ' + tbIrpjM300Relac.tbIrpjM300Exclusao.id +
                                               ' and period(dtInicial,dtFinal) eq ' + this.year)
                      .subscribe(planoContas => {
                                   this.planoContasSelectedList = planoContas.value.slice();

                                   if(this.planoContasSelectedList !== null || this.planoContasSelectedList !== undefined){
                                    this.contadorLinhaSelected = this.planoContasSelectedList.length;
                                    }
                                      else{
                                          this.contadorLinhaSelected = 0;
                                    }


                                   this.planoContasList.forEach(p => p.selected = false);
                                 },
                                 error => this.httpService.handleError(error, () => this.loadTbIrpjM300()),
                                 () => this.httpService.done());
    }
    // this.toolbarRefresh();
  }

  back() {
    this.tbIrpjM300Relac = new TbIrpjM300Relac();
    this.planoContasSelectedList = new Array();
  }

  get tbIrpjM300RelacListDistinct(): Array<TbIrpjM300Relac> {
    const tbIrpjM300RelacList: Array<TbIrpjM300Relac> = new Array();

    this.tbIrpjM300RelacList.forEach(tbIrpjM300Relac => {
      if (!tbIrpjM300RelacList.find(distinct => distinct.tbIrpjM300Adicao &&
                                                distinct.tbIrpjM300Adicao.id === tbIrpjM300Relac.tbIrpjM300Adicao.id &&
                                                distinct.tbIrpjM300Exclusao &&
                                                distinct.tbIrpjM300Exclusao.id === tbIrpjM300Relac.tbIrpjM300Exclusao.id)) {
        tbIrpjM300RelacList.push(tbIrpjM300Relac);
      }
    });

    if(this.tbIrpjM300RelacList !== null || this.tbIrpjM300RelacList !== undefined){
      this.contadorLinhaMapeadas = this.tbIrpjM300RelacList.length;
    }
      else{
          this.contadorLinhaMapeadas = 0;
    }

    return tbIrpjM300RelacList;
  }

  get tbIrpjM300RelacSelection(): any {
    return this.selection(this.tbIrpjM300RelacListDistinct, this.tbIrpjM300Relac);
  }

  private selection(list: Array<any>, item: any): any {
    return item && item.id ? list.find(r => r.id === item.id) : null;
  }

  check(planoContas: PlanoContas, selected: boolean) {
    this.planoContasService.check(this.planoContasList, planoContas, selected);
  }

  private dataTbIrpjM300Relac(tbIrpjM300Relac: TbIrpjM300Relac, planoContas?: PlanoContas) {
    const data = { id: tbIrpjM300Relac.id,
                   planoContas: { id: planoContas ? planoContas.id : tbIrpjM300Relac.planoContas.id },
                   tbIrpjM300Adicao: { id: tbIrpjM300Relac.tbIrpjM300Adicao.id },
                   tbIrpjM300Exclusao: { id: tbIrpjM300Relac.tbIrpjM300Exclusao.id },
                   cadCcus: undefined };
    if (this.cadCcusId) {
      data.cadCcus = { id: this.cadCcusId };
    }

    return data;
  }

  add() {
    const newItems = this.planoContasService.toAdd(this.planoContasList, this.planoContasSelectedList);
    const planoContas = newItems[0];

    const tbIrpjM300Relac: TbIrpjM300Relac | any = this.dataTbIrpjM300Relac(this.tbIrpjM300Relac, planoContas);

    this.httpService.wait();
    this.httpService.post('/custom/ecd/tbirpjm300relac(' + this.year + ')', tbIrpjM300Relac)
                    .subscribe(id => {
                                  tbIrpjM300Relac.id = id;
                                  tbIrpjM300Relac.tbIrpjM300Adicao.cod = this.tbIrpjM300Relac.tbIrpjM300Adicao.cod;
                                  tbIrpjM300Relac.tbIrpjM300Adicao.descricao = this.tbIrpjM300Relac.tbIrpjM300Adicao.descricao;
                                  tbIrpjM300Relac.tbIrpjM300Exclusao.cod = this.tbIrpjM300Relac.tbIrpjM300Exclusao.cod;
                                  tbIrpjM300Relac.tbIrpjM300Exclusao.descricao = this.tbIrpjM300Relac.tbIrpjM300Exclusao.descricao;

                                  this.tbIrpjM300RelacList.push(tbIrpjM300Relac);

                                  this.planoContasList.find(p => p.id === planoContas.id).selected = false;

                                  if (newItems.length > 1) {
                                    this.add();
                                  } else {
                                    this.loadTbIrpjM300();
                                    this.setTbIrpjM300ListUsed();
                                  }
                                },
                                error => this.httpService.handleError(error, () => this.add()),
                                () => this.httpService.done());
  }

  get addDisabled(): boolean {
    return !this.planoContasSelectedList ||
           this.planoContasService.toAdd(this.planoContasList, this.planoContasSelectedList).length < 1;
  }

  update() {
    if (this.planoContasSelectedList && this.planoContasSelectedList.length > 0) {
      const planoContas = this.planoContasSelectedList.find(p => (this.cadCcusId && !p.tbIrpjM300Relac.cadCcus) ||
                                                                  this.cadCcusId !== p.tbIrpjM300Relac.cadCcus.id);

      if (planoContas) {
        const tbIrpjM300Relac: TbIrpjM300Relac | any = this.dataTbIrpjM300Relac(planoContas.tbIrpjM300Relac, planoContas);

        tbIrpjM300Relac.cadCcus = null;
        if (this.cadCcusId) {
          tbIrpjM300Relac.cadCcus = { id: this.cadCcusId };
        }

        this.httpService.wait();
        this.httpService.patch('/tbirpjm300relac', tbIrpjM300Relac.id, tbIrpjM300Relac)
                        .subscribe(() => {
                                     planoContas.tbIrpjM300Relac.cadCcus = this.clone(tbIrpjM300Relac.cadCcus);

                                     this.tbIrpjM300RelacList.find(r => r.id === planoContas.tbIrpjM300Relac.id)
                                                             .cadCcus = this.clone(tbIrpjM300Relac.cadCcus);

                                     this.update();
                                   },
                                   error => this.httpService.handleError(error, () => this.update()),
                                   () => this.httpService.done());
      }
    }
  }

  remove(tbIrpjM300Relac?: TbIrpjM300Relac) {
    const ids: Array<number> = new Array();

    if (tbIrpjM300Relac) {
      if (!confirm('Deseja excluir o relacionamento selecionado?')) {
        return;
      }

      this.tbIrpjM300RelacList.filter(r => r.tbIrpjM300Adicao.id === tbIrpjM300Relac.tbIrpjM300Adicao.id &&
                                           r.tbIrpjM300Exclusao.id === tbIrpjM300Relac.tbIrpjM300Exclusao.id)
                              .forEach(r => ids.push(r.id));
    } else {
      const oldItems = this.clone(this.planoContasService.toRemove(this.planoContasList, this.planoContasSelectedList));
      oldItems.forEach(oldItem => {
        ids.push(this.tbIrpjM300RelacList.find(r => r.planoContas.id === oldItem.id).id);
      });
    }

    this.httpService.wait();
    this.httpService.post('/custom/ecd/delete-tbirpjm300relac(' + this.year + ')', ids)
                    .subscribe(() => {
                                 this.tbIrpjM300RelacList = this.tbIrpjM300RelacList.filter(r => ids.indexOf(r.id) === -1);

                                 this.planoContasList.filter(p => ids.indexOf(p.id) > -1).forEach(p => {
                                   p.selected = false;
                                 });

                                 this.loadTbIrpjM300();

                                 this.setTbIrpjM300ListUsed();
                               },
                               error => this.httpService.handleError(error, () => this.remove()),
                               () => this.httpService.done());
  }

  get removeDisabled(): boolean {
    return !this.planoContasSelectedList ||
           this.planoContasService.toRemove(this.planoContasList, this.planoContasSelectedList).length < 1;
  }

  planoContasDisabled(planoContas: PlanoContas): boolean {
    return this.planoContasService.disabled(this.planoContasSelectedList, planoContas) ||
           (planoContas && planoContas.tbIrpjM300Relac && planoContas.tbIrpjM300Relac.id !== undefined);
  }

  planoContasListStyleClass(planoContas: PlanoContas): string {
    let classes = '';

    if (planoContas.indCta === 'S') {
      classes += 'bold ';
    }

    return classes.trim();
  }

  tbIrpjM300ListStyleClass(tbIrpjM300: TbIrpjM300): string {
    let classes = '';

    if (tbIrpjM300.tipo !== 'E') {
      classes += 'bold locked ';
    }

    if (tbIrpjM300.used) {
      classes += 'line-through locked ';
    }

    return classes.trim();
  }

  private clone(object: any) {
    if (object === undefined || object === null) {
      return object;
    }

    return JSON.parse(JSON.stringify(object));
  }

  /*private toolbarRefresh() {
    /*this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');8/
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }*/

  get title(): string {
    return this.titleService.title;
  }

  get year(): number {
    return this.dblinkedSessionService.periodo.year.value;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  onFiltertbIrpjM300List(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }

  onFilterplanoContasList(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }

  onFilterplanoContasSelectedList(event: any) {
    this.contadorLinhaSelected = event.filteredValue.length;
  }

  onFiltertbIrpjM300RelacListDistinct(event: any) {
    this.contadorLinhaMapeadas = event.filteredValue.length;
  }
}
