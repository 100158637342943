import { Component, OnInit, OnDestroy, ViewChild, ElementRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../app.config';
import { Period } from '../../shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { TimezonePipe } from '../../shared/pipes/timezone.pipe';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { TipoDocumento } from 'app/util/model/tipo-documento.model';
import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { Justificativas } from 'app/util/model/justificativas.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { Departamento } from 'app/util/model/departamento.model';
import { Obrigacao } from 'app/centralweb/model/obrigacao.model';
import { Envio } from 'app/centralweb/model/envio.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { EnvioDestinatarios } from 'app/centralweb/model/envio-destinatarios.model';
import { Relatorios } from 'app/centralweb/model/relatorios.model';
import { RelatorioAgendado } from 'app/centralweb/model/relatorio-agendado.model';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractValueAccessor } from 'app/shared/common/abstract-value-acessor';

@Component({
  selector: 'app-relatorios-agenda',
  templateUrl: './relatorio-agenda.component.html',
  styleUrls:  ['./relatorio-agenda.component.scss'],
  providers: [CrudService, { provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RelatorioAgendaComponent),
    multi: true }]
})
export class RelatorioAgendaComponent implements OnInit, OnDestroy  {



  private sessionSubscription: Subscription;
  relatorioSelecionado: Relatorios;
  usuarioSelecionado: Usuario;
  relatorioAgendado: RelatorioAgendado;

  relatorios: Array<Relatorios> = new Array();
  dataIni: Date;
  dataFin: Date;

  opcoesRelatorio: Array<SelectItem> = new Array();
  opcoesUsuario: Array<SelectItem> = new Array();

  exbirModalRecorrencia: boolean;


  dom: boolean;
  seg: boolean;
  ter: boolean;
  qua: boolean;
  qui: boolean;
  sex: boolean;
  sab: boolean;
  antecipaDiaNaoUtil: boolean;
  diaUtil: boolean;
  diaUtilSabado: boolean;
  dtIni: Date;
  qtdeOcorrencia: number;
  aCada: number;
  diaMes: number;
  dtFin: Date;
  qtdes: any[];
  dias: any[];
  // recorrencias: SelectItem[];
  recorrencias: Array<Recorrencia>;

  empresas: Array<Empresa> = new Array();
  msgs: Message[];

  label: string;
  options: any = new Array();
  empresasSelecionadas: any = new Array();
  valueChange: EventEmitter<{}> = new EventEmitter();
  required = false;
  readonly = false;
  disabled = false;
  private _value: any = null;
  private _value1: any = null;
  title: string;
  subtitle: string;

  constructor(private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent,
    private messageService: MessageService) {
  }

  ngOnInit() {
    this.title = 'RELATÓRIOS AGENDADOS';
    this.subtitle = 'AGENDAR RELATÓRIO PARA SER ENVIADO';
    // this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    // this.toolbarRefresh();
    // this.recorrencias = [];
    this.recorrencias = new Array();
    this.relatorios = new Array<Relatorios>();
    this.titleService.title = 'Relatórios';
    this.qtdes = [];
    this.dias = [];
    this.dataIni = new Date();
    this.dataFin = new Date();
    this.relatorioAgendado = new RelatorioAgendado();
    this.relatorioAgendado.recorrencia = new Recorrencia();
    this.empresas = new Array<Empresa>();

    this.exbirModalRecorrencia = false;

    this.qtdeOcorrencia = 0.00;
    this.aCada = 1;
    this.diaMes = 1;

    this.dom = false;
    this.seg = false;
    this.ter = false;
    this.qua = false;
    this.qui = false;
    this.sex = false;
    this.sab = false;
    this.antecipaDiaNaoUtil = false;
    this.diaUtil = false;
    this.diaUtilSabado = false;

    this.dias.push({ label: 1, value: 1 });
    this.dias.push({ label: 2, value: 2 });
    this.dias.push({ label: 3, value: 3 });
    this.dias.push({ label: 4, value: 4 });
    this.dias.push({ label: 5, value: 5 });
    this.dias.push({ label: 6, value: 6 });
    this.dias.push({ label: 7, value: 7 });
    this.dias.push({ label: 8, value: 8 });
    this.dias.push({ label: 9, value: 9 });
    this.dias.push({ label: 10, value: 10 });
    this.dias.push({ label: 11, value: 11 });
    this.dias.push({ label: 12, value: 12 });
    this.dias.push({ label: 13, value: 13 });
    this.dias.push({ label: 14, value: 14 });
    this.dias.push({ label: 15, value: 15 });
    this.dias.push({ label: 16, value: 16 });
    this.dias.push({ label: 17, value: 17 });
    this.dias.push({ label: 18, value: 18 });
    this.dias.push({ label: 19, value: 19 });
    this.dias.push({ label: 20, value: 20 });
    this.dias.push({ label: 21, value: 21 });
    this.dias.push({ label: 22, value: 22 });
    this.dias.push({ label: 23, value: 23 });
    this.dias.push({ label: 24, value: 24 });
    this.dias.push({ label: 25, value: 25 });
    this.dias.push({ label: 26, value: 26 });
    this.dias.push({ label: 27, value: 27 });
    this.dias.push({ label: 28, value: 28 });
    this.dias.push({ label: 29, value: 29 });
    this.dias.push({ label: 30, value: 30 });
    this.dias.push({ label: 31, value: 31 });

    this.qtdes.push({ label: 1, value: 1 });
    this.qtdes.push({ label: 2, value: 2 });
    this.qtdes.push({ label: 3, value: 3 });
    this.qtdes.push({ label: 4, value: 4 });
    this.qtdes.push({ label: 5, value: 5 });
    this.qtdes.push({ label: 6, value: 6 });
    this.qtdes.push({ label: 7, value: 7 });
    this.qtdes.push({ label: 8, value: 8 });
    this.qtdes.push({ label: 9, value: 9 });
    this.qtdes.push({ label: 10, value: 10 });
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.httpService.wait();
    this.httpService.get('/recorrencia?$select=id,cod,descricao&$orderby=id')
      .subscribe(ref => this.loadRecorrencia(ref.value),
      error => this.httpService.handleError(error, () => this.ngOnInit()),
      () => this.httpService.done());

      this.loadEmpresa();

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.empresaChanged(); });
  }

  empresaChanged(): void {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.httpService.wait();
      this.httpService.get('/relatorios?$select=id,nome,descricao,jasper,params&$filter=agenda eq ' + true + '&$orderby=nome')
        .subscribe(ref => this.loadRelatorios(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

        this.httpService.wait();
      this.httpService.get('/custom/centralweb/get-users-empresa(' + this.dblinkedSessionService.empresa.id + ')')
        .subscribe(usuarios => this.loadUsuarios(usuarios),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());
    }
  }

  // loadRecorrencia(value: Array<any>) {
    loadRecorrencia(value: Array<Recorrencia>) {
    // this.recorrencias = [];
    this.recorrencias = new Array();
    value.forEach(element => {
      this.recorrencias.push(element);
    });
    /* value.forEach(element => {
      this.recorrencias.push({ label: element.cod, value: element });
    }); */
  }


  loadEmpresa() {

    this.empresas = this.dblinkedSessionService.empresas.filter(e => this.empresasSelecionadas.findIndex(es => es.id === e.id) === -1);
    this.empresas.forEach(element => {

      this.options.push({
        value: element.id, label: element.razaoSocial + ' - ' + element.cnpj

      });
    });


  }

  selected(option: any): boolean {

    // return (this.empresasSelecionadas.find(v => v.value === option.value) !== undefined);


    return this._value1 && this._value1.find(v => v[this.options.value] === option[this.options.value]) !== undefined;

  }

  selecionarEmpresa(value: any) {
    console.log(value);

    this.empresasSelecionadas.push({
    value: value.value, label: value.label
    });

  }

  loadRelatorios(value: Array<Relatorios>) {
    this.opcoesRelatorio = new Array();

    this.opcoesRelatorio.push({ label: 'Selecione um relatório', value: null });

   value.forEach(element => {

      this.opcoesRelatorio.push({
        label: element.nome + ' - ' + element.descricao,
        value: element
      });
    });
  }

  loadUsuarios(value: Array<any>) {
    this.opcoesUsuario = new Array();

    this.opcoesUsuario.push({ label: 'Selecione um usuário', value: null });

    value.forEach(element => {

      this.opcoesUsuario.push({
        label: element.nome + ' - ' + element.mail,
        value: element
      });
    });
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  verificaParametroDtIni(value: any) {
    if (value !== null && (value.params !== null && value.params !== undefined)) {
      if (value.params.indexOf('dtIni') >= 0) {
        return true;
      }
    } else {
      return false;
    }
  }

  verificaParametroDtFin(value: any) {
    if (value !== null && (value.params !== null && value.params !== undefined)) {
      if (value.params.indexOf('dtFin') >= 0) {
        return true;
      }
    } else {
      return false;
    }
  }

  verificaParametroUsuarioId(value: any) {
    if (value !== null && (value.params !== null && value.params !== undefined)) {
      if (value.params.indexOf('usuarioId') >= 0) {
        return true;
      }
    } else {
      return false;
    }
  }


  modalRecorrencia() {
    this.exbirModalRecorrencia = true;
  }

  agendarRelatorio() {
    const listasEnviar = {
      relatorio: this.relatorioSelecionado.id,
      recorrencia: this.relatorioAgendado.recorrencia.id,
      usuario: this.dblinkedSessionService.usuario.id,
      dtIni: this.dtIni,
      dtFin: this.dtFin,
      aCada: this.aCada,
      dom: this.dom,
      seg: this.seg,
      ter: this.ter,
      qua: this.qua,
      qui: this.qui,
      sex: this.sex,
      sab: this.sab,
      qtdeOcorrencia: this.qtdeOcorrencia,
      diaMes: this.diaMes,
      antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
      diaUtil: this.diaUtil,
      diaUtilSabado: this.diaUtilSabado,
      empresas: this.empresasSelecionadas,
      destinatarios: this.relatorioAgendado.destinatarios

    };

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/agendar-relatorio', listasEnviar)
      .subscribe(result => this.sucessoAgenda(result),
      error => this.httpService.handleError(error, () => this.agendarRelatorio()),
      () => this.httpService.done());
  }

  sucessoAgenda(result: any) {


    if (result.error === true) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Erro', detail: result.message });
      this.empresaChanged();
    } else {
      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: result.message });
      this.empresaChanged();
    }

  }

  hideMessage() {

  }

  cancelWhenReadonly(event: any): any {
    if (this.readonly) {
      event.preventDefault();
      return false;
    }
  }

  changeCallback(option: any, event: any): void {
    if (this.readonly) {
      return;
    }

    console.log(option);
    console.log(this.options);
    const value = option[this.options.value];

    if (!event.checked) {
      this._value = this._value.filter(v => v[this.options.value] !== value);
    } else {
      const v = new Object();
      v[this.options.value] = value;
      this._value.push(v);
    }

    this.valueChange.next(this._value);
  }

  clear() {
    this.toggleAll();
  }

  toggleAll(all?: any) {
    if (this.readonly) {
      return;
    }

    const _all = all !== undefined && all !== null ? all.checked : false;
    const value: Array<any> = new Array();

    this.options.values.forEach((i: any) => {
      if (_all) {
        value.push({});
        value[value.length - 1][this.options.value] = i[this.options.value];
      }
    });

    this._value = value;
    this.valueChange.next(this._value);
  }




/*
  private toolbarRefresh() {
    this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  } */
}
