import { Component, OnInit, OnDestroy } from '@angular/core';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { HttpService } from '../../shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { SessionService } from '../../shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { UtilService } from '../../shared/services/util.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'app/shared/services/config.service';

@Component({
  selector: 'app-relatorio-pedidos',
  templateUrl: './relatorio-pedidos.component.html',
  styleUrls: [
    './relatorio-pedidos.component.scss'
  ]
})


export class RelatorioPedidosComponent implements OnInit, OnDestroy {
  constructor(
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  title: string;
  subtitle: string;

  // Modal;
  modalPedidosCompra: boolean;
  fornecedor: Array<number>;
  detalhePorItem: boolean;
  dtIni: Date;
  dtFin: Date;
  pedido: any;
  status: Array<number>;
  comprador: Array<number>;
  tipo: Array<any>;
  tipoPedido: number;
  listaRelat: Array<any> = new Array();
  relatorioGerSelecionado: any = {};
  dicRelatComposto: any = {};
  datasIndividuais: boolean = false;
  modalRelatorio: boolean = false;
  modalRelatVendas: boolean;
  cashierView: boolean = false;
  dataRef: Date = new Date();
  DefFinalDate: Date;
  DefInitDate: Date;
  modalTitle: string = "Relatório de Vendas"

  // Dropdowns;
  listaFornecedor: SelectItem[];
  listaTipo: SelectItem[];
  listaStatus: SelectItem[];
  listVendedor: SelectItem[];
  listaComprador: SelectItem[];
  vendedorSelecionados: Array<any>;
  empresa_id: number
  private sessionSubscription: Subscription;
  meses: Array<any>
  mesIni: number;
  mesFin: number;

  // Data;
  defaultDate: Date;
  dtiniMonth: Date;
  dtmaxMonth: Date;
  // Warnings;
  msgs: Message[];
  msgsModal: Message[];

  isComissionRelat: boolean;

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.tipoPedido = params.tipo;
    });

    this.title = 'RELATÓRIOS';
    this.subtitle =
      'relatório de pedidos para visualização na tela ou exportar para excel';

    Promise.resolve(null).then(() => (this.toolbarService.hidden = true));
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    // Data;
    // ------------------------------------------

    this.defaultDate = new Date();
    // ------------------------------------------

    // Modal/ Pedidos;
    this.detalhePorItem = false;
    this.dtIni = this.defaultDate;
    this.dtFin = this.defaultDate;
    this.pedido = '';

    this.modalPedidosCompra = false;
    this.modalRelatVendas = false;
    this.listVendedor = [];
    this.vendedorSelecionados = [];
    this.isComissionRelat = false;
    this.cashierView = false;
    this.modalTitle = "Relatório de Vendas"

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.change();
      }
    );
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  private change() {
    this.isComissionRelat = false;
    this.modalTitle = "Relatório de Vendas"

    if (
      this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected
    ) {
      this.meses = []
      this.meses.push(
        { label: "Janeiro", value: 1 },
        { label: "Fevereiro", value: 2 },
        { label: "Março", value: 3 },
        { label: "Abril", value: 4 },
        { label: "Maio", value: 5 },
        { label: "Junho", value: 6 },
        { label: "Julho", value: 7 },
        { label: "Agosto", value: 8 },
        { label: "Setembro", value: 9 },
        { label: "Outubro", value: 10 },
        { label: "Novembro", value: 11 },
        { label: "Dezembro", value: 12 }
      )
      this.mesIni = this.dblinkedSessionService.periodo.month.value;
      this.mesFin = this.dblinkedSessionService.periodo.month.value;
      this.DefInitDate = new Date(this.dblinkedSessionService.periodo.year.value, 0, 1);
      this.DefFinalDate = new Date(this.dblinkedSessionService.periodo.year.value, 11, 31);
      this.dtiniMonth = this.DefInitDate;
      this.dtmaxMonth = this.DefFinalDate;
      this.empresa_id = parseInt(this.dblinkedSessionService.empresa.id.toString());
      this.getFornecedor();
      this.getTipo();
      this.getStatus();
      this.getCompradores();
      this.getConfigRelatorios();
      this.vendedorLoad();
    }
  }

  private getFornecedor() {
    this.httpService.wait();
    this.httpService
      .get(
        '/custom/pedido/get-ped-fornecedor(' +
        this.dblinkedSessionService.empresa.id +
        ')'
      )
      .subscribe(
        rst => {
          this.loadFornecedor(rst);
        },
        error =>
          this.httpService.handleError(error, () => this.getFornecedor()),
        () => this.httpService.done()
      );
  }

  private getTipo() {
    this.httpService.wait();
    this.httpService.get('/custom/pedido/get-ped-tipo-compra').subscribe(
      rst => {
        this.loadTipo(rst);
      },
      error => this.httpService.handleError(error, () => this.getTipo()),
      () => this.httpService.done()
    );
  }

  private getStatus() {
    this.httpService.wait();
    this.httpService.get('/custom/pedido/get-ped-status').subscribe(
      rst => {
        this.loadStatus(rst);
      },
      error => this.httpService.handleError(error, () => this.getStatus()),
      () => this.httpService.done()
    );
  }

  private getCompradores() {
    this.httpService.wait();
    this.httpService.get('/custom/pedido/get-ped-compradores(' +
    this.dblinkedSessionService.empresa.id +
    ')').subscribe(
      rst => {
        this.loadComprador(rst);
      },
      error => this.httpService.handleError(error, () => this.getCompradores()),
      () => this.httpService.done()
    );
  }

  exibirMensagem(value: any) {
    this.msgs = [];
    if (!value.error) {
      this.msgs.push({ severity: 'success', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.msgs.push({ severity: 'warn', summary: 'Erro', detail: value.mensagem });
    }
  }

  getConfigRelatorios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'modulo': this.tipoPedido == 0 ? '2' : '7'
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            this.listaRelat = new Array();
            for (var line of res["mensagem"]) {
              this.listaRelat.push(line);
            }

            this.dicRelatComposto = res["dicconfigs_compostas"];
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  hideMessage() {


  }

  private loadFornecedor(value: Array<any>) {
    this.fornecedor = [];

    this.listaFornecedor = [];
    value.forEach(element => {
      this.listaFornecedor.push({
        label: element.nome + ' - ' + element.cnpj,
        value: element.id
      });
      this.fornecedor.push(element.id);
    });
  }

  private loadTipo(value: Array<any>) {
    this.tipo = [];

    const tipoArr = [];

    this.listaTipo = [];
    this.listaTipo.push({ label: 'TODOS', value: '*' });
    value.forEach(element => {
      this.listaTipo.push({
        label: element.description,
        value: [element.id]
      });
      tipoArr.push(element.id);
    });

    const index = this.listaTipo.findIndex(c => c.value === '*');
    this.listaTipo.splice(index, 1, { label: 'TODOS', value: tipoArr });

    this.tipo = tipoArr;
  }

  private loadStatus(value: Array<any>) {
    this.status = [];

    this.listaStatus = [];
    value.forEach(element => {
      this.listaStatus.push({
        label: element.description,
        value: element.id
      });
      this.status.push(element.id);
    });
  }

  private loadComprador(value: Array<any>) {
    this.comprador= [];

    this.listaComprador = [];
    value.forEach(element => {
      this.listaComprador.push({
        label: element.description,
        value: element.id
      });
      this.comprador.push(element.id);
    });
  }

  refreshModalPedidosCompra() {
    this.modalPedidosCompra = false;

    this.detalhePorItem = false;
    this.dtIni = this.defaultDate;
    this.dtFin = this.defaultDate;
    this.pedido = '';

    this.fornecedor = [];
    this.listaFornecedor.forEach(element => {
      this.fornecedor.push(element.value);
    });

    this.status = [];
    this.listaStatus.forEach(element => {
      this.status.push(element.value);
    });

    this.tipo = [];
    const tipoArr = [];
    this.listaTipo.forEach(element => {
      if (element.label !== 'TODOS') {
        tipoArr.push(element.value);
      }
    });
    this.tipo = tipoArr;
  }

  showDialogRelatorio(tipo) {
    if (tipo === 'Compra') {
      this.modalPedidosCompra = true;
    }
    else {
      this.relatorioGerSelecionado = tipo;
      this.modalRelatorio = true;
      this.dataRef = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1);
    }
  }

  gerarRelatorioGerencialExcel() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    let config_list = [];
    if (this.relatorioGerSelecionado.is_composta) {
      for (let conf of this.dicRelatComposto[this.relatorioGerSelecionado.id]) {
        if (this.datasIndividuais && conf['dataRef'] == null) {
          this.exibirMensagem({ error: true, mensagem: "Informe uma data para todos os relatórios ou desmarque a opção de datas individuais." });
          return;
        }

        config_list.push({
          'empresa_id': this.dblinkedSessionService.empresa.id,
          'dt_ref': this.datasIndividuais ? [conf['dataRef'].getDate(), conf['dataRef'].getMonth() + 1, conf['dataRef'].getFullYear()] : [this.dataRef.getDate(), this.dataRef.getMonth() + 1, this.dataRef.getFullYear()],
          'config_id': conf['config_relat_ref_id'],
          'worksheet_title': conf['worksheet_title']
        });
      }
    } else {
      config_list = [{
        'empresa_id': this.dblinkedSessionService.empresa.id,
        'dt_ref': [this.dataRef.getDate(), this.dataRef.getMonth() + 1, this.dataRef.getFullYear()],
        'config_id': this.relatorioGerSelecionado.id
      }];
    }

    const infos = {
      list_infos: config_list,
      is_composta: this.relatorioGerSelecionado.is_composta,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', infos, httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();

          } else {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0',
                'userid': this.sessionService.loggedUser.id.toString()
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', httpOptions2)
              .subscribe(
                (res: Blob) => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);
                  let fileName = "Relatório gerencial.xlsx";
                  link.href = url;
                  link.download = fileName;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o relatório." });
                  console.log(err);
                  this.httpService.done();
                });
          }
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o relatório." });
          console.log(err);
          this.httpService.done();
        });
  }

  renderizarRelatorioGerencial() {
    var data_ref: string = this.dataRef.getDate() + '-' + (this.dataRef.getMonth() + 1).toString() + '-' + this.dataRef.getFullYear();
    window.open('/workstats/render-relatorios-gerenciais/' + this.dblinkedSessionService.empresa.id.toString() + '/' + this.relatorioGerSelecionado.id + '/' + this.relatorioGerSelecionado.plano_gerencial_id + '/' + data_ref, '_blank');
  }

  validaModal() {


    let boo = false;

    // if (this.dtIni === null) {
    if (this.mesIni === null) {
      boo = true;

      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Campos Obrigatórios: ',
        detail: 'A data inicial deve ser informada.'
      });
    }

    // if (this.dtFin === null) {
    if (this.mesFin === null) {
      boo = true;
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Campos Obrigatórios: ',
        detail: 'A data final deve ser informada.'
      });
    }

    if (this.fornecedor.length === 0) {
      boo = true;
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Campos Obrigatórios: ',
        detail: 'O participante deve ser informado.'
      });
    }

    if (this.tipo === null) {
      boo = true;
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Campos Obrigatórios: ',
        detail: 'O tipo da compra deve ser informado.'
      });
    }

    if (this.status.length === 0) {
      boo = true;
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Campos Obrigatórios: ',
        detail: 'O status da compra deve ser informado.'
      });
    }

    if (!boo) {
      // 'null' enviado pela url e tratado pelo RelatorioDetalheComponent;
      this.pedido = this.pedido === '' ? 'null' : this.pedido;
      this.windowOpen();
      this.pedido = this.pedido === 'null' ? '' : this.pedido; // Restaura o valor default;
    }
  }

  validaModalExcel() {


    let boo = false;

    // if (this.dtIni === null) {
    if (this.mesIni === null) {
      boo = true;

      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Campos Obrigatórios: ',
        detail: 'A data inicial deve ser informada.'
      });
    }

    // if (this.dtFin === null) {
    if (this.mesFin === null) {
      boo = true;
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Campos Obrigatórios: ',
        detail: 'A data final deve ser informada.'
      });
    }

    if (this.fornecedor.length === 0) {
      boo = true;
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Campos Obrigatórios: ',
        detail: 'O participante deve ser informado.'
      });
    }

    if (this.tipo === null) {
      boo = true;
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Campos Obrigatórios: ',
        detail: 'O tipo da compra deve ser informado.'
      });
    }

    if (this.status.length === 0) {
      boo = true;
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Campos Obrigatórios: ',
        detail: 'O status da compra deve ser informado.'
      });
    }

    if (!boo) {
      // 'null' enviado pela url e tratado pelo RelatorioDetalheComponent;
      this.pedido = this.pedido === '' ? 'null' : this.pedido;
      this.exportarRelatorioExcel();
      this.pedido = this.pedido === 'null' ? '' : this.pedido; // Restaura o valor default;
    }
  }

  exportarRelatorioExcel(){
     // let _date: any[];
     let inicio: string;
     let final: string;
     let ano = this.dblinkedSessionService.periodo.year.value
     let anoString = this.dblinkedSessionService.periodo.year.value.toString()
     // _date = this.utilService.dateToStringDate(this.dtIni).split('/');
     // inicio = _date[2] + '-' + _date[1] + '-' + _date[0];

     // _date = this.utilService.dateToStringDate(this.dtFin).split('/');
     // final = _date[2] + '-' + _date[1] + '-' + _date[0];

     inicio = anoString + '-' + (this.mesIni < 10 ? '0' + this.mesIni : this.mesIni) + '-01'

     final = anoString + '-' + (this.mesFin < 10 ? '0' + this.mesFin : this.mesFin) + '-' + new Date(ano, this.mesFin, 0).getDate().toString()

     let detalhe: number;
     detalhe = this.detalhePorItem ? 0 : 1;

     this.fornecedor = this.listaFornecedor.map(row => row.value)

     this.status = this.listaStatus.map(row => row.value)


     this.comprador = this.listaComprador.map(row => row.value)



    const httpOptions = {
     headers: new HttpHeaders({
       'Authorization': this.configService.bearerApi,
       'Cache-Control': 'no-store, max-age=0'
     }),
     responseType: 'blob' as 'json',
    }


    if(this.pedido == '' || this.pedido == 'null'){
      this.pedido = null;
    }

    const infos = {
     empresa_id: this.dblinkedSessionService.empresa.id,
     data_inicial: inicio,
     data_final: final,
     user_id: this.sessionService.loggedUser.id,
     detalhe: detalhe,
     fornecedor: this.fornecedor,
     pedido: this.pedido,
     status: this.status,
     comprador: this.comprador,
     tipo: this.tipo
    }

    this.httpService.wait()
    try {
     this.httpClient.post(this.configService.defaultUrlApiUpload + 'pedido/relatorio-pedido-compra', infos, httpOptions).toPromise()
       .then((res: any) => {
         let link = document.createElement('a')
         let url = window.URL.createObjectURL(res)
         let fileName = "Relatório Pedidos.xlsx"
         link.href = url
         link.download = fileName
         link.click()
         window.URL.revokeObjectURL(url)
       })
       .catch((err: HttpErrorResponse) => {
         this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.message })
       })
    } catch (err) {
     this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    } finally {
     this.httpService.done()
    }

  }

  windowOpen() {
    // let _date: any[];
    let inicio: string;
    let final: string;
    let ano = this.dblinkedSessionService.periodo.year.value
    let anoString = this.dblinkedSessionService.periodo.year.value.toString()
    // _date = this.utilService.dateToStringDate(this.dtIni).split('/');
    // inicio = _date[2] + '-' + _date[1] + '-' + _date[0];

    // _date = this.utilService.dateToStringDate(this.dtFin).split('/');
    // final = _date[2] + '-' + _date[1] + '-' + _date[0];

    inicio = anoString + '-' + (this.mesIni < 10 ? '0' + this.mesIni : this.mesIni) + '-01'

    final = anoString + '-' + (this.mesFin < 10 ? '0' + this.mesFin : this.mesFin) + '-' + new Date(ano, this.mesFin, 0).getDate().toString()

    let detalhe: number;
    detalhe = this.detalhePorItem ? 0 : 1;

    this.fornecedor =
      this.fornecedor.length === this.listaFornecedor.length
        ? [0]
        : this.fornecedor;

    this.status =
      this.status.length === this.listaStatus.length ? [0] : this.status;

    window.open(
      '/relatorio-detalhe/' +
      this.dblinkedSessionService.empresa.id +
      '/' +
      inicio +
      '/' +
      final +
      '/' +
      detalhe +
      '/' +
      this.fornecedor +
      '/' +
      this.pedido +
      '/' +
      this.status +
      '/' +
      this.comprador +
      '/' +
      this.tipo,
      '_blank'
    );

    this.refreshModalPedidosCompra();
  }

  carregarValores(config) {
    let replicar = false;

    for (let dicConfig of this.dicRelatComposto[this.relatorioGerSelecionado.id]) {
      if (config['id'] === dicConfig['id']) replicar = true;

      if (replicar) {
        dicConfig['dataRef'] = config['dataRef'];
      }
    }
  }

  exibirModal(isComissionRelatParam: boolean) {
    this.isComissionRelat = isComissionRelatParam;

    this.modalTitle = this.isComissionRelat ? "Relatório de Comissões" : "Relatório de Vendas"

    this.vendedorSelecionados = []

    if (this.listVendedor !== null && this.listVendedor && this.listVendedor.length > 0) {
      this.listVendedor.map(item => this.vendedorSelecionados.push(item.value));
    }

    let ano = this.dblinkedSessionService.periodo.year.value;
    let mes = this.dblinkedSessionService.periodo.month.value
    let dia = 31;
    let data = new Date(ano, --mes, 1);

    // pegando o ultimo dia do mes atual
    if (mes !== 11) {
      data.setMonth(mes + 1);
      data.setDate(data.getDate() - 1);
    } else {
      data.setDate(dia);
    }

    this.dtmaxMonth = data;
    this.dtiniMonth = new Date(data.getFullYear(), data.getMonth(), 1);

    this.modalRelatVendas = true;
  }

  reportVendas(type: Number) {
    if (type == 1) {
      const infos = {
        empresa_id: this.dblinkedSessionService.empresa.id,
        user_id: this.sessionService.loggedUser.id,
        vendedores: this.vendedorSelecionados,
        dt_ini: this.dtiniMonth.getFullYear() + "-" + (this.dtiniMonth.getMonth() + 1) + "-" + this.dtiniMonth.getDate(),
        dt_fin: this.dtmaxMonth.getFullYear() + "-" + (this.dtmaxMonth.getMonth() + 1) + "-" + this.dtmaxMonth.getDate(),
        visao: this.cashierView
      }

      this.httpService.wait()
      this.httpService.post(this.configService.defaultUrlApiUpload + "financeiro/relat-comissoes", infos)
        .subscribe(res => {
          this.exibirMensagem(res)
          this.httpService.done()
          if (res.error) return

          this.httpService.wait()
          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          }

          this.httpClient.get(this.configService.defaultUrlApiUpload + "financeiro/relat-comissoes", httpOptions2)
            .subscribe(
              (res: Blob) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                let fileName = "relatorio_comissao.xlsx"
                link.href = url
                link.download = fileName
                link.click()
                window.URL.revokeObjectURL(url)
                this.httpService.done()
              },
              err => {
                this.exibirMensagem({ error: true, mensagem: "Erro no APi ao baixar o relatório, contate o suporte: " + err.message })
                console.log(err)
                this.httpService.done()
              })
        },
          err => {
            this.exibirMensagem({ error: true, mensagem: "Erro no APi ao gerar o relatório, contate o suporte: " + err.message })
            console.log(err)
            this.httpService.done()
          })

    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'datainicial': this.dtiniMonth.toString(),
          'datafinal': this.dtmaxMonth.toString(),
          'vendedores': this.vendedorSelecionados,
          'tipo': type.toString(),
          'userId': this.sessionService.loggedUser.id.toString(),
          visao: this.cashierView.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'pedidos/relat-vendas', null, httpOptions)
        .subscribe(res => {

          if (res["error"]) {
            this.exibirMensagem(res)
              ;
            this.httpService.done();

          } else {
            var file = res["file"]
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0',
                'tipo': type.toString(),
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'pedidos/baixar-relat-vendas', httpOptions2)
              .subscribe(
                res => {
                  window.open(this.configService.defaultUrlApiUpload + "pedidos/baixar-relat-vendas")
                  this.httpService.done();
                },
                err => {
                  if (err["error"] === null || err["error"] === undefined) {
                    this.msgs.push({ severity: 'warn', summary: 'Erro', detail: "Um erro inesperado foi detectado no API. Entre o contato com o suporte." });

                  } else {
                    this.exibirMensagem(err);
                    this.httpService.done();
                  }
                });
          }
        },
          err => {
            if (err["error"] === null || err["error"] === undefined) {
              this.msgs.push({ severity: 'warn', summary: 'Erro', detail: "Um erro inesperado foi detectado no API. Entre o contato com o suporte." });

            } else {
              this.exibirMensagem(err)
              console.log(err);
              this.httpService.done();
            }
          });
    }
  }

  vendedorLoad() {
    this.listVendedor = []
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'userid': this.sessionService.loggedUser.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'pedidos/loadVendedor', httpOptions)
      .subscribe(
        res => {
          const result = res["data"];
          this.listVendedor = []
          if (result && result.length > 0) {
            for (let line of result) {
              this.listVendedor.push({ label: line["id"] + " - " + line["nome"], value: line["id"] });
            }
          }
          this.httpService.done();
        },
        error => this.httpService.handleError(error, () => this.exibirMensagem(error)),
        () => this.httpService.done());
  }

  onCloseModal() {
    this.change()
  }

  exportDetails() {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi, }) }

    const httpOptions2 = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        userId: this.sessionService.loggedUser.id.toString(),
        empresaId: this.dblinkedSessionService.empresa.id.toString(),
      }),
      responseType: 'blob' as 'json'
    }

    const payload = {
      list_empresas_id: [this.dblinkedSessionService.empresa.id],
      userId: this.sessionService.loggedUser.id,
      dtInicial: this.dtiniMonth.getFullYear() + "-" + (this.dtiniMonth.getMonth() + 1) + "-" + this.dtiniMonth.getDate(),
      dtFinal: this.dtmaxMonth.getFullYear() + "-" + (this.dtmaxMonth.getMonth() + 1) + "-" + this.dtmaxMonth.getDate(),
      mov: 's',
    }

    try {
      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + '/homepage/dash-buy-sell/relat', payload, httpOptions).subscribe(
        res => {
          if(!res["error"]) this.httpClient.get(this.configService.defaultUrlApiUpload + '/homepage/dash-buy-sell/relat', httpOptions2).subscribe(
            (res: any) => {
              let link = document.createElement('a')
              let url = window.URL.createObjectURL(res)
              link.href = url
              link.download = "relatorio-movimentacao-detalhada-vendas.xlsx"
              link.click()
              window.URL.revokeObjectURL(url)
              this.httpService.done();
            },
            err => {
              this.exibirMensagem(err.status + ' - Houve um erro ao recuperar seu relatório! Contate o suporte')
              this.httpService.done();
            }
          )
        },
        err => {
          this.exibirMensagem(err.status + ' - Houve um erro ao gerar seu relatório! Contate o suporte')
          this.httpService.done();
        })
    } catch(err) {
      this.exibirMensagem('Falha na comunicação com o servidor!')
    }
  }
}
