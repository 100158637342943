import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { isNullOrUndefined } from 'util';
import { Formulario } from 'app/eventos/model/formulario.model';
import { FormularioPerguntasCampo } from 'app/eventos/model/formulario-perguntas-campo.model';
import { FormularioTipoCampo }  from 'app/eventos/model/formulario-tipo-campo.model';
import { Message, MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-formulario-perguntas-campos',
  templateUrl: './formulario-perguntas-campos.component.html',
  styleUrls: ['./formulario-perguntas-campos.component.scss'],
  providers: [CrudService]
})
export class FormularioPerguntasCamposComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  pt_BR: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;

  modalFormulario           : boolean = false;
  opcoesFormulario          : SelectItem[] = [];
  opcoesTipoCampo           : SelectItem[] = [];
  idLista                   : Array<any> = new Array();

  checkedAll    = false;

  formulario    = new Formulario();
  editFormulario                   : boolean = false;
  btnSalvarDisabled                : boolean = false;
  btnCancelarDisabled              : boolean = false;
  formularioPerguntasCampo         : FormularioPerguntasCampo;
  formularioTipoCampo              : FormularioTipoCampo;

  listaFormularioPerguntasCampos: Array<any> = new Array();
  formularioId : number = 0;
  opcoesComboRequirido :  Array<any> = new Array();
  colslistaFormularioPerguntasCampos : any[];

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title              = 'FORMULÁRIO PERGUNTAS';
    this.subtitle           = 'CADASTRO DE PERGUNTAS';
    this.titleService.title = 'FORMULÁRIO PERGUNTAS';


    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();

    this.modalFormulario    = false;
    this.checkedAll         = false;

    this.opcoesFormulario  = new Array();
    this.formulario       = new Formulario();
    this.formularioPerguntasCampo = new FormularioPerguntasCampo();
    this.editFormulario   = false;
    this.idLista          = new Array();
    this.listaFormularioPerguntasCampos  = new Array();;

    this.colslistaFormularioPerguntasCampos = [
      { field: 'formularioNome', header: 'FORMULÁRIO' },
      { field: 'perguntaDescricao', header: 'PERGUNTA' },
      { field: 'requeridoPt', header: 'REQUERIDO' },
      { field: 'posicaoCampo', header: 'PERGUNTA Nº' },
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.listar();

      });

  }

 listar(){

  this.modalFormulario    = false;
  this.checkedAll         = false;

  this.opcoesFormulario         = new Array();
  this.formulario               = new Formulario();
  this.formularioTipoCampo      = new FormularioTipoCampo();
  this.formularioPerguntasCampo = new FormularioPerguntasCampo();
  this.editFormulario   = false;
  this.idLista          = new Array();
  this.listaFormularioPerguntasCampos  = [] = [];
  this.opcoesTipoCampo                 = [];
  this.toolbarEnable();
  this.getComboDispVizinha();

  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-lista-formularios-perguntas-campos')
    .subscribe(retorno => {
      this.listaFormularioPerguntasCampos = new Array();
      retorno.forEach(i =>{
        this.listaFormularioPerguntasCampos.push(i);

      })
    },
      error => this.httpService.handleError(error, () => this.listar()),
      () => this.httpService.done());


 }


  hideMessage() {




  }

  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;

    this.idLista = new Array();

    this.listaFormularioPerguntasCampos.forEach(i=> {
      if (!this.checkedAll) {
        i.selected = false;
        this.idLista.slice(i.id, 1);
      } else {
        i.selected = true;
        this.idLista.push(i.id);
      }
    });


    this.configuraToolBar();
  }

  configuraToolBar() {
    if (isNullOrUndefined(this.idLista) || this.idLista.length === 0) {
     this.toolbarService.alterarStatus('remove', true, 'Excluir Formulário', () => this.excluirFormulario());
     this.toolbarService.alterarStatus('add', false, 'Nova Formulário', ()  => this.showModalNovoFormulario());
     this.toolbarService.alterarStatus('export', true, 'Editar Formulário', () =>() => this.showModalEditarFormulario());
    } else {
      if (this.idLista.length === 1) {
        this.toolbarService.alterarStatus('remove', false, 'Excluir Formulário', () => this.excluirFormulario());
        this.toolbarService.alterarStatus('add', true, 'Nova Formulário', ()  => this.showModalNovoFormulario());
        this.toolbarService.alterarStatus('export', false, 'Editar Formulário', ()  => this.showModalEditarFormulario());


      } else if(this.idLista.length > 1)  {
        this.toolbarService.alterarStatus('remove', false, 'Excluir Formulário', () => this.excluirFormulario());
        this.toolbarService.alterarStatus('add', true, 'Nova Formulário', () => this.showModalNovoFormulario());
        this.toolbarService.alterarStatus('export', true, 'Editar Formulário', () => this.showModalEditarFormulario());
      }
    }

  }



  selectCheckbox(id: any, event: any) {

    if (event.selected) {
       event.selected = true;
       const index = this.idLista.findIndex(i => i === id);
       this.idLista.splice(index,1);

    } else {
      this.idLista.push(id);
    }

    this.configuraToolBar();

  }



  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

      Promise.resolve(null).then(() => this.toolbarService.hidden = false);
      this.toolbarService.ocultar('voltar');
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.ocultar('more_vert');
      this.toolbarService.ocultar('print');
      this.toolbarService.ocultar('edit');

      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
        () => this.ngOnInit()
      );
      this.toolbarService.ocultar('save');

     // this.toolbarService.replace('more_vert', true, 'class-edit-button', 'more_vert', 'Opções');
      this.toolbarService.ocultar('pesquisa');

      //this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir Pedido',     );
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.replace('export', true, 'class-edit-button', 'edit', 'Editar Formulário', () => this.showModalEditarFormulario() );
     // this.toolbarService.replace('edit', true, 'class-edit-button', 'list', 'Alterar Títulos Selecionados');

      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo Formulário',    () => this.showModalNovoFormulario()    );


  }



  showModalEditarFormulario() {
    this.modalFormulario = true;
    this.hideMessage();
    this.editFormulario = true;
    this.comboFormularios();
    this.comboTipoCampo();

    this.formularioPerguntasCampo = new FormularioPerguntasCampo();
    this.formulario               = new Formulario();
    this.formularioTipoCampo      = new FormularioTipoCampo();

    let i =  this.listaFormularioPerguntasCampos.findIndex(c=> c.id === this.idLista[0] );

    this.formularioPerguntasCampo.id                  = this.listaFormularioPerguntasCampos[i].id;
    this.formularioPerguntasCampo.perguntaDescricao   = this.listaFormularioPerguntasCampos[i].perguntaDescricao;
    this.formularioPerguntasCampo.formularioTipoCampo = new FormularioTipoCampo();
    this.formularioPerguntasCampo.formularioTipoCampo = this.listaFormularioPerguntasCampos[i].formularioTipoCampoId;
    this.formularioPerguntasCampo.formulario          = new Formulario();
    this.formularioPerguntasCampo.formulario          = this.listaFormularioPerguntasCampos[i].formularioId;
    this.formularioPerguntasCampo.posicaoCampo        = this.listaFormularioPerguntasCampos[i].posicaoCampo;
    this.formularioPerguntasCampo.requerido           = this.listaFormularioPerguntasCampos[i].requerido;

  }

  showModalNovoFormulario() {
    this.formularioPerguntasCampo = new FormularioPerguntasCampo();
    this.formulario               = new Formulario();
    this.formularioTipoCampo      = new FormularioTipoCampo();
    this.modalFormulario = true;
    this.editFormulario  = false;
    this.formularioPerguntasCampo.requerido = true;
    this.hideMessage();
    this.comboFormularios();
    this.comboTipoCampo();

  }

  validacao(){

    if (isNullOrUndefined( this.formularioPerguntasCampo.formulario)  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar um formulário!'
      });
      return true;

    }


    if (isNullOrUndefined( this.formularioPerguntasCampo.perguntaDescricao)  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor digitar uma pergunta!'
      });
      return true;

    }


    if (isNullOrUndefined( this.formularioPerguntasCampo.formularioTipoCampo)  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar um tipo de campo!'
      });
      return true;

    }


    if (isNullOrUndefined( this.formularioPerguntasCampo.posicaoCampo)  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor digitar a posição do campo!'
      });
      return true;

    }


    return false;
  }


  ngOnDestroy(): void {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  salvarFormularioPerguntasCampo(){

    if (this.validacao()) {
      return;
    }

    const listaEnviar = {
      formularioPerguntasCampo     : this.formularioPerguntasCampo,
       usuarioId                   : this.sessionService.loggedUser.id,
       IsNovoRegistro              : true
    };


    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-formulario-perguntas-campos', listaEnviar)
      .subscribe(result => {
        console.log(result[0]);
        if (result[0].error) {
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        }

        this.listar();
      },
        error => this.httpService.handleError(error, () => this.salvarFormularioPerguntasCampo()),
        () => this.httpService.done());
  }

  editarFormularioPerguntasCampo(){

    if (this.validacao()) {
      return;
    }


    const listaEnviar = {
      id                            : this.idLista[0],
      formularioPerguntasCampo      : this.formularioPerguntasCampo,
      usuarioId                     : this.sessionService.loggedUser.id,
      IsNovoRegistro                : false
   };

   this.httpService.wait();
   this.httpService.post('/custom/polozi/salvar-formulario-perguntas-campos', listaEnviar)
     .subscribe(result => {
      if (result[0].error ) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      }
      this.listar();
     },
       error => this.httpService.handleError(error, () => this.editarFormularioPerguntasCampo()),
       () => this.httpService.done());


  }


 excluirFormulario(){
   if (confirm('Deseja excluir a(s) perguntas(s) selecionada(s)?')) {

    const listaEnviar = {
      ids              : this.idLista

   };

   this.httpService.wait();
   this.httpService.post('/custom/polozi/deletar-formulario-perguntas-campos', listaEnviar)
     .subscribe(result => {
      if (result[0].error ) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      }
      this.listar();
     },
       error => this.httpService.handleError(error, () => this.excluirFormulario()),
       () => this.httpService.done());


   }
 }

 comboFormularios(){

  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-lista-formularios')
    .subscribe(retorno => {

      this.opcoesFormulario = [];
      retorno.forEach(i =>{
        this.opcoesFormulario.push({label: i.nome , value: i.id});
      })
    },
      error => this.httpService.handleError(error, () => this.comboFormularios()),
      () => this.httpService.done());

 }

 comboTipoCampo(){
  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-lista-tipo-campo')
    .subscribe(retorno => {

      this.opcoesTipoCampo = [];
      retorno.forEach(i =>{
        this.opcoesTipoCampo.push({label: i.descricao , value: i.id});
      })
    },
      error => this.httpService.handleError(error, () => this.comboFormularios()),
      () => this.httpService.done());
 }

 getComboDispVizinha(){
  this.opcoesComboRequirido = new Array();
  this.opcoesComboRequirido.push({label: 'Sim', value: 'Sim'});
  this.opcoesComboRequirido.push({label: 'Não', value: 'Não'});
}


}



