export class TipoClienteServicosComunicacao {
  id: number;
  cod: any;
  descr: string;

  constructor(tipoClienteServicosComunicacao?: TipoClienteServicosComunicacao | number) {
    if (tipoClienteServicosComunicacao !== undefined && tipoClienteServicosComunicacao !== null) {
      if (typeof tipoClienteServicosComunicacao === 'number') {
        this.id = tipoClienteServicosComunicacao;
      } else {
        this.id = tipoClienteServicosComunicacao.id;
        this.cod = tipoClienteServicosComunicacao.cod;
        this.descr = tipoClienteServicosComunicacao.descr;
      }
    }
  }
}
