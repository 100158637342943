import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import exportExcel from 'app/util/processing/exportExcel';
import { MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { AppComponent } from '../../app.component';
import { ConfigService } from '../../shared/services/config.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { HttpService } from '../../shared/services/http.service';
import { SessionService } from '../../shared/services/session.service';
import { TitleService } from '../../shared/services/title.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import LogManagement from 'app/shared/common/log-management/log-control';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-edit-livro',
  templateUrl: './edit-livro.component.html',
  styleUrls: ['./edit-livro.component.scss'],
})
export class EditLivroComponent implements OnInit, OnDestroy {
  // TODO: Review INPUTS

  private LogManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  title: string
  subtitle: string = '';
  private empresaSubscription: Subscription;
  private initSubscription: Subscription;
  private sessionSubscription: Subscription;

  private empresaId: number;
  private params: string;
  icmsIpi: Array<any> = new Array();
  analitico: number;
  detalhesPorItem: boolean;
  exportOrderList: Array<string> = new Array()
  exibirAjuste: boolean;


  //export excel
  colsicmsIpisDetalhe: any[];
  colsicmsIpis: any[];
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  pedidosSelecionados: Array<any> = new Array();
  campoEditar: string;
  valorEditar: string;

  optionsAjustes: SelectItem[] = [
    { label: "CFOP", value: 'CFOP' },
    { label: "CST ICMS", value: 'CST_ICMS' },
    { label: "VL BC ICMS", value: 'VL_BC_ICMS' },
    { label: "ALIQ ICMS", value: 'ALIQ_ICMS' },
    { label: "VL ICMS", value: 'VL_ICMS' },
    { label: "VL ISENTAS", value: 'VL_ISENTAS' },
    { label: "VL OUTRAS", value: 'VL_OUTRAS' },
    { label: "VL ICMS", value: 'VL_ICMS' },
    { label: "CST IPI", value: 'CST_IPI' },
    { label: "VL BC IPI", value: 'VL_BC_IPI' },
    { label: "ALIQ IPI", value: 'ALIQ_IPI' },
    { label: "VL IPI", value: 'VL_IPI' },
    { label: "VL BC ICMS ST", value: 'VL_BC_ICMS_ST' },
    { label: "ALIQ ICMS ST", value: 'ALIQ_ICMS_ST' },
    { label: "VL ICMS ST", value: 'VL_ICMS_ST' },
    { label: "CST PIS", value: 'CST_PIS' },
    { label: "VL BC PIS", value: 'VL_BC_PIS' },
    { label: "ALIQ PIS", value: 'ALIQ_PIS' },
    { label: "VL PIS", value: 'VL_PIS' },
    { label: "CST COF", value: 'CST_COF' },
    { label: "VL BC COF", value: 'VL_BC_COF' },
    { label: "ALIQ COF", value: 'ALIQ_COF' },
    { label: "VL COF", value: 'VL_COF' },
    { label: "CHV NFE", value: 'CHV_NFE' },
    { label: "COD RENDIMENTO", value: 'COD_REND' }


  ]

  constructor(private titleService: TitleService,
              private httpService: HttpService,
              private toolbarService: ToolbarService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private configService: ConfigService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private appComponent: AppComponent,
              private messageService: MessageService,
              private httpClient: HttpClient) {
  }

  ngOnInit(): void {
    if (this.configService.production) {
      this.appComponent._displaySideNav = false;
    } else {
      setTimeout(() => { this.appComponent._displaySideNav = false; });
    }

    this.initSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.toolbarService.hidden = true;
      this.carregar();
      this.exibirAjuste = false;

    });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.toolbarService.hidden = true;
        this.carregar();
        this.exibirAjuste = false;
      });

    this.title = 'LIVRO FISCAL';



    this.colsicmsIpisDetalhe = [
      { field: 'operacao', header: 'OPERAÇÃO' },
      { field: 'cnpj', header: 'CNPJ' },
      { field: 'razao', header: 'RAZÃO' },
      { field: 'numPedido', header: 'PEDIDO' },
      { field: 'numDoc', header: 'NUM DOC' },
      { field: 'codMod', header: 'COD MOD' },
      { field: 'codPart', header: 'COD PART' },
      { field: 'codItem', header: 'COD ITEM' },
      { field: 'dtDoc', header: 'DT DOC' },
      { field: 'dtES', header: 'DT E/S' },
      { field: 'codOper', header: 'COD OPER' },
      { field: 'cfop', header: 'CFOP' },
      { field: 'qtde', header: 'QTDE' },
      { field: 'vlUnit', header: 'VL UNIT' },
      { field: 'vlDesc', header: 'VL DESC' },
      { field: 'vlProd', header: 'VL PROD' },
      { field: 'vlFrete', header: 'VL FRETE' },
      { field: 'vlSeguro', header: 'VL SEGURO' },
      { field: 'cstIcms', header: 'CST ICMS' },
      { field: 'vlBcIcms', header: 'VL BC ICMS' },
      { field: 'aliqIcms', header: 'ALIQ ICMS' },
      { field: 'vlIcms', header: 'VL ICMS' },
      { field: 'vlIsentas', header: 'VL ISENTAS' },
      { field: 'vlOutras', header: 'VL OUTRAS' },
      { field: 'cstIpi', header: 'CST IPI' },
      { field: 'vlBcIpi', header: 'VL BC IPI' },
      { field: 'aliqIpi', header: 'ALIQ IPI' },
      { field: 'vlIpi', header: 'VL IPI' },
      { field: 'vlBcIcmsSt', header: 'VL BC ICMS ST' },
      { field: 'aliqIcmsSt', header: 'ALIQ ICMS ST' },
      { field: 'vlIcmsSt', header: 'VL ICMS ST' },
      { field: 'cstPis', header: 'CST PIS' },
      { field: 'vlBcPis', header: 'VL BC PIS' },
      { field: 'aliqPis', header: 'ALIQ PIS' },
      { field: 'vlPis', header: 'VL PIS' },
      { field: 'cstCofins', header: 'CST COFINS' },
      { field: 'vlBcCofins', header: 'VL BC COFINS' },
      { field: 'aliqCofins', header: 'ALIQ COFINS' },
      { field: 'vlCofins', header: 'VL COFINS' },
      { field: 'vlTot', header: 'VL TOT' },
      { field: 'chvNfe', header: 'CHV NFE' },
      { field: 'codRend', header: 'COD REND' },
      { field: 'id', header: 'id' }
    ];


  }

  ngOnDestroy() {
    this.toolbarService.hidden = true;
    this.sessionService.destroySubscribe(this.initSubscription);
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  private carregar() {

    this.icmsIpi = new Array();

    const mes = this.dblinkedSessionService.periodo.month.value > 8 ?
      this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
    const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
    this.defaultDate = new Date(dt);

    let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
    // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
    lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
    this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

    this.subtitle = '. Período selecionado: ' + '01/' + mes + '/' + this.dblinkedSessionService.periodo.year.value
            + ' à ' + lastDay.getDate() + '/' + mes + '/' + this.dblinkedSessionService.periodo.year.value

    this.icmsIpi = new Array<any>();
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.sessionService.destroySubscribe(this.initSubscription);
      this.httpService.wait();
      this.httpService.get('/custom/fiscal/livros-fiscal-edit', [this.dblinkedSessionService.empresa.id, this.dataIniSearch, this.dataFinSearch])
      .subscribe(dados => {

        this.icmsIpi = dados;

      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());

    }

  }

  selectAll() {
    this.pedidosSelecionados.forEach(item => {
    })
    this.changeToolbar();

  }

 get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  onRowSelect(event: any) {
    this.changeToolbar();
  }

  changeToolbar() {


  }

  modalEdicao(){
    this.exibirAjuste = true;
  }

  salvarAlteracoes(){

    if (confirm('Deseja realmente salvar estas alterações?')) {

      const data = {
        listaLivro     : this.pedidosSelecionados,
        usuario             : this.sessionService.loggedUser.id,
        empresaId           : this.dblinkedSessionService.empresa.id,
        campoEditar         : this.campoEditar,
        valorEditar         : this.valorEditar

      };

      console.log(data);

      this.httpService.wait();
      this.httpService.post('/custom/fiscal/salvar-atualizacoes-livro', data)
      .subscribe(res => {

        if(res.error){
          this.displayMessage(res);




        }else{
          this.displayMessage(res);
          this.campoEditar = '';
          this.valorEditar = '';
          this.exibirAjuste = false;
          this.pedidosSelecionados = new Array();
          this.carregar();

          res.retorno.forEach(element => {

            this.LogManagement.registerLog(
              LogModule.FISCAL,
               LogScreen.TAX_BOOK_EDIT,
               '/salvar-atualizacoes-livro',
               LogAction.EDIT,
               element.nfeId,
               element.tipoId.toString(),
               'registros fiscais alterados',
               ('id: ' + element.id + ' - campo_edit: ' + element.campoEdit + " - valor: " + element.valorEdit)
           );

          });


        }
        },
        error => this.httpService.handleError(error, () => this.salvarAlteracoes()),
        () => this.httpService.done());

    }

  }


  displayMessage(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }


}
