import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, SelectItem } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-parametros-vendas',
  templateUrl: './parametros-vendas.component.html',
  styleUrls: [
    './parametros-vendas.component.scss'
  ],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ParametrosVendasComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;
  msgs: Message[] = [];
  vendedorObrigatorio: boolean = false;
  filtroDataRelatorioVendas: string = "dt_emissao";
  opcoesFiltroRelatorioVendas: SelectItem[] = [
    {label: "Data emissão", value: "dt_emissao"},
    {label: "Data entrega", value: "dt_entrega"},
    {label: "Data entrada/saída", value: "dt_faturamento_automatico"}
  ];
  filtroDataPedido: string = "dt_emissao";
  opcoesFiltroPedido: SelectItem[] = [
    {label: "Data emissão", value: "dt_emissao"},
    {label: "Data entrega", value: "dt_entrega"},
    {label: "Data entrada/saída", value: "dt_faturamento_automatico"}
  ];

  constructor(
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService
  ) { }

  public toolbarMain = new Toolbar();

  toolbarMainIni = [
    { key: 'save', icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'default', onClick: () => this.salvarVendedorObrigatorio() },
    {
     key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideFluxoProv()
        this.changeStateConfigFluxComissao()
      }
    },
  ]

  ngOnInit() {
    this.toolbarService.hidden = true;
    this.toolbarMain.setAll(this.toolbarMainIni);
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.sessionService.loggedUser.id) {
          this.loadParametro();
        }
      }
    );
  }
  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  hideMessage() {
    this.msgs = [];
  }

  currentStateConfigFluxComissao = 'initial';
  changeStateConfigFluxComissao() {
    this.currentStateConfigFluxComissao = this.currentStateConfigFluxComissao === 'initial' ? 'final' : 'initial';
  }

  loadParametro() {
    this.httpService.wait();
    this.httpService.get('custom/financeiro/buscar-parametro-venda', [this.dblinkedSessionService.empresa.id])
      .subscribe(
        retorno => {
          this.vendedorObrigatorio = retorno.vendedorObrigatorio;
          this.filtroDataRelatorioVendas = retorno.filtroDataRelatorioVendas;
          this.filtroDataPedido = retorno.filtroDataPedido;
        },
        error => this.httpService.handleError(error, () => this.loadParametro()),
        () => this.httpService.done());

  }

  /*Show Hide Config Flux Caixa Prov*/
  isCardFluxoProv: boolean = true;
  ShowHideFluxoProv() {
    this.isCardFluxoProv = !this.isCardFluxoProv;
    this.toolbarMain.setIcon('expand', this.isCardFluxoProv ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('expand', this.isCardFluxoProv ? 'Ver menos' : 'Ver mais')
  }

  salvarVendedorObrigatorio() {
    if (confirm('Deseja realmente atualizar esses paramêtros?')) {
      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        vendedorObrigatorio: this.vendedorObrigatorio,
        filtroDataRelatorioVendas: this.filtroDataRelatorioVendas,
        filtroDataPedido: this.filtroDataPedido
      };

      this.httpService.wait();
      this.httpService.post('/custom/financeiro/salvar-parametro-vendedor', infos)
        .subscribe(rst => {
          if (rst.error) {
            this.msgs = [{
              severity: 'warn',
              summary: 'MSG: ',
              detail: rst.message
            }];
          } else {
            this.msgs = [{
              severity: 'success',
              summary: 'MSG: ',
              detail: rst.message
            }];
          }
        }, error => this.httpService.handleError(error, () => this.salvarVendedorObrigatorio()),
          () => this.httpService.done());
    }
  }
}
