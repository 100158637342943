import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { DH_NOT_SUITABLE_GENERATOR } from 'constants';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Util } from 'app/shared/common/util';
import { isNullOrUndefined } from 'util';
import { TabelaCrm} from '../model/tabela-crm.model';
import { TabelaCrmDetalhe} from '../model/tabela-crm-detalhe.model';
import { Message, MessageService, SelectItem } from 'primeng/api';



@Component({
  selector: 'app-tabela-crm-carga',
  templateUrl: './tabela-crm-carga.component.html',
  styleUrls: ['./tabela-crm-carga.component.scss'],
  providers: [CrudService]
})
export class TabelaCrmCargaComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;



  checkedAll = false;

  statusEvento: SelectItem[];

  listTabelaCrmCarga  : Array<any> = new Array();
  opcoesComboStatus : Array<any> = new Array();
  desabilitaSelecao : boolean = false;
  idLista : Array<any> = new Array();
  modalAtualizarTabelaPorPeriodo : boolean = false;

  dataIni: Date;
  dataFin: Date;

  boolModalEditar: boolean = false;
  boolModalNew   : boolean = false;
  opcoesTabelaCrm : SelectItem[] = [];
  tabelaCrmId : number = 0;
  tabelaCrm = new TabelaCrm();
  tabelaCrmDetalhe = new TabelaCrmDetalhe();
  showTabela : boolean = true;
  tColunas : any[] = [];

  tValores : any[] = [];
  colunasValores : any[] = [];
  exibirTabela :boolean = false;


  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'CRM';
    this.subtitle = 'Tabela Crm Detalhe ';
    this.titleService.title = 'Tabela Crm Detalhe';

    this.defaultDate = new Date();
    this.dataIni = null;
    this.dataFin = null;
    this.dataFinSearch = null;
    this.dataIniSearch = null;
    this.idLista = new Array();

    this.tabelaCrmDetalhe = new TabelaCrmDetalhe();
    this.tabelaCrmDetalhe.tabelaCrm = new TabelaCrm();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();

    this.checkedAll = false;

    this.modalAtualizarTabelaPorPeriodo = false;


    this.tColunas = [];


    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.exibirTabela = false;
        this.toolbarEnable();
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

            const mes =
            this.dblinkedSessionService.periodo.month.value.toString.length === 2
              ? this.dblinkedSessionService.periodo.month.value
              : '0' + this.dblinkedSessionService.periodo.month.value;
          const dt =
            this.dblinkedSessionService.periodo.year.value +
            '-' +
            mes +
            '-01 12:00:00';
          this.defaultDate = new Date(dt);
          this.getComboTabelaCrm();
          this.showTabela = true;
          this.boolModalNew = false;
          this.boolModalEditar = false;


        }
        setTimeout(() => {
          this.configuraToolBar()
        }, 1000);

      });

  }


  hideMessage() {


  }

  configuraToolBar() {
    if (!isNullOrUndefined(this.idLista) && this.idLista.length === 1 ) {
      this.toolbarService.alterarStatus('edit', false, 'Editar Registro', () => this.modalEditar(this.idLista[0]));
      this.toolbarService.alterarStatus('add', false, 'Novo Registro', () => this.novoRegistro() );
      this.toolbarService.alterarStatus('remove', false, 'Excluir registro', () => '' );
    } else if( !isNullOrUndefined(this.idLista) && this.idLista.length > 1){
      this.toolbarService.alterarStatus('add', false, 'Novo Registro', () => this.novoRegistro() );
      this.toolbarService.alterarStatus('edit', true, 'Editar Registro', () => this.modalEditar(this.idLista[0]));
      this.toolbarService.alterarStatus('remove', false, 'Excluir registro', () => '' );
    } else{
      this.toolbarService.alterarStatus('edit', true, 'Editar Registro', () => '');
      this.toolbarService.alterarStatus('add', true, 'Atualizar Tabela', () => this.novoRegistro() );
      this.toolbarService.alterarStatus('remove', true, 'Excluir registro', () => '' );

    }

  }

  onFilterEvento(event: any) {
  }

  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('print');
    //this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');

    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.buscarColunasTabelaCrmCarga()
    );
    //this.toolbarService.replace('save', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
    this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar Registro', () => '');
    this.toolbarService.replace('add', true, 'class-new-button', 'SELECIONE', 'SELECIONE',   () => this.novoRegistro()     );

  }


  listar() {
    this.checkedAll = false;
    this.idLista = new Array();

    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();


      setTimeout(()=> this.configuraToolBar(), 500);

  }


  ngOnDestroy(): void {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

selectCheckbox(id: any, event: any) {

 if (event.selected) {
    event.selected = true;
    const index = this.idLista.findIndex(i => i === id);
    this.idLista.splice(index,1);

 } else {
    this.idLista.push(id);
 }

 setTimeout(()=> this.configuraToolBar(), 500);

}

selectTodos(event: any) {
  this.checkedAll = !this.checkedAll;
  this.idLista = new Array();
  this.listTabelaCrmCarga.forEach(i=> {
    if (!this.checkedAll) {
      i.selected = false;
      this.idLista.slice(i.id, 1);
    } else {
      if (i.desabilitaSelecao === false) {
        i.selected = true;
        this.idLista.push(i.id);

      }
    }
  });

  setTimeout(() => this.configuraToolBar() ,100)

}


openModalPeriodo() {

  this.modalAtualizarTabelaPorPeriodo = true;
  this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
  this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
}




modalEditar(id : any){
  this.showTabela = false;
  this.boolModalEditar = true;
  this.tabelaCrmDetalhe = new TabelaCrmDetalhe();
  this.tabelaCrmDetalhe.tabelaCrm = new TabelaCrm();

  let index = this.listTabelaCrmCarga.findIndex(e=> e.id === id);
  this.tabelaCrmDetalhe.id = id;
  this.tabelaCrmDetalhe.campo = this.listTabelaCrmCarga[index].campo;
  this.tabelaCrmDetalhe.posicao = this.listTabelaCrmCarga[index].posicao;
  this.tabelaCrmDetalhe.tabelaCrmId = this.listTabelaCrmCarga[index].tabelaCrmId;
  this.tabelaCrmDetalhe.tabelaCrm.id = this.listTabelaCrmCarga[index].tabelaCrmId;


}

  novoRegistro(){
    console.log('estou aqui');
    this.modalAtualizarTabelaPorPeriodo = true;


  }



  buscarColunasTabelaCrmCarga(){
    this.exibirTabela = false;

    this.tColunas = [];
    setTimeout(() => {
      this.configuraToolBar()
    }, 500);
    this.httpService.wait();
    this.httpService.get('/custom/crm/get-lista-cabecalho-coluna-tabela-crm-carga(' + [this.tabelaCrmId ] +')')
      .subscribe(retorno => {
        this.tColunas = new Array();
        this.colunasValores = [];
        console.log('retorno: ' + JSON.stringify( retorno));


        retorno.forEach(element => {
          this.tColunas.push({field: element.field,  header: element.field}) ;

        });
        try {
          if (this.tColunas.length > 0) {
            this.exibirTabela = true;
          } else {
            this.exibirTabela = false;
          }
        } catch (error) {
          this.exibirTabela = false;
        }
        setTimeout(() => {
          this.configuraToolBar()
        }, 500);

      },
        error => this.httpService.handleError(error, () =>{ console.log(error); this.buscarColunasTabelaCrmCarga()}),
        () => this.httpService.done());



    this.idLista = new Array();
    this.httpService.wait();
    this.httpService.get('/custom/crm/get-lista-tabela-crm-carga(' + [this.tabelaCrmId ] +')')
      .subscribe(retorno => {
        console.log(retorno);

        this.listTabelaCrmCarga = new Array();
        this.tValores = new Array();

        this.dadosRetornados(retorno);


      },
        error => this.httpService.handleError(error, () => this.buscarColunasTabelaCrmCarga()),
        () => this.httpService.done());



       setTimeout(()=> this.configuraToolBar(), 500);

  }

  getComboTabelaCrm(){
    this.tabelaCrmId = 0;
    this.exibirTabela = false;
    this.httpService.wait();
    this.httpService.get('/custom/crm/get-combo-campos-tabela-crm(' + [this.dblinkedSessionService.empresa.id ] +')')
      .subscribe(retorno => {
        this.opcoesTabelaCrm = [];
        this.opcoesTabelaCrm.push({value: '0', label: 'SELECIONE UMA TABELA'});

        retorno.forEach(element => {

          this.opcoesTabelaCrm.push({value: element.id, label: element.descricao});

        });


      },
        error => this.httpService.handleError(error, () => this.getComboTabelaCrm()),
        () => this.httpService.done());

        setTimeout(()=> this.configuraToolBar(), 500);

  }


  formarColunas(item :any){
    item.forEach(element => {
      console.log(element)
    });
  }

  dadosRetornados(dados: Array<any> ){
    var colunas2 =[];
    var colunasValores1 = [];
    console.log(this.tColunas);


    dados.forEach(i =>{
      this.listTabelaCrmCarga.push(i);


    })




  }

  atuazliarPeriodo(){
    this.modalAtualizarTabelaPorPeriodo = true;


    if (isNullOrUndefined(this.dataIni) || this.dataIni.toString() ==='') {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Favor selecionar uma data inicial. Verifique!' });
      return;

    }

    if (isNullOrUndefined(this.dataFin) || this.dataFin.toString() ==='') {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Favor selecionar uma data final. Verifique!' });
      return;
    }


    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() +1 > 8 ?
                    (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() +1 > 8 ?
                    (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
                    this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
                    this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }


    const d1 = new Date(this.dataIniSearch);
    const d2 = new Date(this.dataFinSearch);
    this.modalAtualizarTabelaPorPeriodo = false;
    if (d1 > d2) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Data incial maior que a final. Verifique!' });
      return;
    }

    let listasEnviar = {
      tabelaCrmId : this.tabelaCrmId,
      dataIniSearch : this.dataIniSearch,
      dataFinSearch : this.dataFinSearch
    };


    this.httpService.wait();
    this.httpService.post('/custom/crm/atualizar-tabela-crm-carga', listasEnviar)
    .subscribe(
      (res) => {


        if (res["error"] === false) {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'Sucesso',
            detail: res["message"]
          });
          this.buscarColunasTabelaCrmCarga();
          this.modalAtualizarTabelaPorPeriodo = false;
        } else{
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: res["message"] });
        }
      },
      error => {
        console.log(error);
        this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Erro." });
        this.httpService.done();
      },
      () => {
        this.httpService.done();
      }
    );


  }

}
