import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Moment } from 'moment';
import { Period } from 'app/shared/components/period-select/period.model';
import { TitleService } from 'app/shared/services/title.service';
import { HttpService } from 'app/shared/services/http.service';
import { ConfigService } from 'app/shared/services/config.service';
import { UtilService } from 'app/shared/services/util.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Empresa } from 'app/cadastro/model/empresa.model';

@Component({
  selector: 'app-cte',
  templateUrl: './cte.component.html',
  styleUrls: ['./cte.component.scss'],
})
export class CteComponent implements OnInit, OnDestroy {
  columns: Array<any>; // TODO: type
  rows: Array<any>; // TODO: type

  url: string;
  filters: string;

  tabIndex = 0;

  private empresaSubscription: Subscription;

  private _hasChecked = false;
  private allChecked = false;

  period: Period;
  titlePrincipal: string;
  subtitle: string;

  constructor(private titleService: TitleService,
              private httpService: HttpService,
              private utilService: UtilService,
              private toolbarService: ToolbarService,
              private dblinkedSessionService: DblinkedSessionService) {
  }

  ngOnInit() {
    this.titlePrincipal = 'CTE';
    this.subtitle = 'CONHECIMENTO DE TRANSPORTE ELETRÔNICO';
    // this.toolbarRefresh();
    this.titleService.title = 'Conhecimento de Transporte Eletrônico';

    this.empresaSubscription = this.dblinkedSessionService.empresaChanged.subscribe(() => {
      this.getData();
    });
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.getData();
    }
  }

  ngOnDestroy() {
    // this.toolbarService.clear();

    if (this.empresaSubscription) {
      this.empresaSubscription.unsubscribe();
    }
  }

  tabChange() {
    this.hasChecked = false;
    this.allChecked = false;

    // this.period = null;

    this.getData();
  }

  periodChange() {
    this.toolbarRefresh();
  }

  getData() {
    if (!this.dblinkedSessionService.hasEmpresaSelected) {
      // this.toolbarService.clear();
      return;
    }

    this.url = '/cte?$select=key,oper,series,document,emission';

    switch (this.tabIndex) {
      case 0: // Destinadas
        this.columns = [{ key: 'key', type: '', label: 'Chave', fixed: false },
                        { key: 'oper', type: '', label: 'Operacão', fixed: false },
                        { key: 'series', type: '', label: 'Série', fixed: false },
                        { key: 'document', type: '', label: 'Número', fixed: false },
                        { key: 'issuerCompany.name', type: '', label: 'Emitente', fixed: false },
                        { key: 'issuerCompany.cnpjOrCpf', type: '', label: 'Cnpj/Cpf', fixed: false },
                        { key: 'emission', type: 'datetime', label: 'Emissão', fixed: false, filter: 'year-month' }];

        this.url += ',issuerCompany/cnpjOrCpf,issuerCompany/name';
        this.url += '&$filter=receiverCompany/cnpjOrCpf eq \'' + this.dblinkedSessionService.empresa.cnpj + '\'';
        break;
      case 1: // Emitidas
        this.columns = [{ key: 'key', type: '', label: 'Chave', fixed: false },
                        { key: 'oper', type: '', label: 'Operacão', fixed: false },
                        { key: 'series', type: '', label: 'Série', fixed: false },
                        { key: 'document', type: '', label: 'Número', fixed: false },
                        { key: 'receiverCompany.name', type: '', label: 'Destinatário', fixed: false },
                        { key: 'receiverCompany.cnpjOrCpf', type: '', label: 'Cnpj/Cpf', fixed: false },
                        { key: 'emission', type: 'datetime', label: 'Emissão', fixed: false, filter: 'year-month' }];

        this.url += ',receiverCompany/name,receiverCompany/cnpjOrCpf';
        this.url += '&$filter=issuerCompany/cnpjOrCpf eq \'' + this.dblinkedSessionService.empresa.cnpj + '\'';
        break;
    }

    this.toolbarRefresh();
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  export() {
    /*let ids: Array<number> = new Array();
    this.rows.filter((row: any) => row.__selected)
             .forEach((row: any) => {
               ids.push(row.id);
             });*/

    this.httpService.wait();
    this.httpService.post('/custom/xml/export',
                          { type: 'cte',
                            operationId: this.tabIndex, // TODO: Review (ver emissão própria e outros)
                            cnpj: this.empresa.cnpj,
                            year: /*this.allChecked ? */this.period.year.value/* : null*/,
                            month: /*this.allChecked ? */this.period.month.value/* : null*/,
                            ids: /*!this.allChecked ? ids : */null
                          })
                    .subscribe(result => this.exported(result),
                               error => this.httpService.handleError(error,
                                                                     () => this.export(),
                                                                     (message: any) => this.displayErrorMessage(message)),
                               () => this.httpService.done());
  }
  exported(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file), '_blank');
    } else {
      alert(result.message); // TODO: Use Material dialog
    }
  }

  displayErrorMessage(error: String | any) {
    alert(typeof error === 'string' ? error : error.message);
  }

  filterChange(filter: any) {
    if (filter.key === 'emission' && filter.value) {
      // this.month = filter.value.month.value || null;
      // this.year = filter.value.year.value || null;

      // this.filters = 'year(emission) eq ' + this.year + ' and month(emission) eq ' + this.month;

      this.toolbarRefresh();
    }
  }

  get hasChecked(): boolean {
    return this._hasChecked;
  }
  set hasChecked(hasChecked: boolean) {
    this._hasChecked = hasChecked;

    this.toolbarRefresh();
  }

  private toolbarRefresh() {
    // this.toolbarService.remove('export');
    if (/*this._hasChecked && */this.period && this.period.year && this.period.month) {
      this.toolbarService.alterarStatus('export', false, 'Exportar', () => this.export());

      /* this.toolbarService.add('export', false, 'cloud_download', 'Exportar', 'Exportar', () => this.export());
      this.toolbarService.add('export', false, 'class-export-button', 'description', 'Exportar', () => this.export());
      this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir', () => this.export());
      this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.export());
      this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!', () => this.export());
      this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar', () => this.export());*/
    } else {
      this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.export());

      /*this.toolbarService.clear();
      this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
      this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
      this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
      this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');*/
    }
  }
}
