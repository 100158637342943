import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MessageService } from '../../../../node_modules/primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-config-prioridade',
  templateUrl: './prioridade.component.html',
  styleUrls: ['./prioridade.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ConfigPrioridadeComponent implements OnInit, OnDestroy {
  ////////////////////////////////////////
  // REFATOR SIMON LALLEMENT 21/12/2021 //
  ////////////////////////////////////////

  private sessionSubscription: Subscription

  //String
  titlePrincipal: string = 'Configurações RMS'
  subtitle: string = 'Prioridade'
  currentStatePrioridade: string = 'initial'
  descricaoPrioridade: string = null

  //Array
  listaPrioridades: Array<any> = new Array()
  PrioSelected: Array<any> = new Array()
  selectedRows: Array<any> = new Array()

  //Boolean
  isCardPrioridade: boolean = true
  modalPrioridade: boolean = false
  checkedAll: boolean = false
  edit: boolean = false
  add: boolean = false

  //Miscellaneous
  prioridadeSelecionada: any = {}

  //Number
  indicePrioridade: number = null

  //Toolbar
  toolbarMainIni = [
    { icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: false, disabled: false, color: 'red', onClick: () => this.excluirProridade() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openEditPrioridade() },
    { icon: 'add', text: 'Nova', tooltip: 'Nova', visible: true, disabled: false, color: 'green', onClick: () => this.openAddPrioridade() },
  ]
  public toolbarMain = new Toolbar()

  constructor(
    private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.titleService.title = 'Linnks - Prioridade RMS'
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.getPrioridades()
      }
    })
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  changeStatePrioridade() {
    this.currentStatePrioridade = this.currentStatePrioridade === 'initial' ? 'final' : 'initial';
  }

  ShowHidePrioridade() {
    this.isCardPrioridade = !this.isCardPrioridade
  }

  openAddPrioridade() {
    this.modalPrioridade = true
    this.descricaoPrioridade = null
    this.indicePrioridade = null
    this.add = true
    this.edit = false
  }

  openEditPrioridade() {
    this.modalPrioridade = true
    this.prioridadeSelecionada = this.listaPrioridades.find(element => this.selectedRows[0].id === element.id)
    this.descricaoPrioridade = this.prioridadeSelecionada.nome
    this.indicePrioridade = this.prioridadeSelecionada.indice
    this.edit = true;
  }

  adicionar() {
    if (this.validar(true)) return

    let listaEnviar = {
      nomeTipo: this.descricaoPrioridade,
      indice: this.indicePrioridade,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait();
    this.httpService.post('/custom/rms/adicionar-prioridade', listaEnviar).subscribe(rst => {
      if(rst.error){this.exibirMensagem({ severity: "error", message: rst.detail })}
      else{
        this.exibirMensagem({ severity: "success", message: rst.detail })
        this.getPrioridades()
        this.modalPrioridade = false
        this.add = false
      }
    },
    error => {
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar a prioridade, contate o suporte : " + error.message })
    },
    () =>  this.httpService.done())
  }

  getPrioridades() {
    this.httpService.wait()
    this.httpService.get('/custom/rms/listar-prioridade', this.dblinkedSessionService.empresa.id)
      .subscribe(rst => {
        this.listaPrioridades = new Array()
        rst.forEach(element => { this.listaPrioridades.push(element) })
        this.httpService.done()
        this.resetSelected()
      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao recuperar as prioridades, contate o suporte : " + error.message })
          this.httpService.done()
          this.resetSelected()
        })

  }

  excluirProridade() {
    if (confirm("Deseja remover as prioridades selecionadas?")) {

      let listaEnviar = {
        id: this.selectedRows.map(item => item.id)
      }
      this.httpService.wait();
      this.httpService.post('/custom/rms/deletar-prioridade', listaEnviar)
        .subscribe(rst => {
          this.httpService.done()

          if(rst?.error) {
            return this.exibirMensagem(rst);
          }

          this.getPrioridades()
          this.checkToolBar()
          this.resetSelected()

        }, error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao excluir as prioridades, contate o suporte : " + error.message })
          this.httpService.done()
          this.resetSelected()
        })

    }
  }

  validar(edit: boolean): boolean {
    if (!this.descricaoPrioridade) {
      this.exibirMensagem({ severity: 'warn', mensagem: "O campo Descrição é obrigatório." })
      return true
    }

    if (!this.indicePrioridade) {
      this.exibirMensagem({ severity: 'warn', mensagem: "O campo Índice é obrigatório." })
      return true
    }

    if (this.listaPrioridades.map(item => { return item.indice }).includes(this.indicePrioridade) && !edit) {
      this.exibirMensagem({ severity: 'warn', mensagem: "O índice deve ser único." })
      return true

    } else if (this.prioridadeSelecionada.indice !== this.indicePrioridade && this.listaPrioridades.map(item => { return item.indice }).includes(this.indicePrioridade) && edit) {
      this.exibirMensagem({ severity: 'warn', mensagem: "O índice deve ser único." })
      return true
    }

    let lista = this.listaPrioridades.map(item => { return this.stringComparavel(item.nome) })

    if((this.edit ? this.stringComparavel(this.prioridadeSelecionada.nome) !== this.stringComparavel(this.descricaoPrioridade): true) && lista.includes(this.stringComparavel(this.descricaoPrioridade))) {
      this.exibirMensagem({ severity: 'warn', mensagem: "A descrição deve ser única." })
      return true
    }

    return false
  }

  stringComparavel(string: string): string {
    return string.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z._ ])/g, '').toLowerCase().trim()
  }

  atualizar() {
    if (this.validar(true)) return

    let listaEnviar = {
      id: this.prioridadeSelecionada.id,
      nome: this.descricaoPrioridade,
      indice: this.indicePrioridade,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id
    }


    this.httpService.wait();
    this.httpService.post('/custom/rms/atualizar-prioridade', listaEnviar).subscribe(rst => {
      if(rst.error){this.exibirMensagem({ severity: "error", message: rst.detail })}
      else{
        this.exibirMensagem({ severity: "success", message: rst.detail })
        this.checkToolBar()
        this.getPrioridades()
        this.modalPrioridade = false
        this.edit = false
      }

    }, error => {
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar a prioridade, contate o suporte : " + error.message })
      this.resetSelected()
    },
    () =>  this.httpService.done())

  }

  resetSelected() {
    this.selectedRows = [];
    this.edit = false;
    this.checkToolBar();
  }

  checkToolBar() {
    this.edit = false;
    this.toolbarMain.setVisible('btn1', this.selectedRows.length !== 0);
    this.toolbarMain.setVisible('btn2', this.selectedRows.length === 1);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}
