export class Cfop {
  id: number;
  cfop: string;
  descricao: string;

  constructor(cfop?: Cfop | number) {
    if (cfop !== undefined && cfop !== null) {
      if (typeof cfop === 'number') {
        this.id = cfop;
      } else {
        this.id = cfop.id;
        this.cfop = cfop.cfop;
        this.descricao = cfop.descricao;
      }
    }
  }
}
