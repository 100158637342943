export class RegimeTributario {
  id: number;
  descricao: string;

  constructor(regimeTributario?: RegimeTributario | number) {
    if (regimeTributario !== undefined && regimeTributario !== null) {
      if (typeof regimeTributario === 'number') {
        this.id = regimeTributario;
      } else {
        this.id = regimeTributario.id;
        this.descricao = regimeTributario.descricao;
      }
    }
  }
}
