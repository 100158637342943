import { View } from './view.model';

export class ViewCampos {

  id: number;
  campo: string;
  descricao: string;
  tipo: string;
  view: View;
  aliasCampo: string;

constructor(viewcampos?: ViewCampos | number) {
    if (viewcampos !== undefined && viewcampos !== null) {
      if (typeof viewcampos === 'number') {
        this.id = viewcampos;
      } else {
        this.id = viewcampos.id;
        this.campo = viewcampos.campo;
        this.descricao = viewcampos.descricao;
        this.tipo = viewcampos.tipo;
        this.view = viewcampos.view || new View();
        this.aliasCampo = viewcampos.aliasCampo;
      }
    }
  }
}
