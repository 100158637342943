import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cep'
})

export class CepPipe implements PipeTransform{
	transform(value: string): string{
		if (value !== undefined && value !== null && value.toString().trim() !== '') {
			return value.substr(0, 5) + '-' + value.substr(5, 3);
		}
		return null;
	}
}