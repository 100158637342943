import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CadProj } from '../model/cad-proj.model';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { UtilService } from 'app/shared/services/util.service';
import exportExcel from 'app/util/processing/exportExcel'
import { Toolbar } from 'app/shared/model/toolbar.model';

interface LeiDoBem{
  name: string,
  code: number | boolean
}

@Component({
  selector: 'app-cad-proj',
  templateUrl: './cad-proj.component.html',
  styleUrls: ['./cad-proj.component.scss'],
  providers: [CrudService]
})
export class CadProjComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public cadProj: CadProj = new CadProj();
  public projetos: Array<CadProj> = new Array();
  selectProj:  Array<any> = new Array();
  msgs: Message[];
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;
  codigo: string;
  descricao: string;
  dataInicial: string;
  dataFinal: string;
  ativo: boolean = false;
  leiDoBem: boolean = false;
  ids: Array<any> = new Array();

  title = 'Cadastro Projeto';
  subtitle = "projeto";
  entityPath = 'cadProj';
  params = '?$select=codProj,descrProj,dtInicial,dtFinal,lei_do_bem,ativo&$count=true';
  skip = 0;
  top = 50;
  readonly = false;

  opcoes: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
  exportarProjeto = ['codProj', 'descrProj', 'dtInicialFormatoBrasil', 'dtFinalFormatoBrasil', 'leiDoBemLabel', 'ativoLabel']

  selectedAtivo: LeiDoBem;

  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeViewSubscription: Subscription;
  private beforeEditSubscription: Subscription;

  entityFilter: string = null;


  public toolbarMain = new Toolbar();

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private utilService: UtilService,
    private configService: ConfigService,
    private toolbarService: ToolbarService) {
    super(crudService);
  }

  public toolbarCadProj = new Toolbar()

  toolbarMainCadProj = [
    { key: "atualizar", icon: 'replay', text: 'Atualizar', tooltip: 'Atualizar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: "voltar", icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: "edit", icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { key: "delete", icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { key: "add", icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { key: "saveNew", icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { key: "save", icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
    { key: "export", icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar excel', visible: true, disabled: false, color: 'default', onClick: () => exportExcel(this.projetos, 'projetos', this.exportarProjeto) },
  ]

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.toolbarService.hidden = true
    this.toolbarCadProj.setAll(this.toolbarMainCadProj)
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.carregar()
        }
      }
    );
  }

  carregar(){
    this.title = 'CADASTRO PROJETO';
    this.subtitle = 'PROJETO';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.selectProj = new Array()

    this.toolbarInitial()

    this.limpaCamposForm();

    this.loadRegistros();
  }

  toolbarInitial() {
    this.toolbarCadProj.setVisible('atualizar', true)
    this.toolbarCadProj.setVisible('voltar', false)
    this.toolbarCadProj.setVisible('edit', false)
    this.toolbarCadProj.setVisible('delete', false)
    this.toolbarCadProj.setVisible('add', true)
    this.toolbarCadProj.setVisible('saveNew', false)
    this.toolbarCadProj.setVisible('save', false)
  }

  toolbarNew() {
    this.toolbarCadProj.setVisible('atualizar', false)
    this.toolbarCadProj.setVisible('voltar', true)
    this.toolbarCadProj.setVisible('edit', false)
    this.toolbarCadProj.setVisible('delete', false)
    this.toolbarCadProj.setVisible('add', false)
    this.toolbarCadProj.setVisible('saveNew', true)
    this.toolbarCadProj.setVisible('save', false)
  }

  toolbarEdit() {
    this.toolbarCadProj.setVisible('atualizar', false)
    this.toolbarCadProj.setVisible('voltar', true)
    this.toolbarCadProj.setVisible('edit', false)
    this.toolbarCadProj.setVisible('delete', true)
    this.toolbarCadProj.setVisible('add', false)
    this.toolbarCadProj.setVisible('saveNew', false)
    this.toolbarCadProj.setVisible('save', true)
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastro Projeto';

    this.toolbarNew()

  }

  loadRegistros(){
    this.httpService.wait();
    this.httpService
    .get(`/cadproj?$select=codProj,descrProj,dtInicial,dtFinal,lei_do_bem,ativo&$count=true&$filter=empresa/id eq
            ${this.dblinkedSessionService.empresa.id}&$orderby=codProj`)
    .subscribe(
      (retorno) => {
          this.projetos = new Array();
          retorno.value.forEach(item => {
            this.projetos.push({
              ...item,
              dtInicialFormatoBrasil: this.converteDataParaFormatoBrasileiro(item.dtInicial),
              dtFinalFormatoBrasil: this.converteDataParaFormatoBrasileiro(item.dtFinal),
              ativoLabel: item.ativo ? 'Ativo' : 'Inativo',
              leiDoBemLabel: item.lei_do_bem ? 'Sim' : 'Não'
            });
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editando Conta Contábil';
    this.editarConta = true;
    this.selectProj = []

    this.toolbarEdit()

    this.limpaCamposForm();

    this.httpService.wait();
    this.httpService.get(`/cadproj(${this.ids[0]})?$select=codProj,descrProj,dtInicial,dtFinal,ativo,lei_do_bem&$count=true`)
      .subscribe(plano => {
        this.codigo = plano.value.codProj;
        this.descricao = plano.value.descrProj;
        this.dataInicial = this.adicionaUmDia(plano.value.dtInicial);
        this.dataInicial = this.converteDataParaFormatoBrasileiro(this.dataInicial);
        this.dataFinal = this.adicionaUmDia(plano.value.dtFinal);
        this.dataFinal = this.converteDataParaFormatoBrasileiro(this.dataFinal);
        this.ativo = plano.value.ativo;
        this.leiDoBem = plano.value.lei_do_bem;
      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      ativo: this.ativo,
      codProj: this.codigo,
      descrProj: this.descricao,
      dtInicial: this.dataInicial,
      dtFinal: this.dataFinal,
      lei_do_bem: this.leiDoBem,
      empresa: {
        id: this.dblinkedSessionService.empresa.id
      },
    };

    this.httpService.wait();
    this.httpService.post('/cadproj?$select=codProj,descrProj,dtInicial,dtFinal,lei_do_bem,ativo&$count=true&$top=50', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto cadastrado com sucesso!'
            });
            this.carregar();
        }else{
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
            });
            this.carregar();
            return;
        }
      }, error => this.httpService.handleError(error, () =>  this.salvarNovoRegistro()),
      () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const listasEnviar = {
      id: this.ids[0],
      codProj: this.codigo,
      descrProj: this.descricao,
      dtInicial: this.converteDataParaFormatoAmericano(this.dataInicial),
      dtFinal: this.converteDataParaFormatoAmericano(this.dataFinal),
      ativo: this.ativo,
      lei_do_bem: this.leiDoBem,
    };

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/cadproj', key, listasEnviar, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do projeto!'
          });
          this.carregar();
          return;
        }
      },
      error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
      () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      const listasEnviar = {
        idsContaContabil: this.ids,
      };
      this.httpService.wait();
      this.httpService.delete('/cadproj', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Projeto excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
    }
  }

  changeToolbar(){
    this.toolbarCadProj.setVisible('edit', true)
    this.toolbarCadProj.setVisible('delete', true)
  }

  changeToolbarUn(){
    this.toolbarCadProj.setVisible('edit', false)
    this.toolbarCadProj.setVisible('delete', false)
  }

  onRowSelect(event: any){
    this.ids = new Array()
    this.ids.push(event.data.id);
    event.selected = false;
    this.changeToolbar()
  }

  onRowUnselect(event: any){
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.changeToolbarUn()
  }

  validacao(): boolean {
    if ((this.codigo === null || this.codigo === undefined) || this.codigo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório' });
      return true;
    }
    if ((this.descricao === null || this.descricao === undefined) || this.descricao.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição é Obrigatória' });
      return true;
    }
    if ((this.dataInicial === null || this.dataInicial === undefined) || this.dataInicial.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data Inicial é Obrigatória' });
      return true;
    }

    if ((this.dataFinal === null || this.dataFinal === undefined) || this.dataFinal.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data Final é Obrigatória' });
      return true;
    }

    return false;
  }

  private converteDataParaFormatoBrasileiro(milisegundos: string) {
    const d = new Date(milisegundos);

    return this.utilService.dateToStringDate(d);
  }

  private adicionaUmDia = (milisegundos) => milisegundos + 86400000;

  private converteDataParaFormatoAmericano(data){
    let formatoBrasileiro;

    if(typeof data === 'object'){//GMT...
      const d = new Date(data);
      formatoBrasileiro = this.utilService.dateToStringDate(d);
    }else{//dd-mm-YYYY
      formatoBrasileiro = data;
    }

    const [dia, mes, ano] = formatoBrasileiro.split('/');

    return `${ano}-${mes}-${dia}`;
  }

  private limpaCamposForm(){
    this.codigo = '';
    this.descricao = '';
    this.dataInicial = '';
    this.dataFinal = '';
    this.ativo = false;
    this.leiDoBem = false;
  }

  hideMessage() {

  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    // this.dblinkedSessionService.hidePeriods();
    this.sessionSubscription.remove(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }

}
