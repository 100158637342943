import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-cadastro-gestor',
  templateUrl: './gestor-centro-custo.component.html',
  styleUrls: ['./gestor-centro-custo.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class GestorCentroCustoComponent implements OnInit, OnDestroy {
  ///////////////////////////////////////////
  // REFATOR POR SIMON LALLEMENT 08/07/2021//
  //////////////////////////////////////////

  private sessionSubscription: Subscription;

  // Messagens
  msgs: Message[] = [];

  // String
  titlePrincipal: string = null;
  subtitle: string = null;

  // Dropdown
  usuarios: SelectItem[] = [];
  listPg: SelectItem[] = [];

  // Booleans
  statusLink1 = true;
  statusLink2 = false;
  statusLink3 = false;

  // Number
  usuarioId = 0;
  pgId = 0;

  // Show/hide cards
  isCardCc = true;
  currentStateCc = 'initial';

  // List
  listaCentroCustoDispo: Array<any> = new Array();
  listaCentroCustoRelacionados: Array<any> = new Array();
  listaContasDispo: Array<any> = new Array();
  listaContasRelacionadas: Array<any> = new Array();
  listaAreasDisponiveis: Array<any> = new Array();
  listaAreasRelacionadas: Array<any> = new Array();


  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.toolbarService.hidden = true;
    this.titleService.title = 'Gestores';
    this.titlePrincipal = 'Configurações Orçamento';
    this.subtitle = 'Gestores';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected
      && this.dblinkedSessionService.hasPeriodoSelected
      && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {
      this.usuarioId = 0;
      this.listaCentroCustoDispo = new Array();
      this.listaCentroCustoRelacionados = new Array();
      this.listaContasDispo = new Array();
      this.listaContasRelacionadas = new Array();
      this.getListUsuarios();
      this.getPlanoGerenciais();
    }
  }

  getPlanoGerenciais() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/plano-gerencial', httpOptions)
      .subscribe(
        res => {
          if (res['error']) {
            this.displayMessage(res);
            //this.exibirMensagem(res);

          } else {
            this.listPg = [];
            res['data'].forEach(element => { this.listPg.push({ label: element.codigo + ' - ' + element.nome, value: element.id }); });
          }
          this.httpService.done();
        },
        err => {
          this.displayMessage(err);
          //his.exibirMensagem({ 'error': true, 'mensagem': 'Erro no API para carregar os planos gerenciais: ' + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getListUsuarios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/usuarios-empresa', httpOptions)
      .subscribe(
        res => {
          if (res['error']) {
            this.displayMessage(res);
            //this.exibirMensagem(res);

          } else {
            this.usuarios = [];
            res['data'].forEach(element => { this.usuarios.push({ label: element.full_name, value: element.id }); });
          }
          this.httpService.done();
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagem({ 'error': true, 'mensagem': 'Erro no API para carregar os usuários: ' + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  showTypeVinc(tipo) {
    this.statusLink1 = false;
    this.statusLink2 = false;
    this.statusLink3 = false;

    if (tipo === 'LINK_1') {
      this.statusLink1 = true;
    } else if (tipo === 'LINK_2') {
      this.statusLink2 = true;
    } else if (tipo === 'LINK_3') {
      this.statusLink3 = true;
    }
  }

  hideMessage() {
    this.msgs = [];
  }

  showHideCardCc() {
    this.isCardCc = !this.isCardCc;
  }

  changeStateCc() {
    this.currentStateCc = this.currentStateCc === 'initial' ? 'final' : 'initial';
  }

  salvarRelacionamentoCta() {
    if (this.listaContasRelacionadas.length === 0) {
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'userid': this.usuarioId.toString(),
        'pgid': this.pgId.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/usuario-cta-relac', this.listaContasRelacionadas, httpOptions)
      .subscribe(
        res => {
          this.displayMessage(res);
          //this.exibirMensagem(res);
          this.httpService.done();
          this.buscarCtaRelac();
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagem({ 'error': true, 'mensagem': 'Erro no API para salvar a vinculação: ' + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  salvarRelacionamentoCcus() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'userid': this.usuarioId.toString(),
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/usuario-ccus-relac', this.listaCentroCustoRelacionados, httpOptions)
      .subscribe(
        res => {
          this.displayMessage(res);
          this.httpService.done();
          this.buscarCcusRelac();
        },
        err => {
          this.displayMessage(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  buscarRelacsUsuario() {
    this.buscarCcusRelac();
    this.buscarAreas();
  }
  buscarCcusRelac() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'userid': this.usuarioId.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/usuario-ccus-relac', httpOptions)
      .subscribe(
        res => {
          if (res['error']) {
            this.displayMessage(res);
            //this.exibirMensagem(res);

          } else {
            this.listaCentroCustoRelacionados = new Array();
            this.listaCentroCustoDispo = new Array();
            res['data'][0].forEach(element => { this.listaCentroCustoRelacionados.push(element); });
            res['data'][1].forEach(element => { this.listaCentroCustoDispo.push(element); });
          }
          this.httpService.done();
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagem({ 'error': true, 'mensagem': 'Erro no API para carregar as vinculações: ' + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  buscarCtaRelac() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'pgid': this.pgId.toString(),
        'userid': this.usuarioId.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/usuario-cta-relac', httpOptions)
      .subscribe(
        res => {
          if (res['error']) {
            this.displayMessage(res);
            //this.exibirMensagem(res);

          } else {
            this.listaContasRelacionadas = new Array();
            this.listaContasDispo = new Array();
            res['data'][0].forEach(element => { this.listaContasRelacionadas.push(element); });
            res['data'][1].forEach(element => { this.listaContasDispo.push(element); });
          }
          this.httpService.done();
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagem({ 'error': true, 'mensagem': 'Erro no API para carregar as vinculações: ' + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  async salvarRelacionamentoArea() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        userid: this.usuarioId.toString(),
        empresaid: this.dblinkedSessionService.empresa.id.toString()
      })
    };

    const data = {
      areas_relac: this.listaAreasRelacionadas
    };

    try {
      this.httpService.wait();
      // tslint:disable-next-line: max-line-length
      const response = await this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/usuario-area-relac', data, httpOptions).toPromise();
      this.displayMessage(response);
      //this.exibirMensagem(response);

    } catch (error) {
      this.displayMessage(error);
      //this.exibirMensagem({ error: true, mensagem: 'Erro no API para carregar as áreas' });
    } finally {
      this.httpService.done();
    }
  }

  async buscarAreas() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        userid: this.usuarioId.toString(),
        empresaid: this.dblinkedSessionService.empresa.id.toString()
      })
    };

    try {
      this.httpService.wait();
      // tslint:disable-next-line: max-line-length
      const response = await this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/usuario-area-relac', httpOptions).toPromise();

      this.listaAreasRelacionadas = new Array();
      this.listaAreasDisponiveis = new Array();

      this.listaAreasRelacionadas = response['data'][0];
      this.listaAreasDisponiveis = response['data'][1];

    } catch (error) {
      this.displayMessage(error);
      //this.exibirMensagem({ error: true, mensagem: 'Erro no API para carregar as áreas' });
    } finally {
      this.httpService.done();
    }
  }

  //novo método para exibir resposta
  displayMessage(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }
}
