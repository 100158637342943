import { Component, OnInit, OnDestroy } from '@angular/core';
import { TitleService } from 'app/shared/services/title.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { UtilService } from 'app/shared/services/util.service';
import { HttpService } from 'app/shared/services/http.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PalestraCliente } from '../model/palestra-cliente.model';
import { Palestra } from '../model/palestra.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.scss']
})
export class CheckInComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  palestraCliente: any;
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private utilService: UtilService,
    public dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }

  // controles locais
  title: string;
  subtitle: string;
  msgs: Message[] = [];
  msgsModal: Message[] = [];
  pt_BR: any;

  checkInList: Array<any> = new Array();
  opcoesEvento: SelectItem[];
  eventoId: number;
  qtdeFaltantes: number;
  qtdePresentes: number;
  modalPalestraCliente : boolean = false;
  checkInId : number = 0;
  opcoesComboCheckin : SelectItem[];
  opcoesComboTipoIngresso : SelectItem[];
  colsCheckInList : any[];

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titleService.title = 'Check IN';
    this.title = 'CHECK IN';
    this.subtitle = '';
    this.eventoId = 0;
    this.qtdeFaltantes = 0;
    this.qtdePresentes = 0;

    this.checkInList = new Array<any>();





    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.modalPalestraCliente = false;

    this.colsCheckInList = [
      { field: 'presente', header: 'CHECK IN' },
      { field: 'nome', header: 'NOME' },
      { field: 'cpf', header: 'CPF' },
      { field: 'fone', header: 'FONE' },
      { field: 'email', header: 'E-MAIL' },
      { field: 'ingresso', header: 'INGRESSO' }

  ];

    this.subscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { setTimeout(() => { this.toolbarEnable(); }, 1000); this.start(); });
  }

  start() {


    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {

      this.toolbarEnable();



      // buscando as infos
      this.httpService.wait();
      this.httpService.get('/custom/evento/evento-check-in('+this.dblinkedSessionService.empresa.id+')')
        .subscribe(retorno => {
          this.opcoesEvento = [];
          this.opcoesEvento.push({ label: ('Selecione um evento'), value: 0 });
          retorno.forEach(element => {
          this.opcoesEvento.push({ label: (element.evento + ' - ' + element.data + ' - ' + element.cidade), value: element.id });
          });

        },
          error => this.httpService.handleError(error, () => this.start()),
          () => this.httpService.done());
      // final busca
    }

  }

  buscarParticipantesEvento(){

    // buscando as infos
    this.httpService.wait();
    this.httpService.get('/custom/evento/evento-check-in-part('+this.eventoId+')')
      .subscribe(retorno => {

        this.qtdePresentes = 0;
        this.qtdeFaltantes = 0;
        this.checkInList = new Array();
        this.opcoesComboCheckin       = [];
        this.opcoesComboTipoIngresso  = [];
        retorno.forEach(element => {
          this.checkInList.push(element);
          if(element.presente){
            this.qtdePresentes = this.qtdePresentes + 1;
          }else{
            this.qtdeFaltantes = this.qtdeFaltantes + 1;
          }

          var index = 0;
          if ((this.opcoesComboCheckin !== null || this.opcoesComboCheckin !== undefined)) {
            index = this.opcoesComboCheckin.findIndex(e => e.value === element.presente);
          }

          if (index === -1) {
            this.opcoesComboCheckin.push({label: element.presente , value: element.presente});
          }

          index = 0;
          if ((this.opcoesComboTipoIngresso !== null || this.opcoesComboTipoIngresso !== undefined)) {
            index = this.opcoesComboTipoIngresso.findIndex(e => e.value === element.ingresso);
          }

          if (index === -1) {
            this.opcoesComboTipoIngresso.push({label: element.ingresso , value: element.ingresso});
          }

          this.opcoesComboTipoIngresso.sort((a,b) => a.label.localeCompare(b.label) );

        });

      },
        error => this.httpService.handleError(error, () => this.buscarParticipantesEvento()),
        () => this.httpService.done());

  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.sessionService.destroySubscribe(this.subscription);
  }

  private toolbarEnable() {
    const todos = this.buttons.slice();
    if (todos.length === 0) {
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      // this.toolbarService.replace('edit', false,
      //                             'class-button-gray', 'ATUALIZAR', 'ATUALIZAR', () => this.openModalPeriodo());
      this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
      this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');
      this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo');
    } else {
      this.toolbarService.ocultar('voltar');
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.ocultar('save');
      this.toolbarService.ocultar('edit');
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      // this.toolbarService.replace('edit', false,
      //                             'class-button-gray', 'ATUALIZAR', 'ATUALIZAR', () => this.openModalPeriodo());
      this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
      //       this.toolbarService.alterarStatus('check', false, 'Aprovar todos', () => this.modalStatusPedidosLote());
      this.toolbarService.replace('pesquisa', true, 'class-edit-button', 'search', 'Pesquisar');
      this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar');
      this.toolbarService.replace('print', true, 'class-edit-button', 'print', 'Imprimir');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');
      this.toolbarService.replace('add', true, 'class-new-button', 'ATUALIZAR', 'ATUALIZAR');
    }
  }

  desfazerCheckIn(value: any){
    if (confirm('Deseja realmente desfazer este check-in?')) {
      this.httpService.wait();
      this.httpService.get('/custom/evento/evento-desfazer-check-in('+value.id+')')
        .subscribe(retorno => {
          this.buscarParticipantesEvento();
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: 'Check-In cancelado com sucesso!' });

        },
          error => this.httpService.handleError(error, () => this.fazerCheckIn(value)),
          () => this.httpService.done());
    }
  }

  fazerCheckIn(value: any){

    this.httpService.wait();
    this.httpService.get('/custom/evento/evento-fazer-check-in('+value.id+')')
      .subscribe(retorno => {
        this.buscarParticipantesEvento();

        this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: 'Check-In feito com sucesso!' });

      },
        error => this.httpService.handleError(error, () => this.fazerCheckIn(value)),
        () => this.httpService.done());

  }


  hideMessage() {


  }

  rowStyleClass(fichas: any): string {
    return fichas.status === 2 ||  fichas.status === 6 ? 'bold' : '';
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  showModalPalestraCliente(value : any){
    this.checkInId                = 0 ;
    this.palestraCliente          = new PalestraCliente();
    this.palestraCliente.palestra = new Palestra();

    this.palestraCliente.nomeCompleto = value.nome;
    this.palestraCliente.telefone     = value.fone;
    this.palestraCliente.email        = value.email;
    this.palestraCliente.cpf          = value.cpf;
    this.checkInId                    = value.id;
    this.palestraCliente.empresaNome  = value.empresa;
    this.modalPalestraCliente         = true;
  }

  salvarPalestraCliente(){

    if (this.validacaoPalestraCliente()) {
      return ;
    }

    let listaEnviar = {
      checkInId         : this.checkInId,
      eventoId          : this.eventoId,
      palestraCliente   : this.palestraCliente,
      empresaId         : this.dblinkedSessionService.empresa.id,
      usuarioId         : this.sessionService.loggedUser.id,
      isNovoCliente     : true

    };

      this.httpService.wait();
      this.httpService.post('/custom/polozi/salvar-palestra-cliente-checkin', listaEnviar)
        .subscribe(rst => {
          this.modalPalestraCliente=false;
          if (rst[0].error) {

            this.messageService.add({
              severity: 'error',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst[0].message
            });

          } else {
            this.buscarParticipantesEvento();
            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst[0].message
            });

          }

        },
          error => this.httpService.handleError(error, () => this.httpService.done()),
          () => this.httpService.done());

  }


  validacaoPalestraCliente(){

    if (isNullOrUndefined (this.palestraCliente.nomeCompleto) || this.palestraCliente.nomeCompleto.length < 3){
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor digitar o nome!'
      });
      return true;
    }

    if (isNullOrUndefined (this.palestraCliente.email) || this.palestraCliente.email ===''  ){
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor digitar um email!'
      });
      return true;
    }

    if (isNullOrUndefined (this.palestraCliente.telefone) || this.palestraCliente.telefone ==='(99) 99999-9999'  ){
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor digitar um telefone!'
      });
      return true;
    }


    if (!isNullOrUndefined (this.palestraCliente.telefone)  && this.palestraCliente.telefone.length < 10  ){
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor digitar um telefone!'
      });
      return true;
    }


    return false;
  }

}
