import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';

import { isNullOrUndefined } from 'util';
import { Palestra } from 'app/eventos/model/palestra.model';
import { PalestraCliente } from 'app/eventos/model/palestra-cliente.model';
import { PalestraClienteStatus } from 'app/eventos/model/palestra-cliente-status.model';
import { PalestraClienteObservacoes } from 'app/eventos/model/palestra-cliente-observacoes.model';
import { Time } from '@angular/common';
import { Message, MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-palestra-cliente',
  templateUrl: './palestra-cliente.component.html',
  styleUrls: ['./palestra-cliente.component.scss'],
  providers: [CrudService]
})
export class PalestraClienteComponent implements OnInit, OnDestroy {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  titlePrincipal: any;
  subtitle: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;

  modalFormularioMotivos: boolean = false;
  modalPalestraCliente: boolean = false;
  modalVisualizacaoInf: boolean = false;
  listaMotivosRecusa: Array<any> = new Array();
  idLista: Array<any> = new Array();

  checkedAll = false;



  objEditar: any;
  opcoesPalestras: SelectItem[] = [];
  palestraCliente: PalestraCliente;
  palestra: Palestra;

  listaPalestraCliente: Array<any> = new Array();

  idsListPalestraCliente: Array<any> = new Array();
  listaHistorioObservacoes: Array<any> = new Array();
  modalObservacoes: boolean = false;
  opcoesResponsavel: SelectItem[] = [];
  dataRetorno: Date = new Date();
  selectedIndex: number = 0;
  public carteiraId: number = 0;
  opcoesCarteira: SelectItem[] = [];

  linkLead: string;

  opcoesComboCarteiraLista: SelectItem[];
  opcoesComboStatusLista: SelectItem[];

  colsListaPalestraCliente: any[] = [];

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {

      },
    },
  };

  palestraClienteStatus: PalestraClienteStatus;
  palestraClienteObservacoes: PalestraClienteObservacoes;
  palestraClienteMotivosRecusaVisuzliacao: PalestraClienteStatus;
  palestraClienteObservacoesVisualizacao: PalestraClienteObservacoes;


  editarPalestraCliente: boolean = false;
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titlePrincipal = 'LEADS';
    this.subtitle = 'CADASTRE E GERENCIE AS LEADS ';
    this.linkLead = '';

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();



    this.modalFormularioMotivos = false;
    this.checkedAll = false;

    this.listaMotivosRecusa = new Array();
    this.idLista = new Array();

    this.palestraClienteStatus = new PalestraClienteStatus();
    this.palestraClienteObservacoes = new PalestraClienteObservacoes();
    this.palestraClienteObservacoes.palestraCliente = new PalestraCliente();
    this.palestraClienteObservacoes.palestraClienteStatus = new PalestraClienteStatus();

    this.colsListaPalestraCliente = [
      { field: 'palestraDescricao', header: 'WORKSHOP' },
      { field: 'dataCadastro', header: 'DATA CADASTRO' },
      { field: 'nomeCompleto', header: 'NOME' },
      { field: 'email', header: 'EMAIL' },
      { field: 'telefone', header: 'TELEFONE' },
      { field: 'nomeCarteira', header: 'CARTEIRA' },
      { field: 'descricaoStatus', header: 'STATUS' },
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.change();

      });

  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {
      this.listar();
    }
  }

  listar() {

    this.modalFormularioMotivos = false;
    this.modalObservacoes = false;
    this.modalPalestraCliente = false;
    this.checkedAll = false;
    this.idsListPalestraCliente = new Array();

    this.listaMotivosRecusa = new Array();
    this.idLista = new Array();
    this.toolbarEnable();
    this.listarPalestra();
    this.getComboCarteira();




    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-palestra-cliente(' + this.sessionService.loggedUser.id + ")")
      .subscribe(retorno => {

        this.listaPalestraCliente = new Array();
        this.opcoesComboCarteiraLista = [];
        this.opcoesComboStatusLista = [];
        this.opcoesComboStatusLista.push({ label: '', value: '' });
        this.opcoesComboCarteiraLista.push({ label: '', value: '' });

        retorno.forEach(r => {
          this.listaPalestraCliente.push(r);

          var index = 0;
          if ((this.opcoesComboCarteiraLista !== null || this.opcoesComboCarteiraLista !== undefined)) {
            index = this.opcoesComboCarteiraLista.findIndex(e => e.value === r.nomeCarteira);
          }

          if (index === -1) {
            this.opcoesComboCarteiraLista.push({ label: r.nomeCarteira, value: r.nomeCarteira });
          }
          this.opcoesComboCarteiraLista.sort((a, b) => a.label.localeCompare(b.label));

          index = 0;
          if ((this.opcoesComboStatusLista !== null || this.opcoesComboStatusLista !== undefined)) {
            index = this.opcoesComboStatusLista.findIndex(e => e.value === r.descricaoStatus);
          }

          if (index === -1) {
            this.opcoesComboStatusLista.push({ label: r.descricaoStatus, value: r.descricaoStatus });
          }
          this.opcoesComboStatusLista.sort((a, b) => a.label.localeCompare(b.label));

        });
      },
        error => this.httpService.handleError(error, () => this.listarPalestraClientes()),
        () => this.httpService.done());

  }


  hideMessage() {




  }

  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;
    this.idsListPalestraCliente = new Array();

    this.listaPalestraCliente.forEach(i => {
      if (!this.checkedAll) {
        i.selected = false;
        this.idsListPalestraCliente.slice(i.id, 1);
      } else {
        i.selected = true;
        this.idsListPalestraCliente.push(i.id);
      }

    });
    this.configuraToolBar();
  }

  configuraToolBar() {

    //this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo Registro',    () => this.showModalPalestraCliente()    );
    if (!isNullOrUndefined(this.idsListPalestraCliente)) {

      if (this.idsListPalestraCliente.length == 0) {
        this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.remover(this.idsListPalestraCliente));
        this.toolbarService.alterarStatus('edit', true, 'Editar registro', () => this.showModalEditarPalestraCliente(this.idsListPalestraCliente[0]));
        this.toolbarService.alterarStatus('info', true, 'Informações registro', () => this.showModalObservacoes(this.idsListPalestraCliente[0]));
        this.toolbarService.alterarStatus('add', false, 'Informações registro', () => this.showModalPalestraCliente());
      }

      if (this.idsListPalestraCliente.length == 1) {
        this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.remover(this.idsListPalestraCliente));
        this.toolbarService.alterarStatus('edit', false, 'Editar registro', () => this.showModalEditarPalestraCliente(this.idsListPalestraCliente[0]));
        this.toolbarService.alterarStatus('info', false, 'Informações registro', () => this.showModalObservacoes(this.idsListPalestraCliente[0]));
        this.toolbarService.alterarStatus('add', true, 'Novo Registro', () => this.showModalPalestraCliente());
      } else if (this.idsListPalestraCliente.length > 1) {
        this.toolbarService.alterarStatus('edit', true, 'Editar registro', () => this.showModalEditarPalestraCliente(this.idsListPalestraCliente[0]));
        this.toolbarService.alterarStatus('info', true, 'Informações registro', () => this.showModalObservacoes(this.idsListPalestraCliente[0]));
        this.toolbarService.alterarStatus('add', true, 'Informações registro', () => this.showModalPalestraCliente());
      }


    } else {
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.remover(this.idsListPalestraCliente));
      this.toolbarService.alterarStatus('edit', true, 'Editar registro', () => this.showModalEditarPalestraCliente(this.idsListPalestraCliente[0]));
      this.toolbarService.alterarStatus('info', true, 'Informações registro', () => this.showModalObservacoes(this.idsListPalestraCliente[0]));
      this.toolbarService.alterarStatus('add', false, 'Informações registro', () => this.showModalPalestraCliente());

    }



  }


  selectCheckbox(id: any, event: any) {

    if (event.selected) {
      event.selected = true;
      const index = this.idsListPalestraCliente.findIndex(vw => vw === id);
      this.idsListPalestraCliente.splice(index, 1);
    } else {

      this.idsListPalestraCliente.push(id);
      event.selected = false;
    }

    this.configuraToolBar();


  }



  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    //this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('msg');
  }

  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    //this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('print');
    //this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');


    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.ngOnInit()
    );


    // this.toolbarService.replace('more_vert', true, 'class-edit-button', 'more_vert', 'Opções');
    //this.toolbarService.replace('export', true, 'class-edit-button', 'edit', 'Editar Registro', () => this.editar(this.idsListPalestraCliente[0]) );
    //this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir Pedido',     );
    this.toolbarService.replace('info', true, 'class-edit-button', 'info', 'Observações');
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Alterar Títulos Selecionados');
    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo Registro', () => this.showModalPalestraCliente());


  }

  adicionar() {

    if (this.validacao()) {
      return;
    }


    let listaEnviar = {
      palestraCliente: this.palestraCliente,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuarioId: this.sessionService.loggedUser.id,
      carteiraId: this.carteiraId,
      isNovoCliente: true

    }

    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-palestra-cliente', listaEnviar)
      .subscribe(rst => {
        this.idsListPalestraCliente = [];

        this.listarPalestraClientes();
        if (rst[0].error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst[0].message
          });
          this.listar();
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst[0].message
          });
          this.listar();
        }
      },
        error => this.httpService.handleError(error, () => this.httpService.done()),
        () => this.httpService.done());
    this.modalPalestraCliente = false;

  }



  atualizar() {


    if (this.validacao()) {
      return;
    }


    let listaEnviar = {
      id: this.idsListPalestraCliente[0],
      palestraCliente: this.palestraCliente,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuarioId: this.sessionService.loggedUser.id,
      carteiraId: this.carteiraId,
      isNovoCliente: false

    }

    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-palestra-cliente', listaEnviar)
      .subscribe(rst => {

        this.idsListPalestraCliente = [];
        this.listarPalestraClientes();

        if (rst[0].error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst[0].message
          });
          this.listar();

        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst[0].message
          });

          this.listar();
        }
      },
        error => this.httpService.handleError(error, () => this.httpService.done()),
        () => this.httpService.done());
    this.modalPalestraCliente = false;


  }

  remover(value: any) {

    if (confirm("Deseja remover este registro?")) {
      let listaEnviar = {
        id: value
      }

      this.httpService.wait();
      this.httpService.post('/custom/polozi/deletar-palestra-cliente', listaEnviar)
        .subscribe(rst => {
          this.configuraToolBar();
          this.idsListPalestraCliente = [];
          this.listarPalestraClientes();

          if (rst[0].error === true) {
            this.messageService.add({
              severity: 'error', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst[0].message
            });
            this.listar();

          } else {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst[0].message
            });

            this.listar();
          }
        },
          error => this.httpService.handleError(error, () => this.httpService.done()),
          () => this.httpService.done());



    }
  }

  validacao() {

    if (isNullOrUndefined(this.palestraCliente.palestra.id) || this.palestraCliente.palestra.id === 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar uma palestra!'
      });
      return true;
    }


    if (isNullOrUndefined(this.palestraCliente.nomeCompleto) || this.palestraCliente.nomeCompleto.length < 3) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor digitar o nome!'
      });
      return true;
    }

    if (isNullOrUndefined(this.palestraCliente.email) || this.palestraCliente.email === '') {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor digitar um email!'
      });
      return true;
    }


    if (isNullOrUndefined(this.palestraCliente.telefone) || this.palestraCliente.telefone === '(99) 99999-9999') {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor digitar um telefone!'
      });
      return true;
    }


    if (!isNullOrUndefined(this.palestraCliente.telefone) && this.palestraCliente.telefone.length < 10) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor digitar um telefone!'
      });
      return true;
    }



    return false;
  }


  listarPalestraClientes() {


    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-palestra-cliente(' + this.sessionService.loggedUser.id + ")")
      .subscribe(retorno => {
        this.listaPalestraCliente = new Array();
        retorno.forEach(r => {
          this.listaPalestraCliente.push(r);
        });
      },
        error => this.httpService.handleError(error, () => this.listarPalestraClientes()),
        () => this.httpService.done());


  }

  listarPalestra() {

    //this.dblinkedSessionService.empresa.id
    this.httpService.wait();
    this.httpService
      .get(
        '/custom/polozi/get-palestrante-empresa(' + this.dblinkedSessionService.empresa.id + ')'
      )
      .subscribe(
        rst => {

          this.opcoesPalestras = [];
          this.opcoesPalestras.push({ label: 'Selecionar um workshop', value: 0 });

          rst.forEach(e => {

            this.opcoesPalestras.push({ label: e.codigo + ' - ' + e.data + ' - ' + e.palestranteMome, value: e.palestraId });
          })

        },
        error =>
          this.httpService.handleError(error, () => this.listarPalestraClientes()),
        () => this.httpService.done()
      );


  }

  ngOnDestroy(): void {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  limparTela() {
    this.checkedAll = false;
    this.modalFormularioMotivos = false;
    this.modalObservacoes = false;
    this.modalPalestraCliente = false;
    this.idsListPalestraCliente = [];
    this.palestraCliente = new PalestraCliente();
    this.palestraCliente.palestra = new Palestra();
    this.configuraToolBar();
  }

  showModalObservacoes(value: any) {
    this.selectedIndex = 0;
    this.listaHistorioObservacoes = new Array();
    //this.listarHistoricoObservacoes();
    this.getcomboResponsavel();
    console.log(value);

    this.linkLead = 'https://polozi.s3.amazonaws.com/agendamento_palestra.html?c=' + value + '&d=' + this.sessionService.loggedUser.id;

    this.palestraClienteObservacoes = new PalestraClienteObservacoes();
    this.palestraClienteObservacoes.palestraCliente = new PalestraCliente();
    this.palestraClienteObservacoes.palestraClienteStatus = new PalestraClienteStatus();
    this.palestraClienteStatus = new PalestraClienteStatus();

    this.modalObservacoes = true;
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-opcoes-cliente-motivos-recusa')
      .subscribe(retorno => {
        this.listaMotivosRecusa = new Array();
        this.listaMotivosRecusa.push({ label: 'Selecione um status', value: 0 });
        retorno.forEach(i => {
          this.listaMotivosRecusa.push({ label: i.descricao, value: i.id });
        })
      },
        error => this.httpService.handleError(error, () => this.limparTela()),
        () => this.httpService.done());

  }

  salvarObservacoes() {

    if (this.validacaoObservacao()) {
      return;
    }

    this.palestraClienteObservacoes.palestraCliente.id = this.idsListPalestraCliente[0];

    let listaEnviar = {
      id: this.idsListPalestraCliente,
      palestraClienteObservacoes: this.palestraClienteObservacoes,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuarioId: this.sessionService.loggedUser.id,
      isNovoRegistro: true
    }


    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-palestra-observacao', listaEnviar)
      .subscribe(rst => {
        if (rst[0].error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst[0].message
          });
          this.listar();
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst[0].message
          });
          this.listar();
        }
      },
        error => this.httpService.handleError(error, () => this.httpService.done()),
        () => this.httpService.done());

  }

  showModalPalestraCliente() {
    this.carteiraId = 0;
    this.getComboCarteira();
    this.palestraCliente = new PalestraCliente();
    this.palestraCliente.palestra = new Palestra();
    this.modalPalestraCliente = true;

  }

  showModalEditarPalestraCliente(value: any) {
    console.log(value);

    this.editarPalestraCliente = true;
    this.palestraCliente = new PalestraCliente();
    this.palestraCliente.palestra = new Palestra();

    let i = this.listaPalestraCliente.findIndex(e => e.id === value);

    this.palestraCliente.id = this.listaPalestraCliente[i].id;
    this.palestraCliente.nomeCompleto = this.listaPalestraCliente[i].nomeCompleto;
    this.palestraCliente.telefone = this.listaPalestraCliente[i].telefone;
    this.palestraCliente.email = this.listaPalestraCliente[i].email;
    this.palestraCliente.empresaNome = this.listaPalestraCliente[i].empresaNome;

    this.palestraCliente.palestra = new Palestra();
    this.palestra = new Palestra();

    this.palestra.id = this.listaPalestraCliente[i].palestraId;
    this.palestraCliente.palestra.id = this.palestra.id;
    this.carteiraId = this.listaPalestraCliente[i].carteiraId;

    this.modalPalestraCliente = true;
  }

  listarHistoricoObservacoes() {

    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-historico-observacoes-palestra-cliente', [this.idsListPalestraCliente[0]])
      .subscribe(retorno => {
        this.listaHistorioObservacoes = new Array();
        retorno.forEach(element => { this.listaHistorioObservacoes.push(element); });

      },
        error => this.httpService.handleError(error, () => this.listar()),
        () => this.httpService.done());

  }


  validacaoObservacao() {

    if (isNullOrUndefined(this.palestraClienteObservacoes.palestraClienteStatus.id) || this.palestraClienteObservacoes.palestraClienteStatus.id === 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar um status!'
      });
      return true;
    }

    if (isNullOrUndefined(this.palestraClienteObservacoes.usuarioResponsavelId) || this.palestraClienteObservacoes.usuarioResponsavelId === 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar um responsável!'
      });
      return true;
    }


    if (isNullOrUndefined(this.palestraClienteObservacoes.dataRecusa)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar a data do contato!'
      });
      return true;
    }

    if (isNullOrUndefined(this.palestraClienteObservacoes.observacao) || this.palestraClienteObservacoes.observacao.toString().trim() === '') {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor colocar uma observação!'
      });
      return true;
    }


    return false;
  }

  showModalVisualizacaoInf() {
    this.modalVisualizacaoInf = true;

    this.palestraClienteObservacoesVisualizacao = new PalestraClienteObservacoes();
    this.palestraClienteObservacoesVisualizacao.palestraCliente = new PalestraCliente();
    this.palestraClienteObservacoesVisualizacao.palestraClienteStatus = new PalestraClienteStatus();
    this.palestraClienteMotivosRecusaVisuzliacao = new PalestraClienteStatus();


  }

  selectedRow(value: any) {


    this.palestraClienteObservacoesVisualizacao = new PalestraClienteObservacoes();
    this.palestraClienteObservacoesVisualizacao.palestraCliente = new PalestraCliente();
    this.palestraClienteObservacoesVisualizacao.palestraClienteStatus = new PalestraClienteStatus();
    this.palestraClienteMotivosRecusaVisuzliacao = new PalestraClienteStatus();

    this.palestraClienteObservacoesVisualizacao.dataRecusa = value.dataRecusa;
    this.palestraClienteObservacoesVisualizacao.observacao = value.observacaoCompleta
    this.palestraClienteObservacoesVisualizacao.palestraClienteStatus.id = value.palestraClienteMotivosRecusaId;

    this.modalVisualizacaoInf = true;

  }


  getcomboResponsavel() {

    this.httpService.wait();
    this.httpService
      .get(
        '/custom/polozi/get-combo-responsavel-palestra(' + this.dblinkedSessionService.empresa.id + ')'
      )
      .subscribe(
        rst => {

          this.opcoesResponsavel = [];
          this.opcoesResponsavel.push({ label: 'Selecionar um responsável', value: 0 });

          rst.forEach(e => {

            this.opcoesResponsavel.push({ label: e.nome + ' - ' + e.email, value: e.authUserId });
          })

        },
        error =>
          this.httpService.handleError(error, () => this.listarPalestraClientes()),
        () => this.httpService.done()
      );


  }

  tabChange(event: any) {


    switch (event.index) {
      case 1:
        this.listarHistoricoObservacoes();
        break;

      default:
        break;
    }


  }


  getComboCarteira() {

    this.httpService.wait();
    this.httpService
      .get(
        '/custom/polozi/get-combo-carteira')
      .subscribe(
        rst => {

          this.opcoesCarteira = [];
          this.opcoesCarteira.push({ label: 'Selecionar uma carteira', value: 0 });

          rst.forEach(e => {

            this.opcoesCarteira.push({ label: 'Carteira: ' + e.carteiraNome + ' - Consultor: ' + e.consultorNome, value: e.id });
          })

        },
        error =>
          this.httpService.handleError(error, () => this.listarPalestraClientes()),
        () => this.httpService.done()
      );


  }


}
