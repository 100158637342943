import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import * as Highcharts from 'highcharts'
import { BarChart, ColumnChart, PieChart } from '../interfaces/dash-graphics'

@Component({
  selector: 'app-dash-graphics[container][type][height][isLegend][isLoading]',
  templateUrl: './dash-graphics.component.html',
  styleUrls: ['./dash-graphics.component.scss']
})
export class DashGraphicsComponent implements OnChanges {

  @Input() container: string
  @Input() type: 'pie' | 'column' | 'bar'
  @Input() height: number
  @Input() title: string
  @Input() subtitle: string
  @Input() isMoney: boolean
  @Input() isPercentage: boolean
  @Input() baseList: Array<string> = new Array()
  @Input() isLegend: boolean
  @Input() isLoading: boolean
  @Input() isStack: boolean
  @Input() showShadowBox: boolean = true

  @Input() pieData: Array<PieChart> = new Array()
  @Input() columnData: Array<ColumnChart> = new Array()
  @Input() barData: Array<BarChart> = new Array()

  @Input() colors: Array<any> = ['rgb(85, 0, 189)', 'rgb(232, 43, 123)', 'rgb(36, 4, 45)', 'rgb(254, 198, 1)', 'rgb(39, 232, 188)']

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading !== null && changes.isLoading !== undefined) if (!changes.isLoading.currentValue) this.loadingChart(this.container, this.type, this.sepairData(this.type), this.title, this.subtitle, this.isMoney, this.isPercentage, this.baseList, this.isLegend, this.isStack)
  }

  private sepairData(type: string): Array<any> {
    switch (type) {
      case 'pie':
        return this.pieData
      case 'column':
        return this.columnData
      case 'bar':
        return this.barData
    }
  }

  private loadingChart(container: string, type: any, data: Array<any>, title: string, subtitle: string, isValue: boolean, isPercentage: boolean, baseList: Array<string>, isLegend: boolean, isStack: boolean): void {
    let obj = {
      credits: { enabled: false },
      chart: { type: type, renderTo: container, style: { fontFamily: "\"Roboto\", Arial, Helvetica, sans-serif" } },
      title: { text: title, style: { fontWeight: 'bold', color: 'rgb(33, 33, 33)', fontSize: '18px' }, margin: 30 },
      subtitle: { text: subtitle, style: { color: 'rgb(33, 33, 33, .5)', fontSize: '13px' } },
      colors: this.colors,
      tooltip: {},
      legend: { enabled: isLegend },
      plotOptions: {},
      series: data,
    }

    switch (type) {
      case 'pie':
        obj.series[0]['innerSize'] = '50%'
        obj.plotOptions['pie'] = {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            padding: 0,
            connectorPadding: 5,
            softConnector: false,
            formatter: function () {
              let labelName = this.point.isNull ? void 0 : this.point.name.substr(0, 10)
              return (this.point.name.length > 10) ? `${labelName}...` : labelName
            }
          }
        }
        obj.tooltip['pointFormatter'] = function () {
          if (this.y >= 1e6) return `${this.negative ? '-' : ''} ${(this.y / 1e6).toFixed(3)}M: ${this.percentage.toFixed(0)}%`
          else if (this.y >= 1e3) return `${this.negative ? '-' : ''} ${(this.y / 1e3).toFixed(3)}K: ${this.percentage.toFixed(0)}%`
          return `${this.negative ? '-' : ''} ${this.y}: ${this.percentage.toFixed(0)}%`
        }
      break
      case 'column':
        obj.plotOptions['series'] = {
          dataLabels: {
            enabled: true,
            inside: true,
            formatter: function () {
              return this.y.toLocaleString('pt-Br', {
                maximumFractionDigits: 0
              })
            }
          }
        }
        if(isStack) obj.plotOptions['column'] = { stacking: 'normal' }
        obj['xAxis'] = [{ categories: baseList, crosshair: true }]
        obj['yAxis'] = { title: { text: '', reserveSpace: false } }
        obj.tooltip = {
          headerFormat: '<span style="font-size:10px; margin-bottom: 4px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}:</td><td style="padding-left: 4px">{point.y}{point.custom.extraInformation}</td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        }
      break
      case 'bar':
        obj.plotOptions['series'] = {
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (isPercentage ) return Highcharts.numberFormat(this.y,1) + '%'
              else return this.y.toLocaleString('pt-Br', {
                maximumFractionDigits: 0
              })
            }
          }
        }
        obj['xAxis'] = [{ categories: baseList, crosshair: true }]
        obj['yAxis'] = { title: { text: '', reserveSpace: false } }
        obj.tooltip = {
          headerFormat: '<span style="font-size:10px; margin-bottom: 4px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}:</td><td style="padding-left: 4px">{point.y}{point.custom.extraInformation}</td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        }
      break
    }

    if (isValue) {
      obj.tooltip['valuePrefix'] = 'R$ '
      obj.tooltip['valueDecimals'] = 2
      obj.tooltip['shared'] = true
    }

    if (isPercentage) {
      obj.tooltip['valueSuffix'] = ' %'
      obj.tooltip['valueDecimals'] = 1
      obj.tooltip['shared'] = true
    }

    Highcharts.setOptions({ lang: { decimalPoint: ',', thousandsSep: '.' } })

    new Highcharts.Chart(obj)
  }

}
