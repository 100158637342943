export class TransporteTipo {
  id: number;
  descricao: string;

  constructor(transporteTipo?: TransporteTipo | number) {
    if (transporteTipo !== undefined && transporteTipo !== null) {
      if (typeof transporteTipo === 'number') {
        this.id = transporteTipo;
      } else {
        this.id = transporteTipo.id;
        this.descricao = transporteTipo.descricao;
      }
    }
  }
}
