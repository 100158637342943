import { TbIrpjM300 } from 'app/ecf/model/tb-irpj-m300.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { TbIndRelacao } from 'app/ecf/model/tb-ind-relacao.model';
import { TbTipoLcto } from 'app/ecf/model/tb-tipo-lcto.model';
import { MovApurReal } from 'app/ecf/model/mov-apur-real.model';
import { MovSaldosContabeis } from 'app/contabil/model/mov-saldos-contabeis.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';

export class MovParteAIrpj {
  id: number;
  data: Date;
  hist: string;
  valor: number;
  tbIrpjM300: TbIrpjM300;
  planoContas: PlanoContas;
  cadCcus: CadCcus;
  tbIndRelacao: TbIndRelacao;
  tbTipoLcto: TbTipoLcto;
  movApurReal: MovApurReal;
  movSaldosContabeis: MovSaldosContabeis;
  // empresa: Empresa;
  cadEcd: CadEcd;


  constructor(movParteAIrpj?: MovParteAIrpj | number) {
    if (movParteAIrpj !== undefined && movParteAIrpj !== null) {
      if (typeof movParteAIrpj === 'number') {
        this.id = movParteAIrpj;
      } else {
        this.id = movParteAIrpj.id;
        this.data = movParteAIrpj.data ? new Date(movParteAIrpj.data) : null;
        this.hist = movParteAIrpj.hist;
        this.valor = movParteAIrpj.valor;
        this.tbIrpjM300 = movParteAIrpj.tbIrpjM300;
        this.planoContas = movParteAIrpj.planoContas;
        this.cadCcus = movParteAIrpj.cadCcus;
        this.tbIndRelacao = movParteAIrpj.tbIndRelacao;
        this.tbTipoLcto = movParteAIrpj.tbTipoLcto;
        this.movApurReal = movParteAIrpj.movApurReal;
        this.movSaldosContabeis = movParteAIrpj.movSaldosContabeis;
        // this.empresa = movParteAIrpj.empresa;
        this.cadEcd = movParteAIrpj.cadEcd;

      }
    }
  }
}
