export class IndSitEsp {
  id: number;
  cod: string;
  descricao: string;

  constructor(indsitesp?: IndSitEsp | number) {
    if (indsitesp !== undefined && indsitesp !== null) {
      if (typeof indsitesp === 'number') {
        this.id = indsitesp;
      } else {
        this.id = indsitesp.id;
        this.cod = indsitesp.cod;
        this.descricao = indsitesp.descricao;
      }
    }
  }
}
