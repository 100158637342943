import { Component, OnInit, OnDestroy, ViewChildren, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-status',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.scss'
  ],
  providers: [CrudService]
})
export class SiscoservEditarComponent implements OnInit, OnDestroy {
  @ViewChildren(ToolbarService) panels;

  checkAllBtn: any;

  defaultDate: Date;

  meses: any;

  msgs: Message[];
  msgsModal : Message[];


  rasResults: Array<any> = new Array();

  rvsResults: Array<any> = new Array();

  rpResults: Array<any> = new Array();

  rfResults: Array<any> = new Array();

  idLista: Array<any> = new Array();

  private sessionSubscription: Subscription;

  private tabName: any = 'RAS';

  private tabResults: Array<any> = this.rasResults;

  title: String = 'EDITAR';
  subtitle: String = 'EDIÇÃO DOS REGISTROS IMPORTADOS';

  modalRasRvs: boolean = false;

  dataInicioEvento: Date;
  dataConclusaoEvento: Date;
  opcoesMoeda: SelectItem[] = [];
  moedaId: number;
  opcoesNbs: SelectItem[] = [];
  nbsId: number;
  valorRasRvs: number;

  modalRp: boolean = false;

  dataPagamento: Date;
  valorRp: number;

  modalRf: boolean = false;

  dataFatura: Date;
  valorRf: number;

  numeroOperacaoEmpresa: number;

  //export excel
  colsrasResults: any[];
  colsrvsResults: any[];
  colsrpResults: any[];
  colsrfResults: any[];

  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.toolbarService.clear();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 3, 2);

    this.idLista = new Array();

    this.modalRasRvs = false;
    this.dataInicioEvento = new Date();
    this.dataConclusaoEvento = new Date();
    this.opcoesMoeda = [];
    this.moedaId = 0;
    this.opcoesNbs = [];
    this.nbsId = 0;
    this.valorRasRvs = 0;

    this.modalRp = false;
    this.dataPagamento = new Date();
    this.valorRp = 0;

    this.modalRf = false;
    this.dataFatura = new Date();
    this.valorRf = 0;

    this.numeroOperacaoEmpresa = 0;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.changePeriod(); });

    this.meses = [];
    for (let mes = 1; mes <= 12; mes++) {
      this.meses.push({ label: mes, value: mes });
    }

    this.changePeriod();

    this.toolbarRefresh();

    this.colsrasResults = [
      { field: 'rasNumProcesso', header: 'NÚMERO PROCESSO' },
      { field: 'numOperacaoEmpresa', header: 'NÚMERO OPERAÇÃO' },
      { field: 'rasDtInicio', header: 'DATA INÍCIO' },
      { field: 'rasDtConclusao', header: 'DATA CONCLUSÃO' },
      { field: 'rasModPrestacao', header: 'MODO PRESTAÇÃO' },
      { field: 'rasCodFornecedor', header: 'CÓDIGO FORNECEDOR' },
      { field: 'rasNomeFornecedor', header: 'NOME FORNECEDOR' },
      { field: 'rasEndereco', header: 'ENDEREÇO' },
      { field: 'rasNif', header: 'NIF' },
      { field: 'rasTipVinculacao', header: 'TIPO VINCULAÇÃO' },
      { field: 'rasCodImportador', header: 'CÓDIGO IMPORTADOR' },
      { field: 'rasMoeda', header: 'MOEDA' },
      { field: 'rasDescServico', header: 'DESCRIÇÃO SERVIÇO' },
      { field: 'rasCodNbs', header: 'CÓDIGO NBS' },
      { field: 'rasValor', header: 'VALOR' }
    ];

    this.colsrvsResults = [
      { field: 'rvsNumProcesso', header: 'NÚMERO PROCESSO' },
      { field: 'numOperacaoEmpresa', header: 'NÚMERO OPERAÇÃO' },
      { field: 'rvsDtInicio', header: 'DATA INÍCIO' },
      { field: 'rvsDtConclusao', header: 'DATA CONCLUSÃO' },
      { field: 'rvsModPrestacao', header: 'MODO PRESTAÇÃO' },
      { field: 'rvsCodCliente', header: 'CÓDIGO CLIENTE' },
      { field: 'rvsNomeCliente', header: 'NOME CLIENTE' },
      { field: 'rvsEndereco', header: 'ENDEREÇO' },
      { field: 'rvsNif', header: 'NIF' },
      { field: 'rvsTipVinculacao', header: 'TIPO VINCULAÇÃO' },
      { field: 'rvsCodExportador', header: 'CÓDIGO EXPORTADOR' },
      { field: 'rvsMoeda', header: 'MOEDA' },
      { field: 'rvsDescServico', header: 'DESCRIÇÃO SERVIÇO' },
      { field: 'rvsCodNbs', header: 'CÓDIGO NBS' },
      { field: 'rvsValor', header: 'VALOR' }
    ];

    this.colsrpResults = [
      { field: 'rpNumProcesso', header: 'RAS SELECIONADO' },
      { field: 'rpCodProcesso', header: 'NÚMERO PROCESSO RP' },
      { field: 'numOperacaoEmpresa', header: 'NÚMERO OPERAÇÃO' },
      { field: 'rpNumDocumento', header: 'NUM DOCUMENTO' },
      { field: 'rpDtPagto', header: 'DATA PAGTO' },
      { field: 'rpVlrPago', header: 'VALOR PAGO' }
    ];

    this.colsrfResults = [
      { field: 'rfNumProcesso', header: 'RVS SELECIONADO' },
      { field: 'rfCodProcesso', header: 'NÚMERO PROCESSO RF' },
      { field: 'numOperacaoEmpresa', header: 'NÚMERO OPERAÇÃO' },
      { field: 'rfNumNf', header: 'NUM NF' },
      { field: 'rfDtFaturamento', header: 'DATA FAT' },
      { field: 'rfVlrFaturado', header: 'VALOR FATURADO' }
    ];
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionSubscription.remove(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  private changePeriod() {

    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
      this.loadGrids('all-in');
      this.defaultDate = new Date();
      if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
        const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
          this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
        const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
        this.defaultDate = new Date(dt);
      }
    }
  }

  check(id: any, event: any) {
    if (!event.selected) {
      event.selected = false;
    } else {
      event.selected = true;
    }

    this.selectCheckbox(id, event);
  }

  checkAll(event: any) {
    this.tabFilter();

    this.checkAllBtn = event.checked; // Adiciona o btn 'select all' na var;

    this.idLista = new Array();
    if (event.checked === true) {
      this.tabResults.forEach(rst => {
        rst.selected = true;
        this.idLista.push(rst.id);
      });
    } else {
      this.tabResults.forEach(rst => {
        rst.selected = false;
      });
    }
    this.toolbarCheck(event);

    // console.log('\r\n---> Aba ' + this.tabName + '\r\nIds selecionados:\r\n' + this.selectedIds);
  }

  private edit(event: any) {

    this.tabName = document.getElementById('findme').textContent;
    this.numeroOperacaoEmpresa = event.numOperacaoEmpresa;

    if ((this.tabName === 'RAS') || (this.tabName === 'RVS')) {
      this.showModalRasRvs();
    } else if (this.tabName === 'RP') {
      this.showModalRp();
    } else if (this.tabName === 'RF') {
      this.showModalRf();
    }

  }

  showModalRasRvs() {
    this.modalRasRvs = true;
    this.dataInicioEvento = new Date();
    this.dataConclusaoEvento = new Date();
    this.opcoesMoeda = [];
    this.moedaId = 0;
    this.opcoesNbs = [];
    this.nbsId = 0;
    this.valorRasRvs = 0;

    this.comboCarregarMoeda();
    this.comboCarregarNbs();

  }

  showModalRp() {
    this.modalRp = true;
    this.dataPagamento = new Date();
    this.valorRp = 0;
  }

  showModalRf() {
    this.modalRf = true;
    this.dataFatura = new Date();
    this.valorRf = 0;
  }

  private comboCarregarMoeda() {

    this.httpService.wait();
    this.httpService.get('/custom/siscoserv/getMoedaList')
      .subscribe(retorno => {

        this.opcoesMoeda = [];
        this.opcoesMoeda.push({ label: 'Selecione', value: 0 });
        retorno.forEach(element => {
          this.opcoesMoeda.push({ label: element.id + " - " + element.descricaoMoeda, value: element.id });
        });

      },
        error => this.httpService.handleError(error, () => this.comboCarregarMoeda()),
        () => this.httpService.done());
  }

  private comboCarregarNbs() {

    this.httpService.wait();
    this.httpService.get('/custom/siscoserv/getNbsList')
      .subscribe(retorno => {
        this.opcoesNbs = [];
        this.opcoesNbs.push({ label: 'Selecione', value: 0 });
        retorno.forEach(element => {
          this.opcoesNbs.push({ label: element.id + " - " + element.descricaoNbs, value: element.id });
        });

      },
        error => this.httpService.handleError(error, () => this.comboCarregarNbs()),
        () => this.httpService.done());
  }


  public rectifyRasRvsData() {

    const listaEnviar = {
      numProcesso: this.idLista[0],
      tipoOperacao: this.tabName,
      numeroOperacaoEmpresa: this.numeroOperacaoEmpresa,
      dataInicioEvento: this.dataInicioEvento,
      dataConclusaoEvento: this.dataConclusaoEvento,
      moedaId: this.moedaId,
      nbsId: this.nbsId,
      valorRasRvs: this.valorRasRvs,
      codigoEmpresa: this.dblinkedSessionService.empresa.id.toString()
    };

    this.httpService.wait();
    this.httpService.post('/custom/siscoserv/edit', listaEnviar)
      .subscribe(result => {

        if (result[0].error) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'Retificação não pôde ser concluída: ',
            detail: result[0].message
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'Confirmação: ',
            detail: result[0].message
          });
          window.open(this.httpService.url('/custom/file/' + result[0].file), '_blank');

        }

        this.modalRasRvs = false;
        this.uncheckAll();
        this.toolbarRefresh();
        this.loadGrids(this.tabName);
        this.idLista = new Array();
      },
        error => this.httpService.handleError(error, () => this.rectifyRasRvsData()),
        () => this.httpService.done());

  }


  public rectifyRpData() {

    const listaEnviar = {
      numProcesso: this.idLista[0],
      tipoOperacao: this.tabName,
      numeroOperacaoEmpresa: this.numeroOperacaoEmpresa,
      dataPagamento: this.dataPagamento,
      valorRp: this.valorRp,
      codigoEmpresa: this.dblinkedSessionService.empresa.id.toString()
    };

    this.httpService.wait();
    this.httpService.post('/custom/siscoserv/edit', listaEnviar)

      .subscribe(result => {

        if (result[0].error) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'Retificação não pôde ser concluída: ',
            detail: result[0].message
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'Confirmação: ',
            detail: result[0].message
          });
          window.open(this.httpService.url('/custom/file/' + result[0].file), '_blank');

        }

        this.modalRp = false;
        this.uncheckAll();
        this.toolbarRefresh();
        this.loadGrids(this.tabName);
        this.idLista = new Array();
      },
        error => this.httpService.handleError(error, () => this.rectifyRpData()),
        () => this.httpService.done());

  }

  public rectifyRfData() {

    const listaEnviar = {
      numProcesso: this.idLista[0],
      tipoOperacao: this.tabName,
      numeroOperacaoEmpresa: this.numeroOperacaoEmpresa,
      dataFatura: this.dataFatura,
      valorRf: this.valorRf,
      codigoEmpresa: this.dblinkedSessionService.empresa.id.toString()
    };

    this.httpService.wait();
    this.httpService.post('/custom/siscoserv/edit', listaEnviar)
      .subscribe(result => {

        if (result[0].error) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'Retificação não pôde ser concluída: ',
            detail: result[0].message
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'Confirmação: ',
            detail: result[0].message
          });
          window.open(this.httpService.url('/custom/file/' + result[0].file), '_blank');

        }

        this.modalRf = false;
        this.uncheckAll();
        this.toolbarRefresh();
        this.loadGrids(this.tabName);
        this.idLista = new Array();
      },
        error => this.httpService.handleError(error, () => this.rectifyRfData()),
        () => this.httpService.done());


  }

  private exported(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file), '_blank');
    } else {
      alert(result.message);
    }

    this.uncheckAll();
    this.toolbarRefresh();
    this.loadGrids(this.tabName);
    this.idLista = new Array();
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {

  }

  private loadGrids(type: any) {

    if (type === 'RAS') {
      this.loadGridRas();
    } else if (type === 'RVS') {
      this.loadGridRvs();
    } else if (type === 'RP') {
      this.loadGridRp();
    } else if (type === 'RF') {
      this.loadGridRf();
    } else if (type === 'all-in') {
      this.loadGridRas();
      this.loadGridRvs();
      this.loadGridRp();
      this.loadGridRf();
    }
  }

  private loadGridRas() {
    this.httpService.wait();
    this.httpService.get('/custom/siscoserv/get-ras-rvs-periodo(' +
      this.dblinkedSessionService.empresa.id + ',' + this.dblinkedSessionService.periodo.month.value + ','
      + this.dblinkedSessionService.periodo.year.value + ',RAS-rectify)')
      .subscribe(ref => {
        this.rasResults = ref;
      },
        error => this.httpService.handleError(error, () => this.changePeriod()),
        () => this.httpService.done());
  }

  private loadGridRvs() {
    this.httpService.wait();
    this.httpService.get('/custom/siscoserv/get-ras-rvs-periodo(' +
      this.dblinkedSessionService.empresa.id + ',' + this.dblinkedSessionService.periodo.month.value + ','
      + this.dblinkedSessionService.periodo.year.value + ',RVS-rectify)')
      .subscribe(ref => {
        this.rvsResults = ref;
      },
        error => this.httpService.handleError(error, () => this.changePeriod()),
        () => this.httpService.done());
  }

  private loadGridRp() {
    this.httpService.wait();
    this.httpService.get('/custom/siscoserv/get-rp-rf-periodo(' +
      this.dblinkedSessionService.empresa.id + ',' + this.dblinkedSessionService.periodo.month.value + ','
      + this.dblinkedSessionService.periodo.year.value + ',RP-rectify)')
      .subscribe(ref => {
        this.rpResults = ref;
      },
        error => this.httpService.handleError(error, () => this.changePeriod()),
        () => this.httpService.done());
  }

  private loadGridRf() {
    this.httpService.wait();
    this.httpService.get('/custom/siscoserv/get-rp-rf-periodo(' +
      this.dblinkedSessionService.empresa.id + ',' + this.dblinkedSessionService.periodo.month.value + ','
      + this.dblinkedSessionService.periodo.year.value + ',RF-rectify)')
      .subscribe(ref => {
        this.rfResults = ref;
      },
        error => this.httpService.handleError(error, () => this.changePeriod()),
        () => this.httpService.done());
  }

  private tabFilter() {
    this.tabName = document.getElementById('findme').textContent;

    switch (this.tabName) {
      case 'RAS': {
        this.tabResults = this.rasResults;
        break;
      }
      case 'RVS': {
        this.tabResults = this.rvsResults;
        break;
      }
      case 'RP': {
        this.tabResults = this.rpResults;
        break;
      }
      case 'RF': {
        this.tabResults = this.rfResults;
        break;
      }
      default: {
      }
    }
  }

  private toolbarRefresh() {
    this.toolbarService.clear();
    this.toolbarService.add('info', true, 'class-edit-button', 'info', 'Modelo de Planilhas');
    this.toolbarService.add('warning', true, 'class-edit-button', 'warning', 'Visualizar Log');
    this.toolbarService.add('export', true, 'class-edit-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-edit-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }

  selectCheckbox(id: any, event: any) {

    if (event.selected) {
      event.selected = true;
      const index = this.idLista.findIndex(i => i === id);
      this.idLista.splice(index, 1);
      this.checkAllBtn = false;

    } else {
      this.idLista.push(id);
    }

    this.toolbarCheck(event);

  }

  private uncheckAll() {
    try {
      this.checkAllBtn = false;
    } catch (error) {
      // console.log('\r\n--> ' + error);
    }
  }

  private toolbarCheck(event: any) {
    if (isNullOrUndefined(this.idLista) || this.idLista.length === 0) {
      this.toolbarService.alterarStatus('edit', true, 'Editar Dados', () => this.edit(event));
    } else {
      if (this.idLista.length === 1) {
        this.toolbarService.alterarStatus('edit', false, 'Editar Dados', () => this.edit(event));
      } else if (this.idLista.length > 1) {
        this.toolbarService.alterarStatus('edit', true, 'Editar Dados', () => this.edit(event));
      }
    }

  }

}
