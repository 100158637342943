import { Component, OnInit } from '@angular/core';
import { TbCodEnquadra } from '../model/tb-cod-enquadra.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'siscoserv-enquadramento',
  templateUrl: './enquadramento.component.html',
  styleUrls: ['./enquadramento.component.scss'],
  providers: [CrudService]
})
export class EnquadramentoComponent extends DataTableBasicComponent implements OnInit {
  public tbCodEnquadra: TbCodEnquadra = new TbCodEnquadra();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('CÓDIGO', 'cod'),
      new DataTableColumn('DESCRIÇÃO', 'descricao')
    ];

    this.controls = [
      new DataControl('Código', 'cod').setReadOnly(),
      new DataControl('Descrição', 'descricao').setReadOnly()
    ];
  }
}
