import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmpresaAlmoxarifado } from 'app/cadastro/model/empresa-almoxarifado.model';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Item } from 'app/cadastro/model/item.model';
import { OperacaoRegraContabil } from 'app/cadastro/model/operacao-regra-contabil.model';
import { OperacaoRegraFiscal } from 'app/cadastro/model/operacao-regra-fiscal.model';
import { OperacaoRegra } from 'app/cadastro/model/operacao-regra.model';
import { Participante } from 'app/cadastro/model/participante.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadProj } from 'app/contabil/model/cad-proj.model';
import LogManagement from 'app/shared/common/log-management/log-control';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';
import { Util } from 'app/shared/common/util';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { PedidoService } from 'app/shared/services/pedido.service';
import { SidebarService } from 'app/shared/services/sidebar.service';
import { Action } from 'app/shared/services/socket/protocol/Action';
import { Method } from 'app/shared/services/socket/protocol/Method';
import { Service } from 'app/shared/services/socket/protocol/Service';
import { PysocketService } from 'app/shared/services/socket/pysocket.service';
import { Cfop } from 'app/util/model/cfop.model';
import { CstIcmsTabelaA } from 'app/util/model/cst-icms-tabela-a.model';
import { CstIcmsTabelaB } from 'app/util/model/cst-icms-tabela-b.model';
import { CstIpi } from 'app/util/model/cst-ipi.model';
import { CstPiscofins } from 'app/util/model/cst-piscofins.model';
import { FormaPagamento } from 'app/util/model/forma-pagamento.model';
import { ModNf } from 'app/util/model/mod-nf.model';
import { Moeda } from 'app/util/model/moeda.model';
import { Tipi } from 'app/util/model/tipi.model';
import { TipoBaixa } from 'app/util/model/tipo-baixa.model';
import { TipoDesconto } from 'app/util/model/tipo-desconto.model';
import { TipoFrete } from 'app/util/model/tipo-frete-model';
import { TipoIncoterm } from 'app/util/model/tipo-incoterm.model';
import { TipoIntermediacao } from 'app/util/model/tipo-intermediacao-model';
import { TipoRegraContabil } from 'app/util/model/tipo-regra-contabil.model';
import { TransporteTipo } from 'app/util/model/tipo-transporte-model';
import { Uf } from 'app/util/model/uf.model';
import { UnidadeMedida } from 'app/util/model/unidade-medida.model';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs/Subscription';
import { isNullOrUndefined } from 'util';
import { ConfigService } from '../../shared/services/config.service';
import { CrudService } from '../../shared/services/crud.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { HttpService } from '../../shared/services/http.service';
import { SessionService } from '../../shared/services/session.service';
import { TitleService } from '../../shared/services/title.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { UtilService } from '../../shared/services/util.service';
import { NfeFinalidade } from '../model/nfe-finalidade.model';
import { NfePresencial } from '../model/nfe-presencial.model';
import { PedidoAprovacao } from '../model/pedido-aprovacao.model';
import { PedidoExportacao } from '../model/pedido-exportacao.model';
import { ImportacaoAdicao } from '../model/pedido-importacao.model';
import { PedidoItemRateio } from '../model/pedido-item-rateio.model';
import { PedidoItem } from '../model/pedido-item.model';
import { PedidoParcela } from '../model/pedido-parcela.model';
import { PedidoStatus } from '../model/pedido-status.model';
import { PedidoTipo } from '../model/pedido-tipo.model';
import { Pedido } from '../model/pedido.model';

declare var $: any;

@Component({
  selector: 'app-pedido-produto-new',
  templateUrl: './pedido-produto-new.component.html',
  styleUrls: ['./pedido-produto-new.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class PedidoProdutoComponentNew implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;

  currentStatePedidoProduto = 'initial';
  pedidoEdit: any;
  todoCardId: any;



  changeStatePedidoProduto() {
    this.currentStatePedidoProduto = this.currentStatePedidoProduto === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private httpService: HttpService,
    private httpClient: HttpClient,
    private utilService: UtilService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private toolbarService: ToolbarService,
    private pedidoService: PedidoService,
    private messageService: MessageService,
    private sidebarService: SidebarService,
    private socketService: PysocketService
  ) {

    this.toolbarService.hidden = true
    this.data = 10;

  }

  private LogManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  data: number;
  title: string;
  subtitle: string;
  modalPeriodo: boolean;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;
  tipo: number;
  todosSelecionados1: boolean;
  todosSelecionados2: boolean;
  todosSelecionados3: boolean;
  todosSelecionados4: boolean;
  visualizarContabil: boolean;
  modalParticipante: boolean = false
  newRequest: boolean = false
  usaAsaas: boolean = false
  faturaAutomaticoLote: boolean = false;
  visualizaOutrosPedidos: boolean = true


  pedidosCompraVenda: Array<any> = new Array();
  pedidosNegociacao: Array<any> = new Array();
  pedidosRecusados: Array<any> = new Array();
  pedidosFinalizados: Array<any> = new Array();
  pedidosSelecionados: Array<any> = new Array();
  idPedidoView: Array<any> = new Array();
  idPedidoExcluirView: Array<any> = new Array();

  valorPedidosSelecionados: number;
  valorTotalPedidos: number = 0
  // totalValue: number = 0;

  nfsEmitida: number = 0;

  numeroAba: number;

  pedido: any;
  pedidoItem: PedidoItem;

  replace: boolean = null;

  opcoesAgencia: SelectItem[];
  opcoesPagamentoComissao: SelectItem[];
  opcoesStatusPedido: SelectItem[];
  opcoesStatusAprovacao: SelectItem[];
  opcoesModelo: SelectItem[];
  opcoesMoeda: SelectItem[];
  opcoesOperacao: SelectItem[];
  opcoesOperacaoCont: SelectItem[];
  opcoesItens: SelectItem[] = [];
  opcoesContas: SelectItem[];
  opcoesFormaPagamento: SelectItem[];
  opcoesCentroCusto: SelectItem[];
  opcoesProjeto: SelectItem[];
  opcoesIncoterm: SelectItem[];
  opcoesTipoPagamento: SelectItem[];
  tiposBaixas: SelectItem[];
  opcoesVendedor: SelectItem[];
  pedidoAprovacao = new PedidoAprovacao;
  opcoesCfop: SelectItem[];
  cfopList: Array<any> = new Array();
  opcoesAlmoxarifado: SelectItem[];
  opcoesUnidadeMedida: SelectItem[];
  opcoesUnidadeMedidaOriginal: SelectItem[];
  opcoesCapituloTipi: SelectItem[];
  opcoesCstIcms: SelectItem[];
  opcoesOrigemCstIcms: SelectItem[];
  opcoesCsosn: SelectItem[];
  opcoesModBcIcms: SelectItem[];
  opcoesModBcIcmsSt: SelectItem[];
  opcoesCstIpi: SelectItem[];
  opcoesCodEnqIpi: SelectItem[];
  opcoesCstPis: SelectItem[];
  opcoesCstCofins: SelectItem[];
  opcoesTipoFrete: SelectItem[];
  opcoesTransportador: SelectItem[];
  opcoesDesconto: SelectItem[];
  opcoesStatusEntrega: SelectItem[] = [];
  tiposPagamentos: SelectItem[] = []

  exibirPedido: boolean;
  infoParticipante: any;
  pedidoExterior: boolean;
  botaoEditar: boolean;
  exibirItemPedido: boolean;
  isReadOnly: boolean;
  exibirModalContrato: boolean;
  exbirModalAgencia: boolean;
  porcentagemCc: number;
  porcentagemCadProj: number;
  rateioCentroCusto: number;
  rateioCadProj: number;
  modalCompensacao: boolean;

  contador: number;
  contadorRateio: number;

  visualizacaoContabil: any;
  visualizacaoFiscal: any;

  subTotalPedido: any;
  valorDescontoItens: any;
  valorImpostosRetidos: any;
  valorTotalPedido: any;
  pedidoParcela = new PedidoParcela();
  adtosRelacionadosPedido: Array<any> = new Array();

  modalImpressao: boolean;
  impressaoFormaPagamento: boolean;

  // inicio variaveis forma pgto
  qtdParcelas: number;
  modalFormaPgto: boolean;
  primeroVencimento = new Date();
  valorTotalParcelas: any;
  calculaParcelasPgto: boolean;
  pedidoItemRateio: PedidoItemRateio;
  permiteAlterarConta: boolean = true

  proj: any;
  cc: any;

  adtosPart: Array<any> = new Array();
  adtosOutrosPart: Array<any> = new Array();
  adtosCompensar: Array<any> = new Array();
  valorTotalCompensar: number;
  modalDetalheAdtos: boolean;
  detalheAdtos: Array<any> = new Array();
  tipoPagamento: number;

  valorTotalAdtosCompensados: number;
  valorTotalFormasPagto: any;
  valorTotalFormasPagtoTela: any;
  // final

  somaParcelas: number;
  habilitarSolicitar: boolean = false;
  modalClonePedido: boolean;
  cloneStatusPedido: number = null;
  cloneDataEmissao: Date;
  opcoesParticipante: SelectItem[];
  opcoesUf: SelectItem[];
  modalCentroCusto: boolean;
  exibirRateio: boolean;
  opcoesTipoIntermediacao: SelectItem[];
  opcoesViaTransp: SelectItem[];

  importacaoAdicao: ImportacaoAdicao
  pedidoExportacao: PedidoExportacao;
  tipoUnidadeMedida: Array<UnidadeMedida> = new Array();
  almoxarifados: Array<EmpresaAlmoxarifado> = new Array();
  // importação
  ufs: Array<Uf> = new Array();
  transporteTipos: Array<TransporteTipo> = new Array();
  cfops: Array<Cfop> = new Array();
  tipi: Array<Tipi> = new Array();
  tipoIntermediacao: Array<TipoIntermediacao> = new Array();

  // impostos
  valorIcms: any;
  valorBcIcms: any;
  valorIcmsSt: any;
  valorPis: any;
  valorCofins: any;
  valorIpi: any;
  valorSeguro: any;
  valorFrete: any;
  valorOutrasDespesas: any;
  valorImpostoImportacao: any;
  valorBcIcmsSt: any;
  valorIcmsUfDestino: any;
  valorIcmsUfRemetente: any;
  valorFcp: any;
  valorTotalTributos: any;
  valorItemTributavel: any;
  numeroSequencialItem: number;

  contadorAdicao: number;
  contadorExport: number;
  exbirModalTransportador: boolean;

  somaRateio: number;
  modalStatusPedidoLote: boolean;
  todosSelecionados: boolean;

  opcoesConsumidorFinal: SelectItem[];
  opcoesNfePresencial: SelectItem[];
  opcoesNfeFinalidade: SelectItem[];

  modalReferenciarNfe: boolean;
  nfsParticipantes: Array<any> = new Array();
  itensNfsParticipantes: Array<any> = new Array();
  modalClonePedidoLote: boolean;

  msg: string;
  telefoneContato: boolean;
  operacaoRegraIds: Array<any>;
  codigoMoeda: string;
  centroCustoId: number;
  operacaoRegraGeraFinanceiro: boolean;
  operacaoRegraId: number;
  defaultDate: Date;
  exibirModalImportarItens: boolean;
  file: File;
  valorUnitImportacao: number;
  seguroOuFreteAcionado: boolean = false;
  pedidosFiltrados: Array<any>;

  /*Variaveis Card*/
  statusNegociacao: boolean = true;
  statusCompras: boolean = false;
  statusRecusado: boolean = false;
  statusFinalizado: boolean = false;

  maxParcelas: number;
  parcelasExatas: boolean = false
  validacaoError: boolean;

  informacoesOperacoes: any;
  pedidoMisto: boolean = false;
  mesmaUf: boolean;
  clienteContribuinteIcms: boolean;

  financeiroAntecipado: boolean;
  operacaoRegraAntecipado: number;
  vendedorId: number;
  comissaoAtiva: boolean;
  valorMva: number = 0;

  //Cupom Fiscal
  modalCupomFiscal: boolean = false;
  listaCupons: Array<any> = new Array();
  cuponsSelecionados: Array<number> = new Array();
  cuponsTodosSelected: boolean = false;
  valorTotalCupons: number = 0;
  centroCustoObrigatorio: boolean;

  editPedido: boolean;

  modalRefCupom: boolean = false;
  exibirModalRejeicao: boolean = false;
  descricaoMotivoRejeicao: string = null;

  filterValueChaveEletronica5: any;
  filterValueNumber5: any;
  filterValueParticipant5: any;
  filterValueCnpj5: any;
  filterValueData5: any;
  filterValueData6: any;
  filterValueCFOP5: any;
  filterValueValor5: any;
  filterValueNf6: any;
  filterValueItem6: any;
  filterValueItemRelacionado6: any;
  filterValueOperacao6: any;
  filterValueCFOP6: any;
  filterValueQuantidade6: any;
  filterValueSaldo6: any;
  filterValueUtilizar6: any;
  filterValueCodigoCoo7: any;
  filterValueCodigoCcf7: any;
  filterValueNome7: any;
  filterValueCpfCnpj7: any;
  filterValueSaldoFinal6: any;
  filterValueValorUnit6: any;
  filterValueValorTotal6: any;
  filterValueHoraEmissao7: any;
  filterValueValor7: any;

  modalDataEmissao: boolean = false;
  modalDeadline: boolean = false;
  isBatch: boolean = false;
  allSelected: boolean = false;

  requestCode: string;
  requestProvider: string;
  requestCNPJ: string;
  requestCodControl: string;
  requestTitle: string = 'Pedido';
  requestId;
  modalDeadlineTitle: string = '';
  modalEmissaoTitle: string = '';
  filtroData: string = null

  requestItens: Array<any> = new Array();
  itensSelected: Array<any> = new Array();
  itensClearArray: Array<any> = new Array();

  buscarCustoMedio: boolean = null;
  buscarValorVenda: boolean = null;

  itemDate: Date;
  itemObs: string;

  typeReq: number;

  params: number;

  cols: Array<any> = new Array();
  flowUses: boolean = false;
  showSolicitarAprovacao: boolean = false;
  showChangeDataEntrega: boolean = false;
  requestApproval: boolean = false;
  modalStatusEntrega: boolean = false;
  statusEntregaId: number = null;
  somaIpiBc: boolean;
  relacGeraImposto = { 'icms': false, 'ipi': false, 'pis': false, 'cofins': false }
  travarEdicaoItemPedido: boolean = false

  valorTotalPedidosNegociacao: number = 0
  valorTotalPedidosRecusado: number = 0
  valorTotalPedidosFinalizado: number = 0
  valorTotalPedidosComprasVendas: number = 0

  totalPedidosNegociacao: number = 0
  totalPedidosRecusado: number = 0
  totalPedidosFinalizado: number = 0
  totalPedidosComprasVendas: number = 0

  totalPedidosNegociacaoPaginacao: number = 0
  totalPedidosRecusadoPaginacao: number = 0
  totalPedidosFinalizadoPaginacao: number = 0
  totalPedidosComprasVendasPaginacao: number = 0

  userVendedorId: number = null

  statusPesquisado: string = 'negociacao'

  primeiraChamadaNegociacao: boolean
  primeiraChamadaComprasVendas: boolean
  primeiraChamadaRecusado: boolean
  primeiraChamadaFinalizado: boolean
  telaAtual: string = 'TELA_1'

  @ViewChild('dt10') dt10: Table
  @ViewChild('dt20') dt20: Table
  @ViewChild('dt30') dt30: Table
  @ViewChild('dt40') dt40: Table

  pedidoItensRateioEdicao: Array<any> = new Array();

  agenciaId: number = 0;
  pagamentoComissao: number = 0;
  valorComissao: number = 0;

  redirectnewProduct: boolean = false

  aliquotaInterna: any;
  aliquotaInter: any;
  aliquotaInterVenda: any;
  aliquotaInternaPart: any;
  ieEmpresa: string;

  fatoresConversao: Array<any> = new Array();
  unidadeMedidaOriginalId: number = 0;
  codigoUnidadeMedidaOriginal: string;

  httpOptions: Object = new Object()
  vinculadoOpt: SelectItem[] = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ]
  listarXml: boolean = true
  vincularItens: boolean = false
  excluiIcmsPisCofins: boolean = false
  excluiIcmsPisCofinsCompra: boolean = false
  xmlSelecionado: any = {}
  itensXmlSelecionados: Array<any> = new Array()

  skeletonConfigXml = new SkeletonModel({
    columns: [
      { name: 'DATA', size: '20%', textAlign: 'center' },
      { name: 'NÚMERO', size: '20%', textAlign: 'left' },
      { name: 'CHAVE ELETRÔNICA', size: '40%', textAlign: 'left' },
      { name: 'VALOR', size: '10%', textAlign: 'right' },
      { name: 'AÇÃO', size: '5%', textAlign: 'center' },
      { name: 'VINCULADO', size: '5%', textAlign: 'center' }
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: false
  })

  skeletonConfigItens = new SkeletonModel({
    columns: [
      { name: 'ITEM', size: '20%', textAlign: 'left' },
      { name: 'ITEM RELACIONADO', size: '20%', textAlign: 'left' },
      { name: 'OPERAÇÃO', size: '20%', textAlign: 'left' },
      { name: 'VL UNIT', size: '10%', textAlign: 'right' },
      { name: 'QUANTIDADE', size: '5%', textAlign: 'center' },
      { name: 'VL TOTAL', size: '10%', textAlign: 'right' },
      { name: 'RELACIONADO', size: '5%', textAlign: 'center' }
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true
  })

  toolbarMainIni = [
    {
      key: 'more', icon: 'more_vert', text: '', tooltip: 'Opções', visible: true, disabled: false, color: 'default', dropdown: true,
      options: [
        { optionKey: 'export', label: 'Exportar pedidos', callback: () => this.exportarPedidos() },
        { optionKey: 'sidebar', label: 'Abrir Sidebar', callback: () => this.rowSidebar(this.pedidosSelecionados[0]) },
        { label: 'Alterar Periodo', callback: () => this.openModalPeriodo() },
        { optionKey: "status", label: 'Alterar Status', callback: () => this.modalStatusPedidosLote(), visible: false },
        { label: 'Atualizar', callback: () => this.atualizarRelatorio() },
        { optionKey: "clone", label: 'Clonar', callback: () => this.modalClonarPedido(), visible: false },
        { optionKey: 'escriturar', label: 'Escriturar', callback: () => this.escritLote(), visible: true },
        { optionKey: "generateBill", label: 'Gerar boleto', callback: () => this.gerarBoletoFin(), visible: false },
        { optionKey: 'cobranca', label: 'Enviar fatura', callback: () => this.gerarCobranca(true), visible: false },
        { optionKey: "print", label: 'Imprimir', callback: () => this.imprimirPedido() },
        { optionKey: "deadLine", label: 'Prazo entrega', callback: () => { }, visible: false },
        { optionKey: "dataEmissao", label: 'Data emissão', callback: () => { }, visible: false },
        { optionKey: 'request', label: 'Solicitar Aprovação', callback: () => this.solicitarAprovacao(true) },
        { optionKey: 'changeDeliveryDate', label: 'Status entrega', callback: () => this.openModalStatusEntrega(), visible: false },
        { optionKey: 'contract', label: 'Gerar contrato', visible: false, callback: () => this.changeContractsModal(true) },
        { optionKey: 'previa', label: 'Gerar Prévia Emissão', callback: () => this.emissaoPreviaLote(), visible: true },
        { optionKey: 'validador', label: 'Abrir Validador XML', callback: () => this.abrirValidador(), visible: true },
      ]
    },
    { key: "reject", icon: 'info', text: 'Info Rejeição', tooltip: 'Info Rejeição', visible: false, disabled: false, color: 'default', onClick: () => this.verificarRejeicao() },
    { key: "delete", icon: 'delete', text: 'Excluir', tooltip: 'Excluir Pedido', visible: false, disabled: false, color: 'red', onClick: () => this.excluirPedido() },
    { key: "edit", icon: 'edit', text: 'Editar', tooltip: 'Editar Pedido', visible: false, disabled: false, color: 'default', onClick: () => this.editarPedido() },
    { key: "cobranca", icon: 'request_quote', text: 'Ver fatura', tooltip: 'Ver fatura', visible: false, disabled: false, color: 'default', onClick: () => this.gerarCobranca() },
    { key: "new", icon: 'add', text: 'Novo', tooltip: 'Novo Pedido', visible: true, disabled: false, color: 'green', onClick: () => this.buscarParticipante() },
    { key: "emit", icon: 'add', text: 'Emitir NF', tooltip: 'Emitir NF', visible: true, disabled: false, color: 'default', onClick: () => { } },
  ];

  toolbarEditIni = [
    { icon: 'chevron_left', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: false, color: 'default', onClick: () => this.buscarPedidos() },
    { key: "print", icon: 'print', text: 'Imprimir', tooltip: 'Imprimir Pedido', visible: true, disabled: false, color: 'default', onClick: () => this.imprimirPedido() },
    { key: 'view', icon: 'find_in_page', text: 'Visualizar', tooltip: 'Visualizar Pedido', visible: true, disabled: false, color: 'default', onClick: () => this.visualizarEscrituracao() },
    // { key: 'info', icon: 'info', text: 'Dados Contrato', tooltip: 'Inserir Dados Contrato', visible: false, disabled: false, color: 'default', onClick: () => this.exibirModalContrato = true },
    { key: 'emit', icon: 'text_snippet', text: 'Emitir NF', tooltip: 'Emitir NF', visible: false, disabled: false, color: 'default', onClick: () => this.emitirNfEletronica() },
    { key: 'save', icon: 'save', text: 'Salvar e Escriturar', tooltip: 'Salvar e Escriturar', visible: true, disabled: false, color: 'green', onClick: () => this.atualizarPedido(true) },
    { key: 'save', icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'green', onClick: () => this.atualizarPedido(false) }

  ];

  toolbarXmlIni = [
    { key: "voltar", icon: 'chevron_left', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.voltarListaXml() },
    { key: "save-back", icon: 'save', text: 'Salvar e Voltar', tooltip: 'Salvar vinculação e voltar para lista', visible: false, disabled: false, color: 'green', onClick: () => this.salvarVinculacaoXml(true) },
    { key: "save-create", icon: 'save', text: 'Salvar e Criar', tooltip: 'Salvar vinculação e criar o pedido', visible: false, disabled: false, color: 'green', onClick: () => this.salvarVinculacaoXml(false) },
  ]

  public toolbarMain = new Toolbar()
  public toolbarEdit = new Toolbar()
  public toolbarXml = new Toolbar()

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'DATA DE ENTREGA', size: '10%', textAlign: 'center' },
      { name: 'DATA DE EMISSÃO', size: '10%', textAlign: 'left' },
      { name: 'DATA ENTRADA', size: '10%', textAlign: 'left' },
      { name: 'CÓDIGO', size: '10%', textAlign: 'center' },
      { name: 'CONTROLE', size: '10%', textAlign: 'center' },
      { name: 'NOME', size: '20%', textAlign: 'center' },
      { name: 'TIPO', size: '10%', textAlign: 'center' },
      { name: 'OBJETO', size: '10%', textAlign: 'center' },
      { name: 'VALOR', size: '10%', textAlign: 'center' },
      { name: 'SALDO', size: '10%', textAlign: 'center' },
      { name: 'BAIXA', size: '10%', textAlign: 'center' },
      { name: 'USUÁRIO', size: '10%', textAlign: 'center' },
      { name: 'USUÁRIO CRIAÇÃO', size: '10%', textAlign: 'center' },
      { name: 'STATUS', size: '10%', textAlign: 'center' },
      { name: 'STATUS APROVAÇÃO', size: '15%', textAlign: 'center' },
      { name: 'COBRANÇA GERADA', size: '5%', textAlign: 'center' },
      { name: 'COBRANÇA PAGA', size: '5%', textAlign: 'center' },
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true,
  })

  cobGeradaOpt: SelectItem[] = [
    { label: "SIM", value: true },
    { label: "NÃO", value: false },
  ]

  cobPagaOpt: SelectItem[] = [
    { label: "NÃO PAGA", value: 0 },
    { label: "PAGA PARCIALMENTE", value: 1 },
    { label: "PAGA", value: 2 },
  ]

  readjustmentContract: number = 0
  readjustmentPercentageContract: number = 0
  loadingGenereateContracts: boolean = false
  exibirModalContratoTable: boolean = false
  contratoAtivo: boolean = false
  dateLimitContract: Date = new Date()
  dateReadjustmentContract: Date = new Date()
  optionsReadjustment: Array<SelectItem> = [{ label: 'NENHUM', value: 0 }, { label: 'INPC', value: 1 }, { label: 'IPCA', value: 2 }, { label: 'SELIC', value: 3 }, { label: 'IGP-M', value: 4 }, { label: 'PERSONALIZADO', value: 5 }]
  referenciamentoNfe: boolean = false

  ngOnInit() {

    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarEdit.setAll(this.toolbarEditIni)
    this.toolbarXml.setAll(this.toolbarXmlIni)

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.mesmaUf = false;
    this.clienteContribuinteIcms = false;
    this.exibirPedido = false;
    this.infoParticipante = '';
    this.pedidoExterior = false;
    this.modalCentroCusto = false;
    this.exibirRateio = false;
    this.modalStatusPedidoLote = false;
    this.modalCompensacao = false;
    this.modalClonePedidoLote = false;
    this.exibirModalImportarItens = false;
    this.financeiroAntecipado = false;
    this.operacaoRegraAntecipado = 0;
    this.vendedorId = 0;
    this.faturaAutomaticoLote = false;

    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;
    this.modalPeriodo = false;

    this.pedido = new Pedido();
    this.pedido.modNf = new ModNf();
    this.pedido.moeda = new Moeda();
    this.pedido.pedidoStatus = new PedidoStatus();
    this.pedido.formaPagamento = new FormaPagamento();
    this.pedido.empresaConta = new EmpresaConta();
    this.pedido.tipoFrete = new TipoFrete();
    this.visualizacaoContabil = new Array();
    this.importacaoAdicao = new ImportacaoAdicao();
    this.pedidoExportacao = new PedidoExportacao();

    this.pedido.pedidoStatus.id = 0;
    this.pedido.moeda.id = 1;
    this.pedido.txMoeda = 1.00;
    this.pedido.moeda.simbolo = 'R$';
    this.contadorRateio = 0;
    this.rateioCentroCusto = 100;
    this.pedido.converterTaxa = false;

    this.pedido.formaPagamento.id = null;
    this.pedido.empresaConta.id = null;
    this.pedido.dtFaturamentoAutomatico = new Date();
    this.defaultDate = new Date();
    this.impressaoFormaPagamento = false;
    this.modalImpressao = false;
    this.editPedido = false;
    this.somaIpiBc = false;
    this.relacGeraImposto = { 'icms': false, 'ipi': false, 'pis': false, 'cofins': false }

    this.activatedRoute.params.subscribe(params => {

      if (this.tipo === 9 || this.tipo !== params.tipo) {

        this.params = params.tipo

        this.redirectnewProduct = (params.action == 'newProductPurchases') || (params.action == 'newProductSales')

        this.numeroAba = 0;
        this.todosSelecionados1 = false;
        this.todosSelecionados2 = false;
        this.todosSelecionados3 = false;
        this.todosSelecionados4 = false;

        this.pedidosNegociacao.forEach(element => {
          element.selected = false;
        });

        this.pedidosCompraVenda.forEach(element => {
          element.selected = false;
        });

        this.pedidosRecusados.forEach(element => {
          element.selected = false;
        });

        this.pedidosFinalizados.forEach(element => {
          element.selected = false;
        });

        this.tipo = params.tipo;
        this.exibirPedido = false;
        //this.inicializar();
      }
    });

    /*HIDE SIDEBAR FULLSCREEN (DO NOT REMOVE)*/
    $(document).ready(() => {
      $(".btn-full").click(
        function () {
          $(".mat-drawer-side").toggleClass('d-none');
        }
      );
    });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
          this.dataIni = null;
          this.dataFin = null;
          let lastDay = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0); // ultimo dia do mês

          let mes = this.dblinkedSessionService.periodo.month.value > 9 ?
            this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;

          this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
          this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

          const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
          this.defaultDate = new Date(dt);
          this.inicializar();
        }

      });

    this.cols = [
      { field: 'dt_entrega', header: 'DATA DE ENTREGA' },
      { field: 'dt_emissao', header: 'DATA DE EMISSÃO' },
      { field: 'dt_faturamento', header: this.tipo == 0 ? 'DATA ENTRADA' : 'DATA SAÍDA' },
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'codigoControle', header: this.dblinkedSessionService.empresa.id == 158 ? 'JIRA' : 'CONTROLE' },
      { field: 'participante', header: 'PARTICIPANTE' },
      { field: 'pedidoTipo', header: 'TIPO' },
      { field: 'valorTotalPorItensFormatado', header: 'VALOR' },
      { field: 'valorSaldoFormatado', header: 'SALDO' },
      { field: 'usuario', header: 'USUÁRIO' },
      { field: 'pedidoStatus', header: 'STATUS' }
    ]
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
  }

  async inicializar(resetarTudo: boolean = true) {

    if (this.redirectnewProduct) this.buscarParticipante()
    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }

    this.vendedorId = 0;
    this.exibirPedido = false;

    this.centroDeCustoObrigatorio()
    this.changeToolbar()
    this.comboAlmoxarifado()

    await Promise.all([
      this.buscarParametroFinanceiro(),
      this.getCompanyParameter(),
      this.loadConfigApi(),
      this.verificarVendedor()
    ]).finally(() => {
      this.carregarInformacoesComuns()
      this.buscarPedidos()
    })

    //this.loadTiposPagamentos()

    let dataIni2 = `${this.dataIniSearch.substring(8, 10)}/${this.dataIniSearch.substring(5, 7)}/${this.dataIniSearch.substring(0, 4)}`;
    let dataFin2 = `${this.dataFinSearch.substring(8, 10)}/${this.dataFinSearch.substring(5, 7)}/${this.dataFinSearch.substring(0, 4)}`;

    this.subtitle = `PERÍODO SELECIONADO: ${dataIni2} à ${dataFin2}`

    this.modalPeriodo = false;

    this.exibirModalContrato = false;
    this.exbirModalAgencia = false;
    this.exibirModalImportarItens = false;
    this.exibirModalImportarItens = false;
    this.botaoEditar = false;
    this.requestApproval = false;

    this.valorPedidosSelecionados = 0.00;
    this.valorTotalPedidos = 0.00;
    this.isReadOnly = false;

    this.subTotalPedido = 0.00;
    this.valorDescontoItens = 0.00;
    this.valorImpostosRetidos = 0.00;
    this.valorTotalPedido = 0.00;

    this.todosSelecionados1 = false;
    this.todosSelecionados2 = false;
    this.todosSelecionados3 = false;
    this.todosSelecionados4 = false;

    this.idPedidoView = [];
    this.pedidosSelecionados = [];
    this.pedido = new Pedido();
    this.valorItemTributavel = null;
    this.exibirRateio = false;
    this.somaIpiBc = false;
    this.relacGeraImposto = { 'icms': false, 'ipi': false, 'pis': false, 'cofins': false }

    this.valorTotalPedidosNegociacao = 0
    this.valorTotalPedidosRecusado = 0
    this.valorTotalPedidosFinalizado = 0
    this.valorTotalPedidosComprasVendas = 0

    // const pedidoURL = '/custom/pedido/get-pedidos-new(' + this.dblinkedSessionService.empresa.id +
    // ',' + (this.tipo == 0 ? 'pcp' : 'pvp') + ',' + this.dataIniSearch + ','
    // + this.dataFinSearch + ',' + this.sessionService.loggedUser.id + ',' + (this.dblinkedSessionService.filiaisSelecionadas.length === 0 ? 0 : this.dblinkedSessionService.filiaisSelecionadas)+')';

    this.totalPedidosNegociacao = 0
    this.totalPedidosRecusado = 0
    this.totalPedidosFinalizado = 0
    this.totalPedidosComprasVendas = 0
    // this.statusPesquisado = null


    this.totalPedidosNegociacaoPaginacao = 0
    this.totalPedidosRecusadoPaginacao = 0
    this.totalPedidosFinalizadoPaginacao = 0
    this.totalPedidosComprasVendasPaginacao = 0

    // if (resetarTudo) {
    //   this.getQuantidadePedidos()

    //   this.primeiraChamadaNegociacao = true
    //   this.primeiraChamadaComprasVendas = true
    //   this.primeiraChamadaRecusado = true
    //   this.primeiraChamadaFinalizado = true
    //   this.determinarPrimeiraChamada()
    // }

    this.titleService.title = (this.tipo == 0 ? 'Pedido Compra - Produto' : 'Pedido Venda - Produto');
    this.title = (this.tipo == 0 ? 'COMPRAS E ORÇAMENTO DE PRODUTO' : 'VENDAS E ORÇAMENTO DE PRODUTO');
  }

  loadList(url: string, list: Array<any>) {
    this.skeletonConfig.startLoad();
    this.httpService.get(url).subscribe(
      result => {
        list.length = 0;
        result.value.forEach(value => {
          list.push(value);
        });
      },
      error => this.httpService.handleError(error, () => this.loadList(url, list)),
      () => this.skeletonConfig.endLoad()
    );
  }

  combosUteis() {
    this.ufs = [];

    const listURL = [
      //{ url: '/uf?$select=id,uf&$orderby=uf', destination: this.ufs },
      //{ url: '/transportetipo?$select=id,descricao&$orderby=descricao', destination: this.transporteTipos },
      //{ url: '/tipointermediacao?$select=id,descricao&$orderby=descricao', destination: this.tipoIntermediacao },
      //{ url: '/unidademedida?$select=id,codigo&$filter=unidadePadrao eq true&$orderby=codigo', destination: this.tipoUnidadeMedida },
    ]

    // listURL.forEach(item => this.loadList(item.url, item.destination))

    //this.comboFinalidade();
    //this.comboPresencial();


    // this.httpService.get('/tipodesconto?$select=id,cod&$orderby=cod')
    //   .subscribe(res => this.comboDesconto(res.value),
    //     error => this.httpService.handleError(error, () => this.combosUteis()),
    //     () => this.skeletonConfig.endLoad());

    // opçoe se NFe destina a consumidor final
    this.opcoesConsumidorFinal = [];
    this.opcoesConsumidorFinal.push({ label: 'Sim', value: true });
    this.opcoesConsumidorFinal.push({ label: 'Não', value: false });


  }


  carregarInformacoesComuns() {

    this.combosUteis();

    this.opcoesStatusPedido = [];
    this.opcoesModelo = [];
    this.opcoesMoeda = [];
    this.opcoesIncoterm = [];
    this.opcoesAgencia = [];
    this.opcoesAgencia.push({ label: 'Selecione a Agência', value: 0 });

    this.opcoesPagamentoComissao = [];
    this.opcoesPagamentoComissao.push({ label: 'Selecione a Opção', value: 0 });
    this.opcoesPagamentoComissao.push({ label: 'Agência', value: 1 });
    this.opcoesPagamentoComissao.push({ label: 'Empresa', value: 2 });

    //this.opcoesTipoBaixa = [];
    this.opcoesOrigemCstIcms = [];
    this.opcoesOrigemCstIcms.push({ label: 'Selecione Origem', value: null });
    this.opcoesCstIcms = [];
    this.opcoesCstIcms.push({ label: 'Selecione CST', value: null });

    this.opcoesModBcIcms = [];
    this.opcoesModBcIcms.push({ label: 'Selecione Mod.Deter', value: null });

    this.opcoesCsosn = [];
    this.opcoesCsosn.push({ label: 'Selecione CSOSN', value: null });

    this.opcoesModBcIcmsSt = [];
    this.opcoesModBcIcmsSt.push({ label: 'Selecione Mod.Deter', value: null });

    this.opcoesCodEnqIpi = [];
    this.opcoesCodEnqIpi.push({ label: 'Selecione Cód Enquadramento', value: null });


    this.opcoesCstIpi = [];
    this.opcoesCstIpi.push({ label: 'Selecione CST', value: null });


    this.opcoesCstCofins = [];
    this.opcoesCstPis = [];
    this.opcoesCstCofins.push({ label: 'Selecione CST', value: null });
    this.opcoesCstPis.push({ label: 'Selecione CST', value: null });

    this.opcoesOperacao = [];
    this.opcoesOperacao.push({ label: ('Nenhum'), value: null });

    this.opcoesOperacaoCont = [];
    this.opcoesOperacaoCont.push({ label: 'Selecione', value: 0 });

    this.opcoesNfeFinalidade = [];
    this.opcoesNfePresencial = [];
    this.tiposBaixas = [];
    this.tipoIntermediacao = [];
    this.opcoesUf = [];
    this.opcoesUnidadeMedida = [];
    this.opcoesUnidadeMedidaOriginal = [];
    this.opcoesTipoIntermediacao = [];
    this.opcoesViaTransp = [];
    this.opcoesTipoFrete = [{ label: 'NENHUM', value: 999 }];

    this.opcoesDesconto = []

    this.skeletonConfig.startLoad();
    this.httpService.get('/custom/cadastro/buscar-infos-comuns', [(this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId), 3, this.tipo])
      .subscribe(result => {

        result.forEach(element => {

          if (element.tipo === 'EXCLUIRICMSBASE') {

            this.excluiIcmsPisCofins = element.cod === 'true';
          } else if (element.tipo === 'EXCLUIRICMSBASECOMPRA') {

            this.excluiIcmsPisCofinsCompra = element.cod === 'true';
          } if (element.tipo === 'PEDIDOSTATUS') {
            if (this.tipo == 0) {
              if (element.id === 1 || element.id === 7 || element.id === 12) {
                this.opcoesStatusPedido.push({ label: element.desc, value: element.id });
                this.opcoesStatusAprovacao.push({ label: element.desc, value: element.id });
              }

            } else {
              if (element.id === 1 || element.id === 4 || element.id === 12) {
                this.opcoesStatusPedido.push({ label: element.desc, value: element.id });
                this.opcoesStatusAprovacao.push({ label: element.desc, value: element.id });
              }

            }
          } else if (element.tipo === 'AGENCIA') {
            this.opcoesAgencia.push({ label: (element.cod), value: element.id });
          } else if (element.tipo === 'MODNF') {
            if (element.cod !== 'NFS' && element.cod !== '100') {
              this.opcoesModelo.push({ label: (element.cod + ' - ' + element.desc), value: element.id });
            }

          } else if (element.tipo === 'MOEDA') {
            this.opcoesMoeda.push({ label: element.cod, value: element.id });
          } else if (element.tipo === 'TIPOINCOTERM') {
            this.opcoesIncoterm.push({ label: element.cod, value: element.id });
          } else if (element.tipo === 'TIPOBAIXA') {
            this.tiposBaixas.push({ label: element.cod, value: element.id });
          } else if (element.tipo === 'CSTICMSTABELAA') {
            this.opcoesOrigemCstIcms.push({ label: element.cod + ' - ' + element.desc, value: element.id });
          } else if (element.tipo === 'CSTICMSTABELAB') {
            this.opcoesCstIcms.push({ label: element.cod + ' - ' + element.desc, value: element.id });
          } else if (element.tipo === 'MODBCICMS') {
            this.opcoesModBcIcms.push({ label: element.cod + ' - ' + element.desc, value: element.cod });
          } else if (element.tipo === 'CSOSN') {
            this.opcoesCsosn.push({ label: element.cod + ' - ' + element.desc, value: element.cod });
          } else if (element.tipo === 'MODBCICMSST') {
            this.opcoesModBcIcmsSt.push({ label: element.cod + ' - ' + element.desc, value: element.cod });
          } else if (element.tipo === 'CODENQIPI') {
            this.opcoesCodEnqIpi.push({ label: element.cod + ' - ' + element.grupo, value: element.cod });
          } else if (element.tipo === 'CSTIPI') {
            this.opcoesCstIpi.push({ label: element.cod + ' - ' + element.desc, value: element.id });
          } else if (element.tipo === 'CSTPIS') {
            this.opcoesCstCofins.push({ label: element.cod + ' - ' + element.desc, value: element.id });
            this.opcoesCstPis.push({ label: element.cod + ' - ' + element.desc, value: element.id });
          } else if (element.tipo === 'ALIQINT') {
            this.aliquotaInterna = element.cod;
          } else if (element.tipo === 'OPERACAOREGRA') {
            if (this.tipo === 1) {
              if (!isNullOrUndefined(element.grupo)) {
                if (element.grupo == 1 || (element.grupo == 2 && this.pedidoMisto)) {
                  this.opcoesOperacao.push({ label: element.cod + ' - ' + element.desc, value: element.id });
                }
              }
            } else {
              if (!isNullOrUndefined(element.grupo)) {
                if (element.grupo == 1) {
                  this.opcoesOperacao.push({ label: element.cod + ' - ' + element.desc, value: element.id });
                }
              }
            }

          } else if (element.tipo === 'OPERACAOCONTABIL') {
            this.opcoesOperacaoCont.push({ label: element.cod, value: element.id });

          } else if (element.tipo === 'IE') {
            this.ieEmpresa = element.cod;
          } else if (element.tipo === 'TIPOSPAGAMENTOS') {
            this.tiposPagamentos.push({ label: (element.cod + ' - ' + element.desc), value: element.id });
          } else if (element.tipo === 'NFEFINALIDADE') {
            this.opcoesNfeFinalidade.push({ label: (element.cod), value: element.id });
          } else if (element.tipo === 'NFEPRESENCIAL') {
            this.opcoesNfePresencial.push({ label: (element.cod), value: element.id });
          } else if (element.tipo === 'FORMAPAGAMENTO') {

            this.opcoesFormaPagamento.push({ label: element.cod, value: element.id });

          } else if (element.tipo === 'EMPRESACONTA') {

            this.opcoesContas.push({ label: element.cod, value: element.id });
          } else if (element.tipo === 'TIPOINTERMED') {
            this.opcoesTipoIntermediacao.push({ label: element.cod, value: element.id });
          } else if (element.tipo === 'TRANSPORTETIPO') {
            this.opcoesViaTransp.push({ label: element.cod, value: element.id });
          } else if (element.tipo === 'UM') {
            this.opcoesUnidadeMedida.push({ label: element.cod, value: element.id });
            this.opcoesUnidadeMedidaOriginal.push({ label: element.cod, value: element.id });
          } else if (element.tipo === 'UF') {
            this.opcoesUf.push({ label: element.cod, value: element.id });
          } else if (element.tipo === 'TIPOFRETE') {
            this.opcoesTipoFrete.push({ label: element.cod, value: element.id });
          } else if (element.tipo === 'TIPODESCONTO') {
            this.opcoesDesconto.push({ label: element.cod, value: element.id });
          }





        }
        )
      },
        error => this.httpService.handleError(error, () => this.carregarInformacoesComuns()),
        () => this.skeletonConfig.endLoad());

    /*this.combosUteis();
    /*this.opcoesOperacao = [];
    this.pedidoService.getOperacao(this.tipo == 0 ? 3 : 1, 'p', this.opcoesOperacao, this.pedidoMisto);*/

    // método de loading para ser utilizado no service
    const loadMethods = {
      wait: () => this.skeletonConfig.startLoad(),
      done: () => this.skeletonConfig.endLoad(),
    }

    this.opcoesContas = [];
    //this.pedidoService.carregarContas(this.opcoesContas, loadMethods);

    this.opcoesFormaPagamento = [];
    //this.pedidoService.carregarFormaPagto(this.opcoesFormaPagamento, this.tipo, loadMethods);

    this.opcoesCentroCusto = [];
    this.pedidoService.carregarCentroCusto(this.opcoesCentroCusto, loadMethods);

    this.opcoesProjeto = [];
    this.pedidoService.carregarProjetos(this.opcoesProjeto, 0, loadMethods);

    this.opcoesIncoterm = [];
    //this.pedidoService.carregarIncoterm(this.opcoesIncoterm);
    this.tiposBaixas = [];
    //this.pedidoService.carregarTiposBaixa(this.tiposBaixas, loadMethods);

    this.opcoesStatusAprovacao = [];
    //this.pedidoService.getPedidoStatusAprovacao(this.tipo == 0 ? 3 : 1, this.opcoesStatusAprovacao, loadMethods);

    //this.comboTipoFrete();
    this.loadTransportador();
    this.pagamentoTipo();
    this.buscarVendedores();

  }

  retornaIdCentroDeCusto(cadCustoId, rateio) {

    if (!isNullOrUndefined(cadCustoId)) {

      if (this.centroCustoId !== cadCustoId) {
        this.opcoesProjeto = [];
        this.centroCustoId = cadCustoId;
        this.pedidoService.carregarProjetos(this.opcoesProjeto, this.centroCustoId);
      }
    } else {
      this.centroCustoId = 0;
    }

    if (!isNullOrUndefined(rateio)) {
      if (!isNullOrUndefined(rateio.cadProj.id)) {
        rateio.cadProj.id = null;
        rateio.cadProj.codProj = null;
        rateio.cadProj.descrProj = null;
      }
    }
  }




  /* Carregar Pedidos */
  // loadPedidos(value: Array<any>) {

  //   this.pedidosSelecionados = new Array();
  //   this.pedidosCompraVenda = new Array();
  //   this.pedidosNegociacao = new Array();
  //   this.pedidosRecusados = new Array();
  //   this.pedidosFinalizados = new Array();
  //   this.valorTotalPedidos = 0.00;
  //   this.valorTotalPedido = 0.00;

  //   value.forEach(element => {

  //     if (element.pedidoStatusId === 1
  //       || element.pedidoStatusId === 8
  //       || element.pedidoStatusId === 9) {

  //       this.pedidosNegociacao.push(element);
  //       this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valorTotalPorItens);

  //     } else if (element.pedidoStatusId === 2
  //       || element.pedidoStatusId === 4
  //       || element.pedidoStatusId === 7
  //       || element.pedidoStatusId === 12
  //       || element.pedidoStatusId === 13) {

  //       this.pedidosCompraVenda.push(element);

  //     } else if (element.pedidoStatusId === 3
  //       || element.pedidoStatusId === 14) {

  //       this.pedidosRecusados.push(element);

  //     } else if (element.pedidoStatusId === 5) {

  //       this.pedidosFinalizados.push(element);

  //     }
  //   });

  //   this.pedidosNegociacao.forEach(element => {
  //     element.selected = false;
  //     this.pedidosFiltrados = new Array();
  //     if (this.numeroAba === 0) {
  //       this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valorTotalPorItens);
  //     }
  //   });

  //   this.calcTotalValue(this.pedidosNegociacao)

  //   if (this.numeroAba === 1) {
  //     this.pedidosCompraVenda.forEach(element => {
  //       this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valorTotalPorItens);
  //     });
  //     this.calcTotalValue(this.pedidosCompraVenda)
  //   }

  //   if (this.numeroAba === 2) {
  //     this.pedidosRecusados.forEach(element => {
  //       this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valorTotalPorItens);
  //     });
  //     this.calcTotalValue(this.pedidosRecusados)
  //   }

  //   if (this.numeroAba === 3) {
  //     this.pedidosFinalizados.forEach(element => {
  //       this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valorTotalPorItens);
  //     });
  //     this.calcTotalValue(this.pedidosFinalizados)
  //   }

  // }

  tabChange(event: any) {

    this.pedidosSelecionados = new Array();

    this.todosSelecionados1 = false;
    this.todosSelecionados2 = false;
    this.todosSelecionados3 = false;
    this.todosSelecionados4 = false;

    this.valorTotalPedidos = 0.00;
    this.valorTotalPedido = 0.00;

    this.pedidosNegociacao.forEach(element => {
      element.selected = false;
      this.pedidosFiltrados = new Array();
      if (event.index === 0) {
        this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
      }
    });

    this.pedidosCompraVenda.forEach(element => {
      element.selected = false;
      this.pedidosFiltrados = new Array();
      if (event.index === 1) {
        this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
      }
    });

    this.pedidosRecusados.forEach(element => {
      element.selected = false;
      this.pedidosFiltrados = new Array();
      if (event.index === 2) {
        this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
      }
    });

    this.pedidosFinalizados.forEach(element => {
      element.selected = false;
      this.pedidosFiltrados = new Array();
      if (event.index === 3) {
        this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
      }
    });

    this.changeToolbar();

  }

  // calcTotalValue(value: any) {
  //   let total = 0
  //   value.forEach((item: any) => { total += this.utilService.parseDecimal(item.valorTotalPorItensFormatado); })
  //   this.totalValue = total
  // }

  imprimirPedido() {
    this.modalImpressao = true;
  }

  realizarImpressao() {
    const pedidoId = this.pedidosSelecionados[0].id;

    this.httpService.wait();
    this.httpService.get('/custom/pedido/impressao-pedido(' + pedidoId.toString().split('/')[0] + ',' +
      false + ',' + this.impressaoFormaPagamento + ')')
      .subscribe(result => this.exportedPedido(result),
        error => this.httpService.handleError(error, () => this.imprimirPedido()),
        () => {
          this.httpService.done();
          this.modalImpressao = false;
        });

  }

  exportedPedido(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/pedido'), '_blank');
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
    }
  }
  /* Final Habilitar Impressão */

  /* Atualizar valores */
  /*atualizarValorPedidosSelecionados() {
    this.valorPedidosSelecionados = 0.00;

    this.pedidosSelecionados.forEach(element => {
      this.valorPedidosSelecionados = this.valorPedidosSelecionados + this.utilService.parseDecimal(element.valorTotalPorItens);
    });
  }*/
  /* Final Atualizar valores */

  newParticipante(data) {
    const participante = data.selectedParticipant;
    if (!this.botaoEditar) {
      if (participante) {
        this.pedido = new Pedido();
        this.pedido.participanteEmpresaId = participante.part_emp_id;
        this.pedido.pedidoTipo = new PedidoTipo();
        this.pedido.pedidoTipo.id = this.tipo == 0 ? 3 : 1;
        this.pedido.pedidoStatus = new PedidoStatus();
        this.pedido.empresa = new Empresa();
        this.pedido.empresa.id = this.dblinkedSessionService.empresa.id;
        this.pedido.participante = participante;
        this.pedido.pedidoAprovacao = new Array();
        this.pedido.modNf = new ModNf();
        this.pedido.moeda = new Moeda();
        this.pedido.pedidoStatus = new PedidoStatus();
        this.pedido.pedidoItem = new Array();
        this.pedido.empresaConta = new EmpresaConta();
        this.pedido.formaPagamento = new FormaPagamento();

        this.pedido.nfePresencial = new NfePresencial();
        this.pedido.nfePresencial.id = 9; // padrão: Operação não presencial, outros
        this.pedido.nfeFinalidade = new NfeFinalidade();
        this.pedido.nfeFinalidade.id = 1; // padrão: "descricao" : "NF-e normal"
        this.pedido.consumidorFinal = true; // padrão: "SIM"

        this.pedido.transportador = new Participante();
        this.pedido.transportador.id = 0;
        this.pedido.tipoFrete = new TipoFrete();
        this.pedido.tipoFrete.id = 999;
        this.pedido.ufPlaca = new Uf();
        this.pedido.ufPlaca.id = 999;
        // alterado gck
        this.pedido.embarqueUf = new Uf();
        this.pedido.embarqueUf.id = 999;

        // variaveis inicializadas
        this.pedido.pedidoStatus.id = null;
        this.pedido.modNf.id = null;

        this.pedido.codModDoc = '';
        this.pedido.numSeqEcf = '';
        this.pedido.numContOrdem = '';

        this.pedido.moeda.id = 1;
        this.pedido.txMoeda = 1.00;
        this.codigoMoeda = 'BRL';
        this.pedido.converterTaxa = false;

        this.pedido.formaPagamento.id = null;
        this.pedido.empresaConta.id = null;

        // incoterm do pedido.
        this.pedido.tipoIncoterm = new TipoIncoterm();
        this.pedido.tipoIncoterm.id = null;

        // inicialização totalizadores dos impostos e outros
        this.pedido.valorDesconto = this.utilService.parseMoneyString(0.00);
        this.valorTotalPedido = this.utilService.parseMoneyString(0.00);
        this.subTotalPedido = this.utilService.parseMoneyString(0.00);
        this.pedido.valorIcms = this.utilService.parseMoneyString(0.00);
        this.pedido.valorIcmsSt = this.utilService.parseMoneyString(0.00);
        this.pedido.valorPis = this.utilService.parseMoneyString(0.00);
        this.pedido.valorCofins = this.utilService.parseMoneyString(0.00);
        this.pedido.valorIpi = this.utilService.parseMoneyString(0.00);
        this.pedido.valorSeguro = this.utilService.parseMoneyString(0.00);
        this.pedido.valorFrete = this.utilService.parseMoneyString(0.00);
        this.pedido.valorOutrasDespesas = this.utilService.parseMoneyString(0.00);
        this.pedido.valorImpostoImportacao = this.utilService.parseMoneyString(0.00);
        this.pedido.valorIcmsSt = this.utilService.parseMoneyString(0.00);
        this.pedido.valorBaseCalculoIcms = this.utilService.parseMoneyString(0.00);
        this.pedido.valorIcmsSubst = this.utilService.parseMoneyString(0.00);
        this.pedido.valorIcmsUfDestino = this.utilService.parseMoneyString(0.00);
        this.pedido.valorIcmsUfRemetente = this.utilService.parseMoneyString(0.00);
        this.pedido.valorFcp = this.utilService.parseMoneyString(0.00);
        this.pedido.valorTotalTributos = this.utilService.parseMoneyString(0.00);
        this.numeroSequencialItem = 0;

        this.pedidoItem = new PedidoItem();
        this.pedidoItem.pedidoItensRateio = new Array();
        this.pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado();
        this.pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado();
        this.pedidoItem.operacaoRegra = new OperacaoRegra();
        this.pedidoItem.unidadeMedida = new UnidadeMedida();
        this.pedidoItem.tipoDesconto = new TipoDesconto();
        this.pedidoItem.tipoDesconto.id = 2;
        this.pedidoItem.cest = '';
        this.pedidoItem.ncm = '';
        this.pedidoItem.valorFrete = 0;
        this.pedidoItem.valorSeguro = 0;

        // aprovação do pedido.
        this.pedido.pedidoAprovacao = new Array<PedidoAprovacao>();
        this.pedido.tipoPagamento = 0;
        this.maxParcelas = null;
        this.novoPedido();
      }
    } else {
      if (participante) {

        this.pedido.participanteEmpresaId = participante.participanteEmpresaId;
        this.pedido.participante = participante;

        this.httpService.wait();
        this.httpService.get('/custom/pedido/buscar-infos-participante(' + this.dblinkedSessionService.empresa.id + ',' +
          this.pedido.participante.id + ',' +
          (this.tipo == 0 ? 4 : 2) + ')')
          .subscribe(info => {

            this.infoParticipante = info;
            this.pedidoExterior = (info[0].uf === 'EX') ? true : false;
            this.mesmaUf = (info[0].mesmaUf);

            this.clienteContribuinteIcms = (info[0].contribuinteIcms);
            if (!this.mesmaUf) {
              this.aliquotaInter = info[0].aliquota;
            }

            this.aliquotaInterVenda = info[0].aliquotaVenda;
            this.aliquotaInternaPart = info[0].aliquotaPart;
          },

            error => this.newParticipante(data),
            () => this.httpService.done());


      }
    }
  }

  novoPedido() {
    this.exibirPedido = true;
    this.contador = 1;
    this.contadorAdicao = 0;
    this.contadorExport = 0;
    this.showSolicitarAprovacao = this.flowUses;

    this.opcoesCapituloTipi = [];
    this.opcoesCapituloTipi.push({ label: 'Selecione NCM', value: null });
    this.tipi.forEach(element => {
      if (element.ncm.length === 8) {
        this.opcoesCapituloTipi.push({ label: element.ncm + ' - ' + element.descricao, value: element.ncm });
      }
    });


    this.comboCfop();
    // this.comboAlmoxarifado();

    this.httpService.wait();
    this.httpService.get('/custom/pedido/buscar-infos-participante(' + this.dblinkedSessionService.empresa.id + ',' +
      this.pedido.participante.id + ',' +
      (this.tipo == 0 ? 3 : 1) + ')')
      .subscribe(info => {

        this.toolbarEdit.setClick('save', () => this.salvarPedido(false))

        if (info[0].cidade == null || info[0].logradouro == null || info[0].numero == null || info[0].uf == null) {
          this.buscarPedidos();
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: 'O Endereço do Participante Selecionado está Incompleto!' })
        }

        this.pedidoExterior = (info[0].uf === 'EX') ? true : false;
        this.mesmaUf = (info[0].mesmaUf);
        if (!this.mesmaUf) {
          this.aliquotaInter = info[0].aliquota;
        }
        this.aliquotaInterVenda = info[0].aliquotaVenda;
        this.aliquotaInternaPart = info[0].aliquotaPart;
        this.clienteContribuinteIcms = (info[0].contribuinteIcms);
        // this.opcoesTipoIntermediacao = [];
        // this.opcoesTipoIntermediacao.push({ label: 'Selecione Tipo', value: null });
        // this.tipoIntermediacao.forEach(element => {
        //   this.opcoesTipoIntermediacao.push({ label: element.descricao, value: element.id });
        // });

        this.informacoesOperacoes = info[0].operacoes;

        // this.opcoesViaTransp = [];
        // this.opcoesViaTransp.push({ label: 'Selecione Via Transp', value: null });
        // this.transporteTipos.forEach(element => {
        //   this.opcoesViaTransp.push({ label: element.descricao, value: element.id });
        // });

        // this.opcoesUf = [];
        // this.opcoesUf.push({ label: 'Selecione UF', value: null });
        // this.ufs.forEach(element => {
        //   this.opcoesUf.push({ label: element.uf, value: element.id });
        // });

        // this.opcoesUnidadeMedida = [];
        // this.opcoesUnidadeMedida.push({ label: 'Selecione Unidade Medida', value: null });
        // this.tipoUnidadeMedida.forEach(element => {
        //   this.opcoesUnidadeMedida.push({ label: element.codigo, value: element.id });
        // });

        this.loadAlmoxarifado()
        this.infoParticipante = info; this.pedidoExterior = (info[0].uf === 'EX') ? true : false;
        this.verificaOperacaoRegraGeraFinanceiro(false);
      },
        error => this.httpService.handleError(error, () => this.novoPedido()),
        () => this.httpService.done());
  }

  buscarParticipante() {
    this.newRequest = true
    this.modalParticipante = true
    this.redirectnewProduct = false
    this.referenciamentoNfe = false
  }

  openModal() {
    this.newRequest = false;
    this.modalParticipante = true;
  }

  adicionarItem() {
    this.botaoEditar = false;
    this.pedidoItem = new PedidoItem();
    this.pedidoItem.item = new Item();
    this.pedidoItem.tipoDesconto = new TipoDesconto();
    this.pedidoItem.operacaoRegra = new OperacaoRegra();
    this.pedidoItem.operacaoRegra.operacaoRegraContabil = new OperacaoRegraContabil();
    this.pedidoItem.operacaoRegra.operacaoRegraFiscal = new OperacaoRegraFiscal();
    this.pedidoItem.operacaoRegra.operacaoRegraContabil.tipoRegraContabil = new TipoRegraContabil();
    this.pedidoItem.unidadeMedida = new UnidadeMedida;
    this.pedidoItem.unidadeMedidaTributavel = new UnidadeMedida;
    this.pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA;
    this.pedidoItem.cstIcmsTabelaB = new CstIcmsTabelaB;
    this.pedidoItem.cstPis = new CstPiscofins;
    this.pedidoItem.cstCofins = new CstPiscofins;
    this.pedidoItem.cstIpi = new CstIpi;
    this.pedidoItem.pedidoItensRateio = new Array();
    this.pedidoItem.itemFornecedor = null;
    this.pedidoItem.quantidade = null;
    this.pedidoItem.valorUnit = null;
    this.pedidoItem.cfop === null;
    this.pedidoItem.valorDesconto = null;
    this.pedidoItem.ufDesembaraco = new Uf();
    this.pedidoItem.ufAdquirente = new Uf();
    this.pedidoItem.transporteTipo = new TransporteTipo();
    this.pedidoItem.tipoIntermediacao = new TipoIntermediacao();
    this.pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado();
    this.pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado();
    this.pedidoItem.tipoDesconto = new TipoDesconto();
    this.pedidoItem.tipoDesconto.id = 1;
    this.valorItemTributavel = null;
    this.exibirItemPedido = true;

    if (!isNullOrUndefined(this.ieEmpresa)) {
      if (this.ieEmpresa != 'ISENTO' && this.ieEmpresa != 'ISENTA' && this.ieEmpresa != ' ') {
        this.pedidoItem.aliqInternaDifal = this.aliquotaInterna;
        this.pedidoItem.aliqInterestadualDifal = this.aliquotaInter;

      }
    }
  }

  calcularValorTotal() {
    if (this.pedidoItem.tipoDesconto.id === 1) {

      let desconto: number = (((this.utilService.parseDecimal6(this.pedidoItem.quantidade) *
        this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) *
        (this.utilService.parseDecimal(this.pedidoItem.valorDesconto) / 100)));

      let valorIcmsImp = 0.00;
      let valorPisCofinsImp = 0.00;

      if (!isNullOrUndefined(this.pedidoItem.cfop)) {
        if (this.pedidoItem.cfop.startsWith('3')) {
          valorIcmsImp = this.utilService.parseDecimal(this.pedidoItem.valorIcms);
          valorPisCofinsImp = this.utilService.parseDecimal(this.pedidoItem.valorPis) + this.utilService.parseDecimal(this.pedidoItem.valorCofins);
        }
      }

      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        this.pedidoItem.valorTotal =
          ((this.utilService.parseDecimal6(this.pedidoItem.quantidade) *
            this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
            // this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
            // this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
            // this.utilService.parseDecimal(this.pedidoItem.valorSeguro) -
            this.utilService.parseDecimal(desconto) +
            this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
            this.utilService.parseDecimal(this.pedidoItem.valorIcms) +
            this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
            this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(this.pedidoItem.valorCofins) +
            this.utilService.parseDecimal(this.pedidoItem.valorPis)
          );
      } else {
        this.pedidoItem.valorTotal =
          (this.utilService.parseDecimal6(this.pedidoItem.valorUnit) * this.utilService.parseDecimal6(this.pedidoItem.quantidade))
          - this.utilService.parseDecimal(desconto)
          + this.utilService.parseDecimal(this.pedidoItem.valorOutro)
          + this.utilService.parseDecimal(valorIcmsImp)
          + this.utilService.parseDecimal(valorPisCofinsImp);
      }

      if (this.pedidoItem.unidadeMedidaTributavel.id === this.pedidoItem.unidadeMedida.id) {
        this.pedidoItem.quantidadeTributavel = this.pedidoItem.quantidade;
        this.pedidoItem.valorUnitTributavel = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(this.pedidoItem.valorUnit));
        this.valorItemTributavel = this.utilService.parseDecimalString(this.utilService.parseDecimal6(this.pedidoItem.valorTotal));
      }
    } else {
      this.pedidoItem.valorTotalProduto = this.utilService.parseDecimalString(((this.utilService.parseDecimal6(this.pedidoItem.quantidade) * this.utilService.parseDecimal6(this.pedidoItem.valorUnit))));
      let valorIcmsImp = 0.00;
      let valorPisCofinsImp = 0.00;

      if (!isNullOrUndefined(this.pedidoItem.cfop)) {
        if (this.pedidoItem.cfop.startsWith('3')) {
          valorIcmsImp = this.utilService.parseDecimal(this.pedidoItem.valorIcms);
          valorPisCofinsImp = this.utilService.parseDecimal(this.pedidoItem.valorPis) + this.utilService.parseDecimal(this.pedidoItem.valorCofins);
        }
      }

      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        this.pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (this.utilService.parseDecimal6(this.pedidoItem.quantidade) *
              this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
            this.utilService.parseDecimal(this.pedidoItem.valorDesconto) +
            this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
            this.utilService.parseDecimal(this.pedidoItem.valorIcms) +
            this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
            this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(this.pedidoItem.valorCofins) +
            this.utilService.parseDecimal(this.pedidoItem.valorPis)
          );

      } else {
        this.pedidoItem.valorTotal =
          (this.utilService.parseDecimal6(this.pedidoItem.valorUnit) * this.utilService.parseDecimal6(this.pedidoItem.quantidade))
          - this.utilService.parseDecimal(this.pedidoItem.valorDesconto)
          + this.utilService.parseDecimal(this.pedidoItem.valorOutro)
          + this.utilService.parseDecimal(valorIcmsImp)
          + this.utilService.parseDecimal(valorPisCofinsImp);
      }

      if (this.pedidoItem.unidadeMedidaTributavel.id === this.pedidoItem.unidadeMedida.id) {
        this.pedidoItem.quantidadeTributavel = this.pedidoItem.quantidade;
        this.pedidoItem.valorUnitTributavel = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(this.pedidoItem.valorUnit));
        this.valorItemTributavel = this.utilService.parseDecimalString(this.utilService.parseDecimal6(this.pedidoItem.valorTotal));
      }

    }
  }

  salvarItemLista(pedidoItemId, isImportacao) {


    let bool = false;
    this.fatoresConversao.forEach(element => {

      if (element.unId === this.pedidoItem.unidadeMedida.id) {
        bool = true;
      }
    });

    if (this.unidadeMedidaOriginalId === this.pedidoItem.unidadeMedida.id) {

      bool = true;
    }

    if (!bool) {
      setTimeout(() => {
        this.pedidoItem.unidadeMedida = new UnidadeMedida();
        this.pedidoItem.unidadeMedida.id = this.unidadeMedidaOriginalId;
        this.pedidoItem.unidadeMedida.codigo = this.codigoUnidadeMedidaOriginal;
      }, 1);
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Não é possível utilizar esta unidade de medida!' })
      return;
    }

    if (!this.pedidoItem.operacaoRegra.id) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione uma Operação para Prosseguir!' })
      return;
    }
    // alterado GCK 27.11
    if (!this.pedidoItem.item.id) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione um Item para Prosseguir!' })
      return;
    }
    if ((this.pedido.pedidoTipo.id === 3 && this.pedido.pedidoStatus.id === 12) && (this.pedidoItem.cfop === null || this.pedidoItem.cfop === undefined)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Adicione CFOP para Prosseguir!' })
      return;
    }
    /*if (!(this.utilService.parseDecimal6(this.pedidoItem.quantidade) > 0.00)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Quantidade Comercial do item não pode ser zeros ou vazio. Verifique!' })
      return;
    }*/
    /*if (!(this.utilService.parseDecimal6(this.pedidoItem.valorUnit) > 0.00)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Valor Unitário Comercial do item não pode ser zeros ou vazio. Verifique!' })
      return;
    }*/
    if (!this.pedidoItem.isServico) {
      if (this.pedidoItem.ncm === null || this.pedidoItem.ncm === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione o NCM do Item para Prosseguir!' })
        return;
      }
      if (this.pedidoItem.unidadeMedidaTributavel === null || this.pedidoItem.unidadeMedidaTributavel === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione uma Unidade Tributável para Prosseguir!' })
        return;
      }
      /*if (!(this.utilService.parseDecimal6(this.pedidoItem.quantidadeTributavel) > 0.00)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Quantidade Tributável do item não pode ser zeros ou vazio. Verifique!' })
        return;
      }
      if (!(this.utilService.parseDecimal6(this.pedidoItem.valorUnitTributavel) > 0.00)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Valor Unitário Tributável do item não pode ser zeros ou vazio. Verifique!' })
        return;
      }
      if (!(this.utilService.parseDecimal6(this.valorItemTributavel) > 0.00)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Valor Total Tributável do item não pode ser zeros ou vazio. Verifique!' })
        return;
      }
      if (!(this.utilService.parseDecimal6(this.pedidoItem.valorTotal) > 0.00)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Valor Total do item não pode ser zeros ou vazio. Verifique!' })
        return;
      }*/
    }
    if (this.pedidoItem.cstIcmsTabelaB.id !== null || this.pedidoItem.cstIcmsTabelaB.id !== undefined) {
      if (this.pedidoItem.cstIcmsTabelaB.id === 3 &&
        (this.pedidoItem.percRedBcIcms === null || this.pedidoItem.percRedBcIcms === undefined || this.pedidoItem.percRedBcIcms.trim() === '')) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Adicione PERC. RED. BC ICMS, para Prosseguir!' })
        return;
      }
    }
    if (isNullOrUndefined(pedidoItemId)) {
      this.pedidoItem.id = this.pedido.pedidoItem.length;
    } else {
      this.pedidoItem.id = pedidoItemId;
    }

    this.exibirItemPedido = false;
    // recalcular parcelas - gck 21.03
    this.calculaParcelasPgto = true;

    this.pedidoItem.tipoDesconto.cod = ((this.pedidoItem.tipoDesconto.id === 1) ? '%' :
      ((this.pedidoItem.tipoDesconto.id === 2) ? 'R$' : null));

    this.pedidoItem.id = this.contador;
    this.calculaValorTotalItem();
    // validando tipo de desconto = %
    // if (this.pedidoItem.tipoDesconto.id === 1) {

    //   const desconto = (((this.utilService.parseDecimal6(this.pedidoItem.quantidade) *
    //     this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) *
    //     (this.utilService.parseDecimal(this.pedidoItem.valorDesconto) / 100)));

    //   if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
    //     this.pedidoItem.valorTotal = ((this.utilService.parseDecimal6(this.pedidoItem.quantidade) *
    //       this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
    //       // this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
    //       // this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
    //       // this.utilService.parseDecimal(this.pedidoItem.valorSeguro) -
    //       this.utilService.parseDecimal(desconto) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIcms) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorCofins) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorPis)
    //     );
    //   } else {
    //     this.pedidoItem.valorTotal = ((this.utilService.parseDecimal6(this.pedidoItem.quantidade) *
    //       this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
    //       this.utilService.parseDecimal(desconto) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIcmsSt) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIpi));
    //   }

    //   if (!isImportacao) {
    //     this.pedidoItem.valorDesconto = (desconto);
    //   }
    // } else if (this.pedidoItem.tipoDesconto.id === 2) {

    //   this.pedidoItem.valorDesconto = (this.utilService.parseDecimal(this.pedidoItem.valorDesconto));

    //   if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
    //     this.pedidoItem.valorTotal = ((this.utilService.parseDecimal6(this.pedidoItem.quantidade) *
    //       this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
    //       // this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
    //       // this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
    //       // this.utilService.parseDecimal(this.pedidoItem.valorSeguro) -
    //       this.utilService.parseDecimal(this.pedidoItem.valorDesconto) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIcms) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorCofins) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorPis)
    //     );
    //   } else {
    //     this.pedidoItem.valorTotal = ((this.utilService.parseDecimal6(this.pedidoItem.quantidade) *
    //       this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
    //       this.utilService.parseDecimal(this.pedidoItem.valorDesconto) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIcmsSt) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIpi));
    //   }

    // } else {

    //   if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
    //     this.pedidoItem.valorTotal = ((this.utilService.parseDecimal6(this.pedidoItem.quantidade) *
    //       this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
    //       // this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
    //       // this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIcms) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorCofins) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorPis)
    //     );
    //   } else {
    //     this.pedidoItem.valorTotal = ((this.utilService.parseDecimal6(this.pedidoItem.quantidade) *
    //       this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIcmsSt) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
    //       this.utilService.parseDecimal(this.pedidoItem.valorIpi));
    //   }
    // }
    // alterado GCK 27.11
    this.pedidoItem.quantidadeTributavel = this.utilService.parseDecimal6(this.pedidoItem.quantidadeTributavel);
    this.pedidoItem.valorUnitTributavel = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(this.pedidoItem.valorUnitTributavel));

    // incrementando o numero sequencial dos itens e atribuindo ele ao pedidoItem:
    this.numeroSequencialItem++;
    this.pedidoItem.numSequence = this.numeroSequencialItem;

    // adicionando o item ao pedido

    this.pedido.pedidoItem.push(this.pedidoItem);

    if (this.pedidoItem.isServico) {
      this.buscarImpostosPedidoServico();
    }

    this.contador++;

    this.visualizacaoContabil = new Array();
    this.visualizacaoFiscal = new Array();
    this.calculaValorPedido();

    if (!isImportacao) {
      this.visualizacaoContabil = new Array();
      this.visualizacaoFiscal = new Array();
      this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);
    }

    if (!isImportacao) {
      this.verificaOperacaoRegraGeraFinanceiro(true);
    }

  }


  /*Início Métodos Controle de Tabela*/
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  /*MODAL ALTERAR PERÍODO */
  openModalPeriodo() {
    this.modalPeriodo = true;
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
  }

  closeModalPeriodo() {
    this.modalPeriodo = false;
    this.dataIni = null;
    this.dataFin = null;
  }

  /**
   *  povoa as informações do item selecionado
   * @param args
   */
  dadosItem(args, bool: boolean) {

    let unidadeMedida: UnidadeMedida = this.pedidoItem.unidadeMedida
    this.httpService.wait();
    this.httpService.get('custom/cadastro/get-dados-item', [isNullOrUndefined(args.value) ? args : args.value])
      .subscribe(retorno => {

        this.opcoesCapituloTipi = [];

        if (!isNullOrUndefined(retorno.ncm)) {
          this.opcoesCapituloTipi.push({ label: retorno.ncm + ' - ' + retorno.ncmDescricao, value: retorno.ncm });
        }


        this.pedidoItem.item = new Item();
        this.pedidoItem.item.id = retorno.id;
        this.pedidoItem.item.codigo = retorno.codigo;
        this.pedidoItem.item.descricao = retorno.descricao;
        this.pedidoItem.ncm = isNullOrUndefined(retorno.ncm) ? '' : retorno.ncm;


        if (this.buscarCustoMedio) {
          this.pedidoItem.valorUnit = retorno.valorCusto;
        }

        if (this.buscarValorVenda) {
          this.pedidoItem.valorUnit = retorno.valorVenda;
        }

        if (!isNullOrUndefined(retorno.unidadeMedidaId) && !unidadeMedida.id) {
          this.pedidoItem.unidadeMedida = new UnidadeMedida();
          this.pedidoItem.unidadeMedida.id = retorno.unidadeMedidaId;
          this.pedidoItem.unidadeMedida.codigo = retorno.codigoUnidadeMedida;

          this.unidadeMedidaOriginalId = retorno.unidadeMedidaId;
          this.codigoUnidadeMedidaOriginal = retorno.codigoUnidadeMedida;


          this.pedidoItem.unidadeMedidaTributavel = new UnidadeMedida();
          this.pedidoItem.unidadeMedidaTributavel.id = retorno.unidadeMedidaId;
          this.pedidoItem.unidadeMedidaTributavel.codigo = retorno.codigoUnidadeMedida;

        } else if (unidadeMedida.id) {
          this.pedidoItem.unidadeMedida = new UnidadeMedida()
          this.pedidoItem.unidadeMedida.id = unidadeMedida.id
          this.pedidoItem.unidadeMedida.codigo = unidadeMedida.codigo
          this.pedidoItem.unidadeMedidaTributavel = new UnidadeMedida()
          this.pedidoItem.unidadeMedidaTributavel.id = unidadeMedida.id
          this.pedidoItem.unidadeMedidaTributavel.codigo = unidadeMedida.codigo
          this.unidadeMedidaOriginalId = unidadeMedida.id
          this.codigoUnidadeMedidaOriginal = unidadeMedida.codigo
        }

        let boolIcms = false;

        if (!isNullOrUndefined(retorno.detalheItem)) {
          retorno.detalheItem.forEach(element => {
            if (element.impostoRegraFiscalId === 1) {
              if (!this.referenciamentoNfe) {
                this.pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(element.aliquotaImposto);
                this.pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(element.aliquotaImposto);
                if (this.mesmaUf) {

                  this.pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(this.aliquotaInterna);
                  this.pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(this.aliquotaInterna);
                }
              }
              boolIcms = true;
            } else if (element.impostoRegraFiscalId === 2) {
              if (!this.referenciamentoNfe) {
                this.pedidoItem.aliquotaIpi = this.utilService.parseDecimal4(element.aliquotaImposto);
              }
            } else if (element.impostoRegraFiscalId === 3) {

              if (!isNullOrUndefined(this.pedidoItem.cstPis)) {
                if (this.pedidoItem.cstPis.id !== 8 && !this.pedidoItem.operacaoRegra.utilizaBasePcXml) {
                  this.pedidoItem.perAliquotaPis = this.utilService.parseDecimal4(element.aliquotaImposto);
                }
              }


            } else if (element.impostoRegraFiscalId === 4) {


              if (!isNullOrUndefined(this.pedidoItem.cstCofins)) {
                if (this.pedidoItem.cstCofins.id !== 8 && !this.pedidoItem.operacaoRegra.utilizaBasePcXml) {
                  this.pedidoItem.perAliquotaCofins = this.utilService.parseDecimal4(element.aliquotaImposto);
                }
              }

            } else if (element.impostoRegraFiscalId === 5) {
              this.pedidoItem.aliquotaIcmsSt = this.utilService.parseDecimal4(element.aliquotaImposto);
            } else if (element.impostoRegraFiscalId === 6) {

            }
          });
        }

        if (bool && !boolIcms) {
          if (this.tipo == 0) {
            if (this.aliquotaInter) {
              this.pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(this.aliquotaInter);
              this.pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(this.aliquotaInter);
            }
            if (this.mesmaUf) {

              this.pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(this.aliquotaInterna);
              this.pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(this.aliquotaInterna);
            }
          } else {
            if (this.aliquotaInterVenda) {
              this.pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(this.aliquotaInterVenda);
              this.pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(this.aliquotaInterVenda);
              if (this.mesmaUf) {

                this.pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(this.aliquotaInterna);
                this.pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(this.aliquotaInterna);
              }
            }
          }
        }



        if (!isNullOrUndefined(retorno.origemProduto)) {
          if (retorno.origemProduto == 0) {
            this.pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA();
            this.pedidoItem.cstIcmsTabelaA.id = 1;
          } else if (retorno.origemProduto == 1) {

            this.pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA();
            this.pedidoItem.cstIcmsTabelaA.id = 2;

          }
        }

        this.fatoresConversao = new Array();
        this.fatoresConversao = retorno.fatorConversaoList;


        //let rowPedidoItem = this.pedido.pedidoItem.find(row => row.id = this.pedidoItem.id)
        //if (!this.pedidoItem.empresaAlmoxarifado.id) this.pedidoItem.empresaAlmoxarifado = rowPedidoItem ? rowPedidoItem.empresaAlmoxarifado : new EmpresaAlmoxarifado()


      }, error => this.httpService.handleError(error, () => this.dadosItem(args, bool)),
        () => this.httpService.done());
  }

  dadosUnidade(args) {

    let bool = false;
    this.fatoresConversao.forEach(element => {
      if (element.unId === args) {
        bool = true;
      }
    });

    if (this.unidadeMedidaOriginalId === args) {
      bool = true;
    }

    if (!bool) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Não é possível utilizar esta unidade de medida!' })
      setTimeout(() => {
        this.pedidoItem.unidadeMedida = new UnidadeMedida();
        this.pedidoItem.unidadeMedida.id = this.unidadeMedidaOriginalId;
        this.pedidoItem.unidadeMedida.codigo = this.codigoUnidadeMedidaOriginal;
      }, 1);

    }

  }

  /**
   *  povoa as informações da operacaoregra selecionado
   * @param args
   */
  dadosOperacao(args) {


    this.httpService.wait();
    this.httpService.get('custom/cadastro/get-operacao-pedido', [!isNullOrUndefined(args.value) ? args.value : args])
      .subscribe(retorno => {

        this.retornaIdOperacaoRegra(retorno.id, retorno.tipoRegraContabil)

        this.pedidoItem.operacaoRegra = new OperacaoRegra();
        this.pedidoItem.operacaoRegra.id = retorno.id;
        this.pedidoItem.operacaoRegra.cod = retorno.codigo;
        this.pedidoItem.operacaoRegra.descricao = retorno.descricao;
        this.pedidoItem.operacaoRegra.permiteAlteracaoAlmoxarifado = retorno.permiteAlteracaoAlmoxarifado;
        this.pedidoItem.operacaoRegra.bloqueiaFiscal = retorno.bloqueiaFiscal;
        this.pedidoItem.operacaoRegra.utilizaBasePcXml = retorno.utilizaBasePcXml;
        this.pedidoItem.operacaoRegra.tipoRegra = retorno.tipoRegraContabil;
        this.pedidoItem.isServico = retorno.tipoRegraContabil == 2 ? true : false;
        this.buscarCustoMedio = isNullOrUndefined(retorno.utilizaCustoMedio) ? false : retorno.utilizaCustoMedio;
        this.buscarValorVenda = isNullOrUndefined(retorno.utilizaPrecoVenda) ? false : retorno.utilizaPrecoVenda;

        this.pedido.dadosAdicionais = isNullOrUndefined(this.pedido.dadosAdicionais) ? ('' + retorno.dadosAdicionais) : (this.pedido.dadosAdicionais + ' ' + (!isNullOrUndefined(this.pedido.dadosAdicionais) ? '' : retorno.dadosAdicionais));

        if (retorno.almoxarifadoOrigemId !== 0) {
          this.pedidoItem.operacaoRegra.almoxarifadoOrigem = new EmpresaAlmoxarifado();
          this.pedidoItem.operacaoRegra.almoxarifadoOrigem.id = retorno.almoxarifadoOrigemId;
          this.pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado();
          this.pedidoItem.empresaAlmoxarifado.id = retorno.almoxarifadoOrigemId;
        }

        if (retorno.almoxarifadoDestinoId) {
          this.pedidoItem.operacaoRegra.almoxarifadoDestino = new EmpresaAlmoxarifado();
          this.pedidoItem.operacaoRegra.almoxarifadoDestino.id = retorno.almoxarifadoDestinoId;
          this.pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado();
          this.pedidoItem.almoxarifadoDestino.id = retorno.almoxarifadoDestinoId;
        }

        this.pedidoItem.operacaoRegra.transfEstoque = retorno.transfEstoque;
        this.pedidoItem.operacaoRegra.controlaEstoque = retorno.controlaEstoque;

        this.somaIpiBc = false;
        if (retorno.operacaoRegraFiscalDetalheList != null) {
          retorno.operacaoRegraFiscalDetalheList.forEach(element => {

            if (!isNullOrUndefined(element.csosn)) {
              this.pedidoItem.csosn = element.csosn;
            }

            if (!isNullOrUndefined(element.operacaoRegraFiscalDetalheCfopRelacList)) {
              element.operacaoRegraFiscalDetalheCfopRelacList.forEach(el => {

                if (this.pedido.pedidoTipo.id === 1) {
                  if (this.mesmaUf) {
                    this.pedidoItem.cfop = '5' + el.cfopOrig;
                  } else if (!this.mesmaUf && this.pedido.pedidoTipo.id === 1 && this.pedidoExterior) {
                    this.pedidoItem.cfop = '7' + el.cfopOrig;
                  } else if (!this.mesmaUf && this.pedido.pedidoTipo.id === 1 && !this.pedidoExterior) {
                    this.pedidoItem.cfop = '6' + el.cfopOrig;
                  }
                } else if (this.pedido.pedidoTipo.id === 3) {
                  if (this.mesmaUf || (this.pedido.nfePresencial.id === 1 && !this.mesmaUf)) {
                    this.pedidoItem.cfop = '1' + el.cfopDest;
                    this.verificarCfop();
                  } else if (!this.mesmaUf && this.pedido.pedidoTipo.id === 3 && this.pedidoExterior) {
                    this.pedidoItem.cfop = '3' + el.cfopDest;
                    this.verificarCfop();
                  } else if (!this.mesmaUf && this.pedido.pedidoTipo.id === 3 && !this.pedidoExterior) {
                    if (this.pedidoItem.cfop !== '2556' && this.pedidoItem.cfop !== '2551') {
                      this.pedidoItem.cfop = '2' + el.cfopDest;
                      this.verificarCfop();
                    }

                  }

                }

                if (!isNullOrUndefined(el.cstIcmsTabelaB)) {
                  this.pedidoItem.cstIcmsTabelaB.id = el.cstIcmsTabelaB.id;
                }
              });
            }

            if (!isNullOrUndefined(element.cstIpi.id)) {
              this.pedidoItem.cstIpi = new CstIpi();
              this.pedidoItem.cstIpi.id = element.cstIpi.id;
            }

            if (!isNullOrUndefined(element.cstPisCofins.id)) {

              this.pedidoItem.cstPis = new CstPiscofins();
              this.pedidoItem.cstPis.id = element.cstPisCofins.id;

              this.pedidoItem.cstCofins = new CstPiscofins();
              this.pedidoItem.cstCofins.id = element.cstPisCofins.id;
            }



            if (element.codEnqIpi) this.pedidoItem.codigoIpi = element.codEnqIpi;
            if (element.modBcIcms) this.pedidoItem.modBcIcms = element.modBcIcms;
            if (element.somaIpiBc) this.somaIpiBc = true;
            const relacImpostoId = { 1: 'icms', 2: 'ipi', 3: 'pis', 4: 'cofins' }
            const imposto = relacImpostoId[element.impostoRegraFiscal?.id];
            if (imposto) this.relacGeraImposto[imposto] = element.lucroReal || element.lucroPresumido || element.simples || element.naoContribuinte;
          });
        }

        this.getTaxBaseAmounts();
      }, error => this.httpService.handleError(error, () => this.dadosOperacao(args)),
        () => this.httpService.done());
  }

  visualizarEscrituracao() {
    this.visualizarContabil = true;
  }

  comboTipoPagamento(geraFinanceiro) {
    this.opcoesTipoPagamento = [];

    if (geraFinanceiro) {
      this.opcoesTipoPagamento.push({ label: 'SELECIONE O TIPO DE PAGAMENTO', value: 0 });
      this.opcoesTipoPagamento.push({ label: 'BANCO', value: 1 });
    } else {
      this.opcoesTipoPagamento.push({ label: 'SELECIONE O TIPO DE PAGAMENTO', value: 0 });
      this.opcoesTipoPagamento.push({ label: 'BANCO', value: 1 });
      this.opcoesTipoPagamento.push({ label: 'COMPENSAÇÃO ADIANTAMENTO', value: 2 });
    }
    //this.opcoesTipoPagamento.push({ label: 'DEVOLUÇÃO/RETORNO', value: 3 });
    //this.opcoesTipoPagamento.push({ label: 'CARTÃO DE CRÉDITO', value: 4 });
  }

  pagamentoTipo() {
    if (this.pedido.tipoPagamento === 2) {
      this.adicionarCompensacao();
      this.pedido.pedidoParcela = new Array();
      this.pedidoParcela = new PedidoParcela();
    } else {
      this.adtosRelacionadosPedido = new Array();
    }
  }

  adicionarCompensacao() {
  }

  formaPgtoCalcular(calcular: boolean) {
    // botão calcular é acionado === true
    if (calcular) {
      if (this.primeroVencimento === null || this.primeroVencimento === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione a data do primeiro vencimento para prosseguir!' })
        return;
      } if (this.qtdParcelas === null || (this.qtdParcelas < 0)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe a quantidade de parcelas para prosseguir' })
        return;
      } else {
        if (!isNullOrUndefined(this.maxParcelas) && this.qtdParcelas > this.maxParcelas) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Quantidade de parcelas ultrapassa o número máximo da forma de pagamento' })
          return;
        }
      }

      this.calculaParcelasPgto = false;
      this.pedido.pedidoParcela = new Array();
      this.valorTotalPedido = this.utilService.parseDecimal(this.valorTotalPedido);
      this.valorTotalFormasPagtoTela = this.valorTotalPedido;

      this.pedidoParcela = new PedidoParcela();
      this.pedidoParcela.tipoBaixa = new TipoBaixa();


      let vencimento = new Date(this.primeroVencimento);
      const dia = vencimento.getDate();
      if (this.qtdParcelas === 0) {
        // pegando próximo vencimento - considera mais 1 dia
        this.pedidoParcela.id = 1;
        this.pedidoParcela.parcela = 1;
        this.pedidoParcela.dtVencimento = vencimento;
        this.pedidoParcela.valor = this.valorTotalFormasPagtoTela;
        this.pedidoParcela.historico = '';
        this.pedidoParcela.tipoBaixa = new TipoBaixa();
        this.pedidoParcela.tipoBaixa.id = 0;
        this.pedidoParcela.tipoBaixa.descricao = '';
        this.pedidoParcela.tipoPagamentoId = null
        this.pedidoParcela.tipoPagamentoDesc = ''
        this.pedidoParcela.empresaConta = new EmpresaConta();
        this.pedidoParcela.empresaConta.id = this.pedido.empresaConta.id;
        this.pedidoParcela.empresaConta.nome = this.pedido.empresaConta.nome;
        this.pedido.pedidoParcela.push(this.pedidoParcela);
        this.includeCalendarState()

      } else {
        let vlrParcela = Number((this.valorTotalFormasPagtoTela / this.qtdParcelas).toFixed(2));
        let countVlrParcelas = 0.00;
        this.valorTotalParcelas = this.valorTotalFormasPagtoTela;

        for (let parcela = 1; parcela <= this.qtdParcelas; parcela++) {
          this.pedidoParcela = new PedidoParcela();
          this.pedidoParcela.tipoBaixa = new TipoBaixa();

          let mes = null;
          // pegando próximo vencimento - considera 30 dias
          if (vencimento.getMonth() === 0 && dia > 28 && parcela > 1) {
            // proximo é fevereiro
            vencimento = (new Date(vencimento.getTime() + 28 * 24 * 60 * 60 * 1000));
            const lastDayFev = new Date(vencimento.getFullYear(), vencimento.getMonth() + 1, 0); // ultimo dia do mês
            vencimento = lastDayFev;
            mes = vencimento.getMonth(); // mes da parcela
          } else {

            mes = vencimento.getMonth(); // mes da parcela anterior
            vencimento = ((parcela === 1) ? vencimento : new Date(vencimento.getTime() + 30 * 24 * 60 * 60 * 1000));
            // se não trocou de mes
            if (dia === 1 && mes === vencimento.getMonth() && parcela > 1) {
              // acrescentar mais um dia se não trocou de mÊs
              vencimento = new Date(vencimento.getTime() + (1 * 24 * 60 * 60 * 1000));
            }
            mes = vencimento.getMonth(); // mes da parcela
            vencimento.setDate(dia); // dia de vencimento
          }

          let lastDay = vencimento;
          // validando se é no mes corrente ou o set dia trocou de mes
          if (mes !== vencimento.getMonth()) {
            lastDay = new Date(vencimento.getTime() - (24 * 60 * 60 * 1000));
          }
          // total das parcelas
          countVlrParcelas += this.utilService.parseDecimal(vlrParcela);

          // validando qtd parcelas -> pegar ultima
          if (parcela === this.qtdParcelas) {
            // calculando diferença
            const diference = Number((this.valorTotalFormasPagtoTela - countVlrParcelas).toFixed(2));
            // total das parcelas
            countVlrParcelas += this.utilService.parseDecimal(vlrParcela);
            // descontando ou somando a diferenca.
            vlrParcela = (diference > 0 ? (vlrParcela + diference) : (diference < 0 ? (vlrParcela = vlrParcela + diference) : vlrParcela));
          }

          this.pedidoParcela.id = parcela;
          this.pedidoParcela.parcela = parcela;
          this.pedidoParcela.dtVencimento = lastDay;
          this.pedidoParcela.valor = this.utilService.parseDecimal(vlrParcela);
          this.pedidoParcela.historico = '';
          this.pedidoParcela.tipoBaixa = new TipoBaixa();
          this.pedidoParcela.tipoBaixa.id = 0;
          this.pedidoParcela.tipoBaixa.descricao = '';
          this.pedidoParcela.tipoPagamentoId = null
          this.pedidoParcela.tipoPagamentoDesc = ''
          this.pedidoParcela.empresaConta = new EmpresaConta();
          this.pedidoParcela.empresaConta.id = this.pedido.empresaConta.id;
          this.pedidoParcela.empresaConta.nome = this.pedido.empresaConta.nome;
          this.pedido.pedidoParcela.push(this.pedidoParcela);
          this.includeCalendarState()

        }
      }
    } else if (this.pedido.pedidoParcela) { // novo item é adicionado ao pedido.
      this.qtdParcelas = this.pedido.pedidoParcela.length;
      let parcela = 0;
      let vlrParcela = Number((this.utilService.parseDecimal(this.valorTotalFormasPagtoTela) / this.qtdParcelas).toFixed(2));
      this.valorTotalParcelas = 0.00;
      this.pedido.pedidoParcela.forEach(parc => {
        // total das parcelas
        this.valorTotalParcelas += this.utilService.parseDecimal(vlrParcela);
        // validando qtd parcelas -> pegar ultima e calcular diferenças
        if (parcela === this.qtdParcelas) {
          // calculando diferença
          const diference = Number((this.valorTotalPedido - this.valorTotalParcelas).toFixed(2));
          // descontando ou somando a diferenca.
          vlrParcela = (diference > 0 ? (vlrParcela + diference) : (diference < 0 ? (vlrParcela = vlrParcela + diference) : vlrParcela));
        }
        // atualizando o valor das parcelas - 21.03
        if (this.utilService.parseDecimal(parc.valor) > 0.00 && this.calculaParcelasPgto === false) {
          parc.valor = this.utilService.parseDecimal(parc.valor);
        } else {
          parc.valor = this.utilService.parseDecimal(vlrParcela);
        }
        parcela++;
      });
      this.calculaParcelasPgto = false;
      this.qtdParcelas = null;
    }
  }

  calculaValorParcelas() {
    this.valorTotalParcelas = 0.00;
    this.pedido.pedidoParcela.forEach(el => {
      this.valorTotalParcelas += this.utilService.parseDecimal(el.valor);
    });
  }

  pagamentoForma(atualizar = false) {


    if ((this.editPedido && atualizar) || (!this.editPedido)) {
      // formas pagamentos
      if (this.pedido.formaPagamento.id === null || this.pedido.empresaConta.id === null) {
        return;
      }

      if (this.pedido.formaPagamento.id === null) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione uma forma de pagamento para prosseguir!' })
        return;
      }

      this.valorTotalFormasPagto = 0;
      this.valorTotalFormasPagtoTela = 0;

      this.adtosRelacionadosPedido = new Array();

      this.pedido.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorInss));
      this.pedido.valorIr = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIr));
      this.pedido.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCsll));
      this.pedido.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPis));
      this.pedido.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofins));
      this.pedido.valorIssRetido = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIssRetido));
      this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes));
      this.pedido.valorIcms = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIcms));
      this.pedido.valorIpi = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIpi));
      this.pedido.valorImpostoImportacao = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorImpostoImportacao));

      const isPedidoCompraOuVendaProduto: boolean = true;

      const listasEnviar = {
        pedido: this.pedido,
        empresa: this.dblinkedSessionService.empresa.id,
        usuario: this.sessionService.loggedUser.id,
        valorTotal: this.valorTotalPedido,
        moeda: this.pedido.moeda.id,
        txMoeda: this.pedido.txMoeda,
        isPedidoExterior: this.pedidoExterior,
        isPedidoCompraOuVendaProduto: isPedidoCompraOuVendaProduto,
        converterTaxa: this.pedido.converterTaxa,
        valorComissao: this.valorComissao,
        pagamentoComissao: this.pagamentoComissao
      };

      this.httpService.wait();
      this.httpService.post('/custom/financeiro/buscar-parcela', listasEnviar)
        .subscribe(rst => {
          if (rst["error"]) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: rst.message })
            this.httpService.done()
          } else {

            this.pedido.pedidoParcela = new Array();
            this.valorTotalParcelas = 0.00;
            this.valorTotalFormasPagtoTela = 0.00;
            this.maxParcelas = rst.maxParcelas;
            this.parcelasExatas = rst.parcelasExatas
            this.permiteAlterarConta = rst.permiteAlterarBanco
            if (!isNullOrUndefined(rst.parcelas)) {
              rst.parcelas.forEach(el => {
                if (!el.error) {
                  // this.pedidoParcela = el;
                  this.pedidoParcela = new PedidoParcela()
                  if (rst.boolDataEmissaoPedido) {
                    this.pedidoParcela.dtVencimento = this.addDias(this.pedido.dtEmissao, el.acrescentaMes, el.dias);

                  } else {
                    const d = new Date(el.dtVencimento);
                    this.pedidoParcela.dtVencimento = this.utilService.dateWithoutTimeZone(d.toDateString());
                  }

                  this.pedidoParcela.valor = el.valor
                  this.pedidoParcela.parcela = el.parcela
                  this.pedidoParcela.id = el.id

                  // total das parcelas
                  this.valorTotalParcelas += this.utilService.parseDecimal(el.valor);
                  this.valorTotalFormasPagtoTela += this.utilService.parseDecimal(el.valor);

                  this.pedidoParcela.tipoBaixa = new TipoBaixa()
                  if (el.tipoBaixa) {
                    this.pedidoParcela.tipoBaixa.id = el.tipoBaixa.id
                    this.pedidoParcela.tipoBaixa.descricao = el.tipoBaixa.descricao
                  }

                  this.pedidoParcela.tipoPagamentoId = el.tipoPagamentoId
                  this.pedidoParcela.tipoPagamentoDesc = el.tipoPagamentoDesc

                  this.pedidoParcela.empresaConta = new EmpresaConta()
                  this.pedidoParcela.empresaConta.id = el.empresaConta.id
                  this.pedidoParcela.empresaConta.nome = el.empresaConta.nome
                  this.pedido.pedidoParcela.push(this.pedidoParcela);
                  this.includeCalendarState()
                } else {
                  this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: el.message })
                  return;
                }
              });
            }
          }
        },
          error => this.httpService.handleError(error, () => this.pagamentoForma(atualizar)),
          () => this.httpService.done());

    }


  }

  setEmpresaConta(row) {
    this.opcoesContas.forEach(element => {
      if (element.value === row.empresaConta.id) {
        row.empresaConta.nome = element.label
      }
    })

    this.pedido.pedidoParcela.forEach(element => {
      if (element.parcela > row.parcela) {
        element.empresaConta.id = row.empresaConta.id;
        element.empresaConta.nome = row.empresaConta.nome;
      }

    });
  }

  formaPgtoSalvar() {
    this.somaParcelas = this.utilService.parseDecimal(0);
    this.pedido.pedidoParcela.forEach(element => {
      this.somaParcelas = this.somaParcelas + this.utilService.parseDecimal(element.valor);
    });
    this.somaParcelas = this.utilService.parseDecimal(this.somaParcelas.toFixed(2));
    this.valorTotalPedido = this.utilService.parseDecimal(this.valorTotalPedido);


    for (let parcela of this.pedido.pedidoParcela) {

      if (parcela.dtVencimento == null || !parcela.dtVencimento) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Data de vencimento da parcela inválida!' })
        return;
      }
    }

    if (this.somaParcelas > parseFloat(this.valorTotalPedido)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Valor das parcelas é diferente do Valor Total do Pedido! <br><b> Parcelas: </b>' + this.utilService.parseDecimalString(this.somaParcelas) + ' <br><b> Pedido:</b> ' + this.utilService.parseDecimalString(this.valorTotalPedido) })
    } else {
      this.modalFormaPgto = false;
      this.primeroVencimento = null;
      this.qtdParcelas = null;
    }
  }

  setTipoBaixa(row: any) {
    this.tiposBaixas.forEach(element => {
      if (element.value === row.tipoBaixa.id) {
        row.tipoBaixa.descricao = element.label
      }
    })

    this.pedido.pedidoParcela.forEach(element => {
      if (element.parcela > row.parcela) {
        element.tipoBaixa.id = row.tipoBaixa.id
        element.tipoBaixa.descricao = row.tipoBaixa.descricao
      }
    })
  }

  salvarPedido(salvarNovo: boolean) {

    let permite = true;

    if (this.pedido.pedidoItem !== null || this.pedido.pedidoItem !== undefined) {
      if (this.pedido.pedidoTipo.id === 3 && this.pedido.pedidoStatus.id === 12) {
        for (let item of this.pedido.pedidoItem) {
          if (item.cfop === null || item.cfop === undefined || !item.cfop) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Informar o CFOP do Item: ' + item.item.codigo + ' para Prosseguir!' })
            permite = false;
            return;
          }
        };
      }
    }

    // VALIDAÇÃO CC
    if (this.centroCustoObrigatorio) {
      if (this.pedido.pedidoItem !== null || this.pedido.pedidoItem !== undefined) {
        for (let item of this.pedido.pedidoItem) {
          let porcentagem = 0;
          for (let rat of item.pedidoItensRateio) {
            porcentagem = this.utilService.parseDecimal(porcentagem) + this.utilService.parseDecimal(rat.porcentagem);
            if (rat.cadCcus.id === null || rat.cadCcus.id === undefined) {
              // this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está sem centro de custo relacionado, verifique!' })
              // permite = false;
              // return;
            }
            if (porcentagem === 0 || porcentagem === null || porcentagem === undefined) {
              // this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está com centro de custo sem porcentagem informada!' })
              // permite = false;
              // return;
            }
            if (rat.cadCcus.projetosRelacionados && (rat.cadProj.id === null || rat.cadProj.id === undefined)) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Rateio ' + (item.pedidoItensRateio.indexOf(rat) + 1) + ' do Item: ' + item.item.codigo + ' está sem projeto relacionado, verifique!' })
              permite = false;
              return;
            }
          }
          if ((porcentagem < 100.00 || porcentagem > 100.00) && porcentagem > 0.00) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está com ' + porcentagem + '%, deve ser 100%, verifique!' })
            permite = false;
            return;
          }
        }
      }
    }

    // FIM VALIDAÇÃO CC

    if (permite) {

      // INFORMAÇÕES INICIAIS
      if (this.pedido.pedidoStatus.id === null || this.pedido.pedidoStatus.id === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione o status para prosseguir!' })
        return;
      }
      if (this.pedido.modNf.id === null || this.pedido.modNf.id === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione o modelo para prosseguir!' })
        return;
      }
      if (this.pedido.dtEmissao === null || this.pedido.dtEmissao === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione a data de emissão para prosseguir!' })
        return;
      }
      if ((this.pedido.numNf === null || this.pedido.numNf === undefined || this.pedido.numNf.trim() === '') && (((this.pedido.pedidoStatus.id === 7 || this.pedido.pedidoStatus.id === 4) && this.pedido.modNf.id !== 22 && this.pedido.modNf.id !== 15) || (this.pedido.pedidoStatus.id === 12))) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe o número do documento para prosseguir!' })
        return;
      }
      if (this.pedido.pedidoStatus.id !== 1) {
        if (this.pedido.modNf.id !== 15 && (this.pedido.serie === '' || this.pedido.serie === null || this.pedido.serie === undefined || this.pedido.serie.length > 3)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe a série do pedido com até 3 caracteres para prosseguir!' })
          return;
        }
        if ((this.pedido.dtFaturamentoAutomatico === null || this.pedido.dtFaturamentoAutomatico === undefined) && this.pedido.pedidoTipo.id === 3) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione a data de entrada para prosseguir!' })
          return;
        }
        if ((this.pedido.dtFaturamentoAutomatico === null || this.pedido.dtFaturamentoAutomatico === undefined) && this.pedido.pedidoTipo.id === 1) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione a data de saída para prosseguir!' })
          return;
        }
      }
      if ((this.pedido.chvNfe === null || this.pedido.chvNfe === undefined || this.pedido.chvNfe.length !== 44) && ((this.pedido.modNf.id === 23 && (this.pedido.pedidoStatus.id === 7 || this.pedido.pedidoStatus.id === 4)) || ((this.pedido.pedidoStatus.id === 12) && (this.pedido.modNf.id === 22 || this.pedido.modNf.id === 23)))) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Chave eletrônica deverá ser informada com 44 caracteres!' })
        return;
      }
      if ((this.pedido.nfeReferenciada === null || this.pedido.nfeReferenciada === undefined || this.pedido.nfeReferenciada.length === 0) && (this.pedido.nfeFinalidade.id === 5 || this.pedido.nfeFinalidade.id === 4)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Nfe referenciada deverá ser informada!' })
        return;
      }

      // VALIDAÇÃO ITEM
      if ((!this.pedido.pedidoItem) || this.pedido.pedidoItem.length === 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É necessário informar pelo menos um item do pedido!' })
        return;
      }
      // FIM VALIDAÇÃO ITEM

      // INFORMAÇÕES DE PAGAMENTO
      if (((!this.pedido.tipoPagamento) || this.pedido.tipoPagamento === 0) && this.operacaoRegraGeraFinanceiro) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione o tipo de pagamento para prosseguir!' })
        return;
      }
      if (this.pedido.tipoPagamento === 1) {
        if ((!this.pedido.formaPagamento.id) || this.pedido.formaPagamento.id === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione a forma de pagamento para prosseguir!' })
          return;
        }
        if (this.pedido.empresaConta.id === null || this.pedido.empresaConta.id === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione o tipo de conta para prosseguir!' })
          return;
        }
      }
      for (let parcela of this.pedido.pedidoParcela) {
        if (parcela.dtVencimento == null || !parcela.dtVencimento) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Data de vencimento da parcela inválida!' })
          return;
        }
      }

      if (this.utilService.parseDecimal(this.valorTotalPedido) < this.utilService.parseDecimal(this.valorTotalParcelas)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O valor do pedido é diferente do total das parcelas. Verifique!' })
        return;
      }

      // FIM INFORMAÇÕES DE PAGAMENTO

      // VALIDAÇÃO TRANSPORTE COMPRA PROD E VENDA PROD NACIONAL
      if ((this.pedido.tipoFrete.id !== null || this.pedido.tipoFrete.id !== undefined) && ((this.pedido.pedidoTipo.id === 3) || (this.pedido.pedidoTipo.id === 1 && !this.pedidoExterior))) {
        if ((this.pedido.tipoFrete.id === 1 || this.pedido.tipoFrete.id === 2 || this.pedido.tipoFrete.id === 3) && (this.pedido.transportador.id === null || this.pedido.transportador.id === undefined)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Transportador deve ser informado!' })
          return;
        }
        if (this.pedido.tipoFrete.id === 1 || this.pedido.tipoFrete.id === 2 || this.pedido.tipoFrete.id === 3 || this.pedido.tipoFrete.id === 4 || this.pedido.tipoFrete.id === 5) {
          if (this.pedido.qVol === null || (!this.pedido.qVol)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Quantidade de volumes não pode ser vazio!' })
            return;
          } else if (this.pedido.especieVol === null || (!this.pedido.especieVol)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Espécie não pode ser vazia! Ex.: CX' })
            return;
          } else if (this.pedido.pesoB === null || (!this.pedido.pesoB)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Peso bruto não pode ser vazio!' })
            return;
          } else if (this.pedido.pesoL === null || (!this.pedido.pesoL)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Peso líquido não pode ser vazio!' })
            return;
          } else if (this.pedido.numeracaoVol === null || (!this.pedido.numeracaoVol)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A numeração deverá ser informada!' })
            return;
          }
        }
      }
      // FIM VALIDAÇÃO TRANSPORTE VENDA PROD INTER

      // VALIDAÇÃO TRANSPORTE COMPRA PROD E VENDA PROD NACIONAL
      if (this.pedido.pedidoTipo.id === 1 && this.pedidoExterior) {
        if (this.pedido.embarqueUf.id === null || this.pedido.embarqueUf.id === undefined || this.pedido.embarqueUf.id === 999) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione a UF embarque para prosseguir!' })
          return;
        }
        else if (this.pedido.embarqueLocal === null || (!this.pedido.embarqueLocal)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'informe o local de embarque para prosseguir!' })
          return;
        }
        else if (this.pedido.embarqueDescricao === null || (!this.pedido.embarqueDescricao)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe o local de despacho para prosseguir!' })
          return;
        }
      }
      // FIM VALIDAÇÃO TRANSPORTE VENDA PROD INTER

      // VALIDAÇÃO NF COMUNICACAO
      if (this.pedido.pedidoTipo.id === 3 && (this.pedido.modNf.id === 13 || this.pedido.modNf.id === 15 || this.pedido.modNf.id === 17)) {
        if (this.pedido.numNf.length > 9) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Número de documento não poderá ter mais de 9 dígitos!' })
          return;
        }
      }


      //VALIDAÇÃO FATURA ASAAS
      if (this.usaAsaas && this.pedido.temFaturaAsaas && !this.pedido.temPagamento) {
        if (!confirm("Esse pedido possui uma cobrança ativa pendente, deseje mesmo salvar esse pedido? Essa ação irá excluir a cobrança existente.")) return
      }
      //VALIDAÇÃO COBRANÇA ASAAS

      //VALIDAÇÃO DA DATA DE ENTRADA E EMISSÃO
      if ((this.pedido.dtFaturamentoAutomatico != null && this.pedido.dtEmissao != null) && (this.pedido.dtFaturamentoAutomatico < this.pedido.dtEmissao)) {
        if (this.tipo == 0) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de entrada não pode ser inferior à data de emissão.' })
        else this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de saída não pode ser inferior à data de emissão.' })
        return
      }
      // FIM DA VALIDAÇÃO DE DATA DE ENTREGA E EMISSÃO

      this.pedido.pedidoItem.forEach(it => {

        it.valorTotalProduto = this.utilService.parseDecimal(it.valorTotalProduto);
        it.valorUnit = this.utilService.parseDecimal6(it.valorUnit);
        it.quantidade = this.utilService.parseDecimal6(it.quantidade);

        it.valorUnitTributavel = this.utilService.parseDecimal6(it.valorUnitTributavel);
        it.quantidadeTributavel = this.utilService.parseDecimal6(it.quantidadeTributavel);

        it.valorFrete = this.utilService.parseDecimal(it.valorFrete);
        it.valorDesconto = this.utilService.parseDecimal(it.valorDesconto);
        it.valorSeguro = this.utilService.parseDecimal(it.valorSeguro);
        it.valorOutro = this.utilService.parseDecimal(it.valorOutro);

        it.valorIpi = this.utilService.parseDecimal(it.valorIpi);
        it.aliquotaIpi = this.utilService.parseDecimal(it.aliquotaIpi);

        it.valorBcIcms = this.utilService.parseDecimal(it.valorBcIcms);
        it.aliquotaIcmsSt = this.utilService.parseDecimal(it.aliquotaIcmsSt);
        it.percRedBcIcms = this.utilService.parseDecimal(it.percRedBcIcms);

        it.valorIcms = this.utilService.parseDecimal(it.valorIcms);
        it.aliquotaIcms = this.utilService.parseDecimal(it.aliquotaIcms);

        it.valorIcmsSt = this.utilService.parseDecimal(it.valorIcmsSt);
        it.valorBcIcmsSt = this.utilService.parseDecimal(it.valorBcIcmsSt);

        it.valorPis = this.utilService.parseDecimal(it.valorPis);
        it.vlrAliquotaPis = this.utilService.parseDecimal(it.vlrAliquotaPis);
        it.perAliquotaPis = this.utilService.parseDecimal(it.perAliquotaPis);
        it.valorBcPis = this.utilService.parseDecimal(it.valorBcPis);

        it.valorCofins = this.utilService.parseDecimal(it.valorCofins);
        it.perAliquotaCofins = this.utilService.parseDecimal(it.perAliquotaCofins);
        it.vlrAliquotaCofins = this.utilService.parseDecimal(it.vlrAliquotaCofins);
        it.valorBcCofins = this.utilService.parseDecimal(it.valorBcCofins);

        it.valorIofImport = this.utilService.parseDecimal(it.valorIofImport);
        it.valorDespImport = this.utilService.parseDecimal(it.valorDespImport);
        it.valorImpostoImport = this.utilService.parseDecimal(it.valorImpostoImport);

        it.aliquotaInterestadual = this.utilService.parseDecimal(it.aliquotaInterestadual);
        it.aliquotaInternaUfTermino = this.utilService.parseDecimal(it.aliquotaInternaUfTermino);
        it.percentualMercadoriaDevolvida = this.utilService.parseDecimal(it.percentualMercadoriaDevolvida);
        it.percentualPartilhaUfTermino = this.utilService.parseDecimal(it.percentualPartilhaUfTermino);
        it.percBaseCalculoIcmsFcpUfDest = this.utilService.parseDecimal(it.percBaseCalculoIcmsFcpUfDest);

        it.valorAFRMM = this.utilService.parseDecimal(it.valorAFRMM);

        if (it.dataDesembaraco) {
          it.dataDesembaraco = this.utilService.dateWithoutTimeZone(it.dataDesembaraco.toString());
        }
        if (it.dataRegistroImport) {
          it.dataRegistroImport = this.utilService.dateWithoutTimeZone(it.dataRegistroImport.toString());
        }
        if (it.cnpjAdquirente) {
          it.cnpjAdquirente = it.cnpjAdquirente.replace(/[^\d]+/g, '');
        }
        it.pedidoItensImportAdicao.forEach(adicao => {
          adicao.desconto = this.utilService.parseDecimal(adicao.desconto);
        });

      });

      // convertendo valores do pedido para decimais
      this.pedido.valorBaseCalculoIcms = this.utilService.parseDecimal(this.pedido.valorBaseCalculoIcms);
      this.pedido.valorIcms = this.utilService.parseDecimal(this.pedido.valorIcms);
      this.pedido.valorIcmsSt = this.utilService.parseDecimal(this.pedido.valorIcmsSt);
      this.pedido.valorIpi = this.utilService.parseDecimal(this.pedido.valorIpi);
      this.pedido.valorPis = this.utilService.parseDecimal(this.pedido.valorPis);
      this.pedido.valorCofins = this.utilService.parseDecimal(this.pedido.valorCofins);
      this.pedido.valorFrete = this.utilService.parseDecimal(this.pedido.valorFrete);
      this.pedido.valorSeguro = this.utilService.parseDecimal(this.pedido.valorSeguro);
      this.pedido.valorDesconto = this.utilService.parseDecimal(this.pedido.valorDesconto);
      this.pedido.valorOutrasDespesas = this.utilService.parseDecimal(this.pedido.valorOutrasDespesas);
      this.pedido.valorImpostoImportacao = this.utilService.parseDecimal(this.pedido.valorImpostoImportacao);
      this.pedido.valorIcmsSubst = this.utilService.parseDecimal(this.pedido.valorIcmsSubst);
      this.pedido.valorIcmsUfDestino = this.utilService.parseDecimal(this.pedido.valorIcmsUfDestino);
      this.pedido.valorIcmsUfRemetente = this.utilService.parseDecimal(this.pedido.valorIcmsUfRemetente);
      this.pedido.valorFcp = this.utilService.parseDecimal(this.pedido.valorFcp);
      this.pedido.valorTotalTributos = this.utilService.parseDecimal(this.pedido.valorTotalTributos);


      const listasEnviar = {
        pedido: JSON.stringify(this.pedido, null, ''),
        empresa: this.dblinkedSessionService.empresa.id,
        usuario: this.sessionService.loggedUser.id,
        mes: this.dblinkedSessionService.periodo.month.value,
        ano: this.dblinkedSessionService.periodo.year.value,
        valorTotal: this.valorTotalPedido,
        info: this.adtosCompensar,
        status: this.pedido.id ? 'edit' : 'novo',
        operacaoRegraAntecipado: this.operacaoRegraAntecipado,
        vendedorId: this.vendedorId,
        agenciaId: this.agenciaId,
        valorComissao: this.valorComissao,
        pagamentoComissao: this.pagamentoComissao
      };


      this.httpService.wait();
      this.httpService.post('/custom/escriturar/salvar-pedido', listasEnviar)
        .subscribe(result => {
          if (result.error) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })

            this.pedido.valorBaseCalculoIcms = this.utilService.parseDecimalString(this.pedido.valorBaseCalculoIcms);
            this.pedido.valorIcms = this.utilService.parseDecimalString(this.pedido.valorIcms);
            this.pedido.valorIcmsSt = this.utilService.parseDecimalString(this.pedido.valorIcmsSt);
            this.pedido.valorIpi = this.utilService.parseDecimalString(this.pedido.valorIpi);
            this.pedido.valorPis = this.utilService.parseDecimalString(this.pedido.valorPis);
            this.pedido.valorCofins = this.utilService.parseDecimalString(this.pedido.valorCofins);
            this.pedido.valorFrete = this.utilService.parseDecimalString(this.pedido.valorFrete);
            this.pedido.valorSeguro = this.utilService.parseDecimalString(this.pedido.valorSeguro);
            this.pedido.valorDesconto = this.utilService.parseDecimalString(this.pedido.valorDesconto);
            this.pedido.valorOutrasDespesas = this.utilService.parseDecimalString(this.pedido.valorOutrasDespesas);
            this.pedido.valorImpostoImportacao = this.utilService.parseDecimalString(this.pedido.valorImpostoImportacao);
            this.pedido.valorIcmsSubst = this.utilService.parseDecimalString(this.pedido.valorIcmsSubst);
            this.pedido.valorIcmsUfDestino = this.utilService.parseDecimalString(this.pedido.valorIcmsUfDestino);
            this.pedido.valorIcmsUfRemetente = this.utilService.parseDecimalString(this.pedido.valorIcmsUfRemetente);
            this.pedido.valorFcp = this.utilService.parseDecimalString(this.pedido.valorFcp);
            this.pedido.valorTotalTributos = this.utilService.parseDecimalString(this.pedido.valorTotalTributos);



          } else {

            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })

            this.LogManagement.registerLog(
              [3, 4, 5].includes(this.pedido.pedidoTipo.id) ? LogModule.PURCHASES : LogModule.SALES,
              [3, 4, 5].includes(this.pedido.pedidoTipo.id) ? LogScreen.PRODUCT_PURCHASES_ORDER : LogScreen.PRODUCT_SALES_ORDER,
              '/custom/escriturar/salvar-pedido',
              LogAction.CREATE,
              result.id,
              result.codigo,
              `O pedido (${result.codigo}) foi criado`,
              listasEnviar
            )

            if (salvarNovo) {
              if (this.flowUses && this.requestApproval) {
                this.pedidosSelecionados.push(new Pedido(result.id));
                this.solicitarAprovacao(false);
              }

              this.buscarPedidos();

              this.buscarParticipante();
            }
            else {
              // this.visualizarPedido(result, false);
              if (this.flowUses && this.requestApproval) {
                this.pedidosSelecionados.push(new Pedido(result.id));
                this.solicitarAprovacao(false);
              }

              this.buscarPedidos();
            }
          }
        },
          error => this.httpService.handleError(error, () => this.salvarPedido(salvarNovo)), () => this.httpService.done());
    }
  }

  onInputDate(event: any) {
    let value = event.target.value.replace(/^(\d{1,2})\/?(\d{1,2})\/?(\d{1,4})/, '$1$2$3').split(/\D/)[0],
      response = '';

    if (value.slice(0, 2).length > 0) {
      response = value.slice(0, 2) <= 31 ? value.slice(0, 2) : ''
      if (value.slice(2, 4).length > 0) {
        response += '/' + (value.slice(2, 4) <= 12 ? value.slice(2, 4) : '')
        if (value.slice(4, 8).length > 0) {
          response += '/' + (value.slice(6, 7) >= 1 && value.slice(6, 7) <= 3 ? value.slice(4, 8) : value.slice(4, 6))
        }
      }
    }
    event.target.value = response
  }

  validacao() {
    let retorno: boolean = true;
    let detalhe: string;
    if (this.pedido.pedidoStatus.id === null || this.pedido.pedidoStatus.id === undefined) {
      detalhe = 'Selecione status do pedido para prosseguir!'
      retorno = false;
    }

    if (!retorno) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: detalhe })
      return false
    }
    return true;
  }

  selecionarModelo() {
  }

  editarPedido() {
    this.editPedido = true;
    this.showSolicitarAprovacao = this.flowUses && [null, undefined, 2, 4, 7, 10].includes(this.pedidosSelecionados[0].status_aprovacao_id)
    this.visualizarPedido(event, false);
  }

  excluirPedido() {

    if (confirm('Deseja realmente excluir este Pedido?')) {

      let listaPedidosId = [];
      let pedidosCodsTipos = []

      listaPedidosId = this.pedidosSelecionados.map(item => item.id)
      this.pedidosSelecionados.forEach(item => pedidosCodsTipos.push({ 'id': item.id, 'codigo': item.codigo, 'tipo': item.pedido_tipo_id, 'objeto': item }))

      if (this.numeroAba === 3) {

        if (confirm('Todos os lançamentos contábeis, fiscais e financeiros serão desfeitos, deseja continuar?')) {
          const listasEnviar = {
            pedidos: listaPedidosId,
            userId: this.dblinkedSessionService.usuario.id
          };

          this.httpService.wait();
          this.httpService.post('/custom/pedido/excluir-pedido-finalizado-id', listasEnviar)
            .subscribe(result => {
              if (result.error === true) {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
              } else {
                this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })

                pedidosCodsTipos.forEach(pedido => this.LogManagement.registerLog(
                  [3, 4, 5].includes(pedido.tipo) ? LogModule.PURCHASES : LogModule.SALES,
                  [3, 4, 5].includes(pedido.tipo) ? LogScreen.PRODUCT_PURCHASES_ORDER : LogScreen.PRODUCT_SALES_ORDER,
                  '/custom/pedido/excluir-pedido-finalizado-id',
                  LogAction.DELETE,
                  pedido.id,
                  pedido.codigo,
                  `O pedido (${pedido.codigo}) foi excluido`,
                  pedido.objeto
                ))

                this.buscarPedidos();
              }

            },
              error => this.httpService.handleError(error, () => this.excluirPedido()),
              () => this.httpService.done());
        }

      } else {

        const listasEnviar = {
          pedidos: listaPedidosId,
          todos: false,
          userId: this.dblinkedSessionService.usuario.id
        };

        this.httpService.wait();
        this.httpService.post('/custom/pedido/excluir-pedido-id', listasEnviar)
          .subscribe(result => {
            if (result.error) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })

              pedidosCodsTipos.forEach(pedido => this.LogManagement.registerLog(
                [3, 4, 5].includes(pedido.tipo) ? LogModule.PURCHASES : LogModule.SALES,
                [3, 4, 5].includes(pedido.tipo) ? LogScreen.PRODUCT_PURCHASES_ORDER : LogScreen.PRODUCT_SALES_ORDER,
                '/custom/pedido/excluir-pedido-id',
                LogAction.DELETE,
                pedido.id,
                pedido.codigo,
                `O pedido (${pedido.codigo}) foi excluido`,
                pedido.objeto
              ))
              this.buscarPedidos();
            }

          },
            error => this.httpService.handleError(error, () => this.excluirPedido()),
            () => this.httpService.done());
      }
    }
  }

  gerarBoleto() {

    let listaPedidosId = [];

    this.idPedidoView.forEach(element => {
      listaPedidosId.push(parseInt(element.toString().split('/')[0]));
    });

    const listasEnviar = {
      pedidos: listaPedidosId,
      empresa_id: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'flaskfin/boleto/post-boleto', listasEnviar)
      .subscribe(result => {
        if (result.erro) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.mensagem })
          this.httpService.done();
          return;
        }

        const httpOptions2 = {
          headers: new HttpHeaders({
            'Authorization': this.configService.bearerApi,
            'Cache-Control': 'no-store, max-age=0'
          }),

          responseType: 'blob' as 'json'
        };
        this.httpService.wait();
        this.httpClient.get(`${this.configService.defaultUrlApiUpload}flaskfin/boleto/get-boleto-zip(${result.id})`,
          httpOptions2).subscribe(
            (res: Blob) => {
              let link = document.createElement('a');
              let url = window.URL.createObjectURL(res);
              link.href = url;
              let data = new Date();
              let fileName = `boletos_${data.getDate()}/0${data.getMonth() + 1}/${data.getFullYear()} `
              link.download = fileName;
              link.click();

              window.URL.revokeObjectURL(url);
              this.httpService.done();
            }, err => {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: err })
              console.log(err);
              this.httpService.done();
            });

        const listasEnviar = {
          pedidoIds: listaPedidosId,
          usarFluxo: this.flowUses
        };

        this.httpService.wait();
        this.httpService.post('/custom/pedido/gerar-financeiro-pedido', listasEnviar)
          .subscribe(result => {
            if (result.error === true) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.mensagem })
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.mensagem })
            }
          },
            error => this.httpService.handleError(error, () => this.gerarBoleto()),
            () => this.httpService.done());

        this.httpService.done();

      },
        err => {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: err })
          console.log(err);
          this.httpService.done();
        }
      );
  }

  gerarBoletoFin() {
    let listaPedidosId = [];
    var liids = []
    var myMap = new Map();

    listaPedidosId = this.pedidosSelecionados.map(item => item.id);

    const listasEnviar = {
      pedidoIds: listaPedidosId,
      usarFluxo: this.flowUses
    };

    this.httpService.wait();
    this.httpService
      .post('/custom/pedido/gerar-financeiro-pedido', listasEnviar)
      .subscribe(result => {
        if (result.error || result.nfeIds.length == 0) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.mensagem })
          this.httpService.done();
          return;
        }

        const listasEnviar = {
          pedidos: result.nfeIds,
          empresa_id: this.dblinkedSessionService.empresa.id,
          usuario: this.sessionService.loggedUser.id
        };


        this.httpService.wait();
        this.httpService
          .post(`${this.configService.defaultUrlApiUpload}flaskfin/boleto/post-boleto`, listasEnviar)
          .subscribe(result => {
            if (result.error) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.mensagem })
              this.httpService.done();
              return;
            }
            liids.push(result.id)

            for (var [key, value] of Object.entries(result.codigo)) {

              myMap.set(parseInt(key), value)

            };


            liids[0].forEach(element => {


              const httpOptions2 = {
                headers: new HttpHeaders({
                  'Authorization': this.configService.bearerApi,
                  'Cache-Control': 'no-store, max-age=0',
                  'liids': element.toString(),
                  'codigo': myMap.get(parseInt(element))
                }),

                responseType: 'blob' as 'json'
              };

              this.httpService.wait();
              this.httpClient
                .get(`${this.configService.defaultUrlApiUpload}flaskfin/boleto/get-boleto-zip`, httpOptions2)
                .subscribe((res: Blob) => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);
                  link.href = url;
                  let data = new Date();
                  let fileName = myMap.get(parseInt(element)).toString()
                  link.download = fileName;
                  link.click();

                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                  error => this.httpService.handleError(error, () => this.gerarBoletoFin()),
                  () => this.httpService.done()
                );
            });
          },
            error => this.httpService.handleError(error, () => this.gerarBoletoFin()),
            () => this.httpService.done()
          );
      },

        error => this.httpService.handleError(error, () => this.gerarBoletoFin()),
        () => this.httpService.done()
      );
  }

  buildEditOptions() {
    this.opcoesCapituloTipi = [];
    this.opcoesCapituloTipi.push({ label: 'Selecione NCM', value: null });

    this.tipi.forEach(element => {
      if (element.ncm.length === 8) {
        this.opcoesCapituloTipi.push({ label: element.ncm + ' - ' + element.descricao, value: element.ncm });
      }
    });

    // this.opcoesTipoIntermediacao = [];
    // this.opcoesTipoIntermediacao.push({ label: 'Selecione Tipo', value: null });
    // this.tipoIntermediacao.forEach(element => {
    //   this.opcoesTipoIntermediacao.push({ label: element.descricao, value: element.id });
    // });

    // this.opcoesViaTransp = [];
    // this.opcoesViaTransp.push({ label: 'Selecione Via Transp', value: null });
    // this.transporteTipos.forEach(element => {
    //   this.opcoesViaTransp.push({ label: element.descricao, value: element.id });
    // });

    // this.opcoesUf = [];
    // this.opcoesUf.push({ label: 'Selecione UF', value: null });
    // this.ufs.forEach(element => {
    //   this.opcoesUf.push({ label: element.uf, value: element.id });
    // });

    // this.opcoesUnidadeMedida = [];
    // this.opcoesUnidadeMedida.push({ label: 'Selecione Unidade Medida', value: null });
    // this.tipoUnidadeMedida.forEach(element => {
    //   this.opcoesUnidadeMedida.push({ label: element.codigo, value: element.id });
    // });

    this.loadAlmoxarifado()
  }

  visualizarPedido(event: any, readOnly: boolean) {

    if (!event.id) event = this.pedidosSelecionados[0];

    this.buildEditOptions();

    this.botaoEditar = true;
    this.valorTotalFormasPagto = 0;
    this.visualizacaoContabil = new Array();

    this.isReadOnly = readOnly;
    this.onRowSelectPedido(event, readOnly);

    this.toolbarEdit.setVisible('save', !readOnly);

    if (this.isReadOnly) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Pedido está somente como leitura' })
    }
  }

  onRowSelectPedido(event: any, readOnly: boolean) {

    this.httpService.wait();
    this.httpService.get('/custom/pedido/buscar-infos-pedido(' + event.id + ')')

      .subscribe(res => {

        this.todoCardId = this.pedidosSelecionados[0] ? this.pedidosSelecionados[0].todo_card_id : event.todo_card_id
        this.nfsEmitida = res.nfsEmitida;

        this.pedido = new Pedido();
        this.pedido.temFaturaAsaas = event.cobranca_gerada
        this.pedido.temPagamento = (event.status_pago === 1 || event.status_pago === 2) ? true : false
        this.pedido.id = res.id;
        this.pedido.participanteEmpresaId = res.participanteEmpresaId;
        this.pedido.codigo = res.codigo;
        this.pedido.numNf = res.numNf;
        this.pedido.serie = res.serie;
        this.pedido.dtEntrega = res.dtEntrega;
        this.pedido.dtEmissao = res.dtEmissao;
        this.pedido.dtFaturamentoAutomatico = res.dtFaturamentoAutomatico;
        this.pedido.enderecoCobranca = res.enderecoCobranca;
        this.pedido.enderecoEntrega = res.enderecoEntrega;
        this.pedido.dadosAdicionais = res.dadosAdicionais;
        this.pedido.codigoOrcamento = res.codigoOrcamento;
        this.pedido.codigoControle = res.codigoControle;
        this.pedido.metodoEnvio = res.metodoEnvio;
        this.pedido.chvNfe = res.chvNfe;
        this.pedido.nfeReferenciada = res.nfeReferenciada;
        this.pedido.codModDoc = res.codModDoc;
        this.pedido.numSeqEcf = res.numSeqEcf;
        this.pedido.numContOrdem = res.numContOrdem;
        this.pedido.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorInss));
        this.pedido.valorIr = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorIr));
        this.pedido.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorCsll));
        this.pedido.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorPis));
        this.pedido.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorCofins));
        this.pedido.valorIssRetido = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorIssRetido));
        this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorOutrasRetencoes));
        this.pedido.valorIcmsSt = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorIcmsSt));
        this.pedido.valorIcmsSubst = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorIcmsSubst));
        this.pedido.valorBaseCalculoIcms = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorBaseCalculoIcms));
        this.pedido.valorIcms = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorIcms));
        this.pedido.valorIcmsUfRemetente = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorIcmsUfRemetente));
        this.pedido.valorIcmsUfDestino = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorIcmsUfDestino));
        this.pedido.valorFcp = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorFcp));
        this.pedido.valorIpi = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorIpi));
        this.pedido.valorFrete = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorFrete));
        this.pedido.valorSeguro = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorSeguro));
        this.pedido.valorDesconto = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorDesconto));
        this.pedido.valorOutrasDespesas = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorOutrasDespesas));
        this.pedido.valorImpostoImportacao = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorImpostoImportacao));
        this.pedido.valorTotalTributos = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorTotalTributos));
        this.pedido.valorTotal = this.utilService.parseDecimalString(this.utilService.parseDecimal(res.valorTotal));
        this.pedido.txMoeda = this.utilService.parseDecimal6(res.txMoeda);
        this.pedido.participante = new Participante();
        this.pedido.participante = res.participante;
        this.pedido.consumidorFinal = res.consumidorFinal;
        this.operacaoRegraAntecipado = res.operacaoRegraAntecipado;
        this.vendedorId = res.vendedorId;
        this.agenciaId = res.agenciaId;
        this.valorComissao = res.valorComissao;
        this.pagamentoComissao = res.pagamentoComissao;

        if (this.agenciaId > 0) {
          this.exbirModalAgencia = true;
        }

        this.pedido.codigoAntt = res.codigoAntt;
        this.pedido.placaVeiculo = res.placaVeiculo;
        this.pedido.qVol = res.qVol;
        this.pedido.especieVol = res.especieVol;
        this.pedido.marcaVol = res.marcaVol;
        this.pedido.numeracaoVol = res.numeracaoVol;
        this.pedido.pesoL = res.pesoL;
        this.pedido.pesoB = res.pesoB;
        this.pedido.embarqueLocal = res.embarqueLocal;
        this.pedido.embarqueDescricao = res.embarqueDescricao;
        this.pedido.numeroInvoice = res.numeroInvoice;
        this.pedido.dataInvoice = res.dataInvoice;
        this.pedido.awb = res.awb;
        this.pedido.dataAwb = res.dataAwb;
        this.codigoMoeda = res.moeda.codigo;
        this.pedido.converterTaxa = res.converterTaxa;

        if (!isNullOrUndefined(res.transportador)) {
          this.pedido.transportador = new Participante();
          this.pedido.transportador = res.transportador;
        } else {
          this.pedido.transportador = new Participante();
        }

        if (!isNullOrUndefined(res.embarqueUf)) {
          this.pedido.embarqueUf = new Uf();
          this.pedido.embarqueUf = res.embarqueUf;
        } else {
          this.pedido.embarqueUf = new Uf();
        }

        if (!isNullOrUndefined(res.ufPlaca)) {
          this.pedido.ufPlaca = new Uf();
          this.pedido.ufPlaca = res.ufPlaca;
        } else {
          this.pedido.ufPlaca = new Uf();
        }

        if (!isNullOrUndefined(res.pedidoTipo)) {
          this.pedido.pedidoTipo = new PedidoTipo();
          this.pedido.pedidoTipo = res.pedidoTipo;
        } else {
          this.pedido.pedidoTipo = new PedidoTipo();
        }

        if (!isNullOrUndefined(res.pedidoStatus)) {
          this.pedido.pedidoStatus = new PedidoStatus();
          this.pedido.pedidoStatus = res.pedidoStatus;
        } else {
          this.pedido.pedidoStatus = new PedidoStatus();
        }

        if (!isNullOrUndefined(res.tipoFrete)) {
          this.pedido.tipoFrete = new TipoFrete();
          this.pedido.tipoFrete = res.tipoFrete;
        } else {
          this.pedido.tipoFrete = new TipoFrete();
        }

        if (!isNullOrUndefined(res.transportador)) {
          this.pedido.transportador = new Participante();
          this.pedido.transportador = res.transportador;
        } else {
          this.pedido.transportador = new Participante();
        }

        if (!isNullOrUndefined(res.nfePresencial)) {
          this.pedido.nfePresencial = new NfePresencial();
          this.pedido.nfePresencial = res.nfePresencial;
        } else {
          this.pedido.nfePresencial = new NfePresencial();
        }

        if (!isNullOrUndefined(res.ufPlaca)) {
          this.pedido.ufPlaca = new Uf();
          this.pedido.ufPlaca = res.ufPlaca;
        } else {
          this.pedido.ufPlaca = new Uf();
        }

        if (!isNullOrUndefined(res.embarqueUf)) {
          this.pedido.embarqueUf = new Uf();
          this.pedido.embarqueUf = res.embarqueUf;
        } else {
          this.pedido.embarqueUf = new Uf();
        }

        if (!isNullOrUndefined(res.nfeFinalidade)) {
          this.pedido.nfeFinalidade = new NfeFinalidade();
          this.pedido.nfeFinalidade = res.nfeFinalidade;
        } else {
          this.pedido.nfeFinalidade = new NfeFinalidade();
        }

        if (!isNullOrUndefined(res.modNf)) {
          this.pedido.modNf = new ModNf();
          this.pedido.modNf = res.modNf;
        } else {
          this.pedido.modNf = new ModNf();
        }

        if (!isNullOrUndefined(res.moeda)) {
          this.pedido.moeda = new Moeda();
          this.pedido.moeda = res.moeda;
        } else {
          this.pedido.moeda = new Moeda();
        }

        if (!isNullOrUndefined(res.tipoIncoterm)) {
          this.pedido.tipoIncoterm = new TipoIncoterm();
          this.pedido.tipoIncoterm = res.tipoIncoterm;
        } else {
          this.pedido.tipoIncoterm = new TipoIncoterm();
        }

        if (!isNullOrUndefined(res.formaPagamento)) {
          this.pedido.formaPagamento = new FormaPagamento();
          this.pedido.formaPagamento = res.formaPagamento;
        } else {
          this.pedido.formaPagamento = new FormaPagamento();
        }

        if (!isNullOrUndefined(res.empresaConta)) {
          this.pedido.empresaConta = new EmpresaConta();
          this.pedido.empresaConta = res.empresaConta;
        } else {
          this.pedido.empresaConta = new EmpresaConta();
        }

        if (!isNullOrUndefined(res.empresa)) {
          this.pedido.empresa = new Empresa();
          this.pedido.empresa = res.empresa;
        } else {
          this.pedido.empresa = new Empresa();
        }

        this.pedido.tipoPagamento = res.tipoPagamento;

        this.pedido.pedidoParcela = new Array();
        res.pedidoParcela.forEach(parcela => {
          let pedParcela = new PedidoParcela();
          pedParcela.valor = this.utilService.parseDecimal(parcela.valor);
          pedParcela.dtVencimento = this.utilService.dateWithoutTimeZone(parcela.dtVencimento);
          pedParcela.parcela = parcela.parcela;
          pedParcela.id = parcela.id

          pedParcela.historico = parcela.historico;

          pedParcela.tipoBaixa = new TipoBaixa()
          if (!isNullOrUndefined(parcela.tipoBaixa)) {
            pedParcela.tipoBaixa.id = parcela.tipoBaixa.id;
            pedParcela.tipoBaixa.descricao = parcela.tipoBaixa.descricao;
          }

          if (parcela.tipoPagamento) {
            pedParcela.tipoPagamentoId = parcela.tipoPagamento.id
            pedParcela.tipoPagamentoDesc = parcela.tipoPagamento.descricao
          }

          pedParcela.empresaConta = new EmpresaConta()
          if (parcela.empresaConta) {
            pedParcela.empresaConta.id = parcela.empresaConta.id
            pedParcela.empresaConta.nome = parcela.empresaConta.nome

          } else {
            pedParcela.empresaConta.id = res.empresaConta.id
            pedParcela.empresaConta.nome = res.empresaConta.nome
          }

          this.pedido.pedidoParcela.push(pedParcela);
          this.includeCalendarState()
        });

        this.comboCfop();

        this.pedido.pedidoItem = new Array();
        res.pedidoItens.forEach(pedItem => {

          // this.comboCfop();
          // this.comboAlmoxarifado();

          let pedidoItem = new PedidoItem();
          pedidoItem.operacaoRegra = new OperacaoRegra();


          pedidoItem.id = pedItem.id;
          pedidoItem.quantidade = pedItem.quantidade;
          pedidoItem.valorUnit = this.utilService.parseDecimalString6(pedItem.valorUnit);
          pedidoItem.valorDesconto = this.utilService.parseDecimalString(pedItem.valorDesconto);
          pedidoItem.modBcIcms = pedItem.modBcIcms;
          pedidoItem.percRedBcIcms = pedItem.percRedBcIcms;
          pedidoItem.valorBcIcms = pedItem.valorBcIcms;
          pedidoItem.aliquotaIcms = pedItem.aliquotaIcms;
          pedidoItem.valorIcms = pedItem.valorIcms;
          pedidoItem.valorFrete = this.utilService.parseDecimalString(pedItem.valorFrete);
          pedidoItem.valorSeguro = this.utilService.parseDecimalString(pedItem.valorSeguro);
          pedidoItem.valorOutro = this.utilService.parseDecimalString(pedItem.valorOutro);
          pedidoItem.valorTotalProduto = this.utilService.parseDecimalString(pedItem.valorTotalProduto);
          pedidoItem.valorTotal = this.utilService.parseDecimalString(pedItem.valorTotal);
          pedidoItem.valorSaving = this.utilService.parseDecimalString(pedItem.valorSaving);

          pedidoItem.observacaoSaving = pedItem.observacaoSaving;

          pedidoItem.codigoPedidoPart = pedItem.codigoPedidoPart;
          pedidoItem.codigoProdPart = pedItem.codigoProdPart;

          pedidoItem.modBcIcmsSt = parseInt(pedItem.modBcIcmsSt);
          pedidoItem.valorBcIcmsSt = pedItem.valorBcIcmsSt;
          pedidoItem.aliquotaIcmsSt = pedItem.aliquotaIcmsSt;
          pedidoItem.valorIcmsSt = pedItem.valorIcmsSt;
          pedidoItem.csosn = pedItem.csosn;
          pedidoItem.ncm = pedItem.ncm;

          pedidoItem.classeIpi = pedItem.classeIpi;
          pedidoItem.codigoIpi = pedItem.codigoIpi;
          pedidoItem.valorBcIpi = pedItem.valorBcIpi;
          pedidoItem.aliquotaIpi = pedItem.aliquotaIpi;
          pedidoItem.valorIpi = pedItem.valorIpi;

          pedidoItem.valorBcPis = pedItem.valorBcPis;
          pedidoItem.perAliquotaPis = pedItem.perAliquotaPis;
          pedidoItem.vlrAliquotaPis = pedItem.vlrAliquotaPis;
          pedidoItem.qtdVendaPis = pedItem.qtdVendaPis;
          pedidoItem.valorPis = pedItem.valorPis

          pedidoItem.valorBcCofins = pedItem.valorBcCofins;
          pedidoItem.perAliquotaCofins = pedItem.perAliquotaCofins;
          pedidoItem.vlrAliquotaCofins = pedItem.vlrAliquotaCofins;
          pedidoItem.qtdVendaCofins = pedItem.qtdVendaCofins;
          pedidoItem.valorCofins = pedItem.valorCofins;

          pedidoItem.valorBcImport = pedItem.valorBcImport;
          pedidoItem.valorDespImport = pedItem.valorDespImport;
          pedidoItem.valorIofImport = pedItem.valorIofImport;
          pedidoItem.valorImpostoImport = pedItem.valorImpostoImport;

          pedidoItem.valorCide = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedItem.valorCide));
          pedidoItem.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedItem.valorCofins));
          pedidoItem.valorCofinsRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedItem.valorCofinsRet));
          pedidoItem.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedItem.valorCsll));
          pedidoItem.valorSimples = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedItem.valorCsll));
          pedidoItem.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedItem.valorPis));
          pedidoItem.valorPisRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedItem.valorPisRet));
          pedidoItem.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedItem.valorIss));
          pedidoItem.valorIssRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedItem.valorIssRet));
          pedidoItem.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedItem.valorInss));


          pedidoItem.valorBCIcmsFcpUfDest = pedItem.valorBCIcmsFcpUfDest;
          pedidoItem.percBaseCalculoIcmsFcpUfDest = pedItem.percBaseCalculoIcmsFcpUfDest;
          pedidoItem.aliquotaInternaUfTermino = pedItem.aliquotaInternaUfTermino;
          pedidoItem.aliquotaInterestadual = pedItem.aliquotaInterestadual;
          pedidoItem.percentualPartilhaUfTermino = pedItem.percentualPartilhaUfTermino;
          pedidoItem.valorIcmsPartilhaUfInicio = pedItem.valorIcmsPartilhaUfInicio;
          pedidoItem.valorIcmsPartilhaUfTermino = pedItem.valorIcmsPartilhaUfTermino;
          pedidoItem.aliquotaFcpUfTermino = pedItem.aliquotaFcpUfTermino;
          pedidoItem.valorFcpUfTermino = pedItem.valorFcpUfTermino;


          pedidoItem.valorOperSemIcmsDifal = pedItem.valorOperSemIcmsDifal;
          pedidoItem.aliqInternaDifal = pedItem.aliqInternaDifal;
          pedidoItem.aliqInterestadualDifal = pedItem.aliqInterestadualDifal;
          pedidoItem.valorOperComIcmsDifal = pedItem.valorOperComIcmsDifal;
          pedidoItem.icmsOrigemCreditoDifal = pedItem.icmsOrigemCreditoDifal;
          pedidoItem.icmsDestinoDebitoDifal = pedItem.icmsDestinoDebitoDifal;
          pedidoItem.valorRecolherDifal = pedItem.valorRecolherDifal;

          pedidoItem.percentualMercadoriaDevolvida = pedItem.percentualMercadoriaDevolvida;
          pedidoItem.valorIpiDevolvido = pedItem.valorIpiDevolvido;

          pedidoItem.numeroDi = pedItem.numeroDi;
          pedidoItem.codigoExportador = pedItem.codigoExportador;
          pedidoItem.dataRegistroImport = pedItem.dataRegistroImport;
          pedidoItem.localDesembaraco = pedItem.localDesembaraco;
          pedidoItem.dataDesembaraco = pedItem.dataDesembaraco;
          pedidoItem.valorAFRMM = pedItem.valorAFRMM;
          pedidoItem.cnpjAdquirente = pedItem.cnpjAdquirente;

          if (!isNullOrUndefined(pedItem.tipoDesconto)) {
            pedidoItem.tipoDesconto = new TipoDesconto();
            pedidoItem.tipoDesconto = pedItem.tipoDesconto;
          } else {
            pedidoItem.tipoDesconto = new TipoDesconto();
          }

          if (!isNullOrUndefined(pedItem.item)) {
            pedidoItem.item = new Item();
            pedidoItem.item = pedItem.item;
          } else {
            pedidoItem.item = new Item();
          }

          if (!isNullOrUndefined(pedItem.operacaoRegra)) {
            pedidoItem.operacaoRegra = new OperacaoRegra();
            pedidoItem.operacaoRegra = pedItem.operacaoRegra;
          } else {
            pedidoItem.operacaoRegra = new OperacaoRegra();
          }

          if (!isNullOrUndefined(pedItem.unidadeMedida)) {
            pedidoItem.unidadeMedida = new UnidadeMedida();
            pedidoItem.unidadeMedida = pedItem.unidadeMedida;
          } else {
            pedidoItem.unidadeMedida = new UnidadeMedida();
          }

          if (!isNullOrUndefined(pedItem.cstIcmsTabelaA)) {
            pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA();
            pedidoItem.cstIcmsTabelaA = pedItem.cstIcmsTabelaA;
          } else {
            pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA();
          }

          if (!isNullOrUndefined(pedItem.cstIcmsTabelaB)) {
            pedidoItem.cstIcmsTabelaB = new CstIcmsTabelaB();
            pedidoItem.cstIcmsTabelaB = pedItem.cstIcmsTabelaB;
          } else {
            pedidoItem.cstIcmsTabelaB = new CstIcmsTabelaB();
          }

          if (!isNullOrUndefined(pedItem.cstIpi)) {
            pedidoItem.cstIpi = new CstIpi();
            pedidoItem.cstIpi = pedItem.cstIpi;
          } else {
            pedidoItem.cstIpi = new CstIpi();
          }

          if (!isNullOrUndefined(pedItem.cstPis)) {
            pedidoItem.cstPis = new CstPiscofins();
            pedidoItem.cstPis = pedItem.cstPis;
          } else {
            pedidoItem.cstPis = new CstPiscofins();
          }

          if (!isNullOrUndefined(pedItem.cstCofins)) {
            pedidoItem.cstCofins = new CstPiscofins();
            pedidoItem.cstCofins = pedItem.cstCofins;
          } else {
            pedidoItem.cstCofins = new CstPiscofins();
          }

          if (!isNullOrUndefined(pedItem.ufDesembaraco)) {
            pedidoItem.ufDesembaraco = new Uf();
            pedidoItem.ufDesembaraco = pedItem.ufDesembaraco;
          } else {
            pedidoItem.ufDesembaraco = new Uf();
          }

          if (!isNullOrUndefined(pedItem.unidadeMedidaTributavel)) {
            pedidoItem.unidadeMedidaTributavel = new UnidadeMedida();
            pedidoItem.unidadeMedidaTributavel = pedItem.unidadeMedidaTributavel;
          } else {
            pedidoItem.unidadeMedidaTributavel = new UnidadeMedida();
          }

          if (!isNullOrUndefined(pedItem.transporteTipo)) {
            pedidoItem.transporteTipo = new TransporteTipo();
            pedidoItem.transporteTipo = pedItem.transporteTipo;
          } else {
            pedidoItem.transporteTipo = new TransporteTipo();
          }

          if (!isNullOrUndefined(pedItem.tipoIntermediacao)) {
            pedidoItem.tipoIntermediacao = new TipoIntermediacao();
            pedidoItem.tipoIntermediacao = pedItem.tipoIntermediacao;
          } else {
            pedidoItem.tipoIntermediacao = new TipoIntermediacao();
          }

          if (!isNullOrUndefined(pedItem.ufAdquirente)) {
            pedidoItem.ufAdquirente = new Uf();
            pedidoItem.ufAdquirente = pedItem.ufAdquirente;
          } else {
            pedidoItem.ufAdquirente = new Uf();
          }

          if (!isNullOrUndefined(pedItem.empresaAlmoxarifado)) {
            pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado();
            pedidoItem.empresaAlmoxarifado.id = pedItem.empresaAlmoxarifado.id;
            pedidoItem.empresaAlmoxarifado.codigo = pedItem.empresaAlmoxarifado.codigo;
            pedidoItem.empresaAlmoxarifado.descricao = pedItem.empresaAlmoxarifado.descricao;
          } else {
            pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado();
          }

          if (!isNullOrUndefined(pedItem.almoxarifadoDestino)) {
            pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado();
            pedidoItem.almoxarifadoDestino.id = pedItem.almoxarifadoDestino.id;
            pedidoItem.almoxarifadoDestino.codigo = pedItem.almoxarifadoDestino.codigo;
            pedidoItem.almoxarifadoDestino.descricao = pedItem.almoxarifadoDestino.descricao;
          } else {
            pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado();
          }

          pedidoItem.lote = pedItem.lote;
          pedidoItem.historicoEstoque = pedItem.historicoEstoque;
          pedidoItem.dtValidadeEstoque = pedItem.dtValidadeEstoque;

          if (!isNullOrUndefined(pedItem.cstIcmsTabelaA)) {
            pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA();
            pedidoItem.cstIcmsTabelaA = pedItem.cstIcmsTabelaA;
          } else {
            pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA();
          }

          if (!isNullOrUndefined(pedItem.cstIcmsTabelaB)) {
            pedidoItem.cstIcmsTabelaB = new CstIcmsTabelaB();
            pedidoItem.cstIcmsTabelaB = pedItem.cstIcmsTabelaB;
          } else {
            pedidoItem.cstIcmsTabelaB = new CstIcmsTabelaB();
          }

          pedidoItem.cfop = pedItem.cfop;
          pedidoItem.dadosAdicionais = pedItem.dadosAdicionais;

          pedidoItem.pedidoItensRateio = new Array();

          this.somaRateio = 0.00;
          pedItem.pedidoItensRateio.forEach(rat => {

            this.somaRateio = this.somaRateio + rat.porcentagem;

            let itemRateio = new PedidoItemRateio();

            itemRateio.porcentagem = rat.porcentagem;

            if (!isNullOrUndefined(rat.cadCcus)) {
              itemRateio.cadCcus = new CadCcus();
              itemRateio.cadCcus = rat.cadCcus;
            } else {
              itemRateio.cadCcus = new CadCcus();
            }

            if (!isNullOrUndefined(rat.cadProj)) {
              itemRateio.cadProj = new CadProj();
              itemRateio.cadProj = rat.cadProj;
            } else {
              itemRateio.cadProj = new CadProj();
            }
            itemRateio.id = rat.id;
            pedidoItem.pedidoItensRateio.push(itemRateio);

          });

          if (this.somaRateio < 100) {
            let itemRateio = new PedidoItemRateio();
            itemRateio.cadCcus = new CadCcus();
            itemRateio.cadProj = new CadProj();
            pedidoItem.pedidoItensRateio.push(itemRateio);
          }

          pedidoItem.pedidoItensExportacao = new Array();

          pedItem.pedidoItensExportacao.forEach(exp => {

            let itemExportacao = new PedidoExportacao();

            itemExportacao.id = exp.id;
            itemExportacao.chaveAcessoNfe = exp.chaveAcessoNfe;
            itemExportacao.numeroAto = exp.numeroAto;
            itemExportacao.numeroRegistro = exp.numeroRegistro;
            itemExportacao.qtdItemExport = exp.qtdItemExport;

            pedidoItem.pedidoItensExportacao.push(itemExportacao);

          });

          pedItem.pedidoItensImportAdicao.forEach(imp => {

            let impAdicao = new ImportacaoAdicao();

            impAdicao.id = imp.id;
            impAdicao.numero = imp.numero;
            impAdicao.desconto = imp.desconto;
            impAdicao.codigoFabricante = imp.codigoFabricante;

            pedidoItem.pedidoItensImportAdicao.push(impAdicao);

          });

          this.valorTotalPedido = (this.utilService.parseDecimal(this.valorTotalPedido) +
            (this.utilService.parseDecimal6(pedidoItem.valorUnit) * this.utilService.parseDecimal6(pedidoItem.quantidade)));

          this.pedido.pedidoItem.push(pedidoItem);

        });

        if ((this.pedido.modNf.id === 22 || this.pedido.modNf.id === 19) && this.pedido.pedidoStatus.id === 7) {
          this.toolbarEdit.setVisible('emit', !readOnly);
        } else if ((this.pedido.modNf.id === 22 || this.pedido.modNf.id === 19) && this.pedido.pedidoStatus.id === 4) {
          this.toolbarEdit.setVisible('emit', !readOnly);
          this.toolbarEdit.setClick('emit', () => this.emitirNfEletronica());
          this.toolbarEdit.setText('emit', 'Emitir NF');
        } else if ((this.pedido.modNf.id === 15) && this.pedido.pedidoStatus.id === 4) {
          this.toolbarEdit.setVisible('emit', !readOnly);
          this.toolbarEdit.setClick('emit', () => this.emitirNfComunic());
          this.toolbarEdit.setText('emit', 'Emitir NF 21');
        }

        if (this.pedido.pedidoStatus.id !== 13 && this.pedido.pedidoStatus.id !== 5) {
          this.toolbarEdit.setVisible('info', readOnly);
        }

        if (this.pedido.pedidoStatus.id == 1) {
          this.habilitarSolicitar = true;
        }

        this.opcoesCfop = [];
        this.opcoesCfop.push({ label: 'Selecione CFOP', value: null });
        this.cfopList.forEach(element => {
          if (this.pedido.pedidoTipo.id === 3 && (element.cfop.startsWith('1') || element.cfop.startsWith('2') || element.cfop.startsWith('3'))) {
            this.opcoesCfop.push({ label: element.cfop, value: element.cfop });
          } else if (this.pedido.pedidoTipo.id === 1 && (element.cfop.startsWith('5') || element.cfop.startsWith('6') || element.cfop.startsWith('7'))) {
            this.opcoesCfop.push({ label: element.cfop, value: element.cfop });
          }

        });

        this.verificaOperacaoRegraGeraFinanceiro(true);

        this.exibirPedido = true;
        this.httpService.wait();
        this.httpService.get('/custom/pedido/buscar-infos-participante(' + this.dblinkedSessionService.empresa.id + ',' +
          this.pedido.participante.id + ',' +
          this.pedido.pedidoTipo.id + ')')
          .subscribe(info => {
            this.infoParticipante = info;

            this.pedidoExterior = (info[0].uf === 'EX') ? true : false;
            this.mesmaUf = (info[0].mesmaUf);
            if (!this.mesmaUf) {
              this.aliquotaInter = info[0].aliquota;
            }

            this.aliquotaInternaPart = info[0].aliquotaPart;
            this.clienteContribuinteIcms = (info[0].contribuinteIcms);
            this.aliquotaInterVenda = info[0].aliquotaVenda;

            setTimeout(() => {
              this.visualizacaoContabil = new Array();
              this.visualizacaoFiscal = new Array();
              this.calculaValorPedido();
              this.calculaValorParcelas();
              this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);
            }, 1000);

            this.pedidoEdit = JSON.parse(JSON.stringify(this.pedido))
            this.pedidoEdit.vendedor = this.vendedorId
            this.pedidoEdit.operacaoRegra = this.operacaoRegraAntecipado
          },
            error => this.httpService.handleError(error, () => this.onRowSelectPedido(event, readOnly)),
            () => this.httpService.done());
      },
        error => this.httpService.handleError(error, () => this.onRowSelectPedido(event, readOnly)),
        () => this.httpService.done());
  }

  //SIMON LALLEMENT: Parece não estar em uso
  // loadPedidoAprovacao(value: Array<PedidoAprovacao>) {
  //   this.pedido.pedidoAprovacao = new Array();

  //   value.forEach(element => {
  //     this.pedido.pedidoAprovacao.push(element);
  //   });
  // }

  //SIMON LALLEMENT: Parece não estar em uso
  // loadParcelaPedido(value: Array<PedidoParcela>) {
  //   this.pedido.pedidoParcela = new Array();
  //   value.forEach(element => {
  //     const date = new Date(element.dtVencimento);
  //     element.dtVencimento = this.utilService.dateWithoutTimeZone(date.toDateString());


  //     if (element.tipoBaixa === null) {
  //       element.tipoBaixa = new TipoBaixa();
  //       element.tipoBaixa.id = 0;
  //     }
  //     this.pedido.pedidoParcela.push(element);
  //     this.includeCalendarState()
  //   });
  // }


  findValue(key: string, identification) {
    const opcoes = {
      nfeFinalidade: this.opcoesNfeFinalidade,
      nfePresencial: this.opcoesNfePresencial,
      tipoPagamento: this.opcoesTipoPagamento,
      vendedor: this.opcoesVendedor,
      operacaoRegra: this.opcoesOperacaoCont,
      consumidorFinal: this.opcoesConsumidorFinal
    };

    try {
      let content = null;

      if (Object.keys(opcoes).includes(key)) {
        opcoes[key].forEach((item) => {
          if (item.value == identification.id || item.value == identification) {
            content = item.label;
          }
        });

        return content;
      }

      return content;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  parseObject(object) {
    for (let [key, value] of Object.entries(object)) {
      const parsedValue = this.findValue(key, value);

      if (parsedValue) object[key] = parsedValue;
    }

    return object
  }

  async atualizarPedido(escriturar: boolean) {

    if (this.pedido.pedidoStatus.id === 5) {
      alert('Nao é possível alterar um Pedido Finalizado, a NF deverá ser estornada')
    } else {
      let permite = true;


      if (this.pedido.pedidoItem !== null || this.pedido.pedidoItem !== undefined) {
        if (this.pedido.pedidoTipo.id === 3 && this.pedido.pedidoStatus.id === 12 && !this.pedidoExterior) {
          for (let item of this.pedido.pedidoItem) {
            if (item.cfop === null || item.cfop === undefined || !item.cfop) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Informar o CFOP do Item: ' + item.item.codigo + ' para Prosseguir!' })
              permite = false;
              return;
            }
          };
        }
      }

      // VALIDAÇÃO CC
      if (this.centroCustoObrigatorio) {
        if (this.pedido.pedidoItem !== null || this.pedido.pedidoItem !== undefined) {
          for (let item of this.pedido.pedidoItem) {
            let porcentagem = 0;
            for (let rat of item.pedidoItensRateio) {
              porcentagem = this.utilService.parseDecimal(porcentagem) + this.utilService.parseDecimal(rat.porcentagem);
              if (rat.cadCcus.id === null || rat.cadCcus.id === undefined) {
                // this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está sem centro de custo relacionado, verifique!' })
                // permite = false;
                // return;
              }
              if (porcentagem === 0 || porcentagem === null || porcentagem === undefined) {
                // this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está com centro de custo sem porcentagem informada!' })
                // permite = false;
                // return;
              }
              if (rat.cadCcus.projetosRelacionados && (rat.cadProj.id === null || rat.cadProj.id === undefined)) {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Rateio ' + (item.pedidoItensRateio.indexOf(rat) + 1) + ' do Item: ' + item.item.codigo + ' está sem projeto relacionado, verifique!' })
                permite = false;
                return;
              }
            }
            if ((porcentagem < 100.00 || porcentagem > 100.00) && porcentagem > 0.00) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está com ' + porcentagem + '%, deve ser 100%, verifique!' })
              permite = false;
              return;
            }
          }
        }
      }
      // FIM VALIDAÇÃO CC

      if (permite == true) {

        if (this.pedido.pedidoStatus.id === null || this.pedido.pedidoStatus.id === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione o status para Prosseguir!' })
          return;
        }
        if (this.pedido.modNf.id === null || this.pedido.modNf.id === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione o modelo para Prosseguir!' })
          return;
        }
        if (this.pedido.dtEmissao === null || this.pedido.dtEmissao === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione a data de emissão para Prosseguir!' })
          return;
        }
        if ((this.pedido.numNf === null || this.pedido.numNf === undefined || this.pedido.numNf.trim() === '') && (((this.pedido.pedidoStatus.id === 7 || this.pedido.pedidoStatus.id === 4) && this.pedido.modNf.id !== 22 && this.pedido.modNf.id !== 15) || (this.pedido.pedidoStatus.id === 12))) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe o número do documento para Prosseguir!' })
          return;
        }
        if (this.pedido.pedidoStatus.id !== 1) {
          if (this.pedido.modNf.id !== 15 && (this.pedido.serie === '' || this.pedido.serie === null || this.pedido.serie === undefined || this.pedido.serie.length > 3)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe a série do pedido com até 3 caracteres para Prosseguir!' })
            return;
          }
          if (this.pedido.dtFaturamentoAutomatico === null || this.pedido.dtFaturamentoAutomatico === undefined) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione a data de entrada para Prosseguir!' })
            return;
          }
          if ((this.pedido.dtFaturamentoAutomatico === null || this.pedido.dtFaturamentoAutomatico === undefined) && this.pedido.pedidoTipo.id === 1) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione a data de saída para Prosseguir!' })
            return;
          }
        }
        if ((this.pedido.chvNfe === null || this.pedido.chvNfe === undefined || this.pedido.chvNfe.length !== 44) && ((this.pedido.modNf.id === 23 && (this.pedido.pedidoStatus.id === 7 || this.pedido.pedidoStatus.id === 4)) || ((this.pedido.pedidoStatus.id === 12) && (this.pedido.modNf.id === 22 || this.pedido.modNf.id === 23)))) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Chave Eletrônica deverá ser Informada com 44 caracteres!' })
          return;
        }
        if ((this.pedido.nfeReferenciada === null || this.pedido.nfeReferenciada === undefined || this.pedido.nfeReferenciada.length === 0) && (this.pedido.nfeFinalidade.id === 5 || this.pedido.nfeFinalidade.id === 4)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Nfe Referenciada deverá ser Informada' })
          return;
        }

        // VALIDAÇÃO ITEM
        if ((!this.pedido.pedidoItem) || this.pedido.pedidoItem.length === 0) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Informar pelo Menos um Item no Pedido!' })
          return;
        }
        // FIM VALIDAÇÃO ITEM

        // INFORMAÇÕES DE PAGAMENTO
        if (((!this.pedido.tipoPagamento) || this.pedido.tipoPagamento === 0) && this.operacaoRegraGeraFinanceiro) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione o Tipo de Pagamento para Prosseguir!' })
          return;
        }
        if (this.pedido.tipoPagamento === 1) {
          if ((!this.pedido.formaPagamento.id) || this.pedido.formaPagamento.id === undefined) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione a Forma de Pagamento para Prosseguir!' })
            return;
          }
          if (this.pedido.empresaConta.id === null || this.pedido.empresaConta.id === undefined) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione o tipo de Conta para Prosseguir!' })
            return;
          }
        }
        for (let parcela of this.pedido.pedidoParcela) {
          if (parcela.dtVencimento == null || !parcela.dtVencimento) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Data de Vencimento da Parcela Inválida!' })
            return;
          }
        }

        if (this.utilService.parseDecimal(this.valorTotalPedido) < this.utilService.parseDecimal(this.valorTotalParcelas)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O valor do pedido é diferente do total das parcelas, verifique.' })
          return;
        }
        // FIM INFORMAÇÕES DE PAGAMENTO

        // VALIDAÇÃO TRANSPORTE COMPRA PROD E VENDA PROD NACIONAL
        if ((this.pedido.tipoFrete.id !== null || this.pedido.tipoFrete.id !== undefined) && ((this.pedido.pedidoTipo.id === 3) || (this.pedido.pedidoTipo.id === 1 && !this.pedidoExterior))) {
          if ((this.pedido.tipoFrete.id === 1 || this.pedido.tipoFrete.id === 2 || this.pedido.tipoFrete.id === 3) && (this.pedido.transportador.id === null || this.pedido.transportador.id === undefined)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Transportador Deve ser Informado.' })
            return;
          }
          if (this.pedido.tipoFrete.id === 1 || this.pedido.tipoFrete.id === 2 || this.pedido.tipoFrete.id === 3 || this.pedido.tipoFrete.id === 4 || this.pedido.tipoFrete.id === 5) {
            if (this.pedido.qVol === null || (!this.pedido.qVol)) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Quantidade de Volumes não Pode ser Vazio.' })

              return;
            } else if (this.pedido.especieVol === null || (!this.pedido.especieVol)) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Espécie não Pode ser Vazia. Ex.: CX' })
              return;
            } else if (this.pedido.pesoB === null || (!this.pedido.pesoB)) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Peso Bruto não Pode ser Vazio.' })
              return;
            } else if (this.pedido.pesoL === null || (!this.pedido.pesoL)) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Peso Líquido não Pode ser Vazio.' })
              return;
            } else if (this.pedido.numeracaoVol === null || (!this.pedido.numeracaoVol)) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Numeração Deverá ser Informada!' })
              return;
            }
          }
        }
        // FIM VALIDAÇÃO TRANSPORTE VENDA PROD INTER

        // VALIDAÇÃO TRANSPORTE COMPRA PROD E VENDA PROD NACIONAL
        if (this.pedido.pedidoTipo.id === 1 && this.pedidoExterior) {
          if (this.pedido.embarqueUf.id === null || this.pedido.embarqueUf.id === undefined || this.pedido.embarqueUf.id === 999) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione a Uf Embarque para Prosseguir!' })
            return;
          }
          else if (this.pedido.embarqueLocal === null || (!this.pedido.embarqueLocal)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe o Local de Embarque para Prosseguir!' })
            return;
          }
          else if (this.pedido.embarqueDescricao === null || (!this.pedido.embarqueDescricao)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe o Local de Despacho para Prosseguir!' })
            return;
          }
        }
        // FIM VALIDAÇÃO TRANSPORTE VENDA PROD INTER


        // VALIDAÇÃO NF COMUNICACAO
        if (this.pedido.pedidoTipo.id === 3 && (this.pedido.modNf.id === 17 || this.pedido.modNf.id === 15)) {
          if (this.pedido.numNf.length > 9) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Nota de Comunicação não poderá ter mais de 9 dígitos!' })
            return;
          }
        }

        //VALIDAÇÃO FATURA ASAAS
        if (this.usaAsaas && this.pedido.temFaturaAsaas && !this.pedido.temPagamento) {
          if (!confirm("Esse pedido possui uma cobrança ativa pendente, deseje mesmo salvar esse pedido? Essa ação irá excluir a cobrança existente.")) return
        }
        //VALIDAÇÃO COBRANÇA ASAAS

        //VALIDAÇÃO DA DATA DE ENTRADA E EMISSÃO
        if ((this.pedido.dtFaturamentoAutomatico != null && this.pedido.dtEmissao != null)) {
          let convertDateFat: any = this.pedido.dtFaturamentoAutomatico
          let convertDateEmission: any = this.pedido.dtEmissao

          if (typeof (this.pedido.dtFaturamentoAutomatico) == 'string') {
            convertDateFat = `${convertDateFat.split('/')[1]}/${convertDateFat.split('/')[0]}/${convertDateFat.split('/')[2]}`
          }

          if (typeof (this.pedido.dtEmissao) == 'string') {
            convertDateEmission = `${convertDateEmission.split('/')[1]}/${convertDateEmission.split('/')[0]}/${convertDateEmission.split('/')[2]}`
          }
          let dateFatAuto = new Date(convertDateFat)
          let dateEmission = new Date(convertDateEmission)

          if (dateFatAuto < dateEmission) {
            if (this.tipo == 0) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de entrada não pode ser inferior à data de emissão.' })
            }
            else {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de saída não pode ser inferior à data de emissão.' })
            }
            return
          }
        }
        // FIM DA VALIDAÇÃO DE DATA DE ENTREGA E EMISSÃO

        this.pedido.pedidoItem.forEach(it => {
          it.valorTotalProduto = this.utilService.parseDecimal(it.valorTotalProduto);
          it.valorUnit = this.utilService.parseDecimal6(it.valorUnit);
          it.quantidade = this.utilService.parseDecimal6(it.quantidade);

          it.valorUnitTributavel = this.utilService.parseDecimal6(it.valorUnitTributavel);
          it.quantidadeTributavel = this.utilService.parseDecimal6(it.quantidadeTributavel);

          it.valorFrete = this.utilService.parseDecimal(it.valorFrete);
          it.valorDesconto = this.utilService.parseDecimal(it.valorDesconto);
          it.valorSeguro = this.utilService.parseDecimal(it.valorSeguro);
          it.valorOutro = this.utilService.parseDecimal(it.valorOutro);

          it.valorIpi = this.utilService.parseDecimal(it.valorIpi);
          it.aliquotaIpi = this.utilService.parseDecimal(it.aliquotaIpi);

          it.valorBcIcms = this.utilService.parseDecimal(it.valorBcIcms);
          it.aliquotaIcmsSt = this.utilService.parseDecimal(it.aliquotaIcmsSt);
          it.percRedBcIcms = this.utilService.parseDecimal(it.percRedBcIcms);

          it.valorIcms = this.utilService.parseDecimal(it.valorIcms);
          it.aliquotaIcms = this.utilService.parseDecimal(it.aliquotaIcms);

          it.valorIcmsSt = this.utilService.parseDecimal(it.valorIcmsSt);
          it.valorBcIcmsSt = this.utilService.parseDecimal(it.valorBcIcmsSt);

          it.valorPis = this.utilService.parseDecimal(it.valorPis);
          it.vlrAliquotaPis = this.utilService.parseDecimal(it.vlrAliquotaPis);
          it.perAliquotaPis = this.utilService.parseDecimal(it.perAliquotaPis);
          it.valorBcPis = this.utilService.parseDecimal(it.valorBcPis);

          it.valorCofins = this.utilService.parseDecimal(it.valorCofins);
          it.perAliquotaCofins = this.utilService.parseDecimal(it.perAliquotaCofins);
          it.vlrAliquotaCofins = this.utilService.parseDecimal(it.vlrAliquotaCofins);
          it.valorBcCofins = this.utilService.parseDecimal(it.valorBcCofins);

          it.valorIofImport = this.utilService.parseDecimal(it.valorIofImport);
          it.valorDespImport = this.utilService.parseDecimal(it.valorDespImport);
          it.valorImpostoImport = this.utilService.parseDecimal(it.valorImpostoImport);

          it.aliquotaInterestadual = this.utilService.parseDecimal(it.aliquotaInterestadual);
          it.aliquotaInternaUfTermino = this.utilService.parseDecimal(it.aliquotaInternaUfTermino);
          it.percentualMercadoriaDevolvida = this.utilService.parseDecimal(it.percentualMercadoriaDevolvida);
          it.percentualPartilhaUfTermino = this.utilService.parseDecimal(it.percentualPartilhaUfTermino);
          it.percBaseCalculoIcmsFcpUfDest = this.utilService.parseDecimal(it.percBaseCalculoIcmsFcpUfDest);

          it.valorAFRMM = this.utilService.parseDecimal(it.valorAFRMM);

          if (it.cnpjAdquirente) {
            it.cnpjAdquirente = it.cnpjAdquirente.replace(/[^\d]+/g, '');
          }
          it.pedidoItensImportAdicao.forEach(adicao => {
            adicao.desconto = this.utilService.parseDecimal(adicao.desconto);
          });

        });

        // convertendo valores do pedido para decimais
        this.pedido.valorBaseCalculoIcms = this.utilService.parseDecimal(this.pedido.valorBaseCalculoIcms);
        this.pedido.valorIcms = this.utilService.parseDecimal(this.pedido.valorIcms);
        this.pedido.valorIcmsSt = this.utilService.parseDecimal(this.pedido.valorIcmsSt);
        this.pedido.valorIpi = this.utilService.parseDecimal(this.pedido.valorIpi);
        this.pedido.valorPis = this.utilService.parseDecimal(this.pedido.valorPis);
        this.pedido.valorCofins = this.utilService.parseDecimal(this.pedido.valorCofins);
        this.pedido.valorFrete = this.utilService.parseDecimal(this.pedido.valorFrete);
        this.pedido.valorSeguro = this.utilService.parseDecimal(this.pedido.valorSeguro);
        this.pedido.valorDesconto = this.utilService.parseDecimal(this.pedido.valorDesconto);
        this.pedido.valorOutrasDespesas = this.utilService.parseDecimal(this.pedido.valorOutrasDespesas);
        this.pedido.valorImpostoImportacao = this.utilService.parseDecimal(this.pedido.valorImpostoImportacao);
        this.pedido.valorIcmsSubst = this.utilService.parseDecimal(this.pedido.valorIcmsSubst);
        this.pedido.valorIcmsUfDestino = this.utilService.parseDecimal(this.pedido.valorIcmsUfDestino);
        this.pedido.valorIcmsUfRemetente = this.utilService.parseDecimal(this.pedido.valorIcmsUfRemetente);
        this.pedido.valorFcp = this.utilService.parseDecimal(this.pedido.valorFcp);
        this.pedido.valorTotalTributos = this.utilService.parseDecimal(this.pedido.valorTotalTributos);
        this.pedido.valorTotal = this.utilService.parseDecimal(this.pedido.valorTotal);

        //Verificar os itens do RMS
        let rmsChecked: boolean = true
        let dadoChecked: string = null
        let message: string = null
        if (this.pedido.id) {
          if (this.flowUses) {
            let verificarItensPedido = await this.verificarItensRateioXml()

            rmsChecked = verificarItensPedido[0]
            dadoChecked = verificarItensPedido[1]
            message = verificarItensPedido[2]
          }

          if (!rmsChecked) {
            if (dadoChecked) {
              if (this.travarEdicaoItemPedido) {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Os parâmetros da empresa impedem a edição dos itens e do rateio quando o pedido vem de um RMS. Verificar o ' + dadoChecked + '.' })
                return

              } else if (!confirm("Tem inconsistências de " + dadoChecked + " entre os itens do RMS e os itens do pedido, deseja continuar?")) return
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
              return
            }
          }
        }

        const listasEnviar = {
          pedido: JSON.stringify(this.pedido, null, ''),
          empresa: this.dblinkedSessionService.empresa.id,
          usuario: this.sessionService.loggedUser.id,
          mes: this.dblinkedSessionService.periodo.month.value,
          ano: this.dblinkedSessionService.periodo.year.value,
          valorTotal: this.valorTotalPedido,
          info: this.adtosCompensar,
          escriturar: escriturar,
          status: this.pedido.id ? 'edit' : 'novo',
          operacaoRegraAntecipado: this.operacaoRegraAntecipado,
          vendedorId: this.vendedorId,
          usarFluxo: this.flowUses,
          agenciaId: this.agenciaId,
          valorComissao: this.valorComissao,
          pagamentoComissao: this.pagamentoComissao
        };

        // const url = escriturar ? '/custom/pedido/salvar-pedido' : '/custom/pedido/salvar-pedido-back';
        this.httpService.wait();
        this.httpService.post('/custom/escriturar/salvar-pedido', listasEnviar)
          .subscribe(result => {

            if (!result.error) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })

              if (this.todoCardId) {

                let pedidoCopy = JSON.parse(JSON.stringify(this.pedido))
                pedidoCopy.vendedor = this.vendedorId
                pedidoCopy.operacaoRegra = this.operacaoRegraAntecipado

                this.pedidoEdit = this.parseObject(this.pedidoEdit)
                pedidoCopy = this.parseObject(pedidoCopy)

                const payload = {
                  service: Service.APPROVAL_CENTER,
                  action: Action.ORDER,
                  method: Method.PUT,
                  content: {
                    userId: this.sessionService.loggedUser.id,
                    cardId: this.todoCardId,
                    userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
                    compareList: [
                      { before: this.pedidoEdit, after: pedidoCopy },
                    ]
                  }
                }
                this.socketService.send(payload)
              }

              this.LogManagement.registerLog(
                [3, 4, 5].includes(this.pedido.pedidoTipo.id) ? LogModule.PURCHASES : LogModule.SALES,
                [3, 4, 5].includes(this.pedido.pedidoTipo.id) ? LogScreen.PRODUCT_PURCHASES_ORDER : LogScreen.PRODUCT_SALES_ORDER,
                '/custom/escriturar/salvar-pedido',
                LogAction.EDIT,
                result.id,
                result.codigo,
                `O pedido (${result.codigo}) foi modificado`,
                listasEnviar
              )

              if (this.flowUses && this.requestApproval) this.solicitarAprovacao(false);
              this.buscarPedidos()
            } else {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })

              this.pedido.valorBaseCalculoIcms = this.utilService.parseDecimalString(this.pedido.valorBaseCalculoIcms);
              this.pedido.valorIcms = this.utilService.parseDecimalString(this.pedido.valorIcms);
              this.pedido.valorIcmsSt = this.utilService.parseDecimalString(this.pedido.valorIcmsSt);
              this.pedido.valorIpi = this.utilService.parseDecimalString(this.pedido.valorIpi);
              this.pedido.valorPis = this.utilService.parseDecimalString(this.pedido.valorPis);
              this.pedido.valorCofins = this.utilService.parseDecimalString(this.pedido.valorCofins);
              this.pedido.valorFrete = this.utilService.parseDecimalString(this.pedido.valorFrete);
              this.pedido.valorSeguro = this.utilService.parseDecimalString(this.pedido.valorSeguro);
              this.pedido.valorDesconto = this.utilService.parseDecimalString(this.pedido.valorDesconto);
              this.pedido.valorOutrasDespesas = this.utilService.parseDecimalString(this.pedido.valorOutrasDespesas);
              this.pedido.valorImpostoImportacao = this.utilService.parseDecimalString(this.pedido.valorImpostoImportacao);
              this.pedido.valorIcmsSubst = this.utilService.parseDecimalString(this.pedido.valorIcmsSubst);
              this.pedido.valorIcmsUfDestino = this.utilService.parseDecimalString(this.pedido.valorIcmsUfDestino);
              this.pedido.valorIcmsUfRemetente = this.utilService.parseDecimalString(this.pedido.valorIcmsUfRemetente);
              this.pedido.valorFcp = this.utilService.parseDecimalString(this.pedido.valorFcp);
              this.pedido.valorTotalTributos = this.utilService.parseDecimalString(this.pedido.valorTotalTributos);
              return;
            }
          }, error => this.httpService.handleError(error, () => this.atualizarPedido(escriturar)),
            () => this.httpService.done());
      }
    }
  }

  modalClonarPedido() {

    const event = this.pedidosSelecionados[0];

    this.modalClonePedido = true;
    this.opcoesParticipante = [];
    this.pedido.id = event.id;
    this.pedido.participante = new Participante();
    // const participanteTipo = event.ptId === 3 ? 2 : 1;
    // let url = '/participante?$select=id,nome,fantasia,cpf,cnpj,participanteEmpresas/codigo,participanteEmpresas/fantasia,' +
    //   'participanteEmpresas/participanteTipo/id&$filter=participanteEmpresas/empresa/id eq ' + this.dblinkedSessionService.empresa.id +
    //   ' and participanteEmpresas/participanteTipo/id eq ' + participanteTipo;
    // url += '&$orderby=nome';
    // this.httpService.wait();
    // this.httpService.get(url).subscribe(data => {
    //   let participanteEmpresa;
    //   let codigo; let fantasia;
    //   data.value.forEach(element => {

    //     if (element.participanteEmpresas) {
    //       participanteEmpresa = element.participanteEmpresas.find(pe => pe['participanteTipoId'] === participanteTipo);
    //       if (participanteEmpresa) {
    //         codigo = participanteEmpresa.codigo;
    //       }
    //       if (participanteEmpresa.fantasia && participanteEmpresa.fantasia.trim() !== '') {
    //         fantasia = codigo + ' - ' + participanteEmpresa.fantasia;
    //       }
    //     } else if (element.fantasia === undefined || element.fantasia === null || element.fantasia.trim() === '') {
    //       fantasia = ' X0000 ' + element.nome.trim();
    //     }

    //     this.opcoesParticipante.push({ label: fantasia, value: element.id });

    //   });
    //   this.pedido.participante.id = event.participanteId;
    // }, error => this.httpService.handleError(error, () => this.modalClonarPedido(event)),
    //   () => this.httpService.done());
    // status possíveis na NFSE
    // 4 - Aquisição Serviço
    this.cloneStatusPedido = null;
    this.cloneDataEmissao = null;
    this.pedido.pedidoTipo = new PedidoTipo();
    this.pedido.pedidoTipo.id = 4;
  }

  validacaoClonarPedido() {

    if (isNullOrUndefined(this.cloneStatusPedido)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Favor selecionar um status do pedido!' })
      return true;
    }

    if (isNullOrUndefined(this.pedido.dtEmissao)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Favor selecionar a data de emissão!' })
      return true;
    }

    if (isNullOrUndefined(this.pedido.participante.id)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Favor selecionar o participante!' })
      return true;
    }
    return false;
  }

  itemOnRowSelect(event: any) {


    this.botaoEditar = true;
    this.calculaParcelasPgto = true;
    this.retornaIdOperacaoRegra(event.operacaoRegra.id, event.operacaoRegra.tipoRegra);

    this.pedidoItensRateioEdicao = event.pedidoItensRateio;


    setTimeout(() => {

      this.pedidoItem = new PedidoItem(event);

      this.dadosItem(event.item.id, false);
      this.pedidoItem.isServico = event.operacaoRegra.tipoRegra === 2;
      this.exibirItemPedido = true;
      this.calcularValorTotal();
      this.calculaValorTotalItem();
    }, 2000);
  }

  rateioItem(value: any) {
    this.pedidoItem = value;
    this.exibirRateio = true;


    if (this.pedidoItem.pedidoItensRateio.length === 0) {
      let itemRateio = new PedidoItemRateio();
      itemRateio.cadCcus = new CadCcus();
      itemRateio.cadProj = new CadProj();

      itemRateio.id = this.pedidoItem.pedidoItensRateio.length;
      this.pedidoItem.pedidoItensRateio.push(itemRateio);
    }

    this.visualizacaoContabil = new Array();
  }

  //SIMON LALLEMENT: Não usado
  // controlCentroCusto() {
  //   this.modalCentroCusto = true;
  //   this.calculaRateioDisponivelCentroCusto();
  // }

  //SIMON LALLEMENT: Não usado
  // incluirRateioCentroCusto() {

  //   // CENTRO DE CUSTO
  //   if ((this.cc <= 0 || this.cc.value === null)) {
  //     this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É preciso Selecionar um Centro de Custo' })
  //     return;
  //   }
  //   // iNSERIR PORCENTAGEM
  //   else if ((this.porcentagemCc <= 0) || isNullOrUndefined(this.porcentagemCc)) {
  //     this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Por Favor, Insira a Porcentagem do Rateio!' })
  //     return;
  //   }
  //   // PORCENTAGEM UTILIZADO
  //   else if ((this.rateioCentroCusto <= 0) || (this.rateioCentroCusto < this.porcentagemCc)) {
  //     this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O valor do rateio ultrapassa os 100%, verifique!' })
  //     return;
  //   }

  //   this.pedidoItemRateio = new PedidoItemRateio();

  //   this.rateioCentroCusto = this.rateioCentroCusto - this.utilService.parseDecimal(this.porcentagemCc);
  //   this.pedidoItemRateio.id = this.contadorRateio;
  //   this.pedidoItemRateio.cadProj = this.proj;
  //   this.pedidoItemRateio.cadCcus = this.cc;
  //   this.pedidoItemRateio.porcentagem = this.porcentagemCc;
  //   this.pedidoItem.pedidoItensRateio.push(this.pedidoItemRateio);
  //   this.contadorRateio++;
  // }


  cancelarEdicaoItem() {
    this.exibirItemPedido = false;
    // alterado gilson - > 22.03
    if (this.pedidoItem.tipoDesconto.id === 1 && this.utilService.parseDecimal(this.pedidoItem.valorDesconto) > 0) {
      const desconto = (((this.utilService.parseDecimal6(this.pedidoItem.quantidade) *
        this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) *
        (this.utilService.parseDecimal(this.pedidoItem.valorDesconto) / 100)));
      this.pedidoItem.valorDesconto = (desconto);
      this.pedidoItem.tipoDesconto.cod = '%';
      // alterando o item selecionado
      const index = this.pedido.pedidoItem.findIndex(c => c.id === this.pedidoItem.id);
      this.pedido.pedidoItem.splice(index, 1, this.pedidoItem);
    }

    this.pedidoItem = new PedidoItem();
    this.pedidoItem.pedidoItensImportAdicao = new Array();
    this.pedidoItem.pedidoItensExportacao = new Array();
    this.pedidoItem.pedidoItensRateio = new Array();

    this.idPedidoView = []
    this.pedidosSelecionados = []

    this.changeToolbar()
  }

  excluirLinhaPedido(pedidoItemId) {
    this.exibirItemPedido = false;
    // atualizando valores totais
    this.subTotalPedido = 0.00;
    this.valorTotalPedido = 0.00;
    this.valorDescontoItens = 0.00;
    this.pedido.valorDesconto = 0.00;

    const index = this.pedido.pedidoItem.findIndex(c => c.id === pedidoItemId);
    this.pedido.pedidoItem.splice(index, 1);

    // recalculando os totais
    this.pedido.pedidoItem.forEach(element => {
      if (element.tipoDesconto.id === 1) {
        const desconto = (((this.utilService.parseDecimal6(element.valorUnit) * this.utilService.parseDecimal6(element.quantidade)) *
          (this.utilService.parseDecimal(element.valorDesconto) / 100)));
        this.valorDescontoItens = this.utilService.parseDecimal(this.valorDescontoItens)
          + this.utilService.parseDecimal(desconto);
      } else if (element.tipoDesconto.id === 2) {
        this.valorDescontoItens = this.utilService.parseDecimal(this.valorDescontoItens)
          + this.utilService.parseDecimal(element.valorDesconto);
      }

      this.subTotalPedido = this.utilService.parseDecimal(this.subTotalPedido) +
        (this.utilService.parseDecimal6(element.valorUnit) * this.utilService.parseDecimal6(element.quantidade));
      this.valorTotalPedido = (this.utilService.parseDecimal(this.valorTotalPedido) +
        (this.utilService.parseDecimal6(element.valorUnit) * this.utilService.parseDecimal6(element.quantidade)));
    });
    this.valorTotalPedido = (this.valorTotalPedido - this.valorDescontoItens);
    this.pedido.valorDesconto = this.valorDescontoItens;

    this.calculaValorPedido();
    //this.pagamentoForma();
    this.formaPgtoCalcular(true);
    this.pedidoItem = new PedidoItem();

    this.visualizacaoFiscal = new Array();
    this.visualizacaoContabil = new Array();


    if (this.pedido.pedidoItem.length < 1) {
      this.verificaOperacaoRegraGeraFinanceiro(false);
    } else {
      this.verificaOperacaoRegraGeraFinanceiro(true);
    }
  }


  alterarItemLista() {

    let bool = false;
    this.fatoresConversao.forEach(element => {
      if (element.unId === this.pedidoItem.unidadeMedida.id) {
        bool = true;
      }
    });

    if (this.unidadeMedidaOriginalId === this.pedidoItem.unidadeMedida.id) {
      bool = true;
    }

    if (!bool) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Não é possível utilizar esta unidade de medida!' })

      setTimeout(() => {
        this.pedidoItem.unidadeMedida = new UnidadeMedida();
        this.pedidoItem.unidadeMedida.id = this.unidadeMedidaOriginalId;
        this.pedidoItem.unidadeMedida.codigo = this.codigoUnidadeMedidaOriginal;
      }, 1);
      return;
    }

    if (!this.pedidoItem.operacaoRegra.id) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione uma Operação para Prosseguir!' })
      return;
    }
    // alterado GCK 27.11
    if (!this.pedidoItem.item.id) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione um Item para Prosseguir!' })
      return;
    }
    if ((this.pedido.pedidoTipo.id === 3 && this.pedido.pedidoStatus.id === 12) && (this.pedidoItem.cfop === null || this.pedidoItem.cfop === undefined)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Adicione CFOP para Prosseguir!' })
      return;
    }
    /*if (!(this.utilService.parseDecimal6(this.pedidoItem.quantidade) > 0.00)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Quantidade Comercial do item não pode ser zeros ou vazio. Verifique!' })
      return;
    }
    if (!(this.utilService.parseDecimal6(this.pedidoItem.valorUnit) > 0.00)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Valor Unitário Comercial do item não pode ser zeros ou vazio. Verifique!' })
      return;
    }*/
    if (!this.pedidoItem.isServico) {
      if (this.pedidoItem.ncm === null || this.pedidoItem.ncm === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione o NCM do Item para Prosseguir!' })
        return;
      }
      if (this.pedidoItem.unidadeMedidaTributavel === null || this.pedidoItem.unidadeMedidaTributavel === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione uma Unidade Tributável para Prosseguir!' })
        return;
      }
      /*if (!(this.utilService.parseDecimal6(this.pedidoItem.quantidadeTributavel) > 0.00)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Quantidade Tributável do item não pode ser zeros ou vazio. Verifique!' })
        return;
      }
      if (!(this.utilService.parseDecimal6(this.pedidoItem.valorUnitTributavel) > 0.00)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Valor Unitário Tributável do item não pode ser zeros ou vazio. Verifique!' });
        return;
      }
      if (!(this.utilService.parseDecimal6(this.valorItemTributavel) > 0.00)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Valor Total Tributável do item não pode ser zeros ou vazio. Verifique!' })
        return;
      }
      if (!(this.utilService.parseDecimal6(this.pedidoItem.valorTotal) > 0.00)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Valor Total do item não pode ser zeros ou vazio. Verifique!' })
        return;
      }*/
    }

    this.exibirItemPedido = false;
    this.calculaValorTotalItem();
    // atualizando valores totais
    this.subTotalPedido = 0.00;
    this.valorTotalPedido = 0.00;
    this.valorDescontoItens = 0.00;
    this.valorImpostoImportacao = 0.00;

    let valorIcmsImp = 0.00;
    let valorPisCofinsImp = 0.00;

    if (!isNullOrUndefined(this.pedidoItem.cfop)) {
      if (this.pedidoItem.cfop.startsWith('3')) {
        valorIcmsImp = this.utilService.parseDecimal(this.pedidoItem.valorIcms);
        valorPisCofinsImp = this.utilService.parseDecimal(this.pedidoItem.valorPis) + this.utilService.parseDecimal(this.pedidoItem.valorCofins);
      }
    }

    //Olhar se é NF complementar
    let qtdeCalc = this.utilService.parseDecimal6(this.pedidoItem.quantidade)
    if (this.pedido.nfeFinalidade.id === 2) qtdeCalc = 1

    // revalidando tipo de desconto = %
    if (this.pedidoItem.tipoDesconto.id === 1 && this.utilService.parseDecimal(this.pedidoItem.valorDesconto) > 0) {

      const desconto = (((qtdeCalc *
        this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) *
        (this.utilService.parseDecimal(this.pedidoItem.valorDesconto) / 100)));

      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        this.pedidoItem.valorTotal = ((qtdeCalc *
          this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
          // this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
          // this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
          // this.utilService.parseDecimal(this.pedidoItem.valorSeguro) -
          this.utilService.parseDecimal(desconto) +
          this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
          this.utilService.parseDecimal(this.pedidoItem.valorIcms) +
          this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
          this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
          this.utilService.parseDecimal(this.pedidoItem.valorCofins) +
          this.utilService.parseDecimal(this.pedidoItem.valorPis)
        );
      } else {
        this.pedidoItem.valorTotal = ((qtdeCalc *
          this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
          this.utilService.parseDecimal(desconto) +
          this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
          this.utilService.parseDecimal(this.pedidoItem.valorIcmsSt) +
          this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
          this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
          this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
          this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
          this.utilService.parseDecimal(valorIcmsImp) +
          this.utilService.parseDecimal(valorPisCofinsImp)
        );
      }

    } else if (this.pedidoItem.tipoDesconto.id === 2 && this.utilService.parseDecimal(this.pedidoItem.valorDesconto) > 0) {
      this.pedidoItem.valorDesconto = (this.utilService.parseDecimal(this.pedidoItem.valorDesconto));

      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        this.pedidoItem.valorTotal = ((qtdeCalc *
          this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
          // this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
          // this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
          // this.utilService.parseDecimal(this.pedidoItem.valorSeguro) -
          this.utilService.parseDecimal(this.pedidoItem.valorDesconto) +
          this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
          this.utilService.parseDecimal(this.pedidoItem.valorIcms) +
          this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
          this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
          this.utilService.parseDecimal(this.pedidoItem.valorCofins) +
          this.utilService.parseDecimal(this.pedidoItem.valorPis)
        );
      } else {
        this.pedidoItem.valorTotal = ((qtdeCalc *
          this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
          this.utilService.parseDecimal(this.pedidoItem.valorDesconto) +
          this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
          this.utilService.parseDecimal(this.pedidoItem.valorIcmsSt) +
          this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
          this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
          this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
          this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
          this.utilService.parseDecimal(valorIcmsImp) +
          this.utilService.parseDecimal(valorPisCofinsImp));
      }

    } else {

      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        this.pedidoItem.valorTotal = ((qtdeCalc *
          this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
          // this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
          // this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
          this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
          this.utilService.parseDecimal(this.pedidoItem.valorIcms) +
          this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
          this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
          this.utilService.parseDecimal(this.pedidoItem.valorCofins) +
          this.utilService.parseDecimal(this.pedidoItem.valorPis)
        );
      } else {
        this.pedidoItem.valorTotal = ((qtdeCalc *
          this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
          this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
          this.utilService.parseDecimal(this.pedidoItem.valorIcmsSt) +
          this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
          this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
          this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
          this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
          this.utilService.parseDecimal(valorIcmsImp) +
          this.utilService.parseDecimal(valorPisCofinsImp));
      }
    }

    // alterado GCK 27.11
    this.pedidoItem.quantidadeTributavel = this.utilService.parseDecimal6(this.pedidoItem.quantidadeTributavel);
    this.pedidoItem.valorUnitTributavel = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(this.pedidoItem.valorUnitTributavel));

    this.pedidoItem.pedidoItensRateio = new Array();

    this.pedidoItensRateioEdicao.forEach(element => {

      let pedidoItemRateio = new PedidoItemRateio();
      pedidoItemRateio.cadCcus = new CadCcus();
      pedidoItemRateio.cadCcus.id = element.cadCcus.id;
      pedidoItemRateio.cadCcus.codCcus = element.cadCcus.codCcus;
      pedidoItemRateio.cadCcus.descrCcus = element.cadCcus.descrCcus;
      if (!isNullOrUndefined(element.cadProj)) {

        pedidoItemRateio.cadProj = new CadProj();
        pedidoItemRateio.cadProj.id = element.cadProj.id;
        pedidoItemRateio.cadProj.codProj = element.cadProj.codProj;
        pedidoItemRateio.cadProj.descrProj = element.cadProj.descrProj;
      }

      pedidoItemRateio.porcentagem = element.porcentagem;
      this.pedidoItem.pedidoItensRateio.push(pedidoItemRateio);
    });

    const index = this.pedido.pedidoItem.findIndex(c => c.id === this.pedidoItem.id);
    this.pedido.pedidoItem.splice(index, 1, this.pedidoItem);

    // recalculando os totais
    this.pedido.pedidoItem.forEach(element => {

      this.pedidoItem.tipoDesconto.cod = ((element.tipoDesconto.id === 1) ? '%' :
        ((element.tipoDesconto.id === 2) ? 'R$' : null));

      if (element.tipoDesconto.id === 1) {
        const desconto = (((this.utilService.parseDecimal6(element.valorUnit) * qtdeCalc) *
          (this.utilService.parseDecimal(element.valorDesconto) / 100)));
        this.valorDescontoItens = this.utilService.parseDecimal(this.valorDescontoItens)
          + this.utilService.parseDecimal(desconto);
      } else if (element.tipoDesconto.id === 2) {
        this.valorDescontoItens = this.utilService.parseDecimal(this.valorDescontoItens)
          + this.utilService.parseDecimal(element.valorDesconto);
      }
    });

    this.visualizacaoContabil = new Array();
    this.visualizacaoFiscal = new Array();

    this.pedido.valorDesconto = this.valorDescontoItens;
    this.calculaValorPedido();
    //this.pagamentoForma();
    this.verificaOperacaoRegraGeraFinanceiro(true);

    //alert('alterar')
    if (this.pedidoItem.isServico) {
      this.buscarImpostosPedidoServico();
    }

  }


  calculaValorPedido() {
    // inicializando variaveis pedido.
    this.pedido.valorDesconto = 0.00;
    this.pedido.valorPis = 0.00;
    this.pedido.valorIpi = 0.00;
    this.pedido.valorCofins = 0.00;
    // totalizadores
    this.valorIcms = 0.00;
    this.valorBcIcms = 0.00;
    this.valorIcmsSt = 0.00;
    this.valorPis = 0.00;
    this.valorCofins = 0.00;
    this.valorIpi = 0.00;
    this.valorSeguro = 0.00;
    this.valorFrete = 0.00;
    this.valorOutrasDespesas = 0.00;
    this.valorImpostoImportacao = 0.00;
    this.valorBcIcmsSt = 0.00;
    this.valorIcmsUfDestino = 0.00;
    this.valorIcmsUfRemetente = 0.00;
    this.valorFcp = 0.00;
    this.valorTotalTributos = 0.00;
    this.valorDescontoItens = 0.00;
    // TOTALIZADORES
    this.subTotalPedido = 0.00;
    this.valorTotalPedido = 0.00;
    let valorIcmsImportacao = 0.00;

    let valorIcmsPartilhaUfTerminoTotal = 0.00;

    this.pedido.pedidoItem.forEach(it => {
      //Olhar se é NF complementar
      let qtdeCalc = this.utilService.parseDecimal6(it.quantidade)
      if (this.pedido.nfeFinalidade.id === 2) qtdeCalc = 1

      this.subTotalPedido = this.utilService.parseDecimal(this.subTotalPedido) +
        this.utilService.parseDecimal(this.utilService.parseDecimal6(it.valorUnit) * qtdeCalc);

      this.valorIpi = this.valorIpi + this.utilService.parseDecimal(it.valorIpi);
      this.valorBcIcms = this.valorBcIcms + this.utilService.parseDecimal(it.valorBcIcms);
      this.valorIcms = this.valorIcms + this.utilService.parseDecimal(it.valorIcms);
      this.valorIcmsSt = this.valorIcmsSt + this.utilService.parseDecimal(it.valorIcmsSt);
      this.valorBcIcmsSt = this.valorBcIcmsSt + this.utilService.parseDecimal(it.valorBcIcmsSt);
      this.valorPis = this.valorPis + this.utilService.parseDecimal(it.valorPis);
      this.valorCofins = this.valorCofins + this.utilService.parseDecimal(it.valorCofins);
      this.valorSeguro = this.valorSeguro + this.utilService.parseDecimal(it.valorSeguro);
      this.valorFrete = this.valorFrete + this.utilService.parseDecimal(it.valorFrete);

      valorIcmsPartilhaUfTerminoTotal = valorIcmsPartilhaUfTerminoTotal + this.utilService.parseDecimal(it.valorIcmsPartilhaUfTermino);

      if (it.tipoDesconto.id === 2) {
        this.valorDescontoItens = this.valorDescontoItens + this.utilService.parseDecimal(it.valorDesconto);
      } else {
        this.valorDescontoItens = this.valorDescontoItens +
          (((this.utilService.parseDecimal6(it.valorUnit) * qtdeCalc) * this.utilService.parseDecimal(it.valorDesconto)) / 100);
      }

      this.valorOutrasDespesas = this.valorOutrasDespesas + (this.utilService.parseDecimal(it.valorOutro)
        + this.utilService.parseDecimal(it.valorIofImport) + this.utilService.parseDecimal(it.valorDespImport));
      this.valorImpostoImportacao = this.valorImpostoImportacao + this.utilService.parseDecimal(it.valorImpostoImport);

      if (!isNullOrUndefined(it.cfop)) {
        if (it.cfop.startsWith("3")) {
          valorIcmsImportacao = valorIcmsImportacao + this.utilService.parseDecimal(it.valorIcms);
          valorIcmsImportacao = valorIcmsImportacao + this.utilService.parseDecimal(it.valorPis);
          valorIcmsImportacao = valorIcmsImportacao + this.utilService.parseDecimal(it.valorCofins);
        }
      }
    });

    // aplicando os impostos do item nos impostos gerais.
    this.pedido.valorBaseCalculoIcms = this.valorBcIcms;
    this.pedido.valorIcms = this.valorIcms;
    this.pedido.valorIcmsSt = this.valorBcIcmsSt;
    this.pedido.valorIcmsSubst = this.valorIcmsSt;
    this.pedido.valorImpostoImportacao = this.valorImpostoImportacao;
    this.pedido.valorPis = this.valorPis;
    this.pedido.valorFrete = this.valorFrete;
    this.pedido.valorSeguro = this.valorSeguro;
    this.pedido.valorOutrasDespesas = this.valorOutrasDespesas;
    this.pedido.valorIpi = this.valorIpi;
    this.pedido.valorCofins = this.valorCofins;

    // desconto todos itens
    this.pedido.valorDesconto = this.valorDescontoItens;


    this.valorTotalPedido = (this.utilService.parseDecimal6(this.valorTotalPedido) + this.utilService.parseDecimal6(this.subTotalPedido) +
      this.utilService.parseDecimal(valorIcmsImportacao) +
      this.utilService.parseDecimal(this.pedido.valorIcmsSubst) +
      this.utilService.parseDecimal(this.pedido.valorImpostoImportacao) +
      this.utilService.parseDecimal(this.pedido.valorFrete) + this.utilService.parseDecimal(this.pedido.valorSeguro) +
      this.utilService.parseDecimal(this.pedido.valorOutrasDespesas) + this.utilService.parseDecimal(this.pedido.valorIpi))
      - this.valorDescontoItens;

    if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
      this.valorTotalPedido = (this.utilService.parseDecimal6(this.subTotalPedido) -
        // this.valorTotalPedido = (this.utilService.parseDecimal6(this.subTotalPedido) +
        // this.utilService.parseDecimal(this.pedido.valorFrete) +
        // this.utilService.parseDecimal(this.pedido.valorSeguro) -
        this.utilService.parseDecimal(this.valorDescontoItens) +
        this.utilService.parseDecimal(this.pedido.valorOutrasDespesas) +
        this.utilService.parseDecimal(this.pedido.valorIcms) +
        this.utilService.parseDecimal(this.pedido.valorIcmsSubst) +
        this.utilService.parseDecimal(this.pedido.valorIpi) +
        this.utilService.parseDecimal(this.pedido.valorImpostoImportacao) +
        this.utilService.parseDecimal(this.pedido.valorCofins) +
        this.utilService.parseDecimal(this.pedido.valorPis));
    }

    // formatando decimais
    this.valorTotalPedido = this.utilService.parseDecimalString(this.valorTotalPedido);
    this.subTotalPedido = this.utilService.parseDecimalString(this.subTotalPedido);

    this.pedido.valorBaseCalculoIcms = this.utilService.parseDecimalString(this.pedido.valorBaseCalculoIcms);
    this.pedido.valorIpi = this.utilService.parseDecimalString(this.pedido.valorIpi);
    this.pedido.valorIcms = this.utilService.parseDecimalString(this.pedido.valorIcms);
    this.pedido.valorIcmsSt = this.utilService.parseDecimalString(this.pedido.valorIcmsSt);
    this.pedido.valorPis = this.utilService.parseDecimalString(this.pedido.valorPis);
    this.pedido.valorCofins = this.utilService.parseDecimalString(this.pedido.valorCofins);
    this.pedido.valorFrete = this.utilService.parseDecimalString(this.pedido.valorFrete);
    this.pedido.valorSeguro = this.utilService.parseDecimalString(this.pedido.valorSeguro);
    this.pedido.valorDesconto = this.utilService.parseDecimalString(this.pedido.valorDesconto);
    this.pedido.valorOutrasDespesas = this.utilService.parseDecimalString(this.pedido.valorOutrasDespesas);
    this.pedido.valorImpostoImportacao = this.utilService.parseDecimalString(this.pedido.valorImpostoImportacao);
    this.pedido.valorIcmsSubst = this.utilService.parseDecimalString(this.pedido.valorIcmsSubst);
    this.pedido.valorIcmsUfDestino = this.utilService.parseDecimalString(this.pedido.valorIcmsUfDestino);
    this.pedido.valorIcmsUfRemetente = this.utilService.parseDecimalString(this.pedido.valorIcmsUfRemetente);
    this.pedido.valorFcp = this.utilService.parseDecimalString(this.pedido.valorFcp);
    this.pedido.valorTotalTributos = this.utilService.parseDecimalString(this.pedido.valorTotalTributos);
    this.pedido.valorTotal = this.utilService.parseDecimalString(this.valorTotalPedido);
    // this.formaPgtoCalcular(false);
    if (valorIcmsPartilhaUfTerminoTotal > 0) {

      const str = this.pedido.dadosAdicionais.split('-');

      this.pedido.dadosAdicionais = this.pedido.dadosAdicionais;
      const str1 = this.pedido.dadosAdicionais.split('-');

      let existePartilha = false;

      this.pedido.dadosAdicionais = '';
      for (let index = 0; index < str1.length; index++) {

        const element = str1[index];


        if (element.toString().startsWith(' Total do ICMS de Partilha para a UF do destinatário')) {
          this.pedido.dadosAdicionais = this.pedido.dadosAdicionais + ' - Total do ICMS de Partilha para a UF do destinatário R$ ' + this.utilService.parseDecimalString(valorIcmsPartilhaUfTerminoTotal);
          existePartilha = true;
        } else {
          this.pedido.dadosAdicionais = this.pedido.dadosAdicionais + element.toString();
        }

      }

      if (!existePartilha) {
        this.pedido.dadosAdicionais = this.pedido.dadosAdicionais + ' - Total do ICMS de Partilha para a UF do destinatário R$ ' + this.utilService.parseDecimalString(valorIcmsPartilhaUfTerminoTotal);
      }

    }

  }

  acionaCalcValorUnitImport() {
    this.seguroOuFreteAcionado = true;
  }

  atualizarValorUnit() {
    if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
      if (!isNullOrUndefined(this.pedidoItem.valorFrete) || !isNullOrUndefined(this.pedidoItem.valorSeguro)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Atualizar Valor Unitário para somar com o Frete e/ou Seguro!' })
      }
    }
  }

  calculaValorUnitImport() {

    this.valorUnitImportacao = 0.00;

    if (this.seguroOuFreteAcionado) {

      this.valorUnitImportacao = this.utilService.parseDecimal6(this.valorUnitImportacao) +
        this.utilService.parseDecimal6(this.pedidoItem.valorUnit) +
        this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
        this.utilService.parseDecimal(this.pedidoItem.valorSeguro);

      this.seguroOuFreteAcionado = false;

    } else {
      this.valorUnitImportacao = this.utilService.parseDecimal6(this.pedidoItem.valorUnit);
    }

    if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
      this.pedidoItem.valorUnit = this.valorUnitImportacao;
    }
  }

  calculaValorTotalItem() {
    //Olhar se é NF complementar
    let qtdeCalc = this.utilService.parseDecimal6(this.pedidoItem.quantidade)
    if (this.pedido.nfeFinalidade.id === 2) qtdeCalc = 1

    if (this.pedidoItem.tipoDesconto.id === 1) {

      const desconto = (((qtdeCalc *
        this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) *
        (this.utilService.parseDecimal(this.pedidoItem.valorDesconto) / 100)));

      let valorIcmsImp = 0.00;
      let valorPisCofinsImp = 0.00;


      if (!isNullOrUndefined(this.pedidoItem.cfop)) {
        if (this.pedidoItem.cfop.startsWith('3')) {
          valorIcmsImp = this.utilService.parseDecimal(this.pedidoItem.valorIcms);
          valorPisCofinsImp = this.utilService.parseDecimal(this.pedidoItem.valorPis)
            + this.utilService.parseDecimal(this.pedidoItem.valorCofins);
        }
      }

      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        //  exterior
        this.pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
            this.utilService.parseDecimal(desconto) +
            this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
            this.utilService.parseDecimal(this.pedidoItem.valorIcms) +
            this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
            this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(this.pedidoItem.valorCofins) +
            this.utilService.parseDecimal(this.pedidoItem.valorPis)
          );

      } else {

        this.pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
            this.utilService.parseDecimal(desconto) +
            this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
            this.utilService.parseDecimal(this.pedidoItem.valorIcmsSt) +
            this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
            this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
            this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
            this.utilService.parseDecimal(valorPisCofinsImp) +
            this.utilService.parseDecimal(valorIcmsImp)
          );
      }

      this.pedidoItem.valorTotalProduto =
        this.utilService.parseDecimalString(
          ((qtdeCalc *
            this.utilService.parseDecimal6(this.pedidoItem.valorUnit)))
        );

      if (this.pedidoItem.unidadeMedidaTributavel.id === this.pedidoItem.unidadeMedida.id) {
        this.pedidoItem.quantidadeTributavel = this.pedidoItem.quantidade;
        this.pedidoItem.valorUnitTributavel = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(this.pedidoItem.valorUnit));
        this.valorItemTributavel = this.utilService.parseDecimalString(this.utilService.parseDecimal6(this.pedidoItem.valorTotal));
      }

      this.pedido.valorDesconto = this.utilService.parseDecimalString(desconto);

    } else if (this.pedidoItem.tipoDesconto.id === 2) {

      this.pedidoItem.valorTotalProduto =
        this.utilService.parseDecimalString(
          ((qtdeCalc *
            this.utilService.parseDecimal6(this.pedidoItem.valorUnit)))
        );

      let valorIcmsImp = 0.00;
      let valorPisCofinsImp = 0.00;

      if (!isNullOrUndefined(this.pedidoItem.cfop)) {
        if (this.pedidoItem.cfop.startsWith('3')) {
          valorIcmsImp = this.utilService.parseDecimal(this.pedidoItem.valorIcms);
          valorPisCofinsImp = this.utilService.parseDecimal(this.pedidoItem.valorPis) + this.utilService.parseDecimal(this.pedidoItem.valorCofins);
        }
      }

      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        //  exterior
        this.pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
            this.utilService.parseDecimal(this.pedidoItem.valorDesconto) +
            this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
            this.utilService.parseDecimal(this.pedidoItem.valorIcms) +
            this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
            this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(this.pedidoItem.valorCofins) +
            this.utilService.parseDecimal(this.pedidoItem.valorPis)
          );

      } else {
        this.pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) -
            this.utilService.parseDecimal(this.pedidoItem.valorDesconto) +
            this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
            this.utilService.parseDecimal(this.pedidoItem.valorIcmsSt) +
            this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
            this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
            this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
            this.utilService.parseDecimal(valorPisCofinsImp) +
            this.utilService.parseDecimal(valorIcmsImp)
          );
      }

      if (this.pedidoItem.unidadeMedidaTributavel.id === this.pedidoItem.unidadeMedida.id) {
        this.pedidoItem.quantidadeTributavel = this.pedidoItem.quantidade;
        this.pedidoItem.valorUnitTributavel = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(this.pedidoItem.valorUnit));
        this.valorItemTributavel = this.utilService.parseDecimalString(this.utilService.parseDecimal6(this.pedidoItem.valorTotal));
      }

    } else {

      let valorIcmsImp = 0.00;
      let valorPisCofinsImp = 0.00;

      if (!isNullOrUndefined(this.pedidoItem.cfop)) {
        if (this.pedidoItem.cfop.startsWith('3')) {
          valorIcmsImp = this.utilService.parseDecimal(this.pedidoItem.valorIcms);
          valorPisCofinsImp = this.utilService.parseDecimal(this.pedidoItem.valorPis) + this.utilService.parseDecimal(this.pedidoItem.valorCofins);
        }
      }

      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        //  exterior
        this.pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
            this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
            this.utilService.parseDecimal(this.pedidoItem.valorIcms) +
            this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
            this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(this.pedidoItem.valorCofins) +
            this.utilService.parseDecimal(this.pedidoItem.valorPis)
          );

      } else {
        this.pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
            this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
            this.utilService.parseDecimal(this.pedidoItem.valorIcmsSt) +
            this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
            this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
            this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(this.pedidoItem.valorIpi) +
            this.utilService.parseDecimal(valorPisCofinsImp) +
            this.utilService.parseDecimal(valorIcmsImp)
          );
      }

      this.pedidoItem.valorTotalProduto =
        this.utilService.parseDecimalString(
          ((qtdeCalc *
            this.utilService.parseDecimal6(this.pedidoItem.valorUnit)))
        );

      if (this.pedidoItem.unidadeMedidaTributavel.id === this.pedidoItem.unidadeMedida.id) {
        this.pedidoItem.quantidadeTributavel = this.pedidoItem.quantidade;
        this.pedidoItem.valorUnitTributavel = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(this.pedidoItem.valorUnit));
        this.valorItemTributavel = this.utilService.parseDecimalString(this.utilService.parseDecimal6(this.pedidoItem.valorTotal));
      }
    }

    if (this.valorMva > 0) {

      this.pedidoItem.valorBcIcmsSt = this.utilService.parseMoneyString(this.utilService.parseDecimal(this.pedidoItem.valorTotal) + ((this.utilService.parseDecimal(this.pedidoItem.valorTotal) * this.utilService.parseDecimal(this.valorMva)) / 100));
      this.pedidoItem.valorTotal = this.pedidoItem.valorBcIcmsSt;

    }


    if (!isNullOrUndefined(this.pedidoItem.cfop)) {
      if ((this.excluiIcmsPisCofins || this.excluiIcmsPisCofinsCompra) && !this.pedidoItem.cfop.toString().startsWith('3')) {

        if (this.tipo != 0 && this.excluiIcmsPisCofins) {

          if (this.pedido.modNf.id !== 13 && this.pedido.modNf.id !== 17 && this.pedido.modNf.id !== 15) {


            if (!this.pedidoItem.operacaoRegra.utilizaBasePcXml) {
              this.pedidoItem.valorBcPis = this.utilService.parseDecimalString(
                (qtdeCalc *
                  this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
                this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
                this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
                this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
                this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) -
                this.utilService.parseDecimal(this.pedidoItem.valorIcms)
              );

              this.pedidoItem.valorBcCofins = this.utilService.parseDecimalString(
                (qtdeCalc *
                  this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
                this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
                this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
                this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
                this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) -
                this.utilService.parseDecimal(this.pedidoItem.valorIcms)
              );
            }


          }

          this.pedidoItem.valorPis = this.utilService.parseMoneyString(
            (this.utilService.parseDecimal(this.pedidoItem.valorBcPis) *
              this.utilService.parseDecimal(this.pedidoItem.perAliquotaPis)) / 100);
          this.pedidoItem.valorCofins = this.utilService.parseMoneyString(
            (this.utilService.parseDecimal(this.pedidoItem.valorBcCofins) *
              this.utilService.parseDecimal(this.pedidoItem.perAliquotaCofins)) / 100);
        } else {


          if (this.excluiIcmsPisCofinsCompra) {


            if (this.infoParticipante[0].regimeTributarioId === 1) {

              if (this.pedido.modNf.id !== 13 && this.pedido.modNf.id !== 17 && this.pedido.modNf.id !== 15) {

                if (!this.pedidoItem.operacaoRegra.utilizaBasePcXml) {
                  this.pedidoItem.valorBcPis = this.utilService.parseDecimalString(
                    (qtdeCalc *
                      this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
                    this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
                    this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
                    this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
                    this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport)
                  );

                  this.pedidoItem.valorBcCofins = this.utilService.parseDecimalString(
                    (qtdeCalc *
                      this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
                    this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
                    this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
                    this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
                    this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport)
                  );
                }


              }

              this.pedidoItem.valorPis = this.utilService.parseMoneyString(
                (this.utilService.parseDecimal(this.pedidoItem.valorBcPis) *
                  this.utilService.parseDecimal(this.pedidoItem.perAliquotaPis)) / 100);
              this.pedidoItem.valorCofins = this.utilService.parseMoneyString(
                (this.utilService.parseDecimal(this.pedidoItem.valorBcCofins) *
                  this.utilService.parseDecimal(this.pedidoItem.perAliquotaCofins)) / 100);
            } else {

              if (this.pedido.modNf.id !== 13 && this.pedido.modNf.id !== 17 && this.pedido.modNf.id !== 15) {

                if (!this.pedidoItem.operacaoRegra.utilizaBasePcXml) {

                  this.pedidoItem.valorBcPis = this.utilService.parseDecimalString(
                    (qtdeCalc *
                      this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
                    this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
                    this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
                    this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
                    this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) -
                    this.utilService.parseDecimal(this.pedidoItem.valorIcms)
                  );

                  this.pedidoItem.valorBcCofins = this.utilService.parseDecimalString(
                    (qtdeCalc *
                      this.utilService.parseDecimal6(this.pedidoItem.valorUnit)) +
                    this.utilService.parseDecimal(this.pedidoItem.valorFrete) +
                    this.utilService.parseDecimal(this.pedidoItem.valorSeguro) +
                    this.utilService.parseDecimal(this.pedidoItem.valorOutro) +
                    this.utilService.parseDecimal(this.pedidoItem.valorImpostoImport) -
                    this.utilService.parseDecimal(this.pedidoItem.valorIcms)
                  );
                }


              }

              this.pedidoItem.valorPis = this.utilService.parseMoneyString(
                (this.utilService.parseDecimal(this.pedidoItem.valorBcPis) *
                  this.utilService.parseDecimal(this.pedidoItem.perAliquotaPis)) / 100);
              this.pedidoItem.valorCofins = this.utilService.parseMoneyString(
                (this.utilService.parseDecimal(this.pedidoItem.valorBcCofins) *
                  this.utilService.parseDecimal(this.pedidoItem.perAliquotaCofins)) / 100);
            }
          }
        }

        if (!isNullOrUndefined(this.pedidoItem.cstPis)) {
          if (this.pedidoItem.cstPis.id === 8 || this.pedidoItem.cstPis.id === 10 || this.pedidoItem.cstPis.id === 26 || this.pedidoItem.cstPis.id === 27
            || this.pedidoItem.cstPis.id === 28 || this.pedidoItem.cstPis.id === 29 || this.pedidoItem.cstPis.id === 30 || this.pedidoItem.cstPis.id === 31) {

            this.pedidoItem.valorBcPis = 0.00;
            this.pedidoItem.perAliquotaPis = 0.00;
            this.pedidoItem.valorPis = 0.00;

            this.pedidoItem.valorBcCofins = 0.00;
            this.pedidoItem.perAliquotaCofins = 0.00;
            this.pedidoItem.valorCofins = 0.00;
          }
        }

      }
    }

    if (!this.clienteContribuinteIcms && !this.mesmaUf && this.pedido.pedidoTipo.id === 1) {


      this.pedidoItem.aliquotaInternaUfTermino = this.aliquotaInternaPart;
      this.pedidoItem.aliquotaInterestadual = this.pedidoItem.aliquotaIcms;
      this.pedidoItem.percentualPartilhaUfTermino = this.utilService.parseDecimal(100);
      this.pedidoItem.valorIcmsPartilhaUfTermino = this.utilService.parseDecimal(this.utilService.parseDecimal(((this.utilService.parseDecimal(this.pedidoItem.valorBcIcms) * this.utilService.parseDecimal(this.pedidoItem.aliquotaInternaUfTermino)) / 100)) - this.utilService.parseDecimal(((this.utilService.parseDecimal(this.pedidoItem.valorBcIcms) * this.utilService.parseDecimal(this.pedidoItem.aliquotaInterestadual)) / 100)));


      if (this.infoParticipante[0].uf == 'RJ') {

        this.pedidoItem.valorBCIcmsFcpUfDest = this.pedidoItem.valorBcIcms;
        this.pedidoItem.aliquotaInternaUfTermino = this.aliquotaInternaPart - 2;
        this.pedidoItem.aliquotaInterestadual = this.pedidoItem.aliquotaIcms;
        this.pedidoItem.percBaseCalculoIcmsFcpUfDest = 100;
        this.pedidoItem.aliquotaFcpUfTermino = 2;
        this.pedidoItem.percentualPartilhaUfTermino = this.utilService.parseDecimal(100);
        this.pedidoItem.valorIcmsPartilhaUfTermino = this.utilService.parseDecimal(this.utilService.parseDecimal(((this.utilService.parseDecimal(this.pedidoItem.valorBcIcms) * this.utilService.parseDecimal(this.pedidoItem.aliquotaInternaUfTermino)) / 100)) - this.utilService.parseDecimal(((this.utilService.parseDecimal(this.pedidoItem.valorBcIcms) * this.utilService.parseDecimal(this.pedidoItem.aliquotaInterestadual)) / 100)));
        this.pedidoItem.valorFcpUfTermino = this.utilService.parseDecimal(this.utilService.parseDecimal(this.pedidoItem.valorBcIcms) * this.utilService.parseDecimal(this.pedidoItem.aliquotaFcpUfTermino) / 100);
      }




      if (this.mesmaUf && this.pedido.modNf.id !== 22 && this.pedido.modNf.id !== 23) {
        this.pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(this.aliquotaInterna);
        this.pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(this.aliquotaInterna);
        this.pedidoItem.valorIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(this.pedidoItem.valorBcIcms) *
          this.utilService.parseDecimal(this.pedidoItem.aliquotaIcms)) / 100);
      }


    } else {
      this.pedidoItem.aliquotaInternaUfTermino = 0.00;
      this.pedidoItem.aliquotaInterestadual = 0.00;
      this.pedidoItem.percentualPartilhaUfTermino = 0.00;
      this.pedidoItem.valorIcmsPartilhaUfTermino = 0.00;
      if (this.mesmaUf && this.pedido.modNf.id !== 22 && this.pedido.modNf.id !== 23) {
        this.pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(this.aliquotaInterna);
        this.pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(this.aliquotaInterna);
        this.pedidoItem.valorIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(this.pedidoItem.valorBcIcms) *
          this.utilService.parseDecimal(this.pedidoItem.aliquotaIcms)) / 100);
      }
    }

    this.calcularValorDifalItem();
    this.getTaxBaseAmounts();
  }

  validaCampoNumero(value: any, nomeCampo: string) {
    if (!Util.isNumberOnly(value)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: nomeCampo + ' deverá conter somente números!' })
    }
  }

  incluirItemExportacao() {

    if (isNullOrUndefined(this.pedidoExportacao.qtdItemExport) && isNullOrUndefined(this.pedidoExportacao.numeroRegistro)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Para inserir informação de exportação, preencha as informações!' })
      return;
    }

    if (!isNullOrUndefined(this.pedidoExportacao.numeroRegistro)) {
      if (this.utilService.parseDecimal(this.pedidoExportacao.numeroRegistro.length) !== 12) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Número do Registro de Exportação deverá conter 12 caracteres!' })
        return;
      }
    }

    if (isNullOrUndefined(this.pedidoExportacao.qtdItemExport)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Insira QTD. de item Exportado para Prosseguir!' })
      return;
    }
    if (isNullOrUndefined(this.pedidoExportacao.chaveAcessoNfe)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Insira Chave de Acesso da NF-E Recebida para Prosseguir!' })
      return;
    } else {
      if (this.utilService.parseDecimal(this.pedidoExportacao.chaveAcessoNfe.length) !== 44) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Chave de Acesso deverá conter 44 caracteres!' })
        return;
      }
    }
    this.salvarPedidoExportacao();
  }


  incluirAdicaoImportacao() {
    if (this.importacaoAdicao.id === null || this.importacaoAdicao.id === undefined) {
      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        if (isNullOrUndefined(this.incluirItemExportacao) || (isNullOrUndefined(this.importacaoAdicao.numero))) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Preencha Número, para Prosseguir!' })
          return;
        }
        if (isNullOrUndefined(this.incluirItemExportacao) || (isNullOrUndefined(this.importacaoAdicao.codigoFabricante))) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Preencha Cód.Fabricante, para Prosseguir e Adicione' })
          return;
        }
      }

      this.importacaoAdicao.id = this.contadorAdicao;
      this.pedidoItem.pedidoItensImportAdicao.push(this.importacaoAdicao);
      this.contadorAdicao++;
    } else {
      const index = this.pedidoItem.pedidoItensImportAdicao.findIndex(c => c.id === this.importacaoAdicao.id);
      this.pedidoItem.pedidoItensImportAdicao.splice(index, 1, this.importacaoAdicao);
    }
    this.importacaoAdicao = new ImportacaoAdicao();
  }

  onRowSelectAdicaoImportacao(event: any) {
    this.importacaoAdicao = new ImportacaoAdicao(event.data);
  }

  excluirAdicaoImportacao(id: number = null) {
    if (confirm('Deseja excluir esta Adição?')) {
      const index = this.pedidoItem.pedidoItensImportAdicao.findIndex(c => c.id === id);
      this.pedidoItem.pedidoItensImportAdicao.splice(index, 1);
    }
  }

  comboTipoFrete() {
    this.opcoesTipoFrete = [];
    this.opcoesTipoFrete.push({ label: 'Selecione', value: null });
    this.skeletonConfig.startLoad()
    this.httpService.get('/tipofrete?$select=id,codigo,descricao&$orderby=codigo')// , destination: this.ufs },
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesTipoFrete.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboTipoFrete()),
        () => this.skeletonConfig.endLoad());
  }

  private loadTransportador() {

    this.skeletonConfig.startLoad()
    this.httpService.get('/custom/cadastro/buscar-infos-transportador(' + (this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id) + ')')
      .subscribe(result => {
        this.opcoesTransportador = [{ label: 'Selecione', value: null }];
        result.forEach((item) => {
          this.opcoesTransportador.push({ label: item.nome + " - " + item.cnpj, value: item.id });
        });
      },
        error => this.httpService.handleError(error, () => this.carregarInformacoesComuns()),
        () => this.skeletonConfig.endLoad());
  }

  atualizarTranspPedido() {
    const freteTp = ((this.pedido.tipoFrete.id !== null && this.pedido.tipoFrete.id !== 999) ? this.pedido.tipoFrete.id : null);
    const transp = ((this.pedido.transportador.id !== null && this.pedido.transportador.id !== 0 && freteTp !== 6) ?
      this.pedido.transportador.id : null);
    const placaUf = ((this.pedido.ufPlaca.id !== null && this.pedido.ufPlaca.id !== 999 && freteTp !== 6) ?
      this.pedido.ufPlaca.id : null);

    if (freteTp === 6) {
      this.pedido.transportador = new Participante();
      this.pedido.transportador.id = null;
      this.pedido.codigoAntt = null;
      this.pedido.placaVeiculo = null;
      this.pedido.qVol = null;
      this.pedido.especieVol = null;
      this.pedido.marcaVol = null;
      this.pedido.pesoB = null;
      this.pedido.pesoL = null;
      this.pedido.numeracaoVol = null;
    }

    let validaCampos: boolean;
    validaCampos = true;

    if ((this.pedido.pedidoTipo.id === 3) || (this.pedido.pedidoTipo.id === 1 && !this.pedidoExterior)) {
      if (freteTp === null || freteTp === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Modalidade de Frete Deve ser Informado.' })
        validaCampos = false;
      }
      if (freteTp === 1 || freteTp === 2 || freteTp === 3) {
        if ((transp === null || transp === undefined) && ((this.pedido.pedidoTipo.id === 3 && !this.pedidoExterior) || (this.pedido.pedidoTipo.id === 3 && this.pedidoExterior) || (this.pedido.pedidoTipo.id === 1 && !this.pedidoExterior))) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Transportador Deve ser Informado.' })
          validaCampos = false;
        }
      }
      if (freteTp !== 6) {
        if (this.pedido.qVol === null || (!this.pedido.qVol)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Quantidade de Volumes não Pode ser Vazio.' })
          validaCampos = false;
        } else if (this.pedido.especieVol === null || (!this.pedido.especieVol)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Espécie não Pode ser Vazia. Ex.: CX' })
          validaCampos = false;
        } else if (this.pedido.pesoB === null || (!this.pedido.pesoB)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Peso Bruto não Pode ser Vazio.' })
          validaCampos = false;
        } else if (this.pedido.pesoL === null || (!this.pedido.pesoL)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Peso Líquido não Pode ser Vazio.' })
          validaCampos = false;
        } else if (this.pedido.numeracaoVol === null || (!this.pedido.numeracaoVol)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Numeração Deverá ser Informada!' })
          validaCampos = false;
        } else {
          this.dadosTransportador(transp);
        }
      }
    }

    if (this.pedido.pedidoTipo.id === 1 && this.pedidoExterior) {
      if (this.pedido.embarqueUf.id === null || this.pedido.embarqueUf.id === undefined || this.pedido.embarqueUf.id === 999) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A Uf Embarque Deverá ser Informada!' })
        validaCampos = false;
      }

      else if (this.pedido.embarqueLocal === null || (!this.pedido.embarqueLocal)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Local de Embarque Deverá ser Informado!' })
        validaCampos = false;
      }

      else if (this.pedido.embarqueDescricao === null || (!this.pedido.embarqueDescricao)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Local de Despacho Deverá ser Informado!' })
        validaCampos = false;
      }
    }

    if (validaCampos) {
      this.exbirModalTransportador = false;
    }
  }

  /**
    *  povoa as informações do item selecionado
    * @param args
    */
  dadosTransportador(transpId: number = null) {
    this.httpService.wait();
    this.httpService.get('/participante?$select=id,nome,fantasia,cpf,cnpj,participanteEmpresas/codigo,participanteEmpresas/fantasia' +
      '&$filter=id eq ' + transpId + ' and participanteEmpresas/participanteTipo/id eq 4 &$orderby = nome')
      .subscribe(ref => {
        if ((ref.value.length > 0) && (ref.value[0].participanteEmpresas[0].fantasia !== null)) {
          this.pedido.transportador.nome = ref.value[0].participanteEmpresas[0].codigo + ' - ' + ref.value[0].participanteEmpresas[0].fantasia;
        } else if (ref.value.length == 0) {
          this.pedido.transportador.nome = undefined;
        } else {
          this.pedido.transportador.nome = ref.value[0].participanteEmpresas[0].codigo + ' - ' + ref.value[0].nome;
        }
      }, error => this.httpService.handleError(error, () => this.dadosTransportador(transpId)), () => this.httpService.done());
  }

  validacaoExportacao() {

    if (!isNullOrUndefined(this.pedidoExportacao.numeroAto)) {
      if (parseInt(this.pedidoExportacao.numeroAto.length) === 9) {

        this.salvarPedidoExportacao();
        return;
      }
      if (parseInt(this.pedidoExportacao.numeroAto.length) === 11) {
        this.salvarPedidoExportacao();
        return;
      } else {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Numero do Ato deverá conter 9 ou 11 caracteres!' })
        return;
      }
    } else {
      this.incluirItemExportacao();
    }
  }

  salvarPedidoExportacao() {
    if (this.pedidoExportacao.id === null || this.pedidoExportacao.id === undefined) {
      this.pedidoExportacao.id = this.contadorExport;
      this.pedidoItem.pedidoItensExportacao.push(this.pedidoExportacao);
      this.contadorExport++;
    }
    else {
      const index = this.pedidoItem.pedidoItensExportacao.findIndex(c => c.id === this.pedidoExportacao.id);
      this.pedidoItem.pedidoItensExportacao.splice(index, 1, this.pedidoExportacao);
    }
    this.pedidoExportacao = new PedidoExportacao();
  }

  onRowSelectPedidoExportacao(event: any) {
    this.pedidoExportacao = new PedidoExportacao(event.data);
  }

  excluirItemExportacao(id: number = null) {
    if (confirm('Deseja excluir esta Adição?')) {
      const index = this.pedidoItem.pedidoItensExportacao.findIndex(c => c.id === id);
      this.pedidoItem.pedidoItensExportacao.splice(index, 1);
    }
  }

  replicarRateio() {
    if (confirm('Este Rateio será replicado para todos os outros itens, deseja continuar?')) {
      this.pedido.pedidoItem.forEach(itens => {

        if (itens.id !== this.pedidoItem.id) {

          itens.pedidoItensRateio = new Array();
          this.pedidoItem.pedidoItensRateio.forEach(element => {

            let req = new PedidoItemRateio();
            req.cadCcus = new CadCcus();
            req.cadCcus.descrCcus = element.cadCcus.descrCcus;
            req.cadCcus.codCcus = element.cadCcus.codCcus;
            req.cadCcus.id = element.cadCcus.id;
            req.cadProj = new CadProj();

            if (element.cadProj) {
              req.cadProj.id = element.cadProj.id;
              req.cadProj.codProj = element.cadProj.codProj;
              req.cadProj.descrProj = element.cadProj.descrProj;
            }

            this.modalCentroCusto = false;
            req.porcentagem = element.porcentagem;
            itens.pedidoItensRateio.push(req);
          });

          this.visualizacaoContabil = new Array();
        }
      });
    }
  }

  removerRateioCentroCusto(value: any) {
    if (confirm('Deseja excluir este rateio?')) {
      const index = this.pedidoItem.pedidoItensRateio.findIndex(it => it.id === value);
      this.pedidoItem.pedidoItensRateio.splice(index, 1);
      this.calculaRateioDisponivelCentroCusto();
    }

    this.visualizacaoContabil = new Array();
  }

  calculaRateioDisponivelCentroCusto() {
    let rateio: number;
    rateio = 0.00;
    this.rateioCentroCusto = 100;
    if (this.pedidoItem) {

      this.pedidoItem.pedidoItensRateio.forEach(element => {
        element.porcentagem = this.utilService.parseMoney(element.porcentagem);
        rateio = this.utilService.parseMoney(rateio) + this.utilService.parseMoney(element.porcentagem);
        this.rateioCentroCusto = 100 - this.utilService.parseMoney(rateio);

      });

      if (this.rateioCentroCusto === 0) {
        this.visualizacaoContabil = new Array();
        this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);
      }

    }
  }


  adicionarRateio() {
    let itemRateio = new PedidoItemRateio();
    itemRateio.cadCcus = new CadCcus();
    itemRateio.cadProj = new CadProj();

    itemRateio.id = this.pedidoItem.pedidoItensRateio.length;
    this.pedidoItem.pedidoItensRateio.push(itemRateio);

    this.visualizacaoContabil = new Array();

  }

  // solicitarAprovacao() {

  //   if (confirm('Deseja solicitar a aprovação do(s) pedido(s)?')) {

  //     const listasEnviar = {
  //       pedido: this.pedido.id,
  //       usuario: this.sessionService.loggedUser.id
  //     };

  //     this.httpService.wait();
  //     this.httpService.post('/custom/pedido/solicitar-aprovacao-pedido', listasEnviar)
  //       .subscribe(result => {

  //         if (result.error) {
  //           this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
  //         } else {
  //           this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })
  //         }
  //         this.inicializar();
  //       },
  //         error => this.httpService.handleError(error, () => this.solicitarAprovacao()),
  //         () => this.httpService.done());
  //   }
  // }

  modalStatusPedidosLote() {
    // status possíveis
    this.modalStatusPedidoLote = true;
    this.cloneStatusPedido = null;
    this.cloneDataEmissao = null;
    this.pedido.pedidoTipo = new PedidoTipo();
  }

  aprovarPedidosLote() {
    if (confirm('Deseja alterar o status destes pedidos?')) {
      let pedidosStatus = '';

      this.pedidosSelecionados.forEach(pedidoStatus => {
        pedidosStatus += pedidoStatus.id + ',';
      });

      const status = {
        pedidoId: pedidosStatus.substring(0, (pedidosStatus.length - 1)),
        statusId: this.cloneStatusPedido,
        usuarioId: this.sessionService.loggedUser.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/pedido/alterar-status-pedido-id', status)
        .subscribe(result => {
          result.forEach(el => {
            if (el.error) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: el.message })
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: el.message })
              this.buscarPedidos();
            }
            this.modalStatusPedidoLote = false;
          });
        },
          error => this.httpService.handleError(error, () => this.aprovarPedidosLote()),
          () => this.httpService.done());
    }
  }

  clonarPedido() {

    if (this.validacaoClonarPedido()) {
      return;
    }

    this.modalClonePedido = false;

    const clone = {
      pedidoId: this.pedidosSelecionados[0].id,
      statusId: this.cloneStatusPedido,
      participante: this.pedido.participante.id,
      dtEmissao: this.pedido.dtEmissao,
      dtEntrega: this.pedido.dtEntrega,
      dtSaidaEntrada: this.pedido.dtFaturamentoAutomatico,
      empresa: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id
    };
    this.httpService.wait();
    this.httpService.post('/custom/pedido/clonar-pedido-produto', clone)
      .subscribe(result => {
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result[0].message })

        result.forEach(row => {
          this.LogManagement.registerLog(
            [3, 4, 5].includes(this.pedidosSelecionados[0].pedido_tipo_id) ? LogModule.PURCHASES : LogModule.SALES,
            [3, 4, 5].includes(this.pedidosSelecionados[0].pedido_tipo_id) ? LogScreen.PRODUCT_PURCHASES_ORDER : LogScreen.PRODUCT_SALES_ORDER,
            '/custom/pedido/clonar-pedido-produto',
            LogAction.CREATE,
            row.id,
            row.codigo,
            `O pedido ${row.codigo} foi criado a partir do pedido ${this.pedidosSelecionados[0].codigo}`,
            clone
          )
        })


        this.buscarPedidos();
      },
        error => this.httpService.handleError(error, () => this.clonarPedido()),
        () => this.httpService.done());
  }

  emitirNfEletronica() {

    if (!this.pedidoMisto) {

      for (let item of this.pedido.pedidoItem) {

        if ((!item.cstIcmsTabelaB.id || item.cstIcmsTabelaB.id === null || item.cstIcmsTabelaB.id === undefined) && (item.csosn === null || item.csosn === undefined)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe a Situação Tributária do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
          return;
        }
        if (!item.cstIcmsTabelaA.id || item.cstIcmsTabelaA.id === null || item.cstIcmsTabelaA.id === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe a Origem do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
          return;
        }
        if ((!item.modBcIcms || item.modBcIcms === undefined || item.modBcIcms === null) && (item.cstIcmsTabelaB.id === 2 || item.cstIcmsTabelaB.id === 3 || item.cstIcmsTabelaB.id === 1) && ((this.pedido.pedidoTipo.id === 3 && this.pedidoExterior) || (this.pedido.pedidoTipo.id === 1 && !this.pedidoExterior))) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe o Mod. Deter. ICMS do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
          return;
        }
        if (!item.codigoIpi || item.codigoIpi === undefined || item.codigoIpi === null) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe o Código de Enquadramento do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
          return;
        }
        if (!item.cstIpi.id || item.cstIpi.id === undefined || item.cstIpi.id === null) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe a Situação Tributária - IPI do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
          return;
        }
        if (!item.cstPis.id || item.cstPis.id === undefined || item.cstPis.id === null) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe a Situação Tributária - PIS do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
          return;
        }
        if (!item.cstCofins.id || item.cstCofins.id === undefined || item.cstCofins.id === null) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Informe a Situação Tributária - COFINS do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
          return;
        }
        //  Valida em caso de compra exportação
        if (((this.pedido.pedidoTipo.id === 3) && (this.pedido.pedidoStatus.id === 7 && this.pedidoExterior)) || (this.pedido.pedidoTipo.id === 1 && this.pedido.pedidoStatus.id === 4 && this.pedidoExterior) || (this.pedido.pedidoTipo.id === 1 && this.pedido.pedidoStatus.id === 4 && !this.pedidoExterior)) {
          if ((item.cfop === null || item.cfop === undefined)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Adicione CFOP do Item: ' + item.item.codigo + 'para Prosseguir com a Emissão!' })
            return;
          }
        }
        if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
          if (!item.numeroDi || item.numeroDi === undefined || item.numeroDi === null) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Informar o Número DI/DSI/DA/DRI-E do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (!item.codigoExportador || item.codigoExportador === undefined || item.codigoExportador === null) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Informar o Cód. Exportador do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (!item.dataRegistroImport || item.dataRegistroImport === undefined || item.dataRegistroImport === null) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Informar a Data Registro do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (!item.ufDesembaraco.id || item.ufDesembaraco.id === undefined || item.ufDesembaraco.id === null) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Selecionar Informar a UF Aduaneira do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (!item.localDesembaraco || item.localDesembaraco === undefined || item.localDesembaraco === null) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Informar o Local Aduaneiro do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (!item.transporteTipo.id || item.transporteTipo.id === undefined || item.transporteTipo.id === null) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Selecionar a Via Trasp Aduaneira do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (!item.dataDesembaraco || item.dataDesembaraco === undefined || item.dataDesembaraco === null) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Selecionar a Data Aduaneira do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (item.tipoIntermediacao.id === null || item.tipoIntermediacao.id === undefined) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Selecionar a Forma de Intermediação Aduaneira do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (item.pedidoItensImportAdicao.length < 1) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Adicionar o Número e o Cód Fabricante da Adição do Item: ' + item.item.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
        }
      }
    }
    // Valida Infos Iniciais de Emissão Venda e Compra Exportação - Para não passar!
    if (this.pedidoExterior) {
      if (!this.pedido.moeda.id || this.pedido.moeda.id === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Selecione uma Moeda para Prosseguir com a Emissão!' })
        return;
      } if (!this.pedido.txMoeda || this.pedido.txMoeda === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Informar a Taxa da Moeda para Prosseguir com a Emissão!' })
        return;
      } if (!this.pedido.numeroInvoice || this.pedido.numeroInvoice === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Informar o Número da Invoice para Prosseguir com a Emissão!' })
        return;
      } if ((this.pedido.numeroInvoice !== null || this.pedido.numeroInvoice !== undefined) || this.pedido.numeroInvoice === '') {
        if (!(/^[A-Za-z0-9]*\d+[A-Za-z0-9]*$/.test(this.pedido.numeroInvoice))) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'O Número Invoice não pode conter caracteres especiais!' })
          return;
        }
      } if (!this.pedido.dataInvoice || this.pedido.dataInvoice === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Selecionar a Data da Invoice para Prosseguir com a Emissão!' })
        return;
      }
      if (this.pedido.moeda.id != 1 && this.pedido.converterTaxa === false) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É Necessário Converter a Taxa para Prosseguir com a Emissão!' })
        return;
      }
    }
    if (this.pedidoMisto && this.nfsEmitida === 0) {
      if (confirm('A NF de Produto e de Serviço serão emitidas, deseja continuar?')) {

        this.httpService.wait();
        this.httpService.post('/custom/enotas/emitir-nfse-misto(' + this.pedido.id + ',' + this.flowUses + ')')
          .subscribe(result => {
            if (result.error === 'false') {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })
              this.buscarPedidos();
            } else {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
            }
          }, error => this.httpService.handleError(error, () => this.emitirNfEletronica()),
            () => this.httpService.done());
      }
    } else {
      if (confirm('Deseja realmente emitir esta NFe?')) {

        this.httpService.wait();
        this.httpService.get('/custom/nfe/envia-nfe(' + this.pedido.id + ')')
          .subscribe(result => {
            if (result.error === 'true' || result.error) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
            } else if (result.error === 'false') {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })
            } else {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
            }
          }, error => this.httpService.handleError(error, () => this.emitirNfEletronica()),
            () => this.httpService.done());
      }
    }
  }

  emitirNfComunic() {

    if (confirm('Deseja realmente emitir esta NFe?')) {

      this.httpService.wait();
      this.httpService.get('/custom/nfe/envia-nfe-comunic(' + this.pedido.id + ')')
        .subscribe(result => {
          if (result.error === 'true') {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
          } else if (result.error === 'false') {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })
          } else {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
          }
        }, error => this.httpService.handleError(error, () => this.emitirNfComunic()),
          () => this.httpService.done());
    }

  }

  calculaValorIpi() {
    this.valorIpi = 0.00;
    this.valorIpi = (this.utilService.parseDecimal(this.pedidoItem.valorBcIpi) *
      this.utilService.parseDecimal(this.pedidoItem.aliquotaIpi)) / 100;
    this.pedidoItem.valorIpi = this.utilService.parseMoneyString(this.valorIpi);
  }

  calculaValorIcms() {
    this.pedidoItem.valorIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(this.pedidoItem.valorBcIcms) *
      this.utilService.parseDecimal(this.pedidoItem.aliquotaIcms)) / 100);
    this.calculaValorTotalItem();
  }

  calculaValorIcmsSt() {
    this.pedidoItem.valorIcmsSt = this.utilService.parseMoneyString(
      (this.utilService.parseDecimal(this.pedidoItem.valorBcIcmsSt) *
        this.utilService.parseDecimal(this.pedidoItem.aliquotaIcmsSt)) / 100);

    if (this.pedidoItem.modBcIcmsSt == 5 || this.pedidoItem.modBcIcmsSt == 7) {
      this.pedidoItem.valorIcmsSt = this.utilService.parseMoneyString(this.utilService.parseDecimal(this.pedidoItem.valorIcmsSt) - this.utilService.parseDecimal(this.pedidoItem.valorIcms));
    }

  }

  calculaValorPis() {




    this.pedidoItem.valorPis = this.utilService.parseMoneyString(
      (this.utilService.parseDecimal(this.pedidoItem.valorBcPis) *
        this.utilService.parseDecimal(this.pedidoItem.perAliquotaPis)) / 100);
  }

  calculaValorCofins() {
    this.pedidoItem.valorCofins = this.utilService.parseMoneyString(
      (this.utilService.parseDecimal(this.pedidoItem.valorBcCofins) *
        this.utilService.parseDecimal(this.pedidoItem.perAliquotaCofins)) / 100);
  }

  comboFinalidade() {

    this.skeletonConfig.startLoad();
    this.httpService.get('/nfefinalidade?$select=id,descricao&$orderby=id')
      .subscribe(ref => {
        this.opcoesNfeFinalidade = [];
        ref.value.forEach(element => {
          this.opcoesNfeFinalidade.push({ label: element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboFinalidade()),
        () => this.skeletonConfig.endLoad());
  }

  comboPresencial() {

    this.skeletonConfig.startLoad();
    this.httpService.get('/nfepresencial?$select=id,descricao&$orderby=id')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesNfePresencial.push({ label: element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboPresencial()),
        () => this.skeletonConfig.endLoad());
  }

  comboCfop() {

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-cfop')
      .subscribe(cfop => {
        this.opcoesCfop = [];

        cfop.forEach(element => {
          if (this.pedido.pedidoTipo.id === 3 && (element.cfop.startsWith('1') || element.cfop.startsWith('2') || element.cfop.startsWith('3'))) {
            this.opcoesCfop.push({ label: element.cfop, value: element.cfop });
          } else if (this.pedido.pedidoTipo.id === 1 && (element.cfop.startsWith('5') || element.cfop.startsWith('6') || element.cfop.startsWith('7'))) {
            this.opcoesCfop.push({ label: element.cfop, value: element.cfop });
          }
        });

      },
        error => this.httpService.handleError(error, () => this.comboCfop()),
        () => this.httpService.done());

  }

  comboAlmoxarifado() {

    this.httpService.get('/custom/cadastro/get-almoxarifado(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(rst => {
        this.almoxarifados = rst;
      },
        error => this.httpService.handleError(error, () => this.comboAlmoxarifado()),
        () => this.httpService.done());

  }

  referenciarNfe() {
    this.modalReferenciarNfe = true
    this.listarXml = true
    this.vincularItens = false
    this.nfsParticipantes = new Array()
    if (this.opcoesItens.length === 0) this.retornaIdOperacaoRegra(0, 1, true)

    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      cnpj_part: this.infoParticipante[0].cnpj ? this.infoParticipante[0].cnpj : this.infoParticipante[0].cpf,
      finalidade: this.pedido.nfeFinalidade.id
    }

    this.skeletonConfigXml.startLoad()
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'pedido/listar-xml-pendentes', infos, this.httpOptions)
      .subscribe(res => {
        res["data"].forEach(row => this.nfsParticipantes.push(row))
        this.skeletonConfigXml.endLoad()
      },
        error => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar as NFS-e, contate o suporte: ' + error.message })
          this.skeletonConfigXml.endLoad()
        }
      )
  }

  //SIMON LALLEMENT: não usado
  // calcularSaldo(event: any) {
  //   event.saldoFinal = this.utilService.parseDecimal6(event.saldo) - this.utilService.parseDecimal6(event.utilizar);
  //   if (event.saldoFinal < 0) {
  //     event.saldoFinal = this.utilService.parseDecimal6(event.saldo);
  //     this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Valor a Utilizar deve ser menor ou igual ao saldo!' })
  //     event.utilizar = this.utilService.parseDecimal6(event.saldo);
  //     return;
  //   }
  // }

  //SIMON LALLEMENT: não usado
  // onLinkClick(event: any) {



  //   let existeNotaSelecionada: boolean;
  //   existeNotaSelecionada = false;

  //   if (event.index === 1) {
  //     this.itensNfsParticipantes = new Array();

  //     this.nfsParticipantes.forEach(obj => {
  //       if (obj.selected) {
  //         existeNotaSelecionada = true;
  //       }
  //     });

  //     if (!existeNotaSelecionada) {
  //       this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É necessário selecionar pelo menos uma Nota Fiscal para referenciar os itens' })
  //       return;
  //     }

  //     if (this.pedido.nfeFinalidade.id === 1 || this.pedido.nfeFinalidade.id === 4 || this.pedido.nfeFinalidade.id === 5) {
  //       this.pedido.nfeReferenciada = '';
  //       this.pedido.dadosAdicionais = '';

  //       this.itensNfsParticipantes = new Array();

  //       this.nfsParticipantes.forEach(obj => {
  //         if (obj.selected) {
  //           if (this.pedido.nfeReferenciada === '') {
  //             if (this.pedido.nfeFinalidade.id === 5) {
  //               this.pedido.dadosAdicionais = 'Retorno da sua NF: ' + obj.numDoc + ' CHAVE: ' + obj.chvNfe;
  //             } else if (this.pedido.nfeFinalidade.id === 4) {
  //               this.pedido.dadosAdicionais = 'Devolução da sua NF: ' + obj.numDoc + ' CHAVE: ' + obj.chvNfe;
  //             } else if (this.pedido.nfeFinalidade.id === 1) {
  //               this.pedido.dadosAdicionais = 'NF-E normal: ' + obj.numDoc + ' CHAVE: ' + obj.chvNfe;
  //             } else {
  //               this.pedido.dadosAdicionais = '';
  //             }
  //             this.pedido.nfeReferenciada = obj.chvNfe;
  //           } else {
  //             if (this.pedido.nfeFinalidade.id === 5) {
  //               this.pedido.dadosAdicionais = this.pedido.dadosAdicionais + ', NF: ' + obj.numDoc + ' CHAVE: ' + obj.chvNfe;
  //             } else if (this.pedido.nfeFinalidade.id === 4) {
  //               this.pedido.dadosAdicionais = this.pedido.dadosAdicionais + ', NF: ' + obj.numDoc + ' CHAVE: ' + obj.chvNfe;
  //             } else if (this.pedido.nfeFinalidade.id === 1) {
  //               this.pedido.dadosAdicionais = this.pedido.dadosAdicionais + ', NF: ' + obj.numDoc + ' CHAVE: ' + obj.chvNfe;
  //             } else {
  //               this.pedido.dadosAdicionais = '';
  //             }
  //             this.pedido.nfeReferenciada = this.pedido.nfeReferenciada + ', ' + obj.chvNfe;
  //           }
  //         }

  //         if (this.pedido.nfeFinalidade.id === 5 && this.pedido.nfeReferenciada === '') {
  //           this.pedido.dadosAdicionais = '';
  //         } else if (this.pedido.nfeFinalidade.id === 4 && this.pedido.nfeReferenciada === '') {
  //           this.pedido.dadosAdicionais = '';
  //         } else if (this.pedido.nfeFinalidade.id === 1 && this.pedido.nfeReferenciada === '') {
  //           this.pedido.dadosAdicionais = '';
  //         }
  //       });

  //       const map = {
  //         referenciadas: this.pedido.nfeReferenciada
  //       };

  //       this.httpService.wait();
  //       this.httpService.post('/custom/pedido/buscar-itens-nfs-referenciadas', map)
  //         .subscribe(rst => {
  //           this.itensNfsParticipantes = new Array();

  //           rst.forEach(obj => {
  //             obj.operacaoRegra = new OperacaoRegra();
  //             obj.operacaoRegra.permiteAlteracaoAlmoxarifado = false;
  //             obj.operacaoRegra.almoxarifadoOrigem = new EmpresaAlmoxarifado();
  //             obj.operacaoRegra.almoxarifadoDestino = new EmpresaAlmoxarifado();

  //             let exibirRelacItem: string;

  //             if (obj.possuiItemRelac === true) {
  //               obj.exibirRelacItem = "SIM"
  //             } else {
  //               obj.exibirRelacItem = "NÃO"
  //             }

  //             this.itensNfsParticipantes.push(obj);
  //           });
  //         },
  //           error => this.httpService.handleError(error, () => this.onLinkClick(event)),
  //           () => this.httpService.done()
  //         );
  //     }
  //   }
  // }

  //SIMON LALLEMENT: Não usado
  // salvarReferenciaNfe() {
  //   let itemOk: boolean = true;

  //   this.itensNfsParticipantes.forEach(obj => {

  //     if ((this.pedido.nfeFinalidade.id === 1) && (!obj.possuiItemRelac)) {
  //       this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Item nota não está vinculado com item participante!' })
  //       itemOk = false;
  //       return;
  //     }

  //     if (isNullOrUndefined(obj.operacaoRegra.id)) {
  //       this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Para prosseguir insira uma Operação Regra!' })
  //       itemOk = false;
  //       return;
  //     }
  //   });

  //   setTimeout(() => {

  //     if (itemOk) {

  //       this.itensNfsParticipantes.forEach(obj => {

  //         this.modalReferenciarNfe = false;

  //         if (this.utilService.parseDecimal6(obj.utilizar) > 0.000000 ||
  //           ((this.pedido.nfeFinalidade.id === 1) && this.utilService.parseDecimal6(obj.quantidadeComercial) > 0.000000)) {
  //           this.pedidoItem = new PedidoItem();
  //           this.pedidoItem.item = new Item();
  //           this.pedidoItem.item.id = obj.itemIntId;
  //           this.pedidoItem.item.codigo = obj.codItemInt;
  //           this.pedidoItem.item.descricao = obj.descItemInt;
  //           this.pedidoItem.tipoDesconto = new TipoDesconto();
  //           this.pedidoItem.operacaoRegra = new OperacaoRegra();
  //           this.pedidoItem.operacaoRegra.id = obj.operacaoRegra.id;
  //           this.pedidoItem.operacaoRegra.cod = obj.operacaoRegra.cod;
  //           this.pedidoItem.operacaoRegra.descricao = obj.operacaoRegra.descricao;
  //           this.pedidoItem.operacaoRegra.permiteAlteracaoAlmoxarifado = obj.operacaoRegra.permiteAlteracaoAlmoxarifado;
  //           this.pedidoItem.operacaoRegra.transfEstoque = obj.transfEstoque;

  //           if (obj.operacaoRegra.almoxarifadoDestino.id) {
  //             this.pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado();
  //             this.pedidoItem.almoxarifadoDestino.id = obj.operacaoRegra.almoxarifadoDestino.id;
  //           }

  //           if (obj.operacaoRegra.almoxarifadoOrigem.id) {
  //             this.pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado();
  //             this.pedidoItem.empresaAlmoxarifado.id = obj.operacaoRegra.almoxarifadoOrigem.id;
  //           }




  //           this.pedidoItem.operacaoRegra.operacaoRegraContabil = new OperacaoRegraContabil();
  //           this.pedidoItem.operacaoRegra.operacaoRegraFiscal = new OperacaoRegraFiscal();
  //           this.pedidoItem.operacaoRegra.operacaoRegraContabil.tipoRegraContabil = new TipoRegraContabil();
  //           this.pedidoItem.itemFornecedorReferenciado = new NfeItem();
  //           this.pedidoItem.pedidoItensRateio = new Array();
  //           // importação
  //           this.pedidoItem.pedidoItensRateio = new Array();

  //           // unidade comercial e tributavel - GCK 27.11
  //           this.pedidoItem.unidadeMedida = new UnidadeMedida();
  //           this.pedidoItem.unidadeMedidaTributavel = new UnidadeMedida();
  //           this.pedidoItem.unidadeMedida.id = obj.unidadeId;
  //           this.pedidoItem.unidadeMedidaTributavel.id = obj.unidadeId;
  //           this.pedidoItem.quantidadeTributavel = obj.utilizar;
  //           this.pedidoItem.valorUnitTributavel = obj.valorUnit;

  //           if (this.pedido.nfeFinalidade.id === 1) {
  //             this.pedidoItem.quantidade = obj.quantidadeComercial;
  //           } else {
  //             this.pedidoItem.quantidade = obj.utilizar;
  //           }

  //           this.pedidoItem.valorUnit = obj.valorUnit;
  //           this.pedidoItem.valorDesconto = null;

  //           if (this.pedido.nfeFinalidade.id === 1) {
  //             this.pedidoItem.valorTotal = this.utilService.parseDecimal(obj.quantidadeComercial * obj.valorUnit);
  //           } else {
  //             this.pedidoItem.valorTotal = this.utilService.parseDecimal(obj.utilizar * obj.valorUnit);
  //           }


  //           this.pedidoItem.itemFornecedorReferenciado = new NfeItem();
  //           this.pedidoItem.itemFornecedorReferenciado.id = obj.niId;
  //           this.pedidoItem.itemFornecedorReferenciado.unitaryValue = obj.valorUnit;

  //           if (this.pedido.nfeFinalidade.id === 1) {
  //             this.pedidoItem.itemFornecedorReferenciado.comercialQuantity = obj.quantidadeComercial;
  //           } else {
  //             this.pedidoItem.itemFornecedorReferenciado.comercialQuantity = obj.utilizar;
  //           }


  //           this.pedidoItem.dadosAdicionais = obj.item;
  //           this.pedidoItem.itemFornecedor = obj.item;

  //           // impostos
  //           this.pedidoItem.cstIpi = new CstIpi();
  //           this.pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA();
  //           this.pedidoItem.cstIcmsTabelaB = new CstIcmsTabelaB();
  //           this.pedidoItem.cstPis = new CstPiscofins();
  //           this.pedidoItem.cstCofins = new CstPiscofins();
  //           this.pedidoItem.cstIcmsTabelaA.id = null;
  //           this.pedidoItem.cstIcmsTabelaB.id = null;
  //           this.pedidoItem.cstIpi.id = null;
  //           this.pedidoItem.cstPis.id = null;
  //           this.pedidoItem.cstCofins.id = null;
  //           // importacao
  //           this.pedidoItem.ufDesembaraco = new Uf();
  //           this.pedidoItem.ufDesembaraco.id = null;
  //           this.pedidoItem.transporteTipo = new TransporteTipo();
  //           this.pedidoItem.transporteTipo.id = null;
  //           this.pedidoItem.ufAdquirente = new Uf();
  //           this.pedidoItem.ufAdquirente.id = null;
  //           this.pedidoItem.tipoIntermediacao = new TipoIntermediacao();
  //           this.pedidoItem.tipoIntermediacao.id = null;

  //           this.pedidoItem.id = this.contador;
  //           this.contador++;

  //           this.calculaValorTotalItem();
  //           this.pedido.pedidoItem.push(this.pedidoItem);
  //         }
  //       });
  //     }

  //     if (this.pedido.pedidoItem.length > 0) {
  //       this.calculaValorPedido();
  //       this.atualizaPreviaContabilEFiscalAposImport();
  //       this.verificaOperacaoRegraGeraFinanceiro(true);
  //       //this.pagamentoForma();
  //     }

  //   }, 1000);



  //   // }
  // }

  dadosOperacaoItem(event: any) {
    this.httpService.wait();
    this.httpService.get('/operacaoregra?$select=id,cod,descricao,' +
      'almoxarifadoOrigem/id,almoxarifadoDestino/id,permiteAlteracaoAlmoxarifado,transfEstoque,controlaEstoque&$filter=id eq ' + event.operacao + ' and ativo eq true')
      .subscribe(ref => {
        ref.value.forEach(element => {
          event.operacaoRegra.id = element.id;
          event.operacaoRegra.cod = element.cod;
          event.operacaoRegra.descricao = element.descricao;
          event.operacaoRegra.permiteAlteracaoAlmoxarifado = element.permiteAlteracaoAlmoxarifado;
          event.operacaoRegra.transfEstoque = element.transfEstoque;

          if (!isNullOrUndefined(element.almoxarifadoOrigem)) {
            if (element.almoxarifadoOrigem.id) {
              event.operacaoRegra.almoxarifadoOrigem = new EmpresaAlmoxarifado();
              event.operacaoRegra.almoxarifadoOrigem.id = element.almoxarifadoOrigem.id;
            }
          }


          if (!isNullOrUndefined(element.almoxarifadoDestino)) {
            if (element.almoxarifadoDestino.id) {
              event.operacaoRegra.almoxarifadoDestino = new EmpresaAlmoxarifado();
              event.operacaoRegra.almoxarifadoDestino.id = element.almoxarifadoDestino.id;
            }
          }

        });
      },
        error => this.httpService.handleError(error, () => this.dadosOperacaoItem(event)),
        () => this.httpService.done());
  }

  gerarContratos() {

    if (this.pedido.formaPagamento.id) {
      if (this.pedido.dtEmissao) {
        if (confirm('Os Contratos serão gerados a partir deste pedido até a data de término inforada, deseja continuar?')) {
          const listasEnviar = {
            pedido: this.pedido.id,
            termino: this.pedido.dtFinalContrato,
            usuario: this.sessionService.loggedUser.id
          };

          this.httpService.wait();
          this.httpService.post('/custom/pedido/gerar-contratos', listasEnviar)
            .subscribe(result => this.buscarPedidos(),
              error => this.httpService.handleError(error, () => this.gerarContratos()),
              () => this.httpService.done());
        }
      } else {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Data de Emissão é obrigatória!' })
        return;
      }
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Forma de Pagamento é obrigatória!' })
      return;
    }
  }

  salvarCompensacao() {

  }

  async clonarPedidosLote(): Promise<void> {
    if (this.pedido.dtEmissao === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Data para o clone do pedido deve ser informada!' })
      return;
    }

    if (this.cloneStatusPedido === undefined || this.cloneStatusPedido === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Status do clone do pedido deve ser selecionado!' })
      return;
    }

    let pedidosClone = '';
    this.pedidosSelecionados.forEach(pedidoClone => pedidosClone += pedidoClone.id + ',')

    const clone = {
      pedidoId: pedidosClone.substring(0, (pedidosClone.length - 1)),
      statusId: this.cloneStatusPedido,
      empresa: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id,
      participante: null,
      dtEmissao: this.pedido.dtEmissao,
      dtEntrega: this.pedido.dtEntrega,
      dtSaidaEntrada: this.pedido.dtFaturamentoAutomatico,
    }

    this.httpService.wait()

    try {
      await this.httpService.post('/custom/pedido/clonar-pedido-id', clone).toPromise().then(res => {
        let count: number = 0
        this.modalClonePedidoLote = false

        res.forEach(item => {
          if (item.error) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: item.msg })
          else count++
        })

        if (count > 0 && count <= res.length) {
          res.forEach(row => {
            if (!row.error) {
              this.LogManagement.registerLog(
                [3, 4, 5].includes(this.pedidosSelecionados[0].pedido_tipo_id) ? LogModule.PURCHASES : LogModule.SALES,
                [3, 4, 5].includes(this.pedidosSelecionados[0].pedido_tipo_id) ? LogScreen.PRODUCT_PURCHASES_ORDER : LogScreen.PRODUCT_SALES_ORDER,
                '/custom/pedido/clonar-pedido-id',
                LogAction.CREATE,
                row.id,
                row.codigo,
                `O pedido ${row.codigo} foi criado a partir do pedido ${row.codigoOrig}`,
                clone
              )
            }
          })
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: 'Os pedidos foram clonados com êxito!' })
        }
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Houve um erro ao clonar os pedidos! Contate o suporte!' })
    } finally {
      this.httpService.done()
      this.buscarPedidos()
    }
  }

  // comboDesconto(value: Array<TipoDesconto>) {
  //   this.opcoesDesconto = [];
  //   value.forEach(element => {
  //     this.opcoesDesconto.push({ label: element.cod, value: element.id });
  //   });
  // }

  ExisteParticipanteContato(participaneContato) {

    this.telefoneContato = (isNullOrUndefined(participaneContato.telefoneContato) || (participaneContato.telefoneContato === ""));

    if (!isNullOrUndefined(participaneContato.nomeContato) && isNullOrUndefined(participaneContato.emailContato) && this.telefoneContato) {
      this.msg = participaneContato.nomeContato;
      return this.msg;
    }
    else if (!isNullOrUndefined(participaneContato.nomeContato) && !isNullOrUndefined(participaneContato.emailContato) && this.telefoneContato) {
      this.msg = participaneContato.nomeContato + " - e-mail: " + participaneContato.emailContato;
      return this.msg;
    }
    else if (!isNullOrUndefined(participaneContato.nomeContato) && !isNullOrUndefined(participaneContato.emailContato) && !this.telefoneContato) {
      this.msg = participaneContato.nomeContato + " - e-mail: " + participaneContato.emailContato + " - telefone: " + participaneContato.telefoneContato;
      return this.msg;
    }
    else if (isNullOrUndefined(participaneContato.nomeContato) && !isNullOrUndefined(participaneContato.emailContato) && !this.telefoneContato) {
      this.msg = "e-mail: " + participaneContato.emailContato + " - telefone: " + participaneContato.telefoneContato;
      return this.msg;
    }
    else if (isNullOrUndefined(participaneContato.nomeContato) && isNullOrUndefined(participaneContato.emailContato) && !this.telefoneContato) {
      this.msg = "telefone: " + participaneContato.telefoneContato;
      return this.msg;
    }
    else if (isNullOrUndefined(participaneContato.nomeContato) && !isNullOrUndefined(participaneContato.emailContato) && this.telefoneContato) {
      this.msg = "e-mail: " + participaneContato.emailContato;
      return this.msg;
    }
    else if (!isNullOrUndefined(participaneContato.nomeContato) && isNullOrUndefined(participaneContato.emailContato) && !this.telefoneContato) {
      this.msg = participaneContato.nomeContato + " - telefone: " + participaneContato.telefoneContato;
      return this.msg;
    }
    else if (isNullOrUndefined(participaneContato.nomeContato) && isNullOrUndefined(participaneContato.emailContato) && this.telefoneContato) {
      this.msg = "Participante não possui um contato"
      return this.msg;
    } else {
      this.msg = "Participante não possui um contato"
      return this.msg;
    }
  }

  verificaOperacaoRegraGeraFinanceiro(possuiPedidoItem): any {

    this.operacaoRegraIds = new Array();

    this.pedido.pedidoItem.forEach(element => {
      if (element.operacaoRegra.id) this.operacaoRegraIds.push(element.operacaoRegra.id);
    });

    let listaEnviar = {
      operacaoRegraIds: this.operacaoRegraIds
    }

    let geraFinanceiro: boolean;

    if (possuiPedidoItem && this.operacaoRegraIds.length > 0) {
      this.httpService.wait();
      this.httpService.post('/custom/pedido/verifica-operacao-regras-que-geram-financeiro', listaEnviar)
        .subscribe(res => {
          geraFinanceiro = res.contains;
          this.operacaoRegraGeraFinanceiro = geraFinanceiro;
          this.comboTipoPagamento(geraFinanceiro);
        },
          error => this.httpService.handleError(error, () => this.verificaOperacaoRegraGeraFinanceiro(possuiPedidoItem)),
          () => this.httpService.done());
    } else {
      geraFinanceiro = false;
      this.operacaoRegraGeraFinanceiro = geraFinanceiro;
      this.comboTipoPagamento(geraFinanceiro);
    }
  }

  retornaCodigoMoeda(moedaId): any {

    this.httpService.wait();
    this.httpService.get('/custom/pedido/retorna-codigo-moeda(' + moedaId + ')')
      .subscribe(moeda => {
        this.codigoMoeda = moeda.codigo;
      },
        error => this.httpService.handleError(error, () => this.retornaCodigoMoeda(moedaId)),
        () => this.httpService.done());
  }


  alterarValorUnitItens() {

    this.pedido.pedidoItem.forEach(element => {
      if (!this.pedido.converterTaxa) {
        element.valorUnit = this.utilService.parseDecimal6(element.valorUnit) * this.utilService.parseDecimal6(this.pedido.txMoeda);

        element.valorTotal = this.utilService.parseDecimal(
          this.utilService.parseDecimal6(element.quantidade) * this.utilService.parseDecimal6(element.valorUnit)
        ) +
          this.utilService.parseDecimal6(element.valorFrete) + this.utilService.parseDecimal6(element.valorSeguro) -
          this.utilService.parseDecimal6(element.valorDesconto) + this.utilService.parseDecimal6(element.valorOutro) +
          this.utilService.parseDecimal6(element.valorIcms) + this.utilService.parseDecimal6(element.valorIcmsSt) +
          this.utilService.parseDecimal6(element.valorIpi) + this.utilService.parseDecimal6(element.valorImpostoImport) +
          this.utilService.parseDecimal6(element.valorCofins) + this.utilService.parseDecimal6(element.valorPis);

      } else {
        element.valorUnit = this.utilService.parseDecimal6(element.valorUnit) / this.utilService.parseDecimal6(this.pedido.txMoeda);

        element.valorTotal = this.utilService.parseDecimal(
          this.utilService.parseDecimal6(element.quantidade) * this.utilService.parseDecimal6(element.valorUnit)
        ) +
          this.utilService.parseDecimal6(element.valorFrete) + this.utilService.parseDecimal6(element.valorSeguro) -
          this.utilService.parseDecimal6(element.valorDesconto) + this.utilService.parseDecimal6(element.valorOutro) +
          this.utilService.parseDecimal6(element.valorIcms) + this.utilService.parseDecimal6(element.valorIcmsSt) +
          this.utilService.parseDecimal6(element.valorIpi) + this.utilService.parseDecimal6(element.valorImpostoImport) +
          this.utilService.parseDecimal6(element.valorCofins) + this.utilService.parseDecimal6(element.valorPis);
      }

      element.valorUnit = this.utilService.parseDecimalString6(element.valorUnit);
      element.valorTotal = this.utilService.parseDecimalString(element.valorTotal);
      element.valorUnitTributavel = this.utilService.parseDecimalString6(element.valorUnit);

    });

  }

  retornaIdOperacaoRegra(operacaoRegraId, tipoRegraContabil, skeletonLoad: boolean = false) {


    if (!isNullOrUndefined(operacaoRegraId)) {
      this.operacaoRegraId = operacaoRegraId;
    } else {
      this.operacaoRegraId = 0;
    }

    let isServico = tipoRegraContabil == 2 ? true : false;

    this.opcoesItens = [];
    if (this.pedido.pedidoTipo.id === 2 || this.pedido.pedidoTipo.id === 1) {
      if (skeletonLoad) this.skeletonConfigXml.startLoad()
      else this.httpService.wait()
      this.httpService.get('/custom/pedido/buscar-item-venda('
        + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ',' + this.pedido.pedidoTipo.id + ',' + operacaoRegraId + ')')
        .subscribe(ref => {
          if (!isNullOrUndefined(ref)) {
            ref.forEach(element => {

              if ((!isServico) && element.itemTipo !== 10) {
                this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
              } else if ((isServico) && element.itemTipo === 10) {
                this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
              }
            });
          }

        },
          error => this.httpService.handleError(error, () => this.retornaIdOperacaoRegra(operacaoRegraId, tipoRegraContabil)),
          () => {
            if (skeletonLoad) this.skeletonConfigXml.endLoad()
            else this.httpService.done()
          })

    } else {
      if (skeletonLoad) this.skeletonConfigXml.startLoad()
      else this.httpService.wait()
      this.httpService.get('/custom/pedido/buscar-item-compra('
        + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ',' + this.pedido.pedidoTipo.id + ',' + operacaoRegraId + ')')
        .subscribe(ref => {
          if (!isNullOrUndefined(ref)) {
            ref.forEach(element => {
              if ((!isServico) && element.itemTipo !== 10) {
                this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
              } else if ((isServico) && (element.itemTipo === 10 || element.itemTipo === 12)) {
                this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
              }
            });
          }

        },
          error => this.httpService.handleError(error, () => this.retornaIdOperacaoRegra(operacaoRegraId, tipoRegraContabil)),
          () => {
            if (skeletonLoad) this.skeletonConfigXml.endLoad()
            else this.httpService.done()
          })

    }
  }

  //SIMON LALLEMENT: não usado
  // replicaOperacaoRegra(itemNfe) {
  //   if ((this.pedido.nfeFinalidade.id === 1) && (this.itensNfsParticipantes.indexOf(itemNfe) === 0)) {
  //     this.itensNfsParticipantes.forEach(element => {
  //       element.operacao = itemNfe.operacao;
  //       this.dadosOperacaoItem(element);
  //     });
  //   }
  // }

  showModalImportarItens() {
    this.exibirModalImportarItens = true;
  }

  closeModalImportarItens() {
    this.exibirModalImportarItens = false;
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
  }

  importar() {
    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('type', 'pedido-itens-csv');
    data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());

    this.httpService.wait();
    this.httpService.post('/custom/upload', data)
      .subscribe(result => {

        this.uploaded(result);

      }, error => this.httpService.handleError(error, () => this.importar()),
        () => this.httpService.done());
    this.file = null;
  }

  private uploaded(res?: any) {
    if (res.result.error === true) {

      if (!isNullOrUndefined(res.result.camposInvalido)) {
        res.result.camposInvalido.forEach(element => {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: element })
        });
      } else {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: res.result.message })
      }

    } else {
      /* Caso o usuário opte por sobrescrever os dados (this.replace = true),
      remover todos os itens da lista "Itens do pedido" */
      if (this.replace == true) {
        this.pedido.pedidoItem = new Array();
      }

      this.getPedidoItens(res.result.pedidoItens);
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: res.result.message })
    }
  }

  get importDisabled(): boolean {
    return !this.dblinkedSessionService.hasEmpresaSelected || !this.file;
  }

  getPedidoItens(pedidoItens) {

    pedidoItens.forEach(element => {

      this.pedidoItem = new PedidoItem();
      this.pedidoItem.tipoDesconto = new TipoDesconto();
      this.pedidoItem.cstIpi = new CstIpi();
      this.pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA();
      this.pedidoItem.cstIcmsTabelaB = new CstIcmsTabelaB();
      this.pedidoItem.cstPis = new CstPiscofins();
      this.pedidoItem.cstCofins = new CstPiscofins();
      this.pedidoItem.ufAdquirente = new Uf();
      this.pedidoItem.ufDesembaraco = new Uf();
      this.pedidoItem.unidadeMedida = new UnidadeMedida();
      this.pedidoItem.unidadeMedidaTributavel = new UnidadeMedida();
      this.pedidoItem.item = new Item();
      this.pedidoItem.operacaoRegra = new OperacaoRegra();
      this.pedidoItem.operacaoRegra.operacaoRegraFiscal = new OperacaoRegraFiscal();
      this.pedidoItem.operacaoRegra.operacaoRegraContabil = new OperacaoRegraContabil();
      this.pedidoItem.operacaoRegra.operacaoRegraContabil.tipoRegraContabil = new TipoRegraContabil();
      this.pedidoItem.pedidoItensRateio = new Array();
      this.pedidoItem.transporteTipo = new TransporteTipo();
      this.pedidoItem.tipoIntermediacao = new TipoIntermediacao();
      this.pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado();
      this.pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado();
      this.valorItemTributavel = null;

      var numberValue = Number(element.tipo_desconto_id);
      this.pedidoItem.tipoDesconto.id = numberValue;

      // OPERAÇÃO REGRA
      this.pedidoItem.operacaoRegra.id = element.operacao_regra_id;
      this.pedidoItem.operacaoRegra.cod = element.operacao_regra;
      this.pedidoItem.operacaoRegra.descricao = element.operacao_regra_descricao;
      this.pedidoItem.operacaoRegra.permiteAlteracaoAlmoxarifado = element.permite_alteracao_almoxarifado;
      this.pedidoItem.operacaoRegra.transfEstoque = element.transf_estoque;
      this.pedidoItem.operacaoRegra.controlaEstoque = element.controla_estoque;

      if (!isNullOrUndefined(element.almoxarifado_origem_id)) {
        this.pedidoItem.operacaoRegra.almoxarifadoOrigem = new EmpresaAlmoxarifado();
        this.pedidoItem.operacaoRegra.almoxarifadoOrigem.id = element.almoxarifado_origem_id;
        this.pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado();
        this.pedidoItem.empresaAlmoxarifado.id = element.almoxarifado_origem_id;
      }

      if (!isNullOrUndefined(element.almoxarifado_destino_id)) {
        this.pedidoItem.operacaoRegra.almoxarifadoDestino = new EmpresaAlmoxarifado();
        this.pedidoItem.operacaoRegra.almoxarifadoDestino.id = element.almoxarifado_destino_id;
        this.pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado();
        this.pedidoItem.almoxarifadoDestino.id = element.almoxarifado_destino_id;
      }

      // ITEM
      this.pedidoItem.item.id = element.item_id;
      this.pedidoItem.item.codigo = element.item_codigo;
      this.pedidoItem.item.descricao = element.item_descricao;
      this.pedidoItem.ncm = element.tipi_ncm;
      if (!isNullOrUndefined(element.unidade_medida_id)) {
        this.pedidoItem.unidadeMedida.id = element.unidade_medida_id;
        this.pedidoItem.unidadeMedida.codigo = element.unidade_medida_cod;
        this.pedidoItem.unidadeMedidaTributavel.id = element.unidade_medida_id;
        this.pedidoItem.unidadeMedidaTributavel.codigo = element.unidade_medida_cod;
      }

      // DADOS
      this.pedidoItem.cfop = element.cfop;
      this.pedidoItem.itemFornecedor = element.item_fornecedor;

      this.pedidoItem.quantidade = this.utilService.parseDecimal6(element.quantidade);
      this.pedidoItem.valorUnit = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(element.valor_unit));

      this.pedidoItem.quantidadeTributavel = this.utilService.parseDecimal6(element.quantidade_tributavel);
      this.pedidoItem.valorUnitTributavel =
        this.utilService.parseDecimalString6(this.utilService.parseDecimal6(element.valor_unit_tributavel));

      this.pedidoItem.valorFrete = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_frete));
      this.pedidoItem.valorSeguro = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_seguro));
      this.pedidoItem.valorDesconto = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_desconto));
      this.pedidoItem.valorOutro = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_outro));

      // IPI
      this.pedidoItem.cstIpi.id = element.cst_ipi;
      this.pedidoItem.codigoIpi = element.codigo_enquadramento_ipi;
      this.pedidoItem.valorBcIpi = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_bc_ipi));
      this.pedidoItem.aliquotaIpi = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.aliq_ipi));
      // this.pedidoItem.valorIpi = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_ipi));

      // ICMS
      this.pedidoItem.cstIcmsTabelaA.id = element.cst_icms_tabela_a;
      this.pedidoItem.cstIcmsTabelaB.id = element.cst_icms_tabela_b;
      this.pedidoItem.modBcIcms = element.mod_bc_icms;
      this.pedidoItem.percRedBcIcms = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.p_red_bc_icms));
      this.pedidoItem.valorBcIcms = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_bc_icms));
      this.pedidoItem.aliquotaIcms = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.aliq_icms));
      // this.pedidoItem.valorIcms = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_icms));
      this.pedidoItem.valorBcIcmsSt = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_bc_icms_st));
      this.pedidoItem.aliquotaIcmsSt = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.aliq_icms_st));
      // this.pedidoItem.valorIcmsSt = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_icms_st));
      this.pedidoItem.modBcIcmsSt = parseInt(element.mod_bc_icms_st);
      this.pedidoItem.csosn = element.csosn;

      // PIS
      this.pedidoItem.cstPis.id = element.cst_pis_id;
      this.pedidoItem.valorBcPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_bc_pis));
      this.pedidoItem.perAliquotaPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.aliq_p_pis));
      // this.pedidoItem.vlrAliquotaPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.aliq_v_pis));
      // this.pedidoItem.qtdVendaPis = element.qtde_vend_pis;
      // this.pedidoItem.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_pis));

      // COFINS
      this.pedidoItem.cstCofins.id = element.cst_cofins_id;
      this.pedidoItem.valorBcCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_bc_cofins));
      this.pedidoItem.perAliquotaCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.aliq_p_cofins));
      // this.pedidoItem.vlrAliquotaCofins = element.aliq_v_cofins;
      // this.pedidoItem.qtdVendaCofins = element.qtde_vend_cofins;
      // this.pedidoItem.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_cofins));

      // IMPORTAÇÃO
      this.pedidoItem.valorBcImport = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_bc_import));
      this.pedidoItem.valorDespImport = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_desp_adu_import));
      this.pedidoItem.valorIofImport = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_iof_import));
      this.pedidoItem.valorImpostoImport = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valor_imp_import));

      // DADOS ADICIONAIS
      this.pedidoItem.dadosAdicionais = element.dados_adicionais;

      // DECLARAÇÃO DE IMPORTAÇÃO
      this.pedidoItem.numeroDi = element.numero_di;
      this.pedidoItem.codigoExportador = element.codigo_exportador;
      this.pedidoItem.dataRegistroImport = element.dt_registro_import;
      this.pedidoItem.tipoIntermediacao.id = element.tipo_intermediacao_id;

      // DESEMBARAÇO ADUANEIRO
      this.pedidoItem.ufDesembaraco.id = element.uf_desembaraco_id;
      this.pedidoItem.localDesembaraco = element.local_desembaraco;
      this.pedidoItem.transporteTipo.id = element.transporte_tipo_id;
      this.pedidoItem.dataDesembaraco = element.dt_desembaraco;
      this.pedidoItem.valorAFRMM = this.utilService.parseDecimal(element.valor_afrmm);
      this.pedidoItem.cnpjAdquirente = element.cnpj_adquirente;
      this.pedidoItem.ufAdquirente.id = element.uf_adquirente_id;

      // ADIÇÃO
      this.importacaoAdicao.numero = element.nr_adicao;
      this.importacaoAdicao.codigoFabricante = element.cod_fabricante;

      // EXPORTAÇÃO
      this.pedidoItem.numeroAto = element.numero_ato_export;


      this.calculaValorIcms();
      this.calculaValorIcmsSt();
      this.calculaValorIpi();
      this.calculaValorPis();
      this.calculaValorCofins();
      this.validaCampoNumero(this.importacaoAdicao.numero, 'Número da Adição');
      this.incluirAdicaoImportacao();
      this.botaoEditar = false;
      this.salvarItemLista(null, true);

    });

    this.atualizaPreviaContabilEFiscalAposImport();
    this.verificaOperacaoRegraGeraFinanceiro(true);
    this.pagamentoForma(false);

  }

  atualizaPreviaContabilEFiscalAposImport() {
    this.visualizacaoContabil = new Array();
    this.visualizacaoFiscal = new Array();
    this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);
    // this.pedidoService.buscarPreviaFiscal(this.pedido, this.visualizacaoFiscal);
  }

  previewPedido(event: any) {

    this.visualizacaoContabil = new Array();
    this.visualizacaoFiscal = new Array();

    this.httpService.wait();
    this.httpService.get('/custom/pedido/buscar-infos-pedido(' + (event.id) + ')')
      .subscribe(res => {

        this.nfsEmitida = res.nfsEmitida;

        this.pedido = new Pedido();
        this.pedido.itemFaturamento = new Item();
        this.pedido.participanteEmpresaId = res.participanteEmpresaId;
        this.pedido.id = res.id;
        this.pedido.codigo = res.codigo;
        this.pedido.numNf = res.numNf;
        this.pedido.serie = res.serie;
        this.pedido.dtEntrega = res.dtEntrega;
        this.pedido.dtEmissao = res.dtEmissao;
        this.pedido.dtFaturamentoAutomatico = res.dtFaturamentoAutomatico;
        this.pedido.enderecoCobranca = res.enderecoCobranca;
        this.pedido.enderecoEntrega = res.enderecoEntrega;
        this.pedido.dadosAdicionais = res.dadosAdicionais;
        this.pedido.codigoOrcamento = res.codigoOrcamento;
        this.pedido.codigoControle = res.codigoControle;
        this.pedido.valorInss = this.utilService.parseDecimal(res.valorInss);
        this.pedido.valorIr = this.utilService.parseDecimal(res.valorIr);
        this.pedido.valorCsll = this.utilService.parseDecimal(res.valorCsll);
        this.pedido.valorPis = this.utilService.parseDecimal(res.valorPis);
        this.pedido.valorCofins = this.utilService.parseDecimal(res.valorCofins);
        this.pedido.valorPisFaturamento = this.utilService.parseDecimal(res.valorPisFaturamento);
        this.pedido.valorCofinsFaturamento = this.utilService.parseDecimal(res.valorCofinsFaturamento);
        this.pedido.valorIssRetido = this.utilService.parseDecimal(res.valorIssRetido);
        this.pedido.valorIss = this.utilService.parseDecimal(res.valorIss);
        this.pedido.valorOutrasRetencoes = this.utilService.parseDecimal(res.valorOutrasRetencoes);
        this.pedido.txMoeda = this.utilService.parseDecimal6(res.txMoeda);
        this.pedido.numeroInvoice = res.numeroInvoice;
        this.pedido.dataInvoice = res.dataInvoice;
        this.pedido.dataInicial = res.dataInicial;
        this.pedido.dataFinal = res.dataFinal;
        this.codigoMoeda = res.moeda.codigo;
        this.pedido.converterTaxa = res.converterTaxa;
        this.pedido.itemFaturamento.id = res.itemFaturamento;
        this.pedido.dataUltimaAtualizacao = res.dataAtualizacao;
        this.operacaoRegraAntecipado = res.operacaoRegraAntecipado;
        this.vendedorId = res.vendedorId;
        this.agenciaId = res.agenciaId;
        this.valorComissao = res.valorComissao;
        this.pagamentoComissao = res.pagamentoComissao;

        if (this.agenciaId > 0) {
          this.exbirModalAgencia = true;
        }


        this.pedido.participante = new Participante();
        this.pedido.participante = res.participante;

        if (!isNullOrUndefined(res.pedidoTipo)) {
          this.pedido.pedidoTipo = new PedidoTipo();
          this.pedido.pedidoTipo = res.pedidoTipo;
        } else {
          this.pedido.pedidoTipo = new PedidoTipo();
        }

        if (!isNullOrUndefined(res.pedidoStatus)) {
          this.pedido.pedidoStatus = new PedidoStatus();
          this.pedido.pedidoStatus = res.pedidoStatus;
        } else {
          this.pedido.pedidoStatus = new PedidoStatus();
        }

        if (!isNullOrUndefined(res.modNf)) {
          this.pedido.modNf = new ModNf();
          this.pedido.modNf = res.modNf;
        } else {
          this.pedido.modNf = new ModNf();
        }

        if (!isNullOrUndefined(res.moeda)) {
          this.pedido.moeda = new Moeda();
          this.pedido.moeda = res.moeda;
        } else {
          this.pedido.moeda = new Moeda();
        }

        if (!isNullOrUndefined(res.tipoIncoterm)) {
          this.pedido.tipoIncoterm = new TipoIncoterm();
          this.pedido.tipoIncoterm = res.tipoIncoterm;
        } else {
          this.pedido.tipoIncoterm = new TipoIncoterm();
        }

        if (!isNullOrUndefined(res.formaPagamento)) {
          this.pedido.formaPagamento = new FormaPagamento();
          this.pedido.formaPagamento = res.formaPagamento;
        } else {
          this.pedido.formaPagamento = new FormaPagamento();
        }

        if (!isNullOrUndefined(res.empresaConta)) {
          this.pedido.empresaConta = new EmpresaConta();
          this.pedido.empresaConta = res.empresaConta;
        } else {
          this.pedido.empresaConta = new EmpresaConta();
        }

        if (!isNullOrUndefined(res.empresa)) {
          this.pedido.empresa = new Empresa();
          this.pedido.empresa = res.empresa;
        } else {
          this.pedido.empresa = new Empresa();
        }

        this.pedido.tipoPagamento = res.tipoPagamento;

        this.pedido.pedidoParcela = new Array();
        res.pedidoParcela.forEach(parcela => {
          let pedParcela = new PedidoParcela();
          pedParcela.valor = this.utilService.parseDecimal(parcela.valor);
          pedParcela.dtVencimento = this.utilService.dateWithoutTimeZone(parcela.dtVencimento);
          pedParcela.parcela = parcela.parcela;

          pedParcela.empresaConta = new EmpresaConta()
          if (parcela.empresaConta) {
            pedParcela.empresaConta.id = parcela.empresaConta.id
            pedParcela.empresaConta.nome = parcela.empresaConta.nome

          } else {
            pedParcela.empresaConta.id = res.empresaConta.id
            pedParcela.empresaConta.nome = res.empresaConta.nome
          }

          this.pedido.pedidoParcela.push(pedParcela);
          this.includeCalendarState()
        });



        this.pedido.pedidoItem = new Array();

        res.pedidoItens.forEach(pedItem => {

          let pedidoItem = new PedidoItem();

          pedidoItem.id = pedItem.id;

          if (!isNullOrUndefined(pedItem.tipoDesconto)) {
            pedidoItem.tipoDesconto = new TipoDesconto();
            pedidoItem.tipoDesconto = pedItem.tipoDesconto;
          } else {
            pedidoItem.tipoDesconto = new TipoDesconto();
          }

          if (!isNullOrUndefined(pedItem.item)) {
            pedidoItem.item = new Item();
            pedidoItem.item = pedItem.item;
          } else {
            pedidoItem.item = new Item();
          }

          if (!isNullOrUndefined(pedItem.operacaoRegra)) {
            pedidoItem.operacaoRegra = new OperacaoRegra();
            pedidoItem.operacaoRegra = pedItem.operacaoRegra;
            pedidoItem.isServico = pedItem.operacaoRegra.tipoRegra === 2;
          } else {
            pedidoItem.operacaoRegra = new OperacaoRegra();
          }


          pedidoItem.modBcIcmsSt = parseInt(pedItem.modBcIcmsSt);



          pedidoItem.quantidade = pedItem.quantidade;
          pedidoItem.valorUnit = pedItem.valorUnit;
          pedidoItem.valorDesconto = pedItem.valorDesconto;
          pedidoItem.valorTotal = pedItem.valorTotal;
          pedidoItem.valorIss = pedItem.valorIss;
          pedidoItem.valorIssRet = pedItem.valorIssRet;

          pedidoItem.pedidoItensRateio = new Array();

          pedidoItem.valorOperSemIcmsDifal = this.utilService.parseDecimalString(pedItem.valorOperSemIcmsDifal);
          pedidoItem.aliqInternaDifal = this.utilService.parseDecimalString(pedItem.aliqInternaDifal);
          pedidoItem.aliqInterestadualDifal = this.utilService.parseDecimalString(pedItem.aliqInterestadualDifal);
          pedidoItem.valorOperComIcmsDifal = this.utilService.parseDecimalString(pedItem.valorOperComIcmsDifal);
          pedidoItem.icmsOrigemCreditoDifal = this.utilService.parseDecimalString(pedItem.icmsOrigemCreditoDifal);
          pedidoItem.icmsDestinoDebitoDifal = this.utilService.parseDecimalString(pedItem.icmsDestinoDebitoDifal);
          pedidoItem.valorRecolherDifal = this.utilService.parseDecimalString(pedItem.valorRecolherDifal);



          this.somaRateio = 0.00;
          pedItem.pedidoItensRateio.forEach(rat => {

            this.somaRateio = this.somaRateio + rat.porcentagem;
            let itemRateio = new PedidoItemRateio();


            itemRateio.porcentagem = rat.porcentagem;

            if (!isNullOrUndefined(rat.cadCcus)) {
              itemRateio.cadCcus = new CadCcus();
              itemRateio.cadCcus = rat.cadCcus;
            } else {
              itemRateio.cadCcus = new CadCcus();
            }

            if (!isNullOrUndefined(rat.cadProj)) {
              itemRateio.cadProj = new CadProj();
              itemRateio.cadProj = rat.cadProj;
            } else {
              itemRateio.cadProj = new CadProj();
            }
            itemRateio.id = rat.id;
            pedidoItem.pedidoItensRateio.push(itemRateio);

          });

          if (this.somaRateio < 100) {
            let itemRateio = new PedidoItemRateio();
            itemRateio.cadCcus = new CadCcus();
            itemRateio.cadProj = new CadProj();
            pedidoItem.pedidoItensRateio.push(itemRateio);
          }
          this.pedido.pedidoItem.push(pedidoItem);
          this.visualizacaoFiscal.push(pedidoItem);
        });

        setTimeout(() => {
          this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);
          this.visualizarEscrituracao();
        }, 1000);

      },
        error => this.httpService.handleError(error, () => this.previewPedido(event)),
        () => this.httpService.done());

  }

  onFilterPedido(event) {

    this.todosSelecionados1 = false;
    this.todosSelecionados2 = false;
    this.todosSelecionados3 = false;
    this.todosSelecionados4 = false;

    this.pedidosSelecionados.forEach(element => {
      element.selected = false;
    });

    this.pedidosSelecionados = new Array();
    this.idPedidoView = new Array();
    this.pedidosFiltrados = new Array();
    this.pedidosFiltrados = event.filteredValue;

  }

  //SIMON LALLEMENT: Não usado
  // selectPedidosFiltrados(element: any) {

  //   if (!isNullOrUndefined(this.pedidosFiltrados) && this.pedidosFiltrados.length > 0) {
  //     this.pedidosFiltrados.forEach(ped => {
  //       if (ped.id === element.id) {
  //         element.selected = true;
  //         this.pedidosSelecionados.push(element);
  //       }
  //     });
  //   } else {
  //     element.selected = true;
  //     this.pedidosSelecionados.push(element);
  //   }
  // }

  //SIMON LALLEMENT: Não usado
  // emitirCobrancas() {
  //   this.httpService.wait();
  //   this.httpService.post(this.configService.defaultUrlApiUpload + '/custom/marketplace/emitir-cobranca-direta', this.pedidosSelecionados)
  //     .subscribe(result => {
  //       if (result["error"] == true) {
  //         this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result["msg"] })
  //       } else {
  //         this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result["msg"] })
  //         this.buscarPedidos()
  //       }
  //     },
  //       error => this.httpService.handleError(error),
  //       () => this.httpService.done());

  // }

  showTelaPedidoCompra(tipo) {
    this.statusNegociacao = false;
    this.statusCompras = false;
    this.statusRecusado = false;
    this.statusFinalizado = false;

    this.telaAtual = tipo

    if (tipo === 'TELA_1') {
      this.statusNegociacao = true
      this.numeroAba = 0
      this.valorTotalPedidos = this.valorTotalPedidosNegociacao


    } else if (tipo === 'TELA_2') {
      this.statusCompras = true
      this.numeroAba = 1
      this.valorTotalPedidos = this.valorTotalPedidosComprasVendas

    } else if (tipo === 'TELA_3') {
      this.statusRecusado = true
      this.numeroAba = 2
      this.valorTotalPedidos = this.valorTotalPedidosRecusado

    } else if (tipo === 'TELA_4') {
      this.statusFinalizado = true
      this.numeroAba = 3
      this.valorTotalPedidos = this.valorTotalPedidosFinalizado

    }
    this.determinarPrimeiraChamada()

    // this.definirValorTotalPedidos()

    this.pedidosSelecionados = [];
    this.valorPedidosSelecionados = 0.00

    this.todosSelecionados1 = false;
    this.todosSelecionados2 = false;
    this.todosSelecionados3 = false;
    this.todosSelecionados4 = false;
    // this.valorTotalPedidos = 0.00;
    this.valorTotalPedido = 0.00;
    // this.totalValue = 0;

    // this.pedidosNegociacao.forEach(element => {
    //   element.selected = false;
    //   this.pedidosFiltrados = new Array();
    //   if (this.numeroAba === 0) {
    //     this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
    //   }
    // });

    // this.pedidosCompraVenda.forEach(element => {
    //   element.selected = false;
    //   this.pedidosFiltrados = new Array();
    //   if (this.numeroAba === 1) {
    //     this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
    //   }
    // });

    // this.pedidosRecusados.forEach(element => {
    //   element.selected = false;
    //   this.pedidosFiltrados = new Array();
    //   if (this.numeroAba === 2) {
    //     this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
    //   }
    // });

    // this.pedidosFinalizados.forEach(element => {
    //   element.selected = false;
    //   this.pedidosFiltrados = new Array();
    //   if (this.numeroAba === 3) {
    //     this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
    //   }
    // });

    // this.numeroAba = this.numeroAba;

    // switch (this.numeroAba) {
    //   case 0:
    //     this.calcTotalValue(this.pedidosNegociacao)
    //     break;
    //   case 1:
    //     this.calcTotalValue(this.pedidosCompraVenda)
    //     break;
    //   case 2:
    //     this.calcTotalValue(this.pedidosRecusados)
    //     break;
    //   case 3:
    //     this.calcTotalValue(this.pedidosFinalizados)
    //     break;
    // }

    this.changeToolbar()
  }

  //SIMON LALLEMENT: não usado
  // isCardPedidoCompra: boolean = true;
  // ShowHIdePedidoCompra() {
  //   this.isCardPedidoCompra = !this.isCardPedidoCompra;
  // }

  alterarPeriodo() {
    this.validacaoError = false;

    const mes = this.dblinkedSessionService.periodo.month.value > 8 ?
      this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
    const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
    this.defaultDate = new Date(dt);

    let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
    // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
    lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
    this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

    if (this.dataIni !== null && this.dataFin !== null) {

      const mesIni = this.dataIni.getMonth() > 8 ?
        (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() > 8 ?
        (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
        this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 8) ?
        this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }

    const d1 = new Date(this.dataIniSearch);
    const d2 = new Date(this.dataFinSearch);

    if (d1 > d2) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Data inicial maior que a final. Verifique!' })
      this.validacaoError = true;
      return;
    } else {
      let dataIni = `${this.dataIniSearch.substring(8, 10)}/${this.dataIniSearch.substring(5, 7)}/${this.dataIniSearch.substring(0, 4)}`,
        dataFin = `${this.dataFinSearch.substring(8, 10)}/${this.dataFinSearch.substring(5, 7)}/${this.dataFinSearch.substring(0, 4)}`;

      this.subtitle = `Período selecionado ${dataIni} à ${dataFin}`


      this.modalPeriodo = false;
      // this.getQuantidadePedidos()

      this.primeiraChamadaNegociacao = true
      this.primeiraChamadaComprasVendas = true
      this.primeiraChamadaRecusado = true
      this.primeiraChamadaFinalizado = true

      this.determinarPrimeiraChamada()
    }
  }


  addDias(d, mes, dias) {
    var date;
    if ((typeof d === 'string') && (d.includes('/'))) {
      const str = d.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const day = Number(str[0]);

      date = new Date(year, month, day);
    } else {
      date = new Date(d);
    }

    // retorna 0 para janeiro e 11 para dezembro
    var n_date = new Date(date.getFullYear(), date.getMonth(), eval(dias + date.getDate()));
    return n_date
  }

  async buscarParametroFinanceiro(): Promise<void> {
    this.skeletonConfig.startLoad()
    try {
      await this.httpService.get('/empresaparametrofinanceiro?$select=id,pedidoMisto,operacaoRegraContabil/id,financeiroAntecipado,comissaoAtiva,filtroPedido&$filter=empresa/id eq '
        + this.dblinkedSessionService.empresa.id)
        .toPromise()
        .then(
          ret => {
            ret.value.forEach(element => {
              this.financeiroAntecipado = element.financeiroAntecipado
              this.operacaoRegraAntecipado = element.operacaoRegraContabil ? element.operacaoRegraContabil.id : 0
              this.comissaoAtiva = element.comissaoAtiva
              this.pedidoMisto = element.pedidoMisto
              this.filtroData = element.filtroPedido

            })
          }
        )
        .catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: "Erro ao recuperar as configurações financeiras da empresa, contate o suporte: " + err.message }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao recuperar as configurações financeiras da empresa, contate o suporte: " + err.message })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

  //SIMON LALLEMENT: Não usado
  // loadOperacao(value: Array<OperacaoRegraContabil>) {
  //   this.opcoesOperacaoCont = [];
  //   this.opcoesOperacaoCont.push({ label: 'Selecione', value: 0 });
  //   value.forEach(element => {
  //     this.opcoesOperacaoCont.push({ label: element.descricao, value: element.id });
  //   });
  // }

  gerarFinanceiroPedido() {

    let pedidoIds = new Array();
    pedidoIds.push(this.pedido.id);

    if (confirm('Deseja realmente gerar o financeiro destes pedidos?')) {

      const listasEnviar = {
        pedidoIds: pedidoIds,
        usarFluxo: this.flowUses
      };

      this.httpService.wait();
      this.httpService.post('/custom/pedido/gerar-financeiro-pedido', listasEnviar)
        .subscribe(result => {
          if (result.error === true) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })
          }
        },
          error => this.httpService.handleError(error, () => this.gerarFinanceiroPedido()),
          () => this.httpService.done());
    }
  }

  buscarVendedores() {
    this.skeletonConfig.startLoad()
    this.httpService.get('/custom/cadastro/buscar-vendedor(' + this.dblinkedSessionService.empresa.id + ',' +
      this.sessionService.loggedUser.id + ')')
      .subscribe(ret => {
        this.opcoesVendedor = [];
        this.opcoesVendedor.push({ label: 'Selecione um Vendedor', value: 0 });
        ret.forEach(element => {
          this.opcoesVendedor.push({ label: element.nome, value: element.id });
        })
      }
        ,
        error => this.httpService.handleError(error, () => this.buscarVendedores()),
        () => this.skeletonConfig.endLoad());
  }

  getCupomFiscal() {
    this.cuponsSelecionados = new Array();
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'pedidoid': this.pedido.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'fiscal/cupom-fiscal', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: res["message"] })

          } else {
            this.modalCupomFiscal = true;
            this.listaCupons = new Array();
            for (let line of res["mensagem"]) {
              this.listaCupons.push(line);
              if (line["selected"]) {
                this.cuponsSelecionados.push(line["id"]);
              }
            }
            this.atualizarTotalValorCupom();
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: err })
          this.httpService.done();
        }
      );
  }

  //SIMON LALLEMENT: Não usado
  // checkAllCupom() {
  //   this.cuponsSelecionados = new Array();

  //   if (!this.cuponsTodosSelected) {
  //     for (let line of this.listaCupons) {
  //       this.cuponsSelecionados.push(line["id"]);
  //       line["selected"] = true;
  //     }

  //   } else {
  //     for (let line of this.listaCupons) {
  //       line["selected"] = false;
  //     }
  //   }
  //   this.atualizarTotalValorCupom();

  // }

  //SIMON LALLEMENT: Não usado
  // checkCupom(id, selected) {
  //   if (!selected) {
  //     this.cuponsSelecionados.push(id);

  //   } else {
  //     for (var index = 0; index < this.cuponsSelecionados.length; index++) {
  //       if (this.cuponsSelecionados[index] === id) {
  //         this.cuponsSelecionados.splice(index, 1);
  //       }
  //     }
  //   }

  //   this.cuponsTodosSelected = false;
  //   if (this.cuponsSelecionados.length === this.listaCupons.length) {
  //     this.cuponsTodosSelected = true;
  //   }

  //   this.atualizarTotalValorCupom();
  // }

  atualizarTotalValorCupom() {
    this.valorTotalCupons = 0;
    for (var cpid of this.cuponsSelecionados) {
      for (var line of this.listaCupons) {
        if (line["id"] === cpid) {
          this.valorTotalCupons += line["valor"];
          break;
        }
      }
    }
  }

  atualizarVincCupom() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    const info = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id,
      pedido_id: this.pedido.id,
      licupons: this.cuponsSelecionados
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'financeiro/pedido-cupom-fiscal-relac', info, httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: res["message"] })

          } else {
            this.modalCupomFiscal = false;
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: res["message"] })
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: err })
          this.httpService.done();
        }
      );
  }

  buscarImpostosPedidoServico() {

    const listasEnviar = {
      pedido: JSON.stringify(this.pedido, null, ''),
      empresa: this.dblinkedSessionService.empresa.id,
    };

    this.pedido.valorInss = 0.00;
    this.pedido.valorIr = 0.00;
    this.pedido.valorCsll = 0.00;
    this.pedido.valorPis = 0.00;
    this.pedido.valorCofins = 0.00;
    this.pedido.valorIssRetido = 0.00;
    this.pedido.valorIss = 0.00;
    this.pedido.valorIssRetido = 0.00;
    this.pedido.valorOutrasRetencoes = 0.00;
    this.pedido.valorPisFaturamento = 0.00;
    this.pedido.valorCofinsFaturamento = 0.00;

    this.httpService.post('/custom/pedido/buscar-impostos-pedido', listasEnviar)
      .subscribe(res => {
        res.value.forEach(element => {

          this.pedido.pedidoItem.forEach(pedidoItem => {
            if ((element.pedidoItemid === pedidoItem.id) && pedidoItem.isServico === true) {
              pedidoItem.valorCide = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCide));
              pedidoItem.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofins));
              pedidoItem.valorCofinsRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofinsRet));
              pedidoItem.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
              pedidoItem.valorSimples = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
              pedidoItem.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPis));
              pedidoItem.valorPisRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPisRet));
              pedidoItem.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIss));
              pedidoItem.valorIssRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIssRet));
            }
          });


          this.pedido.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorInss) + this.utilService.parseDecimal(element.valorInss));
          this.pedido.valorIr = this.utilService.parseDecimal(this.pedido.valorIr) + (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
          this.pedido.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCsll) + this.utilService.parseDecimal(element.valorCsll));
          this.pedido.valorPisFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPisFaturamento) + this.utilService.parseDecimal(element.valorPis));
          this.pedido.valorCofinsFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofinsFaturamento) + this.utilService.parseDecimal(element.valorCofins));
          this.pedido.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPis) + this.utilService.parseDecimal(element.valorPisRet));
          this.pedido.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofins) + this.utilService.parseDecimal(element.valorCofinsRet));
          this.pedido.valorIssRetido = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIssRetido) + this.utilService.parseDecimal(element.valorIssRet));
          this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes) + this.utilService.parseDecimal(element.valorOutrasRetencoes));
          this.pedido.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIss) + this.utilService.parseDecimal(element.valorIss));
          this.visualizacaoFiscal.push(element);


        });

      }, error => this.httpService.handleError(error, () => this.buscarImpostosPedidoServico()),
        () => this.httpService.done());
  }

  centroDeCustoObrigatorio() {

    this.skeletonConfig.startLoad();
    this.httpService.get('/custom/pedido/centro-de-custo-obrigatorio(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(element => {
        this.centroCustoObrigatorio = element.cadCcusObrigatorio;
      },
        error => this.httpService.handleError(error, () => this.centroDeCustoObrigatorio()),
        () => this.skeletonConfig.endLoad());
  }


  emissaoLote() {

    let existePedidoEmissao = false;

    this.pedidosSelecionados.forEach(element => {
      if ((element.pedido_status_id === 4 && this.tipo != 0)
        || (element.pedido_status_id === 7 && this.tipo == 0)
      ) {
        existePedidoEmissao = true;
      }
    });

    if (!existePedidoEmissao) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Nenhum pedido selecionado está como status ' + (this.tipo == 0 ? 'compra' : 'venda') })

    } else {

      let ids = new Array();
      let idp = new Array();

      this.pedidosSelecionados.forEach(element => {
        if (element.pedido_misto) {
          ids.push(element.id);
        } else {
          idp.push(element.id);
        }
      });


      if (confirm('Deseja realmente realizar a emissão dos pedidos?')) {

        if (ids.length > 0) {
          const listasEnviar = {
            pedidos: ids,
            usarFluxo: this.flowUses
          };

          this.httpService.wait();
          this.httpService.post('/custom/enotas/emitir-nfse-misto-lote', listasEnviar)
            .subscribe(result => {
              if (result.error === 'true') {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
              } else if (result.error === 'false') {
                this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })
              } else {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
              }
            }, error => this.httpService.handleError(error, () => this.emissaoLote()),
              () => this.httpService.done());
        }

        if (idp.length > 0) {

          const listasEnviar = {
            pedidos: idp
          };


          this.httpService.wait();
          this.httpService.post('/custom/nfe/emitir-nfe-lote', listasEnviar)
            .subscribe(result => {

              if (result.error === 'true') {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
              } else if (result.error === 'false') {
                this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })
              } else {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
              }


            }, error => this.httpService.handleError(error, () => this.emissaoLote()),
              () => this.httpService.done());
        }
      }
    }
  }

  verificarRejeicao() {

    const pedidoId = this.pedidosSelecionados[0].id;

    this.exibirModalRejeicao = true

    this.httpService.wait();
    this.httpService.get('/custom/pedido/pegar-motivo-rejeicao-pedido(' + pedidoId.toString() + ')')
      .subscribe(motivo => {
        this.descricaoMotivoRejeicao = motivo[0].descricao;
      },
        error => this.httpService.handleError(error, () => this.verificarRejeicao()),
        () => this.httpService.done());
  }

  reenviarNf() {
    if (confirm('Deseja realmente realizar a emissão deste pedido?')) {
      const listaPedidosId = this.pedidosSelecionados.map(item => item.id)

      this.httpService.wait();
      this.httpService.post('/custom/enotas/emitir-nfse-misto(' + listaPedidosId + ',' + this.flowUses + ')')
        .subscribe(result => {
          if (result.error === 'true') {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
          } else if (result.error === 'false') {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message })
            this.buscarPedidos();
          } else {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
          }
        }, error => this.httpService.handleError(error, () => this.emitirNfEletronica()),
          () => this.httpService.done());

    }
  }

  infosFormaPagto(id: number) {

    if (!id) {
      this.pedido.empresaConta = new EmpresaConta()
      this.pedido.pedidoParcela = new Array()
      return
    }

    this.permiteAlterarConta = true;

    this.httpService.wait();
    this.httpService.get('/formapagamento?$select=id,descricao,empresaConta/id,empresaConta/nome,permiteAlterarBanco'
      + '&$filter=id eq ' + id)
      .subscribe(ref => {

        let forma = new FormaPagamento();
        forma = ref.value[0];

        if (!isNullOrUndefined(forma.empresaConta)) {
          this.pedido.empresaConta = new EmpresaConta();
          this.pedido.empresaConta.id = forma.empresaConta.id;
          this.pedido.empresaConta.nome = forma.empresaConta.nome;
          this.permiteAlterarConta = forma.permiteAlterarBanco;
          this.pagamentoForma(true);
        }


      },
        error => this.httpService.handleError(error, () => this.infosFormaPagto(id)),
        () => this.httpService.done());
  }

  //SIMON LALLEMENT: Não usado
  // mudarParticipante() {
  //   this.buscarParticipante();
  // }

  //edição data de entrega item


  changeModalDeadline(type: any) {

    this.clearVarsDeadline();

    switch (type) {
      case 0:
        this.getInformations()
        this.modalDeadlineTitle = 'único'
        this.isBatch = false
        this.typeReq = 0
        break
      case 1:
        this.modalDeadlineTitle = 'lote'
        this.isBatch = true
        this.typeReq = 1
        break
    }

    this.modalDeadline = true
  }

  changeModalDataEmissao(type: any) {

    this.clearVarsDeadline();

    switch (type) {
      case 0:
        this.getInformations()
        this.modalEmissaoTitle = 'único'
        this.isBatch = false
        this.typeReq = 0
        break
      case 1:
        this.modalEmissaoTitle = 'lote'
        this.isBatch = true
        this.typeReq = 1
        break
    }

    this.modalDataEmissao = true
  }

  getInformations() {
    this.requestCode = '';
    this.requestProvider = '';
    this.requestCNPJ = '';
    this.requestCodControl = '';
    this.modalDeadlineTitle = 'único';
    this.requestItens = [];
    const listId = this.pedidosSelecionados.map(item => item.id);

    this.httpService.wait()
    this.httpService.get('/custom/pedido/buscar-infos-pedido(' + listId.toString().split('/')[0] + ')').subscribe(res => {
      this.requestCode = res.codigo
      this.requestProvider = res.participante.fantasia
      this.requestCNPJ = res.participante.cnpj
      this.requestCodControl = res.codigoControle
      this.modalDeadlineTitle = `Pedido: ${res.codigo}`
      this.requestItens = res.pedidoItens
      this.requestId = res.id
    },
      err => this.httpService.handleError(err, () => console.log(err)),
      () => this.httpService.done()
    )
  }

  saveRequest(type: number) {
    let url
    let data = {}

    if (type == 0) {
      url = 'itens'

      if (this.validationDeadline(0)) {
        return
      }

      this.requestItens.forEach(reqItem => {
        this.itensSelected.forEach(item => {
          if (item.id == reqItem.id) {
            reqItem.dataEntrega = this.formatDate(this.itemDate)
            if (this.itemObs) reqItem.observacao = this.itemObs
          }
        })
      })

      data = {
        userId: this.sessionService.loggedUser.id,
        pedidoId: this.requestId,
        items: this.requestItens,
      }
    }

    if (type == 1) {
      url = 'pedidos'

      if (this.validationDeadline(1)) {
        return
      }

      let requests = []
      this.pedidosSelecionados.forEach(elem => {
        requests.push(elem.id)
      })

      data = {
        userId: this.sessionService.loggedUser.id,
        pedidos: requests,
        dataEntrega: this.formatDate(this.itemDate),
        observacao: this.itemObs,
      }
    }

    this.httpService.wait()
    this.httpService.post(`/custom/pedido/atualizar-dados-${url}`, data).subscribe(res => {
      if (!res['error']) {
        this.messageService.add({
          severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: res.message
        });
      } else
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: res.message
        });
    },
      err => {
        this.httpService.handleError(err, () => this.buscarPedidos());
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks', summary: 'Erro:  ', detail: err
        });
        this.modalDeadline = false;
      },
      () => this.httpService.done()
    )

    this.clearVarsDeadline()
    this.changeToolbar()
  }

  // reloadRequests() {
  //   this.httpService.wait();
  //   this.httpService.get('/custom/pedido/get-pedidos-new(' + this.dblinkedSessionService.empresa.id +
  //     ',' + (this.tipo == 0 ? 'pcp' : 'pvp') + ',' + this.dataIniSearch + ','
  //     + this.dataFinSearch + ',' + this.sessionService.loggedUser.id + ',' + (this.dblinkedSessionService.filiaisSelecionadas.length === 0 ? 0 : this.dblinkedSessionService.filiaisSelecionadas) + ')').subscribe(
  //       pedidos => this.loadPedidos(pedidos.data),
  //       err => this.httpService.handleError(err, () => console.log(err)),
  //       () => this.httpService.done()
  //     );
  // }

  onItemAllSelect(event: any) {
    this.itensSelected = []
    if (event.checked) {
      this.requestItens.forEach(elem => {
        this.itensSelected.push(elem)
      })
    }
  }

  onItemSelect(event: any) {
    this.itensSelected.push(event.data)
  }

  onItemUnselect(event: any) {
    this.itensSelected = this.itensSelected.filter(line => line.id !== event.data.id)
  }

  validationDeadline(type: any) {
    if (type === 0) {
      if (this.itensSelected.length < 1) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'É preciso selecionar pelo menos 01 (um) item do pedido!' })
        return true
      }
    }

    if ((this.itemDate === null || this.itemDate === undefined)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Data de entrega é um campo obrigatório!' })
      return true
    }
  }

  clearVarsDeadline() {
    this.itensClearArray = []
    this.itensSelected = []
    this.itemDate = null
    this.itemObs = ''
  }

  formatDate(x) {
    return `${x.getDate()}/${x.getMonth() + 1}/${x.getFullYear()}`
  }

  selectAll() {
    this.valorPedidosSelecionados = 0.00
    this.pedidosSelecionados.forEach(item => {
      this.valorPedidosSelecionados += item.valor_total_itens
    })
    this.changeToolbar()
  }

  onRowSelect(event: any) {
    this.valorPedidosSelecionados = this.valorPedidosSelecionados + this.utilService.parseDecimal(event.data.valor_total_itens)
    this.changeToolbar()
  }

  onRowUnselect(event: any) {
    this.valorPedidosSelecionados = this.valorPedidosSelecionados - this.utilService.parseDecimal(event.data.valor_total_itens)
    this.changeToolbar()
  }

  changeToolbar() {

    this.toolbarMain.setAll(this.toolbarMainIni);

    const selectionSize = this.pedidosSelecionados.length;
    this.showSolicitarAprovacao = this.flowUses && this.statusCompras && selectionSize > 0 && this.pedidosSelecionados.filter(pedido => ![null, undefined, 2, 4, 10].includes(pedido.status_aprovacao_id)).length == 0;
    this.showChangeDataEntrega = this.flowUses && this.pedidosSelecionados.length > 0 && !(this.pedidosSelecionados.find(line => line.todo_card_aprovacao_id === null));

    this.toolbarMain.setVisible('emit', selectionSize >= 1);
    this.toolbarMain.setClick('emit', () => this.emissaoProdLote());
    this.tipo == 1 ? this.toolbarMain.setOptionVisible('more', 'cobranca', selectionSize > 0 && (this.statusCompras || this.statusFinalizado) && this.usaAsaas) : this.toolbarMain.setOptionVisible('more', 'cobranca', false)
    this.tipo == 1 ? this.toolbarMain.setOptionVisible('more', 'generateBill', selectionSize > 0 && this.statusCompras) : this.toolbarMain.setOptionVisible('more', 'generateBill', false);

    this.toolbarMain.setVisible('delete', selectionSize > 0);
    this.toolbarMain.setVisible('edit', selectionSize === 1);
    this.toolbarMain.setVisible('reject', this.numeroAba == 2 && selectionSize == 1);
    this.toolbarMain.setVisible('cobranca', selectionSize > 0 && (this.statusCompras || this.statusFinalizado) && this.usaAsaas && this.tipo == 1);
    this.toolbarMain.setOptionVisible('more', 'changeDeliveryDate', this.showChangeDataEntrega);
    this.toolbarMain.setOptionVisible('more', 'deadLine', (selectionSize > 0 && this.statusCompras));
    this.toolbarMain.setOptionVisible('more', 'dataEmissao', (selectionSize > 0 && this.statusCompras));
    this.toolbarMain.setOptionVisible('more', 'print', selectionSize === 1);
    this.toolbarMain.setOptionVisible('more', 'clone', selectionSize > 0);
    this.toolbarMain.setOptionVisible('more', 'status', selectionSize > 0);
    this.toolbarMain.setOptionVisible('more', 'request', this.showSolicitarAprovacao);
    this.toolbarMain.setOptionVisible('more', 'contract', ![null, undefined].includes(this.numeroAba) && (this.numeroAba == 1 || this.numeroAba == 3) && selectionSize > 0); //vend
    this.toolbarMain.setOptionVisible('more', 'sidebar', selectionSize === 1 && this.pedidosSelecionados[0].todo_card_aprovacao_id != null && this.flowUses);
    this.toolbarMain.setOptionClick('more', 'deadLine', (selectionSize === 1) ? () => this.changeModalDeadline(0) : () => this.changeModalDeadline(1));
    this.toolbarMain.setOptionClick('more', 'dataEmissao', (selectionSize === 1) ? () => this.changeModalDataEmissao(1) : () => this.changeModalDataEmissao(1));


    if (selectionSize > 0) {
      // this.toolbarMain.setProps('new', { icon: 'add', text: 'EMITIR NF', tooltip: 'EMITIR NF', visible: true, disabled: false, color: 'green', onClick: () => this.emissaoLote() });
      if (selectionSize == 1)
        this.toolbarMain.setOptionClick('more', 'clone', () => this.modalClonarPedido());
      else
        this.toolbarMain.setOptionClick('more', 'clone', () => this.modalClonePedidoLote = true);

    } else {
      this.toolbarMain.setProps('new', { icon: 'add', text: 'Novo', tooltip: 'Novo Pedido', visible: true, disabled: false, color: 'green', onClick: () => this.buscarParticipante() });
    }
  }

  removerAgencia() {
    this.agenciaId = 0;
    this.valorComissao = 0;
    this.pagamentoComissao = 0;
    this.exbirModalAgencia = false;
  }

  formataPorcentagemComissao() {

    if (this.valorComissao > 100) {
      this.valorComissao = 100;
    } else if (this.valorComissao < 0) {
      this.valorComissao = 0;
    } else {
      this.valorComissao = this.utilService.parseDecimal(this.valorComissao);
    }



  }

  rowSidebar(data: any): void {
    this.sidebarService.rowSelected([data.todo_card_aprovacao_id])
    this.sidebarService.open(true)
    this.sidebarService.selectTab(0)
  }

  referenciarCupom() {
    this.modalRefCupom = true;
  }

  async getCompanyParameter(): Promise<void> {
    try {
      await this.httpService.get('/empresa?$select=usarAprovacao&$filter=id eq ' + this.dblinkedSessionService.empresa.id).toPromise().then(res => {
        res.value.forEach(item => {
          this.flowUses = item.usarAprovacao
          if (this.flowUses) {
            this.getListStatusAprov()
            this.getParametroRms()
          }
        })
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar os paramêtros da empresa! Contate o suporte' })
    }
  }

  exibirMensagem(value: any) {
    let msgs = (value.mensagem || value.message || value.response || value.msg);
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: msgs });

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: msgs });
    }
  }

  solicitarAprovacao(reload) {
    const payload = {
      empresaId: this.dblinkedSessionService.empresa.id,
      origemId: 1,
      itemId: Array.from(new Set(this.pedidosSelecionados.map(pedido => pedido.id))),
      userId: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/workflow/criar-card-aprovacao', payload)
      .subscribe(
        result => {
          this.exibirMensagem({ error: result.error, mensagem: result.response })
          this.httpService.done();
          if (result.error) return;

          const username = this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome
          this.socketService.requestApproval(result.listTodoCardId, result.listNotificacaoId, this.sessionService.loggedUser.id, username)
          if (reload) this.buscarPedidos()
        },
        error => {
          console.log(error)
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao solicitar aprovação, contate o suporte: " + error.message })
          this.httpService.done()
        }
      );
  }

  openModalStatusEntrega() {
    this.modalStatusEntrega = true
  }

  getListStatusAprov() {
    this.httpService.get('/statusaprovacao?$select=id,codigo,descricao&$filter=statusPedido eq true and pedidoProduto eq true and empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=codigo')
      .subscribe(res => {
        this.opcoesStatusEntrega = []
        res.value.forEach(row => this.opcoesStatusEntrega.push({ label: row.codigo + " - " + row.descricao, value: row.id }))
      },
        error => {
          console.log(error)
          this.messageService.add({ severity: "error", detail: "Erro ao carregar os status de entrega, contate o suporte: " + error.message, summary: "Erro" })
        })
  }

  mudarStatusEntrega() {
    let liPedidoId: Array<number> = new Array()
    this.pedidosSelecionados.forEach(ped => liPedidoId.push(ped.id))

    const infos = {
      lipedidoId: liPedidoId,
      userId: this.sessionService.loggedUser.id,
      statusEntregaId: this.statusEntregaId
    }

    this.httpService.wait()
    this.httpService.post("/custom/pedido/mudar-status-entrega", infos)
      .subscribe(
        res => {
          if (res.error) {
            this.exibirMensagem({ error: true, mensagem: res.message })
            return
          }
          this.exibirMensagem({ error: false, mensagem: res.message })
          this.buscarPedidos()
          this.modalStatusEntrega = false
          this.statusEntregaId = null
        },
        error => {
          console.log(error)
          this.exibirMensagem({ error: true, mensagem: "Erro ao mudar os status da entrega, contate o suporte: " + error.message })
        },
        () => this.httpService.done()
      )
  }

  salvarParticipante(event: any) {
    let partSelected = event.selectedParticipant
    this.pedido.participante.id = partSelected.id
    this.pedido.participante.codigo = partSelected.cod_part
    this.pedido.participante.fantasia = partSelected.fantasia
    this.pedido.participanteEmpresaId = partSelected.part_emp_id

    this.httpService.wait();
    this.httpService.get('/custom/pedido/buscar-infos-participante(' + this.dblinkedSessionService.empresa.id + ',' +
      this.pedido.participante.id + ',' +
      (this.tipo == 0 ? 4 : 2) + ')')
      .subscribe(info => {
        if ((info[0].cidade == null) || (info[0].logradouro === "" || info[0].logradouro == null) || (info[0].numero == null) || (info[0].uf == null)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Endereço do Participante Selecionado está Incompleto!' });
        }

        this.infoParticipante = info;
        this.pedidoExterior = (info[0].uf === 'EX') ? true : false;
        this.mesmaUf = (info[0].mesmaUf);
        if (!this.mesmaUf) {
          this.aliquotaInter = info[0].aliquota;
        }
        this.aliquotaInterVenda = info[0].aliquotaVenda;
      },

        error => this.httpService.handleError(error, () => this.salvarParticipante(event)),
        () => this.httpService.done());
  }

  includeCalendarState(): void {
    this.pedido.pedidoParcela.forEach(item => {
      item.calendarState = false
    })
  }

  rowCalendarState(data: any): void {
    this.pedido.pedidoParcela.forEach(item => {
      if (item.id === data.id) item.calendarState = !item.calendarState
    })
  }

  dadosMva(event: any) {



    if (event.value === '4' && !this.pedido.consumidorFinal) {
      if (!this.pedidoItem.item.id) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: 'É necessário informar o item para calcular MVA' })
        return;
      } else {

        this.valorMva = 0;
        this.calculaValorTotalItem();

        const listasEnviar = {
          participanteEmpresaId: this.pedido.participanteEmpresaId,
          itemId: this.pedidoItem.item.id,
          ncm: this.pedidoItem.ncm,
          empresaId: this.dblinkedSessionService.empresa.id,
          consumidorFinal: false,
          valorTotal: this.pedidoItem.valorTotal,
          valorIcms: this.pedidoItem.valorIcms
        };


        this.httpService.wait();
        this.httpService.post('/custom/fiscal/buscar-mva', listasEnviar)
          .subscribe(rst => {

            this.valorMva = rst.valorMva;
            this.pedidoItem.valorBcIcmsSt = rst.baseIcmsSt;
            this.pedidoItem.aliquotaIcmsSt = rst.aliqIcmsSt;
            this.pedidoItem.valorIcmsSt = event.value === '6' ? rst.valorIcmsSt : (this.utilService.parseDecimal(rst.valorIcmsSt) - this.utilService.parseDecimal(this.pedidoItem.valorIcms));


          },
            error => this.httpService.handleError(error, () => this.dadosMva(event)),
            () => this.httpService.done());

      }

    } else if (event.value === '6' && this.pedido.consumidorFinal) {
      if (!this.pedidoItem.item.id) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: 'É necessário informar o item para calcular DIFAL' })
        return;
      } else {

        this.valorMva = 0;
        this.calculaValorTotalItem();

        const listasEnviar = {
          participanteEmpresaId: this.pedido.participanteEmpresaId,
          itemId: this.pedidoItem.item.id,
          ncm: this.pedidoItem.ncm,
          empresaId: this.dblinkedSessionService.empresa.id,
          consumidorFinal: true,
          valorTotal: this.pedidoItem.valorTotal,
          valorIcms: this.pedidoItem.valorIcms,
          aliqIcms: this.pedidoItem.aliquotaIcms
        };


        this.httpService.wait();
        this.httpService.post('/custom/fiscal/buscar-mva', listasEnviar)
          .subscribe(rst => {

            this.valorMva = rst.valorMva;
            this.pedidoItem.valorBcIcmsSt = rst.baseIcmsSt;
            this.pedidoItem.aliquotaIcmsSt = rst.aliqIcmsSt;
            this.pedidoItem.valorIcmsSt = event.value === '6' ? rst.valorIcmsSt : (this.utilService.parseDecimal(rst.valorIcmsSt) - this.utilService.parseDecimal(this.pedidoItem.valorIcms));;


          },
            error => this.httpService.handleError(error, () => this.dadosMva(event)),
            () => this.httpService.done());

      }

    }
  }

  getTaxBaseAmounts() {

    const valorIpi = this.somaIpiBc && this.pedidoItem.valorIpi ? this.pedidoItem.valorIpi : 0;
    const taxBaseAmountIcms = (this.utilService.parseDecimal(this.pedidoItem.valorTotalProduto) + this.utilService.parseDecimal(valorIpi)).toFixed(2);

    if (!this.referenciamentoNfe) {
      if (this.relacGeraImposto['icms']) this.pedidoItem.valorBcIcms = taxBaseAmountIcms;
      if (this.relacGeraImposto['ipi']) this.pedidoItem.valorBcIpi = this.utilService.parseDecimal(this.pedidoItem.valorTotalProduto);
    }
    /*if(!this.pedidoItem.operacaoRegra.utilizaBasePcXml){
      if (this.relacGeraImposto['pis']) this.pedidoItem.valorBcPis = this.utilService.parseDecimal(this.pedidoItem.valorTotalProduto);
      if (this.relacGeraImposto['cofins']) this.pedidoItem.valorBcCofins = this.utilService.parseDecimal(this.pedidoItem.valorTotalProduto);
    }*/


    //this.calculaValorIpi();

    this.valorIpi = 0.00;
    this.valorIpi = (this.utilService.parseDecimal(this.pedidoItem.valorBcIpi) *
      this.utilService.parseDecimal(this.pedidoItem.aliquotaIpi)) / 100;
    this.pedidoItem.valorIpi = this.utilService.parseMoneyString(this.valorIpi);

    //this.calculaValorIcms();

    this.pedidoItem.valorIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(this.pedidoItem.valorBcIcms) *
      this.utilService.parseDecimal(this.pedidoItem.aliquotaIcms)) / 100);


  }

  // async gerarPixQrCode() {
  //   let lipedidos: Array<number> = new Array()
  //   this.pedidosSelecionados.forEach(row => {
  //     lipedidos.push(row.id)
  //   })

  //   const infos = {
  //     origem: "PED",
  //     lititulos: lipedidos,
  //     empresa_id: this.dblinkedSessionService.empresa.id,
  //     user_id: this.sessionService.loggedUser.id
  //   }

  //   this.httpService.wait()
  //   this.httpService.post(this.configService.octaremoteUrl + "/matera/qrcode", infos)
  //     .subscribe(
  //       res => {
  //         if (res.codigo === 500) {
  //           this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res.message })
  //           this.httpService.done()
  //           return

  //         } else if (res.codigo === 400) {
  //           this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res.message })
  //           this.httpService.done()
  //           return

  //         } else {
  //           this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res.message })
  //           res.data.forEach(arquivo => {
  //             const httpOptions2 = {
  //               headers: new HttpHeaders({
  //                 'Authorization': this.configService.bearerApi,
  //                 'Cache-Control': 'no-store, max-age=0'
  //               }),
  //               responseType: 'blob' as 'json'
  //             }

  //             this.httpClient.get(this.configService.octaremoteUrl + "/matera/qrcode?userId=" + this.sessionService.loggedUser.id + "&nome=" + arquivo.toString(), httpOptions2)
  //               .subscribe(
  //                 (res: Blob) => {
  //                   let link = document.createElement('a')
  //                   let url = window.URL.createObjectURL(res)
  //                   link.href = url
  //                   link.download = arquivo
  //                   link.click()
  //                   window.URL.revokeObjectURL(url)
  //                   this.httpService.done()
  //                 },
  //                 err => {
  //                   console.log(err)
  //                   this.exibirMensagem({ error: true, mensagem: "Erro no API ao baixar o QRCode " + arquivo.toString() + ", contate o suporte: " + err.message })
  //                   this.httpService.done()
  //                 }
  //               )
  //             new Promise(async () => await this.delay(2000))
  //           })
  //         }
  //       },
  //       err => {
  //         console.log(err)
  //         this.exibirMensagem({ error: true, mensagem: "Erro no API ao gerar os QRCodes, contate o suporte: " + err.message })
  //         this.httpService.done()
  //       }
  //     )
  // }

  protected async delay(milliseconds: number): Promise<void> {
    return new Promise(function (resolve) { setTimeout(resolve, milliseconds) })
  }

  async loadConfigApi(): Promise<void> {
    this.skeletonConfig.startLoad()
    this.usaAsaas = false
    try {
      await this.httpService.get('/configuracoesapis?$select=codigo,ativo&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
        .toPromise()
        .then(
          res => {
            res.value.forEach(row => {
              if (row.codigo === 'ASAAS' && row.ativo) this.usaAsaas = true
            })
          })
        .catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.message }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao recuperar a configuração API da empresa, contate o suporte: " + err.message })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

  //SIMON LALLEMENT: Não usado
  // loadTiposPagamentos() {
  //   this.tiposPagamentos = []
  //   this.tiposPagamentos.push({ label: 'Selecione', value: null })
  //   this.httpService.get('/tipospagamentos?$select=id,codigo,descricao&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' &$orderby=codigo')
  //     .subscribe(ref => {
  //       ref.value.forEach(element => {
  //         this.tiposPagamentos.push({ label: (element.codigo + ' - ' + element.descricao), value: element.id });
  //       });
  //     },
  //       error => this.httpService.handleError(error, () => this.loadTiposPagamentos()))

  // }

  setTipoPagamento(row: any) {
    this.tiposPagamentos.forEach(element => {
      if (element.value === row.tipoPagamentoId) {
        row.tipoPagamentoDesc = element.label
      }
    })

    this.pedido.pedidoParcela.forEach(element => {
      if (element.parcela > row.parcela) {
        element.tipoPagamentoId = row.tipoPagamentoId
        element.tipoPagamentoDesc = row.tipoPagamentoDesc
      }
    })
  }

  // async getQuantidadePedidos() {
  //   const tipo = (this.tipo == 0 ? 'pcp' : 'pvp')
  //   const payload = {
  //     empresaId: this.dblinkedSessionService.empresa.id,
  //     tipo: tipo,
  //     dataInicial: this.dataIniSearch,
  //     dataFinal: this.dataFinSearch,
  //     userId: this.sessionService.loggedUser.id,
  //     filiaisSelecionadas: this.dblinkedSessionService.filiaisSelecionadas
  //   }


  //   try {
  //     await this.httpService.post('/custom/pedido/get-pedidos-infos-gerais', payload).toPromise().then(res => {
  //       this.totalPedidosNegociacao = this.totalPedidosNegociacaoPaginacao = res.data.emAndamento.quantidade
  //       this.totalPedidosRecusado = this.totalPedidosRecusadoPaginacao = res.data.recusado.quantidade
  //       this.totalPedidosFinalizado = this.totalPedidosFinalizadoPaginacao = res.data.finalizado.quantidade
  //       this.totalPedidosComprasVendas = this.totalPedidosComprasVendasPaginacao = res.data.vendaOuCompra.quantidade


  //       this.valorTotalPedidosNegociacao = res.data.emAndamento.valor ? res.data.emAndamento.valor : 0
  //       this.valorTotalPedidosComprasVendas = res.data.vendaOuCompra.valor ? res.data.vendaOuCompra.valor : 0
  //       this.valorTotalPedidosRecusado = res.data.recusado.valor ? res.data.recusado.valor : 0
  //       this.valorTotalPedidosFinalizado = res.data.finalizado.valor ? res.data.finalizado.valor : 0

  //       this.definirValorTotalPedidos()

  //     }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.status + ' - Houve um erro ao carregar a quantidade de pedidos!' }))
  //   } catch (err) {
  //     this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
  //   }
  // }

  async getPedidos(event: LazyLoadEvent = {}): Promise<void> {
    this.skeletonConfig.startLoad()

    const tipo = (this.tipo == 0 ? 'pcp' : 'pvp')
    const statusPesquisado = this.statusPesquisado
    //SIMON LALLEMENT 09/12/2022: nova rota do LazyLoad pelo Python
    const payload = {
      dataInicial: this.dataIniSearch,
      dataFinal: this.dataFinSearch,
      empresaId: this.dblinkedSessionService.empresa.id,
      filiaisSelecionadas: this.dblinkedSessionService.filiaisSelecionadas,
      statusPesquisado: statusPesquisado,
      tipo: tipo,
      usaAprovacao: this.flowUses,
      usaAsaas: this.usaAsaas,
      filtro_data: this.filtroData,
      visualiza_outros_pedidos: this.visualizaOutrosPedidos,
      vendedor_id: this.userVendedorId,
      limit: event.rows,
      offset: event.first,
      sort_order: event.sortOrder,
      sort_field: event.sortField,
      filters: event.filters
    }

    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }

    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'pedido/listar-pedidos', payload, httpOptions).toPromise()
        .then(
          res => {
            this.redefinirListaPedidos(statusPesquisado)
            this.definirValorTotalPedidos(res["data"]["total"], res["data"]["valor"], res["data"]["totalAbas"])
            this.popularInformacoesPedidos(res["data"]["lista"])

          })
        .catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err["message"] + ": " + err["data"] }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro no API ao buscar os pedidos, contate o suporte: ' + err.message })
    } finally {
      this.skeletonConfig.endLoad()
    }

    // const payload = {
    //   empresaId: this.dblinkedSessionService.empresa.id,
    //   tipo: tipo,
    //   dataInicial: this.dataIniSearch,
    //   dataFinal: this.dataFinSearch,
    //   userId: this.sessionService.loggedUser.id,
    //   offset: event.first,
    //   sort_order: event.sortOrder,
    //   sort_field: event.sortField,
    //   filters: event.filters,
    //   statusPesquisado: this.statusPesquisado,
    //   filiaisSelecionadas: this.dblinkedSessionService.filiaisSelecionadas,
    //   limit: event.rows
    // }

    // try {
    //   await this.httpService.post('/custom/pedido/get-pedidos', payload).toPromise().then(res => {
    //     this.redefinirListaPedidos()
    //     this.definirQuantidadeValorTipoPedidoAtual(res.qtPedidoFiltrado)

    //     this.popularInformacoesPedidos(res.data)

    //   }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.status + ' - Houve um erro ao carregar os Pedidos!' }))
    // } catch (err) {
    //   this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    // } finally {
    //   this.skeletonConfig.endLoad()
    // }
  }

  definirValorTotalPedidos(quantidade: number, valor: number, outrosValores) {
    switch (this.telaAtual) {
      case 'TELA_1':
        this.totalPedidosNegociacaoPaginacao = quantidade
        this.valorTotalPedidos = this.valorTotalPedidosNegociacao = valor
        break
      case 'TELA_2':
        this.totalPedidosComprasVendasPaginacao = quantidade
        this.valorTotalPedidos = this.valorTotalPedidosComprasVendas = valor
        break
      case 'TELA_3':
        this.totalPedidosRecusadoPaginacao = quantidade
        this.valorTotalPedidos = this.valorTotalPedidosRecusado = valor
        break
      case 'TELA_4':
        this.totalPedidosFinalizadoPaginacao = quantidade
        this.valorTotalPedidos = this.valorTotalPedidosFinalizado = valor
        break
    }

    for (let status in outrosValores) {
      if (status == "negociacao") this.totalPedidosNegociacao = outrosValores[status]
      else if (status == "venda") this.totalPedidosComprasVendas = outrosValores[status]
      else if (status == "recusado") this.totalPedidosRecusado = outrosValores[status]
      else if (status == "finalizado") this.totalPedidosFinalizado = outrosValores[status]
    }
  }

  redefinirListaPedidos(statusPesquisado: string) {
    if (statusPesquisado === 'negociacao') this.pedidosNegociacao = []
    else if (statusPesquisado === "venda") this.pedidosCompraVenda = []
    else if (statusPesquisado === "cancelado") this.pedidosRecusados = []
    else if (statusPesquisado === "finalizado") this.pedidosFinalizados = []
  }

  // definirQuantidadeValorTipoPedido(quantidade: number, valor: number) {
  //   switch (this.telaAtual) {
  //     case 'TELA_1':
  //       this.totalPedidosNegociacao = this.totalPedidosNegociacaoPaginacao = quantidade
  //       this.valorTotalPedidosNegociacao = valor
  //       break
  //     case 'TELA_2':
  //       this.totalPedidosComprasVendas = this.totalPedidosComprasVendasPaginacao = quantidade
  //       this.valorTotalPedidosComprasVendas = valor
  //       break
  //     case 'TELA_3':
  //       this.totalPedidosRecusado = this.totalPedidosRecusadoPaginacao = quantidade
  //       this.valorTotalPedidosRecusado = valor
  //       break
  //     case 'TELA_4':
  //       this.totalPedidosFinalizado = this.totalPedidosFinalizadoPaginacao = quantidade
  //       this.valorTotalPedidosFinalizado = valor
  //       break
  //   }
  // }

  popularInformacoesPedidos(dadosPedidos) {
    dadosPedidos.forEach(pedido => {
      if (pedido.pedido_status_id === 1 || pedido.pedido_status_id === 8 || pedido.pedido_status_id == 9) {
        this.pedidosNegociacao.push(pedido);

      } else if (pedido.pedido_status_id === 2 || pedido.pedido_status_id === 4 || pedido.pedido_status_id === 7
        || pedido.pedido_status_id === 12 || pedido.pedido_status_id === 13 || pedido.pedido_status_id == 15) {
        this.pedidosCompraVenda.push(pedido);

      } else if (pedido.pedido_status_id === 3 || pedido.pedido_status_id === 14) {
        this.pedidosRecusados.push(pedido);

      } else if (pedido.pedido_status_id === 5) {
        this.pedidosFinalizados.push(pedido);
      }
      // if (this.statusNegociacao) this.pedidosNegociacao.push(pedido)
      // else if (this.statusCompras) this.pedidosCompraVenda.push(pedido)
      // else if (this.statusRecusado) this.pedidosRecusados.push(pedido)
      // else if (this.statusFinalizado) this.pedidosFinalizados.push(pedido)
    })

    // this.definirValorTotalPedidos()
  }

  determinarPrimeiraChamada() {
    switch (this.telaAtual) {
      case 'TELA_1':
        this.statusPesquisado = "negociacao"

        if (this.primeiraChamadaNegociacao) {
          this.primeiraChamadaNegociacao = false
          this.pedidosNegociacao = new Array()
          this.getPedidos()
        }

        break;
      case 'TELA_2':
        this.statusPesquisado = "venda"

        if (this.primeiraChamadaComprasVendas) {
          this.primeiraChamadaComprasVendas = false
          this.pedidosCompraVenda = new Array()
          this.getPedidos()
        }

        break;
      case 'TELA_3':
        this.statusPesquisado = "cancelado"

        if (this.primeiraChamadaRecusado) {
          this.primeiraChamadaRecusado = false
          this.pedidosRecusados = new Array()
          this.getPedidos()
        }

        break;
      case 'TELA_4':
        this.statusPesquisado = "finalizado"

        if (this.primeiraChamadaFinalizado) {
          this.primeiraChamadaFinalizado = false
          this.pedidosFinalizados = new Array()
          this.getPedidos()
        }

        break;
    }
  }

  escritLote() {
    let podeEscriturar = true

    if (this.pedidosSelecionados.length === 0) {

      alert('É necessário selecionar pelo menos um pedido para escrituração');

    } else {
      this.pedidosSelecionados.forEach(pedido => {
        if (pedido.pedido_status_id === 15) podeEscriturar = false
      })

      if (!podeEscriturar) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: "Não é possível escriturar pedidos em status SALVANDO." })
        return
      }

      if (confirm('Deseja realmente escriturar estes ' + this.pedidosSelecionados.length + ' pedido(s) ?')) {

        const listasEnviar = {
          pedido: JSON.stringify(this.pedido, null, ''),
          empresa: this.dblinkedSessionService.empresa.id,
          usuario: this.sessionService.loggedUser.id,
          pedidoSelecionados: this.pedidosSelecionados
        };

        this.httpService.wait();
        this.httpService.post('/custom/pedido/escriturar-pedido-lote', listasEnviar)
          .subscribe(result => {

            this.download(result, 'ESCRITURADO(S)');
            this.getPedidos()

          },
            error => this.httpService.handleError(error, () => this.escritLote()),
            () => this.httpService.done());
      }

    }

  }

  download(result?: any, tipo?: string) {

    if (result.file !== 'NA') {

      if (result.qtdeErro > 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: (result.qtdeErro + ' ERRO(S) ENCONTRADO(S)') });
      }

      window.open(
        this.httpService.url("/custom/file/" + result.file),
        "_blank"
      );
    } else {
      if (result.qtdeErro > 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: (result.qtdeErro + ' ERRO(S) ENCONTRADO(S)') });
      }
      if (result.qtdeSucesso > 0) {
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Advertência', detail: (result.qtdeSucesso + ' PEDIDO(S) ' + tipo) });
      }

    }
  }

  atualizarRelatorio() {
    let params: LazyLoadEvent = {}
    if (this.statusNegociacao) {
      this.dt10.reset()
      this.dt10.clearState()
      params.rows = this.dt10._rows
      this.statusPesquisado = "negociacao"
      this.getPedidos(params)

    } else if (this.statusCompras) {
      this.dt20.reset()
      this.dt20.clearState()
      params.rows = this.dt20._rows
      this.statusPesquisado = "venda"
      this.getPedidos(params)

    } else if (this.statusRecusado) {
      this.dt30.reset()
      this.dt30.clearState()
      params.rows = this.dt30._rows
      this.statusPesquisado = "cancelado"
      this.getPedidos(params)

    } else if (this.statusFinalizado) {
      this.dt40.reset()
      this.dt40.clearState()
      params.rows = this.dt40._rows
      this.statusPesquisado = "finalizado"
      this.getPedidos(params)
    }

  }

  emissaoProdLote() {

    if (this.pedidosSelecionados.length === 0) {

      alert('É necessário selecionar pelo menos um pedido para emissão');

    } else {

      if (confirm('Deseja realmente emitir este(s) ' + this.pedidosSelecionados.length + ' pedido(s) ?')) {

        const listasEnviar = {
          pedido: JSON.stringify(this.pedido, null, ''),
          empresa: this.dblinkedSessionService.empresa.id,
          usuario: this.sessionService.loggedUser.id,
          pedidoSelecionados: this.pedidosSelecionados
        };

        this.httpService.wait();
        this.httpService.post('/custom/nfe/emissao-produto-lote', listasEnviar)
          .subscribe(result => {

            this.download(result, 'EMITIDO(S)');

          },
            error => this.httpService.handleError(error, () => this.emissaoProdLote()),
            () => this.httpService.done());
      }

    }

  }

  calcularValorDifalItem() {

    if (this.pedido.pedidoTipo.id === 1) {

      this.pedidoItem.valorOperSemIcmsDifal = this.utilService.parseDecimal(0.00);

      this.pedidoItem.aliqInternaDifal = this.utilService.parseDecimal(0.00);
      this.pedidoItem.aliqInterestadualDifal = this.utilService.parseDecimal(0.00);

      this.pedidoItem.valorOperComIcmsDifal = this.utilService.parseDecimal(0.00);

      this.pedidoItem.icmsOrigemCreditoDifal = this.utilService.parseDecimal(0.00);
      this.pedidoItem.icmsDestinoDebitoDifal = this.utilService.parseDecimal(0.00);

      this.pedidoItem.valorRecolherDifal = this.utilService.parseDecimal(0.00);
    } else {
      if (!isNullOrUndefined(this.ieEmpresa)) {
        if (this.ieEmpresa != 'ISENTO' && this.ieEmpresa != 'ISENTA' && this.ieEmpresa != ' ') {


          this.pedidoItem.valorOperSemIcmsDifal = this.utilService.parseDecimal(this.pedidoItem.valorTotal);

          this.pedidoItem.aliqInternaDifal = this.utilService.parseDecimal(this.pedidoItem.aliqInternaDifal);
          this.pedidoItem.aliqInterestadualDifal = this.utilService.parseDecimal(this.pedidoItem.aliqInterestadualDifal);

          this.pedidoItem.valorOperComIcmsDifal = this.utilService.parseDecimal((this.utilService.parseDecimal(this.pedidoItem.valorOperSemIcmsDifal) / (100 - this.utilService.parseDecimal(this.pedidoItem.aliqInternaDifal))) * 100);

          this.pedidoItem.icmsOrigemCreditoDifal = this.utilService.parseDecimal((this.utilService.parseDecimal(this.pedidoItem.valorOperComIcmsDifal) * this.utilService.parseDecimal(this.pedidoItem.aliqInterestadualDifal)) / 100);
          this.pedidoItem.icmsDestinoDebitoDifal = this.utilService.parseDecimal((this.utilService.parseDecimal(this.pedidoItem.valorOperComIcmsDifal) * this.utilService.parseDecimal(this.pedidoItem.aliqInternaDifal)) / 100);

          this.pedidoItem.valorRecolherDifal = this.utilService.parseDecimal(this.utilService.parseDecimal(this.pedidoItem.icmsDestinoDebitoDifal) - this.utilService.parseDecimal(this.pedidoItem.icmsOrigemCreditoDifal));

        }
      }
    }


  }

  calcularValorDifal() {

    if (!isNullOrUndefined(this.ieEmpresa)) {
      if (this.ieEmpresa != 'ISENTO' && this.ieEmpresa != 'ISENTA' && this.ieEmpresa != ' ') {
        this.pedidoItem.valorOperComIcmsDifal = this.utilService.parseDecimal((this.utilService.parseDecimal(this.pedidoItem.valorOperSemIcmsDifal) / (100 - this.utilService.parseDecimal(this.pedidoItem.aliqInternaDifal))) * 100);

        this.pedidoItem.icmsOrigemCreditoDifal = this.utilService.parseDecimal((this.utilService.parseDecimal(this.pedidoItem.valorOperComIcmsDifal) * this.utilService.parseDecimal(this.pedidoItem.aliqInterestadualDifal)) / 100);
        this.pedidoItem.icmsDestinoDebitoDifal = this.utilService.parseDecimal((this.utilService.parseDecimal(this.pedidoItem.valorOperComIcmsDifal) * this.utilService.parseDecimal(this.pedidoItem.aliqInternaDifal)) / 100);

        this.pedidoItem.valorRecolherDifal = this.utilService.parseDecimal(this.utilService.parseDecimal(this.pedidoItem.icmsDestinoDebitoDifal) - this.utilService.parseDecimal(this.pedidoItem.icmsOrigemCreditoDifal));
      }
    }

  }

  async gerarCobranca(mandarEmail: boolean = false) {
    let lipedidos: Array<number> = new Array()
    this.pedidosSelecionados.forEach(row => {
      lipedidos.push(row.id)
    })

    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    const infos = {
      origem: "PED",
      lititulos: lipedidos,
      empresa_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id,
      mandarEmail: mandarEmail
    }

    this.httpService.wait()
    this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Info', detail: 'Processamento das faturas... Favor aguarde.' })
    this.httpClient.post(this.configService.octaremoteUrl + "/asaas/cobranca", infos, httpOptions)
      .subscribe(
        res => {
          if (res["codigo"] === 500) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] })
          else if (res["codigo"] === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res["message"] })
          else {
            if (mandarEmail) this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res["message"] })
            else {
              res["data"].forEach(url => {
                window.open(url)
              })
              this.getPedidos()
            }
          }
          this.httpService.done()
        },
        err => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao processar as faturas de cobrança, contate o suporte: " + err.message })
          this.httpService.done()
        }
      )
  }

  buscarPedidos() {

    this.vendedorId = 0;
    //this.alterarPeriodo();
    this.modalPeriodo = false;
    this.exibirPedido = false;
    this.exibirModalContrato = false;
    this.exibirModalRejeicao = false;

    this.botaoEditar = false;

    this.valorPedidosSelecionados = 0.00;
    this.valorTotalPedidos = 0.00;

    this.valorTotalPedidosNegociacao = 0
    this.valorTotalPedidosRecusado = 0
    this.valorTotalPedidosFinalizado = 0
    this.valorTotalPedidosComprasVendas = 0

    this.totalPedidosNegociacao = 0
    this.totalPedidosRecusado = 0
    this.totalPedidosFinalizado = 0
    this.totalPedidosComprasVendas = 0
    // this.statusPesquisado = null

    this.totalPedidosNegociacaoPaginacao = 0
    this.totalPedidosRecusadoPaginacao = 0
    this.totalPedidosFinalizadoPaginacao = 0
    this.totalPedidosComprasVendasPaginacao = 0

    this.isReadOnly = false;

    this.subTotalPedido = this.utilService.parseMoneyString(0.00);
    this.valorDescontoItens = this.utilService.parseMoneyString(0.00);
    this.valorImpostosRetidos = this.utilService.parseMoneyString(0.00);
    this.valorTotalPedido = this.utilService.parseMoneyString(0.00);

    this.todosSelecionados1 = false;
    this.todosSelecionados2 = false;
    this.todosSelecionados3 = false;
    this.todosSelecionados4 = false;

    this.idPedidoView = [];
    this.pedidosSelecionados = [];
    this.pedido = new Pedido();
    this.exibirRateio = false;
    this.requestApproval = false;

    let dataIni = `${this.dataIniSearch.substring(8, 10)}/${this.dataIniSearch.substring(5, 7)}/${this.dataIniSearch.substring(0, 4)}`,
      dataFin = `${this.dataFinSearch.substring(8, 10)}/${this.dataFinSearch.substring(5, 7)}/${this.dataFinSearch.substring(0, 4)}`;

    this.subtitle = `PERÍODO SELECIONADO: ${dataIni} à ${dataFin}`

    // this.getQuantidadePedidos();
    this.primeiraChamadaNegociacao = true;
    this.primeiraChamadaComprasVendas = true;
    this.primeiraChamadaRecusado = true;
    this.primeiraChamadaFinalizado = true;
    this.determinarPrimeiraChamada();
  }

  async verificarVendedor(): Promise<void> {
    this.skeletonConfig.startLoad()
    try {
      await this.httpService.get('/vendedor?$select=visualizaOutrosVendedores,id&$filter=empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) +
        " and email eq '" + this.sessionService.loggedUser.mail + "'")
        .toPromise()
        .then(
          vend => {
            vend.value.forEach(
              element => {
                if (vend.value[0].visualizaOutrosVendedores === false) this.visualizaOutrosPedidos = false
                this.userVendedorId = vend.value[0].id
              })
          })
        .catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao recuperar os dados do vendedor, contate o suporte: " + err.message }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao recuperar os dados do vendedor, contate o suporte: " + err.message })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

  verificarCfop() {
    if (this.pedidoItem.cfop.startsWith('2')) {
      if (this.pedidoItem.cfop !== '2551' && this.pedidoItem.cfop !== '2556') {
        this.pedidoItem.valorOperSemIcmsDifal = 0.00;
        this.pedidoItem.aliqInternaDifal = 0.00;
        this.pedidoItem.aliqInterestadualDifal = 0.00;
        this.pedidoItem.valorOperComIcmsDifal = 0.00;
        this.pedidoItem.icmsOrigemCreditoDifal = 0.00;
        this.pedidoItem.icmsDestinoDebitoDifal = 0.00;
        this.pedidoItem.valorRecolherDifal = 0.00;
      } else {
        this.pedidoItem.aliqInternaDifal = this.aliquotaInterna;
        this.pedidoItem.aliqInterestadualDifal = this.aliquotaInter;
      }
    }
  }

  changeToolbarXml() {
    this.toolbarXml.setVisible("voltar", this.vincularItens)
    this.toolbarXml.setVisible("save-back", this.vincularItens)
    this.toolbarXml.setVisible("save-create", this.vincularItens)
  }

  vincularItensXml(row: any) {
    this.listarXml = false
    this.vincularItens = true
    this.xmlSelecionado = row
    this.changeToolbarXml()

    this.skeletonConfigItens.startLoad()
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'pedido/itens-xml?nfeId=' + row.id, this.httpOptions)
      .subscribe(res => {
        this.itensNfsParticipantes = new Array()
        res["data"].forEach(row => this.itensNfsParticipantes.push(row))
        this.skeletonConfigItens.endLoad()
      },
        (error: HttpErrorResponse) => {
          console.log(error)
          if (error.error.message)
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: error.error.message })

          else {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a relação dos itens, contate o suporte: ' + error.message })
          }
          this.skeletonConfigItens.endLoad()
        }
      )
  }

  checkVinculacaoXml() {
    this.itensNfsParticipantes.forEach(row => {
      if (!row.item_id || !row.operacao_regra_id || !row.fator) row.relacionado = false
      else if (row.item_id && row.operacao_regra_id || !row.fator) row.relacionado = true
    })
  }

  replicarItensXml(row: any) {
    this.opcoesItens.forEach(element => {
      if (element.value === row.item_id) {
        row.item_relac = element.label
        return
      }
    })

    if (this.itensXmlSelecionados.length > 1) {
      if (confirm("Deseja replicar esse item para a seleção?")) {
        //Trocar o id para todos os mesmos itens da nota
        this.itensXmlSelecionados.forEach(element => {
          if (element.item_id_f === row.item_id_f) {
            element.item_id = row.item_id
            element.item_relac = row.item_relac
            if (row.operacao_regra_id) {
              element.operacao_regra_id = row.operacao_regra_id
              element.operacao = row.operacao
            }
          }
        })

        this.itensXmlSelecionados.forEach(element => {
          element.item_id = row.item_id
          element.item_relac = row.item_relac
          if (row.operacao_regra_id) {
            element.operacao_regra_id = row.operacao_regra_id
            element.operacao = row.operacao
          }
        })
      }
    }

    this.checkVinculacaoXml()
  }

  replicarOperacaoXml(row: any) {
    this.opcoesOperacao.forEach(element => {

      if (element.value === row.operacao_regra_id) {
        row.operacao = element.label
        return
      }
    })


    //Trocar a operacao para todos os mesmos itens da nota
    this.itensNfsParticipantes.forEach(element => {

      if (element.item_id === row.item_id) {
        element.operacao_regra_id = row.operacao_regra_id
        element.operacao = row.operacao
        if (row.utiliza_base_pc_xml == true) {
          element.utiliza_base_pc_xml = row.utiliza_base_pc_xml
        }

      }
    })

    if (this.itensXmlSelecionados.length > 1) {
      if (confirm("Deseja replicar essa operação para a seleção?")) {
        this.itensXmlSelecionados.forEach(element => {
          element.operacao_regra_id = row.operacao_regra_id
          element.operacao = row.operacao
          if (row.utiliza_base_pc_xml == true) {
            element.utiliza_base_pc_xml = row.utiliza_base_pc_xml
          }
        })
      }
    }

    this.checkVinculacaoXml()
  }

  voltarListaXml() {
    this.listarXml = true
    this.vincularItens = false
    this.referenciarNfe()
    this.changeToolbarXml()
  }

  salvarVinculacaoXml(voltar: boolean) {
    const infos = { lista: this.itensNfsParticipantes }

    this.httpService.wait()
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'pedido/itens-xml?nfeId=' + this.xmlSelecionado.id, infos, this.httpOptions)
      .subscribe(res => {
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: res["message"] })
        this.httpService.done()
        if (voltar) this.voltarListaXml()
        else {
          let allVinculado = true
          this.itensNfsParticipantes.forEach(row => {
            if (!row.relacionado) allVinculado = false
          })
          if (allVinculado) this.popularPedidoFromItensXml(this.xmlSelecionado, this.itensNfsParticipantes)
          else this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Os itens do XML precisam ser todos vinculados.' })
        }
      },
        error => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a relação dos itens, contate o suporte: ' + error.message })
          this.httpService.done()
        }
      )
  }

  popularPedidoFromXml(nfe: any) {
    if (!nfe.vinculado) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Os itens do XML precisam ser todos vinculados.' })
      return
    }

    this.httpService.wait()
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'pedido/itens-xml?nfeId=' + nfe.id, this.httpOptions)
      .subscribe(res => {
        this.httpService.done()
        let itensLista = new Array()
        res["data"].forEach(row => itensLista.push(row))
        this.httpService.wait()
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: '', detail: 'Populando o pedido...' })
        this.popularPedidoFromItensXml(nfe, itensLista, false)
      },
        error => {
          this.httpService.done()
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a relação dos itens, contate o suporte: ' + error.message })
        }
      )
  }

  popularPedidoFromItensXml(infosNfe: any, itensNfe: Array<any>, direct: boolean = true) {
    if (direct) {
      this.httpService.wait()
      this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: '', detail: 'Populando o pedido...' })
    }
    this.modalReferenciarNfe = false
    this.referenciamentoNfe = true
    this.pedido.pedidoStatus.id = 7
    this.pedido.modNf.id = 22
    this.pedido.numNf = infosNfe.document.toString()
    this.pedido.serie = infosNfe.series
    this.pedido.dtEntrega = new Date()
    this.pedido.dtEmissao = infosNfe.emission
    this.pedido.dtFaturamentoAutomatico = new Date()
    this.pedido.chvNfe = infosNfe.key
    this.pedido.pedidoItem = new Array()
    itensNfe.forEach(item => {



      let pedidoItem = new PedidoItem()

      pedidoItem.numSequence = item.seq
      pedidoItem.id = item.seq
      pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA()
      pedidoItem.cstIcmsTabelaB = new CstIcmsTabelaB()
      pedidoItem.cstPis = new CstPiscofins()
      pedidoItem.cstCofins = new CstPiscofins()
      pedidoItem.cstIpi = new CstIpi()
      pedidoItem.pedidoItensRateio = new Array()
      pedidoItem.itemFornecedor = null
      pedidoItem.valorDesconto = 0
      pedidoItem.valorFrete = 0
      pedidoItem.ufDesembaraco = new Uf()
      pedidoItem.ufAdquirente = new Uf()
      pedidoItem.transporteTipo = new TransporteTipo()
      pedidoItem.tipoIntermediacao = new TipoIntermediacao()
      pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado()
      pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado()
      pedidoItem.tipoDesconto = new TipoDesconto()
      pedidoItem.tipoDesconto.id = 2
      pedidoItem.tipoDesconto.cod = 'R$'
      if (!this.ieEmpresa) {
        if (this.ieEmpresa != 'ISENTO' && this.ieEmpresa != 'ISENTA' && this.ieEmpresa != ' ') {
          pedidoItem.aliqInternaDifal = this.aliquotaInterna
          pedidoItem.aliqInterestadualDifal = this.aliquotaInter
        }
      }

      //Operação
      pedidoItem.operacaoRegra = new OperacaoRegra()
      pedidoItem.operacaoRegra.id = item.operacao_regra_id
      pedidoItem.operacaoRegra.operacaoRegraContabil = new OperacaoRegraContabil()
      pedidoItem.operacaoRegra.operacaoRegraFiscal = new OperacaoRegraFiscal()
      pedidoItem.operacaoRegra.operacaoRegraContabil.tipoRegraContabil = new TipoRegraContabil()
      pedidoItem.operacaoRegra.utilizaBasePcXml = item.utiliza_base_pc_xml;


      //Itens
      pedidoItem.item = new Item()
      pedidoItem.item.id = item.item_id
      pedidoItem.unidadeMedida = new UnidadeMedida()
      pedidoItem.unidadeMedida.id = item.unidade_id
      pedidoItem.unidadeMedida.codigo = item.unidade_cod
      pedidoItem.unidadeMedidaTributavel = new UnidadeMedida()
      pedidoItem.unidadeMedidaTributavel.id = item.unidade_id
      pedidoItem.unidadeMedidaTributavel.codigo = item.unidade_cod

      //Valores
      pedidoItem.quantidade = item.qtde_final
      pedidoItem.valorUnit = this.pedido.nfeFinalidade.id === 2 ? item.valor_tot : Math.round(item.valor_unit * item.qtde / item.qtde_final * 10000) / 10000
      pedidoItem.valorDesconto = item.v_desconto
      pedidoItem.valorFrete = item.v_frete
      pedidoItem.valorOutro = item.v_outros
      pedidoItem.valorIpi = item.v_ipi
      pedidoItem.valorBcIpi = item.v_bc_ipi
      pedidoItem.aliquotaIpi = item.p_ipi
      pedidoItem.valorIcms = item.v_icms
      pedidoItem.valorBcIcms = item.v_bc_icms
      pedidoItem.aliquotaIcms = item.p_icms
      pedidoItem.valorBcIcmsSt = item.v_bc_icms_st
      pedidoItem.valorIcmsSt = item.v_icms_st
      pedidoItem.valorCredSn = item.v_cred_icms_sn
      pedidoItem.aliqCredSn = item.p_cred_sn

      if (this.excluiIcmsPisCofinsCompra && item.utiliza_base_pc_xml) {
        pedidoItem.valorBcPis = this.utilService.parseDecimal(item.v_bc_pis) - this.utilService.parseDecimal(item.v_icms)
        pedidoItem.perAliquotaPis = item.p_pis
        pedidoItem.valorBcCofins = this.utilService.parseDecimal(item.v_bc_pis) - this.utilService.parseDecimal(item.v_icms)
        pedidoItem.perAliquotaCofins = item.p_cof
      }

      this.pedido.pedidoItem.push(pedidoItem)
    })

    this.pedido.pedidoItem.forEach(item => {
      this.dadosItem2(item, true)
    })

    //Calcular valor total pedido
    this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil)
    this.verificaOperacaoRegraGeraFinanceiro(true)

    //Verificar os CFOP
    this.buscarCfopCorretos(infosNfe, itensNfe)

    this.httpService.done()
  }

  dadosItem2(pedidoItem: PedidoItem, bool: boolean) {

    this.httpService.wait()
    this.httpService.get('custom/cadastro/get-dados-item', [pedidoItem.item.id])
      .subscribe(retorno => {
        this.opcoesCapituloTipi = []
        if (!isNullOrUndefined(retorno.ncm)) {
          this.opcoesCapituloTipi.push({ label: retorno.ncm + ' - ' + retorno.ncmDescricao, value: retorno.ncm });
        }
        pedidoItem.item = new Item();
        pedidoItem.item.id = retorno.id;
        pedidoItem.item.codigo = retorno.codigo;
        pedidoItem.item.descricao = retorno.descricao;
        pedidoItem.ncm = isNullOrUndefined(retorno.ncm) ? '' : retorno.ncm;

        if (this.buscarCustoMedio) {
          pedidoItem.valorUnit = retorno.valorCusto;
        }

        if (this.buscarValorVenda) {
          pedidoItem.valorUnit = retorno.valorVenda;
        }

        if (!isNullOrUndefined(retorno.unidadeMedidaId) && !pedidoItem.unidadeMedida.id) {
          pedidoItem.unidadeMedida = new UnidadeMedida();
          pedidoItem.unidadeMedida.id = retorno.unidadeMedidaId;
          pedidoItem.unidadeMedida.codigo = retorno.codigoUnidadeMedida;

          this.unidadeMedidaOriginalId = retorno.unidadeMedidaId;
          this.codigoUnidadeMedidaOriginal = retorno.codigoUnidadeMedida;

          pedidoItem.unidadeMedidaTributavel = new UnidadeMedida();
          pedidoItem.unidadeMedidaTributavel.id = retorno.unidadeMedidaId;
          pedidoItem.unidadeMedidaTributavel.codigo = retorno.codigoUnidadeMedida;
        } else {
          this.unidadeMedidaOriginalId = retorno.unidadeMedidaId;
          this.codigoUnidadeMedidaOriginal = retorno.codigoUnidadeMedida;
        }

        if (!isNullOrUndefined(retorno.detalheItem)) {
          retorno.detalheItem.forEach(element => {
            if (element.impostoRegraFiscalId === 3) {

              if (!isNullOrUndefined(pedidoItem.cstPis)) {
                if (!isNullOrUndefined(pedidoItem.cstPis)) {
                  if (pedidoItem.cstPis.id !== 8 && !pedidoItem.operacaoRegra.utilizaBasePcXml) {
                    pedidoItem.perAliquotaPis = this.utilService.parseDecimal4(element.aliquotaImposto);

                  }
                }
              }
            } else if (element.impostoRegraFiscalId === 4) {
              if (!isNullOrUndefined(pedidoItem.cstCofins)) {
                if (pedidoItem.cstCofins.id !== 8 && !pedidoItem.operacaoRegra.utilizaBasePcXml) {
                  pedidoItem.perAliquotaCofins = this.utilService.parseDecimal4(element.aliquotaImposto);
                }
              }

            } else if (element.impostoRegraFiscalId === 5) {
              pedidoItem.aliquotaIcmsSt = this.utilService.parseDecimal4(element.aliquotaImposto);
            } else if (element.impostoRegraFiscalId === 6) { }
          });
        }

        if (bool) {
          if (this.tipo == 0) {
            if (this.aliquotaInter) {
              pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(this.aliquotaInter);
              pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(this.aliquotaInter);
              if (this.mesmaUf) {

                pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(this.aliquotaInterna);
                pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(this.aliquotaInterna);
              }
            }
          } else {
            if (this.aliquotaInterVenda) {

              pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(this.aliquotaInterVenda);
              pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(this.aliquotaInterVenda);
              if (this.mesmaUf) {
                pedidoItem.aliquotaIcms = this.utilService.parseDecimal4(this.aliquotaInterna);
                pedidoItem.aliquotaInterestadual = this.utilService.parseDecimal4(this.aliquotaInterna);
              }
            }
          }
        }
        this.fatoresConversao = new Array();
        this.fatoresConversao = retorno.fatorConversaoList;

        if (!isNullOrUndefined(retorno.origemProduto)) {
          if (retorno.origemProduto == 0) {
            pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA();
            pedidoItem.cstIcmsTabelaA.id = 1;
          } else if (retorno.origemProduto == 1) {

            pedidoItem.cstIcmsTabelaA = new CstIcmsTabelaA();
            pedidoItem.cstIcmsTabelaA.id = 2;

          }
        }

      }, error => this.httpService.handleError(error, () => this.dadosItem2(pedidoItem, bool)),
        () => {
          this.dadosOperacao2(pedidoItem)
          this.httpService.done()
        }
      );
  }

  dadosOperacao2(pedidoItem: PedidoItem) {
    this.httpService.wait()
    this.httpService.get('custom/cadastro/get-operacao-pedido', [pedidoItem.operacaoRegra.id])
      .subscribe(retorno => {

        pedidoItem.operacaoRegra = new OperacaoRegra();
        pedidoItem.operacaoRegra.id = retorno.id;
        pedidoItem.operacaoRegra.cod = retorno.codigo;
        pedidoItem.operacaoRegra.descricao = retorno.descricao;
        pedidoItem.operacaoRegra.permiteAlteracaoAlmoxarifado = retorno.permiteAlteracaoAlmoxarifado;
        pedidoItem.operacaoRegra.bloqueiaFiscal = retorno.bloqueiaFiscal;
        pedidoItem.operacaoRegra.utilizaBasePcXml = retorno.utilizaBasePcXml;
        pedidoItem.operacaoRegra.tipoRegra = retorno.tipoRegraContabil;
        pedidoItem.isServico = retorno.tipoRegraContabil == 2 ? true : false;
        this.buscarCustoMedio = isNullOrUndefined(retorno.utilizaCustoMedio) ? false : retorno.utilizaCustoMedio;
        this.buscarValorVenda = isNullOrUndefined(retorno.utilizaPrecoVenda) ? false : retorno.utilizaPrecoVenda;

        this.pedido.dadosAdicionais = isNullOrUndefined(this.pedido.dadosAdicionais) ? ('' + retorno.dadosAdicionais) : (this.pedido.dadosAdicionais + ' ' + (!isNullOrUndefined(this.pedido.dadosAdicionais) ? '' : retorno.dadosAdicionais));

        if (retorno.almoxarifadoOrigemId !== 0 || retorno.almoxarifadoDestinoId) {
          pedidoItem.operacaoRegra.almoxarifadoOrigem = new EmpresaAlmoxarifado();
          pedidoItem.operacaoRegra.almoxarifadoOrigem.id = retorno.almoxarifadoOrigemId;
          pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado();
          pedidoItem.empresaAlmoxarifado.id = retorno.almoxarifadoOrigemId;
        }

        if (retorno.almoxarifadoDestinoId && retorno.almoxarifadoOrigemId !== 0) {
          pedidoItem.operacaoRegra.almoxarifadoDestino = new EmpresaAlmoxarifado();
          pedidoItem.operacaoRegra.almoxarifadoDestino.id = retorno.almoxarifadoDestinoId;
          pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado();
          pedidoItem.almoxarifadoDestino.id = retorno.almoxarifadoDestinoId;
        }

        pedidoItem.operacaoRegra.transfEstoque = retorno.transfEstoque;
        pedidoItem.operacaoRegra.controlaEstoque = retorno.controlaEstoque;

        this.somaIpiBc = false;
        if (retorno.operacaoRegraFiscalDetalheList != null) {
          retorno.operacaoRegraFiscalDetalheList.forEach(element => {
            //Verificação ICMS
            if (element.impostoRegraFiscal.id === 1) {
              let puxarIcms = false
              let puxarIcmsSimples = false
              if (this.infoParticipante[0].regimeTributarioId === 3 && element.lucroReal) puxarIcms = true
              else if (this.infoParticipante[0].regimeTributarioId === 6 && element.lucroPresumido) puxarIcms = true
              else if ([1, 2, 4].includes(this.infoParticipante[0].regimeTributarioId) && element.simples) puxarIcmsSimples = true

              if (!puxarIcms) {
                pedidoItem.valorIcms = 0
                pedidoItem.valorBcIcms = 0
                pedidoItem.aliquotaIcms = 0
              }

              if (puxarIcmsSimples) {
                pedidoItem.valorIcms = pedidoItem.valorCredSn
                pedidoItem.aliquotaIcms = pedidoItem.aliqCredSn
                pedidoItem.valorBcIcms = pedidoItem.aliqCredSn != 0 ? Math.round((pedidoItem.valorCredSn / (pedidoItem.aliqCredSn / 100)) * 100) / 100 : 0
              }
            }

            if (!isNullOrUndefined(element.csosn)) pedidoItem.csosn = element.csosn

            if (!isNullOrUndefined(element.operacaoRegraFiscalDetalheCfopRelacList)) {
              element.operacaoRegraFiscalDetalheCfopRelacList.forEach(el => {

                if (!isNullOrUndefined(el.cstIcmsTabelaB)) {
                  if (pedidoItem.cfop.endsWith(el.cfopDest)) {
                    pedidoItem.cstIcmsTabelaB.id = el.cstIcmsTabelaB.id
                  }
                }
              });
            }

            if (!isNullOrUndefined(element.cstIpi.id)) {
              pedidoItem.cstIpi = new CstIpi()
              pedidoItem.cstIpi.id = element.cstIpi.id
            }

            if (!isNullOrUndefined(element.cstPisCofins.id)) {
              pedidoItem.cstPis = new CstPiscofins()
              pedidoItem.cstPis.id = element.cstPisCofins.id
              pedidoItem.cstCofins = new CstPiscofins()
              pedidoItem.cstCofins.id = element.cstPisCofins.id
            }

            if (element.codEnqIpi) pedidoItem.codigoIpi = element.codEnqIpi
            if (element.modBcIcms) pedidoItem.modBcIcms = element.modBcIcms
            if (element.somaIpiBc) this.somaIpiBc = true
            const relacImpostoId = { 1: 'icms', 2: 'ipi', 3: 'pis', 4: 'cofins' }
            const imposto = relacImpostoId[element.impostoRegraFiscal?.id]
            if (imposto) this.relacGeraImposto[imposto] = element.lucroReal || element.lucroPresumido || element.simples || element.naoContribuinte
          })
        }

        this.getTaxBaseAmounts();

        const valorIpi = this.somaIpiBc && this.pedidoItem.valorIpi ? this.pedidoItem.valorIpi : 0;
        const taxBaseAmountIcms = (this.utilService.parseDecimal(this.pedidoItem.valorTotalProduto) + this.utilService.parseDecimal(valorIpi)).toFixed(2);


        if (this.relacGeraImposto['icms']) this.pedidoItem.valorBcIcms = taxBaseAmountIcms;
        if (this.relacGeraImposto['ipi']) this.pedidoItem.valorBcIpi = this.utilService.parseDecimal(this.pedidoItem.valorTotalProduto);
        if (!this.pedidoItem.operacaoRegra.utilizaBasePcXml) {
          if (this.relacGeraImposto['pis']) this.pedidoItem.valorBcPis = this.utilService.parseDecimal(this.pedidoItem.valorTotalProduto);
          if (this.relacGeraImposto['cofins']) this.pedidoItem.valorBcCofins = this.utilService.parseDecimal(this.pedidoItem.valorTotalProduto);
        }


        //this.calculaValorIpi();

        this.valorIpi = 0.00;
        this.valorIpi = (this.utilService.parseDecimal(this.pedidoItem.valorBcIpi) *
          this.utilService.parseDecimal(this.pedidoItem.aliquotaIpi)) / 100;
        this.pedidoItem.valorIpi = this.utilService.parseMoneyString(this.valorIpi);

        //this.calculaValorIcms();

        this.pedidoItem.valorIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(this.pedidoItem.valorBcIcms) *
          this.utilService.parseDecimal(this.pedidoItem.aliquotaIcms)) / 100);


      }, error => this.httpService.handleError(error, () => this.dadosOperacao2(pedidoItem)),
        () => {
          this.calculaValorUnitImport2(pedidoItem)
          this.calculaValorTotalItem2(pedidoItem)
          this.httpService.done()
        })
  }

  calculaValorTotalItem2(pedidoItem: PedidoItem) {
    //Olhar se é NF complementar
    let qtdeCalc = this.utilService.parseDecimal6(pedidoItem.quantidade)
    if (this.pedido.nfeFinalidade.id === 2) qtdeCalc = 1

    if (pedidoItem.tipoDesconto.id === 1) {

      const desconto = (((qtdeCalc *
        this.utilService.parseDecimal6(pedidoItem.valorUnit)) *
        (this.utilService.parseDecimal(pedidoItem.valorDesconto) / 100)));

      let valorIcmsImp = 0.00;
      let valorPisCofinsImp = 0.00;


      if (!isNullOrUndefined(pedidoItem.cfop)) {
        if (pedidoItem.cfop.startsWith('3')) {
          valorIcmsImp = this.utilService.parseDecimal(pedidoItem.valorIcms);
          valorPisCofinsImp = this.utilService.parseDecimal(pedidoItem.valorPis)
            + this.utilService.parseDecimal(pedidoItem.valorCofins);
        }
      }

      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        //  exterior
        pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(pedidoItem.valorUnit)) -
            this.utilService.parseDecimal(desconto) +
            this.utilService.parseDecimal(pedidoItem.valorOutro) +
            this.utilService.parseDecimal(pedidoItem.valorIcms) +
            this.utilService.parseDecimal(pedidoItem.valorIpi) +
            this.utilService.parseDecimal(pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(pedidoItem.valorCofins) +
            this.utilService.parseDecimal(pedidoItem.valorPis)
          );

      } else {

        pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(pedidoItem.valorUnit)) -
            this.utilService.parseDecimal(desconto) +
            this.utilService.parseDecimal(pedidoItem.valorFrete) +
            this.utilService.parseDecimal(pedidoItem.valorIcmsSt) +
            this.utilService.parseDecimal(pedidoItem.valorSeguro) +
            this.utilService.parseDecimal(pedidoItem.valorOutro) +
            this.utilService.parseDecimal(pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(pedidoItem.valorIpi) +
            this.utilService.parseDecimal(valorPisCofinsImp) +
            this.utilService.parseDecimal(valorIcmsImp)
          );
      }

      pedidoItem.valorTotalProduto =
        this.utilService.parseDecimalString(
          ((qtdeCalc *
            this.utilService.parseDecimal6(pedidoItem.valorUnit)))
        );

      if (pedidoItem.unidadeMedidaTributavel.id === pedidoItem.unidadeMedida.id) {
        pedidoItem.quantidadeTributavel = pedidoItem.quantidade;
        pedidoItem.valorUnitTributavel = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(pedidoItem.valorUnit));
        this.valorItemTributavel = this.utilService.parseDecimalString(this.utilService.parseDecimal6(pedidoItem.valorTotal));
      }

      this.pedido.valorDesconto = this.utilService.parseDecimalString(desconto);

    } else if (pedidoItem.tipoDesconto.id === 2) {

      pedidoItem.valorTotalProduto =
        this.utilService.parseDecimalString(
          ((qtdeCalc *
            this.utilService.parseDecimal6(pedidoItem.valorUnit)))
        );

      let valorIcmsImp = 0.00;
      let valorPisCofinsImp = 0.00;

      if (!isNullOrUndefined(pedidoItem.cfop)) {
        if (pedidoItem.cfop.startsWith('3')) {
          valorIcmsImp = this.utilService.parseDecimal(pedidoItem.valorIcms);
          valorPisCofinsImp = this.utilService.parseDecimal(pedidoItem.valorPis) + this.utilService.parseDecimal(pedidoItem.valorCofins);
        }
      }

      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        //  exterior
        pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(pedidoItem.valorUnit)) -
            this.utilService.parseDecimal(pedidoItem.valorDesconto) +
            this.utilService.parseDecimal(pedidoItem.valorOutro) +
            this.utilService.parseDecimal(pedidoItem.valorIcms) +
            this.utilService.parseDecimal(pedidoItem.valorIpi) +
            this.utilService.parseDecimal(pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(pedidoItem.valorCofins) +
            this.utilService.parseDecimal(pedidoItem.valorPis)
          );

      } else {
        pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(pedidoItem.valorUnit)) -
            this.utilService.parseDecimal(pedidoItem.valorDesconto) +
            this.utilService.parseDecimal(pedidoItem.valorFrete) +
            this.utilService.parseDecimal(pedidoItem.valorIcmsSt) +
            this.utilService.parseDecimal(pedidoItem.valorSeguro) +
            this.utilService.parseDecimal(pedidoItem.valorOutro) +
            this.utilService.parseDecimal(pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(pedidoItem.valorIpi) +
            this.utilService.parseDecimal(valorPisCofinsImp) +
            this.utilService.parseDecimal(valorIcmsImp)
          );
      }

      if (pedidoItem.unidadeMedidaTributavel.id === pedidoItem.unidadeMedida.id) {
        pedidoItem.quantidadeTributavel = pedidoItem.quantidade;
        pedidoItem.valorUnitTributavel = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(pedidoItem.valorUnit));
        this.valorItemTributavel = this.utilService.parseDecimalString(this.utilService.parseDecimal6(pedidoItem.valorTotal));
      }

    } else {

      let valorIcmsImp = 0.00;
      let valorPisCofinsImp = 0.00;

      if (!isNullOrUndefined(pedidoItem.cfop)) {
        if (pedidoItem.cfop.startsWith('3')) {
          valorIcmsImp = this.utilService.parseDecimal(pedidoItem.valorIcms);
          valorPisCofinsImp = this.utilService.parseDecimal(pedidoItem.valorPis) + this.utilService.parseDecimal(pedidoItem.valorCofins);
        }
      }

      if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
        //  exterior
        pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(pedidoItem.valorUnit)) +
            this.utilService.parseDecimal(pedidoItem.valorOutro) +
            this.utilService.parseDecimal(pedidoItem.valorIcms) +
            this.utilService.parseDecimal(pedidoItem.valorIpi) +
            this.utilService.parseDecimal(pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(pedidoItem.valorCofins) +
            this.utilService.parseDecimal(pedidoItem.valorPis)
          );

      } else {
        pedidoItem.valorTotal =
          this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(pedidoItem.valorUnit)) +
            this.utilService.parseDecimal(pedidoItem.valorFrete) +
            this.utilService.parseDecimal(pedidoItem.valorIcmsSt) +
            this.utilService.parseDecimal(pedidoItem.valorSeguro) +
            this.utilService.parseDecimal(pedidoItem.valorOutro) +
            this.utilService.parseDecimal(pedidoItem.valorImpostoImport) +
            this.utilService.parseDecimal(pedidoItem.valorIpi) +
            this.utilService.parseDecimal(valorPisCofinsImp) +
            this.utilService.parseDecimal(valorIcmsImp)
          );
      }

      pedidoItem.valorTotalProduto =
        this.utilService.parseDecimalString(
          ((qtdeCalc *
            this.utilService.parseDecimal6(pedidoItem.valorUnit)))
        );

      if (pedidoItem.unidadeMedidaTributavel.id === pedidoItem.unidadeMedida.id) {
        pedidoItem.quantidadeTributavel = pedidoItem.quantidade;
        pedidoItem.valorUnitTributavel = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(pedidoItem.valorUnit));
        this.valorItemTributavel = this.utilService.parseDecimalString(this.utilService.parseDecimal6(pedidoItem.valorTotal));
      }
    }

    if (this.valorMva > 0) {

      pedidoItem.valorBcIcmsSt = this.utilService.parseMoneyString(this.utilService.parseDecimal(pedidoItem.valorTotal) + ((this.utilService.parseDecimal(pedidoItem.valorTotal) * this.utilService.parseDecimal(this.valorMva)) / 100));
      pedidoItem.valorTotal = pedidoItem.valorBcIcmsSt;

    }


    if ((this.excluiIcmsPisCofins || this.excluiIcmsPisCofinsCompra) && !pedidoItem.cfop.toString().startsWith('3')) {

      if (this.tipo != 0 && this.excluiIcmsPisCofins) {

        if (!pedidoItem.operacaoRegra.utilizaBasePcXml) {
          pedidoItem.valorBcPis = this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(pedidoItem.valorUnit)) +
            this.utilService.parseDecimal(pedidoItem.valorFrete) +
            this.utilService.parseDecimal(pedidoItem.valorSeguro) +
            this.utilService.parseDecimal(pedidoItem.valorOutro) +
            this.utilService.parseDecimal(pedidoItem.valorImpostoImport) -
            this.utilService.parseDecimal(pedidoItem.valorIcms)
          );

          pedidoItem.valorBcCofins = this.utilService.parseDecimalString(
            (qtdeCalc *
              this.utilService.parseDecimal6(pedidoItem.valorUnit)) +
            this.utilService.parseDecimal(pedidoItem.valorFrete) +
            this.utilService.parseDecimal(pedidoItem.valorSeguro) +
            this.utilService.parseDecimal(pedidoItem.valorOutro) +
            this.utilService.parseDecimal(pedidoItem.valorImpostoImport) -
            this.utilService.parseDecimal(pedidoItem.valorIcms)
          );
        }




        pedidoItem.valorPis = this.utilService.parseMoneyString(
          (this.utilService.parseDecimal(pedidoItem.valorBcPis) *
            this.utilService.parseDecimal(pedidoItem.perAliquotaPis)) / 100);
        pedidoItem.valorCofins = this.utilService.parseMoneyString(
          (this.utilService.parseDecimal(pedidoItem.valorBcCofins) *
            this.utilService.parseDecimal(pedidoItem.perAliquotaCofins)) / 100);
      } else {

        if (this.excluiIcmsPisCofinsCompra) {

          if (this.infoParticipante[0].regimeTributarioId === 1) {

            if (!pedidoItem.operacaoRegra.utilizaBasePcXml) {
              pedidoItem.valorBcPis = this.utilService.parseDecimalString(
                (qtdeCalc *
                  this.utilService.parseDecimal6(pedidoItem.valorUnit)) +
                this.utilService.parseDecimal(pedidoItem.valorFrete) +
                this.utilService.parseDecimal(pedidoItem.valorSeguro) +
                this.utilService.parseDecimal(pedidoItem.valorOutro) +
                this.utilService.parseDecimal(pedidoItem.valorImpostoImport)
              );

              pedidoItem.valorBcCofins = this.utilService.parseDecimalString(
                (qtdeCalc *
                  this.utilService.parseDecimal6(pedidoItem.valorUnit)) +
                this.utilService.parseDecimal(pedidoItem.valorFrete) +
                this.utilService.parseDecimal(pedidoItem.valorSeguro) +
                this.utilService.parseDecimal(pedidoItem.valorOutro) +
                this.utilService.parseDecimal(pedidoItem.valorImpostoImport)
              );
            }



            pedidoItem.valorPis = this.utilService.parseMoneyString(
              (this.utilService.parseDecimal(pedidoItem.valorBcPis) *
                this.utilService.parseDecimal(pedidoItem.perAliquotaPis)) / 100);
            pedidoItem.valorCofins = this.utilService.parseMoneyString(
              (this.utilService.parseDecimal(pedidoItem.valorBcCofins) *
                this.utilService.parseDecimal(pedidoItem.perAliquotaCofins)) / 100);
          } else {

            if (!pedidoItem.operacaoRegra.utilizaBasePcXml) {
              pedidoItem.valorBcPis = this.utilService.parseDecimalString(
                (qtdeCalc *
                  this.utilService.parseDecimal6(pedidoItem.valorUnit)) +
                this.utilService.parseDecimal(pedidoItem.valorFrete) +
                this.utilService.parseDecimal(pedidoItem.valorSeguro) +
                this.utilService.parseDecimal(pedidoItem.valorOutro) +
                this.utilService.parseDecimal(pedidoItem.valorImpostoImport) -
                this.utilService.parseDecimal(pedidoItem.valorIcms)
              );

              pedidoItem.valorBcCofins = this.utilService.parseDecimalString(
                (qtdeCalc *
                  this.utilService.parseDecimal6(pedidoItem.valorUnit)) +
                this.utilService.parseDecimal(pedidoItem.valorFrete) +
                this.utilService.parseDecimal(pedidoItem.valorSeguro) +
                this.utilService.parseDecimal(pedidoItem.valorOutro) +
                this.utilService.parseDecimal(pedidoItem.valorImpostoImport) -
                this.utilService.parseDecimal(pedidoItem.valorIcms)
              );
            }


            pedidoItem.valorPis = this.utilService.parseMoneyString(
              (this.utilService.parseDecimal(pedidoItem.valorBcPis) *
                this.utilService.parseDecimal(pedidoItem.perAliquotaPis)) / 100);
            pedidoItem.valorCofins = this.utilService.parseMoneyString(
              (this.utilService.parseDecimal(pedidoItem.valorBcCofins) *
                this.utilService.parseDecimal(pedidoItem.perAliquotaCofins)) / 100);
          }


        }



      }



      if (!isNullOrUndefined(pedidoItem.cstPis)) {
        if (pedidoItem.cstPis.id === 8 || pedidoItem.cstPis.id === 26 || pedidoItem.cstPis.id === 27
          || pedidoItem.cstPis.id === 28 || pedidoItem.cstPis.id === 29 || pedidoItem.cstPis.id === 30 || pedidoItem.cstPis.id === 31) {

          pedidoItem.valorBcPis = 0.00;
          pedidoItem.perAliquotaPis = 0.00;
          pedidoItem.valorPis = 0.00;

          pedidoItem.valorBcCofins = 0.00;
          pedidoItem.perAliquotaCofins = 0.00;
          pedidoItem.valorCofins = 0.00;
        }
      }



    }

    if (!isNullOrUndefined(this.ieEmpresa)) {
      if (this.ieEmpresa != 'ISENTO' && this.ieEmpresa != 'ISENTA' && this.ieEmpresa != ' ') {


        pedidoItem.valorOperSemIcmsDifal = this.utilService.parseDecimal(pedidoItem.valorTotalProduto);

        pedidoItem.aliqInternaDifal = this.utilService.parseDecimal(pedidoItem.aliqInternaDifal);
        pedidoItem.aliqInterestadualDifal = this.utilService.parseDecimal(pedidoItem.aliqInterestadualDifal);

        pedidoItem.valorOperComIcmsDifal = this.utilService.parseDecimal((this.utilService.parseDecimal(pedidoItem.valorOperSemIcmsDifal) / (100 - this.utilService.parseDecimal(pedidoItem.aliqInternaDifal))) * 100);

        pedidoItem.icmsOrigemCreditoDifal = this.utilService.parseDecimal((this.utilService.parseDecimal(pedidoItem.valorOperComIcmsDifal) * this.utilService.parseDecimal(pedidoItem.aliqInterestadualDifal)) / 100);
        pedidoItem.icmsDestinoDebitoDifal = this.utilService.parseDecimal((this.utilService.parseDecimal(pedidoItem.valorOperComIcmsDifal) * this.utilService.parseDecimal(pedidoItem.aliqInternaDifal)) / 100);

        pedidoItem.valorRecolherDifal = this.utilService.parseDecimal(this.utilService.parseDecimal(pedidoItem.icmsDestinoDebitoDifal) - this.utilService.parseDecimal(pedidoItem.icmsOrigemCreditoDifal));

      }
    }
    this.calculaValorPedido()

    this.getTaxBaseAmounts();
  }

  calculaValorUnitImport2(pedidoItem: PedidoItem) {

    this.valorUnitImportacao = 0.00;

    if (this.seguroOuFreteAcionado) {

      this.valorUnitImportacao = this.utilService.parseDecimal6(this.valorUnitImportacao) +
        this.utilService.parseDecimal6(pedidoItem.valorUnit) +
        this.utilService.parseDecimal(pedidoItem.valorFrete) +
        this.utilService.parseDecimal(pedidoItem.valorSeguro);

      this.seguroOuFreteAcionado = false;

    } else {
      this.valorUnitImportacao = this.utilService.parseDecimal6(pedidoItem.valorUnit);
    }

    if (this.pedidoExterior && this.pedido.pedidoTipo.id === 3) {
      pedidoItem.valorUnit = this.valorUnitImportacao;
    }
  }

  getTaxBaseAmounts2(pedidoItem: PedidoItem) {

    const valorIpi = this.somaIpiBc && pedidoItem.valorIpi ? pedidoItem.valorIpi : 0;
    const taxBaseAmountIcms = (this.utilService.parseDecimal(pedidoItem.valorTotalProduto) + this.utilService.parseDecimal(valorIpi)).toFixed(2);

    if (this.relacGeraImposto['icms']) pedidoItem.valorBcIcms = taxBaseAmountIcms;
    if (this.relacGeraImposto['ipi']) pedidoItem.valorBcIpi = this.utilService.parseDecimal(pedidoItem.valorTotalProduto);
    if (!pedidoItem.operacaoRegra.utilizaBasePcXml) {
      if (this.relacGeraImposto['pis']) pedidoItem.valorBcPis = this.utilService.parseDecimal(pedidoItem.valorTotalProduto);
      if (this.relacGeraImposto['cofins']) pedidoItem.valorBcCofins = this.utilService.parseDecimal(pedidoItem.valorTotalProduto);
    }

  }

  buscarMelhorOperacao(row: any) {
    if (this.itensXmlSelecionados.length === 0) {
      //Trocar o id para todos os mesmos itens da nota
      this.itensNfsParticipantes.forEach(element => {
        if (element.item_id_f === row.item_id_f) {
          element.item_id = row.item_id
          element.item_relac = row.item_relac
          if (row.operacao_regra_id) {
            element.operacao_regra_id = row.operacao_regra_id
            element.operacao = row.operacao
          }
        }
      })
    }

    if (row.operacao_regra_id) this.replicarItensXml(row)
    else {
      this.httpClient.get(this.configService.defaultUrlApiUpload + 'pedido/best-operacao-item?itemId=' + row.item_id + "&cnpj=" + this.xmlSelecionado.cnpj, this.httpOptions)
        .subscribe(res => {
          row.operacao_regra_id = res["data"]["item_part"][this.xmlSelecionado.cnpj][row.item_id.toString()]["operacao_id"]
          row.operacao = res["data"]["dicoper"][row.operacao_regra_id.toString()]
          this.replicarItensXml(row)
        },
          error => {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a operação, contate o suporte: ' + error.message })
          }
        )
    }
  }

  buscarCfopCorretos(infosNfe: any, itensNfe: Array<any>) {
    let itens: Array<number> = new Array()
    let operacoes: Array<number> = new Array()
    this.pedido.pedidoItem.forEach(item => {
      itens.push(item.item.id)
      operacoes.push(item.operacaoRegra.id)
    })

    let itensFor: Array<number> = new Array()
    itensNfe.forEach(item => itensFor.push(item.item_id_f))



    const infos = {
      participanteId: this.pedido.participante.id,
      nfeId: infosNfe.id,
      itens: itens,
      operacoes: operacoes,
      empresaId: this.dblinkedSessionService.empresa.id,
      itensFor: itensFor,
      presencial: this.pedido.nfePresencial.id,
      mesmaUf: this.mesmaUf
    }

    this.httpClient.post(this.configService.defaultUrlApiUpload + 'pedido/buscar-cfop', infos, this.httpOptions)
      .subscribe(res => {
        this.pedido.pedidoItem.forEach(item => {
          try {
            let rowItemFor = itensNfe.find(row => row.seq === item.numSequence)
            item.cfop = res["data"][item.item.id][item.operacaoRegra.id][rowItemFor.item_id_f]

          } catch {
            item.cfop = '1'
          }
          this.verificarCfop2(item)
        })
      },
        error => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a operação, contate o suporte: ' + error.message })
        }
      )
  }

  verificarCfop2(pedidoItem: PedidoItem) {
    if (pedidoItem.cfop.startsWith('2')) {



      if (this.mesmaUf || (this.pedido.nfePresencial.id === 1 && !this.mesmaUf)) {
        pedidoItem.cfop = ('1' + pedidoItem.cfop.substr(1, 4));
      }


      if (pedidoItem.cfop !== '2551' && pedidoItem.cfop !== '2556') {
        pedidoItem.valorOperSemIcmsDifal = 0.00;
        pedidoItem.aliqInternaDifal = 0.00;
        pedidoItem.aliqInterestadualDifal = 0.00;
        pedidoItem.valorOperComIcmsDifal = 0.00;
        pedidoItem.icmsOrigemCreditoDifal = 0.00;
        pedidoItem.icmsDestinoDebitoDifal = 0.00;
        pedidoItem.valorRecolherDifal = 0.00;
      } else {
        pedidoItem.aliqInternaDifal = this.aliquotaInterna;
        pedidoItem.aliqInterestadualDifal = this.aliquotaInter;
      }
    }
  }

  saveRequestEmissao(type: number) {
    let url
    let data = {}

    if (type == 1) {
      url = 'pedidos'

      if (this.validationDeadline(1)) {
        return
      }

      let requests = []
      this.pedidosSelecionados.forEach(elem => {
        requests.push(elem.id)
      })

      data = {
        userId: this.sessionService.loggedUser.id,
        pedidos: requests,
        dataEmissao: this.formatDate(this.itemDate),
        faturaAutomaticoLote: this.faturaAutomaticoLote,
      }
    }

    this.httpService.wait()
    this.httpService.post('/custom/pedido/atualizar-dados-emissao', data).subscribe(res => {
      if (!res['error']) {
        this.messageService.add({
          severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: res.message
        });
      } else
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: res.message
        });
    },
      err => {
        this.httpService.handleError(err, () => this.buscarPedidos());
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks', summary: 'Erro:  ', detail: err
        });
        this.modalDataEmissao = false;
      },
      () => this.httpService.done()
    )

    this.clearVarsDeadline()
    this.changeToolbar()
  }

  exportarPedidos() {
    this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aguarde', detail: 'Processando solicitação! Em breve seu arquivo será disponibilizado.' })

    const empresaId = this.dblinkedSessionService.empresa.id
    const usuarioId = this.sessionService.loggedUser.id
    const dataInicial = this.dataIniSearch
    const dataFinal = this.dataFinSearch
    const statusPesquisado = this.statusPesquisado
    const usaAprovacao = this.flowUses

    const payload = {
      empresaId,
      usuarioId,
      dataInicial,
      dataFinal,
      statusPesquisado,
      usaAprovacao,
      tipo: this.tipo == 0 ? 'pcp' : 'pvp',
      usaAsaas: this.usaAsaas
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi
      }),
      responseType: 'blob' as 'json'
    }

    try {
      this.httpClient.post(this.configService.defaultUrlApiUpload + '/pedido/exportar-pedidos', payload, httpOptions).toPromise().then((res: any) => {
        let link = document.createElement('a')
        let url = window.URL.createObjectURL(res)
        link.href = url
        if (this.tipo == 0) link.download = "relatorio-pedido-compra-produto.xlsx"
        else link.download = "relatorio-pedido-venda-produto.xlsx"
        link.click()
        window.URL.revokeObjectURL(url)
      }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao recuperar seu relatório! Contate o suporte' }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    }
  }

  public changeContractsModal(showContractsModal: boolean): void {
    this.exibirModalContratoTable = showContractsModal
    this.dateLimitContract = new Date()
    this.dateReadjustmentContract = new Date()
    this.readjustmentContract = 0
    this.readjustmentPercentageContract = 0
  }

  public generateContracts(): void {
    if (this.validateGenerateContract()) {
      let liPedidoId: Array<number> = this.pedidosSelecionados.map((pedido: any) => { return pedido.id })

      const payload = {
        usuario: this.sessionService.loggedUser.id,
        pedidos_id: liPedidoId,
        termino: this.dateLimitContract,
        data_reajuste: this.dateReadjustmentContract,
        opcao_reajuste: this.readjustmentContract,
        porcentagem_reajuste: this.readjustmentPercentageContract
      }

      this.httpService.wait()

      this.httpService.post('/custom/pedido/gerar-contratos-visualizacao-tabelas', payload).subscribe(
        res => {
          if (!res[0].error) {
            this.primeiraChamadaNegociacao = true
            this.primeiraChamadaComprasVendas = true
            this.primeiraChamadaRecusado = true
            this.primeiraChamadaFinalizado = true
            this.pedidosSelecionados = new Array()

            this.changeContractsModal(false)
            this.determinarPrimeiraChamada()
            this.changeToolbar()
          }

          this.messageService.add({ severity: (res[0].error ? 'error' : 'success'), key: 'messageLinnks', summary: (res[0].error ? 'Erro: ' : 'Sucesso: '), detail: res[0].message })
        },
        error => this.httpService.handleError(error, () => this.generateContracts()),
        () => this.httpService.done())
    }
  }

  private validateGenerateContract(): boolean {
    if (this.dateLimitContract === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de término é obrigatória!' })
      return false
    }

    if (this.readjustmentContract !== 0) {
      if (this.dateReadjustmentContract === null) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de início do reajuste é obrigatória!' })
        return false
      }
    }

    return true
  }

  loadAlmoxarifado() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        filiais: this.dblinkedSessionService.filiaisSelecionadas.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "estoque/almoxarifados", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res)
          } else {
            this.opcoesAlmoxarifado = []
            this.opcoesAlmoxarifado.push({ label: 'Selecione', value: null }, { label: 'Nenhum', value: null })
            res["data"].forEach(row => this.opcoesAlmoxarifado.push(row))
          }

          this.httpService.done()
        },
        (err) => {
          this.httpService.done()
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar a lista dos almoxarifados, contate o suporte: " + err.message })
        }
      )
  }

  calcularQtdeFinal(rowData: any) {
    rowData.qtde_final = null
    if (rowData.fator) rowData.qtde_final = rowData.fator * rowData.qtde
    this.checkVinculacaoXml()
  }

  buscarInfoUnidadeMedida(rowData: any) {
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'pedido/buscar-info-unidade-medida?itemId=' + rowData.item_id + "&unidadeNf=" + rowData.unidade_nf, this.httpOptions)
      .subscribe(res => {
        rowData.unidade_cod_item = res["data"]["unidade_cod"]
        rowData.fator = res["data"]["fator"]
        this.calcularQtdeFinal(rowData)
      },
        error => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a operação, contate o suporte: ' + error.message })
        }
      )
  }

  buscarParametroXml(rowData: any) {


    this.httpService.wait();
    this.httpService.get('/operacaoregra?$select=id,utilizaBasePcXml&$filter=id eq ' + rowData.operacao_regra_id + ' and ativo eq true')
      .subscribe(ref => {
        ref.value.forEach(element => {

          rowData.utiliza_base_pc_xml = element.utilizaBasePcXml;

          this.replicarOperacaoXml(rowData);

        });
      },
        error => this.httpService.handleError(error, () => this.buscarParametroXml(rowData)),
        () => this.httpService.done());
  }

  emissaoPreviaLote() {

    if (this.pedidosSelecionados.length === 0) {

      alert('É necessário selecionar pelo menos um pedido para prévia');

    } else {

      if (confirm('Deseja realmente executar a prévia deste(s) ' + this.pedidosSelecionados.length + ' pedido(s) ?')) {

        const listasEnviar = {
          pedido: JSON.stringify(this.pedido, null, ''),
          empresa: this.dblinkedSessionService.empresa.id,
          usuario: this.sessionService.loggedUser.id,
          pedidoSelecionados: this.pedidosSelecionados
        };

        this.httpService.wait();
        this.httpService.post('/custom/nfe/simular-emissao', listasEnviar)
          .subscribe(result => {

            this.download(result, 'SIMULADO(S)');

          },
            error => this.httpService.handleError(error, () => this.emissaoPreviaLote()),
            () => this.httpService.done());
      }

    }

  }

  abrirValidador() {
    let url = 'https://www.sefaz.rs.gov.br/NFE/NFE-VAL.aspx';
    window.open(url, "_blank");
  }

  async verificarItensRateioXml(): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = {
        pedidoId: this.pedido.id,
        liPedidoItem: this.pedido.pedidoItem
      }

      this.httpService.wait()
      this.httpService.post('/custom/pedido/verificar-itens-rms', payload).subscribe(
        res => {
          this.httpService.done()
          resolve([true, null, null])
        },
        error => {
          this.httpService.done()
          resolve([false, error.error.dado, error.error.message])
        })
    })
  }

  async getParametroRms() {
    this.skeletonConfig.startLoad()
    try {
      await this.httpService.get('/configuracaorms?$select=travarEdicaoItemPedido&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
        .toPromise()
        .then(
          ret => {
            ret.value.forEach(element => {
              this.travarEdicaoItemPedido = element.travarEdicaoItemPedido
            })
          }
        )
        .catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: "Erro ao recuperar as configurações RMS da empresa, contate o suporte: " + err.message }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao recuperar as configurações RMS da empresa, contate o suporte: " + err.message })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

}

