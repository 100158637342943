import { Component, OnInit, OnDestroy, ɵConsole, Output } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';

import { CadCcus}       from 'app/contabil/model/cad-ccus.model';
import { isNullOrUndefined } from 'util';

import { DatePipe } from '@angular/common';

import { trigger, state, style, animate, transition } from '@angular/animations';
declare var $: any;

@Component({
  selector: 'app-controle-orcamento',
  templateUrl: './controle-orcamento.component.html',
  styleUrls: ['./controle-orcamento.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})



export class ControleOrcamentoComponent implements OnInit, OnDestroy {

  currentStateControlOrca = 'initial';
  disabledEditBtn: boolean = true;
  changeStateControlOrca() {
    this.currentStateControlOrca = this.currentStateControlOrca === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  msgs: Message[];
  msgsModal: Message[];
  msgsNaoMapeadas: Message[];
  tipo: number;
  contasNaoMapeadas       : Array<any> = new Array();
  periodoOrcamentoEntrada : Array<any> = new Array();
  listaOrcamento          : Array<any> = new Array();
  listaPedidos            : Array<any> = new Array();
  listaProvisoes          : Array<any> = new Array();
  listaVersoesOrcamentos  : Array<any> = new Array();

  exibirRelacionamento: boolean;
  exibirAjustes: boolean;
  exibirNaoMapeadas: boolean;

  opcoesPlanoContas: SelectItem[];
  contaSelecionada: number;

  opcoesTipoAjustesUsGaap: SelectItem[];
  tipoajustesusGaapSelecionada: string;

  opcoesGerenciais      : SelectItem[];
  idReferencial         : number;
  idsOrcamentosEntrada          : Array<any> = new Array();
  idsOrcamentosEntradaOnFilter  : Array<any> = new Array();
  idsOrcamentosEventosEntrada   : Array<any> = new Array();
  idOrcamento                   : number = 0;


  lcto                      : any;
  valorTotalDeb             : number;
  valorTotalCred            : number;
  checkedAll                : boolean = false;
  modalAlternarCentroCusto  : boolean = false;
  opcoesCentroCusto         : SelectItem[];
  cadCcus                   : CadCcus = new CadCcus();
  centroCustoSelecionadoId  : number = 0;
  centroCustoSelecionado    : CadCcus = new CadCcus();
  checkboxdisabled          : boolean = false;
  idTeste                   : number = 0;
  selecionarTodosDisabled   : boolean = true;
  tabIndexSelecionado       : number = 0;
  modalPeriodo              : boolean = false;
  public dataIni            : any = '';
  public dataFin            : any = '';
  isProcurarPorData         :boolean = false;
  public isNovoParametroData : any = null;

   //export excel
   colsPeriodoOrcamentoEntrada: any[];

    constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private datePipe: DatePipe,
    private messageService: MessageService) {
  }

  ngOnInit(): void {

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.tipo = 9;

    this.titlePrincipal = 'Controle Orçamento';
    // this.titleService.title = 'Linnks - Us Gaap';
    this.subtitle = 'Centro de custo: '
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.exibirRelacionamento = false;
    this.exibirAjustes = false;
    this.idReferencial = 0;
    this.periodoOrcamentoEntrada = new Array();
    this.listaPedidos      = new Array();
    this.listaProvisoes    = new Array();
    this.contasNaoMapeadas = new Array();
    this.exibirNaoMapeadas = false;


    this.valorTotalDeb            = 0;
    this.valorTotalCred           = 0;
    this.centroCustoSelecionadoId = 0;
    this.centroCustoSelecionado   = new CadCcus();
    this.checkboxdisabled         = false;
    this.idTeste                  = 0;
    this.selecionarTodosDisabled  = true;
    this.tabIndexSelecionado      = 0;
    this.tabChange(0);
    this.modalPeriodo             = false;
    this.isProcurarPorData        = false;
    this.dataIni                  = null;
    this.dataFin                  = null;

    this.activatedRoute.params.subscribe(params => {

      if(this.tipo === 9 || this.tipo !== params.tipo ){
        this.tipo = params.tipo;
        this.change();
      }
    });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {


      this.change();
      this.toolbarEnable();

    });

    this.colsPeriodoOrcamentoEntrada = [
      { field: 'Conta', header: 'CONTA CONTÁBIL' },
      { field: 'CentroCusto', header: 'CENTRO DE CUSTO' },
      { field: 'DataTrans', header: 'DATA' },
      { field: 'Documento', header: 'DOCUMENTO' },
      { field: 'Emitente', header: 'EMITENTE' },
      { field: 'Narrativa', header: 'NARRATIVA' },
      { field: 'DescricaoItem', header: 'DESCRIÇÃO' },
      { field: 'NomeAbreviado', header: 'NOME ABREV.' },
      { field: 'DebitoCredito', header: 'VALOR' },
      { field: 'status', header: 'STATUS' }
    ];

  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

        this.idOrcamento = 0;

        this.contasNaoMapeadas            = [];
        this.periodoOrcamentoEntrada      = [];
        this.idsOrcamentosEventosEntrada  = [];
        this.listaPedidos                 = [];
        this.listaProvisoes               = [];

        this.checkedAll       = false;
        this.modalPeriodo     = false;

        if (isNullOrUndefined(this.centroCustoSelecionadoId) || this.centroCustoSelecionadoId === 0) {
          return;
        }

        if (isNullOrUndefined(this.tabIndexSelecionado)) {
          this.tabIndexSelecionado = 0;
        }



        switch (this.tabIndexSelecionado) {
          case 0:


            // this.httpService.get('/custom/orcamento/get-orcamentos-entrada(' + this.dblinkedSessionService.empresa.id +
            // ',' + this.dblinkedSessionService.periodo.month.value + ','
            // + this.dblinkedSessionService.periodo.year.value + ','
            // + this.centroCustoSelecionadoId   +   ','
            // +  !isNullOrUndefined(this.dataIni) ? this.dataIni : ' ' + ','
            // + !isNullOrUndefined(this.dataFin) ? this.dataFin : ' '  + ')')


              const listaEnviarOrcamento = {
                                              empresaId    : this.dblinkedSessionService.empresa.id,
                                              mes          : this.dblinkedSessionService.periodo.month.value,
                                              ano          : this.dblinkedSessionService.periodo.year.value,
                                              centroCustoId : this.centroCustoSelecionadoId,
                                              dataIni      : this.dataIni,
                                              dataFin      : this.dataFin
                                            };

              this.httpService.wait();
              this.httpService.post('/custom/orcamento/get-orcamentos-entrada', listaEnviarOrcamento )
              .subscribe(res => {
                  if(res.error){



                    this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.message });

                  }else{

                    this.contasNaoMapeadas            = new Array();
                    this.periodoOrcamentoEntrada      = new Array();
                    this.idsOrcamentosEventosEntrada  = new Array();
                    this.listaPedidos                 = new Array();

                    res.forEach(element => {
                      this.periodoOrcamentoEntrada.push(element);

                      if (element.mapeado === '0') {
                        this.contasNaoMapeadas.push(element.id)
                      }
                    });
                  }},
                  error => this.httpService.handleError(error, () => this.tabChange(0)),
                  () => this.httpService.done());

            break;

          case 1:

            const listaEnviarPedidos = {
              empresaId    : this.dblinkedSessionService.empresa.id,
              mes          : this.dblinkedSessionService.periodo.month.value,
              ano          : this.dblinkedSessionService.periodo.year.value,
              centroCustoId : this.centroCustoSelecionadoId,
              dataIni      : this.dataIni,
              dataFin      : this.dataFin
            };

              this.httpService.wait();
              this.httpService.post('/custom/orcamento/get-pedidos', listaEnviarPedidos)
              .subscribe(res => {
                  if(res.error){


                    this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
                  }else{

                    this.contasNaoMapeadas            = new Array();
                    this.periodoOrcamentoEntrada      = new Array();
                    this.idsOrcamentosEventosEntrada  = new Array();
                    this.listaPedidos                 = new Array();

                    res.forEach(element => {
                       this.listaPedidos.push(element);
                    });
                  }},
                  error => this.httpService.handleError(error, () => this.tabChange(1)),
                  () => this.httpService.done());


          break;
          case 2:

            const listaEnviarProvisoes = {
              empresaId    : this.dblinkedSessionService.empresa.id,
              mes          : this.dblinkedSessionService.periodo.month.value,
              ano          : this.dblinkedSessionService.periodo.year.value,
              centroCustoId : this.centroCustoSelecionadoId,
              dataIni      : this.dataIni,
              dataFin      : this.dataFin
            };

            this.httpService.wait();
            this.httpService.post('/custom/orcamento/get-provisoes', listaEnviarProvisoes)
            .subscribe(res => {
                if(res.error){


                  this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
                }else{

                  this.contasNaoMapeadas            = new Array();
                  this.periodoOrcamentoEntrada      = new Array();
                  this.idsOrcamentosEventosEntrada  = new Array();
                  this.listaProvisoes               = new Array();

                  res.forEach(element => {
                     this.listaProvisoes.push(element);
                  });
                }},
                error => this.httpService.handleError(error, () => this.tabChange(1)),
                () => this.httpService.done());


            break;

        }




        // if (isNullOrUndefined(this.centroCustoSelecionadoId) || this.centroCustoSelecionadoId === 0) {
        //   return;
        // }



        // this.httpService.wait();
        // this.httpService.get('/custom/orcamento/get-orcamentos-entrada(' + this.dblinkedSessionService.empresa.id +
        // ',' + this.dblinkedSessionService.periodo.month.value + ','
        // + this.dblinkedSessionService.periodo.year.value + ','
        // + this.centroCustoSelecionadoId   + ')')
        // .subscribe(res => {
        //     if(res.error){
        //
        //       this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
        //     }else{

        //       this.contasNaoMapeadas            = new Array();
        //       this.periodoOrcamentoEntrada      = new Array();
        //       this.idsOrcamentosEventosEntrada  = new Array();


        //       res.forEach(element => {
        //         this.periodoOrcamentoEntrada.push(element);
        //         if (element.orcamentoId === 0) {
        //           this.contasNaoMapeadas.push(element.id)
        //         }
        //       });
        //     }},
        //     error => this.httpService.handleError(error, () => this.change()),
        //     () => this.httpService.done());





        }

    }


    selectCheckbox(event: any) {
      this.idsOrcamentosEntrada.push(event.data.id);
      this.idsOrcamentosEventosEntrada.push(event.data);

      this.idTeste = this.idsOrcamentosEventosEntrada[0].planoContaId;

      for (let index = 0; index < this.periodoOrcamentoEntrada.length; index++) {
        const element = this.periodoOrcamentoEntrada[index];

        if (this.idTeste !== element.planoContaId) {
          element.checkboxdisabled = true;
        } else {
          element.checkboxdisabled = false;
        }

      }
      this.verificarBotaoEdit();
    }

    unselectCheckbox(event: any){
      const indexOE = this.idsOrcamentosEntrada.findIndex(vw => vw === event.data.id);
      this.idsOrcamentosEntrada.splice(indexOE, 1);

      const index = this.idsOrcamentosEventosEntrada.findIndex(oee => oee === event.data)
      this.idsOrcamentosEventosEntrada.splice(index,1);

      if ((!isNullOrUndefined(this.idsOrcamentosEventosEntrada) && this.idsOrcamentosEventosEntrada.length <  1) || isNullOrUndefined(this.idsOrcamentosEventosEntrada) ) {
        this.periodoOrcamentoEntrada.forEach(oe => {
          oe.checkboxdisabled = false;
          this.checkedAll     = false;
        });
      }

      this.verificarBotaoEdit();
    }

    selectTodos(event: any) {
      this.checkedAll                   = !this.checkedAll;
      this.idsOrcamentosEntrada         = new Array();
      this.idsOrcamentosEventosEntrada  = new Array();

      if (!isNullOrUndefined(this.idsOrcamentosEntradaOnFilter) && this.idsOrcamentosEntradaOnFilter.length > 0) {
        this.idsOrcamentosEntradaOnFilter.forEach(e => {

            if (this.checkedAll) {
              const i = this.periodoOrcamentoEntrada.findIndex(p => p === e);

              if (!this.periodoOrcamentoEntrada[i].checkboxdisabled) {
                this.periodoOrcamentoEntrada[i].selected = true;
                this.idsOrcamentosEntrada.push(e.id);
                this.idsOrcamentosEventosEntrada.push(e);
              }


            } else {

              this.periodoOrcamentoEntrada.forEach(oe => {
                oe.selected         = false;
                oe.checkboxdisabled = false;
                this.idsOrcamentosEntrada.slice(oe.id, 1);
                this.idsOrcamentosEventosEntrada.slice(oe, 1);
              });

            }

        });
      } else{

          this.periodoOrcamentoEntrada.forEach(oe => {
            if (this.checkedAll === false) {
              oe.selected = false;
              this.idsOrcamentosEntrada.slice(oe.id, 1);
              this.idsOrcamentosEventosEntrada.slice(oe, 1);
              oe.checkboxdisabled = false;

            } else {
              if (!oe.checkboxdisabled ) {
                  oe.selected = true;
                  this.idsOrcamentosEntrada.push(oe.id);
                  this.idsOrcamentosEventosEntrada.push(oe);

              }
            }
          });

      }




      this.verificarBotaoEdit();
    }

    onFilter(event: any){

        if (!isNullOrUndefined(event.filteredValue)) {
          this.idsOrcamentosEntradaOnFilter         = new Array();
          // this.idsOrcamentosEventosEntrada  = new Array();


          event.filteredValue.forEach(e => {
            if (!e.checkboxdisabled  ) {
              this.idsOrcamentosEntradaOnFilter.push(e);
              // this.idsOrcamentosEventosEntrada.push(e);
            }
          });


           this.verificarBotaoEdit();

      }else{
        this.idsOrcamentosEntradaOnFilter         = new Array();
      }




    }

    tabChange(event: any){



      this.idOrcamento = 0;

      this.contasNaoMapeadas            = [];
      this.periodoOrcamentoEntrada      = [];
      this.idsOrcamentosEventosEntrada  = [];

      this.checkedAll = false;




      if (isNullOrUndefined(event)) {
        this.tabIndexSelecionado = 0;
      } else {
        this.tabIndexSelecionado = event.index;
      }

      this.change();

    }



  verificarBotaoEdit(){

    if (this.idsOrcamentosEntrada.length > 0 ) {
      this.disabledEditBtn = false;

      this.toolbarService.alterarStatus('edit', false, 'Atribuir Orçamento', () => this.mostraModalRelacionamento() );

      this.selecionarTodosDisabled = false;
    } else {
      this.disabledEditBtn = true;

      this.toolbarService.alterarStatus('edit', true, 'Atribuir Orçamento', () => this.mostraModalRelacionamento() );
      this.selecionarTodosDisabled = true;
    }


  }


  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {
   //

  }


  mostraModalRelacionamento(){
    this.exibirRelacionamento = true;

    if (this.centroCustoSelecionadoId === undefined || this.centroCustoSelecionadoId === 0) {
      return;
    }

    const listaOrcamento = {
        empresaId       : this.dblinkedSessionService.empresa.id,
        mes             : this.dblinkedSessionService.periodo.month.value,
        ano             : this.dblinkedSessionService.periodo.year.value,
        planoContaId    : this.idsOrcamentosEventosEntrada[0].planoContaId,
        centroCustoId   : this.centroCustoSelecionadoId,
        dataIni         : this.dataIni,
        dataFin         : this.dataFin
    };

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/get-orcamentos', listaOrcamento)

      .subscribe(res => {
        if(res.error){

          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
        }else{
          this.hideMessage();
          this.listaOrcamento = new Array();

          this.listaOrcamento.push({label: 'Nenhum selecionado', value: 0 });
          res.forEach(element => {
            this.listaOrcamento.push({label: element.descricao, value: element.idOrcamento });
          });
        }},
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());



  }

    editar(){
      //this.exibirRelacionamento = true;
    }

    ajustes(){
      this.exibirAjustes = true;
    }


    // verificarContasNaoMapeadas(){
    //   this.exibirNaoMapeadas = true;
    //   this.msgsNaoMapeadas = [];
    // }

    // onRowSelect(event: any) {

    //   //this.dblinkedSessionService.periodo.month.value
    //   //this.dblinkedSessionService.periodo.year.value

    //   const lastDay = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value + 1, 0);

    //  const mes = this.dblinkedSessionService.periodo.month.value < 10 ? '0' + this.dblinkedSessionService.periodo.month.value.toString() : this.dblinkedSessionService.periodo.month.value.toString();

    //   console.log(event);
    //   window.open('/ecd/report-balancete-mensal-lcto/' + this.dblinkedSessionService.empresa.id + '/' + event.codCta +
    //   '/'+this.dblinkedSessionService.periodo.year.value+'-'+mes+'-01'+
    //   '/'+this.dblinkedSessionService.periodo.year.value+'-'+mes+'-'+lastDay.getDate()+'/balancete' + '/false', '_blank');

    //   }


        salvarRelacionamentos(){

          const listaEnviar = {
            idOrcamento       : this.idOrcamento,
            listaOrcamentos   : this.idsOrcamentosEntrada,
            dataIni           : this.dataIni,
            dataFin           : this.dataFin,
            empresaId         : this.dblinkedSessionService.empresa.id,
            centroCustoId     : this.centroCustoSelecionadoId,
            planoContaId      : this.idsOrcamentosEventosEntrada[0].planoContaId

          };

          this.httpService.wait();
          this.httpService.post('/custom/orcamento/atualizar-orcamentos-entrada', listaEnviar)
          .subscribe(res => {
            this.exibirAjustes = false;
            if(res.error){

              this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.message });


            }else{

              this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
              this.idsOrcamentosEntrada = new Array();

              this.exibirRelacionamento = false;
              this.change();
              this.verificarBotaoEdit();
            }
            },
            error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());



        }

        private toolbarEnable() {
          const todos = this.buttons.slice();



        this.toolbarService.replace('voltar', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.alternarPeriodo());
        this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Atribuir Orçamento', () => this.mostraModalRelacionamento() );
        this.toolbarService.replace('pesquisa', false, 'class-edit-button', 'search', 'Centro de Custo', () => this.alternarCentroCusto());


        this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Opções');
        this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar' );
        this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
        this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo');


         // this.toolbarService.ocultar('voltar');
          this.toolbarService.ocultar('download');
          this.toolbarService.ocultar('cancel');
          this.toolbarService.ocultar('check');
          this.toolbarService.ocultar('info');
          this.toolbarService.ocultar('msg');
          this.toolbarService.ocultar('more_vert');
          this.toolbarService.ocultar('export');
          this.toolbarService.ocultar('print');
          this.toolbarService.ocultar('add');
          this.toolbarService.ocultar('remove');
          this.toolbarService.ocultar('save');
          this.toolbarService.ocultar('atualizar');

          this.toolbarService.alterarStatus('edit', true, 'Atribuir Orçamento', () => this.mostraModalRelacionamento() );




        }


        alternarCentroCusto(){
          this.modalAlternarCentroCusto = true;
          this.httpService.wait();
          this.httpService.get('/custom/orcamento/listar-centro-custo-usuario(' + this.dblinkedSessionService.empresa.id +
          ',' + this.sessionService.loggedUser.id + ')')

          .subscribe(ref => {

            if (ref.error) {


              this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: ref.message });
            } else {
              this.loadCentroCusto(ref)
              this.change();

            }

          }
          ,
              error => this.httpService.handleError(error, () => this.alternarCentroCusto()),
              () => this.httpService.done());

        }

        alternarPeriodo(){
          this.modalPeriodo = true;

        }

        alternarCentroCustoSelecionada(event: any){

          this.centroCustoSelecionado = event.value;
          this.subtitle = 'Centro de custo: ' + event.value.descricaoCompleta;
          this.centroCustoSelecionadoId = event.value.id;



          this.change();


        }

        loadCentroCusto(value: Array<any>){

          this.opcoesCentroCusto = [];
          this.opcoesCentroCusto.push({ label: 'Nenhum', value: null });

          value.forEach(element => {
              this.opcoesCentroCusto.push({
                label:   element.codCcus + ' - ' +  element.descrCcus , value: element
              });

            });
          }

          changeDataIni(event : any){

            this.dataFin = event;

          }


    transformDate(date) {
      return this.datePipe.transform(date, 'dd/MM/yyyy');
    }

    salvarSemPlano(){
      if (confirm('Deseja realmente salvar esta despesa como Sem Plano?')) {

        const listaEnviar = {
          listaOrcamentos   : this.idsOrcamentosEntrada,
          semPlano          : true,
          usuario           : this.sessionService.loggedUser.id
        };

        console.log(listaEnviar);

        this.httpService.wait();
        this.httpService.post('/custom/orcamento/atualizar-orcamentos-sem-plano', listaEnviar)
        .subscribe(res => {
          this.exibirAjustes = false;
          if(res.error){

            this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.message });


          }else{

            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
            this.idsOrcamentosEntrada = new Array();

            this.exibirRelacionamento = false;
            this.change();
            this.verificarBotaoEdit();
          }
          },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());

      }
    }

    /*Show Hide ControlOrca*/
    isCardControlOrca: boolean = true;
    ShowHideControlOrca(){
      this.isCardControlOrca = !this.isCardControlOrca;
    }

}
