import { isNullOrUndefined } from "util";

export class TipoRegraContabil {
  id: number;
  descricao: string;

  constructor(tipoRegraContabil?: TipoRegraContabil | number) {
    if (tipoRegraContabil !== undefined && tipoRegraContabil !== null) {
      if (typeof tipoRegraContabil === 'number') {
        this.id = tipoRegraContabil;
      } else {
        this.id = tipoRegraContabil.id;
        this.descricao = tipoRegraContabil.descricao;
      }
    }
  }
}
