import { AuthUser } from 'app/proof/model/auth-user.model';
import { PedidoItem } from './pedido-item.model';

export class ImportacaoAdicao {
  id: number;
  pedidoItem: PedidoItem;
  numero: any;
  desconto: any;
  chaveAcessoNfe: String;
  codigoFabricante: String;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;


  constructor(importacaoAdicao?: ImportacaoAdicao | number) {
    if (importacaoAdicao !== undefined && importacaoAdicao !== null) {
      if (typeof importacaoAdicao === 'number') {
        this.id = importacaoAdicao;
      } else {
        this.id = importacaoAdicao.id;
        this.pedidoItem = importacaoAdicao.pedidoItem;
        this.numero = importacaoAdicao.numero;
        this.desconto = importacaoAdicao.desconto;
        this.chaveAcessoNfe = importacaoAdicao.chaveAcessoNfe;
        this.codigoFabricante = importacaoAdicao.codigoFabricante;
        this.createTimestamp = importacaoAdicao.createTimestamp;
        this.changeUser = importacaoAdicao.changeUser;
        this.changeTimestamp = importacaoAdicao.changeTimestamp;
      }
    }
  }
}
