export class TipoIntermediacao {
  id: number;
  descricao: string;

  constructor(tipoIntermediacao?: TipoIntermediacao | number) {
    if (tipoIntermediacao !== undefined && tipoIntermediacao !== null) {
      if (typeof tipoIntermediacao === 'number') {
        this.id = tipoIntermediacao;
      } else {
        this.id = tipoIntermediacao.id;
        this.descricao = tipoIntermediacao.descricao;
      }
    }
  }
}
