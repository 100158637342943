import { Component, OnDestroy, OnInit, ViewChild, HostListener } from "@angular/core";
import { Table } from 'primeng/table';
import { CrudService } from "app/shared/services/crud.service";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { Message, MessageService, SelectItem } from "primeng/api";
import { Subscription } from "rxjs/Subscription";
import { ToolbarService } from "app/shared/services/toolbar.service";
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service";
import { SessionService } from "app/shared/services/session.service";
import { ConfigService } from "app/shared/services/config.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HttpService } from "app/shared/services/http.service";
import { TitleService } from "app/shared/services/title.service";
import * as Highcharts from "highcharts/highstock";
import HC_exportData from "highcharts/modules/export-data";
import { Toolbar } from 'app/shared/model/toolbar.model';
import idv4 from "app/util/processing/idv4";
import { PysocketService } from "app/shared/services/socket/pysocket.service";
import { Method } from 'app/shared/services/socket/protocol/Method'
import { Action } from 'app/shared/services/socket/protocol/Action'
import { Service } from 'app/shared/services/socket/protocol/Service'
import { truncate } from "fs/promises";

HC_exportData(Highcharts);
declare var $: any;
@Component({
  selector: "app-dashboard-orca-new",
  templateUrl: "./dashboard-orca-new.component.html",
  styleUrls: ["./dashboard-orca-new.component.scss"],
  providers: [CrudService],
  animations: [
    trigger("changeDivSize", [
      state("initial", style({})),
      state(
        "final",
        style({
          opacity: "0",
          height: "0",
          padding: "0px 15px",
        })
      ),
      transition("initial=>final", animate("120ms")),
      transition("final=>initial", animate("70ms")),
    ]),
  ],
})
export class DashboardOrcamentoNewComponent implements OnInit, OnDestroy {

  //Variaveis dos charts
  fullDash1: boolean = false;
  fullDash2: boolean = false;
  fullMainTable: boolean = false;
  isCardDash1: boolean = true;
  isCardDash2: boolean = true;

  //Variaveis da tabela
  isConciseVision: boolean = false;
  isAccumulatedVision: boolean = false;
  statusLink1: boolean = true;
  statusLink2: boolean = false;
  movVisionMode: boolean = false;
  msgs: Message[] = [];
  exibirOpcoesRelatorio: boolean = true;
  listaVersoes: Array<any> = new Array();
  opcaoSelecionada: string = null;
  opcoesMoedas: SelectItem[] = [];
  moeda: number = 1;

  optionsOrcaKey1: SelectItem[] = [];
  optionsOrcaKey2: SelectItem[] = [];
  optionsOrcaKey3: SelectItem[] = [];
  selectedKey1: Array<number> = new Array();
  selectedKey2: Array<number> = new Array();
  selectedKey3: Array<number> = new Array();
  opcoesCentroCustosVar: SelectItem[] = [];
  opcoesCentroCustosAjustes: any = {};
  optionsKey1: any = {};
  optionsKey2: any = {};
  optionsKey3: any = {};
  optionsProjectKey1: any = {};
  optionsProjectKey2: any = {};
  optionsProjectKey3: any = {};
  opcoesProjetosAjustes: SelectItem[] = [];
  opcoesProjetosVar: SelectItem[] = [];
  opcoesAreaVar: SelectItem[] = [];
  opcoesAreaAjustes: any = {};
  opcoesSubAreaVar: SelectItem[] = [];
  opcoesSubAreaAjustes: SelectItem[] = [];
  opcoesModalProjetos: SelectItem[] = [];
  utilizarTxDif: boolean = false;
  txMoeda: number = 1;
  selectedCompany: number = 0;
  selectedCodCta: any = '';
  inputField: any = '';
  versao: any;
  frozenCols: any = { field: 'descr_cta', header: 'descr_cta' }
  frozenColsDet: any = [{ field: 'descr_key1', header: 'descr_key1' }, { field: 'descr_key2', header: 'descr_key2' }]

  currentPercentAux: Array<any> = new Array();
  visaoTrim: boolean = false;
  editOptions: any = [];
  tableValues: any = [];
  tableData: any = [];
  tableValuesOrca: Array<any> = new Array();
  tableValuesVar: Array<any> = new Array();
  tableEdicao: Array<any> = new Array();
  colmeses: Array<any> = [
    ["Jan", "Janeiro", 1, null],
    ["Fev", "Fevereiro", 1, null],
    ["Mar", "Março", 1, null],
    ["Abr", "Abril", 1, null],
    ["Mai", "Maio", 1, null],
    ["Jun", "Junho", 1, null],
    ["Jul", "Julho", 1, null],
    ["Ago", "Agosto", 1, null],
    ["Set", "Setembro", 1, null],
    ["Out", "Outubro", 1, null],
    ["Nov", "Novembro", 1, null],
    ["Dez", "Dezembro", 1, null],
  ];
  movMonths: Array<any> = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
  movTrims: Array<any> = ["q1", "q2", "q3", "q4"];
  colOrca: Array<any> = [
    { header: "Conta", field: "descr_cta" },
    { header: "Janeiro", field: "JanTotal" },
    { header: "Fevereiro", field: "FevTotal" },
    { header: "Março", field: "MarTotal" },
    { header: "Abril", field: "AbrTotal" },
    { header: "Maio", field: "MaiTotal" },
    { header: "Junho", field: "JunTotal" },
    { header: "Julho", field: "JulTotal" },
    { header: "Agosto", field: "AgoTotal" },
    { header: "Setembro", field: "SetTotal" },
    { header: "Outubro", field: "OutTotal" },
    { header: "Novembro", field: "NovTotal" },
    { header: "Dezembro", field: "DezTotal" },
    { header: "Total", field: "total" },
  ];
  colOrcaTrim: Array<any> = [
    { header: "Conta", field: "descr_cta" },
    { header: "1 trimestre", field: "q1" },
    { header: "2 trimestre", field: "q2" },
    { header: "3 trimestre", field: "q3" },
    { header: "4 trimestre", field: "q4" },
    { header: "Total", field: "total" },
  ];
  coltrimestre: Array<any> = [
    ["q1", "1 trimestre"],
    ["q2", "2 trimestre"],
    ["q3", "3 trimestre"],
    ["q4", "4 trimestre"],
  ];
  colsvar: Array<any> = [
    ["JanTotal", "Orc.", 1],
    ["JanRealTotal", "Real.", 1],
    ["JanVarTotal", "Var."],
    ["JanVarPorTotal", "Var. %"],
    ["FevTotal", "Orc.", 1],
    ["FevRealTotal", "Real.", 1],
    ["FevVarTotal", "Var."],
    ["FevVarPorTotal", "Var. %"],
    ["MarTotal", "Orc.", 1],
    ["MarRealTotal", "Real.", 1],
    ["MarVarTotal", "Var."],
    ["MarVarPorTotal", "Var. %"],
    ["AbrTotal", "Orc.", 1],
    ["AbrRealTotal", "Real.", 1],
    ["AbrVarTotal", "Var."],
    ["AbrVarPorTotal", "Var. %"],
    ["MaiTotal", "Orc.", 1],
    ["MaiRealTotal", "Real.", 1],
    ["MaiVarTotal", "Var."],
    ["MaiVarPorTotal", "Var. %"],
    ["JunTotal", "Orc.", 1],
    ["JunRealTotal", "Real.", 1],
    ["JunVarTotal", "Var."],
    ["JunVarPorTotal", "Var. %"],
    ["JulTotal", "Orc.", 1],
    ["JulRealTotal", "Real.", 1],
    ["JulVarTotal", "Var."],
    ["JulVarPorTotal", "Var. %"],
    ["AgoTotal", "Orc.", 1],
    ["AgoRealTotal", "Real."],
    ["AgoVarTotal", "Var.", 1],
    ["AgoVarPorTotal", "Var. %"],
    ["SetTotal", "Orc.", 1],
    ["SetRealTotal", "Real.", 1],
    ["SetVarTotal", "Var."],
    ["SetVarPorTotal", "Var. %"],
    ["OutTotal", "Orc.", 1],
    ["OutRealTotal", "Real.", 1],
    ["OutVarTotal", "Var."],
    ["OutVarPorTotal", "Var. %"],
    ["NovTotal", "Orc.", 1],
    ["NovRealTotal", "Real.", 1],
    ["NovVarTotal", "Var."],
    ["NovVarPorTotal", "Var. %"],
    ["DezTotal", "Orc.", 1],
    ["DezRealTotal", "Real.", 1],
    ["DezVarTotal", "Var."],
    ["DezVarPorTotal", "Var. %"],
    ["total", "Orc."],
    ["totalReal", "Real."],
    ["totalVar", "Var."],
    ["totalVarPor", "Var. %"],
  ];
  percentDic: Array<any> = [
    ["JanVarPorTotal", "Jan"],
    ["FevVarPorTotal", "Fev"],
    ["MarVarPorTotal", "Mar"],
    ["AbrVarPorTotal", "Abr"],
    ["MaiVarPorTotal", "Mai"],
    ["JunVarPorTotal", "Jun"],
    ["JulVarPorTotal", "Jul"],
    ["AgoVarPorTotal", "Ago"],
    ["SetVarPorTotal", "Set"],
    ["OutVarPorTotal", "Out"],
    ["NovVarPorTotal", "Nov"],
    ["DezVarPorTotal", "Dez"],
    ["q1VarPorTotal", "q1"],
    ["q2VarPorTotal", "q2"],
    ["q3VarPorTotal", "q3"],
    ["q4VarPorTotal", "q4"],
    ["totalVarPor", "total"],
  ];
  colsvartrim: Array<any> = [
    ["q1Total", "Orc."],
    ["q1RealTotal", "Real."],
    ["q1VarTotal", "Var."],
    ["q1VarPorTotal", "Var. %"],
    ["q2Total", "Orc."],
    ["q2RealTotal", "Real."],
    ["q2VarTotal", "Var."],
    ["q2VarPorTotal", "Var. %"],
    ["q3Total", "Orc."],
    ["q3RealTotal", "Real."],
    ["q3VarTotal", "Var."],
    ["q3VarPorTotal", "Var. %"],
    ["q4Total", "Orc."],
    ["q4RealTotal", "Real."],
    ["q4VarTotal", "Var."],
    ["q4VarPorTotal", "Var. %"],
    ["total", "Orc."],
    ["totalReal", "Real."],
    ["totalVar", "Var."],
    ["totalVarPor", "Var. %"],
  ];
  hideLines: boolean = true;
  ajustes: Array<any> = new Array();
  modalAjustes: boolean = false;
  modalEdicao: boolean = false;
  key1Ajuste: number = 0;
  key2Ajuste: number = 0;
  key3Ajuste: number = 0;
  ccusRelac: any = {};
  rowEditid: number = 0;
  keyLevel: any = 0;
  ajustesSalvos: any = { orc: {}, real: {}, for: {} };
  movSalvos: any = {};
  movSalvosTemp: any = {};
  movToDelete: any = {};
  modalComentario: boolean = false;
  modalTitulo: string = null;
  comentarioNew: string = null;
  comentarios: Array<any> = new Array();
  comentarioEditId: number;
  mesSelecionado: number = 0;
  tableAjustes: Array<any> = new Array();
  descrCcus: any = {};
  descrProj: any = {};
  descrArea: any = {};
  descrSubArea: any = {};
  descEdicao: any = {};
  tableValuesOrig: Array<any> = new Array();
  edicaoCta: boolean = false;
  ctaSelecionada: string = null;
  centroCustoEdicao: Array<number> = new Array();
  listEmpresas: Array<any> = new Array();
  listEmpresasSelecionadas: SelectItem[] = [];
  empresaSelecionada: number;
  empresasSelecionadas: Array<number> = new Array();
  visaoReal: boolean = false;
  editionState: boolean = false;
  tableVisaoReal: Array<any> = new Array();
  tableValuesAntesYTD: Array<any> = new Array();
  modalMov: boolean = false;
  opcoesCta: Array<any> = new Array();
  tableMov: Array<any> = new Array();
  tableOriginMov: Array<any> = new Array();
  liSobrando = {};
  valorDispoMov: number = 0;
  areaSelecionadas: Array<number> = new Array();
  subareaSelecionadas: Array<number> = new Array();
  editavel: boolean = true;
  moedaVisu: number = 0;
  taxaMeses: {};
  limesreal: Array<String> = new Array();
  isRealizadoVisao: boolean = false;
  levels: any = { 1: '', 2: '', 3: '' };
  opcoesVar: Array<any> = [];
  isForecast: boolean = false;
  loggedUserId: number;
  comentarioGeral: boolean = false;
  diccomentarios: any;
  listCtaVinculadas: Array<number> = [];
  isSelectedCtaEditavel: boolean = false;
  dicQtdComentarios: any = {};
  ccusObrigatorio: boolean = false
  areaAjuste: number = 0;

  // Editor Froala
  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    placeholderText: "Adicionar comentário...",
    charCounterCount: false,
    pluginsEnabled: [
      "align",
      "charCounter",
      "codeBeautifier",
      "codeView",
      "colors",
      "draggable",
      "embedly",
      "emoticons",
      "entities",
      "file",
      "fontAwesome",
      "fontFamily",
      "fontSize",
      "fullscreen",
      "image",
      "imageTUI",
      "imageManager",
      "inlineStyle",
      "inlineClass",
      "lineBreaker",
      "lineHeight",
      "link",
      "lists",
      "paragraphFormat",
      "table",
      "url",
      "video",
      "wordPaste",
    ],
    toolbarButtons: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    toolbarButtonsXS: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    toolbarButtonsSM: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    toolbarButtonsMD: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    imageUploadMethod: "POST",
    key: "LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==",
    events: { "froalaEditor.initialized": function () { } },
  };
  private sessionSubscription: Subscription;

  toolbarMainIni = [
    { icon: 'arrow_back_ios_new', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: false, color: 'default', onClick: () => this.backToOptions() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.export() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'green', onClick: () => this.salvarOrcamento() },
  ]

  toolbarDetailsIni = [
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: true, disabled: false, color: 'default', onClick: () => this.changeEditionState() },
    { icon: 'add', text: 'Adicionar', tooltip: 'Adicionar', visible: true, disabled: false, color: 'default', onClick: () => this.addNewRow() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'green', onClick: () => this.salvarEdicao() },
    { icon: 'close', text: '', tooltip: '', visible: true, disabled: false, color: 'default', onClick: () => this.closeAjustes() },
  ]

  toolbarEditIni = [
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: true, disabled: false, color: 'default', onClick: () => this.changeEditionState() },
    { icon: 'add', text: 'Adicionar', tooltip: 'Adicionar', visible: true, disabled: false, color: 'default', onClick: () => this.addNewRow() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'green', onClick: () => this.salvarEdicao() },
  ]

  toolbarMovIni = [
    { icon: 'save', text: 'Salvar edição', tooltip: 'Salvar edição', visible: true, disabled: false, color: 'green', onClick: () => this.salvarMov() },
  ]

  toolbarMovIni2 = [
    { icon: 'add', text: 'Adicionar', tooltip: 'Adicionar', visible: true, disabled: false, color: 'default', onClick: () => this.addMovRow() },
  ]

  public toolbarMain = new Toolbar();
  public toolbarDetails = new Toolbar();
  public toolbarEdit = new Toolbar();
  public toolbarMov = new Toolbar();
  public toolbarMov2 = new Toolbar();

  tokenProgressoSocket: string  = null;
  private messagesSubscription: Subscription
  isLoadingInProgress: boolean = false
  loadingProgress: number = 0

  constructor(
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private configService: ConfigService,
    private httpService: HttpService,
    private httpClient: HttpClient,
    private titleService: TitleService,
    private messageService: MessageService,
    private socketService: PysocketService
  ) { }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
    this.toolbarService.clear();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titleService.title = "Dashboard Orçamento";

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.exibirOpcoesRelatorio = true;
          this.loggedUserId = this.sessionService.loggedUser.id;
          this.getVersoesGerencial();
          this.getListEmpresas();
        }
      }
    );

    this.messagesSubscription = this.socketService.socketData.subscribe((response) => {
      if (response.service === Service.NOTIFICATION && response.action === Action.LOADING && response.method === Method.GET) {
        this.loadingManagement(response)
      }
    })

    this.toolbarMain.setAll(this.toolbarMainIni);
    this.toolbarDetails.setAll(this.toolbarDetailsIni);
    this.toolbarEdit.setAll(this.toolbarEditIni);
    this.toolbarMov.setAll(this.toolbarMovIni);
    this.toolbarMov2.setAll(this.toolbarMovIni2);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  scroll({ target }) {
    if (target.className == 'ui-table-scrollable-body') {
      Array.from(document.querySelectorAll('.ui-table-unfrozen-view > .ui-table-scrollable-body')).forEach(item => {
        if (target.scrollLeft != item.scrollLeft) item.scrollLeft = target.scrollLeft
      })
    }
  }

  deletSelectedDet(rowId: string, mov = false) {
    if (mov) {
      const rowToDelete = this.tableEdicao.filter((item) => item.line_id === rowId)[0];
      switch (this.keyLevel) {
        case 1:
          this.movSalvosTemp[rowToDelete.key1_id] = {}
          break
        case 2:
          this.movSalvosTemp[rowToDelete.key1_id][rowToDelete.key2_id] = {}
          break
      }
      this.tableEdicao = this.tableEdicao.filter((item) => item.line_id !== rowId);
    } else {
      this.tableEdicao = this.tableEdicao.filter(
        (item) => item.line_id !== rowId
      );
    }
  }

  deletSelectedMov(rowId: string) {
    let rowToDelete = this.tableMov.filter((item) => item.line_id === rowId)[0];
    switch (this.keyLevel) {
      case 1:
        this.movSalvosTemp[rowToDelete.key1_id] = {}
        break
      case 2:
        this.movSalvosTemp[rowToDelete.key1_id][rowToDelete.key2_id] = {}
        break
      case 3:
        this.movSalvosTemp[rowToDelete.key1_id][rowToDelete.key2_id][rowToDelete.key3_id] = {}
        break
    }
    this.tableMov = this.tableMov.filter((item) => item.line_id !== rowId);
  }

  changeEditionState() {
    this.editionState = !this.editionState;
  }

  calcularTotalMov(event): void {
    this.tableMov[event.data]["total"] = 0;
    for (let mes of this.colmeses) {
      this.tableMov[event.data]["total"] += this.tableMov[event.data][mes[0]];
    }
  }

  deletarAjuste(row, fromDet = false): void {
    let key = this.isRealizadoVisao ? 'real' : this.isForecast ? 'for' : 'orc';
    let lineToDelete;
    //Deletar dos ajuste salvos e da tabela principal
    this.ajustesSalvos[key][this.selectedCodCta][this.selectedCompany].forEach((item: any, index) => {
      this.movMonths.forEach((month, ind) => {

        month = key === 'real' ? `${month}Real` : key === 'for' ? `${month}For` : month
        //Calcula o trimestre atual
        let currentTrim = ((ind + 1.5) / 3).toFixed(0)
        currentTrim += key === 'real' ? 'Real' : key === 'for' ? 'For' : ''

        switch (this.keyLevel) {
          case 1:
            if (item.key1_id == row.key1_id) {
              lineToDelete = index
              item.valor = 0;
              this.tableData[this.selectedCompany][this.rowEditid][month][item.key1_id].ajuste = 0;
              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][item.key1_id].ajuste = 0;
            }
            break
          case 2:
            if (item.key1_id == row.key1_id && item.key2_id == row.key2_id) {
              lineToDelete = index
              item.valor = 0;
              this.tableData[this.selectedCompany][this.rowEditid][month][item.key1_id][item.key2_id].ajuste = 0;
              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][item.key1_id][item.key2_id].ajuste = 0;
            }
            break
          case 3:
            if (item.key1_id == row.key1_id && item.key2_id == row.key2_id && item.key3_id == row.key3_id) {
              lineToDelete = index
              item.valor = 0;
              this.tableData[this.selectedCompany][this.rowEditid][month][item.key1_id][item.key2_id][item.key3_id].ajuste = 0;
              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][item.key1_id][item.key2_id][item.key3_id].ajuste = 0;
            }
            break
        }
      })
    })

    this.ajustesSalvos[key][this.selectedCodCta][this.selectedCompany].splice(lineToDelete, 1)

    //Deletar da tabela de ajustes
    if (!fromDet) this.tableAjustes.splice(this.tableAjustes.indexOf(row), 1);
    else this.openDetalhe(this.selectedCodCta, this.ctaSelecionada)

    //Recalcula a tabela
    this.recalcRow();

    this.formatValues();

  }

  backToOptions() {
    this.exibirOpcoesRelatorio = true;
    this.utilizarTxDif = false;
    this.txMoeda = 1;
    this.empresasSelecionadas = [];
    this.empresasSelecionadas.push(this.dblinkedSessionService.empresa.id);
  }

  //verifica se input esta vazio
  checkInput(event) {
    if (event.target.value == 0) event.target.value = null;
  }

  checkNegative(data: any, column: Array<any>): string {
    if (column[1].includes('Var. %')) {
      if ((data.ind_rd === 'D' && data[column[0]] > 0) || (data.ind_rd === 'R' && data[column[0]] < 0)) return 'negative'

    } else if (column[1].includes('Var.')) {
      if (data.ind_cta === 'S' || data.ind_cta === 'T') {
        if ((data.ind_rd === 'D' && data[column[0]] < 0) || (data.ind_rd === 'R' && data[column[0]] < 0)) return 'negative'

      } else {
        if ((data.ind_rd === 'D' && data[column[0]] > 0) || (data.ind_rd === 'R' && data[column[0]] < 0)) return 'negative'
      }
    }
    return '';
  }

  //Funcao para renderizar grafico na tela
  loadDonutChart(container, value) {
    new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: "pie",
        renderTo: container,
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormatter: function () {
          if (this.y >= 1e6) {
            return `${(this.y / 1e6).toFixed(3)}M - (${this.percentage.toFixed(
              0
            )}%)`;
          } else if (this.y >= 1e3) {
            return `${(this.y / 1e3).toFixed(3)}K - (${this.percentage.toFixed(
              0
            )}%)`;
          }
          return `${this.y} - (${this.percentage.toFixed(0)}%)`;
        },
      },
      plotOptions: {
        pie: {
          size: 250,
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            crop: false,
            padding: 0,
            style: {
              fontSize: "10px",
            },
            enabled: true,
            formatter: function () {
              if (this.y >= 1e6) {
                return `${(this.y / 1e6).toFixed(
                  3
                )}M <br/> (${this.percentage.toFixed(0)}%)`;
              } else if (this.y >= 1e3) {
                return `${(this.y / 1e3).toFixed(
                  3
                )}K <br/> (${this.percentage.toFixed(0)}%)`;
              }
              return `${this.y} <br/> (${this.percentage.toFixed(0)}%)`;
            },
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "VL_AJUSTE",
          type: "pie",
          data: value,
          innerSize: "60%",
        },
      ],
    });
  }

  openDetalheVisaoReal(cod_cta: any, cta_descr: string, openModal: boolean = false): void {
    if (openModal) this.changeModalEdicao();
    else this.visaoReal = true;
    this.ctaSelecionada = cta_descr;

    this.selectedCodCta = cod_cta;
    this.isSelectedCtaEditavel = this.listCtaVinculadas.includes(this.selectedCodCta);
    for (let i in this.tableData[this.selectedCompany]) {
      if (this.tableData[this.selectedCompany][i]["cod_cta"] === cod_cta) {
        this.rowEditid = Number(i);
        break;
      }
    }

    this.tableVisaoReal = new Array();
    let dicVisaoTemp: any = {};
    let data = this.deepCopy(this.tableData[this.selectedCompany][this.rowEditid]);

    //Formatar as informações mensais
    this.movMonths.forEach((month, index) => {
      let currentTrim = `q${((index + 1.5) / 3).toFixed(0)}`;
      let forecastColumn = this.isForecast && !this.limesreal.includes(month);

      for (let key1 in data[month]) {
        //executa se key1 for o ultimo nivel
        if (this.keyLevel === 1) {
          if (!(key1 in dicVisaoTemp)) {
            dicVisaoTemp[key1] = {
              total: { mov: 0, ajuste: 0, original: 0 },
              totalReal: { mov: 0, ajuste: 0, original: 0 }
            };
          }

          if (!(`${currentTrim}Total` in dicVisaoTemp[key1])) {
            dicVisaoTemp[key1][`${currentTrim}Total`] = { mov: 0, ajuste: 0, original: 0 }
            dicVisaoTemp[key1][`${currentTrim}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }
          }

          dicVisaoTemp[key1][`${month}Total`] = data[month][key1];
          dicVisaoTemp[key1][`${month}RealTotal`] = data[forecastColumn ? `${month}For` : `${month}Real`][key1]
            ? data[forecastColumn ? `${month}For` : `${month}Real`][key1]
            : { mov: 0, ajuste: 0, original: 0 }


        } else {
          if (!(key1 in dicVisaoTemp)) dicVisaoTemp[key1] = {};
          for (let key2 in data[month][key1]) {
            //executa se key2 for o ultimo nivel
            if (this.keyLevel === 2) {
              if (!(key2 in dicVisaoTemp[key1])) {
                dicVisaoTemp[key1][key2] = {
                  total: { mov: 0, ajuste: 0, original: 0 },
                  totalReal: { mov: 0, ajuste: 0, original: 0 }
                };
              }

              if (!(`${currentTrim}Total` in dicVisaoTemp[key1][key2])) {
                dicVisaoTemp[key1][key2][`${currentTrim}Total`] = { mov: 0, ajuste: 0, original: 0 }
                dicVisaoTemp[key1][key2][`${currentTrim}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }
              }

              dicVisaoTemp[key1][key2][`${month}Total`] = data[month][key1][key2];
              dicVisaoTemp[key1][key2][`${month}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }

              if (data[forecastColumn ? `${month}For` : `${month}Real`][key1])
                if (data[forecastColumn ? `${month}For` : `${month}Real`][key1][key2])
                  dicVisaoTemp[key1][key2][`${month}RealTotal`] = data[forecastColumn ? `${month}For` : `${month}Real`][key1][key2]

            } else {
              if (!(key2 in dicVisaoTemp[key1])) dicVisaoTemp[key1][key2] = {};
              for (let key3 in data[month][key1][key2]) {
                //executa se key3 for o ultimo nivel
                if (this.keyLevel === 3) {
                  if (!(key3 in dicVisaoTemp[key1][key2])) {
                    dicVisaoTemp[key1][key2][key3] = {
                      total: { mov: 0, ajuste: 0, original: 0 },
                      totalReal: { mov: 0, ajuste: 0, original: 0 }
                    };
                  }

                  if (!(`${currentTrim}Total` in dicVisaoTemp[key1][key2][key3])) {
                    dicVisaoTemp[key1][key2][key3][`${currentTrim}Total`] = { mov: 0, ajuste: 0, original: 0 }
                    dicVisaoTemp[key1][key2][key3][`${currentTrim}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }
                  }

                  dicVisaoTemp[key1][key2][key3][`${month}Total`] = data[month][key1][key2][key3];
                  dicVisaoTemp[key1][key2][key3][`${month}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }

                  if (data[forecastColumn ? `${month}For` : `${month}Real`][key1])
                    if (data[forecastColumn ? `${month}For` : `${month}Real`][key1][key2])
                      if (data[forecastColumn ? `${month}For` : `${month}Real`][key1][key2][key3])
                        dicVisaoTemp[key1][key2][key3][`${month}RealTotal`] = data[forecastColumn ? `${month}For` : `${month}Real`][key1][key2][key3]

                }
              }
            }
          }
        }
      }

      //Verifica contas de forecast caso seja
      if (this.isForecast) {
        dicVisaoTemp = this.verifyRealAccounts(dicVisaoTemp, data, 'For', month, currentTrim, forecastColumn)
      }

      //Verifica contas de real
      dicVisaoTemp = this.verifyRealAccounts(dicVisaoTemp, data, 'Real', month, currentTrim, forecastColumn)

      let lastMonth = this.movMonths[index - 1]

      switch (this.keyLevel) {
        case 1:
          for (let key1 in dicVisaoTemp) {
            for (let type of ['original', 'mov', 'ajuste']) {

              dicVisaoTemp[key1]["total"][type] += dicVisaoTemp[key1][`${month}Total`][type];
              dicVisaoTemp[key1]["totalReal"][type] += dicVisaoTemp[key1][`${month}RealTotal`][type];

              if (!this.isAccumulatedVision) {
                dicVisaoTemp[key1][`${currentTrim}Total`][type] += dicVisaoTemp[key1][`${month}Total`][type];
                dicVisaoTemp[key1][`${currentTrim}RealTotal`][type] += dicVisaoTemp[key1][`${month}RealTotal`][type];
              } else if (month !== "Jan") {
                dicVisaoTemp[key1][`${month}Total`][type] += dicVisaoTemp[key1][`${lastMonth}Total`][type];
                dicVisaoTemp[key1][`${month}RealTotal`][type] += dicVisaoTemp[key1][`${lastMonth}RealTotal`][type];

                dicVisaoTemp[key1][`${currentTrim}Total`][type] = dicVisaoTemp[key1][`${month}Total`][type];
                dicVisaoTemp[key1][`${currentTrim}RealTotal`][type] = dicVisaoTemp[key1][`${month}RealTotal`][type];
              }
            }
          }
          break
        case 2:
          for (let key1 in dicVisaoTemp) {
            for (let key2 in dicVisaoTemp[key1]) {
              for (let type of ['original', 'mov', 'ajuste']) {
                dicVisaoTemp[key1][key2]["total"][type] += dicVisaoTemp[key1][key2][`${month}Total`][type];
                dicVisaoTemp[key1][key2]["totalReal"][type] += dicVisaoTemp[key1][key2][`${month}RealTotal`][type];

                if (!this.isAccumulatedVision) {
                  dicVisaoTemp[key1][key2][`${currentTrim}Total`][type] += dicVisaoTemp[key1][key2][`${month}Total`][type];
                  dicVisaoTemp[key1][key2][`${currentTrim}RealTotal`][type] += dicVisaoTemp[key1][key2][`${month}RealTotal`][type];
                } else if (month !== "Jan") {
                  dicVisaoTemp[key1][key2][`${month}Total`][type] += dicVisaoTemp[key1][key2][`${lastMonth}Total`][type];
                  dicVisaoTemp[key1][key2][`${month}RealTotal`][type] += dicVisaoTemp[key1][key2][`${lastMonth}RealTotal`][type];

                  dicVisaoTemp[key1][key2][`${currentTrim}Total`][type] = dicVisaoTemp[key1][key2][`${month}Total`][type];
                  dicVisaoTemp[key1][key2][`${currentTrim}RealTotal`][type] = dicVisaoTemp[key1][key2][`${month}RealTotal`][type];
                }
              }
            }
          }
          break
        case 3:
          for (let key1 in dicVisaoTemp) {
            for (let key2 in dicVisaoTemp[key1]) {
              for (let key3 in dicVisaoTemp[key1][key2]) {
                for (let type of ['original', 'mov', 'ajuste']) {
                  dicVisaoTemp[key1][key2][key3]["total"][type] += dicVisaoTemp[key1][key2][key3][`${month}Total`][type];
                  dicVisaoTemp[key1][key2][key3]["totalReal"][type] += dicVisaoTemp[key1][key2][key3][`${month}RealTotal`][type];

                  if (!this.isAccumulatedVision) {
                    dicVisaoTemp[key1][key2][key3][`${currentTrim}Total`][type] += dicVisaoTemp[key1][key2][key3][`${month}Total`][type];
                    dicVisaoTemp[key1][key2][key3][`${currentTrim}RealTotal`][type] += dicVisaoTemp[key1][key2][key3][`${month}RealTotal`][type];
                  } else if (month !== "Jan") {
                    dicVisaoTemp[key1][key2][key3][`${month}Total`][type] += dicVisaoTemp[key1][key2][key3][`${lastMonth}Total`][type];
                    dicVisaoTemp[key1][key2][key3][`${month}RealTotal`][type] += dicVisaoTemp[key1][key2][key3][`${lastMonth}RealTotal`][type];

                    dicVisaoTemp[key1][key2][key3][`${currentTrim}Total`][type] = dicVisaoTemp[key1][key2][key3][`${month}Total`][type];
                    dicVisaoTemp[key1][key2][key3][`${currentTrim}RealTotal`][type] = dicVisaoTemp[key1][key2][key3][`${month}RealTotal`][type];
                  }
                }
              }
            }
          }
          break
      }
    })

    this.buildDetailsTableVariation(dicVisaoTemp)
    // this.showCharts();
  }

  verifyRealAccounts(table, data, prefix, month, currentTrim, forecastColumn) {
    for (let key1 in data[`${month}${prefix}`]) {
      //executa se key1 for o ultimo nivel
      if (this.keyLevel === 1) {
        if (!(key1 in table)) {
          table[key1] = {
            total: { mov: 0, ajuste: 0, original: 0 },
            totalReal: { mov: 0, ajuste: 0, original: 0 }
          };
        }

        if (!(`${currentTrim}Total` in table[key1])) {
          table[key1][`${currentTrim}Total`] = { mov: 0, ajuste: 0, original: 0 }
          table[key1][`${currentTrim}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }
        }

        table[key1][`${month}RealTotal`] = data[forecastColumn ? `${month}For` : `${month}Real`][key1];
        if (table[key1][`${month}RealTotal`] == undefined)
          table[key1][`${month}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }

        if (table[key1][`${month}Total`] == undefined)
          table[key1][`${month}Total`] = { mov: 0, ajuste: 0, original: 0 }

      } else {
        if (!(key1 in table)) table[key1] = {};
        for (let key2 in data[`${month}${prefix}`][key1]) {
          //executa se key2 for o ultimo nivel
          if (this.keyLevel === 2) {
            if (!(key2 in table[key1])) {
              table[key1][key2] = {
                total: { mov: 0, ajuste: 0, original: 0 },
                totalReal: { mov: 0, ajuste: 0, original: 0 }
              };
            }

            if (!(`${currentTrim}Total` in table[key1][key2])) {
              table[key1][key2][`${currentTrim}Total`] = { mov: 0, ajuste: 0, original: 0 }
              table[key1][key2][`${currentTrim}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }
            }

            if (data[forecastColumn ? `${month}For` : `${month}Real`][key1] === undefined)
              table[key1][key2][`${month}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }
            else if (data[forecastColumn ? `${month}For` : `${month}Real`][key1][key2] === undefined)
              table[key1][key2][`${month}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }
            else
              table[key1][key2][`${month}RealTotal`] = data[forecastColumn ? `${month}For` : `${month}Real`][key1][key2];

            if (table[key1][key2][`${month}Total`] == undefined)
              table[key1][key2][`${month}Total`] = { mov: 0, ajuste: 0, original: 0 }
          } else {
            if (!(key2 in table[key1])) table[key1][key2] = {};
            for (let key3 in data[`${month}${prefix}`][key1][key2]) {
              //executa se key2 for o ultimo nivel
              if (this.keyLevel === 3) {
                if (!(key3 in table[key1][key2])) {
                  table[key1][key2][key3] = {
                    total: { mov: 0, ajuste: 0, original: 0 },
                    totalReal: { mov: 0, ajuste: 0, original: 0 }
                  };
                }

                if (!(`${currentTrim}Total` in table[key1][key2][key3])) {
                  table[key1][key2][key3][`${currentTrim}Total`] = { mov: 0, ajuste: 0, original: 0 }
                  table[key1][key2][key3][`${currentTrim}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }
                }

                if (data[forecastColumn ? `${month}For` : `${month}Real`][key1][key2] === undefined)
                  table[key1][key2][key3][`${month}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }
                else if (data[forecastColumn ? `${month}For` : `${month}Real`][key1][key2][key3] === undefined)
                  table[key1][key2][key3][`${month}RealTotal`] = { mov: 0, ajuste: 0, original: 0 }
                else
                  table[key1][key2][key3][`${month}RealTotal`] = data[forecastColumn ? `${month}For` : `${month}Real`][key1][key2][key3];

                if (table[key1][key2][key3][`${month}Total`] == undefined)
                  table[key1][key2][key3][`${month}Total`] = { mov: 0, ajuste: 0, original: 0 }
              }
            }
          }
        }
      }
    }
    return table
  }

  pushToTable(isVar, data, type, key1 = '0', key2 = '0', key3 = '0') {
    let flag;
    flag = this.isMain(2) ? this.setDescription(key2, this.keyLevel !== 1 ? this.levels['2'][1] : '') : this.setDescription(key1, this.levels['1'][1])
    let lineList = {
      descr_key1: this.setDescription(key1, this.levels['1'][1]),
      key1_id: key1,
      descr_key2: this.setDescription(key2, this.keyLevel !== 1 ? this.levels['2'][1] : ''),
      key2_id: key2,
      descr_key3: this.setDescription(key3, this.keyLevel === 3 ? this.levels['3'][1] : ''),
      key3_id: key3,
      ind_rd: data["ind_rd"],
      ind_cta: data["ind_cta"],
      total: data["total"][type],
      totalReal: isVar && data["totalReal"][type],
      totalVar: isVar && data["totalReal"][type] - data["total"][type],
      totalVarPor: isVar && this.calcPercentage(data["totalReal"][type], data["total"][type]),
      options: this.keyLevel != 1 && !isVar ? (this.optionsOfFlag(flag, this.isMain(2) ? this.levels['3'][1] : this.levels['2'][1])) : [],
      fromAjuste: type === 'ajuste',
      blockActions: isVar ? true : this.isForecast && type === 'original' && this.setIsBlockedAct(data),
      fromMov: type === 'mov',
      isDestiny: type === 'mov' ? this.verifyOrigin(key1, key2, key3) : false,
      line_id: this.idv4()
    }

    if (this.visaoTrim) {
      this.movTrims.forEach(trim => {
        lineList[isVar ? `${trim}Total` : trim] = data[isVar ? `${trim}Total` : trim][type];
        if (isVar) {
          lineList[`${trim}RealTotal`] = data[`${trim}RealTotal`][type];
          lineList[`${trim}VarTotal`] = lineList[`${trim}RealTotal`] - lineList[`${trim}Total`];
          lineList[`${trim}VarPorTotal`] = this.calcPercentage(lineList[`${trim}RealTotal`], lineList[`${trim}Total`]);
        }
      })
    } else {
      this.movMonths.forEach(month => {
        lineList[isVar ? `${month}Total` : month] = data[isVar ? `${month}Total` : month][type];
        if (isVar) {
          lineList[`${month}RealTotal`] = data[`${month}RealTotal`][type];
          lineList[`${month}VarTotal`] = lineList[`${month}RealTotal`] - lineList[`${month}Total`];
          lineList[`${month}VarPorTotal`] = this.calcPercentage(lineList[`${month}RealTotal`], lineList[`${month}Total`]);
        }
      })
    }

    if (isVar)
      this.tableVisaoReal.push(lineList);
    else this.tableEdicao.push(lineList);
  }

  buildDetailsTableVariation(data) {
    //Popular tabela
    for (let type of ['original', 'mov', 'ajuste']) {
      for (let key1 in data) {
        if (this.keyLevel === 1) {
          if ((data[key1]["total"][type] !== 0 && data[key1]["total"][type])
            || (data[key1]["totalReal"][type] !== 0 && data[key1]["totalReal"][type])) {

            if (type == 'mov' && data[key1]["total"][type] < 0) {
              this.tableVisaoReal.forEach(item => {
                if (item.ccus_id == key1) {
                  if (this.visaoTrim) {
                    this.movTrims.forEach(trim => {
                      item[`${trim}Total`] += data[key1][`${trim}Total`].mov;
                      item[`${trim}VarTotal`] = item[`${trim}RealTotal`] - item[`${trim}Total`];
                      item[`${trim}VarPorTotal`] = this.calcPercentage(item[`${trim}RealTotal`], item[`${trim}Total`]);
                    })
                  } else {
                    this.movMonths.forEach(month => {
                      item[`${month}Total`] += data[key1][`${month}Total`].mov;
                      item[`${month}VarTotal`] = item[`${month}RealTotal`] - item[`${month}Total`]
                      item[`${month}VarPorTotal`] = this.calcPercentage(item[`${month}RealTotal`], item[`${month}Total`]);
                    })
                  }
                  item.total += data[key1].total.mov;
                  item.totalVar = item.totalReal - item.total
                  item.totalVarPor = this.calcPercentage(item.totalReal, item.total)
                }
              })
            } else this.pushToTable(true, data[key1], type, key1)
          }
        } else {
          for (let key2 in data[key1]) {
            if (this.keyLevel === 2) {
              if ((data[key1][key2]["total"][type] !== 0 && data[key1][key2]["total"][type])
                || (data[key1][key2]["totalReal"][type] !== 0 && data[key1][key2]["totalReal"][type])) {
                if (type == 'mov' && data[key1][key2]["total"][type] < 0) {
                  this.tableVisaoReal.forEach(item => {
                    if (item.ccus_id == key1 && item.proj_id == key2) {
                      if (this.visaoTrim) {
                        this.movTrims.forEach(trim => {
                          item[`${trim}Total`] += data[key1][key2][`${trim}Total`].mov;
                          item[`${trim}VarTotal`] = item[`${trim}RealTotal`] - item[`${trim}Total`];
                          item[`${trim}VarPorTotal`] = this.calcPercentage(item[`${trim}RealTotal`], item[`${trim}Total`]);
                        })
                      } else {
                        this.movMonths.forEach(month => {
                          item[`${month}Total`] += data[key1][key2][`${month}Total`].mov;
                          item[`${month}VarTotal`] = item[`${month}RealTotal`] - item[`${month}Total`];
                          item[`${month}VarPorTotal`] = this.calcPercentage(item[`${month}RealTotal`], item[`${month}Total`]);
                        })
                      }
                      item.total += data[key1][key2].total.mov;
                      item.totalVar = item.totalReal - item.total
                      item.totalVarPor = this.calcPercentage(item.totalReal, item.total)
                    }
                  })
                } else this.pushToTable(true, data[key1][key2], type, key1, key2)
              }
            } else {
              for (let key3 in data[key1][key2]) {
                if ((data[key1][key2][key3]["total"][type] !== 0 && data[key1][key2][key3]["total"][type])
                  || (data[key1][key2][key3]["totalReal"][type] !== 0 && data[key1][key2][key3]["totalReal"][type])) {
                  if (type == 'mov' && data[key1][key2][key3]["total"][type] < 0) {
                    this.tableVisaoReal.forEach(item => {
                      if (item.key1_id == key1 && item.key2_id == key2 && item.key3_id == key3) {
                        if (this.visaoTrim) {
                          this.movTrims.forEach(trim => {
                            item[`${trim}Total`] += data[key1][key2][key3][`${trim}Total`].mov;
                            item[`${trim}VarTotal`] = item[`${trim}RealTotal`] - item[`${trim}Total`];
                            item[`${trim}VarPorTotal`] = this.calcPercentage(item[`${trim}RealTotal`], item[`${trim}Total`]);
                          })
                        } else {
                          this.movMonths.forEach(month => {
                            item[`${month}Total`] += data[key1][key2][key3][`${month}Total`].mov;
                            item[`${month}VarTotal`] = item[`${month}RealTotal`] - item[`${month}Total`];
                            item[`${month}VarPorTotal`] = this.calcPercentage(item[`${month}RealTotal`], item[`${month}Total`]);
                          })
                        }
                        item.total += data[key1][key2][key3].total.mov;
                        item.totalVar = item.totalReal - item.total
                        item.totalVarPor = this.calcPercentage(item.totalReal, item.total)
                      }
                    })
                  } else this.pushToTable(true, data[key1][key2][key3], type, key1, key2, key3)
                }
              }
            }
          }
        }
      }
    }

  }

  calcPercentage(dividend, divider) {
    return divider != 0 ? (dividend / divider) - 1 : 0;
  }

  fullScreen(dash: string) {
    switch (dash) {
      case "Dash1":
        this.fullDash1 = !this.fullDash1;
        break;
      case "Dash2":
        this.fullDash2 = !this.fullDash2;
        break;
      case "MainTable":
        let element: any = document.querySelector(".mat-sidenav");
        this.fullMainTable = !this.fullMainTable;
        if (this.fullMainTable) element.style.zIndex = 0;
        else element.style.zIndex = 2;
        break;
    }
  }

  ShowHIdeCard(dash: string): void {
    if (dash === "Dash1") this.isCardDash1 = !this.isCardDash1;
    else this.isCardDash2 = !this.isCardDash2;
  }

  changeModalEdicao() {
    this.modalEdicao = !this.modalEdicao;
  }

  changeVision(state: boolean, type: string): void {
    this.clearFilters()
    this.closeAjustes();
    if (type === "trim") {
      this.visaoTrim = state;
    } else if (type === "concise") {
      this.isConciseVision = state;
      this.formatValues();
    } else if (type === "accumulated") {
      this.isAccumulatedVision = state;
      this.formatValues();
    } else if (type === "orcado") {
      this.isRealizadoVisao = state;
      this.taxaMes();
      this.formatValues();
    }
  }

  @ViewChild('dt') table: Table;
  @ViewChild('dtt') table2: Table;
  clearFilters() {
    if (this.statusLink1) this.table.reset()
    else this.table2.reset()
  }

  showTypeDashboard(tipo) {
    this.clearFilters();
    this.closeAjustes();
    this.statusLink1 = false;
    this.statusLink2 = false;
    if (tipo === "LINK_1") {
      this.statusLink1 = true;
      this.toolbarDetails.setVisible('btn1', true);
      this.toolbarDetails.setVisible('btn2', true);
      this.toolbarDetails.setVisible('btn3', true);
      this.toolbarEdit.setVisible('btn1', true);
      this.toolbarEdit.setVisible('btn2', true);
      this.toolbarEdit.setVisible('btn3', true);
    } else if (tipo === "LINK_2") {
      this.statusLink2 = true;
      this.toolbarDetails.setVisible('btn1', false);
      this.toolbarDetails.setVisible('btn2', false);
      this.toolbarDetails.setVisible('btn3', false);
      this.toolbarEdit.setVisible('btn1', false);
      this.toolbarEdit.setVisible('btn2', false);
      this.toolbarEdit.setVisible('btn3', false);
    }
  }

  esconderLinhas() {
    this.tableValuesOrca = new Array();
    this.tableValuesVar = new Array();

    for (let line of this.tableValues) {
      let line2 = this.deepCopy(line);
      let mov1: boolean = false
      let mov2: boolean = false
      line2.total = 0;
      line2.totalOrig = 0;
      line.totalReal = this.isForecast ? 0 : line.totalReal;
      line["q1Total"] = line["q2Total"] = line["q3Total"] = line["q4Total"] = 0;
      line2["q1Total"] = line2["q2Total"] = line2["q3Total"] = line2["q4Total"] = 0;
      if (this.isForecast) line["q1RealTotal"] = line["q2RealTotal"] = line["q3RealTotal"] = line["q4RealTotal"] = 0;
      this.movMonths.forEach((month, index) => {
        let lastMonth = this.movMonths[index - 1]
        let currentTrim = `q${(Math.floor(index / 3) + 1)}`
        if (this.isForecast || this.isRealizadoVisao) {
          if (this.limesreal.includes(month) || this.isRealizadoVisao) line2[`${month}Total`] = line2[`${month}RealTotal`]
          else {
            line2[`${month}Total`] = line2[`${month}ForTotal`]
            line2.totalOrig += line2[`${month}ForTotal`]
            line[`${month}RealTotal`] = line[`${month}ForTotal`]
          }
          line[`${currentTrim}RealTotal`] += line[`${month}RealTotal`]
          line.totalReal += line[`${month}RealTotal`]
        }
        line2.total += line2[`${month}Total`];
        if (!this.isAccumulatedVision || month === "Jan") {
          line2[`${currentTrim}Total`] += line2[`${month}Total`];
          line[`${currentTrim}Total`] += line[`${month}Total`];
        } else {
          line2[`${month}Total`] += line2[`${lastMonth}Total`]
          line[`${month}Total`] += line[`${lastMonth}Total`]
          line[`${month}RealTotal`] += line[`${lastMonth}RealTotal`]
          if ([1, 2, 3, 4].includes((index + 1) / 3)) {
            line2[`${currentTrim}Total`] = line2[`${month}Total`];
            line[`${currentTrim}Total`] = line[`${month}Total`];
            line[`${currentTrim}RealTotal`] = line[`${month}RealTotal`];
          }
        }

        if (line2[`${month}Total`] != 0) mov1 = true
        if (line[`${month}Total`] != 0 || (this.isForecast && line[`${month}RealTotal`] != 0)) mov2 = true
      })

      if (this.hideLines) {
        if (mov1) this.tableValuesOrca.push(line2);
        if (mov2) this.tableValuesVar.push(line);

      } else {
        this.tableValuesOrca.push(line2);
        this.tableValuesVar.push(line);
      }
    }
  }


  //Chamada de renderizacao dos charts
  showCharts() {
    if (this.tableEdicao.length > 0) {
      let dataToRender = this.tableEdicao.map(
        (item) => new Object({ name: item.descr_key1, y: Math.abs(item.total) })
      );
      this.loadDonutChart("chart_1", dataToRender);
    } else {
      setTimeout(() => {
        this.loadDonutChart("chart_1", [
          { name: "2020", y: 1000 },
          { name: "2021", y: 1000 },
        ]);
      }, 1000);
    }
  }

  //Funcao para calcular dados do footer da tabela de detalhes
  calcTotalEdit(data, month, percent = false) {
    let response: any = 0;
    if (percent) {
      response = this.toPercent(this.calcPercentage(this.currentPercentAux[1], this.currentPercentAux[0])) + "%";
      this.currentPercentAux = [];
    } else {
      data.map((item) => (response += item[month]));
      if (!month.includes("Var") && !month.includes("Real")) this.currentPercentAux.push(response);
      else if (month.includes("Real")) this.currentPercentAux.push(response);
    }
    return response;
  }

  getAllProj(): void {
    let key = this.keyOf('PROJ')
    if (key == -1) return
    this.editOptions.forEach((id: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
          empresaid: id.toString(),
          ccusid: this.centroCustoEdicao.toString()
        }),
      };

      this.httpService.wait();
      this.httpClient
        .get(
          this.configService.defaultUrlApiUpload + "contabil/proj",
          httpOptions
        )
        .subscribe(
          (res) => {
            if (res["error"]) {
              this.exibirMensagem(res);
            } else {
              let auxDescr = {};
              this.descEdicao[id] = {}

              for (let line of res["mensagem"]) {
                auxDescr[line["id"]] =
                  line["cod_proj"] + " - " + line["descr_proj"];
              }

              this.descEdicao[id] = auxDescr;
            }

            this.httpService.done();
          },

          (err) => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    })
  }

  changeEmpresaSelecionada() {
    this.listEmpresasSelecionadas = new Array();
    for (let empresa of this.empresasSelecionadas) {
      let opcao = this.listEmpresas.find(i => i.value == empresa);
      if (opcao) this.listEmpresasSelecionadas.push(opcao);
    }
  }

  saveOptions(key, data) {
    switch (key) {
      case 1:
        this.optionsKey1 = data
        break
      case 2:
        this.optionsKey2 = data
        break
      case 3:
        this.optionsKey3 = data
        break
    }
  }

  getCcusOrca(key) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        versao: this.versao.versao,
        empresaid: this.empresasSelecionadas.toString(),
        userid: this.sessionService.loggedUser.id.toString(),
        ano: this.versao.ano.toString()
      }),
    }

    this.httpClient.get(this.configService.defaultUrlApiUpload + "orcamento/ccus", httpOptions)
        .subscribe(
          (res) => {
            if (res["error"]) this.exibirMensagem(res)
            else {
              let opcoesCentroCustos = []
              this.ccusRelac = {}
              opcoesCentroCustos.push({ label: "Não informado", value: "0" })
              this.ccusRelac[0] = false
              for (let line of res["mensagem"]) {
                opcoesCentroCustos.push({label: line["cod_ccus"] + " - " + line["descr_ccus"], value: line["cod_ccus"]})
                this.ccusRelac[line["id"]] = line["projetos_relacionados"]
              }
              this.saveOrcaOptions(key, opcoesCentroCustos)
            }
          },

          (err) => {
            this.exibirMensagem(err)
            console.log(err)
          }
        )
  }

  getAreaOrca(key) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.empresasSelecionadas.toString(),
        userid: this.sessionService.loggedUser.id.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "cadastro/area", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res);
          } else {
            let opcoesArea = [];
            opcoesArea.push({ label: "Não informado", value: "0" });
            res["data"].forEach(line => {
              opcoesArea.push({
                label: line["codigo"] + " - " + line["descricao"],
                value: line["codigo"],
              })
            })
            this.saveOrcaOptions(key, opcoesArea)
          }
          this.httpService.done();
        },
        (err) => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao buscar as áreas, contate o suporte: " + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getSubAreaOrca(key) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        versao: this.versao.versao.toString(),
        areas: (key == 2 ? this.selectedKey1 : this.selectedKey2).toString(),
        ano: this.versao.ano.toString(),
        empresas: this.dblinkedSessionService.empresa.id.toString()
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "orcamento/subarea", httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            let opcoesSubArea = [];
            res["data"].forEach(line => opcoesSubArea.push(line))
            this.saveOrcaOptions(key, opcoesSubArea)
          }
          this.httpService.done();
        },

        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao buscar as subareas, contatar o suporte: " + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getProjOrca(key) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        versaoid: this.versao.versao.toString(),
        ccus: (key == 2 ? this.selectedKey1 : this.selectedKey2).toString(),
        ano: this.versao.ano.toString(),
        empresas: this.empresasSelecionadas.toString()
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "orcamento/proj", httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            let opcoesProjetos = [];
            for (let line of res["data"]) {
              opcoesProjetos.push({
                label: line["cod_proj"] + " - " + line["descr_proj"],
                value: line["cod_proj"],
              });
            }
            this.saveOrcaOptions(key, opcoesProjetos)
          }
          this.httpService.done();
        },

        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao buscar os projetos, contatar o suporte: " + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  saveOrcaOptions(key, data) {
    switch (key) {
      case 1:
        this.optionsOrcaKey1 = data
        break
      case 2:
        this.optionsOrcaKey2 = data
        break
      case 3:
        this.optionsOrcaKey3 = data
        break
    }
  }

  getOrcaOptions(type = null, key = null) {
    if (type) {
      switch (this.levels[key][1]) {
        case 'PROJ':
          this.getProjOrca(key)
          break;
        case 'SUBAREA':
          this.getSubAreaOrca(key)
          break;
      }
    } else for (let key = 1; key <= this.keyLevel; key++) {
      switch (this.levels[key][1]) {
        case 'CCUS':
          this.getCcusOrca(key)
          break;
        case 'AREA':
          this.getAreaOrca(key)
          break;
      }
    }
  }

  getListEmpresas() {
    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + "cadastro/getgroupempresas/" + this.dblinkedSessionService.empresa.id + "-" + this.sessionService.loggedUser.id)
      .subscribe(
        res => {
          this.listEmpresas = []
          this.empresasSelecionadas = []
          res.data.forEach(row => this.listEmpresas.push({ value: row.id, label: row.fantasia }))
          this.empresasSelecionadas.push(this.dblinkedSessionService.empresa.id)
          this.selectedCompany = this.dblinkedSessionService.empresa.id
          this.changeEmpresaSelecionada()
          this.httpService.done()
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao obter as empresas do grupo, entrar em contato com o suporte: " + err.message })
          this.httpService.done()
          console.log(err)
        }
      )
  }

  keyOf(type) {
    let arr: any = Array.from(Object.values(this.levels)).map(item => item[1])
    return (arr.indexOf(type)) + 1
  }

  getCcus(): void {
    let key = this.keyOf('CCUS')
    if (key == -1) return
    this.editOptions.forEach((id: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
          empresaid: id.toString(),
          userid: this.sessionService.loggedUser.id.toString()
        }),
      };

      this.httpService.wait();
      this.httpClient
        .get(
          this.configService.defaultUrlApiUpload + "contabil/ccus",
          httpOptions
        )
        .subscribe(
          (res) => {
            if (res["error"]) {
              this.exibirMensagem(res);
            } else {
              this.descrCcus[id] = {}
              this.opcoesCentroCustosAjustes[id] = []
              for (let line of res["mensagem"]) {
                this.opcoesCentroCustosAjustes[id].push({
                  label: line["cod_ccus"] + " - " + line["descr_ccus"],
                  value: line["id"],
                });
                this.ccusRelac[line["id"]] = line["projetos_relacionados"];
                this.descrCcus[id][line["id"]] = line["cod_ccus"] + " - " + line["descr_ccus"];
                this.centroCustoEdicao.push(line["id"]);
              }

            }
            this.httpService.done();
          },

          (err) => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    })
    this.saveOptions(key, this.opcoesCentroCustosAjustes)
  }

  getArea() {
    let key = this.keyOf('AREA')
    if (key == -1) return
    this.editOptions.forEach((id: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
          empresaid: [id].toString(),
          userid: this.sessionService.loggedUser.id.toString(),
        }),
      };

      this.httpService.wait();
      this.httpClient.get(this.configService.defaultUrlApiUpload + "cadastro/area", httpOptions)
        .subscribe(
          (res) => {
            if (res["error"]) {
              this.exibirMensagem(res);
            } else {
              this.descrArea[id] = {}
              this.opcoesAreaAjustes[id] = [];
              this.opcoesAreaAjustes[id].push({ label: "Não informado", value: "0" });
              res["data"].forEach(line => {
                this.opcoesAreaAjustes[id].push({
                  label: line["codigo"] + " - " + line["descricao"],
                  value: line["id"],
                })
                this.descrArea[id][line["id"]] = line["codigo"] + " - " + line["descricao"];
              })
            }
            this.httpService.done();
          },
          (err) => {
            this.exibirMensagem({ error: true, mensagem: "Erro ao buscar as áreas, contate o suporte: " + err.message });
            console.log(err);
            this.httpService.done();
          }
        );
    })
    this.saveOptions(key, this.opcoesAreaAjustes)
  }

  getVersoesGerencial() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        ano: this.dblinkedSessionService.periodo.year.value.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "orcamento/dashboard/get-versoes-orcamento", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res);
          } else {
            this.listaVersoes = new Array();
            for (let line of res["data"]) {
              this.listaVersoes.push(line);
            }
          }
          this.httpService.done();
        },

        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  resetVariables() {
    this.keyLevel = 0;
    this.movSalvos = {};
    this.ajustesSalvos = { orc: {}, real: {}, for: {} };
    this.visaoReal = false;
    this.edicaoCta = false;
    this.tableValues = new Array();
    this.tableValuesOrig = new Array();
    this.opcoesCta = [];
    this.exibirOpcoesRelatorio = false;
    this.visaoTrim = false;
    this.isAccumulatedVision = false;
    this.hideLines = true;
    this.isConciseVision = false;
    this.limesreal = new Array();
    this.diccomentarios = {};
    this.listCtaVinculadas = new Array();
    this.isSelectedCtaEditavel = false;
    this.dicQtdComentarios = {};
  }

  buildListaForecast() {
    this.limesreal = new Array();
    if (this.versao.mes_min_forecast) {
      for (let m of this.colmeses) {
        m[3] = 'F';
      }

      for (let m = this.versao.mes_min_forecast; m <= this.versao.mes_max_forecast; m++) {
        this.limesreal.push(this.colmeses[m - 1][0]);
        this.colmeses[m - 1][3] = 'R';
      }

    } else {
      for (let m of this.colmeses) {
        m[3] = 'B';
      }
    }
    this.isForecast = this.limesreal.length !== 0
  }

  chooseNivelClass(row): string {
    let estilo: string = ""
    if (row.ind_cta != "A") estilo = "highlight-line strong "
    if (row.nivel === 1) {
      estilo += "nivel1"

    } else if (row.nivel === 2) {
      estilo += "nivel2"

    } else if (row.nivel === 3) {
      estilo += "nivel3"

    } else if (row.nivel === 4) {
      estilo += "nivel4"

    } else if (row.nivel === 5) {
      estilo += "nivel5"
    }

    return estilo
  }

  getGerencial(vers: any, first: boolean) {
    this.loadingProgress = 0
    this.versao = vers;
    this.ccusObrigatorio = this.versao.cad_ccus_obrigatorio
    this.tokenProgressoSocket = idv4()
    this.isLoadingInProgress = true
    const infos = {
      empresa_id: this.empresasSelecionadas,
      empresaConectadaId: this.dblinkedSessionService.empresa.id,
      tokenProgressoSocket: this.tokenProgressoSocket,
      liccus: this.getGerencialInfos('CCUS'),
      liproj: this.getGerencialInfos('PROJ'),
      liarea: this.getGerencialInfos('AREA'),
      lisubarea: this.getGerencialInfos('SUBAREA'),
      moeda_id: this.moeda,
      utilizarTxDif: this.utilizarTxDif,
      txMoeda: this.txMoeda,
      first: first,
      versao: this.versao,
      user_id: this.sessionService.loggedUser.id
    };
    this.httpService.post(this.configService.defaultUrlApiUpload + "orcamento/dashboard/get-valores-gerencial-new", infos)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res);
          } else {
            this.resetVariables()
            this.levels = res["data"]["infos"];
            this.keyLevel = this.levels.nivel;
            this.moedaVisu = res["data"]["moeda_orig"];
            this.moeda = res["data"]["moeda_orig"];
            this.editavel = res["data"]["editavel"];
            this.movSalvos = res["data"].mov;
            this.tableData = res["data"]["data"];
            this.taxaMeses = res["data"]["dictaxas"];
            this.diccomentarios = res["data"]["diccomentarios"];
            this.hideLines = vers["esconder_linhas"];
            this.isConciseVision = !vers["visao_detalhada"];
            this.visaoTrim = !vers["visao_mensal"];
            this.isAccumulatedVision = vers["visao_ytd"];
            this.buildListaForecast();
            this.taxaMes();
            this.formatValues();
            this.editOptions = this.empresasSelecionadas;
            this.tableValuesOrig = this.deepCopy(this.tableValues);
            this.opcaoSelecionada = vers.versao;
            this.dicQtdComentarios = res["data"]["dic_qtdcomentarios"];
            this.verifyAjuste();
            this.getOrcaOptions();
            Promise.resolve(this.getCcus()).then((res) => { this.getAllProj() });
            Promise.resolve(this.getArea()).then((res) => { this.getAllSubArea() });
            for (let line of this.tableValues) {
              if (line.ind_cta === 'A') this.opcoesCta.push({ label: line.cod_cta + " - " + line.descr_cta, value: line.cod_cta });
            }
            if (res["data"]["diccta"]) {
              for (let objCta of res["data"]["diccta"]) {
                this.listCtaVinculadas.push(objCta.cod_cta);
              }
            }
          }

          this.httpService.done();
        },

        (err) => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível carregar o orçamento. Contate o suporte." });
          console.log(err);
          this.httpService.done();
        },
        async ()=>{
          await new Promise(f => setTimeout(f, 1500));
          this.isLoadingInProgress = false
        }
      );
  }

  isMain(key) {
    if ((key === 3 && this.keyLevel !== 3) || (key === 2 && this.keyLevel === 1)) return true
    return ['CCUS', 'AREA'].includes(this.levels[key][1])
  }

  optionsOfFlag(label, type) {
    let flag = label.match(/^\d{1,4}/)[0];
    let options = [];
    switch (type) {
      case 'PROJ':
        options = flag ? Object.entries(this.descEdicao[this.selectedCompany])
          .filter((item: any) => item[1].match(/^\d{1,4}/)[0] === flag)
          .map((desc) => new Object({ label: desc[1], value: desc[0] }))
          : []
        break;
      case 'SUBAREA':
        options = flag ? Object.entries(this.descrSubArea[this.selectedCompany])
          .filter((item: any) => item[1].match(/^\d{1,4}/)[0] === flag)
          .map((desc) => new Object({ label: desc[1], value: desc[0] }))
          : []
        break;
    }
    return options
  }

  getKeyOptions(type, descr, lineId, ignore, isMov = false) {
    if (ignore) return
    let options = this.optionsOfFlag(descr, type)
    if (isMov) {
      this.tableMov.map((item) => {
        if (item.line_id === lineId) {
          item.options = options;
        }
      });
    } else {
      this.tableEdicao.map((item) => {
        if (item.line_id === lineId) {
          item.options = options;
        }
      });
    }

  }

  getAjusteOptions(key) {
    if (this.isMain(key)) return

    let desc = '', options = [], flag = '';

    switch (key - 1) {
      case 1:
        desc = this.setDescription(this.key1Ajuste, this.levels[key - 1][1])
        break
      case 2:
        desc = this.setDescription(this.key2Ajuste, this.levels[key - 1][1])
        break
    }

    flag = desc.match(/^\d{1,4}/)[0];

    switch (this.levels[key][1]) {
      case 'PROJ':
        options = flag ? Object.entries(this.descEdicao[this.selectedCompany])
          .filter((item: any) => item[1].match(/^\d{1,4}/)[0] === flag)
          .map((desc) => new Object({ label: desc[1], value: desc[0] }))
          : []
        break;
      case 'SUBAREA':
        options = flag ? Object.entries(this.descrSubArea[this.selectedCompany])
          .filter((item: any) => item[1].match(/^\d{1,4}/)[0] === flag)
          .map((desc) => new Object({ label: desc[1], value: desc[0] }))
          : []
        break;
    }

    switch (key) {
      case 2:
        this.optionsProjectKey2 = options
        break
      case 3:
        this.optionsProjectKey3 = options
        break
    }
  }

  getGerencialInfos(type) {
    let response = []
    for (let key = 1; key <= this.keyLevel; key++) {
      if (this.levels[key][1] == type) {
        switch (key) {
          case 1:
            response = this.selectedKey1
            break
          case 2:
            response = this.selectedKey2
            break
          case 3:
            response = this.selectedKey3
            break
        }
      }
    }
    return response
  }

  taxaMes() {
    for (let mes in this.taxaMeses) {
      for (let line of this.colmeses) {
        if (line[0] === mes) {
          if (!this.isRealizadoVisao && !this.limesreal.includes(mes)) {
            line[2] = this.taxaMeses[mes][0];

          } else {
            line[2] = this.taxaMeses[mes][1];
          }
          break;
        }
      }
    }

    Object.keys(this.taxaMeses).forEach((taxMonth: string) => {
      this.colmeses.forEach((informationsMonth: Array<any>) => {
        const currentMonth: string = informationsMonth[0]

        if (currentMonth === taxMonth) {
          this.colsvar.forEach((columnMonth: any) => {
            if (columnMonth[0].match(currentMonth)) {
              if (columnMonth[1] === 'Orc.') columnMonth[2] = this.taxaMeses[taxMonth][0]
              if (columnMonth[1] === 'Real.') columnMonth[2] = this.taxaMeses[taxMonth][1]
            }
          })
        }
      })
    })
  }

  formatValues() {
    let tableTemp = [], first = true, line: any = {};
    for (let company in this.tableData) {
      this.tableData[company].forEach((item: any, index: number) => {
        if (first) {
          tableTemp[index] = {
            cod_cta: item.cod_cta,
            descr_cta: item.descr_cta,
            formula: item.formula,
            id: item.id,
            ind_cta: item.ind_cta.toUpperCase(),
            ind_rd: item.ind_rd.toUpperCase(),
            nivel: item.nivel,
            ordem: item.ordem,
            parent_id: item.parent_id,
            is_porcentagem: item.is_porcentagem,
            inverter_sinal: item.inverter_sinal
          }
          line = item
        } else {
          line = this.tableData[company].filter(row => row.cod_cta === tableTemp[index].cod_cta)[0]
        }
        if (line.cod_cta == tableTemp[index].cod_cta) {
          this.colsvar.forEach(key => {
            if (!tableTemp[index].hasOwnProperty(key[0])) tableTemp[index][key[0]] = 0;
            tableTemp[index][key[0]] += line[key[0]];
          });
          this.colsvartrim.forEach(key => {
            if (key[0] !== "total" && key[0] !== "totalReal" && key[0] !== "totalVar" && key[0] !== "totalVarPor") {
              if (!tableTemp[index].hasOwnProperty(key[0])) tableTemp[index][key[0]] = 0;
              tableTemp[index][key[0]] += line[key[0]];
            }
          });
          this.movMonths.forEach((month, ind) => {
            month += 'ForTotal'
            let currentTrim = `q${((ind + 1.5) / 3).toFixed(0)}ForTotal`

            if ([1, 2, 3, 4].includes((ind + 1) / 3)) {
              if (!tableTemp[index].hasOwnProperty(currentTrim)) tableTemp[index][currentTrim] = 0;
              tableTemp[index][currentTrim] = line[currentTrim];
            }
            if (!tableTemp[index].hasOwnProperty(month)) tableTemp[index][month] = 0;
            tableTemp[index][month] += line[month];

          });
        }
      });
      first = false;
    }

    this.tableValues = tableTemp;
    this.esconderLinhas();
    this.atualizarContasSinteticas();
    this.updateVarPercentGlobal();
    this.changeVisionConcise();
  }

  changeLinesState() {
    this.hideLines = !this.hideLines;
    this.formatValues();
  }

  changeVisionConcise() {
    if (this.isConciseVision) {
      let tableTempOrca: Array<any> = new Array();
      let tableTempVar: Array<any> = new Array();
      for (let line of this.tableValuesOrca) {
        if (line["ind_cta"] !== "A") {
          tableTempOrca.push(line);
        }
      }

      for (let line of this.tableValuesVar) {
        if (line["ind_cta"] !== "A") {
          tableTempVar.push(line);
        }
      }

      this.tableValuesOrca = this.deepCopy(tableTempOrca);
      this.tableValuesVar = this.deepCopy(tableTempVar);
    }
  }

  verifyAjuste() {
    for (let type in this.ajustesSalvos) {
      let hasAjuste = false, key1_ajuste: any = null, key2_ajuste: any = null, key3_ajuste: any = null,
        defaultMonth = type === 'real' ? 'JanReal' : type === 'for' ? 'JanFor' : 'Jan';
      for (let company in this.tableData) {
        this.tableData[company].forEach((row) => {
          hasAjuste = false
          key1_ajuste = null; key2_ajuste = null; key3_ajuste = null;
          for (let key1 in row[defaultMonth]) {
            if (this.keyLevel === 1) {
              this.movMonths.forEach(month => {
                month = type === 'real' ? `${month}Real` : type === 'for' ? `${month}For` : month
                if (row[month][key1].ajuste !== 0) {
                  key1_ajuste = key1;
                  hasAjuste = true
                }
              })
            }
            else {
              for (let key2 in row[defaultMonth][key1]) {
                if (this.keyLevel === 2) {
                  this.movMonths.forEach(month => {
                    month = type === 'real' ? `${month}Real` : type === 'for' ? `${month}For` : month
                    if (row[month][key1][key2].ajuste !== 0) {
                      key1_ajuste = key1;
                      key2_ajuste = key2;
                      hasAjuste = true
                    }
                  })
                }
                else {
                  for (let key3 in row[defaultMonth][key1][key2]) {
                    this.movMonths.forEach(month => {
                      month = type === 'real' ? `${month}Real` : type === 'for' ? `${month}For` : month
                      if (row[month][key1][key2][key3].ajuste !== 0) {
                        key1_ajuste = key1;
                        key2_ajuste = key2;
                        key3_ajuste = key3;
                        hasAjuste = true
                      }
                    })
                  }
                }
              }
            }
            if (hasAjuste) {
              if (!this.ajustesSalvos[type].hasOwnProperty(row.cod_cta))
                this.ajustesSalvos[type][row.cod_cta] = {}
              if (!this.ajustesSalvos[type][row.cod_cta].hasOwnProperty(company))
                this.ajustesSalvos[type][row.cod_cta][company] = []

              let obj = {
                key1_id: key1_ajuste ? Number(key1_ajuste) : 0,
                key2_id: key2_ajuste ? Number(key2_ajuste) : 0,
                key3_id: key3_ajuste ? Number(key3_ajuste) : 0,
                valor: 0,
                comentarios: {}
              }
              this.movMonths.forEach(month => {
                month = type === 'real' ? `${month}Real` : type === 'for' ? `${month}For` : month
                let comentario = this.diccomentarios[company][row.id];
                if (comentario) comentario = this.diccomentarios[company][row.id][month];
                switch (this.keyLevel) {
                  case 1:
                    obj[month] = row[month][key1_ajuste].ajuste
                    obj.valor += row[month][key1_ajuste].ajuste
                    if (comentario) comentario = comentario[key1_ajuste];
                    obj.comentarios[month] = comentario;
                    break
                  case 2:
                    obj[month] = row[month][key1_ajuste][key2_ajuste].ajuste
                    obj.valor += row[month][key1_ajuste][key2_ajuste].ajuste
                    if (comentario) comentario = comentario[key1_ajuste];
                    if (comentario) comentario = comentario[key2_ajuste];
                    obj.comentarios[month] = comentario;
                    break
                  case 3:
                    obj[month] = row[month][key1_ajuste][key2_ajuste][key3_ajuste].ajuste
                    obj.valor += row[month][key1_ajuste][key2_ajuste][key3_ajuste].ajuste
                    if (comentario) comentario = comentario[key1_ajuste];
                    if (comentario) comentario = comentario[key2_ajuste];
                    if (comentario) comentario = comentario[key3_ajuste];
                    obj.comentarios[month] = comentario;
                    break
                }
              })
              this.ajustesSalvos[type][row.cod_cta][company].push(obj)
            }
          }
        })
      }
    }
  }

  calcularTotalLinhaDetalhe(event): void {
    this.tableEdicao[event.data]["total"] = 0;
    for (let mes of this.colmeses) {
      this.tableEdicao[event.data]["total"] +=
        this.tableEdicao[event.data][mes[0]];
    }
  }

  deepCopy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map((item) => this.deepCopy(item))
      : source instanceof Date
        ? new Date(source.getTime())
        : source && typeof source === "object"
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
            Object.defineProperty(
              o,
              prop,
              Object.getOwnPropertyDescriptor(source, prop)
            );
            o[prop] = this.deepCopy(source[prop]);
            return o;
          }, Object.create(Object.getPrototypeOf(source)))
          : (source as T);
  }

  getMoeda() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        ano: this.versao.ano.toString(),
      }),
    };

    this.httpClient
      .get(this.configService.defaultUrlApiUpload + "util/moedas", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res);
          } else {
            this.opcoesMoedas = [];
            for (let line of res["mensagem"]) {
              this.opcoesMoedas.push({ label: line["cod"], value: line["id"] });
            }
          }
          this.httpService.done();
        },

        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  moedaSelecionada() {
    return ("CONTA - Valores em " + this.opcoesMoedas.filter((item) => item.value === this.moedaVisu)[0].label);
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.msgs = [];
      this.messageService.add({
        key: 'messageLinnks',
        severity: "success",
        summary: "Sucesso",
        detail: value.mensagem,
      });
    } else {
      this.msgs = [];
      this.messageService.add({
        key: 'messageLinnks',
        severity: "warn",
        summary: "Erro",
        detail: value.mensagem,
      });
    }
  }

  hideMessage() {
    this.msgs = [];
  }

  formatarDataExtenso() {
    const dt = new Date();
    const month = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    const week = [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ];
    const semana = week[dt.getDay()];
    const mes = month[dt.getMonth()];

    return (`Hoje é ${semana}, ${dt.getDate()} de ${mes} de ${dt.getFullYear()}`);
  }

  salvarOrcamento() {
    if (
      confirm("Deseja realmente salvar essas alterações no banco de dados?")
    ) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
          versaoid: this.versao.versao_id.toString(),
          empresaid: [this.empresasSelecionadas].toString(),
          ano: this.versao.ano.toString(),
          userid: this.sessionService.loggedUser.id.toString(),
          tipoid: this.versao.tipo_id.toString(),
          moedaid: this.moeda.toString(),
          confid: this.versao.id.toString()
        }),
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + "orcamento/dashboard/salvar-orcamento", [this.tableData, this.movSalvos], httpOptions)
        .subscribe(
          (res) => {
            this.exibirMensagem(res);
            this.httpService.done();
            this.getGerencial(this.versao, false);
          },

          (err) => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /*FullScreen Card*/
  fullscreen: boolean = false;
  fullScreenCard() {
    this.fullscreen = !this.fullscreen;
  }

  hideModalAjustes() {
    this.modalAjustes = false;
  }

  // Change state card
  currentStateEdicao = "initial";
  changeStateEdicao() {
    this.currentStateEdicao =
      this.currentStateEdicao === "initial" ? "final" : "initial";
  }

  /*FullScreen Card*/
  fullscreenEdicao: boolean = false;
  fullScreenCardEdicao() {
    this.fullscreenEdicao = !this.fullscreenEdicao;
  }

  /*Show Hide Card*/
  isCardVisibleEdicao: boolean = true;
  ShowHideCardEdicao() {
    this.isCardVisibleEdicao = !this.isCardVisibleEdicao;
  }

  isCommentVisible: boolean = false;
  showComments() {
    this.isCommentVisible = !this.isCommentVisible;
  }

  toPercent(value: number) {
    return Math.round(value * 10000) / 100;
  }

  openModalAjuste(codCta, currentMonth): void {

    if (this.isAccumulatedVision) {
      this.exibirMensagem({ error: true, mensagem: "Não é possível fazer ajustes na visão YTD." });
      return;
    }

    if (this.isForecast && this.limesreal.includes(currentMonth) && !this.isRealizadoVisao) {
      this.exibirMensagem({ error: true, mensagem: "Para ajustar o realizado, usar a visão 'Realizado'" });
      return;
    }

    if (this.versao.status !== "Aberto") {
      this.exibirMensagem({ error: true, mensagem: "Essa versão está fechada." });
      return;
    }
    if (!this.editavel) {
      this.exibirMensagem({ error: true, mensagem: "Esta versão não pode ser mais editada." });
      return;
    }

    this.isSelectedCtaEditavel = this.listCtaVinculadas.includes(codCta);
    if (!this.isSelectedCtaEditavel) {
      this.exibirMensagem({ error: true, mensagem: "O usuário não possui permissão para fazer ajustes nesta conta." });
      return;
    }

    let key = this.isRealizadoVisao ? 'real' : this.isForecast ? 'for' : 'orc';

    let validation = false
    this.tableAjustes = new Array();
    this.closeAjustes();
    this.key3Ajuste = 0;
    this.key2Ajuste = 0;
    this.key1Ajuste = 0;
    this.optionsProjectKey1 = this.isMain(1) ? this.optionsKey1[this.selectedCompany] : []
    if (this.keyLevel !== 1) this.optionsProjectKey2 = this.isMain(2) ? this.optionsKey2[this.selectedCompany] : []
    if (this.keyLevel === 3) this.optionsProjectKey3 = this.isMain(3) ? this.optionsKey3[this.selectedCompany] : []
    this.selectedCodCta = codCta;
    this.isSelectedCtaEditavel = this.listCtaVinculadas.includes(this.selectedCodCta);
    for (let i in this.tableData[this.selectedCompany]) {
      if (this.tableData[this.selectedCompany][i]["cod_cta"] == this.selectedCodCta) {
        this.rowEditid = Number(i);
        break;
      }
    }
    this.opcoesModalProjetos = [];
    this.modalTitulo = `Ajustes ${this.tableData[this.selectedCompany][this.rowEditid].descr_cta}`;

    //Reseta os ajustes
    this.ajustes = new Array();
    for (let i: number = 1; i < 13; i++) {
      this.ajustes.push({ ref: i, valor: 0 });
    }

    if (this.ajustesSalvos[key].hasOwnProperty(codCta))
      if (this.ajustesSalvos[key][codCta].hasOwnProperty(this.selectedCompany))
        validation = true

    if (validation) {
      this.ajustesSalvos[key][codCta][this.selectedCompany].forEach(item => {
        this.tableAjustes.push({
          ...item,
          key1: this.setDescription(item.key1_id, this.levels['1'][1]),
          key2: this.keyLevel !== 1 && this.setDescription(item.key2_id, this.levels['2'][1]),
          key3: this.keyLevel === 3 && this.setDescription(item.key3_id, this.levels['3'][1])
        })
      })
    }
    this.modalAjustes = true;
  }

  changeSelectedCompany(id: number, fromAjuste = false, modal = false) {
    this.selectedCompany = id;
    if (fromAjuste) {
      this.openModalAjuste(this.selectedCodCta, null)
    } else {
      if (!this.statusLink2)
        this.openDetalhe(this.selectedCodCta, this.ctaSelecionada, modal)
      else
        this.openDetalheVisaoReal(this.selectedCodCta, this.ctaSelecionada, modal)
    };
  }

  // Mostra e formata tabela de detalhes
  openDetalhe(cod_cta: any, cta_descr: string, openModal: boolean = false): void {
    if (openModal) this.changeModalEdicao();
    else this.edicaoCta = true;
    this.ctaSelecionada = cta_descr;
    this.selectedCodCta = cod_cta;

    this.isSelectedCtaEditavel = this.listCtaVinculadas.includes(this.selectedCodCta);
    for (let i in this.tableData[this.selectedCompany]) {
      if (this.tableData[this.selectedCompany][i]["cod_cta"] === cod_cta) {
        this.rowEditid = Number(i);
        break;
      }
    }
    this.tableEdicao = new Array();
    let dicEdicaoTemp: any = {};
    let data = this.deepCopy(this.tableData[this.selectedCompany][this.rowEditid])

    //Formatar as informações mensais
    this.movMonths.forEach((month, index) => {

      let currentTrim = `q${((index + 1.5) / 3).toFixed(0)}`;

      let monthToReal = this.limesreal.includes(month);

      for (let key1 in data[this.isForecast ? `${month}For` : month]) {
        //executa se key1 for o ultimo nivel
        if (this.keyLevel === 1) {
          if (!(key1 in dicEdicaoTemp)) dicEdicaoTemp[key1] = { total: { original: 0, ajuste: 0, mov: 0 } };

          if (!(currentTrim in dicEdicaoTemp[key1]))
            dicEdicaoTemp[key1][currentTrim] = { mov: 0, ajuste: 0, original: 0 }

          if (this.isForecast || this.isRealizadoVisao) {
            if (monthToReal || this.isRealizadoVisao) {
              if (data[`${month}Real`][key1] == undefined)
                data[`${month}Real`][key1] = { mov: 0, ajuste: 0, original: 0 }

              dicEdicaoTemp[key1][month] = data[`${month}Real`][key1];

            } else {
              if (data[`${month}For`][key1] == undefined)
                data[`${month}For`][key1] = { mov: 0, ajuste: 0, original: 0 }

              dicEdicaoTemp[key1][month] = data[`${month}For`][key1];
            }
          } else {
            dicEdicaoTemp[key1][month] = data[month][key1];
          }
        } else {
          if (!(key1 in dicEdicaoTemp)) dicEdicaoTemp[key1] = {};
          for (let key2 in data[this.isForecast ? `${month}For` : month][key1]) {
            //executa se key2 for o ultimo nivel
            if (this.keyLevel === 2) {
              if (!(key2 in dicEdicaoTemp[key1]))
                dicEdicaoTemp[key1][key2] = { total: { original: 0, ajuste: 0, mov: 0 } };

              if (!(currentTrim in dicEdicaoTemp[key1][key2]))
                dicEdicaoTemp[key1][key2][currentTrim] = { mov: 0, ajuste: 0, original: 0 }

              if (this.isForecast || this.isRealizadoVisao) {
                if (monthToReal || this.isRealizadoVisao) {
                  if (data[`${month}Real`][key1] == undefined)
                    data[`${month}Real`][key1] = {}
                  if (data[`${month}Real`][key1][key2] == undefined)
                    data[`${month}Real`][key1][key2] = { mov: 0, ajuste: 0, original: 0 }

                  dicEdicaoTemp[key1][key2][month] =
                    data[`${month}Real`][key1][key2];

                } else {
                  if (data[`${month}For`][key1] == undefined)
                    data[`${month}For`][key1] = {}
                  if (data[`${month}For`][key1][key2] == undefined)
                    data[`${month}For`][key1][key2] = { mov: 0, ajuste: 0, original: 0 }

                  dicEdicaoTemp[key1][key2][month] =
                    data[`${month}For`][key1][key2];
                }
              } else {
                dicEdicaoTemp[key1][key2][month] =
                  data[month][key1][key2];
              }
            } else {
              if (!(key2 in dicEdicaoTemp[key1])) dicEdicaoTemp[key1][key2] = {};
              for (let key3 in data[this.isForecast ? `${month}For` : month][key1][key2]) {
                if (this.keyLevel === 3) {
                  if (!(key3 in dicEdicaoTemp[key1][key2]))
                    dicEdicaoTemp[key1][key2][key3] = { total: { original: 0, ajuste: 0, mov: 0 } };

                  if (!(currentTrim in dicEdicaoTemp[key1][key2][key3]))
                    dicEdicaoTemp[key1][key2][key3][currentTrim] = { mov: 0, ajuste: 0, original: 0 }

                  if (this.isForecast || this.isRealizadoVisao) {
                    if (monthToReal || this.isRealizadoVisao) {
                      if (data[`${month}Real`][key1][key2] == undefined)
                        data[`${month}Real`][key1][key2] = {}
                      if (data[`${month}Real`][key1][key2][key3] == undefined)
                        data[`${month}Real`][key1][key2][key3] = { mov: 0, ajuste: 0, original: 0 }

                      dicEdicaoTemp[key1][key2][key3][month] =
                        data[`${month}Real`][key1][key2][key3];

                    } else {
                      if (data[`${month}For`][key1][key2] == undefined)
                        data[`${month}For`][key1][key2] = {}
                      if (data[`${month}For`][key1][key2][key3] == undefined)
                        data[`${month}For`][key1][key2][key3] = { mov: 0, ajuste: 0, original: 0 }

                      dicEdicaoTemp[key1][key2][key3][month] =
                        data[`${month}For`][key1][key2][key3];
                    }
                  } else {
                    dicEdicaoTemp[key1][key2][key3][month] =
                      data[month][key1][key2][key3];
                  }
                }
              }
            }
          }
        }
      }

      if (this.isRealizadoVisao || this.isForecast) {
        for (let key1 in data[`${month}Real`]) {
          if (this.keyLevel === 1) {
            if (!(key1 in dicEdicaoTemp))
              dicEdicaoTemp[key1] = { total: { original: 0, ajuste: 0, mov: 0 } };
            if (!(month in dicEdicaoTemp[key1]))
              dicEdicaoTemp[key1][month] = { mov: 0, ajuste: 0, original: 0 };
            if (!(currentTrim in dicEdicaoTemp[key1]))
              dicEdicaoTemp[key1][currentTrim] = { mov: 0, ajuste: 0, original: 0 }

            if (this.isRealizadoVisao || monthToReal) {
              if (data[`${month}Real`][key1] == undefined)
                data[`${month}Real`][key1] = {}
              if (data[`${month}Real`][key1] == undefined)
                data[`${month}Real`][key1] = { mov: 0, ajuste: 0, original: 0 }

              if (monthToReal || this.isRealizadoVisao) {
                dicEdicaoTemp[key1][month] =
                  data[`${month}Real`][key1];
              }
            }
          } else for (let key2 in data[`${month}Real`][key1]) {
            if (!(key1 in dicEdicaoTemp)) dicEdicaoTemp[key1] = {};
            if (this.keyLevel === 2) {
              if (!(key2 in dicEdicaoTemp[key1]))
                dicEdicaoTemp[key1][key2] = { total: { original: 0, ajuste: 0, mov: 0 } };
              if (!(month in dicEdicaoTemp[key1][key2]))
                dicEdicaoTemp[key1][key2][month] = { mov: 0, ajuste: 0, original: 0 };

              if (!(currentTrim in dicEdicaoTemp[key1][key2]))
                dicEdicaoTemp[key1][key2][currentTrim] = { mov: 0, ajuste: 0, original: 0 }

              if (this.isRealizadoVisao || monthToReal) {
                if (data[`${month}Real`][key1] == undefined)
                  data[`${month}Real`][key1] = {}
                if (data[`${month}Real`][key1][key2] == undefined)
                  data[`${month}Real`][key1][key2] = { mov: 0, ajuste: 0, original: 0 }

                if (monthToReal || this.isRealizadoVisao) {
                  dicEdicaoTemp[key1][key2][month] =
                    data[`${month}Real`][key1][key2];
                }
              }
            } else for (let key3 in data[`${month}Real`][key1][key2]) {
              if (!(key2 in dicEdicaoTemp[key1])) dicEdicaoTemp[key1][key2] = {};
              if (this.keyLevel === 3) {
                if (!(key3 in dicEdicaoTemp[key1][key2]))
                  dicEdicaoTemp[key1][key2][key3] = { total: { original: 0, ajuste: 0, mov: 0 } };
                if (!(month in dicEdicaoTemp[key1][key2][key3]))
                  dicEdicaoTemp[key1][key2][key3][month] = { mov: 0, ajuste: 0, original: 0 };

                if (!(currentTrim in dicEdicaoTemp[key1][key2][key3]))
                  dicEdicaoTemp[key1][key2][key3][currentTrim] = { mov: 0, ajuste: 0, original: 0 }

                if (this.isRealizadoVisao || monthToReal) {
                  if (data[`${month}Real`][key1][key2] == undefined)
                    data[`${month}Real`][key1][key2] = {}
                  if (data[`${month}Real`][key1][key2][key3] == undefined)
                    data[`${month}Real`][key1][key2][key3] = { mov: 0, ajuste: 0, original: 0 }

                  if (monthToReal || this.isRealizadoVisao) {
                    dicEdicaoTemp[key1][key2][key3][month] =
                      data[`${month}Real`][key1][key2][key3];
                  }
                }
              }
            }
          }
        }
      }
      let lastMonth = this.movMonths[index - 1]
      switch (this.keyLevel) {
        case 1:
          for (let key1 in dicEdicaoTemp) {
            for (let type of ['original', 'mov', 'ajuste']) {
              dicEdicaoTemp[key1]["total"][type] +=
                dicEdicaoTemp[key1][month][type];
              if (!this.isAccumulatedVision)
                dicEdicaoTemp[key1][currentTrim][type] += dicEdicaoTemp[key1][month][type];
              else if (month !== "Jan") {
                dicEdicaoTemp[key1][month][type] += dicEdicaoTemp[key1][lastMonth][type]
                dicEdicaoTemp[key1][currentTrim][type] = dicEdicaoTemp[key1][month][type];
              }
            }
          }
          break
        case 2:
          for (let key1 in dicEdicaoTemp) {
            for (let key2 in dicEdicaoTemp[key1]) {
              for (let type of ['original', 'mov', 'ajuste']) {
                dicEdicaoTemp[key1][key2]["total"][type] +=
                  dicEdicaoTemp[key1][key2][month][type];
                if (!this.isAccumulatedVision)
                  dicEdicaoTemp[key1][key2][currentTrim][type] += dicEdicaoTemp[key1][key2][month][type];
                else if (month !== "Jan") {
                  dicEdicaoTemp[key1][key2][month][type] += dicEdicaoTemp[key1][key2][lastMonth][type]
                  dicEdicaoTemp[key1][key2][currentTrim][type] = dicEdicaoTemp[key1][key2][month][type];
                }
              }
            }
          }
          break
        case 3:
          for (let key1 in dicEdicaoTemp) {
            for (let key2 in dicEdicaoTemp[key1]) {
              for (let key3 in dicEdicaoTemp[key1][key2]) {
                for (let type of ['original', 'mov', 'ajuste']) {
                  dicEdicaoTemp[key1][key2][key3]["total"][type] +=
                    dicEdicaoTemp[key1][key2][key3][month][type];
                  if (!this.isAccumulatedVision)
                    dicEdicaoTemp[key1][key2][key3][currentTrim][type] += dicEdicaoTemp[key1][key2][key3][month][type];
                  else if (month !== "Jan") {
                    dicEdicaoTemp[key1][key2][key3][month][type] += dicEdicaoTemp[key1][key2][key3][lastMonth][type]
                    dicEdicaoTemp[key1][key2][key3][currentTrim][type] = dicEdicaoTemp[key1][key2][key3][month][type];
                  }
                }
              }
            }
          }
          break
      }
    })
    this.buildDetailsTable(dicEdicaoTemp)
    // this.showCharts();
  }

  buildDetailsTable(data) {
    //Popular tabela
    for (let key1 in data) {
      if (this.keyLevel === 1) {
        for (let type of ['original', 'mov', 'ajuste']) {
          if (data[key1]["total"][type] !== 0 && data[key1]["total"][type]) {
            if (type == 'mov' && data[key1]["total"][type] < 0) {
              this.tableEdicao.forEach(item => {
                if (item.key1_id == key1) {
                  if (this.visaoTrim) {
                    this.movTrims.forEach(trim => {
                      item[trim] += data[key1][trim][type]
                    })
                  } else {
                    this.movMonths.forEach(month => {
                      item[month] += data[key1][month].mov
                    })
                  }
                  item.total += data[key1].total.mov
                }
              })
            } else this.pushToTable(false, data[key1], type, key1)
          }
        }
      } else {
        for (let key2 in data[key1]) {
          if (this.keyLevel === 2) {
            for (let type of ['original', 'mov', 'ajuste']) {
              if (data[key1][key2]["total"][type] !== 0 && data[key1][key2]["total"][type]) {

                //flag para pegar opcoes do dropdown da linha
                let flag = this.descEdicao[this.selectedCompany][key2] && this.descEdicao[this.selectedCompany][key2].match(/^\d{3}/)[0];
                if (type == 'mov' && data[key1][key2]["total"][type] < 0) {
                  this.tableEdicao.forEach(item => {
                    if (item.key1_id == key1 && item.key2_id == key2) {
                      if (this.visaoTrim) {
                        this.movTrims.forEach(trim => {
                          item[trim] += data[key1][key2][trim][type]
                        })
                      } else {
                        this.movMonths.forEach(month => {
                          item[month] += data[key1][key2][month][type]
                        })
                      }
                      item.total += data[key1][key2].total.mov
                    }
                  })
                } else this.pushToTable(false, data[key1][key2], type, key1, key2)
              }
            }
          } else {
            for (let key3 in data[key1][key2]) {
              if (this.keyLevel === 3) {
                for (let type of ['original', 'mov', 'ajuste']) {
                  if (data[key1][key2][key3]["total"][type] !== 0 && data[key1][key2][key3]["total"][type]) {

                    //flag para pegar opcoes do dropdown da linha
                    let flag = this.descEdicao[this.selectedCompany][key3] && this.descEdicao[this.selectedCompany][key3].match(/^\d{3}/)[0];
                    if (type == 'mov' && data[key1][key2][key3]["total"][type] < 0) {
                      this.tableEdicao.forEach(item => {
                        if (item.key1_id == key1 && item.key2_id == key2 && item.key3_id == key3) {
                          if (this.visaoTrim) {
                            this.movTrims.forEach(trim => {
                              item[trim] += data[key1][key2][key3][trim][type]
                            })
                          } else {
                            this.movMonths.forEach(month => {
                              item[month] += data[key1][key2][key3][month][type]
                            })
                          }
                          item.total += data[key1][key2][key3].total.mov
                        }
                      })
                    } else this.pushToTable(false, data[key1][key2][key3], type, key1, key2, key3)
                  }
                }
              }
            }
          }
        }
      }
    }
    this.movToDelete = {}
    this.movSalvosTemp = this.findMovs();
  }

  setIsBlockedAct(data) {
    let total = 0
    this.movMonths.forEach(month => {
      if (!this.limesreal.includes(month)) {
        total += data[month].original
      }
    })
    return total === 0
  }

  findMovs() {
    let response = {};
    for (let cta in this.movSalvos) {
      for (let key1 in this.movSalvos[cta]) {
        if (this.keyLevel === 1) {
          if (cta === String(this.selectedCodCta) || this.movSalvos[cta][key1].cta_origin === this.selectedCodCta) {
            if (!response.hasOwnProperty(key1)) {
              response[key1] = {}
            }
            response[key1] = { ...this.movSalvos[cta][key1] }
          }
        }
        else {
          for (let key2 in this.movSalvos[cta][key1]) {
            if (this.keyLevel === 2) {
              if (cta === String(this.selectedCodCta) || this.movSalvos[cta][key1][key2].cta_origin === this.selectedCodCta) {
                if (!response.hasOwnProperty(key1)) {
                  response[key1] = {}
                }
                response[key1][key2] = { ...this.movSalvos[cta][key1][key2] }
              }
            } else for (let key3 in this.movSalvos[cta][key1][key2]) {
              if (this.keyLevel === 3) {
                if (cta === String(this.selectedCodCta) || this.movSalvos[cta][key1][key2][key3].cta_origin === this.selectedCodCta) {
                  if (!response.hasOwnProperty(key1)) {
                    response[key1] = {}
                  }
                  if (!response[key1].hasOwnProperty(key2)) {
                    response[key1][key2] = {}
                  }
                  response[key1][key2][key3] = { ...this.movSalvos[cta][key1][key2][key3] }
                }
              }
            }
          }
        }
      }
    }
    return response
  }

  verifyOrigin(key1, key2 = null, key3 = null) {
    if (this.keyLevel !== 3) key3 = null
    if (this.keyLevel === 1) key2 = null
    let response = false;
    if (this.movSalvos.hasOwnProperty(this.selectedCodCta))
      if (this.movSalvos[this.selectedCodCta].hasOwnProperty(key1))
        if (!key2) response = true
        else if (this.movSalvos[this.selectedCodCta][key1].hasOwnProperty(key2)) {
          if (!key3) response = true
          else if (this.movSalvos[this.selectedCodCta][key1][key2].hasOwnProperty(key3)) {
            response = true
          }
        }
    return response
  }

  openMovModal(selectedId: string, isMov: boolean = false, blockEdit = false): void {
    this.tableMov = [];
    this.movToDelete = this.deepCopy(this.movSalvosTemp);
    this.movVisionMode = blockEdit;
    if (isMov) {
      const currentMov: any = this.tableEdicao.filter((item) => item.line_id === selectedId)[0];
      if (blockEdit) {
        let movData;
        switch (this.keyLevel) {
          case 1:
            movData = this.movSalvosTemp[currentMov.key1_id];
            this.tableOriginMov = [this.toTableFormat(this.tableData[movData.company_origin].filter(
              (item: any) => item.cod_cta == movData.cta_origin)[0], movData.key1_origin)]

            this.tableMov.push({
              ...movData,
              key1_id: currentMov.key1_id,
              key2_id: currentMov.key2_id,
              line_id: this.idv4(),
              options: []
            })
            break
          case 2:
            movData = this.movSalvosTemp[currentMov.key1_id][currentMov.key2_id];
            this.tableOriginMov = [this.toTableFormat(this.tableData[movData.company_origin].filter(
              (item: any) => item.cod_cta == movData.cta_origin)[0], movData.key1_origin, movData.key2_origin)]

            this.tableMov.push({
              ...movData,
              key1_id: currentMov.key1_id,
              key2_id: currentMov.key2_id,
              line_id: this.idv4(),
              options: []
            })
            break
          case 3:
            movData = this.movSalvosTemp[currentMov.key1_id][currentMov.key2_id][currentMov.key3_id];
            this.tableOriginMov = [this.toTableFormat(this.tableData[movData.company_origin].filter(
              (item: any) => item.cod_cta == movData.cta_origin)[0], movData.key1_origin, movData.key2_origin, movData.key3_origin)]

            this.tableMov.push({
              ...movData,
              key1_id: currentMov.key1_id,
              key2_id: currentMov.key2_id,
              key3_id: currentMov.key3_id,
              line_id: this.idv4(),
              options: []
            })
            break
        }
      }
      else {
        for (let key1 in this.movSalvosTemp) {
          for (let key2 in this.movSalvosTemp[key1]) {
            if (key1 == currentMov.key1_id && key2 == currentMov.key2_id) {
              this.tableOriginMov = this.tableEdicao.filter(
                (item) => item.key1_id == this.movSalvosTemp[key1][key2].key1_origin
                  && item.key2_id == this.movSalvosTemp[key1][key2].key2_origin)
              this.tableOriginMov[0]['descr_cta'] = this.setDescription(this.selectedCodCta, 'cta')
              this.tableOriginMov[0]['descr_company'] = this.setDescription(this.selectedCompany, 'comp')
              break;
            }
          }
        }
      }
    }
    else if (!blockEdit) {
      let line = this.tableEdicao.filter((item) => item.line_id === selectedId)[0]
      this.tableOriginMov = [this.toTableFormat(this.tableData[this.selectedCompany][this.rowEditid], line.key1_id, line.key2_id, line.key3_id)];
      this.tableOriginMov[0]['descr_cta'] = this.setDescription(this.selectedCodCta, 'cta')
      this.tableOriginMov[0]['descr_company'] = this.setDescription(this.selectedCompany, 'comp')
    }
    if (!blockEdit) {
      this.getCurrentMov();
      if (this.tableMov.length === 0)
        this.addMovRow();
    }
    this.modalMov = true;
  }

  toTableFormat(data: any, key1: any = '0', key2: any = '0', key3: any = '0') {
    let response = {
      total: 0,
      key1_id: key1,
      key2_id: key2,
      key3_id: key3,
      descr_cta: this.setDescription(data.cod_cta, 'cta'),
      descr_key1: this.setDescription(key1, this.levels['1'][1]),
      descr_key2: this.setDescription(key2, this.keyLevel !== 1 ? this.levels['2'][1] : ''),
      descr_key3: this.setDescription(key3, this.keyLevel === 3 ? this.levels['3'][1] : ''),
    };
    this.movMonths.forEach(month => {
      let monthOrigin = month
      if (this.isRealizadoVisao || (this.isForecast && this.limesreal.includes(month)))
        monthOrigin += 'Real'
      else if (this.isForecast) monthOrigin += 'For'
      response[month] = 0;
      switch (this.keyLevel) {
        case 1:
          if (data[monthOrigin][key1])
            response[month] += data[monthOrigin][key1].original
          break
        case 2:
          if (data[monthOrigin][key1])
            if (data[monthOrigin][key1][key2])
              response[month] += data[monthOrigin][key1][key2].original
          break
        case 3:
          if (data[monthOrigin][key1])
            if (data[monthOrigin][key1][key2])
              if (data[monthOrigin][key1][key2][key3])
                response[month] += data[monthOrigin][key1][key2][key3].original
          break
      }
      response.total += response[month]
    })
    return response
  }

  // verifica se no centro de custo tem alguma movimentacao
  getCurrentMov() {

    for (let key1 in this.movSalvosTemp) {
      if (this.keyLevel === 1) {
        if (this.movSalvosTemp[key1].key1_origin == this.tableOriginMov[0].key1_id
          && this.movSalvosTemp[key1].cta_origin == this.selectedCodCta) {
          this.tableMov.push({
            ...this.movSalvosTemp[key1],
            key1_id: key1,
            key2_id: 0,
            line_id: this.idv4(),
            options: [],
          })
        }

      } else for (let key2 in this.movSalvosTemp[key1]) {
        if (this.keyLevel === 2) {
          if (this.movSalvosTemp[key1][key2].key1_origin == this.tableOriginMov[0].key1_id
            && this.movSalvosTemp[key1][key2].key2_origin == this.tableOriginMov[0].key2_id
            && this.movSalvosTemp[key1][key2].cta_origin == this.selectedCodCta) {
            let flag = this.descEdicao[this.selectedCompany][key2] && this.descEdicao[this.selectedCompany][key2].match(/^\d{3}/)[0];
            this.tableMov.push({
              ...this.movSalvosTemp[key1][key2],
              key1_id: key1,
              key2_id: key2,
              line_id: this.idv4(),
              options: flag ? Object.entries(this.descEdicao[this.selectedCompany])
                .filter((item: any) => item[1].match(/^\d{3}/)[0] == flag)
                .map((desc) => new Object({ label: desc[1], value: desc[0] }))
                : [],
            })
          }
        } else for (let key3 in this.movSalvosTemp[key1][key2]) {
          if (this.keyLevel === 3) {
            if (this.movSalvosTemp[key1][key2][key3].key1_origin == this.tableOriginMov[0].key1_id
              && this.movSalvosTemp[key1][key2][key3].key2_origin == this.tableOriginMov[0].key2_id
              && this.movSalvosTemp[key1][key2][key3].key3_origin == this.tableOriginMov[0].key3_id
              && this.movSalvosTemp[key1][key2][key3].cta_origin == this.selectedCodCta) {
              let flag = this.descEdicao[this.selectedCompany][key3] && this.descEdicao[this.selectedCompany][key3].match(/^\d{3}/)[0];
              this.tableMov.push({
                ...this.movSalvosTemp[key1][key2][key3],
                key1_id: key1,
                key2_id: key2,
                key3_id: key3,
                line_id: this.idv4(),
                options: flag ? Object.entries(this.descEdicao[this.selectedCompany])
                  .filter((item: any) => item[1].match(/^\d{3}/)[0] == flag)
                  .map((desc) => new Object({ label: desc[1], value: desc[0] }))
                  : [],
              })
            }
          }
        }
      }
    }
  }

  //calcula a sobra no footer do modal de movimentacao
  calcMovRest(month) {
    let originTotal = this.tableOriginMov[0] && this.tableOriginMov[0][month]
    this.tableMov.forEach((row) => { originTotal -= row[month] })
    return originTotal;
  }

  setDescription(id, type: string) {
    type = type.toLowerCase()
    let response: any = 'Não informado';
    if (id != 0) {
      switch (type) {
        case 'cta':
          response = this.opcoesCta.filter(item => item.value == id)[0]
          response = response && response.label
          break;
        case 'ccus':
          Object.entries(this.descrCcus[this.selectedCompany])
            .forEach((desc) => { if (desc[0] == id) { response = desc[1] } })
          break;
        case 'proj':
          Object.entries(this.descEdicao[this.selectedCompany])
            .forEach((desc) => { if (desc[0] == id) { response = desc[1] } })
          break;
        case 'area':
          Object.entries(this.descrArea[this.selectedCompany])
            .forEach((desc) => { if (desc[0] == id) { response = desc[1] } })
          break;
        case 'comp':
          response = this.listEmpresas.filter(item => item.value == id)[0]
          response = response && response.label.split(' ')[0]
          break;
      }
    }
    return response
  }

  //adiciona uma nova linha na edicao da movimentacao
  addMovRow(): void {
    this.tableMov.push({
      cta_origin: this.selectedCodCta,
      cta_id: 0,
      key1_origin: this.tableOriginMov[0].key1_id,
      key2_origin: this.tableOriginMov[0].key2_id,
      key3_origin: this.tableOriginMov[0].key3_id,
      company_origin: this.selectedCompany,
      company_destiny: this.editOptions.length > 1 ? 0 : this.selectedCompany,
      key1_id: 0,
      key2_id: 0,
      key3_id: 0,
      Jan: 0,
      Fev: 0,
      Mar: 0,
      Abr: 0,
      Mai: 0,
      Jun: 0,
      Jul: 0,
      Ago: 0,
      Set: 0,
      Out: 0,
      Nov: 0,
      Dez: 0,
      total: 0,
      comentario: null,
      line_id: this.idv4(),
      options: [],
    });
  }

  salvarMov() {
    let destinyRow: number;
    if (this.checkMov()) {
      switch (this.keyLevel) {
        case 1:
          for (let key1 in this.movToDelete) {
            let destinyRow = 0, originRow = 0;
            let lineToDelete = this.movToDelete[key1]
            this.tableData[lineToDelete.company_destiny].forEach((row: any, ind: number) => {
              if (row.cod_cta == lineToDelete.cta_id) {
                this.movSalvos[row.cod_cta][key1] = {}
                destinyRow = ind;
                return;
              }
            })
            this.tableData[lineToDelete.company_origin].forEach((row: any, ind: number) => {
              if (row.cod_cta == lineToDelete.cta_origin) {
                originRow = ind;
                return;
              }
            })
            this.movMonths.forEach((month, index) => {
              month = this.isForecast ? `${month}For` : month
              //Calcula o trimestre atual
              let currentTrim = ((index + 1.5) / 3).toFixed(0)
              currentTrim = this.isForecast ? currentTrim + 'For' : currentTrim

              this.tableData[lineToDelete.company_origin][originRow][month][lineToDelete.key1_origin].mov = 0;
              this.tableData[lineToDelete.company_origin][originRow][`q${currentTrim}`][lineToDelete.key1_origin].mov = 0;
              this.tableData[lineToDelete.company_destiny][destinyRow][month][key1].mov = 0;
              this.tableData[lineToDelete.company_destiny][destinyRow][`q${currentTrim}`][key1].mov = 0;
            })
            this.recalcRow(destinyRow)
          }
          break
        case 2:
          for (let key1 in this.movToDelete) {
            for (let key2 in this.movToDelete[key1]) {
              let destinyRow = 0, originRow = 0;
              let lineToDelete = this.movToDelete[key1][key2]
              this.tableData[lineToDelete.company_destiny].forEach((row: any, ind: number) => {
                if (row.cod_cta == lineToDelete.cta_id) {
                  this.movSalvos[row.cod_cta][key1][key2] = {}
                  destinyRow = ind;
                  return;
                }
              })
              this.tableData[lineToDelete.company_origin].forEach((row: any, ind: number) => {
                if (row.cod_cta == lineToDelete.cta_origin) {
                  originRow = ind;
                  return;
                }
              })
              this.movMonths.forEach((month, index) => {
                month = this.isForecast ? `${month}For` : month
                //Calcula o trimestre atual
                let currentTrim = ((index + 1.5) / 3).toFixed(0)
                currentTrim = this.isForecast ? currentTrim + 'For' : currentTrim

                this.tableData[lineToDelete.company_origin][originRow][month][lineToDelete.key1_origin][lineToDelete.key2_origin].mov = 0;
                this.tableData[lineToDelete.company_origin][originRow][`q${currentTrim}`][lineToDelete.key1_origin][lineToDelete.key2_origin].mov = 0;
                this.tableData[lineToDelete.company_destiny][destinyRow][month][key1][key2].mov = 0;
                this.tableData[lineToDelete.company_destiny][destinyRow][`q${currentTrim}`][key1][key2].mov = 0;
              })
              this.recalcRow(destinyRow)
            }
          }
          break
        case 3:
          for (let key1 in this.movToDelete) {
            for (let key2 in this.movToDelete[key1]) {
              for (let key3 in this.movToDelete[key1][key2]) {
                let destinyRow = 0, originRow = 0;
                let lineToDelete = this.movToDelete[key1][key2][key3]
                this.tableData[lineToDelete.company_destiny].forEach((row: any, ind: number) => {
                  if (row.cod_cta == lineToDelete.cta_id) {
                    this.movSalvos[row.cod_cta][key1][key2][key3] = {}
                    destinyRow = ind;
                    return;
                  }
                })
                this.tableData[lineToDelete.company_origin].forEach((row: any, ind: number) => {
                  if (row.cod_cta == lineToDelete.cta_origin) {
                    originRow = ind;
                    return;
                  }
                })
                this.movMonths.forEach((month, index) => {
                  month = this.isForecast ? `${month}For` : month
                  //Calcula o trimestre atual
                  let currentTrim = ((index + 1.5) / 3).toFixed(0)
                  currentTrim = this.isForecast ? currentTrim + 'For' : currentTrim

                  this.tableData[lineToDelete.company_origin][originRow][month][lineToDelete.key1_origin][lineToDelete.key2_origin][lineToDelete.key3_origin].mov = 0;
                  this.tableData[lineToDelete.company_origin][originRow][`q${currentTrim}`][lineToDelete.key1_origin][lineToDelete.key2_origin][lineToDelete.key3_origin].mov = 0;
                  this.tableData[lineToDelete.company_destiny][destinyRow][month][key1][key2][key3].mov = 0;
                  this.tableData[lineToDelete.company_destiny][destinyRow][`q${currentTrim}`][key1][key2][key3].mov = 0;
                })
                this.recalcRow(destinyRow)
              }
            }
          }
          break
      }
      this.tableMov.forEach((line: any) => {
        if (!this.movSalvos.hasOwnProperty(line.cta_id)) {
          this.movSalvos[line.cta_id] = {}
        }
        switch (this.keyLevel) {
          case 1:
            this.movSalvos[line.cta_id][line.key1_id] = {
              total: 0,
              cta_id: line.cta_id,
              cta_origin: line.cta_origin,
              key1_origin: line.key1_origin,
              key2_origin: line.key2_origin,
              company_origin: line.company_origin,
              company_destiny: line.company_destiny,
              comentario: line.comentario
            }
            break
          case 2:
            if (!this.movSalvos[line.cta_id].hasOwnProperty(line.key1_id)) {
              this.movSalvos[line.cta_id][line.key1_id] = {}
            }

            this.movSalvos[line.cta_id][line.key1_id][line.key2_id] = {
              total: 0,
              cta_id: line.cta_id,
              cta_origin: line.cta_origin,
              key1_origin: line.key1_origin,
              key2_origin: line.key2_origin,
              company_origin: line.company_origin,
              company_destiny: line.company_destiny,
              comentario: line.comentario
            }
            break
          case 3:
            if (!this.movSalvos[line.cta_id].hasOwnProperty(line.key1_id)) {
              this.movSalvos[line.cta_id][line.key1_id] = {}
            }
            if (!this.movSalvos[line.cta_id][line.key1_id].hasOwnProperty(line.key2_id)) {
              this.movSalvos[line.cta_id][line.key1_id][line.key2_id] = {}
            }

            this.movSalvos[line.cta_id][line.key1_id][line.key2_id][line.key3_id] = {
              total: 0,
              cta_id: line.cta_id,
              cta_origin: line.cta_origin,
              key1_origin: line.key1_origin,
              key2_origin: line.key2_origin,
              key3_origin: line.key3_origin,
              company_origin: line.company_origin,
              company_destiny: line.company_destiny,
              comentario: line.comentario
            }
            break
        }
        //Atualiza valores da tabela principal
        this.tableData[this.selectedCompany].forEach((row: any, ind: number) => {
          if (row.cod_cta == line.cta_id) { destinyRow = ind; return; }
        })

        this.movMonths.forEach((month: any, index: number) => {

          let monthEdit = this.isForecast ? `${month}For` : month

          //Calcula o trimestre atual
          let currentTrim = ((index + 1.5) / 3).toFixed(0)
          currentTrim = this.isForecast ? currentTrim + 'For' : currentTrim

          switch (this.keyLevel) {
            case 1:
              //Adiciona a mov ao mes e trimestre origin
              this.tableData[line.company_origin][this.rowEditid][monthEdit][line.key1_origin].mov = 0
              if ([1, 4, 7, 10].includes(index + 1))
                this.tableData[line.company_origin][this.rowEditid][`q${currentTrim}`][line.key1_origin].mov = 0
              this.tableData[line.company_origin][this.rowEditid][monthEdit][line.key1_origin].mov -= line[month];
              this.tableData[line.company_origin][this.rowEditid][`q${currentTrim}`][line.key1_origin].mov -= line[month];

              //Adiciona a mov ao mes e trimestre destino

              if (!this.tableData[line.company_destiny][destinyRow][monthEdit].hasOwnProperty(line.key1_id))
                this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id] = { ajuste: 0, mov: 0, original: 0 };

              if (!this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`].hasOwnProperty(line.key1_id))
                this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id] = { ajuste: 0, mov: 0, original: 0 };

              if (!this.movSalvos[line.cta_id][line.key1_id].hasOwnProperty(month))
                this.movSalvos[line.cta_id][line.key1_id][month] = 0

              this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id].mov = line[month];
              this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id].mov += line[month];

              this.movSalvos[line.cta_id][line.key1_id][month] = line[month];
              this.movSalvos[line.cta_id][line.key1_id].total += line[month];

              break

            case 2:
              //Adiciona a mov ao mes e trimestre origin
              this.tableData[line.company_origin][this.rowEditid][monthEdit][line.key1_origin][line.key2_origin].mov = 0
              if ([1, 4, 7, 10].includes(index + 1))
                this.tableData[line.company_origin][this.rowEditid][`q${currentTrim}`][line.key1_origin][line.key2_origin].mov = 0
              this.tableData[line.company_origin][this.rowEditid][monthEdit][line.key1_origin][line.key2_origin].mov -= line[month];
              this.tableData[line.company_origin][this.rowEditid][`q${currentTrim}`][line.key1_origin][line.key2_origin].mov -= line[month];


              //Adiciona a mov ao mes e trimestre destino

              if (!this.tableData[line.company_destiny][destinyRow][monthEdit].hasOwnProperty(line.key1_id))
                this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id] = {};

              if (!this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id].hasOwnProperty(line.key2_id))
                this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id][line.key2_id] = { ajuste: 0, mov: 0, original: 0 };

              if (!this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`].hasOwnProperty(line.key1_id))
                this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id] = {};

              if (!this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id].hasOwnProperty(line.key2_id))
                this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id][line.key2_id] = { ajuste: 0, mov: 0, original: 0 };

              if (!this.movSalvos[line.cta_id][line.key1_id][line.key2_id].hasOwnProperty(month))
                this.movSalvos[line.cta_id][line.key1_id][line.key2_id][month] = 0

              this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id][line.key2_id].mov = line[month];
              this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id][line.key2_id].mov += line[month];

              this.movSalvos[line.cta_id][line.key1_id][line.key2_id][month] = line[month];
              this.movSalvos[line.cta_id][line.key1_id][line.key2_id].total += line[month];
              break
            case 3:
              //Adiciona a mov ao mes e trimestre origin
              this.tableData[line.company_origin][this.rowEditid][monthEdit][line.key1_origin][line.key2_origin][line.key3_origin].mov = 0
              if ([1, 4, 7, 10].includes(index + 1))
                this.tableData[line.company_origin][this.rowEditid][`q${currentTrim}`][line.key1_origin][line.key2_origin][line.key3_origin].mov = 0
              this.tableData[line.company_origin][this.rowEditid][monthEdit][line.key1_origin][line.key2_origin][line.key3_origin].mov -= line[month];
              this.tableData[line.company_origin][this.rowEditid][`q${currentTrim}`][line.key1_origin][line.key2_origin][line.key3_origin].mov -= line[month];



              //Adiciona a mov ao mes e trimestre destino

              if (!this.tableData[line.company_destiny][destinyRow][monthEdit].hasOwnProperty(line.key1_id))
                this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id] = {};

              if (!this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id].hasOwnProperty(line.key2_id))
                this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id][line.key2_id] = {};

              if (!this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id][line.key2_id].hasOwnProperty(line.key3_id))
                this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id][line.key2_id][line.key3_id] = { ajuste: 0, mov: 0, original: 0 };

              if (!this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`].hasOwnProperty(line.key1_id))
                this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id] = {};

              if (!this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id].hasOwnProperty(line.key2_id))
                this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id][line.key2_id] = {};

              if (!this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id][line.key2_id].hasOwnProperty(line.key3_id))
                this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id][line.key2_id][line.key3_id] = { ajuste: 0, mov: 0, original: 0 };

              if (!this.movSalvos[line.cta_id][line.key1_id][line.key2_id][line.key3_id].hasOwnProperty(month))
                this.movSalvos[line.cta_id][line.key1_id][line.key2_id][line.key3_id][month] = 0

              this.tableData[line.company_destiny][destinyRow][monthEdit][line.key1_id][line.key2_id][line.key3_id].mov = line[month];
              this.tableData[line.company_destiny][destinyRow][`q${currentTrim}`][line.key1_id][line.key2_id][line.key3_id].mov += line[month];

              this.movSalvos[line.cta_id][line.key1_id][line.key2_id][line.key3_id][month] = line[month];
              this.movSalvos[line.cta_id][line.key1_id][line.key2_id][line.key3_id].total += line[month];


              break
          }
        })
        this.recalcRow(destinyRow)
      });
      this.recalcRow()
      this.formatValues()
      this.openDetalhe(this.selectedCodCta, this.ctaSelecionada)
      this.modalMov = false;
    }
  }

  checkMov() {
    let response = true, msg = '', movTotal = 0;
    this.tableMov.forEach((line) => {
      this.movMonths.forEach(month => {
        movTotal = this.tableOriginMov[0][month] - line[month]
        if (movTotal < 0) {
          msg = "Saldo insuficiente"
          response = false
        }
      })
      movTotal -= line.total
      if (line.cta_id === 0) {
        msg = "Selecione uma conta para fazer a movimentacao."
        response = false
      } else if (line.key2_id === 0 && line.options.length !== 0) {
        msg = "Selecione um projeto para fazer a movimentacao."
        response = false
      }
    })
    if (!response) this.exibirMensagem({
      error: true,
      mensagem: msg,
    });
    return true
  }

  varLabels(type: any, trim = false) {
    let response = '', teste = true
    let month = type[0].substr(0, trim ? 2 : 3)
    if (trim)
      this.movMonths.forEach((mont, index) => {
        if (month.slice(-1) == ((index + 1.5) / 3).toFixed(0)) teste = !this.limesreal.includes(mont)
      })
    else teste = !this.limesreal.includes(month)
    if (this.isForecast && type[1].includes('Real') && teste)
      response = 'FORECAST'
    else {
      switch (type[1]) {
        case 'Orc.':
          response = ((this.moedaVisu !== 1) ? `BUDGET <br> TX: ${type[2]}` : 'BUDGET')
          break
        case 'Real.':
          response =  ((this.moedaVisu !== 1) ? `REALIZADO <br> TX: ${type[2]}` : 'REALIZADO')
          break
        case 'Var.':
          response = 'VARIAÇÃO'
          break
        case 'Var. %':
          response = 'VARIAÇÃO %'
          break
      }
    }
    return response
  }

  salvarEdicao() {
    if (confirm("Confirmar edição dos valores?")) {

      if (Object.keys(this.movToDelete).length > 0) {
        switch (this.keyLevel) {
          case 1:
            for (let key1 in this.movToDelete) {
              let destinyRow = 0;
              this.tableData[this.movToDelete[key1].company_destiny].forEach((row: any, ind: number) => {
                if (row.cod_cta == this.movToDelete[key1].cta_id) {
                  this.movSalvos[row.cod_cta][key1] = {}
                  destinyRow = ind;
                  return;
                }
              })
              this.movMonths.forEach((month, index) => {
                let currentTrim = ((index + 1.5) / 3).toFixed(0)
                this.tableData[this.movToDelete[key1].company_destiny][destinyRow][month][key1].mov = 0;
                this.tableData[this.movToDelete[key1].company_destiny][destinyRow][`q${currentTrim}`][key1].mov = 0;
              })
              this.recalcRow(destinyRow)
            }
            break
          case 2:
            for (let key1 in this.movToDelete) {
              for (let key2 in this.movToDelete[key1]) {
                let destinyRow = 0;
                this.tableData[this.movToDelete[key1][key2].company_destiny].forEach((row: any, ind: number) => {
                  if (row.cod_cta == this.movToDelete[key1][key2].cta_id) {
                    this.movSalvos[row.cod_cta][key1][key2] = {}
                    destinyRow = ind;
                    return;
                  }
                })
                this.movMonths.forEach((month, index) => {
                  let currentTrim = ((index + 1.5) / 3).toFixed(0)
                  this.tableData[this.movToDelete[key1][key2].company_destiny][destinyRow][month][key1][key2].mov = 0;
                  this.tableData[this.movToDelete[key1][key2].company_destiny][destinyRow][`q${currentTrim}`][key1][key2].mov = 0;
                })
                this.recalcRow(destinyRow)
              }
            }
            break
          case 3:
            for (let key1 in this.movToDelete) {
              for (let key2 in this.movToDelete[key1]) {
                for (let key3 in this.movToDelete[key1][key2]) {
                  let destinyRow = 0;
                  this.tableData[this.movToDelete[key1][key2][key3].company_destiny].forEach((row: any, ind: number) => {
                    if (row.cod_cta == this.movToDelete[key1][key2][key3].cta_id) {
                      this.movSalvos[row.cod_cta][key1][key2][key3] = {}
                      destinyRow = ind;
                      return;
                    }
                  })
                  this.movMonths.forEach((month, index) => {
                    let currentTrim = ((index + 1.5) / 3).toFixed(0)
                    this.tableData[this.movToDelete[key1][key2][key3].company_destiny][destinyRow][month][key1][key2][key3].mov = 0;
                    this.tableData[this.movToDelete[key1][key2][key3].company_destiny][destinyRow][`q${currentTrim}`][key1][key2][key3].mov = 0;
                  })
                  this.recalcRow(destinyRow)
                }
              }
            }
            break
        }
      }

      //Resetar a linha
      if (this.isForecast)
        this.tableData[this.selectedCompany][this.rowEditid]["totalFor"] = 0
      else {
        this.tableData[this.selectedCompany][this.rowEditid]["total"] = 0;
        this.tableData[this.selectedCompany][this.rowEditid]["totalVar"] = 0;
      }

      this.movMonths.forEach(month => {
        month = this.isForecast ? `${month}For` : month
        this.tableData[this.selectedCompany][this.rowEditid][`${month}Total`] = 0;
        for (let key1 in this.tableData[this.selectedCompany][this.rowEditid][month]) {
          if (this.keyLevel === 1) this.tableData[this.selectedCompany][this.rowEditid][month][key1] = { ajuste: 0, mov: 0, original: 0 }
          else {
            for (let key2 in this.tableData[this.selectedCompany][this.rowEditid][month][key1]) {
              if (this.keyLevel === 2) this.tableData[this.selectedCompany][this.rowEditid][month][key1][key2] = { ajuste: 0, mov: 0, original: 0 };
              else {
                for (let key3 in this.tableData[this.selectedCompany][this.rowEditid][month][key1][key2]) {
                  if (this.keyLevel === 3) this.tableData[this.selectedCompany][this.rowEditid][month][key1][key2][key3] = { ajuste: 0, mov: 0, original: 0 };
                }
              }
            }
          }
        }
      })

      //Assinar os novos valores
      for (let line of this.tableEdicao) {
        let type = line.fromAjuste ? 'ajuste' : line.fromMov ? 'mov' : 'original';
        this.movMonths.forEach((month, index) => {

          let monthEdit = this.isForecast ? `${month}For` : month
          //Calcula o trimestre atual
          let currentTrim = this.isForecast ? ((index + 1.5) / 3).toFixed(0) + 'For' : ((index + 1.5) / 3).toFixed(0)

          switch (this.keyLevel) {
            case 1:
              // Mes + Total da linha
              if (!this.tableData[this.selectedCompany][this.rowEditid][monthEdit].hasOwnProperty(line["key1_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]] = { ajuste: 0, mov: 0, original: 0 };

              this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]][type] += line[month];
              // this.tableData[this.selectedCompany][this.rowEditid][`${monthEdit}Total`] += line[month];
              if (!this.isForecast) this.tableData[this.selectedCompany][this.rowEditid]["total"] += line[month];

              // Trimestre orçamento
              if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`].hasOwnProperty(line["key1_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]] = { ajuste: 0, mov: 0, original: 0 };

              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]][type] += line[month];
              // this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Total`] += line[month];

              //Trimestre variação
              if (!this.isForecast) {
                if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`].hasOwnProperty(line["key1_id"]))
                  this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`][line["key1_id"]] = { ajuste: 0, mov: 0, original: 0 };
              }

              break
            case 2:
              // Mes + Total da linha
              if (!this.tableData[this.selectedCompany][this.rowEditid][monthEdit].hasOwnProperty(line["key1_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]] = {};

              if (!this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]].hasOwnProperty(line["key2_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]][line["key2_id"]] = { ajuste: 0, mov: 0, original: 0 };


              this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]][line["key2_id"]][type] += line[month];
              // this.tableData[this.selectedCompany][this.rowEditid][`${monthEdit}Total`] += line[month];
              if (!this.isForecast) this.tableData[this.selectedCompany][this.rowEditid]["total"] += line[month];

              // Trimestre orçamento
              if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`].hasOwnProperty(line["key1_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]] = {};

              if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]].hasOwnProperty(line["key2_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]][line["key2_id"]] = { ajuste: 0, mov: 0, original: 0 };

              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]][line["key2_id"]][type] += line[month];
              // this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Total`] += line[month];

              //Trimestre variação
              if (!this.isForecast) {
                if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`].hasOwnProperty(line["key1_id"]))
                  this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`][line["key1_id"]] = {};

                if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`][line["key1_id"]].hasOwnProperty(line["key2_id"]))
                  this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`][line["key1_id"]][line["key2_id"]] = { ajuste: 0, mov: 0, original: 0 };
              }
              break
            case 3:
              // Mes + Total da linha
              if (!this.tableData[this.selectedCompany][this.rowEditid][monthEdit].hasOwnProperty(line["key1_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]] = {};

              if (!this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]].hasOwnProperty(line["key2_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]][line["key2_id"]] = {};

              if (!this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]][line["key2_id"]].hasOwnProperty(line["key3_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]][line["key2_id"]][line["key3_id"]] = { ajuste: 0, mov: 0, original: 0 };


              this.tableData[this.selectedCompany][this.rowEditid][monthEdit][line["key1_id"]][line["key2_id"]][line["key3_id"]][type] += line[month];
              // this.tableData[this.selectedCompany][this.rowEditid][`${monthEdit}Total`] += line[month];
              if (!this.isForecast) this.tableData[this.selectedCompany][this.rowEditid]["total"] += line[month];

              // Trimestre orçamento
              if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`].hasOwnProperty(line["key1_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]] = {};

              if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]].hasOwnProperty(line["key2_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]][line["key2_id"]] = {};

              if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]][line["key2_id"]].hasOwnProperty(line["key3_id"]))
                this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]][line["key2_id"]][line["key3_id"]] = { ajuste: 0, mov: 0, original: 0 };

              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][line["key1_id"]][line["key2_id"]][line["key3_id"]][type] += line[month];
              // this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Total`] += line[month];

              //Trimestre variação
              if (!this.isForecast) {
                if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`].hasOwnProperty(line["key1_id"]))
                  this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`][line["key1_id"]] = {};

                if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`][line["key1_id"]].hasOwnProperty(line["key2_id"]))
                  this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`][line["key1_id"]][line["key2_id"]] = {};

                if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`][line["key1_id"]][line["key2_id"]].hasOwnProperty(line["key3_id"]))
                  this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}Var`][line["key1_id"]][line["key2_id"]][line["key3_id"]] = { ajuste: 0, mov: 0, original: 0 };
              }
              break
          }

        })
      }

      this.editionState = false;

      //Recalcula a tabela
      this.recalcRow();
      //Recalcula a tabela principal
      this.formatValues();

      this.closeAjustes();

      this.modalEdicao = false;
    }
  }

  updateVarPercent() {
    this.percentDic.forEach((item) => {
      this.tableValuesVar[this.rowEditid][item[0]] = this.calcPercentage(this.tableValuesVar[this.rowEditid][item[1] === "total" ? item[1] + "Real" : item[1] + "RealTotal"], this.tableValuesVar[this.rowEditid][item[1] === "total" ? item[1] : item[1] + "Total"]);
    });
  }

  updateVarPercentGlobal() {
    this.tableValuesVar.forEach(row => {
      this.percentDic.forEach((item) => {
        row[item[0]] = this.calcPercentage(row[item[1] === "total" ? item[1] + "Real" : item[1] + "RealTotal"], row[item[1] === "total" ? item[1] : item[1] + "Total"]);
      })
    });
  }

  updateEditValue(lineId: string, isMov = false) {
    if (isMov) {
      this.tableMov.map((row) => {
        if (row.line_id === lineId) {
          row.descr_key1 = this.setDescription(row.key1_id, this.levels['1'][1]);
          row.descr_key2 = this.keyLevel !== 1 ? this.setDescription(row.key2_id, this.levels['2'][1]) : '';
          row.descr_key3 = this.keyLevel === 3 ? this.setDescription(row.key3_id, this.levels['3'][1]) : '';
        }
      });
    } else {
      this.tableEdicao.map((row) => {
        if (row.line_id === lineId) {
          row.descr_key1 = this.setDescription(row.key1_id, this.levels['1'][1]);
          row.descr_key2 = this.keyLevel !== 1 ? this.setDescription(row.key2_id, this.levels['2'][1]) : '';
          row.descr_key3 = this.keyLevel === 3 ? this.setDescription(row.key3_id, this.levels['3'][1]) : '';
        }
      });
    }
  }

  closeAjustes() {
    this.edicaoCta = false;
    this.visaoReal = false;
    this.tableEdicao = new Array();
    this.tableVisaoReal = new Array();

    this.editionState = false;
  }

  export() {
    //Header da requisição
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'escondernulos': this.hideLines.toString(),
        visao: 'all'
      })
    };

    const infos = {
      empresa_id: this.empresasSelecionadas,
      empresaConectadaId: this.dblinkedSessionService.empresa.id,
      tokenProgressoSocket: idv4(),
      liccus: this.getGerencialInfos('CCUS'),
      liproj: this.getGerencialInfos('PROJ'),
      liarea: this.getGerencialInfos('AREA'),
      lisubarea: this.getGerencialInfos('SUBAREA'),
      moeda_id: this.moeda,
      utilizarTxDif: this.utilizarTxDif,
      txMoeda: this.txMoeda,
      first: false,
      versao: this.versao,
      user_id: this.sessionService.loggedUser.id,
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'orcamento/relat-orca-dash', infos, httpOptions)
      .toPromise()
      .then(res => {
        if (!res["error"]) {
          this.exibirMensagem(res);
          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0'
            }),
            responseType: 'blob' as 'json'
          };

          this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/relat-orca-dash', httpOptions2)
            .toPromise()
            .then((res: Blob) => {
              let link = document.createElement('a');
              let url = window.URL.createObjectURL(res);
              let fileName = 'Relatorio_orcamento_gerencial.xlsx';

              link.href = url;
              link.download = fileName;
              link.click();

              window.URL.revokeObjectURL(url);
            })
            .catch(err => {
              this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível baixar o relatório. Contate o suporte" })
              console.log(err)
              this.httpService.done();
            });

          this.httpService.done();

        } else {
          this.exibirMensagem(res);

        }
        this.httpService.done();

      })
      .catch(err => {
        this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível gerar o relatório. Contate o suporte" })
        console.log(err)
        this.httpService.done();
      });
  }

  checkEdition(id, month: string) {
    let response = false;
    if (month.includes('Total'))
      month = month.substr(0, 3)

    let monthOrig = month

    if (this.isRealizadoVisao || (this.isForecast && this.limesreal.includes(month)))
      month += 'Real'
    else if (this.isForecast) month += 'For'

    for (let company in this.tableData) {
      for (let cta in this.tableData[company]) {
        if (this.tableData[company][cta].cod_cta == id) {
          for (let key1 in this.tableData[company][cta][month]) {
            if (this.keyLevel === 1) {
              if (this.tableData[company][cta][month][key1].mov !== 0
                || this.tableData[company][cta][month][key1].ajuste !== 0)
                response = true
            } else {
              for (let key2 in this.tableData[company][cta][month][key1]) {
                if (this.keyLevel === 2) {
                  if (this.tableData[company][cta][month][key1][key2].mov !== 0
                    || this.tableData[company][cta][month][key1][key2].ajuste !== 0)
                    response = true
                } else {
                  for (let key3 in this.tableData[company][cta][month][key1][key2]) {
                    if (this.keyLevel === 3) {
                      if (this.tableData[company][cta][month][key1][key2][key3].mov !== 0
                        || this.tableData[company][cta][month][key1][key2][key3].ajuste !== 0)
                        response = true
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (Object.keys(this.movSalvos).length > 0 && !this.isRealizadoVisao) {
      for (let company in this.movSalvos) {
        switch (this.keyLevel) {
          case 1:
            for (let key1 in this.movSalvos[company]) {
              if (this.movSalvos[company][key1].cta_origin == id) {
                if (this.movSalvos[company][key1][monthOrig] !== 0)
                  response = true
              }
            }
            break
          case 2:
            for (let key1 in this.movSalvos[company]) {
              for (let key2 in this.movSalvos[company][key1]) {
                if (this.movSalvos[company][key1][key2].cta_origin == id) {
                  if (this.movSalvos[company][key1][key2][monthOrig] !== 0)
                    response = true
                }
              }
            }
            break
          case 3:
            for (let key1 in this.movSalvos[company]) {
              for (let key2 in this.movSalvos[company][key1]) {
                for (let key3 in this.movSalvos[company][key1][key2]) {
                  if (this.movSalvos[company][key1][key2][key3].cta_origin == id) {
                    if (this.movSalvos[company][key1][key2][key3][monthOrig] !== 0)
                      response = true
                  }
                }
              }
            }
            break
        }
      }
    }
    return response && monthOrig !== 'total';
  }

  checkDetEdition(id, month: string) {
    let response = false;
    const row = this.tableEdicao.filter(item => item.line_id === id)[0]
    if (Object.keys(this.movSalvosTemp).length > 0 && !this.isRealizadoVisao) {
      switch (this.keyLevel) {
        case 1:
          for (let key1 in this.movSalvosTemp) {
            if (this.movSalvosTemp[key1].key1_origin == row.key1_id && this.movSalvosTemp[key1].cta_origin == row.cod_cta) {
              if (this.movSalvosTemp[key1][month] !== 0)
                response = true
            }
          }
          break
        case 2:
          for (let key1 in this.movSalvosTemp) {
            for (let key2 in this.movSalvosTemp[key1]) {
              if (this.movSalvosTemp[key1][key2].key1_origin == row.key1_id && this.movSalvosTemp[key1][key2].cta_origin == row.cod_cta) {
                if (this.movSalvosTemp[key1][key2][month] !== 0)
                  response = true
              }
            }
          }
          break
        case 3:
          for (let key1 in this.movSalvosTemp) {
            for (let key2 in this.movSalvosTemp[key1]) {
              if (this.movSalvosTemp[key1][key2].key1_origin == row.key1_id && this.movSalvosTemp[key1][key2].cta_origin == row.cod_cta) {
                if (this.movSalvosTemp[key1][key2][month] !== 0)
                  response = true
              }
            }
          }
          break
      }
    }
    return response;
  }

  addNewRow() {
    this.editionState = true;

    this.tableEdicao.push({
      Abr: 0,
      Ago: 0,
      Dez: 0,
      Fev: 0,
      Jan: 0,
      Jul: 0,
      Jun: 0,
      Mai: 0,
      Mar: 0,
      Nov: 0,
      Out: 0,
      Set: 0,
      key1_id: "0",
      descr_key1: "",
      descr_key2: "",
      line_id: this.idv4(),
      options: [],
      key2_id: "0",
      total: 0,
      fromAjuste: false,
      fromMov: false,
    });
  }

  idv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  openAjustes(row: any): void {
    if (row !== null) {
      this.key1Ajuste = Number(row.key1_id);
      this.key2Ajuste = this.keyLevel !== 1 && Number(row.key2_id);
      this.key3Ajuste = this.keyLevel === 3 && Number(row.key3_id);

      //Reseta os ajustes
      this.movMonths.forEach((month, index) => {
        month = this.isRealizadoVisao ? `${month}Real` : this.isForecast ? `${month}For` : month;
        this.ajustes[index].valor = row[month]
        this.ajustes[index].comentario = row['comentarios'][month];
      })
    }
  }

  openModalComentarios(rowdata, col) {
    if (col[1] !== "Var." || rowdata.ind_cta != 'A') return;

    this.opcoesCentroCustosVar = new Array();
    this.key1Ajuste = 0;
    this.opcoesProjetosVar = new Array();
    this.key2Ajuste = 0;
    this.opcoesAreaVar = new Array();
    this.opcoesSubAreaVar = new Array();
    this.opcoesVar = new Array();
    this.comentarios = new Array();
    this.comentarioNew = "";
    this.comentarioEditId = null;

    for (let nivel in this.levels) {
      switch (this.levels[nivel]) {
        case "Centro de Custo":
          this.opcoesVar.push([this.opcoesCentroCustosVar, this.opcoesCentroCustosAjustes]);
          break;

        case "Projeto":
          this.opcoesVar.push([this.opcoesProjetosVar, this.opcoesProjetosAjustes]);
          break;
      }
    }

    for (let i in this.tableData[this.selectedCompany]) {
      if (this.tableData[this.selectedCompany][i]["id"] == rowdata.id) {
        this.rowEditid = Number(i);
        break;
      }
    }
    let conta = this.tableData[this.selectedCompany][this.rowEditid];
    this.opcoesVar[0][0].push({ label: "Não informado", value: 0 });

    let month = col[0].substring(0, 3);
    for (let valueNivel1 in conta[month]) {
      for (let empresa in this.opcoesVar[0][1]) {
        let info = this.opcoesVar[0][1][empresa].find(option => option.value == valueNivel1);
        if (info) {
          this.opcoesVar[0][0].push(info);
          break;
        }
      }
    }
    if (!this.versao.consolidar || this.empresaSelecionada) this.carregarComentarios();
    this.modalComentario = true;
  }

  checkComments(data) {
    return data.ind_cta === 'A' && Boolean(this.dicQtdComentarios[data.cod_cta])
  }

  getProjetosVar() {
    let mes = this.colmeses[this.mesSelecionado][0];

    for (let key1 in this.tableData[this.selectedCompany][this.rowEditid][mes]) {
      for (let key2 in this.tableData[this.selectedCompany][this.rowEditid][key1]) {
        let proj = this.opcoesVar[1][1].find(option => option.value == key2);
        if (proj) this.opcoesVar[1][0].push(proj);
      }
    }
  }

  carregarComentarios() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaId: this.versao.consolidar ? this.empresaSelecionada.toString() : this.dblinkedSessionService.empresa.id.toString(),
        ctaId: this.tableValues[this.rowEditid]["id"].toString(),
        ccusId: this.key1Ajuste.toString(),
        projId: this.key2Ajuste.toString(),
        areaId: "0",
        subareaId: "0",
        periodo: (this.mesSelecionado + 1).toString(),
        versaoId: this.versao.versao_id.toString(),
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/get-comentarios-variacao', httpOptions)
      .subscribe(
        res => {
          if (!res["error"]) {
            this.comentarios = res["mensagem"];
            this.dicQtdComentarios = res["dic_qtdcomentarios"];
            this.comentarioNew = "";
            this.comentarioEditId = null;
          } else {
            this.exibirMensagem(res);
          }

          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  salvarComentario() {
    if (this.versao.consolidar && !this.empresaSelecionada) return this.exibirMensagem({ "error": true, mensagem: "Selecione uma empresa" });

    if (this.comentarioNew !== null && this.comentarioNew !== "") {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
        }),
      };

      const payload = {
        comentario: this.comentarioNew,
        comentarioId: this.comentarioEditId,
        userId: this.sessionService.loggedUser.id,
        ctaId: this.tableValues[this.rowEditid]["id"],
        empresaId: this.versao.consolidar ? this.empresaSelecionada : this.dblinkedSessionService.empresa.id,
        mes: this.mesSelecionado + 1,
        ccusId: this.key1Ajuste,
        projId: this.key2Ajuste,
        versaoId: this.versao.versao_id.toString(),
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'orcamento/dashboard/comentarios', payload, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.carregarComentarios();
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  editarComentario(comentario: any) {
    this.comentarioNew = comentario.comentario;
    this.comentarioEditId = comentario.id
  }

  cancelarComentario() {
    this.comentarioNew = "";
    this.comentarioEditId = null;
  }

  excluirComentario(comentarioId: number) {
    if (!confirm('Deseja realmente excluir esse comentário?')) return;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        comentarioid: comentarioId.toString()
      })
    }

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'orcamento/dashboard/comentarios', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.carregarComentarios();
          this.httpService.done();
        }, err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();

        }
      )
  }

  getAjustesCcus() {
    let key = this.isRealizadoVisao ? 'real' : 'orc'
    //Reseta os ajustes
    this.ajustes = new Array();
    for (let i: number = 1; i < 13; i++) {
      this.ajustes.push({ ref: i, valor: 0 });
    }

    //Buscar os valores referente ao centro de custo selecionado se tiver
    if (
      !this.ccusRelac[this.key1Ajuste] &&
      this.ajustesSalvos[key].hasOwnProperty(this.rowEditid)
    ) {
      for (let dic of this.ajustesSalvos[key][this.rowEditid]) {
        if (dic.key1_id === this.key1Ajuste) {
          for (let aj of this.ajustes) {
            if (this.colmeses[aj.ref - 1][0] === dic.mes) {
              aj.valor = dic.valor;
              break;
            }
          }
        }
      }
    }
  }

  getAjustesProj() {
    let key = this.isRealizadoVisao ? 'real' : 'orc'
    //Reseta os ajustes
    this.ajustes = new Array();
    for (let i: number = 1; i < 13; i++) {
      this.ajustes.push({ ref: i, valor: 0, comentarios: [] });
    }

    //Buscar os valores referente ao projeto selecionado se tiver
    if (this.ajustesSalvos[key].hasOwnProperty(this.rowEditid)) {
      for (let dic of this.ajustesSalvos[key][this.rowEditid]) {
        if (dic.key2_id === this.key2Ajuste) {
          for (let aj of this.ajustes) {
            if (this.colmeses[aj.ref - 1][0] === dic.mes) {
              aj.valor = dic.valor;
              break;
            }
          }
        }
      }
    }
  }

  carregarValores(numeroMes: number) {
    if (confirm("Deseja carregar as informações para os meses seguintes?")) {
      var i: any;
      for (i in this.ajustes) {
        if (i > numeroMes - 1) {
          this.ajustes[i].valor = this.ajustes[numeroMes - 1].valor;
          this.ajustes[i].comentario = this.ajustes[numeroMes - 1].comentario;
        }
      }
    }
  }

  atualizarContasSinteticas() {
    let listaTable: Array<any> = [this.tableValuesOrca, this.tableValuesVar];
    //Resetar as contas analiticas
    listaTable.forEach(table => {
      for (let dic of table) {
        //Contas sinteticas
        if (dic["ind_cta"] === "S") {
          //Os meses
          for (let m = 0; m < 12; m++) {
            dic[this.colmeses[m][0] + "Total"] = 0;
            dic[this.colmeses[m][0] + "RealTotal"] = 0;
          }

          //Os trimestres
          for (let trim = 0; trim < 4; trim++) {
            dic["q" + (trim + 1) + "Total"] = 0;
            dic["q" + (trim + 1) + "RealTotal"] = 0;
          }

          //O total
          dic["total"] = 0;
          dic["totalReal"] = 0;
        }
      }

      //Recalcula elas
      for (let n of [5, 4, 3, 2]) {
        for (let dic of table) {
          if (dic["nivel"] === n) {
            this.adicionarValorContaSup(dic, table);
          }
        }
      }

      //Recalcular as variações
      for (let dic of table) {
        for (let m of this.colmeses) {
          dic[m[0] + "VarTotal"] = dic[m[0] + "RealTotal"] - dic[m[0] + "Total"];
        }

        for (let trim = 0; trim < 4; trim++) {
          dic["q" + (trim + 1) + "VarTotal"] = dic["q" + (trim + 1) + "RealTotal"] - dic["q" + (trim + 1) + "Total"];
        }

        dic["totalVar"] = dic["totalReal"] - dic["total"];
      }

      this.calcContasTotais(table);
    });
  }

  resolve(cod_cta, month: string, table) {
    let response: any = 0, row: any = {}
    row = table.find(item => item.cod_cta == cod_cta)
    if (!['total', 'totalReal'].includes(month)) month = `${month}Total`
    if (row) response = row.ind_rd == 'D' && row.ind_cta == "A" ? -row[month] : row[month]
    else response = '0'
    return response
  }

  buildForm(form, month, table) {
    let response = ''
    if (form.includes('{')) {
      let block = form.slice(form.indexOf('{'), form.indexOf('}') + 1).toUpperCase()
      let restAfter = form.slice(form.indexOf('}') + 1)
      let restBefore = form.slice(0, form.indexOf('{'))
      let numbers = block.split(/[{}]/).filter(item => item !== "")
      response = restBefore + "(" + this.resolve(numbers, month, table) + ")" + restAfter
    }

    return response.includes('{') ? this.buildForm(response, month, table) : response
  }

  calcContasTotais(table: any) {
    table.forEach(item => {
      if (item.ind_cta === "T" && item.formula !== null) {
        // Zera a conta
        this.movMonths.forEach((month, index) => {
          let currentTrim = ((index + 1.5) / 3).toFixed(0)
          item[`${month}Total`] = 0
          item[`q${currentTrim}Total`] = 0
          item[`${month}RealTotal`] = 0
          item[`q${currentTrim}RealTotal`] = 0
        })
        item.total = 0
        item.totalReal = 0
        // Pega o codigo das contas na formula
        this.movMonths.forEach((month, index) => {
          let currentTrim = ((index + 1.5) / 3).toFixed(0)
          let formResult = eval(this.buildForm(item.formula, month, table))
          let formResultReal = eval(this.buildForm(item.formula, `${month}Real`, table))
          if (formResult == Infinity || formResult == -Infinity) formResult = 0
          if (formResultReal == Infinity || formResultReal == -Infinity) formResultReal = 0
          item[`${month}Total`] += formResult
          item[`${month}RealTotal`] += formResultReal
          item[`${month}VarTotal`] = (item[`${month}Total`] - item[`${month}RealTotal`]) * (item.ind_rd == 'R' ? -1 : 1)
          if (!this.isAccumulatedVision) {
            item[`q${currentTrim}Total`] += formResult
            item[`q${currentTrim}RealTotal`] += formResultReal

          } else {
            item[`q${currentTrim}Total`] = formResult
            item[`q${currentTrim}RealTotal`] = formResultReal
          }

          if (item.is_porcentagem) {
            let formResultPor = eval(this.buildForm(item.formula, "q" + currentTrim, table))
            if (formResultPor == Infinity || formResultPor == -Infinity) formResultPor = 0
            let formResultPorReal = eval(this.buildForm(item.formula, "q" + currentTrim + "Real", table))
            if (formResultPorReal == Infinity || formResultPorReal == -Infinity) formResultPorReal = 0
            item[`q${currentTrim}Total`] = formResultPor
            item[`q${currentTrim}RealTotal`] = formResultPorReal
          }
          item[`q${currentTrim}VarTotal`] = (item[`q${currentTrim}Total`] - item[`q${currentTrim}RealTotal`]) * (item.ind_rd == 'R' ? -1 : 1)
        })
        item.total = eval(this.buildForm(item.formula, 'total', table))
        if (item.total == Infinity || item.total == -Infinity) item.total = 0
        item.totalReal = eval(this.buildForm(item.formula, 'totalReal', table))
        if (item.totalReal == Infinity || item.totalReal == -Infinity) item.totalReal = 0
        item.totalVar = (item.total - item.totalReal) * (item.ind_rd == 'R' ? -1 : 1)
      }
    })
  }

  adicionarValorContaSup(dicin: any, dicout: any) {
    for (let line of dicout) {
      if (line["id"] === dicin["parent_id"] && line["ind_cta"] === "S") {
        //Por mês
        for (let m = 0; m < 12; m++) {
          line[this.colmeses[m][0] + "Total"] += dicin.ind_rd === "D" && dicin.ind_cta === "A" ? dicin[this.colmeses[m][0] + "Total"] * -1 : dicin[this.colmeses[m][0] + "Total"];
          line[this.colmeses[m][0] + "RealTotal"] += dicin.ind_rd === "D" && dicin.ind_cta === "A" ? dicin[this.colmeses[m][0] + "RealTotal"] * -1 : dicin[this.colmeses[m][0] + "RealTotal"];
        }

        //Por trimestre
        for (let trim = 0; trim < 4; trim++) {
          line["q" + (trim + 1) + "Total"] += dicin.ind_rd === "D" && dicin.ind_cta === "A" ? dicin["q" + (trim + 1) + "Total"] * -1 : dicin["q" + (trim + 1) + "Total"];
          line["q" + (trim + 1) + "RealTotal"] += dicin.ind_rd === "D" && dicin.ind_cta === "A" ? dicin["q" + (trim + 1) + "RealTotal"] * -1 : dicin["q" + (trim + 1) + "RealTotal"];
        }

        //Total
        line["total"] += dicin.ind_rd === "D" && dicin.ind_cta === "A" ? dicin["total"] * -1 : dicin["total"];
        line["totalReal"] += dicin.ind_rd === "D" && dicin.ind_cta === "A" ? dicin["totalReal"] * -1 : dicin["totalReal"];

        break;
      }
    }
  }

  salvarAjustes() {

    if (this.key2Ajuste === 0 && this.ccusRelac[this.key1Ajuste]) {
      this.exibirMensagem({ error: true, mensagem: "Selecione um projeto para fazer o ajuste." });

    } else {
      //Pega linha para salvar ajuste
      this.tableData[this.selectedCompany].forEach((item, index) => {
        if (item.cod_cta == this.selectedCodCta) this.rowEditid = index
      })

      let key = this.isRealizadoVisao ? 'real' : this.isForecast ? 'for' : 'orc';

      // verifica se ja existe as chaves necessarias para salvar o ajuste
      if (!this.ajustesSalvos[key].hasOwnProperty(this.selectedCodCta)) this.ajustesSalvos[key][this.selectedCodCta] = {}
      if (!this.ajustesSalvos[key][this.selectedCodCta].hasOwnProperty(this.selectedCompany)) this.ajustesSalvos[key][this.selectedCodCta][this.selectedCompany] = []

      //cria nova estrutura para ser salva
      let obj = {
        key1_id: Number(this.key1Ajuste),
        key2_id: this.key2Ajuste ? Number(this.key2Ajuste) : 0,
        key3_id: this.key3Ajuste ? Number(this.key3Ajuste) : 0,
        valor: 0,
        comentarios: {}
      }

      this.movMonths.forEach((month, index) => {

        month = key === 'real' ? `${month}Real` : key === 'for' ? `${month}For` : month
        // armazena valores mensais na nova estrutura
        obj[month] = this.ajustes[index].valor
        obj.valor += this.ajustes[index].valor
        obj.comentarios[month] = this.ajustes[index].comentario;


        //Calcula o trimestre atual
        let currentTrim = ((index + 1.5) / 3).toFixed(0)
        currentTrim += key === 'real' ? 'Real' : key === 'for' ? 'For' : ''

        //Adiciona o ajuste ao mes e trimestre
        switch (this.keyLevel) {
          case 1:
            if (!this.tableData[this.selectedCompany][this.rowEditid][month].hasOwnProperty(obj.key1_id))
              this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id] = { ajuste: 0, mov: 0, original: 0 };

            if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`].hasOwnProperty(obj.key1_id))
              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id] = { ajuste: 0, mov: 0, original: 0 };
            else if (index === 0) this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id].ajuste = 0

            this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id].ajuste = obj[month];
            this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id].comentarios = obj.comentarios[month];
            this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id].ajuste += obj[month];
            break
          case 2:
            if (!this.tableData[this.selectedCompany][this.rowEditid][month].hasOwnProperty(obj.key1_id))
              this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id] = {};

            if (!this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id].hasOwnProperty(obj.key2_id))
              this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id][obj.key2_id] = { ajuste: 0, mov: 0, original: 0 };

            if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`].hasOwnProperty(obj.key1_id))
              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id] = {};

            if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id].hasOwnProperty(obj.key2_id))
              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id][obj.key2_id] = { ajuste: 0, mov: 0, original: 0 };
            else if (index === 0) this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id][obj.key2_id].ajuste = 0

            this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id][obj.key2_id].ajuste = obj[month];
            this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id][obj.key2_id].comentarios = obj.comentarios[month];
            this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id][obj.key2_id].ajuste += obj[month];
            break
          case 3:
            if (!this.tableData[this.selectedCompany][this.rowEditid][month].hasOwnProperty(obj.key1_id))
              this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id] = {};

            if (!this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id].hasOwnProperty(obj.key2_id))
              this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id][obj.key2_id] = {};

            if (!this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id][obj.key2_id].hasOwnProperty(obj.key3_id))
              this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id][obj.key2_id][obj.key3_id] = { ajuste: 0, mov: 0, original: 0 };

            if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`].hasOwnProperty(obj.key1_id))
              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id] = {};

            if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id].hasOwnProperty(obj.key2_id))
              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id][obj.key2_id] = {};

            if (!this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id][obj.key2_id].hasOwnProperty(obj.key3_id))
              this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id][obj.key2_id][obj.key3_id] = { ajuste: 0, mov: 0, original: 0 };
            else if (index === 0) this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id][obj.key2_id][obj.key3_id].ajuste = 0

            this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id][obj.key2_id][obj.key3_id].ajuste = obj[month];
            this.tableData[this.selectedCompany][this.rowEditid][month][obj.key1_id][obj.key2_id][obj.key3_id].comentarios = obj.comentarios[month];
            this.tableData[this.selectedCompany][this.rowEditid][`q${currentTrim}`][obj.key1_id][obj.key2_id][obj.key3_id].ajuste += obj[month];
            break
        }
      })

      //salva estrutura com os demais ajustes
      let index: any = null

      switch (this.keyLevel) {
        case 1:
          this.ajustesSalvos[key][this.selectedCodCta][this.selectedCompany].forEach(
            (item, ind) => { if (item.key1_id == obj.key1_id) index = ind })
          break
        case 2:
          this.ajustesSalvos[key][this.selectedCodCta][this.selectedCompany].forEach(
            (item, ind) => { if (item.key1_id == obj.key1_id && item.key2_id == obj.key2_id) index = ind })
          break
        case 3:
          this.ajustesSalvos[key][this.selectedCodCta][this.selectedCompany].forEach(
            (item, ind) => { if (item.key1_id == obj.key1_id && item.key2_id == obj.key2_id && item.key3_id == obj.key3_id) index = ind })
          break
      }
      if (index !== null)
        this.ajustesSalvos[key][this.selectedCodCta][this.selectedCompany][index] = obj;
      else
        this.ajustesSalvos[key][this.selectedCodCta][this.selectedCompany].push(obj);

      //Recalcula a tabela
      this.recalcRow();

      this.formatValues();

      this.modalAjustes = false;

    }
  }

  recalcRow(line: any = null): void {
    const lineToEdit = line ? line : this.rowEditid;

    this.tableData[this.selectedCompany][lineToEdit][this.isForecast ? "totalFor" : "total"] = 0;

    this.movMonths.forEach((month, index) => {
      //Calcula o trimestre atual
      let currentTrim = this.isForecast ? ((index + 1.5) / 3).toFixed(0) + 'For' : ((index + 1.5) / 3).toFixed(0);

      if (this.isRealizadoVisao) {
        let monthReal = `${month}Real`
        this.tableData[this.selectedCompany][lineToEdit][monthReal + "Total"] = 0

        switch (this.keyLevel) {
          // caso tenha apenas 1 nivel
          case 1:
            for (let key1 in this.tableData[this.selectedCompany][lineToEdit][monthReal]) {
              for (let type of ['original', 'mov', 'ajuste']) {

                this.tableData[this.selectedCompany][lineToEdit][monthReal + "Total"] +=
                  this.tableData[this.selectedCompany][lineToEdit][monthReal][key1][type];


                this.tableData[this.selectedCompany][lineToEdit]["total"] +=
                  this.tableData[this.selectedCompany][lineToEdit][monthReal][key1][type];

              }
            }
            if ([1, 2, 3, 4].includes((index + 1) / 3)) {
              this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}RealTotal`] = 0;
              for (let key1 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Real`]) {
                for (let type of ['original', 'mov', 'ajuste']) {
                  this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}RealTotal`] +=
                    this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Real`][key1][type];
                }
              }
            }
            break

          // caso tenha apenas 2 niveis
          case 2:
            for (let key1 in this.tableData[this.selectedCompany][lineToEdit][monthReal]) {
              for (let key2 in this.tableData[this.selectedCompany][lineToEdit][monthReal][key1]) {
                for (let type of ['original', 'mov', 'ajuste']) {

                  this.tableData[this.selectedCompany][lineToEdit][monthReal + "Total"] +=
                    this.tableData[this.selectedCompany][lineToEdit][monthReal][key1][key2][type];


                  this.tableData[this.selectedCompany][lineToEdit]["total"] +=
                    this.tableData[this.selectedCompany][lineToEdit][monthReal][key1][key2][type];

                }
              }
            }
            if ([1, 2, 3, 4].includes((index + 1) / 3)) {
              this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}RealTotal`] = 0;
              for (let key1 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Real`]) {
                for (let key2 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Real`][key1]) {
                  for (let type of ['original', 'mov', 'ajuste']) {
                    this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}RealTotal`] +=
                      this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Real`][key1][key2][type];
                  }
                }
              }
            }
            break
          // caso tenha 3 niveis
          case 3:
            for (let key1 in this.tableData[this.selectedCompany][lineToEdit][monthReal]) {
              for (let key2 in this.tableData[this.selectedCompany][lineToEdit][monthReal][key1]) {
                for (let key3 in this.tableData[this.selectedCompany][lineToEdit][monthReal][key1][key2]) {
                  for (let type of ['original', 'mov', 'ajuste']) {

                    this.tableData[this.selectedCompany][lineToEdit][monthReal + "Total"] +=
                      this.tableData[this.selectedCompany][lineToEdit][monthReal][key1][key2][key3][type];


                    this.tableData[this.selectedCompany][lineToEdit]["total"] +=
                      this.tableData[this.selectedCompany][lineToEdit][monthReal][key1][key2][key3][type];

                  }
                }
              }
            }
            if ([1, 2, 3, 4].includes((index + 1) / 3)) {
              this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}RealTotal`] = 0;
              for (let key1 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Real`]) {
                for (let key2 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Real`][key1]) {
                  for (let key3 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Real`][key1][key2]) {
                    for (let type of ['original', 'mov', 'ajuste']) {
                      this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}RealTotal`] +=
                        this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Real`][key1][key2][key3][type];
                    }
                  }
                }
              }
            }
            break
        }
      }
      month = this.isForecast ? `${month}For` : month

      //Recalcula o total do mês e o total da conta
      this.tableData[this.selectedCompany][lineToEdit][`${month}Total`] = 0

      switch (this.keyLevel) {
        // caso tenha apenas 1 nivel
        case 1:
          for (let key1 in this.tableData[this.selectedCompany][lineToEdit][month]) {
            for (let type of ['original', 'mov', 'ajuste']) {

              this.tableData[this.selectedCompany][lineToEdit][`${month}Total`] +=
                this.tableData[this.selectedCompany][lineToEdit][month][key1][type];

              if (!this.isForecast) {
                this.tableData[this.selectedCompany][lineToEdit]["total"] +=
                  this.tableData[this.selectedCompany][lineToEdit][month][key1][type];
              }
            }
          }
          if ([1, 2, 3, 4].includes((index + 1) / 3)) {
            this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Total`] = 0;
            for (let key1 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}`]) {
              for (let type of ['original', 'mov', 'ajuste']) {
                this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Total`] +=
                  this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}`][key1][type];
              }
            }
            if (!this.isForecast) {
              this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}VarTotal`] =
                this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}RealTotal`] - this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Total`]
            }
          }
          break

        // caso tenha apenas 2 niveis
        case 2:
          for (let key1 in this.tableData[this.selectedCompany][lineToEdit][month]) {
            for (let key2 in this.tableData[this.selectedCompany][lineToEdit][month][key1]) {
              for (let type of ['original', 'mov', 'ajuste']) {

                this.tableData[this.selectedCompany][lineToEdit][`${month}Total`] +=
                  this.tableData[this.selectedCompany][lineToEdit][month][key1][key2][type];

                if (!this.isForecast) {
                  this.tableData[this.selectedCompany][lineToEdit]["total"] +=
                    this.tableData[this.selectedCompany][lineToEdit][month][key1][key2][type];
                }
              }
            }
          }
          if ([1, 2, 3, 4].includes((index + 1) / 3)) {
            this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Total`] = 0;
            for (let key1 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}`]) {
              for (let key2 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}`][key1]) {
                for (let type of ['original', 'mov', 'ajuste']) {
                  this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Total`] +=
                    this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}`][key1][key2][type];
                }
              }
            }
            if (!this.isForecast) {
              this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}VarTotal`] =
                this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}RealTotal`] - this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Total`]
            }
          }
          break
        // caso tenha 3 niveis
        case 3:
          for (let key1 in this.tableData[this.selectedCompany][lineToEdit][month]) {
            for (let key2 in this.tableData[this.selectedCompany][lineToEdit][month][key1]) {
              for (let key3 in this.tableData[this.selectedCompany][lineToEdit][month][key1][key2]) {
                for (let type of ['original', 'mov', 'ajuste']) {

                  this.tableData[this.selectedCompany][lineToEdit][`${month}Total`] +=
                    this.tableData[this.selectedCompany][lineToEdit][month][key1][key2][key3][type];

                  if (!this.isForecast) {
                    this.tableData[this.selectedCompany][lineToEdit]["total"] +=
                      this.tableData[this.selectedCompany][lineToEdit][month][key1][key2][key3][type];
                  }
                }
              }
            }
          }
          if ([1, 2, 3, 4].includes((index + 1) / 3)) {
            this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Total`] = 0;
            for (let key1 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}`]) {
              for (let key2 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}`][key1]) {
                for (let key3 in this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}`][key1][key2]) {
                  for (let type of ['original', 'mov', 'ajuste']) {
                    this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Total`] +=
                      this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}`][key1][key2][key3][type];
                  }
                }
              }
            }
            if (!this.isForecast) {
              this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}VarTotal`] =
                this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}RealTotal`] - this.tableData[this.selectedCompany][lineToEdit][`q${currentTrim}Total`]
            }
          }
          break
      }
      if (!this.isForecast) {
        this.tableData[this.selectedCompany][lineToEdit][month + "VarTotal"] =
          this.tableData[this.selectedCompany][lineToEdit][`${month}RealTotal`] - this.tableData[this.selectedCompany][lineToEdit][`${month}Total`]
      }
    })
  }

  checkForecast(mes: String): boolean {
    return this.isRealizadoVisao ? true : this.colmeses.filter(month => month[0] == mes)[0][3] === 'R'
  }

  inverterSinal(col: Array<any>, rowData: any) {
    if (rowData.ind_cta === 'T' && rowData.is_porcentagem) {
      if (col[1] === 'Var.') return "-";
      let key = col[0].replace(/por/i, '');

      let value = (rowData.inverter_sinal || rowData.ind_rd === 'D') ? -rowData[key] : rowData[key];

      if (isNaN(value) || +value === Infinity) value = 0;

      return col[1].includes('Var. %') ? this.toPercent(value) + "p.p." : this.toPercent(value) + "%";
    }

    if (!col[1].includes('Var. %')) {
      if (rowData.inverter_sinal || (rowData.ind_rd === 'D' && rowData.ind_cta !== 'A')) {
        return -rowData[col[0]];

      } else {
        return rowData[col[0]];
      }

    } else {
      return this.toPercent(rowData[col[0]]) + '%';
    }

  }

  changeComentarioGeral() {
    this.key1Ajuste = 0;
    this.key2Ajuste = 0;
    this.carregarComentarios();
  }

  formatValue(rowData, value) {
    if (rowData.inverter_sinal || (rowData.ind_rd === 'D' && rowData.ind_cta !== 'A')) value = -value;
    if (rowData.ind_cta == 'T' && rowData.is_porcentagem) value *= 100;
    return rowData.ind_cta == 'N' ? '' : value;
  }


  getAllSubArea() {
    let key = this.keyOf('SUBAREA')
    if (key == -1) return
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        areaid: this.areaAjuste.toString()
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "cadastro/subarea", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            this.opcoesSubAreaAjustes = []
            res["data"].forEach(line => this.opcoesSubAreaAjustes.push({
              label: line["codigo"] + " - " + line["descricao"],
              value: line["id"]
            }))
          }

          this.httpService.done();
        },

        (err) => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao buscar as subareas, contate o suporte: " + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  loadingManagement(data: any){
    if(data.content.tokenProgressoSocket == this.tokenProgressoSocket){
      this.loadingProgress = Math.round(data.content.progress)
    }
  }

}
