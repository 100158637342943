import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { TbCsllM350 } from 'app/ecf/model/tb-csll-m350.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';

export class TbCsllM350Relac {
  id: number;
  planoContas: PlanoContas;
  tbCsllM350Adicao: TbCsllM350;
  tbCsllM350Exclusao: TbCsllM350;
  cadCcus: CadCcus;

  constructor(tbcsllm350relac?: TbCsllM350Relac | number) {
    if (tbcsllm350relac !== undefined && tbcsllm350relac !== null) {
      if (typeof tbcsllm350relac === 'number') {
        this.id = tbcsllm350relac;
      } else {
        this.id = tbcsllm350relac.id;
        this.cadCcus = tbcsllm350relac.cadCcus || new CadCcus();
        this.planoContas = tbcsllm350relac.planoContas || new PlanoContas();
        this.tbCsllM350Adicao = tbcsllm350relac.tbCsllM350Adicao || new TbCsllM350();
        this.tbCsllM350Exclusao = tbcsllm350relac.tbCsllM350Exclusao || new TbCsllM350();
      }
    }
  }
}
