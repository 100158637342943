import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { Uf } from 'app/util/model/uf.model';
import { MatDialog } from '@angular/material/dialog';
import {
  ParticipanteEventosDialogComponent,
  MAT_DIALOG_CONFIG_EVENTO
} from '../participante-eventos-dialog/participante-eventos-dialog.component';
import { Util } from 'app/shared/common/util';
import { EventosFicha } from '../model/eventos.ficha';
import { EventosFichaPagamento } from '../model/eventos-ficha-pagamento.model';
import { TipoBaixa } from 'app/util/model/tipo-baixa.model';
import { FormaPagamento } from 'app/util/model/forma-pagamento.model';
import { isNullOrUndefined, isUndefined } from 'util';
import { ActivatedRoute, Router, ActivationEnd, NavigationEnd, NavigationStart } from '@angular/router';
import { EventosFichaDependente } from '../model/evetos-ficha-dependente.model'
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { Palestra } from '../model/palestra.model';
import { service } from 'powerbi-client';
import { MotivoEstorno } from 'app/eventos/model/motivos-estorno.model';
import { element } from 'protractor';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Message, MessageService, SelectItem } from 'primeng/api';


@Component({
  selector: 'app-participantes-eventos',
  templateUrl: './participantes-eventos.component.html',
  styleUrls: ['./participantes-eventos.scss', '../../shared/styles/centralweb.scss'],
  providers: [CrudService]
})
export class ParticipantesEventosComponent implements OnInit, OnDestroy {


  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  opcoesEventos: SelectItem[];
  opcoesEventosOld: SelectItem[];
  opcoesEventosNew: SelectItem[];

  opcoesIngressos: SelectItem[];
  opcoesIngressosOld: SelectItem[];
  opcoesIngressosNew: SelectItem[];

  tiposBaixas: SelectItem[];
  ingressosSelecionado: number;
  ingressoSelecionadoOld: number = 0;
  ingressoSelecionadoNew: number = 0;
  opcoesFormaPagto: SelectItem[];

  opcoesTipoPagamento: SelectItem[];
  tipoPagamentoSelecionado: number;
  public isCreditoAVista: boolean = false;
  public isCreditoParcelado: boolean = false;
  public isCartaoDebito: boolean = false;
  public isDinheiro: boolean = false;
  public isCheque: boolean = false;
  public isDeposito: boolean = false;
  isPagSeguro: boolean;
  public isPesquisa: boolean;
  public isCadastro: boolean;

  opcoesDepositoBancario: SelectItem[];
  depositoBancarioSelecionado: number;

  opcoesUF: SelectItem[];
  oldParticipanteOpcoesUF: SelectItem[];
  newParticipanteOpcoesUF: SelectItem[];

  ufSelecionada: number;

  opcoesMunicipio: SelectItem[];
  oldParticipanteopcoesMunicipio: SelectItem[];
  newParticipanteopcoesMunicipio: SelectItem[];


  municipioSelecionado: number;
  cpf: String;
  nome: String;
  ddd: String;
  telefone: String;
  email: String;
  cep: String;
  logradouro: String;
  numero: String;
  bairro: String;
  complemento: String;
  valor: String;
  numeroCartao: String;
  dtValidade: Date;
  ccv: String;
  parceladoem: String;
  eventosFicha = new EventosFicha();

  fichaPagamento = new EventosFichaPagamento();
  eventosFichaDependente = new EventosFichaDependente();
  fichaPagamentoTabela = new EventosFichaPagamento();
  eventosFichaDependenteTabela = new EventosFichaDependente();

  valorPacote: any;
  valorPromocional: any;

  editFormaPagto: boolean;
  editDependente: boolean;

  min: number;
  max: number;

  opcoesPalestra: SelectItem[];
  opcoesVendedor: SelectItem[];
  palestraSelecionada: number;
  isDisableSave: boolean;
  isBonus: boolean;

  opcoesStatus: SelectItem[];
  opcoesStatusSelecionado: number;
  origemparticipante: Array<any> = new Array();
  tipo: String = '9';
  eventoFichaId: number;
  ufId: number;
  municipioId: number;
  nomeDependente: String;
  cpfDependente: String;
  emailDependente: String;
  telefoneDependente: String;
  isPodeEditar: boolean;
  selectedEventosArray: Array<any> = new Array();
  checkedAll: boolean = false;
  idParticipanteEventoView: Array<any> = new Array();
  participantesEventos: Array<any> = new Array();
  idParticipanteEventoExcluirView: Array<any> = new Array();
  boolPalestraAberta: boolean = false;
  totalDependentes: number = 0;
  totalFichaPagamento: number = 0;
  eventoSelecionadoOld: number = 0;
  eventoSelecionadoNew: number = 0;


  modalMotivosEstornos: boolean = false;
  modalTransferencia: boolean = false;
  modalInformacaoTransferencia: boolean = false;
  opcoesMotivosEstornos: SelectItem[];
  motivoEstornoSelecionado: number;
  motivos: MotivoEstorno;
  isTemEstorno: boolean = false;
  observacaoEstorno: string;
  valorEstorno: number;
  valorTaxaTransferencia: number = 0.00;
  valorCreditoTransferencia: number = 0.00;
  observacaoTransferencia: string = '';
  listaTransferenciaInf: Array<any> = new Array();
  somaPagamentos: number = 0.00;
  autorizacao: string;
  numeroDocumento: string;
  numeroTerminal: string;
  numeroEstabelecimento: string;


  oldParticipanteEventosFicha = new EventosFicha();
  newParticipanteEventosFicha = new EventosFicha();

  selectedIndex: number = 0;
  depId: number = 0;
  selectedIndexInformacoes: number = 0;
  listaTransferenciaInfParticipante: Array<any> = new Array();
  opcoesInstituicoesBancarias: SelectItem[];
  instituicoesBancariaSelecionada: number = 0;
  agenciaBanco: string = null;
  bancoConta: string = null;
  opcoesFormaPagamentoEstorno: SelectItem[];
  formaPagamentoEstornoSelecionado: number = 0;
  opcoesStatusCombo: SelectItem[];
  public boolBoletoVencido: boolean = false;
  isPodeEditarBoletoDataAnterior: boolean = false;
  opcoesComboDeptoEmpresa: SelectItem[];
  opcoesDeptoEmpresa: SelectItem[];
  valorMaximoEstorno: number = 0.00;
  modalPesquisarNome: boolean = false;
  nomeSearch: string = '';
  opcoesEventosMes: any[] = [];
  eventoIdPesquisa: number = 0;

  colsOrigemParticipante: any;

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private matDialog: MatDialog,
    private dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService

  ) {

    this.activatedRoute.params.subscribe(params => {

      this.toolbarService.clear();
      if (params['tipo'] === '0') {
        this.tipo = '0';

        // variavel, utilizar replace()
        // this.toolbarService.clear();
        // this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Criar novo');
        // this.toolbarService.replace('add', false, 'class-new-button', 'SELECIONE', 'Adicionar', () => { this.novoParticipante(); });
        setTimeout(() => {
          this.configurarToolBar();
        }, 500);
      } else if (params['tipo'] === '1') {
        this.toolbarService.clear();
        this.limpaTela();
        this.listar();
        this.isPodeEditar = null;

        this.tipo = '1';

        // this.toolbarService.add('info', true, 'class-edit-button', 'info', 'Informações transferência');
        // this.toolbarService.add('undo', true, 'class-edit-button', 'sync_alt', 'Transferir evento');
        // this.toolbarService.add('save', true, 'class-edit-button', 'payment', 'Motivo Estorno');
        // this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
        // this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar');
      }

    });



  }


  ngOnInit(): void {

    this.limpaTela();
    this.isDisableSave = false;
    this.eventosFicha.novoEventoFicha = true;
    this.eventosFicha.substituirBoletoAnterior = false;
    this.editDependente = false;
    this.isPodeEditar = true;
    this.valorEstorno = 0.00;
    this.valorTaxaTransferencia = 0.00;
    this.valorCreditoTransferencia = 0.00;

    this.origemparticipante = [];
    this.listaTransferenciaInf = [];
    this.listaTransferenciaInfParticipante = [];
    this.opcoesDeptoEmpresa = [];

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'PARTICIPANTE';
    this.subtitle = 'Participante do evento';
    this.titleService.title = 'PARTICIPANTE';
    this.editFormaPagto = false;
    this.editDependente = false;
    this.isBonus = false;
    this.modalMotivosEstornos = false;
    this.modalTransferencia = false;
    this.isTemEstorno = false;
    this.boolPalestraAberta = false;
    this.totalDependentes = 0;
    this.totalFichaPagamento = 0;
    this.observacaoEstorno = '';
    this.observacaoTransferencia = '';

    this.autorizacao = '';
    this.numeroDocumento = '';
    this.numeroTerminal = '';
    this.numeroEstabelecimento = '';

    this.oldParticipanteEventosFicha = new EventosFicha();
    this.newParticipanteEventosFicha = new EventosFicha();
    this.eventoFichaId = 0;
    this.depId = 0;
    this.selectedIndexInformacoes = 0;
    this.modalPesquisarNome = false;
    this.nomeSearch = '';

    this.colsOrigemParticipante = [];


    //this.toolbarEnable();

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();



    //this.carregaTipoPagamento();
    this.comboTiposBaixa();
    this.ufChanged();
    this.eventosFicha = new EventosFicha();
    this.fichaPagamento = new EventosFichaPagamento();
    this.fichaPagamento.formaPagamento = new FormaPagamento();
    this.eventosFicha.fichasPagamento = new Array<any>();
    this.eventosFicha.palestra = new Palestra();
    this.eventosFicha.id = 0;
    this.valorPacote = 0;
    this.valorPromocional = 0;

    this.isCreditoAVista = false;
    this.isCreditoParcelado = false;
    this.isCartaoDebito = false;
    this.isDinheiro = false;
    this.isCheque = false;
    this.isDeposito = false;
    this.isPagSeguro = false;
    this.eventosFicha.valorTotal = null;
    this.fichaPagamento.valor = null;

    this.fichaPagamento = new EventosFichaPagamento();
    this.fichaPagamento.formaPagamento = new FormaPagamento();
    this.fichaPagamento.formaPagamento.tipoBaixa = new TipoBaixa();
    this.somaPagamentos = 0.00;

    this.isPodeEditarBoletoDataAnterior = false;

    this.colsOrigemParticipante = [
      { field: 'dataInicioEvento', header: 'Dt Ini Evento' },
      { field: 'descricaoEvento', header: 'Desc. Evento' },
      { field: 'nomeparticipante', header: 'Nome' },
      { field: 'participanteFone', header: 'Fone' },
      { field: 'nomeSeminarista', header: 'Seminarista' },
      { field: 'somaParcelas', header: 'Valor' },
      { field: 'descricaoStatusFicha', header: 'Status Ficha' },
      { field: 'deptoDescricao', header: 'DEPTO' }
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {

        this.listar();

      });


  }

  hideMessage() {


  }

  private toolbarEnable() {

    this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir');
    this.toolbarService.add('info', true, 'class-edit-button', 'info', 'Informações transferência');
    this.toolbarService.add('undo', true, 'class-edit-button', 'sync_alt', 'Transferir evento');
    this.toolbarService.add('save', true, 'class-button-gray', 'Motivo Estorno', 'Motivo Estorno');
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');
    this.toolbarService.replace(

      'add',
      false,
      'class-new-button',
      'SELECIONE',
      'Selecionar',
      () => {
        this.novoParticipante();
      }
    );
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }


  limpaTela() {
    this.cpf = '';
    this.nome = '';
    this.ddd = '';
    this.telefone = '';
    this.email = '';
    this.cep = '';
    this.logradouro = '';
    this.numero = '';
    this.bairro = '';
    this.complemento = '';
    this.dtValidade = new Date();
    this.ccv = '';
    this.valor = '';
    this.numeroCartao = '';
    this.parceladoem = '';
    this.ufSelecionada = null;
    this.municipioSelecionado = null;
    this.eventosFicha = new EventosFicha();
    this.eventosFicha.complemento = '';
    this.eventosFicha.id = 0;
    this.eventosFicha.novoEventoFicha = true;
    this.eventosFicha.substituirBoletoAnterior = false;
    this.eventosFichaDependente = new EventosFichaDependente();
    this.eventosFichaDependente.nome = '';
    this.eventosFichaDependente.telefone = '';
    this.eventosFichaDependente.cpf = '';
    this.eventosFichaDependente.email = '';
    this.eventosFicha.observacao = null;
    this.eventosFicha.eventoDadosAdicionais = null;
    this.eventosFicha.valorTotal = null;
    this.fichaPagamento.valor = null;
    this.fichaPagamento = new EventosFichaPagamento();
    this.fichaPagamento.formaPagamento = new FormaPagamento();
    this.fichaPagamento.formaPagamento.tipoBaixa = new TipoBaixa();
    this.somaPagamentos = 0.00;

  }




  validacao() {

    var dataAtual = new Date();

    let anoAtual = dataAtual.getFullYear();
    let mesAtual = dataAtual.getMonth();
    //let diaAtual = dataAtual.getDate() + 1;
    let diaAtual = dataAtual.getDate();
    var diffDias: number = 0;
    var boolBoletoVencido = false;
    var isBoleto = false;
    var isBoletoMenor10 = false;
    var valorBoleto: number = 0.00;
    var numeroParcelaBoleto: number = 0;
    var dataPrimeiroVencimento = new Date();
    var valorParcela: number = 0.00;

    dataAtual.setFullYear(anoAtual);
    dataAtual.setMonth(mesAtual);
    dataAtual.setDate(diaAtual);


    switch (this.eventosFicha.statusEventoId) {
      // 1;"Fechada completa"
      // 2;"Fechada incompleta"
      // 3;"Pendente"
      // 4;"Deletado"
      // 5;"Estornado"

      case 1: //"Fechada completa"


        if (this.eventosFicha.eventoId === 0 || isNullOrUndefined(this.eventosFicha.eventoId)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Evento não pode ser vazio. Verifique!'
          });
          return true;
        }

        if (this.eventosFicha.ingressosId === 0 || isNullOrUndefined(this.eventosFicha.ingressosId)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Ingressos não pode ser vazio. Verifique!'
          });
          return true;
        }

        if (isNullOrUndefined(this.boolPalestraAberta) || this.boolPalestraAberta === false) {
          if (!isNullOrUndefined(this.eventosFicha.atendente)) {
            if (this.eventosFicha.atendente.length < 3 || isNullOrUndefined(this.eventosFicha.atendente)) {
              this.messageService.add({
                severity: 'warn', key: 'messageLinnks',
                summary: 'MSG: ',
                detail: 'O nome do atendente deve ter no mínimo 3 caracteres. Verifique!'
              });
              return true;
            }
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'O nome do atendente deve ter no mínimo 3 caracteres. Verifique!'
            });
            return true;
          }
        }


        if (isNullOrUndefined(this.eventosFicha.dataVenda) || this.eventosFicha.dataVenda.toString() === '') {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Favor informar a data da venda. Verifique!'
          });
          return true;
        }

        if (isNullOrUndefined(this.eventosFicha.deptoEmpresaId) || this.eventosFicha.deptoEmpresaId === 0) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Favor selecionar o departamento. Verifique!'
          });
          return true;
        }


        if (this.eventosFicha.nome === '' || isNullOrUndefined(this.eventosFicha.nome)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Nome não pode ser vazio. Verifique!'
          });
          return true;
        }

        if (isNullOrUndefined(this.eventosFicha.cpf) && isNullOrUndefined(this.eventosFicha.cnpj)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'CPF ou CNPJ não pode ser vazio. Verifique!'
          });
          return true;
        }


        if ((isNullOrUndefined(this.eventosFicha.cnpj) || this.eventosFicha.cnpj === '') && (!isNullOrUndefined(this.eventosFicha.cpf) && this.eventosFicha.cpf.length < 11)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'É Obrigatório informar CPF!' });
          return true;
        }

        if (this.eventosFicha.email === '' || this.eventosFicha.email === null || this.eventosFicha.email === undefined) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Email não pode ser vazio. Verifique!'
          });
          return true;
        }

        if (!Util.validacaoEmail(this.eventosFicha.email)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Email inválido. Verifique!'
          });
          return true;

        }


        if (isNullOrUndefined(this.eventosFicha.cnpj) || this.eventosFicha.cnpj === '') {
          if (!Util.isCpf(this.eventosFicha.cpf.toString().replace(/[^\d]+/g, ''))) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'CPF inválido ' });
            return true;
          }
        }


        if (isNullOrUndefined(this.eventosFicha.cpf) || this.eventosFicha.cpf === '') {
          if (!Util.isCnpj(this.eventosFicha.cnpj.toString().replace(/[^\d]+/g, ''))) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'CNPJ inválido ' });
            return true;
          }
        }

        if (this.eventosFicha.telefone === '' || isNullOrUndefined(this.eventosFicha.telefone)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Telefone não pode ser vazio. Verifique!'
          });
          return true;
        }

        if (this.eventosFicha.telefone.length < 10) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Telefone inválido. Verifique!'
          });
          return true;
        }

        if (this.eventosFicha.cep === '' || this.eventosFicha.cep === null || this.eventosFicha.cep === undefined) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'CEP não pode ser vazio. Verifique!'
          });
          return true;
        }


        if (this.eventosFicha.logradouro === '' || this.eventosFicha.logradouro === null || this.eventosFicha.logradouro === undefined) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Logradouro não pode ser vazio. Verifique!'
          });
          return true;
        }

        if (this.eventosFicha.numero === '' || this.eventosFicha.numero === null || this.eventosFicha.numero === undefined) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Número não pode ser vazio. Verifique!'
          });
          return true;
        }


        if (this.eventosFicha.bairro === '' || this.eventosFicha.bairro === null || this.eventosFicha.bairro === undefined) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Bairro não pode ser vazio. Verifique!'
          });
          return true;
        }


        if (this.eventosFicha.municipioId === null || this.eventosFicha.municipioId === undefined) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Municipio não pode ser vazio. Verifique!'
          });
          return true;
        }


        if (this.eventosFicha.ufId === null || this.eventosFicha.ufId === undefined) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'UF não pode ser vazio. Verifique!'
          });
          return true;
        }

        if (!isNullOrUndefined(this.eventosFicha.fichasPagamento)) {

          this.eventosFicha.fichasPagamento.forEach(element => {

            //if (isNullOrUndefined(this.eventosFicha.novoEventoFicha) || this.eventosFicha.novoEventoFicha ) {
            if (!isNullOrUndefined(element.formaPagamento) && (element.formaPagamento.id === 83 || element.formaPagamento.id === 82)) {
              //var dataPrimeiroVencimento = new Date();
              isBoleto = false;
              valorBoleto = element.valor;
              numeroParcelaBoleto = element.parcela;

              try {
                let ano = element.dtPrimeiroVencimento.getFullYear();
                let mes = element.dtPrimeiroVencimento.getMonth();
                let dia = element.dtPrimeiroVencimento.getDate();

                dataPrimeiroVencimento.setFullYear(ano);
                dataPrimeiroVencimento.setMonth(mes);
                dataPrimeiroVencimento.setDate(dia);
              } catch (error) {

                //let dia = element.dtPrimeiroVencimento.getDate();
                let dia = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(0, element.dtPrimeiroVencimento.toString().indexOf('/')));
                //let mes = element.dtPrimeiroVencimento.getMonth() ;
                let mes = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(element.dtPrimeiroVencimento.toString().indexOf('/') + 1, element.dtPrimeiroVencimento.toString().lastIndexOf('/'))) - 1;
                //let ano = element.dtPrimeiroVencimento.getFullYear();
                let ano = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(element.dtPrimeiroVencimento.toString().lastIndexOf('/') + 1, element.dtPrimeiroVencimento.toString().length));
                dataPrimeiroVencimento.setFullYear(ano);
                dataPrimeiroVencimento.setMonth(mes);
                dataPrimeiroVencimento.setDate(dia);
              }



              if (!isNullOrUndefined(element.valorParcela)) {
                valorParcela = Number.parseFloat(element.valorParcela.toString());

                if (valorParcela < 10) {
                  isBoletoMenor10 = true;
                }

              }
              //this.getVerificarSePodeEditarEventoFichaId(this.eventosFicha.id, valorParcela,dataPrimeiroVencimento,numeroParcelaBoleto);

              // diffDias = Math.floor( (Date.UTC(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate())  - Date.UTC(dataPrimeiroVencimento.getFullYear(), dataPrimeiroVencimento.getMonth() , dataPrimeiroVencimento.getDate() ) / (1000 * 60 * 60 * 24)) );
              diffDias = this.transformDias(dataPrimeiroVencimento, dataAtual);

              //if (!(dataAtual <=  dataPrimeiroVencimento)) {
              if (!this.isPodeEditarBoletoDataAnterior) {
                if (diffDias < 1) {
                  this.messageService.add({
                    severity: 'warn', key: 'messageLinnks',
                    summary: 'MSG: ',
                    detail: 'A data de vencimento tem que ser pelo menos a data de amanhã!'
                  });
                  boolBoletoVencido = true;
                  return true;
                }
              }



              if (diffDias > 30) {
                this.messageService.add({
                  severity: 'warn', key: 'messageLinnks',
                  summary: 'MSG: ',
                  detail: 'O boleto não pode mais que 30 dias!'
                });
                boolBoletoVencido = true;
                return true;
              }



              if (isBoletoMenor10) {
                this.messageService.add({
                  severity: 'warn', key: 'messageLinnks',
                  summary: 'MSG: ',
                  detail: 'O valor da parcela do boleto não pode ser menor que R$10!'
                });
                return true;
              }


            }
            //}



          })

          if (boolBoletoVencido) {
            return true;
          }

        }



        if (!isNullOrUndefined(this.eventosFicha.fichasPagamento) && this.eventosFicha.fichasPagamento.length === 0) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Não há um pagamento cadastrado para esse cliente!'
          });
          return true;
        }



        return false;

      case 5:

        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Não é permitido modificar um registro estornado!'
        });

        return true;

      default:


        if (this.eventosFicha.eventoId === 0 || isNullOrUndefined(this.eventosFicha.eventoId)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Evento não pode ser vazio. Verifique!'
          });
          return true;
        }

        if (this.eventosFicha.ingressosId === 0 || isNullOrUndefined(this.eventosFicha.ingressosId)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Ingressos não pode ser vazio. Verifique!'
          });
          return true;
        }

        if (!isNullOrUndefined(this.eventosFicha.atendente)) {
          if (this.eventosFicha.atendente.length < 3 || isNullOrUndefined(this.eventosFicha.atendente)) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'O nome do atendente deve ter no mínimo 3 caracteres. Verifique!'
            });
            return true;
          }
        }



        if (isNullOrUndefined(this.eventosFicha.statusEventoId)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Favor selecionar um status. Verifique!'
          });
          return true;
        }

        if (this.eventosFicha.nome === '' || isNullOrUndefined(this.eventosFicha.nome)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Nome não pode ser vazio. Verifique!'
          });
          return true;
        }

        if (!isNullOrUndefined(this.eventosFicha.cpf) && (this.eventosFicha.cpf !== '')) {
          if (!Util.isCpf(this.eventosFicha.cpf.toString().replace(/[^\d]+/g, ''))) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'CPF inválido. Verifique! ' });
            return true;
          }

        }


        if (!isNullOrUndefined(this.eventosFicha.fichasPagamento)) {
          this.eventosFicha.fichasPagamento.forEach(element => {
            if (!isNullOrUndefined(element.formaPagamento) && (element.formaPagamento.id === 83 || element.formaPagamento.id === 82)) {
              if (!isNullOrUndefined(this.eventosFicha.novoEventoFicha) && this.eventosFicha.novoEventoFicha) {
                let ano = element.dtPrimeiroVencimento.getFullYear();
                let mes = element.dtPrimeiroVencimento.getMonth();
                let dia = element.dtPrimeiroVencimento.getDate();

                var dataPrimeiroVencimento = new Date();

                dataPrimeiroVencimento.setFullYear(ano);
                dataPrimeiroVencimento.setMonth(mes);
                dataPrimeiroVencimento.setDate(dia);

                diffDias = this.transformDias(dataPrimeiroVencimento, dataAtual);

                if (diffDias < 1 || diffDias > 30) {
                  this.messageService.add({
                    severity: 'warn', key: 'messageLinnks',
                    summary: 'MSG: ',
                    detail: 'A data de vencimento tem que ser pelo menos a data de amanhã!'
                  });
                  boolBoletoVencido = true;
                  return true;
                }
              }


            }
          })
        }

        //console.log('diffDias: ' + diffDias);

        if (boolBoletoVencido) {
          return true;
        }

        return false;

    }



  }


  salvaParticipante() {
    var dataAtual = new Date();
    let erro = false;
    var isBoleto = false;
    var isBoletoMenor10 = false;
    var valorBoleto: number = 0.00;
    var diffDias: number = 0;
    let diaAtual = dataAtual.getDate();
    this.boolBoletoVencido = false;
    var dataPrimeiroVencimento = new Date();


    this.calculaValorTotal();
    this.getVerificarSePodeEditar()

    if (this.validacao()) {
      return;
    }

    const isPodeEditarBoletoDataAnterior = this.isPodeEditarBoletoDataAnterior;
    const substituirBoletoAnterior = (!isNullOrUndefined(this.eventosFicha.substituirBoletoAnterior) ? this.eventosFicha.substituirBoletoAnterior : false);

    this.eventosFicha.empresaId = this.dblinkedSessionService.empresa.id;
    this.eventosFicha.authUserId = this.sessionService.loggedUser.id;


    if (isNullOrUndefined(this.eventosFicha.complemento)) {
      this.eventosFicha.complemento = '';
    }

    if (isNullOrUndefined(this.eventosFicha.novoEventoFicha)) {
      this.eventosFicha.novoEventoFicha = true;
    }


    if (!isNullOrUndefined(this.eventosFicha.palestra)) {
      this.eventosFicha.palestraId = this.eventosFicha.palestra.id;
    }


    if (!isNullOrUndefined(this.eventosFicha.fichasPagamento)) {
      this.eventosFicha.fichasPagamento.forEach(element => {

        if (!isNullOrUndefined(element.formaPagamento) && (element.formaPagamento.id === 83 || element.formaPagamento.id === 82)) {
          isBoleto = true;

          try {
            let ano = element.dtPrimeiroVencimento.getFullYear();
            let mes = element.dtPrimeiroVencimento.getMonth();
            let dia = element.dtPrimeiroVencimento.getDate();

            dataPrimeiroVencimento.setFullYear(ano);
            dataPrimeiroVencimento.setMonth(mes);
            dataPrimeiroVencimento.setDate(dia);
          } catch (error) {

            //let dia = element.dtPrimeiroVencimento.getDate();
            let dia = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(0, element.dtPrimeiroVencimento.toString().indexOf('/')));
            //let mes = element.dtPrimeiroVencimento.getMonth() ;
            let mes = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(element.dtPrimeiroVencimento.toString().indexOf('/') + 1, element.dtPrimeiroVencimento.toString().lastIndexOf('/'))) - 1;
            //let ano = element.dtPrimeiroVencimento.getFullYear();
            let ano = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(element.dtPrimeiroVencimento.toString().lastIndexOf('/') + 1, element.dtPrimeiroVencimento.toString().length));
            dataPrimeiroVencimento.setFullYear(ano);
            dataPrimeiroVencimento.setMonth(mes);
            dataPrimeiroVencimento.setDate(dia);
          }


          diffDias = this.transformDias(dataPrimeiroVencimento, dataAtual);
          //console.log(diffDias);

          valorBoleto = Number.parseFloat(element.valorParcela.toString());

          if (valorBoleto < 10) {
            isBoletoMenor10 = true;
          }


          if (!substituirBoletoAnterior) {
            if (!isPodeEditarBoletoDataAnterior) {
              if (diffDias < 1) {
                this.messageService.add({
                  severity: 'warn', key: 'messageLinnks',
                  summary: 'MSG: ',
                  detail: 'A data de vencimento tem que ser pelo menos a data de amanhã!'
                });
                this.boolBoletoVencido = true;
                return true;
              }
            }

          } else {
            if (diffDias < 1) {
              this.messageService.add({
                severity: 'warn', key: 'messageLinnks',
                summary: 'MSG: ',
                detail: 'A data de vencimento tem que ser pelo menos a data de amanhã!'
              });
              this.boolBoletoVencido = true;
              return true;
            }
          }




          if (diffDias > 30) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'O boleto não pode mais que 30 dias!'
            });
            this.boolBoletoVencido = true;
            return true;
          }


        }

      })

    }


    if (isBoleto) {


      if (isBoletoMenor10) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'O valor da parcela do boleto não pode ser menor que R$10 '
        });
        return;
      }

      //if (confirm('Deseja excluir esta Forma de Pagamento?')) {
      if (!confirm('O pagamento de boleto tem que ser com data de no mínimo D+1 e no máximo D+30')) {
        return;
      }
    }


    if (!isNullOrUndefined(this.eventosFicha.statusEventoId) && this.eventosFicha.statusEventoId === 1) {
      if (!isNullOrUndefined(this.eventosFicha.substituirBoletoAnterior) && this.eventosFicha.substituirBoletoAnterior) {
        if (!isNullOrUndefined(this.eventosFicha.fichasPagamento)) {

          this.eventosFicha.fichasPagamento.forEach(element => {

            if (isNullOrUndefined(this.eventosFicha.novoEventoFicha) || this.eventosFicha.novoEventoFicha) {
              if (!isNullOrUndefined(element.formaPagamento) && (element.formaPagamento.id === 83 || element.formaPagamento.id === 82)) {
                var dataPrimeiroVencimento = new Date();

                try {
                  let ano = element.dtPrimeiroVencimento.getFullYear();
                  let mes = element.dtPrimeiroVencimento.getMonth();
                  let dia = element.dtPrimeiroVencimento.getDate();

                  dataPrimeiroVencimento.setFullYear(ano);
                  dataPrimeiroVencimento.setMonth(mes);
                  dataPrimeiroVencimento.setDate(dia);
                } catch (error) {

                  //let dia = element.dtPrimeiroVencimento.getDate();
                  let dia = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(0, element.dtPrimeiroVencimento.toString().indexOf('/')));
                  //let mes = element.dtPrimeiroVencimento.getMonth() ;
                  let mes = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(element.dtPrimeiroVencimento.toString().indexOf('/') + 1, element.dtPrimeiroVencimento.toString().lastIndexOf('/'))) - 1;
                  //let ano = element.dtPrimeiroVencimento.getFullYear();
                  let ano = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(element.dtPrimeiroVencimento.toString().lastIndexOf('/') + 1, element.dtPrimeiroVencimento.toString().length));
                  dataPrimeiroVencimento.setFullYear(ano);
                  dataPrimeiroVencimento.setMonth(mes);
                  dataPrimeiroVencimento.setDate(dia);
                }

                // diffDias = Math.floor( (Date.UTC(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate())  - Date.UTC(dataPrimeiroVencimento.getFullYear(), dataPrimeiroVencimento.getMonth() , dataPrimeiroVencimento.getDate() ) / (1000 * 60 * 60 * 24)) );
                diffDias = this.transformDias(dataPrimeiroVencimento, dataAtual);

                //if (!(dataAtual <=  dataPrimeiroVencimento)) {
                //if (this.getVerificarSePodeEditarEventoFichaId((!isNullOrUndefined( this.eventosFicha.id) ? this.eventosFicha.id : 0),valorBoleto,dataPrimeiroVencimento,element.parcela)) {
                if (!this.isPodeEditarBoletoDataAnterior) {
                  if (diffDias < 1) {
                    this.messageService.add({
                      severity: 'warn', key: 'messageLinnks',
                      summary: 'MSG: ',
                      detail: 'A data de vencimento tem que ser pelo menos a data de amanhã!'
                    });
                    this.boolBoletoVencido = true;
                    return true;
                  }
                }


                if (diffDias > 30) {
                  this.messageService.add({
                    severity: 'warn', key: 'messageLinnks',
                    summary: 'MSG: ',
                    detail: 'O boleto não pode mais que 30 dias!'
                  });
                  this.boolBoletoVencido = true;
                  return true;
                }

              }
            }


          })



          if (this.boolBoletoVencido) {
            return;
          }

        }
      }
    }


    if (this.boolBoletoVencido) {
      return;
    }

    this.httpService.wait();
    this.httpService.post('/custom/evento/salvar-evento-ficha', this.eventosFicha)
      .subscribe(result => {


        if (result.error) {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: result.message });
        } else {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result.message });

          this.limpaTela();
          this.router.navigate(['/eventos/participantes-eventos/1/0']);
          this.listar();
          this.eventosFicha.novoEventoFicha = true;
          this.eventosFicha.substituirBoletoAnterior = false;
          this.isPodeEditar = true;

          this.toolbarService.clear();
          this.toolbarService.add('info', true, 'class-edit-button', 'info', 'Informações transferência');
          this.toolbarService.add('undo', true, 'class-edit-button', 'sync_alt', 'Transferir evento');
          this.toolbarService.add('save', true, 'class-edit-button', 'payment', 'Motivo Estorno');
          this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
          this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar');

        }
        //this.toolbarService.alterarStatus('edit', true, 'Editar Participantes Evento', () => this.EditarParticipanteEvento() );
        //this.toolbarService.alterarStatus('remove', true, 'Excluir Participantes Evento', () => this.ExcluirParticipanteEvento() );

      },
        error => this.httpService.handleError(error, () => this.salvaParticipante()),
        () => this.httpService.done());



  }

  pesquisarCep() {

    this.eventosFicha.cep = this.eventosFicha.cep.replace(/[^\d]+/g, '');

    if (this.eventosFicha.cep.length !== 8) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'CEP deve conter 8 caracteres!'
      });
      return;

    } else {


      this.httpService.wait();
      this.httpService.get('/custom/service/get-endereco(' + this.eventosFicha.cep + ')')
        .subscribe(
          rst => {

            if (!rst.errors) {
              this.eventosFicha.logradouro = rst.logradouro;
              this.eventosFicha.bairro = rst.bairro;
              this.eventosFicha.ufId = rst.uf;
              this.eventosFicha.municipioId = rst.localidade;
              this.eventosFicha.numero = '';
              this.eventosFicha.complemento = '';
              // uf
              this.httpService.wait();
              this.httpService.get('/uf?$select=id,uf&$filter=uf eq ' + '\'' + rst.uf + '\'').subscribe(
                ref => {
                  try {
                    this.eventosFicha.ufId = ref.value[0].id;
                    if (this.eventosFicha.ufId) {
                      this.comboMunicipio();
                      // busca cidade
                      setTimeout(() => this.setCidadeBusca(rst.ibge), 1000);
                      // fim busca cidade
                    }
                  } catch (error) {
                    this.eventosFicha.logradouro = '';
                    this.eventosFicha.bairro = '';
                    this.eventosFicha.ufId = null;
                    this.eventosFicha.municipioId = null;
                    this.eventosFicha.numero = '';
                    this.eventosFicha.complemento = '';

                    this.messageService.add({
                      severity: 'warn', key: 'messageLinnks',
                      summary: 'MSG: ',
                      detail: 'CEP Inválido!'
                    });
                  }

                },
                error => this.httpService.handleError(error, () => this.pesquisarCep()),
                () => this.httpService.done());
              // fim uf

            } else {

              try {
                rst.errors.forEach(element => {
                  this.eventosFicha.logradouro = '';
                  this.eventosFicha.bairro = '';
                  this.eventosFicha.ufId = null;
                  this.eventosFicha.municipioId = null;
                  this.eventosFicha.numero = '';
                  this.eventosFicha.complemento = '';

                  if (element.code === 40001) {
                    this.messageService.add({
                      severity: 'warn', key: 'messageLinnks',
                      summary: 'MSG: ',
                      detail: 'CEP Inválido!'
                    });


                  } else {
                    this.messageService.add({
                      severity: 'warn', key: 'messageLinnks',
                      summary: 'MSG: ',
                      detail: element.message
                    });
                  }
                });
              } catch (error) {
                this.eventosFicha.logradouro = '';
                this.eventosFicha.bairro = '';
                this.eventosFicha.ufId = null;
                this.eventosFicha.municipioId = null;
                this.eventosFicha.numero = '';
                this.eventosFicha.complemento = '';

                this.messageService.add({
                  severity: 'warn', key: 'messageLinnks',
                  summary: 'MSG: ',
                  detail: 'CEP Inválido!'
                });
              }
              return;
            }


          }, error => this.httpService.handleError(error, () => this.pesquisarCep()),
          () => this.httpService.done()
        );





    }

  }

  newParticipantepesquisarCep() {

    this.newParticipanteEventosFicha.cep = this.newParticipanteEventosFicha.cep.replace(/[^\d]+/g, '');

    if (this.newParticipanteEventosFicha.cep.length !== 8) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'CEP deverá conter 8 caracteres!'
      });
      return;

    } else {

      this.httpService.wait();
      this.httpService.get('/custom/service/get-endereco(' + this.newParticipanteEventosFicha.cep + ')')
        .subscribe(
          rst => {

            if (!rst.errors) {
              this.newParticipanteEventosFicha.logradouro = rst.logradouro;
              this.newParticipanteEventosFicha.bairro = rst.bairro;
              this.newParticipanteEventosFicha.ufId = rst.uf;
              this.newParticipanteEventosFicha.municipioId = rst.localidade;
              this.newParticipanteEventosFicha.numero = '';
              this.newParticipanteEventosFicha.complemento = '';
              // uf
              this.httpService.wait();
              this.httpService.get('/uf?$select=id,uf&$filter=uf eq ' + '\'' + rst.uf + '\'').subscribe(
                ref => {
                  this.newParticipanteEventosFicha.ufId = ref.value[0].id;
                  if (this.newParticipanteEventosFicha.ufId) {
                    this.newParticipanteComboMunicipio();
                    // busca cidade
                    setTimeout(() => this.newParticipantesetCidadeBusca(rst.ibge), 1000);
                    // fim busca cidade
                  }
                },
                error => this.httpService.handleError(error, () => this.newParticipantepesquisarCep()),
                () => this.httpService.done());
              // fim uf

            } else {

              rst.errors.forEach(element => {
                this.eventosFicha.logradouro = '';
                this.eventosFicha.bairro = '';
                this.eventosFicha.ufId = null;
                this.eventosFicha.municipioId = null;
                this.eventosFicha.numero = '';
                this.eventosFicha.complemento = '';

                if (element.code === 40001) {
                  this.messageService.add({
                    severity: 'warn', key: 'messageLinnks',
                    summary: 'MSG: ',
                    detail: 'CEP Inválido!'
                  });


                } else {
                  this.messageService.add({
                    severity: 'warn', key: 'messageLinnks',
                    summary: 'MSG: ',
                    detail: element.message
                  });
                }
              });
              return;
            }

          }, error => this.httpService.handleError(error, () => this.pesquisarCep()),
          () => this.httpService.done()
        );

    }

  }

  setCidadeBusca(codigoIbge) {

    if (codigoIbge) {

      this.httpService.wait();
      this.httpService.get('/municipioibge?$select=id,nome&$filter=cod eq ' + '\'' + codigoIbge + '\'')
        .subscribe(
          cid => {
            this.eventosFicha.municipioId = cid.value[0].id;
          },
          error =>
            this.httpService.handleError(error, () => this.setCidadeBusca(codigoIbge)),
          () => this.httpService.done());

    } else {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Cidade não encontrada, selecione nas opções'
      });
      return;
    }

  }

  newParticipantesetCidadeBusca(codigoIbge) {

    if (codigoIbge) {

      this.httpService.wait();
      this.httpService.get('/municipioibge?$select=id,nome&$filter=cod eq ' + '\'' + codigoIbge + '\'')
        .subscribe(
          cid => {
            this.newParticipanteEventosFicha.municipioId = cid.value[0].id;
          },
          error =>
            this.httpService.handleError(error, () => this.newParticipantesetCidadeBusca(codigoIbge)),
          () => this.httpService.done());

    } else {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Cidade não encontrada, selecione nas opções'
      });
      return;
    }

  }



  comboMunicipio() {
    this.opcoesMunicipio = [];
    this.opcoesMunicipio.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + this.eventosFicha.ufId)
      .subscribe(
        ref => {
          ref.value.forEach(element => {
            this.opcoesMunicipio.push({
              label: element.nome,
              value: element.id
            });
          });
        },
        error =>
          this.httpService.handleError(error, () => this.comboMunicipio()),
        () => this.httpService.done()
      );
  }


  oldParticipanteComboMunicipio() {
    this.oldParticipanteopcoesMunicipio = [];
    this.oldParticipanteopcoesMunicipio.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + this.oldParticipanteEventosFicha.ufId)
      .subscribe(
        ref => {
          ref.value.forEach(element => {
            this.oldParticipanteopcoesMunicipio.push({
              label: element.nome,
              value: element.id
            });
          });
        },
        error =>
          this.httpService.handleError(error, () => this.oldParticipanteComboMunicipio()),
        () => this.httpService.done()
      );
  }


  newParticipanteComboMunicipio() {
    this.newParticipanteopcoesMunicipio = [];
    this.newParticipanteopcoesMunicipio.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + this.newParticipanteEventosFicha.ufId)
      .subscribe(
        ref => {
          ref.value.forEach(element => {
            this.newParticipanteopcoesMunicipio.push({
              label: element.nome,
              value: element.id
            });
          });
        },
        error =>
          this.httpService.handleError(error, () => this.newParticipanteComboMunicipio()),
        () => this.httpService.done()
      );
  }



  novoParticipante() {
    this.limpaTela();
    const participanteTipoId = 1;
    /*switch (this.pedido.pedidoTipo.id) {
      case 1:
      case 2:
        participanteTipoId = 1;
        break;
      case 3:
      case 4:
        participanteTipoId = 2;
        break;
    }*/
    MAT_DIALOG_CONFIG_EVENTO.data = {
      participanteTipoId: participanteTipoId,
      action: 'CRIAR PARTICIPANTE'
    };
    const matDialogRef = this.matDialog.open(ParticipanteEventosDialogComponent, MAT_DIALOG_CONFIG_EVENTO);
    matDialogRef.afterClosed().subscribe(participante => {
      this.eventosFicha.cpf = participante.cpf;
      this.eventosFicha.nome = participante.nome;
      this.eventosFicha.participanteId = participante.id;
      this.carregaParticipanteContato(participante.id);
      this.carregaParticipanteEndereco(participante.id);

    });

  }

  carregaParticipanteEndereco(id: any) {
    this.httpService.wait();
    this.httpService.get('/custom/evento/get-participante-endereco(' + id + ')')
      .subscribe(listEventos => this.loadParticipanteEndereco(listEventos),
        error => this.httpService.handleError(error, () => this.carregaParticipanteEndereco(id)),
        () => this.httpService.done());

  }

  loadParticipanteEndereco(value: Array<any>) {
    value.forEach(element => {
      this.eventosFicha.cep = element.cep;
      this.eventosFicha.logradouro = element.logradouro;
      this.eventosFicha.numero = element.numero;
      this.eventosFicha.bairro = element.bairro;
      this.eventosFicha.complemento = element.complemento;
      this.eventosFicha.ufId = element.uf;
      this.buscarUf(this.eventosFicha.ufId);
      this.eventosFicha.municipioId = element.cidade;

    });
  }


  carregaParticipanteContato(id: any) {
    this.httpService.wait();
    this.httpService.get('/custom/evento/get-participante-contato(' + id + ')')
      .subscribe(listEventos => this.loadParticipanteContato(listEventos),
        error => this.httpService.handleError(error, () => this.carregaParticipanteContato(id)),
        () => this.httpService.done());
  }


  loadParticipanteContato(value: Array<any>) {
    value.forEach(element => {
      this.ddd = element.ddd;
      this.eventosFicha.telefone = element.ddd + element.telefone;
      this.eventosFicha.email = element.email;
    });
  }


  listar() {

    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
      this.checkedAll = false;
      this.opcoesEventos = [];
      this.opcoesIngressos = [];
      this.opcoesIngressosOld = [];
      this.opcoesIngressosNew = [];
      this.opcoesDepositoBancario = [];
      this.opcoesPalestra = [];
      this.opcoesVendedor = [];

      this.ingressoSelecionadoOld = 0;
      this.ingressoSelecionadoNew = 0;
      this.eventoSelecionadoOld = 0;
      this.eventoSelecionadoNew = 0;

      this.opcoesStatus = [];
      this.origemparticipante = [];
      this.listaTransferenciaInf = [];
      this.opcoesEventos = new Array();
      this.idParticipanteEventoView = new Array();
      this.idParticipanteEventoExcluirView = new Array();
      this.isTemEstorno = false;

      this.observacaoTransferencia = '';
      this.valorTaxaTransferencia = 0.00;
      this.valorCreditoTransferencia = 0.00;
      this.eventoSelecionadoNew = 0;
      this.eventoSelecionadoOld = 0;


      this.opcoesEventos.push({ label: 'Selecionar Evento', value: 0 });
      this.opcoesIngressos.push({ label: 'Selecione o Ingresso', value: 0 });
      this.opcoesPalestra.push({ label: 'Selecionar a Palestra', value: 0 });
      this.opcoesVendedor.push({ label: 'Selecione o vendedor', value: 0 });

      this.getListaEventosMes();
      this.carregarLisaParticipantes();



      this.httpService.wait();
      this.httpService.get('/custom/evento/get-eventos-participante',
        [this.dblinkedSessionService.periodo.year.value,
        this.dblinkedSessionService.periodo.month.value,
        this.dblinkedSessionService.empresa.id
        ])
        .subscribe(retorno => {
          this.opcoesEventos = [];
          this.opcoesEventos.push({ label: 'Selecionar Evento', value: 0 });
          retorno.forEach(r => {
            //this.opcoesEventos.push({     label: r.dataInicio + '-' + r.item.toUpperCase(), value: r.id });
            this.opcoesEventos.push({ label: (!isNullOrUndefined(r.dataInicio) ? r.dataInicio : 'SEM DATA') + ' - ' + r.item.toUpperCase() + " - " + r.municipio + '-' + r.uf, value: r.id });
          });
        },
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());

      //listar as palestras
      this.opcoesPalestra = [];
      this.httpService.get('/custom/polozi/listar-palestras-fichas',
        [0,
          0,
          this.dblinkedSessionService.empresa.id
        ])
        .subscribe(retorno => {
          this.opcoesPalestra = [];
          this.opcoesPalestra.push({ label: 'Selecionar a Palestra', value: 0 });

          retorno.forEach(r => {
            this.opcoesPalestra.push({ label: r.codigo + ' - ' + r.nomeseminarista + (isNullOrUndefined(r.data) ? '' : r.data), value: r.id });
          });
        },
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());

      //listar os status dos eventos
      this.httpService.get('/custom/polozi/listar-opcoes-status-evento')
        .subscribe(retorno => {
          this.opcoesStatus = [];
          retorno.forEach(r => {
            this.opcoesStatus.push({ label: r.descricao, value: r.id });
          });
        },
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());

      // listar os vendedores
      this.httpService.get('/custom/polozi/listar-vendedores', this.dblinkedSessionService.empresa.id)
        .subscribe(retorno => {
          this.opcoesVendedor = [];
          retorno.forEach(r => {
            this.opcoesVendedor.push({ label: r.nome, value: r.id });
          });
        },
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());

      //pegar a lista de depto empresa
      this.getListaComboDepartamentoEmpresa();

      setTimeout(() => {
        this.configurarToolBar();
      }, 500);

    } //Final do If

  } //Final do metodo listar


  /* @fernandostavale
    Caso palestra seja aberta, o campo atendente (input) fica oculto e o campo vendedor (dropdown) fica liberado
    Caso palestra seja fechada, o campo atendente (input) fica liberado e o campo vendedor (dropdown) fica oculto
  */
  verificaPalestraAbertaFechada() {


    if (this.eventosFicha.palestraId) {
      this.httpService.get('/palestra?$select=id,palestraAberta,codigo,palestrante/nome&$filter=id eq ' + '\'' + this.eventosFicha.palestraId + '\'').subscribe(
        ref => {
          if (ref.value[0].palestraAberta) {
            this.boolPalestraAberta = true;
          } else {
            this.boolPalestraAberta = false;
          }

        },
        error => this.httpService.handleError(error, () => this.verificaPalestraAbertaFechada()),
        () => this.httpService.done());
    }
  }

  carregarEventosTransferir() {

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-eventos-transferir',
      [this.dblinkedSessionService.empresa.id])
      .subscribe(retorno => {
        this.opcoesEventosNew = [];
        this.opcoesEventosOld = [];
        this.ingressoSelecionadoNew = 0;
        this.opcoesEventosNew.push({ label: 'Selecionar Evento', value: 0 });
        retorno.forEach(r => {
          //this.opcoesEventos.push({     label: r.dataInicio + '-' + r.item.toUpperCase(), value: r.id });
          this.opcoesEventosOld.push({ label: (!isNullOrUndefined(r.dataInicio) ? r.dataInicio : 'SEM DATA') + ' - ' + r.item.toUpperCase() + " - " + r.municipio + '-' + r.uf, value: r.id });
          this.opcoesEventosNew.push({ label: (!isNullOrUndefined(r.dataInicio) ? r.dataInicio : 'SEM DATA') + ' - ' + r.item.toUpperCase() + " - " + r.municipio + '-' + r.uf, value: r.id });
        });
      },
        error => this.httpService.handleError(error, () => this.carregarEventosTransferir()),
        () => this.httpService.done());

  }



  getInformacoesTransferir() {
    this.listaTransferenciaInf = [];
    this.modalInformacaoTransferencia = true;

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-eventos-transferir-inf ',
      [this.eventoFichaId, this.depId])
      .subscribe(retorno => {

        this.listaTransferenciaInf = [];
        retorno.forEach(e => this.listaTransferenciaInf.push(e));


      },
        error => this.httpService.handleError(error, () => this.getInformacoesTransferir()),
        () => this.httpService.done());


    this.getInformacoesTransferirParticipante();

  }

  getInformacoesTransferirParticipante() {
    this.listaTransferenciaInfParticipante = [];
    this.httpService.wait();
    this.httpService.get('/custom/evento/get-eventos-transferir-inf-participantes',
      [this.eventoFichaId, this.depId])
      .subscribe(retorno => {
        this.listaTransferenciaInfParticipante = [];
        retorno.forEach(e => { this.listaTransferenciaInfParticipante.push(e) });
      },
        error => this.httpService.handleError(error, () => this.getInformacoesTransferirParticipante()),
        () => this.httpService.done());



  }

  carregarLisaParticipantes() {
    this.toolbarService.clear();
    this.toolbarService.add('info', true, 'class-edit-button', 'info', 'Informações transferência');
    this.toolbarService.add('undo', true, 'class-edit-button', 'sync_alt', 'Transferir evento');
    this.toolbarService.add('save', true, 'class-edit-button', 'payment', 'Motivo Estorno');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar');

    this.origemparticipante = new Array();
    this.getComboStatus();




  }

  buscarUf(ufs: any) {
    this.httpService.wait();
    this.httpService.get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + ufs + '&$orderby=nome')
      .subscribe(ref => this.loadMunicipio(ref.value),
        error => this.httpService.handleError(error, () => this.buscarUf(ufs)),
        () => this.httpService.done());
  }

  oldParticipanteBuscarUf(ufs: any) {
    this.httpService.wait();
    this.httpService.get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + ufs + '&$orderby=nome')
      .subscribe(ref => this.oldParticipanteLoadMunicipio(ref.value),
        error => this.httpService.handleError(error, () => this.oldParticipanteBuscarUf(ufs)),
        () => this.httpService.done());
  }

  newParticipanteBuscarUf(ufs: any) {
    this.httpService.wait();
    this.httpService.get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + ufs + '&$orderby=nome')
      .subscribe(ref => this.newParticipanteLoadMunicipio(ref.value),
        error => this.httpService.handleError(error, () => this.newParticipanteBuscarUf(ufs)),
        () => this.httpService.done());
  }

  loadMunicipio(array: Array<MunicipioIbge>) {
    this.opcoesMunicipio = [];
    this.opcoesMunicipio.push({ label: 'Selecionar Municipio', value: null });
    array.forEach(r => {
      this.opcoesMunicipio.push({ label: r.nome, value: r.id });
    });

  }

  newParticipanteLoadMunicipio(array: Array<MunicipioIbge>) {
    this.newParticipanteopcoesMunicipio = [];
    this.newParticipanteopcoesMunicipio.push({ label: 'Selecionar Municipio', value: null });
    array.forEach(r => {
      this.newParticipanteopcoesMunicipio.push({ label: r.nome, value: r.id });

    });

  }


  oldParticipanteLoadMunicipio(array: Array<MunicipioIbge>) {
    this.oldParticipanteopcoesMunicipio = [];
    this.oldParticipanteopcoesMunicipio.push({ label: 'Selecionar Municipio', value: null });
    array.forEach(r => {
      this.oldParticipanteopcoesMunicipio.push({ label: r.nome, value: r.id });

    });

  }


  ufChanged() {
    this.httpService.wait();
    this.httpService.get('/uf?$select=id,uf&$orderby=uf').subscribe(ref => this.loadUf(ref.value),
      error => this.httpService.handleError(error, () => this.ufChanged()),
      () => this.httpService.done());
  }

  loadUf(array: Array<Uf>) {
    this.opcoesUF = [];
    this.oldParticipanteOpcoesUF = [];
    this.newParticipanteOpcoesUF = [];

    this.opcoesUF.push({ label: 'Selecione', value: 0 });
    this.oldParticipanteOpcoesUF.push({ label: 'Selecione', value: 0 });
    this.newParticipanteOpcoesUF.push({ label: 'Selecione', value: 0 });


    array.forEach(r => {
      this.opcoesUF.push({ label: r.uf, value: r.id });
      this.oldParticipanteOpcoesUF.push({ label: r.uf, value: r.id });
      this.newParticipanteOpcoesUF.push({ label: r.uf, value: r.id });

    });
  }


  carregaTipoPagamento() {
    this.opcoesTipoPagamento = [];
    this.opcoesTipoPagamento.push({ label: 'CRÉDITO À VISTA', value: 1 });
    this.opcoesTipoPagamento.push({ label: 'CRÉDITO PARCELADO', value: 2 });
    this.opcoesTipoPagamento.push({ label: 'CARTÃO DE DÉBITO', value: 3 });
    this.opcoesTipoPagamento.push({ label: 'DINHEIRO', value: 4 });
    this.opcoesTipoPagamento.push({ label: 'CHEQUE', value: 5 });
    this.opcoesTipoPagamento.push({ label: 'DEPÓSITO BANCÁRIO', value: 6 });
    this.opcoesTipoPagamento.push({ label: 'BOLETO - PAGSEGURO', value: 7 });
  }

  validaTipoPagamento(tipo: any) {
    //console.log( tipo);
    //console.log(this.opcoesFormaPagto);


    if (tipo !== null) {
      if (tipo.id !== undefined && tipo.id > 0) {

        this.httpService.wait();
        this.httpService.get('/custom/evento/listar-detal-forma-pagto',
          [tipo.id])
          .subscribe(retorno => {
            console.log(retorno);

            this.fichaPagamento.formaPagamento = new FormaPagamento();
            this.fichaPagamento.formaPagamento.tipoBaixa = new TipoBaixa();
            this.fichaPagamento.formaPagamento.id = retorno.id;

            this.fichaPagamento.formaPagamento = retorno;
            this.fichaPagamento.formaPagamento.tipoBaixa.id = retorno.tipoBaixaId;

            this.min = 1;
            this.max = retorno.maxParcelas;

            this.isCreditoAVista = false;
            this.isCreditoParcelado = false;
            this.isCartaoDebito = false;
            this.isDinheiro = false;
            this.isCheque = false;
            this.isDeposito = false;
            this.isPagSeguro = false;


            this.numeroCartao = '';
            this.dtValidade = null;
            this.ccv = '';
            this.parceladoem = '';
            this.depositoBancarioSelecionado = null;
            this.autorizacao = '';
            this.numeroDocumento = '';
            this.numeroTerminal = '';
            this.numeroEstabelecimento = '';

            if (this.fichaPagamento.formaPagamento.tipoBaixa.id === 3) {
              this.isCreditoAVista = true;
              // console.log('isCreditoAVista: ' + this.isCreditoAVista);
            } else if (this.fichaPagamento.formaPagamento.tipoBaixa.id === 4) {
              this.isCartaoDebito = true;
              //  console.log('isCartaoDebito: ' + this.isCartaoDebito);
            } else if (this.fichaPagamento.formaPagamento.tipoBaixa.id === 5) {
              this.isDinheiro = true;
              //  console.log('isDinheiro: ' + this.isDinheiro);
            } else if (this.fichaPagamento.formaPagamento.tipoBaixa.id === 6) {
              this.isCheque = true;
              // console.log('isCheque: ' + this.isCheque);
            } else if (this.fichaPagamento.formaPagamento.tipoBaixa.id === 2) {
              this.isDeposito = true;
              //  console.log('isDeposito: ' + this.isDeposito);
            } else if (this.fichaPagamento.formaPagamento.tipoBaixa.id === 12) {
              this.isPagSeguro = true;
              //   console.log('isPagSeguro: ' + this.isPagSeguro);
            } else if (this.fichaPagamento.formaPagamento.tipoBaixa.id === 13) {
              console.log('isCreditoParcelado: ' + this.isCreditoParcelado);
              this.isCreditoParcelado = true;
            }

          },
            error => this.httpService.handleError(error, () => this.validaTipoPagamento(tipo)),
            () => this.httpService.done());
      }
    }





  }

  validaAdicaoEditDependente() {


    if (isNullOrUndefined(this.eventosFichaDependente.nome) || this.eventosFichaDependente.nome.length < 2) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar o nome do dependente. Verifique!'
      });
      return true;
    }


    if (!isNullOrUndefined(this.eventosFichaDependente.cpf) && this.eventosFichaDependente.cpf !== '') {
      if (this.eventosFichaDependente.cpf.length < 11) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor preencher o cpf. Verifique!'
        });

        return true;
      }

    }


    if (this.eventosFichaDependente.ingressoId === undefined || isNullOrUndefined(this.eventosFichaDependente.ingressoId) || this.eventosFichaDependente.ingressoId < 1) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um ingresso. Verifique!'
      });
      return true;
    }


  }

  validaFormaPagamento() {
    let erro: boolean = false;

    if (isNullOrUndefined(this.fichaPagamento.valor)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar um valor. Verifique!'
      });
      erro = true;

    }

    if (isNullOrUndefined(this.fichaPagamento.formaPagamento)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um tipo de pagamento. Verifique!'
      });
      erro = true;
    }


    this.validaTipoPagamento(this.fichaPagamento.formaPagamento.tipoBaixa.id);


    if (!isNullOrUndefined(this.fichaPagamento.formaPagamento.tipoBaixa)) {
      if (this.fichaPagamento.formaPagamento.tipoBaixa.id > 0) {
        if (this.isCreditoParcelado) {
          if (this.fichaPagamento.parcela === 0 || this.fichaPagamento.parcela === null
            || this.fichaPagamento.parcela === undefined) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Parcelado Em, não pode ser vazio. Verifique!'
            });
            erro = true;
          }
        }

        if (this.isPagSeguro) {
          if (this.fichaPagamento.parcela === 0 || this.fichaPagamento.parcela === null
            || this.fichaPagamento.parcela === undefined) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Quantidade de parcelas não pode ser vazio ou 0. Verifique!'
            });
            erro = true;
          }

          if (this.fichaPagamento.dtPrimeiroVencimento === undefined || this.fichaPagamento.dtPrimeiroVencimento === null) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Data do primeiro vencimento não pode ser vazio. Verifique!'
            });
            erro = true;

          }

          if (this.fichaPagamento.parcela === undefined || this.utilService.parseDecimal(this.fichaPagamento.parcela) <= 0) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Quantidade de parcelas deve ser entre 1 e ' + this.max + '. Verifique!'
            });
            erro = true;

          }
        }
        if (this.isCreditoAVista || this.isCreditoParcelado) {
          if (this.fichaPagamento.numeroCartao === '' || this.fichaPagamento.numeroCartao === null
            || this.fichaPagamento.numeroCartao === undefined) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Número do Cartão não pode ser vazio. Verifique!'
            });
            erro = true;
          }

          if (this.fichaPagamento.ccv === '' || this.fichaPagamento.ccv === null || this.fichaPagamento.ccv === undefined) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Cód. compra não pode ser vazio. Verifique!'
            });
            erro = true;
          }



        }

        if (!isNullOrUndefined(this.fichaPagamento.formaPagamento.tipoBaixa) && this.isCartaoDebito) {
          if (this.fichaPagamento.ccv === '' || this.fichaPagamento.ccv === null || this.fichaPagamento.ccv === undefined) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Cód. compra não pode ser vazio. Verifique!'
            });
            erro = true;
          }
        }



        if (this.isDeposito) {
          if (this.fichaPagamento.empresaContaId === null || this.fichaPagamento.empresaContaId === undefined || this.fichaPagamento.empresaContaId === 0) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'A conta bancária não pode ser vazia. Verifique!'
            });
            erro = true;
          }
        }
      } else {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Deve ser selecionado um tipo de pagamento. Verifique!'
        });
        erro = true;
      }
    }


    return erro;

  }

  buscarIngressos(eventSelecionado: any) {
    this.opcoesIngressos = [];
    this.comboFormaPagto(eventSelecionado);
    this.carregaEmpresaConta(eventSelecionado);

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-eventos-ingressos-por-id(' + eventSelecionado + ')')
      .subscribe(listEventos => this.loadListaEventosIngressosPorId(listEventos),
        error => this.httpService.handleError(error, () => this.buscarIngressos(eventSelecionado)),
        () => this.httpService.done());

  }

  buscarIngressosOld(eventoIdOld: number) {

    this.httpService.get('/custom/evento/get-eventos-ingressos-por-id(' + eventoIdOld + ')')
      .subscribe(listIngressos => {
        this.opcoesIngressosOld = [];
        listIngressos.forEach(e => {
          this.opcoesIngressosOld.push({
            label: 'Valor Pacote:' +
              this.utilService.parseDecimalString(e.valorPacote) + ' ' + e.codigo, value: e.id
          });

        });

      },
        error => this.httpService.handleError(error, () => this.buscarIngressosOld(eventoIdOld)),
        () => this.httpService.done());

  }


  buscarIngressosNew(eventoIdNew: number) {

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-eventos-ingressos-por-id(' + eventoIdNew + ')')
      .subscribe(listIngressos => {
        this.opcoesIngressosNew = [];
        this.ingressoSelecionadoNew = 0;
        this.opcoesIngressosNew.push({ label: 'Selecione o Ingresso', value: 0 });
        listIngressos.forEach(e => {
          this.opcoesIngressosNew.push({
            label: 'Valor Pacote:' +
              this.utilService.parseDecimalString(e.valorPacote) + ' ' + e.codigo, value: e.id
          });
        });
      },
        error => this.httpService.handleError(error, () => this.buscarIngressosNew(eventoIdNew)),
        () => this.httpService.done());

  }

  salvarTransferirEvento() {


    if (this.validacaoTransferencia()) {
      return;
    }


    const listaTransferir = {
      eventoFichaId: this.idParticipanteEventoView[0],
      oldEventoId: this.eventoSelecionadoOld,
      newEventoId: this.eventoSelecionadoNew,
      oldEventoIngressoId: this.ingressoSelecionadoOld,
      newEventoIngressoId: this.ingressoSelecionadoNew,
      valorTaxa: this.valorTaxaTransferencia,
      valorCredito: this.valorCreditoTransferencia,
      observacao: this.observacaoTransferencia,
      usuarioId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id,
      depId: this.depId
    };

    this.httpService.post('/custom/polozi/salvar-transferir-evento', listaTransferir)
      .subscribe(result => {

        if (result[0].error === true) {

          this.listar();
          this.modalTransferencia = false;
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: result[0].message });
        } else {
          this.listar();
          this.modalTransferencia = false;
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result[0].message });

        }
      },
        error => this.httpService.handleError(error, () => this.salvarTransferirEvento()),
        () => this.httpService.done());

    this.desabilitarToolBar();


  }

  validacaoTransferencia() {

    if (isNullOrUndefined(this.eventoSelecionadoNew) || this.eventoSelecionadoNew === 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um evento!'
      });
      return true;
    }

    if (isNullOrUndefined(this.ingressoSelecionadoNew) || this.ingressoSelecionadoNew === 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar o tipo de ingresso!'
      });
      return true;
    }

    if (isNullOrUndefined(this.valorTaxaTransferencia) || this.valorTaxaTransferencia < 0.00) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'O valor da taxa deve ser maior ou igual a zero!'
      });
      return true;
    }


    if (isNullOrUndefined(this.valorCreditoTransferencia) || this.valorCreditoTransferencia < 0.00) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'O valor da taxa deve ser maior ou igual a zero!'
      });
      return true;
    }


    return false;
  }


  loadListaEventosIngressosPorId(value: Array<any>) {
    this.opcoesIngressos = [];
    this.opcoesIngressos.push({ label: 'Selecione o Ingresso', value: 0 });
    value.forEach(r => {
      this.opcoesIngressos.push({
        label: 'Valor Pacote:' +
          this.utilService.parseDecimalString(r.valorPacote) + ' ' + r.codigo, value: r.id
      });

    });

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 46) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Somente são aceitos números e pontos(".")!'
      });
      return false;
    }
    return true;
  }

  selectIngresso(event: any) {
    this.valorPacote = 0;
    this.valorPromocional = 0;

    this.httpService.wait();
    this.httpService.get('/eventoingresso?$select=id,valorPacote,valorPromocional,codigo&$orderby=id&$filter=id eq ' + event)
      .subscribe(
        ref => {
          ref.value.forEach(element => {
            this.valorPacote = element.valorPacote;
            this.valorPromocional = element.valorPromocional;
            if (element.codigo === 'BONUS') {
              this.isBonus = true;
            } else {
              this.isBonus = false;
            }

          });
        },
        error =>
          this.httpService.handleError(error, () => this.selectIngresso(event)),
        () => this.httpService.done()
      );

  }

  ngOnDestroy(): void {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  atualizaPagto() {
    const index = this.eventosFicha.fichasPagamento.findIndex(c => c.id === this.fichaPagamento.id);
    this.eventosFicha.fichasPagamento.splice(index, 1);
    this.addPagto();
    this.editFormaPagto = false;
  }


  atualizaDependente() {
    this.editDependente = false;
    const index = this.eventosFicha.eventosFichaDependente.findIndex(c => c.id === this.eventosFichaDependente.id);
    this.eventosFicha.eventosFichaDependente.splice(index, 1);
    this.addDependete();
  }

  removerPagto(event: any) {
    if (confirm('Deseja excluir esta Forma de Pagamento?')) {
      const index = this.eventosFicha.fichasPagamento.findIndex(c => c.id === event.id);
      this.eventosFicha.fichasPagamento.splice(index, 1);
      this.calculaValorTotal();
      this.editFormaPagto = false;
    }
  }

  removerDependente(event: any) {

    if (confirm('Deseja excluir este Dependente?')) {
      const index = this.eventosFicha.eventosFichaDependente.findIndex(c => c.id === event.id);
      this.eventosFicha.eventosFichaDependente.splice(index, 1);
      this.editDependente = false;
    }
  }

  addPagto() {
    if (!this.validaFormaPagamento()) {
      if (this.eventosFicha.fichasPagamento === undefined) {
        this.eventosFicha.fichasPagamento = new Array();
      }
      this.fichaPagamento.id = (this.eventosFicha.fichasPagamento === undefined ? 1 : this.eventosFicha.fichasPagamento.length + 1);
      this.fichaPagamento.descTipoPagto = this.buscarDescricaoPagto(this.fichaPagamento.tipoPagto);
      this.eventosFicha.fichasPagamento.push(this.fichaPagamento);
      this.fichaPagamento = new EventosFichaPagamento();
      this.fichaPagamento.formaPagamento = new FormaPagamento();

      this.totalFichaPagamento = this.eventosFicha.fichasPagamento.length;

      this.calculaValorTotal();

      this.isCreditoAVista = false;
      this.isCreditoParcelado = false;
      this.isCartaoDebito = false;
      this.isDinheiro = false;
      this.isCheque = false;
      this.isDeposito = false;
      this.isPagSeguro = false;

    }



  }

  addDependete() {



    if (this.validaAdicaoEditDependente()) {
      return;
    }

    this.editDependente = false;

    if (isNullOrUndefined(this.eventosFicha.eventosFichaDependente)) {
      this.eventosFicha.eventosFichaDependente = new Array();
    }

    this.totalDependentes = 0;

    this.eventosFichaDependenteTabela = new EventosFichaDependente();
    this.eventosFichaDependenteTabela.id = (isNullOrUndefined(this.eventosFicha.eventosFichaDependente) ? 1 : this.eventosFicha.eventosFichaDependente.length + 1);
    this.eventosFichaDependenteTabela.nome = this.eventosFichaDependente.nome;
    this.eventosFichaDependenteTabela.email = this.eventosFichaDependente.email;
    this.eventosFichaDependenteTabela.cpf = this.eventosFichaDependente.cpf;
    this.eventosFichaDependenteTabela.telefone = this.eventosFichaDependente.telefone;
    this.eventosFichaDependenteTabela.ingressoId = this.eventosFichaDependente.ingressoId;
    if (!isNullOrUndefined(this.eventosFichaDependente.ingressoId) && this.eventosFichaDependente.ingressoId > 0) {
      this.eventosFichaDependenteTabela.ingressoDescricao = this.retornaDescricaoIngresso(this.eventosFichaDependente.ingressoId);
    } else {
      this.eventosFichaDependenteTabela.ingressoDescricao = '';
    }



    this.eventosFicha.eventosFichaDependente.push(this.eventosFichaDependenteTabela);

    this.eventosFichaDependente.nome = '';
    this.eventosFichaDependente.telefone = '';
    this.eventosFichaDependente.cpf = '';
    this.eventosFichaDependente.email = '';
    this.eventosFichaDependente.ingressoDescricao = '';
    this.eventosFichaDependente.ingressoId = null;


    if (!isNullOrUndefined(this.eventosFicha.eventosFichaDependente)) {
      this.totalDependentes = this.eventosFicha.eventosFichaDependente.length;
    } else {
      this.totalDependentes = 0;
    }


    this.eventosFichaDependenteTabela = new EventosFichaDependente();



  }

  retornaDescricaoIngresso(ingressoId: number): string {
    let descricao: string = '';

    this.opcoesIngressos.forEach(element => {
      if (element.value === ingressoId) {
        descricao = element.label;
      }
    });

    return descricao;

  }



  calculaValorTotal() {

    let valorTotal = 0;
    let valorTotalEventoFicha = 0

    if (!isNullOrUndefined(this.eventosFicha.valorTotal)) {
      valorTotalEventoFicha = this.eventosFicha.valorTotal;
    }


    if (this.eventosFicha.fichasPagamento) {
      valorTotalEventoFicha = this.eventosFicha.valorTotal;
      this.eventosFicha.fichasPagamento.forEach(element => {
        valorTotal = this.utilService.parseDecimal(valorTotal) + this.utilService.parseDecimal(element.valor);
      });

      if (!isNullOrUndefined(valorTotalEventoFicha)) {
        this.fichaPagamento.valor = Number((this.utilService.parseDecimal(valorTotalEventoFicha) - this.utilService.parseDecimal(valorTotal))).toFixed(2);
      } else {
        this.fichaPagamento.valor = this.utilService.parseDecimal(valorTotal);
      }

      try {
        this.eventosFicha.valor = valorTotal.toString();
        this.somaPagamentos = valorTotal;
      } catch (error) {

      }


    }

  }

  private buscarDescricaoPagto(tipo: any): string {

    let retorno: string;
    if (tipo === 1) {
      retorno = 'CRÉDITO À VISTA';
    } else if (tipo === 2) {
      retorno = 'CRÉDITO PARCELADO';
    } else if (tipo === 3) {
      retorno = 'CARTÃO DE DÉBITO';
    } else if (tipo === 4) {
      retorno = 'DINHEIRO';
    } else if (tipo === 5) {
      retorno = 'CHEQUE';
    } else if (tipo === 6) {
      retorno = 'DEPÓSITO BANCÁRIO';
    } else if (tipo === 7) {
      retorno = 'BOLETO - PAGSEGURO';
    }

    return retorno;
  }

  selectTipoPagto(event: any) {

    this.editFormaPagto = true;
    this.isPodeEditar = false;
    this.fichaPagamento = new EventosFichaPagamento();
    this.fichaPagamento.formaPagamento = new FormaPagamento();
    this.fichaPagamento.formaPagamento.id = event.formaPagamento.id;

    Promise.resolve(this.validaTipoPagamento(this.fichaPagamento.formaPagamento)).then(() => {
      this.fichaPagamento.id = event.id;
      this.fichaPagamento.numeroCartao = !isNullOrUndefined(event.numeroCartao) ? event.numeroCartao : "";
      this.fichaPagamento.ccv = !isNullOrUndefined(event.ccv) ? event.ccv : "";
      this.fichaPagamento.autorizacao = !isNullOrUndefined(event.autorizacao) ? event.autorizacao : "";
      this.fichaPagamento.numeroDocumento = !isNullOrUndefined(event.numeroDocumento) ? event.numeroDocumento : "";
      this.fichaPagamento.numeroTerminal = !isNullOrUndefined(event.numeroTerminal) ? event.numeroTerminal : "";
      this.fichaPagamento.parcela = !isNullOrUndefined(event.parcela) ? event.parcela : "";
      this.fichaPagamento.valorParcela = !isNullOrUndefined(event.valorParcela) ? event.valorParcela : "";
      this.fichaPagamento.numCheque = !isNullOrUndefined(event.numCheque) ? event.numCheque : "";
      this.fichaPagamento.valor = !isNullOrUndefined(event.valor) ? event.valor : "";
      this.fichaPagamento.numeroEstabelecimento = !isNullOrUndefined(event.numeroEstabelecimento) ? event.numeroEstabelecimento : "";
      this.fichaPagamento.empresaContaId = !isNullOrUndefined(event.empresaContaId) ? event.empresaContaId : 0;

      if (!isNullOrUndefined(event.dtPrimeiroVencimento) && event.dtPrimeiroVencimento !== "") {
        try {
          this.fichaPagamento.dtPrimeiroVencimento.setDate(event.dtPrimeiroVencimento);
        } catch (error) {

          this.fichaPagamento.dtPrimeiroVencimento = event.dtPrimeiroVencimento;
        }

      } else {
        this.fichaPagamento.dtPrimeiroVencimento = null;
      }



    });


  }

  selectTipoDependente(event: any) {
    this.editDependente = true;
    this.eventosFichaDependente = event;
  }


  calcularValorParcela() {
    try {
      if (this.isPagSeguro || this.isCreditoAVista) {
        this.fichaPagamento.valorParcela = this.utilService.parseDecimal(this.utilService.parseDecimal(this.fichaPagamento.valor) / this.utilService.parseDecimal(this.fichaPagamento.parcela)).toString();
      }

    } catch (error) {

    }

  }

  change() {



  }

  comboTiposBaixa() {
    this.tiposBaixas = [];
    this.tiposBaixas.push({ label: 'Selecione', value: 0 });
    this.httpService.wait();
    this.httpService.get('/tipobaixa?$select=id,descricao&$orderby=descricao')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.tiposBaixas.push({ label: element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboTiposBaixa()),
        () => this.httpService.done());
  }

  comboFormaPagto(eventSelecionado: any) {
    this.opcoesFormaPagto = [];
    this.opcoesFormaPagto.push({ label: 'Selecione', value: 0 });
    this.httpService.get('/custom/evento/listar-forma-pagto',
      [this.dblinkedSessionService.empresa.id, eventSelecionado
      ])
      .subscribe(retorno => {

        this.opcoesFormaPagto = [];
        this.opcoesFormaPagto.push({ label: 'Selecione', value: 0 });

        retorno.forEach(element => {
          this.opcoesFormaPagto.push({ label: element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboFormaPagto(eventSelecionado)),
        () => this.httpService.done());
  }

  carregaEmpresaConta(eventSelecionado: any) {


    this.httpService.wait();
    this.httpService.get('/custom/evento/listar-empresa-conta',
      [this.dblinkedSessionService.empresa.id, eventSelecionado
      ])
      .subscribe(retorno => {
        this.opcoesDepositoBancario = [];
        this.opcoesDepositoBancario.push({ label: 'Selecione', value: 0 });

        retorno.forEach(element => {
          this.opcoesDepositoBancario.push({ label: element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.carregaEmpresaConta(eventSelecionado)),
        () => this.httpService.done());

  }

  calcularNumeroParcela() {
    if (this.fichaPagamento.parcela > this.max) {
      this.fichaPagamento.parcela = this.max;
    }
    this.fichaPagamento.valorParcela = this.utilService.parseMoneyString(this.fichaPagamento.valor / this.fichaPagamento.parcela);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  selectCheckbox(id: any, event: any) {
    this.eventosFicha.id = id;
    this.eventoFichaId = id;
    this.depId = event.depId


    // manipulando listas
    if (event.selected) {
      event.selected = true;
      // impressão de pedido
      const index = this.idParticipanteEventoView.findIndex(vw => vw === id);
      this.idParticipanteEventoView.splice(index, 1);

      this.idParticipanteEventoExcluirView.splice(this.idParticipanteEventoExcluirView.findIndex(e => e.id.toString() === event.id.toString()), 1);
    } else {

      this.idParticipanteEventoView.push(id);
      this.idParticipanteEventoExcluirView.push(event);


      event.selected = false;
    }

    this.configurarToolBar();

  }

  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;
    this.idParticipanteEventoView = new Array();
    this.idParticipanteEventoExcluirView = new Array();

    this.origemparticipante.forEach(pc => {
      if (this.checkedAll === false) {
        pc.selected = false;
        this.idParticipanteEventoView.slice(pc.id, 1);
        this.idParticipanteEventoExcluirView.slice(pc.id, 1);
      } else {
        pc.selected = true;
        this.idParticipanteEventoView.push(pc.id);
        this.idParticipanteEventoExcluirView.push(pc);
      }
    });
    setTimeout(() => {
      this.configurarToolBar();
    }, 500);
  }



  desabilitarToolBar() {
    // this.toolbarService.alterarStatus('save', true, 'Motivo Estorno', () => this.motivosEstornos());
    this.toolbarService.alterarStatus('save', true, 'Motivo Estorno', () => this.verificaSeTemEstorno(this.eventosFicha.id, this.depId));
    this.toolbarService.alterarStatus('edit', true, 'Editar Participantes Evento', () => this.EditarParticipanteEvento());
    this.toolbarService.alterarStatus('remove', true, 'Excluir Participantes Evento', () => this.ExcluirParticipanteEvento());

  }




  ExcluirParticipanteEvento() {
    if (confirm('Deseja excluir esta ficha?')) {
      if (!isNullOrUndefined(this.depId) && this.depId > 0) {
        this.eventosFicha.depId = this.depId;
      } else {
        this.eventosFicha.depId = 0;
      }

      this.httpService.wait();
      this.httpService.post('/custom/evento/deletar-evento-ficha', this.eventosFicha)
        .subscribe(result => {


          if (result.error) {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: result.message });
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result.message });
            this.listar();
            // this.toolbarService.alterarStatus('save', true, 'Motivo Estorno', () => this.motivosEstornos());
            this.toolbarService.alterarStatus('save', true, 'Motivo Estorno', () => this.verificaSeTemEstorno(this.eventosFicha.id, this.depId));
            this.toolbarService.alterarStatus('edit', true, 'Editar Participantes Evento', () => this.EditarParticipanteEvento());
            this.toolbarService.alterarStatus('remove', true, 'Excluir Participantes Evento', () => this.ExcluirParticipanteEvento());


          }
        },
          error => this.httpService.handleError(error, () => this.ExcluirParticipanteEvento()),
          () => this.httpService.done());

    }
  }

  EditarParticipanteEvento() {


    if (!isNullOrUndefined(this.activatedRoute.snapshot.data) && this.activatedRoute.snapshot.data.value !== 0) {

      this.toolbarService.clear();
      this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Criar novo');
      this.toolbarService.replace('add', false, 'class-new-button', 'SELECIONE', 'Adicionar', () => { this.novoParticipante(); });

      this.carregarEventoFichaId(this.eventoFichaId, this.idParticipanteEventoExcluirView[0].depId);
      this.tipo = '1';
      this.router.navigate(['/eventos/participantes-eventos/' + '0' + '/', this.eventoFichaId]);

      this.idParticipanteEventoView = new Array();
      this.idParticipanteEventoExcluirView = new Array();
      this.isPodeEditar = true;
      this.editFormaPagto = false;


    }


  }

  desabilitarSalvar() {
    if (!isNullOrUndefined(this.eventosFicha.statusEventoId) && this.eventosFicha.statusEventoId === 1) {
      this.isDisableSave = true;
    } else {
      this.isDisableSave = false;
    }

    return this.isDisableSave;

  }

  carregarEventoFichaId(eventoFichaId, depId) {

    this.eventosFicha = new EventosFicha();
    this.fichaPagamento = new EventosFichaPagamento();
    this.fichaPagamento.formaPagamento = new FormaPagamento();
    this.eventosFicha.fichasPagamento = new Array();
    this.eventosFicha.palestra = new Palestra();
    this.eventosFicha.id = 0;
    this.valorPacote = 0;
    this.valorPromocional = 0;


    this.isCreditoAVista = false;
    this.isCreditoParcelado = false;
    this.isCartaoDebito = false;
    this.isDinheiro = false;
    this.isCheque = false;
    this.isDeposito = false;
    this.isPagSeguro = false;
    this.eventosFicha.valorTotal = null;
    this.fichaPagamento.valor = null;

    this.fichaPagamento = new EventosFichaPagamento();
    this.fichaPagamento.formaPagamento = new FormaPagamento();
    this.fichaPagamento.formaPagamento.tipoBaixa = new TipoBaixa();
    this.somaPagamentos = 0.00;

    this.municipioId = 0;
    this.ufId = 0;

    if (!isNullOrUndefined(eventoFichaId) && eventoFichaId > 0) {
      this.isDisableSave = true;
      this.totalFichaPagamento = 0;
      this.totalDependentes = 0;

      this.httpService.wait();
      this.httpService.get('/custom/evento/get-evento-ficha-id',
        [eventoFichaId, depId])
        .subscribe(retorno => {
          console.log(retorno);
          this.opcoesDepositoBancario = [];
          this.isDeposito = false;
          retorno.forEach(element => {
            this.eventosFicha.eventoId = element.eventoId;
            this.httpService.wait();
            this.httpService.get('/custom/evento/get-eventos-ingressos-por-id(' + element.eventoId + ')')
              .subscribe(listEventos => { this.loadListaEventosIngressosPorId(listEventos); this.carregarEventoFichaDependente(element.id) },
                error => this.httpService.handleError(error, () => this.carregarEventoFichaId(eventoFichaId, this.idParticipanteEventoExcluirView[0].depId)),
                () => this.httpService.done());

            this.eventosFicha.id = eventoFichaId;
            this.eventosFicha.ingressosId = element.eventoIngressoId;
            this.eventosFicha.participanteId = element.participanteId;
            Promise.resolve(this.eventosFicha.eventoId = element.eventoId).then(() => this.comboFormaPagto(element.eventoId));
            this.eventosFicha.palestraId = element.palestraId;
            this.eventosFicha.vendedorId = element.vendedorId;
            this.eventosFicha.atendente = element.atendente;
            this.eventosFicha.observacao = element.observacao;
            this.eventosFicha.nome = element.participanteNome;
            this.eventosFicha.cpf = element.cpf;
            this.eventosFicha.complemento = element.complemnto;
            this.eventosFicha.logradouro = element.logradouro;
            this.eventosFicha.telefone = element.fone;
            this.eventosFicha.email = element.email;
            this.eventosFicha.cep = element.cep;
            this.eventosFicha.ufId = element.ufId;
            this.eventosFicha.municipioId = element.municipioIbgeId;
            this.eventosFicha.bairro = element.bairro;
            this.eventosFicha.numero = element.numero;
            this.ufId = element.ufId;
            this.eventosFicha.statusEventoId = element.eventoFichaStatusId;
            this.valorPacote = element.valorPacote;
            this.valorPromocional = element.valorPromocional;
            this.eventosFicha.parceladoem = element.parcela;
            this.eventosFicha.valorParcela = element.valorParcela;
            this.eventosFicha.eventoDadosAdicionais = element.dadosAdicionais;
            this.eventosFicha.depId = element.depId;
            this.eventosFicha.cnpj = element.cnpj;
            if (this.eventosFicha.dataVenda !== null) {
              try {
                this.eventosFicha.dataVenda.setDate(element.dataVenda);
              } catch (error) {
                this.eventosFicha.dataVenda = element.dataVenda;
              }
            } else {
              this.eventosFicha.dataVenda = null;
            }
            this.eventosFicha.substituirBoletoAnterior = false;

            this.buscarUf(element.ufId);
            this.municipioId = element.municipioIbgeId;
            this.buscarIngressos(element.eventoId);

            this.eventosFicha.palestraId = element.palestraId;
            Promise.resolve(this.carregarEventoFichaPagamento(element.id)).then(() => this.totalFichaPagamento = this.eventosFicha.fichasPagamento.length);


            this.verificaPalestraAbertaFechada();
            this.isPodeEditar = element.isPodeEditar;
            this.eventosFicha.novoEventoFicha = false;
            this.eventosFicha.valorTotal = element.valorTotal;
            this.eventosFicha.deptoEmpresaId = element.deptoEmpresaId;


          });
        },
          error => this.httpService.handleError(error, () => ''),
          () => this.httpService.done());

      //this.eventosFicha.novoEventoFicha = false;


      // if (this.eventosFicha.statusEventoId === 1) {
      //   this.toolbarService.replace('add', false, 'class-new-button', 'SELECIONE', 'Selecionar Cliente', () => this.novoParticipante());
      // } else {
      //   this.toolbarService.replace('add', true, 'class-new-button', 'SELECIONE', 'Selecionar Cliente', () => this.novoParticipante());
      // }


    }
  }


  carregarEventoFichaDependente(eventoFichaId: number) {

    this.eventosFicha.eventosFichaDependente = new Array();

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-evento-ficha-dependente-id',
      [eventoFichaId])
      .subscribe(
        retorno => {
          this.totalDependentes = 0;
          retorno.forEach(element => {

            this.eventosFichaDependenteTabela = new EventosFichaDependente();

            this.eventosFichaDependenteTabela.id = (isNullOrUndefined(this.eventosFicha.eventosFichaDependente) ? 1 : this.eventosFicha.eventosFichaDependente.length + 1);
            this.eventosFichaDependenteTabela.nome = element.nome;
            this.eventosFichaDependenteTabela.email = element.email;
            this.eventosFichaDependenteTabela.telefone = element.telefone;
            this.eventosFichaDependenteTabela.cpf = element.cpf;
            this.eventosFichaDependenteTabela.eventosFicha = this.eventosFicha;
            this.eventosFichaDependenteTabela.ingressoId = element.eventoIngressoId;
            if (!isNullOrUndefined(element.eventoIngressoId) && element.eventoIngressoId > 0) {
              this.eventosFichaDependenteTabela.ingressoDescricao = element.eventoIngressoDescricao;
            } else {
              this.eventosFichaDependenteTabela.ingressoDescricao = element.eventoIngressoDescricao;
            }

            this.eventosFicha.eventosFichaDependente.push(this.eventosFichaDependenteTabela);

            if (!isNullOrUndefined(this.eventosFicha.eventosFichaDependente)) {
              this.totalDependentes = this.eventosFicha.eventosFichaDependente.length;
            } else {
              this.totalDependentes = 0;
            }

          });
        },
        error => this.httpService.handleError(error, () => this.carregarEventoFichaDependente(eventoFichaId)),
        () => this.httpService.done());

  }


  carregarEventoFichaPagamento(eventoFichaId: number) {

    //new EventosFichaPagamento();
    //this.eventosFicha.fichasPagamento = new Array();
    //this.fichaPagamento               = new EventosFichaPagamento();

    this.eventosFicha.fichasPagamento = new Array<any>();

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-evento-ficha-pagamento-id',
      [eventoFichaId])
      .subscribe(retorno => {
        this.totalFichaPagamento = 0;

        retorno.forEach(element => {
          this.fichaPagamentoTabela = new EventosFichaPagamento();
          this.fichaPagamentoTabela.id = (isNullOrUndefined(this.eventosFicha.fichasPagamento) ? 1 : this.eventosFicha.fichasPagamento.length + 1);
          this.fichaPagamentoTabela.descTipoPagto = element.formaPagamentoDescricao;
          this.fichaPagamentoTabela.formaPagamento = new FormaPagamento();

          this.fichaPagamentoTabela.empresaContaId = !isNullOrUndefined(element.empresaContaId) ? element.empresaContaId : 0;
          this.fichaPagamentoTabela.formaPagamento.descricao = !isNullOrUndefined(element.formaPagamentoDescricao) ? element.formaPagamentoDescricao : null;
          this.fichaPagamentoTabela.formaPagamento.id = !isNullOrUndefined(element.formaPagamentoId) ? element.formaPagamentoId : 0;
          this.fichaPagamentoTabela.autorizacao = !isNullOrUndefined(element.autorizacao) ? element.autorizacao : null;
          this.fichaPagamentoTabela.numeroDocumento = !isNullOrUndefined(element.numeroDocumento) ? element.numeroDocumento : null;
          this.fichaPagamentoTabela.numeroEstabelecimento = !isNullOrUndefined(element.numeroEstabelecimento) ? element.numeroEstabelecimento : null;
          this.fichaPagamentoTabela.numeroTerminal = !isNullOrUndefined(element.numeroTerminal) ? element.numeroTerminal : null;
          this.fichaPagamentoTabela.valor = !isNullOrUndefined(element.valor) ? element.valor : 0;
          this.fichaPagamentoTabela.numCheque = !isNullOrUndefined(element.numCheque) ? element.numCheque : null;
          this.fichaPagamentoTabela.parcela = !isNullOrUndefined(element.parcela) ? element.parcela : 0;
          this.fichaPagamentoTabela.valorParcela = !isNullOrUndefined(element.valorParcela) ? element.valorParcela : 0;
          this.fichaPagamentoTabela.ccv = !isNullOrUndefined(element.ccv) ? element.ccv : null;
          this.fichaPagamentoTabela.numeroCartao = !isNullOrUndefined(element.numero_cartao) ? element.numero_cartao : null;

          if (!isNullOrUndefined(element.dataPrimeiroVencimento) && element.dataPrimeiroVencimento !== "") {
            try {
              //this.fichaPagamentoTabela.dtPrimeiroVencimento.setDate(element.dataPrimeiroVencimento);
              this.fichaPagamentoTabela.dtPrimeiroVencimento = element.dataPrimeiroVencimento;
            } catch (error) {
              this.fichaPagamentoTabela.dtPrimeiroVencimento = element.dataPrimeiroVencimento;
            }
          } else {
            this.fichaPagamentoTabela.dtPrimeiroVencimento = null;
          }



          this.fichaPagamentoTabela.tipoBaixaId = !isNullOrUndefined(element.tipoBaixaId) ? element.tipoBaixaId : 0;

          this.fichaPagamento.empresaContaId = !isNullOrUndefined(element.empresaContaId) ? element.empresaContaId : 0;
          this.fichaPagamento.autorizacao = !isNullOrUndefined(element.autorizacao) ? element.autorizacao : null;
          this.fichaPagamento.numeroDocumento = !isNullOrUndefined(element.numeroDocumento) ? element.numeroDocumento : null;
          this.fichaPagamento.numeroTerminal = !isNullOrUndefined(element.numeroTerminal) ? element.numeroTerminal : null;
          this.fichaPagamento.numeroEstabelecimento = !isNullOrUndefined(element.numeroEstabelecimento) ? element.numeroEstabelecimento : null;
          this.fichaPagamento.tipoBaixaId = !isNullOrUndefined(element.tipoBaixaId) ? element.tipoBaixaId : 0;

          this.fichaPagamento.formaPagamento.descricao = !isNullOrUndefined(element.formaPagamentoDescricao) ? element.formaPagamentoDescricao : null;
          this.fichaPagamento.formaPagamento.id = !isNullOrUndefined(element.tipoBaixaId) ? element.formaPagamentoId : 0;
          this.fichaPagamento.valor = !isNullOrUndefined(element.valor) ? element.valor : 0;
          this.fichaPagamento.numCheque = !isNullOrUndefined(element.numCheque) ? element.numCheque : null;
          this.fichaPagamento.parcela = !isNullOrUndefined(element.parcela) ? element.parcela : null;
          this.fichaPagamento.valorParcela = !isNullOrUndefined(element.valorParcela) ? element.valorParcela : 0;
          this.fichaPagamento.ccv = !isNullOrUndefined(element.ccv) ? element.ccv : null;
          this.fichaPagamento.numeroCartao = !isNullOrUndefined(element.numero_cartao) ? element.numero_cartao : null;

          if (!isNullOrUndefined(element.dataPrimeiroVencimento) && element.dataPrimeiroVencimento !== "") {
            try {
              this.fichaPagamento.dtPrimeiroVencimento.setDate(element.dataPrimeiroVencimento);

            } catch (error) {
              this.fichaPagamento.dtPrimeiroVencimento = element.dataPrimeiroVencimentoEn;
            }

          } else {
            this.fichaPagamento.dtPrimeiroVencimento = null;
          }


          this.fichaPagamento.formaPagamento.tipoBaixaId = !isNullOrUndefined(element.tipoBaixaId) ? element.tipoBaixaId : 0;


          this.eventosFicha.fichasPagamento.push(this.fichaPagamentoTabela);

          this.calculaValorTotal();
          if (!isNullOrUndefined(this.eventosFicha.fichasPagamento)) {
            this.totalFichaPagamento = this.eventosFicha.fichasPagamento.length;
          } else {
            this.totalFichaPagamento = 0;
          }

        });
      },
        error => this.httpService.handleError(error, () => this.carregarEventoFichaPagamento(eventoFichaId)),
        () => this.httpService.done());


  }

  validarCpf(cpf: any) {

    try {
      if ((cpf !== undefined || cpf !== null) && cpf.length > 0) {
        this.eventosFicha.cnpj = null;
      }
    } catch (error) {

    }

    if (!isNullOrUndefined(cpf)) {
      //cpf = cpf.replace(/./g,'');
      //cpf = cpf.replace(/-/g,'');
      if (!Util.isCpf(cpf.toString().replace(/[^\d]+/g, ''))) {

        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'CPF Inválido!' });
      } else {

      }
    }

  }


  validarCnpj(cnpj: string) {

    try {
      if ((cnpj !== undefined || cnpj !== null) && cnpj.length > 0) {
        this.eventosFicha.cpf = null;
      }
    } catch (error) {

    }

    if (!isNullOrUndefined(cnpj)) {
      //cnpj = cnpj.replace(/./g,'');
      //cnpj = cnpj.replace(/-/g,'');

      if (!Util.isCnpj(cnpj.toString().replace(/[^\d]+/g, ''))) {

        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'CNPJ Inválido!' });
      } else {

      }
    }

  }


  transferirEvento() {
    this.modalTransferencia = true;
    this.ingressoSelecionadoOld = 0;
    this.ingressoSelecionadoNew = 0;
    this.eventoSelecionadoOld = 0;
    this.eventoSelecionadoNew = 0;
    this.valorTaxaTransferencia = 0.00;
    this.valorCreditoTransferencia = 0.00;
    this.selectedIndex = 0;

    this.oldParticipanteEventosFicha = new EventosFicha();
    this.newParticipanteEventosFicha = new EventosFicha();

    Promise.resolve(this.carregarEventosTransferir()).then(() => this.carregarInformacaoTransferir(this.idParticipanteEventoView[0], this.idParticipanteEventoExcluirView[0].depId));


  }

  carregarInformacaoTransferir(eventoFichaId: number, depId: number) {

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-evento-ficha-id',
      [eventoFichaId, depId])
      .subscribe(retorno => {
        this.eventoSelecionadoOld = retorno[0].eventoId;
        this.buscarIngressosOld(retorno[0].eventoId);
        this.ingressoSelecionadoOld = retorno[0].eventoIngressoId;

      },
        error => this.httpService.handleError(error, () => this.carregarInformacaoTransferir(eventoFichaId, depId)),
        () => this.httpService.done());



  }


  motivosEstornos(value: any) {
    console.log(value);

    this.opcoesInstituicoesBancarias = [];
    this.opcoesFormaPagamentoEstorno = [];

    this.bancoConta = null;
    this.agenciaBanco = null;
    this.valorMaximoEstorno = 0.00;

    this.motivoEstornoSelecionado = 0;
    this.formaPagamentoEstornoSelecionado = 0;
    this.instituicoesBancariaSelecionada = 0;
    this.modalMotivosEstornos = true;
    this.modalTransferencia = false;
    this.observacaoEstorno = '';
    this.valorEstorno = 0.00;


    try {
      this.valorMaximoEstorno = this.origemparticipante.find(e => e.id === value.id).somaParcelas.toString().replace(".", "").replace(",", ".");
    } catch (error) {

    }


    this.httpService.wait();
    this.httpService.get('/custom/polozi/motivo-estorno')
      .subscribe(ref => this.loadMotivosEstornos(ref),

        error => this.httpService.handleError(error, () => this.motivosEstornos(value)),
        () => this.httpService.done());

    this.comboInstituicoesBancarias();
    this.comboFormaPagamentoEstorno();

  }
  loadMotivosEstornos(value: Array<MotivoEstorno>) {

    this.opcoesMotivosEstornos = [];
    this.opcoesMotivosEstornos.push({ label: 'Selecione o Motivo de Estorno', value: 0 });
    value.forEach(element => {
      this.opcoesMotivosEstornos.push({
        label: element.motivos,
        value: element.id
      });
    });
  }



  salvarMotivosEstornos() {

    if (this.validacaoEstorno()) {
      return;
    }

    const listasEnviar = {
      motivosId: this.motivoEstornoSelecionado,
      eventosFichaId: this.eventosFicha.id,
      usuarioId: this.sessionService.loggedUser.id,
      observacaoEstorno: this.observacaoEstorno,
      valorEstorno: this.valorEstorno,
      dependenteId: this.depId,
      adicionarMotivoEstorno: true,
      instituicaoFinanceiraId: this.instituicoesBancariaSelecionada,
      bancoConta: this.bancoConta,
      agenciaBanco: this.agenciaBanco,
      empresaId: this.dblinkedSessionService.empresa.id,
      estornoFormaPagamentoId: this.formaPagamentoEstornoSelecionado
    };

    this.httpService.post('/custom/polozi/salvar-motivo-estorno', listasEnviar)
      .subscribe(result => {

        if (result[0].error === true) {

          this.listar();
          this.modalMotivosEstornos = false;
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: result[0].message });
        } else {
          this.listar();
          this.modalMotivosEstornos = false;
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result[0].message });

        }
      },
        error => this.httpService.handleError(error, () => this.salvarMotivosEstornos()),
        () => this.httpService.done());

    this.desabilitarToolBar();



  }

  validacaoEstorno() {
    if (isNullOrUndefined(this.motivoEstornoSelecionado) || this.motivoEstornoSelecionado < 1) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Selecione Motivo de Estorno para Salvar!'
      });
      return true;
    }

    if (isNullOrUndefined(this.formaPagamentoEstornoSelecionado) || this.formaPagamentoEstornoSelecionado < 1) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Selecione uma forma de pagamento!'
      });
      return true;
    }

    if (!isNullOrUndefined(this.formaPagamentoEstornoSelecionado) && this.formaPagamentoEstornoSelecionado === 2) {
      if (isNullOrUndefined(this.instituicoesBancariaSelecionada) || this.instituicoesBancariaSelecionada === 0) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Favor selecionar um banco!'
        });
        return true;
      }

      if (isNullOrUndefined(this.agenciaBanco) || this.agenciaBanco === '') {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Favor digitar o número da agência bancária!'
        });
        return true;
      } else {
        if (!this.validarAgenciaBancaria(this.agenciaBanco)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG',
            detail: 'Favor digitar apenas os números da agência!'
          });
          return true;
        }


      }

      if (isNullOrUndefined(this.bancoConta) || this.bancoConta === '') {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Favor digitar o número da Conta!'
        });
        return true;
      } else {

        if (!this.validarContaBancaria(this.bancoConta)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG',
            detail: 'Favor digitar apenas número da Conta!'
          });
          return true;

        }

      }


    }

    if (this.valorEstorno === null || this.valorEstorno === undefined || this.valorEstorno <= 0.00) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'O valor do estorno deve ser maior que 0.00!'
      });
      return true;
    }

    console.log(this.valorEstorno);
    console.log(this.valorMaximoEstorno);

    if (this.valorEstorno > this.valorMaximoEstorno) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'O valor do estorno é maior que o valor pago!'
      });
      return true;
    }


    return false;
  }

  verificaSeTemEstorno(eventoFichaId: number, dependenteId: number): boolean {

    this.isTemEstorno = false;
    this.httpService.get('/custom/polozi/verificar-motivo-estorno-evento-ficha(' + eventoFichaId + ',' + dependenteId + ')')
      .subscribe(result => {

        if (result[0].error === true) {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result[0].message });
        } else {
          if (result[0].motivoEstornoEventoFicha) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG',
              detail: 'Este participante já tem um motivo de estorno!'
            });

            return;
          } else {
            this.motivosEstornos(this.eventosFicha);
          };

        }
      },
        error => this.httpService.handleError(error, () => this.verificaSeTemEstorno(eventoFichaId, dependenteId)),
        () => this.httpService.done());

    return this.isTemEstorno;

  }

  enviarEmail() {
    alert(this.eventoFichaId)
  }

  preenchervalor() {
    if (!isNullOrUndefined(this.eventosFicha.valorTotal)) {
      this.fichaPagamento.valor = this.utilService.parseDecimal(this.eventosFicha.valorTotal);
    } else {
      this.fichaPagamento.valor = 0.00;
    }
  }


  tabChange(event: any) {
    switch (event.index) {
      case 1:

        this.carregarInformacaoTransferirParticipante(this.eventoFichaId, this.depId)
        break;

      default:
        break;
    }
  }

  carregarInformacaoTransferirParticipante(eventoFichaId: number, depId: number) {
    this.oldParticipanteEventosFicha = new EventosFicha();
    this.newParticipanteEventosFicha = new EventosFicha();

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-evento-transferencia-pessoa-id',
      [eventoFichaId, depId])
      .subscribe(retorno => {
        this.oldParticipanteEventosFicha = new EventosFicha();
        this.newParticipanteEventosFicha = new EventosFicha();

        this.oldParticipanteEventosFicha.nome = retorno[0].oldParticipanteNome;
        this.oldParticipanteEventosFicha.cpf = retorno[0].oldParticipanteCpf;
        this.oldParticipanteEventosFicha.email = retorno[0].oldParticipanteEmail;
        this.oldParticipanteEventosFicha.telefone = retorno[0].oldParticipanteFone;
        this.oldParticipanteEventosFicha.cep = retorno[0].oldParticipanteCep;
        this.oldParticipanteEventosFicha.logradouro = retorno[0].oldParticipanteLogradouro;
        this.oldParticipanteEventosFicha.numero = retorno[0].oldParticipanteNumero;
        this.oldParticipanteEventosFicha.bairro = retorno[0].oldParticipanteBairro;
        this.oldParticipanteEventosFicha.complemento = retorno[0].oldParticipanteComplemento;
        this.oldParticipanteEventosFicha.ufId = retorno[0].oldParticipanteUfId
        Promise.resolve(this.oldParticipanteBuscarUf(retorno[0].oldParticipanteUfId)).then(() => { this.oldParticipanteEventosFicha.municipioId = retorno[0].oldParticipanteMunicipioIbgeId });


      },
        error => this.httpService.handleError(error, () => this.carregarInformacaoTransferirParticipante(eventoFichaId, depId)),
        () => this.httpService.done());



  }

  validacaoTransferenciaParticipante() {

    if (isNullOrUndefined(this.newParticipanteEventosFicha.nome) || this.newParticipanteEventosFicha.nome.trim().length < 3) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar o nome do novo participante'
      });
      return true;
    }

    if (isNullOrUndefined(this.newParticipanteEventosFicha.cpf) || this.newParticipanteEventosFicha.cpf.trim().length < 9) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar o cpf do novo participante'
      });
      return true;
    }


    if (isNullOrUndefined(this.newParticipanteEventosFicha.email)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar o email do novo participante'
      });
      return true;
    }

    if (isNullOrUndefined(this.newParticipanteEventosFicha.telefone) || this.newParticipanteEventosFicha.telefone.trim().length < 10) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar o telefone do novo participante'
      });
      return true;
    }


    if (isNullOrUndefined(this.newParticipanteEventosFicha.cep) || this.newParticipanteEventosFicha.cep.trim().length < 8) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar o cep do novo participante'
      });
      return true;
    }

    if (isNullOrUndefined(this.newParticipanteEventosFicha.logradouro) || this.newParticipanteEventosFicha.logradouro.trim().length < 1) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar a rua do novo participante'
      });
      return true;
    }


    if (isNullOrUndefined(this.newParticipanteEventosFicha.numero) || this.newParticipanteEventosFicha.numero.trim().length < 1) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar o numero da rua do novo participante'
      });
      return true;
    }

    if (isNullOrUndefined(this.newParticipanteEventosFicha.bairro) || this.newParticipanteEventosFicha.bairro.trim().length < 1) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar o bairro do novo participante'
      });
      return true;
    }

    if (isNullOrUndefined(this.newParticipanteEventosFicha.ufId)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar a UF do novo participante'
      });
      return true;
    }

    if (isNullOrUndefined(this.newParticipanteEventosFicha.municipioId)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar o Município do novo participante'
      });
      return true;
    }


    return false;
  }

  salvarTransferirParticipante() {

    if (this.validacaoTransferenciaParticipante()) {
      return;
    }


    let dados = {
      eventoFichaId: this.idParticipanteEventoView[0],
      oldParticipanteEventosFicha: this.oldParticipanteEventosFicha,
      newParticipanteEventosFicha: this.newParticipanteEventosFicha,
      usuarioId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id,
      depId: this.depId
    };

    this.httpService.wait();
    this.httpService.post('/custom/evento/salvar-evento-transferencia-participante', dados)
      .subscribe(result => {


        if (result.error) {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: result.message });
          this.eventosFicha.novoEventoFicha = true;
          this.eventosFicha.substituirBoletoAnterior = false;
          this.isPodeEditar = false;
          this.modalTransferencia = false;
          this.editFormaPagto = false;
        } else {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result.message });

          this.limpaTela();
          this.router.navigate(['/eventos/participantes-eventos/1/0']);

          this.listar();
          this.eventosFicha.novoEventoFicha = true;
          this.eventosFicha.substituirBoletoAnterior = false;
          this.isPodeEditar = false;
          this.modalTransferencia = false;
        }
        //this.toolbarService.alterarStatus('edit', true, 'Editar Participantes Evento', () => this.EditarParticipanteEvento() );
        //this.toolbarService.alterarStatus('remove', true, 'Excluir Participantes Evento', () => this.ExcluirParticipanteEvento() );

      },
        error => this.httpService.handleError(error, () => this.salvarTransferirParticipante()),
        () => this.httpService.done());

  }



  configurarToolBar() {
    try {
      this.toolbarService.clear();
      this.activatedRoute.params.subscribe(params => {

        if (params['tipo'] === '0') {
          this.editFormaPagto = false;
          Promise.resolve(this.toolbarService.clear()).then(e => {
            this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Criar novo');
            this.toolbarService.replace('add', false, 'class-new-button', 'SELECIONE', 'Adicionar', () => { this.novoParticipante(); });

          });

        } else if (params['tipo'] === '1') {


          Promise.resolve(this.toolbarService.clear()).then(e => {

            this.toolbarService.add('search', true, 'class-edit-button', 'search', 'Pesquisar Nome');
            this.toolbarService.add('info', true, 'class-edit-button', 'info', 'Informações transferência');
            this.toolbarService.add('undo', true, 'class-edit-button', 'sync_alt', 'Transferir evento');
            this.toolbarService.add('save', true, 'class-edit-button', 'payment', 'Motivo Estorno');
            this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
            this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar');

            if (this.idParticipanteEventoExcluirView.length === 0) {

              this.toolbarService.alterarStatus('search', true, 'Pesquisar Nome', () => this.pesquisarNome());

              this.toolbarService.alterarStatus('save', true, 'Motivo Estorno', () => this.motivosEstornos(this.eventosFicha));
              //this.toolbarService.alterarStatus('undo', false, 'Transferir evento ficha', () => this.transferirEvento());
              this.toolbarService.alterarStatus('info', true, 'Informações transferência', () => this.getInformacoesTransferir());
              this.toolbarService.alterarStatus('undo', true, 'Transferir evento ficha', () => this.transferirEvento());
              this.toolbarService.alterarStatus('save', true, 'Motivo Estorno', () => this.verificaSeTemEstorno(this.eventosFicha.id, this.depId));
              this.toolbarService.alterarStatus('edit', true, 'Editar Participantes Evento', () => this.EditarParticipanteEvento());
              this.toolbarService.alterarStatus('remove', true, 'Excluir Participantes Evento', () => this.ExcluirParticipanteEvento());

            } else if (this.idParticipanteEventoExcluirView.length === 1) {

              this.toolbarService.alterarStatus('save', false, 'Motivo Estorno', () => this.motivosEstornos(this.eventosFicha));
              //this.toolbarService.alterarStatus('undo', false, 'Transferir evento ficha', () => this.transferirEvento());
              this.toolbarService.alterarStatus('info', false, 'Informações transferência', () => this.getInformacoesTransferir());
              this.toolbarService.alterarStatus('undo', false, 'Transferir evento ficha', () => this.transferirEvento());
              this.toolbarService.alterarStatus('save', false, 'Motivo Estorno', () => this.verificaSeTemEstorno(this.eventosFicha.id, this.depId));
              this.toolbarService.alterarStatus('edit', false, 'Editar Participantes Evento', () => this.EditarParticipanteEvento());
              this.toolbarService.alterarStatus('remove', false, 'Excluir Participantes Evento', () => this.ExcluirParticipanteEvento());

            } else if (this.idParticipanteEventoExcluirView.length > 1) {

              // this.toolbarService.alterarStatus('save', true, 'Motivo Estorno', () => this.motivosEstornos());
              this.toolbarService.alterarStatus('info', true, 'Informações transferência', () => this.getInformacoesTransferir());
              this.toolbarService.alterarStatus('undo', true, 'Transferir evento ficha', () => this.verificaSeTemEstorno(this.eventosFicha.id, this.depId));
              this.toolbarService.alterarStatus('save', true, 'Motivo Estorno', () => this.verificaSeTemEstorno(this.eventosFicha.id, this.depId));
              this.toolbarService.alterarStatus('edit', true, 'Editar Participantes Evento', () => this.EditarParticipanteEvento());
              this.toolbarService.alterarStatus('remove', false, 'Excluir Participantes Evento', () => this.ExcluirParticipanteEvento());

            }



          });
          //this.toolbarService.clear();
        }
      });


    } catch (error) {

      //this.toolbarService.replace('save', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());
      //this.toolbarService.replace('save', true, 'Motivo Estorno', () => this.motivosEstornos());

    }

  }



  comboInstituicoesBancarias() {
    this.opcoesInstituicoesBancarias = [];
    this.opcoesInstituicoesBancarias.push({ label: 'Selecione o Banco', value: 0 });
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-combo-instituicoes-bancarias')
      .subscribe(
        retorno => {

          this.opcoesInstituicoesBancarias = [];
          this.opcoesInstituicoesBancarias.push({ label: 'Selecione o Banco', value: 0 });
          retorno.forEach(element => { this.opcoesInstituicoesBancarias.push({ label: element.codigo + ' - ' + element.descricao, value: element.id }) });
        },
        error =>
          this.httpService.handleError(error, () => this.comboInstituicoesBancarias()),
        () => this.httpService.done()
      );

  }


  comboFormaPagamentoEstorno() {
    this.opcoesFormaPagamentoEstorno = [];
    this.opcoesFormaPagamentoEstorno.push({ label: 'Selecione o Banco', value: 0 });
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-combo-estorno-forma-pagamento')
      .subscribe(
        retorno => {

          this.opcoesFormaPagamentoEstorno = [];
          this.opcoesFormaPagamentoEstorno.push({ label: 'Selecione uma forma de estorno', value: 0 });
          retorno.forEach(element => { this.opcoesFormaPagamentoEstorno.push({ label: element.descricao, value: element.id }) });
        },
        error =>
          this.httpService.handleError(error, () => this.comboFormaPagamentoEstorno()),
        () => this.httpService.done()
      );

  }


  validarAgenciaBancaria(texto: string) {
    let regex = new RegExp('[0-9]{1,3}-?([0-9]|[Xx]{1})');
    return regex.test(texto);
  }

  validarContaBancaria(texto: string) {
    let regex = new RegExp('[0-9]{3,4}-?([0-9]|[Xx]{1})');
    return regex.test(texto);
  }


  getComboStatus() {

    this.httpService.wait();
    this.httpService.get('/eventofichastatus?$select=id,descricao&$orderby=descricao')
      .subscribe(ref => {
        console.log(ref);
        this.opcoesStatusCombo = [];
        this.opcoesStatusCombo.push({ label: '', value: '' });
        ref.value.forEach(e => {
          if (e.id !== 4) {
            this.opcoesStatusCombo.push({ label: e.descricao, value: e.descricao });
          }
        })
      },
        error => this.httpService.handleError(error, () => this.getComboStatus()),
        () => this.httpService.done());

  }

  transformDias(date1: any, date2?: any): any {
    const daydiff = moment(date1).diff(moment(date2), "days");

    return daydiff;
  }

  getVerificarSePodeEditar() {
    this.isPodeEditarBoletoDataAnterior = false;
    var valorBoleto: number = 0.00;
    var numeroParcelaBoleto: number = 0;
    var dataPrimeiroVencimento = new Date();

    if (!isNullOrUndefined(this.eventosFicha) && this.eventosFicha.id > 0) {
      if (!isNullOrUndefined(this.eventosFicha.fichasPagamento)) {

        this.eventosFicha.fichasPagamento.forEach(element => {

          if (!isNullOrUndefined(element.formaPagamento) && (element.formaPagamento.id === 83 || element.formaPagamento.id === 82)) {
            //var dataPrimeiroVencimento = new Date();

            valorBoleto = element.valor;
            numeroParcelaBoleto = element.parcela;

            try {
              let ano = element.dtPrimeiroVencimento.getFullYear();
              let mes = element.dtPrimeiroVencimento.getMonth();
              let dia = element.dtPrimeiroVencimento.getDate();

              dataPrimeiroVencimento.setFullYear(ano);
              dataPrimeiroVencimento.setMonth(mes);
              dataPrimeiroVencimento.setDate(dia);
            } catch (error) {

              //let dia = element.dtPrimeiroVencimento.getDate();
              let dia = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(0, element.dtPrimeiroVencimento.toString().indexOf('/')));
              //let mes = element.dtPrimeiroVencimento.getMonth() ;
              let mes = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(element.dtPrimeiroVencimento.toString().indexOf('/') + 1, element.dtPrimeiroVencimento.toString().lastIndexOf('/'))) - 1;
              //let ano = element.dtPrimeiroVencimento.getFullYear();
              let ano = Number.parseInt(element.dtPrimeiroVencimento.toString().substring(element.dtPrimeiroVencimento.toString().lastIndexOf('/') + 1, element.dtPrimeiroVencimento.toString().length));
              dataPrimeiroVencimento.setFullYear(ano);
              dataPrimeiroVencimento.setMonth(mes);
              dataPrimeiroVencimento.setDate(dia);
            }

          }




        })

        this.getVerificarSePodeEditarEventoFichaId(this.eventosFicha.id, valorBoleto, dataPrimeiroVencimento, numeroParcelaBoleto);

      }



    }



  }


  //Verifica se tem um boleto igual caso tenha pode editar o registro com data anterior.
  getVerificarSePodeEditarEventoFichaId(eventoFichaId: number, valor: number, dataPrimeiroVencimento: any, numeroParcela: number) {
    this.isPodeEditarBoletoDataAnterior = true;
    this.httpService.wait();
    this.httpService.get('/custom/evento/get-evento-ficha-boleto-emitido-valido',
      [eventoFichaId, valor, dataPrimeiroVencimento, numeroParcela])
      .subscribe(
        ref => {
          this.isPodeEditarBoletoDataAnterior = ref;
        },
        error => this.httpService.handleError(error, () => this.getVerificarSePodeEditarEventoFichaId(eventoFichaId, valor, dataPrimeiroVencimento, numeroParcela)),
        () => this.httpService.done()
      );



  }

  getListaComboDepartamentoEmpresa() {

    this.httpService.get('/custom/evento/get-lista-depto-por-empresa(' + [this.dblinkedSessionService.empresa.id] + ')')
      .subscribe(
        ref => {
          console.log(ref);
          this.opcoesComboDeptoEmpresa = [];
          ref.forEach(element => {
            this.opcoesComboDeptoEmpresa.push({ value: element.id, label: element.descricao });
          });
        },
        error => this.httpService.handleError(error, () => this.getListaComboDepartamentoEmpresa()),
        () => this.httpService.done()
      );





  }


  onFilter(event: any) {


  }


  pesquisarNome() {
    this.modalPesquisarNome = true;
  }

  filtrar(nome: string) {

    // [this.dblinkedSessionService.periodo.year.value,
    //   this.dblinkedSessionService.periodo.month.value,
    //   this.dblinkedSessionService.empresa.id])

    if (nome !== null || nome !== undefined) {
      if (nome.trim().length === 0) {
        nome = "  ";
      }

    }

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-lista-participante-evento',
      [this.dblinkedSessionService.periodo.year.value,
      this.dblinkedSessionService.periodo.month.value,
      this.dblinkedSessionService.empresa.id, nome])

      .subscribe(retorno => {

        this.origemparticipante = [];
        this.opcoesDeptoEmpresa = [];
        retorno.forEach(r => {
          r.selected = false;
          this.origemparticipante.push(r);

          var index = 0;

          if (this.opcoesDeptoEmpresa !== null || this.opcoesDeptoEmpresa !== undefined) {
            index = this.opcoesDeptoEmpresa.findIndex(e => e.value === r.deptoDescricao);
          }

          if (index === -1) {
            this.opcoesDeptoEmpresa.push({ label: r.deptoDescricao, value: r.deptoDescricao });
          }

          this.opcoesDeptoEmpresa.sort((a, b) => a.label.localeCompare(b.label));

        });
      },
        error => this.httpService.handleError(error, () => this.filtrar(nome)),
        () => this.httpService.done());

  }


  getListaEventosMes() {

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-lista-eventos-participantes-mes',
      [this.dblinkedSessionService.empresa.id,
      this.dblinkedSessionService.periodo.year.value,
      this.dblinkedSessionService.periodo.month.value
      ])

      .subscribe(retorno => {

        this.opcoesEventosMes = [];
        this.opcoesEventosMes.push({ value: 0, label: 'Selecione um evento' });
        this.eventoIdPesquisa = 0;
        retorno.forEach(r => {

          this.opcoesEventosMes.push({ value: r.id, label: 'ID: ' + r.id + ' - ' + r.descricaoCompleta + r.cidadeNomeCompleto + r.dataInicio });

        });
      },
        error => this.httpService.handleError(error, () => this.getListaEventosMes()),
        () => this.httpService.done());

  }

  buscarParticipantes() {
    console.log(this.eventoIdPesquisa);

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-lista-participante-evento',
      [this.eventoIdPesquisa])

      .subscribe(retorno => {

        this.origemparticipante = [];
        this.opcoesDeptoEmpresa = [];
        retorno.forEach(r => {
          r.selected = false;
          this.origemparticipante.push(r);

          var index = 0;

          if (this.opcoesDeptoEmpresa !== null || this.opcoesDeptoEmpresa !== undefined) {
            index = this.opcoesDeptoEmpresa.findIndex(e => e.value === r.deptoDescricao);
          }

          if (index === -1) {
            this.opcoesDeptoEmpresa.push({ label: r.deptoDescricao, value: r.deptoDescricao });
          }

          this.opcoesDeptoEmpresa.sort((a, b) => a.label.localeCompare(b.label));

        });
      },
        error => this.httpService.handleError(error, () => this.buscarParticipantes()),
        () => this.httpService.done());




  }


}

