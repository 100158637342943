import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { OperacaoRegraContabil } from '../model/operacao-regra-contabil.model';
import { OperacaoRegraContabilDetalhe } from '../model/operacao-regra-contabil-detalhe.model';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { TabelaCampo } from '../../cadastro/model/tabela-campo.model';
import { TipoEntradaSaida } from '../../util/model/tipo-entrada-saida.model';
import { PlanoContas } from '../../contabil/model/plano-contas.model';
import { ConfigService } from 'app/shared/services/config.service';
import { CadProj } from 'app/contabil/model/cad-proj.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadHistPadrao } from 'app/contabil/model/cad-hist-padrao.model';
import { TipoRegraContabil } from 'app/util/model/tipo-regra-contabil.model';
import { UtilService } from 'app/shared/services/util.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { TipoOperacao } from 'app/util/model/tipo-operacao.model';
import { isNullOrUndefined } from 'util';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { truncate } from 'node:fs/promises';
import { HttpClient} from '@angular/common/http';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';
import LogManagement from 'app/shared/common/log-management/log-control';
import exportExcel from 'app/util/processing/exportExcel'

interface Options {
  descricao: string,
  id: number
}
@Component({
  selector: 'app-operacao-regra-contabil',
  templateUrl: './operacao-regra-contabil.component.html',
  styleUrls: ['./operacao-regra-contabil.component.scss'],
  providers: [CrudService]
})
export class OperacaoRegraContabilComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public operacaoRegraContabil: OperacaoRegraContabil = new OperacaoRegraContabil();
  public operacaoRegraContabilVerif: OperacaoRegraContabil;

  private sessionSubscription: Subscription;

  opcoesCentroCusto: CadCcus[];
  opcoesPlanoContas: SelectItem[];
  opcoesCadHist: SelectItem[];
  opcoesProjetos: CadProj[];
  pt: any;
  opcoesCamposFormula: SelectItem[];
  opcoesTipoRegraContabil: Options[];
  opcoesTipoOperacao: Options[];
  opcoesTipoEntradaSaida: Options[];
  campos: Array<TabelaCampo> = new Array();
  tiposRegraContabil: Array<TipoRegraContabil> = new Array();
  opcoesCampos: SelectItem[];
  opcoesOperacao: SelectItem[];
  opcoesEntradaSaida: SelectItem[];

  novaRegra: boolean;
  editarRegraBoolean: boolean;
  edit: boolean;
  editProjeto: boolean;
  showInativos : boolean = false;
  checaStatusLote: boolean = true;
  clonagemUnica: boolean = false
  editProjetoCentroCusto: boolean;
  editTipoOperacao: boolean; //tipo operacao
  regraDetalhe: Array<OperacaoRegraContabilDetalhe> = new Array();
  detalheAlterar: OperacaoRegraContabilDetalhe;

  proj: any;
  cc: any;
  to: any; //tipo operacao
  id: number;   // id da operacao-regra-contabil
  porcentagem: any;
  porcentagemCc: number;
  rateioProj: number;
  rateioCentroCusto: number;
  opcoesDebitoCredito: SelectItem[];
  opcoesStatusRegraContabilShowInativos: SelectItem[] = [{ label: 'Ativo', value: true }, { label: 'Inativo', value: false }];
  opcoesStatusRegraContabil: SelectItem[] = [{ label: 'Ativo', value: true }];

  title: string;
  subtitle: string;

  regras: Array<OperacaoRegraContabil> = new Array();
  regrasContabeisSelecionadas: Array<OperacaoRegraContabil> = new Array()
  operacaoRegraContabilEditar: OperacaoRegraContabil;

  editarPlanoContas: boolean;
  editarCadHist: boolean;
  editarIndDc: boolean;
  operacaoRegraContabilDetalheEditar: OperacaoRegraContabilDetalhe;
  nomeNovaRegra: string;
  copiarRegra: boolean;
  msgs: Message[];

  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  idNovaRegraDetalhe = 0;

  empresaRealizaAlteracoes: boolean = false
  cadastroGrupoEmpresa: boolean = false
  criarRegraContabil: boolean = false
  empresasGrupo: SelectItem[] = new Array()
  empresasGrupoSelecionadas: Array<any> = new Array()
  modalGrupoEmpresas: boolean = false
  descricaoModalGrupoEmpresas: string = ''
  tituloModalGrupoEmpresas: string = ''
  nomeNovaRegraClonagemGrupoEmpresa: string = ''
  clonagem: boolean = false

  condicaoEditarRegraContabil: boolean = false
  condicaoCriarRegraContabil: boolean = false

  colunasExportar = ["id", "descricao", "tipoRegraDescricao", "tipoOperacaoDescricao", "statusRegra"]

  constructor(private configService: ConfigService,
    private crudService: CrudService,
    private httpService: HttpService,
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private toolbarService: ToolbarService,
    private messageService: MessageService) {
    super(crudService);
  }

  private LogManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  public toolbarRegraContabil = new Toolbar()

  toolbarMainRegraContabil = [
    {
      key: 'btn-clone-expanded', icon: 'content_copy', text: '', tooltip: 'Clonar', visible: true, disabled: false, color: 'default', dropdown: true,
      options: [
        { label: 'Clonar no grupo de empresas', callback: () => this.setarVariaveisClonagem(false) },
        { optionKey: 'btn-unique-clone', label: 'Clonar somente na empresa atual', callback: () => this.setarVariaveisClonagem(true) },
      ]
    },
    { key: 'btn-navigate-before', icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.empresaChanged() },
    { key: 'btn-refresh', icon: 'replay', text: 'Atualizar', tooltip: 'Atualizar Relatório', visible: false, disabled: false, color: 'default', onClick: () => this.empresaChanged() },
    { key: 'btn-clone', icon: 'content_copy', text: 'Copiar Regra', tooltip: 'Copiar Regra', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoParaSalvar(true) },
    { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.editarRegraSelect() },
    { key: 'btn-delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.cadastroGrupoEmpresa ? this.excluirRegraContabilGrupoEmpresa() : this.excluirRegra() },
    { key: 'btn-add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarRegra() },
    { key: 'btn-save-new', icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoParaSalvar() },
    { key: 'btn-save-change', icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoParaSalvar() },
    { key: 'btn-able-disable', icon: 'edit', text: 'Ativar/Desativar', tooltip: 'Ativar ou Desativar em lote', visible: false, disabled: false, color: 'default', onClick: () => this.batchEdit(this.regrasContabeisSelecionadas[this.regrasContabeisSelecionadas.length-1].ativo) }
  ]

  ngOnInit() {
    // TODO: REVIEW (IMPORTANT!)
    this.toolbarRegraContabil.setAll(this.toolbarMainRegraContabil)
    this.toolbarService.hidden = true;
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'REGRAS CONTÁBEIS';
    this.subtitle = 'CADASTRE, EDITE OU EXCLUA REGRA CONTÁBIL';

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {

        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

          let mes = this.dblinkedSessionService.periodo.month.value > 9 ?
            this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
          const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
          this.defaultDate = new Date(dt);
          // validando data inicial e final
          let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
          this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
          this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

          if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
            mes = this.dblinkedSessionService.periodo.month.value > 9 ?
              this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
            const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
            this.defaultDate = new Date(dt);
            // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
            lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
            this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
            this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();
          }
        }

        this.empresaChanged();
      });

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
      this.carregarDadosGrupoEmpresa()

      if (this.editarRegraBoolean)
        if (!this.validarRegra()) return
        else this.inicializarVariaveis(this.showInativos)

      else this.inicializarVariaveis(this.showInativos)

      this.changeToolbar()
    }
  }

  inicializarVariaveis(filter : boolean) {
    this.nomeNovaRegra = '';
    this.novaRegra = false;
    this.editarRegraBoolean = false;
    this.edit = false;
    this.editProjeto = false;
    this.editProjetoCentroCusto = false;
    this.editTipoOperacao = false; //tipo operacao
    this.porcentagem = 0;
    this.porcentagemCc = 0;
    this.rateioProj = 100;
    this.rateioCentroCusto = 100;
    this.proj = [];
    this.cc = [];
    this.editarPlanoContas = false;
    this.editarIndDc = false;
    this.editarCadHist = false;
    this.copiarRegra = false;
    this.criarRegraContabil = false
    this.modalGrupoEmpresas = false
    this.clonagem = false
    this.clonagemUnica = false
    this.nomeNovaRegraClonagemGrupoEmpresa = ''
    this.showInativos = filter

    this.opcoesTipoRegraContabil = [];
    this.opcoesTipoEntradaSaida = [];
    this.opcoesTipoOperacao = [];

    this.regrasContabeisSelecionadas = new Array()

    this.operacaoRegraContabil = new OperacaoRegraContabil();
    this.operacaoRegraContabil.operacaoRegraContabilDetalheList = new Array<OperacaoRegraContabilDetalhe>();
    this.operacaoRegraContabil.tipoEntradaSaida = new TipoEntradaSaida();
    this.detalheAlterar = new OperacaoRegraContabilDetalhe;

    this.regraDetalhe = new Array()

    this.operacaoRegraContabilEditar = new OperacaoRegraContabil()

    this.operacaoRegraContabilDetalheEditar = new OperacaoRegraContabilDetalhe();
    this.operacaoRegraContabilDetalheEditar.planoContas = new PlanoContas();
    this.operacaoRegraContabilDetalheEditar.cadHistPadrao = new CadHistPadrao();

    this.detalheAlterar.formula = '';
    this.detalheAlterar.planoContas = new PlanoContas();
    this.operacaoRegraContabil.tipoRegraContabil = new TipoRegraContabil();
    this.operacaoRegraContabilVerif = new OperacaoRegraContabil();
    this.opcoesDebitoCredito = [];

    this.opcoesDebitoCredito.push({ label: 'D/C', value: null });
    this.opcoesDebitoCredito.push({ label: 'D', value: 'D' });
    this.opcoesDebitoCredito.push({ label: 'C', value: 'C' });

    this.loadPlanoContas();

    this.httpService.wait();
    this.httpService.get('/tipoentradasaida?$select=id,descricao&$orderby=descricao')
      .subscribe(ref => this.loadTipoEntradaSaida(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/tiporegracontabil?$select=id,descricao'
      + '&$orderby=descricao')
      .subscribe(ref => this.loadTipoRegraContabil(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/tipooperacao?$select=id,descricao'
      + '&$orderby=descricao')
      .subscribe(ref => this.loadTipoOperacao(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
      if(this.cadastroGrupoEmpresa) this.adicionarTodasEmpresasGrupoNaSelecao()

      this.buscarOperacoesRegrasContabeis(filter)

      this.httpService.wait();
      this.httpService.get('/cadproj?$select=id,codProj,descrProj' +
        '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
        ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value +
        '&$orderby=codProj')
        .subscribe(ref => this.loadProjeto(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/cadccus?$select=id,codCcus,descrCcus' +
        '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
        ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value + '&$orderby=codCcus')
        .subscribe(ref => this.loadCcus(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/cadhistpadrao?$select=id,codHist,descrHist' +
        '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
        ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value + '&$orderby=codHist')
        .subscribe(ref => this.loadCadHist(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

    }

    this.buscarOperacoesRegrasContabeis(filter)

    this.httpService.wait();
    this.httpService.get('/cadproj?$select=id,codProj,descrProj,ativo' +
      '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value +
      '&$orderby=codProj')
      .subscribe(ref => this.loadProjeto(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/cadccus?$select=id,codCcus,descrCcus,ativo' +
      '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value + '&$orderby=codCcus')
      .subscribe(ref => this.loadCcus(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/cadhistpadrao?$select=id,codHist,descrHist' +
      '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value + '&$orderby=codHist')
      .subscribe(ref => this.loadCadHist(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());
  }

  loadOperacao(value: Array<TipoEntradaSaida>) {
    this.opcoesOperacao = new Array();
    this.opcoesOperacao.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesOperacao.push({ label: element.descricao, value: element.id });
    });
  }


  loadRegras(value: Array<OperacaoRegraContabil>) {
    this.regras = value;
    this.regras.forEach(regraContabil => {
      regraContabil.tipoRegraDescricao = regraContabil.tipoRegraContabil?.descricao,
      regraContabil.tipoOperacaoDescricao = regraContabil.tipoEntradaSaida?.descricao,
      regraContabil.statusRegra = regraContabil.ativo ? 'Ativo' : 'Inativo'
    })
  }

  criarRegra() {
    this.criarRegraContabil = true
    this.novaRegra = true;
    this.edit = false;
    this.editarRegraBoolean = false;
    this.regraDetalhe = new Array();
    this.operacaoRegraContabil.ativo = true
    this.addItem();
    this.addItem();
    this.changeToolbar()
  }

  modalCampo(event: any) {
    this.detalheAlterar = event;

    if (this.operacaoRegraContabilDetalheEditar.planoContas === null) {
      this.operacaoRegraContabilDetalheEditar.planoContas = new PlanoContas();
    }

    if (this.operacaoRegraContabilDetalheEditar.cadHistPadrao === null) {
      this.operacaoRegraContabilDetalheEditar.cadHistPadrao = new CadHistPadrao();
    }

    this.operacaoRegraContabilDetalheEditar = event;

    this.httpService.wait();
    this.httpService.get('/tabelacampo?$select=id,campo,descricao,tipoCampo/cod,tabela/nome,param&$filter=permiteFormula eq true'
      + '&$orderby=campo')
      .subscribe(ref => this.loadCampos(ref.value),
        error => this.httpService.handleError(error, () => this.modalCampo(event)),
        () => this.httpService.done());
  }

  onRowSelectRegra(value: any) {
    this.operacaoRegraContabilEditar = value.data;

    this.checaStatusLote = this.regrasContabeisSelecionadas.every(x => x.ativo === value.data.ativo)
    if(this.regrasContabeisSelecionadas.length > 1) this.operacaoRegraContabilEditar = new OperacaoRegraContabil()

    if (this.operacaoRegraContabilDetalheEditar.planoContas === null) this.operacaoRegraContabilDetalheEditar.planoContas = new PlanoContas()
    if (this.operacaoRegraContabilDetalheEditar.cadHistPadrao === null) this.operacaoRegraContabilDetalheEditar.cadHistPadrao = new CadHistPadrao()

    this.changeToolbar()
  }

  onUnRowSelectRegra(event) {
    this.checaStatusLote = this.regrasContabeisSelecionadas.every(x => x.ativo === true)
    if (this.checaStatusLote === false) this.checaStatusLote = this.regrasContabeisSelecionadas.every(x => x.ativo === false)

    this.operacaoRegraContabilEditar = new OperacaoRegraContabil()
    this.operacaoRegraContabilDetalheEditar.planoContas = new PlanoContas()
    this.operacaoRegraContabilDetalheEditar.cadHistPadrao = new CadHistPadrao()

    if(this.regrasContabeisSelecionadas.length === 1) {
      this.operacaoRegraContabilEditar = this.regrasContabeisSelecionadas[0]
      if (this.operacaoRegraContabilDetalheEditar.planoContas === null) this.operacaoRegraContabilDetalheEditar.planoContas = new PlanoContas()
      if (this.operacaoRegraContabilDetalheEditar.cadHistPadrao === null) this.operacaoRegraContabilDetalheEditar.cadHistPadrao = new CadHistPadrao()
    }
    this.changeToolbar()
  }

  selecionarTudo() {
    this.checaStatusLote = this.regrasContabeisSelecionadas.every(x => x.ativo === true)
    if (this.checaStatusLote === false) this.checaStatusLote = this.regrasContabeisSelecionadas.every(x => x.ativo === false)

    this.changeToolbar()
  }

  removerLinha(event: any) {
    if (confirm('Deseja excluir esta linha?')) {

      // Deletar do Banco de Dados apenas se o registro já estiver gravado
      if (event.id > 0) {
        this.httpService.wait();
        this.httpService.delete('/operacaoregracontabildetalhe', event.id.toString())
          .subscribe(() => this.editarRegraSelect(),
            error => this.httpService.handleError(error, () => this.removerLinha(event)),
            () => this.httpService.done());
      }

      // Remover o registro da lista "regraDetalhe"
      for (var i = 0; i < this.regraDetalhe.length; i++) {
        if (this.regraDetalhe[i].id == event.id) {
          this.regraDetalhe.splice(i, 1);
        }
      }
    }

  }

  editarRegra(event: any) {
    this.editarRegraBoolean = true;
    this.novaRegra = true;
    this.criarRegraContabil = false

    this.httpService.wait();
    this.httpService.get('/operacaoregracontabil?$select=id,tipoRegraContabil,'
      + 'descricao,'
      + 'ativo'
      + '&$filter=id eq ' + event.operacaoId)
      .subscribe(operacao => {
        this.operacaoRegraContabil = operacao.value[0];
        this.operacaoRegraContabilEditar = operacao.value[0];

        this.httpService.wait();
        this.httpService.get('/operacaoregracontabildetalhe?$select=id,planoContas/id,'
          + 'planoContas/dtAlt,planoContas/codNaturezaConta,planoContas/indCta,planoContas/nivel,'
          + 'planoContas/codCta,planoContas/descrCta,planoContas/ativo,indDc,planoContas,' +
          'formula,cadHistPadrao/id,cadHistPadrao/codHist,cadHistPadrao/descrHist,dfc,planoContas/aceitaRateio'
          + '&$filter=operacaoRegraContabil/id eq ' + this.operacaoRegraContabil.id + '&$orderby=indDc desc')
          .subscribe(regraDetal => {

            this.regraDetalhe = new Array();
            regraDetal.value.forEach(element => {
              if (element.planoContas == null) {
                element.planoContas = new PlanoContas();
              }
              if (element.cadHistPadrao == null) {
                element.cadHistPadrao = new CadHistPadrao();
              }
              this.regraDetalhe.push(element);

              this.httpService.wait();
              this.httpService.get('/operacaoregracontabildetalhecadproj?$select=id,'
                + 'cadProj/id,cadProj/descrProj,porcentagem'
                + '&$filter=operacaoRegraContabilDetalhe/id eq ' + element.id)
                .subscribe(cc => {
                },
                  error => this.httpService.handleError(error, () => this.editarRegra(event)),
                  () => this.httpService.done());


            });
          },
            error => this.httpService.handleError(error, () => this.editarRegra(event)),
            () => this.httpService.done());
      },
        error => this.httpService.handleError(error, () => this.editarRegra(event)),
        () => this.httpService.done());

  }

  editarRegraSelect() {
    this.editarRegraBoolean = true;
    this.novaRegra = true;
    this.editarPlanoContas = false;
    this.editarCadHist = false;
    this.edit = false;
    this.editarIndDc = false;
    this.editProjetoCentroCusto = false;
    this.editProjeto = false;
    this.criarRegraContabil = false

    this.changeToolbar()

    this.httpService.wait();
    this.httpService.get('/operacaoregracontabil?$select=id,descricao,tipoRegraContabil,tipoEntradaSaida,'
      + 'tipoOperacao,'
      + 'ativo'
      + '&$filter=id eq ' + this.operacaoRegraContabilEditar.id)
      .subscribe(operacao => {
        this.operacaoRegraContabil = operacao.value[0];

        this.httpService.wait();
        this.httpService.get('/operacaoregracontabildetalhe?$select=id,planoContas/id,'
          + 'planoContas/dtAlt,planoContas/codNaturezaConta,planoContas/indCta,planoContas/nivel,'
          + 'planoContas/codCta,planoContas/descrCta,planoContas/ativo,indDc,planoContas,' +
          'formula,cadHistPadrao/id,cadHistPadrao/codHist,cadHistPadrao/descrHist,dfc,planoContas/aceitaRateio'
          + '&$filter=operacaoRegraContabil/id eq ' + this.operacaoRegraContabil.id + '&$orderby=indDc desc')
          .subscribe(regraDetal => {

            this.regraDetalhe = new Array();
            regraDetal.value.forEach(element => {
              if (element.planoContas == null) {
                element.planoContas = new PlanoContas();
              }
              if (element.cadHistPadrao == null) {
                element.cadHistPadrao = new CadHistPadrao();
              }

              this.httpService.wait();
              this.httpService.get('/operacaoregracontabildetalhecadproj?$select=id,'
                + 'cadProj/id,cadProj/descrProj,porcentagem'
                + '&$filter=operacaoRegraContabilDetalhe/id eq ' + element.id)
                .subscribe(cadProj => {
                  element.operacaoRegraContabilDetalheCadProjList = new Array();

                  cadProj.value.forEach(cp => {
                    element.operacaoRegraContabilDetalheCadProjList.push(cp);
                  });
                },
                  error => this.httpService.handleError(error, () => this.editarRegraSelect()),
                  () => this.httpService.done());

              this.httpService.wait();
              this.httpService.get('/operacaoregracontabildetalhecentrocusto?$select=id,'
                + 'cadCcus/id,cadCcus/codCcus,cadCcus/descrCcus,porcentagem'
                + '&$filter=operacaoRegraContabilDetalhe/id eq ' + element.id)
                .subscribe(cadCc => {
                  element.operacaoRegraContabilDetalheCentroCustoList = new Array();

                  cadCc.value.forEach(cc => {
                    element.operacaoRegraContabilDetalheCentroCustoList.push(cc);
                  });

                },
                  error => this.httpService.handleError(error, () => this.editarRegraSelect()),
                  () => this.httpService.done());



              this.regraDetalhe.push(element);

            });
          },
            error => this.httpService.handleError(error, () => this.editarRegraSelect()),
            () => this.httpService.done());
      },
        error => this.httpService.handleError(error, () => this.editarRegraSelect()),
        () => this.httpService.done());

  }

  editPlanoContas(event: any) {
    this.editarPlanoContas = true;
    this.operacaoRegraContabilDetalheEditar = event;

    if (this.operacaoRegraContabilDetalheEditar.planoContas === null) this.operacaoRegraContabilDetalheEditar.planoContas = new PlanoContas()
    if (this.operacaoRegraContabilDetalheEditar.cadHistPadrao === null) this.operacaoRegraContabilDetalheEditar.cadHistPadrao = new CadHistPadrao()
  }

  editeIndDc(event: any) {
    this.editarIndDc = true;
    this.operacaoRegraContabilDetalheEditar = event;

    if (this.operacaoRegraContabilDetalheEditar.planoContas === null) this.operacaoRegraContabilDetalheEditar.planoContas = new PlanoContas()
    if (this.operacaoRegraContabilDetalheEditar.cadHistPadrao === null) this.operacaoRegraContabilDetalheEditar.cadHistPadrao = new CadHistPadrao()
  }

  editeDfc(event: any, event1: any) {

    const listasEnviar = {
      id: event.id.toString(),
      dfc: !event1 ? "true" : "false",
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/atualizar-detalhe-dfc', listasEnviar)
      .subscribe(result => this.editarRegraSelect(),
        error => this.httpService.handleError(error, () => this.editeDfc(event, event1)),
        () => this.httpService.done());


  }

  editCadHist(event: any) {
    this.editarCadHist = true
    this.operacaoRegraContabilDetalheEditar = event

    if (this.operacaoRegraContabilDetalheEditar.planoContas === null) this.operacaoRegraContabilDetalheEditar.planoContas = new PlanoContas()
    if (this.operacaoRegraContabilDetalheEditar.cadHistPadrao === null) this.operacaoRegraContabilDetalheEditar.cadHistPadrao = new CadHistPadrao()
  }

  editIndDc(event: any) {
    const listasEnviar = {
      id: event.id.toString(),
      indDc: event.indDc.toString(),
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/atualizar-detalhe-regra-contabil-ind-dc', listasEnviar)
      .subscribe(result => this.result(result),
        error => this.httpService.handleError(error, () => this.editIndDc(event)),
        () => this.httpService.done());

  }

  /* Função que salva uma regra no Banco de Dados, bem como os seus detalhes */
  salvarRegra(value: boolean) {

    /* Inicialmente, é necessário realizar uma série de validações para garantir
    que o preenchimento dos dados esteja correto. */

    /* Em caso afirmativo, realizar a gravação da nova regra no Banco de Dados
    ou a atualização de uma regra já existente. */
    if (value) {

      // Gravação de nova regra
      const listasEnviar = {

        empresa: this.dblinkedSessionService.empresa.id,
        ativo: this.operacaoRegraContabil.ativo,
        titulo: this.operacaoRegraContabil.descricao,
        tipoRegra: this.operacaoRegraContabil.tipoRegraContabil.id,
        tipoOperacao: this.operacaoRegraContabil.tipoOperacao.id,
        tipoEntradaSaida: this.operacaoRegraContabil.tipoEntradaSaida.id,
        usuario: this.sessionService.loggedUser.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/cadastro/criar-regra-contabil', listasEnviar)
        .subscribe(result => {

              // Salva o log de criação da operação regra contábil
              this.LogManagement.registerLog(
                LogModule.REGISTRATION,
                LogScreen.ACC_RULE_OPERATION,
                '/operacao-regra-contabil',
                LogAction.CREATE,
                result["operacaoId"],
                this.operacaoRegraContabil.descricao,
                `A operação regra contábil ${this.operacaoRegraContabil.descricao} foi criada.`,
                this.operacaoRegraContabil
              )
              /* Finalmente, realizar a gravação dos detalhes da regra, adicionando
              os registros novos no Banco de Dados e atualizando os existentes. */
              this.atualizarRegraDetalhes(result["operacaoId"])
            },
          error => this.httpService.handleError(error, () => this.salvarRegra(value)),
          () => this.httpService.done());

    } else {

      // Atualização de regra existente
      const listasEnviar = {
        id: this.operacaoRegraContabil.id,
        ativo: this.operacaoRegraContabil.ativo,
        empresa: this.dblinkedSessionService.empresa.id,
        titulo: this.operacaoRegraContabil.descricao,
        tipoRegra: this.operacaoRegraContabil.tipoRegraContabil.id,
        TipoOperacao: this.operacaoRegraContabil.tipoOperacao.id,
        tipoEntradaSaida: this.operacaoRegraContabil.tipoEntradaSaida.id,
        usuario: this.sessionService.loggedUser.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/cadastro/atualizar-titulo-regra-cont', listasEnviar)
        .subscribe(result => {

              // Salva o log de criação da operação regra contábil
              this.LogManagement.registerLog(
                LogModule.REGISTRATION,
                LogScreen.ACC_RULE_OPERATION,
                '/operacao-regra-contabil',
                LogAction.EDIT,
                this.operacaoRegraContabil.id,
                this.operacaoRegraContabil.descricao,
                `A operação regra contábil ${this.operacaoRegraContabil.descricao} foi alterada.`,
                this.operacaoRegraContabil
              )

            /* Finalmente, realizar a gravação dos detalhes da regra, adicionando
            os registros novos no Banco de Dados e atualizando os existentes. */
            this.atualizarRegraDetalhes(this.operacaoRegraContabilEditar.id)

          },
          error => this.httpService.handleError(error, () => this.salvarRegra(value)),
          () => this.httpService.done());

    }
  } //final do salvar regras

  resultPedido(event: any) {
    this.editarRegraBoolean = true;
  }

  modalProjeto(event: any) {
    if (this.operacaoRegraContabilDetalheEditar.planoContas === null) this.operacaoRegraContabilDetalheEditar.planoContas = new PlanoContas()
    if (this.operacaoRegraContabilDetalheEditar.cadHistPadrao === null) this.operacaoRegraContabilDetalheEditar.cadHistPadrao = new CadHistPadrao()

    if (event.planoContas && event.planoContas.aceitaRateio) {
      this.editProjeto = true;
      this.detalheAlterar = event;
      this.operacaoRegraContabilDetalheEditar = event;
      this.calculaRateioDisponivelProjeto();
    }
  }

  modalCentroCusto(event: any) {

    if (this.operacaoRegraContabilDetalheEditar.planoContas === null) {
      this.operacaoRegraContabilDetalheEditar.planoContas = new PlanoContas();
    }

    if (this.operacaoRegraContabilDetalheEditar.cadHistPadrao === null) {
      this.operacaoRegraContabilDetalheEditar.cadHistPadrao = new CadHistPadrao();
    }

    if (event.planoContas && event.planoContas.aceitaRateio) {
      this.editProjetoCentroCusto = true;
      this.detalheAlterar = event;
      this.operacaoRegraContabilDetalheEditar = event;
      this.calculaRateioDisponivelCentroCusto();
    }
  }


  cancelarEdicao() {
    if (confirm('Todas as alterações serão perdidas! Deseja continuar?')) {
      this.editarRegraSelect();
    }
  }

  incluirRateio() {

    const listasEnviar = {
      operacaoRegraContabilDetalhe: this.operacaoRegraContabilDetalheEditar.id.toString(),
      cadProj: this.proj.id,
      porcentagem: this.utilService.parseDecimal(this.porcentagem),
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/atualizar-rateio-projeto', listasEnviar)
      .subscribe(result => this.editarRegraSelect(),
        error => this.httpService.handleError(error, () => this.incluirRateio()),
        () => this.httpService.done());

    this.calculaRateioDisponivelProjeto();
    this.zerarVariavesProj();

  }

  incluirRateioCentroCusto() {

    const listasEnviar = {
      operacaoRegraContabilDetalhe: this.operacaoRegraContabilDetalheEditar.id.toString(),
      cadCcus: this.cc.id,
      porcentagem: this.utilService.parseDecimal(this.porcentagemCc),
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/atualizar-rateio-cc', listasEnviar)
      .subscribe(result => this.editarRegraSelect(),
        error => this.httpService.handleError(error, () => this.incluirRateioCentroCusto()),
        () => this.httpService.done());

    this.calculaRateioDisponivelCentroCusto();
    this.zerarVariavesCc();

  }



  zerarVariavesProj() {
    this.porcentagem = 0;
  }

  zerarVariavesCc() {
    this.porcentagemCc = 0;
  }

  calculaRateioDisponivelProjeto() {
    let rateioProj: number;
    rateioProj = 0.00;
    this.rateioProj = 100;
    if (this.operacaoRegraContabilDetalheEditar) {
      if (this.operacaoRegraContabilDetalheEditar.id) {
        this.operacaoRegraContabilDetalheEditar.operacaoRegraContabilDetalheCadProjList.forEach(element => {
          element.porcentagem = this.utilService.parseDecimal(element.porcentagem);
          rateioProj = rateioProj + element.porcentagem;
        });
        this.rateioProj = this.rateioProj - rateioProj;

      }
    }

  }

  calculaRateioDisponivelCentroCusto() {
    let rateio: number;
    rateio = 0.00;
    this.rateioCentroCusto = 100;
    if (this.operacaoRegraContabilDetalheEditar) {
      if (this.operacaoRegraContabilDetalheEditar.id) {
        this.operacaoRegraContabilDetalheEditar.operacaoRegraContabilDetalheCentroCustoList.forEach(element => {
          element.porcentagem = this.utilService.parseDecimal(element.porcentagem);
          rateio = rateio + element.porcentagem;
        });
        this.rateioCentroCusto = this.rateioCentroCusto - rateio;
      }
    }
  }

  loadPlanoContas() {

    this.opcoesPlanoContas = [];
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-planocontas',
      [this.dblinkedSessionService.empresa.id, this.dataIniSearch.toString()])
      .subscribe(ref => ref.forEach(element => {
        this.opcoesPlanoContas.push({ label: element.codCta + ' - ' + element.descrCta, value: element.id });
      }),
        error => this.httpService.handleError(error, () => this.loadPlanoContas()),
        () => this.httpService.done());

  }

  loadCadHist(value: Array<any>) {
    this.opcoesCadHist = [];

    this.opcoesCadHist.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesCadHist.push({ label: element.codHist + ' - ' + element.descrHist, value: element.id });
    });
  }

  excluirLinha() {
    if (confirm('Deseja excluir esta linha?')) {

      this.httpService.wait();
      this.httpService.delete('/operacaoregracontabildetalhe', this.operacaoRegraContabilDetalheEditar.id.toString())
        .subscribe(() => this.empresaChanged(),
          error => this.httpService.handleError(error, () => this.excluirLinha()),
          () => this.httpService.done());
    }
  }

  salvarAlteracaoPlanoContas() {

    const listasEnviar = {
      id: this.operacaoRegraContabilDetalheEditar.id.toString(),
      planoContas: this.operacaoRegraContabilDetalheEditar.planoContas.id.toString(),
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/atualizar-detalhe-regra-contabil-pc', listasEnviar)
      .subscribe(result => this.editarRegraSelect(),
        error => this.httpService.handleError(error, () => this.salvarAlteracaoPlanoContas()),
        () => this.httpService.done());
  }

  salvarAlteracaoIndDc() {

    const listasEnviar = {
      id: this.operacaoRegraContabilDetalheEditar.id.toString(),
      indDc: this.operacaoRegraContabilDetalheEditar.indDc,
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/atualizar-detalhe-regra-contabil-ind-dc', listasEnviar)
      .subscribe(result => this.editarRegraSelect(),
        error => this.httpService.handleError(error, () => this.salvarAlteracaoIndDc()),
        () => this.httpService.done());
  }

  salvarFormula() {
    this.regraDetalhe.forEach(item => {
      if (item.id == this.detalheAlterar.id) {
        item.formula = this.detalheAlterar.formula;
      }
    });

    this.edit = false;

  }

  validarRegra(): boolean {
    let variavel: boolean = true;

    this.regraDetalhe.forEach(element => {
      if (isNullOrUndefined(element.formula) || isNullOrUndefined(element.planoContas) || isNullOrUndefined(element.indDc)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG:', detail: 'Operação Inválida, Favor Revise' });
        variavel = false;
      }
    });

    return variavel
  }

  salvarAlteracaoCadHist() {

    const listasEnviar = {
      id: this.operacaoRegraContabilDetalheEditar.id.toString(),
      cadHist: this.operacaoRegraContabilDetalheEditar.cadHistPadrao.id.toString(),
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/atualizar-detalhe-regra-contabil-cad-hist', listasEnviar)
      .subscribe(result => this.editarRegraSelect(),
        error => this.httpService.handleError(error, () => this.salvarAlteracaoCadHist()),
        () => this.httpService.done());
  }

  result(event: any) {

    this.editarRegraBoolean = true;
    this.novaRegra = true;

    this.httpService.wait();
    this.httpService.get('/operacaoregracontabil?$select=id,tipoRegraContabil,'
      + 'descricao,'
      + 'ativo'
      + '&$filter=id eq ' + this.operacaoRegraContabil.id)
      .subscribe(operacao => {
        this.operacaoRegraContabil = operacao.value[0];

        this.httpService.wait();
        this.httpService.get('/operacaoregracontabildetalhe?$select=id,planoContas/id,'
          + 'planoContas/dtAlt,planoContas/codNaturezaConta,planoContas/indCta,planoContas/nivel,'
          + 'planoContas/codCta,planoContas/descrCta,planoContas/ativo,indDc,planoContas,' +
          'formula,cadHistPadrao/id,cadHistPadrao/codHist,cadHistPadrao/descrHist,dfc,planoContas/aceitaRateio'
          + '&$filter=operacaoRegraContabil/id eq ' + this.operacaoRegraContabil.id + '&$orderby=indDc desc')
          .subscribe(regraDetal => {

            this.regraDetalhe = new Array();
            regraDetal.value.forEach(element => {
              this.regraDetalhe.push(element);

              this.httpService.wait();
              this.httpService.get('/operacaoregracontabildetalhecadproj?$select=id,'
                + 'cadProj/id,cadProj/descrProj,porcentagem'
                + '&$filter=operacaoRegraContabilDetalhe/id eq ' + element.id)
                .subscribe(cc => {
                },
                  error => this.httpService.handleError(error, () => this.result(event)),
                  () => this.httpService.done());


            });
          },
            error => this.httpService.handleError(error, () => this.result(event)),
            () => this.httpService.done());
      },
        error => this.httpService.handleError(error, () => this.result(event)),
        () => this.httpService.done());

  }



  loadProjeto(value: Array<any>) {
    this.opcoesProjetos = [];

    value.forEach(element => {
      if (element.ativo) {
        element.label = element.codProj + ' - ' + element.descrProj;
        this.opcoesProjetos.push(element);
      }
    });
  }

  loadCcus(value: Array<any>) {
    this.opcoesCentroCusto = [];

    value.forEach(element => {
      if (element.ativo) {
        element.label = element.codCcus + ' - ' + element.descrCcus;
        this.opcoesCentroCusto.push(element);
      }
    });
  }

  onChangeConta(event: any) {

    if (event.planoContas.aceitaRateio) {
      event.operacaoRegraContabilDetalheCadProjList = new Array();
      event.operacaoRegraContabilDetalheCentroCustoList = new Array();
    }
  }

  loadProj(value: Array<any>) {
    this.opcoesProjetos = [];

    value.forEach(element => {
      this.opcoesProjetos.push(element);
    });
  }

  loadTipoRegraContabil(value: Array<TipoRegraContabil>) {

    this.opcoesTipoRegraContabil.push({ descricao: 'Selecione o Tipo de Regra Contábil', id: null });

    value.forEach(element => {
      this.opcoesTipoRegraContabil.push({
        descricao: element.descricao,
        id: element.id
      })
    });
  }

  loadTipoOperacao(value: Array<TipoOperacao>) {
    this.opcoesTipoOperacao.push({ descricao: 'Selecione o Tipo de Operação', id: null });

    value.forEach(element => {
      this.opcoesTipoOperacao.push({
        descricao: element.descricao,
        id: element.id
      });
    });

  }

  loadTipoEntradaSaida(value: Array<TipoEntradaSaida>) {

    this.opcoesTipoEntradaSaida.push({ descricao: 'Selecione o Tipo', id: null });

    value.forEach(element => {
      this.opcoesTipoEntradaSaida.push({
        descricao: element.descricao,
        id: element.id
      });
    })
  }



  loadCampos(value: Array<TabelaCampo>) {
    this.campos = [];
    this.opcoesCampos = [];
    this.opcoesCampos.push({ label: 'Selecione o campo', value: null });
    this.campos = value;
    if (this.operacaoRegraContabil.tipoRegraContabil) {
      this.campos.forEach(element => {
        if (element.param) {
          if (this.operacaoRegraContabil.tipoRegraContabil.descricao === 'Produto'
            && (element.param.startsWith('p') || element.param.startsWith('ps') || element.param.startsWith('pf')
              || element.param.startsWith('psf'))) {
            if (element.tipoCampo.cod === 'numeric') {
              this.opcoesCampos.push({ label: element.tabela.nome + ' - ' + element.descricao, value: element });
            }
          } else if ((this.operacaoRegraContabil.tipoRegraContabil.descricao === 'Serviço')
            && (element.param.startsWith('s') || element.param.startsWith('ps') || element.param.startsWith('sf')
              || element.param.startsWith('psf') || element.param.indexOf('s') !== -1)) {
            if (element.tipoCampo.cod === 'numeric') {
              this.opcoesCampos.push({ label: element.tabela.nome + ' - ' + element.descricao, value: element });
            }
          } else if (((this.operacaoRegraContabil.tipoRegraContabil.descricao === 'Outros'))
            && (element.param.startsWith('o') || element.param.startsWith('po'))) {
            if (element.tipoCampo.cod === 'numeric') {
              this.opcoesCampos.push({ label: element.tabela.nome + ' - ' + element.descricao, value: element });
            }
          } else if ((this.operacaoRegraContabil.tipoRegraContabil.descricao === 'Financeiro' || this.operacaoRegraContabil.tipoRegraContabil.descricao === 'Sistema')
            && (element.param.startsWith('f') || element.param.startsWith('pf') || element.param.startsWith('sf')
              || element.param.startsWith('psf'))) {
            if (element.tipoCampo.cod === 'numeric') {
              this.opcoesCampos.push({ label: element.tabela.nome + ' - ' + element.descricao, value: element });
            }
          }
        }
      });
    }



    this.edit = true;
  }

  onChangeCampoFormula(event: any) {
    if (this.detalheAlterar.formula) {
      this.detalheAlterar.formula = this.detalheAlterar.formula +
        '{{' + event.value.tabela.nome + '.' + event.value.campo + '}}';
    } else {
      this.detalheAlterar.formula = '{{' + event.value.tabela.nome + '.' + event.value.campo + '}}';
    }
  }

  abreParenteses() {
    this.detalheAlterar.formula = this.detalheAlterar.formula + ' (';
  }

  fechaParenteses() {
    this.detalheAlterar.formula = this.detalheAlterar.formula + ' )';
  }

  incluirSoma() {
    this.detalheAlterar.formula = this.detalheAlterar.formula + ' + ';
  }

  incluirSub() {
    this.detalheAlterar.formula = this.detalheAlterar.formula + ' - ';
  }

  incluirMult() {
    this.detalheAlterar.formula = this.detalheAlterar.formula + ' * ';
  }

  incluirDiv() {
    this.detalheAlterar.formula = this.detalheAlterar.formula + ' / ';
  }

  limparCampoFormula() {
    this.detalheAlterar.formula = '';
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  verifSaldoRateio(): boolean {
    if (this.rateioProj <= 0 || ((this.rateioProj - this.porcentagem) < 0) || (this.porcentagem === 0)) {
      return true;
    } else {
      return false;
    }
  }

  verifSaldoRateioCentroCusto(): boolean {
    if (this.rateioCentroCusto <= 0 || ((this.rateioCentroCusto - this.porcentagemCc) < 0) || (this.porcentagemCc === 0)) {
      return true;
    } else {
      return false;
    }
  }

  verifRegra(): boolean {
    if (!this.operacaoRegraContabil.descricao || !this.operacaoRegraContabil.tipoRegraContabil
      || !this.detalheAlterar) {
      return true;
    } else {
      return false;
    }
  }

  removerRateioCadProj(event: any) {

    if (confirm('Deseja excluir este rateio?')) {

      this.httpService.wait();
      this.httpService.delete('/operacaoregracontabildetalhecadproj', event.id.toString())
        .subscribe(() => this.editarRegraSelect(),
          error => this.httpService.handleError(error, () => this.removerRateioCadProj(event)),
          () => this.httpService.done());

    }
  }

  removerRateioCentroCusto(event: any) {
    if (confirm('Deseja excluir este rateio?')) {

      this.httpService.wait();
      this.httpService.delete('/operacaoregracontabildetalhecentrocusto', event.id.toString())
        .subscribe(() => this.editarRegraSelect(),
          error => this.httpService.handleError(error, () => this.removerRateioCentroCusto(event)),
          () => this.httpService.done());
    }
  }

  /* Função que realiza a validação dos campos pertencentes a uma regra */
  validarCampos() {

    this.msgs = [];

    //Validacao do Titulo
    if (isNullOrUndefined(this.operacaoRegraContabil.descricao) || this.operacaoRegraContabil.descricao.trim().length < 1) {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: "Favor digitar o Título!" });
      return false;
    }

    //Tipo Entrada Saida
    if (isNullOrUndefined(this.operacaoRegraContabil.tipoEntradaSaida) || isNullOrUndefined(this.operacaoRegraContabil.tipoEntradaSaida.id)) {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: "Favor selecionar o Tipo de Entrada!" });
      return false;
    }

    //Validacao do tipo de regra
    if (isNullOrUndefined(this.operacaoRegraContabil.tipoRegraContabil) || isNullOrUndefined(this.operacaoRegraContabil.tipoRegraContabil.id)) {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: "Favor selecionar o Tipo de Regra!" });
      return false
    }

    //Validacao do tipo de operacao
    if (isNullOrUndefined(this.operacaoRegraContabil.tipoOperacao) || isNullOrUndefined(this.operacaoRegraContabil.tipoOperacao.id)) {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: "Favor selecionar o Tipo de Operação!" });
      return false;
    }

    return true;

  }

  excluirRegra() {

    if (confirm('Deseja excluir esta regra?')) {
      let operacaoRegraContabilLog = {'id' : this.operacaoRegraContabilEditar.id,
                                      'descricao': this.operacaoRegraContabilEditar.descricao,
                                      'objeto': this.operacaoRegraContabilEditar}
      const map = {
        id: this.operacaoRegraContabilEditar.id.toString()
      };

      this.httpService.wait();
      this.httpService.delete('/operacaoregracontabil', this.operacaoRegraContabilEditar.id.toString())
      this.httpService
        .post('/custom/cadastro/excluir-regra-contabil', map)
        .subscribe(result => {
          this.empresaChanged();

          if(result.error == "false"){
            this.LogManagement.registerLog(
              LogModule.REGISTRATION,
              LogScreen.ACC_RULE_OPERATION,
              '/operacao-regra-contabil',
              LogAction.DELETE,
              operacaoRegraContabilLog.id,
              operacaoRegraContabilLog.descricao,
              `A regra contábil ${operacaoRegraContabilLog.descricao} foi excluida.`,
              operacaoRegraContabilLog.objeto
            )
          }

          this.messageService.add({ key: 'messageLinnks', severity: result.severity, summary: result.summary, detail: result.detail });
        },
          error => this.httpService.handleError(error, () => this.excluirRegra()),
          () => this.httpService.done());

    }
  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }



  fecharRateioCadProj() {
    this.editProjeto = false;
  }

  fecharRateioCentroCusto() {
    this.editProjetoCentroCusto = false;
  }

  addItem() {
    const item = new OperacaoRegraContabilDetalhe();
    this.idNovaRegraDetalhe = this.idNovaRegraDetalhe - 1;
    item.id = this.idNovaRegraDetalhe;
    item.formula = null;
    item.dfc = false;
    item.indDc = "D";
    item.cadHistPadrao = new CadHistPadrao();
    item.planoContas = new PlanoContas();

    this.regraDetalhe.push(item);
  }

  duplicarRegra() {
    this.copiarRegra = true;
  }

  gravarNovaRegra() {
    const listasEnviar = {
      id: this.operacaoRegraContabilEditar.id.toString(),
      nomeNovaRegra: this.nomeNovaRegra,
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/copiar-regra-contabil', listasEnviar)
      .subscribe(result => this.empresaChanged(),
        error => this.httpService.handleError(error, () => this.salvarAlteracaoCadHist()),
        () => this.httpService.done());
  }

  /* Função que faz a gravação dos detalhes de uma regra no
  Banco de Dados. */
  atualizarRegraDetalhes(regraId: Number) {

    var dados = {
      regraDetalhe: this.regraDetalhe,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuarioId: this.sessionService.loggedUser.id,
      regraId: regraId,
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/atualizar-regra-detalhes', dados)
      .subscribe(result => this.empresaChanged(),
        error => this.httpService.handleError(error, () => this.atualizarRegraDetalhes(regraId)),
        () => this.httpService.done());
  }

  /* Função que faz a validação dos detalhes pertencentes a uma regra */
  validarDetalhesRegra() {
    this.msgs = [];

    /* Inicialmente, validar se os campos estão devidamente
    preenchidos para todos os registros */
    var i = 1
    var resultado = true
    let credito = false
    let debito = false
    this.regraDetalhe.forEach(item => {
      if (resultado == true) {
        if ((item.formula == null) || (item.formula == undefined) ||
          (item.formula == "")) {
          this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: 'Por favor insira uma fórmula para a linha ' + i })
          resultado = false
          return
        }

        if ((item.planoContas.id == null) || (item.planoContas.id == undefined)) {
          this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: 'Por favor insira uma plano de contas para a linha ' + i })
          resultado = false
          return
        }

        if ((item.cadHistPadrao.id == null) || (item.cadHistPadrao.id == undefined)) {
          this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: 'Por favor insira um histórico para a linha ' + i })
          resultado = false
          return
        }

        if (item.indDc === "D") debito = true
        else if (item.indDc === "C") credito = true
      }
      i = i + 1
    })

    if (resultado && (!debito || !credito)) {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: 'É necessário ter pelo menos uma fórmula de crédito e uma fórmula de débito'})
      resultado = false
    }

    /* Em caso,afirmativo, verificar se o Dfc está marcado como "True" para
    um e apenas um dos registros. */
    if (resultado == true) {
      var resultDfc = this.validarDfc();
      return resultDfc;
    } else {
      return false;
    }
  }

  /* Função que a faz validação do campo Dfc, que deve estar como "True"
  em apenas um dos detalhes de uma regra. */
  validarDfc() {
    this.msgs = [];
    let countDfc = 0;
    this.regraDetalhe.forEach(element => {
      if (element.dfc) {
        countDfc = countDfc + 1;
      }
    });

    if (countDfc < 1) {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: 'Por favor deixe 1 linha com o DFC selecionado.' });
      return false;
    } else if (countDfc > 1) {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: 'Por favor deixe apenas 1 linha com o DFC selecionado.' });
      return false;
    } else {
      return true;
    }
  }

  buscarOperacoesRegrasContabeis(filter : boolean)  {
    const empresaId = this.dblinkedSessionService.empresa.id

    this.httpService.wait();
    this.httpService.get(`/custom/cadastro/buscar-regras-contabeis(${empresaId},${filter})`)
      .subscribe(
        response => this.loadRegras(response.dados),
        error => this.httpService.handleError(error, () => this.salvarRegraContabilGrupoEmpresa()),
        () => this.httpService.done()
      )
  }

  async carregarDadosGrupoEmpresa() {
    if (this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.hasEmpresaSelected) {
      await this.verificarSeEmpresaPodeRealizarModificacoes()
      if (this.empresaRealizaAlteracoes) await this.getCadastroGrupoEmpresas()
      this.changeToolbar()
    }
  }

  async getCadastroGrupoEmpresas() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.get(`/empresa?$select=cadastroGrupoEmpresa&$filter=id eq ${companyId}`).subscribe(
      res => {
        this.cadastroGrupoEmpresa = res.value[0].cadastroGrupoEmpresa ? true : false

        if(this.cadastroGrupoEmpresa) this.getEmpresasGrupo()
      },
      error => this.httpService.handleError(error, () => this.getCadastroGrupoEmpresas())
    )
  }


  getEmpresasGrupo() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.get(`/empresagrupocadastrorelac?$select=id,empresaRelac/nomeFantasia&$filter=empresa/id eq ` + companyId).subscribe(
      res => {
        this.empresasGrupo = []
        this.empresasGrupoSelecionadas = []

        this.empresasGrupoSelecionadas.push(companyId)

        res.value.forEach(company => {
          this.empresasGrupo.push({
            label: company.empresaRelac.nomeFantasia,
            value: company.empresaRelac.id
          })

          this.empresasGrupoSelecionadas.push(company.empresaRelac.id)
        })
      },
      error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro ao buscar as empresas do grupo' })
    )
  }


  async verificarSeEmpresaPodeRealizarModificacoes() {
    const companyId = this.dblinkedSessionService.empresa.id
    this.empresaRealizaAlteracoes = false

    try {
      await this.httpService.get(`/custom/cadastro/verifica-grupo-empresa-permite-alteracao(${companyId})`).toPromise()
      .then(res => this.empresaRealizaAlteracoes = res.permissoes.permiteAlteracaoRegraContabil)
      .catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }

  changeToolbar() {
    let existeOperacaoSelecionada = false
    this.checaStatusLote
    if(this.regrasContabeisSelecionadas !== null && this.regrasContabeisSelecionadas !== undefined && this.regrasContabeisSelecionadas.length > 0) existeOperacaoSelecionada = true

    this.condicaoEditarRegraContabil = this.empresaRealizaAlteracoes && this.editarRegraBoolean
    this.condicaoCriarRegraContabil = this.empresaRealizaAlteracoes && this.criarRegraContabil && !this.editarRegraBoolean
    const condicaoTelaPrincipal= !this.editarRegraBoolean && !this.novaRegra && !this.editarRegraBoolean && !this.condicaoEditarRegraContabil && !this.condicaoCriarRegraContabil
    const condicaoTelaPrincipalComPermissaoDeRealizarAlteracoes = condicaoTelaPrincipal && this.empresaRealizaAlteracoes
    const condicaoEditarRegraContabilOuCriarRegraContabil = this.condicaoEditarRegraContabil || this.condicaoCriarRegraContabil && this.empresaRealizaAlteracoes
    const condicaoTelaPrincipalComSelecaoEPermissaoFazerMudancas = this.empresaRealizaAlteracoes && condicaoTelaPrincipal && existeOperacaoSelecionada

    const condicaoPaginaInicialUmSelecionado = this.regrasContabeisSelecionadas.length === 1
    const condicaoPaginaInicialMaisDeUmSelecionado = this.regrasContabeisSelecionadas.length >= 1

    this.toolbarRegraContabil.setVisible('btn-refresh', condicaoTelaPrincipal && !existeOperacaoSelecionada)
    this.toolbarRegraContabil.setVisible('btn-add', condicaoTelaPrincipalComPermissaoDeRealizarAlteracoes && !existeOperacaoSelecionada)
    this.toolbarRegraContabil.setVisible('btn-save-new', this.condicaoCriarRegraContabil)
    this.toolbarRegraContabil.setVisible('btn-save-change', this.condicaoEditarRegraContabil)
    this.toolbarRegraContabil.setVisible('btn-navigate-before', condicaoEditarRegraContabilOuCriarRegraContabil)

    this.toolbarRegraContabil.setVisible('btn-clone', condicaoTelaPrincipalComSelecaoEPermissaoFazerMudancas && condicaoPaginaInicialUmSelecionado && !this.cadastroGrupoEmpresa)
    this.toolbarRegraContabil.setVisible('btn-clone-expanded', condicaoTelaPrincipalComSelecaoEPermissaoFazerMudancas && this.cadastroGrupoEmpresa)
    this.toolbarRegraContabil.setOptionVisible('btn-clone-expanded', 'btn-unique-clone', condicaoTelaPrincipalComSelecaoEPermissaoFazerMudancas && condicaoPaginaInicialUmSelecionado)

    this.toolbarRegraContabil.setVisible('btn-edit', condicaoTelaPrincipalComSelecaoEPermissaoFazerMudancas && condicaoPaginaInicialUmSelecionado)
    this.toolbarRegraContabil.setVisible('btn-delete', condicaoTelaPrincipalComSelecaoEPermissaoFazerMudancas && condicaoPaginaInicialUmSelecionado)
    this.toolbarRegraContabil.setVisible('btn-able-disable', condicaoPaginaInicialMaisDeUmSelecionado && this.checaStatusLote)
  }

  determinarMetodoParaSalvar(isClone: boolean = false): void {
    let validationFields: boolean = true

    if(!isClone) validationFields = this.validarCampos() && this.validarDetalhesRegra()

    if (this.cadastroGrupoEmpresa) this.adicionarTodasEmpresasGrupoNaSelecao()

    if (isClone && this.cadastroGrupoEmpresa && !this.clonagemUnica) {
      this.clonagem = true
      this.modalGrupoEmpresas = true
      this.tituloModalGrupoEmpresas = 'Clonagem de regra contábil em várias empresas'
      this.descricaoModalGrupoEmpresas = 'Em quais outras empresas você deseja clonar esta regra contábil?'
    } else if (!isClone && this.cadastroGrupoEmpresa && validationFields) {
      this.clonagem = false
      this.modalGrupoEmpresas = true
      this.tituloModalGrupoEmpresas = this.novaRegra ? 'Criação de regra contábil em várias empresas' : 'Edição de regra contábil em várias empresas'
      this.descricaoModalGrupoEmpresas = this.novaRegra ? 'Em quais outras empresas você deseja criar regra contábil?' : 'Em quais outras empresas você deseja editar esta regra contábil?'
    } else if ((isClone && !this.cadastroGrupoEmpresa) || this.clonagemUnica) this.duplicarRegra()
    else if (validationFields) this.salvarRegra(this.criarRegraContabil)
  }

  salvarRegraContabilGrupoEmpresa() {
    const empresaCriadoraId = this.dblinkedSessionService.empresa.id
    const titulo = this.operacaoRegraContabil.descricao
    const tipoRegraContabilId = this.operacaoRegraContabil.tipoRegraContabil.id
    const tipoOperacaoId = this.operacaoRegraContabil.tipoOperacao.id
    const tipoEntradaSaidaId = this.operacaoRegraContabil.tipoEntradaSaida.id
    const usuarioId = this.sessionService.loggedUser.id
    const empresasGrupoIds = this.empresasGrupoSelecionadas
    const regraDetalhe = this.regraDetalhe
    const regraAtiva = this.operacaoRegraContabil.ativo

    const payload = { empresaCriadoraId, titulo, tipoRegraContabilId, tipoOperacaoId, tipoEntradaSaidaId, usuarioId, regraDetalhe, empresasGrupoIds, regraAtiva }

    this.httpService.wait();
    this.httpService.post('/custom/grupo-empresa/criar-regra-contabil-grupo-empresa', payload)
      .subscribe(
        response => this.exibirRespostaRequisicao(response),
        error => this.httpService.handleError(error, () => this.salvarRegraContabilGrupoEmpresa()),
        () => this.httpService.done()
      )
  }

  editarRegraContabilGrupoEmpresa() {
    const empresaEditoraId = this.dblinkedSessionService.empresa.id
    const operacaoRegraContabilId = this.operacaoRegraContabil.id
    const titulo = this.operacaoRegraContabil.descricao
    const grupoEmpresaOperacaoRegraContabilOrigemId = this.operacaoRegraContabil.grupoEmpresaOperacaoRegraContabilOrigemId
    const tipoRegraContabilId = this.operacaoRegraContabil.tipoRegraContabil.id
    const tipoOperacaoId = this.operacaoRegraContabil.tipoOperacao.id
    const tipoEntradaSaidaId = this.operacaoRegraContabil.tipoEntradaSaida.id
    const usuarioId = this.sessionService.loggedUser.id
    const empresasGrupoIds = this.empresasGrupoSelecionadas
    const regraDetalhe = this.regraDetalhe
    const ativo = this.operacaoRegraContabil.ativo

    const payload = {
      ativo,
      empresaEditoraId,
      operacaoRegraContabilId,
      grupoEmpresaOperacaoRegraContabilOrigemId,
      titulo,
      tipoRegraContabilId,
      tipoOperacaoId,
      tipoEntradaSaidaId,
      usuarioId,
      regraDetalhe,
      empresasGrupoIds
    }

    this.httpService.wait();
    this.httpService.post('/custom/grupo-empresa/editar-regra-contabil-grupo-empresa', payload)
      .subscribe(
        response => this.exibirRespostaRequisicao(response),
        error => this.httpService.handleError(error, () => this.editarRegraContabilGrupoEmpresa()),
        () => this.httpService.done()
      )
  }

  excluirRegraContabilGrupoEmpresa() {
    const confirmacaoExclusao = confirm('Deseja excluir esta regra? Esta ação afetará todas as empresas do grupo.')

    if (!confirmacaoExclusao) return

    const operacaoRegraContabilId = this.operacaoRegraContabilEditar.id
    const grupoEmpresaOperacaoRegraContabilOrigemId = this.operacaoRegraContabilEditar.grupoEmpresaOperacaoRegraContabilOrigemId
    const empresasGrupoIds = this.empresasGrupoSelecionadas

    const payload = { operacaoRegraContabilId, grupoEmpresaOperacaoRegraContabilOrigemId, empresasGrupoIds }

    this.httpService.wait();
    this.httpService.post('/custom/grupo-empresa/excluir-regra-contabil-grupo-empresa', payload)
      .subscribe(
        response => this.exibirRespostaRequisicao(response),
        error => this.httpService.handleError(error, () => this.excluirRegraContabilGrupoEmpresa()),
        () => this.httpService.done()
      )
  }

  validarClonagemRegraContabilGrupoEmpresa(): boolean {
    let dadosValidos: boolean = this.nomeNovaRegraClonagemGrupoEmpresa !== '' && this.nomeNovaRegraClonagemGrupoEmpresa !== null && this.nomeNovaRegraClonagemGrupoEmpresa !== undefined

    if(!dadosValidos) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: 'Informe um novo título de Regra Contábil' })

    return dadosValidos
  }


  clonarRegraGrupoEmpresa() {
    const empresaClonadoraId = this.dblinkedSessionService.empresa.id

    const empresasGrupoIds = this.empresasGrupoSelecionadas.filter(empresaSelecionadaId => empresaSelecionadaId !== empresaClonadoraId)
    if(empresasGrupoIds.length <= 0) return this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: 'Selecione ao menos uma empresa para clonar a regra'})

    let operacoesRegrasContabeisIds = new Array()
    this.regrasContabeisSelecionadas.forEach(regra => operacoesRegrasContabeisIds.push(regra.id))

    const usuarioId = this.sessionService.loggedUser.id
    const grupoEmpresaOperacaoRegraContabilOrigemId = this.operacaoRegraContabilEditar.grupoEmpresaOperacaoRegraContabilOrigemId

    const payload = { usuarioId, empresaClonadoraId, operacoesRegrasContabeisIds, grupoEmpresaOperacaoRegraContabilOrigemId, empresasGrupoIds }

    this.httpService.wait();
    this.httpService.post('/custom/grupo-empresa/clonar-regra-contabil-grupo-empresa', payload).subscribe(
      response => this.exibirRespostaRequisicao(response),
      error => this.httpService.handleError(error, () => this.excluirRegraContabilGrupoEmpresa()),
      () => this.httpService.done()
    )
  }

  exibirRespostaRequisicao(response) {
    if (!response.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: response.message })
      this.empresaChanged()
    } else this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: response.message })
  }

  adicionarTodasEmpresasGrupoNaSelecao() {
    const empresaId = this.dblinkedSessionService.empresa.id
    this.empresasGrupoSelecionadas = new Array()
    this.empresasGrupoSelecionadas.push(empresaId)
    this.empresasGrupo.forEach(empresa => this.empresasGrupoSelecionadas.push(empresa.value))
  }

  batchEdit(status : boolean) {
    let message
    this.regrasContabeisSelecionadas[this.regrasContabeisSelecionadas.length-1].ativo === true ? message = 'Deseja desativar todas as regras selecionadas ?' : message = 'Deseja ativar todas as regras selecionadas ?'
    if(confirm(message)){
      let payload
      let updateStatus
      let listRegrasId = new Array()
      status === true ? updateStatus = false : updateStatus = true

      for ( let regras of this.regrasContabeisSelecionadas) listRegrasId.push(regras.id)

      payload = {
          userId: this.sessionService.loggedUser.id,
          regrasId: listRegrasId,
          status: updateStatus
        }


      this.httpService.wait()
      this.httpService.post('/custom/cadastro/altera-status-regras-contabeis', payload).subscribe(
        response => this.exibirRespostaRequisicao(response),
        error => this.httpService.handleError(error, () => this.batchEdit(updateStatus)),
        () => this.httpService.done()
      )
      this.empresaChanged()
    }
  }

  setarVariaveisClonagem(clonagemUnica: boolean) {
    this.clonagemUnica = clonagemUnica
    this.determinarMetodoParaSalvar(true)
  }

  exportarExcel() {
    exportExcel(this.regras, 'operacao-regra-contabil', this.colunasExportar)
  }
}
