import { isNullOrUndefined } from "util";
import {GetNetCliente} from '../model/getnet-cliente.model';
import {GetNetPlano} from '../model/getnet-plano.model';

export class GetNetAssinatura{

  id                : number;
  getNetCliente     : GetNetCliente;
  getNetClienteId   : number;
  getNetPlano       : GetNetPlano;
  getNetPlanoId     : number;
  subscriptionId    : string;
  installmentStartDate : Date;
  installmentStartDatePt : String;
  status               : string;
  statusPt             : string;
  endDate              : Date;
  endDatePt            : String;
  createDate           : Date;
  createDatePt         : String;


  constructor(getNetAssinatura?: GetNetAssinatura | number) {
    if (!isNullOrUndefined(getNetAssinatura)) {
      if (typeof getNetAssinatura === 'number') {
        this.id = getNetAssinatura;
      } else {
        this.id             = getNetAssinatura.id;
        this.getNetCliente  = getNetAssinatura.getNetCliente;
        this.getNetClienteId = getNetAssinatura.getNetClienteId;
        this.getNetPlano     = getNetAssinatura.getNetPlano;
        this.getNetPlanoId   = getNetAssinatura.getNetPlanoId;
        this.subscriptionId  = getNetAssinatura.subscriptionId;
        this.installmentStartDate = getNetAssinatura.installmentStartDate;
        this.status               = getNetAssinatura.status;
        this.endDate              = getNetAssinatura.endDate;
        this.endDatePt            = getNetAssinatura.endDatePt;
        this.createDate           = getNetAssinatura.createDate;
        this.createDatePt         = getNetAssinatura.createDatePt;
        this.statusPt             = getNetAssinatura.statusPt;

      }
    }
  }

}