export class TbCodEnquadra {
  id: number;
  cod: string;
  nome: string;
  paisOrig: string;
  valueAlias: string;

  constructor(tbCodEnquadra?: TbCodEnquadra | number) {
    if (tbCodEnquadra) {
      if (typeof tbCodEnquadra === 'number') {
        this.id = tbCodEnquadra;
      } else {
        this.id = tbCodEnquadra.id;
        this.cod = tbCodEnquadra.cod;
        this.nome = tbCodEnquadra.nome;
        this.paisOrig = tbCodEnquadra.paisOrig;
        this.valueAlias = tbCodEnquadra.valueAlias;
      }
    }
  }
}
