import { Component, OnInit } from '@angular/core';
import { PlanoContasRfb } from '../model/plano-contas-rfb.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'app-plano-contas-rfb',
  templateUrl: './plano-contas-rfb.component.html',
  styleUrls: ['./plano-contas-rfb.component.scss'],
  providers: [CrudService]
})
export class PlanoContasRfbComponent extends DataTableBasicComponent implements OnInit {
  public planoContasRfb: PlanoContasRfb = new PlanoContasRfb();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
     new DataTableColumn('Codigo Conta', 'codCta'),
     new DataTableColumn('Descr Conta', 'descrCta'),
     new DataTableColumn('Data Ini', 'dtIni'),
     new DataTableColumn('Data Fim', 'dtFin'),
     new DataTableColumn('Tipo Conta', 'tipoCta'),
     new DataTableColumn('Cod Cta Sup', 'parent.codCta'),
     new DataTableColumn('Nível', 'nivelCta'),
     new DataTableColumn('Cod Natureza', 'codNatureza'),
     new DataTableColumn('Utilização', 'utilizacao')

    ];

    this.controls = [
        new DataControl('Codigo Conta', 'codCta')
        .setRequired()
        .setMaxLength(255),
        new DataControl('Descr Conta', 'descrCta')
        .setRequired()
        .setMaxLength(255),
        new DataControl('Data Ini', 'dtIni')
        .setRequired()
        .setMinLength(8)
        .setMaxLength(8),
        new DataControl('Data Fin', 'dtFin')
        .setMinLength(8)
        .setMaxLength(8)
        .setRequired(),
        new DataControl('Tipo Conta', 'tipoCta')
        .setRequired()
        .setMaxLength(255),
        new DataControl('Cod Cta Sup', 'parent.codCta')
        .setRequired()
        .setMaxLength(255),
        new DataControl('Nível', 'nivelCta')
        .setRequired()
        .setMaxLength(11),
        new DataControl('Cod Natureza', 'codNatureza')
        .setRequired()
        .setMaxLength(11),
        new DataControl('Utilizacao', 'utilizacao')
        .setRequired()
        .setMaxLength(11)


    ];
  }
}
