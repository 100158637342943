import { AuthGroup } from './auth-group.model';
import { AuthCrowd } from './auth-crowd.model';

export class AuthUser {
  id: number;
  login: string;
  mail: string;
  pass: string;
  displayName: string;
  fullName: string;
  lastAuth: Date;
  token: string;
  active = true;
  authGroups: Array<AuthGroup> = new Array();
  authCrowds: Array<AuthCrowd> = new Array();

  constructor(authUser?: AuthUser | number) {
    if (authUser !== undefined && authUser !== null) {
      if (typeof authUser === 'number') {
        this.id = authUser;
      } else {
        this.id = authUser.id;
        this.login = authUser.login;
        this.mail = authUser.mail;
        this.pass = authUser.pass;
        this.displayName = authUser.displayName;
        this.fullName = authUser.fullName;
        this.lastAuth = authUser.lastAuth;
        this.token = authUser.token;
        this.active = authUser.active;
        this.authGroups = authUser.authGroups || new Array();
        this.authCrowds = authUser.authCrowds || new Array();
      }
    }
  }
}
