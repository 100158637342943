import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'app/shared/services/config.service';

@Component({
  selector: 'app-teste',
  templateUrl: './import-planilha.component.html',
  styleUrls: ['./import-planilha.component.scss']
})

export class ContabilImportPlanilhaComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef

  file: File = null
  filename: string = null
  listTipoArquivo: Array<any> = [
    { label: 'Lançamento Contábil (Txt) (DEPRECIADO)', value: 999 },
    { label: 'Lançamento Contábil (XLSX)', value: 1 }
  ]
  idTipoArquivo: number = null

  private sessionSubscription: Subscription

  constructor(private httpService: HttpService,
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private messageService: MessageService,
    private configService: ConfigService,
    private httpClient: HttpClient) {
    this.toolbarService.hidden = true
    this.dblinkedSessionService.hidePeriods()
  }

  ngOnInit() {
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.file = null
        this.filename = null
        document.getElementById('file-name').innerText = ''
        this.idTipoArquivo = null
      })
  }

  ngOnDestroy() {
    this.sessionSubscription.remove(this.sessionSubscription)
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0]
    this.filename = this.file.name
  }

  importar() {
    if (this.idTipoArquivo === 999) {
      const data: FormData = new FormData()
      data.append('file', this.file)
      data.append('type', 'cont-txt')
      data.append('empresaId', this.dblinkedSessionService.empresa.id.toString())
      data.append('usuarioId', this.sessionService.loggedUser.id.toString())

      this.httpService.wait()
      this.httpService.post('/custom/upload', data)
        .subscribe(
          result => {
            if (result.result[0].error === true) {
              result.result[0].value.forEach(element => {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: element });
              });
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Lançamentos Realizados com Sucesso!' });
            }
          },
          error => this.httpService.handleError(error, () => this.importar()),
          () => this.httpService.done())
    } else if (this.idTipoArquivo === 1) {
      const formData = new FormData()
      formData.append('file', this.file)
      const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }

      this.httpService.wait()
      this.httpClient.post(`${this.configService.defaultUrlApiUpload}contabil/importar-lcto-cont?empresaId=${this.dblinkedSessionService.empresa.id}&userId=${this.sessionService.loggedUser.id}`, formData, httpOptions)
        .subscribe(
          res => {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res["message"] })
            this.httpService.done()
          },
          err => {
            if (err.status === 400) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: err.error.message })
              const httpOptions2 = {
                headers: new HttpHeaders({
                  'Authorization': this.configService.bearerApi,
                  'Cache-Control': 'no-store, max-age=0'
                }),
                responseType: 'blob' as 'json'
              }

              this.httpClient.get(`${this.configService.defaultUrlApiUpload}contabil/importar-lcto-cont?userId=${this.sessionService.loggedUser.id}`, httpOptions2)
                .subscribe(
                  (res: Blob) => {
                    let link = document.createElement('a')
                    let url = window.URL.createObjectURL(res)
                    let fileName = "log.txt"
                    link.href = url
                    link.download = fileName
                    link.click()
                    window.URL.revokeObjectURL(url)
                    this.httpService.done()
                  },
                  err => {
                    this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao baixar o log, contatar o suporte: " + err.message })
                    this.httpService.done()
                  }
                )
            } else if (err.status === 500 && err.error.message) {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.error.message })
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Não foi possível alcançar o API, contate o suporte." })
            }
            this.httpService.done()
          }
        )
    }
  }

  public formatDateExtensive(): String {
    const dt = new Date()
    const monthExtensive = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const weekExtensive = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]

    const month = monthExtensive[dt.getMonth()]
    const week = weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }

  public getLinkLayoutUpload(){
    window.open('https://aws-website-downloads-e72og.s3.amazonaws.com/LAYOUT_UPLOAD/LAYOUT_LCTO_CONT.xlsx', '_blank')
  }
}
