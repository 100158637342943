import { isNullOrUndefined } from "util";

export class OpcaoMoedaVersao {
  id: number;
  descricao: string;

  constructor(opcaoMoedaVersao?: OpcaoMoedaVersao | number) {

    if (!isNullOrUndefined(opcaoMoedaVersao)) {
      if (typeof opcaoMoedaVersao === 'number') {
        this.id = opcaoMoedaVersao;

      } else {
        this.id = opcaoMoedaVersao.id;
        this.descricao = opcaoMoedaVersao.descricao;
      }
    }
  }
}
