import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { CadHistPadrao } from 'app/contabil/model/cad-hist-padrao.model';
import { OperacaoRegraContabil } from 'app/cadastro/model/operacao-regra-contabil.model';
import { OperacaoRegraContabilDetalheCadProj } from 'app/cadastro/model/operacao-regra-contabil-detalhe-cad-proj.model';
import { OperacaoRegraContabilDetalheCentroCusto } from 'app/cadastro/model/operacao-regra-contabil-detalhe-centro-custo.model';


export class OperacaoRegraContabilDetalhe {
  id: number;
  operacaoRegraContabil: OperacaoRegraContabil;
  planoContas: PlanoContas;
  indDc: string;
  formula: string;
  cadHistPadrao: CadHistPadrao;
  operacaoRegraContabilDetalheCadProjList: Array<OperacaoRegraContabilDetalheCadProj>;
  operacaoRegraContabilDetalheCentroCustoList: Array<OperacaoRegraContabilDetalheCentroCusto>;
  dfc: boolean;

  constructor(operacaoRegraContabilDetalhe?: OperacaoRegraContabilDetalhe | number) {
    if (operacaoRegraContabilDetalhe !== undefined && operacaoRegraContabilDetalhe !== null) {
      if (typeof operacaoRegraContabilDetalhe === 'number') {
        this.id = operacaoRegraContabilDetalhe;
      } else {
        this.id = operacaoRegraContabilDetalhe.id;
        this.operacaoRegraContabil = operacaoRegraContabilDetalhe.operacaoRegraContabil;
        this.planoContas = operacaoRegraContabilDetalhe.planoContas;
        this.indDc = operacaoRegraContabilDetalhe.indDc;
        this.formula = operacaoRegraContabilDetalhe.formula;
        this.cadHistPadrao = operacaoRegraContabilDetalhe.cadHistPadrao;
        this.operacaoRegraContabilDetalheCadProjList = operacaoRegraContabilDetalhe.operacaoRegraContabilDetalheCadProjList;
        this.operacaoRegraContabilDetalheCentroCustoList = operacaoRegraContabilDetalhe.operacaoRegraContabilDetalheCentroCustoList;
        this.dfc = operacaoRegraContabilDetalhe.dfc;
      }
    }
  }
}
