export class TipoAssinante {
  id: number;
  cod: any;
  descr: string;

  constructor(tipoAssinante?: TipoAssinante | number) {
    if (tipoAssinante !== undefined && tipoAssinante !== null) {
      if (typeof tipoAssinante === 'number') {
        this.id = tipoAssinante;
      } else {
        this.id = tipoAssinante.id;
        this.cod = tipoAssinante.cod;
        this.descr = tipoAssinante.descr;
      }
    }
  }
}
