import { Uf } from './uf.model';

export class MunicipioIbge {
  id: number;
  cod: string;
  uf: Uf;
  nome: string;
  dtIni: string;
  dtFin: string;

  label: string;

  constructor(municipioIbge?: MunicipioIbge | number) {
    if (municipioIbge !== undefined && municipioIbge !== null) {
      if (typeof municipioIbge === 'number') {
        this.id = municipioIbge;
      } else {
        this.id = municipioIbge.id;
        this.uf = municipioIbge.uf;
        this.cod = municipioIbge.cod;
        this.nome = municipioIbge.nome;
        this.dtIni = municipioIbge.dtIni;
        this.dtFin = municipioIbge.dtFin;

        if (municipioIbge.uf && municipioIbge.nome) {
          this.label = (municipioIbge.uf.uf ? municipioIbge.uf.uf + ' - ' : '') + municipioIbge.nome;
        }
      }
    }
  }
}
