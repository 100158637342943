import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MessageService } from '../../../../node_modules/primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-config-tipo-anexo',
  templateUrl: './tipo-anexo.component.html',
  styleUrls: ['./tipo-anexo.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ConfigTipoAnexoComponent implements OnInit, OnDestroy {
  ////////////////////////////////////////
  // REFATOR SIMON LALLEMENT 28/12/2021 //
  ////////////////////////////////////////

  private sessionSubscription: Subscription

  //Toolbar
  toolbarMainIni = [
    { icon: 'add', text: 'Nova', tooltip: 'Nova', visible: true, disabled: false, color: 'green', onClick: () => this.openAddTpAnexo() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openEditTpAnexo() },
    { icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: false, disabled: false, color: 'red', onClick: () => this.excluirTpAnexo() }
  ]
  public toolbarMain = new Toolbar()

  //Array
  tipoAnexoRequisicao: Array<any> = new Array()
  tpAnexoSelected: Array<number> = new Array()

  //String
  titlePrincipal: string = 'Configurações RMS'
  subtitle: string = 'Tipo Anexo'
  currentStateTpAnexo: string = 'initial'
  descricaoTpAnexo: string = null

  //Boolean
  isCardTpAnexo: boolean = true
  checkedAll: boolean = false
  modalTpAnexo: boolean = false
  add: boolean = false
  edit: boolean = false

  //Miscellaneous
  tpAnexoSelecionada: any = {}

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.titleService.title = 'Linnks - Tipo Anexo RMS'
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.tipoAnexoRequisicaoItens()
      }
    })
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
  }

  openAddTpAnexo() {
    this.modalTpAnexo = true
    this.descricaoTpAnexo = null
    this.add = true
  }

  openEditTpAnexo() {
    this.modalTpAnexo = true
    this.tpAnexoSelecionada = this.tipoAnexoRequisicao.find(element => this.tpAnexoSelected[0] === element.id)
    this.descricaoTpAnexo = this.tpAnexoSelecionada.descricao
    this.edit = true
  }

  excluirTpAnexo() {
    if (confirm("Deseja remover os tipos de anexo selecionados?")) {
      let listaEnviar = {
        id: this.tpAnexoSelected
      }

      this.httpService.wait();
      this.httpService.post('/custom/rms/deletar-tipo-anexo', listaEnviar)
        .subscribe(rst => {
          this.tipoAnexoRequisicaoItens()
          this.checkToolBar()
          this.httpService.done()

        }, error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao excluir os tipos de anexo, contate o suporte : " + error.message })
          this.httpService.done()
        })
    }
  }

  changeStateTpAnexo() {
    this.currentStateTpAnexo = this.currentStateTpAnexo === 'initial' ? 'final' : 'initial'
  }

  showHideTpAnexo() {
    this.isCardTpAnexo = !this.isCardTpAnexo
  }

  adicionar() {
    if (this.validacao()) return

      let listaEnviar = {
        nomeTipo: this.descricaoTpAnexo,
        usuario: this.sessionService.loggedUser.id,
        empresa: this.dblinkedSessionService.empresa.id
      }

      this.httpService.wait();
      this.httpService.post('/custom/rms/adicionar-tipo-anexo', listaEnviar)
      .subscribe(rst => {
        this.tipoAnexoRequisicaoItens()
        this.modalTpAnexo = false
        this.add = false
        this.httpService.done()

      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar o tipo de anexo, contate o suporte : " + error.message })
          this.httpService.done()
        })
  }

  validacao(): boolean {
    if (!this.descricaoTpAnexo) {
      this.exibirMensagem({ error: true, mensagem: "O campo Descrição é obrigatório." })
      return true
    }

    return false
  }

  tipoAnexoRequisicaoItens() {
    this.httpService.wait()
    this.httpService.get('/custom/rms/listar-tipo-anexo', this.dblinkedSessionService.empresa.id)
    .subscribe(rst => {
      this.tipoAnexoRequisicao = new Array()
      rst.forEach(element => { this.tipoAnexoRequisicao.push(element) })
      this.httpService.done()
    },
      error => {
        console.log(error)
        this.exibirMensagem({ severity: "error", message: "Erro no API ao obter os tipos de anexo, contate o suporte : " + error.message })
        this.httpService.done()
      })
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  atualizar() {
    if (this.validacao()) return
    this.tpAnexoSelecionada.nome = this.descricaoTpAnexo

    this.httpService.wait();
    this.httpService.post('/custom/rms/atualizar-tipo-anexo', this.tpAnexoSelecionada)
    .subscribe(rst => {
      this.tipoAnexoRequisicaoItens()
      this.checkToolBar()
      this.modalTpAnexo = false
      this.edit = false
      this.httpService.done()

    }, error => {
      console.log(error)
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar o tipo de anexo, contate o suporte : " + error.message })
      this.httpService.done()
    })
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  onRowSelect(event: any) {
    this.tipoAnexoRequisicao.forEach(item => {
      if (item.id === event.data.id) {
        this.tpAnexoSelected.push(item.id)
      }
    })

    this.checkToolBar()
  }

  checkToolBar() {
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn2', false)

    if (this.tpAnexoSelected.length > 0) {
      this.toolbarMain.setVisible('btn3', true)
      this.toolbarMain.setVisible('btn2', true)
      if (this.tpAnexoSelected.length > 1) {
        this.toolbarMain.setVisible('btn2', false)
      }
    }
  }

  onRowUnselect(event: any) {
    const index = this.tipoAnexoRequisicao.findIndex(c => c === event.data.id)
    this.tpAnexoSelected.splice(index, 1)
    this.checkToolBar()
  }

  selectTodos() {
    this.checkedAll = !this.checkedAll
    this.tpAnexoSelected = new Array()
    if (this.checkedAll) {
      this.tipoAnexoRequisicao.forEach(row => this.tpAnexoSelected.push(row.id))
    }

    this.checkToolBar()
  }
}
