import { Regra } from 'app/cadastro/model/regra.model';
import { GrupoRegra } from 'app/cadastro/model/grupo-regra.model';

export class RegraRelac {
  id: number;
  regra: Regra;
  grupoRegra: GrupoRegra;

  constructor(regraRelac?: RegraRelac | number) {
    if (regraRelac !== undefined && regraRelac !== null) {
      if (typeof regraRelac === 'number') {
        this.id = regraRelac;
      } else {
        this.id = regraRelac.id;
        this.regra = regraRelac.regra;
        this.grupoRegra = regraRelac.grupoRegra;
      }
    }
  }
}
