import { Component, OnInit, OnDestroy } from '@angular/core';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadProj } from 'app/contabil/model/cad-proj.model';
import { isNullOrUndefined } from 'util';
import { Message, MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
  selector: 'app-report',
  templateUrl: './relatorio-rh.component.html',
  styleUrls: ['./relatorio-rh.component.scss'],
})

export class RHRelatorios implements OnInit, OnDestroy {

  sessionSubscription: Subscription;

  opcoesFuncionarios: SelectItem[];
  funcionariosSelecionados: Array<any>;
  dtIni: Date;
  dtFin: Date;
  DefFinalDate: Date;
  DefInitDate: Date;
  centroCustosOpcoes: Array<CadCcus> = new Array();
  opcoesCentroCustos: SelectItem[];
  titlePrincipal: string;
  msgs: Message[];
  listaRelatGer: Array<any> = new Array();
  relatGerSelecionado: any;
  dicRelatComposto: any = {};
  datasIndividuais: boolean = false;
  modalRelatorioGer: boolean = false;
  dataRef: Date = new Date();
  modalAfd: boolean = false

  //MOdal demonstrativo
  statusDemonRh: boolean = false;
  statusleidobem: boolean = false;
  centroCustoSelecionados: CadCcus[];
  opcoesProjetos: SelectItem[];
  projetosSelecionados: CadProj[];

  //Modal orçamento
  statusOrcaRh: boolean = false;
  versaoSelecionada: number = null;
  opcoesVersao: SelectItem[] = [];
  pgelecionado: number = null;
  opcoesPg: SelectItem[] = [];
  empresaId: any;

  //modal folha de pagamento
  statusFolhaPgto: boolean = false;

  //Modal DBPayroll
  modalDbPayroll: boolean = false;

  opcoesContrato: SelectItem[] = []
  tipoCargoSelecionado: number = null

  constructor(private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.titlePrincipal = 'RELATÓRIOS';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.relatGerSelecionado = {};

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
          this.DefInitDate = new Date(this.dblinkedSessionService.periodo.year.value, 0, 1);
          this.DefFinalDate = new Date(this.dblinkedSessionService.periodo.year.value, 11, 31);
          this.dtIni = this.DefInitDate;
          this.dtFin = this.DefFinalDate;

          this.opcoesProjetos = [];
          this.projetosSelecionados = [];
          this.opcoesCentroCustos = [];
          this.centroCustoSelecionados = [];
          this.opcoesFuncionarios = [];
          this.funcionariosSelecionados = [];
          this.empresaId = this.dblinkedSessionService.empresa.id;

          //Buscar os valores de opções
          this.getFuncionarios();
          this.getCentroCusto();
          this.getConfigRelatorios();
          this.getTipoCargo()
        }
      });
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  getTipoCargo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/tipo-cargo', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }
          this.opcoesContrato = []
          res["data"].forEach(element => {
            this.opcoesContrato.push({label: element["codigo"] + " - " + element["descricao"], value: element["id"]})
          });

          this.httpService.done()
        },
        err => {
          console.log(err)
          this.httpService.done()
          this.exibirMensagem({error: true, mensagem: "Erro no API ao obter os contratos, contate o suporte: " + err.message})
        }
      );
  }

  gerarOrcamentoRh() {
    var dt = new Date();
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'versaoid': this.versaoSelecionada.toString(),
        'pgid': this.pgelecionado.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/orcamento-rh', null, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          window.open(this.configService.defaultUrlApiUpload + 'rh/baixarorcamento/' + this.dblinkedSessionService.empresa.razaoSocial.toUpperCase(), "_blank");
          this.statusOrcaRh = false;
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getFuncionarios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/funcionarios', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }
          this.opcoesFuncionarios = []
          res["data"].forEach(row => this.opcoesFuncionarios.push({ label: row["codigo"] + " - " + row["nome"], value: row["id"] }))
          this.httpService.done()
        },
        error => {
          console.log(error)
          this.httpService.done()
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao obter os funcionários, contate o suporte: " + error.message })
        })
  }

  getCentroCusto() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/ccus', httpOptions)
      .subscribe(proj => {
        this.opcoesCentroCustos = [];
        var result = proj["mensagem"];
        for (let line of result) {
          this.opcoesCentroCustos.push({ "label": line["cod_ccus"] + " - " + line["descr_ccus"], "value": line["id"] });
        }
        this.httpService.done();
        this.comboProjetos();
      },
        error => this.httpService.handleError(error, () => this.exibirMensagem(error)),
        () => this.httpService.done());

  }

  onChangeCcus() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'ccus': this.centroCustoSelecionados.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/getprojfiltrados', null, httpOptions)
      .subscribe(
        res => {
          var result = res["mensagem"];
          this.opcoesProjetos = [];
          this.projetosSelecionados = [];

          if (!this.statusFolhaPgto && !this.statusleidobem) {
            this.opcoesProjetos.push({ label: 'Em branco', value: null });
          }

          for (let line of result) {
            this.opcoesProjetos.push({ label: line["proj"], value: line["id"] });
            this.projetosSelecionados.push(line["id"]);
          }

          this.httpService.done();
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  gerarDemonstrativoRh() {
    let dtinit: Date
    let dtfin: Date
    if (isNullOrUndefined(this.dtIni)) {
      dtinit = this.DefInitDate
      dtfin = this.DefFinalDate

    } else {
      dtinit = this.dtIni
      dtfin = this.dtFin
    }

    const httpOptions = {headers: new HttpHeaders({Authorization: this.configService.bearerApi})}

    const infos = {
      liccus: this.centroCustoSelecionados,
      liproj: this.projetosSelecionados,
      lifunc: this.funcionariosSelecionados,
      dtmin: dtinit.getFullYear() + "-" + (dtinit.getMonth() + 1) + "-" + dtinit.getDate(),
      dtmax: dtfin.getFullYear() + "-" + (dtfin.getMonth() + 1) + "-" + dtfin.getDate(),
      empresa_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/demonstrativo-rh', infos, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          window.open(this.configService.defaultUrlApiUpload + 'rh/baixardemonstrativo/' + this.sessionService.loggedUser.id, "_blank")
          this.httpService.done()
        },
        err => {
          this.exibirMensagem(err)
          this.httpService.done()
        }
      )
  }

  exibirMensagem(value: any) {

    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  hideMessage() {

  }

  centroCustoLoad(value: Array<CadCcus>) {
    this.centroCustosOpcoes = value;
    this.opcoesCentroCustos = [];
    this.centroCustoSelecionados = [];
    this.opcoesCentroCustos.push({ label: 'Em branco', value: null });
    if (this.centroCustosOpcoes.length > 0) {
      this.centroCustosOpcoes.forEach(element => {
        this.opcoesCentroCustos.push({ label: element.codCcus + ' - ' + element.descrCcus, value: element.id });
        if (!isNullOrUndefined(this.opcoesCentroCustos)) {
          this.opcoesCentroCustos.map(item => this.centroCustoSelecionados.push(item.value));
        }
      });
    }
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  showDialogRelatorio(tipo) {

    if (tipo == 'DEM_RH') {
      this.projetosSelecionados = [];
      this.centroCustoSelecionados = [];
      this.dtFin = this.DefFinalDate;

      if (this.opcoesCentroCustos.length === 0 || this.opcoesCentroCustos[0].value !== null) {
        this.opcoesCentroCustos.unshift({ label: 'Em branco', value: null });
      }

      if (this.opcoesProjetos.length === 0 || this.opcoesProjetos[0].value !== null) {
        this.opcoesProjetos.unshift({ label: 'Em branco', value: null });
      }

      if (!isNullOrUndefined(this.opcoesProjetos) && this.opcoesProjetos.length > 0) {
        this.opcoesProjetos.map(item => this.projetosSelecionados.push(item.value));
      }

      if (!isNullOrUndefined(this.opcoesCentroCustos) && this.opcoesCentroCustos.length > 0) {
        this.opcoesCentroCustos.map(item => this.centroCustoSelecionados.push(item.value));
      }

      if (this.opcoesFuncionarios !== null && this.opcoesFuncionarios && this.opcoesFuncionarios.length > 0) {
        this.opcoesFuncionarios.map(item => this.funcionariosSelecionados.push(item.value));
      }

      this.statusDemonRh = true;
    } else if (tipo == "ORCA_RH") {
      this.dtFin = this.DefFinalDate;
      this.getOrcaVersoes();
      this.getPlanoGerenciais();
      this.statusOrcaRh = true;

    } else if (tipo === 'LEI_DO_BEM') {

      this.dtIni = this.DefInitDate;
      this.dtFin = this.DefFinalDate;


      this.projetosSelecionados = [];
      this.centroCustoSelecionados = [];
      this.dtFin = this.DefFinalDate;
      if (this.opcoesCentroCustos[0] !== null && this.opcoesCentroCustos[0] !== undefined && this.opcoesCentroCustos[0].value === null) {
        this.opcoesCentroCustos.shift();
      }

      if (this.opcoesProjetos[0] !== null && this.opcoesProjetos[0] !== undefined && this.opcoesProjetos[0].value === null) {
        this.opcoesProjetos.shift();
      }

      if (this.opcoesProjetos !== null && this.opcoesProjetos && this.opcoesProjetos.length > 0) {
        this.opcoesProjetos.map(item => this.projetosSelecionados.push(item.value));
      }

      if (this.opcoesCentroCustos !== null && this.opcoesCentroCustos && this.opcoesCentroCustos.length > 0) {
        this.opcoesCentroCustos.map(item => this.centroCustoSelecionados.push(item.value));
      }

      if (this.opcoesFuncionarios !== null && this.opcoesFuncionarios && this.opcoesFuncionarios.length > 0) {
        this.opcoesFuncionarios.map(item => this.funcionariosSelecionados.push(item.value));
      }

      this.statusleidobem = true;

    } else if (tipo === 'FOLHA_PGTO') {
      let ano = this.dblinkedSessionService.periodo.year.value;
      let mes = this.dblinkedSessionService.periodo.month.value
      let dia = 31;
      let data = new Date(ano, --mes, 1);

      // pegando o ultimo dia do mes atual
      if (mes !== 11) {
        data.setMonth(mes + 1);
        data.setDate(data.getDate() - 1);
      } else {
        data.setDate(dia);
      }

      this.dtFin = data;
      this.dtIni = new Date(data.getFullYear(), data.getMonth(), 1);
      this.projetosSelecionados = [];
      this.centroCustoSelecionados = [];
      this.funcionariosSelecionados = [];

      if (this.opcoesCentroCustos[0] !== null && this.opcoesCentroCustos[0] !== undefined && this.opcoesCentroCustos[0].value === null) {
        this.opcoesCentroCustos.shift();
      }

      if (this.opcoesProjetos[0] !== null && this.opcoesProjetos[0] !== undefined && this.opcoesProjetos[0].value === null) {
        this.opcoesProjetos.shift();
      }

      if (this.opcoesProjetos !== null && this.opcoesProjetos && this.opcoesProjetos.length > 0) {
        this.opcoesProjetos.map(item => this.projetosSelecionados.push(item.value));
      }

      if (this.opcoesCentroCustos !== null && this.opcoesCentroCustos && this.opcoesCentroCustos.length > 0) {
        this.opcoesCentroCustos.map(item => this.centroCustoSelecionados.push(item.value));
      }

      if (this.opcoesFuncionarios !== null && this.opcoesFuncionarios && this.opcoesFuncionarios.length > 0) {
        this.opcoesFuncionarios.map(item => this.funcionariosSelecionados.push(item.value));
      }

      this.statusFolhaPgto = true;

    } else if (tipo === 'DB_PAYROLL') {
      this.getOrcaVersoes();
      this.versaoSelecionada = 0;
      this.modalDbPayroll = true;
      this.dataRef = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1);

    } else if (tipo === 'AFD') {
      this.funcionariosSelecionados = new Array()
      this.dtIni = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dtFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
      this.modalAfd = true

    } else {
      this.relatGerSelecionado = tipo;
      this.modalRelatorioGer = true;
      this.dataRef = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1);
    }
  }

  gerarRelatorioGerencialExcel() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    let config_list = [];
    if (this.relatGerSelecionado.is_composta) {
      for (let conf of this.dicRelatComposto[this.relatGerSelecionado.id]) {
        if (this.datasIndividuais && conf['dataRef'] == null) {
          this.exibirMensagem({ error: true, mensagem: "Informe uma data para todos os relatórios ou desmarque a opção de datas individuais." });
          return;
        }

        config_list.push({
          'empresa_id': this.dblinkedSessionService.empresa.id,
          'dt_ref': this.datasIndividuais ? [conf['dataRef'].getDate(), conf['dataRef'].getMonth() + 1, conf['dataRef'].getFullYear()] : [this.dataRef.getDate(), this.dataRef.getMonth() + 1, this.dataRef.getFullYear()],
          'config_id': conf['config_relat_ref_id'],
          'worksheet_title': conf['worksheet_title']
        });
      }
    } else {
      config_list = [{
        'empresa_id': this.dblinkedSessionService.empresa.id,
        'dt_ref': [this.dataRef.getDate(), this.dataRef.getMonth() + 1, this.dataRef.getFullYear()],
        'config_id': this.relatGerSelecionado.id
      }];
    }

    const infos = {
      list_infos: config_list,
      is_composta: this.relatGerSelecionado.is_composta,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', infos, httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();

          } else {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0',
                'userid': this.sessionService.loggedUser.id.toString()
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', httpOptions2)
              .subscribe(
                (res: Blob) => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);
                  let fileName = "Relatório gerencial.xlsx";
                  link.href = url;
                  link.download = fileName;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o relatório." });
                  console.log(err);
                  this.httpService.done();
                });
          }
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o relatório." });
          console.log(err);
          this.httpService.done();
        });
  }

  renderizarRelatorioGerencial() {
    var data_ref: string = this.dataRef.getDate() + '-' + (this.dataRef.getMonth() + 1).toString() + '-' + this.dataRef.getFullYear();
    window.open('/workstats/render-relatorios-gerenciais/' + this.dblinkedSessionService.empresa.id.toString() + '/' + this.relatGerSelecionado.id + '/' + this.relatGerSelecionado.plano_gerencial_id + '/' + data_ref, '_blank');
  }

  getConfigRelatorios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'modulo': '6'
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            this.listaRelatGer = new Array();
            for (var line of res["mensagem"]) {
              this.listaRelatGer.push(line);
            }

            this.dicRelatComposto = res["dicconfigs_compostas"];
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getOrcaVersoes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/versao', httpOptions)
      .subscribe(
        res => {
          this.opcoesVersao = [];
          var result = res["data"];
          for (let line of result) {
            this.opcoesVersao.push({ "label": line["descricao"], "value": line["id"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getPlanoGerenciais() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/plano_gerencial', httpOptions)
      .subscribe(
        res => {
          this.opcoesPg = [];
          var result = res["mensagem"];
          for (let line of result) {
            this.opcoesPg.push({ "label": line["codigo"] + " - " + line["descricao"], "value": line["id"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  comboProjetos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/proj', httpOptions)
      .subscribe(proj => {
        this.opcoesProjetos = [];
        var result = proj["mensagem"];
        for (let line of result) {
          this.opcoesProjetos.push({ "label": line["cod_proj"] + " - " + line["descr_proj"], "value": line["id"] });
        }
        this.httpService.done();
      },
        error => this.httpService.handleError(error, () => this.exibirMensagem(error)),
        () => this.httpService.done());
  }

  gerarFolhaPgto() {
    if (this.dtFin < this.dtIni) {
      this.exibirMensagem({ error: true, mensagem: "A data inicial deve ser inferior à data final." });
      return;
    }

    if (this.funcionariosSelecionados.length === 0) {
      this.exibirMensagem({ error: true, mensagem: "Selecione pelo menos um Funcionário." });
      return;
    }

    let dtIni: string = this.dtIni.getFullYear() + "-" + (this.dtIni.getMonth() + 1) + "-" + this.dtIni.getDate();
    let dtFin: string = this.dtFin.getFullYear() + "-" + (this.dtFin.getMonth() + 1) + "-" + this.dtFin.getDate();

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'dtInit': dtIni,
        'dtFin': dtFin,
        'func': this.funcionariosSelecionados.toString(),
        'ccus': this.centroCustoSelecionados.length === this.opcoesCentroCustos.length ? "" : this.centroCustoSelecionados.toString(),
        'proj': this.projetosSelecionados.length === this.opcoesProjetos.length ? "" : this.projetosSelecionados.toString(),
        'userid': this.sessionService.loggedUser.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/relat-folha-pgto', null, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userId': this.sessionService.loggedUser.id.toString()
            }),

            responseType: 'blob' as 'json'
          };

          this.httpService.wait();
          this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/baixar-relat-folha-pgto', httpOptions2)
            .subscribe(
              (res: Blob) => {
                let link = document.createElement('a');
                let url = window.URL.createObjectURL(res);
                let hoje = new Date();
                let mes_hoje = hoje.getMonth() + 1 < 10 ? "0" + (hoje.getMonth() + 1).toString() : (hoje.getMonth() + 1).toString();
                let ano_hoje = hoje.getFullYear().toString();

                link.href = url;
                let fileName = `Relatório de folha de pagamento ${mes_hoje}/${ano_hoje}.xlsx`;
                link.download = fileName;
                link.click();

                window.URL.revokeObjectURL(url);
                this.httpService.done();
              },
              err => {
                console.log(err);
                this.exibirMensagem(err);
                this.httpService.done();
              });

          this.httpService.done();
        },
        err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        });
  }

  gerarRelatLeiDoBem() {
    if (this.dtFin < this.dtIni) {
      this.exibirMensagem({ error: true, mensagem: "A data inicial deve ser inferior à data final." })
      return
    }

    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      dt_ini: this.dtIni.getFullYear() + "-" + (this.dtIni.getMonth() + 1) + "-" + this.dtIni.getDate(),
      dt_fin: this.dtFin.getFullYear() + "-" + (this.dtFin.getMonth() + 1) + "-" + this.dtFin.getDate(),
      lifunc: this.funcionariosSelecionados,
      liproj: this.projetosSelecionados,
      liccus: this.centroCustoSelecionados,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'rh/relatorio-ldb-rateio', infos)
      .subscribe(
        res => {
          this.httpService.done()
          if (res.error) {
            this.exibirMensagem(res)
            return
          }

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          }

          this.httpService.wait()
          this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/relatorio-ldb-rateio', httpOptions2)
            .subscribe(
              (res: Blob) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                link.href = url
                let fileName = "Relatorio_LDB.xlsx"
                link.download = fileName
                link.click()
                window.URL.revokeObjectURL(url)
                this.httpService.done()
              },
              err => {
                console.log(err)
                this.exibirMensagem({error: true, mensagem: "Erro no API ao baixar o relatório, contate o suporte: " + err.message})
                this.httpService.done()
              });
        },
        err => {
          console.log(err)
          this.exibirMensagem({error: true, mensagem: "Erro no API ao gerar o relatório, contate o suporte: " + err.message})
          this.httpService.done()
        });
  }

  gerarRelatorioDbPayroll() {
    if (this.dataRef === null || this.dataRef === undefined) {
      this.exibirMensagem({ error: true, mensagem: "A data de referência é inválida" });

    } else if (this.versaoSelecionada <= 0) {
      this.exibirMensagem({ error: true, mensagem: "Selecione uma versão para gerar o relatório." });

    } else {

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi
        })
      };

      const info = {
        empresa_id: this.dblinkedSessionService.empresa.id,
        versao_id: this.versaoSelecionada,
        dt_ref: new Date(this.dataRef.getFullYear(), this.dataRef.getMonth(), 1),
        user_id: this.sessionService.loggedUser.id
      }

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/relatorio-dbpayroll', info, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            if (!res["error"]) {
              const httpOptions2 = {
                headers: new HttpHeaders({
                  'Authorization': this.configService.bearerApi,
                  'Cache-Control': 'no-store, max-age=0',
                  'userId': this.sessionService.loggedUser.id.toString()
                }),

                responseType: 'blob' as 'json'
              };

              this.httpService.wait();
              this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/relatorio-dbpayroll', httpOptions2)
                .subscribe(
                  (res: Blob) => {
                    let link = document.createElement('a');
                    let url = window.URL.createObjectURL(res);
                    let hoje = new Date();
                    let mes_hoje = this.dataRef.getMonth() + 1;
                    let ano_hoje = this.dataRef.getFullYear();

                    link.href = url;
                    let fileName = `DBPayroll_${mes_hoje}-${ano_hoje}.xlsx`;
                    link.download = fileName;
                    link.click();

                    window.URL.revokeObjectURL(url);
                    this.httpService.done();
                  },
                  err => {
                    console.log(err);
                    this.exibirMensagem(err);
                    this.httpService.done();
                  });
            }
            this.httpService.done();
          },
          err => {
            console.log(err);
            this.exibirMensagem(err);
            this.httpService.done();
          });
    }
  }

  carregarValores(config) {
    let replicar = false;

    for (let dicConfig of this.dicRelatComposto[this.relatGerSelecionado.id]) {
      if (config['id'] === dicConfig['id']) replicar = true;

      if (replicar) {
        dicConfig['dataRef'] = config['dataRef'];
      }
    }
  }

  gerarRelatorioAfd() {
    if (this.dtFin < this.dtIni) {
      this.exibirMensagem({ error: true, mensagem: "A data inicial deve ser inferior à data final." });
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    const info = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      dt_ini: this.dtIni.getFullYear() + "-" +
        ((this.dtIni.getMonth() + 1) < 10 ? "0" + (this.dtIni.getMonth() + 1) : (this.dtIni.getMonth() + 1)) + "-" +
        (this.dtIni.getDate() < 10 ? "0" + this.dtIni.getDate() : this.dtIni.getDate()),
      dt_fin: this.dtFin.getFullYear() + "-" +
        ((this.dtFin.getMonth() + 1) < 10 ? "0" + (this.dtFin.getMonth() + 1) : (this.dtFin.getMonth() + 1)) + "-" +
        (this.dtFin.getDate() < 10 ? "0" + this.dtFin.getDate() : this.dtFin.getDate()),
      func: this.funcionariosSelecionados,
      contrato: this.tipoCargoSelecionado
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/export/relatorio-pontuario', info, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          if (!res["error"]) {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0'
              }),

              responseType: 'blob' as 'json'
            };

            this.httpService.wait();
            this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/export/relatorio-pontuario', httpOptions2)
              .subscribe(
                (res: Blob) => {
                  if (res["error"]) {
                    this.exibirMensagem(res)
                  } else {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(res);
                    link.download = `Relatório de apontamento.xlsx`;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                  }
                  this.httpService.done();
                },
                err => {
                  console.log(err);
                  this.exibirMensagem({ error: true, mensagem: "Erro ao baixar o relatório de apontamento, contate o suporte: " + err.message });
                  this.httpService.done();
                });
          }

          this.httpService.done();
        },
        err => {
          console.log(err);
          this.exibirMensagem({ error: true, mensagem: "Erro ao gerar o relatório de apontamento, contate o suporte: " + err.message });
          this.httpService.done();
        });
  }
}