import { CadProj } from 'app/contabil/model/cad-proj.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { PedidoItem } from 'app/financeiro/model/pedido-item.model';


export class PedidoItemRateio {
  id: number;
  pedidoItem: PedidoItem;
  cadProj: CadProj;
  cadCcus: CadCcus;
  porcentagem: number;

  constructor(pedidoItemRateio?: PedidoItemRateio | number) {
    if (pedidoItemRateio !== undefined && pedidoItemRateio !== null) {
      if (typeof pedidoItemRateio === 'number') {
        this.id = pedidoItemRateio;
      } else {
        this.id = pedidoItemRateio.id;
        this.pedidoItem = pedidoItemRateio.pedidoItem;
        this.cadProj = pedidoItemRateio.cadProj;
        this.cadCcus = pedidoItemRateio.cadCcus;
        this.porcentagem = pedidoItemRateio.porcentagem;
    }
  }
}
}
