import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../app.config';
import { Period } from '../../shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { TimezonePipe } from '../../shared/pipes/timezone.pipe';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { TipoDocumento } from 'app/util/model/tipo-documento.model';
import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { Departamento } from 'app/util/model/departamento.model';
import { Obrigacao } from 'app/centralweb/model/obrigacao.model';
import { Envio } from 'app/centralweb/model/envio.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-obrigacao',
  templateUrl: './obrigacao.component.html',
  styleUrls: ['./obrigacao.component.scss'],
  providers: [CrudService]
})
export class ObrigacaoComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  events: any[];

  recorrencias: Array<Recorrencia>;
  recorrenciasEditar: Array<Recorrencia>;
  departamentos: SelectItem[];
  documentos: any[];
  gatilhos: SelectItem[];
  responsaveis: SelectItem[];
  tiposArquivo: SelectItem[];
  codigosObrigacao: any[];
  opcoesusuarios: SelectItem[];
  usuarios: SelectItem[];
  qtdes: any[];
  dias: any[];
  tipoDocumento: any[];
  gatilhosEditar: SelectItem[];
  responsavelEditar: SelectItem[];
  empresas: Array<Empresa> = new Array();
  obrigacoes: Array<any> = new Array();
  calendar1: Array<any> = new Array();
  envios: Array<any> = new Array();
  empresasSelecionadas: Empresa[];
  obrigacao: Obrigacao;
  obrigacaoEditar: Obrigacao;

  tipoDocumentoSelecionado: TipoDocumento;
  exbirModalRecorrencia: boolean;
  exbirModalObrigacao: boolean;
  exibirModalEnvio: boolean;
  exibirModalHistoricoEnvio: boolean;
  dtIni: Date;
  qtdeOcorrencia: number;
  aCada: number;
  diaMes: number;
  dtFin: Date;
  isVencimentoDisabled: boolean;
  isRecorrenciaDisabled: boolean;
  isCancelarDisabled: boolean;

  file: File;
  files: Array<any>;

  opcoesCodigos: Array<SelectItem> = new Array();

  dom: boolean;
  seg: boolean;
  ter: boolean;
  qua: boolean;
  qui: boolean;
  sex: boolean;
  sab: boolean;
  antecipaDiaNaoUtil: boolean;
  diaUtil: boolean;
  diaUtilSabado: boolean;

  obrigSelecionada: any;

  btnDisabled: boolean;


  msgs: Message[];

  uploadedFiles: any[] = [];

  justificativa: string;
  mensagem: string;

  vlPrincipal: number;
  vlMulta: number;
  vlJuros: number;
  vlSaldoRecolher: number;

  vlSaldoCredor: number;

  vlImposto1: number;
  vlImposto2: number;
  vlImposto3: number;
  vlImposto4: number;

  envioExibir: Envio;

  usuario: Usuario = new Usuario();

  title: string;
  subtitle: string;

  constructor(private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent,
    private messageService: MessageService) {
  }

  ngOnInit() {
    this.titleService.title = 'CentralWeb';
    this.title = 'CONFIGURAR OBRIGAÇÕES';
    this.subtitle = 'CADASTRE, ALTERE OU EXCLUA AS OBRIGAÇÕES DESEJADAS';

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.obrigacao = new Obrigacao();
      this.obrigacao.usuario = new Usuario();
      this.obrigacao.recorrencia = new Recorrencia();
      this.obrigacao.tipoArquivo = new TipoArquivo();
      this.obrigacao.codigosObrigacoes = new CodigosObrigacoes();
      this.obrigacao.gatilhoObrigacao = new GatilhoObrigacao();

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.periodChanged(); });
  }

  periodChanged(): void {

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {

      this.files = new Array();

      this.obrigSelecionada = '';
      this.justificativa = '';
      this.mensagem = '';

      this.obrigacoes = new Array<Obrigacao>();
      this.empresas = new Array<Empresa>();
      this.obrigacoes = new Array<Obrigacao>();
      this.empresasSelecionadas = new Array<Empresa>();
      this.tipoDocumentoSelecionado = new TipoDocumento();

      this.calendar1 = new Array<any>();
      this.envios = new Array<any>();
      this.envioExibir = new Envio();
      this.envioExibir.usuario = new Usuario();

      this.obrigacao.departamento = new Departamento();
      this.obrigacaoEditar = new Obrigacao();
      this.obrigacaoEditar.codigosObrigacoes = new CodigosObrigacoes();
      this.obrigacaoEditar.recorrencia = new Recorrencia();
      this.obrigacaoEditar.tipoArquivo = new TipoArquivo();
      this.obrigacaoEditar.gatilhoObrigacao = new GatilhoObrigacao();


      this.tipoDocumento = [];
      this.recorrencias = new Array();
      this.recorrenciasEditar = new Array();
      this.exbirModalRecorrencia = false;
      this.exbirModalObrigacao = false;
      this.exibirModalEnvio = false;
      this.exibirModalHistoricoEnvio = false;
      this.qtdes = [];
      this.dias = [];
      this.gatilhosEditar = [];
      this.responsavelEditar = [];
      this.recorrenciasEditar = [];
      this.dtIni = new Date();
      this.dtFin = new Date();
      this.qtdeOcorrencia = 0;
      this.aCada = 1;
      this.diaMes = 1;

      this.dom = false;
      this.seg = false;
      this.ter = false;
      this.qua = false;
      this.qui = false;
      this.sex = false;
      this.sab = false;
      this.antecipaDiaNaoUtil = false;
      this.diaUtil = false;
      this.diaUtilSabado = false;



      this.dias.push({ label: 1, value: 1 });
      this.dias.push({ label: 2, value: 2 });
      this.dias.push({ label: 3, value: 3 });
      this.dias.push({ label: 4, value: 4 });
      this.dias.push({ label: 5, value: 5 });
      this.dias.push({ label: 6, value: 6 });
      this.dias.push({ label: 7, value: 7 });
      this.dias.push({ label: 8, value: 8 });
      this.dias.push({ label: 9, value: 9 });
      this.dias.push({ label: 10, value: 10 });
      this.dias.push({ label: 11, value: 11 });
      this.dias.push({ label: 12, value: 12 });
      this.dias.push({ label: 13, value: 13 });
      this.dias.push({ label: 14, value: 14 });
      this.dias.push({ label: 15, value: 15 });
      this.dias.push({ label: 16, value: 16 });
      this.dias.push({ label: 17, value: 17 });
      this.dias.push({ label: 18, value: 18 });
      this.dias.push({ label: 19, value: 19 });
      this.dias.push({ label: 20, value: 20 });
      this.dias.push({ label: 21, value: 21 });
      this.dias.push({ label: 22, value: 22 });
      this.dias.push({ label: 23, value: 23 });
      this.dias.push({ label: 24, value: 24 });
      this.dias.push({ label: 25, value: 25 });
      this.dias.push({ label: 26, value: 26 });
      this.dias.push({ label: 27, value: 27 });
      this.dias.push({ label: 28, value: 28 });
      this.dias.push({ label: 29, value: 29 });
      this.dias.push({ label: 30, value: 30 });
      this.dias.push({ label: 31, value: 31 });

      this.qtdes.push({ label: 1, value: 1 });
      this.qtdes.push({ label: 2, value: 2 });
      this.qtdes.push({ label: 3, value: 3 });
      this.qtdes.push({ label: 4, value: 4 });
      this.qtdes.push({ label: 5, value: 5 });
      this.qtdes.push({ label: 6, value: 6 });
      this.qtdes.push({ label: 7, value: 7 });
      this.qtdes.push({ label: 8, value: 8 });
      this.qtdes.push({ label: 9, value: 9 });
      this.qtdes.push({ label: 10, value: 10 });

      this.vlPrincipal = 0.00;
      this.vlMulta = 0.00;
      this.vlJuros = 0.00;
      this.vlSaldoRecolher = 0.00;
      this.vlSaldoCredor = 0.00;

      this.vlImposto1 = 0.00;
      this.vlImposto2 = 0.00;
      this.vlImposto3 = 0.00;
      this.vlImposto4 = 0.00;

      this.btnDisabled = true;

      this.httpService.wait();
      this.httpService.get('/tipoarquivo?$select=id,nome,descricao' +
        '&$orderby=nome')
        .subscribe(ref => this.loadTiposProjeto(ref.value),
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/departamento?$select=id,cod,descricao' +
        '&$orderby=cod')
        .subscribe(ref => this.loadDepartamentos(ref.value),
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/gatilhoobrigacao?$select=id,cod,descricao' +
        '&$orderby=cod')
        .subscribe(ref => this.loadGatilhos(ref.value),
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/recorrencia?$select=id,cod,descricao&$orderby=id')
        .subscribe(ref => this.loadRecorrencia(ref.value),
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.loadEmpresa();

    }

  }



  loadEmpresa() {

    this.empresas = this.dblinkedSessionService.empresas.filter(e => this.empresasSelecionadas.findIndex(es => es.id === e.id) === -1);
  }




  loadRecorrencia(value: Array<Recorrencia>) {

    this.recorrencias = new Array();
    this.recorrenciasEditar = new Array();
    value.forEach(element => {

      this.recorrencias.push(element);
      if (element.id !== 1) {
        this.recorrenciasEditar.push(element);
      }

    });
  }


  loadTiposProjeto(value: Array<TipoArquivo>) {
    this.tiposArquivo = new Array();
    this.tiposArquivo.push({ label: 'Selecione Tipo de Arquivo', value: null });
    value.forEach(element => {

      this.tiposArquivo.push({
        label: element.descricao,
        value: element.id
      });
    });
  }

  loadDepartamentos(value: Array<Departamento>) {
    this.departamentos = new Array();
    this.departamentos.push({ label: 'Selecione um Departamento', value: null });
    value.forEach(element => {

      this.departamentos.push({
        label: element.descricao,
        value: element.id
      });
    });
  }

  loadTipoDocumentos(value: Array<any>) {

    this.tipoDocumento = [];
    value.forEach(element => {

      this.tipoDocumento.push({ label: element.cod, value: element.id });


    });


  }

  loadGatilhos(value: Array<any>) {
    this.gatilhos = new Array();
    this.gatilhosEditar = new Array();
    this.gatilhos.push({ label: 'Selecione Avisos', value: null });
    value.forEach(element => {

      this.gatilhos.push({
        label: element.descricao,
        value: element.id
      });

      this.gatilhosEditar.push({
        label: element.descricao,
        value: element.id
      });
    });
  }

  loadResponsavel(value: Array<Usuario>) {
    this.responsaveis = new Array();
    this.responsavelEditar.push({ label: 'Selecione um Responsável', value: null });
    this.responsavelEditar = [];
    value.forEach(element => {

      this.responsavelEditar.push({
        label: element.nome + ' - ' + element.mail,
        value: element.id
      });
    });
  }

  carregarObrigacao(id: any) {
    console.log(id);
    this.httpService.wait();
    this.httpService.get('/codigosobrigacoes?$select=id,cod,descricao' +
      '&$filter=tipoArquivo/id eq ' + id.value +
      '&$orderby=cod')
      .subscribe(ref => this.loadCodigosObrigacao(ref.value),
        error => this.httpService.handleError(error, () => this.carregarObrigacao(id)),
        () => this.httpService.done());
  }

  carregarUsuarios(id: any) {

    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-users-depto(' + id.value + ',' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(usuarios => this.loadUsuarios(usuarios),
        error => this.httpService.handleError(error, () => this.tabChange(2)),
        () => this.httpService.done());
  }

  modalRecorrencia() {
    this.exbirModalRecorrencia = true;
  }

  criarObrigacao() {

    const listasEnviar = {
      recorrencia: this.obrigacao.recorrencia.id,
      tipoArquivo: this.obrigacao.tipoArquivo.id,
      codigosObrigacoes: this.obrigacao.codigosObrigacoes.id,
      departamento: this.obrigacao.departamento.id,
      usuario: this.dblinkedSessionService.usuario.id,
      dtIni: this.dtIni,
      dtFin: this.dtFin,
      aCada: this.aCada,
      dom: this.dom,
      seg: this.seg,
      ter: this.ter,
      qua: this.qua,
      qui: this.qui,
      sex: this.sex,
      sab: this.sab,
      qtdeOcorrencia: this.qtdeOcorrencia,
      diaMes: this.diaMes,
      antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
      diaUtil: this.diaUtil,
      diaUtilSabado: this.diaUtilSabado,
      empresas: this.empresasSelecionadas,
      gatilhoObrigacao: this.obrigacao.gatilhoObrigacao.id,
      usuarioResponsavel: this.obrigacao.usuario.id
    };
    console.log(listasEnviar);
    this.httpService.wait();
    this.httpService.post('/custom/centralweb/cadastrar-obrigacao', listasEnviar)
      .subscribe(result => this.sucessoCadastro(result),
        error => this.httpService.handleError(error, () => this.criarObrigacao()),
        () => this.httpService.done());


  }

  sucessoCadastro(result: any) {

    if (result.error === true) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Erro', detail: result.message });
      this.periodChanged();
    } else {
      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: result.message });
      this.periodChanged();
    }

  }

  atualizarRecorrencia() {
    if (confirm('Todas as recorrências seguintes desta obrigação serão alteradas, deseja continuar?')) {
      console.log(this.obrigacao);
      console.log(this.obrigacao.gatilhoObrigacao);
      const listasEnviar = {
        obrigacao: this.obrigacao,
        recorrencia: this.obrigacaoEditar.recorrencia.id,
        usuario: this.dblinkedSessionService.usuario.id,
        dtIni: this.dtIni,
        dtFin: this.dtFin,
        aCada: this.aCada,
        dom: this.dom,
        seg: this.seg,
        ter: this.ter,
        qua: this.qua,
        qui: this.qui,
        sex: this.sex,
        sab: this.sab,
        qtdeOcorrencia: (isNullOrUndefined( this.qtdeOcorrencia) ? 0 : this.qtdeOcorrencia),
        diaMes: this.diaMes,
        antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
        diaUtil: this.diaUtil,
        diaUtilSabado: this.diaUtilSabado,
        obrigacaoAnterior: this.obrigacaoEditar.id,
        gatilhoObrigacao: this.obrigacao.gatilhoObrigacao,
        //usuarioResponsavel: this.obrigacao.usuario.id

      };

      this.httpService.wait();
      this.httpService.post('/custom/centralweb/atualizar-recorrencias', listasEnviar)
        .subscribe(result => {
            return this.tabChange(1);
        },
          error => this.httpService.handleError(error, () => this.criarObrigacao()),
          () => this.httpService.done());

    }

  }

  atualizarObrigacao() {

    const listasEnviar = {
      vencimento: this.dtIni,
      id: this.obrigacaoEditar.id,
      gatilhoObrigacao: this.obrigacao.codigosObrigacoes.id

    };
    this.httpService.wait();
    this.httpService.post('/custom/centralweb/atualizar-obrigacao-unico', listasEnviar)
      .subscribe(result => {

        if (result.error) {
          this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Erro:', detail: result.message });
        } else {
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso:', detail: result.message });
        }

        this.tabChange(1);
      },
        error => this.httpService.handleError(error, () => this.atualizarObrigacao()),
        () => this.httpService.done());


  }

  loadCodigosObrigacao(value: Array<any>) {
    this.opcoesCodigos = new Array();
    this.opcoesCodigos.push({ label: 'Selecione uma Obrigação', value: null });
    value.forEach(element => {
      this.opcoesCodigos.push({
        label: element.cod + ' - ' + element.descricao,
        value: element.id
      });
    });
  }

  loadUsuarios(value: Array<Usuario>) {
    this.opcoesusuarios = new Array();
    this.opcoesusuarios.push({ label: 'Selecione um Responsável', value: null });
    console.log(value);
    value.forEach(element => {
      this.opcoesusuarios.push({
        label: element.nome + ' - ' + element.mail,
        value: element.id
      });
    });
  }


  ngOnDestroy() {

    this.toolbarService.hidden = false;

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }


  tabChange(event: any) {

    this.exbirModalObrigacao = false;
    this.exibirModalEnvio = false;
    this.calendar1 = new Array();


    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-empresa-edicao(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(formalizacoes => this.loadObrigacoes(formalizacoes),
        error => this.httpService.handleError(error, () => this.tabChange(2)),
        () => this.httpService.done());


    if (event.index === 2) {
      this.httpService.wait();
      this.httpService.get('/custom/centralweb/calendar(' + (this.dblinkedSessionService.periodo.month.value - 1).toString() + ',' +
        this.dblinkedSessionService.periodo.year.value.toString() + ',' + this.dblinkedSessionService.empresa.id + ')')
        .subscribe(formalizacoes => this.loadCalendar1(formalizacoes),
          error => this.httpService.handleError(error, () => this.tabChange(2)),
          () => this.httpService.done());

    }

    if (event.index === 3) {
      this.httpService.wait();
      this.httpService.get('/custom/centralweb/get-envios(' + (this.dblinkedSessionService.periodo.year.value) + ',' +
        (this.dblinkedSessionService.periodo.month.value) + ',' + this.dblinkedSessionService.empresa.id + ')')
        .subscribe(formalizacoes => this.loadEnvios(formalizacoes),
          error => this.httpService.handleError(error, () => this.tabChange(2)),
          () => this.httpService.done());

    }



  }

  loadCalendar1(value: Array<any>) {
    this.calendar1 = new Array();
    value.forEach(element => {
      this.calendar1.push(element);
    });

  }

  loadEnvios(value: Array<any>) {
    this.envios = new Array();
    value.forEach(element => {
      this.envios.push(element);
    });
  }

  loadObrigacoes(value: Array<Obrigacao>) {
    this.obrigacoes = new Array();
    this.opcoesCodigos.push({ label: 'Selecione uma Obrigação', value: null });
    value.forEach(element => {
      this.obrigacoes.push(element);
    });
  }

  onRowSelect(event: any) {

    this.isVencimentoDisabled = true;
    this.isRecorrenciaDisabled = false;
    this.isCancelarDisabled = true;
    this.httpService.wait();
    this.httpService.get('/obrigacao?$select=id,tipoArquivo,codigosObrigacoes,recorrencia,departamento,dtVencto,gatilhoObrigacao' +
      '&$filter=id eq ' + event.data.obrigId +
      '&$orderby=dtVencto')
      .subscribe(ref => this.loadObrigEditar(ref.value),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());






  }

  loadObrigEditar(value: Obrigacao) {
    this.obrigacaoEditar = value[0];
    this.exbirModalObrigacao = true;
  }

  onRowSelectEnvio(event: any) {

    this.httpService.wait();
    this.httpService.get('/envio?$select=id,dtEnvio,authUserEnvio,authUserEnvio/displayName,vlPrincipal,vlMulta,vlJuros,vlSaldoRecolher,' +
      'vlSaldoCredor,vlImposto1,vlImposto2,vlImposto3,vlImposto4,tipoDocumento/cod,justificativa,' +
      'mensagem,obrigacao/codigosObrigacoes/cod,obrigacao/tipoArquivo/id' +
      '&$filter=id eq ' + event.data.envioId)
      .subscribe(ref => this.loadEnvio(ref.value),
        error => this.httpService.handleError(error, () => this.onRowSelectEnvio(event)),
        () => this.httpService.done());
  }

  loadEnvio(value: any) {

    this.exibirModalHistoricoEnvio = true;
    this.envioExibir = value[0];
  }

  deletarObrigacao(id: number) {
    if (confirm('Deseja realmente remover esta obrigação?')) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/deletarobrigacao(' + id + ')')
        .subscribe(result => {

          if (result.error) {
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Erro:', detail: result.message });
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso:', detail: result.message });
          }

          this.tabChange(1);
        },
          error => this.httpService.handleError(error, () => this.deletarObrigacao(id)),
          () => this.httpService.done());
    }
  }

  deletarObrigacoes(id: number) {
    if (confirm('Deseja realmente remover esta obrigação e todas as seguintes?')) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/deletarobrigacoes(' + id + ')')
        .subscribe(result => {
          this.tabChange(1);


          result.forEach(element => {
            if (element.error) {
              this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: element.message });
            } else {
              this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso:', detail: element.message });
            }
          });
        },
          error => this.httpService.handleError(error, () => this.deletarObrigacoes(id)),
          () => this.httpService.done());
    }
  }

  alertObrig(event: any) {
    this.httpService.wait();
    this.httpService.get('/tipodocumento?$select=id,cod,descricao' +
      '&$orderby=cod')
      .subscribe(ref => this.loadTipoDocumentos(ref.value),
        error => this.httpService.handleError(error, () => this.alertObrig(event)),
        () => this.httpService.done());

    this.obrigSelecionada = event;


    this.exibirModalEnvio = true;
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];

  }



  importar() {

    if (this.file === undefined) {
      alert('Selecione um arquivo para anexar');
    } else {
      const data: FormData = new FormData();
      data.append('file', this.file);
      data.append('type', 'cw');
      data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
      data.append('obrigacaoId', this.obrigSelecionada.obrigId.toString());

      this.httpService.wait();
      this.httpService.post('/custom/upload', data)
        .subscribe(result => this.uploaded(result),
          error => this.httpService.handleError(error, () => this.importar()),
          () => this.httpService.done());
    }
  }

  private uploaded(result?: any) {
    const data: FormData = new FormData();
    data.append('obrigacaoId', this.obrigSelecionada.obrigId.toString());

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/get-files-to-send', data)
      .subscribe(formalizacoes => this.loadFiles(formalizacoes),
        error => this.httpService.handleError(error, () => this.uploaded(result)),
        () => this.httpService.done());

    /*this.messageService.add({
       severity: result.error ? 'error' : 'success',
       detail: result.message || 'Upload efetuado com sucesso!'
     }];*/
  }

  private sent(result?: any) {
    this.messageService.add({
      severity: result.error ? 'error' : 'success',
      detail: result.message || 'Obrigação Enviada com Sucesso!'
    });
  }

  calcSaldoRecolher() {
    this.vlSaldoRecolher = this.utilService.parseDecimal(this.vlPrincipal) +
      this.utilService.parseDecimal(this.vlMulta) + this.utilService.parseDecimal(this.vlJuros);
  }

  cancelarEnvio() {
    this.exbirModalObrigacao = false;
    this.exibirModalEnvio = false;
    this.mensagem = '';
    this.justificativa = '';
    this.files = new Array();
    this.tipoDocumentoSelecionado = new TipoDocumento();
    this.vlPrincipal = 0.00;
    this.vlMulta = 0.00;
    this.vlJuros = 0.00;
    this.vlSaldoRecolher = 0.00;
    this.vlSaldoCredor = 0.00;

    this.vlImposto1 = 0.00;
    this.vlImposto2 = 0.00;
    this.vlImposto3 = 0.00;
    this.vlImposto4 = 0.00;
  }

  loadFiles(event: any) {
    this.files = event;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {
    this.file = null;

  }

  onTabChanged(event: any) {
    console.log(event);
    console.log(event.index);
    if (event.index === 0) {
      this.isVencimentoDisabled = true;
      this.isRecorrenciaDisabled = false;
      this.isCancelarDisabled = true;
    }

    if (event.index === 1) {
      this.isVencimentoDisabled = false;
      this.isRecorrenciaDisabled = true;
      this.isCancelarDisabled = true;

    }

    if (event.index === 2) {
      this.isVencimentoDisabled = false;
      this.isRecorrenciaDisabled = false;
      this.isCancelarDisabled = true;

    }

  }
}
