import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TabChanger } from '../interfaces/dash-changer'

@Component({
  selector: 'app-dash-changer[options][format]',
  templateUrl: './dash-changer.component.html',
  styleUrls: ['./dash-changer.component.scss']
})
export class DashChangerComponent {

  @Input() options: Array<TabChanger> = new Array()
  @Input() format: 'chip' | 'text'

  @Output() tabSelected = new EventEmitter()

  public selectTab(period: string): void {
    this.options.forEach(item => {
      item.active = false
      if(item.value === period) {
        item.active = true
      }
    })

    this.tabSelected.emit(period)
  }
}
