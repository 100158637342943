import { AuthUser } from './auth-user.model';
import { AuthApp } from './auth-app.model';

export class AuthCrowd {
  id: number;
  name: string;
  description: string;
  active = true;
  authUsers: Array<AuthUser> = new Array();
  authApps: Array<AuthApp> = new Array();

  constructor(authCrowd?: AuthCrowd | number) {
    if (authCrowd !== undefined && authCrowd !== null) {
      if (typeof authCrowd === 'number') {
        this.id = authCrowd;
      } else {
        this.id = authCrowd.id;
        this.name = authCrowd.name;
        this.description = authCrowd.description;
        this.active = authCrowd.active;
        this.authUsers = authCrowd.authUsers || new Array();
        this.authApps = authCrowd.authApps || new Array();
      }
    }
  }
}
