import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { SelectItem, Message, MessageService } from 'primeng/api';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { UtilService } from 'app/shared/services/util.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { TitleService } from 'app/shared/services/title.service';
import { LoginService } from 'app/shared/services/login.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Util } from 'app/shared/common/util';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-empresa-parametro-contabil',
  templateUrl: './empresa-parametro-contabil.component.html',
  styleUrls: ['./empresa-parametro-contabil.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ],

})

export class EmpresaParametroContabilComponent implements OnInit, OnDestroy {
  dataIni: Date;
  dataFin: Date;
  planoContasSelecionado: PlanoContas;

  telaConfiguracao: boolean = false;
  ccClienteNacional: any;
  ccClienteNacionalRelac: any;
  ccClienteNacionalAnalitica: any = false;
  ccClienteInternacional: any;
  ccClienteInternacionalRelac: any;
  ccClienteInternacionalAnalitica: any = false;
  ccFornecedor: any;
  ccFornecedorRelac: any;
  ccFornecedorAnalitica: any = false;
  ccFornecedorInternacional: any;
  ccFornecedorInternacionalRelac: any;
  ccFornecedorInternacionalAnalitica: any = false;
  ccFinanceiro: any;
  ccFinanceiroRelac: any;
  ccFinanceiroAnalitica: any = false;
  verificarConciliacao: any = false;
  alterarRateio: any = false;
  currentId: any = null;

  /* campos validação Plano de Contas*/
  codPlanoContasPadrao: any = false;
  minimoAnalitica: number;
  digitosNivel1: number;
  digitosNivel2: number;
  digitosNivel3: number;
  digitosNivel4: number;
  digitosNivel5: number;
  digitosNivel6: number;
  iniCodNat1: any;
  iniCodNat2: any;
  iniCodNat3: any;
  iniCodNat4: any;
  respContabil:any;

  niveisContasDre: string = null
  niveisBalancoPatrimonial: string = null
  contaEncerramentoResultadoSelecionada: number
  planosContas: Array<PlanoContas> = new Array()
  opcoesPlanoContasResultado: SelectItem[]


  /* Final campos validação Plano de Contas*/

  private sessionSubscription: Subscription;
  opcoesPlanoContas: SelectItem[];
  opcoesPlanoContasRfb: SelectItem[];

  empresaParametroContabil: Array<any> = new Array();

  pt: any;
  title: string;
  subtitle: string;

  msgs: Message[];

  planoContasEnviar: number[];
  defaultDate: Date;
  validaCampos: boolean;
  primeiroDiadoMes: Date;
  ultimoDiadiaMes: Date;
  centroCustoObrigatorio: boolean;
  centroCustoId: number;

  colsParametroContabil: any[];

  currentStateResultadosParametrosContabeis = 'initial';

  editarParametros: boolean = false;

  // Variáveis usadas na recorrência de lançamentos
  meses: any;

  visualizarContaAutomatica: boolean;

  constructor(private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private loginService: LoginService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.title = 'Parâmetro Contábil';
    this.subtitle = 'Informações e conexões de Empresa Parâmetro Contábil';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.defaultDate = new Date();

    this.primeiroDiadoMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth(), 1);
    this.ultimoDiadiaMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);

    this.dataIni = this.primeiroDiadoMes;
    this.dataFin = this.ultimoDiadiaMes;

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => this.carregarDados());

    this.colsParametroContabil = [
      { field: 'ccClienteNacional', header: 'CONTA CONTÁBIL CLIENTE NACIONAL' },
      { field: 'ccFornecedorNacional', header: 'CONTA CONTÁBIL FORNECEDOR NACIONAL' },
      { field: 'ccFinanceiro', header: 'CONTA CONTÁBIL FINANCEIRO' },
      { field: 'verificarConciliacaoStr', header: 'VERIFICA CONCILIAÇÃO' }
    ]

    this.meses = [];
    this.meses.push({ label: "SELECIONE", value: null });
    for (let mes = 1; mes <= 12; mes++) {
      this.meses.push({ label: mes, value: mes });
    }

    this.toolbarService.clear();
  }

  // Função que carrega os dados da tela inicial
  async carregarDados() {
    if (!this.dblinkedSessionService.hasEmpresaSelected || !this.dblinkedSessionService.hasYearSelected) {
      return;
    }

    this.opcoesPlanoContasResultado = [];
    this.opcoesPlanoContasResultado.push({ label: 'Selecione uma conta de Resultado', value: null });

    this.telaConfiguracao = false;

    if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
      const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
        this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
      const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
      this.defaultDate = new Date(dt);
      this.primeiroDiadoMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth(), 1);
      this.ultimoDiadiaMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
      this.dataIni = this.primeiroDiadoMes;
      this.dataFin = this.ultimoDiadiaMes;
    }

    this.empresaParametroContabil = [];

    if (this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id) {
      await this.getPlanosContas()
      await this.getPlanoContasRfb()
      this.carregarDadosParametroContabil()
    }
  }

  hideMessage() {
    this.msgs = null;
  }


  /* A função abaixo controla a abertura e o fechamento do card
  usado na tela inicial */
  changeStateResultadosParametrosContabeis() {
    this.currentStateResultadosParametrosContabeis = this.currentStateResultadosParametrosContabeis === 'initial' ? 'final' : 'initial';
  }

  /* Show/Hide Card Resultados Parâmetros Contábeis */
  isCardResultadosParametrosContabeis: boolean = true;
  showHideCardResultadosParametrosContabeis() {
    this.isCardResultadosParametrosContabeis = !this.isCardResultadosParametrosContabeis;
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionSubscription.remove(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  onFilter() {
  }

  // Função que abre a tela de edição de um registro e configura suas variáveis
  editarParametroContabil(rowData: any) {

    this.currentId = rowData.id;
    this.ccClienteNacional = rowData.ccClienteNacional;
    this.ccClienteInternacional = rowData.ccClienteInternacional;
    this.ccFornecedor = rowData.ccFornecedorNacional;
    this.ccFornecedorInternacional = rowData.ccFornecedorInternacional;
    this.ccFinanceiro = rowData.ccFinanceiro;
    this.ccClienteNacionalRelac = rowData.ccClienteNacionalRelac;
    this.ccClienteInternacionalRelac = rowData.ccClienteInternacionalRelac;
    this.ccFornecedorRelac = rowData.ccFornecedorNacionalRelac;
    this.ccFornecedorInternacionalRelac = rowData.ccFornecedorInternacionalRelac;
    this.ccFinanceiroRelac = rowData.ccFinanceiroRelac;


    this.codPlanoContasPadrao = rowData.codigo_plano_contas_padrao === 'SIM' ? true : false;
    this.minimoAnalitica = rowData.nivel_minimo_analitica;
    this.digitosNivel1 =  rowData.digitos_nivel_1;
    this.digitosNivel2 =  rowData.digitos_nivel_2;
    this.digitosNivel3 =  rowData.digitos_nivel_3;
    this.digitosNivel4 =  rowData.digitos_nivel_4;
    this.digitosNivel5 =  rowData.digitos_nivel_5;
    this.digitosNivel6 =  rowData.digitos_nivel_6;
    this.iniCodNat1    =  rowData.validacao_natureza_1;
    this.iniCodNat2    =  rowData.validacao_natureza_2;
    this.iniCodNat3    =  rowData.validacao_natureza_3;
    this.iniCodNat4    =  rowData.validacao_natureza_4;
    this.respContabil  =  rowData.respContabil;
    this.visualizarContaAutomatica = rowData.visualizarContaAutomatica;


    this.telaConfiguracao = true;
    this.editarParametros = true;

    if (rowData.verificarConciliacao == true) {
      this.verificarConciliacao = true;
    } else {
      this.verificarConciliacao = false;
    }

    if (rowData.alterarRateio == true) {
      this.alterarRateio = true;
    } else {
      this.alterarRateio = false;
    }

    this.niveisContasDre = rowData.niveisContasDre
    this.niveisBalancoPatrimonial = rowData.niveisBalancoPatrimonial
    this.contaEncerramentoResultadoSelecionada = rowData.contaEncerramentoResultadoId

    /* Atualizar os status dos Dropdowns à esquerda, indicando se as contas
    selecionadas são analíticas ou não. */
    this.checarContaAnalitica(this.ccClienteNacional, 1);
    this.checarContaAnalitica(this.ccClienteInternacional, 2);
    this.checarContaAnalitica(this.ccFornecedor, 3);
    this.checarContaAnalitica(this.ccFornecedorInternacional, 4);
    this.checarContaAnalitica(this.ccFinanceiro, 5);

  }

  /* Função que exclui um registro da tabela cadastro.empresa_parametro_contabil
  e suas conexões na tabela contabil.plano_contas_ref_relac */
  removerParametroContabil(rowData: any) {
    if (confirm('Deseja remover esse registro?')) {

      this.msgs = [];

      this.currentId = rowData.id;
      this.ccClienteNacional = rowData.ccClienteNacional;
      this.ccClienteInternacional = rowData.ccClienteInternacional;
      this.ccFornecedor = rowData.ccFornecedorNacional;
      this.ccFornecedorInternacional = rowData.ccFornecedorInternacional;
      this.ccFinanceiro = rowData.ccFinanceiro;
      this.ccClienteNacionalRelac = rowData.ccClienteNacionalRelac;
      this.ccClienteInternacionalRelac = rowData.ccClienteInternacionalRelac;
      this.ccFornecedorRelac = rowData.ccFornecedorNacionalRelac;
      this.ccFornecedorInternacionalRelac = rowData.ccFornecedorInternacionalRelac;
      this.ccFinanceiroRelac = rowData.ccFinanceiroRelac;

      /* Verificar se as contas selecionadas nos Dropdowns da esquerda são analíticas. */
      this.checarContaAnalitica(this.ccClienteNacional, 1);
      this.checarContaAnalitica(this.ccClienteInternacional, 2);
      this.checarContaAnalitica(this.ccFornecedor, 3);
      this.checarContaAnalitica(this.ccFornecedorInternacional, 4);
      this.checarContaAnalitica(this.ccFinanceiro, 5);

      this.httpService.wait();
      this.httpService.get('/custom/cadastro/remover-dados-empresa-parametro-contabil(' + rowData.id + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {

              // Chamar a função que remove as relações do Plano Contas ligadas ao registro excluído
              this.removerConexoesPlanoContas();
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  removerConexoesPlanoContas() {
    let dadosConexoes = {
      ccClienteNacional: this.ccClienteNacional,
      ccClienteInternacional: this.ccClienteInternacional,
      ccFornecedor: this.ccFornecedor,
      ccFornecedorInternacional: this.ccFornecedorInternacional,
      ccFinanceiro: this.ccFinanceiro,
      ccClienteNacionalRelac: this.ccClienteNacionalRelac,
      ccClienteInternacionalRelac: this.ccClienteInternacionalRelac,
      ccFornecedorRelac: this.ccFornecedorRelac,
      ccFornecedorInternacionalRelac: this.ccFornecedorInternacionalRelac,
      ccFinanceiroRelac: this.ccFinanceiroRelac,
      ccClienteNacionalAnalitica: this.ccClienteNacionalAnalitica,
      ccClienteInternacionalAnalitica: this.ccClienteInternacionalAnalitica,
      ccFornecedorAnalitica: this.ccFornecedorAnalitica,
      ccFornecedorInternacionalAnalitica: this.ccFornecedorInternacionalAnalitica,
      ccFinanceiroAnalitica: this.ccFinanceiroAnalitica
    }

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/remover-conexoes-plano-contas', dadosConexoes)
      .subscribe(
        res => {
          if (res["error"] == false) {
            this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
          } else {
            this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: res["message"] });
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );

    /* Ao término da execução, carregar os dados atualizados
    na tela inicial */
    this.carregarDados();
    this.voltarTelaInicialParametroContabil();
  }

  // Função que abre a tela de adição de um registro e configura suas variáveis
  addItem() {
    this.telaConfiguracao = true;

    // Os valores dos dropdowns à direita também deverão ser resetados
    this.ccClienteNacionalRelac = null;
    this.ccClienteInternacionalRelac = null;
    this.ccFornecedorRelac = null;
    this.ccFornecedorInternacionalRelac = null;
    this.ccFinanceiroRelac = null;

    // Os status das contas selecionadas à esquerda (analíticas ou não) também deverão ser resetados
    this.ccClienteNacionalAnalitica = false;
    this.ccClienteInternacionalAnalitica = false;
    this.ccFornecedorAnalitica = false;
    this.ccFornecedorInternacionalAnalitica = false;
    this.ccFinanceiroAnalitica = false;
  }

  /* Função que retorna à tela inicial e configura as variáveis envolvidas,
  resetando alguns valores */
  voltarTelaInicialParametroContabil() {
    this.ccClienteNacional = null;
    this.ccClienteInternacional = null;
    this.ccFornecedor = null;
    this.ccFornecedorInternacional = null;
    this.ccFinanceiro = null;
    this.verificarConciliacao = false;
    this.alterarRateio = false;

    // Os valores dos dropdowns à direita também deverão ser resetados
    this.ccClienteNacionalRelac = null;
    this.ccClienteInternacionalRelac = null;
    this.ccFornecedorRelac = null;
    this.ccFornecedorInternacionalRelac = null;
    this.ccFinanceiroRelac = null;

    // Os status das contas selecionadas à esquerda (analíticas ou não) também deverão ser resetados
    this.ccClienteNacionalAnalitica = false;
    this.ccClienteInternacionalAnalitica = false;
    this.ccFornecedorAnalitica = false;
    this.ccFornecedorInternacionalAnalitica = false;
    this.ccFinanceiroAnalitica = false;

    this.telaConfiguracao = false;
    this.editarParametros = null;
    this.currentId = null;

    this.niveisContasDre = null
    this.niveisBalancoPatrimonial = null
    this.contaEncerramentoResultadoSelecionada = null
  }

  /* Função que grava um novo registro ou atualiza um já existente
  na tabela cadastro.empresa_parametro_contabil */
  salvarParametroContabil() {

    this.msgs = [];

    if (this.respContabil) {
      if (this.respContabil.indexOf(";") != -1) {
        var splitted = this.respContabil.split(";")
        let invalido = false

        splitted.forEach(element => {
          if (!Util.validacaoEmail(element.trim())) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'O e-mail ' + element + ' é inválido!' })
            invalido = true
          }
        })
        if (invalido) return

      } else {
        if (!Util.validacaoEmail(this.respContabil.trim())) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'O e-mail ' + this.respContabil + ' é inválido!' })
          return false
        }
      }
    }

    let niveisContasDreValid = true
    let splittedNiveisContasDre = this.niveisContasDre.split(";")
    splittedNiveisContasDre.forEach(element => {
      if (element === '') {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: "Níveis de contas para o DRE inválidos! Separe cada nível por ';' e tente novamente" })
        niveisContasDreValid = false
        return
      }

      let convertedToNumberElement = null
      try {
        convertedToNumberElement = Number(element)
      } catch (exception) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: "Níveis de contas para o DRE inválidos! Os níveis de contas devem ser números!" })
        niveisContasDreValid = false
        return
      }

      if (isNaN(convertedToNumberElement) || convertedToNumberElement == null) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: "Níveis de contas para o DRE inválidos! Os níveis de contas devem ser números!" })
        niveisContasDreValid = false
        return
      }
    })

    if (!niveisContasDreValid) return

    let niveisBalancoPatrimonialValid = true
    let splittedNiveisBalancoPatrimonial = this.niveisBalancoPatrimonial.split(";")
    splittedNiveisBalancoPatrimonial.forEach(element => {
      if (element === '') {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: "Níveis de contas para o Balanço Patrimonial inválidos! Separe cada nível por ';' e tente novamente" })
        niveisBalancoPatrimonialValid = false
        return
      }

      let convertedToNumberElement = null
      try {
        convertedToNumberElement = Number(element)
      } catch (exception) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: "Níveis de contas para o Balanço Patrimonial inválidos! Os níveis de contas devem ser números!" })
        niveisBalancoPatrimonialValid = false
        return
      }

      if (isNaN(convertedToNumberElement) || convertedToNumberElement == null) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: "Níveis de contas para o Balanço Patrimonial inválidos! Os níveis de contas devem ser números!" })
        niveisBalancoPatrimonialValid = false
        return
      }
    })

    if (!niveisBalancoPatrimonialValid) return

    // Realizar a validação para os campos da coluna à esquerda da tela
    if (this.ccClienteNacional == null || this.ccClienteNacional == undefined
      || this.ccClienteNacional == "") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: 'Preencha com um valor a Conta Contábil Cliente Nacional.' });
    } else if (this.ccClienteInternacional == null || this.ccClienteInternacional == undefined
      || this.ccClienteInternacional == "") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: 'Preencha com um valor a Conta Contábil Cliente Internacional.' });
    } else if (this.ccFornecedor == null || this.ccFornecedor == undefined
      || this.ccFornecedor == "") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: 'Preencha com um valor a Conta Contábil Fornecedor.' });
    } else if (this.ccFornecedorInternacional == null || this.ccFornecedorInternacional == undefined
      || this.ccFornecedorInternacional == "") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: 'Preencha com um valor a Conta Contábil Fornecedor Internacional.' });
    } else if (this.ccFinanceiro == null || this.ccFinanceiro == undefined
      || this.ccFinanceiro == "") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: 'Preencha com um valor a Conta Contábil Financeiro.' });
    } else {

      /* Caso as validações sejam bem-sucedidas, verificar se a operação é de adição ou
      modificação de registro. Isso é feito a partir do valor de this.editarParametros */
      let dados = {
        ccClienteNacional: this.ccClienteNacional,
        ccClienteInternacional: this.ccClienteInternacional,
        ccFornecedor: this.ccFornecedor,
        ccFornecedorInternacional: this.ccFornecedorInternacional,
        ccFinanceiro: this.ccFinanceiro,
        ccClienteNacionalRelac: this.ccClienteNacionalRelac,
        ccClienteInternacionalRelac: this.ccClienteInternacionalRelac,
        ccFornecedorRelac: this.ccFornecedorRelac,
        ccFornecedorInternacionalRelac: this.ccFornecedorInternacionalRelac,
        ccFinanceiroRelac: this.ccFinanceiroRelac,
        id: this.currentId,
        verificarConciliacao: this.verificarConciliacao,
        alterarRateio: this.alterarRateio,
        usuarioId: this.sessionService.loggedUser.id,
        empresaId: this.dblinkedSessionService.empresa.id,
        ccClienteNacionalAnalitica: this.ccClienteNacionalAnalitica,
        ccClienteInternacionalAnalitica: this.ccClienteInternacionalAnalitica,
        ccFornecedorAnalitica: this.ccFornecedorAnalitica,
        ccFornecedorInternacionalAnalitica: this.ccFornecedorInternacionalAnalitica,
        ccFinanceiroAnalitica: this.ccFinanceiroAnalitica,
        codPlanoContasPadrao: this.codPlanoContasPadrao,
        minimoAnalitica: this.minimoAnalitica,
        digitosNivel1: this.digitosNivel1,
        digitosNivel2: this.digitosNivel2,
        digitosNivel3: this.digitosNivel3,
        digitosNivel4: this.digitosNivel4,
        digitosNivel5: this.digitosNivel5,
        digitosNivel6: this.digitosNivel6,
        iniCodNat1: this.iniCodNat1,
        iniCodNat2: this.iniCodNat2,
        iniCodNat3: this.iniCodNat3,
        iniCodNat4: this.iniCodNat4,
        respContabil: this.respContabil,
        visualizarContaAutomatica: this.visualizarContaAutomatica,
        nivelContaDre: this.niveisContasDre,
        nivelContaBalancoPatrimonial: this.niveisBalancoPatrimonial,
        contaEncerramentoResultadoId: this.contaEncerramentoResultadoSelecionada
      }

      // Editando um registro existente
      if (this.editarParametros == true) {

        /* Inicialmente, deve-se realizar a atualização das conexões dos Plano Contas na
        tabela contabil.plano_contas_ref_relac. */
        this.httpService.wait();
        this.httpService.post('/custom/cadastro/editar-conexoes-plano-contas', dados)
          .subscribe(
            res => {
              if (res["error"] != false) {
                this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: res["message"] });
              }
              this.httpService.done();
            },
            err => {
              console.log(err);
              this.httpService.done();
            }
          );

        // Na sequência, editar o registro selecionado da tabela cadastro.empresa_parametro_contabil
        this.httpService.wait();
        this.httpService.post('/custom/cadastro/editar-empresa-parametro-contabil', dados)
          .subscribe(
            res => {
              if (res["error"] == false) {
                this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              } else {
                this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: res["message"] });
              }
              this.httpService.done();
            },
            err => {
              console.log(err);
              this.httpService.done();
            }
          );

        // Criando um registro novo
      } else {

        this.httpService.wait();
        this.httpService.post('/custom/cadastro/adicionar-empresa-parametro-contabil', dados)
          .subscribe(
            res => {
              if (res["error"] != false) {
                this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: res["message"] });
              }
              this.httpService.done();
            },
            err => {
              console.log(err);
              this.httpService.done();
            }
          );


        /* Na sequência, realizar a inserção das conexões entre Plano Contas na
        tabela contabil.plano_contas_ref_relac. */
        this.httpService.wait();
        this.httpService.post('/custom/cadastro/adicionar-conexoes-plano-contas', dados)
          .subscribe(
            res => {
              if (res["error"] == false) {
                this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              } else {
                this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: res["message"] });
              }
              this.httpService.done();
            },
            err => {
              console.log(err);
              this.httpService.done();
            }
          );
      }
      // Retornar para a tela inicial e carregar os dados atualizados
      setTimeout(() => {
        this.carregarDados();
        this.voltarTelaInicialParametroContabil();
      }, 2000);
    }

  }

  /* Função que verifica se as contas selecionadas nos Dropdowns da esquerda
  são analíticas ou não, atualizando seus status */
  checarContaAnalitica(contaContabilId: any, numeroDropDown: number) {
    if ((contaContabilId != null) && (contaContabilId != undefined)) {
      this.httpService.wait();
      this.httpService.get('/planocontas?$select=indCta&$'
        + 'filter=id eq ' + contaContabilId)
        .subscribe(result => {
          let tipoConta = result.value[0];
          if (tipoConta["indCta"] == 'A') {
            if (numeroDropDown == 1) {
              this.ccClienteNacionalAnalitica = true;
            } else if (numeroDropDown == 2) {
              this.ccClienteInternacionalAnalitica = true;
            } else if (numeroDropDown == 3) {
              this.ccFornecedorAnalitica = true;
            } else if (numeroDropDown == 4) {
              this.ccFornecedorInternacionalAnalitica = true;
            } else if (numeroDropDown == 5) {
              this.ccFinanceiroAnalitica = true;
            }
          } else {
            if (numeroDropDown == 1) {
              this.ccClienteNacionalAnalitica = false;
            } else if (numeroDropDown == 2) {
              this.ccClienteInternacionalAnalitica = false;
            } else if (numeroDropDown == 3) {
              this.ccFornecedorAnalitica = false;
            } else if (numeroDropDown == 4) {
              this.ccFornecedorInternacionalAnalitica = false;
            } else if (numeroDropDown == 5) {
              this.ccFinanceiroAnalitica = false;
            }
          }
          this.httpService.done();
        },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );

    } else {
      if (numeroDropDown == 1) {
        this.ccClienteNacionalAnalitica = false;
      } else if (numeroDropDown == 2) {
        this.ccClienteInternacionalAnalitica = false;
      } else if (numeroDropDown == 3) {
        this.ccFornecedorAnalitica = false;
      } else if (numeroDropDown == 4) {
        this.ccFornecedorInternacionalAnalitica = false;
      } else if (numeroDropDown == 5) {
        this.ccFinanceiroAnalitica = false;
      }
    }

  }

  carregarDadosParametroContabil() {


    this.empresaParametroContabil = new Array();
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-empresa-parametro-contabil', this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          if (res["error"] == false) {
            for (let line of res["result"]) {

              let label_conta_cliente: any;
              let label_conta_fornecedor: any;
              let label_conta_financeiro: any;

              this.opcoesPlanoContas.forEach(pc => {
                if (pc.value == line["conta_cliente_id"]) {
                  label_conta_cliente = pc.label;
                }
                if (pc.value == line["conta_fornecedor_id"]) {
                  label_conta_fornecedor = pc.label;
                }
                if (pc.value == line["conta_financeiro_id"]) {
                  label_conta_financeiro = pc.label;
                }
              });

              this.empresaParametroContabil.push({
                "id": line["id"], "ccClienteNacional": line["conta_cliente_id"],
                "ccFornecedorNacional": line["conta_fornecedor_id"], "ccFinanceiro": line["conta_financeiro_id"],
                "verificarConciliacao": line["verifica_conciliacao"],
                "ccClienteInternacional": line["conta_cliente_internacional_id"],
                "ccFornecedorInternacional": line["conta_fornecedor_internacional_id"],
                "alterarRateio": line["alteracao_rateio"],
                "verificarConciliacaoStr": line["verifica_conciliacao_str"],
                "ccClienteNacionalRelac": line["conta_cliente_relac_id"],
                "ccFornecedorNacionalRelac": line["conta_fornecedor_relac_id"], "ccFinanceiroRelac": line["conta_financeiro_relac_id"],
                "ccClienteInternacionalRelac": line["conta_cliente_internacional_relac_id"],
                "ccFornecedorInternacionalRelac": line["conta_fornecedor_internacional_relac_id"],
                "labelContaCliente": label_conta_cliente, "labelContaFornecedor": label_conta_fornecedor,
                "labelContaFinanceiro": label_conta_financeiro,
                "codigo_plano_contas_padrao": line["codigo_plano_contas_padrao"],
                "nivel_minimo_analitica": line["nivel_minimo_analitica"],
                "validacao_natureza_1": line["validacao_natureza_1"],
                "validacao_natureza_2": line["validacao_natureza_2"],
                "validacao_natureza_3": line["validacao_natureza_3"],
                "validacao_natureza_4": line["validacao_natureza_4"],
                "validacao_natureza_5": line["validacao_natureza_5"],
                "digitos_nivel_1": line["digitos_nivel_1"],
                "digitos_nivel_2": line["digitos_nivel_2"],
                "digitos_nivel_3": line["digitos_nivel_3"],
                "digitos_nivel_4": line["digitos_nivel_4"],
                "digitos_nivel_5": line["digitos_nivel_5"],
                "digitos_nivel_6": line["digitos_nivel_6"],
                "respContabil": line["respContabil"],
                "visualizarContaAutomatica": line["visualizaContaAutomatica"],
                "niveisContasDre": line["niveisContasDre"],
                "niveisBalancoPatrimonial": line["niveisBalancoPatrimonial"],
                "contaEncerramentoResultadoId": line["contaEncerramentoResultadoId"]
              });
            }
          }

        },
        error => this.httpService.handleError(error, () => this.carregarDados()),
        () => this.httpService.done());
  }

  async getPlanosContas() {
    /* Inicialmente, obter os dados a serem usados nos dropdowns
      de Plano Contas na tela de adição/edição */

    const url = `/planocontas?$select=id,codCta,descrCta,indCta,nivel,codNaturezaConta/descricao,codNaturezaConta/id,dtInicial&$filter=empresa/id eq
                ${this.dblinkedSessionService.empresa.id} and period(dtInicial,dtFinal) eq ${this.dblinkedSessionService.periodo.year.value}
                and indCta eq 'S'&$orderby=codCta`

    try {
      this.httpService.wait()
      await this.httpService.get(url).toPromise()
        .then(
          response => {
            if(response.value === undefined || response.value === null || response.value.length <= 0) return

            this.planosContas = new Array()

            this.opcoesPlanoContas = []

            this.opcoesPlanoContas.unshift({ label: '', value: null })
            this.opcoesPlanoContas.unshift({ label: 'SELECIONE', value: null })

            response.value.forEach(planosConta => {
              this.planosContas.push(planosConta)
              this.opcoesPlanoContas.push({ label: planosConta.codCta + ' - ' + planosConta.descrCta, value: planosConta.id })
            })
          }
        ).catch(error => this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: 'Erro ao buscar os planos de contas' }))
    } catch(error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: 'Erro ao buscar os planos de contas' })
    } finally {
      this.httpService.done()
      this.popularOpcoesPlanoContasResultado()
    }
  }

  async getPlanoContasRfb() {
    try {
      this.httpService.wait()
      await this.httpService.get('/custom/cadastro/get-plano-contas-rfb', this.dblinkedSessionService.empresa.id).toPromise()
        .then(
          response => {
            this.opcoesPlanoContasRfb = [];
            this.opcoesPlanoContasRfb.unshift({ label: '', value: null });
            this.opcoesPlanoContasRfb.unshift({ label: 'SELECIONE', value: null });

            for (let line of response["result"]) {
              this.opcoesPlanoContasRfb.push({ label: line.cod_cta + ' - ' + line.descr_cta, value: line.id });;
            }
          }
        ).catch(error => this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: 'Erro ao buscar os planos de contas' }))
    } catch(error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: 'Erro na comunicação com o servidor! Contate o suporte.' })
    } finally {
      this.httpService.done()
    }
  }

  popularOpcoesPlanoContasResultado() {
    if(this.planosContas.length <= 0) return

    this.planosContas.filter(planoContas => planoContas.codNaturezaConta.id === 3).forEach(element => {
      this.opcoesPlanoContasResultado.push({ label: element.codCta + ' - ' + element.descrCta, value: element.id })
    })
  }
}