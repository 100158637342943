import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { EmpresaPeriodo } from 'app/cadastro/model/empresa-periodo.model';
import { CodIncTrib } from '../../util/model/cod-inc-trib.model';
import { CodTipoCont } from '../../util/model/cod-tipo-cont.model';
import { IndAproCred } from '../../util/model/ind-apro-cred.model';
import { IndRegCum } from '../../util/model/ind-reg-cum.model';
import { ClassificacaoTributaria } from '../../util/model/classificacao-tributaria.model';
import { RegimeTributario } from 'app/util/model/regime-tributario.model';

export class Empresa {
  id: number;
  razaoSocial: string;
  nomeFantasia: string;
  telefone: string;
  fax: string;
  email: string;
  endereco: string;
  municipioIbge: MunicipioIbge;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  cnpj: string;
  cnae: string;
  ie: string;
  ieSt: string;
  im: string;
  tipoLucro: string;
  inicioAtividades: Date;
  nire: string;
  respRfb: string;
  regimeFiscal: string;
  dtRegJunta: Date;
  cpf: string;
  sistema: string;
  baseInterna: string;
  acesso: string;
  usuario: string;
  senha: string;
  suframa: string;
  acessoRemoto: string;
  legEsp: string;
  particularidades: string;
  icms: boolean;
  ipi: boolean;
  icmsSt: boolean;
  issqn: boolean;
  pisCofins: boolean;
  infoStatus: string;
  regimeCxComp: string;
  naturezaJuridica: any;
  naturezaJuridicaEcf: any;
  verificaConciliacao: boolean;
  numInicialNf: number;
  numInicialNfs: number;
  codIncTrib: CodIncTrib;
  codTipoCont: CodTipoCont;
  indAproCred: IndAproCred;
  indRegCum: IndRegCum;
  classificacaoTributaria: ClassificacaoTributaria;
  indEscrituracao: boolean;
  indDesoneracao: boolean;
  indAcordoIsenMulta: boolean;
  indSitPj: number;
  foneFixo: string;
  regimeTributario: RegimeTributario;

  empresaPeriodos: Array<EmpresaPeriodo>;
  movEstNegativo: boolean;

  mesIni: number;
  cadCCusObrigatorio: boolean;
  vinculaItemOperacao: boolean;

  destinatariosVincXml: string;

  grupoEstoque: boolean;
  beneficioObrigatorio: boolean;
  segmentoObrigatorio: boolean;
  altoVolume: boolean;
  matrizId: number;
  visualizaFiliais: boolean;
  faturaProdutoAutomatico: boolean;

  filiais: Array<Empresa> = new Array();

  constructor(empresa?: Empresa | number) {
    if (empresa !== undefined && empresa !== null) {
      if (typeof empresa === 'number') {
        this.id = empresa;
      } else {
        this.id = empresa.id;
        this.razaoSocial = empresa.razaoSocial;
        this.nomeFantasia = empresa.nomeFantasia;
        this.telefone = empresa.telefone;
        this.fax = empresa.fax;
        this.email = empresa.email;
        this.endereco = empresa.endereco;
        this.municipioIbge = empresa.municipioIbge;
        this.numero = empresa.numero;
        this.complemento = empresa.complemento;
        this.bairro = empresa.bairro;
        this.cep = empresa.cep;
        this.cnpj = empresa.cnpj;
        this.cnae = empresa.cnae;
        this.ie = empresa.ie;
        this.ieSt = empresa.ieSt;
        this.im = empresa.im;
        this.tipoLucro = empresa.tipoLucro;
        this.inicioAtividades = empresa.inicioAtividades;
        this.nire = empresa.nire;
        this.respRfb = empresa.respRfb;
        this.regimeFiscal = empresa.regimeFiscal;
        this.dtRegJunta = empresa.dtRegJunta;
        this.cpf = empresa.cpf;
        this.sistema = empresa.sistema;
        this.baseInterna = empresa.baseInterna;
        this.acesso = empresa.acesso;
        this.usuario = empresa.usuario;
        this.senha = empresa.senha;
        this.suframa = empresa.suframa;
        this.acessoRemoto = empresa.acessoRemoto;
        this.legEsp = empresa.legEsp;
        this.particularidades = empresa.particularidades;
        this.icms = empresa.icms;
        this.ipi = empresa.ipi;
        this.icmsSt = empresa.icmsSt;
        this.issqn = empresa.issqn;
        this.pisCofins = empresa.pisCofins;
        this.infoStatus = empresa.infoStatus;
        this.regimeCxComp = empresa.regimeCxComp;
        this.naturezaJuridica = empresa.naturezaJuridica;
        this.naturezaJuridicaEcf = empresa.naturezaJuridicaEcf;
        this.empresaPeriodos = empresa.empresaPeriodos;
        this.verificaConciliacao = empresa.verificaConciliacao;
        this.numInicialNf = empresa.numInicialNf;
        this.numInicialNfs = empresa.numInicialNfs;
        this.codIncTrib = empresa.codIncTrib;
        this.codTipoCont = empresa.codTipoCont;
        this.indAproCred = empresa.indAproCred;
        this.indRegCum = empresa.indRegCum;
        this.classificacaoTributaria = empresa.classificacaoTributaria;
        this.indEscrituracao = empresa.indEscrituracao;
        this.indDesoneracao = empresa.indDesoneracao;
        this.indAcordoIsenMulta = empresa.indAcordoIsenMulta;
        this.indSitPj = empresa.indSitPj;
        this.foneFixo = empresa.foneFixo;
        this.regimeTributario = empresa.regimeTributario;
        this.movEstNegativo = empresa.movEstNegativo;
        this.mesIni = empresa.mesIni;
        this.cadCCusObrigatorio = empresa.cadCCusObrigatorio;
        this.vinculaItemOperacao = empresa.vinculaItemOperacao;
        this.destinatariosVincXml = empresa.destinatariosVincXml;
        this.grupoEstoque = empresa.grupoEstoque;
        this.beneficioObrigatorio = empresa.beneficioObrigatorio;
        this.segmentoObrigatorio = empresa.segmentoObrigatorio;
        this.altoVolume = empresa.altoVolume;
        this.matrizId = empresa.matrizId;
        this.visualizaFiliais = empresa.visualizaFiliais;
        this.faturaProdutoAutomatico = empresa.faturaProdutoAutomatico;
      }
    }
  }
}
