import { Component, OnInit, OnDestroy } from '@angular/core';

import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { InstituicaoFinanceira } from 'app/util/model/instituicao-financeira.model';
import { Message, MessageService } from 'primeng/api';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { TipoConta } from 'app/util/model/tipo-conta.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { isNullOrUndefined } from 'util';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { GrupoComissao } from '../model/grupo-comissao.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Vendedor } from 'app/eventos/model/vendedor.model';
declare var $: any;

@Component({
  selector: 'app-cadastro-comissao',
  templateUrl: './cadastro-comissao.component.html',
  styleUrls: [
    './cadastro-comissao.component.scss'
  ],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class CadastroComissaoComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService

  ) {}

  private sessionSubscription: Subscription; // change session event;

  title = '';
  subtitle = '';

  lista: any;
  grupo: GrupoComissao;

  itensRelacionar: any;
  itensRelacionados: any;
  usuariosRelacionar: any;
  usuariosRelacionadas: any;
  tipoItemRelacionar: any;
  tipoItemRelacionadas: any;
  visaoTipoItem: boolean;

  gerente: {id: number, label: string;};

  gerentOptions: Array<any> = [];
  picklistState: boolean = false
  newGroup: boolean = false

  // components;
  showLista = false;
  showNew = false;
  editar = false;


  // msgs;
  msgs: Message[] = [];

  defaultDate: Date;
  pt_BR: any;

  // expand and hide card
  currentStateSelectItem= 'initial';
  currentStateItemSelecionado= 'initial';
  currentStateItens= 'initial';
  currentStateAliquota = 'initial';

  changeStateSelectItem() {
    this.currentStateSelectItem = this.currentStateSelectItem === 'initial' ? 'final' : 'initial';
  }

  changeStateItemSelecionado() {
    this.currentStateItemSelecionado = this.currentStateItemSelecionado === 'initial' ? 'final' : 'initial';
  }

  changeStateItens() {
    this.currentStateItens = this.currentStateItens === 'initial' ? 'final' : 'initial';
  }

  changeStateAliquota() {
    this.currentStateAliquota = this.currentStateAliquota === 'initial' ? 'final' : 'initial';
  }

  toolbarMainIni = [
    { icon: 'add', text: 'Adicionar', tooltip: 'Adicionar', visible: true, disabled: false, color: 'default', onClick: () => this.criarGrupoComissao() },
    { icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
      this.ShowHideSelectItem()
      this.changeStateSelectItem()
    } },
  ]

  toolbarDetailsIni = [
    { icon: 'arrow_back_ios_new', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: false, color: 'default', onClick: () => this.voltarListaItens() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.deletarGrupo() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'green', onClick: () => this.salvarGrupoComissao() },
    { icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
      this.ShowHideItemSelecionado()
      this.changeStateItemSelecionado()
    } },
  ]

  toolbarItensIni = [
    { icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
      this.ShowHideItens()
      this.changeStateItens()
    } },
  ]

  toolbarAliquotaIni = [
    { icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
      this.ShowHideAliquota()
      this.changeStateAliquota()
    } },
  ]

  public toolbarMain = new Toolbar()
  public toolbarDetails = new Toolbar()
  public toolbarItens = new Toolbar()
  public toolbarAliquota = new Toolbar()

  ngOnInit() {
    this.dblinkedSessionService.hidePeriods();

    Promise.resolve(null).then(() => (this.toolbarService.hidden = true));

    this.title = 'GRUPOS DE COMISSÃO';
    this.editar = false;
    this.newGroup = false;

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
      }
    );

    this.defaultDate = new Date();
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarDetails.setAll(this.toolbarDetailsIni)
    this.toolbarItens.setAll(this.toolbarItensIni)
    this.toolbarAliquota.setAll(this.toolbarAliquotaIni)
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  private changeSession() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.editar = false;
      this.newGroup = false;

      this.httpService.wait();
      this.httpService.get('custom/cadastro/get-grupos-comissao', [this.dblinkedSessionService.empresa.id])
        .subscribe(retorno => {
            this.lista = retorno;

        }, error => this.httpService.handleError(error, () => this.changeSession()),
          () => this.httpService.done());
    }
  }


  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() {

  }

  verGrupo(value: any){
    this.editar = true;
    this.newGroup = false;
    this.grupo = value;
    this.visaoTipoItem = false;
    this.picklistState = false

    this.itensRelacionar      = [];
    this.usuariosRelacionar   = [];
    this.tipoItemRelacionar   = [];

    this.itensRelacionados      = [];
    this.usuariosRelacionadas   = [];
    this.tipoItemRelacionadas   = [];
    this.gerentOptions = [];

    value.itensRelacionados.forEach(element => {
      this.itensRelacionados.push(element);
    });

    value.vendedores.forEach(element => {
      this.usuariosRelacionadas.push(element);
    });

    value.tipoItens.forEach(element => {
      this.tipoItemRelacionadas.push(element);
    });

    if(this.itensRelacionados.length > 0){
      this.visaoTipoItem = false;
    }

    if(this.tipoItemRelacionadas.length > 0){
      this.visaoTipoItem = true;
    }
    this.getRelateCommission();
  }

  /*Show Hide isCardItens*/
  isCardSelectItem: boolean = true;
  ShowHideSelectItem(){
    this.isCardSelectItem = !this.isCardSelectItem;
    this.toolbarMain.setIcon('btn2', this.isCardSelectItem ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn2', this.isCardSelectItem ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide isCardItens*/
  isCardItemSelecionado: boolean = true;
  ShowHideItemSelecionado(){
    this.isCardItemSelecionado = !this.isCardItemSelecionado;
    this.toolbarDetails.setIcon('btn4', this.isCardItemSelecionado ? 'expand_less' : 'expand_more')
    this.toolbarDetails.setTooltip('btn4', this.isCardItemSelecionado ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide isCardItens*/
  isCardItens: boolean = true;
  ShowHideItens(){
    this.isCardItens = !this.isCardItens;
    this.toolbarItens.setIcon('btn1', this.isCardItens ? 'expand_less' : 'expand_more')
    this.toolbarItens.setTooltip('btn1', this.isCardItens ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide isCardItens*/
  isCardAliquota: boolean = true;
  ShowHideAliquota(){
    this.isCardAliquota = !this.isCardAliquota;
    this.toolbarAliquota.setIcon('btn1', this.isCardAliquota ? 'expand_less' : 'expand_more')
    this.toolbarAliquota.setTooltip('btn1', this.isCardAliquota ? 'Ver menos' : 'Ver mais')
  }

  voltarListaItens() {
    this.editar = false;
    this.newGroup = false;
    this.changeSession();
  }

  salvarGrupoComissao(){

   if(confirm('Deseja salvar este grupo?')){

    if(!this.grupo.codigo){
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: 'É necessário informar o código'});
      return;
    }

    if(!this.grupo.descricao){
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: 'É necessário informar a descrição'});
      return;
    }

    if(this.grupo.porcentagem === null && this.grupo.porcentagem === undefined && this.grupo.porcentagem !== 0) {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: 'É necessário informar a porcentagem'});
      return;
    }

    if (this.grupo.dtInicial == undefined || this.grupo.dtInicial.toString().length == 0){
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: 'É necessário informar a Data Inicial'});
      return;
    }

    if (this.grupo.dtFinal == undefined || this.grupo.dtFinal.toString().length == 0){
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: 'É necessário informar a Data Final'});
      return;
    }

    let usuarios = [];
    let tipoItens = [];
    let itens = [];
    this.msgs = [];


    this.usuariosRelacionadas.forEach(element => {
      usuarios.push(element.id);
    });

    this.tipoItemRelacionadas.forEach(element => {
      tipoItens.push(element.id);
    });

    this.itensRelacionados.forEach(element => {
      itens.push(element.id);
    });

    if(itens.length > 0){
      tipoItens = [];
    }


    if(this.gerente && this.gerente.id) {
      this.grupo.gerenteId = this.gerente.id;
    }

    this.grupo.dtInicial = this.grupo.dtInicial.toLocaleString('default',{day: 'numeric', month: 'numeric', year: 'numeric'})
    this.grupo.dtFinal = this.grupo.dtFinal.toLocaleString('default',{day: 'numeric', month: 'numeric', year: 'numeric'})

    const listasEnviar = {
      grupo:                this.grupo,
      visaoTipoItem:        this.visaoTipoItem,
      itensRelacionados:    itens,
      tipoItemRelacionadas: tipoItens,
      usuariosRelacionadas: usuarios,
      usuarioId           : this.sessionService.loggedUser.id
    };


    this.httpService.wait();
    this.httpService.post('/custom/cadastro/salvar-grupo-comissao', listasEnviar)
      .subscribe(result =>  {
        if(!result.error){
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Msg: ', detail: result.message});
          this.newGroup = false;
          this.changeSession();
        } else {
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: result.message});
        }

      },
        error => this.httpService.handleError(error, () => this.salvarGrupoComissao()),
        () => this.httpService.done());

    }
  }

  deletarGrupo(){
    if(confirm('Deseja realmente deletar este grupo?')){

      const listasEnviar = {
        grupo:                this.grupo
      };


      this.httpService.wait();
      this.httpService.post('/custom/cadastro/deletar-grupo-comissao', listasEnviar)
        .subscribe(result =>  {
          if(!result.error){
            this.msgs.push({ severity: 'success', summary: 'Msg: ', detail: result.message});
            this.newGroup = false;
            this.changeSession();
          } else {
            this.msgs.push({ severity: 'warn', summary: 'Msg: ', detail: result.message});
          }

        },
          error => this.httpService.handleError(error, () => this.salvarGrupoComissao()),
          () => this.httpService.done());

      }

  }


  criarGrupoComissao(){
    this.editar = true;
    this.newGroup = true;
    this.grupo = new GrupoComissao();
    this.grupo.id = 0;
    this.grupo.codigo = '';
    this.grupo.descricao = '';
    this.grupo.porcentagem = 0.00;
    this.grupo.ativo = true;
    this.grupo.empresa = new Empresa();
    this.grupo.empresa.id = this.dblinkedSessionService.empresa.id;

    this.itensRelacionar      = [];
    this.itensRelacionados    = [];
    this.usuariosRelacionar   = [];
    this.usuariosRelacionadas = [];
    this.tipoItemRelacionar   = [];
    this.tipoItemRelacionadas = [];
    this.visaoTipoItem = false;
    this.picklistState = false

    this.grupo.porcentagemGerente = 0.00;
    this.grupo.porcentagemDesconto = 0.00;
    this.grupo.gerenteId = 0;
    this.gerentOptions = [];

    this.getRelateCommission();
  }

  getRelateCommission() {
    this.httpService.wait();
    this.httpService.get('custom/cadastro/get-relacionar-comissao', [this.dblinkedSessionService.empresa.id, this.grupo.id])
    .subscribe(retorno => {
      if(retorno.listItens !== null && retorno.listItens !== undefined){
        retorno.listItens.forEach(element => {
          this.itensRelacionar.push(element);
        });
      }

      if(retorno.listUsuarios !== null && retorno.listUsuarios !== undefined){
        retorno.listUsuarios.forEach(element => {
          this.usuariosRelacionar.push(element);
          this.gerentOptions.push({id: element.id, label: element.desc});
        });
      }

      if(retorno.listTipoItens !== null && retorno.listTipoItens !== undefined){
        retorno.listTipoItens.forEach(element => {
          this.tipoItemRelacionar.push(element);
        });
      }

      if(!this.newGroup && this.lista.length > 0) {
        if(this.grupo && this.grupo.id !== null && this.grupo.id !== undefined) {
          const currentList = this.lista.find(element => element.id === this.grupo.id);
          if(currentList.gerentId !== null && currentList.gerentId !== undefined) {
            const selectedGerente = this.gerentOptions.find(item => item.id === currentList.gerentId);
            this.gerente = selectedGerente;
          }
        }
      }
      this.picklistState = true

     }, error => this.httpService.handleError(error, () => this.changeSession()),
     () => this.httpService.done());

     this.toolbarDetails.setVisible('btn2', this.grupo.id !== 0)
  }
}
