import { isNullOrUndefined } from "util";
import { GetNetPagamentoStatus } from 'app/getnet/model/getnet-pagamento-status.model'
import { GetNetPagamento } from 'app/getnet/model/getnet-pagamento-cartao-credito.model'
import { GetNetPagamentoParcela } from 'app/getnet/model/getnet-pagamento-parcela.model'

export class GetNetPagamentoHistorico{

  id                : number;
  getNetPagamento   : GetNetPagamento;
  getNetPagamentoStatus : GetNetPagamentoStatus;
  authorizationCode     : string;
  authorizedAt          : Date;
  reasonCode            : string;
  acquirer              : string;
  softDescriptor        : string;
  terminalNsu           : string;
  acquirerTransactionId : string;
  transactionId         : string;
  status                : string;
  cancelRequestAt       : Date;
  cancelRequestId       : string;
  cancelCustomKey       : string;
  paymentId             : string;
  receivedAt            : Date;
  valor                 : number;
  delayed               : Boolean;
  horaBrasil            : Date;
  descriptionDetail     : string;
  description           : string;
  errorCode             : string;
  mensagem              : string;
  valorEstorno          : number;
  justificativa         : string;
  getNetPagamentoParcela : GetNetPagamentoParcela;
  getNetPagamentoParcelaId : number;

  constructor(getNetPagamentoHistorioco?: GetNetPagamentoHistorico | number) {
    if (!isNullOrUndefined(getNetPagamentoHistorioco)) {
      if (typeof getNetPagamentoHistorioco === 'number') {
        this.id = getNetPagamentoHistorioco;
      } else {
        this.id             = getNetPagamentoHistorioco.id;
        this.getNetPagamento   = getNetPagamentoHistorioco.getNetPagamento;
        this.getNetPagamentoStatus = getNetPagamentoHistorioco.getNetPagamentoStatus;
        this.authorizationCode     = getNetPagamentoHistorioco.authorizationCode;
        this.authorizedAt          = getNetPagamentoHistorioco.authorizedAt;
        this.reasonCode            = getNetPagamentoHistorioco.reasonCode;
        this.acquirer              = getNetPagamentoHistorioco.acquirer;
        this.softDescriptor        = getNetPagamentoHistorioco.softDescriptor;
        this.terminalNsu           = getNetPagamentoHistorioco.terminalNsu;
        this.acquirerTransactionId = getNetPagamentoHistorioco.acquirerTransactionId;
        this.transactionId         = getNetPagamentoHistorioco.transactionId;
        this.status                = getNetPagamentoHistorioco.status;
        this.cancelRequestAt       = getNetPagamentoHistorioco.cancelRequestAt;
        this.cancelRequestId       = getNetPagamentoHistorioco.cancelRequestId;
        this.cancelCustomKey       = getNetPagamentoHistorioco.cancelCustomKey;
        this.paymentId             = getNetPagamentoHistorioco.paymentId;
        this.receivedAt            = getNetPagamentoHistorioco.receivedAt;
        this.valor                 = getNetPagamentoHistorioco.valor;
        this.delayed               = getNetPagamentoHistorioco.delayed;
        this.horaBrasil            = getNetPagamentoHistorioco.horaBrasil;
        this.descriptionDetail     = getNetPagamentoHistorioco.descriptionDetail;
        this.description           = getNetPagamentoHistorioco.description;
        this.errorCode             = getNetPagamentoHistorioco.errorCode;
        this.mensagem              = getNetPagamentoHistorioco.mensagem;
        this.valorEstorno          = getNetPagamentoHistorioco.valorEstorno;
        this.justificativa         = getNetPagamentoHistorioco.justificativa;
        this.getNetPagamentoParcela = getNetPagamentoHistorioco.getNetPagamentoParcela;
        this.getNetPagamentoParcelaId = getNetPagamentoHistorioco.getNetPagamentoParcelaId;

      }
    }
  }

}