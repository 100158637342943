import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AppComponent } from '../../app.component';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MenuItem, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-composicao-formalizacao',
  templateUrl: './composicao-formalizacao.component.html',
  styleUrls: ['./composicao-formalizacao.component.scss'],
  providers: [CrudService]
})
export class ComposicaoFormalizacaoComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;
  opcoesContas: SelectItem[];
  vlContabil: number;
  vlFinanceiro: number;
  vlDiferenca: number;
  items: MenuItem[];


  constructor(private titleService: TitleService,
              private toolbarService: ToolbarService,
              private httpService: HttpService,
              private utilService: UtilService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private crudService: CrudService,
              private appComponent: AppComponent) {
  }

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.opcoesContas = [];

    this.vlContabil = 27483.50;
    this.vlFinanceiro = 23433.50;
    this.vlDiferenca = this.vlContabil - this.vlFinanceiro;

    this.items = [{ label: 'Status',
                    items: [{ label: 'Formalizar',
                              icon: 'fa-check', command: (event) => {
                                this.alertClick('form');
                              }
                            },
                            {
                              label: 'Homologar',
                              disabled: true,
                              icon: 'fa-check-circle', command: (event) => {
                                this.alertClick('hom');
                              }
                            }]
                  },
                  {
                    label: 'Editar',
                    icon: 'fa-edit',
                    items: [{ label: 'Incluir Composição',
                              icon: 'fa-plus' },
                            { label: 'Anexar Arquivo',
                              icon: 'fa-paperclip' }]
                  }];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.periodChanged(); });
  }

  alertClick(event: any): void {
    alert(event);
  }

  periodChanged(): void {

    this.opcoesContas = [];
    this.opcoesContas.push({ label: 'Selecione uma conta', value: null });
    this.opcoesContas.push({ label: '101010101 - CAIXA', value: null });
    this.opcoesContas.push({ label: '101010201 - BANCO SANTANDER-C/C - SANCHEZ CANO', value: null });
    this.opcoesContas.push({ label: '101010202 - BANCO ITAU C/C- SANCHEZ CANO', value: null });
    this.opcoesContas.push({ label: '101010203 - BANCO BRADESCO C/C', value: null });


  }

  ngOnDestroy() {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

}
