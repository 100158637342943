export class TbPerApur {
  id: number;
  cod: string;
  descricao: string;


  constructor(tbPerApur?: TbPerApur | number) {
    if (tbPerApur !== undefined && tbPerApur !== null) {
      if (typeof tbPerApur === 'number') {
        this.id = tbPerApur;
      } else {
        this.id = tbPerApur.id;
        this.cod = tbPerApur.cod;
        this.descricao = tbPerApur.descricao;
      }
    }
  }
}
