import { CadParticipante} from './cad-participante.model';
import { CodRel} from '../../util/model/cod-rel.model';

export class CadParticipanteRelac {
  id: number;
  codRel: CodRel = new CodRel();
  dtIniRel: Date;
  dtFinRel: Date;
  cadParticipante: CadParticipante = new CadParticipante();

  constructor(cadparticipanterelac?: CadParticipanteRelac | number) {
    if (cadparticipanterelac !== undefined && cadparticipanterelac !== null) {
      if (typeof cadparticipanterelac === 'number') {
        this.id = cadparticipanterelac;
      } else {
          this.id = cadparticipanterelac.id;
          this.codRel = cadparticipanterelac.codRel || new CodRel();
          this.dtIniRel = cadparticipanterelac.dtIniRel;
          this.dtFinRel = cadparticipanterelac.dtFinRel;
          this.cadParticipante = cadparticipanterelac.cadParticipante || new CadParticipante();
      }
    }
  }
}
