import { AuthUser } from 'app/proof/model/auth-user.model';


export class ComissaoTipo {
  id: number;
  descricao: string;
  changeUser: AuthUser;
  changeTimestamp: Date;

  constructor(comissaoTipo?: ComissaoTipo | number) {
    if (comissaoTipo !== undefined && comissaoTipo !== null) {
      if (typeof comissaoTipo === 'number') {
        this.id = comissaoTipo;
      } else {
        this.id = comissaoTipo.id;
        this.descricao = comissaoTipo.descricao;
        this.changeUser = comissaoTipo.changeUser;
        this.changeTimestamp = comissaoTipo.changeTimestamp;
      }
    }
  }
}
