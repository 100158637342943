import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import deepCopy from 'app/util/processing/deepcopy';
import exportExcel from 'app/util/processing/exportExcel';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { AppComponent } from '../../app.component';
import { ConfigService } from '../../shared/services/config.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { HttpService } from '../../shared/services/http.service';
import { SessionService } from '../../shared/services/session.service';
import { TitleService } from '../../shared/services/title.service';
import { ToolbarService } from '../../shared/services/toolbar.service';

@Component({
  selector: 'app-report',
  templateUrl: './report-retidos-fonte.component.html',
  styleUrls: ['./report-retidos-fonte.component.scss'],
})
export class LFRetidosFonteComponent implements OnInit, OnDestroy {
  // TODO: Review INPUTS
  @Input() keyField: 'id';
  @Input() dtIni: 'dtIni';
  @Input() dtFin: 'dtFin';
  @Input() servico: 'servico';

  title: string;
  subtitle: string;
  private empresaSubscription: Subscription;
  private initSubscription: Subscription;

  private empresaId: number;
  private params: string;
  retidosFonte: Array<any> = new Array();
  retidosFonteFiltered: Array<any> = new Array();
  columnTitle: string;
  toActualize: Array<any> = new Array();
  checkedAll: boolean = false;
  msgs: Message[] = null;
  exportOrderList: Array<string> = new Array()

  //export excel
  colsretidosFonte: any[];

  colunasRelatorioRetidoFonte = []

  constructor(private titleService: TitleService,
              private httpService: HttpService,
              private toolbarService: ToolbarService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private configService: ConfigService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private httpClient: HttpClient,
              private appComponent: AppComponent,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    if (this.configService.production) {
      this.appComponent._displaySideNav = false;
    } else {
      setTimeout(() => { this.appComponent._displaySideNav = false; });
    }

    this.activatedRoute.params.subscribe(params => {
        this.empresaId = parseInt(params['id'], 10);
        this.dtIni = params['dtIni'];
        this.dtFin = params['dtFin'];
        this.servico = params['detalhePorItem'];

        if (!this.initSubscription) {
          this.initSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
                                                                          () => { this.carregar(); });
        } else {
          this.carregar();
        }
    });

    this.titleService.title = 'Livro Retidos na Fonte de ' + this.dtIni.substring(8, 10) + '/' +
                                this.dtIni.substring(5, 7) + '/' + this.dtIni.substring(0, 4) +
                                ' até ' + this.dtFin.substring(8, 10) + '/' + this.dtFin.substring(5, 7) + '/' + this.dtFin.substring(0, 4);
    this.title = 'LIVROS FISCAIS - RETIDOS NA FONTE ';
    this.subtitle = 'Livros fiscais - Retidos na fonte de ' + this.dtIni.substring(8, 10) + '/' +
                      this.dtIni.substring(5, 7) + '/' + this.dtIni.substring(0, 4) +
                      ' até ' + this.dtFin.substring(8, 10) + '/' + this.dtFin.substring(5, 7) + '/' + this.dtFin.substring(0, 4);
    if (this.servico.toString() === '1') {
      this.subtitle += '. Listando Serviços Prestados.';
      this.columnTitle = 'Data Saída';
    }
    if (this.servico.toString() === '0') {
      this.subtitle += '. Listando Serviços Tomados.';
      this.columnTitle = 'Data Entrada';
    }
    if (this.servico.toString() === '2') {
      this.subtitle += '. Listando Serviços Tomados e Prestados.';
      this.columnTitle = 'Data Entrada/Saida';
    }
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.colsretidosFonte = [
      { field: 'dtEntrada', header: this.columnTitle },
      { field: 'dtEmissao', header: 'Data Emissão' },
      { field: 'cnpj', header: 'CNPJ' },
      { field: 'razao', header: 'RAZÃO SOCIAL' },
      { field: 'mun', header: 'MUNICÍPIO' },
      { field: 'uf', header: 'UF' },
      { field: 'regTrib', header: 'REGIME TRIB' },
      { field: 'status_cnpj', header: 'STATUS CNPJ' },
      { field: 'atualizado', header: 'ATUALIZADO EM' },
      { field: 'numDoc', header: 'NUM DOC' },
      { field: 'status', header: 'STATUS' },
      { field: 'codList', header: 'COD LIST' },
      { field: 'codItem', header: 'COD ITEM' },
      { field: 'descItem', header: 'DESCR ITEM' },
      { field: 'vlrOper', header: 'VALOR OPER' },
      { field: 'deducao', header: 'DEDUÇÃO' },
      { field: 'vlrServico', header: 'VALOR SERVIÇO' },
      { field: 'aliqIssqn', header: 'ALIQ ISSQN' },
      { field: 'vlrIssqn', header: 'VALOR ISSQN' },
      { field: 'aliqIrrf', header: 'ALIQ IRRF' },
      { field: 'vlIrrf', header: 'VALOR IRRF' },
      { field: 'aliqCsrf', header: 'ALIQ CSRF' },
      { field: 'vlCsrf', header: 'VALOR CSRF' },
      { field: 'aliqInss', header: 'ALIQ INSS' },
      { field: 'vlrInss', header: 'VALOR INSS' }
    ];
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.initSubscription);
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  private carregar() {
    this.retidosFonte = new Array<any>();
    if (this.dblinkedSessionService.hasEmpresaSelected) {


      this.sessionService.destroySubscribe(this.initSubscription);
      this.httpService.wait();
      this.httpService.get('/custom/fiscal/livros-retidos-fonte', [this.empresaId, this.dtIni, this.dtFin, this.servico])
      .subscribe(dados => {
        this.retidosFonte = dados;
        this.retidosFonteFiltered = dados;

      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());

    }
  }

  onFilter(event) {
    var filteredData = event.filteredValue;
    this.retidosFonteFiltered = filteredData;

    if ((this.checkedAll === false) && (this.toActualize.length > 0)) {
        this.selectTodos();
    }
  }

  atualizarPart(){

    this.messageService.add({ severity: 'info', summary: 'Info', detail: "O API da Sintegra pode demorar alguns minutos para responder, por favor aguarde."});
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'toactu': this.toActualize.toString(),
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'userid': this.sessionService.loggedUser.id.toString()})
      };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/actuparticipante', null, httpOptions)
      .subscribe(
        res => {
          this.httpService.done();
          this.exibirMensagem(res);
          this.carregar();
        },
        err => {
          this.httpService.done();
          console.log(err);
          this.exibirMensagem({error: true, mensagem: "Erro no API, contate o suporte: " + err.message});
        }
      );
    this.toActualize = new Array();
  }

  exibirMensagem(value: any){
    if(!value.error){

      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem});

    } else {

      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro', detail: value.mensagem});
    }
  }

  select(event: any) {
    this.toActualize.push(event.data.id);
    event.selected = false;
  }

  unselect(event: any){
    const index = this.toActualize.findIndex(i => i === event.data.id);
    this.toActualize.splice(index, 1);
    event.selected = true;
[]
  }

  selectTodos(){
    this.checkedAll = !this.checkedAll;
    this.toActualize = new Array();
    this.retidosFonteFiltered.forEach(e => {
      if (this.checkedAll === false) {
        e.selected = false;
        const index = this.toActualize.findIndex(i => i === e.id);
        this.toActualize.splice(index, 1);
      } else {
        e.selected = true;
        this.toActualize.push(e.id);
      }
    })
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage(){

  }

  public exportarExcel() {
    this.resetColunasExport()
    let options = [this.dblinkedSessionService.empresa.id, this.dtIni, this.dtFin, this.servico]

    this.httpService.wait()
    this.httpService.get('/custom/fiscal/livros-retidos-fonte',options).subscribe((res) => {
        if (res["error"]) {
          this.exibirMensagem(res);
        } else if(res.length > 0) {
          const items = res

          this.exportOrderList = new Array()

          items.forEach(item => {
            item['dtSaida'] = deepCopy(item.dtEntrada)
            item['dtEntradaSaida'] = deepCopy(item.dtEntrada)
          })

          if (this.servico.toString() === '1') this.colunasRelatorioRetidoFonte.splice(0, 2)
          else if (this.servico.toString() === '0') {
            this.colunasRelatorioRetidoFonte.splice(0, 1)
            this.colunasRelatorioRetidoFonte.splice(1, 1)
          }
          else if (this.servico.toString() === '2') this.colunasRelatorioRetidoFonte.splice(1, 2)

          exportExcel(items, "demaisDocs.xlsx" , this.colunasRelatorioRetidoFonte)

        } else {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Alerta', detail: 'Não foi resgatado nenhum dado' })
        }
        this.httpService.done();
      }
    )
  }

  resetColunasExport() {
    this.colunasRelatorioRetidoFonte = [
      'dtEntradaSaida',
      'dtEntrada',
      'dtSaida',
      'dtEmissao',
      'cnpj',
      'razao',
      'mun',
      'uf',
      'regTrib',,
      'status_cnpj',
      'atualizado',
      'numDoc',
      'status',
      'codList',
      'codItem',
      'descItem',
      'vlrOper',
      'deducao',
      'vlrServico',,
      'aliqIssqn',
      'vlrIssqn',
      'aliqIrrf',,
      'vlIrrf',
      'aliqCsrf',
      'vlCsrf',
      'aliqInss',
      'vlrInss',
    ];
  }
}
