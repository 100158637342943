import { ConfigPattern } from "../interfaces/bs2"

export function resetConfigPattern(): ConfigPattern {
  let configPattern: ConfigPattern = {
    refreshToken: null,
    chaveAcesso: null,
    fornecedorPadraoId: null,
    clientePadraoId: null
  }

  return {...configPattern}
}