import { CadEcd } from './cad-ecd.model';

export class CadParticipante {
  id: number;
  codPart: string;
  nome: string;
  codPais: string;
  cnpj: string;
  cpf: string;
  nit: string;
  uf: string;
  ie: string;
  ieSt: string;
  codMun: string;
  im: string;
  suframa: string;
  cadEcd: CadEcd = new CadEcd();

  constructor(cadparticipante?: CadParticipante | number) {
    if (cadparticipante !== undefined && cadparticipante !== null) {
      if (typeof cadparticipante === 'number') {
        this.id = cadparticipante;
      } else {
        this.id = cadparticipante.id;
        this.codPart = cadparticipante.codPart;
        this.nome = cadparticipante.nome;
        this.codPais = cadparticipante.codPais;
        this.cnpj = cadparticipante.cnpj;
        this.cpf = cadparticipante.cpf;
        this.nit = cadparticipante.nit;
        this.uf = cadparticipante.uf;
        this.ie = cadparticipante.ie;
        this.ieSt = cadparticipante.ieSt;
        this.codMun = cadparticipante.codMun;
        this.im = cadparticipante.im;
        this.suframa = cadparticipante.suframa;
        this.cadEcd = cadparticipante.cadEcd || new CadEcd();
      }
    }
  }
}
