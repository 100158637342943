import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';

@Component({
  selector: 'app-modal-participante',
  templateUrl: './modal-participante.component.html',
  styleUrls: ['./modal-participante.component.scss']
})

export class ModalParticipanteComponent implements OnInit {

  listParticipant: Array<any> = [];
  selectedParticipant: Array<any> = [];

  totalParticipant: number = 0;
  empresaId: number = 0;
  @Input() participanteTipoId: number = null

  applyAll: boolean = false;

  @Input() isVisible: boolean = false;
  @Input() isMultiple: boolean = false;
  @Input() isReplicable: boolean = false;

  @Input() saveButtonLabel: string = 'Salvar';

  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() onSelectParticipant = new EventEmitter();

  constructor(
    private httpService: HttpService,
    private configService: ConfigService,
    private messageService: MessageService,
    private dblinkedSessionService: DblinkedSessionService,
  ) {
    this.resetValues();
  }

  ngOnInit() {
    this.resetValues();
  }

  resetValues() {

    this.listParticipant = new Array();
    this.selectedParticipant = new Array();

    this.totalParticipant = 0;
    this.empresaId = 0;

    this.applyAll = false;

  }

  getParticipants(event: LazyLoadEvent = {}) {

    if (this.isVisible) {

      const paramload = {
        offset: event.first,
        limit: event.rows,
        sort_order: event.sortOrder,
        sort_field: event.sortField,
        filters: event.filters,
        participante_tipo_id: this.participanteTipoId
      }

      this.httpService.wait();
      this.httpService.post(this.configService.defaultUrlApiUpload + 'get_distinct_participants/' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId), paramload)
        .subscribe(res => {
          if (res["severity"] === "error") {
            this.showMessage(res)
          } else {
            this.listParticipant = res["data"]["participantes"];
            this.totalParticipant = res["data"]["total_part"];
          }
        }, error => {
          this.showMessage({ severity: 'error', message: "Erro no API, contate o suporte: " + error.message });
          console.log(error)
        },
          () => this.httpService.done());
    }

  }

  showMessage(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  onSave() {
    let response: any = {};
    response.selectedParticipant = this.selectedParticipant;
    if (this.isReplicable) response.applyAll = this.applyAll;

    this.setVisibleState(false);
    this.onSelectParticipant.emit(response);
    this.resetValues();
  }

  setVisibleState(state: boolean) {
    this.isVisible = state;
    this.isVisibleChange.emit(this.isVisible);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPageXLarge;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

}
