import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { CrudService } from '../../shared/services/crud.service';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from '../../shared/services/config.service';
import { TitleService } from '../../shared/services/title.service';
import { HttpService } from '../../shared/services/http.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { SessionService } from '../../shared/services/session.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { UtilService } from '../../shared/services/util.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Pedido } from '../model/pedido.model';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { PedidoService } from 'app/shared/services/pedido.service';
import { ModNf } from 'app/util/model/mod-nf.model';
import { PedidoStatus } from '../model/pedido-status.model';
import { Moeda } from 'app/util/model/moeda.model';
import { FormaPagamento } from 'app/util/model/forma-pagamento.model';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { TipoIncoterm } from 'app/util/model/tipo-incoterm.model';
import { PedidoItem } from '../model/pedido-item.model';
import { Item } from 'app/cadastro/model/item.model';
import { TipoDesconto } from 'app/util/model/tipo-desconto.model';
import { OperacaoRegra } from 'app/cadastro/model/operacao-regra.model';
import { OperacaoRegraContabil } from 'app/cadastro/model/operacao-regra-contabil.model';
import { OperacaoRegraFiscal } from 'app/cadastro/model/operacao-regra-fiscal.model';
import { TipoRegraContabil } from 'app/util/model/tipo-regra-contabil.model';
import { Message } from '../../../../node_modules/primeng/api';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { PedidoTipo } from '../model/pedido-tipo.model';
import { PedidoParcela } from '../model/pedido-parcela.model';
import { TipoBaixa } from 'app/util/model/tipo-baixa.model';
import { isNullOrUndefined, isNull } from 'util';
import { PedidoAprovacao } from '../model/pedido-aprovacao.model';
import { Participante } from 'app/cadastro/model/participante.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadProj } from 'app/contabil/model/cad-proj.model';
import { PedidoItemRateio } from '../model/pedido-item-rateio.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { EmpresaAlmoxarifado } from 'app/cadastro/model/empresa-almoxarifado.model';
import { CstPiscofins } from 'app/util/model/cst-piscofins.model';
import { CstIpi } from 'app/util/model/cst-ipi.model';
import { Dropdown } from 'primeng/dropdown';
import { Table } from 'primeng/table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { SidebarService } from 'app/shared/services/sidebar.service';
import { PysocketService } from 'app/shared/services/socket/pysocket.service';
import { Service } from 'app/shared/services/socket/protocol/Service';
import { Action } from 'app/shared/services/socket/protocol/Action';
import { Method } from 'app/shared/services/socket/protocol/Method';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';
import LogManagement from 'app/shared/common/log-management/log-control';
declare var $: any;


@Component({
  selector: 'app-pedido-servico-new',
  templateUrl: './pedido-servico-new.component.html',
  styleUrls: ['./pedido-servico-new.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class PedidoServicoComponentNew implements OnInit, OnDestroy, AfterViewInit {

  currentStatePedidoServico = 'initial';
  pedidoEdit: any;
  todoCardId: any;
  changeStatePedidoServico() {
    this.currentStatePedidoServico = this.currentStatePedidoServico === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private httpService: HttpService,
    private httpClient: HttpClient,
    private utilService: UtilService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toolbarService: ToolbarService,
    private pedidoService: PedidoService,
    private messageService: MessageService,
    private sidebarService: SidebarService,
    private socketService: PysocketService
  ) {
    this.toolbarService.hidden = true;
    this.data = 10;
  }

  private LogManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  data: number;
  title: string;
  subtitle: string;
  tipo: number;
  modalPeriodo: boolean;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;
  dtEmissaoNova: Date;
  todosSelecionados1: boolean;
  todosSelecionados2: boolean;
  todosSelecionados3: boolean;
  todosSelecionados4: boolean;
  visualizarContabil: boolean; // modal para verificar prévia contábil
  pedidoMisto: boolean;
  modalParticipante: boolean = false
  newRequest: boolean = false
  visualizaOutrosPedidos: boolean = true
  msgs: Message[] = [];

  pedidosCompraVenda: Array<any> = new Array();
  pedidosNegociacao: Array<any> = new Array();
  pedidosRecusados: Array<any> = new Array();
  pedidosFinalizados: Array<any> = new Array();
  pedidosSelecionados: Array<any> = new Array();
  idPedidoView: Array<any> = new Array();
  clearArray: Array<any> = new Array()
  idPedidoExcluirView: Array<any> = new Array();
  financeiroAntecipado: boolean;
  operacaoRegraAntecipado: number;

  valorPedidosSelecionados: number;
  valorTotalPedidos: number = 0

  valorTotalPedidosNegociacao: number = 0
  valorTotalPedidosRecusado: number = 0
  valorTotalPedidosFinalizado: number = 0
  valorTotalPedidosComprasVendas: number = 0

  totalPedidosNegociacao: number = 0
  totalPedidosRecusado: number = 0
  totalPedidosFinalizado: number = 0
  totalPedidosComprasVendas: number = 0

  totalPedidosNegociacaoPaginacao: number = 0
  totalPedidosRecusadoPaginacao: number = 0
  totalPedidosFinalizadoPaginacao: number = 0
  totalPedidosComprasVendasPaginacao: number = 0

  primeiraChamadaNegociacao: boolean
  primeiraChamadaComprasVendas: boolean
  primeiraChamadaRecusado: boolean
  primeiraChamadaFinalizado: boolean
  telaAtual: string = 'TELA_1'

  numeroAba: number;

  infosAdto: any;

  pedido: Pedido;
  pedidoItem: PedidoItem;
  modalClonePedidoLote: boolean;
  modalImpressao: boolean;
  impressaoFormaPagamento: boolean;
  // usaPix: boolean = false
  usaAsaas: boolean = false

  opcoesEndereco: SelectItem[];
  opcoesStatusPedido: SelectItem[];
  opcoesStatusAprovacao: SelectItem[];
  opcoesModelo: SelectItem[];
  opcoesMoeda: SelectItem[];
  opcoesOperacao: SelectItem[];
  opcoesOperacaoCont: SelectItem[];
  opcoesItens: SelectItem[];
  opcoesContas: SelectItem[];
  opcoesFormaPagamento: SelectItem[];
  opcoesCentroCusto: SelectItem[];
  opcoesProjeto: SelectItem[];
  alteracoesPossiveisPedido: SelectItem[];
  opcoesIncoterm: SelectItem[];
  opcoesTipoPagamento: SelectItem[];
  tiposBaixas: SelectItem[];
  opcoesVendedor: SelectItem[];
  opcoesStatusEntrega: SelectItem[] = []
  opcoesDesconto: SelectItem[] = []
  pedidoAprovacao = new PedidoAprovacao;
  pedidoItensRateioEdicao: Array<any> = new Array();
  tiposPagamentos: SelectItem[] = []

  exibirPedido: boolean;
  infoParticipante: any;
  pedidoExterior: boolean;
  botaoEditar: boolean;
  exibirItemPedido: boolean;
  isReadOnly: boolean;
  exibirModalContrato: boolean;
  porcentagemCc: number;
  porcentagemCadProj: number;
  rateioCentroCusto: number;
  somaRateio: number;
  rateioCadProj: number;
  // totalValue: number = 0;

  msgsModal: Message[] = [];
  msgsModalRateio: Message[] = [];
  contador: number;
  contadorRateio: number;

  visualizacaoContabil: any;
  visualizacaoFiscal: any;

  subTotalPedido: any;
  valorDescontoItens: any;
  valorImpostosRetidos: any;
  valorTotalPedido: any;
  pedidoParcela = new PedidoParcela;
  adtosRelacionadosPedido: Array<any> = new Array();

  // inicio variaveis forma pgto
  qtdParcelas: number;
  modalFormaPgto: boolean;
  primeroVencimento = new Date();
  valorTotalParcelas: any;
  calculaParcelasPgto: boolean;
  pedidoItemRateio: PedidoItemRateio;

  proj: any;
  cc: any;

  adtosPart: Array<any> = new Array();
  adtosOutrosPart: Array<any> = new Array();
  adtosCompensar: Array<any> = new Array();
  valorTotalCompensar: number;
  modalDetalheAdtos: boolean;
  detalheAdtos: Array<any> = new Array();
  tipoPagamento: number;

  valorTotalAdtosCompensados: number;
  valorTotalFormasPagto: any;
  valorTotalFormasPagtoTela: any;
  // final

  somaParcelas: number;
  habilitarSolicitar: boolean = false;
  modalClonePedido: boolean;
  cloneStatusPedido: SelectItem[];
  cloneDataEmissao: Date;
  opcoesParticipante: SelectItem[];
  modalCentroCusto: boolean;
  exibirRateio: boolean;
  modalStatusPedidoLote: boolean;
  atualizacaoPedido: number;
  faturaAutomaticoLote: boolean;

  exibirModalRejeicao: boolean = false;
  descricaoMotivoRejeicao: string = null;

  msg: string;
  telefoneContato: boolean;
  operacaoRegraIds: Array<any>;
  codigoMoeda: string;
  centroCustoId: number;
  operacaoRegraGeraFinanceiro: boolean;
  operacaoRegraId: number;
  defaultDate: Date;
  filtroData: string = null

  opcoesPedidoFaturamento: SelectItem[];
  idItemFaturamento: number;
  pedidosFiltrados: Array<any>;

  /*Variaveis Card*/
  statusNegociacao: boolean = true;
  statusCompras: boolean = false;
  statusRecusado: boolean = false;
  statusFinalizado: boolean = false;

  maxParcelas: number;

  validacaoError: boolean;
  vendedorId: number;
  comissaoAtiva: boolean;
  colsPedidosNegociacao: any;

  permiteAlterarConta: boolean = true;
  parcelasExatas: boolean = false
  centroCustoObrigatorio: boolean;
  showChangeDataEntrega: boolean = false
  permiteResetarLazyload: boolean = false

  cols;

  flowUses: boolean = false;
  showSolicitarAprovacao: boolean = false;
  requestApproval: boolean = false;
  params: number;
  modalStatusEntrega: boolean = false;
  statusEntregaId: number = null;
  travarEdicaoItemPedido: boolean = false

  buscarCustoMedio: boolean = null;
  buscarValorVenda: boolean = null;

  userVendedorId: number = null

  statusPesquisado: string = 'negociacao'

  //Variaveis da criação de contrato
  readjustmentContract: number = 0
  readjustmentPercentageContract: number = 0
  loadingGenereateContracts: boolean = false
  exibirModalContratoTable: boolean = false
  contratoAtivo: boolean = false
  dateLimitContract: Date = new Date()
  dateReadjustmentContract: Date = new Date()
  optionsReadjustment: Array<SelectItem> = new Array()


  @ViewChild('dt10') dt10: Table;
  @ViewChild('dt20') dt20: Table;
  @ViewChild('dt30') dt30: Table;
  @ViewChild('dt40') dt40: Table;

  redirectnewService: boolean = false

  toolbarMainIni = [
    {
      key: 'more', icon: 'more_vert', text: '', tooltip: 'Opções', visible: true, disabled: false, color: 'default', dropdown: true,
      options: [
        { optionKey: "export", label: 'Exportar pedidos', callback: () => this.exportarPedidos() },
        { label: 'Alterar Periodo', callback: () => this.openModalPeriodo() },
        { label: 'Atualizar', callback: () => this.atualizarRelatorio() },
        { optionKey: "status", label: 'Alterar Status', callback: () => this.modalStatusPedidosLote(), visible: false },
        { optionKey: "clone", label: 'Clonar', callback: () => this.modalClonarPedido(), visible: false },
        { optionKey: 'escriturar', label: 'Escriturar', callback: () => this.escritLote(), visible: true },
        // { optionKey: 'export', label: 'Exportar', callback: () => this.dt10.exportCSV() },
        { optionKey: "generateBill", label: 'Gerar boleto', callback: () => this.gerarBoletoFin(), visible: false },
        // { optionKey: 'pixqrcode', label: 'Gerar QRCode Pix', callback: () => this.gerarPixQrCode(), visible: false },
        { optionKey: 'cobranca', label: 'Enviar fatura', callback: () => this.gerarCobranca(true), visible: false },
        { optionKey: "print", label: 'Imprimir', callback: () => this.imprimirPedido() },
        { optionKey: 'sidebar', label: 'Abrir Sidebar', callback: () => this.rowSidebar(this.pedidosSelecionados[0]) },
        { optionKey: 'request', label: 'Solicitar aprovação', callback: () => this.solicitarAprovacao(true) },
        { optionKey: 'changeDeliveryDate', label: 'Status entrega', callback: () => this.openModalStatusEntrega(), visible: false },
        { optionKey: 'contract', label: 'Gerar contrato', visible: false, callback: () => this.changeContractsModal(true) },
      ]
    },
    { key: "reject", icon: 'info', text: 'Info Rejeição', tooltip: 'Info Rejeição', visible: false, disabled: false, color: 'default', onClick: () => this.verificarRejeicao() },
    { key: "delete", icon: 'delete', text: 'Excluir', tooltip: 'Excluir Pedido', visible: false, disabled: false, color: 'red', onClick: () => this.excluirPedido() },
    { key: "edit", icon: 'edit', text: 'Editar', tooltip: 'Editar Pedido', visible: false, disabled: false, color: 'default', onClick: () => this.editarPedido() },
    { key: "cobranca", icon: 'request_quote', text: 'Ver fatura', tooltip: 'Ver fatura', visible: false, disabled: false, color: 'default', onClick: () => this.gerarCobranca() },
    { key: "new", icon: 'add', text: 'Novo', tooltip: 'Novo Pedido', visible: true, disabled: false, color: 'green', onClick: () => this.buscarParticipante() },
    { key: "emit", icon: 'add', text: 'ESCRITURAR', tooltip: 'Escriturar NF', visible: false, disabled: false, color: 'green', onClick: () => this.escritLote() }
  ]

  toolbarEditIni = [
    { icon: 'chevron_left', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: false, color: 'default', onClick: () => this.carregarPedidos() },
    { key: "print", icon: 'print', text: 'Imprimir', tooltip: 'Imprimir Pedido', visible: true, disabled: false, color: 'default', onClick: () => this.imprimirPedido() },
    { key: 'view', icon: 'find_in_page', text: 'Visualizar', tooltip: 'Visualizar Pedido', visible: true, disabled: false, color: 'default', onClick: () => this.visualizarEscrituracao() },
    // { key: 'info', icon: 'info', text: 'Dados Contrato', tooltip: 'Inserir Dados Contrato', visible: false, disabled: false, color: 'default', onClick: () => this.exibirModalContrato = true },
    { key: 'emit', icon: 'text_snippet', text: 'Emitir NF', tooltip: 'Emitir NF', visible: false, disabled: false, color: 'default', onClick: () => this.emitirNf() },
    { key: 'saveAndBook', icon: 'save_as', text: 'Salvar e escriturar', tooltip: 'Salvar e escriturar', visible: true, disabled: false, color: 'green', onClick: () => this.atualizarPedido(true) },
    { key: 'save', icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'green', onClick: () => this.atualizarPedido(false) },

  ]

  public toolbarMain = new Toolbar()
  public toolbarEdit = new Toolbar()

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'DATA DE ENTREGA', size: '10%', textAlign: 'center' },
      { name: 'DATA DE EMISSÃO', size: '10%', textAlign: 'left' },
      { name: 'DATA ENTRADA', size: '10%', textAlign: 'left' },
      { name: 'CÓDIGO', size: '10%', textAlign: 'center' },
      { name: 'CONTROLE', size: '10%', textAlign: 'center' },
      { name: 'NOME', size: '20%', textAlign: 'center' },
      { name: 'TIPO', size: '10%', textAlign: 'center' },
      { name: 'OBJETO', size: '10%', textAlign: 'center' },
      { name: 'VALOR', size: '10%', textAlign: 'center' },
      { name: 'SALDO', size: '10%', textAlign: 'center' },
      { name: 'BAIXA', size: '10%', textAlign: 'center' },
      { name: 'USUÁRIO', size: '10%', textAlign: 'center' },
      { name: 'USUÁRIO CRIAÇÃO', size: '10%', textAlign: 'center' },
      { name: 'STATUS', size: '10%', textAlign: 'center' },
      { name: 'STATUS APROVAÇÃO', size: '15%', textAlign: 'center' },
      { name: 'COBRANÇA GERADA', size: '5%', textAlign: 'center' },
      { name: 'COBRANÇA PAGA', size: '5%', textAlign: 'center' },
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true,
  })

  cobGeradaOpt: SelectItem[] = [
    { label: "SIM", value: true },
    { label: "NÃO", value: false },
  ]

  cobPagaOpt: SelectItem[] = [
    { label: "NÃO PAGA", value: 0 },
    { label: "PAGA PARCIALMENTE", value: 1 },
    { label: "PAGA", value: 2 },
  ]

  ngOnInit() {

    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarEdit.setAll(this.toolbarEditIni)


    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.exibirPedido = false;
    this.infoParticipante = '';
    this.pedidoExterior = false;
    this.modalCentroCusto = false;
    this.exibirRateio = false;
    this.atualizacaoPedido = 0;
    this.faturaAutomaticoLote = false;
    this.idItemFaturamento = 0;
    this.impressaoFormaPagamento = false;
    this.modalImpressao = false;
    this.vendedorId = 0;
    this.permiteAlterarConta = true;

    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;
    this.dtEmissaoNova = null;
    this.modalPeriodo = false;
    this.pedidoMisto = false;
    this.comissaoAtiva = false;
    this.permiteResetarLazyload = false

    this.pedido = new Pedido();
    this.pedido.modNf = new ModNf();
    this.pedido.moeda = new Moeda();
    this.pedido.pedidoStatus = new PedidoStatus();
    this.pedido.formaPagamento = new FormaPagamento();
    this.pedido.empresaConta = new EmpresaConta();
    this.visualizacaoContabil = new Array();
    this.financeiroAntecipado = false;
    this.operacaoRegraAntecipado = 0;

    this.pedido.pedidoStatus.id = 0;
    this.pedido.moeda.id = 1;
    this.pedido.txMoeda = 1.00;
    this.pedido.moeda.simbolo = 'R$';
    this.contadorRateio = 0;
    this.rateioCentroCusto = 100;
    this.pedido.converterTaxa = false;

    this.pedido.formaPagamento.id = null;
    this.pedido.empresaConta.id = null;
    this.exibirModalRejeicao = false;
    this.descricaoMotivoRejeicao = null;
    this.defaultDate = new Date();

    this.optionsReadjustment = [{ label: 'NENHUM', value: 0 }, { label: 'INPC', value: 1 }, { label: 'IPCA', value: 2 }, { label: 'SELIC', value: 3 }, { label: 'IGP-M', value: 4 }, { label: 'PERSONALIZADO', value: 5 }]

    this.activatedRoute.params.subscribe(params => {

      if (this.tipo === 9 || this.tipo !== params.tipo) {

        this.params = params.tipo

        this.redirectnewService = (params.action == 'newServicePurchases') || (params.action == 'newServiceSales')

        this.numeroAba = 0;
        this.todosSelecionados1 = false;
        this.todosSelecionados2 = false;
        this.todosSelecionados3 = false;
        this.todosSelecionados4 = false;

        this.pedidosNegociacao.forEach(element => {
          element.selected = false;
        });

        this.pedidosCompraVenda.forEach(element => {
          element.selected = false;
        });

        this.pedidosRecusados.forEach(element => {
          element.selected = false;
        });

        this.pedidosFinalizados.forEach(element => {
          element.selected = false;
        });

        this.tipo = params.tipo;
        this.exibirPedido = false;
        this.cols = [
          { field: 'dt_entrega', header: 'DATA DE ENTREGA' },
          { field: 'dt_emissao', header: 'DATA DE EMISSÃO' },
          { field: 'dt_faturamento', header: this.tipo == 0 ? 'DATA ENTRADA' : 'DATA SAÍDA' },
          { field: 'codigo', header: 'CÓDIGO' },
          { field: 'codigoControle', header: this.dblinkedSessionService.empresa.id == 158 ? 'JIRA' : 'CONTROLE' },
          { field: 'participante', header: 'PARTICIPANTE' },
          { field: 'pedidoTipo', header: 'TIPO' },
          { field: 'valorTotalPorItensFormatado', header: 'VALOR' },
          { field: 'valorSaldoFormatado', header: 'SALDO' },
          { field: 'usuario', header: 'USUÁRIO' },
          { field: 'pedidoStatus', header: 'STATUS' }
        ];
        //this.inicializar();
      }
    });

    /*HIDE SIDEBAR FULLSCREEN (DO NOT REMOVE)*/
    $(document).ready(() => {
      $(".btn-full").click(
        function () {
          $(".mat-drawer-side").toggleClass('d-none');
        }
      );
    });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
          this.dataIni = null;
          this.dataFin = null;
          let lastDay = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0); // ultimo dia do mês

          let mes = this.dblinkedSessionService.periodo.month.value > 9 ?
            this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;

          this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
          this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();
          this.inicializar()
          // this.getQuantidadePedidos()
          //if (!this.validacaoError) {
          this.primeiraChamadaNegociacao = true
          this.primeiraChamadaComprasVendas = true
          this.primeiraChamadaRecusado = true
          this.primeiraChamadaFinalizado = true
          // this.determinarPrimeiraChamada()

          //}
        }
      });

    this.cols = [
      { field: 'dt_entrega', header: 'DATA DE ENTREGA' },
      { field: 'dt_emissao', header: 'DATA DE EMISSÃO' },
      { field: 'dt_faturamento', header: this.tipo == 0 ? 'DATA ENTRADA' : 'DATA SAÍDA' },
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'codigoControle', header: this.dblinkedSessionService.empresa.id == 158 ? 'JIRA' : 'CONTROLE' },
      { field: 'participante', header: 'PARTICIPANTE' },
      { field: 'pedidoTipo', header: 'TIPO' },
      { field: 'valorTotalPorItensFormatado', header: 'VALOR' },
      { field: 'valorSaldoFormatado', header: 'SALDO' },
      { field: 'usuario', header: 'USUÁRIO' },
      { field: 'pedidoStatus', header: 'STATUS' }
    ]
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
  }

  hideMessage() {
  }

  async inicializar(resetarTudo: boolean = true) {



    if (this.redirectnewService) this.buscarParticipante()

    this.exibirPedido = false;


    this.vendedorId = 0;

    this.comboDesconto();
    //this.loadTiposPagamentos(); TODO REMOVE;
    this.centroDeCustoObrigatorio();

    await Promise.all([
      this.buscarParametroFinanceiro(),
      this.getCompanyParameter(),
      this.loadConfigApi(),
      this.verificarVendedor()
    ]).finally(() => {
      this.carregarInformacoesComuns()
      this.carregarPedidos()
    })

    //this.alterarPeriodo();
    this.modalPeriodo = false;

    this.exibirModalContrato = false;
    this.exibirModalRejeicao = false;

    this.botaoEditar = false;

    this.valorPedidosSelecionados = 0.00;
    this.valorTotalPedidos = 0.00;

    this.valorTotalPedidosNegociacao = 0
    this.valorTotalPedidosRecusado = 0
    this.valorTotalPedidosFinalizado = 0
    this.valorTotalPedidosComprasVendas = 0

    this.totalPedidosNegociacao = 0
    this.totalPedidosRecusado = 0
    this.totalPedidosFinalizado = 0
    this.totalPedidosComprasVendas = 0
    // this.statusPesquisado = []

    this.totalPedidosNegociacaoPaginacao = 0
    this.totalPedidosRecusadoPaginacao = 0
    this.totalPedidosFinalizadoPaginacao = 0
    this.totalPedidosComprasVendasPaginacao = 0

    this.isReadOnly = false;

    this.subTotalPedido = this.utilService.parseMoneyString(0.00);
    this.valorDescontoItens = this.utilService.parseMoneyString(0.00);
    this.valorImpostosRetidos = this.utilService.parseMoneyString(0.00);
    this.valorTotalPedido = this.utilService.parseMoneyString(0.00);

    this.todosSelecionados1 = false;
    this.todosSelecionados2 = false;
    this.todosSelecionados3 = false;
    this.todosSelecionados4 = false;

    this.idPedidoView = [];
    this.pedidosSelecionados = [];
    this.clearArray = []
    this.pedido = new Pedido();

    this.changeToolbar()
    // this.carregarInformacoesComuns();
    this.exibirRateio = false;
    this.requestApproval = false;

    this.titleService.title = (this.tipo == 0 ? 'Pedido Compra - Serviço' : 'Pedido Venda - Serviço');
    this.title = (this.tipo == 0 ? 'COMPRAS E ORÇAMENTO DE SERVIÇO' : 'VENDAS E ORÇAMENTO DE SERVIÇO');

    let dataIni = `${this.dataIniSearch.substring(8, 10)}/${this.dataIniSearch.substring(5, 7)}/${this.dataIniSearch.substring(0, 4)}`,
      dataFin = `${this.dataFinSearch.substring(8, 10)}/${this.dataFinSearch.substring(5, 7)}/${this.dataFinSearch.substring(0, 4)}`;

    this.subtitle = `PERÍODO SELECIONADO: ${dataIni} à ${dataFin}`

    this.optionsReadjustment = [{ label: 'NENHUM', value: 0 }, { label: 'INPC', value: 1 }, { label: 'IPCA', value: 2 }, { label: 'SELIC', value: 3 }, { label: 'IGP-M', value: 4 }, { label: 'PERSONALIZADO', value: 5 }]

    // console.log(this.dblinkedSessionService.filiaisSelecionadas);

    // if (!this.validacaoError) {
    //   this.skeletonConfig.startLoad();
    //   this.httpService.get('/custom/pedido/get-pedidos-new(' + this.dblinkedSessionService.empresa.id +
    //   ',' + (this.tipo == 0 ? 'pcs' : 'pvs') + ',' + this.dataIniSearch + ','
    //   + this.dataFinSearch + ',' + this.sessionService.loggedUser.id + ',' + (this.dblinkedSessionService.filiaisSelecionadas.length === 0 ? 0 : this.dblinkedSessionService.filiaisSelecionadas)+')')
    //     .subscribe(pedidos => this.loadPedidos(pedidos.data),
    //       error => this.httpService.handleError(error, () => this.inicializar()),
    //       () => this.skeletonConfig.endLoad());
    // }


  }

  carregarInformacoesComuns() {
    const loadMethods = {
      wait: () => this.skeletonConfig.startLoad(),
      done: () => this.skeletonConfig.endLoad(),
    }

    this.opcoesEndereco = [];
    this.opcoesEndereco.push({ label: 'END PRESTADOR', value: '0' });
    this.opcoesEndereco.push({ label: 'END TOMADOR', value: '1' });

    //this.buscarParametroFinanceiro();

    this.opcoesStatusPedido = [];
    //this.pedidoService.getPedidoStatus(this.tipo == 0 ? 4 : 2, this.opcoesStatusPedido, loadMethods);

    this.opcoesStatusAprovacao = [];
    //this.pedidoService.getPedidoStatusAprovacao(this.tipo == 0 ? 4 : 2, this.opcoesStatusAprovacao, loadMethods);

    this.opcoesModelo = [];
    //this.pedidoService.getModNfs(this.tipo == 0 ? 4 : 2, 's', this.opcoesModelo, loadMethods);

    this.opcoesMoeda = [];
    //this.pedidoService.getMoeda(this.opcoesMoeda, loadMethods);

    this.opcoesOperacao = [];
    this.opcoesOperacaoCont = [];
    //this.pedidoService.getOperacao(this.tipo == 0 ? 4 : 2, 's', this.opcoesOperacao, this.pedidoMisto, loadMethods);

    this.opcoesContas = [];
    //this.pedidoService.carregarContas(this.opcoesContas, loadMethods);

    this.opcoesFormaPagamento = [];
    //this.pedidoService.carregarFormaPagto(this.opcoesFormaPagamento, this.tipo, loadMethods);

    this.opcoesCentroCusto = [];
    this.pedidoService.carregarCentroCusto(this.opcoesCentroCusto, loadMethods);

    this.opcoesProjeto = [];
    this.pedidoService.carregarProjetos(this.opcoesProjeto, 0, loadMethods);

    this.opcoesIncoterm = [];
    //this.pedidoService.carregarIncoterm(this.opcoesIncoterm, loadMethods);

    this.tiposBaixas = [];
    this.pedidoService.carregarTiposBaixa(this.tiposBaixas, loadMethods);

    this.opcoesOperacao = [];

    this.comboAlteracoesPedido();
    this.pagamentoTipo();
    this.buscarVendedores();
    this.skeletonConfig.startLoad();


    this.httpService.get('/custom/cadastro/buscar-infos-comuns-serv', [this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id, this.tipo == 0 ? 1 : 2, this.dblinkedSessionService.periodo.month.value, this.dblinkedSessionService.periodo.year.value])
      .subscribe(result => {


        this.opcoesModelo.push({ label: ('SELECIONE O MODELO'), value: null });
        this.opcoesFormaPagamento.push({ label: 'Nenhum', value: null });

        result.forEach(element => {


          if (element.tipo === 'PEDIDOSTATUS') {

            let pedidoTipo = this.tipo === 0 ? 4 : 2;

            if (this.tipo == 0) {


              if (element.id === 1 || element.id === 7 || element.id === 12) {
                this.opcoesStatusPedido.push({ label: element.desc, value: element.id });
                this.opcoesStatusAprovacao.push({ label: element.desc, value: element.id });
              }

            } else {

              if (element.id === 1 || element.id === 4 || element.id === 12) {
                this.opcoesStatusPedido.push({ label: element.desc, value: element.id });
                this.opcoesStatusAprovacao.push({ label: element.desc, value: element.id });
              }

            }
          } else if (element.tipo === 'MODNF') {
            if (element.cod == 'NFS' || element.cod == '100') {
              this.opcoesModelo.push({ label: (element.cod + ' - ' + element.desc), value: element.id });
            }
          } else if (element.tipo === 'MOEDA') {
            this.opcoesMoeda.push({ label: element.cod, value: element.id });
          } else if (element.tipo === 'OPERACAOREGRA') {


            this.opcoesOperacao.push({ label: element.cod + ' - ' + element.desc, value: element.id });


          } else if (element.tipo === 'OPERACAOCONTABIL') {

            this.opcoesOperacaoCont.push({ label: element.cod, value: element.id });

          } else if (element.tipo === 'EMPRESACONTA') {

            this.opcoesContas.push({ label: element.cod, value: element.id });

          } else if (element.tipo === 'FORMAPAGAMENTO') {

            this.opcoesFormaPagamento.push({ label: element.cod, value: element.id });

          } else if (element.tipo === 'TIPOINCOTERM') {
            this.opcoesIncoterm.push({ label: element.cod, value: element.id });
          } else if (element.tipo === 'TIPOSPAGAMENTOS') {
            this.tiposPagamentos.push({ label: (element.cod + ' - ' + element.desc), value: element.id });
          }



        }
        )
      },
        error => this.httpService.handleError(error, () => this.carregarInformacoesComuns()),
        () => this.skeletonConfig.endLoad())
  }


  retornaIdCentroDeCusto(cadCustoId, rateio) {

    if (!isNullOrUndefined(cadCustoId)) {

      if (this.centroCustoId !== cadCustoId) {
        this.opcoesProjeto = [];
        this.centroCustoId = cadCustoId;
        this.pedidoService.carregarProjetos(this.opcoesProjeto, this.centroCustoId);
      }
    } else {
      this.centroCustoId = 0;
    }

    if (!isNullOrUndefined(rateio)) {
      if (!isNullOrUndefined(rateio.cadProj.id)) {
        rateio.cadProj.id = null;
        rateio.cadProj.codProj = null;
        rateio.cadProj.descrProj = null;
      }
    }
  }
  onInputDate(event: any) {
    let value = event.target.value.replace(/^(\d{1,2})\/?(\d{1,2})\/?(\d{1,4})/, '$1$2$3').split(/\D/)[0],
      response = '';

    if (value.slice(0, 2).length > 0) {
      response = value.slice(0, 2) <= 31 ? value.slice(0, 2) : ''
      if (value.slice(2, 4).length > 0) {
        response += '/' + (value.slice(2, 4) <= 12 ? value.slice(2, 4) : '')
        if (value.slice(4, 8).length > 0) {
          response += '/' + (value.slice(6, 7) >= 1 && value.slice(6, 7) <= 3 ? value.slice(4, 8) : value.slice(4, 6))
        }
      }
    }
    event.target.value = response
  }

  clearFilter(dropdown: Dropdown) {
    dropdown.resetFilter();
  }

  comboAlteracoesPedido() {
    this.alteracoesPossiveisPedido = [];

    this.alteracoesPossiveisPedido.push({ label: 'Alterar Status em Lote', value: 1 });
    this.alteracoesPossiveisPedido.push({ label: 'Alterar Data de Emissão', value: 2 });
  }

  buscarAprovadores() {
    // aprovadores
    this.pedidoAprovacao = new PedidoAprovacao();

    // aprovadores do pedido.
    this.pedido.pedidoAprovacao = new Array<PedidoAprovacao>();
    this.httpService.get('/custom/pedido/buscar-aprovador(' + this.dblinkedSessionService.empresa.id + ',' +
      this.pedido.participante.id + ',' +
      this.pedido.pedidoTipo.id + ')').subscribe(rst => {
        rst.forEach(el => {
          this.pedidoAprovacao = el;
          this.pedido.pedidoAprovacao.push(this.pedidoAprovacao);
        });
      },
        error => this.httpService.handleError(error, () => this.buscarAprovadores()),
        () => this.httpService.done());
  }

  /* Carregar Pedidos */
  // loadPedidos(value: Array<any>) {

  //   this.pedidosSelecionados = new Array();
  //   this.pedidosCompraVenda = new Array();
  //   this.pedidosNegociacao = new Array();
  //   this.pedidosRecusados = new Array();
  //   this.pedidosFinalizados = new Array();
  //   this.valorTotalPedidos = 0.00;
  //   this.valorTotalPedido = 0.00;

  //   value.forEach(element => {

  //     if (element.pedidoStatusId === 1
  //       || element.pedidoStatusId === 8
  //       || element.pedidoStatusId === 9) {

  //       this.pedidosNegociacao.push(element);
  //       this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valorTotalPorItens);

  //     } else if (element.pedidoStatusId === 2
  //       || element.pedidoStatusId === 4
  //       || element.pedidoStatusId === 7
  //       || element.pedidoStatusId === 12
  //       || element.pedidoStatusId === 13) {

  //       this.pedidosCompraVenda.push(element);

  //     } else if (element.pedidoStatusId === 3
  //       || element.pedidoStatusId === 14) {

  //       this.pedidosRecusados.push(element);

  //     } else if (element.pedidoStatusId === 5) {

  //       this.pedidosFinalizados.push(element);

  //     }
  //   });

  //   this.pedidosNegociacao.forEach(element => {
  //     element.selected = false;
  //     this.pedidosFiltrados = new Array();
  //     if (this.numeroAba === 0) {
  //       this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valorTotalPorItens);
  //     }
  //   });

  //   this.calcTotalValue(this.pedidosNegociacao)

  // }

  tabChange(event: any) {

    this.idPedidoView = new Array();
    this.todosSelecionados1 = false;
    this.todosSelecionados2 = false;
    this.todosSelecionados3 = false;
    this.todosSelecionados4 = false;

    this.valorTotalPedidos = 0.00;

    this.optionsReadjustment = [{ label: 'NENHUM', value: 0 }, { label: 'INPC', value: 1 }, { label: 'IPCA', value: 2 }, { label: 'SELIC', value: 3 }, { label: 'IGP-M', value: 4 }, { label: 'PERSONALIZADO', value: 5 }]

    this.pedidosNegociacao.forEach(element => {
      element.selected = false;
      this.pedidosFiltrados = new Array();
      if (event.index === 0) {
        this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
      }
    });

    this.pedidosCompraVenda.forEach(element => {
      element.selected = false;
      this.pedidosFiltrados = new Array();
      if (event.index === 1) {
        this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
      }
    });

    this.pedidosRecusados.forEach(element => {
      element.selected = false;
      this.pedidosFiltrados = new Array();
      if (event.index === 2) {
        this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
      }
    });

    this.pedidosFinalizados.forEach(element => {
      element.selected = false;
      this.pedidosFiltrados = new Array();
      if (event.index === 3) {
        this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valor_total_itens);
      }
    });

    this.numeroAba = event.index;
    this.atualizarValorPedidosSelecionados();

  }

  verificarRejeicao() {

    this.exibirModalRejeicao = true

    this.httpService.wait();
    this.httpService.get('/custom/pedido/pegar-motivo-rejeicao-pedido(' + this.pedidosSelecionados[0].id.toString() + ')')
      .subscribe(motivo => {
        this.descricaoMotivoRejeicao = motivo[0].descricao;
      },
        error => this.httpService.handleError(error, () => this.verificarRejeicao()),
        () => this.httpService.done());
  }


  imprimirPedido() {
    this.modalImpressao = true;
  }

  realizarImpressao() {
    this.pedidosSelecionados.forEach(element => {
      this.httpService.wait();
      this.httpService.get('/custom/pedido/impressao-pedido(' + element.id.toString().split('/')[0] + ',' +
        false + ',' + this.impressaoFormaPagamento + ')')
        .subscribe(result => this.exportedPedido(result),
          error => this.httpService.handleError(error, () => this.imprimirPedido()),
          () => this.httpService.done());
    });
  }

  exportedPedido(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/pedido'), '_blank');
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result.message });
    }
  }

  atualizarValorPedidosSelecionados() {

    this.valorPedidosSelecionados = 0.00;

    this.pedidosSelecionados.forEach(element => {
      this.valorPedidosSelecionados = this.valorPedidosSelecionados + this.utilService.parseDecimal(element.valor_total_itens);
    });
  }


  newParticipante(data) {
    const participante = data.selectedParticipant;
    if (!this.botaoEditar) {
      if (participante) {
        this.pedido = new Pedido();
        this.pedido.participanteEmpresaId = participante.part_emp_id;
        this.pedido.tipoPagamento = 0;
        this.pedido.empresa = new Empresa();
        this.pedido.empresa.id = this.dblinkedSessionService.empresa.id;
        this.pedido.participante = participante;
        this.pedido.pedidoStatus = new PedidoStatus();
        this.pedido.modNf = new ModNf();
        this.pedido.moeda = new Moeda();
        this.pedido.moeda.id = 1;
        this.pedido.txMoeda = 1.00;
        this.pedido.tipoIncoterm = new TipoIncoterm();
        this.pedido.pedidoItem = new Array();
        this.pedido.pedidoTipo = new PedidoTipo();
        this.pedido.pedidoTipo.id = this.tipo == 0 ? 4 : 2;
        this.pedido.formaPagamento = new FormaPagamento();
        this.pedido.empresaConta = new EmpresaConta();
        this.pedidoItem = new PedidoItem();
        this.pedido.valorInss = this.utilService.parseMoneyString(0.00);
        this.pedido.valorIr = this.utilService.parseMoneyString(0.00);
        this.pedido.valorCsll = this.utilService.parseMoneyString(0.00);
        this.pedido.valorPis = this.utilService.parseMoneyString(0.00);
        this.pedido.valorCofins = this.utilService.parseMoneyString(0.00);
        this.pedido.valorIssRetido = this.utilService.parseMoneyString(0.00);
        this.pedido.valorOutrasRetencoes = this.utilService.parseMoneyString(0.00);
        this.valorTotalPedido = this.utilService.parseMoneyString(0.00);
        this.pedido.converterTaxa = false;
        this.codigoMoeda = 'BRL';
        this.maxParcelas = null;

        if (this.tipo === 2) {
          this.pedido.localPrestacao = 0;
        }

        this.novoPedido();

      }
    } else {
      if (participante) {

        this.pedido.participanteEmpresaId = participante.participanteEmpresaId;
        this.pedido.participante = participante;

        this.httpService.wait();
        this.httpService.get('/custom/pedido/buscar-infos-participante(' + this.dblinkedSessionService.empresa.id + ',' +
          this.pedido.participante.id + ',' +
          (this.tipo == 0 ? 4 : 2) + ')')
          .subscribe(info => {


            this.infoParticipante = info;
            this.pedidoExterior = (info[0].uf === 'EX') ? true : false;
          },

            error => this.httpService.handleError(error, () => this.newParticipante(data)),
            () => this.httpService.done());


      }
    }
  }

  novoPedido() {
    this.exibirPedido = true;
    this.contador = 1;
    this.showSolicitarAprovacao = this.flowUses;

    this.httpService.wait();
    this.httpService.get('/custom/pedido/buscar-infos-participante(' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ',' +
      this.pedido.participante.id + ',' +
      (this.tipo == 0 ? 4 : 2) + ')')
      .subscribe(info => {


        this.msgs = [];
        if ((info[0].cidade == null) || (info[0].logradouro === "" || info[0].logradouro == null) || (info[0].numero == null) || (info[0].uf == null)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Endereço do Participante Selecionado está Incompleto!' });
          this.carregarPedidos();
        }

        this.toolbarEdit.setClick('save', () => this.salvarPedido(false))

        if (this.tipo == 0) {
          this.buscarAprovadores();
        }

        this.infoParticipante = info;
        this.pedidoExterior = (info[0].uf === 'EX') ? true : false;
        this.verificaOperacaoRegraGeraFinanceiro(false);
      },

        error => this.httpService.handleError(error, () => this.novoPedido()),
        () => this.httpService.done());
  }

  buscarParticipante() {
    this.newRequest = true;
    this.modalParticipante = true;
    this.redirectnewService = false
  }

  openModal() {
    this.newRequest = false;
    this.modalParticipante = true;
  }

  adicionarItem() {
    this.botaoEditar = false;
    this.pedidoItem = new PedidoItem();
    this.pedidoItem.item = new Item();
    this.pedidoItem.tipoDesconto = new TipoDesconto();
    this.pedidoItem.operacaoRegra = new OperacaoRegra();
    this.pedidoItem.operacaoRegra.operacaoRegraContabil = new OperacaoRegraContabil();
    this.pedidoItem.operacaoRegra.operacaoRegraFiscal = new OperacaoRegraFiscal();
    this.pedidoItem.operacaoRegra.operacaoRegraContabil.tipoRegraContabil = new TipoRegraContabil();
    this.pedidoItem.pedidoItensRateio = new Array();
    this.pedidoItem.temIrrf = false;

    this.pedidoItem.itemFornecedor = null;
    this.pedidoItem.quantidade = null;
    this.pedidoItem.valorUnit = null;
    this.pedidoItem.valorDesconto = null;
    this.exibirItemPedido = true;
  }

  calcularValorTotal() {
    if (this.pedidoItem.tipoDesconto.id === 1) {
      this.pedidoItem.valorTotal = this.utilService.parseDecimal(this.utilService.parseDecimal6(this.pedidoItem.valorUnit) * this.utilService.parseDecimal6(this.pedidoItem.quantidade)) * (100 - this.utilService.parseDecimal6(this.pedidoItem.valorDesconto)) / 100
    } else {
      this.pedidoItem.valorTotal = this.utilService.parseDecimal(this.utilService.parseDecimal6(this.pedidoItem.valorUnit) * this.utilService.parseDecimal6(this.pedidoItem.quantidade)) - this.utilService.parseDecimal6(this.pedidoItem.valorDesconto);
    }

    this.pedidoItem.valorTotal = this.utilService.parseDecimalString(this.pedidoItem.valorTotal);
  }

  excluirItemLista(pedidoItemId: any) {
    if (confirm('Deseja realmente excluir este Item?')) {
      this.exibirItemPedido = false;

      const index = this.pedido.pedidoItem.findIndex(c => c.id === pedidoItemId);
      this.pedido.pedidoItem.splice(index, 1);


      this.pedido.valorInss = this.utilService.parseDecimalString(0.00);
      this.pedido.valorIr = this.utilService.parseDecimalString(0.00);
      this.pedido.valorCsll = this.utilService.parseDecimalString(0.00);
      this.pedido.valorPis = this.utilService.parseDecimalString(0.00);
      this.pedido.valorCofins = this.utilService.parseDecimalString(0.00);
      this.pedido.valorPisFaturamento = this.utilService.parseDecimalString(0.00);
      this.pedido.valorCofinsFaturamento = this.utilService.parseDecimalString(0.00);
      this.pedido.valorIssRetido = this.utilService.parseDecimalString(0.00);
      this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(0.00);
      this.pedido.valorIss = this.utilService.parseDecimalString(0.00);



      this.visualizacaoFiscal = new Array();
      this.visualizacaoContabil = new Array();

      const listasEnviar = {
        pedido: JSON.stringify(this.pedido, null, ''),
        empresa: (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId),
      };

      this.httpService.post('/custom/pedido/buscar-impostos-pedido', listasEnviar)
        .subscribe(res => {

          res.value.forEach(element => {
            this.pedido.valorInss = this.utilService.parseDecimal(this.pedido.valorInss) + this.utilService.parseDecimal(element.valorInss);
            this.pedido.valorIr = this.utilService.parseDecimal(this.pedido.valorIr) + (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
            this.pedido.valorCsll = this.utilService.parseDecimal(this.pedido.valorCsll) + this.utilService.parseDecimal(element.valorCsll);
            this.pedido.valorPisFaturamento = this.utilService.parseDecimal(this.pedido.valorPisFaturamento) + this.utilService.parseDecimal(element.valorPis);
            this.pedido.valorCofinsFaturamento = this.utilService.parseDecimal(this.pedido.valorCofinsFaturamento) + this.utilService.parseDecimal(element.valorCofins);
            this.pedido.valorPis = this.utilService.parseDecimal(this.pedido.valorPis) + this.utilService.parseDecimal(element.valorPisRet);
            this.pedido.valorCofins = this.utilService.parseDecimal(this.pedido.valorCofins) + this.utilService.parseDecimal(element.valorCofinsRet);
            this.pedido.valorIssRetido = this.utilService.parseDecimal(this.pedido.valorIssRetido) + this.utilService.parseDecimal(element.valorIssRet);
            this.pedido.valorOutrasRetencoes = this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes) + this.utilService.parseDecimal(element.valorOutrasRetencoes);
            this.pedido.valorIss = this.utilService.parseDecimal(this.pedido.valorIss) + this.utilService.parseDecimal(element.valorIss);
            this.visualizacaoFiscal.push(element);

            this.pedido.pedidoItem.forEach(pedidoItem => {
              if (element.pedidoItemid === pedidoItem.id) {
                pedidoItem.valorCide = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCide));
                pedidoItem.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofins));
                pedidoItem.valorCofinsRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofinsRet));
                pedidoItem.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
                pedidoItem.valorSimples = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
                pedidoItem.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPis));
                pedidoItem.valorPisRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPisRet));
                pedidoItem.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIss));
                pedidoItem.valorIssRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIssRet));
                pedidoItem.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorInss));
                pedidoItem.valorIrrf = (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
                pedidoItem.temIrrf = element.temIrrf;
              }
            });
          });

          this.atualizarSubtotal();
          this.formaPgtoCalcular(true);
          //this.pagamentoForma();

          if (this.pedido.pedidoItem.length < 1) {
            this.verificaOperacaoRegraGeraFinanceiro(false);
          } else {
            this.verificaOperacaoRegraGeraFinanceiro(true);
          }

        }, error => this.httpService.handleError(error, () => this.excluirItemLista(pedidoItemId)),
          () => this.httpService.done());
    }
  }

  salvarItemLista(pedidoItemId) {
    if (this.pedidoItem.operacaoRegra.id === null || this.pedidoItem.operacaoRegra.id === undefined || this.pedidoItem.operacaoRegra.id.toString().length === 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ', detail: 'Selecione a Operação para Prosseguir!'
      });
      return;
    } if (this.pedidoItem.item.id === null || this.pedidoItem.item.id === undefined || this.pedidoItem.item.id.toString().length === 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ', detail: 'Selecione um Item para Prosseguir!'
      });
      return;
    } if (this.pedidoItem.quantidade === null || this.pedidoItem.quantidade === undefined || this.pedidoItem.quantidade.toString().length === 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ', detail: 'Informe a Quantidade do Item para Prosseguir!'
      });
      return;
    } if (this.pedidoItem.valorUnit === null || this.pedidoItem.valorUnit === undefined || this.pedidoItem.valorUnit.toString().length === 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ', detail: 'Informe o Valor Unitário do Item para Prosseguir!'
      });
      return;
    }

    this.pedidoItem.pedidoItensRateio = new Array();

    this.pedidoItensRateioEdicao.forEach(element => {

      let pedidoItemRateio = new PedidoItemRateio();
      pedidoItemRateio.cadCcus = new CadCcus();
      pedidoItemRateio.cadCcus.id = element.cadCcus.id;
      pedidoItemRateio.cadCcus.codCcus = element.cadCcus.codCcus;
      pedidoItemRateio.cadCcus.descrCcus = element.cadCcus.descrCcus;
      if (!isNullOrUndefined(element.cadProj)) {

        pedidoItemRateio.cadProj = new CadProj();
        pedidoItemRateio.cadProj.id = element.cadProj.id;
        pedidoItemRateio.cadProj.codProj = element.cadProj.codProj;
        pedidoItemRateio.cadProj.descrProj = element.cadProj.descrProj;
      }

      pedidoItemRateio.porcentagem = element.porcentagem;
      this.pedidoItem.pedidoItensRateio.push(pedidoItemRateio);
    });

    this.pedido.pedidoItem.push(this.pedidoItem);
    this.exibirItemPedido = false;
    this.pedidoItem.numSequence = this.contador;

    if (isNullOrUndefined(pedidoItemId)) {
      this.pedidoItem.id = this.pedido.pedidoItem.length;
    } else {
      this.pedidoItem.id = pedidoItemId;
    }

    this.visualizacaoContabil = new Array();
    this.visualizacaoFiscal = new Array();

    const listasEnviar = {
      pedido: JSON.stringify(this.pedido, null, ''),
      empresa: this.dblinkedSessionService.empresa.id,
    };

    this.pedido.valorInss = 0.00;
    this.pedido.valorIr = 0.00;
    this.pedido.valorCsll = 0.00;
    this.pedido.valorPis = 0.00;
    this.pedido.valorCofins = 0.00;
    this.pedido.valorIssRetido = 0.00;
    this.pedido.valorIss = 0.00;
    this.pedido.valorIssRetido = 0.00;
    this.pedido.valorOutrasRetencoes = 0.00;
    this.pedido.valorPisFaturamento = 0.00;
    this.pedido.valorCofinsFaturamento = 0.00;

    this.httpService.post('/custom/pedido/buscar-impostos-pedido', listasEnviar)
      .subscribe(res => {
        res.value.forEach(element => {

          this.pedido.pedidoItem.forEach(pedidoItem => {
            if (element.pedidoItemid === pedidoItem.id) {
              pedidoItem.valorCide = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCide));
              pedidoItem.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofins));
              pedidoItem.valorCofinsRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofinsRet));
              pedidoItem.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
              pedidoItem.valorSimples = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
              pedidoItem.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPis));
              pedidoItem.valorPisRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPisRet));
              pedidoItem.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIss));
              pedidoItem.valorIssRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIssRet));
              pedidoItem.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorInss));
              pedidoItem.valorIrrf = (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
              pedidoItem.temIrrf = element.temIrrf;

            }
          });


          this.pedido.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorInss) + this.utilService.parseDecimal(element.valorInss));
          this.pedido.valorIr = this.utilService.parseDecimal(this.pedido.valorIr) + (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
          this.pedido.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCsll) + this.utilService.parseDecimal(element.valorCsll));
          this.pedido.valorPisFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPisFaturamento) + this.utilService.parseDecimal(element.valorPis));
          this.pedido.valorCofinsFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofinsFaturamento) + this.utilService.parseDecimal(element.valorCofins));
          this.pedido.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPis) + this.utilService.parseDecimal(element.valorPisRet));
          this.pedido.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofins) + this.utilService.parseDecimal(element.valorCofinsRet));
          this.pedido.valorIssRetido = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIssRetido) + this.utilService.parseDecimal(element.valorIssRet));
          this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes) + this.utilService.parseDecimal(element.valorOutrasRetencoes));
          this.pedido.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIss) + this.utilService.parseDecimal(element.valorIss));
          this.visualizacaoFiscal.push(element);


        });

        setTimeout(() => {
          this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);
          this.atualizarSubtotal();
          //this.pagamentoForma();
        }, 500);

      }, error => this.httpService.handleError(error, () => this.salvarItemLista(pedidoItemId)),
        () => this.httpService.done());


    this.verificaOperacaoRegraGeraFinanceiro(true);

    this.contador++;
    this.botaoEditar = false;
  }

  /*Início Métodos Controle de Tabela*/
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  /*MODAL ALTERAR PERÍODO */
  openModalPeriodo() {
    this.modalPeriodo = true;
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
  }

  closeModalPeriodo() {
    this.modalPeriodo = false;
    this.dataIni = null;
    this.dataFin = null;
  }

  /**
   *  povoa as informações do item selecionado
   * @param args
   */
  dadosItem(args) {
    this.httpService.get('/item?$select=id,codigo,descricao,valorVenda,valorCusto&$filter=id eq ' + args.value + '&$orderby=id')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.pedidoItem.item.codigo = element.codigo;
          this.pedidoItem.item.descricao = element.descricao;

          if (this.buscarCustoMedio) {
            this.pedidoItem.valorUnit = element.valorCusto;
          }


          if (this.buscarValorVenda) {
            this.pedidoItem.valorUnit = element.valorVenda;
          }

        });
      },
        error => this.httpService.handleError(error, () => this.dadosItem(args)),
        () => this.httpService.done());
  }

  /**
   *  povoa as informações da operacaoregra selecionado
   * @param args
   */
  /**
   *  povoa as informações da operacaoregra selecionado
   * @param args
   */
  dadosOperacao(args) {
    this.httpService.wait();
    this.httpService.get('custom/cadastro/get-operacao-pedido', [!isNullOrUndefined(args.value) ? args.value : args])
      .subscribe(retorno => {

        this.retornaIdOperacaoRegra(retorno.id, retorno.tipoRegraContabil);

        this.pedidoItem.operacaoRegra = new OperacaoRegra();
        this.pedidoItem.operacaoRegra.id = retorno.id;
        this.pedidoItem.operacaoRegra.cod = retorno.codigo;
        this.pedidoItem.operacaoRegra.descricao = retorno.descricao;
        this.pedidoItem.operacaoRegra.permiteAlteracaoAlmoxarifado = retorno.permiteAlteracaoAlmoxarifado;
        this.pedidoItem.isServico = retorno.tipoRegraContabil == 2 ? true : false;

        this.buscarCustoMedio = isNullOrUndefined(retorno.utilizaCustoMedio) ? false : retorno.utilizaCustoMedio;
        this.buscarValorVenda = isNullOrUndefined(retorno.utilizaPrecoVenda) ? false : retorno.utilizaPrecoVenda;


        this.pedido.dadosAdicionais = isNullOrUndefined(this.pedido.dadosAdicionais) ? ('' + retorno.dadosAdicionais) : (this.pedido.dadosAdicionais + ' ' + (!isNullOrUndefined(this.pedido.dadosAdicionais) ? '' : retorno.dadosAdicionais));

        if (retorno.almoxarifadoOrigemId !== 0) {
          this.pedidoItem.operacaoRegra.almoxarifadoOrigem = new EmpresaAlmoxarifado();
          this.pedidoItem.operacaoRegra.almoxarifadoOrigem.id = retorno.almoxarifadoOrigemId;
          this.pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado();
          this.pedidoItem.empresaAlmoxarifado.id = retorno.almoxarifadoOrigemId;
        }

        if (retorno.almoxarifadoDestinoId) {
          this.pedidoItem.operacaoRegra.almoxarifadoDestino = new EmpresaAlmoxarifado();
          this.pedidoItem.operacaoRegra.almoxarifadoDestino.id = retorno.almoxarifadoDestinoId;
          this.pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado();
          this.pedidoItem.almoxarifadoDestino.id = retorno.almoxarifadoDestinoId;
        }

        this.pedidoItem.operacaoRegra.transfEstoque = retorno.transfEstoque;
        this.pedidoItem.operacaoRegra.controlaEstoque = retorno.controlaEstoque;

        if (!isNullOrUndefined(retorno.operacaoRegraFiscalDetalheList)) {
          retorno.operacaoRegraFiscalDetalheList.forEach(element => {
            if (!isNullOrUndefined(element.cstIpi.id)) {
              this.pedidoItem.cstIpi = new CstIpi();
              this.pedidoItem.cstIpi.id = element.cstIpi.id;
            }

            if (!isNullOrUndefined(element.cstPisCofins.id)) {

              this.pedidoItem.cstPis = new CstPiscofins();
              this.pedidoItem.cstPis.id = element.cstPisCofins.id;

              this.pedidoItem.cstCofins = new CstPiscofins();
              this.pedidoItem.cstCofins.id = element.cstPisCofins.id;
            }
          });
        }
      }, error => this.httpService.handleError(error, () => this.dadosOperacao(args)),
        () => this.httpService.done());
  }

  visualizarEscrituracao() {
    this.visualizarContabil = true;
  }

  atualizarSubtotal() {

    this.subTotalPedido = this.utilService.parseMoneyString(0.00);
    this.valorDescontoItens = this.utilService.parseMoneyString(0.00);
    this.valorImpostosRetidos = this.utilService.parseMoneyString(0.00);
    this.valorTotalPedido = this.utilService.parseMoneyString(0.00);

    this.pedido.pedidoItem.forEach(element => {
      this.subTotalPedido = this.utilService.parseDecimalString(this.utilService.parseDecimal6(this.subTotalPedido) + (this.utilService.parseDecimal6(element.valorUnit) * this.utilService.parseDecimal6(element.quantidade)));

      if (element.tipoDesconto.id === 2) {
        this.valorDescontoItens = this.utilService.parseMoneyString(this.utilService.parseDecimal(this.valorDescontoItens) + this.utilService.parseDecimal(element.valorDesconto));
      } else {
        this.valorDescontoItens = this.utilService.parseMoneyString(this.utilService.parseDecimal(this.valorDescontoItens) + this.utilService.parseDecimal(element.valorUnit) * this.utilService.parseDecimal(element.quantidade) - this.utilService.parseDecimal(element.valorTotal))
      }

    });

    this.valorImpostosRetidos =
      this.utilService.parseDecimalString(
        this.utilService.parseDecimal(this.valorImpostosRetidos) + this.utilService.parseDecimal(this.pedido.valorInss) +
        this.utilService.parseDecimal(this.pedido.valorIr) + this.utilService.parseDecimal(this.pedido.valorCsll) +
        this.utilService.parseDecimal(this.pedido.valorPis) + this.utilService.parseDecimal(this.pedido.valorCofins) +
        this.utilService.parseDecimal(this.pedido.valorIssRetido) + this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes)
      );

    this.valorTotalPedido = this.utilService.parseDecimalString(
      this.utilService.parseDecimal(this.valorTotalPedido) + this.utilService.parseDecimal6(this.subTotalPedido) -
      this.utilService.parseDecimal(this.valorDescontoItens) - this.utilService.parseDecimal(this.valorImpostosRetidos)
    );
  }


  comboTipoPagamento(geraFinanceiro) {
    this.opcoesTipoPagamento = [];
    if (geraFinanceiro) {
      this.opcoesTipoPagamento.push({ label: 'SELECIONE O TIPO DE PAGAMENTO', value: 0 });
      this.opcoesTipoPagamento.push({ label: 'BANCO', value: 1 });
    } else {
      this.opcoesTipoPagamento.push({ label: 'SELECIONE O TIPO DE PAGAMENTO', value: 0 });
      this.opcoesTipoPagamento.push({ label: 'BANCO', value: 1 });
      this.opcoesTipoPagamento.push({ label: 'COMPENSAÇÃO ADIANTAMENTO', value: 2 });
    }
    //this.opcoesTipoPagamento.push({ label: 'DEVOLUÇÃO/RETORNO', value: 3 });
    //this.opcoesTipoPagamento.push({ label: 'CARTÃO DE CRÉDITO', value: 4 });
  }

  pagamentoTipo() {
    if (this.pedido.tipoPagamento === 2) {
      this.adicionarCompensacao();
      this.pedido.pedidoParcela = new Array();
      this.pedidoParcela = new PedidoParcela();
    } else {
      this.adtosRelacionadosPedido = new Array();
    }
  }

  adicionarCompensacao() {
  }

  formaPgtoCalcular(calcular: boolean) {
    if (calcular) {
      if (this.primeroVencimento === null || this.primeroVencimento === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Selecione a data do primeiro vencimento para prosseguir!'
        });
        return;
      }

      if (this.qtdParcelas === null || (this.qtdParcelas < 0)) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Informe a quantidade de parcelas para prosseguir!'
        });
        return;
      } else {
        if (!isNullOrUndefined(this.maxParcelas) && this.qtdParcelas > this.maxParcelas) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Quantidade de parcelas ultrapassa o número máximo da forma de pagamento!'
          });
          return;
        }
      }

      this.calculaParcelasPgto = false;
      this.pedido.pedidoParcela = new Array();
      this.valorTotalPedido = this.utilService.parseDecimal(this.valorTotalPedido);
      this.valorTotalFormasPagtoTela = this.valorTotalPedido;

      this.pedidoParcela = new PedidoParcela();
      this.pedidoParcela.tipoBaixa = new TipoBaixa();

      let vencimento = new Date(this.primeroVencimento);
      const dia = vencimento.getDate();
      if (this.qtdParcelas === 0) {
        // pegando próximo vencimento - considera mais 1 dia
        // vencimento = new Date(vencimento.getTime() + 1 * 24 * 60 * 60 * 1000);

        this.pedidoParcela.id = 1;
        this.pedidoParcela.parcela = 1;
        this.pedidoParcela.dtVencimento = vencimento;
        this.pedidoParcela.valor = this.valorTotalFormasPagtoTela;
        this.pedidoParcela.historico = '';
        this.pedidoParcela.tipoBaixa = new TipoBaixa();
        this.pedidoParcela.tipoBaixa.id = 0;
        this.pedidoParcela.tipoBaixa.descricao = '';
        this.pedidoParcela.tipoPagamentoId = null
        this.pedidoParcela.tipoPagamentoDesc = ''
        this.pedidoParcela.empresaConta = new EmpresaConta();
        this.pedidoParcela.empresaConta.id = this.pedido.empresaConta.id;
        this.pedidoParcela.empresaConta.nome = this.pedido.empresaConta.nome;
        this.pedido.pedidoParcela.push(this.pedidoParcela);

      } else {

        let vlrParcela = Number((this.valorTotalFormasPagtoTela / this.qtdParcelas).toFixed(2));
        let countVlrParcelas = 0.00;
        this.valorTotalParcelas = this.valorTotalFormasPagtoTela;

        for (let parcela = 1; parcela <= this.qtdParcelas; parcela++) {
          this.pedidoParcela = new PedidoParcela();
          this.pedidoParcela.tipoBaixa = new TipoBaixa();

          let mes = null;
          // pegando próximo vencimento - considera 30 dias
          if (vencimento.getMonth() === 0 && dia > 28 && parcela > 1) {
            // proximo é fevereiro
            vencimento = (new Date(vencimento.getTime() + 28 * 24 * 60 * 60 * 1000));
            const lastDayFev = new Date(vencimento.getFullYear(), vencimento.getMonth() + 1, 0); // ultimo dia do mês
            vencimento = lastDayFev;
            mes = vencimento.getMonth(); // mes da parcela
          } else {

            mes = vencimento.getMonth(); // mes da parcela anterior
            vencimento = ((parcela === 1) ? vencimento : new Date(vencimento.getTime() + 30 * 24 * 60 * 60 * 1000));
            // se não trocou de mes
            if (dia === 1 && mes === vencimento.getMonth() && parcela > 1) {
              // acrescentar mais um dia se não trocou de mÊs
              vencimento = new Date(vencimento.getTime() + (1 * 24 * 60 * 60 * 1000));
            }
            mes = vencimento.getMonth(); // mes da parcela
            vencimento.setDate(dia); // dia de vencimento
          }

          let lastDay = vencimento;
          // validando se é no mes corrente ou o set dia trocou de mes
          if (mes !== vencimento.getMonth()) {
            lastDay = new Date(vencimento.getTime() - (24 * 60 * 60 * 1000));
          }
          // total das parcelas
          countVlrParcelas += this.utilService.parseDecimal(vlrParcela);

          // validando qtd parcelas -> pegar ultima
          if (parcela === this.qtdParcelas) {
            // calculando diferença
            const diference = Number((this.valorTotalFormasPagtoTela - countVlrParcelas).toFixed(2));
            // total das parcelas
            countVlrParcelas += this.utilService.parseDecimal(vlrParcela);

            // descontando ou somando a diferenca.
            vlrParcela = (diference > 0 ? (vlrParcela + diference) : (diference < 0 ? (vlrParcela = vlrParcela + diference) : vlrParcela));
          }

          this.pedidoParcela.id = parcela;
          this.pedidoParcela.parcela = parcela;
          this.pedidoParcela.dtVencimento = lastDay;
          this.pedidoParcela.valor = this.utilService.parseDecimal(vlrParcela);
          this.pedidoParcela.historico = '';
          this.pedidoParcela.tipoBaixa = new TipoBaixa();
          this.pedidoParcela.tipoBaixa.id = 0;
          this.pedidoParcela.tipoBaixa.descricao = '';
          this.pedidoParcela.tipoPagamentoId = null
          this.pedidoParcela.tipoPagamentoDesc = ''
          this.pedidoParcela.empresaConta = new EmpresaConta();
          this.pedidoParcela.empresaConta.id = this.pedido.empresaConta.id;
          this.pedidoParcela.empresaConta.nome = this.pedido.empresaConta.nome;
          this.pedido.pedidoParcela.push(this.pedidoParcela);

        }
      }
    } else if (this.pedido.pedidoParcela) { // novo item é adicionado ao pedido.
      this.qtdParcelas = this.pedido.pedidoParcela.length;
      let parcela = 0;
      let vlrParcela = Number((this.utilService.parseDecimal(this.valorTotalFormasPagtoTela) / this.qtdParcelas).toFixed(2));
      this.valorTotalParcelas = 0.00;
      this.pedido.pedidoParcela.forEach(parc => {
        // total das parcelas
        this.valorTotalParcelas += this.utilService.parseDecimal(vlrParcela);
        // validando qtd parcelas -> pegar ultima e calcular diferenças
        if (parcela === this.qtdParcelas) {
          // calculando diferença
          const diference = Number((this.valorTotalPedido - this.valorTotalParcelas).toFixed(2));
          // descontando ou somando a diferenca.
          vlrParcela = (diference > 0 ? (vlrParcela + diference) : (diference < 0 ? (vlrParcela = vlrParcela + diference) : vlrParcela));
        }
        // atualizando o valor das parcelas - 21.03
        if (this.utilService.parseDecimal(parc.valor) > 0.00 && this.calculaParcelasPgto === false) {
          parc.valor = this.utilService.parseDecimal(parc.valor);
        } else {
          parc.valor = this.utilService.parseDecimal(vlrParcela);
        }
        parcela++;
      });
      this.calculaParcelasPgto = false;
      this.qtdParcelas = null;
    }
  }

  calculaValorParcelas() {
    this.valorTotalParcelas = 0.00;
    this.pedido.pedidoParcela.forEach(el => {
      this.valorTotalParcelas += this.utilService.parseDecimal(el.valor);
    });
  }

  pagamentoForma() {
    // formas pagamentos
    if (this.pedido.formaPagamento.id === null || this.pedido.empresaConta.id === null) {
      return;
    }
    if (this.pedido.formaPagamento.id === null) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecione a Forma de Pagamento para Prosseguir!'
      });
      return;
    }

    this.valorTotalFormasPagto = 0;
    this.valorTotalFormasPagtoTela = 0;

    this.adtosRelacionadosPedido = new Array();

    this.pedido.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorInss));
    this.pedido.valorIr = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIr));
    this.pedido.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCsll));
    this.pedido.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPis));
    this.pedido.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofins));
    this.pedido.valorIssRetido = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIssRetido));
    this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes));

    const isPedidoCompraOuVendaProduto: boolean = false;

    const listasEnviar = {
      pedido: this.pedido,
      empresa: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id,
      valorTotal: this.valorTotalPedido,
      moeda: this.pedido.moeda.id,
      txMoeda: this.pedido.txMoeda,
      isPedidoExterior: this.pedidoExterior,
      isPedidoCompraOuVendaProduto: isPedidoCompraOuVendaProduto,
      converterTaxa: this.pedido.converterTaxa
    };


    this.httpService.wait();
    this.httpService.post('/custom/financeiro/buscar-parcela', listasEnviar)
      .subscribe(rst => {
        if (rst["error"]) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: rst.message });
          this.httpService.done()
        } else {
          this.permiteAlterarConta = rst.permiteAlterarBanco;
          this.pedido.pedidoParcela = new Array();
          this.valorTotalParcelas = 0.00;
          this.valorTotalFormasPagtoTela = 0.00;
          this.maxParcelas = rst.maxParcelas;
          this.parcelasExatas = rst.parcelasExatas
          if (!isNullOrUndefined(rst.parcelas)) {
            rst.parcelas.forEach(el => {
              if (!el.error) {
                this.pedidoParcela = new PedidoParcela()
                if (rst.boolDataEmissaoPedido) {
                  this.pedidoParcela.dtVencimento = this.addDias(this.pedido.dtEmissao, el.acrescentaMes, el.dias);

                } else {
                  const d = new Date(el.dtVencimento);
                  this.pedidoParcela.dtVencimento = this.utilService.dateWithoutTimeZone(d.toDateString());
                }

                this.pedidoParcela.valor = el.valor
                this.pedidoParcela.parcela = el.parcela
                this.pedidoParcela.id = el.id

                // total das parcelas
                this.valorTotalParcelas += this.utilService.parseDecimal(el.valor);
                this.valorTotalFormasPagtoTela += this.utilService.parseDecimal(el.valor);

                this.pedidoParcela.tipoBaixa = new TipoBaixa()
                if (el.tipoBaixa) {
                  this.pedidoParcela.tipoBaixa.id = el.tipoBaixa.id
                  this.pedidoParcela.tipoBaixa.descricao = el.tipoBaixa.descricao
                }

                this.pedidoParcela.tipoPagamentoId = el.tipoPagamentoId
                this.pedidoParcela.tipoPagamentoDesc = el.tipoPagamentoDesc

                this.pedidoParcela.empresaConta = new EmpresaConta()
                this.pedidoParcela.empresaConta.id = el.empresaConta.id
                this.pedidoParcela.empresaConta.nome = el.empresaConta.nome
                this.pedido.pedidoParcela.push(this.pedidoParcela);
              } else {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: el.message });
                return;
              }
            });
          }
        }
      },
        error => this.httpService.handleError(error, () => this.pagamentoForma()),
        () => this.httpService.done());
  }

  setEmpresaConta(row) {

    this.opcoesContas.forEach(element => {
      if (element.value === row.empresaConta.id) {
        row.empresaConta.nome = element.label
      }
    })

    this.pedido.pedidoParcela.forEach(element => {
      if (element.parcela > row.parcela) {
        element.empresaConta.id = row.empresaConta.id;
        element.empresaConta.nome = row.empresaConta.nome;
      }

    });

  }

  formaPgtoSalvar() {
    this.somaParcelas = this.utilService.parseDecimal(0);
    this.pedido.pedidoParcela.forEach(element => {
      this.somaParcelas = this.somaParcelas + this.utilService.parseDecimal(element.valor);
    });
    this.somaParcelas = this.utilService.parseDecimal(this.somaParcelas.toFixed(2));
    this.valorTotalPedido = this.utilService.parseDecimal(this.valorTotalPedido);


    for (let parcela of this.pedido.pedidoParcela) {

      if (parcela.dtVencimento == null || !parcela.dtVencimento) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Data de Vencimento da Parcela Inválida!' });

        return;
      }
    }

    if (this.somaParcelas !== this.valorTotalPedido) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Valor das parcelas é diferente do Valor Total do Pedido! <br><b> Parcelas: </b>'
          + this.utilService.parseDecimalString(this.somaParcelas) + ' <br><b> Pedido:</b> '
          + this.utilService.parseDecimalString(this.valorTotalFormasPagtoTela)
      });
    } else {
      this.modalFormaPgto = false;
      this.primeroVencimento = null;
      this.qtdParcelas = null;
    }
  }

  setTipoBaixa(row: any) {
    this.tiposBaixas.forEach(element => {
      if (element.value === row.tipoBaixa.id) {
        row.tipoBaixa.descricao = element.label
      }
    })

    this.pedido.pedidoParcela.forEach(element => {
      if (element.parcela > row.parcela) {
        element.tipoBaixa.id = row.tipoBaixa.id
        element.tipoBaixa.descricao = row.tipoBaixa.descricao
      }
    })
  }

  salvarPedido(salvarNovo: boolean) {
    let permite = true;

    if ((!this.pedido.pedidoItem) || this.pedido.pedidoItem.length === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'É Necessário Informar pelo Menos um Item no Pedido!' });
      permite = false;
      return;
    }

    if (!this.pedido.pedidoParcela) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Insira a Data de Vencimento da Parcela!' });
    } else {
      this.pedido.pedidoParcela.forEach(parcela => {
        if (!parcela.dtVencimento) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Data de Vencimento da Parcela Inválida!' });
          return;
        }
      });
    }

    // VALIDAÇÃO CC E PROJETO

    if (this.centroCustoObrigatorio) {
      this.pedido.pedidoItem.forEach(item => {

        let porcentagem = 0;
        item.pedidoItensRateio.forEach(rat => {

          porcentagem = this.utilService.parseDecimal(porcentagem) + this.utilService.parseDecimal(rat.porcentagem);
          if (rat.cadProj.id && !rat.cadCcus.id) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está com projeto sem centro de custo relacionado, verifique!' });
            permite = false;
            return;
          }

          if (rat.cadCcus.id && (porcentagem === 0 || isNullOrUndefined(porcentagem))) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está com centro de custo sem porcentagem informada!' });

          }
        })

        if ((porcentagem < 100.00 || porcentagem > 100.00) && porcentagem > 0.00) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está com ' + porcentagem + '%, deve ser 100%, verifique!' });
          permite = false;
          return;
        }
      });
    }

    //  FIM VALIDAÇÃO CC E PROJETO

    if (permite) {
      // VALIDAÇÕES INFORMAÇÕES INICIAIS
      if (this.pedido.pedidoStatus.id === null || this.pedido.pedidoStatus.id === undefined) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione o Status do Pedido para Prosseguir!' });
        return;
      }
      if (this.pedido.modNf.id === null || this.pedido.modNf.id === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione o Modelo para Prosseguir!'
        });
        return;
      }
      if (this.pedido.dtEmissao === null || this.pedido.dtEmissao === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione a Data da Emissão para Prosseguir!'
        });
        return;
      }
      if (this.pedido.dtFaturamentoAutomatico === null || this.pedido.dtFaturamentoAutomatico === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione a Data da Entrada/Saída para Prosseguir!'
        });
        return;
      }
      // FIM VALIDAÇÕES INFORMAÇÕES INICIAIS

      // INFORMAÇÕES DE PAGAMENTO
      if (((!this.pedido.tipoPagamento) || this.pedido.tipoPagamento === 0) && this.operacaoRegraGeraFinanceiro) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Selecione o Tipo de Pagamento para Prosseguir!' });
        return;
      }
      if (this.pedido.tipoPagamento === 1) {
        if ((!this.pedido.formaPagamento.id) || this.pedido.formaPagamento.id === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Selecione a Forma de Pagamento para Prosseguir!' });
          return;
        }

        if (this.pedido.empresaConta.id === null || this.pedido.empresaConta.id === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Selecione o Tipo de Conta para Prosseguir!' });
          return;
        }
      }

      if (this.utilService.parseDecimal(this.valorTotalPedido) < this.utilService.parseDecimal(this.valorTotalParcelas)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'O valor do pedido é diferente do total das parcelas, verifique.' });
        return;
      }
      // FIM VALIDAÇÕES INFORMAÇÕES DE PAGAMENTO

      // VALIDAÇÕES APROVADOR
      if ((this.pedido.pedidoAprovacao === null || this.pedido.pedidoAprovacao === undefined) && this.tipo == 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Deve ser Informado um Aprovador para esse Pedido!' });
        return;
      }

      //VALIDAÇÃO FATURA ASAAS
      if (this.usaAsaas && this.pedido.temFaturaAsaas && !this.pedido.temPagamento) {
        if (!confirm("Esse pedido possui uma cobrança ativa pendente, deseje mesmo salvar esse pedido? Essa ação irá excluir a cobrança existente.")) return
      }
      //VALIDAÇÃO COBRANÇA ASAAS

      //VALIDAÇÃO DA DATA DE ENTRADA E EMISSÃO
      if ((this.pedido.dtFaturamentoAutomatico != null && this.pedido.dtEmissao != null) && (this.pedido.dtFaturamentoAutomatico < this.pedido.dtEmissao)) {
        if (this.tipo == 0) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de entrada não pode ser inferior à data de emissão.' })
        else this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de saída não pode ser inferior à data de emissão.' })
        return
      }
      // FIM DA VALIDAÇÃO DE DATA DE ENTREGA E EMISSÃO

      const listasEnviar = {
        pedido: JSON.stringify(this.pedido, null, ''),
        empresa: this.dblinkedSessionService.empresa.id,
        usuario: this.sessionService.loggedUser.id,
        mes: this.dblinkedSessionService.periodo.month.value,
        ano: this.dblinkedSessionService.periodo.year.value,
        info: this.adtosCompensar,
        escriturar: false,
        status: this.pedido.id ? 'edit' : 'novo',
        idItemFaturamento: this.idItemFaturamento,
        vendedorId: this.vendedorId,
        operacaoRegraAntecipado: this.operacaoRegraAntecipado,
        usarFluxo: this.flowUses
      };

      this.httpService.wait();
      this.httpService.post('/custom/escriturar/salvar-pedido', listasEnviar)
        .subscribe(result => {

          this.msgs = [];

          if (result.error) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: result.message });
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: result.message });
            this.LogManagement.registerLog(
              [3, 4, 5].includes(this.pedido.pedidoTipo.id) ? LogModule.PURCHASES : LogModule.SALES,
              [3, 4, 5].includes(this.pedido.pedidoTipo.id) ? LogScreen.SERVICE_PURCHASES_ORDER : LogScreen.SERVICE_SALES_ORDER,
              '/custom/escriturar/salvar-pedido',
              LogAction.CREATE,
              result.id,
              result.codigo,
              `O pedido (${result.codigo}) foi criado`,
              listasEnviar
            )

            if (salvarNovo) {
              if (this.flowUses && this.requestApproval) {
                this.pedidosSelecionados.push(new Pedido(result.id));
                this.solicitarAprovacao(false);
              }

              this.carregarPedidos();

              this.buscarParticipante();
            }
            else {
              // this.visualizarPedido(result, false);
              if (this.flowUses && this.requestApproval) {
                this.pedidosSelecionados.push(new Pedido(result.id));
                this.solicitarAprovacao(false);
              }

              this.carregarPedidos();
            }
          }
        },
          error => this.httpService.handleError(error, () => this.salvarPedido(salvarNovo)),
          () => this.httpService.done());
    }

  }

  selecionarModelo() {

  }

  verificaStatus(event: any) {
    if (event == 1)
      this.habilitarSolicitar = true;
    else
      this.habilitarSolicitar = false;
  }

  editarPedido() {
    this.showSolicitarAprovacao = this.flowUses && [null, undefined, 2, 4, 7, 10].includes(this.pedidosSelecionados[0].status_aprovacao_id)
    this.visualizarPedido(event, false);
  }


  excluirPedido() {
    if (confirm('Deseja realmente excluir este Pedido?')) {

      let pedidosCodsTipos = []
      let listaPedidosId = this.pedidosSelecionados.map(item => item.id);

      this.pedidosSelecionados.forEach(item => pedidosCodsTipos.push({ 'id': item.id, 'codigo': item.codigo, 'tipo': item.pedido_tipo_id, 'objeto': item }))

      if (this.numeroAba === 3) {

        if (confirm('Todos os lançamentos contábeis, fiscais e financeiros serão desfeitos, deseja continuar?')) {
          const listasEnviar = {
            pedidos: listaPedidosId,
            userId: this.dblinkedSessionService.usuario.id
          };

          this.httpService.wait();
          this.httpService.post('/custom/pedido/excluir-pedido-finalizado-id', listasEnviar)
            .subscribe(result => {

              if (result.error === true) {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: result.message });
              } else {
                this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: result.message });

                pedidosCodsTipos.forEach(pedido => this.LogManagement.registerLog(
                  [3, 4, 5].includes(this.tipo) ? LogModule.PURCHASES : LogModule.SALES,
                  [3, 4, 5].includes(this.tipo) ? LogScreen.SERVICE_PURCHASES_ORDER : LogScreen.SERVICE_SALES_ORDER,
                  '/custom/pedido/excluir-pedido-finalizado-id',
                  LogAction.DELETE,
                  pedido.id,
                  pedido.codigo,
                  `O pedido (${pedido.codigo}) foi excluido`,
                  pedido.objeto
                ))

                this.carregarPedidos();
              }

            },
              error => this.httpService.handleError(error, () => this.excluirPedido()),
              () => this.httpService.done());
        }

      } else {

        const listasEnviar = {
          pedidos: listaPedidosId,
          todos: false,
          userId: this.dblinkedSessionService.usuario.id
        };

        this.httpService.wait();
        this.httpService.post('/custom/pedido/excluir-pedido-id', listasEnviar)
          .subscribe(result => {

            if (result.error === true) {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'MSG: ', detail: result.message });
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: result.message });

              pedidosCodsTipos.forEach(pedido => this.LogManagement.registerLog(
                [3, 4, 5].includes(pedido.tipo) ? LogModule.PURCHASES : LogModule.SALES,
                [3, 4, 5].includes(pedido.tipo) ? LogScreen.SERVICE_PURCHASES_ORDER : LogScreen.SERVICE_SALES_ORDER,
                '/custom/pedido/excluir-pedido-id',
                LogAction.DELETE,
                pedido.id,
                pedido.codigo,
                `O pedido (${pedido.codigo}) foi excluido`,
                pedido.objeto
              ))

              this.carregarPedidos();
            }

          },
            error => this.httpService.handleError(error, () => this.excluirPedido()),
            () => this.httpService.done());
      }
    }
  }

  visualizarPedido(event: any, readOnly: boolean) {

    if (!event.id) event = this.pedidosSelecionados[0];

    this.botaoEditar = true;
    this.valorTotalFormasPagto = 0;
    this.visualizacaoContabil = new Array();

    this.isReadOnly = readOnly;
    this.onRowSelectPedido(event, readOnly);

    this.verificaStatus(event.pedido_status_id);

    if (this.isReadOnly) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Pedido está como somente leitura!'
      });
    }

  }

  onRowSelectPedido(event: any, readOnly: boolean) {
    this.httpService.wait();
    this.httpService.get('/custom/pedido/buscar-infos-pedido(' + event.id + ')')
      .subscribe(res => {

        this.todoCardId = this.pedidosSelecionados[0] ? this.pedidosSelecionados[0].todo_card_id : event.todo_card_id
        this.opcoesItens = [];
        this.opcoesItens.push({ label: 'SELECIONE O ITEM', value: 0 });

        this.infosAdto = res.mapAdto;

        this.pedido = new Pedido();
        this.pedido.temFaturaAsaas = event.cobranca_gerada
        this.pedido.temPagamento = (event.status_pago === 1 || event.status_pago === 2) ? true : false
        this.pedido.itemFaturamento = new Item();
        this.pedido.participanteEmpresaId = res.participanteEmpresaId;
        this.pedido.id = res.id;

        if (!isNullOrUndefined(res.localPrestacao)) {
          this.pedido.localPrestacao = res.localPrestacao;
        }

        this.pedido.codigo = res.codigo;
        this.pedido.numNf = res.numNf;
        this.pedido.serie = res.serie;
        this.pedido.dtEntrega = res.dtEntrega;
        this.pedido.dtEmissao = res.dtEmissao;
        this.pedido.dtFaturamentoAutomatico = res.dtFaturamentoAutomatico;
        this.pedido.enderecoCobranca = res.enderecoCobranca;
        this.pedido.enderecoEntrega = res.enderecoEntrega;
        this.pedido.dadosAdicionais = res.dadosAdicionais;
        this.pedido.codigoOrcamento = res.codigoOrcamento;
        this.pedido.codigoControle = res.codigoControle;
        this.pedido.valorInss = this.utilService.parseDecimal(res.valorInss);
        this.pedido.valorIr = this.utilService.parseDecimal(res.valorIr);
        this.pedido.valorCsll = this.utilService.parseDecimal(res.valorCsll);
        this.pedido.valorPis = this.utilService.parseDecimal(res.valorPis);
        this.pedido.valorCofins = this.utilService.parseDecimal(res.valorCofins);
        this.pedido.valorPisFaturamento = this.utilService.parseDecimal(res.valorPisFaturamento);
        this.pedido.valorCofinsFaturamento = this.utilService.parseDecimal(res.valorCofinsFaturamento);
        // this.pedido.valorIssRetido = this.utilService.parseDecimal(res.valorIssRetido);
        this.pedido.valorIss = this.utilService.parseDecimal(res.valorIss);
        this.pedido.valorOutrasRetencoes = this.utilService.parseDecimal(res.valorOutrasRetencoes);
        this.pedido.txMoeda = this.utilService.parseDecimal6(res.txMoeda);
        this.pedido.numeroInvoice = res.numeroInvoice;
        this.pedido.dataInvoice = res.dataInvoice;
        this.pedido.dataInicial = res.dataInicial;
        this.pedido.dataFinal = res.dataFinal;
        this.codigoMoeda = res.moeda.codigo;
        this.pedido.converterTaxa = res.converterTaxa;
        this.pedido.dataUltimaAtualizacao = res.dataAtualizacao;
        this.vendedorId = res.vendedorId;
        this.operacaoRegraAntecipado = res.operacaoRegraAntecipado;
        this.pedido.itemFaturamento.id = res.itemFaturamento;
        this.idItemFaturamento = res.itemFaturamento;


        this.pedido.participante = new Participante();
        this.pedido.participante = res.participante;

        if (!isNullOrUndefined(res.pedidoTipo)) {
          this.pedido.pedidoTipo = new PedidoTipo();
          this.pedido.pedidoTipo = res.pedidoTipo;
        } else {
          this.pedido.pedidoTipo = new PedidoTipo();
        }

        if (!isNullOrUndefined(res.pedidoStatus)) {
          this.pedido.pedidoStatus = new PedidoStatus();
          this.pedido.pedidoStatus = res.pedidoStatus;
        } else {
          this.pedido.pedidoStatus = new PedidoStatus();
        }

        if (!isNullOrUndefined(res.modNf)) {
          this.pedido.modNf = new ModNf();
          this.pedido.modNf = res.modNf;
        } else {
          this.pedido.modNf = new ModNf();
        }

        if (!isNullOrUndefined(res.moeda)) {
          this.pedido.moeda = new Moeda();
          this.pedido.moeda = res.moeda;
        } else {
          this.pedido.moeda = new Moeda();
        }

        if (!isNullOrUndefined(res.tipoIncoterm)) {
          this.pedido.tipoIncoterm = new TipoIncoterm();
          this.pedido.tipoIncoterm = res.tipoIncoterm;
        } else {
          this.pedido.tipoIncoterm = new TipoIncoterm();
        }

        if (!isNullOrUndefined(res.formaPagamento)) {
          this.pedido.formaPagamento = new FormaPagamento();
          this.pedido.formaPagamento = res.formaPagamento;
        } else {
          this.pedido.formaPagamento = new FormaPagamento();
        }

        if (!isNullOrUndefined(res.empresaConta)) {
          this.pedido.empresaConta = new EmpresaConta();
          this.pedido.empresaConta = res.empresaConta;
        } else {
          this.pedido.empresaConta = new EmpresaConta();
        }

        if (!isNullOrUndefined(res.empresa)) {
          this.pedido.empresa = new Empresa();
          this.pedido.empresa = res.empresa;
        } else {
          this.pedido.empresa = new Empresa();
        }

        if (isNull(res.tipoPagamento)) {
          this.pedido.tipoPagamento = 1;
        } else {
          this.pedido.tipoPagamento = res.tipoPagamento;
        }

        this.pedido.pedidoParcela = new Array();
        res.pedidoParcela.forEach(parcela => {
          let pedParcela = new PedidoParcela();
          pedParcela.valor = this.utilService.parseDecimal(parcela.valor);
          pedParcela.dtVencimento = this.utilService.dateWithoutTimeZone(parcela.dtVencimento);
          pedParcela.parcela = parcela.parcela;
          pedParcela.historico = parcela.historico;
          pedParcela.id = parcela.id

          pedParcela.tipoBaixa = new TipoBaixa()
          if (!isNullOrUndefined(parcela.tipoBaixa)) {
            pedParcela.tipoBaixa.id = parcela.tipoBaixa.id;
            pedParcela.tipoBaixa.descricao = parcela.tipoBaixa.descricao;
          }

          if (parcela.tipoPagamento) {
            pedParcela.tipoPagamentoId = parcela.tipoPagamento.id
            pedParcela.tipoPagamentoDesc = parcela.tipoPagamento.descricao
          }

          pedParcela.empresaConta = new EmpresaConta()
          if (parcela.empresaConta) {
            pedParcela.empresaConta.id = parcela.empresaConta.id
            pedParcela.empresaConta.nome = parcela.empresaConta.nome

          } else {
            pedParcela.empresaConta.id = res.empresaConta.id
            pedParcela.empresaConta.nome = res.empresaConta.nome
          }


          this.pedido.pedidoParcela.push(pedParcela);
        });



        this.pedido.pedidoItem = new Array();

        this.opcoesItens = [];
        this.opcoesItens.push({ label: 'SELECIONE O ITEM', value: 0 });

        res.pedidoItens.forEach(pedItem => {

          let pedidoItem = new PedidoItem();

          pedidoItem.id = pedItem.id;
          if (!isNullOrUndefined(pedItem.tipoDesconto)) {
            pedidoItem.tipoDesconto = new TipoDesconto();
            pedidoItem.tipoDesconto = pedItem.tipoDesconto;
          } else {
            pedidoItem.tipoDesconto = new TipoDesconto();
          }

          if (!isNullOrUndefined(pedItem.item)) {
            pedidoItem.item = new Item();
            pedidoItem.item = pedItem.item;
          } else {
            pedidoItem.item = new Item();
          }

          if (!isNullOrUndefined(pedItem.operacaoRegra)) {
            pedidoItem.operacaoRegra = new OperacaoRegra();
            pedidoItem.operacaoRegra = pedItem.operacaoRegra;


            this.httpService.wait();
            this.httpService.get('/custom/pedido/buscar-item-venda('
              + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ',' + this.pedido.pedidoTipo.id + ',' + pedidoItem.operacaoRegra.id + ')')
              .subscribe(ref => {
                if (!isNullOrUndefined(ref)) {

                  this.opcoesItens = [];
                  this.opcoesItens.push({ label: 'SELECIONE O ITEM', value: 0 });

                  ref.forEach(element => {

                    //if (element.id === pedItem.item.id) {
                    this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
                    //}

                  });
                }

              },
                error => this.httpService.handleError(error, () => this.onRowSelectPedido(event, readOnly)),
                () => this.httpService.done());



          } else {
            pedidoItem.operacaoRegra = new OperacaoRegra();
          }

          pedidoItem.quantidade = pedItem.quantidade;
          pedidoItem.valorUnit = pedItem.valorUnit;
          pedidoItem.valorDesconto = pedItem.valorDesconto;
          pedidoItem.valorTotal = pedItem.valorTotal;
          pedidoItem.valorIss = pedItem.valorIss;
          pedidoItem.valorIssRet = pedItem.valorIssRet;
          pedidoItem.valorSaving = pedItem.valorSaving;
          pedidoItem.observacaoSaving = pedItem.observacaoSaving;

          pedidoItem.pedidoItensRateio = new Array();

          this.somaRateio = 0.00;
          pedItem.pedidoItensRateio.forEach(rat => {

            this.somaRateio = this.somaRateio + rat.porcentagem;
            let itemRateio = new PedidoItemRateio();


            itemRateio.porcentagem = rat.porcentagem;

            if (!isNullOrUndefined(rat.cadCcus)) {
              itemRateio.cadCcus = new CadCcus();
              itemRateio.cadCcus = rat.cadCcus;
            } else {
              itemRateio.cadCcus = new CadCcus();
            }

            if (!isNullOrUndefined(rat.cadProj)) {
              itemRateio.cadProj = new CadProj();
              itemRateio.cadProj = rat.cadProj;
            } else {
              itemRateio.cadProj = new CadProj();
            }
            itemRateio.id = rat.id;
            pedidoItem.pedidoItensRateio.push(itemRateio);

          });

          if (this.somaRateio < 100) {
            let itemRateio = new PedidoItemRateio();
            itemRateio.cadCcus = new CadCcus();
            itemRateio.cadProj = new CadProj();
            pedidoItem.pedidoItensRateio.push(itemRateio);
          }
          this.pedido.pedidoItem.push(pedidoItem);
        });

        this.visualizacaoContabil = new Array();
        this.visualizacaoFiscal = new Array();

        const listasEnviar = {
          pedido: JSON.stringify(this.pedido, null, ''),
          empresa: (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId),
        };

        this.pedido.valorInss = 0.00;

        this.pedido.valorIr = 0.00;
        this.pedido.valorCsll = 0.00;
        this.pedido.valorPis = 0.00;
        this.pedido.valorCofins = 0.00;
        this.pedido.valorIssRetido = 0.00;
        this.pedido.valorIss = 0.00;
        this.pedido.valorOutrasRetencoes = 0.00;
        this.pedido.valorPisFaturamento = 0.00;
        this.pedido.valorCofinsFaturamento = 0.00;

        this.httpService.wait();
        this.httpService.post('/custom/pedido/buscar-impostos-pedido', listasEnviar)
          .subscribe(res => {
            res.value.forEach(element => {

              this.pedido.pedidoItem.forEach(pedidoItem => {
                if (element.pedidoItemid === pedidoItem.id) {
                  pedidoItem.valorCide = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCide));
                  pedidoItem.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofins));
                  pedidoItem.valorCofinsRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofinsRet));
                  pedidoItem.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
                  pedidoItem.valorSimples = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
                  pedidoItem.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPis));
                  pedidoItem.valorPisRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPisRet));
                  pedidoItem.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIss));
                  pedidoItem.valorIssRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIssRet));
                  pedidoItem.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorInss));
                  pedidoItem.valorIrrf = (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
                  pedidoItem.temIrrf = element.temIrrf;
                }
              });


              this.pedido.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorInss) + this.utilService.parseDecimal(element.valorInss));
              this.pedido.valorIr = this.utilService.parseDecimal(this.pedido.valorIr) + (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
              this.pedido.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCsll) + this.utilService.parseDecimal(element.valorCsll));
              this.pedido.valorPisFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPisFaturamento) + this.utilService.parseDecimal(element.valorPis));
              this.pedido.valorCofinsFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofinsFaturamento) + this.utilService.parseDecimal(element.valorCofins));
              this.pedido.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPis) + this.utilService.parseDecimal(element.valorPisRet));
              this.pedido.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofins) + this.utilService.parseDecimal(element.valorCofinsRet));
              this.pedido.valorIssRetido = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIssRetido) + this.utilService.parseDecimal(element.valorIssRet));
              this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes) + this.utilService.parseDecimal(element.valorOutrasRetencoes));
              this.pedido.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIss) + this.utilService.parseDecimal(element.valorIss));
              this.visualizacaoFiscal.push(element);


            });

            setTimeout(() => {
              this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);
              this.atualizarSubtotal();
              this.calculaValorParcelas();
            }, 500);

          }, error => this.httpService.handleError(error, () => this.onRowSelectPedido(event, readOnly),
            () => this.httpService.done()));

        this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);
        // this.pedidoService.buscarPreviaFiscal(this.pedido, this.visualizacaoFiscal);

        this.loadToolbarEdit();

        this.verificaOperacaoRegraGeraFinanceiro(true);

        this.exibirPedido = true;
        this.httpService.wait();
        this.httpService.get('/custom/pedido/buscar-infos-participante(' + this.dblinkedSessionService.empresa.id + ',' +
          this.pedido.participante.id + ',' +
          this.pedido.pedidoTipo.id + ')')
          .subscribe(info => {
            this.infoParticipante = info; this.pedidoExterior = (info[0].uf === 'EX') ? true : false;
            this.pedido.itemFaturamento.id = res.itemFaturamento;

            this.pedidoEdit = JSON.parse(JSON.stringify(this.pedido))
            this.pedidoEdit.vendedor = this.vendedorId
            this.pedidoEdit.operacaoRegra = this.operacaoRegraAntecipado
          },
            error => this.httpService.handleError(error, () => this.onRowSelectPedido(event, readOnly)),
            () => this.httpService.done());
      },
        error => this.httpService.handleError(error, () => this.onRowSelectPedido(event, readOnly)),
        () => this.httpService.done());
  }

  loadToolbarEdit() {

    this.toolbarEdit.setAll(this.toolbarEditIni);

    this.toolbarEdit.setVisible('save', !this.isReadOnly && this.pedido.pedidoStatus.id !== 5);

    this.toolbarEdit.setVisible('saveAndBook', (this.pedido.pedidoStatus.description === 'Compra') && (this.pedido.pedidoTipo.description === 'Aquisição de Serviço') && !this.isReadOnly);

    if ((this.pedido.pedidoStatus.id === 7) && (this.pedido.pedidoTipo.id === 4) || // Status = Compra e Tipo = Aquisição de Serviço
      (this.pedido.pedidoStatus.id === 12) && (this.pedido.pedidoTipo.id === 2)) {  // Status = Draft e Tipo = Venda de Serviço
      this.toolbarEdit.setVisible('saveAndBook', !this.isReadOnly);
    }

    if (this.pedido.pedidoStatus.id !== 13 && this.pedido.pedidoStatus.id !== 5) {
      this.toolbarEdit.setVisible('info', this.isReadOnly);
    }

    if (this.pedido.pedidoStatus.id === 4 && this.pedido.pedidoTipo.id === 2) {  // Status = Venda e Tipo = Venda de Serviço
      this.toolbarEdit.setVisible('emit', !this.isReadOnly);
    }

  }


  calculaRateioDisponivelCentroCusto() {
    let rateio: number;
    rateio = 0.00;
    this.rateioCentroCusto = 100;
    if (this.pedidoItem) {

      this.pedidoItem.pedidoItensRateio.forEach(element => {
        element.porcentagem = this.utilService.parseMoney(element.porcentagem);
        rateio = this.utilService.parseMoney(rateio) + this.utilService.parseMoney(element.porcentagem);
        this.rateioCentroCusto = 100 - this.utilService.parseMoney(rateio);
      });

      if (this.rateioCentroCusto === 0) {
        this.visualizacaoContabil = new Array();
        this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);
      }

    }
  }


  adicionarRateio() {
    let itemRateio = new PedidoItemRateio();
    itemRateio.cadCcus = new CadCcus();
    itemRateio.cadProj = new CadProj();

    itemRateio.id = this.pedidoItem.pedidoItensRateio.length;
    this.pedidoItem.pedidoItensRateio.push(itemRateio);

    this.visualizacaoContabil = new Array();
    this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);

  }

  loadPedidoAprovacao(value: Array<PedidoAprovacao>) {
    this.pedido.pedidoAprovacao = new Array();

    value.forEach(element => {
      this.pedido.pedidoAprovacao.push(element);
    });
  }

  //SIMON LALLEMENT: Parece não estar em uso
  // loadParcelaPedido(value: Array<PedidoParcela>) {
  //   this.pedido.pedidoParcela = new Array();
  //   value.forEach(element => {
  //     const date = new Date(element.dtVencimento);
  //     element.dtVencimento = this.utilService.dateWithoutTimeZone(date.toDateString());

  //     if (element.tipoBaixa === null) {
  //       element.tipoBaixa = new TipoBaixa();
  //       element.tipoBaixa.id = 0;
  //     }
  //     this.pedido.pedidoParcela.push(element);
  //   });
  // }


  findValue(key: string, identification) {
    const opcoes = {
      localPrestacao: this.opcoesEndereco,
      tipoPagamento: this.opcoesTipoPagamento,
      vendedor: this.opcoesVendedor,
      operacaoRegra: this.opcoesOperacaoCont
    };

    try {
      let content = null;

      if (Object.keys(opcoes).includes(key)) {
        opcoes[key].forEach((item) => {
          if (item.value == identification.id || item.value == identification) {
            content = item.label;
          }
        });

        return content;
      }

      return content;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  parseObject(object) {
    for (let [key, value] of Object.entries(object)) {
      const parsedValue = this.findValue(key, value);

      if (parsedValue) object[key] = parsedValue;
    }

    return object
  }




  async atualizarPedido(escriturar: boolean = false) {

    if (this.pedido.pedidoStatus.id === 5) {
      alert('Nao é possível alterar um Pedido Finalizado, a NF deverá ser estornada')

    } else {

      let permite = true;

      if ((!this.pedido.pedidoItem) || this.pedido.pedidoItem.length === 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'É Necessário Informar pelo Menos um Item no Pedido!' });
        permite = false;
        return;
      }

      this.pedido.pedidoParcela.forEach(parcela => {
        if (!parcela.dtVencimento) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Data de Vencimento da Parcela Inválida!' });
          permite = false;
          return;
        }
      });

      // INFORMAÇÕES RATEIO
      if (this.centroCustoObrigatorio) {
        this.pedido.pedidoItem.forEach(item => {

          let porcentagem = 0;
          item.pedidoItensRateio.forEach(rat => {

            porcentagem = this.utilService.parseDecimal(porcentagem) + this.utilService.parseDecimal(rat.porcentagem);
            if (rat.cadProj.id && !rat.cadCcus.id) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está com projeto sem centro de custo relacionado, verifique!' });
              permite = false;
              return;
            }
            if (rat.cadCcus.id && (porcentagem === 0 || isNullOrUndefined(porcentagem))) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está com centro de custo sem porcentagem informada!' });
              permite = false;
              return;
            }

            if (rat.cadCcus.projetosRelacionados && isNullOrUndefined(rat.cadProj.id)) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Rateio ' + (item.pedidoItensRateio.indexOf(rat) + 1) + ' do Item: ' + item.item.codigo + ' está sem projeto relacionado, verifique!' });
              permite = false;
              return;
            }
          });

          if ((porcentagem < 100.00 || porcentagem > 100.00) && porcentagem > 0.00) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Rateio do Item: ' + item.item.codigo + ' está com ' + porcentagem + '%, deve ser 100%, verifique!' });
            permite = false;
            return;
          }
        });
      }
      // FIM INFORMAÇÕES RATEIO


      // INFORMAÇÕES INICIAIS
      if (permite) {
        // VALIDAÇÕES INFORMAÇÕES INICIAIS
        if (this.pedido.pedidoStatus.id === null || this.pedido.pedidoStatus.id === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione o Status do Pedido para Prosseguir!' })
          return;
        }
        if (this.pedido.modNf.id === null || this.pedido.modNf.id === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Selecione o Modelo para Prosseguir!' })
          return;
        }
        if (this.pedido.dtEmissao === null || this.pedido.dtEmissao === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione a Data da Emissão para Prosseguir!' })
          return;
        }
        if (this.pedido.dtFaturamentoAutomatico === null || this.pedido.dtFaturamentoAutomatico === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione a Data da Entrada/Saída para Prosseguir!' })
          return;
        }
        if ((this.pedido.numNf === null || this.pedido.numNf === undefined || this.pedido.numNf.trim() === '') && escriturar === true) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Informe o Número para Prosseguir com a Escrituração!' })
          return;
        }
        if (this.pedido.pedidoStatus.id === 7 || this.pedido.pedidoStatus.id === 12) {
          if (this.pedido.pedidoTipo.id === 4 && this.pedidoExterior && escriturar === true) {
            if (!this.pedido.moeda.id || this.pedido.moeda === undefined) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Selecione a Moeda para Prosseguir com a Escrituração!' })
              return;
            }
            if ((!this.pedido.txMoeda || this.pedido.txMoeda === undefined) && (this.pedido.moeda.id != 1)) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Informe a Taxa para Prosseguir com a Escrituração!' })
              return;
            }
            if (this.pedido.moeda.id != 1 && this.pedido.converterTaxa === false) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Converta a Taxa para Prosseguir com a Escrituração!' })
              return;
            }
          }
        }
        // FIM INFORMAÇÕES INICIAIS

        // INFORMAÇÕES DE PAGAMENTO
        let valorParcelas = 0;
        this.pedido.pedidoParcela.forEach(el => {
          el.dtVencimento = this.utilService.dateWithoutTimeZone(el.dtVencimento.toString());
          valorParcelas = valorParcelas + el.valor;
        });

        if (!valorParcelas) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'É Necessário Informar a Parcela' });
          return;
        }

        if (((!this.pedido.tipoPagamento) || this.pedido.tipoPagamento === 0) && this.operacaoRegraGeraFinanceiro) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione o Tipo de Pagamento para Prosseguir!' });
          return;
        }

        if (this.pedido.tipoPagamento === 1) {
          if ((!this.pedido.formaPagamento.id) || this.pedido.formaPagamento.id === undefined) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione o Tipo de Pagamento para Prosseguir!' });
            return;
          }

          if (this.pedido.empresaConta.id === null || this.pedido.empresaConta.id === undefined) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione o Tipo de Conta para Prosseguir!' });
            return;
          }
        }

        if (this.utilService.parseDecimal(this.valorTotalPedido) < this.utilService.parseDecimal(this.valorTotalParcelas)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O valor do pedido é diferente do total das parcelas, verifique.' });
          return;
        }
        // FIM INFORMAÇÕES DE PAGAMENTO

        //VALIDAÇÃO FATURA ASAAS
        if (this.usaAsaas && this.pedido.temFaturaAsaas && !this.pedido.temPagamento) {
          if (!confirm("Esse pedido possui uma cobrança ativa pendente, deseje mesmo salvar esse pedido? Essa ação irá excluir a cobrança existente.")) return
        }
        //VALIDAÇÃO COBRANÇA ASAAS

        //VALIDAÇÃO DA DATA DE ENTRADA E EMISSÃO
        if ((this.pedido.dtFaturamentoAutomatico != null && this.pedido.dtEmissao != null)) {
          let convertDateFat: any = this.pedido.dtFaturamentoAutomatico
          let convertDateEmission: any = this.pedido.dtEmissao

          if (typeof (this.pedido.dtFaturamentoAutomatico) == 'string') {
            convertDateFat = `${convertDateFat.split('/')[1]}/${convertDateFat.split('/')[0]}/${convertDateFat.split('/')[2]}`
          }

          if (typeof (this.pedido.dtEmissao) == 'string') {
            convertDateEmission = `${convertDateEmission.split('/')[1]}/${convertDateEmission.split('/')[0]}/${convertDateEmission.split('/')[2]}`
          }
          let dateFatAuto = new Date(convertDateFat)
          let dateEmission = new Date(convertDateEmission)

          if (dateFatAuto < dateEmission) {
            if (this.tipo == 0) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de entrada não pode ser inferior à data de emissão.' })
            }
            else {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de saída não pode ser inferior à data de emissão.' })
            }
            return
          }
        }
        // FIM DA VALIDAÇÃO DE DATA DE ENTREGA E EMISSÃO

        let rmsChecked: boolean = true
        let dadoChecked: string = null
        let message: string = null
        if (this.pedido.id) {
          if (this.flowUses) {
            let verificarItensPedido = await this.verificarItensRateioXml()
            rmsChecked = verificarItensPedido[0]
            dadoChecked = verificarItensPedido[1]
            message = verificarItensPedido[2]
          }

          if (!rmsChecked) {
            if (dadoChecked) {
              if (this.travarEdicaoItemPedido) {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Os parâmetros da empresa impedem a edição dos itens e do rateio quando o pedido vem de um RMS. Verificar o ' + dadoChecked + '.' })
                return

              } else if (!confirm("Tem inconsistências de " + dadoChecked + " entre os itens do RMS e os itens do pedido, deseja continuar?")) return
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
              return
            }
          }
        }

        const listasEnviar = {
          pedido: JSON.stringify(this.pedido, null, ''),
          empresa: this.dblinkedSessionService.empresa.id,
          usuario: this.sessionService.loggedUser.id,
          mes: this.dblinkedSessionService.periodo.month.value,
          ano: this.dblinkedSessionService.periodo.year.value,
          info: this.adtosCompensar,
          escriturar: escriturar,
          status: this.pedido.id ? 'edit' : 'novo',
          idItemFaturamento: this.idItemFaturamento,
          vendedorId: this.vendedorId,
          operacaoRegraAntecipado: this.operacaoRegraAntecipado,
        };


        this.httpService.wait();
        this.httpService.post('/custom/escriturar/salvar-pedido', listasEnviar)
          .subscribe(result => {

            if (!result.error) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: result.message });
              this.LogManagement.registerLog(
                [3, 4, 5].includes(this.pedido.pedidoTipo.id) ? LogModule.PURCHASES : LogModule.SALES,
                [3, 4, 5].includes(this.pedido.pedidoTipo.id) ? LogScreen.SERVICE_PURCHASES_ORDER : LogScreen.SERVICE_SALES_ORDER,
                '/custom/escriturar/salvar-pedido',
                LogAction.EDIT,
                result.id,
                result.codigo,
                `O pedido (${result.codigo}) foi modificado`,
                listasEnviar
              )

              if (this.todoCardId) {

                let pedidoCopy = JSON.parse(JSON.stringify(this.pedido))
                pedidoCopy.vendedor = this.vendedorId
                pedidoCopy.operacaoRegra = this.operacaoRegraAntecipado

                this.pedidoEdit = this.parseObject(this.pedidoEdit)
                pedidoCopy = this.parseObject(pedidoCopy)

                const payload = {
                  service: Service.APPROVAL_CENTER,
                  action: Action.ORDER,
                  method: Method.PUT,
                  content: {
                    userId: this.sessionService.loggedUser.id,
                    cardId: this.todoCardId,
                    userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
                    compareList: [
                      { before: this.pedidoEdit, after: pedidoCopy },
                    ]
                  }
                }

                this.socketService.send(payload)
              }

              if (this.flowUses && this.requestApproval) this.solicitarAprovacao(false)
              this.carregarPedidos();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'MSG: ', detail: result.message });
              return;
            }
          }, error => this.httpService.handleError(error, () => this.atualizarPedido(escriturar)),
            () => this.httpService.done());
      }
    }
  }

  modalClonarPedido(event: any = null) {

    if (event == null) event = this.pedidosSelecionados[0];

    this.modalClonePedido = true;
    this.opcoesParticipante = [];
    this.pedido.id = event.id;
    this.pedido.participante = new Participante();
    // const participanteTipo = ((this.tipo == 1) ? 1 : 2);
    // let url = '/participante?$select=id,nome,fantasia,cpf,cnpj,participanteEmpresas/codigo,participanteEmpresas/fantasia,' +
    //   'participanteEmpresas/participanteTipo/id&$filter=participanteEmpresas/empresa/id eq ' + this.dblinkedSessionService.empresa.id +
    //   ' and participanteEmpresas/participanteTipo/id eq ' + participanteTipo;
    // url += '&$orderby=nome';
    // this.httpService.wait();
    // this.httpService.get(url).subscribe(data => {
    //   let participanteEmpresa;
    //   let codigo;
    //   let fantasia;

    //   data.value.forEach(element => {

    //     if (element.participanteEmpresas) {
    //       participanteEmpresa = element.participanteEmpresas.find(pe => pe['participanteTipoId'] === participanteTipo);
    //       if (participanteEmpresa) {
    //         codigo = participanteEmpresa.codigo;
    //       }
    //       if (participanteEmpresa.fantasia && participanteEmpresa.fantasia.trim() !== '') {
    //         fantasia = codigo + ' - ' + participanteEmpresa.fantasia;
    //       }
    //     } else if (element.fantasia === undefined || element.fantasia === null || element.fantasia.trim() === '') {
    //       fantasia = ' X0000 ' + element.nome.trim();
    //     }
    //     this.opcoesParticipante.push({ label: fantasia, value: element.id });
    //   });


    //   this.pedido.participante.id = event.participanteId;
    // }, error => this.httpService.handleError(error, () => this.modalClonarPedido(event)),
    //   () => this.httpService.done());
    // status possíveis na NFSE
    // 4 - Aquisição Serviço
    this.cloneStatusPedido = null;
    this.cloneDataEmissao = null;
    this.pedido.pedidoTipo = new PedidoTipo();
    this.pedido.pedidoTipo.id = 4;

  }

  alterarItemLista(pedidoItemId: any) {
    if (this.pedidoItem.operacaoRegra.id === null || this.pedidoItem.operacaoRegra.id === undefined || this.pedidoItem.operacaoRegra.id.toString().length === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Campo Operação é Obrigatório!' });
      return;
    } if (this.pedidoItem.item.id === null || this.pedidoItem.item.id === undefined || this.pedidoItem.item.id.toString().length === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Campo Item é Obrigatório!' });
      return;
    } if (this.pedidoItem.quantidade === null || this.pedidoItem.quantidade === undefined || this.pedidoItem.quantidade.toString().length === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Campo Quantidade é Obrigatório!' });
      return;
    } if (this.pedidoItem.valorUnit === null || this.pedidoItem.valorUnit === undefined || this.pedidoItem.valorUnit.toString().length === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Campo Valor Unitário é Obrigatório!' });
      return;
    }

    this.exibirItemPedido = false;
    this.botaoEditar = false;
    // alterando o item selecionado
    const index = this.pedido.pedidoItem.findIndex(c => c.id === pedidoItemId);
    this.pedido.pedidoItem.splice(index, 1);

    this.salvarItemLista(pedidoItemId);
  }


  itemOnRowSelect(event: any) {

    this.pedidoItensRateioEdicao = event.pedidoItensRateio;

    Promise.resolve(this.retornaIdOperacaoRegra(event.operacaoRegra.id, event.operacaoRegra.tipoRegra)).then(() => {

      setTimeout(() => {
        this.exibirItemPedido = true;
        this.botaoEditar = true;
        this.calculaParcelasPgto = true;
        this.pedidoItem = new PedidoItem(event.data);
        this.pedidoItem.id = event.id;
        this.pedidoItem.tipoDesconto = new TipoDesconto();
        this.pedidoItem.tipoDesconto.id = event.tipoDesconto.id;
        this.pedidoItem.operacaoRegra = new OperacaoRegra();
        this.pedidoItem.operacaoRegra.id = event.operacaoRegra.id;
        this.pedidoItem.operacaoRegra.cod = event.operacaoRegra.cod;
        this.pedidoItem.operacaoRegra.descricao = event.operacaoRegra.descricao;
        this.pedidoItem.item = new Item();
        this.pedidoItem.item.id = event.item.id;
        this.pedidoItem.item.codigo = event.item.codigo;
        this.pedidoItem.item.descricao = event.item.descricao;
        this.pedidoItem.quantidade = event.quantidade;
        this.pedidoItem.valorUnit = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(event.valorUnit));
        this.pedidoItem.valorDesconto = this.utilService.parseDecimalString(event.valorDesconto);
        this.pedidoItem.valorSaving = this.utilService.parseDecimalString(event.valorSaving);
        this.pedidoItem.observacaoSaving = event.observacaoSaving;
        this.calcularValorTotal();
      }, 1500);

    });
  }


  rateioItem(value: any) {
    this.pedidoItem = value;
    this.exibirRateio = true;


    if (this.pedidoItem.pedidoItensRateio.length === 0) {
      let itemRateio = new PedidoItemRateio();
      itemRateio.cadCcus = new CadCcus();
      itemRateio.cadProj = new CadProj();

      itemRateio.id = this.pedidoItem.pedidoItensRateio.length;
      this.pedidoItem.pedidoItensRateio.push(itemRateio);
    }

    this.visualizacaoContabil = new Array();
    this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);

  }

  controlCentroCusto() {
    this.modalCentroCusto = true;
    this.calculaRateioDisponivelCentroCusto();
  }

  incluirRateioCentroCusto() {

    // CENTRO DE CUSTO
    if ((this.cc <= 0 || this.cc.value === null)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'É preciso Selecionar um Centro de Custo'
      });
      return;
    }
    // iNSERIR PORCENTAGEM
    else if ((this.porcentagemCc <= 0) || isNullOrUndefined(this.porcentagemCc)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Por Favor, Insira a Porcentagem do Rateio!'
      });
      return;
    }
    // PORCENTAGEM UTILIZADO
    else if ((this.rateioCentroCusto <= 0) || (this.rateioCentroCusto < this.porcentagemCc)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'O valor do rateio ultrapassa os 100%, verifique!'
      });
      return;
    }

    this.pedidoItemRateio = new PedidoItemRateio();

    this.rateioCentroCusto = this.rateioCentroCusto - this.utilService.parseDecimal(this.porcentagemCc);
    this.pedidoItemRateio.id = this.contadorRateio;
    this.pedidoItemRateio.cadProj = this.proj;
    this.pedidoItemRateio.cadCcus = this.cc;
    this.pedidoItemRateio.porcentagem = this.porcentagemCc;
    this.pedidoItem.pedidoItensRateio.push(this.pedidoItemRateio);
    this.contadorRateio++;
  }

  replicarRateio() {
    if (confirm('Este Rateio será replicado para todos os outros itens, deseja continuar?')) {
      this.pedido.pedidoItem.forEach(itens => {

        if (itens.id !== this.pedidoItem.id) {

          itens.pedidoItensRateio = new Array();
          this.pedidoItem.pedidoItensRateio.forEach(element => {

            let req = new PedidoItemRateio();
            req.cadCcus = new CadCcus();
            req.cadCcus.descrCcus = element.cadCcus.descrCcus;
            req.cadCcus.codCcus = element.cadCcus.codCcus;
            req.cadCcus.id = element.cadCcus.id;
            req.cadProj = new CadProj();

            if (element.cadProj) {
              req.cadProj.id = element.cadProj.id;
              req.cadProj.codProj = element.cadProj.codProj;
              req.cadProj.descrProj = element.cadProj.descrProj;
            }

            this.modalCentroCusto = false;
            req.porcentagem = element.porcentagem;
            itens.pedidoItensRateio.push(req);
          });

          this.visualizacaoContabil = new Array();
          this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);

        }
      });
    }
  }

  removerRateioCentroCusto(value: any) {
    if (confirm('Deseja excluir este rateio?')) {
      const index = this.pedidoItem.pedidoItensRateio.findIndex(it => it.id === value);
      this.pedidoItem.pedidoItensRateio.splice(index, 1);
      this.calculaRateioDisponivelCentroCusto();
    }

    this.visualizacaoContabil = new Array();
    this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);
  }

  emitirNf() {
    this.msgs = [];

    if (this.pedido.pedidoTipo.id === 2) {
      if (this.pedido.pedidoStatus.id != 4) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Status informado não Permite Emissão!' })
        return;
      }
      if (this.pedido.modNf.id != 25) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Modelo informado não Permite Emissão!' })
        return;
      }
      // Venda Exportação
      if (this.pedidoExterior) {
        if (!this.pedido.moeda.id || this.pedido.moeda.id === undefined || this.pedido.moeda.id === null) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Selecione a Moeda para Prosseguir com a Emissão!' })
          return;
        }
        if ((this.pedido.txMoeda != 1) && (this.pedido.txMoeda === null || this.pedido.txMoeda === undefined || this.pedido.txMoeda === 0)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Informe a Taxa para Prosseguir com a Emissão!' })
          return;
        }
        if (this.pedido.numeroInvoice === null || this.pedido.numeroInvoice === undefined || this.pedido.numeroInvoice === "") {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Informe o Número da Invoice para Prosseguir com a Emissão!' })
          return;
        }
        if (this.pedido.dataInvoice === null || this.pedido.dataInvoice === undefined) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Selecione a Data da Invoice para Prosseguir com a Emissão!' })
          return;
        }
        if (this.pedido.txMoeda != 1 && (this.pedido.converterTaxa === false)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Converta a Taxa Prosseguir com a Emissão!' })
          return;
        }
      }
    }

    if (confirm('Deseja realmente emitir esta NFS?')) {
      this.httpService.wait();
      this.httpService.post('/custom/enotas/emitir-nfse(' + this.pedido.id + ',' + this.flowUses + ')')
        .subscribe(result => {
          if (result.error) {

            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Impossibilita Emissão: ', detail: result.message });
          } else {

            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message });
            this.carregarPedidos();
          }
        }, error => this.httpService.handleError(error, () => this.emitirNf()),
          () => this.httpService.done());
    }
  }

  emitirNfsLote() {

    for (let pedido of this.pedidosSelecionados) {
      if (pedido.pedido_tipo_id === 2) {
        if (pedido.pedido_status_id != 4 && pedido.pedido_status_id != 3) {
          this.msgs = [{ severity: 'warn', summary: 'MSG: ', detail: 'O campo Status informado no pedido: ' + pedido.codigo + ' não permite emissão!' }];
          return;
        }
        if (pedido.mod_nf_id != 25) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Modelo Informado no pedido: ' + pedido.codigo + ' não Permite Emissão!' })
          return;
        }
        if (pedido.codigo.indexOf('I') > 0) {
          if (!pedido.moeda_id || pedido.moeda_id === undefined || pedido.moeda_id === null) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Selecione a Moeda do pedido: ' + pedido.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (pedido.tx_moeda === null || pedido.tx_moeda === undefined || pedido.tx_moeda === 0) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Informe a Taxa do pedido: ' + pedido.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (pedido.numero_invoice === null || pedido.numero_invoice === undefined || pedido.numero_invoice === "") {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Informe o Número da Invoice do pedido: ' + pedido.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (pedido.dt_invoice === null || pedido.dt_invoice === undefined) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Selecione a Data da Invoice do pedido: ' + pedido.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
          if (pedido.tx_moeda != 1 && (pedido.converter_taxa === false)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Converta a Taxa do pedido: ' + pedido.codigo + ' para Prosseguir com a Emissão!' })
            return;
          }
        }
      }
    }

    let ids = [];
    if (confirm('Deseja realmente emitir estas NFS?')) {
      this.pedidosSelecionados.forEach(element => {
        if (element.pedido_status_id == 4 || element.pedido_status_id == 3)
          ids.push(element.id);
      });

      const listasEnviar = {
        pedido: ids,
        usuario: this.sessionService.loggedUser.id,
        usarFluxo: this.flowUses
      };

      this.httpService.wait();
      this.httpService.post('/custom/enotas/emitir-nfse-lote', listasEnviar)
        .subscribe(res => {

          if (res.error) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Impossibilita Emissão: ', detail: res.message });
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: res.sucesso });

          }

          this.carregarPedidos()


        },
          error => this.httpService.handleError(error, () => this.emitirNfsLote()),
          () => this.httpService.done());
    }
  }

  // solicitarAprovacao() {



  //   if (confirm('Deseja solicitar a aprovação do(s) pedido(s)?')) {

  //     const listasEnviar = {
  //       pedido: this.pedido.id,
  //       usuario: this.sessionService.loggedUser.id
  //     };

  //     this.httpService.wait();
  //     this.httpService.post('/custom/pedido/solicitar-aprovacao-pedido', listasEnviar)
  //       .subscribe(result => {

  //         if (result.error) {
  //           this.msgs = [];
  //           this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: result.message });
  //         } else {
  //           this.msgs = [];
  //           this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: result.message });
  //         }
  //         this.inicializar();
  //       },
  //         error => this.httpService.handleError(error, () => this.solicitarAprovacao()),
  //         () => this.httpService.done());
  //   }
  // }

  modalStatusPedidosLote() {
    this.modalStatusPedidoLote = true;
    this.cloneStatusPedido = null;
    this.cloneDataEmissao = null;
    this.pedido.pedidoTipo = new PedidoTipo();
  }

  aprovarPedidosLote() {

    if (confirm('Deseja alterar o status destes pedidos?')) {

      let pedidosStatus = '';
      this.pedidosSelecionados.forEach(pedidoStatus => {
        pedidosStatus += pedidoStatus.id + ',';
      });

      const status = {
        pedidoId: pedidosStatus.substring(0, (pedidosStatus.length - 1)),
        statusId: this.cloneStatusPedido,
        usuarioId: this.sessionService.loggedUser.id,
        atualizacaoPedido: this.atualizacaoPedido,
        faturaAutomaticoLote: this.faturaAutomaticoLote,
        dtEmissaoNova: this.dtEmissaoNova
      };

      this.httpService.wait();
      this.httpService.post('/custom/pedido/alterar-status-pedido-id', status)
        .subscribe(result => {
          result.forEach(el => {
            if (el.error) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: el.message });
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: el.message });
              this.carregarPedidos();
            }

            this.modalStatusPedidoLote = false;

          });

        },
          error => this.httpService.handleError(error, () => this.aprovarPedidosLote()),
          () => this.httpService.done());
    }
  }

  clonarPedido() {

    if (this.validacaoClonarPedido()) {
      return;
    }

    let pedidosClone = '';
    this.pedidosSelecionados.forEach(pedidoClone => {
      pedidosClone += pedidoClone.id + ',';
    });

    const clone = {
      pedidoId: pedidosClone.substring(0, (pedidosClone.length - 1)),
      statusId: this.cloneStatusPedido,
      participante: this.pedido.participante.id,
      dtEmissao: this.pedido.dtEmissao,
      dtEntrega: this.pedido.dtEntrega,
      dtSaidaEntrada: this.pedido.dtFaturamentoAutomatico,
      empresa: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id
    };
    this.httpService.wait();
    this.httpService.post('/custom/pedido/clonar-pedido-id', clone)
      .subscribe(result => {
        this.modalClonePedido = false
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: result[0].message });

        result.forEach(row => {
          if (!row.error) {
            this.LogManagement.registerLog(
              [3, 4, 5].includes(this.pedidosSelecionados[0].pedido_tipo_id) ? LogModule.PURCHASES : LogModule.SALES,
              [3, 4, 5].includes(this.pedidosSelecionados[0].pedido_tipo_id) ? LogScreen.SERVICE_SALES_ORDER : LogScreen.SERVICE_SALES_ORDER,
              '/custom/pedido/clonar-pedido-id',
              LogAction.CREATE,
              row.id,
              row.codigo,
              `O pedido ${row.codigo} foi criado a partir do pedido ${row.codigoOrig}`,
              clone
            )
          }
        })

        this.carregarPedidos();
      },
        error => this.httpService.handleError(error, () => this.clonarPedido()),
        () => this.httpService.done());
  }


  validacaoClonarPedido() {

    if (isNullOrUndefined(this.cloneStatusPedido)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar um status do pedido!'
      });
      return true;
    }

    if (isNullOrUndefined(this.pedido.dtEmissao)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar a data de emissão!'
      });
      return true;
    }

    if (isNullOrUndefined(this.pedido.participante.id)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar o participante!'
      });
      return true;
    }
    return false;
  }

  gerarContratos() {

    if (this.pedido.formaPagamento.id) {
      if (this.pedido.dtEmissao) {
        if (confirm('Os Contratos serão gerados a partir deste pedido até a data de término informada, deseja continuar?')) {
          const listasEnviar = {
            pedido: this.pedido.id,
            termino: this.pedido.dtFinalContrato,
            usuario: this.sessionService.loggedUser.id
          };

          this.httpService.wait();
          this.httpService.post('/custom/pedido/gerar-contratos', listasEnviar)
            .subscribe(result => this.carregarPedidos(),
              error => this.httpService.handleError(error, () => this.gerarContratos()),
              () => this.httpService.done());
        }
      } else {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Data de Emissão é obrigatória!'
        });
        return;
      }
    } else {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Forma de Pagamento é obrigatória!'
      });
      return;
    }
  }

  escriturarNfServicoLote(pedidosSelecionados: any) {

    for (let pedido of pedidosSelecionados) {
      if (pedido.pedido_tipo_id === 4) {
        if (pedido.num_nf === null || pedido.num_nf === undefined || pedido.num_nf.trim() === '') {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Informe o Número do Documento do pedido: ' + pedido.codigo + ' para Prosseguir com a Escrituração!' });
          return;
        }
        if (pedido.codigo.indexOf('I') > 0) {
          if (!pedido.moeda_id || pedido.moeda_id === undefined || pedido.moeda_id === null) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione a Moeda do pedido: ' + pedido.codigo + ' para Prosseguir com a Escrituração!' });
            return;
          }
          if (pedido.tx_moeda === null || pedido.tx_moeda === undefined || pedido.tx_moeda === 0) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Informe a Taxa do pedido: ' + pedido.codigo + ' para Prosseguir com a Escrituração!' });
            return;
          }
          if (pedido.tx_moeda != 1 && (pedido.converter_taxa === false)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Converta a Taxa do pedido: ' + pedido.codigo + ' para Prosseguir com a Escrituração!' });
            return;
          }
        }
      }
    }

    let listaIds = [];
    if (confirm('Deseja realmente escriturar estas NFS?')) {
      pedidosSelecionados.forEach(pedido => {
        listaIds.push(pedido.id);
      });

      const listasEnviar = {
        pedidos: listaIds,
        usuario: this.sessionService.loggedUser.id,
        usarFluxo: this.flowUses
      };

      this.httpService.wait();
      this.httpService.post('/custom/pedido/escriturar-lote', listasEnviar)
        .subscribe(result => {

          result.forEach(msg => {
            this.messageService.add({ severity: msg.error ? 'error' : 'success', key: 'messageLinnks', summary: 'MSG: ', detail: msg.message });
            if (!msg.error) this.permiteResetarLazyload = true
          });

          if (this.permiteResetarLazyload) this.carregarPedidos()

        },
          error => this.httpService.handleError(error, () => this.escriturarNfServicoLote(pedidosSelecionados)),
          () => this.httpService.done());
    }
  }

  modalClonarPedidosLote() {
    // status possíveis na NFSE
    // 2 - Venda Serviço
    this.cloneStatusPedido = null;
    this.cloneDataEmissao = null;
    this.pedido.pedidoTipo = new PedidoTipo();
    this.pedido.pedidoTipo.id = 2;
    this.httpService.wait();
    this.httpService.get('/pedidostatus?$select=id,description&$orderby=id')
      .subscribe(ref => { this.comboPedidoStatus(ref.value); this.modalClonePedidoLote = true; },
        error => this.httpService.handleError(error, () => this.modalClonarPedidosLote()),
        () => this.httpService.done());

  }

  async clonarPedidosLote(): Promise<void> {
    if (this.pedido.dtEmissao === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Data para o clone do pedido deve ser informada!' })
      return;
    }

    if (this.cloneStatusPedido === undefined || this.cloneStatusPedido === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Status do clone do pedido deve ser selecionado!' })
      return;
    }

    let pedidosClone = '';
    this.pedidosSelecionados.forEach(pedidoClone => pedidosClone += pedidoClone.id + ',')

    const clone = {
      pedidoId: pedidosClone.substring(0, (pedidosClone.length - 1)),
      statusId: this.cloneStatusPedido,
      empresa: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id,
      participante: null,
      dtEmissao: this.pedido.dtEmissao,
      dtEntrega: this.pedido.dtEntrega,
      dtSaidaEntrada: this.pedido.dtFaturamentoAutomatico,
    }

    this.httpService.wait()

    try {
      await this.httpService.post('/custom/pedido/clonar-pedido-id', clone).toPromise().then(res => {
        let count: number = 0
        this.modalClonePedidoLote = false

        res.forEach(item => {
          if (item.error) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: item.msg })
          else count++
        })

        if (count > 0 && count <= res.length) this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: 'Os pedidos foram clonados com êxito!' })

        res.forEach(row => {
          if (!row.error) {
            this.LogManagement.registerLog(
              [3, 4, 5].includes(this.pedidosSelecionados[0].pedido_tipo_id) ? LogModule.PURCHASES : LogModule.SALES,
              [3, 4, 5].includes(this.pedidosSelecionados[0].pedido_tipo_id) ? LogScreen.SERVICE_PURCHASES_ORDER : LogScreen.SERVICE_SALES_ORDER,
              '/custom/pedido/clonar-pedido-id',
              LogAction.CREATE,
              row.id,
              row.codigo,
              `O pedido ${row.codigo} foi criado a partir do pedido ${row.codigoOrig}`,
              clone
            )
          }
        })
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Houve um erro ao clonar os pedidos! Contate o suporte!' })
    } finally {
      this.httpService.done()
      this.carregarPedidos();
    }
  }


  comboPedidoStatus(value: Array<any>) {

    this.opcoesStatusPedido = [];

    /* 1 - Venda Produto
       2 - Venda Serviço
       3 - Aquisição Produto
       4 - Aquisição Serviço*/

    /* 1 - Em negociação
    2 - Aprovado
    3 - Recusado
    4 - Venda
    5 - Finalizado
    6 - Excluído
    7 - Compra
    10 - Compra Direta
    11 - Venda Direta*/
    value.forEach(element => {
      if ((element.id === 1 || element.id === 4 || element.id === 12)
        && (this.pedido.pedidoTipo.id === 1 || this.pedido.pedidoTipo.id === 2)) {
        this.opcoesStatusPedido.push({ label: element.description, value: element.id });
      } else if ((element.id === 1 || element.id === 7 || element.id === 12)
        && (this.pedido.pedidoTipo.id === 3 || this.pedido.pedidoTipo.id === 4)) {
        this.opcoesStatusPedido.push({ label: element.description, value: element.id });
      }
    });
  }

  ExisteParticipanteContato(participaneContato) {

    this.telefoneContato = (isNullOrUndefined(participaneContato.telefoneContato) || (participaneContato.telefoneContato === ""));

    if (!isNullOrUndefined(participaneContato.nomeContato) && isNullOrUndefined(participaneContato.emailContato) && this.telefoneContato) {
      this.msg = participaneContato.nomeContato;
      return this.msg;
    }
    else if (!isNullOrUndefined(participaneContato.nomeContato) && !isNullOrUndefined(participaneContato.emailContato) && this.telefoneContato) {
      this.msg = participaneContato.nomeContato + " - e-mail: " + participaneContato.emailContato;
      return this.msg;
    }
    else if (!isNullOrUndefined(participaneContato.nomeContato) && !isNullOrUndefined(participaneContato.emailContato) && !this.telefoneContato) {
      this.msg = participaneContato.nomeContato + " - e-mail: " + participaneContato.emailContato + " - telefone: " + participaneContato.telefoneContato;
      return this.msg;
    }
    else if (isNullOrUndefined(participaneContato.nomeContato) && !isNullOrUndefined(participaneContato.emailContato) && !this.telefoneContato) {
      this.msg = "e-mail: " + participaneContato.emailContato + " - telefone: " + participaneContato.telefoneContato;
      return this.msg;
    }
    else if (isNullOrUndefined(participaneContato.nomeContato) && isNullOrUndefined(participaneContato.emailContato) && !this.telefoneContato) {
      this.msg = "telefone: " + participaneContato.telefoneContato;
      return this.msg;
    }
    else if (isNullOrUndefined(participaneContato.nomeContato) && !isNullOrUndefined(participaneContato.emailContato) && this.telefoneContato) {
      this.msg = "e-mail: " + participaneContato.emailContato;
      return this.msg;
    }
    else if (!isNullOrUndefined(participaneContato.nomeContato) && isNullOrUndefined(participaneContato.emailContato) && !this.telefoneContato) {
      this.msg = participaneContato.nomeContato + " - telefone: " + participaneContato.telefoneContato;
      return this.msg;
    }
    else if (isNullOrUndefined(participaneContato.nomeContato) && isNullOrUndefined(participaneContato.emailContato) && this.telefoneContato) {
      this.msg = "Participante não possui um contato"
      return this.msg;
    } else {
      this.msg = "Participante não possui um contato"
      return this.msg;
    }
  }

  verificaOperacaoRegraGeraFinanceiro(possuiPedidoItem): any {

    this.operacaoRegraIds = new Array();

    this.pedido.pedidoItem.forEach(element => {
      this.operacaoRegraIds.push(element.operacaoRegra.id);
    });

    let listaEnviar = {
      operacaoRegraIds: this.operacaoRegraIds
    }

    let geraFinanceiro: boolean;

    if (possuiPedidoItem && this.pedido.pedidoItem.length) {
      this.httpService.wait();
      this.httpService.post('/custom/pedido/verifica-operacao-regras-que-geram-financeiro', listaEnviar)
        .subscribe(res => {
          geraFinanceiro = res.contains;
          if (this.operacaoRegraGeraFinanceiro !== geraFinanceiro) {
            this.operacaoRegraGeraFinanceiro = geraFinanceiro;
            this.comboTipoPagamento(geraFinanceiro);

            if (!geraFinanceiro) {
              this.pedido.valorInss = this.utilService.parseMoneyString(0.00);
              this.pedido.valorIr = this.utilService.parseMoneyString(0.00);
              this.pedido.valorCsll = this.utilService.parseMoneyString(0.00);
              this.pedido.valorPis = this.utilService.parseMoneyString(0.00);
              this.pedido.valorCofins = this.utilService.parseMoneyString(0.00);
              this.pedido.valorIssRetido = this.utilService.parseMoneyString(0.00);
              this.pedido.valorOutrasRetencoes = this.utilService.parseMoneyString(0.00);
              this.pagamentoForma();
              this.atualizarSubtotal();
            }
          }
        },
          error => this.httpService.handleError(error, () => this.verificaOperacaoRegraGeraFinanceiro(possuiPedidoItem)),
          () => this.httpService.done());

    } else {
      geraFinanceiro = false;
      if (this.operacaoRegraGeraFinanceiro !== geraFinanceiro) {
        this.operacaoRegraGeraFinanceiro = geraFinanceiro;
        this.comboTipoPagamento(geraFinanceiro);

        if (geraFinanceiro) {
          this.pedido.valorInss = this.utilService.parseMoneyString(0.00);
          this.pedido.valorIr = this.utilService.parseMoneyString(0.00);
          this.pedido.valorCsll = this.utilService.parseMoneyString(0.00);
          this.pedido.valorPis = this.utilService.parseMoneyString(0.00);
          this.pedido.valorCofins = this.utilService.parseMoneyString(0.00);
          this.pedido.valorIssRetido = this.utilService.parseMoneyString(0.00);
          this.pedido.valorOutrasRetencoes = this.utilService.parseMoneyString(0.00);
          this.pagamentoForma();
          this.atualizarSubtotal();
        }
      }
    }
  }

  retornaCodigoMoeda(moedaId): any {

    this.httpService.wait();
    this.httpService.get('/custom/pedido/retorna-codigo-moeda(' + moedaId + ')')
      .subscribe(moeda => {
        this.codigoMoeda = moeda.codigo;
      },
        error => this.httpService.handleError(error, () => this.retornaCodigoMoeda(moedaId)),
        () => this.httpService.done());
  }

  alterarValorUnitItens() {

    this.pedido.pedidoItem.forEach(element => {
      if (!this.pedido.converterTaxa) {
        element.valorUnit = this.utilService.parseDecimal6(element.valorUnit) * this.utilService.parseDecimal6(this.pedido.txMoeda);
        element.valorDesconto = this.utilService.parseDecimal6(element.valorDesconto) * this.utilService.parseDecimal6(this.pedido.txMoeda);
        element.valorTotal = (this.utilService.parseDecimal6(element.quantidade) * this.utilService.parseDecimal6(element.valorUnit)) -
          this.utilService.parseDecimal6(element.valorDesconto);

      } else {
        element.valorUnit = this.utilService.parseDecimal6(element.valorUnit) / this.utilService.parseDecimal6(this.pedido.txMoeda);
        element.valorDesconto = this.utilService.parseDecimal6(element.valorDesconto) / this.utilService.parseDecimal6(this.pedido.txMoeda);
        element.valorTotal = (this.utilService.parseDecimal6(element.quantidade) * this.utilService.parseDecimal6(element.valorUnit)) -
          this.utilService.parseDecimal6(element.valorDesconto);
      }
    });

    this.visualizacaoContabil = new Array();
    this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);

  }

  retornaIdOperacaoRegra(operacaoRegraId, tipoRegraContabil) {


    if (!isNullOrUndefined(operacaoRegraId)) {
      this.operacaoRegraId = operacaoRegraId;
    } else {
      this.operacaoRegraId = 0;
    }

    let isServico = (tipoRegraContabil == 2 || tipoRegraContabil == 5) ? true : false;

    this.opcoesItens = [];
    this.opcoesItens.push({ label: 'SELECIONE O ITEM', value: 0 });
    if (this.pedido.pedidoTipo.id === 2 || this.pedido.pedidoTipo.id === 1) {
      this.httpService.wait();
      this.httpService.get('/custom/pedido/buscar-item-venda('
        + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ',' + this.pedido.pedidoTipo.id + ',' + operacaoRegraId + ')')
        .subscribe(ref => {
          if (!isNullOrUndefined(ref)) {
            ref.forEach(element => {

              if ((!isServico) && element.itemTipo !== 10) {
                this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
              } else if ((isServico) && element.itemTipo === 10) {
                this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
              }
            });
          }

        },
          error => this.httpService.handleError(error, () => this.retornaIdOperacaoRegra(operacaoRegraId, tipoRegraContabil)),
          () => this.httpService.done());
    } else {
      this.httpService.wait();
      this.httpService.get('/custom/pedido/buscar-item-compra('
        + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ',' + this.pedido.pedidoTipo.id + ',' + operacaoRegraId + ')')
        .subscribe(ref => {
          if (!isNullOrUndefined(ref)) {
            ref.forEach(element => {
              if ((!isServico) && element.itemTipo !== 10) {
                this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
              } else if ((isServico) && (element.itemTipo === 10 || element.itemTipo === 12)) {
                this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
              }
            });
          }



        },
          error => this.httpService.handleError(error, () => this.retornaIdOperacaoRegra(operacaoRegraId, tipoRegraContabil)),
          () => this.httpService.done());

    }
  }

  comboPedidoFaturamento() {
    if (this.pedido.pedidoItem) {
      this.opcoesPedidoFaturamento = [];
      this.opcoesPedidoFaturamento.push({ label: ('Nenhum'), value: null });
      this.pedido.pedidoItem.forEach(element => {
        this.opcoesPedidoFaturamento.push({ label: (element.item.codigo + ' - ' + element.item.descricao), value: element.item.id });
      });
    }
  }

  previewPedido(event: any) {

    this.visualizacaoContabil = new Array();
    this.visualizacaoFiscal = new Array();

    this.httpService.wait();
    this.httpService.get('/custom/pedido/buscar-infos-pedido(' + (event.id) + ')')
      .subscribe(res => {

        this.pedido = new Pedido();
        this.pedido.itemFaturamento = new Item();
        this.pedido.participanteEmpresaId = res.participanteEmpresaId;
        this.pedido.id = res.id;

        if (!isNullOrUndefined(res.localPrestacao)) {
          this.pedido.localPrestacao = res.localPrestacao;
        }

        this.pedido.codigo = res.codigo;
        this.pedido.numNf = res.numNf;
        this.pedido.serie = res.serie;
        this.pedido.dtEntrega = res.dtEntrega;
        this.pedido.dtEmissao = res.dtEmissao;
        this.pedido.dtFaturamentoAutomatico = res.dtFaturamentoAutomatico;
        this.pedido.enderecoCobranca = res.enderecoCobranca;
        this.pedido.enderecoEntrega = res.enderecoEntrega;
        this.pedido.dadosAdicionais = res.dadosAdicionais;
        this.pedido.codigoOrcamento = res.codigoOrcamento;
        this.pedido.codigoControle = res.codigoControle;
        this.pedido.valorInss = this.utilService.parseDecimal(res.valorInss);
        this.pedido.valorIr = this.utilService.parseDecimal(res.valorIr);
        this.pedido.valorCsll = this.utilService.parseDecimal(res.valorCsll);
        this.pedido.valorPis = this.utilService.parseDecimal(res.valorPis);
        this.pedido.valorCofins = this.utilService.parseDecimal(res.valorCofins);
        this.pedido.valorPisFaturamento = this.utilService.parseDecimal(res.valorPisFaturamento);
        this.pedido.valorCofinsFaturamento = this.utilService.parseDecimal(res.valorCofinsFaturamento);
        this.pedido.valorIssRetido = this.utilService.parseDecimal(res.valorIssRetido);
        this.pedido.valorIss = this.utilService.parseDecimal(res.valorIss);
        this.pedido.valorOutrasRetencoes = this.utilService.parseDecimal(res.valorOutrasRetencoes);
        this.pedido.txMoeda = this.utilService.parseDecimal6(res.txMoeda);
        this.pedido.numeroInvoice = res.numeroInvoice;
        this.pedido.dataInvoice = res.dataInvoice;
        this.pedido.dataInicial = res.dataInicial;
        this.pedido.dataFinal = res.dataFinal;
        this.codigoMoeda = res.moeda.codigo;
        this.pedido.converterTaxa = res.converterTaxa;
        this.pedido.itemFaturamento.id = res.itemFaturamento;
        this.pedido.dataUltimaAtualizacao = res.dataAtualizacao;
        this.vendedorId = res.vendedorId;
        this.operacaoRegraAntecipado = res.operacaoRegraAntecipado;
        this.pedido.itemFaturamento.id = res.itemFaturamento;
        this.idItemFaturamento = res.itemFaturamento;


        this.pedido.participante = new Participante();
        this.pedido.participante = res.participante;

        if (!isNullOrUndefined(res.pedidoTipo)) {
          this.pedido.pedidoTipo = new PedidoTipo();
          this.pedido.pedidoTipo = res.pedidoTipo;
        } else {
          this.pedido.pedidoTipo = new PedidoTipo();
        }

        if (!isNullOrUndefined(res.pedidoStatus)) {
          this.pedido.pedidoStatus = new PedidoStatus();
          this.pedido.pedidoStatus = res.pedidoStatus;
        } else {
          this.pedido.pedidoStatus = new PedidoStatus();
        }

        if (!isNullOrUndefined(res.modNf)) {
          this.pedido.modNf = new ModNf();
          this.pedido.modNf = res.modNf;
        } else {
          this.pedido.modNf = new ModNf();
        }

        if (!isNullOrUndefined(res.moeda)) {
          this.pedido.moeda = new Moeda();
          this.pedido.moeda = res.moeda;
        } else {
          this.pedido.moeda = new Moeda();
        }

        if (!isNullOrUndefined(res.tipoIncoterm)) {
          this.pedido.tipoIncoterm = new TipoIncoterm();
          this.pedido.tipoIncoterm = res.tipoIncoterm;
        } else {
          this.pedido.tipoIncoterm = new TipoIncoterm();
        }

        if (!isNullOrUndefined(res.formaPagamento)) {
          this.pedido.formaPagamento = new FormaPagamento();
          this.pedido.formaPagamento = res.formaPagamento;
        } else {
          this.pedido.formaPagamento = new FormaPagamento();
        }

        if (!isNullOrUndefined(res.empresaConta)) {
          this.pedido.empresaConta = new EmpresaConta();
          this.pedido.empresaConta = res.empresaConta;
        } else {
          this.pedido.empresaConta = new EmpresaConta();
        }

        if (!isNullOrUndefined(res.empresa)) {
          this.pedido.empresa = new Empresa();
          this.pedido.empresa = res.empresa;
        } else {
          this.pedido.empresa = new Empresa();
        }

        if (isNull(res.tipoPagamento)) {
          this.pedido.tipoPagamento = 1;
        } else {
          this.pedido.tipoPagamento = res.tipoPagamento;
        }

        this.pedido.pedidoParcela = new Array();
        res.pedidoParcela.forEach(parcela => {
          let pedParcela = new PedidoParcela();
          pedParcela.valor = this.utilService.parseDecimal(parcela.valor);
          pedParcela.dtVencimento = this.utilService.dateWithoutTimeZone(parcela.dtVencimento);
          pedParcela.parcela = parcela.parcela;
          pedParcela.historico = parcela.historico;

          if (!isNullOrUndefined(parcela.tipoBaixa)) {
            pedParcela.tipoBaixa = parcela.tipoBaixa;
          }

          pedParcela.empresaConta = new EmpresaConta()
          if (parcela.empresaConta) {
            pedParcela.empresaConta.id = parcela.empresaConta.id
            pedParcela.empresaConta.nome = parcela.empresaConta.nome

          } else {
            pedParcela.empresaConta.id = res.empresaConta.id
            pedParcela.empresaConta.nome = res.empresaConta.nome
          }

          this.pedido.pedidoParcela.push(pedParcela);
        });


        this.pedido.pedidoItem = new Array();

        res.pedidoItens.forEach(pedItem => {



          let pedidoItem = new PedidoItem();

          pedidoItem.id = pedItem.id;

          if (!isNullOrUndefined(pedItem.tipoDesconto)) {
            pedidoItem.tipoDesconto = new TipoDesconto();
            pedidoItem.tipoDesconto = pedItem.tipoDesconto;
          } else {
            pedidoItem.tipoDesconto = new TipoDesconto();
          }

          if (!isNullOrUndefined(pedItem.item)) {
            pedidoItem.item = new Item();
            pedidoItem.item = pedItem.item;
          } else {
            pedidoItem.item = new Item();
          }

          if (!isNullOrUndefined(pedItem.operacaoRegra)) {
            pedidoItem.operacaoRegra = new OperacaoRegra();
            pedidoItem.operacaoRegra = pedItem.operacaoRegra;

          } else {
            pedidoItem.operacaoRegra = new OperacaoRegra();
          }

          pedidoItem.quantidade = pedItem.quantidade;
          pedidoItem.valorUnit = pedItem.valorUnit;
          pedidoItem.valorDesconto = pedItem.valorDesconto;
          pedidoItem.valorTotal = pedItem.valorTotal;
          pedidoItem.valorIss = pedItem.valorIss;
          pedidoItem.valorIssRet = pedItem.valorIssRet;

          pedidoItem.pedidoItensRateio = new Array();

          this.somaRateio = 0.00;
          pedItem.pedidoItensRateio.forEach(rat => {

            this.somaRateio = this.somaRateio + rat.porcentagem;
            let itemRateio = new PedidoItemRateio();


            itemRateio.porcentagem = rat.porcentagem;

            if (!isNullOrUndefined(rat.cadCcus)) {
              itemRateio.cadCcus = new CadCcus();
              itemRateio.cadCcus = rat.cadCcus;
            } else {
              itemRateio.cadCcus = new CadCcus();
            }

            if (!isNullOrUndefined(rat.cadProj)) {
              itemRateio.cadProj = new CadProj();
              itemRateio.cadProj = rat.cadProj;
            } else {
              itemRateio.cadProj = new CadProj();
            }
            itemRateio.id = rat.id;
            pedidoItem.pedidoItensRateio.push(itemRateio);

          });

          if (this.somaRateio < 100) {
            let itemRateio = new PedidoItemRateio();
            itemRateio.cadCcus = new CadCcus();
            itemRateio.cadProj = new CadProj();
            pedidoItem.pedidoItensRateio.push(itemRateio);
          }
          this.pedido.pedidoItem.push(pedidoItem);
        });


        const listasEnviar = {
          pedido: JSON.stringify(this.pedido, null, ''),
          empresa: (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId),
        };

        this.pedido.valorInss = 0.00;
        this.pedido.valorIr = 0.00;
        this.pedido.valorCsll = 0.00;
        this.pedido.valorPis = 0.00;
        this.pedido.valorCofins = 0.00;
        this.pedido.valorIssRetido = 0.00;
        this.pedido.valorIss = 0.00;
        this.pedido.valorOutrasRetencoes = 0.00;
        this.pedido.valorPisFaturamento = 0.00;
        this.pedido.valorCofinsFaturamento = 0.00;

        this.httpService.wait();
        this.httpService.post('/custom/pedido/buscar-impostos-pedido', listasEnviar)
          .subscribe(res => {
            res.value.forEach(element => {

              this.pedido.pedidoItem.forEach(pedidoItem => {
                if (element.pedidoItemid === pedidoItem.id) {
                  pedidoItem.valorCide = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCide));
                  pedidoItem.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofins));
                  pedidoItem.valorCofinsRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofinsRet));
                  pedidoItem.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
                  pedidoItem.valorSimples = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
                  pedidoItem.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPis));
                  pedidoItem.valorPisRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPisRet));
                  pedidoItem.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIss));
                  pedidoItem.valorIssRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIssRet));
                  pedidoItem.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorInss));
                  pedidoItem.valorIrrf = (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
                  pedidoItem.temIrrf = element.temIrrf;
                }
              });


              this.pedido.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorInss) + this.utilService.parseDecimal(element.valorInss));
              this.pedido.valorIr = this.utilService.parseDecimal(this.pedido.valorIr) + (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
              this.pedido.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCsll) + this.utilService.parseDecimal(element.valorCsll));
              this.pedido.valorPisFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPisFaturamento) + this.utilService.parseDecimal(element.valorPis));
              this.pedido.valorCofinsFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofinsFaturamento) + this.utilService.parseDecimal(element.valorCofins));
              this.pedido.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPis) + this.utilService.parseDecimal(element.valorPisRet));
              this.pedido.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofins) + this.utilService.parseDecimal(element.valorCofinsRet));
              this.pedido.valorIssRetido = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIssRetido) + this.utilService.parseDecimal(element.valorIssRet));
              this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes) + this.utilService.parseDecimal(element.valorOutrasRetencoes));
              this.pedido.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIss) + this.utilService.parseDecimal(element.valorIss));
              this.visualizacaoFiscal.push(element);


            });

            setTimeout(() => {
              this.pedidoService.buscarPreviaContabil(this.pedido, this.visualizacaoContabil);
              this.visualizarEscrituracao();
            }, 500);
          }, error => this.httpService.handleError(error, () => this.previewPedido(event),
            () => this.httpService.done()));
      },
        error => this.httpService.handleError(error, () => this.previewPedido(event)),
        () => this.httpService.done());



  }


  onFilterPedido(event) {


    this.todosSelecionados1 = false;
    this.todosSelecionados2 = false;
    this.todosSelecionados3 = false;
    this.todosSelecionados4 = false;

    this.pedidosSelecionados.forEach(element => {
      element.selected = false;
    });

    this.pedidosSelecionados = new Array();
    this.idPedidoView = new Array();
    this.pedidosFiltrados = new Array();
    this.pedidosFiltrados = event.filteredValue;

  }

  selectPedidosFiltrados(element: any) {

    if (!isNullOrUndefined(this.pedidosFiltrados) && this.pedidosFiltrados.length > 0) {
      this.pedidosFiltrados.forEach(ped => {
        if (ped.id === element.id) {
          element.selected = true;
          this.pedidosSelecionados.push(element);
        }
      });
    } else {
      element.selected = true;
      this.pedidosSelecionados.push(element);
    }
  }

  //funcionalidade que chama APIs externas no Python @octavio
  emitirCobrancas() {
    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'custom/marketplace/emitir-cobranca-direta', this.pedidosSelecionados)
      .subscribe(result => {
        this.msgs = [];
        if (result["error"] == true) {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: result["msg"] });
        } else {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result["msg"] });
          this.carregarPedidos();
        }
      },
        error => this.httpService.handleError(error),
        () => this.httpService.done());

  }
  //fim @octavio

  showTelaPedidoServico(tipo) {
    this.statusNegociacao = false;
    this.statusCompras = false;
    this.statusRecusado = false;
    this.statusFinalizado = false;
    this.telaAtual = tipo

    if (tipo === 'TELA_1') {
      this.statusNegociacao = true
      this.numeroAba = 0
      this.valorTotalPedidos = this.valorTotalPedidosNegociacao

    } else if (tipo === 'TELA_2') {
      this.statusCompras = true
      this.numeroAba = 1
      this.valorTotalPedidos = this.valorTotalPedidosComprasVendas

    } else if (tipo === 'TELA_3') {
      this.statusRecusado = true
      this.numeroAba = 2
      this.valorTotalPedidos = this.valorTotalPedidosRecusado

    } else if (tipo === 'TELA_4') {
      this.statusFinalizado = true
      this.numeroAba = 3
      this.valorTotalPedidos = this.valorTotalPedidosFinalizado
    }

    this.determinarPrimeiraChamada()

    this.pedidosSelecionados = [];
    this.idPedidoView = [];
    this.clearArray = []

    this.todosSelecionados1 = false;
    this.todosSelecionados2 = false;
    this.todosSelecionados3 = false;
    this.todosSelecionados4 = false;
    // this.valorTotalPedidos = 0.00;
    this.valorTotalPedido = 0.00;

    // this.pedidosNegociacao.forEach(element => {
    //   element.selected = false;
    //   this.pedidosFiltrados = new Array();
    //   if (this.numeroAba === 0) {
    //     this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valorTotalPorItens);
    //   }
    // });

    // this.pedidosCompraVenda.forEach(element => {
    //   element.selected = false;
    //   this.pedidosFiltrados = new Array();
    //   if (this.numeroAba === 1) {
    //     this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valorTotalPorItens);
    //   }
    // });

    // this.pedidosRecusados.forEach(element => {
    //   element.selected = false;
    //   this.pedidosFiltrados = new Array();
    //   if (this.numeroAba === 2) {
    //     this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valorTotalPorItens);
    //   }
    // });

    // this.pedidosFinalizados.forEach(element => {
    //   element.selected = false;
    //   this.pedidosFiltrados = new Array();
    //   if (this.numeroAba === 3) {
    //     this.valorTotalPedidos = this.valorTotalPedidos + this.utilService.parseDecimal(element.valorTotalPorItens);
    //   }
    // });

    // this.numeroAba = this.numeroAba;
    // switch (this.numeroAba) {
    //   case 0:
    //     this.calcTotalValue(this.pedidosNegociacao)
    //     break;
    //   case 1:
    //     this.calcTotalValue(this.pedidosCompraVenda)
    //     break;
    //   case 2:
    //     this.calcTotalValue(this.pedidosRecusados)
    //     break;
    //   case 3:
    //     this.calcTotalValue(this.pedidosFinalizados)
    //     break;
    // }

    // this.atualizarValorPedidosSelecionados();
    this.changeToolbar();
  }

  // calcTotalValue(value: any) {
  //   let total = 0
  //   value.forEach((item: any) => { total += this.utilService.parseDecimal(item.valorTotalPorItensFormatado); })
  //   // this.totalValue = total
  // }

  /*Show Hide Card Pedido Servico*/
  isCardPedidoServico: boolean = true;
  ShowHIdePedidoServico() {
    this.isCardPedidoServico = !this.isCardPedidoServico;
  }

  alterarPeriodo() {

    this.validacaoError = false;

    const mes = this.dblinkedSessionService.periodo.month.value > 8 ?
      this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
    const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
    this.defaultDate = new Date(dt);

    let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
    // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
    lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
    this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

    if (this.dataIni !== null && this.dataFin !== null) {

      const mesIni = this.dataIni.getMonth() > 8 ?
        (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() > 8 ?
        (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
        this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 8) ?
        this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }

    const d1 = new Date(this.dataIniSearch);
    const d2 = new Date(this.dataFinSearch);
    if (d1 > d2) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'MSG: ', detail: 'Data inicial maior que a final. Verifique!' });
      this.validacaoError = true;
      return;
    } else {

      let dataIni = `${this.dataIniSearch.substring(8, 10)}/${this.dataIniSearch.substring(5, 7)}/${this.dataIniSearch.substring(0, 4)}`,
        dataFin = `${this.dataFinSearch.substring(8, 10)}/${this.dataFinSearch.substring(5, 7)}/${this.dataFinSearch.substring(0, 4)}`;

      this.subtitle = `Período selecionado ${dataIni} à ${dataFin}`

      this.modalPeriodo = false;

      // this.getQuantidadePedidos()

      this.primeiraChamadaNegociacao = true
      this.primeiraChamadaComprasVendas = true
      this.primeiraChamadaRecusado = true
      this.primeiraChamadaFinalizado = true

      this.determinarPrimeiraChamada()
    }
  }


  addDias(d, mes, dias) {

    var date;
    if ((typeof d === 'string') && (d.includes('/'))) {
      const str = d.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const day = Number(str[0]);

      date = new Date(year, month, day);
    } else {
      date = new Date(d);
    }

    // retorna 0 para janeiro e 11 para dezembro
    var n_date = new Date(date.getFullYear(), date.getMonth(), eval(dias + date.getDate()));
    return n_date
  }

  buscarVendedores() {

    this.skeletonConfig.startLoad();
    this.httpService.get('/custom/cadastro/buscar-vendedor(' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ',' +
      this.sessionService.loggedUser.id + ')')
      .subscribe(ret => {
        this.opcoesVendedor = [];
        this.opcoesVendedor.push({ label: 'Selecione um Vendedor', value: 0 });
        ret.forEach(element => {
          this.opcoesVendedor.push({ label: element.nome, value: element.id });
        })
      }
        ,
        error => this.httpService.handleError(error, () => this.buscarVendedores()),
        () => this.skeletonConfig.endLoad());
  }

  async buscarParametroFinanceiro(): Promise<void> {
    this.skeletonConfig.startLoad()
    try {
      await this.httpService.get('/empresaparametrofinanceiro?$select=id,pedidoMisto,operacaoRegraContabil/id,financeiroAntecipado,comissaoAtiva,filtroPedido&$filter=empresa/id eq '
        + this.dblinkedSessionService.empresa.id)
        .toPromise()
        .then(
          ret => {
            ret.value.forEach(element => {
              this.financeiroAntecipado = element.financeiroAntecipado
              this.operacaoRegraAntecipado = element.operacaoRegraContabil ? element.operacaoRegraContabil.id : 0
              this.comissaoAtiva = element.comissaoAtiva
              this.pedidoMisto = element.pedidoMisto
              this.filtroData = element.filtroPedido

            })
          }
        )
        .catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: "Erro ao recuperar as configurações financeiras da empresa, contate o suporte: " + err.message }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao recuperar as configurações financeiras da empresa, contate o suporte: " + err.message })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

  loadOperacao(value: Array<OperacaoRegraContabil>) {
    this.opcoesOperacaoCont = [];
    this.opcoesOperacaoCont.push({ label: 'Selecione', value: 0 });
    value.forEach(element => {
      this.opcoesOperacaoCont.push({ label: element.descricao, value: element.id });
    });
  }

  gerarBoletoFin() {
    let listaPedidosId = this.pedidosSelecionados.map(item => item.id);
    var liids = []
    var myMap = new Map();

    const listasEnviar = {
      pedidoIds: listaPedidosId,
      usarFluxo: this.flowUses
    };

    this.httpService.wait();
    this.httpService
      .post('/custom/pedido/gerar-financeiro-pedido', listasEnviar)
      .subscribe(result => {
        if (result.error || result.nfeIds.length == 0) {
          this.exibirMensagem(result);
          this.httpService.done();
          return;
        }

        const listasEnviar = {
          pedidos: result.nfeIds,
          empresa_id: this.dblinkedSessionService.empresa.id,
          usuario: this.sessionService.loggedUser.id
        };


        this.httpService.wait();
        this.httpService
          .post(`${this.configService.defaultUrlApiUpload}flaskfin/boleto/post-boleto`, listasEnviar)
          .subscribe(result => {
            if (result.error) {
              this.exibirMensagem(result);
              this.httpService.done();
              return;
            }

            liids.push(result.id)

            for (var [key, value] of Object.entries(result.codigo)) {
              myMap.set(parseInt(key), value)
            };

            liids[0].forEach(element => {

              const httpOptions2 = {
                headers: new HttpHeaders({
                  'Authorization': this.configService.bearerApi,
                  'Cache-Control': 'no-store, max-age=0',
                  'liids': element.toString(),
                  'codigo': myMap.get(parseInt(element))
                }),

                responseType: 'blob' as 'json'
              };

              this.httpService.wait();
              this.httpClient
                .get(`${this.configService.defaultUrlApiUpload}flaskfin/boleto/get-boleto-zip`, httpOptions2)
                .subscribe((res: Blob) => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);
                  link.href = url;
                  let data = new Date();
                  let fileName = myMap.get(parseInt(element)).toString()
                  link.download = fileName;
                  link.click();

                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                  error => this.httpService.handleError(error, () => this.carregarPedidos()),
                  () => this.httpService.done()
                );
            });
          },
            error => this.httpService.handleError(error, () => this.carregarPedidos()),
            () => this.httpService.done()
          );
      },

        error => this.httpService.handleError(error, () => this.carregarPedidos()),
        () => this.httpService.done()
      );
  }

  exibirMensagem(value: any) {
    let msgs = (value.mensagem || value.message || value.response || value.msg);
    this.msgs = [];
    if (value.error == true || value.erro == true) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: msgs });
    } else if (value.error == false || value.erro == false) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: msgs });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: 'Erro desconhecido' });
    }
  }

  centroDeCustoObrigatorio() {

    this.skeletonConfig.startLoad();
    this.httpService.get('/custom/pedido/centro-de-custo-obrigatorio(' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ')')
      .subscribe(element => {
        this.centroCustoObrigatorio = element.cadCcusObrigatorio;
      },
        error => this.httpService.handleError(error, () => this.centroDeCustoObrigatorio()),
        () => this.skeletonConfig.endLoad());
  }

  reenviarNf() {
    if (confirm('Deseja realmente realizar a emissão deste pedido?')) {
      let ids = [];
      if (confirm('Deseja realmente emitir estas NFS?')) {
        this.pedidosSelecionados.forEach(element => {
          if (element.pedido_status_id == 4 || element.pedido_status_id == 3)
            ids.push(element.id);
        });

        const listasEnviar = {
          pedido: ids,
          usuario: this.sessionService.loggedUser.id
        };

        this.httpService.wait();
        this.httpService.post('/custom/enotas/emitir-nfse-lote', listasEnviar)
          .subscribe(res => {

            this.msgs = [];

            if (res.error) {
              this.msgs.push({ severity: 'error', summary: 'Impossibilita Emissão: ', detail: res.message });
            } else {
              this.msgs.push({ severity: 'success', summary: 'Sucesso: ', detail: res.sucesso });
              this.carregarPedidos();
            }


          },
            error => this.httpService.handleError(error, () => this.emitirNfsLote()),
            () => this.httpService.done());
      }

    }
  }

  infosFormaPagto(id: number) {

    if (!id) {
      this.pedido.empresaConta = new EmpresaConta()
      this.pedido.pedidoParcela = new Array()
      return
    }

    this.permiteAlterarConta = true;

    this.httpService.wait();
    this.httpService.get('/formapagamento?$select=id,descricao,empresaConta/id,empresaConta/nome,permiteAlterarBanco'
      + '&$filter=id eq ' + id)
      .subscribe(ref => {

        let forma = new FormaPagamento();
        forma = ref.value[0];

        if (!isNullOrUndefined(forma.empresaConta)) {
          this.pedido.empresaConta = new EmpresaConta();
          this.pedido.empresaConta.id = forma.empresaConta.id;
          this.pedido.empresaConta.nome = forma.empresaConta.nome;
          this.permiteAlterarConta = forma.permiteAlterarBanco;
          this.pagamentoForma();
        }


      },
        error => this.httpService.handleError(error, () => this.infosFormaPagto(id)),
        () => this.httpService.done());
  }

  gerarFinanceiroPedido() {

    let pedidoIds = new Array();
    pedidoIds.push(this.pedido.id);

    if (confirm('Deseja realmente gerar o financeiro destes pedidos?')) {

      const listasEnviar = {
        pedidoIds: pedidoIds,
        usarFluxo: this.flowUses
      };

      this.httpService.wait();
      this.httpService.post('/custom/pedido/gerar-financeiro-pedido', listasEnviar)
        .subscribe(result => {
          this.msgs = [];
          if (result.error === true) {
            this.msgs = [{ severity: 'warn', summary: 'MSG: ', detail: result.message }];
          } else {
            this.msgs = [{ severity: 'success', summary: 'MSG: ', detail: result.message }];
          }
        },
          error => this.httpService.handleError(error, () => this.gerarFinanceiroPedido()),
          () => this.httpService.done());
    }
  }

  mudarParticipante() {
    this.buscarParticipante();
  }

  changeToolbar() {

    this.toolbarMain.setAll(this.toolbarMainIni);

    const selectionSize = this.pedidosSelecionados.length,
      canEmit = this.pedidosSelecionados.filter(item => item.pedido_status_id === 4 || item.pedido_status_id === 3).length > 0;

    this.showSolicitarAprovacao = this.flowUses && this.statusCompras && selectionSize > 0 && this.pedidosSelecionados.filter(pedido => ![null, undefined, 2, 4, 10].includes(pedido.status_aprovacao_id)).length == 0;

    this.showChangeDataEntrega = this.flowUses && this.pedidosSelecionados.length > 0 && !(this.pedidosSelecionados.find(line => line.todo_card_aprovacao_id === null));


    this.tipo == 1 ? this.toolbarMain.setOptionVisible('more', 'generateBill', selectionSize > 0 && this.statusCompras) : this.toolbarMain.setOptionVisible('more', 'generateBill', false);
    // this.tipo == 1 ? this.toolbarMain.setOptionVisible('more', 'pixqrcode', selectionSize > 0 && (this.statusCompras || this.statusFinalizado) && this.usaPix) : this.toolbarMain.setOptionVisible('more', 'pixqrcode', false)
    this.tipo == 1 ? this.toolbarMain.setOptionVisible('more', 'cobranca', selectionSize > 0 && (this.statusCompras || this.statusFinalizado) && this.usaAsaas) : this.toolbarMain.setOptionVisible('more', 'cobranca', false)

    this.toolbarMain.setVisible('delete', selectionSize > 0);
    this.toolbarMain.setVisible('edit', selectionSize === 1);
    this.toolbarMain.setVisible('reject', ![null, undefined].includes(this.numeroAba) && this.numeroAba == 2 && selectionSize == 1);
    this.toolbarMain.setVisible('cobranca', selectionSize > 0 && (this.statusCompras || this.statusFinalizado) && this.usaAsaas && this.tipo == 1);
    this.toolbarMain.setOptionVisible('more', 'changeDeliveryDate', this.showChangeDataEntrega);
    this.toolbarMain.setOptionVisible('more', 'clone', selectionSize > 0);
    this.toolbarMain.setOptionVisible('more', 'status', selectionSize > 0);
    this.toolbarMain.setOptionVisible('more', 'print', selectionSize === 1);
    this.toolbarMain.setOptionVisible('more', 'sidebar', selectionSize === 1 && this.pedidosSelecionados[0].todo_card_aprovacao_id != null && this.flowUses);
    this.toolbarMain.setOptionVisible('more', 'request', this.showSolicitarAprovacao);
    this.toolbarMain.setOptionVisible('more', 'contract', ![null, undefined].includes(this.numeroAba) && (this.numeroAba == 1 || this.numeroAba == 3) && selectionSize > 0); //venda ou finalizado


    if (selectionSize > 0) {
      this.toolbarMain.setOptionClick('more', 'clone', () => selectionSize == 1 ? this.modalClonarPedido() : this.modalClonePedidoLote = true);

      if (this.tipo == 0 && this.numeroAba === 1)
        this.toolbarMain.setProps('emit', { icon: 'add', text: 'ESCRITURAR', tooltip: 'Escriturar NF', visible: true, disabled: false, color: 'green', onClick: () => this.escritLote() });

      if (canEmit)
        this.toolbarMain.setProps('emit', { icon: 'add', text: 'EMITIR NF', tooltip: 'Emitir NF', visible: true, disabled: false, color: 'green', onClick: () => this.emitirNfsLote() });
    }

    this.atualizarValorPedidosSelecionados();
  }

  rowSidebar(data: any): void {
    this.sidebarService.rowSelected([data.todo_card_aprovacao_id])
    this.sidebarService.open(true)
    this.sidebarService.selectTab(0)
  }

  async getCompanyParameter(): Promise<void> {
    try {
      await this.httpService.get('/empresa?$select=usarAprovacao&$filter=id eq ' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId)).toPromise().then(res => {
        res.value.forEach(item => {
          this.flowUses = item.usarAprovacao
          if (this.flowUses) {
            this.getListStatusAprov()
            this.getParametroRms()
          }
        })
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar os paramêtros da empresa! Contate o suporte' })
    }
  }

  solicitarAprovacao(reload) {
    const payload = {
      empresaId: this.dblinkedSessionService.empresa.id,
      origemId: 1,
      itemId: Array.from(new Set(this.pedidosSelecionados.map(pedido => pedido.id))),
      userId: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/workflow/criar-card-aprovacao', payload)
      .subscribe(
        result => {
          this.exibirMensagem({ error: result.error, mensagem: result.response })
          this.httpService.done()
          if (result.error) return;

          const username = this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome
          this.socketService.requestApproval(result.listTodoCardId, result.listNotificacaoId, this.sessionService.loggedUser.id, username)
          if (reload) this.carregarPedidos()
        },
        error => {
          console.log(error)
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao solicitar aprovação, contate o suporte: " + error.message })
          this.httpService.done()
        }
      );
  }

  openModalStatusEntrega() {
    this.modalStatusEntrega = true
  }

  getListStatusAprov() {
    this.httpService.get('/statusaprovacao?$select=id,codigo,descricao&$filter=statusPedido eq true and pedidoServico eq true and empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + '&$orderby=codigo')
      .subscribe(res => {
        this.opcoesStatusEntrega = []
        res.value.forEach(row => this.opcoesStatusEntrega.push({ label: row.codigo + " - " + row.descricao, value: row.id }))
      },
        error => {
          console.log(error)
          this.messageService.add({ severity: "error", detail: "Erro ao carregar os status de entrega, contate o suporte: " + error.message, summary: "Erro" })
        })
  }

  mudarStatusEntrega() {
    let liPedidoId: Array<number> = new Array()
    this.pedidosSelecionados.forEach(ped => liPedidoId.push(ped.id))

    const infos = {
      lipedidoId: liPedidoId,
      userId: this.sessionService.loggedUser.id,
      statusEntregaId: this.statusEntregaId
    }

    this.httpService.wait()
    this.httpService.post("/custom/pedido/mudar-status-entrega", infos)
      .subscribe(
        res => {
          if (res.error) {
            this.exibirMensagem({ error: true, mensagem: res.message })
            return
          }
          this.exibirMensagem({ error: false, mensagem: res.message })
          this.carregarPedidos();
          this.modalStatusEntrega = false
          this.statusEntregaId = null
        },
        error => {
          console.log(error)
          this.exibirMensagem({ error: true, mensagem: "Erro ao mudar os status da entrega, contate o suporte: " + error.message })
        },
        () => this.httpService.done()
      )
  }

  comboDesconto() {
    this.skeletonConfig.startLoad();
    this.httpService.get('/tipodesconto?$select=id,cod&$orderby=cod')
      .subscribe(res => {
        this.opcoesDesconto = []
        res.value.forEach(element => this.opcoesDesconto.push({ label: element.cod, value: element.id }))
      },
        error => {
          console.log(error)
          this.messageService.add({ severity: "error", detail: "Erro ao carregar os tipos de desconto, contate o suporte: " + error.message, summary: "Erro" })
        },
        () => this.skeletonConfig.endLoad());

  }

  salvarParticipante(event: any) {
    let partSelected = event.selectedParticipant
    this.pedido.participante.id = partSelected.id
    this.pedido.participante.codigo = partSelected.cod_part
    this.pedido.participante.fantasia = partSelected.fantasia
    this.pedido.participanteEmpresaId = partSelected.part_emp_id

    this.httpService.wait();
    this.httpService.get('/custom/pedido/buscar-infos-participante(' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ',' +
      this.pedido.participante.id + ',' +
      (this.tipo == 0 ? 4 : 2) + ')')
      .subscribe(info => {
        if ((info[0].cidade == null) || (info[0].logradouro === "" || info[0].logradouro == null) || (info[0].numero == null) || (info[0].uf == null)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O Endereço do Participante Selecionado está Incompleto!' });
        }

        this.infoParticipante = info;
        this.pedidoExterior = (info[0].uf === 'EX') ? true : false;
      },

        error => this.httpService.handleError(error, () => this.salvarParticipante(event)),
        () => this.httpService.done());

  }

  rowCalendarState(data: any): void {
    this.pedido.pedidoParcela.forEach(item => {
      if (item.id === data.id) item.calendarState = !item.calendarState
    })
  }

  // async gerarPixQrCode() {
  //   let lipedidos: Array<number> = new Array()
  //   this.pedidosSelecionados.forEach(row => {
  //     lipedidos.push(row.id)
  //   })

  //   const infos = {
  //     origem: "PED",
  //     lititulos: lipedidos,
  //     empresa_id: this.dblinkedSessionService.empresa.id,
  //     user_id: this.sessionService.loggedUser.id
  //   }

  //   this.httpService.wait()
  //   this.httpService.post(this.configService.octaremoteUrl + "/matera/qrcode", infos)
  //     .subscribe(
  //       res => {
  //         if (res.codigo === 500) {
  //           this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res.message })
  //           this.httpService.done()
  //           return

  //         } else if (res.codigo === 400) {
  //           this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res.message })
  //           this.httpService.done()
  //           return

  //         } else {
  //           this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res.message })
  //           res.data.forEach(arquivo => {
  //             const httpOptions2 = {
  //               headers: new HttpHeaders({
  //                 'Authorization': this.configService.bearerApi,
  //                 'Cache-Control': 'no-store, max-age=0'
  //               }),
  //               responseType: 'blob' as 'json'
  //             }

  //             this.httpClient.get(this.configService.octaremoteUrl + "/matera/qrcode?userId=" + this.sessionService.loggedUser.id + "&nome=" + arquivo.toString(), httpOptions2)
  //               .subscribe(
  //                 (res: Blob) => {
  //                   let link = document.createElement('a')
  //                   let url = window.URL.createObjectURL(res)
  //                   link.href = url
  //                   link.download = arquivo
  //                   link.click()
  //                   window.URL.revokeObjectURL(url)
  //                   this.httpService.done()
  //                 },
  //                 err => {
  //                   console.log(err)
  //                   this.exibirMensagem({ error: true, mensagem: "Erro no API ao baixar o QRCode " + arquivo.toString() + ", contate o suporte: " + err.message })
  //                   this.httpService.done()
  //                 }
  //               )
  //             new Promise(async () => await this.delay(2000))
  //           })
  //         }
  //       },
  //       err => {
  //         console.log(err)
  //         this.exibirMensagem({ error: true, mensagem: "Erro no API ao gerar os QRCodes, contate o suporte: " + err.message })
  //         this.httpService.done()
  //       }
  //     )
  // }

  protected async delay(milliseconds: number): Promise<void> {
    return new Promise(function (resolve) { setTimeout(resolve, milliseconds) })
  }

  loadConfigApi(): void {
    this.usaAsaas = false
    // this.usaPix = false
    this.httpService.get('/configuracoesapis?$select=codigo,ativo&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          res.value.forEach(row => {
            // if (row.codigo === 'MAT' && row.ativo) this.usaPix = true
            if (row.codigo === 'ASAAS' && row.ativo) this.usaAsaas = true
          })
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao recuperar a configuração API da empresa, contate o suporte: " + err.message })
        }
      )
  }

  loadTiposPagamentos() {
    this.tiposPagamentos = []
    this.tiposPagamentos.push({ label: 'Selecione', value: null })
    this.httpService.get('/tipospagamentos?$select=id,codigo,descricao&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' &$orderby=codigo')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.tiposPagamentos.push({ label: (element.codigo + ' - ' + element.descricao), value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.loadTiposPagamentos()))

  }

  setTipoPagamento(row: any) {
    this.tiposPagamentos.forEach(element => {
      if (element.value === row.tipoPagamentoId) {
        row.tipoPagamentoDesc = element.label
      }
    })

    this.pedido.pedidoParcela.forEach(element => {
      if (element.parcela > row.parcela) {
        element.tipoPagamentoId = row.tipoPagamentoId
        element.tipoPagamentoDesc = row.tipoPagamentoDesc
      }
    })
  }

  // async getQuantidadePedidos() {
  //   const tipo = (this.tipo == 0 ? 'pcs' : 'pvs')
  //   const payload = {
  //     empresaId: this.dblinkedSessionService.empresa.id,
  //     tipo: tipo,
  //     dataInicial: this.dataIniSearch,
  //     dataFinal: this.dataFinSearch,
  //     userId: this.sessionService.loggedUser.id,
  //     filiaisSelecionadas: this.dblinkedSessionService.filiaisSelecionadas
  //   }


  //   try {
  //     await this.httpService.post('/custom/pedido/get-pedidos-infos-gerais', payload).toPromise().then(res => {
  //       this.totalPedidosNegociacao = this.totalPedidosNegociacaoPaginacao = res.data.emAndamento.quantidade
  //       this.totalPedidosRecusado = this.totalPedidosRecusadoPaginacao = res.data.recusado.quantidade
  //       this.totalPedidosFinalizado = this.totalPedidosFinalizadoPaginacao = res.data.finalizado.quantidade
  //       this.totalPedidosComprasVendas = this.totalPedidosComprasVendasPaginacao = res.data.vendaOuCompra.quantidade

  //       this.valorTotalPedidosNegociacao = res.data.emAndamento.valor ? res.data.emAndamento.valor : 0
  //       this.valorTotalPedidosComprasVendas = res.data.vendaOuCompra.valor ? res.data.vendaOuCompra.valor : 0
  //       this.valorTotalPedidosRecusado = res.data.recusado.valor ? res.data.recusado.valor : 0
  //       this.valorTotalPedidosFinalizado = res.data.finalizado.valor ? res.data.finalizado.valor : 0

  //     }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.status + ' - Houve um erro ao carregar a quantidade de pedidos!' }))
  //   } catch (err) {
  //     this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
  //   }
  // }

  async getPedidos(event: LazyLoadEvent = {}): Promise<void> {
    this.skeletonConfig.startLoad()

    const tipo = (this.tipo == 0 ? 'pcs' : 'pvs')
    const statusPesquisado = this.statusPesquisado
    //SIMON LALLEMENT 09/12/2022: nova rota do LazyLoad pelo Python
    const payload = {
      dataInicial: this.dataIniSearch,
      dataFinal: this.dataFinSearch,
      empresaId: this.dblinkedSessionService.empresa.id,
      filiaisSelecionadas: this.dblinkedSessionService.filiaisSelecionadas,
      statusPesquisado: statusPesquisado,
      tipo: tipo,
      usaAprovacao: this.flowUses,
      usaAsaas: this.usaAsaas,
      filtro_data: this.filtroData,
      visualiza_outros_pedidos: this.visualizaOutrosPedidos,
      vendedor_id: this.userVendedorId,
      limit: event.rows,
      offset: event.first,
      sort_order: event.sortOrder,
      sort_field: event.sortField,
      filters: event.filters
    }

    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }

    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'pedido/listar-pedidos', payload, httpOptions).toPromise()
        .then(
          res => {
            this.redefinirListaPedidos(statusPesquisado)
            this.definirValorTotalPedidos(res["data"]["total"], res["data"]["valor"], res["data"]["totalAbas"])
            this.popularInformacoesPedidos(res["data"]["lista"])

          })
        .catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err["message"] + ": " + err["data"] }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro no API ao buscar os pedidos, contate o suporte: ' + err.message })
    } finally {
      this.skeletonConfig.endLoad()
    }

    // const payload = {
    //   empresaId: this.dblinkedSessionService.empresa.id,
    //   tipo: tipo,
    //   dataInicial: this.dataIniSearch,
    //   dataFinal: this.dataFinSearch,
    //   userId: this.sessionService.loggedUser.id,
    //   offset: event.first,
    //   sort_order: event.sortOrder,
    //   sort_field: event.sortField,
    //   filters: event.filters,
    //   statusPesquisado: this.statusPesquisado,
    //   filiaisSelecionadas: this.dblinkedSessionService.filiaisSelecionadas,
    //   limit: event.rows
    // }

    // try {
    //   await this.httpService.post('/custom/pedido/get-pedidos', payload).toPromise().then(res => {
    //     this.redefinirListaPedidos()
    //     this.definirQuantidadeValorTipoPedidoAtual(res.qtPedidoFiltrado)

    //     this.popularInformacoesPedidos(res.data)

    //   }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.status + ' - Houve um erro ao carregar os Pedidos!' }))
    // } catch (err) {
    //   this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    // } finally {
    //   this.skeletonConfig.endLoad()
    // }
  }

  redefinirListaPedidos(statusPesquisado: string) {
    if (statusPesquisado === 'negociacao') this.pedidosNegociacao = []
    else if (statusPesquisado === "venda") this.pedidosCompraVenda = []
    else if (statusPesquisado === "cancelado") this.pedidosRecusados = []
    else if (statusPesquisado === "finalizado") this.pedidosFinalizados = []
  }

  definirValorTotalPedidos(quantidade: number, valor: number, outrosValores) {
    switch (this.telaAtual) {
      case 'TELA_1':
        this.totalPedidosNegociacaoPaginacao = quantidade
        this.valorTotalPedidos = this.valorTotalPedidosNegociacao = valor
        break
      case 'TELA_2':
        this.totalPedidosComprasVendasPaginacao = quantidade
        this.valorTotalPedidos = this.valorTotalPedidosComprasVendas = valor
        break
      case 'TELA_3':
        this.totalPedidosRecusadoPaginacao = quantidade
        this.valorTotalPedidos = this.valorTotalPedidosRecusado = valor
        break
      case 'TELA_4':
        this.totalPedidosFinalizadoPaginacao = quantidade
        this.valorTotalPedidos = this.valorTotalPedidosFinalizado = valor
        break
    }

    for (let status in outrosValores) {
      if (status == "negociacao") this.totalPedidosNegociacao = outrosValores[status]
      else if (status == "venda") this.totalPedidosComprasVendas = outrosValores[status]
      else if (status == "recusado") this.totalPedidosRecusado = outrosValores[status]
      else if (status == "finalizado") this.totalPedidosFinalizado = outrosValores[status]
    }
  }

  popularInformacoesPedidos(dadosPedidos) {
    dadosPedidos.forEach(pedido => {
      if (pedido.pedido_status_id === 1 || pedido.pedido_status_id === 8 || pedido.pedido_status_id == 9) {
        this.pedidosNegociacao.push(pedido);

      } else if (pedido.pedido_status_id === 2 || pedido.pedido_status_id === 4 || pedido.pedido_status_id === 7
        || pedido.pedido_status_id === 12 || pedido.pedido_status_id === 13 || pedido.pedido_status_id == 15) {
        this.pedidosCompraVenda.push(pedido);

      } else if (pedido.pedido_status_id === 3 || pedido.pedido_status_id === 14) {
        this.pedidosRecusados.push(pedido);

      } else if (pedido.pedido_status_id === 5) {
        this.pedidosFinalizados.push(pedido);
      }
      // if (this.statusNegociacao) this.pedidosNegociacao.push(pedido)
      // else if (this.statusCompras) this.pedidosCompraVenda.push(pedido)
      // else if (this.statusRecusado) this.pedidosRecusados.push(pedido)
      // else if (this.statusFinalizado) this.pedidosFinalizados.push(pedido)
    })

    // this.definirValorTotalPedidos()
  }

  determinarPrimeiraChamada() {
    switch (this.telaAtual) {
      case 'TELA_1':
        this.statusPesquisado = "negociacao"

        if (this.primeiraChamadaNegociacao) {
          this.primeiraChamadaNegociacao = false
          this.getPedidos()
        }

        break;
      case 'TELA_2':
        this.statusPesquisado = "venda"

        if (this.primeiraChamadaComprasVendas) {
          this.primeiraChamadaComprasVendas = false
          this.getPedidos()
        }

        break;
      case 'TELA_3':
        this.statusPesquisado = "cancelado"

        if (this.primeiraChamadaRecusado) {
          this.primeiraChamadaRecusado = false
          this.getPedidos()
        }

        break;
      case 'TELA_4':
        this.statusPesquisado = "finalizado"

        if (this.primeiraChamadaFinalizado) {
          this.primeiraChamadaFinalizado = false
          this.getPedidos()
        }

        break;
    }
  }

  escritLote() {

    if (this.pedidosSelecionados.length === 0) {

      alert('É necessário selecionar pelo menos um pedido para escrituração');

    } else {

      if (confirm('Deseja realmente escriturar estes ' + this.pedidosSelecionados.length + ' pedido(s) ?')) {

        const listasEnviar = {
          pedido: JSON.stringify(this.pedido, null, ''),
          empresa: this.dblinkedSessionService.empresa.id,
          usuario: this.sessionService.loggedUser.id,
          pedidoSelecionados: this.pedidosSelecionados
        };

        this.httpService.wait();
        this.httpService.post('/custom/pedido/escriturar-pedido-lote', listasEnviar)
          .subscribe(result => {

            this.download(result);
            this.getPedidos()

          },
            error => this.httpService.handleError(error, () => this.escritLote()),
            () => this.httpService.done());
      }

    }

  }

  download(result?: any) {

    if (result.file !== 'NA') {

      if (result.qtdeErro > 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: (result.qtdeErro + ' PEDIDO(S) NÃO ESCRITURADO(S)') });
      }

      window.open(
        this.httpService.url("/custom/file/" + result.file),
        "_blank"
      );
    } else {
      if (result.qtdeErro > 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: (result.qtdeErro + ' PEDIDO(S) NÃO ESCRITURADO(S)') });
      }
      if (result.qtdeSucesso > 0) {
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Advertência', detail: (result.qtdeSucesso + ' PEDIDO(S) ESCRITURADO(S)') });
      }

    }


  }

  atualizarRelatorio() {
    let params: LazyLoadEvent = {}
    if (this.statusNegociacao) {
      this.dt10.reset()
      this.dt10.clearState()
      params.rows = this.dt10._rows
      this.statusPesquisado = "negociacao"
      this.getPedidos(params)
      // this.getQuantidadePedidos();

    } else if (this.statusCompras) {
      this.dt20.reset()
      this.dt20.clearState()
      params.rows = this.dt20._rows
      this.statusPesquisado = "venda"
      this.getPedidos(params)
      // this.getQuantidadePedidos();

    } else if (this.statusRecusado) {
      this.dt30.reset()
      this.dt30.clearState()
      params.rows = this.dt30._rows
      this.statusPesquisado = "cancelado"
      this.getPedidos(params)
      // this.getQuantidadePedidos();

    } else if (this.statusFinalizado) {
      this.dt40.reset()
      this.dt40.clearState()
      params.rows = this.dt40._rows
      this.statusPesquisado = "finalizado"
      this.getPedidos(params)
      // this.getQuantidadePedidos();
    }


  }

  async gerarCobranca(mandarEmail: boolean = false) {
    let lipedidos: Array<number> = new Array()
    this.pedidosSelecionados.forEach(row => {
      lipedidos.push(row.id)
    })

    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    const infos = {
      origem: "PED",
      lititulos: lipedidos,
      empresa_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id,
      mandarEmail: mandarEmail
    }

    this.httpService.wait()
    this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Info', detail: 'Processamento das faturas... Favor aguarde.' })
    this.httpClient.post(this.configService.octaremoteUrl + "/asaas/cobranca", infos, httpOptions)
      .subscribe(
        res => {
          if (res["codigo"] === 500) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] })
          else if (res["codigo"] === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res["message"] })
          else {
            if (mandarEmail) this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res["message"] })
            else {
              res["data"].forEach(url => {
                window.open(url)
              })
              this.getPedidos()
            }
          }
          this.httpService.done()
        },
        err => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao processar as faturas de cobrança, contate o suporte: " + err.message })
          this.httpService.done()
        }
      )
  }

  carregarPedidos() {


    this.vendedorId = 0;
    //this.alterarPeriodo();
    this.modalPeriodo = false;
    this.exibirPedido = false;
    this.exibirModalContrato = false;
    this.exibirModalRejeicao = false;

    this.botaoEditar = false;

    this.valorPedidosSelecionados = 0.00;
    this.valorTotalPedidos = 0.00;

    this.valorTotalPedidosNegociacao = 0
    this.valorTotalPedidosRecusado = 0
    this.valorTotalPedidosFinalizado = 0
    this.valorTotalPedidosComprasVendas = 0

    this.totalPedidosNegociacao = 0
    this.totalPedidosRecusado = 0
    this.totalPedidosFinalizado = 0
    this.totalPedidosComprasVendas = 0
    // this.statusPesquisado = []

    this.totalPedidosNegociacaoPaginacao = 0
    this.totalPedidosRecusadoPaginacao = 0
    this.totalPedidosFinalizadoPaginacao = 0
    this.totalPedidosComprasVendasPaginacao = 0

    this.isReadOnly = false;

    this.subTotalPedido = this.utilService.parseMoneyString(0.00);
    this.valorDescontoItens = this.utilService.parseMoneyString(0.00);
    this.valorImpostosRetidos = this.utilService.parseMoneyString(0.00);
    this.valorTotalPedido = this.utilService.parseMoneyString(0.00);

    this.todosSelecionados1 = false;
    this.todosSelecionados2 = false;
    this.todosSelecionados3 = false;
    this.todosSelecionados4 = false;

    this.idPedidoView = [];
    this.pedidosSelecionados = [];
    this.clearArray = []
    this.pedido = new Pedido();
    this.exibirRateio = false;
    this.requestApproval = false;

    this.titleService.title = (this.tipo == 0 ? 'Pedido Compra - Serviço' : 'Pedido Venda - Serviço');
    this.title = (this.tipo == 0 ? 'COMPRAS E ORÇAMENTO DE SERVIÇO' : 'VENDAS E ORÇAMENTO DE SERVIÇO');

    let dataIni = `${this.dataIniSearch.substring(8, 10)}/${this.dataIniSearch.substring(5, 7)}/${this.dataIniSearch.substring(0, 4)}`,
      dataFin = `${this.dataFinSearch.substring(8, 10)}/${this.dataFinSearch.substring(5, 7)}/${this.dataFinSearch.substring(0, 4)}`;

    this.subtitle = `PERÍODO SELECIONADO: ${dataIni} à ${dataFin}`

    // this.getQuantidadePedidos();
    this.primeiraChamadaNegociacao = true;
    this.primeiraChamadaComprasVendas = true;
    this.primeiraChamadaRecusado = true;
    this.primeiraChamadaFinalizado = true;
    this.determinarPrimeiraChamada();

  }

  async verificarVendedor(): Promise<void> {
    this.skeletonConfig.startLoad()
    try {
      await this.httpService.get('/vendedor?$select=visualizaOutrosVendedores,id&$filter=empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) +
        " and email eq '" + this.sessionService.loggedUser.mail + "'")
        .toPromise()
        .then(
          vend => {
            vend.value.forEach(
              element => {
                if (vend.value[0].visualizaOutrosVendedores === false) this.visualizaOutrosPedidos = false
                this.userVendedorId = vend.value[0].id
              })
          })
        .catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao recuperar os dados do vendedor, contate o suporte: " + err.message }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao recuperar os dados do vendedor, contate o suporte: " + err.message })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

  exportarPedidos() {
    this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aguarde', detail: 'Processando solicitação! Em breve seu arquivo será disponibilizado.' })

    const empresaId = this.dblinkedSessionService.empresa.id
    const usuarioId = this.sessionService.loggedUser.id
    const dataInicial = this.dataIniSearch
    const dataFinal = this.dataFinSearch
    const statusPesquisado = this.statusPesquisado
    const usaAprovacao = this.flowUses

    const payload = {
      empresaId,
      usuarioId,
      dataInicial,
      dataFinal,
      statusPesquisado,
      usaAprovacao,
      tipo: this.tipo == 0 ? 'pcs' : 'pvs',
      usaAsaas: this.usaAsaas
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi
      }),
      responseType: 'blob' as 'json'
    }

    try {
      this.httpClient.post(this.configService.defaultUrlApiUpload + '/pedido/exportar-pedidos', payload, httpOptions).toPromise().then((res: any) => {
        let link = document.createElement('a')
        let url = window.URL.createObjectURL(res)
        link.href = url
        if (this.tipo == 0) link.download = "relatorio-pedido-compra-servico.xlsx"
        else link.download = "relatorio-pedido-venda-servico.xlsx"
        link.click()
        window.URL.revokeObjectURL(url)
      }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao recuperar seu relatório! Contate o suporte' }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    }
  }

  public changeContractsModal(showContractsModal: boolean): void {
    this.exibirModalContratoTable = showContractsModal
    this.dateLimitContract = new Date()
    this.dateReadjustmentContract = new Date()
    this.readjustmentContract = 0
    this.readjustmentPercentageContract = 0
  }

  public generateContracts(): void {
    if (this.validateGenerateContract()) {
      let liPedidoId: Array<number> = this.pedidosSelecionados.map((pedido: any) => { return pedido.id })

      const payload = {
        usuario: this.sessionService.loggedUser.id,
        pedidos_id: liPedidoId,
        termino: this.dateLimitContract,
        data_reajuste: this.dateReadjustmentContract,
        opcao_reajuste: this.readjustmentContract,
        porcentagem_reajuste: this.readjustmentPercentageContract
      }

      this.httpService.wait()

      this.httpService.post('/custom/pedido/gerar-contratos-visualizacao-tabelas', payload).subscribe(
        res => {
          if (!res[0].error) {
            this.primeiraChamadaNegociacao = true
            this.primeiraChamadaComprasVendas = true
            this.primeiraChamadaRecusado = true
            this.primeiraChamadaFinalizado = true
            this.pedidosSelecionados = new Array()

            this.changeContractsModal(false)
            this.determinarPrimeiraChamada()
            this.changeToolbar()
          }

          this.messageService.add({ severity: (res[0].error ? 'error' : 'success'), key: 'messageLinnks', summary: (res[0].error ? 'Erro: ' : 'Sucesso: '), detail: res[0].message })
        },
        error => this.httpService.handleError(error, () => this.generateContracts()),
        () => this.httpService.done())
    }
  }

  private validateGenerateContract(): boolean {
    if (this.dateLimitContract === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de término é obrigatória!' })
      return false
    }

    if (this.readjustmentContract !== 0) {
      if (this.dateReadjustmentContract === null) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de início do reajuste é obrigatória!' })
        return false
      }
    }

    return true
  }

  async verificarItensRateioXml(): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = {
        pedidoId: this.pedido.id,
        liPedidoItem: this.pedido.pedidoItem
      }

      this.httpService.wait()
      this.httpService.post('/custom/pedido/verificar-itens-rms', payload).subscribe(
        res => {
          this.httpService.done()
          resolve([true, null, null])
        },
        error => {
          this.httpService.done()
          resolve([false, error.error.dado, error.error.message])
        })
    })
  }

  async getParametroRms() {
    this.skeletonConfig.startLoad()
    try {
      await this.httpService.get('/configuracaorms?$select=travarEdicaoItemPedido&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
        .toPromise()
        .then(
          ret => {
            ret.value.forEach(element => {
              this.travarEdicaoItemPedido = element.travarEdicaoItemPedido
            })
          }
        )
        .catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: "Erro ao recuperar as configurações RMS da empresa, contate o suporte: " + err.message }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao recuperar as configurações RMS da empresa, contate o suporte: " + err.message })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }
}
