export class StatusEvento {
  id: number;
  descricao: string;

  constructor(statusEvento?: StatusEvento | number) {
    if (statusEvento !== undefined && statusEvento !== null) {
      if (typeof statusEvento === 'number') {
        this.id = statusEvento;
      } else {
        this.id = statusEvento.id;
        this.descricao = statusEvento.descricao;
      }
    }
  }


}
