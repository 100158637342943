export class CstIcms {
  id: number;
  cst: string;
  descricao: string;

  constructor(csticms?: CstIcms | number) {
    if (csticms !== undefined && csticms !== null) {
      if (typeof csticms === 'number') {
        this.id = csticms;
      } else {
        this.id = csticms.id;
        this.cst = csticms.cst;
        this.descricao = csticms.descricao;
      }
    }
  }
}
