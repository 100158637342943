import { GrupoFluxoCaixa } from './fluxo_caixa_grupo';

export class AliasGrupoFluxoCaixa {
  id: number;
  codigoAlias: number;
  nomeAlias: string;
  posicaoAlias: string;
  grupoFluxoCaixa: GrupoFluxoCaixa;

  constructor(aliasGrupo?: AliasGrupoFluxoCaixa | number) {
    if (aliasGrupo !== undefined && aliasGrupo !== null) {
      if (typeof aliasGrupo === 'number') {
        this.id = aliasGrupo;
      } else {
        this.id = aliasGrupo.id;
        this.codigoAlias = aliasGrupo.codigoAlias;
        this.nomeAlias = aliasGrupo.nomeAlias;
        this.posicaoAlias = aliasGrupo.posicaoAlias;
        this.grupoFluxoCaixa = aliasGrupo.grupoFluxoCaixa;
      }
    }
  }
}
