import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormArray, FormBuilder, Validators, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap, convertToParamMap } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Uf } from 'app/util/model/uf.model';
import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { TipoEcd } from 'app/util/model/tipo-ecd.model';
import { TbTipoEcf } from 'app/ecf/model/tb-tipo-ecf.model';
import { FormaTrib } from 'app/util/model/forma-trib.model';
import { FormaTribPer } from 'app/util/model/forma-trib-per.model';
import { MesBalRed } from 'app/util/model/mes-bal-red.model';
import { FormaApur } from 'app/util/model/forma-apur.model';
import { FormaApurI } from 'app/util/model/forma-apur-i.model';
import { ApurCsll } from 'app/util/model/apur-csll.model';
import { IndRecReceita } from 'app/util/model/ind-rec-receita.model';
import { IndAliqCsll } from 'app/util/model/ind-aliq-csll.model';
import { CodQualifPj } from 'app/util/model/cod-qualif-pj.model';
import { TipEscPre } from 'app/util/model/tip-esc-pre.model';
import { TipEnt } from 'app/util/model/tip-ent.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { UtilService } from 'app/shared/services/util.service';
import { EmpresaPeriodo } from 'app/cadastro/model/empresa-periodo.model';
import { SelectItem, Message, MessageService } from 'primeng/api';

class FormDataControl extends FormControl {
  transient: boolean;

  constructor(formState?: any,
    validator?: ValidatorFn | ValidatorFn[] | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    transient: boolean = false) {
    super(formState, validator, asyncValidator);

    this.transient = transient;
  }
}

@Component({
  selector: 'app-empresa-info',
  templateUrl: './empresa-info.component.html',
  styleUrls: ['./empresa-info.component.scss'],
})
export class EmpresaInfoComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;

  @ViewChild('formContainer')
  private formContainer: ElementRef;

  private formValues: any;

  empresa: Empresa;

  tabIndex = 0;
  tabReady = false; // https://github.com/angular/material2/issues/5269



  private sessionSubscription: Subscription;
  private formSubscription: Subscription;
  private empresaSubscription: Subscription;

  years: Array<number> = new Array();
  quarters: Array<number> = [1, 2, 3, 4];
  months: Array<any> = [{ id: 1, description: 'Janeiro' },
  { id: 2, description: 'Fevereiro' },
  { id: 3, description: 'Março' },
  { id: 4, description: 'Abril' },
  { id: 5, description: 'Maio' },
  { id: 6, description: 'Junho' },
  { id: 7, description: 'Julho' },
  { id: 8, description: 'Agosto' },
  { id: 9, description: 'Setembro' },
  { id: 10, description: 'Outubro' },
  { id: 11, description: 'Novembro' },
  { id: 12, description: 'Dezembro' }];

  naturezaJuridicaList: Array<any> = new Array();
  regimeTributarioList: Array<any> = new Array();
  naturezaJuridicaEcfList: Array<SelectItem> = new Array();
  ufs: Array<Uf> = new Array();
  municipios: Array<MunicipioIbge> = new Array();
  tipoEcds: Array<TipoEcd> = new Array();
  tipoEcfs: Array<TbTipoEcf> = new Array();
  formaTribs: Array<FormaTrib> = new Array();
  formaTribPers: Array<FormaTribPer> = new Array();
  mesBalReds: Array<MesBalRed> = new Array();
  formaApurs: Array<FormaApur> = new Array();
  formaApurIs: Array<FormaApurI> = new Array();
  apurCslls: Array<ApurCsll> = new Array();
  indRecReceitas: Array<IndRecReceita> = new Array();
  indAliqCslls: Array<IndAliqCsll> = new Array();
  codQualifPjs: Array<CodQualifPj> = new Array();
  tipEscPres: Array<TipEscPre> = new Array();
  tipEnts: Array<TipEnt> = new Array();

  indCentralizada: any;
  indMudancaPc: any;
  codPlanRef: any;
  tipoEcdId: any;
  tipoEcfId: any;
  msgs: Message[] = [];

  f: any;
  selectedTipoEcfs: any;
  selectedFormTribs: any;
  selectedFormaApurs: any;
  selectedCodQualifPjs: any;
  selectedTipEscPres: any;
  selectedTipEnts: any;
  selectedFormaApurIs: any;
  selectedApurCslls: any;
  selectedIndRecReceitas: any;
  selectedFormaTribPers: any[];
  selectedMesBalReds: any;
  selectedIndAliqCslls: any;

  // TODO: Refactoring
  private requiredKeys: Array<string> = ['tipoEcd.id',
    'tipoEcf.id',
    'formaTrib.id',
    'formaApur.id',
    'formaApurI.id',
    'apurCsll.id',
    'indRecReceita.id',
    'indAliqCsll.id',
    'codQualifPj.id',
    'tipEscPre.id',
    'tipEnt.id'];

  private pjHabChildKeys = ['indRepes',
    'indRecap',
    'indPadis',
    'indPatvd',
    'indReidi',
    'indRepenec',
    'indReicomp',
    'indRetaero',
    'indRecine',
    'indResiduosSolid',
    'indRecopa',
    'indCopaDoMund',
    'indRetid',
    'indRepnblRedes',
    'indReif',
    'indOlimpiadas'];

  displayCodScp = false;
  loaded = false;
  title: string;
  subtitle: string;

  constructor(private formBuilder: FormBuilder,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private router: Router,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private messageService: MessageService) {
  }

  ngOnInit() {
    this.indCentralizada = undefined;
    this.indMudancaPc = undefined;
    this.codPlanRef = undefined;
    this.titleService.title = 'Informações da empresa';
    this.title = 'Informações da empresa';
    this.subtitle = 'ALTERE OU EXCLUA AS INFORMAÇÕES DESEJADAS';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged, () => this.loadUfList());
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.formSubscription);
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  loadUfList() {
    this.httpService.loadLists([
      { url: '/uf?$select=id,uf&$orderby=uf', destination: this.ufs },
      { url: '/naturezajuridica?$select=id,descricao&$orderby=descricao', destination: this.naturezaJuridicaList },
      { url: '/regimetributario?$select=id,descricao&$orderby=descricao', destination: this.regimeTributarioList },
      { url: '/naturezajuridicaecf?$select=id,codigo,descricao&$orderby=descricao', destination: this.naturezaJuridicaEcfList },
      { url: '/municipioibge?$select=id,nome&$orderby=nome', destination: this.municipios }],
      () => this.loadEmpresa());
  }

  loadEmpresa(postSave: boolean = false) {
    if (!postSave) {
      this.loaded = false;
    }

    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.httpService.wait();
      this.httpService.get('/empresa?$select=' +
        'id,razaoSocial,nomeFantasia,cnpj,im,ie,municipioIbge/id,municipioIbge/uf/id,empresaPeriodos/periodo,' +
        'endereco,numero,complemento,bairro,cep,telefone,email,cnae,naturezaJuridica/id,naturezaJuridicaEcf/id,' +
        'regimeTributario/id,empresaPeriodos/tipoEcd/id,empresaPeriodos/tipoEcf/id,empresaPeriodos/codScp,' +
        'empresaPeriodos/formaTrib/id,empresaPeriodos/formaApur/id,empresaPeriodos/formaApurI/id,' +
        'empresaPeriodos/apurCsll/id,empresaPeriodos/indRecReceita/id,empresaPeriodos/indAliqCsll/id,' +
        'empresaPeriodos/codQualifPj/id,empresaPeriodos/tipEscPre/id,empresaPeriodos/tipEnt/id,' +
        'empresaPeriodos/optRefis,empresaPeriodos/optPaes,empresaPeriodos/indAdmFunClu,' +
        'empresaPeriodos/indPartCons,empresaPeriodos/indOpExt,empresaPeriodos/indOpVinc,' +
        'empresaPeriodos/indPjEnquad,empresaPeriodos/indPartExt,empresaPeriodos/indAtivRural,' +
        'empresaPeriodos/indLucExp,empresaPeriodos/indRedIsen,empresaPeriodos/indFin,' +
        'empresaPeriodos/indDoaEleit,empresaPeriodos/indPartColig,empresaPeriodos/indVendExp,' +
        'empresaPeriodos/indRecExt,empresaPeriodos/indAtivExt,empresaPeriodos/indComExp,' +
        'empresaPeriodos/indPagtoExt,empresaPeriodos/indEcomTi,empresaPeriodos/indRoyRec,' +
        'empresaPeriodos/indRoyPag,empresaPeriodos/indRendServ,empresaPeriodos/indPagtoRem,' +
        'empresaPeriodos/indInovTec,empresaPeriodos/indCapInf,empresaPeriodos/indPjHab,' +
        'empresaPeriodos/indPoloAm,empresaPeriodos/indZonExp,empresaPeriodos/indAreaCom,' +
        'empresaPeriodos/indPaisPais,empresaPeriodos/hashEcfAnterior,empresaPeriodos/indQteScp,' +
        'empresaPeriodos/indRepes,empresaPeriodos/indRecap,empresaPeriodos/indPadis,empresaPeriodos/indOlimpiadas,' +
        'empresaPeriodos/indPatvd,empresaPeriodos/indReidi,empresaPeriodos/indRepenec,' +
        'empresaPeriodos/indReicomp,empresaPeriodos/indRetaero,empresaPeriodos/indRecine,' +
        'empresaPeriodos/indResiduosSolid,empresaPeriodos/indRecopa,empresaPeriodos/indCopaDoMund,' +
        'empresaPeriodos/indRetid,empresaPeriodos/indRepnblRedes,empresaPeriodos/indReif,' +
        'empresaPeriodos/formaTribPerRelacs/periodo,empresaPeriodos/formaTribPerRelacs/formaTribPer/id,' +
        'empresaPeriodos/mesBalRedRelacs/periodo,empresaPeriodos/mesBalRedRelacs/mesBalRed/id',
        this.dblinkedSessionService.empresa.id)
        .subscribe(empresa => {
          this.empresa = new Empresa(empresa.value);

          // if (this.empresa.municipioIbge && this.empresa.municipioIbge.uf) {
          //   this.onChangeUf(this.empresa.municipioIbge.uf.id, () => this.empresaLoaded());
          // } else {
            this.empresaLoaded();
          // }
          // this.toolbarService.clear();
        },
          error => this.httpService.handleError(error, () => this.loadEmpresa()),
          () => this.httpService.done());
    } else {
      this.sessionSubscription = this.dblinkedSessionService.sessionLoaded.subscribe(() => {
        this.loadEmpresa();
      });
    }

  }

  empresaLoaded() {
    this.tabIndex = 0;

    // TODO: Use EmpresaPeriod data (if exists)
    this.years = new Array();
    const year = this.utilService.date().getFullYear(); // TODO: Get from server
    /*for (let y = 0; y < 5; y++) {
      this.years.push(year - y);
    }*/
    this.years.push(year - 1); // TODO: Until fix PATCH bug
    this.years.push(year);

    // TODO: Create service
    this.formGroup = this.formBuilder.group([]);

    this.formGroup.addControl('razaoSocial',
      new FormDataControl(this.empresa.razaoSocial,
        [Validators.required, Validators.minLength(3), Validators.maxLength(90)]));

    this.formGroup.addControl('nomeFantasia',
        new FormDataControl(this.empresa.nomeFantasia,
          [Validators.required, Validators.minLength(3), Validators.maxLength(90)]));

    this.formGroup.addControl('cnpj',
      new FormDataControl(this.empresa.cnpj,
        [Validators.required, Validators.minLength(14), Validators.maxLength(14)]));

    this.formGroup.addControl('im',
      new FormDataControl(this.empresa.im,
        [Validators.required]));

    this.formGroup.addControl('ie',
      new FormDataControl(this.empresa.ie,
        [Validators.required]));

    this.formGroup.addControl('uf.id',
      new FormDataControl(this.empresa.municipioIbge && this.empresa.municipioIbge.uf ?
        this.empresa.municipioIbge.uf.id : null,
        [Validators.required],
        null,
        true));

    this.formGroup.addControl('municipioIbge.id',
      new FormDataControl(this.empresa.municipioIbge ? this.empresa.municipioIbge.id : null,
        [Validators.required]));

    this.formGroup.addControl('endereco',
      new FormDataControl(this.empresa.endereco,
        [Validators.required, Validators.maxLength(150)]));

    this.formGroup.addControl('numero',
      new FormDataControl(this.empresa.numero,
        [Validators.required, Validators.maxLength(6)]));

    this.formGroup.addControl('complemento',
      new FormDataControl(this.empresa.complemento,
        [Validators.maxLength(50)]));

    this.formGroup.addControl('bairro',
      new FormDataControl(this.empresa.bairro,
        [Validators.required, Validators.maxLength(50)]));

    this.formGroup.addControl('cep',
      new FormDataControl(this.empresa.cep,
        [Validators.required, Validators.maxLength(8)]));

    this.formGroup.addControl('telefone',
      new FormDataControl(this.empresa.telefone,
        [Validators.required, Validators.maxLength(15)]));

    this.formGroup.addControl('email',
      new FormDataControl(this.empresa.email,
        [Validators.required, Validators.maxLength(115)]));

    this.formGroup.addControl('naturezaJuridica.id',
      new FormDataControl(this.empresa.naturezaJuridica ? this.empresa.naturezaJuridica.id : null,
        [Validators.required]));

    this.formGroup.addControl('naturezaJuridicaEcf.id',
      new FormDataControl(this.empresa.naturezaJuridicaEcf ? this.empresa.naturezaJuridicaEcf.id : null,
        [Validators.required]));

    this.formGroup.addControl('regimeTributario.id',
      new FormDataControl(this.empresa.regimeTributario ? this.empresa.regimeTributario.id : null,
        [Validators.required]));

    this.formGroup.addControl('cnae',
      new FormDataControl(this.empresa.cnae,
        [Validators.required, Validators.maxLength(7)]));

    this.years.forEach(y => {
      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']id',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).id : null));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']tipoEcd.id',
        new FormDataControl(this.empresaPeriodo(y) && this.empresaPeriodo(y).tipoEcd ?
          this.empresaPeriodo(y).tipoEcd.id : null));
      const tipoEcdControl = this.formGroup.controls['empresaPeriodos[periodo=' + y + ']tipoEcd.id'];

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']tipoEcf.id',
        new FormDataControl(this.empresaPeriodo(y) && this.empresaPeriodo(y).tipoEcf ?
          this.empresaPeriodo(y).tipoEcf.id : null));
      const tipoEcfControl = this.formGroup.controls['empresaPeriodos[periodo=' + y + ']tipoEcf.id'];

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']codScp',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).codScp : null));

      tipoEcdControl.valueChanges.subscribe(value => {
        this.handleCodScp(y, value === 3 || tipoEcfControl.value === 3);
      });
      tipoEcfControl.valueChanges.subscribe(value => {
        this.handleCodScp(y, value === 3 || tipoEcdControl.value === 3);
      });
      this.handleCodScp(y, tipoEcfControl.value === 3 || tipoEcdControl.value === 3);

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']formaTrib.id',
        new FormDataControl(this.empresaPeriodo(y) && this.empresaPeriodo(y).formaTrib ?
          this.empresaPeriodo(y).formaTrib.id : null));
      const formaTribControl = this.formGroup.controls['empresaPeriodos[periodo=' + y + ']formaTrib.id'];

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']formaApur.id',
        new FormDataControl(this.empresaPeriodo(y) && this.empresaPeriodo(y).formaApur ?
          this.empresaPeriodo(y).formaApur.id : null));
      const formaApurControl = this.formGroup.controls['empresaPeriodos[periodo=' + y + ']formaApur.id'];

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']formaApurI.id',
        new FormDataControl(this.empresaPeriodo(y) && this.empresaPeriodo(y).formaApurI ?
          this.empresaPeriodo(y).formaApurI.id : null));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']apurCsll.id',
        new FormDataControl(this.empresaPeriodo(y) && this.empresaPeriodo(y).apurCsll ?
          this.empresaPeriodo(y).apurCsll.id : null));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRecReceita.id',
        new FormDataControl(this.empresaPeriodo(y) && this.empresaPeriodo(y).indRecReceita ?
          this.empresaPeriodo(y).indRecReceita.id : null));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indAliqCsll.id',
        new FormDataControl(this.empresaPeriodo(y) && this.empresaPeriodo(y).indAliqCsll ?
          this.empresaPeriodo(y).indAliqCsll.id : null));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']codQualifPj.id',
        new FormDataControl(this.empresaPeriodo(y) && this.empresaPeriodo(y).codQualifPj ?
          this.empresaPeriodo(y).codQualifPj.id : null));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']tipEscPre.id',
        new FormDataControl(this.empresaPeriodo(y) && this.empresaPeriodo(y).tipEscPre ?
          this.empresaPeriodo(y).tipEscPre.id : null));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']tipEnt.id',
        new FormDataControl(this.empresaPeriodo(y) && this.empresaPeriodo(y).tipEnt ?
          this.empresaPeriodo(y).tipEnt.id : null));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']optRefis',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).optRefis || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']optPaes',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).optPaes || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indAdmFunClu',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indAdmFunClu || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indPartCons',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indPartCons || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indOpExt',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indOpExt || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indOpVinc',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indOpVinc || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indPjEnquad',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indPjEnquad || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indPartExt',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indPartExt || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indAtivRural',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indAtivRural || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indLucExp',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indLucExp || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRedIsen',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRedIsen || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indFin',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indFin || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indDoaEleit',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indDoaEleit || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indPartColig',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indPartColig || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indVendExp',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indVendExp || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRecExt',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRecExt || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indAtivExt',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indAtivExt || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indComExp',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indComExp || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indPagtoExt',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indPagtoExt || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indEcomTi',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indEcomTi || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRoyRec',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRoyRec || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRoyPag',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRoyPag || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRendServ',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRendServ || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indPagtoRem',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indPagtoRem || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indInovTec',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indInovTec || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indCapInf',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indCapInf || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indPjHab',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indPjHab || false : false));
      const indPjHabControl = this.formGroup.controls['empresaPeriodos[periodo=' + y + ']indPjHab'];
      indPjHabControl.valueChanges.subscribe(value => {
        this.handlePjHab(y, value);
      });

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indPoloAm',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indPoloAm || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indZonExp',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indZonExp || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indAreaCom',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indAreaCom || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indPaisPais',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indPaisPais || false : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']hashEcfAnterior',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).hashEcfAnterior : null,
          [Validators.maxLength(40)]));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indQteScp',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indQteScp : null,
          [Validators.max(100)]));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRepes',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRepes : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRecap',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRecap : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indPadis',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indPadis : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indPatvd',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indPatvd : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indReidi',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indReidi : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRepenec',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRepenec : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indReicomp',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indReicomp : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRetaero',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRetaero : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRecine',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRecine : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indResiduosSolid',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indResiduosSolid : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRecopa',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRecopa : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indCopaDoMund',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indCopaDoMund : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRetid',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRetid : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indRepnblRedes',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indRepnblRedes : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indReif',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indReif : false));

      this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']indOlimpiadas',
        new FormDataControl(this.empresaPeriodo(y) ? this.empresaPeriodo(y).indOlimpiadas : false));

      this.quarters.forEach(q => {
        this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']formaTribPerRelacs[periodo=' + q + ']formaTribPer.id',
          new FormDataControl(this.formaTribPer(y, q) ? this.formaTribPer(y, q).id : null));
        const control = this.formGroup.controls['empresaPeriodos[periodo=' + y + ']formaTribPerRelacs[periodo=' + q + ']formaTribPer.id'];
        control.valueChanges.subscribe(value => {
          this.handleFormaTribPer(y, q, value);
        });
      });

      this.months.forEach(m => {
        this.formGroup.addControl('empresaPeriodos[periodo=' + y + ']mesBalRedRelacs[periodo=' + m.id + ']mesBalRed.id',
          new FormDataControl(this.mesBalRed(y, m.id) ? this.mesBalRed(y, m.id).id : null));
      });

      formaTribControl.valueChanges.subscribe(value => {
        this.handleFormaApur(y, value, formaApurControl.value);
      });
      formaApurControl.valueChanges.subscribe(value => {
        this.handleFormaApur(y, formaTribControl.value, value);
      });
    });

    this.formValues = this.formGroup.getRawValue();

    // TODO: Move
    this.formSubscription = this.formGroup.statusChanges.subscribe(status => {
      let formStatus = status;

      // TODO: Review (use only if new period / move from here)
      if (this.tabIndex > 0 && formStatus === 'VALID') {
        Object.keys(this.formGroup.controls).filter(key => key.startsWith('empresaPeriodos[periodo=' + this.selectedYear + ']'))
          .forEach((key: string) => {
            const control = this.formGroup.controls[key];

            if (key.indexOf(']') > -1 && this.requiredKeys.indexOf(key.split(']')[1]) > -1) {
              if (!control.validator) {
                control.setValidators([Validators.required]);
                control.updateValueAndValidity();
                control.parent.updateValueAndValidity();

                formStatus = control.parent.status;
              }
            }

            control.markAsDirty(); // TODO: Review
          });
      }

    });

    // TODO: Move
    setTimeout(() => {
      const field = this.formContainer ? this.formContainer.nativeElement.querySelector('input, textarea') : null;
      if (field) {
        field.focus();
      }
    }, 100);

    this.loaded = true;
  }

  onChangeUf(ufId: number, callback: Function = null) {
    this.httpService.loadList('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + ufId,
      this.municipios,
      callback);
  }

  save() {
    this.msgs = [];

    if (this.indCentralizada != undefined ||
      this.indMudancaPc != undefined ||
      this.codPlanRef != undefined ||
      this.tipoEcdId != undefined ||
      this.tipoEcfId != undefined) {

      if (this.indCentralizada == null || this.indCentralizada == undefined
        || this.indCentralizada == '') {
        this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: "Favor inserir um valor para Ind. Centralizada" });
      } else if (this.indMudancaPc == null || this.indMudancaPc == undefined
        || this.indMudancaPc == '') {
        this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: "Favor inserir um valor para Ind. Mudança" });
      } else if (this.codPlanRef == null || this.codPlanRef == undefined
        || this.codPlanRef == '') {
        this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: "Favor inserir um valor para Cod. Plan. Ref" });
      } else if (this.tipoEcdId == null || this.tipoEcdId == undefined
        || this.tipoEcdId == '') {
        this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: "Favor inserir um valor para Tipo ECD" });
      } else if (this.tipoEcfId == null || this.tipoEcfId == undefined
        || this.tipoEcfId == '') {
        this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: "Favor inserir um valor para Tipo ECF" });
      } else {

        let dados = {
          usuarioId: this.sessionService.loggedUser.id,
          empresaId: this.dblinkedSessionService.empresa.id,
          indCentralizada: this.indCentralizada,
          indMudancaPc: this.indMudancaPc,
          codPlanRef: this.codPlanRef,
          tipoEcdId: this.tipoEcdId,
          tipoEcfId: this.tipoEcfId,
          ano: this.selectedYear
        }

        this.httpService.wait();
        this.httpService.post('/custom/ecd/atualizar-dados-cadecd', dados)
          .subscribe(
            res => {
              this.msgs = [];
              if (res["error"] == false) {
                this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              } else {
                this.messageService.add({ key: 'messageLinnks', severity: 'error', detail: res["message"] });
              }
              this.httpService.done();
            },
            err => {
              console.log(err);
              this.httpService.done();
            }
          );

        this.indCentralizada = undefined;
        this.indMudancaPc = undefined;
        this.codPlanRef = undefined;
        this.tipoEcdId = undefined;
        this.tipoEcfId = undefined;
      }

    } else {

      const data: any = new Object();

      const formValues = this.formGroup.getRawValue();
      const controls = this.formGroup.controls;

      console.log('primeiro if: ' + this.selectedYear);
      // TODO: Move (preSave) and handle all years
      if (this.selectedYear) {
        let found = false;

        Object.keys(controls).filter(key => key.startsWith('empresaPeriodos[periodo=' + this.selectedYear + ']'))
          .forEach((key: string) => {
            if (key.indexOf(']') > -1 && this.pjHabChildKeys.indexOf(key.split(']')[1]) > -1 && controls[key].value) {
              found = true;
            }
          });

        if (!found) {
          const control = controls['empresaPeriodos[periodo=' + this.selectedYear + ']indPjHab'];

          if (control.value) {
            control.setValue(false);
            control.updateValueAndValidity();
            control.parent.updateValueAndValidity();

            control.markAsDirty(); // TODO: Review
          }
        }
      }

      this.empresa.empresaPeriodos.forEach(element => {
        if (element.periodo == this.selectedYear) {
          data.empresaPeriodos = element;
        }
      });


      Object.keys(formValues).forEach((key: string) => {
        if (/*controls.transient || */!controls[key].dirty) { // TODO: Use transient controls
          return;
        }

        this.setValue(data, key, formValues[key]);
      });
      console.log(data);

      this.httpService.wait();
      this.httpService.patch('/empresa', this.dblinkedSessionService.empresa.id, data)
        .subscribe(() => this.postSave(data),
          error => this.httpService.handleError(error, () => this.save()),
          () => this.httpService.done());
    }
  }

  postSave(data?: any) {
    // TODO: Update this.empresa

    const empresa: Empresa = this.dblinkedSessionService.empresas.find(e => e.id === this.dblinkedSessionService.empresa.id);

    this.httpService.wait();
    this.httpService.get('/empresa?$select=cnpj,razaoSocial', this.dblinkedSessionService.empresa.id)
      .subscribe(e => {
        empresa.razaoSocial = e.value.razaoSocial;
        empresa.cnpj = e.value.cnpj;

        this.dblinkedSessionService.sessionLoaded.next();

        this.loadEmpresa(true);
      },
        error => this.httpService.handleError(error, () => this.postSave(data)),
        () => this.httpService.done());
  }

  tabChange() {
    if (this.tabIndex > 0 && this.formaTribs.length === 0) {
      this.httpService.loadLists([{ url: '/tipoecd?$select=id,cod,descricao&$orderby=cod', destination: this.tipoEcds },
      { url: '/tbtipoecf?$select=id,cod,descricao&$orderby=cod', destination: this.tipoEcfs },
      { url: '/formatrib?$select=id,cod,descricao&$orderby=cod', destination: this.formaTribs },
      { url: '/formatribper?$select=id,cod,descricao&$orderby=cod', destination: this.formaTribPers },
      { url: '/mesbalred?$select=id,cod,descricao&$orderby=cod', destination: this.mesBalReds },
      { url: '/formaapur?$select=id,cod,descricao&$orderby=cod', destination: this.formaApurs },
      { url: '/formaapuri?$select=id,cod,descricao&$orderby=cod', destination: this.formaApurIs },
      { url: '/apurcsll?$select=id,cod,descricao&$orderby=cod', destination: this.apurCslls },
      { url: '/indrecreceita?$select=id,cod,descricao&$orderby=cod', destination: this.indRecReceitas },
      { url: '/indaliqcsll?$select=id,cod,descricao&$orderby=cod', destination: this.indAliqCslls },
      { url: '/codqualifpj?$select=id,cod,descricao&$orderby=cod', destination: this.codQualifPjs },
      { url: '/tipescpre?$select=id,cod,descricao&$orderby=cod', destination: this.tipEscPres },
      { url: '/tipent?$select=id,cod,descricao&$orderby=cod', destination: this.tipEnts }], () => {
        const y = this.selectedYear;

        const controlIndPjHab = this.formGroup.controls['empresaPeriodos[periodo=' + y + ']indPjHab'];
        this.handlePjHab(this.selectedYear, controlIndPjHab.value);

        this.quarters.forEach(q => {
          const control = this.formGroup.controls['empresaPeriodos[periodo=' + y + ']formaTribPerRelacs[periodo=' + q + ']formaTribPer.id'];
          control.setValidators([Validators.required]);
          control.updateValueAndValidity();
          control.parent.updateValueAndValidity();

          this.handleFormaTribPer(this.selectedYear, q, control.value);
        });

        this.months.forEach(m => {
          const control = this.formGroup.controls['empresaPeriodos[periodo=' + y + ']mesBalRedRelacs[periodo=' + m.id + ']mesBalRed.id'];
          control.setValidators([Validators.required]);
          control.updateValueAndValidity();
          control.parent.updateValueAndValidity();
        });
      });

      this.tabReady = true;
    }
  }

  handleCodScp(year: number, value: boolean) {
    this.displayCodScp = value;

    const controlCodScp = this.formGroup.controls['empresaPeriodos[periodo=' + year + ']codScp'];

    if (value) {
      controlCodScp.setValidators([Validators.required, Validators.maxLength(14)]);
    } else {
      controlCodScp.clearValidators();
    }

    controlCodScp.updateValueAndValidity();
    controlCodScp.parent.updateValueAndValidity();
  }

  handleFormaApur(year: number, formaTribValue: number, formaApurValue: number) {
    this.quarters.forEach(q => {
      const control = this.formGroup.controls['empresaPeriodos[periodo=' + year + ']' +
        'formaTribPerRelacs[periodo=' + q + ']formaTribPer.id'];

      if (control.disabled) {
        control.enable();
      }

      let value: number = null;

      if (formaApurValue === 2) { // Apuração ANUAL
        if ([1, 5, 6].indexOf(formaTribValue) !== -1) { // Forma de tributação REAL, PRESUMIDO ou ARBITRADO
          switch (formaTribValue) {
            case 1: // REAL
              value = 2;
              break;
            case 5: // PRESUMIDO
              value = 3;
              break;
            case 6: // ARBITRADO
              value = 4;
              break;
          }

          if (!control.value || parseInt(control.value, 10) !== value) {
            control.setValue(value);
            control.updateValueAndValidity();
            control.parent.updateValueAndValidity();

            control.markAsDirty();
          }

          if (control.enabled) {
            control.disable();
          }
        } else {
          if (!control.value || parseInt(control.value, 10) > 0) {
            control.setValue(null);
            control.updateValueAndValidity();
            control.parent.updateValueAndValidity();

            control.markAsDirty();
          }
        }
      }
    });
  }

  handleFormaTribPer(year: number, quarter: number, value: number) {
    this.months.filter(m => m.id > (quarter - 1) * 3 && m.id <= quarter * 3).forEach(m => {
      const control = this.formGroup.controls['empresaPeriodos[periodo=' + year + ']mesBalRedRelacs[periodo=' + m.id + ']mesBalRed.id'];

      if (value === 2 || value === 5) {
        if (control.value !== 2 && control.value !== 3) {
          // Os meses correspondentes a trimestres marcados em 0010.FORMA_TRIB_PER
          // iguais a “R”(2) ou “E”(5) devem estar preenchidos com “E”(2) ou “B”(3)
          control.setValue(null);
          control.updateValueAndValidity();
          control.parent.updateValueAndValidity();

          control.markAsDirty();
        }
      } else if (value !== undefined && value !== null) {
        if (control.value !== 1) {
          // Os meses correspondentes a trimestres marcados em 0010.FORMA_TRIB_PER
          // como diferente de “R”(2) e “E”(5) devem estar preenchidos com zero “0”(1)
          control.setValue(1);
          control.updateValueAndValidity();
          control.parent.updateValueAndValidity();

          control.markAsDirty();
        }
      } else if (control.value !== undefined && control.value !== null) {
        control.setValue(null);
        control.updateValueAndValidity();
        control.parent.updateValueAndValidity();

        control.markAsDirty();
      }
    });
  }

  handlePjHab(year: number, value: boolean) {
    this.pjHabChildKeys.forEach(key => {
      const control = this.formGroup.controls['empresaPeriodos[periodo=' + year + ']' + key];

      if (!value && control.value) {
        control.setValue(false);
        control.updateValueAndValidity();
        control.parent.updateValueAndValidity();

        control.markAsDirty();
      }

      if (value && control.disabled) {
        control.enable();
      } else if (!value && control.enabled) {
        control.disable();
      }
    });
  }

  mesBalRedDisabled(id: number, year: number, month: number): boolean {
    const q = Math.ceil(month / 3);
    const qControl = this.formGroup.controls['empresaPeriodos[periodo=' + year + ']formaTribPerRelacs[periodo=' + q + ']formaTribPer.id'];

    if (qControl.value === 2 || qControl.value === 5) {
      if (id !== 2 && id !== 3) {
        // Os meses correspondentes a trimestres marcados em 0010.FORMA_TRIB_PER
        // iguais a “R”(2) ou “E”(5) devem estar preenchidos com “E”(2) ou “B”(3)
        return true;
      }
    } else if (qControl.value !== undefined && qControl.value !== null) {
      if (id !== 1) {
        // Os meses correspondentes a trimestres marcados em 0010.FORMA_TRIB_PER
        // como diferente de “R”(2) e “E”(5) devem estar preenchidos com zero “0”(1)
        return true;
      }
    }

    return false;
  }

  get selectedYear(): number {
    return this.years[this.tabIndex - 1];
  }

  empresaPeriodo(year: number): EmpresaPeriodo {
    const empresaPeriodo: EmpresaPeriodo = this.empresa.empresaPeriodos.find(ep => ep.periodo === year);
    if (empresaPeriodo) {
      return empresaPeriodo;
    }

    this.empresa.empresaPeriodos.sort((a: EmpresaPeriodo, b: EmpresaPeriodo) => {
      if (a.periodo === b.periodo) {
        return 0;
      } else if (a.periodo < b.periodo) {
        return 1;
      } else {
        return -1;
      }
    });

    return this.empresa.empresaPeriodos.find(ep => ep.periodo < year);
  }

  formaTribPer(year: number, quarter: number): FormaTribPer {
    const empresaPeriodo: EmpresaPeriodo = this.empresaPeriodo(year);

    if (empresaPeriodo) {
      const formaTribPerRelac = empresaPeriodo.formaTribPerRelacs.find(f => f.periodo === quarter);

      if (formaTribPerRelac) {
        return formaTribPerRelac.formaTribPer;
      }
    }

    return null;
  }

  mesBalRed(year: number, quarter: number): FormaTribPer {
    const empresaPeriodo: EmpresaPeriodo = this.empresaPeriodo(year);

    if (empresaPeriodo) {
      const mesBalRedRelac = empresaPeriodo.mesBalRedRelacs.find(f => f.periodo === quarter);

      if (mesBalRedRelac) {
        return mesBalRedRelac.mesBalRed;
      }
    }

    return null;
  }

  setValue(obj: any, key: string, value: any) {
    if (key.indexOf('[') > -1) {
      const mainKey = key.substr(0, key.indexOf('['));

      if (!obj[mainKey]) {
        obj[mainKey] = new Array();
      }

      key = key.substr(key.indexOf('[') + 1);

      const paramKey = key.substr(0, key.indexOf('='));
      const paramValue = key.substr(key.indexOf('=') + 1, key.indexOf(']') - key.indexOf('=') - 1);

      let currentObj = obj[mainKey].find(d => d[paramKey] === +paramValue);

      if (currentObj === undefined) {
        const newObj = new Object();
        newObj[paramKey] = +paramValue;

        obj[mainKey].push(newObj);

        currentObj = newObj;
      }

      key = key.substr(key.indexOf(']') + 1);

      this.setValue(currentObj, key, value);

      return;
    }

    if (key.indexOf('.') > -1) {
      // TODO: Handle multi levels
      const mainKey = key.split('.')[0];
      const childKey = key.split('.')[1];

      if (!obj[mainKey]) {
        obj[mainKey] = new Object();
      }

      obj[mainKey][childKey] = value;
    } else {
      obj[key] = value;
    }
  }

  hideMessage() {
    this.msgs = [];
  }

}