import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import LogManagement from 'app/shared/common/log-management/log-control';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { PedidoService } from 'app/shared/services/pedido.service';
import { SessionService } from 'app/shared/services/session.service';
import { SidebarService } from 'app/shared/services/sidebar.service';
import { Action } from 'app/shared/services/socket/protocol/Action';
import { Method } from 'app/shared/services/socket/protocol/Method';
import { Service } from 'app/shared/services/socket/protocol/Service';
import { PysocketService } from 'app/shared/services/socket/pysocket.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs/Subscription';
import { isNullOrUndefined } from 'util';
import { OperacaoRegraContabil } from '../../cadastro/model/operacao-regra-contabil.model';
import { ParticipanteEmpresa } from '../../cadastro/model/participante-empresa.model';
import { CadCcus } from '../../contabil/model/cad-ccus.model';
import { CadProj } from '../../contabil/model/cad-proj.model';
import { TipoBaixa } from '../../util/model/tipo-baixa.model';
import { FinanceiroRateio } from '../model/financeiro-rateio.model';
import { NfeFin } from '../model/nfe-fin.model';

@Component({
  selector: 'app-receb-pagto',
  templateUrl: './receb-pagto.component.html',
  styleUrls: ['./receb-pagto.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class RecebPagtoComponent implements OnInit, OnDestroy {
  @ViewChildren('cmp') components: QueryList<MultiSelect>
  @ViewChild('dt1') dt1: Table | undefined
  @ViewChild('dt2') dt2: Table | undefined

  private sessionSubscription: Subscription

  //String
  codControle: string
  codPart: string
  codPedido: string
  cnpjCpf: string
  dataCompetencia: String
  dataEmissao: String
  dataVencimentoTitulo: string
  emailNegativacao: string = null
  historico: string
  nfTitulo: string
  nomeTitulo: string
  numDoc: string
  observacao: string = null
  parametro: string = null
  parcelaTitulo: string
  participanteCodigo: string = null
  participanteFantasia: string = null
  query: string
  razaoSocial: string
  statusTitulo: string
  subtitle: string
  subtitle2: string = ""
  tDtEmissao: string = ''
  tDtPrevisao: string = ''
  tDtVcto: string = ''
  telefoneNegativacao: string = null
  tHistorico: string = ''
  thistpag: string = '';
  tipoDesconto: string = null
  tipoJuros: string = null
  tipoMulta: string = null
  title: string
  tNf: string = ''
  tNome: string = ''
  tOperacao: string = ''
  tParcela: string = ''
  tStatus: string = ''
  ttipopag: string = ''
  tValor: string = ''
  valorTotal: string

  //Date
  dataFin: Date = null
  dataHistorico: Date
  dataIni: Date = null
  dataNovoVencimento: Date
  dataVencimento: Date
  defaultDate: Date
  dtCompetencia: Date
  dtEmissao: Date
  dtFimOccorencia: Date
  dtOperacao: Date
  primeiroVencimento = new Date()

  //Boolean
  alterarRecorrencia: boolean
  baixarTitulo: boolean
  baixarTitulosLote: boolean = false
  carregarNovamente: boolean = false
  carregarNovamenteVencidos: boolean = true
  carregarNovamenteRegistro: boolean = true
  centroCustoObrigatorio: boolean
  criarBaixaFinanceira: boolean
  criarHistoricoCobranca: boolean
  desabilitarTrazerBaixa: boolean
  editarBaixa: boolean
  editarHistorico: boolean
  editarPagtoOuReceb: boolean
  exibirModalAdicionarAdiantamento: boolean
  existeContaResultado: boolean
  existeProjetosRelacionados: boolean
  filtroPadrao: boolean = true
  finPed: boolean = false
  finProv: boolean = false
  flowUses: boolean = false
  histBaixa: boolean = true
  histCobranca: boolean = true
  isEstorno: boolean
  isFromPedido: boolean = false
  isReadOnly: boolean
  loading: boolean = false
  modalAlternarConta: boolean = false
  modalCentroCusto: boolean
  modalDetalhe: boolean
  modalDetalheCobranca: boolean = false
  modalDetalheContFin: boolean
  modalEdicaoLote: boolean = false
  modalEdit: boolean
  modalEfetuarBaixa: boolean
  modalFiltro: boolean = false
  modalNegativar: boolean = false
  modalOperacao: boolean
  modalOperacaoPagto: boolean
  modalParticipante: boolean = false
  pagamentoSomenteContaPadrao: boolean = false
  parcelamento: boolean
  participanteObrigatorio: boolean = false
  permiteAlterarMoeda: boolean = false
  permiteMultiMoeda: boolean = false
  provisao: boolean
  rateioJurosMulta: boolean
  recebimentoSomenteContaPadrao: boolean = false
  redirectNewPayment: boolean = false
  redirectNewReceivement: boolean = false
  refIsAlreadyDefined: boolean
  relatorioAnalitico: boolean
  requestApproval: boolean = false
  showSolicitarAprov: boolean = false
  statusLink1: boolean = true
  statusLink2: boolean = false
  statusLink3: boolean = false
  statusMovimentacao: boolean = true
  statusVencidos: boolean = false
  tabelaComFiltro: boolean = false
  tituloNfe: boolean
  tituloNewEdit: boolean
  tituloPedido: boolean
  todasSelecionadas: boolean
  todosSelecionados: boolean
  usaAsaas: boolean = false
  usaPix: boolean = false
  usarMultaJuros: boolean = false

  //Miscellaneous
  antecipaDiaNaoUtil: any
  cc: any
  cnabFormaSelecionadaId: any
  chavePixSelecionada: any
  cobranca: any = {}
  codBarras: any
  codPagamentoGps: any
  codReceita: any
  codRecolhimento: any
  contaSelecionadaId: any
  dataFinSearch: any
  dataIniSearch: any
  digitoIdRecolhimento: any
  dtApuracao: any
  eventValue: any
  filtroAdto: any
  historicoOperacao: any
  idRecolhimento: any
  infosTelaDeBaixa: any
  linkFinanceiro: any
  meses: any
  nDocumento: any
  ocorrenciaMes: any
  pagamentoEdit: {}
  participanteContasId: any
  participanteId: any
  participanteSelecionado: any = {}
  proj: any
  recebimentoEdit: {}
  recorrencia: any
  refFinanceiro: any
  selectedOption: any
  tipoSelecionadoId: any
  valorTotalParcelas: any
  comprovantePagamento: any
  comprovantes: any

  //Number
  abrirModal: number
  centroCustoId: number
  confTaxaId: number = 0
  contaBancariaAsaasId: number
  contadorFiltro: number
  contadorLinha: number = 0
  contadorRateio: number
  contaSelecionadaFiltro: number = null
  contaSelecionadaFiltroPreSelect: number = null
  diasDesconto: number = 0
  empresaContaPagamentoId: number = null
  empresaContaRecebimentoId: number = null
  exibicoesBaixas: number = 3
  idExcluir: number
  idMovFin: number
  moedaSelecionadaId: number
  nfbId: number
  nfeFinId: number
  nfeFinIdDetalhe: number = null
  nfhId: number
  public operacaotipoid: number
  parcelaId: number
  participanteTipoId: number = 0
  porcentagemCc: number
  qtdParcelas: number
  rateioCadProj: number
  rateioCentroCusto: number
  somaCompensado: number
  somaDisponivel: number
  somaOriginal: number
  somaSaldo: number
  tab: number
  tipoBaixaId: number = null
  todoCardAprovacaoId: number = null
  todoCardId: number = null
  totalAberto: number
  totalCompensado: number
  totalFiltro: number
  totalMov: number = 0
  totalPago: number
  totalPeriodo: number
  totalRecords: number = 0
  totalTitVencido: number
  totalVenc: number = 0
  totalVencido: number
  totalVencidos: number = 0
  valor: number
  valorAberto: number
  valorBaixa: number = 0
  valorCompensadoBaixa: number
  valorDesconto: number = null
  valorDescontoBaixa: number
  valorDescontoParcelamento: number
  valorJuros: number = null
  valorJurosBaixa: number
  valorJurosParcelamento: number
  valorMulta: number = null
  valorMultaBaixa: number
  valorMultaParcelamento: number
  valorOperacao: number
  valorPrincipalParcelamento: number
  valorReais: number
  valorRecebido: number
  valorRecebidoBaixa: number
  valorSaldoBaixar: number
  valorTaxa: number = 1
  valorTaxaAdm: number
  valorTotalBaixa: number
  valorTotalParcelamento: number
  valorVencimento: number

  //Classes
  cadProj = new CadProj
  centroCusto = new CadCcus
  cliente: ParticipanteEmpresa
  conta: EmpresaConta
  contaSelecionada: EmpresaConta
  financeiroRateio = new FinanceiroRateio()
  operacaoRegra = new OperacaoRegraContabil
  tipoBaixa: TipoBaixa
  public toolbarMain = new Toolbar()

  //Arrays
  adtos: any[] = []
  adtosCache: any[]
  adtosFiltradosPorOperacaoId: any[]
  adtosListaFinal: any[] = new Array()
  adtosSelecionados: any[]
  baixas: Array<any> = new Array()
  clearArr: Array<any> = new Array()
  codReceitaLista: any[]
  cols: Array<any> = [
    { field: 'dt_emissao', header: 'EMISSÃO' },
    { field: 'dt_previsao', header: 'PREVISÃO' },
    { field: 'dt_vcto', header: 'VENCTO/BAIXA' },
    { field: 'historico', header: 'HISTÓRICO' },
    { field: 'historicoParcela', header: 'HIST PARCELA' },
    { field: 'nome', header: 'NOME' },
    { field: 'nomeConta', header: 'CONTA' },
    { field: 'numDoc', header: 'DOC' },
    { field: 'parcela', header: 'PARCELA' },
    { field: 'razaoSocial', header: 'RAZÃO SOCIAL' },
    { field: 'status', header: 'STATUS' },
    { field: 'remessa', header: 'REMESSA' },
    { field: 'tipoBaixa', header: 'TIPO' },
    { field: 'valor', header: 'VALOR' },
    { field: 'operacao', header: 'OPERAÇÃO' }
  ]
  colsMovimentacao = []
  colsVencidos = []
  contasFornecedorLista: any[]
  detalheAdto: Array<any> = new Array()
  detalheCont: Array<any> = new Array()
  detalheFin: Array<any> = new Array()
  historicos: Array<any> = new Array()
  historicosPag: Array<any> = new Array()
  idNfesBaixa: Array<any> = new Array()
  linhasEdicao: Array<any> = new Array()
  list2: Array<any> = new Array()
  movimentacaoRateios: Array<FinanceiroRateio> = new Array()
  operacao: Array<any> = [
    { label: 'Adiantamento de Fornecedores Nacionais', value: 1 },
    { label: 'Adiantamento de Clientes Nacionais', value: 2 },
    { label: 'Adiantamento de Fornecedores Internacionais', value: 3 },
    { label: 'Adiantamento de Clientes Internacionais', value: 4 }]

  parcelamentoList: Array<NfeFin> = new Array()
  recebimentos: Array<any> = new Array()
  remessas: Array<any> = new Array()
  rowsSelected: Array<any> = new Array()
  sidebarCardId: Array<any> = new Array()
  TipoPag: Array<any> = new Array()
  tiposLista: any[] = []
  vencidos: Array<any> = new Array()
  visiveis: Array<any> = new Array()
  visiveisVencidos: Array<any> = new Array()
  listApprovalStatus: Array<string> = new Array()
  chavesPixParticipante: Array<any> = new Array()
  cardsAprovacaoCriados: Array<any> = new Array()

  //Dropdown and Multiselect
  itensEditaveis: SelectItem[] = []
  opcoesCentroCusto: SelectItem[]
  selectContas: SelectItem[]
  opcoesMoeda: SelectItem[]
  opcoesOperacao: SelectItem[]
  opcoesProjetos: SelectItem[]
  opcoesTiposBaixa: SelectItem[]
  remessaOptions: SelectItem[]
  statusAprovOptions: SelectItem[]
  statusOptions: SelectItem[]
  tipoMultaJuros: SelectItem[] = [
    { value: "FIXED", label: "Valor" },
    { value: "PERCENTAGE", label: "Porcentagem" }
  ]

  // froala
  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    placeholderText: 'Adicionar comentário...',
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'],
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',
    events: { 'froalaEditor.initialized': function () { }, },
  }

  //Toolbar
  toolbarMainIni = [
    {
      key: 'more', icon: 'more_vert', text: '', tooltip: 'Opções', visible: true, disabled: false, color: 'default', dropdown: true,
      options: [
        { label: 'Atualizar', callback: () => this.atualizarRelatorio() },
        { label: 'Expandir relatório', callback: () => this.relAnalitico() },
        { label: 'Gerar remessas', callback: () => this.gerarRemessas() },
        { optionKey: 'boleto', label: 'Gerar boleto', callback: () => this.gerarBoleto() },
        { optionKey: 'pix', label: 'Gerar QRCode Pix', callback: () => this.gerarPixQrCode() },
        { optionKey: 'cobranca', label: 'Enviar fatura', callback: () => this.gerarCobranca(true) },
        { optionKey: 'assinatura', label: 'Gerar assinatura', callback: () => this.gerarAssinatura() },
        { optionKey: 'sidebar', label: 'Abrir Sidebar', callback: () => this.rowSidebar(this.sidebarCardId) },
        { optionKey: 'baixar', label: 'Baixar', callback: () => this.baixarLote() },
        { optionKey: 'solicitar_aprovacao', label: 'Solicitar Aprovação', callback: () => this.solicitarAprovacao() },
        { optionKey: 'detalhe_cobranca', label: 'Detalhes cobrança', callback: () => this.buscarInfosCobranca() },
        { optionKey: 'solicitar_autorizacao', label: 'Solicitar Autorização', callback: () => this.solicitarAprovacao() },
        { optionKey: 'receipt', label: 'Ver Comprovante', callback: () => this.recuperaComprovantes() },
        { label: 'Reprocessar contabilidade', callback: () => this.reprocessarContabilidade() },
        { label: 'Cancelar baixas', callback: () => this.cancelarBaixas() },
      ]
    },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.gerarRelatorio() },
    { icon: 'filter_alt', text: 'Filtrar', tooltip: 'Filtrar', visible: true, disabled: false, color: 'default', onClick: () => this.openModalFiltro() },
    { key: "cobranca", icon: 'request_quote', text: 'Ver fatura', tooltip: 'Ver fatura', visible: false, disabled: false, color: 'default', onClick: () => this.gerarCobranca() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirRecebimentoPagamentoEmLote() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => { } },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => { } },
  ]

  //Skeleton
  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'VENC/BAIXA', size: '10%', textAlign: 'left' },
      { name: 'PREVISÃO', size: '8%', textAlign: 'left' },
      { name: 'EMISSÃO', size: '8%', textAlign: 'left' },
      { name: 'NOME', size: '12%', textAlign: 'left' },
      { name: 'RAZÃO SOCIAL', size: '12%', textAlign: 'left' },
      { name: 'OPERAÇÃO', size: '12%', textAlign: 'left' },
      { name: 'CONTA', size: '12%', textAlign: 'left' },
      { name: 'HISTÓRICO', size: '12%', textAlign: 'left' },
      { name: 'DOC', size: '9%', textAlign: 'left' },
      { name: 'Parc', size: '6%', textAlign: 'left' },
      { name: 'Valor', size: '10%', textAlign: 'left' },
      { name: 'Status', size: '10%', textAlign: 'left' },
      { name: 'Status Aprovação', size: '10%', textAlign: 'left' },
      { name: 'Remessa', size: '8%', textAlign: 'left' },
      { name: 'Tipo Baixa', size: '8%', textAlign: 'left' },
      { name: 'Observações', size: '10%', textAlign: 'left' },
      { name: 'Contabilidade', size: '5%', textAlign: 'left' },
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true
  })

  private LogManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private pedidoService: PedidoService,
    private messageService: MessageService,
    private httpClient: HttpClient,
    private sidebarService: SidebarService,
    private socketService: PysocketService
  ) { }

  ngOnInit() {
    this.parcelaId = null
    this.valorPrincipalParcelamento = 0.00;
    this.valorMultaParcelamento = 0.00;
    this.valorJurosParcelamento = 0.00;
    this.valorDescontoParcelamento = 0.00;
    this.valorTotalParcelamento = 0.00;
    this.baixarTitulo = false;

    this.contadorFiltro = 0;
    this.moedaSelecionadaId = 1;
    this.totalRecords = 0;

    this.colsMovimentacao = [
      { field: 'dt_vcto', header: 'Vencto/Baixa' },
      { field: 'dt_previsao', header: 'Previsão' },
      { field: 'dt_emissao', header: 'Emissão' },
      { field: 'nome', header: 'Nome' },
      { field: 'operacao', header: 'Operacao' },
      { field: 'contaBancaria', header: 'Conta' },
      { field: 'historico', header: 'Historico' },
      { field: 'nf', header: 'Doc' },
      { field: 'parcela', header: 'Parcela' },
      { field: 'valor', header: 'Valor' },
      { field: 'status', header: 'Status' },
      { field: 'statusRemessa', header: 'Remessa' },
    ];

    this.colsVencidos = [
      { field: 'dt_vcto', header: 'Vencto' },
      { field: 'dataBaixaPrev', header: 'Previsão' },
      { field: 'dt_emissao', header: 'Emissão' },
      { field: 'nome', header: 'Nome' },
      { field: 'operacao', header: 'Operacao' },
      { field: 'contaBancaria', header: 'Conta' },
      { field: 'historico', header: 'Historico' },
      { field: 'nf', header: 'Doc' },
      { field: 'parcela', header: 'Parcela' },
      { field: 'valor', header: 'Valor' },
      { field: 'status', header: 'Status' },
      { field: 'statusRemessa', header: 'Remessa' },
    ];

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.modalDetalheContFin = false;
    this.modalEdit = false;
    this.modalDetalhe = false;
    this.todasSelecionadas = false;
    this.modalEfetuarBaixa = false;
    this.modalEdicaoLote = false;
    this.modalOperacao = false;
    this.modalOperacaoPagto = false;
    this.tituloNfe = false;
    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.tab = 0;
    this.idExcluir = 0;
    this.provisao = false;
    this.moedaSelecionadaId = 1;
    this.parcelamentoList = new Array();

    this.activatedRoute.params.subscribe(params => {
      this.contaSelecionadaFiltro = null
      if (params['tipo']) {
        this.parametro = params.tipo;
        if (params.id) {
          this.idNfesBaixa.push(params.id);
        }

        this.subtitle = 'Todas as contas';

        if (params.tipo === 'cr') {
          this.title = 'RECEBIMENTO';
          this.subtitle2 = 'Títulos a Receber no período ';
          this.titleService.title = 'Títulos a Receber';
          this.redirectNewReceivement = params.action == 'newReceivement';

          if (params.modal == 'baixar') {
            if (params.id) {
              this.modalBaixaRota(params.id);
            }
          }
          else if (params.modal == 'editar') {
            this.abrirModal = 1;
          }
          else {
            this.changePeriod(true);
          }

        } else {
          this.title = 'PAGAMENTO';
          this.subtitle2 = 'Títulos a Pagar no período ';
          this.titleService.title = 'Títulos a Pagar';

          this.redirectNewPayment = params.action == 'newPayment';

          if (params.modal == 'baixar') {
            if (params.id) {
              this.modalBaixaRota(params.id);
            }
          }
          else if (params.modal == 'editar') {
            this.abrirModal = 2;
          }
          else {
            this.changePeriod(true);
          }
        }
      }
    });
    // centro de custo e projetos
    this.centroCusto = new CadCcus;
    this.cadProj = new CadProj;
    this.modalCentroCusto = false;
    this.porcentagemCc = 0;
    this.cc = [];
    this.proj = [];
    this.rateioCentroCusto = 100;
    this.rateioCadProj = 100;
    this.movimentacaoRateios = new Array();

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.defaultDate = new Date();
        let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
        let mes = this.defaultDate.getMonth() > 8 ? (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
        this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
        this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
          this.contaSelecionadaFiltroPreSelect = null
          this.contaSelecionadaFiltro = null
          if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
            mes = this.dblinkedSessionService.periodo.month.value > 9 ? this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;

            const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
            this.defaultDate = new Date(dt);
            lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
            this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
            this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();
          }
        }

        // verifica se é um redirecionamento da home, se nao, executa os métodos normais
        if (this.redirectNewPayment)
          this.displayPgmtoDialog(2);
        else if (this.redirectNewReceivement)
          this.displayRecebimentoDialog(1);
        else this.changePeriod(true);

      });

    this.meses = [];
    this.meses.push({ label: 'Selecionar', value: 0 });
    for (let mes = 1; mes <= 12; mes++) {
      this.meses.push({ label: mes, value: mes });
    }

    /*contador scroll table adiantamento*/
    this.contadorLinha = 0;

    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true

    this.listApprovalStatus = ['APROVADO', 'PEDIDO CRIADO', 'TITULO CRIADO', 'BAIXADO', 'FINANCEIRO APROVADO', 'PEDIDO APROVADO', 'RMS APROVADO']

    this.getPersonalizedStatus()
  }

  ngOnDestroy() {
    this.sessionSubscription.remove(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
    this.toolbarService.hidden = false
  }

  changePeriod(padrao: boolean, atualizarTudo: boolean = true) {
    if (this.statusMovimentacao) this.carregarNovamenteRegistro = atualizarTudo
    if (this.statusVencidos) this.carregarNovamenteVencidos = atualizarTudo

    this.carregarNovamente = atualizarTudo

    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
      if (this.contaSelecionadaFiltro) {
        let conta = this.selectContas.find(row => row.value === this.contaSelecionadaFiltro)
        if (conta) this.subtitle = "Conta selecionada: " + conta.label
        else this.subtitle = "Todas as contas"
      } else {
        this.subtitle = "Todas as contas"
      }

      this.filtroPadrao = padrao;

      this.baixarTitulo = false;
      this.opcoesMoeda = [];
      this.modalEdit = false;
      this.modalDetalhe = false;
      this.todasSelecionadas = false;
      this.modalEfetuarBaixa = false;
      this.modalEdicaoLote = false;
      this.modalOperacao = false;
      this.modalOperacaoPagto = false;
      this.modalAlternarConta = false;
      this.parcelamento = false;

      if (atualizarTudo) {
        this.valorPrincipalParcelamento = 0.00;
        this.valorMultaParcelamento = 0.00;
        this.valorJurosParcelamento = 0.00;
        this.valorDescontoParcelamento = 0.00;
        this.valorTotalParcelamento = 0.00;
        this.parcelamentoList = new Array();

        this.valorVencimento = 0.00;
        this.valorRecebido = 0.00;
        this.valorAberto = 0.00;
        this.valorTaxa = 1.00;
        this.contaSelecionada = new EmpresaConta();
        this.tipoBaixa = new TipoBaixa();
        this.contaSelecionadaId = '';

        this.cliente = new ParticipanteEmpresa();
        this.valorRecebidoBaixa = 0.00;
        this.valorMultaBaixa = 0.00;
        this.valorJurosBaixa = 0.00;
        this.valorDescontoBaixa = 0.00;
        this.valorTotalBaixa = 0.00;
        this.nfeFinId = 0;
        this.nfbId = 0;
        this.nfhId = 0;

        this.nomeTitulo = '';
        this.nfTitulo = '';
        this.dataVencimentoTitulo = '';
        this.parcelaTitulo = '';
        this.historico = '';
        this.dataHistorico = new Date();
        this.historicosPag = new Array<any>();
        this.TipoPag = new Array<any>();

        this.criarHistoricoCobranca = false;
        this.criarBaixaFinanceira = false;
        this.editarHistorico = false;
        this.editarBaixa = false;

        this.totalPeriodo = 0.00;
        this.totalFiltro = 0.00;
        this.totalPago = 0.00;
        this.totalAberto = 0.00;
        this.totalVencido = 0.00;
        this.totalTitVencido = 0.00;
        this.recebimentos = new Array<any>();
        this.visiveis = new Array<any>();
        this.vencidos = new Array<any>();
        this.visiveisVencidos = new Array<any>();
        this.remessas = new Array<any>();
        if (this.abrirModal > 0) {
          //do nothing
        } else {
          this.idNfesBaixa = new Array();
          this.rowsSelected = new Array()
        }
        this.selectedOption = '';


        this.contaSelecionada = new EmpresaConta();
        this.cliente = new ParticipanteEmpresa();
        this.operacaoRegra = new OperacaoRegraContabil;
        this.centroCusto = new CadCcus;
        this.cadProj = new CadProj;
        this.relatorioAnalitico = true;

        this.valorOperacao = 0.00;
        this.tipoSelecionadoId = "";
        this.cnabFormaSelecionadaId = "";
        this.codReceitaLista = [];
        this.nDocumento = "";
        this.codBarras = "";
        this.codReceita = "";
        this.dtApuracao = "";
        this.idRecolhimento = "";
        this.digitoIdRecolhimento = "";
        this.participanteContasId = "";
        this.codRecolhimento = "";
        this.codPagamentoGps = "";
        this.historicoOperacao = '';
        this.linkFinanceiro = '';
        this.refFinanceiro = '';
        this.recorrencia = '';
        this.ocorrenciaMes = 1;
        this.antecipaDiaNaoUtil = '';
        this.statusOptions = [];
        this.statusAprovOptions = [];
        this.remessaOptions = [];
        this.relatorioAnalitico = true;
        this.isEstorno = false;

        this.loadTiposPagamentos();
        this.loadCodReceita();
        this.loadParametroFinanceiro();
        this.loadConfigApi()
        this.pedidoService.getMoedaDesc(this.opcoesMoeda);
        this.centroDeCustoObrigatorio();
        this.getCompanyParameter()
        this.comboContas()

        this.httpService.get('/tipobaixa?$select=id,descricao'
          + '&$orderby=descricao')
          .subscribe(ref => this.loadTipoBaixa(ref.value),
            error => this.httpService.handleError(error, () => this.changePeriod(padrao)))


        // somente após habilitar datas
        if (this.dataIniSearch !== null && this.dataFinSearch !== null) {

          if (!isNullOrUndefined(this.dblinkedSessionService.empresa.altoVolume)) {

            if (!this.dblinkedSessionService.empresa.altoVolume || !this.filtroPadrao) {
              this.getHistoryTransactions(0, this.dt1 ? this.dt1._rows : 15);
              this.loadVencidos(0, this.dt2 ? this.dt2._rows : 15);
            }

          } else {
            this.getHistoryTransactions(0, this.dt1 ? this.dt1._rows : 15);
            this.loadVencidos(0, this.dt2 ? this.dt2._rows : 15);
          }

          setTimeout(() => {
            this.loadNovaTransacao();
          }, 1000);
        }
      }
    }
  }

  async loadVencidos(offset: number = 0, limit: number = 15, filters: Object = {}, sortField: string = "", sortOrder: number = 0, defaultParam: boolean = true) {
    if (this.carregarNovamenteVencidos) {
      if (this.dblinkedSessionService.hasEmpresaSelected) {

        this.totalVencidos = 0;

        const data = {
          empresaId: this.dblinkedSessionService.empresa.id,
          parametro: this.parametro,
          contaSelecionada: this.contaSelecionadaFiltro ? this.contaSelecionadaFiltro : 0,
          offset,
          limit,
          filters,
          sortField,
          sortOrder,
          defaultParam,
          dataIniSearch: this.dataIniSearch,
          dataFinSearch: this.dataFinSearch,
          tipo: this.parametro,
          ano: this.dblinkedSessionService.periodo.year.value,
          mes: this.dblinkedSessionService.periodo.month.value,
          participante: this.participanteCodigo
        }

        try {
          this.skeletonConfig.startLoad()
          const ref = await this.httpService.post('/custom/financeiro/cr-cp-empresa-vencidos', data).toPromise()

          if (ref.error) {
            this.exibirMensagem(ref)
            return
          }

          this.vencidos = ref.data;
          this.totalVencidos = ref.totalRecords
          this.totalTitVencido = 0.00;
          this.visiveisVencidos = new Array();

          this.vencidos.forEach(element => {
            this.visiveisVencidos.push(element);

            if (element.status === 'VENCIDO') {
              this.valor = this.utilService.parseMoney((element.valor));
              this.totalTitVencido = this.totalTitVencido + this.valor;
            }

            this.operacaoRegra.nfId = element.id;

            if (isNullOrUndefined(element.nfeid)) {
              this.operacaoRegra.podeEditar = true;
            } else {
              this.operacaoRegra.podeEditar = false;
            }
          });

        } catch (error) {
          this.exibirMensagem({ error: true, message: "Erro no API ao recuperar os títulos vencidos: " + error.message })
        } finally {
          this.skeletonConfig.endLoad()
        }

      }
    }
    this.carregarNovamenteVencidos = true
  }

  loadTipoBaixa(array: Array<TipoBaixa>) {
    this.opcoesTiposBaixa = [];
    this.opcoesTiposBaixa.push({ label: 'Nenhum', value: null });
    array.forEach(tipoBaixa => {
      this.opcoesTiposBaixa.push({ label: tipoBaixa.descricao, value: tipoBaixa.id });
    });
  }

  onInputDate(event: any) {
    let value = event.target.value.replace(/^(\d{1,2})\/?(\d{1,2})\/?(\d{1,4})/, '$1$2$3').split(/\D/)[0],
      response = '';

    if (value.slice(0, 2).length > 0) {
      response = value.slice(0, 2) <= 31 ? value.slice(0, 2) : ''
      if (value.slice(2, 4).length > 0) {
        response += '/' + (value.slice(2, 4) <= 12 ? value.slice(2, 4) : '')
        if (value.slice(4, 8).length > 0) {
          response += '/' + (value.slice(6, 7) >= 1 && value.slice(6, 7) <= 3 ? value.slice(4, 8) : value.slice(4, 6))
        }
      }
    }
    event.target.value = response
  }

  onRowSelect(event: any) {

    this.eventValue = event;
    this.movimentacaoRateios =  new Array();

    if (event.provisao) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Títulos de provisão não podem ser baixados',

      });

      return;
    }


    /* Reinicio Variáveis Parcelamento*/
    this.primeiroVencimento = this.defaultDate;
    this.parcelamento = false;
    this.valorPrincipalParcelamento = 0.00;
    this.valorMultaParcelamento = 0.00;
    this.valorJurosParcelamento = 0.00;
    this.valorDescontoParcelamento = 0.00;
    this.valorTotalParcelamento = 0.00;
    this.parcelamentoList = new Array();
    this.qtdParcelas = 0;

    this.infosTelaDeBaixa = event;

    this.tDtEmissao = '';
    this.tDtEmissao = event.dt_emissao;
    this.tDtVcto = '';
    this.tDtVcto = event.dt_vcto;
    this.tDtPrevisao = '';
    this.tDtPrevisao = event.dt_previsao;
    this.tNome = '';
    this.tNome = event.nome;
    this.tOperacao = '';
    this.tOperacao = event.operacao;
    this.tHistorico = '';
    this.tHistorico = event.historico;
    this.tNf = '';
    this.tNf = event.nf;
    this.tNf = '';
    this.tNf = event.nf;
    this.tParcela = '';
    this.tParcela = event.parcela;
    this.tValor = '';
    this.tValor = event.valor;
    this.tStatus = '';
    this.tStatus = event.status;
    this.dataEmissao = '';
    this.dataEmissao = event.dataEmissao;
    this.dataCompetencia = '';
    this.dataCompetencia = event.dataCompetencia;
    this.thistpag = event.historicosPag;
    this.ttipopag = event.TipoPag;
    this.adtosListaFinal = new Array()
    this.showTypeTabs('LINK_1');

    if (event.tipo !== 'pp' && event.tipo !== 'pr') {
      this.modalEfetuarBaixa = true;
      this.dataNovoVencimento = null;
      this.contaSelecionadaId = event.empresa_conta;
      this.valorSaldoBaixar = event.valor_saldo;
      this.valorRecebidoBaixa = Math.abs(this.utilService.parseMoney(event.valor)) - this.utilService.parseMoney(event.valorMulta) - this.utilService.parseMoney(event.valorJuros) + this.utilService.parseMoney(event.valorDesconto) + this.utilService.parseMoney(event.valorTaxaAdm)
      this.valorMultaBaixa = this.utilService.parseMoney(event.valorMulta);
      this.valorJurosBaixa = this.utilService.parseMoney(event.valorJuros);
      this.valorDescontoBaixa = this.utilService.parseMoney(event.valorDesconto);
      this.valorCompensadoBaixa = this.utilService.parseMoney(0.00);
      this.valorTaxaAdm = this.utilService.parseMoney(event.valorTaxaAdm);
      this.calcularValorBaixa();


      this.nomeTitulo = event.nome;
      this.nfTitulo = event.nf;
      this.dataVencimentoTitulo = event.dt_vcto;
      this.parcelaTitulo = event.parcela;
      this.nfeFinId = event.id;
      this.dataVencimento = event.dt_vcto;

      this.criarHistoricoCobranca = false;
      this.criarBaixaFinanceira = true;
      this.editarHistorico = false;

      this.editarBaixa = false;
      this.rateioJurosMulta = false;
      this.desabilitarTrazerBaixa = false;
      this.todoCardAprovacaoId = event.todo_card_id

      this.tipoBaixa.id = event.tipoBaixaId
      this.contaSelecionadaId = event.empresaContaId

      this.httpService.wait();
      this.httpService.get(`/custom/financeiro/buscar-baixas(${event.id})`)
        .subscribe(b => {
          if (b.error) this.exibirMensagem({ error: true, message: b.message })
          else this.baixas = b;
        }, error => this.httpService.handleError(error, () => this.onRowSelect(event)),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/financeiro/buscar-historico-cobranca',
        [event.id])
        .subscribe(h => {
          this.historicos = h;
        }, error => this.httpService.handleError(error, () => this.onRowSelect(event)),
          () => this.httpService.done());




    }
  }

  calcularValorBaixa() {
    this.valorTotalBaixa =
      this.utilService.parseDecimal4(this.valorRecebidoBaixa) +
      this.utilService.parseDecimal4(this.valorMultaBaixa) +
      this.utilService.parseDecimal4(this.valorJurosBaixa) -
      this.utilService.parseDecimal4(this.valorDescontoBaixa) -
      this.utilService.parseDecimal4(this.valorCompensadoBaixa)

    if (this.parametro === 'cr') this.valorTotalBaixa -= this.valorTaxaAdm
    else if (this.parametro === 'cp') this.valorTotalBaixa += this.valorTaxaAdm

    this.valorTotalBaixa = this.utilService.parseDecimal4(this.valorTotalBaixa);
    this.valorBaixa = this.utilService.parseDecimal4(this.valorRecebidoBaixa + this.valorCompensadoBaixa + this.valorTaxaAdm - this.valorDescontoBaixa)

    this.valorRecebidoBaixa = this.utilService.parseDecimal4(this.valorRecebidoBaixa);
  }

  onFilter(event: any) {

    this.valorVencimento = 0.00;
    this.valorRecebido = 0.00;
    this.valorAberto = 0.00;
    this.contadorFiltro = 0;
    this.visiveis = new Array();
    this.visiveisVencidos = new Array();
    event.filteredValue.forEach(element => {
      if (this.tab === 0) {
        this.visiveis.push(element);
      } else if (this.tab === 1) {
        this.visiveisVencidos.push(element);
      }

      this.valor = this.utilService.parseMoney((element.valor));
      this.valorVencimento = this.valorVencimento + this.valor;
      if (element.saldo > 0.00) {
        this.valor = this.utilService.parseMoney((element.valor));
        this.valorRecebido = this.valorRecebido + this.valor;
      } else if (element.saldo === 0.00) {
        this.valor = this.utilService.parseMoney((element.valor));
        this.valorAberto = this.valorAberto + this.valor;
      }
      this.contadorFiltro = this.contadorFiltro + 1;
    });
  }

  loadNovaTransacao() {
    this.parameterToolbar()
    this.changeToolbar()
    this.toolbarEnable();
  }

  relAnalitico() {
    this.relatorioAnalitico = !this.relatorioAnalitico;
    this.changeOpt()
  }

  changeOpt() {

    let opt

    if (this.tab === 0) {
      opt = [
        { label: (this.relatorioAnalitico ? 'Voltar relatório' : 'Expandir relatório'), callback: () => this.relAnalitico() },
        { label: 'Gerar remessas', callback: () => this.gerarRemessas() },
      ]

      if (this.rowsSelected.length > 0) opt.push(
        { label: 'Reprocessar contabilidade', callback: () => this.reprocessarContabilidade() },
        { label: 'Cancelar baixas', callback: () => this.cancelarBaixas() }
      )

    } else {
      opt = [
        { label: (this.relatorioAnalitico ? 'Voltar relatório' : 'Expandir relatório'), callback: () => this.relAnalitico() },
        { label: 'Gerar remessas', callback: () => this.gerarRemessas() },
      ]
    }


    this.toolbarMain.setOptions('btn2', opt)
  }

  gerarRemessas() {
    if (this.convertArr(this.rowsSelected, true).length === 0) return this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Você precisa selecionar pelo menos uma conta válida!' });

    const listasEnviar = {
      listaTitulos: this.convertArr(this.rowsSelected, true),
      usuario: this.sessionService.loggedUser.id,
      tipo: this.parametro,
      empresaId: this.dblinkedSessionService.empresa.id
    };
    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'custom/financeiro/pagamento/v2/post-remessas', listasEnviar)
      .subscribe(result => {

        if (result["mensagem"] === "erro") {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: '',
            detail: result["erro"],

          });
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: '',
            detail: result["sucesso"],

          });

        }
      },
        error => this.httpService.handleError(error),
        () => this.httpService.done());
    this.atualizarVoltar();
  }

  private toolbarEnable() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('save');

    this.toolbarService.replace('back', false, 'class-first-button', 'undo', 'Voltar', () => this.modalEfetuarBaixa = false);
    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.atualizarVoltar());
    this.toolbarService.replace('alterar', false, 'class-button-gray', 'Alterar Período/Conta', 'Alterar Período/Conta', () => this.openModalFiltro());
    //this.toolbarService.replace('save', false, 'class-button-gray', 'Alternar Conta', 'Alternar Conta', () => this.alternarConta());

    this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Opções');
    this.toolbarService.replace('pesquisa', true, 'class-edit-button', 'search', 'Pesquisar');
    this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar');

    this.toolbarService.replace('print', true, 'class-other-button', 'save', 'Salvar',);
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir',);
    //this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Alterar Títulos Selecionados', () => this.editarLote());

    if (this.parametro === 'cr') {
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Alterar Títulos Selecionados', () => this.editarRegistro(1, event));
    } else {
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Alterar Títulos Selecionados', () => this.editarRegistro(2, event));
    }



    if (this.parametro === 'cr') {
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo Recebimento', () => this.displayRecebimentoDialog(1));
    } else {
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo Pagamento', () => this.displayPgmtoDialog(2));
    }
  }

  alternarConta() {
    this.modalAlternarConta = true;
  }

  openModalFiltro() {
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch)
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch)
    this.participanteCodigo = ""
    if (this.parametro === 'cr') this.empresaContaRecebimentoId ? this.contaSelecionadaFiltroPreSelect = this.empresaContaRecebimentoId : null
    else this.empresaContaPagamentoId ? this.contaSelecionadaFiltroPreSelect = this.empresaContaPagamentoId : null

    if (this.contaSelecionadaFiltro) this.contaSelecionadaFiltroPreSelect = this.contaSelecionadaFiltro
    this.modalFiltro = true
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPageLarge;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinksLarge;
  }

  exibirBaixa(event: any) {

    this.criarHistoricoCobranca = false;
    this.criarBaixaFinanceira = false;
    this.editarHistorico = false;
    this.editarBaixa = true;

    this.valorDescontoBaixa = event.desconto;
    this.valorJurosBaixa = event.juros;
    this.valorMultaBaixa = event.multa;
    this.valorRecebidoBaixa = event.valor + event.valorTxAdm;
    this.dataVencimento = event.data_baixa;
    this.nfbId = event.nfbId;
    this.conta = event.empresaConta;
    this.tipoBaixa = event.tipoBaixa;
    this.valorTaxa = event.valorTaxa;
    this.moedaSelecionadaId = event.moedaId;
    this.contaSelecionadaId = event.empresaConta.id;
    this.calcularValorBaixa();

    this.rateioJurosMulta = event.rateioJurosMulta;
    let isBaixa = true;
    this.loadRateio(event.nfbId, isBaixa);

    this.httpService.wait();
    this.httpService.get('/custom/financeiro/buscar-adiantamentos-pelo-nfe-fin-baixa-id(' + this.nfbId + ',' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(listaNfeFinId => {


        if (this.adtosListaFinal.length > 0) {
          this.adtosListaFinal.forEach(af => {
            this.adtos.push(af);
          });
        }

        setTimeout(() => {
          this.adtosListaFinal = [];
          listaNfeFinId.forEach(element => {
            element.dataView = this.utilService._timestampToStringDateTime2(new Date(element.data));
            const index = this.adtos.findIndex(a => a.id === element.id);
            if (index >= 0) {
              this.adtos.splice(index, 1);
            }
            this.adtosListaFinal.push(element);
            this.totalValorOrigEDisp();
            this.totalValorCompensado();
            this.somaValoresCompensados("somar");
          });
        }, 1000);

      }, error => this.httpService.handleError(error, () => this.exibirBaixa(event)),
        () => this.httpService.done());

    this.desabilitarTrazerBaixa = true;
  }

  exibirHistorico(event: any) {

    this.criarHistoricoCobranca = false;
    this.criarBaixaFinanceira = false;
    this.editarHistorico = true;
    this.editarBaixa = false;

    this.nfhId = event.nfhId;
    this.historico = event.descricao;
    this.dataHistorico = event.data;

  }

  rowSelect(event: any) {
    this.sidebarCardId = new Array()

    this.rowsSelected.push(event.data)
    if (this.rowsSelected.length === 1) if (this.rowsSelected[0].todo_card_aprovacao_id) this.sidebarCardId.push(this.rowsSelected[0].todo_card_aprovacao_id)

    this.changeToolbar(event.data)

    this.totalFiltro = this.totalFiltro + this.utilService.parseMoney((event.data.valor))
  }

  rowUnselect(event: any) {
    this.sidebarCardId = new Array()

    this.rowsSelected = this.rowsSelected.filter(line => line.id !== event.data.id)
    if (this.rowsSelected.length === 1) if (this.rowsSelected[0].todo_card_aprovacao_id) this.sidebarCardId.push(this.rowsSelected[0].todo_card_aprovacao_id)

    this.changeToolbar()

    this.totalFiltro = this.totalFiltro - this.utilService.parseMoney((event.data.valor))
  }

  convertArr(data: Array<any>, all: boolean, casualties?: boolean): Array<any> {
    let aux: Array<any> = new Array()

    if (all) {
      data.forEach(item => {
        aux.push(item.id)
      })
    } else {
      data.forEach(item => {
        if (casualties) {
          if ((item.status.toUpperCase() === 'BAIXADO' || item.status.toUpperCase() === 'BAIXA PARCIAL')) aux.push(item.id)
        } else {
          if (item.status.toUpperCase() !== 'BAIXADO' && item.status.toUpperCase() !== 'BAIXA PARCIAL') aux.push(item.id)
        }
      })
    }

    return aux
  }

  selectAll(event: any) {
    this.sidebarCardId = new Array()
    this.rowsSelected = new Array()
    this.totalFiltro = 0

    if (event.checked) {
      if (this.tab === 0) this.recebimentos.forEach(item => {
        this.rowsSelected.push(item)
        const index = this.visiveis.findIndex(c => c.id === item.id);
        if (index >= 0 || this.visiveis.length === 0) this.totalFiltro = this.totalFiltro + this.utilService.parseMoney((item.valor));
      })
      else this.vencidos.forEach(item => {
        this.rowsSelected.push(item)
        const index = this.visiveisVencidos.findIndex(c => c.id === item.id);
        if (index >= 0 || this.visiveisVencidos.length === 0) this.totalFiltro = this.totalFiltro + this.utilService.parseMoney((item.valor));
      })
    }

    this.changeToolbar()
  }

  changeToolbar(event: any = {}) {
    this.toolbarMain.setOptionVisible('more', 'boleto', (this.parametro === 'cr' && this.rowsSelected.length > 0) ? true : false)
    this.toolbarMain.setOptionVisible('more', 'pix', (this.parametro === 'cr' && this.rowsSelected.length > 0 && this.usaPix) ? true : false)
    this.toolbarMain.setOptionVisible('more', 'cobranca', (this.parametro === 'cr' && this.rowsSelected.length > 0 && this.usaAsaas) ? true : false)
    this.toolbarMain.setOptionVisible('more', 'assinatura', (this.parametro === 'cr' && this.rowsSelected.length === 1 && this.usaAsaas) ? true : false)
    this.toolbarMain.setOptionVisible('more', 'sidebar', (this.flowUses && this.sidebarCardId.length === 1) ? true : false)
    this.toolbarMain.setOptionVisible('more', 'solicitar_aprovacao', (this.flowUses && this.validateApprovaStatus()) ? true : false)
    this.toolbarMain.setOptionVisible('more', 'solicitar_autorizacao', (this.rowsSelected.length > 0 && !this.flowUses))
    this.toolbarMain.setOptionVisible('more', 'baixar', this.rowsSelected.length > 0 ? true : false)
    this.toolbarMain.setOptionVisible('more', 'detalhe_cobranca', (this.parametro === 'cr' && this.rowsSelected.length === 1 && this.usaAsaas) ? true : false)
    this.toolbarMain.setOptionVisible('more', 'receipt', (this.parametro === 'cp' && this.rowsSelected.length === 1 && this.verificaComprovante()) ? true : false)
    // this.toolbarMain.setOptionVisible('more', 'gerar_pagamento', (this.rowsSelected.length > 0 && this.parametro === "cp" && this.statusMovimentacao && this.usaAsaas))
    this.toolbarMain.setVisible('cobranca', this.rowsSelected.length > 0 && this.parametro === 'cr' && this.usaAsaas);
    this.toolbarMain.setVisible('btn5', this.rowsSelected.length > 0)

    this.toolbarMain.setVisible('btn6', this.rowsSelected.length > 0)
    this.toolbarMain.setClick('btn6', (this.rowsSelected.length < 2) ? () => this.editarRegistro(this.parameterToolbar(), event) : () => this.editarLote())

    this.toolbarMain.setClick('btn7', (this.parametro === 'cr') ? () => this.displayRecebimentoDialog(this.parameterToolbar()) : () => this.displayPgmtoDialog(this.parameterToolbar()))
    this.toolbarMain.setTooltip('btn7', (this.parametro === 'cr') ? 'Novo Recebimento' : 'Novo Pagamento')


    this.changeOpt()
  }

  validateApprovaStatus(): boolean {
    if (this.rowsSelected.length > 0) {
      let aux = this.rowsSelected.filter(line =>
        line.statusAprovacao !== null &&
        line.statusAprovacao !== undefined &&
        line.statusAprovacao.toUpperCase() !== 'RECUSADO' &&
        line.statusAprovacao.toUpperCase() !== 'TITULO CRIADO' &&
        line.statusAprovacao.toUpperCase() !== 'NÃO SOLICITADO' &&
        line.statusAprovacao.toUpperCase() !== 'AUTORIZAÇÃO RECUSADA'
      )
      if (aux.length === this.rowsSelected.length) return false
      else return true

    } else return false
  }

  rowSidebar(data: any): void {
    this.sidebarService.rowSelected(data)
    this.sidebarService.open(true)
    this.sidebarService.selectTab(0)
  }

  async getCompanyParameter(): Promise<void> {
    try {
      await this.httpService.get('/empresa?$select=usarAprovacao&$filter=id eq ' + this.dblinkedSessionService.empresa.id).toPromise().then(res => {
        res.value.forEach(item => {
          this.flowUses = item.usarAprovacao
        })
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar os paramêtros da empresa! Contate o suporte' })
    }

    if (this.redirectNewPayment || this.redirectNewReceivement) this.showSolicitarAprov = this.flowUses
  }

  excluirRecebimentoPagamentoEmLote() {
    if (confirm('Deseja realmente excluir este(s) título(s)?')) {
      let temBaixas: boolean = false
      let idToExcluir: Array<number> = new Array()
      this.rowsSelected.forEach(row => {
        if (row.status === 'BAIXADO' || row.status === 'BAIXA PARCIAL') temBaixas = true
        idToExcluir.push(row.id)
      })

      if (temBaixas && !confirm('A seleção contém baixas, isso irá excluí-las primeiro. Continuar?')) return

      const listaExcluir = {
        idsExcluir: idToExcluir
      }

      this.httpService.wait()
      this.httpService.post('/custom/financeiro/remover-pagto-recbto-lote', listaExcluir)
        .subscribe(
          result => {
            this.exibirMensagem({ error: result.error, mensagem: result.message })
            this.rowsSelected.forEach(row => {
              this.LogManagement.registerLog(
                LogModule.FINANCE,
                this.parametro === 'cr' ? LogScreen.RECEIPTS : LogScreen.PAYMENTS,
                '/custom/financeiro/remover-pagto-recbto-lote',
                LogAction.DELETE,
                row.id,
                row.historico,
                "O título " + row.historico + " foi excluído",
                idToExcluir)
              })

            this.atualizarVoltar()
          },
          error => {
            this.exibirMensagem({ error: true, mensagem: "Erro interno ao remover os títulos, contate o suporte: " + error.message })
          },
          () => this.httpService.done())
    }
  }

  editarLote() {
    this.linhasEdicao = [{ item: null, valor: null }]
    this.itensEditaveis = [
      { label: "Conta bancária", value: "banco" },
      { label: "Data previsão", value: "previsao" },
      { label: "Data de vencimento", value: "vencimento" },
      { label: "Observações", value: "historico" },
      { label: "Tipo de " + (this.parametro === "cr" ? "recebimento" : "pagamento"), value: "tipopag" },
      { label: "Tipo de baixa", value: "tipobaixa" }
    ]
    this.baixarTitulosLote = false
    this.modalEdicaoLote = true

  }

  salvarEdicaoLote() {
    const infos = {
      data: this.linhasEdicao,
      usuario: this.sessionService.loggedUser.id,
      liid: this.convertArr(this.rowsSelected, true)
    }

    this.httpService.wait()
    this.httpService.post('/custom/financeiro/edicao-lote', infos)
      .subscribe(
        result => {
          if (this.baixarTitulosLote) this.baixarLote()
          else {
            if (this.usaAsaas) this.rowsSelected.forEach(row => this.atualizarCobranca(row.id))

            this.rowsSelected.forEach(row => {
              this.LogManagement.registerLog(
                LogModule.FINANCE,
                this.parametro === 'cr' ? LogScreen.RECEIPTS : LogScreen.PAYMENTS,
                '/custom/financeiro/edicao-lote',
                LogAction.EDIT,
                row.id,
                row.historico,
                `O título ${row.historico} foi modificado`,
                infos
              )
            })

            this.atualizarVoltar()
          }
        },
        error => this.httpService.handleError(error, () => this.salvarEdicaoLote()),
        () => this.httpService.done())
  }

  selectContasBancarias(value: Array<EmpresaConta>) {
    this.selectContas = [];
    value.forEach(element => {

      this.selectContas.push({
        label: element.id + '-' + element.nome + ' - AG: ' + element.agencia + ' - CC: '
          + element.conta + '-' + element.dvConta + (!isNullOrUndefined(element.tipoConta.descricao) ? '-' + element.tipoConta.descricao : ''), value: element.id
      });
    });
  }

  baixarLote() {
    if (this.convertArr(this.rowsSelected, false, false).length === 0) return this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Você precisa selecionar pelo menos uma conta válida!' });

    let tipoBaixaId = null
    this.linhasEdicao.forEach(row => {
      if (row.item === 'tipobaixa') tipoBaixaId = row.valor
    })

    const listasEnviar = {
      id: (this.convertArr(this.rowsSelected, false, false)),
      usuario: this.sessionService.loggedUser.id,
      tipoBaixa: tipoBaixaId
    };

    this.httpService.wait();
    this.httpService.post('/custom/financeiro/baixar-lote-titulo', listasEnviar)
      .subscribe(result => {
        if (result.length) result = result[0];

        if (result.error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: '',
            detail: result.message,

          });
          this.atualizarVoltar();
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: '',
            detail: result.message,

          });
          this.atualizarVoltar();
        }
      },
        error => this.httpService.handleError(error, () => this.baixarLote()),
        () => this.httpService.done());
  }

  baixar() {

    if (isNullOrUndefined(this.valorBaixa)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: '',
        detail: 'Valor da baixa deverá ser informado!',

      });
      return;
    }

    if (this.tipoBaixa.id === null || this.tipoBaixa.id === undefined) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: '',
        detail: 'Selecione tipo baixa para prosseguir!',

      });
      return;
    }

    // if (this.somaCompensado > this.valorSaldoBaixar) {
    //   this.messageService.add({
    //     severity: 'warn', key: 'messageLinnks',
    //     summary: '',
    //     detail: 'O Valor compensado não pode ser maior que o valor do título!',

    //   });

    //   return;
    // }

    if (this.valorTotalBaixa < 0) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: '',
        detail: 'O valor Total da Baixa não pode ser menor que zero, por favor verifique!',

      });

      return;
    }


    if (this.centroCustoObrigatorio &&
      ((this.utilService.parseMoney(this.valorMultaBaixa) > 0.00)
        || (this.utilService.parseMoney(this.valorJurosBaixa) > 0.00)
        || (this.utilService.parseMoney(this.valorDescontoBaixa) > 0.00))
    ) {
      if (this.movimentacaoRateios.length < 1) {

        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: '',
          detail: 'Para prosseguir, Insira um Centro de Custo!',

        });
        return;
      }
    }


    if (this.centroCustoObrigatorio &&
      (this.utilService.parseMoney(this.valorTotalBaixa) > 0.00) &&
      (this.tipoBaixa.id === 8)
    ) {
      if (this.movimentacaoRateios.length < 1) {
        this.messageService.add({
          severity: 'warn',
          key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Para prosseguir, Insira um Centro de Custo!'
        });
        return;
      }
    }


    this.adtosListaFinal.forEach(c => {
      c.vl_compensado = this.utilService.parseDecimal(c.vl_compensado);
    });

    const listasEnviar = {
      nfeFinId: this.nfeFinId,
      valorBaixa: this.utilService.parseDecimal4(this.valorRecebidoBaixa - this.valorCompensadoBaixa - this.valorTaxaAdm),
      valorMulta: this.utilService.parseDecimal4(this.valorMultaBaixa),
      valorJuros: this.utilService.parseDecimal4(this.valorJurosBaixa),
      valorDesconto: this.utilService.parseDecimal4(this.valorDescontoBaixa),
      dataBaixa: this.dataVencimento,
      dataNovoVencimento: this.dataNovoVencimento,
      tipoBaixa: this.tipoBaixa.id,
      empresaConta: this.contaSelecionadaId,
      usuario: this.sessionService.loggedUser.id,
      valorCompensado: this.utilService.parseDecimal4(this.somaCompensado),
      rateio: this.movimentacaoRateios,
      rateioJurosMulta: this.rateioJurosMulta,
      titulosQueForamCompensados: this.adtosListaFinal,
      moedaSelecionada: this.moedaSelecionadaId,
      valorTaxa: this.utilService.parseDecimal4(this.valorTaxa),
      valorTaxaAdm: this.utilService.parseDecimal4(this.valorTaxaAdm),
      todocardAprovacaoId: this.todoCardAprovacaoId
    };


    this.httpService.wait();
    this.httpService.post('/custom/financeiro/baixar-titulo', listasEnviar)
      .subscribe(result => {


        if (result[0].error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks', summary: '', detail: result[0].message
          });

        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: '',
            detail: result[0].message,

          });
          this.atualizarVoltar();
        }
      },
        error => this.httpService.handleError(error, () => this.baixar()),
        () => this.httpService.done());

  }

  alterarBaixa() {
    if (confirm('Deseja alterar esta baixa?')) {


      if (this.tipoBaixa.id === null || this.tipoBaixa.id === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: '',
          detail: 'Selecione tipo baixa para prosseguir!',

        });

        return;
      }

      if (this.valorTotalBaixa < 0) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: '',
          detail: 'O valor Total da Baixa não pode ser menor que zero, por favor verifique!',

        });


        return;
      }

      this.adtosListaFinal.forEach(c => {
        c.vl_compensado = this.utilService.parseDecimal(c.vl_compensado);
      });

      const listasEnviar = {
        nfeFinBaixaId: this.nfbId,
        valorBaixa: this.utilService.parseMoney(this.valorRecebidoBaixa - this.valorCompensadoBaixa - this.valorTaxaAdm),
        valorMulta: this.utilService.parseMoney(this.valorMultaBaixa),
        valorJuros: this.utilService.parseMoney(this.valorJurosBaixa),
        valorDesconto: this.utilService.parseMoney(this.valorDescontoBaixa),
        dataBaixa: this.dataVencimento,
        dataNovoVencimento: this.dataNovoVencimento,
        tipoBaixa: this.tipoBaixa.id,
        empresaConta: this.contaSelecionadaId,
        usuario: this.sessionService.loggedUser.id,
        valorCompensado: this.utilService.parseMoney(this.valorCompensadoBaixa),
        rateio: this.movimentacaoRateios,
        rateioJurosMulta: this.rateioJurosMulta,
        titulosQueForamCompensados: this.adtosListaFinal
      };

      this.httpService.wait();
      this.httpService.post('/custom/financeiro/alterar-baixa', listasEnviar)
        .subscribe(result => {

          if (result.error === true) {

            this.messageService.add({
              severity: 'error', key: 'messageLinnks',
              summary: 'Mensagem',
              detail: result.message,

            });

            this.atualizarVoltar();
          } else {

            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'Mensagem',
              detail: result.message,

            });

            this.atualizarVoltar();
          }
        },
          error => this.httpService.handleError(error, () => this.alterarBaixa()),
          () => this.httpService.done());

    }
  }

  alterarHistorico() {
    const listasEnviar = {
      id: this.nfhId,
      historico: this.historico,
      dataHistorico: this.dataHistorico,
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/financeiro/alterar-historico', listasEnviar)
      .subscribe(result => {
        if (result.error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'Mensagem',
            detail: result.message,

          });
          this.onRowSelect(this.infosTelaDeBaixa);
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'Mensagem',
            detail: result.message,

          });

          this.onRowSelect(this.infosTelaDeBaixa);
        }
      },
        error => this.httpService.handleError(error, () => this.alterarHistorico()),
        () => this.httpService.done());

  }

  excluirBaixa(nfbId) {

    if (confirm('Deseja excluir esta baixa?')) {

      if (nfbId === 0) {
        nfbId = this.nfbId;
      }

      let isAlterar = false;

      this.httpService.wait();
      this.httpService.post('/custom/financeiro/deletar-baixa(' + nfbId + ',' + isAlterar + ')')
        .subscribe(result => {
          if (result[0].error === true) {
            this.messageService.add({
              severity: 'error', key: 'messageLinnks',
              summary: 'Erro:',
              detail: result[0].message,
            });
          } else {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'Sucesso:',
              detail: "Baixa excluída com sucesso!",
            });

            this.onRowSelect(this.eventValue);
            //this.atualizarVoltar();
          }
        },
          error => this.httpService.handleError(error, () => this.alterarHistorico()),
          () => this.httpService.done());

    }
  }

  excluirHistorico(nfhId) {
    if (confirm('Deseja excluir este historico?')) {

      const listasEnviar = {
        nfhId: nfhId
      };

      this.httpService.wait();
      this.httpService.post('/custom/financeiro/excluir-historico-cobranca', listasEnviar)
        .subscribe(result => {

          if (result.error === true) {

            this.messageService.add({
              severity: 'error', key: 'messageLinnks',
              summary: 'Mensagem',
              detail: result.message,

            });
            this.onRowSelect(this.infosTelaDeBaixa);
          } else {

            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'Mensagem',
              detail: result.message,

            });
            this.onRowSelect(this.infosTelaDeBaixa);
          }
        },
          error => this.httpService.handleError(error, () => this.excluirHistorico(nfhId)),
          () => this.httpService.done());

    }
  }

  gravarHistorico() {
    const listasEnviar = {
      id: this.nfeFinId,
      historico: this.historico,
      dataHistorico: this.dataHistorico,
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/financeiro/gravar-historico', listasEnviar)
      .subscribe(result => {

        if (result.error === true) {

          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'Mensagem',
            detail: result.message,

          });

          this.onRowSelect(this.infosTelaDeBaixa);
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'Mensagem',
            detail: result.message,

          });

          this.onRowSelect(this.infosTelaDeBaixa);
        }
      },
        error => this.httpService.handleError(error, () => this.gravarHistorico()),
        () => this.httpService.done());
  }

  criarHistorico() {
    this.criarHistoricoCobranca = true;
    this.criarBaixaFinanceira = false;
    this.editarHistorico = false;
    this.editarBaixa = false;
    this.historico = null;
  }

  criarBaixa() {
    this.criarHistoricoCobranca = false;
    this.criarBaixaFinanceira = true;
    this.editarHistorico = false;
    this.editarBaixa = false;
  }

  cancelar() {
    this.changePeriod(true, false);
    this.modalEfetuarBaixa = false;
  }

  tabChange(event: any) {
    this.tab = event.index;
    this.rowsSelected = new Array()
    this.loadNovaTransacao();
  }


  displayRecebimentoDialog(operacaoTipoId: number = null) {
    this.parcelaId = null
    this.operacaotipoid = operacaoTipoId;
    this.cliente = new ParticipanteEmpresa();
    this.operacaoRegra = new OperacaoRegraContabil;
    this.valorOperacao = 0.00;
    this.valorReais = 0.00
    this.historicoOperacao = '';
    this.linkFinanceiro = '';
    this.refFinanceiro = '';
    this.contaSelecionadaId = '';
    this.dtOperacao = this.defaultDate;
    this.recorrencia = '';
    this.ocorrenciaMes = 1;
    this.antecipaDiaNaoUtil = '';
    this.dtFimOccorencia = this.defaultDate;
    this.centroCusto = new CadCcus;
    this.cadProj = new CadProj;
    this.modalCentroCusto = false;
    this.porcentagemCc = 0;
    this.cc = [];
    this.proj = [];
    this.rateioCentroCusto = 100;
    this.rateioCadProj = 100;
    this.movimentacaoRateios = new Array();
    this.operacaoRegra.novoRegistro = true;
    this.operacaoRegra.podeEditar = true;
    this.dataEmissao = '';
    this.dataCompetencia = '';
    this.isEstorno = false;
    this.isFromPedido = false
    this.participanteSelecionado = {}
    this.participanteCodigo = null
    this.participanteFantasia = null
    this.showSolicitarAprov = this.flowUses;
    this.tipoSelecionadoId = null
    this.observacao = null
    this.tipoBaixaId = null
    this.tipoMulta = null
    this.valorMulta = null
    this.tipoJuros = null
    this.valorJuros = null
    this.tipoDesconto = null
    this.valorDesconto = null
    this.diasDesconto = null
    this.usarMultaJuros = false
    this.httpService.wait();
    this.httpService.get('/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 1 and tipoRegraContabil/id eq 3'
      + ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo eq true&$orderby=descricao asc')
      .subscribe(ref => this.loadOperacao(ref.value),
        error => this.httpService.handleError(error, () => this.atualizarVoltar()),
        () => this.httpService.done());
    let participanteTipoId;
    switch (operacaoTipoId) {
      case 1:
        participanteTipoId = 1;
        break;
      case 2:
        participanteTipoId = 2;
        break;
    }

    this.tituloNewEdit = false;
    this.modalOperacao = true;
    this.editarPagtoOuReceb = false;
    this.contaSelecionadaId = this.empresaContaRecebimentoId ? this.empresaContaRecebimentoId : null
  }

  loadParametroFinanceiro() {
    this.httpService.get('/empresaparametrofinanceiro?$select=visualizaPedido,visualizaProvisao,participanteObrigatorio,confTaxa/id,' +
      'permiteAlterarMoeda,permiteMultiMoeda,empresaContaPagamento/id,empresaContaRecebimento/id,recebimentoSomenteContaPadrao,' +
      'pagamentoSomenteContaPadrao&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
      .subscribe(ret => {
        let result = ret.value[0]
        this.finProv = result.visualizaProvisao
        this.finPed = result.visualizaPedido
        this.participanteObrigatorio = result.participanteObrigatorio
        this.confTaxaId = result.confTaxa ? result.confTaxa.id : 0
        this.permiteAlterarMoeda = result.permiteAlterarMoeda
        this.permiteMultiMoeda = result.permiteMultiMoeda
        this.empresaContaPagamentoId = result.empresaContaPagamento ? result.empresaContaPagamento.id : null
        this.empresaContaRecebimentoId = result.empresaContaRecebimento ? result.empresaContaRecebimento.id : null
        this.recebimentoSomenteContaPadrao = result.recebimentoSomenteContaPadrao
        this.pagamentoSomenteContaPadrao = result.pagamentoSomenteContaPadrao
      },
        error => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao buscar os parametros financeiros da empresa, contate o suporte: " + error.message })
        }
      )
  }

  comboContas() {
    this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,instituicaoFinanceira,tipoConta/id,tipoConta/descricao,ativo'
      + '&$filter=empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id) + ' and ativo eq true&$orderby=nome')
      .subscribe(ref => {
        this.selectContas = [{ label: "Nenhum", value: null }]
        ref.value.forEach(element => {
          this.selectContas.push({
            label: element.id + ' - ' + element.nome +
              (element.agencia ? ' - AG: ' + element.agencia : '') +
              (element.conta ? ' - CC: ' + element.conta : '') +
              (element.dvConta ? '-' + element.dvConta : '') +
              (element.tipoConta ? ' - ' + element.tipoConta.descricao : ''),
            value: element.id
          })
        })
      },
        error => {
          this.exibirMensagem({ error: true, mensagem: "Não foi possível recuperar as contas bancárias, contate o suporte: " + error.message })
        }
      )
  }

  editarRegistro(operacaoTipoId: number, event: any) {
    this.modalOperacao = false;
    this.modalOperacaoPagto = false;
    this.existeContaResultado = false;

    if (this.rowsSelected.length > 1) {
      this.editarLote();
      return;
    }

    try {
      if (this.rowsSelected[0].idPodeEditar) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Esse registro não pode ser alterado!' });
        return;
      }

      if (!event.isPodeEditar) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Esse registro não pode ser alterado, está baixado ou confirmado!' });
        return;
      }
    }
    catch (err) {
      if (!this.recebimentos) {
        this.recebimentos.forEach(e => {
          if (this.rowsSelected[0].id === e.id) {
            if (!e.isPodeEditar) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Esse registro não pode ser alterado!' });
              return;
            }
          }
        });
      }
      if (!this.recebimentos) {
        this.vencidos.forEach(e => {
          if (this.rowsSelected[0].id === e.id) {
            if (!e.isPodeEditar) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Esse registro não pode ser alterado!' });
              return;
            }
          }
        });
      }
    }

    this.tituloNewEdit = true;
    this.tituloPedido = event.pedido;
    this.statusTitulo = event.status;
    this.showSolicitarAprov = this.flowUses && [undefined, null, 'RECUSADO', 'TITULO CRIADO', 'NÃO SOLICITADO'].includes(event.statusAprovacao?.toUpperCase());

    if (!isNullOrUndefined(operacaoTipoId) && operacaoTipoId === 2) {
      this.editarPagamento(2, event);
    } else {
      this.editarRecebimento(1, event);

    }

  }

  editarPagamento(operacaoTipoId: number, event: any) {

    this.operacaotipoid = operacaoTipoId;
    this.cliente = new ParticipanteEmpresa();
    this.operacaoRegra = new OperacaoRegraContabil;
    this.valorOperacao = 0.00;
    this.valorReais = 0.00;
    this.tipoSelecionadoId = "";
    this.cnabFormaSelecionadaId = "";
    this.nDocumento = "";
    this.codBarras = "";
    this.codReceita = "";
    this.dtApuracao = "";
    this.idRecolhimento = "";
    this.digitoIdRecolhimento = "";
    this.participanteContasId = "";
    this.codRecolhimento = "";
    this.codPagamentoGps = "";
    this.historicoOperacao = '';
    this.linkFinanceiro = '';
    this.refFinanceiro = '';
    this.contaSelecionadaId = '';
    this.dtOperacao = this.defaultDate;
    this.recorrencia = '';
    this.ocorrenciaMes = 1;
    this.antecipaDiaNaoUtil = '';
    this.dtFimOccorencia = this.defaultDate;
    this.observacao = null
    this.dtEmissao = new Date();
    this.dtCompetencia = new Date();

    this.tituloPedido = event.pedido;
    this.statusTitulo = event.status;

    this.centroCusto = new CadCcus;
    this.cadProj = new CadProj;
    this.modalCentroCusto = false;
    this.porcentagemCc = 0;
    this.cc = [];
    this.proj = [];
    this.rateioCentroCusto = 100;
    this.rateioCadProj = 100;
    this.movimentacaoRateios = new Array();
    this.operacaoRegra.novoRegistro = false;
    this.modalOperacaoPagto = true;

    this.loadEditarRegistroPagamento(this.rowsSelected[0].id, event);


    try {
      this.dataEmissao = '';
      this.dataEmissao = event.dataEmissao;
    } catch (error) {

    }

    try {
      this.dataCompetencia = '';
      this.dataCompetencia = event.dataCompetencia;
    } catch (error) {

    }

  }

  loadEditarRegistroRecebimento(movFinId: number, event: any) {

    if (!isNullOrUndefined(event.nfeId)) {
      this.tituloNfe = true;
    } else {
      this.tituloNfe = false;
    }

    this.idMovFin = movFinId;
    this.httpService.wait();
    this.httpService.get('/custom/financeiro/get-Informacoes-pagamento-por-id('
      + movFinId + ',' + this.tituloNfe + ')')
      .subscribe(ret => {

        this.parcelaId = ret.parcela_id
        this.tipoBaixa.id = ret.tipoBaixaId

        this.isFromPedido = false
        if (ret.codigoPed) {
          this.isFromPedido = true
        }

        this.httpService.wait();
        let url = "";
        if (this.isFromPedido || this.tituloPedido) {
          url = `/operacaoregra?$select=id,descricao&$filter=tipoEntradaSaida/id eq 2 and ativo eq true`
        } else {
          url = "/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 1 and tipoRegraContabil/id eq 3 and ativo eq true"
        }
        this.httpService.get(url + ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=descricao asc')
          .subscribe(ref => this.loadOperacao(ref.value),
            error => this.httpService.handleError(error, () => this.atualizarVoltar()),
            () => this.httpService.done());


        this.todoCardId = event.todo_card_id
        this.operacaoRegra.id = (ret.operacaoRegraId ?? ret.operacaoRegraContabilId)
        this.historicoOperacao = ret.descricao;
        this.linkFinanceiro = ret.linkFinanceiro;
        this.refFinanceiro = ret.refFinanceiro;
        this.refIsAlreadyDefined = ret.refFinanceiro ? true : false
        this.valorOperacao = this.utilService.parseMoney(ret.valorOperacao);
        this.valorTaxa = !isNullOrUndefined(ret.txMoeda) ? this.utilService.parseDecimal4(ret.txMoeda) : 0;
        this.moedaSelecionadaId = !isNullOrUndefined(ret.moeda) ? ret.moeda : 1;
        this.contaSelecionadaId = ret.empresaContaId;
        this.provisao = ret.provisao;
        this.observacao = ret.observacao
        this.tipoSelecionadoId = ret.tipoPagamentoId
        this.extrairTipoBaixa()
        this.tipoMulta = ret.tipoMulta
        this.valorMulta = ret.valorMulta
        this.tipoJuros = ret.tipoJuros
        this.valorJuros = ret.valorJuros
        this.tipoDesconto = ret.tipoDesconto
        this.valorDesconto = ret.valorDesconto
        this.diasDesconto = ret.diasDesconto
        this.usarMultaJuros = ret.usarMultaJuros

        if (this.valorTaxa > 0) {
          this.convertValorMoeda();
        }

        // this.loadParticipantes(1, false, false);
        this.dtOperacao = ret.dtVencimento;


        if (ret.recorrenciaId > 1) {
          this.recorrencia = true;
        } else {
          this.recorrencia = false;
        }

        if (ret.antecipaDiaNaoUtil === true) {
          this.antecipaDiaNaoUtil = true;
        } else {
          this.antecipaDiaNaoUtil = false;
        }
        this.dtFimOccorencia = ret.dtFinalRateio;
        this.ocorrenciaMes = ret.ocorrenciaRepete;
        this.cliente.codigo = ret.participanteId;
        this.participanteCodigo = ret.codPart;
        this.participanteFantasia = ret.fantasia;

        let isBaixa = false;
        this.loadRateio(ret.movFinId, isBaixa);

        this.contaSelecionadaId = ret.empresaContaId;

        if (!this.tituloPedido) {
          this.existeContaResultadoParaOperacaoRegra(this.operacaoRegra.id);
        }



        this.alterarRecorrencia = false;
        this.editarPagtoOuReceb = true;

        this.recebimentoEdit = {
          movFinId: this.idMovFin,
          empresa: this.dblinkedSessionService.empresa.id,
          operacaoRegra: this.operacaoRegra.id,
          cliente: this.cliente.codigo,
          valorOperacao: this.utilService.parseMoney(this.valorOperacao),
          historicoOperacao: this.historicoOperacao,
          linkFinanceiro: this.linkFinanceiro,
          refFinanceiro: this.refFinanceiro,
          dtOperacao: this.dtOperacao,
          recorrencia: this.recorrencia,
          ocorrenciaMes: this.ocorrenciaMes,
          antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
          dtFimOccorencia: this.dtFimOccorencia,
          usuario: this.sessionService.loggedUser.id,
          empresaConta: this.contaSelecionadaId,
          rateio: [...this.movimentacaoRateios],
          novoRegistro: this.operacaoRegra.novoRegistro,
          alterarRecorrencia: this.alterarRecorrencia,
          provisao: this.provisao,
          tituloNfe: this.tituloNfe,
          dataEmissao: this.dataEmissao,
          dataCompetencia: this.dataCompetencia,
          moedaSelecionada: this.moedaSelecionadaId,
          valorTaxa: this.utilService.parseDecimal4(this.valorTaxa),
          tipo: this.parametro,
          isEstorno: this.isEstorno,
          tipoPagamentoId: this.tipoSelecionadoId,
          observacao: this.observacao,
          tipoMulta: this.tipoMulta,
          tipoJuros: this.tipoJuros,
          tipoDesconto: this.tipoDesconto,
          valorMulta: this.valorMulta,
          valorJuros: this.valorJuros,
          valorDesconto: this.valorDesconto,
          diasDesconto: ~~this.diasDesconto
        };

      },
        error => this.httpService.handleError(error, () => this.loadContaPadraoPgto()),
        () => this.httpService.done());
  }

  loadEditarRegistroPagamento(movFinId: number, event: any) {

    if (!isNullOrUndefined(event.nfeId)) {
      this.tituloNfe = true;
    } else {
      this.tituloNfe = false;
    }

    this.idMovFin = movFinId;
    this.httpService.wait();
    this.httpService.get('/custom/financeiro/get-Informacoes-pagamento-por-id('
      + movFinId + ',' + this.tituloNfe + ')')
      .subscribe(ret => {
        this.isFromPedido = false
        if (ret.codigoPed) {
          this.isFromPedido = true
        }

        let url = "";
        if (this.isFromPedido) {
          url = `/operacaoregra?$select=id,descricao&$filter=tipoEntradaSaida/id eq 1 and ativo eq true`
        } else {
          url = "/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 2 and tipoRegraContabil/id eq 3 and ativo eq true "
        }
        this.httpService.get(url + ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=descricao asc')
          .subscribe(ref => this.loadOperacao(ref.value),
            error => this.httpService.handleError(error, () => this.atualizarVoltar()),
            () => this.httpService.done());

        this.todoCardId = event.todo_card_id
        this.operacaoRegra.id = (ret.operacaoRegraId ?? ret.operacaoRegraContabilId);
        this.historicoOperacao = ret.descricao;
        this.linkFinanceiro = ret.linkFinanceiro;
        this.refFinanceiro = ret.refFinanceiro;
        this.refIsAlreadyDefined = ret.refFinanceiro ? true : false
        this.provisao = ret.provisao;
        this.valorTaxa = !isNullOrUndefined(ret.txMoeda) ? this.utilService.parseDecimal4(ret.txMoeda) : 0;
        this.moedaSelecionadaId = !isNullOrUndefined(ret.moeda) ? ret.moeda : 1;
        this.observacao = ret.observacao

        this.tipoSelecionadoId = ret.tipoPagamentoId;
        this.cnabFormaSelecionadaId = ret.cnabFormaId;
        this.nDocumento = ret.nDocumento;
        this.codBarras = ret.codBarras;
        this.codReceita = ret.codReceita;
        this.dtApuracao = ret.dtApuracao;
        this.idRecolhimento = ret.idRecolhimento;
        this.digitoIdRecolhimento = ret.digitoIdRecolhimento;
        this.codRecolhimento = ret.codRecolhimento;
        this.codPagamentoGps = ret.codPagamentoGps;
        this.participanteContasId = ret.participanteContasId;
        this.participanteId = ret.participanteId;
        this.participanteCodigo = ret.codPart;
        this.participanteFantasia = ret.fantasia;
        if (this.participanteId != null) {
          this.loadParticipanteContas(this.participanteId)
          this.getChavesPixParticipante(this.participanteId)
        }
        //fim @octavio

        this.valorOperacao = this.utilService.parseMoney(ret.valorOperacao);

        // this.loadParticipantes(2, false, false);
        this.dtOperacao = ret.dtVencimento;

        this.dtEmissao = ret.dtEmissao;
        this.dtCompetencia = ret.dtCompetencia;

        if (ret.recorrenciaId > 1) {
          this.recorrencia = true;
        } else {
          this.recorrencia = false;
        }

        if (ret.antecipaDiaNaoUtil === true) {
          this.antecipaDiaNaoUtil = true;
        } else {
          this.antecipaDiaNaoUtil = false;
        }

        this.dtFimOccorencia = ret.dtFinalRateio;
        this.ocorrenciaMes = ret.ocorrenciaRepete;
        this.cliente.codigo = ret.participanteId;
        this.chavePixSelecionada = ret.participanteChavePixId

        let isBaixa = false;
        this.loadRateio(ret.movFinId, isBaixa);
        this.contaSelecionadaId = ret.empresaContaId;

        if (!this.tituloPedido) {
          this.existeContaResultadoParaOperacaoRegra(this.operacaoRegra.id);
        }


        this.alterarRecorrencia = false;
        this.editarPagtoOuReceb = true;

        if (this.valorTaxa > 0) {
          this.convertValorMoeda();
        }

        this.pagamentoEdit = {
          movFinId: this.idMovFin,
          empresa: this.dblinkedSessionService.empresa.id,
          operacaoRegra: this.operacaoRegra.id,
          cliente: this.cliente.codigo,
          valorOperacao: this.utilService.parseMoney(this.valorOperacao),
          tituloNfe: this.tituloNfe,
          historicoOperacao: this.historicoOperacao,
          linkFinanceiro: this.linkFinanceiro,
          refFinanceiro: this.refFinanceiro,
          dtOperacao: this.dtOperacao,
          recorrencia: this.recorrencia,
          ocorrenciaMes: this.ocorrenciaMes,
          antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
          dtFimOccorencia: this.dtFimOccorencia,
          usuario: this.sessionService.loggedUser.id,
          empresaConta: this.contaSelecionadaId,
          // Salvar RateioCentroCusto
          rateio: [...this.movimentacaoRateios],
          novoRegistro: this.operacaoRegra.novoRegistro,
          // para pagar por CNAB @octavio
          tipoPagamentoId: this.tipoSelecionadoId,
          nDocumento: this.nDocumento,
          codBarras: this.codBarras,
          codReceita: this.codReceita,
          dtApuracao: this.dtApuracao,
          idRecolhimento: this.idRecolhimento,
          digitoIdRecolhimento: this.digitoIdRecolhimento,
          participanteContasId: this.participanteContasId,
          codRecolhimento: this.codRecolhimento,
          codPagamentoGps: this.codPagamentoGps,
          // fim @octavio
          alterarRecorrencia: this.alterarRecorrencia,
          provisao: this.provisao,

          dtEmissao: this.dtEmissao,
          dtCompetencia: this.dtCompetencia,

          moedaSelecionada: this.moedaSelecionadaId,
          valorTaxa: this.utilService.parseDecimal4(this.valorTaxa),
          tipo: this.parametro,
          isEstorno: this.isEstorno
        };
      },
        error => this.httpService.handleError(error, () => this.loadContaPadraoPgto()),
        () => this.httpService.done());
  }

  editarRecebimento(operacaoTipoId: number, event: any) {

    this.operacaotipoid = operacaoTipoId;
    this.cliente = new ParticipanteEmpresa();
    this.operacaoRegra = new OperacaoRegraContabil;
    this.valorOperacao = 0.00;
    this.historicoOperacao = '';
    this.linkFinanceiro = '';
    this.refFinanceiro = '';
    this.contaSelecionadaId = '';
    this.dtOperacao = this.defaultDate;
    this.recorrencia = '';
    this.ocorrenciaMes = 1;
    this.antecipaDiaNaoUtil = '';
    this.dtFimOccorencia = this.defaultDate;
    this.centroCusto = new CadCcus;
    this.cadProj = new CadProj;
    this.modalCentroCusto = false;
    this.porcentagemCc = 0;
    this.cc = [];
    this.proj = [];
    this.rateioCentroCusto = 100;
    this.rateioCadProj = 100;
    this.movimentacaoRateios = new Array();
    this.operacaoRegra.novoRegistro = false;
    this.operacaoRegra.podeEditar = true;
    this.operacaoRegra.novoRegistro = false;
    this.observacao = null

    this.tituloPedido = event.pedido;
    this.statusTitulo = event.status;
    this.tipoSelecionadoId = null

    this.modalOperacao = true;
    this.loadEditarRegistroRecebimento(this.rowsSelected[0].id, event);
  }

  loadRateio(rateioId: number, isBaixa: boolean) {
    this.movimentacaoRateios = new Array();

    if (isNullOrUndefined(rateioId)) {
      return;
    }

    this.httpService.wait();
    this.httpService.get('/custom/financeiro/get-rateios('
      + rateioId + ',' + isBaixa + ')')
      .subscribe(ret => {

        ret.forEach(element => {

          this.proj = [];
          this.financeiroRateio = new FinanceiroRateio();

          this.cadProj = new CadProj();
          this.cadProj.id = element.cadProjId;
          this.cadProj.codProj = element.codProj;
          this.cadProj.descrProj = element.descrProj;

          this.centroCusto = new CadCcus;
          this.centroCusto.id = element.cadCcusId;
          this.centroCusto.codCcus = element.codCcus;
          this.centroCusto.descrCcus = element.descrCcus;


          if (isNullOrUndefined(this.contadorRateio)) {
            this.contadorRateio = 0;
          }

          this.rateioCentroCusto = this.rateioCentroCusto - this.utilService.parseDecimal(element.porcentagem);

          this.financeiroRateio.id = this.contadorRateio;
          this.financeiroRateio.cadProj = this.cadProj;

          this.financeiroRateio.cadCcus = this.centroCusto;
          this.financeiroRateio.porcentagem = element.porcentagem;

          this.movimentacaoRateios.push(this.financeiroRateio);

          this.contadorRateio++;
          this.proj = [];
          this.porcentagemCc = 0;

        }

        )

      },
        error => this.httpService.handleError(error, () => this.loadContaPadraoPgto()),
        () => this.httpService.done());


  }

  displayPgmtoDialog(operacaoTipoId: number = null) {
    this.operacaotipoid = operacaoTipoId;
    this.cliente = new ParticipanteEmpresa();
    this.operacaoRegra = new OperacaoRegraContabil;
    this.valorOperacao = 0.00;
    this.valorReais = 0.00
    this.moedaSelecionadaId = 1;
    this.isFromPedido = false

    // para pagar por CNAB @octavio
    this.tipoSelecionadoId = "";
    this.cnabFormaSelecionadaId = "";
    this.nDocumento = "";
    this.codBarras = "";
    this.codReceita = "";
    this.dtApuracao = "";
    this.idRecolhimento = "";
    this.digitoIdRecolhimento = "";
    this.participanteContasId = "";
    this.codRecolhimento = "";
    this.codPagamentoGps = "";

    // fim @octavio

    this.historicoOperacao = '';
    this.linkFinanceiro = '';
    this.refFinanceiro = '';
    this.contaSelecionadaId = '';
    this.dtOperacao = this.defaultDate;
    this.recorrencia = null;
    this.ocorrenciaMes = 1;
    this.antecipaDiaNaoUtil = '';
    this.dtFimOccorencia = this.defaultDate;
    // this.comboContas();
    // start centro de custo e projetos
    this.centroCusto = new CadCcus;
    this.cadProj = new CadProj;
    this.modalCentroCusto = false;
    this.porcentagemCc = 0;
    this.cc = [];
    this.proj = [];
    this.rateioCentroCusto = 100;
    this.rateioCadProj = 100;
    this.movimentacaoRateios = new Array();
    this.dataCompetencia = '';
    this.dataEmissao = '';
    this.operacaoRegra.novoRegistro = true;
    this.isEstorno = false;
    this.participanteSelecionado = {}
    this.participanteCodigo = null
    this.participanteFantasia = null
    this.showSolicitarAprov = this.flowUses;
    this.observacao = null

    this.httpService.get('/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 2 and tipoRegraContabil/id eq 3 and ativo eq true '
      + ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=descricao asc')
      .subscribe(ref => this.loadOperacao(ref.value),
        error => this.httpService.handleError(error, () => this.atualizarVoltar()),
        () => this.httpService.done());
    let participanteTipoId;
    switch (operacaoTipoId) {
      case 1:
        participanteTipoId = 1;
        break;
      case 2:
        participanteTipoId = 2;
        break;
    }
    // this.loadParticipantes(participanteTipoId, false, false);
    // this.loadContaPadraoPgto();
    this.tituloNewEdit = false;
    this.modalOperacaoPagto = true;
    this.editarPagtoOuReceb = false;
    this.contaSelecionadaId = this.empresaContaPagamentoId ? this.empresaContaPagamentoId : null
  }

  validacao() {

    if ((isNullOrUndefined(this.operacaoRegra.id) || this.operacaoRegra.id === 0) && !this.tituloNfe) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar uma operação!',

      });

      return true;
    }


    if (isNullOrUndefined(this.dtOperacao)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar uma data de Operação!',

      });

      return true;
    }


    if (isNullOrUndefined(this.contaSelecionadaId) || this.contaSelecionadaId === 0) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar uma conta!',

      });
      return true;
    }

    if (this.moedaSelecionadaId > 1 && isNullOrUndefined(this.valorTaxa)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor inserir uma taxa para a moeda!'
      });

      return true;
    }

    if (isNullOrUndefined(this.valorOperacao) || this.valorOperacao <= 0) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor digitar o valor da operação!',

      });

      return true;
    }

    if ((isNullOrUndefined(this.historicoOperacao) || this.historicoOperacao === '') && !this.tituloNfe) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor digitar o histórico da operação!',

      });


      return true;
    }

    // validação para CNAB @octavio
    if ([1, 5].includes(this.cnabFormaSelecionadaId) && (this.cliente.codigo == '' || isNullOrUndefined(this.cliente.codigo))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar um fornecedor.',

      });

      return true;
    }

    if ([5].includes(this.cnabFormaSelecionadaId) && (this.participanteContasId == '' || isNullOrUndefined(this.participanteContasId))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor selecionar a conta de um fornecedor.',

      });

      return true;
    }

    if ([1, 3, 6].includes(this.cnabFormaSelecionadaId) && (this.codBarras === '' || isNullOrUndefined(this.codBarras))) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor inserir um código de barras.',

      });


      return true;
    }

    if ([1, 3, 6].includes(this.cnabFormaSelecionadaId) && (this.codBarras === '' || isNullOrUndefined(this.codBarras))) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor inserir um código de barras.',

      });

      return true;
    }

    if ([2, 3, 4, 6].includes(this.cnabFormaSelecionadaId) && (this.dtApuracao === '' || isNullOrUndefined(this.dtApuracao))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor inserir a data de apuração.',

      });

      return true;
    }

    if ([2].includes(this.cnabFormaSelecionadaId) && (this.codReceita === '' || isNullOrUndefined(this.codReceita))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor inserir o código da receita.',

      });


      return true;
    }


    if (this.cnabFormaSelecionadaId == 4 && (this.codPagamentoGps === '' || isNullOrUndefined(this.codPagamentoGps))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor inserir o código de pagamento.',

      });

      return true;
    }

    if (this.cnabFormaSelecionadaId == 3 && (this.codRecolhimento === '' || isNullOrUndefined(this.codRecolhimento))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor inserir o código de recolhimento.',

      });
      return true;
    }


    if (this.cnabFormaSelecionadaId == 3 && (this.idRecolhimento === '' || isNullOrUndefined(this.idRecolhimento))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor inserir o id de recolhimento.',

      });

      return true;
    }


    if (this.cnabFormaSelecionadaId == 3 && (this.digitoIdRecolhimento === '' || isNullOrUndefined(this.digitoIdRecolhimento))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Favor inserir o dígito do id de recolhimento.',

      });

      return true;
    }

    if (this.participanteObrigatorio && (this.cliente.codigo == '' || isNullOrUndefined(this.cliente.codigo))) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'É obrigatório informar um participante'
      });
      return true;

    }

    if (this.centroCustoObrigatorio && this.existeContaResultado && !this.tituloNfe) {
      if (this.movimentacaoRateios.length < 1) {

        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Para prosseguir, Insira um Centro de Custo!',

        });

        return true;
      } else {

        let porcRateio = 0;
        this.movimentacaoRateios.forEach(element => {
          porcRateio = porcRateio + this.utilService.parseDecimal(element.porcentagem);
        });
        porcRateio = Math.round(porcRateio * 100) / 100
        if (porcRateio != 100) {
          this.messageService.add({
            key: 'messageLinnks',
            severity: 'warn',
            summary: 'MSG',
            detail: 'A Porcentagem de Rateio deverá ser 100%!'
          });
          return true;
        }
      }
    }

    if (this.movimentacaoRateios.length > 0) {
      let porcRateio = 0;
      this.movimentacaoRateios.forEach(element => {
        porcRateio = porcRateio + this.utilService.parseDecimal(element.porcentagem);
      });
      porcRateio = Math.round(porcRateio * 100) / 100
      if (porcRateio != 100) {
        this.messageService.add({
          key: 'messageLinnks',
          severity: 'warn',
          summary: 'MSG',
          detail: 'A Porcentagem de Rateio deverá ser 100%!'
        });
        return true;
      }
    }

    if (this.usaAsaas && (this.tipoBaixaId === 1 || this.tipoBaixaId === 17)) {
      if (this.valorMulta && this.valorMulta < 0) {
        this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: '', detail: 'O valor da multa deve ser superior a 0' })
        return true
      }

      if (this.valorJuros && this.valorJuros < 0) {
        this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: '', detail: 'O valor dos juros deve ser superior a 0' })
        return true
      }

      if (this.valorDesconto && this.valorDesconto < 0) {
        this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: '', detail: 'O valor do desconto deve ser superior a 0' })
        return true
      }

      if (this.diasDesconto < 0) {
        this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: '', detail: 'A quantidade de dias de desconto deve ser superior a 0' })
        return true
      }
    }

    return false
  }

  loadContaPadraoPgto() {
    this.httpService.wait();
    this.httpService.get('/empresaparametrofinanceiro?$select=id,empresaContaPagamento/id&$filter=empresa/id eq '
      + this.dblinkedSessionService.empresa.id)
      .subscribe(ret => {
        this.contaSelecionadaId = ret.value[0].empresaContaPagamento.id;
        this.onChangeContaBancaria(ret, 'padrao');
      },
        error => this.httpService.handleError(error, () => this.loadContaPadraoPgto()),
        () => this.httpService.done());
  }

  public loadParticipantes(participanteTipoId: number = null, isEstorno, changeBoolean: boolean) {
    this.participanteTipoId = participanteTipoId
    if (isEstorno) this.participanteTipoId = this.participanteTipoId === 1 ? 2 : 1
    this.modalParticipante = true
  }

  loadOperacao(value: Array<OperacaoRegraContabil>) {
    this.opcoesOperacao = [];
    this.opcoesOperacao.push({ label: 'Selecione', value: 0 });
    value.forEach(element => {
      this.opcoesOperacao.push({ label: element.descricao, value: element.id });
    });
  }

  findValue(key: string, identification) {
    const opcoes = {
      moeda: this.opcoesMoeda,
      empresaConta: this.selectContas,
      operacaoRegra: this.opcoesOperacao,
      tipoPagamentoId: this.tiposLista
    };

    try {
      let content = null;

      if (Object.keys(opcoes).includes(key)) {
        opcoes[key].forEach((item) => {
          if (item.value == identification.id || item.value == identification) {
            content = item.label;
          }
        });

        return content;
      }

      return content;
    } catch (error) {
      return null;
    }
  }

  parseObject(object) {
    try {
      for (let [key, value] of Object.entries(object)) {
        const parsedValue = this.findValue(key, value);

        if (parsedValue) object[key] = parsedValue;
      }
    } catch (erro) {
      console.log(erro)
    }

    return object
  }

  // salvarRecebimento() {

  //   if (this.alterarRecorrencia) {
  //     if (confirm('Deseja alterar somente esta recorrência? (Apenas os campos de DATA e VALOR serão alterados)')) {
  //       this.dadosRecebimento();
  //     }
  //   } else {
  //     this.dadosRecebimento();
  //   }
  // }

  salvarRecebimento() {

    if (this.validacao()) return

    if (this.alterarRecorrencia && !confirm('Deseja alterar somente esta recorrência? (Apenas os campos de DATA e VALOR serão alterados)')) return

    const listasEnviar = {
      tipoBaixa: this.tipoBaixa.id,
      movFinId: this.idMovFin,
      empresa: this.dblinkedSessionService.empresa.id,
      operacaoRegra: this.operacaoRegra.id,
      cliente: this.cliente.codigo,
      valorOperacao: this.utilService.parseMoney(this.valorOperacao),
      historicoOperacao: this.historicoOperacao,
      linkFinanceiro: this.linkFinanceiro,
      refFinanceiro: this.refFinanceiro,
      dtOperacao: this.dtOperacao,
      recorrencia: this.recorrencia,
      ocorrenciaMes: this.ocorrenciaMes,
      antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
      dtFimOccorencia: this.dtFimOccorencia,
      usuario: this.sessionService.loggedUser.id,
      empresaConta: this.contaSelecionadaId,
      rateio: this.movimentacaoRateios,
      novoRegistro: this.operacaoRegra.novoRegistro,
      alterarRecorrencia: this.alterarRecorrencia,
      provisao: this.provisao,
      tituloNfe: this.tituloNfe,
      dataEmissao: this.dataEmissao,
      dataCompetencia: this.dataCompetencia,
      moedaSelecionada: this.moedaSelecionadaId,
      valorTaxa: this.utilService.parseDecimal4(this.valorTaxa),
      tipo: this.parametro,
      baixar: this.baixarTitulo,
      isEstorno: this.isEstorno,
      tipoPagamentoId: this.tipoSelecionadoId,
      observacao: this.observacao,
      tipoMulta: this.tipoMulta,
      tipoJuros: this.tipoJuros,
      tipoDesconto: this.tipoDesconto,
      valorMulta: this.valorMulta,
      valorJuros: this.valorJuros,
      valorDesconto: this.valorDesconto,
      diasDesconto: ~~this.diasDesconto,
      usarMultaJuros: this.usarMultaJuros,
      nDocumento: this.nDocumento
    }

    let recebimentoCopy = JSON.parse(JSON.stringify(listasEnviar))
    this.recebimentoEdit = this.parseObject(this.recebimentoEdit)
    recebimentoCopy = this.parseObject(recebimentoCopy)

    this.httpService.wait();
    this.httpService.post('/custom/financeiro/salvar-titulo', listasEnviar)
      .subscribe(result => {
        if (result[0].error === true) {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: result[0].message })
          this.httpService.done()
        } else {
          this.httpService.done()
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: result[0].message })
          this.participanteCodigo = ""

          this.LogManagement.registerLog(
            LogModule.FINANCE,
            LogScreen.RECEIPTS,
            '/custom/financeiro/salvar-titulo',
            this.operacaoRegra.novoRegistro ? LogAction.CREATE : LogAction.EDIT,
            result[0].nfeFinId,
            this.historicoOperacao,
            `O título ${this.historicoOperacao} foi ` + (this.operacaoRegra.novoRegistro ? "criado" : "modificado"),
            listasEnviar
          )

          const payload = {
            service: Service.APPROVAL_CENTER,
            action: Action.FINANCIAL,
            method: Method.PUT,
            content: {
              userId: this.sessionService.loggedUser.id,
              cardId: this.todoCardId,
              userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
              compareList: [
                { before: this.recebimentoEdit, after: recebimentoCopy },
              ]
            }
          }

          this.socketService.send(payload)

          if (this.usaAsaas && this.idMovFin) this.atualizarCobranca(this.idMovFin)

          if (this.flowUses && this.requestApproval) this.solicitarAprovacao(true, result[0].nfeFinId)
          else this.atualizarVoltar()
        }
      }, error => {
        this.httpService.done()
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao salvar o título, contatar o suporte: " + error.message })
      })
  }

  salvarPagamento(alterarRecorrencia, baixar: Boolean) {

    if (alterarRecorrencia) {
      if (confirm('Deseja alterar somente esta recorrência?')) {
        this.dadosPagamento(alterarRecorrencia, baixar);
      }
    } else {
      this.dadosPagamento(alterarRecorrencia, baixar);
    }
  }

  dadosPagamento(alterarRecorrencia, baixar: Boolean) {

    if (this.validacao() === true) {
      return
    }

    const listasEnviar = {
      movFinId: this.idMovFin,
      empresa: this.dblinkedSessionService.empresa.id,
      operacaoRegra: this.operacaoRegra.id,
      cliente: this.cliente.codigo,
      valorOperacao: this.utilService.parseMoney(this.valorOperacao),
      tituloNfe: this.tituloNfe,
      historicoOperacao: this.historicoOperacao,
      linkFinanceiro: this.linkFinanceiro,
      refFinanceiro: this.refFinanceiro,
      dtOperacao: this.dtOperacao,
      recorrencia: this.recorrencia,
      ocorrenciaMes: this.ocorrenciaMes,
      antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
      dtFimOccorencia: this.dtFimOccorencia,
      usuario: this.sessionService.loggedUser.id,
      empresaConta: this.contaSelecionadaId,
      // Salvar RateioCentroCusto
      rateio: this.movimentacaoRateios,
      novoRegistro: this.operacaoRegra.novoRegistro,
      // para pagar por CNAB @octavio
      tipoPagamentoId: this.tipoSelecionadoId,
      nDocumento: this.nDocumento,
      codBarras: this.codBarras,
      codReceita: this.codReceita,
      dtApuracao: this.dtApuracao,
      idRecolhimento: this.idRecolhimento,
      digitoIdRecolhimento: this.digitoIdRecolhimento,
      participanteContasId: this.participanteContasId,
      codRecolhimento: this.codRecolhimento,
      codPagamentoGps: this.codPagamentoGps,
      // fim @octavio
      alterarRecorrencia: this.alterarRecorrencia,
      provisao: this.provisao,

      dtEmissao: this.dtEmissao,
      dtCompetencia: this.dtCompetencia,

      moedaSelecionada: this.moedaSelecionadaId,
      valorTaxa: this.utilService.parseDecimal4(this.valorTaxa),
      tipo: this.parametro,
      baixar: baixar,
      isEstorno: this.isEstorno,
      observacao: this.observacao,
      participantePixId: this.chavePixSelecionada,
      nDocument: this.nDocumento
    };

    let pagamentoCopy = JSON.parse(JSON.stringify(listasEnviar))
    this.pagamentoEdit = this.parseObject(this.pagamentoEdit)
    pagamentoCopy = this.parseObject(pagamentoCopy)

    this.httpService.wait();
    this.httpService.post('/custom/financeiro/salvar-titulo', listasEnviar)
      .subscribe(result => {

        if (result[0].error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'MSG',
            detail: result[0].message,

          });


        }
        else {

          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG',
            detail: result[0].message,

          });

          this.participanteCodigo = "";

          this.LogManagement.registerLog(
            LogModule.FINANCE,
            LogScreen.PAYMENTS,
            '/custom/financeiro/salvar-titulo',
            this.operacaoRegra.novoRegistro ? LogAction.CREATE : LogAction.EDIT,
            result[0].nfeFinId,
            this.historicoOperacao,
            `O título ${this.historicoOperacao} foi ` + (this.operacaoRegra.novoRegistro ? "criado" : "modificado"),
            listasEnviar
          )

          const payload = {
            service: Service.APPROVAL_CENTER,
            action: Action.FINANCIAL,
            method: Method.PUT,
            content: {
              userId: this.sessionService.loggedUser.id,
              cardId: this.todoCardId,
              userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
              compareList: [
                { before: this.pagamentoEdit, after: pagamentoCopy },
              ]
            }
          }

          this.socketService.send(payload)

          if (this.flowUses && this.requestApproval) {
            this.solicitarAprovacao(true, result[0].nfeFinId);
          } else {
            this.atualizarVoltar();
          }
        }
      }, error => this.httpService.handleError(error, () => this.dadosPagamento(alterarRecorrencia, baixar)), () => this.httpService.done());
  }

  controlCentroCusto(cadCustoId = null) {

    if ((this.rateioCentroCusto <= 0) || (this.rateioCentroCusto < this.porcentagemCc)) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'O valor do rateio é 100%, selecione um rateio para alterar!',

      });

      return;
    }
    this.modalCentroCusto = true;
    this.contadorRateio = 0;

    this.httpService.wait();
    this.httpService.get('/cadccus?$select=id,codCcus,descrCcus,projetosRelacionados,ativo' +
      '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value + '&$orderby=codCcus')
      .subscribe(cc => this.comboCcus(cc.value),
        error => this.httpService.handleError(error, () => this.controlCentroCusto(cadCustoId)),
        () => this.httpService.done());

    if (!isNullOrUndefined(cadCustoId)) {
      this.centroCustoId = cadCustoId;
    } else {
      this.centroCustoId = 0;
    }

    this.httpService.wait();
    this.httpService.get('/custom/financeiro/get-projetos(' + this.dblinkedSessionService.empresa.id + ','
      + this.centroCustoId + ')')
      .subscribe(proj => {
        this.comboProjeto(proj);
      },
        error => this.httpService.handleError(error, () => this.controlCentroCusto(cadCustoId)),
        () => this.httpService.done());
  }

  comboCcus(value: Array<any>) {
    this.opcoesCentroCusto = [];
    this.opcoesCentroCusto.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      if (element.ativo) {
        element.label = element.codCcus + ' - ' + element.descrCcus;
        this.opcoesCentroCusto.push(element);
      }

    });

    if (this.modalEfetuarBaixa) {
      this.porcentagemCc = 100;
    }

  }

  comboProjeto(value: Array<any>) {
    this.opcoesProjetos = [];
    this.opcoesProjetos.push({ label: 'Nenhum', value: null });
    if (value.length < 1) {
      this.existeProjetosRelacionados = false;
    }
    value.forEach(element => {
      element.label = element.codProj + ' - ' + element.descrProj;
      this.opcoesProjetos.push(element);
      this.existeProjetosRelacionados = element.existeProjetosRelacionados;
    });
  }

  fecharModalRateio() {
    this.modalCentroCusto = false;
    // this.rateioCentroCusto = 100;
  }

  removerRateioCentroCusto(event: any) {
    if (confirm('Deseja excluir este rateio?')) {
      const index = this.movimentacaoRateios.indexOf(event);
      this.movimentacaoRateios.splice(index, 1);
    }
    this.rateioCentroCusto = 100;
    this.movimentacaoRateios.forEach(element => {
      this.rateioCentroCusto = Math.round((this.rateioCentroCusto - this.utilService.parseDecimal(element.porcentagem)) * 100) / 100;
    });
  }

  incluirRateioCentroCusto() {
    if ((this.rateioCentroCusto <= 0) || (this.rateioCentroCusto < this.porcentagemCc)) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'O valor do rateio ultrapassa os 100%, verifique!',

      });


      return;
    }

    if (this.existeProjetosRelacionados && isNullOrUndefined(this.proj.id)) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Para prosseguir, Insira um Projeto!',

      });

      return;
    }

    if (isNullOrUndefined(this.cc.id)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Para prosseguir, Insira um Centro de Custo!',

      });


      return;
    }

    this.financeiroRateio = new FinanceiroRateio();
    this.rateioCentroCusto = Math.round((this.rateioCentroCusto - this.utilService.parseDecimal(this.porcentagemCc)) * 100) / 100;
    this.financeiroRateio.id = this.contadorRateio;
    this.financeiroRateio.cadProj = this.proj;
    this.financeiroRateio.cadCcus = this.cc;
    this.financeiroRateio.porcentagem = this.porcentagemCc;

    this.movimentacaoRateios.push(this.financeiroRateio);
    this.contadorRateio++;
    this.proj = [];
    this.porcentagemCc = 0;
  }

  editarCentroCusto() {
    this.modalCentroCusto = true;
    this.rateioCentroCusto = 100;
    this.movimentacaoRateios.forEach(element => {
      this.rateioCentroCusto = Math.round((this.rateioCentroCusto - this.utilService.parseDecimal(element.porcentagem)) * 100) / 100;
      this.contadorRateio++;
    });
  }

  atualizarVoltar() {
    this.contaSelecionadaFiltro = this.contaSelecionadaFiltroPreSelect

    this.modalFiltro = false
    this.centroCusto = new CadCcus;
    this.cadProj = new CadProj;
    this.modalCentroCusto = false;
    this.porcentagemCc = 0;
    this.cc = [];
    this.proj = [];
    this.rowsSelected = new Array()
    this.clearArr = new Array()
    this.sidebarCardId = new Array()
    this.rateioCentroCusto = 100;
    this.rateioCadProj = 100;
    this.movimentacaoRateios = new Array();
    this.moedaSelecionadaId = 1;
    this.requestApproval = false;
    this.linhasEdicao = new Array()
    // end centro de custo e projetos
    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() > 8 ?
        (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() > 8 ?
        (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 9) ?
        this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
        this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }
    const d1 = new Date(this.dataIniSearch);
    const d2 = new Date(this.dataFinSearch);
    if (d1 > d2) {

      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Data inicial maior que a final. Verifique!',

      });

      return;
    }
    //para o modal de edição fechar corretamente @octavio 05/06/2020
    this.abrirModal = 0;
    let url = window.location.pathname;
    this.location.replaceState(url.substring(0, url.lastIndexOf("/")));
    //fim @octavio

    this.changePeriod(false);
    this.alterarRecorrencia = false;
    this.editarPagtoOuReceb = false;
  }

  centroDeCustoObrigatorio() {
    this.httpService.get('/custom/pedido/centro-de-custo-obrigatorio(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(element => {
        this.centroCustoObrigatorio = element.cadCcusObrigatorio;
      },
        error => this.httpService.handleError(error, () => this.centroDeCustoObrigatorio()));
  }

  existeContaResultadoParaOperacaoRegra(operacaoRegraContabilId) {

    this.httpService.wait();
    this.httpService.get('/custom/financeiro/existe-conta-resultado-para-operacao-regra(' + operacaoRegraContabilId + ')')
      .subscribe(element => {

        this.existeContaResultado = element.existeContaResultado;
      },
        error => this.httpService.handleError(error, () => this.existeContaResultadoParaOperacaoRegra(operacaoRegraContabilId)),
        () => this.httpService.done());
  }

  loadParticipanteContas(idParticipante) {
    // if (this.cliente.codigo != null){
    this.httpService.wait();
    this.httpService.get('/custom/financeiro/get-contas-participante(' + idParticipante + ')')
      .subscribe(
        ret => {
          this.contasFornecedorLista = [];
          ret.forEach(element => {
            this.contasFornecedorLista.push({
              label: element["banco"],
              value: element["contaId"]
            });

          });
        },
        error => {
          this.httpService.done();
        },
        () => {
          this.httpService.done();
        });
    // }
  }

  loadTiposPagamentos() {
    this.httpService.get('/custom/cadastro/get-tipos-pagamentos(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(
        ret => {
          this.tiposLista = []
          this.tiposLista.push({ label: 'Nenhum', value: null, cnab_id: null, tipoBaixaId: null })
          ret.forEach(element => this.tiposLista.push({
            label: element["codigo"].toString() + ' - ' + element["descricao"],
            value: element["id"],
            cnab_id: element["cnab_id"],
            tipoBaixaId: element["tipoBaixaId"]
          }))
        },
        error => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'ERRO', detail: "Erro ao obter os tipos de pagamento/recebimento, contate o suporte: " + error.message })
        })
  }

  onChangeTipoPagamento(event) {
    this.codBarras = '';
    this.codReceita = '';
    this.dtApuracao = '';
    this.codPagamentoGps = '';
    this.codRecolhimento = '';
    this.idRecolhimento = '';
    this.digitoIdRecolhimento = '';
    for (let i = 0; i < this.tiposLista.length; i++) {
      if (this.tiposLista[i]["value"] === event["value"]) {
        this.cnabFormaSelecionadaId = this.tiposLista[i]["cnab_id"];
      }
    }
  }

  loadCodReceita() {
    this.httpService.get('/custom/financeiro/get-cod-receita-rfb')
      .subscribe(
        ret => {
          this.codReceitaLista = [];
          ret.forEach(element => {
            this.codReceitaLista.push({
              label: element["cod"].toString() + ' - ' + element["descricao"],
              value: element["cod"]
            });
          });
        },
        error => {
          this.httpService.done();
        });
  }

  showTypeTabs(tipo) {

    this.statusLink1 = false;
    this.statusLink2 = false;
    this.statusLink3 = false;

    if (tipo === 'LINK_1') {
      this.statusLink1 = true;
    } else if (tipo === 'LINK_2') {
      this.statusLink2 = true;



    } else if (tipo === 'LINK_3') {
      this.statusLink3 = true;
    }

  }

  filterOperacao(event: any) {

    this.filtroAdto = event.value;

    if (!isNullOrUndefined(this.filtroAdto) && this.filtroAdto.length > 0) {
      this.tabelaComFiltro = true;
      this.adtosFiltradosPorOperacaoId = this.adtosCache.filter(item => this.filtroAdto.includes(item.tipo_operacao_id));
    }
    else {
      this.tabelaComFiltro = false;
      this.adtos = this.adtosCache;
    }
    this.adtos.forEach(a => {
      a.selected = false;
    });
    this.adtosSelecionados = [];

    if (this.adtosFiltradosPorOperacaoId !== null || this.adtosFiltradosPorOperacaoId !== undefined) {
      if (this.adtosFiltradosPorOperacaoId.length > 0) {
        this.contadorLinha = this.adtosFiltradosPorOperacaoId.length;
      }
      else {
        this.contadorLinha = this.adtos.length;
      }
    }
    else {
      this.contadorLinha = 0;
    }

    if (event.value.length === 0) {
      this.contadorLinha = this.adtos.length;
    }
  }

  limparFiltro() {
    this.components['_results'].forEach(ds => {
      ds.value = null;
      ds.updateLabel();
    });
    this.tabelaComFiltro = false;
    this.filtroAdto = [];
    this.adtos = this.adtosCache;
  }

  showModalFiltraAdiantamentos() {

    this.httpService.wait();
    this.httpService.get('/custom/financeiro/get-adiantamentos(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(ret => {

        this.adtos = [];
        this.adtosCache = []
        this.adtosSelecionados = []
        this.adtosFiltradosPorOperacaoId = [];

        this.somaOriginal = 0;
        this.somaSaldo = 0;
        this.somaDisponivel = 0;
        this.totalCompensado = 0;

        ret.forEach(element => {
          // element.dataView = this.utilService._timestampToStringDateTime2(new Date(element.data)); //Não levava em consideração o timezone
          element.dataView = element.data;
          element.selected = false;
          if (element.vl_disponivel > 0) {
            this.adtos.push(element);
          }
        });

        this.adtosCache = this.adtos;

        /*contador scroll table adiantamento*/
        if (this.adtos !== null || this.adtos !== undefined) {
          this.contadorLinha = this.adtos.length;
        }
        else {
          this.contadorLinha = 0;
        }

      }, error => this.httpService.handleError(error, () => this.showModalFiltraAdiantamentos()),
        () => this.httpService.done());



    this.adtosSelecionados = [];
    this.exibirModalAdicionarAdiantamento = true;
    this.adtos.forEach(a => {
      a.selected = false;
    });
    this.adtos.sort((a, b) => a.data - b.data);
    this.limparFiltro();
  }

  closeModalFiltraAdiantamentos(dt: Table) {
    this.exibirModalAdicionarAdiantamento = false;
    dt.reset();
  }

  adicionarAdiantamentosFiltrados(dt: Table) {

    if (this.adtosSelecionados.length > 0) {
      this.adtosSelecionados.forEach(a => {
        a.vl_compensado = null;
        this.adtosListaFinal.push(a);
      });

      this.adtosListaFinal.forEach(af => {
        const index = this.adtos.findIndex(a => a.id === af.id);
        if (index >= 0) {
          this.adtos.splice(index, 1);
        }
      });
    }

    this.totalValorOrigEDisp();
    this.adtosCache = [];
    this.adtosCache = this.adtos;
    this.exibirModalAdicionarAdiantamento = false;
    dt.reset();
  }

  selectAdiantamento(event: any) {
    this.adtosSelecionados.push(event.data);
  }

  unselectAdiantamento(event: any) {
    this.adtosSelecionados.splice(this.adtosSelecionados.findIndex(c => c.id === event.data.id), 1);
  }

  selectTodosAdiantamentos(event: any) {
    this.adtosSelecionados = [];

    this.todosSelecionados = !this.todosSelecionados;

    if (event.checked) {
      if (this.tabelaComFiltro) {
        this.adtosFiltradosPorOperacaoId.forEach(element => {
          element.selected = true;
          this.adtosSelecionados.push(element);
        });
      } else {
        this.adtos.forEach(element => {
          element.selected = true;
          this.adtosSelecionados.push(element);
        });
      }

    } else {
      if (this.tabelaComFiltro) {
        this.adtosFiltradosPorOperacaoId.forEach(element => {
          element.selected = false;
        });
      } else {
        this.adtos.forEach(element => {
          element.selected = false;
        });
      }
    }
  }

  removerAdto(adto) {
    this.somaOriginal = this.utilService.parseMoney(this.somaOriginal - adto.vl_original);
    this.somaSaldo = this.utilService.parseMoney(this.somaSaldo - adto.vl_saldo);
    this.somaDisponivel = this.utilService.parseMoney(this.somaDisponivel - adto.vl_disponivel);

    if (!isNullOrUndefined(adto.vl_compensado)) {
      this.totalCompensado = this.utilService.parseMoney(this.totalCompensado - this.utilService.parseDecimal(adto.vl_compensado));
    }

    const index = this.adtosListaFinal.findIndex(c => c.id === adto.id);
    this.adtosListaFinal.splice(index, 1);
    adto.vl_disponivel = adto.vl_disponivel + adto.vl_compensado;
    this.adtos.push(adto);

    this.adtosCache = [];
    this.adtosCache = this.adtos;
  }

  somaValoresCompensados(operacao: string=null) {
    this.somaCompensado = 0.00;
    this.adtosListaFinal.forEach(c => {
      this.somaCompensado = this.utilService.parseMoney(this.somaCompensado + this.utilService.parseDecimal(c.vl_compensado));
    });
    this.valorCompensadoBaixa = this.somaCompensado;
    if (operacao === "somar") this.valorRecebidoBaixa += this.somaCompensado
    this.calcularValorBaixa();
  }

  validarValorCompensado() {
    this.adtosListaFinal.forEach(rowData => {
      let valorCompensado = rowData.vl_compensado

      if (!(typeof valorCompensado === "number")) {

        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: "Valor inválido!",

        });

        rowData.vl_compensado = null
      } else {
        if (valorCompensado <= 0) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG',
            detail: "Valor deve ser maior que zero!",

          });


          rowData.vl_compensado = null
        }
        if (valorCompensado > rowData.vl_disponivel) {

          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG',
            detail: "Valor a ser compensado não pode ser maior que o valor disponível!",

          });
          rowData.vl_compensado = null;
        }
      }
    })
  }

  totalValorCompensado() {
    this.totalCompensado = 0.00;
    this.adtosListaFinal.forEach(c => {
      if (!isNullOrUndefined(c.vl_compensado)) {
        this.totalCompensado = this.utilService.parseMoney(this.totalCompensado + this.utilService.parseDecimal(c.vl_compensado));
      }
    });
  }

  totalValorOrigEDisp() {
    this.somaOriginal = 0;
    this.somaSaldo = 0;
    this.somaDisponivel = 0;
    this.totalCompensado = 0;

    this.adtosListaFinal.forEach(af => {
      this.somaOriginal = this.utilService.parseMoney(this.somaOriginal + af.vl_original);
      this.somaSaldo = this.utilService.parseMoney(this.somaSaldo + af.vl_saldo);
      this.somaDisponivel = this.utilService.parseMoney(this.somaDisponivel + af.vl_disponivel);
    });
  }

  modalBaixaRota(nfid: number) {
    // função que abre o modal de baixa diretamente (pelo id) @octavio 16/06/2020
    let event: any = {};

    this.httpService.wait();
    this.httpService.get('/custom/financeiro/get-baixa-direta(' + nfid + ')')
      .subscribe(ret => {
        event = ret[0];
      },
        error => this.httpService.handleError(error, () => this.modalBaixaRota(nfid)),
        () => {
          this.modalOperacao = false;
          this.modalOperacaoPagto = false;
          this.onRowSelect(event);
          this.httpService.done()
        });
  }

  showTelaMovVenc(tipo) {
    this.statusMovimentacao = false;
    this.statusVencidos = false;

    if (tipo === 'TELA_1') {
      this.statusMovimentacao = true;
      this.tab = 0;
      this.toolbarMain.setClick('btn1', () => this.atualizarVoltar())
    } else if (tipo === 'TELA_2') {
      this.statusVencidos = true;
      this.tab = 1;
      this.toolbarMain.setClick('btn1', async () => await this.loadVencidos())
    }

    this.clearArr = []
    this.changeToolbar()
  }

  aplyRowStyle(rowData): string {

    return rowData.pedido ? 'row-highlight' : rowData.provisao ? 'row-prov' : '';
  }

  formaPgtoCalcular() {

    let vencimento = new Date(this.primeiroVencimento);
    const dia = vencimento.getDate();

    this.parcelamentoList = new Array();

    let vlrParcela = Number((this.valorRecebidoBaixa / this.qtdParcelas).toFixed(2));

    let countVlrParcelas = 0.00;
    this.valorTotalParcelas = this.valorRecebidoBaixa;

    for (let parcela = 1; parcela <= this.qtdParcelas; parcela++) {

      let nFin = new NfeFin();

      let mes = null;
      // pegando próximo vencimento - considera 30 dias
      if (vencimento.getMonth() === 0 && dia > 28 && parcela > 1) {
        // proximo é fevereiro
        vencimento = (new Date(vencimento.getTime() + 28 * 24 * 60 * 60 * 1000));
        const lastDayFev = new Date(vencimento.getFullYear(), vencimento.getMonth() + 1, 0); // ultimo dia do mês
        vencimento = lastDayFev;
        mes = vencimento.getMonth(); // mes da parcela
      } else {

        mes = vencimento.getMonth(); // mes da parcela anterior
        vencimento = ((parcela === 1) ? vencimento : new Date(vencimento.getTime() + 30 * 24 * 60 * 60 * 1000));
        // se não trocou de mes
        if (dia === 1 && mes === vencimento.getMonth() && parcela > 1) {
          // acrescentar mais um dia se não trocou de mÊs
          vencimento = new Date(vencimento.getTime() + (1 * 24 * 60 * 60 * 1000));
        }
        mes = vencimento.getMonth(); // mes da parcela
        vencimento.setDate(dia); // dia de vencimento
      }

      let lastDay = vencimento;
      // validando se é no mes corrente ou o set dia trocou de mes
      if (mes !== vencimento.getMonth()) {
        lastDay = new Date(vencimento.getTime() - (24 * 60 * 60 * 1000));
      }
      // total das parcelas
      countVlrParcelas += this.utilService.parseDecimal(vlrParcela);

      // validando qtd parcelas -> pegar ultima
      if (parcela === this.qtdParcelas) {
        // calculando diferença
        const diference = Number((this.valorRecebidoBaixa - countVlrParcelas).toFixed(2));
        // total das parcelas
        countVlrParcelas += this.utilService.parseDecimal(vlrParcela);

        // descontando ou somando a diferenca.
        vlrParcela = (diference > 0 ? (vlrParcela + diference) : (diference < 0 ? (vlrParcela = vlrParcela + diference) : vlrParcela));
      }

      nFin.valor = vlrParcela;
      nFin.valorPrincipal = vlrParcela;
      nFin.dtVencimento = vencimento;
      nFin.dtVencimentoOriginal = vencimento;
      nFin.parcela = parcela;
      nFin.empresaConta = new EmpresaConta();
      nFin.multa = 0.00;
      nFin.juros = 0.00;
      nFin.desconto = 0.00;
      nFin.empresaConta.id = this.contaSelecionadaId;
      this.selectContas.forEach(element => {
        if (element.value === nFin.empresaConta.id) {
          nFin.empresaConta.nome = element.label;
        }

      });

      this.parcelamentoList.push(nFin);

    }

    this.calculaValorParcelamento();

  }

  setConta(event: any, dados: any) {
    if (confirm('Deseja também replicar a conta bancária para as outras parcelas?')) {
      dados.empresaConta.id = event.value;
      this.selectContas.forEach(element => {
        if (element.value === dados.empresaConta.id) {
          dados.empresaConta.nome = element.label;
        }
      });
      this.parcelamentoList.forEach(element => {
        if (element.parcela > dados.parcela) {
          element.empresaConta.id = event.value;
          this.selectContas.forEach(el => {
            if (el.value === dados.empresaConta.id) {
              element.empresaConta.nome = el.label;
            }
          });
        }
      });
    } else {
      dados.empresaConta.id = event.value;
      this.selectContas.forEach(element => {
        if (element.value === dados.empresaConta.id) {
          dados.empresaConta.nome = element.label;
        }
      });
    }
  }

  calculaValorParcelamento() {

    this.valorPrincipalParcelamento = 0.00;
    this.valorMultaParcelamento = 0.00;
    this.valorJurosParcelamento = 0.00;
    this.valorDescontoParcelamento = 0.00;
    this.valorTotalParcelamento = 0.00;


    this.parcelamentoList.forEach(element => {

      let valorPrincipal = isNullOrUndefined(this.utilService.parseDecimal(element.valorPrincipal)) ? 0 : this.utilService.parseDecimal(element.valorPrincipal);
      let multa = isNullOrUndefined(this.utilService.parseDecimal(element.multa)) ? 0 : this.utilService.parseDecimal(element.multa);
      let juros = isNullOrUndefined(this.utilService.parseDecimal(element.juros)) ? 0 : this.utilService.parseDecimal(element.juros);
      let desconto = isNullOrUndefined(this.utilService.parseDecimal(element.desconto)) ? 0 : this.utilService.parseDecimal(element.desconto);
      let valorTotal = valorPrincipal + multa + juros - desconto;

      element.valorPrincipal = isNullOrUndefined(this.utilService.parseDecimal(element.valorPrincipal)) ? 0 : this.utilService.parseDecimal(element.valorPrincipal);
      element.multa = isNullOrUndefined(this.utilService.parseDecimal(element.multa)) ? 0 : this.utilService.parseDecimal(element.multa);
      element.juros = isNullOrUndefined(this.utilService.parseDecimal(element.juros)) ? 0 : this.utilService.parseDecimal(element.juros);
      element.desconto = isNullOrUndefined(this.utilService.parseDecimal(element.desconto)) ? 0 : this.utilService.parseDecimal(element.desconto);
      element.valor = isNullOrUndefined(valorTotal) ? 0 : this.utilService.parseDecimal(valorTotal);

      this.valorPrincipalParcelamento = this.valorPrincipalParcelamento + (isNullOrUndefined(this.utilService.parseDecimal(element.valorPrincipal)) ? 0 : this.utilService.parseDecimal(element.valorPrincipal));
      this.valorMultaParcelamento = this.valorMultaParcelamento + (isNullOrUndefined(this.utilService.parseDecimal(element.multa)) ? 0 : this.utilService.parseDecimal(element.multa));
      this.valorJurosParcelamento = this.valorJurosParcelamento + (isNullOrUndefined(this.utilService.parseDecimal(element.juros)) ? 0 : this.utilService.parseDecimal(element.juros));
      this.valorDescontoParcelamento = this.valorDescontoParcelamento + (isNullOrUndefined(this.utilService.parseDecimal(element.desconto)) ? 0 : this.utilService.parseDecimal(element.desconto));
      this.valorTotalParcelamento = this.valorTotalParcelamento + (isNullOrUndefined(valorTotal) ? 0 : this.utilService.parseDecimal(valorTotal));

    });

  }

  alterarValorParcelamento(event: any, tipo: string) {
    if (confirm('Deseja também replicar os valores de ' + tipo + ' para as outras parcelas?')) {
      if (tipo === 'principal') {

        this.parcelamentoList.forEach(element => {
          if (element.parcela > event.parcela) {
            element.valorPrincipal = event.valorPrincipal;
          }
        });
      } else if (tipo === 'multa') {
        this.parcelamentoList.forEach(element => {
          if (element.parcela > event.parcela) {
            element.multa = event.multa;
          }
        });
      } else if (tipo === 'juros') {
        this.parcelamentoList.forEach(element => {
          if (element.parcela > event.parcela) {
            element.juros = event.juros;
          }
        });
      } else if (tipo === 'desconto') {
        this.parcelamentoList.forEach(element => {
          if (element.parcela > event.parcela) {
            element.desconto = event.desconto;
          }
        });
      } else if (tipo === 'documento') {
        this.parcelamentoList.forEach(element => {
          if (element.parcela > event.parcela) {
            element.documento = event.documento;
          }
        });
      }

      this.calculaValorParcelamento();
    } else {
      this.calculaValorParcelamento();
    }
  }

  closeModalParcelamento() {

    if (confirm('Deseja realmente cancelar o parcelamento?')) {
      this.parcelamento = false;
      this.valorPrincipalParcelamento = 0.00;
      this.valorMultaParcelamento = 0.00;
      this.valorJurosParcelamento = 0.00;
      this.valorDescontoParcelamento = 0.00;
      this.valorTotalParcelamento = 0.00;
      this.parcelamentoList = new Array();

    }

  }

  salvarParcelamento() {

    if (confirm('Deseja realmente salvar o parcelamento?')) {
      if (this.utilService.parseDecimal(this.valorRecebidoBaixa) < this.utilService.parseDecimal(this.valorPrincipalParcelamento)) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'O valor do principal deve ser maior ou igual ao valor do título'
        });
        return;


      } else {
        const listasEnviar = {
          nfeFinId: this.nfeFinId,
          parcelamento: this.parcelamentoList,
          usuario: this.sessionService.loggedUser.id

        };


        this.httpService.wait();
        this.httpService.post('/custom/financeiro/parcelar-titulo', listasEnviar)
          .subscribe(result => {
            if (result.error === true) {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: result.message });
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: '', detail: result.message });
              this.changePeriod(true);
            }
          },
            error => this.httpService.handleError(error, () => this.baixar()),
            () => this.httpService.done());

      }
    }
  }

  onChangeContaBancaria(event: any, parametro: string) {

    if (parametro === 'select') {
      if (this.confTaxaId != 0) {
        this.httpService.wait();
        this.httpService.get('/custom/financeiro/buscar-conf-taxa',
          [this.confTaxaId, event.value, this.utilService.dateFormat(this.dtOperacao), 0])
          .subscribe(ref => {
            this.valorTaxa = this.utilService.parseDecimal4(ref.taxa);
            this.moedaSelecionadaId = ref.moeda;
          },
            error => this.httpService.handleError(error, () => this.onChangeContaBancaria(event, parametro)),
            () => this.httpService.done());
      }
    } else if (parametro === 'moeda') {
      if (this.confTaxaId != 0) {
        this.httpService.wait();
        this.httpService.get('/custom/financeiro/buscar-conf-taxa',
          [this.confTaxaId, this.contaSelecionadaId, this.utilService.dateFormat(this.dtOperacao), this.moedaSelecionadaId])
          .subscribe(ref => {
            this.valorTaxa = this.utilService.parseDecimal4(ref.taxa);
            this.moedaSelecionadaId = ref.moeda;
          },
            error => this.httpService.handleError(error, () => this.onChangeContaBancaria(event, parametro)),
            () => this.httpService.done());
      }
    } else {
      if (this.confTaxaId != 0) {
        this.httpService.wait();
        this.httpService.get('/custom/financeiro/buscar-conf-taxa',
          [this.confTaxaId, this.contaSelecionadaId, this.utilService.dateFormat(this.dtOperacao), 0])
          .subscribe(ref => {
            this.valorTaxa = this.utilService.parseDecimal4(ref.taxa);
            this.moedaSelecionadaId = ref.moeda;
          },
            error => this.httpService.handleError(error, () => this.onChangeContaBancaria(event, parametro)),
            () => this.httpService.done());
      }
    }
  }

  previewFinanceiro(event: any, tipo: string) {

    this.detalheFin = new Array()
    this.detalheCont = new Array()
    this.detalheAdto = new Array()
    this.cobranca = {}
    this.codPedido = null
    this.codControle = null
    this.numDoc = null
    this.valorTotal = null
    this.codPart = null
    this.razaoSocial = null
    this.cnpjCpf = null
    this.nfeFinIdDetalhe = null

    this.httpService.wait()
    this.httpService.get('/custom/financeiro/buscar-infos-nfe-id',
      [tipo === 'mov' ? event.nfeFinId : event.id, this.dblinkedSessionService.empresa.id])
      .subscribe(ref => {
        this.modalDetalheContFin = true
        this.detalheFin = ref.fin
        this.detalheAdto = ref.adto
        this.detalheCont = ref.cont
        this.codPedido = ref.codPedido
        this.codControle = ref.codControle
        this.numDoc = ref.numDoc
        this.valorTotal = ref.valorTotal
        this.codPart = ref.codPart
        this.razaoSocial = ref.razaoSocial
        this.cnpjCpf = ref.cnpjCpf
        this.nfeFinIdDetalhe = tipo === 'mov' ? event.nfeFinId : event.id
        this.httpService.done()
      },
        error => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'ERRO', detail: 'Erro no API ao recuperar as informoações do títulos. Contatar o suporte: ' + error.message })
          this.httpService.done()
        })
  }

  convertValorMoeda() {
    this.valorReais = this.utilService.parseDecimal(this.utilService.parseDecimal(this.valorOperacao) * this.utilService.parseDecimal4(this.valorTaxa))
  }

  async getMoreTransactions(event: LazyLoadEvent) {
    if (this.statusMovimentacao) await this.getHistoryTransactions(event.first, event.rows, event.filters, event.sortField, event.sortOrder, false)

    if (this.statusVencidos) await this.loadVencidos(event.first, event.rows, event.filters, event.sortField, event.sortOrder, false)
  }

  async getHistoryTransactions(offset: number = 0, limit: number = 15, filters: Object = {}, sortField: string = "", sortOrder: number = 0, defaultParam: boolean = true) {
    if (this.carregarNovamenteRegistro) {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        if (!this.dblinkedSessionService.empresa.altoVolume || !this.filtroPadrao) {
          this.totalRecords = 0;

          this.recebimentos = new Array();

          const data = {
            company_id: this.dblinkedSessionService.empresa.id,
            offset,
            limit,
            filters,
            sortField,
            sortOrder,
            defaultParam,
            dataIniSearch: this.dataIniSearch,
            dataFinSearch: this.dataFinSearch,
            tipo: this.parametro,
            ano: this.dblinkedSessionService.periodo.year.value,
            mes: this.dblinkedSessionService.periodo.month.value,
            contaSelecionada: this.contaSelecionadaFiltro,
            filiais: this.dblinkedSessionService.filiaisSelecionadas,
            participante: this.participanteCodigo

          };

          this.skeletonConfig.startLoad()
          this.httpService.post('/custom/financeiro/buscar-cr-cp', data)
            .subscribe(result => {
              if (result.error) {
                this.exibirMensagem(result)
                return
              }

              this.totalFiltro = 0.00;
              this.totalVencido = 0.00
              this.totalAberto = 0.00
              this.recebimentos = result.data;
              this.totalRecords = result.totalRecords;
              this.totalPeriodo = result.totalPeriodo;
              this.totalPago = result.totalPago;
              this.query = result.query;

              result.data.forEach(element => {
                if (!isNullOrUndefined(element.status)) {
                  if (element.status === 'VENCIDO') {
                    this.valor = this.utilService.parseMoney((element.valor_saldo));
                    this.totalVencido = this.totalVencido + this.valor;
                    element.isPodeEditar = true;
                  } else if (element.status === 'PENDENTE') {
                    this.valor = this.utilService.parseMoney((element.valor_saldo));
                    this.totalAberto = this.totalAberto + this.valor;
                    element.isPodeEditar = true;
                  } else if (element.status.toString().startsWith('BAI') || element.status === "CONFIRMADO") {
                    this.valor = this.utilService.parseMoney((element.valor_saldo));
                    this.totalFiltro = this.totalFiltro + this.valor;
                    element.isPodeEditar = false;
                  } else {
                    this.valor = this.utilService.parseMoney((element.valor_saldo));
                    element.isPodeEditar = true;
                  }
                }

                if (this.remessaOptions.findIndex(r => r.value === element.statusRemessa) === -1 && element.statusRemessa) {
                  this.remessaOptions.push({ label: element.statusRemessa, value: element.statusRemessa });
                }
              });

              this.statusOptions = [];
              result.distinct.forEach(element => {
                if (this.statusOptions.findIndex(r => r.value === element) === -1 && element) {
                  this.statusOptions.push({ label: element, value: element });
                }
              });

              this.statusAprovOptions = []
              result.distinctAprov.forEach(element => {
                if (element) this.statusAprovOptions.push({ label: element, value: element })
              })

            }, error => {
              this.exibirMensagem({ error: true, message: "Erro ao buscar a lista de títulos, contate o suporte: " + error.message })
            }, () => this.skeletonConfig.endLoad());
        }
      }
    }
    this.carregarNovamenteRegistro = true
  }

  exibirMensagem(value: any) {
    let msgs = value.mensagem ? value.mensagem : value.message
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: msgs });

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: msgs });
    }
  }

  gerarRelatorio() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    let payload = {
      empresaid: this.dblinkedSessionService.empresa.id,
      tipo: this.statusMovimentacao ? 'Movimentação' : 'Vencidos',
      parametro: this.parametro,
      dtIni: this.dataIniSearch,
      dtFin: this.dataFinSearch,
      tableValue: this.statusMovimentacao ? this.recebimentos : this.statusVencidos ? this.vencidos : [],
      query: this.query
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'financeiro/relatorio-receb-pagto', payload, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          if (res["error"]) return this.httpService.done();

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0'
            }),
            responseType: 'blob' as 'json'
          };

          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/relatorio-receb-pagto', httpOptions2)
            .subscribe(
              (res: any) => {
                let link = document.createElement('a');
                let url = window.URL.createObjectURL(res);
                let tipo = this.parametro == 'cr' ? 'Recebimento' : 'Pagamento';
                let fileName = `Relatório ${tipo}.xlsx`;
                link.href = url;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
                this.httpService.done();
              },
              err => {
                this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível baixar o relatório." });
                this.httpService.done();
              });

        }, err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível gerar o relatório." });
          this.httpService.done();
        });
  }

  reprocessarContabilidade() {
    if (this.convertArr(this.rowsSelected, false, true).length === 0) return this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Você precisa selecionar pelo menos uma conta válida!' });

    if (confirm('Deseja realmente reprocessar a contabilidade destes Títulos?')) {
      const listasEnviar = {
        empresaId: this.dblinkedSessionService.empresa.id,
        list1: (this.convertArr(this.rowsSelected, false, true)),
        list2: this.list2,
        usuario: this.sessionService.loggedUser.id,
        mes: this.dblinkedSessionService.periodo.month.value,
        ano: this.dblinkedSessionService.periodo.year.value,
        param: 'cont-fin'
      };

      this.httpService.wait();
      this.httpService.post('/custom/nfe/reprocessar-nf', listasEnviar)
        .subscribe(res => {


          let error = false
          res.forEach(element => {
            if (element.error) {
              error = true;
              return
            }
          });

          if (error) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res[0].message });
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Os títulos foram reprocessadas com sucesso' });
          }
        },
          error => this.httpService.handleError(error),
          () => this.httpService.done());
    }
  }

  cancelarBaixas() {
    if (this.convertArr(this.rowsSelected, false, true).length === 0) return this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Você precisa selecionar pelo menos uma conta válida!' })

    if (confirm('Deseja realmente cancelar as baixas destes Títulos?')) {

      const listasEnviar = {
        empresaId: this.dblinkedSessionService.empresa.id,
        list1: (this.convertArr(this.rowsSelected, false, true)),
        list2: this.list2,
        usuario: this.sessionService.loggedUser.id,
        mes: this.dblinkedSessionService.periodo.month.value,
        ano: this.dblinkedSessionService.periodo.year.value,
        param: 'deletar-baixa'
      };

      this.httpService.wait();
      this.httpService.post('/custom/nfe/reprocessar-nf', listasEnviar)
        .subscribe(res => {
          let error = false;
          res.forEach(element => {

            if (element.error) {


              this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Advertência', detail: element.message });
              error = true;
            } else {

              this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: element.message });
            }
          });
          if (!error)
            this.atualizarVoltar();
        },
          error => this.httpService.handleError(error),
          () => this.httpService.done());

    }
  }

  onFilterAdiantamento(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }

  parameterToolbar() {
    if (this.parametro === 'cr') return 1
    else return 2
  }

  estocarSelecaoParticipante(data) {
    this.participanteSelecionado = data.selectedParticipant;
    this.salvarParticipante();
  }

  salvarParticipante() {
    this.participanteCodigo = this.participanteSelecionado.cod_part
    this.participanteFantasia = this.participanteSelecionado.fantasia
    this.cliente.codigo = this.participanteSelecionado.id
    this.loadParticipanteContas(this.participanteSelecionado.id)
    this.getChavesPixParticipante(this.participanteSelecionado.id)
  }

  convertDate(value) {
    if (/\d{2}\/\d{2}\/\d{4}/.test(value)) return value
    let date = new Date(value);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return [day, month, year].join("/");
  }

  solicitarAprovacao(isDetails?: boolean, nfeFinId?: number) {
    this.cardsAprovacaoCriados = new Array()

    let aux: Array<any> = new Array(), listFinId: Array<any> = new Array()

    if (isDetails) {
      listFinId.push(nfeFinId)
    } else {
      aux = this.rowsSelected.filter(line =>
        (line.statusAprovacao === null ||
          line.statusAprovacao === undefined ||
          line.statusAprovacao.toUpperCase() === 'RECUSADO' ||
          line.statusAprovacao.toUpperCase() === 'TITULO CRIADO' ||
          line.statusAprovacao.toUpperCase() === 'NÃO SOLICITADO' ||
          line.statusAprovacao.toUpperCase() === 'AUTORIZAÇÃO RECUSADA') &&
        (line.status.toUpperCase() !== 'BAIXADO' ||
          line.status.toUpperCase() !== 'BAIXA PARCIAL')
      )

      aux.forEach(item => {
        listFinId.push(item.nfeFinId)
      })
    }

    const payload = {
      empresaId: this.dblinkedSessionService.empresa.id,
      origemId: 2,
      itemId: listFinId,
      userId: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/workflow/criar-card-aprovacao', payload)
      .subscribe(
        result => {
          this.exibirMensagem({ error: result.error, mensagem: result.response })
          this.httpService.done()
          this.atualizarVoltar()

          if (!result.error) {
            const username = this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome
            this.socketService.requestApproval(result.listTodoCardId, result.listNotificacaoId, this.sessionService.loggedUser.id, username)

            this.cardsAprovacaoCriados = result.listTodoCardId
          }
        },
        error => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao solicitar aprovação, contate o suporte: " + error.message })
          this.httpService.done()
        }
      );
  }

  async gerarBoleto() {
    let dicPedidos: any = {}
    this.rowsSelected.forEach(row => {
      if (!dicPedidos.hasOwnProperty(row.pedidoId)) {
        dicPedidos[row.pedidoId] = new Array()
      }

      dicPedidos[row.pedidoId].push(row.id)
    })

    for (let ped in dicPedidos) {
      const listasEnviar = {
        pedidos: dicPedidos[ped],
        empresa_id: this.dblinkedSessionService.empresa.id,
        usuario: this.sessionService.loggedUser.id
      }

      this.httpService.wait()
      this.httpService.post(`${this.configService.defaultUrlApiUpload}flaskfin/boleto/post-boleto`, listasEnviar)
        .subscribe(result => {
          if (result.error) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.mensagem })
            this.httpService.done()
            return
          }

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'liids': [result.id].toString(),
              'codigo': result.codigo[result.id].toString()
            }),
            responseType: 'blob' as 'json'
          };

          this.httpClient.get(`${this.configService.defaultUrlApiUpload}flaskfin/boleto/get-boleto-zip`, httpOptions2)
            .subscribe((res: any) => {
              let link = document.createElement('a')
              let url = window.URL.createObjectURL(res)
              link.href = url
              link.download = result.codigo[result.id].toString()
              link.click()
              window.URL.revokeObjectURL(url)
              this.httpService.done()
            },
              error => {
                this.exibirMensagem({ error: true, mensagem: "Erro no API ao baixar o boleto, contate o suporte: " + error.message })
                this.httpService.done()
              },
            );
        },
          error => {
            this.exibirMensagem({ error: true, mensagem: "Erro no API ao gerar o boleto, contate o suporte: " + error.message })
            this.httpService.done()
          },
        );

      await new Promise(r => setTimeout(r, 2000))

    }
  }

  async gerarPixQrCode() {
    let lititulos: Array<number> = new Array()
    this.rowsSelected.forEach(row => {
      lititulos.push(row.id)
    })

    const infos = {
      origem: "FIN",
      lititulos: lititulos,
      empresa_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    this.httpService.post(this.configService.octaremoteUrl + "/matera/qrcode", infos)
      .subscribe(
        res => {
          if (res.codigo === 500) {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res.message })
            this.httpService.done()
            return

          } else if (res.codigo === 400) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res.message })
            this.httpService.done()
            return

          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res.message })
            res.data.forEach(arquivo => {
              const httpOptions2 = {
                headers: new HttpHeaders({
                  'Authorization': this.configService.bearerApi,
                  'Cache-Control': 'no-store, max-age=0'
                }),
                responseType: 'blob' as 'json'
              }

              this.httpClient.get(this.configService.octaremoteUrl + "/matera/qrcode?userId=" + this.sessionService.loggedUser.id + "&nome=" + arquivo.toString(), httpOptions2)
                .subscribe(
                  (res: Blob) => {
                    let link = document.createElement('a')
                    let url = window.URL.createObjectURL(res)
                    link.href = url
                    link.download = arquivo
                    link.click()
                    window.URL.revokeObjectURL(url)
                    this.httpService.done()
                  },
                  err => {
                    this.exibirMensagem({ error: true, mensagem: "Erro no API ao baixar o QRCode " + arquivo.toString() + ", contate o suporte: " + err.message })
                    this.httpService.done()
                  }
                )
              new Promise(async () => await this.delay(2000))
            })
          }
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao gerar os QRCodes, contate o suporte: " + err.message })
          this.httpService.done()
        }
      )
  }

  protected async delay(milliseconds: number): Promise<void> {
    return new Promise(function (resolve) { setTimeout(resolve, milliseconds) })
  }

  loadConfigApi(): void {
    this.usaAsaas = false
    this.usaPix = false
    this.contaBancariaAsaasId = null
    this.httpService.get('/configuracoesapis?$select=codigo,ativo,empresaConta/id&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          res.value.forEach(row => {
            if (row.codigo === 'MAT' && row.ativo) this.usaPix = true
            if (row.codigo === 'ASAAS' && row.ativo) {
              this.usaAsaas = true
              this.contaBancariaAsaasId = row.empresaConta?.id ?? null
            }
          })
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao recuperar a configuração API da empresa, contate o suporte: " + err.message })
        }
      )
  }

  adicionarLinhaEdicaoLote(): void {
    let itensUsados: Array<string> = new Array()
    this.linhasEdicao.forEach(row => {
      if (row.item) itensUsados.push(row.item)
    })

    itensUsados.forEach(element => {
      let index = this.itensEditaveis.findIndex(row => row.value === element)
      if (index >= 0) this.itensEditaveis.splice(index, 1)
    })

    if (this.itensEditaveis.length === 0) this.exibirMensagem({ error: true, mensagem: this.dblinkedSessionService.usuario.nome + ", você já atingiu o número máximo de edição" })
    else this.linhasEdicao.push({ item: null, valor: null })
  }

  excluirLinhaEdicaoLote(rowData: any): void {
    let valorAdicionar = rowData.item
    let index = this.linhasEdicao.findIndex(row => row === rowData)
    this.linhasEdicao.splice(index, 1)

    if (valorAdicionar === "banco") this.itensEditaveis.push({ label: "Conta bancária", value: "banco" })
    else if (valorAdicionar === "previsao") this.itensEditaveis.push({ label: "Data previsão", value: "previsao" })
    else if (valorAdicionar === "vencimento") this.itensEditaveis.push({ label: "Data de vencimento", value: "vencimento" })
    else if (valorAdicionar === "tipopag") this.itensEditaveis.push({ label: "Tipo de " + (this.parametro === "cr" ? "recebimento" : "pagamento"), value: "tipopag" })
    else if (valorAdicionar === "tipobaixa") this.itensEditaveis.push({ label: "Tipo de baixa", value: "tipobaixa" })
    else if (valorAdicionar === "historico") this.itensEditaveis.push({ label: "Observações", value: "historico" })
  }

  atualizarRelatorio() {
    this.dt1.reset()
    this.dt1.clearState()
    this.dt2.reset()
    this.dt2.clearState()
    this.getHistoryTransactions(0, this.dt1._rows)
    this.loadVencidos(0, this.dt2._rows)
  }

  mostrarMaisBaixas() {
    this.exibicoesBaixas += 3
  }

  mostrarMenosBaixas() {
    if (this.exibicoesBaixas > 3) this.exibicoesBaixas -= 3
  }

  async gerarCobranca(mandarEmail: boolean = false) {
    let lititulos: Array<number> = new Array()
    this.rowsSelected.forEach(row => {
      lititulos.push(row.id)
    })

    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    const infos = {
      origem: "FIN",
      lititulos: lititulos,
      empresa_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id,
      mandarEmail: mandarEmail
    }

    this.httpService.wait()
    this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Info', detail: 'Processamento das faturas... Favor aguarde.' })
    this.httpClient.post(this.configService.octaremoteUrl + "/asaas/cobranca", infos, httpOptions)
      .subscribe(
        res => {
          if (res["codigo"] === 500) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] })
          else if (res["codigo"] === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res["message"] })
          else {
            if (mandarEmail) this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res["message"] })
            else {
              res["data"].forEach(url => {
                window.open(url)
              })
            }
          }
          this.httpService.done()
        },
        err => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao gerar os documentos de cobrança, contate o suporte: " + err.message })
          this.httpService.done()
        }
      )
  }

  buscarInfosCobranca() {
    let nfeFinId = this.rowsSelected[0].id

    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    this.httpService.wait()
    this.httpClient.get(this.configService.octaremoteUrl + "/asaas/cobranca?nfeFinId=" + nfeFinId.toString(), httpOptions)
      .subscribe(
        res => {
          if (res["codigo"] === 500) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] })
          else if (res["codigo"] === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res["message"] })
          else {
            this.cobranca = res["data"]
            this.modalDetalheCobranca = true
          }
          this.httpService.done()
        },
        err => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao obter as informações de cobrança, contate o suporte: " + err.message })
          this.httpService.done()
        }
      )
  }

  atualizarCobranca(nfeFinId: number) {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    const infos = {
      nfeFinId: nfeFinId,
      userId: this.sessionService.loggedUser.id,
      oneRec: this.alterarRecorrencia,
      rec: this.recorrencia
    }
    this.httpClient.put(this.configService.octaremoteUrl + "/asaas/cobranca", infos, httpOptions)
      .subscribe(
        res => {
          if (res["codigo"] === 500) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] })
          else if (res["codigo"] === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res["message"] })
        },
        err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao atualizar as informações de cobrança, contate o suporte: " + err.message })
      )
  }

  cancelarCobranca(status: boolean) {
    let nfeFinId = this.rowsSelected[0].id
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    this.httpService.wait()
    this.httpClient.delete(this.configService.octaremoteUrl + "/asaas/cobranca?id=" + nfeFinId + "&userId=" + this.sessionService.loggedUser.id + "&status=" + status + "&origem=FIN", httpOptions)
      .subscribe(
        res => {
          if (res["codigo"] === 500) {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] })

          } else if (res["codigo"] === 400) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res["message"] })

          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res["message"] })
            this.buscarInfosCobranca()
          }
          this.httpService.done()
        },
        err => {
          if (status) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao cancelar a cobrança, contate o suporte: " + err.message })
          else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao restaurar a cobrança, contate o suporte: " + err.message })
          this.httpService.done()
        }
      )
  }

  extrairTipoBaixa() {
    let tipo = this.tiposLista.find(row => row.value === this.tipoSelecionadoId)
    this.tipoBaixaId = tipo.tipoBaixaId
  }

  copiarQRCode() {
    navigator.clipboard.writeText(this.cobranca.qrcode)
    this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Copiado', detail: "QRCode copiado com sucesso!" })
  }

  copiarLinhaDigitavel() {
    navigator.clipboard.writeText(this.cobranca.linha_digitavel)
    this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Copiado', detail: "Linha digitavel copiado com sucesso!" })
  }

  async gerarAssinatura() {
    let lititulos: Array<number> = new Array()
    this.rowsSelected.forEach(row => {
      lititulos.push(row.id)
    })

    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    const infos = {
      nfe_fin_id: lititulos[0],
      empresa_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    this.httpClient.post(this.configService.octaremoteUrl + "/asaas/assinatura", infos, httpOptions)
      .subscribe(
        res => {
          if (res["codigo"] === 500) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] })
          else if (res["codigo"] === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res["message"] })
          else this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res["message"] })
          this.httpService.done()
        },
        err => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao gerar os documentos de cobrança, contate o suporte: " + err.message })
          this.httpService.done()
        }
      )
  }

  negativacao(negativado: boolean) {
    let nfeFinId = this.rowsSelected[0].id
    if (!negativado && !confirm("Confirmar negativação desse cliente?")) return

    if (!negativado) {
      if (!this.telefoneNegativacao) {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "O telefone é obrigatório " })
        return
      }
      if (!this.emailNegativacao) {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "O e-mail é obrigatório " })
        return
      }
    }

    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    const infos = {
      nfeFinId: nfeFinId,
      userId: this.sessionService.loggedUser.id,
      status: negativado,
      telefone: this.telefoneNegativacao,
      email: this.emailNegativacao
    }
    this.httpService.wait()
    this.httpClient.post(this.configService.octaremoteUrl + "/asaas/negativacao", infos, httpOptions)
      .subscribe(
        res => {
          if (res["codigo"] === 500) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] })
          else if (res["codigo"] === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res["message"] })
          else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res["message"] })
            this.buscarInfosCobranca()
            this.modalNegativar = false
          }
          this.httpService.done()
        },
        err => {
          if (negativado) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao cancelar a negativação, contate o suporte: " + err.message })
          else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao negativar a cobrança, contate o suporte: " + err.message })
          this.httpService.done()
        }
      )
  }

  abrirModalNegativacao() {
    this.emailNegativacao = null
    this.telefoneNegativacao = null
    this.modalNegativar = true
  }

  cancelarAssinatura() {
    if (!confirm("Deseje realmente cancelar a assinatura? Essa ação é irreversível.")) return

    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    this.httpService.wait()
    this.httpClient.delete(this.configService.octaremoteUrl + "/asaas/assinatura?nfeFinId=" + this.rowsSelected[0].id, httpOptions)
      .subscribe(
        res => {
          if (res["codigo"] === 500) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] })
          else if (res["codigo"] === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res["message"] })
          else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res["message"] })
            this.buscarInfosCobranca()
          }
          this.httpService.done()
        },
        err => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao cancelar a assinatura, contate o suporte: " + err.message })
          this.httpService.done()
        }
      )
  }

  async getPersonalizedStatus(): Promise<void> {
    try {
      await this.httpService.get('/statusaprovacao?$select=descricao&$filter=statusPedido eq true and empresa/id eq '+ this.dblinkedSessionService.empresa.id).toPromise().then(res => {
        res.value.forEach(line => this.listApprovalStatus.push(line.descricao))
      })

    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar os status de aprovação! Contate o suporte' })
    }
  }

  getChavesPixParticipante(participanteId) {
    this.chavePixSelecionada = null

    this.httpService.wait()
    this.httpService.get(`/custom/cadastro/buscar-chave-pix-participante(${participanteId})`)
    .subscribe(res => {
      this.chavesPixParticipante = []
      res.data.forEach(chavePix => {
        this.chavesPixParticipante.push({
          label: `${chavePix[2]} - ${chavePix[3]}`,
          value: chavePix[0]
        })
      })
    }, error => this.httpService.handleError(error),
      () => this.httpService.done());
  }

  recuperaComprovantes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    }

    const payloadPagamentos = { pagamentos: this.rowsSelected }

    this.httpService.wait()
    this.httpClient.post(this.configService.octaremoteUrl  + '/asaas/recuperar-comprovantes', payloadPagamentos, httpOptions)
    .subscribe(
      res => {
        this.comprovantes = res["data"]
        if (this.comprovantes[this.rowsSelected[0].id] === null || this.comprovantes[this.rowsSelected[0].id] === undefined) {
          this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Advertência', detail: "O pagamento selecionado não possui um comprovante disponível."})
        }
        else window.open(this.comprovantes[this.rowsSelected[0].id], '_blank')
      },
      err => {
        if (err.status !== 400) {
          this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: "Erro ao recuperar os comprovantes de pagamento, contate o suporte!"})
        }
      }
    )
    this.httpService.done()
  }

  verificaComprovante() {
    if ((this.rowsSelected[0].statusAprovacaoId === 136 || ((this.rowsSelected[0].statusAprovacaoId === 10 || this.rowsSelected[0].statusAprovacaoId === 7) && this.rowsSelected[0].status === 'BAIXADO')) &&
         (this.rowsSelected[0].empresaContaId === this.contaBancariaAsaasId)) return true

    return false
  }
}
