import { Empresa } from 'app/cadastro/model/empresa.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { TipoConta } from 'app/util/model/tipo-conta.model';
import { InstituicaoFinanceira } from 'app/util/model/instituicao-financeira.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Moeda } from 'app/util/model/moeda.model';
import { TipoChavePix } from 'app/util/model/tipo-chave-pix.model';


export class EmpresaConta {
  id: number;
  empresa: Empresa;
  nome: string;
  tipoConta: TipoConta;
  instituicaoFinanceira: InstituicaoFinanceira;
  agencia: string;
  dvAgencia: string;
  conta: string;
  dvConta: string;
  saldoInicial: number;
  saldoAtual: number;
  planoContas: PlanoContas;
  dtInicial: Date;
  dtFinal: Date;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;
  ativo      : boolean;
  pagamentosautomatizados: boolean;
  convenio: string;
  moeda: Moeda;
  codCtaPlanoContas: string;
  codigoTransmissao: string
  chavePix: string
  tipoChavePix: TipoChavePix

  constructor(empresaConta?: EmpresaConta | number) {
    if (empresaConta !== undefined && empresaConta !== null) {
      if (typeof empresaConta === 'number') {
        this.id = empresaConta;
      } else {
        this.id = empresaConta.id;
        this.empresa = empresaConta.empresa;
        this.nome = empresaConta.nome;
        this.tipoConta = empresaConta.tipoConta;
        this.instituicaoFinanceira = empresaConta.instituicaoFinanceira;
        this.agencia = empresaConta.agencia;
        this.dvAgencia = empresaConta.dvAgencia;
        this.conta = empresaConta.conta;
        this.dvConta = empresaConta.dvConta;
        this.saldoInicial = empresaConta.saldoInicial;
        this.saldoAtual = empresaConta.saldoAtual;
        this.planoContas = empresaConta.planoContas;
        this.dtInicial = empresaConta.dtInicial;
        this.dtFinal = empresaConta.dtFinal;
        this.createTimestamp = empresaConta.createTimestamp;
        this.changeUser = empresaConta.changeUser;
        this.changeTimestamp = empresaConta.changeTimestamp;
        this.ativo       = empresaConta.ativo;
        this.pagamentosautomatizados = empresaConta.pagamentosautomatizados;
        this.convenio = empresaConta.convenio;
        this.moeda = empresaConta.moeda;
        this.codCtaPlanoContas = empresaConta.codCtaPlanoContas;
        this.codigoTransmissao = empresaConta.codigoTransmissao
        this.chavePix = empresaConta.chavePix
        this.tipoChavePix = empresaConta.tipoChavePix
      }
    }
  }
}
