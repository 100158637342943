import { Empresa } from '../../cadastro/model/empresa.model';

export class CadHistPadrao {
  id: number;
  codHist: string;
  descrHist: string;
  empresa: Empresa;
  dtInicial: Date;
  dtFinal: Date;

  label: string;

  constructor(cadHistPadrao?: CadHistPadrao | number) {
    if (cadHistPadrao !== undefined && cadHistPadrao !== null) {
      if (typeof cadHistPadrao === 'number') {
        this.id = cadHistPadrao;
      } else {
        this.id = cadHistPadrao.id;
        this.codHist = cadHistPadrao.codHist;
        this.descrHist = cadHistPadrao.descrHist;
        this.empresa = cadHistPadrao.empresa;
        this.dtInicial = cadHistPadrao.dtInicial;
        this.dtFinal = cadHistPadrao.dtFinal;
      }
    }
  }
}
