import { Usuario } from 'app/cadastro/model/usuario.model';
import { Empresa } from 'app/cadastro/model/empresa.model';

export class DirfDeclaracao {
  id: number;
  empresa: Empresa = new Empresa();
  layout: any;
  exported = false;
  changeDatetime: Date;
  anoReferencia: number;
  anoCalendario: number;
  responsavelCpf: string;
  naturezaJuridica: any = {};
  socioOstensivo = false;
  depositoJudicial = false;
  clubeInvestimento = false;
  rendimentosExterior = false;
  planoSaude = false;
  olimpiadas2016 = false;
  situacaoEspecial = false;
  recursoTesouroNacional = false;
  fundacaoPublica = false;
  situacaoEspecialData: Date | number | string;
  declaracaoOriginal: DirfDeclaracao;
  envio: Date;
  recibo: string;
  historico: Array<any>;
  usuario: Usuario;

  constructor(declaracao?: DirfDeclaracao | number) {
    if (declaracao) {
      if (typeof declaracao === 'number') {
        this.id = declaracao;
      } else {
        this.id = declaracao.id;
        this.empresa = new Empresa(declaracao.empresa);
        this.layout = declaracao.layout;
        this.exported = declaracao.exported || false;
        this.changeDatetime = declaracao.changeDatetime/* ? new Date(declaracao.changeDatetime) : null*/;
        this.anoReferencia = declaracao.anoReferencia;
        this.anoCalendario = declaracao.anoCalendario;
        this.responsavelCpf = declaracao.responsavelCpf;
        this.naturezaJuridica = declaracao.naturezaJuridica || {};
        this.socioOstensivo = declaracao.socioOstensivo || false;
        this.depositoJudicial = declaracao.depositoJudicial || false;
        this.clubeInvestimento = declaracao.clubeInvestimento || false;
        this.rendimentosExterior = declaracao.rendimentosExterior || false;
        this.planoSaude = declaracao.planoSaude || false;
        this.olimpiadas2016 = declaracao.olimpiadas2016 || false;
        this.situacaoEspecial = declaracao.situacaoEspecial || false;
        this.situacaoEspecialData = declaracao.situacaoEspecialData;
        this.recursoTesouroNacional = declaracao.recursoTesouroNacional || false;
        this.fundacaoPublica = declaracao.fundacaoPublica || false;
        /*if (this.situacaoEspecialData) {
          const utilService = new UtilService();
          this.situacaoEspecialData = utilService.timestampToInputFieldDate(+this.situacaoEspecialData);
        }*/
        this.declaracaoOriginal = declaracao.declaracaoOriginal;
        this.recibo = declaracao.recibo;
        this.historico = declaracao.historico;
        this.usuario = new Usuario(declaracao.usuario);
      }
    }
  }

  get retificadora(): boolean {
    return this.declaracaoOriginal !== undefined && this.declaracaoOriginal !== null;
  }

  get status(): string {
    if (this.recibo) {
      return 'Concluída';
    }

    if (this.envio) {
      return 'Enviada';
    }

    if (this.exported) {
      return 'Processada';
    }

    return 'Pendente';
  }
}
