import { Component, OnInit, OnDestroy } from '@angular/core';

import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Empresa } from '../model/empresa.model';
import { EmpresaConta } from '../model/empresa-conta.model';
import { HttpService } from 'app/shared/services/http.service';
import { InstituicaoFinanceira } from 'app/util/model/instituicao-financeira.model';
import { Message, MessageService } from 'primeng/api';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { TipoConta } from 'app/util/model/tipo-conta.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { isNullOrUndefined } from 'util';
import { GrupoTributacao } from '../model/grupo-tributacao.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Mva } from 'app/fiscal/model/mva.model';
declare var $: any;

@Component({
  selector: 'app-mva',
  templateUrl: './mva.component.html',
  styleUrls: [
    './mva.component.scss'
  ],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class MvaComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {}

  private sessionSubscription: Subscription; // change session event;

  title = '';
  subtitle = '';

  lista: any;
  mva: any;
  ncmsRelacionados: any;
  ncmsRelacionar: any;
  ufsRelacionadas: any;
  ufsRelacionar: any;

  // components;
  showLista = false;
  showNew = false;
  editar = false;
  mostrarInativos = false
  mostrarMaisOpcoes: boolean = true

  // msgs;
  msgs: Message[] = [];

  defaultDate: Date;
  pt_BR: any;

  // expand and hide card
  currentStateSelectItem= 'initial';
  currentStateItemSelecionado= 'initial';
  currentStateItens= 'initial';
  currentStateAliquota = 'initial';

  tipisOptions: SelectItem[] = new Array()
  tipiCapituloSelecionado: string = ''
  loadingTipis: boolean = false
  listaNcms: Array<any> = new Array()

  changeStateSelectItem() {
    this.currentStateSelectItem = this.currentStateSelectItem === 'initial' ? 'final' : 'initial';
  }

  changeStateItemSelecionado() {
    this.currentStateItemSelecionado = this.currentStateItemSelecionado === 'initial' ? 'final' : 'initial';
  }

  changeStateItens() {
    this.currentStateItens = this.currentStateItens === 'initial' ? 'final' : 'initial';
  }

  changeStateAliquota() {
    this.currentStateAliquota = this.currentStateAliquota === 'initial' ? 'final' : 'initial';
  }

  public toolbarMain = new Toolbar()
  public toolbarMainItemS = new Toolbar()
  public toolbarMainItemD = new Toolbar()
  public toolbarMainA = new Toolbar()

  toolbarMainIni = [
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.criarGrupoTributacao() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideSelectItem()
        this.changeStateSelectItem()
      }
    },
  ]

  toolbarMainIniItemS = [
    { icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: false, color: 'default', onClick: () => this.voltarListaItens() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'default', onClick: () => this.salvarGrupoTributacao() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideItemSelecionado()
        this.changeStateItemSelecionado()
      }
    },
  ]

  toolbarMainIniItemD = [
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideItens()
        this.changeStateItens()
      }
    },
  ]
  toolbarMainIniA = [
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideAliquota()
        this.changeStateAliquota()
      }
    },
  ]


  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarMainItemS.setAll(this.toolbarMainIniItemS)
    this.toolbarMainItemD.setAll(this.toolbarMainIniItemD)
    this.toolbarMainA.setAll(this.toolbarMainIniA)
    this.toolbarService.hidden = true
    this.dblinkedSessionService.hidePeriods();
    Promise.resolve(null).then(() => (this.toolbarService.hidden = true));
    this.title = 'MVA';
    this.editar = false;
    this.mva = new Mva();
    this.mva.id = 0;
    this.mva.nome = '';
    this.mva.valor = '';
    this.mva.ncm = new Array();
    this.mva.uf = new Array();
    this.mva.ativo = true

    this.ncmsRelacionados = new Array();
    this.ncmsRelacionar = new Array();
    this.ufsRelacionadas = new Array();
    this.ufsRelacionar = new Array();

    this.listaNcms = new Array()

    this.mostrarInativos = false
    this.mostrarMaisOpcoes = true

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
      }
    );

    this.defaultDate = new Date();
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };

  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  private changeSession() {
    this.resetarVariaveis()

    if (!this.dblinkedSessionService.hasEmpresaSelected) return

    this.editar = false
    this.mva.empresa = new Empresa()
    this.mva.empresa.id = this.dblinkedSessionService.empresa.id

    this.getMva()
  }

  getMva() {
    this.httpService.wait()
    this.httpService.get('custom/cadastro/get-mva', [this.dblinkedSessionService.empresa.id, this.mostrarInativos]).subscribe(
      response => this.lista = response,
      error => this.httpService.handleError(error, () => this.changeSession()),
      () => this.httpService.done()
    )
  }


  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() { }

  verMva(value: any){
    this.resetarVariaveis()
    this.editar = true
    this.getOptTipis()
    this.mva = new Mva()
    this.mva.id = value.id
    this.mva.nome = value.nome
    this.mva.valor = value.valor
    this.mva.ativo = value.ativo
    this.getRelacMva()
    value.ncmsRelacionadas.forEach(element => { this.ncmsRelacionados.push(element) })
    value.ufsRelacionados.forEach(element => { this.ufsRelacionadas.push(element) })
   }

  /*Show Hide isCardItens*/
  isCardSelectItem: boolean = true;
  ShowHideSelectItem(){
    this.isCardSelectItem = !this.isCardSelectItem;
    this.toolbarMain.setIcon('btn4', this.isCardSelectItem ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn4', this.isCardSelectItem ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide isCardItens*/
  isCardItemSelecionado: boolean = true;
  ShowHideItemSelecionado(){
    this.isCardItemSelecionado = !this.isCardItemSelecionado;
    this.toolbarMainItemS.setIcon('btn4', this.isCardItemSelecionado ? 'expand_less' : 'expand_more')
    this.toolbarMainItemS.setTooltip('btn4', this.isCardItemSelecionado ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide isCardItens*/
  isCardItens: boolean = true;
  ShowHideItens(){
    this.isCardItens = !this.isCardItens;
    this.toolbarMainItemD.setIcon('btn1', this.isCardItens ? 'expand_less' : 'expand_more')
    this.toolbarMainItemD.setTooltip('btn1', this.isCardItens ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide isCardItens*/
  isCardAliquota: boolean = true;
  ShowHideAliquota(){
    this.isCardAliquota = !this.isCardAliquota;
    this.toolbarMainA.setIcon('btn1', this.isCardAliquota ? 'expand_less' : 'expand_more')
    this.toolbarMainA.setTooltip('btn1', this.isCardAliquota ? 'Ver menos' : 'Ver mais')
  }

  voltarListaItens() {
    this.editar = false

    this.resetarVariaveis()
  }

  salvarGrupoTributacao(){
    const confirmarSalvar = confirm('Deseja salvar este mva?')

    if(!confirmarSalvar) return

    let ncms = []

    this.ncmsRelacionados.forEach(element => {
      ncms.push(element.id)
    })

    let ufs = []

    this.ufsRelacionadas.forEach(element => {
      ufs.push(element.id)
    })

    const listasEnviar = {
      mva: this.mva,
      ncmsRelacionadas: ncms,
      ufsRelacionados: ufs,
      usuarioId: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait()
    this.httpService.post('/custom/cadastro/salvar-mva', listasEnviar).subscribe(
      response =>  {
        if(!response.error){
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Msg: ', detail: response.message})
          this.changeSession()
        } else {
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: response.message})
        }
      },
      error => this.httpService.handleError(error, () => this.salvarGrupoTributacao()),
      () => this.httpService.done()
    )
  }

  criarGrupoTributacao(){
    this.editar = true
    this.mva = new Mva()
    this.mva.id = 0
    this.mva.nome = ''
    this.mva.valor = ''
    this.mva.ufs = new Array()
    this.mva.ncms = new Array()
    this.ncmsRelacionados = new Array()
    this.ufsRelacionadas = new Array()
    this.mva.empresa = new Empresa()
    this.mva.empresa.id = this.dblinkedSessionService.empresa.id
    this.mva.ativo = true

    this.getOptTipis()
    this.getRelacMva()
  }

  private getRelacMva() {
    this.httpService.wait()
    this.httpService.get('custom/cadastro/get-mva-relac', [this.dblinkedSessionService.empresa.id, this.mva.id]).subscribe(
      response => {
        this.listaNcms = new Array()
        this.ufsRelacionar = new Array()

        this.listaNcms = response.ncmsRelacionar
        this.ufsRelacionar = response.ufsRelacionar
      },
      error => this.httpService.handleError(error, () => this.changeSession()),
      () => this.httpService.done()
    )
  }

  private async getOptTipis(): Promise<void> {
    this.loadingTipis = true

    try {
      await this.httpService.get('/custom/financeiro/capitulos-tipi').toPromise().then(res => {
        this.tipisOptions = new Array()
        res.forEach(item => { this.tipisOptions.push({ label: (item.Tipis ? (item.Tipis + ' - ') : '') + item.descricao, value: item.capitulo }) })
      }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Msg: ', detail: 'Houve um erro ao carregar os tipis! Contate o suporte'}))
    } catch (err) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!'})
    } finally {
      this.loadingTipis = false
    }
  }

  filtrarNcms() {
    this.ncmsRelacionar = new Array()
    this.ncmsRelacionar = this.listaNcms.filter(item => (item.capitulo === this.tipiCapituloSelecionado))
  }

  resetarVariaveis() {
    this.ncmsRelacionados = new Array()
    this.ncmsRelacionar = new Array()
    this.ufsRelacionadas = new Array()
    this.ufsRelacionar = new Array()
    this.listaNcms = new Array()
    this.mva = new Mva()
    this.tipiCapituloSelecionado = null
    this.mostrarInativos = false
  }
}