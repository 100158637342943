import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ConfigService } from '../../shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { UtilService } from '../../shared/services/util.service';
import { Toolbar } from 'app/shared/model/toolbar.model';
import exportExcel from 'app/util/processing/exportExcel';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { MaquinaCartao } from './maquina-cartao.interface';

@Component({
  selector: 'app-maquina-cartao',
  templateUrl: './maquina-cartao.component.html',
  styleUrls: ['./maquina-cartao.component.scss']
})
export class MaquinaCartaoComponent implements OnInit, OnDestroy {


  constructor(
    protected toolbarService: ToolbarService,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private utilService: UtilService,
    private messageService: MessageService
  ) { this.toolbarService.hidden = true }

  title: string;
  tituloModal: string;
  tituloBotaoModal: string;

  maqSelecionadas: Array<any> = new Array()
  dadosTabela: Array<any> = new Array()

  tipoModal: string;
  mostrarModal: boolean;
  checkboxDesativada: boolean = false;

  empresaId: number;
  userId: number;

  maquinaCartao: MaquinaCartao;
  boolEditar: boolean;

  listaConta: SelectItem[];

  requisicaoContaCarregando: boolean;

  private sessionSubscription: Subscription;

  toolbarMainIni = [
    { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.prepararEditarMaquina()},
    { key: 'btn-delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirMaquinas() },
    { key: 'btn-new', icon: 'add', text: 'Nova', tooltip: 'Nova requisição', visible: true, disabled: false, color: 'green', onClick: () => this.prepararCriarMaquina() },
    { key: 'btn-loading', icon: 'hourglass_full', text: 'Carregando...', tooltip: '', visible: false, disabled: true, color: 'default', onClick: () => {} }
  ]
  btnArr: Array<string> = ['btn-new', 'btn-delete', 'btn-edit']
  public toolbarMain = new Toolbar()

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'CÓDIGO', size: '15%', textAlign: 'center' },
      { name: 'DESCRIÇÃO', size: '30%', textAlign: 'center' },
      { name: 'IDENTIFICAÇÃO EXTERNA', size: '15%', textAlign: 'center' },
      { name: 'CONTA BANCÁRIA RELACIONADA', size: '40%', textAlign: 'center' },
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true,
  })

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.title = 'Cadastro de máquinas de cartão';
    this.tituloModal = '';

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,() => {
      if(this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
        this.empresaId = this.dblinkedSessionService.empresa.id
        this.userId = this.sessionService.loggedUser.id
        this.reiniciarTabela()
        this.buscarListaConta()
      }
    })
  }

  reiniciarTabela() {
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.maqSelecionadas = []
    this.dadosTabela = []
    this.receberMaquinas()
    this.atualizarToolbar()
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  async receberMaquinas(event: LazyLoadEvent = {}): Promise<void> {

    this.skeletonConfig.startLoad()

    try {
      await this.httpService.get('custom/cadastro/listar-maquina-cartao('+this.empresaId+')').toPromise().then(res => {
        this.dadosTabela = res.itens

        this.dadosTabela.forEach(maquina => {
          maquina.conta_nome = this.listaConta.find((conta) => {return conta.value == maquina.conta_id}).label
        });
      }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao carregar as maquinas de cartão! Contate o suporte'}))
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

  public dataPadraoReq(data: Date): String {
    return data.toISOString().split('T')[0]
  }

  private async excluirMaquinas():Promise<void> {
    if (confirm('Deseja excluir as maquinas de cartão selecionadas?')) {
      const payload = []

      this.maqSelecionadas.forEach(maquina => {
        payload.push({
          maquina_id: maquina.id,
          user_id: this.userId,
          empresa_id: this.empresaId
        })
      })

      this.toolbarCarregando()
      this.checkboxDesativada = true

      try {
        await this.httpService.post('/custom/cadastro/desativar-maquina-cartao', payload).toPromise().then(res => {
          if(!res.error) this.receberMaquinas()
          this.messageService.add({ severity: (res.error ? 'error' : 'success'), key: 'messageLinnks', summary: (res.error ? 'Erro' : 'Sucesso'), detail: (res.error ? res.message : 'Registro excluido com sucesso')})
        }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao deletar as maquinas de cartão! Contate o suporte'}))
      } catch(err) {
        this.messageService.add({severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!'})
      } finally {
        this.checkboxDesativada = false;
        this.reiniciarTabela()
      }
    }
  }

  public formatDateExtensive(): String {
    const dt = new Date()
    const monthExtensive = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const weekExtensive = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]

    const month = monthExtensive[dt.getMonth()]
    const week = weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }

  private toolbarCarregando(): void {

    this.btnArr.forEach(item => {
      this.toolbarMain.setVisible(item, false)
    })
    this.toolbarMain.setVisible('btn-loading', true)
  }

  fecharModal() {
    this.mostrarModal = false;
  }

  concluirModal() {
    if(this.tipoModal === 'criar'){
      this.criarMaquina()
    }
    else {
      this.editarMaquina()
    }
  }

  private prepararCriarMaquina() {
    this.mostrarModal = true;
    this.tipoModal = "criar";

    this.tituloModal = 'Criar máquina de cartão';
    this.tituloBotaoModal = 'Salvar';

    this.maquinaCartao = {
      codigo: '',
      descricao: '',
      externoId: '',
      contaBancoId: 0,
    }
  }

  private prepararEditarMaquina() {
    this.mostrarModal = true;
    this.tipoModal = "editar";

    this.tituloModal = 'Editar máquina de cartão';
    this.tituloBotaoModal = 'Salvar';
    this.maquinaCartao = {
      codigo: this.maqSelecionadas[0].codigo,
      descricao: this.maqSelecionadas[0].descricao,
      externoId: this.maqSelecionadas[0].externo_id,
      contaBancoId: this.listaConta.find((conta) => {return conta.value == this.maqSelecionadas[0].conta_id}).value,
    }
  }

  private criarMaquina() {

    if (!this.validacaoModal()) return

    const payload = {
      cod_maquina: this.maquinaCartao.codigo,
      desc_maquina: this.maquinaCartao.descricao,
      externo_id: this.maquinaCartao.externoId,
      conta_bancaria_id: this.maquinaCartao.contaBancoId,
      user_id: this.userId,
      empresa_id: this.empresaId
    }

    try {
      this.httpService.wait();
      this.httpService.post('/custom/cadastro/criar-maquina-cartao', payload)
      .subscribe(
        res => {
          if (res.error) {
            if(typeof res.value == "string") {
              this.messageService.add({
                severity: 'error', key: 'messageLinnks',
                summary: 'Erro: ',
                detail: res.value
              });
            }
            else {
              res.message.forEach(msg => {
                this.messageService.add({
                  severity: 'error', key: 'messageLinnks',
                  summary: 'Erro: ',
                  detail: msg.message
                });
              });
            }
          }
          else {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'Sucesso: ',
              detail: 'Registro salvo com sucesso'
            });
            this.reiniciarTabela()
            this.fecharModal()
          }
        },
        error => this.httpService.handleError(error, () => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: error.status + ' - Houve um erro ao salvar a máquina de cartão! Contate o suporte'})),
        () => this.httpService.done()
      )
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }

  private editarMaquina() {
    if (!this.validacaoModal()) return

    const payload = {
      maquina_id: this.maqSelecionadas[0].id,
      cod_maquina: this.maquinaCartao.codigo,
      desc_maquina: this.maquinaCartao.descricao,
      externo_id: this.maquinaCartao.externoId,
      conta_bancaria_id: this.maquinaCartao.contaBancoId,
      user_id: this.userId,
      empresa_id: this.empresaId
    }

    try {
      this.httpService.wait();
      this.httpService.post('/custom/cadastro/editar-maquina-cartao', payload)
      .subscribe(
        res => {
          if (res.error) {
            if(typeof res.value == "string") {
              this.messageService.add({
                severity: 'error', key: 'messageLinnks',
                summary: 'Erro: ',
                detail: res.value
              });
            }
            else {
              res.message.forEach(msg => {
                this.messageService.add({
                  severity: 'error', key: 'messageLinnks',
                  summary: 'Erro: ',
                  detail: msg.message
                });
              });
            }
          }
          else {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'Sucesso: ',
              detail: 'Registro editado com sucesso'
            });
            this.reiniciarTabela()
            this.fecharModal()
          }
        },
        error => this.httpService.handleError(error, () => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: error.status + ' - Houve um erro ao editar a máquina de cartão! Contate o suporte'})),
        () => this.httpService.done()
      )
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }

  async buscarListaConta(): Promise<void> {
    try {
      this.requisicaoContaCarregando = true
      await this.httpService.get('/empresaconta?$select=id,nome,tipoConta/id,agencia,dvAgencia,conta,dvConta,tipoConta/descricao&$filter=empresa/id eq ' + this.empresaId + ' and ativo=true&$orderby=nome')
      .toPromise().then(res => {
        this.listaConta = []
        res.value.forEach(conta => {
          if(conta.tipoConta.id !== 10) {
            let label: string = conta.id + " - " + conta.nome
            if (conta.agencia) label += " - Ag: " + conta.agencia
            if (conta.dvAgencia) label += "-" + conta.dvAgencia
            if (conta.conta) label += " - CC: " + conta.conta
            if (conta.dvConta) label += "-" + conta.dvConta
            label += " - " + conta.tipoConta.descricao
            this.listaConta.push({ label: label, value: conta.id })
          }
        });
      }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao carregar a lista de contas! Contate o suporte'}))
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.requisicaoContaCarregando = false
    }
  }

  public get rowsPerPage(): Array<any> {
    return this.configService.applicationConfig.rowsPerPage
  }

  public get rows(): number {
    return this.configService.applicationConfig.rows
  }

  public get pageLinks(): number {
    return this.configService.applicationConfig.pageLinks
  }

  public atualizarToolbar(): void {
    this.toolbarMain.setVisible('btn-delete', this.maqSelecionadas.length > 0)
    this.toolbarMain.setVisible('btn-edit', this.maqSelecionadas.length === 1)

    this.toolbarMain.setVisible('btn-loading', false)
  }

  validacaoModal(): boolean {
    if(this.maquinaCartao.codigo == '') {
      this.messageService.add({ severity: 'error', key: 'messageLinnks',
        summary: 'Erro', detail: 'Preencha o código!' })
      return false;
    }
    if(this.maquinaCartao.descricao == '') {
      this.messageService.add({ severity: 'error', key: 'messageLinnks',
        summary: 'Erro', detail: 'Preencha a descrição!' })
      return false;
    }
    if(this.maquinaCartao.externoId == '') {
      this.messageService.add({ severity: 'error', key: 'messageLinnks',
        summary: 'Erro', detail: 'Preencha o Identificador externo!' })
      return false;
    }
    if(this.maquinaCartao.contaBancoId == 0) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks',
        summary: 'Erro', detail: 'Selecione uma conta bancária!' })
      return false;
    }
    return true;
  }

}
