export class IndGrandePorte {
  id: number;
  cod: string;
  descricao: string;

  constructor(indgrandeporte?: IndGrandePorte | number) {
    if (indgrandeporte !== undefined && indgrandeporte !== null) {
      if (typeof indgrandeporte === 'number') {
        this.id = indgrandeporte;
      } else {
        this.id = indgrandeporte.id;
        this.cod = indgrandeporte.cod;
        this.descricao = indgrandeporte.descricao;
      }
    }
  }
}
