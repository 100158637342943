import { Component, OnDestroy, OnInit } from '@angular/core'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { Integration } from '../interfaces/Integration'
import { SessionService } from 'app/shared/services/session.service'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { Subscription } from 'rxjs'
import { HttpService } from 'app/shared/services/http.service'
import { MessageService, SelectItem } from 'primeng/api'
import idv4 from 'app/util/processing/idv4'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ConfigService } from 'app/shared/services/config.service'

@Component({
  selector: 'app-cfg-api-client',
  templateUrl: './cfg-api-client.component.html',
  styleUrls: ['./cfg-api-client.component.scss']
})

export class CfgApiClientComponent implements OnInit, OnDestroy {

  protected sessionSubscription: Subscription

  integrationsList: Array<Integration> = new Array()
  optCompanyAccounts: SelectItem[] = new Array()
  optAPIs: SelectItem[] = new Array()

  httpOptions: Object = new Object()
  logoIntegrationList: Object = new Object()
  classIntegrationList: Object = new Object()

  isLoadingIntegrationsList: boolean = false
  loadingOptCompanyAccounts: boolean = false
  loadingIconOptCompanyAccounts: boolean = false
  loadingOptAPIS: boolean = false
  loadingIconOptAPIS: boolean = false
  btnLoading: boolean = false
  isValidMatera: boolean = false

  empresaId: number = null
  userId: number = null

  defaultDate: Date = new Date()

  constructor(
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private messageService: MessageService,
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { this.toolbarService.hidden = true }

  public ngOnInit(): void {
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,() => {
      if(this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) this.initialize()
    })
  }

  public ngOnDestroy(): void {
    this.sessionSubscription.remove(this.sessionSubscription)
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  private async initialize(): Promise<void> {
    this.resetVars()

    await Promise.all([
      this.getIntegrations(),
      this.getOptCompanyAccounts()
    ])
  }

  private resetVars(): void {
    this.empresaId = this.dblinkedSessionService.empresa.id
    this.userId = this.sessionService.loggedUser.id
    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }

    this.optCompanyAccounts = new Array()

    this.loadingOptCompanyAccounts = false
    this.loadingIconOptCompanyAccounts = false

    this.setAvailableReferences()
  }

  private setAvailableReferences(): void {
    this.logoIntegrationList = {
      'FOX': 'assets/integration-banking-logo/icon-foxbit.png',
      'MAT': 'assets/integration-banking-logo/icon-matera.png',
      'EYE': 'assets/integration-banking-logo/icon-eye.png',
      'CON': 'assets/integration-banking-logo/icon-conciliadora.png',
      'BS2': 'assets/integration-banking-logo/icon-bs2.png',
      'PLVRE': 'assets/integration-banking-logo/icon-paylivre.png',
      'ASAAS': 'assets/integration-banking-logo/icon-asaas.png',
      'TRAY': 'assets/integration-banking-logo/icon-tray.png'
    }

    this.classIntegrationList = {
      'FOX': 'bg-foxbit',
      'MAT': 'bg-matera',
      'EYE': 'bg-eye',
      'CON': 'bg-conciliadora',
      'BS2': 'bg-bs2',
      'PLVRE': 'bg-paylivre',
      'ASAAS': 'bg-asaas',
      'TRAY': 'bg-tray'
    }

    this.optAPIs = [
      { label: 'Nenhuma', value: null },
      { label: 'BS2', value: 'BS2' },
      { label: 'Conciliadora', value: 'CON' },
      { label: 'Eye Mobile', value: 'EYE' },
      { label: 'Matera', value: 'MAT' },
      { label: 'PayLivre', value: 'PLVRE' },
      { label: 'Asaas', value: 'ASAAS' },
      { label: 'Tray', value: 'TRAY' }
    ]
  }

  private async getIntegrations(): Promise<void> {
    this.isLoadingIntegrationsList = true

    try {
      await this.httpClient.get(this.configService.octaremoteUrl + `/cadastro/configuracao-api?empresaId=${this.empresaId}`, this.httpOptions).toPromise().then(res => {
        this.integrationsList = new Array()
        res['data'].forEach(item => {

          const startDate: Date = new Date(item.data_inicial)
          startDate.setHours(startDate.getHours() + 4)

          this.integrationsList.push({ id: item?.id, lineId: idv4(), name: item?.descricao, integrationCode: item?.codigo, companyAccount: item?.conta_bancaria_id, startDate: startDate,  isSave: false, isLoading: false })
        })
      })
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isLoadingIntegrationsList = false
    }
  }

  public async saveIntegration(integration: Integration): Promise<void> {
    this.validationIntegration(integration)

    const payload = {
      empresa_id: this.empresaId,
      descricao: integration.name,
      codigo: integration.integrationCode,
      empresa_conta_id: integration.companyAccount,
      data_inicial: this.formatStartDate(integration.startDate),
      user_id: this.userId
    }

    integration.isLoading = true

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/configuracao-api', payload, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          this.integrationsList.forEach(item => { if(item.lineId === integration.lineId) item.lineId = idv4(), item.id = res['data'] })
          this.setSaveIntegration(integration.lineId, true)
        }
        this.showResponse((res['codigo'] === 200) ? 'success' : 'error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar sua configuração de API! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      integration.isLoading = false
    }
  }

  private async getOptCompanyAccounts(): Promise<void> {
    this.loadingOptCompanyAccounts = true
    this.loadingIconOptCompanyAccounts = true

    try {
      await this.httpService.get('/empresaconta?$select=id,nome,tipoConta/id,agencia,dvAgencia,conta,dvConta,tipoConta/descricao&$filter=empresa/id eq ' + this.empresaId + ' and ativo=true&$orderby=nome').toPromise().then(res => {
        this.optCompanyAccounts = [{ label: 'Nenhuma', value: null }]
        res.value.forEach(item => {
          if(item.tipoConta.id !== 10) {
            let label: string = item.id + " - " + item.nome
            if (item.agencia) label += " - Ag: " + item.agencia
            if (item.dvAgencia) label += "-" + item.dvAgencia
            if (item.conta) label += " - CC: " + item.conta
            if (item.dvConta) label += "-" + item.dvConta
            label += " - " + item.tipoConta.descricao
            this.optCompanyAccounts.push({ label: label, value: item.id })
          }
        })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as contas da empresa! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptCompanyAccounts = false
      this.loadingIconOptCompanyAccounts = false
    }
  }

  public addNewIntegration(): void {
    let newIntegration: Integration = { id: null, lineId: `new-${idv4()}`, name: 'Nova configuração', integrationCode: null, companyAccount: null, startDate: new Date(), isSave: false, isOpen: true, isLoading: false }

    this.integrationsList.push({...newIntegration})
  }

  public openCardIntegration(integrationLineId: string): void {
    this.integrationsList.forEach(item => {
      if(item.lineId === integrationLineId) {
        item.isOpen = !item.isOpen
      }
    })
  }

  public setCompanyAccountLabel(companyAccountId: number): string {
    let response: string = null, findCompany: SelectItem

    findCompany = this.optCompanyAccounts.find(line => line.value === companyAccountId)
    response = findCompany ? findCompany.label : 'Nenhuma'
    return response
  }

  public setIntegrationLabel(integrationCode: string): string {
    let response: string = null, references: SelectItem[] = [...this.optAPIs]

    response = references.find(line => line.value === integrationCode).label
    return response
  }

  public setLogoImage(integrationCode: string): string {
    let response: string = null, findImage: string = null

    findImage = this.logoIntegrationList[integrationCode]
    response = findImage ? findImage : null
    return response
  }

  public setCssClass(integrationCode: string): string {
    let response: string = null, findClass: string = null

    findClass = this.classIntegrationList[integrationCode]
    response = findClass ? findClass : 'bg-none'
    return response
  }

  public setShowRelatedAccount(integrationCode: string): boolean {
    let response: boolean = false

    response = [null, 'EYE', 'CON', 'PLVRE', 'TRAY'].includes(integrationCode) ? false : true

    return response
  }

  public setShowRelatedStartDate(integrationCode: string): boolean {
    let response: boolean = false

    response = [null, 'EYE', 'CON', 'PLVRE', 'ASAAS', 'TRAY'].includes(integrationCode) ? false : true

    return response
  }

  public setIsTimer(integrationCode: string): boolean {
    let response: boolean = false

    response = [null, 'MAT'].includes(integrationCode) ? false : true

    return response
  }

  public setAPIsAvailable(integrationLineId: string): SelectItem[] {
    let references: SelectItem[] = [...this.optAPIs]

    this.integrationsList.forEach(item => {
      if(item.lineId !== integrationLineId && item.integrationCode !== null) references = references.filter(line => line !== references.find(elem => elem.value === item.integrationCode))
    })

    return references
  }

  public setSaveIntegration(integrationLineId: string, condition: boolean): void {
    setTimeout(() => {
      this.integrationsList.forEach(item => {
        if(item.lineId === integrationLineId) item.isSave = condition
      })
    }, 1)
  }

  public setValidForm(integrationLineId: string, event: any): void {
    setTimeout(() => {
      this.integrationsList.forEach(item => {
        if(item.lineId === integrationLineId) item.isSave = false, item.isValid = event.validatedSteps
      })
    }, 1)
  }

  public setColumnSize(integrationCode: string): string {
    let columnSize: string = null
    let showRelatedAccount: boolean = this.setShowRelatedAccount(integrationCode)
    let showRelatedStartDate: boolean = this.setShowRelatedStartDate(integrationCode)

    if (showRelatedAccount && showRelatedStartDate) columnSize = "form-group col-sm-3"
    else if (showRelatedAccount || showRelatedStartDate) columnSize = "form-group col-sm-4"
    else columnSize = "form-group col-sm-6"

    return columnSize
  }

  private validationIntegration(integration: Integration): void {
    let message: string = null

    if(integration.name === null || integration.name.length === 0) {
      message = 'Nome é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if(integration.integrationCode === null) {
      message = 'API de referência é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if(this.setShowRelatedAccount(integration.integrationCode) && integration.companyAccount === null) {
      message = 'Conta referência é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if(this.setShowRelatedStartDate(integration.integrationCode) && integration.startDate === null) {
      message = 'Data inicial é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if(integration.integrationCode !== null) {
      const objMessages = {
        'MAT': 'O formulário da Matera está incompleto! Complete a última etapa!',
        'EYE': 'O formulário da Eye Mobile está incompleto!',
        'CON': 'O formulário da Conciliadora está incompleto!',
        'BS2': 'O formulário da BS2 está incompleto!',
        'PLVRE': 'O formulário da PayLivre está incompleto!',
        'ASAAS': 'O formulário da ASAAS está incompleto!',
        'TRAY': 'O formulário da TRAY está incompleto!'
      }

      if(!integration.isValid) {
        message = objMessages[integration.integrationCode]
        this.showResponse('info', message)
        throw message
      }

      if(!integration.isValid && integration.id !== null) this.setSaveIntegration(integration.lineId, true)
    }
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso: ', detail: message })
      break
    }
  }

  public formatDateExtensive(): string {
    const dt = new Date()
    const monthExtensive = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const weekExtensive = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]

    const month = monthExtensive[dt.getMonth()]
    const week = weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }

  private formatStartDate(date: Date): string {
    const year = date.getFullYear()
    const month = ((date.getMonth() + 1) > 9 ) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))
    const day = (date.getDate() > 9 ) ? date.getDate() : ('0' + date.getDate())

    return `${year}-${month}-${day}`
  }
}
