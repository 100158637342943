import { AuthUser } from "app/proof/model/auth-user.model";
import { Empresa } from "app/cadastro/model/empresa.model";

export class Aprovador {
  id: number;
  authUser: AuthUser;
  dtIni: Date;
  dtFin: Date;
  ativo: boolean = true;
  aprovadorSubstituto: Aprovador;
  empresa: Empresa;

  constructor(Aprovador?: Aprovador | number) {
    if (Aprovador !== undefined && Aprovador !== null) {
      if (typeof Aprovador === 'number') {
        this.id = Aprovador;
      } else {
        this.id = Aprovador.id;
        this.authUser = Aprovador.authUser;
        this.dtIni = Aprovador.dtIni;
        this.dtFin = Aprovador.dtFin;
        this.ativo = Aprovador.ativo;
        this.aprovadorSubstituto = Aprovador.aprovadorSubstituto;
        this.empresa = Aprovador.empresa;
      }
    }
  }
}