export class BcCalculoCredito {
  id: number;
  codigo: string;
  descricao: string;

  constructor(bcCalculoCredito?: BcCalculoCredito | number) {
    if (bcCalculoCredito !== undefined && bcCalculoCredito !== null) {
      if (typeof bcCalculoCredito === 'number') {
        this.id = bcCalculoCredito;
      } else {
        this.id = bcCalculoCredito.id;
        this.codigo = bcCalculoCredito.codigo;
        this.descricao = bcCalculoCredito.descricao;
      }
    }
  }
}
