import { Component, OnInit, OnDestroy } from '@angular/core';

import { ConfigService } from '../../shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Message } from 'primeng/api';
import { Router } from '@angular/router';
import { ToolbarService } from '../../shared/services/toolbar.service';

@Component({
  selector: 'app-ficha-estoque',
  templateUrl: './ficha-estoque.component.html',
  styleUrls: [
    './ficha-estoque.component.scss'
  ],
  providers: [CrudService]
})
export class FichaEstoqueComponent implements OnInit, OnDestroy {
  msgs: Message[] = [];
  msgsModal: Message[] = [];

  title: string;
  subtitle: string;

  lista: Array<any>;

  codigo: any;
  descricao: any;
  qtde: any;
  valor: any;
  cstMedio: any;

  //export excel
  colslista: any[]

  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private router: Router
  ) {}

  ngOnInit() {
    Promise.resolve(null).then(() => (this.toolbarService.hidden = true));

    this.title = 'ficha de estoque';
    this.subtitle = 'posição final do período';

    this.lista = [
      {
        codigo: 'AI9996004350',
        descricao: 'SISTEMA DE APLICACAO DE VAPOR',
        un: 'UN',
        qtde: '10',
        valor: '40537,47',
        cstMedio: ' 4.053,75'
      },
      {
        codigo: 'AI9996004352',
        descricao: 'ACESSORIOS PARA PROD ALIM',
        un: 'UN',
        qtde: '32',
        valor: '275811,93',
        cstMedio: '8619,12'
      },
      {
        codigo: 'AI9996004358',
        descricao: 'TUBOS DE COBRE',
        un: 'UN',
        qtde: '50',
        valor: '70024,81',
        cstMedio: '1400,49'
      },
      {
        codigo: 'AI9996004409',
        descricao: 'DISSOLVEDOR CONTINUO',
        un: 'UN',
        qtde: '55',
        valor: '733303,68',
        cstMedio: '13332,79'
      },
      {
        codigo: 'AI9996004460',
        descricao: 'MODULO DE COMPRESSAO REFRIG',
        un: 'UN',
        qtde: '4',
        valor: '402708,06',
        cstMedio: '100677,01'
      },
      {codigo: 'AI9996004461', descricao: 'MODULO DE EVAPORACAO REFRIG', un: 'UN', qtde: '96', valor: '362448,85', cstMedio: '3775,51'},
      {codigo: 'AI9996004462', descricao: 'CONDENSADORES A AR REFRIG', un: 'UN', qtde: '43', valor: '171259,93', cstMedio: '3982,79'},
      {codigo: 'AI9996004468', descricao: 'AC PARA INST EQUIP FRIGORIFICO', un: 'UN', qtde: '2', valor: '39886,95', cstMedio: '19943,48'},
      {codigo: 'AI9996004470', descricao: 'BICO DE EXTRUSAO EM INOX', un: 'UN', qtde: '25', valor: '58215,65', cstMedio: '2328,63'},
      {codigo: 'AI9996004479', descricao: 'DIFUSOR ROTACIONAL', un: 'PC', qtde: '72', valor: '22013,27', cstMedio: '305,74'},
      {codigo: 'AI9996004483', descricao: 'ROSCA TRANSPORTADORA DE ACUCAR', un: 'PC', qtde: '206', valor: '196679,11', cstMedio: '954,75'},
      {codigo: 'AI9996004497', descricao: 'ROSCA TRANSPORTADORA DE ACUCAR', un: 'PC', qtde: '143', valor: '21621,89', cstMedio: '151,20'},
      {codigo: 'AI9996004518', descricao: 'TAMPA CHAPA GALVANIZADA', un: 'PC', qtde: '30', valor: '2943,72' , cstMedio: '98,12'},
      {codigo: 'AI9996004519', descricao: 'PORTA FILTROS GALVANIZADOS', un: 'PC', qtde: '16', valor: '9719,71', cstMedio: '607,48'},
      {codigo: 'AI9996004520', descricao: 'TOLVA', un: 'UN', qtde: '12', valor: '45456,85', cstMedio: '3788,07'},
      {codigo: 'AI9996004587', descricao: 'SISTEMA ENCHIMENTO DE BANDEJAS', un: 'UN', qtde: '64', valor: '56321,14', cstMedio: '880,02'},
      {codigo: 'AI9996004596', descricao: 'PENEIRA VIBRATORIA', un: 'UN', qtde: '89', valor: '6654,11', cstMedio: '74,77'},
      {codigo: 'AI9996004612', descricao: 'PLATAFORMA E ESTRUTURA MAQUINA', un: 'UN', qtde: '64', valor: '94884,81', cstMedio: '1482,58'},
      {codigo: 'AI9996004613', descricao: 'ELEVADOR DE PRODUTOS', un: 'UN', qtde: '2', valor: '225006,60', cstMedio: '112503,30'},
      {codigo: 'AI9996004614', descricao: 'PULMAO DE ALIMENTACAO', un: 'UN', qtde: '9', valor: '188518,08', cstMedio: '20946,45'},

    ];

    this.colslista = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'qtde', header: 'SALDO QTDE' },
      { field: 'valor', header: 'SALDO VALOR' },
      { field: 'cstMedio', header: 'CUSTO MÉDIO' }
    ];

  }

  ngOnDestroy() {
    this.toolbarService.clear();
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() {


  }

  onRowSelect() {
    this.router.navigate(['./ficha-estoque-detalhe']);
  }
}
