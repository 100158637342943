import { Empresa } from '../../cadastro/model/empresa.model';

export class MovBalancete {
  id: number;
  codCta: string;
  descrCta: string;
  sldIni: number;
  indDcSldIni: string;
  vlDeb: number;
  vlCred: number;
  sldFin: number;
  indDcSldFin: string;
  empresa: Empresa = new Empresa();
  periodo: string;


  constructor(movbalancete?: MovBalancete | number) {
    if (movbalancete !== undefined && movbalancete !== null) {
      if (typeof movbalancete === 'number') {
        this.id = movbalancete;
      } else {
        this.id = movbalancete.id;
        this.codCta = movbalancete.codCta;
        this.sldIni = movbalancete.sldIni;
        this.indDcSldIni = movbalancete.indDcSldIni;
        this.vlDeb = movbalancete.vlDeb;
        this.vlCred = movbalancete.vlCred;
        this.sldFin = movbalancete.sldFin;
        this.indDcSldFin = movbalancete.indDcSldFin;
        this.empresa = movbalancete.empresa || new Empresa();
        this.periodo = movbalancete.periodo;
      }
    }
  }
}
