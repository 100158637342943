import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem, MenuItem, Message, MessageService  } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { UtilService } from 'app/shared/services/util.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { ValidatorsService } from 'app/shared/services/validators.service';
import { PickList } from 'primeng/picklist';
import { Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { Table } from 'primeng/table';
import exportExcel from 'app/util/processing/exportExcel';
import { Toolbar } from 'app/shared/model/toolbar.model'
import { ToolbarService } from 'app/shared/services/toolbar.service'


declare var $: any;

  @Component({
  selector: 'app-controle-usuario',
  templateUrl: './controle-usuario.component.html',
  styleUrls: [
    './controle-usuario.component.scss'
  ],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})



  export class ControleUsuarioComponent implements OnInit, OnDestroy {
    @ViewChild('pickList') pickList: PickList;

    @ViewChild('dt') dt: Table
      exportMov1() {
      this.dt.exportCSV()
    }

    public toolbarMain = new Toolbar()
    toolbarMainIni = [
      { key: 'acess_management', icon: 'manage_accounts', text: 'Conceder acesso', tooltip: 'Gerenciar acesso', visible: true, disabled: false, color: 'default', onClick: () => this.concederAcesso() },
      { key: 'refresh', icon: 'refresh', text: 'Atualizar', tooltip: 'Opções', visible: true, disabled: false, color: 'default', onClick: () => this.change() },
      { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Criar usuário', visible: true, disabled: false, color: 'default', onClick: () => this.addNovoUsuario() },
    ]

    userId: number;
    items: MenuItem[];
    empresaGrupoRelacionar: Map<number, Array<any>> = new Map();
    empresaGrupoRelacionados: Map<number, Array<any>> = new Map();

    listaGruposRelacionarTemp    : Array<any> = new Array();
    listaGruposRelacionar     : Array<any> = new Array();
    listaGruposRelacionados   : Array<any> = new Array();

    listaEmpresasRelacionar    : Array<any> = new Array();
    listaEmpresasRelacionados  : Array<any> = new Array();

    listaUsuarios         : Array<any> = new Array();

    nome: string;
    sobrenome: string;

    email: string;
    confEmail: string;
    fone: string;
    celular: string;
    ativo: boolean;
    statusOptions: SelectItem[];
    selected: string[] = ["SIM"];
    opcoesAcesso: SelectItem[] = [];
    oldEmpresaId: number;
    oldEmpresaId2: number;
    modalConcederAcesso: boolean;

    picklistState: boolean = true

    exportMov = ["id","nomeCompleto","email","ativo"]

    constructor(
      private configService: ConfigService,
      private dblinkedSessionService: DblinkedSessionService,
      private httpService: HttpService,
      private sessionService: SessionService,
      private utilService: UtilService,
      private validatorsService: ValidatorsService,
      private messageService: MessageService,
      private router: Router,
      private appComponent: AppComponent,
      private toolbarService: ToolbarService,
    ) { this.toolbarService.hidden = true }

    private sessionSubscription: Subscription; // change session event;

    title = '';
    subtitle = '';

    activeIndex: number = 0;
    novoUsuario: boolean;
    editUsuario: boolean;

    // components;
    tiposLista = [];
    showLista = false;
    showNew = false;
    showEdit = false;
    codigo = '';
    descricao = '';
    cnabFormaId = '';
    tipoSelecionadoId = 0;

    // dropdowns;
    formaCnabList: SelectItem[] = [];
    formaCnab: number = null;

    // msgs;
    msgs: Message[] = [];
    msgsModal: Message[] = null;

    // date;
    defaultDate: Date;
    pt_BR: any;
    pt: any;

    //variaveis
    usuarios: Array<Object>;
    usuariosSemAcesso: Array<Object>;
    usuarioSemAcessoId: number = null;
    modalEdit: boolean = false;
    modalReset: boolean = false;
    modalNew: boolean = false;
    idEdit: number = null;
    nomeEdit: string = null;
    nomeCompletoEdit: string = null;
    emailEdit: string = null;
    ativoEdit: boolean = false;
    novaSenha: string = null;
    novaSenha2: string = null;
    forcePasswordReset: boolean = false;

    //variaveis
    urlNew: string = null;
    grupos: SelectItem[];
    groupId: number = null;

    //variaveis de relacionamento
    listaRelacionar: Array<any> = new Array();
    listaRelacionados: Array<any> = new Array();
    listaGrupoRelacionar: Array<any> = new Array();
    listaGrupoRelacionados: Array<any> = new Array();

    //datepipe
    datePipePt: DatePipe = new DatePipe('pt-BR');

    testeusuarios: any[];

    //Variables card
    statusUsuario: boolean = true;
    statusUsuarioGrupo: boolean = false;

    // Collapse Card
    currentStateCadastroMenu = 'initial';
    changeStateCadastroMenu() {
    this.currentStateCadastroMenu = this.currentStateCadastroMenu === 'initial' ? 'final' : 'initial';
    }

    opcoesAtivo: SelectItem[];

    wait: boolean  = true

    ngOnInit() {
      this.toolbarMain.setAll(this.toolbarMainIni)

      this.appComponent._waitForMFA.subscribe(state => { this.wait = state, this.toolbarService.hidden = true})

      this.dblinkedSessionService.hidePeriods();

      this.title = 'Controle de Acesso';
      this.subtitle = 'Controle de Usuário';


      // change session event;
      this.sessionSubscription = this.sessionService.initSubscribe(
        this.dblinkedSessionService.sessionChanged,
        () => {
          this.change();
        }
      );

      this.defaultDate = new Date();
      this.pt_BR = {
        firstDayOfWeek: 0,
        dayNames: [
          'Domingo',
          'Segunda-Feira',
          'Terça-Feira',
          'Quarta-Feira',
          'Quinta-Feira',
          'Sexta-Feira',
          'Sábado'
        ],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro'
        ],
        monthNamesShort: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        today: 'Hoje',
        clear: 'Limpar'
      };

    this.editUsuario = false;

    this.opcoesAcesso = [];
    this.opcoesAcesso.push({label: 'OPERACIONAL',value: 'OPERACIONAL'});
    this.opcoesAcesso.push({label: 'ADMIN',value: 'ADMIN'});

    this.novoUsuario = false;
    this.empresaGrupoRelacionar = new Map();
    this.empresaGrupoRelacionados = new Map();

    this.listaGruposRelacionarTemp  = new Array();
    this.listaGruposRelacionar  = new Array();
    this.listaGruposRelacionados   = new Array();
    this.userId = 0;
    this.ativo = false;

    this.title = 'PERMISSÕES';

    this.items = [{
      label: 'Informações Básicas',
      command: (event: any) => {
          this.activeIndex = 0;
          this.msgs.length = 0;

      }
  },
  {
      label: 'Configurações Empresas',
      command: (event: any) => {
          this.activeIndex = 1;
          this.msgs.length = 0;

      }
  },
  {
    label: 'Configurações Acesso',
    command: (event: any) => {
        this.activeIndex = 2;
        this.msgs.length = 0;

    }
},
  {
      label: 'Confirmação',
      command: (event: any) => {
          this.activeIndex = 3;
          this.msgs.length = 0;

      }
  }
];

    /*HIDE EYE  (DO NOT REMOVE)*/
    $(document).ready(() => {
      $("#ueEyePass2").mousedown(function () {
        $("#uePassowrd2").attr("type", "text");
    });

    $("#ueEyePass2").mouseup(function () {
        $("#uePassowrd2").attr("type", "password");
    });

      $("#ueEyePass3").mousedown(function () {
        $("#uePassowrd3").attr("type", "text");
    });

    $("#ueEyePass3").mouseup(function () {
        $("#uePassowrd3").attr("type", "password");
    });
    });

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
      }
    );

    }

    ngOnDestroy() {
      this.toolbarService.hidden = false
      this.dblinkedSessionService.hidePeriods();
      this.sessionService.destroySubscribe(this.sessionSubscription);
    }

    get hasEmpresaSelected(): boolean {
      return this.dblinkedSessionService.hasEmpresaSelected
    }

    get loading(): boolean {
      return this.httpService.loading
    }

    get rowsPerPage(): Array<any> {
      return this.configService.applicationConfig.rowsPerPage
    }

    get rows(): number {
      return this.configService.applicationConfig.rows
    }

    get pageLinks(): number {
      return this.configService.applicationConfig.pageLinks
    }

    private changeSession() {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.novoUsuario = false;
        this.editUsuario = false;
        this.statusOptions = [];
        this.statusOptions.push({ label: 'SIM', value: 'SIM' });
        this.statusOptions.push({ label: 'NÃO', value: 'NÃO' });

        this.nome = '';
        this.sobrenome = '';
        this.fone = '';
        this.email = '';
        this.confEmail = '';
        this.celular = '';
        this.ativo = true;
        this.listaEmpresasRelacionar = new Array();
        this.listaEmpresasRelacionados = new Array();
        this.listaGruposRelacionarTemp = new Array();

        this.httpService.wait();
        this.httpService.get(`custom/cadastro/controle-acesso/controle-usuario-list2?auth_user_id=${this.sessionService.loggedUser.id}&empresa_id=${this.dblinkedSessionService.empresa.id}&admin_id=${this.sessionService.loggedUser.id}`)
          .subscribe(retorno => {

            retorno.forEach(element => {
              if (element.relac){
                this.listaGruposRelacionarTemp.push(element);
              } else {
                // do nothing
              }

            });

            },
            error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());

        const reqEmp = {
          authUserId                     : this.sessionService.loggedUser.id,
          metodo: 'get-empresas-admin'
        };

        this.httpService.wait();
        this.httpService.post('/custom/permissoes/comandos-permissoes', reqEmp)
          .subscribe(result => {

            result.valor.forEach(element => {
              this.listaEmpresasRelacionar.push(element);

            });
          },
            error => this.httpService.handleError(error, () => this.changeSession()),
            () => this.httpService.done());

      }
    }

    hideMessage() {

    }

    // Edit Items Modal
    openEditCadMenu(row) {
      this.modalEdit = true;
      this.idEdit = row.id;
      // this.nomeEdit = row.nome;
      this.nomeCompletoEdit = row.nomeCompleto;
      this.emailEdit = row.email;
      this.ativoEdit = row.ativo;
      this.buscarGrupos(row.id);
    }

    openResetSenha(row) {
      this.modalReset = true;
      this.novaSenha = "";
      this.novaSenha2 = "";
      this.idEdit = row.id;

    }

    // New Item Modal
    openNewCadMenu() {
      this.modalNew = true;
    }

    //On Filter Table
    onFilter(event: any) {
    }

    /*Open Close DropDown Date*/
    dropdate: boolean = false;
    DropdownDateEvent(e: Event) {
    this.dropdate = !this.dropdate;
    }

    /*Show Hide isCardCadastroUser*/
    isCardCadastroUser: boolean = true;
    ShowHIdeCardCadastroUser() {
    this.isCardCadastroUser = !this.isCardCadastroUser;
    }

    /*Show Hide isCardCadastroMenu*/
    isCardCadastroMenu: boolean = true;
    ShowHideCardCadastroMenu() {
      this.isCardCadastroMenu = !this.isCardCadastroMenu;
    }

    /*declaração de abas*/
    showTelaCadastroUser(tipo) {
        this.statusUsuario = false;
        this.statusUsuarioGrupo = false;

        if (tipo === 'TELA_1') {
          this.statusUsuario = true;
          this.dropdate = false;

        }

        else if (tipo === 'TELA_2') {
          this.statusUsuarioGrupo = true;
          this.dropdate = false;

        }
    }

    concederAcesso() {
      this.usuariosSemAcesso = [];
      this.modalConcederAcesso = true;

      this.listaGruposRelacionar =  Object.assign([], this.listaGruposRelacionarTemp);
      this.listaGruposRelacionados = new Array();

      this.httpService.wait();
      this.httpService.get(`/custom/cadastro/controle-acesso/usuarios-sem-acesso?auth_user_id=${this.sessionService.loggedUser.id}&empresa_id=${this.dblinkedSessionService.empresa.id}`).subscribe(
        retorno => {
          if (retorno.error){
            alert('Não foi possível buscar os usuários sem acesso');
            return;
          }
          retorno.forEach( linha => {
            this.usuariosSemAcesso.push({
              value: linha['id'],
              label: `${linha['nome_usuario']} - ${linha['mail']}`,
            });
          });
        },
        error => {
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar usuários sem acesso." });
          this.httpService.done();
        },
        () => this.httpService.done()
      );

    }

    concederAcessoUsuario() {
      let req = {
        authUserId: this.usuarioSemAcessoId,
        empresaId: this.dblinkedSessionService.empresa.id
      }

      this.httpService.wait();
      this.httpService.post(`/custom/cadastro/controle-acesso/conceder-acesso-usuario`, req).subscribe(
        retorno => {
          if (retorno.error){
            alert('Não foi possível conceder o acesso');
            return;
          }
          this.messageService.add({severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: "Acesso concedido!" });
        },
        error => {
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao conceder o acesso" });
          this.httpService.done();
        },
        () => this.httpService.done()
      );

      let req2 = [];
      this.listaGruposRelacionados.forEach(element => {
        req2.push({"authUserId": req.authUserId, "groupId": element.id, "empresaId": req.empresaId})
      });

      this.httpService.wait();
      this.httpService.post('/custom/cadastro/controle-acesso/controle-usuario-post', req2)
      .subscribe( retorno => {
        if (retorno.error){
          alert('Algum erro ocorreu ao salvar o relacionamento!');
          return;
        }

      },
        error => {
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao salvar relacionamento." });
          this.httpService.done();
          return
      },
        () => this.httpService.done()
      );
    }

    listUsuarios() {
      // função que atualiza a página e busca os usuários
      this.modalNew = false;
      this.usuarios = [];
      this.statusOptions = [];
      this.statusOptions.push({ label: 'SIM', value: 'SIM' });
      this.statusOptions.push({ label: 'NÃO', value: 'NÃO' });

      this.getComboAtivo();

      this.httpService.wait();
      this.httpService.get(`/custom/cadastro/controle-acesso/usuarios?empresa_id=${this.dblinkedSessionService.empresa.id}`).subscribe(
        retorno => {
          if (retorno.error){
            alert('Não foi possível buscar os usuários');
            return;
          }
          this.usuarios = [];

          retorno.value.forEach( linha => {
            this.usuarios.push({
              id: linha['id'],
              nome: linha['full_name'],
              nomeCompleto: linha['full_name'],
              ativo: linha['active'],
              email: linha['login'],
              confEmail: linha['login']
            });
          });
        },
        error => {
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
          this.httpService.done();
        },
        () => this.httpService.done()
      );
    }

    tabChange(event: any){
    }

    buscarUsuarios(){
      // função que busca usuários de um grupo
      this.listaRelacionados = new Array();
      this.listaRelacionar = new Array();


      if(this.groupId > 0){
        this.httpService.wait();
        this.httpService.get(`custom/cadastro/controle-acesso/controle-usuario-list?auth_group_id=${this.groupId}&empresa_id=${this.dblinkedSessionService.empresa.id}`)
          .subscribe(retorno => {
            try {
              retorno.forEach(element => {
                if(element.relac){
                  this.listaRelacionados.push(element);
                } else {
                  this.listaRelacionar.push(element);
                }
              });

            } catch (error) {
              this.messageService.add({
                severity: 'warn',key: 'messageLinnks',
                summary: 'MSG: ',
                detail: 'Erro ao buscar grupos!'
              });
            }


          }, error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());

      }
    }

    // salvarRelacionamento(){
    //   // funcao que salva usuarios em um grupo
    //     if (confirm('Deseja realmente salvar este relacionamento?')) {

    //
    //       // primeiro deletamos os que não foram relacionados
    //       let req1 = [];
    //       this.listaRelacionar.forEach(element => {
    //         req1.push({"authUserId": element.id, "groupId": this.groupId, "empresaId": this.dblinkedSessionService.empresa.id})
    //       });

    //       this.httpService.wait();
    //       this.httpService.post('/custom/cadastro/controle-acesso/controle-usuario-delete', req1)
    //       .subscribe( retorno => {
    //         if (retorno.error){
    //           alert('Algum erro ocorreu ao deletar o relacionamento!');
    //           return;
    //         }

    //       },
    //         error => {
    //           this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao deletar relacionamento." });
    //           this.httpService.done();
    //           return
    //       },
    //         () => this.httpService.done()
    //       );

    //       // agora salvamos os relacionados
    //       let req2 = [];
    //       this.listaRelacionados.forEach(element => {
    //         req2.push({"authUserId": element.id, "groupId": this.groupId, "empresaId": this.dblinkedSessionService.empresa.id})
    //       });

    //       this.httpService.wait();
    //       this.httpService.post('/custom/cadastro/controle-acesso/controle-usuario-post', req2)
    //       .subscribe( retorno => {
    //         if (retorno.error){
    //           alert('Algum erro ocorreu ao salvar o relacionamento!');
    //           return;
    //         }

    //       },
    //         error => {
    //           this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao salvar relacionamento." });
    //           this.httpService.done();
    //           return
    //       },
    //         () => this.httpService.done()
    //       );
    //       this.change()
    //     }
    //     this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: "Usuário(s) Relacionado(s)." });
    // }

    change() {
        if (this.dblinkedSessionService.hasEmpresaSelected) {

            this.listaRelacionar = new Array();
            this.listaRelacionados = new Array();


            this.listaGrupoRelacionados = new Array();
            this.listaGrupoRelacionar = new Array();


            this.grupos = [];
            this.grupos.push({ label: 'Selecione', value: 0 });

            // pegamos a listagem de usuários
            this.listUsuarios();

            this.httpService.wait();
            this.httpService.get(`custom/cadastro/controle-acesso/controle-usuario-list2?auth_user_id=${this.sessionService.loggedUser.id}&empresa_id=${this.dblinkedSessionService.empresa.id}&admin_id=${this.sessionService.loggedUser.id}`)
            .subscribe(retorno => {
                try {
                  retorno.forEach(element => {
                    this.grupos.push({ label: element.group_name, value: element.id });
                  });
                } catch (error) {
                  this.messageService.add({
                    severity: 'warn',key: 'messageLinnks',
                    summary: 'MSG: ',
                    detail: 'Erro ao buscar grupos!'
                  });
                }


              }, error => this.httpService.handleError(error, () => this.change()),
                () => this.httpService.done());

        }
    }

    buscarGrupos(user_id : number){
      // função que busca grupos do usuário
      this.listaGrupoRelacionados = new Array();
      this.listaGrupoRelacionar = new Array();

      this.picklistState = false

      this.userId = user_id;

      if(user_id > 0){
        this.httpService.wait();
        this.httpService.get(`custom/cadastro/controle-acesso/controle-usuario-list2?auth_user_id=${user_id}&empresa_id=${this.dblinkedSessionService.empresa.id}&admin_id=${this.sessionService.loggedUser.id}`)
          .subscribe(retorno => {
            try {
              retorno.forEach(element => {
                if (element.relac){
                  this.listaGrupoRelacionados.push(element);
                } else {
                  this.listaGrupoRelacionar.push(element);
                }
              });

              this.picklistState = true
            } catch (error) {
              this.messageService.add({
                severity: 'warn',key: 'messageLinnks',
                summary: 'MSG: ',
                detail: 'Erro ao buscar grupos!'
              });
            }


          }, error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());

      } else {
        this.picklistState = true
      }
    }

    salvarRelacionamentoGrupo(){
      let confirmacaoRepetida = false;
      if (this.listaGrupoRelacionados && this.listaGrupoRelacionados.length === 0) {
        if (!confirm('Para remover todos os grupos, é necessário remover o usuário da empresa. Deseja continuar?')) {
          return;
        } else {
          if (!confirm('TEM CERTEZA QUE DESEJA REMOVER O USUÁRIO DA EMPRESA?')) {
          } else {

            //removemos o usuário da empresa
            confirmacaoRepetida = true;

            let req0 = [{
              authUserId: this.userId,
              empresaId: this.dblinkedSessionService.empresa.id
            }];

            this.httpService.wait();
            this.httpService.post('/custom/cadastro/controle-acesso/deletar-usuario-projeto-dbl-relac', req0)
            .subscribe( retorno => {
              if (retorno.error){
                alert('Algum erro ocorreu ao deletar o usuário!');
                return;
              }
            },
              error => {
                this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao deletar usuário." });
                this.httpService.done();
                return;
            },
              () => this.httpService.done()
            );
            // nao eh necessario mexer nos seus grupos
            return;
          }
        }
      }

      if (confirmacaoRepetida || confirm('Deseja realmente salvar este relacionamento?')) {
        this.modalEdit = false;
        this.resetarFiltros();


        // primeiro DESATIVAMOS os que não foram relacionados
        let req1 = [];
        this.listaGrupoRelacionar.forEach(element => {
          req1.push({"groupId": element.id, "authUserId": this.userId, "empresaId": this.dblinkedSessionService.empresa.id})
        });

        this.httpService.wait();
        this.httpService.post('/custom/cadastro/controle-acesso/controle-usuario-put', req1)
        .subscribe( retorno => {
          if (retorno.error){
            alert('Algum erro ocorreu ao deletar o relacionamento!');
            return;
          }

        },
          error => {
            this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao deletar relacionamento." });
            this.httpService.done();
            return;
        },
          () => {
            if (confirmacaoRepetida) {
              this.listUsuarios();
              this.httpService.done();
            }
          }
        );

        // agora salvamos os relacionados
        let req2 = [];
        this.listaGrupoRelacionados.forEach(element => {
          req2.push({"groupId": element.id, "authUserId": this.userId, "empresaId": this.dblinkedSessionService.empresa.id})
        });

        this.httpService.post('/custom/cadastro/controle-acesso/controle-usuario-post', req2)
        .subscribe( retorno => {

          if (retorno.error){
            alert('Algum erro ocorreu ao salvar o relacionamento!');
            return;
          }

        },
          error => {
            this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao salvar relacionamento." });
            this.httpService.done();
            return;
        },
          () => {
            if (confirmacaoRepetida) {
              this.listUsuarios();
              this.httpService.done();
            }
          }
        );

        if (confirmacaoRepetida) {
          // não é necessário desativar o usuário
          return;
        }

        // agora ativamos ou desativamos o usuário na empresa através da tabela cadastro.projeto_dbl_relac
        if (this.listaGrupoRelacionados.length === 0) {
          // se nao tiver grupos, retornamos o usuário para o sistema antigo, independente do ativoEdit
          this.ativoEdit = true;
        }
        let req3 = {"authUserId": this.userId, "empresaId": this.dblinkedSessionService.empresa.id, "ativoEdit": this.ativoEdit};

        this.httpService.post('/custom/cadastro/controle-acesso/desativa-projeto-dbl-relac', req3)
        .subscribe( retorno => {
          this.listUsuarios();

          if (retorno.error){
            alert('Algum erro ocorreu ao ativar/desativar o usuário!');
            return;
          }
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: "Usuário atualizado" });

        },
          error => {
            this.listUsuarios();

            this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao ativar/desativar o usuário!" });
            this.httpService.done();
            return;
        },
          () => this.httpService.done()
        );
      }
    }

    salvarReset(){
      if (confirm('Deseja realmente redefinir esta senha?')) {

        if (!this.validatorsService.validaSenha(this.novaSenha, this.novaSenha2)) {
          return;
        }

        this.modalReset = false;

        let req1 = {
          authUserId: this.idEdit,
          novaSenha: this.novaSenha,
          forcePasswordReset: this.forcePasswordReset,
          usuarioQueAlterou: this.sessionService.loggedUser.id
        };
        this.httpService.wait();
        this.httpService.post('/custom/cadastro/controle-acesso/alterar-senha', req1)
        .subscribe( retorno => {
          if (retorno.error){
            alert('Algum erro ocorreu ao alterar a senha do usuário!');
            return;
          }
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: "Senha alterada com sucesso!" });
          this.listUsuarios();

        },
          error => {
            this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao alterar senha." });
            this.httpService.done();
            return
        },
          () => this.httpService.done()
        );

      }
    }

   usuarioExcluir(rowValue) {
      if (confirm('Deseja realmente excluir este usuário?')) {
        let req1 = [{
          authUserId: rowValue.id,
          empresaId: this.dblinkedSessionService.empresa.id
        }];
        this.httpService.wait();
        this.httpService.post('/custom/cadastro/controle-acesso/deletar-usuario-projeto-dbl-relac', req1)
        .subscribe( retorno => {
          if (retorno.error) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: retorno.value });
          else this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: retorno.value });
          this.listUsuarios();

        },
          error => {
            this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao deletar usuário." });
            this.httpService.done();
            return;
        },
          () => this.httpService.done()
        );

      }
    }


    addNovoUsuario(){
      this.novoUsuario = true;
      this.editUsuario = false;
    }

    cadastrarUsuario(){

      let mensagem = this.editUsuario ? 'Deseja realmente salvar estas alterações?' : 'Deseja realmente criar este usuário?';

      if (confirm(mensagem)) {
        if(this.listaEmpresasRelacionados.length === 0){
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'Erro',
            detail: 'É necessário selecionar pelo menos uma empresa'
          });
          return;
        }

        if (this.empresaGrupoRelacionados) {
          if (Object.entries(this.empresaGrupoRelacionados).length !== this.listaEmpresasRelacionados.length) {
            this.messageService.add({
              severity: 'error',key: 'messageLinnks',
              summary: 'Erro',
              detail: 'Toda empresa precisa de pelo menos um grupo relacionado!'
            });
            return;
          }
        } else {
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'Erro',
            detail: 'Há algo de errado com os grupos relacionados.'
          });
          return;
        }

        // todo usuário agora é "operacional" e não "admin"
        for (let line of this.listaEmpresasRelacionados) {
          line['tipoAcesso'] = 'OPERACIONAL';
        }
        const reqUsr = {
          authUserId                     : this.sessionService.loggedUser.id,
          metodo: 'post-usuario',
          grupos: this.listaGruposRelacionados,
          empresas: this.listaEmpresasRelacionados,
          nome: this.nome,
          sobrenome: this.sobrenome,
          email: this.email.toLocaleLowerCase(),
          confEmail: this.confEmail,
          fone: this.fone,
          celular: this.celular,
          edit: false,
          usrId: this.userId,
          ativo: this.ativo,
          novasPermissoes: true
        };


        this.httpService.wait();
        this.httpService.post('/custom/permissoes/comandos-permissoes', reqUsr)
          .subscribe(result => {
            if(result.error){
              result.erros.forEach(element => {
                this.messageService.add({
                  severity: 'error',key: 'messageLinnks',
                  summary: 'Erro',
                  detail: element.mensagem
                });
                return;

              });
              return;

            }else{
              this.messageService.add({
                severity: 'success',key: 'messageLinnks',
                summary: 'Sucesso',
                detail: this.editUsuario ? 'Usuário Atualizado com Sucesso!' : 'Usuário Criado com Sucesso!'
              });
              // @octavio 30/11/2020
              // agora adicionamos o usuário aos seus respectivos grupos+empresas
              let userId = result.valor[0].userId;
              let req1 = [];
              //único jeito que achei para iterar esse objeto
              for (let [key, value] of Object.entries(this.empresaGrupoRelacionados)) {
                value.forEach( grupo => {
                  req1.push({"authUserId": userId, "groupId": grupo.id, "empresaId": parseInt(key)})

                });
              }
              // agora salvamos mandamos a requisição para salvar os grupos
              this.httpService.wait();
              this.httpService.post('/custom/cadastro/controle-acesso/controle-usuario-post', req1)
              .subscribe( retorno => {
                if (retorno.error){
                  alert('Algum erro ocorreu ao salvar o relacionamento!');
                  return;
                }

              },
                error => {
                  this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao salvar relacionamento." });
                  this.httpService.done();
                  return;
              },
                () => this.httpService.done()
              );

              this.changeSession();

              alert("Anote a senha do novo usuário: " + result.valor[0].senhaAleatoria);
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/controle-acesso/controle-usuario']);
              });
            }

          },
            error => this.httpService.handleError(error, () => this.changeSession()),
            () => this.httpService.done());
      }
    }

    cancelarEdicao(){
      if (confirm('As alterações serão perdidas, deseja continuar?')) {
      this.changeSession();
      }
    }

    loadComponent(value: any){

      this.activeIndex = 0;
      this.novoUsuario = true;
      this.editUsuario = true;
      this.userId = value.id;

      this.listaEmpresasRelacionados = new Array();
      this.listaGruposRelacionados = new Array();

      const reqUsr = {
        authUserId                     : this.sessionService.loggedUser.id,
        metodo: 'get-usuario-id',
        login: value.login

      };

      this.httpService.wait();
      this.httpService.post('/custom/permissoes/comandos-permissoes', reqUsr)
        .subscribe(result => {
          result.valor.forEach(element => {

          this.nome = element.nome;
          this.sobrenome = element.sobrenome;
          this.fone = element.fone;
          this.email = element.email;
          this.confEmail = element.confEmail;
          this.celular = element.celular;
          this.ativo = element.ativo;

          element.empresas.forEach(emp => {
            this.listaEmpresasRelacionados.push(emp);
            this.listaEmpresasRelacionar.forEach(element => {
              if(element.id === emp.id){
                const index = this.listaEmpresasRelacionar.findIndex(c => c === element);
                this.listaEmpresasRelacionar.splice(index, 1);
              }
            });
          });

          element.grupos.forEach(grp => {
            this.listaGruposRelacionados.push(grp);
            this.listaGruposRelacionar.forEach(element => {
              if(element.id === grp.id){
                const index = this.listaGruposRelacionar.findIndex(c => c === element);
                this.listaGruposRelacionados.splice(index, 1);
              }
            });
          });

          });
        },
          error => this.httpService.handleError(error, () => this.loadComponent(value)),
          () => this.httpService.done());

    }

    changeGroup(empresaId: number){
      if (this.empresaGrupoRelacionados[empresaId] ||
        this.empresaGrupoRelacionar[empresaId]) {
          // verificamos se a empresa já tem alguma relação de grupos
          this.listaGruposRelacionados = this.empresaGrupoRelacionados[empresaId];
          this.listaGruposRelacionar = this.empresaGrupoRelacionar[empresaId];
      } else {
        // caso não tenha, carregamos todos os grupos
        this.listaGruposRelacionar =  Object.assign([], this.listaGruposRelacionarTemp);
        this.listaGruposRelacionados = new Array();

      }
      this.oldEmpresaId = empresaId;
    }

    changeGroup2(empresa){
      // mudamos os grupos da tela de confirmação
      this.oldEmpresaId2 = empresa.id;
    }

    salvarRelacao() {
      this.empresaGrupoRelacionados[this.oldEmpresaId] = this.listaGruposRelacionados;
      this.empresaGrupoRelacionar[this.oldEmpresaId] = this.listaGruposRelacionar;

      this.oldEmpresaId2 = this.listaEmpresasRelacionados[0].id;

      for (let line of this.listaEmpresasRelacionados) {
        if (this.empresaGrupoRelacionados[line["id"]] != null || this.empresaGrupoRelacionados[line["id"]] != undefined) {
          line["selecionada"] = true;
        }
      }

      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: "Informação Gravada." });

    }

    avancarEdicao(n: number) {
    if (this.email !== this.confEmail || this.email === "") {
      alert("O e-mail está incorreto!");
      return;
    }
    else {
      this.activeIndex = n + 1;
    }
    }

    voltarEdicao(n: number) {
      this.activeIndex = n - 1;
    }

    resetarFiltros() {
      this.pickList.resetFilter();
    }

    getComboAtivo() {
      this.opcoesAtivo = [];
      this.opcoesAtivo.push({label: 'Sim', value: 'true'});
      this.opcoesAtivo.push({label: 'Não', value: 'false'});
    }

    exportExcelAll(item, fileName, list?) {
      exportExcel(item, fileName, list)
    }



  }
