import { FormControl, Validators, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

export class DataOptions {
  url: string;
  parent: string;
  label: any;
  prompt: string;
  application: string;
  values: Array<any>;
  value: any;
  static = false;

  nullLabel: string;

  description: string;
  child: string;
  childField: string;
}

export class DataObject extends FormControl {
  key: string;
  label: string;
  type = 'text';
  required = false;

  onChange: Function;

  minWidth: string;
  maxWidth: string;
  classNames: string;

  options: DataOptions;
  filteredOptions: Observable<any[]>;

  constraintName: string = null;

  timezone: string;
  timeWithSeconds = false;

  private _formControl: FormControl;

  constructor(label: string, key?: string) {
    super();

    if (key === undefined || key === null) {
      key = label.trim(); // TODO: Convert label to Camel case key
      label = null;
    }

    key = key.replace(/\//g, '.');

    this.key = key;
    this.label = label;
  }

  setLabel(label: string): any {
    this.label = label;
    return this;
  }

  protected setType(type: string): any {
    this.type = type;
    return this;
  }
  setTypeText(): any {
    return this.setType('text');
  }
  setTypeTextArea(): any {
    return this.setType('textarea');
  }
  setTypeSelect(): any {
    return this.setType('select');
  }
  setTypeAutocomplete(): any {
    return this.setType('autocomplete');
  }
  setTypeMultiSelect(): any {
    return this.setType('multiselect');
  }
  setTypeNumber(numbersOnly: boolean = false): any {
    return this.setType(numbersOnly ? 'numbers-only' : 'number');
  }
  setTypeMoney(): any {
    return this.setType('money');
  }
  setTypeCurrency(): any {
    return this.setTypeMoney();
  }
  setTypeSlider(): any {
    return this.setType('slider');
  }
  setTypeCheckBox(): any {
    return this.setType('checkbox');
  }
  setTypeDate(): any {
    return this.setType('date');
  }
  setTypeDateLocal(): any {
    return this.setType('date-local');
  }
  setTypeDateTime(withSeconds: boolean = false): any {
    this.timeWithSeconds = withSeconds;
    return this.setType('datetime');
  }
  setTypeDateTimeLocal(withSeconds: boolean = false): any {
    this.timeWithSeconds = withSeconds;
    return this.setType('datetime-local');
  }
  setTypeTime(withSeconds: boolean = false): any {
    this.timeWithSeconds = withSeconds;
    return this.setType('time');
  }
  setTypeEmail(): any {
    return this.setType('email');
  }
  setTypeCnpj(): any {
    return this.setType('cnpj');
  }
  setTypePassword(): any {
    return this.setType('password');
  }
  setTypeList(): any {
    return this.setType('list');
  }
  setTypeImage(): any {
    return this.setType('image');
  }

  setOnChange(onChange: Function): DataObject {
    this.onChange = onChange;
    return this;
  }

  setOptions(options: any): DataObject {
    this.options = options;
    return this;
  }

  setMinWidth(minWidth: string): DataObject {
    this.minWidth = minWidth;
    return this;
  }
  setMaxWidth(maxWidth: string): DataObject {
    this.maxWidth = maxWidth;
    return this;
  }

  setClassNames(classNames: string): DataObject {
    this.classNames = classNames;
    return this;
  }

  setTimezone(timezone: string): any {
    this.timezone = timezone;
    return this;
  }

  setDisabled(disabled: boolean = true): any {
    if (disabled) {
      this.disable();
    } else {
      this.enable();
    }

    return this;
  }

  setRequired(required: boolean = true): any {
    this.required = required;
    return this;
  }

  setConstraintName(constraintName: string): any {
    this.constraintName = constraintName;
    return this;
  }
  setUnique(constraintName: string): any {
    return this.setConstraintName(constraintName);
  }

  getFormControl(): FormControl {
    return this._formControl;
  }
  setFormControl(formControl: FormControl): any {
    this._formControl = formControl;
    return this;
  }

  get hasTimezone(): boolean {
    return this.timezone !== undefined && this.timezone !== null && this.timezone !== '';
  }

  get hasDate(): boolean {
    return this.type.startsWith('date');
  }

  get hasTime(): boolean {
    return (this.hasDate || this.type.startsWith('time')) && this.type.indexOf('time') !== -1;
  }

  get hasDateOrTime(): boolean {
    return this.hasDate || this.hasTime;
  }
}
