import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ComissaoTipo } from 'app/financeiro/model/comissao-tipo.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { Empresa } from '../../cadastro/model/empresa.model';
import { FormaPagamento } from '../../util/model/forma-pagamento.model';
import { FormaPagamentoDetalhe } from '../../util/model/forma-pagamento-detalhe.model';
import { UtilService } from 'app/shared/services/util.service';
import { TipoBaixa } from 'app/util/model/tipo-baixa.model';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { ConfiguracoesApis } from 'app/cadastro/model/configuracoes-apis.model';
import { isNullOrUndefined } from 'util';
import { AuthUser }  from 'app/proof/model/auth-user.model'
import { trigger, state, style, animate, transition } from '@angular/animations';
declare var $: any;

@Component({
  selector: 'app-forma-pagamento',
  templateUrl: './forma-pagamento.component.html',
  styleUrls: ['./forma-pagamento.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class FormaPagamentoComponent extends DataTableBasicComponent implements OnInit, OnDestroy {

  currentStateFormPag = 'initial';
    changeStateFormPag() {
      this.currentStateFormPag = this.currentStateFormPag === 'initial' ? 'final' : 'initial';
  }

  private empresaSubscription: Subscription;

  formaPagamento: FormaPagamento;
  formaPagamentoDetalhe: FormaPagamentoDetalhe;
  detalheList: Array<FormaPagamentoDetalhe>;

  entityFilter: string = null;
  title: String;
  subtitle: String;
  msgs: Message[];
  criar: boolean;
  editar: boolean;
  detalharPagamento: boolean;

  formaPagamentoList: Array<FormaPagamento>;

  porcentagemTotal: number;
  ultimoDia: number;
  idExcluir: number;

  tiposBaixas: SelectItem[];
  contasBancarias: SelectItem[];
  opcoesgateways : SelectItem[];



  constructor(
    private configService: ConfigService,
    private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {
    super(crudService);
  }

  ngOnInit() {

    this.title = 'FORMA DE PAGAMENTO';
    this.subtitle = 'CADASTE, EDITE OU EXCLUA FORMAS DE PAGAMENTO';

   // this.toolbarService.clear();

    this.formaPagamentoList = new Array();
    this.formaPagamento = new FormaPagamento();
    this.formaPagamento.tipoBaixa = new TipoBaixa();
    this.formaPagamento.tipoBaixaId = 0;
    this.formaPagamento.empresaConta = new EmpresaConta();
    this.formaPagamento.empresaContaId = 0;
    this.formaPagamento.ativo = true;
    this.formaPagamento.boolDataEmissaoPedido = false;

    this.formaPagamento.formaPagamentoDetalheList = new Array();
    this.formaPagamento.configuracoesApis = new ConfiguracoesApis();
    this.formaPagamento.configuracoesApisId = 0;
    this.formaPagamentoDetalhe = new FormaPagamentoDetalhe();
    this.detalheList = new Array();

    this.criar = false;

    this.editar = false;

    this.detalharPagamento = false;


    this.empresaSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.empresaChanged,
      () => {
        this.empresaChanged();
      }
    );

    Promise.resolve(null).then(() => this.toolbarRefresh());

  }

  hideMessage() {

  }

  private toolbarRefresh() {
    this.toolbarService.ocultar('voltar');
    // this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('save');
    //this.toolbarService.ocultar('remove');
    //this.toolbarService.ocultar('edit');

    // this.toolbarService.alterarStatus('atualizar', false, 'Atualizar relatório', () => this.empresaChanged());
    // this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirFormaPagamento() );
    // this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.editarFormaPagamento() );
    this.toolbarService.replace('atualizar', true, 'class-first-button', 'refresh', 'Atualizar', () => this.empresaChanged());
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.excluirFormaPagamento());
    this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar', () => this.editarFormaPagamento());
    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar nova forma de pagamento', () => this.criarFormaPagamento());

    /*this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-other-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-first-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar');
    this.toolbarService.add('add', false, 'class-new-button', 'NOVO',
      'Criar nova forma de pagamento', () => this.criarFormaPagamento());*/
  }

  private toolbarEdit() {
    if (!isNullOrUndefined(this.idExcluir) && this.idExcluir > 0) {
      this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.excluirFormaPagamento() );
      this.toolbarService.alterarStatus('edit', false, 'Editar', () => this.editarFormaPagamento() );
      this.toolbarService.replace('add', true, 'class-new-button', 'NOVO',
          'Criar nova forma de pagamento', () => this.criarFormaPagamento());

    } else {
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirFormaPagamento() );
      this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.editarFormaPagamento() );
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO',
          'Criar nova forma de pagamento', () => this.criarFormaPagamento());

    }
    this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.excluirFormaPagamento() );
    this.toolbarService.alterarStatus('edit', false, 'Editar', () => this.editarFormaPagamento() );
    this.toolbarService.replace('add', true, 'class-new-button', 'NOVO',
        'Criar nova forma de pagamento', () => this.criarFormaPagamento());
    /*this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-other-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-first-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', false, 'class-remove-button', 'delete', 'Excluir', () => this.excluirFormaPagamento());
    this.toolbarService.add('edit', false, 'class-edit-button', 'edit', 'Editar', () => this.editarFormaPagamento());
    this.toolbarService.add('add', false, 'class-new-button', 'NOVO',
      'Criar nova forma de pagamento', () => this.criarFormaPagamento());*/
  }

  criarFormaPagamento() {
    this.criar = true;
    // this.toolbarService.clear();
    this.formaPagamento.tipoBaixa = new TipoBaixa();
    this.formaPagamento.tipoBaixaId = 0;
    this.formaPagamento.empresaConta = new EmpresaConta();
    this.formaPagamento.empresaContaId = 0;
    this.formaPagamento.ativo          = true;
    this.formaPagamento.configuracoesApis = new ConfiguracoesApis();
    this.formaPagamento.configuracoesApisId = 0;
    this.formaPagamento.boolDataEmissaoPedido = false;


  }

  excluirFormaPagamento() {
    if (confirm('Esta forma de pagamento será excluída, deseja continuar?')) {

      const listasEnviar = {
        empresa: this.dblinkedSessionService.empresa.id,
        formaPagamento: this.idExcluir,
        usuario: this.sessionService.loggedUser.id,
        idExcluir: this.idExcluir
      };

      this.httpService.wait();
      this.httpService.post('/custom/financeiro/excluir-forma-pagamento', listasEnviar)
        .subscribe(result => this.resultFormaPagamento(result),
          error => this.httpService.handleError(error, () => this.excluirFormaPagamento()),
          () => this.httpService.done());

    }
  }

  editarFormaPagamento() {

    this.editar = true;

    this.httpService.wait();
      this.httpService.get('custom/financeiro/listar-forma-pgto',
        [this.idExcluir])
        .subscribe(retorno => {
          this.loadFormaPagamentoEditar(retorno)

        }, error => this.httpService.handleError(error, () => this.editarFormaPagamento()),
          () => this.httpService.done());
  }

  loadFormaPagamentoEditar(value: any) {

    this.formaPagamento = value[0];
    this.formaPagamento.tipoBaixa = new TipoBaixa();
    this.formaPagamento.tipoBaixa.id = value[0].tipoBaixa.id;
    this.formaPagamento.tipoBaixaId = value[0].tipoBaixaId;
    this.formaPagamento.empresaConta = new EmpresaConta();
    this.formaPagamento.empresaConta.id = value[0].empresaConta.id;
    this.formaPagamento.empresaContaId = value[0].empresaContaId;
    this.formaPagamento.ativo          = value[0].ativo;
    this.formaPagamento.configuracoesApis  = new ConfiguracoesApis();
    this.formaPagamento.configuracoesApis.id = value[0].configuracoesApisId;
    this.formaPagamento.configuracoesApisId  = value[0].configuracoesApisId;
    this.formaPagamento.boolDataEmissaoPedido = value[0].boolDataEmissaoPedido;

    this.httpService.wait();
    this.httpService.get('/formapagamentodetalhe?$select=id,dias,porcentagem,parcela,acrescentaMes,formaPagamento/id' +
      '&$filter=formaPagamento/id eq ' + this.idExcluir + '&$orderby=parcela')
      .subscribe(ref => this.loadFormaPagamentoDetalhe(ref.value),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());
  }

  loadFormaPagamentoDetalhe(value: any) {


    this.formaPagamento.formaPagamentoDetalheList = value;
    this.detalheList = value;

    this.porcentagemTotal = 0.00;

    this.detalheList.forEach(element => {
      this.porcentagemTotal = this.porcentagemTotal + this.utilService.parseDecimal(element.porcentagem);
    });

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  empresaChanged() {

    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.comboTiposBaixa();
      this.comboEmpresaConta();
      this.getComboGateway();

      this.formaPagamentoList = new Array();
      this.formaPagamento = new FormaPagamento();
      this.formaPagamento.formaPagamentoDetalheList = new Array();
      this.formaPagamentoDetalhe = new FormaPagamentoDetalhe();
      this.detalheList = new Array();
      this.criar = false;
      this.editar = false;
      this.detalharPagamento = false;

      this.formaPagamento.empresa = new Empresa();
      this.formaPagamento.empresa.id = this.dblinkedSessionService.empresa.id;
      this.detalheList = new Array();

      const detalhe = new FormaPagamentoDetalhe();
      detalhe.formaPagamento = new FormaPagamento();
      detalhe.formaPagamento.id = this.formaPagamento.id;
      detalhe.dias = 0;
      detalhe.porcentagem = 0;
      detalhe.parcela = 1;
      this.detalheList.push(detalhe);
      this.ultimoDia = 0;

      this.httpService.wait();
      this.httpService.get('/formapagamento?$select=id,descricao,descricaoEn,maxParcelas,tipoBaixa/id' +
        '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=descricao')
        .subscribe(ref => this.loadFormaPagamento(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());
    }
  }
  }


  onRowSelectFormaPagamento(event: any) {

    this.idExcluir = event.data.id;

    this.toolbarEdit();
  }

  salvarFormaPagamento() {

    if (this.validacao()) {
      return;
    }

    this.formaPagamento.formaPagamentoDetalheList = this.detalheList;
    this.formaPagamento.tipoBaixa             = new TipoBaixa();
    this.formaPagamento.tipoBaixa.id          = this.formaPagamento.tipoBaixaId;
    this.formaPagamento.tipoBaixaId           = this.formaPagamento.tipoBaixaId;
    this.formaPagamento.empresaConta          = new EmpresaConta();
    this.formaPagamento.empresaConta.id       = this.formaPagamento.empresaContaId;
    this.formaPagamento.empresaContaId        = this.formaPagamento.empresaContaId;
    this.formaPagamento.ativo                 = this.formaPagamento.ativo;
    this.formaPagamento.configuracoesApis     = new ConfiguracoesApis();
    this.formaPagamento.configuracoesApis.id  = this.formaPagamento.configuracoesApisId;
    this.formaPagamento.configuracoesApisId   = this.formaPagamento.configuracoesApisId;
    this.formaPagamento.changeUser            = new AuthUser();
    this.formaPagamento.changeUser.id         = this.sessionService.loggedUser.id;
    this.formaPagamento.boolDataEmissaoPedido = this.formaPagamento.boolDataEmissaoPedido;

    this.httpService.wait();
    this.httpService.post('/custom/financeiro/criar-forma-pagto', this.formaPagamento)
      .subscribe(result => {



        if(result.error){
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result.message
          });
        }else{
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result.message
          });
          this.empresaChanged();
   }},
        error => this.httpService.handleError(error, () => this.salvarFormaPagamento()),
        () => this.httpService.done());

      }

  atualizarFormaPagamento() {
    if (this.validacao()) {
      return;
    }

    this.formaPagamento.changeUser = new AuthUser()
    this.formaPagamento.changeUser.id = this.sessionService.loggedUser.id;

    this.httpService.wait();
    this.httpService.post('/custom/financeiro/criar-forma-pagto', this.formaPagamento)
      .subscribe(() => this.empresaChanged(),
        error => this.httpService.handleError(error, () => this.salvarFormaPagamento()),
        () => this.httpService.done());

  }

  resultFormaPagamento(value: any) {
    this.idExcluir = null;

    if (value.error === true) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: value.message });
    } else {
      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: value.message });
      this.empresaChanged();
    }
    this.toolbarEdit();
  }

  removerLinha(value: any) {

    if (!this.editar) {
      const index = this.detalheList.findIndex(c => c === value);
    this.detalheList.splice(index, 1);
    } else {
      this.httpService.wait();
      this.httpService.delete('/formapagamentodetalhe', value.id.toString())
        .subscribe(() => this.editarFormaPagamento(),
        error => this.httpService.handleError(error, () => this.removerLinha(value)),
        () => this.httpService.done());
    }

    this.verificarParcelas(value);

  }

  verificarParcelas(value: any) {
    this.porcentagemTotal = 0.00;

    this.detalheList.forEach(element => {
      this.porcentagemTotal = this.utilService.parseDecimal(this.porcentagemTotal + element.porcentagem);
    });

    if (isNullOrUndefined(this.formaPagamento.maxParcelas)) {

      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Erro', detail: 'Por favor insira o número máximo de parcelas!' });
    }

    if (this.porcentagemTotal > 100) {
      value.porcentagem = 0;

      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Erro', detail: 'Porcentagem total ultrapassa 100%' });
    }

    if (value.parcela > 0 && value.porcentagem > 0
      && this.porcentagemTotal < 100
      && this.detalheList.length === value.parcela
      && (!isNullOrUndefined(this.formaPagamento.maxParcelas) || this.formaPagamento.maxParcelas !== 0)
      && value.parcela < this.formaPagamento.maxParcelas) {

      const detalhe = new FormaPagamentoDetalhe();
      detalhe.formaPagamento = new FormaPagamento();
      detalhe.formaPagamento = this.formaPagamento;
      detalhe.dias = 0;
      detalhe.porcentagem = 0;
      detalhe.parcela = value.parcela + 1;
      this.detalheList.push(detalhe);
      this.ultimoDia = detalhe.dias;
    }
  }

  cancelar() {
    if (confirm('Todas as alterações serão perdidas! Deseja continuar?')) {
      this.empresaChanged();
    }
  }

  adicionarDetalhe() {
    this.detalheList.push(this.formaPagamentoDetalhe);
  }

  loadFormaPagamento(value: Array<FormaPagamento>) {

    this.formaPagamentoList = value;
    this.toolbarRefresh();
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  comboTiposBaixa() {
    this.tiposBaixas = [];
    this.tiposBaixas.push({ label: 'Selecione', value: 0 });
    this.httpService.wait();
    this.httpService.get('custom/financeiro/get-combo-tipo-baixa')
      .subscribe(ref => {
        ref.forEach(element => {
          this.tiposBaixas.push({ label: element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboTiposBaixa()),
        () => this.httpService.done());
  }

  comboEmpresaConta() {
    this.contasBancarias = [];
    this.contasBancarias.push({ label: 'Selecione', value: 0 });
    this.httpService.wait();
    this.httpService.get('/empresaconta?$select=id,nome,agencia,conta&$'
      + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' &$orderby=nome')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.contasBancarias.push({ label: (element.nome + ' - AG: ' + element.agencia + ' Conta: ' + element.conta), value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboEmpresaConta()),
        () => this.httpService.done());
  }

  getComboGateway(){
    this.httpService.wait();
      this.httpService.get('custom/financeiro/get-combo-gateway-pagamentos', [this.dblinkedSessionService.empresa.id])
        .subscribe(retorno => {
          this.opcoesgateways = [];
          retorno.forEach(element =>{
            this.opcoesgateways.push({label: element.codigo + ' - ' + element.descricao , value: element.id})
          })

        }, error => this.httpService.handleError(error, () => this.editarFormaPagamento()),
          () => this.httpService.done());

  }


  validacao(){

    if (!this.dblinkedSessionService.hasEmpresaSelected ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecionar uma empresa!'
      });
      return true;

    }

    if (isNullOrUndefined( this.formaPagamento.descricao) || (!isNullOrUndefined(this.formaPagamento.descricao) && this.formaPagamento.descricao.trim() === '' )) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar uma descrição!'
      });
      return true;
    }


    if (isNullOrUndefined(this.formaPagamento.tipoBaixaId) || this.formaPagamento.tipoBaixaId === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma forma de pagamento!'
      });
      return true;
    }


    if (this.detalheList && (this.formaPagamento.tipoBaixaId !== 12 && this.formaPagamento.tipoBaixaId !== 13
      && this.formaPagamento.tipoBaixaId !== 3 && this.formaPagamento.tipoBaixaId !== 4)) {
        var acrescentaMesValido :Boolean = true;
        var diasValido          :Boolean = true;
        console.log(this.detalheList);

        if (isNullOrUndefined(this.detalheList) || this.detalheList.length === 0) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Favor adicionar um detalhe de pagamento!'
          });
        }

      this.detalheList.forEach(element =>{

        if (isNullOrUndefined(element.acrescentaMes) || element.acrescentaMes < 0 ) {
          acrescentaMesValido = false;
        }

        if (isNullOrUndefined(element.dias) || element.dias < 0 ) {
          diasValido = false;
        }


      });

      if (!acrescentaMesValido ) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor digitar um mês, podendo ser maior ou igual a 0!'
        });
        return true;

      }

      if (!diasValido ) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor digitar um dia, podendo ser maior ou igual a 0!'
        });
        return true;

      }

    }

    if (isNullOrUndefined(this.formaPagamento.maxParcelas) || this.formaPagamento.maxParcelas === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Por favor insira o número máximo de parcelas!'
      });
      return true;
    }else{
      if(this.detalheList.length > this.formaPagamento.maxParcelas){
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Quantidade de parcelas ultrapassa o número máximo!'
        });
        return true;
      }
    }


    return false;
  }

  /*Show Hide FormPag*/
  isCardFormPag: boolean = true;
  ShowHIdeFormPag(){
    this.isCardFormPag = !this.isCardFormPag;
  }

}
