import { AuthUser } from './auth-user.model';
import { AuthApp } from './auth-app.model';
import { AuthFeature } from './auth-feature.model';


export class AuthGroup {
  id: number;
  name: string;
  description: string;
  active = true;
  authUsers: Array<AuthUser> = new Array();
  authApps: Array<AuthApp> = new Array();
  authFeatures: Array<AuthFeature> = new Array();

  constructor(authGroup?: AuthGroup | number) {
    if (authGroup !== undefined && authGroup !== null) {
      if (typeof authGroup === 'number') {
        this.id = authGroup;
      } else {
        this.id = authGroup.id;
        this.name = authGroup.name;
        this.description = authGroup.description;
        this.active = authGroup.active;
        this.authUsers = authGroup.authUsers || new Array();
        this.authApps = authGroup.authApps || new Array();
        this.authFeatures = authGroup.authFeatures || new Array();
      }
    }
  }
}

