import { AuthUser } from 'app/proof/model/auth-user.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { FormaPagamentoDetalhe } from 'app/util/model/forma-pagamento-detalhe.model';
import { TipoBaixa } from './tipo-baixa.model';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { ConfiguracoesApis } from 'app/cadastro/model/configuracoes-apis.model';

export class FormaPagamento {
  id: number;
  descricao: string;
  descricaoEn: string;
  empresa: Empresa;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;
  maxParcelas: number;
  tipoBaixa: TipoBaixa;
  tipoBaixaId: number;
  formaPagamentoDetalheList: Array<FormaPagamentoDetalhe>;
  empresaConta: EmpresaConta;
  empresaContaId: number;
  ativo: boolean;
  configuracoesApis: ConfiguracoesApis;
  configuracoesApisId: number;
  boolDataEmissaoPedido: boolean;
  administradoraId: number;
  bandeiraId: number;
  mensalidade: number;
  diaVencimento: number;
  intervaloDias: number;
  regraContabilId: number;
  regraNova: boolean;
  dataReferencia: string;
  isRecebimento: boolean = true
  isPagamento: boolean = true
  parcelasExatas: boolean = false
  permiteAlterarBanco: boolean = true
  filtro: string;
  paydayDefault: number;
  programacaoSemanal: boolean = false;
  // paymentWeek: number;
  minDaysInterval: number;
  dayOfWeek: number;
  tipoPagamentoId: number
  grupoEmpresaFormaPagamentoOrigemId: number

  constructor(formaPagamento?: FormaPagamento | number) {
    if (formaPagamento !== undefined && formaPagamento !== null) {
      if (typeof formaPagamento === 'number') {
        this.id = formaPagamento;
      } else {
        this.id = formaPagamento.id;
        this.descricao = formaPagamento.descricao;
        this.descricaoEn = formaPagamento.descricaoEn;
        this.empresa = formaPagamento.empresa;
        this.createTimestamp = formaPagamento.createTimestamp;
        this.changeUser = formaPagamento.changeUser;
        this.changeTimestamp = formaPagamento.changeTimestamp;
        this.maxParcelas = formaPagamento.maxParcelas;
        this.tipoBaixa = formaPagamento.tipoBaixa;
        this.tipoBaixaId = formaPagamento.tipoBaixaId;
        this.formaPagamentoDetalheList = formaPagamento.formaPagamentoDetalheList;
        this.empresaConta = formaPagamento.empresaConta;
        this.empresaContaId = formaPagamento.empresaContaId;
        this.ativo = formaPagamento.ativo;
        this.configuracoesApis = formaPagamento.configuracoesApis;
        this.configuracoesApisId = formaPagamento.configuracoesApisId;
        this.boolDataEmissaoPedido = formaPagamento.boolDataEmissaoPedido;
        this.administradoraId = formaPagamento.administradoraId;
        this.bandeiraId = formaPagamento.bandeiraId;
        this.mensalidade = formaPagamento.mensalidade;
        this.diaVencimento = formaPagamento.diaVencimento;
        this.intervaloDias = formaPagamento.intervaloDias;
        this.regraContabilId = formaPagamento.regraContabilId;
        this.regraNova = formaPagamento.regraNova;
        this.dataReferencia = formaPagamento.dataReferencia;
        this.isRecebimento = formaPagamento.isRecebimento
        this.isPagamento = formaPagamento.isPagamento
        this.parcelasExatas = formaPagamento.parcelasExatas
        this.permiteAlterarBanco = formaPagamento.permiteAlterarBanco
        this.filtro = formaPagamento.filtro;
        this.paydayDefault = formaPagamento.paydayDefault;
        this.programacaoSemanal = formaPagamento.programacaoSemanal;
        // this.paymentWeek = formaPagamento.paymentWeek;
        this.minDaysInterval = formaPagamento.minDaysInterval;
        this.dayOfWeek = formaPagamento.dayOfWeek;
        this.tipoPagamentoId = formaPagamento.tipoPagamentoId;
        this.grupoEmpresaFormaPagamentoOrigemId = formaPagamento.grupoEmpresaFormaPagamentoOrigemId
      }
    }
  }
}
