import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from 'app/shared/services/config.service';
import { UtilService } from 'app/shared/services/util.service';
import { HttpService } from 'app/shared/services/http.service';
import { PysocketService } from 'app/shared/services/socket/pysocket.service';
import idv4 from 'app/util/processing/idv4';
import { Service } from 'app/shared/services/socket/protocol/Service';
import { Action } from 'app/shared/services/socket/protocol/Action';
import { Method } from 'app/shared/services/socket/protocol/Method';

@Component({
  selector: 'app-importpedidos',
  templateUrl: './import-pedidos.component.html',
  styleUrls: ['./import-pedidos.component.scss']
})

export class PedidosImportComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef
  @ViewChild('fileChoosen') fileName: ElementRef<HTMLSpanElement>

  private sessionSubscription: Subscription
  private messagesSubscription: Subscription

  file: File
  listTipoArquivo: Array<any> = [
    { label: 'Importação pedidos (CSV)', value: 1 },
    // { label: 'Arquivo NFS100 (CSV) (DEPRECIADO - Usar Importação pedidos)', value: 2 },
    { label: 'Planilha TradeWorks (CSV)', value: 3 }
  ]
  idTipoArquivo: Number
  filename: string
  loadingProgress: number = 0
  isLoadingInProgress: boolean = false
  tokenProgressoSocket: string = null

  constructor(private titleService: TitleService,
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private httpService: HttpService,
    private messageService: MessageService,
    private socketService: PysocketService) {

  }

  ngOnInit(): void {
    this.titleService.title = 'Importar arquivos de pedidos'
    this.toolbarService.hidden = true
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.onChange()
    })

    this.messagesSubscription = this.socketService.socketData.subscribe((response) => {
      if (response.service === Service.NOTIFICATION && response.action === Action.LOADING && response.method === Method.GET) {
        this.loadingManagement(response)
      }
    })
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
  }

  onChange() {
    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
      this.idTipoArquivo = null
    }
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0]
    this.filename = this.file.name;
    this.fileName.nativeElement.innerHTML = this.filename
  }


  // confirm() {
    // let httpOptions = null;
    // if (this.idTipoArquivo === 2) {
    //   httpOptions = {
    //     headers: new HttpHeaders({
    //       'tipo': this.idTipoArquivo.toString(),
    //       'userid': this.sessionService.loggedUser.id.toString(),
    //       'empresaid': this.dblinkedSessionService.empresa.id.toString(),
    //       'Authorization': this.configService.bearerApi,
    //       'mes': this.dblinkedSessionService.periodo.month.value < 10 ? '0' + this.dblinkedSessionService.periodo.month.value.toString() : this.dblinkedSessionService.periodo.month.value.toString(),
    //       'ano': this.dblinkedSessionService.periodo.year.value.toString(),
    //     })
    //   };
    // }

    // const formData = new FormData();
    // formData.append('file', this.file);

  //   if (!this.utilservice.validArquivo(this.filename)) {
  //     this.httpService.wait();
  //     this.httpClient.post(this.configService.defaultUrlApiUpload + 'pedidos/confirm', formData, httpOptions)
  //       .subscribe(
  //         res => {
  //           if (res["error"]) {
  //             this.exibirMensagem(res);

  //           } else {
  //             this.confirmPeriodo(res);
  //           }

  //           this.httpService.done();
  //         },
  //         err => {
  //           this.exibirMensagem({ error: true, mensagem: "Erro o API, entre em contato com o suporte: " + err.message });
  //           console.log(err)
  //           this.httpService.done();
  //         }
  //       );

  //   } else {

  //     this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Aviso:', detail: 'O nome do arquivo não pode conter carateres acentuados e/ou especiais.' });
  //   }
  // }

  // confirmPeriodo(value: any) {
  //   if (value === 1) {
  //     if (confirm('Período já possui informações, deseja substituir?')) {
  //       this.importar();
  //     }

  //   } else if (value === 0) {
  //     this.importar();

  //   } else if (value === 2) {

  //     this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Aviso:', detail: 'Período Encerrado, não é possível fazer alterações!' });

  //   } else if (value.error) {
  //     this.exibirMensagem(value);
  //   }
  // }

  importar(confirmar: boolean=false) {

    if (this.idTipoArquivo === 3) {
      const data: FormData = new FormData();
      data.append('file', this.file);
      data.append('type', 'planilha-tradeworks-csv');
      data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());

      this.httpService.wait();
      this.httpService.post('/custom/upload', data).
        subscribe(result => {
          let retornoFuncao = result["result"];
          let dadosFuncao = retornoFuncao["entity"];

          if (dadosFuncao["error"] === false) {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: dadosFuncao["message"] });
            window.open(this.httpService.url('/custom/file/' + dadosFuncao["file"] + '/keep'), '_blank');
          } else {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: dadosFuncao["message"] });
          }

        }, error => this.httpService.handleError(error, () => this.importar()),
          () => this.httpService.done());

    } else {
      this.loadingProgress = 0
        this.isLoadingInProgress = true
        this.tokenProgressoSocket = idv4()
        const formData = new FormData()
        formData.append('file', this.file)
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': this.configService.bearerApi
          })}

        this.httpClient.post(`${this.configService.defaultUrlApiUpload}financeiro/upload-pedidos-lote?empresaId=${this.dblinkedSessionService.empresa.id}&userId=${this.sessionService.loggedUser.id}&tokenProgress=${this.tokenProgressoSocket}&confirmar=${confirmar}`, formData, httpOptions)
          .subscribe(
            res => {
              if (!res["error"] && res["data"] === 1) {
                if (confirm("Já existe código de controle nesse período, deseja prosseguir?")) {
                  this.importar()
                  return
                }
                else {
                  this.isLoadingInProgress = false
                  return
                }
              }
              this.exibirMensagem(res)
              if (res["error"] && !res["data"]) {
                this.isLoadingInProgress = false
                return
              }
              const httpOptions2 = {
                headers: new HttpHeaders({
                  'Authorization': this.configService.bearerApi,
                  'Cache-Control': 'no-store, max-age=0',
                  'userid': this.sessionService.loggedUser.id.toString()
                }),
                responseType: 'blob' as 'json'
              }

              this.httpClient.get(`${this.configService.defaultUrlApiUpload}financeiro/upload-pedidos-lote`, httpOptions2)
                .subscribe(
                  (res: Blob) => {
                    let link = document.createElement('a')
                    let url = window.URL.createObjectURL(res)
                    let fileName = "log.txt"
                    link.href = url
                    link.download = fileName
                    link.click()
                    window.URL.revokeObjectURL(url)
                    this.isLoadingInProgress = false
                  },
                  err => {
                    this.exibirMensagem({ error: true, mensagem: "Erro no API ao baixar o log, contatar o suporte: " + err.message })
                    console.log(err)
                    this.isLoadingInProgress = false
                  })
            },
            err => {
              this.exibirMensagem({ error: true, mensagem: "Erro no API ao importar o arquivo, contatar o suporte: " + err.message })
              console.log(err)
              this.isLoadingInProgress = false
            }
          )
      }
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  loadingManagement(data: any) {
    if (data.content.tokenProgressoSocket == this.tokenProgressoSocket) {
      this.loadingProgress = Math.round(data.content.progress)
    }
  }

  public getLinkLayoutUpload(tipoLayout: string){
    if(tipoLayout === "legenda")
    window.open('https://aws-website-downloads-e72og.s3.amazonaws.com/LAYOUT_UPLOAD/LEGENDAS_UPLOAD_PEDIDOS.pdf', '_blank')
    else if (tipoLayout === "layout")
    window.open('https://aws-website-downloads-e72og.s3.amazonaws.com/LAYOUT_UPLOAD/LAYOUT_PEDIDOS_LOTE.csv', '_blank')
  }

  public formatDateExtensive(): String {
    const dt = new Date()
    const monthExtensive = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const weekExtensive = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]

    const month = monthExtensive[dt.getMonth()]
    const week = weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }
}
