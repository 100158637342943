import { Empresa } from "app/cadastro/model/empresa.model";

export class Aprovacao {
  id: number;
  ordem: number;
  codigo: string;
  descricao: string;
  empresa: Empresa;
  exigeRateio: boolean = false;
  exigeParticipante: boolean = false;
  exigeFormaPagamento: boolean = false;
  exigeOperacao: boolean = false;
  exigeItem: boolean = false;

  constructor(Aprovacao?: Aprovacao | number) {
    if (Aprovacao !== undefined && Aprovacao !== null) {
      if (typeof Aprovacao === 'number') {
        this.id = Aprovacao;
      } else {
        this.id = Aprovacao.id;
        this.ordem = Aprovacao.ordem;
        this.codigo = Aprovacao.codigo;
        this.descricao = Aprovacao.descricao;
        this.empresa = Aprovacao.empresa;
        this.exigeRateio = Aprovacao.exigeRateio;
        this.exigeParticipante = Aprovacao.exigeParticipante
        this.exigeFormaPagamento = Aprovacao.exigeFormaPagamento;
        this.exigeOperacao = Aprovacao.exigeOperacao;
        this.exigeItem = Aprovacao.exigeItem;
      }
    }
  }
}