import { Component, OnInit, OnDestroy, ɵConsole } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';

import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { isNullOrUndefined } from 'util';

import { trigger, state, style, animate, transition } from '@angular/animations';
declare var $: any;


@Component({
  selector: 'app-gerencial',
  templateUrl: './gerencial.component.html',
  styleUrls: ['./gerencial.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class GerencialOrcamentoComponent implements OnInit, OnDestroy {
  currentStateOrcaGer = 'initial';
  changeStateOrcaGer() {
    this.currentStateOrcaGer = this.currentStateOrcaGer === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  msgs: Message[];
  msgsModal: Message[];
  msgsNaoMapeadas: Message[];
  tipo: number;
  contasNaoMapeadas: Array<any> = new Array();
  periodoOrcamentoEntrada: Array<any> = new Array();
  listaOrcamento: Array<any> = new Array();
  listaPedidos: Array<any> = new Array();
  listaProvisoes: Array<any> = new Array();
  listaVersoesOrcamentos: Array<any> = new Array();

  dadosOrcamento: Array<any> = new Array();
  comentariosOrcamento: Array<any> = new Array();

  comentario: string;
  podeEditar: boolean;
  comentarioId: number;
  orcamentoId: number;

  exibirRelacionamento: boolean;
  exibirAjustes: boolean;
  exibirNaoMapeadas: boolean;
  exibirComentario: boolean;

  opcoesPlanoContas: SelectItem[];
  contaSelecionada: number;

  opcoesTipoAjustesUsGaap: SelectItem[];
  tipoajustesusGaapSelecionada: string;

  opcoesGerenciais: SelectItem[];
  idReferencial: number;
  idsOrcamentosEntrada: Array<any> = new Array();
  idsOrcamentosEntradaOnFilter: Array<any> = new Array();
  idsOrcamentosEventosEntrada: Array<any> = new Array();
  idOrcamento: number = 0;


  lcto: any;
  valorTotalDeb: number;
  valorTotalCred: number;
  checkedAll: boolean = false;
  modalAlternarCentroCusto: boolean = false;
  opcoesCentroCusto: SelectItem[];
  cadCcus: CadCcus = new CadCcus();
  centroCustoSelecionadoId: number = 0;
  centroCustoSelecionado: CadCcus = new CadCcus();
  checkboxdisabled: boolean = false;
  idTeste: number = 0;
  selecionarTodosDisabled: boolean = true;
  tabIndexSelecionado: number = 0;
  modalPeriodo: boolean = false;
  public dataIni: any = '';
  public dataFin: any = '';
  isProcurarPorData: boolean = false;
  //2020-06-08 PROVISAO
  exibirProvisao : boolean = false;
  justificativaProvisao : string = '';
  valorProvisao : number = 0.00;
  provisaoId    : number = 0;
  valorOrcamento : number = 0.00;
  provisoesOrcamento: Array<any> = new Array();
  valorMaximoProvisao : number = 0.00;
  valorProvisaoOld    : number = 0.00;

  //export excel
  colsDadosOrcamento: any[];

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.tipo = 9;
    this.exibirComentario = false;

    this.titlePrincipal = 'Gerencial';
    // this.titleService.title = 'Linnks - Us Gaap';
    this.subtitle = 'Centro de custo: (Selecione um Centro de Custo) '
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.exibirRelacionamento = false;
    this.exibirAjustes = false;
    this.idReferencial = 0;
    this.periodoOrcamentoEntrada = new Array();
    this.comentario = '';
    this.podeEditar = false;
    this.comentarioId = 0;
    this.orcamentoId = 0;

    this.listaPedidos = new Array();
    this.listaProvisoes = new Array();
    this.contasNaoMapeadas = new Array();
    this.exibirNaoMapeadas = false;
    this.dadosOrcamento = new Array();
    this.comentariosOrcamento = new Array();


    this.valorTotalDeb = 0;
    this.valorTotalCred = 0;
    this.centroCustoSelecionadoId = 0;
    this.centroCustoSelecionado = new CadCcus();
    this.checkboxdisabled = false;
    this.idTeste = 0;
    this.selecionarTodosDisabled = true;
    this.tabIndexSelecionado = 0;
    this.modalPeriodo = false;
    this.isProcurarPorData = false;
    this.dataIni = '';
    this.dataFin = '';

    this.activatedRoute.params.subscribe(params => {

      if (this.tipo === 9 || this.tipo !== params.tipo) {
        this.tipo = params.tipo;
        this.change();
      }
    });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {

      this.change();
      this.toolbarEnable();

    });

    this.colsDadosOrcamento = [
      { field: 'codCta', header: 'CONTA CONTÁBIL' },
      { field: 'linha', header: 'LINHA' },
      { field: 'descricaoCompleta', header: 'DESCRIÇÃO' },
      { field: 'descricaoRazao', header: 'DESCRIÇÃO RAZÃO' },
      { field: 'valorOrcamento', header: 'VALOR ORÇAMENTO' },
      { field: 'valorRelacionado', header: 'VALOR RELACIONADO' }
    ];

  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService) {
      if (this.dblinkedSessionService.hasEmpresaSelected &&
        this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
        && this.dblinkedSessionService.periodo.year) {



      this.idOrcamento = 0;

      this.contasNaoMapeadas = [];
      this.periodoOrcamentoEntrada = [];
      this.idsOrcamentosEventosEntrada = [];
      this.listaPedidos = [];
      this.listaProvisoes = [];

      this.checkedAll = false;
      this.modalPeriodo = false;
      this.dadosOrcamento = new Array();

      if (isNullOrUndefined(this.centroCustoSelecionadoId) || this.centroCustoSelecionadoId === 0) {
        return;
      }

      this.listarDashboard();

    }
  }

  }

  listarDashboard(){

    this.dadosOrcamento = new Array();

    this.httpService.wait();
    this.httpService.get('/custom/orcamento/listar-dashboard(' + this.dblinkedSessionService.empresa.id +
      ',' + this.sessionService.loggedUser.id + ',' + this.centroCustoSelecionadoId +
      ',' + this.dblinkedSessionService.periodo.year.value +
      ',' + this.dblinkedSessionService.periodo.month.value + ')')
      .subscribe(res => {
        res.forEach(element => {
          this.dadosOrcamento.push(element);
          this.podeEditar = false;
          this.comentarioId = 0;
        });
      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }


  selectCheckbox(id: any, event: any) {

    if (event.selected) {
      const indexOE = this.idsOrcamentosEntrada.findIndex(vw => vw === id);
      this.idsOrcamentosEntrada.splice(indexOE, 1);

      const index = this.idsOrcamentosEventosEntrada.findIndex(oee => oee === event)
      this.idsOrcamentosEventosEntrada.splice(index, 1);

      if ((!isNullOrUndefined(this.idsOrcamentosEventosEntrada) && this.idsOrcamentosEventosEntrada.length < 1) || isNullOrUndefined(this.idsOrcamentosEventosEntrada)) {
        this.periodoOrcamentoEntrada.forEach(oe => {
          oe.checkboxdisabled = false;
          this.checkedAll = false;
        });
      }

    } else {
      this.idsOrcamentosEntrada.push(id);
      this.idsOrcamentosEventosEntrada.push(event);


      this.idTeste = this.idsOrcamentosEventosEntrada[0].planoContaId;

      for (let index = 0; index < this.periodoOrcamentoEntrada.length; index++) {
        const element = this.periodoOrcamentoEntrada[index];

        if (this.idTeste !== element.planoContaId) {
          element.checkboxdisabled = true;
        } else {
          element.checkboxdisabled = false;
        }


      }


    }

    this.verificarBotaoEdit();

  }

  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;
    this.idsOrcamentosEntrada = new Array();
    this.idsOrcamentosEventosEntrada = new Array();

    if (!isNullOrUndefined(this.idsOrcamentosEntradaOnFilter) && this.idsOrcamentosEntradaOnFilter.length > 0) {
      this.idsOrcamentosEntradaOnFilter.forEach(e => {

        if (this.checkedAll) {
          const i = this.periodoOrcamentoEntrada.findIndex(p => p === e);

          if (!this.periodoOrcamentoEntrada[i].checkboxdisabled) {
            this.periodoOrcamentoEntrada[i].selected = true;
            this.idsOrcamentosEntrada.push(e.id);
            this.idsOrcamentosEventosEntrada.push(e);
          }


        } else {

          this.periodoOrcamentoEntrada.forEach(oe => {
            oe.selected = false;
            oe.checkboxdisabled = false;
            this.idsOrcamentosEntrada.slice(oe.id, 1);
            this.idsOrcamentosEventosEntrada.slice(oe, 1);
          });

        }

      });
    } else {

      this.periodoOrcamentoEntrada.forEach(oe => {
        if (this.checkedAll === false) {
          oe.selected = false;
          this.idsOrcamentosEntrada.slice(oe.id, 1);
          this.idsOrcamentosEventosEntrada.slice(oe, 1);
          oe.checkboxdisabled = false;

        } else {
          if (!oe.checkboxdisabled) {
            oe.selected = true;
            this.idsOrcamentosEntrada.push(oe.id);
            this.idsOrcamentosEventosEntrada.push(oe);

          }
        }
      });

    }




    this.verificarBotaoEdit();
  }

  onFilter(event: any) {

    if (!isNullOrUndefined(event.filteredValue)) {
      this.idsOrcamentosEntradaOnFilter = new Array();
      // this.idsOrcamentosEventosEntrada  = new Array();


      event.filteredValue.forEach(e => {
        if (!e.checkboxdisabled) {
          this.idsOrcamentosEntradaOnFilter.push(e);
          // this.idsOrcamentosEventosEntrada.push(e);
        }
      });

      this.verificarBotaoEdit();

    } else {
      this.idsOrcamentosEntradaOnFilter = new Array();
    }




  }




  verificarBotaoEdit() {

    if (this.idsOrcamentosEntrada.length > 0) {

      this.toolbarService.alterarStatus('edit', false, 'Atribuir Orçamento', () => this.mostraModalRelacionamento());

      this.selecionarTodosDisabled = false;
    } else {

      this.toolbarService.alterarStatus('edit', true, 'Atribuir Orçamento', () => this.mostraModalRelacionamento());
      this.selecionarTodosDisabled = true;
    }


  }


  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {
    //

  }


  mostraModalRelacionamento() {
    this.exibirRelacionamento = true;

    if (this.centroCustoSelecionadoId === undefined || this.centroCustoSelecionadoId === 0) {
      return;
    }

    const listaOrcamento = {
      empresaId: this.dblinkedSessionService.empresa.id,
      mes: this.dblinkedSessionService.periodo.month.value,
      ano: this.dblinkedSessionService.periodo.year.value,
      planoContaId: this.idsOrcamentosEventosEntrada[0].planoContaId,
      centroCustoId: this.centroCustoSelecionadoId,
      dataIni: this.dataIni,
      dataFin: this.dataFin
    };

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/get-orcamentos', listaOrcamento)

      .subscribe(res => {
        if (res.error) {

          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
        } else {
          this.hideMessage();
          this.listaOrcamento = new Array();

          this.listaOrcamento.push({ label: 'Nenhum selecionado', value: 0 });
          res.forEach(element => {
            this.listaOrcamento.push({ label: element.descricao, value: element.idOrcamento });
          });
        }
      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());



  }

  editar() {
    //this.exibirRelacionamento = true;
  }

  ajustes() {
    this.exibirAjustes = true;
  }





  private toolbarEnable() {
    const todos = this.buttons.slice();



    this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Atribuir Orçamento', () => this.mostraModalRelacionamento());
    this.toolbarService.replace('pesquisa', false, 'class-edit-button', 'search', 'Centro de Custo', () => this.alternarCentroCusto());


    this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Opções');
    this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar');
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo');


    // this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('add');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('save');

    this.toolbarService.alterarStatus('edit', true, 'Atribuir Orçamento', () => this.mostraModalRelacionamento());




  }


  alternarCentroCusto() {
    this.modalAlternarCentroCusto = true;
    this.httpService.wait();
    this.httpService.get('/custom/orcamento/listar-centro-custo-usuario(' + this.dblinkedSessionService.empresa.id +
      ',' + this.sessionService.loggedUser.id + ')')

      .subscribe(ref => {

        if (ref.error) {

          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: ref.message });
        } else {
          this.loadCentroCusto(ref)
          this.change();

        }

      }
        ,
        error => this.httpService.handleError(error, () => this.alternarCentroCusto()),
        () => this.httpService.done());

  }

  alternarPeriodo() {
    this.modalPeriodo = true;

  }

  alternarCentroCustoSelecionada(event: any) {

    this.centroCustoSelecionado = event.value;
    this.subtitle = 'Centro de custo: ' + event.value.descricaoCompleta;
    this.centroCustoSelecionadoId = event.value.id;

    this.change();


  }

  loadCentroCusto(value: Array<any>) {

    this.opcoesCentroCusto = [];
    this.opcoesCentroCusto.push({ label: 'Nenhum', value: null });

    value.forEach(element => {
      this.opcoesCentroCusto.push({
        label: element.codCcus + ' - ' + element.descrCcus, value: element
      });

    });
  }

  changeDataIni(event: any) {
    console.log(event);

  }

  selecionarComentario(event: any) {
    this.exibirComentario = true;
    this.comentario = '';
    this.podeEditar = true;
    this.comentarioId = 0;
    this.orcamentoId = event.orcamentoId;

    this.buscarComentariosOrcamentoId(event.orcamentoId, this.sessionService.loggedUser.id );
  }

  buscarComentariosOrcamentoId(orcamentoId: number, userId: number){

    this.comentariosOrcamento = new Array();

    this.httpService.wait();
    this.httpService.get('/custom/orcamento/get-comentarios(' + orcamentoId +
      ',' + userId + ')')

      .subscribe(ref => {
        ref.forEach(element => {
          this.comentariosOrcamento.push(element);
        });

      }
        ,
        error => this.httpService.handleError(error, () => this.selecionarComentario(event)),
        () => this.httpService.done());

  }


  editarComentario(event: any) {
    if (event.podeEditar) {
      this.comentario = event.comentario;
      this.podeEditar = true;
      this.comentarioId = event.comentarioId;
    } else {
      this.comentario = event.comentario;
      this.podeEditar = false;
      this.comentarioId = 0;
    }
  }

  adicionarComentario() {
    this.podeEditar = true;
    this.comentario = '';
    this.comentarioId = 0;
  }

  limparComentario() {
    this.podeEditar = false;
    this.comentario = '';
    this.comentarioId = 0;
    this.exibirComentario = false;
  }

  salvarComentario() {

    if(isNullOrUndefined(this.comentario) || this.comentario === null || this.comentario === ''){
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Comentário deverá ser preenchido' });
      return;
    }

    const comentario = {
      comentarioId  : this.comentarioId,
      comentario    : this.comentario,
      authUserId    : this.sessionService.loggedUser.id,
      orcamentoId   : this.orcamentoId,
      excluir       : false
    };

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/salvar-comentario', comentario)
      .subscribe(res => {
        if(res.error){
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.detail });
        } else {
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'MSG: ', detail: res.detail });
          this.buscarComentariosOrcamentoId(this.orcamentoId, this.sessionService.loggedUser.id );
          this.listarDashboard();
        }
      },
        error => this.httpService.handleError(error, () => this.salvarComentario()),
        () => this.httpService.done());
  }

  excluirComentario(){
    if (confirm('Deseja realmente excluir este comentário?')) {
      const comentario = {
        comentarioId  : this.comentarioId,
        comentario    : this.comentario,
        authUserId    : this.sessionService.loggedUser.id,
        orcamentoId   : this.orcamentoId,
        excluir       : true
      };

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/salvar-comentario', comentario)
        .subscribe(res => {
          if(res.error){
            this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.detail });
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'MSG: ', detail: res.detail });
            this.exibirProvisao         = true;
            this.justificativaProvisao  = '';
            this.valorProvisao = 0.00;
            this.provisaoId    = 0;
            this.buscarComentariosOrcamentoId(this.orcamentoId, this.sessionService.loggedUser.id );
            this.listarDashboard();
          }
        },
          error => this.httpService.handleError(error, () => this.salvarComentario()),
          () => this.httpService.done());

    }
  }


  selecionarProvisao(event: any) {

    this.valorMaximoProvisao = 0.00;

    this.exibirProvisao         = true;
    this.justificativaProvisao  = '';
    this.valorProvisao = 0.00;
    this.provisaoId    = 0;

    this.valorOrcamento = event.valorOrcamento;
    this.valorMaximoProvisao = event.saldoProvisao;
    this.podeEditar = true;
    this.comentarioId = 0;
    this.orcamentoId = event.orcamentoId;

    this.buscarProvisoesOrcamentoId(event.orcamentoId);
  }



  salvarProvisao() {

    if (this.validacaoProvisao()) {
      return;
    }


    const provisao = {
      provisaoId            : this.provisaoId,
      justificativaProvisao : this.justificativaProvisao,
      valorProvisao : this.valorProvisao,
      authUserId    : this.sessionService.loggedUser.id,
      orcamentoId   : this.orcamentoId,
    };

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/salvar-provisao', provisao)
      .subscribe(res => {

        if(res.error){
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.detail });
        } else {
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'MSG: ', detail: res.detail });
          this.podeEditar    = true;
          this.valorProvisao = 0.00;
          this.provisaoId    = 0;
          this.justificativaProvisao = '';
          this.buscarProvisoesOrcamentoId(this.orcamentoId);
          this.listarDashboard();
          this.provisaoId    = 0;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarComentario()),
        () => this.httpService.done());
  }

  adicionarProvisao() {

    this.podeEditar     = true;
    this.valorProvisao  = 0.00;
    this.valorProvisaoOld = 0.00;
    this.provisaoId     = 0;
    this.justificativaProvisao = '';
  }

  editarProvisao(event: any) {

    this.justificativaProvisao = event.justificativa;
    this.provisaoId            = event.id;
    this.valorProvisao         = event.valor;
    this.valorProvisaoOld      = event.valor;
    this.valorMaximoProvisao   = event.saldoValorProvisao;
    this.podeEditar            = true;
  }

  excluirProvisao(){
    if (confirm('Deseja realmente excluir esta provisão?')) {
      const comentario = {
        provisaoId  : this.provisaoId
      };

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/salvar-excluir-provisao', comentario)
        .subscribe(res => {
          if(res.error){
            this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: res.detail });
          } else {
            this.podeEditar    = true;
            this.valorProvisao = 0.00;
            this.valorProvisaoOld = 0.00;
            this.provisaoId    = 0;
            this.justificativaProvisao = '';
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'MSG: ', detail: res.detail });
            this.buscarProvisoesOrcamentoId(this.orcamentoId );
            this.listarDashboard();
          }
        },
          error => this.httpService.handleError(error, () => this.salvarComentario()),
          () => this.httpService.done());

    }
  }

  limparProvisao() {
    this.msgsModal  = [];
    this.podeEditar = false;
    this.justificativaProvisao = '';
    this.provisaoId = 0;
    this.valorProvisao = 0.00;

  }

 validacaoProvisao(){

  var saldo               : number = 0.00;
  var valorProvisao       : number = 0.00;
  var valorMaximoProvisao : number = 0.00;
  var valorProvisaoOld    : number = 0.00;

  if (!isNullOrUndefined(this.valorProvisao)) {
    valorProvisao       = Number.parseFloat( this.valorProvisao.toString().replace(".","").replace(",",".") );
  }

  if (!isNullOrUndefined(this.valorMaximoProvisao)) {
    valorMaximoProvisao = Number.parseFloat( this.valorMaximoProvisao.toString().replace(".","").replace(",",".") );
  }

  if (!isNullOrUndefined(this.valorProvisaoOld)) {
    valorProvisaoOld    = Number.parseFloat( this.valorProvisaoOld.toString().replace(".","").replace(",",".") );
  }

  if (isNullOrUndefined(this.valorProvisao)) {
    this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'O valor da provisão não pode ser nula!' });
    return true;

  } else{
    if (valorProvisao <= 0.00) {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'O valor da provisão não pode ser negativo ou zero!' });
      return true;

    }

  }





  if (!isNullOrUndefined(this.provisaoId) && this.provisaoId > 0) {
    saldo =  valorMaximoProvisao + valorProvisaoOld;

    if (valorProvisao > saldo) {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'O valor da provisão ultrapassa o valor máximo da alocado para a provisão!' });
      return true;
    }

  } else {

    if (valorProvisao > valorMaximoProvisao) {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'O valor da provisão ultrapassa o valor máximo da alocado para a provisão!' });
      return true;
    }

  }



  return false;
 }

 buscarProvisoesOrcamentoId(orcamentoId: number){

  this.provisoesOrcamento = new Array();

  this.httpService.wait();
  this.httpService.get('/custom/orcamento/get-provisoes-orcamento(' + orcamentoId + ')')
    .subscribe(ref => {
      ref.forEach(element => {
//     this.valorMaximoProvisao = element.saldoValorProvisao;
        this.provisoesOrcamento.push(element);
      });

    }
      ,
      error => this.httpService.handleError(error, () => this.selecionarComentario(event)),
      () => this.httpService.done());

}

  /*Show Hide Orçamento Gerencial*/
  isCardOrcaGer: boolean = true;
  ShowHideOrcaGer(){
    this.isCardOrcaGer = !this.isCardOrcaGer;
  }

}
