import { isNullOrUndefined } from "util";

export class EduzzCarga{
  id                : number;
  saleId            : number;
  contractId        : number;
  dateCreate        : Date;
  datePayment       : Date;
  saleStatus        : number;
  saleStatusName    : string;
  saleItemId        : number;
  saleItemDiscount  : number;
  clientFirstAccessId : number;
  saleRecuperacao     : number;
  saleFunilInfinito   :number;
  saleAmountWin       : number;
  saleNetGain         : number;
  saleCoop            : string;
  salePartner         : number;
  saleFee             : number;
  saleOthers          : number;
  saleTotal           : number;
  refundType          : string;
  refundValue         : number;
  refundPartialValue  : number;
  salePaymentMethod   : string;
  clientId            : number;
  clientName          : string;
  clientEmail         : string;
  clientCel           : string;
  producerId          : number;
  affiliateId         : number;
  producerName        : string;
  affiliateName       :string;
  contentId           :number;
  contentTitle        : string;
  contentTypeId       : number;
  clientDocument      : string;
  pagina              : number;


  constructor(eduzzCarga?: EduzzCarga | number) {
    if (!isNullOrUndefined(eduzzCarga)) {
      if (typeof eduzzCarga === 'number') {
        this.id = eduzzCarga;
      } else {
        this.id = eduzzCarga.id;
        this.saleId = eduzzCarga.saleId;
        this.contractId = eduzzCarga.contractId;
        this.dateCreate = eduzzCarga.dateCreate;
        this.datePayment = eduzzCarga.datePayment;
        this.saleStatus = eduzzCarga.saleStatus;
        this.saleStatusName = eduzzCarga.saleStatusName;
        this.saleItemId = eduzzCarga.saleItemId;
        this.saleItemDiscount = eduzzCarga.saleItemDiscount;
        this.clientFirstAccessId = eduzzCarga.clientFirstAccessId;
        this.saleRecuperacao = eduzzCarga.saleRecuperacao;
        this.saleFunilInfinito = eduzzCarga.saleFunilInfinito;
        this.saleAmountWin = eduzzCarga.saleAmountWin;
        this.saleNetGain = eduzzCarga.saleNetGain;
        this.saleCoop = eduzzCarga.saleCoop;
        this.salePartner = eduzzCarga.salePartner;
        this.saleFee = eduzzCarga.saleFee;
        this.saleOthers = eduzzCarga.saleOthers;
        this.saleTotal = eduzzCarga.saleTotal;
        this.refundType = eduzzCarga.refundType;
        this.refundValue = eduzzCarga.refundValue;
        this.refundPartialValue = eduzzCarga.refundPartialValue;
        this.salePaymentMethod = eduzzCarga.salePaymentMethod;
        this.clientId = eduzzCarga.clientId;
        this.clientName = eduzzCarga.clientName;
        this.clientEmail = eduzzCarga.clientEmail;
        this.clientCel = eduzzCarga.clientCel;
        this.producerId = eduzzCarga.producerId;
        this.affiliateId = eduzzCarga.affiliateId;
        this.producerName = eduzzCarga.producerName;
        this.affiliateName = eduzzCarga.affiliateName;
        this.contentId = eduzzCarga.contentId;
        this.contentTitle = eduzzCarga.contentTitle;
        this.contentTypeId = eduzzCarga.contentTypeId;
        this.clientDocument = eduzzCarga.clientDocument;
        this.pagina = eduzzCarga.pagina;


      }

    }

  }

}