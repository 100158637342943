export class UnidadeMedida {
  id: number;
  codigo: string;
  descricao: string;
  unidadePadrao: boolean;

  constructor(unidadeMedida?: UnidadeMedida | number) {
    if (unidadeMedida !== undefined && unidadeMedida !== null) {
      if (typeof unidadeMedida === 'number') {
        this.id = unidadeMedida;
      } else {
        this.id = unidadeMedida.id;
        this.codigo = unidadeMedida.codigo;
        this.descricao = unidadeMedida.descricao;
        this.unidadePadrao = unidadeMedida.unidadePadrao;
      }
    }
  }
}
