import { Empresa } from '../../cadastro/model/empresa.model';

export class PrioridadeRequisicao {
  id: number;
  nome: string;
  nomeEn: string;
  nomeEs: string;
  empresa: Empresa;

  constructor(prioridadeRequisicao?: PrioridadeRequisicao | number) {
    if (prioridadeRequisicao !== undefined && prioridadeRequisicao !== null) {
      if (typeof prioridadeRequisicao === 'number') {
        this.id = prioridadeRequisicao;
      } else {
        this.id = prioridadeRequisicao.id;
        this.nome = prioridadeRequisicao.nome;
        this.nomeEn = prioridadeRequisicao.nomeEn;
        this.nomeEs = prioridadeRequisicao.nomeEs;
        this.empresa = prioridadeRequisicao.empresa;
      }
    }
  }
}
