import { Component, OnInit, OnDestroy } from '@angular/core';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { Message, SelectItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { CadCcus}       from 'app/contabil/model/cad-ccus.model';
import { isNull, isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from 'app/cadastro/model/usuario.model';

@Component({
  selector: 'app-controle-orcamento-fini',
  templateUrl: './ata-orcamento.component.html',
  styleUrls: ['./ata-orcamento.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class AtaOrcamentoComponent implements OnInit, OnDestroy {

  currentStateControlOrca = 'initial';
  disabledEditBtn: boolean = true;
  disabledAjusteBtn: boolean = false;
  changeStateControlOrca() {
    this.currentStateControlOrca = this.currentStateControlOrca === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  pt: any;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  msgs: Message[];
  msgsModal: Message[];
  msgsNaoMapeadas: Message[];
  tipo: number;
  contasNaoMapeadas       : Array<any> = new Array();
  atas                : Array<any> = new Array();
  areas                : Array<any> = new Array();
  periodoOrcamentoEntrada : Array<any> = new Array();
  listaOrcamento          : Array<any> = new Array();
  listaPedidos            : Array<any> = new Array();
  listaProvisoes          : Array<any> = new Array();
  listaVersoesOrcamentos  : Array<any> = new Array();
  ajustesOrcamento        : Array<any> = new Array();
  listaRazoes             : Array<any> = new Array();
  linhasSelecionadas      : Array<string> = [];

  exibirRelacionamento: boolean;
  exibirAjustes: boolean;
  exibirNaoMapeadas: boolean;
  exibirIntercompany: boolean;
  exibirImpressao: boolean;
  exibirRazao: boolean;
  exibirRazaoEdit: boolean;
  alterarRazaoGeral: boolean = false;
  alterarRazaoMes: boolean = false;
  alterarRazaoOcorrencia: boolean = false;
  idEdit: number;

  razaoSocialEditOrig :String = '';
  razaoSocialEdit :String = '';
  razaoSocialSimplificadaEditOrig :String = '';
  razaoSocialSimplificadaEdit :String = '';

  opcoesPlanoContas: SelectItem[];
  contaSelecionada: number;


  opcoesTipoAjustesUsGaap: SelectItem[];
  tipoajustesusGaapSelecionada: string;

  opcoesGerenciais      : SelectItem[];
  idReferencial         : number;
  idsOrcamentosEntrada          : Array<any> = new Array();
  idsOrcamentosEntradaOnFilter  : Array<any> = new Array();
  idsOrcamentosEventosEntrada   : Array<any> = new Array();
  idOrcamento                   : number = 0;


  lcto                      : any;
  valorTotalDeb             : number;
  valorTotalCred            : number;
  checkedAll                : boolean = false;
  modalAplicarFiltro        : boolean = false;
  visualizaAta              : boolean = false;
  opcoesCentroCusto         : SelectItem[];
  cadCcus                   : CadCcus = new CadCcus();
  centroCustoSelecionadoId  : number = 0;
  centroCustoSelecionado    : CadCcus = new CadCcus();
  checkboxdisabled          : boolean = false;
  idTeste                   : number = 0;
  selecionarTodosDisabled   : boolean = true;
  tabIndexSelecionado       : number = 0;
  modalPeriodo              : boolean = false;
  dataIni            : any = '';
  dataFin            : any = '';
  isProcurarPorData         :boolean = false;
  isNovoParametroData : any = null;
  filtro                    : string = '';
  novoAjuste                : boolean = false;

  idAta                     : number;
  infoAta                   : string;
  dataAta                   : any = '';
  tituloAta                 : string;
  areaId                    : String;
  classificacao                    : String;
  prazoAta                   : any = '';
  responsavel                    : String;
  status                    : String;
  acao                    : String;


  idOrcamentos: Array<any> = new Array();
  checkBoxTodos: boolean;
  filteredLctos: Array<any> = new Array();
  editarSegmento: boolean = false;
  editarArea: boolean = false;
  editarSubArea: boolean = false;
  editarClassificacao: boolean = false;
  editarConta2: boolean = false;

  opcoesArea: SelectItem[] = [];
  opcoesSegmento: SelectItem[] = [];
  opcoesGerencial: SelectItem[] = [];
  opcoesSubArea: SelectItem[] = [];
  opcoesClassificacao: SelectItem[] = [];
  opcoesDescrConta2: SelectItem[] = [];

  segmento: String = '';
  gerencial: String = '';
  area: String = '';
  subarea: String = '';
  descrCta2: String = '';
  vlrIntercompany: any;
  historico: String = '';
  codCliFor: String = '';
  valor: any;

  optionsClassificacao: SelectItem[] = [
    { label: "Diversos", value: 'Diversos' },
    { label: "MdO", value: 'MdO' }
  ]

  optionsStatus: SelectItem[] = [
    { label: "Pendente", value: 'Pendente' },
    { label: "Em Andamento", value: 'Em Andamento' },
    { label: "Concluído", value: 'Concluído' }
  ]

  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
      },
    },
  };

    constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private datePipe: DatePipe,
    private httpClient: HttpClient) {
  }

  ngOnInit(): void {

    //Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.tipo = 9;

    this.titlePrincipal = 'PLANO DE AÇÃO';
    // this.titleService.title = 'Linnks - Us Gaap';
    this.subtitle = '';
    this.historico = '';
    this.codCliFor = '';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.vlrIntercompany = 0.00;
    this.valor = 0.00;
    this.exibirRelacionamento = false;
    this.exibirIntercompany = false;
    this.exibirAjustes = false;
    this.idReferencial = 0;
    this.areaId ='';
    this.periodoOrcamentoEntrada = new Array();
    this.listaPedidos      = new Array();
    this.listaProvisoes    = new Array();
    this.contasNaoMapeadas = new Array();
    this.exibirNaoMapeadas = false;
    this.toolbarService.hidden = true
    this.idOrcamentos = new Array();


    this.valorTotalDeb            = 0;
    this.valorTotalCred           = 0;
    this.centroCustoSelecionadoId = 0;
    this.centroCustoSelecionado   = new CadCcus();
    this.checkboxdisabled         = false;
    this.idTeste                  = 0;
    this.selecionarTodosDisabled  = true;
    this.tabIndexSelecionado      = 0;
    this.modalPeriodo             = false;
    this.isProcurarPorData        = false;
    this.dataIni                  = null;
    this.dataFin                  = null;
    this.novoAjuste               = false;


    this.visualizaAta             = false;
    this.dataIni                  = null;

    this.optionsFroola = {
      language: 'pt_br',
      pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
        'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
        'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
        'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
      charCounterCount: false,
      toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', '|',
        'insertImage', 'insertLink', 'insertVideo', '|', 'insertTable', 'formatOL', 'formatUL', '|',
        '|', 'insertUser', 'insertAttach', 'undo', 'redo', 'clearFormatting'],
      imageUploadMethod: 'POST',
      key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

      // Set max image size to 5MB.
      imageMaxSize: 5 * 1024 * 1024,

      // somente upload PNG and JPG.
      imageAllowedTypes: ['jpeg', 'jpg', 'png'],
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      imageStyles: { class1: 'Class 1', class2: 'Class 2' },
      imageEditButtons: ['imageReplace', 'imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],

      events: {
        'froalaEditor.initialized': function (e) {
          console.log('froalaEditor cometario - > inicializado');
        },
        /*'froalaEditor.buttons.refresh': function (e, editor) {
          // Do something here.
          console.log('froalaEditor - > inicializado' + JSON.stringify(editor) + ' | ' + JSON.stringify(e));
        },*/
        'froalaEditor.image.beforeUpload': function (e, editor, images) {
          if (images.length) {
            // Criando um File Reader.
            const reader = new FileReader();
            // Configure o leitor para inserir imagens quando elas estiverem carregadas.
            reader.onload = (ev) => {
              const result = ev.target['result'];
              editor.image.insert(result, null, null, editor.image.get());
              // console.log('Saída do leitor de arquivos' + ev,  editor.image,  ev.target['result']);
            };
            // Lendo imagem as base64.
            reader.readAsDataURL(images[0]);
          }
          // Pare o upload padrão.
          return false;
        }
      }
    };

    this.activatedRoute.params.subscribe(params => {

      if(this.tipo === 9 || this.tipo !== params.tipo ){
        this.tipo = params.tipo;
        this.change();
      }
    });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {


      this.change();

    });

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

        this.idOrcamento = 0;
        this.filtro = '';

        this.contasNaoMapeadas            = [];
        this.periodoOrcamentoEntrada      = [];
        this.idsOrcamentosEventosEntrada  = [];
        this.listaPedidos                 = [];
        this.listaProvisoes               = [];

        this.checkedAll       = false;
        this.modalPeriodo     = false;

        this.buscarAtas();
        this.buscarAreas();

        if (isNullOrUndefined(this.centroCustoSelecionadoId) || this.centroCustoSelecionadoId === 0) {
          return;
        }

        if (isNullOrUndefined(this.tabIndexSelecionado)) {
          this.tabIndexSelecionado = 0;
        }
      }
    }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPageLarge;
  }

  get rows() {
    return this.configService.applicationConfig.rows50;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {
   // this.msgs = [];
    this.msgsModal = [];
  }


  transformDate(date) {
      return this.datePipe.transform(date, 'dd/MM/yyyy');
    }


    /*Show Hide ControlOrca*/
    isCardControlOrca: boolean = true;
    ShowHideControlOrca(){
      this.isCardControlOrca = !this.isCardControlOrca;
    }


    buscarAtas(){

      this.visualizaAta = false;

      this.httpService.wait();
      this.httpService.get('/custom/orcamento/buscar-atas(' + this.dblinkedSessionService.empresa.id +',' + this.sessionService.loggedUser.id+')')
      .subscribe(res => {

        this.atas = new Array();

        res.forEach(element => {

          this.atas.push(element);

        });

      },
        error => this.httpService.handleError(error, () => this.buscarAtas()),
        () => this.httpService.done());
    }

    buscarAreas(){

      this.visualizaAta = false;

      this.httpService.wait();
      this.httpService.get('/custom/orcamento/buscar-areas(' + this.dblinkedSessionService.empresa.id +',' + this.sessionService.loggedUser.id+')')
      .subscribe(res => {


        this.opcoesArea = new Array();

        res.forEach(element => {
          this.opcoesArea.push({ label: (element.area), value: element.area });

        });

      },
        error => this.httpService.handleError(error, () => this.buscarAreas()),
        () => this.httpService.done());
    }

    novaAta(){
      this.idAta = 0;
      this.infoAta = '';
      this.dataAta = new Date();
      this.prazoAta = new Date();
      this.tituloAta = '';
      this.visualizaAta = true;
      this.area = '';
      this.classificacao = '';
      this.responsavel = '';
      this.status = '';
      this.valor = 0;
      this.acao = '';
    }


     // Função que controla um registro selecionado pelo usuário no checkbox
  check(id: any, event: any) {
    const index = this.idOrcamentos.findIndex(c => c === id);

    if (index >= 0) {
      this.idOrcamentos.splice(index, 1);
    } else {
      this.idOrcamentos.push(id);
    }
    this.verificarBotaoEdit();
  }

   // Função que controla os registros selecionados pelo checkbox quando o usuário clica no "Select All"
   checkAll() {
    this.idOrcamentos = new Array();

    if (!this.checkBoxTodos) {
      this.filteredLctos.forEach(rst => {
        rst.selected = true;
        this.idOrcamentos.push(rst.orcId);
      });
    } else {
      this.filteredLctos.forEach(rst => {
        rst.selected = false;
      });
    }
    this.verificarBotaoEdit();
  }

   /* Função que realiza o controle dos registros selecionados a partir de um filtro aplicado */
   onFilter(event: any) {

    this.filteredLctos.forEach(rst => {
      rst.selected = false;
    });

    this.filteredLctos = new Array();
    this.idOrcamentos = new Array();

    event.filteredValue.forEach(element => {
      this.filteredLctos.push(element);
    });

    this.checkBoxTodos = false;
    this.verificarBotaoEdit();
  }

  verificarBotaoEdit(){

    if (this.idOrcamentos) {
      this.disabledEditBtn = false;
      this.selecionarTodosDisabled = false;
      this.disabledAjusteBtn = true;
    } else {
      this.disabledEditBtn = true;
      this.selecionarTodosDisabled = true;
      this.disabledAjusteBtn = false;
    }


  }

  visualizarAta(ata: any){

    this.areaId = '';

    this.visualizaAta = true;
    this.idAta = ata.id;
    this.tituloAta = ata.titulo;
    this.infoAta = ata.ata;
    this.dataAta = this.utilService.dateWithoutTimeZone(ata.data);
    this.areaId = ata.areaId;
    this.classificacao = ata.classificacao;
    this.valor = ata.valor;
    this.acao = ata.acao;
    this.responsavel = ata.responsavel;
    this.prazoAta = this.utilService.dateWithoutTimeZone(ata.prazoForm);
    this.status = ata.status;




      }



  alternarCentroCusto(){
    this.modalAplicarFiltro = true;
  }

  changeDataIni(event : any){

    this.dataFin = event;

  }


  windowOpen(url: string, target?: string) {
      setTimeout(() => {
        window.open(url, target), 1;
      });
    }



    textAreaAdjust(component: string) {
      const o = document.getElementById(component);
      o.style.height = '1px';
      o.style.height =
        o.scrollHeight > o.clientHeight ? 25 + o.scrollHeight + 'px' : '60px';
    }

    public initControlsFroala;
    public deleteAllFroala;
    public initialize(initControls) {
      this.infoAta = '';
      this.initControlsFroala = initControls;
      this.deleteAllFroala = function () {
        this.initControlsFroala.getEditor()('html.set', '');
        this.initControlsFroala.getEditor()('undo.reset');
        this.initControlsFroala.getEditor()('undo.saveStep');
      };
    }

    public optionsFroola: Object = {};

    extAreaThree(str: boolean): void {

      this.infoAta = '';
      if (str) {
        document.getElementById('divTextAreaThree').style.display = 'none';
        document.getElementById('textAreaThree').style.display = 'inline';
        /** construir froola */
        this.initControlsFroala.getEditor()('html.set', '');
        this.initControlsFroala.initialize();

        /** final construir. */
      } else {
        document.getElementById('textAreaThree').style.display = 'none';
        document.getElementById('divTextAreaThree').style.display = 'inline-block';
        /** destruir froola */
        this.initControlsFroala.getEditor()('html.set', '');
        this.initControlsFroala.destroy();
        /** final destruir. */
      }
    }


    textAreaThreeEdit(str: boolean, comentario: any): void {
      if (str) {
        document.getElementById('divTextAreaThree').style.display = 'none';
        document.getElementById('textAreaThree').style.display = 'inline';
        /** construir froola */
        this.initControlsFroala.getEditor()('html.set', '');
        this.initControlsFroala.initialize();



      } else {
        document.getElementById('textAreaThree').style.display = 'none';
        document.getElementById('divTextAreaThree').style.display = 'inline-block';
        /** destruir froola */
        this.initControlsFroala.getEditor()('html.set', '');
        this.initControlsFroala.destroy();
        /** final destruir. */
      }
    }

    textAreaThreeSave() {

      this.infoAta = this.initControlsFroala.getEditor()('html.get', '');

      if (this.infoAta === '') { // se texto vazio!
        document.getElementById('textAreaThree').style.display = 'none';
        document.getElementById('divTextAreaThree').style.display = 'inline-block';
        /** destruir froola */
        this.initControlsFroala.getEditor()('html.set', '');
        this.initControlsFroala.destroy();
        /** final destruir. */
        return
      }

    }

    textAreaThree(str: boolean): void {

      this.infoAta = '';
      if (str) {
        document.getElementById('divTextAreaThree').style.display = 'none';
        document.getElementById('textAreaThree').style.display = 'inline';
        /** construir froola */
        this.initControlsFroala.getEditor()('html.set', '');
        this.initControlsFroala.initialize();

        /** final construir. */
      } else {
        document.getElementById('textAreaThree').style.display = 'none';
        document.getElementById('divTextAreaThree').style.display = 'inline-block';
        /** destruir froola */
        this.initControlsFroala.getEditor()('html.set', '');
        this.initControlsFroala.destroy();
        /** final destruir. */
      }
    }

    salvarAta(acao: any) {


      const listasEnviar = {
        id: this.idAta,
        empresaId: this.dblinkedSessionService.empresa.id,
        usuario: this.sessionService.loggedUser.id,
        titulo: this.tituloAta,
        ata: this.infoAta,
        acao: acao !== 'remove' ? acao === 'salvar' && this.idAta === 0 ? 'novo' : 'edit' : 'remove',
        data: this.utilService.dateWithoutTimeZone(this.dataAta),
        area: this.areaId,
        classificacao: this.classificacao,
        prazo: this.utilService.dateWithoutTimeZone(this.prazoAta),
        responsavel: this.responsavel,
        status: this.status,
        valor: this.valor,
        planoAcao: this.acao

      };


      if (confirm('Deseja realmente '+(acao !== 'remove' ? 'salvar' : 'excluir') +' este Plano de Ação?')) {

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/post-ata-reuniao', listasEnviar)
        .subscribe(result => {


          if (!result.error) {
            this.msgs = [{
              severity: 'success',
              summary: 'MSG: ',
              detail: result.message


            }];
            this.change();
          } else {
            this.msgs = [{
              severity: 'warn',
              summary: 'MSG: ',
              detail: result.message
            }];
            return;
          }

        },
          error => this.httpService.handleError(error, () => this.salvarAta(acao)), () => this.httpService.done());
      }

    }

    impressao() {

      if(this.idAta === 0 ){
        alert('É necessário salvar o Plano antes de realizar a impressão')
        return;

      } else {
        if (confirm('Deseja realmente realizar a impressão deste Plano de Ação?')) {


          this.httpService.wait();
          this.httpService.get('/custom/orcamento/impressao-ata(' + this.idAta + ')')
            .subscribe(result => this.exportedAta(result),
              error => this.httpService.handleError(error, () => this.impressao()),
              () => this.httpService.done());
        }
      }



    }

    exportedAta(result?: any) {
      if (!result.error) {
        window.open(this.httpService.url('/custom/file/' + result.file + '/atapdf'), '_blank');
      } else {
        this.msgs.push({ severity: 'warn', summary: 'Advertência', detail: result.message });
      }
    }



}
