import { ConfigPattern } from "../interfaces/eye"

export function resetConfigPattern(): ConfigPattern {
  let configPattern: ConfigPattern = {
    usuario: null,
    senha: null,
    cliente_padrao_id: null,
    taxaServicoId: null
  }

  return {...configPattern}
}