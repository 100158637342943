import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { isNullOrUndefined } from 'util';
import {GetNetPagamento} from 'app/getnet/model/getnet-pagamento-cartao-credito.model'
import {Eventos} from 'app/eventos/model/eventos.model';
import {GetNetPlano} from '../model/getnet-plano.model'
import { GetNetAssinatura} from '../model/getnet-assinatura.model';
import { Message, MessageService } from 'primeng/api';


@Component({
  selector: 'app-getnet-assinatura',
  templateUrl: './getnet-assinatura.component.html',
  styleUrls: ['./getnet-assinatura.component.scss', '../../shared/styles/centralweb.scss'],
  providers: [CrudService]
})
export class GetnetAssinaturaComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;


  listaGetnetAssinaturas  : Array<any> = new Array();
  idLista           : Array<any> = new Array();

  msgs: Message[] = [];
  msgsModal: Message [] = [];
  msgsAlterar: Message[] = [];
  checkedAll = false;

  btnCancelarDisabled               : boolean = false;
  modalInformacoes                  : boolean = false;
  getNetPagamento                   = new GetNetPagamento();
  getNetPlano                       = new GetNetPlano();
  getNetAssinatura                  = new GetNetAssinatura();

  evento                            = new Eventos();

  valorEstorno                      : number = 0.00;
  selectedIndex                     : number = 0;
  justificativaEstorno              : string = '';

  opcoesComboStatus                 : Array<any> = new Array();
  nomeCompleto                      : string = '';

  modalPeriodo : boolean = false;
  dataIni: Date;
  dataFin: Date;


  getNetParcelaId          : number = 0;
  getnetPlanId             : string = '';
  subscriptionId           : string = '';
  colsListaGetnetAssinaturas : any[] = [];

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'GETNET ASSINATURAS';
    this.subtitle = '';
    this.titleService.title = 'GETNET ASSINATURAS';

    this.checkedAll = false;
    this.msgs                           = [];
    this.msgsAlterar                    = [];
    this.modalInformacoes = false;

    this.listaGetnetAssinaturas = new Array();

    this.idLista        = new Array();
    this.selectedIndex  = 0;

    this.modalInformacoes = false;

    this.getnetPlanId   = '';
    this.subscriptionId = '';
    this.nomeCompleto   = '';

    this.colsListaGetnetAssinaturas = [
      { field: 'subscriptionId', header: 'ASSINATURA ID' },
      { field: 'nomeCompleto', header: 'NOME CLIENTE' },
      { field: 'createDate', header: 'DT CRIAÇÃO ASS.' },
      { field: 'endDate', header: 'DT FINAL ASS.' },
      { field: 'installmentStartDate', header: 'DT INICIO PAG.' },
      { field: 'endDate', header: 'DT FINAL PAG.' },
      { field: 'statusPt', header: 'STATUS' }
    ];


    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
          && this.dblinkedSessionService.periodo.year) {


            const ano = this.dblinkedSessionService.periodo.year.value;
            const lastDay = new Date(ano, this.dblinkedSessionService.periodo.month.value + 0, 0); // ultimo dia do mês
            // formatando meses com dois digitos
            let mes = this.dblinkedSessionService.periodo.month.value > 9 ?
              (+this.dblinkedSessionService.periodo.month.value  ) :'0'  + (+this.dblinkedSessionService.periodo.month.value );

            this.dataIniSearch = ano + '-' + mes + '-01';
            this.dataFinSearch = ano + '-' + mes + '-' + lastDay.getDate();

            this.listar();
            setTimeout(() => {
              this.toolbarEnable();
            }, 500);

          }


      });

  }

 listar(){

  this.checkedAll = false;
  this.listaGetnetAssinaturas = new Array();
  this.idLista          = new Array();

  this.getNetParcelaId  = 0;

  this.httpService.wait();
  this.httpService.get('/custom/getnet/get-lista-getnet-assinaturas(' + [ this.dataIniSearch , this.dataFinSearch] + ')')
    .subscribe(retorno => {
      console.log(retorno);
      this.listaGetnetAssinaturas = new Array();
      this.opcoesComboStatus    = new Array();
      retorno.forEach(i =>{
        this.listaGetnetAssinaturas.push(i);

        try {
            var index = 0;

            if (this.opcoesComboStatus !== null || this.opcoesComboStatus !== undefined) {
              index = this.opcoesComboStatus.findIndex(e => e.value === i.statusPt);
            }

            if (index === -1) {
              this.opcoesComboStatus.push({label: i.statusPt , value: i.statusPt});
            }

            this.opcoesComboStatus.sort((a,b) => a.label.localeCompare(b.label));

        } catch (error) {

        }




      })
    },
      error => this.httpService.handleError(error, () => this.listar()),
      () => this.httpService.done());

 }


  hideMessage() {

    this.msgsAlterar  = [];


  }

  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;

    this.idLista = new Array();

    this.listaGetnetAssinaturas.forEach(i=> {
      if (!this.checkedAll) {
        i.selected = false;
        this.idLista.slice(i.id, 1);
      } else {
        i.selected = true;
        this.idLista.push(i.id);
      }
    });


    this.configuraToolBar();
  }

  configuraToolBar() {



    if (isNullOrUndefined(this.idLista) || this.idLista.length === 0) {
    // this.toolbarService.alterarStatus('remove', true, 'Excluir Consultor', () => this.excluirConsultor());
     //this.toolbarService.alterarStatus('add', false, 'Novo Consultor', ()  => this.showModalNovoConsultor());
     //this.toolbarService.alterarStatus('export', true, 'Editar Consultor', () =>() => this.showModalEditarConsultor());
    } else {
      if (this.idLista.length === 1) {
      //  this.toolbarService.alterarStatus('remove', false, 'Excluir Consultor', () => this.excluirConsultor());
       // this.toolbarService.alterarStatus('add', true, 'Novo Consultor', ()  => this.showModalNovoConsultor());
       // this.toolbarService.alterarStatus('export', false, 'Editar Consultor', ()  => this.showModalEditarConsultor());


      } else if(this.idLista.length > 1)  {
        //this.toolbarService.alterarStatus('remove', false, 'Excluir Consultor', () => this.excluirConsultor());
        // this.toolbarService.alterarStatus('add', true, 'Novo Consultor', () => this.showModalNovoConsultor());
        // this.toolbarService.alterarStatus('export', true, 'Editar Consultor', () => this.showModalEditarConsultor());
      }
    }

  }

  selectCheckbox(id: any, event: any) {

    if (event.selected) {
       event.selected = true;
       const index = this.idLista.findIndex(i => i === id);
       this.idLista.splice(index,1);

    } else {
      this.idLista.push(id);
    }

    this.configuraToolBar();

  }


  onFilterEvento(event: any) {
  }

  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('edit');


    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.listar()
    );



    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('save');

    this.toolbarService.replace('edit', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());

    this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo Registro',    () => ''    );

  }



  ngOnDestroy(): void {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }



  showModalInformacoes(value : any){
    console.log(value);
    this.getNetPlano      = new GetNetPlano();
    this.getNetAssinatura = new GetNetAssinatura();
    this.nomeCompleto     = '';

    this.selectedIndex = 0;

    this.getNetAssinatura.subscriptionId          = value.subscriptionId;
    this.nomeCompleto                             = value.nomeCompleto;
    this.getNetAssinatura.createDatePt            = value.createDate;
    this.getNetAssinatura.endDatePt               = value.endDate;
    this.getNetAssinatura.installmentStartDatePt  = value.installmentStartDate;
    this.getNetAssinatura.statusPt                = value.statusPt;

    this.modalInformacoes = true;

  }


  tabChange(event: any){


    switch (event.index) {
      case 1:


        break;

      default:
        break;
    }


  }


  openModalPeriodo() {

    this.msgs =[];

    this.modalPeriodo = true;
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
  }

  periodoChange() {
    this.msgs =[];



    if (isNullOrUndefined(this.dataIni) || this.dataIni.toString() ==='') {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Favor selecionar uma data inicial. Verifique!' });
      return;

    }

    if (isNullOrUndefined(this.dataFin) || this.dataFin.toString() ==='') {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Favor selecionar uma data final. Verifique!' });
      return;
    }

    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() +1 > 9 ?
                    (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() +1 > 9 ?
                    (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 9) ?
                    this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
                    this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }
    const d1 = new Date(this.dataIniSearch);
    const d2 = new Date(this.dataFinSearch);
    if (d1 > d2) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Data incial maior que a final. Verifique!' });
      return;
    }


    this.httpService.wait();
    this.httpService.get('/custom/getnet/get-lista-getnet-assinaturas(' + [ this.dataIniSearch , this.dataFinSearch] + ')')
      .subscribe(retorno => {
        this.listaGetnetAssinaturas = new Array();
        this.opcoesComboStatus    = new Array();
        retorno.forEach(i =>{
          this.listaGetnetAssinaturas.push(i);
          try {
              var index = 0;

              if (this.opcoesComboStatus !== null || this.opcoesComboStatus !== undefined) {
                index = this.opcoesComboStatus.findIndex(e => e.value === i.statusPt);
              }

              if (index === -1) {
                this.opcoesComboStatus.push({label: i.statusPt , value: i.statusPt});
              }

              this.opcoesComboStatus.sort((a,b) => a.label.localeCompare(b.label));
          } catch (error) {

          }



        })

      },
        error => this.httpService.handleError(error, () => this.periodoChange()),
        () => this.httpService.done());

  }


onFilter(event: any){

}


}
