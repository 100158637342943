import { CadEcf } from 'app/ecf/model/cad-ecf.model';
import { TbPerApur } from 'app/ecf/model/tb-per-apur.model';

export class MovApurReal {
  id: number;
  dtIni: Date;
  dtFin: Date;
  tbPerApur: TbPerApur;
  cadEcf: CadEcf;

  constructor(movApurReal?: MovApurReal | number) {
    if (movApurReal !== undefined && movApurReal !== null) {
      if (typeof movApurReal === 'number') {
        this.id = movApurReal;
      } else {
        this.id = movApurReal.id;
        this.dtIni = movApurReal.dtIni;
        this.dtFin = movApurReal.dtFin;
        this.tbPerApur = movApurReal.tbPerApur || new TbPerApur();
        this.cadEcf = movApurReal.cadEcf || new CadEcf();
      }
    }
  }
}
