import { isNullOrUndefined } from "util";

export class TipoOrcamento{
  id        : number;
  codigo    : string;
  descricao : string;
  ativo     : boolean;

  constructor(tipoOrcamento?: TipoOrcamento | number){
    if(!isNullOrUndefined(tipoOrcamento)){
      if(typeof tipoOrcamento === "number"){
        this.id = tipoOrcamento;
      }else{
        this.id        = tipoOrcamento.id;
        this.codigo    = tipoOrcamento.codigo;
        this.descricao = tipoOrcamento.descricao;
        this.ativo     = tipoOrcamento.ativo;
      }
    }
  }
}