import { Component, OnInit, AfterViewInit, OnDestroy, HostListener, Renderer2, ViewChild } from '@angular/core'
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RoutesRecognized } from '@angular/router'
import { Subscription } from 'rxjs/Subscription'
import { AuthLoggedUser } from './proof/model/auth-logged-user.model'
import { NavigationMenu } from './shared/model/navigation-menu.model'
import { ToolbarButton } from './shared/model/toolbar-button.model'
import { ConfigService } from './shared/services/config.service'
import { SessionService } from './shared/services/session.service'
import { ToolbarService } from './shared/services/toolbar.service'
import { HttpService } from './shared/services/http.service'
import { TitleService } from './shared/services/title.service'
import { LoginService } from './shared/services/login.service'
import { ScrollService } from './shared/services/scroll.service'
import { UtilService } from './shared/services/util.service'
import { NavigationMenuService } from './shared/services/navigation-menu.service'
import { MatSidenav } from '@angular/material/sidenav'
import { CnpjPipe } from './shared/pipes/cnpj.pipe'
import { DblinkedSessionService } from './shared/services/dblinked-session.service'
import { Usuario } from 'app/cadastro/model/usuario.model'
import { ValidatorsService } from './shared/services/validators.service'
import { MessageService, PrimeNGConfig } from 'primeng/api'
import { SidebarService } from './shared/services/sidebar.service'
import { PysocketService } from './shared/services/socket/pysocket.service'
import { Subject } from 'rxjs'
import { Empresa } from './cadastro/model/empresa.model'
declare var $: any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  private routerSubscription: Subscription
  private sessionSubscription: Subscription
  private socketSubscription: Subscription

  private scrollListener: Function
  private resizeListener: Function

  private cnpjPipe: CnpjPipe = new CnpjPipe()
  _waitForMFA: Subject<boolean> = new Subject()

  empresas: Array<any> = new Array()
  filiais: Array<any> = new Array()
  filiaisSelecionadas: Array<string> = [];

  menusPorEmpresaGrupo: Array<string> = new Array()

  lastPing?: Date = null

  versionSystem: string = 'Beta V2.1'
  idleState: string = 'Not started.'
  novaSenha: string
  novaSenha2: string
  mfaCode: string
  publicationNoticeMessage: string = ''

  private _loading: boolean = false
  private menuFromUrl: boolean = true
  _displaySideNav: boolean = true
  sidenavState: boolean = false
  showMenu: boolean = true
  novosMenus: boolean = false
  userIsActive: boolean = false
  forceResetDialog: boolean = false
  waitForMFA: boolean = false
  flowUses: boolean = false
  timedOut: boolean = false
  isPublicationNotice: boolean = false

  private SMALL_WIDTH_BREAKPOINT: number = 840

  private navigationInterval: any
  private body: Element | any
  routeData: any

  @ViewChild('mainSidenav') sidenav: MatSidenav
  @ViewChild('sidenavContainer') private sidenavContainer: any
  @ViewChild('mainContainer') private mainContainer: any

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private titleService: TitleService,
    private loginService: LoginService,
    private socketService: PysocketService,
    private scrollService: ScrollService,
    private utilService: UtilService,
    private validatorsService: ValidatorsService,
    private config: PrimeNGConfig,
    private sidebarService: SidebarService,
    public configService: ConfigService,
    public dblinkedSessionService: DblinkedSessionService,
    public messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized && this.authenticated) {
        this.routeData = data.state.root.firstChild.data
        this.multiFactorAuthentication()
      }
    })

    this.router.events.subscribe(() => {
      if (this.authenticated && !this.isScreenSmall) this.sidenav.open()
      else this.sidenav.close()
    })

    this.getEnvironment()

    this.config.setTranslation({
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    })

    setInterval(() => this.getPublicationNotice(), 3600000) //intervalo de 1 hora para checar se há algum aviso de publicação
  }

  ngOnDestroy(): void {
    clearInterval(this.navigationInterval)
    this.sessionService.destroySubscribe(this.routerSubscription, this.sessionSubscription)
    this.scrollListener()
    this.scrollListener = undefined
    this.resizeListener = undefined
  }

  ngAfterViewInit(): void {
    const sidenavContent = this.sidenavContainer._element.nativeElement.querySelector('.mat-drawer-content')
    this.body = document.querySelector('body')
    this.utilService.mainContainer = this.mainContainer.nativeElement
    this.scrollService.update(sidenavContent)

    this.scrollListener = this.renderer.listen(sidenavContent, 'scroll', (event: any) => {
      this.scrollService.target = event.target
      this.scrollService.top = event.target.scrollTop
      this.scrollService.left = event.target.scrollLeft
      this.scrollService.width = event.target.offsetWidth
      this.scrollService.height = event.target.offsetHeight

      this.scrollService.scroll.next(event.target)
    })

    this.resizeListener = this.renderer.listen(window, 'resize', (event: any) => {
      this.scrollService.update(sidenavContent)
    })
  }

  get tests(): boolean {
    return this.configService.tests
  }

  get isScreenSmall(): boolean {
    return window.matchMedia('(max-width: ' + this.SMALL_WIDTH_BREAKPOINT + 'px)').matches
  }

  get isScreenFull(): boolean {
    return (document.fullscreenElement !== undefined && document.fullscreenElement !== null)
  }

  get fullScreenTooltip(): string {
    return this.isScreenFull ? 'Exibir no navegador' : 'Exibir em tela cheia'
  }

  get canLogout(): boolean {
    try { return !this.editing }
    catch(exception) { return true }
  }

  get editing(): boolean {
    return this.sessionService.editing
  }

  get authenticated(): boolean {
    return this.httpService.authenticated || this.loginService.authCheck()
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons
  }

  get navigationMenu(): NavigationMenuService {
    return this.sessionService.navigationMenu
  }

  get selectedMenu(): NavigationMenu {
    if (this.activeMenu && this.currentMenu && this.activeMenu.id === this.currentMenu.id) {
      if (this.currentMenu.menus && this.currentMenu.menus.length > 0) {
        const menus = this.currentMenu.menus.filter(menu => this.checkMenu(menu))
        if (menus.length > 0) return menus[0]
      }
    }
    return this.activeMenu
  }

  get activeMenu(): NavigationMenu {
    return this.navigationMenu !== null ? this.navigationMenu.activeMenu : null
  }

  get currentMenu(): NavigationMenu {
    return this.navigationMenu !== null ? this.navigationMenu.currentMenu : null
  }

  get currentMenus(): Array<NavigationMenu> {
    if (!this.navigationMenu) return new Array()

    return this.navigationMenu.menus.filter(menu => {
      return this.checkMenu(menu)
    })
  }

  get displayBackMenu(): boolean {
    return this.navigationMenu && this.navigationMenu.displayBack
  }

  get backMenuTitle(): string {
    return this.navigationMenu && this.navigationMenu.previousMenu ? this.navigationMenu.previousMenu.title : ' Início'
  }

  get backMenuHint(): string {
    return this.navigationMenu && this.navigationMenu.previousMenu ? this.navigationMenu.previousMenu.hint : ''
  }

  get loading(): boolean {
    return this._loading || this.httpService.loading
  }

  get offline(): boolean {
    return this.httpService.offline
  }

  get displaySidenav(): boolean {
    return this.authenticated && this._displaySideNav
  }

  get loggedUser(): AuthLoggedUser {
    return this.sessionService.loggedUser
  }

  get usuario(): Usuario {
    return this.dblinkedSessionService.usuario
  }

  get root(): boolean {
    return this.loggedUser && this.configService.root !== null && (this.loggedUser.login === this.configService.root || this.loggedUser.mail === this.configService.root)
  }

  get applicationTitle(): string {
    return this.titleService.applicationTitle
  }

  get title(): string {
    return this.titleService.title
  }

  get toolbarHidden(): boolean {
    return this.toolbarService.hidden
  }

  get displayEmpresa(): boolean {
    return this.authenticated && this.dblinkedSessionService.displayEmpresa
  }

  get disableEmpresa(): boolean {
    return this.editing || this.dblinkedSessionService.disableEmpresa
  }

  get displayPeriodo(): boolean {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.displayPeriodo
  }

  get displayFiliais(): Array<string> {
    return this.filiais;
  }


  initialize(): void {
    let cancel = false

    this.sessionService.navigationMenu = new NavigationMenuService(this.configService)
    this.sessionService.navigationMenu.initMenu(this.router.url)

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.router.url === event.url) cancel = true
        if (this.configService.production) this._loading = true
      } else if (event instanceof NavigationEnd) {
        this._loading = false
        if (!cancel) {
          this.toolbarService.clear()
          this.toolbarService.init.next()
          this.toolbarLnks()
          this.gravarLog()

          if (this.loginService.isLogoutPath(this.router.url)) {
            this.sessionService.navigationMenu = new NavigationMenuService(this.configService)
            this.sessionService.navigationMenu.initMenu('/')
          } else if (!this.loginService.isLoginPath(this.router.url) && this.menuFromUrl) {
            this.menuFromUrl = false
            this.sessionService.navigationMenu = new NavigationMenuService(this.configService)
            this.sessionService.navigationMenu.initMenu(this.router.url)
          }
        }
        cancel = false
      } else if (event instanceof NavigationCancel || event instanceof NavigationError) {
        cancel = true
        this._loading = false
      }
    })

    $(document).ready(() => {
      $("#ueEyePass0").mousedown(function () {
        $("#uePassowrd0").attr("type", "text")
      })
      $("#ueEyePass0").mouseup(function () {
        $("#uePassowrd0").attr("type", "password")
      })
      $("#ueEyePass1").mousedown(function () {
        $("#uePassowrd1").attr("type", "text")
      })
      $("#ueEyePass1").mouseup(function () {
        $("#uePassowrd1").attr("type", "password")
      })
    })

    this.sessionSubscription = this.dblinkedSessionService.sessionLoaded.subscribe(() => {
      this.empresas = new Array();

      if (this.dblinkedSessionService.empresas.length === 0) {
        this.logout()
        alert("Você não possui nenhuma empresa ativa")
      }
      this.dblinkedSessionService.empresas.forEach(empresa => {
        this.empresas.push({
          label: this.cnpjPipe.transform(empresa.cnpj) + ' - ' + empresa.nomeFantasia,
          value: empresa
        })
      })
      this.empresaChange()
      // this.retornaMenusDoUsuario()
    })

    this.socketSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.getPublicationNotice() // chamando ao inicializar a aplicação
        this.socketService.disconnect()
        this.socketService.connect(this.dblinkedSessionService.empresa, this.sessionService.loggedUser)
      }
    })
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any): void {
    this.sessionHandle(true)
  }

  @HostListener('window:load', ['$event'])
  loadHandler(event: any): void {
    this.sessionHandle()
  }

  sessionHandle(remove?: boolean): void {
    const sessions = parseInt(localStorage.getItem('sessions'), 10) || 0

    if (remove && sessions <= 1) localStorage.removeItem('sessions')
    else localStorage.setItem('sessions', (sessions + (remove ? -1 : 1)).toString())
  }

  async getEnvironment(): Promise<void> {
    this.httpService.wait()
    try {
      if (this.configService.applicationConfig.testsEnvironment !== false) await this.httpService.get('/custom/environment').toPromise().then(res => {
        this.configService.tests = res.tests
        if (this.configService.tests) this.configService.applicationTitle += ' - AMBIENTE PARA TESTES'
        this.initialize()
      }).catch(err => this.httpService.handleError(err, () => this.ngOnInit()))
      else this.initialize()
    } catch(err) {
      this.httpService.handleError(err, () => this.ngOnInit())
    } finally {
      this.httpService.done()
    }
  }

  async mfaVerification(): Promise<void> {
    const params = { codigo: this.mfaCode }
    this.httpService.wait()

    try {
      await this.httpService.post('custom/cadastro/mfa-verification', params).toPromise().then(res => {
        if (res.error == false) {
          this.waitForMFA = false
          this.toolbarService.hidden = false
        }
        else alert(res.value)
      })

      this._waitForMFA.next(false)
    } catch(err) {
      this.httpService.handleError(err)
    } finally {
      this.httpService.done()
    }
  }

  async gravarLog(): Promise<void> {
    try {
      if ( this.router.url && this.loggedUser && this.loggedUser.id && this.dblinkedSessionService && this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id) {
        const params = {
          menuUrl: this.router.url,
          authUserId: this.loggedUser.id,
          empresaId: this.dblinkedSessionService.empresa.id
        }

        await this.httpService.post('/custom/cadastro/gravar-log', params).toPromise()
      }
    } catch(err) {
      this.httpService.handleError(err)
    }
  }

  async multiFactorAuthentication(): Promise<void> {
    if (this.routeData && this.routeData.mfa) this.waitForMFA = true
    else {
      this.waitForMFA = false
      return
    }

    this.toolbarService.hidden = true

    try {
      await this.httpService.post('custom/cadastro/mfa-mail').toPromise().then(res => {
        if (res.error == true) alert(res.value)
      })
    } catch(err) {
      this.httpService.handleError(err)
    }
  }

  async getUserMenu(uId: number, eId: number): Promise<void> {
    this.httpService.wait()
    try {
      await this.httpService.get('/custom/cadastro/menus-grupos?authUserId=' + uId + '&empresaId=' + eId).toPromise().then(res => {
        this.menusPorEmpresaGrupo = []
        this.novosMenus = true
        res.value.forEach(linha => this.menusPorEmpresaGrupo.push(linha))
        this.userIsActive = res.userIsActive

        if (this.menusPorEmpresaGrupo.length == 0) return

        let rotaAtivada = this.router.url
        let menuAutorizado = this.currentMenus.some(menu => {
          if (menu.link !== null && menu.link !== undefined) if (menu.link[0] === rotaAtivada) return true
        })

        if (menuAutorizado === false) {
          for (let i = 0; i < this.menusPorEmpresaGrupo.length; i++) {
            if (this.menusPorEmpresaGrupo[i].includes("*")) {
              menuAutorizado = rotaAtivada.includes(this.menusPorEmpresaGrupo[i].split("*")[0])
              return true
            }
          }
        }

        if (menuAutorizado === false) {
          this.router.navigateByUrl('/dashboard')
          alert("Você não possui permissão para acessar " + rotaAtivada.toString())
        }
      }).catch(err => this.httpService.handleError(err, () => this.ngOnInit()))
    } catch(err) {
      this.httpService.handleError(err, () => this.ngOnInit())
    } finally {
      this.httpService.done()
    }
  }

  async salvarNovaSenha(): Promise<void> {
    if (!this.validatorsService.validaSenha(this.novaSenha, this.novaSenha2)) return

    let req1 = {
      authUserId: this.loggedUser.id,
      novaSenha: this.novaSenha,
      forcePasswordReset: false,
      usuarioQueAlterou: this.sessionService.loggedUser.id
    }

    this.httpService.wait()

    try {
      await this.httpService.post('/custom/cadastro/controle-acesso/alterar-senha', req1).toPromise().then(res => {
        this.loginService.forceReset = false
        if (res.error) {
          alert('Algum erro ocorreu ao alterar a senha do usuário!')
          return
        }
      })
    } catch(err) {
      this.httpService.handleError(err)
    } finally {
      this.httpService.done()
      this.forceResetDialog = false
    }
  }

  async retornaMenusDoUsuario(): Promise<void> {
    let uId: number
    let eId: number

    try {
      this.forceResetDialog = this.loginService.forceReset

      if (this.loggedUser.id > 0) uId = this.loggedUser.id
      else return

      if (this.dblinkedSessionService.empresa.id > 0) eId = this.dblinkedSessionService.empresa.id
      else return

      await this.getUserMenu(uId, eId)
    } catch (err) {
      this.errorHandlerImg(err)
    }
  }

  srcImg(): string {
    let url = ''
    try {
      if (this.dblinkedSessionService && this.dblinkedSessionService.empresa.id)  url = 'https://aws-website-downloads-e72og.s3.amazonaws.com/LOGOTIPOS/menu_octa/' + this.dblinkedSessionService.empresa.id + '.png'
      else url = 'https://aws-website-downloads-e72og.s3.amazonaws.com/LOGOTIPOS/menu_octa/159.png'
      return url
    } catch (error) {
      url = 'https://aws-website-downloads-e72og.s3.amazonaws.com/LOGOTIPOS/menu_octa/159.png'
      return url
    }
  }

  errorHandlerImg(event): void {
    event.target.src = "https://aws-website-downloads-e72og.s3.amazonaws.com/LOGOTIPOS/menu_octa/159.png"
  }

  manualLinnks(): void {
    window.open(this.httpService.url('https://sites.google.com/linnks.com.br/base-conhecimento-octalink/tutoriais?authuser=0'), '_blank')
  }

  logout(): void {
    this.sidenav.close()
    Promise.resolve(null).then(() => this.toolbarService.clear())
    this.loginService.doLogout()
    this.socketService.disconnect()
  }

  empresaChange(): void {
    this.dblinkedSessionService.empresaChanged.next(this.dblinkedSessionService.empresa)

    this.filiais = new Array();
    this.dblinkedSessionService.filiaisSelecionadas = new Array()
    this.dblinkedSessionService.empresa.filiais.forEach(element => {
      this.filiais.push({label: element.cnpj + ' - ' + element.nomeFantasia, value: element.id});
      this.dblinkedSessionService.filiaisSelecionadas.push(element.id)
    });

    this.dblinkedSessionService.displayFiliais = false;

    if(this.dblinkedSessionService.empresa.filiais.length > 0){
      this.dblinkedSessionService.displayFiliais = true;
    } else {
      this.dblinkedSessionService.displayFiliais = false;
    }

    this.retornaMenusDoUsuario()
  }

  periodoChange(event: any): void {
    this.dblinkedSessionService.periodoChanged.next(event)
  }

  handleToggle(): void {
    this.sidenav.toggle()
    if (this.sidenavState) this.sidenavState = false
    else this.sidenavState = true
  }

  startNavigationTimer(): void {
    if (this.configService.navigationTimeout === null || this.configService.navigationTimeout < 1) return

    clearInterval(this.navigationInterval)
    this.navigationInterval = setInterval(() => {
      if (this.httpService.authenticated && this.navigationMenu.activeMenu !== null) this.navigationMenu.findMenu(this.navigationMenu.activeMenu)
    }, this.configService.navigationTimeout)
  }

  menuActivate(menu: NavigationMenu): void {
    if (this.navigationMenu.activeMenu === menu) return
    this.startNavigationTimer()
    this.navigationMenu.activeMenu = menu
  }

  menuCallback(menu: NavigationMenu): void {
    this.startNavigationTimer()
    this.navigationMenu.menuCallback(menu)
    if (menu.link) this.router.navigate(menu.link)
  }

  menuBack(): void {
    this.startNavigationTimer()
    this.navigationMenu.menuBack()
  }

  private checkMenu(menu: NavigationMenu, child?: boolean): boolean {
    if (this.novosMenus === false) return false

    if (!menu.permissions && !menu.menus) return true

    if (menu.menus && menu.checkChildren !== false && menu.menus.findIndex(m => m.check !== false && (!child || m.link) && this.checkMenu(m, true)) > -1) return true

    if (!this.loggedUser) return false

    if (!this.userIsActive && this.menusPorEmpresaGrupo.length > 0) return false

    if (this.menusPorEmpresaGrupo.length > 0 && menu.permissions && this.menusPorEmpresaGrupo.find(m => m == menu.permissions[0].url)) return true

    const apps = this.sessionService.loggedUser.authApps.filter(app => {
      return !menu.permissions || menu.permissions.findIndex(p => !p.application || app.key === p.application) > -1
    })

    if (apps.length > 0 && !menu.menus && (!menu.permissions || !menu.permissions.find(permission => permission.url !== undefined && permission.url !== null))) return true

    return false
  }

  closeSidebar(): void {
    this.sidebarService.open(false)
    this.sidebarService.clearRow()
  }

  toggleFullScreen(): void {
    if (this.isScreenFull) {
      const d: Document | any = document

      if (d.webkitExitFullscreen) d.webkitExitFullscreen()
      else if (d.exitFullscreen) d.exitFullscreen()
      else if (d.mozCancelFullScreen) d.mozCancelFullScreen()
      else if (d.msExitFullscreen) d.msExitFullscreen()
    } else {
      if (this.body.webkitRequestFullScreen) this.body.webkitRequestFullScreen()
      else if (this.body.requestFullscreen) this.body.requestFullscreen()
      else if (this.body.mozRequestFullScreen) this.body.mozRequestFullScreen()
      else if (this.body.msRequestFullScreen) this.body.msRequestFullScreen()
    }
  }

  isButtonText(button: ToolbarButton): boolean {
    return button.name === 'NOVO' || button.name === 'SALVAR' || button.name === 'ENVIAR' || button.name === 'Alternar Conta' || button.name === 'SOLICITAR' || button.name === 'EMITIR NF' || button.name === 'ESCRITURAR NF' || button.name === 'ESCRITURAR NFS' || button.name === 'BAIXAR' || button.name === 'ESCRITURAR DOC' || button.name === 'ESCRITURAR' || button.name === 'FORMALIZAR' || button.name === 'HOMOLOGAR' || button.name === 'DESFAZER' || button.name === 'Alterar Período' || button.name === 'GERAR' || button.name === 'ATUALIZAR' || button.name === 'ALTERAR' || button.name === 'SELECIONE' || button.name === 'AJUSTES' || button.name === 'Alterar Período/Conta'
  }

  isButtonIcon(button: ToolbarButton): boolean {
    return !this.isButtonText(button)
  }

  private toolbarLnks() {
    this.toolbarService.add('voltar', true, 'class-first-button', 'undo', 'Voltar',)
    this.toolbarService.add('atualizar', true, 'class-other-button', 'refresh', 'Atualizar',)
    this.toolbarService.add('check', true, 'class-other-button', 'check', 'Finalizar',)
    this.toolbarService.add('info', true, 'class-other-button', 'info', 'Info',)
    this.toolbarService.add('msg', true, 'class-other-button', 'warning', 'Visualizar',)
    this.toolbarService.add('pesquisa', true, 'class-first-button', 'search', 'Pesquisar',)
    this.toolbarService.add('download', true, 'class-other-button', 'cloud_download', 'Download',)
    this.toolbarService.add('cancel', true, 'class-other-button', 'block', 'Cancelar',)
    this.toolbarService.add('export', true, 'class-other-button', 'description', 'Exportar',)
    this.toolbarService.add('print', true, 'class-first-button', 'print', 'Imprimir',)
    this.toolbarService.add('save', true, 'class-other-button', 'save', 'Voltar',)
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir',)
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar',)
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Criar novo',)
  }

  private async getPublicationNotice(): Promise<void> {
    if(this.dblinkedSessionService.hasEmpresaSelected) {
      try {
        await this.httpService.get('avisopublicacao?$select=ativo,mensagem').toPromise().then(res => {
          this.isPublicationNotice = res.value[0].ativo
          this.publicationNoticeMessage = res.value[0].mensagem
        })
      } catch(err) {
        console.log(err)
      }
    }
  }

  filialChange() {
    this.dblinkedSessionService.empresaChanged.next(this.dblinkedSessionService.empresa)
  }
}
