import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { LoginService } from 'app/shared/services/login.service';


@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['../ecd-export/export.component.scss'],
})

export class EcfExportComponent implements OnInit, OnDestroy {
  ecfList: Array<any> = null;

  private entity: string;
  canRemove: boolean;

  private sessionSubscription: Subscription;
  private usuarioSubscription: Subscription;
  title: string;
  subtitle: string;

  constructor(private titleService: TitleService,
              private toolbarService: ToolbarService,
              private httpService: HttpService,
              private utilService: UtilService,
              private loginService: LoginService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService) {
  }

  ngOnInit(): void {
    this.title = 'ECF - ESCRITURAÇÃO CONTÁBIL FISCAL';
    this.subtitle = 'EXPORTAR, APAGAR OU APURAR';
    this.titleService.title = 'ECF - Download';

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
                                                                 () => { this.sessionChange(); });
    // Promise.resolve(null).then(() => this.toolbarRefresh());
  }

  ngOnDestroy() {
    // this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  sessionChange() {
    // this.toolbarService.clear();

    this.canRemove = false;

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasYearSelected) {
      this.httpService.wait();
      this.httpService.get('/cadecf' +
                          '?$select=dtIni,dtFin,status/id' +
                          '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
                                  ' and period(dtIni,dtFin) eq ' + this.year +
                          '&$orderby=dtIni')
                      .subscribe(cadecf => {
                                   this.ecfList = cadecf.value;
                                   this.ecfList.forEach(ecf => {
                                     ecf.homologado = ecf.status.id === 3;
                                     ecf.apurado = ecf.homologado || ecf.status.id === 2;
                                   });

                                   if (this.ecfList && this.ecfList.length > 0) {
                                     this.entity = 'ecf';
                                     this.loaded();
                                   } else {
                                     this.httpService.wait();
                                     this.httpService.get('/cadecd' +
                                                         '?$select=dtIni,dtFin,status/id' +
                                                         '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
                                                                 ' and period(dtIni,dtFin) eq ' + this.year +
                                                                 ' and movSaldosContabeisList is not empty' +
                                                         '&$orderby=dtIni')
                                                     .subscribe(cadecd => {
                                                                  this.ecfList = cadecd.value;
                                                                  this.ecfList.forEach(ecf => {
                                                                    ecf.homologado = ecf.status.id === 3;
                                                                    ecf.apurado = ecf.homologado || ecf.status.id === 2;
                                                                  });

                                                                  this.entity = 'ecd';
                                                                  this.loaded();
                                                                },
                                                                error => this.httpService.handleError(error, () => this.sessionChange()),
                                                                () => this.httpService.done());
                                   }
                                 },
                                 error => this.httpService.handleError(error, () => this.sessionChange()),
                                 () => this.httpService.done());
    }
  }

  loaded() {
    if (this.ecfList && this.ecfList.length > 0) {
      this.toolbarService.alterarStatus('export', false, 'Download', () => this.export());
      /*this.toolbarService.add('export', false, 'class-download-button', 'cloud_download', 'Download', () => this.export());
      this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar', () => this.export());
      this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir', () => this.export());*/

      this.canRemove = !this.isVirtual &&
                       this.loginService.checkPermission('/centralweb/cad' + this.entity + '(' + this.ecfList[0].id + ')', 'DELETE');

      if (this.canRemove) {
        this.toolbarService.alterarStatus('remove', false, 'Excluir selecionado(s)', () => this.remove());
        // this.toolbarService.add('remove', false, 'delete', 'delete', 'Excluir selecionado(s)', () => this.remove());
      } else {
        this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.remove());
        // this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.remove());
      }

      this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'adicionar', () => this.sessionChange());
      // this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!', () => this.export());
      // this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'adicionar', () => this.export());
      this.onSelect();
    }
  }

  onSelect() {
    this.toolbarService.get('export').disabled = this.ecfList.length === 0 || this.hasSelected;
    if (this.toolbarService.get('remove')) {
      this.toolbarService.get('remove').disabled = !this.hasSelected || this.hasSelectedApurado;
    }
  }
  onSelectAll(checked: boolean) {
    this.ecfList.forEach(ecf => {
      ecf.selected = checked;
    });
    this.onSelect();
  }

  onSelectAllStatus(undo: boolean, statusId: number) {
    let process: string;

    switch (statusId) {
      case 2:
        if (this.allApuracaoDisabled) {
          return false;
        }
        process = 'apuração';
        break;
      case 3:
        if (this.allHomologacaoDisabled) {
          return false;
        }
        process = 'homologação';
        break;
    }

    if (confirm('Deseja ' + (undo ? 'desfazer' : 'efetuar' ) + ' a ' + process + ' de todo o período?')) {
      this.httpService.wait();
      this.httpService.post('/custom/ecf/status(' + this.dblinkedSessionService.empresa.id + ',' +
                                                this.dblinkedSessionService.periodo.year.value + ',' +
                                                (undo ? statusId - 1 : statusId) + ')')
                      .subscribe(() => {
                                   this.ecfList.forEach(ecf => {
                                     ecf.status.id = undo ? statusId - 1 : statusId;
                                     ecf.homologado = ecf.status.id === 3;
                                     ecf.apurado = ecf.homologado || ecf.status.id === 2;
                                   });

                                   this.onSelect();
                                 },
                                 error => this.httpService.handleError(error, () => this.onSelectAllStatus(undo, statusId)),
                                 () => this.httpService.done());

      return true;
    }

    return false;
  }

  onClick(row: any, statusId: number, disabled: boolean, event?: any) {
    if (!this.isVirtual && !disabled && (!event || event.keyCode === 32)) {
      let undo: boolean;
      let process: string;

      switch (statusId) {
        case 2:
          undo = row.apurado;
          process = 'apuração';
          break;
        case 3:
          undo = row.homologado;
          process = 'homologação';
          break;
      }

      if (confirm('Deseja ' + (!undo ? 'desfazer' : 'efetuar' ) + ' a ' + process + ' do período?')) {
        this.onChange(row);
      }else{
        if(statusId == 2){
          row.apurado = !row.apurado;
        }else if(statusId == 3){
          row.homologado = !row.homologado;
        }
      }
    }

  }

  onChange(row: any) {
    if (row.homologado) {
      row.status.id = 3;
    } else if (row.apurado) {
      row.status.id = 2;
    } else {
      row.status.id = 1;
    }

    this.httpService.wait();
    this.httpService.post('/custom/ecf/status(' + row.id + ',' + row.status.id + ')')
                    .subscribe(() => this.onSelect(),
                               error => this.httpService.handleError(error, () => this.onChange(row)),
                               () => this.httpService.done());
  }

  remove() {
    if (confirm('Deseja excluir o(s) registro(s) selecionados?')) {
      this.removeNext();
    }
  }

  removeNext() {
    const ecf: any = this.ecfList.find(e => e.selected);

    if (ecf) {
      this.httpService.wait();
      this.httpService.delete('/cad' + this.entity, ecf.id)
                      .subscribe(() => {
                                   this.ecfList.splice(this.ecfList.findIndex(e => e.selected), 1);

                                   this.onSelect();

                                   this.removeNext();
                                 },
                                 error => this.httpService.handleError(error, () => this.removeNext()),
                                 () => this.httpService.done());
    }
  }

  export() {
    if (confirm('O arquivo txt da ECF será exportado. Deseja continuar?')) {
    this.httpService.wait();
    this.httpService.post('/custom/ecf/export(' + this.dblinkedSessionService.empresa.cnpj + ',' + this.year + ')')
                    .subscribe(result => this.download(result),
                               error => this.httpService.handleError(error, () => this.export()),
                               () => this.httpService.done());
    }
  }

  download(result: any) {
    if (!result.error) {
      if (!this.ecfList || this.ecfList.length === 0) {
        this.sessionChange();
      }

      window.open(this.httpService.url('/custom/file/' + result.file), '_blank');
    }
  }

  get selected(): Array<any> {
    if (!this.ecfList) {
      return null;
    }

    return this.ecfList.filter(ecf => ecf.selected);
  }

  get hasSelected(): boolean {
    return this.selected && this.selected.length > 0;
  }

  get hasNotSelected(): boolean {
    return this.ecfList && this.selected && this.ecfList.length === this.selected.length;
  }

  get hasSelectedApurado(): boolean {
    return this.selected && this.selected.findIndex(ecf => ecf.apurado) > -1;
  }

  get hasApurado(): boolean {
    return this.ecfList && this.ecfList.findIndex(ecf => ecf.apurado) > -1;
  }

  get hasNotApurado(): boolean {
    return this.ecfList && this.ecfList.findIndex(ecf => !ecf.apurado) > -1;
  }

  get hasAllApurado(): boolean {
    return this.hasApurado && !this.hasNotApurado;
  }

  get hasAllNotApurado(): boolean {
    return !this.hasApurado && this.hasNotApurado;
  }

  get hasHomologado(): boolean {
    return this.ecfList && this.ecfList.findIndex(ecf => ecf.homologado) > -1;
  }

  get hasNotHomologado(): boolean {
    return this.ecfList && this.ecfList.findIndex(ecf => !ecf.homologado) > -1;
  }

  get hasAllHomologado(): boolean {
    return this.hasHomologado && !this.hasNotHomologado;
  }

  get hasAllNotHomologado(): boolean {
    return !this.hasHomologado && this.hasNotHomologado;
  }

  get allApuracaoDisabled(): boolean {
    return this.isVirtual || this.hasHomologado || (this.hasApurado && !this.hasAllApurado);
  }

  get allHomologacaoDisabled(): boolean {
    return this.isVirtual || this.hasNotApurado || (this.hasHomologado && !this.hasAllHomologado);
  }

  get allApuracaoIndeterminate(): boolean {
    return this.hasApurado && !this.hasAllApurado;
  }

  get allHomologacaoIndeterminate(): boolean {
    return this.hasHomologado && !this.hasAllHomologado;
  }

  get allSelectedIndeterminate(): boolean {
    return this.hasSelected && !this.hasNotSelected;
  }

  get year(): number {
    return this.dblinkedSessionService.periodo.year.value;
  }

  get isVirtual(): boolean {
    return this.entity === 'ecd';
  }

  /*private toolbarRefresh() {
    // this.toolbarService.remove('export');
    this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }*/
}
