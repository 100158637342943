import { Empresa } from '../../cadastro/model/empresa.model';
import { TipoCcus } from '../../util/model/tipo-ccus.model';

export class CadCcus {
  id: number;
  dtAlt: Date;
  codCcus: string;
  descrCcus: string;
  empresa: Empresa;
  dtInicial: Date;
  dtFinal: Date;
  ativo: boolean;
  label: string;
  projetosRelacionados: boolean;
  tipoCcus: TipoCcus = new TipoCcus();

  constructor(ccus?: CadCcus | number) {
    if (ccus !== undefined && ccus !== null) {
      if (typeof ccus === 'number') {
        this.id = ccus;
      } else {
        this.id = ccus.id;
        this.dtAlt = ccus.dtAlt || new Date();
        this.codCcus = ccus.codCcus;
        this.descrCcus = ccus.descrCcus;
        this.empresa = ccus.empresa || new Empresa();
        this.dtInicial = ccus.dtInicial;
        this.dtFinal = ccus.dtFinal;
        this.ativo = ccus.ativo;
        this.projetosRelacionados = ccus.projetosRelacionados;
        this.tipoCcus = ccus.tipoCcus || new TipoCcus();
      }
    }
  }
}
