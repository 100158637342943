import { isNullOrUndefined } from "util";

export class FormularioMotivoCancelamento{

  id                : number;
  descricao         : string;


  constructor(formularioMotivoCancelamento?: FormularioMotivoCancelamento | number) {
    if (!isNullOrUndefined(formularioMotivoCancelamento)) {
      if (typeof formularioMotivoCancelamento === 'number') {
        this.id = formularioMotivoCancelamento;
      } else {
        this.id         = formularioMotivoCancelamento.id;
        this.descricao  = formularioMotivoCancelamento.descricao;

      }
    }
  }

}