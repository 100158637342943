import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService } from '../../../../node_modules/primeng/api';
import { Seminarista } from '../model/seminarista.model';
import { Util } from 'app/shared/common/util';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-seminarista',
  templateUrl: './seminarista.component.html',
  styleUrls: ['./seminarista.component.scss'],
})
export class SeminaristaComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  private timezonePipe: TimezonePipe;
  pt: any;
  msgs: Message[] = [];
  msgsModal: Message[] = [];

  titlePrincipal: string;
  subtitle: string;

  botaoEditar: boolean = false;
  botaoNovo: boolean = false;
  objEditar: any;

  // Requisição = selectitens
  origemRequisicao: Array<any> = new Array();
  seminarista: Seminarista;
  colsOrigemRequisicao : any[];

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
    this.timezonePipe = new TimezonePipe();
  }

  ngOnInit(): void {
    // requisição
    this.seminarista = new Seminarista();

    this.titlePrincipal = 'CONFIGURAÇÕES';
    this.subtitle = 'SEMINARISTA';
    this.titleService.title = 'Cadastro de Seminaristas';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.colsOrigemRequisicao = [
      { field: 'nome', header: 'NOME' },
      { field: 'email', header: 'EMAIL' },
      { field: 'telefone', header: 'FONE' },
      { field: 'urlOutlookEmail', header: 'Url OUTLOOK' },
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });
    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };
  }

  ngOnDestroy() {
    // this.toolbarService.clear();
    // this.toolbarService.hidden = false;
    // this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  data() {
    // this.dtCorte = new Date(this.timezonePipe.transform(this.dtCorte));
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

      this.seminaristas(this.dblinkedSessionService.empresa.id);

      // renderizando a toolbar
      setTimeout(() => {
        this.toolbarEdit();
      }, 500);
    }
  }

  onFilterRms(event: any) {
    console.log('event: ' + event);
  }

  export() {
    // alert('export');
  }

  pesquisa() {
    // alert('pesquisa');
  }

  adicionar() {

    if (this.validacao()) {
      return ;
    }

    let listaEnviar = {
      nome      : this.seminarista.nome,
      email     : this.seminarista.email,
      telefone  : this.seminarista.telefone,
      urlOutlookEmail : this.seminarista.urlOutlookEmail,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.empresa.id
    }

      this.httpService.wait();
      this.httpService.post('/custom/polozi/adicionar-seminarista', listaEnviar)
        .subscribe(rst => {
          console.log(rst);
          if (rst["error"]) {
            this.messageService.add({
              severity: 'warn',key: 'messageLinnks',
              summary: 'MSG',
              detail: rst["detail"]
            });
          } else {
            this.toolbarEdit();
            this.seminaristas(this.empresa.id);

          }
        },
          error => this.httpService.handleError(error, () => console.log('sucesso')),
          () => this.httpService.done());

  }

  seminaristas(empresaId: any) {
    this.origemRequisicao = new Array();
    this.httpService.wait();
    this.httpService.get('/custom/polozi/listar-seminarista', empresaId).subscribe(rst => {
      this.origemRequisicao = new Array();
      rst.forEach(element => {
        this.origemRequisicao.push(element);
      });
    }, error => this.httpService.handleError(error, () => this.seminaristas(empresaId)),
      () => this.httpService.done());

    this.seminarista.nome = "";
  }

  remover(value: any) {
    if (confirm("Deseja remover este seminarista?")) {
      let listaEnviar = {
        id: value.id
      }
      this.httpService.wait();
      this.httpService.post('/custom/polozi/deletar-seminarista', listaEnviar)
      .subscribe(rst => {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Semininarista Deletado com Sucesso!'
        });
        this.toolbarEdit();
        this.seminaristas(this.dblinkedSessionService.empresa.id);
      }, error => this.httpService.handleError(error, () => this.seminaristas(this.dblinkedSessionService.empresa.id)),
        () => this.httpService.done());

    }
  }

  editar(value: any){
    this.botaoNovo = true;
    this.seminarista.nome     = value.nome;
    this.seminarista.email    = value.email;
    this.seminarista.telefone = value.telefone;
    this.seminarista.urlOutlookEmail = value.urlOutlookEmail;
    this.toolbarService.alterarStatus('voltar', false, 'voltar', () => this.novo());
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Editar tipo', () => this.atualizar(value));
  }

  atualizar(value: any){

    //value.id    = this.seminarista.id;
    value.nome  = this.seminarista.nome;
    value.email = this.seminarista.email;
    value.telefone = this.seminarista.telefone;
    value.urlOutlookEmail = this.seminarista.urlOutlookEmail;

    let listEnviar = {
      id        : value.id,
      nome      : this.seminarista.nome,
      email     : this.seminarista.email,
      telefone  : this.seminarista.telefone,
      urlOutlookEmail : this.seminarista.urlOutlookEmail,
      usuario: this.sessionService.loggedUser.id
    }


    this.httpService.wait();
    this.httpService.post('/custom/polozi/atualizar-seminarista', listEnviar).subscribe(rst => {
      console.log(rst);
      if (rst["error"]) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG',
          detail: rst["detail"]
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG',
          detail: rst["detail"]
        });
        this.botaoNovo = false;
        this.toolbarEdit();
        this.seminaristas(this.dblinkedSessionService.empresa.id);

      }


    }, error => this.httpService.handleError(error, () => this.seminaristas(this.dblinkedSessionService.empresa.id)),
      () => this.httpService.done());
  }

  hideMessage() {


  }

  private toolbarEdit() {

      this.botaoNovo = false;
      const todos = this.buttons.slice();
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.replace('voltar', false, 'class-first-button', 'undo', 'Voltar', () => this.novo());
      this.toolbarService.replace('pesquisa', true, 'class-first-button', 'search', 'Pesquisar', );
      // this.toolbarService.alterarStatus('pesquisa', true, 'Detalhes Rateio', () => this.pesquisa());

      // this.toolbarService.alterarStatus('download', true, 'Download Anexos', () => this.export());

      // this.toolbarService.alterarStatus('voltar', true, 'Voltar', () => this.ngOnInit());
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.change());
      // this.toolbarService.alterarStatus('atualizar', true, 'Atualizar RMS', () => this.change());
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
      // this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.ngOnInit());
      this.toolbarService.replace('save', true, 'class-first-button', 'save', 'Salvar',() => this.ngOnInit() );
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar', () => this.ngOnInit() );



      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar novo tipo', () => this.novo());


  }

  novo(){
    this.botaoNovo = !this.botaoNovo;
    this.seminaristas(this.dblinkedSessionService.empresa.id);
    if(this.botaoNovo){
      this.seminarista = new Seminarista();
      this.toolbarService.alterarStatus('voltar', false, 'voltar', () => this.novo());
      this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar novo tipo', () => this.adicionar());
    }
    else{
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar novo tipo', () => this.novo());
    }
  }

  onSelect(event: any){
    console.log(event);
    let selecionados = 0;

    this.origemRequisicao.forEach(element => {
      if(element.selected){
        selecionados++;
      }
    });

    if(selecionados > 1){
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.remover(event));
      this.toolbarService.alterarStatus('edit', true, 'Editar registro', () => this.editar(event));
      this.botaoNovo = false;
      this.toolbarEdit();
    }else{
      if(event.selected){
        this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.remover(event));
        this.toolbarService.alterarStatus('edit', false, 'Editar registro', () => this.editar(event));
      }else if(selecionados == 0){
        this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.remover(event));
        this.toolbarService.alterarStatus('edit', true, 'Editar registro', () => this.editar(event));
        this.botaoNovo = false;
        this.toolbarEdit();
      }else{
        this.origemRequisicao.forEach(element => {
          if(element.selected){
            event = element;
          }
        });

        this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.remover(event));
        this.toolbarService.alterarStatus('edit', false, 'Salvar requisição', () => this.editar(event));
      }
    }
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  validacao(){

    if (this.seminarista.nome == null || this.seminarista.nome == undefined) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: 'Verifiquei o nome,  não pode estar vazio!' });
      return true;
    }

    if (!isNullOrUndefined(this.seminarista.email)) {
      if (!Util.validacaoEmail(this.seminarista.email)) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor digitar o email do seminarista!'
        });
        return true;
      }

    }
    return false;
  }


  //https://outlook.live.com/calendar/0/deeplink/compose
  //https://outlook.live.com/calendar/0/deeplink/compose?startdt=2020-08-12T00:00:00&enddt=2020-08-13T12:00:00&subject=Hello%20World&body=Hello%20World

}
