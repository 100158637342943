import { JsogService } from '../services/jsog.service';
import { DataObject } from '../components/data-table/model/data-object.model';

export class Util {
  static jsog = new JsogService();

  static equals(object1, object2): boolean {
    if (object1 === null || object1 === undefined || object2 === null || object2 === undefined) { return object1 === object2; }
    if (Util.isArray(object1) && object1.length !== object2.length) { return false; }

    try {
      return JSON.stringify(object1) === JSON.stringify(object2);
    } catch (exception) {
      return this.jsog.stringify(object1) === this.jsog.stringify(object2);
    }
  }

  static isArray(obj: any): any {
    return Array.isArray(obj) || Object.prototype.toString.call(obj) === '[object Array]';
  }

  static clone(object: any) {
    if (object === undefined || object === null) {
      return object;
    }

    try {
      return JSON.parse(JSON.stringify(object));
    } catch (exception) {
      return this.jsog.parse(this.jsog.stringify(object));
    }
  }

  static getFieldValue(field: any, key: string, defaultValue: any = null): any {
    if (key === undefined || key === null) {
      return defaultValue !== undefined ? defaultValue : null;
    }

    key = key.replace(/\//g, '.');

    const keys = key.split('.');
    const parent = this.getFieldParentValue(field, key);

    if (parent === undefined || parent === null) {
      return defaultValue !== undefined ? defaultValue : null;
    }

    const value = parent[keys[keys.length - 1]];

    if (value === undefined && defaultValue !== undefined) {
      return defaultValue;
    }

    return value;
  }
  static getFieldParentValue(field: any, key: string): any {
    if (key === undefined || key === null) {
      return null;
    }

    key = key.replace(/\//g, '.');

    const keys = key.split('.');
    let _field = field;

    for (let i = 0; i < keys.length - 1; i++) {
      if (_field !== null && _field !== undefined) {
        _field = _field[keys[i]];
      }
    }

    return _field;
  }

  static setFieldValue(field: any, key: string, value: any) {
    if (key === undefined || key === null) {
      return;
    }

    if (field === undefined) {
      field = new Object();
    }

    key = key.replace(/\//g, '.');

    const keys = key.split('.');
    keys.forEach((k, index) => {
      if (field[k] === undefined || (field[k] === null && index !== keys.length - 1)) {
        field[k] = new Object();
      }

      if (index === keys.length - 1) {
        field[k] = value;
      }

      field = field[k];
    });
  }

  static findById(array: Array<any>, item: any) {
    return array.find(arrayItem => arrayItem.id === (item instanceof Object ? item.key : item));
  }
  static findByKey(array: Array<any>, item: any) {
    return array.find(arrayItem => arrayItem.key === (item instanceof Object ? item.id : item.replace(/\//g, '.')));
  }
  static findDataControl(controls: Array<DataObject>, control: DataObject | string) {
    return controls.find(arrayItem => arrayItem.key === (control instanceof DataObject ? control.key : control.replace(/\//g, '.')));
  }

  static getDataControlOptions(controls: Array<DataObject>, control: DataObject | string) {
    return Util.findDataControl(controls, control).options.values;
  }

  static fixUrlPart(urlPart: string): string {
    if (urlPart === undefined || urlPart === null) {
      return '';
    }

    if (urlPart.trim().indexOf('/') === 0) {
      urlPart = urlPart.trim().substr(1);
    }

    if (urlPart.trim().lastIndexOf('/') !== urlPart.trim().length - 1 && urlPart.indexOf('?') < 0) {
      urlPart = urlPart.trim() + '/';
    }

    return urlPart.trim();
  }

  static stripNonNumeric(value: string, dots: boolean = false): string {
    if (dots) {
      return value.replace(/[^0-9.]/g, '');
    }

    return value.replace(/[^0-9]/g, '');
  }

  static isUndefined(value: any): boolean {
    return value === undefined;
  }

  static isNotUndefined(value: any): boolean {
    return !this.isUndefined(value);
  }

  static isNull(value: any): boolean {
    return value === null;
  }

  static isNotNull(value: any): boolean {
    return !this.isNull(value);
  }

  static isNullOrUndefined(value: any): boolean {
    return value === undefined || value === null;
  }

  static isNotNullOrUndefined(value: any): boolean {
    return !this.isNullOrUndefined(value);
  }

  static isEmpty(value: string): boolean {
    return value === undefined || value === null || value.trim() === '';
  }

  static isNotEmpty(value: string): boolean {
    return !this.isEmpty(value);
  }

  static isCpf(value: string) {
    if (this.isEmpty(value)) {
      return false;
    }

    const v = this.stripNonNumeric(value);

    if (parseInt(v, 10) === 0) {
      return false;
    }

    const d = v.split('');

    if (d.length !== 11 || (v.match(new RegExp(d[0], 'g')) || []).length === 11) {
      return false;
    }

    const m1 = [+d[0] * 1, +d[1] * 2, +d[2] * 3, +d[3] * 4, +d[4] * 5, +d[5] * 6, +d[6] * 7, +d[7] * 8, +d[8] * 9];
    let s1 = 0;
    for (const i in m1) {
      if (Object.prototype.hasOwnProperty.call(m1, i)) {
        s1 += m1[i];
      }
    }
    const d1 = (s1 % 11 === 10) ? 0 : (s1 % 11);

    const m2 = [+d[0] * 0, +d[1] * 1, +d[2] * 2, +d[3] * 3, +d[4] * 4, +d[5] * 5, +d[6] * 6, +d[7] * 7, +d[8] * 8, d1 * 9];
    let s2 = 0;
    for (const j in m2) {
      if (Object.prototype.hasOwnProperty.call(m2, j)) {
        s2 += m2[j];
      }
    }
    const d2 = (s2 % 11 === 10) ? 0 : (s2 % 11);

    return (parseInt(d1.toString(), 10) === parseInt(d[9], 10) && parseInt(d2.toString(), 10) === parseInt(d[10], 10));
  }

  static isCnpj(value: string) {
    if (this.isEmpty(value)) {
      return false;
    }

    const v = this.stripNonNumeric(value);

    if (parseInt(v, 10) === 0) {
      return false;
    }

    const d = v.split('');

    if (d.length !== 14) {
      return false;
    }

    const m1 = [+d[0] * 5, +d[1] * 4, +d[2] * 3, +d[3] * 2, +d[4] * 9, +d[5] * 8,
                +d[6] * 7, +d[7] * 6, +d[8] * 5, +d[9] * 4, +d[10] * 3, +d[11] * 2];
    let s1 = 0;
    for (const i in m1) {
      if (Object.prototype.hasOwnProperty.call(m1, i)) {
        s1 += m1[i];
      }
    }
    const d1 = (s1 % 11 < 2) ? 0 : 11 - (s1 % 11);

    const m2 = [+d[0] * 6, +d[1] * 5, +d[2] * 4, +d[3] * 3, +d[4] * 2, +d[5] * 9,
                +d[6] * 8, +d[7] * 7, +d[8] * 6, +d[9] * 5, +d[10] * 4, +d[11] * 3, d1 * 2];
    let s2 = 0;
    for (const j in m2) {
      if (Object.prototype.hasOwnProperty.call(m2, j)) {
        s2 += m2[j];
      }
    }
    const d2 = (s2 % 11 < 2) ? 0 : 11 - (s2 % 11);

    return (parseInt(d1.toString(), 10) === parseInt(d[12], 10) && parseInt(d2.toString(), 10) === parseInt(d[13], 10));
  }

  static isCnpjOrCpf(value: string): boolean {
    return this.isCnpj(value) || this.isCpf(value);
  }

  static validacaoEmail(field: any) {
    const usuario = field.substring(0, field.indexOf('@'));
    const dominio = field.substring(field.indexOf('@') + 1, field.length);

    if ((usuario.length >= 1) &&
        (dominio.length >= 3) &&
        (usuario.search('@') === -1 ) &&
        (dominio.search('@') === -1 ) &&
        (usuario.search(' ') === -1 ) &&
        (dominio.search(' ') === -1 ) &&
        (dominio.search('.') !== -1) &&  (dominio.indexOf('.') >= 1) &&
        (dominio.lastIndexOf('.') < dominio.length - 1)) {
          return true;
    }
        return false;

  }

  static isNumber(n) {
     return !isNaN(parseFloat(n)) && isFinite(n);
  }

  static isNumberOnly(n) {
    return !isNaN(parseFloat(n)) && isFinite(n) && !(n.indexOf('.') >= 1);
 }

  static removeCaracteresCnpj(field: any) : string{
    return field.toString().replace(/[-\/\\^$*+?.()|[\]{}]/g,'');
  }

}
