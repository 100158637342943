import { FormArray, FormControl, FormGroup } from '@angular/forms';

export class FormValidations {

  static passwordValidation() {
    const validator = (formControl: FormControl) => {
      if (!formControl.root || !(<FormGroup>formControl.root).controls) {
        return null
      }

      const field = (<FormGroup>formControl.root).get('password')

      if(field.value) {

        if (field.value.length === 0) {
          return { required: true }
        }

        if (!(field.value.length <= 15 && field.value.length >= 6)) {
          return { length: true }
        }

        let regex = new RegExp(/^(.*[a-z].*)$/);
        if (regex.test(field.value) === false) {
          return { lowercase: true }
        }

        regex = new RegExp(/^(.*[A-Z].*)$/);
        if (regex.test(field.value) === false) {
          return { uppercase: true }
        }

        regex = new RegExp(/^(.*[-+_!@#$%&*].*)$/);
        if (regex.test(field.value) === false) {
          return { special: true }
        }

      }

      return null
    }

    return validator
  }

  static equalsTo(otherField: string) {
    const validator = (formControl: FormControl) => {
      if (otherField == null) {
        throw new Error('É necessário informar um campo.')
      }

      if (!formControl.root || !(<FormGroup>formControl.root).controls) {
        return null
      }

      const field = (<FormGroup>formControl.root).get(otherField)

      if (!field) {
        throw new Error('É necessário informar um campo válido.')
      }

      if (field.value !== formControl.value) {
        return { equalsTo : otherField }
      }

      return null
    }

    return validator
  }
}