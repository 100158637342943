import { Empresa } from './empresa.model';
import { TipoEntradaSaida } from '../../util/model/tipo-entrada-saida.model';

export class Mi {
  id: number;
  codigo: string;
  descricao: string;
  tipo: TipoEntradaSaida;
  empresa: Empresa;
  quantidade: boolean;
  valor: boolean;
  transfAlmoxarifado: boolean;
  transfParticipante: boolean;
  transformacao: boolean

  constructor(mi?: Mi | number) {
    if (mi !== undefined && mi !== null) {
      if (typeof mi === 'number') {
        this.id = mi;
      } else {
        this.id = mi.id;
        this.codigo = mi.codigo;
        this.descricao = mi.descricao;
        this.tipo = mi.tipo;
        this.empresa = mi.empresa;
        this.quantidade = mi.quantidade;
        this.valor = mi.valor;
        this.transfAlmoxarifado = mi.transfAlmoxarifado;
        this.transfParticipante = mi.transfParticipante;
        this.transformacao = mi.transformacao
      }
    }
  }
}
