export class TbIrpjM300 {
  id: number;
  cod:  string;
  descricao:  string;
  dtIni:  string;
  dtFin:  string;
  ordem:  string;
  tipo:  string;
  formato:  string;
  linhaEcf:  string;
  formula:  string;
  tipoLanc:  string;
  used: boolean;

  constructor(tbIrpjM300?: TbIrpjM300 | number) {
    if (tbIrpjM300 !== undefined && tbIrpjM300 !== null) {
      if (typeof tbIrpjM300 === 'number') {
        this.id = tbIrpjM300;
      } else {
        this.id = tbIrpjM300.id;
        this.cod = tbIrpjM300.cod;
        this.descricao = tbIrpjM300.descricao;
        this.dtIni = tbIrpjM300.dtIni;
        this.dtFin = tbIrpjM300.dtFin;
        this.ordem = tbIrpjM300.ordem;
        this.tipo = tbIrpjM300.tipo;
        this.formato = tbIrpjM300.formato;
        this.linhaEcf = tbIrpjM300.linhaEcf;
        this.formula = tbIrpjM300.formula;
        this.tipoLanc = tbIrpjM300.tipoLanc;
        this.used = tbIrpjM300.used;
      }
    }
  }
}
