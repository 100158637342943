import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { Usuario } from '../model/usuario.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { SessionService } from 'app/shared/services/session.service';
import { Md5Service } from 'app/shared/services/md5.service';
import { HttpService } from '../../shared/services/http.service';
import { Util } from 'app/shared/common/util';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
  providers: [CrudService]
})
export class UsuarioComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public usuario: Usuario = new Usuario();

  private defaultPass = '******';

  private beforeViewSubscription: Subscription;
  private beforeSaveSubscription: Subscription;

  constructor(private crudService: CrudService,
              private httpService: HttpService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('Nome', 'authUser.displayName'),
      new DataTableColumn('Nome Completo', 'authUser.fullName'),
      new DataTableColumn('E-mail', 'authUser.mail'),
      new DataTableColumn('Ativo', 'authUser.active')
        .setTypeCheckBox()
    ];

    this.controls = [
        new DataControl('Nome Completo', 'authUser.fullName')
          .setRequired()
          .setMinLength(5)
          .setMaxLength(60)
          .setOnChange((control: DataControl, value: any) => {
            if (value && value.trim()) {
              const displayNameFormControl = this.crudService.form.get('authUser.displayName');

              let newValue = value.trim();
              if (newValue) {
                if (newValue.trim().indexOf(' ') !== - 1) {
                  newValue = value.trim().substr(0, value.trim().indexOf(' ')).trim();
                }

                if (!displayNameFormControl.value || !displayNameFormControl.value.trim() ||
                    newValue.indexOf(displayNameFormControl.value.trim()) === 0) {
                  displayNameFormControl.setValue(newValue);
                  displayNameFormControl.markAsDirty();
                }
              }
            }
          }),
        new DataControl('Nome para exibição', 'authUser.displayName')
          .setRequired()
          .setMinLength(2)
          .setMaxLength(25),
        new DataControl('CPF', 'cpf')
          .setMinLength(11),
        new DataControl('Departamento', 'departamento')
          .setRequired()
          .setTypeSelect()
          .setOptions({ url: '/departamento', value: 'id', label: 'cod' }),
        // TODO: Gestor
        new DataControl('E-mail', 'authUser.mail')
          .setRequired()
          .setTypeEmail()
          .setMinLength(10)
          .setMaxLength(60),
        new DataControl('Celular', 'celular')
          .setMaxLength(20),
        new DataControl('Telefone fixo', 'telefone')
          .setMaxLength(20),
        new DataControl('Password', 'authUser.pass')
          .setRequired()
          .setTypePassword()
          .setMinLength(6)
          .setMaxLength(50),
        new DataControl('Grupos', 'authUser.authGroups')
          .setTypeMultiSelect()
          .setOptions({ url: '/authgroup', value: 'id', label: 'name', application: 'proof' }),
        new DataControl('Projetos', 'projetoDblRelacs')
          .setTypeMultiSelect()
          // TODO: REVIEW
          .setOptions({ groupUrl: '/projetodbl?$select=id,cod&$orderby=cod', groupValue: 'id', groupLabel: 'cod', /*groupApplication: ,*/
                        url: '/empresa', value: 'idempresa', label: 'razaosocial', application: 'proof' }),
        new DataControl('Nível', 'nivel')
          .setRequired()
          .setTypeNumber()
          .setMin(1)
          .setMax(6),
        new DataControl('Ativo', 'authUser.active')
          .setTypeCheckBox(),
      ];

    this.beforeViewSubscription = this.crudService.beforeView.subscribe((usuario: Usuario) => {
      if (usuario.id) {
        usuario.authUser.pass = this.defaultPass;
      }
    });

    this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((usuario: Usuario) => this.beforeSave(usuario));
  }

  private beforeSave(usuario: Usuario) {
    if (!usuario.authUser.displayName || usuario.authUser.displayName.trim() === usuario.authUser.fullName.trim() ||
        usuario.authUser.displayName.trim() === '') {
      let err = true;

      let displayName = usuario.authUser.fullName.trim();
      if (displayName.indexOf(' ') !== -1) {
        displayName = displayName.substr(0, displayName.indexOf(' ')).trim();
        if (displayName !== '') {
          usuario.authUser.displayName = displayName;
          err = false;
        }
      }

      if (err) {
        this.beforeSaveSubscription.unsubscribe();
        this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((u: Usuario) => this.beforeSave(u));

        const message = 'Por favor, preencha corretamente o campo \'Nome Completo\'';
        alert (message);
        throw message;
      }
    }

    if (usuario.authUser.mail && usuario.authUser.mail.trim() !== '') {
      usuario.authUser.login = usuario.authUser.mail;
      usuario.mail = usuario.authUser.mail;
      usuario.login = usuario.authUser.login;
    }

    if (usuario.authUser.pass === this.defaultPass) {
      usuario.authUser.pass = null;
    }

    if (usuario.authUser.pass && usuario.authUser.pass.trim() !== '') {
      usuario.authUser.pass = new Md5Service().b64_md5(usuario.authUser.pass);
      usuario.senha = usuario.authUser.pass;
    }

    usuario.nome = usuario.authUser.displayName;
    usuario.sobrenome = usuario.authUser.fullName;
    usuario.ativo = usuario.authUser.active;

    // TODO: Empresa x Projeto x Usuario controls
    /*if (this.crudService.updatedEntity.id) {
      // Update
      console.log(Util.clone(usuario));
      usuario.projetoDblRelacs = [{ id: 2368 }];
      usuario.projetoDblRelacs.push({
        usuario: usuario, // TODO: REVIEW (OData or Eclipselink bug?)
        projetoDbl: { id: 1 },
        empresa: { id: 16 }
      });
      console.log(usuario);
    } else {
      // Insert
      usuario.projetoDblRelacs = [{
        usuario: usuario, // TODO: REVIEW (OData or Eclipselink bug?)
        projetoDbl: { id: 6 },
        empresa: { id: 16 }
      }];
    }*/
  }

  ngOnDestroy() {
    if (this.beforeViewSubscription) {
      this.beforeViewSubscription.unsubscribe();
    }
    if (this.beforeSaveSubscription) {
      this.beforeSaveSubscription.unsubscribe();
    }
  }
}
