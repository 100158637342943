export class Idioma {
  id: number;
  codigo: string;
  descricao: string;

  constructor(idioma?: Idioma | number) {
    if (idioma !== undefined && idioma !== null) {
      if (typeof idioma === 'number') {
        this.id = idioma;
      } else {
        this.id = idioma.id;
        this.codigo = idioma.codigo;
        this.descricao = idioma.descricao;
      }
    }
  }
}
