import { AuthUser } from 'app/proof/model/auth-user.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Item } from 'app/cadastro/model/item.model';
import { AliquotaTributo } from 'app/cadastro/model/aliquota-tributo.model';


export class GrupoTributacao {

  id: number;
  codigo: string;
  descricao: string;
  dtInicial: Date;
  dtFinal: Date;
  empresa: Empresa;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;

  item: Array<Item> = new Array();
  aliquotaTributo: Array<AliquotaTributo> = new Array();

  constructor(grupoTributacao?: GrupoTributacao | number) {
    if (grupoTributacao !== undefined && grupoTributacao !== null) {
      if (typeof grupoTributacao === 'number') {
        this.id = grupoTributacao;
      } else {
        this.id = grupoTributacao.id;
        this.codigo = grupoTributacao.codigo;
        this.descricao = grupoTributacao.descricao;
        this.dtInicial = grupoTributacao.dtInicial;
        this.dtFinal = grupoTributacao.dtFinal;
        this.empresa = grupoTributacao.empresa;
         this.createTimestamp = grupoTributacao.createTimestamp;
        this.changeUser = grupoTributacao.changeUser;
        this.changeTimestamp = grupoTributacao.changeTimestamp;
        this.item = grupoTributacao.item;
        this.aliquotaTributo = grupoTributacao.aliquotaTributo;
      }
    }
  }
}
