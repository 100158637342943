import { Eventos } from "./eventos.model";

export class EventosIngressos {
  id                    : number;
  codigo                : String;
  eventos               : Eventos;
  descricao             : String;
  descricaoComplemento  : String;
  valorPacote           : any;
  valorPromocional      : any;
  link                  : String;

  constructor(eventosIngressos?: EventosIngressos | number) {
    if (eventosIngressos !== undefined && eventosIngressos !== null) {
      if (typeof eventosIngressos === 'number') {
        this.id = eventosIngressos;
      } else {
        this.id                   = eventosIngressos.id;
        this.codigo               = eventosIngressos.codigo;
        this.eventos              = eventosIngressos.eventos;
        this.descricao            = eventosIngressos.descricao;
        this.descricaoComplemento = eventosIngressos.descricaoComplemento;
        this.valorPacote          = eventosIngressos.valorPacote;
        this.valorPromocional     = eventosIngressos.valorPromocional;
        this.link                 = eventosIngressos.link;

      }
    }
  }

}