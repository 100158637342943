import { Participante } from 'app/cadastro/model/participante.model';
import { ParticipanteEmpresa } from './participante-empresa.model';

export class ParticipanteContato {
  id: number;
  contato: string;
  tipoContato: string;
  fone: string;
  email: string;
  principal: boolean;
  dtInicial: Date;
  dtFinal: Date;
  participanteEmpresa: ParticipanteEmpresa;


  //participante dialog utiliza:
  participante: Participante;

  constructor(participanteContato?: ParticipanteContato | number) {
    if (participanteContato !== undefined && participanteContato !== null) {
      if (typeof participanteContato === 'number') {
        this.id = participanteContato;
      } else {
        this.id = participanteContato.id;
        this.contato = participanteContato.contato;
        this.tipoContato = participanteContato.tipoContato;
        this.fone = participanteContato.fone;
        this.email = participanteContato.email;
        this.principal = participanteContato.principal;
        this.dtInicial = participanteContato.dtInicial;
        this.dtFinal = participanteContato.dtFinal;
        this.participante = participanteContato.participante;
        this.participanteEmpresa = participanteContato.participanteEmpresa;

      }
    }
  }
}
