import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TitleService } from 'app/shared/services/title.service';
import { UtilService } from 'app/shared/services/util.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService } from '../../../../node_modules/primeng/api';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
  selector: 'app-controle-grupo',
  templateUrl: './controle-grupo.component.html',
  styleUrls: ['./controle-grupo.component.scss'
],
animations: [
  trigger('changeDivSize', [
    state('initial', style({

    })),
    state('final', style({
      opacity: '0',
      height: '0',
      padding: '0px 15px',

    })),
    transition('initial=>final', animate('200ms')),
    transition('final=>initial', animate('200ms'))
  ]),
]
})

export class ControleGrupoComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;

  msgs: Message[] = [];

  editaGrupo: boolean;
  novoGrupo: boolean;
  codNomeGrupo: string = '';
  nomeGrupo: string = '';
  descricaoGrupo: string = '';

  titlePrincipal: string;
  subtitle: string;
  tituloCard: string;

  listaUsuarios: Array<any> = new Array();
  listaAprovadores: Array<any> = new Array();

  grupos: SelectItem[];
  groupId: number;

  listaRelacionar: Array<any> = new Array();
  listaRelacionados: Array<any> = new Array();

  picklistState: boolean = true

  toolbarMainIni = [
    { key: 'btn-delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.desativarGrupo() },
    { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.prepararEdicao() },
    { key: 'btn-new', icon: 'add', text: 'Novo', tooltip: 'Novo grupo', visible: true, disabled: false, color: 'green', onClick: () => this.prepararNovo() }
  ]

  public toolbarMain = new Toolbar()

  constructor(private titleService: TitleService,
    private toolbarService: ToolbarService,
    private configService: ConfigService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { toolbarService.hidden = true }

    // date;
    defaultDate: Date;
    pt_BR: any;
    pt: any;

    //datepipe
    datePipePt: DatePipe = new DatePipe('pt-BR');

  ngOnInit(): void {
    // requisição

    this.titlePrincipal = 'Controle de Acesso';
    this.subtitle = 'Controle de Grupo';
    this.titleService.title = 'Configurar grupos e seus menus relacionados';
    this.tituloCard = "MENUS POR GRUPO";
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.groupId = 0;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });
    }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

    /*Show Hide isCardCadastroMenu*/
    isCardMenuGrupo: boolean = true;
    ShowHideMenuGrupo() {
    this.isCardMenuGrupo = !this.isCardMenuGrupo;
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

        this.toolbarMain.setAll(this.toolbarMainIni);

        this.listaRelacionar = new Array();
        this.listaRelacionados = new Array();


        this.grupos = [];
        this.grupos.push({ label: 'Selecione', value: 0, data: { desc: ''} });

        this.httpService.wait();
        this.httpService.get('/custom/cadastro/controle-acesso/grupos')
          .subscribe(retorno => {
            try {
              retorno.value.forEach(element => {
                this.grupos.push({ label: element.name, value: element.id, data: {desc: (element.description != false) ? element.description : ''} });
              });
            } catch (error) {
              this.mostrarResposta('warn', 'Erro ao buscar menus!');
            }


          }, error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());
    }
  }

  onFilterRms(event: any) {
    console.log('event: ' + event);
  }

  export() {
    // alert('export');
  }

  pesquisa() {
    // alert('pesquisa');
  }


  hideMessage() {

  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  salvarRelacionamento(){
    if (confirm('Deseja realmente salvar este relacionamento?')) {



      // primeiro deletamos os que não foram relacionados
      let req1 = {
        groupId: this.groupId,
        listaRelacionar: this.listaRelacionar
      };

      this.httpService.wait();
      this.httpService.post('/custom/cadastro/controle-acesso/controle-grupo-delete', req1)
      .subscribe( retorno => {
        if (retorno.error){
          alert('Algum erro ocorreu ao deletar o relacionamento!');
          return;
        }

      },
        error => {
          this.mostrarResposta('error', "Falha ao deletar relacionamento.");
          this.httpService.done();
          return
      },
        () => this.httpService.done()
      );

      // agora salvamos os relacionados
      let req2 = {
        groupId: this.groupId,
        listaRelacionados: this.listaRelacionados
      };

      this.httpService.wait();
      this.httpService.post('/custom/cadastro/controle-acesso/controle-grupo-post', req2)
      .subscribe( retorno => {
        if (retorno.error){
          alert('Algum erro ocorreu ao salvar o relacionamento!');
          return;
        }

      },
        error => {
          this.mostrarResposta('error', "Falha ao salvar relacionamento.");
          this.httpService.done();
          return
      },
        () => this.httpService.done()
      );
      this.change()
    }
    this.mostrarResposta('success', "Menu(s) Relacionado(s).");
  }

  tabChange(event: any){

  }

  grupoSelecionado() {
    this.listaRelacionados = new Array();
    this.listaRelacionar = new Array();
    this.picklistState = false

    if(this.groupId > 0) {
      this.buscarMenus()
      this.toolbarMain.setVisible('btn-edit', true)
      this.toolbarMain.setVisible('btn-delete', true)
    }
    else {
      this.picklistState = true
      this.toolbarMain.setVisible('btn-edit', false)
      this.toolbarMain.setVisible('btn-delete', false)
    }
  }

  buscarMenus(){
    this.httpService.wait();
    this.httpService.get('custom/cadastro/controle-acesso/controle-grupo-list?auth_group_id=' + this.groupId)
      .subscribe(retorno => {
        try {
          retorno.forEach(element => {
            if(element.relac){
              this.listaRelacionados.push(element);
            } else {
              this.listaRelacionar.push(element);
            }
          });

          this.picklistState = true
        } catch (error) {
          this.mostrarResposta('warn', 'Erro ao buscar menus!');
        }

      }, error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }

  prepararNovo() {
    this.limparForm();
    this.novoGrupo = true;
    this.tituloCard = 'NOVO GRUPO';
  }

  prepararEdicao() {
    const grupo = this.grupos.find(grupo => grupo.value == this.groupId);
    const nomeCompleto = grupo.label;

    this.codNomeGrupo = nomeCompleto.substring(1, nomeCompleto.indexOf('] - '));
    this.nomeGrupo = nomeCompleto.substring(nomeCompleto.indexOf('] - ') + 4);
    this.descricaoGrupo = grupo.data.desc;

    this.editaGrupo = true;
    this.tituloCard = 'EDITAR GRUPO';
  }

  criarGrupo() {
    if (!this.validacaoGrupo()) return;

    const payloadGrupo = {
      cod_nome: this.codNomeGrupo,
		  desc_nome: this.nomeGrupo,
		  description: this.descricaoGrupo,
		  user_id: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/controle-acesso/criar-grupo', payloadGrupo)
      .subscribe(result => {
        if (!result.error) {
          this.mostrarResposta('success', 'Grupo cadastrado com sucesso!');
          this.novoGrupo = false;
          this.tituloCard = 'MENUS POR GRUPO';
          this.limparForm();
          this.change();
        } else {
          this.mostrarResposta('warn', 'Falha no cadastro do grupo. Tente novamente');
          return;
        }
      }, error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }

  editarGrupo() {
    if (!this.validacaoGrupo()) return;

    const payloadGrupo = {
      cod_nome: this.codNomeGrupo,
      desc_nome: this.nomeGrupo,
      description: this.descricaoGrupo,
      group_id: this.groupId,
      user_id: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/controle-acesso/editar-grupo', payloadGrupo)
      .subscribe(result => {
        if (!result.error) {
          this.mostrarResposta('success', 'Grupo editado com sucesso!');
          this.editaGrupo = false;
          this.tituloCard = 'MENUS POR GRUPO';
          this.limparForm();
          this.change();
        } else {
          this.mostrarResposta('warn', 'Falha ao editar o grupo. Tente novamente');
          return;
        }
      }, error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }

  salvarForm() {
    if (this.editaGrupo) {
      this.editarGrupo()
    }
    else {
      this.criarGrupo()
    }
  }

  fecharForm() {
    this.novoGrupo = false;
    this.editaGrupo = false;
    this.tituloCard = 'MENUS POR GRUPO';
  }

  desativarGrupo() {
    if (confirm("TEM CERTEZA QUE DESEJA DESATIVAR O GRUPO?")) {
      const payloadGrupo = [{
        group_id: this.groupId,
        user_id: this.sessionService.loggedUser.id
      }];

      this.httpService.wait();
      this.httpService.post('/custom/cadastro/controle-acesso/desativar-grupo', payloadGrupo)
        .subscribe(result => {
          if (!result.error) {
            this.mostrarResposta('success', 'Grupo desativado com sucesso!');
            this.change()
          } else {
            this.mostrarResposta('warn', 'Falha ao desativar o grupo. Tente novamente');
            return;
          }
        }, error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
    }
  }

  private validacaoGrupo(): boolean {
    let message: string = null

    if (!this.codNomeGrupo) {
      message = 'O campo código é obrigatório!'
      this.mostrarResposta('info', message)
      throw message
    }
    if (!this.nomeGrupo) {
      message = 'O campo nome é obrigatório!'
      this.mostrarResposta('info', message)
      throw message
    }
    if (this.codNomeGrupo.length > 6) {
      message = 'O campo código só pode ter no máximo seis letras!'
      this.mostrarResposta('info', message)
      throw message
    }

    return true
  }

  limparForm() {
    this.codNomeGrupo = '';
    this.nomeGrupo = '';
    this.descricaoGrupo = '';
  }

  protected mostrarResposta(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Atenção: ', detail: message })
      break
    }
  }
}
