import { Observable } from 'rxjs/Observable';
import { DataObject, DataOptions } from './data-object.model';

export class DataTableColumn extends DataObject {
  nowrap: boolean;
  cssClass: string;
  options: DataOptions;
  filteredOptions: Observable<any[]>;
  editable = false;
  ready = false;
  minLength: number = null;
  maxLength: number = null;
  hint: string = null;
  min: number = null;
  max: number = null;
  step: number = null;

  filterOption = 1;

  filter: string = undefined;
  orderClass: string = null;

  callback: Function;
  //  public callback: () => any;

  pipe: string;

  constructor(label: string,
    key: string = label.toLowerCase(),
    type: string = 'text',
    nowrap: boolean = false,
    cssClass: string = null) {
    super(label, key);

    this.setType(type);
    this.nowrap = nowrap;
    this.cssClass = cssClass;
  }

  setNoWrap(nowrap: boolean = true): DataTableColumn {
    this.nowrap = nowrap;
    return this;
  }

  setPipe(pipe: string): DataTableColumn {
    this.pipe = pipe;
    return this;
  }

  setCssClass(cssClass: string): DataTableColumn {
    this.cssClass = cssClass;
    return this;
  }

  setOptions(options: any): DataTableColumn {
    this.options = options;
    return this;
  }

  setEditable(editable: boolean = true) {
    this.editable = editable;
    return this;
  }

  setCallback(callback: Function = null) {
    this.callback = () => callback();
    return this;
  }

  get filterContains(): boolean {
    return this.filterOption === 1;
  }
  setFilterContains() {
    this.filterOption = 1;
    return this;
  }

  get filterStartsWith(): boolean {
    return this.filterOption === 2;
  }
  setFilterStartsWith() {
    this.filterOption = 2;
    return this;
  }

  get filterEndsWith(): boolean {
    return this.filterOption === 3;
  }
  setFilterEndsWith() {
    this.filterOption = 3;
    return this;
  }

  setReady(ready: boolean = true) {
    this.ready = ready;
  }
}
