export class Lst {
  id: number;
  codigo: string;
  descricao: string;

  constructor(lst?: Lst | number) {
    if (lst !== undefined && lst !== null) {
      if (typeof lst === 'number') {
        this.id = lst;
      } else {
        this.id = lst.id;
        this.codigo = lst.codigo;
        this.descricao = lst.descricao;
      }
    }
  }
}
