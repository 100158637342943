import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Item } from 'app/cadastro/model/item.model';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { UnidadeMedida } from 'app/util/model/unidade-medida.model';
import exportExcel from 'app/util/processing/exportExcel';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ItemBomArvore } from '../model/item-bom-arvore.model';


@Component({
  selector: 'app-item-bom',
  templateUrl: './item-bom.component.html',
  styleUrls: [
    './item-bom.component.scss'
  ]
})
export class ItemBomComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {}

  private sessionSubscription: Subscription; // change session event;

  @ViewChild('file') fileInput: ElementRef;
  title = '';
  subtitle = '';
  itemEditado = '';
  idItemEditado: number;
  totalRecords: number

  lista: Array<any> = new Array();
  listaItens: Array<any> = new Array();
  listaAlmoxarifados: Array<any> = new Array();

  exibirModalImportarItens: boolean;
  new: boolean;
  edit: boolean;

  listaItensArvore: Array<any> = new Array();
  opcoesItens: SelectItem[];
  opcoesItens2: SelectItem[];
  opcoesAlmoxarifados: SelectItem[];

  defaultDate: Date;
  dataInicio: Date;
  dataFinal: Date;
  ativo: boolean;
  modalClonar: boolean;
  descricao: string;
  itemPai: Item;
  itemClone: Item;
  file: File;

  //export excel
  colsLista: any[];
  exportBom = ["codigo", "descricao", "descricaoBom", "qtdeItens", "dtIni", "dtFin", "ativo"]

  opcoesAtivo: SelectItem[] = [{ label: 'Sim', value: "sim" }, { label: 'Não', value: "não" }]

  tipoUnidadeMedida: Array<UnidadeMedida> = new Array();
  opcoesUnidadeMedida: SelectItem[];


  @ViewChild('dt1') dt1: Table
  exportMov1() {
    this.dt1.exportCSV()
  }

  public toolbarMain = new Toolbar()


  toolbarMainIni = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: false, disabled: false, color: 'default', onClick: () => this.exportExcelAll(this.lista, 'arvore_produtos', this.exportBom) },
    { icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.inicializar() },
    { icon: 'cloud_upload', text: 'Upload', tooltip: 'Upload', visible: false, disabled: false, color: 'default', onClick: () => this.showModalImportarItens() },
    { icon: 'content_copy', text: 'Clonar', tooltip: 'Clonar', visible: false, disabled: false, color: 'default', onClick: () => this.clonarItem() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirItem() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.novaArvore() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarItem() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarItem() },
  ]

   ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    Promise.resolve(null).then(() => this.refreshToolbar());
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);


    this.title = 'Árvore de Produtos';

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
      }
    );

    this.colsLista = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'descricaoBom', header: 'DADOS ADICIONAIS' },
      { field: 'qtdeItens', header: 'QTDE ITENS' },
      { field: 'dtIni', header: 'DATA INI' },
      { field: 'dtFin', header: 'DATA FIN' },
      { field: 'ativo', header: 'ATIVO' }
    ];
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  exportExcelAll(item, fileName, list?) {
    exportExcel(item, fileName, list)
  }

  private changeSession() {

      if (this.dblinkedSessionService.hasEmpresaSelected &&
        this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
        && this.dblinkedSessionService.periodo.year) {

        const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
        this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
        const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
        this.defaultDate = new Date(dt);
        this.idItemEditado = 0;




      this.itemPai = new Item();
      this.itemClone = new Item();
      this.totalRecords = 0
      this.new = false;
      this.edit = false;
      this.itemEditado = '';
      this.ativo = false;
      this.descricao = '';
      this.modalClonar = false;
      this.refreshToolbar();
      this.loadCadItens();
      this.loadAlmoxarifado();
      this.loadItensArvore();
      this.loadUnidades();
      this.exibirModalImportarItens = false;

      this.toolbarMain.setVisible('btn1', true)
      this.toolbarMain.setVisible('btn2', false)
      this.toolbarMain.setVisible('btn3', false)
      this.toolbarMain.setVisible('btn4', false)
      this.toolbarMain.setVisible('btn5', false)
      this.toolbarMain.setVisible('btn6', true)
      this.toolbarMain.setVisible('btn7', false)
      this.toolbarMain.setVisible('btn8', false)
    }
  }

  inicializar(){
    if(this.edit){
      if (confirm('As alterações serão perdidas, deseja continuar?')) {
        this.changeSession();
        }
    }else{
      this.changeSession();
    }


  }

  showModalImportarItens(){
    this.exibirModalImportarItens = true;
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
  }

  importar() {
    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('type', 'arvore-produto-itens-csv');
    data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());

    this.httpService.wait();
    this.httpService.post('/custom/upload', data)
                    .subscribe(result => {

                      this.uploaded(result);

                    },error => this.httpService.handleError(error, () => this.importar()),
                               () => this.httpService.done());
    this.file = null;
  }

  private uploaded(res?: any) {



    if (res.result.error === true) {
      if(!isNullOrUndefined(res.result.camposInvalido)){
        res.result.camposInvalido.forEach(element => {
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: element});
        });
      }else{
        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: res.result.message});
      }

    } else {
      this.getItens(res.result.pedidoItens);
      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: res.result.message});
    }
  }

  getItens(itens){

    this.listaItensArvore = new Array();

    let contador = 1;
    itens.forEach(element => {
      element.id = contador;
      this.listaItensArvore.push(element);
      contador++;
   });

    this.adicionarItensArvore(5);


  }

  salvarClone(){
    if (confirm('Deseja realmente duplicar esta árvore?')) {

      if(!this.itemPai.id){
        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro:', detail: 'É necessário informar o item'  });
        return;
      }

    const req = {
      empresaId : this.dblinkedSessionService.empresa.id,
      metodo    : 'duplicar-arvore',
      authUserId: this.sessionService.loggedUser.id,
      token     : JSON.parse(localStorage.getItem('data')).authToken,
      idItem    : this.idItemEditado,
      itemPai   : this.itemPai.id ? this.itemPai.id : 0

    };


    this.httpService.wait();
    this.httpService.post('/custom/estoque/comandos-estoque', req)
    .subscribe(result => {
      console.log(result);
      if(!result.error){
        this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso:', detail: 'Item duplicado com sucesso!' });
        this.changeSession();
      }else{
        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro:', detail: 'Não foi possível salvar o item' });
      }
      },
        error => this.httpService.handleError(error, () => this.loadCadItens()),
        () => this.httpService.done());

      }
  }


  private loadCadItens() {
    this.listaItens = new Array();

    this.opcoesItens = [];
    this.opcoesItens.push({ label: 'Nenhum', value: null });
    this.opcoesItens2 = [];
    this.opcoesItens2.push({ label: 'Nenhum', value: null });


    const req = {
      empresaId : this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id,
      metodo    : 'get-itens',
      authUserId: this.sessionService.loggedUser.id,
      token     : JSON.parse(localStorage.getItem('data')).authToken
    };


    this.httpService.wait();
    this.httpService.post('/custom/estoque/comandos-estoque', req)
    .subscribe(result => {
      result.valor.forEach(element => {
        element.label = element.codigo + '-' + element.descricao;
        this.opcoesItens.push(element);
        this.opcoesItens2.push(element);
      });
      },
        error => this.httpService.handleError(error, () => this.loadCadItens()),
        () => this.httpService.done());

  }

  private loadAlmoxarifado() {
    this.listaAlmoxarifados = new Array();

    this.opcoesAlmoxarifados = [];
    this.opcoesAlmoxarifados.push({ label: 'Nenhum', value: null });

    const req = {
      empresaId : this.dblinkedSessionService.empresa.id,
      metodo    : 'get-almoxarifados',
      authUserId: this.sessionService.loggedUser.id,
      token     : JSON.parse(localStorage.getItem('data')).authToken
    };


    this.httpService.wait();
    this.httpService.post('/custom/estoque/comandos-estoque', req)
    .subscribe(result => {
      result.valor.forEach(element => {
        element.label = element.codigo + ' - ' + element.descricao;
        this.opcoesAlmoxarifados.push(element);
      });
      },
        error => this.httpService.handleError(error, () => this.loadAlmoxarifado()),
        () => this.httpService.done());

  }


  private loadItensArvore() {
    this.lista = new Array();

    const req = {
      empresaId : this.dblinkedSessionService.empresa.id,
      metodo    : 'get-itens-arvore',
      authUserId: this.sessionService.loggedUser.id,
      token     : JSON.parse(localStorage.getItem('data')).authToken
    };


    this.httpService.wait();
    this.httpService.post('/custom/estoque/comandos-estoque', req)
    .subscribe(result => {
      this.totalRecords = result.valor.length
      result.valor.forEach(element => {
        this.lista.push(element);
      });
      },
        error => this.httpService.handleError(error, () => this.loadItensArvore()),
        () => this.httpService.done());

  }

  loadArvore(any: any, any1: any){
    this.toolbarMain.setVisible('btn1', false)
    this.toolbarMain.setVisible('btn2', true)
    this.toolbarMain.setVisible('btn3', true)
    this.toolbarMain.setVisible('btn4', true)
    this.toolbarMain.setVisible('btn5', true)
    this.toolbarMain.setVisible('btn6', false)
    this.toolbarMain.setVisible('btn7', false)
    this.toolbarMain.setVisible('btn8', true)

    this.listaItensArvore = new Array();

    this.idItemEditado = any1.id;

    this.itemEditado = any1.codigo + " - " + any1.descricao;

    this.dataInicio = any1.dtIni;
    this.dataFinal = any1.dtFin;
    this.edit = true;
    this.ativo = any1.ativo === 'SIM' ? true : false;
    this.descricao = any1.descricaoBom;

    const req = {
      empresaId : this.dblinkedSessionService.empresa.id,
      metodo    : 'get-arvore-produto',
      authUserId: this.sessionService.loggedUser.id,
      token     : JSON.parse(localStorage.getItem('data')).authToken,
      itemBomId : any1.id
    };


    this.httpService.wait();
    this.httpService.post('/custom/estoque/comandos-estoque', req)
    .subscribe(result => {
      let contador = 1;
      result.valor.forEach(element => {
        element.id = contador;
        this.listaItensArvore.push(element);
        contador++;
      });
        this.adicionarItensArvore(5);
      },
        error => this.httpService.handleError(error, () => this.loadItensArvore()),
        () => this.httpService.done());

        this.toolbarService.replace(
          'add',
          false,
          'class-new-button',
          'SALVAR',
          'Salvar árvore de Produto',
          () => this.salvarItem()
        );

  }

  adicionarItensArvore(param: number){

    let contador = this.listaItensArvore.length;
    for (let index = 0; index < param+1; index++) {
      let iba = new ItemBomArvore();
      iba.item = new Item();
      iba.un = new UnidadeMedida();
      iba.qtde = 0;
      iba.id = contador;
      this.listaItensArvore.push(iba);
      contador++;
    }
  }

  excluirItemLista(event: any){
    if (confirm('Deseja realmente excluir este item?')) {
      let index = this.listaItensArvore.indexOf(event);
      this.listaItensArvore.splice(index, 1);
      this.verificarQtdeItens();
    }
  }

  carregarUnItem(event: any){
    if(event.item.id){

        this.httpService.wait();
    this.httpService.get('custom/cadastro/buscar-infos-unidade-medida', event.item.id)
      .subscribe(retorno => {

        this.opcoesUnidadeMedida = [];
        this.opcoesUnidadeMedida.push({ label: 'Nenhum', value: null });
        let contador = 1;
        retorno.forEach(element => {

          if(contador ==1){
            event.unidadeMedida = new UnidadeMedida();
            event.unidadeMedida.id = element.id;
            event.unidadeMedida.codigo = element.codigo;
          }

          element.label = element.codigo;
          this.opcoesUnidadeMedida.push(element);

          contador++;
        });

    }, error => this.httpService.handleError(error, () => this.carregarUnItem(event)),
    () => this.httpService.done());
    }
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() {

  }


  private refreshToolbar() {

    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('add');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('export');
    this.toolbarService.replace('voltar', false, 'class-first-button', 'undo', 'Voltar', () => this.inicializar());


    /*
    this.toolbarService.replace('export', true, 'class-other-button', 'description', 'Exportar', );
      this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir', );
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar', );*/





    this.toolbarService.replace(
      'add',
      false,
      'class-new-button',
      'NOVO',
      'Nova Árvore de Produto',
      () => this.novaArvore()
    );


  }

  private novaArvore(){
    this.listaItensArvore = new Array();
    this.new = true;
    this.edit = false;
    this.adicionarItensArvore(5);

    this.toolbarMain.setVisible('btn1', false)
    this.toolbarMain.setVisible('btn2', true)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', false)
    this.toolbarMain.setVisible('btn7', true)
    this.toolbarMain.setVisible('btn8', false)

    }

  salvarItem(){


    let contador = 0;
    let contadorAlm = 0;
    this.listaItensArvore.forEach(element => {
      if(!element.qtde){
        contador++;
      }
    });

    if(contador === 0){
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro:', detail: 'É necessário informar pelo menos um item na árvore de produto' });
      return;
    }

    if(!this.dataInicio){
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro:', detail: 'É informar data de início' });
      return;
    }

    if(this.new && !this.itemPai.id){
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro:', detail: 'É necessário informar o item'  });
      return;
    }


    if (confirm('Deseja realmente salvar as alterações?')) {

    const req = {
      empresaId : this.dblinkedSessionService.empresa.id,
      metodo    : 'salvar-arvore-produto',
      authUserId: this.sessionService.loggedUser.id,
      token     : JSON.parse(localStorage.getItem('data')).authToken,
      tipo      : this.edit ? 'edit' : 'new',
      idItem    : this.idItemEditado,
      lista     : JSON.parse(JSON.stringify(this.listaItensArvore)),
      dtInicio  : this.utilService.dateFormat(this.dataInicio),
      dataFinal : this.utilService.dateFormat(this.dataFinal),
      ativo     : this.ativo,
      descricao : this.descricao,
      itemPai   : this.itemPai.id ? this.itemPai.id : 0
    };


    this.httpService.wait();
    this.httpService.post('/custom/estoque/comandos-estoque', req)
    .subscribe(result => {
      console.log(result);
      if(!result.error){
        this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso:', detail: 'Item salvo com sucesso!' });
        this.changeSession();
      }else{
        console.log(result)
        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro:', detail: result.erros[0].mensagem });
      }

      },
        error => this.httpService.handleError(error, () => this.loadItensArvore()),
        () => this.httpService.done());

    }
  }

  clonarItem(){
    this.modalClonar = true;
  }

  excluirItem(){
    if (confirm('Deseja realmente excluir esta arvore de produto?')) {

      console.log(this.itemEditado);
      console.log(this.listaItensArvore);

      const req = {
        empresaId : this.dblinkedSessionService.empresa.id,
        metodo    : 'salvar-arvore-produto',
        authUserId: this.sessionService.loggedUser.id,
        token     : JSON.parse(localStorage.getItem('data')).authToken,
        tipo      : 'remove',
        idItem    : this.idItemEditado,
      };


      this.httpService.wait();
      this.httpService.post('/custom/estoque/comandos-estoque', req)
      .subscribe(result => {
        console.log(result);
        if(!result.error){
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso:', detail: 'Item excluído com sucesso!' });
          this.changeSession();
        }else{
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro:', detail: 'Não foi possível excluir o item' });
        }

        },
          error => this.httpService.handleError(error, () => this.loadItensArvore()),
          () => this.httpService.done());

      }
  }

  verificarQtdeItens(){
    let contador = 0;
    this.listaItensArvore.forEach(element => {
      if(!element.item.id){
        contador++;
      }

    });




    if(contador < 3){
      this.adicionarItensArvore(3);
    }
  }

  private loadUnidades() {
    this.listaItens = new Array();

    this.opcoesUnidadeMedida = [];
    this.opcoesUnidadeMedida.push({ label: 'Nenhum', value: null });


    const req = {
      empresaId : this.dblinkedSessionService.empresa.id,
      metodo    : 'get-unidade-medida',
      authUserId: this.sessionService.loggedUser.id,
      token     : JSON.parse(localStorage.getItem('data')).authToken
    };


    this.httpService.wait();
    this.httpService.post('/custom/estoque/comandos-estoque', req)
    .subscribe(result => {



      result.valor.forEach(element => {
        element.label = element.codigo;
        this.opcoesUnidadeMedida.push(element);
      });
      },
        error => this.httpService.handleError(error, () => this.loadUnidades()),
        () => this.httpService.done());

  }



}
