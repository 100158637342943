import { ControlValueAccessor } from '@angular/forms';

export abstract class AbstractValueAccessor implements ControlValueAccessor {
    private _value: any = null;
    get value(): any {
      return this._value;
    }
    set value(value: any) {
      if (value !== this._value || value === null) {
        this._value = value;
        this.onChange(value);
      }
    }

    writeValue(value: any) {
      this._value = value;
      this.onChange(value);
    }

    onChange = (_) => {
    }

    onTouched = () => {
    }

    registerOnChange(fn: (_: any) => void): void {
      this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
      this.onTouched = fn;
    }
}
