import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { CrudService } from 'app/shared/services/crud.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
// import { c } from '@angular/core/src/render3';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-dbl-config-importacao',
  templateUrl: './dbl-config-importacao.component.html',
  styleUrls: ['./dbl-config-importacao.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ],

})

export class DblConfigImportacaoComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  titlePrincipal: string;
  subtitle: string;

  currentStateScriptsDbl = 'initial';
  scripts_importacao_dbl: Array<any> = new Array();
  scripts_filtrados: Array<any> = new Array();
  scripts_selecionados: Array<any> = new Array();
  checkBoxTodos: boolean;
  modalCriacaoScriptDbl: boolean;
  opcoesTipoArquivo: SelectItem[];
  opcoesRecorrente: SelectItem[];
  opcoesPeriodicidade: SelectItem[];
  opcoesTipoArquivoEscolhido: any;
  opcoesRecorrenteEscolhido: any;
  opcoesPeriodicidadeEscolhido: any;

  currentId: any;
  currentTipoArquivo: any;
  currentPeriodicidade: any;
  currentRecorrente: any;
  currentPeriodo: any;
  modalEdicaoScriptDbl: boolean;

  periodoSelecionado: Date;
  periodoObtencaoDados: Date;
  defaultDate: Date;

  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }


  ngOnInit(): void {

    this.titlePrincipal = 'IMPORTAÇÃO DBL';
    this.subtitle = 'CONFIGURAÇÕES DE SCRIPTS PARA A INSERÇÃO DE DADOS NO SISTEMA';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.defaultDate = new Date();

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected &&
        this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

        this.carregarScriptsDbl();
        this.carregarDadosModal();
      }
    });

    this.toolbarService.clear();
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  // Função que carrega os dados na tabela principal da tela
  carregarScriptsDbl() {

    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id.toString(),
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-scripts-dbl', dados)
      .subscribe(
        res => {

          // Todas as variáveis envolvidas nas operações da tela são inicializadas
          this.scripts_importacao_dbl = new Array();
          this.scripts_selecionados = new Array();
          this.checkBoxTodos = false;
          this.modalCriacaoScriptDbl = false;
          this.modalEdicaoScriptDbl = false;

          for (let line of res) {
            var data_final = line["ultima_modificacao"];
            var formattedData = new Date(parseFloat(data_final));

            let month = String(formattedData.getMonth() + 1);
            let day = String(formattedData.getDate());
            const year = String(formattedData.getFullYear());

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            data_final = day + '/' + month + '/' + year;
            this.scripts_importacao_dbl.push({
              "id": line["id"], "tipo_arquivo": line["tipo_arquivo"],
              "recorrente": line["recorrente"], "periodicidade": line["periodicidade"],
              "data_modificacao": data_final, "periodo": line["periodo"], "status": line["status"]
            });
            this.scripts_filtrados = this.scripts_importacao_dbl;
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  // Função que carrega os dados a serem usados nos modais de adição e edição
  carregarDadosModal() {

    // Inicializar os dados de recorrência
    this.opcoesRecorrente = [];
    this.opcoesRecorrente = [
      { label: 'SELECIONE', value: 'SELECIONE' },
      { label: 'SIM', value: 'SIM' },
      { label: 'NÃO', value: 'NÃO' },
    ];

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-tipos-arquivo-dbl')
      .subscribe(
        res => {

          // Inicializar os dados referentes aos tipos de arquivo
          this.opcoesTipoArquivo = [];
          this.opcoesTipoArquivo = [{ label: 'SELECIONE', value: 'SELECIONE' }];
          for (let line of res) {
            this.opcoesTipoArquivo.push({ label: line["descricao"], value: line["descricao"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-tipos-periodicidade')
      .subscribe(
        res => {

          // Inicializar os dados referentes à periodicidade dos scripts DBL
          this.opcoesPeriodicidade = [];
          this.opcoesPeriodicidade = [{ label: 'SELECIONE', value: 'SELECIONE' }];
          for (let line of res) {
            this.opcoesPeriodicidade.push({ label: line["descricao"], value: line["descricao"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que realiza o controle dos registros selecionados a partir de um filtro aplicado */
  onFilter(event: any) {

    this.scripts_filtrados.forEach(rst => {
      rst.selected = false;
    });

    this.scripts_filtrados = new Array();
    this.scripts_selecionados = new Array();

    event.filteredValue.forEach(element => {
      this.scripts_filtrados.push(element);
    });

    this.checkBoxTodos = false;

    console.log(this.scripts_filtrados);

  }


  fileSanitize(file: string) {
    return file.replace(/[^a-zA-Z0-9_-]/g, '').toLowerCase();
  }

  hideMessage() {
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  /* Show/Hide Card Pesquisa Lançamentos */
  isCardScriptsDbl: boolean = true;
  showHideCardScriptsDbl() {
    this.isCardScriptsDbl = !this.isCardScriptsDbl;
  }

  changeStateScriptsDbl() {
    this.currentStateScriptsDbl = this.currentStateScriptsDbl === 'initial' ? 'final' : 'initial';
  }

  // Função que controla um registro selecionado pelo usuário no checkbox
  selectCheckbox(event: any) {
    console.log('select checkbox');

    this.scripts_selecionados.push(event.data.id);
    console.log(this.scripts_selecionados);
  }

  unselectCheckbox(event: any){
    console.log('unselect checkbox');
    const index = this.scripts_selecionados.findIndex(c => c === event.data.id);
    this.scripts_selecionados.splice(index, 1);

    console.log(this.scripts_selecionados);
  }

  // Função que controla os registros selecionados pelo checkbox quando o usuário clica no "Select All"
  checkAll(event: any) {
    this.scripts_selecionados = new Array();

    if (event.checked) {
      this.scripts_filtrados.forEach(rst => {
        rst.selected = true;
        this.scripts_selecionados.push(rst.id);
      });
    } else {
      this.scripts_filtrados.forEach(rst => {
        rst.selected = false;
      });
    }
  }

  /* Função que habilita o modal e a atribuição de valor às variáveis correspondentes
  quando o usuário seleciona um registro para edição. */
  editarScriptDbl(event: any) {

    this.periodoObtencaoDados = undefined;

    this.currentId = event.id;
    this.currentTipoArquivo = event.tipo_arquivo;
    this.currentPeriodicidade = event.periodicidade;
    this.currentRecorrente = event.recorrente;
    this.currentPeriodo = event.periodo;

    this.modalEdicaoScriptDbl = true;
  }

  // Função que remove um registro selecionado pelo usuário
  removerScriptDbl(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-script-dbl(' + event.id + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });

              // Carregar novamente a tabela principal na tela
              this.carregarScriptsDbl();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }

  }

  // Função que remove todos os registros selecionados pelo usuário no checkbox
  removerSelecionados() {
    console.log(this.scripts_selecionados);
    if (confirm('Deseja deletar esse(s) registro(s)?')) {

      // Realizar a operação se houver ao menos 1 registro selecionado
      if (this.scripts_selecionados.length > 0) {
        let dados = {
          ids: this.scripts_selecionados
        }

        this.httpService.wait();
        this.httpService.post('/custom/dbl/remover-scripts-selecionados', dados)
          .subscribe(
            res => {
              if (res["error"] == false) {
                this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
                // Carregar novamente a tela principal na tela
                this.carregarScriptsDbl();
              } else {
                this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
              }
              this.httpService.done();
            },
            err => {
              this.exibirMensagem(err);
              console.log(err);
              this.httpService.done();
            }
          );
      } else {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "Nenhum registro selecionado." });
      }
    }

  }

  /* Função que faz a atribuição de valor às variáveis correspondentes
  quando o usuário abre o modal para adição de registro */
  adicionarScriptDbl() {
    this.modalCriacaoScriptDbl = true;
    this.opcoesRecorrenteEscolhido = "SELECIONE";
    this.opcoesTipoArquivoEscolhido = "SELECIONE";
    this.opcoesPeriodicidadeEscolhido = "SELECIONE";
    this.periodoSelecionado = undefined;

  }

  // Função que salva no Banco de Dados um script DBL criado pelo usuário
  salvarScriptDbl() {

    // Checar as condições antes de realizar a operação no Banco de Dados
    if (this.opcoesTipoArquivoEscolhido == "SELECIONE") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg', detail: 'Selecione um tipo de arquivo.' });
    } else if (this.opcoesPeriodicidadeEscolhido == "SELECIONE") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg', detail: 'Selecione uma periodicidade.' });
    } else if (this.opcoesRecorrenteEscolhido == "SELECIONE") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg', detail: 'Selecione uma recorrência.' });
    } else if ((this.opcoesPeriodicidadeEscolhido == "IMEDIATO") &&
      (this.opcoesRecorrenteEscolhido == "SIM")) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg', detail: 'Scripts recorrentes não devem possuir periodicidade imediata.' });
    } else if ((this.opcoesPeriodicidadeEscolhido != "IMEDIATO") &&
      (this.opcoesRecorrenteEscolhido == "NÃO")) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg', detail: 'Scripts não recorrentes devem possuir periodicidade imediata.' });

    } else {

      /* Checar qual período deve ser inserido. Se o campo não foi usado no modal,
      obter o período selecionado no ERP. Senão, obter mês e ano da data fornecida pelo usuário. */
      var periodo_final;
      if (this.periodoSelecionado == undefined) {
        let month = this.dblinkedSessionService.periodo.month.value.toString();
        if (month.length == 1) {
          month = "0" + month;
        }
        periodo_final = month + this.dblinkedSessionService.periodo.year.value.toString();
      } else {
        let month = String(this.periodoSelecionado.getMonth() + 1);
        let day = String(this.periodoSelecionado.getDate());
        const year = String(this.periodoSelecionado.getFullYear());

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        periodo_final = month + year;
      }

      this.modalCriacaoScriptDbl = false;

      let dados = {
        periodo: periodo_final,
        tipoArquivo: this.opcoesTipoArquivoEscolhido,
        periodicidade: this.opcoesPeriodicidadeEscolhido,
        recorrente: this.opcoesRecorrenteEscolhido,
        empresaId: this.dblinkedSessionService.empresa.id.toString(),
        usuarioId: this.sessionService.loggedUser.id.toString()
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/gravar-script-dbl', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Msg', detail: res["message"] });

              // Carregar novamente os dados da tabela principal na tela
              this.carregarScriptsDbl();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Msg', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  // Função que salva no Banco de Dados um script DBL editado pelo usuário
  salvarScriptDblEditado() {
    // Checar as condições antes de realizar a operação no Banco de Dados
    if (this.currentTipoArquivo == "SELECIONE") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg', detail: 'Selecione um tipo de arquivo.' });
    } else if (this.currentPeriodicidade == "SELECIONE") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg', detail: 'Selecione uma periodicidade.' });
    } else if (this.currentRecorrente == "SELECIONE") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg', detail: 'Selecione uma recorrência.' });
    } else if ((this.currentPeriodicidade == "IMEDIATO") &&
      (this.currentRecorrente == "SIM")) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg', detail: 'Scripts recorrentes não devem possuir periodicidade imediata.' });
    } else if ((this.currentPeriodicidade != "IMEDIATO") &&
      (this.currentRecorrente == "NÃO")) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg', detail: 'Scripts não recorrentes devem possuir periodicidade imediata.' });

    } else {

      /* Checar se o período deve ser alterado. Se o campo não foi usado no modal,
      seu valor deve ser mantido. Senão, obter mês e ano da data fornecida pelo usuário. */
      var periodo_final;
      if (this.periodoObtencaoDados == undefined) {
        periodo_final = this.currentPeriodo;
      } else {
        let month = String(this.periodoObtencaoDados.getMonth() + 1);
        let day = String(this.periodoObtencaoDados.getDate());
        const year = String(this.periodoObtencaoDados.getFullYear());

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        periodo_final = month + year;
      }

      this.modalEdicaoScriptDbl = false;

      let dados = {
        id: this.currentId,
        tipoArquivo: this.currentTipoArquivo,
        periodicidade: this.currentPeriodicidade,
        recorrente: this.currentRecorrente,
        empresaId: this.dblinkedSessionService.empresa.id.toString(),
        usuarioId: this.sessionService.loggedUser.id.toString(),
        periodo: periodo_final
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/editar-script-dbl', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Msg', detail: res["message"] });

              // Carregar novamente os dados da tabela principal na tela
              this.carregarScriptsDbl();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }


}
