export class TipoEcd {
  id: number;
  cod: string;
  descricao: string;

  constructor(tipoecd?: TipoEcd | number) {
    if (tipoecd !== undefined && tipoecd !== null) {
      if (typeof tipoecd === 'number') {
        this.id = tipoecd;
      } else {
        this.id = tipoecd.id;
        this.cod = tipoecd.cod;
        this.descricao = tipoecd.descricao;
      }
    }
  }
}
