// NÃO COMENTAR ->
import { AuthAppComponent } from './proof/app/app.component'
import { AuthCrowdComponent } from './proof/crowd/crowd.component'
import { AuthFeatureComponent } from './proof/feature/feature.component'
import { AuthFeatureUrlComponent } from './proof/feature-url/feature-url.component'
import { AuthGroupComponent } from './proof/group/group.component'
import { AuthLogComponent } from './proof/log/log.component'
import { AuthProfileComponent } from './proof/profile/profile.component'
import { AuthService } from './shared/services/auth.service'
import { AuthStatusComponent } from './proof/auth-status/status.component'
import { AuthUserComponent } from './proof/user/user.component'
import { DashboardComponent } from './main/dashboard/dashboard.component'
import { LoginComponent } from './proof/login/login.component'
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { UsuarioComponent } from './cadastro/usuario/usuario.component'
import { Dashboard } from 'powerbi-client'

// LIBERADO COMENTAR ->
import { AcessoComponent } from './powerbi/acesso/acesso.component'
import { AgendaComponent } from 'app/centralweb/agenda/agenda.component'
import { AliquotaTributoComponent } from './cadastro/aliquota-tributo/aliquota-tributo.component'
import { AnaliseXmlImportPlanilhaComponent } from './xml/analise/import.component'
import { AprovadorComponent } from './rms/aprovador/aprovador.component'
import { AprovadorFaturaComponent } from './financeiro/fatura/configuracao/aprovador-fatura/aprovador-fatura.component'
import { ArquivosMagneticosComponent } from './fiscal/arquivos-magneticos/arquivos-magneticos-component'
import { AtaOrcamentoComponent } from './orcamento/ata-orcamento/ata-orcamento.component';
import { AuditorParticipanteComponent } from './cadastro/auditor/participante/participante.component'
import { AutorizacaoDebitoComponent } from 'app/centralweb/autorizacao-debito/autorizacao-debito.component'
import { BeneficioFiscalComponent } from './rms/beneficio-fiscal/beneficio-fiscal.component'
import { BoletoComponent } from './pagseguro/boleto/boleto.component'
import { CadAglutComponent } from './contabil/cad-aglut/cad-aglut.component'
import { CadAglutRelacComponent } from './contabil/cad-aglut-relac/cad-aglut-relac.component'
import { CadastrarPlataformaComponent } from './cadastro/cadastrar-plataforma/cadastrar-plataforma.component'
import { CadastroAprovacao } from './cadastro/aprovacao/aprovacao.component'
import { CadastroAreaComponent } from './orcamento/cadastro-area/cadastro-area.component'
import { CadastroComissaoComponent } from './financeiro/cadastro-comissao/cadastro-comissao.component'
import { CadastroGruposEmpresasComponent } from 'app/cadastro/cadastro-grupo-empresa/cadastro-grupo-empresa.component'
import { CadastroInformacaoComponent } from 'app/cadastro/cadastro-informacao-empresa/cadastro-informacao-empresa.component'
import { CadastroMenuComponent } from './controle-acesso/cadastro-menu/cadastro-menu.component'
import { CadastroPowerBiComponent } from './powerbi/cadastro/cadastro.component'
import { CadastroRegraCentralWebComponent } from './financeiro/fatura/configuracao/cadastro-regra-centralweb/cadastro-regra-centralweb.component'
import { CadastroRegraOfxComponent } from './financeiro/fatura/configuracao/cadastro-regra-ofx/cadastro-regra-ofx.component'
import { CadBenefEncargoCtaRelac } from './recursos-humanos/cad-benef-encargo-cta-relac/cad-benef-encargo-cta-relac.component'
import { CadBeneficioComponent } from './recursos-humanos/cad-beneficios/cad-beneficios.component'
import { CadCargoComponent } from './recursos-humanos/cad-cargo/cad-cargo.component'
import { CadCcusComponent } from './contabil/cad-ccus/cad-ccus.component'
import { CadEcdComponent } from './contabil/cad-ecd/cad-ecd.component'
import { CadEcfComponent } from './ecf/cad-ecf/cad-ecf.component'
import { CadEncargosComponent } from './recursos-humanos/cad-encargos/cad-encargos.component'
import { CadFuncComponent } from './recursos-humanos/cad-funcionarios/cad-func.component'
import { CadHistPadraoComponent } from './contabil/cad-hist-padrao/cad-hist-padrao.component'
import { CadLeiDoBemComponent } from './contabil/cad-lei-do-bem/cad-lei-do-bem.component'
import { CadNivelComponent } from './recursos-humanos/cad-nivel/cad-nivel.component'
import { CadParticipanteComponent } from './contabil/cad-participante/cad-participante.component'
import { CadParticipanteRelacComponent } from './contabil/cad-participante-relac/cad-participante-relac.component'
import { CadPeriodoComponent } from './recursos-humanos/cad-periodos/cad-periodos.component'
import { CadProjComponent } from './contabil/cad-proj/cad-proj.component'
import { CadRateioComponent } from './recursos-humanos/cad-rateio/cad-rateio.component'
import { CalendarioComponent } from './financeiro/calendario/calendario.component'
import { CalendarioNewComponent } from './financeiro/calendario-new/calendario-new.component'
import { CambioComponent } from './financeiro/cambio/cambio.component'
import { CampanhaComponent } from './eventos/campanha/campanha.component'
import { CarteiraComponent } from './eventos/carteira/carteira.component'
import { CentralAprovacaoComponent } from 'app/centralweb/central-aprovacao/central-aprovacao.component'
import { CertificadoComponent } from './empresa/certificado/certificado.component'
import { CertificadosDigitaisComponent } from './fiscal/certificados-digitais/certificados-digitais.component'
import { CfgApiAdmComponent } from './api/configuracoes/cfg-api-adm/cfg-api-adm.component'
import { CfgApiClientComponent } from './api/configuracoes/cfg-api-client/cfg-api-client.component'
import { CfopComponent } from './util/cfop/cfop.component'
import { CheckInComponent } from './eventos/checkin/checkin.component'
import { CheckListComponent } from 'app/centralweb/checklist/check-list.component'
import { ClassificacaoComponent } from './orcamento/classificacao/classificacao.component'
import { CodigosObrigacoesComponent } from './util/codigos-obrigacoes/codigos-obrigacoes.component'
import { CodNaturezaContaComponent } from './util/cod-natureza-conta/cod-natureza-conta.component'
import { CodRelComponent } from './util/cod-rel/cod-rel.component'
import { ComissaoBaseComponent } from './financeiro/comissao-base/comissao-base.component'
import { ComissaoBaseEmpresaComponent } from './financeiro/comissao-base-empresa/comissao-base-empresa.component'
import { ComissaoTipoComponent } from './financeiro/comissao-tipo/comissao-tipo.component'
import { ComposicaoFormalizacaoComponent } from 'app/contabil/composicao-formalizacao/composicao-formalizacao.component'
import { ConfDashboardComponent } from './financeiro/conf-dashboard/conf-dashboard.component'
import { ConfigBudgetDashboardComponent } from './orcamento/configuracoes/dashboard/configuracao-dashboard.component'
import { ConfigCadCCusCadProjComponent } from './contabil/config-cad-ccus-cad-proj/config-cad-ccus-cad-proj.component'
import { ConfigCategoriaComponent } from './rms/categoria/categoria.component'
import { ConfigNdaComponent } from './rms/nda/nda.component'
import { ConfigObjetoComponent } from './rms/objeto/objeto.component'
import { ConfigOrigemComponent } from './rms/origem/origem.component'
import { ConfigPrioridadeComponent } from './rms/prioridade/prioridade.component'
import { ConfigRelatComponent } from './powerbi/config-relat/config-relat.component'
import { ConfigTipoAnexoComponent } from './rms/tipo-anexo/tipo-anexo.component'
import { ConfigTipoComponent } from './rms/tipo/tipo.component'
import { ConfiguracaoImportacaoComponent } from './orcamento/configuracao-importacao/configuracao-importacao.component'
import { ConfiguracoesAPIsComponent } from './cadastro/configuracoes-apis/configuracoes-apis.component'
import { ConfirmarRecebimentoComponent } from 'app/centralweb/confirmar-recebimento/confirmar-recebimento.component'
import { ConsultaClientesComponent } from './eventos/consulta-clientes/consulta-clientes.component'
import { ConsultorComponent } from './eventos/consultor/consultor.component'
import { ContabilImportComponent } from './contabil/import/import.component'
import { ContabilImportConciliacaoComponent } from './contabil/import-conciliacao/import-conciliacao.component'
import { ContabilImportPlanilhaComponent } from './contabil/import-planilha/import-planilha.component'
import { ControleGrupoComponent } from './controle-acesso/controle-grupo/controle-grupo.component'
import { ControleOrcamentoComponent } from './orcamento/controle-orcamento/controle-orcamento.component'
import { ControleOrcamentoFiniComponent } from './orcamento/controle-orcamento-fini/controle-orcamento-fini.component'
import { ControleUsuarioComponent } from './controle-acesso/controle-usuario/controle-usuario.component'
import { CstIcmsComponent } from './util/cst-icms/cst-icms.component'
import { CstPiscofinsComponent } from './util/cst-piscofins/cst-piscofins.component'
import { CteComponent } from './xml/cte/cte.component'
import { DashboardCentralComponent } from 'app/centralweb/dashboard/dashboard.component'
import { DashboardComprasComponent } from './financeiro/dashboard-compra/dashboard.component'
import { DashboardEstoque2Component } from './estoque/dashboard2/dashboard2.component'
import { DashboardEstoqueComponent } from './estoque/dashboard/dashboard.component'
import { DashboardFinanceiroComponent } from './financeiro/dashboard/dashboard.component'
import { DashboardNewComponent3 } from './financeiro/dashboard-new3/dashboard-new3.component'
import { DashboardNewComponent4 } from './financeiro/dashboard-new4/dashboard-new4.component'
import { DashboardOrcamentoBIComponent } from './orcamento/dashboard-bi/dashboard-bi.component'
import { DashboardOrcamentoNewComponent } from './orcamento/dashboard-orca-new/dashboard-orca-new.component'
import { DashboardRH } from './recursos-humanos/dashboard/dashboard.component'
import { DashboardTransferComponent1 } from './transfer/dashboard1/dashboard1.component'
import { DashboardVendasComponent } from './financeiro/dashboard-venda/dashboard.component'
import { DblComponent } from './projeto-dbl/projeto-dbl.component'
import { DblConfigImportacaoComponent } from './projeto-dbl/configuracao-importacao/dbl-config-importacao.component'
import { DblImportComponent } from './dbl/import-dbl/import-dbl.component'
import { DeptoEmpresaComponent } from './cadastro/depto-empresa/depto-empresa.component'
import { DestinatariosComponent } from 'app/centralweb/destinatarios/destinatarios.component'
import { DirfComponent } from './dirf/main/dirf.component'
import { DistribGPComponent } from './cadastro/distrib-gp/distrib-gp.component'
import { EcdExportComponent } from './contabil/ecd-export/export.component'
import { EcdExport2Component } from './contabil/ecd-export2/export2.component'
import { EcdLctoEncerramentoComponent } from './contabil/lcto-encerramento/lcto-encerramento.component'
import { EcdReportBalanceteMensalComponent } from './contabil/report-balancete-mensal/report-balancete-mensal.component'
import { EcdReportBalanceteMensalNewComponent } from './contabil/report-balancete-mensal-new/report-balancete-mensal-new.component'
import { EcdReportBalanceteMensalLctoComponent } from './contabil/report-balancete-mensal-lcto/report-balancete-mensal-lcto.component'
import { EcdReportComponent } from './contabil/report/report.component'
import { EcdReportNewComponent } from './contabil/reportnew/reportnew.component'
import { EcdReportEvolucaoMensalComponent } from './contabil/report-evolucao-mensal/report-evolucao-mensal.component'
import { EcdReportGerencial } from './contabil/report-gerencial/report-gerencial.component'
import { EcdReportPlanoContasComponent } from './contabil/report-plano-contas/report-plano-contas.component'
import { EcfExportComponent } from './contabil/ecf-export/export.component'
import { EditLivroComponent } from './fiscal/edit-livro/edit-livro.component'
import { EduzzCargaComponent } from './eduzz/eduzz-carga/eduzz-carga.component'
import { EmpresaAlmoxarifadoComponent } from 'app/cadastro/empresa-almoxarifado/empresa-almoxarifado.component'
import { EmpresaCnaeComponent } from 'app/cadastro/empresa-cnae/empresa-cnae.component'
import { EmpresaComponent } from './cadastro/empresa/empresa.component'
import { EmpresaContaComponent } from 'app/cadastro/empresa-conta/empresa-conta.component'
import { EmpresaContaRefComponent } from 'app/cadastro/empresa-conta-ref/empresa-conta-ref.component'
import { EmpresaInfoComponent } from './cadastro/empresa-info/empresa-info.component'
import { EmpresaParametroBaixaComponent } from 'app/cadastro/empresa-parametro-baixa/empresa-parametro-baixa.component'
import { EmpresaParametroContabilComponent } from 'app/cadastro/empresa-parametro-contabil/empresa-parametro-contabil.component'
import { EmpresaParametroFinanceiroComponent } from 'app/cadastro/empresa-parametro-financeiro/empresa-parametro-financeiro.component'
import { EnquadramentoComponent } from './siscoserv/enquadramento/enquadramento.component'
import { EnviadosComponent } from 'app/centralweb/enviados/enviados.component'
import { EspacoCliente } from 'app/api/espaco-cliente/espaco-cliente.component'
import { EstoqueImportPlanilhaComponent } from './estoque/import/import.component'
import { EventoPagamentoPagSeguroComponent } from './eventos/evento-pagamento-pagseguro/evento-pagamento-pagseguro.component'
import { EventoProjRelacComponent } from './eventos/evento-proj-relac/evento-proj-relac.component'
import { EventosComponent } from './eventos/eventos/eventos.component'
import { EventosPlanoComponent } from './eventos/eventos-plano/eventos-plano.component'
 import { ExportOrcamentoComponent } from './orcamento/exp-orca/exp-orca.component'
import { ExtratoOfxComponent } from './financeiro/extrato-ofx/extrato-ofx.component'
import { FaturaComponent } from './financeiro/fatura/fatura.component'
import { FeriadosComponent } from './util/feriados/feriados.component'
import { FichaEstoqueComponent } from './estoque/ficha-estoque/ficha-estoque.component'
import { FichaEstoqueDetalheComponent } from './estoque/ficha-estoque-detalhe/ficha-estoque-detalhe.component'
import { FichasComponent } from './eventos/fichas/fichas.component'
import { FichasConfEmailComponent } from './eventos/fichas-conf-email/fichas-conf-email.component'
import { FichasEventoComponent } from './eventos/fichas-evento/fichas-evento.component'
import { FiscalImportComponent } from './fiscal/import-fiscal/import-fiscal.component'
import { FluxoAprovComponent } from './shared/components/fluxo-aprov/fluxo-aprov.component'
import { FluxoCaixaComponent } from './financeiro/fluxo-caixa/fluxo-caixa.component'
import { FluxoCaixaCtrlComponent } from './financeiro/fluxo-caixa-ctrl/fluxo-caixa-ctrl.component'
import { FormaEscritContabilComponent } from './util/forma-escrit-contabil/forma-escrit-contabil.component'
import { FormalizacaoConciliacaoComponent } from 'app/contabil/formalizacao-conciliacao/formalizacao-conciliacao.component'
import { FormaPagamentoComponent } from './financeiro/forma-pagamento/forma-pagamento.component'
import { FormaPagamentoRefacComponent } from './financeiro/forma-pagamento-refac/forma-pagamento-refac.component'
import { FormularioComponent } from 'app/eventos/formulario/formulario.component'
import { FormularioMotivoCancelamentoComponent } from './eventos/formulario-motivo-cancelamento/formulario-motivo-cancelamento.component'
import { FormularioMotivoReagendamentoComponent } from './eventos/formulario-motivo-reagendamento/formulario-motivo-reagendamento.component'
import { FormularioPerguntasCamposComponent } from 'app/eventos/formulario-perguntas-campos/formulario-perguntas-campos.component'
import { FormularioVisualizacaoRespostasCamposComponent } from 'app/eventos/formulario-visualizacao-respostas/formulario-visualizacao-respostas.component'
import { GatilhoObrigacaoComponent } from 'app/centralweb/gatilho-obrigacao/gatilho-obrigacao.component'
import { GerencialOrcamentoComponent } from './orcamento/gerencial/gerencial.component'
import { GerenciarBoletoComponent } from './pagseguro/gerenciar-boleto/gerenciar-boleto.component'
import { GestaoGrupoOperacaoComponent } from './cadastro/gestao-grupo-operacao/gestao-grupo-operacao.component'
import { GestaoGrupoOperacaoItemComponent } from './cadastro/gestao-grupo-operacao-item/gestao-grupo-operacao-item.component'
import { GestaoOrcamentoComponent } from './orcamento/gestao-orcamento/gestao-orcamento.component'
import { GestorCentroCustoComponent } from './cadastro/gestor-centro-custo/gestor-centro-custo.component'
import { GetnetAssinaturaComponent } from './getnet/getnet-assinatura/getnet-assinatura.component'
import { GetnetPagamentoCartaoCreditoComponent } from './getnet/getnet-pagamento-cartao-credito/getnet-pagamento-cartao-credito.component'
import { GetnetPlanosComponent } from './getnet/getnet-planos/getnet-planos.component'
import { GrupoComponent } from './cadastro/grupo/grupo.component'
import { GrupoEstoqueComponent } from './estoque/grupo-estoque/grupo-estoque.component'
import { GrupoOperacaoComponent } from './cadastro/grupo-operacao/grupo-operacao.component'
import { GrupoRegraEmpresaRelacComponent } from './cadastro/grupo-regra-empresa-relac/grupo-regra-empresa-relac.component'
import { GrupoRelacComponent } from './cadastro/grupo-relac/grupo-relac.component'
import { GrupoTributacaoComponent } from './cadastro/grupo-tributacao/grupo-tributacao.component'
import { GrupoTributacaoRefComponent } from './cadastro/grupo-tributacao-ref/grupo-tributacao-ref.component'
import { ImportacaoF600Component } from './fiscal/import-f600/import-f600.component'
import { ImportacaoOfxComponent } from './financeiro/fatura/configuracao/importacao-ofx/importacao-ofx.component'
import { ImportarArquivoComponent } from './eventos/importar-arquivo/importar-arquivos.component'
import { ImportarLeadComponent } from './eventos/importar-lead/importar-lead.component'
import { IndFinEscComponent } from './util/ind-fin-esc/ind-fin-esc.component'
import { IndGrandePorteComponent } from './util/ind-grande-porte/ind-grande-porte.component'
import { IndicesComponent } from './financeiro/indices/indices.component'
import { IndNireComponent } from './util/ind-nire/ind-nire.component'
import { IndSitEspComponent } from './util/ind-sit-esp/ind-sit-esp.component'
import { IndSitIniPerComponent } from './util/ind-sit-ini-per/ind-sit-ini-per.component'
import { InscritosEventoComponent } from './eventos/inscritos-evento/inscritos-evento.component'
import { ItemBomComponent } from './estoque/item-bom/item-bom.component'
import { ItemComponent } from './cadastro/item/item.component'
import { ItemContabilComponent } from './cadastro/item-contabil/item-contabil.component'
import { ItemGrupoComponent } from './cadastro/item-grupo/item-grupo.component'
import { ItemGrupoTributacaoComponent } from './cadastro/item-grupo-tributacao/item-grupo-tributacao.component'
import { ItemParticipanteRelacComponent } from './cadastro/item-participante-relac/item-participante-relac.component'
import { ItemRefComponent } from './cadastro/item-ref/item-ref.component'
import { ItemTipoComponent } from './util/item-tipo/item-tipo.component'
import { JustificativasComponent } from './util/justificativas/justificativas.component'
import { LancamentoComponent } from 'app/contabil/lancamento/lancamento.component'
import { LeadLoversComponent } from './eventos/leadlovers/leadlovers.component'
import { LFDemaisDocsComponent } from './fiscal/report-demais-docs/report-demais-docs.component'
import { LFIcmsIpiComponent } from './fiscal/report-icms-ipi/report-icms-ipi.component'
import { LFRetidosFonteComponent } from './fiscal/report-retidos-fonte/report-retidos-fonte.component'
import { LivrosFiscaisComponent } from './fiscal/report/report.component'
import { LocalidadeGrupoComponent } from './financeiro/localidade-grupo/localidade-grupo.component'
import { LocalidadeGrupoMunicipioComponent } from './financeiro/localidade-grupo-municipio/localidade-grupo-municipio.component'
import { LogComponent } from 'app/api/log/log.component'
import { LstComponent } from './util/lst/lst.component'
import { MaquinaCartaoComponent } from './cadastro/maquina-cartao/maquina-cartao.component'
import { MiComponent } from 'app/cadastro/mi/mi.component'
import { ModoPrestacaoComponent } from './siscoserv/modo-prestacao/modo-prestacao.component'
import { MoedaComponent } from './siscoserv/moeda/moeda.component'
import { MonitoramentoComponent } from './api/monitoramento/monitoramento.component'
import { MotivoEstornoComponent } from './eventos/motivo-estorno/motivo-estorno.component'
import { MovBalanceteComponent } from './contabil/mov-balancete/mov-balancete.component'
import { MovimentacaoComponent } from './financeiro/movimentacao/movimentacao.component'
import { MovimentacaoInternaComponent } from './estoque/movimentacao-interna/movimentacao-interna.component'
import { MovLctoContabilComponent } from './contabil/mov-lcto-contabil/mov-lcto-contabil.component'
import { MovTermoAeComponent } from './contabil/mov-termo-ae/mov-termo-ae.component'
import { MunicipioIbgeComponent } from './util/municipio-ibge/municipio-ibge.component'
import { MvaComponent } from './cadastro/mva/mva.component'
import { NaoNifComponent } from './siscoserv/nao-nif/nao-nif.component'
import { NbsComponent } from './siscoserv/nbs/nbs.component'
import { NfeComponent } from './xml/nfe/nfe.component'
import { NfeFinEditComponent } from './shared/components/nfe-fin-edit/nfe-fin-edit.component'
import { NotasEmitidasComponent } from './estoque/notas-emitidas/notas-emitidas-component'
import { NotasPendentesComponent } from './estoque/notas-pendentes/notas-pendentes.component'
import { ObrigacaoComponent } from 'app/centralweb/obrigacao/obrigacao.component'
import { OperacaoRegraComponent } from 'app/cadastro/operacao-regra/operacao-regra.component'
import { OperacaoRegraContabilComponent } from 'app/cadastro/operacao-regra-contabil/operacao-regra-contabil.component'
import { OperacaoRegraFiscalComponent } from 'app/cadastro/operacao-regra-fiscal/operacao-regra-fiscal.component'
 import { OrcamentoImportArquivoComponent } from './orcamento/importar-arquivos-orcamento/importar-arquivos-orcamento.component'
import { OrcamentoVersaoComponent } from './orcamento/configuracoes/orcamento-versao/orcamento-versao.component'
import { OrdensComponent } from './estoque/ordens/ordens.component'
import { PaisComponent } from './siscoserv/pais/pais.component'
import { PaisIbgeComponent } from './util/pais-ibge/pais-ibge.component'
import { PalestraClienteComponent } from './eventos/palestra-cliente/palestra-cliente.component'
import { PalestraClienteStatusComponent } from './eventos/palestra-cliente-status/palestra-cliente-status.component'
import { PalestraComponent } from './eventos/palestra/palestra.component'
import { ParametrosComponent } from './rms/parametros/parametros.component'
import { ParametrosVendasComponent } from './vendas/parametros-vendas/parametros-vendas.component'
import { ParteAM300CsllComponent } from './ecf/parte-a-m300-csll/parte-a-m300-csll.component'
import { ParteAM300IrpjComponent } from './ecf/parte-a-m300-irpj/parte-a-m300-irpj.component'
import { ParteAM300IrpjGerencialComponent } from './ecf/parte-a-m300-irpj-gerencial/parte-a-m300-irpj-gerencial.component'
import { ParteAM350CsllGerencialComponent } from './ecf/parte-a-m350-csll-gerencial/parte-a-m350-csll-gerencial.component'
import { ParteBComponent } from './ecf/parte-b/parte-b.component'
import { ParteBCsllComponent } from './ecf/parte-b-csll/parte-b-csll.component'
import { ParticipanteComponent } from './cadastro/participante/participante.component'
import { ParticipanteEmpresaComponent } from 'app/cadastro/participante-empresa/participante-empresa.component'
import { ParticipantesEventosComponent } from './eventos/participantes-eventos/participantes-eventos.component'
import { PedidoEditComponent } from './shared/components/pedido-edit/pedido-edit.component'
import { PedidoProdutoComponent } from './financeiro/pedido-produto/pedido-produto.component'
import { PedidoProdutoComponentNew } from './financeiro/pedido-produto-new/pedido-produto-new.component'
import { PedidoServicoComponent } from './financeiro/pedido-servico/pedido-servico.component'
import { PedidoServicoComponentNew } from './financeiro/pedido-servico-new/pedido-servico-new.component'
import { PedidosImportComponent } from './financeiro/import-pedidos/import-pedidos.component'
import { PedidoViewComponent } from './shared/components/pedido-view/pedido-view.component'
import { PendenciasComponent } from 'app/centralweb/pendencias/pendencias.component'
import { PeriodoBudgetComponent } from './orcamento/configuracoes/periodo/periodo-budget.component'
import { PeriodoEstoqueComponent } from './estoque/periodo/periodo.component'
import { PeriodoFinanceiroComponent } from './financeiro/periodo/periodo.component'
import { PeriodoFiscalComponent } from './fiscal/periodo/periodo.component'
import { PeriodoOrcamentoComponent } from './orcamento/periodo/periodo.component'
import { PeriodoTransferComponent } from './transfer/periodo/periodo.component'
import { PlanoContasComponent } from './contabil/planocontas/planocontas.component'
import { PlanoContasNewComponent } from './contabil/planocontas-new/planocontas-new.component'
import { PlanoContasRefRelacComponent } from './contabil/plano-contas-ref-relac/plano-contas-ref-relac.component'
import { PlanoContasRefRelacCsllComponent } from './contabil/plano-contas-ref-relac-csll/plano-contas-ref-relac-csll.component'
import { PlanoContasRefRelacIrpjComponent } from './contabil/plano-contas-ref-relac-irpj/plano-contas-ref-relac-irpj.component'
import { PlanoContasRefRelacNewComponent } from './contabil/plano-contas-ref-relac-new/plano-contas-ref-relac-new.component'
import { PlanoContasRfbComponent } from './contabil/plano-contas-rfb/plano-contas-rfb.component'
import { PlanoGerencialComponent } from './contabil/planogerencial/planogerencial.component'
import { PlanoGerencialContasComponent } from './contabil/planogerencialcontas/planogerencialcontas.component'
import { PlanoGerencialNewComponent } from './contabil/planogerencialnew/planogerencialnew.component'
import { PosicaoCrCpComponent } from './financeiro/posicao-cr-cp/posicao-cr-cp.component'
import { PosicaoEstoqueComponent } from './contabil/posicao-estoque/posicao-estoque.component'
import { ProjetoDblComponent } from './cadastro/projeto-dbl/projeto-dbl.component'
import { RecebPagtoComponent } from './financeiro/receb-pagto/receb-pagto.component'
import { RegistroExportacaoComponent } from './fiscal/registro-exportacao/registro-exportacao.component'
import { RegraComponent } from './cadastro/regra/regra.component'
import { RegraContabilOrigemComponent } from './cadastro/regra-contabil-origem/regra-contabil-origem.component'
import { RegraRelacComponent } from './cadastro/regra-relac/regra-relac.component'
import { ReinfEventosComponent } from './reinf/eventos/eventos.component'
import { ReinfEventosDetalheComponent } from './reinf/eventos-detalhe/eventos-detalhe.component'
import { RelacionarCampanhaCarteiraComponent } from './eventos/relacionar-campanha-carteira/relacionar-campanha-carteira.component'
import { RelacionarCarteiraClientesComponent } from './eventos/relacionar-carteira-clientes/relacionar-carteira-clientes.component'
import { RelatorioAgendaComponent } from 'app/centralweb/relatorio-agenda/relatorio-agenda.component'
import { RelatorioAgendaEditComponent } from 'app/centralweb/relatorio-agenda-edit/relatorio-agenda-edit.component'
import { RelatorioBoletosComponent } from './pagseguro/relatorio-boletos/relatorio-boleto.component'
import { RelatorioComponent } from './financeiro/relatorio/relatorio.component'
import { RelatorioDetalheComponent } from './financeiro/relatorio-detalhe/relatorio-detalhe.component'
import { RelatorioEstoqueComponent } from './estoque/relatorio/relatorios-estoque.component'
import { RelatorioFinanceiroComponent } from './financeiro/relatorio-financeiro/relatorio-financeiro.component'
import { RelatorioFluxoCaixaComponent } from 'app/financeiro/relatorio-fluxo-caixa/relatorio-fluxo-caixa.component'
import { RelatorioPedidosComponent } from './financeiro/relatorio-pedidos/relatorio-pedidos.component'
import { RelatoriosComponent } from 'app/centralweb/relatorios/relatorios.component'
import { RelatoriosGerenciaisComponent } from './powerbi/relatorios-gerenciais/relatorios-gerenciais.component'
 import { RelatoriosOrcamentoComponent } from './orcamento/relatorios-gerenciais/relatorios-gerenciais.component'
 import { RelatoriosOrcamentoGestComponent } from './orcamento/relatorios-gerenciais-gest/relatorios-gerenciais-gest.component'
import { RelatoriosPbiComponent } from './powerbi/relatorios/relatorios.component'
import { RelatoriosTPComponent } from './transfer/relatorios/relatorios.component'
import { RemessasComponent } from './financeiro/remessas/remessas.component'
import { ReportXmlComponent } from './fiscal/report-xml/report-xml.component'
import { RequisicaoComponent } from './estoque/requisicao/requisicao.component'
import { RequisicaoMaterialComponent } from './rms/requisicao-material/requisicao-material.component'
import { RespCadItemComponent } from './cadastro/resp-cad-item/resp-cad-item.component'
import { RHImportarPlanilhasComponent } from './recursos-humanos/importar-fp/importar-fp.component'
import { RHRelatorios } from './recursos-humanos/relatorios-rh/relatorio-rh.component'
import { SegmentoParticipanteComponent } from './cadastro/segmento-participante/segmento-participante.component'
import { SeminaristaComponent } from './eventos/seminarista/seminarista.component'
import { SignatariosComponent } from './cadastro/signatarios/signatarios.component'
import { SiscoservEditarComponent } from './siscoserv/editar-arquivos/editar.component'
import { SiscoservExportXmlComponent } from './siscoserv/export-xml/export-xml.component'
import { SiscoservImportComponent } from './siscoserv/import/import.component'
import { SiscoservStatusComponent } from './siscoserv/status/status.component'
import { StaffComponent } from './eventos/staff/staff.component'
import { TabelaCrmCargaComponent } from './crm/tabela-crm-carga/tabela-crm-carga.component'
import { TabelaCrmComponent } from './crm/tabela-crm/tabela-crm.component'
import { TabelaCrmDetalheComponent } from './crm/tabela-crm-detalhe/tabela-crm-detalhe.component'
import { TaxaMoedaComponent } from './orcamento/configuracoes/taxa-moeda/taxa-moeda.component'
import { TesteComponent } from './front/teste/teste.component'
import { TipiComponent } from './util/tipi/tipi.component'
import { TipoArquivoComponent } from './util/tipo-arquivo/tipo-arquivo.component'
import { TipoDocumentoComponent } from './util/tipo-documento/tipo-documento.component'
import { TipoEcdComponent } from './util/tipo-ecd/tipo-ecd.component'
import { TipoIngressoComponent } from './eventos/tipo-ingresso/tipo-ingresso.component'
import { TipoOrcamentoComponent } from './orcamento/tipo-orcamento/tipo-orcamento.component'
import { TipoPagamentoComponent } from './cadastro/tipo-pagamento/tipo-pagamento.component'
import { TipoRegistroComponent } from './siscoserv/tipo-registro/tipo-registro.component'
import { TipoVinculoComponent } from './siscoserv/tipo-vinculo/tipo-vinculo.component'
import { TransferImportPlanilhaComponent } from './transfer/import/import.component'
import { TransferirParticipanteEventoComponent } from './eventos/transferir-participante-evento/transferir-participante-evento.component'
import { TransferRelatorios } from './transfer/relatorios-transfer/relatorio-transfer.component'
import { TributosPagamentoRelacComponent } from 'app/centralweb/tributos-pagamento-relac/tributos-pagamento-relac.component'
import { TxtImpConcComponent } from './contabil/txt-imp-conc/txt-imp-conc.component'
import { UfComponent } from './util/uf/uf.component'
import { UploadImplantacaoComponent } from './implantacao/upload/upload.component'
import { UnidadeMedida } from './util/unidade-medida/unidade-medida.component'
import { UsGaapComponent } from './contabil/us-gaap/us-gaap.component'
import { VendedorComponent } from './eventos/vendedor/vendedor.component'
import { ViewCamposComponent } from './cadastro/view-campos/view-campos.component'
import { ViewComponent } from './cadastro/view/view.component'
import { ViewProjetoDblRelacComponent } from './cadastro/view-projeto-dbl-relac/view-projeto-dbl-relac.component'
import { VinculacaoRegraContabilComponent } from './cadastro/vinculacao-regra-contabil/vinculacao-regra-contabil.component'
import { WorkFlowComponent } from './centralweb/work-flow/work-flow.component'
import { WorkFlowProjetoComponent } from './centralweb/work-flow-projeto/work-flow-projeto.component'
import { WorkstatsRenderRelatorioGerencial } from './powerbi/render-relatorio-gerencial/render-relatorio-gerencial.component'
import { XmlCompanyComponent } from './xml/company/company.component'
import { XmlDetlaheItemComponent } from './fiscal/xml-detalhe-item/xml-detalhe-item.component'
import { XmlNaoEncontradoComponent } from './fiscal/xml-nao-encontrado/xml-nao-encontrado.component'

export const appRoutes: Routes = [

  // NÃO COMENTAR ->
  { path: 'auth-status/:id', component: AuthStatusComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'auth-status', component: AuthStatusComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'crowd/:id', component: AuthCrowdComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'crowd', component: AuthCrowdComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'user/:id', component: AuthUserComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'user', component: AuthUserComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'profile', component: AuthProfileComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'app/:id', component: AuthAppComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'app', component: AuthAppComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'feature/:id', component: AuthFeatureComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'feature', component: AuthFeatureComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'feature-url/:id', component: AuthFeatureUrlComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'feature-url', component: AuthFeatureUrlComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'group/:id', component: AuthGroupComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'group', component: AuthGroupComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'log/:id', component: AuthLogComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'log', component: AuthLogComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'login/:url', component: LoginComponent },
  { path: 'login/reset/:token', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LoginComponent },
  { path: 'usuario/:id', component: UsuarioComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'usuario', component: UsuarioComponent, canActivate: [AuthService], canDeactivate: [AuthService] },

  // LIBERADO COMENTAR ->
  { data: { mfa: true }, path: 'controle-acesso/controle-usuario', component: ControleUsuarioComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { data: { mfa: true }, path: 'empresa/empresa', component: EmpresaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'aliquota-tributo', component: AliquotaTributoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'aliquota-tributo/:id', component: AliquotaTributoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'analise-xml/import', component: AnaliseXmlImportPlanilhaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'api/espaco-cliente', component: EspacoCliente, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'api/log', component: LogComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'api/monitoramento', component: MonitoramentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'api/configuracoes/admin', component: CfgApiAdmComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'api/configuracoes/cliente', component: CfgApiClientComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'auditor/participante', component: AuditorParticipanteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'auditor/participante/:id', component: AuditorParticipanteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'beneficio-fiscal', component: BeneficioFiscalComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'beneficio-fiscal/:id', component: BeneficioFiscalComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-aglut-relac', component: CadAglutRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-aglut-relac/:id', component: CadAglutRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-aglut', component: CadAglutComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-aglut/:id', component: CadAglutComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-benef-encargo-cta-relac', component: CadBenefEncargoCtaRelac, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-beneficio', component: CadBeneficioComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-cargo', component: CadCargoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-ccus', component: CadCcusComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-ccus/:id', component: CadCcusComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-ecd', component: CadEcdComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-ecd/:id', component: CadEcdComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-ecf', component: CadEcfComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-ecf/:id', component: CadEcfComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-encargos', component: CadEncargosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-func', component: CadFuncComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-hist-padrao', component: CadHistPadraoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-hist-padrao/:id', component: CadHistPadraoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-lei-do-bem', component: CadLeiDoBemComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-lei-do-bem', component: CadLeiDoBemComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-nivel', component: CadNivelComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-participante-relac', component: CadParticipanteRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-participante-relac/:id', component: CadParticipanteRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-participante', component: CadParticipanteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-participante/:id', component: CadParticipanteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-periodo', component: CadPeriodoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-proj', component: CadProjComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-proj/:id', component: CadProjComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cad-rateio', component: CadRateioComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cadastrar-plataforma', component: CadastrarPlataformaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cadastro-grupo-empresa', component: CadastroGruposEmpresasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cadastro-informacao-empresa', component: CadastroInformacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cadastro/aprovacao', component: CadastroAprovacao, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cadastro/gestor-centro-custo', component: GestorCentroCustoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'calendario-new', component: CalendarioNewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'calendario-new', component: CalendarioNewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'calendario', component: CalendarioComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cambio', component: CambioComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/agenda', component: AgendaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/check-list', component: CheckListComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/confirmar-recebimento(:id)', component: ConfirmarRecebimentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/dashboard', component: DashboardCentralComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/enviados', component: EnviadosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/gatilho-obrigacao', component: GatilhoObrigacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/obrigacao', component: ObrigacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/pendencias', component: PendenciasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/relatorio-agenda-edit', component: RelatorioAgendaEditComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/relatorio-agenda', component: RelatorioAgendaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/relatorios', component: RelatoriosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/tributos-pagamento-relac', component: TributosPagamentoRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/work-flow-projeto', component: WorkFlowProjetoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/work-flow/:todoProjetoId/:todoCardId', component: WorkFlowComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/work-flow/autorizacao-debito', component: AutorizacaoDebitoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'centralweb/work-flow/central-aprovacao', component: CentralAprovacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cfop', component: CfopComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cfop/:id', component: CfopComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cod-natureza-conta', component: CodNaturezaContaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cod-natureza-conta/:id', component: CodNaturezaContaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cod-rel', component: CodRelComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cod-rel/:id', component: CodRelComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'codigos-obrigacoes', component: CodigosObrigacoesComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'codigos-obrigacoes/:id', component: CodigosObrigacoesComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'comissao-base-empresa', component: ComissaoBaseEmpresaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'comissao-base-empresa/:id', component: ComissaoBaseEmpresaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'comissao-base', component: ComissaoBaseComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'comissao-base/:id', component: ComissaoBaseComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'comissao-tipo', component: ComissaoTipoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'comissao-tipo/:id', component: ComissaoTipoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'compras/dashboard-compra', component: DashboardComprasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'conf-dashboard', component: ConfDashboardComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'config-cad-ccus-cad-proj', component: ConfigCadCCusCadProjComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'config-relat', component: ConfigRelatComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'configuracoes-apis', component: ConfiguracoesAPIsComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'contabil/composicao-formalizacao', component: ComposicaoFormalizacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'contabil/empresa', component: EmpresaInfoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'contabil/formalizacao-conciliacao', component: FormalizacaoConciliacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'contabil/import-conciliacao', component: ContabilImportConciliacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'contabil/import-planilha', component: ContabilImportPlanilhaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'contabil/import', component: ContabilImportComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'contabil/import/:id', component: ContabilImportComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'contabil/lancamento', component: LancamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'contabil/posicao-estoque/:tipo', component: PosicaoEstoqueComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'contabil/txt-imp-conc', component: TxtImpConcComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'contabil/us-gaap/:tipo', component: UsGaapComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'controle-acesso/cadastro-menu', component: CadastroMenuComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'controle-acesso/controle-grupo', component: ControleGrupoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'crm/tabela-crm-carga', component: TabelaCrmCargaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'crm/tabela-crm-detalhe', component: TabelaCrmDetalheComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'crm/tabela-crm', component: TabelaCrmComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cst-icms', component: CstIcmsComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cst-icms/:id', component: CstIcmsComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cst-piscofins', component: CstPiscofinsComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'cst-piscofins/:id', component: CstPiscofinsComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'dashboard-financeiro', component: DashboardFinanceiroComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'dashboard-new3', component: DashboardNewComponent3, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'dashboard-new4', component: DashboardNewComponent4, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'dbl/import-dbl', component: DblImportComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'depto-empresa', component: DeptoEmpresaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'destinatarios', component: DestinatariosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'destinatarios/:id', component: DestinatariosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'dirf', pathMatch: 'full', redirectTo: 'dirf/main' },
  { path: 'dirf/main', component: DirfComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'distrib-gp', component: DistribGPComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ecd', pathMatch: 'full', redirectTo: 'ecd/export' },
  { path: 'ecd/export', component: EcdExportComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ecd/exportnew', component: EcdExport2Component, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ecd/lcto-encerramento', component: EcdLctoEncerramentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ecd/report-balancete-mensal-lcto/:id/:codCta/:dtIni/:dtFin/:tipo/:encerramento', component: EcdReportBalanceteMensalLctoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ecd/report-balancete-mensal/:id/:dtIni/:dtFin/:encerramento/:idioma', component: EcdReportBalanceteMensalComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ecd/report-balancete-mensal-new/:id/:dtIni/:dtFin/:encerramento/:idioma', component: EcdReportBalanceteMensalNewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ecd/report-evolucao-mensal/:id/:mes/:year/:cc/:cp/:idioma', component: EcdReportEvolucaoMensalComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ecd/report-plano-contas/:id', component: EcdReportPlanoContasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ecd/report', component: EcdReportComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ecd/reportnew', component: EcdReportNewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ecf', pathMatch: 'full', redirectTo: 'ecf/export' },
  { path: 'ecf/export', component: EcfExportComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eduzz/eduzz-carga', component: EduzzCargaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eduzz/eduzz-carga', component: EduzzCargaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-almoxarifado', component: EmpresaAlmoxarifadoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-almoxarifado/:id', component: EmpresaAlmoxarifadoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-cnae', component: EmpresaCnaeComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-cnae/:id', component: EmpresaCnaeComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-conta-ref', component: EmpresaContaRefComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-conta', component: EmpresaContaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-conta/:id', component: EmpresaContaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-parametro-baixa', component: EmpresaParametroBaixaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-parametro-baixa/:id', component: EmpresaParametroBaixaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-parametro-contabil', component: EmpresaParametroContabilComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-parametro-contabil/:id', component: EmpresaParametroContabilComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-parametro-financeiro', component: EmpresaParametroFinanceiroComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa-parametro-financeiro/:id', component: EmpresaParametroFinanceiroComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa/certificado-a1', component: CertificadoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa/certificado-a1/:id', component: CertificadoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'empresa/empresa/:id', component: EmpresaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'estoque/dashboard', component: DashboardEstoqueComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'estoque/dashboard2', component: DashboardEstoque2Component, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'estoque/import', component: EstoqueImportPlanilhaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'estoque/periodo', component: PeriodoEstoqueComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'estoque/relatorios', component: RelatorioEstoqueComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/campanha', component: CampanhaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/carteira', component: CarteiraComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/checkin', component: CheckInComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/consulta-clientes', component: ConsultaClientesComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/consulta-clientes', component: ConsultaClientesComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/consultor', component: ConsultorComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/evento-pagamento-pagseguro', component: EventoPagamentoPagSeguroComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/evento-pagamento-pagseguro', component: EventoPagamentoPagSeguroComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/eventos-plano', component: EventosPlanoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/eventos-plano', component: EventosPlanoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/eventos', component: EventosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/fichas-conf-email', component: FichasConfEmailComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/fichas-evento', component: FichasEventoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/fichas', component: FichasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/formulario-motivo-cancelamento', component: FormularioMotivoCancelamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/formulario-motivo-cancelamento', component: FormularioMotivoCancelamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/formulario-motivo-reagendamento', component: FormularioMotivoReagendamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/formulario-motivo-reagendamento', component: FormularioMotivoReagendamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/formulario-perguntas-campos', component: FormularioPerguntasCamposComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/formulario-visualizacao-respostas', component: FormularioVisualizacaoRespostasCamposComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/formulario', component: FormularioComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/importar-arquivos', component: ImportarArquivoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/importar-arquivos', component: ImportarArquivoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/importar-lead', component: ImportarLeadComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/importar-lead', component: ImportarLeadComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/inscritos-evento', component: InscritosEventoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/leadlovers', component: LeadLoversComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/motivo-estorno', component: MotivoEstornoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/participantes-eventos/:tipo/:id', component: ParticipantesEventosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/relacionar-campanha-carteira', component: RelacionarCampanhaCarteiraComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/relacionar-carteira-clientes', component: RelacionarCarteiraClientesComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/staff', component: StaffComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/transferir-participante-evento', component: TransferirParticipanteEventoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/transferir-participante-evento', component: TransferirParticipanteEventoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'eventos/vendedor', component: VendedorComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'extrato-ofx', component: ExtratoOfxComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'feriados', component: FeriadosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'feriados/:id', component: FeriadosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ficha-estoque-detalhe', component: FichaEstoqueDetalheComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ficha-estoque', component: FichaEstoqueComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/cadastro-comissao', component: CadastroComissaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/fatura', component: FaturaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/fatura/configuracao/aprovador-fatura', component: AprovadorFaturaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/fatura/configuracao/cadastro-regra-centralweb', component: CadastroRegraCentralWebComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/fatura/configuracao/cadastro-regra-ofx', component: CadastroRegraOfxComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/fatura/configuracao/importacao-ofx', component: ImportacaoOfxComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/fluxo-caixa-ctrl', component: FluxoCaixaCtrlComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/fluxo-caixa', component: FluxoCaixaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/import-pedidos', component: PedidosImportComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/parametros-vendas', component: ParametrosVendasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/periodo', component: PeriodoFinanceiroComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/relatorio-fluxo-caixa', component: RelatorioFluxoCaixaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'financeiro/relatorio', component: RelatorioComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/arquivos-magneticos', component: ArquivosMagneticosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/certificados-digitais', component: CertificadosDigitaisComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/certificados-digitais', component: CertificadosDigitaisComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/edit-livro', component: EditLivroComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/import-f600', component: ImportacaoF600Component, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/import-f600', component: ImportacaoF600Component, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/import-fiscal', component: FiscalImportComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/periodo', component: PeriodoFiscalComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/registro-exportacao', component: RegistroExportacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/report-demais-docs/:id/:dtIni/:dtFin/:entradaSaida', component: LFDemaisDocsComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/report-icms-ipi/:id/:dtIni/:dtFin/:entradaSaida/:detalhePorItem', component: LFIcmsIpiComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/report-retidos-fonte/:id/:dtIni/:dtFin/:detalhePorItem', component: LFRetidosFonteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/report-xml', component: ReportXmlComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/report', component: LivrosFiscaisComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/xml-detalhe-item/:id/:entradaSaida/:year/:month', component: XmlDetlaheItemComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fiscal/xml-nao-encontrado/:id/:year/:month', component: XmlNaoEncontradoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'fluxo-aprov', component: FluxoAprovComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'forma-escrit-contabil', component: FormaEscritContabilComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'forma-escrit-contabil/:id', component: FormaEscritContabilComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'forma-pagamento', component: FormaPagamentoRefacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'gestao-grupo-operacao-item', component: GestaoGrupoOperacaoItemComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'gestao-grupo-operacao', component: GestaoGrupoOperacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'getnet/getnet-assinatura', component: GetnetAssinaturaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'getnet/getnet-assinatura', component: GetnetAssinaturaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'getnet/getnet-pagamento-cartao-credito', component: GetnetPagamentoCartaoCreditoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'getnet/getnet-pagamento-cartao-credito', component: GetnetPagamentoCartaoCreditoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'getnet/getnet-planos', component: GetnetPlanosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'getnet/getnet-planos', component: GetnetPlanosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'grupo-estoque', component: GrupoEstoqueComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'grupo-estoque/:id', component: GrupoEstoqueComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'grupo-operacao', component: GrupoOperacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'grupo-regra-empresa-relac', component: GrupoRegraEmpresaRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'grupo-relac', component: GrupoRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'grupo-tributacao', component: GrupoTributacaoRefComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'grupo-tributacao/:id', component: GrupoTributacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'grupo', component: GrupoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'grupo/:id', component: GrupoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'implantacao/upload', component: UploadImplantacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ind-fin-esc', component: IndFinEscComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ind-fin-esc/:id', component: IndFinEscComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ind-grande-porte', component: IndGrandePorteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ind-grande-porte/:id', component: IndGrandePorteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ind-nire', component: IndNireComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ind-nire/:id', component: IndNireComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ind-sit-esp', component: IndSitEspComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ind-sit-esp/:id', component: IndSitEspComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ind-sit-ini-per', component: IndSitIniPerComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ind-sit-ini-per/:id', component: IndSitIniPerComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'indices', component: IndicesComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'item-bom', component: ItemBomComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'item-contabil', component: ItemContabilComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'item-grupo-tributacao', component: ItemGrupoTributacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'item-grupo-tributacao/:id', component: ItemGrupoTributacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'item-grupo', component: ItemGrupoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'item-grupo/:id', component: ItemGrupoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'item-participante-relac', component: ItemParticipanteRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'item-ref', component: ItemRefComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'item', component: ItemComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'item/:id', component: ItemComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'justificativas', component: JustificativasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'justificativas/:id', component: JustificativasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'lalur', pathMatch: 'full', redirectTo: 'lalur/parte-a-m300-irpj' },
  { path: 'lalur/parte-a-m300-csll', component: ParteAM300CsllComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'lalur/parte-a-m300-irpj-gerencial', component: ParteAM300IrpjGerencialComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'lalur/parte-a-m300-irpj', component: ParteAM300IrpjComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'lalur/parte-a-m350-csll-gerencial', component: ParteAM350CsllGerencialComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'lalur/parte-b-csll', component: ParteBCsllComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'lalur/parte-b', component: ParteBComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'localidade-grupo-municipio', component: LocalidadeGrupoMunicipioComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'localidade-grupo-municipio/:id', component: LocalidadeGrupoMunicipioComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'localidade-grupo', component: LocalidadeGrupoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'localidade-grupo/:id', component: LocalidadeGrupoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'lst', component: LstComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'lst/:id', component: LstComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'maquina-cartao', component: MaquinaCartaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'mi', component: MiComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'mi/:id', component: MiComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'mov-balancete', component: MovBalanceteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'mov-balancete/:id', component: MovBalanceteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'mov-lcto-contabil', component: MovLctoContabilComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'mov-lcto-contabil/:id', component: MovLctoContabilComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'mov-saldos-contabeis', component: MovBalanceteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'mov-saldos-contabeis/:id', component: MovBalanceteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'mov-termo-ae', component: MovTermoAeComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'mov-termo-ae/:id', component: MovTermoAeComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'movimentacao-interna', component: MovimentacaoInternaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'movimentacao', component: MovimentacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'municipio-ibge', component: MunicipioIbgeComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'municipio-ibge/:id', component: MunicipioIbgeComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'mva', component: MvaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'nfe-fin-edit', component: NfeFinEditComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'notas-emitidas', component: NotasEmitidasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'notas-pendentes', component: NotasPendentesComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'operacao-contabil-externa', component: RegraContabilOrigemComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'operacao-regra-contabil', component: OperacaoRegraContabilComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'operacao-regra-contabil/:id', component: OperacaoRegraContabilComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'operacao-regra-fiscal', component: OperacaoRegraFiscalComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'operacao-regra-fiscal/:id', component: OperacaoRegraFiscalComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'operacao-regra', component: OperacaoRegraComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'operacao-regra/:id', component: OperacaoRegraComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/ata-orcamento', component: AtaOrcamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/cadastro-area', component: CadastroAreaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/classificacao', component: ClassificacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/configuracoes/configuracao-importacao', component: ConfiguracaoImportacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/configuracoes/dashboard', component: ConfigBudgetDashboardComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/configuracoes/periodo', component: PeriodoBudgetComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/configuracoes/taxa-moeda', component: TaxaMoedaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/configuracoes/tipo-orcamento', component: TipoOrcamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/controle-orcamento-fini', component: ControleOrcamentoFiniComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/controle-orcamento', component: ControleOrcamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/dashboard-bi', component: DashboardOrcamentoBIComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/dashboard-orca-new', component: DashboardOrcamentoNewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/dashboard-orca-new', component: DashboardOrcamentoNewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
   { path: 'orcamento/exp-orca', component: ExportOrcamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/gerencial-orcamento', component: GerencialOrcamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/gestao-orcamento', component: GestaoOrcamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
   { path: 'orcamento/importar-arquivos-orcamento', component: OrcamentoImportArquivoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/periodo', component: PeriodoOrcamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
   { path: 'orcamento/relatorios', component: RelatoriosOrcamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
   { path: 'orcamento/relatorios-gest', component: RelatoriosOrcamentoGestComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'orcamento/versao-orcamento', component: OrcamentoVersaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'ordens', component: OrdensComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'pagseguro/boleto', component: BoletoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'pagseguro/gerenciar-boleto', component: GerenciarBoletoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'pagseguro/relatorio-boletos', component: RelatorioBoletosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'pais', component: PaisIbgeComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'palestras/palestra-cliente-status', component: PalestraClienteStatusComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'palestras/palestra-cliente-status', component: PalestraClienteStatusComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'palestras/palestra-cliente', component: PalestraClienteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'palestras/palestra/:tipo', component: PalestraComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'participante-empresa', component: ParticipanteEmpresaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'participante', component: ParticipanteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'participante/:id', component: ParticipanteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'pedido-edit', component: PedidoEditComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'pedido-produto-new/pedido-produto-new/:tipo', component: PedidoProdutoComponentNew, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'pedido-produto/pedido-produto/:tipo', component: PedidoProdutoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'pedido-servico-new/pedido-servico-new/:tipo', component: PedidoServicoComponentNew, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'pedido-servico/pedido-servico/:tipo', component: PedidoServicoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'pedido-view/:id/:idioma', component: PedidoViewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'plano-contas-ref-relac-csll', component: PlanoContasRefRelacCsllComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'plano-contas-ref-relac-csll/:id', component: PlanoContasRefRelacCsllComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'plano-contas-ref-relac-irpj', component: PlanoContasRefRelacIrpjComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'plano-contas-ref-relac-irpj/:id', component: PlanoContasRefRelacIrpjComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'plano-contas-ref-relac-new', component: PlanoContasRefRelacNewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'plano-contas-ref-relac-new/:id', component: PlanoContasRefRelacNewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'plano-contas-ref-relac', component: PlanoContasRefRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'plano-contas-ref-relac/:id', component: PlanoContasRefRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'plano-contas-rfb', component: PlanoContasRfbComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'plano-contas-rfb/:id', component: PlanoContasRfbComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'planocontas-new', component: PlanoContasNewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'planocontas-new/:id', component: PlanoContasNewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'planocontas', component: PlanoContasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'planocontas/:id', component: PlanoContasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'planogerencial', component: PlanoGerencialComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'planogerencial/:id', component: PlanoGerencialComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'planogerencialcontas', component: PlanoGerencialContasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'planogerencialcontas/:id', component: PlanoGerencialContasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'planogerencialnew', component: PlanoGerencialNewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'polozi/evento-proj-relac', component: EventoProjRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'polozi/seminarista', component: SeminaristaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'polozi/tipo-ingresso', component: TipoIngressoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'posicao-cr-cp/:tipo', component: PosicaoCrCpComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'powerbi/acesso', component: AcessoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'powerbi/cadastro', component: CadastroPowerBiComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'powerbi/relatorios', component: RelatoriosPbiComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'projeto-dbl', component: ProjetoDblComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'projeto-dbl/:id', component: ProjetoDblComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'projetodbl-sped', component: DblComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'projetodbl-sped', component: DblComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'projetodbl-sped/dbl-config-importacao', component: DblConfigImportacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'receb-pagto/:tipo', component: RecebPagtoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'receb-pagto/:tipo/:modal/:id', component: RecebPagtoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'regra-relac', component: RegraRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'regra-relac/:id', component: RegraRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'regra', component: RegraComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'reinf/eventos-detalhe/:ids', component: ReinfEventosDetalheComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'reinf/eventos', component: ReinfEventosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'relatorio-detalhe/:id/:dtIni/:dtFin/:detalhePorItem/:fornecedor/:pedido/:status/:comprador/:tipo', component: RelatorioDetalheComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'relatorio-financeiro', component: RelatorioFinanceiroComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'relatorio-pedidos/:tipo', component: RelatorioPedidosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'remessas', component: RemessasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'requisicao', component: RequisicaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'resp-cad-item', component: RespCadItemComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'resp-cad-item/:id', component: RespCadItemComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rh/dashboard', component: DashboardRH, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rh/importar-fp', component: RHImportarPlanilhasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rh/relatorios', component: RHRelatorios, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rh/relatorios', component: RHRelatorios, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rms/aprovador-tecnico', component: AprovadorComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rms/categoria', component: ConfigCategoriaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rms/nda', component: ConfigNdaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rms/objeto', component: ConfigObjetoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rms/origem', component: ConfigOrigemComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rms/parametros', component: ParametrosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rms/prioridade', component: ConfigPrioridadeComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rms/requisicao-material', component: RequisicaoMaterialComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rms/requisicao-material/:id', component: RequisicaoMaterialComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rms/tipo-anexo', component: ConfigTipoAnexoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'rms/tipo', component: ConfigTipoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'segmento-participante', component: SegmentoParticipanteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'segmento-participante', component: SegmentoParticipanteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'signatarios', component: SignatariosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'signatarios/:id', component: SignatariosComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv', pathMatch: 'full', redirectTo: 'siscoserv/import' },
  { path: 'siscoserv/editar', component: SiscoservEditarComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/enquadramento', component: EnquadramentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/enquadramento/:id', component: EnquadramentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/export-xml', component: SiscoservExportXmlComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/import', component: SiscoservImportComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/modo-prestacao', component: ModoPrestacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/modo-prestacao/:id', component: ModoPrestacaoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/moeda', component: MoedaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/moeda/:id', component: MoedaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/nao-nif', component: NaoNifComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/nao-nif/:id', component: NaoNifComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/nbs', component: NbsComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/nbs/:id', component: NbsComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/pais', component: PaisComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/pais/:id', component: PaisComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/status', component: SiscoservStatusComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/tipo-registro', component: TipoRegistroComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/tipo-registro/:id', component: TipoRegistroComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/tipo-vinculo', component: TipoVinculoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'siscoserv/tipo-vinculo/:id', component: TipoVinculoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'teste/teste', component: TesteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'tipi', component: TipiComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'tipi/:id', component: TipiComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'tipo-arquivo', component: TipoArquivoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'tipo-arquivo/:id', component: TipoArquivoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'tipo-documento', component: TipoDocumentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'tipo-documento/:id', component: TipoDocumentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'tipo-ecd', component: TipoEcdComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'tipo-ecd/:id', component: TipoEcdComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'tipo-item', component: ItemTipoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'tipo-item/:id', component: ItemTipoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'tipo-pagamento', component: TipoPagamentoComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'transfer/dashboard1', component: DashboardTransferComponent1, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'transfer/import', component: TransferImportPlanilhaComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'transfer/periodo', component: PeriodoTransferComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'transfer/relatorio-transfer', component: TransferRelatorios, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'transfer/relatorios', component: RelatoriosTPComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'uf', component: UfComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'uf/:id', component: UfComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'unidade-medida', component: UnidadeMedida, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'vendas/dashboard-vendas', component: DashboardVendasComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'view-campos', component: ViewCamposComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'view-campos/:id', component: ViewCamposComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'view-projeto-dbl-relac', component: ViewProjetoDblRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'view-projeto-dbl-relac/:id', component: ViewProjetoDblRelacComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'view', component: ViewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'view/:id', component: ViewComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'vinculacao-operacao-contabil-externa', component: VinculacaoRegraContabilComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'workstats/relatorios-gerenciais', component: RelatoriosGerenciaisComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'workstats/render-relatorios-gerenciais/:empresaid/:relatid/:pgid/:dataref', component: WorkstatsRenderRelatorioGerencial, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'xml', pathMatch: 'full', redirectTo: 'xml/nfe' },
  { path: 'xml/company', component: XmlCompanyComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'xml/company/:id', component: XmlCompanyComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'xml/cte', component: CteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'xml/cte/:id', component: CteComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'xml/nfe', component: NfeComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'xml/nfe/:id', component: NfeComponent, canActivate: [AuthService], canDeactivate: [AuthService] },
  { path: 'xml/settings', pathMatch: 'full', redirectTo: 'xml/company' },

  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: '**', redirectTo: 'dashboard' }
]

export const appRoutedComponents = [

  // NÃO COMENTAR ->
  AuthUserComponent,
  AuthProfileComponent,
  AuthCrowdComponent,
  AuthStatusComponent,
  AuthAppComponent,
  AuthFeatureComponent,
  AuthFeatureUrlComponent,
  AuthGroupComponent,
  AuthLogComponent,
  DashboardComponent,
  LoginComponent,
  UsuarioComponent,

  // LIBERADO COMENTAR ->
  AcessoComponent,
  AgendaComponent,
  AliquotaTributoComponent,
  AnaliseXmlImportPlanilhaComponent,
  AprovadorComponent,
  AprovadorFaturaComponent,
  ArquivosMagneticosComponent,
  AtaOrcamentoComponent,
  AuditorParticipanteComponent,
  AutorizacaoDebitoComponent,
  BeneficioFiscalComponent,
  BoletoComponent,
  CadAglutComponent,
  CadAglutRelacComponent,
  CadastrarPlataformaComponent,
  CadastroAprovacao,
  CadastroAreaComponent,
  CadastroComissaoComponent,
  CadastroGruposEmpresasComponent,
  CadastroInformacaoComponent,
  CadastroMenuComponent,
  CadastroPowerBiComponent,
  CadastroRegraCentralWebComponent,
  CadastroRegraOfxComponent,
  CadBenefEncargoCtaRelac,
  CadBeneficioComponent,
  CadCargoComponent,
  CadCcusComponent,
  CadEcdComponent,
  CadEcfComponent,
  CadEncargosComponent,
  CadFuncComponent,
  CadHistPadraoComponent,
  CadLeiDoBemComponent,
  CadNivelComponent,
  CadParticipanteComponent,
  CadParticipanteRelacComponent,
  CadPeriodoComponent,
  CadProjComponent,
  CadRateioComponent,
  CalendarioComponent,
  CalendarioNewComponent,
  CambioComponent,
  CampanhaComponent,
  CarteiraComponent,
  CentralAprovacaoComponent,
  CertificadoComponent,
  CertificadosDigitaisComponent,
  CfgApiAdmComponent,
  CfgApiClientComponent,
  CfopComponent,
  CheckInComponent,
  CheckListComponent,
  ClassificacaoComponent,
  CodigosObrigacoesComponent,
  CodNaturezaContaComponent,
  CodRelComponent,
  ComissaoBaseComponent,
  ComissaoBaseEmpresaComponent,
  ComissaoTipoComponent,
  ComposicaoFormalizacaoComponent,
  ConfDashboardComponent,
  ConfigBudgetDashboardComponent,
  ConfigCadCCusCadProjComponent,
  ConfigCategoriaComponent,
  ConfigNdaComponent,
  ConfigObjetoComponent,
  ConfigOrigemComponent,
  ConfigPrioridadeComponent,
  ConfigRelatComponent,
  ConfigTipoAnexoComponent,
  ConfigTipoComponent,
  ConfiguracaoImportacaoComponent,
  ConfiguracoesAPIsComponent,
  ConfirmarRecebimentoComponent,
  ConsultaClientesComponent,
  ConsultorComponent,
  ContabilImportComponent,
  ContabilImportConciliacaoComponent,
  ContabilImportPlanilhaComponent,
  ControleGrupoComponent,
  ControleOrcamentoComponent,
  ControleOrcamentoFiniComponent,
  ControleUsuarioComponent,
  CstIcmsComponent,
  CstPiscofinsComponent,
  CteComponent,
  DashboardCentralComponent,
  DashboardComprasComponent,
  DashboardEstoque2Component,
  DashboardEstoqueComponent,
  DashboardFinanceiroComponent,
  DashboardNewComponent3,
  DashboardNewComponent4,
  DashboardOrcamentoBIComponent,
  DashboardOrcamentoNewComponent,
  DashboardOrcamentoNewComponent,
  DashboardRH,
  DashboardTransferComponent1,
  DashboardVendasComponent,
  DblComponent,
  DblConfigImportacaoComponent,
  DblImportComponent,
  DeptoEmpresaComponent,
  DestinatariosComponent,
  DirfComponent,
  DistribGPComponent,
  EcdExportComponent,
  EcdExport2Component,
  EcdLctoEncerramentoComponent,
  EcdReportBalanceteMensalComponent,
  EcdReportBalanceteMensalNewComponent,
  EcdReportBalanceteMensalLctoComponent,
  EcdReportComponent,
  EcdReportNewComponent,
  EcdReportEvolucaoMensalComponent,
  EcdReportGerencial,
  EcdReportPlanoContasComponent,
  EcfExportComponent,
  EditLivroComponent,
  EduzzCargaComponent,
  EmpresaAlmoxarifadoComponent,
  EmpresaCnaeComponent,
  EmpresaComponent,
  EmpresaContaComponent,
  EmpresaContaRefComponent,
  EmpresaInfoComponent,
  EmpresaParametroBaixaComponent,
  EmpresaParametroContabilComponent,
  EmpresaParametroFinanceiroComponent,
  EnquadramentoComponent,
  EnviadosComponent,
  EspacoCliente,
  EstoqueImportPlanilhaComponent,
  EventoPagamentoPagSeguroComponent,
  EventoProjRelacComponent,
  EventosComponent,
  EventosPlanoComponent,
  EventosPlanoComponent,
   ExportOrcamentoComponent,
  ExtratoOfxComponent,
  FaturaComponent,
  FeriadosComponent,
  FichaEstoqueComponent,
  FichaEstoqueDetalheComponent,
  FichasComponent,
  FichasConfEmailComponent,
  FichasEventoComponent,
  FiscalImportComponent,
  FluxoAprovComponent,
  FluxoCaixaComponent,
  FluxoCaixaCtrlComponent,
  FormaEscritContabilComponent,
  FormalizacaoConciliacaoComponent,
  FormaPagamentoComponent,
  FormaPagamentoRefacComponent,
  FormularioComponent,
  FormularioMotivoCancelamentoComponent,
  FormularioMotivoReagendamentoComponent,
  FormularioPerguntasCamposComponent,
  FormularioVisualizacaoRespostasCamposComponent,
  GatilhoObrigacaoComponent,
  GerencialOrcamentoComponent,
  GerenciarBoletoComponent,
  GestaoGrupoOperacaoComponent,
  GestaoGrupoOperacaoItemComponent,
  GestaoOrcamentoComponent,
  GestorCentroCustoComponent,
  GetnetAssinaturaComponent,
  GetnetPagamentoCartaoCreditoComponent,
  GetnetPlanosComponent,
  GrupoComponent,
  GrupoEstoqueComponent,
  GrupoOperacaoComponent,
  GrupoRegraEmpresaRelacComponent,
  GrupoRelacComponent,
  GrupoTributacaoComponent,
  GrupoTributacaoRefComponent,
  ImportacaoF600Component,
  ImportacaoOfxComponent,
  ImportarArquivoComponent,
  ImportarLeadComponent,
  IndFinEscComponent,
  IndGrandePorteComponent,
  IndicesComponent,
  IndNireComponent,
  IndSitEspComponent,
  IndSitIniPerComponent,
  InscritosEventoComponent,
  ItemBomComponent,
  ItemComponent,
  ItemContabilComponent,
  ItemGrupoComponent,
  ItemGrupoTributacaoComponent,
  ItemParticipanteRelacComponent,
  ItemRefComponent,
  ItemTipoComponent,
  JustificativasComponent,
  LancamentoComponent,
  LeadLoversComponent,
  LFDemaisDocsComponent,
  LFIcmsIpiComponent,
  LFRetidosFonteComponent,
  LivrosFiscaisComponent,
  LocalidadeGrupoComponent,
  LocalidadeGrupoMunicipioComponent,
  LogComponent,
  LstComponent,
  MaquinaCartaoComponent,
  MiComponent,
  ModoPrestacaoComponent,
  MoedaComponent,
  MonitoramentoComponent,
  MotivoEstornoComponent,
  MovBalanceteComponent,
  MovimentacaoComponent,
  MovimentacaoInternaComponent,
  MovLctoContabilComponent,
  MovTermoAeComponent,
  MunicipioIbgeComponent,
  MvaComponent,
  NaoNifComponent,
  NbsComponent,
  NfeComponent,
  NfeFinEditComponent,
  NotasEmitidasComponent,
  NotasPendentesComponent,
  ObrigacaoComponent,
  OperacaoRegraComponent,
  OperacaoRegraContabilComponent,
  OperacaoRegraFiscalComponent,
   OrcamentoImportArquivoComponent,
  OrcamentoVersaoComponent,
  OrdensComponent,
  PaisComponent,
  PaisIbgeComponent,
  PalestraClienteComponent,
  PalestraClienteStatusComponent,
  PalestraComponent,
  ParametrosComponent,
  ParametrosVendasComponent,
  ParteAM300CsllComponent,
  ParteAM300IrpjComponent,
  ParteAM300IrpjGerencialComponent,
  ParteAM350CsllGerencialComponent,
  ParteBComponent,
  ParteBCsllComponent,
  ParticipanteComponent,
  ParticipanteEmpresaComponent,
  ParticipantesEventosComponent,
  PedidoEditComponent,
  PedidoProdutoComponent,
  PedidoProdutoComponentNew,
  PedidoServicoComponent,
  PedidoServicoComponentNew,
  PedidosImportComponent,
  PedidoViewComponent,
  PendenciasComponent,
  PeriodoBudgetComponent,
  PeriodoEstoqueComponent,
  PeriodoFinanceiroComponent,
  PeriodoFiscalComponent,
  PeriodoOrcamentoComponent,
  PeriodoTransferComponent,
  PlanoContasComponent,
  PlanoContasNewComponent,
  PlanoContasRefRelacComponent,
  PlanoContasRefRelacCsllComponent,
  PlanoContasRefRelacIrpjComponent,
  PlanoContasRefRelacNewComponent,
  PlanoContasRfbComponent,
  PlanoGerencialComponent,
  PlanoGerencialContasComponent,
  PlanoGerencialNewComponent,
  PosicaoCrCpComponent,
  PosicaoEstoqueComponent,
  ProjetoDblComponent,
  RecebPagtoComponent,
  RegistroExportacaoComponent,
  RegraComponent,
  RegraContabilOrigemComponent,
  RegraRelacComponent,
  ReinfEventosComponent,
  ReinfEventosDetalheComponent,
  RelacionarCampanhaCarteiraComponent,
  RelacionarCarteiraClientesComponent,
  RelatorioAgendaComponent,
  RelatorioAgendaEditComponent,
  RelatorioBoletosComponent,
  RelatorioComponent,
  RelatorioDetalheComponent,
  RelatorioEstoqueComponent,
  RelatorioFinanceiroComponent,
  RelatorioFluxoCaixaComponent,
  RelatorioPedidosComponent,
  RelatoriosComponent,
  RelatoriosGerenciaisComponent,
   RelatoriosOrcamentoComponent,
   RelatoriosOrcamentoGestComponent,
  RelatoriosPbiComponent,
  RelatoriosTPComponent,
  RemessasComponent,
  ReportXmlComponent,
  RequisicaoComponent,
  RequisicaoMaterialComponent,
  RespCadItemComponent,
  RHImportarPlanilhasComponent,
  RHRelatorios,
  SegmentoParticipanteComponent,
  SeminaristaComponent,
  SignatariosComponent,
  SiscoservEditarComponent,
  SiscoservExportXmlComponent,
  SiscoservImportComponent,
  SiscoservStatusComponent,
  StaffComponent,
  TabelaCrmCargaComponent,
  TabelaCrmComponent,
  TabelaCrmDetalheComponent,
  TaxaMoedaComponent,
  TesteComponent,
  TipiComponent,
  TipoArquivoComponent,
  TipoDocumentoComponent,
  TipoEcdComponent,
  TipoIngressoComponent,
  TipoOrcamentoComponent,
  TipoPagamentoComponent,
  TipoRegistroComponent,
  TipoVinculoComponent,
  TransferImportPlanilhaComponent,
  TransferirParticipanteEventoComponent,
  TransferRelatorios,
  TributosPagamentoRelacComponent,
  TxtImpConcComponent,
  UfComponent,
  UploadImplantacaoComponent,
  UnidadeMedida,
  UsGaapComponent,
  VendedorComponent,
  ViewCamposComponent,
  ViewComponent,
  ViewProjetoDblRelacComponent,
  VinculacaoRegraContabilComponent,
  WorkFlowComponent,
  WorkFlowComponent,
  WorkFlowProjetoComponent,
  WorkstatsRenderRelatorioGerencial,
  XmlCompanyComponent,
  XmlDetlaheItemComponent
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}

// INATIVAS

// import { ContaBancariaComponent } from './cadastro/conta-bancaria/conta-bancaria.component'
// import { EcdReportBalancoPatrimonialComponent } from './contabil/report-balanco-patrimonial/report-balanco-patrimonial.component'
// import { IndexComponent } from './dbl/index/index.component'
// import { ReportTitAbertoComponent } from './contabil/report-tit-aberto/report-tit-aberto.component'
// import { ReportTitAbertoCpComponent } from './contabil/report-tit-aberto-cp/report-tit-aberto-cp.component'
// import { SiscoservExportComponent } from './siscoserv/export/export.component'
// import { SiscoservHistoryComponent } from './siscoserv/history/history.component'
// import { WindowSidebarComponent } from './shared/components/sidebar/window-sidebar/window-sidebar.component'

// { path: 'centralweb/work-flow/sidebar-expandida', component: WindowSidebarComponent, canActivate: [AuthService], canDeactivate: [AuthService] },

// WindowSidebarComponent,
// XmlNaoEncontradoComponent,
