export class CstIpi {
  id: number;
  cst: string;
  descricao: string;

  constructor(cstipi?: CstIpi | number) {
    if (cstipi !== undefined && cstipi !== null) {
      if (typeof cstipi === 'number') {
        this.id = cstipi;
      } else {
        this.id = cstipi.id;
        this.cst = cstipi.cst;
        this.descricao = cstipi.descricao;
      }
    }
  }
}
