import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TimezonePipe } from '../../shared/pipes/timezone.pipe';
import { TitleService } from '../../shared/services/title.service';
import { ConfigService } from '../../shared/services/config.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { HttpService } from '../../shared/services/http.service';
import { UtilService } from '../../shared/services/util.service';
import { SessionService } from '../../shared/services/session.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-report-xml',
  templateUrl: './report-xml.component.html',
  styleUrls: ['./report-xml.component.scss'],
})
export class ReportXmlComponent implements OnInit, OnDestroy {

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
    this.timezonePipe = new TimezonePipe();
  }
  title: string;
  subtitle: string;
  private sessionSubscription: Subscription;
  private timezonePipe: TimezonePipe;
  dtIni: Date;
  dtFin: Date;
  defaultDate: Date;
  modalIcmsIpi: boolean;
  modalXmlCte: boolean;
  modalRetidos: boolean;
  modalDemaisDocs: boolean;
  detalhePorItem: boolean;
  entradaSaida: any;
  servico: any;
  tipos: any[];
  tiposRetido: any[];
  tipoArquivo: any[];

  filtroTipo: any;
  currentStateProcessados = 'initial';

  DefInitDate: Date;
  DefFinalDate: Date;
  dtInicial: Date;
  dtFinal: Date;

  dataIni: Date;
  dataFin: Date;

  dtInicialStr: String;
  dtFinalStr: String;

  /*Open Close DropDown Date*/
  dropdate: boolean = false;
  DropdownDateEvent() {
    this.dropdate = !this.dropdate;
  }

  msgs: Message[] = [];
  xmlPendente: Array<any> = new Array();

  dataIniSearch: any;
  dataFinSearch: any;
  idNfesBaixa: Array<any> = new Array();

  colsXmlPendente: any[] = [];

  ngOnInit(): void {

    this.title = 'Relatórios XML';
    this.subtitle = 'Informações Referentes a XML';
    this.titleService.title = 'Relatórios XML';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.change(); });

      this.colsXmlPendente = [
        { field: 'emissao', header: 'DATA' },
        { field: 'emitente', header: 'NOME' },
        { field: 'cnpj', header: 'CNPJ' },
        { field: 'numNf', header: 'NÚMERO DOC' },
        { field: 'model', header: 'MODELO' },
        { field: 'valor', header: 'VALOR' },
        { field: 'chave', header: 'CHAVE' },
        { field: 'finnfe', header: 'FINALIDADE' },
        { field: 'status', header: 'STATUS' }

      ];



  }

  ngOnDestroy() {
    // this.toolbarService.clear();
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id && this.dblinkedSessionService.hasPeriodoSelected) {

      this.dataIniSearch = null;
      this.dataFinSearch = null;

      this.defaultDate = new Date();
      this.tipos = [];
      this.tipos.push({ label: 'Selecione', value: '' });
      this.tipos.push({ label: 'Entrada', value: 0 });
      this.tipos.push({ label: 'Saída', value: 1 });
      this.tiposRetido = [];
      this.tiposRetido.push({ label: 'Selecione', value: '' });
      this.tiposRetido.push({ label: 'Serviços Prestados', value: 0 });
      this.tiposRetido.push({ label: 'Serviços Tomados', value: 1 });

      this.tipoArquivo = [
        { label: 'XML', value: 'XML' },
        { label: 'CTE', value: 'CTE' },
      ];

      this.dataIni = null;
      this.dataFin = null;

      let lastDay = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0); // ultimo dia do mês

      let mes = this.dblinkedSessionService.periodo.month.value > 8 ?
        this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;

      this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
      this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();




      this.dtIni = this.defaultDate;
      this.dtFin = this.defaultDate;
      this.modalIcmsIpi = false;
      this.modalRetidos = false;
      this.modalDemaisDocs = false;
      this.detalhePorItem = false;
      this.entradaSaida = '999';
      this.servico = '999';
    }
  }

  exibirModal(tipo) {
    if (tipo === 'ICMS_IPI') {
      this.modalIcmsIpi = true;
    } else if (tipo === 'XML_CTE') {
      this.modalXmlCte = true;

      this.loadDados(false);

    }
  }

  loadXml(value: any) {
    this.xmlPendente = new Array();
    value.forEach(element => {
      this.xmlPendente.push(element);

    });

  }

  buscarXmlNaoEncontrados() {
    if (confirm('Para esta consulta o SPED deste período deve estar carregado no dblinked, deseja continuar?')) {

      this.windowOpen('/fiscal/xml-nao-encontrado/' + this.dblinkedSessionService.empresa.id + '/' +
        this.dblinkedSessionService.periodo.year.value + '/' +
        this.dblinkedSessionService.periodo.month.value, '_blank');

    }
  }


  reportPc(data: any) {

    if (data === 'item') {
      this.windowOpen('/fiscal/xml-detalhe-item/' + this.dblinkedSessionService.empresa.id + '/' +
        this.entradaSaida + '/' + this.dblinkedSessionService.periodo.year.value + '/' +
        this.dblinkedSessionService.periodo.month.value, '_blank');
    }
    this.change();
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  windowOpen(url: string, target?: string) {
    window.open(url, target)
  }

  /*Atualizar Período*/
  atualizarTudo() {
    this.dropdate = false;



    // Verificar se as duas datas estão preenchidas
    if (this.dtInicial == null || this.dtInicial == undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: 'Selecione uma data inicial para filtro.' });

    } else if (this.dtFinal == null || this.dtFinal == undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: 'Selecione uma data final para filtro.' });

      // Verificar se a data final é posterior à data inicial.
    } else {
      if (this.dtInicial > this.dtFinal) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: 'Data inicial maior que a final. Verifique!' });

      } else {
        let month = String(this.dtInicial.getMonth() + 1);
        let day = String(this.dtInicial.getDate());
        let year = String(this.dtInicial.getFullYear());

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        this.dtInicialStr = year + '-' + month + '-' + day;

        month = String(this.dtFinal.getMonth() + 1);
        day = String(this.dtFinal.getDate());
        year = String(this.dtFinal.getFullYear());

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        this.dtFinalStr = year + '-' + month + '-' + day;

        this.loadDados(true);


      }
    }

  }

  onFilter(event: any) {

    this.idNfesBaixa = new Array();

    let contador = 0;
    event.filteredValue.forEach(element => {
      this.idNfesBaixa.push(element.chave);
      element.selected = true;
      contador = contador + 1;
    });

    if (contador === 0) {
      this.idNfesBaixa = new Array();
    }

  }

  selectCheckbox(id: any, event: any) {

    const index = this.idNfesBaixa.findIndex(c => c === id);

    if (!event.selected) {
      this.idNfesBaixa.splice(index, 1);
    } else {
      this.idNfesBaixa.push(id);
    }


  }

  loadDados(boolean: Boolean) {

    const listasEnviar = {
      empresaId: this.dblinkedSessionService.empresa.id,
      dtIni: boolean ? this.dtInicial : this.dataIniSearch,
      dtFim: boolean ? this.dtFinal : this.dataFinSearch,
      filtroTipo: this.filtroTipo,
      filtro: boolean
    };

    let mesIni = this.dtInicial.getMonth() + 1;
    let mesFin = this.dtFinal.getMonth() + 1;

    this.dataIniSearch = this.dtInicial.getFullYear() + '-' + mesIni + '-' + ((this.dtInicial.getDate() > 9) ?
      this.dtInicial.getDate() : '0' + this.dtInicial.getDate());
    this.dataFinSearch = this.dtFinal.getFullYear() + '-' + mesFin + '-' + ((this.dtFinal.getDate() > 9) ?
      this.dtFinal.getDate() : '0' + this.dtFinal.getDate());


    this.httpService.wait();
    this.httpService.post('/custom/dbl/buscar-xml-cte-monitor',
      listasEnviar)
      .subscribe(ref => this.loadXml(ref),
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());

  }

  impressaoDanfe() {
    if (confirm('Deseja realmente exportar as notas selecionadas?')) {
      this.idNfesBaixa.forEach(nfeId => {
        this.httpService.wait();
        this.httpService.get('/custom/nfe/gerar-danfe-monitor(' + nfeId + ')')
          .subscribe(result => this.exportedDanfe(result),
            error => this.httpService.handleError(error, () => this.impressaoDanfe()),
            () => this.httpService.done());
      });
    }
  }

  impressaoXml() {
    if (confirm('Deseja realmente exportar as notas selecionadas?')) {
      this.idNfesBaixa.forEach(nfeId => {
        this.httpService.wait();
        this.httpService.get('/custom/nfe/gerar-danfe-monitor-xml(' + nfeId + ')')
          .subscribe(result => this.exportedDanfe(result),
            error => this.httpService.handleError(error, () => this.impressaoDanfe()),
            () => this.httpService.done());
      });
    }
  }


  exportedDanfe(result?: any) {

    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/danfe'), '_blank');
      this.idNfesBaixa = new Array();

      this.xmlPendente.forEach(element => {
        element.selected = false;

      });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result.message });
      this.idNfesBaixa = new Array();

      this.xmlPendente.forEach(element => {
        element.selected = false;

      });
    }
  }


  /*Início Métodos Controle de Tabela*/
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
  /*Final - Métodos Controle de Tabela*/

  onTableRowSelect(event) {
    console.log(event);
    this.selectCheckbox(event.data.chave, { ...event.data, selected: true })
  }
  onTableRowUnselect(event) {
    this.selectCheckbox(event.data.chave, { ...event.data, selected: false })
  }
  onTableselectAll(event) {
    this.selectTodos(event.checked)
  }

  selectTodos(event: any) {
    console.log(event);

  }


}
