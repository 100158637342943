import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Message, MessageService } from 'primeng/api';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-relacionar-carteira-clientes',
  templateUrl: './relacionar-carteira-clientes.component.html',
  styleUrls: ['./relacionar-carteira-clientes.component.scss'],
})
export class RelacionarCarteiraClientesComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  msgs: Message[] = [];

  titlePrincipal: string;
  subtitle: string;

  listaCarteiras: Array<any> = new Array();
  listaAprovadores: Array<any> = new Array();

  carteiras             : SelectItem[];
  carteiraNewConsultor  : SelectItem[];
  public carteiraId     : number = 0;
  public carteiraIdNew  : number = 0;


  public listaCarteiraRelacionadas       : Array<any> = new Array();
  public listaCarteiraRelacionadasOld    : Array<any> = new Array();
  listaCarteiraSemRelacionamento  : Array<any> = new Array();
  listaCarteiraNewConsultor       : Array<any> = new Array();
  listaExport                     : Array<any|any|any|any> = new Array();
  modalPeriodo                    : boolean = false;
  modalTransferirCarteira         : boolean = false;
  public dataIni                  : any = null;
  public dataFin                  : any = null;
  public dados                    : any = null;



  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private dblinkedSessionService: DblinkedSessionService,
    private datePipe: DatePipe,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    // requisição

    this.titlePrincipal = 'CONFIGURAÇÕES';
    this.subtitle = 'RELACIONAR CARTEIRA POR CLIENTES';
    this.titleService.title = 'Carteira por clientes';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.carteiraId   = 0;
    this.modalPeriodo = false;
    this.dataIni      = null;
    this.dataFin      = null;
    this.listaExport  = new Array();



    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });
    }

  ngOnDestroy() {
    // this.toolbarService.clear();
    // this.toolbarService.hidden = false;
    // this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  data() {
    // this.dtCorte = new Date(this.timezonePipe.transform(this.dtCorte));
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

        this.listaCarteiras                 = new Array();
        this.listaAprovadores               = new Array();
        this.listaCarteiraRelacionadas      = new Array();
        this.listaCarteiraSemRelacionamento = new Array();
        this.listaCarteiraNewConsultor      = new Array();

        this.httpService.wait();
        this.httpService.get('custom/polozi/get-carteira')
          .subscribe(retorno => {
            this.carteiras = [];
            this.carteiraNewConsultor = [];
            this.carteiras.push({ label: 'Selecione', value: 0 });
            this.carteiraNewConsultor.push({ label: 'Selecione', value: 0 });

            retorno.forEach(element => {
              this.carteiras.push({ label: element.carteiraNome + ' - ' + element.consultorNome, value: element.id });
              this.carteiraNewConsultor.push({ label: element.carteiraNome + ' - ' + element.consultorNome, value: element.id });
            });

          }, error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());



      // renderizando a toolbar
      setTimeout(() => {
        this.toolbarEdit();
      }, 500);
    }
  }

  onFilterRms(event: any) {
    console.log('event: ' + event);
  }

  export() {
    // alert('export');
  }

  pesquisa() {
    // alert('pesquisa');
  }


 hideMessage() {

  }

  private toolbarEdit() {
    const todos = this.buttons.slice();
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('save');
    // this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('export');
    this.toolbarService.add('undo', true, 'class-edit-button', 'sync_alt', 'Transferir evento');

    // this.toolbarService.alterarStatus('pesquisa', true, 'Detalhes Rateio', () => this.pesquisa());
    // this.toolbarService.alterarStatus('download', true, 'Download Anexos', () => this.export());
    // this.toolbarService.alterarStatus('voltar', true, 'Voltar', () => this.ngOnInit());
    // this.toolbarService.alterarStatus('atualizar', true, 'Atualizar RMS', () => this.change());
    // this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.ngOnInit());
    // this.toolbarService.alterarStatus('save', true, 'Salvar requisição', () => this.ngOnInit());
    //this.toolbarService.alterarStatus('edit', true, 'Editar requisição', () => this.ngOnInit());


    this.toolbarService.replace('edit', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.alterarPeriodo());
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Aprovadores', () => this.salvarRelacionamento());

    //this.toolbarService.alterarStatus('undo', false, 'Transferir evento ficha', () => this.transferirEvento());

  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  salvarRelacionamento(){

    if (this.validacao()) {
      return;
    }



    if (confirm('Deseja realmente salvar este relacionamento?')) {
      const listaEnviar = {
        carteiraId                      : this.carteiraId,
        listaCarteiraRelacionadas       : this.listaCarteiraRelacionadas,
        listaCarteiraSemRelacionamento  : this.listaCarteiraSemRelacionamento,
        empresaId                       : this.empresa.id

      };


      this.httpService.wait();
      this.httpService.post('/custom/polozi/salvar-relacionamento-cliente', listaEnviar)
        .subscribe(result => {
          let existeErro = false;
          result.forEach(element => {
            if(element.error){
              existeErro = true;
              this.messageService.add({
                severity: element.severity,
                summary: 'MSG: ',
                detail: element.message
              });
            }
          });
          if(!existeErro){
            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Alteração salva com sucesso!'
            });
          }

          this.change();
          this.carteiraId = 0;

        },
          error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
          () => this.httpService.done());
    }

    // this.carteiraId = 0;

    // this.change();
  }

  validacao(){


    if (isNullOrUndefined( this.carteiraId) || this.carteiraId === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um usuário'
      });
      return true;
    }


    if (isNullOrUndefined(this.empresa.id) || this.empresa.id === 0 ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma empresa'
      });
      return true;
    }


    return false;

  }

  tabChange(event: any){
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Relacionamento', () => this.salvarRelacionamento());
  }

  buscarCarteiras(){
    this.listaCarteiraRelacionadas        = new Array();
    this.listaCarteiraSemRelacionamento   = new Array();


    if(this.carteiraId > 0){
      this.toolbarService.alterarStatus('undo', false, 'Transferir Carteira', () => this.showModalTransferirCarteira());

      this.httpService.wait();
      this.httpService.get('custom/polozi/get-lista-carteira-clientes',
        [this.carteiraId,   this.dataIni, this.dataFin])
        .subscribe(retorno => {
          this.listaCarteiraRelacionadas        = new Array();
          this.listaCarteiraRelacionadasOld     = new Array();
          this.listaCarteiraSemRelacionamento   = new Array();
          retorno.forEach(element => {
            if (element.relacionado) {
              this.listaCarteiraRelacionadas.push(element);
              this.listaCarteiraRelacionadasOld.push(element);
            } else {
              this.listaCarteiraSemRelacionamento.push(element);
            }

          });

        }, error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done() );

    } else{
      this.toolbarService.alterarStatus('undo', true, 'Transferir Carteira', () => this.showModalTransferirCarteira());
    }
  }

  alterarPeriodo(){
    this.modalPeriodo = true;

  }

  changeDataIni(event : any){

    this.dataFin = event;

  }

  exportarCSV(){

    this.listaExport = new Array();
    let  carteiraNome : any;

    carteiraNome =  this.carteiras.find( e => e.value === this.carteiraId).label;
    this.listaExport.push({ 'nome': 'CLIENTES RELACIONADOS', 'email': '', 'celular': '', 'criado em': '', 'palestra origem': '' } );

    this.listaCarteiraRelacionadas.forEach(i=>{
      this.listaExport.push({ 'nome': i.nomeCliente, 'email': i.emailCliente, 'celular': i.telefoneCliente, 'criado em': i.criadoEm, 'palestra origem': i.palestraOrigem } );
    });

    this.downloadFile(this.listaExport,'download');
  }


///
  downloadFile(data, filename='data') {

    let csvData = this.ConvertToCSV(data, [ 'nome','email','celular', 'criado em', 'palestra origem' ]);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
///


///
    ConvertToCSV(objArray, headerList) {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = '';
      for (let index in headerList) {
      row += headerList[index] + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
      let line = '';
      let contador = 0;
      for (let index in headerList) {
        let head = headerList[index];
        if(contador > 0){
          line += ',' + array[i][head];
        } else {
          line += array[i][head];
        }

        contador++;
      }
      str += line + '\r\n';
      }
      return str;
    }
///

transformDate(date) {
  return this.datePipe.transform(date, 'yyyy/MM/dd');
}

showModalTransferirCarteira(){
  this.modalTransferirCarteira        = true;
  this.carteiraIdNew = 0;
  Promise.resolve(e=>{
    this.listaCarteiraRelacionadasOld   = new Array();
    this.listaCarteiraRelacionadas.forEach(element =>{
      this.listaCarteiraRelacionadasOld.push(element);
    });
  }).then(e=>{this.buscarNovoCarteira()});




  console.log(this.listaCarteiraRelacionadasOld);




}

salvarTransferirCarteira(){

  if (confirm('Deseja realmente salvar este relacionamento?')) {
    const listaEnviar = {
      carteiraId                      : this.carteiraId,
      carteiraIdNew                   : this.carteiraIdNew,
      listaCarteiraRelacionadasOld    : this.listaCarteiraRelacionadasOld,
      listaCarteiraNewConsultor       : this.listaCarteiraNewConsultor,
      empresaId                       : this.empresa.id
    };


    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-transferencia-cliente', listaEnviar)
      .subscribe(result => {
        let existeErro = false;
        result.forEach(element => {
          if(element.error){
            existeErro = true;
            this.messageService.add({
              severity: element.severity,
              summary: 'MSG: ',
              detail: element.message
            });
          }
        });
        if(!existeErro){
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Alteração salva com sucesso!'
          });
        }

        this.change();
        this.carteiraId = 0;

      },
        error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
        () => this.httpService.done());
  }


}

buscarNovoCarteira(){
  this.listaCarteiraNewConsultor      = new Array();
  this.httpService.wait();
  this.httpService.get('custom/polozi/get-lista-carteira-clientes-new', [this.carteiraIdNew,   this.dataIni, this.dataFin])
    .subscribe(retorno => {
      this.listaCarteiraNewConsultor   = new Array();
      retorno.forEach(element => {
        this.listaCarteiraNewConsultor.push(element);
      })


    }, error => this.httpService.handleError(error, () => this.change()),
      () => this.httpService.done() );


}
  buscarNovoConsultor(carteiraId : number){
    console.log(carteiraId);
    this.carteiraNewConsultor = [];
    this.httpService.wait();
    this.httpService.get('custom/polozi/get-carteira-new', [carteiraId])
      .subscribe(retorno => {
        this.carteiraNewConsultor = [];
        this.carteiraNewConsultor.push({ label: 'Selecione', value: 0 });
        retorno.forEach(element => {
          this.carteiraNewConsultor.push({ label: element.carteiraNome + ' - ' + element.consultorNome, value: element.id });
        });

      }, error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());

  }

}
