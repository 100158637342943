import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { HttpService } from 'app/shared/services/http.service';
import { SelectItem, Message, MessageService } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { Empresa } from '../../cadastro/model/empresa.model';
import { FormaPagamento } from '../../util/model/forma-pagamento.model';
import { FormaPagamentoDetalhe } from '../../util/model/forma-pagamento-detalhe.model';
import { UtilService } from 'app/shared/services/util.service';
import { TipoBaixa } from 'app/util/model/tipo-baixa.model';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { ConfiguracoesApis } from 'app/cadastro/model/configuracoes-apis.model';
import { isNullOrUndefined } from 'util';
import { AuthUser } from 'app/proof/model/auth-user.model'
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import idv4 from 'app/util/processing/idv4';

@Component({
  selector: 'app-forma-pagamento',
  templateUrl: './forma-pagamento-refac.component.html',
  styleUrls: ['./forma-pagamento-refac.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class FormaPagamentoRefacComponent extends DataTableBasicComponent implements OnInit, OnDestroy {

  currentStateFormPag = 'initial';
  changeStateFormPag() {
    this.currentStateFormPag = this.currentStateFormPag === 'initial' ? 'final' : 'initial';
  }

  private empresaSubscription: Subscription;

  formaPagamento: FormaPagamento;
  formaPagamentoDetalhe: FormaPagamentoDetalhe;
  detalheList: Array<FormaPagamentoDetalhe>;

  entityFilter: string = null;
  title: String;
  subtitle: String;
  msgs: Message[];
  criar: boolean;
  editar: boolean;
  detalharPagamento: boolean;
  usaSplitFormaPag: boolean = false
  modalSplitPagamento: boolean = false
  clonagemUnica: boolean = false

  formaPagamentoList: Array<FormaPagamento>;

  porcentagemTotal: number;
  ultimoDia: number;

  tiposBaixas: SelectItem[];
  tiposPagamentos: SelectItem[] = []
  contasBancarias: SelectItem[];
  opcoesgateways: SelectItem[];
  opcoesOperacaoRegra: SelectItem[] = [];
  opcoesAdm: SelectItem[] = [];
  opcoesBandeira: SelectItem[] = [];
  opcoesDataReferencia: SelectItem[] = [
    { label: "Emissão", value: "dt_emissao" },
    { label: "Entrega", value: "dt_entrega" },
    { label: "Entrada/Saída", value: "dt_faturamento_automatico" },
    { label: "Dia Fixo", value: "dia_fixo" }
  ]
  opcoesTipoCobranca: SelectItem[] = [
    { label: "R$", value: 1 },
    { label: "%", value: 2 }
  ]

  opcoesAtivo: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
  opcoesDaysOfWeek: SelectItem[] = [{ label: "Domingo", value: 0 }, { label: "Segunda-Feira", value: 1 }, { label: "Terça-Feira", value: 2 },
  { label: "Quarta-Feira", value: 3 }, { label: "Quinta-Feira", value: 4 }, { label: "Sexta-Feira", value: 5 }, { label: "Sábado", value: 6 }];

  statusTela: string = 'listagem';
  regraNova: boolean = true;

  httpOptions: Object = new Object()
  splitPagamento: Array<any> = new Array()

  empresaRealizaAlteracoes: boolean = false
  cadastroGrupoEmpresa: boolean = false
  empresasGrupo: SelectItem[] = new Array()
  empresasGrupoSelecionadas: Array<any> = new Array()
  clonagemGrupoEmpresas: boolean = false
  modalGrupoEmpresas: boolean = false
  tituloModalGrupoEmpresas: string = ''
  descricaoModalGrupoEmpresas: string = ''
  codigoNovaRegraClonagemGrupoEmpresa: string = ''
  descricaoNovaRegraClonagemGrupoEmpresa: string = ''

  formasPagamentosSelecionadas: Array<FormaPagamento> = new Array()

  public toolbarFormPag = new Toolbar();

  toolbarMainFormPag = [
    {
      key: 'btn-clone-expanded', icon: 'content_copy', text: '', tooltip: 'Clonar', visible: false, disabled: false, color: 'default', dropdown: true,
      options: [
        { label: 'Clonar no grupo de empresas', callback: () => this.setarVariaveisClonagem(false) },
        { label: 'Clonar somente na empresa atual', callback: () => this.setarVariaveisClonagem(true) },
      ]
    },
    { key: 'btn-back', icon: 'arrow_back_ios_new', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.empresaChanged() },
    { key: 'btn-delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.cadastroGrupoEmpresa && this.empresaRealizaAlteracoes ? this.excluirFormaPagamentoGrupoEmpresa() : this.excluirFormaPagamento() },
    { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.showTela('edit') },
    { key: 'btn-new', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.showTela('add') },
    { key: 'btn-save', icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: false, disabled: false, color: 'green', onClick: () => this.determinarMetodoParaSalvar() },
    { key: 'btn-clone', icon: 'save', text: 'Clonar', tooltip: 'Clonar', visible: false, disabled: false, color: 'green', onClick: () => this.setarVariaveisClonagem(true) },
  ]

  constructor(
    private configService: ConfigService,
    private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService,
    private httpClient: HttpClient
  ) {
    super(crudService);
  }

  ngOnInit() {
    this.toolbarFormPag.setAll(this.toolbarMainFormPag)
    this.title = 'FORMA DE PAGAMENTO';
    this.subtitle = 'CADASTRE, EDITE OU EXCLUA FORMAS DE PAGAMENTO';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.formasPagamentosSelecionadas = new Array()
    this.formaPagamentoList = new Array();
    this.formaPagamento = new FormaPagamento();
    this.formaPagamentoDetalhe = new FormaPagamentoDetalhe();
    this.detalheList = new Array();
    this.criar = false;
    this.editar = false;
    this.detalharPagamento = false;
    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }


    this.empresaSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.empresaChanged,
      () => {
        this.empresaChanged();
      }
    );
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  empresaChanged() {
    if (!this.dblinkedSessionService.hasEmpresaSelected || !this.dblinkedSessionService.hasPeriodoSelected ||
        !this.dblinkedSessionService.periodo.month || !this.dblinkedSessionService.periodo.year) return

    this.statusTela = "listagem";
    this.regraNova = true;

    this.carregarDadosGrupoEmpresa()
    this.comboTiposBaixa();
    this.comboEmpresaConta();
    this.getComboGateway();
    this.getOperacaoRegraContabil();
    this.getAdministradoras();
    this.getBandeiras();
    this.getFormaPgto();
    this.comboTiposPagamentos()
    this.getParamCobranca()

    this.formasPagamentosSelecionadas = new Array()
    this.formaPagamento = new FormaPagamento();
    this.formaPagamento.empresa = new Empresa(this.dblinkedSessionService.empresa.id);
    this.formaPagamentoDetalhe = new FormaPagamentoDetalhe();
    this.formaPagamentoList = new Array();
    this.detalheList = new Array();
    this.criar = false;
    this.editar = false;
    this.detalharPagamento = false;

    const detalhe = new FormaPagamentoDetalhe();
    detalhe.formaPagamento = new FormaPagamento(this.formaPagamento.id);
    detalhe.dias = 0;
    detalhe.porcentagem = 0;
    detalhe.parcela = 1;
    this.detalheList.push(detalhe);
    this.ultimoDia = 0;

    this.modalGrupoEmpresas = false

    this.changeToolbar()
  }

  empresaChangedFalse() {
    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected &&
      this.dblinkedSessionService.periodo.month && this.dblinkedSessionService.periodo.year) {
      this.statusTela = "listagem";
      this.regraNova = false;

      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.comboTiposBaixa();
        this.comboEmpresaConta();
        this.getComboGateway();
        this.getOperacaoRegraContabil();
        this.getAdministradoras();
        this.getBandeiras();
        this.getFormaPgto();

        this.formaPagamento = new FormaPagamento();
        this.formaPagamento.empresa = new Empresa(this.dblinkedSessionService.empresa.id);
        this.formaPagamentoDetalhe = new FormaPagamentoDetalhe();
        this.formaPagamentoList = new Array();
        this.detalheList = new Array();
        this.criar = false;
        this.editar = false;
        this.detalharPagamento = false;

        const detalhe = new FormaPagamentoDetalhe();
        detalhe.formaPagamento = new FormaPagamento(this.formaPagamento.id);
        detalhe.dias = 0;
        detalhe.porcentagem = 0;
        detalhe.parcela = 1;
        this.detalheList.push(detalhe);
        this.ultimoDia = 0;

        this.changeToolbar()
      }
    }
  }

  exibirMensagem(value: any) {
    const detail = (value.mensagem || value.message || value.response || value.msg);
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail });
    }
  }

  getFormaPgto() {
    this.httpService.wait();
    this.httpService.get('/custom/financeiro/list-forma-pgto(' + this.dblinkedSessionService.empresa.id + ',' + this.regraNova + ')')
      .subscribe(
        res => {
          this.formaPagamento = new FormaPagamento();
          this.formaPagamentoDetalhe = new FormaPagamentoDetalhe();
          this.detalheList = new Array();
          this.formaPagamentoList = new Array();

          for (let line of res) {
            let formaPagamento: FormaPagamento = line;
            formaPagamento.tipoBaixa = new TipoBaixa();
            formaPagamento.tipoBaixa.id = line.tipoBaixaId;
            formaPagamento.empresaConta = new EmpresaConta();
            formaPagamento.empresaConta.id = line.empresaContaId;
            formaPagamento.configuracoesApis = new ConfiguracoesApis();
            formaPagamento.configuracoesApis.id = line.configuracoesApisId;
            formaPagamento.changeUser = new AuthUser();
            formaPagamento.changeUser.id = line.changeUserId;
            formaPagamento.dataReferencia = line.dataReferencia
            formaPagamento.isPagamento = line.isPagamento
            formaPagamento.isRecebimento = line.isRecebimento
            formaPagamento.parcelasExatas = line.parcelasExatas
            formaPagamento.permiteAlterarBanco = line.permiteAlterarBanco
            formaPagamento.programacaoSemanal = line.programacaoSemanal
            formaPagamento.paydayDefault = line.paydayDefault
            // formaPagamento.paymentWeek = line.paymentWeek
            formaPagamento.dayOfWeek = line.dayOfWeek
            formaPagamento.minDaysInterval = line.minDaysInterval
            formaPagamento.grupoEmpresaFormaPagamentoOrigemId = line.grupoEmpresaFormaPagamentoOrigemId

            this.formaPagamentoList.push(formaPagamento);

            this.formaPagamentoList.map((item) => {
              if (item.ativo) {
                item.filtro = "SIM"
              } else {
                item.filtro = "NÃO"
              }
            })
          }
        },
        err => this.httpService.handleError(err, () => this.getFormaPgto()),
        () => this.httpService.done());
  }

  hideMessage() {
    this.msgs = null;
  }


  showTela(tela) {
    this.criar = tela === "add";
    this.editar = tela === "edit";

    switch (tela) {
      case "edit": {
        this.statusTela = 'form';
        this.editarFormaPagamento()
        this.changeToolbar()
        this.maxParcelasChanged()
        break
      }

      case "add": {
        this.statusTela = 'form'
        this.criarFormaPagamento()
        this.changeToolbar()
        break
      }

      case "listagem": {
        if (!confirm('As alterações não salvas serão perdidas, deseja continuar?')) return

        this.statusTela = "listagem"
        this.onRowUnselect()
        break
      }
    }
  }

  fixedToolbar() {
    this.comboTiposBaixa();
    this.comboEmpresaConta();
    this.getComboGateway();
    this.getOperacaoRegraContabil();
    this.getAdministradoras();
    this.getBandeiras();
    this.getFormaPgto();
    this.changeToolbar()
  }

  criarFormaPagamento() {
    this.criar = true;
    this.formaPagamento = new FormaPagamento();
    this.formaPagamentoDetalhe = new FormaPagamentoDetalhe();
    this.detalheList = new Array();
    this.formaPagamento.tipoBaixa = new TipoBaixa();
    this.formaPagamento.tipoBaixaId = 0;
    this.formaPagamento.empresaConta = new EmpresaConta();
    this.formaPagamento.empresaContaId = 0;
    this.formaPagamento.ativo = true;
    this.formaPagamento.configuracoesApis = new ConfiguracoesApis();
    this.formaPagamento.configuracoesApisId = 0;
    this.formaPagamento.boolDataEmissaoPedido = false;
    this.formaPagamento.dataReferencia = 'dt_emissao';
    this.formaPagamento.parcelasExatas = false
  }

  excluirFormaPagamento() {
    const formaPagamentoId = this.formaPagamento.id

    const listasEnviar = {
      empresa: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id,
      formaPagamento: formaPagamentoId,
      idExcluir: formaPagamentoId
    }

    if (confirm('Esta forma de pagamento será excluída, deseja continuar?')) {
      this.httpService.wait();
      this.httpService.post('/custom/financeiro/excluir-forma-pagamento', listasEnviar)
        .subscribe(
          result => {
            if (!result.error) {
              this.messageService.add({
                key: 'messageLinnks',
                severity: 'sucess',
                summary: 'Sucesso',
                detail: 'Forma de Pagamento excluída com Sucesso'
              });
              this.resultFormaPagamento(result),
              this.empresaChanged()
            }
            if (result.error) {
              this.messageService.add({
                key: 'messageLinnks',
                severity: 'error',
                summary: 'Erro',
                detail: result.message,
              });
            }
          }, error => this.httpService.handleError(error, () => this.excluirFormaPagamento()),
          () => this.httpService.done());
    }

    this.changeToolbar()
  }

  editarFormaPagamento() {
    this.editar = true;
    this.loadFormaPagamentoEditar()
  }

  loadFormaPagamentoEditar() {
    this.httpService.wait();
    this.httpService.get('custom/financeiro/get-forma-pgt-detalhe(' + this.formaPagamento.id + ')')
      .subscribe(res => {
        this.loadFormaPagamentoDetalhe(res)

      }, err => this.httpService.handleError(err, () => this.empresaChanged()),
        () => this.httpService.done());
  }

  loadFormaPagamentoDetalhe(value: Array<any>) {
    this.formaPagamento.formaPagamentoDetalheList = value
    this.detalheList = value
    this.detalheList.forEach(parcela => parcela.splitPagamento.forEach(split => this.buscarCarteira(split)))


    if (!this.regraNova) {
      this.porcentagemTotal = 0.00
      this.detalheList.forEach(element => this.porcentagemTotal = this.porcentagemTotal + this.utilService.parseDecimal(element.porcentagem))
    }
  }

  onRowSelect(event: any) {
    this.formaPagamento = this.formasPagamentosSelecionadas[0]
    this.changeToolbar()
  }

  onRowUnselect() {
    this.changeToolbar()
  }

  selecionarTudo() {
    this.changeToolbar()
  }

  adicionarLinha() {
    if (this.formaPagamento.maxParcelas == null || this.formaPagamento.maxParcelas === 0) {
      this.formaPagamento.maxParcelas = 1;
      this.maxParcelasChanged();
      return;
    }

    if (this.detalheList.length === this.formaPagamento.maxParcelas) {
      this.exibirMensagem({ error: true, mensagem: "Número máximo de parcelas já alcançado." });
      return;
    }

    let detalhe = new FormaPagamentoDetalhe();
    detalhe.parcela = this.detalheList.length === 0 ? 1 : this.detalheList[this.detalheList.length - 1].parcela + 1;
    detalhe.dias = this.detalheList.length === 0 ? this.formaPagamento.intervaloDias : this.detalheList[this.detalheList.length - 1].dias + this.formaPagamento.intervaloDias;
    this.detalheList.push(detalhe);
  }

  salvarFormaPagamento() {
    this.formaPagamento.formaPagamentoDetalheList = this.detalheList
    this.formaPagamento.ativo = this.formaPagamento.ativo
    this.formaPagamento.tipoBaixa = new TipoBaixa(this.formaPagamento.tipoBaixaId)
    this.formaPagamento.empresaConta = new EmpresaConta(this.formaPagamento.empresaContaId)
    this.formaPagamento.configuracoesApis = new ConfiguracoesApis(this.formaPagamento.configuracoesApisId)
    this.formaPagamento.changeUser = new AuthUser(this.sessionService.loggedUser.id)
    this.formaPagamento.empresa = new Empresa(this.dblinkedSessionService.empresa.id)
    this.formaPagamento.regraNova = this.regraNova

    if (this.formaPagamento.regraNova) {
      this.httpService.wait()
      this.httpService.post('/custom/financeiro/salvar-forma-pagto-regra-nova', this.formaPagamento)
        .subscribe(
          result => {
            if (!result.error) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: result.message })
              this.formaPagamento.id = result.formaPagamentoId
              this.empresaChanged()

            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: result.message })
            }
          },
          error => this.httpService.handleError(error, () => this.salvarFormaPagamento()),
          () => this.httpService.done())

    } else {
      this.httpService.wait();
      this.httpService.post('/custom/financeiro/criar-forma-pagto', this.formaPagamento)
        .subscribe(
          result => {
            if (!result.error) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: result.message })
              this.empresaChangedFalse()

            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: result.message })
            }
          },
          error => this.httpService.handleError(error, () => this.salvarFormaPagamento()),
          () => this.httpService.done());
    }
  }

  atualizarFormaPagamento() {
    if (this.validacao()) return

    this.formaPagamento.changeUser = new AuthUser()
    this.formaPagamento.changeUser.id = this.sessionService.loggedUser.id;

    this.httpService.wait();
    this.httpService.post('/custom/financeiro/criar-forma-pagto', this.formaPagamento)
      .subscribe(() => this.fixedToolbar(),
        error => this.httpService.handleError(error, () => this.salvarFormaPagamento()),
        () => this.httpService.done());

  }

  resultFormaPagamento(value: any) {
    this.msgs = [];
    if (value.error === true) {
      this.msgs.push({ severity: 'error', summary: 'Erro', detail: value.message });
    } else {
      this.msgs.push({ severity: 'success', summary: 'Sucesso', detail: value.message });
      this.getFormaPgto();
    }
  }

  removerLinha(value: any) {
    if (!this.regraNova) {
      if (!this.editar) {
        const index = this.detalheList.findIndex(c => c === value);
        this.detalheList.splice(index, 1);
      } else {
        this.httpService.wait();
        this.httpService.delete('/formapagamentodetalhe', value.id.toString())
          .subscribe(() => this.editarFormaPagamento(),
            error => this.httpService.handleError(error, () => this.removerLinha(value)),
            () => this.httpService.done());
      }

      this.verificarParcelas(value);

    } else {
      if (!this.formaPagamento.parcelasExatas) {
        let idx = this.detalheList.findIndex(detalhe => detalhe.parcela === value);
        this.detalheList.splice(idx, 1);

        let parcela = value;
        for (let i = idx; i < this.detalheList.length; i++) {
          this.detalheList[i].parcela = parcela++;
        }

        this.intervaloDiasChanged(0, this.formaPagamento.intervaloDias);

      } else {
        this.exibirMensagem({ "error": true, "mensagem": "Não é possível mudar a quantidade de parcelas." })
      }
    }
  }

  replicarValores(parcela: number) {
    let idx = this.detalheList.findIndex(detalhe => detalhe.parcela === parcela);
    let detalhe = this.detalheList[idx++];

    for (let i = idx; i < this.detalheList.length; i++) {
      this.detalheList[i].txAdmVista = detalhe.txAdmVista;
      this.detalheList[i].txAdmParcelado = detalhe.txAdmParcelado;
      this.detalheList[i].antecipacaoVista = detalhe.antecipacaoVista;
      this.detalheList[i].antecipacaoParcelado = detalhe.antecipacaoParcelado;
    }
  }

  verificarParcelas(value: any) {
    this.porcentagemTotal = 0.00;

    this.detalheList.forEach(element => {
      this.porcentagemTotal = this.utilService.parseDecimal(this.porcentagemTotal + element.porcentagem);
    });

    if (isNullOrUndefined(this.formaPagamento.maxParcelas)) {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Erro', detail: 'Por favor insira o número máximo de parcelas!' });
    }

    if (this.porcentagemTotal > 100) {
      value.porcentagem = 0;
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Erro', detail: 'Porcentagem total ultrapassa 100%' });
    }

    if (value.parcela > 0 && value.porcentagem > 0
      && this.porcentagemTotal < 100
      && this.detalheList.length === value.parcela
      && (!isNullOrUndefined(this.formaPagamento.maxParcelas) || this.formaPagamento.maxParcelas !== 0)
      && value.parcela < this.formaPagamento.maxParcelas) {

      const detalhe = new FormaPagamentoDetalhe();
      detalhe.formaPagamento = new FormaPagamento();
      detalhe.formaPagamento = this.formaPagamento;
      detalhe.dias = 0;
      detalhe.porcentagem = 0;
      detalhe.parcela = value.parcela + 1;
      this.detalheList.push(detalhe);
      this.ultimoDia = detalhe.dias;
    }
  }

  maxParcelasChanged() {
    if (this.detalheList.length < this.formaPagamento.maxParcelas) {
      let count = this.detalheList.length === 0 ? 1 : this.detalheList[this.detalheList.length - 1].parcela + 1;

      while (this.detalheList.length < this.formaPagamento.maxParcelas) {
        let formaPagamentoDetalhe = new FormaPagamentoDetalhe();
        formaPagamentoDetalhe.parcela = count++;

        this.detalheList.push(formaPagamentoDetalhe);
      }

      this.intervaloDiasChanged(0, this.formaPagamento.intervaloDias);

    } else if (this.detalheList.length > this.formaPagamento.maxParcelas) {
      while (this.detalheList.length > this.formaPagamento.maxParcelas) {
        this.detalheList.pop();
      }
    }

    this.payDayChanged();
  }

  intervaloDiasChanged(idx: number, dias: number) {
    let intervalo = this.formaPagamento.intervaloDias != null ? this.formaPagamento.intervaloDias : 0;

    for (let i = idx; i < this.detalheList.length; i++) {
      let formaPagamento = this.detalheList[i];
      formaPagamento.dias = dias;
      dias += intervalo;
    }
  }

  payDayChanged() {
    this.detalheList.map(detalhe => {
      detalhe.payday = this.formaPagamento.paydayDefault
      detalhe.acrescentaMes = 0
    });
  }

  regraNovaChanged() {
    if (this.criar) {
      this.formaPagamento = new FormaPagamento();
    } else if (this.editar) {
      this.showTela('edit');
    } else {
      this.getFormaPgto();
    }

    this.formaPagamento.regraNova = this.regraNova;
    this.formaPagamento = null;
    this.changeToolbar()
  }

  cancelar() {
    if (confirm('Todas as alterações serão perdidas! Deseja continuar?')) {
      this.empresaChanged();
    }
  }

  adicionarDetalhe() {
    this.detalheList.push(this.formaPagamentoDetalhe);
  }

  loadFormaPagamento(value: Array<FormaPagamento>) {
    this.formaPagamentoList = value;
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  comboTiposBaixa() {
    this.tiposBaixas = [];
    this.tiposBaixas.push({ label: 'Selecione', value: 0 });
    this.httpService.wait();
    this.httpService.get('custom/financeiro/get-combo-tipo-baixa')
      .subscribe(ref => {
        ref.forEach(element => {
          this.tiposBaixas.push({ label: element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboTiposBaixa()),
        () => this.httpService.done());
  }

  comboEmpresaConta() {
    this.contasBancarias = [];
    this.contasBancarias.push({ label: 'Selecione', value: 0 });
    this.httpService.wait();
    this.httpService.get('/empresaconta?$select=id,nome,agencia,conta&$'
      + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' and ativo eq true&$orderby=nome')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.contasBancarias.push({ label: (element.nome + ' - AG: ' + element.agencia + ' Conta: ' + element.conta), value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboEmpresaConta()),
        () => this.httpService.done());
  }

  getComboGateway() {
    this.httpService.wait();
    this.httpService.get('custom/financeiro/get-combo-gateway-pagamentos', [this.dblinkedSessionService.empresa.id])
      .subscribe(retorno => {
        this.opcoesgateways = [];
        retorno.forEach(element => {
          this.opcoesgateways.push({ label: element.codigo + ' - ' + element.descricao, value: element.id })
        })

      }, error => this.httpService.handleError(error, () => this.editarFormaPagamento()),
        () => this.httpService.done());
  }

  getOperacaoRegraContabil() {
    this.httpService.wait();
    this.httpService.get('custom/financeiro/get-operacao-regra-contabil(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(
        res => {
          this.opcoesOperacaoRegra = new Array();
          for (let operacaoRegra of res) {
            this.opcoesOperacaoRegra.push({ label: operacaoRegra.descricao, value: operacaoRegra.id });
          }
        }, err => {
          this.httpService.handleError(err, () => this.empresaChanged());
        }, () => this.httpService.done());
  }

  getAdministradoras() {
    this.httpService.wait();
    this.httpService.get('custom/financeiro/get-list-administradora')
      .subscribe(
        res => {
          this.opcoesAdm = new Array();

          for (let adm of res) {
            this.opcoesAdm.push({
              label: adm.nome,
              value: adm.id
            });
          }
        }, err => {
          this.httpService.handleError(err, () => this.empresaChanged());
        }, () => this.httpService.done());
  }

  getBandeiras() {
    this.httpService.wait();
    this.httpService.get('custom/financeiro/get-list-bandeira')
      .subscribe(
        res => {
          this.opcoesBandeira = new Array();

          for (let bandeira of res) {
            this.opcoesBandeira.push({
              label: bandeira.nome,
              value: bandeira.id
            });
          }
        }, err => {
          this.httpService.handleError(err, () => this.empresaChanged());
        }, () => this.httpService.done());
  }

  validaDias(parcela) {
    let idx = this.detalheList.findIndex(detalhe => detalhe.parcela === parcela);

    if (idx > 0) {
      if (this.detalheList[idx - 1].dias >= this.detalheList[idx].dias) {
        this.intervaloDiasChanged(idx - 1, this.detalheList[idx - 1].dias);
        this.exibirMensagem({ error: true, mensagem: "O n° de dias deve ser superior ao da parcela anterior." });
        return;
      }
    }

    this.intervaloDiasChanged(idx, this.detalheList[idx].dias);
  }

  validacaoForm() {

    if (!this.regraNova) {

      if (!this.dblinkedSessionService.hasEmpresaSelected) {
        this.exibirMensagem({ error: true, mensagem: "Selecionar uma empresa!" });
        return true;
      }

      if (this.formaPagamento.descricao == null || this.formaPagamento.descricao.trim() === '') {
        this.exibirMensagem({ error: true, mensagem: "Favor digitar uma descrição!" });
        return true;
      }

      if (this.formaPagamento.tipoBaixaId == null || this.formaPagamento.tipoBaixaId === 0) {
        this.exibirMensagem({ error: true, mensagem: "Favor selecionar uma forma de pagamento!" });
        return true;
      }

      if (this.detalheList && (this.formaPagamento.tipoBaixaId !== 12 && this.formaPagamento.tipoBaixaId !== 13
        && this.formaPagamento.tipoBaixaId !== 3 && this.formaPagamento.tipoBaixaId !== 4)) {
        var acrescentaMesValido: Boolean = true;
        var diasValido: Boolean = true;


        this.detalheList.forEach(element => {
          if (isNullOrUndefined(element.acrescentaMes) || element.acrescentaMes < 0) {
            acrescentaMesValido = false;
          }

          if (isNullOrUndefined(element.dias) || element.dias < 0) {
            diasValido = false;
          }
        });

        if (!acrescentaMesValido) {
          this.exibirMensagem({ error: true, mensagem: "Favor digitar um mês, podendo ser maior ou igual a 0!" });
          return true;
        }

        if (!diasValido) {
          this.exibirMensagem({ error: true, mensagem: "Favor digitar um dia, podendo ser maior ou igual a 0!" });
          return true;
        }
      }

      if (this.formaPagamento.maxParcelas == null || this.formaPagamento.maxParcelas === 0) {
        this.exibirMensagem({ error: true, mensagem: "Por favor insira o número máximo de parcelas!" });
        return true;
      } else {
        if (this.detalheList.length > this.formaPagamento.maxParcelas) {
          this.exibirMensagem({ error: true, mensagem: "Quantidade de parcelas ultrapassa o número máximo!" });
          return true;
        }
      }

      return false;
    }

    let msg = "";
    if (!this.formaPagamento.descricao || !this.formaPagamento.descricao.length) msg = "Informe a descrição.";
    else if (!this.formaPagamento.tipoBaixaId || this.formaPagamento.tipoBaixaId <= 0) msg = "Selecione um tipo.";
    else if (!this.formaPagamento.maxParcelas || this.formaPagamento.maxParcelas <= 0) msg = "Informe o número máximo de parcelas.";
    else if (this.formaPagamento.intervaloDias == null || this.formaPagamento.intervaloDias == undefined || this.formaPagamento.intervaloDias < 0) msg = "Informe o intervalo de dias.";
    else if (!this.formaPagamento.empresaContaId || this.formaPagamento.empresaContaId <= 0) msg = "Selecione uma conta padrão.";
    else if (!this.formaPagamento.isPagamento && !this.formaPagamento.isRecebimento) msg = "Escolher se a forma de pagamento é de recebimento ou de pagamento ou dos dois."

    if (msg !== "") {
      this.exibirMensagem({ error: true, mensagem: msg });
      return true;
    }

    return false;
  }

  validacao(): boolean {
    if (this.regraNova) {
      if ((this.formaPagamento.descricao === null || this.formaPagamento.descricao === undefined) || this.formaPagamento.descricao.trim().length < 1) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Informe a descrição' });
        return true;
      }
      if ((this.formaPagamento.tipoBaixaId === null || this.formaPagamento.tipoBaixaId === undefined) || this.formaPagamento.tipoBaixaId <= 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Selecione um tipo.' });
        return true;
      }
      if ((this.formaPagamento.maxParcelas === null || this.formaPagamento.maxParcelas === undefined) || this.formaPagamento.maxParcelas <= 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Informe o número máximo de parcelas.' });
        return true;
      }
      if (!this.formaPagamento.intervaloDias && this.formaPagamento.dataReferencia != 'dia_fixo') {
        if (this.formaPagamento.maxParcelas === 1 && this.formaPagamento.intervaloDias === 0) return false;

        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Informe o intervalo de dias.' });
        return true;
      }
      if ((this.formaPagamento.empresaContaId === null || this.formaPagamento.empresaContaId === undefined) || this.formaPagamento.empresaContaId <= 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Selecione uma conta padrão.' });
        return true;
      }
      if (!this.formaPagamento.isPagamento && !this.formaPagamento.isRecebimento) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Escolher se a forma de pagamento é de recebimento ou de pagamento ou dos dois.' });
        return true;
      }

      let msg = "";
      if (this.formaPagamento.dataReferencia == 'dia_fixo') {
        if (!this.formaPagamento.paydayDefault)
          msg = "Informe o dia de pagamento das parcelas";
        else if (this.formaPagamento.paydayDefault < 1 || this.formaPagamento.paydayDefault > 31)
          msg = "O dia de pagamento das parcelas é inválido";
        else if (this.detalheList.find(detalhe => detalhe.acrescentaMes == null))
          msg = "Informe o acrescenta mês de todas as parcelas";
      }

      if (this.formaPagamento.programacaoSemanal) {
        if (!this.formaPagamento.minDaysInterval)
          msg = "Informe o intervalo mínimo de dias para o pagamento das parcelas";
        else if (!this.formaPagamento.dayOfWeek)
          msg = "Informe o dia da semana";
      }

      if (msg) {
        this.exibirMensagem({ error: true, mensagem: msg });
        return true;
      }

      return false
    }
    if (!this.regraNova) {
      if ((this.formaPagamento.descricao === null || this.formaPagamento.descricao === undefined) || this.formaPagamento.descricao.trim().length < 1) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Informe a descrição' });
        return true;
      }
      if ((this.formaPagamento.tipoBaixaId === null || this.formaPagamento.tipoBaixaId === undefined) || this.formaPagamento.tipoBaixaId <= 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Selecione um tipo.' });
        return true;
      }
      if ((this.formaPagamento.maxParcelas === null || this.formaPagamento.maxParcelas === undefined) || this.formaPagamento.maxParcelas <= 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Informe o número máximo de parcelas.' });
        return true;
      }
    }
    return false;
  }

  /*Show Hide FormPag*/
  isCardFormPag: boolean = true;
  ShowHIdeFormPag() {
    this.isCardFormPag = !this.isCardFormPag;
  }

  tirarOpcaoDiaFixo() {
    if (this.formaPagamento.programacaoSemanal) {
      this.opcoesDataReferencia = [
        { label: "Emissão", value: "dt_emissao" },
        { label: "Entrega", value: "dt_entrega" },
        { label: "Entrada/Saída", value: "dt_faturamento_automatico" }
      ]
      this.formaPagamento.maxParcelas = 1
      this.formaPagamento.intervaloDias = 0
      this.detalheList = new Array()
      this.formaPagamentoDetalhe.parcela = 1
      this.formaPagamentoDetalhe.dias = 1
      this.detalheList.push(this.formaPagamentoDetalhe)

    } else {
      this.opcoesDataReferencia = [
        { label: "Emissão", value: "dt_emissao" },
        { label: "Entrega", value: "dt_entrega" },
        { label: "Entrada/Saída", value: "dt_faturamento_automatico" },
        { label: "Dia Fixo", value: "dia_fixo" }
      ]
      this.formaPagamento.maxParcelas = null
      this.formaPagamento.intervaloDias = null
      this.detalheList = new Array()
      this.formaPagamentoDetalhe = new FormaPagamentoDetalhe
    }
  }

  checkMaxDay() {
    if (this.formaPagamento.paydayDefault > 31) this.formaPagamento.paydayDefault = 31
  }

  comboTiposPagamentos() {
    this.tiposPagamentos = []
    this.tiposPagamentos.push({ label: 'Selecione', value: null })
    this.httpService.get('/tipospagamentos?$select=id,codigo,descricao&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' &$orderby=codigo')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.tiposPagamentos.push({ label: (element.codigo + ' - ' + element.descricao), value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboTiposPagamentos()))
  }

  private async getParamCobranca(): Promise<void> {
    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/asaas/param-cobranca?empresaId=${this.dblinkedSessionService.empresa.id}`, this.httpOptions).toPromise().then(res => {
        if (res['codigo'] === 200) this.usaSplitFormaPag = res["data"].split_forma_pag
        else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar o parametro de split de pagamento! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Houve um erro ao carregar o parametro de split de pagamento! Contate o suporte.')
    }
  }

  showModalSplit(parcela: any) {
    this.modalSplitPagamento = true
    this.formaPagamentoDetalhe = parcela
    if (this.formaPagamentoDetalhe.splitPagamento)
      this.splitPagamento = this.formaPagamentoDetalhe.splitPagamento
    else {
      this.formaPagamentoDetalhe.splitPagamento = new Array()
      this.splitPagamento = this.formaPagamentoDetalhe.splitPagamento
    }
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
        break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
        break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
        break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso: ', detail: message })
        break
    }
  }

  removerSplit(rowSplit: any) {
    const index = this.splitPagamento.findIndex(element => element.id === rowSplit.id)
    this.splitPagamento.splice(index, 1)
  }

  adicionarLinhaSplit() {
    let split = {
      id: idv4(),
      nome: null,
      carteira: null,
      tipo_cobranca_id: null,
      valor: 0,
      status_descr: null,
      status: null
    }

    this.splitPagamento.push(split)
  }

  salvarSplit() {
    this.validationSplit()
    this.modalSplitPagamento = false
  }

  async buscarCarteira(rowSplit: any) {
    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/asaas/verif-carteira?wallet=${rowSplit.carteira}`, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          rowSplit.status = res["data"].status
          rowSplit.status_descr = res["data"].status_descr
        }
        else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao verificar a carteira! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Houve um erro ao verificar a carteira! Contate o suporte')
    }
  }

  private validationSplit(): void {
    let message: string = null

    this.splitPagamento.forEach(split => {
      if(split.nome === null) {
        message = 'Nome é um campo obrigatório!'
        this.showResponse('info', message)
        throw message
      }

      if(split.carteira === null) {
        message = 'Carteira é um campo obrigatório!'
        this.showResponse('info', message)
        throw message
      }

      if(split.tipo_cobranca_id === null) {
        message = 'Tipo de cobrança é um campo obrigatório!'
        this.showResponse('info', message)
        throw message
      }

      if(split.valor === null) {
        message = 'Valor da cobrança é um campo obrigatório!'
        this.showResponse('info', message)
        throw message
      }

      if(split.tipo_cobranca_id === 2 && split.valor > 100) {
        message = 'O percentual da cobrança não pode ultrapassar 100%!'
        this.showResponse('info', message)
        throw message
      }

      if(split.valor <= 0) {
        message = 'O valor do split deve ser superior a 0!'
        this.showResponse('info', message)
        throw message
      }
    })
  }

  async carregarDadosGrupoEmpresa() {
    if (this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.hasEmpresaSelected) {
      await this.verificarSeEmpresaPodeRealizarModificacoes()
      if (this.empresaRealizaAlteracoes) await this.getCadastroGrupoEmpresas()
      this.changeToolbar()
    }
  }

  async getCadastroGrupoEmpresas() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.get(`/empresa?$select=cadastroGrupoEmpresa&$filter=id eq ${companyId}`).subscribe(
      res => {
        this.cadastroGrupoEmpresa = res.value[0].cadastroGrupoEmpresa ? true : false

        if(this.cadastroGrupoEmpresa) this.getEmpresasGrupo()
      },
      error => this.httpService.handleError(error, () => this.getCadastroGrupoEmpresas())
    )
  }

  getEmpresasGrupo() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.get(`/empresagrupocadastrorelac?$select=id,empresaRelac/nomeFantasia&$filter=empresa/id eq ` + companyId).subscribe(
      res => {
        this.empresasGrupo = []
        this.empresasGrupoSelecionadas = []

        this.empresasGrupoSelecionadas.push(companyId)

        res.value.forEach(company => {
          this.empresasGrupo.push({
            label: company.empresaRelac.nomeFantasia,
            value: company.empresaRelac.id
          })

          this.empresasGrupoSelecionadas.push(company.empresaRelac.id)
        })
      },
      error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro ao buscar as empresas do grupo' })
    )
  }

  async verificarSeEmpresaPodeRealizarModificacoes() {
    const companyId = this.dblinkedSessionService.empresa.id
    this.empresaRealizaAlteracoes = false

    try {
      await this.httpService.get(`/custom/cadastro/verifica-grupo-empresa-permite-alteracao(${companyId})`).toPromise()
      .then(res => this.empresaRealizaAlteracoes = res.permissoes.permiteAlteracaoFormaPagamento)
      .catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }

  changeToolbar() {
    const telaInicial = this.statusTela == "listagem" && !this.criar && !this.editar
    const telaInicialPermissaoParaAlterar = telaInicial && this.empresaRealizaAlteracoes
    const telaFormularioPermissaoParaAlterar = this.empresaRealizaAlteracoes && this.statusTela == 'form' && (this.criar || this.editar)
    const telaInicialPermissaoParaAlterarUmaFormaSelecionada = telaInicialPermissaoParaAlterar && this.formasPagamentosSelecionadas.length === 1 && this.formaPagamento != null
    const telaInicialPermissaoParaAlterarMaisDeUmaFormaSelecionada = telaInicialPermissaoParaAlterar && this.formasPagamentosSelecionadas.length >= 1 && this.formaPagamento != null
    const telaInicialPermissaoParaAlterarFormaSelecionadaCadastroGrupoEmpresa = telaInicialPermissaoParaAlterarMaisDeUmaFormaSelecionada && this.cadastroGrupoEmpresa

    this.toolbarFormPag.setVisible('btn-back', this.statusTela == 'form')
    this.toolbarFormPag.setVisible('btn-delete', telaInicialPermissaoParaAlterarUmaFormaSelecionada)
    this.toolbarFormPag.setVisible('btn-edit', telaInicialPermissaoParaAlterarUmaFormaSelecionada)
    this.toolbarFormPag.setVisible('btn-new', telaInicialPermissaoParaAlterar)
    this.toolbarFormPag.setVisible('btn-save', telaFormularioPermissaoParaAlterar)
    this.toolbarFormPag.setVisible('btn-clone', telaInicialPermissaoParaAlterarMaisDeUmaFormaSelecionada && !this.cadastroGrupoEmpresa)
    this.toolbarFormPag.setVisible('btn-clone-expanded', telaInicialPermissaoParaAlterarFormaSelecionadaCadastroGrupoEmpresa)
  }

  determinarMetodoParaSalvar(isClone: boolean = false): void {
    let validationFields: boolean = false

    if(!isClone) validationFields = this.validacao()

    if(this.cadastroGrupoEmpresa) this.adicionarTodasEmpresasGrupoNaSelecao()

    if (isClone && this.cadastroGrupoEmpresa && !this.clonagemUnica) {
      this.clonagemGrupoEmpresas = true
      this.modalGrupoEmpresas = true
      this.tituloModalGrupoEmpresas = 'Clonagem de forma de pagamento em várias empresas'
      this.descricaoModalGrupoEmpresas = 'Em quais outras empresas você deseja clonar esta forma de pagamento?'
    } else if (!isClone && this.cadastroGrupoEmpresa && !validationFields) {
      this.clonagemGrupoEmpresas = false
      this.modalGrupoEmpresas = true
      this.tituloModalGrupoEmpresas = this.criar ? 'Criação de forma de pagamento em várias empresas' : 'Edição de forma de pagamento em várias empresas'
      this.descricaoModalGrupoEmpresas = this.criar ? 'Em quais outras empresas você deseja criar forma de pagamento?' : 'Em quais outras empresas você deseja editar esta forma de pagamento?'
    } else if (isClone && this.clonagemUnica) this.clonarFormaPagamentoGrupoEmpresa()
    else if (!validationFields) this.salvarFormaPagamento()
  }

  adicionarTodasEmpresasGrupoNaSelecao() {
    this.empresasGrupoSelecionadas = new Array()
    this.selecionarEmpresaCriadoraGrupoEmpresas()
    this.empresasGrupo.forEach(empresa => this.empresasGrupoSelecionadas.push(empresa.value))
  }

  criarFormaPagamentoGrupoEmpresa() {
    this.selecionarEmpresaCriadoraGrupoEmpresas()

    this.formaPagamento.formaPagamentoDetalheList = this.detalheList
    this.formaPagamento.ativo = this.formaPagamento.ativo
    this.formaPagamento.tipoBaixa = new TipoBaixa(this.formaPagamento.tipoBaixaId)
    this.formaPagamento.empresaConta = new EmpresaConta(this.formaPagamento.empresaContaId)
    this.formaPagamento.configuracoesApis = new ConfiguracoesApis(this.formaPagamento.configuracoesApisId)
    this.formaPagamento.changeUser = new AuthUser(this.sessionService.loggedUser.id)
    this.formaPagamento.empresa = new Empresa(this.dblinkedSessionService.empresa.id)
    this.formaPagamento.regraNova = this.regraNova

    const payload = {
      empresaCriadoraId: this.dblinkedSessionService.empresa.id,
      userId: this.sessionService.loggedUser.id,
      empresasGrupoIds: this.empresasGrupoSelecionadas,
      formaPagamento: this.formaPagamento
    }

    this.httpService.wait()
    this.httpService.post('/custom/grupo-empresa/salvar-forma-pagamento-grupo-empresa', payload)
    .subscribe(
      response => {
        this.resultFormaPagamento(response)

        if (!response.error) {
          const infoMessage = 'Forma de pagamento cadastrada com sucesso! Agora, você deve atualizá-la para informar a conta padrão e o tipo de pagamento para cada empresa do grupo.'
          this.showResponse('info', infoMessage)
          this.empresaChanged()
        } else this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: response.message })

      },
      error => this.httpService.handleError(error, () => this.salvarFormaPagamento()),
      () => this.httpService.done())
  }

  excluirFormaPagamentoGrupoEmpresa() {
    this.adicionarTodasEmpresasGrupoNaSelecao()

    const confirmExclusao = confirm('Esta ação afetará todas as empresas do grupo. Deseja continuar?')
    if(!confirmExclusao) return

    const payload = {
      empresasGrupo: this.empresasGrupoSelecionadas,
      formaPagamentoId: this.formaPagamento.id,
      grupoEmpresaFormaPagamentoOrigemId: this.formaPagamento.grupoEmpresaFormaPagamentoOrigemId
    }

    this.httpService.wait();
    this.httpService.post('/custom/grupo-empresa/excluir-forma-pagamento-grupo-empresa', payload)
    .subscribe(
      response => {
        this.resultFormaPagamento(response)
        this.empresaChanged()
      },
      error => this.httpService.handleError(error, () => this.excluirFormaPagamentoGrupoEmpresa()),
      () => this.httpService.done()
    )
  }

  clonarFormaPagamentoGrupoEmpresa() {
    const empresaId = this.dblinkedSessionService.empresa.id
    const formasPagamentosIds = []

    this.formasPagamentosSelecionadas.forEach(formaPagamento => formasPagamentosIds.push(formaPagamento.id))

    let empresasSelecionadas = []
    if(this.clonagemUnica) empresasSelecionadas.push(empresaId)
    else empresasSelecionadas = this.empresasGrupoSelecionadas.filter(empresaSelecionadaId => empresaSelecionadaId !== empresaId)


    const payload = {
      userId: this.sessionService.loggedUser.id,
      clonarNaPropriaEmpresa: this.clonagemUnica,
      empresasGrupo: empresasSelecionadas,
      formasPagamentosIds
    }

    this.httpService.wait();
    this.httpService.post('/custom/grupo-empresa/clonar-forma-pagamento-grupo-empresa', payload)
    .subscribe(
      response => {
        this.messageService.add({
          key: 'messageLinnks',
          severity: 'info',
          summary: 'Informação',
          detail: 'Forma de pagamento clonada com sucesso! Agora, você deve atualizá-la para informar a conta padrão e o tipo de pagamento para cada empresa do grupo.'
        })
        this.empresaChanged()
      },
      error => this.httpService.handleError(error, () => this.clonarFormaPagamentoGrupoEmpresa()),
      () => this.httpService.done()
    )
  }

  editarFormaPagamentoGrupoEmpresa() {
    this.selecionarEmpresaCriadoraGrupoEmpresas()

    this.formaPagamento.formaPagamentoDetalheList = this.detalheList
    this.formaPagamento.ativo = this.formaPagamento.ativo
    this.formaPagamento.tipoBaixa = new TipoBaixa(this.formaPagamento.tipoBaixaId)
    this.formaPagamento.empresaConta = new EmpresaConta(this.formaPagamento.empresaContaId)
    this.formaPagamento.configuracoesApis = new ConfiguracoesApis(this.formaPagamento.configuracoesApisId)
    this.formaPagamento.changeUser = new AuthUser(this.sessionService.loggedUser.id)
    this.formaPagamento.empresa = new Empresa(this.dblinkedSessionService.empresa.id)
    this.formaPagamento.regraNova = this.regraNova

    const payload = {
      userId: this.sessionService.loggedUser.id,
      empresasGrupoIds: this.empresasGrupoSelecionadas,
      formaPagamento: this.formaPagamento,
      formaPagamentoId: this.formaPagamento.id,
      grupoEmpresaFormaPagamentoOrigemId: this.formaPagamento.grupoEmpresaFormaPagamentoOrigemId
    }

    this.httpService.wait()
    this.httpService.post('/custom/grupo-empresa/editar-forma-pagamento-grupo-empresa', payload)
    .subscribe(
      response => {
        if (!response.error) {
          const infoMessage = 'Forma de pagamento atualizada com sucesso! Agora, você deve atualizá-la para informar a conta padrão e o tipo de pagamento para cada empresa do grupo.'
          this.showResponse('info', infoMessage)
          this.empresaChanged()
        } else this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: response.message })

      },
      error => this.httpService.handleError(error, () => this.salvarFormaPagamento()),
      () => this.httpService.done())
  }

  setarVariaveisClonagem(clonagemUnica: boolean) {
    this.clonagemUnica = clonagemUnica
    this.determinarMetodoParaSalvar(true)
  }

  selecionarEmpresaCriadoraGrupoEmpresas() {
    const empresaId = this.dblinkedSessionService.empresa.id
    const posicaoEmpresaIdGrupoEmpresas = this.empresasGrupoSelecionadas.indexOf(empresaId)
    if (posicaoEmpresaIdGrupoEmpresas === -1) this.empresasGrupoSelecionadas.unshift(empresaId)
  }
}
