import { Component, OnInit } from '@angular/core';
import { ItemTipo} from '../model/item-tipo.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'app-item-tipo',
  templateUrl: './item-tipo.component.html',
  styleUrls: ['./item-tipo.component.scss'],
  providers: [CrudService]
})
export class ItemTipoComponent extends DataTableBasicComponent implements OnInit {
  public itemTipo: ItemTipo = new ItemTipo();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('Código', 'codigo'),
      new DataTableColumn('Descrição', 'descricao')

    ];

    this.controls = [
      new DataControl('Código', 'codigo')
        .setRequired()
        .setMinLength(2)
        .setMaxLength(2),
      new DataControl('Descrição', 'descricao')
        .setRequired()
        .setMinLength(1)
        .setMaxLength(255)
    ];
  }
}
