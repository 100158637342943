export class TbNaoNif {
  id: number;
  cod: string;
  descricao: string;
  valueAlias: string;

  constructor(tbNaoNif?: TbNaoNif | number) {
    if (tbNaoNif) {
      if (typeof tbNaoNif === 'number') {
        this.id = tbNaoNif;
      } else {
        this.id = tbNaoNif.id;
        this.cod = tbNaoNif.cod;
        this.descricao = tbNaoNif.descricao;
        this.valueAlias = tbNaoNif.valueAlias;
      }
    }
  }
}
