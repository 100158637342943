import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ComissaoBaseEmpresa } from 'app/financeiro/model/comissao-base-empresa.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { ToolbarService } from '../../shared/services/toolbar.service';

@Component({
  selector: 'app-comissao-base-empresa',
  templateUrl: './comissao-base-empresa.component.html',
  styleUrls: ['./comissao-base-empresa.component.scss'],
  providers: [CrudService]
})
export class ComissaoBaseEmpresaComponent extends DataTableBasicComponent
  implements OnInit, OnDestroy {

  public comissaoBaseEmpresa: ComissaoBaseEmpresa = new ComissaoBaseEmpresa();

  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;

  entityFilter: string = null;

  constructor(
    private crudService: CrudService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService
  ) {
    super(crudService);
  }

  ngOnInit() {
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.empresaSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.empresaChanged,
      () => {
        this.empresaChanged();
      }
    );

    this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((comissaoBaseEmpresa: ComissaoBaseEmpresa) => {

      comissaoBaseEmpresa.empresa = new Empresa(this.dblinkedSessionService.empresa.id);

      // TODO: Remover gambi
      comissaoBaseEmpresa.createTimestamp = new Date();

    });


  }


  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
  }

  empresaChanged() {


    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.entityFilter = 'empresa/id eq ' + this.dblinkedSessionService.empresa.id;

      this.comissaoBaseEmpresa.empresa = this.dblinkedSessionService.empresa;

      this.columns = [
        new DataTableColumn('Código', 'codigo'),
        new DataTableColumn('Descrição', 'descricao'),
        new DataTableColumn('Value Formula', 'valueFormula'),
        new DataTableColumn('Payment Formula', 'paymentFormula'),
        new DataTableColumn('Data Inicial', 'dtInicial'),
        new DataTableColumn('Data Final', 'dtFinal')
      ];

      this.controls = [

        new DataControl('Código', 'codigo')
          .setTypeText()
          .setMinLength(1)
          .setMaxLength(10),

        new DataControl('Descrição', 'descricao')
          .setRequired()
          .setMinLength(1)
          .setMaxLength(150),

        new DataControl('Comissão Base', 'comissaoBase')
          .setTypeSelect()
          .setOptions({
            url: '/comissaobase?$select=id,codigo,descricao&$orderby=codigo',
            value: 'id',
            label: ['codigo', ' - ', 'descricao']
          }),

        new DataControl('Value Formula', 'valueFormula')
          .setRequired()
          .setMinLength(1)
          .setMaxLength(255),

        new DataControl('Payment Formula', 'paymentFormula')
          .setRequired()
          .setMinLength(1)
          .setMaxLength(255),

        new DataControl('Data Ativação', 'dtInicial')
          .setRequired()
          .setTypeDate(),

        new DataControl('Data Desativação', 'dtFinal')
          .setRequired()
          .setTypeDate(),
        ];
    }
  }
}
