import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mwl-demo-utils-calendar-header',
  styleUrls: ['../calendario.component.scss'
  ],
  template: `
  <div class="row">
    <div class="col-sm-12">
      <div class="row text-center">
          <div class="col text-left">
            <div class="btn-group btn-group-sm">
              <div
                style="font-weight: 600; line-height: 1.7;"
                class="btn btn-outline-secondary"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)">
                Anterior
              </div>
              <div
                style="font-weight: 600; line-height: 1.7;"
                class="btn btn btn-outline-primary"
                mwlCalendarToday
                [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)">
                Hoje
              </div>
              <div
                style="font-weight: 600; line-height: 1.7;"
                class="btn btn-outline-secondary"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)">
                Próximo
              </div>
            </div>
          </div>
          <div class="col">
            <h3 style="
            font-size: 1rem;
            font-weight: bold;
            color: #666;
            line-height: 40px;
            "> {{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</h3>
          </div>
          <div class="col"></div>
      </div>
    </div>
  </div>

  `
})
export class CalendarHeaderComponent {
  @Input() view: string;

  @Input() viewDate: Date;

  @Input() locale: string = 'pt';

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
}
