import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { SessionService } from 'app/shared/services/session.service'
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api'
import { TabChanger } from '../components/interfaces/dash-changer'
import { BarChart, ColumnChart, PieChart } from '../components/interfaces/dash-graphics'
import { TableContent } from '../components/interfaces/dash-table'
import { DashMainComponent } from '../dash-main/dash-main.component'

@Component({
  selector: 'app-dash-purchases',
  templateUrl: './dash-purchases.component.html',
  styleUrls: ['./dash-purchases.component.scss']
})
export class DashPurchasesComponent extends DashMainComponent implements OnInit, OnDestroy {

  transactionsDetails: TableContent
  transactionsDetailsSimplified: TableContent

  loadingOptCompanies: boolean
  loadingOptIconCompanies: boolean
  isLoading: boolean
  isTableLoading: boolean = false
  isTableExportLoading: boolean = false

  placeholderCompanies: string = 'Selecione a(s) empresa(s)'

  periodSelected: any
  itensTabSelected: any
  providerTabSelected: any
  movTabSelected: any
  evolutionTabSelected: any

  customExport: Function = () => {}

  providerLabel: Array<string> = new Array()
  ccusLabel: Array<string> = new Array()
  projLabel: Array<string> = new Array()
  itemLabel: Array<string> = new Array()
  availableCompanies: Array<SelectItem> = new Array()
  selectedCompanies: Array<number> = new Array()

  statusData: Array<PieChart> = new Array()
  itensPercentageData: Array<BarChart> = new Array()
  providersPercentageData: Array<BarChart> = new Array()
  itemData: Array<BarChart> = new Array()
  providerData: Array<BarChart> = new Array()
  ccusData: Array<BarChart> = new Array()
  projData: Array<BarChart> = new Array()
  monthlyEvolutionData: Array<ColumnChart> = new Array()
  monthlyEvolutionDataYTD: Array<ColumnChart> = new Array()
  monthlyEvolutionBase: Array<string> = new Array()
  periodOptions: Array<TabChanger> = new Array()
  tabOptions: Array<TabChanger> = new Array()
  tabOptionsTable: Array<TabChanger> = new Array()
  tabOptionsProvider: Array<TabChanger> = Array()
  tabOptionsItens: Array<TabChanger> = Array()

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    public messageService: MessageService
  ) { super(messageService, dblinkedSessionService) }

  public ngOnInit(): void {
    this.startTabs()
    this.initialize()
  }

  public ngOnDestroy(): void {
    this.startVars()
  }

  public async initialize(): Promise <void>{
    this.isLoading = true
    await this.getCompanyGroup()
    this.getInformations(false)
  }

  private async getInformations(isLoading : boolean): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi
      })
    }

    const payload = {
      list_empresas_id: this.selectedCompanies,
      dtInicial: this.getDate('initial', this.periodSelected),
      dtFinal: this.getDate('final', this.periodSelected),
      mov: 'e'
    }

    if(isLoading) this.isLoading = true

    try {
      await this.httpClient.post(`${this.configService.defaultUrlApiUpload}homepage/dash-buy-sell`, payload, httpOptions).toPromise().then(res => {
        if(!res['error']) {
          this.startVars()
          this.getDetails()

          if (res['data'].fornecedor[0].razao_social !== 'Outros') {
            res['data'].fornecedor.forEach(item => {
              if (item.razao_social !== 'Outros') {
                this.providerLabel.push(item.razao_social)
                let oldProv = (res['data'].fornecedor_ano_anterior).find(x => x.razao_social == item.razao_social)
                let extraInformationProv = oldProv ? ((item.valor - (oldProv?.valor ?? 0)) / Math.abs(oldProv?.valor || 1) * 100).toFixed(2) : 0

                this.providerData[0].data.push({y: item.valor, custom: { extraInformation: ` / YoY: ${extraInformationProv}%` }})
                this.providerData[1].data.push(oldProv?.valor ?? 0)
              }
            })
          } else {
            res['data'].fornecedor_ano_anterior.forEach(item => {
              this.providerLabel.push(item.razao_social)
              this.providerData[0].data.push({y: 0 , custom: { extraInformation: ` / YoY: -100%`}})
              this.providerData[1].data.push(item.valor)
            })
          }

          if (res['data'].porcentagem_for.length) {
            res['data'].porcentagem_for.forEach(item => {
              if (item.razao_social !== 'Outros') {
                let oldProvPorcentagem = (res['data'].porcentagem_for_ano_anterior).find(x => x.razao_social == item.razao_social)
                let extraInformationProvPorcentagem = oldProvPorcentagem ? ((item.porcentagem - (oldProvPorcentagem?.porcentagem ?? 0)) / Math.abs(oldProvPorcentagem?.porcentagem || 1) * 100).toFixed(2) : 0

                this.providersPercentageData[0].data.push({y: item.porcentagem, custom: { extraInformation: ` / YoY: ${extraInformationProvPorcentagem}%` }})
                this.providersPercentageData[1].data.push(oldProvPorcentagem?.porcentagem ?? 0)
              }
            })
          } else {
            res['data'].porcentagem_for_ano_anterior.forEach(item => {
              this.providersPercentageData[0].data.push({y: 0 , custom: { extraInformation: ` / YoY: -100%`}})
              this.providersPercentageData[1].data.push(item.porcentagem)
            })
          }

          if (res['data'].ccus.length) {
            res['data'].ccus.forEach(item => {
              this.ccusLabel.push(item.desc_ccus)
              let oldCcus = (res['data'].ccus_ano_anterior).find(x => x.desc_ccus == item.desc_ccus)
              let extraInformationCcus = oldCcus ? ((item.valor - (oldCcus?.valor ?? 0)) / Math.abs(oldCcus?.valor || 1) * 100).toFixed(2) : 0

              this.ccusData[0].data.push({y: item.valor, custom: { extraInformation: ` / YoY: ${extraInformationCcus}%` }})
              this.ccusData[1].data.push(oldCcus?.valor ?? 0)
            })
          } else {
            res['data'].ccus_ano_anterior.forEach(item => {
              this.ccusLabel.push(item.desc_ccus)

              this.ccusData[0].data.push({y: 0 , custom: { extraInformation: ` / YoY: -100%`}})
              this.ccusData[1].data.push(item.valor)
            })
          }

          if (res['data'].proj.length) {
            res['data'].proj.forEach(item => {
              this.projLabel.push(item.desc_proj)
              let oldProj = (res['data'].proj_ano_anterior).find(x => x.desc_proj == item.desc_proj)
              let extraInformationProj = oldProj ? ((item.valor - (oldProj?.valor ?? 0)) / Math.abs(oldProj?.valor || 1) * 100).toFixed(2) : 0

              this.projData[0].data.push({y: item.valor, custom: { extraInformation: ` / YoY: ${extraInformationProj}%` }})
              this.projData[1].data.push(oldProj?.valor ?? 0)
            })
          } else {
            res['data'].proj_ano_anterior.forEach(item => {
              this.projLabel.push(item.desc_proj)

              this.projData[0].data.push({y: 0 , custom: { extraInformation: ` / YoY: -100%`}})
              this.projData[1].data.push(item.valor)
            })
          }

          if (res['data'].item[0].desc_item !== 'Outros') {
            res['data'].item.forEach(item => {
              if (item.desc_item !== 'Outros') {
                this.itemLabel.push(item.desc_item)
                let oldItem = (res['data'].item_ano_anterior).find(x => x.desc_item == item.desc_item)
                let extraInformationItem = oldItem ? ((item.valor - oldItem?.valor) / Math.abs(oldItem?.valor || 1) * 100).toFixed(2) : 0

                this.itemData[0].data.push({ y: item.valor, custom: { extraInformation: ` / YoY: ${extraInformationItem}%` }})
                this.itemData[1].data.push(oldItem?.valor ?? 0)
              }
            })
          } else {
            res['data'].item_ano_anterior.forEach(item => {
              this.itemLabel.push(item.desc_item)

              this.itemData[0].data.push({y: 0 , custom: { extraInformation: ` / YoY: -100%`}})
              this.itemData[1].data.push(item.valor)
            })
          }

          if (res['data'].porcentagem_itens.length) {
            res['data'].porcentagem_itens.forEach(item => {
              if (item.desc_item !== 'Outros') {
                let oldItemPercentage = (res['data'].porcentagem_itens_ano_anterior).find(x => x.desc_item == item.desc_item)
                let extraInformationItemPercentage = oldItemPercentage ? ((item.porcentagem - oldItemPercentage?.porcentagem) / Math.abs(oldItemPercentage?.porcentagem || 1) * 100).toFixed(2) : 0

                this.itensPercentageData[0].data.push({ y: item.porcentagem, custom: { extraInformation: ` / YoY:  ${extraInformationItemPercentage}%` }})
                this.itensPercentageData[1].data.push(oldItemPercentage?.porcentagem ?? 0)
              }
            })
          } else {
            res['data'].porcentagem_itens_ano_anterior.forEach(item => {
              this.itensPercentageData[0].data.push({y: 0 , custom: { extraInformation: ` / YoY: -100%`}})
              this.itensPercentageData[1].data.push(item.porcentagem)
            })
          }

          res['data'].status.forEach(item => {
            this.statusData[0].data.push({
              name: item.status,
              negative: (item.valor <= 0) ? true : false,
              y: Math.abs(item.valor)
            })
          })

          let averageEvolution = 0
          let averageEvolutionLastYear = 0
          let ytdEvolution = 0
          let ytdEvolutionLastYear = 0
          res['data'].evolucao_mensal.forEach((item, i) => {
            let oldEvol = res['data'].evolucao_mensal_ano_anterior[i]

            ytdEvolution += item
            ytdEvolutionLastYear += oldEvol

            this.monthlyEvolutionData[1].data.push({
              y: item,
              custom: {extraInformation: (` / YoY: ${(((item - oldEvol) / Math.abs(oldEvol || 1) * 100)).toFixed(2)}%`)}
            })

            this.monthlyEvolutionDataYTD[1].data.push({
              y: ytdEvolution,
              custom: {extraInformation: (` / YoY: ${(((ytdEvolution - ytdEvolutionLastYear) / Math.abs(ytdEvolutionLastYear || 1) * 100)).toFixed(2)}%`)}
            })

            this.monthlyEvolutionData[0].data.push(oldEvol)
            this.monthlyEvolutionDataYTD[0].data.push(ytdEvolutionLastYear)

            averageEvolution += item

            averageEvolutionLastYear += oldEvol

            let extraInformationAverageEvolutionLastYear = averageEvolutionLastYear !== 0 ? (((averageEvolution / (i+1)) - (averageEvolutionLastYear / (i+1))) / Math.abs((averageEvolutionLastYear / (i+1)) || 1) * 100).toFixed(2) : 0

            this.monthlyEvolutionData[3].data.push({
              y: (averageEvolution / (i+1)),
              custom: {extraInformation: (` / YoY: ${extraInformationAverageEvolutionLastYear}%`)}
            })

            this.monthlyEvolutionDataYTD[3].data.push({
              y: (averageEvolution / (i+1)),
              custom: {extraInformation: (` / YoY: ${extraInformationAverageEvolutionLastYear}%`)}
            })

            this.monthlyEvolutionData[2].data.push(averageEvolutionLastYear / (i+1))
            this.monthlyEvolutionDataYTD[2].data.push(averageEvolutionLastYear / (i+1))
          })
        }
        else this.showErrorResponse(res['mensagem'])
      }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao carregar os dados da dashboard! Contate o suporte'))
    } catch(err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.isLoading = false
    }
  }

  private async getDetails(lazyLoading: LazyLoadEvent = {}): Promise<void> {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi, }) }

    const payload = {
      list_empresas_id: this.selectedCompanies,
      dtInicial: this.getDate('initial', this.periodSelected),
      dtFinal: this.getDate('final', this.periodSelected),
      mov: 'e',
      limit: lazyLoading.rows,
      offset: lazyLoading.first,
      sort_order: lazyLoading.sortOrder,
      sort_field: lazyLoading.sortField,
      filters: lazyLoading.filters,
    }

    this.isTableLoading = true

    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'homepage/dash-buy-sell-details', payload, httpOptions).toPromise().then(res => {
        if(!res['error']) {
          this.transactionsDetails.rows = new Array()
          this.transactionsDetailsSimplified.rows = new Array()

          res['data'].detailedMovement.details.forEach(item => this.transactionsDetails.rows.push(item))
          this.transactionsDetails.cols[14].footerValue = res['data'].totalValue
          this.transactionsDetails.totalRecords = res['data'].detailedMovement.total

          res['data'].simplifiedMovement.details.forEach(item => this.transactionsDetailsSimplified.rows.push(item))
          this.transactionsDetailsSimplified.cols[6].footerValue = res['data'].totalValue
          this.transactionsDetailsSimplified.totalRecords = res['data'].simplifiedMovement.total
        }
        else this.showErrorResponse(res['mensagem'])
      }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao carregar os detalhes da dashboard! Contate o suporte'))
    } catch(err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.isTableLoading = false
    }
  }

  private async getCompanyGroup(): Promise<void> {
    this.loadingOptCompanies = true
    this.loadingOptIconCompanies = true

    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi, }) }

    try {
      await this.httpClient.get(`${this.configService.defaultUrlApiUpload}/cadastro/getgroupempresas/${this.dblinkedSessionService.empresa.id}-${this.sessionService.loggedUser.id}`, httpOptions).toPromise().then(res => {
        if(res['data'].length) res['data'].forEach(item => {
          this.availableCompanies.push({ label: item.fantasia, value: item.id })
        })
        else this.availableCompanies.push({ label: this.dblinkedSessionService.empresa.nomeFantasia, value: this.dblinkedSessionService.empresa.id })
      }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao carregar o grupo de empresas! Contate o suporte'))
    } catch(err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.placeholderCompanies = this.dblinkedSessionService.empresa.nomeFantasia
      this.selectedCompanies.push(this.dblinkedSessionService.empresa.id)
      this.loadingOptCompanies = false
      this.loadingOptIconCompanies = false
    }
  }

  public async exportDetails() {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi, }) }

    const httpOptions2 = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        userId: this.sessionService.loggedUser.id.toString(),
        empresaId: this.dblinkedSessionService.empresa.id.toString(),
      }),
      responseType: 'blob' as 'json'
    }

    const payload = {
      list_empresas_id: this.selectedCompanies,
      userId: this.sessionService.loggedUser.id,
      dtInicial: this.getDate('initial', this.periodSelected),
      dtFinal: this.getDate('final', this.periodSelected),
      mov: 'e',
    }

    this.isTableExportLoading = true

    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + '/homepage/dash-buy-sell/relat', payload, httpOptions).toPromise().then(res => {
        if(!res["error"]) this.httpClient.get(this.configService.defaultUrlApiUpload + '/homepage/dash-buy-sell/relat', httpOptions2).toPromise().then((res: any) => {
          let link = document.createElement('a')
          let url = window.URL.createObjectURL(res)
          link.href = url
          link.download = "relatorio-movimentacao-detalhada-compras.xlsx"
          link.click()
          window.URL.revokeObjectURL(url)
        }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao recuperar seu relatório! Contate o suporte'))
      }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao gerar seu relatório! Contate o suporte'))
    } catch(err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.isTableExportLoading = false
    }
  }

  private startVars(): void {
    this.providerLabel = new Array()
    this.ccusLabel = new Array()
    this.projLabel = new Array()

    this.providerData = [
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value).toString(), data: new Array()},
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value - 1).toString(), data: new Array()}
    ]

    this.ccusData = [
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value).toString(), data: new Array()},
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value - 1).toString(), data: new Array()}
    ]

    this.projData = [
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value).toString(), data: new Array()},
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value - 1).toString(), data: new Array()}
    ]

    this.itemData = [
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value).toString(), data: new Array()},
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value - 1).toString(), data: new Array()}
    ]

    this.statusData = [{
      name: 'status',
      type: 'pie',
      data: new Array()
    }]

    this.itensPercentageData = [
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value).toString(), data: new Array<any>()},
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value - 1).toString(), data: new Array<any>()}
    ]

    this.providersPercentageData = [
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value).toString(), data: new Array<any>()},
      {type: 'bar', name: (this.dblinkedSessionService.periodo.year.value - 1).toString(), data: new Array<any>()}
    ]

    this.monthlyEvolutionBase = (this.periodSelected == 'lastYear' ? this.baseListEvolutionOffset(this.dblinkedSessionService.periodo.month.value - 1) : this.baseListEvolution())

    this.monthlyEvolutionData = [
      {
        type: 'column',
        name: (this.periodSelected == 'lastYear' ? "Ano anterior" : (this.dblinkedSessionService.periodo.year.value - 1).toString()),
        data: new Array()
      },
      {
        type: 'column',
        name: (this.periodSelected == 'lastYear' ? "Ano atual" : (this.dblinkedSessionService.periodo.year.value).toString()),
        data: new Array()
      },
      {
        type: 'spline',
        name: 'Média ' + (this.periodSelected == 'lastYear' ? "anterior" :  (this.dblinkedSessionService.periodo.year.value - 1).toString()),
        data: new Array(),
        dataLabels: {
          enabled: false
        }
      },
      {
        type: 'spline',
        name: 'Média ' + (this.periodSelected == 'lastYear' ? "atual" :  (this.dblinkedSessionService.periodo.year.value).toString()),
        data: new Array(),
        dataLabels: {
          enabled: false
        }
      }
    ]

    this.monthlyEvolutionDataYTD = [
      {
        type: 'column',
        name: (this.periodSelected == 'lastYear' ? "Ano anterior" : (this.dblinkedSessionService.periodo.year.value - 1).toString()),
        data: new Array()
      },
      {
        type: 'column',
        name: (this.periodSelected == 'lastYear' ? "Ano atual" : (this.dblinkedSessionService.periodo.year.value).toString()),
        data: new Array()
      },
      {
        type: 'spline',
        name: 'Média ' + (this.periodSelected == 'lastYear' ? "anterior" : (this.dblinkedSessionService.periodo.year.value - 1).toString()),
        data: new Array(),
        dataLabels: {
          enabled: false
        }
      },
      {
        type: 'spline',
        name: 'Média ' + (this.periodSelected == 'lastYear' ? "atual" : (this.dblinkedSessionService.periodo.year.value).toString()),
        data: new Array(),
        dataLabels: {
          enabled: false
        }
      }
    ]

    this.transactionsDetails = {
      cols: [
        { title: 'Data', width: 10, align: 'center', key: 'data', type: 'text' },
        { title: 'Razão social', width: 20, align: 'left', key: 'razao_social', type: 'text' },
        { title: 'Cod. pedido', width: 10, align: 'left', key: 'cod_pedido', type: 'text' },
        { title: 'Cod. controle pedido', width: 10, align: 'left', key: 'cod_controle_pedido', type: 'text' },
        { title: 'Num. documento', width: 10, align: 'center', key: 'num_doc', type: 'text' },
        { title: 'Operação', width: 10, align: 'left', key: 'cod_descr_operacao', type: 'text' },
        { title: 'Status', width: 10, align: 'center', key: 'status', type: 'text' },
        { title: 'Item', width: 10, align: 'left', key: 'cod_descr_item', type: 'text' },
        { title: 'Centro de custo', width: 10, align: 'left', key: 'cod_descr_ccus', type: 'text' },
        { title: 'Projeto', width: 10, align: 'left', key: 'cod_descr_proj', type: 'text'},
        { title: 'Quantidade', width: 10, align: 'center', key: 'quantidade', type: 'text' },
        { title: 'Almoxarifado', width: 10, align: 'left', key: 'cod_descr_alm', type: 'text' },
        { title: 'Lote', width: 10, align: 'left', key: 'lote', type: 'text' },
        { title: 'Validade', width: 10, align: 'left', key: 'validade', type: 'text', footerValue: 'Total:'  },
        { title: 'Valor', width: 10, align: 'right', key: 'valor', type: 'money', footerValue: 0 },
      ],
      rows: new Array(),
      totalRecords: 0
    }

    this.transactionsDetailsSimplified = {
      cols: [
        { title: 'Data', width: 8, align: 'center', key: 'data', type: 'text' },
        { title: 'Razão social', width: 15, align: 'left', key: 'razao_social', type: 'text' },
        { title: 'Cod. pedido', width: 15, align: 'left', key: 'cod_pedido', type: 'text' },
        { title: 'Num. documento', width: 5, align: 'center', key: 'num_doc', type: 'text' },
        { title: 'Desc. operação', width: 10, align: 'left', key: 'cod_descr_operacao', type: 'text' },
        { title: 'Status', width: 10, align: 'center', key: 'status', type: 'text', footerValue: 'Total:' },
        { title: 'Valor', width: 10, align: 'right', key: 'valor', type: 'money', footerValue: 0 },
      ],
      rows: new Array(),
      totalRecords: 0
    }
  }

  private startTabs(): void {
    this.periodOptions = [
      { label: 'Mês', value: 'month', active: true },
      { label: 'Ano', value: 'year', active: false },
      { label: 'Últimos 12 meses', value: 'lastYear', active: false }
    ]

    this.tabOptions = [
      { label: 'Evolução mensal', value: 'normal', active: true },
      { label: 'Evolução mensal YTD (Acumulada)', value: 'ytd', active: false }
    ]

    this.tabOptionsTable = [
      { label: 'Movimentação Resumida', value: 'simplified', active: true },
      { label: 'Movimentação Detalhada', value: 'normal', active: false }
    ]

    this.tabOptionsProvider = [
      { label: 'Valor por fornecedor', value: 'valuesProvider', active: true },
      { label: 'Participação do total', value: 'percentageProvider', active: false }
    ]

    this.tabOptionsItens= [
      { label: 'Valor por item', value: 'valuesItens', active: true },
      { label: 'Participação do total', value: 'percentageItens', active: false }
    ]

    this.providerTabSelected = 'valuesProvider'
    this.itensTabSelected = 'valuesItens'
    this.movTabSelected = 'simplified'
    this.evolutionTabSelected = 'normal'

    this.periodSelected = 'month'

  }

  public changePeriod(event: any): void {
    this.periodSelected = event
    this.checkCompaniesList()
  }

  public changeTab(event: any, tab: number) {
    switch (tab) {
      case 1:
        this.providerTabSelected = event
        break
      case 2:
        this.itensTabSelected = event
        break
      case 3:
        this.evolutionTabSelected = event
        break
      case 4:
        this.movTabSelected = event
        break
    }
  }

  public changeFilterDetails(lazyLoading: LazyLoadEvent = {}): void {
    this.getDetails(lazyLoading)
  }

  public checkCompaniesList(): void {
    if (this.selectedCompanies.length === 0) {
      this.showErrorResponse('Por favor, selecione pelo menos uma empresa')
      this.placeholderCompanies = 'Selecione a(s) empresa(s)'
    }
    else this.getInformations(true)
  }
}
