import * as FileSaver from 'file-saver';

export default function exportExcel(item: Array<any>, fileName: string, list?: Array<string>) {

    import("xlsx").then(xlsx => {
        const values = prepareExcelValues(item, list)
        const worksheet = xlsx.utils.json_to_sheet(values);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data']  };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array', });
        saveAsExcelFile(excelBuffer, fileName);
    });
}

 function saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}

 function prepareExcelValues(item, orderList? :Array<string>){

    item = item.map(item => {
      let objTeste = {}
      for(let key of orderList){
       Object.defineProperty(objTeste, key, {enumerable: true, writable: true})
       objTeste[key] = item[key]
      }
      return objTeste
    })
    return item
  }
