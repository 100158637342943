import { Component, OnInit } from '@angular/core';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { AuthCrowd } from 'app/proof/model/auth-crowd.model';

@Component({
  selector: 'app-crowd',
  templateUrl: './crowd.component.html',
  styleUrls: ['./crowd.component.scss'],
  providers: [CrudService]
})
export class AuthCrowdComponent extends DataTableBasicComponent implements OnInit {
  public authCrowd = new AuthCrowd();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('CROWD', 'name'),
      new DataTableColumn('ATIVO', 'active')
        .setTypeCheckBox()
    ];

    this.controls = [
      new DataControl('Crowd', 'name')
        .setRequired()
        .setMinLength(3)
        .setMaxLength(50)
        .setReadOnly(),
      new DataControl('Descrição', 'description')
        .setTypeTextArea()
        .setMinLength(3)
        .setMaxLength(255),
      new DataControl('Ativo', 'active')
        .setTypeCheckBox(),
      new DataControl('Usuários', 'authUsers')
        .setTypeMultiSelect()
        .setOptions({ url: '/authuser?filter=id gt 1', value: 'id', label: 'fullName' }),
      new DataControl('Aplicações', 'authApps')
        .setTypeMultiSelect()
        .setOptions({ url: '/authapp', value: 'id', label: 'name' })
    ];
  }
}
