import { isNullOrUndefined } from "util";

export class FormularioTipoCampo{

  id                : number;
  descricao         : string;


  constructor(formularioTipoCampo?: FormularioTipoCampo | number) {
    if (!isNullOrUndefined(formularioTipoCampo)) {
      if (typeof formularioTipoCampo === 'number') {
        this.id = formularioTipoCampo;
      } else {
        this.id         = formularioTipoCampo.id;
        this.descricao       = formularioTipoCampo.descricao;

      }
    }
  }

}