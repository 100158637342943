import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ConfigService } from '../../shared/services/config.service';
import { CrudService } from '../../shared/services/crud.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from '../../shared/services/util.service';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';
import { isNullOrUndefined } from 'util';
import { Table } from 'primeng/table';
import { Toolbar } from 'app/shared/model/toolbar.model';
import exportExcel from 'app/util/processing/exportExcel';

@Component({
  selector: 'app-requisicao',
  templateUrl: './requisicao.component.html',
  styleUrls: [
    './requisicao.component.scss'
  ],
  providers: [CrudService]
})
export class  RequisicaoComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {}

  title: string;
  subtitle: string;

  private checkAllBtn: boolean;
  private idsSelecionados: Array<any>;

  // Colunas da tabela;
  lista: Array<any>;
  codItem: any;
  data: any;
  descricao: any;
  doc: any;
  mi: any;
  local: any;
  qtde: any;
  valor: any;

  // Campos do modal;
  modal: boolean;
  descModal: any;
  docModal: any;
  item1Modal: any;
  listaLocal: SelectItem[];
  local1Modal: any;
  miModal: any;
  participante1Modal: any;
  qtde1Modal: number;
  qtdeDisponivelModal: number;
  valor1Modal: number;

  // Campos do 2º item do modal;
  inputItem2: boolean;
  item2Modal: any;
  local2Modal: any;
  participante2Modal: any;

  // Dropdowns;
  listaItem: SelectItem[];
  listaMI: SelectItem[];
  listaParticipante: SelectItem[];

  private salvarBoo: boolean; // Booleano do btn 'Salvar' do modal (Salvar/ Alterar);

  // Data;
  dtOperacao: Date;
  defaultDate: Date;
  // Warnings;
  msgs: Message[];
  msgsModal: Message[];

  modalEstorno: boolean;
  requisicaoEdicao: any;

  qtdeEstorno: number;
  dtEstorno: Date;
  historicoEstorno: string;

  statusId: number;

  modalPeriodo: boolean;
  dataIni: Date;
  dataFin: Date;
  dataIniSearch: any;
  dataFinSearch: any;

  checks: boolean

  //export excel
  colsLista: any[];

  
  @ViewChild('dt1') dt1: Table
  exportMov() {
    this.dt1.exportCSV()
  }
  
  exportRequisicao = ["data","codigoItem","historico","almoxarifado","tipoEntradaSaida","qtde"]

  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () =>  this.exportExcelAll(this.lista, 'requisicao', this.exportRequisicao) },
    { icon: 'replay', text: 'Atualizar', tooltip: 'atualizar', visible: true, disabled: false, color: 'default', onClick: () => this.getRequisicoes() },
    { icon: 'access_time', text: 'Alterar Período', tooltip: 'Alterar Período', visible: true, disabled: false, color: 'default', onClick: () => this.openModalPeriodo() },
    { icon: 'reply', text: 'Estornar', tooltip: 'Estornar', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.showModal() },
  ]

  private sessionSubscription: Subscription; // Var utilizada no change period event;

  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    this.toolbarDefault();
    this.requisicaoEdicao = '';

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.title = 'Requisição';
    this.subtitle = 'Requisição de material';

    this.modalEstorno = false;
    this.modal = false;
    this.inputItem2 = false;
    this.qtdeDisponivelModal = 0.00;

    this.checkAllBtn = false;
    this.idsSelecionados = [];
    this.lista = [];
    this.dataIniSearch = null;
    this.dataFinSearch = null;

    // Data;
    this.defaultDate = new Date();

    // change period event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changePeriod();
      }
    );

    this.colsLista = [
      { field: 'data', header: 'DATA' },
      { field: 'requisicaoId', header: 'CÓDIGO' },
      { field: 'codigoItem', header: 'ITEM' },
      { field: 'historico', header: 'HISTÓRICO' },
      { field: 'almoxarifado', header: 'ALMOXARIFADO' },
      { field: 'tipoEntradaSaida', header: 'TIPO' },
      { field: 'qtde', header: 'QTDE' }
    ];
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.toolbarService.clear();
  }

  checkss(){
    this.checks = !this.checks
  }

  exportExcelAll(item, fileName, list?) {
    exportExcel(item, fileName, list)
  }

  private changePeriod() {

    if (
      this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected &&
      this.dblinkedSessionService.periodo.month
    ) {
      this.defaultDate = new Date();

      let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
      // formatando meses com dois digitos
      let mes = this.defaultDate.getMonth() > 8 ?
                  (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
      this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
      this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

        if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
          mes = this.dblinkedSessionService.periodo.month.value > 8 ?
            this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
          const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
          this.defaultDate = new Date(dt);
          // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
          lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
          this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
          this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();
        }
      this.getRequisicoes();
      this.getItem();
      this.getLocal();
      this.getCadEcd();

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', true)
    this.toolbarMain.setVisible('btn3', true)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', false)
    this.toolbarMain.setVisible('btn7', true)
    }
  }

  cancelar() {
    this.inputItem2 = false;
    this.modal = false;
  }
  changeToolbar(){
    if(this.idsSelecionados.length > 0)
    this.toolbarMain.setVisible('btn5', true)
    this.toolbarMain.setVisible('btn6', true)
    this.toolbarMain.setVisible('btn7', true)
    this.toolbarMain.setVisible('btn8', true)

    if(this.idsSelecionados.length === 1)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', false)
    this.toolbarMain.setVisible('btn7', false)
    this.toolbarMain.setVisible('btn8', false)
    this.changeToolbar()
  } 

  changetoolbarEdit(){
    if(this.idsSelecionados.length > 0 && this.idsSelecionados.length < 2){
      this.toolbarMain.setVisible('btn5', true)
    }
    else{
      this.toolbarMain.setVisible('btn5', false)
    } 
  }
  changeToolbarRemove(){
    if(this.idsSelecionados.length > 1){
      this.toolbarMain.setVisible('btn1', true)
      this.toolbarMain.setVisible('btn2', true)
      this.toolbarMain.setVisible('btn3', true)
      this.toolbarMain.setVisible('btn4', true)
      this.toolbarMain.setVisible('btn5', true)
      this.toolbarMain.setVisible('btn6', true)
      this.toolbarMain.setVisible('btn7', true)
    }
    if(this.idsSelecionados.length === 0){
      this.toolbarMain.setVisible('btn1', true)
      this.toolbarMain.setVisible('btn2', true)
      this.toolbarMain.setVisible('btn3', true)
      this.toolbarMain.setVisible('btn6', false)
      this.toolbarMain.setVisible('btn7', true)
    }
    if(this.idsSelecionados.length === 1){
      this.toolbarMain.setVisible('btn1', true)
      this.toolbarMain.setVisible('btn2', true)
      this.toolbarMain.setVisible('btn3', true)
      this.toolbarMain.setVisible('btn4', true)
      this.toolbarMain.setVisible('btn5', true)
      this.toolbarMain.setVisible('btn6', true)
      this.toolbarMain.setVisible('btn7', true)
    }
  }

  selectCheckbox(event: any) {
    this.idsSelecionados.push(event);
    event.selected = false;
    this.toolbarMain.setVisible('btn6', true)
    console.log(this.idsSelecionados)
    this.changetoolbarEdit()
    this.changeToolbarRemove()
    
}
  unselectCheckbox(event: any) {
    const index = this.idsSelecionados.findIndex(c => c === event);
    this.idsSelecionados.splice(index, 1);
    event.selected = true;
    console.log(this.idsSelecionados)
    this.changetoolbarEdit()
    this.changeToolbarRemove()
  }

  row(event){
    this.toolbarMain.setClick('btn4', () => this.selectRequisicao(event))
    this.toolbarMain.setClick('btn5', () => this.editarRequisicao(event))
    this.toolbarMain.setClick('btn6', () => this.removerRequisicao(event))
  }
  
  selectCheckboxContainer(event){
    document.getElementById('checkboxContainer'+event.requisicaoId).click();
    this.toolbarMain.setClick('btn4', () => this.selectRequisicao(event))
    this.toolbarMain.setClick('btn5', () => this.editarRequisicao(event))
    this.toolbarMain.setClick('btn6', () => this.removerRequisicao(event))
  };

  check(id: any, event: any) {
    const index = this.idsSelecionados.findIndex(c => c === id);
    !event.selected
      ? this.idsSelecionados.push(id)
      : this.idsSelecionados.splice(index, 1);

    this.getIdsSelecionados();
  }

  checkAll() {
    this.checkAllBtn = !this.checkAllBtn;

    this.idsSelecionados = [];

    if (this.checkAllBtn) {
      this.lista.forEach(rst => {
        rst.selected = true;
        this.idsSelecionados.push(rst.requisicaoId);
        this.toolbarMain.setVisible('btn1', true)
        this.toolbarMain.setVisible('btn2', true)
        this.toolbarMain.setVisible('btn3', true)
        this.toolbarMain.setVisible('btn4', false)
        this.toolbarMain.setVisible('btn5', false)
        this.toolbarMain.setVisible('btn6', true)
        this.toolbarMain.setVisible('btn7', true)
      });
    } else {
      this.lista.forEach(rst => {
        rst.selected = false;
        this.toolbarMain.setVisible('btn1', true)
        this.toolbarMain.setVisible('btn2', true)
        this.toolbarMain.setVisible('btn3', true)
        this.toolbarMain.setVisible('btn4', false)
        this.toolbarMain.setVisible('btn5', false)
        this.toolbarMain.setVisible('btn6', false)
        this.toolbarMain.setVisible('btn7', true)
      });
    }
  }

  private deleteMovInterna(obj) {
    this.httpService.wait();
    this.httpService.post('/custom/estoque/delete-mov-interna', obj).subscribe(
      () => {
        this.changePeriod();
      },
      error =>
        this.httpService.handleError(error, () => this.deleteMovInterna(obj)),
      () => this.httpService.done()
    );
  }

  private excluir() {
    const boo = this.idsSelecionados.length > 1;
    let msg;
    switch (boo) {
      case true:
        msg = 'Deseja excluír os itens?';
        break;
      case false:
        msg = 'Deseja excluír o item?';
        break;
      default:
        break;
    }

    if (confirm(msg)) {
      this.idsSelecionados.forEach(element => {
        const index = this.lista.findIndex(c => c.id === element);
        this.lista.splice(index, 1);
      });

      const itens = {
        idsSelecionados: this.idsSelecionados
      };
      this.deleteMovInterna(itens);

      this.refreshToolbar();
    }
  }
  private getIdsSelecionados() {
    if (this.idsSelecionados.length === 0) {
    this.toolbarMain.setVisible('btn5', true)
    this.toolbarMain.setVisible('btn6', true)
    this.toolbarMain.setVisible('btn7', true)
    this.toolbarMain.setVisible('btn8', true)
    }
    if (this.idsSelecionados.length === 1) {
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', false)
    this.toolbarMain.setVisible('btn7', false)
    this.toolbarMain.setVisible('btn8', false)
    }
    if (this.idsSelecionados.length > 1) {
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', false)
    this.toolbarMain.setVisible('btn7', false)
    this.toolbarMain.setVisible('btn8', false)
    }
  }

  private getItem() {
    this.httpService.wait();
    this.httpService
      .post(
        '/custom/estoque/get-mov-interna-item(' +
          this.dblinkedSessionService.empresa.id +
          ')'
      )
      .subscribe(
        rst => {
          this.loadItem(rst);
        },
        error => this.httpService.handleError(error, () => this.getItem()),
        () => this.httpService.done()
      );
  }

  private getLocal() {
    this.httpService.wait();
    this.httpService
      .get(
        '/empresaalmoxarifado?$select=id,codigo,descricao' +
          '&$filter=empresa/id eq ' +
          this.dblinkedSessionService.empresa.id +
          '&$orderby=codigo'
      )
      .subscribe(
        proj => this.loadLocal(proj.value),
        error => this.httpService.handleError(error, () => this.getLocal()),
        () => this.httpService.done()
      );
  }

  private getMI() {
    this.httpService.wait();
    this.httpService
      .get(
        '/mi?$select=id,codigo,descricao,tipo' +
          '&$filter=empresa/id eq ' +
          this.dblinkedSessionService.empresa.id +
          '&$orderby=codigo'
      )
      .subscribe(
        proj => this.loadMI(proj.value),
        error => this.httpService.handleError(error, () => this.getMI()),
        () => this.httpService.done()
      );
  }

  public getRequisicoes() {

    if(!isNullOrUndefined(this.dataIni)){
      if (this.dataIni !== null && this.dataFin !== null) {
        const mesIni = this.dataIni.getMonth() > 8 ?
                      (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
        const mesFin = this.dataFin.getMonth() > 8 ?
                      (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
        this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
                      this.dataIni.getDate() : '0' + this.dataIni.getDate());
        this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
                      this.dataFin.getDate() : '0' + this.dataFin.getDate());
      }
    }

    this.qtdeDisponivelModal = 0.00;
    this.httpService.wait();
    this.httpService
      .get(
        '/custom/estoque/get-requisicoes(' +
          this.dblinkedSessionService.empresa.id +
          ',' +
          this.dblinkedSessionService.periodo.month.value +
          ',' +
          this.dblinkedSessionService.periodo.year.value +
          ',' +
          this.dataIniSearch +
          ',' +
          this.dataFinSearch +
          ')'
      )
      .subscribe(
        rst => {
          this.lista = rst;
        },
        error =>
          this.httpService.handleError(error, () => this.getRequisicoes()),
        () => this.httpService.done()
      );
      console.log(this.lista)
  }

  private getCadEcd() {
    this.qtdeDisponivelModal = 0.00;
    this.httpService.wait();
    this.httpService
      .get(
        '/custom/estoque/get-cad-ecd(' +
          this.dblinkedSessionService.empresa.id +
          ',' +
          this.dblinkedSessionService.periodo.month.value +
          ',' +
          this.dblinkedSessionService.periodo.year.value +
          ')'
      )
      .subscribe(
        rst => {
          if(rst.length > 0){
            this.statusId = rst[0].status;
          }else{
            this.statusId = 0;
          }

        },
        error =>
          this.httpService.handleError(error, () => this.getCadEcd()),
        () => this.httpService.done()
      );
  }

  buscarSaldoItem(event: any){

    if(event.value){

      // this.local1Modal = event.almoxarifadoId;

      console.log(this.local)

      this.httpService.wait();
      this.httpService
        .get(
          '/custom/estoque/get-sld-item(' +
            this.dblinkedSessionService.empresa.id +
            ',' +
            this.dblinkedSessionService.periodo.month.value +
            ',' +
            this.dblinkedSessionService.periodo.year.value +
            ',' +
            event.value +
            ',' +
            this.local1Modal +
            ')'
        )
        .subscribe(
          rst => {
            this.qtdeDisponivelModal = this.utilService.parseDecimal(rst.qtde);
          },
          error =>
            this.httpService.handleError(error, () => this.buscarSaldoItem(event)),
          () => this.httpService.done()
        );
    }

  }

  private getParticipante() {
    this.httpService.wait();
    this.httpService
      .post(
        '/custom/estoque/get-mov-interna-participante(' +
          this.dblinkedSessionService.empresa.id +
          ')'
      )
      .subscribe(
        rst => {
          this.loadParticipante(rst);
        },
        error =>
          this.httpService.handleError(error, () => this.getParticipante()),
        () => this.httpService.done()
      );
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() {


  }

  private loadItem(value: Array<any>) {
    this.listaItem = [];
    this.listaItem.push({ label: 'selecione', value: null });
    value.forEach(element => {
      this.listaItem.push({
        label: element.codigo + ' - ' + element.descricao,
        value: element.id
      });
    });
  }

  private loadLocal(value: Array<any>) {
    this.listaLocal = [];
    this.listaLocal.push({ label: 'selecione', value: null });
    value.forEach(element => {
      this.listaLocal.push({
        label: element.codigo + ' - ' + element.descricao,
        value: element.id
      });
    });
  }

  private loadMI(value: Array<any>) {
    this.listaMI = [];
    this.listaMI.push({ label: 'selecione', value: null });
    value.forEach(element => {
      this.listaMI.push({
        label:
          element.codigo +
          ' - ' +
          element.descricao +
          ' - ' +
          element.tipo.descricao,
        value: element.id
      });
    });
  }

  private loadParticipante(value: Array<any>) {
    this.listaParticipante = [];
    this.listaParticipante.push({ label: 'selecione', value: null });
    value.forEach(element => {
      this.listaParticipante.push({
        label: element.cnpj + ' - ' + element.nome,
        value: element.id
      });
    });
  }

  private refreshModal() {
    this.descModal = null;

    this.docModal = null;

    this.dtOperacao = this.defaultDate;

    this.item1Modal = null;
    this.item2Modal = null;

    this.local1Modal = null;

    this.miModal = null;

    this.participante1Modal = null;
    this.participante2Modal = null;

    this.qtde1Modal = 0;

    this.valor1Modal = 0.0;
  }

  private refreshToolbar() {
    this.idsSelecionados = [];
    this.toolbarRemove(true);
    this.toolbarEdit(true);
  }

  private validaItem(obj) {

    if (
      obj.data === null ||
      obj.qtde === null ||
      obj.local === null ||
      obj.codItem === null
    ) {

      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        // summary: 'Campos Obrigatórios: ',
        detail: 'Todos os campos devem ser preenchidos.'
      });
      return true;
    }
    return false;
  }

  salvar() {

    const item = {
      idReq: this.editarRequisicao ? this.requisicaoEdicao.requisicaoId : 0,
      data: this.dtOperacao,
      doc: this.docModal,
      mi: this.miModal,
      qtde: this.qtde1Modal,
      valor: this.utilService.parseDecimal(this.valor1Modal),
      local: this.local1Modal,
      codItem: this.item1Modal,
      descricao: this.descModal,
      participante: this.participante1Modal,
      empresa: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id,
      qtdeDisp: this.qtdeDisponivelModal,

      boo: !this.editarRequisicao
    };

    if (!this.validaItem(item)) {
      this.modal = false;
      this.updateMovInterna(item);
      this.refreshToolbar();
    }
  }

  showItem2() {
    this.miModal !== null
      ? (this.inputItem2 = true)
      : (this.inputItem2 = false);
  }

  private showModal() {
    this.salvarBoo = true;
    this.modal = true;
    this.refreshModal();
    this.qtdeDisponivelModal = null
  }

  private toolbarDefault() {
    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.getRequisicoes());
    this.toolbarService.replace('edit', false,
                                'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());
    this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Opções');

    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir', );
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
    this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar' );

  this.toolbarService.ocultar('more_vert');
  this.toolbarService.ocultar('remove');
  this.toolbarService.ocultar('check');
  this.toolbarService.ocultar('info');
  this.toolbarService.ocultar('pesquisa');
  this.toolbarService.ocultar('alterar');

    this.toolbarService.add(
      'remove',
      true,
      'class-remove-button',
      'delete',
      'Excluir'
    );

    this.toolbarService.add(
      'msg',
      true,
      'class-edit-button',
      'edit',
      'Editar'
    );

    this.toolbarService.replace(
      'add',
      false,
      'class-new-button',
      'NOVO',
      'Criar novo',
      () => this.showModal()
    );
  }

  private toolbarEdit(boo) {

  }

  private toolbarRemove(boo) {
    boo
      ? this.toolbarService.alterarStatus('remove', true, 'Excluir', () =>
          this.excluir()
        )
      : this.toolbarService.alterarStatus('remove', false, 'Excluir', () =>
          this.excluir()
        );
  }

  private toolbarPrint(boo) {
    boo
      ? this.toolbarService.alterarStatus('print', true, 'Imprimir', () =>
          this.imprimir()
        )
      : this.toolbarService.alterarStatus('print', false, 'Imprimir', () =>
          this.imprimir()
        );
  }

  private updateMovInterna(obj) {
    this.httpService.wait();
    this.httpService.post('/custom/estoque/update-mov-interna', obj).subscribe(
      result => {


        if (result.error === true) {
          this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
        } else {
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result.message });
          this.changePeriod();
        }


      },
      error =>
        this.httpService.handleError(error, () => this.updateMovInterna(obj)),
      () => this.httpService.done()
    );
  }

  selectRequisicao(event: any){
    if(event.tipoEntradaSaida !== 'E'){
      this.modalEstorno = true;
      this.requisicaoEdicao = event;
    }else{

      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: 'Somente requisições de saída podem ser estornadas' });
    }

  }

  editarRequisicao(event: any){
    this.requisicaoEdicao = event;

    this.salvarBoo = false;

    this.local1Modal = event.almoxarifadoId;

    this.httpService.wait();
      this.httpService
        .get(
          '/custom/estoque/get-sld-item(' +
            this.dblinkedSessionService.empresa.id +
            ',' +
            this.dblinkedSessionService.periodo.month.value +
            ',' +
            this.dblinkedSessionService.periodo.year.value +
            ',' +
            event.itemId +
            ',' +
            this.local1Modal +
            ')'
        )
        .subscribe(
          rst => {
            if(event.tipoEntradaSaida === 'S'){
              this.qtdeDisponivelModal = this.utilService.parseDecimal(rst.qtde) + this.utilService.parseDecimal(event.qtdeBigDecimal);
            }else {
              this.qtdeDisponivelModal = this.utilService.parseDecimal(rst.qtde) - this.utilService.parseDecimal(event.qtdeBigDecimal);
            }
          },
          error =>
            this.httpService.handleError(error, () => this.editarRequisicao(event)),
          () => this.httpService.done()
        );

    this.dtOperacao = this.utilService.timestampToDate(event.dataReq);
    this.qtde1Modal = event.qtdeBigDecimal;
    this.local1Modal = event.almoxarifadoId;
    this.item1Modal = event.itemId;
    this.modal = true;
  }

  removerRequisicao(event: any){



    if(event.tipoEntradaSaida === 'S'){
      if(event.qtde !== event.saldo){
        if (confirm('Deseja realmente excluir esta requisição? os estornos também serão excluídos juntos com esta requisição')) {
          this.httpService.wait();
          this.httpService.delete('/movimentacao', event.requisicaoId.toString())
          .subscribe(
            () => {
              // this.toolbarMain.setVisible('btn9', true)
              this.changePeriod(); 
              this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: 'Requisição excluída com sucesso' })

          ;},
              error => this.httpService.handleError(error, () => this.removerRequisicao(event)),
              () => this.httpService.done());
        }

      }else{
        if (confirm('Deseja realmente excluir esta requisição?')) {
          if(this.statusId == 0){
            this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: 'Não existe período de estoque criado para este mês' })
          } else if(this.statusId >= 2){
            this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: 'Período fechado' })
          }else{

            this.httpService.wait();
            this.httpService.delete('/movimentacao', event.requisicaoId.toString())
            .subscribe(
              () => {
                // this.toolbarMain.setVisible('btn9', true)
                this.changePeriod(); 
                this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: 'Requisição excluída com sucesso' })
                ;},
                error => this.httpService.handleError(error, () => this.removerRequisicao(event)),
                () => this.httpService.done());

          }

        }
      }

    }else{
      if (confirm('Deseja realmente excluir esta requisição?')) {
        if(this.statusId == 0){
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: 'Não existe período de estoque criado para este mês' })
        } else if(this.statusId >= 2){
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: 'Período fechado' })
        }else{
        this.httpService.wait();
        this.httpService.delete('/movimentacao', event.requisicaoId.toString())
          .subscribe(() => {
            // this.toolbarMain.setVisible('btn9', true)
           this.changePeriod();
           this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: 'Requisição excluída com sucesso' });},
            error => this.httpService.handleError(error, () => this.removerRequisicao(event)),
            () => this.httpService.done());
        }
      }
    }

  }

  salvarEstorno(){

    if(!this.dtEstorno || this.qtdeEstorno <= 0 || !this.qtdeEstorno){

      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: 'Campos data e quantidade são obrigatórios' });
      return;
    }else{
      if(this.utilService.parseDecimal(this.qtdeEstorno) <= this.utilService.parseDecimal(this.requisicaoEdicao.saldo)){
        const objEstorno = {
          id: this.requisicaoEdicao.requisicaoId,
          data: this.dtEstorno,
          hist: this.historicoEstorno,
          qtde: this.qtdeEstorno,
          usuario: this.sessionService.loggedUser.id
        };


            this.httpService.wait();
            this.httpService.post('/custom/estoque/salvar-estorno', objEstorno).subscribe(
            result => {



            if (result.error === true) {
              this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
            } else {
              this.modalEstorno = false;
              this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result.message });
              this.changePeriod();
            }


          },
          error =>
            this.httpService.handleError(error, () => this.salvarEstorno()),
          () => this.httpService.done()
        );
      }else{

        this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: 'Quantidade do Estorno não pode ultrapassar o saldo da requisição' });
      }

    }




  }

  openModalPeriodo() {
    this.modalPeriodo = true;
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
  }

  private imprimir() {
    const boo = this.idsSelecionados.length > 1;
    let msg;
    switch (boo) {
      case true:
        msg = 'Deseja realmente imprimir estas requisições?';
        break;
      case false:
        msg = 'Deseja realmente imprimir esta requisição?';
        break;
      default:
        break;
    }

    if (confirm(msg)) {

      const itens = {
        idsSelecionados: this.idsSelecionados
      };

      this.imprimirRequisicao(itens);

      this.refreshToolbar();
    }
  }

  private imprimirRequisicao(obj) {
    this.httpService.wait();
    this.httpService.post('/custom/estoque/impressao-requisicao', obj).subscribe(
      result =>
      result.forEach(element => {
        this.download(element);
      })
      ,
      error =>
        this.httpService.handleError(error, () => this.imprimirRequisicao(obj)),
      () => this.httpService.done()
    );
  }

  download(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/estoque'), '_blank');
    } else {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: result.message });
    }
  }

}


