import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { EmpresaParametroBaixa } from 'app/cadastro/model/empresa-parametro-baixa.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';

@Component({
  selector: 'app-empresa-parametro-baixa',
  templateUrl: './empresa-parametro-baixa.component.html',
  styleUrls: ['./empresa-parametro-baixa.component.scss'],
  providers: [CrudService]
})
export class EmpresaParametroBaixaComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public empresaParametroBaixa: EmpresaParametroBaixa = new EmpresaParametroBaixa();

  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;

  entityFilter: string = null;

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  ngOnInit() {

    this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((empresaParametroBaixa: EmpresaParametroBaixa) => {

      empresaParametroBaixa.empresa = new Empresa(this.dblinkedSessionService.empresa.id);
    });

    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
      () => { this.empresaChanged(); });

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.entityFilter = 'empresa/id eq ' + this.dblinkedSessionService.empresa.id;

      this.columns = [
        new DataTableColumn('Nome', 'nome'),
        new DataTableColumn('Conta Contábil', 'planoContas.codCta')];

      this.controls = [
        new DataControl('Nome', 'nome')
          .setRequired()
          .setMinLength(3)
          .setMaxLength(255),
          new DataControl('Conta Contábil', 'planoContas')
          .setTypeSelect()
          .setOptions({ url: '/planocontas?$select=id,codCta,descrCta&$'
          + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
          ' and indCta eq ' + '\'' + 'A' + '\'' + '&$orderby=codCta',
                        value: 'id',
                        label: ['codCta', ' - ', 'descrCta']})
      ];
    }
  }
}
