import { MovParteADeducoesCsll } from 'app/ecf/model/mov-parte-a-deducoes-csll.model';
import { MovSaldosContabeis } from 'app/contabil/model/mov-saldos-contabeis.model';

export class TbRelacMovParteADeducoesSaldosContabeisCsll {
    id: number;
    movParteADeducoesCsll?: MovParteADeducoesCsll = new MovParteADeducoesCsll();
    movSaldosContabeis?: MovSaldosContabeis = new MovSaldosContabeis();
    saldoRelacionado: number;

    constructor(tbRelacMovParteADeducoesSaldosContabeisCsll?: TbRelacMovParteADeducoesSaldosContabeisCsll | number) {
        if (tbRelacMovParteADeducoesSaldosContabeisCsll !== undefined && tbRelacMovParteADeducoesSaldosContabeisCsll !== null) {
            if (typeof tbRelacMovParteADeducoesSaldosContabeisCsll === 'number') {
                this.id = tbRelacMovParteADeducoesSaldosContabeisCsll;
            } else {
                this.id = tbRelacMovParteADeducoesSaldosContabeisCsll.id;
                this.movParteADeducoesCsll = tbRelacMovParteADeducoesSaldosContabeisCsll.movParteADeducoesCsll ||
                                             new MovParteADeducoesCsll();
                this.movSaldosContabeis = tbRelacMovParteADeducoesSaldosContabeisCsll.movSaldosContabeis || new MovSaldosContabeis();
                this.saldoRelacionado = tbRelacMovParteADeducoesSaldosContabeisCsll.saldoRelacionado;

            }
        }
    }
}
