import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { isNullOrUndefined } from 'util';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { TitleService } from 'app/shared/services/title.service';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';
declare var $: any;

@Component({
  selector: 'app-cad-beneficios',
  templateUrl: './cad-beneficios.component.html',
  styleUrls: ['./cad-beneficios.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadBeneficioComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = null;
  msgsModal: Message[] = null;
  subtitle: string;
  title: string;
  modalNew: boolean = false;
  modalEdit: boolean = false;
  modalReajNew: boolean = false;
  modalReajEdit: boolean = false;
  modalFuncNew: boolean = false;
  modalFuncEdit: boolean = false;
  modalFuncExcluir: boolean = false;
  modalCargoNew: boolean = false;
  modalCargoEdit: boolean = false;
  modalCargoExcluir: boolean = false;
  modalFuncComNew: boolean = false;
  modalFuncComEdit: boolean = false;
  modalCargoComNew: boolean = false;
  modalCargoComEdit: boolean = false;
  modalFaixaNew: boolean = false;
  modalFaixaEdit: boolean = false;
  modalFaixaExcluir: boolean = false;
  beneficiosLista: Array<any> = new Array();
  rowSelected: boolean = false;
  reajusteLista: Array<any> = new Array();
  funcBenefLista: Array<any> = new Array();
  cargoBenefLista: Array<any> = new Array();
  idadeNivBenefLista: Array<any> = new Array();
  allPeriodsExclude: Array<boolean> = [true, false, false];
  rowSelectedOnce: boolean = false;
  opcoesVersoes: Array<any> = new Array();
  versaoSelecionada: number;

  //Variaveis do beneficio
  codigoNew: string = "";
  descricaoNew: string = "";
  beneficioNew: string = "";
  comissaoNew: boolean = false;
  idadeNew: boolean = false;
  possuiPisoNew: boolean = false;
  possuiDependentesNew: boolean = false;
  valorPisoNew: number = null;
  dependentesIdadeMaxNew: number = null;
  idEdit: number = null;
  codigoEdit: string = null;
  descricaoEdit: string = null;
  beneficioEdit: string = null;
  comissaoEdit: boolean = false;
  idadeEdit: boolean = false;
  possuiPisoEdit: boolean = false;
  possuiDependentesEdit: boolean = false;
  valorPisoEdit: number = null;
  dependentesIdadeMaxEdit: number = null;

  //Variaveis do reajuste
  percentualNew: number = null;
  dtReajusteNew: Date = null;
  reajusteIdEdit: number = null;
  percentualEdit: number = null;
  dtReajusteEdit: Date = null;

  //Variaveis de funcionarios
  funcOpcoes: SelectItem[] = [];
  funcSelecionados: Array<number> = new Array();
  valorNew: number = null;
  valorDescNew: number = null;
  funcRelacId: number = null;
  valorEdit: number = null;
  valorDescEdit: number = null;
  valorProjEdit: number = null;
  allFuncValor: boolean = false;
  allPeriodsValor: Array<boolean> = [true, false, false];
  allFuncValorDesc: boolean = false;
  allPeriodsValorDesc: Array<boolean> = [true, false, false];
  allFuncValorProj: boolean = false;
  allPeriodsValorProj: Array<boolean> = [true, false, false];
  percentMesNew: number = null;
  percentTrimNew: number = null;
  percentMesProjNew: number = null;
  percentTrimProjNew: number = null;
  //salarioBaseProjNew: number = null;
  percentMesEdit: number = null;
  percentTrimEdit: number = null;
  percentMesProjEdit: number = null;
  percentTrimProjEdit: number = null;
  salarioProjEdit: number = null;
  allFuncPercentMes: boolean = false;
  allPeriodsPercentMes: Array<boolean> = [true, false, false];
  allFuncPercentTrim: boolean = false;
  allPeriodsPercentTrim: Array<boolean> = [true, false, false];
  allFuncSalarioProj: boolean = false;
  allPeriodsSalarioProj: Array<boolean> = [true, false, false];
  allFuncPercentMesProj: boolean = false;
  allPeriodsPercentMesProj: Array<boolean> = [true, false, false];
  allFuncPercentTrimProj: boolean = false;
  allPeriodsPercentTrimProj: Array<boolean> = [true, false, false];

  //Variaveis de cargos
  aplicNivel: boolean = false;
  aplicNivelPy: boolean = false;
  cargoIdNew: number = null;
  cargoOpcoes: SelectItem[] = [];
  cargosSelecionados: Array<number> = new Array();
  niveisOpcoes: SelectItem[] = [];
  niveisSelecionados: Array<number> = new Array();
  valorCargoNew: number = null;
  valorCargoDescNew: number = null;
  cargoRelacId: number = null;
  valorCargoEdit: number = null;
  valorCargoDescEdit: number = null;
  valorCargoProjEdit: number = null;
  allCargoValor: boolean = false;
  allCargoPeriodsValor: Array<boolean> = [true, false, false];
  allCargoValorDesc: boolean = false;
  allCargoPeriodsValorDesc: Array<boolean> = [true, false, false];
  allCargoValorProj: boolean = false;
  allCargoPeriodsValorProj: Array<boolean> = [true, false, false];

  //Variaveis de idade
  idadeMinNew: number = null;
  idadeMaxNew: number = null;
  relacId: number = null;
  allValor: boolean = false;
  allValorDesc: boolean = false;
  allValorProj: boolean = false;

  //export excel
  colsBeneficio: any[];

  @ViewChild('dt') dt: Table
  exportMov() {
    this.dt.exportCSV()
  }

  constructor(private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private messageService: MessageService,
    private titleService: TitleService) { }

    public toolbarMain = new Toolbar()
    public toolbarFunc = new Toolbar()
    public toolbarCargo = new Toolbar()
    public toolbarReajuste = new Toolbar()
    public toolbarNivel = new Toolbar()

  toolbarMainIni = [
    { key: 'export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.dt.exportCSV() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddBeneficio() },
  ]

  toolbarFuncIni = [
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddFunc() },
    {
      key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardDetal()
        this.changeStateDetal()
      }
    },
  ]

  toolbarCargoIni = [
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddCargo() },
    {
      key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardDetal()
        this.changeStateDetal()
      }
    },
  ]

  toolbarReajusteIni = [
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddReajuste() },
    {
      key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardDetal()
        this.changeStateDetal()
      }
    },
  ]
  toolbarNivelIni = [
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddIdadeRelac() },
    {
      key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardDetal()
        this.changeStateDetal()
      }
    },
  ]


  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni);
    this.toolbarFunc.setAll(this.toolbarFuncIni);
    this.toolbarCargo.setAll(this.toolbarCargoIni);
    this.toolbarReajuste.setAll(this.toolbarReajusteIni);
    this.toolbarNivel.setAll(this.toolbarNivelIni);
    this.toolbarService.hidden = true;
    this.title = 'BENEFÍCIOS';
    this.subtitle = 'Cadastro de benefícios';
    this.titleService.title = "Benefícios RH"
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.rowSelected = false;
          this.getBeneficios();
          this.getFuncionarios();
          this.getNiveis();
          this.getCargos();
          this.getVersaoOrca();
          this.toolbarEnable();
          this.toolbarInitial()

          if ((this.idEdit != null) && this.dblinkedSessionService.hasPeriodoSelected) {
            this.getBenefFuncRelac();
            this.getBeneCargoRelac();
            if (this.idadeEdit) {
              this.getIdadeNivRelac();
            }
          }
        }

      });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.periodoChanged,
      () => {
        if (this.rowSelectedOnce)
          if (this.rowSelected !== null) this.rowSelected = true;
      })

    this.colsBeneficio = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'nome', header: 'BENEFÍCIO' },
      { field: 'descricao', header: 'DESCRIÇÃO' }
    ];
  }

  toolbarInitial(){
    this.rowSelected = false
    this.toolbarMain.setVisible('export', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.toolbarMain.setVisible('add', true)
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  getVersaoOrca() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/versao', httpOptions)
      .subscribe(
        res => {
          this.opcoesVersoes = [];
          var result = res["data"];
          for (let line of result) {
            this.opcoesVersoes.push({ "label": line["descricao"], "value": line["id"] });
          }
          if (this.versaoSelecionada == undefined && this.opcoesVersoes.length > 0) {
            this.versaoSelecionada = this.opcoesVersoes[0].value;
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  excluirIdadeNivRelac(row) {
    this.relacId = row.id;
    this.allPeriodsExclude = [true, false, false];
    this.modalFaixaExcluir = true;
  }

  deleteFaixaRelac() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'relacid': this.relacId.toString(),
        'allperiods': this.allPeriodsExclude.toString(),
        'versaoid': this.versaoSelecionada.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/beneficioidade', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.modalFaixaExcluir = false;
          this.getIdadeNivRelac();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  atuTabBenef() {
    this.getReajustes();
    if (!this.idadeEdit) {
      this.getBenefFuncRelac();
      this.getBeneCargoRelac();
    } else {
      this.getIdadeNivRelac();
    }

  }

  openAddIdadeRelac() {
    this.niveisSelecionados = null;
    this.idadeMinNew = null;
    this.idadeMaxNew = null;
    this.valorNew = null;
    this.valorDescNew = 0;
    this.modalFaixaNew = true;

  }

  onRowSelect(row){
    this.toolbarMain.setVisible('edit', true)
    this.toolbarMain.setVisible('delete', true)

    this.toolbarMain.setClick('edit', () => this.openEditBeneficio(row))
    this.toolbarMain.setClick('delete', () => this.deleteBeneficio(row))

    this.rowSelectedOnce = true;
    this.idEdit = row.id;
    this.comissaoEdit = row.comissao;
    this.idadeEdit = row.idade;
    this.rowSelected = true;
    this.getReajustes();
    if (!this.idadeEdit) {
      this.getBenefFuncRelac();
      this.getBeneCargoRelac();
    } else {
      this.getIdadeNivRelac();
    }
  }

  onRowUnselect(event){
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.rowSelected = false;
  }

  addFaixaRelac() {
    if (this.niveisSelecionados.length === 0) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "Selecione pelo menos um nível." });

    } else if (this.idadeMinNew === null) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Idade min' é obrigatório" });

    } else if (this.idadeMaxNew === null) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Idade max' é obrigatório" });

    } else if (this.valorNew === null) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Valor' é obrigatório" });

    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'nivelid': this.niveisSelecionados.toString(),
          'beneficioid': this.idEdit.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'valor': this.valorNew.toString(),
          'valordesc': this.valorDescNew.toString(),
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'ano': this.dblinkedSessionService.periodo.year.value.toString(),
          'mes': this.dblinkedSessionService.periodo.month.value.toString(),
          'idademin': this.idadeMinNew.toString(),
          'idademax': this.idadeMaxNew.toString(),
          'versaoid': this.versaoSelecionada.toString()
        })
      };
      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/beneficioidade', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.httpService.done();
            this.modalFaixaNew = false;
            this.getIdadeNivRelac();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  onIdadeNivSelect(row) {
    this.relacId = row.id;
    this.valorEdit = row.valor;
    this.valorDescEdit = row.valor_desc;
    this.valorProjEdit = row.valor_proj;
    this.allValor = false;
    this.allValorDesc = false;
    this.allValorProj = false;
    this.allPeriodsValor = [true, false, false];
    this.allPeriodsValorDesc = [true, false, false];
    this.allPeriodsValorProj = [true, false, false];
    this.modalFaixaEdit = true;
  }

  aplicarNivel() {
    if (this.aplicNivel === true) {
      this.niveisSelecionados = new Array();
      this.aplicNivelPy = false;
      this.getCargos()
    } else {
      this.cargoOpcoes = [];
      this.aplicNivelPy = true;
    }
  }

  deleteReajuste() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'reajusteid': this.reajusteIdEdit.toString(),
        'userid': this.sessionService.loggedUser.id.toString(),
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'versaoid': this.versaoSelecionada.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/reajustes', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.modalReajEdit = false;
          this.getReajustes();
          this.getBeneCargoRelac();
          this.getBenefFuncRelac();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  changePeriodsPercentMes(idx) {
    if (idx === 0) {
      this.allPeriodsPercentMes = [true, false, false];
    } else if (idx === 1) {
      this.allPeriodsPercentMes = [false, true, false];
    } else {
      this.allPeriodsPercentMes = [false, false, true];
    }
  }

  changePeriodsPercentTrim(idx) {
    if (idx === 0) {
      this.allPeriodsPercentTrim = [true, false, false];
    } else if (idx === 1) {
      this.allPeriodsPercentTrim = [false, true, false];
    } else {
      this.allPeriodsPercentTrim = [false, false, true];
    }
  }

  changePeriodsPercentMesProj(idx) {
    if (idx === 0) {
      this.allPeriodsPercentMesProj = [true, false, false];
    } else if (idx === 1) {
      this.allPeriodsPercentMesProj = [false, true, false];
    } else {
      this.allPeriodsPercentMesProj = [false, false, true];
    }
  }

  changePeriodsPercentTrimProj(idx) {
    if (idx === 0) {
      this.allPeriodsPercentTrimProj = [true, false, false];
    } else if (idx === 1) {
      this.allPeriodsPercentTrimProj = [false, true, false];
    } else {
      this.allPeriodsPercentTrimProj = [false, false, true];
    }
  }

  changePeriodsSalarioProj(idx) {
    if (idx === 0) {
      this.allPeriodsSalarioProj = [true, false, false];
    } else if (idx === 1) {
      this.allPeriodsSalarioProj = [false, true, false];
    } else {
      this.allPeriodsSalarioProj = [false, false, true];
    }
  }

  changePeriodsValor(idx) {
    if (idx === 0) {
      this.allPeriodsValor = [true, false, false];
    } else if (idx === 1) {
      this.allPeriodsValor = [false, true, false];
    } else {
      this.allPeriodsValor = [false, false, true];
    }
  }

  changePeriodsValorDesc(idx) {
    if (idx === 0) {
      this.allPeriodsValorDesc = [true, false, false];
    } else if (idx === 1) {
      this.allPeriodsValorDesc = [false, true, false];
    } else {
      this.allPeriodsValorDesc = [false, false, true];
    }
  }

  changePeriodsValorProj(idx) {
    if (idx === 0) {
      this.allPeriodsValorProj = [true, false, false];
    } else if (idx === 1) {
      this.allPeriodsValorProj = [false, true, false];
    } else {
      this.allPeriodsValorProj = [false, false, true];
    }
  }

  changeCargoPeriodsValor(idx) {
    if (idx === 0) {
      this.allCargoPeriodsValor = [true, false, false];
    } else if (idx === 1) {
      this.allCargoPeriodsValor = [false, true, false];
    } else {
      this.allCargoPeriodsValor = [false, false, true];
    }
  }

  changeCargoPeriodsValorDesc(idx) {
    if (idx === 0) {
      this.allCargoPeriodsValorDesc = [true, false, false];
    } else if (idx === 1) {
      this.allCargoPeriodsValorDesc = [false, true, false];
    } else {
      this.allCargoPeriodsValorDesc = [false, false, true];
    }
  }

  changeCargoPeriodsValorProj(idx) {
    if (idx === 0) {
      this.allCargoPeriodsValorProj = [true, false, false];
    } else if (idx === 1) {
      this.allCargoPeriodsValorProj = [false, true, false];
    } else {
      this.allCargoPeriodsValorProj = [false, false, true];
    }
  }

  changePeriodsExclude(idx) {
    if (idx === 0) {
      this.allPeriodsExclude = [true, false, false];
    } else if (idx === 1) {
      this.allPeriodsExclude = [false, true, false];
    } else {
      this.allPeriodsExclude = [false, false, true];
    }
  }

  getBeneCargoRelac() {
    const httpOptions = {headers: new HttpHeaders({'Authorization': this.configService.bearerApi})}

    this.httpService.wait()
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/beneficioscargorelac?beneficioid=' + this.idEdit + '&mes=' + this.dblinkedSessionService.periodo.month.value + '&ano=' + this.dblinkedSessionService.periodo.year.value + '&empresaid=' + this.dblinkedSessionService.empresa.id +
      '&comissao=' + this.comissaoEdit, httpOptions)
      .subscribe(
        res => {
          if (!res["error"]) {
            let result = res["mensagem"]
            this.cargoBenefLista = new Array()
            for (let line of result) {
              this.cargoBenefLista.push(line)
            }
          } else {
            this.cargoBenefLista = new Array()
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Aviso', detail: res["mensagem"] })
          }
          this.httpService.done()
        },
        err => {
          this.exibirMensagem(err)
          this.httpService.done()
        }
      );
  }

  getNiveis() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/niveis', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.niveisOpcoes = [];
          for (let line of result) {
            this.niveisOpcoes.push({ "label": line["codigo"] + " - " + line["nivel"], "value": line["id"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getCargos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'filnivel': this.aplicNivelPy.toString(),
        'niveisid': this.niveisSelecionados.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/cargos', httpOptions)
      .subscribe(
        res => {
          this.cargoOpcoes = [];
          if (res["error"] === false) {
            let result = res["mensagem"];
            for (let line of result) {
              this.cargoOpcoes.push({ "label": line["codigo"] ? line["codigo"] + " - " + line["descricao"] : line["descricao"], "value": line["id"] });
            }
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  addCargoRelac() {
    if (this.cargosSelecionados.length === 0) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "Selecione pelo menos um cargo." });

    } else {
      let httpOptions: any;
      if (!this.comissaoEdit) {
        httpOptions = {
          headers: new HttpHeaders({
            'Authorization': this.configService.bearerApi,
            'cargoid': this.cargosSelecionados.toString(),
            'beneficioid': this.idEdit.toString(),
            'userid': this.sessionService.loggedUser.id.toString(),
            'valor': this.valorCargoNew ? this.valorCargoNew.toString() : "0",
            'valordesc': this.valorCargoDescNew ? this.valorCargoDescNew.toString() : "0",
            'empresaid': this.dblinkedSessionService.empresa.id.toString(),
            'ano': this.dblinkedSessionService.periodo.year.value.toString(),
            'mes': this.dblinkedSessionService.periodo.month.value.toString(),
            'comissao': this.comissaoEdit.toString()
          })
        };
      } else {
        httpOptions = {
          headers: new HttpHeaders({
            'Authorization': this.configService.bearerApi,
            'cargoid': this.cargosSelecionados.toString(),
            'beneficioid': this.idEdit.toString(),
            'userid': this.sessionService.loggedUser.id.toString(),
            'percentmes': this.percentMesNew ? this.percentMesNew.toString() : "0",
            'percenttrim': this.percentTrimNew ? this.percentTrimNew.toString() : "0",
            'percentmesproj': this.percentMesProjNew ? this.percentMesProjNew.toString() : "0",
            'percenttrimproj': this.percentTrimProjNew ? this.percentTrimProjNew.toString() : "0",
            'empresaid': this.dblinkedSessionService.empresa.id.toString(),
            'ano': this.dblinkedSessionService.periodo.year.value.toString(),
            'mes': this.dblinkedSessionService.periodo.month.value.toString(),
            'comissao': this.comissaoEdit.toString(),
            'versaoid': this.versaoSelecionada.toString()
          })
        };
      }

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/beneficioscargorelac', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.httpService.done();
            this.modalCargoNew = false;
            this.modalCargoComNew = false;
            this.getBeneCargoRelac();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  deleteCargoRelac() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'relacid': this.cargoRelacId.toString(),
        'comissao': this.comissaoEdit.toString(),
        'allperiods': this.allPeriodsExclude.toString(),
        'versaoid': this.versaoSelecionada.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/beneficioscargorelac', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.modalCargoEdit = false;
          this.modalCargoComEdit = false;
          this.getBeneCargoRelac();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

    this.modalCargoExcluir = false;
  }

  editCargoRelac() {
    let httpOptions: any;
    if (!this.comissaoEdit) {
      httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'relacid': this.cargoRelacId.toString(),
          'valor': this.valorCargoEdit.toString(),
          'valordesc': this.valorCargoDescEdit.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'valorproj': this.valorCargoProjEdit.toString(),
          'allcargoval': this.allCargoValor.toString(),
          'allcargoperiodsval': this.allCargoPeriodsValor.toString(),
          'allcargovaldesc': this.allCargoValorDesc.toString(),
          'allcargoperiodsvaldesc': this.allCargoPeriodsValorDesc.toString(),
          'allcargovalproj': this.allCargoValorProj.toString(),
          'allcargoperiodsvalproj': this.allCargoPeriodsValorProj.toString(),
          'comissao': this.comissaoEdit.toString()
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'relacid': this.cargoRelacId.toString(),
          'percentmes': this.percentMesEdit.toString(),
          'percenttrim': this.percentTrimEdit.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'percentmesproj': this.percentMesProjEdit.toString(),
          'percenttrimproj': this.percentTrimProjEdit.toString(),
          'allcargopercentmes': this.allFuncPercentMes.toString(),
          'allcargopercenttrim': this.allFuncPercentTrim.toString(),
          'allcargopercentmesproj': this.allFuncPercentMesProj.toString(),
          'allcargopercenttrimproj': this.allFuncPercentTrimProj.toString(),
          'allcargoperiodspercentmes': this.allPeriodsPercentMes.toString(),
          'allcargoperiodspercenttrim': this.allPeriodsPercentTrim.toString(),
          'allcargoperiodspercentmesproj': this.allPeriodsPercentMesProj.toString(),
          'allcargoperiodspercenttrimproj': this.allPeriodsPercentTrimProj.toString(),
          'comissao': this.comissaoEdit.toString(),
          'versaoid': this.versaoSelecionada.toString()
        })
      };
    }

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/beneficioscargorelac', null, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.modalCargoEdit = false;
          this.modalCargoComEdit = false;
          this.getBeneCargoRelac();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  editFaixaRelac() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'relacid': this.relacId.toString(),
        'valor': this.valorEdit.toString(),
        'valordesc': this.valorDescEdit.toString(),
        'userid': this.sessionService.loggedUser.id.toString(),
        'valorproj': this.valorProjEdit.toString(),
        'allvalor': this.allValor.toString(),
        'allperiodsval': this.allPeriodsValor.toString(),
        'allvalordesc': this.allValorDesc.toString(),
        'allperiodsvaldesc': this.allPeriodsValorDesc.toString(),
        'allvalorproj': this.allValorProj.toString(),
        'allperiodsvalproj': this.allPeriodsValorProj.toString(),
        'versaoid': this.versaoSelecionada.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/beneficioidade', null, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.modalFaixaEdit = false;
          this.getIdadeNivRelac();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  onCargoRelacSelect(row) {
    this.cargoRelacId = row.id;
    if (!this.comissaoEdit) {
      this.valorCargoEdit = row.valor;
      this.valorCargoDescEdit = row.valor_desc;
      this.valorCargoProjEdit = row.valor_proj;
      this.allCargoValor = false;
      this.allCargoValorDesc = false;
      this.allCargoValorProj = false;
      this.allCargoPeriodsValor = [true, false, false];
      this.allCargoPeriodsValorDesc = [true, false, false];
      this.allCargoPeriodsValorProj = [true, false, false];
      this.modalCargoEdit = true;
    } else {
      this.percentMesEdit = row.percentual_mes;
      this.percentTrimEdit = row.percentual_trim;
      this.percentMesProjEdit = row.percentual_mes_proj;
      this.percentTrimProjEdit = row.percentual_trim_proj;
      this.salarioProjEdit = row.salario_proj;
      this.allFuncPercentMes = false;
      this.allFuncPercentTrim = false;
      this.allFuncPercentMesProj = false;
      this.allFuncPercentTrimProj = false;
      this.allPeriodsPercentMes = [true, false, false];
      this.allPeriodsPercentTrim = [true, false, false];
      this.allPeriodsPercentMesProj = [true, false, false];
      this.allPeriodsPercentTrimProj = [true, false, false];
      this.modalCargoComEdit = true;
    }
  }

  getFuncionarios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/funcionarios', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }
          this.funcOpcoes = []
          res["data"].forEach(row => {
            if (row["ativo"]) this.funcOpcoes.push({ label: row["codigo"] + " - " + row["nome"], value: row["id"] })
          })
          this.httpService.done()
        },
        error => {
          console.log(error)
          this.httpService.done()
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao obter os funcionários, contate o suporte: " + error.message })
        })
  }

  openAddFunc() {
    this.funcSelecionados = new Array();
    if (!this.comissaoEdit) {
      this.valorNew = null;
      this.valorDescNew = null;
      this.modalFuncNew = true;
    } else {
      this.percentMesNew = null;
      this.percentTrimNew = null;
      this.percentMesProjNew = null;
      this.percentTrimProjNew = null;
      //this.salarioBaseProjNew = null;
      this.modalFuncComNew = true;
    }
  }

  addFuncRelac() {
    if (this.funcSelecionados.length === 0) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "Selecione pelo menos um funcionário." });

    } else {

      let httpOptions: any;
      if (!this.comissaoEdit) {
        httpOptions = {
          headers: new HttpHeaders({
            'Authorization': this.configService.bearerApi,
            'funcid': this.funcSelecionados.toString(),
            'beneficioid': this.idEdit.toString(),
            'userid': this.sessionService.loggedUser.id.toString(),
            'valor': this.valorNew.toString(),
            'valordesc': this.valorDescNew.toString(),
            'empresaid': this.dblinkedSessionService.empresa.id.toString(),
            'ano': this.dblinkedSessionService.periodo.year.value.toString(),
            'mes': this.dblinkedSessionService.periodo.month.value.toString(),
            'comissao': this.comissaoEdit.toString(),
            'versaoid': this.versaoSelecionada.toString()
          })
        };
      } else {
        httpOptions = {
          headers: new HttpHeaders({
            'Authorization': this.configService.bearerApi,
            'funcid': this.funcSelecionados.toString(),
            'beneficioid': this.idEdit.toString(),
            'userid': this.sessionService.loggedUser.id.toString(),
            'percentmes': this.percentMesNew.toString(),
            'percenttrim': this.percentTrimNew.toString(),
            'percentmesproj': this.percentMesProjNew.toString(),
            'percenttrimproj': this.percentTrimProjNew.toString(),
            'empresaid': this.dblinkedSessionService.empresa.id.toString(),
            'ano': this.dblinkedSessionService.periodo.year.value.toString(),
            'mes': this.dblinkedSessionService.periodo.month.value.toString(),
            'comissao': this.comissaoEdit.toString(),
            'versaoid': this.versaoSelecionada.toString()
          })
        };
      }
      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/beneficiosfuncrelac', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.httpService.done();
            this.modalFuncComNew = false;
            this.modalFuncNew = false;
            this.getBenefFuncRelac();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  addBeneficio() {
    if (this.codigoNew === "" || this.codigoNew === null || this.codigoNew == undefined) {
      this.exibirMensagem({ "error": true, "mensagem": "O código do benefício é obrigatório." })
    }
    else if (this.beneficioNew === "" || this.beneficioNew === null || this.beneficioNew == undefined) {
      this.exibirMensagem({ "error": true, "mensagem": "O nome do benefício é obrigatório." })
    } else {

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'nome': this.beneficioNew.toString(),
          'codigo': this.codigoNew.toString(),
          'descricao': this.descricaoNew.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'comissao': this.comissaoNew.toString(),
          'idade': this.idadeNew.toString(),
          'valorPiso': (this.comissaoNew && this.possuiPisoNew) ? this.valorPisoNew.toString() : "0,00",
          'dependentes': this.possuiDependentesNew.toString(),
          'idademax': (this.dependentesIdadeMaxNew && this.possuiDependentesNew) ? this.dependentesIdadeMaxNew.toString() : '0',
        })
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/beneficios', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.getBeneficios();
            this.httpService.done();
            this.modalNew = false;
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  getBeneficios() {
    this.toolbarInitial()
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/beneficios', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.beneficiosLista = new Array();
          for (let line of result) {
            this.beneficiosLista.push(line);
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  toolbarEnable() {
    this.toolbarService.clear();
    this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Novo segmento', () => this.openAddBeneficio());
  }

  openEditBeneficio(row) {
    this.modalEdit = true;
    this.idEdit = row.id;
    this.codigoEdit = row.codigo;
    this.descricaoEdit = row.descricao;
    this.beneficioEdit = row.nome;
    this.comissaoEdit = row.comissao;
    this.possuiPisoEdit = (row.valorPiso !== 0.0);
    this.valorPisoEdit = row.valorPiso;
    this.possuiDependentesEdit = row.dependentes;
    this.dependentesIdadeMaxEdit = row.dependentesIdadeMax;
  }

  openAddBeneficio() {
    this.modalNew = true;
    this.codigoNew = "";
    this.descricaoNew = "";
    this.beneficioNew = "";
    this.comissaoNew = false;
    this.idadeNew = false;
    this.valorPisoNew = null;
    this.possuiPisoNew = false;
  }

  hideMessage() {


  }

  getIdadeNivRelac() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'beneficioid': this.idEdit.toString(),
        'mes': this.dblinkedSessionService.periodo.month.value.toString(),
        'ano': this.dblinkedSessionService.periodo.year.value.toString(),
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/beneficioidade', httpOptions)
      .subscribe(
        res => {
          if (!res["error"]) {
            let result = res["mensagem"];
            this.idadeNivBenefLista = new Array();
            for (let line of result) {
              this.idadeNivBenefLista.push(line);
            }
          } else {
            this.idadeNivBenefLista = new Array();

            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Aviso', detail: res["mensagem"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  openAddCargo() {
    this.cargosSelecionados = new Array();
    if (!this.comissaoEdit) {
      this.valorCargoNew = null;
      this.valorCargoDescNew = null;
      this.modalCargoNew = true;
    } else {
      this.percentMesNew = null;
      this.percentTrimNew = null;
      this.percentMesProjNew = null;
      this.percentTrimProjNew = null;
      //this.salarioBaseProjNew = null;
      this.modalCargoComNew = true;
    }
  }

  onFuncRelacSelect(row) {
    this.funcRelacId = row.id;
    if (!this.comissaoEdit) {
      this.valorEdit = row.valor;
      this.valorDescEdit = row.valor_desc;
      this.valorProjEdit = row.valor_proj;
      this.allFuncValor = false;
      this.allFuncValorDesc = false;
      this.allFuncValorProj = false;
      this.allPeriodsValor = [true, false, false];
      this.allPeriodsValorDesc = [true, false, false];
      this.allPeriodsValorProj = [true, false, false];
      this.modalFuncEdit = true;
    } else {
      this.percentMesEdit = row.percentual_mes;
      this.percentTrimEdit = row.percentual_trim;
      this.percentMesProjEdit = row.percentual_mes_proj;
      this.percentTrimProjEdit = row.percentual_trim_proj;
      this.allFuncPercentMes = false;
      this.allFuncPercentTrim = false;
      this.allFuncPercentMesProj = false;
      this.allFuncPercentTrimProj = false;
      this.allPeriodsPercentMes = [true, false, false];
      this.allPeriodsPercentTrim = [true, false, false];
      this.allPeriodsPercentMesProj = [true, false, false];
      this.allPeriodsPercentTrimProj = [true, false, false];
      this.modalFuncComEdit = true;
    }
  }

  excluirCargoRelac(row) {
    this.cargoRelacId = row.id;
    this.allPeriodsExclude = [true, false, false];
    this.modalCargoExcluir = true;
  }

  excluirFuncRelac(row) {
    this.funcRelacId = row.id;
    this.allPeriodsExclude = [true, false, false];
    this.modalFuncExcluir = true;
  }

  deleteFuncRelac() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'relacid': this.funcRelacId.toString(),
        'comissao': this.comissaoEdit.toString(),
        'allperiods': this.allPeriodsExclude.toString(),
        'versaoid': this.versaoSelecionada.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/beneficiosfuncrelac', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.modalFuncExcluir = false;
          this.modalFuncComEdit = false;
          this.getBenefFuncRelac();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  editFuncRelac() {
    let httpOptions: any;
    if (!this.comissaoEdit) {
      httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'relacid': this.funcRelacId.toString(),
          'valor': this.valorEdit.toString(),
          'valordesc': this.valorDescEdit.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'valorproj': this.valorProjEdit.toString(),
          'allfuncval': this.allFuncValor.toString(),
          'allperiodsval': this.allPeriodsValor.toString(),
          'allfuncvaldesc': this.allFuncValorDesc.toString(),
          'allperiodsvaldesc': this.allPeriodsValorDesc.toString(),
          'allfuncvalproj': this.allFuncValorProj.toString(),
          'allperiodsvalproj': this.allPeriodsValorProj.toString(),
          'comissao': this.comissaoEdit.toString(),
          'versaoid': this.versaoSelecionada.toString()
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'relacid': this.funcRelacId.toString(),
          'percentmes': this.percentMesEdit.toString(),
          'percenttrim': this.percentTrimEdit.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'percentmesproj': this.percentMesProjEdit.toString(),
          'percenttrimproj': this.percentTrimProjEdit.toString(),
          'allfuncpercentmes': this.allFuncPercentMes.toString(),
          'allfuncpercenttrim': this.allFuncPercentTrim.toString(),
          'allfuncpercentmesproj': this.allFuncPercentMesProj.toString(),
          'allfuncpercenttrimproj': this.allFuncPercentTrimProj.toString(),
          'allperiodspercentmes': this.allPeriodsPercentMes.toString(),
          'allperiodspercenttrim': this.allPeriodsPercentTrim.toString(),
          'allperiodspercentmesproj': this.allPeriodsPercentMesProj.toString(),
          'allperiodspercenttrimproj': this.allPeriodsPercentTrimProj.toString(),
          'comissao': this.comissaoEdit.toString(),
          'versaoid': this.versaoSelecionada.toString()
        })
      };
    }

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/beneficiosfuncrelac', null, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.modalFuncEdit = false;
          this.modalFuncComEdit = false;
          this.getBenefFuncRelac();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getBenefFuncRelac() {
    const httpOptions = {headers: new HttpHeaders({'Authorization': this.configService.bearerApi,
        'beneficioid': this.idEdit.toString(),
        'mes': this.dblinkedSessionService.periodo.month.value.toString(),
        'ano': this.dblinkedSessionService.periodo.year.value.toString(),
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'comissao': this.comissaoEdit.toString(),
        'versao': this.versaoSelecionada.toString()
      })
    };

    this.httpService.wait()
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/beneficiosfuncrelac?beneficioid=' + this.idEdit + '&mes=' + this.dblinkedSessionService.periodo.month.value + '&ano=' + this.dblinkedSessionService.periodo.year.value +
      '&empresaid=' + this.dblinkedSessionService.empresa.id + '&comissao=' + this.comissaoEdit + '&versao=' + this.versaoSelecionada, httpOptions)
      .subscribe(
        res => {
          if (!res["error"]) {
            let result = res["mensagem"]
            this.funcBenefLista = new Array()
            for (let line of result) {
              this.funcBenefLista.push(line)
            }
          } else {
            this.funcBenefLista = new Array()
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Aviso', detail: res["mensagem"] });
          }
          this.httpService.done()
        },
        err => {
          this.exibirMensagem(err)
          this.httpService.done()
        }
      );
  }

  deleteBeneficio(row) {
    if (confirm("Isso irá excluir o benefício, tem certeza?")) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'beneficioid': row.id.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/beneficios', httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.httpService.done();
            this.modalEdit = false;
            this.getBeneficios();
            this.rowSelected = false;
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  editBeneficio() {
    if (this.codigoEdit === "" || this.codigoEdit === null || this.codigoEdit == undefined) {
      this.exibirMensagem({ "error": true, "mensagem": "O código do benefício é obrigatório." })
    }
    else if (this.beneficioEdit === "" || this.beneficioEdit === null || this.beneficioEdit == undefined) {
      this.exibirMensagem({ "error": true, "mensagem": "O nome do benefício é obrigatório." })
    } else {

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'nome': this.beneficioEdit.toString(),
          'codigo': this.codigoEdit.toString(),
          'descricao': this.descricaoEdit.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'beneficioid': this.idEdit.toString(),
          'valorPiso': (this.comissaoEdit && this.possuiPisoEdit) ? this.valorPisoEdit.toString() : "0,00",
          'dependentes': this.possuiDependentesEdit.toString(),
          'idadeMax': (this.possuiDependentesEdit && this.dependentesIdadeMaxEdit) ? this.dependentesIdadeMaxEdit.toString() : '0'

        })
      };

      this.httpService.wait();
      this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/beneficios', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.httpService.done();
            this.modalEdit = false;
            this.getBeneficios();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  addReajuste() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi })}

    const infos = {
      beneficioId: this.idEdit,
      percentual: this.percentualNew,
      dtReajuste: this.dtReajusteNew.getFullYear() + '-' + (this.dtReajusteNew.getMonth() + 1) + '-' + this.dtReajusteNew.getDate(),
      userId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id,
      versaoId: this.versaoSelecionada
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/reajustes', infos, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
          this.modalReajNew = false
          this.getReajustes()
          this.getBeneCargoRelac()
          this.getBenefFuncRelac()
        },
        err => {
          this.exibirMensagem(err)
          this.httpService.done()
        }
      );
  }

  editReajuste() {
    if (confirm('Essa ação irá recalcular todos os reajustes, pode levar alguns minutos. Confirma?')) {
      const httpOptions = {
        headers: new HttpHeaders({'Authorization': this.configService.bearerApi})}

      const payload = {
        reajusteId: this.reajusteIdEdit,
        percentual: this.percentualEdit,
        dtReajuste: this.dtReajusteEdit.getFullYear() + '-' + (this.dtReajusteEdit.getMonth() + 1) + '-' + this.dtReajusteEdit.getDate(),
        userId: this.sessionService.loggedUser.id,
        empresaId: this.dblinkedSessionService.empresa.id,
        versaoId: this.versaoSelecionada
      }

      this.httpService.wait()
      this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/reajustes', payload, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res)
            this.modalReajEdit = false
            this.httpService.done()
            this.getReajustes()
            this.getBenefFuncRelac()
            this.getBeneCargoRelac()
          },
          err => {
            this.exibirMensagem(err)
            this.httpService.done()
          }
        );
    }
  }

  openAddReajuste() {
    this.percentualNew = null;
    this.dtReajusteNew = new Date();
    this.modalReajNew = true;
  }

  onReajSelect(row) {
    this.percentualEdit = row.percentual;
    this.dtReajusteEdit = new Date(row.dt_reaj + "-0300");
    this.reajusteIdEdit = row.id;
    this.modalReajEdit = true;
  }

  getReajustes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'beneficioid': this.idEdit.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/reajustes', httpOptions)
      .subscribe(
        res => {
          this.reajusteLista = new Array();
          for (var line of res["mensagem"]) {
            this.reajusteLista.push(line);
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  comissaoOuIdade(idx) {
    if (idx === 0) {
      this.idadeNew = false;
      this.possuiDependentesNew = false;
    } else if (idx === 1) {
      this.comissaoNew = false;
      this.possuiPisoNew = false;
    }
  }

  /*Show Hide tabela*/
  isCardBenef: boolean = true;
  ShowHIdeCardBenef() {
    this.isCardBenef = !this.isCardBenef;
  }

  currentStateBenef = 'initial';
  changeStateBenef() {
    this.currentStateBenef = this.currentStateBenef === 'initial' ? 'final' : 'initial';
  }

  fullBenef: boolean = false;
  fullScreenBenef() {
    this.fullBenef = !this.fullBenef;
  }

  /*Show Hide detalhes*/
  isCardDetal: boolean = true;
  ShowHIdeCardDetal() {
    this.isCardDetal = !this.isCardDetal;
    this.toolbarFunc.setIcon('expand', this.isCardDetal ? 'expand_less' : 'expand_more')
    this.toolbarNivel.setIcon('expand', this.isCardDetal ? 'expand_less' : 'expand_more')
    this.toolbarReajuste.setIcon('expand', this.isCardDetal ? 'expand_less' : 'expand_more')
    this.toolbarCargo.setIcon('expand', this.isCardDetal ? 'expand_less' : 'expand_more')
    this.toolbarFunc.setTooltip('expand', this.isCardDetal ? 'Ver menos' : 'Ver mais')
    this.toolbarNivel.setTooltip('expand', this.isCardDetal ? 'Ver menos' : 'Ver mais')
    this.toolbarReajuste.setTooltip('expand', this.isCardDetal ? 'Ver menos' : 'Ver mais')
    this.toolbarCargo.setTooltip('expand', this.isCardDetal ? 'Ver menos' : 'Ver mais')
  }

  currentStateDetal = 'initial';
  changeStateDetal() {
    this.currentStateDetal = this.currentStateDetal === 'initial' ? 'final' : 'initial';
  }

  fullDetal: boolean = false;
  fullScreenDetal() {
    this.fullDetal = !this.fullDetal;
  }

  statusTela1: boolean = true;
  statusTela2: boolean = false;
  statusTela3: boolean = false;

  showTelaBeneficios(tipo) {
    this.statusTela1 = false;
    this.statusTela2 = false;
    this.statusTela3 = false;

    if (tipo === 'TELA_1') {
      this.statusTela1 = true;

    } else if (tipo === 'TELA_2') {
      this.statusTela2 = true;

    } else if (tipo === 'TELA_3') {
      this.statusTela3 = true;

    }
  }


  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return [10, 20, 50, 100]
  }


}
