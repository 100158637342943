import { Component, OnInit } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ConfigService } from 'app/shared/services/config.service';
import { Subject } from 'rxjs';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TipoBaixa } from 'app/util/model/tipo-baixa.model';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router'
import { UtilService } from 'app/shared/services/util.service';
import { CalendarEvent } from 'angular-calendar';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})

export class CalendarComponent implements OnInit {

  public calendar: Array<CalendarComponent> = new Array();
  public events: Array<any> = new Array();

  opcaoDataBanco: Array<any>;
  selectBaixa: Array<any>;

  colors = {
    red: {
      primary: '#ff2222',
    },
    darkRed: {
      primary: '#992222',
    },
    blue: {
      primary: '#1e90ff',
    },
    darkBlue: {
      primary: '#488A99',
    },
    default: {
      primary: '#ffffff',
    },
  };

  //Boolean
  modalNovo: boolean = false;
  modalEdit: boolean = false;
  modalBaixar: boolean = false;
  showCalendar: boolean = false;
  isTipoBaixa: boolean = true;


  // any
  formatarWeek: any
  currentDate: any
  currentDay: any
  dataIniSearch: any
  dataFinSearch: any
  selectedOption: any;
  currentInformation: any[] = new Array();
  currentInformationPagamento: any[] = new Array();
  movimentacaoTotal: any

  //Calendario
  listaEventos: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();

  //strings
  view: any;
  viewMonth: string = 'month';
  title: string = '';
  color: string = '';
  periodo: string = '';
  calendarioUrl: string = '';
  linkCalendario: string = '';
  filtrarLink: any

  //date
  viewDate: Date = new Date(Date.now());
  defaultDate: Date;
  dataIni: Date;
  dataFin: Date;
  editData: Date;
  dataBaixa: Date;
  dayAtual: any
  totalReceb: any;
  totalRecebimento: any
  totalPagamento: any
  totalMovimentacao: any
  eventList: any
  formatPagamento: any
  formatRecebimento: any
  dtvencimentoNew: Date = new Date()
  choicedDate: Date = new Date()


  // number
  pagamento: any;
  recebimento: any;
  movimentacao: any;
  editId: number;
  idBaixa: any;

  //select
  opcoesTiposBaixa: SelectItem[];
  opcoesContaBancaria: SelectItem[];

  tipoBaixa: TipoBaixa;
  contaSelecionada: EmpresaConta;

  private sessionSubscription: Subscription;

  constructor(
    private httpService: HttpService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private router: Router,
    private httpClient: HttpClient,
    private utilService: UtilService,
  ) { }

  ngOnInit() {
    this.calendarioUrl = this.configService.defaultUrlApiUpload;
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.defaultDate = new Date();
    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;

    let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    let mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    this.periodo = (this.defaultDate.getMonth() + 1) + "-" + this.defaultDate.getFullYear()
    this.formatarWeek = {
      "Sun": "Domingo",
      "Mon": "Segunda-Feira",
      "Tue": "Terça-Feira",
      "Wed": "Quarta-Feira",
      "Thu": "Quinta-Feira",
      "Fri": "Sexta-Feira",
      "Sat": "Sábado"
    }

    this.dblinkedSessionService.loadEmpresas();
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {

          let year = this.dblinkedSessionService.periodo.year.value,
            month = this.dblinkedSessionService.periodo.month.value - 1;
          this.choicedDate = new Date(year, month, 1);
          this.viewDate = new Date(year, month, 1);

          this.getEventos();
          this.loadContasBancarias();

        }
      });
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  onEventClick(data) {
    this.showCalendar = true;
    this.currentDay = data.date
    const translateDate = data.date.toString().split(' ')
    this.currentDate = this.formatarWeek[translateDate[0]], this.formatarWeek[translateDate[2]]

    this.totalRecebimento = 0;
    this.totalPagamento = 0;

    this.currentInformation = [];
    this.currentInformationPagamento = [];

    this.events.forEach(event => {
      if (this.utilService._timestampToStringDateTime2(event.dt_vencimento) == this.utilService._timestampToStringDateTime2(data.date)) {
        if (['CR', 'recebimento'].includes(event.rorp)) {
          this.currentInformation.push(event);
          this.totalRecebimento += this.utilService.parseMoney(event.valor);
        } else if (['CP', 'pagamento'].includes(event.rorp)) {
          this.currentInformationPagamento.push(event);
          this.totalPagamento += this.utilService.parseMoney(event.valor);
        }
        this.viewDate = data.date;
      }
    });

    this.totalMovimentacao = this.totalRecebimento + this.totalPagamento;
    this.formatRecebimento = this.totalRecebimento;
    this.formatPagamento = this.totalPagamento;
    this.movimentacaoTotal = this.totalMovimentacao;

  }

  renderMarks() {
    try {
      let previousDate = {};

      const eventInsert = (event) => {
        let color = this.toMarkFormat(event);
        let key = this.utilService._timestampToStringDateTime2(event.dt_vencimento)
        this.eventList.push({
          ...event,
          color,
        })

        if (previousDate[key] == undefined)
          previousDate[key] = [color.primary]
        else
          previousDate[key].push(color.primary)
      }

      const wasInserted = (event) => {
        if (previousDate[this.utilService._timestampToStringDateTime2(event.dt_vencimento)] == undefined)
          return false
        return previousDate[this.utilService._timestampToStringDateTime2(event.dt_vencimento)].includes(this.toMarkFormat(event).primary)
      }

      this.events.forEach(event => {
        if (Object.keys(previousDate).length == 0 || !wasInserted(event)) {
          eventInsert(event)
        }
      });

    } catch (error) {
      console.log(error)
    }
  }

  toMarkFormat(event) {
    let color = this.colors.default;

    if (['CP', 'pagamento'].includes(event.rorp) && ['BAIXADO', 'PROVISÃO', 'PROV'].includes(event.status)) {
      color = this.colors.darkRed;

    } else if (['CP', 'pagamento'].includes(event.rorp) && event.status == 'EM ABERTO') {
      color = this.colors.red;

    } else if (['CR', 'recebimento'].includes(event.rorp) && event.status == 'BAIXADO') {
      color = this.colors.darkBlue;

    } else if (['CR', 'recebimento'].includes(event.rorp) && event.status == 'EM ABERTO') {
      color = this.colors.blue;

    }

    return color;
  }

  getEventos() {
    this.showCalendar = false;
    this.httpService.wait()
    this.httpService.get(this.configService.defaultUrlApiUpload + 'custom/financeiro/calendario/get-eventos', [this.dblinkedSessionService.empresa.id, this.periodo])
      .subscribe(
        res => {
          this.events = new Array();
          this.eventList = new Array();
          this.calendarioUrl = this.configService.defaultUrlApiUpload + 'custom/financeiro/calendario/exportar-eventos/' + res[0].empresa_hash;
          res.forEach(item => {
            let valor = (item.valor)
            let descricao = item.descricao
            let nome = item.nome
            const date = new Date(item.dt_vencimento.toString());
            date.setDate(date.getDate() + 1);
            this.events.push({
              ...item,
              title: 'R$' + valor + ' - ' + descricao + ' - ' + nome,
              dt_vencimento: date,
              start: date,
              view: item.nfe_id ? 'NF' : 'FI'
            })
          })
          this.renderMarks();
        },
        err => { this.httpService.handleError(err, () => console.log(err)) },
        () => {
          this.httpService.done();
          this.refresh.next();
        }
      )
  }

  eventClickReceb() {
    this.router.navigateByUrl('receb-pagto/cr/baixar/' + this.filtrarLink);
  }

  eventClickPag() {
    this.router.navigateByUrl('receb-pagto/cp/baixar/' + this.filtrarLink);
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  openNovo() {
    this.modalNovo = true
  }

  openEdit(event) {
    this.selectedOption = null;
    this.opcaoDataBanco = new Array();
    this.editData = event.start;
    this.editId = event.id;
    this.view = event.view;
    this.opcaoDataBanco.push({ label: 'Selecione', value: '' });
    this.opcaoDataBanco.push({ label: 'Data Vencimento', value: 'alteraData' });
    this.opcaoDataBanco.push({ label: 'Conta bancária', value: 'alteraConta' });
    this.modalEdit = true
  }

  loadTipoBaixa(array: Array<TipoBaixa>) {
    this.tipoBaixa = new TipoBaixa();
    this.tipoBaixa.id = 0;
    this.opcoesTiposBaixa = [];
    array.forEach(tipoBaixa => {
      this.opcoesTiposBaixa.push({ label: tipoBaixa.descricao, value: tipoBaixa.id });
    });
  }

  loadContasBancarias() {
    this.httpService.wait();
    this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,instituicaoFinanceira,tipoConta/id'
      + '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id
      + '&$orderby=nome')
      .subscribe(ref => {
        this.contaSelecionada = new EmpresaConta();
        this.opcoesContaBancaria = [];
        ref.value.forEach(conta => {
          this.opcoesContaBancaria.push({ label: conta.nome, value: conta.id });
        });
      },
        error => this.httpService.handleError(error, () => this.openEdit(event)),
        () => this.httpService.done());
  }

  atualizarEvento() {

    const atualiza = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'data': this.dtvencimentoNew.getFullYear() + "-" + (this.dtvencimentoNew.getMonth() + 1) + "-" + this.dtvencimentoNew.getDate(),
        'id': this.editId.toString(),
        'userid': this.sessionService.loggedUser.id.toString(),
        'view': this.view.toString(),
        'conta': this.contaSelecionada.toString()
      })
    };

    this.modalEdit = false;
    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'financeiro/calendario/editevento', null, atualiza)
      .subscribe(
        (res: any) => {
          this.exibirMensagem(res);
          this.getEventos();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
        }, () => {
          this.httpService.done();
        })
  }

  openBaixarEvento(event) {
    this.tipoBaixa = new TipoBaixa();
    this.isTipoBaixa = true;
    this.idBaixa = event.id;

    this.httpService.wait();
    this.httpService.get('/tipobaixa?$select=id,descricao'
      + '&$orderby=descricao')
      .subscribe(ref => this.loadTipoBaixa(ref.value),
        error => this.httpService.handleError(error, () => this.openBaixarEvento(event)),
        () => this.httpService.done());

    this.modalBaixar = true;
  }

  prebaixa() {
    if (confirm('Confirma baixa?')) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'id': this.idBaixa.toString(),
          'databaixa': this.dataBaixa.toString(),
          'data': this.dataBaixa.toString(),
        })
      };

      this.httpService.wait();
      this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/calendario/getelementosbaixar', httpOptions)
        .subscribe(
          (res: any) => {
            if (res.error)
              this.exibirMensagem(res);
            else
              this.baixar(res["mensagem"]);
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
          },
          () => {
            this.httpService.done();
          }
        );
    }
  }

  baixar(elements) {
    this.modalBaixar = false;
    const listasEnviar = {
      nfeFinId: elements.id,
      valorBaixa: elements.valor,
      valorMulta: 0,
      valorJuros: 0,
      valorDesconto: 0,
      dataBaixa: elements.dataBaixa,
      dataNovoVencimento: null,
      tipoBaixa: this.tipoBaixa,
      empresaConta: elements.empresacontaid,
      usuario: this.sessionService.loggedUser.id,
      valorCompensado: 0,
      rateio: null,
      rateioJurosMulta: 0,
      titulosQueForamCompensados: []
    };

    this.httpService.wait();
    this.httpService.post('/custom/financeiro/baixar-titulo', listasEnviar)
      .subscribe(result => {
        this.exibirMensagem(result[0]);
        this.getEventos();
        this.modalBaixar = false;
      },
        error => this.httpService.handleError(error, () => this.baixar(elements)),
        () => this.httpService.done());
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({
        severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem
      });
    } else {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem
      });
    }
  }

  onEditFieldChange(item) {
    this.selectedOption = item;
    if (item === 'alteraConta') {
    }
  }

  checkTipoBaixa(value: any) {

    if (value != null && value != undefined && value > 0) {
      this.isTipoBaixa = false;
    } else {
      this.isTipoBaixa = true;
    }

  }

}




