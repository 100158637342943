import { TipoLancamento } from 'app/util/model/tipo-lancamento.model';
import { Natureza } from 'app/util/model/natureza.model';
import { FormalizacaoConciliacao } from 'app/contabil/model/formalizacao-conciliacao.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { TipoAjustesUsGaap } from 'app/util/model/tipo-ajustes-us-gaap';
import { PlanoContas } from './plano-contas.model';
import { CadCcus } from './cad-ccus.model';

export class LancamentosFormalizacao {
  id: number;
  dataLcto: Date;
  descricao: string;
  tipoLancamento: TipoLancamento;
  natureza: Natureza;
  valor: number;
  planoContas: PlanoContas;
  formalizacaoConciliacao: FormalizacaoConciliacao;
  usuario: Usuario;
  dataFim: Date;
  documento: string;
  obs: string;
  dataCompetencia: Date;
  tipoAjustesUsGaap: TipoAjustesUsGaap;
  planoContasCredito: PlanoContas;
  excluido: boolean;
  tipoPartida: string;
  cadCcus: CadCcus;

  constructor(lancamentosformalizacao?: LancamentosFormalizacao | number) {
    if (lancamentosformalizacao !== undefined && lancamentosformalizacao !== null) {
      if (typeof lancamentosformalizacao === 'number') {
        this.id = lancamentosformalizacao;
      } else {
        this.id = lancamentosformalizacao.id;
        this.dataLcto = lancamentosformalizacao.dataLcto;
        this.descricao = lancamentosformalizacao.descricao;
        this.tipoLancamento = lancamentosformalizacao.tipoLancamento;
        this.natureza = lancamentosformalizacao.natureza;
        this.valor = lancamentosformalizacao.valor;
        this.planoContas = lancamentosformalizacao.planoContas;
        this.formalizacaoConciliacao = lancamentosformalizacao.formalizacaoConciliacao;
        this.usuario = lancamentosformalizacao.usuario;
        this.dataFim = lancamentosformalizacao.dataFim;
        this.documento = lancamentosformalizacao.documento;
        this.obs = lancamentosformalizacao.obs;
        this.dataCompetencia = lancamentosformalizacao.dataCompetencia;
        this.tipoAjustesUsGaap = lancamentosformalizacao.tipoAjustesUsGaap;
        this.planoContasCredito = lancamentosformalizacao.planoContasCredito;
        this.excluido = lancamentosformalizacao.excluido;
        this.tipoPartida = lancamentosformalizacao.tipoPartida;
        this.cadCcus = lancamentosformalizacao.cadCcus;
      }
    }
  }
}
