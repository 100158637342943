import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel'
import { Toolbar } from 'app/shared/model/toolbar.model'
import { CnpjPipe } from 'app/shared/pipes/cnpj.pipe'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpService } from 'app/shared/services/http.service'
import { SessionService } from 'app/shared/services/session.service'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { MessageService, SelectItem } from 'primeng/api'
import { Subscription } from 'rxjs'
import { Mediation } from '../interfaces/mediation'
import { TimerCard } from '../interfaces/timerCard'
import { TimerStatus } from '../interfaces/timerStatus'
import { resetMediation } from '../utils/reset-mediation'

@Component({
  selector: 'app-cfg-api-adm',
  templateUrl: './cfg-api-adm.component.html',
  styleUrls: ['./cfg-api-adm.component.scss']
})
export class CfgApiAdmComponent implements OnInit, OnDestroy {

  protected sessionSubscription: Subscription

  httpOptions: Object = new Object()

  title: string

  tableData: Array<Mediation> = new Array()
  optLevy: Array<any> = new Array()

  currentScreen: string

  optCompany: SelectItem[] = new Array()
  optParticipantProviders: SelectItem[] = new Array()
  optParticipantClient: SelectItem[] = new Array()
  optDebitOperations: SelectItem[] = new Array()
  optCreditOperations: SelectItem[] = new Array()
  optParticipantProvidersTable: SelectItem[] = new Array()
  optParticipantClientTable: SelectItem[] = new Array()
  optDebitOperationsTable: SelectItem[] = new Array()
  optCreditOperationsTable: SelectItem[] = new Array()

  mediation: Mediation = resetMediation()
  mediationSelected: Mediation

  empresaId: number = null
  userId: number = null

  optLevyLoading: boolean = true
  optIconLevyLoading: boolean = false
  optCompanyLoading: boolean = true
  optIconCompanyLoading: boolean = false
  optParticipantLoading: boolean = true
  optIconParticipantLoading: boolean = false
  optOperationLoading: boolean = true
  optIconOperationLoading: boolean = false
  showModal: boolean = false
  isSaveLoading: boolean = false
  isCompanySelected: boolean = false

  labelCompany: string = 'Selecione'

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'CNPJ', size: '8%', textAlign: 'left' },
      { name: 'Razão Social', size: '15%', textAlign: 'left' },
      { name: 'Fornecedor', size: '12%', textAlign: 'left' },
      { name: 'Operação débito', size: '10%', textAlign: 'left' },
      { name: 'Cliente', size: '12%', textAlign: 'left' },
      { name: 'Operação crédito', size: '10%', textAlign: 'left' },
      { name: 'Tipo QR Code', size: '8%', textAlign: 'left' },
      { name: 'Valor QR Code', size: '6%', textAlign: 'right' },
      { name: 'Tipo cashout', size: '8%', textAlign: 'left' },
      { name: 'Valor cashout', size: '6%', textAlign: 'right' }
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true
  })

  // Conciliadora vars
  conciliadoraTimersList: Array<TimerStatus> = new Array()

  // Matera vars
  materaTimersList: Array<TimerCard> = new Array()

  // EyeMobile vars
  eyeMobileTimersList: Array<TimerCard> = new Array()

  // BS2 vars
  bs2TimersList: Array<TimerCard> = new Array()

  // PayLivre vars
  payLivreTimersList: Array<TimerCard> = new Array()


  public toolbarMain = new Toolbar()

  constructor(
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private messageService: MessageService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private cnpjPipe: CnpjPipe
  ) { this.toolbarService.hidden = true }

  public ngOnInit(): void {
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) this.initializeMatera()
    })
  }

  public ngOnDestroy(): void {
    this.sessionSubscription.remove(this.sessionSubscription)
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  get rowsPerPage(): Array<any> {
    return this.configService.applicationConfig.rowsPerPage
  }

  get rows(): number {
    return this.configService.applicationConfig.rows
  }

  get pageLinks(): number {
    return this.configService.applicationConfig.pageLinks
  }

  // TELA MATERA
  public async initializeMatera(): Promise<void> {
    this.title = "Configuração de PIX mediador"
    this.currentScreen = 'MAT'

    this.empresaId = this.dblinkedSessionService.empresa.id
    this.userId = this.sessionService.loggedUser.id
    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }

    this.toolbarMain.setAll([
      { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar mediação', visible: false, disabled: false, color: 'default', onClick: () => this.openingModal(true) },
      { key: 'btn-new', icon: 'add', text: 'Nova', tooltip: 'Nova mediação', visible: true, disabled: false, color: 'green', onClick: () => this.openingModal(false) }
    ])

    await Promise.all([
      this.skeletonConfig.startLoad(),
      this.getoptLevy(),
      this.getOptCompany(),
      this.getOptOperations(),
      this.loadingParticipants(),
    ])

    this.getInformations()

    this.materaTimersList = await this.getTimerPerFamily(2);
  }

  private async getInformations(): Promise<void> {
    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/cadastro/configuracao-pix-mediador`, this.httpOptions).toPromise().then(res => {
        this.tableData = new Array()
        if (res['codigo'] === 200) res['data'].forEach(item => this.tableData.push(item))
        else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as configurações! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.skeletonConfig.endLoad()
      this.setOptionsTable()
    }
  }

  public async saveIntegration(): Promise<void> {
    this.validationMendiation()
    const payload = { user_id: this.userId, ...this.mediation }
    this.isSaveLoading = true

    try {
      await this.httpClient.post(`${this.configService.octaremoteUrl}/cadastro/configuracao-pix-mediador`, payload, this.httpOptions).toPromise().then(res => {
        this.showResponse((res['codigo'] === 200) ? 'success' : 'error', res['message'])
        if (res['codigo'] === 200) this.getInformations()
      })
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.mediation = resetMediation()
      this.mediationSelected = null
      this.isSaveLoading = false
      this.openingModal(false)
      this.changeToolbar()
    }
  }

  private async getOptCompany(): Promise<void> {
    this.optCompanyLoading = true
    this.optIconCompanyLoading = true

    try {
      await this.httpService.get(`/empresa?$select=id,cnpj,razaoSocial&$orderby=razaoSocial`).toPromise().then(res => {
        this.optCompany = new Array()
        res.value.forEach(item => this.optCompany.push({ label: this.cnpjPipe.transform(item.cnpj) + " - " + item.razaoSocial, value: item.id }))
        this.optCompany = this.optCompany.filter(line => !this.tableData.map(item => { return item.empresa_id }).includes(line.value))
      })
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.optCompanyLoading = false
      this.optIconCompanyLoading = false
    }
  }

  private async getoptLevy(): Promise<void> {
    this.optLevyLoading = true
    this.optIconLevyLoading = true

    try {
      await this.httpClient.get(this.configService.octaremoteUrl + '/util/tipos-valores-pix', this.httpOptions).toPromise().then(res => {
        if (res['codigo'] === 200) {
          this.optLevy = new Array()
          res['data'].multa?.forEach(item => this.optLevy.push({ label: item.descricao, value: item.id, isPercentual: item.percentual }))
        } else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao buscar os tipos de cobranças!'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.optLevyLoading = false
      this.optIconLevyLoading = false
    }
  }

  private async getOptParticipants(participantType: 1 | 2): Promise<void> {
    try {
      await this.httpService.get(`/custom/rms/buscar-fornecedores(160,${participantType})`).toPromise().then(res => {
        if (participantType === 1) this.optParticipantClient = new Array()
        else this.optParticipantProviders = new Array()

        res.forEach(item => {
          let labelCNPJ: string = null, labelCPF: string = null, obj: SelectItem

          labelCNPJ = item.cnpj ? ` - CNPJ: ${item.cnpj}` : null
          labelCPF = item.cpf ? ` - CPF: ${item.cpf}` : null

          obj = { label: item.fornecedor + (labelCNPJ || labelCPF || ' - EXTERIOR'), value: item.id }

          if (participantType === 1) this.optParticipantClient.push(obj)
          else this.optParticipantProviders.push(obj)
        })
      }).catch(err => this.showResponse('error', err.status + `- Houve um erro ao carregar os participantes! Contate o suporte`))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async loadingParticipants(): Promise<void> {
    this.optParticipantLoading = true
    this.optIconParticipantLoading = true

    await Promise.all([
      this.getOptParticipants(1),
      this.getOptParticipants(2)
    ]).finally(() => {
      this.optParticipantLoading = false
      this.optIconParticipantLoading = false
    })
  }

  private async getOptOperations(): Promise<void> {
    this.optOperationLoading = true
    this.optIconOperationLoading = true

    try {
      await this.httpService.get(`/operacaoregracontabil?$select=id,descricao,tipoEntradaSaida/id&$filter=tipoRegraContabil/id eq 3 and empresa/id eq 160 and ativo eq true&$orderby=descricao`).toPromise().then(res => {
        this.optCreditOperations = new Array()
        this.optDebitOperations = new Array()

        res.value.forEach(item => {
          if (item.tipoEntradaSaida !== null) {
            if (item.tipoEntradaSaida.id === 1) this.optCreditOperations.push({ label: item.descricao, value: item.id })
            else this.optDebitOperations.push({ label: item.descricao, value: item.id })
          }
        })
      }).catch(err => this.showResponse('error', err.status + `- Houve um erro ao carregar os operações contábeis! Contate o suporte`))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.optOperationLoading = false
      this.optIconOperationLoading = false
    }
  }

  public openingModal(edit: boolean): void {
    this.showModal = !this.showModal

    if (edit) {
      this.mediation = this.mediationSelected
      this.isCompanySelected = true
      this.labelCompany = this.mediation.nome_fantasia
    } else {
      this.mediation = resetMediation()
      this.isCompanySelected = false
      this.labelCompany = 'Selecione'
    }
  }

  public setOptLevyLabel(levyId: number): string {
    let response: string = null
    response = this.optLevy.find(line => line.value === levyId)?.label
    return response
  }

  public setValueLevyLabel(levyId: number, value: number): string {
    let response: string = null

    if (this.setIsPercentualValue(levyId)) response = `${value}%`
    else response = `R$ ${value}`

    return response
  }

  public setIsPercentualValue(levyId: number): boolean {
    let response: boolean = false, findOpt: Object = new Object()

    findOpt = this.optLevy.find(line => line.value === levyId)

    if (findOpt !== null && findOpt !== undefined) {
      if (findOpt['isPercentual']) response = true
      else response = false
    }

    return response
  }

  public setProviderLabel(providerId: number, participantType: 1 | 2): string {
    let response: string = null, aux: SelectItem[] = new Array()

    if (participantType === 1) aux = [...this.optParticipantClient]
    else aux = [...this.optParticipantProviders]

    response = aux.find(line => line.value === providerId)?.label

    return response
  }

  public setOperationLabel(operationId: number, operationType: 1 | 2): string {
    let response: string = null

    if (operationType === 1) response = this.optCreditOperations.find(line => line.value === operationId)?.label
    else response = this.optDebitOperations.find(line => line.value === operationId)?.label

    return response
  }

  public setOptionsTable(): void {
    this.optParticipantProvidersTable = new Array()
    this.optParticipantClientTable = new Array()
    this.optDebitOperationsTable = new Array()
    this.optCreditOperationsTable = new Array()

    this.optParticipantProviders.forEach(item => {
      if (this.tableData.map(elem => { return elem.fornecedor_padrao_id }).includes(item.value)) {
        this.optParticipantProvidersTable.push(item)
      }
    })

    this.optParticipantClient.forEach(item => {
      if (this.tableData.map(elem => { return elem.cliente_padrao_id }).includes(item.value)) {
        this.optParticipantClientTable.push(item)
      }
    })

    this.optDebitOperations.forEach(item => {
      if (this.tableData.map(elem => { return elem.operacao_regra_contabil_saida_id }).includes(item.value)) {
        this.optDebitOperationsTable.push(item)
      }
    })

    this.optCreditOperations.forEach(item => {
      if (this.tableData.map(elem => { return elem.operacao_regra_contabil_entrada_id }).includes(item.value)) {
        this.optCreditOperationsTable.push(item)
      }
    })
  }

  public changeToolbar(): void {
    this.toolbarMain.setVisible('btn-edit', ((this.mediationSelected === null) ? false : true))
  }

  private validationMendiation(): void {
    let message: string = null

    if (this.mediation.empresa_id === null) {
      message = 'Empresa é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (this.mediation.tipo_cobranca_id_qrcode === null) {
      message = 'Cobrança QR Code é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (this.mediation.valor_qrcode === null) {
      message = 'Valor QR Code é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (this.setIsPercentualValue(this.mediation.tipo_cobranca_id_qrcode) && this.mediation.valor_qrcode > 100) {
      message = 'O percentual do QR Code não pode ultrapassar 100%!'
      this.showResponse('info', message)
      throw message
    }

    if (this.mediation.tipo_cobranca_id_cashout === null) {
      message = 'Cobrança Cashout é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (this.mediation.valor_cashout === null) {
      message = 'Valor QR Code é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (this.setIsPercentualValue(this.mediation.tipo_cobranca_id_cashout) && this.mediation.valor_cashout > 100) {
      message = 'O percentual do Cashout não pode ultrapassar 100%!'
      this.showResponse('info', message)
      throw message
    }

    if (this.mediation.fornecedor_padrao_id === null) {
      message = 'Fornecedor é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (this.mediation.operacao_regra_contabil_saida_id === null) {
      message = 'Operação débito é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (this.mediation.cliente_padrao_id === null) {
      message = 'Cliente é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (this.mediation.operacao_regra_contabil_entrada_id === null) {
      message = 'Operação crédito é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }
  }

  // TELA CONCILIADORA
  public initializeConciliadora() {

    this.currentScreen = 'CON'
    this.title = "Status dos Timers Conciliadora"
    this.toolbarMain.setAll([])

    // TODO: REMOVER TIMERS DE TESTE
    this.conciliadoraTimersList = [
      {
        name: "Timer 1",
        status: "Rodando",
        description: "Primeiro timer de teste Conciliadora",
        isRunning: true
      },
      {
        name: "Timer 2",
        status: "Rodando",
        description: "Segundo timer de teste Conciliadora",
        isRunning: true
      },
    ]

  }

  public changeTimerConciliadora(index) {
    //TODO: ADICIONAR AQUI O METODO DE PARAR/INICIAR O TIMER
    if (this.conciliadoraTimersList[index].isRunning) {
      this.conciliadoraTimersList[index].isRunning = false
      this.conciliadoraTimersList[index].status = "Parado"
    }
    else {
      this.conciliadoraTimersList[index].isRunning = true
      this.conciliadoraTimersList[index].status = "Rodando"
    }
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
        break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
        break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
        break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso: ', detail: message })
        break
    }
  }

  public formatDateExtensive(): string {
    const dt = new Date()
    const monthExtensive = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const weekExtensive = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]

    const month = monthExtensive[dt.getMonth()]
    const week = weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }

  public async initializeEyeMobile() {
    this.currentScreen = 'EYE'
    this.title = "Status dos Timers da EyeMobile"
    this.toolbarMain.setAll([])
    this.eyeMobileTimersList = await this.getTimerPerFamily(1);
  }

  public async initializeBS2() {
    this.currentScreen = 'BS2'
    this.title = "Status dos Timers da BS2"
    this.toolbarMain.setAll([])
    this.bs2TimersList = await this.getTimerPerFamily(3)
  }

  public async initializePayLivre() {
    this.currentScreen = 'PAY'
    this.title = "Status dos Timers da Pay Livre"
    this.toolbarMain.setAll([])
    this.payLivreTimersList = await this.getTimerPerFamily(4)
  }

  public changeTimer(index) {
    if (this.currentScreen == 'MAT') {
      this.changeTimerItemValue(this.materaTimersList[index]);
    } else if (this.currentScreen == 'EYE') {
      this.changeTimerItemValue(this.eyeMobileTimersList[index]);
    } else if (this.currentScreen == 'BS2') {
      this.changeTimerItemValue(this.bs2TimersList[index]);
    } else if (this.currentScreen == 'PAY') {
      this.changeTimerItemValue(this.payLivreTimersList[index]);
    }
  }

  private async getTimerPerFamily(familyId: number): Promise<Array<TimerCard>> {
    let timers: Array<TimerCard> = new Array();
    try {
      await this.httpService.get('/apischedulers?$select=id,codigo,nome,descricao,ativo&$filter=familiaApi/id eq ' + familyId).toPromise().then(
        (res) => {
          for (let i = 0; i < res.value.length; i++) {
            let item = res.value[i]
            timers.push({
              id: item['id'],
              cod: item['codigo'],
              name: item['nome'],
              description: item['descricao'],
              isRunning: item['ativo']
            })
          }
        }
      ).catch(
        (err) => {
          this.showResponse('error', 'Falha ao resgatar timer schedulers')
        }
      );
    } catch (e) {
      this.showResponse('error', 'Falha ao resgatar timer schedulers')
    } finally {
      return timers;
    }
  }

  private async changeTimerItemValue(item: TimerCard) {
    let payload = {
      id: item.id,
      ativo: !item.isRunning,
      changeUserId: this.sessionService.loggedUser.id
    }
    try {
      await this.httpService.post("/custom/cadastro/atualizar-valor-scheduler", payload).toPromise().then(
        (res) => {
          item.isRunning = !item.isRunning
        }
      ).catch(
        (err) => {
          this.showResponse('error', 'Falha ao atualizar valor do scheduler')
        }
      )
    } catch (e) {
      this.showResponse('error', 'Falha ao atualizar valor do scheduler')
    }
  }

}
