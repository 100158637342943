import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { isNullOrUndefined } from 'util';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';
declare var $: any;

@Component({
  selector: 'app-cad-cargo',
  templateUrl: './cad-cargo.component.html',
  styleUrls: ['./cad-cargo.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadCargoComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = null;
  msgsModal: Message[] = null;
  subtitle: string;
  title: string;
  modalNew: boolean = false;
  modalEdit: boolean = false;
  cargoLista: Array<any> = new Array();
  selectedJobs: Array<any> = new Array()
  statusOptions: SelectItem[] = [{ label: 'Ativo', value: true }, { label: 'Inativo', value: false }];

  nivelList: SelectItem[] = [];
  tipoCargoList: SelectItem[] = [];

  //Variaveis do cargo
  codigoNew: string = null;
  descricaoNew: string = null;
  nivelIdNew: number = null;
  tipoCargoIdNew: number = null;
  valorMinNew: number = null;
  valorMaxNew: number = null;
  valorMedioNew: number = null;
  idEdit: number = null;
  codigoEdit: string = null;
  descricaoEdit: string = null;
  nivelIdEdit: number = null;
  tipoCargoIdEdit: number = null;
  valorMinEdit: number = null;
  valorMaxEdit: number = null;
  valorMedioEdit: number = null;
  salarioMediano: boolean = false;
  cargoAtivo: boolean = true
  batchStatus: boolean = false

  //export excel
  colsCargos: any[];

  constructor(private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private messageService: MessageService) { }

    public toolbarMain = new Toolbar()

    @ViewChild('dt') dt: Table
    exportMov() {
      this.dt.exportCSV()
    }

    toolbarMainIni = [
      { key: 'export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.dt.exportCSV() },
      { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddCargo() },
      { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.edit() },
      { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.deleteCargo() },
      { key: 'able-disable', icon: 'edit', text: 'Ativar/Desativar', tooltip: 'Ativar ou Desativar em lote', visible: false, disabled: false, color: 'default', onClick: () => this.changeJobStatusBatch() },
      {
        key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
          this.ShowHIdeCardBenef()
          this.changeStateBenef()
        }
      },
    ]

  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni);
    this.toolbarService.hidden = true;
    this.title = 'CARGOS';
    this.subtitle = 'Cadastro de cargo';
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.getCargos();
          this.getNiveis();
          this.getTipoCargo();
          this.changeToolbar()
        }

      });
    this.colsCargos = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'nivel', header: 'NÍVEL' },
      { field: 'tipo_cargo', header: 'CONTRATO' },
      { field: 'valor_min', header: 'VALOR MIN' },
      { field: 'valor_max', header: 'VALOR MAX' },
      { field: 'valor_mediano', header: 'VALOR MEDIANO ' }
    ];
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  toolbarInitial(){
    this.changeToolbar()
  }

  getNiveis() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/niveis', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.nivelList = [];
          for (let line of result) {
            this.nivelList.push({ "label": line["codigo"] + " - " + line["descricao"], "value": line["id"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getTipoCargo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/tipo-cargo', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }
          this.tipoCargoList = []
          res["data"].forEach(element => {
            this.tipoCargoList.push({label: element["codigo"] + " - " + element["descricao"], value: element["id"]})
          });

          this.httpService.done()
        },
        err => {
          console.log(err)
          this.httpService.done()
          this.exibirMensagem({error: true, mensagem: "Erro no API ao obter os contratos, contate o suporte: " + err.message})
        }
      );
  }

  addCargo() {
    if (this.salarioMediano) {
      this.valorMinNew = 0;
      this.valorMaxNew = 0;
    } else {
      this.valorMedioNew = 0;
    }

    if (this.descricaoNew == null || this.descricaoNew === "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Descrição' é obrigatório." });

    } else if (this.tipoCargoIdNew == null || this.tipoCargoIdNew < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Contrato' é obrigatório." });

    } else if (this.salarioMediano && this.valorMedioNew === null) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Valor médio' é obrigatório." });

    } else if (isNullOrUndefined(this.valorMinNew) && !this.salarioMediano) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Valor Min' é obrigatório." });

    } else if (isNullOrUndefined(this.valorMaxNew) && !this.salarioMediano) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Valor Max' é obrigatório." });

    } else if (this.valorMaxNew < this.valorMinNew && !this.salarioMediano) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O valor max deve ser inferior ao valor min." });

    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'nivelid': this.nivelIdNew.toString(),
          'codigo': this.codigoNew.toString(),
          'descricao': this.descricaoNew.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'tipocargoid': this.tipoCargoIdNew.toString(),
          'valormin': this.valorMinNew.toString(),
          'valormax': this.valorMaxNew.toString(),
          'valormedio': this.valorMedioNew.toString(),
          'cargoativo': this.cargoAtivo.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/cargos', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.resetVars()
            this.getCargos();
            this.httpService.done();
            this.modalNew = false;
            this.changeToolbar()
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  getCargos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/cargos', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }

          this.cargoLista = new Array();
          res["data"].forEach(row => {this.cargoLista.push(row)})
          this.httpService.done()
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Error no API ao carregar os cargos, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  openAddCargo() {
    this.modalNew = true;
    this.codigoNew = "";
    this.descricaoNew = null;
    this.nivelIdNew = 0;
    this.tipoCargoIdNew = null;
    this.valorMinNew = null;
    this.valorMaxNew = null;
    this.salarioMediano = false;
    this.valorMedioNew = null;
    this.cargoAtivo = true
  }

  onRowSelect(row) {
    this.batchStatus = this.selectedJobs.some(job => job.ativo)
    this.changeToolbar()
  }

  onRowUnselect(row) {
    this.changeToolbar()
    row.selected = true
  }

  deleteCargo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'cargoid': this.idEdit.toString()
      })
    };

    if (confirm('Deseja deletar o registro selecionado? ')) {
    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/cargos', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.modalEdit = false;
          this.resetVars()
          this.getCargos();
          this.changeToolbar()
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
    }
  }

  edit() {
    const selectedJob = this.selectedJobs[0]

    this.codigoEdit = selectedJob.codigo;
    this.descricaoEdit = selectedJob.descricao;
    this.nivelIdEdit = selectedJob.nivel_id;
    this.idEdit = selectedJob.id;
    this.tipoCargoIdEdit = selectedJob.tipo_cargo_id;
    this.valorMinEdit = selectedJob.valor_min;
    this.valorMaxEdit = selectedJob.valor_max;
    this.valorMedioEdit = selectedJob.valor_mediano;
    this.cargoAtivo = selectedJob.ativo

    this.modalEdit = true
  }

  editCargo() {
    if (this.salarioMediano) {
      this.valorMinEdit = 0;
      this.valorMaxEdit = 0;
    } else {
      this.valorMedioEdit = 0;
    }

    if (this.descricaoEdit == null || this.descricaoEdit === "") {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Descrição' é obrigatório." });

    } else if (this.nivelIdEdit == null || this.nivelIdEdit < 1) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Nível' é obrigatório." });

    } else if (this.tipoCargoIdEdit == null || this.tipoCargoIdEdit < 1) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Contrato' é obrigatório." });

    } else if (this.salarioMediano && this.valorMedioEdit === null) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Valor médio' é obrigatório." });

    } else if (isNullOrUndefined(this.valorMinEdit) && !this.salarioMediano) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Valor Min' é obrigatório." });

    } else if (isNullOrUndefined(this.valorMaxEdit) && !this.salarioMediano) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Valor Max' é obrigatório." });

    } else if (this.valorMaxEdit < this.valorMinEdit && !this.salarioMediano) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O valor max deve ser inferior ao valor min." });

    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'nivelid': this.nivelIdEdit.toString(),
          'codigo': this.codigoEdit.toString(),
          'descricao': this.descricaoEdit.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'tipocargoid': this.tipoCargoIdEdit.toString(),
          'valormin': this.valorMinEdit.toString(),
          'valormax': this.valorMaxEdit.toString(),
          'cargoid': this.idEdit.toString(),
          'valormedio': this.valorMedioEdit.toString(),
          'cargoativo': this.cargoAtivo.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/cargos', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.httpService.done();
            this.modalEdit = false;
            this.resetVars()
            this.getCargos();
            this.changeToolbar()
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  exibirMensagem(value: any) {
    if (!value.error) this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });
    else this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return 50;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  /*Show Hide*/
  isCardBenef: boolean = true;
  ShowHIdeCardBenef() {
    this.isCardBenef = !this.isCardBenef;
    this.toolbarMain.setIcon('expand', this.isCardBenef ? 'expand_less' : 'expand_more');
    this.toolbarMain.setTooltip('expand', this.isCardBenef ? 'Ver menos' : 'Ver mais')
  }

  currentStateBenef = 'initial';
  changeStateBenef() {
    this.currentStateBenef = this.currentStateBenef === 'initial' ? 'final' : 'initial';
  }

  fullBenef: boolean = false;
  fullScreenBenef() {
    this.fullBenef = !this.fullBenef;
  }

  changeJobStatusBatch() {
    const newStatus = !this.batchStatus

    const confirmAction = confirm(`Deseja ${newStatus ? 'ativar' : 'inativar'} o(s) registro(s) selecionado(s)?`)
    if(!confirmAction) return

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
      })
    }

    const jobsIds = this.selectedJobs.map(job => job.id);

    const payload = {
      "cargosIds": jobsIds,
      "status": newStatus,
      "userId": this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/atualizar-status-cargo', payload, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
          this.modalEdit = false
          this.resetVars()
          this.getCargos()
          this.changeToolbar()
        },
        err => {
          this.exibirMensagem(err)
          console.log(err)
          this.httpService.done()
        }
      )
  }

  changeToolbar() {
    const hasOneJobSelected = this.selectedJobs.length === 1
    const hasOneOrMoreJobSelected = this.selectedJobs.length >= 1

    this.toolbarMain.setVisible('export', true)
    this.toolbarMain.setVisible('expand', true)
    this.toolbarMain.setVisible('add', true)
    this.toolbarMain.setVisible('edit', hasOneJobSelected)
    this.toolbarMain.setVisible('delete', hasOneJobSelected)
    this.toolbarMain.setVisible('able-disable', hasOneOrMoreJobSelected)
  }

  resetVars() {
    this.selectedJobs = new Array()
    this.batchStatus = false
  }
}