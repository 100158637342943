import { Component, OnInit } from '@angular/core';
import { TbTipoVinc } from '../model/tb-tipo-vinc.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'siscoserv-tipo-vinculo',
  templateUrl: './tipo-vinculo.component.html',
  styleUrls: ['./tipo-vinculo.component.scss'],
  providers: [CrudService]
})
export class TipoVinculoComponent extends DataTableBasicComponent implements OnInit {
  public tbTipoVinc: TbTipoVinc = new TbTipoVinc();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('CÓDIGO', 'cod'),
      new DataTableColumn('DESCRIÇÃO', 'descricao'),
      new DataTableColumn('CÓDIGO*', 'valueAlias')
    ];

    this.controls = [
      new DataControl('Código', 'cod').setReadOnly(),
      new DataControl('Descrição', 'descricao').setReadOnly(),
      new DataControl('Código*', 'valueAlias')
    ];
  }
}
