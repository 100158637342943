import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { MessageService, SelectItem } from 'primeng/api';
import { ConfigPattern } from '../../interfaces/asaas';
import { Terms } from '../../interfaces/matera';
import { resetConfigPattern } from '../../utils/reset-asaas-form';
import { resetTerms } from '../../utils/reset-matera-form';

@Component({
  selector: 'app-cfg-asaas',
  templateUrl: './cfg-asaas.component.html',
  styleUrls: ['./cfg-asaas.component.scss']
})

export class CfgAsaasComponent implements OnInit, OnChanges {

  @Input() isRender: boolean
  @Input() isSave: boolean
  @Input() isConfigExists: boolean
  @Output() isValid = new EventEmitter()

  httpOptions: Object = new Object()

  _isConfigExists: boolean = false
  contaCriada: boolean = false
  isSaveLoading: boolean = false
  loadingConfigPattern: boolean = false
  loadingIconOptCompanyAccounts: boolean = false
  loadingOptCompanyAccounts: boolean = false
  loadingIconOptProviders: boolean = false
  loadingOptProviders: boolean = false
  optIconLevyLoading: boolean = false
  optIconOperationLoading: boolean = false
  optOperationLoading: boolean = false
  showModalSplit: boolean = false
  splitFormaPag: boolean = false
  isLocationDisabled: boolean = false
  isAcceptedTerms: boolean = false
  cashoutAutomatico: boolean = false
  avisoEmail: boolean = false

  empresa_conta_id: number = null
  empresaId: number = null
  operacao_regra_contabil_id_entrada: number = null
  operacao_regra_contabil_id_saida: number = null
  operacao_regra_contabil_id_taxas: number = null
  userId: number = null
  valorCashout: number
  contadorTransferMensal: number
  providerId: number = null
  dias_aviso_apos: number = null
  faturamento_mensal: number = null

  splitPagamento: Array<any> = new Array()
  optLevy: Array<any> = new Array()

  splitSelected: any = {}

  dataCashout: Date
  dataPadrao: Date

  email_contato: string = null
  tipo_chave_pix: string = null
  dias_aviso_antes: string = null

  optCompanyAccounts: SelectItem[] = new Array()
  optCreditOperations: SelectItem[] = new Array()
  optDebitOperations: SelectItem[] = new Array()
  optPixKeys: SelectItem[] = [
    {label: 'CPF', value: 'CPF'},
    {label: 'CNPJ', value: 'CNPJ'},
    {label: 'E-mail', value: 'EMAIL'},
    {label: 'Telefone', value: 'PHONE'},
    {label: 'EVP', value: 'EVP'},
  ]
  optProviders: SelectItem[] = new Array()

  config: ConfigPattern = resetConfigPattern()
  terms: Terms = resetTerms()

  skeletonConfigAsaas = new SkeletonModel({
    columns: [
      { name: 'Nome', size: '30%', textAlign: 'left' },
      { name: 'Carteira', size: '35%', textAlign: 'left' },
      { name: 'Valor', size: '10%', textAlign: 'right' },
      { name: 'Status', size: '25%', textAlign: 'center' }
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true
  })

  public toolbarMain = new Toolbar()

  constructor(
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private httpService: HttpService
  ) { }

  public ngOnInit(): void {
    this.empresaId = this.dblinkedSessionService.empresa.id
    this.userId = this.sessionService.loggedUser.id

    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }

    this.toolbarMain.setAll([
      { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar split', visible: false, disabled: false, color: 'default', onClick: () => this.openingModalSplit(true) },
      { key: 'btn-delete', icon: 'delete', text: 'Excluir', tooltip: 'Excluir split', visible: false, disabled: false, color: 'default', onClick: () => this.excluirSplit() },
      { key: 'btn-new', icon: 'add', text: 'Nova', tooltip: 'Novo split', visible: true, disabled: false, color: 'green', onClick: () => this.openingModalSplit(false) }
    ])

    this.getOptProviders()
    this.getOptCompanyAccounts()
    this.getOptOperations()
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.isSave && changes.isSave.currentValue) this.saveConfigPattern()
    if(changes.isConfigExists && changes.isConfigExists.currentValue) this._isConfigExists = true
    if(changes.isRender && changes.isRender.currentValue) {
      this.getIPAddress()
      if(this._isConfigExists) this.initialize()
    }
  }

  private async initialize(): Promise<void> {
    this.getoptLevy()
    this.getConfigPattern()
    this.getAsaasSplit()
    this.getParamCobranca()

  }

  private async getConfigPattern(): Promise<void> {
    this.loadingConfigPattern = true

    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/cadastro/configuracao-api?empresaId=${this.empresaId}&codigo=ASAAS`, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          if (res['data'].length > 0) {
            this.contaCriada = true
            this.config.chaveApi = res['data'][0].chave_api
            this.config.usuario = res['data'][0].usuario
            this.config.walletId = res['data'][0].wallet_id
            this.config.accountStatus = res['data'][0].account_status
            this.config.onboardingUrl = res['data'][0].onboarding_url
            this.isValid.emit({validatedSteps: true})
          }
        } else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao obter os dados da conta ASAAS! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o API, contate o suporte.')
    } finally {
      this.loadingConfigPattern = false
    }
  }

  private async saveConfigPattern(): Promise<void> {
    const payload = {
      empresa_id: this.empresaId,
      user_id: this.userId,
      nome: this.config.razao,
      email: this.config.email,
      cnpj: this.config.cnpj,
      telefone: this.config.telefone,
      logradouro: this.config.logradouro,
      numero: this.config.numero,
      complemento: this.config.complemento,
      bairro: this.config.bairro,
      cep: this.config.cep,
      faturamento_mensal: this.faturamento_mensal
    }

    if (!this.contaCriada) {
      try {
        await this.httpClient.post(this.configService.octaremoteUrl + '/asaas/conta', payload, this.httpOptions).toPromise().then(res => {
          if(res['codigo'] === 200) {
            this.saveFamilyRelationship()
            this.salvarParamCobranca()
            this.saveAcceptedTerms()
          } else this.showResponse('error', res['message'])
        }).catch(err => this.showResponse('error', err.status + ' - Erro no API ao criar a conta, contate o suporte.'))
      } catch(err) {
        this.showResponse('error', 'Falha na comunicação com o servidor! Contate o suporte.')
      }
    } else this.salvarParamCobranca()
  }

  private async saveFamilyRelationship(): Promise<void> {
    const httpOptions: Object = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    const payload: Object = { empresa_id: this.dblinkedSessionService.empresa.id, familia_id: 5 }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/empresa-familia', payload, httpOptions).toPromise()
      .then(res => this.getConfigPattern())
      .catch(err =>
        this.showResponse('error', err.status + ' - Houve um erro ao salvar a família de API ASAAS! Contate o suporte.')
      )
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor! Contate o suporte.')
    }
  }

  public validationConfig(): void {
    this.isValid.emit({ validatedSteps: (this.config.razao && this.config.cnpj && this.config.telefone && this.config.email && this.config.cep && this.config.logradouro && this.config.numero && this.config.bairro && this.faturamento_mensal && this.isAcceptedTerms) })
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso: ', detail: message })
      break
    }
  }

  public getCompleteAddress(): void {
    if(this.config.cep !== null && this.config.cep.length === 8) this.httpClient.get(`https://viacep.com.br/ws/${this.config.cep}/json/`).toPromise().then(res => {
      if(!(res['erro'] == 'true')) {
        this.config.logradouro = res['logradouro'] ? res['logradouro'] : null
        this.config.complemento = res['complemento'] ? res['complemento'] : null
        this.config.numero = res['numero'] ? res['numero'] : null
        this.config.bairro = res['bairro'] ? res['bairro'] : null
      }
    })
  }

  get rowsPerPage(): Array<any> {
    return this.configService.applicationConfig.rowsPerPage
  }

  get rows(): number {
    return this.configService.applicationConfig.rows
  }

  get pageLinks(): number {
    return this.configService.applicationConfig.pageLinks
  }

  public changeToolbar(): void {
    this.toolbarMain.setVisible('btn-edit', ((this.splitSelected?.id) ? true : false))
    this.toolbarMain.setVisible('btn-delete', ((this.splitSelected?.id) ? true : false))
  }

  public setIsPercentualValue(levyId: number): boolean {
    let response: boolean = false, findOpt: Object = new Object()

    findOpt = this.optLevy.find(line => line.value === levyId)

    if(findOpt !== null && findOpt !== undefined) {
      if(findOpt['isPercentual']) response = true
      else response = false
    }

    return response
  }

  private async getoptLevy(): Promise<void> {
    this.optIconLevyLoading = true
    try {
      await this.httpClient.get(this.configService.octaremoteUrl + '/util/tipos-valores-pix', this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          this.optLevy = new Array()
          res['data'].multa?.forEach(item => this.optLevy.push({ label: item.descricao, value: item.id, isPercentual: item.percentual }))
        } else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao buscar os tipos de cobranças!'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.optIconLevyLoading = false
    }
  }

  private async getAsaasSplit(startSkeleton: boolean=true): Promise<void> {
    if (startSkeleton) this.skeletonConfigAsaas.startLoad()
    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/asaas/split-pagamento?empresaId=${this.empresaId}`, this.httpOptions).toPromise().then(res => {
        this.splitPagamento = new Array()
        if(res['codigo'] === 200) res['data'].forEach(item => this.splitPagamento.push(item))
        else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os splits de pagamento! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.skeletonConfigAsaas.endLoad()
    }
  }

  public openingModalSplit(edit: boolean): void {
    this.showModalSplit = !this.showModalSplit
    if(!edit) this.splitSelected = {}
  }

  public async saveSplitAsaas(): Promise<void> {
    this.validationSplit()
    const payload = {
      user_id: this.userId,
      empresa_id: this.empresaId,
      split: this.splitSelected
    }

    this.isSaveLoading = true

    try {
      await this.httpClient.post(`${this.configService.octaremoteUrl}/asaas/split-pagamento`, payload, this.httpOptions).toPromise().then(res => {
        this.showResponse((res['codigo'] === 200) ? 'success' : 'error', res['message'])
        if (res["codigo"] === 200) {
          this.splitSelected = {}
          this.openingModalSplit(false)
          this.changeToolbar()
          this.getAsaasSplit()
        }
      })
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isSaveLoading = false
    }
  }

  private validationSplit(): void {
    let message: string = null

    if(this.splitSelected.nome === null) {
      message = 'Nome é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if(this.splitSelected.carteira === null) {
      message = 'Carteira é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if(this.splitSelected.tipo_cobranca_id === null) {
      message = 'Tipo de cobrança é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if(this.splitSelected.valor === null) {
      message = 'Valor da cobrança é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if(this.setIsPercentualValue(this.splitSelected.tipo_cobranca_id) && this.splitSelected.valor > 100) {
      message = 'O percentual da cobrança não pode ultrapassar 100%!'
      this.showResponse('info', message)
      throw message
    }
  }

  public async excluirSplit() {
    this.skeletonConfigAsaas.startLoad()
    try {
      await this.httpClient.delete(`${this.configService.octaremoteUrl}/asaas/split-pagamento?splitId=${this.splitSelected.id}`, this.httpOptions).toPromise().then(res => {
        this.showResponse((res['codigo'] === 200) ? 'success' : 'error', res['message'])
        if(res['codigo'] === 200) {
          this.splitSelected = {}
          this.changeToolbar()
          this.getAsaasSplit(false)
        }
      })
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  public async salvarParamCobranca() {
    this.validationCobranca()
    const payload = {
      empresa_id: this.empresaId,
      user_id: this.userId,
      operacao_in: this.operacao_regra_contabil_id_entrada,
      operacao_out: this.operacao_regra_contabil_id_saida,
      empresa_conta_id: this.empresa_conta_id,
      tipo_chave_pix: this.tipo_chave_pix,
      email_contato: this.email_contato,
      split_forma_pag: this.splitFormaPag,
      cashout_automatico: this.cashoutAutomatico,
      data_cashout: this.dataCashout,
      valor_cashout: this.valorCashout,
      operacao_taxas: this.operacao_regra_contabil_id_taxas,
      participante_empresa_id: this.providerId,
      aviso_email: this.avisoEmail,
      dias_aviso_antes: this.dias_aviso_antes,
      dias_aviso_apos: ~~this.dias_aviso_apos
    }

    try {
      await this.httpClient.post(`${this.configService.octaremoteUrl}/asaas/param-cobranca`, payload, this.httpOptions).toPromise().then(res => {
        this.showResponse((res['codigo'] === 200) ? 'success' : 'error', res['message'])
      })
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isValid.emit({validatedSteps: true})
    }
  }

  private async getParamCobranca(): Promise<void> {
    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/asaas/param-cobranca?empresaId=${this.empresaId}`, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          let param = res["data"]
          this.operacao_regra_contabil_id_entrada = param.operacao_regra_contabil_id_entrada
          this.operacao_regra_contabil_id_saida = param.operacao_regra_contabil_id_saida
          this.empresa_conta_id = param.empresa_conta_id
          this.tipo_chave_pix = param.tipo_chave_pix
          this.email_contato = param.email_contato
          this.splitFormaPag = param.split_forma_pag
          this.cashoutAutomatico = param.cashout_automatico
          this.dataCashout = new Date(param.data_cashout)
          this.valorCashout = param.valor_cashout
          this.contadorTransferMensal = param.contador_transfer_mensal
          this.operacao_regra_contabil_id_taxas = param.operacao_regra_contabil_taxas_cashout
          this.providerId = param.participante_empresa_id_taxas_cashout,
          this.avisoEmail = param.aviso_email
          this.dias_aviso_antes = param.dias_aviso_antes,
          this.dias_aviso_apos = param.dias_aviso_apos

        } else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os splits de pagamento! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getOptOperations(): Promise<void> {
    this.optOperationLoading = true
    this.optIconOperationLoading = true

    try {
      await this.httpService.get(`/operacaoregracontabil?$select=id,descricao,tipoEntradaSaida/id&$filter=tipoRegraContabil/id eq 3 and empresa/id eq ${this.empresaId} and ativo eq true&$orderby=descricao`).toPromise().then(res => {
        this.optCreditOperations = new Array()
        this.optDebitOperations = new Array()

        res.value.forEach(item => {
          if(item.tipoEntradaSaida !== null) {
            if(item.tipoEntradaSaida.id === 1) this.optCreditOperations.push({ label: item.descricao, value: item.id })
            else this.optDebitOperations.push({ label: item.descricao, value: item.id })
          }
        })
      }).catch(err => this.showResponse('error', err.status + `- Houve um erro ao carregar os operações contábeis! Contate o suporte`))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.optOperationLoading = false
      this.optIconOperationLoading = false
    }
  }

  private validationCobranca(): void {
    let message: string = null

    if(this.operacao_regra_contabil_id_entrada === null) {
      message = 'A operação de crédito é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if(this.operacao_regra_contabil_id_saida === null) {
      message = 'A operação de débito é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

  }

  private async getOptCompanyAccounts(): Promise<void> {
    this.loadingOptCompanyAccounts = true
    this.loadingIconOptCompanyAccounts = true

    try {
      await this.httpService.get('/empresaconta?$select=id,nome,tipoConta/id,agencia,dvAgencia,conta,dvConta,tipoConta/descricao&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo=true&$orderby=nome').toPromise().then(res => {
        this.optCompanyAccounts = [{ label: 'Nenhuma', value: null }]
        res.value.forEach(item => {
          if(item.tipoConta.id !== 10) {
            let label: string = item.id + " - " + item.nome
            if (item.agencia) label += " - Ag: " + item.agencia
            if (item.dvAgencia) label += "-" + item.dvAgencia
            if (item.conta) label += " - CC: " + item.conta
            if (item.dvConta) label += "-" + item.dvConta
            label += " - " + item.tipoConta.descricao
            this.optCompanyAccounts.push({ label: label, value: item.id })
          }
        })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as contas da empresa! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptCompanyAccounts = false
      this.loadingIconOptCompanyAccounts = false
    }
  }

  private async getIPAddress(): Promise<void> {
    try {
      await this.httpClient.get('https://api.ipify.org/?format=json').toPromise().then(res => {
        this.terms.ip = res['ip']
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro recuperar o IP!'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  public emitValidatedSteps(): void {
    const accepted = (acc) => {
      this.terms.precisao = acc.coords.accuracy
      this.terms.latitude = acc.coords.latitude
      this.terms.longitude = acc.coords.longitude
      this.terms.timestamp = this.formatTermsTimestamp(acc.timestamp)

      navigator.geolocation.clearWatch(navigatorId)

      this.isAcceptedTerms = true
      this.validationConfig()
    }

    const denied = (den) => {
      this.isLocationDisabled = true
      this.isAcceptedTerms = false
      this.validationConfig()
    }

    const navigatorId = navigator.geolocation.watchPosition(accepted, denied)
  }

  private formatTermsTimestamp(timestamp: number): string {
    let response: string = null, date: Date = new Date(timestamp), year: number, month: number, day: number

    year = date.getFullYear(),  month = date.getMonth() + 1, day = date.getDate()
    response = `${year}-${(month > 9) ? month : ('0' + month)}-${(day > 9) ? day : ('0' + day)} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

    return response
  }

  private async saveAcceptedTerms(): Promise<void> {
    const httpOptions: Object = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    const payload: Object = { empresa_id: this.dblinkedSessionService.empresa.id, user_id: this.sessionService.loggedUser.id, ...this.terms }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/asaas/termos-de-uso', payload, httpOptions).toPromise().catch(err =>
        this.showResponse('error', err.status + ' - Houve um erro ao salvar os termos de uso!')
      )
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getOptProviders(): Promise<void> {
    this.loadingOptProviders = true
    this.loadingIconOptProviders = true

    try {
      await this.httpService.get('/participanteempresa?$select=id,fantasia,codigo&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo=true and participanteTipo/id=2&$orderby=fantasia').toPromise().then(res => {
        this.optProviders = [{ label: 'Nenhuma', value: null }]
        res.value.forEach(provider => {
          let label: string = provider.codigo + " - " + provider.fantasia
          this.optProviders.push({ label: label, value: provider.id })
        })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os fornecedores da empresa! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptProviders = false
      this.loadingIconOptProviders = false
    }
  }
}
