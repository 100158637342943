import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import {Eventos} from 'app/eventos/model/eventos.model';
import {Plano} from 'app/eventos/model/plano.model'
import { Util } from 'app/shared/common/util';
import { isNullOrUndefined } from 'util';
import { Message, MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-eventos-plano',
  templateUrl: './eventos-plano.component.html',
  styleUrls: ['./eventos-plano.component.scss'],
  providers: [CrudService]
})
export class EventosPlanoComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  pt_BR: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;

  modalEventoPlano           : boolean = false;
  opcoesEventoPlano          : SelectItem[] = [];

  idLista           : Array<any> = new Array();
  listaEventosPlano : Array<any> = new Array();
  checkedAll    = false;


  editEventoPlano                  : boolean = false;
  btnSalvarDisabled                : boolean = false;
  btnCancelarDisabled              : boolean = false;

  eventos     = new Eventos();
  plano       = new Plano();
  eventoId    : number = 0;
  valorVista  : number = 0.00;
  listRecorrencia   : SelectItem[];
  opcoesComboUf     : SelectItem[];
  opcoesComboStatus : SelectItem[];
  colsEventosPlano  : any[];

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title              = 'EVENTOS PLANOS';
    this.subtitle           = 'CADASTRO DE PLANOS';
    this.titleService.title = 'EVENTOS PLANOS';


    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();

    this.modalEventoPlano    = false;
    this.checkedAll         = false;

    this.opcoesEventoPlano  = new Array();
    this.editEventoPlano    = false;
    this.idLista            = new Array();
    this.listRecorrencia    = [] ;

    this.listRecorrencia.push({label: 'Sim', value: true});
    this.listRecorrencia.push({label: 'Não', value: false});

    this.colsEventosPlano = [
      { field: 'descricaoCompleta', header: 'EVENTO' },
      { field: 'dataInicioEvento', header: 'DT INI. EVENT.' },
      { field: 'uf', header: 'UF' },
      { field: 'nomeCidade', header: 'CIDADE' },
      { field: 'parcela', header: 'PARCELA Nº' },
      { field: 'valorUnit', header: 'VALOR UNIT' },
      { field: 'valor', header: 'VALOR' },
      { field: 'recorrenciaPt', header: 'RECORRÊNCIA' },
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {

        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
            this.listar();
        }

      });

  }

 listar(){

  this.modalEventoPlano   = false;
  this.checkedAll         = false;

  this.opcoesEventoPlano  = new Array();
  this.editEventoPlano    = false;
  this.idLista            = new Array();

  this.getComboUf();
  this.toolbarEnable();

  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-lista-eventos-planos(' + [this.dblinkedSessionService.empresa.id] + ')')
    .subscribe(retorno => {

      this.listaEventosPlano  = new Array();
      this.opcoesComboStatus  = [];
      retorno.forEach(i =>{
        this.listaEventosPlano.push(i);

        var index = 0;
        //if (!isNullOrUndefined(this.opcoesComboStatus)) {
          if (this.opcoesComboStatus !== null || this.opcoesComboStatus !== undefined) {
          index = this.opcoesComboStatus.findIndex(e => e.value === i.recorrenciaPt);
        }

        if (index === -1) {
          this.opcoesComboStatus.push({label: i.recorrenciaPt , value: i.recorrenciaPt});
        }

        this.opcoesComboStatus.sort((a,b) => a.label.localeCompare(b.label));


      })
    },
      error => this.httpService.handleError(error, () => this.listar()),
      () => this.httpService.done());



 }

  hideMessage() {


  }

  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;
    this.idLista = new Array();
    this.listaEventosPlano.forEach(i=> {
      if (!this.checkedAll) {
        i.selected = false;
        this.idLista.slice(i.id, 1);
      } else {
        i.selected = true;
        this.idLista.push(i.id);
      }
    });


    this.configuraToolBar();
  }

  configuraToolBar() {
    if (isNullOrUndefined(this.idLista) || this.idLista.length === 0) {
     this.toolbarService.alterarStatus('remove', true, 'Excluir Registro(s)', () => this.excluirEventoPlano());
     this.toolbarService.alterarStatus('add', false, 'Nova Registro', ()  => this.showModalNovoEventoPlano());
     this.toolbarService.alterarStatus('export', true, 'Editar Registro', () =>() => this.showModalEditarEventoPlano());
    } else {
      if (this.idLista.length === 1) {
        this.toolbarService.alterarStatus('remove', false, 'Excluir Registro(s)', () => this.excluirEventoPlano());
        this.toolbarService.alterarStatus('add', true, 'Nova Registro', ()  => this.showModalNovoEventoPlano());
        this.toolbarService.alterarStatus('export', false, 'Editar Registro', ()  => this.showModalEditarEventoPlano());


      } else if(this.idLista.length > 1)  {
        this.toolbarService.alterarStatus('remove', false, 'Excluir Registro(s)', () => this.excluirEventoPlano());
        this.toolbarService.alterarStatus('add', true, 'Nova Registro', () => this.showModalNovoEventoPlano());
        this.toolbarService.alterarStatus('export', true, 'Editar Registro', () => this.showModalEditarEventoPlano());
      }
    }

  }



  selectCheckbox(id: any, event: any) {


    if (event.selected) {
       event.selected = true;
       const index = this.idLista.findIndex(i => i === id);
       this.idLista.splice(index,1);

    } else {
      this.idLista.push(id);
    }


    this.configuraToolBar();

  }



  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

      Promise.resolve(null).then(() => this.toolbarService.hidden = false);
      this.toolbarService.ocultar('voltar');
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.ocultar('more_vert');
      this.toolbarService.ocultar('print');
      this.toolbarService.ocultar('edit');

      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
        () => this.ngOnInit()
      );
      this.toolbarService.ocultar('save');

     // this.toolbarService.replace('more_vert', true, 'class-edit-button', 'more_vert', 'Opções');
      this.toolbarService.ocultar('pesquisa');

      //this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir Pedido',     );
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.replace('export', true, 'class-edit-button', 'edit', 'Editar Registro', () => this.showModalEditarEventoPlano() );
     // this.toolbarService.replace('edit', true, 'class-edit-button', 'list', 'Alterar Títulos Selecionados');

      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo Registro',    () => this.showModalNovoEventoPlano()    );


  }



  showModalEditarEventoPlano() {
    this.modalEventoPlano = true;
    this.hideMessage();
    this.editEventoPlano = true;
    this.comboEventos();
    this.plano          = new Plano();
    this.plano.eventos  = new Eventos();

    const index = this.listaEventosPlano.findIndex(i => i.id === this.idLista[0]);
    console.log(this.listaEventosPlano[index]);
    this.plano.valor         = this.listaEventosPlano[index].valor;
    this.plano.valorUnit     = this.listaEventosPlano[index].valorUnit;
    this.plano.eventos.id    = this.listaEventosPlano[index].eventoId;
    this.eventoId            = this.listaEventosPlano[index].eventoId;
    this.plano.parcela       = this.listaEventosPlano[index].parcela;
    this.plano.recorrencia   = this.listaEventosPlano[index].recorrencia;




  }


  showModalNovoEventoPlano() {
    this.plano          = new Plano();
    this.plano.eventos  = new Eventos();
    this.hideMessage();
    this.comboEventos();
    this.valorVista       = 0.00;
    this.eventoId         = 0;
    this.plano.parcela    = 1;
    this.plano.valor      = 0.00;
    this.plano.valorUnit  = 0.00;
    this.plano.recorrencia = false;
    this.modalEventoPlano  = true;

  }

  validacao(){

    if (isNullOrUndefined( this.eventoId) || this.eventoId === 0  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar um evento!'
      });
      return true;

    }


    if (isNullOrUndefined( this.plano.parcela) || this.plano.parcela < 1  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor informar uma parcela maior ou igual a 1!'
      });
      return true;

    }

    if (isNullOrUndefined( this.plano.parcela) || this.plano.parcela > 12  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor informar uma parcela menor ou igual a 12'
      });
      return true;

    }


    if (isNullOrUndefined( this.plano.valor) || this.plano.valor < 1  ||  this.plano.valor.toString() === '' ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor informar um valor não pode ser negativo ou menor que 1!'
      });
      return true;

    }

      if (isNullOrUndefined( this.plano.valorUnit) || this.plano.valorUnit < 1  || this.plano.valorUnit.toString() ==='' ) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'A favor informar um valor unitário a vista não pode ser negativo ou menor que 1!'
        });
        return true;

      }

    return false;
  }


  ngOnDestroy(): void {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  salvarEventoPlano(){

    if (this.validacao()) {
      return;
    }
    this.plano.eventos.id = this.eventoId;

    const listaEnviar = {
       eventosPlano         : this.plano,
       eventosId            : this.eventoId,
       usuarioId            : this.sessionService.loggedUser.id,
       empresaId            : this.dblinkedSessionService.empresa.id,
       IsNovoRegistro       : true
    };


    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-evento-planos', listaEnviar)
      .subscribe(result => {

        if (result[0].error) {
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        }

        this.listar();
      },
        error => this.httpService.handleError(error, () => this.salvarEventoPlano()),
        () => this.httpService.done());


  }

  editarEventoPlano(){

    if (this.validacao()) {
      return;
    }

    this.plano.eventos.id = this.eventoId;

    const listaEnviar = {
      planoId                       : this.idLista[0],
      eventosPlano                  : this.plano,
      eventosId                     : this.eventoId,
      usuarioId                     : this.sessionService.loggedUser.id,
      empresaId                     : this.dblinkedSessionService.empresa.id,
      IsNovoRegistro                : false
   };

   console.log(listaEnviar);


   this.httpService.wait();
   this.httpService.post('/custom/polozi/salvar-evento-planos', listaEnviar)
     .subscribe(result => {
      if (result[0].error ) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      }
      this.listar();
     },
       error => this.httpService.handleError(error, () => this.editarEventoPlano()),
       () => this.httpService.done());

  }


 excluirEventoPlano(){
   if (confirm('Deseja excluir o(s) registro(s) selecionada(s)?')) {

    const listaEnviar = {
      ids              : this.idLista

   };


   this.httpService.wait();
   this.httpService.post('/custom/polozi/deletar-eventos-plano', listaEnviar)
     .subscribe(result => {
      if (result[0].error ) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      }
      this.listar();
     },
       error => this.httpService.handleError(error, () => this.excluirEventoPlano()),
       () => this.httpService.done());

   }

 }

 comboEventos(){
//{ano},{mes},{empresaId}

  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-lista-eventos',[this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, this.dblinkedSessionService.empresa.id])
    .subscribe(retorno => {
      console.log(retorno);
      this.opcoesEventoPlano = [];
      this.opcoesEventoPlano.push({label: 'Selecione um evento' , value: 0});
      retorno.forEach(i =>{
        this.opcoesEventoPlano.push({label: i.dataInicio + ' - ' +  i.nomeCidade + '/' + i.uf  + ' - ' + i.codigo + ' - ' + i.descricao , value: i.id});
      })
    },
      error => this.httpService.handleError(error, () => this.comboEventos()),
      () => this.httpService.done());

 }

 preencheValorUnitario(){
   this.plano.valorUnit = this.plano.valor;
 }

 onFilter(event: any) {
   console.log(event);
 }

 teste(event : any ,field : any,filterMatchMode : any){

 }

 getComboUf(){

  this.httpService.wait();
  this.httpService.get('/uf?$select=id,uf&$orderby=uf').subscribe(ref => {

    this.opcoesComboUf = [];
    this.opcoesComboUf.push({label: '', value: ''});
    ref.value.forEach(element => {
      this.opcoesComboUf.push({label: element.uf, value: element.uf});
    });


  },
    error => this.httpService.handleError(error, () => this.getComboUf()),
    () => this.httpService.done());



}


}



