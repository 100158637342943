import { Component, OnInit, OnDestroy } from '@angular/core';
import { TitleService } from 'app/shared/services/title.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { UtilService } from 'app/shared/services/util.service';
import { HttpService } from 'app/shared/services/http.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { element } from 'protractor';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-fichas',
  templateUrl: './fichas.component.html',
  styleUrls: ['./fichas.component.scss']
})
export class FichasComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private utilService: UtilService,
    public dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }

  // controles locais
  title: string;
  subtitle: string;
  msgs: Message[] = [];
  msgsModal: Message[] = [];
  pt_BR: any;
  // filtros
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;
  modalPeriodo: boolean;
  saldoAtual: number;
  valorFiltro: number;
  // tabela
  fichas: Array<any> = new Array();
  fichasSelecionadas: Array<any> = new Array();
  fichasFiltradas: Array<any> = new Array();
  ids: Array<any> = new Array();

  idBoletosCheck: Array<any> = new Array();
  todosSelecionados: boolean;


  opcoesComboTipoIngresso : SelectItem[];
  opcoesComboStatus       : SelectItem[];
  opcoesComboErro         : SelectItem[];
  colsFichas: any;

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titleService.title = 'Relatório das Fichas';
    this.title = 'Fichas';
    this.subtitle = 'Visualizar fichas';
    this.defaultDate = new Date();
    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;
    this.fichas = new Array<any>();
    this.modalPeriodo = false;
    this.saldoAtual = 0.00;
    this.valorFiltro = 0.00;
    this.idBoletosCheck = new Array();


    this.todosSelecionados = false;

    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };


    this.colsFichas = [
      { field: 'full_name', header: 'NOME' },
      { field: 'email', header: 'EMAIL' },
      { field: 'cpf', header: 'CPF' },
      { field: 'training_type_description', header: 'CURSO' },
      { field: 'ingresso', header: 'INGRESSO' },
      { field: 'start_date', header: 'DATA' },
      { field: 'Status', header: 'STATUS' },
      { field: 'motivo_erro', header: 'STATUS' },
      { field: 'valor', header: 'VALOR' }

    ];

    this.subscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { setTimeout(() => { this.toolbarEnable(); }, 1000); this.start(); });
  }

  start() {
    // formatação de datas
    let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    let mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
      if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
        mes = this.dblinkedSessionService.periodo.month.value > 8 ?
          this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
        const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
        this.defaultDate = new Date(dt);
        // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
        lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
        this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
        this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

      }

      this.ids = [];
      this.todosSelecionados = false;
      this.toolbarEnable();

      // final formatação de datas
      this.idBoletosCheck = new Array();
      this.modalPeriodo = false;
      this.saldoAtual = 0.00;
      this.valorFiltro = 0.00;

      //pegar os tipos de ingressos
      this.getComboTipoIngresso();

      // buscando as infos
      this.httpService.wait();
      this.httpService.get('/custom/polozi/get-fichas-local',
        [this.dblinkedSessionService.empresa.id, mes, this.dblinkedSessionService.periodo.year.value])
        .subscribe(retorno => {
          this.fichas = retorno;

          this.opcoesComboTipoIngresso = [];
          this.opcoesComboStatus = [];
          this.opcoesComboErro   = [];

          retorno.forEach(element =>{
            var index = 0;
            if (!isNullOrUndefined(this.opcoesComboTipoIngresso)) {
              index = this.opcoesComboTipoIngresso.findIndex(e => e.value === element.ingresso);
            }

            if (index === -1) {
              this.opcoesComboTipoIngresso.push({label: element.ingresso , value: element.ingresso});
            }

            index = 0;
            if (!isNullOrUndefined(this.opcoesComboStatus)) {
              index = this.opcoesComboStatus.findIndex(e => e.value === element.status);
            }
            if (index === -1) {
              this.opcoesComboStatus.push({label: element.status , value: element.status});
            }

            index = 0;
            if (!isNullOrUndefined(this.opcoesComboErro)) {
              index = this.opcoesComboErro.findIndex(e => e.value === element.motivo_erro);
            }
            if (index === -1) {
              this.opcoesComboErro.push({label: element.motivo_erro , value: element.motivo_erro});
            }


          });



        },
          error => this.httpService.handleError(error, () => this.start()),
          () => this.httpService.done());
      // final busca
    }

  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.sessionService.destroySubscribe(this.subscription);
  }

  private toolbarEnable() {
    const todos = this.buttons.slice();
    if (todos.length === 0) {
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      // this.toolbarService.replace('edit', false,
      //                             'class-button-gray', 'ATUALIZAR', 'ATUALIZAR', () => this.openModalPeriodo());
      this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
      this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');
      this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo');
    } else {
      this.toolbarService.ocultar('voltar');
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.ocultar('save');
      this.toolbarService.ocultar('edit');
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      // this.toolbarService.replace('edit', false,
      //                             'class-button-gray', 'ATUALIZAR', 'ATUALIZAR', () => this.openModalPeriodo());
      this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
      //       this.toolbarService.alterarStatus('check', false, 'Aprovar todos', () => this.modalStatusPedidosLote());
      this.toolbarService.replace('pesquisa', true, 'class-edit-button', 'search', 'Pesquisar');
      this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar');
      this.toolbarService.replace('print', true, 'class-edit-button', 'print', 'Imprimir');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');
      this.toolbarService.replace('add', true, 'class-new-button', 'ATUALIZAR', 'ATUALIZAR');
    }
  }

  selectFicha(id: any, ficha: any) {
    if (!ficha.selected) {
      this.ids.push(id);
      this.fichasSelecionadas.push(ficha);
    } else {
      let index1 = this.ids.indexOf(id);
      this.ids.splice(index1, 1);
      let index = this.ids.indexOf(ficha);
      this.fichasSelecionadas.splice(index, 1);
    }

     if (this.ids.length > 0){
      this.toolbarService.replace('remove', false, 'class-remove-button', 'delete', 'Excluir', () => this.excluirFicha());;
      if (this.ids.length === 1){
        this.toolbarService.replace('print', false, 'class-first-button', 'print', 'Imprimir', () => this.visualizarFicha());
      }else{
        this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir', () => this.visualizarFicha());
      }

      this.toolbarService.replace('add', false, 'class-new-button', 'ATUALIZAR', 'ATUALIZAR', () => this.atualizarFichas());

    } else {

    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir', () => this.visualizarFicha());
      this.toolbarService.replace('add', true, 'class-new-button', 'ATUALIZAR', 'ATUALIZAR');
    }
  }

  excluirFicha(){
    if (confirm('Deseja realmente excluir estas Fichas?')) {
    let listaEnviar = {
      fichas: this.ids
    }

    this.httpService.wait();
    this.httpService
      .post('/custom/polozi/excluir-fichas', listaEnviar)
      .subscribe(
        result => {
          result.forEach(element => {
          if (element.error === 'true') {
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: element.message });
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: element.message });
          }
        });
        this.start();
        },
        error =>
          this.httpService.handleError(error, () => this.atualizarFichas()),
        () => this.httpService.done()
      );
    }
  }

  selectTodos(event: any) {

      if(this.fichasFiltradas.length > 0){
        this.fichasSelecionadas = this.fichasFiltradas;
      }else{
        this.fichasSelecionadas = this.fichas;
      }

    if (!this.todosSelecionados) {

      this.fichasSelecionadas.forEach(slt => {
        slt.selected = true;
        this.ids.push(slt.id);
      });

      this.todosSelecionados = true;

    } else {

      this.fichasSelecionadas.forEach(sli => {
        sli.selected = false;
        let index1 = this.ids.indexOf(sli.id);
        this.ids.splice(index1, 1);
      });
      this.todosSelecionados = false;
      this.fichasSelecionadas = new Array();
    }

    if (this.ids.length > 0){
      this.toolbarService.replace('add', false, 'class-new-button', 'ATUALIZAR', 'ATUALIZAR', () => this.atualizarFichas());
      if(this.ids.length === 1){
        this.toolbarService.replace('print', false, 'class-first-button', 'print', 'Imprimir', () => this.visualizarFicha());
      }
    } else{
      this.toolbarService.replace('add', true, 'class-new-button', 'ATUALIZAR', 'ATUALIZAR');
    }



   }

  onFilter(event: any) {
    this.fichasFiltradas = new Array();
    event.filteredValue.forEach(element => {
        this.fichasFiltradas.push(element);
    });
 }



  atualizarFichas() {
    let listaEnviar = {
      fichas: this.ids
    }

    this.httpService.wait();
    this.httpService
      .post('/custom/polozi/atualizar-fichas', listaEnviar)
      .subscribe(
        result => {
          this.start();
        },
        error =>
          this.httpService.handleError(error, () => this.atualizarFichas()),
        () => this.httpService.done()
      );
  }

  visualizarFicha() {
    this.ids.forEach(fichaId => {
      this.httpService.wait();
      this.httpService.get('/custom/polozi/imprimir-polozi(' + fichaId + ')')
      .subscribe(result => this.exportedFicha(result),
      error => this.httpService.handleError(error, () => this.visualizarFicha()),
      () => this.httpService.done());
    });
  }

  exportedFicha(result?: any) {

    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/polozi_jasper'), '_blank');
      this.ids = new Array();
    } else {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: result.message });
      this.ids = new Array();
    }

    this.fichas.forEach(element => {
      element.selected = false;
    });

    this.toolbarService.alterarStatus('print', true, 'Imprimir', () => this.visualizarFicha() );


  }

  hideMessage() {


  }

  rowStyleClass(fichas: any): string {
    return fichas.status === 2 ||  fichas.status === 6 ? 'bold' : '';
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  getComboTipoIngresso(){
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-combo-tipo-ingresso')
      .subscribe(retorno => {
        this.opcoesComboTipoIngresso = [];

        retorno.forEach(element =>{
          this.opcoesComboTipoIngresso.push({label: element.codigo, value: element.codigo});
        });
      },
        error => this.httpService.handleError(error, () => this.getComboTipoIngresso()),
        () => this.httpService.done());

   }



}
