import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormArray, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import { DataSource } from '@angular/cdk/table';
import { MatSort } from '@angular/material/sort';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { CrudService } from 'app/shared/services/crud.service';

class Company {
  id: number;
  companyId: number;
  cnpjOrCpf: string;
  name: string;
  fantasy: string;
  active: boolean;
  lastNsu: number;
  changeTimestamp: Date;
  certificateFound: boolean;
  certificatePass: string;
  certificateExpiration: Date;
  certificateExpired: boolean;
  certificateToExpire: boolean;

  constructor() {
  }
}

export class CompanyDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  dataChange: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>([]);

  constructor(private _data: any,
              private _sort: MatSort) {
    super();

    const copiedData = this.data.slice();
    _data.value.forEach(company => {
      copiedData.push(company);
    });
    this.dataChange.next(copiedData);
  }

  get filter(): string {
    return this._filterChange.value;
  }
  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  connect(): Observable<Company[]> {
    const displayDataChanges = [
      this.dataChange,
      this._sort ? this._sort.sortChange : [],
      this._filterChange
    ];

    return Observable.merge(...displayDataChanges).map(() => {
      return this.getSortedData();
    });
  }

  disconnect() {
  }

  get data(): Company[] {
    return this.dataChange.value;
  }

  getSortedData(): Company[] {
    let data = this.data.slice();

    if (this.filter !== undefined && this.filter !== null && this.filter.trim() !== '') {
      data = this.data.slice().filter((company: Company) => {
        return (company.name + ' ' + company.fantasy).toLowerCase().indexOf(this.filter.toLowerCase()) > -1;
      });
    }

    if (this._sort && this._sort.active && this._sort.direction !== '') {
      data = data.sort((a, b) => {
        let propertyA: any = null;
        let propertyB: any = null;

        switch (this._sort.active) {
          case 'name': [propertyA, propertyB] = [a.name, b.name]; break;
          case 'cnpjOrCpf': [propertyA, propertyB] = [a.cnpjOrCpf, b.cnpjOrCpf]; break;
          case 'certificateExpiration': [propertyA, propertyB] = [a.certificateExpiration, b.certificateExpiration]; break;
        }

        const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
        const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

        return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
      });
    }

    return data;
  }
}

export type TrackByStrategy = 'id' | 'reference' | 'index';

@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.scss'],
  providers: [CrudService]
})
export class CertificadoComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('filter') filter: ElementRef;

  @ViewChild('fileInput') fileInput: ElementRef;
  fileList: FileList;

  company: Company = null;

  formGroup: FormGroup;
  private formValues: any;

  private defaultPass = '******';

  private empresaSubscription: Subscription;
  private formSubscription: Subscription;

  private _displayForm = false;

  displayedColumns = [ 'name', 'cnpjOrCpf', 'certificateExpiration', 'action' ];
  dataSource: CompanyDataSource | null;

  trackByStrategy: TrackByStrategy = 'reference';

  constructor(private formBuilder: FormBuilder,
              private crudService: CrudService,
              private httpService: HttpService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toolbarService: ToolbarService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService) {
    this.formGroup = this.formBuilder.group([]);

    this.formGroup.addControl('file',
                              new FormControl({ value: null, disabled: false }));
    this.formGroup.addControl('pass',
                              new FormControl({ value: null, disabled: false },
                                                [ Validators.required, Validators.minLength(4), Validators.maxLength(50) ]));
    this.formGroup.addControl('passConfirm',
                              new FormControl({ value: null, disabled: false },
                                                [ Validators.required, Validators.minLength(4), Validators.maxLength(50) ]));

    this.activatedRoute.params.subscribe(params => {
      const id: number = params['id'];

      this._displayForm = false;

      this.company = new Company();
      this.company.companyId = id;

      if (this.company.companyId > 0) {
        this.loadCompany(id);
      } else {
        this.loadCompanies();
      }
    });
  }

  ngOnInit() {
    Promise.resolve(null).then(() => { this.dblinkedSessionService.disableEmpresa = true; });

    Observable.fromEvent(this.filter.nativeElement, 'keyup')
              .debounceTime(150)
              .distinctUntilChanged()
              .subscribe(() => {
                if (!this.dataSource) { return; }
                this.dataSource.filter = this.filter.nativeElement.value;
              });
  }

  ngOnDestroy() {
    this.dblinkedSessionService.disableEmpresa = false;

    // this.toolbarService.clear();

    this.sessionService.destroySubscribe(this.empresaSubscription,
                                         this.formSubscription);
  }

  init() {
    this._displayForm = true;

    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
                                                                 () => { this.empresaChanged(); });
  }

  companyTrackBy = (index: number, company: Company) => {
    switch (this.trackByStrategy) {
      case 'id': return company.id;
      case 'reference': return company;
      case 'index': return index;
    }
  }

  edit(company: Company) {
    this.company = null;
    console.log(company);
    this.router.navigateByUrl('/empresa/certificado-a1/' + company.id);
  }

  empresaChanged() {
    this.loadCompany();
  }

  loadCompanies() {
    this.httpService.wait();
    this.httpService.get('/custom/empresa/certificate')
                    .subscribe(companies => {
                                if (companies && companies.count > 1) {
                                  this.dataSource = new CompanyDataSource(companies, this.sort);
                                } else {
                                  this.init();
                                }
                              },
                              error => {
                                if (parseInt(error.status, 10) === this.httpService.UNAUTHENTICATED && this.httpService.authenticated) {
                                  this.httpService.done();

                                  this.init();
                                } else {
                                  this.httpService.handleError(error, () => this.loadCompanies());
                                }
                              },
                              () => this.httpService.done());
  }

  loadCompany(id: number = null) {
    this.company = null;

    this.fileList = null;
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }

    this.formGroup.reset();

    // this.toolbarService.clear();

    if (id !== null || this.dblinkedSessionService.hasEmpresaSelected) {
      const url = id !== null ?
                  '/custom/empresa/' +  id + '/certificate' :
                  '/custom/empresa/cnpj(' +  this.dblinkedSessionService.empresa.cnpj + ')/certificate';

      this.httpService.wait();
      this.httpService.get(url)
                      .subscribe(company => {
                                   this.company = company || null;

                                   if (this.company && !this.company.companyId) {
                                     this.company.companyId = company.id;
                                   }

                                   const fileControl: AbstractControl = this.formGroup.get('file');

                                   if (this.company &&
                                       this.company.certificateExpiration !== undefined &&
                                       this.company.certificateExpiration !== null) {
                                     this.formGroup.get('pass').setValue(this.defaultPass);
                                     fileControl.clearValidators();
                                   } else {
                                     fileControl.setValidators([ Validators.required ]);
                                   }
                                   fileControl.updateValueAndValidity();

                                   // this.toolbarService.clear();
                                   if (id !== null) {
                                    this.toolbarService.alterarStatus('voltar', false, 'Voltar',
                                                                      () => this.router.navigateByUrl('/empresa/certificado-a1'));
                                     // this.toolbarService.replace('back', false, 'chevron_left', 'Voltar', 'VOLTAR',
                                    //                             () => this.router.navigateByUrl('/empresa/certificado-a1'));
                                   }

                                   this.formValues = this.formGroup.getRawValue();

                                   this.formSubscription = this.formGroup.statusChanges.subscribe(status => {
                                     this.toolbarService.alterarStatus('voltar', false, 'Desfazer alterações', () => {
                                       this.fileList = null;
                                       if (this.fileInput && this.fileInput.nativeElement) {
                                         this.fileInput.nativeElement.value = '';
                                       }

                                       this.empresaChanged();
                                     });

                                     if (status === 'VALID' && (this.isEqualPasswords || this.isPristinePassword)) {
                                       this.toolbarService.alterarStatus('save', false, 'Salvar alterações', () => {
                                         this.save();
                                       });
                                     } else {
                                      this.toolbarService.alterarStatus('save', true, 'Salvar alterações', () => this.save());
                                     }
                                   });
                                 },
                                 error => {
                                   if (parseInt(error.status, 10) === this.httpService.NOT_FOUND) {
                                     this.httpService.done();
                                   }  else {
                                     this.httpService.handleError(error, () => this.empresaChanged());
                                   }
                                 },
                                 () => this.httpService.done());
    }
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get displayForm(): boolean {
    return (this.company && this.company.companyId > 0) || (this._displayForm && this.dblinkedSessionService.hasEmpresaSelected);
  }

  get displayPasswordConfirm(): boolean {
    const passControl: AbstractControl = this.formGroup.get('pass');

    return passControl.value !== undefined &&
           passControl.value !== null &&
           passControl.value.trim() !== '' &&
           !this.isPristinePassword &&
           passControl.valid;
  }

  get isEqualPasswords(): boolean {
    return this.formGroup.get('pass').value === this.formGroup.get('passConfirm').value;
  }

  get isEmptyPassword(): boolean {
    const passControl: AbstractControl = this.formGroup.get('pass');

    return passControl.value === undefined ||
           passControl.value === null ||
           passControl.value.trim() === '';
  }

  get isDefaultPassword(): boolean {
    return this.formGroup.get('pass').value === this.defaultPass;
  }

  get isPristinePassword(): boolean {
    return this.formGroup.get('pass').pristine;
  }

  fileSanitize(file: string) {
    return file.replace(/[^a-zA-Z0-9_-]/g, '').toLowerCase();
  }

  fileChange(fileList: FileList) {
    this.fileList = fileList;

    if (this.company !== null) {
      this.company.certificateExpiration = null;
      this.company.certificateExpired = false;
      this.company.certificateToExpire = false;
    }

    if (this.isDefaultPassword) {
      const passControl = this.formGroup.get('pass');
      passControl.setValue(null);
      passControl.markAsTouched();
      passControl.markAsDirty();
      passControl.updateValueAndValidity();
    }
  }

  fileUpload(company: Company) {
    if (this.fileList && this.fileList.length === 1) {
      const data: FormData = new FormData();
      data.append('file', this.fileList.item(0));
      data.append('replace', 'true');
      data.append('destination', '/home/ec2-user/certificates/' + company.companyId + '.' +
                                 this.fileSanitize(company.fantasy || company.name) + '.pfx');

      this.httpService.wait();
      this.httpService.post('../upload', data)
                      .subscribe(result => this.empresaChanged(),
                                 error => this.httpService.handleError(error, () => this.fileUpload(company)),
                                 () => this.httpService.done());
    } else {
      this.empresaChanged();
    }
  }

  save(callback: Function = null) {
    if (!this.company) {
      this.company = new Company();
    }

    Object.keys(this.formGroup.controls).forEach((key: string) => {
      this.company[key] = this.formGroup.get(key).value;
    });

    this.company.certificatePass = this.company['pass'];

    this.company.certificateExpiration = null;
    this.company.certificateExpired = false;
    this.company.certificateToExpire = false;

    this.company.active = true;
    delete(this.company['pass']);
    delete(this.company['passConfirm']);
    delete(this.company['file']);

    if (this.company.companyId) {
      this.httpService.wait();
      this.httpService.patch('/company', this.company.companyId, this.company, 'auditor')
                      .subscribe(result => this.fileUpload(this.company),
                                 error => this.httpService.handleError(error, () => this.save()),
                                 () => this.httpService.done());
    } else {
      this.company.cnpjOrCpf = this.dblinkedSessionService.empresa.cnpj;
      this.company.fantasy = this.dblinkedSessionService.empresa.nomeFantasia;
      this.company.name = this.dblinkedSessionService.empresa.razaoSocial;

      this.httpService.wait();
      this.httpService.post('/company', this.company, 'auditor')
                      .subscribe(company => {
                                   this.company.companyId = company.id;

                                   this.fileUpload(this.company);
                                 },
                                 error => this.httpService.handleError(error, () => this.save()),
                                 () => this.httpService.done());
    }
  }
}
