import { isNullOrUndefined } from "util";

export class PalestraClienteStatus{

  id                : number;
  descricao         : string;
  ativo             : boolean;
  dataObrigatoria   : boolean;


  constructor(palestraClienteStatus?: PalestraClienteStatus | number) {
    if (!isNullOrUndefined(palestraClienteStatus)) {
      if (typeof palestraClienteStatus === 'number') {
        this.id = palestraClienteStatus;
      } else {
        this.id         = palestraClienteStatus.id;
        this.descricao  = palestraClienteStatus.descricao;
        this.ativo      = palestraClienteStatus.ativo;
        this.dataObrigatoria = palestraClienteStatus.dataObrigatoria;

      }
    }
  }

}