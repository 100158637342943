import { Component, OnInit, OnDestroy } from '@angular/core';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { EmpresaAlmoxarifado } from '../model/empresa-almoxarifado.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { MessageService } from 'primeng/api';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-empresa-almoxarifado',
  templateUrl: './empresa-almoxarifado.component.html',
  styleUrls: ['./empresa-almoxarifado.component.scss'],
  providers: [CrudService]
})
export class EmpresaAlmoxarifadoComponent extends DataTableBasicComponent
  implements OnInit, OnDestroy {
  public empresaAlmoxarifado: EmpresaAlmoxarifado = new EmpresaAlmoxarifado();
  public registrosAlmoxarifado: Array<EmpresaAlmoxarifado> = new Array();

  ids: Array<any> = new Array();

  //Boolean
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;
  check: boolean = false

  //Strings
  codAlmo: string;
  parentAlmo: string;
  entityFilter: string = null;

  //Any
  descAlmo: any;
  parent: Array<any> = new Array();

  //Select
  opcoesParent: SelectItem[]

  title = 'EMPRESA ALMOXARIFADO';
  subtitle = "EMPRESA ALMOXARIFADO";

  public empresaSelecionada: number;
  private empresaSubscription: Subscription;
  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeEditSubscription: Subscription;


  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private toolbarService: ToolbarService,
    private messageService: MessageService,
    private configService: ConfigService,
    private utilService: UtilService) {
    super(crudService);
  }

  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]

  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
    );

  }

  carregar(){
    this.title = 'EMPRESA ALMOXARIFADO';
    this.subtitle = 'EMPRESA ALMOXARIFADO';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();

    this.toolbarMain.setVisible('btn1', false)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', true)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', false)

    this.limpaCamposForm();
    this.loadRegistros();
  }
  selectCheckboxContainer(data){
    setTimeout(() => {
    document.getElementById('checkboxContainer'+data.id).click()
    }, );
  };

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar Empresa Almoxarifado';

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', true)
    this.toolbarMain.setVisible('btn6', false)

    this.loadLista();
  }

  loadLista(){
    this.opcoesParent = [];
    this.opcoesParent.push({ label: 'Selecione', value: null }, { label: 'Nenhum', value: 0 });
    this.httpService.get(`/empresaalmoxarifado?$select=id,codigo&$orderby=codigo`)
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesParent.push({
            label: element.codigo,
            value: element.id
          });
        });
      }, error => this.httpService.handleError(error, () => this.httpService.done()));
  }

  loadRegistros(){
    this.httpService.wait();
    this.httpService
    .get(`/empresaalmoxarifado?$select=codigo,descricao,parent/codigo&$filter=empresa/id eq ${this.dblinkedSessionService.empresa.id}&$orderby=codigo`)
    .subscribe(
      (retorno) => {
          this.registrosAlmoxarifado = new Array();
          retorno.value.forEach(item => {
            this.registrosAlmoxarifado.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar Empresa Almoxarifado';
    this.editarConta = true;
    this.loadLista();

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', true)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', true)

    this.limpaCamposForm();

    this.httpService.get(`/empresaalmoxarifado(${this.ids[0]})?$select=codigo,descricao,parent/id,parent/codigo&$count=true`)
      .subscribe(item => {
        this.codAlmo = item.value.codigo;
        this.descAlmo = item.value.descricao;
        this.parentAlmo = item.value.parent.id;
      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());
  }

  filtrarVazio(jsondata) {
    if(this.parentAlmo === null || this.parentAlmo == undefined) {
       delete jsondata['parent']
    }
    if(this.parentAlmo == null || this.parentAlmo == '') {
      delete this.parentAlmo;
    }

  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      descricao: this.descAlmo,
      empresa: {
        id: this.dblinkedSessionService.empresa.id
      },
      parent: {
         id: this.parentAlmo

       },
      codigo: this.codAlmo
    };
    this.filtrarVazio(jsonData);


    this.httpService.post('/empresaalmoxarifado', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro cadastrado com sucesso!'
            });
            this.carregar();
        }else{
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
            });
            this.carregar();
            return;
        }
      }, error => this.httpService.handleError(error, () =>  this.salvarNovoRegistro()),
      () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      descricao: this.descAlmo,
      parent: {
         id: this.parentAlmo
       },
      codigo: this.codAlmo
    };
    this.filtrarVazio(jsonData);

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/empresaalmoxarifado', key, jsonData, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
      error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
      () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      this.httpService.wait();
      this.httpService.delete('/empresaalmoxarifado', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Registro excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
            this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.loadEditarRegistro());
            this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.carregar());
            this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirRegistro());

          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
    }
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
  }

  selectCheckbox(event: any){
    this.ids.push(event.data.id);
    event.selected = false;
    this.changeToolbar();

    const check = this.ids[this.ids.length - 1];
    this.ids.length = 0;
    this.ids.push(check)
  }

  unselectCheckbox(event: any){
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.changeToolbarUn();
    console.log(this.ids)
  }

  changeToolbar() {
    this.toolbarMain.setVisible('btn2', true)
    this.toolbarMain.setVisible('btn3', true)
  }

  changeToolbarUn(){
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
  }

  validacao(): boolean {
    if ((this.codAlmo === null || this.codAlmo === undefined) || this.codAlmo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório' });
      return true;
    }
    if ((this.descAlmo === null || this.descAlmo === undefined) || this.descAlmo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição é Obrigatório' });
      return true;
    }
    if ((this.parentAlmo === null || this.parentAlmo === undefined) || this.parentAlmo.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Almoxarifado Pai é Obrigatório' });
      return true;
    }
    return false;
  }

  private limpaCamposForm(){
    this.codAlmo = '';
    this.descAlmo = '';
    this.parentAlmo = '';
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}






function add_event(arg0: string) {
  throw new Error('Function not implemented.');
}

