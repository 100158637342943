import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { Message, MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class AnaliseXmlImportPlanilhaComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;

  file: File;
  replace: boolean;

  msgs: Message[] = null;

  constructor(private titleService: TitleService,
              private httpService: HttpService,
              private toolbarService: ToolbarService,
              private dblinkedSessionService: DblinkedSessionService,
              private sessionService: SessionService,
              private messageService: MessageService
            ) {
  }

  ngOnInit(): void {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titleService.title = 'Importar planilha';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    // this.toolbarService.clear();
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    // this.toolbarService.clear();
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
  }

  importar() {
    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('type', 'xml-faltante');
    data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
    data.append('usuarioId', this.sessionService.loggedUser.id.toString());
    data.append('month', this.dblinkedSessionService.periodo.month.value.toString());
    data.append('year', this.dblinkedSessionService.periodo.year.value.toString());

    this.httpService.wait();
    this.httpService.post('/custom/upload', data)
                    .subscribe(result => this.uploaded(result),
                               error => this.httpService.handleError(error, () => this.importar()),
                               () => this.httpService.done());
  }

  private uploaded(res?: any) {



    if (res.result.error === true) {
        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: res.result.message});
    } else {
      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: res.result.message});
      window.open(this.httpService.url('/custom/file/' + res.result.filename + '/xml-faltante'), '_blank');
    }

  }

  get importDisabled(): boolean {
    return !this.dblinkedSessionService.hasEmpresaSelected || !this.file;
  }

  hideMessage() {
    this.file = null;

  }
}
