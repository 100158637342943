import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'cadastro-regra-centralweb',
  templateUrl: './cadastro-regra-centralweb.component.html',
  styleUrls: ['./cadastro-regra-centralweb.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadastroRegraCentralWebComponent implements OnInit, OnDestroy {
  /////////////////////////////
  // REFATOR SIMON LALLEMENT //
  // 26/07/2021              //
  /////////////////////////////

  private sessionSubscription: Subscription;

  //Strings
  titlePrincipal: string = 'CADASTRO CENTRALWEB';
  currentStateCentralWebRegras: string = 'initial';

  //Messages
  msgs: Message[] = [];

  //Boolean
  isCardCentralWebRegras: boolean = true;

  //Dropdown
  operacoesRegrasContabeis: SelectItem[] = [];

  //Number
  operacaoEscolhida: number = 0;

  //Array
  listaObrigacaoRelacionar: Array<any> = new Array();
  listaObrigacaoRelacionadas: Array<any> = new Array();

  toolbarMainIni = [
    { icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
      this.ShowHideCentralWebRegras()
      this.changeStateCentralWebRegras()
    } },
  ]

  public toolbarMain = new Toolbar();

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.titleService.title = 'Cadastro de regras CentralWeb';
    this.toolbarService.hidden = true;
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });
    this.toolbarMain.setAll(this.toolbarMainIni)
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.httpService.wait();
      this.httpService.get(this.configService.defaultUrlApiUpload + 'get_distinct_operacao_regra_contabil/' + this.dblinkedSessionService.empresa.id)
        .subscribe(
          res => {
            if (res["severity"] === "error") {
              this.exibirMensagem(res)

            } else {
              this.operacoesRegrasContabeis = [];
              res["data"].forEach(element => { this.operacoesRegrasContabeis.push(element) });
            }
            this.httpService.done()
          },
          err => {
            console.log(err)
            this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + err.message })
            this.httpService.done()
          }
        )
    }
  }

  exibirMensagem(obj) {
    if (obj.severity === "error") {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: obj.message
      })

    } else if (obj.severity === "warn") {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Atenção', detail: obj.message
      })

    } else if (obj.severity === "info") {
      this.messageService.add({
        severity: 'info', key: 'messageLinnks', summary: 'Informação', detail: obj.message
      })

    } else {
      this.messageService.add({
        severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: obj.message
      })
    }
  }

  hideMessage() {

  }

  salvarRegrasObrigacao() {
    if (this.operacaoEscolhida === 0) {
      this.exibirMensagem({ "severity": "warn", "message": "Selecione uma operação contabil." })
      return
    }

    const info = {
      liobrigacoes: this.listaObrigacaoRelacionadas,
      regra_contabil_id: this.operacaoEscolhida,
      user_id: this.sessionService.loggedUser.id,
      empresa_id: this.dblinkedSessionService.empresa.id
    };

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'insert_operacao_regra_obrigacao_relac', info)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.buscarListaObrigacoes()
          this.httpService.done()
        },
        err => {
          console.log(err)
          this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + err.message })
          this.httpService.done()
        }
      )
  }

  buscarListaObrigacoes() {
    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'get_obrigacao/' + this.dblinkedSessionService.empresa.id + '_' + this.operacaoEscolhida)
      .subscribe(
        res => {
          if (res["severity"] === "error") {
            this.exibirMensagem(res)

          } else {
            this.listaObrigacaoRelacionadas = new Array();
            this.listaObrigacaoRelacionar = new Array();
            res["data"][0].forEach(element => this.listaObrigacaoRelacionar.push(element))
            res["data"][1].forEach(element => this.listaObrigacaoRelacionadas.push(element))
          }
          this.httpService.done()
        },
        err => {
          console.log(err)
          this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + err.message })
          this.httpService.done()
        }
      )
  }

  ShowHideCentralWebRegras() {
    this.isCardCentralWebRegras = !this.isCardCentralWebRegras;
    this.toolbarMain.setIcon('btn1', this.isCardCentralWebRegras ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn1', this.isCardCentralWebRegras ? 'Ver menos' : 'Ver mais')
  }

  changeStateCentralWebRegras() {
    this.currentStateCentralWebRegras = this.currentStateCentralWebRegras === 'initial' ? 'final' : 'initial';
  }
}
















// import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

// // import { Empresa } from 'app/cadastro/model/empresa.model';
// // import { Subscription } from 'rxjs/Subscription';
// // import { SelectItem } from 'app/shared/common/select-item.interface';
// // import { TitleService } from 'app/shared/services/title.service';
// // import { ToolbarService } from 'app/shared/services/toolbar.service';
// // import { UtilService } from 'app/shared/services/util.service';
// // import { ConfigService } from 'app/shared/services/config.service';
// // import { HttpService } from 'app/shared/services/http.service';
// // import { SessionService } from 'app/shared/services/session.service';
// // import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
// // import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
// // import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
// // import { Message } from 'primeng/api';
// // import { PickList, Dropdown } from 'primeng/primeng';
// import { trigger, state, style, animate, transition } from '@angular/animations';
// // import { isNullOrUndefined } from 'util';
// // declare var $: any;

// @Component({
//   selector: 'cadastro-regra-centralweb',
//   templateUrl: './cadastro-regra-centralweb.component.html',
//   styleUrls: ['./cadastro-regra-centralweb.component.scss'],
//   animations: [
//     trigger('changeDivSize', [
//       state('initial', style({

//       })),
//       state('final', style({
//         opacity: '0',
//         height: '0',
//         padding: '0px 15px',

//       })),
//       transition('initial=>final', animate('200ms')),
//       transition('final=>initial', animate('200ms'))
//     ]),
//   ]
// })

// export class CadastroRegraCentralWebComponent implements OnInit, OnDestroy {
// //   @ViewChild('pickList') pickList: PickList;

// //   currentStateCentralWebRegras = 'initial';
// //   changeStateCentralWebRegras() {
// //     this.currentStateCentralWebRegras = this.currentStateCentralWebRegras === 'initial' ? 'final' : 'initial';
// //   }

//   private sessionSubscription: Subscription;

// //   msgs: Message[] = [];

// //   titlePrincipal: string;
// //   subtitle: string;

// //   operacoesRegrasContabeis: SelectItem[];
// //   operacaoEscolhida: any;

// //   listaObrigacaoRelacionar: Array<any> = new Array();
// //   listaObrigacaoRelacionadas: Array<any> = new Array();

// //   url_environment: any;

//   constructor(private titleService: TitleService,
// //     private configService: ConfigService,
// //     private toolbarService: ToolbarService,
// //     private httpService: HttpService,
// //     private utilService: UtilService,
// //     private sessionService: SessionService,
// //     private modalService: NgbModal,
//     private dblinkedSessionService: DblinkedSessionService) {}

//   ngOnInit() {
// //     // requisição

// //     this.titlePrincipal = 'CONFIGURAÇÕES';
// //     this.subtitle = 'CADASTRO REGRAS CENTRALWEB';
// //     this.titleService.title = 'Cadastro de Regras para os registros dos arquivos enviados na CentralWeb';
// //     this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
// //     this.operacaoEscolhida = null;
// //     this.url_environment = this.configService.defaultUrlApiUpload;

// //     this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
// //       this.change();
// //     });
//     }

//   ngOnDestroy() {
//     this.sessionService.destroySubscribe(this.sessionSubscription);
//   }

// //   change() {
// //     if (this.dblinkedSessionService.hasEmpresaSelected &&
// //       this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
// //       && this.dblinkedSessionService.periodo.year) {

// //         // Assim que a tela é inicializada, chamar a função Python
// //         // que obtém todas as operações das regras contábeis
// //         this.listaObrigacaoRelacionar = new Array();
// //         this.listaObrigacaoRelacionadas = new Array();
// //         this.operacoesRegrasContabeis = [];
// //         this.operacoesRegrasContabeis.push({ label: 'Selecione', value: 0 });
// //         this.operacaoEscolhida = null;

// //         this.httpService.wait();
// //         this.httpService.get(this.url_environment + '/get_distinct_operacao_regra_contabil/' +
// //         this.dblinkedSessionService.empresa.id)
// //           .subscribe(retorno => {
// //             retorno.forEach(element => {
// //               this.operacoesRegrasContabeis.push({ label: element.descricao, value: element.id });
// //             });
// //           }, error => this.httpService.handleError(error, () => this.change( )),
// //             () => this.httpService.done());


// //       // renderizando a toolbar
// //       setTimeout(() => {
// //         this.toolbarEdit();
// //       }, 500);

// //     }
// //   }

// //   hideMessage() {
// //     this.msgs = [];
// //   }

// //   private toolbarEdit() {
// //     const todos = this.buttons.slice();
// //     this.toolbarService.ocultar('voltar');
// //     this.toolbarService.ocultar('download');
// //     this.toolbarService.ocultar('cancel');
// //     this.toolbarService.ocultar('check');
// //     this.toolbarService.ocultar('info');
// //     this.toolbarService.ocultar('msg');
// //     this.toolbarService.ocultar('atualizar');
// //     this.toolbarService.ocultar('save');
// //     this.toolbarService.ocultar('more_vert');
// //     this.toolbarService.ocultar('pesquisa');
// //     this.toolbarService.ocultar('export');
// //     this.toolbarService.ocultar('print');
// //     this.toolbarService.ocultar('add');
// //     this.toolbarService.ocultar('edit');
// //     this.toolbarService.ocultar('remove');
// //   }

// //   get empresa(): Empresa {
// //     return this.dblinkedSessionService.empresa;
// //   }

// //   get buttons(): Array<ToolbarButton> {
// //     return this.toolbarService.buttons;
// //   }

// //   get rowsPerPage() {
// //     return this.configService.applicationConfig.rowsPerPage;
// //   }

// //   get rows() {
// //     return this.configService.applicationConfig.rows;
// //   }

// //   get pageLinks() {
// //     return this.configService.applicationConfig.pageLinks;
// //   }

// //   salvarRegrasObrigacao(){

// //     this.msgs= [];
// //     if (isNullOrUndefined(this.operacaoEscolhida) || this.operacaoEscolhida === 0) {
// //       this.msgs = [{
// //         severity: 'warn',
// //         summary: 'MSG: ',
// //         detail: 'Nenhuma Operação Regra selecionada!'
// //       }];
// //       return true;
// //     }

// //     if (confirm('Deseja salvar este relacionamento?')) {

// //       let listaEnviar = {
// //         operacaoRegraContabilId : this.operacaoEscolhida,
// //         listaObrigacaoRelacionadas : this.listaObrigacaoRelacionadas,
// //         empresaId : this.dblinkedSessionService.empresa.id,
// //         usuarioId : this.sessionService.loggedUser.id
// //       }

// //       this.httpService.wait();
// //       this.httpService.post(this.url_environment + '/insert_operacao_regra_obrigacao_relac/', listaEnviar)
// //       .subscribe(result => {
// //         this.msgs = [];
// //         if (result["status"] === "error") {
// //           this.msgs.push({ severity: 'error', summary: '', detail: result["mensagem"] });
// //         } else {
// //           this.msgs.push({ severity: 'success', summary: '', detail: result["mensagem"] });
// //         }
// //         this.change();
// //         },
// //           error => this.httpService.handleError(error, () => this.salvarRegrasObrigacao()),
// //           () => this.httpService.done());
// //     }
// //   }


// //   buscarListaObrigacoes(){
// //     this.listaObrigacaoRelacionar = new Array();
// //     this.listaObrigacaoRelacionadas = new Array();

// //     this.httpService.wait();
// //     this.httpService.get(this.url_environment + '/get_obrigacao/' +
// //         this.dblinkedSessionService.empresa.id + '_' + this.operacaoEscolhida)
// //       .subscribe(result => {
// //         result.forEach(element => {
// //             if(element.relac){
// //             this.listaObrigacaoRelacionadas.push(element);
// //           }else{
// //             this.listaObrigacaoRelacionar.push(element);
// //           }
// //         });
// //       }, error => this.httpService.handleError(error, () => this.buscarListaObrigacoes()),
// //         () => this.httpService.done() );

// //   }

// //   /*Show Hide CentralWeb Regras*/
// //   isCardCentralWebRegras: boolean = true;
// //   ShowHideCentralWebRegras(){
// //     this.isCardCentralWebRegras = !this.isCardCentralWebRegras;
// //   }
// }
