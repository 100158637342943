import { Empresa } from "app/cadastro/model/empresa.model";
import { isNullOrUndefined } from "util";

export class Area {
  id: string;
  descricao: string;
  codigo: string;
  data: string;
  selected: boolean;
  empresaId: Empresa;
  constructor(area?: Area | number) {
    if (!isNullOrUndefined(area)) {
      if (typeof area === 'number') {
        this.id = area.toString();
      } else {
        this.id = area.id;
        this.descricao = area.descricao;
        this.codigo = area.codigo;
        this.data = area.data;
        this.selected = false;
        this.empresaId = area.empresaId;
      }
    }
  }
}
