import { Component, OnInit, OnDestroy } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message } from '../../../../node_modules/primeng/api';
import { ActivatedRoute } from '@angular/router';
import { CrudService } from 'app/shared/services/crud.service';

@Component({
  selector: 'app-leadlovers',
  templateUrl: './leadlovers.component.html',
  styleUrls: ['./leadlovers.component.scss'],
  providers: [CrudService]
})
export class LeadLoversComponent implements OnInit, OnDestroy {
  usuarios: Array<any> = new Array();


  private sessionSubscription: Subscription;
  pt: any;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  msgs: Message[];
  tipo: number;
  colsUsuarios: any[] = [];

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService
  ) { }

  ngOnInit(): void {

    this.titlePrincipal = 'LEADLOVERS';
    this.titleService.title = 'Cadastro de Usuários LeadLovers';
    this.subtitle = 'Cadastro de Usuários no Portal Vida Plena';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.colsUsuarios = [
      { field: 'usuario', header: 'NOME' },
      { field: 'email', header: 'EMAIL' },
      { field: 'dtRegistro', header: 'DT REGISTRO' },
      { field: 'pagtoConfirmadoPt', header: 'PAGO' },
      { field: 'usuarioGeradoPt', header: 'GERADO' },
      { field: 'plano', header: 'PLANO' },
      { field: 'duracao', header: 'DURAÇÃO' },
      { field: 'dtExpira', header: 'DT EXPIRA' },
      { field: 'usuarioPai', header: 'USUÁRIO PAI' },
      { field: 'formaPagto', header: 'FORMA PAGTO' },
      { field: 'tipoPagto', header: 'TIPO PAGTO' }
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
      this.toolbaratualiza();
    });

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }
  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  toolbaratualiza() {
    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');

    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.change()
    );
    this.toolbarService.ocultar('save');

    this.toolbarService.replace('more_vert', true, 'class-edit-button', 'more_vert', 'Opções');
    this.toolbarService.ocultar('pesquisa');

    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir Pedido',
    );
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('edit', true, 'class-edit-button', 'list', 'Alterar Títulos Selecionados');

    this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo Evento'
    );
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {


      this.httpService.wait();
      this.httpService.get('custom/leadlovers/buscar-usuarios-leadlovers',
        [this.dblinkedSessionService.empresa.id])
        .subscribe(retorno => {
          console.log(retorno);
            this.usuarios = new Array();
            retorno.forEach(element => {
                this.usuarios.push(element);

            });
        }, error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());

    }
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {

  }

  onFilter(event: any){

  }

}
