import { MovParteAIrpj } from 'app/ecf/model/mov-parte-a-irpj.model';
import { MovSaldosContabeis } from 'app/contabil/model/mov-saldos-contabeis.model';

export class TbRelacMovParteAIrpjSaldosContabeis {
    id: number;
    movParteAIrpj: MovParteAIrpj;
    movSaldosContabeis: MovSaldosContabeis;
    saldoRelacionado: number;

    saldoRelacionadoEditing: number = null;
    changed = false;

    constructor(tbRelacMovParteAIrpjSaldosContabeis?: TbRelacMovParteAIrpjSaldosContabeis | number) {
        if (tbRelacMovParteAIrpjSaldosContabeis !== undefined && tbRelacMovParteAIrpjSaldosContabeis !== null) {
            if (typeof tbRelacMovParteAIrpjSaldosContabeis === 'number') {
                this.id = tbRelacMovParteAIrpjSaldosContabeis;
            } else {
                this.id = tbRelacMovParteAIrpjSaldosContabeis.id;
                this.movParteAIrpj = tbRelacMovParteAIrpjSaldosContabeis.movParteAIrpj || new MovParteAIrpj();
                this.movSaldosContabeis = tbRelacMovParteAIrpjSaldosContabeis.movSaldosContabeis || new MovSaldosContabeis();
                this.saldoRelacionado = tbRelacMovParteAIrpjSaldosContabeis.saldoRelacionado;

                this.saldoRelacionadoEditing = tbRelacMovParteAIrpjSaldosContabeis.saldoRelacionadoEditing || null;
                this.changed = tbRelacMovParteAIrpjSaldosContabeis.changed || false;
            }
        }
    }
}
