import { Injectable, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { TitleService } from './title.service';
import { Md5Service } from './md5.service';
import { ToolbarService } from './toolbar.service';
import { SessionService } from './session.service';
import { NavigationMenuService } from './navigation-menu.service';
import { SocketService } from './socket.service';
import { AuthCrowd } from 'app/proof/model/auth-crowd.model';
import { AuthCredentials } from 'app/proof/model/auth-credentials.model';
import { AuthLoggedUser } from 'app/proof/model/auth-logged-user.model';
import { AuthFeatureUrl } from 'app/proof/model/auth-feature-url.model';
import { Subscription } from 'rxjs/Subscription';

@Injectable()
export class LoginService {
  private OFFLINE = 0;
  private OK = 200;
  private UNAUTHENTICATED = 401;
  private NOT_FOUND = 404;
  // private TOOMANY = 405;

  private ALL = '*';

  public error: boolean = null;
  public errorMessage: string = null;
  public url: string = null;

  public crowds: Array<AuthCrowd> = new Array();
  private authCredentials: AuthCredentials = new AuthCredentials();

  public token: string = null;
  public resetSent = false;
  public resetErrorMessage: string = null;
  public resetState: boolean = false

  private messagesSubscription: Subscription;
  public forceReset: boolean = false;

  constructor(private sessionService: SessionService,
              private titleService: TitleService,
              private httpClient: HttpClient,
              private router: Router,
              private md5Service: Md5Service,
              private configService: ConfigService,
              private httpService: HttpService,
              private toolbarService: ToolbarService,
              private socketService: SocketService) {
    this._init();

    // TODO: Review
    if (this.authenticated) {
      setTimeout(() => {
        this.httpService.authenticate.next(true);

        this.socketService.send({
          action: 'login',
          loggedUserId: this.loggedUser.id
        });
      }, 50);
    } else {
      this.httpService.authenticate.next(false);
    }

    this.messagesSubscription = this.socketService.messages
                                    .subscribe(data => {
                                      switch (data.action) {
                                        case 'login':
                                          if(data.loggedUserId.toString().indexOf('-') === 0){
                                          if (this.authenticated &&
                                              this.configService.uniqueLogin &&
                                              this.loggedUser && this.loggedUser.id === data.loggedUserId) {

                                            this.doLogout(true);
                                          } else {
                                            this.authVerify();
                                          }
                                        }
                                          break;
                                        case 'logoff':
                                          if (this.configService.uniqueLogin &&
                                              this.loggedUser && this.loggedUser.id === data.loggedUserId) {
                                            this.doLogout(true);
                                          }
                                          break;
                                      }
                                    });
  }

  // TODO: REVIEW
  init(url: string) {
    this.sessionService.navigationMenu = new NavigationMenuService(this.configService);
    this.sessionService.navigationMenu.initMenu(url);

    // this.toolbarService.clear();

    if (this.isLogoutPath(url)) {
      this.doLogout();
    } else {
      this._init();

      this.url = url;
    }
  }
  private _init() {
    const data: any = JSON.parse(localStorage.getItem('data'));
    if (data !== undefined && data !== null) {
      this.authSuccess(data);
    }
  }

  loadCrowds() {
    if (this.configService.crowdAuth && this.login && this.login.trim()) {
      this.httpService.wait();
      this.httpService.get(this.configService.proofAuthUrl + '/crowd/' + this.login)
                      .map(crowds => crowds.value || crowds)
                      .subscribe(crowds => {
                                   this.crowds = crowds;
                                   if (this.crowds && this.crowds.length === 1) {
                                     this.crowdId = this.crowds[0].id;
                                   }
                                 },
                                 error => this.httpService.handleError(error, () => this.loadCrowds()),
                                 () => this.httpService.done());
    }
  }

  get mailAuth(): boolean {
    return this.configService.mailAuth;
  }

  get crowdAuth(): boolean {
    return this.configService.crowdAuth;
  }

  doLogin(fieldEmail: ElementRef, fieldPassword: ElementRef, fieldCrowd: ElementRef = null): void {
    this.errorMessage = null;
    this.resetErrorMessage = null;
    this.resetState = false

    const _authCredentials = this.clone(this.authCredentials);

    _authCredentials.application = this.configService.application;

    _authCredentials.md5Password = this.md5Service.b64_md5(_authCredentials.password);
    delete _authCredentials.password;

    const authCredentials = this.httpService.jsonStringify(_authCredentials);

    if (this.token && this.token.trim() !== '') {
      this.httpService.wait();
      this.httpService.post('/custom/usuario/reset-password(' + this.token.trim() + ')',
                            _authCredentials/*,
                            this.httpService.jsonHeader.options('centralweb', authCredentials)*/)
                      .subscribe(() => this.postLogin(authCredentials, fieldEmail, fieldPassword, fieldCrowd),
                                 result => {
                                   if (result.error) {
                                     this.errorMessage = result.message || 'Erro desconhecido';
                                     this.httpService.done();
                                   } else {
                                     this.httpService.handleError(result, () => this.doLogin(fieldEmail, fieldPassword, fieldCrowd));
                                   }
                                 },
                                 () => this.httpService.done());
    } else {
      this.postLogin(authCredentials, fieldEmail, fieldPassword, fieldCrowd);
    }

    this.token = null;
  }
  private postLogin(authCredentials: string, fieldEmail: ElementRef, fieldPassword: ElementRef, fieldCrowd: ElementRef) {
    const url = this.configService.proofAuthUrl + this.configService.loginPath;

    this.httpService.wait();
    this.httpClient.post(url, authCredentials, this.httpService.jsonHeader.options('proof', authCredentials))
                    .map(data => this.httpService.jsonParse(data))
                    .subscribe(data => this._authSuccess(data),
                               error => this.authFail(error, fieldEmail, fieldPassword, fieldCrowd),
                               () => this.httpService.done());
  }

  doLogout(skipSocket?: boolean, skipNavigate?: boolean): void {
    localStorage.removeItem('data');
    /**
     * Este metodo abaixo limpa todos os dados do navegador,
     * incluindo empresa e período selecionados - GilsonKopper(17/08/2018)
     */
    // localStorage.clear();

    const loggedUserId = this.loggedUser.id;
    this.loggedUser = null;

    this.httpService.authenticate.next(false);

    this.setHeaderAuthorization(null);

    // this.toolbarService.clear();

    this.errorMessage = null;
    this.password = '';
    this.crowdId = null;

    if (!skipSocket) {
      this.socketService.send({
        action: 'logoff',
        loggedUserId: loggedUserId
      });
    }

    if (!skipNavigate) {
      this.router.navigateByUrl(this.configService.loginPath);
    }
  }

  async resetPassword() {
    this.errorMessage = null;
    this.resetErrorMessage = null;
    this.resetState = false

    this.httpService.wait();
    this.httpService.get('/custom/usuario/forgot-password', this.login).subscribe(() => {
      this.resetSent = true;
    }, result => {
      if (result.error && result.error.error) {
        this.resetErrorMessage = result.error.message || 'Erro desconhecido';

        this.httpService.done();
        this.resetState = true
      } else {
        this.httpService.handleError(result, () => this.resetPassword());
      }
    }, () => {
      this.httpService.done()
      this.resetState = true
    });


  }

  getTokenEmail(callback: Function) {
    this.httpService.wait();
    this.httpService.get('/custom/usuario/token-email', this.token)
                    .subscribe(result => callback(result.mail),
                               () => {
                                 this.token = null;
                                 this.httpService.done();
                               },
                               () => this.httpService.done());
  }

  authCheck(data?: any): boolean {
    const _data: any = data || JSON.parse(localStorage.getItem('data'));

    if (_data !== undefined && _data !== null) {
      this.setHeaderAuthorization(_data.authToken);
    }

    this.loggedUser = new AuthLoggedUser(_data);

    this.httpService.authenticate.next(this.authenticated);

    return this.authenticated;
  }

  authVerify(): boolean {
    if (this.authCheck() && this.isLoginPath(window.location.pathname)) {
      const params = window.location.pathname.split(';url=');

      if (params.length > 1) {
        this.router.navigateByUrl(params[1].replace(/%2F/g, '/'));
      } else {
        this.router.navigateByUrl('/');
      }

      return true;
    }

    return this.authenticated;
  }

  private _authSuccess(data: any): void {
    localStorage.setItem('data', JSON.stringify(data));
    this.toolbarService.hidden = false;

    this.authSuccess(data);
  }

  authSuccess(data: any): void {
    const alreadyAuth = this.authenticated;
    this.authCheck(data);

    if (!alreadyAuth) {
      this.socketService.send({
        action: 'login',
        loggedUserId: this.loggedUser.id
      });
    }

    if (this.loggedUser.id) {
      // vamos verificar se eh necessario modificar senha @octavio 23/12/2020
      this.httpService.wait();
      this.httpService.get(`/custom/cadastro/force-password-reset?auth_user_id=${this.loggedUser.id}`)
      .subscribe( retorno => {
        this.forceReset = false;
        if (retorno.error){
          console.log("erro com force-password-reset", retorno.error);
          return;
        }
        if (retorno.value) {
          alert("Será necessário resetar a senha para uma senha forte.\n \
          Favor usar pelo menos uma letra maiúscula, uma letra minúscula, \n \
          um carácter especial (-+_!@#$%&*), com pelo menos 6 e no máximo 15 caracteres.");
          this.forceReset = true;
          return;
        }
      },
        error => {
          this.httpService.done();
          return
      },
        () => {
          this.httpService.done();
          if (this.url) {
            this.router.navigateByUrl(this.httpService.loginUrlHandle(this.url));
          }
        }
      );
    }


  }

  authFail(error: any, fieldEmail: ElementRef, fieldPassword: ElementRef, fieldCrowd?: ElementRef): void {
    switch (parseInt(error.status, 10)) {
      case this.OK:
      case this.OFFLINE:
      case this.NOT_FOUND:
        this.httpService.handleError(error, () => this.doLogin(fieldEmail, fieldPassword, fieldCrowd));
        break;

      case this.UNAUTHENTICATED:
        this.errorMessage = 'E-mail e/ou senha incorretos';
        break;

      // case this.TOOMANY:
      //   this.errorMessage = 'Espere alguns segundos para realizar o login novamente';
      //   break;

      default:
        this.errorMessage = this.httpService.httpErrorMessage(error, 'Erro desconhecido');
    }

    this.authCredentials.password = '';
    if (fieldPassword && fieldPassword.nativeElement) {
      fieldPassword.nativeElement.focus();
    }

    // TODO: Review
    if (this.httpService.loading) {
      this.httpService.done();
    }
  }

  setHeaderAuthorization(token: string) {
    this.httpService.setHeaderAuthorization(token);
  }

  get windowTitle(): string {
    return this.titleService.windowTitle;
  }

  get authenticated(): boolean {
    return this.httpService.authenticated;
  }

  get loggedUser(): AuthLoggedUser {
    return this.sessionService.loggedUser;
  }
  set loggedUser(loggedUser: AuthLoggedUser) {
    this.sessionService.loggedUser = loggedUser;
  }

  isLogoutPath(url: string): boolean {
    return url !== undefined && url !== null && url.trim().indexOf(this.configService.logoutPath) === 0;
  }

  isLoginPath(url: string): boolean {
    return url !== undefined && url !== null && url.trim().indexOf(this.configService.loginPath) === 0;
  }

  get login(): string {
    return this.authCredentials.login;
  }
  set login(login: string) {
    this.authCredentials.login = login;
  }

  get password(): string {
    return this.authCredentials.password;
  }
  set password(password: string) {
    this.authCredentials.password = password;
  }

  get crowdId(): number {
    return this.authCredentials.crowdId;
  }
  set crowdId(crowdId: number) {
    this.authCredentials.crowdId = crowdId;
  }

  checkPermission(url: string, method?: string, applicationKey?: string): boolean {
    if (!this.authenticated) {
      return false;
    }

    let found = false;

    const apps = this.loggedUser.authApps.filter(a => a.key === (applicationKey || this.configService.defaultApplication) &&
                                                      a['features'].length > 0);

    apps.forEach((app: any) => {
      if (!found) {
        app['features'].forEach((feature: any) => {
          if (!found) {
            const authFeatureUrl: AuthFeatureUrl = feature['urls'].find((u: any) => this.checkPath(u.path, url));

            if (authFeatureUrl) {
              switch (method) {
                default:
                  found = authFeatureUrl.allowGet;
                  break;
                case 'POST':
                  found = authFeatureUrl.allowPost;
                  break;
                case 'PUT':
                case 'PATCH':
                  found = authFeatureUrl.allowPut;
                  break;
                case 'DELETE':
                  found = authFeatureUrl.allowDelete;
                  break;
              }
            }
          }
        });
      }
    });

    return found;
  }

  checkPath(featurePath: string, urlPath: string, method?: string): boolean {
    if (featurePath === urlPath || featurePath === this.ALL) {
      return true;
    }

    try {
      if (featurePath.indexOf(this.ALL) > -1 &&
          !(featurePath.substring(0, featurePath.indexOf(this.ALL)) === urlPath.substring(0, featurePath.indexOf(this.ALL)))) {
          return false;
      }
    } catch (exception) {
      return false;
    }

    try {
      let tempFeaturePath: string = featurePath;

      while (tempFeaturePath.indexOf(this.ALL) > -1) {
        const param: string = urlPath.substring(tempFeaturePath.indexOf(this.ALL),
                                              urlPath.length - (tempFeaturePath.length - tempFeaturePath.indexOf(this.ALL) - 1));

        tempFeaturePath = this.replaceParam(tempFeaturePath, param);

        if (tempFeaturePath === urlPath && tempFeaturePath.indexOf(this.ALL) === -1) {
          return true;
        }
      }
    } catch (exception) {
      // Do nothing
    }

    return false;
  }

  private replaceParam(path: string, param: string): string {
    return path.substring(0, path.indexOf(this.ALL)) + param + path.substring(path.length - (path.length - path.indexOf(this.ALL) - 1));
  }

  private clone(object: any) {
    if (object === undefined || object === null) {
      return object;
    }

    return JSON.parse(JSON.stringify(object));
  }

  doWfAcao(projId: number): void {

    this.socketService.send({
      action: 'login',
      loggedUserId: projId + "-P"
    });
  }

  resetErrorState(): void {
    this.errorMessage = null
    this.resetErrorMessage = null
    this.resetState = false
  }

}
