import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { SelectItem } from '../../shared/common/select-item.interface';
import { HttpService } from '../../shared/services/http.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from '../../shared/services/session.service';
import { UtilService } from '../../shared/services/util.service';
import { ConfigService } from '../../shared/services/config.service';
import { Router } from '@angular/router';
import * as Highcharts from "highcharts/highstock";
import HC_exportData from "highcharts/modules/export-data";
HC_exportData(Highcharts);


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})

export class DashboardCentralComponent implements OnInit, OnDestroy {
  isRTL: boolean;
  opcoesResponsavel: SelectItem[];
  responsavelId: number;

  obrigacoesAgendaMes: Array<any> = new Array();
  obrigacoesEnviaMes: Array<any> = new Array();
  obrigacoesHoje: Array<any> = new Array();
  obrigacoesVencidas: Array<any> = new Array();

  totalHoje: number;
  totalAgendaMes: number;
  totalEnviaMes: number;
  totalVencidas: number;

  cklstNaoIniciado: number;
  cklstEmAndamento: number;
  cklstConcluido: number;
  cklstVencido: number;

  tituloModal: String;
  modalObrigacoes: boolean;
  relObrigacoes: Array<any> = new Array();
  colsRelObrigacoes: any[] = [];

  private sessionSubscription: Subscription;
  constructor(
    private titleService: TitleService,
    private httpService: HttpService,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private utilService: UtilService,
    private toolbarService: ToolbarService, private router: Router) {
    this.isRTL = true; // this.isLstRTL;
  }

  public dataTributos: Array<any> = [{
    label: 'Federal',
    data: [93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8],
    borderWidth: 1
  },
  {
    label: 'Estadual',
    data: [53, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -18],
    borderWidth: 1
  },
  {
    label: 'Municipal',
    data: [33, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -13],
    borderWidth: 1
  }];
  public colorsTributos: any = ['rgba(54, 162, 235, 1)', 'rgba(2, 179, 67, 1)', 'rgba(255, 131, 0, 1)'];

  public chartLabels: Array<any> = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto',
    'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  public chartOptions: any = {
    legend: {
      // position: 'bottom',
      labels: {
        fontColor: '#9B9B9B',
        fontFamily: 'font-family: "Lato", sans-serif !important;'
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': R$ ';
          }
          label += ((Math.round(tooltipItem.yLabel * 100)) / 100).toLocaleString('pt-BR') + '';
          return label;
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontColor: '#9B9B9B'
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontColor: '#9B9B9B'
          // stepSize: 50000
        }
      }]
    },
    responsive: false,
    maintainAspectRatio: false
  };

  public chartColors: Array<any> = [{
    backgroundColor: 'rgba(2,26,212,0.05)',
    borderColor: 'rgba(2,26,212, 1)'
  }, {
    backgroundColor: 'rgba(2,188,118,.09)',
    borderColor: 'rgba(2,188,118,1)'
  }, {
    backgroundColor: 'rgba(235,19,19,0.09)',
    borderColor: 'rgba(235,19,18,1)'
  }];

  public chartDataMes: Array<any> = [{
    label: 'Agendadas',
    data: [93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8],
    borderWidth: 1
  },
  {
    label: 'Enviadas',
    data: [53, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -18],
    borderWidth: 1
  },
  {
    label: 'Vencidas',
    data: [33, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -13],
    borderWidth: 1
  }];

  public chartMesOptions: any = {
    legend: {
      // position: 'bottom',
      labels: {
        fontColor: '#9B9B9B',
        fontFamily: 'font-family: "Lato", sans-serif !important;'
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += ((Math.round(tooltipItem.yLabel * 100)) / 100) + '';
          return label;
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontColor: '#9B9B9B'
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontColor: '#9B9B9B'
          // stepSize: 50000
        }
      }]
    },
    responsive: false,
    maintainAspectRatio: false
  };

  // grafico evolução diária
  public chartDataDay: Array<any> = [{
    label: 'Agendadas',
    data: [93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 10, 45, 29, 87, 58, 29, 31],
    borderWidth: 1
  },
  {
    label: 'Enviadas',
    data: [93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 10, 45, 29, 87, 58, 29, 31],
    borderWidth: 1
  },
  {
    label: 'Vencidas',
    data: [93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 10, 45, 29, 87, 58, 29, 31],
    borderWidth: 1
  }];
  public chartLabelsDay: Array<any> = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16',
    '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  public chartColorsDay: Array<any> = ['rgba(2,26,212, 0.5)', 'rgba(2,188,118,0.5)', 'rgba(235,19,18,0.5)'];

  // Chart PIZZA dpto
  public chartPizza: any;

  public labelPizza: Array<any>;

  public colorPizza = {
    backgroundColor: ['#ff8300', '#02b343', '#36a2eb', '#AE27CD', '#FFA706', '#9108d6', '#FF2A05',
      '#c3ff00', '#34eb61', '#FFBB00', '#36A2EB', '#FF6384']
  };
  public optionsPizza = {
    tooltips: {
      mode: 'label',
      callbacks: {
        label: function (tooltipItem, data) {
          let totalObrigacao = 0;
          const indice = tooltipItem.index;
          for (let i = 0; i < 10; i++) {
            totalObrigacao = (data.datasets[0].data[i] ? totalObrigacao + data.datasets[0].data[i] : totalObrigacao);
          }
          return [data.labels[indice] + ': ' + (Math.round((data.datasets[0].data[indice]) * 100) / 100) + ' (' +
            ((Math.round((data.datasets[0].data[indice]) * 100)) /
              (Math.round((totalObrigacao) * 100) / 100)).toFixed(2) + '%)'];
        }
      }
    },
    scales: {
      xAxes: [{
        display: false,
      }],
      yAxes: [{
        display: false
      }]
    },
    legend: {
      position: 'right',
      labels: {
        boxWidth: 12,
        fontColor: '#9B9B9B',
        fontFamily: 'font-family: "Lato", sans-serif !important;'
      }
    },
    responsive: false,
    maintainAspectRatio: false
  };

  // @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  ngOnInit(): void {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.titleService.title = 'DashBoard Central Web';
    this.tituloModal = 'Linnks';

    setTimeout(() => {
      // const resizeCharts = () => this.charts.forEach(chart => chart.chart.resize());
      // // Initial resize
      // resizeCharts();
      // For performance reasons resize charts on delayed resize event
      // this.layoutService.on('resize.DashboardFinanceiroComponent', resizeCharts);
    });


    this.colsRelObrigacoes = [
      { field: 'resp', header: 'Responsável' },
      { field: 'obrigacao', header: 'Obrigação' },
      { field: 'arquivo', header: 'Arquivo' },
      { field: 'empresa', header: 'Empresa' },
      { field: 'vcto', header: 'Vencto' },
      { field: 'envio', header: 'Envio' },
      { field: 'descricao', header: 'Status' }

    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.inicializar(); });

  }

  inicializar() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

      this.responsavelId = 0;

      this.dataTributos = [{
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderWidth: 1
      }, {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderWidth: 1
      }, {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderWidth: 1
      }];

      this.chartDataDay = [{
        label: 'Agendadas',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderWidth: 1
      }, {
        label: 'Enviadas',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderWidth: 1
      }, {
        label: 'Vencidas',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderWidth: 1
      }];

      this.chartDataMes = [{
        label: 'Agendadas',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderWidth: 1
      },
      {
        label: 'Enviadas',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderWidth: 1
      },
      {
        label: 'Vencidas',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderWidth: 1
      }];

      this.getDashboardPart1();
      this.getDashboardPart2();
      this.getDashboardPart3();
      this.getDashboardPart4();
      this.getDashboardPart5();
      this.getDashboardPart6();
      this.comboResp();
    }

  }


  formatarDataExtenso() {
    const dt = new Date();
    const month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const week = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    const semana = week[dt.getDay()];
    const mes = month[dt.getMonth()];
    return 'Hoje é ' + semana + ', ' + dt.getDate() + ' de ' + mes + ' de ' + dt.getFullYear() + '.';
  }

  // buscando os totais das obrigações por status
  getDashboardPart1() {
    const data = new Date();
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-dashboard-01(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.year.value + ',' + this.dblinkedSessionService.periodo.month.value + ',' +
      (data.getDate()) + ')').subscribe(dashPart1 => this.setDashboardPart1(dashPart1),
        error => this.httpService.handleError(error, () => this.getDashboardPart1()),
        () => this.httpService.done());
  }

  // setando os valores das obrigações por status
  setDashboardPart1(dashPart1: Array<any>) {
    this.obrigacoesAgendaMes = new Array();
    this.obrigacoesEnviaMes = new Array();
    this.obrigacoesHoje = new Array();
    this.obrigacoesVencidas = new Array();

    this.totalHoje = 0;
    this.totalAgendaMes = 0;
    this.totalEnviaMes = 0;
    this.totalVencidas = 0;

    dashPart1.forEach(element => {
      if (element.descricao === 'Hoje') {
        // element.descricao = 'Agendada';
        this.obrigacoesHoje.push(element);
        this.totalHoje++;
      } else if (element.descricao === 'Vencida') {
        this.obrigacoesVencidas.push(element);
        this.totalVencidas++;
      } else if (element.descricao === 'Agendada') {
        this.obrigacoesAgendaMes.push(element);
        this.totalAgendaMes++;
      } else if (element.descricao === 'Enviada') {
        this.obrigacoesEnviaMes.push(element);
        this.totalEnviaMes++;
      }

    });

  }

  // buscando os valores do escopo dos checkList
  getDashboardPart2() {
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-dashboard-ChkLst(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.year.value + ',' + this.dblinkedSessionService.periodo.month.value + ')')
      .subscribe(dashPart2 => this.setDashboardPart2(dashPart2),
        error => this.httpService.handleError(error, () => this.getDashboardPart2()),
        () => this.httpService.done());
  }

  // setando os valores do escopo dos checkList
  setDashboardPart2(dashPart2: Array<any>) {
    this.cklstNaoIniciado = 0;
    this.cklstEmAndamento = 0;
    this.cklstConcluido = 0;
    this.cklstVencido = 0;
    dashPart2.forEach(element => {
      if (element.descricao === 'NaoIniciado') {
        this.cklstNaoIniciado = element.qtdade;
      } else if (element.descricao === 'EmAndamento') {
        this.cklstEmAndamento = element.qtdade;
      } else if (element.descricao === 'Concluido') {
        this.cklstConcluido = element.qtdade;
      } else if (element.descricao === 'Vencido') {
        this.cklstVencido = element.qtdade;
      }
    });
  }

  // buscando os valores do grafico Evolução valor dos tributos
  getDashboardPart3() {
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-dashboard-03(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.year.value + ')').subscribe(dashPart3 => this.setDashboardPart3(dashPart3),
        error => this.httpService.handleError(error, () => this.getDashboardPart3()),
        () => this.httpService.done());
  }

  // setando valores do grafico Evolução valor dos tributos
  setDashboardPart3(dashPart3: Array<any>) {
    const tribMunici = new Array();
    const tribEstado = new Array();
    const tribFederal = new Array();

    let valorMun = 0.00;
    let valorEst = 0.00;
    let valorFed = 0.00;

    let mesCtrl = 1;
    let count = 0;

    dashPart3.forEach(element => {
      if (mesCtrl === Number(element.mes)) {
        if (element.id === 4) {
          valorFed = (this.utilService.parseDecimal(element.valor));
        } else if (element.id === 5) {
          valorEst = (this.utilService.parseDecimal(element.valor));
        } else if (element.id === 6) {
          valorMun = (this.utilService.parseDecimal(element.valor));
        }
      } else {
        count++;
        // recebendo o proximo mes
        mesCtrl = Number(element.mes);

        // verifico se um mes não tem movimento
        if (mesCtrl > count + 1 && count > 1) {
          for (let i = count; i < mesCtrl; i++) {
            tribFederal.push(0.00);
            tribEstado.push(0.00);
            tribMunici.push(0.00);
            count++;
          }
        }

        // setando no array o mes anterior
        tribFederal.push(valorFed);
        tribEstado.push(valorEst);
        tribMunici.push(valorMun);
        // zerando variáveis para proximo mÊs
        valorMun = 0.00;
        valorEst = 0.00;
        valorFed = 0.00;

        // setando os valores para pegar proximo mÊs
        if (element.id === 4) {
          valorFed = (this.utilService.parseDecimal(element.valor));
        } else if (element.id === 5) {
          valorEst = (this.utilService.parseDecimal(element.valor));
        } else if (element.id === 6) {
          valorMun = (this.utilService.parseDecimal(element.valor));
        }
      }
    });
    // setando no array o ultimo mes
    tribFederal.push(valorFed);
    tribEstado.push(valorEst);
    tribMunici.push(valorMun);

    // preenchendo os meses sem movimento com zeros
    if (12 > count) {
      for (let i = count; i <= 12; i++) {
        tribFederal.push(0.00);
        tribEstado.push(0.00);
        tribMunici.push(0.00);
      }
    }

    this.dataTributos = [{
      name: 'Federal',
      data: tribFederal,
    },
    {
      name: 'Estadual',
      data: tribEstado,
    },
    {
      name: 'Municipal',
      data: tribMunici,
    }];

    this.loadColumnChart('chart_column', this.dataTributos)
  }

  loadColumnChart(container, value) {
    new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: 'column',
        renderTo: container,
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      xAxis: {
        categories: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        crosshair: true
      },
      tooltip: {
        pointFormatter: function () {
          if (this.y >= 1e6) {
            return `${(this.y / 1e6).toFixed(3)}M`;
          } else if (this.y >= 1e3) {
            return `${(this.y / 1e3).toFixed(3)}K `;
          }
          return `${this.y}`;
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          pointWidth: 15
        }
      }, colors: this.colorsTributos,
      series: value
    });
  }

  // buscando os valores do grafico pizza deptos
  getDashboardPart4() {
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-dashboard-04(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.year.value + ',' + this.dblinkedSessionService.periodo.month.value + ')')
      .subscribe(dashPart4 => this.setDashboardPart4(dashPart4),
        error => this.httpService.handleError(error, () => this.getDashboardPart4()),
        () => this.httpService.done());
  }

  // setando valores do grafico pizza deptos
  setDashboardPart4(dashPart4: Array<any>) {
    this.chartPizza = [];
    const dadosNomes = new Array();
    const dadosQtdades = new Array();
    dashPart4.forEach(ret => {
      this.chartPizza.push({ name: ret.nome, y: ret.qtdade });
    });
    this.loadDonutChart('chart_pizza', this.chartPizza)
  }

  loadDonutChart(container, value) {
    new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: "pie",
        renderTo: container,
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormatter: function () {
          if (this.y >= 1e6) {
            return `${(this.y / 1e6).toFixed(3)}M - (${this.percentage.toFixed(
              0
            )}%)`;
          } else if (this.y >= 1e3) {
            return `${(this.y / 1e3).toFixed(3)}K - (${this.percentage.toFixed(
              0
            )}%)`;
          }
          return `${this.y} - (${this.percentage.toFixed(0)}%)`;
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          colors: this.colorPizza.backgroundColor,
        },
      },
      series: [
        {
          name: "VL_AJUSTE",
          type: "pie",
          data: value,
        },
      ],
    });
  }

  // buscando os valores do grafico por resposável
  getDashboardPart5() {

    let dadosAgendadas = new Array();
    let dadosEnviadas = new Array();
    let dadosVencidas = new Array();
    // agendadas
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-dashboard-05(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.year.value + ',1,' + this.responsavelId + ')').subscribe(data1 => {
        dadosAgendadas = data1; this.setDashboardPart5(dadosAgendadas, dadosEnviadas, dadosVencidas);
      }, error => this.httpService.handleError(error, () => this.getDashboardPart5()), () => this.httpService.done());

    // Enviadas
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-dashboard-05(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.year.value + ',2,' + this.responsavelId + ')').subscribe(data2 => {
        dadosEnviadas = data2; this.setDashboardPart5(dadosAgendadas, dadosEnviadas, dadosVencidas);
      }, error => this.httpService.handleError(error, () => this.getDashboardPart5()),
        () => this.httpService.done());

    // Vencidas
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-dashboard-05-01(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.year.value + ',' + this.responsavelId + ')').subscribe(data3 => {
        dadosVencidas = data3;
        this.setDashboardPart5(dadosAgendadas, dadosEnviadas, dadosVencidas);
      }, error => this.httpService.handleError(error, () => this.getDashboardPart5()),
        () => this.httpService.done());
  }

  // setando valores do grafico por resposável
  setDashboardPart5(agendadas: Array<any>, enviadas: Array<any>, vencidas: Array<any>) {
    const dadosAgendadas = new Array();
    const dadosEnviadas = new Array();
    const dadosVencidas = new Array();

    agendadas.forEach(ret => {
      dadosAgendadas.push(ret.qtdade);
    });
    enviadas.forEach(ret => {
      dadosEnviadas.push(ret.qtdade);
    });
    vencidas.forEach(ret => {
      dadosVencidas.push(ret.qtdade);
    });

    // Chart grafico mensal
    this.chartDataMes = [{
      name: 'Agendadas',
      data: dadosAgendadas,

    },
    {
      name: 'Enviadas',
      data: dadosEnviadas,

    },
    {
      name: 'Vencidas',
      data: dadosVencidas,

    }];
    this.loadAreaChart('chart_area1', this.chartDataMes)
  }
  loadAreaChart(container, value) {
    new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        renderTo: container,
        type: 'area'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        allowDecimals: false,
        accessibility: {
          rangeDescription: ''
        }
      },
      yAxis: {
        title: {
          text: ''
        },
      },
      tooltip: {
        pointFormat: '{series.name}-{point.y}'
      },
      series: value
    });
  }

  // buscando os valores do grafico por resposável por dia por mes
  getDashboardPart6() {

    let dadosAgendadas = new Array();
    let dadosEnviadas = new Array();
    let dadosVencidas = new Array();
    // agendadas = 1
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-dashboard-06(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.year.value + ',' + this.dblinkedSessionService.periodo.month.value +
      ',1,' + this.responsavelId + ')').subscribe(data1 => {
        dadosAgendadas = data1; this.setDashboardPart6(dadosAgendadas, dadosEnviadas, dadosVencidas);
      }, error => this.httpService.handleError(error, () => this.getDashboardPart6()), () => this.httpService.done());

    // Enviadas = 2
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-dashboard-06(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.year.value + ',' + this.dblinkedSessionService.periodo.month.value +
      ',2,' + this.responsavelId + ')').subscribe(data2 => {
        dadosEnviadas = data2; this.setDashboardPart6(dadosAgendadas, dadosEnviadas, dadosVencidas);
      }, error => this.httpService.handleError(error, () => this.getDashboardPart6()),
        () => this.httpService.done());

    // Vencidas
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-dashboard-06-01(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.year.value + ',' + this.dblinkedSessionService.periodo.month.value + ',' +
      this.responsavelId + ')').subscribe(data3 => {
        dadosVencidas = data3;
        this.setDashboardPart6(dadosAgendadas, dadosEnviadas, dadosVencidas);
      }, error => this.httpService.handleError(error, () => this.getDashboardPart6()),
        () => this.httpService.done());
  }

  // setando valores do grafico por resposável por dia por mes
  setDashboardPart6(agendadas: Array<any>, enviadas: Array<any>, vencidas: Array<any>) {
    const dadosAgendadas = new Array();
    const dadosEnviadas = new Array();
    const dadosVencidas = new Array();

    agendadas.forEach(ret => {
      dadosAgendadas.push(ret.qtdade);
    });
    enviadas.forEach(ret => {
      dadosEnviadas.push(ret.qtdade);
    });
    vencidas.forEach(ret => {
      dadosVencidas.push(ret.qtdade);
    });

    // Chart grafico mensal
    this.chartDataDay = [{
      name: 'Agendadas',
      data: dadosAgendadas,
    },
    {
      name: 'Enviadas',
      data: dadosEnviadas,
    },
    {
      name: 'Vencidas',
      data: dadosVencidas,
    }];
    this.loadAreaChart('chart_area2', this.chartDataDay)
  }

  detalharObrigacoes(tipo: any) {
    this.modalObrigacoes = true;
    this.relObrigacoes = new Array();
    /*
this.obrigacoesAgendaMes = new Array();
    this.obrigacoesEnviaMes = new Array();
    this.obrigacoesHoje = new Array();
    this.obrigacoesVencidas = new Array();
    */
    switch (tipo) {
      case '1': {
        this.tituloModal = 'Obrigações Vencendo Hoje';
        this.relObrigacoes = this.obrigacoesHoje;
        break;
      }
      case '2': {
        this.tituloModal = 'Obrigações Agendadas no Mês';
        this.relObrigacoes = this.obrigacoesAgendaMes;
        break;
      }
      case '3': {
        this.tituloModal = 'Obrigações Enviadas no Mês';
        this.relObrigacoes = this.obrigacoesEnviaMes;
        break;
      }
      case '4': {
        this.tituloModal = 'Obrigações Vencidas';
        this.relObrigacoes = this.obrigacoesVencidas;
        break;
      }
      default: {
        break;
      }
    }
  }

  abrirPagina() {
    this.router.navigate(['./centralweb/check-list']);
  }

  resetModal() {
    this.modalObrigacoes = false;
  }


  atualizaOne() {
    this.dataTributos = [{
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderWidth: 1
    }, {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderWidth: 1
    }, {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderWidth: 1
    }];

    this.chartPizza = [{
      label: this.labelPizza,
      data: [1, 1, 1, 1],
      borderWidth: 1
    }];
    this.getDashboardPart3();
    this.getDashboardPart4();
  }

  comboResp() {
    this.opcoesResponsavel = [];
    this.opcoesResponsavel.push({ label: 'TODOS', value: 0 });
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrig-dashboard-User(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.year.value + ')').subscribe(ref => {
        ref.forEach(element => {
          this.opcoesResponsavel.push({ label: element.nome, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboResp()),
        () => this.httpService.done());
  }

  alternarResp() {
    this.getDashboardPart5();
    this.getDashboardPart6();
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get isLstRTL() {
    return document.documentElement.getAttribute('dir') === 'rtl' ||
      document.body.getAttribute('dir') === 'rtl';
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}
