import { RequisicaoFornecedorSugerido } from './requisicao-fornecedor-sugerido.model';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { OperacaoRegraContabil } from 'app/cadastro/model/operacao-regra-contabil.model';

export class RequisicaoFinanceiro {
  id: number;
  isTransferencia: boolean = false;
  dtVencimento: Date;
  historico: string;
  valor: number;
  valorBrl: number;
  operacaoRegraContabil: OperacaoRegraContabil = new OperacaoRegraContabil();
  requisicaoFornecedorSugerido: RequisicaoFornecedorSugerido = new RequisicaoFornecedorSugerido();
  nDocumento: number;
  contaOrigem: EmpresaConta = new EmpresaConta();
  contaDestino: EmpresaConta = new EmpresaConta();

  constructor(requisicaoFinanceiro?: RequisicaoFinanceiro | number) {
    if (requisicaoFinanceiro != null) {
      if (typeof requisicaoFinanceiro === 'number') {
        this.id = requisicaoFinanceiro;
      } else {
        this.id = requisicaoFinanceiro.id;
        this.isTransferencia = requisicaoFinanceiro.isTransferencia;
        this.dtVencimento = requisicaoFinanceiro.dtVencimento;
        this.historico = requisicaoFinanceiro.historico;
        this.valor = requisicaoFinanceiro.valor;
        this.valorBrl = requisicaoFinanceiro.valorBrl
        this.operacaoRegraContabil = requisicaoFinanceiro.operacaoRegraContabil;
        this.requisicaoFornecedorSugerido = requisicaoFinanceiro.requisicaoFornecedorSugerido;
        this.nDocumento = requisicaoFinanceiro.nDocumento;
        this.contaOrigem = requisicaoFinanceiro.contaOrigem;
        this.contaDestino = requisicaoFinanceiro.contaDestino;
      }
    }
  }
}
