import { Component, OnInit, OnDestroy, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';

import { Participante } from '../../cadastro/model/participante.model';

import { element, promise } from '../../../../node_modules/protractor';
import { isNullOrUndefined } from 'util';
import { Util } from 'app/shared/common/util';
import * as moment from 'moment';
import { Message, MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-boleto',
  templateUrl: './boleto.component.html',
  styleUrls: [
    './boleto.component.scss'
  ],
  providers: [CrudService]
})
export class BoletoComponent implements OnInit, OnDestroy {
  // @ViewChildren(ToolbarService) panels;
  private sessionSubscription: Subscription;
  title: string;
  subtitle: string;
  dtOperacao: Date;
  defaultDate: Date;

  msgs: Message[];

  cpf: string = '';
  nome: string;
  ddd: string;
  fone: string;
  email: string;
  cep: string;
  rua: string;
  numero: string;
  bairro: string;
  complemento: string;
  cidade: string;

  vencimento: Date;
  numeroParcelas: number;
  valor: number;
  instrucoes: string;
  descricao: string;

  opcoesUf: SelectItem[];
  uf: number = null;

  opcoesMunicipio: SelectItem[];
  municipio: number;
  listaBoletoAntigo : SelectItem[] =[];
  boletoAntigoId    : number = 0;

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titleService.title = 'Geração de Boletos';
    this.title = 'BOLETOS PAGSEGURO';
    this.subtitle = 'CADASTRE E CONTROLE OS BOLETOS GERADOS PARA O PAGSEGURO';

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.inicializar();
      }
    );
  }

  inicializar() {
    if (
      this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected &&
      this.dblinkedSessionService.periodo.month &&
      this.dblinkedSessionService.periodo.year
    ) {
      if (
        this.dblinkedSessionService.periodo.month.value !==
        this.utilService.currentMonth(this.defaultDate) + 1
      ) {
        const mes =
          this.dblinkedSessionService.periodo.month.value.toString.length === 2
            ? this.dblinkedSessionService.periodo.month.value
            : '0' + this.dblinkedSessionService.periodo.month.value;
        const dt =
          this.dblinkedSessionService.periodo.year.value +
          '-' +
          mes +
          '-01 12:00:00';
        this.defaultDate = new Date(dt);
      }
      this.resetarVariaveis();
      this.toolbarEnable();
      this.comboUf();
    }
  }

  resetarVariaveis(){
    this.cpf = '';
    this.nome = '';
    this.ddd = '';
    this.fone = '';
    this.email = '';
    this.cep = '';
    this.rua = '';
    this.numero = '';
    this.bairro = '';
    this.complemento = '';
    this.cidade = '';
    this.vencimento = new Date();
    this.numeroParcelas = 1;
    this.valor = 0;
    this.instrucoes = '';
    this.descricao = '';
    this.listaBoletoAntigo = [];
    this.listaBoletoAntigo.push({label: 'Selecione um boleto', value : 0});
    this.boletoAntigoId = 0;
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  comboUf() {
    this.opcoesUf = [];
    this.opcoesUf.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/uf?$select=id,uf&$orderby=uf').subscribe(
      ref => {
        ref.value.forEach(element => {
          this.opcoesUf.push({ label: element.uf, value: element.id });
        });
      },
      error => this.httpService.handleError(error, () => this.comboUf()),
      () => this.httpService.done()
    );
  }

  comboMunicipio() {
    this.opcoesMunicipio = [];
    this.opcoesMunicipio.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + this.uf)
      .subscribe(
        ref => {
          ref.value.forEach(element => {
            this.opcoesMunicipio.push({
              label: element.nome,
              value: element.id
            });
          });
        },
        error =>
          this.httpService.handleError(error, () => this.comboMunicipio()),
        () => this.httpService.done()
      );
  }

  private toolbarEnable() {
    try {
      this.toolbarService.clear();
      this.toolbarService.replace('voltar', false, 'class-first-button', 'undo', 'Voltar', () => this.inicializar());
      this.toolbarService.replace('export',true,'class-other-button','file_copy', 'Clonar Pedido');
      this.toolbarService.replace('print', true,'class-first-button','print','Imprimir Pedido', null);
      this.toolbarService.replace('save', true,'class-other-button','save', 'Salvar alterações', null);
      this.toolbarService.replace('remove',true,'class-remove-button', 'delete','Excluir');
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit','Editar');
      this.toolbarService.replace('add',false,'class-new-button','GERAR','GERAR BOLETO', () => this.gerarBoleto() );

    } catch (error) {

    }
  }

  public gerarBoleto() {

      let erro = false;


      var dataAtual = new Date();
      var diffDias : number = 0;


      if (this.cpf.length !== 11 && this.cpf.length !== 14) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Cliente: É Obrigatório informar CPF/CNPJ!'});
      }

      if (!this.ddd) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Cliente: É Obrigatório informar o DDD!'});
      } else if (this.ddd.length < 2 || this.ddd.length > 3) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Cliente: DDD deve conter entre 2 e 3 caracteres!'});
      }

      if (!this.fone) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Cliente: Fone é Obrigatório!'});
      }else if(this.fone.length < 8 || this.fone.length > 9){
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Cliente: Fone deve conter entre 8 e 9 caracteres!'});
      }

      if (isNullOrUndefined(this.email) || this.email === '') {
        erro = true;
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Email inválido!'
        });
      } else {
        if (!Util.validacaoEmail(this.email)) {
          erro = true;
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Email inválido!'
          });
        }
      }


      if (!this.cep) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Endereço: CEP é Obrigatório!'});
      } else if (this.cep.length !== 8) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Endereço: CEP deve conter 8 caracteres somente números!'});
      }

      if (!this.rua) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Endereço: Rua é Obrigatório!'});
      }

      if (!this.numero) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Endereço: Número é Obrigatório!'});
      }

      if (!this.bairro) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Endereço: Bairro é Obrigatório!'});
      }

      if (!this.vencimento) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Boleto: Vencimento é Obrigatório!'});
      }

      if (!this.numeroParcelas) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Boleto: Numero Parcelas é Obrigatório!'});
      }

      if (!this.valor) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Boleto: Valor é Obrigatório!'});
      } else if (this.valor < 10) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Boleto: Valor deve ser superior a R$ 10!'});
      }

      if (!this.municipio) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Endereço: Municipio é Obrigatório!'});
      }

      if (!this.uf) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Dados Endereço: UF é Obrigatório!'});
      }

      let ano = this.vencimento.getFullYear();
      let mes = this.vencimento.getMonth() ;
      let dia = this.vencimento.getDate();

      var dataPrimeiroVencimento = new Date();
      dataPrimeiroVencimento.setFullYear(ano);
      dataPrimeiroVencimento.setMonth(mes);
      dataPrimeiroVencimento.setDate(dia);

      diffDias = this.transformDias(dataPrimeiroVencimento, dataAtual) ;


      if (diffDias < 1 || diffDias > 30) {
        erro = true;
        this.messageService.add({severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'O boleto deve ser emitido com data D+1 e no máximo D+30!'});
      }




      if(!erro) {
        const listasEnviar = {
          cpf: this.cpf,
          nome: this.nome,
          ddd: this.ddd,
          fone: this.fone,
          email: this.email,
          cep: this.cep,
          rua: this.rua,
          numero: this.numero,
          bairro: this.bairro,
          complemento: this.complemento,
          vencimento: this.vencimento,
          numeroParcelas: this.numeroParcelas,
          valor: this.valor,
          instrucoes: this.instrucoes,
          descricao: this.descricao,
          uf: this.uf,
          municipio: this.municipio,
          usuario: this.sessionService.loggedUser.id,
          empresa: this.dblinkedSessionService.empresa.id,
          boletoAntigoId : this.boletoAntigoId
        };

        this.httpService.wait();
        this.httpService
          .post('/custom/pagseguro/gerar-boleto-avulso', listasEnviar)
          .subscribe(
            result => {
              if (!result.error) {
                this.messageService.add({
                  severity: 'success',key: 'messageLinnks',
                  summary: 'MSG: ',
                  detail: 'Boleto Gerado com Sucesso!'
                });
                this.inicializar();

              } else {
                  this.messageService.add({
                    severity: 'warn',key: 'messageLinnks',
                    summary: 'MSG: ',
                    detail: result.error
                  });
             }
            },
            error => this.httpService.handleError(error, () => this.gerarBoleto()),
            () => this.httpService.done()
          );

      }


  }

  pesquisarCep() {

    this.cep = this.cep.replace(/[^\d]+/g, '');

    if (this.cep.length !== 8) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'CEP deverá conter 8 caracteres!'
      });
      return;

   } else {

      this.httpService.wait();
    this.httpService.get('/custom/service/get-endereco(' + this.cep + ')')
      .subscribe(
        rst => {

          if (!rst.errors) {
          //  this.cep = rst.postalCode;
          this.rua = rst.logradouro ;
          this.bairro = rst.bairro;
          this.uf = rst.uf;
          this.municipio = rst.localidade;
          // uf
          this.httpService.wait();
          this.httpService.get('/uf?$select=id,uf&$filter=uf eq ' + '\'' + rst.uf + '\'').subscribe(
            ref => {

              try {
                this.uf = ref.value[0].id;
                if (this.uf) {
                  this.comboMunicipio();
                   // busca cidade
                    setTimeout(() => this.setCidadeBusca(rst.ibge) , 1000);
                  // fim busca cidade
                }
              } catch (error) {
                this.httpService.done()
              }


            },
            error => this.httpService.handleError(error, () => this.pesquisarCep()),
            () => this.httpService.done());
            // fim uf

          } else {

            rst.errors.forEach(element => {

              this.messageService.add({
                severity: 'warn',key: 'messageLinnks',
                summary: 'MSG: ',
                detail: element.message
              });
            });
            return;

          }

        },  error => this.httpService.handleError(error, () => this.pesquisarCep()),
    () => this.httpService.done()
    );

    }

  }

  setCidadeBusca(codigoIbge) {

    if (codigoIbge) {

      this.httpService.wait();
    this.httpService.get('/municipioibge?$select=id,nome&$filter=cod eq ' + '\'' + codigoIbge + '\'')
    .subscribe(
      cid => {
        this.municipio = cid.value[0].id;
      },
      error =>
        this.httpService.handleError(error, () => this.pesquisarCep()),
      () => this.httpService.done())

    } else {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Cidade não encontrada, selecione nas opções'
      });
      return;
    }

  }

  pesquisarCpf() {

    this.nome = '';
    this.ddd = '';
    this.fone = '';
    this.email = '';
    this.cep = '';
    this.rua = '';
    this.numero = '';
    this.bairro = '';
    this.complemento = '';
    this.uf = 0;
    this.opcoesMunicipio = [];
    this.opcoesMunicipio.push({ label: 'Selecione', value: null });

      this.httpService.wait();
      this.httpService.get('/custom/pagseguro/pesquisar-cpf(' + this.dblinkedSessionService.empresa.id +
            ',' + ( !isNullOrUndefined(this.cpf) ? this.cpf : '' ) + ')')
        .subscribe(
          info => {

            //this.cpf = info[0].cpf;
            info.forEach(element =>{
              console.log(element);
              this.cpf = element.cpf;
              this.nome = element.nome;
              this.ddd = element.ddd;
              this.fone = element.fone;
              this.email = element.email;
              this.cep = element.cep;
              this.rua = element.rua;
              this.numero = element.numero;
              this.bairro = element.bairro;
              this.complemento = element.complemento;
              this.uf = element.uf;


              this.opcoesMunicipio = [];
              this.opcoesMunicipio.push({ label: 'Selecione', value: null });
              this.httpService.wait();
              this.httpService
                .get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + this.uf)
                .subscribe(
                  ref => {
                    ref.value.forEach(element => {
                      this.opcoesMunicipio.push({
                        label: element.nome,
                        value: element.id
                      });
                    });
                    this.municipio = info[0].municipio;
                  },
                  error =>
                    this.httpService.handleError(error, () =>
                      this.comboMunicipio()
                    ),
                  () => this.httpService.done()
                );

            })


          },
          error => this.httpService.handleError(error, () => this.pesquisarCpf()),
          () => this.httpService.done()
        );

  }

  hideMessage() {

  }

  getPesquisarBoletoAntigo(){

    try {
        if (!isNullOrUndefined(this.cpf) && this.cpf.length > 5) {
          this.httpService.wait();
          this.httpService.get('/custom/pagseguro/get-boleto-por-cpf(' + [this.dblinkedSessionService.empresa.id , this.cpf ] + ')')
            .subscribe(retorno => {

              this.listaBoletoAntigo =[];
              this.listaBoletoAntigo.push({label: 'Selecione um boleto', value : 0});
              retorno.forEach(element => {
                this.listaBoletoAntigo.push({label: 'Vencimento: ' + element.vencimento + ' - Nome: ' + element.nome + ' - Referência: ' + element.referencia + ' - Valor: ' + element.valor + ' - Parcelas: ' + element.parcelas + ' - Status: ' + element.status, value : element.id});
              });

            },
              error => this.httpService.handleError(error, () => this.getPesquisarBoletoAntigo( )),
              () => this.httpService.done());

      }

    } catch (error) {

    }

  }

  transformDias(date1: any, date2?: any): any {
    const daydiff = moment(date1).diff(moment(date2), "days");

    return Math.abs(daydiff);
  }


}
