import { Injectable } from '@angular/core';

import * as _momentTimezone from 'moment-timezone';
import * as _moment from 'moment';
import { Moment } from 'moment';

@Injectable()
export class UtilService {
  private currentTimezone: string;
  private utcTimezone = 'UTC';
  private momentTimezone = _momentTimezone;

  printing = false;

  mainContainer: any;

  constructor() {
    this.currentTimezone = this.momentTimezone.tz.guess();
  }

  moment(date?: Date | string | number | Array<number>): Moment {
    return _moment(date);
  }

  isDate(date: string): boolean {
    const d = _moment(date, 'D/M/YYYY');
    if (!d || !d.isValid()) {
      return false;
    }

    return date.indexOf(d.format('D/M/YYYY')) > -1 ||
      date.indexOf(d.format('DD/MM/YYYY')) > -1 ||
      date.indexOf(d.format('D/M/YY')) > -1 ||
      date.indexOf(d.format('DD/MM/YY')) > -1;
  }

  parseDate(date: string, format?: string): number {
    try {
      let __date: Date;

      let _date: any[];
      let _time: any[];

      switch (format) {
        case 'yyyy-MM-dd':
          _date = date.split('-');
          __date = new Date(_date[0], _date[1] - 1, _date[2]);
          break;

        case 'dd/MM/yyyy':
          _date = date.split('/');
          __date = new Date(_date[2], _date[1] - 1, _date[0]);
          break;

        case 'yyyy-MM-dd':
          _date = date.split('-');
          __date = new Date(_date[0], _date[1] - 1, _date[2]);
          break;

        case 'dd/MM/yyyy HH:mm':
          _date = date.split(' ')[0].split('/');
          _time = date.split(' ')[1].split(':');
          __date = new Date(_date[2], _date[1] - 1, _date[0], _time[0], _time[1]);
          break;

        case 'yyyy-MM-ddTHH:mm':
          _date = date.split('T')[0].split('-');
          _time = date.split('T')[1].split(':');
          __date = new Date(_date[0], _date[1] - 1, _date[2], _time[0], _time[1]);
          break;

        case 'yyyy-MM-dd HH:mm':
          _date = date.split(' ')[0].split('-');
          _time = date.split(' ')[1].split(':');
          __date = new Date(_date[0], _date[1] - 1, _date[2], _time[0], _time[1]);
          break;

        case 'dd/MM/yyyy HH:mm:ss':
          _date = date.split(' ')[0].split('/');
          _time = date.split(' ')[1].split(':');
          __date = new Date(_date[2], _date[1] - 1, _date[0], _time[0], _time[1], _time[2]);
          break;

        case 'yyyy-MM-dd HH:mm:ss':
          _date = date.split(' ')[0].split('-');
          _time = date.split(' ')[1].split(':');
          __date = new Date(_date[0], _date[1] - 1, _date[2], _time[0], _time[1], _time[2]);
          break;

        case 'yyyy-MM-ddTHH:mm:ss':
          _date = date.split('T')[0].split('-');
          _time = date.split('T')[1].split(':');
          __date = new Date(_date[0], _date[1] - 1, _date[2], _time[0], _time[1], _time[2]);
          break;
      }

      return this.momentTimezone(__date).valueOf();
    } catch (exception) {
      return null;
    }
  }


  timestampToUtc(timestamp: number): number {
    return this.momentTimezone.tz(timestamp, this.utcTimezone).milliseconds();
  }
  timestampToLocal(timestamp: number): number {
    return this.momentTimezone.tz(timestamp, this.currentTimezone).milliseconds();
  }


  timestampToDate(timestamp: number): Date {
    return this.momentTimezone(timestamp).toDate();
  }
  timestampToUtcDate(timestamp: number): Date {
    return this.momentTimezone.tz(timestamp, this.utcTimezone).toDate();
  }
  timestampToLocalDate(timestamp: number): Date {
    return this.momentTimezone.tz(timestamp, this.currentTimezone).toDate();
  }

  timestampToStringDate(timestamp: number): string {
    return this.timestampToStringDateTime(timestamp).split(' ')[0];
  }
  timestampToStringUtcDate(timestamp: number): string {
    return this.timestampToStringUtcDateTime(timestamp).split(' ')[0];
  }
  timestampToStringLocalDate(timestamp: number): string {
    return this.timestampToStringLocalDateTime(timestamp).split(' ')[0];
  }

  timestampToStringTime(timestamp: number): string {
    return this.timestampToStringDateTime(timestamp).split(' ')[1].substr(0, 5);
  }
  timestampToStringUtcTime(timestamp: number): string {
    return this.timestampToStringUtcDateTime(timestamp).split(' ')[1].substr(0, 5);
  }
  timestampToStringLocalTime(timestamp: number): string {
    return this.timestampToStringLocalDateTime(timestamp).split(' ')[1].substr(0, 5);
  }

  _timestampToStringDateTime(date: Date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).substr(-2);
    const day = ('0' + (date.getDate())).substr(-2);
    const hours = ('0' + (date.getHours())).substr(-2);
    const minutes = ('0' + (date.getMinutes())).substr(-2);

    return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
  }

  _timestampToStringDateTime2(date: Date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).substr(-2);
    const day = ('0' + (date.getDate())).substr(-2);

    return day + '/' + month + '/' + year;
  }

  _timestampToStringDateTime3(date: Date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).substr(-2);
    const day = ('0' + (date.getDate())).substr(-2);

    return day + '-' + month + '-' + year;
  }

  timestampToStringDateTime(timestamp: number): string {
    return this._timestampToStringDateTime(this.timestampToDate(timestamp));
  }
  timestampToStringUtcDateTime(timestamp: number): string {
    return this._timestampToStringDateTime(this.timestampToUtcDate(timestamp));
  }
  timestampToStringLocalDateTime(timestamp: number): string {
    return this._timestampToStringDateTime(this.timestampToLocalDate(timestamp));
  }

  _timestampToInputFieldDateTime(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).substr(-2);
    const day = ('0' + (date.getDate())).substr(-2);
    const hours = ('0' + (date.getHours())).substr(-2);
    const minutes = ('0' + (date.getMinutes())).substr(-2);

    return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes;
  }
  timestampToInputFieldDateTime(timestamp: number): string {
    return this._timestampToInputFieldDateTime(this.timestampToDate(timestamp));
  }
  timestampToInputFieldUtcDateTime(timestamp: number): string {
    return this._timestampToInputFieldDateTime(this.timestampToUtcDate(timestamp));
  }
  timestampToInputFieldLocalDateTime(timestamp: number): string {
    return this._timestampToInputFieldDateTime(this.timestampToLocalDate(timestamp));
  }

  timestampToInputFieldDate(timestamp: number): string {
    return this.timestampToInputFieldDateTime(timestamp).split('T')[0];
  }
  timestampToInputFieldUtcDate(timestamp: number): string {
    return this.timestampToInputFieldUtcDateTime(timestamp).split('T')[0];
  }
  timestampToInputFieldLocalDate(timestamp: number): string {
    return this.timestampToInputFieldLocalDateTime(timestamp).split('T')[0];
  }


  dateToStringDate(date: Date): string { // TODO: Use momentjs ??
    return this.date(date).toLocaleDateString();
  }

  dateToStringDatetime(date: Date): string { // TODO: Use momentjs ??
    return this.date(date).toLocaleString();
  }

  dateToTimestamp(date?: Date): number {
    return this.momentTimezone(date).valueOf();
  }

  date(date?: Date): Date {
    return date || this.momentTimezone().toDate();
  }

  parseMoneyString(value: any): string {
    return this.parseDecimalString(value);
  }

  parseMoney(value: any): number {
    const _value: string = this.parseMoneyString(value);
    return _value !== null ? parseFloat(_value.replace(',', '.')) : null;
  }

  parseDecimalString(value: any): string {
    if (value === null || value === undefined || value.toString().trim === '') {
      return null;
    }

    value = value.toString();

    const point = value.lastIndexOf('.');
    const comma = value.lastIndexOf(',');
    const decimal = point > comma ? point : comma;

    if (decimal > -1) {
      value = value.substring(0, decimal) + '###' + value.substring(decimal + 1);
      value = value.replace(/\./g, '')
        .replace(/\,/g, '')
        .replace('###', '.');
    }

    const _value: number = parseFloat(value);

    if (isNaN(_value)) {
      return null;
    }

    return _value.toFixed(2).replace('.', ',');
  }

  parseDecimal(value: any): number {
    const _value: string = this.parseDecimalString(value);
    return _value !== null ? parseFloat(_value.replace(',', '.')) : null;
  }

  parseDecimal4(value: any): number {
    const _value: string = this.parseDecimalString4(value);
    return _value !== null ? parseFloat(_value.replace(',', '.')) : null;
  }

  parseDecimal6(value: any): number {
    const _value: string = this.parseDecimalString6(value);
    return _value !== null ? parseFloat(_value.replace(',', '.')) : null;
  }

  lastDayOfMonth(date?: any): Moment {
    return this.moment(date).endOf('month');
  }
  lastDayOfYear(date?: any): Moment {
    return this.moment(date).endOf('year');
  }

  firstDayOfMonth(date?: any): Moment {
    return this.moment(date).startOf('month');
  }

  firstDayOfYear(date?: any): Moment {
    return this.moment(date).startOf('year');
  }

  currentYear(date?: any): number {
    return this.moment(date).year();
  }

  currentMonth(date: any): number {
    return this.moment(date).month();
  }

  currentDay(date: any): number {
    return this.moment(date).day();
  }

  timezoneOffset(date: any): number {
    return this.moment(date).utcOffset() / 60;
  }

  utcTimestamp(date: any): number {
    return this.utcDate(date).getTime();
  }

  utcDate(date: any): Date {
    return this.moment(date).utc(true).toDate();
  }

  dateFormat(date: any, format?: string): string {
    return this.moment(date).format(format).replace('T', ' ');
  }

  // corrige problema de exibir um dia a menos(dd/mm/aaaa)
  dateWithoutTimeZone(date: string): Date {
    const _value: Date = new Date(date);
    return _value !== null ? new Date(_value.getUTCFullYear(), _value.getUTCMonth(), _value.getUTCDate()) : null;
  }

  /**
   * @description corrige problema de exibir um dia a menos(dd/mm/aaaa) utilizado milliseconds
   * @description ou {{'1546308000000' | date:"format": 'timezone'}} => EX: {{'1546308000000' | date:"dd/MM/yyyy": '-100'}}
   * @author Gilson Kopper
   * @since 25.01.2018
  */
  millisecondsToDate(milliseconds: string): Date {
    const _value: Date = new Date(new Date(milliseconds).toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }));
    const _date: Date = new Date(); // Meu current time
    const timeDifference: any = (_date.getTime() - _value.getTime());
    const differenceInMinutes = Math.floor(timeDifference / (1000 * 60));
    _date.setMinutes(_date.getMinutes() - _date.getTimezoneOffset() - differenceInMinutes);
    return (_value !== null ? _date : null); // pode ser String, basta alterar para _date.toLocaleString()
  }

  print(selector: string = 'table') {
    const element = document.querySelector(selector) || this.mainContainer;

    this.printing = true;
    this.clearPrintArea();

    if (window.matchMedia) {
      const mediaQueryList = window.matchMedia('print');
      mediaQueryList.removeListener(mql => this.printListener(mql));
      mediaQueryList.addListener(mql => this.printListener(mql));
    } else {
      window.onbeforeprint = () => this.beforePrint();
      window.onafterprint = () => this.afterPrint();
    }

    setTimeout(() => {
      document.getElementById('print_area').appendChild(element.cloneNode(true));
      window.print();
    }, 100);
  }

  clearPrintArea() {
    document.getElementById('print_area').innerHTML = '';
  }

  beforePrint() {
  }

  afterPrint() {
    this.printing = false;
    this.clearPrintArea();
  }

  printListener(mql: any) {
    if (mql.matches) {
      this.beforePrint();
    } else {
      this.afterPrint();
    }
  }

  parseDecimalString4(value: any): string {
    if (value === null || value === undefined || value.toString().trim === '') {
      return null;
    }

    value = value.toString();

    const point = value.lastIndexOf('.');
    const comma = value.lastIndexOf(',');
    const decimal = point > comma ? point : comma;

    if (decimal > -1) {
      value = value.substring(0, decimal) + '###' + value.substring(decimal + 1);
      value = value.replace(/\./g, '')
        .replace(/\,/g, '')
        .replace('###', '.');
    }

    const _value: number = parseFloat(value);

    if (isNaN(_value)) {
      return null;
    }

    return _value.toFixed(4).replace('.', ',');
  }

  parseDecimalString6(value: any): string {
    if (value === null || value === undefined || value.toString().trim === '') {
      return null;
    }

    value = value.toString();

    const point = value.lastIndexOf('.');
    const comma = value.lastIndexOf(',');
    const decimal = point > comma ? point : comma;

    if (decimal > -1) {
      value = value.substring(0, decimal) + '###' + value.substring(decimal + 1);
      value = value.replace(/\./g, '')
        .replace(/\,/g, '')
        .replace('###', '.');
    }

    const _value: number = parseFloat(value);

    if (isNaN(_value)) {
      return null;
    }

    return _value.toFixed(6).replace('.', ',');
  }

  validArquivo(texto: string) {
    let regex = new RegExp('[^0-9a-zA-Z.\\s_()]');
    return regex.test(texto);
  }

  toDate(value: string) {
    const dictionary = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    }

    const date = value.split(' ');

    const usaDate = `${dictionary[date[2]]}-${date[1]}-${date[3]}`;

    console.log(usaDate, new Date(usaDate).toString());

    return new Date(usaDate);

  }
}
