import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpf'
})
export class CpfPipe implements PipeTransform {
  transform(value: any, args?: any[]): any {
    if (value !== undefined && value !== null && value.toString().trim() !== '') {
      const newValue = value.replace(/\D+/g, '');

      if (newValue.length !== 11) {
        return value;
      }

      return newValue.substr(0, 3) + '.' +
             newValue.substr(3, 3) + '.' +
             newValue.substr(6, 3) + '-' +
             newValue.substr(9, 2);
    }

    return null;
  }
}
