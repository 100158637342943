export class CodNaturezaConta {
  id: number;
  cod: string;
  descricao: string;

  constructor(codnaturezaconta?: CodNaturezaConta | number) {
    if (codnaturezaconta !== undefined && codnaturezaconta !== null) {
      if (typeof codnaturezaconta === 'number') {
        this.id = codnaturezaconta;
      } else {
        this.id = codnaturezaconta.id;
        this.cod = codnaturezaconta.cod;
        this.descricao = codnaturezaconta.descricao;
      }
    }
  }
}
