import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { ConfigService } from 'app/shared/services/config.service';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { MessageService } from 'primeng/api';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [CrudService]
})
export class AuthUserComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public authUser: AuthUser = new AuthUser();
  public registrosAuthUser: Array<AuthUser> = new Array();

  ids: Array<any> = new Array();

  //Array
  grupos = new Array();
  items = new Array();
  selectedItems = new Array();
  selectedGrupos = new Array();

  //Boolean
  selectedGruposAll = false;
  selectedGruposAll1 = false;
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;
  active: boolean = true;

  //Form
  formulario: FormGroup;
 
  //Any
  description: any;
  email: any;
  password: any;

  //String
  title: string;
  subtitle: string;
  nomeCompleto: string;
  name: string;

  opcoesAtivo: SelectItem[] = [{ label: 'Todos', value: '' }, { label: 'Sim', value: true }, { label: 'Não', value: false }];

  private sessionSubscription: Subscription;

  constructor(
    crudService: CrudService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private messageService: MessageService,
    private httpService: HttpService,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]
  
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
    );

  }

  carregar(){
    this.title = 'Usuários';
    this.subtitle = 'Usuários';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();

    this.limpaCamposForm();
    this.loadRegistros();

    this.toolbarMain.setVisible('btn1', false)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', true)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', false)
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar Usuários';

    this.loadGrupos();
    this.loadItem();

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', true)
    this.toolbarMain.setVisible('btn6', false)
  }

  loadGrupos(){
    this.httpService.wait();
    this.httpService
    .get(`authgroup?$select=id,name&$orderby=name`, null, 'proof')
    .subscribe(
      (retorno) => {
          this.grupos = new Array();
          retorno.value.forEach(item => {
            this.grupos.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadItem(){
    this.httpService.wait();
    this.httpService
    .get(`authuser?filter=id%20gt%201&$select=id,fullName&$orderby=fullName`, null, 'proof')
    .subscribe(
      (retorno) => {
          this.items = new Array();
          retorno.value.forEach(item => {
            this.items.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadRegistros(){
    this.httpService.wait();
    this.httpService
    .get(`/authuser?$select=displayName,fullName,mail,active&$count=true&$skip=0&$filter=id gt 1&$orderby=displayName`, null, 'proof')
    .subscribe(
      (retorno) => {
          this.registrosAuthUser = new Array();
          retorno.value.forEach(item => {
            this.registrosAuthUser.push({
              ...item,
            });
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.loadGrupos();
    this.loadItem();
    this.title = 'Editar Usuários';
    this.editarConta = true;

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', true)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', true)

    this.limpaCamposForm();

    this.httpService.wait();
    this.httpService.get(`/authuser(${this.ids[0]})?$select=displayName,fullName,mail,pass,active,authGroups/id,authGroups/name&$count=true`, null, 'proof')
      .subscribe(item => {
        this.name = item.value.displayName;
        this.nomeCompleto = item.value.fullName;
        this.email = item.value.mail;
        this.password = item.value.pass;
        this.active = item.value.active;
        this.selectedGrupos = item.value.authGroups.map( grupo => String(grupo.id))
      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }
    
    if(this.selectedGrupos === null){
      this.selectedGrupos = [];
    }
    const jsonData = {
      displayName: this.name,
      fullName: this.nomeCompleto,
      mail: this.email,
      pass: this.password,
      active: this.active,
    }
    jsonData['authGroups'] = this.selectedGrupos.map( grupo =>
      {
        return {
          id: grupo,
        };
    });
    
    this.httpService.wait();
    this.httpService.post('/authuser', jsonData, 'proof')
      .subscribe(result => {
        if (!(result === null || result === undefined)) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro cadastrado com sucesso!'
            });
            this.carregar();
        }else{
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
            });
            this.carregar();
            return;
        }
      }, error => this.httpService.handleError(error, () =>  this.salvarNovoRegistro()),
      () => this.httpService.done());
  }


  private getApplicationName(id){
    const retorno = this.grupos.filter(app => app.id === id);
    if(retorno.length === 1){
      return retorno[0].name;
    }

    return '';
  }

  filtrarVazio(jsonData) {
    if(this.description === null) {
      delete jsonData['description']
    }
  }


  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      displayName: this.name,
      fullName: this.nomeCompleto,
      mail: this.email,
      pass: this.password,
      active: this.active,
    }
    jsonData['authGroups'] = this.selectedGrupos.map( grupo =>
      {
        return {
          id: grupo,
        };
    });

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/authuser', key, jsonData, 'proof')
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      const jsonData = { 
        active: this.active,
        name: this.name,
        description: this.description,
      };
      this.httpService.wait();
      this.httpService.delete('/authuser', this.ids, 'proof')
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Projeto excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());
    }
  }

  selectGruposAll(){
    if(this.selectedGruposAll){
      this.selectedGrupos = this.grupos.map( grupo =>  String(grupo.id));
    }else{
      this.selectedGrupos = new Array();
    }
  }

  selectGruposAll1(){
    if(this.selectedGruposAll1){
      this.selectedItems = this.items.map( item =>  String(item.id));
    }else{
      this.selectedItems = new Array();
    }
  }

  selectCheckboxContainer(data){
    setTimeout(() => {
      document.getElementById('checkboxContainer'+data.id).click();
    },100);
  };

  
  selectCheckbox(event: any) {
    this.ids.push(event.data.id);
    event.selected = false;
    this.changeToolbar()
  }

  unselectCheckbox(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.changeToolbarUn()
  }

  changeToolbar() {
    this.toolbarMain.setVisible('btn2', true)
    this.toolbarMain.setVisible('btn3', true)
  }

  changeToolbarUn(){
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
  }

  validacao(): boolean {
    
    if (this.name === undefined || this.name.length < 1) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nome é obrigatório' });
        return true;
    }

    return false;
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  private limpaCamposForm(){
    this.name = '';
    this.nomeCompleto = '';
    this.email = '';
    this.password = '';
    this.active = false;
    this.selectedGrupos = null;
    this.selectedItems = null;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}
