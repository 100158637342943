import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { AppComponent } from '../../app.component';
import { Config } from '../../app.config';
import { PlanoContas } from '../../contabil/model/plano-contas.model';
import { ScrollService } from 'app/shared/services/scroll.service';
import { ToolbarService } from '../../shared/services/toolbar.service';

@Component({
  selector: 'app-report-gerencial',
  templateUrl: './report-gerencial.component.html',
  styleUrls: ['./report-gerencial.component.scss'],
})

export class EcdReportGerencial implements OnInit, OnDestroy {
  // TODO: Review INPUTS
  @Input() keyField = 'id';

  private empresaSubscription: Subscription;
  private initSubscription: Subscription;

  private empresaId: number;
  private mes: number;
  private ano: number;
  private gerencial: number;
  private visao: number;

  private params: string;

  cols : any[];
  value: any[];
  titulo: string;

  gerencialList: Array<any> = new Array();

  constructor(private titleService: TitleService,
              private httpService: HttpService,
              private configService: ConfigService,
              private scrollService: ScrollService,
              private toolbarService: ToolbarService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private appComponent: AppComponent) {
  }

  ngOnInit(): void {
    if (this.configService.production) {
      this.appComponent._displaySideNav = false;
    } else {
      setTimeout(() => { this.appComponent._displaySideNav = false; });
    }

    this.activatedRoute.params.subscribe(params => {
        this.empresaId = parseInt(params['empresaId'], 10);
        this.mes = parseInt(params['mes'], 10);
        this.ano = parseInt(params['ano'], 10);
        this.gerencial = parseInt(params['gerencial'], 10);
        this.visao = parseInt(params['visao'], 10);

        if (!this.initSubscription) {
          this.initSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
                                                                            () => { this.load(); });
        } else {
          this.load();
        }
    });

    this.titleService.title = 'REPORT GERENCIAL';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.initSubscription);
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  private load() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.sessionService.destroySubscribe(this.initSubscription);


    this.cols = [];
    this.cols = [
      { field: 'Campo1', header: 'COD CTA',     style: "texto; 30",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo2', header: 'DATA',     style: "data; 20", filter: true, filterType: 'multi'},
      { field: 'Campo3', header: 'CONTA CONT',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo4', header: 'ORIGEM',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo5', header: 'LOTE',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo6', header: 'DOCUMENTO',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo7', header: 'LINHA',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo8', header: 'CENTRO CUSTO',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo9', header: 'VALOR',        style: "valor; 10", filter: true, filterType: 'text', filterMatchMode: 'contains' },
      { field: 'Campo10', header: 'OBSERVAÇÕES',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo14', header: 'NATUREZA',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo11', header: 'HISTÓRICO',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo12', header: 'CNPJ',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo13', header: 'FILIAL',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo15', header: 'AREA',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo16', header: 'SUBAREA',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'},
      { field: 'Campo17', header: 'CLASSIFICACAO',     style: "texto; 20",  filter: true, filterType: 'text', filterMatchMode: 'contains'}

  ];

  this.value = [];

      this.httpService.wait();
      this.httpService.get('/custom/ecd/gerencial', [this.empresaId, this.mes, this.ano, this.gerencial, this.visao])
                      .subscribe(result => {

                        result.forEach(element => {
                          this.value.push({
                          Campo1: element.codCta,
                          Campo2: element.data,
                          Campo3: element.contaContabil,
                          Campo4: element.origem,
                          Campo5: element.lote,
                          Campo6: element.documento,
                          Campo7: element.linha,
                          Campo8: element.centroCusto,
                          Campo9: element.valorFormatado,
                          Campo10: element.observacoes,
                          Campo11: element.historico,
                          Campo12: element.empCnpj,
                          Campo13: element.filial,
                          Campo14: element.natureza,
                          Campo15: element.area,
                          Campo16: element.subarea,
                          Campo17: element.classificacao
                          });
                          this.titulo = element.titulo;
                        });



       },
       error => this.httpService.handleError(error, () => this.load()),
       () => this.httpService.done());
    }
  }

  rowStyleClass(planoContas: PlanoContas): string {
    return planoContas.indCta === 'S' ? 'bold' : '';
    // TODO: FIX =>  return this.planoContasService.rowStyleClass(planoContas);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}
