import { Empresa } from '../../cadastro/model/empresa.model';

export class CadAglut {
  id: number;
  cod: string;
  descricao: string;
  empresa: Empresa = new Empresa();

  constructor(cadaglut?: CadAglut | number) {
    if (cadaglut !== undefined && cadaglut !== null) {
      if (typeof cadaglut === 'number') {
        this.id = cadaglut;
      } else {
        this.id = cadaglut.id;
        this.cod = cadaglut.cod;
        this.descricao = cadaglut.descricao;
        this.empresa = cadaglut.empresa || new Empresa();
      }
    }
  }
}
