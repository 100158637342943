import { Component, OnInit } from '@angular/core';
import { TipoEcd} from '../model/tipo-ecd.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { ConfigService } from 'app/shared/services/config.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-tipo-ecd',
  templateUrl: './tipo-ecd.component.html',
  styleUrls: ['./tipo-ecd.component.scss'],
  providers: [CrudService]
})
export class TipoEcdComponent extends DataTableBasicComponent implements OnInit {
  public tipoEcd: TipoEcd = new TipoEcd();
  public registrosTipoEcd: Array<TipoEcd> = new Array();

  private sessionSubscription: Subscription;

  ctrlSave: boolean;
  entityFilter: string = null;
  editarConta: boolean;
  novaConta: boolean;
  ids: Array<any> = new Array();
  selectECD: Array<any> = new Array();

  title = 'Tipo Ecd';
  subtitle = "Tipo Ecd";

  codigo: any;
  descricao: string;

  constructor(crudService: CrudService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private configService: ConfigService,
    private messageService: MessageService,
    ) {
    super(crudService);
  }

  public toolbarMain = new Toolbar();

  toolbarMainIni = [
    { key: 'voltar',  icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { key: 'saveNew', icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { key: 'save', icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]


  ngOnInit() {
    this.toolbarService.hidden = true;
    this.toolbarMain.setAll(this.toolbarMainIni);

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
      );
  }

  carregar() {
    this.title = 'Tipo Ecd';
    this.subtitle = 'Tipo Ecd';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.selectECD = new Array();

    this.limpaCamposForm();

    this.toolbarInitial();

    this.loadRegistros();
  }

  toolbarInitial(){
    this.toolbarMain.setVisible('voltar', false)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.toolbarMain.setVisible('add', true)
    this.toolbarMain.setVisible('saveNew', false)
    this.toolbarMain.setVisible('save', false)
  }

  toolbarNew(){
    this.toolbarMain.setVisible('voltar', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.toolbarMain.setVisible('add', false)
    this.toolbarMain.setVisible('saveNew', true)
    this.toolbarMain.setVisible('save', false)
  }

  toolbarEdit(){
    this.toolbarMain.setVisible('voltar', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', true)
    this.toolbarMain.setVisible('add', false)
    this.toolbarMain.setVisible('saveNew', false)
    this.toolbarMain.setVisible('save', true)
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar';
    this.limpaCamposForm();
    this.toolbarNew()

  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService
      .get(`/tipoecd?$select=cod,descricao&$count=true&$top=50&$orderby=cod`)
      .subscribe(
        (retorno) => {
          this.registrosTipoEcd = new Array();
          retorno.value.forEach(item => {
            this.registrosTipoEcd.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar';
    this.editarConta = true;
    this.selectECD = [];

    this.limpaCamposForm();

    this.toolbarEdit();

    this.httpService.wait();
    this.httpService.get(`/tipoecd(${this.ids[0]})?$select=cod,descricao&$count=true`)
      .subscribe(item => {
        this.codigo = item.value.cod;
        this.descricao = item.value.descricao;
      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonEnvio = {
      cod: this.codigo,
      descricao: this.descricao
    }

    this.httpService.wait();
    this.httpService.post('/tipoecd', jsonEnvio)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonEnvio = {
      cod: this.codigo,
      descricao: this.descricao
    }

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/tipoecd', key, jsonEnvio, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      this.httpService.wait();
      this.httpService.delete('/tipoecd', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Registro excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
    }
  }


  onRowSelect(event: any) {
    this.ids = new Array();
    this.ids.push(event.data.id);
    event.selected = false;
    this.changeToolbar()
  }

  onRowUnselect(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.changeToolbarUn()
  }

  changeToolbar() {
    this.toolbarMain.setVisible('edit', true)
    this.toolbarMain.setVisible('delete', true)
  }

  changeToolbarUn(){
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
  }


  validacao(): boolean {
      if (this.codigo === undefined || this.codigo === null || this.codigo == '' || this.codigo.length < 1 || this.codigo.length > 1) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código inválido' });
        return true;
      }

      if (this.descricao === undefined || this.descricao === null || this.descricao == '' || this.descricao.length < 1 || this.descricao.length > 255) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição inválida' });
        return true;
      }

    return false;
  }

  provisorio(){}

  private adicionaUmDia = (milisegundos) => {
    if(milisegundos === null || milisegundos == ''){
      return null;
    }

    return milisegundos + 86400000;
  };

  private converteDataParaFullText(data) {
    if(data === undefined || data === 'null'){
      return '';
    }

    let novoFormato;
    novoFormato = new Date(data);

    return novoFormato;
  }

  private limpaCamposForm() {
   this.codigo = '';
   this.descricao = '';
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
