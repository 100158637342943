import { AuthUser } from 'app/proof/model/auth-user.model';
import { PedidoItem } from './pedido-item.model';

export class PedidoExportacao {
  id: number;
  pedidoItem: PedidoItem;
  numeroAto: any;
  numeroRegistro: any;
  chaveAcessoNfe: any;
  qtdItemExport: any;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;

  constructor(pedidoExportacao?: PedidoExportacao | number) {
    if (pedidoExportacao !== undefined && pedidoExportacao !== null) {
      if (typeof pedidoExportacao === 'number') {
        this.id = pedidoExportacao;
      } else {
        this.id = pedidoExportacao.id;
        this.pedidoItem = pedidoExportacao.pedidoItem;
        this.numeroAto = pedidoExportacao.numeroAto;
        this.numeroRegistro = pedidoExportacao.numeroRegistro;
        this.chaveAcessoNfe = pedidoExportacao.chaveAcessoNfe;
        this.qtdItemExport = pedidoExportacao.qtdItemExport;
        this.createTimestamp = pedidoExportacao.createTimestamp;
        this.changeUser = pedidoExportacao.changeUser;
        this.changeTimestamp = pedidoExportacao.changeTimestamp;
      }
    }
  }
}
