import { Component, /*HostListener, */Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs/Subscription';
import { DataTableColumn } from './model/data-table-column.model';
import { DataControl } from './model/data-control.model';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { Selected, DataTableService } from './data-table.service';
import { TitleService } from 'app/shared/services/title.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { CrudService } from 'app/shared/services/crud.service';
import { UtilService } from 'app/shared/services/util.service';
import { ScrollService } from 'app/shared/services/scroll.service';
import { Util } from 'app/shared/common/util';
import { SessionService } from 'app/shared/services/session.service';
import { Message, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() mainTitle: string;
  @Input() singleTitle: string;
  @Input() application: string = null;
  @Input() routerPath: string;

  @Input() entityPath: string;
  private currentEntityPath: string;
  @Input() entityFilter: string;
  private currentEntityFilter: string;
  @Input() orderColumns: string;

  @Input() entityTop: number = null;
  @Input() private entity: any;
  @Input() keyField = 'id';
  @Input() readonly = false;
  @Input() columns: Array<DataTableColumn> = new Array();
  @Input() controls: Array<DataControl> = new Array();
  @Input() extras: any;
  @Input() gender = 'masculine';
  @Input() debug = false;
  @Input() autoEdit: boolean | string = this.configService.autoEdit;
  @Input() printable: boolean | string = false;
  @Input() bulkeditable: string = null;
  @Input() bulkdelete: boolean | string = null;

  // @Output() itemsChange: EventEmitter<{}> = new EventEmitter();

  @Output() select: EventEmitter<any> = new EventEmitter();
  private currentRow: any = null;
  /*@Output() scroll: EventEmitter<any> = new EventEmitter();*/

  alwaysDisplayHint = false;

  private scrollSubscription: Subscription;
  private afterListSubscription: Subscription;

  private sessionLoadSubscription: Subscription;

  private refreshSubscription: Subscription;
  private viewSubscription: Subscription;

  private keyValue: string;
  public viewInit = false;

  private params: string;

  private skip = 0;
  private top: number;

  private dialogRef: MatDialogRef<DialogComponent>;

  private updated: Array<Selected> = new Array();

  titlePrincipal: string;
  subtitle: string;

  /*@HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    switch (event.key) {
      case 'Enter':
        if (!this.dialogRef.componentInstance.confirmDisabled) {
          this.dialogRef.componentInstance.dialogClose(true);
        }
        break;
      case 'Escape':
        this.dialogRef.componentInstance.dialogClose(false);
        break;
    }
  }*/

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: TitleService,
    private configService: ConfigService,
    private crudService: CrudService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private scrollService: ScrollService,
    private dataTableService: DataTableService,
    private sessionService: SessionService,
    matDialog: MatDialog) {
    this.dataTableService.bulkEditCallback = () => {
      this.toolbarService.get('bulkedit').disabled = true;

      this.dialogRef = matDialog.open(DialogComponent, {
        disableClose: true
      });

      this.dialogRef.componentInstance.mainTitle = this.mainTitle;
      this.dialogRef.componentInstance.singleTitle = this.singleTitle;
      this.dialogRef.componentInstance.selectedLength = this.selected.length;

      this.bulkeditable.split(',').forEach(field => {
        let found = false;

        this.columns.forEach(column => {
          if (!found && column.key === field) {
            this.dialogRef.componentInstance.controls.push(column);
            found = true;
          }
        });

        if (!found) {
          this.controls.forEach(control => {
            if (!found && control.key === field) {
              this.dialogRef.componentInstance.controls.push(control);
              found = true;
            }
          });
        }
      });

      this.dialogRef.afterClosed().subscribe(save => {
        if (save) {
          this.bulkEdit();
        }

        // TODO: Decrease selected item count to get last operation (??)
        this.toolbarService.get('bulkedit').disabled = false;
        this.dialogRef = null;
      });
    };

    this.dataTableService.bulkDeleteCallback = () => {
      if (confirm('Deseja excluir o(s) registro(s) selecionados?')) {
        this.selected.forEach(selected => this._delete(selected.key));
      }
    };

    this.refreshSubscription = this.crudService.refresh.subscribe(() => this.loadChanges(true));

    this.viewSubscription = this.crudService.afterView.subscribe(() => {
      if (this.autoEdit && this.autoEdit.toString().toLocaleLowerCase() !== 'false' && !this.editing) {
        this.crudService.edit(this.entityPath, true);
      }
    });
  }

  ngOnChanges() {
    this.sessionLoadSubscription = this.sessionService.initSubscribe(this.sessionService.sessionLoaded, () => this.loadChanges());
  }

  private loadChanges(force: boolean = false) {
    if (this.entityPath &&
      (force ||
        ((!this.currentEntityPath || this.currentEntityPath !== this.entityPath) ||
          (!this.currentEntityFilter || this.currentEntityFilter !== this.entityFilter)))) {
      if (this.dataTableService.lastRouterPath !== this.routerPath) {
        this.dataTableService.reset(this.routerPath);
      }

      this.skip = 0;

      if (Util.isNullOrUndefined(this.entityTop)) {
        this.entityTop = this.configService.datatableTop;
      }
      this.top = this.entityTop;

      if (!this.readonly) {
        this.activatedRoute.params.subscribe(params => {
          this.keyValue = params['id'];
        });
      }

      if (Util.isNotUndefined(this.routerPath)) {
        this.titleService.title = this.mainTitle;

        // TODO: PUT ON CONSTRUCTOR ??

        if (!this.orderColumns && this.columns && this.columns.length > 0) {
          this.orderColumns = this.columns[0].key;
        }

        this.crudService.init(this.application, this.routerPath,
          (this.gender === 'masculine' ? 'o ' : 'a ') + this.singleTitle,
          this.entityPath, this.entityFilter, this.orderColumns,
          this.entity, this.keyValue,
          this.entity !== undefined ? this.entity.constructor : null);

        this.crudService.columns = this.columns;
        if (this.columns) {
          this.columns.forEach(column => {
            if (column.options && column.options.value) {
              if (!Util.isArray(column.options.values)) {
                column.options.values = new Array();

                /*column.options.values.push({
                  value: undefined,
                  label: column.options.prompt
                });*/
              }

              if (column.options.url && !column.options.parent) {
                this.fillFilterOptions(column, undefined, (this.keyValue !== undefined && this.keyValue !== null) ||
                  this.activatedRoute.snapshot.params['preserve']);
              }
            }
          });

          this.viewInit = true;

          this.get(this.keyValue, this.skip, this.top);
        }
      }
    }

    this.currentEntityPath = this.entityPath;
    this.currentEntityFilter = this.entityFilter;
  }

  ngOnInit() {
    this.titlePrincipal = '';
    this.subtitle = '';
    if (this.bulkdelete && this.bulkdelete !== 'false') {
      this.bulkdelete = true;
    }
  }

  private fillFilterOptions(column: DataTableColumn, value?: any, preserve: boolean = false) {
    let url = column.options.url.trim();
    if (url.indexOf('#parentValue') !== -1 && value !== null && value !== undefined && value !== -2) {
      url = url.replace('#parentValue', encodeURIComponent(value));
    }

    this.crudService.resetOptions(column);

    if (!preserve) {
      this.dataTableService.unfilterColumn(column);

      this.columns.filter(c => c.options && c.options.parent === column.key)
        .forEach(c => {
          this.crudService.resetOptions(c);

          this.dataTableService.unfilterColumn(c);
        });
    }

    if (url.indexOf('#parentValue') !== -1) {
      return;
    }

    url = this.crudService.fixFillUrl(url, column);

    this.httpService.wait();
    this.httpService.get(url, null,
      column.options.application !== undefined ? column.options.application : this.application)
      .map(options => options.value || options)
      .subscribe(options => {
        const labelKey = column.options.label;
        const valueKey = column.options.value;

        /*column.options.values.push({
          value: undefined,
          label: column.options.prompt
        });*/

        this.crudService.resetOptions(column);

        options.forEach(option => {
          column.options.values.push({
            value: option[valueKey],
            label: option[labelKey]
          });
        });

        if (column.filter !== undefined && column.filter !== null && column.filter !== '') {
          this.columns.filter(c => c.options && c.options.parent === column.key)
            .forEach(c => { this.fillFilterOptions(c, column.filter, true); });
        }
      },
        error => this.httpService.handleError(error,
          () => this.ngOnChanges(),
          null,
          this.application),
        () => this.httpService.done());
  }

  private bulkEdit() {
    const data: Object = new Object();

    const controls = this.dialogRef.componentInstance.formGroup.controls;
    for (const key in controls) {
      if (Object.prototype.hasOwnProperty.call(controls, key)) {
        if (controls[key].dirty && controls[key].value !== this.dialogRef.componentInstance.originalValue[key]) {
          Util.setFieldValue(data, key, controls[key].value); // TODO: REVIEW
        }
      }
    }

    this.updated = new Array();
    this.selected.forEach(selected => this._patch(selected.key, data));
  }

  private _patch(key, data) {
    this.httpService.wait();
    this.httpService.patch(this.entityPath, key, data)
      .subscribe(() => {
        this.updated.push(key);

        if (this.selected.length === this.updated.length) {
          this.updated = new Array();
          this.toolbarService.get('bulkedit').disabled = false;
        }

        const item = this.items.find(i => i[this.keyField] === key);

        for (const k in data) {
          if (Object.prototype.hasOwnProperty.call(data, k)) {
            item[k] = data[k];
          }
        }
      },
        error => this.httpService.handleCrudError(this.singleTitle, this.crudService.controls,
          error, () => this._patch(key, data)),
        () => this.httpService.done());
  }

  private _delete(key) {
    this.httpService.wait();
    this.httpService.delete(this.entityPath, key)
      .subscribe(() => {
        this.selected = this.selected.filter(s => s.key !== key);

        if (this.selected.length === 0) {
          setTimeout(() => this.get(), 250);
        }
      },
        error => this.httpService.handleError(error, () => this._delete(key)),
        () => this.httpService.done());
  }

  trackByItems(index: number, item: any) {
    return item[this.keyField];
  }

  setParams(keyValue?: any) {
    this.params = (this.entityPath.indexOf('?') > -1 ? '&' : '?') + '$select=';

    // TODO: REFACTORING
    if (keyValue !== undefined && keyValue !== null) {
      this.controls.filter(control => !control.transient).forEach(control => {
        if (control.options && !control.options.static && control.options.value) {
          this.params += control.key.replace(/\./g, '/') + '/' + control.options.value + ',';

          if (control.options.label && (control.options.parent || keyValue !== undefined && keyValue !== null)) {
            if (Util.isArray(control.options.label)) {
              control.options.label.forEach((label: any) => {
                if (label.substr(0, 1) !== ' ') {
                  this.params += control.key.replace(/\./g, '/') + '/' + label + ',';
                }
              });
            } else {
              this.params += control.key.replace(/\./g, '/') + '/' + control.options.label + ',';
            }
          }
        } else {
          this.params += control.key.replace(/\./g, '/') + ',';
        }
      });
    } else {
      this.columns.forEach(column => {
        if (column.options && !column.options.static && (column.options.url || column.options.values)) {
          if (column.options.value) {
            this.params += column.key.replace(/\./g, '/') + '/' + column.options.value + ',';
          }

          if (column.options.label && (column.options.parent || keyValue !== undefined && keyValue !== null)) {
            if (Util.isArray(column.options.label)) {
              column.options.label.forEach((label: any) => {
                if (label.substr(0, 1) !== ' ') {
                  this.params += column.key.replace(/\./g, '/') + '/' + label + ',';
                }
              });
            } else {
              this.params += column.key.replace(/\./g, '/') + '/' + column.options.label + ',';
            }
          }
        } else {
          this.params += column.key.replace(/\./g, '/') + ',';
        }
      });
    }

    if (this.extras !== undefined && this.extras !== null) {
      if (Util.isArray(this.extras)) {
        if (this.extras.length > 0) {
          this.params += this.extras.join(',').trim().replace(/\./g, '/');
        }
      } else {
        this.params += this.extras.replace(/\./g, '/');
      }
    }

    if (this.params.substr(this.params.length - 1) === ',') {
      this.params = this.params.substr(0, this.params.length - 1);
    }

    this.params += '&$count=true';
  }

  get(keyValue: any = null,
    skip = 0,
    top: number = this.entityTop) {
    this.crudService.key = keyValue;

    if (keyValue === undefined || keyValue === null) {
      let callback;

      if (skip === 0) {
        callback = () => {
          if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
          }

          this.scrollSubscription = this.scrollService.scroll.subscribe((content: any) => {
            if (this.items.length === this.itemsCount) {
              return;
            }

            const lastRow: any = content.querySelector('table tbody tr:last-of-type');

            if (lastRow === undefined) {
              return;
            }

            const mark = lastRow.offsetTop - window.innerHeight; // TODO: Review (- content.offsetTop ??)
            if (mark < content.scrollTop) {
              if (!this.loading) {
                this.skip += this.top;
                this.get(null, this.skip, this.top);
              }
            }
          });

          this.afterListSubscription = this.crudService.afterList.subscribe(() /*items*/ => {
            // this.itemsChange.next(items);

            if (this.printable && this.printable.toString().toLocaleLowerCase() !== 'false') {
              if (this.itemsCount > 0) {
                this.toolbarService.add('print', false, 'print', 'print', 'Imprimir', () => this.print());
              }
            }

            if (this.bulkeditable || this.bulkdelete) {
              this.dataTableService.refreshButtons();
            }
          });
        };
      }

      this.setParams();
      this.crudService.columns.forEach(c => {
        c.filter = this.dataTableService.getFilterValue(c);
      });
      this.crudService.get(this.controls,
        this.entityPath + this.params + '&$skip=' + skip + '&$top=' + top,
        callback,
        this.readonly);
    } else {
      this.setParams(keyValue);
      this.crudService.get(this.controls,
        this.entityPath + this.params,
        keyValue);
    }
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.scrollSubscription,
      this.afterListSubscription,
      this.refreshSubscription,
      this.viewSubscription,
      this.sessionLoadSubscription);

    this.toolbarService.clear();
  }

  cancel() {
    this.crudService.cancel();
  }
  remove() {
    this.crudService.remove();
  }
  save() {
    this.crudService.save();
  }

  print() {
    this.crudService.print('table');
  }

  get printing(): boolean {
    return this.utilService.printing;
  }

  get form(): FormGroup {
    return this.crudService.form;
  }

  get item(): any {
    return this.entity;
  }
  set item(entity: any) {
    this.entity = entity;
  }

  get itemsCount(): number {
    return this.crudService.itemsCount;
  }
  get items(): Array<any> {
    return this.crudService.items;
  }

  get viewing(): boolean {
    return this.crudService.viewing;
  }

  get editing(): boolean {
    return this.crudService.editing;
  }

  get isNew(): boolean {
    return this.crudService.isNew;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get formTitle(): string {
    const _new: boolean = this.keyValue === undefined || this.keyValue === null || this.keyValue === '';

    if (_new) {
      return 'Editando nov' + (this.gender === 'feminine' ? 'a' : 'o') + ' ' + this.singleTitle.toLowerCase();
    } else {
      const firstColumn = this.columns[0];

      let keyValue;
      if (firstColumn.options && firstColumn.options.label) {
        keyValue = Util.getFieldValue(this.item, firstColumn.key)[firstColumn.options.label];
      } else {
        keyValue = Util.getFieldValue(this.item, firstColumn.key);
      }

      return (this.editing ? 'Editando' : 'Exibindo') + ' ' + this.singleTitle.toString().toLowerCase() + ' ' +
        (keyValue === undefined || keyValue === null || keyValue.toString().trim() === '' ? '' : '"' + keyValue + '"');
    }
  }

  get gridTitle(): string {
    return 'Exibindo ' + this.mainTitle.substr(0, 1).toLowerCase() + this.mainTitle.substr(1);
  }

  get allSelectTooltip(): string {
    const article = (this.gender === 'feminine' ? 'a' : 'o') + 's';
    return (this.allVisibleSelected ? 'Desm' : 'M') + 'arcar tod' + article + ' ' +
      article + ' ' + this.mainTitle.toLocaleLowerCase() + ' visíveis';
  }

  rowSelect(row: any) {
    if (row !== this.currentRow) {
      this.currentRow = row;
    }
  }

  isSelected(row: any): boolean {
    return this.currentRow === row;
  }

  view(key: number | string, row: any) {
    if (this.hasSelected) {
      this.singleSelect(row);
    } else {
      if (!this.readonly && this.controls.length > 0) {
        this.crudService.view(key);
      }
    }

    this.select.next({ key: key, row: row }); // TODO: REVIEW
  }

  optionValue(control: DataControl, option: any, withLabel: boolean = false): any {
    return this.crudService.optionValue(control, option, withLabel);
  }

  compareOptions(option1: any, option2: any): boolean {
    return JSON.stringify(option1) === JSON.stringify(option2);
  }

  fieldLabel(column: DataTableColumn, item: any, defaultValue?: any): any {
    const value = Util.getFieldValue(item, column.key, defaultValue);

    if (column.options && column.options.value) {
      if (column.options.parent && (!column.options.values || column.options.values.length === 0)) {
        if (value !== undefined && value !== null) {
          return value[column.options.label || column.options.value];
        }
      } else if (column.options.values) {
        const option = column.options.values.find(o => (value &&
          o.value === (column.options.static ? value : value[column.options.value])) ||
          (value === null && (o.value === null || o.value === -1)));
        return option ? option.label || option.value : defaultValue;
      } else {
        return null;
      }
    }

    return value;
  }

  fieldValue(item: any, control: any, defaultValue?: any): any {
    const value = Util.getFieldValue(item, control.key, defaultValue);

    if (control.type === 'select') {
      switch (value) {
        case undefined:
          return -2;
        case null:
          return -1;
      }
    }

    return value;
  }

  label(controlOrOption: DataControl | any, label: any = null) {
    if (label === null) {
      let value = controlOrOption.getFormControl().value;
      const options = controlOrOption.options;

      if (options.values) {
        if (controlOrOption.type === 'select') {
          switch (+value) {
            case -2:
              value = undefined;
              break;
            case -1:
              value = null;
              break;
          }
        }

        if (options.values.length > 0 && value !== undefined) {
          const option = options.values.find(v => value && v[options.value] === value[options.value]);
          const labelValue = this.dataTableService.label(option, options.label);
          if (labelValue !== null) {
            return labelValue;
          }
        }

        if (!controlOrOption.required) {
          return Util.isNotNullOrUndefined(controlOrOption.options.nullLabel) ? controlOrOption.options.nullLabel : 'Nenhum(a)';
        }

        return null;
      } else {
        return value;
      }
    }

    return this.dataTableService.label(controlOrOption, label);
  }

  sort(key: string) {
    this.skip = 0;
    this.top = this.entityTop;

    this.dataTableService.setOrder(key);

    this.columns.forEach(column => {
      const order = this.dataTableService.getOrder(column.key);
      column.orderClass = order && Util.isNotNullOrUndefined(order.sortIndex) ? (order.sortInverted ? 'desc' : 'asc') : undefined;
    });

    this.crudService.sort(key, this.readonly);
  }

  filter(column: DataTableColumn, value: any) {
    let immediate = false;
    if (column.type === 'checkbox' || column.type === 'select') {
      if (!column.ready) {
        return;
      }

      immediate = true;
    }

    this.skip = 0;
    this.top = this.entityTop;

    this.dataTableService.setFilterValue(column, value);

    this.columns.filter(c => c.options && c.options.parent === column.key).forEach(c => {
      if (value !== undefined && value !== null) {
        this.fillFilterOptions(c, value);
      } else {
        this.crudService.resetOptions(c);

        this.dataTableService.unfilterColumn(c);

        this.columns.filter(child => child.options && child.options.parent === c.key)
          .forEach(child => {
            this.crudService.resetOptions(child);

            this.dataTableService.unfilterColumn(child);
          });
      }
    });

    this.crudService.columns.forEach(c => {
      c.filter = this.dataTableService.getFilterValue(c);
    });

    this.crudService.filter(this.readonly, immediate);
  }

  cancelWhenReadonly(event: any, readonly: any): any {
    if (readonly) {
      event.preventDefault();
      return false;
    }
  }

  changeCallback(event: any, readonly: any): void {
    if (readonly) {
      return;
    }
  }

  slideEnd(control: DataControl) {
    if (!this.editing) {
      setTimeout(() => {
        control.getFormControl().setValue(this.entity[control.key]);
      }, 1);
    }
  }
  clickCallback(event: any, control: DataControl): any {
    if (!this.editing) {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }

    if (control && control.onClick && typeof control.onClick === 'function') {
      control.onClick(event, control);
    }
  }

  get ordered(): Array<any> {
    return this.dataTableService.ordered;
  }
  get hasOrdered(): boolean {
    return this.dataTableService.hasOrdered;
  }


  get filtered(): Array<any> {
    return this.dataTableService.filtered;
  }
  get hasFiltered(): boolean {
    return this.dataTableService.hasFiltered;
  }
  filterValue(column: DataTableColumn): any {
    // TODO: Use undefined OR null depending on column type
    const value = this.dataTableService.getFilterValue(column);
    return value !== undefined ? value : null;
  }


  get selected(): Array<Selected> {
    return this.dataTableService.selected;
  }
  set selected(selected: Array<Selected>) {
    this.dataTableService.selected = selected;
  }
  get hasSelected(): boolean {
    return this.dataTableService.hasSelected;
  }
  get allVisibleSelected(): boolean {
    return this.dataTableService.allVisibleSelected(this.items, this.keyField);
  }
  get allSelected(): boolean {
    return this.dataTableService.allSelected(this.items, this.keyField);
  }

  allSelect() {
    this.dataTableService.allSelect(this.items, this.keyField);
  }
  singleSelect(item: any) {
    this.dataTableService.singleSelect(item, this.keyField);
  }
  checked(item: any) {
    return this.dataTableService.checked(item, this.keyField);
  }

  findControl(key: string): DataControl {
    return this.crudService.findControl(key);
  }

  get sessionLoaded(): boolean {
    return (!this.configService.useServerInfo && Util.isNotNullOrUndefined(this.sessionService.loggedUser)) ||
      Util.isNotNullOrUndefined(this.sessionService.server);
  }

  lazy() {
    if (!this.loading) {
      this.skip += this.top;
      this.get(null, this.skip, this.top);
    }
  }
}
