import { GatilhoObrigacaoDisparo } from 'app/centralweb/model/gatilho-obrigacao-disparo.model';

export class GatilhoObrigacao {
  id: number;
  cod: string;
  descricao: string;
  gatilhoObrigacaoDisparoList: Array<GatilhoObrigacaoDisparo>;


  constructor(gatilhoobrigacao?: GatilhoObrigacao | number) {
    if (gatilhoobrigacao !== undefined && gatilhoobrigacao !== null) {
      if (typeof gatilhoobrigacao === 'number') {
        this.id = gatilhoobrigacao;
      } else {
        this.id = gatilhoobrigacao.id;
        this.cod = gatilhoobrigacao.cod;
        this.descricao = gatilhoobrigacao.descricao;
        this.gatilhoObrigacaoDisparoList = gatilhoobrigacao.gatilhoObrigacaoDisparoList;
      }
    }
  }
}
