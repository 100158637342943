import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ConfigService } from '../../shared/services/config.service';
import { HttpService } from '../../shared/services/http.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { SessionService } from '../../shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-relatorio-detalhe',
  templateUrl: './relatorio-detalhe.component.html',
  styleUrls: ['./relatorio-detalhe.component.scss']
})
export class RelatorioDetalheComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService
  ) {}

  // Parâmetros da url;
  @Input()
  keyField: 'id';
  @Input()
  dtIni: 'dtIni';
  @Input()
  dtFin: 'dtFin';
  @Input()
  detalhePorItem: 'detalhePorItem';
  @Input()
  fornecedor: 'fornecedor';
  @Input()
  pedido: 'pedido';
  @Input()
  status: 'status';
  @Input()
  tipo: 'tipo';
  @Input()
  comprador: 'comprador';

  // Parâmetros do subtítulo;
  subDtIni: String = '';
  subDtFin: String = '';
  subFornecedor: String = '';
  subPedido: String = '';
  subStatus: String = '';
  subTipo: String = '';

  // Parâmetros da query;
  private queryEmpresaId: any;
  private queryDtIni: any;
  private queryDtFin: any;
  private queryDetalhePorItem: any;
  private queryFornecedor: string;
  private queryComprador: string;
  private queryPedido: any;
  private queryStatus: string;
  private queryTipo: string;

  title: string;
  subtitle: string;

  detalhe: Boolean = false;

  private empresaSubscription: Subscription;
  private initSubscription: Subscription;

  lista: Array<any> = [];
  colsLista :any[] = [];

  ngOnInit() {
    Promise.resolve(null).then(() => (this.toolbarService.hidden = true));

    this.title = 'relatório de pedidos';

    this.activatedRoute.params.subscribe(params => {
      this.queryDetalhePorItem = params['detalhePorItem'];
      this.queryDtIni = params['dtIni'];
      this.queryDtFin = params['dtFin'];
      this.queryEmpresaId = params['id'];
      this.queryFornecedor = params['fornecedor'];
      this.queryPedido = params['pedido'] === 'null' ? '' : params['pedido'];
      this.queryStatus = params['status'];
      this.queryTipo = params['tipo'];
      this.queryComprador = params['comprador'];
    });

    // Chamar após a definição das vars dentro do 'this.activatedRoute.params.subscribe';
    this.getPedidos();
    this.setSubtitle();

    this.validaDetalhe();

    if (this.queryFornecedor === '0') {
      this.getFornecedor();
    }
    if (this.queryStatus === '0') {
      this.getStatus();
    }

    // this.colsLista =[
    //   { field: 'dtEmissao', header: 'DT EMISSÃO' },
    //   { field: 'codPedido', header: 'COD PEDIDO' },
    //   { field: 'incoterm', header: 'INCOTERM' },
    //   { field: 'numJira', header: 'COD CONTROLE' },
    //   { field: 'orcamento', header: 'ORÇAMENTO' },
    //   { field: 'mtdEnvio', header: 'MÉTODO ENVIO' },
    //   { field: 'cnpj', header: 'CNPJ' },
    //   { field: 'nome', header: 'NOME' },
    //   { field: 'uf', header: 'UF' },
    //   { field: 'codigo', header: 'CÓDIGO' },
    //   { field: 'descricao', header: 'DESCRIÇÃO' },
    //   { field: 'pnForn', header: 'PN FORNECEDOR' },
    //   { field: 'operacao', header: 'OPERAÇÃO' },
    //   { field: 'ncm', header: 'NCM' },
    //   { field: 'quantidade', header: 'QTDE' },
    //   { field: 'vlrUnit', header: 'VLR UNIT' },
    //   { field: 'vlrTotal', header: 'VLR TOTAL' },
    //   { field: 'vlrIcms', header: 'VLR ICMS' },
    //   { field: 'vlrIcmsSt', header: 'VLR ICMS ST' },
    //   { field: 'vlrIpi', header: 'VLR IPI' },
    //   { field: 'vlrImpImport', header: 'VLR IMP IMPORT' },
    //   { field: 'vlrDesconto', header: 'VLR DESCONTO' },
    //   { field: 'vlrFrete', header: 'VLR FRETE' },
    //   { field: 'vlrSeguro', header: 'VLR SEGURO' },
    //   { field: 'vlrOutro', header: 'VLR OUTRO' },
    //   { field: 'tipo', header: 'TIPO' },
    //   { field: 'rateio', header: 'RATEIO' },
    //   { field: 'status', header: 'STATUS' }
    // ];

      this.colsLista = [];
      this.colsLista.push( { field: 'dtEmissao', header: 'DT EMISSÃO' });
      this.colsLista.push( { field: 'codPedido', header: 'COD PEDIDO' });
      this.colsLista.push( { field: 'incoterm', header: 'INCOTERM' });
      this.colsLista.push( { field: 'numJira', header: 'COD CONTROLE' });
      this.colsLista.push( { field: 'orcamento', header: 'ORÇAMENTO' });
      this.colsLista.push( { field: 'mtdEnvio', header: 'MÉTODO ENVIO' });
      this.colsLista.push( { field: 'cnpj', header: 'CNPJ' });
      this.colsLista.push(  { field: 'nome', header: 'NOME' });
      this.colsLista.push( { field: 'uf', header: 'UF' });
      if(this.detalhe) this.colsLista.push( { field: 'codigo', header: 'CÓDIGO' });
      if(this.detalhe) this.colsLista.push( { field: 'descricao', header: 'DESCRIÇÃO' });
      if(this.detalhe) this.colsLista.push( { field: 'pnForn', header: 'PN FORNECEDOR' });
      if(this.detalhe) this.colsLista.push( { field: 'operacao', header: 'OPERAÇÃO' });
      if(this.detalhe) this.colsLista.push( { field: 'ncm', header: 'NCM' });
      this.colsLista.push( { field: 'quantidade', header: 'QTDE' });
      if(this.detalhe) this.colsLista.push( { field: 'vlrUnit', header: 'VLR UNIT' });
      this.colsLista.push( { field: 'vlrTotal', header: 'VLR TOTAL' });
      if(this.detalhe) this.colsLista.push( { field: 'vlrIcms', header: 'VLR ICMS' });
      if(this.detalhe) this.colsLista.push( { field: 'vlrIcmsSt', header: 'VLR ICMS ST' });
      if(this.detalhe) this.colsLista.push( { field: 'vlrIpi', header: 'VLR IPI' });
      if(this.detalhe) this.colsLista.push( { field: 'vlrImpImport', header: 'VLR IMP IMPORT' });
      if(this.detalhe) this.colsLista.push( { field: 'vlrDesconto', header: 'VLR DESCONTO' });
      if(this.detalhe) this.colsLista.push( { field: 'vlrFrete', header: 'VLR FRETE' });
      if(this.detalhe) this.colsLista.push( { field: 'vlrSeguro', header: 'VLR SEGURO' });
      if(this.detalhe) this.colsLista.push( { field: 'vlrOutro', header: 'VLR OUTRO' });
      this.colsLista.push( { field: 'tipo', header: 'TIPO' });
     if(this.detalhe)  this.colsLista.push( { field: 'rateio', header: 'RATEIO' });
      this.colsLista.push( { field: 'status', header: 'STATUS' }  );



  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.initSubscription);
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  private getFornecedor() {
    const arr: Array<any> = [];

    this.httpService.wait();
    this.httpService
      .get('/custom/pedido/get-ped-fornecedor(' + this.queryEmpresaId + ')')
      .subscribe(
        rst => {
          rst.forEach(element => {
            arr.push(element.id);
          });

          this.queryFornecedor = JSON.stringify(arr, null, '')
            .replace('[', '')
            .replace(']', '');

          this.getPedidos();
          this.setSubtitle();
        },
        error =>
          this.httpService.handleError(error, () => this.getFornecedor()),
        () => this.httpService.done()
      );
  }

  private getPedidos() {
    this.httpService.wait();

    const obj = {
      id: this.queryEmpresaId,
      dtIni: this.queryDtIni,
      dtFin: this.queryDtFin,
      detalhePorItem: this.queryDetalhePorItem,
      fornecedor: this.queryFornecedor,
      pedido: this.queryPedido,
      status: this.queryStatus,
      tipo: this.queryTipo,
      comprador: this.queryComprador
    };

    this.httpService.post('/custom/pedido/get-ped-all', obj).subscribe(
      rst => {
        this.lista = rst;
      },
      error => this.httpService.handleError(error, () => this.getPedidos()),
      () => this.httpService.done()
    );
  }

  private getStatus() {
    const arr: Array<any> = [];

    this.httpService.wait();
    this.httpService.get('/custom/pedido/get-ped-status').subscribe(
      rst => {
        rst.forEach(element => {
          arr.push(element.id);
        });

        this.queryStatus = JSON.stringify(arr, null, '')
          .replace('[', '')
          .replace(']', '');

        this.getPedidos();
        this.setSubtitle();
      },
      error => this.httpService.handleError(error, () => this.getStatus()),
      () => this.httpService.done()
    );
  }

  private setSubtitle() {
    // data inicial;
    // ------------------------------------------
    this.subDtIni =
      this.queryDtIni.substring(8, 10) +
      '/' +
      this.queryDtIni.substring(5, 7) +
      '/' +
      this.queryDtIni.substring(0, 4);

    // data final;
    // ------------------------------------------
    this.subDtFin =
      this.queryDtFin.substring(8, 10) +
      '/' +
      this.queryDtFin.substring(5, 7) +
      '/' +
      this.queryDtFin.substring(0, 4);

    // pedido;
    // ------------------------------------------
    this.subPedido = this.queryPedido === '' ? 'diversos' : this.queryPedido;

    // fornecedor;
    // ------------------------------------------
    if (!this.queryFornecedor.match(',')) {
      this.httpService.wait();
      this.httpService
        .get('/participante?$select=nome&$filter=id eq ' + this.queryFornecedor)
        .subscribe(
          rst => {
            rst.value.forEach(element => {
              this.subFornecedor = element.nome;
            });
          },
          error =>
            this.httpService.handleError(error, () => this.setSubtitle()),
          () => this.httpService.done()
        );
    } else {
      this.subFornecedor = 'diversos';
    }

    // status;
    // ------------------------------------------
    if (!this.queryStatus.match(',')) {
      this.httpService.wait();
      this.httpService
        .get(
          '/pedidostatus?$select=description&$filter=id eq ' + this.queryStatus
        )
        .subscribe(
          rst => {
            rst.value.forEach(element => {
              this.subStatus = element.description;
            });
          },
          error =>
            this.httpService.handleError(error, () => this.setSubtitle()),
          () => this.httpService.done()
        );
    } else {
      this.subStatus = 'diversos';
    }

    // tipo;
    // ------------------------------------------
    if (!this.queryTipo.match(',')) {
      this.httpService.wait();
      this.httpService
        .get('/pedidotipo?$select=description&$filter=id eq ' + this.queryTipo)
        .subscribe(
          rst => {
            rst.value.forEach(element => {
              this.subTipo = element.description;
            });
          },
          error =>
            this.httpService.handleError(error, () => this.setSubtitle()),
          () => this.httpService.done()
        );
    } else {
      this.subTipo = 'diversos';
    }
  }

  private validaDetalhe() {
    if (this.queryDetalhePorItem === '0') {
      this.detalhe = true;
    }
    if (this.queryDetalhePorItem === '1') {
      this.detalhe = false;
    }
  }
}
// console.log('\n--> ' + JSON.stringify(rst, null, ''));
