import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from 'app/shared/services/util.service';

@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {
  private utilService: UtilService;

  constructor() {
    this.utilService = new UtilService();
  }

  transform(date: any, timezone?: number): number {
    const diff = (timezone ? timezone * 60 : this.utilService.moment(date).utcOffset()) * 60 * 1000 * -1;

    return date + diff;
  }
}
