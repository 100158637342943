import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model'
import { Item } from 'app/cadastro/model/item.model'
import { OperacaoRegraContabil } from 'app/cadastro/model/operacao-regra-contabil.model'
import { OperacaoRegra } from 'app/cadastro/model/operacao-regra.model'
import { CadCcus } from 'app/contabil/model/cad-ccus.model'
import { CadProj } from 'app/contabil/model/cad-proj.model'
import { PedidoParcela } from 'app/financeiro/model/pedido-parcela.model'
import { Categoria } from 'app/rms/model/categoria.model'
import { Nda } from 'app/rms/model/nda.model'
import { Objeto } from 'app/rms/model/objeto.model'
import { PrioridadeRequisicao } from 'app/rms/model/prioridade-requisicao.model'
import { RequisicaoFinanceiro } from 'app/rms/model/requisicao-financeiro.model'
import { RequisicaoFornecedorSugerido } from 'app/rms/model/requisicao-fornecedor-sugerido.model'
import { RequisicaoItemRateio } from 'app/rms/model/requisicao-item-rateio.model'
import { RequisicaoItem } from 'app/rms/model/requisicao-item.model'
import { Requisicao } from 'app/rms/model/requisicao.model'
import { Tipo } from 'app/rms/model/tipo.model'
import { FormaPagamento } from 'app/util/model/forma-pagamento.model'
import { ItemTipo } from 'app/util/model/item-tipo.model'
import { Moeda } from 'app/util/model/moeda.model'
import { Origem } from 'app/util/model/origem.model'
import { Tipi } from 'app/util/model/tipi.model'
import { TipoBaixa } from 'app/util/model/tipo-baixa.model'

export function resetRequisition(): Requisicao {
  let requisition: Requisicao = new Requisicao()
  requisition.titulo = null
  requisition.dtEntrega = new Date()
  requisition.tipo = new Tipo(null)
  requisition.objeto = new Objeto(null)
  requisition.categoria = new Categoria(null)
  requisition.origem = new Origem(null)
  requisition.nda = new Nda(null)
  requisition.prioridade = new PrioridadeRequisicao(null)
  requisition.moeda = new Moeda(1)
  requisition.txMoeda = 1
  requisition.descricao = null
  requisition.formaPagamento = new FormaPagamento(null)
  requisition.empresaConta = new EmpresaConta(null)

  return {...requisition}
}

export function resetRequisitionItem(): RequisicaoItem {
  let requisitionItem: RequisicaoItem = new RequisicaoItem()
  requisitionItem.itemCadastrado = true
  requisitionItem.operacaoRegra = new OperacaoRegra(null)
  requisitionItem.operacaoId = null
  requisitionItem.itemInt = new Item(null)
  requisitionItem.descricao = null, requisitionItem.descricaoBr = null, requisitionItem.descricaoEn = null
  requisitionItem.itemTipo = new ItemTipo(null)
  requisitionItem.partNumber = null
  requisitionItem.tipiCap = new Tipi(null)
  requisitionItem.tipi = new Tipi(null)
  requisitionItem.fabricante = null
  requisitionItem.link = null
  requisitionItem.quantidade = null
  requisitionItem.valorUnit = null, requisitionItem.valorTotal = null, requisitionItem.valorTotalBrl = null

  return {...requisitionItem}
}

export function resetRequisitionItemApportionment(): RequisicaoItemRateio {
  let requisitionItemApportionment: RequisicaoItemRateio = new RequisicaoItemRateio()
  requisitionItemApportionment.cadCcus = new CadCcus(null)
  requisitionItemApportionment.cadProj = new CadProj(null)
  requisitionItemApportionment.cadCcus.label = 'Nenhum'
  requisitionItemApportionment.cadProj.label = 'Nenhum'
  requisitionItemApportionment.porcentagem = null

  return {...requisitionItemApportionment}
}

export function resetSuggestedSupplier(): RequisicaoFornecedorSugerido {
  let suggestedSupplier: RequisicaoFornecedorSugerido = new RequisicaoFornecedorSugerido()
  suggestedSupplier.empresa = null
  suggestedSupplier.email = null
  suggestedSupplier.link = null
  suggestedSupplier.area = null
  suggestedSupplier.telefone = null
  suggestedSupplier.nome = null
  suggestedSupplier.observacao = null
  suggestedSupplier.participanteEmpresaId = null

  return {...suggestedSupplier}
}

export function resetFinancialRequisition(): RequisicaoFinanceiro {
  let financialRequisition: RequisicaoFinanceiro = new RequisicaoFinanceiro()
  financialRequisition.operacaoRegraContabil = new OperacaoRegraContabil(null)
  financialRequisition.historico = null
  financialRequisition.valor = null, financialRequisition.valorBrl = null
  financialRequisition.dtVencimento = new Date()
  financialRequisition.nDocumento = null
  financialRequisition.contaOrigem = new EmpresaConta(null)
  financialRequisition.contaDestino = new EmpresaConta(null)

  return {...financialRequisition}
}

export function resetInstallmentOrder(): PedidoParcela {
  let installmentOrder: PedidoParcela = new PedidoParcela()
  installmentOrder.id = 1
  installmentOrder.parcela = 1
  installmentOrder.historico = null
  installmentOrder.valor = null
  installmentOrder.empresaConta = new EmpresaConta(null)
  installmentOrder.empresaConta.nome = null
  installmentOrder.tipoBaixa = resetLowType()
  installmentOrder.dtVencimento = new Date()

  return installmentOrder
}

export function resetLowType(): TipoBaixa {
  let lowType = new TipoBaixa(0)
  lowType.descricao = null

  return lowType
}