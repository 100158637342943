import { TitleService } from 'app/shared/services/title.service';
import { UtilService } from 'app/shared/services/util.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Period } from 'app/shared/components/period-select/period.model';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { Message, MessageService } from 'primeng/api';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { EcdEcfService } from './../../contabil/services/ecf-ecf.service';
import { MovParteAIrpj } from 'app/ecf/model/mov-parte-a-irpj.model';
import { MovParteADeducoes } from 'app/ecf/model/mov-parte-a-deducoes.model';
import { TbIrpjM300 } from 'app/ecf/model/tb-irpj-m300.model';
import { TbIrpjN620 } from 'app/ecf/model/tb-irpj-n620.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';
import { MovSaldosContabeis } from 'app/contabil/model/mov-saldos-contabeis.model';
import { TbRelacMovParteAIrpjSaldosContabeis } from 'app/ecf/model/tb-relac-mov-parte-a-irpj-saldos-contabeis.model';
import { TbRelacMovParteADeducoesSaldosContabeis } from 'app/ecf/model/tb-relac-mov-parte-a-deducoes-saldos-contabeis.model';
import { TbIndRelacao } from 'app/ecf/model/tb-ind-relacao.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { MovParteB } from 'app/ecf/model/mov-parte-b.model';
import { MovParteBLcto } from 'app/ecf/model/mov-parte-b-lcto.model';
import { TbPrejAcumulado } from 'app/ecf/model/tb-prej-acumulado.model';

@Component({
    selector: 'app-teste',
    templateUrl: './parte-a-m300-irpj.component.html',
    styleUrls: ['./parte-a-m300-irpj.component.scss'],
    providers: [EcdEcfService]
})
export class ParteAM300IrpjComponent implements OnInit, OnDestroy {
    planoSelecionados: any; // TODO: REVIEW
    contasSelecionadas: any; // TODO: REVIEW
    contasSelecionadasDeducao: any; // TODO: REVIEW

    private sessionSubscription: Subscription;

    modalConta: boolean;
    modalContaDeducoes: boolean;
    modalMapeamento: boolean;
    modalMapeamentoExcluir: boolean;
    modalMapeamentoDeducoes: boolean;
    modalSelecao: boolean;
    modalSelecaoDeducoes: boolean;
    modalInexistente: boolean;
    modalPrejuizoAcumulado: boolean;

    movParteA: MovParteAIrpj;
    movParteADeducoes: MovParteADeducoes;
    // movParteAListAno: Array<MovParteAIrpj> = new Array();
    movParteADeducoesListAno: Array<MovParteADeducoes> = new Array();

    tbIrpj: TbIrpjM300;
    tbN620: TbIrpjN620;
    cadEcd: Array<CadEcd>;

    listaList: Array<any>;
    listaListExc: Array<any>;
    listaListDed: Array<any>;
    listaListSoma: Array<any>;
    listaListSomaExc: Array<any>;
    listaListSomaDeducoes: Array<any>;
    listaListSomaResultado: Array<any>;
    listaSomaResultadoAntesIrpj: Array<any>;
    listaSomaLucroAntesCompensacao: Array<any>;
    listaPrejFiscalCompensado: Array<any>;
    listaSaldoPrejFiscalCompensar: Array<any>;
    listaCompensacaoPrejPeriodoAnterior: Array<any>;
    listaLucroAjustado: Array<any>;
    listaImpRenda: Array<any>;
    listaAdicImpRenda: Array<any>;
    listaTotalIrpj: Array<any>;
    listaTotalAntecIrpj: Array<any>;
    listaValorLiqRecolher: Array<any>;

    listSomaIrpj: Array<any>;
    balancetesSelecionados: Array<MovSaldosContabeis> = new Array();
    balancete: Array<MovSaldosContabeis> = new Array();
    movParteAList: Array<MovParteAIrpj> = new Array();
    movParteADeducoesList: Array<MovParteADeducoes> = new Array();
    movParteANew: Array<MovParteAIrpj> = new Array();
    tbRelacIrpj: Array<TbRelacMovParteAIrpjSaldosContabeis>;
    tbRelacIrpjList: Array<TbRelacMovParteAIrpjSaldosContabeis>;
    tbRelacDeducoes: Array<TbRelacMovParteADeducoesSaldosContabeis>;
    tbRelacDeducoesList: Array<TbRelacMovParteADeducoesSaldosContabeis>;

    tbIrpjM300ListAll: Array<TbIrpjM300> = new Array();
    tbIrpjM300Selecionados: Array<TbIrpjM300> = new Array();

    indicadorLcto: SelectItem[];

    tbIrpjM300Relacionados: Array<TbIrpjM300> = new Array();

    tbIrpjN620ListAll: Array<TbIrpjN620> = new Array();
    tbIrpjN620Selecionados: Array<TbIrpjN620> = new Array();
    tbIndRelacao: Array<TbIndRelacao> = new Array();
    planoContas: Array<PlanoContas> = new Array();
    planoContasFull: Array<PlanoContas> = new Array();

    newTbRelacIrpj: TbRelacMovParteAIrpjSaldosContabeis;
    atuaTbRelacIrpj: TbRelacMovParteAIrpjSaldosContabeis;
    atuaTbRelacDeducoes: TbRelacMovParteADeducoesSaldosContabeis;
    saldosRelacionados: Array<TbRelacMovParteAIrpjSaldosContabeis> = new Array();
    saldosRelacionadosDeducoes: Array<TbRelacMovParteADeducoesSaldosContabeis> = new Array();
    display = false;
    tipoConta: number;
    mes: number;

    msgs: Message[] = [];

    opcoesMovParteB: SelectItem[];
    movParteBList: Array<MovParteB> = new Array();
    movParteBOpcoes: Array<MovParteB> = new Array();
    movParteBSelecionados: Array<MovParteB> = new Array();
    addParteB: boolean;
    movParteBRelacionados: Array<MovParteBLcto>;
    movParteBLcto: MovParteBLcto;

    movParteBDemonstrativo: any = new Array(); // TODO: REVIEW
    desabilitarLancamentos: boolean;
    desabilitarLancamentosDeducoes: boolean;

    tbPrejAcumulado: TbPrejAcumulado = new TbPrejAcumulado();

    private editSaldosRelacionadosTimeout: NodeJS.Timer;

    titlePrincipal: string;
    subtitle: string;

    contadorLinha: number = 0;
    contadorLinhaN620: number = 0;

    constructor(private httpService: HttpService,
                private sessionService: SessionService,
                private dblinkedSessionService: DblinkedSessionService,
                private utilService: UtilService,
                private titleService: TitleService,
                private ecdEcfService: EcdEcfService,
                private messageService: MessageService) {
      this.titleService.title = 'Livro de Apuração do Lucro Real (IRPJ)';
    }

    ngOnInit() {
        this.titlePrincipal = 'LIVRO DE APURAÇÃO DO LUCRO REAL (LALUR - IRPJ)';
        this.subtitle = 'CÁLCULO DO LALUR - VISÃO M300 (IRPJ)';
        this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

        this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
                                                                             () => { this.periodChanged(); });
        this.contadorLinha = 0;
        this.contadorLinhaN620 = 0;


    }

    ngOnDestroy() {
      this.dblinkedSessionService.hidePeriods();

      this.sessionService.destroySubscribe(this.sessionSubscription);
    }

    periodChanged() {
        if (!this.dblinkedSessionService.hasEmpresaSelected || !this.dblinkedSessionService.hasPeriodoSelected) {
          return;
        }

        this.ecdEcfService.load(this.dblinkedSessionService.empresa.id, this.year);

        this.tbPrejAcumulado = new TbPrejAcumulado();
        this.tbPrejAcumulado.empresa = new Empresa(this.empresa.id);
        this.tbPrejAcumulado.periodo = this.year - 1;
        this.tbPrejAcumulado.valorPrejuizo = 0.00;

        this.display = true;
        this.tipoConta = null;
        this.mes = 0;
        this.tbIrpjM300Selecionados = new Array();
        this.tbIrpjM300Relacionados = new Array();
        this.tbIrpjN620Selecionados = new Array();
        this.balancetesSelecionados = new Array();
        this.movParteA = new MovParteAIrpj();
        this.movParteBLcto = new MovParteBLcto();
        this.movParteADeducoes = new MovParteADeducoes();
        this.addParteB = false;
        this.movParteBDemonstrativo = new Array<any>();
        this.desabilitarLancamentos = false;
        this.desabilitarLancamentosDeducoes = false;
        this.listaListSoma = new Array<any>();
        this.listaListSomaExc = new Array<any>();
        this.listSomaIrpj = new Array<any>();
        this.saldosRelacionados = new Array<TbRelacMovParteAIrpjSaldosContabeis>();
        this.saldosRelacionadosDeducoes = new Array<TbRelacMovParteADeducoesSaldosContabeis>();
        this.movParteBRelacionados = new Array();
        this.tbIrpjM300ListAll = new Array();

        this.tbRelacIrpjList = new Array();
        this.tbRelacDeducoesList = new Array();
        this.cadEcd = new Array();

        /*this.httpService.wait();
        this.httpService.get('/custom/ecd/m300', this.empresa.id)
            .subscribe(tbIrpjM300List => this.tbIrpjM300ListAll = tbIrpjM300List.value,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());*/

        this.httpService.wait();
        this.httpService.get('/custom/ecd/m300Relac', this.empresa.id)
            .subscribe(tbIrpjM300Relacionados => this.tbIrpjM300Relacionados = tbIrpjM300Relacionados.value,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/tbindrelacao?$select=id,cod,descricao')
            .subscribe(tbIndRelacaoList => this.tbIndRelacao = tbIndRelacaoList.value,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/tbirpj', [this.year, this.empresa.id])
            .subscribe(tbIrpjList => this.listaList = tbIrpjList,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/tbirpjexc', [this.year, this.empresa.id])
            .subscribe(tbIrpjListExc => this.listaListExc = tbIrpjListExc,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/tbdeducoes', [this.year, this.empresa.id])
                        .subscribe(listaListDed => this.listaListDed = listaListDed,
                                   error => this.httpService.handleError(error, () => this.periodChanged()),
                                   () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/tbsomadeducoes', [this.year, this.empresa.id])
                        .subscribe(listaListSomaDeducoes => this.listaListSomaDeducoes = listaListSomaDeducoes,
                                   error => this.httpService.handleError(error, () => this.periodChanged()),
                                   () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(lucro_prej_acum,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listaListSomaResultado => this.listaListSomaResultado = listaListSomaResultado,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(irpj_csll,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listSomaIrpj => this.listSomaIrpj = listSomaIrpj,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(lucro_prej_acum_antes_irpj_csll,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listaSomaResultadoAntesIrpj => this.listaSomaResultadoAntesIrpj = listaSomaResultadoAntesIrpj,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(adicoes,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listaListSoma => this.listaListSoma = listaListSoma,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(exclusoes,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listaListSomaExc => this.listaListSomaExc = listaListSomaExc,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(lucro_antes_comp_prej_fiscal,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listaSomaLucroAntesCompensacao => this.listaSomaLucroAntesCompensacao = listaSomaLucroAntesCompensacao,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(prej_fiscal_compensar,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listaPrejFiscalCompensado => this.listaPrejFiscalCompensado = listaPrejFiscalCompensado,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(prej_fiscal_compensado,' + this.year + ',' + this.empresa.id + ')')
                        .subscribe(l => this.listaCompensacaoPrejPeriodoAnterior = l,
                                   error => this.httpService.handleError(error, () => this.periodChanged()),
                                   () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(saldo_prej_compensar,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listaSaldoPrejFiscalCompensar => this.listaSaldoPrejFiscalCompensar = listaSaldoPrejFiscalCompensar,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(lucro_ajustado,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listaLucroAjustado => this.listaLucroAjustado = listaLucroAjustado,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(imp_renda,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listaImpRenda => this.listaImpRenda = listaImpRenda,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(adic_imp_renda,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listaAdicImpRenda => this.listaAdicImpRenda  = listaAdicImpRenda,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea(total_irpj_recolher,' + this.year + ',' + this.empresa.id + ')')
            .subscribe(listaTotalIrpj => this.listaTotalIrpj  = listaTotalIrpj,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listaparteafunction(irpj_recolher,' + this.year + ',' + this.empresa.id + ',1)')
            .subscribe(listaTotalAntecIrpj => this.listaTotalAntecIrpj  = listaTotalAntecIrpj,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listaparteafunction(irpj_recolher,' + this.year + ',' + this.empresa.id + ',2)')
                        .subscribe(listaValorLiqRecolher => this.listaValorLiqRecolher = listaValorLiqRecolher,
                                   error => this.httpService.handleError(error, () => this.periodChanged()),
                                   () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/tbprejacumulado?$select=id,periodo,valorPrejuizo' +
                                             '&$filter=empresa/id eq ' + this.empresa.id +
                                             ' and periodo eq ' + (this.year - 1))
                        .subscribe(tbPrejAcumulado => {
                                     if (tbPrejAcumulado.value.length === 1) {
                                       this.tbPrejAcumulado = new TbPrejAcumulado(tbPrejAcumulado.value[0]);
                                     }
                                   },
                                   error => this.httpService.handleError(error, () => this.periodChanged()),
                                   () => this.httpService.done());

        this.indicadorLcto = [];
        this.indicadorLcto.push({label: 'Selecione', value: null});
        this.indicadorLcto.push({label: 'Base de cálculo negativa da CSLL', value: 'B'});
        this.indicadorLcto.push({label: 'Crédito', value: 'C'});
        this.indicadorLcto.push({label: 'Débito', value: 'D'});
        this.indicadorLcto.push({label: 'Prejuízo do Exercício', value: 'P'});

    }

    excMovParteA(id: number) {

        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletetbmovpartea', [id, this.empresa.id])
                .subscribe(() => this.periodChanged(),
                error => this.httpService.handleError(error, () => this.excMovParteA(id)),
                () => this.httpService.done());
        }
    }

    excMovParteADeducoes(id: number) {

        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletetbmovparteadeducoes', [id, this.empresa.id])
                .subscribe(() => this.periodChanged(),
                error => this.httpService.handleError(error, () => this.excMovParteADeducoes(id)),
                () => this.httpService.done());
        }
    }


    /*Método acionado quando o usuário clica em alguma Conta e Mês*/
    onContaSelect(idIrpj: number, mes: number) {

        this.desabilitarLancamentos = false;
        this.tbIrpj = new TbIrpjM300(idIrpj);
        this.mes = mes;
        this.modalConta = true;
        this.modalSelecao = false;
        this.verifMes(mes);

        /*this.httpService.wait();
        this.httpService.get('/movparteairpj?$select=id,data,hist,valor,tbIrpjM300,tbIndRelacao,tbIndRelacao/id,tbIndRelacao/cod,' +
            'tbIndRelacao/descricao,planoContas/id,planoContas/codCta,planoContas/descrCta,cadEcd/empresa/id' +
            '&$filter=tbIrpjM300/id eq ' + this.tbIrpj.id +
            ' and data ge \'' + this.utilService.dateFormat(this.movParteA.data) + '\'' +
            ' and data le \'' + this.utilService.dateFormat(this.movParteA.data) + '\' and cadEcd/empresa/id eq ' +
             this.empresa.id)
            .subscribe(movVerif => this.existRelac(movVerif.value),
            error => this.httpService.handleError(error, () => this.onContaSelect(idIrpj, mes)),
            () => this.httpService.done());*/

            this.httpService.wait();
        this.httpService.get('/movparteairpj?$select=id,data,hist,valor,tbIrpjM300,tbIndRelacao,tbIndRelacao/id,tbIndRelacao/cod,' +
            'tbIndRelacao/descricao,planoContas/id,planoContas/codCta,planoContas/descrCta,cadEcd/empresa/id' +
            '&$filter=tbIrpjM300/id eq ' + this.tbIrpj.id +
            ' and data eq ' + this.movParteA.data.getTime() +
            ' and cadEcd/empresa/id eq ' +
            this.empresa.id)
            .subscribe(movVerif => this.existRelac(movVerif.value),
            error => this.httpService.handleError(error, () => this.onContaSelect(idIrpj, mes)),
            () => this.httpService.done());

        /*this.httpService.wait();
        this.httpService.get('/movparteairpj?$select=id,data,hist,valor,tbIrpjM300,tbIndRelacao,tbIndRelacao/id,tbIndRelacao/cod,' +
            'tbIndRelacao/descricao,planoContas/id,planoContas/codCta,planoContas/descrCta,empresa/id' +
            '&$filter=tbIrpjM300/id eq ' + this.tbIrpj.id + '&$orderby=data')
            .subscribe(movParteAListAno => this.movParteAListAno = movParteAListAno.value,
            error => this.httpService.handleError(error, () => this.onContaSelect(idIrpj, mes)),
            () => this.httpService.done());*/

        this.opcoesMovParteB = [];
        this.opcoesMovParteB.push({ label: 'Selecione uma conta da Parte B', value: null });

        this.httpService.wait();
        this.httpService.get('/movparteb?$&$filter=empresa/id eq ' + this.empresa.id + '&$orderby=data')
                        .subscribe(movParteBList => this.loadParteB(movParteBList.value),
                                   error => this.httpService.handleError(error, () => this.onContaSelect(idIrpj, mes)),
                                   () => this.httpService.done());
}

    loadParteB(value: Array<MovParteB>) {
        this.movParteBOpcoes = value;

        this.movParteBOpcoes.forEach(element => {
        this.opcoesMovParteB.push({ label: element.cod + ' - ' + element.descricao, value: element });
        });

    }



    onContaSelectDeducoes(idN620: number, mes: number) {
        this.periodChanged();
        this.desabilitarLancamentosDeducoes = false;
        this.tbN620 = new TbIrpjN620(idN620);
        this.mes = mes;

        this.modalContaDeducoes = true;
        this.modalSelecaoDeducoes = false;

        this.verifMes(mes);

        this.httpService.wait();
        this.httpService.get('/movparteadeducoes?$select=id,data,hist,valor,tbIrpjN620,tbIndRelacao,tbIndRelacao/id,tbIndRelacao/cod,' +
            'tbIndRelacao/descricao,planoContas/id,planoContas/codCta,planoContas/descrCta' +
            '&$filter=tbIrpjN620/id eq ' + this.tbN620.id +
            ' and data eq ' + this.movParteADeducoes.data.getTime() +
            ' and cadEcd/empresa/id eq ' + this.empresa.id)
            .subscribe(movDedVerif => this.existRelacDeducoes(movDedVerif.value),
            error => this.httpService.handleError(error, () => this.onContaSelectDeducoes(idN620, mes)),
            () => this.httpService.done());


        this.httpService.wait();
        this.httpService.get('/movparteadeducoes?$select=id,data,hist,valor,tbIrpjN620,tbIndRelacao,tbIndRelacao/id,tbIndRelacao/cod,' +
            'tbIndRelacao/descricao,planoContas/id,planoContas/codCta,planoContas/descrCta' +
            '&$filter=tbIrpjN620/id eq ' + this.tbN620.id + ' and cadEcd/empresa/id eq ' + this.empresa.id +
            '&$orderby=data')
            .subscribe(movParteADeducoesListAno => this.movParteADeducoesListAno = movParteADeducoesListAno.value,
            error => this.httpService.handleError(error, () => this.onContaSelectDeducoes(idN620, mes)),
            () => this.httpService.done());

    }

    existRelac(param: any) {

        this.movParteA = new MovParteAIrpj(param[0]);
        this.movParteBRelacionados = new Array();

        /*verifica se o movParteA existe*/
        if (this.movParteA.id === null || this.movParteA.id === undefined) {
            this.modalInexistente = true;
            this.modalConta = false;
        }

        if (this.movParteA.id) {
            this.movParteA.tbTipoLcto = null;
            this.movParteA.movApurReal = null;
            this.movParteA.cadCcus = null;
            this.movParteA.planoContas = this.movParteA.planoContas ?
            this.planoContas.find(planoContas => planoContas.id === this.movParteA.planoContas.id) : null;
            this.movParteA.tbIndRelacao = this.movParteA.tbIndRelacao ?
            this.tbIndRelacao.find(relacao => relacao.id === this.movParteA.tbIndRelacao.id) : null;
            this.movParteA.data = this.movParteA.data;

            // this.saldosRelacionados

            this.httpService.wait();
            this.httpService.get('/tbrelacmovparteairpjsaldoscontabeis?$select=id,saldoRelacionado,' +
            'movSaldosContabeis/cadEcd/dtIni,movSaldosContabeis/id,movSaldosContabeis/planoContas/id,' +
                'movSaldosContabeis/planoContas/codCta,movSaldosContabeis/planoContas/descrCta,movSaldosContabeis/cadCcus/id,' +
                'movSaldosContabeis/cadCcus/codCcus,movSaldosContabeis/cadCcus/descrCcus,movSaldosContabeis/indDcIni,movParteAIrpj/id,' +
                'movSaldosContabeis/vlSldIni,movSaldosContabeis/indDcFin,movSaldosContabeis/vlSldFin,movParteAIrpj/cadEcd/empresa/id' +
                '&$filter=movParteAIrpj/id eq ' + this.movParteA.id)
                //'&$orderby=movSaldosContabeis/planoContas/codCta')
                .subscribe(saldosRelacionados => {
                    this.saldosRelacionados = saldosRelacionados.value;
                    if (this.saldosRelacionados && this.saldosRelacionados.length > 0) {
                        this.desabilitarLancamentos = true;
                    }

                },
                error => this.httpService.handleError(error, () => this.existRelac(param)),
                () => this.httpService.done());

            this.httpService.wait();
            this.httpService.get('/movparteblcto?$select=id,data,valor,indLcto,descricao,movParteAIrpj/id,movParteB/id,' +
            'movParteB/cod,movParteB/descricao,movParteAIrpj/cadEcd/empresa/id&$filter=movParteAIrpj/id eq ' + this.movParteA.id)
            .subscribe(movParteBRelacionados => {
                this.movParteBRelacionados = movParteBRelacionados.value;
                if (this.movParteBRelacionados && this.movParteBRelacionados.length > 0) {
                        this.desabilitarLancamentos = true;
                    }
            },
            error => this.httpService.handleError(error, () => this.existRelac(param)),
            () => this.httpService.done());

        } else {
            this.httpService.wait();
            this.httpService.get('/tbrelacmovparteairpjsaldoscontabeis?$select=id,saldoRelacionado,movSaldosContabeis,' +
            'movSaldosContabeis/id,movSaldosContabeis/planoContas/codCta,movSaldosContabeis/planoContas/descrCta,' +
            'movSaldosContabeis/indDcIni,movSaldosContabeis/vlSldIni,movSaldosContabeis/indDcFin,' +
            'movSaldosContabeis/vlSldFin,movParteAIrpj/id,movParteAIrpj/cadEcd/empresa/id' +
                '&$filter=movParteAIrpj/id is null')
                .subscribe(tbRelacIrpj => this.tbRelacIrpj = tbRelacIrpj.value,
                error => this.httpService.handleError(error, () => this.existRelac(param)),
                () => this.httpService.done());
        }
    }

 existRelacDeducoes(param: any) {
        this.movParteADeducoes = new MovParteADeducoes(param[0]);

        /*verifica se o movParteA existe*/
        if (this.movParteADeducoes.id === null || this.movParteADeducoes.id === undefined) {
            this.modalInexistente = true;
            this.modalContaDeducoes = false;
        }

        if (this.movParteADeducoes.id) {

            this.movParteADeducoes.planoContas = this.movParteADeducoes.planoContas ?
            this.planoContas.find(planoContas => planoContas.id === this.movParteADeducoes.planoContas.id) : null;
            this.movParteADeducoes.tbIndRelacao = this.movParteADeducoes.tbIndRelacao ?
            this.tbIndRelacao.find(relacao => relacao.id === this.movParteADeducoes.tbIndRelacao.id) : null;
            this.movParteADeducoes.data = this.movParteADeducoes.data;


            this.httpService.wait();
            this.httpService.get('/tbrelacmovparteadeducoessaldoscontabeis?$select=id,saldoRelacionado,' +
            'movSaldosContabeis/cadEcd/dtIni,movSaldosContabeis/id,movSaldosContabeis/planoContas/codCta,movSaldosContabeis' +
            '/planoContas/descrCta,movSaldosContabeis/indDcIni,movSaldosContabeis/vlSldIni,movSaldosContabeis/indDcFin' +
            ',movSaldosContabeis/vlSldFin' +
                '&$filter=movParteADeducoes/id eq ' + this.movParteADeducoes.id).subscribe(saldosRelacionadosDeducoes => {
                    this.saldosRelacionadosDeducoes = saldosRelacionadosDeducoes.value;
                    if (this.saldosRelacionadosDeducoes && this.saldosRelacionadosDeducoes.length > 0) {
                        this.desabilitarLancamentosDeducoes = true;
                    }

                },
                error => this.httpService.handleError(error, () => this.existRelacDeducoes(param)),
                () => this.httpService.done());
        } else {
            this.httpService.wait();
            this.httpService.get('/tbrelacmovparteairpjsaldoscontabeis?$select=id,saldoRelacionado,movSaldosContabeis,' +
            'movSaldosContabeis/id,movSaldosContabeis/planoContas/codCta,movSaldosContabeis/planoContas/descrCta,' +
            'movSaldosContabeis/indDcIni,movSaldosContabeis/vlSldIni,movSaldosContabeis/indDcFin,movSaldosContabeis/vlSldFin,' +
            'movParteAIrpj/id' +
                '&$filter=movParteAIrpj/id is null')
                .subscribe(tbRelacDeducoes => this.tbRelacDeducoes = tbRelacDeducoes.value,
                error => this.httpService.handleError(error, () => this.existRelacDeducoes(param)),
                () => this.httpService.done());
        }
    }
    exibirModalSelecao() {
        this.modalSelecao = true;

        this.httpService.wait();
        this.httpService.get('/movsaldoscontabeis?$select=id,cadEcd/dtIni,vlSldIni,indDcIni,cadEcd/dtFin,vlSldFin,indDcFin,' +
                             'planoContas/id,planoContas/codCta,planoContas/descrCta,cadCcus/codCcus' +
                             '&$filter=cadEcd/dtIni eq ' + this.movParteA.data.getTime() + ' and ' +
                             'planoContas/empresa/id eq ' + this.empresa.id + '&$orderby=planoContas/codCta')
                        .subscribe(balancete => this.balancete = balancete.value,
                                   error => this.httpService.handleError(error, () => this.exibirModalSelecao()),
                                   () => this.httpService.done());
    }
    exibirModalSelecaoDeducoes() {
        this.modalSelecaoDeducoes = true;

        this.httpService.wait();
        this.httpService.get('/movsaldoscontabeis?$select=id,cadEcd/dtIni,vlSldIni,indDcIni,cadEcd/dtFin,vlSldFin,indDcFin,' +
                             'planoContas/id,planoContas/codCta,planoContas/descrCta' +
                             '&$filter=cadEcd/dtIni eq ' + this.movParteADeducoes.data.getTime() + ' and ' +
                             'planoContas/empresa/id eq ' + this.empresa.id + '&$orderby=planoContas/codCta')
                        .subscribe(balancete => this.balancete = balancete.value,
                                   error => this.httpService.handleError(error, () => this.exibirModalSelecaoDeducoes()),
                                   () => this.httpService.done());
    }

    adicionarPrejuizoAcumulado() {
      this.modalPrejuizoAcumulado = true;
    }

    get valorPrejuizo(): number {
      return this.tbPrejAcumulado && this.tbPrejAcumulado.valorPrejuizo > 0 ? this.tbPrejAcumulado.valorPrejuizo : 0.00;
    }

    fixValorPrejuizo() {
      this.tbPrejAcumulado.valorPrejuizo = this.utilService.parseDecimal(this.tbPrejAcumulado.valorPrejuizo);
      if (!this.tbPrejAcumulado.valorPrejuizo || this.tbPrejAcumulado.valorPrejuizo < 0) {
        this.tbPrejAcumulado.valorPrejuizo = 0.00;
      }
    }

    salvarValorPrejuizo() {
      this.fixValorPrejuizo();

      if (this.tbPrejAcumulado.id > 0) {
        this.httpService.wait();
        this.httpService.patch('/tbprejacumulado', this.tbPrejAcumulado.id, this.tbPrejAcumulado)
                        .subscribe(() => this.success(),
                                  error => this.httpService.handleError(error, () => this.salvarValorPrejuizo()),
                                  () => this.httpService.done());

        this.modalPrejuizoAcumulado = false;
      } else {
        this.httpService.wait();
        this.httpService.post('/tbprejacumulado', this.tbPrejAcumulado)
                        .subscribe(() => this.success(),
                                  error => this.httpService.handleError(error, () => this.salvarValorPrejuizo()),
                                  () => this.httpService.done());
      }
    }

    alerta() {
        this.periodChanged();
        this.modalConta = false;
        this.modalContaDeducoes = false;
        this.modalMapeamento = false;
        this.modalSelecao = false;
        this.modalMapeamentoDeducoes = false;
        this.tbRelacIrpjList = new Array();
        this.tbRelacDeducoesList = new Array();
        this.movParteAList = new Array();
        this.balancetesSelecionados = new Array();
    }

    /*Método Utilizado para definir qual mês será realizada a busca */
    verifMes(mes: number) {
      const monthStr = ('0' + mes.toString()).substr(mes.toString().length - 1);
      this.movParteA.data = new Date(this.year + '-' + monthStr + '-01 12:00:00');
      this.movParteADeducoes.data = new Date(this.year + '-' + monthStr + '-01 12:00:00');
    }

    salvarMapeamento(movSaldos: MovSaldosContabeis) {
        this.newTbRelacIrpj = new TbRelacMovParteAIrpjSaldosContabeis();
        this.newTbRelacIrpj.movParteAIrpj = new MovParteAIrpj(this.movParteA);
        this.newTbRelacIrpj.movSaldosContabeis = new MovSaldosContabeis(movSaldos);

        this.httpService.wait();
        this.httpService.post('/tbrelacmovparteairpjsaldoscontabeis', this.newTbRelacIrpj)
            .subscribe(() => this.successMapeamento(),
            error => this.httpService.handleError(error, () => this.successMapeamento()),
            () => this.httpService.done());

    }

    adicionarMapeamento() {

        this.balancetesSelecionados.forEach(tbRelacMap => {
            const tb = new TbRelacMovParteAIrpjSaldosContabeis();
            tb.movSaldosContabeis = new MovSaldosContabeis(tbRelacMap.id);
            tb.movParteAIrpj = new MovParteAIrpj(this.movParteA.id);
            tb.saldoRelacionado = tbRelacMap.vlSldFin;
            this.tbRelacIrpjList.push(tb);
        });

        this.httpService.wait();
        this.httpService.post('/custom/ecd/salvarlistatbrelacmovparteairpjsaldoscontabeis', this.tbRelacIrpjList)
            .subscribe(() => this.atualizarSaldoMovParteA(),
            error => this.httpService.handleError(error, () => this.adicionarMapeamento()),
            () => this.httpService.done());

    }

    adicionarMapeamentoDeducoes() {

        this.balancetesSelecionados.forEach(tbRelacDedMap => {
            const tbDed = new TbRelacMovParteADeducoesSaldosContabeis();
            tbDed.movSaldosContabeis = new MovSaldosContabeis(tbRelacDedMap.id);
            tbDed.movParteADeducoes = new MovParteADeducoes(this.movParteADeducoes.id);
            tbDed.saldoRelacionado = tbRelacDedMap.vlSldFin;
            this.tbRelacDeducoesList.push(tbDed);
        });

        this.httpService.wait();
        this.httpService.post('/custom/ecd/salvarlistatbrelacmovparteadeducoessaldoscontabeis', this.tbRelacDeducoesList)
            .subscribe(() => this.onContaSelectDeducoes(this.tbN620.id, this.mes),
            error => this.httpService.handleError(error, () => this.adicionarMapeamentoDeducoes()),
            () => this.httpService.done());

    }

    successMapeamento() {
        this.httpService.wait();
        this.httpService.get('/tbrelacmovparteairpjsaldoscontabeis?$select=id,saldoRelacionado,movSaldosContabeis,movSaldosContabeis/id,' +
            'movSaldosContabeis/planoContas/codCta,movSaldosContabeis/planoContas/descrCta,movSaldosContabeis/indDcIni,' +
            'movSaldosContabeis/vlSldIni,movSaldosContabeis/indDcFin,movSaldosContabeis/vlSldFin,movParteAIrpj/id' +
            '&$filter=movParteAIrpj' + (this.movParteA ? '/id eq ' + this.movParteA.id : ' is null'))
            .subscribe(tbRelacIrpj => this.tbRelacIrpj = tbRelacIrpj.value,
            error => this.httpService.handleError(error, () => this.successMapeamento()),
            () => this.httpService.done());
        this.modalSelecao = false;
    }


    select(balanceteSelecionado: MovSaldosContabeis) {
        alert(balanceteSelecionado.planoContas.codCta);
    }

    selected(balanceteSelecionado: MovSaldosContabeis) {
        alert(balanceteSelecionado.planoContas.codCta);
    }

    atualizarMapeamentoDeducoes(tbRelacAtualizar: TbRelacMovParteADeducoesSaldosContabeis) {
        this.atuaTbRelacDeducoes = new TbRelacMovParteADeducoesSaldosContabeis(tbRelacAtualizar);
        this.atuaTbRelacDeducoes.movSaldosContabeis = new MovSaldosContabeis(tbRelacAtualizar.movSaldosContabeis);
        this.atuaTbRelacDeducoes.movParteADeducoes = new MovParteADeducoes(tbRelacAtualizar.movParteADeducoes);

        this.httpService.wait();
        this.httpService.put('/custom/ecd/atualizarrelacparteamovsaldosdeducoes', this.atuaTbRelacDeducoes.id, this.atuaTbRelacDeducoes)
                        .subscribe(() => this.success(),
                                   error => this.httpService.handleError(error, () => this.atualizarMapeamentoDeducoes(tbRelacAtualizar)),
                                   () => this.httpService.done());
    }

    onEditSaldosRelacionados(event: any, callback?: Function) {
      clearTimeout(this.editSaldosRelacionadosTimeout);
      this.editSaldosRelacionadosTimeout = setTimeout(() => {
        const data: TbRelacMovParteAIrpjSaldosContabeis = this.saldosRelacionados.find(s => s.id === event.data.id);

        if (data) {
          const value: number = this.utilService.parseDecimal(event.data.saldoRelacionado);

          if (data.saldoRelacionadoEditing === null || value !== data.saldoRelacionado || value !== data.saldoRelacionadoEditing) {
            data.saldoRelacionadoEditing = value;
            data.changed = true;
          }
        }

        if (typeof callback === 'function') {
          callback();
        }
      }, 100);
    }

    onEditCompleteSaldosRelacionados(event: any) {
      this.onEditSaldosRelacionados(event, () => { this.updateSaldoRelacionado(); });
    }

    updateSaldoRelacionado() {
      const s: TbRelacMovParteAIrpjSaldosContabeis = this.saldosRelacionados.find(s1 => s1.changed);

      const data = {
        saldoRelacionado: this.utilService.parseDecimal(s.saldoRelacionadoEditing !== null ?
                                                        s.saldoRelacionadoEditing :
                                                        s.saldoRelacionado)
      };

      if (data.saldoRelacionado > s.movSaldosContabeis.vlSldFin) {
        this.exibirErroSaldoRelacionado();
        this.onContaSelect(this.tbIrpj.id, this.mes);
      } else {
        this.httpService.wait();
        this.httpService.patch('/tbrelacmovparteairpjsaldoscontabeis', s.id, data)
                        .subscribe(() => {
                                    s.saldoRelacionado = data.saldoRelacionado;
                                    s.changed = false;

                                    if (this.saldosRelacionados.find(s2 => s2.changed)) {
                                      this.updateSaldoRelacionado();
                                    } else {
                                      this.atualizarSaldoMovParteA();
                                    }
                                  },
                                  error => this.httpService.handleError(error, () => this.updateSaldoRelacionado()),
                                  () => this.httpService.done());
      }
    }

    atualizarMapeamentoListDeducoes(tbRelacAtualizarList: Array<TbRelacMovParteADeducoesSaldosContabeis>) {
        this.httpService.wait();

        tbRelacAtualizarList.forEach(tbRelacionar => {
            tbRelacionar.saldoRelacionado = this.utilService.parseDecimal(tbRelacionar.saldoRelacionado);
            if (tbRelacionar.saldoRelacionado > tbRelacionar.movSaldosContabeis.vlSldFin) {
                this.exibirErroSaldoRelacionado();
                try {
                    this.onContaSelect(this.tbIrpj.id, this.mes);
                } catch (error) {
                    console.log(error);
                    this.httpService.done()
                }

            } else {
                this.httpService.put('/custom/ecd/atualizarrelacparteamovsaldosdeducoes',
                tbRelacionar.id, tbRelacionar.saldoRelacionado)
                    .subscribe(() => this.success(),
                    error => this.httpService.handleError(error, () => this.atualizarMapeamentoListDeducoes(tbRelacAtualizarList)),
                    () => this.httpService.done());
            }

        });
    }

    atualizarSaldoUtilizadoParteB(tbRelac: Array<MovParteBLcto>) {

    this.httpService.wait();
    this.httpService.put('/custom/ecd/atualizarrelacparteamovparteb', 1, tbRelac) // TODO: FIX
                    .subscribe(() => this.atualizarSaldoMovParteA(),
                    error => this.httpService.handleError(error, () => this.atualizarSaldoUtilizadoParteB(tbRelac)),
                    () => this.httpService.done());
    }


    selectTbRelac(movSaldos: MovSaldosContabeis) {

        if (this.balancetesSelecionados && this.selectedTbRelac(movSaldos)) {
            this.balancetesSelecionados.splice(this.balancetesSelecionados.indexOf(movSaldos), 1);
        } else {
            this.balancetesSelecionados.push(movSaldos);
        }
    }

    selectedTbRelac(movSaldos: MovSaldosContabeis): boolean {
        return this.balancetesSelecionados && this.balancetesSelecionados.find(p => p.id === movSaldos.id) !== undefined;
    }

    success() {
        this.saldosRelacionados.filter(s => s.changed).forEach(s => s.changed = false);
        this.periodChanged();
    }

    successMovA(idMovA: number, mes: number) {
        this.saldosRelacionados.filter(s => s.changed).forEach(s => s.changed = false);
        this.onContaSelect(idMovA, mes);
        this.periodChanged();
    }

    excluirMapeamento(tbRelacAtualizar: TbRelacMovParteAIrpjSaldosContabeis) {

        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletetbrelacmov', tbRelacAtualizar.id)
                .subscribe(() => this.atualizarSaldoMovParteA(),
                error => this.httpService.handleError(error, () => this.excluirMapeamento(tbRelacAtualizar)),
                () => this.httpService.done());
        }
    }

    excluirMapeamentoDeducoes(tbRelacAtualizar: TbRelacMovParteADeducoesSaldosContabeis) {

        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletetbrelacmovdeducoes', tbRelacAtualizar.id)
                .subscribe(() => this.success(),
                error => this.httpService.handleError(error, () => this.excluirMapeamentoDeducoes(tbRelacAtualizar)),
                () => this.httpService.done());
        }
    }

    successExclusao() {
        this.periodChanged();
        this.modalConta = false;
        this.modalMapeamento = false;
    }

    excluirMovParteA(movParteA: MovParteAIrpj) {


        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletemovpartea', movParteA.id)
                .subscribe(() => this.successExclusao(),
                error => this.httpService.handleError(error, () => this.excluirMovParteA(movParteA)),
                () => this.httpService.done());
        }
    }

     excluirMovParteADeducoes(movParteA: MovParteADeducoes) {


        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletemovparteadeducoes', movParteA.id)
                .subscribe(() => this.successExclusao(),
                error => this.httpService.handleError(error, () => this.excluirMovParteADeducoes(movParteA)),
                () => this.httpService.done());
        }
    }

    exibirModalMapeamento() {
        this.modalMapeamento = true;
    }



    selectedIrpj(tbIrpj: TbIrpjM300): boolean {
        return this.tbIrpjM300Selecionados && this.tbIrpjM300Selecionados.find(p => p.id === tbIrpj.id) !== undefined;
    }

    adicionarConta() {
        this.movParteAList = new Array();
        this.tbIrpjM300Selecionados.forEach(tbIrpjM300 => {

        this.httpService.wait();
        this.httpService.get('/cadecd?$select=id,indSitEsp/id,indNire/id,indSitIniPer/id,indFinEsc/id,' +
                                             'codHashSub,indGrandePorte/id,empresa/id,dtIni,dtFin,status/id' +
                                    '&$filter=empresa/id eq ' + this.empresa.id +
                                            ' and period(dtIni,dtFin) eq ' + this.dblinkedSessionService.periodo.year.value +
                                    '&$orderby=dtIni')
                .subscribe(ecd => {
                    this.cadEcd = ecd.value;
                    this.cadEcd.forEach(element => {
                    const mov = new MovParteAIrpj();
                    mov.tbIrpjM300 = new TbIrpjM300(tbIrpjM300);
                    mov.tbIndRelacao = new TbIndRelacao(4);
                    mov.tbTipoLcto = null;
                    mov.movApurReal = null;
                    mov.cadCcus = null;
                    mov.planoContas = null;
                    mov.valor = 0.00;
                    mov.data = new Date(element.dtIni);
                    mov.cadEcd = element;
                    this.movParteAList.push(mov);
                });
                this.httpService.wait();
                    this.httpService.post('/custom/ecd/salvarlistamovparteirpj', this.movParteAList)
                    .subscribe(() => this.alerta(),
                    error => this.httpService.handleError(error, () => this.adicionarConta()),
                    () => this.httpService.done());
                },
                error => this.httpService.handleError(error, () => this.adicionarConta()),
                () => this.httpService.done());

       });
    }

    vincularContaAdicao() {
        this.tbIrpjM300ListAll = new Array();

        this.httpService.wait();
        this.httpService.get('/custom/ecd/tb-irpj-m300-adi', [this.year, this.empresa.id])
                      .subscribe(tbIrpjM300List => {
                          this.planoIrpjLoad(tbIrpjM300List)
                            if(this.tbIrpjM300ListAll !== null || this.tbIrpjM300ListAll !== undefined){
                                this.contadorLinha = this.tbIrpjM300ListAll.length;
                            }
                                else{
                                    this.contadorLinha = 0;
                            }
                        },
                                 error => this.httpService.handleError(error, () => this.vincularContaAdicao()),
                                 () => this.httpService.done());


        this.modalMapeamento = true;
        this.tipoConta = 1;


    }

    planoIrpjLoad(value: Array<TbIrpjM300>) {
        this.tbIrpjM300ListAll = value;
        this.contadorLinha = value.length;
    }

    excluirContaAdicao() {
        this.modalMapeamentoExcluir = true;
    }

    vincularContaExclusao() {
        this.tbIrpjM300ListAll = new Array();

        this.httpService.wait();
        this.httpService.get('/custom/ecd/tb-irpj-m300-exc', [this.year, this.empresa.id])
                        .subscribe(tbIrpjM300List => this.planoIrpjLoad(tbIrpjM300List),
                                   error => this.httpService.handleError(error, () => this.vincularContaExclusao()),
                                   () => this.httpService.done());

        this.modalMapeamento = true;
        this.tipoConta = 2;
    }

    vincularContaDeducao() {
        this.tbIrpjN620ListAll = new Array();

        this.httpService.wait();
        this.httpService.get('/tbirpjn620?$select=id,cod,descricao&$filter=tipo eq \'E\'&$orderby=id')
            .subscribe(tbIrpjN620ListAll => {
                this.tbIrpjN620ListAll = tbIrpjN620ListAll.value;
                if(this.tbIrpjN620ListAll !== null || this.tbIrpjN620ListAll !== undefined){
                    this.contadorLinhaN620 = this.tbIrpjN620ListAll.length;
                }
                    else{
                        this.contadorLinhaN620 = 0;
                }
            },
            error => this.httpService.handleError(error, () => this.vincularContaDeducao()),
            () => this.httpService.done());


        this.modalMapeamentoDeducoes = true;
    }




    exibirErroSaldoRelacionado() {

        this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'Erro',
            detail: 'Saldo relacionado deverá ser menor do que o saldo final da conta'
        });
    }


    selectDeducao(tbDed: TbIrpjN620) {

        if (this.tbIrpjN620Selecionados && this.selectedDeducoes(tbDed)) {
            this.tbIrpjN620Selecionados.splice(this.tbIrpjN620Selecionados.indexOf(tbDed), 1);
        } else {
            this.tbIrpjN620Selecionados.push(tbDed);
        }
    }

    selectedDeducoes(tbDeducoes: TbIrpjN620): boolean {
        return this.tbIrpjN620Selecionados && this.tbIrpjN620Selecionados.find(p => p.id === tbDeducoes.id) !== undefined;
    }

    selectIrpj(tbIrpj: TbIrpjM300) {


        if (this.tbIrpjM300Selecionados && this.selectedIrpj(tbIrpj)) {
            this.tbIrpjM300Selecionados.splice(this.tbIrpjM300Selecionados.indexOf(tbIrpj), 1);
        } else {
            this.tbIrpjM300Selecionados.push(tbIrpj);
        }

    }

    adicionarContaDeducao() {
        this.movParteADeducoesList = new Array();
        this.tbIrpjN620Selecionados.forEach(tbIrpjN620 => {

        this.httpService.wait();
        this.httpService.get('/cadecd?$select=id,indSitEsp/id,indNire/id,indSitIniPer/id,indFinEsc/id,' +
                                              'codHashSub,indGrandePorte/id,empresa/id,dtIni,dtFin,status/id' +
                                      '&$filter=empresa/id eq ' + this.empresa.id +
                                              ' and period(dtIni,dtFin) eq ' + this.dblinkedSessionService.periodo.year.value +
                                      '&$orderby=dtIni')
                .subscribe(ecd => {
                    this.cadEcd = ecd.value;
                    this.cadEcd.forEach(element => {
                        const mov = new MovParteADeducoes();
                        mov.tbIrpjN620 = new TbIrpjN620(tbIrpjN620);
                        mov.tbIndRelacao = new TbIndRelacao(4);
                        mov.planoContas = null;
                        mov.valor = 0.00;
                        mov.data = new Date(element.dtIni);
                        mov.cadEcd = element;
                        this.movParteADeducoesList.push(mov);
                    });
                this.httpService.wait();
                    this.httpService.post('/custom/ecd/salvarlistamovpartededucoes', this.movParteADeducoesList)
                                    .subscribe(() => this.alerta(),
                                               error => this.httpService.handleError(error, () => this.adicionarConta()),
                                               () => this.httpService.done());
                },
                error => this.httpService.handleError(error, () => this.adicionarConta()),
                () => this.httpService.done());
       });
    }

    atualizarMovParteAMes(movParteAtualizar: MovParteAIrpj) {
        this.movParteA.valor = this.utilService.parseDecimal(movParteAtualizar.valor);

        this.httpService.wait();
            this.httpService.put('/custom/ecd/atualizar-mov-parte-a', this.movParteA.id, this.movParteA)
                            .subscribe(() => this.atualizarSaldoMovParteA(),
                                       error => this.httpService.handleError(error, () => this.atualizarMovParteAMes(movParteAtualizar)),
                                       () => this.httpService.done());
    }

    atualizarMovParteADeducoesMes(movParteAtualizar: MovParteADeducoes) {
        this.movParteADeducoes.valor = this.utilService.parseDecimal(movParteAtualizar.valor);
        this.httpService.wait();
            this.httpService.put('/custom/ecd/atualizar-mov-parte-a-deducoes', this.movParteADeducoes.id, this.movParteADeducoes)
                            .subscribe(() => this.atualizarSaldoMovParteADeducoes(),
                                       error => this.httpService.handleError(error,
                                                                             () => this.atualizarMovParteADeducoesMes(movParteAtualizar)),
                                       () => this.httpService.done());

    }

    atualizarMovParteA(movParteAtualizar: Array<MovParteAIrpj>) {
      movParteAtualizar.forEach(element => {
        element.valor = this.utilService.parseDecimal(element.valor);
      });

      this.httpService.wait();
      this.httpService.put('/custom/ecd/atualizarmovpartea', 1, movParteAtualizar) // TODO: FIX
                      .subscribe(() => this.atualizarSaldoMovParteA(),
                                 error => this.httpService.handleError(error, () => this.atualizarMovParteA(movParteAtualizar)),
                                 () => this.httpService.done());
    }

    atualizarSaldoMovParteA() {
      this.httpService.wait();
      this.httpService.put('/custom/ecd/atualizarvalorpartea', this.movParteA.id, this.movParteA.id)
                      .subscribe(() => this.successMovA(this.movParteA.tbIrpjM300.id, this.mes),
                                 error => this.httpService.handleError(error, () => this.atualizarSaldoMovParteA()),
                                 () => this.httpService.done());
    }

    atualizarSaldoMovParteADeducoes() {
      this.httpService.wait();
      this.httpService.put('/custom/ecd/atualizarvalorparteadeducoes', this.movParteADeducoes.id, this.movParteADeducoes.id)
                      .subscribe(() => this.success(),
                                 error => this.httpService.handleError(error, () => this.atualizarSaldoMovParteADeducoes()),
                                 () => this.httpService.done());
    }

    atualizarMovParteADeducoes(movParteAtualizar: Array<MovParteADeducoes >) {
      movParteAtualizar.forEach(element => {
            element.valor =  this.utilService.parseDecimal(element.valor);
            // element.empresa = new Empresa(this.empresa.id);
      });

      this.httpService.wait();
      this.httpService.put('/custom/ecd/atualizarmovparteadeducoes', 1, movParteAtualizar) // TODO: FIX
                      .subscribe(() => this.success(),
                                 error => this.httpService.handleError(error, () => this.atualizarMovParteADeducoes(movParteAtualizar)),
                                 () => this.httpService.done());
    }

    adicionarParteB() {
        this.addParteB = true;
        this.movParteBLcto = new MovParteBLcto();
    }

    selectMovParteB(movParteB: MovParteB) {

        if (this.movParteBSelecionados && this.selectedMovParteB(movParteB)) {
            this.movParteBSelecionados.splice(this.movParteBSelecionados.indexOf(movParteB), 1);
        } else {
            this.movParteBSelecionados.push(movParteB);
        }
    }

    selectedMovParteB(movParteB: MovParteB): boolean {
        return this.movParteBSelecionados && this.movParteBSelecionados.find(p => p.id === movParteB.id) !== undefined;
    }

    adicionarMapeamentoParteB() {
        this.movParteBLcto.data = this.utilService.lastDayOfMonth([this.year,
                                                 this.mes - 1]).toDate();

        this.movParteBLcto.movParteAIrpj = this.movParteA;

        this.movParteBLcto.valor = this.utilService.parseDecimal(this.movParteBLcto.valor);

        this.httpService.wait();
        this.httpService.post('/movparteblcto', this.movParteBLcto)
        .subscribe(() => this.atualizarSaldoMovParteA(),
        error => this.httpService.handleError(error, () => this.adicionarMapeamentoParteB()),
        () => this.httpService.done());
    }

    atualizarParteAValorParteB() {
        this.httpService.wait();
        this.httpService.put('/custom/ecd/atualizarparteaparteb', this.movParteA.id, this.movParteBLcto)
        .subscribe(() => this.success(),
        error => this.httpService.handleError(error, () => this.atualizarParteAValorParteB()),
        () => this.httpService.done());
    }

    excluirRelacParteB(movLcto: MovParteBLcto) {
        if (confirm('Deseja excluir o registro atual?')) {
          this.httpService.wait();
          this.httpService.delete('/movparteblcto', movLcto.id)
                          .subscribe(() => this.atualizarSaldoMovParteA(),
                                      error => this.httpService.handleError(error, () => this.excluirRelacParteB(movLcto)),
                                      () => this.httpService.done());
        }
    }

    carregarDemonstrativoParteB() {
      if (this.movParteBLcto.movParteB && this.movParteBLcto.movParteB.id) {
        this.httpService.wait();
        this.httpService.get('/custom/ecd/movpartebdemonstrativo-mensal', [this.movParteBLcto.movParteB.id, this.mes, this.year])
                        .subscribe(movParteBDemonstrativo => this.movParteBDemonstrativo = movParteBDemonstrativo[0],
                                   error => this.httpService.handleError(error, () => this.carregarDemonstrativoParteB()),
                                   () => this.httpService.done());
      }
    }

    get buttonDisabled(): boolean {
      return !this.movParteA.hist && this.utilService.parseDecimal(this.movParteA.valor) > 0;
    }

    get buttonDisabledDeducoes(): boolean {
      return !this.movParteADeducoes.hist && this.utilService.parseDecimal(this.movParteADeducoes.valor) > 0;
    }

    get title(): string {
      return this.titleService.title;
    }

    irpjDisabled(event: any) {
       return event.used;
    }

    get year(): number {
      return this.dblinkedSessionService.periodo.year.value;
    }

    get empresa(): Empresa {
      return this.dblinkedSessionService.empresa;
    }

    get hasApurado(): boolean {
      return !this.ecdEcfService.isLoaded || this.ecdEcfService.hasApurado;
    }

    get allApurado(): boolean {
      return !this.ecdEcfService.isLoaded || this.ecdEcfService.allApurado;
    }

    onFilter(event: any) {
        this.contadorLinha = event.filteredValue.length;
      }

    onFilterN620(event: any) {
    this.contadorLinhaN620 = event.filteredValue.length;
    }

}
