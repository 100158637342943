import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { Util } from 'app/shared/common/util'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpService } from 'app/shared/services/http.service'
import { SessionService } from 'app/shared/services/session.service'
import { MenuItem, MessageService, SelectItem } from 'primeng/api'
import { AccountState, LoginAccount, Representative, SettingsLevy, Terms } from '../../interfaces/matera'
import { resetRepresentativeMatera, resetSettingsLevy, resetAccountState, resetLoginAccount, resetTerms } from '../../utils/reset-matera-form'
@Component({
  selector: 'app-cfg-matera',
  templateUrl: './cfg-matera.component.html',
  styleUrls: ['./cfg-matera.component.scss']
})
export class CfgMateraComponent implements OnInit, OnChanges {

  @Input() isRender: boolean
  @Input() isSave: boolean
  @Input() isConfigExists: boolean = false
  @Output() isValid = new EventEmitter()
  @Output() isFinishedSave = new EventEmitter()

  stepMenu: MenuItem[] =  [ { label: 'Representante legal' }, { label: 'Cobrança' }, { label: 'Resumo' } ]
  optTypeLevyFees: Array<any> = new Array()
  optTypeLevyFine: Array<any> = new Array()
  optTypeLevyDiscount: Array<any> = new Array()
  optTypeLevyReduction: Array<any> = new Array()

  optCompanyAccounts: SelectItem[] = new Array()
  optOperations: SelectItem[] = new Array()

  activeTab: number = 0
  actualYear: number = 0
  participantTypeId: number = 0

  strActiveTab: string = 'Representante legal'

  _isConfigExists: boolean = false
  isHaveAccount: boolean = false
  optTypeLevyLoading: boolean = true
  optIconTypeLevyLoading: boolean = false
  isPercentualTypeLevyFees: boolean = false
  isPercentualTypeLevyFine: boolean = false
  isPercentualTypeLevyDiscount: boolean = false
  isPercentualTypeLevyReduction: boolean = false
  isLoadingAccountMatera: boolean = false
  isAcceptedTerms: boolean = false
  isSupportContact: boolean = false
  isLocationDisabled: boolean = false
  loadingOptCompanyAccounts: boolean = false
  loadingIconOptCompanyAccounts: boolean = false
  loadingOptProvider: boolean = true
  loadingIconOptProvider: boolean = false
  loadingOptOperation: boolean = true
  loadingIconOptOperation: boolean = false
  modalParticipant: boolean = false
  accountCreated: boolean = false

  baseDate: Date = null

  providerCode: string
  providerFantasyName: string

  representative: Representative = resetRepresentativeMatera()
  settingsLevy: SettingsLevy = resetSettingsLevy()
  accountState: AccountState = resetAccountState()
  loginAccount: LoginAccount = resetLoginAccount()
  terms: Terms = resetTerms()

  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService,
    private messageService: MessageService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private configService: ConfigService,
  ) { }

  public ngOnInit(): void {
    this.actualYear = new Date().getFullYear()
    this.isValid.emit({ validatedSteps: false })
    this.getOptTypeLevy()
    this.getOptCompanyAccounts()
    this.getOptOperations()
    this.getIPAddress()
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.isSave && changes.isSave.currentValue) this.saveConfigMatera()
    if(changes.isConfigExists && changes.isConfigExists.currentValue) this._isConfigExists = true
    if(changes.isRender && changes.isRender.currentValue) if(this._isConfigExists) this.initialize()
  }

  private initialize(): void {
    this.isLoadingAccountMatera = true
    this.isSupportContact = false
    this.accountCreated = true

    this.isValid.emit({ validatedSteps: true })
    this.stepMenu = [ { label: 'Cobrança' }, { label: 'Conta' } ]
    this.activeTab = 1
    this.strActiveTab = 'Conta'

    this.getAccountMatera()
  }

  private async getAccountMatera(): Promise<void> {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }

    try {
      await this.httpClient.get(this.configService.octaremoteUrl + `/matera/conta?empresaId=${this.dblinkedSessionService.empresa.id}`, httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          if(res['data'].status === 'REGULAR') this.accountState.status = 'ATIVA'
          else if(res['data'].status === 'REJECTED')this.accountState.status = 'REJEITADA'
          else this.accountState.status = 'PENDENTE'

          this.accountState.accountId = res['data'].accountId
          this.accountState.accountNumber = res['data'].accountNumber
          this.accountState.branch = res['data'].branch
          this.accountState.create_timestamp = new Date(res['data'].create_timestamp)
          this.accountState.create_timestamp.setHours(this.accountState.create_timestamp.getHours() + 4)
        } else this.accountState.status = 'PENDENTE'

        if((this.accountState.status === 'PENDENTE') && (new Date() > (this.accountState.create_timestamp))) this.isSupportContact = true
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao recuperar sua conta da Matera!'))

      if(this.accountState.status === 'ATIVA') await Promise.all([ this.getKeyPix(), this.getLevyPix() ])
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isLoadingAccountMatera = false
    }
  }

  private async getKeyPix(): Promise<void> {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }

    try {
      await this.httpClient.get(this.configService.octaremoteUrl + `/matera/alias?empresaId=${this.dblinkedSessionService.empresa.id}&userId=${this.sessionService.loggedUser.id}`, httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          this.accountState.keyType = res['data'].type
          this.accountState.key = res['data'].name
          this.accountState.keyStatus = res['data'].status
        }
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao recuperar sua chave PIX!'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getLevyPix(): Promise<void> {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }

    try {
      await this.httpClient.get(this.configService.octaremoteUrl + `/cadastro/configuracao-pix-cliente?empresaId=${this.dblinkedSessionService.empresa.id}`, httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          this.settingsLevy = {...res['data']}
          this.providerCode = res['data'][0].codigo_fornecedor
          this.providerFantasyName = res['data'][0].fantasia_fornecedor
        }
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao recuperar suas cobranças do PIX!'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private saveConfigMatera(): void {
    if(!this.accountCreated) this.createAccountMatera()
    else this.saveLevyPix()
  }

  private async createAccountMatera(): Promise<void> {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    const empresa_id: number = this.dblinkedSessionService.empresa.id
    const user_id: number = this.sessionService.loggedUser.id
    let payload: Object = {}, method: string = null

    if(this.isHaveAccount) payload = {empresa_id: empresa_id, user_id: user_id, account_id: this.loginAccount.accountId}
    else payload = { empresa_id: empresa_id, user_id: user_id, representante: {...this.representative} }

    method = this.isHaveAccount ? 'put' : 'post'

    try {
      await this.httpClient[method](this.configService.octaremoteUrl + '/matera/conta', payload, httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) Promise.all([
          this.isLoadingAccountMatera = true,
          this.saveLevyPix(),
          this.saveAcceptedTerms(),
          this.saveFamilyRelationship()
        ]).finally(() => this.initialize())
        else this.showResponse('error', res['message']), this.isLoadingAccountMatera = false
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao conectar sua conta Matera!'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isFinishedSave.emit(true)
    }
  }

  private async saveLevyPix(): Promise<void> {
    const httpOptions: Object = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    const payload: Object = { empresa_id: this.dblinkedSessionService.empresa.id, user_id: this.sessionService.loggedUser.id, ...this.settingsLevy }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/configuracao-pix-cliente', payload, httpOptions).toPromise().catch(err =>
        this.showResponse('error', err.status + ' - Houve um erro ao salvar os dados da cobrança do PIX!')
      )
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isFinishedSave.emit(true)
      this.getAccountMatera()
    }
  }

  private async saveAcceptedTerms(): Promise<void> {
    const httpOptions: Object = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    const payload: Object = { empresa_id: this.dblinkedSessionService.empresa.id, user_id: this.sessionService.loggedUser.id, ...this.terms }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/matera/termos-de-uso', payload, httpOptions).toPromise().catch(err =>
        this.showResponse('error', err.status + ' - Houve um erro ao salvar os termos de uso!')
      )
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async saveFamilyRelationship(): Promise<void> {
    const httpOptions: Object = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    const payload: Object = { empresa_id: this.dblinkedSessionService.empresa.id, familia_id: 2 }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/empresa-familia', payload, httpOptions).toPromise().catch(err =>
        this.showResponse('error', err.status + ' - Houve um erro ao salvar a família de API Matera!')
      )
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getIPAddress(): Promise<void> {
    try {
      await this.httpClient.get('https://api.ipify.org/?format=json').toPromise().then(res => {
        this.terms.ip = res['ip']
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro recuperar o IP!'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getOptCompanyAccounts(): Promise<void> {
    this.loadingOptCompanyAccounts = true
    this.loadingIconOptCompanyAccounts = true

    try {
      await this.httpService.get('/empresaconta?$select=id,nome,tipoConta/id,agencia,dvAgencia,conta,dvConta,tipoConta/descricao&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo=true&$orderby=nome').toPromise().then(res => {
        this.optCompanyAccounts = [{ label: 'Nenhuma', value: null }]
        res.value.forEach(item => {
          if(item.tipoConta.id !== 10) {
            let label: string = item.id + " - " + item.nome
            if (item.agencia) label += " - Ag: " + item.agencia
            if (item.dvAgencia) label += "-" + item.dvAgencia
            if (item.conta) label += " - CC: " + item.conta
            if (item.dvConta) label += "-" + item.dvConta
            label += " - " + item.tipoConta.descricao
            this.optCompanyAccounts.push({ label: label, value: item.id })
          }
        })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as contas da empresa! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptCompanyAccounts = false
      this.loadingIconOptCompanyAccounts = false
    }
  }

  private async getOptTypeLevy(): Promise<void> {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }

    this.optTypeLevyLoading = true
    this.optIconTypeLevyLoading = true

    try {
      await this.httpClient.get(this.configService.octaremoteUrl + '/util/tipos-valores-pix', httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          this.optTypeLevyFees = [{ label: 'Nenhum', value: null }]
          this.optTypeLevyFine = [{ label: 'Nenhum', value: null }]
          this.optTypeLevyDiscount = [{ label: 'Nenhum', value: null }]
          this.optTypeLevyReduction = [{ label: 'Nenhum', value: null }]

          res['data'].juros?.forEach(item => this.optTypeLevyFees.push({ label: item.descricao, value: item.id, isPercentual: item.percentual }))
          res['data'].multa?.forEach(item => this.optTypeLevyFine.push({ label: item.descricao, value: item.id, isPercentual: item.percentual }))
          res['data'].desconto?.forEach(item => this.optTypeLevyDiscount.push({ label: item.descricao, value: item.id, isPercentual: item.percentual }))
          res['data'].multa?.forEach(item => this.optTypeLevyReduction.push({ label: item.descricao, value: item.id, isPercentual: item.percentual }))
        } else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao buscar os tipos de cobranças'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.optTypeLevyLoading = false
      this.optIconTypeLevyLoading = false
    }
  }

  private async getOptOperations(): Promise<void> {
    this.loadingOptOperation = true
    this.loadingIconOptOperation = true

    try {
      await this.httpService.get(`/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 2 and tipoRegraContabil/id eq 3 and empresa/id eq ${this.dblinkedSessionService.empresa.id} and ativo eq true&$orderby=descricao`).toPromise().then(res => {
        this.optOperations = new Array()
        res.value.forEach(item => this.optOperations.push({ label: item.descricao, value: item.id }))
      }).catch(err => this.showResponse('error', err.status + `- Houve um erro ao carregar os operações contábeis! Contate o suporte`))
    } catch(err) {
      if(err) this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptOperation = false
      this.loadingIconOptOperation = false
    }
  }

  public getCompleteAddress(): void {
    if(this.representative.endereco.cep !== null && this.representative.endereco.cep.length === 8) this.httpClient.get(`https://viacep.com.br/ws/${this.representative.endereco.cep}/json/`).toPromise().then(res => {
      if(!(res['erro'] == 'true')) {
        this.representative.endereco.logradouro = res['logradouro'] ? res['logradouro'] : null
        this.representative.endereco.complemento = res['complemento'] ? res['complemento'] : null
        this.representative.endereco.numero = res['numero'] ? res['numero'] : null
        this.representative.endereco.bairro = res['bairro'] ? res['bairro'] : null
        this.representative.endereco.cidade = res['localidade'] ? res['localidade'] : null
        this.representative.endereco.uf = res['uf'] ? res['uf'] : null
      }
    })
  }

  public changeFormRegister(): void {
    this.isHaveAccount = !this.isHaveAccount
  }

  public formatDateStr(): void {
    let date: Date = new Date(this.baseDate), dateStr: string = `${date.getFullYear()}-${(date.getMonth() > 9) ? date.getMonth() : '0'+date.getMonth()}-${(date.getDate() > 9) ? date.getDate() : '0'+date.getDate()}`

    this.representative.nascimento = dateStr
  }

  public onlyNumbersFormat(field: string, isAddress: boolean = false): void {
    const formatExpression = /([\u0300-\u036f]|[^0-9])/g

    if(isAddress) this.representative.endereco[field] = this.representative.endereco[field] ? this.representative.endereco[field].normalize('NFD').replace(formatExpression, '') : null
    else this.representative[field] = this.representative[field] ? this.representative[field].normalize('NFD').replace(formatExpression, '') : null
  }

  public ufFormat(): void {
    this.representative.endereco.uf = this.representative.endereco.uf.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z])/g, '').toUpperCase()
  }

  public changeTypeLevy(fieldDropdown: string, fieldInput: string): void {
    if(this.settingsLevy[fieldDropdown] === null) this.settingsLevy[fieldInput] = null

    switch(fieldDropdown) {
      case 'tipo_juros_id':
        this.isPercentualTypeLevyFees = this.optTypeLevyFees.find(line => line.value === this.settingsLevy.tipo_juros_id)?.isPercentual
      break
      case 'tipo_multa_id':
        this.isPercentualTypeLevyFine = this.optTypeLevyFine.find(line => line.value === this.settingsLevy.tipo_multa_id)?.isPercentual
      break
      case 'tipo_desconto_id':
        this.isPercentualTypeLevyDiscount = this.optTypeLevyDiscount.find(line => line.value === this.settingsLevy.tipo_desconto_id)?.isPercentual
      break
      case 'tipo_abatimento_id':
        this.isPercentualTypeLevyReduction = this.optTypeLevyReduction.find(line => line.value === this.settingsLevy.tipo_abatimento_id)?.isPercentual
      break
    }
  }

  public nextStep(): void {
    this.validationForm()
    this.activeTab++
    this.changeActiveIndex(this.activeTab)
  }

  public backStep(): void {
    this.activeTab--
    this.changeActiveIndex(this.activeTab)
  }

  public changeActiveIndex(activeTab: number): void {
    this.strActiveTab = this.stepMenu[activeTab].label
    this.isValid.emit({ validatedSteps: false })
    if(this.strActiveTab === 'Conta') this.isValid.emit({ validatedSteps: true })
  }

  public emitValidatedSteps(): void {
    const accepted = (acc) => {
      this.terms.precisao = acc.coords.accuracy
      this.terms.latitude = acc.coords.latitude
      this.terms.longitude = acc.coords.longitude
      console.log(acc.timestamp)
      this.terms.timestamp = this.formatTermsTimestamp(acc.timestamp)

      if(this.strActiveTab === 'Resumo' && this.isAcceptedTerms) this.isValid.emit({ validatedSteps: true })

      navigator.geolocation.clearWatch(navigatorId)
    }

    const denied = (den) => {
      this.isLocationDisabled = true
      this.isAcceptedTerms = false
      this.isValid.emit({ validatedSteps: false })
    }

    const navigatorId = navigator.geolocation.watchPosition(accepted, denied)
  }

  private formatTermsTimestamp(timestamp: number): string {
    let response: string = null, date: Date = new Date(timestamp), year: number, month: number, day: number

    year = date.getFullYear(),  month = date.getMonth() + 1, day = date.getDate()
    response = `${year}-${(month > 9) ? month : ('0' + month)}-${(day > 9) ? day : ('0' + day)} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

    return response
  }

  public loadParticipant(participantTypeId: number) {
    this.participantTypeId = participantTypeId
    this.modalParticipant = true
  }

  public saveProvider(data) {
    this.providerCode = data.selectedParticipant.cod_part
    this.providerFantasyName = data.selectedParticipant.fantasia
    this.settingsLevy.fornecedor_padrao_id = data.selectedParticipant.part_emp_id
  }

  private validationForm(): void {
    let message: string = null

    if(this.strActiveTab === 'Representante legal') {
      if(this.isHaveAccount) {
        if(this.loginAccount.accountId === null) {
          message = 'ID da conta é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }
      } else {
        if(this.representative.nome === null || this.representative.nome.length === 0) {
          message = 'Nome é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.email === null || this.representative.email.length === 0) {
          message = 'E-mail é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(!Util.validacaoEmail(this.representative.email)) {
          message = 'E-mail inserido é inválido!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.cpf === null || this.representative.cpf.length === 0) {
          message = 'CPF é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.cpf.length !== 11) {
          message = 'CPF deve conter 11 digitos!'
          this.showResponse('info', message)
          throw message
        }

        if(this.baseDate === null) {
          message = 'Nascimento é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(this.baseDate !== null && (new Date(this.baseDate) > new Date())) {
          message = 'Nascimento não pode ser superior a data atual!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.telefone === null || this.representative.telefone.length === 0) {
          message = 'Telefone é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.telefone.length !== 11) {
          message = 'Telefone deve conter 11 digitos!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.nome_mae === null || this.representative.nome_mae.length === 0) {
          message = 'Nome da mãe é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.endereco.cep === null || this.representative.endereco.cep.length === 0) {
          message = 'CEP é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.endereco.cep.length !== 8) {
          message = 'CEP deve conter 8 digitos!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.endereco.logradouro === null || this.representative.endereco.logradouro.length === 0) {
          message = 'Logradouro é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.endereco.numero === null || this.representative.endereco.numero.length === 0) {
          message = 'Número é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.endereco.bairro === null || this.representative.endereco.bairro.length === 0) {
          message = 'Bairro é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.endereco.cidade === null || this.representative.endereco.cidade.length === 0) {
          message = 'Cidade é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.endereco.uf === null) {
          message = 'UF é um campo obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if(this.representative.endereco.uf.length !== 2) {
          message = 'UF deve conter 2 digitos!'
          this.showResponse('info', message)
          throw message
        }
      }
    } else if(this.strActiveTab === 'Cobrança') {
      if(this.settingsLevy.email === null || this.settingsLevy.email.length === 0) {
        message = 'E-mail destinatário é um campo obrigatório!'
        this.showResponse('info', message)
        throw message
      }

      if(!Util.validacaoEmail(this.settingsLevy.email)) {
        message = 'E-mail destinatário inserido é inválido!'
        this.showResponse('info', message)
        throw message
      }

      const fieldName = {valor_juros: 'Valor de juros', valor_multa: 'Valor de multa', valor_desconto: 'Valor de desconto', valor_abatimento: 'Valor de abatimento'}

      Object.keys(this.settingsLevy).forEach((item, itemIdx) => {
        if((itemIdx % 2) === 0) Object.keys(this.settingsLevy).forEach((elem, elemIdx) => {
          if(!['empresa_conta_id', 'fornecedor_padrao_id', 'operacao_regra_contabil_id', 'email'].includes(elem)) {
            if((itemIdx+1) === elemIdx) {
              if(this.settingsLevy[elem] !== null) {
                if(this.settingsLevy[item] === null) {
                  message = `${fieldName[item]} se tornou obrigatório!`
                  this.showResponse('info', message)
                  throw message
                }

                if(item === 'valor_juros' && this.isPercentualTypeLevyFees && this.settingsLevy.valor_juros > 100) {
                  message = `O percentual do valor de juros não deve ultrapassar 100%!`
                  this.showResponse('info', message)
                  throw message
                }

                if(item === 'valor_multa' && this.isPercentualTypeLevyFine && this.settingsLevy.valor_multa > 100) {
                  message = `O percentual do valor de multa não deve ultrapassar 100%!`
                  this.showResponse('info', message)
                  throw message
                }

                if(item === 'valor_desconto' && this.isPercentualTypeLevyDiscount && this.settingsLevy.valor_desconto > 100) {
                  message = `O percentual do valor de desconto não deve ultrapassar 100%!`
                  this.showResponse('info', message)
                  throw message
                }

                if(item === 'valor_abatimento' && this.isPercentualTypeLevyReduction && this.settingsLevy.valor_abatimento > 100) {
                  message = `O percentual do valor de abatimento não deve ultrapassar 100%!`
                  this.showResponse('info', message)
                  throw message
                }
              }
            }
          }
        })
      })
    }
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso: ', detail: message })
      break
    }
  }
}