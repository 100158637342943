export class OperadoresLogicos {
  id: number;
  name: string;
  alias: string;

  constructor(operadoresLogicos?: OperadoresLogicos | number) {
    if (operadoresLogicos !== undefined && operadoresLogicos !== null) {
      if (typeof operadoresLogicos === 'number') {
        this.id = operadoresLogicos;
      } else {
        this.id = operadoresLogicos.id;
        this.name = operadoresLogicos.name;
        this.alias = operadoresLogicos.alias;
      }
    }
  }
}
