import { TipoChavePix } from "app/util/model/tipo-chave-pix.model"
import { Participante } from "./participante.model"

export class ParticipanteChavePix {
  id: number
  participante: Participante
  tipoChavePix: TipoChavePix | number
  chavePix: string
  descricaoChavePix: string

  constructor(participanteChavePix?: ParticipanteChavePix | number) {
    if (participanteChavePix !== undefined && participanteChavePix !== null) {
      if (typeof participanteChavePix === 'number') {
        this.id = participanteChavePix
      } else {
        this.id = participanteChavePix.id
        this.participante = participanteChavePix.participante
        this.tipoChavePix = participanteChavePix.tipoChavePix
        this.chavePix = participanteChavePix.chavePix
        this.descricaoChavePix = participanteChavePix.descricaoChavePix
      }
    }
  }
}