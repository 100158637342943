import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Subscription } from 'rxjs/Subscription';
import { Message } from 'primeng/api';
import { Empresa } from '../../cadastro/model/empresa.model';
import { TpConfigRelat } from '../models/tp-config-relat.model';

@Component({
  selector: 'app-relatorio-transfer',
  templateUrl: './relatorio-transfer.component.html',
  styleUrls: ['./relatorio-transfer.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class TransferRelatorios implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = null;
  msgsModal: Message[] = null;
  title: string;
  subtitle: string;

  //configurar relatório
  tpConfigRelat: TpConfigRelat;
  selectedWorksheet: number;
  worksheetSqlRelac: any;
  isNewConfig: boolean = false;

  //tabelas e dropdowns
  opcoesWorksheet: SelectItem[];
  tableFields: Array<any>;
  listTableFields: Array<any>;

  constructor(private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService) { }

    get rowsPerPage() {
      return this.configService.applicationConfig.rowsPerPage;
    }
    get rows() {
      return this.configService.applicationConfig.rows;
    }
    get pageLinks() {
      return this.configService.applicationConfig.pageLinks;
    }

    ngOnInit(): void {
      this.title = 'CONFIGURAÇÃO DE RELATÓRIOS';
      this.subtitle = 'CONFIGURE E CONTROLE OS RELATÓRIOS';
      Promise.resolve(null).then(() => this.toolbarService.hidden = true);
      this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

      this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
        () => {
          if(this.dblinkedSessionService.hasEmpresaSelected) {
            this.msgs = [];
            this.msgsModal = [];

            this.tpConfigRelat = new TpConfigRelat();
            this.selectedWorksheet = null;
            this.worksheetSqlRelac = {};

            this.getWorksheets();
          }
        });
    }

    ngOnDestroy(): void {
      this.sessionService.destroySubscribe(this.sessionSubscription);
    }

    exibirMensagem(value: any) {
      this.msgs = [];
      if (!value.error) {
        this.msgs.push({ severity: 'success', summary: 'Sucesso', detail: value.mensagem });

      } else {
        this.msgs.push({ severity: 'warn', summary: 'Erro', detail: value.mensagem });
      }
    }

    hideMessage() {
      this.msgs = [];
    }

    getWorksheets() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi
        })
      };

      this.httpService.wait();
      this.httpClient.get(this.configService.defaultUrlApiUpload + 'fiscal/tp-relat-worksheets', httpOptions)
        .subscribe(
          res => {
            this.opcoesWorksheet = new Array();

            if(!res["error"]) {
              const listWorksheets = res["mensagem"];
              this.listTableFields = res['fields'];

              for(let worksheet of listWorksheets) {
                this.opcoesWorksheet.push({'label': worksheet.description, 'value': worksheet.id});
              }
            }

            this.getRelatorios();
            this.httpService.done();
          }, err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }

    getRelatorios() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'empresaid': this.dblinkedSessionService.empresa.id.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.get(this.configService.defaultUrlApiUpload + 'fiscal/tp-config-relat', httpOptions)
        .subscribe(
          res => {
            this.tpConfigRelat = new TpConfigRelat();

            this.exibirMensagem(res);

            if(!res["error"]) {
              this.isNewConfig = res["relatorio"] == null;

              if(!this.isNewConfig) {
                let dicRelatWorksheetSql = res['dicRelatWorksheetSql'];
                let relatorio = res["relatorio"];

                this.tpConfigRelat.id = relatorio.id;
                this.tpConfigRelat.nome = relatorio.nome;
                this.tpConfigRelat.codigo = relatorio.codigo;

                for(let worksheet of this.opcoesWorksheet) {
                  this.worksheetSqlRelac[worksheet.value] = dicRelatWorksheetSql[this.tpConfigRelat.id] != null? dicRelatWorksheetSql[this.tpConfigRelat.id][worksheet.value] : "";
                }
              }
            }

            this.httpService.done();
          }, err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }

    loadTableFields() {
      this.tableFields = new Array();
      let worksheetName = "";

      for(let worksheet of this.opcoesWorksheet) {
        if(worksheet.value === this.selectedWorksheet) {
          worksheetName = worksheet.label;
          break;
        }
      }

      let nomeView = ['IMPO', 'IMPO-REV', 'IMPO-VENDA'].indexOf(worksheetName) >=0 ? 'tp_impo' :worksheetName === 'OP'? 'fiscal.tp_ops' : 'fiscal.tp_mov';

      for(let field of this.listTableFields) {
        if(field.nome_view === nomeView) {
          this.tableFields.push({ campo: field.campo, descricao: field.descricao, tipo: field.tipo });
        }
      }
    }

    onSelectViewCampo(campo: string) {
      this.worksheetSqlRelac[this.selectedWorksheet] = this.worksheetSqlRelac[this.selectedWorksheet] == null? campo : this.worksheetSqlRelac[this.selectedWorksheet] + campo;
    }

    salvarRelatorio() {
      if(this.tpConfigRelat.nome == null || this.tpConfigRelat.nome == "") {
        this.exibirMensagem({"error": true, "mensagem": "Preencha o campo do nome."});
        return;
      }

      if(this.tpConfigRelat.codigo == null || this.tpConfigRelat.codigo == "") {
        this.exibirMensagem({"error": true, "mensagem": "Preencha o campo do código."});
        return;
      }

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi
        })
      };

      const payload = {
        empresaid: this.dblinkedSessionService.empresa.id,
        userid: this.dblinkedSessionService.usuario.id,
        nome: this.tpConfigRelat.nome,
        codigo: this.tpConfigRelat.codigo,
        dicWorksheetSql: this.worksheetSqlRelac
      }

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'fiscal/tp-config-relat', payload, httpOptions)
        .subscribe(
          res => {
            this.tpConfigRelat.id = res['configid'];
            this.isNewConfig = false;
            this.exibirMensagem(res);
            this.httpService.done();
          }, err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }

    editarRelatorio() {
      if(this.tpConfigRelat.nome == null || this.tpConfigRelat.nome === "") {
        this.exibirMensagem({"error": true, "mensagem": "Preencha o campo do nome."});
        return;
      }

      if(this.tpConfigRelat.codigo == null || this.tpConfigRelat.codigo === "") {
        this.exibirMensagem({"error": true, "mensagem": "Preencha o campo do código."});
        return;
      }

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi
        })
      };

      const payload = {
        empresaid: this.dblinkedSessionService.empresa.id,
        userid: this.dblinkedSessionService.usuario.id,
        configid: this.tpConfigRelat.id,
        nome: this.tpConfigRelat.nome,
        codigo: this.tpConfigRelat.codigo,
        dicWorksheetSql: this.worksheetSqlRelac
      }

      this.httpService.wait();
      this.httpClient.put(this.configService.defaultUrlApiUpload + 'fiscal/tp-config-relat', payload, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.httpService.done();
          }, err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }

  /*Show Hide Config*/
  isCardConfig: boolean = true;
  ShowHIdeConfig() {
    this.isCardConfig = !this.isCardConfig;
  }

  currentStateConfig = 'initial';
  changeStateConfig() {
    this.currentStateConfig = this.currentStateConfig === 'initial' ? 'final' : 'initial';
  }
}
