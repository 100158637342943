import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';
import { FluxoCaixa } from '../model/fluxo_caixa';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Message, MessageService, SelectItem } from '../../../../node_modules/primeng/api';
import { GrupoFluxoCaixa } from '../model/fluxo_caixa_grupo';
import { TipoGrupoFluxo } from 'app/util/model/tipo-fluxo-caixa-model';
import { AliasGrupoFluxoCaixa } from '../model/fluxo_caixa_grupo_alias';
import { FormulaDetalhe } from '../model/fluxo_caixa_formula_detal';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { OperacaoRegraContabil } from 'app/cadastro/model/operacao-regra-contabil.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-fluxo-caixa-ctrl',
  templateUrl: './fluxo-caixa-ctrl.component.html',
  styleUrls: [
    './fluxo-caixa-ctrl.component.scss'
  ],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class FluxoCaixaCtrlComponent implements OnInit, OnDestroy {

  currentStateConfigFlux = 'initial';
  changeStateConfigFlux() {
    this.currentStateConfigFlux = this.currentStateConfigFlux === 'initial' ? 'final' : 'initial';
  }

  currentStateConfigFluxBI = 'initial';
  changeStateConfigFluxBI() {
    this.currentStateConfigFluxBI = this.currentStateConfigFluxBI === 'initial' ? 'final' : 'initial';
  }

  currentStateParametroTaxa = 'initial';
  changeStateParametroTaxa() {
    this.currentStateParametroTaxa = this.currentStateParametroTaxa === 'initial' ? 'final' : 'initial';
  }

  currentStateConfigContasPadrao = 'initial';
  changeStateConfigContasPadrao() {
    this.currentStateConfigContasPadrao = this.currentStateConfigContasPadrao === 'initial' ? 'final' : 'initial';
  }

  currentStateConfigFluxProv = 'initial';
  changeStateConfigFluxProv() {
    this.currentStateConfigFluxProv = this.currentStateConfigFluxProv === 'initial' ? 'final' : 'initial';
  }

  currentStateConfigFluxComissao = 'initial';
  changeStateConfigFluxComissao() {
    this.currentStateConfigFluxComissao = this.currentStateConfigFluxComissao === 'initial' ? 'final' : 'initial';
  }

  currentStateConfigParticipante = 'initial';
  changeStateConfigParticipante() {
    this.currentStateConfigParticipante = this.currentStateConfigParticipante === 'initial' ? 'final' : 'initial';
  }

  currentStateConfigFluxPedidoMisto = 'initial';
  changeStateConfigFluxPedidoMisto() {
    this.currentStateConfigFluxPedidoMisto = this.currentStateConfigFluxPedidoMisto === 'initial' ? 'final' : 'initial';
  }

  currentStateGrupoOper = 'initial';
  changeStateGrupoOper() {
    this.currentStateGrupoOper = this.currentStateGrupoOper === 'initial' ? 'final' : 'initial';
  }

  currentStateConfigParametroBoleto = 'initial';
  changeStateConfigParametroBoleto() {
    this.currentStateConfigParametroBoleto = this.currentStateConfigParametroBoleto === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  title: string;
  subtitle: string;
  pt_BR: any;
  msgs: Message[] = [];
  msgsModal: Message[] = [];
  relPbi: number;
  confTaxa: number;
  confTaxaPedido: number;
  contasSelecionadas: Array<number> = new Array();
  provFlxuoFin: boolean;
  pedidoFluxoFin: boolean;
  opcoesOperacao: SelectItem[];
  opcoesConfigTaxa: SelectItem[];
  operacaoId: number;
  comissaoAtivo: boolean;
  participanteObrigatorio: boolean;
  permiteAlterarMoeda: boolean;
  permiteMultiMoeda: boolean;

  instFinanceira: number;
  selectedInstFinanceira: number;
  instFinanceiras: SelectItem[] = [];
  tipoBoletoId: number;
  tipoBoleto: SelectItem[] = [];

  //vir
  toolbarMainIni = [
    { icon: 'refresh', text: '', tooltip: 'Atualizar relatório', visible: true, disabled: false, color: 'default', onClick: () => this.start() },
    { icon: 'share', text: '', tooltip: 'Relacionar Grupo e Operações', visible: false, disabled: false, color: 'default', onClick: () => this.abrirFormularioVinculos() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.removerFluxo() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openModalFluxo(false) },
    { icon: 'save', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.openModalFluxo(true) },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideFluxo()
        this.changeStateConfigFlux()
      }
    },
  ]

  toolbarGrupoOperIni = [
    { icon: 'summarize', text: 'Vinculações', tooltip: 'Vinculações', visible: true, disabled: false, color: 'default', onClick: () => this.exportarVinculacaoFluxoCaixa() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: true, disabled: true, color: 'red', onClick: () => this.removerOperacaoLista() },
    { icon: 'add', text: 'Adicionar', tooltip: 'Adicionar', visible: true, disabled: true, color: 'green', onClick: () => this.adicionarOperacaoLista() },
  ]

  toolbarFluxoBIIni = [
    { icon: 'done_all', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'default', onClick: () => this.salvarParametroBi() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideFluxoBI()
        this.changeStateConfigFluxBI()
      }
    },
  ]

  toolbarTaxaIni = [
    { icon: 'done_all', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'default', onClick: () => this.salvarParametroTaxas() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideParametroTaxa()
        this.changeStateParametroTaxa()
      }
    },
  ]

  toolbarContasIni = [
    { icon: 'done_all', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'default', onClick: () => this.salvarContasPadrao() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.showHideContasPadrao()
        this.changeStateConfigContasPadrao()
      }
    },
  ]

  toolbarFluxoProvIni = [
    { icon: 'done_all', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'default', onClick: () => this.salvarParametroProv() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideFluxoProv()
        this.changeStateConfigFluxProv()
      }
    },
  ]

  toolbarFluxoMistoIni = [
    { icon: 'done_all', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'default', onClick: () => this.salvarParametroAntecipado() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideFluxoPedidoMisto()
        this.changeStateConfigFluxPedidoMisto()
      }
    },
  ]

  toolbarComissaoIni = [
    { icon: 'done_all', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'default', onClick: () => this.salvarParametroComissao() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideFluxoComissao()
        this.changeStateConfigFluxComissao()
      }
    },
  ]

  toolbarParticipanteIni = [
    { icon: 'done_all', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'default', onClick: () => this.salvarParametroParticipante() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideParametroParticipante()
        this.changeStateConfigParticipante()
      }
    },
  ]

  toolbarParametroBoletoIni = [
    { icon: 'done_all', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'default', onClick: () => this.updateTipoBoleto() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideParametroBoleto()
        this.changeStateConfigParametroBoleto()
      }
    },
  ]

  public toolbarMain = new Toolbar()
  public toolbarGrupoOper = new Toolbar()
  public toolbarFluxoBI = new Toolbar()
  public toolbarVinculacao = new Toolbar()
  public toolbarTaxa = new Toolbar()
  public toolbarContas = new Toolbar()
  public toolbarFluxoProv = new Toolbar()
  public toolbarFluxoMisto = new Toolbar()
  public toolbarComissao = new Toolbar()
  public toolbarParticipante = new Toolbar()
  public toolbarParametroBoleto = new Toolbar()

  skeleton = new SkeletonModel({
    columns: [
      { name: 'Linha', size: '10%', textAlign: 'center' },
      { name: 'Descrição', size: '57%', textAlign: 'left' },
      { name: 'Tipo', size: '17%', textAlign: 'left' },
      { name: 'Ação', size: '15%', textAlign: 'center' },
    ],
  })

  skeletonAlias = new SkeletonModel({
    columns: [
      { name: 'Código', size: '10%', textAlign: 'left' },
      { name: 'Nome', size: '75%', textAlign: 'left' },
      { name: 'Ação', size: '15%', textAlign: 'center' },
    ],
  })

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private httpClient: HttpClient
  ) { }

  // tabela dados fluxos
  listaCtrl: Array<any> = new Array();
  // select check box
  // tabela dados fluxos
  listaOperacaoRegras: Array<any> = new Array();
  listaOperacaoRegrasSelecionadas: Array<any> = new Array();
  // select check box
  filterOrigem: Array<any> = new Array();
  filterDestino: Array<any> = new Array();
  selectAllOrigem: boolean;
  selectAllDest: boolean;
  idSelecionado: Array<any> = new Array();
  operacaoSelecionada: Array<any> = new Array();
  operacaoAdicionada: Array<any> = new Array();

  // Select favorito
  fluxoIdFav: number = null;

  // fluxo de caixa
  fluxoCaixa = new FluxoCaixa();
  grupoFluxoCaixa = new GrupoFluxoCaixa();
  aliasGrupo = new AliasGrupoFluxoCaixa();
  modalFluxoCaixa: boolean;
  modalFluxoCaixaGrupos: boolean;
  modalAliasGrupo: boolean;
  // select
  opcoesEmpresaConta: SelectItem[];
  opcoesFluxoCaixa: SelectItem[];
  tiposFluxoCaixa: SelectItem[];
  opcoesGrupoFluxoCaixa: SelectItem[];
  opcoesAliasFluxoCaixa: SelectItem[];
  opcoesTipoConta: SelectItem[];

  // vinculação
  vinculaGrupoOperacao: boolean;

  // formulas
  formulaDetalList: Array<any> = new Array();
  formulaDetalhe = new FormulaDetalhe();
  modalFormula: boolean;
  modalFormulaDetal: boolean;
  operadorCtrl: boolean; // verificando se tem operado na ultima posição;
  pedidoMisto: boolean;
  financeiroAntecipado: boolean;

  faturaAutomatico: boolean;
  diasFaturar: number;

  tiposBi: SelectItem[];

  tipoAtualizacao: number;
  tiposAtualizacao: SelectItem[];

  //criando lista bancária

  listaContasBancarias: SelectItem[] = [];

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.provFlxuoFin = false;
    this.pedidoFluxoFin = false;
    this.pedidoMisto = false;
    this.faturaAutomatico = false;
    this.diasFaturar = 0;
    this.financeiroAntecipado = false;
    this.comissaoAtivo = false;
    this.title = 'PARÂMETROS FINANCEIRO';
    this.titleService.title = '';
    this.relPbi = 0;
    this.confTaxa = 0;
    this.confTaxaPedido = 0;
    this.contasSelecionadas = new Array();
    this.operacaoId = 0;
    this.participanteObrigatorio = false;
    this.permiteAlterarMoeda = false;
    this.permiteMultiMoeda = false;

    this.tiposAtualizacao = [
      { label: 'PADRÃO - Transações são atualizadas a cada 6 horas.', value: 1 },
      { label: 'BUSCA ATUALIZADA - Habilita o botão na tela extrato OFX para buscar transações em tempo real.', value: 2 },
    ];

    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.vinculaGrupoOperacao = false
        this.start()
      }
    }
    );

    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarGrupoOper.setAll(this.toolbarGrupoOperIni)
    this.toolbarFluxoBI.setAll(this.toolbarFluxoBIIni)
    this.toolbarTaxa.setAll(this.toolbarTaxaIni)
    this.toolbarContas.setAll(this.toolbarContasIni)
    this.toolbarFluxoProv.setAll(this.toolbarFluxoProvIni)
    this.toolbarFluxoMisto.setAll(this.toolbarFluxoMistoIni)
    this.toolbarComissao.setAll(this.toolbarComissaoIni)
    this.toolbarParticipante.setAll(this.toolbarParticipanteIni)
    this.toolbarParametroBoleto.setAll(this.toolbarParametroBoletoIni)
    this.toolbarService.hidden = true
  }

  ngOnDestroy() {
    // this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
    this.toolbarService.hidden = false
  }

  async start() {
    this.subtitle = 'Criar, alterar e excluir as configurações do relatório';
    this.loadBi();
    this.loadParametro();
    Promise.resolve(null).then(() => this.toolbarSetButtons());

    this.listaCtrl = new Array();
    this.listaOperacaoRegras = new Array();
    this.listaOperacaoRegrasSelecionadas = new Array();

    this.selectAllOrigem = false;
    this.selectAllDest = false;
    this.filterOrigem = new Array();
    this.filterDestino = new Array();
    this.idSelecionado = new Array();
    this.operacaoSelecionada = new Array();
    this.operacaoAdicionada = new Array();

    this.modalFluxoCaixa = false;
    this.modalFluxoCaixaGrupos = false;
    this.modalAliasGrupo = false;
    this.vinculaGrupoOperacao = false;

    // fluxo de caixa
    this.fluxoCaixa = new FluxoCaixa();
    this.fluxoCaixa.empresa = new Empresa();
    this.fluxoCaixa.changeUser = new AuthUser();
    // grupo fluxo
    this.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.grupoFluxoCaixa.fluxoCaixa = new FluxoCaixa();
    this.grupoFluxoCaixa.tipoGrupoFluxo = new TipoGrupoFluxo();
    this.fluxoCaixa.grupoFluxoCaixa = new Array();
    this.fluxoCaixa.empresaContas = new Array();

    // formulas
    this.modalFormula = false;
    this.modalFormulaDetal = false;
    this.formulaDetalhe = new FormulaDetalhe();
    this.formulaDetalList = new Array();
    this.operadorCtrl = false;

    if (
      this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected &&
      this.dblinkedSessionService.periodo.month) {

      this.loadConfigTaxa();

      this.httpService.wait();
      this.httpService.get('/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 1 and tipoRegraContabil/id eq 3'
        + ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo eq true&$orderby=descricao asc')
        .subscribe(ref => this.loadOperacao(ref.value),
          error => this.httpService.handleError(error, () => this.start()),
          () => this.httpService.done());

      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        authUserId: this.sessionService.loggedUser.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/fluxocaixa/fluxo_caixa-ctrl-list', infos)
        .subscribe(retorno => {
          this.listaCtrl = (retorno);
          this.comboOptFluxoCaixa();
          this.comboEmpresaConta();
          this.getTipoConta();
          this.comboTiposFluxoCaixa();
          this.detectFavorito();
        },
          error => this.httpService.handleError(error, () => this.start()),
          () => this.httpService.done());

      this.buscarContasBancarias();
      this.getParametroBoleto();
      this.getTipoBoleto();
    }

    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', false)
  }

  getTipoConta() {

    this.opcoesTipoConta = [];

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-tipo-conta(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(retorno => {

        for (let line of retorno) {
          this.opcoesTipoConta.push({ label: line.descricao, value: line.id });
        }

        this.httpService.done();
      },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        });

  }

  getTipoBoleto() {
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-tipos-boleto')
      .subscribe(response => {
        this.tipoBoleto = [];

        response.tiposBoletos.forEach(tipo => {
          this.tipoBoleto.push({ value: tipo.id, label: tipo.descricao })
        });
      },
        error => this.httpService.handleError(error, () => this.getTipoBoleto()),
        () => this.httpService.done());
  }

  getParametroBoleto() {
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-parametro-boleto(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(response => {
        this.tipoBoletoId = response.parametroBoleto
      },
        error => this.httpService.handleError(error, () => this.getParametroBoleto()),
        () => this.httpService.done());
  }

  updateTipoBoleto() {
    const data = {
      empresaId: this.dblinkedSessionService.empresa.id,
      userId: this.sessionService.loggedUser.id,
      tipoBoletoId: this.tipoBoletoId
    }

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/update-parametro-boleto', data)
      .subscribe(response => {
        this.messageService.add(response);
      },
        error => this.httpService.handleError(error, () => this.updateTipoBoleto()),
        () => this.httpService.done());
  }

  buscarContasBancarias() {

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/buscar-contas-bancarias(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(retorno => {
        this.contasSelecionadas = [];

        for (let line of retorno) {
          this.contasSelecionadas.push(line["tipo_conta_id"]);
        }

      },
        error => this.httpService.handleError(error, () => this.buscarContasBancarias()),
        () => this.httpService.done());

  }

  loadOperacao(value: Array<OperacaoRegraContabil>) {
    this.opcoesOperacao = [];
    this.opcoesOperacao.push({ label: 'Selecione', value: 0 });
    value.forEach(element => {
      this.opcoesOperacao.push({ label: element.descricao, value: element.id });
    });
  }

  loadConfigTaxa() {
    this.opcoesConfigTaxa = [];
    this.opcoesConfigTaxa.push({ label: 'Selecione', value: 0 });
    this.opcoesConfigTaxa.push({ label: 'Taxa Corporação', value: 1 });
    this.opcoesConfigTaxa.push({ label: 'PTAX Último Dia', value: 2 });
    this.opcoesConfigTaxa.push({ label: 'PTAX Média Mês', value: 3 });
    this.opcoesConfigTaxa.push({ label: 'PTAX Último Dia Mês Anterior', value: 4 });
  }

  detectFavorito() {
    this.fluxoIdFav = null;
    for (let line of this.listaCtrl) {
      if (line["favorito"] === true) {
        this.fluxoIdFav = line["id"];
        break;
      }
    }
  }

  selectCheckbox(id: any, event: any) {
    if (event.selected) {
      const index = this.idSelecionado.findIndex(c => c === id);
      this.idSelecionado.splice(index, 1);
      event.selected = false;
      this.fluxoCaixa = new FluxoCaixa()
    } else {
      this.idSelecionado.push(id);
      this.fluxoCaixa.id = event.id;
      this.fluxoCaixa.nomeFluxo = event.nomeFluxo;
      this.fluxoCaixa.empresa = new Empresa();
      this.fluxoCaixa.empresa.id = event.empresaId;
      this.fluxoCaixa.changeUser = new AuthUser();
      this.fluxoCaixa.changeUser.id = event.changeUserId;
      event.selected = true;
    }

    if (this.idSelecionado.length > 0 && this.idSelecionado.length < 2) {
      this.toolbarEditButtons(event);
      this.toolbarMain.setVisible('btn2', true)
      this.toolbarMain.setVisible('btn3', true)
      this.toolbarMain.setVisible('btn4', true)
    } else {
      this.toolbarSetButtons();
      this.toolbarMain.setVisible('btn2', false)
      this.toolbarMain.setVisible('btn3', false)
      this.toolbarMain.setVisible('btn4', false)
    }

  }

  // Criando e alterando fluxo de caixa
  openModalFluxo(ctrl: boolean) {
    this.fluxoCaixa.empresaContas = new Array();


    // novo fluxo
    if (ctrl) {
      this.fluxoCaixa = new FluxoCaixa();
      this.fluxoCaixa.empresa = new Empresa();
      this.fluxoCaixa.changeUser = new AuthUser();
      this.modalFluxoCaixa = true;

      // editando fluxo
    } else {
      this.httpService.get('/contapadraofluxocaixa?$select=id,empresaConta/id,fluxoCaixa/id&$filter=fluxoCaixa/id eq ' + this.fluxoCaixa.id)
        .subscribe(ref => {
          ref.value.forEach(element => {
            this.fluxoCaixa.empresaContas.push(element.empresaConta.id);
          });
          this.modalFluxoCaixa = true
        }, error => this.httpService.handleError(error, () => this.openModalFluxo(ctrl)),
          () => this.httpService.done());
    }
  }

  comboEmpresaConta() {
    this.httpService.wait();
    this.httpService.get('/empresaconta?$select=id,nome&$filter=empresa/id eq ' +
      this.dblinkedSessionService.empresa.id + ' and ativo eq true&$orderby=nome')
      .subscribe(ref => {
        this.opcoesEmpresaConta = [];
        ref.value.forEach(element => {
          this.opcoesEmpresaConta.push({ label: element.nome, value: element.id });
        });
      }, error => this.httpService.handleError(error, () => this.comboEmpresaConta()),
        () => this.httpService.done());

  }

  // Salvando fluxo de caixa
  fluxoCaixaSalvar(ctrl: boolean) {
    if (this.fluxoCaixa.nomeFluxo === null || this.fluxoCaixa.nomeFluxo === undefined || this.fluxoCaixa.nomeFluxo === '') {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Informe o Nome do Fluxo para prosseguir!'
      });
      return;
    }

    // novo fluxo
    if (!ctrl) {
      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        authUserId: this.sessionService.loggedUser.id,
        nomeFluxo: this.fluxoCaixa.nomeFluxo,
        contas: this.fluxoCaixa.empresaContas,
        alterar: false
      };
      this.httpService.wait();
      this.httpService.post('/custom/fluxocaixa/fluxo_caixa-salvar', infos)
        .subscribe(rst => {
          this.fluxoCaixa.id = rst.id;
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: 'Fluxo salvo com sucesso!' });
          this.start();
        }, error => this.httpService.handleError(error, () => this.fluxoCaixaSalvar(ctrl)),
          () => this.httpService.done()
        );
    } else { // alterando fluxo
      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        authUserId: this.sessionService.loggedUser.id,
        nomeFluxo: this.fluxoCaixa.nomeFluxo,
        fluxoId: this.fluxoCaixa.id,
        contas: this.fluxoCaixa.empresaContas,
        alterar: true
      };
      this.httpService.wait();
      this.httpService.post('/custom/fluxocaixa/fluxo_caixa-salvar', infos)
        .subscribe(rst => {
          this.fluxoCaixa.id = rst.id;
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: 'Fluxo alterado com sucesso!' });
          this.start();
          // const index = this.listaCtrl.findIndex(i => i.id === this.fluxoCaixa.id);
          // this.listaCtrl.splice(index, 1, this.fluxoCaixa);
        }, error => this.httpService.handleError(error, () => this.fluxoCaixaSalvar(ctrl)),
          () => this.httpService.done()
        );
    }
    this.modalFluxoCaixa = false;
  }

  removerFluxo() {
    if (confirm('Deseja remover este Fluxo de Caixa?')) {
      this.fluxoCaixa.excluirFluxo = true;
      this.httpService.wait();
      this.httpService.put('/fluxocaixa', this.fluxoCaixa.id, this.fluxoCaixa)
        .subscribe(() => {
          this.start();
        }, error => this.httpService.handleError(error, () => this.removerFluxo()),
          () => this.httpService.done());
    }
  }

  onRowSelect(event: any) {

    if (!event.id) event = event.data;

    this.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.grupoFluxoCaixa.fluxoCaixa = new FluxoCaixa();
    this.grupoFluxoCaixa.fluxoCaixa.id = event.id;
    this.grupoFluxoCaixa.tipoGrupoFluxo = new TipoGrupoFluxo();
    this.fluxoCaixa.grupoFluxoCaixa = new Array();
    this.fluxoCaixa.id = event.id
    this.skeleton.startLoad();
    this.httpService.get('/grupofluxocaixa?$select=id,codigoGrupo,descricao,fluxoCaixa/id,fluxoCaixa/nomeFluxo,tipoGrupoFluxo/id,' +
      'tipoGrupoFluxo/descricao&$filter=fluxoCaixa/id eq ' + event.id + '&$orderby=codigoGrupo')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.fluxoCaixa.grupoFluxoCaixa.push(element);
        });
        this.modalFluxoCaixaGrupos = true;
      }, error => this.httpService.handleError(error, () => this.onRowSelect(event)),
        () => this.skeleton.endLoad());

  }

  changeFluxoFavorito(fluxoid) {
    //Atualiza o favorito
    const infos = {
      fluxo_id: fluxoid,
      empresa_id: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/fluxocaixa/atualizarfluxofavorito', infos)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
        },
        err => {
          console.log(err)
          this.exibirMensagem({ error: true, mensagem: "Erro no API, contate o suporte: " + err.message })
          this.httpService.done()
        }
      )
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem ? value.mensagem : value.message });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem ? value.mensagem : value.message });
    }
  }

  comboTiposFluxoCaixa() {
    this.tiposFluxoCaixa = [];
    this.tiposFluxoCaixa.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/tipogrupofluxo?$select=id,descricao&$orderby=descricao')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.tiposFluxoCaixa.push({ label: element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboTiposFluxoCaixa()),
        () => this.httpService.done());
  }

  comboOptFluxoCaixa() {
    this.opcoesFluxoCaixa = [];
    this.opcoesFluxoCaixa.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/fluxocaixa?$select=id,nomeFluxo,empresa/id&$filter=empresa/id eq ' +
      this.dblinkedSessionService.empresa.id + ' and excluirFluxo ne true&$orderby=nomeFluxo')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesFluxoCaixa.push({ label: element.nomeFluxo, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboOptFluxoCaixa()),
        () => this.httpService.done());
  }

  grupoFluxoCaixaAdd() {
    if (this.grupoFluxoCaixa.codigoGrupo === null || this.grupoFluxoCaixa.codigoGrupo === undefined ||
      this.grupoFluxoCaixa.codigoGrupo === '' || this.grupoFluxoCaixa.descricao === undefined ||
      this.grupoFluxoCaixa.descricao === '' || this.grupoFluxoCaixa.tipoGrupoFluxo.id === undefined ||
      this.grupoFluxoCaixa.tipoGrupoFluxo.id === null) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Informe o Grupo, o Codigo do Grupo e a Descrição para prosseguir!'
      });
      return;
    }
    const fluxoId = this.grupoFluxoCaixa.fluxoCaixa.id;
    const tipoId = this.grupoFluxoCaixa.tipoGrupoFluxo.id;

    // salvar
    if (this.grupoFluxoCaixa.id === undefined) {
      this.httpService.wait();
      this.httpService.post('/grupofluxocaixa', this.grupoFluxoCaixa)
        .subscribe(rst => {
          this.grupoFluxoCaixa.id = rst.id;
          // buscando nomes nos options
          const descricao = this.tiposFluxoCaixa.find(i => i.value === tipoId).label;
          this.grupoFluxoCaixa.tipoGrupoFluxo.descricao = descricao;
          // setando no array
          this.fluxoCaixa.grupoFluxoCaixa.push(this.grupoFluxoCaixa);
          // zerando variaveis
          this.grupoFluxoCaixa = new GrupoFluxoCaixa();
          this.grupoFluxoCaixa.fluxoCaixa = new FluxoCaixa();
          this.grupoFluxoCaixa.fluxoCaixa.id = fluxoId;
          this.grupoFluxoCaixa.tipoGrupoFluxo = new TipoGrupoFluxo();
          this.grupoFluxoCaixa.tipoGrupoFluxo.id = null;

        }, error => this.httpService.handleError(error, () => this.grupoFluxoCaixaAdd()),
          () => this.httpService.done()
        );

    } else { // alterar

      this.httpService.wait();
      this.httpService.put('/grupofluxocaixa', this.grupoFluxoCaixa.id, this.grupoFluxoCaixa)
        .subscribe(() => {

          const index = this.fluxoCaixa.grupoFluxoCaixa.findIndex(i => i.id === this.grupoFluxoCaixa.id);
          // buscando nomes nos options
          const descricao = this.tiposFluxoCaixa.find(i => i.value === this.grupoFluxoCaixa.tipoGrupoFluxo.id).label;
          this.grupoFluxoCaixa.tipoGrupoFluxo.descricao = descricao;
          // setando no array
          this.fluxoCaixa.grupoFluxoCaixa.splice(index, 1, this.grupoFluxoCaixa);

          // zerando variaveis
          this.grupoFluxoCaixa = new GrupoFluxoCaixa();
          this.grupoFluxoCaixa.fluxoCaixa = new FluxoCaixa();
          this.grupoFluxoCaixa.fluxoCaixa.id = fluxoId;
          this.grupoFluxoCaixa.tipoGrupoFluxo = new TipoGrupoFluxo();
          this.grupoFluxoCaixa.tipoGrupoFluxo.id = null;

        },
          error =>
            this.httpService.handleError(error, () => this.grupoFluxoCaixaAdd()),
          () => this.httpService.done()
        );
    }

  }

  grupofluxoCaixaSalvar() {
    this.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.modalFluxoCaixaGrupos = false;
  }

  editarGrupoFluxoCaixa(event: any) {
    this.grupoFluxoCaixa = new GrupoFluxoCaixa(event);
  }

  removerGrupoFluxoCaixa(event: any) {
    if (confirm('Deseja excluir esta linha?')) {
      this.httpService.wait();
      this.httpService.delete('/grupofluxocaixa', event.id.toString())
        .subscribe(() => {
          const index = this.fluxoCaixa.grupoFluxoCaixa.findIndex(c => c.id === event.id);
          this.fluxoCaixa.grupoFluxoCaixa.splice(index, 1);
        }, error => this.httpService.handleError(error, () => this.removerGrupoFluxoCaixa(event)),
          () => this.httpService.done());
    }
  }

  // Modal Alias linhas Fluxo de caixa
  criarAliasGrupo(event: any) {

    event = event.data;

    this.grupoFluxoCaixa = new GrupoFluxoCaixa(event);
    // this.fluxoCaixa = new FluxoCaixa(event.fluxoCaixa);
    this.grupoFluxoCaixa.aliasGrupoList = new Array();
    this.aliasGrupo = new AliasGrupoFluxoCaixa();
    this.aliasGrupo.grupoFluxoCaixa = new GrupoFluxoCaixa(this.grupoFluxoCaixa);
    // zerando a lista de alias
    this.opcoesAliasFluxoCaixa = [];
    // abrindo o modal
    // this.modalAliasGrupo = true;

    this.skeletonAlias.startLoad();
    this.httpService.get('/aliasgrupofluxocaixa?$select=id,codigoAlias,posicaoAlias,nomeAlias,grupoFluxoCaixa/id,' +
      'grupoFluxoCaixa/descricao&$filter=grupoFluxoCaixa/id eq ' + this.grupoFluxoCaixa.id + '&$orderby=codigoAlias')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.grupoFluxoCaixa.aliasGrupoList.push(element);
        });
        // ordenando o array
        // this.grupoFluxoCaixa.aliasGrupoList.sort((a, b) => a.codigoAlias.localeCompare(b.codigoAlias));
      }, error => this.httpService.handleError(error, () => this.onRowSelect(event)),
        () => this.skeletonAlias.endLoad());
  }

  // adicioanando o apelido para linha do grupo
  aliasGruposFluxoCaixaAdd() {

    if (!this.aliasGrupo.codigoAlias || !this.aliasGrupo.nomeAlias) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Informe o Codigo do Grupo e a nome para prosseguir!'
      });
      return;
    }

    if (this.isFloat(this.aliasGrupo.codigoAlias)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'O código deve conter somente números.'
      })
      return
    }

    // salvar
    if (this.aliasGrupo.id === undefined) {

      this.httpService.wait();
      this.httpService.post('/aliasgrupofluxocaixa', this.aliasGrupo)
        .subscribe(rst => {
          this.aliasGrupo.id = rst.id;
          // setando o apelido no array
          this.grupoFluxoCaixa.aliasGrupoList.push(this.aliasGrupo);
          // ordenando o array
          // this.grupoFluxoCaixa.aliasGrupoList.sort((a, b) => a.codigoAlias.localeCompare(b.codigoAlias));
          // zerando variaveis
          this.aliasGrupo = new AliasGrupoFluxoCaixa();
          this.aliasGrupo.grupoFluxoCaixa = new GrupoFluxoCaixa(this.grupoFluxoCaixa);
        }, error => this.httpService.handleError(error, () => this.aliasGruposFluxoCaixaAdd()),
          () => this.httpService.done());

    } else { // alterar

      this.httpService.wait();
      this.httpService.put('/aliasgrupofluxocaixa', this.aliasGrupo.id, this.aliasGrupo)
        .subscribe(() => {

          const index = this.grupoFluxoCaixa.aliasGrupoList.findIndex(i => i.id === this.aliasGrupo.id);
          // buscando nomes nos options
          /*const nomeFluxo = this.opcoesFluxoCaixa.find(i => i.value === this.aliasGrupo.id).label;
          this.grupoFluxoCaixa.fluxoCaixa.nomeFluxo = nomeFluxo;*/
          // setando no array
          this.grupoFluxoCaixa.aliasGrupoList.splice(index, 1, this.aliasGrupo);
          // ordenando o array
          // this.grupoFluxoCaixa.aliasGrupoList.sort((a, b) => a.codigoAlias.localeCompare(b.codigoAlias));
          // zerando variaveis
          this.aliasGrupo = new AliasGrupoFluxoCaixa();
          this.aliasGrupo.grupoFluxoCaixa = new GrupoFluxoCaixa(this.grupoFluxoCaixa);
        },
          error =>
            this.httpService.handleError(error, () => this.aliasGruposFluxoCaixaAdd()),
          () => this.httpService.done()
        );

    }
  }
  // salvar nome do alias
  salvarAliasGrupo() {
    this.modalAliasGrupo = false;
    this.aliasGrupo = new AliasGrupoFluxoCaixa();
  }

  editarAliasGrupo(event: any) {
    this.aliasGrupo = new AliasGrupoFluxoCaixa(event);
  }

  removerAliasGrupo(event: any) {
    if (confirm('Deseja excluir esta linha?')) {
      this.httpService.wait();
      this.httpService.delete('/aliasgrupofluxocaixa', event.id.toString())
        .subscribe(() => {
          const index = this.grupoFluxoCaixa.aliasGrupoList.findIndex(c => c.id === event.id);
          this.grupoFluxoCaixa.aliasGrupoList.splice(index, 1);
        }, error => this.httpService.handleError(error, () => this.removerAliasGrupo(event)),
          () => this.httpService.done());
    }
  }

  // cancelando todas as ediçoes
  cancelarEdicaoGrupo() {
    // variaveis d controle
    const fluxoId = this.grupoFluxoCaixa.fluxoCaixa.id;
    const lst = this.grupoFluxoCaixa.aliasGrupoList;
    // zerando variaveis
    this.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.grupoFluxoCaixa.fluxoCaixa = new FluxoCaixa();
    this.grupoFluxoCaixa.fluxoCaixa.id = fluxoId;
    this.grupoFluxoCaixa.tipoGrupoFluxo = new TipoGrupoFluxo();
    this.grupoFluxoCaixa.tipoGrupoFluxo.id = null;
    this.grupoFluxoCaixa.aliasGrupoList = lst;
  }

  cancelarEdicaoAlias() {
    this.aliasGrupo = new AliasGrupoFluxoCaixa();
    this.aliasGrupo.grupoFluxoCaixa = this.grupoFluxoCaixa;
  }
  // abrir modal pré formulario de composição
  abrirFormularioVinculos() {
    this.opcoesGrupoFluxoCaixa = [];
    this.opcoesAliasFluxoCaixa = [];
    this.vinculaGrupoOperacao = true;
    this.comboGruposFluxo();
    this.subtitle = 'RELACIONAMENTO: ' + this.fluxoCaixa.nomeFluxo;
    this.toolbarService.alterarStatus('atualizar', true, 'Atualizar relatório', () => this.start());
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.start());
  }

  comboAliasFluxoCaixa() {
    this.aliasGrupo = new AliasGrupoFluxoCaixa();
    this.listaOperacaoRegras = new Array();
    this.listaOperacaoRegrasSelecionadas = new Array();
    this.opcoesAliasFluxoCaixa = [];
    this.opcoesAliasFluxoCaixa.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/aliasgrupofluxocaixa?$select=id,codigoAlias,posicaoAlias,nomeAlias' +
      '&$filter=grupoFluxoCaixa/id eq ' + this.grupoFluxoCaixa.id + '&$orderby=codigoAlias')
      .subscribe(ref => {
        // ref.value.sort((a, b) => a.codigoAlias.localeCompare(b.codigoAlias));
        ref.value.forEach(element => {
          this.opcoesAliasFluxoCaixa.push({ label: element.codigoAlias + ' - ' + element.nomeAlias, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboAliasFluxoCaixa()),
        () => this.httpService.done());
  }

  // abrir formulario de composição
  vincularGrupoOperacao(event: any, origem: boolean) {
    // se as opções não existirem
    // this.fluxoCaixa.id = this.grupoFluxoCaixa.fluxoCaixa.id;
    if ((this.opcoesAliasFluxoCaixa === undefined || origem)) {
      this.comboAliasFluxoCaixa();
    }
    if ((this.opcoesGrupoFluxoCaixa === undefined || origem)) {
      this.comboGruposFluxo();
    }
    // se não tiver um Alias definido
    setTimeout(() => {
      if (this.aliasGrupo.id === undefined) {
        this.aliasGrupo = new AliasGrupoFluxoCaixa(event);
      }
      if (this.aliasGrupo.id === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Selecione uma linha para prosseguir!'
        });
        return;
      }
      this.continua();
    }, 500);
  }

  continua() {
    this.modalFluxoCaixaGrupos = false;
    this.modalAliasGrupo = false;
    this.vinculaGrupoOperacao = true;
    // alterando o status dos botoes
    // this.toolbarService.alterarStatus('atualizar', (this.vinculaGrupoOperacao), 'Atualizar relatório', () => this.start());
    // this.toolbarService.alterarStatus('voltar', !(this.vinculaGrupoOperacao), 'Voltar', () => this.start());
    // this.toolbarService.alterarStatus('pesquisa', true, 'Add Formula', () => this.start());
    // this.toolbarService.alterarStatus('check', true, 'Relacionar Grupo e Operações', () => this.abrirFormularioVinculos());
    // this.toolbarService.alterarStatus('edit', true, 'Editar Fluxo', () => this.openModalFluxo(false));
    const fluxo = this.fluxoCaixa;
    const grupo = this.grupoFluxoCaixa;
    const linha = this.aliasGrupo;
    this.fluxoCaixa = new FluxoCaixa(fluxo);
    this.grupoFluxoCaixa = new GrupoFluxoCaixa(grupo);
    this.aliasGrupo = new AliasGrupoFluxoCaixa(linha);
    if (
      this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected &&
      this.dblinkedSessionService.periodo.month && this.aliasGrupo.id) {
      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        authUserId: this.sessionService.loggedUser.id,
        fluxoId: this.fluxoCaixa.id
      };
      // buscando operações relacionadas
      this.httpService.wait();
      this.httpService.post('/custom/fluxocaixa/fluxo_caixa-list-relac', infos)
        .subscribe(retorno => {
          this.listaOperacaoRegrasSelecionadas = (retorno);

          // buscando todas as operações
          this.httpService.wait();
          this.httpService.post('/custom/fluxocaixa/fluxo_caixa-list-operacoes', infos)
            .subscribe(ret => {
              if (this.listaOperacaoRegrasSelecionadas.length === 0) {
                this.listaOperacaoRegras = ret;
              } else {
                let list: Array<any> = new Array()
                ret.forEach(element => {
                  //Operações normais
                  if (element.id !== 0) {
                    let index = this.listaOperacaoRegrasSelecionadas.findIndex(l => l.id === element.id)
                    if (index === -1) list.push(element)
                  }

                  //Operações de transferências
                  else {
                    let index2 = this.listaOperacaoRegrasSelecionadas.findIndex(l => l.descricaoRegra === element.descricaoRegra)
                    if (index2 === -1) list.push(element)
                  }
                });
                this.listaOperacaoRegras = list
              }
              // listar apenas os relacionamnetos do alias selecionado
              const listCtrl = this.listaOperacaoRegrasSelecionadas;
              this.listaOperacaoRegrasSelecionadas = new Array();
              listCtrl.forEach(element => {
                if (element.aliasId === this.aliasGrupo.id) {
                  this.listaOperacaoRegrasSelecionadas.push(element);
                  // controle filtro lista adcionadas
                  this.filterDestino.push(element);
                }
              });

            },
              error => this.httpService.handleError(error, () => this.continua()),
              () => this.httpService.done());
          // final buscando todas as operações

          // continuação buscando operações relacionadas
        },
          error => this.httpService.handleError(error, () => this.continua()),
          () => this.httpService.done());

    } // if empresa selecionada
  } // final metodo

  adicionarOperacaoLista() {

    this.operacaoSelecionada.forEach(element => {
      // adicionando item da lista de operações selecionadas
      this.listaOperacaoRegrasSelecionadas.push(element);
      // controle filtro lista adcionadas
      this.filterDestino.push(element);
    });

    const listClone = this.listaOperacaoRegras;
    this.listaOperacaoRegras = new Array();

    listClone.forEach(element => {
      const index = this.listaOperacaoRegrasSelecionadas.findIndex(l => l.id === element.id);
      if (index === -1) {
        this.listaOperacaoRegras.push(element);
      }
    });

    // limpando a lista de selecionadas
    this.operacaoSelecionada = new Array();

    if (this.listaOperacaoRegrasSelecionadas.length > 0) {
      this.salvarComposicao();
    }

  }

  // removendo itens na segunda lista
  removerOperacaoLista() {

    let newSelectedList = [], newList = this.listaOperacaoRegras;

    this.listaOperacaoRegrasSelecionadas.forEach(operacao => {
      let selected = this.operacaoAdicionada.filter(item => item.id == operacao.id).length > 0;
      if (selected)
        newList.push(operacao);
      else
        newSelectedList.push(operacao);
    })

    this.listaOperacaoRegras = newList;
    this.listaOperacaoRegrasSelecionadas = newSelectedList;

    this.operacaoAdicionada = new Array();

    this.salvarComposicao();
  }

  salvarComposicao() {

    if (this.aliasGrupo.id === undefined || this.aliasGrupo.id === null) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecione uma linha para prosseguir!'
      });
      return;
    }

    let listaOperacoes = new Array()
    let operacaoesUnicas = new Set()

    this.listaOperacaoRegrasSelecionadas.forEach( row => {
      if (!operacaoesUnicas.has(row.id)) {
        operacaoesUnicas.add(row.id)
        listaOperacoes.push(row)
      }
    })

    const infos = {
      empresaId: this.dblinkedSessionService.empresa.id,
      authUserId: this.sessionService.loggedUser.id,
      aliasId: this.aliasGrupo.id,
      operacoes: listaOperacoes,
      alterar: false
    };

    this.httpService.wait();
    this.httpService.post('/custom/fluxocaixa/fluxo_caixa-vincular-operacaoes', infos)
      .subscribe(rst => {
        if (rst.error === true) {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: rst.message });
          // this.start();
        } else {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: rst.message });
          // this.start();
        }
      }, error => this.httpService.handleError(error, () => this.salvarComposicao()),
        () => this.httpService.done()
      );

  }

  openModalFormulaDetal(event: any) {
    this.fluxoCaixa.id = event.fluxoCaixa.id;
    this.fluxoCaixa.nomeFluxo = event.fluxoCaixa.nomeFluxo;
    this.grupoFluxoCaixa = new GrupoFluxoCaixa(event);
    this.comboGruposFluxoFormula();
    this.modalFormulaDetal = true;
    this.operadorCtrl = false;
    this.formulaDetalList = new Array();
    this.formulaDetalhe = new FormulaDetalhe();
    // salvo a posição linha do grupo
    this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
    this.formulaDetalhe.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.formulaDetalhe.fluxoCaixa = new FluxoCaixa(this.fluxoCaixa);
    this.httpService.wait();
    this.httpService.get('/formuladetalhe?$select=id,operador,fluxoCaixa/id,fluxoCaixa/nomeFluxo,' +
      'grupoFluxoCaixa/id,grupoFluxoCaixa/descricao' +
      '&$filter=fluxoCaixa/id eq ' + event.fluxoCaixa.id + ' and codigoGrupo eq ' + event.codigoGrupo + '&$orderby=id')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.formulaDetalList.push(element);
        });
      },
        error => this.httpService.handleError(error, () => this.openModalFormulaDetal(event)),
        () => this.httpService.done());
  }

  // dropdown com todos os grupos para compor as formulas
  comboGruposFluxoFormula() {
    this.opcoesGrupoFluxoCaixa = [];
    this.opcoesFluxoCaixa.push({ label: 'Selecione', value: null });
    this.httpService.wait(); // and tipoGrupoFluxo/id ne 2
    this.httpService.get('/grupofluxocaixa?$select=id,codigoGrupo,descricao&$filter=fluxoCaixa/id eq '
      + this.fluxoCaixa.id + '&$orderby=codigoGrupo')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesGrupoFluxoCaixa.push({ label: element.codigoGrupo + ' - ' + element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboGruposFluxoFormula()),
        () => this.httpService.done());
  }

  formulaDetalheAdd() {

    if (!this.formulaDetalhe.grupoFluxoCaixa.id) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecione um Grupo para prosseguir!'
      });
      return;
    }
    // validando o tipo de operador.
    if (this.formulaDetalhe.operador) {
      if ((this.formulaDetalhe.operador.indexOf('-') === -1) && (this.formulaDetalhe.operador.indexOf('+') === -1)) {
        this.operadorCtrl = false;
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Somente operador de + ou - são aceitos!'
        });
        return;
      }
    } else {
      this.operadorCtrl = false;
    }
    // validando se foi informado um operador

    // salvar
    if (this.formulaDetalhe.id === undefined) {

      this.httpService.wait();
      this.httpService.post('/formuladetalhe', this.formulaDetalhe)
        .subscribe(rst => {
          this.formulaDetalhe.id = rst.id;
          // setando o apelido no array
          const descricao = this.opcoesGrupoFluxoCaixa.find(i => i.value === this.formulaDetalhe.grupoFluxoCaixa.id).label;
          this.formulaDetalhe.grupoFluxoCaixa.descricao = descricao.split(' - ')[1];
          this.formulaDetalList.push(this.formulaDetalhe);
          // zerando variaveis
          this.formulaDetalhe = new FormulaDetalhe();
          // salvo a posição linha do grupo
          this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
          this.formulaDetalhe.grupoFluxoCaixa = new GrupoFluxoCaixa();
          this.formulaDetalhe.fluxoCaixa = new FluxoCaixa(this.fluxoCaixa);
        }, error => this.httpService.handleError(error, () => this.formulaDetalheAdd()),
          () => this.httpService.done());

    } else { // alterar

      this.httpService.wait();
      this.httpService.put('/formuladetalhe', this.formulaDetalhe.id, this.formulaDetalhe)
        .subscribe(() => {

          const descricao = this.opcoesGrupoFluxoCaixa.find(i => i.value === this.formulaDetalhe.grupoFluxoCaixa.id).label;
          this.formulaDetalhe.grupoFluxoCaixa.descricao = descricao.split(' - ')[1];
          const index = this.formulaDetalList.findIndex(i => i.id === this.formulaDetalhe.id);
          // setando no array
          this.formulaDetalList.splice(index, 1, this.formulaDetalhe);

          // zerando variaveis
          this.formulaDetalhe = new FormulaDetalhe();
          // salvo a posição linha do grupo
          this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
          this.formulaDetalhe.grupoFluxoCaixa = new GrupoFluxoCaixa();
          this.formulaDetalhe.fluxoCaixa = new FluxoCaixa(this.fluxoCaixa);
        },
          error =>
            this.httpService.handleError(error, () => this.formulaDetalheAdd()),
          () => this.httpService.done()
        );

    }
  }

  cancelarFormulaDetalhe() {
    this.operadorCtrl = false;
    this.formulaDetalhe = new FormulaDetalhe();
    // salvo a posição linha do grupo
    this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
    this.formulaDetalhe.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.formulaDetalhe.fluxoCaixa = new FluxoCaixa(this.fluxoCaixa);
  }

  salvarFormulaDetalhe() {

    this.operadorCtrl = false;

    const size = this.formulaDetalList.length;
    let i = 1;
    this.formulaDetalList.forEach(formula => {
      if (i < size) {
        if (formula.operador === '' || formula.operador === undefined) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'A fórmula está inconsistente, favor confira a sequencia do cálculo!'
          });
          this.operadorCtrl = true;
          return;
        }
      } else {
        if (formula.operador !== '' && formula.operador !== undefined && formula.operador !== null) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'A fórmula está inconsistente, o último grupo não deve conter operador!'
          });
          this.operadorCtrl = true;
          return;
        }
      }
      i++;
    });
    if (!this.operadorCtrl) {
      this.operadorCtrl = false;
      this.modalFormulaDetal = false;
      this.formulaDetalList = new Array();
      this.comboGruposFluxo();
    }
  }

  editarFormulaDetalhe(event: any) {
    this.formulaDetalhe = new FormulaDetalhe(event);
    this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
  }

  removerFormulaDetalhe(event: any) {
    if (confirm('Deseja excluir esta formula?')) {
      this.httpService.wait();
      this.httpService.delete('/formuladetalhe', event.id.toString())
        .subscribe(() => {
          const index = this.formulaDetalList.findIndex(i => i.id === this.formulaDetalhe.id);
          this.formulaDetalList.splice(index, 1);
          this.formulaDetalhe = new FormulaDetalhe();
          this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
          this.formulaDetalhe.grupoFluxoCaixa = new GrupoFluxoCaixa();
          this.formulaDetalhe.fluxoCaixa = new FluxoCaixa(this.fluxoCaixa);
        }, error => this.httpService.handleError(error, () => this.removerFormulaDetalhe(event)),
          () => this.httpService.done());
    }
  }

  // abrir modal pré formulario de composição
  comboGruposFluxo() {
    this.opcoesGrupoFluxoCaixa = [];
    this.opcoesFluxoCaixa.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/grupofluxocaixa?$select=id,codigoGrupo,descricao&$filter=fluxoCaixa/id eq '
      + this.fluxoCaixa.id + ' and tipoGrupoFluxo/id eq 2&$orderby=codigoGrupo')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesGrupoFluxoCaixa.push({ label: element.codigoGrupo + ' - ' + element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboGruposFluxo()),
        () => this.httpService.done());
  }

  private toolbarSetButtons() {
    const todos = this.buttons.slice();
    if (todos.length === 0) {
      this.toolbarService.replace('voltar', true, 'class-first-button', 'undo', 'Voltar',);
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
      // this.toolbarService.replace('pesquisa', true, 'class-edit-button', 'playlist_add', 'Formula', );
      this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir',);
      this.toolbarService.replace('save', true, 'class-first-button', 'save', 'Imprimir',);
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar');
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar novo Fluxo', () => this.openModalFluxo(true));
    } else {
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.ocultar('print');
      this.toolbarService.replace('voltar', true, 'class-first-button', 'undo', 'Voltar',);
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      this.toolbarService.replace('check', true, 'class-edit-button', 'share', 'Atualizar Status');
      // this.toolbarService.replace('pesquisa', true, 'class-edit-button', 'functions', 'Formula', );
      this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir',);
      this.toolbarService.replace('save', true, 'class-edit-button', 'save', 'Imprimir');
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar');
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar novo Fluxo', () => this.openModalFluxo(true));
    }
  }

  toolbarEditButtons(event: any) {
    // verificando se tem grupos cadastrados;
    this.toolbarService.alterarStatus('check', !(event.grupos > 0), 'Relacionar Grupo e Operações',
      () => this.abrirFormularioVinculos());
    this.toolbarService.alterarStatus('edit', false, 'Editar Fluxo', () => this.openModalFluxo(false));
    this.toolbarService.alterarStatus('remove', false, 'Remover Fluxo', () => this.removerFluxo());
  }

  onFilterOrigem(event: any) {
    this.filterOrigem = new Array();
    this.selectAllOrigem = false;

    event.filteredValue.forEach(element => {
      element.selected = false;
      this.filterOrigem.push(element);
    });
  }

  onSelectAllOrigem(event: any) {

    this.operacaoSelecionada = new Array();

    // chegara como false a primeira vez.
    if (event.checked) {
      this.selectAllOrigem = true;
      this.listaOperacaoRegras.forEach(form => {
        const index = this.filterOrigem.findIndex(o => o.id === form.id);
        if (index !== -1) {
          form.selected = true;
          this.operacaoSelecionada.push(form);
        } else {
          form.selected = false;
        }
      });
    } else {
      this.selectAllOrigem = false;
      this.listaOperacaoRegras.forEach(form => {
        form.selected = false;
      });
    }

  }

  onFilterDestino(event: any) {
    this.filterDestino = new Array();
    this.selectAllDest = false;
    event.filteredValue.forEach(element => {
      element.selected = false;
      this.filterDestino.push(element);
    });
  }

  numberOnly(): void {
    this.aliasGrupo.codigoAlias = Number(this.aliasGrupo.codigoAlias.toString().normalize("NFD").replace(/([\u0300-\u036f]|[^0-9])/g, ""))
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10; // this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  /*Show Hide Semana*/
  isCardPagRec: boolean = true;
  ShowHIdeCardPagRec() {
    this.isCardPagRec = !this.isCardPagRec;
  }

  currentStatePagRec = 'initial';
  changeStatePagRec() {
    this.currentStatePagRec = this.currentStatePagRec === 'initial' ? 'final' : 'initial';
  }

  /*FullScreen PagRec*/
  fullPagRec: boolean = false;
  fullScreenPagRec() {
    this.fullPagRec = !this.fullPagRec;
  }

  private loadBi() {

    this.tiposBi = [];
    this.tiposBi.push({ label: 'Selecione', value: 0 });

    this.httpService.wait();
    this.httpService.get('custom/pbi/buscar-bi-usuario',
      [this.sessionService.loggedUser.id, this.dblinkedSessionService.empresa.id])
      .subscribe(retorno => {
        retorno.forEach(element => {
          this.tiposBi.push({ label: element.nome, value: element.id });
          if (element.selected) {
            this.relPbi = element.id;
          }
        });

      }, error => this.httpService.handleError(error, () => this.loadBi()),
        () => this.httpService.done());

  }

  salvarParametroProv() {
    if (confirm('Deseja realmente atualizar este parametro?')) {

      if (!this.provFlxuoFin) {
        if (confirm('Os títulos salvos como provisão serão excluídos, deseja continuar?')) {
          const infos = {
            empresaId: this.dblinkedSessionService.empresa.id,
            provFlxuoFin: this.provFlxuoFin,
            pedidoFluxoFin: this.pedidoFluxoFin

          };
          this.httpService.wait();
          this.httpService.post('/custom/financeiro/salvar-parametro-prov', infos)
            .subscribe(rst => {
              if (rst.error) {
                this.messageService.add({
                  severity: 'warn', key: 'messageLinnks',
                  summary: 'MSG: ',
                  detail: rst.message
                });
              } else {
                this.messageService.add({
                  severity: 'success', key: 'messageLinnks',
                  summary: 'MSG: ',
                  detail: rst.message
                });
              }


            }, error => this.httpService.handleError(error, () => this.salvarParametroProv()),
              () => this.httpService.done());

        }
      } else {
        const infos = {
          empresaId: this.dblinkedSessionService.empresa.id,
          provFlxuoFin: this.provFlxuoFin,
          pedidoFluxoFin: this.pedidoFluxoFin

        };
        this.httpService.wait();
        this.httpService.post('/custom/financeiro/salvar-parametro-prov', infos)
          .subscribe(rst => {
            if (rst.error) {
              this.messageService.add({
                severity: 'warn', key: 'messageLinnks',
                summary: 'MSG: ',
                detail: rst.message
              });
            } else {
              this.messageService.add({
                severity: 'success', key: 'messageLinnks',
                summary: 'MSG: ',
                detail: rst.message
              });
            }


          }, error => this.httpService.handleError(error, () => this.salvarParametroProv()),
            () => this.httpService.done());
      }



    }
  }

  salvarParametroBi() {
    if (confirm('Deseja realmente atualizar este parametro?')) {
      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        authUserId: this.sessionService.loggedUser.id,
        relPbi: this.relPbi

      };
      this.httpService.wait();
      this.httpService.post('/custom/financeiro/salvar-parametro-usuario', infos)
        .subscribe(rst => {
          if (rst.error) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.message
            });
          } else {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.message
            });
          }


        }, error => this.httpService.handleError(error, () => this.salvarParametroBi()),
          () => this.httpService.done());
    }
  }

  salvarContasPadrao() {
    if (confirm('Deseja realmente atualizar as contas padrões?')) {
      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        authUserId: this.sessionService.loggedUser.id,
        contasSelecionadas: this.contasSelecionadas
      };
      this.httpService.wait();
      this.httpService.post('/custom/cadastro/salvar-contas-bancarias-padrao', infos)
        .subscribe(rst => {
          if (rst.error) {
            this.msgs = [{
              severity: 'warn',
              summary: 'MSG: ',
              detail: rst.message
            }];
          } else {
            this.msgs = [{
              severity: 'success',
              summary: 'MSG: ',
              detail: rst.message,
            }];

          }

        }, error => this.httpService.handleError(error, () => this.salvarContasPadrao()),
          () => this.httpService.done());

    }
  }

  private loadParametro() {

    this.httpService.wait();
    this.httpService.get('custom/financeiro/buscar-parametro',
      [this.dblinkedSessionService.empresa.id])
      .subscribe(retorno => {
        this.pedidoFluxoFin = retorno.visualizaPedido;
        this.provFlxuoFin = retorno.visualizaProvisao;
        this.pedidoMisto = retorno.pedidoMisto;
        this.operacaoId = retorno.operacaoId;
        this.financeiroAntecipado = retorno.financeiroAntecipado;
        this.comissaoAtivo = retorno.comissaoAtiva;
        this.participanteObrigatorio = retorno.participanteObrigatorio;
        this.confTaxa = retorno.confTaxa;
        this.confTaxaPedido = retorno.confTaxaPedido;
        this.permiteAlterarMoeda = retorno.permiteAlterarMoeda;
        this.permiteMultiMoeda = retorno.permiteMultiMoeda;
        this.tipoAtualizacao = retorno.tipoAtualizacao;
        this.faturaAutomatico = retorno.faturaAutomatico;
        this.diasFaturar = retorno.diasFaturar;
      }, error => this.httpService.handleError(error, () => this.loadParametro()),
        () => this.httpService.done());

  }

  /*oow Hide Config Flux Caixa*/
  isCardFluxo: boolean = true;
  ShowHideFluxo() {
    this.isCardFluxo = !this.isCardFluxo;
    this.toolbarMain.setIcon('btn6', this.isCardFluxo ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn6', this.isCardFluxo ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide Config Flux Caixa BI*/
  isCardFluxoBI: boolean = true;
  ShowHideFluxoBI() {
    this.isCardFluxoBI = !this.isCardFluxoBI;
    this.toolbarFluxoBI.setIcon('btn2', this.isCardFluxoBI ? 'expand_less' : 'expand_more')
    this.toolbarFluxoBI.setTooltip('btn2', this.isCardFluxoBI ? 'Ver menos' : 'Ver mais')
  }

  isCardVinculacaoBancaria: boolean = true;
  showHidevinculacaoBancaria() {
    this.isCardVinculacaoBancaria = !this.isCardVinculacaoBancaria;
    this.toolbarVinculacao.setIcon('btn3', this.isCardVinculacaoBancaria ? 'expand_less' : 'expand_more')
    this.toolbarVinculacao.setTooltip('btn3', this.isCardVinculacaoBancaria ? 'Ver menos' : 'Ver mais')
  }
  /*Show Hide Config Taxa*/
  isCardParametroTaxa: boolean = true;
  ShowHideParametroTaxa() {
    this.isCardParametroTaxa = !this.isCardParametroTaxa;
    this.toolbarTaxa.setIcon('btn2', this.isCardParametroTaxa ? 'expand_less' : 'expand_more')
    this.toolbarTaxa.setTooltip('btn2', this.isCardParametroTaxa ? 'Ver menos' : 'Ver mais')
  }



  //show or hide "CONTAS BANCÁRIAS PADRÃO"
  isCardContasPadrao: boolean = true;
  showHideContasPadrao() {
    this.isCardContasPadrao = !this.isCardContasPadrao;
    this.toolbarContas.setIcon('btn2', this.isCardContasPadrao ? 'expand_less' : 'expand_more')
    this.toolbarContas.setTooltip('btn2', this.isCardContasPadrao ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide Config Flux Caixa Prov*/
  isCardFluxoProv: boolean = true;
  ShowHideFluxoProv() {
    this.isCardFluxoProv = !this.isCardFluxoProv;
    this.toolbarFluxoProv.setIcon('btn2', this.isCardFluxoProv ? 'expand_less' : 'expand_more')
    this.toolbarFluxoProv.setTooltip('btn2', this.isCardFluxoProv ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide Config Participante*/
  isCardParametroParticipante: boolean = true;
  ShowHideParametroParticipante() {
    this.isCardParametroParticipante = !this.isCardParametroParticipante;
    this.toolbarParticipante.setIcon('btn2', this.isCardParametroParticipante ? 'expand_less' : 'expand_more')
    this.toolbarParticipante.setTooltip('btn2', this.isCardParametroParticipante ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide Config Flux Caixa Comissao*/
  isCardFluxoComissao: boolean = true;
  ShowHideFluxoComissao() {
    this.isCardFluxoComissao = !this.isCardFluxoComissao;
    this.toolbarComissao.setIcon('btn2', this.isCardFluxoComissao ? 'expand_less' : 'expand_more')
    this.toolbarComissao.setTooltip('btn2', this.isCardFluxoComissao ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide Config Flux Caixa Prov*/
  isCardFluxoPedidoMisto: boolean = true;
  ShowHideFluxoPedidoMisto() {
    this.isCardFluxoPedidoMisto = !this.isCardFluxoPedidoMisto;
    this.toolbarFluxoMisto.setIcon('btn2', this.isCardFluxoPedidoMisto ? 'expand_less' : 'expand_more')
    this.toolbarFluxoMisto.setTooltip('btn2', this.isCardFluxoPedidoMisto ? 'Ver menos' : 'Ver mais')
  }

  isCardContasBancariasVinculadas: boolean = true;
  showHideContasBancariasVinculadas() {
    this.isCardContasBancariasVinculadas = !this.isCardContasBancariasVinculadas;
  }

  /*Show Hide Grupo Operação*/
  isCardParametroBoleto: boolean = true;
  ShowHideParametroBoleto() {
    this.isCardParametroBoleto = !this.isCardParametroBoleto;
    this.toolbarParametroBoleto.setIcon('btn2', this.isCardParametroBoleto ? 'expand_less' : 'expand_more')
    this.toolbarParametroBoleto.setTooltip('btn2', this.isCardParametroBoleto ? 'Ver menos' : 'Ver mais')
  }


  salvarParametroAntecipado() {
    if (confirm('Deseja realmente atualizar este parametro?')) {

      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        pedidoMisto: this.pedidoMisto,
        operacaoId: this.operacaoId,
        financeiroAntecipado: this.financeiroAntecipado,
        faturaAutomatico: this.faturaAutomatico,
        diasFaturar: this.diasFaturar

      };
      this.httpService.wait();
      this.httpService.post('/custom/financeiro/salvar-parametro-pedido-misto', infos)
        .subscribe(rst => {
          if (rst.error) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.message
            });
          } else {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.message
            });
          }


        }, error => this.httpService.handleError(error, () => this.salvarParametroAntecipado()),
          () => this.httpService.done());
    }
  }

  salvarParametroComissao() {
    if (confirm('Deseja realmente atualizar este parametro?')) {

      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        comissaoAtivo: this.comissaoAtivo,

      };
      this.httpService.wait();
      this.httpService.post('/custom/financeiro/salvar-parametro-comissao', infos)
        .subscribe(rst => {
          if (rst.error) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.message
            });
          } else {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.message
            });
          }
        }, error => this.httpService.handleError(error, () => this.salvarParametroComissao()),
          () => this.httpService.done());
    }
  }

  salvarParametroParticipante() {
    if (confirm('Deseja realmente atualizar este parametro?')) {

      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        participanteObrigatorio: this.participanteObrigatorio,

      };
      this.httpService.wait();
      this.httpService.post('/custom/financeiro/salvar-parametro-participante', infos)
        .subscribe(rst => {
          if (rst.error) {
            this.msgs = [{
              severity: 'warn',
              summary: 'MSG: ',
              detail: rst.message
            }];
          } else {
            this.msgs = [{
              severity: 'success',
              summary: 'MSG: ',
              detail: rst.message
            }];
          }


        }, error => this.httpService.handleError(error, () => this.ShowHideParametroParticipante()),
          () => this.httpService.done());
    }



  }


  salvarParametroTaxas() {
    if (confirm('Deseja realmente atualizar este parametro?')) {

      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        confTaxa: this.confTaxa,
        confTaxaPedido: this.confTaxaPedido,
        permiteAlterarMoeda: this.permiteAlterarMoeda,
        permiteMultiMoeda: this.permiteMultiMoeda

      };
      this.httpService.wait();
      this.httpService.post('/custom/financeiro/salvar-parametro-taxa', infos)
        .subscribe(rst => {
          if (rst.error) {
            this.msgs = [{
              severity: 'warn',
              summary: 'MSG: ',
              detail: rst.message
            }];
          } else {
            this.msgs = [{
              severity: 'success',
              summary: 'MSG: ',
              detail: rst.message
            }];
          }


        }, error => this.httpService.handleError(error, () => this.salvarParametroComissao()),
          () => this.httpService.done());
    }
  }

  updateLinkOper() {
    this.toolbarGrupoOper.setDisabled('btn2', this.operacaoAdicionada.length == 0);
    this.toolbarGrupoOper.setDisabled('btn3', this.operacaoSelecionada.length == 0);
  }

  isFloat(x) {
    if (!isNaN(x)) {
      if (parseInt(x) != parseFloat(x)) {
        return true
      }
    }

    return false
  }

  exportarVinculacaoFluxoCaixa() {
    const payload: any = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      fluxo_id: this.fluxoCaixa.id,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/relatorio-vinculacao', payload)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
          if (res["error"]) return

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          };

          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/relatorio-vinculacao', httpOptions2)
            .subscribe(
              (res: Blob) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                link.href = url
                link.download = "Relatório vinculação.xlsx"
                link.click()
                window.URL.revokeObjectURL(url)
              }, err => {
                this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível baixar o relatório: " + err.message })
                console.log(err)
              });

        }, err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível gerar o relatório: " + err.message })
          console.log(err)
          this.httpService.done()
        });
  }
}
