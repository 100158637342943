import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { Subscription } from 'rxjs/Subscription';

import { Empresa } from '../model/empresa.model';
import { MunicipioIbge } from '../../util/model/municipio-ibge.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { MessageService } from 'primeng/api';

import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss'],
  providers: [CrudService]
})
export class EmpresaComponent extends DataTableBasicComponent
  implements OnInit, OnDestroy {
  public empresa: Empresa = new Empresa();
  public registrosEmpresas: Array<Empresa> = new Array();

  private sessionSubscription: Subscription;
  private afterSaveSubscription: Subscription;
  private afterRemoveSubscription: Subscription;

  editarConta: boolean;
  novaConta: boolean;
  razao: string;
  fantasia: string;
  cnpj: string;
  uf: string;
  municipio: string;
  ids: Array<any> = new Array();

  ufs: Array<any> = new Array();
  municipios: Array<any> = new Array();
  indicadorIncidenciaTributaria: Array<any> = new Array();
  indicadorApropriacaoCredito: Array<any> = new Array();
  codigoTipoContribuicaoApurada: Array<any> = new Array();
  indicadorRegimeCumulativo: Array<any> = new Array();
  // listaTodoProjetos: Array<any> = new Array()
  mes = [
    { value: 1, label: 'Janeiro' },
    { value: 2, label: 'Fevereiro' },
    { value: 3, label: 'Março' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Maio' },
    { value: 6, label: 'Junho' },
    { value: 7, label: 'julho' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Setembo' },
    { value: 10, label: 'Outubro' },
    { value: 11, label: 'Novembro' },
    { value: 12, label: 'Dezembro' }
  ];

  selectedUf: any = null;
  selectedMunicipio: any = null;
  selectedIndicadorIncidenciaTributaria: any = null;
  selectedIndicadorApropriacaoCredito: any = null;
  selectedCodigoTipoContribuicaoApurada: any = null;
  selectedIndicadorRegimeCumulativo: any = null;
  selectedMes: any = null;
  // todoProjetoId: number = null
  // jsonData = {};

  statusMunicipio = 'SELECIONE A UF';

  verificaConciliacao: boolean = false;
  permiteEstoqueNegativo: boolean = false;
  centroCustoObrigatorio: boolean = false;
  vinculaItemOperacao: boolean = false;
  calendarioAutomatico: boolean = false;
  grupoEstoqueObrigatorio: boolean = false;
  beneficioObrigatorioRms: boolean = false;
  segmentoObrigatorio: boolean = false;
  visualizaFiliais: boolean = false;
  cadastroGrupoEmpresa: boolean = false;
  faturaProdutoAutomatico: boolean = false;

  telefone: string = null;
  numeroInicialNf: string = null;
  serieNf: string = null;
  numeroNfs: string = null;
  serieNfs: string = null;
  rps: string = null;
  cnae: string = null;
  destinatariosVincXml: string = null;

  empresasGrupo: Array<any> = new Array()
  empresasGrupoSelecionadas: Array<any> = new Array()
  empresaId: Number = null
  placeholderEmpresas: string = 'Selecione a(s) empresa(s)'
  permiteAlteracaoProdutoServico: boolean = false
  permiteAlteracaoParticipante: boolean = false
  permiteAlteracaoContaContabil: boolean = false
  permiteAlteracaoRegraContabil: boolean = false
  permiteAlteracaoRegraFiscal: boolean = false
  permiteAlteracaoOperacaoRegra: boolean = false
  permiteAlteracaoFormaPagamento: boolean = false

  title = 'EMPRESAS';
  subtitle = "EMPRESAS";

  buttonToolbar = this.getButtonToolbarContent();
  toolbarMainIni = this.getToolbarMainIniContent();

  public toolbarMain = new Toolbar();

  constructor(
    private httpClient: HttpClient,
    private crudService: CrudService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private configService: ConfigService,
    private httpService: HttpService,
    private utilService: UtilService,
    private messageService: MessageService,
    private dblinkedSessionService: DblinkedSessionService
  ) {
    super(crudService);
    this.toolbarService.hidden = true
  }

  ngOnInit() {
    // this.carregarToolbar();
    this.toolbarService.hidden = true;
    // Promise.resolve(null).then(() => this.ocultaBotoesToolbar());
    this.toolbarMain.setAll(this.toolbarMainIni);

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
    );

    this.afterSaveSubscription = this.crudService.afterSave.subscribe(
      empresa => {
        this.dblinkedSessionService.loadEmpresas();
      }
    );

    this.afterRemoveSubscription = this.crudService.afterRemove.subscribe(
      empresa => {
        this.dblinkedSessionService.loadEmpresas();
      }
    );
  }

  carregar() {
    this.title = 'Empresas';
    this.subtitle = 'Empresa';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();

    this.carregaBotoesToolbarInicial();
    this.limpaCamposForm();

    this.loadUfs();
    this.loadRegistros();
  }

  empresaChanged() {
    this.columns = [
      new DataTableColumn('RAZÃO', 'razaoSocial').setNoWrap(),
      new DataTableColumn('FANTASIA', 'nomeFantasia'),
      new DataTableColumn('CNPJ', 'cnpj').setNoWrap(),
      new DataTableColumn('UF', 'municipioIbge.uf')
        .setTypeSelect()
        .setOptions({ url: '/uf', value: 'id', label: 'uf' }),
      new DataTableColumn('Município', 'municipioIbge')
        .setTypeSelect()
        .setOptions({
          url: '/municipioibge?$filter=uf/id eq #parentValue',
          parent: 'municipioIbge.uf',
          value: 'id',
          label: 'nome'
        })
    ];

    this.controls = [

      new DataControl('Razão', 'razaoSocial')
        .setRequired()
        .setMinLength(5)
        .setMaxLength(255),
      new DataControl('Fantasia', 'nomeFantasia')
        .setRequired()
        .setMinLength(5)
        .setMaxLength(255),
      new DataControl('Cnpj', 'cnpj')
        .setRequired()
        .setMinLength(14)
        .setMaxLength(18),
      new DataControl('Telefone', 'telefone'),
      new DataControl('UF', 'municipioIbge.uf')
        .setTypeSelect()
        .setOptions({ url: '/uf', value: 'id', label: 'uf' }),
      new DataControl('Município', 'municipioIbge').setTypeSelect().setOptions({
        url: '/municipioibge?$filter=uf/id eq #parentValue',
        parent: 'municipioIbge.uf',
        value: 'id',
        label: 'nome'
      }),
      new DataControl(
        'Verifica Conciliação',
        'verificaConciliacao'
      ).setTypeCheckBox(),
      new DataControl('Número Inicial NF', 'numInicialNf')
        .setMinLength(1)
        .setMaxLength(255),
      new DataControl('Série NF', 'serieNf'),
      new DataControl('Número NFS', 'numInicialNfs'),
      new DataControl('Serie NFS', 'serieNfs')
        .setMinLength(1)
        .setMaxLength(255),
      new DataControl('RPS', 'rps')
        .setMinLength(0)
        .setMaxLength(255),
      new DataControl('CNAE', 'cnae')
        .setMinLength(1)
        .setMaxLength(255),

      new DataControl('Indicador da incidência tributária', 'codIncTrib')
        .setTypeSelect()
        .setOptions({
          url: '/codinctrib',
          value: 'id',
          label: ['id', ' - ', 'descricao']
        }),

      new DataControl('Indicador de Método de Apropriação de Créditos', 'indAproCred')
        .setTypeSelect()
        .setOptions({
          url: '/indaprocred',
          value: 'id',
          label: ['id', ' - ', 'descricao']
        }),

      new DataControl('Código indicador do Tipo de Contribuição Apurada no Período', 'codTipoCont')
        .setTypeSelect()
        .setOptions({
          url: '/codtipocont',
          value: 'id',
          label: ['id', ' - ', 'descricao']
        }),

      new DataControl('Indicador de Regime Cumulativo', 'indRegCum')
        .setTypeSelect()
        .setOptions({
          url: '/indregcum',
          value: 'id',
          label: ['id', ' - ', 'descricao']
        }),

      new DataControl(
        'Indicador de Método de Apropriação de Créditos',
        'indAproCred'
      )
        .setTypeSelect()
        .setOptions({
          url: '/indaprocred',
          value: 'id',
          label: ['id', ' - ', 'descricao']
        }),

      new DataControl(
        'Código indicador do Tipo de Contribuição Apurada no Período',
        'codTipoCont'
      )
        .setTypeSelect()
        .setOptions({
          url: '/codtipocont',
          value: 'id',
          label: ['id', ' - ', 'descricao']
        }),

      new DataControl('Indicador de Regime Cumulativo', 'indRegCum')
        .setTypeSelect()
        .setOptions({
          url: '/indregcum',
          value: 'id',
          label: ['id', ' - ', 'descricao']
        }),



      new DataControl('Mês inicio', 'mesIni')
        .setTypeSelect()
        .setOptions({
          static: true,
          value: 'value',
          label: 'label',
          values: [{ value: 1, label: 'Janeiro' },
          { value: 2, label: 'Fevereiro' },
          { value: 3, label: 'Março' },
          { value: 4, label: 'Abril' },
          { value: 5, label: 'Maio' },
          { value: 6, label: 'Junho' },
          { value: 7, label: 'julho' },
          { value: 8, label: 'Agosto' },
          { value: 9, label: 'Setembo' },
          { value: 10, label: 'Outubro' },
          { value: 11, label: 'Novembro' },
          { value: 12, label: 'Dezembro' }
          ]
        }),

      new DataControl('Destinatários Vinc. XML', 'destinatariosVincXml'),

      new DataControl(
        'Permite Estoque Negativo',
        'movEstNegativo'
      ).setTypeCheckBox(),

      new DataControl(
        'Centro de custo obrigatório',
        'cadCCusObrigatorio'
      ).setTypeCheckBox(),

      new DataControl(
        'Vincula item com operação',
        'vinculaItemOperacao'
      ).setTypeCheckBox(),

      new DataControl(
        'Calendário automático',
        'calendarioauto'
      ).setTypeCheckBox(),

      new DataControl(
        'Grupo de Estoque obrigatório',
        'grupoEstoque'
      ).setTypeCheckBox(),

      new DataControl(
        'Benefício Obrigatório na RMS',
        'beneficioObrigatorio'
      ).setTypeCheckBox(),

      new DataControl(
        'Segmento Obrigatório',
        'segmentoObrigatorio'
      ).setTypeCheckBox()
    ];
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar';

    this.carregaBotoesToolbarFormNovo();
    this.loadDadosDropdownForm();
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = this.montaJsonFormEnvio();

    this.removeIdNullForm(jsonData)

    this.httpService.wait();
    this.httpService.post('/empresa', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const listasEnviar = this.montaJsonFormEnvio();

    this.removeIdNullForm(listasEnviar)

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/empresa', key, listasEnviar, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.salvarConfiguracaoGrupoEmpresa()
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  loadUfs() {
    this.httpService.wait();
    this.httpService
      .get(`/uf?$select=id,uf&$orderby=uf`)
      .subscribe(
        (retorno) => {
          this.ufs = new Array();
          retorno.value.forEach(item => {
            this.ufs.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService
      .get(`/empresa?$select=razaoSocial,nomeFantasia,cnpj,municipioIbge/uf/id,municipioIbge/id,municipioIbge/nome&$count=true&$skip=0&$orderby=razaoSocial`)
      .subscribe(
        (retorno) => {
          this.registrosEmpresas = new Array();
          retorno.value.forEach(item => {


            this.dblinkedSessionService.empresas.forEach(element => {
              if(item.id === element.id){

                this.registrosEmpresas.push({
                  ...item,
                  ufSigla: this.converteIdEmUf(item.municipioIbge?.uf.id)
                });

              }

            });


          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  // loadTodoProjetos() {
  //   this.httpService.wait()
  //   this.httpService.get(`/todoprojeto?$select=id,nome&$filter=empresa/id eq ` + this.dblinkedSessionService.empresa.id + '&$orderby=nome')
  //     .subscribe(
  //       res => {
  //         this.listaTodoProjetos = new Array()
  //         res.value.forEach(item => {
  //           this.listaTodoProjetos.push({label: item.nome, value: item.id});
  //         })
  //       },
  //       error => {
  //         console.log(error)
  //         this.exibirMensagem({error: true, mensagem: "Erro ao buscar os projetos de workflow, contate o suporte: " + error.message})
  //       },
  //       () => this.httpService.done()
  //     );
  // }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  loadEditarRegistro() {
    this.title = 'Editar';
    this.editarConta = true;
    this.carregaBotoesToolbarFormEditar();
    this.loadDadosDropdownForm();

    this.httpService.wait();
    this.httpService.get(`/empresa(${this.ids[0]})?$select=todoProjeto/id,razaoSocial,nomeFantasia,cnpj,telefone,municipioIbge/uf/id,municipioIbge/uf/uf,municipioIbge/id,municipioIbge/nome,verificaConciliacao,numInicialNf,serieNf,numInicialNfs,serieNfs,rps,cnae,codIncTrib/id,codIncTrib/id,codIncTrib/descricao,indAproCred/id,indAproCred/id,indAproCred/descricao,codTipoCont/id,codTipoCont/id,codTipoCont/descricao,indRegCum/id,indRegCum/id,indRegCum/descricao,indAproCred/id,indAproCred/id,indAproCred/descricao,codTipoCont/id,codTipoCont/id,codTipoCont/descricao,indRegCum/id,indRegCum/id,indRegCum/descricao,mesIni,destinatariosVincXml,movEstNegativo,cadCCusObrigatorio,vinculaItemOperacao,calendarioauto,grupoEstoque,beneficioObrigatorio,segmentoObrigatorio,visualizaFiliais,cadastroGrupoEmpresa,faturaProdutoAutomatico&$count=true`)
      .subscribe(item => {
        this.empresaId = this.ids[0]
        this.razao = item.value.razaoSocial;
        this.fantasia = item.value.nomeFantasia;
        this.cnpj = item.value.cnpj;
        this.telefone = item.value.telefone;
        this.selectedUf = item.value.municipioIbge?.uf?.id; ///pulei
        this.selectedMunicipio = item.value.municipioIbge?.id; ///pulei
        this.verificaConciliacao = item.value.verificaConciliacao;
        this.numeroInicialNf = item.value.numInicialNf;
        this.serieNf = item.value.serieNf;
        this.numeroNfs = item.value.numInicialNfs;
        this.serieNfs = item.value.serieNfs;
        this.rps = item.value.rps;
        this.cnae = item.value.cnae;
        this.selectedIndicadorIncidenciaTributaria = item.value.codIncTrib?.id;
        this.selectedIndicadorApropriacaoCredito = item.value.indAproCred?.id;
        this.selectedCodigoTipoContribuicaoApurada = item.value.codTipoCont?.id;
        this.selectedIndicadorRegimeCumulativo = item.value.indRegCum?.id;
        this.selectedMes = item.value.mesIni;
        this.destinatariosVincXml = item.value.destinatariosVincXml;
        this.permiteEstoqueNegativo = item.value.movEstNegativo;
        this.centroCustoObrigatorio = item.value.cadCCusObrigatorio;
        this.vinculaItemOperacao = item.value.vinculaItemOperacao;
        this.calendarioAutomatico = item.value.calendarioauto;
        this.grupoEstoqueObrigatorio = item.value.grupoEstoque;
        this.beneficioObrigatorioRms = item.value.beneficioObrigatorio;
        this.segmentoObrigatorio = item.value.segmentoObrigatorio;
        this.visualizaFiliais = item.value.visualizaFiliais;
        this.cadastroGrupoEmpresa = item.value.cadastroGrupoEmpresa
        this.faturaProdutoAutomatico = item.value.faturaProdutoAutomatico;

        this.buscarPermissoesGrupoEmpresa()
        this.buscarEmpresasGrupo()

        this.loadMunicipio();
      },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
  }

  loadDadosDropdownForm() {
    // this.title = 'Cadastrar';
    // this.editarConta = true;

    this.loadIndicadorIncidenciaTributaria();
    this.loadIndicadorMetodoApropriacaoCredito();
    this.loadCodigoTipoContribuicaoApurada();
    this.loadIndicadorRegimeCumulativo();
    // this.loadTodoProjetos()
  }

  loadMunicipio() {
    if (this.selectedUf !== undefined && this.selectedUf !== null) {
      this.statusMunicipio = 'CARREGANDO...'
      this.httpService.wait();
      this.httpService
        .get(`/municipioibge?$filter=uf/id eq ${this.selectedUf}&$select=id,nome&$orderby=nome`)
        .subscribe(
          (retorno) => {
            this.statusMunicipio = 'SELECIONE';
            this.municipios = new Array();
            retorno.value.forEach(item => {
              this.municipios.push(item);
            })
          },
          error => this.httpService.handleError(error, () => this.loadMunicipio()),
          () => this.httpService.done()
        );
    }
  }

  private montaJsonFormEnvio() {
    return {
      beneficioObrigatorio: this.beneficioObrigatorioRms,
      cadCCusObrigatorio: this.centroCustoObrigatorio,
      // cadCcusObrigatorioRms: this.centroCustoRMSObrigatorio,
      calendarioauto: this.calendarioAutomatico,
      cnae: this.cnae,
      cnpj: this.cnpj,
      codIncTrib: {
        id: this.selectedIndicadorIncidenciaTributaria
      },
      codTipoCont: {
        id: this.selectedCodigoTipoContribuicaoApurada
      },
      destinatariosVincXml: this.destinatariosVincXml,
      grupoEstoque: this.grupoEstoqueObrigatorio,
      indAproCred: {
        id: this.selectedIndicadorApropriacaoCredito
      },
      indRegCum: {
        id: this.selectedIndicadorRegimeCumulativo
      },
      mesIni: this.selectedMes,
      movEstNegativo: this.permiteEstoqueNegativo,
      municipioIbge: {
        id: this.selectedMunicipio
      },
      nomeFantasia: this.fantasia,
      numInicialNf: this.numeroInicialNf,
      numInicialNfs: this.numeroNfs,
      razaoSocial: this.razao,
      rps: this.rps,
      segmentoObrigatorio: this.segmentoObrigatorio,
      visualizaFiliais: this.visualizaFiliais,
      serieNf: this.serieNf,
      serieNfs: this.serieNfs,
      telefone: this.telefone,
      verificaConciliacao: this.verificaConciliacao,
      vinculaItemOperacao: this.vinculaItemOperacao,
      cadastroGrupoEmpresa: this.cadastroGrupoEmpresa,
      faturaProdutoAutomatico: this.faturaProdutoAutomatico
      // todoProjeto: {
      //   id: this.todoProjetoId
      // }
    }
  }

  private loadIndicadorIncidenciaTributaria() {
    this.httpService.wait();
    this.httpService
      .get(`/codinctrib?$select=id,id,descricao&$orderby=id,descricao`)
      .subscribe(
        (retorno) => {
          this.indicadorIncidenciaTributaria = new Array();
          retorno.value.forEach(item => {
            this.indicadorIncidenciaTributaria.push({
              ...item,
              descricaoCompleta: `${item.id} - ${item.descricao}`
            });
          })
        },
        error => this.httpService.handleError(error, () => this.loadIndicadorIncidenciaTributaria()),
        () => this.httpService.done()
      );
  }

  private loadIndicadorMetodoApropriacaoCredito() {
    this.httpService.wait();
    this.httpService
      .get(`/indaprocred?$select=id,id,descricao&$orderby=id,descricao`)
      .subscribe(
        (retorno) => {
          this.indicadorApropriacaoCredito = new Array();
          retorno.value.forEach(item => {
            this.indicadorApropriacaoCredito.push({
              ...item,
              descricaoCompleta: `${item.id} - ${item.descricao}`
            });
          })
        },
        error => this.httpService.handleError(error, () => this.loadIndicadorMetodoApropriacaoCredito()),
        () => this.httpService.done()
      );
  }

  private loadCodigoTipoContribuicaoApurada() {
    this.httpService.wait();
    this.httpService
      .get(`/codtipocont?$select=id,id,descricao&$orderby=id,descricao`)
      .subscribe(
        (retorno) => {
          this.codigoTipoContribuicaoApurada = new Array();
          retorno.value.forEach(item => {
            this.codigoTipoContribuicaoApurada.push({
              ...item,
              descricaoCompleta: `${item.id} - ${item.descricao}`
            });
          })
        },
        error => this.httpService.handleError(error, () => this.loadCodigoTipoContribuicaoApurada()),
        () => this.httpService.done()
      );
  }

  private loadIndicadorRegimeCumulativo() {
    this.httpService.wait();
    this.httpService
      .get(`/indregcum?$select=id,id,descricao&$orderby=id,descricao`)
      .subscribe(
        (retorno) => {
          this.indicadorRegimeCumulativo = new Array();
          retorno.value.forEach(item => {
            this.indicadorRegimeCumulativo.push({
              ...item,
              descricaoCompleta: `${item.id} - ${item.descricao}`
            });
          })
        },
        error => this.httpService.handleError(error, () => this.loadIndicadorRegimeCumulativo()),
        () => this.httpService.done()
      );
  }

  provisorio() { }

  selectCheckboxContainer(data) {
    setTimeout(() => {
      document.getElementById('checkboxContainer' + data.id).click();
    }, 100);
  };

  selectCheckbox(event: any) {
    this.ids.push(event.data.id);
    this.filtraFuncoesToolbar();
  }

  unselectCheckbox(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    this.filtraFuncoesToolbar();
  }

  filtraFuncoesToolbar() {
    if (this.ids.length === 1) {
      this.toolbarMain.setVisible(this.buttonToolbar.excluir, true);
      this.toolbarMain.setVisible(this.buttonToolbar.editar, true);
      this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, true);
    } else {
      this.toolbarMain.setVisible(this.buttonToolbar.excluir, false);
      this.toolbarMain.setVisible(this.buttonToolbar.editar, false);
      this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, true);
    }
  }

  converteIdEmUf(id) {
    if (id !== null && id !== undefined) {
      let sigla = this.ufs.filter(item => item.id == id);
      return sigla[0].uf;
    }
    return id;
  }

  validacao(): boolean {
    if ((this.razao === null || this.razao === undefined) || this.razao.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Razão é Obrigatório' });
      return true;
    }
    if ((this.fantasia === null || this.fantasia === undefined) || this.fantasia.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Fantasia é Obrigatório' });
      return true;
    }
    if ((this.cnpj === null || this.cnpj === undefined) || this.cnpj.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'CNPJ é Obrigatório' });
      return true;
    }

    return false;
  }


  private getButtonToolbarContent() {
    return {
      voltar: 'btn1',
      excluir: 'btn2',
      editar: 'btn3',
      adicionarNovo: 'btn4',
      salvarNovo: 'btn5',
      salvarAlteracao: 'btn6',
    }
  }

  private getToolbarMainIniContent() {
    return [
      { icon: 'undo', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: false, color: 'default', onClick: () => this.carregar() },
      { icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: false, disabled: false, color: 'red', onClick: () => this.provisorio() },
      { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
      { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.criarNovo() },
      { icon: 'check', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: true, disabled: false, color: 'green', onClick: () => this.salvarNovoRegistro() },
      { icon: 'check', text: 'Salvar', tooltip: 'Salvar Alteração', visible: true, disabled: false, color: 'green', onClick: () => this.salvarAlteracaoRegistro() },
    ];
  }

  private carregaBotoesToolbarFormEditar() {
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, true);

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, true);
  }

  private carregaBotoesToolbarFormNovo() {
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, true);

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, true);
  }

  private carregaBotoesToolbarInicial() {
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.excluir, true);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, true);

    this.toolbarMain.setVisible(this.buttonToolbar.excluir, false);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, true);
  }

  private ocultaBotoesToolbar() {
    this.toolbarMain.setVisible(this.buttonToolbar.voltar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.excluir, false);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, false);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, false);
  }

  private removeIdNullForm(dados) {
    if (dados.codIncTrib.id === null) {
      delete dados['codIncTrib'];
    }
    if (dados.codTipoCont.id === null) {
      delete dados['codTipoCont'];
    }
    if (dados.indAproCred.id === null) {
      delete dados['indAproCred'];
    }
    if (dados.indRegCum.id === null) {
      delete dados['indRegCum'];
    }
    if (dados.municipioIbge.id === null) {
      delete dados['municipioIbge'];
    }
    return dados;
  }

  private replaceVirgulaPorPonto(valor) {
    if (valor !== undefined && valor !== null) {
      valor = String(valor);
      valor = valor.replace(',', '.');
    }

    return valor;
  }

  // private ocultaBotoesToolbar() {
  //   this.toolbarService.ocultar('atualizar');
  //   this.toolbarService.ocultar('check');
  //   this.toolbarService.ocultar('info');
  //   this.toolbarService.ocultar('msg');
  //   this.toolbarService.ocultar('pesquisa');
  //   this.toolbarService.ocultar('download');
  //   this.toolbarService.ocultar('cancel');
  //   this.toolbarService.ocultar('save');
  // this.toolbarService.ocultar('voltar');
  // this.toolbarService.ocultar('export');
  // this.toolbarService.ocultar('print');
  // this.toolbarService.ocultar('remove');
  // this.toolbarService.ocultar('edit');
  // this.toolbarService.ocultar('add');
  // }

  private converteDataParaFormatoBrasileiro(milisegundos: string) {// dd/mm/YYYY
    const d = new Date(milisegundos);

    return this.utilService.dateToStringDate(d);
  }

  private adicionaUmDia = (milisegundos) => milisegundos + 86400000;

  private converteDataParaFullText(data) {
    let novoFormato;

    if (typeof data === 'object') {//GMT... (recebe GMT quando usuário altera o campo)
      novoFormato = new Date(data);
    } else {// dd/mm/YYYY (recebe brasileiro quando campo não foi alterado)
      let [dia, mes, ano] = data.split('/');
      novoFormato = new Date(ano, mes - 1, dia);
    }

    return novoFormato;
  }

  private limpaCamposForm() {
    this.razao = '';
    this.fantasia = '';
    this.cnpj = '';
    this.uf = '';
    this.municipio = '';

    this.selectedUf = null;
    this.selectedMunicipio = null;
    this.selectedIndicadorIncidenciaTributaria = null;
    this.selectedIndicadorApropriacaoCredito = null;
    this.selectedCodigoTipoContribuicaoApurada = null;
    this.selectedIndicadorRegimeCumulativo = null;
    this.selectedMes = null;

    this.verificaConciliacao = false;
    this.permiteEstoqueNegativo = false;
    this.centroCustoObrigatorio = false;
    this.vinculaItemOperacao = false;
    this.calendarioAutomatico = false;
    this.grupoEstoqueObrigatorio = false;
    this.beneficioObrigatorioRms = false;
    this.segmentoObrigatorio = false;
    this.visualizaFiliais = false;
    this.cadastroGrupoEmpresa = false

    this.telefone = null;
    this.numeroInicialNf = null;
    this.serieNf = null;
    this.numeroNfs = null;
    this.serieNfs = null;
    this.rps = null;
    this.cnae = null;
    this.destinatariosVincXml = null;

    this.empresasGrupo = []
    this.empresasGrupoSelecionadas = []
    this.placeholderEmpresas = 'Selecione a(s) empresa(s)'

    this.permiteAlteracaoProdutoServico = false
    this.permiteAlteracaoParticipante = false
    this.permiteAlteracaoContaContabil = false
    this.permiteAlteracaoRegraContabil = false
    this.permiteAlteracaoRegraFiscal = false
    this.permiteAlteracaoOperacaoRegra = false
    this.permiteAlteracaoFormaPagamento = false
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  salvar() {

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.afterSaveSubscription);
    this.sessionService.destroySubscribe(this.afterRemoveSubscription);
  }

  buscarEmpresasGrupo() {
    this.empresasGrupo = [];
    this.empresasGrupoSelecionadas = [];

    this.httpService.wait();
    this.httpService.get(`/custom/cadastro/buscar-empresas-habilitaveis-grupo(${this.empresaId})`)
    .subscribe(
      res => {
        if(res) this.carregarEmpresasSelecionadas(res)
      },
      error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro ao buscar as empresas do grupo' }),
      () => this.httpService.done()
    )
  }

  carregarEmpresasSelecionadas(listaEmpresas) {
   listaEmpresas.forEach(empresa => {
      this.empresasGrupo.push({
        label: empresa.nome_fantasia,
        value: empresa.id
      })
    })

    this.buscarEmpresasConfiguradasClonagem()
  }

  salvarConfiguracaoGrupoEmpresa() {
    if(!this.cadastroGrupoEmpresa) {
      this.empresasGrupoSelecionadas = []
    }

    const payload = {
      empresaId: this.empresaId.toString(),
      usuarioId: this.sessionService.loggedUser.id.toString(),
      empresasGrupo: this.empresasGrupoSelecionadas,
      permiteAlteracaoProdutoServico: this.permiteAlteracaoProdutoServico,
      permiteAlteracaoParticipante: this.permiteAlteracaoParticipante,
      permiteAlteracaoContaContabil: this.permiteAlteracaoContaContabil,
      permiteAlteracaoRegraContabil: this.permiteAlteracaoRegraContabil,
      permiteAlteracaoRegraFiscal: this.permiteAlteracaoRegraFiscal,
      permiteAlteracaoOperacaoRegra: this.permiteAlteracaoOperacaoRegra,
      permiteAlteracaoFormaPagamento: this.permiteAlteracaoFormaPagamento
    }

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/salvar-configuracao-grupo-empresa', payload).subscribe(
      res => { if(res.error) this.messageService.add({ severity: res.severity, key: res.key, summary: res.summary, detail: res.detail }) },
      error => this.httpService.handleError(error, () => this.salvarConfiguracaoGrupoEmpresa()),
      () => this.httpService.done()
    );
  }

  buscarEmpresasConfiguradasClonagem() {
    this.httpService.wait();
    this.httpService.get(`/empresagrupocadastrorelac?$select=empresaRelac/id,empresaRelac/nomeFantasia&$filter=empresa/id eq ` + this.empresaId).subscribe(
      res => {
        let nomesEmpresasConfiguradas = []

        res.value.forEach(value => {
          nomesEmpresasConfiguradas.push(value.empresaRelac.nomeFantasia)
          this.empresasGrupoSelecionadas.push(value.empresaRelac.id)
        })

        if (nomesEmpresasConfiguradas.length > 0) this.placeholderEmpresas = nomesEmpresasConfiguradas.join(', ')
      },
      error => this.httpService.handleError(error, () => this.buscarEmpresasConfiguradasClonagem()),
      () => this.httpService.done()
    )
  }

  buscarPermissoesGrupoEmpresa(){
    this.httpService.wait();
    this.httpService.get(`/empresaparametrogrupo?$select=permiteAlteracaoProdutoServico,permiteAlteracaoParticipante,permiteAlteracaoContaContabil,permiteAlteracaoRegraContabil,permiteAlteracaoRegraFiscal,permiteAlteracaoOperacaoRegra,permiteAlteracaoFormaPagamento&$filter=empresaGestora/id eq ` + this.empresaId)
    .subscribe(
      response => {
        if(response.value === null || response.value === undefined || (Array.isArray(response.value) && response.value.length === 0)) return

        const permissoes = response.value[0]
        this.permiteAlteracaoProdutoServico = permissoes.permiteAlteracaoProdutoServico
        this.permiteAlteracaoParticipante = permissoes.permiteAlteracaoParticipante
        this.permiteAlteracaoContaContabil = permissoes.permiteAlteracaoContaContabil
        this.permiteAlteracaoRegraContabil = permissoes.permiteAlteracaoRegraContabil
        this.permiteAlteracaoRegraFiscal = permissoes.permiteAlteracaoRegraFiscal
        this.permiteAlteracaoOperacaoRegra = permissoes.permiteAlteracaoOperacaoRegra
        this.permiteAlteracaoFormaPagamento = permissoes.permiteAlteracaoFormaPagamento
      },
      error => this.httpService.handleError(error, () => this.buscarPermissoesGrupoEmpresa()),
      () => this.httpService.done()
    )
  }
}
