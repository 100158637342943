import { Component, OnInit } from '@angular/core';
import { Pais } from '../model/pais.model';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { MessageService } from 'primeng/api';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { SessionService } from 'app/shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Subscription } from 'rxjs/Subscription';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';

@Component({
  selector: 'app-pais-ibge',
  templateUrl: './pais-ibge.component.html',
  styleUrls: ['./pais-ibge.component.scss']
})
export class PaisIbgeComponent implements OnInit {
  public registrosPaises: Array<Pais> = new Array();

  private sessionSubscription: Subscription;

  ctrlSave: boolean;
  entityFilter: string = null;

  title = 'País';
  subtitle = "País";

  nome: string;
  codigoBacen: number;

  constructor(private messageService: MessageService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private configService: ConfigService,
    private httpService: HttpService) {
  }

  public toolbarMain = new Toolbar();

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'PAÍS', size: '70%', textAlign: 'center' },
      { name: 'CÓDIGO BACEN', size: '30%', textAlign: 'center' },
    ],
    hasFilter: true,
    hasSort: true,
  })

  ngOnInit() {
    this.toolbarService.hidden = true;

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
      );
  }

  carregar() {
    this.title = 'País';
    this.subtitle = 'País';

    this.loadRegistros();
  }

  async loadRegistros() {
    this.skeletonConfig.startLoad();

    try {
      await this.httpService.get('/pais?$select=id,nome,codigoBacen&$count=true&$orderby=nome')
      .toPromise()
      .then(res => {
        this.registrosPaises = new Array();

        res.value.forEach(item => {
          this.registrosPaises.push(item);
        })
      })
    } catch(err) {
      this.messageService.add({
        severity: 'error',
        key: 'messageLinnks',
        summary: 'Erro',
        detail: 'Houve um erro ao carregar os países! Contate o suporte'
      })
    }

    this.skeletonConfig.endLoad()
    // this.httpService
    //   .get(`/pais?$select=nome,codigoBacen&$count=true&$orderby=nome`)
    //   .subscribe(
    //     (retorno) => {
    //       this.registrosPaises = new Array();
    //       retorno.value.forEach(item => {
    //         this.registrosPaises.push(item);
    //       })
    //     },
    //     error => this.httpService.handleError(error, () => this.carregar()),
    //     () => this.skeletonConfig.endLoad()
    //   );
  }

  provisorio(){}

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  // get loading(): boolean {
  //   return this.httpService.loading;
  // }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
