import { Empresa } from '../../cadastro/model/empresa.model';

export class CadProj {
  id: number;
  label: string
  codProj: string;
  descrProj: string;
  empresa: Empresa = new Empresa();
  dtInicial: Date;
  dtFinal: Date;
  ativo: boolean;
  lei_do_bem: boolean;

  constructor(cadproj?: CadProj | number) {
    if (cadproj !== undefined && cadproj !== null) {
      if (typeof cadproj === 'number') {
        this.id = cadproj;
      } else {
        this.id = cadproj.id;
        this.codProj = cadproj.codProj;
        this.descrProj = cadproj.descrProj;
        this.empresa = cadproj.empresa || new Empresa();
        this.dtInicial = cadproj.dtInicial;
        this.dtFinal = cadproj.dtFinal;
        this.ativo = cadproj.ativo;
        this.lei_do_bem = cadproj.lei_do_bem;
      }
    }
  }
}
