import { AuthUser } from 'app/proof/model/auth-user.model';
import { Depto } from 'app/cadastro/model/depto.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TodoProjetoUsuarios } from './todo-projeto-usuarios.model';

export class TodoProjeto {
  id: number;
  empresa: Empresa;
  nome: string;
  descricao: string;
  corBg: string;
  depto: Depto;
  usuario: AuthUser;
  arquivado: boolean;
  key: string;
  participantes: Array<TodoProjetoUsuarios>;
  participantesAdm: Array<TodoProjetoUsuarios>;
  addParticipantes: boolean;
  blockMovVertical: boolean;
  addParticipantesOld: boolean;

  constructor(todoProjeto?: TodoProjeto | number) {
    if (todoProjeto !== undefined && todoProjeto !== null) {
      if (typeof todoProjeto === 'number') {
        this.id = todoProjeto;
      } else {
        this.id = todoProjeto.id;
        this.empresa = todoProjeto.empresa;
        this.nome = todoProjeto.nome;
        this.descricao = todoProjeto.descricao;
        this.corBg = todoProjeto.corBg;
        this.depto = todoProjeto.depto;
        this.usuario = todoProjeto.usuario;
        this.arquivado = todoProjeto.arquivado;
        this.key = todoProjeto.key;
        this.participantes = todoProjeto.participantes;
        this.participantesAdm = todoProjeto.participantesAdm;
        this.addParticipantes = todoProjeto.addParticipantes;
        this.blockMovVertical = todoProjeto.blockMovVertical;
        this.addParticipantesOld = todoProjeto.addParticipantesOld;
      }
    }
  }
}
