import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { RegraRelac } from 'app/cadastro/model/regra-relac.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';

@Component({
  selector: 'app-regra-relac',
  templateUrl: './regra-relac.component.html',
  styleUrls: ['./regra-relac.component.scss'],
  providers: [CrudService]
})
export class RegraRelacComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public regrarelac: RegraRelac = new RegraRelac();

  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;

  entityFilter: string = null;

  constructor(private crudService: CrudService,
              private httpService: HttpService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  ngOnInit() {
    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
                                                                         () => { this.empresaChanged(); });

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.columns = [
        new DataTableColumn('GRUPO REGRA', 'grupoRegra')
          .setTypeSelect()
          .setOptions({ url: '/gruporegra', value: 'id', label: 'descricao' }),
        new DataTableColumn('REGRA', 'regra')
          .setTypeSelect()
          .setOptions({ url: '/regra', value: 'id', label: 'nome' })

      ];


      this.controls = [
        new DataControl('Grupo Regra', 'grupoRegra')
        .setTypeSelect()
        .setOptions({ url: '/gruporegra', value: 'id', label: 'descricao' }),
        new DataControl('Regra', 'regra')
          .setTypeSelect()
          .setOptions({ url: '/regra', value: 'id', label: ['cod', ' - ', 'nome'] })
      ];
    }
  }
}
