import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { AppComponent } from '../../app.component';
import { TitleService } from '../../shared/services/title.service';
import { SessionService } from '../../shared/services/session.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';
declare var $: any;

@Component({
  selector: 'app-render-report-gerencial',
  templateUrl: './render-relatorio-gerencial.component.html',
  styleUrls: ['./render-relatorio-gerencial.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class WorkstatsRenderRelatorioGerencial implements OnInit, OnDestroy {

  currentStateTableView = 'initial';
  changeStateTableView() {
    this.currentStateTableView = this.currentStateTableView === 'initial' ? 'final' : 'initial';
  }

  @Input() keyEmpresa: 'empresaid';
  @Input() keyRelat: 'relatid';
  @Input() dataRef: 'dataref';
  @Input() keyPg: 'pgid';

  empresaId: number = null;
  relatId: number = null;
  pgId: number = null;
  private empresaSubscription: Subscription;
  private initSubscription: Subscription;
  title: string = "";

  diccontas: Array<any> = new Array();
  lipgc: Array<any> = new Array();
  colunas: Array<any> = new Array();
  colunasDetalhe: Array<any> = new Array();
  contasGerenciais: Array<any>;
  limesstr: Array<string> = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
  configName: string;
  hideNoValueContas: boolean;
  modalDetalhe: boolean = false;
  detalheList: Array<any> = new Array();
  tableDetalhe: Array<any> = new Array();
  niveis: Array<any> = new Array();
  contaselecionada: string;

  constructor(private titleService: TitleService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private appComponent: AppComponent,
    private configService: ConfigService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private httpClient: HttpClient,
    private messageService: MessageService) { }

  ngOnInit() {

    /*HIDE SIDEBAR FULLSCREEN (DO NOT REMOVE)*/
    $(document).ready(() => {
      $(".btn-full").click(
        function () {
          $(".mat-drawer-side").toggleClass('d-none');
        }
      );
    });

    this.toolbarService.clear();
    this.titleService.title = "Relatório Gerencial";
    this.title = 'RELATÓRIO GERENCIAL';

    if (this.configService.production) {
      this.appComponent._displaySideNav = false;
    } else {
      setTimeout(() => { this.appComponent._displaySideNav = false; });
    }

    this.activatedRoute.params.subscribe(params => {
      this.empresaId = parseInt(params['empresaid'], 10);
      this.relatId = params['relatid'];
      this.dataRef = params['dataref'];
      this.pgId = params['pgid'];
      this.configName = "";
      this.contasGerenciais = new Array();
      this.hideNoValueContas = false;
      this.detalheList = new Array();
      this.tableDetalhe = new Array();
      this.modalDetalhe = false;

      if (!this.initSubscription) {
        this.initSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
          () => { this.atualizar(); });
      } else {
        this.atualizar();
      }
    });
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.sessionService.destroySubscribe(this.initSubscription);
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  atualizar() {
    this.diccontas = new Array();

    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.sessionService.destroySubscribe(this.initSubscription);
      this.buscarGerencialContas();
      this.getPlanoGerencialContas();
      this.getConfigInfos();
    }
  }

  getPlanoGerencialContas() {
    // Buscar as contas gerenciais
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'planoGerencialId': this.pgId.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/plano_gerencial_contas', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
          } else {
            this.lipgc = new Array();

            for (let line of res["mensagem"][0]) {
              this.lipgc.push(line);
            }
          }

          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível carregar o plano gerencial." });
          console.log(err);
          this.httpService.done();
        }
      );

  }
  getDicContas(pgId: number) {
    // Buscar as contas gerenciais
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.empresaId.toString(),
        'configRelatId': this.relatId.toString(),
        'planoGerencialId': pgId.toString(),
        'dayRef': this.dataRef.split("-")[0],
        'monthRef': this.dataRef.split("-")[1],
        'yearRef': this.dataRef.split("-")[2]
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/config-relat-column', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();
          } else {
            this.diccontas = new Array();
            this.colunas = new Array();

            this.colunas.push({
              config_relat_id: this.relatId,
              label: "Contas",
              indice: 0,
              tipo: 1,
              qtdDecimais: 2
            });

            for (let line of res["columns"]) {
              // Tratar o label
              if (line["label"].includes("{") && line["label"].includes("}")) {
                var separador: Array<string> = ["{", "}"];
                var lilabel: Array<string> = line["label"].split(new RegExp(separador.join("|"), 'g'));

                for (let i in lilabel) {
                  if (lilabel[i].includes("DATA.day")) {
                    lilabel[i] = eval(lilabel[i].replace("DATA.day", this.dataRef.split("-")[0])).toString();

                  } else if (lilabel[i].includes("DATA.month")) {
                    lilabel[i] = this.limesstr[Number(eval(lilabel[i].replace("DATA.month", this.dataRef.split("-")[1]))) - 1];

                  } else if (lilabel[i].includes("DATA.year")) {
                    lilabel[i] = eval(lilabel[i].replace("DATA.year", this.dataRef.split("-")[2])).toString();
                  }
                }

                line["label"] = lilabel.join(" ")
              }

              // Adicionar a coluna
              this.colunas.push(line);
            }

            this.calculateColumns();
          }

          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível buscar os valores das contas." });
          console.log(err);
          this.httpService.done();
        });
  }

  buscarGerencialContas() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'planoGerencialId': this.pgId.toString()
      })
    }

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/plano_gerencial_contas', httpOptions)
      .subscribe(res => {
        this.contasGerenciais = new Array();

        for (let conta of res["mensagem"][0]) {
          this.contasGerenciais.push({
            'id': conta.id,
            'cod_cta': conta.cod_cta,
            'sql': conta.sql,
            'nivel': conta.nivel,
            'linha_acima': conta.linha_acima,
            'linha_abaixo': conta.linha_abaixo,
            'linha_negrito': conta.linha_negrito,
            'linha_cor': conta.linha_cor,
            'fonte_cor': conta.fonte_cor,
            'a': conta.descr_cta,
            'ind_cta': conta.ind_cta,
            'is_porcentagem': conta.is_porcentagem
          });
        }

        this.getDicContas(this.pgId);
        this.httpService.done();
      },
        err => {
          console.log(err);
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível buscar as contas do plano gerencial." });
          this.contasGerenciais = new Array();
          this.httpService.done();
        });
  }

  getConfigInfos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'configid': this.relatId.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            return;
          } else {
            this.configName = res["mensagem"][0];
            this.hideNoValueContas = res["mensagem"][1];
          }

          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível carregar as configurações de colunas." });
          console.log(err)
          this.httpService.done()
        }
      );
  }

  gerarRelatorioGerencialExcel() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    const infos = {
      list_infos: [{ "empresa_id": this.dblinkedSessionService.empresa.id, "dt_ref": [this.dataRef.split("-")[0], this.dataRef.split("-")[1], this.dataRef.split("-")[2]], "config_id": this.relatId.toString() }],
      is_composta: false,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', infos, httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();

          } else {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0',
                'userid': this.sessionService.loggedUser.id.toString()
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', httpOptions2)
              .subscribe(
                (res: Blob) => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);
                  let fileName = "Relatório gerencial.xlsx";
                  link.href = url;
                  link.download = fileName;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o relatório." });
                  console.log(err);
                  this.httpService.done();
                });
          }
        },
        err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível gerar o relatório gerencial." });
          console.log(err);
          this.httpService.done();
        });
  }

  calculateColumns() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    const payload = {
      empresaid: this.dblinkedSessionService.empresa.id,
      configRelatId: this.relatId,
      planoGerencialId: this.pgId,
      dateRef: [1, this.dataRef.split("-")[1], this.dataRef.split("-")[2]]
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/config-relat-column-values', payload, httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();
            return;
          }

          for (let conta of this.contasGerenciais) {
            for (let col of this.colunas) {
              if (col.indice === 0 || col.tipo === 2) continue;
              if (col.tipo == 3 && col.ispercent && conta.is_porcentagem && !col.isformulapgc) {
                let letras = col.formula.split(/[()+*-/]|[^a-z]/i).filter(i => i != '');
                conta[this.alfabeto(col.indice)] = letras.length == 2 ? res['diccontas'][conta['id']][this.alfabeto(col.indice)] : "-";
                continue;
              }

              conta[this.alfabeto(col.indice)] = res['diccontas'][conta['id']][this.alfabeto(col.indice)];
            }
          }

          for (let formula of res['formulas_analise']) {
            let conta = this.contasGerenciais.find(c => c.id === formula.pgc_id);

            conta[this.alfabeto(formula.column_indice) + "-pgc"] = {};
            conta[this.alfabeto(formula.column_indice) + "-pgc"]['isFormulaPgc'] = true;
            conta[this.alfabeto(formula.column_indice) + "-pgc"]['isPercent'] = formula.ispercent;
            conta[this.alfabeto(formula.column_indice) + "-pgc"]['isValor'] = formula.isvalor;
          }

          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível buscar os valores das colunas." });
          this.httpService.done();
        });
  }

  openModalDetalhe(conta) {
    if (conta.ind_cta !== 'A') return;

    let alreadyReseted = false;
    this.contaselecionada = conta['a'];
    this.colunasDetalhe = new Array();
    if (!alreadyReseted) {
      this.tableDetalhe = new Array();
      alreadyReseted = true;
    }

    for (let coluna of this.colunas) {
      if (coluna.indice !== 0 && !coluna.isformulapgc) this.colunasDetalhe.push(coluna);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    const payload = {
      configRelatId: this.relatId,
      ctaid: conta.id,
      planoGerencialId: this.pgId,
      dateRef: [1, this.dataRef.split("-")[1], this.dataRef.split("-")[2]]
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/get-detalhe-conta', payload, httpOptions)
      .subscribe(
        res => {
          this.niveis = new Array();
          if (!alreadyReseted) {
            this.tableDetalhe = new Array();
            alreadyReseted = true;
          }
          this.niveis = res["niveis"];

          for (let key in res["dicdetalhes"]) {
            let info = key.split(";$;");
            let detalhe = {};

            detalhe['a'] = conta['a'];
            for (let i in this.niveis) {
              detalhe[this.niveis[i].tipo] = info[i];
            }

            for (let col in res["dicdetalhes"][key][conta.id]) {
              detalhe[col] = res["dicdetalhes"][key][conta.id][col];
            }

            this.tableDetalhe.push(detalhe);
          }

          this.exibirMensagem(res);
          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível buscar os valores das colunas." });
          this.httpService.done();
        });

    this.modalDetalhe = true;
  }

  isHidden(conta: any) {
    let hidden = true;

    for (let i in this.colunas) {
      let indice = this.alfabeto(this.colunas[i].indice);

      if ((conta[indice] != null && (conta[indice] > 0 || conta[indice] < 0)) || conta.ind_cta === "N") {
        hidden = false;
        break;
      }
    }

    return hidden;
  }

  alfabeto(index) {
    //revisar esse metodo
    let ordA = 'a'.charCodeAt(0);
    let ordZ = 'z'.charCodeAt(0);
    let len = ordZ - ordA + 1;

    let col = "";

    while (index >= 0) {
      col = String.fromCharCode(index % len + ordA) + col;
      index = Math.floor(index / len) - 1;
    }

    return col;
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({
        severity: 'success', key: 'messageLinnks',
        summary: 'MSG:',
        detail: value.mensagem
      });

    } else {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: value.mensagem
      });
    }
  }

  hideMessage() {

  }

  /*Show Hide Card Table View*/
  isCardTableView: boolean = true;
  ShowHIdeTableView() {
    this.isCardTableView = !this.isCardTableView;
  }

  /*FullScreen Table*/
  fullTable: boolean = false;
  fullScreenTable() {
    this.fullTable = !this.fullTable;
  }

  getStyle(template, col, conta) {
    if (template === 'header') {
      return { 'background-color': col.iscorfundocabecalho ? col.corfundo : '', 'color': col.iscortextocabecalho ? col.cortexto : '', 'width': col.indice === 0 ? '270px' : '50px' };
    }

    return {
      'background-color': conta['linha_cor'] != null ? conta['linha_cor'] : col.iscorfundocoluna ? col.corfundo : '',
      'color': conta['fonte_cor'] != null ? conta['fonte_cor'] : col.iscortextocoluna ? col.cortexto : '',
      'font-weight': conta['linha_negrito'] ? 'bold' : col.iscolunanegrito ? 'bold' : '',
      'width': col.indice === 0 ? '270px' : '50px'
    }

  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  dataRefToDate(dateRef: string) {
    //retorna o dataRef instanciado como Date
    let vetDate = dateRef.split("-");

    return new Date(Number(vetDate[2]), Number(vetDate[1]) - 1, Number(vetDate[0]));
  }

  toPercent(conta, ispercent, value) {
    return conta.is_porcentagem && !ispercent ? value * 100 : value;
  }
}
