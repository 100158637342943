import { AuthUser } from 'app/proof/model/auth-user.model';
import { Empresa } from 'app/cadastro/model/empresa.model';

export class RespCadItem {

  id: number;
  empresa: Empresa;
  authUser: AuthUser;
  responsaveis: string;

constructor(respCadItem?: RespCadItem | number) {
    if (respCadItem !== undefined && respCadItem !== null) {
      if (typeof respCadItem === 'number') {
        this.id = respCadItem;
      } else {
        this.id = respCadItem.id;
        this.empresa = respCadItem.empresa;
        this.authUser = respCadItem.authUser;
        this.responsaveis = respCadItem.responsaveis;

      }
    }
  }
}
