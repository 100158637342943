export class Departamento {
    id: number;
    cod: string;
    descricao: string;

    constructor(departamento?: Departamento | number) {
      if (departamento !== undefined && departamento !== null) {
        if (typeof departamento === 'number') {
          this.id = departamento;
        } else {
          this.id = departamento.id;
          this.cod = departamento.cod;
          this.descricao = departamento.descricao;
        }
      }
    }
  }
