import { Component, OnInit, OnDestroy } from '@angular/core';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from '../../shared/services/session.service';
import { ConfigService } from '../../shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as Highcharts from 'highcharts/highstock';
import { Message, MessageService } from 'primeng/api';
declare var $: any;

@Component({
  selector: "app-dashboard-new4",
  templateUrl: "./dashboard-new4.component.html",
  styleUrls: ["./dashboard-new4.component.scss"],
  providers: [CrudService],
  animations: [
    trigger("changeDivSize", [
      state("initial", style({})),
      state(
        "final",
        style({
          opacity: "0",
          height: "0",
          padding: "0px 15px",
        })
      ),
      transition("initial=>final", animate("200ms")),
      transition("final=>initial", animate("200ms")),
    ]),
  ],
})
export class DashboardNewComponent4 implements OnInit, OnDestroy {
  DefInterDate: Date;
  DefFinalDate: Date;
  msgs: Message[] = null;
  saldoFinalPeriodAtu: number = 0;
  saldoInitPeriodAtu: number = 0;
  saldoInitPeriodHisto: number = 0;
  saldoFinalPeriodHisto: number = 0;
  saldoInitPeriodDiff: number = 0;
  saldoFinalPeriodDiff: number = 0;
  movPeriodAtu: number = 0;
  periodoInitAtu: string = "";
  periodoFinAtu: string = "";
  isWeekVision: boolean = true;
  isMonthVision: boolean = false;
  isWeekForPrint: boolean = false;
  isMonthForPrint: boolean = false;
  listSemanas: Array<any> = new Array();
  listMeses: Array<any> = new Array();
  weekSelected: number = null;
  monthSelected: number = null;
  detalheFluxo: boolean = false;
  movTable: Array<any> = new Array();
  listaContas: Array<any> = new Array();
  fluxoHistorico: Array<any> = new Array();
  detalheFluxoCaixa: any;
  dt_historico: Date = new Date();
  usr_historico: string = "";
  fullscreen: boolean;
  serieschart2: Array<any> = new Array();
  mediachart: Array<any> = new Array();
  categorias: Array<any> = new Array();
  titulo = "";
  fluxoCaixaAtual: any[];
  fluxoCaixaDiff: any[];
  listEmpresas: Array<any> = [];
  empresasSelecionadas: Array<number> = [];
  statusSummary: boolean = true;
  statusFolha: boolean = false;
  statusOperCambio: boolean = false;
  statusSaldoprevisto: boolean = true;
  statusVariacaoanterior: boolean = false;
  statusFluxoHistorico: boolean = false;
  statusEntrada: boolean = true;
  statusOntem: boolean = false;
  statusSemanal: boolean = false;
  statusMensal: boolean = false;
  statusAnual: boolean = false;
  saldoInitAno: string = "0";
  cashRealizadoYTD: string = "0";
  saldoFinAno: string = "0";
  cashRealizado: string = "0";
  dolarIPG: number = 0;
  dolarInitAno: number = 0;
  dolarHoje: number = 0;
  dolarMedio: number = 0;
  rendaRealYTD: string = "0";
  custoDespYTD: string = "0";
  capexYTD: string = "0";
  intangivelYTD: string = "0";
  miniCardsValues: Object = {
    saldoInitAnoAnteriores: [],
    cashRealizadoYTDAnteriores: [],
    saldoFinAnoAnteriores: [],
    cashRealizadoAnteriores: [],
    dolarIPGAnteriores: [],
    dolarInitAnoAnteriores: [],
    dolarHojeAnteriores: [],
    dolarMedioAnteriores: [],
    rendaRealYTDAnteriores: [],
    custoDespYTDAnteriores: [],
    capexYTDAnteriores: [],
    intangivelYTDAnteriores: [],
  };
  bigNumberAnteriores: Array<number> = [];
  selectedMiniCard: string = "";
  dadosAnteriores: boolean = false;
  statusLink1: boolean = true;
  statusLink2: boolean = false;
  modalMiniCards: boolean;
  modalAnexos: boolean;
  currentStateDash1 = "initial";
  currentStateDash2 = "initial";
  currentStateTable1 = "initial";
  currentStateTable2 = "initial";
  currentStateCashView = "initial";
  box1: string;
  box1Valor: string;
  box2: string;
  box2Valor: string;
  box3: string;
  box3Valor: string;
  box4: string;
  box4Valor: string;
  box5: string;
  box5Valor: string;
  infosDashBox1: Array<any>;
  infosDashBox2: Array<any>;
  infosDashBox3: Array<any>;
  infosDashBox4: Array<any>;
  infosDashBox5: Array<any>;
  boxSelecionado: number = 1;
  tituloSelecionado1 = "";
  legendaDasboard2: Array<any>;
  serieschart: Array<any>;
  titulochart1 = "";
  titulochart2 = "";
  visualizacaoId: number = 0;
  transPendentes: any[];
  somaTransPendentes: number;
  somaWeekly: number;
  highestPayments: any[];
  summary: any[];
  operacaoCambio: any[];
  operacaoFolha: any[];
  legendaDiversos: Array<any>;
  valorDiversos: Array<any>;
  legendaFolha: Array<any>;
  valorFolha: Array<any>;
  legendaCambio: Array<any>;
  valorCambio: Array<any>;
  visaoYtd: boolean = true;
  visao12m: boolean = false;
  visaoMes: boolean = false;
  criacaoComentario: boolean = false;
  modalCommentarios: boolean = false;
  edicaoComentario: boolean = false;
  comentario: string = "";
  rowComments: Array<any>;
  rowSelected: any;
  idComentario: number;
  detalheBankApprovals: boolean = false;

  //export excel
  colstransPendentes: any[];
  colssummary: any[];
  colsoperacaoFolha: any[];
  colsoperacaoCambio: any[];


  contadorLinha: number = 0;

  public optionsDescFroola: Object = {
    placeholderText: "Adicionar comentário...",
    charCounterCount: false,
    pluginsEnabled: [
      "align",
      "charCounter",
      "codeBeautifier",
      "codeView",
      "colors",
      "draggable",
      "embedly",
      "emoticons",
      "entities",
      "file",
      "fontAwesome",
      "fontFamily",
      "fontSize",
      "fullscreen",
      "image",
      "imageTUI",
      "imageManager",
      "inlineStyle",
      "inlineClass",
      "lineBreaker",
      "lineHeight",
      "link",
      "lists",
      "paragraphFormat",
      "table",
      "url",
      "video",
      "wordPaste",
    ], // 'quickInsert',
    toolbarButtons: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    toolbarButtonsXS: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    toolbarButtonsSM: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    toolbarButtonsMD: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    imageUploadMethod: "POST",
    key: "LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==",
    events: {
      "froalaEditor.initialized": function () { },
    },
  };

  private sessionSubscription: Subscription;

  constructor(
    private titleService: TitleService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        for (let i in this.miniCardsValues) {
          this.miniCardsValues[i] = [
            { ano: new Date().getFullYear(), value: 0, variacao: null },
            { ano: new Date().getFullYear() - 1, value: 0, variacao: null },
            { ano: new Date().getFullYear() - 2, value: 0, variacao: null },
          ];
        }
        /*HIDE SIDEBAR FULLSCREEN (DO NOT REMOVE)*/
        $(document).ready(() => {
          $(".btn-full").click(function () {
            $(".mat-drawer-side").toggleClass("d-none");
          });

          $("body")
            .off("click.dropdown touchstart.dropdown.data-api", ".dropdown")
            .on(
              "click.dropdown touchstart.dropdown.data-api",
              ".dropdown form",
              function (e) {
                e.stopPropagation();
              }
            );
        });

        //Define as datas atuais
        let today: Date = this.getDateOfISOWeek(
          this.getWeekNumber(new Date()),
          new Date().getFullYear()
        );
        this.isWeekForPrint = true;

        this.DefInterDate = today;
        this.DefFinalDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 7
        );

        this.getWeekList(new Date());
        this.getMonthList();

        this.dblinkedSessionService.showPeriodsWithNextMonths(
          true,
          true,
          24,
          1,
          2
        );
        Promise.resolve(null).then(() => {
          this.toolbarService.clear();
          this.weekSelected = this.getWeekNumber(new Date());
        });
        this.titleService.title = "Dashboard Financeiro";

        if (
          this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected &&
          this.dblinkedSessionService.periodo.month
        ) {
          //Minicards (big numbers)
          this.dadosAnteriores = false;
          this.getBigNumbers();
          this.getCambioNumbers();
          this.getContabilNumbers();
          this.getListEmpresas();

          //Dashboard2 (financial resume)
          this.atualizarDash2(1);

          //Weekly bank approvals
          this.buscarInfosWeekly("pend");

          //Weekly transactions
          this.buscarInfosWeekly("diversos");
          this.buscarInfosWeekly("operacaocambio");
          this.buscarInfosWeekly("operacaofolha");
          this.buscarInfosChart();

          //Fluxo de caixa
          this.getFluxoCaixa();
        }
      }
    );

    this.colstransPendentes = [
      { field: 'operacao', header: 'OPERAÇÃO' },
      { field: 'quantidade', header: 'Nº OPERAÇÕES' },
      { field: 'banco', header: 'BANCO' },
      { field: 'status', header: 'STATUS' },
      { field: 'valor', header: 'VALOR(BRL)' }
    ];

    this.colssummary = [
      { field: 'operacao', header: 'OPERAÇÃO' },
      { field: 'fantasia', header: 'NOME' },
      { field: 'historico', header: 'HISTÓRICO' },
      { field: 'codigoControle', header: 'COD CONTROLE' },
      { field: 'status', header: 'STATUS' },
      { field: 'valor', header: 'VALOR(BRL)' }
    ];

    this.colsoperacaoFolha = [
      { field: 'operacao', header: 'OPERAÇÃO' },
      { field: 'fantasia', header: 'NOME' },
      { field: 'historico', header: 'HISTÓRICO' },
      { field: 'codigoControle', header: 'COD CONTROLE' },
      { field: 'status', header: 'STATUS' },
      { field: 'valor', header: 'VALOR(BRL)' }
    ];

    this.colsoperacaoCambio = [
      { field: 'operacao', header: 'OPERAÇÃO' },
      { field: 'fantasia', header: 'NOME' },
      { field: 'historico', header: 'HISTÓRICO' },
      { field: 'codigoControle', header: 'COD CONTROLE' },
      { field: 'status', header: 'STATUS' },
      { field: 'valor', header: 'VALOR(BRL)' }
    ];

    this.contadorLinha = 0;
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  getInfosDash2(i: number) {
    let empresaSelect: Array<number> = [];

    if (
      this.empresasSelecionadas === undefined ||
      this.empresasSelecionadas === null ||
      this.empresasSelecionadas.length === 0
    ) {
      empresaSelect.push(this.dblinkedSessionService.empresa.id);
    } else {
      empresaSelect = this.empresasSelecionadas;
    }

    let anos: Array<number> = [];
    anos.push(new Date().getFullYear());
    anos.push(new Date().getFullYear() - 1);
    anos.push(new Date().getFullYear() - 2);

    let mesfinal = new Date(
      this.dblinkedSessionService.periodo.year.value,
      this.dblinkedSessionService.periodo.month.value - 1,
      1
    );

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        posicao: i.toString(),
        liempresaid: empresaSelect.toString(),
        anos: anos.toString(),
        visu: this.visualizacaoId.toString(),
        mesfinal: mesfinal.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload +
        "dashboard/financeiro/get-info-dash",
        httpOptions
      )
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res);
          } else {
            if (this.visualizacaoId === 0) {
              this.visualizacaoId = res["mensagem"]["visuid"];
            }

            if (this.legendaDasboard2.length === 0) {
              this.legendaDasboard2 = res["mensagem"]["data"]["meses"];
            }

            this.extractInfoDash2(i, res["mensagem"]);
          }
          this.httpService.done();
        },
        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  extractInfoDash2(i: number, ret: any) {
    if (i === 1) {
      this.box1 = ret["box"];
      this.box1Valor = ret["data"]["valor"];
      this.infosDashBox1 = ret["data"];
    } else if (i === 2) {
      this.box2 = ret["box"];
      this.box2Valor = ret["data"]["valor"];
      this.infosDashBox2 = ret["data"];
    } else if (i === 3) {
      this.box3 = ret["box"];
      this.box3Valor = ret["data"]["valor"];
      this.infosDashBox3 = ret["data"];
    } else if (i === 4) {
      this.box4 = ret["box"];
      this.box4Valor = ret["data"]["valor"];
      this.infosDashBox4 = ret["data"];
    } else {
      this.box5 = ret["box"];
      this.box5Valor = ret["data"]["valor"];
      this.infosDashBox5 = ret["data"];
    }

    if (i === this.boxSelecionado) {
      this.loadChart4(this.boxSelecionado);
    }
  }

  getListEmpresas() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "cadastro/getgroupempresas/" + this.dblinkedSessionService.empresa.id + "-" + this.sessionService.loggedUser.id, httpOptions)
      .subscribe(
        (res) => {
          this.listEmpresas = [];
          for (let line of res["data"]) {
            this.listEmpresas.push({ value: line["id"], label: line["razao"] });
          }
          this.empresasSelecionadas = [];
          this.empresasSelecionadas.push(
            this.dblinkedSessionService.empresa.id
          );
          this.httpService.done();
        },
        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  changeStateDash1() {
    this.currentStateDash1 =
      this.currentStateDash1 === "initial" ? "final" : "initial";
  }

  changeStateDash2() {
    this.currentStateDash2 =
      this.currentStateDash2 === "initial" ? "final" : "initial";
  }

  changeStateTable1() {
    this.currentStateTable1 =
      this.currentStateTable1 === "initial" ? "final" : "initial";
  }

  changeStateTable2() {
    this.currentStateTable2 =
      this.currentStateTable2 === "initial" ? "final" : "initial";
  }

  changeStateCashView() {
    this.currentStateCashView =
      this.currentStateCashView === "initial" ? "final" : "initial";
  }

  labelFormater(value) {
    if (value) {
      if (value / 1e6 >= 1) {
        return `${(value / 1e6).toFixed(1)}M`;
      } else if (value / 1e3 >= 1) return `${(value / 1e3).toFixed(1)}K`;
      return `${value.toFixed()}`;
    }
    return "";
  }

  setClassCard(value) {
    if (value >= 0) {
      return "card-blue";
    }
    return "card-red";
  }

  onCommentSelect(row) {
    console.log(row);
    this.comentario = null;
    this.rowSelected = row;
    if (row.comentarios === "") {
      this.criacaoComentario = true;
      this.edicaoComentario = false;
    } else {
      this.criacaoComentario = false;
      this.edicaoComentario = true;
      this.comentario = row.comentarios;
    }

    //Header da requisição
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        id: row.historic_id.toString(),
      }),
    };

    let result: any;
    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload +
        "dashboard/fluxocaixa/fluxo_historico_comment",
        httpOptions
      )
      .subscribe(
        (res) => {
          // Get the result
          result = res;
          this.httpService.done();

          //Display modal
          this.rowComments = new Array();
          this.modalCommentarios = true;
          for (let c of result["mensagem"]) {
            this.rowComments.push(c);
          }
        },
        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getStringFromHtml(text) {
    const html = text;
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  toggleAddComentario() {
    this.criacaoComentario = true;
    this.edicaoComentario = false;
    this.comentario = "";
  }

  toggleEditComentario(c) {
    this.criacaoComentario = false;
    this.edicaoComentario = true;
    this.comentario = c.descricao;
    this.idComentario = c.id;
  }

  deleteComment(c) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        id: c.id.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .delete(
        this.configService.defaultUrlApiUpload +
        "dashboard/fluxocaixa/fluxo_historico_comment",
        httpOptions
      )
      .subscribe(
        (res) => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.onCommentSelect(this.rowSelected);
          this.getFluxoCaixaHistorico();
        },
        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  criarComentario() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        userid: this.sessionService.loggedUser.id.toString(),
        comentario: this.comentario.toString(),
        fluxoid: this.rowSelected.historic_id.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .post(
        this.configService.defaultUrlApiUpload +
        "dashboard/fluxocaixa/fluxo_historico_comment",
        null,
        httpOptions
      )
      .subscribe(
        (res) => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.criacaoComentario = false;
          this.comentario = null;
          this.onCommentSelect(this.rowSelected);
          this.getFluxoCaixaHistorico();
        },

        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  editarComentario() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        userid: this.sessionService.loggedUser.id.toString(),
        comentario: this.comentario,
        id: this.idComentario.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .put(
        this.configService.defaultUrlApiUpload +
        "dashboard/fluxocaixa/fluxo_historico_comment",
        null,
        httpOptions
      )
      .subscribe(
        (res) => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.edicaoComentario = false;
          this.comentario = null;
          this.onCommentSelect(this.rowSelected);
          this.getFluxoCaixaHistorico();
        },

        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  isCardDash1: boolean = true;
  ShowHIdeCardDash1() {
    this.isCardDash1 = !this.isCardDash1;
  }

  isCardDash2: boolean = true;
  ShowHIdeCardDash2() {
    this.isCardDash2 = !this.isCardDash2;
  }

  fullDash2: boolean = false;
  fullScreenDash2() {
    this.fullDash2 = !this.fullDash2;
  }

  isCardTable1: boolean = true;
  ShowHIdeCardTable1() {
    this.isCardTable1 = !this.isCardTable1;
  }

  isCardTable2: boolean = true;
  ShowHIdeCardTable2() {
    this.isCardTable2 = !this.isCardTable2;
  }

  isCardCashView: boolean = true;
  ShowHIdeCardCashView() {
    this.isCardCashView = !this.isCardCashView;
  }

  showTelabank(tipo) {
    this.statusSummary = false;
    this.statusFolha = false;
    this.statusOperCambio = false;

    this.somaWeekly = 0.0;

    if (tipo === "TELA_1") {
      this.statusSummary = true;
      this.statusFolha = false;
      this.statusOperCambio = false;
      this.somaWeekly = 0.0;
      this.summary.forEach((element) => {
        this.somaWeekly = this.somaWeekly + element.valor;
      });
    } else if (tipo === "TELA_2") {
      this.statusSummary = false;
      this.statusFolha = true;
      this.statusOperCambio = false;
      this.somaWeekly = 0.0;
      this.operacaoFolha.forEach((element) => {
        this.somaWeekly = this.somaWeekly + element.valor;
      });
    } else if (tipo === "TELA_4") {
      this.statusSummary = false;
      this.statusFolha = false;
      this.statusOperCambio = true;
      this.somaWeekly = 0.0;
      this.operacaoCambio.forEach((element) => {
        this.somaWeekly = this.somaWeekly + element.valor;
      });
    }

    this.loadChart9("chart_17");
  }

  showTelacashview(tipo) {
    this.statusSaldoprevisto = false;
    this.statusVariacaoanterior = false;
    this.statusFluxoHistorico = false;
    if (tipo === "TELA_5") {
      this.statusSaldoprevisto = true;
    } else if (tipo === "TELA_6") {
      this.statusVariacaoanterior = true;
    } else if (tipo === "TELA_7") {
      this.statusFluxoHistorico = true;
    }
  }

  showTelaMiniCard(tipo) {
    if (tipo === "TELA_8_1") {
      this.boxSelecionado = 1;
    } else if (tipo === "TELA_8_2") {
      this.boxSelecionado = 2;
    } else if (tipo === "TELA_8_3") {
      this.boxSelecionado = 3;
    } else if (tipo === "TELA_8_4") {
      this.boxSelecionado = 4;
    } else if (tipo === "TELA_8_5") {
      this.boxSelecionado = 5;
    }
    this.loadChart4(this.boxSelecionado);
  }

  // showTelaModal(tipo) {
  //   this.statusLink1 = false;
  //   this.statusLink2 = false;

  //   if (tipo === 'TELA_1') {
  //     this.statusLink1 = true;
  //   }

  //   else if (tipo === 'TELA_2') {
  //     this.statusLink2 = true;
  //     this.loadChartMiniCards('chart_18');
  //   }
  // }

  filter4(period) {
    this.isWeekVision = false;
    this.isMonthVision = false;

    if (period === "FILTER_1") {
      this.isWeekVision = true;
      this.isMonthVision = false;
    } else if (period === "FILTER_2") {
      this.isMonthVision = true;
      this.isWeekVision = false;
    }
  }

  filterForPrint(period) {
    if (period === "FILTER_1") {
      this.isWeekForPrint = true;
      this.isMonthForPrint = false;
    }
    if (period === "FILTER_2") {
      this.isMonthForPrint = true;
      this.isWeekForPrint = false;
    }
  }

  loadChart4(event) {
    this.serieschart = new Array();
    if (event === 1) {
      for (let ano in this.infosDashBox1) {
        if (ano != "valor" && ano != "meses") {
          this.serieschart.push({
            name: ano,
            type: "line",
            data: this.infosDashBox1[ano],
          });
        }
      }
    } else if (event === 2) {
      for (let ano in this.infosDashBox2) {
        if (ano != "valor" && ano != "meses") {
          this.serieschart.push({
            name: ano,
            type: "line",
            data: this.infosDashBox2[ano],
          });
        }
      }
    } else if (event === 3) {
      for (let ano in this.infosDashBox3) {
        if (ano != "valor" && ano != "meses") {
          this.serieschart.push({
            name: ano,
            type: "line",
            data: this.infosDashBox3[ano],
          });
        }
      }
    } else if (event === 4) {
      for (let ano in this.infosDashBox4) {
        if (ano != "valor" && ano != "meses") {
          this.serieschart.push({
            name: ano,
            type: "line",
            data: this.infosDashBox4[ano],
          });
        }
      }
    } else {
      for (let ano in this.infosDashBox5) {
        if (ano != "valor" && ano != "meses") {
          this.serieschart.push({
            name: ano,
            type: "line",
            data: this.infosDashBox5[ano],
          });
        }
      }
    }

    if (this.boxSelecionado == 1) {
      this.tituloSelecionado1 = this.box1;
    } else if (this.boxSelecionado == 2) {
      this.tituloSelecionado1 = this.box2;
    } else if (this.boxSelecionado == 3) {
      this.tituloSelecionado1 = this.box3;
    } else if (this.boxSelecionado == 4) {
      this.tituloSelecionado1 = this.box4;
    } else if (this.boxSelecionado == 5) {
      this.tituloSelecionado1 = this.box5;
    }

    this.titulochart1 = this.tituloSelecionado1;

    var chart = new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: "line",
        renderTo: "chart_12",
      },
      title: {
        text: this.tituloSelecionado1,
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: this.legendaDasboard2,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              let y = Math.abs(this.y);
              if (y / 1e6 >= 1) {
                return `${(this.y / 1e6).toFixed(1)}M`;
              } else if (y / 1e3 >= 1) return `${(this.y / 1e3).toFixed(1)}K`;
              return `${this.y.toFixed()}`;
            },
          },
          enableMouseTracking: false,
        },
      },

      series: this.serieschart,
    });
  }

  loadChart9(container) {
    if (this.statusSummary) {
      this.serieschart2 = this.valorDiversos;
      this.mediachart = this.calcDeslMedia(this.serieschart2);
      this.categorias = this.legendaDiversos;
      this.titulo = "Diversos";
      this.titulochart2 = this.titulo;
    } else if (this.statusOperCambio) {
      this.serieschart2 = this.valorCambio;
      this.mediachart = this.calcDeslMedia(this.serieschart2);
      this.categorias = this.legendaCambio;
      this.titulo = "Operação de Cambio";
      this.titulochart2 = this.titulo;
    } else if (this.statusFolha) {
      this.serieschart2 = this.valorFolha;
      this.mediachart = this.calcDeslMedia(this.serieschart2);
      this.categorias = this.legendaFolha;
      this.titulo = "Folha Pagamento";
      this.titulochart2 = this.titulo;
    }

    var chart = new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: "column",
        renderTo: container,
      },
      title: {
        text: this.titulo,
      },
      xAxis: {
        categories: this.categorias,
      },
      yAxis: {
        title: {
          text: "",
          reserveSpace: false,
        },
      },
      tooltip: {
        valuePrefix: "R$",
        valueDecimals: 2,
        shared: true,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              let y = Math.abs(this.y);
              if (y / 1e6 >= 1) {
                return `${(this.y / 1e6).toFixed(1)}M`;
              } else if (y / 1e3 >= 1) return `${(this.y / 1e3).toFixed(1)}K`;
              return `${this.y.toFixed()}`;
            },
            style: { textOutline: "0px contrast" },
          },
        },
      },
      series: [
        {
          type: "column",
          name: this.titulo,
          data: this.serieschart2,
        },
        {
          type: "spline",
          color: "#5B5B5B",
          name: "Média móvel",
          data: this.mediachart,
        },
      ],
    });
  }

  loadChartMiniCards(container) {
    let values = [];
    let media = [];
    let anos = [];

    let index = this.miniCardsValues[this.selectedMiniCard].length - 1;

    for (let miniCard of this.miniCardsValues[this.selectedMiniCard]) {
      values[index] = miniCard.value;
      media[index] = miniCard.variacao;
      anos[index--] = miniCard.ano;
    }

    let decimalsValueTooltip: number = 2;

    if (
      this.selectedMiniCard === "dolarIPGAnteriores" ||
      this.selectedMiniCard === "dolarInitAnoAnteriores" ||
      this.selectedMiniCard === "dolarHojeAnteriores" ||
      this.selectedMiniCard === "dolarMedioAnteriores"
    ) {
      decimalsValueTooltip = 4;
    }

    var chart = new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: "column",
        renderTo: container,
      },
      title: {
        text: "RELAÇÃO ANUAL",
      },
      xAxis: {
        categories: anos,
        crosshair: true,
      },
      yAxis: [
        {
          title: {
            text: "Valor",
          },
        },
        {
          title: {
            text: "Variação (%)",
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      series: [
        {
          type: "column",
          borderRadius: 3,
          name: "Valor",
          data: values,
          tooltip: {
            valuePrefix: "R$ ",
            valueDecimals: decimalsValueTooltip,
          },
          dataLabels: {
            enabled: true,
            formatter: function () {
              let y = Math.abs(this.y);
              if (y / 1e6 >= 1) {
                return `${(this.y / 1e6).toFixed(1)}M`;
              } else if (y / 1e3 >= 1) return `${(this.y / 1e3).toFixed(1)}K`;
              return `${this.y.toFixed()}`;
            },
          },
        },
        {
          type: "line",
          color: "#5B5B5B",
          name: "Variação ano-1",
          data: media,
          yAxis: 1,
          tooltip: {
            valueSuffix: " %",
            valueDecimals: 2,
          },
        },
      ],
    });
  }

  calcDeslMedia(serie: Array<number>) {
    var med: Array<number> = new Array();
    var i: number;
    for (i = 0; i < 12; i++) {
      med[i] =
        serie.slice(0, i + 1).reduce(function (a, b) {
          return a + b;
        }) /
        (i + 1);
    }
    return med;
  }

  formatarDataExtenso() {
    const dt = new Date();
    const month = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    const week = [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ];
    const semana = week[dt.getDay()];
    const mes = month[dt.getMonth()];

    return (
      "Hoje é " +
      semana +
      ", " +
      dt.getDate() +
      " de " +
      mes +
      " de " +
      dt.getFullYear() +
      "."
    );
  }

  openModalMiniCards(selectedMiniCard) {
    if (!this.dadosAnteriores) {
      this.getBigNumbersAnteriores();
      setTimeout(() => {
        Promise.resolve(null).then(() =>
          this.calcBigNumbersAnteriores(this.bigNumberAnteriores)
        );
      }, 6000);
      this.getCambioNumbersAnteriores();
      this.getContabilNumbersAnteriores();
      this.dadosAnteriores = true;
    }

    // this.showTelaModal('TELA_1');
    this.selectedMiniCard = selectedMiniCard;
    this.loadChartMiniCards("chart_18");
    this.modalMiniCards = true;
  }

  openModalAnexos() {
    this.modalAnexos = true;
  }

  buscarInfosWeekly(infoParam: string) {
    let empresaSelect: Array<number> = [];
    if (
      this.empresasSelecionadas === undefined ||
      this.empresasSelecionadas === null ||
      this.empresasSelecionadas.length === 0
    ) {
      empresaSelect.push(this.dblinkedSessionService.empresa.id);
    } else {
      empresaSelect = this.empresasSelecionadas;
    }

    if (this.isWeekVision && this.weekSelected != null) {
      let today: Date = this.getDateOfISOWeek(
        this.weekSelected,
        new Date().getFullYear()
      );

      this.DefInterDate = today;
      this.DefFinalDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 7
      );
    } else if (this.isMonthVision && this.monthSelected != null) {
      this.DefInterDate = new Date(
        new Date().getFullYear(),
        this.monthSelected - 1,
        1
      );
      this.DefFinalDate = new Date(
        new Date().getFullYear(),
        this.monthSelected,
        1
      );
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        dtini: this.DefInterDate.toString(),
        dtfin: this.DefFinalDate.toString(),
        liempresaid: empresaSelect.toString(),
        visao: infoParam.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload +
        "dashboard/financeiro/get-info-weekly",
        httpOptions
      )
      .subscribe(
        (res) => {
          if (infoParam === "pend") {
            this.transPendentes = res["mensagem"];
            this.somaTransPendentes = 0.0;

            if (this.transPendentes) {
              this.transPendentes.forEach((element) => {
                this.somaTransPendentes =
                  this.somaTransPendentes + element.valor;
              });
            }
          } else if (infoParam === "diversos") {
            this.summary = res["mensagem"];
            this.somaWeekly = 0.0;

            if (this.summary) {
              this.summary.forEach((element) => {
                this.somaWeekly = this.somaWeekly + element.valor;
              });
            }
          } else if (infoParam === "operacaocambio") {
            this.operacaoCambio = res["mensagem"];
          } else if (infoParam === "operacaofolha") {
            this.operacaoFolha = res["mensagem"];
          }
          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        }
      );
  }

  getContabilNumbers() {
    this.rendaRealYTD = "0";
    this.custoDespYTD = "0";
    this.capexYTD = "0";
    this.intangivelYTD = "0";

    let empresaSelect: Array<number> = [];

    if (
      this.empresasSelecionadas === undefined ||
      this.empresasSelecionadas === null ||
      this.empresasSelecionadas.length === 0
    ) {
      empresaSelect.push(this.dblinkedSessionService.empresa.id);
    } else {
      empresaSelect = this.empresasSelecionadas;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        dtInicial: new Date(new Date().getFullYear(), 0, 1).toString(),
        dtFinal: new Date().toString(),
        empresaid: empresaSelect.toString(),
      }),
    };

    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload +
        "dashboard/financeiro/getcontabil",
        httpOptions
      )
      .subscribe(
        (res) => {
          let result: any = res["mensagem"];
          this.rendaRealYTD =
            result[0] === null ? "0" : this.abreviateNumber(result[0]);
          this.miniCardsValues["rendaRealYTDAnteriores"][0].value =
            result[0] === null ? 0 : result[0];

          this.custoDespYTD =
            result[1] === null ? "0" : this.abreviateNumber(result[1]);
          this.miniCardsValues["custoDespYTDAnteriores"][0].value =
            result[1] === null ? 0 : result[1];

          this.capexYTD =
            result[2] === null ? "0" : this.abreviateNumber(result[2]);
          this.miniCardsValues["capexYTDAnteriores"][0].value =
            result[2] === null ? 0 : result[2];

          this.intangivelYTD =
            result[3] === null ? "0" : this.abreviateNumber(result[3]);
          this.miniCardsValues["intangivelYTDAnteriores"][0].value =
            result[3] === null ? 0 : result[3];
        },
        (err) => {
          console.log(err);
          this.exibirMensagem(err);
        }
      );
  }

  getCambioNumbers() {
    this.dolarInitAno = 0;
    this.dolarHoje = 0;
    this.dolarMedio = 0;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        dtinicial: new Date().getFullYear() + "-01-01",
        dtfinal: new Date().getFullYear() + "-" + (new Date().getMonth() - 1) + "-" + new Date().getDate(),
        moeda: "2",
      }),
    };

    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload + "financeiro/cambio", httpOptions
      )
      .subscribe(
        (res) => {
          let result: any = res;
          let dolarMedioNum: number = 0;

          this.dolarInitAno =
            result[result.length - 1]["cotacao_compra_dia"].toString();
          this.miniCardsValues["dolarInitAnoAnteriores"][0].value =
            result[result.length - 1]["cotacao_compra_dia"];

          this.dolarHoje = result[0]["cotacao_compra_dia"].toString();
          this.miniCardsValues["dolarHojeAnteriores"][0].value =
            result[0]["cotacao_compra_dia"];

          for (let line of result) {
            dolarMedioNum += line["cotacao_compra_dia"];
          }

          this.dolarMedio =
            Math.round((dolarMedioNum / result.length) * 10000) / 10000;
          this.miniCardsValues["dolarMedioAnteriores"][0].value =
            this.dolarMedio;
        },
        (err) => {
          console.log(err);
          this.exibirMensagem(err);
        }
      );
  }

  getBigNumbers() {
    let baixados: Boolean = true;
    let empresaSelect: Array<number> = [];

    if (
      this.empresasSelecionadas === undefined ||
      this.empresasSelecionadas === null ||
      this.empresasSelecionadas.length === 0
    ) {
      empresaSelect.push(this.dblinkedSessionService.empresa.id);
    } else {
      empresaSelect = this.empresasSelecionadas;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: empresaSelect.toString(),
        dtinit: new Date(new Date().getFullYear(), 0, 1).toString(),
        dtfinal: new Date(new Date().getFullYear() + 1, 0, 1).toString(),
        baixados: baixados.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .post(
        this.configService.defaultUrlApiUpload + "dashboard/fluxocaixa",
        null,
        httpOptions
      )
      .subscribe(
        (res) => {
          let result = res["mensagem"];
          this.calcBigNumbers(result);
          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
        }
      );

    const httpOptions2 = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: empresaSelect.toString(),
        mes: this.dblinkedSessionService.periodo.month.value.toString(),
        ano: this.dblinkedSessionService.periodo.year.value.toString(),
      }),
    };
    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload +
        "dashboard/financeiro/dolaripg",
        httpOptions2
      )
      .subscribe(
        (res) => {
          this.dolarIPG = res["mensagem"];
          this.miniCardsValues["dolarIPGAnteriores"][0].value = res["mensagem"];
          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  calcBigNumbers(res) {
    let chaveInit: string = new Date().getFullYear().toString() + "-01-01";
    let chaveFinal: string = new Date().getFullYear().toString() + "-12-31";
    let chaveToday: string =
      new Date().getFullYear().toString() +
      "-" +
      ((new Date().getMonth() + 1).toString().length === 1
        ? "0" + (new Date().getMonth() + 1).toString()
        : (new Date().getMonth() + 1).toString()) +
      "-" +
      (new Date().getDate().toString().length === 1
        ? "0" + new Date().getDate().toString()
        : new Date().getDate().toString());
    let saldoInitAnoNum: number = 0;
    let saldoYTDNum: number = 0;
    let saldoFinAnoNum: number = 0;
    let saldoTotalNum: number = 0;

    for (let conta in res["lisaldoinicial"][chaveInit]) {
      saldoInitAnoNum += res["lisaldoinicial"][chaveInit][conta];
      saldoYTDNum +=
        res["lisaldoinicial"][chaveToday][conta] -
        res["lisaldoinicial"][chaveInit][conta];
      saldoFinAnoNum += res["lisaldofinal"][chaveFinal][conta];
      saldoTotalNum +=
        res["lisaldofinal"][chaveFinal][conta] -
        res["lisaldoinicial"][chaveInit][conta];
    }

    this.saldoInitAno = this.abreviateNumber(saldoInitAnoNum);
    this.cashRealizadoYTD = this.abreviateNumber(saldoYTDNum);
    this.saldoFinAno = this.abreviateNumber(saldoFinAnoNum);
    this.cashRealizado = this.abreviateNumber(saldoTotalNum);

    //gravando o valor absoluto para comparar com os valores dos anos anteriores
    this.miniCardsValues["saldoInitAnoAnteriores"][0].value = saldoInitAnoNum;
    this.miniCardsValues["cashRealizadoYTDAnteriores"][0].value = saldoYTDNum;
    this.miniCardsValues["saldoFinAnoAnteriores"][0].value = saldoFinAnoNum;
    this.miniCardsValues["cashRealizadoAnteriores"][0].value = saldoTotalNum;
  }

  abreviateNumber(number: number) {
    if (Math.abs(number) >= 1000) {
      return (
        Math.round(number / 1000).toLocaleString("pt-Br", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }) + " Mi"
      );
    } else {
      return Math.round(number).toLocaleString("pt-Br", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  }

  calcFluxoDiff() {
    this.fluxoCaixaDiff = new Array();
    this.saldoInitPeriodDiff = 0;
    this.saldoFinalPeriodDiff = 0;
    for (let line of this.fluxoHistorico) {
      this.fluxoCaixaDiff.push({
        conta: line.conta,
        pre_pgtos: 0,
        final_pos_pgto: 0,
        mov_prev_negativos: 0,
        mov_prev_positivo: 0,
        historic_id: line.historic_id,
        comentarios: line.comentario,
      });
    }

    if (this.fluxoHistorico.length != 0) {
      for (let line of this.fluxoCaixaDiff) {
        let i1 = 0;
        let i2 = 0;
        for (let line1 of this.fluxoCaixaAtual) {
          if (line1.conta === line.conta) {
            break;
          } else {
            i1++;
          }
        }

        for (let line1 of this.fluxoHistorico) {
          if (line1.conta === line.conta) {
            break;
          } else {
            i2++;
          }
        }
        try {
          line["pre_pgtos"] = Math.round((this.fluxoCaixaAtual[i1]["pre_pgtos"] - this.fluxoHistorico[i2]["saldo_init"]) * 100) / 100;
        } catch (error) {
          line["pre_pgtos"] = 0;
        }

        try {
          line["final_pos_pgto"] = Math.round((this.fluxoCaixaAtual[i1]["final_pos_pgto"] - this.fluxoHistorico[i2]["saldo_final"]) * 100) / 100;
        } catch (error) {
          line["final_pos_pgto"] = 0;
        }

        try {
          line["mov_prev_negativo"] = Math.round((this.fluxoCaixaAtual[i1]["mov_prev_negativo"] - this.fluxoHistorico[i2]["mov_neg"]) * 100) / 100;
        } catch (error) {
          line["mov_prev_negativo"] = 0;
        }

        try {
          line["mov_prev_positivo"] = Math.round((this.fluxoCaixaAtual[i1]["mov_prev_positivo"] - this.fluxoHistorico[i2]["mov_pos"]) * 100) / 100;
        } catch (error) {
          line["mov_prev_positivo"] = 0;
        }
        // line["pre_pgtos"] = Math.round((this.fluxoCaixaAtual[i1]["pre_pgtos"] - this.fluxoHistorico[i2]["saldo_init"]) * 100) / 100;
        // line["final_pos_pgto"] = Math.round((this.fluxoCaixaAtual[i1]["final_pos_pgto"] - this.fluxoHistorico[i2]["saldo_final"]) * 100) / 100;
        // line["mov_prev_negativo"] = Math.round((this.fluxoCaixaAtual[i1]["mov_prev_negativo"] - this.fluxoHistorico[i2]["mov_neg"]) * 100) / 100;
        // line["mov_prev_positivo"] = Math.round((this.fluxoCaixaAtual[i1]["mov_prev_positivo"] - this.fluxoHistorico[i2]["mov_pos"]) * 100) / 100;
        this.saldoInitPeriodDiff += line["pre_pgtos"];
        this.saldoFinalPeriodDiff += line["final_pos_pgto"];
      }

      this.saldoInitPeriodDiff =
        Math.round(this.saldoInitPeriodDiff * 100) / 100;
      this.saldoFinalPeriodDiff =
        Math.round(this.saldoFinalPeriodDiff * 100) / 100;
    }
  }

  buscarInfosChart() {
    let empresaSelect: Array<number> = [];

    if (
      this.empresasSelecionadas === undefined ||
      this.empresasSelecionadas === null ||
      this.empresasSelecionadas.length === 0
    ) {
      empresaSelect.push(this.dblinkedSessionService.empresa.id);
    } else {
      empresaSelect = this.empresasSelecionadas;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: empresaSelect.toString(),
        visualizacaoid: this.visualizacaoId.toString(),
        ano: new Date().getFullYear().toString(),
      }),
    };
    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload +
        "dashboard/financeiro/get-info-weekly-fin",
        httpOptions
      )
      .subscribe(
        (res) => {
          let result = res["mensagem"];

          this.legendaDiversos = result["liperiodos"];
          this.legendaFolha = result["liperiodos"];
          this.legendaCambio = result["liperiodos"];
          this.valorDiversos = result["lidiversos"];
          this.valorFolha = result["lifolha"];
          this.valorCambio = result["licambio"];
          this.httpService.done();
          this.loadChart9("chart_17");
        },
        (err) => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getWeekList(today: Date) {
    for (var i = 1; i < 54; i++) {
      this.listSemanas.push({
        value: i,
        label: i.toString() + "-" + today.getFullYear().toString(),
      });
    }
  }

  getMonthList() {
    let limeses: Array<string> = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    let i: number = 1;

    for (var m of limeses) {
      this.listMeses.push({ value: i, label: m });
      i++;
    }
  }

  getFluxoCaixa() {
    if (this.isWeekVision && this.weekSelected != null) {
      let today: Date = this.getDateOfISOWeek(
        this.weekSelected,
        new Date().getFullYear()
      );

      this.DefInterDate = today;
      this.DefFinalDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 7
      );
    } else if (this.isMonthVision && this.monthSelected != null) {
      this.DefInterDate = new Date(
        new Date().getFullYear(),
        this.monthSelected - 1,
        1
      );
      this.DefFinalDate = new Date(
        new Date().getFullYear(),
        this.monthSelected,
        1
      );
    }

    let baixados: Boolean = true;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        dtinit: this.DefInterDate.toString(),
        dtfinal: this.DefFinalDate.toString(),
        baixados: baixados.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .post(
        this.configService.defaultUrlApiUpload + "dashboard/fluxocaixa",
        null,
        httpOptions
      )
      .subscribe(
        (res) => {
          // Get the result
          let result = res["mensagem"];
          this.extractResult(result);
          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getFluxoCaixaHistorico() {
    if (this.isWeekVision && this.weekSelected != null) {
      let today: Date = this.getDateOfISOWeek(
        this.weekSelected,
        new Date().getFullYear()
      );

      this.DefInterDate = today;
      this.DefFinalDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 7
      );
    } else if (this.isMonthVision && this.monthSelected != null) {
      this.DefInterDate = new Date(
        new Date().getFullYear(),
        this.monthSelected - 1,
        1
      );
      this.DefFinalDate = new Date(
        new Date().getFullYear(),
        this.monthSelected,
        1
      );
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        dtmin: this.DefInterDate.toString(),
        dtmax: this.DefFinalDate.toString(),
      }),
    };
    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload +
        "dashboard/fluxocaixa/fluxocaixa_historico",
        httpOptions
      )
      .subscribe(
        (res) => {
          // Get the result
          this.fluxoHistorico = new Array();
          this.saldoFinalPeriodHisto = 0;
          this.saldoInitPeriodHisto = 0;
          this.dt_historico = null;
          this.detalheFluxoCaixa = res["mensagem"]["detalhe"];
          let liresumo = res["mensagem"]["resumo"];
          for (let line of liresumo) {
            this.fluxoHistorico.push(line);
            this.saldoInitPeriodHisto += line["saldo_init"];
            this.saldoFinalPeriodHisto += line["saldo_final"];
          }

          this.saldoInitPeriodHisto =
            Math.round(this.saldoInitPeriodHisto * 100) / 100;
          this.saldoFinalPeriodHisto =
            Math.round(this.saldoFinalPeriodHisto * 100) / 100;

          if (this.fluxoHistorico.length > 0) {
            this.dt_historico = this.fluxoHistorico[0]["dt_historico"];
            this.usr_historico = this.fluxoHistorico[0]["usr"];
          }
          this.calcFluxoDiff();
          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 2 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  extractResult(res) {
    this.fluxoCaixaAtual = [];
    this.saldoFinalPeriodAtu = 0;
    this.saldoInitPeriodAtu = 0;
    this.movPeriodAtu = 0;
    this.periodoInitAtu = "";
    this.periodoFinAtu = "";

    //Cria uma linha por conta
    for (let days in res["limovimentacao"]) {
      for (let cont in res["limovimentacao"][days]) {
        this.fluxoCaixaAtual.push({ conta: cont });
      }

      break;
    }

    //Definir as chaves certas para as datas
    let chaveAnteFin: string =
      this.DefInterDate.getFullYear().toString() +
      "-" +
      ((this.DefInterDate.getMonth() + 1).toString().length === 1
        ? "0" + (this.DefInterDate.getMonth() + 1).toString()
        : (this.DefInterDate.getMonth() + 1).toString()) +
      "-" +
      (this.DefInterDate.getDate().toString().length === 1
        ? "0" + this.DefInterDate.getDate().toString()
        : this.DefInterDate.getDate().toString());

    let chaveAtuFin: string = "";
    if (this.isWeekVision) {
      let finalDate = this.DefFinalDate;
      finalDate.setDate(this.DefFinalDate.getDate() - 1);

      chaveAtuFin =
        finalDate.getFullYear().toString() +
        "-" +
        ((finalDate.getMonth() + 1).toString().length === 1
          ? "0" + (finalDate.getMonth() + 1).toString()
          : (finalDate.getMonth() + 1).toString()) +
        "-" +
        (finalDate.getDate().toString().length === 1
          ? "0" + finalDate.getDate().toString()
          : finalDate.getDate().toString());
    } else {
      let chaveTemp: Date = new Date(
        this.DefFinalDate.getFullYear(),
        this.DefFinalDate.getMonth(),
        this.DefFinalDate.getDate() - 1
      );
      chaveAtuFin =
        chaveTemp.getFullYear().toString() +
        "-" +
        ((chaveTemp.getMonth() + 1).toString().length === 1
          ? "0" + (chaveTemp.getMonth() + 1).toString()
          : (chaveTemp.getMonth() + 1).toString()) +
        "-" +
        (chaveTemp.getDate().toString().length === 1
          ? "0" + chaveTemp.getDate().toString()
          : chaveTemp.getDate().toString());
    }

    let chaveAtuMov: string = "";

    if (this.isWeekVision) {
      chaveAtuMov =
        this.DefInterDate.getFullYear().toString() +
        (this.getWeekNumber(this.DefInterDate).toString().length === 1
          ? "0" + this.getWeekNumber(this.DefInterDate).toString()
          : this.getWeekNumber(this.DefInterDate).toString());
    } else if (this.isMonthVision) {
      chaveAtuMov =
        this.DefInterDate.getFullYear().toString() +
        ((this.DefInterDate.getMonth() + 1).toString().length === 1
          ? "0" + (this.DefInterDate.getMonth() + 1).toString()
          : (this.DefInterDate.getMonth() + 1).toString());
    }

    //Definir os headers
    this.periodoInitAtu =
      (this.DefInterDate.getDate().toString().length === 1
        ? "0" + this.DefInterDate.getDate().toString()
        : this.DefInterDate.getDate().toString()) +
      "-" +
      ((this.DefInterDate.getMonth() + 1).toString().length === 1
        ? "0" + (this.DefInterDate.getMonth() + 1).toString()
        : (this.DefInterDate.getMonth() + 1).toString()) +
      "-" +
      this.DefInterDate.getFullYear().toString();

    let periodoFAtu: Date = new Date(
      this.DefFinalDate.getFullYear(),
      this.DefFinalDate.getMonth(),
      this.DefFinalDate.getDate() - 1
    );

    this.periodoFinAtu =
      (periodoFAtu.getDate().toString().length === 1
        ? "0" + periodoFAtu.getDate().toString()
        : periodoFAtu.getDate().toString()) +
      "-" +
      ((periodoFAtu.getMonth() + 1).toString().length === 1
        ? "0" + (periodoFAtu.getMonth() + 1).toString()
        : (periodoFAtu.getMonth() + 1).toString()) +
      "-" +
      periodoFAtu.getFullYear().toString();

    this.listaContas = new Array();

    for (let line of this.fluxoCaixaAtual) {
      line["pre_pgtos"] = res["lisaldoinicial"][chaveAnteFin][line["conta"]];
      line["final_pos_pgto"] = res["lisaldofinal"][chaveAtuFin][line["conta"]];

      if (this.isWeekVision) {
        line["mov_prev_negativo"] =
          res["limovcpcrweek"][chaveAtuMov][line["conta"]]["CP"];
        line["mov_prev_positivo"] =
          res["limovcpcrweek"][chaveAtuMov][line["conta"]]["CR"];
      } else {
        line["mov_prev_negativo"] =
          res["limovcpcrmonth"][chaveAtuMov][line["conta"]]["CP"];
        line["mov_prev_positivo"] =
          res["limovcpcrmonth"][chaveAtuMov][line["conta"]]["CR"];
      }

      this.saldoFinalPeriodAtu += line["final_pos_pgto"];
      this.saldoInitPeriodAtu += line["pre_pgtos"];
      this.listaContas.push({
        conta: line["conta"],
        saldo_init: line["pre_pgtos"],
        saldo_final: line["final_pos_pgto"],
      });
    }

    this.saldoFinalPeriodAtu = Math.round(this.saldoFinalPeriodAtu * 100) / 100;
    this.saldoInitPeriodAtu = Math.round(this.saldoInitPeriodAtu * 100) / 100;
    this.getFluxoCaixaHistorico();
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() {

  }

  exportSaldosExcel() {
    let baixados: Boolean = true;

    //Header da requisição
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        dtinit: this.DefInterDate.toString(),
        dtfinal: this.DefFinalDate.toString(),
        dtinitmonth: new Date(
          this.dblinkedSessionService.periodo.year.value,
          0,
          1
        ).toString(),
        dtfinmonth: new Date(
          this.dblinkedSessionService.periodo.year.value,
          this.dblinkedSessionService.periodo.month.value,
          1
        ).toString(),
        baixados: baixados.toString(),
      }),
    };
    this.httpService.wait();
    this.httpClient
      .post(
        this.configService.defaultUrlApiUpload +
        "dashboard/fluxocaixa/prepareExcel",
        null,
        httpOptions
      )
      .subscribe(
        (res) => {
          let dt = new Date();
          this.exibirMensagem(res);
          window.open(
            this.configService.defaultUrlApiUpload +
            "dashboard/fluxocaixa/baixarExcel/" +
            dt.toString(),
            "_blank"
          );
          this.httpService.done();
        },
        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  atualizarDash2(tipo: any) {
    this.visualizacaoId = tipo;
    if (tipo === 1) {
      this.visaoYtd = true;
      this.visao12m = false;
      this.visaoMes = false;
    } else if (tipo === 2) {
      this.visaoYtd = false;
      this.visao12m = true;
      this.visaoMes = false;
    } else if (tipo === 3) {
      this.visaoYtd = false;
      this.visao12m = false;
      this.visaoMes = true;
    }
    this.legendaDasboard2 = [];
    for (var i = 1; i <= 5; i++) {
      this.getInfosDash2(i);
    }
  }

  getWeekNumber(dt) {
    var tdt = new Date(dt.valueOf());
    var dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
    }

    return 1 + Math.ceil((firstThursday - tdt.getTime()) / 604800000);
  }

  onFlowCashDiffSelect(conta, oper) {
    this.onFlowCashSelect(conta, oper, false);
    setTimeout(() => {
      let movTableTemp = new Array();

      //Comparação dos títulos nos dois fluxos de caixa
      let listIdIgual = new Array();

      for (let line1 of this.detalheFluxoCaixa[conta]) {
        for (let line2 of this.movTable) {
          if (line2.nfe_fin_id === line1.nfe_fin_id) {
            listIdIgual.push(line1.nfe_fin_id);
            if (line2.valor - line1.valor != 0) {
              movTableTemp.push({
                dt_vcto: line1.dt_vcto,
                dt_previsao: line1.dt_previsao,
                dt_emissao: line1.dt_emissao,
                nome: line1.nome,
                operacao: line1.operacao,
                contaBancaria: line1.contaBancaria,
                historico: line1.historico,
                nf: line1.nf,
                parcela: line1.parcela,
                valor: line2.valor - line1.valor,
                status: line1.status,
              });
            }
            break;
          }
        }
      }

      //Check se tem títulos a mais no historico
      for (let line of this.detalheFluxoCaixa[conta]) {
        if (!listIdIgual.includes(line.nfe_fin_id) && line["mov"] === oper) {
          movTableTemp.push({
            dt_vcto: line.dt_vcto,
            dt_previsao: line.dt_previsao,
            dt_emissao: line.dt_emissao,
            nome: line.nome,
            operacao: line.operacao,
            contaBancaria: line.contaBancaria,
            historico: line.historico,
            nf: line.nf,
            parcela: line.parcela,
            valor: line.valor,
            status: line.status,
          });
        }
      }

      //Check se tem títulos a mais no fluxo real
      for (let line of this.movTable) {
        if (!listIdIgual.includes(line.nfe_fin_id)) {
          movTableTemp.push({
            dt_vcto: line.dt_vcto,
            dt_previsao: line.dt_previsao,
            dt_emissao: line.dt_emissao,
            nome: line.nome,
            operacao: line.operacao,
            contaBancaria: line.contaBancaria,
            historico: line.historico,
            nf: line.nf,
            parcela: line.parcela,
            valor: line.valor,
            status: line.status,
          });
        }
      }

      this.movTable = movTableTemp;
      this.detalheFluxo = true;
    }, 3000);
  }

  onFlowCashSelect(conta, oper, window) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        dtmin: this.DefInterDate.toString(),
        dtmax: this.DefFinalDate.toString(),
        oper: oper.toString(),
        conta: conta.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload +
        "dashboard/fluxocaixa/detalhe_mov",
        httpOptions
      )
      .subscribe(
        (res) => {
          // Get the result
          let result = res["mensagem"];
          this.movTable = new Array();

          if (this.movTable !== null || this.movTable !== undefined) {
            this.contadorLinha = this.movTable.length;
          }
          else {
            this.contadorLinha = 0;
          }

          for (let line of result) {
            this.movTable.push(line);
          }
          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
        }
      );
    if (window) {
      this.detalheFluxo = true;
    }
  }

  onFlowCashHistorySelect(conta, oper) {
    this.detalheFluxo = true;
    this.movTable = new Array();
    for (let line of this.detalheFluxoCaixa[conta]) {
      if (line["mov"] === oper) {
        this.movTable.push(line);
      }
    }
  }

  onWeeklyBankApprovalsSelect(conta, descrOper, status, window) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        dtmin: this.DefInterDate.toString(),
        dtmax: this.DefFinalDate.toString(),
        descrOper: descrOper.toString(),
        conta: conta.toString(),
        status: status.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload +
        "dashboard/fluxocaixa/detalhe_mov",
        httpOptions
      )
      .subscribe(
        (res) => {
          // Get the result
          let result = res["mensagem"];
          this.movTable = new Array();
          for (let line of result) {
            this.movTable.push(line);
          }
          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
        }
      );
    if (window) {
      this.detalheBankApprovals = true;
    }
  }

  imprimirAnexo() {
    if (confirm("Deseja realmente imprimir este relatório?")) {
      const listasEnviar = {
        empresa: this.dblinkedSessionService.empresa.id,
        //variavel transações pendentes
        transPendentes: this.transPendentes,

        //variavel summary
        summary: this.summary,

        //variavel folha de pagamento
        operacaoFolha: this.operacaoFolha,

        //variavel operação de cambio
        operacaoCambio: this.operacaoCambio,

        //variavel Cashview Saldo EOW
        fluxoCaixaAtual: this.fluxoCaixaAtual,

        //variavel Cashview historico
        fluxoHistorico: this.fluxoHistorico,

        //variavel Cashview em relação à semana anterior
        fluxoCaixaDiff: this.fluxoCaixaDiff,

        //variaveis highchart 1 - dashboard2
        legendaDasboard2: this.legendaDasboard2,
        serieschart: this.serieschart,
        titulochart1: this.titulochart1,

        //variaveis highchart 2 - Summary, Folha de pagamento ou Operação de cambio
        categorias: this.categorias,
        titulo: this.titulo,
        serieschart2: this.serieschart2,
        mediachart: this.mediachart,
        isWeekVision: this.isWeekForPrint,
        weekSelected: this.weekSelected,
        monthSelected: this.monthSelected,
        titulochart2: this.titulochart2,
      };

      this.httpService.wait();
      this.httpService
        .post("/custom/orcamento/impressao-report-financeiro", listasEnviar)
        .subscribe(
          (result) => this.download(result),
          (error) =>
            this.httpService.handleError(error, () => this.imprimirAnexo()),
          () => this.httpService.done()
        )
    }
  }

  download(result?: any) {
    if (!result.error) {
      window.open(
        this.httpService.url("/custom/file/" + result.file + "/financeiro"),
        "_blank"
      );
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result.message });
    }
  }

  checkFluxoHistorico() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        dtmin: this.DefInterDate.toString(),
        dtmax: this.DefFinalDate.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload +
        "dashboard/fluxocaixa/check_historico",
        httpOptions
      )
      .subscribe(
        (res) => {
          this.httpService.done();
          if (res["mensagem"]) {
            if (
              confirm(
                "Já consta um histórico no banco de dados, deseja sobrepôr?"
              )
            ) {
              this.gravarHistorico();
            }
          } else {
            this.gravarHistorico();
          }
        },
        (err) => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  gravarHistorico() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        dtmin: this.DefInterDate.toString(),
        dtmax: this.DefFinalDate.toString(),
        userid: this.sessionService.loggedUser.id.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .post(
        this.configService.defaultUrlApiUpload +
        "dashboard/fluxocaixa/gravar_historico",
        this.listaContas,
        httpOptions
      )
      .subscribe(
        (res) => {
          this.httpService.done();
          this.exibirMensagem(res);
          this.getFluxoCaixaHistorico();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getBigNumbersAnteriores() {
    let today = new Date();
    this.getListasFluxo(new Date(today.getFullYear() - 1, 0, 1), 0);
    this.getListasFluxo(new Date(today.getFullYear() - 1, 11, 31), 1);
    this.getListasFluxo(
      new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()),
      2
    );
    this.getListasFluxo(new Date(today.getFullYear() - 2, 0, 1), 3);
    this.getListasFluxo(new Date(today.getFullYear() - 2, 11, 31), 4);
    this.getListasFluxo(
      new Date(today.getFullYear() - 2, today.getMonth(), today.getDate()),
      5
    );
  }

  getListasFluxo(dtInit, i) {
    let baixados: Boolean = true;
    let diaPosterior = new Date(
      dtInit.getFullYear(),
      dtInit.getMonth(),
      dtInit.getDate() + 1
    );

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        dtinit: dtInit.toString(),
        dtfinal: diaPosterior.toString(),
        baixados: baixados.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .post(
        this.configService.defaultUrlApiUpload + "dashboard/fluxocaixa",
        null,
        httpOptions
      )
      .subscribe(
        (res) => {
          let result = res["mensagem"];
          this.bigNumberAnteriores[i] = result;
          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  calcBigNumbersAnteriores(res) {
    let chaveInit = Object.keys(res[0]["lisaldoinicial"])[0];
    let chaveFinal = Object.keys(res[1]["lisaldoinicial"])[0];
    let chaveToday = Object.keys(res[2]["lisaldoinicial"])[0];
    let chaveInit2 = Object.keys(res[3]["lisaldoinicial"])[0];
    let chaveFinal2 = Object.keys(res[4]["lisaldoinicial"])[0];
    let chaveToday2 = Object.keys(res[5]["lisaldoinicial"])[0];

    for (let conta in res[0]["lisaldoinicial"][chaveInit]) {
      this.miniCardsValues["saldoInitAnoAnteriores"][1].value +=
        res[0]["lisaldoinicial"][chaveInit][conta] || 0;
      this.miniCardsValues["cashRealizadoYTDAnteriores"][1].value +=
        res[2]["lisaldoinicial"][chaveToday][conta] -
        res[0]["lisaldoinicial"][chaveInit][conta] || 0;
      this.miniCardsValues["saldoFinAnoAnteriores"][1].value +=
        res[1]["lisaldofinal"][chaveFinal][conta] || 0;
      this.miniCardsValues["cashRealizadoAnteriores"][1].value +=
        res[1]["lisaldofinal"][chaveFinal][conta] -
        res[0]["lisaldoinicial"][chaveInit][conta] || 0;

      this.miniCardsValues["saldoInitAnoAnteriores"][2].value +=
        res[3]["lisaldoinicial"][chaveInit2][conta] || 0;
      this.miniCardsValues["cashRealizadoYTDAnteriores"][2].value +=
        res[5]["lisaldoinicial"][chaveToday2][conta] -
        res[3]["lisaldoinicial"][chaveInit2][conta] || 0;
      this.miniCardsValues["saldoFinAnoAnteriores"][2].value +=
        res[4]["lisaldofinal"][chaveFinal2][conta] || 0;
      this.miniCardsValues["cashRealizadoAnteriores"][2].value +=
        res[4]["lisaldofinal"][chaveFinal2][conta] -
        res[3]["lisaldoinicial"][chaveInit2][conta] || 0;
    }

    for (let i = 1; i < 3; i++) {
      let fullYear = new Date().getFullYear() - i;

      this.miniCardsValues["saldoInitAnoAnteriores"][i].ano = fullYear;
      this.miniCardsValues["saldoInitAnoAnteriores"][i - 1].variacao =
        this.calculaVariacao(
          this.miniCardsValues["saldoInitAnoAnteriores"][i - 1].value,
          this.miniCardsValues["saldoInitAnoAnteriores"][i].value
        );

      this.miniCardsValues["cashRealizadoYTDAnteriores"][i].ano = fullYear;
      this.miniCardsValues["cashRealizadoYTDAnteriores"][i - 1].variacao =
        this.calculaVariacao(
          this.miniCardsValues["cashRealizadoYTDAnteriores"][i - 1].value,
          this.miniCardsValues["cashRealizadoYTDAnteriores"][i].value
        );

      this.miniCardsValues["saldoFinAnoAnteriores"][i].ano = fullYear;
      this.miniCardsValues["saldoFinAnoAnteriores"][i - 1].variacao =
        this.calculaVariacao(
          this.miniCardsValues["saldoFinAnoAnteriores"][i - 1].value,
          this.miniCardsValues["saldoFinAnoAnteriores"][i].value
        );

      this.miniCardsValues["cashRealizadoAnteriores"][i].ano = fullYear;
      this.miniCardsValues["cashRealizadoAnteriores"][i - 1].variacao =
        this.calculaVariacao(
          this.miniCardsValues["cashRealizadoAnteriores"][i - 1].value,
          this.miniCardsValues["cashRealizadoAnteriores"][i].value
        );
    }
  }

  getCambioNumbersAnteriores() {
    for (let i = 1; i < 3; i++) {
      let today = new Date();
      today.setFullYear(today.getFullYear() - i);

      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
          dtinicial: new Date().getFullYear() + "-01-01",
          dtfinal: today.getFullYear() + "-" + (today.getMonth() - 1) + "-" + today.getDate(),
          moeda: "2",
        }),
      };

      this.httpClient
        .get(
          this.configService.defaultUrlApiUpload + "financeiro/cambio", httpOptions
        )
        .subscribe(
          (res) => {
            let result: any = res;
            let dolarMedioNum: number = 0;

            for (let line of result) {
              dolarMedioNum += line["cotacao_compra_dia"];
            }

            this.miniCardsValues["dolarInitAnoAnteriores"][i].value =
              result[result.length - 1]["cotacao_compra_dia"];
            this.miniCardsValues["dolarHojeAnteriores"][i].value =
              result[0]["cotacao_compra_dia"];
            this.miniCardsValues["dolarMedioAnteriores"][i].value =
              Math.round((dolarMedioNum / result.length) * 10000) / 10000;

            this.miniCardsValues["dolarInitAnoAnteriores"][i].ano =
              today.getFullYear();
            this.miniCardsValues["dolarInitAnoAnteriores"][i - 1].variacao =
              this.calculaVariacao(
                this.miniCardsValues["dolarInitAnoAnteriores"][i - 1].value,
                this.miniCardsValues["dolarInitAnoAnteriores"][i].value
              );

            this.miniCardsValues["dolarHojeAnteriores"][i].ano =
              today.getFullYear();
            this.miniCardsValues["dolarHojeAnteriores"][i - 1].variacao =
              this.calculaVariacao(
                this.miniCardsValues["dolarHojeAnteriores"][i - 1].value,
                this.miniCardsValues["dolarHojeAnteriores"][i].value
              );

            this.miniCardsValues["dolarMedioAnteriores"][i].ano =
              today.getFullYear();
            this.miniCardsValues["dolarMedioAnteriores"][i - 1].variacao =
              this.calculaVariacao(
                this.miniCardsValues["dolarMedioAnteriores"][i - 1].value,
                this.miniCardsValues["dolarMedioAnteriores"][i].value
              );
          },
          (err) => {
            console.log(err);
            this.exibirMensagem(err);
          }
        );

      const httpOptions2 = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
          empresaid: this.dblinkedSessionService.empresa.id.toString(),
          mes: (this.dblinkedSessionService.periodo.month.value - 1).toString(),
          ano: (this.dblinkedSessionService.periodo.year.value - 1).toString(),
        }),
      };

      this.httpService.wait();
      this.httpClient
        .get(
          this.configService.defaultUrlApiUpload +
          "dashboard/financeiro/dolaripg",
          httpOptions2
        )
        .subscribe(
          (res) => {
            this.miniCardsValues["dolarIPGAnteriores"][i].ano =
              today.getFullYear();
            this.miniCardsValues["dolarIPGAnteriores"][i].value =
              res["mensagem"];
            this.miniCardsValues["dolarIPGAnteriores"][i - 1].variacao =
              this.calculaVariacao(
                this.miniCardsValues["dolarIPGAnteriores"][i - 1].value,
                this.miniCardsValues["dolarIPGAnteriores"][i].value
              );

            this.httpService.done();
          },
          (err) => {
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  getContabilNumbersAnteriores() {
    let empresaSelect: Array<number> = [];

    if (
      this.empresasSelecionadas === undefined ||
      this.empresasSelecionadas === null ||
      this.empresasSelecionadas.length === 0
    ) {
      empresaSelect.push(this.dblinkedSessionService.empresa.id);
    } else {
      empresaSelect = this.empresasSelecionadas;
    }

    for (let i = 1; i < 3; i++) {
      let today = new Date();
      today.setFullYear(today.getFullYear() - i);

      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
          dtInicial: new Date(today.getFullYear(), 0, 1).toString(),
          dtFinal: today.toString(),
          empresaid: empresaSelect.toString(),
        }),
      };

      this.httpClient
        .get(
          this.configService.defaultUrlApiUpload +
          "dashboard/financeiro/getcontabil",
          httpOptions
        )
        .subscribe(
          (res) => {
            let result: any = res["mensagem"];

            this.miniCardsValues["rendaRealYTDAnteriores"][i].ano =
              today.getFullYear();
            this.miniCardsValues["rendaRealYTDAnteriores"][i].value =
              result[0] === null ? 0 : result[0];
            this.miniCardsValues["rendaRealYTDAnteriores"][i - 1].variacao =
              this.calculaVariacao(
                this.miniCardsValues["rendaRealYTDAnteriores"][i - 1].value,
                this.miniCardsValues["rendaRealYTDAnteriores"][i].value
              );

            this.miniCardsValues["custoDespYTDAnteriores"][i].ano =
              today.getFullYear();
            this.miniCardsValues["custoDespYTDAnteriores"][i].value =
              result[1] === null ? 0 : result[1];
            this.miniCardsValues["custoDespYTDAnteriores"][i - 1].variacao =
              this.calculaVariacao(
                this.miniCardsValues["custoDespYTDAnteriores"][i - 1].value,
                this.miniCardsValues["custoDespYTDAnteriores"][i].value
              );

            this.miniCardsValues["capexYTDAnteriores"][i].ano =
              today.getFullYear();
            this.miniCardsValues["capexYTDAnteriores"][i].value =
              result[2] === null ? 0 : result[2];
            this.miniCardsValues["capexYTDAnteriores"][i - 1].variacao =
              this.calculaVariacao(
                this.miniCardsValues["capexYTDAnteriores"][i - 1].value,
                this.miniCardsValues["capexYTDAnteriores"][i].value
              );

            this.miniCardsValues["intangivelYTDAnteriores"][i].ano =
              today.getFullYear();
            this.miniCardsValues["intangivelYTDAnteriores"][i].value =
              result[3] === null ? 0 : result[3];
            this.miniCardsValues["intangivelYTDAnteriores"][i - 1].variacao =
              this.calculaVariacao(
                this.miniCardsValues["intangivelYTDAnteriores"][i - 1].value,
                this.miniCardsValues["intangivelYTDAnteriores"][i].value
              );
          },
          (err) => {
            console.log(err);
            this.exibirMensagem(err);
          }
        );
    }
  }

  calculaVariacao(ano1: number, ano2: number) {
    if (ano2 === 0 || isNaN(ano2) || ano2 === null || ano2 === undefined)
      return 0;
    if (ano1 === 0 || isNaN(ano1) || ano1 === null || ano1 === undefined)
      return 0;

    let ret = 0;

    if (ano1 < 0 && ano2 < 0) {
      ret = (ano2 / ano1) * 100;
    } else if (ano1 > 0 && ano2 < 0) {
      ret = (ano1 / (-ano2 / 2)) * 100;
    } else {
      ret = (ano1 / ano2) * 100;
    }

    return ret > 100 ? ret - 100 : (100 - ret) * -1;
  }

  onFilter(event: any) {

  }

  onRowSelect(event: any) {

  }

  onFilterDetalhePendentes(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }


}
