import { Pipe, PipeTransform } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { UtilService } from 'app/shared/services/util.service';

@Pipe({
  name: 'weight'
})
export class WeightPipe implements PipeTransform {
  private percentPipe = new PercentPipe('pt');

  constructor(private utilService: UtilService) {
  }

  transform(value: string): string {
    if (value !== undefined && value !== null) {
      return this.percentPipe.transform(this.utilService.parseMoneyString(value), '1.2-4');
    }
    return null;
  }
}
