import { column } from "./column";
import { SkeletonConfig } from "./skeletonConfig";

export class SkeletonModel {

  loadingThreads: number = 0;

  columnList: Array<column> = [];
  isLoading: boolean = false;
  hasFilter: boolean = false;
  hasSort: boolean = false;
  hasSelection: boolean = false;
  styleClass: string = null

  constructor(config?: SkeletonConfig) {
    this.columnList = config.columns;
    this.isLoading = config.isLoading || false;
    this.hasFilter = config.hasFilter || false;
    this.hasSort = config.hasSort || false;
    this.hasSelection = config.hasSelection || false;
    this.styleClass = config.styleClass || 'table-system'
  }

  setColumns(columns: Array<column>) {
    this.columnList = columns;
  }

  startLoad() {
    if (this.loadingThreads < 0) {
      this.loadingThreads = 0;
    }
    this.loadingThreads += 1;
    this.isLoading = true;
  }

  endLoad() {
    this.loadingThreads -= 1;
    if (this.loadingThreads < 1) {
      this.isLoading = false;
    }
    // console.log(this.loadingThreads)
  }

}
