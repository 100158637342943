import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { AppComponent } from '../../app.component';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { Table } from 'primeng/table';
import { Toolbar } from "app/shared/model/toolbar.model";
import exportExcel from 'app/util/processing/exportExcel';

@Component({
    selector: 'app-report',
    templateUrl: './report-balancete-mensal-lcto.component.html',
    styleUrls: ['./report-balancete-mensal-lcto.component.scss'],
    providers: [CrudService]
})
export class EcdReportBalanceteMensalLctoComponent implements OnInit, OnDestroy {
    // TODO: Review INPUTS
    @Input() id = 'id';
    @Input() codCta = 'codCta';
    @Input() dtIni = 'dtIni';
    @Input() dtFin = 'dtFin';
    @Input() tipo = 'tipo';
    @Input() encerramento = 'encerramento';

    private sessionSubscription: Subscription;

    private keyValue: string;
    private params: string;
    lctos: Array<any>;
    selectedPc: any;
    modal: boolean;
    // lctosPartida: Array<any>;
    lctosPartida: any[] = [];
    saldoInicial = 0;
    titlePrincipal: string;
    subtitle: string;
    dataConver : any

    contadorLinha: number = 0;

    //Toolbar
    toolbarMainIni = [
        { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.exportExcelAll()}
    ]

    public toolbarMain = new Toolbar()

    constructor(private titleService: TitleService,
      private toolbarService: ToolbarService,
      private httpService: HttpService,
                private utilService: UtilService,
                private sessionService: SessionService,
                private dblinkedSessionService: DblinkedSessionService,
                private configService: ConfigService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private crudService: CrudService,
                private appComponent: AppComponent) {
    }

    ngOnInit(): void {
      setTimeout(() => { this.appComponent._displaySideNav = false; });
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.modal = false;

        this.activatedRoute.params.subscribe(params => {
            this.id = params['id'];
            this.codCta = params['codCta'];
            this.dtIni = params['dtIni'];
            this.dtFin = params['dtFin'];
            this.tipo = params['tipo'];
            this.encerramento = params['encerramento'];
        });

        this.titleService.title = 'ECD - Relatórios/Balancete Mensal/Lançamentos ' +    this.codCta;
        Promise.resolve(null).then(() => this.toolbarService.hidden = true);

        this.httpService.wait();
        this.httpService.get('/custom/ecd-report/balanceteperiodolcto', [this.id,
                                                                         this.codCta,
                                                                         this.dtIni,
                                                                         this.dtFin,
                                                                         this.tipo,
                                                                         this.encerramento])
                        .subscribe(lctos => this.lctos = lctos,
                                   error => this.httpService.handleError(error, () => this.ngOnInit()),
                                   () => this.httpService.done());

        this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

        this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
                                                                             () => { this.change(); });

      this.titlePrincipal = 'RAZÃO CONTABIL';
      this.subtitle = 'CONTA: ' + this.codCta;

      this.contadorLinha = 0;
    }

    ngOnDestroy() {
      this.toolbarService.hidden = false;
      // this.toolbarService.clear();

      this.dblinkedSessionService.hidePeriods();

      this.sessionService.destroySubscribe(this.sessionSubscription);
    }

    change() {
      // this.toolbarService.clear();
      this.getSaldoInicial();
    }

    reportPdf() {
        alert('pdf');
    }

    reportXls() {
        alert('xls');
    }

    onRowSelect(event: any) {
        this.modal = true;

        this.httpService.wait();
        this.httpService.get('/custom/ecd-report/detalhelcto', [this.id, event.data.pta])
                        .subscribe(lctosPartida => {
                            this.lctosPartida = lctosPartida;

                            if(this.lctosPartida !== null || this.lctosPartida !== undefined){
                                this.contadorLinha = this.lctosPartida.length;
                                }
                                    else{
                                        this.contadorLinha = 0;
                                }

                        },

                        error => this.httpService.handleError(error, () => this.onRowSelect(event)),
                        () => this.httpService.done());



    }

    getSaldoInicial() {
        this.httpService.wait();
        this.httpService.get('/custom/ecd-report/saldoinicial', [this.id, this.codCta, this.dtIni])
                        .subscribe(saldoInicial => this.saldoInicial = saldoInicial,
                                   error => this.httpService.handleError(error, () => this.getSaldoInicial()),
                                   () => this.httpService.done());
    }

    exportarLcto(data: any) {
        this.httpService.wait();
        this.httpService.post('/custom/ecd-report/export-lcto(' + data.pta + ')')
                        .subscribe(result => this.download(result),
                                    error => this.httpService.handleError(error, () => this.exportarLcto(data)),
                                    () => this.httpService.done());
    }

    download(result: any) {
        if (!result.error) {
            this.windowOpen(this.httpService.url('/custom/file/' + result.file), '_blank');
        }
    }

    windowOpen(url: string, target?: string) {
        window.open(url, target);
    }

    onFilterlctosPartida(event: any) {
        this.contadorLinha = event.filteredValue.length;
      }

    exportExcelAll(){
        let exportList = []
        const orderList = ['data', 'numLancamento', 'lote', 'historico','cc','proj','debito','credito','saldoAMD','DC']
        this.lctos.forEach(lcto => {
            this.dataConver = new Date(lcto.dataLcto)
            this.dataConver.setHours(this.dataConver.getHours() + 3)
            exportList.push({
                data: this.dataConver.toLocaleDateString(),
                numLancamento: lcto.numero,
                lote: lcto.lote,
                historico: lcto.historico,
                cc: lcto.cc,
                proj: lcto.cp,
                debito: lcto.debito,
                credito: lcto.credito,
                saldoAMD: lcto.saldo,
                DC: lcto.indSaldo
            })
        })
        exportExcel(exportList, "Razão Contábil - Balancete", orderList)
    }
}
