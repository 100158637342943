import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Period } from 'app/shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { AppComponent } from '../../app.component';
import { CrudService } from 'app/shared/services/crud.service';
import { Regra } from 'app/cadastro/model/regra.model';
import { Grupo } from 'app/cadastro/model/grupo.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { View } from 'app/cadastro/model/view.model';
import { ProjetoDbl } from 'app/cadastro/model/projeto-dbl.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-grupo-regra-empresa-relac',
  templateUrl: './grupo-regra-empresa-relac.component.html',
  styleUrls: ['./grupo-regra-empresa-relac.component.scss'],
  providers: [CrudService]
})
export class GrupoRegraEmpresaRelacComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  regras: Array<Regra> = new Array();
  grupos: Array<Grupo> = new Array();
  gruposRelac: Array<Grupo> = new Array();
  empresas: Array<Empresa> = new Array();
  views: Array<View> = new Array();
  projetos: Array<ProjetoDbl> = new Array();
  opcoesRegras: SelectItem[];
  opcoesGrupos: SelectItem[];
  opcoesViews: SelectItem[];
  viewSelecionada: View;
  regraSelecionada: Regra;
  grupoSelecionado: Grupo;
  msgs: Message[] = [];

  gruposSelecionados: Grupo[];
  empresasSelecionadas: Empresa[];
  projetosSelecionados: ProjetoDbl[];
  title: string;
  subtitle: string;

  constructor(private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent,
    private messageService: MessageService) {
  }

  ngOnInit() {
    this.title = 'GERENCIAR RELACIONAMENTO DE REGRAS';
    this.subtitle = 'GERENCIE VIEW COM PROJETO, REGRA COM GRUPOS E GRUPOS COM EMPRESAS';
      this.titleService.title = 'Configurar Relacionamentos';
      Promise.resolve(null).then(() => this.toolbarService.hidden = true);
      this.regras = new Array<Regra>();
      this.grupos = new Array<Grupo>();
      this.gruposRelac = new Array<Grupo>();
      this.empresas = new Array<Empresa>();
      this.views = new Array<View>();
      this.viewSelecionada = new View();
      this.viewSelecionada = null;
      this.regraSelecionada = new Regra();
      this.regraSelecionada = null;
      this.grupoSelecionado = new Grupo();
      this.grupoSelecionado = null;

      this.projetos = new Array<ProjetoDbl>();
      this.projetosSelecionados = new Array<ProjetoDbl>();
      this.gruposSelecionados = new Array<Grupo>();
      this.empresasSelecionadas = new Array<Empresa>();
      this.opcoesRegras = [];
      this.opcoesGrupos = [];
      this.opcoesViews = [];

      this.httpService.wait();
    this.httpService.get('/regra?$select=id,cod,nome&$orderby=ordem')
      .subscribe(ref => this.loadRegras(ref.value),
      error => this.httpService.handleError(error, () => this.ngOnInit()),
      () => this.httpService.done());

      this.httpService.wait();
    this.httpService.get('/view?$select=id,nome&$orderby=nome')
      .subscribe(ref => this.loadViews(ref.value),
      error => this.httpService.handleError(error, () => this.ngOnInit()),
      () => this.httpService.done());

      this.httpService.wait();
    this.httpService.get('/grupo?$select=id,descricao&$orderby=descricao')
      .subscribe(ref => this.loadGrupos(ref.value),
      error => this.httpService.handleError(error, () => this.ngOnInit()),
      () => this.httpService.done());
  }


  ngOnDestroy() {
    this.toolbarService.hidden = false;
    // this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  loadGrupos(value: Array<Grupo>) {

    this.grupos = value;

    this.opcoesGrupos.push({ label: 'Selecione um Grupo', value: null });

    this.grupos.forEach(element => {
      this.opcoesGrupos.push({ label: element.descricao, value: element });
    });

  }

  loadRegras(value: Array<Regra>) {

    this.regras = value;

    this.opcoesRegras.push({ label: 'Selecione uma Regra', value: null });

    this.regras.forEach(element => {
      this.opcoesRegras.push({ label: element.cod + ' - ' + element.nome, value: element });
    });

    }

  loadEmpresas(value: Array<Empresa>) {

    this.empresas = value;
  }

  loadViews(value: Array<View>) {

    this.views = value;

    this.opcoesViews.push({ label: 'Selecione uma View', value: null });

    this.views.forEach(element => {
      this.opcoesViews.push({ label: element.nome, value: element });
    });

  }

  loadProjetos(value: Array<ProjetoDbl>) {

    this.projetos = value.filter(p => this.projetosSelecionados.findIndex(ps => ps.id === p.id) === -1);
  }

  loadGrupo(value: Array<Grupo>) {

    this.grupos = value.filter(g => this.gruposSelecionados.findIndex(gs => gs.id === g.id) === -1);
  }

loadEmpresa(value: Array<Empresa>) {

    this.empresas = value.filter(e => this.empresasSelecionadas.findIndex(es => es.id === e.id) === -1);
  }

  onChangeView(event: any) {
      this.projetos = new Array<ProjetoDbl>();
      this.projetosSelecionados = new Array<ProjetoDbl>();

      if (event.value.id !== null) {

        this.httpService.wait();
          this.httpService.get('/viewprojetodblrelac?$select=projetoDbl' +
          '&$filter=view/id eq ' + event.value.id +
          '&$orderby=projetoDbl/descricao')
          .subscribe(ref => this.loadProjetosSelecionados(ref.value),
          error => this.httpService.handleError(error, () => this.onChangeView(event)),
          () => this.httpService.done());

      }
  }

  onChangeRegra(event: any) {
      this.grupos = new Array<Grupo>();
      this.gruposSelecionados = new Array<Grupo>();

      if (event.value.id !== null) {

          this.httpService.wait();
          this.httpService.get('/regragruporelac?$select=grupo' +
          '&$filter=regra/id eq ' + event.value.id +
          '&$orderby=grupo/descricao')
          .subscribe(ref => this.loadGruposSelecionados(ref.value),
          error => this.httpService.handleError(error, () => this.onChangeRegra(event)),
          () => this.httpService.done());
      }

  }

  onChangeGrupo(event: any) {
      this.empresas = new Array<Empresa>();
      this.empresasSelecionadas = new Array<Empresa>();

      if (event.value.id !== null) {

          this.httpService.wait();
          this.httpService.get('/grupoempresarelac?$select=empresa/id,empresa/razaoSocial,empresa/nomeFantasia,' +
          'empresa/telefone,empresa/fax,empresa/email,empresa/endereco,empresa/municipioIbge/id,empresa/numero,empresa/complemento,' +
          'empresa/bairro,empresa/cep,empresa/cnpj,empresa/cnae,empresa/ie,empresa/ieSt,empresa/im,empresa/tipoLucro,' +
          'empresa/inicioAtividades,empresa/nire,empresa/respRfb,empresa/regimeFiscal,empresa/dtRegJunta,empresa/cpf,empresa/sistema,' +
          'empresa/baseInterna,empresa/acesso,empresa/usuario,empresa/senha,empresa/suframa,empresa/acessoRemoto,empresa/legEsp,' +
          'empresa/particularidades,empresa/icms,empresa/ipi,empresa/icmsSt,empresa/issqn,empresa/pisCofins,empresa/infoStatus,' +
          'empresa/regimeCxComp&$filter=grupo/id eq ' + event.value.id + '&$orderby=empresa/razaoSocial')
          .subscribe(ref => this.loadEmpresasSelecionadas(ref.value),
          error => this.httpService.handleError(error, () => this.onChangeGrupo(event)),
          () => this.httpService.done());
      }
  }

  loadProjetosSelecionados(value: any) {
      value.forEach(element => {
        this.projetosSelecionados.push(element.projetoDbl);
      });

      this.httpService.wait();
          this.httpService.get('/projetodbl?$select=id,cod,descricao&$orderby=cod')
          .subscribe(ref => this.loadProjetos(ref.value),
          error => this.httpService.handleError(error, () => this.loadProjetosSelecionados(value)),
          () => this.httpService.done());
  }

  loadGruposSelecionados(value: any) {

      value.forEach(element => {
        this.gruposSelecionados.push(element.grupo);
      });

      this.httpService.wait();
          this.httpService.get('/grupo?$select=id,descricao&$orderby=descricao')
          .subscribe(ref => this.loadGrupo(ref.value),
          error => this.httpService.handleError(error, () => this.loadGruposSelecionados(value)),
          () => this.httpService.done());
  }

  loadEmpresasSelecionadas(value: any) {

      value.forEach(element => {
        this.empresasSelecionadas.push(element.empresa);
      });

      this.httpService.wait();
          this.httpService.get('/empresa?$select=id,cnpj,razaoSocial&$orderby=razaoSocial')
          .subscribe(ref => this.loadEmpresa(ref.value),
          error => this.httpService.handleError(error, () => this.loadEmpresasSelecionadas(value)),
          () => this.httpService.done());
  }

  atualizarRelacView() {
   const listaViewEnviar = {
      view: this.viewSelecionada,
      projetosSelecionados: this.projetosSelecionados
    };

    this.httpService.wait();
    this.httpService.post('/custom/dbl/atualizar-lista-relac-view', listaViewEnviar)
      .subscribe(result => this.successAtuaView(result),
      error => this.httpService.handleError(error, () => this.atualizarRelacView()),
      () => this.httpService.done());
  }

  atualizarRegraGrupo() {
   const listaRegraEnviar = {
      regra: this.regraSelecionada,
      gruposSelecionados: this.gruposSelecionados
    };

    this.httpService.wait();
    this.httpService.post('/custom/dbl/atualizar-lista-relac-regra', listaRegraEnviar)
      .subscribe(result => this.successAtuaView(result),
      error => this.httpService.handleError(error, () => this.atualizarRegraGrupo()),
      () => this.httpService.done());
  }

  atualizarGrupoEmpresa() {
   const listaGrupoEnviar = {
      grupo: this.grupoSelecionado,
      empresasSelecionadas: this.empresasSelecionadas
    };

    this.httpService.wait();
    this.httpService.post('/custom/dbl/atualizar-lista-relac-grupo', listaGrupoEnviar)
      .subscribe(result => this.successAtuaView(result),
      error => this.httpService.handleError(error, () => this.atualizarRegraGrupo()),
      () => this.httpService.done());
  }

  successAtuaView(event: any) {

        this.messageService.add({severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: 'Relacionamento atualizado!'});
        this.ngOnInit();
  }

}
