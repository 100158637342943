export class Uf {
  id: number;
  codIbge: string;
  uf: string;
  periodo: string;

  constructor(uf?: Uf | number) {
    if (uf !== undefined && uf !== null) {
      if (typeof uf === 'number') {
        this.id = uf;
      } else {
        this.id = uf.id;
        this.codIbge = uf.codIbge;
        this.uf = uf.uf;
        this.periodo = uf.periodo;
      }
    }
  }
}
