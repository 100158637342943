import { Injectable } from '@angular/core';

import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { ConfigService } from './config.service';
import { JsogService } from './jsog.service';
import { HttpService } from './http.service';

@Injectable()
export class SocketService {
  private webSocket: WebSocket;
  private sendTimeout: NodeJS.Timer;
  private connectedSubscription: Subscription;

  public messages: Subject<any> = new Subject();

  constructor(private configService: ConfigService,
              private jsogService: JsogService,
              private httpService: HttpService) {
    if (this.configService.useSocket) {
      this.connectedSubscription = this.httpService.connected.subscribe((connected: boolean) => {
        if (connected) {
          this.ping();
        } else {
          this.offline();
        }
      });
    }
  }

  init(callback?: Function) {
    this.webSocket = new WebSocket(this.configService.wsUrl + '/proof/socket/main');

    this.webSocket.onopen = () => {
      this.online();

      if (callback && typeof callback === 'function') {
        callback();
      }
    };

    this.webSocket.onmessage = (message: any) => {
      let data = null;

      try {
        data = this.jsogService.parse(message.data);
      } catch (exception) {
        data = message.data;
      }

      this.messages.next(data);
    };

    this.webSocket.onerror = (data?: any) => {
      console.warn('Socket error:', data);
    };

    this.webSocket.onclose = (data?: any) => {
      this.offline();
    };
  }

  send(data: any) {
    if (!this.configService.useSocket) {
      return;
    }

    clearTimeout(this.sendTimeout);

    if (this.webSocket) {
      switch (this.webSocket.readyState) {
        case WebSocket.CLOSED:
          this.init();
          this.sendTimeout = setTimeout(() => this.send(data), this.httpService.offline ? this.configService.onlineCheckInterval : 50);
          break;
        case WebSocket.CLOSING:
        case WebSocket.CONNECTING:
          this.sendTimeout = setTimeout(() => this.send(data), this.httpService.offline ? this.configService.onlineCheckInterval : 50);
          break;
        case WebSocket.OPEN:
          this.webSocket.send(this.jsogService.stringify(data));
          break;
        default:
          console.warn('ERROR SENDING');
      }
    } else {
      this.init();
      this.sendTimeout = setTimeout(() => this.send(data), this.httpService.offline ? this.configService.onlineCheckInterval : 50);
    }
  }

  ping() {
    this.send({
      action: 'ping'
    });
  }

  offline(callback?: Function) {
    this.httpService.goOffline(null, () => this.init());
  }

  online() {
    this.httpService.goOnline();
  }
}
