import { Provider, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { Period } from './period.model';

export abstract class AbstractValueAccessor implements ControlValueAccessor {
    private _value: Period = null;
    get value(): Period {
      return this._value;
    }
    set value(value: Period) {
      if (value !== this._value || value === null) {
        this._value = value;
        this.onChange(value);
      }
    }

    writeValue(value: Period) {
      this._value = value;
      this.onChange(value);
    }

    onChange = (_) => {};
    onTouched = () => {};
    registerOnChange(fn: (_: Period) => void): void { this.onChange = fn; }
    registerOnTouched(fn: () => void): void { this.onTouched = fn; }
}
