import { Component, OnInit, OnDestroy } from '@angular/core'
import { TitleService } from 'app/shared/services/title.service'
import { HttpService } from 'app/shared/services/http.service'
import { ToolbarService } from '../../shared/services/toolbar.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { SessionService } from 'app/shared/services/session.service'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { ConfigService } from 'app/shared/services/config.service'
import { Subscription } from 'rxjs'
import { Toolbar } from 'app/shared/model/toolbar.model'
import { ActivatedRoute, Router } from '@angular/router'
import ShortcutsMethods from '../util/shortcutsMethods'
import { HttpClient } from '@angular/common/http'
import { MessageService, SelectItem } from 'primeng/api'
import { SidebarService } from 'app/shared/services/sidebar.service'
import { Dashboard } from '../interfaces/Dashboard'
import { ToolbarOption } from 'app/shared/model/toolbarOption'
import { Shortcut } from '../interfaces/Shortcut'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class DashboardComponent extends ShortcutsMethods implements OnInit, OnDestroy {

  private sessionSubscription: Subscription

  title: string
  subtitle: string
  originDashSelected: string = null
  currentStateShortcuts: string = 'initial'
  dropdownMessage: string = 'Carregando...'

  isCardShortcuts: boolean = false
  showSettingsModal: boolean = false
  isNoTab: boolean = true
  isShortcuts: boolean = false
  isDash: boolean = false
  isEmail: boolean = false
  hideShortcuts: boolean = false
  btnShortcutsLoading: boolean = false
  toolbarLoading: boolean = true
  tableShortcutsLoading: boolean = true
  dashboardsLoading: boolean = true
  isReady: boolean = false
  isLoadingOptIntervals: boolean = false
  isLoadingOptIconIntervals: boolean = false
  btnEmailLoading: boolean = false

  selectedDashId: number
  empresaId: number
  userId: number
  cardOpening: number
  tabOpening: number = 0

  shortcutsData: Array<Shortcut> = new Array()
  selectedShortcuts: Array<Shortcut> = new Array()
  dashboardAvailable: Array<Dashboard> = new Array()
  dashboardData: Array<Dashboard> = new Array()
  toolbarShortcutsButtons: Array<ToolbarOption> = new Array()
  tableShortcutLoading: Array<Object> = new Array()
  selectedRanges: Array<Number> = new Array()

  favoriteDashs: SelectItem[] = []
  optIntervals: SelectItem[] = []

  dashSelectedReset: Dashboard = { link_image: null, origin: null, name: null, description: null, id: null, active: null, isMain: null, isFavorite: null }
  dashSelected: Dashboard = {...this.dashSelectedReset}

  toolbarShortcuts: Toolbar = new Toolbar()

  constructor(
    private sidebarService: SidebarService,
    private activatedRoute: ActivatedRoute,
    public titleService: TitleService,
    public httpService: HttpService,
    public httpClient: HttpClient,
    public toolbarService: ToolbarService,
    public sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    public configService: ConfigService,
    public router: Router,
    public messageService: MessageService,
  ) { super(router, configService, httpClient, dblinkedSessionService, httpService, sessionService, messageService) }

  public ngOnInit(): void {
    this.titleService.title = 'Área inicial'
    this.title = 'Olá, ' + this.sessionService.loggedUser.displayName
    this.subtitle = 'Seja bem-vindo de volta!'

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if(this.dblinkedSessionService.hasEmpresaSelected) this.initialize()
    })

    this.activatedRoute.params.subscribe(params => {
      if(params.card && params.card !== null && params.card !== undefined) {
        this.cardOpening = parseInt(params.card)
        this.tabOpening = params.tab ? parseInt(params.tab) : 0
      }
    })

    this.sidebarService.isReady.subscribe(item => {
      if(item && this.cardOpening !== null && this.cardOpening !== undefined) setTimeout(() => {
        this.sidebarService.open(true)
        this.sidebarService.rowSelected([this.cardOpening])
        this.sidebarService.selectTab(this.tabOpening)
      }, 1)
    })

    Promise.resolve(null).then(() => this.toolbarService.hidden = true)
  }

  public ngOnDestroy(): void {
    this.toolbarService.hidden = false
    this.sessionSubscription.remove(this.sessionSubscription)
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  private async initialize(): Promise<void> {
    if(this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
      this.empresaId = this.dblinkedSessionService.empresa.id
      this.userId = this.sessionService.loggedUser.id
      this.isReady = true
      this.isNoTab = true
      this.isShortcuts = false
      this.isDash = false
      this.isEmail = false
      this.originDashSelected = null
      this.dashboardData = new Array()
      this.tableShortcutLoading = [{}, {}, {}]
      this.dashSelected = {...this.dashSelectedReset}
      this.selectedDashId = null
      this.selectedRanges = new Array()

      Promise.all([
        this.getShortcutsAvailable(),
        this.getHideShortcuts(),
        this.getMyShortcuts(),
        this.getDashboardsAvailable(),
        this.getOptIntervals(),
        this.getMyEmailPermission()
      ])
    }
  }

  private async getHideShortcuts(): Promise<void> {
    try{
      await this.httpService.get(`/custom/cadastro/configuracao-homepage(${this.empresaId},${this.userId})`).toPromise().then(res => {
        if(!res.error) this.hideShortcuts = res.hideShortcut
        else this.showResponse('error', res.detail)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar suas configurações! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  public async saveHideShortcuts(): Promise<void> {
    const payload = {
      hideShortcut: this.hideShortcuts,
      empresaId: this.empresaId,
      userId: this.userId
    }

    try{
      await this.httpService.post('/custom/cadastro/configuracao-homepage', payload).toPromise().then(res => {
        if(!res.error) this.hideShortcuts = res.hideShortcut
        else this.showResponse('error', res.detail)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar suas configurações! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getShortcutsAvailable(): Promise<void> {
    try{
      await this.httpService.get(`/custom/cadastro/atalho-homepage?empresaId=${this.empresaId}&userId=${this.userId}`).toPromise().then(res => {
        if(!res.error) this.shortcutsData = res.map((item, i) => {
          item.order = i + 1
          item.shortcutId = item.id
          item.selected = false
          item.disabledSelection = false
          return item
        })
        else this.showResponse('error', res.detail)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os atalhos disponíveis! Contate o suporte')
      )
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.tableShortcutsLoading = false
    }
  }

  private async getMyShortcuts(): Promise<void> {
    try{
      await this.httpService.get(`/custom/cadastro/atalhos-favoritos-usuario(${this.empresaId},${this.userId})`).toPromise().then(res => {
        if(!res.error) {
          this.buildShortcuts(this.sortByOrder(res.shortcuts, 'descending'))
          this.buildToolbar(this.sortByOrder(res.shortcuts, 'ascending'))
        } else this.showResponse('error', res.detail)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar seus atalhos! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.toolbarLoading = false
    }
  }

  public async saveMyShortcuts(): Promise<void> {
    let aux: Array<any> = new Array()
    this.selectedShortcuts = this.sortByOrder(this.selectedShortcuts, 'ascending')
    this.selectedShortcuts.forEach(item => aux.push({shortcutId: item.shortcutId, order: item.order}))

    this.btnShortcutsLoading = true

    const payload = {
      shortcuts: aux,
      empresaId: this.empresaId,
      userId: this.userId
    }

    try{
      await this.httpService.post('/custom/cadastro/atalhos-favoritos-usuario', payload).toPromise().then(res => {
        if(!res.error) this.buildToolbar(res.shortcuts)
        else this.showResponse('error', res.detail)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar seus atalhos! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.btnShortcutsLoading = false
    }
  }

  private async getDashboardsAvailable(): Promise<void> {
    try {
      await this.httpService.get(`/custom/cadastro/dashboard-homepage?empresaId=${this.empresaId}&userId=${this.userId}`).toPromise().then(res => {
        this.dashboardAvailable = res.map(item => { return item })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as dashboards disponíveis! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      await this.getMyDashboards()
      this.dashboardsLoading = false
    }
  }

  private async getMyDashboards(): Promise<void> {
    try {
      await this.httpService.get(`/custom/cadastro/dashboard-homepage-user(${this.empresaId},${this.userId})`).toPromise().then(res => {
        if(!res.error) {
          this.favoriteDashs = new Array()
          res.dashboards.forEach(item => {
            this.dashboardAvailable.forEach(elem => {
              if(item === elem.id) {
                elem.isFavorite = true
                if(elem.id === res.dashboard_main) {
                  elem.isMain = true
                  this.dashSelected = elem
                  this.selectedDashId = this.dashSelected.id
                }

                if(elem.isFavorite) this.favoriteDashs.push({ label: elem.name, value: elem.id })
              }
            })
          })
        }
        else this.showResponse('error', res.detail)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar suas dashboards! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.dropdownMessage = 'Nenhuma dashboard favorita!'
    }
  }

  public async setDashUser(dash: Dashboard, setIsMain: boolean): Promise<void> {
    if(setIsMain) this.dashboardAvailable.forEach(item => {
      if(item.id === dash.id) {
        item.isMain = !item.isMain
        item.isFavorite = item.isMain ? true : item.isFavorite

        dash.isMain = item.isMain
        dash.isFavorite = item.isFavorite

        this.dashSelected = item.isMain ? item : {...this.dashSelectedReset}
        this.selectedDashId = this.dashSelected.id

        this.showResponse('info', item.isMain ? `Agora a dashboard: ${item.name} é sua principal!` : 'Agora você não possui dashboard principal!')
      } else {
        item.isMain = false
      }
    })
    else this.dashboardAvailable.forEach(item => {
      if(item.id === dash.id) {
        item.isFavorite = !item.isFavorite
        dash.isFavorite = item.isFavorite

        if(item.isFavorite) this.favoriteDashs.push({ label: item.name, value: item.id })
        else this.favoriteDashs = this.favoriteDashs.filter(line => line.value !== item.id)
      }
    })

    const payload = {
      empresaId: this.empresaId,
      userId: this.userId,
      dashboardId: dash.id,
      isFavorite: dash.isFavorite,
      isMain: dash.isMain
    }

    try {
      await this.httpService.post('/custom/cadastro/dashboard-homepage-favorita', payload).toPromise().then(res => {
        if(res.error) this.showResponse('error', res.detail)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar sua dashboard! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getOptIntervals(): Promise<void> {
    this.isLoadingOptIntervals = true
    this.isLoadingOptIconIntervals = true

    try {
      await this.httpService.get('/custom/cadastro/listar-intervalos').toPromise().then(res => {
        if(!res.error) {
          this.optIntervals = new Array()
          if(res.data.length > 0) res.data.forEach(item => this.optIntervals.push({ label: item.desc, value: item.id }) )
        } else this.showResponse('error', res.detail)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao buscar os intervalos! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isLoadingOptIntervals = false
      this.isLoadingOptIconIntervals = false
    }
  }

  private async getMyEmailPermission(): Promise<void> {
    try {
      await this.httpService.get(`/custom/cadastro/configuracao-relatorio(${this.empresaId},${this.userId})`).toPromise().then(res => {
        if(!res.error) {
          this.selectedRanges = res.data
        } else this.showResponse('error', res.detail)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao buscar suas configurações de e-mail! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isLoadingOptIntervals = false
      this.isLoadingOptIconIntervals = false
    }
  }

  public async setDashboardPermission(): Promise<void> {
    const payload = {
      empresaId: this.empresaId,
      userId: this.userId,
      intervaloId: this.selectedRanges,
      tipoRelatId: 1,
    }

    this.btnEmailLoading = true

    try {
      await this.httpService.post('/custom/cadastro/config-relatorio-email', payload).toPromise().then(res => {
        this.showResponse(res.severity, res.detail)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar suas configurações de e-mail! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isLoadingOptIntervals = false
      this.isLoadingOptIconIntervals = false
      this.btnEmailLoading = false
    }
  }

  private buildShortcuts(shortcuts: Array<any>): void {
    this.selectedShortcuts = new Array()

    shortcuts.forEach(item => {
      this.shortcutsData.forEach(elem => {
        if(item.shortcutId === elem.shortcutId) {
          this.shortcutsData.forEach(unit => { if(item.order === unit.order) unit.order = elem.order })
          elem.order = item.order
          elem.selected = true
          this.selectedShortcuts.push(elem)
        }
      })
    })

    this.shortcutsData = this.sortByOrder(this.shortcutsData, 'ascending')
  }

  private buildToolbar(shortcuts: Array<any>): void {
    this.toolbarShortcutsButtons = new Array()

    shortcuts.forEach(item => {
      this.shortcutsData.forEach(elem => {
        if(elem.shortcutId === item.shortcutId) this.toolbarShortcutsButtons.push({
          icon: elem.icon,
          text: elem.shortcut,
          title: elem.module,
          color: 'purple',
          onClick: () => this.shortcutsActions(elem.action)
        })
      })
    })

    this.toolbarShortcuts.setAll(this.toolbarShortcutsButtons)
    this.checkShortcutsLength()
  }

  public changeDashboard(): void {
    this.dashboardAvailable.forEach(item => { if(item.id === this.selectedDashId) this.dashSelected = item })
  }

  private sortByOrder(arr: Array<any>, order: string): Array<any> {
    if(order === 'ascending') return arr.sort((a, b) => a.order - b.order)
    else if(order === 'descending') return arr.sort((a, b) => b.order - a.order)
    else return ['the operation entered is invalid']
  }

  private shortcutsActions(shortcut: string): void {
    this.shortcutsMethods[shortcut]()
  }

  public onRowReorder(): void {
    this.shortcutsData.forEach((item, index) => {
      item.order = index + 1

      this.selectedShortcuts.forEach(elem => {
        if(item.shortcutId === elem.shortcutId) elem.order = item.order
      })
    })
  }

  public onRowSelect(event: any): void {
    this.shortcutsData.forEach(item => { if(item.shortcutId === event.data.shortcutId) item.selected = true })
    this.checkShortcutsLength()
  }

  public onRowUnselect(event: any): void {
    this.shortcutsData.forEach(item => { if(item.shortcutId === event.data.shortcutId) item.selected = false })
    this.checkShortcutsLength()
  }

  private checkShortcutsLength(): void {
    if(this.selectedShortcuts.length === 8) this.shortcutsData.forEach(item => item.disabledSelection = !item.selected)
    else this.shortcutsData.forEach(item => item.disabledSelection = false)
  }

  public changeSettingsTab(tab: number, dash?: string): void {
    this.isNoTab = false
    this.isShortcuts = false
    this.isDash = false
    this.isEmail = false
    this.originDashSelected = null
    this.dashboardData = new Array()

    switch(tab) {
      case 0:
        this.isShortcuts = true
      break
      case 1:
        this.isDash = true
        this.originDashSelected = dash
        this.dashboardAvailable.forEach(item => { if(item.origin.toLowerCase() === this.originDashSelected) this.dashboardData.push(item) })
      break
      case 2:
        this.isEmail = true
      break
    }
  }

  public isNotReady(): string {
    if(!this.dblinkedSessionService.hasEmpresaSelected) return 'Seleciona uma empresa para continuar'
    if(!this.dblinkedSessionService.hasPeriodoSelected) return 'Selecione um período para continuar'
  }

  public changeStateShortcuts(): void {
    this.currentStateShortcuts = this.currentStateShortcuts === 'initial' ? 'final' : 'initial'
    this.isCardShortcuts = !this.isCardShortcuts
  }

  public openModal(): void {
    this.showSettingsModal = true
  }

}
