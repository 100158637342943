import { Component, OnInit } from '@angular/core';
import { TipoArquivo} from '../model/tipo-arquivo.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { FormControl, FormGroup } from '@angular/forms';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { ConfigService } from 'app/shared/services/config.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-tipo-arquivo',
  templateUrl: './tipo-arquivo.component.html',
  styleUrls: ['./tipo-arquivo.component.scss'],
  providers: [CrudService]
})
export class TipoArquivoComponent extends DataTableBasicComponent implements OnInit {
  public tipoArquivo: TipoArquivo = new TipoArquivo();
  public registrosTipoArquivo: Array<TipoArquivo> = new Array();

  formulario: FormGroup;

  ctrlSave: boolean;
  entityFilter: string = null;
  editarConta: boolean;
  novaConta: boolean;
  ids: Array<any> = new Array();
  select: Array<any> = new Array();

  title = 'Tipo Arquivo';
  subtitle = "Tipo Arquivo";

  private sessionSubscription: Subscription;

  public toolbarMain = new Toolbar();

  toolbarMainIni = [
    { key: "voltar", icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: "edit", icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { key: "delete", icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirRegistro() },
    { key: "add", icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.criarNovo() },
    { key: "saveNew", icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'green', onClick: () => this.salvarNovoRegistro() },
    { key: "save", icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'green', onClick: () => this.salvarAlteracaoRegistro() },
  ]
  constructor(crudService: CrudService,
      private toolbarService: ToolbarService,
      private sessionService: SessionService,
      private dblinkedSessionService: DblinkedSessionService,
      private httpService: HttpService,
      private configService: ConfigService,
      private messageService: MessageService,
      ) {
    super(crudService);
  }

  ngOnInit() {
    this.toolbarService.hidden = true;
    this.toolbarMain.setAll(this.toolbarMainIni);
    this.createForm(new TipoArquivo());

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
      );
  }

  createForm(tipoArquivo: TipoArquivo){
    this.formulario = new FormGroup({
      nome: new FormControl(tipoArquivo.nome),
      descricao: new FormControl(tipoArquivo.descricao),
    })
  }


  carregar() {
    this.toolbarMain.setAll(this.toolbarMainIni);

    this.title = 'Tipo Arquivo';
    this.subtitle = 'Tipo Arquivo';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.select = new Array();

    this.limpaCamposForm();

    this.loadRegistros();
  }

  criarNovo() {
    this.novaConta = true;
    this.editarConta = false;
    this.title = 'Cadastrar';

    this.toolbarMain.setVisible('saveNew', this.novaConta);
    this.toolbarMain.setVisible('voltar', this.novaConta);
    this.toolbarMain.setVisible('add', false);

  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService
      .get(`/tipoarquivo?$select=nome,descricao&$count=true&$skip=0&$orderby=nome`)
      .subscribe(
        (retorno) => {
          this.registrosTipoArquivo = new Array();
          retorno.value.forEach(item => {
            this.registrosTipoArquivo.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }


  loadEditarRegistro() {
    this.title = 'Editar';
    this.editarConta = true;
    this.novaConta = false;

    this.toolbarMain.setVisible('delete', this.editarConta);
    this.toolbarMain.setVisible('save', this.editarConta);
    this.toolbarMain.setVisible('voltar', this.editarConta);
    this.toolbarMain.setVisible('add', false);
    this.toolbarMain.setVisible('edit',  false);

    this.limpaCamposForm();

    this.httpService.wait();
    this.httpService.get(`/tipoarquivo(${this.ids[0]})?$select=nome,descricao&$count=true`)
      .subscribe(item => {
        this.formulario.setValue({
          nome: item.value.nome,
          descricao: item.value.descricao,
        })
      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    this.httpService.wait();
    this.httpService.post('/tipoarquivo', this.formulario.value)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/tipoarquivo', key, this.formulario.value, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar o registro selecionado? ')) {
      this.httpService.wait();
      this.httpService.delete('/tipoarquivo', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Registro excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
    }
  }

  onRowSelect(event: any) {
    this.ids = new Array();
    this.ids.push(event.data.id);

    const choosen = this.select[this.select.length - 1];
    this.select.length = 0;
    this.select.push(choosen);
    this.change()
  }

  onRowUnselect(event: any) {
    this.select = new Array();
    this.ids = new Array();
    this.change();
  }

  change(){
    const selectionSize = this.select.length;
    this.toolbarMain.setVisible('edit', selectionSize >=1); 
    this.toolbarMain.setVisible('delete', selectionSize >=1); 
  }

  validacao(): boolean {
      if (this.formulario.value.nome === undefined || this.formulario.value.nome === null || this.formulario.value.nome == '' || this.formulario.value.nome.length < 1 || this.formulario.value.nome.length > 255) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nome inválido' });
        return true;
      }

      if (this.formulario.value.descricao === undefined || this.formulario.value.descricao === null || this.formulario.value.descricao == '' || this.formulario.value.descricao.length < 1  || this.formulario.value.descricao.length > 255) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição inválida' });
        return true;
      }

    return false;
  }

  private limpaCamposForm() {
    this.formulario.reset(new TipoArquivo());
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
