import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { MessageService, SelectItem } from 'primeng/api';
import { ConfigPattern } from '../../interfaces/bs2';
import { resetConfigPattern } from '../../utils/reset-bs2-form';

@Component({
  selector: 'app-cfg-bs2',
  templateUrl: './cfg-bs2.component.html',
  styleUrls: ['./cfg-bs2.component.scss']
})
export class CfgBs2Component implements OnInit, OnChanges {

  @Input() isRender: boolean
  @Input() isSave: boolean
  @Input() isConfigExists: boolean
  @Output() isValid = new EventEmitter()

  httpOptions: Object = new Object()

  listOFXMemoRelated: Array<any> = new Array()
  listOFXMemoToRelate: Array<any> = new Array()

  optOperationAccountingRule: Array<SelectItem> = new Array()

  _isConfigExists: boolean = false
  loadingConfigPattern: boolean = false
  loadingOptProvider: boolean = true
  loadingIconOptProvider: boolean = false
  loadingOptCustomer: boolean = true
  loadingIconOptCustomer: boolean = false
  loadingOptOperation: boolean = true
  loadingIconOptOperation: boolean = false
  loadingOFXMemo: boolean = false
  modalParticipant: boolean = false

  empresaId: number = null
  userId: number = null
  operacaoVinculadaId: number = null
  participantTypeId: number = null

  clientCode: string = null
  clientFantasyName: string = null
  providerCode: string = null
  providerFantasyName: string = null

  config: ConfigPattern = resetConfigPattern()

  constructor(
    private httpService: HttpService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  public ngOnInit(): void {
    this.empresaId = this.dblinkedSessionService.empresa.id
    this.userId = this.sessionService.loggedUser.id

    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    this.isValid.emit({ validatedSteps: false })
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.isSave && changes.isSave.currentValue) this.saveConfigPattern()
    if(changes.isConfigExists && changes.isConfigExists.currentValue) this._isConfigExists = true
    if(changes.isRender && changes.isRender.currentValue) this.initialize(this._isConfigExists)
  }

  private async initialize(isConfigExists: boolean): Promise<void> {
    this.isValid.emit({ validatedSteps: true })

    if (isConfigExists) this.loadingConfigPattern = true

    await Promise.all([ this.getOptOperationAccountingRule() ])
    if (isConfigExists) this.getConfigPattern()
  }

  private async getConfigPattern(): Promise<void> {
    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/cadastro/configuracao-api?empresaId=${this.empresaId}&codigo=BS2`, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          this.config.refreshToken = res['data'][0].chave_api
          this.config.chaveAcesso = res['data'][0].usuario
          this.config.clientePadraoId = res['data'][0].cliente_padrao_id
          this.config.fornecedorPadraoId = res['data'][0].fornecedor_padrao_id
          this.clientCode = res['data'][0].codigo_cliente
          this.clientFantasyName = res['data'][0].fantasia_cliente
          this.providerCode = res['data'][0].codigo_fornecedor
          this.providerFantasyName = res['data'][0].fantasia_fornecedor
        } else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar os dados padrões da sua configuração! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingConfigPattern = false
    }
  }

  private async saveConfigPattern(): Promise<void> {
    this.isValid.emit({ validatedSteps: true })
    this.validationConfig()

    const payload = {
      empresa_id: this.empresaId,
      user_id: this.userId,
      codigo: 'BS2',
      usuario: this.config.chaveAcesso,
      cliente_padrao_id: this.config.clientePadraoId,
      fornecedor_padrao_id: this.config.fornecedorPadraoId,
      chave_api: this.config.chaveAcesso
    }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/configuracao-api', payload, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) this.saveFamilyRelationship()
        else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar os dados padrões da sua configuração! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async saveFamilyRelationship(): Promise<void> {
    const httpOptions: Object = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    const payload: Object = { empresa_id: this.dblinkedSessionService.empresa.id, familia_id: 3 }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/empresa-familia', payload, httpOptions).toPromise().catch(err =>
        this.showResponse('error', err.status + ' - Houve um erro ao salvar a família de API Conciliadora!')
      )
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  public validationConfig(): void {
    this.isValid.emit({ validatedSteps: (this.config.chaveAcesso && this.config.clientePadraoId && this.config.fornecedorPadraoId) })
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso: ', detail: message })
      break
    }
  }

  public loadParticipant(participantTypeId: number) {
    this.participantTypeId = participantTypeId
    this.modalParticipant = true
  }

  public saveParticipant(participantTypeId: number, data) {
    if (participantTypeId === 1) {
      this.clientCode = data.selectedParticipant.cod_part
      this.clientFantasyName = data.selectedParticipant.fantasia
      this.config.clientePadraoId = data.selectedParticipant.part_emp_id
    } else if (participantTypeId === 2) {
      this.providerCode = data.selectedParticipant.cod_part
      this.providerFantasyName = data.selectedParticipant.fantasia
      this.config.fornecedorPadraoId = data.selectedParticipant.part_emp_id
    }
  }

  private async getOptOperationAccountingRule(): Promise<void> {
    this.loadingOptOperation = true
    this.loadingIconOptOperation = true

    try {
      await this.httpService.get(this.configService.defaultUrlApiUpload + 'get_distinct_operacao_regra_contabil/' + this.dblinkedSessionService.empresa.id).toPromise().then(res => {
        if (res["severity"] !== "error") {
          this.optOperationAccountingRule = new Array()
          res["data"].forEach(element => { this.optOperationAccountingRule.push(element) })
        }
      }).catch(err => this.showResponse('error', err.status + `- Houve um erro as regras de operação contábeis! Contate o suporte`))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptOperation = false
      this.loadingIconOptOperation = false
    }
  }

  public changeOperationAccountingRule(): void {
    this.validationConfig()
    this.getListOFXMemo()
  }

  private async getListOFXMemo(): Promise<void> {
    this.loadingOFXMemo = true

    try {
      await this.httpService.get(this.configService.defaultUrlApiUpload + 'get_distinct_associated_memo_with_rules/' + this.dblinkedSessionService.empresa.id + '_' + this.operacaoVinculadaId).toPromise().then(res => {
        this.listOFXMemoRelated = new Array();
        this.listOFXMemoToRelate = new Array();
        res["data"][0].forEach(element => this.listOFXMemoToRelate.push(element))
        res["data"][1].forEach(element => this.listOFXMemoRelated.push(element))
      }).catch(err => this.showResponse('error', err.status + `- Houve um erro as regras de operação contábeis! Contate o suporte`))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOFXMemo = false
    }
  }

  public async saveListOFXMemo(): Promise<void> {
    const payload = {
      limemo: this.listOFXMemoRelated,
      regra_contabil_id: this.operacaoVinculadaId,
      user_id: this.sessionService.loggedUser.id,
      empresa_id: this.dblinkedSessionService.empresa.id
    }

    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'insert_ofx_rule', payload, this.httpOptions).toPromise().then(res => {
        if (res["severity"] === "success") this.showResponse('success', res["message"])
        else this.showResponse('error', res["message"])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar as operações vinculadas! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

}
