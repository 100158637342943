import { Component, Input } from '@angular/core'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { MessageService } from 'primeng/api'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-dash-main[dashId]',
  templateUrl: './dash-main.component.html',
  styleUrls: ['./dash-main.component.scss']
})
export class DashMainComponent {

  protected sessionSubscription: Subscription

  @Input() dashId: number

  listMonths: Array<string> = [ "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez" ]
  monthExtensive: Array<string> = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro" ]
  weekExtensive: Array<string> = [ "domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado" ]

  constructor(
    protected messageService: MessageService,
    protected dblinkedSessionService: DblinkedSessionService,
  ) { }

  protected showErrorResponse(message: string): void {
    this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
  }

  protected baseListEvolution(): Array<string> {
    let response: Array<string> = new Array()
    const finalMonth = this.dblinkedSessionService.periodo.month.value
    const year = this.dblinkedSessionService.periodo.year.value.toString().substring(2,4)

    this.listMonths.forEach((item, idx) => { if(idx <= finalMonth) response.push(`${item}-${year}`) })

    return response
  }

  protected baseListEvolutionOffset(offset: number): Array<string> {
    let response: Array<string> = new Array()
    const finalMonth = this.dblinkedSessionService.periodo.month.value + offset + 1
    const year = (this.dblinkedSessionService.periodo.year.value - 1)

    Array.from(Array(13)).forEach((_, idx) => { if(idx <= 12) response.push(`${this.listMonths[(idx+offset) % 12]}-${(idx+offset) >= 12 ? (year + 1).toString().substring(2,4) : year.toString().substring(2,4)}`) })

    return response
  }

  protected getDate(model: 'initial' | 'final', timeCourse: 'daily' | 'lastSevenDays' | 'lastFifteenDays' | 'lastThirtyDays' | 'nextSevenDays' | 'nextFifteenDays' | 'nextThirtyDays' | 'weekly' | 'month' | 'year' | 'lastYear' ) {
    const yearSelected = this.dblinkedSessionService.periodo.year.value
    const monthSelected = this.dblinkedSessionService.periodo.month.value

    let response: string, dateResponse: Date, month: string, day: string, sunday: Date, saturday: Date

    if(model === 'initial') {
      switch (timeCourse) {
        case 'weekly':
          dateResponse = new Date()
        break
        case 'lastSevenDays':
          dateResponse = new Date()
          dateResponse.setDate(dateResponse.getDate() - 6)
        break
        case 'lastFifteenDays':
          dateResponse = new Date()
          dateResponse.setDate(dateResponse.getDate() - 13)
        break
        case 'lastThirtyDays':
          dateResponse = new Date()
          dateResponse.setDate(dateResponse.getDate() - 30)
        break
        case 'month':
          dateResponse = new Date(yearSelected, monthSelected - 1, 1)
        break
        case 'year':
          dateResponse = new Date(yearSelected, 0, 1)
        break
        case 'lastYear':
          dateResponse = new Date(yearSelected-1, monthSelected-1, 1)
        break
        default:
          dateResponse = new Date()
        break
      }
    }

    if(model === 'final') {
      switch (timeCourse) {
        case 'daily':
        case 'weekly':
        case 'lastSevenDays':
        case 'lastFifteenDays':
        case 'lastThirtyDays':
          dateResponse = new Date()
        break
        case 'nextSevenDays':
          dateResponse = new Date()
          dateResponse.setDate(dateResponse.getDate() + 6)
        break
        case 'nextFifteenDays':
          dateResponse = new Date()
          dateResponse.setDate(dateResponse.getDate() + 13)
        break
        case 'nextThirtyDays':
          dateResponse = new Date()
          dateResponse.setDate(dateResponse.getDate() + 30)
        break
        default:
          dateResponse = new Date(yearSelected, monthSelected, 0)
        break
      }
    }

    month = ((dateResponse.getMonth()+1) < 10) ? `0${(dateResponse.getMonth()+1)}` : (dateResponse.getMonth()+1).toString()
    day = (dateResponse.getDate() < 10) ? `0${dateResponse.getDate()}` : dateResponse.getDate().toString()

    response = `${dateResponse.getFullYear()}-${month}-${day}`

    return response
  }

  public formatDateExtensive(): String {
    const dt = new Date()

    const month = this.monthExtensive[dt.getMonth()]
    const week = this.weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }
}
