import { animate, state, style, transition, trigger } from '@angular/animations'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Toolbar } from 'app/shared/model/toolbar.model'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpService } from 'app/shared/services/http.service'
import { SessionService } from 'app/shared/services/session.service'
import { SidebarService } from 'app/shared/services/sidebar.service'
import { Action } from 'app/shared/services/socket/protocol/Action'
import { Method } from 'app/shared/services/socket/protocol/Method'
import { Service } from 'app/shared/services/socket/protocol/Service'
import { PysocketService } from 'app/shared/services/socket/pysocket.service'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import deepCopy from 'app/util/processing/deepcopy'
import exportExcel from 'app/util/processing/exportExcel'
import { MessageService, SelectItem } from 'primeng/api'
import { Table } from 'primeng/table'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-central-aprovacao',
  templateUrl: './central-aprovacao.component.html',
  styleUrls: ['./central-aprovacao.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class CentralAprovacaoComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription
  private messagesSubscription: Subscription

  currentState = 'initial'
  exportFileTab: string
  selectedDate: string

  tab: number = 0
  totalValue: number = 0

  showConcluded: boolean = false
  showPending: boolean = false
  showRefused: boolean = false
  cardState: boolean = true
  tableLoading: boolean = true
  showFilterModal: boolean = false
  hasTimeCourse: boolean = false
  showReq: boolean = false
  showApr: boolean = false
  isManager: boolean = false

  tableData: Array<any>  = new Array()
  tableDataApproved: Array<any> = new Array()
  tableDataPending: Array<any> = new Array()
  tableDataRefused: Array<any> = new Array()
  cloneTableDataPending: Array<any> = new Array()
  cloneTableDataRefused: Array<any> = new Array()
  cloneTableDataApproved: Array<any> = new Array()
  tableDataLoading: Array<any> = new Array()
  rowSelected: Array<any> = new Array()
  clearArr: Array<any> = new Array()
  newRows: Array<any> = new Array()
  filterSelected: Array<any> = new Array()
  requestTypeSelected: Array<number> = new Array()
  prioritySelected: Array<number> = new Array()
  listApprovalStatus: Array<string> = new Array()
  exportOrderList: Array<string> = new Array()

  priorities: SelectItem[] = []
  requestTypes: SelectItem[] = []
  prioritiesTable: SelectItem[] = []
  typesTable: SelectItem[] = []
  destinysTable: SelectItem[] = []
  objectsTable: SelectItem[] = []
  applicantsTable: SelectItem[] = []
  responsiblesTable: SelectItem[] = []
  currentApprovalsTable: SelectItem[] = []
  coinsTable: SelectItem[] = []

  dataFilterTable: any
  dataDeliveryFilterTable: any
  codeFilterTable: any
  titleFilterTable: any
  prioritiesFilterTable: any
  typesFilterTable: any
  destinysFilterTable: any
  objectsFilterTable: any
  applicantsFilterTable: any
  responsiblesFilterTable: any
  currentApprovalsFilterTable: any
  coinsFilterTable: any
  valueFilterTable: any

  dateIni: Date = new Date()
  dateFin: Date = new Date()

  @ViewChild('dt') tableApprovals: Table

  toolbarMainIni = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar excel', visible: true, disabled: false, color: 'default', onClick: () => {} },
    { icon: 'upload_file', text: 'Atividades', tooltip: 'Exportar atividades', visible: true, disabled: false, color: 'default', onClick: () => this.exportActivities() },
    { icon: 'refresh', text: 'Atualizar', tooltip: 'Atualizar dados', visible: true, disabled: false, color: 'default', onClick: () => this.refreshTable() },
    { icon: 'filter_alt', text: 'Filtrar', tooltip: 'Filtrar', visible: true, disabled: false, color: 'default', onClick: () => this.openFilterModal() },
    { icon: 'view_sidebar', text: 'Sidebar', tooltip: 'Sidebar', visible: false, disabled: false, color: 'default', onClick: () => this.changeSidebar() },
    { icon: 'account_tree', text: 'Etapa', tooltip: 'Alterar etapa', visible: false, disabled: false, color: 'default', dropdown: true},
  ]

  public toolbarMain = new Toolbar()

  constructor(
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private configService: ConfigService,
    private sidebarService: SidebarService,
    private sessionService: SessionService,
    private messageService: MessageService,
    private socketService: PysocketService,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.tableDataLoading = [{}, {}, {}]

    this.sidebarService.clearArr.subscribe(item => {
      this.rowSelected = item
      this.changeToolbar()
    })
    this.sidebarService.clearArr.subscribe(item => this.clearArr = item)

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,() => {
      if(this.dblinkedSessionService.hasEmpresaSelected){
        this.initialize()
      }
    })

    this.messagesSubscription = this.socketService.socketData.subscribe((response) => {
      if(response.service === Service.APPROVAL_CENTER) if(response.action === Action.APPROVE) if(response.method === Method.GET) this.onApprovements(response)
    })
  }

  ngOnDestroy(): void {
    this.toolbarService.hidden = false
    this.sessionSubscription.remove(this.sessionSubscription)
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  async initialize(): Promise<void> {
    this.hasTimeCourse = false

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
      this.listApprovalStatus = ['APROVADO', 'PEDIDO CRIADO', 'TITULO CRIADO', 'BAIXADO', 'FINANCEIRO APROVADO', 'PEDIDO APROVADO', 'RMS APROVADO']
      this.exportOrderList = ['dt', 'codigo', 'titulo', 'tipo', 'destinoRms', 'objeto', 'prioridade', 'requisitante', 'labelAprovador', 'currentAprov', 'codigoMoeda', 'valor']
      this.hasTimeCourse = true

      this.dateIni = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dateFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)

      this.selectedDate = "deliveryDay"

      await this.getPersonalizedStatus()
      await this.getOptions()
      await this.getInformations()
      this.getDefaultDateFilter()
      this.changeScreen(0)
      this.getPriority()
      this.getTypeRequest()
      this.getPhases()
      this.getManager()
    }
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected
  }

  get loading(): boolean {
    return this.httpService.loading
  }

  get rowsPerPage(): Array<any> {
    return this.configService.applicationConfig.rowsPerPage
  }

  get rows(): number {
    return this.configService.applicationConfig.rows
  }

  get pageLinks(): number {
    return this.configService.applicationConfig.pageLinks
  }

  async getInformations() {
    this.tableLoading = true
    this.tableData = new Array()

    const payload = {
      dtIni: this.dateIni.getTime(),
      dtFin: this.dateFin.getTime(),
      listTipoId: this.requestTypeSelected,
      listPrioridadeId: this.prioritySelected,
      typeDate: this.selectedDate
    }

    try {
      await this.httpService.post('/custom/workflow/card-aprovacao/' + this.dblinkedSessionService.empresa.id + '/' + this.sessionService.loggedUser.id, payload).toPromise().then(res => {
        this.tableDataApproved = new Array()
        this.tableDataPending = new Array()
        this.tableDataRefused = new Array()

        if(!res.error) res.forEach(item => {
          item.labelAprovador = this.setApproverLabel(item)
          item.dt = this.convertTimestamp(item.data)
          item.codigoMoeda = item.moeda ? item.moeda.codigo : 'BRL'
          item.dataCriacaoLabel = item.dataCriacao ? this.convertTimestamp(item.dataCriacao) : ''
          item.dataEntregaLabel = item.dataEntrega ? this.convertTimestamp(item.dataEntrega) : ''

          if(item.status === 'PENDENTE' || item.status === 'AGUARDANDO AUTORIZAÇÃO') this.tableDataPending.push(item)
          if(this.listApprovalStatus.includes(item.status)) this.tableDataApproved.push(item)
          if(item.status === 'RECUSADO') this.tableDataRefused.push(item)
        })
        else this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: res.message })
      })
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar as aprovações! Contate o suporte' })
    }

    this.tableLoading = false

    this.cloneTableDataPending = this.tableDataPending
    this.cloneTableDataRefused = this.tableDataRefused
    this.cloneTableDataApproved = this.tableDataApproved
  }

  async getPriority(): Promise<void> {
    try {
      await  this.httpService.get('/prioridaderequisicao?$select=id,nome,indice&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=indice').toPromise().then(res => {
        this.priorities = new Array()
          res.value.forEach(item => {
            this.priorities.push({ label: (item.indice ? item.indice + " - " : '') + item.nome, value: item.id })
          })
      })

    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar as prioridades! Contate o suporte' })
    }
  }

  async getTypeRequest() {
    try {
      await this.httpService.get('/tipo?$select=id,nome,tipo_rms_pedido/id&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=id').toPromise().then(res => {
          this.requestTypes = new Array()
          res.value.forEach(item => {
            this.requestTypes.push({ label: item.nome, value: item.id })
          })
      })

    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar os tipos de requisições! Contate o suporte' })
    }
  }

  async getOptions(): Promise<void> {
    try {
      await this.httpService.get('custom/workflow/central-show-options/'  + this.dblinkedSessionService.empresa.id + '/' + this.sessionService.loggedUser.id).toPromise().then(res => {
        this.showReq = res.showRms
        this.showApr = res.showAprov
      })

    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar as opções de filtragem! Contate o suporte' })
    }

    this.onFilter()
  }

  async saveOptions(): Promise<void> {

    const payload = {
      showRms: this.showReq,
      showAprov: this.showApr
    }

   try {
      await this.httpService.post('custom/workflow/central-show-options/'  + this.dblinkedSessionService.empresa.id + '/' + this.sessionService.loggedUser.id, payload).toPromise()

    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao salvar as opções de filtragem! Contate o suporte' })
    }
  }

   async getManager(): Promise<void> {
    this.isManager = false

    try {
      await this.httpService.get(`/gestores?$select=user/id&$filter=empresa/id eq ${this.dblinkedSessionService.empresa.id}`).toPromise().then(res => {
        res.value.forEach(item => {
          if (item.user.id === this.sessionService.loggedUser.id) this.isManager = true
        })
      })
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar seu cargo! Contate o suporte' })
    }
  }

  async getPhases(): Promise<void> {
    try {
      await this.httpService.get('/custom/workflow/get-aprovacao', this.dblinkedSessionService.empresa.id).toPromise().then(res => {
        let aux: Array<any> = new Array()
        res.forEach(item => {
          aux.push({ label: item.descricao, callback: () => this.updatePhases(item), visible: true })
        })

        this.toolbarMain.setOptions('btn6', aux)
      })
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregas as etapas! Contate o suporte' })
    }
  }

  async updatePhases(data: any): Promise<void> {
    try {
      let aux: Array<any> = new Array()
      this.rowSelected.forEach(item => {
        aux.push(item.cardAprovacaoId)
      })

      if(confirm('Todas as aprovações selecionadas serão alteradas para a etapa: ' + data.descricao)) {
        const payload = {
          listCardId: aux,
          newPhase: data.id,
          userId: this.sessionService.loggedUser.id,
          empresaId: this.dblinkedSessionService.empresa.id
        }

        await this.httpService.post('/custom/workflow/forcar-etapa-aprovacao', payload).toPromise().then(res => {
          this.messageService.add({
            severity: res.error ? 'error' : 'success',
            key: 'messageLinnks',
            summary: res.error ? 'Erro: ' : 'Sucesso: ',
            detail: res.message
          })

          if (!res.error) {
            let aux = []

            res.listStatusCard.forEach(item => {
              aux.push(item.todoCardAprovacaoId)
              const payload = {
                service: Service.APPROVAL_CENTER,
                action: Action.APPROVE,
                method: Method.PUT,
                content: {
                  message: 'redefinida',
                  status: item.newStatus,
                  stage: item.newEtapa,
                  userId: this.sessionService.loggedUser.id,
                  cardId: item.todoCardAprovacaoId,
                  userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome
                }
              }

              this.socketService.send(payload)
            })

            const payload = {
              service: Service.APPROVAL_CENTER,
              action: Action.APPROVE,
              method: Method.GET,
              content: {
                listCardId: aux,
              }
            }

            this.socketService.send(payload)
            this.socketService.sendNotification(res.listNotificacaoId)
          }
        })
      }

    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao alterar as etapas das aprovações! Contate o suporte' })
    }
  }

  async getPersonalizedStatus(): Promise<void> {
    try {
      await this.httpService.get('/statusaprovacao?$select=descricao&$filter=statusPedido eq true and empresa/id eq '+ this.dblinkedSessionService.empresa.id).toPromise().then(res => {
        res.value.forEach(line => this.listApprovalStatus.push(line.descricao))
      })

    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar os status do pedido! Contate o suporte' })
    }
  }

  async onApprovements(data: any): Promise<void> {
    await this.getRows(data.content.listCardId)

    if(this.newRows.length > 0) {
      this.newRows.forEach(item => {
        if(item.status === 'PENDENTE') {
          let arr: Array<any> = new Array()

          arr = this.tableDataPending.find(line => line.cardAprovacaoId === item.cardAprovacaoId)
          this.tableDataRefused = this.tableDataRefused.filter(line => line.cardAprovacaoId !== item.cardAprovacaoId)

          if(arr !== undefined) {
            this.tableDataPending.forEach(elem => {
              if(item.cardAprovacaoId === elem.cardAprovacaoId) {
                elem.currentAprov = item.currentAprov
                elem.aprovadores = item.aprovadores
                elem.labelAprovador = this.setApproverLabel(elem)
              }
            })
          } else {
            item.labelAprovador = this.setApproverLabel(item)
            this.tableDataPending.unshift(item)
          }
        } else {
          data.content.listCardId.forEach(elem => {
            this.tableDataPending = this.tableDataPending.filter(line => line.cardAprovacaoId !== elem)
          })

          item.labelAprovador = 'Sem aprovador'

          if(this.listApprovalStatus.includes(item.status)) this.tableDataApproved.unshift(item)
          if(item.status === 'RECUSADO') this.tableDataRefused.unshift(item)

          this.clearArr = []
        }
      })

    } else {
      data.content.listCardId.forEach(item => {
        this.tableDataPending = this.tableDataPending.filter(line => line.id !== item)
      })
    }

    this.cloneTableDataPending = this.tableDataPending
    this.cloneTableDataRefused = this.tableDataRefused
    this.cloneTableDataApproved = this.tableDataApproved

    this.onFilter()
  }

  async getDefaultDateFilter(): Promise<void> {
    try {
      await this.httpService.get(`/custom/rms/recuperar-data-padrao-rms(${this.dblinkedSessionService.empresa.id})`).toPromise().then((res: any) => {
        if(!res.error) this.selectedDate = res.data == 0 ? 'deliveryDay' : 'creationDay'
      })
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar o filtro de data padrão! Contate o suporte' })
    }
  }

  async getRows(data: any): Promise<void> {
    const payload = {
      dtIni: this.dateIni.getTime(),
      dtFin: this.dateFin.getTime(),
      listTipoId: this.requestTypeSelected,
      listPrioridadeId: this.prioritySelected,
      listCardId: data
    }

    try {
      await this.httpService.post('/custom/workflow/card-aprovacao/' + this.dblinkedSessionService.empresa.id + '/' + this.sessionService.loggedUser.id, payload).toPromise().then(res => {
        this.newRows = res
      })
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar as aprovações! Contate o suporte' })
    }
  }

  async downloadPdf(data: number): Promise<void> {
    try {
      this.httpService.get('/custom/rms/impressao-rms(' + data + ',' + false + ')').toPromise().then(res => {
        if(!res.error) {
          window.open(this.httpService.url('/custom/file/' + res.file + '/print_rms'), '_blank')
        } else {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res.message })
        }
      })
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao gerar o PDF! Contate o suporte' })
    }
  }

  onFilter(): void {
    this.totalValue = 0

    this.filterSelected = new Array()

    if(this.showReq) this.filterSelected.push(1)
    if(this.showApr) this.filterSelected.push(2)

    if(this.filterSelected.length > 0) {
      this.tableDataPending = []
      this.tableDataApproved = []
      this.tableDataRefused = []

      this.filterSelected.forEach(item => {
        if(item === 1) {
          let auxPending = []
          let auxApproved = []
          let auxRefused = []

          auxPending = this.cloneTableDataPending.filter(line => line.requisitanteId === this.sessionService.loggedUser.id)
          auxPending.forEach(item => {
            let arr: Array<any> = new Array()
            arr = this.tableDataPending.find(line => line.id === item.id)
            if(arr === undefined) this.tableDataPending.push(item)
          })

          auxApproved = this.cloneTableDataApproved.filter(line => line.requisitanteId === this.sessionService.loggedUser.id)
          auxApproved.forEach(item => {
            let arr: Array<any> = new Array()
            arr = this.tableDataApproved.find(line => line.id === item.id)
            if(arr === undefined) this.tableDataApproved.push(item)
          })

          auxRefused = this.cloneTableDataRefused.filter(line => line.requisitanteId === this.sessionService.loggedUser.id)
          auxRefused.forEach(item => {
            let arr: Array<any> = new Array()
            arr = this.tableDataRefused.find(line => line.id === item.id)
            if(arr === undefined) this.tableDataRefused.push(item)
          })
        }
        if(item === 2) {
          let auxPending = []
          let auxApproved = []
          let auxRefused = []

          this.cloneTableDataPending.forEach(item => {
            item.aprovadores.forEach(elem => {
              if(elem.authUserId === this.sessionService.loggedUser.id) {
                auxPending.push(item)
              }
             })
          })
          auxPending.forEach(item => {
            let arr: Array<any> = new Array()
            arr = this.tableDataPending.find(line => line.id === item.id)
            if(arr === undefined) this.tableDataPending.push(item)
          })

          this.cloneTableDataApproved.forEach(item => {
            item.aprovadores.forEach(elem => {
              if(elem.authUserId === this.sessionService.loggedUser.id) {
                auxApproved.push(item)
              }
             })
          })
          auxApproved.forEach(item => {
            let arr: Array<any> = new Array()
            arr = this.tableDataApproved.find(line => line.id === item.id)
            if(arr === undefined) this.tableDataApproved.push(item)
          })

          this.cloneTableDataRefused.forEach(item => {
            item.aprovadores.forEach(elem => {
              if(elem.authUserId === this.sessionService.loggedUser.id) {
                auxRefused.push(item)
              }
             })
          })
          auxRefused.forEach(item => {
            let arr: Array<any> = new Array()
            arr = this.tableDataRefused.find(line => line.id === item.id)
            if(arr === undefined) this.tableDataRefused.push(item)
          })
        }
      })
    } else {
      this.tableDataPending = this.cloneTableDataPending
      this.tableDataApproved = this.cloneTableDataApproved
      this.tableDataRefused = this.cloneTableDataRefused
    }

    switch (this.tab) {
      case 0:
        this.tableData = this.tableDataPending
        this.exportFileTab = '(Em andamento)'
      break
      case 1:
        this.tableData = this.tableDataApproved
        this.exportFileTab = '(Finalizados)'
      break
      case 2:
        this.tableData = this.tableDataRefused
        this.exportFileTab = '(Recusados)'
      break
    }

    this.resetFiltersTable()

    this.toolbarMain.setClick('btn1', () => exportExcel(this.tableData, `central-de-aprovação ${this.exportFileTab}`, this.exportOrderList))

    this.tableData.forEach(item => {
      this.totalValue += item.valor
      this.setTableFilters(item)
    })
  }

  async refreshTable(): Promise<void> {
    this.tableLoading = true
    this.tableData = new Array()
    this.totalValue = 0

    await this.getOptions()
    await this.getInformations()
    this.changeScreen(this.tab)
  }

  onSelectAll(event: any): void {
    this.rowSelected = []
    if(event.checked) this.rowSelected = deepCopy(this.clearArr)

    this.changeToolbar()
    if(this.rowSelected.length === 0) {
      this.sidebarService.open(false)
      this.sidebarService.clearRow()
    }
  }

  onRowSelect(event: any): void {
    this.rowSelected.push(event.data)
    this.changeToolbar()
  }

  onRowUnselect(event: any): void {
    this.rowSelected = this.rowSelected.filter(line => line.id !== event.data.id)
    this.changeToolbar()
    if(this.rowSelected.length === 0) {
      this.sidebarService.open(false)
      this.sidebarService.clearRow()
    }
  }

  changeToolbar() {
    this.toolbarMain.setVisible('btn5', this.rowSelected.length > 0)
    this.toolbarMain.setVisible('btn6', this.isManager && this.rowSelected.length > 0)
  }

  changeSidebar() {
    let aux = []
    this.rowSelected.forEach(item => {
      aux.push(item.cardAprovacaoId)
    })

    this.sidebarService.rowSelected(aux)
    this.sidebarService.open(true)
    this.sidebarService.selectTab(0)
  }

  rowSidebar(data: any) {
    this.rowSelected = []
    this.clearArr = []
    this.rowSelected.push(data)
    this.clearArr.push(data)

    this.changeSidebar()
  }

  listApprovers(data: any): String {
    let i: number = 0
    let res = 'Aprovadores: '

    if(data.length === 0) res = 'Sem aprovador'
    else if(data.length === 1) res = data[0].fullName
    else data.forEach(item => {
      i++
      res += item.fullName

      if(i !== data.length) res += ', '
    })

    return res
  }

  setApproverLabel(arr): string {
    let res: string = 'Sem aprovador'

    if(arr.aprovadores && arr.aprovadores.length > 0) {
      arr.aprovadores = arr.aprovadores.filter(line => line.substituto === false)
      res = (arr.aprovadores.length === 1) ? arr.aprovadores[0].fullName : 'Vários'
    }

    return res
  }

  changeScreen(type: number): void {
    this.tableData = new Array()

    switch(type) {
      case 0:
        this.showPending = true
        this.showConcluded = false
        this.showRefused = false
        this.tab = 0
      break
      case 1:
        this.showPending = false
        this.showConcluded = true
        this.showRefused = false
        this.tab = 1
      break
      case 2:
        this.showPending = false
        this.showConcluded = false
        this.showRefused = true
        this.tab = 2
      break
    }

    this.rowSelected = []
    this.clearArr = []
    this.sidebarService.open(false)
    this.sidebarService.clearRow()

    this.onFilter()
  }

  formatDateExtensive(): String {
    const dt = new Date()
    const monthExtensive = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const weekExtensive = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]

    const month = monthExtensive[dt.getMonth()]
    const week = weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }

  convertTimestamp(timestamp): string {
    let fullDate: string = ''

    if(timestamp){
      const date = new Date(timestamp)
      date.setHours(date.getHours()+3)
      const day = date.getDate() > 9 ? date.getDate() : '0'+date.getDate()
      const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : '0'+(date.getMonth()+1)

      fullDate = day + '/' + month + '/' + date.getFullYear()
    }

    return fullDate
  }

  openFilterModal(): void {
    this.showFilterModal = true
  }

  filterTable(): void {
    this.refreshTable()
    this.showFilterModal=false
    this.hasTimeCourse = true
  }

  setTableFilters(item: Object): void {
    if(item['prioridade']) this.prioritiesTable.push({ label: item['prioridade'].toUpperCase(), value: item['prioridade'] })
    if(item['tipo']) this.typesTable.push({ label: item['tipo'].toUpperCase(), value: item['tipo'] })
    if(item['destinoRms']) this.destinysTable.push({ label: item['destinoRms'].toUpperCase(), value: item['destinoRms'] })
    if(item['objeto']) this.objectsTable.push({ label: item['objeto'].toUpperCase(), value: item['objeto'] })
    if(item['requisitante']) this.applicantsTable.push({ label: item['requisitante'].toUpperCase(), value: item['requisitante'] })
    if(item['labelAprovador']) this.responsiblesTable.push({ label: item['labelAprovador'].toUpperCase(), value: item['labelAprovador'] })
    if(item['currentAprov']) this.currentApprovalsTable.push({ label: item['currentAprov'].toUpperCase(), value: item['currentAprov'] })
    if(item['moeda'].codigo) this.coinsTable.push({ label: item['moeda'].codigo.toUpperCase(), value: item['moeda'].codigo })

    let callBack = (a,b) => { return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0) }

    this.prioritiesTable = this.setUniqueArr(this.prioritiesTable, 'value').sort(callBack)
    this.typesTable = this.setUniqueArr(this.typesTable, 'value').sort(callBack)
    this.destinysTable = this.setUniqueArr(this.destinysTable, 'value').sort(callBack)
    this.objectsTable = this.setUniqueArr(this.objectsTable, 'value').sort(callBack)
    this.applicantsTable = this.setUniqueArr(this.applicantsTable, 'value').sort(callBack)
    this.responsiblesTable = this.setUniqueArr(this.responsiblesTable, 'value').sort(callBack)
    this.currentApprovalsTable = this.setUniqueArr(this.currentApprovalsTable, 'value').sort(callBack)
    this.coinsTable = this.setUniqueArr(this.coinsTable, 'value').sort(callBack)
  }

  resetFiltersTable(): void {
    this.prioritiesTable = new Array()
    this.typesTable = new Array()
    this.destinysTable = new Array()
    this.objectsTable = new Array()
    this.applicantsTable = new Array()
    this.responsiblesTable = new Array()
    this.currentApprovalsTable = new Array()
    this.coinsTable = new Array()

    this.tableApprovals.filters = {}
    this.tableApprovals.filteredValue = null
    this.tableApprovals.sortField = null
    this.tableApprovals.sortOrder = 1

    this.dataFilterTable = null
    this.dataDeliveryFilterTable = null
    this.codeFilterTable = null
    this.titleFilterTable = null
    this.prioritiesFilterTable = null
    this.typesFilterTable = null
    this.destinysFilterTable = null
    this.objectsFilterTable = null
    this.applicantsFilterTable = null
    this.responsiblesFilterTable = null
    this.currentApprovalsFilterTable = null
    this.coinsFilterTable = null
    this.valueFilterTable = null
  }

  setUniqueArr(arr: Array<any>, parameter: string): Array<any> {
    const objectMap = arr.reduce((map, object) => {
      map.set(object[parameter], object)
      return map
    }, new Map())

    return Array.from(objectMap, ([_, value]) => value)
  }

  public async exportActivities() {
    this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aguarde', detail: 'Processando solicitação! Em breve seu arquivo será disponibilizado.' })

    const allTables: Array<any> = [...this.cloneTableDataPending,...this.cloneTableDataApproved, ...this.cloneTableDataRefused]

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi
      }),
      responseType: 'blob' as 'json'
    }

    const payload = {
      companyId: this.dblinkedSessionService.empresa.id,
      userId: this.sessionService.loggedUser.id,
      todo_cards_id: allTables.map((approval: any) => { return approval.id })
    }

    try {
      this.httpClient.post(this.configService.defaultUrlApiUpload + '/workflow/exportar-atividades-aprovacao', payload, httpOptions).toPromise().then((res: any) => {
          let link = document.createElement('a')
          let url = window.URL.createObjectURL(res)
          link.href = url
          link.download = "relatorio-central-aprovacao-atividades.xlsx"
          link.click()
          window.URL.revokeObjectURL(url)
      }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao recuperar seu relatório! Contate o suporte' }))
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    }
  }

}
