import { Rateio } from "./rateio.model";
import { CadEcd } from "app/contabil/model/cad-ecd.model";
import { Nfe } from "app/financeiro/model/nfe.model";
import { EmpresaAlmoxarifado } from "app/cadastro/model/empresa-almoxarifado.model";
import { Participante } from "app/cadastro/model/participante.model";
import { NfeItem } from "app/financeiro/model/nfe-item.model";
import { MovInterna } from "./mov-interna-model";
import { Item } from "app/cadastro/model/item.model";
import { ParticipanteTipo } from "app/cadastro/model/participante-tipo.model";
import { TipoEntradaSaida } from "app/util/model/tipo-entrada-saida.model";
import { UnidadeMedida } from "app/util/model/unidade-medida.model";

export class MovInternaItem {
  id: number;
  item: Item;
  itemId: number;
  movInterna: MovInterna;
  empresaAlmoxarifadoOrig: EmpresaAlmoxarifado;
  empresaAlmoxarifadoOrigId: number;
  empresaAlmoxarifadoDest: EmpresaAlmoxarifado;
  empresaAlmoxarifadoDestId: number;
  qtde: number;
  valor: number;
  participanteOrig: Participante;
  participanteOrigId: number;
  participanteDest: Participante;
  participanteDestId: number;
  nfeItem: NfeItem;
  nfeItemId: number;
  participanteOrigTipo: ParticipanteTipo;
  participanteDestTipo: ParticipanteTipo;
  tipoEntradaSaida: TipoEntradaSaida;
  tipoEntradaSaidaId: number;
  unidadeMedida  : UnidadeMedida;
  unidadeMedidaId: number;

  constructor(movInternaItem?: MovInternaItem | number) {
    if (movInternaItem !== undefined && movInternaItem !== null) {
      if (typeof movInternaItem === 'number') {
        this.id = movInternaItem;
      } else {
        this.id = movInternaItem.id;
        this.item = movInternaItem.item;
        this.itemId = movInternaItem.itemId;
        this.movInterna = movInternaItem.movInterna;
        this.empresaAlmoxarifadoOrig = movInternaItem.empresaAlmoxarifadoOrig;
        this.empresaAlmoxarifadoOrigId = movInternaItem.empresaAlmoxarifadoOrigId;
        this.empresaAlmoxarifadoDest = movInternaItem.empresaAlmoxarifadoDest;
        this.empresaAlmoxarifadoDestId = movInternaItem.empresaAlmoxarifadoDestId;
        this.qtde = movInternaItem.qtde;
        this.valor = movInternaItem.valor;
        this.participanteOrig = movInternaItem.participanteOrig;
        this.participanteOrigId = movInternaItem.participanteOrigId;
        this.participanteDest = movInternaItem.participanteDest;
        this.participanteDestId = movInternaItem.participanteDestId;
        this.nfeItem = movInternaItem.nfeItem;
        this.nfeItemId = movInternaItem.nfeItemId;
        this.participanteOrigTipo = movInternaItem.participanteOrigTipo;
        this.participanteDestTipo = movInternaItem.participanteDestTipo;
        this.tipoEntradaSaida = movInternaItem.tipoEntradaSaida;
        this.tipoEntradaSaidaId = movInternaItem.tipoEntradaSaidaId;
        this.unidadeMedida = movInternaItem.unidadeMedida;
        this.unidadeMedidaId = movInternaItem.unidadeMedidaId;
      }
    }
  }
}
