import { AuthFeature } from './auth-feature.model';

export class AuthFeatureUrl {
  id: number;
  path: string;
  allowGet: boolean;
  allowPost: boolean;
  allowPut: boolean;
  allowDelete: boolean;
  authFeature: AuthFeature = new AuthFeature();
  active = true;

  constructor(authFeatureUrl?: AuthFeatureUrl | number) {
    if (authFeatureUrl !== undefined && authFeatureUrl !== null) {
      if (typeof authFeatureUrl === 'number') {
        this.id = authFeatureUrl;
      } else {
        this.id = authFeatureUrl.id;
        this.path = authFeatureUrl.path;
        this.allowGet = authFeatureUrl.allowGet;
        this.allowPost = authFeatureUrl.allowPost;
        this.allowPut = authFeatureUrl.allowPut;
        this.allowDelete = authFeatureUrl.allowDelete;
        this.authFeature = authFeatureUrl.authFeature || new AuthFeature();
        this.active = authFeatureUrl.active;
      }
    }
  }
}
