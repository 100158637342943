export class ImpostoRegraFiscal {
  id: number;
  nome: string;
  descricao: string;
  params: string;

  constructor(impostoRegraFiscal?: ImpostoRegraFiscal | number) {
    if (impostoRegraFiscal !== undefined && impostoRegraFiscal !== null) {
      if (typeof impostoRegraFiscal === 'number') {
        this.id = impostoRegraFiscal;
      } else {
        this.id = impostoRegraFiscal.id;
        this.nome = impostoRegraFiscal.nome;
        this.descricao = impostoRegraFiscal.descricao;
        this.params = impostoRegraFiscal.params;
      }
    }
  }
}
