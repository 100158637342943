import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TipoConta } from '../../util/model/tipo-conta.model';

@Component({
  selector: 'app-empresa-conta',
  templateUrl: './empresa-conta.component.html',
  styleUrls: ['./empresa-conta.component.scss'],
  providers: [CrudService]
})

export class EmpresaContaComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public empresaConta: EmpresaConta = new EmpresaConta();

  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeViewSubscription: Subscription;
  private beforeEditSubscription: Subscription;

  entityFilter: string = null;

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  ngOnInit() {


    this.beforeViewSubscription = this.crudService.beforeAdd.subscribe((empresaConta: EmpresaConta) => {

     const parentFormControlInstituicao = this.controls.find(control => control.key === 'instituicaoFinanceira');
      parentFormControlInstituicao.setHidden(true);

      const parentFormControlAgencia = this.controls.find(control => control.key === 'agencia');
      parentFormControlAgencia.setHidden(true);

      const parentFormControlDvAgencia = this.controls.find(control => control.key === 'dvAgencia');
      parentFormControlDvAgencia.setHidden(true);

      const parentFormControlConta = this.controls.find(control => control.key === 'conta');
      parentFormControlConta.setHidden(true);

      const parentFormControlDvConta = this.controls.find(control => control.key === 'dvConta');
      parentFormControlDvConta.setHidden(true);

    });

    this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((empresaConta: EmpresaConta) => {
      empresaConta.empresa = new Empresa(this.dblinkedSessionService.empresa.id);
    });

    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
      () => { this.empresaChanged(); });

      this.beforeEditSubscription = this.crudService.beforeEdit.subscribe((empresaConta: EmpresaConta) => this.beforeEdit(empresaConta));

  }

  private beforeEdit(empresaConta: EmpresaConta) {

    empresaConta.empresa = new Empresa(this.dblinkedSessionService.empresa.id);

    if (empresaConta.tipoConta) {

      if (empresaConta.tipoConta.id === 6 || empresaConta.tipoConta.id === 7
        || empresaConta.tipoConta.id === 8 || empresaConta.tipoConta.id === 9 || empresaConta.tipoConta.id === 10) {

        empresaConta.tipoConta = new TipoConta(empresaConta.tipoConta.id);

        const parentFormControlTipoConta = this.controls.find(control => control.key === 'tipoConta');
        parentFormControlTipoConta.setHidden(true);
        parentFormControlTipoConta.setRequired(false);

        const parentFormControlInstituicao = this.controls.find(control => control.key === 'instituicaoFinanceira');
        parentFormControlInstituicao.setHidden(true);
        parentFormControlInstituicao.setRequired(false);

        const parentFormControlAgencia = this.controls.find(control => control.key === 'agencia');
        parentFormControlAgencia.setHidden(true);
        parentFormControlAgencia.setRequired(false);

        const parentFormControlDvAgencia = this.controls.find(control => control.key === 'dvAgencia');
        parentFormControlDvAgencia.setHidden(true);
        parentFormControlDvAgencia.setRequired(false);

        const parentFormControlConta = this.controls.find(control => control.key === 'conta');
        parentFormControlConta.setHidden(true);
        parentFormControlConta.setRequired(false);

        const parentFormControlDvConta = this.controls.find(control => control.key === 'dvConta');
        parentFormControlDvConta.setHidden(true);
        parentFormControlDvConta.setRequired(false);

      } else {

        const parentFormControlInstituicao = this.controls.find(control => control.key === 'instituicaoFinanceira');
        parentFormControlInstituicao.setHidden(false);
        parentFormControlInstituicao.setRequired();

        const parentFormControlAgencia = this.controls.find(control => control.key === 'agencia');
        parentFormControlAgencia.setHidden(false);
        parentFormControlAgencia.setRequired();

        const parentFormControlDvAgencia = this.controls.find(control => control.key === 'dvAgencia');
        parentFormControlDvAgencia.setHidden(false);

        const parentFormControlConta = this.controls.find(control => control.key === 'conta');
        parentFormControlConta.setHidden(false);
        parentFormControlConta.setRequired();

        const parentFormControlDvConta = this.controls.find(control => control.key === 'dvConta');
        parentFormControlDvConta.setHidden(false);
        parentFormControlDvConta.setRequired();

      }
    }
}

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
    this.sessionService.destroySubscribe(this.beforeViewSubscription);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.entityFilter = 'empresa/id eq ' + this.dblinkedSessionService.empresa.id;

      this.columns = [
        new DataTableColumn('Nome', 'nome'),
        new DataTableColumn('Tipo da Conta', 'tipoConta')
          .setTypeSelect()
          .setOptions({ url: '/tipoconta', value: 'id', label: ['descricao'] }),
        new DataTableColumn('Instituição Financeira', 'instituicaoFinanceira')
          .setTypeSelect()
          .setOptions({ url: '/instituicaofinanceira', value: 'id', label: ['descricao'] }),
        new DataTableColumn('Agência', 'agencia'),
        new DataTableColumn('Dv Agência', 'dvAgencia'),
        new DataTableColumn('Conta', 'conta'),
        new DataTableColumn('Dv Conta', 'dvConta'),
        new DataTableColumn('Saldo Inicial', 'saldoInicial').setTypeMoney(),
        new DataTableColumn('Saldo Atual', 'saldoAtual').setTypeMoney()
      ];

      this.controls = [
        new DataControl('Nome', 'nome')
          .setRequired()
          .setMinLength(3)
          .setMaxLength(255),
        new DataControl('Tipo da Conta', 'tipoConta')
          .setRequired()
          .setTypeSelect()
          .setOptions({ url: '/tipoconta', value: 'id', label: ['descricao'] })
          .setOnChange((dataControl: DataControl, tipoConta: any) => {

          if (tipoConta) {

            if (tipoConta.id === undefined || tipoConta.id === 6 || tipoConta.id === 7 || tipoConta.id === 8
              || tipoConta.id === 9 || tipoConta.id === 10) {

              const parentFormControlInstituicao = this.controls.find(control => control.key === 'instituicaoFinanceira');
              parentFormControlInstituicao.setHidden(true);
              parentFormControlInstituicao.setRequired(false);

              const parentFormControlAgencia = this.controls.find(control => control.key === 'agencia');
              parentFormControlAgencia.setHidden(true);
              parentFormControlAgencia.setRequired(false);

              const parentFormControlDvAgencia = this.controls.find(control => control.key === 'dvAgencia');
              parentFormControlDvAgencia.setHidden(true);
              parentFormControlAgencia.setRequired(false);

              const parentFormControlConta = this.controls.find(control => control.key === 'conta');
              parentFormControlConta.setHidden(true);
              parentFormControlConta.setRequired(false);

              const parentFormControlDvConta = this.controls.find(control => control.key === 'dvConta');
              parentFormControlDvConta.setHidden(true);
              parentFormControlDvConta.setRequired(false);

            } else {

              const parentFormControlInstituicao = this.controls.find(control => control.key === 'instituicaoFinanceira');
              parentFormControlInstituicao.setHidden(false);
              parentFormControlInstituicao.setRequired();

              const parentFormControlAgencia = this.controls.find(control => control.key === 'agencia');
              parentFormControlAgencia.setHidden(false);
              parentFormControlAgencia.setRequired();

              const parentFormControlDvAgencia = this.controls.find(control => control.key === 'dvAgencia');
              parentFormControlDvAgencia.setHidden(false);

              const parentFormControlConta = this.controls.find(control => control.key === 'conta');
              parentFormControlConta.setHidden(false);
              parentFormControlConta.setRequired();

              const parentFormControlDvConta = this.controls.find(control => control.key === 'dvConta');
              parentFormControlDvConta.setHidden(false);
              parentFormControlDvConta.setRequired();

            }
          }

        }),
        new DataControl('Instituição Financeira', 'instituicaoFinanceira')
          .setTypeSelect()
          .setOptions({ url: '/instituicaofinanceira', value: 'id', label: ['codigo', ' - ', 'descricao'] }),
        new DataControl('Agência', 'agencia')
          .setMinLength(3)
          .setMaxLength(255),
        new DataControl('Dv Agência', 'dvAgencia'),
        new DataControl('Conta', 'conta')
          .setMinLength(3)
          .setMaxLength(255),
        new DataControl('Dv Conta', 'dvConta'),
        new DataControl('Saldo Inicial', 'saldoInicial')
          .setTypeMoney(),
          new DataControl('Conta Contábil', 'planoContas')
          .setTypeSelect()
          .setOptions({
            url: '/planocontas?$select=id,codCta,descrCta&$'
              + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
              ' and indCta eq ' + '\'' + 'A' + '\'' + ' and ' +
              'period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value
              + '&$orderby=codCta',
            value: 'id',
            label: ['codCta', ' - ', 'descrCta']
          }),
        new DataControl('Data Inicial', 'dtInicial')
          .setRequired()
          .setTypeDate(),
        new DataControl('Data Final', 'dtFinal')
          .setTypeDate(),
      ];
    }
  }
}
