import {Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Message, SelectItem } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TitleService } from 'app/shared/services/title.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarService } from '../../services/toolbar.service';
// import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-pedido-view',
  templateUrl: './pedido-view.component.html',
  styleUrls: ['./pedido-view.component.scss' ],
  providers: [CrudService]
})

export class PedidoViewComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;
  infosPedido: any;
  infosPedidoProd: any;
  qtdLinhas: any;
  descTotal: number;
  vlrOutros: number;
  subTotal: number;
  vlrTotal: number;
  pedidoId: any;
  pedidoIdioma: any;
  larguraImpressao = 70;

  constructor(
    private titleService: TitleService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private toolbarService: ToolbarService
    ) {}

    ngOnInit() {
      this.titleService.title = 'Pedido Impressão';
      this.infosPedido = '';
      this.infosPedidoProd = '';
      this.qtdLinhas = 0;
      this.descTotal = 0.00;
      this.vlrOutros = 0.00;
      this.vlrTotal = 0.00;
      this.subTotal = 0.00;
      this.pedidoId = '';
      this.pedidoIdioma = '';
      Promise.resolve(null).then(() => this.toolbarService.clear());
      this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
        () => {this.visualizarPedido();  }); // this.print1();
    }

  visualizarPedido() {
      this.activatedRoute.params.subscribe(params => {
        if (params['id']) {
          this.pedidoId = params['id'];
          this.pedidoIdioma = params['idioma'];
        }});
      this.httpService.wait();
      this.httpService.get('/custom/pedido/impressao-pedido(' + this.pedidoId +  ')')
          .subscribe(pedido => {this.infosPedido = pedido[0];
            this.titleService.title = this.titleService.title + ' ' + this.infosPedido.pedidoNum;

            // somente apos listar o pedido buscando os itens
            this.httpService.wait();
            this.httpService.get('/custom/pedido/impressao-pedido-produtos(' + this.pedidoId +  ')')
                .subscribe(produtos => { this.listarProdutos(produtos); },
                error => this.httpService.handleError(error, () => this.visualizarPedido()),
                () => this.httpService.done());
            // fim dos itens

          },
          error => this.httpService.handleError(error, () => this.visualizarPedido()),
          () => this.httpService.done());

    }

    listarProdutos(produtos: any) {
      this.infosPedidoProd = produtos;
      if (this.infosPedidoProd.length < 12 ) {
        this.qtdLinhas = new Array(12 - this.infosPedidoProd.length);
      }
      this.descTotal = 0.00;
      this.vlrOutros = 0.00;
      this.vlrTotal = 0.00;
      this.subTotal = 0.00;
      produtos.forEach(element => {

        if (element.vlrItem) {
          this.subTotal = this.subTotal + element.vlrItem;
        }
        if (element.vlrDescItem) {
          this.descTotal = this.descTotal + element.vlrDescItem;
        }
        // calculando valores extras - GCK 24.10
        if (element.vlrFrete) {
          this.vlrOutros = this.vlrOutros + element.vlrFrete;
        }
        if (element.vlrSeguro) {
          this.vlrOutros = this.vlrOutros + element.vlrSeguro;
        }
        if (element.vlrOutroItem) {
          this.vlrOutros = this.vlrOutros + element.vlrOutroItem;
        }
      });
      console.log();
      // this.vlrOutros = this.vlrOutros + this.infosPedido.subTotal; // alterado GCK 28.11
      this.vlrTotal = ((this.subTotal + this.vlrOutros) - this.descTotal);
      // this.print();
      // setTimeout(() => this.printPdf(), 5000);

    }

    print(selector: string = 'container-impressao') {
      this.larguraImpressao = 100;
      document.getElementById('div-botoes').style.display = 'none';
      this.utilService.print(selector);
    }

    /*printPdf() {
      // Get the element. https://github.com/eKoopmans/html2pdf#npm
      // documentação: https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html
      const element = document.getElementById('container-impressao');
      const opt = {
        margin:       0.5,
        filename:     'Pedido_' + this.infosPedido.pedidoNum + '.pdf',
        pagebreak:  { mode: ['avoid-all', 'css', 'avoid-page'] },
        html2canvas:  { scale: 3 },
        jsPDF:        { unit: 'cm', format: 'a4', orientation: 'portrait' }
      };
      html2pdf().set(opt).from(element).save();
    }*/

    ngOnDestroy() {
      this.dblinkedSessionService.hidePeriods();
      this.sessionService.destroySubscribe(this.sessionSubscription);
    }
}
