import { Empresa } from 'app/cadastro/model/empresa.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';

export class TributosPagamentoRelac {
    id: number;
    codigosObrigacoes: CodigosObrigacoes;
    usuarioResponsavelComprovante: Usuario;
    empresa: Empresa;

    constructor(tributospagamentorelac?: TributosPagamentoRelac | number) {
      if (tributospagamentorelac !== undefined && tributospagamentorelac !== null) {
        if (typeof tributospagamentorelac === 'number') {
          this.id = tributospagamentorelac;
        } else {
          this.id = tributospagamentorelac.id;
          this.codigosObrigacoes = tributospagamentorelac.codigosObrigacoes;
          this.usuarioResponsavelComprovante = tributospagamentorelac.usuarioResponsavelComprovante;
          this.empresa = tributospagamentorelac.empresa;
        }
      }
    }
  }
