import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService, SelectItem } from 'primeng/api';


@Component({
  selector: 'app-tipo-ingresso',
  templateUrl: './tipo-ingresso.component.html',
  styleUrls: ['./tipo-ingresso.component.scss'],
  providers: [CrudService]
})
export class TipoIngressoComponent implements OnInit, OnDestroy {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  pt_BR: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  msgs: Message[] = [];
  tpingresso: SelectItem[];
  tpingressoValue: number;

  ingresso: any;
  descricao: string;
  descricaoComplementar: string;

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'TIPO DE INGRESSO';
    this.subtitle = 'Tipo de Ingresso';
    this.titleService.title = 'INGRESSOS';
    this.tpingressoValue = 0;

    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();


    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.listar();
      });

  }



  hideMessage() {


  }

  onChangeIngresso(){
        this.descricao = null;
        this.descricaoComplementar = null;
        this.toolbarService.replace('add', true, 'class-new-button', 'SALVAR', 'Salvar', () => this.salvar());
        if (this.tpingressoValue > 0) {
          this.httpService.wait();
          this.httpService.get('/custom/polozi/get-ingresso',
            [this.tpingressoValue])
            .subscribe(retorno => {
                this.descricao = retorno[0].descricao;
                this.descricaoComplementar = retorno[0].descricaoComplementar;
                this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar', () => this.salvar());

            },
              error => this.httpService.handleError(error, () => this.listar()),
              () => this.httpService.done());

        }


  }

  salvar(){
    const event = {
      tpingressoValue: this.tpingressoValue,
      descricao: this.descricao,
      descricaoComplementar: this.descricaoComplementar
    };

    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-tipo-ingresso', event)
      .subscribe(result => {
        this.messageService.add({
          severity: result.severity,
          summary: result.summary,
          detail: result.detail
        });
        this.listar();
      },
        error => this.httpService.handleError(error, () => this.salvar()),
        () => this.httpService.done());
  }


  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');

    this.toolbarService.replace('atualizar', true, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.listar()
    );
    this.toolbarService.ocultar('save');

    this.toolbarService.replace('more_vert', true, 'class-edit-button', 'more_vert', 'Opções');
    this.toolbarService.ocultar('pesquisa');

    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir Pedido',
    );
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('export', true, 'class-edit-button', 'edit', 'Editar Evento', () => this.showModalEditarEventos()
    );
    this.toolbarService.replace('edit', true, 'class-edit-button', 'list', 'Alterar Títulos Selecionados');

    this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo Evento',
      () => this.showModalEventos()
    );

  }

  showModalEventos(){

  }

  showModalEditarEventos(){

  }


  listar() {

    this.toolbarEnable();
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

        this.tpingressoValue = 0;
        this.descricao = '';
        this.descricaoComplementar = '';

        this.tpingresso = [];

        this.getComtoTipoIngresso();



    }
    setTimeout(() => {
      // this.loadOpcoes();
    }, 1000);


  }

 getComtoTipoIngresso(){
  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-combo-tipo-ingresso')
    .subscribe(retorno => {
      console.log(retorno);
      this.tpingresso = [];
      this.tpingresso.push({ label: 'Selecionar Ingresso', value: 0 });
      retorno.forEach(element =>{
        this.tpingresso.push({label: element.codigo, value: element.id});
      });
    },
      error => this.httpService.handleError(error, () => this.listar()),
      () => this.httpService.done());

 }


  ngOnDestroy(): void {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


}
