import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Message, MessageService } from 'primeng/api';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';

@Component({
  selector: 'app-agenda',
  templateUrl: './txt-imp-conc.component.html',
  styleUrls: [
    './txt-imp-conc.component.scss'
  ],
  providers: [CrudService]
})
export class TxtImpConcComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  pt: any;
  msgs: Message[];
  arquivos: Array<any> = new Array();
  title: string;
  subtitle: string;

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    // this.toolbarService.clear();
    this.titleService.title = 'Conciliação';
    this.title = 'ARQUIVOS IMPORTADOS';
    this.subtitle = 'Arquivos txt importados no período selecionado';

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.periodChanged();
      }
    );
  }

  periodChanged(): void {
    if (
      this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected
    ) {
      this.httpService.wait();
      this.httpService
        .get(
          '/custom/conciliacao/txt-importados(' +
            this.dblinkedSessionService.empresa.id +
            ',' +
            this.dblinkedSessionService.periodo.month.value +
            ',' +
            this.dblinkedSessionService.periodo.year.value +
            ')'
        )
        .subscribe(
          rst => this.loadArquivos(rst),
          error =>
            this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done()
        );
    }
  }

  loadArquivos(value: any) {
    this.arquivos = new Array();
    if (value.length > 0) {
      value.forEach(element => {
        this.arquivos.push(element);
      });
    }
  }

  excluirArquivo(value: any) {
    if (
      confirm('Deseja realmente excluir todos os lançamentos deste arquivo?')
    ) {

      this.httpService.wait();
      this.httpService
        .post(
          '/custom/conciliacao/deletar-arquivo-txt(' +
            this.dblinkedSessionService.empresa.id +
            ',' +
            value.arquivo +
            ')',
          null
        )
        .subscribe(
          rst => {
            if (rst.result === undefined) {
              this.messageService.add({
                severity: 'success',key: 'messageLinnks',
                summary: 'Sucesso',
                detail: 'Lançamentos deletados com sucesso!'
              });
              this.periodChanged();
            } else {
              rst.result.forEach(element => {
                this.messageService.add({
                  severity: 'warn',key: 'messageLinnks',
                  summary: 'Advertência',
                  detail: element
                });
              });
            }
          },
          error =>
            this.httpService.handleError(error, () =>
              this.excluirArquivo(value)
            ),
          () => this.httpService.done()
        );
    }
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {

  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }
}
