export class EcfIdentQualif {
  id: number;
  cod: string;
  descricao: string;

  constructor(ecfIdentQualif?: EcfIdentQualif | number) {
    if (ecfIdentQualif !== undefined && ecfIdentQualif !== null) {
      if (typeof ecfIdentQualif === 'number') {
        this.id = ecfIdentQualif;
      } else {
        this.id = ecfIdentQualif.id;
        this.cod = ecfIdentQualif.cod;
        this.descricao = ecfIdentQualif.descricao;
      }
    }
  }
}
