import { Moeda } from "app/util/model/moeda.model";
import { isNullOrUndefined } from "util";
import { Periodo } from "./periodo.model";

export class PeriodoTaxa{
  id : number;
  periodo : Periodo;
  moeda : Moeda;
  taxa : string;


  constructor(periodoTaxa?: PeriodoTaxa | number){

    if (!isNullOrUndefined(periodoTaxa)) {

      if (typeof periodoTaxa === 'number') {
        this.id = periodoTaxa;
      } else {
        this.id = periodoTaxa.id
        this.periodo = periodoTaxa.periodo;
        this.moeda = periodoTaxa.moeda;
        this.taxa = periodoTaxa.taxa;
      }
    }
  }
}