import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AppComponent } from '../../app.component';
import { TitleService } from '../../shared/services/title.service';
import { HttpService } from '../../shared/services/http.service';
import { SessionService } from '../../shared/services/session.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { ConfigService } from '../../shared/services/config.service';
import { UtilService } from '../../shared/services/util.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-xml-nao-encontrado',
  templateUrl: './xml-nao-encontrado.component.html',
  styleUrls: ['./xml-nao-encontrado.component.scss'],
})
export class XmlNaoEncontradoComponent implements OnInit, OnDestroy {
  // TODO: Review INPUTS
  @Input() keyField: 'id';
  @Input() year: 'year';
  @Input() month: 'month';

  title: string;
  subtitle: string;
  private empresaSubscription: Subscription;
  private initSubscription: Subscription;

  private empresaId: number;
  private params: string;
  icmsIpi: Array<any> = new Array();
  analitico: number;
  detalhesPorItem: boolean;

  constructor(private titleService: TitleService,
              private httpService: HttpService,
              private toolbarService: ToolbarService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private configService: ConfigService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private appComponent: AppComponent,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    if (this.configService.production) {
      this.appComponent._displaySideNav = false;
    } else {
      setTimeout(() => { this.appComponent._displaySideNav = false; });
    }

    this.activatedRoute.params.subscribe(params => {
      console.log(params);
        this.empresaId = parseInt(params['id'], 10);
        this.month = params['month'];
        this.year = params['year'];
        if (!this.initSubscription) {
          this.initSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
                                                                          () => { this.carregar(); });
        } else {
          this.carregar();
        }
    });
    this.detalhesPorItem = ((this.analitico === 0) ? true : false);

    this.titleService.title = 'XML Não Encontrados';
    this.title = 'Relatório de XML não encontrados';

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.initSubscription);
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  private carregar() {
    this.icmsIpi = new Array<any>();
    this.detalhesPorItem = true;
      this.httpService.wait();
      this.httpService.get('/custom/fiscal/xml-nao-encontrado', [this.empresaId, this.year, this.month])
      .subscribe(dados => {
        this.icmsIpi = dados;
      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());
  }

  onFilter(event: any) {

  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

}
