import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

//import { Empresa } from '../../cadastro/model/empresa.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
//import { AppComponent } from '../../app.component';
import { AppComponent } from 'app/app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
//import { Requisicao } from '../model/requisicao.model';
import { Requisicao } from 'app/rms/model/requisicao.model';
import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CadProj } from 'app/contabil/model/cad-proj.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';

//import { RequisicaoItem } from '../model/requisicao-item.model';
import { RequisicaoItem } from 'app/rms/model/requisicao-item.model';
//import { RequisicaoFornecedorSugerido } from '../model/requisicao-fornecedor-sugerido.model';
import { RequisicaoFornecedorSugerido } from 'app/rms/model/requisicao-fornecedor-sugerido.model';
import { Moeda } from 'app/util/model/moeda.model';
import { Objeto } from 'app/rms/model/objeto.model';
import { Origem } from 'app/util/model/origem.model';
import { Nda } from 'app/rms/model/nda.model';
import { TipoAnexo } from 'app/rms/model/tipo-anexo.model';
import { Tipo } from 'app/rms/model/tipo.model';
import { Categoria } from 'app/rms/model/categoria.model';
import { Padis } from 'app/rms/model/padis.model';
import { LeiDoBem } from 'app/rms/model/lei-do-bem.model';
import { PrioridadeRequisicao } from 'app/rms/model/prioridade-requisicao.model';
import { PrioridadeCriterio } from 'app/rms/model/prioridade-criterio.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { RequisicaoStatus } from 'app/rms/model/requisicao-status.model';
import { Message, MessageService } from 'primeng/api';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-aprovador-fatura',
  templateUrl: './aprovador-fatura.component.html',
  styleUrls: ['./aprovador-fatura.component.scss'],
})
export class AprovadorFaturaComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = [];

  titlePrincipal: string;
  subtitle: string;

  listaUsuarios: Array<any> = new Array();
  listaAprovadores: Array<any> = new Array();

  aprovadoresTecnicos: SelectItem[];
  aprovId: number;

  listaUsuariosRelacionar: Array<any> = new Array();
  listaUsuariosRelacionados: Array<any> = new Array();

  picklistState: boolean = false
  picklistState2: boolean = true

  toolbarMainIni = [
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Aprovadores', visible: true, disabled: false, color: 'green', onClick: () => this.salvarAprovadores() }
  ]

  public toolbarMain = new Toolbar()

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    // requisição

    this.titlePrincipal = 'CONFIGURAÇÕES';
    this.subtitle = 'APROVADORES FATURA';
    this.titleService.title = 'Aprovadores Técnicos para a Fatura';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.aprovId = 0;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });

    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
  }

  ngOnDestroy() {
    // this.toolbarService.clear();
    // this.toolbarService.hidden = false;
    // this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
    this.toolbarService.hidden = false
  }

  data() {
    // this.dtCorte = new Date(this.timezonePipe.transform(this.dtCorte));
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

        this.listaUsuarios = new Array();
        this.listaAprovadores = new Array();
        this.listaUsuariosRelacionar = new Array();
        this.listaUsuariosRelacionados = new Array();

        this.picklistState = false

        this.aprovadoresTecnicos = [];
        this.aprovadoresTecnicos.push({ label: 'Selecione', value: 0 });

        this.httpService.wait();
        this.httpService.get('custom/fatura/listar-aprovadores-fatura',
          [this.dblinkedSessionService.empresa.id] )
          .subscribe(retorno => {
            retorno.forEach(element => {

              this.aprovadoresTecnicos.push({ label: element.nome, value: element.id });
            });

          }, error => this.httpService.handleError(error, () => this.change( )),
            () => this.httpService.done());



        this.httpService.wait();
      //this.httpService.get('custom/rms/listar-usuarios',
      this.httpService.get('custom/fatura/listar-usuarios',
        [this.dblinkedSessionService.empresa.id])
        .subscribe(retorno => {
          retorno.forEach(element => {

            if(element.aprov){
              this.listaAprovadores.push(element);
            }else{
              this.listaUsuarios.push(element);
            }

            this.picklistState = true
          });

        }, error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());

      // renderizando a toolbar
      setTimeout(() => {
        this.toolbarEdit();
      }, 500);
    }
  }

  onFilterRms(event: any) {
    console.log('event: ' + event);
  }

  export() {
    // alert('export');
  }

  pesquisa() {
    // alert('pesquisa');
  }


 hideMessage() {

  }

  private toolbarEdit() {
    const todos = this.buttons.slice();
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.alterarStatus('pesquisa', true, 'Detalhes Rateio', () => this.pesquisa());
    this.toolbarService.alterarStatus('download', true, 'Download Anexos', () => this.export());
    this.toolbarService.alterarStatus('voltar', true, 'Voltar', () => this.ngOnInit());
    this.toolbarService.alterarStatus('atualizar', true, 'Atualizar RMS', () => this.change());
    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.ngOnInit());
    this.toolbarService.alterarStatus('save', true, 'Salvar requisição', () => this.ngOnInit());
    this.toolbarService.alterarStatus('edit', true, 'Editar requisição', () => this.ngOnInit());
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Aprovadores', () => this.salvarAprovadores());
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  salvarAprovadores(){
    if (confirm('Deseja realmente salvar os aprovadores?')) {
      const req = {
        empresa: this.dblinkedSessionService.empresa.id,
        listaUsuarios: this.listaUsuarios,
        listaAprovadores: this.listaAprovadores
      };



      this.httpService.wait();
      this.httpService.post('/custom/fatura/salvar-aprovadores-fatura', req)
        .subscribe(result => {
          let existeErro = false;
          result.forEach(element => {
            if(element.error){
              existeErro = true;
              this.messageService.add({
                severity: element.severity,
                summary: 'MSG: ',
                detail: element.message
              });
            }
          });
          if(!existeErro){
            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Alteração salva com sucesso!'
            });
          }


          this.change();
        },
          error => this.httpService.handleError(error, () => this.salvarAprovadores()),
          () => this.httpService.done());
    }
  }

  salvarRelacionamento(){
    if (confirm('Deseja realmente salvar este relacionamento?')) {
      const req = {
        aprov: this.aprovId,
        listaUsuariosRelacionar: this.listaUsuariosRelacionar,
        listaUsuariosRelacionados: this.listaUsuariosRelacionados
      };


      this.httpService.wait();
      this.httpService.post('/custom/fatura/salvar-relacionamento', req)
        .subscribe(result => {
          let existeErro = false;
          result.forEach(element => {
            if(element.error){
              existeErro = true;
              this.messageService.add({
                severity: element.severity,
                summary: 'MSG: ',
                detail: element.message
              });
            }
          });
          if(!existeErro){
            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Alteração salva com sucesso!'
            });
          }


          this.change();
        },
          error => this.httpService.handleError(error, () => this.salvarAprovadores()),
          () => this.httpService.done());
    }
  }

  tabChange(event: any){

    if(event.index === 1){

      this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Relacionamento', () => this.salvarRelacionamento());
    }else{
      this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Aprovadores', () => this.salvarAprovadores());
    }
  }

  buscarUsuariosAprovador(){

    this.listaUsuariosRelacionados = new Array();
    this.listaUsuariosRelacionar = new Array();

    this.picklistState2 = false

    if(this.aprovId > 0){
      this.httpService.wait();
      this.httpService.get('custom/fatura/listar-usuarios-relacionados',
        [this.aprovId, this.dblinkedSessionService.empresa.id])
        .subscribe(retorno => {
          retorno.forEach(element => {
            if(element.relac){
              this.listaUsuariosRelacionados.push(element);
            } else {
              this.listaUsuariosRelacionar.push(element);
            }

            this.picklistState2 = true
          });

        }, error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());

    } else {
      this.picklistState2 = true
    }
  }
}
