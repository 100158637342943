export class ColumnRelat {
  id: number;
  configRelatId: number;
  tipo: number = null;
  qtdDecimais: number = null;
  sql: string = null;
  indice: number = null;
  label: string = null;
  formula: string = null;
  corFundo: number = null;
  corTexto: number = null;
  isCorFundoCabecalho: boolean = false;
  isCorFundoColuna: boolean = false;
  isCorTextoCabecalho: boolean = false
  isCorTextoColuna: boolean = false;
  isCabecalhoNegrito: boolean = false;
  isColunaNegrito: boolean = false;
  isValor: boolean = true;
  isPercent: boolean = false;
  isFormulaPgc: boolean = false;
  viewCampoValor: string = null;

  constructor(columnRelat?: ColumnRelat | number) {
    if (columnRelat !== undefined && columnRelat !== null) {
      if (typeof columnRelat === 'number') {
        this.id = columnRelat;
      } else {
        this.id = columnRelat.id;
        this.configRelatId = columnRelat.configRelatId;
        this.tipo = columnRelat.tipo;
        this.qtdDecimais = columnRelat.qtdDecimais;
        this.sql = columnRelat.sql;
        this.indice = columnRelat.indice;
        this.label = columnRelat.label;
        this.formula = columnRelat.formula;
        this.corFundo = columnRelat.corFundo;
        this.corTexto = columnRelat.corTexto;
        this.isCorFundoCabecalho = columnRelat.isCorFundoCabecalho;
        this.isCorFundoColuna = columnRelat.isCorFundoColuna
        this.isCorTextoCabecalho = columnRelat.isCorTextoCabecalho;
        this.isCorTextoColuna = columnRelat.isCorTextoColuna
        this.isCabecalhoNegrito= columnRelat.isCabecalhoNegrito;
        this.isColunaNegrito = columnRelat.isColunaNegrito;
        this.isValor = columnRelat.isValor;
        this.isPercent = columnRelat.isPercent;
        this.isFormulaPgc = columnRelat.isFormulaPgc;
        this.viewCampoValor = columnRelat.viewCampoValor;
      }
    }
  }
}
