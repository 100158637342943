import { FaturaItem } from "./fatura-item.model";
import { CadProj } from "app/contabil/model/cad-proj.model";
import { CadCcus } from "app/contabil/model/cad-ccus.model";
import { AuthUser } from "app/proof/model/auth-user.model";

export class FaturaRateio {
  id: number;
  faturaItem : FaturaItem;
  cadProj: CadProj;
  cadCcus: CadCcus;
  authUser : AuthUser;
  porcentagem: number;

  constructor(faturaRateio?: FaturaRateio | number) {
    if (faturaRateio !== undefined && faturaRateio !== null) {
      if (typeof faturaRateio === 'number') {
        this.id = faturaRateio;
      } else {
        this.id = faturaRateio.id;
        this.faturaItem = faturaRateio.faturaItem;
        this.cadProj = faturaRateio.cadProj;
        this.cadCcus = faturaRateio.cadCcus;
        this.porcentagem = faturaRateio.porcentagem;
        this.authUser = faturaRateio.authUser;
    }
  }
}



}