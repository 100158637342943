import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Inject, PLATFORM_ID, ViewChild, QueryList } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import "froala-editor/js/plugins.pkgd.min.js"; //all plugins imported
import FroalaEditor from 'froala-editor'
import { AuthUser } from 'app/proof/model/auth-user.model';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { DragulaOptions } from 'dragula';
import { DragulaService, DragulaDirective } from 'ng2-dragula';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from '../../../../node_modules/primeng/api';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { TodoCard } from '../model/todo-card.model';
import { TodoCardChecklist } from '../model/todo-card-checklist.model';
import { TodoCardChecklistAtividades } from '../model/todo-card-checklist-atividades.model';
import { TodoCardComentarios } from '../model/todo-card-comentarios.model';
import { TodoEtiquetas } from '../model/todo-etiquetas.model';
import { TodoEtiquetasCardRelac } from '../model/todo-etiquetas-card-relac.model';
import { TodoPainel } from '../model/todo-painel.model';
import { TodoPartCardRelac } from '../model/todo-part-card-relac.model';
import { TodoProjeto } from '../model/todo-projeto.model';
import { TodoProjetoUsuarios } from '../model/todo-projeto-usuarios.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { Util } from 'app/shared/common/util';
import { isPlatformBrowser } from '@angular/common';
import { TodoAlcadasAcoes } from '../model/todo-alcadas-acoes.model';
import { TodoAlcadasRegras } from '../model/todo-alcadas-regras.model';
import { DndDropEvent } from 'ngx-drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TodoPrioridade } from '../model/todo-prioridade.model';
import { SocketService } from 'app/shared/services/socket.service';
import { LoginService } from 'app/shared/services/login.service';
import { PrimeNGConfig } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import * as Highcharts from 'highcharts/highstock';
import HC_exportData from 'highcharts/modules/export-data';
import { element } from 'protractor';
import { ViewCampos } from 'app/cadastro/model/view-campos.model';
import { isNullOrUndefined } from 'util';
import { Requisicao } from 'app/rms/model/requisicao.model';
import { Pedido } from 'app/financeiro/model/pedido.model';
import { NfeFin } from 'app/financeiro/model/nfe-fin.model';

declare var $: any;
HC_exportData(Highcharts);
@Component({
  selector: 'app-work-flow',
  templateUrl: './work-flow.component.html',
  styleUrls: ['./work-flow.component.scss', '../../shared/styles/centralweb.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ],
  providers: [MessageService]
})
export class WorkFlowComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('container') container: ElementRef;
  @ViewChild('inputTitleList') _inputTitleList: ElementRef;
  @ViewChild('descricaoCartao') _descricaoCartao: ElementRef;
  @ViewChild('comentarioCartao') _comentarioCartao: ElementRef;
  @ViewChild('inputCardText') _newCardText: ElementRef;
  @ViewChild('file') fileInput: ElementRef;
  @ViewChild('addChkLstAtiv') addChkLstAtiv: ElementRef;

  // editableChkLstAtiv: any;
  private modalRef: NgbModalRef;
  dataFim: NgbDateStruct;
  today = this.calendar.getToday();

  temp: any;
  ticks = 0;
  private timer: any;
  private sub: Subscription;
  private projId: any;

  asideInformation: Array<any> = [];
  showAside: boolean = false;
  selectedAllTable: boolean = false;

  public FroalaEditor = require('froala-editor');

  currentDate: any;

  statusMovimentacao: boolean = true;
  statusVencidos: boolean = false;
  tab: number = 0;

  private subGetIdProjeto: any;
  isRTL: boolean;

  dataNew: Date;

  listas = []; // lista principal;
  listasCtrl = []; // lista de comparação;
  listasMov = false; // permissão de comparação das listas;
  validaLista = false; // permissão de atualização das listas;
  listasAlcadasCtrl = []; // lista de principal;

  widgetList: Array<any> = [];
  widgetService: Array<any> = [];

  textTitulo = '';
  displayCheckBoxImpressao = 'none'
  displayCLModal = 'none';
  displayFiltroAvancado = 'none';
  displayDefinirAprovadorModal = 'none';
  displayDefinirAprovadorModalCard = 'none';
  displayEtiqModal = 'none';
  displayModalReq = 'none';
  displayModalResp = 'none';
  displayTexAtiv = [];
  displayMoverCardsModal = 'none';
  displaySeguirCardModal = 'none';
  //displayAnexoModal = 'none';
  displayCategoriaAnexo = 'none';
  displayMoverCardsList = 'none';
  displayPartModal = 'none';
  displayPrazoModal = 'none';
  displayPrioridadeModal = 'none';
  displayUnarchiveList = 'none';
  displayStatusModal = 'none';
  textCartao = '';
  textDescricao = '';
  textComentario = '';
  textChecklist = '';

  closeResult: string;
  file: File;

  //impressao
  ocultarComents = false;
  ocultarAtiv = false;

  // cheklist controls
  checkList = new TodoCardChecklist();
  checklistAtividade = new TodoCardChecklistAtividades();
  card = new TodoCard();
  etiquetas: Array<TodoEtiquetas> = [];
  participantes: Array<TodoProjetoUsuarios> = [];
  participantesProjeto: Array<TodoProjetoUsuarios> = [];
  participante = new TodoProjetoUsuarios();
  prioridade = 0;
  chkLstCount = 0;
  chkLstItCount = 0;
  // end cheklist

  //
  todoCard = new TodoCard();
  msgs: Message[] = [];
  msgsModal: Message[] = [];
  msgsLista: Message[] = [];
  msgsItem: Message[] = [];

  // visualização de telas
  currentScreen = [
    { name: 'Kanban', code: 'KB', inactive: false },
    { name: 'Tabela', code: 'TB', inactive: false },
  ];
  selectedScreen: string = null;
  dashboardScreen1: boolean = true;
  dashboardScreen2: boolean = false;

  viewCampos: Array<ViewCampos> = new Array();

  // heitor
  // ============================================
  private sessionSubscription: Subscription; // change session event;

  // indexes;
  idListaIni: any;
  idListaFin: any;
  indexCurrentPainel: any;
  indexCurrentCard: any;
  indexCurrentChecklist: any;

  // listas;
  listMoverCards = [];
  listArquivados = []; // listas arquivadas;
  listSugestoes = [];

  tableData: any = [];

  // projeto info;
  projetoNome: any;
  projetoKey: any;

  // url;
  todoProjetoId: any;
  todoCardId: any;

  // user adm;
  userAdm: Boolean = false;

  // modal convidar participante;
  modalConvidarParticipante = false;
  mcpCount = 0;
  mcpInput = '';
  mcpEmail = [];
  mcpEmailList: SelectItem[] = [];
  mcpLoginList = [];

  // modal desarquivar;
  modalDesarquivar = false;
  mdItem: any;
  mdCards = false;
  mdOption: any;
  mdOptionList: SelectItem[] = [];

  // modal editar projeto;
  modalEditarProjeto = false;
  mepAdms = [];
  mepAdmsList: SelectItem[];
  mepAdmsListSubstituto: SelectItem[];
  mepCor: any;
  mepDepartamento = [];
  mepDepartamentoList: SelectItem[];
  mepKey: any;
  mepNome: any;
  mepUsuarios = [];
  mepUsuariosList: SelectItem[];
  usuariosList: SelectItem[] = [];
  mepEtiquetaList: SelectItem[];
  addParticipantes = false;
  mepOwner = 0;
  mepOwnerList: SelectItem[];
  mepOrdenacao = 0;
  mepOrdenacaoList: SelectItem[];
  blockMovVertical = false;
  addParticipantesOld = false;
  owner = 0;
  ordenacao = 0;
  listaPrioridade: SelectItem[];

  // modar etiquetas projeto;
  modalEtiquetaProjeto = false;
  etiqueta = new TodoEtiquetas();
  etiquetaList: Array<TodoEtiquetas> = [];

  // modal mover cards;
  moveAll = false;

  // modal categorias anexo;
  modalCategoriasAnexo = false;
  categoriaId = null;
  categoriaNome = '';
  categoriaList: SelectItem[] = [];

  // modal seguir card;
  segCard: Array<number> = [];
  seguirCardList: SelectItem[];
  listSeguindo = [];
  seguirCardValue = 0;

  // modal anexo;
  enableInput = false;

  // modal alçadas;
  modalAlcadas = false;
  alcadasBtnAprovacao = true;

  alcadasList: SelectItem[] = []; // lista de alçadas no modal de alçadas;
  alcadasCardList: SelectItem[] = []; // lista de alçadas no modal do card;
  alcadaId: any;
  alcadaDescricao = '';
  isCardMov: boolean = true;

  alcadasRegrasList: Array<TodoAlcadasRegras> = [];
  alcadasRegrasFullList: Array<TodoAlcadasRegras> = [];

  alcadasPainelIniList: SelectItem[];
  alcadasPainelFinList: SelectItem[];
  alcadasPainelFinListTEMP: SelectItem[];
  alcadasAcoesList: SelectItem[];

  regrasCount = 0;

  todoCardComentario = new TodoCardComentarios();

  currentStateMov = 'initial';

  //usuario substituto
  usuarioSubstituto = 0;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options = {
    credits: { enabled: false },
    chart: {
      type: 'column',
      renderTo: 'chart',
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },
    title: {
      text: "Movimentação"
    },
    xAxis: [{
      categories: ['Empresa 1', 'Empresa 2', 'Empresa 3'],
      crosshair: true
    }],
    yAxis: {
      title: {
        text: "",
        reserveSpace: false
      }
    },
    tooltip: {
      valuePrefix: "R$ ",
      valueDecimals: 2,
      shared: true
    },
    series: [{
      type: 'column',
      name: "Pagamentos",
      data: [0, 1, 2],
      color: "#D95656"
    },
    {
      type: 'column',
      name: "Recebimentos",
      data: [1, 2, 3],
      color: "#5F91E6"
    },
    {
      type: 'spline',
      name: "Saldo acumulado",
      data: [1, 2, 3],
      color: "#C0C0C0",
      dataLabels: {
        enabled: true,
        formatter: function () {
          return this.y.toLocaleString('pt-Br', {
            maximumFractionDigits: 0
          })
        }
      }
    }]
  }


  showHighcharts: boolean = false;

  // modal alçadas;
  modalAssinarCard = false;
  modalUsuarioSubstituto = false;
  modalAssinarCards = false;
  assBtnRecusa = false;
  assCardIndex: any;
  assCloneList: any;
  assIndexDestino: any;
  assIndexDestinoList: any;
  assResponse: any;

  modalOrigemProjeto = false;
  exibirCondicao = false;
  buscarFinanceiro = false;
  buscarPedido = false;
  sql = '';
  condicaoSelecionada: any;


  //modal filtros
  modalFiltrar = false;
  mepRequisitante = [];
  mepPrioridade = [];
  mepResponsavel = [];
  mepMarcador = [];
  filtroVazio = true;
  mepContem: any;
  filtro = {
    requisitantes: [],
    prioridades: [],
    responsaveis: [],
    marcadores: [],
    contem: String
  }

  alturaDaMaiorLista: any;
  idDaMaiorLista: any;

  public options: Object = {
    charCounterCount: false,
    imageUpload: true,
    imageDefaultAlign: 'left',
    toolbarButtons: [['bold', 'italic', 'underline', 'paragraphFormat', 'align'],
    ['insertImage', 'insertLink', 'insertVideo'], ['insertTable', 'formatOL', 'formatUL'], ['undo', 'redo']],
    imageDefaultDisplay: 'inline-block',
    imageUploadMethod: 'POST',
    apiKey: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',
    imageMaxSize: 5 * 1024 * 1024,
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
    events: {
      'image.beforeUpload': function (images, editor = this) {
        if (images.length) {
          // Criando um File Reader.
          const reader = new FileReader();
          // Configure o leitor para inserir imagens quando elas estiverem carregadas.
          reader.onload = (ev) => {
            const result = ev.target['result'];
            editor.image.insert(result, null, null, editor.image.get());
            // console.log('Saída do leitor de arquivos' + ev,  editor.image,  ev.target['result']);
          };
          // Lendo imagem as base64.
          reader.readAsDataURL(images[0]);
        }
        // Pare o upload padrão.
        return false;
      }
    }
  };

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', '|',
      'insertImage', 'insertLink', 'insertVideo', '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', '|',
      'insertImage', 'insertLink', 'insertVideo', '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', '|',
      'insertImage', 'insertLink', 'insertVideo', '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', '|',
      'insertImage', 'insertLink', 'insertVideo', '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    apiKey: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    // Set max image size to 5MB.
    imageMaxSize: 5 * 1024 * 1024,

    // somente upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
    events: {
      initialized: function () {
        // console.log('froalaEditor - > inicializado');
      },
      'image.beforeUpload': function (editor, images) {
        if (images.length) {
          // Criando um File Reader.
          const reader = new FileReader();
          // Configure o leitor para inserir imagens quando elas estiverem carregadas.
          reader.onload = (ev) => {
            const result = ev.target['result'];
            // editor.image.insert(result, null, null, editor.image.get());
            console.log('Saída do leitor de arquivos' + ev, editor.image, ev.target['result']);
          };
          // Lendo imagem as base64.
          reader.readAsDataURL(images[0]);
        }
        // Pare o upload padrão.
        return false;
      }

    },
  }

  showActions = false;
  showAprovs = false;
  showComentarios = false;
  nomeIni = ''; // nome inicial dos campos título, descrição e etc pra ser utilizado na inserção das atividades;
  // ============================================

  // parametros para permissões para Listas
  optionsList: DragulaOptions = {
    isContainer: function (el) {
      return false; // somente elementos em drake.containers serão levados em conta
    },
    moves: (el, container, handle: Element, sibling) => {
      el.classList.add('is-moving');
      this.listasMov = false;
      this.sub.unsubscribe();
      if (handle.className.split(' ').indexOf('disabled') !== -1) {


        this.messageService.add({
          severity: 'error', key: 'messageLinnks',
          summary: 'Erro',
          detail: 'Esta lista só pode ser movida pelo administrador.'
        });

        return false;
      } else if (handle.className.split(' ').indexOf('some-handle') !== -1) {
        return handle.className.split(' ').indexOf('some-handle') !== -1;
      } else {
        return false;
      }
    },
    revertOnSpill: true, // spilling colocará o elemento de volta de onde ele foi arrastado, se isso for verdade
    direction: 'horizontal'
  };
  // parametros para permissões para os itens
  optionsItem: DragulaOptions = {
    moves: (el, container, handle: Element, sibling) => {
      this.idListaIni = el.getAttribute('list-id');
      // console.log('\n--> idListaIni' + JSON.stringify(this.idListaIni));

      this.listasMov = false;
      this.sub.unsubscribe();
      el.classList.add('is-moving');
      if (handle.className.split(' ').indexOf('disabled') !== -1) {


        this.messageService.add({
          severity: 'error', key: 'messageLinnks',
          summary: 'Erro',
          detail: 'Este cartão só pode ser movido pelo aprovador.'
        });

        return false;
      }

      return handle.className.split(' ').indexOf('handle') !== -1;
    },
    revertOnSpill: true // spilling colocará o elemento de volta de onde ele foi arrastado, se isso for verdade
  };
  /// parametros para mover atividades checklist
  optionsAtividades: DragulaOptions = {
    moves: (el, container, handle: Element, sibling) => {
      this.stopGetListas();
      // this.msgSocket(this.todoProjetoId);
      return true;
    },
    revertOnSpill: true
  };

  /** DRAG AND DROP FILES - GCK */
  public lastDropEvent: DndDropEvent | null = null;
  ctrlDropFile: any;
  public lastDropTypes: ReadonlyArray<string>;
  public lastDropFiles: object[];
  public lastDropItems: object[];

  private messagesSubscription: Subscription;

  newDataFim: Date;
  private _chart: Highcharts.Chart;

  filterAsideValue: String = ''
  cloneAside: Array<any> = []

  constructor(
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private dblinkedSessionService: DblinkedSessionService,
    private dragulaService: DragulaService,
    private httpService: HttpService,
    private modalService: NgbModal,
    private router: Router,
    public sessionService: SessionService,
    private titleService: TitleService, // ButtonModule, CalendarModule, Calendar
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private snackBarService: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: Object,
    private socketService: SocketService,
    private loginService: LoginService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private configService: ConfigService) {
    this.dragulaService.createGroup('LISTAS', this.optionsList);
    this.dragulaService.createGroup('SUBITEM', this.optionsItem);
    this.dragulaService.createGroup('ATIVIDADES', this.optionsAtividades);

    // drop painel;
    // ------------------------------------------
    this.dragulaService.dropModel('LISTAS').subscribe(args => {
      const listasTmp = [];
      args.targetModel.forEach(lista => {
        listasTmp.push(lista);
      });
      this.listas = listasTmp;

      /*this.listasMov = true;
      this.startReload();*/

      this.updateIndexPaineis();
    });

    // drop card;
    // ------------------------------------------
    this.dragulaService.dropModel('SUBITEM').subscribe(args => {
      let strDiv = args.target.parentElement.outerHTML;
      const strPart = strDiv.search('list-id');
      strDiv = strDiv.slice(strPart, strPart + 15);
      const idFind = strDiv.split('"');
      this.idListaFin = idFind[1];

      this.listasMov = false;
      this.sub.unsubscribe();

      // Movimentação na mesma lista !
      if (this.idListaIni !== undefined && this.idListaFin !== undefined) {
        if (this.idListaIni === this.idListaFin) {
          if (this.blockMovVertical) {
            //bloquear movimentação na mesma lsta
            this.optionsItem.revertOnSpill = true;
            this.listasMov = true;
            this.getListas('ctrl');
            this.startReload();

            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'Advertência',
              detail: 'Não é possivel mover o cartão na lista.'
            });

            return;
          } else {
            // TARGET;
            this.moveCards(args.targetModel, this.idListaFin, false);
          }
        } else {
          // Movimentação para outra lista !

          // validação se o drop card é inválido;
          // --------------------------------------
          let saveChanges = true;

          if (this.idListaFin > this.idListaIni) {
            let stopAdding = false;

            // percorre as listas;
            this.listas.forEach(painel => {
              if (!stopAdding) {
                // somente considerar as listas cuja posição é maior do que a posição da lista que o card saiu;
                if (painel.id > this.idListaIni) {
                  // se a posição da lista for igual à posição em que o card foi inserido...
                  if (painel.id.toString() === this.idListaFin.toString()) {
                    stopAdding = true;
                  } else {
                    if (painel.aprovador != null) {

                      this.messageService.add({
                        severity: 'error', key: 'messageLinnks',
                        summary: 'Erro',
                        detail:
                          'Este cartão deve ser aprovado na lista: ' +
                          painel.name +
                          '.'
                      });
                      saveChanges = false;
                      stopAdding = true;
                    }
                  }
                }
              }
            });

          }

          if (!saveChanges) {
            this.listas = this.listasCtrl;
            this.listasMov = true;
            this.startReload();
          } else {
            // SOURCE;
            this.moveCards(args.sourceModel, this.idListaIni, false);

            // TARGET;
            this.moveCards(args.targetModel, this.idListaFin, true);
          }
        }
      }
      /*this.listasMov = true;
      this.startReload();*/
    });

    // drop Atividades;
    // ------------------------------------------
    this.dragulaService.dropModel('ATIVIDADES').subscribe(args => {
      this.moveAtiv(args.targetModel);
    });
    this.isRTL = true;
  }

  ngAfterViewInit() {
    Object.assign(this.optionsList, {
      mirrorContainer: this.container.nativeElement
    });

  }

  rowData: any;
  cols = [];

  ngOnInit(): void {
    this.dataNew = new Date();

    this.primengConfig.ripple = true;
    Promise.resolve(null).then(() => (this.toolbarService.hidden = true));
    this.dblinkedSessionService.hidePeriods();
    this.titleService.title = 'Projetos - WorkFlow';
    // construtor do card;
    this.card = new TodoCard();
    this.etiquetas = [];
    this.currentDate = new Date().valueOf();
    this.todoCardComentario = new TodoCardComentarios();
    this.buscarViews();

    // heitor
    // ==========================================
    this.subGetIdProjeto = this.activatedRoute.params.subscribe(params => {
      this.todoProjetoId = params['todoProjetoId'];
      this.todoCardId = params['todoCardId'];
    });

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
      }
    );
    // ==========================================
  }

  ngOnDestroy() {
    this.listasMov = false;
    this.validaLista = false;

    this.dragulaService.destroy('ATIVIDADES');
    this.dragulaService.destroy('SUBITEM');
    this.dragulaService.destroy('LISTAS');
    this.dragulaService.removeModel('ATIVIDADES');
    this.dragulaService.removeModel('SUBITEM');
    this.dragulaService.removeModel('LISTAS');

    /*const bag1 = this.dragulaService.find('ATIVIDADES');
    console.log('\n--> ATIVIDADES' + JSON.stringify(bag1));
    const bag2 = this.dragulaService.find('SUBITEM');
    console.log('\n--> SUBITEM' + JSON.stringify(bag2));
    const bag3 = this.dragulaService.find('LISTAS');
    console.log('\n--> LISTAS' + JSON.stringify(bag3));*/

    // limpando sessoes
    Promise.resolve(null).then(() => (this.toolbarService.hidden = false));

    this.sub.unsubscribe();
    this.subGetIdProjeto.unsubscribe();
    this.sessionService.destroySubscribe(this.sessionSubscription);
    this.sessionSubscription.unsubscribe();
    this.listas = [];
  }

  editandoTitulo(idCol: any): any {
    this.setIndexCurrentPainel(idCol);
  }

  adicionarCartao(str: boolean, iCol: any): void {
    this.textCartao = '';
    if (str) {
      document.getElementById('novoCartao_' + iCol).style.display = 'none';
      document.getElementById('salvarCartao_' + iCol).style.display = 'grid';
      this._newCardText.nativeElement.focus();
    } else {
      document.getElementById('novoCartao_' + iCol).style.display = 'inline';
      document.getElementById('salvarCartao_' + iCol).style.display = 'none';
    }
  }

  salvarCartao(idCol: any): any {
    // Atualiza a lista local;
    // ------------------------------------------
    this.setIndexCurrentPainel(idCol);

    const nome =
      this.dblinkedSessionService.usuario.nome +
      ' ' +
      this.dblinkedSessionService.usuario.sobrenome;
    const avatar = nome.split(' ');

    const novo = {
      // *** ESPELHAR KEYS NOS MÉTODOS:
      // cardClone()
      // getListas()
      id: this.projetoKey,
      idTabela: null,
      idPainel: this.indexCurrentPainel.id,
      name: this.textCartao,
      descricao: '',
      posicao: this.indexCurrentPainel.subitems.length + 1,
      responsavelId: null,
      responsavel: null,
      requisitanteId: this.sessionService.loggedUser.id,
      requisitante:
        this.dblinkedSessionService.usuario.nome +
        ' ' +
        this.dblinkedSessionService.usuario.sobrenome,
      avatar: avatar[0].charAt(0) + avatar[avatar.length - 1].charAt(0),
      dataInicio: null,
      dataFim: null,
      newDataFim: null,
      flags: [],
      comentarios: [],
      checkLists: [],
      checkListsConcluidos: null,
      qtdAtividades: null,
      anexos: [],
      participantes: [],
      seguindo: null,
      assinado: false,
      actions: [],
      changeTimestamp: new Date(),
      aprovador: null,
      aprovadorNome: null,
      alcada: null,
      alcadaNome: null,
      prioridade: 0,
    };
    this.indexCurrentPainel.subitems.push(novo);

    // reiniciando variaveis
    document.getElementById('novoCartao_' + idCol).style.display = 'inline';
    document.getElementById('salvarCartao_' + idCol).style.display = 'none';
    this.textCartao = '';

    // Atualiza o banco;
    // ------------------------------------------
    this.httpService
      .post('/custom/workflow/select-card-count', {
        todoProjetoId: this.todoProjetoId,
        todoPainelId: this.indexCurrentPainel.idTabela
      })
      .subscribe(
        rst1 => {
          this.startGetListas();
          this.setIndexCurrentCard(idCol, novo.id);
          this.indexCurrentCard.id = this.projetoKey + (rst1.count + 1);
          this.calculandoAltura();

          const todoCard = new TodoCard();
          todoCard.codigo = rst1.count + 1;
          todoCard.titulo = novo.name;
          todoCard.posicao = novo.posicao;
          todoCard.todoPainel = new TodoPainel();
          todoCard.todoPainel.id = this.indexCurrentPainel.idTabela;
          todoCard.arquivado = false;
          todoCard.excluido = false;
          todoCard.responsavel = new AuthUser();
          todoCard.responsavel.id = this.sessionService.loggedUser.id;
          todoCard.usuario = new AuthUser();
          todoCard.usuario.id = this.sessionService.loggedUser.id;
          todoCard.prioridade = new TodoPrioridade();
          todoCard.prioridade.id = 2;

          this.httpService.postdb('/todocard', todoCard).subscribe(
            rst2 => {

              this.msgSocket(this.todoProjetoId);
              this.indexCurrentCard.idTabela = rst2.id;
              this.seguirCardRequisitante(rst2.id);
              this.getListas('ctrl');

              // insere os participantes do projeto no card;
              if (this.addParticipantes) {
                this.participantesProjeto.forEach(participante => {
                  if (participante.usuario.id != this.sessionService.loggedUser.id) {
                    this.setParticipanteCard(participante);
                  }
                });
              }
            },
            error =>
              this.httpService.handleError(error, () =>
                this.salvarCartao(idCol)
              ),
            () => this.httpService.done()
          );

        },
        error =>
          this.httpService.handleError(error, () => this.salvarCartao(idCol)),
        () => this.httpService.done()
      );


  }

  novaLista(str: boolean): void {
    this.textTitulo = '';
    if (str) {
      document.getElementById('novaLista').style.display = 'none';
      document.getElementById('addNovaLista').style.display = 'inherit';
      this._inputTitleList.nativeElement.focus();
    } else {
      document.getElementById('novaLista').style.display = 'inherit';
      document.getElementById('addNovaLista').style.display = 'none';
    }
  }

  adicionarLista() {
    this.listasMov = false;
    this.sub.unsubscribe();
    // Atualiza a lista local;
    // ------------------------------------------
    const posicao = this.listas.length + 1;

    const novo = {
      // *** ESPELHAR KEYS NO MÉTODO getListas() ***
      id: posicao,
      idTabela: null,
      name: this.textTitulo,
      final: true,
      aprovador: null,
      aprovadorNome: null,
      subitems: [],
      subitemsArquivados: [],
      changeTimestamp: new Date()
    };

    this.listas.push(novo);
    document.getElementById('novaLista').style.display = 'inline-block';
    document.getElementById('addNovaLista').style.display = 'none';
    this.textTitulo = '';

    // Atualiza o banco;
    // ------------------------------------------
    const todoPainel = new TodoPainel();
    todoPainel.posicao = novo.id;
    todoPainel.titulo = novo.name;
    todoPainel.todoProjeto = new TodoProjeto();
    todoPainel.todoProjeto.id = this.todoProjetoId;
    todoPainel.usuario = new AuthUser();
    todoPainel.usuario.id = this.sessionService.loggedUser.id;
    todoPainel.painelFinal = true;
    todoPainel.arquivado = false;

    this.httpService.postdb('/todopainel', todoPainel).subscribe(
      rst => {
        this.setIndexCurrentPainel(novo.id);
        this.indexCurrentPainel.idTabela = rst.id;
        this.updateIndexPaineis();
        //this.listasMov = true;
        // this.startReload();
      },
      error => this.httpService.handleError(error, () => this.adicionarLista()),
      () => this.httpService.done()
    );
  }

  /** Bootstrap Modal 0- inicio **/
  openModal(component, flagId: any, textCartao: string, lista: string, subitem: any = null) {

    this.buscarFinanceiro = false;
    this.buscarPedido = false;

    this.httpService.wait();
    this.httpService
      .post(
        '/custom/workflow/get-listas-card', {
        cardId: flagId,
        projetoId: this.todoProjetoId
      }
      )
      .subscribe(
        rst => {
          this.categoriaId = null;
          this.categoriaNome = '';

          this.lastDropItems = [];
          this.listasMov = true;
          this.startReload();

          this.setIndexCurrentPainel(lista);
          this.setIndexCurrentCard(lista, flagId);
          this.fillSugestoes(this.indexCurrentCard.participantes, 'participantes');

          // alterar user requisitante e responsável
          this.comboUsuarios();
          this.todoCard = new TodoCard();
          this.todoCard.usuario = new AuthUser();
          this.todoCard.usuario.id = null;
          this.todoCard.responsavel = new AuthUser();
          this.todoCard.responsavel.id = null;
          this.todoCard.responsavelSub = new AuthUser();
          this.todoCard.responsavelSub.id = null;

          this.todoCard.prioridade = new TodoPrioridade();
          this.todoCard.prioridade.id = null;

          this.todoCard.rms = new Requisicao();
          if (rst[0].rms.id > 0) {
            this.todoCard.rms.id = rst[0].rms.id;
            this.todoCard.rms.infos = rst[0].rms;
          } else {
            this.todoCard.rms.id = 0;
          }

          this.todoCard.pedido = new Pedido();
          if (rst[0].pedido.id > 0) {
            this.todoCard.pedido.id = rst[0].pedido.id;
            this.todoCard.pedido = rst[0].pedido;
          } else {
            this.todoCard.pedido.id = 0;
          }

          this.todoCard.nfeFin = new NfeFin();
          if (rst[0].nfeFin.id > 0) {
            this.todoCard.nfeFin.id = rst[0].nfeFin.id;
            this.todoCard.nfeFin.infos = rst[0].nfeFin;
          } else {
            this.todoCard.nfeFin.id = 0;
          }

          if (rst.comentarios) {
            if (rst[0].comentarios === undefined) {
              this.indexCurrentCard.actions = [];
            } else {
              this.indexCurrentCard.actions = rst[0].actions;
            }
          }

          if (rst[0].aprovs === undefined) {
            this.indexCurrentCard.aprovs = [];
          } else {
            this.indexCurrentCard.aprovs = rst[0].aprovs;
          }

          if (rst[0].comentarios === undefined) {
            this.indexCurrentCard.comentarios = [];
          } else {
            this.indexCurrentCard.comentarios = rst[0].comentarios;
          }

          if (rst[0].flags === undefined) {
            this.indexCurrentCard.flags = [];
          } else {
            this.indexCurrentCard.flags = rst[0].flags;
          }

          if (rst[0].actions === undefined) {
            this.indexCurrentCard.actions = [];
          } else {
            this.indexCurrentCard.actions = rst[0].actions;
          }

          // console.log('\n--> ' + JSON.stringify(this.indexCurrentCard));
          this.textTitulo = this.indexCurrentCard.id;
          this.textCartao = this.indexCurrentCard.name;
          this.textDescricao = rst[0].descricao;

          // inicializando listas
          this.card.checklist = new Array();
          // ocultando ações;
          this.showActions = false;
          this.showAprovs = false;
          this.showComentarios = false;

          // timer de verificação se o click no card foi pra assinar ou visualizar: assinarCard();
          setTimeout(() => {
            if (!this.indexCurrentCard.assinado) {
              this.hideMessage();
              this.modalRef = this.modalService.open(component, {
                windowClass: 'xlModal',
                size: 'lg'
              });
            }
          }, 300);

        },
        error =>
          this.httpService.handleError(error, () => this.openModal(component, flagId, textCartao, lista, subitem)),
        () => this.httpService.done()
      );

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.getListas('ctrl');
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.getListas('ctrl');
      return 'by clicking on a backdrop';
    } else {
      this.getListas('ctrl');
      return `with: ${reason}`;
    }
  }

  onClosed() {
    this.modalRef.close();
    this.textTitulo = '';
    this.textCartao = '';
    this.getListas('ctrl');
  }

  textAreaOne(str: boolean): void {
    this.nomeIni = this.indexCurrentCard.name;
    if (str) {
      document.getElementById('divTextAreaOne').style.display = 'none';
      document.getElementById('textAreaOne').style.display = 'inline';
    } else {
      document.getElementById('textAreaOne').style.display = 'none';
      document.getElementById('divTextAreaOne').style.display = 'inline-block';
    }
  }

  textAreaOneSave() {
    document.getElementById('textAreaOne').style.display = 'none'; // gilson;
    document.getElementById('divTextAreaOne').style.display = 'inline-block'; // gilson;
    this.updateCard();

    this.indexCurrentCard.name = this.textCartao;

    this.insertAtividade({
      idCard: this.indexCurrentCard.idTabela,
      idUser: this.sessionService.loggedUser.id,
      acao: 'MENSAGEM_WF_EDIT_TITULO',
      nomeIni: this.nomeIni,
      nomeFin: this.textCartao
    });
  }

  textAreaTwo(str: boolean): void {
    this.nomeIni = this.indexCurrentCard.descricao;
    if (str) {
      document.getElementById('divTextAreaTwo').style.display = 'none';
      document.getElementById('textAreaTwo').style.display = 'inline';
      if (isPlatformBrowser(this.platformId)) {
        // this._descricaoCartao.nativeElement.focus();
      }
    } else {
      document.getElementById('textAreaTwo').style.display = 'none';
      document.getElementById('divTextAreaTwo').style.display = 'inline-block';
    }
  }

  textAreaTwoSave() {
    document.getElementById('textAreaTwo').style.display = 'none';
    document.getElementById('divTextAreaTwo').style.display = 'inline-block';
    this.updateCard();

    this.insertAtividade({
      idCard: this.indexCurrentCard.idTabela,
      idUser: this.sessionService.loggedUser.id,
      acao: 'MENSAGEM_WF_EDIT_DESCRICAO',
      nomeIni: this.nomeIni,
      nomeFin: this.textDescricao
    });
    // fazer o uploada da imagem aqui
    // this._descricaoCartao.nativeElement.focus();
    // console.log();
  }

  textAreaThree(str: boolean): void {
    if (str) {
      document.getElementById('divTextAreaThree').style.display = 'none';
      document.getElementById('textAreaThree').style.display = 'inline';
      /** construir froola */
      // this.initControlsFroala.getEditor()('html.set', '');
      // this.initControlsFroala.initialize();
      this.fillSugestoes(this.indexCurrentCard.participantes, 'participantes');
      /** final construir. */
    } else {
      document.getElementById('textAreaThree').style.display = 'none';
      document.getElementById('divTextAreaThree').style.display = 'inline-block';
      /** destruir froola */
      // this.initControlsFroala.getEditor()('html.set', '');
      this.initControlsFroala.destroy();
      /** final destruir. */
    }
  }


  textAreaThreeEdit(str: boolean, comentario: any): void {
    if (str) {
      document.getElementById('divTextAreaThree').style.display = 'none';
      document.getElementById('textAreaThree').style.display = 'inline';
      /** construir froola */
      // this.initControlsFroala.getEditor()('html.set', '');
      // this.initControlsFroala.initialize();
      this.fillSugestoes(this.indexCurrentCard.participantes, 'participantes');
      /** final construir. */
      // setando a mensagem original
      this.textComentario = comentario.mensagem;
      this.nomeIni = comentario.mensagem;
      // criando o modelo
      this.todoCardComentario = new TodoCardComentarios();
      this.todoCardComentario.id = comentario.id;
      this.todoCardComentario.mensagem = comentario.mensagem;
      this.todoCardComentario.usuario = new AuthUser();
      this.todoCardComentario.usuario.id = comentario.userId;
      this.todoCardComentario.todoCard = new TodoCard();
      this.todoCardComentario.todoCard.id = this.indexCurrentCard.idTabela;
    } else {
      document.getElementById('textAreaThree').style.display = 'none';
      document.getElementById('divTextAreaThree').style.display = 'inline-block';
      /** destruir froola */
      // this.initControlsFroala.getEditor()('html.set', '');
      // this.initControlsFroala.destroy();
      /** final destruir. */
    }
  }

  textAreaThreeSave() {

    // this.textComentario =  this.initControlsFroala.getEditor()('html.set', '');

    if (this.textComentario === '') { // se texto vazio!
      document.getElementById('textAreaThree').style.display = 'none';
      document.getElementById('divTextAreaThree').style.display = 'inline-block';
      /** destruir froola */
      // this.initControlsFroala.getEditor()('html.set', '');
      // this.initControlsFroala.destroy();
      /** final destruir. */
      return
    }
    if (this.todoCardComentario.id === undefined) { // salvar
      document.getElementById('textAreaThree').style.display = 'none';
      document.getElementById('divTextAreaThree').style.display = 'inline-block';

      /** destruir froola */
      // this.initControlsFroala.getEditor()('html.set', '');
      // this.initControlsFroala.destroy();
      /** final destruir. */

      this.insertComentario();

    } else { // alterar

      document.getElementById('textAreaThree').style.display = 'none';
      document.getElementById('divTextAreaThree').style.display = 'inline-block';

      /** destruir froola */
      // this.initControlsFroala.getEditor()('html.set', '');
      // this.initControlsFroala.destroy();

      this.updateComentario();
    }
  }

  /** generic show hiden component */
  componentShowHide(component: string, div: string) {
    const x = document.getElementById(component);
    const y = document.getElementById(div);
    if (x.style.display === 'none') {
      x.style.display = 'block';
      y.style.display = 'none';
      if (isPlatformBrowser(this.platformId)) {
        // this.addChkLstAtiv.nativeElement.focus();
      }
      x.focus();
    } else {
      x.style.display = 'none';
      y.style.display = 'block';
    }
  }

  // ocultando textarea ativos
  hiddenOther(currentId, pos) {
    let i = 0;
    this.indexCurrentCard.checkLists.map(cklst => {
      if (currentId !== cklst.id) {
        document.getElementById(
          'divTextAreaCheckList_' + cklst.id
        ).style.display = 'block';
        document.getElementById('textAreaCheckList_' + cklst.id).style.display =
          'none';
      }
      /** relaçao de textArea dos checklists ativo ou não*/
      this.displayTexAtiv[i] = i !== pos ? true : false;
      i++;
    });
  }

  // auto resize textarea
  textAreaAdjust(component: string) {
    const o = document.getElementById(component);
    o.style.height = '1px';
    o.style.height =
      o.scrollHeight > o.clientHeight ? 25 + o.scrollHeight + 'px' : '60px';
  }

  // auto resize textarea
  textAreaAtivAdjust(component: string) {
    const o = document.getElementById(component);
    o.style.height = '1px';
    o.style.height =
      o.scrollHeight > o.clientHeight ? 5 + o.scrollHeight + 'px' : '60px';
  }

  openModalRequisitante(content) {

    this.displayModalReq = 'block';
    this.todoCard = new TodoCard();
    this.todoCard.id = this.indexCurrentCard.id;
    // requisitante
    this.todoCard.usuario = new AuthUser();
    this.todoCard.usuario.id = this.indexCurrentCard.requisitanteId;
    this.todoCard.usuario.fullName = this.indexCurrentCard.requisitante;
    this.todoCard.responsavel = new AuthUser();
    this.todoCard.responsavel.id = null;
    this.todoCard.responsavelSub = new AuthUser();
    this.todoCard.responsavelSub.id = null;
    this.todoCard.prioridade = new TodoPrioridade();
    this.todoCard.prioridade.id = null;
    this.todoCard.rms = new Requisicao();
    this.todoCard.pedido = new Pedido();
    this.todoCard.nfeFin = new NfeFin();
  }

  openModalResponsavel(content) {
    this.displayModalResp = 'block';
    this.todoCard = new TodoCard();
    this.todoCard.id = this.indexCurrentCard.id;
    // responsavel
    this.todoCard.usuario = new AuthUser();
    this.todoCard.usuario.id = null;
    this.todoCard.responsavel = new AuthUser();
    this.todoCard.responsavel.id = this.indexCurrentCard.responsavelId;
    this.todoCard.responsavelSub = new AuthUser();
    this.todoCard.responsavelSub.id = this.indexCurrentCard.responsavelSub;
    this.todoCard.prioridade = new TodoPrioridade();
    this.todoCard.prioridade.id = null;
    this.todoCard.rms = new Requisicao();
    this.todoCard.pedido = new Pedido();
    this.todoCard.nfeFin = new NfeFin();
  }

  alterarRequisitante() {
    console.log("mudando requisitante")
    const map = {
      id: this.indexCurrentCard.idTabela,
      requisitante: this.todoCard.usuario.id,
      campo: 'requisitante'
    };

    this.displayModalReq = 'none';

    this.httpService.post('/custom/workflow/update-card-key', map).subscribe(
      rst => {
        /*this.messageService.add({
          severity: 'sucess',
          summary: 'MSG: ',
          detail: 'Requisitante alterado com sucesso!'
        }];*/

        this.messageService.add({
          severity: 'success',
          summary: 'MSG: ',
          detail: 'Requisitante alterado com sucesso!',
          key: 'messageLinnks',
          life: 5000
        });


        this.indexCurrentCard.requisitanteId = this.todoCard.usuario.id;

        this.insertAtividade({
          idCard: this.indexCurrentCard.idTabela,
          idUser: this.sessionService.loggedUser.id,
          acao: 'MENSAGEM_WF_EDIT_REQUISITANTE',
          requisitanteId: map.requisitante
        });
      },
      error =>
        this.httpService.handleError(error, () => this.alterarRequisitante()),
      () => this.httpService.done()
    );
  }

  alterarResponsavel() {
    const map = {
      id: this.indexCurrentCard.idTabela,
      responsavel: this.todoCard.responsavel.id,
      responsavelSub: this.todoCard.responsavelSub.id,
      campo: 'responsavel'
    };

    this.displayModalResp = 'none';

    this.httpService.post('/custom/workflow/update-card-key', map).subscribe(
      rst => {
        /*this.messageService.add({
          severity: 'sucess',
          summary: 'MSG: ',
          detail: 'Responsável alterado com sucesso!'
        }];*/

        this.indexCurrentCard.responsavelId = this.todoCard.responsavel.id;
        this.indexCurrentCard.responsavelSub = this.todoCard.responsavelSub.id;


        this.insertAtividade({
          idCard: this.indexCurrentCard.idTabela,
          idUser: this.sessionService.loggedUser.id,
          acao: 'MENSAGEM_WF_EDIT_RESPONSAVEL',
          responsavelId: map.responsavel
        });

        this.enviarEmailResponsavel();
      },
      error =>
        this.httpService.handleError(error, () => this.alterarResponsavel()),
      () => this.httpService.done()
    );
  }

  private comboUsuarios() {

    let participantesFiltrados = this.participantesProjeto.filter((participante) => {
      return !this[JSON.stringify(participante)] && (this[JSON.stringify(participante)] = true);
    });

    this.participantesProjeto = participantesFiltrados;

    participantesFiltrados.map(participante => {
      this.usuariosList.push({
        label: participante.usuario.fullName,
        value: participante.usuario.id
      })
    })
  }

  openModalCheckBoxImpressao() {
    this.stopGetListas();
    this.displayCheckBoxImpressao = 'block';
  }

  /** Bootstrap Modal 1- inicio - new CheckList **/
  openModalCl(content) {
    this.stopGetListas();

    this.checkList = new TodoCardChecklist();
    this.displayCLModal = 'block';
    this.checkList.titulo = 'Checklist';
    // ocultando os campos texto
    /*this.indexCurrentCard.checkLists.forEach(cklst => {
      document.getElementById('divTextAreaCheckList_' + cklst.id).style.display = 'block';
      document.getElementById('textAreaCheckList_' + cklst.id).style.display = 'none';
    });*/
    /** relaçao de textArea dos checklists ativo ou não*/
    let i = 0;
    this.indexCurrentCard.checkLists.forEach(l => {
      this.displayTexAtiv[i] = true;
      i++;
    });
  }

  /*openModalFiltroAvancado(){
    this.displayFiltroAvancado = this.displayFiltroAvancado == 'none' ? 'block' : 'none';
  } */

  onCloseHandled() {
    this.displayCheckBoxImpressao = 'none';
    this.displayCLModal = 'none';
    this.modalFiltrar = false;
    this.filtro.requisitantes = [];
    this.filtro.prioridades = [];
    this.filtro.responsaveis = [];
    this.filtro.marcadores = [];
    this.filtro.contem = String;
    this.filtroVazio = true;
    this.displayFiltroAvancado = 'none';
    this.displayDefinirAprovadorModal = 'none';
    this.displayDefinirAprovadorModalCard = 'none';
    this.displayEtiqModal = 'none';
    this.displayMoverCardsModal = 'none';
    this.displaySeguirCardModal = 'none';
    //this.displayAnexoModal = 'none';
    this.displayCategoriaAnexo = 'none';
    this.enableInput = false;
    this.categoriaId = null;
    this.displayMoverCardsList = 'none';
    this.displayModalResp = 'none';
    this.displayModalReq = 'none';
    this.displayPartModal = 'none';
    this.displayPrazoModal = 'none';
    this.displayPrioridadeModal = 'none';
    this.displayUnarchiveList = 'none';
    this.displayStatusModal = 'none';

    this.modalConvidarParticipante = false;
    this.mcpInput = '';
    this.mcpEmail = [];
    this.mcpEmailList = [];

    this.modalDesarquivar = false;

    this.modalEditarProjeto = false;

    this.modalEtiquetaProjeto = false;
    this.modalUsuarioSubstituto = false;

    this.modalCategoriasAnexo = false;
    this.categoriaNome = '';

    this.modalAlcadas = false;
    this.usuarioSubstituto = 0;
    this.startGetListas();
  }

  // crando o cheklista
  onSaveChklst() {
    this.displayCLModal = 'none';

    this.checkList.id = null;
    this.checkList.titulo = this.checkList.titulo;
    this.checkList.todoCard = new TodoCard();
    this.checkList.todoCard.id = this.indexCurrentCard.idTabela;
    this.checkList.usuario = new AuthUser();
    this.checkList.usuario.id = this.sessionService.loggedUser.id;

    this.card.checklist.push(this.checkList);

    this.checklistAtividade = new TodoCardChecklistAtividades();
    this.checkList.checklistAtividades = new Array();

    // increment id
    this.chkLstCount++;

    // Atualiza a lista local;
    // ------------------------------------------
    this.indexCurrentCard.checkLists.push(this.checkList);

    this.insertAtividade({
      idCard: this.indexCurrentCard.idTabela,
      idUser: this.sessionService.loggedUser.id,
      acao: 'MENSAGEM_WF_CREATE_CHECKLIST',
      checklistName: this.checkList.titulo
    });

    // Atualiza o banco;
    // ------------------------------------------
    this.httpService.postdb('/todocardchecklist', this.checkList).subscribe(
      rst => {
        try {
          this.indexCurrentChecklist = this.indexCurrentCard.checkLists.find(
            f => f.id === null
          );
          this.indexCurrentChecklist.id = rst.id;
          // console.log('\n--> id recuperado !' + rst.id + '  | => ');
        } catch (error) {
          console.log('\n--> error: ' + error);
        }

        this.startGetListas();
      },
      error => this.httpService.handleError(error, () => this.onSaveChklst()),
      () => this.httpService.done()
    );
  }

  onFocusInEditable() {
    this.stopGetListas();
  }

  onEditChklst(event: any) {
    this.indexCurrentChecklist = this.indexCurrentCard.checkLists.find(
      f => f.id === event
    );
    this.nomeIni = this.indexCurrentChecklist.titulo;

    // Atualiza a lista local;
    // ------------------------------------------
    this.indexCurrentChecklist.titulo = document.getElementById(
      'checkList_' + event
    ).innerHTML;

    this.insertAtividade({
      idCard: this.indexCurrentCard.idTabela,
      idUser: this.sessionService.loggedUser.id,
      acao: 'MENSAGEM_WF_EDIT_CHECKLIST',
      checklistName: this.indexCurrentChecklist.titulo.trim(),
      nomeIni: this.nomeIni,
      nomeFin: this.indexCurrentChecklist.titulo
    });

    // Atualiza o banco;
    // ------------------------------------------
    const map = { id: event, titulo: this.indexCurrentChecklist.titulo.trim() };
    this.httpService.post('/custom/workflow/update-checklist', map).subscribe(
      rst => {
      },
      error =>
        this.httpService.handleError(error, () => this.onEditChklst(event)),
      () => this.httpService.done()
    );

    this.textChecklist = '';
  }

  newAtividade() {
    this.checklistAtividade = new TodoCardChecklistAtividades();
  }

  checkListItemSave(event: any) {
    this.stopGetListas();

    this.indexCurrentChecklist = this.indexCurrentCard.checkLists.find(
      f => String(f.id) === String(event.id)
    );
    // recebendo todos os campos do checklist selecionado
    this.checkList = new TodoCardChecklist(event);

    // alterando ou criando novo checklist
    if (this.checklistAtividade.id !== undefined) {
      // Atualiza o banco;
      // ------------------------------------------
      const id = this.checklistAtividade.todoCardChecklist.toString();
      this.checklistAtividade.usuario = new AuthUser();
      this.checklistAtividade.usuario.id = this.sessionService.loggedUser.id;
      this.checklistAtividade.todoCardChecklist = new TodoCardChecklist();
      this.checklistAtividade.todoCardChecklist.id = parseInt(id, 10);

      const index = this.indexCurrentChecklist.checklistAtividades !== undefined ? this.indexCurrentChecklist.checklistAtividades.findIndex(
        i => i.id === this.checklistAtividade.id
      ) : null;

      this.indexCurrentChecklist.checklistAtividades.splice(
        index,
        1,
        this.checklistAtividade
      );

      // hide text area;
      this.componentShowHide(
        'atividadeText_' + this.checklistAtividade.id,
        'atividade_' + this.checklistAtividade.id
      );

      this.insertAtividade({
        idCard: this.indexCurrentCard.idTabela,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_EDIT_CHECKLIST_ATIVIDADE',
        checklistName: this.indexCurrentChecklist.titulo,
        checklistAtivName: this.checklistAtividade.descricao,
        nomeIni: this.nomeIni,
        nomeFin: this.checklistAtividade.descricao
      });

      this.httpService
        .post('/custom/workflow/update-desc-atividades', {
          ativId: this.checklistAtividade.id,
          ativDescricao: this.checklistAtividade.descricao
        })
        .subscribe(
          () => {
            this.checklistAtividade = new TodoCardChecklistAtividades();

          },
          error =>
            this.httpService.handleError(error, () =>
              this.checkListItemSave(event)
            ),
          () => this.httpService.done()
        );
    } else {
      // nova atividade
      this.checklistAtividade.id = null;
      this.checklistAtividade.concluida = false;
      this.checklistAtividade.todoCardChecklist = new TodoCardChecklist();
      this.checklistAtividade.todoCardChecklist.id = this.indexCurrentChecklist.id;

      this.indexCurrentChecklist.checklistAtividades.push(
        this.checklistAtividade
      );

      this.insertAtividade({
        idCard: this.indexCurrentCard.idTabela,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_CREATE_CHECKLIST_ATIVIDADE',
        checklistName: this.indexCurrentChecklist.titulo,
        checklistAtivName: this.checklistAtividade.descricao
      });

      this.indexCurrentCard.qtdAtividades++;
      this.indexCurrentChecklist.porcentagem = this.checkList.checklistAtividades.length > 0 ? this.checkList.checklistAtividades.length : 0;
      // contador de evolução das atividades
      this.contadorconcluidas();

      // Atualiza o banco;
      // ------------------------------------------
      this.checklistAtividade.usuario = new AuthUser();
      this.checklistAtividade.usuario.id = this.sessionService.loggedUser.id;
      this.checklistAtividade.posicao = this.checkList.checklistAtividades.length > 0 ? this.checkList.checklistAtividades.length - 1 : 0;

      this.httpService
        .postdb('/todocardchecklistatividades', this.checklistAtividade)
        .subscribe(
          rst => {
            const ativIndex = this.indexCurrentChecklist.checklistAtividades !== undefined ? this.indexCurrentChecklist.checklistAtividades.findIndex(
              f => f.id === null
            ) : null;

            const ativ = this.indexCurrentChecklist.checklistAtividades.find(
              f => f.id === null
            );
            ativ.id = rst.id;
            ativ.todoCardChecklist = this.indexCurrentChecklist.id; // fix
            this.indexCurrentChecklist.checklistAtividades.splice(
              ativIndex,
              1,
              ativ
            );
            // console.log('\n--> id recuperado !');

          },
          error =>
            this.httpService.handleError(error, () => this.onSaveChklst()),
          () => this.httpService.done()
        );

      // contador de itens e zerando a ativdade
      this.chkLstItCount++;
      this.checklistAtividade = new TodoCardChecklistAtividades();
    }
  }

  /**
   * Excluir o checkList
   * @param event
   */
  checkListDelete(event: any) {
    if (confirm('Deseja excluir este CheckList?')) {

      // Atualiza a lista local;
      // ----------------------------------------
      this.insertAtividade({
        idCard: this.indexCurrentCard.idTabela,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_DELETE_CHECKLIST',
        checklistName: this.indexCurrentCard.checkLists.find(
          c => c.id === event.id
        ).titulo
      });

      const index = this.indexCurrentCard.checkLists.findIndex(
        c => c.id === event.id
      );
      this.indexCurrentCard.checkLists.splice(index, 1);

      // Atualiza o banco;
      // ----------------------------------------
      this.httpService
        .post('/custom/workflow/delete-checklist', { id: event.id })
        .subscribe(
          rst => '',
          error =>
            this.httpService.handleError(error, () =>
              this.checkListDelete(event)
            ),
          () => this.httpService.done()
        );
    }
  }

  /**
   * excluir uma atividade do checklist
   */
  checkListItemDelete(event: any) {
    if (confirm('Deseja excluir esta Atividade?')) {
      this.stopGetListas();

      this.indexCurrentChecklist = this.indexCurrentCard.checkLists.find(
        f => f.id.toString() === event.todoCardChecklist.toString()
      );

      // Atualiza a lista local;
      // ----------------------------------------
      this.insertAtividade({
        idCard: this.indexCurrentCard.idTabela,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_DELETE_CHECKLIST_ATIVIDADE',
        checklistName: this.indexCurrentChecklist.titulo,
        checklistAtivName: this.indexCurrentChecklist.checklistAtividades.find(
          f => f.id === event.id
        ).descricao
      });

      const ativ = this.indexCurrentChecklist.checklistAtividades.findIndex(
        f => f.id === event.id
      );
      this.indexCurrentChecklist.checklistAtividades.splice(ativ, 1);

      // atualizando os contadores
      this.indexCurrentCard.qtdAtividades--;
      if (ativ.concluida === true) {
        this.indexCurrentCard.checkListsConcluidos--;
      }
      this.contadorconcluidas();

      // Atualiza o banco;
      // ----------------------------------------
      this.httpService
        .post('/custom/workflow/delete-checklist-atividade', { id: event.id })
        .subscribe(
          rst => '',
          error =>
            this.httpService.handleError(error, () =>
              this.checkListItemDelete(event)
            ),
          () => this.httpService.done()
        );
    }
  }

  /**
   * finalizar atividades
   * @param event
   */
  checkListItemComplete(event: any) {
    this.stopGetListas();

    setTimeout(() => {
      let idAtividadde = '';
      if (event.todoCardChecklist.id === undefined) {
        idAtividadde = event.todoCardChecklist.toString();
      } else {
        idAtividadde = event.todoCardChecklist.id.toString();
      }
      this.indexCurrentChecklist = this.indexCurrentCard.checkLists.find(
        f => f.id.toString() === idAtividadde.toString()
      );

      // removendo finalizadas
      this.indexCurrentChecklist.checklistAtividades.forEach(fin => {
        if (fin.concluida) {
          this.indexCurrentCard.checkListsConcluidos--;
        }
      });

      // alterando o item local;
      const ativ = this.indexCurrentChecklist.checklistAtividades.find(
        f => f.id === event.id
      );
      ativ.concluida = !event.concluida;
      const i = this.indexCurrentChecklist.checklistAtividades.findIndex(
        f => f.id === event.id
      );
      this.indexCurrentChecklist.checklistAtividades.splice(i, 1, ativ);

      // adicionado finalizadas
      this.indexCurrentChecklist.checklistAtividades.forEach(fin => {
        if (fin.concluida) {
          this.indexCurrentCard.checkListsConcluidos++;
        }
      });
      const idx = this.indexCurrentCard.checkLists.findIndex(
        f => f.id.toString() === event.todoCardChecklist.toString()
      );
      this.indexCurrentCard.checkLists.splice(
        idx,
        1,
        this.indexCurrentChecklist
      );

      this.contadorconcluidas();

      this.insertAtividade({
        idCard: this.indexCurrentCard.idTabela,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_CHECK_CHECKLIST_ATIVIDADE',
        checklistName: this.indexCurrentChecklist.titulo,
        checklistAtivName: ativ.descricao,
        checklistAtivCheck: ativ.concluida ? 'concluída' : 'não concluída'
      });

      // Atualiza o banco;
      // ------------------------------------------
      this.httpService
        .post('/custom/workflow/update-checklist-atividade', { id: event.id })
        .subscribe(
          rst => {
            setTimeout(() => { this.startGetListas(); }, 1000);
          },
          error =>
            this.httpService.handleError(error, () =>
              this.checkListItemComplete(event)
            ),
          () => this.httpService.done()
        );
    }, 500);
  }

  /**
   * alterar ordem das atividades
   */
  moveAtiv(targetModel: any) {
    // Atividades ordem;
    const map = [];
    let count = 0;
    // const idChekList = targetModel[0].todoCardChecklist.id;
    targetModel.forEach(ativ => {
      map.push({
        ativId: ativ.id,
        posicao: count
      });
      count++;
    });

    if (count > 0) {
      this.httpService
        .post('/custom/workflow/update-pos-atividades', map)
        .subscribe(
          rst => {
            this.msgSocket(this.todoProjetoId);
            // Erro api: could not serialize access due to read/write dependencies among transactions;
            if (rst.response === 'refactory') {
              this.moveAtiv(targetModel);
            }
          },
          error =>
            this.httpService.handleError(error, () =>
              this.moveAtiv(targetModel)
            ),
          () => this.httpService.done()
        );
    }
  }

  /**
   * contar itens concluídos
   */
  contadorconcluidas() {
    // Atividades concluídas;
    // ------------------------------------------
    let countAtiv = 0;
    this.indexCurrentChecklist.checklistAtividades.forEach(ativ => {
      if (ativ.concluida) {
        countAtiv++;
      }
    });
    if (countAtiv > 0) {
      this.indexCurrentChecklist.porcentagem = Math.trunc(
        (countAtiv * 100) /
        this.indexCurrentChecklist.checklistAtividades.length
      );
    } else {
      this.indexCurrentChecklist.porcentagem = 0;
    }
    // Checklists concluídos;
    // ------------------------------------------
    /*let countConcluido = 0;
    this.indexCurrentCard.checkLists.forEach(checklist => {
      if (checklist.porcentagem === 100) {
        countConcluido++;
      }
    });*/
  }

  /**
   * alterar uma atividade do checklist
   */
  checkListItemEdit(event: any) {
    this.stopGetListas();

    this.nomeIni = event.descricao;
    // ocultando todos textAreas dos CheckLists
    this.indexCurrentCard.checkLists.forEach(cklst => {
      document.getElementById(
        'divTextAreaCheckList_' + cklst.id
      ).style.display = 'block';
      document.getElementById('textAreaCheckList_' + cklst.id).style.display =
        'none';
      // ocultando todos campos texto das atividades
      cklst.checklistAtividades.forEach(ativ => {
        document.getElementById('atividade_' + ativ.id).style.display = 'block';
        document.getElementById('atividadeText_' + ativ.id).style.display =
          'none';
      });
    });

    this.checklistAtividade = new TodoCardChecklistAtividades(event);
    if (event.todoCardChecklist.id === undefined) {
      this.checklistAtividade.todoCardChecklist = event.todoCardChecklist.toString();
    } else {
      this.checklistAtividade.todoCardChecklist = new TodoCardChecklist();
      this.checklistAtividade.todoCardChecklist.id = event.todoCardChecklist.id.toString();
    }
    this.componentShowHide(
      'atividadeText_' + event.id,
      'atividade_' + event.id
    );
  }

  /** modal CheckList */

  /** anexos */
  anexoExcluir(event: any) {
    if (confirm('Deseja remover este anexo?')) {
      this.stopGetListas();
      // Atualiza a lista local;
      // ------------------------------------------
      const anexo = this.indexCurrentCard.anexos.findIndex(
        f => f.nome === event.nome
      );

      this.indexCurrentCard.anexos.splice(anexo, 1);

      this.insertAtividade({
        idCard: this.indexCurrentCard.idTabela,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_DELETE_ANEXO',
        anexo: event.nome,
        anexoCategoria: event.categoria.split(' - ')[1]
      });

      // Deleta o arquivo da pasta do projeto/card;
      // ------------------------------------------
      this.httpService
        .post('/custom/workflow/delete-anexo-card', {
          projetoId: this.todoProjetoId,
          cardId: this.indexCurrentCard.idTabela,
          anexoNome: event.nome,
          anexoCategoria: event.categoria
        })
        .subscribe(
          rst => {
            this.startGetListas();
            /** destruir froola */
            document.getElementById('textAreaThree').style.display = 'none';
            document.getElementById('divTextAreaThree').style.display = 'inline-block';
            // this.initControlsFroala.getEditor()('html.set', '');
            // this.initControlsFroala.destroy();
            /** final destruir. */
            this.fillSugestoes(this.indexCurrentCard.participantes, 'participantes');
          },
          error =>
            this.httpService.handleError(error, () => this.anexoExcluir(event)),
          () => this.httpService.done()
        );
    }
  }

  anexoBaixar(event: any) {
    window.open(
      this.httpService.url(
        '/custom/file/' +
        this.todoProjetoId +
        '/' +
        this.indexCurrentCard.idTabela +
        '/' +
        event.categoria +
        '/' +
        event.nome +
        '/workflow'
      ),
      '_blank'
    );
  }
  // =====

  anexoChange(event) {
    this.file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    this.stopGetListas();

    // Atualiza a lista local;
    // ------------------------------------------
    this.indexCurrentCard.anexos.push({ nome: this.file.name, categoria: this.getCategoriaSelected() });

    this.insertAtividade({
      idCard: this.indexCurrentCard.idTabela,
      idUser: this.sessionService.loggedUser.id,
      acao: 'MENSAGEM_WF_INSERT_ANEXO',
      anexo: this.file.name,
      anexoCategoria: this.getCategoriaSelected().split(' - ')[1]
    });

    // Envia o anexo para o dir default do tomcat;
    // ------------------------------------------
    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('type', 'workflow');
    data.append('projetoId', this.todoProjetoId);
    data.append('categoria', this.getCategoriaSelected());
    data.append('cardId', this.indexCurrentCard.idTabela);
    data.append('fileName', this.file.name);

    this.httpService.post('/custom/upload', data).subscribe(
      () => {
        /** destruir froola */
        document.getElementById('textAreaThree').style.display = 'none';
        document.getElementById('divTextAreaThree').style.display = 'inline-block';
        // this.initControlsFroala.getEditor()('html.set', '');
        // this.initControlsFroala.destroy();
        /** final destruir. */
        this.fillSugestoes(this.indexCurrentCard.participantes, 'participantes');
        this.startGetListas();

      },
      error =>
        this.httpService.handleError(error, () => this.anexoChange(event)),
      () => this.httpService.done()
    );

    this.onCloseHandled();
  }
  /** anexos - final */

  /** Bootstrap Modal 2- inicio - new etiquetas **/
  openModalEtiquetas(content) {
    this.displayEtiqModal = 'block';
    this.listaEtiquetasProjeto();
  }

  private listaEtiquetasProjeto() {
    this.etiquetas = [];
    this.httpService
      .get(
        '/todoetiquetas?$select=id,nome,corBg,descricao&$filter=todoProjeto/id eq ' +
        this.todoProjetoId
      )
      .subscribe(
        rst =>
          rst.value.forEach(obj => {
            const i = this.indexCurrentCard.flags.findIndex(
              f => f.id === obj.id
            );
            if (i === -1) {
              this.etiquetas.push(obj);
            }
          }),
        error =>
          this.httpService.handleError(error, () =>
            this.listaEtiquetasProjeto()
          ),
        () => this.httpService.done()
      );
  }

  setEtiquetaCard(etiqueta: TodoEtiquetas) {
    // inserindo relacionamento
    const etiquetasCardRelac = new TodoEtiquetasCardRelac();
    etiquetasCardRelac.todoCard = new TodoCard(this.indexCurrentCard);
    etiquetasCardRelac.todoCard.id = this.indexCurrentCard.idTabela;
    etiquetasCardRelac.todoEtiquetas = new TodoEtiquetas(etiqueta);

    this.httpService
      .post('/todoetiquetascardrelac', etiquetasCardRelac)
      .subscribe(
        rst => {
          this.indexCurrentCard.flags.push({
            id: etiqueta.id,
            nome: etiqueta.nome,
            corBg: etiqueta.corBg,
            etiquetaCardRelacId: rst.id
          });
          this.msgSocket(this.todoProjetoId);
          const eti = this.etiquetas.findIndex(e => e.id === etiqueta.id);
          // removendo da lista corrente
          this.etiquetas.splice(eti, 1);
        },
        error =>
          this.httpService.handleError(error, () =>
            this.setEtiquetaCard(etiqueta)
          ),
        () => this.httpService.done()
      );
  }

  removeEtiqueta(etiqueta) {
    if (confirm('Deseja remover esta etiqueta?')) {
      this.httpService
        .post(
          '/custom/workflow/remove-relac-card-flag',
          etiqueta.etiquetaCardRelacId
        )
        .subscribe(
          rst => {
            const flag = this.indexCurrentCard.flags.findIndex(
              f => f.id === etiqueta.id
            );
            // removendo da lista corrente
            this.indexCurrentCard.flags.splice(flag, 1);

            this.msgSocket(this.todoProjetoId);
          },
          error =>
            this.httpService.handleError(error, () =>
              this.removeEtiqueta(etiqueta)
            ),
          () => this.httpService.done()
        );
    }
  }

  /** Bootstrap Modal 2- final - new etiquetas **/

  /** Bootstrap Modal 3- inicio - Participante **/
  openModalParticipante(content) {
    this.displayPartModal = 'block';
    this.listaParticipanteProjeto();
  }

  private listaParticipanteProjeto() {
    this.participantes = [];

    console.log(this.participantesProjeto)

    this.participantesProjeto.forEach(obj => {
      // verifico se esta na lista
      const i =
        this.indexCurrentCard.participantes.length > 0
          ? this.indexCurrentCard.participantes.findIndex(
            f => f.id === obj.usuario.id
          )
          : -1;
      // verifico se é o responsável
      // const z = this.indexCurrentCard.responsavelId === obj.usuario.id ? 0 : -1;
      // if (i === -1 && z === -1) {
      if (i === -1) {
        this.participantes.push(obj);
      }
    });
  }

  setParticipanteCard(participante: TodoProjetoUsuarios) {
    setTimeout(() => {
      // inserindo relacionamento
      const partCardRelac = new TodoPartCardRelac();
      partCardRelac.todoCard = new TodoCard();
      partCardRelac.todoCard.id = this.indexCurrentCard.idTabela;
      partCardRelac.usuario = new AuthUser();
      partCardRelac.usuario.id = participante.usuario.id;

      const avatar = participante.usuario.fullName.split(' ');

      // const part = this.indexCurrentCard.participantes.findIndex(
      //   p => p === participante.usuario.id
      // );

      this.indexCurrentCard.participantes.forEach(element => {
        if (element.id === participante.usuario.id) {
          alert("sim");
          return;
        }
      });


      this.httpService.post('/todopartcardrelac', partCardRelac).subscribe(
        rst => {
          // this.msgSocket(this.todoProjetoId);

          this.indexCurrentCard.participantes.push({
            id: participante.usuario.id,
            responsavel: participante.usuario.fullName,
            avatar: avatar[0].charAt(0) + avatar[avatar.length - 1].charAt(0),
            partCardRelacId: rst.id
          });

          const part = this.participantes.findIndex(
            p => p.id === participante.id
          );
          // removendo da lista corrente
          this.participantes.splice(part, 1);

          /** destruir froola */
          document.getElementById('textAreaThree').style.display = 'none';
          document.getElementById('divTextAreaThree').style.display = 'inline-block';
          // this.initControlsFroala.getEditor()('html.set', '');
          // this.initControlsFroala.destroy();
          this.fillSugestoes(this.indexCurrentCard.participantes, 'participantes');
          /** final destruir. */

        },
        error =>
          this.httpService.handleError(error, () =>
            this.setParticipanteCard(participante)
          ),
        () => this.httpService.done()
      );
    }, 1000);
  }

  removeParticipante(participante) {
    if (confirm('Deseja remover este participante?')) {
      this.sub.unsubscribe();
      this.httpService
        .post(
          '/custom/workflow/remove-relac-card-part',
          participante.partCardRelacId
        )
        .subscribe(
          rst => {
            const part = this.indexCurrentCard.participantes.findIndex(
              p => p.id === participante.id
            );
            // removendo da lista corrente
            this.indexCurrentCard.participantes.splice(part, 1);

            if (this.indexCurrentCard.participantes.length === 0) {
              this.indexCurrentCard.participantes = [];
            }

            /** destruir froola */
            document.getElementById('textAreaThree').style.display = 'none';
            document.getElementById('divTextAreaThree').style.display = 'inline-block';
            // this.initControlsFroala.getEditor()('html.set', '');
            // this.initControlsFroala.destroy();
            this.fillSugestoes(this.indexCurrentCard.participantes, 'participantes');
            /** final destruir. */

            this.startReload();
          },
          error =>
            this.httpService.handleError(error, () =>
              this.removeParticipante(participante)
            ),
          () => this.httpService.done()
        );
    }
  }

  /** Bootstrap Modal 3- final - Participante **/

  /** Bootstrap Modal 4- inicio - Prazo **/
  openModalPrazo(content) {
    this.displayPrazoModal = 'block';
  }

  remDataFimCard() {
    if (confirm('Deseja remover esta data?')) {
      this.insertAtividade({
        idCard: this.indexCurrentCard.idTabela,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_DELETE_PRAZO'
      });

      this.setDataFimCard();
    }
  }

  setDataFimCard() {
    let date = String(this.newDataFim);
    date = date.substr(0, 15);

    let month = date.substr(3, 4);
    let day = date.substr(7, 3);
    let year = date.substr(10, 5);

    this.changeExtensiveDateFromNumber(day, month, year);

    let stringDate = null;
    if (this.dataFim !== null && this.dataFim !== undefined) {

      this.insertAtividade({
        idCard: this.indexCurrentCard.idTabela,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_CREATE_PRAZO',
        prazo:
          (this.dataFim.day.toString.length === 1 ? '0' + this.dataFim.day : this.dataFim.day) +
          '/' +

          (this.dataFim.month.toString.length === 1 ? '0' + this.dataFim.month : this.dataFim.month) +
          '/' +
          this.dataFim.year
      });

      stringDate =
        this.dataFim.year +
        '-' +
        this.dataFim.month +
        '-' +
        this.dataFim.day +
        'T23:59:59';
    }


    // Atualiza o banco;
    // ------------------------------------------
    const map = {
      id: this.indexCurrentCard.idTabela,
      dataFim: stringDate,
      campo: 'dataFim'
    };

    this.onCloseHandled();

    this.httpService.post('/custom/workflow/update-card-key', map).subscribe(
      rst => {

        this.indexCurrentCard.dataFim = null;
        if (this.dataFim !== null && this.dataFim !== undefined) {
          this.httpService
            .get(
              '/todocard?$select=id,dtFin&$filter=id eq ' +
              this.indexCurrentCard.idTabela
            )
            .subscribe(
              dt => {

                dt.value.forEach(val => {
                  const d = new Date(val.dtFin);
                  val.dtFin = this.utilService.dateWithoutTimeZone(
                    d.toDateString()
                  );
                  this.indexCurrentCard.dataFim = val.dtFin;
                });
              },
              error =>
                this.httpService.handleError(error, () =>
                  this.setDataFimCard()
                ),
              () => this.httpService.done()
            );
        }
        this.dataFim = null;
      },
      error => this.httpService.handleError(error, () => this.setDataFimCard()),
      () => this.httpService.done()
    );
  }

  changeExtensiveDateFromNumber(day, month, year) {
    let fakeDay = day;
    let fakeMonth = month;
    let fakeYear = year;

    if (fakeMonth == " Jan") {
      fakeMonth = "01"
    };

    if (fakeMonth == " Feb") {
      fakeMonth = "02"
    };

    if (fakeMonth == " Mar") {
      fakeMonth = "03"
    };

    if (fakeMonth == " Apr") {
      fakeMonth = "04"
    };

    if (fakeMonth == " May") {
      fakeMonth = "05"
    };

    if (fakeMonth == " Jun") {
      fakeMonth = "06"
    };

    if (fakeMonth == " Jul") {
      fakeMonth = "07"
    };

    if (fakeMonth == " Aug") {
      fakeMonth = "08"
    };

    if (fakeMonth == " Sep") {
      fakeMonth = "09"
    };

    if (fakeMonth == " Oct") {
      fakeMonth = "10"
    };

    if (fakeMonth == " Nov") {
      fakeMonth = "11"
    };

    if (fakeMonth == " Dec") {
      fakeMonth = "12"
    };

    let date = {
      day: fakeDay,
      month: fakeMonth,
      year: fakeYear
    };

    this.dataFim = date;

  };

  /** Bootstrap Modal 4- final - Prazo **/

  /** Bootstrap Modal 5 - Mover cards **/
  openModalMoverCards(event: any) {
    this.indexCurrentPainel = event;
    this.stopGetListas();

    this.listMoverCards = [];

    console.log(this.listas, "Listas")

    if (this.indexCurrentPainel.aprovador !== null) {
      this.listas.forEach(lista => {
        if (lista.id < this.indexCurrentPainel.id) {
          this.listMoverCards.push(lista);
        }
      });
    } else {
      let stopAdding1 = false;
      let stopAdding2 = false;
      let lastPosition = null;

      this.listas.forEach(lista => {
        console.log(this.listas, "Listas")

        // somente considerar as listas cuja posição é diferente da posição da lista que desencadeou a ação;
        if (lista.id !== this.indexCurrentPainel.id) {
          if (!stopAdding1) {
            // adiciona normalmente até a primeira lista com aprovação !
            this.listMoverCards.push(lista);
            if (lista.aprovador !== null) {
              lastPosition = lista.id;
              stopAdding1 = true;

              // se a posição da lista com aprovação for maior que a posição da lista que desencadeou a ação, encerra a validação;
              if (lastPosition > this.indexCurrentPainel.id) {
                stopAdding2 = true;
              }
            }

            // se a posição da lista com aprovação (se encontrada) for menor que a posição da lista que desencadeou a ação,
            // valida as listas à frente da lista que desencadeou a ação;
          } else if (!stopAdding2 && lista.id > this.indexCurrentPainel.id) {
            // adiciona normalmente até a primeira lista com aprovação !
            const index = this.listMoverCards.find(
              f => f.id.toString() === lista.id.toString()
            );
            if (index !== -1) {
              this.listMoverCards.push(lista);
            }
            if (lista.aprovador !== null) {
              stopAdding2 = true;
            }
          }
        }
      });
    }

    this.displayMoverCardsModal = 'block';
    this.displayMoverCardsList = 'block';
  }
  /** Bootstrap Modal 5 - final - Mover cards **/

  /** Bootstrap Modal 6 - Definir aprovador da lista **/
  openModalDefinirAprovador(event: any) {
    this.indexCurrentPainel = event;
    this.displayDefinirAprovadorModal = 'block';
  }
  /** Bootstrap Modal 6 - final - Definir aprovador da lista **/

  /** Bootstrap Modal 6.1 - Definir aprovador do card **/
  openModalDefinirAprovadorCard() {
    this.displayDefinirAprovadorModalCard = 'block';
  }
  /** Bootstrap Modal 6.1 - final - Definir aprovador do card **/

  /** Bootstrap Modal 7 - Seguir card **/
  openModalSeguirCard() {
    this.displaySeguirCardModal = 'block';
    this.loadListSeguindo();
  }
  /** Bootstrap Modal 7 - final - Seguir card **/

  /** Bootstrap Modal 9 - Status **/
  openModalStatus() {
    this.stopGetListas();
    this.displayStatusModal = 'block';
  }
  /** Bootstrap Modal 9 - final - Status **/

  hideMessage() {

  }

  cardClone() {
    // trava anti-luzlei
    if (confirm('Deseja clonar este cartão?')) {
      this.listasMov = false;
      this.sub.unsubscribe();

      this.httpService.wait();
      this.httpService
        .post('/custom/workflow/select-card-count', {
          todoProjetoId: this.todoProjetoId
        })
        .subscribe(
          rst0 => {
            const codigo = rst0.count + 1;

            const nome =
              this.dblinkedSessionService.usuario.nome +
              ' ' +
              this.dblinkedSessionService.usuario.sobrenome;
            const avatar = nome.split(' ');

            const novo = {
              // *** ESPELHAR KEYS NOS MÉTODOS:
              // getListas()
              // salvarCartao()
              id: this.projetoKey + codigo,
              idTabela: null,
              idPainel: this.indexCurrentPainel.id,
              name: this.indexCurrentCard.name,
              descricao: this.indexCurrentCard.descricao,
              posicao: this.indexCurrentPainel.subitems.length + 1,
              responsavelId: this.sessionService.loggedUser.id,
              responsavel:
                this.dblinkedSessionService.usuario.nome +
                ' ' +
                this.dblinkedSessionService.usuario.sobrenome,
              requisitanteId: this.sessionService.loggedUser.id,
              requisitante:
                this.dblinkedSessionService.usuario.nome +
                ' ' +
                this.dblinkedSessionService.usuario.sobrenome,
              avatar: avatar[0].charAt(0) + avatar[avatar.length - 1].charAt(0),
              dataInicio: this.indexCurrentCard.dataInicio,
              dataFim: this.indexCurrentCard.dataFim,
              flags: this.indexCurrentCard.flags,
              comentarios: [],
              checkLists: this.indexCurrentCard.checkLists,
              checkListsConcluidos: 0,
              qtdAtividades: this.indexCurrentCard.qtdAtividades,
              anexos: [],
              participantes: this.indexCurrentCard.participantes,
              seguindo: null,
              assinado: false,
              actions: [],
              changeTimestamp: new Date(),
              aprovador: null,
              aprovadorNome: null,
              alcada: null,
              alcadaNome: null,
              prioridade: 0
            };
            this.indexCurrentPainel.subitems.push(novo);

            const card = new TodoCard();
            card.codigo = codigo;
            card.titulo = novo.name;
            card.descricao = novo.descricao;
            card.posicao = novo.posicao;
            card.dtIni = novo.dataInicio;
            card.dtFin = novo.dataFim;
            card.todoPainel = new TodoPainel();
            card.todoPainel.id = this.indexCurrentPainel.idTabela;
            card.responsavel = new AuthUser();
            card.responsavel.id = novo.responsavelId;
            card.usuario = new AuthUser();
            card.usuario.id = novo.requisitanteId;
            card.arquivado = false;
            card.excluido = false;

            this.httpService.wait();
            this.httpService.postdb('/todocard', card).subscribe(
              rst1 => {
                const index = this.indexCurrentPainel.subitems.find(
                  f => f.idTabela === null
                );
                index.idTabela = rst1.id;

                // clonar etiquetas;
                // --------------------------------------
                index.flags.forEach(obj => {
                  const etiqueta = new TodoEtiquetasCardRelac();
                  etiqueta.todoCard = new TodoCard();
                  etiqueta.todoCard.id = index.idTabela;
                  etiqueta.todoEtiquetas = new TodoEtiquetas();
                  etiqueta.todoEtiquetas.id = obj.id;

                  this.httpService.wait();
                  this.httpService
                    .postdb('/todoetiquetascardrelac', etiqueta)
                    .subscribe(
                      rst2 => {
                        obj.id = rst2.id;
                      },
                      error =>
                        this.httpService.handleError(error, () =>
                          this.cardClone()
                        ),
                      () => this.httpService.done()
                    );
                });

                // clonar checklists;
                // --------------------------------------
                index.checkLists.forEach(obj1 => {
                  const checklist = new TodoCardChecklist();
                  checklist.todoCard = new TodoCard();
                  checklist.todoCard.id = index.idTabela;
                  checklist.usuario = new AuthUser();
                  checklist.usuario.id = this.sessionService.loggedUser.id;
                  checklist.titulo = obj1.titulo;

                  this.httpService.wait();
                  this.httpService
                    .postdb('/todocardchecklist', checklist)
                    .subscribe(
                      rst2 => {
                        obj1.id = rst2.id;

                        obj1.checklistAtividades.forEach(obj2 => {
                          const checklistAtiv = new TodoCardChecklistAtividades();
                          checklistAtiv.todoCardChecklist = new TodoCardChecklist();
                          checklistAtiv.todoCardChecklist.id = rst2.id;
                          checklistAtiv.usuario = new AuthUser();
                          checklistAtiv.usuario.id = this.sessionService.loggedUser.id;
                          checklistAtiv.descricao = obj2.descricao;
                          checklistAtiv.concluida = false;
                          checklistAtiv.posicao = obj2.posicao;

                          this.httpService.wait();
                          this.httpService
                            .postdb('/todocardchecklistatividades', checklistAtiv)
                            .subscribe(
                              rst3 => {

                                obj2.id = rst3.id;
                                this.getListas('ctrl');
                              },
                              error =>
                                this.httpService.handleError(error, () =>
                                  this.cardClone()
                                ),
                              () => this.httpService.done()
                            );
                        });
                      },
                      error =>
                        this.httpService.handleError(error, () =>
                          this.cardClone()
                        ),
                      () => this.httpService.done()
                    );
                });

                // clonar participantes;
                // --------------------------------------
                index.participantes.forEach(obj => {
                  const participante = new TodoPartCardRelac();
                  participante.todoCard = new TodoCard();
                  participante.todoCard.id = index.idTabela;
                  participante.usuario = new AuthUser();
                  participante.usuario.id = obj.id;

                  this.httpService.wait();
                  this.httpService
                    .postdb('/todopartcardrelac', participante)
                    .subscribe(
                      rst2 => {
                        obj.partCardRelacId = rst2.id;
                      },
                      error =>
                        this.httpService.handleError(error, () =>
                          this.cardClone()
                        ),
                      () => this.httpService.done()
                    );
                });

                this.onClosed();

                this.listasMov = true;
                this.startReload();

              },
              error =>
                this.httpService.handleError(error, () => this.cardClone()),
              () => this.httpService.done()
            );
          },
          error => this.httpService.handleError(error, () => this.cardClone()),
          () => this.httpService.done()
        );

      this.msgSocket(this.todoProjetoId);
    }
  }

  // heitor
  // ============================================
  private changeSession() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.usuario) {

      console.log('chamei')
      this.projId = 0;
      this.getCategorias();
      this.getUserAdm();
      this.getParticipantes();
      this.getProjetoInfo();
      this.getEmails();
      this.loadSeguirCard();

      this.getListas('default');
      //this.getListasArquivadas();

      this.listasMov = true;
      this.validaLista = true;
      this.startReload();

      this.getAlcadas(null);
      this.getAlcadasAcoes();
      this.getAlcadasPainelList();
    }
  }

  startGetListas() {
    this.listasMov = true;
    this.startReload();
  }

  stopGetListas() {
    this.listasMov = false;
    this.sub.unsubscribe();
  }

  private getListas(lista: string) {
    //this.httpService.wait();
    this.httpService
      .post('/custom/workflow/get-listas-refac', {
        projetoId: this.todoProjetoId,
        usuarioId: this.sessionService.loggedUser.id,
        filtro: this.filtroVazio ? null : this.filtro
      })
      .subscribe(
        rst => {

          this.usuarioSubstituto = 0;
          if (lista === 'default') {

            this.listas = [];
            this.listArquivados = [];
            rst.forEach(element => {
              if (element.arquivada) {
                this.listArquivados.push(element);
              } else {
                this.listas.push(element);
              }
            });

            setTimeout(() => {
              this.calculandoAltura();
            }, 500)

            // se a url possuir o id do card, abre o modal (rotas);
            if (this.todoCardId !== '0') {

              this.httpService
                .post(
                  '/custom/workflow/busca-card(' +
                  this.todoCardId +
                  ',' +
                  this.todoProjetoId +
                  ')'
                )
                .subscribe(
                  result => {

                    if (result.error === false) {
                      document.getElementById(this.todoCardId).click();

                    } else {
                      this.messageService.add({
                        severity: 'error', key: 'messageLinnks',
                        summary: 'Erro',
                        detail: result.response
                      });
                    }
                  },
                  error =>
                    this.httpService.handleError(error, () =>
                      this.getListas(lista)
                    ),
                  () => this.httpService.done()
                );
            }

          } else if (lista === 'ctrl') {

            this.listasCtrl = [];
            this.listArquivados = [];
            rst.forEach(element => {
              if (element.arquivada) {
                this.listArquivados.push(element);
              } else {
                this.listasCtrl.push(element);
              }
            });


            setTimeout(() => {
              this.calculandoAltura();
            }, 500)

            this.validandoListas();
          }

        },
        error =>
          this.httpService.handleError(error, () => this.getListas(lista)),
        () => this.httpService.done()
      );
  }

  private getListasArquivadas() {
    this.httpService
      .post('/custom/workflow/get-listas-refac', {
        projetoId: this.todoProjetoId,
        usuarioId: this.sessionService.loggedUser.id,
        arquivadas: true // retorna listas arquivadas;
      })
      .subscribe(
        rst => {
          this.listArquivados = [];
          this.listArquivados = rst;
        },
        error =>
          this.httpService.handleError(error, () => this.getListasArquivadas()),
        () => this.httpService.done()
      );
  }

  adicionarEmail() {

    this.mcpInput = this.mcpInput.toLowerCase();
    if (!this.mcpLoginList.find(f => f.login === this.mcpInput)) {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'E-mail não encontrado na base de dados.'
      });
    } else if (this.mcpEmailList.find(f => f.label === this.mcpInput)) {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'E-mail já adicionado.'
      });
    } else if (
      this.participantesProjeto.find(
        x =>
          x.usuario.id ===
          this.mcpLoginList.find(y => y.login === this.mcpInput).id
      )
    ) {
      // participantesProjeto
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'E-mail já vinculado à um participante do projeto.'
      });
    } else {
      this.mcpCount++;
      this.mcpEmailList.push({
        label: this.mcpInput,
        value: this.mcpCount
      });
      this.mcpEmail.push(this.mcpCount);

      // *tratativa para pré-exibir o emails inseridos na lista;
      const mcpEmailOld = this.mcpEmail;
      this.mcpEmail = [];
      mcpEmailOld.forEach(obj => {
        this.mcpEmail.push(obj);
      });

      this.mcpInput = '';
    }
  }

  alterarProjeto() {
    let usuarios = [];

    this.mepUsuariosList.map(element => {
      usuarios.push(element.value);
    });


    const todoProjeto = {
      id: this.todoProjetoId,
      participates: usuarios,
      selecionados: this.mepUsuarios
    };

    this.httpService
      .post('/custom/workflow/libera-edicao-projeto', todoProjeto)
      .subscribe(
        rst => {

          if (rst.value || (!rst.value && this.usuarioSubstituto > 0)) {
            let boo = false;

            if (this.mepAdms.length === 0) {
              boo = true;
              this.messageService.add({
                severity: 'error', key: 'messageLinnks',
                summary: 'Erro',
                detail: 'Campo obrigatório: ADMINISTRADORES'
              });
            }

            if (!boo) {

              if (!boo) {
                this.projetoNome = this.mepNome;
                this.projetoKey = this.mepKey;

                this.modalEditarProjeto = false;

                const todoProjeto = {
                  nome: this.mepNome,
                  corBg: this.mepCor,
                  depto: this.mepDepartamento,
                  key: this.mepKey,
                  id: this.todoProjetoId,
                  empresa: this.dblinkedSessionService.empresa.id,
                  usuario: this.sessionService.loggedUser.id,
                  participates: this.mepUsuarios,
                  participantesAdm: this.mepAdms,
                  addParticipantes: this.addParticipantes,
                  blockMovVertical: this.blockMovVertical,
                  addParticipantesOld: this.addParticipantesOld,
                  owner: this.mepOwner,
                  ordenacao: this.mepOrdenacao,
                  usuarioSubstituto: this.usuarioSubstituto

                };

                this.httpService
                  .post('/custom/workflow/alterar-projeto', todoProjeto)
                  .subscribe(
                    () => {
                      this.msgSocket(this.todoProjetoId);
                      this.getListas('ctrl');
                      this.modalUsuarioSubstituto = false;
                      this.usuarioSubstituto = 0;

                    },
                    error =>
                      this.httpService.handleError(error, () => this.alterarProjeto()),
                    () => this.httpService.done()
                  );
              }

            }
          } else {
            this.modalEditarProjeto = false;
            this.modalUsuarioSubstituto = true;
          }
        },
        error =>
          this.httpService.handleError(error, () => this.alterarProjeto()),
        () => this.httpService.done()
      );


  }

  arquivarCard() {
    if (confirm('Deseja arquivar este cartão?')) {
      this.onClosed(); // fecha o modal do card;

      // insere o card na lista de arquivados;
      const card = this.indexCurrentPainel.subitems.find(
        f => f.id === this.indexCurrentCard.id
      );
      card.changeTimestamp = new Date();
      this.indexCurrentPainel.subitemsArquivados.push(card);

      // remove o card da lista;
      const cardIndex = this.indexCurrentPainel.subitems.findIndex(
        f => f.id === this.indexCurrentCard.id
      );
      this.indexCurrentPainel.subitems.splice(cardIndex, 1);

      this.updateIndexCards(this.indexCurrentPainel.id, false, false, false); // update indexes...

      this.insertAtividade({
        idCard: this.indexCurrentCard.idTabela,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_ARCHIVE'
      });

      this.httpService
        .post('/custom/workflow/arquivar-card', {
          id: this.indexCurrentCard.idTabela,
          changeUserId: this.sessionService.loggedUser.id
        })
        .subscribe(
          () => { this.getListas('ctrl') },
          error =>
            this.httpService.handleError(error, () => this.arquivarCard()),
          () => this.httpService.done()
        );
    }
  }

  arquivarLista(event: any) {
    if (confirm('Deseja arquivar esta lista?')) {
      // Atualiza a lista local;
      // ------------------------------------------
      this.listas.splice(
        this.listas.findIndex(i => i.idTabela === event.idTabela),
        1
      );

      this.updateIndexPaineis(); // update indexes...

      // Atualiza o banco;
      // ------------------------------------------
      this.httpService
        .post('/custom/workflow/arquivar-lista', {
          id: event.idTabela,
          changeUserId: this.sessionService.loggedUser.id
        })
        .subscribe(
          () => this.getListas('ctrl'),
          error =>
            this.httpService.handleError(error, () =>
              this.arquivarLista(event)
            ),
          () => this.httpService.done()
        );
    }
  }

  arquivarProjeto() {
    if (confirm('Deseja arquivar este projeto?')) {
      this.httpService
        .post('/custom/workflow/arquivar-projeto', {
          id: this.todoProjetoId,
          changeUserId: this.sessionService.loggedUser.id
        })
        .subscribe(
          () => this.router.navigate(['./work-flow-projeto']),
          error =>
            this.httpService.handleError(error, () => this.arquivarProjeto()),
          () => this.httpService.done()
        );
    }
  }

  clonarProjeto() {
    if (confirm('Deseja clonar este projeto?')) {
      this.httpService
        .post('/custom/workflow/clonar-projeto', {
          id: this.todoProjetoId,
          changeUserId: this.sessionService.loggedUser.id
        })
        .subscribe(
          () => this.router.navigate(['./work-flow-projeto']),
          error =>
            this.httpService.handleError(error, () => this.clonarProjeto()),
          () => this.httpService.done()
        );
    }
  }

  assinarCard(event: any) {
    this.assCloneList = [];

    setTimeout(() => {
      event.assinado = true;

      setTimeout(() => {
        event.assinado = false;

        // encontra a lista selecionada;
        this.setIndexCurrentPainel(event.idPainel);

        // encontra o card selecionado;
        this.assCardIndex = this.indexCurrentPainel.subitems.findIndex(f => f.id.toString() === event.id.toString());
        this.indexCurrentCard = this.indexCurrentPainel.subitems[this.assCardIndex];

        // busca alçada;
        this.getAlcada();

        // abre o modal de aprovação individual do card;
        this.modalAssinarCard = true;

        this.stopGetListas();

      }, 250);
    }, 250);
  }

  filtrarCards() {
    this.filtro.requisitantes = this.mepRequisitante;
    this.filtro.responsaveis = this.mepResponsavel;
    this.filtro.prioridades = this.mepPrioridade;
    this.filtro.marcadores = this.mepMarcador;
    this.filtro.contem = this.mepContem;
    this.filtroVazio = false;

    const filtro = {
      projetoId: this.todoProjetoId,
      usuarioId: this.sessionService.loggedUser.id,
      filtro: this.filtro
    }

    if (this.mepRequisitante.length === 0 && this.mepResponsavel.length === 0
      && this.mepPrioridade.length === 0 && this.mepMarcador.length === 0 && (!this.mepContem
        || this.mepContem === '' || this.mepContem === undefined)) {
      this.limparFiltro();
    } else {
      this.getListas('ctrl');
    }
  }

  /**
   * busca a alçada do card;
   */
  private getAlcada() {
    this.assResponse = [];
    this.assBtnRecusa = false;

    // busca a alçada do card na lista de regras de todas as alçadas;
    this.alcadasRegrasFullList.forEach(regra => {
      if (this.indexCurrentCard.alcada === regra.todoAlcadas.id) { // alçada do card === alçada da lista ?
        if (this.indexCurrentPainel.idTabela === regra.todoPainelIni.id) { // id da lista do card === id do painel inicial da alçada ?
          const map = {
            acao: regra.todoAlcadasAcoes.id,
            alcadaId: regra.todoAlcadas.id,
            alcadaNome: regra.todoAlcadas.descricao,
            aprovacao: regra.aprovacao,
            indexDestino: null,
            painelId: regra.todoPainelFin.id,
            painelNome: regra.todoPainelFin.titulo,
            projetoId: regra.todoPainelFin.todoProjeto.id,
            projetoNome: regra.todoPainelFin.todoProjeto.nome,
          };

          if (map.projetoId.toString() === this.todoProjetoId.toString()) {
            map.indexDestino = this.listas.findIndex(f => f.idTabela === map.painelId);
          }

          this.assResponse.push(map);

          // O botão de recusa no modal de aprovação individual do card só é exbido se houver alçada de recusa;
          if (!regra.aprovacao) { this.assBtnRecusa = true; }
        }
      }
    });
  }

  /**
   * evento ao clicar no botão de aprovação ou recusa individual do modal;
   */
  assinarCardAction(confirm: boolean, updateIndexes: boolean) {

    let acao;
    let alcadaId = null;
    let alcadaNome;
    let aprovacao;
    let indexDestino;
    let painelId;
    let painelNome;
    let projetoId;
    let projetoNome;

    if (confirm) {

      let check = false;
      // busca a alçada de aprovação;
      this.assResponse.forEach(regra => {
        if (regra.aprovacao && regra.acao === 1) {
          acao = regra.acao;
          alcadaId = regra.alcadaId;
          alcadaNome = regra.alcadaNome;
          aprovacao = regra.aprovacao;
          indexDestino = regra.indexDestino;
          painelId = regra.painelId;
          painelNome = regra.painelNome;
          projetoId = regra.projetoId;
          projetoNome = regra.projetoNome;

          check = true;
        }
      });

      // se o card não possuir alçada de aprovação: movimentação default
      if (!check) {
        indexDestino = this.listas.findIndex(f => f.id === this.indexCurrentPainel.id + 1);
      }
    } else {
      if (!confirm) {

        // busca a alçada de recusa;
        this.assResponse.forEach(regra => {
          if (!regra.aprovacao && regra.acao === 1) {
            acao = regra.acao;
            alcadaId = regra.alcadaId;
            alcadaNome = regra.alcadaNome;
            aprovacao = regra.aprovacao;
            indexDestino = regra.indexDestino;
            painelId = regra.painelId;
            painelNome = regra.painelNome;
            projetoId = regra.projetoId;
            projetoNome = regra.projetoNome;
          }
        });
      }
    }

    // preenche a lista de informações necessárias para verificação posterior de alçada de clonagem no card;
    if (alcadaId !== null) {
      this.assCloneList.push(
        {
          idAlcada: alcadaId,
          idCardOrigem: this.indexCurrentCard.idTabela,
          idPainelOrigem: this.indexCurrentPainel.idTabela,
          idUser: this.sessionService.loggedUser.id,
          confirm: confirm
        }
      );
    }

    // insere o card na lista destino;
    this.listas[indexDestino].subitems.push(this.indexCurrentCard);

    /**
     * Somente via aprovação indivual (updateIndexes: true):
     *
     * Remoção da lista e atualização dos indexes;
     *
     * Verificação se o card possui alçada de clonagem;
     */
    if (updateIndexes) {

      // remove o card da lista origem;
      this.indexCurrentPainel.subitems.splice(this.assCardIndex, 1);

      // atualiza os indexes das listas;
      this.updateIndexCards(this.indexCurrentPainel.id, false, confirm, true);
      this.updateIndexCards(this.listas[indexDestino].id, false, confirm, true);

      this.cardCloneAlcada(confirm);

    } else if (indexDestino !== null && !this.assIndexDestinoList.includes(indexDestino)) {
      this.assIndexDestinoList.push(indexDestino);
    }

    // insere a ativdade;
    this.insertAtividade({
      idCard: this.indexCurrentCard.idTabela,
      idUser: this.sessionService.loggedUser.id,
      acao: confirm ? 'MENSAGEM_WF_ASSINATURA' : 'MENSAGEM_WF_RECUSA',
      listaIni: this.indexCurrentPainel.name
    });

    // fecha o modal de aprovação individual do card;
    this.modalAssinarCard = false;

    if (this.assResponse.length > 0) {
      //todofer: this.notificaAlcada(aprovacao, alcadaNome, painelNome);
    }
  }

  /**
   * Verificação se o card possui alçada de clonagem;
   */
  private cardCloneAlcada(confirm: boolean) {
    this.httpService.post('/custom/workflow/card-clone-alcada', this.assCloneList)
      .subscribe(
        result => {
          result.forEach(element => {
            this.messageService.add({
              severity: confirm ? 'success' : 'error',
              summary: 'Sucesso',
              detail: element.response
            });
          });
        },
        error => this.httpService.handleError(error, () => this.cardCloneAlcada(confirm)),
        () => this.httpService.done()
      );
  }

  /**
   * exibe a mensagem de notificação de alçada acionada;
   */
  private notificaAlcada(aprovacao: boolean, alcadaNome: string, painelNome: string) {

    const msg = 'Alcada "' + alcadaNome + '" acionada, cartão [' + this.indexCurrentCard.id.toUpperCase() + ']'
      + ' movido para a lista: ' + painelNome;

    this.messageService.add({
      severity: aprovacao ? 'success' : 'error',
      summary: 'Sucesso',
      detail: msg
    });
  }

  assinarCards(event: any) {
    this.indexCurrentPainel = event;
    this.modalAssinarCards = true;
    this.stopGetListas();
  }

  assinarCardsAction() {
    this.assCloneList = [];
    this.assIndexDestinoList = [];
    let count = 0;

    this.indexCurrentPainel.subitems.forEach(card => {

      // encontra o card selecionado;
      this.assCardIndex = count;
      this.indexCurrentCard = card;

      // busca alçada;
      this.getAlcada();

      this.assinarCardAction(true, false);

      count++;
    });

    // remove os cards da lista origem;
    this.indexCurrentPainel.subitems = [];

    // atualiza os indexes das listas;
    this.updateIndexCards(this.indexCurrentPainel.id, false, true, true);
    this.assIndexDestinoList.forEach(index => {
      this.updateIndexCards(this.listas[index].id, false, true, true);
    });

    // Verificação se o card possui alçada de clonagem;
    this.cardCloneAlcada(true);

    this.modalAssinarCards = false;
  }

  definirAprovador(event: any) {
    let usr = event;
    if (event === null) {
      usr = event;
    } else {
      usr = event.usuario.id;
    }

    // Atualiza a lista local;
    // ------------------------------------------
    const index = this.listas.findIndex(
      f => f.id.toString() === this.indexCurrentPainel.id.toString()
    );
    this.listas[index].aprovador = event === null ? null : event.usuario.id;
    this.listas[index].aprovadorNome =
      event === null ? null : event.usuario.fullName;

    // remove os aprovadores por card nos cartões da lista;
    this.listas[index].subitems.forEach(card => {
      card.aprovador = null;
      card.aprovadorNome = null;
    });

    // Atualiza o banco;
    // ------------------------------------------
    this.httpService
      .post('/custom/workflow/definir-aprovador', {
        idPainel: this.indexCurrentPainel.idTabela,
        idAprovador: usr
      })
      .subscribe(
        rst => this.msgSocket(this.todoProjetoId),
        error =>
          this.httpService.handleError(error, () =>
            this.definirAprovador(event)
          ),
        () => this.httpService.done()
      );

    this.onCloseHandled();
  }

  definirAprovadorCard(event: any) {
    let usr = event;
    if (event === null) {
      usr = event;
    } else {
      usr = event.usuario.id;
    }

    // Atualiza a lista local;
    // ------------------------------------------
    this.indexCurrentCard.aprovador = event === null ? null : event.usuario.id;
    this.indexCurrentCard.aprovadorNome =
      event === null ? null : event.usuario.fullName;

    // Atualiza o banco;
    // ------------------------------------------
    this.httpService
      .post('/custom/workflow/definir-aprovador-card', {
        idCard: this.indexCurrentCard.idTabela,
        idAprovador: usr
      })
      .subscribe(
        rst => this.msgSocket(this.todoProjetoId),
        error =>
          this.httpService.handleError(error, () =>
            this.definirAprovador(event)
          ),
        () => this.httpService.done()
      );

    this.onCloseHandled();
  }

  definirPainelFinal(event: any) {
    this.listas.splice(
      this.listas.findIndex(i => i.idTabela === event.idTabela),
      1
    );
    this.listas.push(event);
    this.updateIndexPaineis();
  }

  deleteCard() {
    if (confirm('Deseja excluir este cartão?')) {
      this.onClosed(); // fecha o modal do card;

      this.httpService
        .post('/custom/workflow/delete-card', {
          id: this.indexCurrentCard.idTabela
        })
        .subscribe(
          rst => {
            const card = this.indexCurrentPainel.subitems.findIndex(
              f => f.id === this.indexCurrentCard.id
            );
            this.indexCurrentPainel.subitems.splice(card, 1); // remove o card da lista local;
            this.updateIndexCards(this.indexCurrentPainel.id, false, true, false); // atualiza o index do banco e da lista local;
          },
          error => this.httpService.handleError(error, () => this.deleteCard()),
          () => this.httpService.done()
        );
    }
  }

  desarquivar() {
    if (this.mdOption[1] == null) {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'Selecione um item.'
      });
      return;
    }
    this.listasMov = false;
    this.sub.unsubscribe();
    if (confirm('Deseja desarquivar este item?')) {
      if (this.mdItem === 'cartão') {
        this.desarquivarCards();
      } else if (this.mdItem === 'lista') {
        this.desarquivarListas();
      }
    }
    this.onCloseHandled();
  }

  private desarquivarCards() {
    const map = [];
    map.push(this.mdOption);
    map.forEach(id => {
      // insere o card na lista;
      const card = this.indexCurrentPainel.subitemsArquivados.find(
        f => f.idTabela.toString() === id.toString()
      );
      this.indexCurrentPainel.subitems.push(card);

      // remove da lista de arquivados;
      const cardIndex = this.indexCurrentPainel.subitemsArquivados.findIndex(
        f => f.idTabela.toString() === id.toString()
      );
      this.indexCurrentPainel.subitemsArquivados.splice(1, cardIndex);

      this.insertAtividade({
        idCard: id,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_UNARCHIVE'
      });
    });

    this.updateIndexCards(this.indexCurrentPainel.id, false, true, false); // update indexes...

    this.httpService.post('/custom/workflow/desarquivar-cards', map).subscribe(
      () => {
        this.listasMov = true;
        //this.startReload();
      },
      error =>
        this.httpService.handleError(error, () => this.desarquivarCards()),
      () => this.httpService.done()
    );
  }

  private desarquivarListas() {
    const map = [];
    map.push(this.mdOption);
    map.forEach(id => {
      // insere a lista no fim das listas;
      const lista = this.listArquivados.find(
        f => f.idTabela.toString() === id.toString()
      );
      this.listas.push(lista);
    });

    this.updateIndexPaineis(); // update indexes...

    this.httpService.post('/custom/workflow/desarquivar-listas', map).subscribe(
      () => {
        this.getListas('ctrl');
        this.listasMov = true;
        //this.startReload();
        this.msgSocket(this.todoProjetoId);
      },
      error =>
        this.httpService.handleError(error, () => this.desarquivarListas()),
      () => this.httpService.done()
    );
  }

  editarEtiqueta(event: any) {
    this.etiqueta = new TodoEtiquetas(event);
  }

  enviarEmail() {
    const map = [];
    this.mcpEmailList.forEach(obj => {
      if (this.mcpEmail.find(f => f === obj.value)) {
        map.push({ email: obj.label });
      }
    });

    const listasEnviar = {
      projeto: this.todoProjetoId,
      usuario: this.sessionService.loggedUser.id,
      email: map
    };

    this.httpService
      .post('/custom/workflow/enviar-convite-projeto', listasEnviar)
      .subscribe(
        rst => {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: '',
            detail: 'Convite enviado com sucesso!'
          });
          this.modalConvidarParticipante = false;
          this.msgSocket(this.todoProjetoId);
        },
        error => this.httpService.handleError(error, () => this.enviarEmail()),
        () => this.httpService.done()
      );
  }

  enviarEmailAprovador(
    cardId: number,
    painelIniId: number,
    painelFinId: number
  ) {
    this.httpService
      .post('/custom/workflow/enviar-email-aprovador', {
        cardId: cardId,
        painelIniId: painelIniId,
        painelFinId: painelFinId
      })
      .subscribe(
        () => { },
        error =>
          this.httpService.handleError(error, () =>
            this.enviarEmailAprovador(cardId, painelIniId, painelFinId)
          ),
        () => this.httpService.done()
      );
  }

  enviarEmailResponsavel() {
    this.httpService
      .post('/custom/workflow/enviar-email-responsavel('
        + this.indexCurrentCard.idTabela
        + ','
        + this.sessionService.loggedUser.id
        + ')'
      )
      .subscribe(
        () => { },
        error =>
          this.httpService.handleError(error, () =>
            this.enviarEmailResponsavel()
          ),
        () => this.httpService.done()
      );
  }

  formatDate(milliseconds: any) {
    const d = new Date(milliseconds); // yyyy-MM-dd;
    return (
      d.toJSON().substring(8, 10) + // day;
      '/' +
      d.toJSON().substring(5, 7) + // month;
      '/' +
      d.toJSON().substring(0, 4) // year;
    );
  }

  formatDate2(date: string) {
    const d =
      date.substring(3, 5) + // month;
      '/' +
      date.substring(0, 2) + // day;
      '/' +
      date.substring(6, 10); // year;

    return new Date(d);
  }

  /**
   * preenche:
   * alcadasList: lista de alçadas no modal de alçadas;
   * alcadasCardList: // lista de alçadas no modal do card;
   * alçadasRegrasFullList: lista de regras de todas as alçadas;
   */
  private getAlcadas(alcadaId: any) {
    this.alcadasList = [];
    this.alcadasCardList = [];
    this.alcadasRegrasFullList = [];

    this.httpService.get(
      '/todoalcadasregras?$select='
      + 'id,'
      + 'todoAlcadas/id,'
      + 'todoAlcadas/descricao,'
      + 'todoAlcadasAcoes/id,'
      + 'todoPainelIni/id,'
      + 'todoPainelIni/titulo,'
      + 'todoPainelFin/id,'
      + 'todoPainelFin/titulo,'
      + 'todoPainelFin/todoProjeto/id,'
      + 'todoPainelFin/todoProjeto/nome,'
      + 'aprovacao,'
      + '&$filter=todoAlcadas/todoProjeto/id=' + this.todoProjetoId + ' and todoAlcadas/active = true'
      + '&$orderby=todoAlcadas/descricao,todoPainelIni/titulo'
    )
      .subscribe(
        result => {
          this.alcadasRegrasFullList = result.value;

          this.alcadasList.push({ label: 'Selecione', value: null });
          this.alcadasList.push({ label: 'Nova Regra', value: 0 });
          let lastAlcadaId = null;
          result.value.forEach(regra => {
            if (regra.todoAlcadas.id !== lastAlcadaId) {
              this.alcadasList.push({ label: regra.todoAlcadas.descricao, value: regra.todoAlcadas.id });
              this.alcadasCardList.push({ label: regra.todoAlcadas.descricao, value: regra.todoAlcadas.id });
            }
            lastAlcadaId = regra.todoAlcadas.id;
          });
          this.alcadaId = -1;
          setTimeout(() => { this.alcadaId = alcadaId; }, 500);
        },
        error => this.httpService.handleError(error, () => this.getAlcadas(alcadaId)),
        () => this.httpService.done()
      );
  }

  /**
   * preenche a lista de ações que podem ser selecionadas na regras;
   */
  private getAlcadasAcoes() {
    this.alcadasAcoesList = [];
    this.alcadasAcoesList.push({ label: 'Selecione', value: null });
    this.httpService.get('/todoalcadasacoes?$orderby=descricao')
      .subscribe(
        result => {
          result.value.forEach(acao => { this.alcadasAcoesList.push({ label: acao.descricao, value: acao.id }); });
        },
        error => this.httpService.handleError(error, () => this.getAlcadasAcoes()),
        () => this.httpService.done()
      );
  }

  /**
   * preenche a lista de paineis origem e destino que podem ser selecionados nas regras;
   */
  private getAlcadasPainelList() {
    this.alcadasPainelIniList = [];
    this.alcadasPainelFinList = [];
    this.alcadasPainelFinListTEMP = [];

    this.alcadasPainelIniList.push({ label: 'Selecione', value: null });
    this.alcadasPainelFinList.push({ label: 'Selecione', value: null });
    this.alcadasPainelFinListTEMP.push({ label: 'Selecione', value: null });

    this.listasAlcadasCtrl = []; // lista de controle das alçadas;

    this.httpService.wait();
    this.httpService.post('/custom/workflow/get-alcada-painel-fin-list(' + this.sessionService.loggedUser.id + ')')
      .subscribe(
        result => {
          result.forEach(painel => {
            // adiciona à lista de paineis origem somente os paineis do projeto; GCK 06.03.2019
            const index = this.listas.findIndex(f => f.idTabela === painel.value);
            if (index !== -1) {
              this.alcadasPainelIniList.push({ label: painel.label.substring(painel.label.lastIndexOf('- ') + 1), value: painel.value });
            }
            this.alcadasPainelFinList.push({ label: painel.label, value: painel.value });
            this.alcadasPainelFinListTEMP.push({ label: painel.label, value: painel.value });
          });
        },
        error => this.httpService.handleError(error, () => this.getAlcadasPainelList()),
        () => this.httpService.done()
      );
  }



  /**
   * retorna a label do painel selecionado;
   */
  getPainelLabel(event: any) {
    let info = 'Selecione';
    const obj = this.alcadasPainelFinList.find(f => f.value === event);
    if (obj !== undefined) {
      info = this.alcadasPainelFinList.find(f => f.value === event).label;
    }
    return info;
  }

  /**
   * retorna o id da última posição do alcadaRegrasList;
   */
  getRegrasLastId(event: any) {
    let check;
    let count = 0;
    this.alcadasRegrasList.forEach(regra => {
      count++;
      if (count === this.alcadasRegrasList.length) {
        check = (regra.id === event.id) ? true : false;
      }
    });
    return check;
  }

  refreshModalAlcadas() {
    this.getAlcadasPainelList();
    this.alcadaDescricao = '';
    this.alcadaId = undefined;
    this.alcadasRegrasList = [];
    this.regrasCount = 0;
    this.modalAlcadas = true;
  }

  // Validações:
  /**
   * Listas com alçada de aprovação e recusa não podem receber mais regras (monitorar lista origem);
   *
   * Ação 'Movimentar cartão' não pode apontar para outro projeto (monitorar lista destino);
   *
   * Ação 'Clonar cartão' não pode apontar para o mesmo projeto (monitorar lista destino);
   */

  /**
   * trigger ao selecionar o painel origem;
   */
  triggerAlcadasPainelIni(rowData: any) {
    if (rowData.todoPainelIni.id !== null) {

      const list = [];
      let msg = null;

      this.alcadasRegrasList.forEach(regra => {
        if (regra.todoPainelIni.id === rowData.todoPainelIni.id) {

          const obj = regra.todoPainelIni.id + '|' + regra.todoAlcadasAcoes.id + '|' + regra.aprovacao;

          if (!list.includes(obj)) {
            list.push(obj);
          } else {
            msg = 'A lista origem já possui uma alçada semelhante';
            this.messageService.add({
              severity: 'error', key: 'messageLinnks',
              summary: 'Erro',
              detail: msg
            });
          }
        }
      });

      // caso na ação seja selecionado "Mover Cartão"
      // trazer apenas as listas do proprio projeto - GCK 06.03.2019
      if (rowData.todoAlcadasAcoes.id === 1) {
        // const projeto = this.getPainelLabel(rowData.todoPainelFin.id);
        this.alcadasPainelFinListTEMP = [];
        this.alcadasPainelFinListTEMP.push({ label: 'Selecione', value: null });
        this.alcadasPainelFinList.forEach(destino => {
          const nomeProjeto = destino.label; // .split(' - ')[0];
          // nomeProjeto = nomeProjeto.split(' - ')[0];
          if (nomeProjeto.toUpperCase().indexOf(this.projetoNome.toUpperCase()) !== -1) {
            this.alcadasPainelFinListTEMP.push({ label: destino.label, value: destino.value });
          }
        });
      } else {
        this.alcadasPainelFinListTEMP = this.alcadasPainelFinList;
      }

      rowData.checkTodoPainelIni = msg;
    }
  }

  /**
   * trigger ao selecionar o painel destino;
   */
  triggerAlcadasPainelFin(rowData: any) {
    if (rowData.todoPainelFin.id !== null && rowData.todoAlcadasAcoes.id !== null) {
      this.alcadasBtnAprovacao = false;

      this.httpService.wait();
      this.httpService.get('/todopainel?$select=titulo,todoProjeto/id&$filter=id eq ' + rowData.todoPainelFin.id)
        .subscribe(
          result => {

            let msg = null;
            if (rowData.todoAlcadasAcoes.id === 1 && result.value[0].todoProjeto.id.toString() !== this.todoProjetoId.toString()) {
              msg = 'A lista origem deve ser do projeto atual para a ação "Movimentar cartão"';
              this.messageService.add({
                severity: 'error', key: 'messageLinnks',
                summary: 'Erro',
                detail: msg
              });
            } else if (rowData.todoAlcadasAcoes.id === 2 && result.value[0].todoProjeto.id.toString() === this.todoProjetoId.toString()) {
              msg = 'A lista origem não pode ser do projeto atual para a ação "Clonar cartão"';
              this.messageService.add({
                severity: 'error', key: 'messageLinnks',
                summary: 'Erro',
                detail: msg
              });
            }
            rowData.checkTodoPainelFin = msg;

            this.alcadasBtnAprovacao = true;

          },
          error => this.httpService.handleError(error, () => this.triggerAlcadasPainelFin(rowData)),
          () => this.httpService.done()
        );
    }
  }

  /**
   * trigger ao selecionar uma regra;
   */
  triggerAlcadasRegras() {
    this.alcadaDescricao = '';
    this.alcadasRegrasList = [];

    if (this.alcadaId === 0) {
      this.inserirRegra(false);
    } else if (this.alcadaId > 0) {
      this.alcadasRegrasFullList.forEach(regra => {
        if (regra.todoAlcadas.id === this.alcadaId) {

          const todoAlcadasRegras = new TodoAlcadasRegras();
          todoAlcadasRegras.id = regra.id;

          this.alcadaDescricao = regra.todoAlcadas.descricao;

          todoAlcadasRegras.todoPainelIni = new TodoPainel();
          todoAlcadasRegras.todoPainelIni.id = regra.todoPainelIni.id;
          todoAlcadasRegras.todoPainelIni.titulo = regra.todoPainelIni.titulo;

          todoAlcadasRegras.todoPainelFin = new TodoPainel();
          todoAlcadasRegras.todoPainelFin.id = regra.todoPainelFin.id;

          todoAlcadasRegras.todoAlcadasAcoes = new TodoAlcadasAcoes();
          todoAlcadasRegras.todoAlcadasAcoes.id = regra.todoAlcadasAcoes.id;

          todoAlcadasRegras.aprovacao = regra.aprovacao;

          todoAlcadasRegras.update = true;

          todoAlcadasRegras.checkTodoPainelIni = null;
          todoAlcadasRegras.checkTodoPainelFin = null;

          todoAlcadasRegras.condicao = isNullOrUndefined(regra.condicao) ? '' : regra.condicao;

          this.alcadasRegrasList.push(todoAlcadasRegras);
        }
      });
    }
  }

  salvarAlcadaCard(event: any) {
    this.indexCurrentCard.alcada = event !== null ? event.value : null;
    this.indexCurrentCard.alcadaNome = event !== null ? event.label : null;

    this.httpService.wait();
    this.httpService.post('/custom/workflow/update-alcada-card',
      {
        idCard: this.indexCurrentCard.idTabela,
        idAlcada: event !== null ? event.value : null
      }
    )
      .subscribe(
        result => {
          this.msgSocket(this.todoProjetoId);
        },
        error => this.httpService.handleError(error, () => this.salvarAlcadaCard(event)),
        () => this.httpService.done()
      );
  }

  salvarAlcada() {
    let check = false;

    if (this.alcadaDescricao === '' || this.alcadaDescricao === null) {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'O campo "NOME" deve ser preenchido.'
      });
      check = true;
    }

    let count = 0;
    this.alcadasRegrasList.forEach(regra => {
      count++;

      if (regra.todoPainelIni.id === null) {
        this.messageService.add({
          severity: 'error', key: 'messageLinnks',
          summary: 'Erro',
          detail: 'O campo "ORIGEM" deve ser preenchido na ' + count + 'º regra.'
        });
        check = true;
      } else if (regra.checkTodoPainelIni !== null) {
        this.messageService.add({
          severity: 'error', key: 'messageLinnks',
          summary: 'Erro',
          detail: regra.checkTodoPainelIni + ' na ' + count + 'º regra.'
        });
        check = true;
      }

      if (regra.todoAlcadasAcoes.id === null) {
        this.messageService.add({
          severity: 'error', key: 'messageLinnks',
          summary: 'Erro',
          detail: 'O campo "AÇÂO" deve ser preenchido na ' + count + 'º regra.'
        });
        check = true;
      }

      if (regra.todoPainelFin.id === null) {
        this.messageService.add({
          severity: 'error', key: 'messageLinnks',
          summary: 'Erro',
          detail: 'O campo "DESTINO" deve ser preenchido na ' + count + 'º regra.'
        });
        check = true;
      } else if (regra.checkTodoPainelFin !== null) {
        this.messageService.add({
          severity: 'error', key: 'messageLinnks',
          summary: 'Erro',
          detail: regra.checkTodoPainelFin + ' na ' + count + 'º regra.'
        });
        check = true;
      }


    });

    if (!check) {

      const list = [];
      this.alcadasRegrasList.forEach(regra => {
        list.push(
          {
            alcadaId: this.alcadaId,
            alcadaDescricao: this.alcadaDescricao,
            alcadaTodoProjetoId: this.todoProjetoId,
            regraId: regra.id,
            regraTodoAlcadasAcoesId: regra.todoAlcadasAcoes.id,
            regraAprovacao: regra.aprovacao,
            regraTodoPainelIniId: regra.todoPainelIni.id,
            regraTodoPainelFinId: regra.todoPainelFin.id,
            regraUpdate: regra.update,
            condicao: regra.condicao
          }
        );
      });

      this.httpService.wait();
      this.httpService.post('/custom/workflow/update-alcada-regra', list)
        .subscribe(
          result => {
            this.getAlcadas(result);
            this.modalAlcadas = false;
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'Sucesso',
              detail: 'Regra salva com sucesso!'
            });
            this.msgSocket(this.todoProjetoId);
          },
          error => this.httpService.handleError(error, () => this.salvarAlcada()),
          () => this.httpService.done()
        );
    }
  }


  excluirAlcada() {
    if (confirm('Deseja excluir esta alçada?')) {
      this.refreshModalAlcadas();

      this.httpService.post('/custom/workflow/exclui-alcada(' + this.alcadaId + ')')
        .subscribe(
          result => {
            this.getAlcadas(null);
          },
          error => this.httpService.handleError(error, () => this.excluirAlcada()),
          () => this.httpService.done()
        );
    }
  }

  inserirRegra(update: boolean) {
    this.regrasCount++;

    const todoAlcadasRegras = new TodoAlcadasRegras();
    todoAlcadasRegras.id = this.regrasCount;

    todoAlcadasRegras.todoPainelIni = new TodoPainel();
    todoAlcadasRegras.todoPainelIni.id = null;

    todoAlcadasRegras.todoPainelFin = new TodoPainel();
    todoAlcadasRegras.todoPainelFin.id = null;

    todoAlcadasRegras.todoAlcadasAcoes = new TodoAlcadasAcoes();
    todoAlcadasRegras.todoAlcadasAcoes.id = null;

    todoAlcadasRegras.aprovacao = true;

    todoAlcadasRegras.update = update;

    todoAlcadasRegras.checkTodoPainelIni = null;
    todoAlcadasRegras.checkTodoPainelFin = null;

    this.alcadasRegrasList.push(todoAlcadasRegras);
    this.sql = '';
  }

  excluirRegra(event: any) {
    if (confirm('Deseja excluir esta regra?')) {
      const index = this.alcadasRegrasList.findIndex(f => f.id === event.id);
      this.alcadasRegrasList.splice(index, 1);

      if (event.update) {
        this.httpService.post('/custom/workflow/exclui-alcada-regra(' + event.id + ')')
          .subscribe(
            result => { this.msgSocket(this.todoProjetoId); },
            error => this.httpService.handleError(error, () => this.excluirRegra(event)),
            () => this.httpService.done()
          );
      }
    }
  }

  getCategorias() {
    this.categoriaList = [];
    this.httpService
      .post('/custom/workflow/get-categoria-list(' + this.todoProjetoId + ')')
      .subscribe(
        rst => {
          this.categoriaList = rst;
        },
        error =>
          this.httpService.handleError(error, () => this.getCategorias()),
        () => this.httpService.done()
      );
  }

  getEmails() {
    this.mcpLoginList = [];

    this.httpService
      .get('/custom/workflow/get-auth-user')
      .subscribe(
        rst => {
          this.mcpLoginList = rst;
        },
        error => this.httpService.handleError(error, () => this.getEmails()),
        () => this.httpService.done()
      );
  }

  private getParticipantes() {
    this.participantesProjeto = [];
    this.httpService
      .get(
        '/todoprojetousuarios?$select=id,usuario/id,usuario/fullName,administrador&$filter=todoProjeto/id eq ' +
        this.todoProjetoId +
        '&$orderby=usuario/fullName'
      )
      .subscribe(
        rst => {
          rst.value.forEach(obj => {
            this.participantesProjeto.push(obj);
          });
        },
        error =>
          this.httpService.handleError(error, () => this.getParticipantes()),
        () => this.httpService.done()
      );
  }

  private getProjetoInfo() {
    this.httpService
      .get(
        '/todoprojeto?$select=nome,key,corBg,depto,addParticipantes,blockMovVertical,addParticipantesOld,owner/id,ordenacao/id&$filter=id eq ' +
        this.todoProjetoId
      )
      .subscribe(
        rst =>
          rst.value.map(obj => {
            // console.log(obj);
            this.projetoNome = obj.nome.toUpperCase();
            this.projetoKey = obj.key.toUpperCase();

            // modal editar projeto;
            this.mepNome = obj.nome;
            this.mepKey = obj.key;
            this.mepCor = obj.corBg;
            this.addParticipantes = obj.addParticipantes;
            this.blockMovVertical = obj.blockMovVertical;
            this.addParticipantesOld = obj.addParticipantesOld;
            this.loadDepartamento(obj.depto);
            this.loadUsuarios();
            this.loadEtiquetas();
            this.loadPrioridade();
            this.loadOwner(obj.owner !== null ? obj.owner.id : 0);
            this.loadOrdenacao(obj.ordenacao !== null ? obj.ordenacao.id : 0);
          }),
        error =>
          this.httpService.handleError(error, () => this.getProjetoInfo()),
        () => this.httpService.done()
      );
  }

  private getUserAdm() {
    this.httpService
      .get(
        '/todoprojetousuarios?$select=administrador&$filter=todoProjeto/id eq ' +
        this.todoProjetoId +
        ' and usuario/id eq ' +
        this.sessionService.loggedUser.id
      )
      .subscribe(
        rst => {
          rst.value.forEach(obj => {
            this.userAdm = obj.administrador;
          });
        },
        error =>
          this.httpService.handleError(error, () => this.getUserAdm()),
        () => this.httpService.done()
      );
  }

  private insertAtividade(event: any) {
    // ações no br.com.dblinked.common.MessageUtil;

    this.httpService.post('/custom/workflow/insert-atividade', event).subscribe(
      rst => {

        this.msgSocket(this.todoProjetoId);
        if (event.acao === 'MENSAGEM_WF_MOVIMENTACAO') {

          if (this.indexCurrentPainel.ordenacao === 'true') {
            //this.startGetListas();

            if (this.listasMov) {
              this.getListas('ctrl');
            }
          }
        }

        this.listas.forEach(lista => {
          lista.subitems.forEach(card => {
            if (card.id.toString() === event.idCard.toString()) {
              const ativ = {
                data: new Date(),
                mensagem: rst.msg,
                userName:
                  this.dblinkedSessionService.usuario.nome +
                  ' ' +
                  this.dblinkedSessionService.usuario.sobrenome
              };

              card.atividades.unshift(ativ);
            }
          });
        });
      },
      error =>
        this.httpService.handleError(error, () => this.insertAtividade(event)),
      () => this.httpService.done()
    );
  }

  //vir
  insertComentario() {
    this.listasMov = false;
    this.sub.unsubscribe();

    // Atualiza a lista local;
    // ------------------------------------------
    const map = {
      id: null,
      mensagem: this.textComentario,
      userName:
        this.dblinkedSessionService.usuario.nome +
        ' ' +
        this.dblinkedSessionService.usuario.sobrenome,
      userId: this.sessionService.loggedUser.id,
      data: new Date() // new Date().toString().substring(16, 24)
    };

    this.indexCurrentCard.comentarios.unshift(map);

    this.insertAtividade({
      idCard: this.indexCurrentCard.idTabela,
      idUser: this.sessionService.loggedUser.id,
      acao: 'MENSAGEM_WF_INSERT_COMENTARIO',
      comentario: map.mensagem
    });

    // Atualiza o banco;
    // ------------------------------------------
    const todoCardComentarios = new TodoCardComentarios();
    todoCardComentarios.todoCard = new TodoCard();
    todoCardComentarios.mensagem = map.mensagem;
    todoCardComentarios.usuario = new AuthUser();
    todoCardComentarios.usuario.id = map.userId;
    todoCardComentarios.todoCard.id = this.indexCurrentCard.idTabela;

    this.httpService
      .postdb('/todocardcomentarios', todoCardComentarios)
      .subscribe(
        rst => {
          try {
            this.indexCurrentCard.comentarios.find(f => f.id === null).id =
              rst.id;
          } catch (error) {
            console.log('\n--> error: ' + error);
          }

          this.buscaUsersMencionados(this.textComentario, 'participantes');
          this.textComentario = '';

          this.listasMov = true;
          this.startReload();
        },
        error => this.httpService.handleError(error, () => this.insertComentario()),
        () => this.httpService.done()
      );
  }

  updateComentario() {
    this.listasMov = false;
    this.sub.unsubscribe();

    // Atualiza a lista local;
    // ------------------------------------------
    const map = {
      id: null,
      mensagem: this.textComentario,
      userName:
        this.dblinkedSessionService.usuario.nome +
        ' ' +
        this.dblinkedSessionService.usuario.sobrenome,
      userId: this.sessionService.loggedUser.id,
      data: new Date() // new Date().toString().substring(16, 24) mensagem: this.textComentario,
    };

    // this.indexCurrentCard.comentarios.unshift(map);

    this.insertAtividade({
      idCard: this.indexCurrentCard.idTabela,
      idUser: this.sessionService.loggedUser.id,
      acao: 'MENSAGEM_WF_EDIT_COMENTARIO',
      comentarioIni: this.nomeIni,
      comentarioFin: this.textComentario
    });

    // Atualiza o banco;
    // ------------------------------------------
    const todoCardComent = new TodoCardComentarios();
    todoCardComent.id = this.todoCardComentario.id;
    todoCardComent.todoCard = new TodoCard();
    todoCardComent.mensagem = map.mensagem;
    todoCardComent.usuario = new AuthUser();
    todoCardComent.usuario.id = map.userId;
    todoCardComent.todoCard.id = this.indexCurrentCard.idTabela;

    // this.httpService.postdb('/todocardcomentarios', todoCardComentarios).subscribe(
    this.httpService.put('/todocardcomentarios', todoCardComent.id, todoCardComent).subscribe(
      rst => {
        try {
          // this.indexCurrentCard.comentarios.find(f => f.id === todoCardComentarios.id).id = rst.id;
          const index = this.indexCurrentCard.comentarios.findIndex(f => f.id === todoCardComent.id);
          // setando no array
          // this.indexCurrentCard.comentarios.splice(index, 1, todoCardComent);
          this.todoCardComentario = new TodoCardComentarios();
        } catch (error) {
          console.log('\n--> error: ' + error);
        }

        this.buscaUsersMencionados(this.textComentario, 'participantes');
        this.textComentario = '';

        this.listasMov = true;
        this.startReload();
      },
      error => this.httpService.handleError(error, () => this.updateComentario()),
      () => this.httpService.done()
    );
  }

  deleteComentario(comentario: any) {
    // console.log('\n--> comentario: ' + JSON.stringify(comentario));
    if (confirm('Deseja excluir este comentário?')) {
      this.stopGetListas();
      document.getElementById('textAreaThree').style.display = 'none';
      document.getElementById('divTextAreaThree').style.display = 'inline-block';
      // this.nomeIni = '';
      // this.textComentario = '';
      this.todoCardComentario = new TodoCardComentarios();

      this.insertAtividade({
        idCard: this.indexCurrentCard.idTabela,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_DELETE_COMENTARIO',
        comentario: comentario.mensagem
      });

      this.httpService.delete('/todocardcomentarios', comentario.id.toString())
        .subscribe(() => {
          const index = this.indexCurrentCard.comentarios.findIndex(c => c.id === comentario.id);
          this.indexCurrentCard.comentarios.splice(index, 1);
          this.startGetListas();
        }, error => this.httpService.handleError(error, () => this.deleteComentario(comentario)),
          () => this.httpService.done());
    }
  }

  loadUsuarios() {
    this.mepUsuarios = [];
    this.mepUsuariosList = [];
    this.mepAdms = [];
    this.mepAdmsList = [];
    this.mepAdmsListSubstituto = [];

    this.httpService
      .post('/custom/workflow/load-usuarios',
        {
          empresaId: this.dblinkedSessionService.empresa.id,
          projetoId: this.todoProjetoId
        }
      )
      .subscribe(
        rst1 => {
          // rst1.forEach(obj => {
          //   this.mepUsuariosList.push({
          //     label: obj.fullName,
          //     value: obj.id
          //   });
          // });
          let fakeUsersList = [];
          rst1.map(user => {
            fakeUsersList.push({ label: user.fullName, value: user.id })
          })
          this.mepUsuariosList = fakeUsersList;
        },
        error => this.httpService.handleError(error, () => console.log(error)),
        () => this.httpService.done()
      );

    // BUSCA PARTCIPANTES DO PROJETO E INSERE OS IDS NA LISTA 'mepUsuarios';
    // SE PARTCIPANTES FOREM ADMS INSERE OS IDS NAS LISTA 'mepAdms' e 'mepAdmsList';
    this.httpService
      .get(
        '/todoprojetousuarios?$select=usuario/id,administrador&$filter=todoProjeto/id eq ' +
        this.todoProjetoId
      )
      .subscribe(
        rst2 => {
          this.mepAdmsListSubstituto.push({
            label: 'Selecione',
            value: 0
          });

          rst2.value.map(obj => {
            this.mepUsuarios.push(obj.usuario.id);
            if (obj.administrador === true) {
              this.mepAdms.push(obj.usuario.id);
            }
            this.mepAdmsList.push({
              label: this.mepUsuariosList.find(
                f => f.value === obj.usuario.id
              ).label,
              value: obj.usuario.id
            });
            this.mepAdmsListSubstituto.push({
              label: this.mepUsuariosList.find(
                f => f.value === obj.usuario.id
              ).label,
              value: obj.usuario.id
            });
          });
        },
        error =>
          this.httpService.handleError(error, () => this.loadUsuarios()),
        () => this.httpService.done()
      );
  }

  loadAdms() {
    const mepAdmsOld = this.mepAdms;
    this.mepAdms = [];
    this.mepAdmsList = [];
    this.mepAdmsListSubstituto = [];

    this.mepAdmsListSubstituto.push({
      label: 'Selecione',
      value: 0
    });

    // lista de participantes selecionados;
    this.mepUsuarios.forEach(obj => {
      // lista de participantes completa;
      const index = this.mepUsuariosList.find(c => c.value === obj);
      // lista de adms selecionáveis;
      this.mepAdmsList.push({
        label: index.label,
        value: index.value
      });

      this.mepAdmsListSubstituto.push({
        label: index.label,
        value: index.value
      });
    });

    // lista de usuários que já estavam selecionados;
    this.mepAdms = mepAdmsOld;
  }

  loadDepartamento(event: any) {
    this.mepDepartamento = [];
    this.mepDepartamentoList = [];

    this.httpService
      .get('/depto?$select=id,descricao&$orderby=descricao')
      .subscribe(
        rst => {
          this.mepDepartamentoList.push({
            label: 'Selecione',
            value: null
          });
          rst.value.forEach(obj => {
            this.mepDepartamentoList.push({
              label: obj.descricao,
              value: obj.id
            });
          });
          this.mepDepartamento = event.id;
        },
        error =>
          this.httpService.handleError(error, () =>
            this.loadDepartamento(event)
          ),
        () => this.httpService.done()
      );
  }

  loadEtiquetas() {
    this.etiqueta = new TodoEtiquetas();
    this.etiqueta.todoProjeto = new TodoProjeto();
    this.etiqueta.todoProjeto.id = this.todoProjetoId;
    this.etiqueta.usuario = new AuthUser();
    this.etiqueta.usuario.id = this.sessionService.loggedUser.id;
    this.etiqueta.corBg = '#0091ea';

    // listarEtiquetas;
    this.etiquetaList = [];
    this.mepEtiquetaList = [];

    this.httpService
      .get(
        '/todoetiquetas?$select=id,nome,corBg,descricao,todoProjeto/id,usuario/id&$filter=todoProjeto/id eq ' +
        this.etiqueta.todoProjeto.id +
        '&$orderby=id ASC'
      )
      .subscribe(
        rst =>
          rst.value.forEach(obj => {
            this.etiquetaList.push(obj);
            this.mepEtiquetaList.push({
              label: obj.nome,
              value: obj.id
            });
          }),
        error =>
          this.httpService.handleError(error, () => this.loadEtiquetas()),
        () => this.httpService.done()
      );

    /*this.etiquetaList.forEach(obj => {console.log(obj)})*/

  }

  // movição de cards por drag & drop;
  private moveCards(argsModel: any, idLista: any, target: boolean) {
    if (this.listas.length > 0) {
      // armazena os cards da lista do dragula;
      const cardsToMove = [];
      argsModel.forEach(card => {
        cardsToMove.push(card);
      });
      // insere os cards da lista do dragula na lista local;
      const painelToMove = this.listas.find(
        f => f.id.toString() === idLista.toString()
      );
      painelToMove.subitems = cardsToMove;

      this.updateIndexCards(idLista, target, true, false);
    } else {
      // console.log('\n--> movimentação na instânca anterior do componente...');
    }
  }

  // movimentação de card por seleção de lista;
  moveCard(event: any) {
    const cardToMove = this.indexCurrentCard;
    const cardToMoveIndex = this.indexCurrentPainel.subitems.find(
      f => f.idTabela.toString() === cardToMove.idTabela.toString()
    );

    // remove from;
    this.indexCurrentPainel.subitems.splice(cardToMoveIndex, 1);
    this.updateIndexCards(this.indexCurrentPainel.id, false, true, false);

    // insert into;
    const painelToMove = this.listas.find(
      f => f.id.toString() === event.id.toString()
    );
    painelToMove.subitems.push(cardToMove);

    // notificar aprovador;
    if (painelToMove.aprovador !== null) {
      this.enviarEmailAprovador(
        cardToMove.idTabela,
        this.indexCurrentPainel.idTabela,
        painelToMove.idTabela
      );
    }

    this.insertAtividade({
      idCard: cardToMove.idTabela,
      idUser: this.sessionService.loggedUser.id,
      acao: 'MENSAGEM_WF_MOVIMENTACAO',
      listaIni: this.indexCurrentPainel.name,
      listaFin: painelToMove.name
    });

    this.updateIndexCards(event.id, false, true, false);
  }

  // movimentação de todos os cards por seleção de lista;
  moveAllCards(event: any) {
    const cardsToMove = [];

    this.indexCurrentPainel.subitems.forEach(card => {
      cardsToMove.push(card);
    });

    // remove from;
    this.indexCurrentPainel.subitems = [];
    this.updateIndexCards(this.indexCurrentPainel.id, false, true, false);

    // insert into;
    cardsToMove.forEach(card => {
      event.subitems.push(card);

      this.insertAtividade({
        idCard: card.idTabela,
        idUser: this.sessionService.loggedUser.id,
        acao: 'MENSAGEM_WF_MOVIMENTACAO',
        listaIni: this.indexCurrentPainel.name,
        listaFin: event.name
      });

      // notificar aprovador;
      if (event.aprovador !== null) {
        this.enviarEmailAprovador(
          card.idTabela,
          this.indexCurrentPainel.idTabela,
          event.idTabela
        );
      }
    });

    this.updateIndexCards(event.id, false, true, false);

    this.onCloseHandled();
  }

  openModalConvidarPartipante() {
    this.mcpCount = 0;
    this.mcpEmail = [];
    this.mcpEmailList = [];
    this.modalConvidarParticipante = true;
  }

  openModalDesarquivar(event: any) {
    this.mdItem = event;
    this.mdOption = [];
    this.mdOptionList = [];

    const map = [];

    if (event === 'lista') {
      this.listArquivados.forEach(lista => {
        map.push(
          this.formatDate(lista.changeTimestamp) +
          ' - ' +
          lista.name +
          '|' +
          lista.idTabela
        );
      });
    } else if (event === 'cartão') {
      this.indexCurrentPainel.subitemsArquivados.forEach(card => {
        map.push(card.id + ' - ' + card.name + '|' + card.idTabela); //  this.formatDate(card.changeTimestamp) +
      });
    }

    map.sort();
    this.mdOptionList.push({ label: 'Selecione ' + event, value: null });
    map.forEach(obj => {
      this.mdOptionList.push({
        label: obj.split('|')[0],
        value: obj.split('|')[1]
      });
    });

    // this.modalDesarquivar = true;
    this.displayUnarchiveList = 'block';
  }

  removerEtiqueta(event: any) {
    if (confirm('Deseja excluir a etiqueta "' + event.nome + '"?')) {
      this.httpService.delete('/todoetiquetas', event.id.toString()).subscribe(
        () => {
          const index = this.etiquetaList.indexOf(event);
          this.etiquetaList.splice(index, 1);
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Marcador excluído com sucesso!'
          }
          );
          this.msgSocket(this.todoProjetoId);
        },
        error =>
          this.httpService.handleError(error, () =>
            this.removerEtiqueta(event)
          ),
        () => this.httpService.done()
      );
    }
  }

  cancelEtiqueta() {
    this.etiqueta = new TodoEtiquetas();
    this.etiqueta.todoProjeto = new TodoProjeto();
    this.etiqueta.todoProjeto.id = this.todoProjetoId;
    this.etiqueta.usuario = new AuthUser();
    this.etiqueta.usuario.id = this.sessionService.loggedUser.id;
    this.etiqueta.corBg = '#0091ea';
    this.messageService.add({
      severity: 'success', key: 'messageLinnks',
      summary: 'MSG: ',
      detail: 'Edição do marcador cancelada!'
    }
    );
  }
  salvarEtiquetas() {
    // VALIDANDO EDIÇÃO DA ETIQUETA
    if (this.etiqueta.id !== undefined) {
      this.httpService
        .put('/todoetiquetas', this.etiqueta.id, this.etiqueta)
        .subscribe(
          () => {
            const index = this.etiquetaList.findIndex(
              i => i.id === this.etiqueta.id
            );
            this.etiquetaList.splice(index, 1, this.etiqueta);
            // reiniciando variaveis do modal
            const etiq = new TodoEtiquetas(this.etiqueta);
            this.etiqueta = new TodoEtiquetas();
            this.etiqueta.todoProjeto = new TodoProjeto();
            this.etiqueta.todoProjeto.id = etiq.todoProjeto.id;
            this.etiqueta.usuario = new AuthUser();
            this.etiqueta.usuario.id = this.sessionService.loggedUser.id;
            this.etiqueta.corBg = '#0091ea';
            this.msgsModal = [
              {
                severity: 'success', key: 'messageLinnks',
                summary: 'MSG: ',
                detail: 'Marcador alterado com sucesso!'
              }
            ];
            this.msgSocket(this.todoProjetoId);

            // this.loadEtiquetas();
          },
          error =>
            this.httpService.handleError(error, () => this.salvarEtiquetas()),
          () => this.httpService.done()
        );
    } else {
      // SE NÃO TIVER ID É UMA NOVA.
      if (this.etiqueta.nome === undefined || ((this.etiqueta.nome).trim()).length === 0) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'Advertência: ',
          detail: 'Nome do marcador é obrigatório!'
        });
        return;
      }

      this.httpService.postdb('/todoetiquetas', this.etiqueta).subscribe(
        rst => {
          this.etiqueta.id = rst.id;
          this.etiquetaList.push(this.etiqueta);
          // reiniciando variaveis do modal
          const etiq = new TodoEtiquetas(this.etiqueta);
          this.etiqueta = new TodoEtiquetas();
          this.etiqueta.todoProjeto = new TodoProjeto();
          this.etiqueta.todoProjeto.id = etiq.todoProjeto.id;
          this.etiqueta.usuario = new AuthUser();
          this.etiqueta.usuario.id = this.sessionService.loggedUser.id;
          this.etiqueta.corBg = '#0091ea';
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Marcador salvo com sucesso!'
          }
          );
          // this.loadEtiquetas();
        },
        error =>
          this.httpService.handleError(error, () => this.salvarEtiquetas()),
        () => this.httpService.done()
      );
    }
  }

  private loadSeguirCard() {
    this.seguirCardList = [];

    this.seguirCardList = [
      { label: 'Mover', value: this.getSeguirCardValue() },
      { label: 'Assinar', value: this.getSeguirCardValue() },
      { label: 'Recusar', value: this.getSeguirCardValue() },
      { label: 'Clonar', value: this.getSeguirCardValue() },
      { label: 'Arquivar', value: this.getSeguirCardValue() },
      { label: 'Desarquivar', value: this.getSeguirCardValue() },
      { label: 'Edição de título', value: this.getSeguirCardValue() },
      { label: 'Edição de descrição', value: this.getSeguirCardValue() },
      { label: 'Inclusão de checklist', value: this.getSeguirCardValue() },
      { label: 'Edição de checklist', value: this.getSeguirCardValue() },
      { label: 'Exclusão de checklist', value: this.getSeguirCardValue() },
      { label: 'Inclusão de atividade no checklist', value: this.getSeguirCardValue() },
      { label: 'Edição de atividade no checklist', value: this.getSeguirCardValue() },
      { label: 'Marcação de atividade no checklist', value: this.getSeguirCardValue() },
      { label: 'Exclusão de atividade no checklist', value: this.getSeguirCardValue() },
      { label: 'Alteração de requisitante', value: this.getSeguirCardValue() },
      { label: 'Alteração de responsável', value: this.getSeguirCardValue() },
      { label: 'Inclusão de prazo', value: this.getSeguirCardValue() },
      { label: 'Exclusão de prazo', value: this.getSeguirCardValue() },
      { label: 'Inclusão de comentário', value: this.getSeguirCardValue() },
      { label: 'Inclusão de anexo', value: this.getSeguirCardValue() },
      { label: 'Exclusão de anexo', value: this.getSeguirCardValue() },
    ];
  }

  private loadListSeguindo() {
    this.segCard = [];



    let mapSeguindo = this.indexCurrentCard.partCardRelacAtiv;

    mapSeguindo.movimentar ? this.segCard.push(1) : "";
    mapSeguindo.assinar ? this.segCard.push(2) : "";
    mapSeguindo.recusar ? this.segCard.push(3) : "";
    mapSeguindo.clonar ? this.segCard.push(4) : "";
    mapSeguindo.archive ? this.segCard.push(5) : "";
    mapSeguindo.unarchive ? this.segCard.push(6) : "";
    mapSeguindo.editTitulo ? this.segCard.push(7) : "";
    mapSeguindo.editDescricao ? this.segCard.push(8) : "";
    mapSeguindo.createChecklist ? this.segCard.push(9) : "";
    mapSeguindo.editChecklist ? this.segCard.push(10) : "";
    mapSeguindo.deleteChecklist ? this.segCard.push(11) : "";
    mapSeguindo.createChecklistAtividade ? this.segCard.push(12) : "";
    mapSeguindo.editChecklistAtividade ? this.segCard.push(13) : "";
    mapSeguindo.checkChecklistAtividade ? this.segCard.push(14) : "";
    mapSeguindo.deleteChecklistAtividade ? this.segCard.push(15) : "";
    mapSeguindo.editRequisitante ? this.segCard.push(16) : "";
    mapSeguindo.editResponsavel ? this.segCard.push(17) : "";
    mapSeguindo.createPrazo ? this.segCard.push(18) : "";
    mapSeguindo.deletePrazo ? this.segCard.push(19) : "";
    mapSeguindo.insertComentario ? this.segCard.push(20) : "";
    mapSeguindo.insertAnexo ? this.segCard.push(21) : "";
    mapSeguindo.deleteAnexo ? this.segCard.push(22) : "";
  }

  /**
   * definição automatica da propriedade 'value' da lista: segCardList
   */
  private getSeguirCardValue() { this.seguirCardValue++; return this.seguirCardValue; }

  seguirCard(seguir: boolean) {
    this.seguirCardValue = 0;

    const map = {
      idUsuario: this.sessionService.loggedUser.id,
      idTodoCard: this.indexCurrentCard.idTabela,
      seguindo: seguir,

      movimentar: seguir ? (this.segCard.find(f => f === 1) && this.todoCard.usuario.id != this.sessionService.loggedUser.id ? true : false) : false,
      assinar: seguir ? (this.segCard.find(f => f === 2) ? true : false) : false,
      recusar: seguir ? (this.segCard.find(f => f === 3) ? true : false) : false,
      clonar: seguir ? (this.segCard.find(f => f === 4) ? true : false) : false,
      archive: seguir ? (this.segCard.find(f => f === 5) ? true : false) : false,
      unarchive: seguir ? (this.segCard.find(f => f === 6) ? true : false) : false,
      editTitulo: seguir ? (this.segCard.find(f => f === 7) ? true : false) : false,
      editDescricao: seguir ? (this.segCard.find(f => f === 8) ? true : false) : false,
      createChecklist: seguir ? (this.segCard.find(f => f === 9) ? true : false) : false,
      editChecklist: seguir ? (this.segCard.find(f => f === 10) ? true : false) : false,
      deleteChecklist: seguir ? (this.segCard.find(f => f === 11) ? true : false) : false,
      createChecklistAtividade: seguir ? (this.segCard.find(f => f === 12) ? true : false) : false,
      editChecklistAtividade: seguir ? (this.segCard.find(f => f === 13) ? true : false) : false,
      checkChecklistAtividade: seguir ? (this.segCard.find(f => f === 14) ? true : false) : false,
      deleteChecklistAtividade: seguir ? (this.segCard.find(f => f === 15) ? true : false) : false,
      editRequisitante: seguir ? (this.segCard.find(f => f === 16) ? true : false) : false,
      editResponsavel: seguir ? (this.segCard.find(f => f === 17) ? true : false) : false,
      createPrazo: seguir ? (this.segCard.find(f => f === 18) ? true : false) : false,
      deletePrazo: seguir ? (this.segCard.find(f => f === 19) ? true : false) : false,
      insertComentario: seguir ? (this.segCard.find(f => f === 20) ? true : false) : false,
      insertAnexo: seguir ? (this.segCard.find(f => f === 21) ? true : false) : false,
      deleteAnexo: seguir ? (this.segCard.find(f => f === 22) ? true : false) : false,
    };
    this.indexCurrentCard.seguindo = map.seguindo;

    // Atualiza o banco;
    // ------------------------------------------
    this.httpService
      .post('/custom/workflow/seguir-card', map)
      .subscribe(
        rst => '',
        error => this.httpService.handleError(error, () => this.seguirCard(seguir)),
        () => this.httpService.done()
      );

    this.getListas('ctrl');
    this.displaySeguirCardModal = 'none';
    this.loadSeguirCard();
    this.loadListSeguindo();
  }


  private setIndexCurrentPainel(idPainel: any) {
    this.indexCurrentPainel = this.listas.find(
      l => l.id.toString() === idPainel.toString()
    );
  }

  private setIndexCurrentCard(idPainel: any, idCard: any) {
    const indexPainel = this.listas.findIndex(
      l => l.id.toString() === idPainel.toString()
    );
    const indexCard = this.listas[indexPainel].subitems.find(
      l => l.id.toString() === idCard.toString()
    );

    if (indexCard !== undefined) {
      this.indexCurrentCard = this.listas[indexPainel].subitems.find(
        l => l.id.toString() === idCard.toString()
      );
    }

    /** relaçao de textArea dos checklists ativo ou não*/
    if (this.modalRef === undefined) {
      let i = 0;
      this.indexCurrentCard.checkLists.forEach(l => {
        this.displayTexAtiv[i] = true;
        i++;
      });
    }

    if (this.validaLista) {
      // console.log('update');
      this.textTitulo = this.indexCurrentCard.id;
      this.textCartao = this.indexCurrentCard.name;
      // this.textDescricao = this.indexCurrentCard.descricao;
    }
  }

  updatePainel(event: any) {
    // Atualiza a lista local;
    // ------------------------------------------
    this.setIndexCurrentPainel(event);
    this.indexCurrentPainel.name = Util.removeCaracteresCnpj(document.getElementById(event).innerHTML).replace(/<(.|\n)*?>/g, '');

    // Atualiza o banco;
    // ------------------------------------------
    const map = {
      id: this.indexCurrentPainel.idTabela,
      titulo: this.indexCurrentPainel.name.trim()
    };

    this.httpService.post('/custom/workflow/update-painel', map).subscribe(
      rst => {
        this.msgSocket(this.todoProjetoId);
      },
      error =>
        this.httpService.handleError(error, () => this.updatePainel(event)),
      () => this.httpService.done()
    );
    setTimeout(() => {
      this.validaLista = true;
      this.listasMov = true;
      this.startReload();
    }, 1000);
  }

  private updateCard() {
    this.sub.unsubscribe();
    this.validaLista = false;

    // Atualiza a lista local;
    // ------------------------------------------
    // this.indexCurrentCard.name = this.textCartao;
    // this.indexCurrentCard.descricao = this.textDescricao;

    // Atualiza o banco;
    // ------------------------------------------
    const map = {
      id: this.indexCurrentCard.idTabela,
      titulo: this.textCartao,
      descricao: this.textDescricao
    };

    this.httpService.post('/custom/workflow/update-card', map).subscribe(
      rst => {
        this.validaLista = true;
        this.msgSocket(this.todoProjetoId);
      },
      error => this.httpService.handleError(error, () => this.updateCard()),
      () => this.httpService.done()
    );
  }

  updateIndexPaineis() {
    this.listasMov = false;
    this.sub.unsubscribe();

    const map = [];
    let count = 0;

    this.listas.forEach(lista => {
      count++;

      if (this.listas.length !== count) {
        lista.final = false;
      } else {
        lista.final = true;
      }
      lista.id = count;

      map.push({
        id: lista.idTabela,
        posicao: lista.id,
        final: lista.final,
        changeUserId: this.sessionService.loggedUser.id
      });
    });

    this.httpService
      .post('/custom/workflow/update-index-paineis', map)
      .subscribe(
        rst => {
          /*this.listasMov = true;
          this.startReload();*/
          this.msgSocket(this.todoProjetoId);
        },
        error =>
          this.httpService.handleError(error, () => this.updateIndexPaineis()),
        () => this.httpService.done()
      );
  }

  private updateIndexCards(idLista: any, target: boolean, confirm: boolean, verifAlcada: boolean) {
    this.listasMov = false;
    this.sub.unsubscribe();

    this.setIndexCurrentPainel(idLista);

    let cardMov = null;
    let listaIniId;
    let listaFinId;
    //let mesmoPainel = false;

    if (target) {
      // card movido;
      // ----------------------------------------
      cardMov = this.indexCurrentPainel.subitems.find(
        f => f.idPainel.toString() !== this.idListaFin.toString()
      );
      // console.log('\n--> CARD MOVIDO: ' + JSON.stringify(cardMov));

      if (cardMov !== undefined) {
        this.insertAtividade({
          idCard: cardMov.idTabela,
          idUser: this.sessionService.loggedUser.id,
          acao: 'MENSAGEM_WF_MOVIMENTACAO',
          listaIni: this.listas.find(l => l.id.toString() === this.idListaIni.toString()).name,
          listaFin: this.listas.find(l => l.id.toString() === this.idListaFin.toString()).name,
        });

      }

      listaIniId = this.listas.find(
        f => f.id.toString() === this.idListaIni.toString()
      ).idTabela;

      listaFinId = this.listas.find(
        f => f.id.toString() === this.idListaFin.toString()
      ).idTabela;

      // notificar aprovador;
      // ----------------------------------------
      if (this.indexCurrentPainel.aprovador !== null && cardMov !== undefined) {
        this.enviarEmailAprovador(cardMov.idTabela, listaIniId, listaFinId);
      }
    }

    const map = [];
    let count = 0;

    this.indexCurrentPainel.subitems.forEach(card => {
      count++;

      card.idPainel = idLista;
      card.posicao = count;

      if (cardMov === null || cardMov === undefined) {
        cardMov = false;
      } else if (card.idTabela === cardMov.idTabela) {
        cardMov = true;
      }


      map.push({
        cardId: card.idTabela,
        cardMov: cardMov,
        cardPosicao: card.posicao,
        cardPainelId: this.indexCurrentPainel.idTabela,
        changeUserId: this.sessionService.loggedUser.id,
        listaIniId: listaIniId,
        listaFinId: listaFinId,
        confirm: confirm,
        verifAlcada: verifAlcada
      });
    });

    if (count === 0) {
      this.indexCurrentPainel.subitems = [];
      // verificando se esta no mesmo projeto
    } else if (
      this.todoProjetoId.toString() ===
      this.indexCurrentPainel.idProjeto.toString()
    ) {
      this.httpService
        .post('/custom/workflow/update-index-cards', map)
        .subscribe(
          () => {
            this.msgSocket(this.todoProjetoId);
            // console.log('update index cards');
            this.calculandoAltura();
            this.listasMov = true;
            this.getListas('ctrl');
            //this.startReload();
          },
          error =>
            this.httpService.handleError(error, () =>
              this.updateIndexCards(idLista, target, confirm, verifAlcada)
            ),
          () => this.httpService.done()
        );
    }
  }
  // ============================================

  validandoListas() {
    if (this.listasMov) {
      // console.log(
      //   '\n são iguais? -->' + Util.equals(this.listasCtrl, this.listas)
      // );
      if (!Util.equals(this.listasCtrl, this.listas)) {
        if (this.validaLista) {
          // verifica se o sistema está pronto para atualizar a lista
          this.listas = this.listasCtrl;
          if (this.indexCurrentCard !== undefined) {
            try {
              this.setIndexCurrentCard(
                this.indexCurrentCard.idPainel,
                this.indexCurrentCard.id
              );
            } catch (error) {
              // console.log('\n--> error: ' + JSON.stringify(error));
            }
          }
        } else {
          // console.log('reload');
          this.startReload(); // variavel não está pronta para atualizar, inicia o timer novamente
        }
      }
    }
  }

  private startReload() {
    if (this.timer) {
      this.sub.unsubscribe();
      this.sub.unsubscribe();
    }
    this.timer = Observable.timer(1000, 1000);
    this.sub = this.timer.subscribe(t => this.monitoraSocket());

  }


  async sleep(forHowLong) {
    function timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    await timeout(forHowLong);
  }

  public initControlsFroala;
  public deleteAllFroala;
  public initialize(initControls) {
    this.textComentario = '';
    this.initControlsFroala = initControls;
    this.deleteAllFroala = function () {
      // this.initControlsFroala.getEditor()('html.set', '');
      this.initControlsFroala.getEditor()('undo.reset');
      this.initControlsFroala.getEditor()('undo.saveStep');
    };
  }

  // preenche a lista de sugestões;
  private fillSugestoes(list: Array<any>, listKey: string) {
    this.listSugestoes = [];
    let opcoes = {};
    let opcoesAnexos = {};

    switch (listKey) {

      case 'participantes':
        // $('textAreaThreeSize').FroalaEditor();
        this.optionsFroola = {};

        list.forEach(element => {
          this.listSugestoes.push({ label: element.responsavel, value: element.id });
        });
        opcoes = list.map(function (currentValue, index, arr) { return currentValue.responsavel });
        opcoesAnexos = this.indexCurrentCard.anexos.map(function (currentValue, index, arr) { return currentValue.nome });

        FroalaEditor.DefineIcon('insertUser', { NAME: 'at' });
        FroalaEditor.RegisterCommand('insertUser', {
          title: 'Mencionar Participante',
          type: 'dropdown',
          focus: false,
          undo: false,
          refreshAfterCallback: true,
          options: opcoes,
          callback: function (cmd, val) {
            this.html.insert('<span style="color:#1155CC;text-decoration:underline;">@' + opcoes[val] + '</span> ');
          }
        });
        FroalaEditor.DefineIcon('insertAttach', { NAME: 'paperclip' });
        FroalaEditor.RegisterCommand('insertAttach', {
          title: 'Mencionar anexo',
          type: 'dropdown',
          focus: false,
          undo: false,
          refreshAfterCallback: true,
          options: opcoesAnexos,
          callback: function (cmd, val) {
            this.html.insert('<span style="color:#1155CC;text-decoration:underline;">#' + opcoesAnexos[val] + '</span>&nbsp;<span>&nbsp;</span>');
          }
        });
        this.optionsFroola = {
          language: 'pt_br',
          pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
            'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
            'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
            'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
          charCounterCount: false,
          toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', '|',
            'insertImage', 'insertLink', 'insertVideo', '|', 'insertTable', 'formatOL', 'formatUL', '|',
            '|', 'insertUser', 'insertAttach', 'undo', 'redo', 'clearFormatting'],
          imageUploadMethod: 'POST',
          key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

          // Set max image size to 5MB.
          imageMaxSize: 5 * 1024 * 1024,

          // somente upload PNG and JPG.
          imageAllowedTypes: ['jpeg', 'jpg', 'png'],
          imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
          imageStyles: { class1: 'Class 1', class2: 'Class 2' },
          imageEditButtons: ['imageReplace', 'imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],

          events: {
            'froalaEditor.initialized': function (e) {
              console.log('froalaEditor cometario - > inicializado');
            },
            /*'froalaEditor.buttons.refresh': function (e, editor) {
              // Do something here.
              console.log('froalaEditor - > inicializado' + JSON.stringify(editor) + ' | ' + JSON.stringify(e));
            },*/
            'froalaEditor.image.beforeUpload': function (e, editor, images) {
              if (images.length) {
                // Criando um File Reader.
                const reader = new FileReader();
                // Configure o leitor para inserir imagens quando elas estiverem carregadas.
                reader.onload = (ev) => {
                  const result = ev.target['result'];
                  editor.image.insert(result, null, null, editor.image.get());
                  // console.log('Saída do leitor de arquivos' + ev,  editor.image,  ev.target['result']);
                };
                // Lendo imagem as base64.
                reader.readAsDataURL(images[0]);
              }
              // Pare o upload padrão.
              return false;
            }
          }
        };
        // ==========================================
        break;

      default:
        break;
    }
  }

  private buscaUsersMencionados(arg: string, listKey: string) {
    switch (listKey) {
      case 'participantes':
        this.listSugestoes.forEach(element => {
          if (arg.includes('@' + this.codificarHtml(element.label))) {
            console.log(arg.includes('@' + element.label));
            this.enviarEmailUserMencionado(element.value, arg);
          }
        });
        break;

      default:
        break;
    }
  }

  private enviarEmailUserMencionado(userDestId: number, comentario: string) {
    this.httpService
      .post('/custom/workflow/enviar-email-usuario-mencionado',
        {
          idCard: this.indexCurrentCard.idTabela,
          idUser: this.sessionService.loggedUser.id,
          idUserDest: userDestId,
          comentario: comentario
        }
      )
      .subscribe(
        () => { },
        error =>
          this.httpService.handleError(error, () =>
            this.enviarEmailUserMencionado(userDestId, comentario)
          ),
        () => this.httpService.done()
      );
  }

  // user logado é:
  // - Adm ?
  // - Participante ?
  // - Requisitante ?
  // - Responsavel ?
  enableCardView(card: any) {
    let check = false;

    // user adm;
    if (this.userAdm) {
      check = true;
    }

    // lista de participantes do card;
    card.participantes.forEach(participante => {
      if (participante.id.toString() === this.sessionService.loggedUser.id.toString()) {
        check = true;
      }
    });

    // requisitante do card;
    if (card.requisitanteId !== null && card.requisitanteId.toString() === this.sessionService.loggedUser.id.toString()) {
      check = true;
    }

    // responsavel do card;
    if (card.responsavelId !== null && card.responsavelId.toString() === this.sessionService.loggedUser.id.toString()) {
      check = true;
    }

    return check;
  }

  // define o id do card no html (rotas);
  getCardId(card: any) {
    return card.id;
  }

  salvarCategoria() {
    const indexCategoriaNome = this.categoriaList.findIndex(f => f.label === this.categoriaNome);

    // se a categoria não existir no diretorio...
    // ------------------------------------------
    if (indexCategoriaNome === -1) {
      const categoriaMap = { label: this.categoriaNome, value: null };

      // editar;
      // editar;
      // editar;
      if (this.categoriaId !== null) {
        const indexCategoriaId = this.categoriaList.findIndex(f => f.value.toString() === this.categoriaId.toString());
        categoriaMap.value = this.categoriaId;
        this.categoriaList.splice(indexCategoriaId, 1, categoriaMap);

        this.httpService.post('/custom/workflow/edita-pasta-categoria',
          {
            idProjeto: this.todoProjetoId,
            categoriaOld: this.categoriaId,
            categoria: categoriaMap.value + ' - ' + categoriaMap.label
          }
        )
          .subscribe(
            () => { },
            error =>
              this.httpService.handleError(error, () =>
                this.salvarCategoria()
              ),
            () => this.httpService.done()
          );
      } else {
        // salvar;
        // salvar;
        // salvar;
        categoriaMap.value = this.getCategoriaId() + 1;
        this.categoriaList.push(categoriaMap);

        this.httpService.post('/custom/workflow/salva-pasta-categoria',
          {
            idProjeto: this.todoProjetoId,
            categoria: categoriaMap.value + ' - ' + categoriaMap.label
          }
        )
          .subscribe(
            () => { },
            error =>
              this.httpService.handleError(error, () =>
                this.salvarCategoria()
              ),
            () => this.httpService.done()
          );
      }

      this.categoriaNome = '';
      this.categoriaId = null;

      // se existir...
      // ------------------------------------------
    } else {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'A categoria "' + this.categoriaNome + '" já consta na lista de categorias.'
      });
    }
  }

  editarCategoria(event: any) {
    this.categoriaNome = event.label;
    this.categoriaId = event.value;
  }

  excluirCategoria(event: any) {
    const index = this.categoriaList.findIndex(f => f.value.toString() === event.value.toString());

    // verifica se a pasta não esta vazia !
    let check = false;
    this.listas.forEach(lista => {
      lista.subitems.forEach(card => {
        card.anexos.forEach(anexo => {
          if (anexo.categoria === (event.value + ' - ' + event.label)) {
            check = true;
          }
        });
      });
    });

    if (!check) {
      this.httpService.post('/custom/workflow/exclui-pasta-categoria',
        {
          idProjeto: this.todoProjetoId,
          categoria: event.value + ' - ' + event.label
        }
      )
        .subscribe(
          () => {
            this.categoriaList.splice(index, 1);
          },
          error =>
            this.httpService.handleError(error, () =>
              this.salvarCategoria()
            ),
          () => this.httpService.done()
        );
    } else {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'A categoria "' + event.label + '" possui anexos e não pode ser excluida.'
      });
    }
  }

  // retorna o último id da lista: categoriaList
  private getCategoriaId() {
    let id = 0;
    this.categoriaList.forEach(element => {
      if (element.value > id) {
        id = element.value;
      }
    });
    return id;
  }

  private getCategoriaSelected() {
    let categoria;

    if (this.categoriaId !== null) {
      const obj = this.categoriaList.find(f => f.value.toString() === this.categoriaId.toString());
      categoria = obj.value + ' - ' + obj.label;
    } else {
      categoria = '0 - Sem categoria';
    }

    return categoria;
  }
  /** Bootstrap Modal 8 - Anexo *
  openModalAnexo() {
    this.displayAnexoModal = 'block';
    setTimeout(() => this.enableInput = true, 250);

    if (this.categoriaList.length === 0) {
      setTimeout(() => document.getElementById('file-upload').click(), 500);
    }
  }
  /** Bootstrap Modal 8 - final - Anexo **/

  openAnexoSelect() {
    this.enableInput = true;
    setTimeout(() => document.getElementById('file-upload').click(), 500);
  }

  /** Bootstrap Modal 9 - Categoria anexo - DragAndDrop **/
  openModalDrop() {
    if (this.categoriaList.length === 0) {
      this.categoriaNome = ((this.categoriaNome === 'SemCateg0ri4') ? '' : 'SemCateg0ri4');
    } else {
      this.displayCategoriaAnexo = 'block';
    }
  }
  /** Bootstrap Modal 9 - final - Anexo **/

  selecionarCategoriaAnexo() {
    this.displayCategoriaAnexo = 'none';
    this.categoriaNome = this.getCategoriaSelected().split(' - ')[1];
  }

  onDrop(event: DndDropEvent) {
    this.lastDropEvent = event;
    this.dragDropChange(this.lastDropEvent.event);
    /* this.lastDropTypes = this.lastDropEvent.event.dataTransfer.types;
    this.lastDropFiles = [];
    for( let i = 0; i < this.lastDropEvent.event.dataTransfer.files.length; i++ ) {
      const file = this.lastDropEvent.event.dataTransfer.files[ i ];
      console.log(this.lastDropEvent.event);
      this.lastDropFiles.push( {
        name: file.name,
        type: file.type,
        size: file.size,
      } );
    }
    this.anexoChange(this.lastDropEvent.event);
//    this.lastDropItems = [];

    for ( let i = 0; i < this.lastDropEvent.event.dataTransfer.items.length; i++ ) {
          const item = this.lastDropEvent.event.dataTransfer.items[ i ];
          this.lastDropItems.push( {
            type: item.type,
            kind: item.kind,
            data: this.lastDropEvent.event.dataTransfer.getData( item.type )
        } );
    }*/
  }

  // ==========
  dragDropChange(event) {
    this.file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    this.stopGetListas();

    // Atualiza a lista local;
    // ------------------------------------------
    this.indexCurrentCard.anexos.push({ nome: this.file.name, categoria: this.getCategoriaSelected() });

    this.insertAtividade({
      idCard: this.indexCurrentCard.idTabela,
      idUser: this.sessionService.loggedUser.id,
      acao: 'MENSAGEM_WF_INSERT_ANEXO',
      anexo: this.file.name,
      anexoCategoria: this.getCategoriaSelected().split(' - ')[1]
    });

    // Envia o anexo para o dir default do tomcat;
    // ------------------------------------------
    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('type', 'workflow');
    data.append('projetoId', this.todoProjetoId);
    data.append('categoria', this.getCategoriaSelected());
    data.append('cardId', this.indexCurrentCard.idTabela);
    data.append('fileName', this.file.name);

    this.httpService.post('/custom/upload', data).subscribe(
      () => {
        if (this.categoriaList.length === 0) {
          this.categoriaNome = 'SemCateg0ri4';
          /** destruir froola */
          document.getElementById('textAreaThree').style.display = 'none';
          document.getElementById('divTextAreaThree').style.display = 'inline-block';
          // this.initControlsFroala.getEditor()('html.set', '');
          // this.initControlsFroala.destroy();
          this.fillSugestoes(this.indexCurrentCard.participantes, 'participantes');
          /** final destruir. */
          this.startGetListas();
        }
      },
      error =>
        this.httpService.handleError(error, () => this.dragDropChange(event)),
      () => this.httpService.done()
    );
  }

  codificarHtml(texto) {
    const htmlCodigo = {
      'á': { 'code': '&aacute;' },
      'Á': { 'code': '&Aacute;' },
      'ã': { 'code': '&atilde;' },
      'Ã': { 'code': '&Atilde;' },
      'à': { 'code': '&agrave;' },
      'À': { 'code': '&Agrave;' },
      'é': { 'code': '&eacute;' },
      'É': { 'code': '&Eacute;' },
      'í': { 'code': '&iacute;' },
      'Í': { 'code': '&Iacute;' },
      'ó': { 'code': '&oacute;' },
      'Ó': { 'code ': '&Oacute;' },
      'õ': { 'code': '&otilde;' },
      'Õ': { 'code': '&Otilde;' },
      'ú': { 'code': '&uacute;' },
      'Ú': { 'code': '&Uacute;' },
      'ç': { 'code': '&ccedil;' },
      'Ç': { 'code': '&Ccedil;' }
    };
    const acentos = ['á', 'Á', 'ã', 'Ã', 'à', 'À', 'é', 'É', 'í', 'Í', 'ó', 'Ó', 'õ', 'Õ', 'ú', 'Ú', 'ç', 'Ç'];
    for (let i = 0; i < acentos.length; i++) {
      if (htmlCodigo[acentos[i]] !== undefined) {
        texto = texto.toString().replace(acentos[i], htmlCodigo[acentos[i]].code);
      }
    }
    return texto;
  }

  loadOwner(id: number) {
    this.mepOwner = 0;
    this.mepOwnerList = [];
    this.mepOwnerList.push({ label: 'Selecione', value: 0 });

    this.httpService
      .get(
        '/todoprojetousuarios?$select=usuario/id,usuario/fullName,administrador&$filter=todoProjeto/id eq ' +
        this.todoProjetoId
      )
      .subscribe(
        rst2 => {
          rst2.value.forEach(obj => {
            this.mepOwnerList.push({
              label: obj.usuario.fullName,
              value: obj.usuario.id
            });
          });
          this.mepOwner = id;
        },

        error =>
          this.httpService.handleError(error, () => this.loadOwner(id)),
        () => this.httpService.done()
      );
  }

  loadOrdenacao(id: number) {
    this.mepOrdenacao = 0;
    this.mepOrdenacaoList = [];
    this.mepOrdenacaoList.push({ label: 'Selecione', value: 0 });

    this.httpService.get('todoordenacao?$select=id,descricao&$orderby=id')
      .subscribe(
        rst2 => {
          rst2.value.forEach(obj => {
            this.mepOrdenacaoList.push({
              label: obj.descricao == "Id" ? 'Key' : obj.descricao, // key = id
              value: obj.id
            });
          });
          this.mepOrdenacao = id;
        },

        error =>
          this.httpService.handleError(error, () => this.loadOrdenacao(id)),
        () => this.httpService.done()
      );
  }

  alterarPrioridade() {
    const map = {
      id: this.indexCurrentCard.idTabela,
      prioridade: this.todoCard.prioridade.id,
      nome: this.todoCard.prioridade.nome,
      campo: 'prioridade'
    };

    this.httpService.post('/custom/workflow/update-card-key', map).subscribe(
      rst => {

        this.displayPrioridadeModal = 'none';
        this.indexCurrentCard.prioridadeId = this.todoCard.prioridade.id;
        this.indexCurrentCard.prioridade = this.todoCard.prioridade.nome;

        this.insertAtividade({
          idCard: this.indexCurrentCard.idTabela,
          idUser: this.sessionService.loggedUser.id,
          acao: 'MENSAGEM_WF_EDIT_PRIORIDADE',
          prioridade: this.todoCard.prioridade.id,
          nome: map.nome
        });
      },
      error =>
        this.httpService.handleError(error, () => this.alterarPrioridade()),
      () => this.httpService.done()
    );

  }

  openModalPrioridade(content) {
    this.displayPrioridadeModal = 'block';

    this.todoCard = new TodoCard();
    this.todoCard.id = this.indexCurrentCard.id;
    // responsavel
    this.todoCard.usuario = new AuthUser();
    this.todoCard.usuario.id = null;
    this.todoCard.responsavel = new AuthUser();
    this.todoCard.responsavel.id = null;
    this.todoCard.responsavelSub = new AuthUser();
    this.todoCard.responsavelSub.id = null;
    this.todoCard.prioridade = new TodoPrioridade();
    this.todoCard.prioridade.id = this.indexCurrentCard.prioridadeId;
    this.todoCard.prioridade.nome = this.indexCurrentCard.prioridade;
    this.todoCard.rms = new Requisicao();
    this.todoCard.pedido = new Pedido();
    this.todoCard.nfeFin = new NfeFin();
  }

  loadPrioridade() {
    this.listaPrioridade = [];

    this.httpService.get('todoprioridade').subscribe(
      rst2 => {
        rst2.value.forEach(obj => {
          this.listaPrioridade.push({
            label: obj.nome,
            value: obj.id
          });
        })
      },

      error =>
        this.httpService.handleError(error, () => this.loadPrioridade()),
      () => this.httpService.done()
    );
  }

  seguirCardRequisitante(idCard: number) {
    this.seguirCardValue = 0;

    const map = {
      idUsuario: this.sessionService.loggedUser.id,
      idTodoCard: idCard,
      seguindo: true,
      movimentar: true,
      assinar: false,
      recusar: false,
      clonar: false,
      archive: false,
      unarchive: false,
      editTitulo: false,
      editDescricao: false,
      createChecklist: false,
      editChecklist: false,
      deleteChecklist: false,
      createChecklistAtividade: false,
      editChecklistAtividade: false,
      checkChecklistAtividade: false,
      deleteChecklistAtividade: false,
      editRequisitante: false,
      editResponsavel: false,
      createPrazo: false,
      deletePrazo: false,
      insertComentario: false,
      insertAnexo: false,
      deleteAnexo: false
    };

    // Atualiza o banco;
    // ------------------------------------------
    this.httpService
      .post('/custom/workflow/seguir-card', map)
      .subscribe(
        rst => '',
        error => this.httpService.handleError(error, () => this.seguirCardRequisitante(idCard)),
        () => this.httpService.done()
      );

    this.displaySeguirCardModal = 'none';
  }

  private calculandoAltura() {
    this.alturaDaMaiorLista = 0;
    this.idDaMaiorLista = 0;

    this.listas.forEach(lista => {
      lista.subitems.name = String(lista.subitems.name).substring(0, 10) + "...";
      if (lista.subitems.length > this.alturaDaMaiorLista) {
        this.alturaDaMaiorLista = lista.subitems.length;
        this.idDaMaiorLista = lista.id;
      }
    });

    this.alturaDaMaiorLista *= 181; //Tamanho fixo do card
  }

  imprimirCartao() {
    this.httpService.wait();
    this.httpService.get('/custom/workflow/impressao-card(' + this.indexCurrentCard.idTabela + ',' +
      this.ocultarComents + ',' + this.ocultarAtiv + ')')
      .subscribe(result => this.exportedCard(result),
        error => this.httpService.handleError(error, () => this.imprimirCartao()),
        () => this.httpService.done());
  }

  exportedCard(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/wf_card'), '_blank');
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result.message });
    }
  }

  arquivarTodos(event: any) {
    if (confirm('Deseja arquivar todos os cards desta lista?')) {
      this.httpService.post('/custom/workflow/arquivar-todos', {
        id: event.idTabela,
        changeUserId: this.sessionService.loggedUser.id
      })
        .subscribe(
          () => { },
          error =>
            this.httpService.handleError(error, () => this.arquivarTodos(event)),
          () => this.httpService.done()
        );
      this.updateIndexCards(event.id, false, true, false);
    }
  }

  limparFiltro() {
    this.mepContem = '';
    this.filtro.requisitantes = [];
    this.filtro.prioridades = [];
    this.filtro.responsaveis = [];
    this.filtro.marcadores = [];
    this.filtro.contem = String;
    this.filtroVazio = true;
    this.mepRequisitante = [];
    this.mepPrioridade = [];
    this.mepResponsavel = [];
    this.mepMarcador = [];
    this.displayFiltroAvancado = 'none';
    this.getListas('ctrl');
  }

  msgSocket(projId: number) {
    this.loginService.doWfAcao(projId);
  }

  private monitoraSocket() {

    let data;
    this.messagesSubscription = this.socketService.messages
      .subscribe(data => {

        switch (data.action) {
          case 'login':
            if (data.loggedUserId.toString().indexOf('-') > 0) {

              this.projId = data.loggedUserId.split('-')[0];

            }
            break;
        }
      });

    if (this.projId !== undefined) {
      if (this.projId === this.todoProjetoId) {
        this.getListas('ctrl');
        this.projId = 0;
      }
    }
  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPageLarge;
  }
  get rows() {
    return this.configService.applicationConfig.rowsLarge;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinksLarge;
  }


  showDashboardScreen() {
    const { name, code, inactive }: any = this.selectedScreen !== undefined || null ? this.selectedScreen : 'KB';

    if (code !== undefined || null) {

      this.dashboardScreen1 = !this.dashboardScreen1;
      this.dashboardScreen2 = !this.dashboardScreen2;

      if (code === 'TB') {

        this.asideInformation = [];
        this.cloneAside = this.asideInformation

        let list = [];

        this.listas.map(item => {

          return [...item.subitems.map(
            subItems => {

              let av = subItems.requisitante.split(" ");
              av = av[0].substr(0, 1) + av[av.length - 1].substr(0, 1);

              return list.push({
                listName: item.name,
                cardName: subItems.name,
                responsible: subItems.responsavel,
                deadline: subItems.dataFim,
                priority: subItems.prioridade,
                requester: subItems.requisitante,
                responsibleId: subItems.responsavelId,
                id: subItems.id,
                requesterAvatar: av,
                selected: false,
                showExtendedItens: false,
                avatar: subItems.avatar
              })
            }
          )];
        });

        this.tableData = list;

        setTimeout(() => {
          const RefDiv = document.getElementById("shadowBox");
          let height = document.defaultView.getComputedStyle(RefDiv, null).getPropertyValue("height");
          height = height.substr(0, height.length - 2);
          this.asideStyle = +height;

          this.showHighcharts = true;
        }, 500)

      }

    } else {

      this.messageService.add({
        severity: 'error',
        key: 'messageLinnks',
        summary: 'Advertência',
        detail: "Escolha uma visualização"
      });

    };

  };

  rowsSelected: Array<any> = new Array();
  closeAside() {
    this.tableData.map(element => element.selected = false);
    this.selectedAllTable = false;
    this.asideInformation = [];
    this.cloneAside = this.asideInformation
    this.rowsSelected = []
    this.statusTarefas = true
    this.statusNotificacoes = false

    setTimeout(() => {
      this.showHighcharts = false;
      this.showHighcharts = true;
    }, 100)
  }

  ShowHIdeCardMov() {
    this.isCardMov = !this.isCardMov;
  }

  changeStateMov() {
    this.currentStateMov = this.currentStateMov === 'initial' ? 'final' : 'initial';
  }

  showTelaWorkflow(tipo) {
    this.statusMovimentacao = false;
    this.statusVencidos = false;

    if (tipo === 'TELA_1') {
      this.statusMovimentacao = true;
      this.statusVencidos = false;
      // this.tab = 0;

    } else if (tipo === 'TELA_2') {
      this.statusVencidos = true;
      this.statusMovimentacao = false;
      // this.tab = 1;

    }
  }

  exportExcel(data: any, name: string) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(data.value);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, name);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  asideStyle: number = 400;

  onRowSelect(event: any) {
    this.showAside = true;

    let av = event.data.requester.split(" ");
    av = av[0].substr(0, 1) + av[av.length - 1].substr(0, 1);

    this.asideInformation.push({
      listName: event.data.listName,
      cardName: event.data.cardName,
      responsible: event.data.responsible,
      deadline: event.data.deadline,
      priority: event.data.priority,
      requester: event.data.requester,
      responsibleId: event.data.responsavelId,
      avatar: event.data.avatar,
      requesterAvatar: av,
      id: event.data.id,
      selected: event.data.selected
    });

    let filtredArray = this.asideInformation.filter(function (item) {
      return !this[JSON.stringify(item)] && (this[JSON.stringify(item)] = true);
    }, Object.create(null));

    this.asideInformation = filtredArray;

    this.showHighcharts = true;
    this.cloneAside = this.asideInformation
  }

  onRowUnselect(event: any) {
    let itemIndex;

    this.asideInformation.map((element, index) => {
      if (element.id == event.data.id) {
        itemIndex = index
      };

    });

    this.asideInformation.splice(itemIndex, 1);
    this.showHighcharts = false;
    this.showHighcharts = true;
    this.cloneAside = this.asideInformation
  }

  onSelectAll(event: any) {
    this.showAside = true;
    this.showHighcharts = false;

    setTimeout(() => {
      if (event.checked) {
        this.asideInformation = [];
        this.tableData.map(item => {
          item.selected = true;

          this.asideInformation.push({
            listName: item.listName,
            cardName: item.cardName,
            responsible: item.responsible,
            deadline: item.deadline,
            priority: item.priority,
            requester: item.requester,
            responsibleId: item.responsavelId,
            avatar: item.avatar,
            id: item.id,
            selected: item.selected,
            requesterAvatar: item.requesterAvatar
          });
        });

        this.showHighcharts = true;

      } else {
        this.tableData.map(item => {
          item.selected = false;
        });

        this.showHighcharts = false;
        this.showHighcharts = true;
        this.asideInformation = [];
      }


      let filtredArray = this.asideInformation.filter(function (item) {
        return !this[JSON.stringify(item)] && (this[JSON.stringify(item)] = true);
      }, Object.create(null));

      this.asideInformation = filtredArray;

    }, 100)

    this.cloneAside = this.asideInformation
  }

  // criar nova lista com as propriedades necessárias

  newList;

  showMoreItens(item: any, index: number) {
    item.showExtendedItens = !item.showExtendedItens;

    if (item.showExtendedItens) {
      this.httpService.wait();
      this.httpService
        .post(
          '/custom/workflow/get-listas-card', {
          cardId: item.id,
          projetoId: this.todoProjetoId
        }
        )
        .subscribe(
          rst => {
            const rst0 = rst[0];

            this.newList = {
              description: rst0.descricao == "null" || null || undefined ? 'Nenhuma descrição' : rst0.descricao,
              coments: rst0.comentarios == undefined ? null : rst0.comentarios,
              actions: rst0.actions == undefined ? null : rst0.actions,
              listName: item.listName,
              cardName: item.cardName,
              responsible: item.responsible,
              deadline: item.deadline,
              priority: item.priority,
              requester: item.requester,
              responsibleId: item.responsavelId,
              avatar: item.avatar,
              id: item.id,
              selected: item.selected,
              showExtendedItens: item.showExtendedItens,
              requesterAvatar: item.requesterAvatar
            };

            this.asideInformation[index] = this.newList;

            console.log(this.asideInformation)

          },
          error =>
            this.httpService.handleError(error, () => this.showMoreItens(item.id, index)),
          () => this.httpService.done()
        );

    }


  }

  informarCondicao(event: any) {
    console.log(event);
    this.exibirCondicao = true;
    this.condicaoSelecionada = event;

    if (isNullOrUndefined(event.condicao)) {
      this.sql = '';
    } else {
      this.sql = event.condicao;
    }
  }

  buscarViews() {

    this.httpService.wait();
    this.httpService.get('/viewcampos?$select=id,campo,descricao,tipo,view/nome&$orderby=campo')
      .subscribe(ref => this.loadViewCampos(ref.value),
        error => this.httpService.handleError(error, () => this.buscarViews()),
        () => this.httpService.done());

  }

  loadViewCampos(value: Array<ViewCampos>) {
    this.viewCampos = new Array();

    let nome: string;
    nome = 'workflow.vw_workflow';

    value.forEach(element => {
      if (element.view.nome == nome) {
        console.log(element);
        this.viewCampos.push(element);

      };

    });


  }

  onSelectViewCampo(event: any) {

    if (isNullOrUndefined(this.sql)) {
      this.sql = '';
    }

    this.sql = this.sql + ' ' + event.campo;

  }

  salvarCondicao() {
    this.condicaoSelecionada.condicao = this.sql;
    this.sql = '';
    this.exibirCondicao = false;
  }

  buscarInfoFinanceiro() {
    this.buscarFinanceiro = true;
  }

  buscarInfoPedido() {
    this.buscarPedido = true;
  }

  triggerAlcadasCondicao(rowData: any) {
    if (rowData.todoPainelIni.id !== null) {

      rowData.condicao = rowData.sql;

    }
  }

  filterAside() {
    let filter = []

    if (this.filterAsideValue.length > 0) {
      this.cloneAside.forEach(item => {
        let cardName = item.cardName.toUpperCase()
        let filterValue = this.filterAsideValue.toUpperCase()
        if (cardName.includes(filterValue)) {
          filter.push(item)
        }
      })
      this.asideInformation = filter
    } else {
      this.asideInformation = this.cloneAside
    }

  }

  statusTarefas: boolean = true
  statusNotificacoes: boolean = false

  asideChangeScreen(screen: any) {
    this.statusTarefas = false
    this.statusNotificacoes = false
    if (screen === 0) {
      this.statusTarefas = true
      this.statusNotificacoes = false
    } else if (screen === 1) {
      this.statusTarefas = false
      this.statusNotificacoes = true
    }
  }
}
