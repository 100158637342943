import { Injectable } from "@angular/core";
import { Empresa } from "app/cadastro/model/empresa.model";
import { AuthLoggedUser } from "app/proof/model/auth-logged-user.model";
import { WebSocketController } from "./WebSocketController";
import { Action } from 'app/shared/services/socket/protocol/Action'
import { Service } from "./protocol/Service";
import { Method } from "./protocol/Method";
import { SocketData } from "./protocol/SocketData";

@Injectable()
export class PysocketService extends WebSocketController{

  connect(company: Empresa,user: AuthLoggedUser) {

    this.company = company
    this.user = user
    const token = JSON.parse(localStorage.getItem('data')).authToken

    if(!this.webSocket) {
      try{
        this.webSocket = new WebSocket(`${this.baseUrl}/${this.company.id}/${this.user.id}/${false}`)

        this.webSocket.onopen = (connected) => {
          this.send({
            service: Service.LOGIN,
            action: Action.AUTH,
            method: Method.POST,
            content: { token }
          })

          console.log('Pysocket have been connected.')

          this.socketState.next(true)
        }

        this.webSocket.onmessage = (message) => {
          if(message.data.method === Method.ERROR) console.log(message)
          const comment = JSON.parse(message.data)
          this.socketData.next(comment)
        }

      }catch(error){
        console.log(error)
        this.socketState.next(false)
      }
    }
  }

  requestApproval(listTodoCardId: Array<number>, listNotificacaoId: Array<number>, userId: number, userName: string): void {
    for (let cardId of listTodoCardId) {
      const payload = {
        service: Service.APPROVAL_CENTER,
        action: Action.APPROVE,
        method: Method.POST,
        content: {
          userId: userId,
          cardId: cardId,
          userName: userName,
        }
      }
      this.send(payload)
    }

    const payload = {
      service: Service.APPROVAL_CENTER,
      action: Action.APPROVE,
      method: Method.GET,
      content: {
        listCardId: listTodoCardId
      }
    }

    this.send(payload)
    this.sendNotification(listNotificacaoId)
  }

  sendNotification(notifications: number[]){
    notifications.forEach(id =>{
      const notifye: SocketData = {
        service: Service.NOTIFICATION,
        action: Action.HISTORIC,
        method: Method.POST,
        content: {
          notificationId: id
        }
      }
      this.send(notifye)
    })
  }
}