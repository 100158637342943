import { animate, state, style, transition, trigger } from "@angular/animations";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { SkeletonModel } from "app/shared/components/skeleton/models/skeletonModel";
import { ConfigService } from "app/shared/services/config.service";
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service";
import { SessionService } from "app/shared/services/session.service";
import { TitleService } from "app/shared/services/title.service";
import { ToolbarService } from "app/shared/services/toolbar.service";
import { MessageService } from "primeng/api";
import { Subscription } from "rxjs/Subscription";


@Component({
  selector: 'espaco-cliente',
  templateUrl: './espaco-cliente.component.html',
  styleUrls: ['./espaco-cliente.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class EspacoCliente implements OnInit, OnDestroy {

  private sessionSubscription: Subscription

  httpOptions: Object = new Object()

  dt_atualizacao: Date = new Date()

  lista: Array<any> = new Array()

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'ID', textAlign: 'center' },
      { name: 'FANTASIA', textAlign: 'left' },
      { name: 'ESPAÇO BDD (MB)', textAlign: 'right' },
      { name: 'ESPAÇO BDD (%)', textAlign: 'right' },
      { name: 'ESPAÇO EC2 (MB)', textAlign: 'right' },
      { name: 'ESPAÇO EC2 (%)', textAlign: 'right' }
    ],
    hasFilter: true,
    hasSort: true
  })

  constructor(
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.titleService.title = "Espaço cliente"
    this.toolbarService.hidden = true
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      async () => {
        this.buscarRegistros()
      })
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  async buscarRegistros() {
    this.skeletonConfig.startLoad()
    try {
      await this.httpClient.get(this.configService.defaultUrlApiUpload + "log/espaco-cliente", this.httpOptions).toPromise()
        .then(res => {
          this.lista = new Array()
          res["data"].forEach(element => {
            this.lista.push(element)
            this.dt_atualizacao = new Date(element.atualizado)
          })
        })
        .catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro ao recuperar os registros, contate o suporte.' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro ao recuperar os registros, contate o suporte.' })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }
}

