export class CstIcmsTabelaB {
  id: number;
  cst: string;
  descricao: string;

  constructor(cstIcmsTabelaB?: CstIcmsTabelaB | number) {
    if (cstIcmsTabelaB !== undefined && cstIcmsTabelaB !== null) {
      if (typeof cstIcmsTabelaB === 'number') {
        this.id = cstIcmsTabelaB;
      } else {
        this.id = cstIcmsTabelaB.id;
        this.cst = cstIcmsTabelaB.cst;
        this.descricao = cstIcmsTabelaB.descricao;
      }
    }
  }
}
