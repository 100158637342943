import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Empresa } from '../model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService } from 'primeng/api';
import { isNullOrUndefined } from 'util';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-gestao-grupo-operacao',
  templateUrl: './gestao-grupo-operacao.component.html',
  styleUrls: ['./gestao-grupo-operacao.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ],
})

export class GestaoGrupoOperacaoComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = [];

  titlePrincipal: string;
  subtitle: string;

  grupos : SelectItem[];
  grupoId : number;
  listaOperacaoRegrasMapeadas : Array<any> = new Array();
  listaOperacaoRegrasAMapear : Array<any> = new Array();

  showCardBool: boolean = true;
  currentStateMov = 'initial';

  picklistState: boolean = true

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }


  data() {
    // this.dtCorte = new Date(this.timezonePipe.transform(this.dtCorte));
  }


  ngOnInit(): void {
    // requisição

    this.titlePrincipal = 'CONFIGURAÇÕES';
    this.subtitle = 'Relacionamento Operação Regras e Grupo';
    this.titleService.title = 'Relacionamento Operação Regras e Grupo';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.grupoId = 0;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
    this.toolbarService.hidden = false;
  }


  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

        this.listaOperacaoRegrasMapeadas = new Array();
        this.listaOperacaoRegrasAMapear = new Array();

        this.grupos = [];
        this.grupos.push({ label: 'Selecione o Grupo', value: 0});

        this.httpService.wait();
        this.httpService.get('custom/cadastro/get-grupos', [this.dblinkedSessionService.empresa.id])
          .subscribe(retorno => {
            retorno.forEach(element => {
              this.grupos.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
            });

          }, error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());


      // renderizando a toolbar
      setTimeout(() => {
        this.toolbarEdit();
      }, 500);
    }
  }

  onFilterRms(event: any) {
    console.log('event: ' + event);
  }

  export() {
    // alert('export');
  }

  pesquisa() {
    // alert('pesquisa');
  }


 hideMessage() {

  }

  private toolbarEdit() {
    const todos = this.buttons.slice();
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.alterarStatus('pesquisa', true, 'Detalhes Rateio', () => this.pesquisa());
    this.toolbarService.alterarStatus('download', true, 'Download Anexos', () => this.export());
    this.toolbarService.alterarStatus('voltar', true, 'Voltar', () => this.ngOnInit());
    this.toolbarService.alterarStatus('atualizar', true, 'Atualizar RMS', () => this.change());
    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.ngOnInit());
    this.toolbarService.alterarStatus('save', true, 'Salvar requisição', () => this.ngOnInit());
    this.toolbarService.alterarStatus('edit', true, 'Editar requisição', () => this.ngOnInit());
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Aprovadores', () => this.salvarRelacionamento());
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  salvarRelacionamento(){

    if (this.validacao()) {
      return;
    }

    if (confirm('Deseja realmente salvar este relacionamento?')) {
      const req = {
        grupoId: this.grupoId,
        listaOperacaoRegrasMapeadas: this.listaOperacaoRegrasMapeadas,
        empresaId: this.empresa.id
      };


      this.httpService.wait();
      this.httpService.post('/custom/cadastro/salvar-relacionamento-grupo-operacao-regras', req)
      .subscribe(result => {
        if(result.error){
          this.messageService.add({
            severity: result.severity,
            summary:  result.summary,
            detail:   result.detail
          });
        } else{
          this.messageService.add({
            severity: result.severity,
            summary:  result.summary,
            detail:   result.detail
          });
        }
        this.change();
        this.grupoId = 0;
        },
          error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
          () => this.httpService.done());
    }
  }

  validacao(){
    if (this.grupoId == null || this.grupoId == undefined || this.grupoId === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um Grupo'
      });
      return true;
    }

    if (this.empresa.id == null || this.empresa.id == undefined || this.empresa.id === 0 ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma empresa'
      });
      return true;
    }

    return false;

  }

  tabChange(event: any){
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Relacionamento', () => this.salvarRelacionamento());
  }


  buscarOperacaoRegras(){

    this.listaOperacaoRegrasMapeadas = new Array();
    this.listaOperacaoRegrasAMapear = new Array();

    this.picklistState = false

    if (this.grupoId > 0){
      this.httpService.wait();
      this.httpService.get('custom/cadastro/get-operacao-regras', [this.dblinkedSessionService.empresa.id, this.grupoId])
        .subscribe(retorno => {

          console.log('retorno:  ' + JSON.stringify(retorno));

          retorno.forEach(element => {
              if(element.relac){
              this.listaOperacaoRegrasMapeadas.push(element);
            }else{
              this.listaOperacaoRegrasAMapear.push(element);
            }

          });

          this.picklistState = true

        }, error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done() );
    } else {
      this.picklistState = true
    }
  }

  showCard() {
    this.showCardBool = !this.showCardBool;
    this.currentStateMov = this.currentStateMov === 'initial' ? 'final' : 'initial';

  };

}