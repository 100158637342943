import { Component, OnInit } from '@angular/core';
import { MunicipioIbge } from '../model/municipio-ibge.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { Subscription } from 'rxjs/Subscription';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { MessageService } from 'primeng/api';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { SessionService } from 'app/shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';

@Component({
  selector: 'app-municipio-ibge',
  templateUrl: './municipio-ibge.component.html',
  styleUrls: ['./municipio-ibge.component.scss'],
  providers: [CrudService]
})
export class MunicipioIbgeComponent extends DataTableBasicComponent implements OnInit {
  public municipioIbge: MunicipioIbge = new MunicipioIbge();
  public registrosMunicipioIbge: Array<MunicipioIbge> = new Array();

  private sessionSubscription: Subscription;

  ctrlSave: boolean;
  entityFilter: string = null;
  editarConta: boolean;
  novaConta: boolean;
  ids: Array<any> = new Array();
  selectM: Array<any> = new Array();

  title = 'Municipio Ibge';
  subtitle = "Municipio Ibge";

  uf: string;
  ufs = new Array();
  selectedUf: any = null;
  municipio: string;
  codigoIbge: any;
  dtIni: string;
  dtFin: string;

  constructor(
    crudService: CrudService,
    private messageService: MessageService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private configService: ConfigService,
    private httpService: HttpService
    ) {
    super(crudService);
  }

  public toolbarMain = new Toolbar();

  toolbarMainIni = [
    { key: 'voltar',  icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { key: 'saveNew', icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { key: 'save', icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]

  ngOnInit() {
    this.toolbarService.hidden = true;
    this.toolbarMain.setAll(this.toolbarMainIni);

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
      );
  }

  carregar() {
    this.title = 'Municipio IBGE';
    this.subtitle = 'Municipio IBGE';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.selectM = new Array();

    this.limpaCamposForm();

    this.toolbarInitial();

    this.loadUfs();
    this.loadRegistros();
  }

  toolbarInitial(){
    this.toolbarMain.setVisible('voltar', false)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.toolbarMain.setVisible('add', true)
    this.toolbarMain.setVisible('saveNew', false)
    this.toolbarMain.setVisible('save', false)
  }

  toolbarNew(){
    this.toolbarMain.setVisible('voltar', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.toolbarMain.setVisible('add', false)
    this.toolbarMain.setVisible('saveNew', true)
    this.toolbarMain.setVisible('save', false)
  }

  toolbarEdit(){
    this.toolbarMain.setVisible('voltar', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', true)
    this.toolbarMain.setVisible('add', false)
    this.toolbarMain.setVisible('saveNew', false)
    this.toolbarMain.setVisible('save', true)
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar';

    this.toolbarNew();

  }

  loadUfs(){
    this.httpService.wait();
    this.httpService
      .get(`/uf?$select=id,uf&$orderby=uf`)
      .subscribe(
        (retorno) => {
          this.ufs = new Array();
          retorno.value.forEach(item => {
            this.ufs.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService
      .get(`/municipioibge?$select=uf/id,nome,cod&$count=true&$orderby=uf`)
      .subscribe(
        (retorno) => {
          this.registrosMunicipioIbge = new Array();
          retorno.value.forEach(item => {
            this.registrosMunicipioIbge.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar';
    this.editarConta = true;
    this.selectM = []

    this.toolbarEdit();

    this.limpaCamposForm();

    this.httpService.wait();
    // this.httpService.get(`/uf(${this.ids[0]})?$select=uf,codIbge,periodo&$count=true`)
    this.httpService.get(`/municipioibge(${this.ids[0]})?$select=uf/id,uf/uf,nome,cod,dtIni,dtFin&$count=true`)
      .subscribe(item => {
        this.codigoIbge = item.value.cod;
        this.selectedUf = item.value.uf?.id;
        this.municipio = item.value.nome;
        this.dtIni = item.value.dtIni;
        this.dtFin = item.value.dtFin;
      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonEnvio = {
      cod: this.codigoIbge,
      dtIni: this.dtIni,
      dtFin: this.dtFin,
      nome: this.municipio,
      uf: {
        id: this.selectedUf
      },
    }

    this.httpService.wait();
    this.httpService.post('/municipioibge', jsonEnvio)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }


  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonEnvio = {
      cod: this.codigoIbge,
      dtIni: this.dtIni,
      dtFin: this.dtFin,
      nome: this.municipio,
      uf: {
        id: this.selectedUf
      },
    }

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/municipioibge', key, jsonEnvio, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      this.httpService.wait();
      this.httpService.delete('/municipioibge', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Registro excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
    }
  }

  getUfNome(id){
    if(id === undefined || id === null || id == ''){
      return '';
    }

    const response = this.ufs.filter(uf => {
      return uf.id == id;
    })

    if(this.ufs.length > 0){
      return response[0].uf;
    }

    return '';
  }

  onRowSelect(event: any) {
    this.ids = new Array();
    this.ids.push(event.data.id);
    event.selected = false;
    this.toolbarMain.setVisible('edit', true)
    this.toolbarMain.setVisible('delete', true)
  }

  onRowUnselect(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
  }

  validacao(): boolean {
    if (this.selectedUf === undefined || this.selectedUf === null || this.selectedUf == '') {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'UF inválida' });
      return true;
    }

    if (this.municipio === undefined || this.municipio === null || this.municipio.trim() == '') {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Município inválido' });
      return true;
    }

    if (this.codigoIbge === undefined || this.codigoIbge === null || this.codigoIbge.trim() == '') {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código IBGE inválido' });
      return true;
    }

    if (String(this.codigoIbge).length != 7) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código IBGE deve ter 7 caracteres' });
      return true;
    }

    // if (this.dtIni === undefined || this.dtIni === null || this.dtIni == '') {
    //   this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'DTINI inválido' });
    //   return true;
    // }

    // if (String(this.dtIni).length != 8 ) {
    //   this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'DTINI deve ter 8 caracteres' });
    //   return true;
    // }

    // if (this.dtFin === undefined || this.dtFin === null || this.dtFin == '') {
    //   this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'DTFIN inválido' });
    //   return true;
    // }

    // if (String(this.dtFin).length != 8 ) {
    //   this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'DTFIN deve ter 8 caracteres' });
    //   return true;
    // }

    return false;
  }

  provisorio(){}

  private limpaCamposForm() {
    this.codigoIbge = null;
    this.selectedUf = null;
    this.municipio = null;
    this.dtIni = null;
    this.dtFin = null;
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
