import { Selected } from './../../shared/components/data-table/data-table.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { LoginService } from 'app/shared/services/login.service';
import { Message } from 'primeng/api';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';

@Component({
  selector: 'app-periodo',
  templateUrl: './periodo.component.html',
  styleUrls: ['./periodo.component.scss'],
})

export class PeriodoOrcamentoComponent implements OnInit, OnDestroy {
  ecdList: Array<any> = null;
  msgs: Message[] = null;
  msgSucesso: Message[] = null;
  msgAdvertencia: Message[] = null;
  logAdvertencia: Message[] = null;
  existeLog: boolean;
  modalLog: boolean;
  canRemove: boolean;
  private sessionSubscription: Subscription;
  title: string;
  subtitle: string;

  constructor(private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private loginService: LoginService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService) {
  }

  ngOnInit(): void {
    this.title = 'ORÇAMENTO - PERÍODO';
    this.subtitle = 'CONTROLE DE PERÍODO';
    this.titleService.title = 'ORÇAMENTO - PERÍODO';

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.existeLog = false;
    this.modalLog = false;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.sessionChange(); });
  }

  ngOnDestroy() {
    // this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  sessionChange() {
    // this.toolbarService.clear();


    this.canRemove = false;

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasYearSelected) {
      this.httpService.wait();
      this.httpService.get('/cadecd' +
        '?$select=dtIni,dtFin,statusOrcamento/id' +
        '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
        ' and period(dtIni,dtFin) eq ' + this.dblinkedSessionService.periodo.year.value +
        '&$orderby=dtIni')
        .subscribe(cadecd => {
          this.ecdList = cadecd.value;
          this.ecdList.forEach(ecd => {
            ecd.homologado = ecd.statusOrcamento.id === 3;
            ecd.apurado = ecd.homologado || ecd.statusOrcamento.id === 2;
          });

        },
          error => this.httpService.handleError(error, () => this.sessionChange()),
          () => this.httpService.done());
    }
  }



  onSelectAllStatus(undo: boolean, statusId: number) {
    let process: string;

    switch (statusId) {
      case 2:
        if (this.allApuracaoDisabled) {
          return false;
        }
        process = 'apuração';
        break;
      case 3:
        if (this.allHomologacaoDisabled) {
          return false;
        }
        process = 'homologação';
        break;
    }

    if (confirm('Deseja ' + (undo ? 'desfazer' : 'efetuar') + ' a ' + process + ' de todo o período?')) {
      this.httpService.wait();
      this.httpService.post('/custom/ecd/status-orcamento(' + this.dblinkedSessionService.empresa.id + ',' +
        this.dblinkedSessionService.periodo.year.value + ',' +
        (undo ? statusId - 1 : statusId) + ')')
        .subscribe(() => {
          this.ecdList.forEach(ecd => {
            ecd.statusOrcamento.id = undo ? statusId - 1 : statusId;
            ecd.homologado = ecd.statusOrcamento.id === 3;
            ecd.apurado = ecd.homologado || ecd.statusOrcamento.id === 2;
          });

        },
          error => this.httpService.handleError(error, () => this.onSelectAllStatus(undo, statusId)),
          () => this.httpService.done());

      return true;
    }

    return false;
  }

  onClick(row: any, statusId: number, disabled: boolean, event?: any) {

    console.log(row);
    console.log(statusId);
    console.log(event);


    if (!disabled && (!event || event.keyCode === 32)) {
      let undo: boolean;
      let process: string;

      switch (statusId) {
        case 2:
          undo = row.statusOrcamento.id == 2;
          row.apurado = row.statusOrcamento.id == 2;
          process = 'apuração';
          break;
      }

      if (confirm('Deseja ' + (undo ? 'desfazer' : 'efetuar') + ' a ' + process + ' do período?')) {
        this.onChange(row);
        return true;

      }
    }

    return false;
  }

  onChange(row: any) {
    if (row.apurado) {
      row.statusOrcamento.id = 1;
    } else {
      row.statusOrcamento.id = 2;
    }



    this.httpService.wait();
    this.httpService.post('/custom/ecd/status-orcamento(' + row.id + ',' + row.statusOrcamento.id + ')')
      // .subscribe(() => this.onSelect(),
      .subscribe(result => this.resultado(result),
        error => this.httpService.handleError(error, () => this.onChange(row)),
        () => this.httpService.done());
  }


  resultado(result: any) {
    this.msgSucesso = [];
    this.msgAdvertencia = [];
    this.logAdvertencia = [];
    this.existeLog = false;

    result.forEach(element => {
      if (element.error) {
        this.logAdvertencia.push({ severity: 'warn', summary: 'Advertência:', detail: element.message });
        this.existeLog = true;
        this.msgAdvertencia = [];
        this.msgAdvertencia.push({
          severity: 'warn', summary: 'Advertência:',
          detail: 'Ocorreram erros que impossibilitaram a ação, verifique os detalhes no log'
        });
      } else {
        this.msgSucesso.push({ severity: 'success', summary: 'Sucesso:', detail: element.message });
        this.sessionChange();
      }
    });


  }

  get selected(): Array<any> {
    if (!this.ecdList) {
      return null;
    }

    return this.ecdList.filter(ecd => ecd.selected);
  }

  get hasSelected(): boolean {
    return this.selected && this.selected.length > 0;
  }

  get hasNotSelected(): boolean {
    return this.ecdList && this.selected && this.ecdList.length === this.selected.length;
  }

  get hasSelectedApurado(): boolean {
    return this.selected && this.selected.findIndex(ecf => ecf.apurado) > -1;
  }

  get hasApurado(): boolean {
    return this.ecdList && this.ecdList.findIndex(ecf => ecf.apurado) > -1;
  }

  get hasNotApurado(): boolean {
    return this.ecdList && this.ecdList.findIndex(ecf => !ecf.apurado) > -1;
  }

  get hasAllApurado(): boolean {
    return this.hasApurado && !this.hasNotApurado;
  }

  get hasAllNotApurado(): boolean {
    return !this.hasApurado && this.hasNotApurado;
  }

  get hasHomologado(): boolean {
    return this.ecdList && this.ecdList.findIndex(ecf => ecf.homologado) > -1;
  }

  get hasNotHomologado(): boolean {
    return this.ecdList && this.ecdList.findIndex(ecf => !ecf.homologado) > -1;
  }

  get hasAllHomologado(): boolean {
    return this.hasHomologado && !this.hasNotHomologado;
  }

  get hasAllNotHomologado(): boolean {
    return !this.hasHomologado && this.hasNotHomologado;
  }

  get allApuracaoDisabled(): boolean {
    return this.hasHomologado || (this.hasApurado && !this.hasAllApurado);
  }

  get allHomologacaoDisabled(): boolean {
    return this.hasNotApurado || (this.hasHomologado && !this.hasAllHomologado);
  }

  get allApuracaoIndeterminate(): boolean {
    return this.hasApurado && !this.hasAllApurado;
  }

  get allHomologacaoIndeterminate(): boolean {
    return this.hasHomologado && !this.hasAllHomologado;
  }

  get allSelectedIndeterminate(): boolean {
    return this.hasSelected && !this.hasNotSelected;
  }

  abreModalLog() {
    this.modalLog = true;

  }

  hideMessage() {
    this.msgs = null;
    this.msgAdvertencia = [];
    this.logAdvertencia = [];
    this.msgSucesso = [];
    this.existeLog = false;
  }
  private toolbarRefresh() {
    // casos em que a toolbar vem vazia (session de outra página)
    const todos = this.buttons.slice();
    if (todos.length === 0) {
      this.toolbarService.replace('atualizar', true, 'class-first-button', 'refresh', 'Atualizar relatório',);
      this.toolbarService.replace('msg', true, 'class-other-button', 'warning', 'Visualizar',);
      this.toolbarService.replace('pesquisa', true, 'class-first-button', 'search', 'Pesquisar',);
      this.toolbarService.replace('download', true, 'class-other-button', 'cloud_download', 'Download',);
      this.toolbarService.replace('cancel', true, 'class-other-button class-botao-margin', 'block', 'Cancelar',);
      this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir',);
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir',);
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar',);
      this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Adicionar',);
    } else {
      // this.toolbarService.ocultar('check');
      // this.toolbarService.ocultar('info');
      // this.toolbarService.ocultar('cancel');
    }
  }
  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }
}
