import { RegraGrupo } from 'app/cadastro/model/regra-grupo.model';
import { RegraCamposSelect } from 'app/cadastro/model/regra-campos-select.model';
import { RegraCamposGroup } from 'app/cadastro/model/regra-campos-group.model';
import { RegraCamposOrder } from 'app/cadastro/model/regra-campos-order.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { View } from 'app/cadastro/model/view.model';

export class Regra {

  id: number;
  cod: string;
  nome: string;
  descricao: string;
  sql: string;
  sqlCount: string;
  ativa: boolean;
  dtIni: Date;
  dtFin: Date;
  regraGrupo: Array<RegraGrupo>;
  regraCamposSelect: Array<RegraCamposSelect>;
  regraCamposGroup: Array<RegraCamposGroup>;
  regraCamposOrder: Array<RegraCamposOrder>;
  exbirZero: boolean;
  homologada: boolean;
  regra: boolean;
  authUser: AuthUser;
  changeUser: AuthUser;
  grauRisco: string;
  ordem: number;
  view: View;

constructor(regra?: Regra | number) {
    if (regra !== undefined && regra !== null) {
      if (typeof regra === 'number') {
        this.id = regra;
      } else {
        this.id = regra.id;
        this.cod = regra.cod;
        this.nome = regra.nome;
        this.descricao = regra.descricao;
        this.sql = regra.sql;
        this.sqlCount = regra.sqlCount;
        this.ativa = regra.ativa;
        this.dtIni = regra.dtIni;
        this.dtFin = regra.dtFin;
        this.regraGrupo = regra.regraGrupo;
        this.regraCamposSelect = regra.regraCamposSelect;
        this.regraCamposGroup = regra.regraCamposGroup;
        this.regraCamposOrder = regra.regraCamposOrder;
        this.exbirZero = regra.exbirZero;
        this.homologada = regra.homologada;
        this.regra = regra.regra;
        this.authUser = regra.authUser;
        this.changeUser = regra.changeUser;
        this.grauRisco = regra.grauRisco;
        this.ordem = regra.ordem;
        this.view = regra.view;
      }
    }
  }
}
