import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public isReady: Subject<boolean> = new Subject()

  display = new EventEmitter<boolean>()
  rowsSelected = new EventEmitter<Array<any>>()
  clearArr = new EventEmitter<Array<any>>()
  tabSelected: number = 0

  constructor() { }

  open(state): void {
    this.display.emit(state)
  }

  rowSelected(arr: any): void {
    this.rowsSelected.emit(arr)
  }

  clearRow(): any {
    this.clearArr.emit([])
  }

  selectTab(tab: number): void {
    this.tabSelected = tab
  }

}
