import { isNullOrUndefined } from "util";
import { Formulario} from './formulario.model';
import { FormularioTipoCampo } from './formulario-tipo-campo.model';

export class FormularioPerguntasCampo{

  id                    : number;
  perguntaDescricao     : string;
  formulario            : Formulario;
  posicaoCampo          : string;
  tamanhoCampo          : number;
  formularioTipoCampo   : FormularioTipoCampo;
  requerido             : boolean;


  constructor(formularioPerguntasCampo?: FormularioPerguntasCampo | number) {
    if (!isNullOrUndefined(formularioPerguntasCampo)) {
      if (typeof formularioPerguntasCampo === 'number') {
        this.id = formularioPerguntasCampo;
      } else {
        this.id                      = formularioPerguntasCampo.id;
        this.perguntaDescricao       = formularioPerguntasCampo.perguntaDescricao;
        this.formulario              = formularioPerguntasCampo.formulario;
        this.posicaoCampo            = formularioPerguntasCampo.posicaoCampo;
        this.tamanhoCampo            = formularioPerguntasCampo.tamanhoCampo;
        this.formularioTipoCampo     = formularioPerguntasCampo.formularioTipoCampo;
        this.requerido               = formularioPerguntasCampo.requerido;
      }
    }
  }

}