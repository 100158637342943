import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MessageService, SelectItem } from '../../../../node_modules/primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-config-objeto',
  templateUrl: './objeto.component.html',
  styleUrls: ['./objeto.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ConfigObjetoComponent implements OnInit, OnDestroy {
  ////////////////////////////////////////
  // REFATOR SIMON LALLEMENT 28/12/2021 //
  ////////////////////////////////////////

  private sessionSubscription: Subscription

  //Toolbar
  toolbarMainIni = [
    { icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: false, disabled: false, color: 'red', onClick: () => this.excluirObjeto() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openEditObjeto() },
    { icon: 'add', text: 'Nova', tooltip: 'Nova', visible: true, disabled: false, color: 'green', onClick: () => this.openAddObjeto() },
  ]
  public toolbarMain = new Toolbar()

  //Array
  objRequisicao: Array<any> = new Array()
  objSelected: Array<number> = new Array()
  selectedRows: Array<any> = new Array()

  //String
  titlePrincipal: string = 'Configurações RMS'
  subtitle: string = 'Objeto'
  currentStateObjeto: string = 'initial'
  descricaoObjeto: string = null

  //Boolean
  isCardObjeto: boolean = true
  checkedAll: boolean = false
  modalObjeto: boolean = false
  add: boolean = false
  edit: boolean = false

  //Miscellaneous
  objetoSelecionado: any = {}

  //Number
  relacaoId: number = null

  //Dropdown
  listRelacaoObjeto: SelectItem[] = []
  listTipoRequisicaoObjeto: SelectItem[] = []
  selectedsTipos: number[] = []

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.titleService.title = 'Linnks - Objeto RMS'
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.objRequisicaoItens()
        this.buscarRelacoes()
        this.getTiposRequisicao()
      }
    })
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
  }

  openAddObjeto() {
    this.modalObjeto = true
    this.descricaoObjeto = null
    this.relacaoId = null
    this.add = true
  }

  buscarRelacoes() {
    this.httpService.wait()
    this.httpService.get(`objetormspedido?$select=id,descricao&$orderby=descricao`)
      .subscribe(ref => {
        this.listRelacaoObjeto = []
        ref.value.forEach(element => { this.listRelacaoObjeto.push({ label: element.descricao, value: element.id }) })
        this.httpService.done()

      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao obter as relações de objeto, contate o suporte : " + error.message })
          this.httpService.done()
        })
  }

  getTiposRequisicao() {
    this.httpService.wait();
    this.httpService.get('/custom/rms/listar-tipos', this.dblinkedSessionService.empresa.id)
      .subscribe(rst => {
        this.listTipoRequisicaoObjeto = []

        rst.forEach(element => {
          this.listTipoRequisicaoObjeto.push({
            label: element.nome,
            value: element.id
          })
        })

        this.httpService.done()
        this.resetSelected()
      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro ao obter os tipos de RMS, contate o suporte : " + error.message })
          this.httpService.done()
          this.resetSelected()
        })
  }

  openEditObjeto() {
    this.modalObjeto = true
    this.objetoSelecionado = this.objRequisicao.find(element => this.selectedRows[0].id === element.id)
    this.descricaoObjeto = this.objetoSelecionado.nome
    this.relacaoId = this.objetoSelecionado.tipo_relac_id

    if(this.objetoSelecionado.tipoRequisicao.length > 0) {
      this.selectedsTipos = [];
      this.objetoSelecionado.tipoRequisicao.forEach(element => {
        this.selectedsTipos.push(element.idTipoRequisicao)
      });
    }
    this.edit = true
  }

  excluirObjeto() {
    if (confirm("Deseja remover os objetos selecionados?")) {
      let listaEnviar = {
        id: this.selectedRows.map(item => item.id)
      }

      this.httpService.wait();
      this.httpService.post('/custom/rms/deletar-obj', listaEnviar)
        .subscribe(rst => {
          this.httpService.done();

          if(rst?.error) {
            return this.exibirMensagem(rst);
          }

          this.objRequisicaoItens()
          this.checkToolBar()
          this.resetSelected()
        }, error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao excluir os objetos, contate o suporte : " + error.message })
          this.httpService.done()
          this.resetSelected()
        })
    }
  }

  changeStateObjeto() {
    this.currentStateObjeto = this.currentStateObjeto === 'initial' ? 'final' : 'initial'
  }

  showHideObjeto() {
    this.isCardObjeto = !this.isCardObjeto;
  }

  adicionar() {
    if (this.validacao()) return

    let listaEnviar = {
      nomeTipo: this.descricaoObjeto,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id,
      tipo_relac: this.relacaoId,
      tipos_requisicao: this.selectedsTipos
    }

    this.httpService.wait();
    this.httpService.post('/custom/rms/adicionar-obj', listaEnviar).subscribe(rst => {
      if(rst.error){this.exibirMensagem({ severity: "error", message: rst.detail })}
      else{
        this.exibirMensagem({ severity: "success", message: rst.detail })
        this.objRequisicaoItens()
        this.modalObjeto = false
        this.add = false
      }
    },
    error => {
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar o objeto, contate o suporte : " + error.message })
      this.resetSelected()
    },
    () => this.httpService.done())
  }

  validacao(): boolean {
    if (!this.descricaoObjeto) {
      this.exibirMensagem({ error: true, mensagem: "O campo Descrição é obrigatório." })
      return true
    }

    if (!this.relacaoId) {
      this.exibirMensagem({ error: true, mensagem: "O campo Relação é obrigatório." })
      return true
    }

    if(this.selectedsTipos.length <= 0) {
      this.exibirMensagem({ error: true, mensagem: "Selecione pelo menos um tipo de requisição." })
      return true
    }

    let lista = this.objRequisicao.map(item => { return this.stringComparavel(item.nome) })

    if((this.edit ? this.stringComparavel(this.objetoSelecionado.nome) !== this.stringComparavel(this.descricaoObjeto): true) && lista.includes(this.stringComparavel(this.descricaoObjeto))) {
      this.exibirMensagem({ severity: 'warn', mensagem: "A descrição deve ser única." })
      return true
    }

    return false
  }

  stringComparavel(string: string): string {
    return string.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z._ ])/g, '').toLowerCase().trim()
  }

  objRequisicaoItens() {
    this.httpService.wait()
    this.httpService.get('/custom/rms/listar-obj', this.dblinkedSessionService.empresa.id).subscribe(rst => {
      this.objRequisicao = new Array()
      rst.forEach(element => { this.objRequisicao.push(element) })
      this.httpService.done()
    },
      error => {
        console.log(error)
        this.exibirMensagem({ severity: "error", message: "Erro no API ao obter os objetos, contate o suporte : " + error.message })
        this.httpService.done()
      })
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  atualizar() {
    if (this.validacao()) return

    let listaEnviar = {
      id: this.objetoSelecionado.id,
      nome: this.descricaoObjeto,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id,
      tipo_relac_id: this.relacaoId,
      tipoRequisicaoIds: this.selectedsTipos
    }

    this.httpService.wait();
    this.httpService.post('/custom/rms/atualizar-obj', listaEnviar).subscribe(rst => {
      if(rst.error){this.exibirMensagem({ severity: "error", message: rst.detail })}
      else{
        this.exibirMensagem({ severity: "success", message: rst.detail })
        this.objRequisicaoItens()
        this.checkToolBar()
        this.modalObjeto = false
        this.edit = false
        this.resetSelected()
      }
    }, error => {
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar o objeto, contate o suporte : " + error.message })
      this.resetSelected()
    },
    () => this.httpService.done())
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  resetSelected() {
    this.selectedRows = [];
    this.selectedsTipos = [];
    this.edit = false;
    this.checkToolBar();
  }

  checkToolBar() {
    this.edit = false;
    this.toolbarMain.setVisible('btn1', this.selectedRows.length !== 0);
    this.toolbarMain.setVisible('btn2', this.selectedRows.length === 1);
  }

  closeModal() {
    this.modalObjeto = false;
    this.edit = false;
    this.add = false;
    this.selectedsTipos = [];
  }
}
