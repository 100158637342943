import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from '../../shared/services/title.service';
import { ConfigService } from '../../shared/services/config.service';
import { HttpService } from '../../shared/services/http.service';
import { SessionService } from '../../shared/services/session.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Message, MessageService } from '../../../../node_modules/primeng/api';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-arquivos-magneticos',
  templateUrl: './arquivos-magneticos-component.html',
  styleUrls: ['./arquivos-magneticos-component.scss'],
})
export class ArquivosMagneticosComponent implements OnInit, OnDestroy {

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
    }

    title                      : string;
    subtitle                   : string;
    private sessionSubscription: Subscription;
    modalSpeedIcmsIpi          : boolean;
    exportarBlocoH             : boolean;
    modalEfd                   : boolean;
    modalNf21                  : boolean;
    modalDimeSc                : boolean;
    modalNfs                   : boolean;
    meses                      : any[];
    mesSelecionado             : number;
    msgsModal                  : Message[] = [];
    msgs                       : Message[] = [];
    listRelatorio              : Array<any> = new Array();
    idRelatorio                : any;
    dtInicial                  : Date;
    dtFinal                    : Date;
    defaultDate                : Date;
    partOutroMunicipio         : boolean;
    modalCat42: boolean
    valorDime                  : any;
    codigo31                  : any;
    dtVencimentoReg33         : Date;
    origem33                  : any;
    codigoReceita33           : any;
    valorReg33                : any;
    classeVencimentoReg33     : any;
    numAcordoReg33            : any;

    dtVencimentoReg332         : Date;
    origem332                  : any;
    codigoReceita332           : any;
    valorReg332                : any;
    classeVencimentoReg332     : any;
    numAcordoReg332            : any;

    dtVencimentoReg333         : Date;
    origem333                  : any;
    codigoReceita333           : any;
    valorReg333                : any;
    classeVencimentoReg333     : any;
    numAcordoReg333            : any;





    identificacaoReg46       : any;
    origemReg46               : any;

    identificacaoReg462       : any;
    origemReg462               : any;

  ngOnInit(): void {
    this.title = 'Exportar Arquivos';
    this.subtitle = 'Arquivos Magnéticos para exportar';
    this.titleService.title = 'Exportar arquivos';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
    () => { this.change(); });

    this.defaultDate = new Date();

  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  exibirModal(exibirModal) {
    if (exibirModal === 'SPEED_ICMS_IPI') {
      this.modalSpeedIcmsIpi = true;
      this.exportarBlocoH    = false;
    } else if (exibirModal === 'EFD_PIS_COFINS') {
      this.modalEfd = true;
    } else if (exibirModal === 'NFS_CAMPINAS') {
      this.idRelatorio = 0;
      this.modalNfs = true;
      this.relatNFS();
    } else if (exibirModal === 'NFS_21') {
      this.modalNf21 = true;
    } else if (exibirModal === 'DIME_SC') {
      this.modalDimeSc = true;
    } else if (exibirModal === 'CAT42') {
      this.modalCat42 = true;
    }
  }

  relatNFS() {
    if (this.dblinkedSessionService) {
      if (this.dblinkedSessionService.hasEmpresaSelected &&
        this.dblinkedSessionService.hasPeriodoSelected &&
        this.dblinkedSessionService.periodo.month &&
        this.dblinkedSessionService.periodo.year) {
        this.listRelatorio = new Array();
        this.listRelatorio.push({label:'Serviços tomados', value: 2});
        this.listRelatorio.push({label:'Serviços prestados', value: 1});
      }
    }
  }

  exportarNfs() {
    this.modalNfs = false

    const httpOptions = { headers: new HttpHeaders({'Authorization': this.configService.bearerApi})}

    const infos = {
      tipo: this.idRelatorio,
      empresaid: this.dblinkedSessionService.empresa.id,
      dtinicial: this.dtInicial.getFullYear() + "-" + (this.dtInicial.getMonth() + 1) + "-" + this.dtInicial.getDate(),
      dtfinal: this.dtFinal.getFullYear() + "-" + (this.dtFinal.getMonth() + 1) + "-" + this.dtFinal.getDate(),
      outromuni: this.partOutroMunicipio,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'fiscal/nftoprefeitura', infos, httpOptions)
      .subscribe(
        res => {
          this.httpService.done()
          if (res["error"]) {
            this.httpService.done()
            this.exibirMensagem(res)
            return
          }

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          };

          this.httpClient.get(this.configService.defaultUrlApiUpload + 'fiscal/nftoprefeitura', httpOptions2)
            .subscribe(
              (res: any) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                let fileName = 'arquivo_campinas.txt'
                link.href = url
                link.download = fileName
                link.click()
                window.URL.revokeObjectURL(url)
                this.httpService.done()
              },
              err => {
                this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível baixar o arquivo, contate o suporte: " + err.message })
                this.httpService.done()
              })
        },
        err => {
          this.httpService.done()
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível gerar o arquivo, contate o suporte: " + err.message })
        }
      )
  }

  exibirMensagem(value: any){
    if(!value.error){
      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem});

    } else {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro', detail: value.mensagem});
    }
  }

  change() {
    if (!this.dblinkedSessionService.hasEmpresaSelected || !this.dblinkedSessionService.hasYearSelected) {
      return;
    }
    this.mesSelecionado = null;

    this.modalSpeedIcmsIpi = false;

    if (this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id) {
    this.httpService.wait();
          this.httpService.get('/custom/ecd-report/mesesrelatorios(' +
                                this.dblinkedSessionService.empresa.id + ',' + this.dblinkedSessionService.periodo.year.value + ')')
                          .subscribe(periodos => this.periodosLoad(periodos),
                                    error => this.httpService.handleError(error, () => this.change()),
                                    () => this.httpService.done());
    }
  }

  exportarSpeed() {
    if (!this.mesSelecionado) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecione o mês para prosseguir!'
      });
      return;
    }
    this.httpService.wait();
          this.httpService.get('/custom/sped/sped-fiscal(' + this.dblinkedSessionService.empresa.id + ',' +
                                this.mesSelecionado + ',' + this.dblinkedSessionService.periodo.year.value + ',' + this.exportarBlocoH+ ',' + false+')')
    .subscribe(result => { this.download(result); },
      error => this.httpService.handleError(error, () => this.exportarSpeed()),
      () => this.httpService.done());
    this.mesSelecionado = null;
    this.modalSpeedIcmsIpi = false;
  }

  exportarDime() {
    if (!this.mesSelecionado) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecione o mês para prosseguir!'
      });
      return;
    }


    const listasEnviar = {

      empresaId: this.dblinkedSessionService.empresa.id,
      mes: this.mesSelecionado,
      ano: this.dblinkedSessionService.periodo.year.value,
      valorReg31: this.valorDime,
      codigo31: this.codigo31,
      dtVencimentoReg33: this.dtVencimentoReg33,
      origem33: this.origem33,
      codigoReceita33: this.codigoReceita33,
      valorReg33: this.valorReg33,
      classeVencimentoReg33: this.classeVencimentoReg33,
      numAcordoReg33: this.numAcordoReg33,
      dtVencimentoReg332: this.dtVencimentoReg332,
      origem332: this.origem332,
      codigoReceita332: this.codigoReceita332,
      valorReg332: this.valorReg332,
      classeVencimentoReg332: this.classeVencimentoReg332,
      numAcordoReg332: this.numAcordoReg332,
      dtVencimentoReg333: this.dtVencimentoReg333,
      origem333: this.origem333,
      codigoReceita333: this.codigoReceita333,
      valorReg333: this.valorReg333,
      classeVencimentoReg333: this.classeVencimentoReg333,
      numAcordoReg333: this.numAcordoReg333,
      identificacaoReg46: this.identificacaoReg46,
      origemReg46: this.origemReg46,
      identificacaoReg462: this.identificacaoReg462,
      origemReg462: this.origemReg462
    };

    this.httpService.wait();
          this.httpService.post('/custom/dime/gerar-dime', listasEnviar)
    .subscribe(result => {

      result.forEach(element => {

        this.downloadDime(element);
      });


    },
      error => this.httpService.handleError(error, () => this.exportarDime()),
      () => this.httpService.done());
    this.mesSelecionado = null;
    this.modalSpeedIcmsIpi = false;
  }



  exportarEfd() {
    if (!this.mesSelecionado) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecione o mês para prosseguir!'
      });
      return;
    }
    this.httpService.wait();
          this.httpService.get('/custom/efd/efd-cont(' + this.dblinkedSessionService.empresa.id + ',' +
                                this.mesSelecionado + ',' + this.dblinkedSessionService.periodo.year.value + ',' + false+ ')')
    .subscribe(result => { this.download(result); },
      error => this.httpService.handleError(error, () => this.exportarSpeed()),
      () => this.httpService.done());
    this.mesSelecionado = null;
    this.modalEfd = false;
  }

  exportarNfComunic() {
    if (!this.mesSelecionado) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecione o mês para prosseguir!'
      });
      return;
    }
    this.httpService.wait();
          this.httpService.get('/custom/nfe/get-nfe-comunic(' + this.dblinkedSessionService.empresa.id + ',' +
          this.dblinkedSessionService.periodo.year.value + ',' + this.mesSelecionado + ')')
    .subscribe(result => { this.downloadNf21(result); },
      error => this.httpService.handleError(error, () => this.exportarNfComunic()),
      () => this.httpService.done());
    this.mesSelecionado = null;
    this.modalEfd = false;
  }

  download(result: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file ), '_blank');
    }
  }

  downloadDime(result: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file ), '_blank');
    }
  }

  downloadNf21(result: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file+ '/mod21' ), '_blank');
    }
  }


  hideMessage() {


  }

  periodosLoad(element: Array<any>) {
    this.meses = [];
    this.meses.push({label: 'Selecione', value: null});
    this.meses.push({label: 'Janeiro', value: 1});
    this.meses.push({label: 'Fevereiro', value: 2});
    this.meses.push({label: 'Março', value: 3});
    this.meses.push({label: 'Abril', value: 4});
    this.meses.push({label: 'Maio', value: 5});
    this.meses.push({label: 'Junho', value: 6});
    this.meses.push({label: 'Julho', value: 7});
    this.meses.push({label: 'Agosto', value: 8});
    this.meses.push({label: 'Setembro', value: 9});
    this.meses.push({label: 'Outubro', value: 10});
    this.meses.push({label: 'Novembro', value: 11});
    this.meses.push({label: 'Dezembro', value: 12});
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  windowOpen(url: string, target?: string) {
    window.open(url, target);
  }

  exportarCat42() {
    if (!this.mesSelecionado) {
      this.messageService.add({
        severity: 'warn',
        key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecione o mês para prosseguir!'
      });
      return
    }

    const empresaId = this.dblinkedSessionService.empresa.id
    const mesSelecionado = this.mesSelecionado
    const ano = this.dblinkedSessionService.periodo.year.value

    const params = '(' + empresaId + ',' + mesSelecionado + ',' + ano + ')'

    this.httpService.wait();
    this.httpService.get('/custom/cat/portaria-cat42' + params)
    .subscribe(
      result => {
        if(!result.error) {
          this.download(result)

        } else {
          this.messageService.add({
            severity: 'warn',
            key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result.response
          })
        }
      },
      error => this.httpService.handleError(error, () => this.exportarCat42()),
      () => this.httpService.done())

    this.mesSelecionado = null
    this.modalSpeedIcmsIpi = false
  }

  onInputDate(event: any) {
    let value = event.target.value.replace(/^(\d{1,2})\/?(\d{1,2})\/?(\d{1,4})/, '$1$2$3').split(/\D/)[0],
      response = '';

    if (value.slice(0, 2).length > 0) {
      response = value.slice(0, 2) <= 31 ? value.slice(0, 2) : ''
      if (value.slice(2, 4).length > 0) {
        response += '/' + (value.slice(2, 4) <= 12 ? value.slice(2, 4) : '')
        if (value.slice(4, 8).length > 0) {
          response += '/' + (value.slice(6, 7) >= 1 && value.slice(6, 7) <= 3 ? value.slice(4, 8) : value.slice(4, 6))
        }
      }
    }
    event.target.value = response
  }

}


