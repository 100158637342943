export class TipoBaixa {
  id: number;
  descricao: string;
  ativo    : Boolean;

  constructor(tipoBaixa?: TipoBaixa | number) {
    if (tipoBaixa !== undefined && tipoBaixa !== null) {
      if (typeof tipoBaixa === 'number') {
        this.id = tipoBaixa;
      } else {
        this.id = tipoBaixa.id;
        this.descricao = tipoBaixa.descricao;
        this.ativo     = tipoBaixa.ativo;
      }
    }
  }
}
