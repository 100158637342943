import { Validators, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ValidatorsService } from 'app/shared/services/validators.service';
import { DataObject, DataOptions } from './data-object.model';

export class DataControl extends DataObject {

  options: DataOptions;
  filteredOptions: Observable<any[]>;

  readonly = false;
  min: number = null;
  max: number = null;
  step: number = null;
  minLength: number = null;
  maxLength: number = null;
  pattern: string = null;
  transient = false;
  hidden = false;
  hint: string = null;
  alwaysDisplayHint = false;
  onClick: Function;
  onChange: Function;
  classNames: string = null;

  data: any = null;
  originalValue: any;
  defaultValue: any;

  public validators: ValidatorFn[] = [];
  public asyncValidators: AsyncValidatorFn[] = null; // TODO: Implement ??

  constructor(label: string, key?: string, defaultValue?: any) {
    super(label, key);

    this.defaultValue = defaultValue;
  }

  setTypeNumber(numbersOnly: boolean = false): DataControl {
    this.setMin(0.00);
    this.setStep(1.00);
    return <DataControl>super.setType(numbersOnly ? 'numbers-only' : 'number');
  }
  setTypeMoney(): DataControl {
    this.setMin(0.00);
    this.setStep(0.50);
    return <DataControl>this.setType('money');
  }
  setTypeCurrency(): DataControl {
    return this.setTypeMoney();
  }
  setTypeEmail(): DataControl {
    this.validators.push(ValidatorsService.email);
    return <DataControl>this.setType('email');
  }
  setTypeCnpj(): DataControl {
    this.validators.push(ValidatorsService.cnpj);
    return <DataControl>this.setType('cnpj');
  }

  setOptions(options: any): DataControl {
    return <DataControl>super.setOptions(options);
  }

  setClassNames(classNames: string): DataControl {
    return <DataControl>super.setClassNames(classNames);
  }

  setHint(hint: string): DataControl {
    this.hint = hint;
    return this;
  }

  setAlwaysDisplayHint(alwaysDisplayHint: boolean) {
    this.alwaysDisplayHint = alwaysDisplayHint;
    return this;
  }

  setReadOnly(readonly: boolean = true): DataControl {
    this.readonly = readonly;
    return this;
  }

  setRequired(required: boolean = true): DataControl {
    this.required = required;
    this.validators.push(Validators.required);
    return this;
  }

  setMin(min: number): DataControl {
    this.min = min;
    return this;
  }

  setMax(max: number): DataControl {
    this.max = max;
    return this;
  }

  setStep(step: number): DataControl {
    this.step = step;
    return this;
  }

  setMinLength(minLength: number): DataControl {
    this.minLength = minLength;

    this.validators.push(Validators.minLength(minLength));
    return this;
  }

  setMaxLength(maxLength: number): DataControl {
    this.maxLength = maxLength;

    this.validators.push(Validators.maxLength(maxLength));
    return this;
  }

  setPattern(pattern: string): DataControl {
    this.pattern = pattern;

    this.validators.push(Validators.pattern(pattern));
    return this;
  }

  setTransient(transient: boolean = true): DataControl {
    this.transient = transient;
    return this;
  }

  setHidden(hidden: boolean = true): DataControl {
    this.hidden = hidden;
    return this;
  }

  setData(data: any): DataControl {
    this.data = data;
    return this;
  }

  setOriginalValue(value: any): DataControl {
    this.originalValue = value;
    return this;
  }

  setDefaultValue(value: any): DataControl {
    this.defaultValue = value;
    return this;
  }

  setOnClick(onClick: Function): DataControl {
    this.onClick = onClick;
    return this;
  }

  setOnChange(onChange: Function): DataControl {
    this.onChange = onChange;
    return this;
  }
}
