import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { SessionService } from 'app/shared/services/session.service';
import { MessageService, SelectItem } from 'primeng/api';
import { ConfigPattern } from '../../interfaces/tray';
import { resetConfigPattern } from '../../utils/reset-tray-form';
import { HttpService } from 'app/shared/services/http.service';

@Component({
  selector: 'app-cfg-tray',
  templateUrl: './cfg-tray.component.html',
  styleUrls: ['./cfg-tray.component.scss']
})
export class CfgTrayComponent implements OnInit, OnChanges {

  @Input() isRender: boolean
  @Input() isSave: boolean
  @Input() isConfigExists: boolean
  @Output() isValid = new EventEmitter()

  httpOptions: Object = new Object()

  _isConfigExists: boolean = false
  loadingConfigPattern: boolean = false
  loadingOptClient: boolean = false
  loadingIconOptClient: boolean = false
  loadingIconOptOper: boolean = false
  modalParticipante: boolean = false

  empresaId: number = null
  userId: number = null
  tipoParticipanteId: number = null

  codigoCliente: string = null
  nomeFantasiaCliente: string = null

  optOperation: SelectItem[] = []

  config: ConfigPattern = resetConfigPattern()

  constructor(
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private httpService: HttpService
  ) { }

  public ngOnInit(): void {
    this.empresaId = this.dblinkedSessionService.empresa.id
    this.userId = this.sessionService.loggedUser.id

    this.isValid.emit({ validatedSteps: false })
    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    this.getOptOperation()
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.isSave && changes.isSave.currentValue) this.saveConfigPattern()
    if(changes.isConfigExists && changes.isConfigExists.currentValue) this._isConfigExists = true
    if(changes.isRender && changes.isRender.currentValue) this.initialize(this._isConfigExists)
  }

  private async initialize(isConfigExists: boolean): Promise<void> {
    this.isValid.emit({ validatedSteps: true })

    if (isConfigExists) this.getConfigPattern()
  }

  private async getConfigPattern(): Promise<void> {
    this.loadingConfigPattern = true

    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/cadastro/configuracao-api?empresaId=${this.empresaId}&codigo=TRAY`, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          this.config.baseurl = res['data'][0].base_url
          this.config.codigo = res['data'][0].codigo_loja
          this.config.cliente_padrao_id = res['data'][0].cliente_padrao_id
          this.codigoCliente = res['data'][0].codigo_cliente
          this.nomeFantasiaCliente = res['data'][0].fantasia_cliente
          this.config.operacao_padrao_id = res['data'][0].operacao_padrao_id
        } else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar os dados padrões da sua configuração! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.validationConfig()
      this.loadingConfigPattern = false
    }
  }

  private async saveConfigPattern(): Promise<void> {
    this.isValid.emit({ validatedSteps: true })
    this.validationConfig()

    const payload = {
      empresa_id: this.empresaId,
      user_id: this.userId,
      codigo: 'TRAY',
      cliente_padrao_id: this.config.cliente_padrao_id,
      operacao_padrao_id: this.config.operacao_padrao_id,
      base_url: this.config.baseurl,
      codigo_loja: this.config.codigo
    }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/configuracao-api', payload, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) this.saveFamilyRelationship()
        else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar os dados padrões da sua configuração! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async saveFamilyRelationship(): Promise<void> {
    const httpOptions: Object = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    const payload: Object = { empresa_id: this.dblinkedSessionService.empresa.id, familia_id: 8 }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/empresa-familia', payload, httpOptions).toPromise().catch(err =>
        this.showResponse('error', err.status + ' - Houve um erro ao salvar a relação de família de API!')
      )
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  public loadCliente(tipoParticipanteId: number) {
    this.tipoParticipanteId = tipoParticipanteId
    this.modalParticipante = true
  }

  public salvarCliente(data) {
    this.codigoCliente = data.selectedParticipant.cod_part
    this.nomeFantasiaCliente = data.selectedParticipant.fantasia
    this.config.cliente_padrao_id = data.selectedParticipant.part_emp_id
    this.validationConfig()
  }

  public validationConfig(): void {
    this.isValid.emit({ validatedSteps: ( this.config.cliente_padrao_id ) })
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso: ', detail: message })
      break
    }
  }

  private async getOptOperation(): Promise<void> {
    this.loadingIconOptOper = true
    try {
      await this.httpService.get(`/operacaoregra?$select=id,cod,descricao&$filter=empresa/id eq ${this.dblinkedSessionService.empresa.id} and ativo eq true and tipoEntradaSaida/id eq 2&$orderby=cod`).toPromise()
      .then(res => res["value"].forEach(element => this.optOperation.push({label: element.cod + " - " + element.descricao, value: element.id})))
      .catch(err => this.showResponse('error', 'Houve um erro ao buscar as operações: ' + err.error.message))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingIconOptOper = false
    }
  }

}
