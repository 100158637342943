export class TbIrpjN620 {
  id: number;
  cod:  string;
  descricao:  string;
  dtIni:  string;
  dtFin:  string;
  ordem:  string;
  tipo:  string;
  formato:  string;
  linhaEcf:  string;
  formula:  string;



  constructor(tbirpjn620?: TbIrpjN620 | number) {
    if (tbirpjn620 !== undefined && tbirpjn620 !== null) {
      if (typeof tbirpjn620 === 'number') {
        this.id = tbirpjn620;
      } else {
        this.id = tbirpjn620.id;
        this.cod = tbirpjn620.cod;
        this.descricao = tbirpjn620.descricao;
        this.dtIni = tbirpjn620.dtIni;
        this.dtFin = tbirpjn620.dtFin;
        this.ordem = tbirpjn620.ordem;
        this.tipo = tbirpjn620.tipo;
        this.formato = tbirpjn620.formato;
        this.linhaEcf = tbirpjn620.linhaEcf;
        this.formula = tbirpjn620.formula;

            }
    }
  }
}
