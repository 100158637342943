import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { isNullOrUndefined } from 'util';
import { ConfigService } from 'app/shared/services/config.service';
import { ViewCampos } from 'app/cadastro/model/view-campos.model';
import { TodoProjetoOrigem } from 'app/centralweb/model/todo-projeto-origem.model';
import { TodoProjeto } from 'app/centralweb/model/todo-projeto.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { TodoPainel } from 'app/centralweb/model/todo-painel.model';

@Component({
  selector: 'app-fluxo-aprov',
  templateUrl: './fluxo-aprov.component.html',
  styleUrls: ['./fluxo-aprov.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class FluxoAprovComponent implements OnInit, OnDestroy {

  @Input() paramTipo: any;
  @Input() classTipo: any;
  @Input() projeto: any;

  nome: string;
  tipoRms: number;
  origemId: number;
  objetoId: number;
  categoriaId: number;
  urgenciaId: number;
  empresaId: number;
  responsavelId: number;
  projetoId: number;
  painelId: number;

  editFluxo: boolean = true;
  editandoLinha: boolean;
  class: string;
  linhaEditada: any;
  sql: string;
  tipoOrigem: string;
  origemSelecionada: string;
  campoData: string;

  opcoesTipoRms: SelectItem[];
  opcoesOrigemRms: SelectItem[];
  opcoesCategoriaRms: SelectItem[];
  opcoesUrgenciaRms: SelectItem[];
  opcoesObjetoRms: SelectItem[];
  opcoesAcoesRms: SelectItem[];
  opcoesStatusRms: SelectItem[];
  opcoesParticipantes: SelectItem[];
  opcoesMarcadores: SelectItem[];
  opcoesPaineis: SelectItem[];
  opcoesData: SelectItem[];

  detalhes: Array<any> = new Array();
  fluxos: Array<any> = new Array();
  viewCampos: Array<ViewCampos> = new Array();
  opcoesOrigem: SelectItem[];

  cols: any[];

  addPart: boolean;
  addAdm: boolean;
  idsUsuario: Array<number> = [];
  idsMarcador: Array<number> = [];

  constructor(
    private configService: ConfigService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) {

    this.opcoesCategoriaRms = [];
    this.opcoesTipoRms = [];
    this.opcoesOrigemRms = [];
    this.opcoesUrgenciaRms = [];
    this.opcoesAcoesRms = [];
    this.opcoesStatusRms = [];
    this.opcoesOrigem = [];
    this.opcoesData = [];

    this.class = 'col-sm-12';
    this.tipoOrigem = 'RMS';

    this.campoData = '';

    this.tipoRms = 0;
    this.origemId = 0;
    this.objetoId = 0;
    this.categoriaId = 0;
    this.urgenciaId = 0;
    this.empresaId = 0;
    this.responsavelId = 0;
    this.painelId = 0;
    this.origemSelecionada = 'RMS';
    this.editandoLinha = false;
    this.addPart = false;
    this.addAdm = false;
    this.idsUsuario = new Array();
    this.idsMarcador = new Array();

    this.editFluxo = true;

    this.detalhes = new Array();

    setTimeout(() => {
      this.empresaChanged()
    }, 1000);

  }

  ngOnInit() {




  }

  ngOnDestroy() {

  }


  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.opcoesParticipantes = [];

      this.editFluxo = true;
      this.projetoId = this.projeto;

      this.httpService.wait();
      this.httpService.get('/custom/cadastro/get-origens-cadastradas(' + this.projetoId + ')')
        .subscribe(ret => this.loadOrigensCadastradas(ret),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/workflow/get-users-projeto(' + this.projetoId + ')')
        .subscribe(ret => this.loadParticipantes(ret),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/workflow/get-marcadores-projeto(' + this.projetoId + ')')
        .subscribe(ret => this.loadMarcadores(ret),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/workflow/get-paineis-projeto(' + this.projetoId + ')')
        .subscribe(ret => this.loadPaineis(ret),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());


      this.httpService.wait();
      this.httpService.get('/custom/cadastro/get-origens-wf')
        .subscribe(ret => this.loadOrigens(ret),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());


      this.httpService.wait();
      this.httpService.get('/custom/cadastro/get-campos-view-origem(' + this.tipoOrigem + ')')
        .subscribe(ret => this.loadCamposView(ret),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      /*this.httpService.wait();
      this.httpService.get('/custom/cadastro/get-fluxos(' + this.dblinkedSessionService.empresa.id +
        ',' + this.tipoOrigem + ')')
        .subscribe(ret => this.loadFluxos(ret),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());*/

    }

  }

  buscarCamposView() {
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-campos-view-origem(' + this.origemSelecionada + ')')
      .subscribe(ret => this.loadCamposView(ret),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());
  }

  loadFluxos(ret: any) {

    this.fluxos = [];

    ret.forEach(element => {
      this.fluxos.push(element);
    });

  }

  loadCamposView(ret: any) {

    this.opcoesData = [];

    this.viewCampos = ret;
    ret.forEach(element => {
      if (element.tipo.toString().toLowerCase() === 'data') {
        this.opcoesData.push({ label: element.campo, value: element.campo });

      }
    });



  }

  loadOrigens(ret: any) {
    this.opcoesOrigem = [];

    ret.forEach(element => {
      this.opcoesOrigem.push({ label: element.origem, value: element.origem });
    });

  }

  loadParticipantes(ret: any) {

    this.opcoesParticipantes = [];

    ret.forEach(element => {
      this.opcoesParticipantes.push({ label: element.login, value: element.usuarioId });

    });

  }

  loadMarcadores(ret: any) {

    this.opcoesMarcadores = [];

    ret.forEach(element => {
      console.log(element);
      this.opcoesMarcadores.push({ label: element.marcador, value: element.marcadorId });

    });

  }

  loadPaineis(ret: any) {

    this.opcoesPaineis = [];

    ret.forEach(element => {
      this.opcoesPaineis.push({ label: element.painel, value: element.painelId });

    });

  }

  loadOrigensCadastradas(ret: any) {

    this.detalhes = new Array();
    this.editFluxo = true;

    ret.forEach(element => {
      let wfOrig = new TodoProjetoOrigem();
      wfOrig.sql = element.sql;
      wfOrig.origem = element.origem;
      wfOrig.todoProjeto = new TodoProjeto();
      wfOrig.todoProjeto.id = element.projetoId;
      wfOrig.addAdm = element.addAdm;
      wfOrig.addPart = element.addPart;
      wfOrig.participantes = element.participantes;
      wfOrig.marcadores = element.marcadores;
      wfOrig.responsavel = new AuthUser();
      if (!isNullOrUndefined(element.responsavel.id)) {
        wfOrig.responsavel.id = element.responsavel.id;
      }
      wfOrig.todoPainel = new TodoPainel();
      if (!isNullOrUndefined(element.todoPainel.id)) {
        wfOrig.todoPainel.id = element.todoPainel.id;
      }
      wfOrig.campoData = element.campoData;

      this.detalhes.push(wfOrig);

    });
  }

  loadInfos(ret: any) {

    this.opcoesCategoriaRms = [];
    this.opcoesTipoRms = [];
    this.opcoesOrigemRms = [];
    this.opcoesUrgenciaRms = [];
    this.opcoesObjetoRms = [];
    this.opcoesAcoesRms = [];
    this.opcoesStatusRms = [];

    this.opcoesCategoriaRms.push({ label: 'Selecione a Categoria', value: 0 });
    this.opcoesTipoRms.push({ label: 'Selecione o Tipo', value: 0 });
    this.opcoesOrigemRms.push({ label: 'Selecione a Origem', value: 0 });
    this.opcoesUrgenciaRms.push({ label: 'Selecione a Urgência', value: 0 });
    this.opcoesObjetoRms.push({ label: 'Selecione o Objeto', value: 0 });
    this.opcoesAcoesRms.push({ label: 'Selecione a Ação', value: 0 });
    this.opcoesStatusRms.push({ label: 'Selecione o Status', value: 0 });

    if (ret.categoria) {
      ret.categoria.forEach(element => {

        this.opcoesCategoriaRms.push({ label: element.nome, value: element.id });
      });
    }
    if (ret.tipo) {
      ret.tipo.forEach(element => {
        this.opcoesTipoRms.push({ label: element.nome, value: element.id });
      });
    }
    if (ret.origem) {
      ret.origem.forEach(element => {
        this.opcoesOrigemRms.push({ label: element.nome, value: element.id });
      });
    }
    if (ret.urgencia) {
      ret.urgencia.forEach(element => {
        this.opcoesUrgenciaRms.push({ label: element.nome, value: element.id });
      });
    }
    if (ret.objeto) {
      ret.objeto.forEach(element => {
        this.opcoesObjetoRms.push({ label: element.nome, value: element.id });
      });
    }
    if (ret.status) {
      ret.status.forEach(element => {
        this.opcoesStatusRms.push({ label: element.nome, value: element.id });
      });
    }
    if (ret.acoes) {
      ret.acoes.forEach(element => {
        this.opcoesAcoesRms.push({ label: element.nome, value: element.id });
      });
    }

  }

  isCardVinculacao: boolean = true;
  currentStateVinculacao = 'initial';
  changeStateVinculacao() {
    this.currentStateVinculacao = this.currentStateVinculacao === 'initial' ? 'final' : 'initial';
  }

  ShowHIdeCardVinculacao() {
    this.isCardVinculacao = !this.isCardVinculacao;
  }


  salvarFluxoAprov() {
    if (this.fluxoValidado()) {
      console.log(this.detalhes);
      const listasEnviar = {
        detalhes: this.detalhes,
        usuarioId: this.sessionService.loggedUser.id,
        empresa: this.dblinkedSessionService.empresa.id,
        TodoProjeto: this.projetoId
      };

      this.httpService.wait();
      this.httpService.post('/custom/cadastro/salvar-fluxo-aprov', listasEnviar)
        .subscribe(result => {
          if (!result.error) {
            this.empresaChanged();
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Msg:', detail: result.message });
          } else {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: result.message });
          }

        },
          error => this.httpService.handleError(error, () => this.salvarFluxoAprov()),
          () => this.httpService.done());
    }


  }

  private fluxoValidado(): boolean {

    let bool: boolean;
    bool = true;

    if (this.paramTipo === 'rms') {
      if (isNullOrUndefined(this.nome)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: 'Nome é Obrigatório' });
        bool = false;
        return;
      } else if (this.nome.length < 5) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: 'Nome precisa ter ao menos 5 caracteres' });
        bool = false;
        return;
      } else if (this.tipoRms === 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: 'Tipo de RMS precisa ser preenchida' });
        bool = false;
        return;
      } else if (this.origemId === 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: 'Origem da RMS precisa ser preenchida' });
        bool = false;
        return;
      } else if (this.objetoId === 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: 'Objeto da RMS precisa ser preenchida' });
        bool = false;
        return;
      } else if (this.categoriaId === 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: 'Categoria da RMS precisa ser preenchida' });
        bool = false;
        return;
      } else if (this.urgenciaId === 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: 'Urgência da RMS precisa ser preenchida' });
        bool = false;
        return;
      }



    } else {

    }

    return bool;
  }


  hideMessage() {

  }

  addLinha() {

    if (this.painelId === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: 'É necessário informar um Painel' });
      return;
    } else if (this.sql === '') {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: 'É necessário informar uma Condição' });
      return;
    }

    let wfOrig = new TodoProjetoOrigem();
    wfOrig.sql = this.sql;
    wfOrig.origem = this.origemSelecionada;
    wfOrig.todoProjeto = new TodoProjeto();
    wfOrig.todoProjeto.id = this.projetoId;
    wfOrig.addAdm = this.addAdm;
    wfOrig.addPart = this.addPart;
    wfOrig.participantes = this.idsUsuario;
    wfOrig.marcadores = this.idsMarcador;
    wfOrig.responsavel = new AuthUser();
    wfOrig.responsavel.id = this.responsavelId;
    wfOrig.todoPainel = new TodoPainel();
    wfOrig.todoPainel.id = this.painelId;
    wfOrig.campoData = this.campoData;
    this.detalhes.push(wfOrig);

    this.sql = '';
    this.idsUsuario = new Array();
  }

  salvarLinha() {

    if (this.painelId === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: 'É necessário informar um Painel' });
      return;
    } else if (this.sql === '') {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Msg:', detail: 'É necessário informar uma Condição' });
      return;
    }

    const index = this.detalhes.findIndex(c => c === this.linhaEditada);
    this.detalhes.splice(index, 1);


    let wfOrig = new TodoProjetoOrigem();
    wfOrig.sql = this.sql;
    wfOrig.origem = this.origemSelecionada;
    wfOrig.todoProjeto = new TodoProjeto();
    wfOrig.todoProjeto.id = this.projetoId;
    wfOrig.addPart = this.addPart;
    wfOrig.addAdm = this.addAdm;
    wfOrig.participantes = this.idsUsuario;
    wfOrig.marcadores = this.idsMarcador;
    wfOrig.responsavel = new AuthUser();
    wfOrig.responsavel.id = this.responsavelId;
    wfOrig.todoPainel = new TodoPainel();
    wfOrig.todoPainel.id = this.painelId;
    wfOrig.campoData = this.campoData;

    this.detalhes.push(wfOrig);

    this.editandoLinha = false;
    this.idsUsuario = new Array();
  }

  obterStatusOrigem(status) {
    var resultado;

    this.opcoesStatusRms.forEach(element => {
      if (element["value"] == status.statusOrigemId) {
        resultado = element["label"];
        status.nome = element["label"];
      }
    });

    return resultado;
  }

  obterStatusDestino(status) {
    var resultado;

    this.opcoesStatusRms.forEach(element => {
      if (element["value"] == status.statusDestinoId) {
        resultado = element["label"];
        status.nome = element["label"];
      }
    });

    return resultado;
  }

  obterAcao(acao) {
    var resultado;

    this.opcoesAcoesRms.forEach(element => {
      if (element["value"] == acao.acoesId) {
        resultado = element["label"];
        acao.nome = element["label"];
      }
    });

    return resultado;
  }

  removerFluxo(event: any) {
    if (confirm('Deseja realmente excluir este fluxo?')) {

      console.log(event);
      const index = this.detalhes.findIndex(c => c === event);
      this.detalhes.splice(index, 1);
    }
  }

  editarFluxo(event: any) {
    console.log(event);

    this.idsUsuario = new Array();
    this.idsMarcador = new Array();

    this.editFluxo = true;
    this.editandoLinha = true;
    this.linhaEditada = event;
    this.sql = event.sql;
    this.origemSelecionada = event.origem;
    this.addPart = event.addPart;
    this.addAdm = event.addAdm;
    this.idsUsuario = event.participantes;
    this.idsMarcador = event.marcadores;
    this.campoData = event.campoData;
    this.origemSelecionada = event.origem;

    if (!isNullOrUndefined(event.responsavel.id)) {
      this.responsavelId = event.responsavel.id;
    } else {
      this.responsavelId = 0;
    }

    if (!isNullOrUndefined(event.todoPainel.id)) {
      this.painelId = event.todoPainel.id;
    } else {
      this.painelId = 0;
    }


  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  voltarEdit() {

    this.tipoRms = 0;
    this.origemId = 0;
    this.objetoId = 0;
    this.categoriaId = 0;
    this.urgenciaId = 0;
    this.detalhes = [];
  }

  addFluxo() {
    this.editFluxo = true;
    this.nome = '';
    this.tipoRms = 0;
    this.origemId = 0;
    this.objetoId = 0;
    this.categoriaId = 0;
    this.urgenciaId = 0;
    this.detalhes = [];
    this.addPart = true;
    this.addAdm = true;
  }

  removerLinha(event: any) {
    if (confirm('Deseja excluir esta linha?')) {
      const index = this.detalhes.findIndex(c => c === event);
      this.detalhes.splice(index, 1);
    }
  }

  onSelectViewCampo(campo: string) {
    if (this.sql === null || this.sql === undefined)
      this.sql = `${campo}`;
    else
      this.sql += ` ${campo}`;
  }


}
