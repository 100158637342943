import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Area } from '../model/area-model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';
import exportExcel from 'app/util/processing/exportExcel';


@Component({
  selector: 'app-cadastro-area',
  templateUrl: './cadastro-area.component.html',
  styleUrls: ['./cadastro-area.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadastroAreaComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = [];
  titlePrincipal: string;
  subtitle: string;
  modalNew: boolean = false;
  modalEdit: boolean = false;
  editTool: boolean = false;
  newCodigo: string;
  newDescricao: string;
  newCondicao: string;
  editCodigo: string;
  editDescricao: string;
  editCondicao: string;
  areaCodigo: string;
  areaDescricao: string;
  subareaCodigo: string;
  subareaDescricao: string;
  listaAreas: Array<Area>;
  listaSubareas: Array<any>;
  projetosLdb: SelectItem[];
  areaId: number;
  subareaId: number;
  listaAreaSubareaDisponiveis: Array<any>;
  listaAreaSubareaVinculados: Array<any>;
  listaSegmentosDisponiveis: Array<any>;
  listaSegmentosVinculados: Array<any>;
  statusTelaArea: boolean = true;
  statusTelaSubarea: boolean = false;
  TelaSubarea: boolean = true;
  TelaCond: boolean = false;
  statusTelaSub: boolean = false;
  statusTelaCond: boolean = false;
  cp: boolean;
  cc: boolean;

  centrosCusto: Array<any> = new Array();
  projetos: Array<any> = new Array();
  colslistaAreas: any[] = [];
  colslistaSubareas: any[] = [];

  selectedAreas: Array<any> = new Array();
  selectedSubAreas: Array<any> = new Array();

  checkedAll: boolean = false;

  modalVincularCentroCusto: boolean = false
  listaCentrosCustoDisponiveis: Array<any> = new Array()
  listaCentrosCustoVinculados: Array<any> = new Array()

  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }


  @ViewChild('dt1') dt1: Table
  export() {
    this.dt1.exportCSV()
  }

  public toolbarMain = new Toolbar()
  public toolbarMainSub = new Toolbar()

  toolbarMainIni = [
    { key: 'btn-relate', icon: 'sync_alt', text: 'Relacionar', tooltip: 'Relacionar Cetros de Custo a Área', visible: false, disabled: false, color: 'default', onClick: () => this.showModalRelateCostCenter() },
    { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openEditModal() },
    { key: 'btn-delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.deleteAreaSubarea() },
    { key: 'btn-add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.openNewModal() },
    { key: 'btn-download', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: false, disabled: false, color: 'default', onClick: () => this.exportAll() }
  ]

  toolbarMainIniSub = [
    {
      key: 'btn-expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardCond()
        this.changeStateCond()
      }
    },
  ]

  ngOnInit(): void {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarMainSub.setAll(this.toolbarMainIniSub)
    this.toolbarService.hidden = true
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.titlePrincipal = 'CONFIGURAÇÕES ORÇAMENTO';
    this.subtitle = 'CADASTRO DE ÁREAS E SUBÁREAS';

    this.colslistaAreas = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRICÃO' },
      { field: 'data', header: 'ÚLTIMA EDIÇÃO' },
    ];

    this.colslistaSubareas = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRICÃO' },
      { field: 'data', header: 'ÚLTIMA EDIÇÃO' },
    ];

    this.colslistaAreas = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRICÃO' },
      { field: 'dataFormatada', header: 'ÚLTIMA EDIÇÃO' },
    ];

    this.colslistaSubareas = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRICÃO' },
      { field: 'dataFormatada', header: 'ÚLTIMA EDIÇÃO' },
    ];

    this.selectedAreas = new Array();
    this.selectedSubAreas = new Array();

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.loadAreas();
        this.loadSubareas();
        this.loadAreaSubareaRelac();
        this.buscarProjetos();
        this.buscarCentroCusto();
        this.statusTelaArea = true;
        this.statusTelaSubarea = false;
        this.statusTelaSub = false;
        this.statusTelaCond = false;
        this.TelaSubarea = true;
        this.TelaCond = false;
        this.cp = false;
        this.cc = false;

        this.changeToolbar()
      }
    });
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    this.selectedAreas = new Array()
    this.selectedSubAreas = new Array()
    this.handleSelect()
    this.loadAreas()
    this.loadSubareas()
  }

  loadAreaSubareaRelac() {
    if ((this.areaId !== null && this.areaId !== undefined) || (this.subareaId !== null && this.subareaId !== undefined)) {
      const dados = {
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        areaId: this.areaId,
        subareaId: this.subareaId ? this.subareaId : null,
        area: this.statusTelaArea ? 'true' : 'false'
      };

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/get-area-subarea-relac', dados)
        .subscribe(
          res => {

            this.listaAreaSubareaDisponiveis = new Array();
            this.listaAreaSubareaVinculados = new Array();
            const resultVinc = res['result_vinculados'];
            for (const line of resultVinc) {
              this.listaAreaSubareaVinculados.push({ codigo: line[1], descricao: line[2], id: line[0] });
            }
            const resultDisp = res['result_disponiveis'];
            for (const line of resultDisp) {
              this.listaAreaSubareaDisponiveis.push({ codigo: line[1], descricao: line[2], id: line[0] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem({ error: true, mensagem: err });
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  salvarVinculacaoAreaSubarea() {
    if (this.listaAreaSubareaDisponiveis !== null && this.listaAreaSubareaDisponiveis !== undefined && this.listaAreaSubareaVinculados !== null && this.listaAreaSubareaVinculados !== undefined) {

      /* Criar as listas contendo os IDs dos registros disponíveis e vinculados */
      var listaDisp: Array<any> = new Array();
      var listaVinc: Array<any> = new Array();
      for (const line of this.listaAreaSubareaDisponiveis) {
        listaDisp.push(line.id);
      }
      for (const line of this.listaAreaSubareaVinculados) {
        listaVinc.push(line.id);
      }

      const dados = {
        dadosDisponiveis: listaDisp,
        dadosVinculados: listaVinc,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        areaId: this.areaId,
        subareaId: this.subareaId,
        area: this.statusTelaArea ? 'true' : 'false',
      };

      if ((this.statusTelaArea === true) && (listaVinc.length < 1)) {

        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione no máximo, uma área para vinculação." });
      } else {
        this.httpService.wait();
        this.httpService.post('/custom/orcamento/salvar-vinculacao-area-subarea', dados)
          .subscribe(
            res => {
              this.exibirMensagem({ error: false, mensagem: 'Registro vinculado com sucesso!' });
              this.httpService.done();
              this.change();
              this.loadAreaSubareaRelac();
            },
            err => {
              this.exibirMensagem({ error: true, mensagem: 'Não foi possível vincular esse registro!' });
              console.log(err);
              this.httpService.done();
            }
          );
      }
    }
  }

  selectCheckboxSubarea(event: any){
    this.listaSubareas.map((area) => {
      if(area.id == event.data.id && area.codigo == event.data.codigo){
        area.selected = true;
      }
    })
    this.validateEdit();
  }

  unselectCheckboxSubarea(event: any){
    this.listaSubareas.map((area) => {
      if(area.id == event.data.id && area.codigo == event.data.codigo){
        area.selected = false;
      }
    })
    this.validateEdit();
  }

  validateEdit() {
    if (this.statusTelaArea == true) {
      if (this.selectedAreas.length > 1) {
        this.editTool = true;
        this.statusTelaCond = false;
        this.statusTelaSub = false;
        this.TelaCond = false;
      } else {
        this.editTool = false;
      }
    } else {
      if (this.selectedSubAreas.length > 1) {
        this.editTool = true;
      } else {
        this.editTool = false;
      }
    }

  }

  salvarCondicao() {

  }

  onAreaRowSelect(row) {
    if (this.selectedAreas.length > 1) {
      this.exibirMensagem({ error: true, mensagem: 'Para visualizar as vinculações desta área, desmarque todas as áreas.' });
      return;
    }
    this.areaId = row.id;
    this.areaCodigo = row.codigo;
    this.areaDescricao = row.descricao;
    this.statusTelaSubarea = false;
    this.TelaCond = true;
    this.statusTelaSub = true;
    this.statusTelaCond = false;
    this.cp = false;
    this.cc = true;
    this.loadAreaSubareaRelac();
  }

  editAreaSubarea() {
    if (!this.editCodigo || !this.editCodigo) {
      this.exibirMensagem({ error: true, mensagem: 'Preencha os dois campos.' });
    } else {
      const dados = {
        areaId: this.areaId,
        subareaId: this.subareaId,
        area: this.statusTelaArea ? true : false,
        codigo: this.editCodigo.toString(),
        descricao: this.editDescricao.toString(),
        condicao: this.editCondicao.toString(),
        userid: this.sessionService.loggedUser.id.toString()
      };
      this.httpService.wait();
      this.httpService.post('/custom/orcamento/edit-area-subarea', dados)
        .subscribe(
          res => {
            this.exibirMensagem({ error: false, mensagem: `${this.statusTelaArea ? 'Área' : 'Subárea'} alterada com sucesso!` });
            this.httpService.done();
            this.change();
          },
          err => {
            this.exibirMensagem({ error: true, mensagem: 'Não foi possível editar esse registro!' });
            console.log(err);
            this.httpService.done();
          }
        );
      this.modalEdit = false;
    }
  }


  loadAreas() {
    const dados = {
      Authorization: this.configService.bearerApi,
      empresaid: this.dblinkedSessionService.empresa.id.toString()
    };
    this.httpService.wait();
    this.httpService.post('/custom/orcamento/get-areas', dados)
      .subscribe(
        res => {

          this.listaAreas = new Array();
          const result = res['result'];

          // ORDENA O RESULTADO RECEBIDO POR CODIGO, DO MENOR PARA O MAIOR
          for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < result.length; j++) {
              if (parseInt(result[i][0]) < parseInt(result[j][0])) {
                const aux = result[i];
                result[i] = result[j];
                result[j] = aux;
              }
            }
          }

          for (const line of result) {
            this.listaAreas.push({
              codigo: line[0],
              descricao: line[1],
              data: line[2],
              id: line[3],
              selected: false,
              empresaId: new Empresa(this.dblinkedSessionService.empresa.id)
            });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: err });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  loadSubareas() {

    const dados = {
      Authorization: this.configService.bearerApi,
      empresaid: this.dblinkedSessionService.empresa.id.toString()
    };
    this.httpService.wait();
    this.httpService.post('/custom/orcamento/get-subareas', dados)
      .subscribe(
        res => {

          this.listaSubareas = new Array();
          const result = res['result'];

          // ORDENA O RESULTADO RECEBIDO POR CODIGO, DO MENOR PARA O MAIOR

          for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < result.length; j++) {
              if (parseInt(result[i][0]) < parseInt(result[j][0])) {
                const aux = result[i];
                result[i] = result[j];
                result[j] = aux;
              }
            }
          }

          for (const line of result) {
            this.listaSubareas.push({
              codigo: line[0],
              descricao: line[1],
              data: line[2],
              id: line[3],
              condicao: line[5],
              selected: false
            });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: err });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  hideMessage() {

  }



  openEditModal() {
    if (this.statusTelaArea === true) {
      if (this.selectedAreas.length > 0) {
        this.modalEdit = true;
        this.areaId = parseInt(this.statusTelaArea[0].id)
        this.editCodigo = this.statusTelaArea[0].codigo;
        this.editDescricao = this.statusTelaArea[0].descricao;
      } else {
        this.exibirMensagem({ error: true, mensagem: 'Selecione uma área para editar.' });
      }
    } else {
      if (this.selectedSubAreas.length > 0) {
        this.modalEdit = true;
        this.subareaId = parseInt(this.selectedSubAreas[0].id);
        this.editCodigo = this.selectedSubAreas[0].codigo;
        this.editDescricao = this.selectedSubAreas[0].descricao;
        this.editCondicao = this.selectedSubAreas[0].condicao;
      } else {
        this.exibirMensagem({ error: true, mensagem: 'Selecione uma subárea para editar.' });
      }
    }
  }


  openNewModal() {
    this.modalNew = true;
    this.newCodigo = '';
    this.newDescricao = '';
    this.newCondicao = '';
  }

  addAreaSubarea() {
    if (this.statusTelaArea) {
      if ((this.newCodigo.toString() === '') ||
        (this.newDescricao.toString() === '')) {


        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Preencha os dois campos." });

      } else {
        const invalidCode = this.listaAreas.filter((area) => {
          return area.codigo == this.newCodigo ? true : false;
        });

        if (invalidCode.length > 0) {
          this.exibirMensagem({
            error: true, mensagem: 'Este código já está sendo utilizado por outra área.'
          });
        } else {
          this.modalNew = false;
          const dados = {
            Authorization: this.configService.bearerApi,
            empresaid: this.dblinkedSessionService.empresa.id.toString(),
            userid: this.sessionService.loggedUser.id.toString(),
            codigo: this.newCodigo.toString(),
            descricao: this.newDescricao.toString(),
            area: this.statusTelaArea ? 'true' : 'false',
            condicao: this.newCondicao.toString()
          };

          this.httpService.wait();
          this.httpService.post('/custom/orcamento/add-area-subarea', dados)
            .subscribe(
              res => {
                this.exibirMensagem({ error: false, mensagem: 'Área cadastrada com sucesso!' });
                this.httpService.done();
                this.change();
              },
              err => {
                this.exibirMensagem({ error: true, mensagem: err });
                console.log(err);
                this.httpService.done();
              }
            );
        }
      }
    } else {
      if ((this.newCodigo.toString() === '') ||
        (this.newDescricao.toString() === '')) {

        this.exibirMensagem({
          error: true, mensagem: 'Preencha os dois campos.',
        });

      } else {
        const invalidCode = this.listaSubareas.filter((area) => {
          return area.codigo == this.newCodigo ? true : false;
        });

        if (invalidCode.length > 0) {
          this.exibirMensagem({
            error: true, mensagem: 'Este código já está sendo utilizado por outra subárea.'
          });
        } else {
          this.modalNew = false;
          const dados = {
            Authorization: this.configService.bearerApi,
            empresaid: this.dblinkedSessionService.empresa.id.toString(),
            userid: this.sessionService.loggedUser.id.toString(),
            codigo: this.newCodigo.toString(),
            descricao: this.newDescricao.toString(),
            area: this.statusTelaArea ? 'true' : 'false',
            condicao: this.newCondicao.toString()

          };

          this.httpService.wait();
          this.httpService.post('/custom/orcamento/add-area-subarea', dados)
            .subscribe(
              res => {
                this.exibirMensagem({ error: false, mensagem: 'Subárea cadastrada com sucesso!' });
                this.httpService.done();
                this.change();
              },
              err => {
                this.exibirMensagem({ error: true, mensagem: err });
                console.log(err);
                this.httpService.done();
              }
            );
        }
      }
    }
  }

  deleteAreaSubarea() {
    if (this.statusTelaArea) {
      if (this.selectedAreas.length > 0) {
        if (!confirm(`Tem certeza que deseja excluir ${this.selectedAreas.length} registro${this.selectedAreas.length > 1 ? 's' : ''}?`)) { return; }
        const dados = this.selectedAreas.map((area) => {
          return {
            empresaid: this.dblinkedSessionService.empresa.id.toString(),
            areaId: area.id,
            area: true,
          };
        });

        this.httpService.wait();
        this.httpService.post('/custom/orcamento/delete-area-subarea', dados)
          .subscribe(
            res => {
              this.exibirMensagem({
                error: false,
                mensagem: `Áre${this.selectedAreas.length > 1 ? 'as' : 'a'} deletad${this.selectedAreas.length > 1 ? 'as' : 'a'} com sucesso!`
              });
              this.httpService.done();
              this.change();
            },
            err => {
              this.exibirMensagem({ error: true, mensagem: 'Não foi possível excluir esse registro!' });
              console.log(err);
              this.httpService.done();
            }
          );
      } else {
        this.exibirMensagem({ error: true, mensagem: 'Selecione um registro para excluir.' });
      }
    } else {
     if (this.selectedSubAreas.length > 0) {
        if (!confirm(`Tem certeza que deseja excluir ${this.selectedSubAreas.length} registro${this.selectedSubAreas.length > 1 ? 's' : ''}?`)) { return; }
        const dados = this.selectedSubAreas.map((subarea) => {
          return {
            empresaid: this.dblinkedSessionService.empresa.id.toString(),
            subareaId: subarea.id,
            area: false,
          };
        });
        this.httpService.wait();
        this.httpService.post('/custom/orcamento/delete-area-subarea', dados)
          .subscribe(
            res => {
              this.exibirMensagem({
                error: false,
                mensagem: `Subáre${this.selectedSubAreas.length > 1 ? 'as' : 'a'} deletad${this.selectedSubAreas.length > 1 ? 'as' : 'a'} com sucesso!`
              });
              this.httpService.done();
              this.change();
            },
            err => {
              this.exibirMensagem({ error: true, mensagem: 'Não foi possível excluir esse registro!' });
              console.log(err);
              this.httpService.done();
            }
          );
      } else {
        this.exibirMensagem({ error: true, mensagem: 'Selecione um registro para excluir.' });
      }
    }
  }

  exibirMensagem(value: any) {
    if (!value.error) this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem })
    else this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem })
  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  //Show/hide cards
  isCardTable: boolean = true;
  isCardCond: boolean = true;

  currentStateTable = 'initial';
  currentStateCond = 'initial';

  ShowHIdeCardCond() {
    this.isCardCond = !this.isCardCond;
    this.toolbarMainSub.setIcon('btn-expand', this.isCardCond ? 'expand_less' : 'expand_more')
    this.toolbarMainSub.setTooltip('btn-expand', this.isCardCond ? 'Ver menos' : 'Ver mais')
  }

  changeStateTable() {
    this.currentStateTable = this.currentStateTable === 'initial' ? 'final' : 'initial';
  }

  changeStateCond() {
    this.currentStateCond = this.currentStateCond === 'initial' ? 'final' : 'initial';
  }

  // Telas
  showTelaAreas(tipo) {
    this.listaAreaSubareaDisponiveis = new Array();
    this.listaAreaSubareaVinculados = new Array();

    if (tipo === 'TELA_1') {
      this.statusTelaArea = true;
      this.statusTelaSubarea = false;
      this.statusTelaSub = false;
      this.statusTelaCond = false;
      this.subareaId = null;
      this.TelaCond = false;
    } else if (tipo === 'TELA_2') {
      this.statusTelaSubarea = true;
      this.statusTelaArea = false;
      this.statusTelaSub = false;
      this.statusTelaCond = false;
      this.TelaCond = false;
      this.areaId = null;
    } else if (tipo === 'TELA_SUB') {
      this.statusTelaSub = true;
      this.statusTelaCond = false;
      this.statusTelaSub = false;
      this.loadAreaSubareaRelac();

    } else if (tipo === 'TELA_COND') {
      this.statusTelaCond = true;
      this.statusTelaSub = false;
      this.cc = true;
    } else if (tipo === 'CC') {
      this.cc = true;
      this.cp = false;
    } else if (tipo === 'CP') {
      this.cc = false;
      this.cp = true;
    }
  }

  buscarProjetos() {
    this.projetos = new Array();
    this.httpService.wait();
    this.httpService.get('/cadproj?$select=id,codProj,descrProj&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      '&$orderby=codProj').subscribe(cp => this.projetos = cp.value,
        error => this.httpService.handleError(error, () => this.buscarProjetos()),
        () => this.httpService.done());

  }

  buscarCentroCusto() {
    this.centrosCusto = new Array();
    this.httpService.wait();
    this.httpService.get('/cadccus?$select=id,codCcus,descrCcus&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      '&$orderby=codCcus').subscribe(cc => this.centrosCusto = cc.value,
        error => this.httpService.handleError(error, () => this.buscarCentroCusto()),
        () => this.httpService.done());
  }

  handleSelect(): void {
    this.toolbarMain.setVisible('btn1', this.selectedAreas.length === 1 || this.selectedSubAreas.length === 1 ? true : false)
    this.toolbarMain.setVisible('btn2', this.selectedAreas.length >= 1 || this.selectedSubAreas.length >= 1 ? true : false)
    this.toolbarMain.setVisible('btn3', true)
    this.toolbarMain.setVisible('btn4', true)
  }

  exportAll() {
    if(this.statusTelaArea && !this.statusTelaSubarea) {
      if(this.listaAreas.length > 0) {
        const orderList = ['codigo', 'descricao', 'ultimaEdicao'];
        let exportList = [];

        this.listaAreas.forEach(item => {
          let dateConverted = new Date(item.data).toLocaleString('pt-BR', {timeZone: 'UTC'}).split(' ');
          let date = dateConverted[0];

          exportList.push({
            codigo: item.codigo,
            descricao: item.descricao,
            ultimaEdicao: date,
          })
        })
        exportExcel(exportList, 'Áreas', orderList)
      }

    } else if(this.statusTelaSubarea && !this.statusTelaArea) {
      if(this.listaSubareas.length > 0) {
        const orderList = ['codigo', 'descricao', 'ultimaEdicao'];
        let exportList = [];

        this.listaSubareas.forEach(item => {
          let dateConverted = new Date(item.data).toLocaleString('pt-BR', {timeZone: 'UTC'}).split(' ');
          let date = dateConverted[0];

          exportList.push({
            codigo: item.codigo,
            descricao: item.descricao,
            ultimaEdicao: date,
          })
        })
        exportExcel(exportList, 'Subáreas', orderList)
      }
    }
  }

  changeToolbar() {
    const condicaoUmaAreaSelecionada = this.selectedAreas.length === 1 || this.selectedSubAreas.length === 1 ? true : false
    const condicaoUmaOuMaisAreasSelecionadas = this.selectedAreas.length >= 1 || this.selectedSubAreas.length >= 1 ? true : false

    this.toolbarMain.setVisible('btn-edit', condicaoUmaAreaSelecionada)
    this.toolbarMain.setVisible('btn-delete', condicaoUmaOuMaisAreasSelecionadas)
    this.toolbarMain.setVisible('btn-add', true)
    this.toolbarMain.setVisible('btn-download', true)
    this.toolbarMain.setVisible('btn-relate', condicaoUmaAreaSelecionada)
    this.toolbarMain.setVisible('btn-expand-les', true)
  }

  showModalRelateCostCenter() {
    this.modalVincularCentroCusto = true
    this.getCentosCustoRelacaoArea()
  }

  getCentosCustoRelacaoArea() {
    let areaId = null
    if(this.selectedAreas.length > 0) areaId = Number(this.selectedAreas[0].id)

    const empresaId = this.dblinkedSessionService.empresa.id

    this.httpService.wait()
    this.httpService.get(`/custom/orcamento/listar-centro-custo-relacao-area(${empresaId},${areaId})`).subscribe(
      response => {
        if(response.error) this.exibirMensagem({ error: true, mensagem: 'Ocorreu um erro ao buscar as relações dos centros de custo e a área' })

        this.listaCentrosCustoDisponiveis = new Array()
        this.listaCentrosCustoVinculados = new Array()

        response.data.centrosCustoDisponiveis.forEach(data => {
          this.listaCentrosCustoDisponiveis.push({ id: data.id, descricao: data.descricao })
        })

        response.data.centrosCustoRelacionados.forEach(data => {
          this.listaCentrosCustoVinculados.push({ id: data.id, descricao: data.descricao })
        })
      },
      error => this.exibirMensagem({ error: true, mensagem: 'Erro de comunicação com o servidor! Contate o suporte.' }),
      () => this.httpService.done()
    )
  }

  salvarRelacaoAreaCentroCusto() {
    let areaId = null
    if(this.selectedAreas.length > 0) areaId = Number(this.selectedAreas[0].id)

    const centrosCustoIds = new Array()
    this.listaCentrosCustoVinculados.forEach(item => {
      centrosCustoIds.push(item.id)
    })

    const payload = {
      areaId: areaId,
      userId: this.sessionService.loggedUser.id,
      centrosCustoIds,
      empresaId: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait()
    this.httpService.post('/custom/orcamento/relacionar-area-centro-custo', payload).subscribe(
      response => {
        this.exibirMensagem({ error: response.error, mensagem: response.message })

        if(response.error) return

        this.modalVincularCentroCusto = false
        this.onHideModalVinculacaoCentroCusto()
      },
      error => {
        this.exibirMensagem({ error: true, mensagem: 'Não foi possível relacionar a área aos centros de custo' })
        this.httpService.done()
      },
      () => this.httpService.done()
    )
  }

  onHideModalVinculacaoCentroCusto() {
    this.listaCentrosCustoDisponiveis = new Array()
    this.listaCentrosCustoVinculados = new Array()
  }
}
