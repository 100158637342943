import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { HttpService } from "app/shared/services/http.service";
import { SessionService } from "app/shared/services/session.service";
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service";
import { ToolbarService } from '../../shared/services/toolbar.service';
import { ConfigService } from "app/shared/services/config.service";
import { TitleService } from "app/shared/services/title.service";
import { Subscription } from "rxjs";
import { Message, MessageService, SelectItem } from "primeng/api";
import { CrudService } from "app/shared/services/crud.service";
import { trigger, state, style, animate, transition } from "@angular/animations";
import * as Highcharts from "highcharts/highstock";
import HC_exportData from "highcharts/modules/export-data";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
HC_exportData(Highcharts);

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard1.component.html",
  styleUrls: ["./dashboard1.component.scss"],
  providers: [CrudService],
  animations: [
    trigger("changeDivSize", [
      state("initial", style({})),
      state(
        "final",
        style({
          opacity: "0",
          height: "0",
          padding: "0px 15px",
        })
      ),
      transition("initial=>final", animate("200ms")),
      transition("final=>initial", animate("200ms")),
    ]),
  ],
})

export class DashboardTransferComponent1
  implements OnInit, OnDestroy, AfterViewInit {
  private sessionSubscription: Subscription;
  models: any;
  permissions: any;
  viewMode: any;
  configId: any;

  msgs: Message[] = [];
  relatorioGerado: boolean;
  dtAtualizacao: Date;

  //Váriavies Manipula Tela
  statusTela1: boolean = false;
  statusTela2: boolean = false;
  statusTela3: boolean = false;
  statusTela4: boolean = false;
  statusTela5: boolean = false;
  statusTela6: boolean = false;

  //Váriavies com dados dos charts
  dataChart1: any;
  dataChart2: any;
  dataChart3: any;
  dataChart5: any;
  dataChart6: any;
  dataChart7: any;
  dataChart9: any;
  dataChart10: any;
  dataChart12: any;
  dataChart15: any;
  dataChart16: any;
  dataChart18: any;
  dataChart19: any;

  //Váriavies Manipula Card
  fullscreen: boolean;
  currentStateDash1 = "initial";
  currentStateDash2 = "initial";
  currentStateDash3 = "initial";
  currentStateDash4 = "initial";
  currentStateDash5 = "initial";
  currentStateDash6 = "initial";
  currentStateDash7 = "initial";
  currentStateDash8 = "initial";
  currentStateDash9 = "initial";
  currentStateDash10 = "initial";
  currentStateDash11 = "initial";
  currentStateDash12 = "initial";
  currentStateDash13 = "initial";
  currentStateDash14 = "initial";
  currentStateDash15 = "initial";
  currentStateDash16 = "initial";
  currentStateDash17 = "initial";
  currentStateDash18 = "initial";
  currentStateDash19 = "initial";
  currentStateDash20 = "initial";

  // Controle Hightcharts Status da Tela
  statusDash1: boolean = true;
  statusDash2: boolean = true;
  statusDash3: boolean = true;
  statusDash5: boolean = true;
  statusDash6: boolean = true;
  statusDash7: boolean = true;
  statusDash9: boolean = true;
  statusDash10: boolean = true;
  statusDash12: boolean = true;
  statusDash13: boolean = true;
  statusDash15: boolean = true;
  statusDash16: boolean = true;
  statusDash18: boolean = true;
  statusDash19: boolean = true;

  // Váriavies Estatícas Tabela de todas as Abas
  colsCapExpo: any[];
  opcoesCapExpo: any;
  opcoesCapExpoEscolhido: any;
  CapExpo: any[];
  TotaisCapExpo: any = {};

  colsCapExpoColig: any[];
  opcoesCapExpoColig: any;
  opcoesCapExpoColigEscolhido: any;
  CapExpoColig: any[];
  TotaisCapExpoColig: any = {};

  colsPrlRev: any[];
  opcoesPrlRev: any;
  opcoesPrlRevEscolhido: any;
  PrlRev: any[];
  TotaisPrlRev: any = {};

  colsImobilizado: any[];
  opcoesImobilizado: any;
  opcoesImobilizadoEscolhido: any;
  Imobilizado: any[];
  TotaisImobilizado: any = {};

  colsPicRev: any[];
  opcoesPicRev: any;
  opcoesPicRevEscolhido: any;
  PicRev: any[];
  TotaisPicRev: any = {};

  colsPrlVendProd: any[];
  opcoesPrlVendProd: any;
  opcoesPrlVendProdEscolhido: any;
  PrlVendProd: any[];
  TotaisPrlVendProd: any = {};

  selectedYear: number;
  selectedMonth: number;
  opcoesAno: SelectItem[] = [];
  opcoesMeses: SelectItem[] = [];
  loadSucess: boolean = false;

  colorList: Array<string> = new Array("#12239E", "#54B5FB", "#A3D0D4", "#E66C37", "#F18F49", "#F18F49", "#5C0001", "#B73A3A", "#D82C20", "#C4B078", "#C4B07B");

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpClient: HttpClient,
    private messageService: MessageService,
    private toolbarService: ToolbarService
  ) { }

  loadDonutChart(container, value) {
    new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: "pie",
        renderTo: container,
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormatter: function () {
          if (this.y >= 1e6) {
            return `${(this.y / 1e6).toFixed(3)}M - (${this.percentage.toFixed(
              0
            )}%)`;
          } else if (this.y >= 1e3) {
            return `${(this.y / 1e3).toFixed(3)}K - (${this.percentage.toFixed(
              0
            )}%)`;
          }
          return `${this.y} - (${this.percentage.toFixed(0)}%)`;
        },
      },
      plotOptions: {
        pie: {
          size: 250,
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            crop: false,
            padding: 0,
            style: {
              fontSize: "10px",
            },
            enabled: true,
            formatter: function () {
              if (this.y >= 1e6) {
                return `${(this.y / 1e6).toFixed(
                  3
                )}M <br/> (${this.percentage.toFixed(0)}%)`;
              } else if (this.y >= 1e3) {
                return `${(this.y / 1e3).toFixed(
                  3
                )}K <br/> (${this.percentage.toFixed(0)}%)`;
              }
              return `${this.y} <br/> (${this.percentage.toFixed(0)}%)`;
            },
          },
          showInLegend: true,
        },
      },
      series: [{
        name: 'VL_AJUSTE',
        colors: this.colorList,
        type: 'pie',
        data: value,
        innerSize: "60%"
      }],
    });
  }

  loadBarChart(container, value, percent, isRelacional = false) {
    //PDRAO VALUE = { categories:[] , datas: { name: '', data: [] } }

    const dataSum = value.datas[0].data.length > 0 ? value.datas[0].data.reduce((a, b) => a + b) : 0;

    new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: "bar",
        renderTo: container,
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormatter: function () {
          if (!isRelacional) {
            if (this.y >= 1e6) {
              return this.percentage != null ? `${(this.y / 1e6).toFixed(3)}M - (${this.percentage.toFixed(0)}%)` : `${(this.y / 1e6).toFixed(3)}M`;
            } else if (this.y >= 1e3) {
              return this.percentage != null ? `${(this.y / 1e3).toFixed(3)}K - (${this.percentage.toFixed(0)}%)` : `${(this.y / 1e3).toFixed(3)}K`;
            }
            return this.percentage != null ? `${this.y} - (${this.percentage.toFixed(0)}%)` : `${this.y}`;
          }

          //calcula a porcentagem da relação entre os 2 itens, caso seja um gráfico relacional
          let yPercent = parseFloat(this.percentage.toFixed(2));
          let valPercent = 100 - yPercent;
          let val = this.y * valPercent / yPercent;
          let tooltipPercent = this.series.index === 0 ? this.y / val : val / this.y;

          if (tooltipPercent === Infinity) tooltipPercent = 100;
          let yfixed = this.y >= 1e6 ? (this.y / 1e6).toFixed(3) + 'M' : this.y >= 1e3 ? (this.y / 1e3).toFixed(3) + 'K' : this.y + ""

          return `${yfixed} - (${(tooltipPercent).toFixed(5)}%)`;
        },
      },
      legend: {
        reversed: true,
      },
      xAxis: {
        categories: value.categories,
        labels: {
          formatter: function () {
            if (this.value.toString().length > 15)
              return this.value.toString().slice(0, 12) + "...";
            return this.value.toString();
          },
        },
      },
      yAxis: {
        tickInterval: dataSum / 10,
        labels: {
          formatter: function () {
            if (percent && value.datas.length === 1) {
              var pcnt = (Number(this.value) / dataSum) * 100;
              return pcnt.toFixed() + "%";
            } else if (this.value >= 1e6) {
              return `${(Number(this.value) / 1e6).toFixed(0)}M`;
            }
            return `${(Number(this.value) / 1e3).toFixed(0)}K`;
          },
        },
        title: {
          text: "",
        },
      },
      plotOptions: {
        series: {
          shadow: false,
          stacking: value.datas.length > 1 ? "normal" : null,
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (percent && value.datas.length === 1) {
                var pcnt = (this.y / dataSum) * 100;
                return pcnt.toFixed(1) + "%";
              } else if (this.y >= 1e6) {
                return `${(this.y / 1e6).toFixed(0)}M`;
              }
              return `${(this.y / 1e3).toFixed(0)}K`;
            },
          },
        },
      },
      series: value.datas.map(
        (item, index) =>
          new Object({
            type: "bar",
            name: item.name,
            showInLegend: value.datas.length > 1,
            data: item.data,
            color: this.colorList[index],
          })
      ),
    });
  }

  loadLineChart(container, value) {
    new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: "line",
        renderTo: container,
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormatter: function () {
          if (this.y >= 1e6) {
            return `${(this.y / 1e6).toFixed(3)}M - (${this.percentage.toFixed(
              0
            )}%)`;
          } else if (this.y >= 1e3) {
            return `${(this.y / 1e3).toFixed(3)}K - (${this.percentage.toFixed(
              0
            )}%)`;
          }
          return `${this.y} - (${this.percentage.toFixed(0)}%)`;
        },
      },
      xAxis: {
        categories: value.categories,
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
        },
        series: {
          shadow: false,
        },
      },
      series: value.datas.map(
        (item, index) =>
          new Object({
            name: item.name,
            data: item.data,
            type: "line",
            color: this.colorList[index],
          })
      ),
    });
  }

  ngOnInit(): void {
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
          this.titleService.title = "Dashboard";
          let selectedYear = new Date().getFullYear();

          this.opcoesAno = new Array();
          for(let i = -2; i <= 2; i++) {
            this.opcoesAno.push({ label: (selectedYear - i).toString(), value: selectedYear - i });
          }

          this.opcoesMeses = [];
          for(let i = 0; i < 12; i++) {
            const date = new Date(2022, i, 1);
            const value = date.getMonth();
            const label = date.toLocaleDateString('pt-BR', {month: 'long'});
            this.opcoesMeses.push({ value, label });
          }

          this.selectedYear = selectedYear;
          this.selectedMonth = 11;
          this.getTpConfigRelat();
        }
      }
    );
  }

  exibirMensagem(value: any) {
    const detail = (value.mensagem || value.message || value.response || value.msg);
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail });
    }
  }

  ngAfterViewInit(): void {
    // this.showTelaDashboard("TELA_1");
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
  get loading(): boolean {
    return this.httpService.loading;
  }

  exportExcel() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      }),
      responseType: 'blob' as 'json'
    };

    let dtIni = new Date(this.selectedYear, 0, 1);
    let dtFin = new Date(this.selectedYear, 11, 31);
    //getting month name
    let monthIni = dtIni.toLocaleDateString('default', { month: 'long' })
    let monthFin = dtFin.toLocaleDateString('default', { month: 'long' })

    const infos = {
      configid: this.configId,
      empresaid: this.dblinkedSessionService.empresa.id,
      dtIni: dtIni.getFullYear() + "-" + (dtIni.getMonth() + 1) + "-" + dtIni.getDate(),
      dtFin: dtFin.getFullYear() + "-" + (dtFin.getMonth() + 1) + "-" + dtFin.getDate(),
      user_id: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'util/relat-sobre-demanda', infos, httpOptions)
      .subscribe(
        (res: Blob) => {
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(res)
            let fileName = `CALC_TP_${this.dblinkedSessionService.empresa.nomeFantasia}_${this.selectedYear}(${monthIni}-${monthFin}).xlsx`
            link.href = url
            link.download = fileName
            link.click()
            window.URL.revokeObjectURL(url)
            this.httpService.done()
        }, err => {
          this.blobError(err)
          this.httpService.done()
        })
  }

  private blobError(httpErrorBlob: HttpErrorResponse): void {
    const fileBlob = new Blob([httpErrorBlob.error], {type: 'application/json'})
    const fileReader = new FileReader()

    fileReader.readAsText(fileBlob)

    fileReader.addEventListener('loadend', (fileProcessed: ProgressEvent<FileReader>) =>
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: JSON.parse(fileProcessed.target?.result as string)?.message ?? 'Falha ao realizar download do arquivo!' })
    )
  }

  formatarDataExtenso() {
    const dt = new Date();
    const month = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    const week = [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ];
    const semana = week[dt.getDay()];
    const mes = month[dt.getMonth()];

    return (
      "Hoje é " +
      semana +
      ", " +
      dt.getDate() +
      " de " +
      mes +
      " de " +
      dt.getFullYear() +
      "."
    );
  }

  getStringFromHtml(text) {
    const html = text;
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  hideMessage() {
    this.msgs = [];
  }

  showTelaDashboard(tipo) {
    this.statusTela1 = false;
    this.statusTela2 = false;
    this.statusTela3 = false;
    this.statusTela4 = false;
    this.statusTela5 = false;
    this.statusTela6 = false;

    if (!this.loadSucess) return;

    if (tipo === "TELA_1") {
      this.statusTela1 = true;
      this.loadDonutChart("chart_1", this.dataChart1);
      this.loadDonutChart("chart_2", this.dataChart2);
      this.loadBarChart("chart_3", this.dataChart3, true);
    } else if (tipo === "TELA_2") {
      this.statusTela2 = true;
      this.loadDonutChart("chart_5", this.dataChart5);
      this.loadDonutChart("chart_6", this.dataChart6);
      this.loadBarChart("chart_7", this.dataChart7, true);
    } else if (tipo === "TELA_3") {
      this.statusTela3 = true;
      this.loadBarChart("chart_9", this.dataChart9, true);
      this.loadBarChart("chart_10", this.dataChart10, false, true);

    } else if (tipo === "TELA_4") {
      this.statusTela4 = true;
      this.loadBarChart("chart_12", this.dataChart12, true);

      this.loadLineChart("chart_13", {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datas: [
          {
            name: "Tokyo",
            data: [200, 530, 600, 21, 100, 21, 321, 324, 23, 123, 112, 501],
          },
          {
            name: "Eua",
            data: [20, 202, 321, 21, 65, 1321, 321, 324, 231, 743, 23, 5],
          },
        ],
      });
    } else if (tipo === "TELA_5") {
      this.statusTela5 = true;
      this.loadBarChart("chart_15", this.dataChart15, false);
      this.loadBarChart("chart_16", this.dataChart16, true, true);
    } else if (tipo === "TELA_6") {
      this.statusTela6 = true;
      this.loadBarChart("chart_18", this.dataChart18, false);
      this.loadBarChart("chart_19", this.dataChart19, true, true);
    }
  }

  getTpConfigRelat() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'fiscal/tp-config-relat', httpOptions)
      .subscribe(
        res => {
          if (!res["error"]) {
            let relat = res["relatorio"];
            if (relat != null) {
              this.configId = relat.id;
            } else {
              this.configId = null;
              this.loadSucess = false;
              this.showTelaDashboard("");
              this.exibirMensagem({ "error": true, "mensagem": "Você precisa registrar o relatório de transfer-pricing para acessar o DashBoard." })
            }
          }

          this.httpService.done();
        }, err => {
          console.log(err);
          this.httpService.done();
          this.exibirMensagem(err);
        }
      );
  }

  getTpCalculo() {
    if (this.configId == null) {
      this.exibirMensagem({ "error": true, "mensagem": "Você precisa registrar o relatório de transfer-pricing para acessar o DashBoard." })
      return;
    }

    let dtIni = new Date(this.selectedYear, 0, 1);
    let dtFin = new Date(this.selectedYear, this.selectedMonth + 1, 0);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        configId: this.configId.toString(),
        dtIni: dtIni.getFullYear() + "-" + (dtIni.getMonth() + 1) + "-" + dtIni.getDate(),
        dtFin: dtFin.getFullYear() + "-" + (dtFin.getMonth() + 1) + "-" + dtFin.getDate(),
        ano: this.selectedYear.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "fiscal/tp-calculo", httpOptions)
      .subscribe(
        (res) => {
          this.CapExpo = new Array();
          this.CapExpoColig = new Array();
          this.PrlRev = new Array();
          this.Imobilizado = new Array();
          this.PicRev = new Array();
          this.PrlVendProd = new Array();

          this.TotaisCapExpo = {};
          this.TotaisCapExpoColig = {};
          this.TotaisPrlRev = {};
          this.TotaisImobilizado = {};
          this.TotaisPicRev = {};
          this.TotaisPrlVendProd = {};

          if (res["error"]) {
            this.loadSucess = false;
            this.showTelaDashboard(""); //esconder as informações
            this.exibirMensagem(res);
            this.httpService.done();
            return;
          }

          let dicValores = res["mensagem"];
          let infosBarChartPrlRev = [[], []];
          let infosBarChartPrlRev1 = [[], [], []];
          let infosBarCharImobilizado = [[], []];
          let infosBarChartPicVenda = [[], []];
          let infosBarChartPicVenda1 = [[], [], []];
          let infosBarChartPrlVenda = [[], []];
          let infosBarChartPrlVenda1 = [[], [], []];

          let keysAbas = [
            ['CAP_EXPO', [this.CapExpo, this.TotaisCapExpo]],
            ['CAP_EXPO_COLIG', [this.CapExpoColig, this.TotaisCapExpoColig]],
            ['IMOBILIZADO', [this.Imobilizado, this.TotaisImobilizado]]
          ]

          for (let aba of keysAbas) {
            let first = true;

            for (let line of Object.entries(dicValores[aba[0].toString()])) {
              if (line[0] === "ajustes") {
                if (aba[0] === 'IMOBILIZADO') {
                  for (let itemAjuste of dicValores[aba[0].toString()]['ajustes']['item']) {
                    let val = Math.round(itemAjuste[1] * 100) / 100;

                    infosBarCharImobilizado[0].push(itemAjuste[0]);
                    infosBarCharImobilizado[1].push(val);
                  }
                }

                continue;
              }

              //adiciona linha na tabela
              aba[1][0].push(line[1]);

              //calcula os footers
              let listKeys = Object.keys(line[1]); //chaves dos footers

              //inicializando
              if (first) {
                for (let key of listKeys) {
                  aba[1][1][key] = 0;
                }

                first = false;
              }

              //calculando
              for (let key of listKeys) {
                if (!isNaN(line[1][key])) {
                  aba[1][1][key] += line[1][key];
                }
              }
            }
          }

          //abas com dicionários com uma camada a mais
          keysAbas = [
            ['PRL_REVENDA', [this.PrlRev, this.TotaisPrlRev]],
            ['PIC_VENDA', [this.PicRev, this.TotaisPicRev]],
            ['PRL_VENDA_PROD', [this.PrlVendProd, this.TotaisPrlVendProd]]
          ];

          for (let aba of keysAbas) {
            let first = true;

            for (let line of Object.entries(dicValores[aba[0].toString()])) {
              if (line[0] === "ajustes") {
                for (let itemAjuste of dicValores[aba[0].toString()]['ajustes']['item']) {
                  let val = Math.round(itemAjuste[1] * 100) / 100;

                  if (val <= 0) continue;

                  if (aba[0] === 'PRL_REVENDA') {
                    infosBarChartPrlRev[0].push(itemAjuste[0]);
                    infosBarChartPrlRev[1].push(val);
                  } else if (aba[0] === 'PIC_VENDA') {
                    infosBarChartPicVenda[0].push(itemAjuste[0]);
                    infosBarChartPicVenda[1].push(val);
                  } else {
                    infosBarChartPrlVenda[0].push(itemAjuste[0]);
                    infosBarChartPrlVenda[1].push(val);
                  }
                }

                for (let itemAjuste of dicValores[aba[0].toString()]['ajustes']['relac']) {
                  let val1 = Math.round(itemAjuste[1][0] * 100) / 100
                  let val2 = Math.round(itemAjuste[1][1] * 100) / 100

                  if (isNaN(val1)) val1 = 0;
                  if (isNaN(val2)) val2 = 0;

                  if (aba[0] == 'PRL_REVENDA') {
                    infosBarChartPrlRev1[0].push(itemAjuste[0]);
                    infosBarChartPrlRev1[1].push(val1);
                    infosBarChartPrlRev1[2].push(val2);
                  } else if (aba[0] == 'PIC_VENDA') {
                    infosBarChartPicVenda1[0].push(itemAjuste[0]);
                    infosBarChartPicVenda1[1].push(val1);
                    infosBarChartPicVenda1[2].push(val2);
                  } else {
                    infosBarChartPrlVenda1[0].push(itemAjuste[1][2]);
                    infosBarChartPrlVenda1[1].push(val1);
                    infosBarChartPrlVenda1[2].push(val2);
                  }
                }

                continue;
              }

              //adiciona linha na tabela
              aba[1][0].push(line[1]);

              //calcula os footers
              let listKeysBase = Object.keys(line[1]); //chaves dos footers

              //inicializando
              if (first) {
                for (let base of listKeysBase) {
                  aba[1][1][base] = {};

                  for (let key of Object.keys(line[1][base]))
                    aba[1][1][base][key] = 0;
                }

                first = false;
              }

              //calculando
              for (let base of listKeysBase) {
                if (aba[1][1][base] == null) {
                  aba[1][1][base] = {};
                }

                for (let key of Object.keys(line[1][base])) {
                  let val = line[1][base][key] == null || isNaN(line[1][base][key]) ? 0 : line[1][base][key];

                  if (aba[1][1][base][key] == null) {
                    aba[1][1][base][key] = 0;
                  }

                  aba[1][1][base][key] += val;
                }
              }
            }
          }

          try {
            this.CapExpo.sort((line2, line1) => line1.ajuste_final - line2.ajuste_final);
            this.CapExpoColig.sort((line2, line1) => line1.ajuste_final - line2.ajuste_final);
            this.PrlRev.sort((line2, line1) => line1.Formula.ajuste_total - line2.Formula.ajuste_total);
            this.PicRev.sort((line2, line1) => line1.Formula.val_ajuste - line2.Formula.val_ajuste);
            this.PrlVendProd.sort((line2, line1) => (line1.Formula.ajuste_total || 0) - (line2.Formula.ajuste_total || 0));
          } catch (err) {
            console.log(err);
          }

          //carregando os gráficos
          //cap_expo
          let formatedData = [];
          let count = 0;

          for (let pais of dicValores["CAP_EXPO"]["ajustes"]['pais']) {
            let ajustePais = Math.round(pais[1] * 100) / 100;
            if (ajustePais < 0) continue;

            formatedData.push({
              name: pais[0],
              y: ajustePais < 0 ? ajustePais * -1 : ajustePais,
              color: this.colorList[count++],
              negative: ajustePais < 0
            });
          }

          this.dataChart1 = formatedData;

          count = 0;
          formatedData = [];
          for (let emp of dicValores["CAP_EXPO"]["ajustes"]['empresa']) {
            let ajusteEmp = Math.round(emp[1] * 100) / 100;
            if (ajusteEmp < 0) continue;

            formatedData.push({
              name: emp[0],
              y: ajusteEmp < 0 ? ajusteEmp * -1 : ajusteEmp,
              color: this.colorList[count++],
              negative: ajusteEmp < 0,
            });
          }
          this.dataChart2 = formatedData;

          count = 0;
          formatedData = [[], []];
          for (let item of dicValores["CAP_EXPO"]["ajustes"]['item']) {
            let ajusteItem = Math.round(item[1] * 100) / 100;
            if (ajusteItem < 0) continue;

            formatedData[0].push(item[0]);
            formatedData[1].push(ajusteItem);
          }

          this.dataChart3 = {
            categories: formatedData[0],
            color: this.colorList[count++],
            datas: [
              {
                name: this.selectedYear,
                data: formatedData[1],
              },
            ],
          };

          //cap_expo_colig
          //ajuste por pais
          count = 0;
          formatedData = [];
          for (let pais of dicValores["CAP_EXPO_COLIG"]["ajustes"]['pais']) {
            let ajustePais = Math.round(pais[1] * 100) / 100;
            if (ajustePais < 0) continue;

            formatedData.push({
              name: pais[0],
              y: ajustePais < 0 ? ajustePais * -1 : ajustePais,
              color: this.colorList[count++],
              negative: ajustePais < 0,
            });
          }
          this.dataChart5 = formatedData;

          //ajuste por empresa
          count = 0;
          formatedData = [];
          for (let emp of dicValores["CAP_EXPO_COLIG"]["ajustes"]['empresa']) {
            let ajusteEmp = Math.round(emp[1] * 100) / 100;
            if (ajusteEmp < 0) continue;

            formatedData.push({
              name: emp[0],
              y: ajusteEmp < 0 ? ajusteEmp * -1 : ajusteEmp,
              color: this.colorList[count++],
              negative: ajusteEmp < 0,
            });
          }

          this.dataChart6 = formatedData;

          //ajuste por item
          count = 0;
          formatedData = [[], []];
          for (let item of dicValores["CAP_EXPO_COLIG"]["ajustes"]['item']) {
            let ajusteItem = Math.round(item[1] * 100) / 100;
            if (ajusteItem < 0) continue;

            formatedData[0].push(item[0]);
            formatedData[1].push(ajusteItem);
          }

          this.dataChart7 = {
            categories: formatedData[0],
            color: this.colorList[count++],
            datas: [
              {
                name: this.selectedYear,
                data: formatedData[1],
              },
            ],
          };

          //PRL_REVENDA
          this.dataChart9 = {
            categories: infosBarChartPrlRev[0],
            datas: [{ name: this.selectedYear, data: infosBarChartPrlRev[1] }],
          }

          this.dataChart10 = {
            categories: infosBarChartPrlRev1[0],
            datas: [
              { name: "Valor Unit CPV", data: infosBarChartPrlRev1[2] },
              { name: "Valor Unit Venda", data: infosBarChartPrlRev1[1] }
            ],
          };

          //IMOBILIZADO
          this.dataChart12 = {
            categories: infosBarCharImobilizado[0],
            datas: [{ name: this.selectedYear, data: infosBarCharImobilizado[1] }],
          };

          //PIC_VENDA
          this.dataChart15 = {
            categories: infosBarChartPicVenda[0],
            datas: [{ name: this.selectedYear, data: infosBarChartPicVenda[1] }],
          };

          this.dataChart16 = {
            categories: infosBarChartPicVenda1[0],
            datas: [
              { name: "Vl Importação", data: infosBarChartPicVenda1[1] },
              { name: "Vl Compra Local", data: infosBarChartPicVenda1[2] }
            ],
          };

          //PRL_VENDA
          this.dataChart18 = {
            categories: infosBarChartPrlVenda[0],
            datas: [{ name: this.selectedYear, data: infosBarChartPrlVenda[1] }],
          };

          this.dataChart19 = {
            categories: infosBarChartPrlVenda1[0],
            datas: [
              { name: "Qtde MPI", data: infosBarChartPrlVenda1[2] },
              { name: "Qtde PA", data: infosBarChartPrlVenda1[1] }
            ],
          };

          //ocultar accordions caso o ajuste seja 0
          if (this.TotaisCapExpo['ajuste_final'], this.TotaisImobilizado['ajuste'] <= 0) {

            this.currentStateDash1 = "final";
            this.currentStateDash2 = "final";
            this.currentStateDash3 = "final";

            this.currentStateDash12 = "final";
            this.currentStateDash13 = "final";

            this.isCardDash1 = false;
            this.isCardDash2 = false;
            this.isCardDash3 = false;
            this.isCardDash12 = false;
            this.isCardDash13 = false;
          }

          this.loadSucess = true;
          this.showTelaDashboard("TELA_2");
          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
          this.exibirMensagem(err);
        }
      );
  }

  /*Show Hide Table*/
  isCardTable: boolean = true;
  ShowHIdeCardTable() {
    this.isCardTable = !this.isCardTable;
  }

  isCardDash1: boolean = true;
  ShowHIdeCardDash1() {
    this.isCardDash1 = !this.isCardDash1;
  }

  isCardDash2: boolean = true;
  ShowHIdeCardDash2() {
    this.isCardDash2 = !this.isCardDash2;
  }

  isCardDash3: boolean = true;
  ShowHIdeCardDash3() {
    this.isCardDash3 = !this.isCardDash3;
  }

  isCardDash4: boolean = true;
  ShowHIdeCardDash4() {
    this.isCardDash4 = !this.isCardDash4;
  }

  isCardDash5: boolean = true;
  ShowHIdeCardDash5() {
    this.isCardDash5 = !this.isCardDash5;
  }

  isCardDash6: boolean = true;
  ShowHIdeCardDash6() {
    this.isCardDash6 = !this.isCardDash6;
  }

  isCardDash7: boolean = true;
  ShowHIdeCardDash7() {
    this.isCardDash7 = !this.isCardDash7;
  }

  isCardDash8: boolean = true;
  ShowHIdeCardDash8() {
    this.isCardDash8 = !this.isCardDash8;
  }

  isCardDash9: boolean = true;
  ShowHIdeCardDash9() {
    this.isCardDash9 = !this.isCardDash9;
  }

  isCardDash10: boolean = true;
  ShowHIdeCardDash10() {
    this.isCardDash10 = !this.isCardDash10;
  }

  isCardDash11: boolean = true;
  ShowHIdeCardDash11() {
    this.isCardDash11 = !this.isCardDash11;
  }

  isCardDash12: boolean = true;
  ShowHIdeCardDash12() {
    this.isCardDash12 = !this.isCardDash12;
  }

  isCardDash13: boolean = true;
  ShowHIdeCardDash13() {
    this.isCardDash13 = !this.isCardDash13;
  }

  isCardDash14: boolean = true;
  ShowHIdeCardDash14() {
    this.isCardDash14 = !this.isCardDash14;
  }

  isCardDash15: boolean = true;
  ShowHIdeCardDash15() {
    this.isCardDash15 = !this.isCardDash15;
  }

  isCardDash16: boolean = true;
  ShowHIdeCardDash16() {
    this.isCardDash16 = !this.isCardDash16;
  }

  isCardDash17: boolean = true;
  ShowHIdeCardDash17() {
    this.isCardDash17 = !this.isCardDash17;
  }

  isCardDash18: boolean = true;
  ShowHIdeCardDash18() {
    this.isCardDash18 = !this.isCardDash18;
  }

  isCardDash19: boolean = true;
  ShowHIdeCardDash19() {
    this.isCardDash19 = !this.isCardDash19;
  }

  isCardDash20: boolean = true;
  ShowHIdeCardDash20() {
    this.isCardDash20 = !this.isCardDash20;
  }

  changeStateDash1() {
    this.currentStateDash1 =
      this.currentStateDash1 === "initial" ? "final" : "initial";
  }

  changeStateDash2() {
    this.currentStateDash2 =
      this.currentStateDash2 === "initial" ? "final" : "initial";
  }

  changeStateDash3() {
    this.currentStateDash3 =
      this.currentStateDash3 === "initial" ? "final" : "initial";
  }

  changeStateDash4() {
    this.currentStateDash4 =
      this.currentStateDash4 === "initial" ? "final" : "initial";
  }

  changeStateDash5() {
    this.currentStateDash5 =
      this.currentStateDash5 === "initial" ? "final" : "initial";
  }

  changeStateDash6() {
    this.currentStateDash6 =
      this.currentStateDash6 === "initial" ? "final" : "initial";
  }

  changeStateDash7() {
    this.currentStateDash7 =
      this.currentStateDash7 === "initial" ? "final" : "initial";
  }

  changeStateDash8() {
    this.currentStateDash8 =
      this.currentStateDash8 === "initial" ? "final" : "initial";
  }

  changeStateDash9() {
    this.currentStateDash9 =
      this.currentStateDash9 === "initial" ? "final" : "initial";
  }

  changeStateDash10() {
    this.currentStateDash10 =
      this.currentStateDash10 === "initial" ? "final" : "initial";
  }

  changeStateDash11() {
    this.currentStateDash11 =
      this.currentStateDash11 === "initial" ? "final" : "initial";
  }

  changeStateDash12() {
    this.currentStateDash12 =
      this.currentStateDash12 === "initial" ? "final" : "initial";
  }

  changeStateDash13() {
    this.currentStateDash13 =
      this.currentStateDash13 === "initial" ? "final" : "initial";
  }

  changeStateDash14() {
    this.currentStateDash14 =
      this.currentStateDash14 === "initial" ? "final" : "initial";
  }

  changeStateDash15() {
    this.currentStateDash15 =
      this.currentStateDash15 === "initial" ? "final" : "initial";
  }

  changeStateDash16() {
    this.currentStateDash16 =
      this.currentStateDash16 === "initial" ? "final" : "initial";
  }

  changeStateDash17() {
    this.currentStateDash17 =
      this.currentStateDash17 === "initial" ? "final" : "initial";
  }

  changeStateDash18() {
    this.currentStateDash18 =
      this.currentStateDash18 === "initial" ? "final" : "initial";
  }

  changeStateDash19() {
    this.currentStateDash19 =
      this.currentStateDash19 === "initial" ? "final" : "initial";
  }

  changeStateDash20() {
    this.currentStateDash20 =
      this.currentStateDash20 === "initial" ? "final" : "initial";
  }

  fullDash1: boolean = false;
  fullScreenDash1() {
    this.fullDash1 = !this.fullDash1;
  }

  fullDash2: boolean = false;
  fullScreenDash2() {
    this.fullDash2 = !this.fullDash2;
  }

  fullDash3: boolean = false;
  fullScreenDash3() {
    this.fullDash3 = !this.fullDash3;
  }

  fullDash5: boolean = false;
  fullScreenDash5() {
    this.fullDash5 = !this.fullDash5;
  }

  fullDash6: boolean = false;
  fullScreenDash6() {
    this.fullDash6 = !this.fullDash6;
  }

  fullDash7: boolean = false;
  fullScreenDash7() {
    this.fullDash7 = !this.fullDash7;
  }

  fullDash9: boolean = false;
  fullScreenDash9() {
    this.fullDash9 = !this.fullDash9;
  }

  fullDash10: boolean = false;
  fullScreenDash10() {
    this.fullDash10 = !this.fullDash10;
  }

  fullDash12: boolean = false;
  fullScreenDash12() {
    this.fullDash12 = !this.fullDash12;
  }

  fullDash13: boolean = false;
  fullScreenDash13() {
    this.fullDash13 = !this.fullDash13;
  }

  fullDash15: boolean = false;
  fullScreenDash15() {
    this.fullDash15 = !this.fullDash15;
  }

  fullDash16: boolean = false;
  fullScreenDash16() {
    this.fullDash16 = !this.fullDash16;
  }

  fullDash18: boolean = false;
  fullScreenDash18() {
    this.fullDash18 = !this.fullDash18;
  }

  fullDash19: boolean = false;
  fullScreenDash19() {
    this.fullDash19 = !this.fullDash19;
  }

  onRowSelect(event) { }

  public optionsDescFroola: Object = {
    placeholderText: "Adicionar comentário...",
    charCounterCount: false,
    pluginsEnabled: [
      "align",
      "charCounter",
      "codeBeautifier",
      "codeView",
      "colors",
      "draggable",
      "embedly",
      "emoticons",
      "entities",
      "file",
      "fontAwesome",
      "fontFamily",
      "fontSize",
      "fullscreen",
      "image",
      "imageTUI",
      "imageManager",
      "inlineStyle",
      "inlineClass",
      "lineBreaker",
      "lineHeight",
      "link",
      "lists",
      "paragraphFormat",
      "table",
      "url",
      "video",
      "wordPaste",
    ], // 'quickInsert',
    toolbarButtons: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    toolbarButtonsXS: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    toolbarButtonsSM: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    toolbarButtonsMD: [
      "bold",
      "italic",
      "underline",
      "paragraphFormat",
      "align",
      "|",
      "insertTable",
      "formatOL",
      "formatUL",
      "|",
      "undo",
      "redo",
    ],
    imageUploadMethod: "POST",
    key: "LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==",
    events: {
      "froalaEditor.initialized": function () { },
    },
  };

  String(str: string) {
    return str.toString();
  }
}
