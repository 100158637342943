import { isNullOrUndefined }  from 'util';

export class PalestraInformacoesAdicionais{

  palestraId        : number;
  descricao         : string;
  quantidade        : number;


  constructor(palestraInformacoesAdicionais?: PalestraInformacoesAdicionais | number){

    if (!isNullOrUndefined(palestraInformacoesAdicionais)) {
        if (typeof palestraInformacoesAdicionais === 'number') {
            this.palestraId = palestraInformacoesAdicionais;
        } else {
            this.palestraId = palestraInformacoesAdicionais.palestraId;
            this.descricao  = palestraInformacoesAdicionais.descricao;
            this.quantidade = palestraInformacoesAdicionais.quantidade;
        }
    }


  }


}