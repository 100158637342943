export class CodServicoTelecomunicacao {
  id: number;
  grupo: string;
  cod: any;
  descr: string;

  constructor(codServicoTelecomunicacao?: CodServicoTelecomunicacao | number) {
    if (codServicoTelecomunicacao !== undefined && codServicoTelecomunicacao !== null) {
      if (typeof codServicoTelecomunicacao === 'number') {
        this.id = codServicoTelecomunicacao;
      } else {
        this.id = codServicoTelecomunicacao.id;
        this.grupo = codServicoTelecomunicacao.grupo;
        this.cod = codServicoTelecomunicacao.cod;
        this.descr = codServicoTelecomunicacao.descr;
      }
    }
  }
}
