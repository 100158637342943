export class Operacao {
  id: number;
  descricao: string;

  constructor(operacao?: Operacao | number) {
    if (operacao !== undefined && operacao !== null) {
      if (typeof operacao === 'number') {
        this.id = operacao;
      } else {
        this.id = operacao.id;
        this.descricao = operacao.descricao;
      }
    }
  }
}
