import { NgModule, Component, Inject/*, HostListener*/, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MaterialModule } from 'app/shared/common/material.module';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { DataTableService } from 'app/shared/components/data-table/data-table.service';

import { Util } from 'app/shared/common/util';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  originalValue: Object;

  selectedLength = 0;
  mainTitle: string;
  singleTitle: string;

  controls: Array<DataControl | DataTableColumn> = new Array();
  _control: DataControl | DataTableColumn;
  formGroup: FormGroup;

  loaded = false;

  private changeTimeout: NodeJS.Timer;

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    private dataTableService: DataTableService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group([]);

    this.controls.forEach(control => {
      // TODO: Review/Fix Validators
      this.formGroup.addControl(control.key,
        new FormControl(undefined, [Validators.required]));
    });

    this.originalValue = this.formGroup.getRawValue();

    this.loaded = true;
  }

  defaultChange(control: DataControl | DataTableColumn, value: string) {
    if (value !== undefined && value !== null && value.trim() === '') {
      clearTimeout(this.changeTimeout);
      this.changeTimeout = setTimeout(() => {
        this.formGroup.get(control.key).setValue(null);
        clearTimeout(this.changeTimeout);
      }, 1);
    }
  }

  cancel() {
    this._control = undefined;

    this.formGroup.reset();
    this.formGroup.updateValueAndValidity();
  }

  /*@HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    switch (event.key) {
      case 'Enter':
        return false;
      case 'Escape':
        this.dialogRef.componentInstance.dialogClose(false);
        break;
    }
  }*/

  label(option: any, label: any) {
    return this.dataTableService.label(option, label);
  }

  dialogClose(dialogResult?: any) {
    this.dialogRef.close(dialogResult);
  }

  get changed(): boolean {
    return !Util.equals(this.formGroup.value, this.originalValue);
  }

  get displayValue(): any {
    const value = this.formGroup.get(this._control.key).value;

    switch (this._control.type) {
      case 'select':
        const option = this._control.options.values.find(o => o.value === value);

        if (option) {
          return option.label;
        }

        break;
      default:
        return value;
    }

    return null;
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [DialogComponent],
  declarations: [DialogComponent],
  entryComponents: [DialogComponent]
})
export class AppDialogModule { // TODO: Rename to DialogModule (conflits with PrimeNG component)
}
