import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs/Subscription'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { HttpService } from 'app/shared/services/http.service'
import { SessionService } from 'app/shared/services/session.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { Toolbar } from 'app/shared/model/toolbar.model'
import { MessageService, SelectItem } from 'primeng/api'

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.scss'],
})
export class ParametrosComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription

  optionsProject: Array<SelectItem> = new Array()
  optionsManager: Array<SelectItem> = new Array()
  optionsDate: Array<SelectItem> = new Array()
  optionsCompanyAccount: Array<SelectItem> = new Array()

  isUseFlow: boolean
  mandatoryCostCenter: boolean
  mandatoryOperation: boolean
  mandatoryFinancialApproval: boolean
  mandatoryOrderApproval: boolean
  mandatoryBankInformation: boolean
  isSendMention: boolean
  isSendFlow: boolean
  allowChangingFinancialRmsAccount: boolean
  forbidItemEditOrder: boolean = false

  companyParameterId: number
  selectedProjectId: number
  selectedDefaultCompanyAccountId: number
  selectedDateFilter: number

  selectedManagers: Array<any> = new Array()

  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Alterações', visible: true, disabled: false, color: 'green', onClick: () => this.saveParameters() }
  ]

  constructor(
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private messageService: MessageService,
    public dblinkedSessionService: DblinkedSessionService,
  ) { }

  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if(this.dblinkedSessionService.hasEmpresaSelected) this.getParameters()
    })
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  private getParameters(): void {

    try {
      this.httpService.wait()
      this.httpService.get(`/custom/cadastro/recuperar-parametros-rms(${this.dblinkedSessionService.empresa.id})`).subscribe((res: any) => {
        if(!res.error) {
          this.resetVars()

          res?.detail?.projetos.forEach(project => {
            this.optionsProject.push({
              label: project.nome,
              value: project.id
            })
          })

          res?.detail?.gestores.forEach(manager => {
            this.optionsManager.push({
              label: manager.fullName,
              value: manager.id
            })
          })

          this.optionsCompanyAccount.push({
            label: "NENHUMA",
            value: null
          })

          res?.detail?.empresasConta.forEach(companyAccount => {
            if(companyAccount.tipoContaId !== 10) this.optionsCompanyAccount.push({
              label: companyAccount.nomeEmpresa,
              value: companyAccount.id
            })
          })

          this.optionsDate =  [{label: 'Data de entrega', value: 0}, {label: 'Data de criação', value: 1}]

          this.isUseFlow = res?.detail?.todoProjetoId ?? false
          this.mandatoryCostCenter = res?.detail?.configuracaoRms?.cadCcusObrigatorioRms ?? false
          this.mandatoryOperation = res?.detail?.configuracaoRms?.operacaoObrigatoria ?? false
          this.mandatoryFinancialApproval = res?.detail?.configuracaoRms?.exigirAprovacaoFinanceiro ?? false
          this.mandatoryOrderApproval = res?.detail?.configuracaoRms?.exigirAprovacaoPedido ?? false
          this.mandatoryBankInformation = res?.detail?.configuracaoRms?.infosBancariasObrigatorias ?? false
          this.isSendMention = res?.detail?.configuracaoRms?.sendMailMention ?? false
          this.isSendFlow = res?.detail?.configuracaoRms?.sendMailFlow ?? false
          this.forbidItemEditOrder = res?.detail?.configuracaoRms?.travarEdicaoItemPedido ?? false
          this.allowChangingFinancialRmsAccount = res?.detail?.contaPadrao?.permitirAlterar ?? false

          this.selectedDateFilter = res?.detail?.configuracaoRms?.dataPadrao ?? 0

          this.companyParameterId = res?.detail?.configuracaoRms?.id ?? null
          this.selectedProjectId = res?.detail?.todoProjetoId ?? null
          this.selectedDefaultCompanyAccountId = res?.detail?.contaPadrao?.id ?? null

          this.selectedManagers = res?.detail?.gestoresSelecionados ?? new Array()

        } else this.showResponse('error', res.detail)
      },
      error => this.showResponse('error', 'Erro no API ao salvar os parâmetros, contate o suporte : ' + error.message ),
      () => this.httpService.done())
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private saveParameters(): void {
    const payload = {
      companyId: this.dblinkedSessionService.empresa.id,
      userId: this.sessionService.loggedUser.id,
      companyParameterId: this.companyParameterId,
      isUseFlow: this.isUseFlow,
      mandatoryCostCenter: this.mandatoryCostCenter,
      mandatoryOperation: this.mandatoryOperation,
      mandatoryFinancialApproval: this.mandatoryFinancialApproval,
      mandatoryOrderApproval: this.mandatoryOrderApproval,
      mandatoryBankInformation: this.mandatoryBankInformation,
      isSendMention: this.isSendMention,
      isSendFlow: this.isSendFlow,
      allowChangingFinancialRmsAccount: this.allowChangingFinancialRmsAccount,
      selectedProjectId: this.selectedProjectId,
      selectedCompanyAccountId: this.selectedDefaultCompanyAccountId,
      selectedManagers: this.selectedManagers,
      selectedDateFilter: this.selectedDateFilter,
      forbidItemEditOrder: this.forbidItemEditOrder
    }

    try{
      this.httpService.wait()
      this.httpService.post('/custom/cadastro/salvar-parametros-rms', payload).subscribe(
        res => {
          if(!res.error) this.showResponse('success', res.detail)
          else this.showResponse('error', res.detail)
        },
        error => this.showResponse('error', 'Erro no API ao salvar os parâmetros, contate o suporte : ' + error.message ),
        () => this.httpService.done()
      )
    } catch(err){
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private resetVars(): void {
    this.optionsProject = new Array()
    this.optionsManager = new Array()
    this.optionsDate = new Array()
    this.optionsCompanyAccount = new Array()

    this.isUseFlow = false
    this.mandatoryCostCenter = false
    this.mandatoryOperation = false
    this.mandatoryFinancialApproval = false
    this.mandatoryOrderApproval = false
    this.mandatoryBankInformation = false
    this.isSendMention = false
    this.isSendFlow = false
    this.allowChangingFinancialRmsAccount = true

    this.companyParameterId = null
    this.selectedProjectId = null
    this.selectedDefaultCompanyAccountId = null
    this.selectedDateFilter = 0
    this.selectedManagers = new Array()
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Atenção: ', detail: message })
      break
    }
  }

  public formatDateExtensive(): String {
    const dt = new Date()
    const monthExtensive = [ 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro' ]
    const weekExtensive = [ 'domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado' ]

    const month = monthExtensive[dt.getMonth()]
    const week = weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }
}