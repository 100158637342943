import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { SessionService } from 'app/shared/services/session.service'
import { MessageService, SelectItem } from 'primeng/api'
import { DashMainComponent } from '../dash-main/dash-main.component'
import { LineData } from '../components/interfaces/dash-big-number'
import { BarChart, ColumnChart } from '../components/interfaces/dash-graphics'
import { TableControl, TableContent } from '../components/interfaces/dash-table'
import { TabChanger } from '../components/interfaces/dash-changer'
import deepCopy from 'app/util/processing/deepcopy'

@Component({
  selector: 'app-dash-financial-summary',
  templateUrl: './dash-financial-summary.component.html',
  styleUrls: ['./dash-financial-summary.component.scss']
})

export class DashFinancialSummaryComponent extends DashMainComponent implements OnInit, OnDestroy {

  checkingAccountData: LineData
  investmentsData: LineData

  isLoading: boolean
  isTableLoading: boolean = false
  loadingOptCompanies: boolean
  loadingOptIconCompanies: boolean

  periodSelected: any

  releasesExpire: TableContent
  releasesWillExpire: TableContent
  approvReleasesExpire: TableContent
  approvReleasesWillExpire: TableContent
  nextPayments: TableContent
  nextReceipts: TableContent

  monthlyEvolutionBase: Array<string> = new Array()
  monthlyEvolutionData: Array<ColumnChart> = new Array()
  periodOptions: Array<TabChanger> = new Array()
  colsReleases: Array<TableControl> = new Array()
  colsRecordMovements: Array<TableControl> = new Array()
  // barLabel: Array<string> = new Array()
  // barData: Array<BarChart> = new Array()
  columnAccountLabel: Array<string> = new Array()
  columnAccountData: Array<ColumnChart> = new Array()
  availableCompanies: Array<SelectItem> = new Array()
  selectedCompanies: Array<number> = new Array()

  placeholderCompanies : string = 'Selecione a(s) empresa(s)'

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    public messageService: MessageService
  ) { super(messageService, dblinkedSessionService) }

  public ngOnInit(): void {
    this.startPeriod()
    this.initialize()
  }

  public ngOnDestroy(): void {
    this.startVars()
  }

  public async initialize(): Promise <void>{
    this.isLoading = true
    await this.getCompanyGroup()
    this.getInformations(false)
  }

  private async getInformations(isLoading : boolean): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        month: this.dblinkedSessionService.periodo.month.value.toString(),
        year: this.dblinkedSessionService.periodo.year.value.toString()
      })
    }

    const payload = {
      list_empresas_id: this.selectedCompanies,
      month: this.dblinkedSessionService.periodo.month.value.toString(),
      year: this.dblinkedSessionService.periodo.year.value.toString()
    }

    if(isLoading) this.isLoading = true

    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'homepage/dash-financial-summary', payload, httpOptions).toPromise().then(res => {
        if(!res["error"]) {
          this.startVars()
          this.getDetails()

          this.columnAccountLabel = []

          this.checkingAccountData.lines.push({ title: 'Todas as contas', value: res["data"].saldo_por_conta.cc.total })
          let accountName: Array<string> = Object.keys(res["data"].saldo_por_conta.cc)
          accountName.sort()
          accountName.forEach(item => { if(item != "total") this.checkingAccountData.lines.push({ title: item, value: res["data"].saldo_por_conta.cc[item] }) })

          this.investmentsData.lines.push({ title: 'Todas as contas', value: res['data'].saldo_por_conta.inv.total })
          accountName = Object.keys(res["data"].saldo_por_conta.inv)
          accountName.sort()
          accountName.forEach(item => { if(item != "total") this.investmentsData.lines.push({ title: item, value: res["data"].saldo_por_conta.inv[item] }) })

          res['data'].saldo_por_tipo_conta.forEach(item => {
            // this.barLabel.push(item.descricao)
            // this.barData[0].data.push({y: item.valor, custom: { extraInformation: ` / YoY: ${(((item.valor - item.valor_anterior) / Math.abs(item.valor_anterior) * 100)).toFixed(2)}%` }})
            // this.barData[1].data.push(item.valor_anterior)
            this.columnAccountLabel.push(item.descricao)
            this.columnAccountData[0].data.push({y: item.valor, custom: { extraInformation: ` / YoY: ${(((item.valor - item.valor_anterior) / Math.abs(item.valor_anterior) * 100)).toFixed(2)}%` }})
            this.columnAccountData[1].data.push(item.valor_anterior)
          })

          res['data'].movimentacoes_para_hoje.lancamentos_vencidos.forEach(item => {
            this.releasesExpire.rows.push({ periodo: item.periodo, recebimento: item.recebimento, pagamento: item.pagamento, movimentacao: item.movimentacao })

            this.releasesExpire.cols[1].footerValue += item.recebimento
            this.releasesExpire.cols[2].footerValue += item.pagamento
            this.releasesExpire.cols[3].footerValue += item.movimentacao
          })

          res['data'].movimentacoes_para_hoje.lancamentos_vencer.forEach(item => {
            this.releasesWillExpire.rows.push({ periodo: item.periodo, recebimento: item.recebimento, pagamento: item.pagamento, movimentacao: item.movimentacao })

            this.releasesWillExpire.cols[1].footerValue += item.recebimento
            this.releasesWillExpire.cols[2].footerValue += item.pagamento
            this.releasesWillExpire.cols[3].footerValue += item.movimentacao
          })

          res['data'].aguardando_aprovacao.lancamentos_vencidos.forEach(item => {
            this.approvReleasesExpire.rows.push({ periodo: item.periodo, recebimento: item.recebimento, pagamento: item.pagamento, movimentacao: item.movimentacao })

            this.approvReleasesExpire.cols[1].footerValue += item.recebimento
            this.approvReleasesExpire.cols[2].footerValue += item.pagamento
            this.approvReleasesExpire.cols[3].footerValue += item.movimentacao
          })

          res['data'].aguardando_aprovacao.lancamentos_vencer.forEach(item => {
            this.approvReleasesWillExpire.rows.push({ periodo: item.periodo, recebimento: item.recebimento, pagamento: item.pagamento, movimentacao: item.movimentacao })

            this.approvReleasesWillExpire.cols[1].footerValue += item.recebimento
            this.approvReleasesWillExpire.cols[2].footerValue += item.pagamento
            this.approvReleasesWillExpire.cols[3].footerValue += item.movimentacao
          })

          this.releasesExpire.totalRecords = this.releasesExpire.rows.length
          this.releasesWillExpire.totalRecords = this.releasesWillExpire.rows.length
          this.approvReleasesExpire.totalRecords = this.approvReleasesExpire.rows.length
          this.approvReleasesWillExpire.totalRecords = this.approvReleasesWillExpire.rows.length

          this.monthlyEvolutionData[0].data = res['data'].licrcp.CP
          this.monthlyEvolutionData[1].data = res['data'].licrcp.CR
          this.monthlyEvolutionData[2].data = res['data'].licrcp.saldos
        } else this.showErrorResponse(res['mensagem'])
      })
      .catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao carregar os dados da dashboard! Contate o suporte'))
    } catch(err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.isLoading = false
    }
  }

  private async getDetails(): Promise<void> {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
      })
    }

    const payload = {
      list_empresas_id: this.selectedCompanies,
      dataInicial: this.getDate('initial', this.periodSelected),
      dataFinal: this.getDate('final', this.periodSelected)
    }

    this.isTableLoading = true

    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'homepage/dash-financial-summary-mov', payload,  httpOptions).toPromise().then(res => {
        if(!res['error']) {
          this.nextPayments.rows = new Array()
          this.nextReceipts.rows = new Array()
          this.nextPayments.cols[8].footerValue = 0
          this.nextReceipts.cols[8].footerValue = 0

          res['data'].proximos_pagamentos.forEach(item => {
            this.nextPayments.rows.push({
              data: item.data,
              participante: item.participante,
              valor: item.valor,
              operacao: item.operacao,
              codigo_pedido: item.codigo_pedido,
              documento: item.documento,
              status: item.status,
              conta_bancaria: item.conta_bancaria,
              observacao: item.historico_parcela
            })

            this.nextPayments.cols[8].footerValue += item.valor
          })

          res['data'].proximos_recebimentos.forEach(item => {
            this.nextReceipts.rows.push({
              data: item.data,
              participante: item.participante,
              valor: item.valor,
              operacao: item.operacao,
              codigo_pedido: item.codigo_pedido,
              documento: item.documento,
              status: item.status,
              conta_bancaria: item.conta_bancaria,
              observacao: item.historico_parcela
            })

            this.nextReceipts.cols[8].footerValue += item.valor
          })

          this.nextPayments.totalRecords = this.nextPayments.rows.length
          this.nextReceipts.totalRecords = this.nextReceipts.rows.length
        }
        else this.showErrorResponse(res['mensagem'])
      }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao carregar os dados da dashboard! Contate o suporte'))
    } catch(err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.isTableLoading = false
    }
  }

  private async getCompanyGroup(): Promise<void> {
    this.loadingOptCompanies = true
    this.loadingOptIconCompanies = true

    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi, }) }

    try {
      await this.httpClient.get(`${this.configService.defaultUrlApiUpload}/cadastro/getgroupempresas/${this.dblinkedSessionService.empresa.id}-${this.sessionService.loggedUser.id}`, httpOptions).toPromise().then(res => {
        if(res['data'].length) res['data'].forEach(item => {
          this.availableCompanies.push({ label: item.fantasia, value: item.id })
        })
        else this.availableCompanies.push({ label: this.dblinkedSessionService.empresa.nomeFantasia, value: this.dblinkedSessionService.empresa.id })
      }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao carregar o grupo de empresas! Contate o suporte'))
    } catch(err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.placeholderCompanies = this.dblinkedSessionService.empresa.nomeFantasia
      this.selectedCompanies.push(this.dblinkedSessionService.empresa.id)
      this.loadingOptCompanies = false
      this.loadingOptIconCompanies = false
    }
  }

  private startVars(): void {
    this.colsReleases = [
      { title: 'Período', width: 40, align: 'left', key: 'periodo', type: 'text', footerValue: 'TOTAL' },
      { title: 'Entradas', width: 20, align: 'right', key: 'recebimento', type: 'money', footerValue: 0 },
      { title: 'Saídas', width: 20, align: 'right', key: 'pagamento', type: 'money', footerValue: 0 },
      { title: 'Movto', width: 20, align: 'right', key: 'movimentacao', type: 'money', footerValue: 0 }
    ]

    this.colsRecordMovements = [
      { title: 'Data', width: 10, align: 'left', key: 'data', type: 'text', footerValue: 'TOTAL' },
      { title: 'Participante', width: 15, align: 'left', key: 'participante', type: 'text' },
      { title: 'Operação', width: 15, align: 'left', key: 'operacao', type: 'text' },
      { title: 'Conta Bancária', width: 15, align: 'left', key: 'conta_bancaria', type: 'text' },
      { title: 'Cód. pedido', width: 10, align: 'left', key: 'codigo_pedido', type: 'text' },
      { title: 'Núm. doc.', width: 10, align: 'left', key: 'documento', type: 'text' },
      { title: 'Status', width: 10, align: 'left', key: 'status', type: 'text' },
      { title: 'Observação', width: 10, align: 'left', key: 'observacao', type: 'text' },
      { title: 'Valor', width: 10, align: 'right', key: 'valor', type: 'money', footerValue: 0 }
    ]

    this.checkingAccountData = {
      title: 'Conta corrente',
      subtitle: 'saldo atual em R$',
      lines: new Array()
    }

    this.investmentsData = {
      title: 'Investimentos',
      subtitle: 'saldo atual em R$',
      lines: new Array()
    }

    // this.barData = [
    //   { type: 'bar', name: this.dblinkedSessionService.periodo.year.value.toString(), data: new Array() },
    //   { type: 'bar', name: (this.dblinkedSessionService.periodo.year.value-1).toString(), data: new Array() }
    // ]
    this.columnAccountData = [
      { type: 'column', name: this.dblinkedSessionService.periodo.year.value.toString(), data: new Array() },
      { type: 'column', name: (this.dblinkedSessionService.periodo.year.value-1).toString(), data: new Array() }
    ]

    this.releasesExpire = {
      title: 'lançamentos vencidos',
      cols: deepCopy(this.colsReleases),
      rows: new Array(),
      totalRecords: 0
    }

    this.releasesWillExpire = {
      title: 'lançamentos a vencer',
      cols:  deepCopy(this.colsReleases),
      rows: new Array(),
      totalRecords: 0
    }

    this.approvReleasesExpire = {
      title: 'lançamentos vencidos',
      cols: deepCopy(this.colsReleases),
      rows: new Array(),
      totalRecords: 0
    }

    this.approvReleasesWillExpire = {
      title: 'lançamentos a vencer',
      cols: deepCopy(this.colsReleases),
      rows: new Array(),
      totalRecords: 0
    }

    this.nextPayments = {
      title: 'Pagamentos',
      cols: deepCopy(this.colsRecordMovements),
      rows: new Array(),
      totalRecords: 0
    }

    this.nextReceipts = {
      title: 'Recebimentos',
      cols: deepCopy(this.colsRecordMovements),
      rows: new Array(),
      totalRecords: 0
    }

    this.monthlyEvolutionBase = this.baseListEvolution()

    this.monthlyEvolutionData = [{
      type: 'column',
      name: 'Pagamentos',
      data: new Array(),
      color: 'rgb(232, 43, 123)'
    },
    {
      type: 'column',
      name: 'Recebimentos',
      data: new Array(),
      color: 'rgb(85, 0, 189)'
    },
    {
      type: 'spline',
      name: 'Saldo Final',
      data: new Array(),
      color: 'rgb(192, 192, 192)',
    }]
  }

  private startPeriod(): void {
    this.periodOptions = [
      { label: 'Diário', value: 'daily', active: true },
      { label: 'Últimos 7 dias', value: 'lastSevenDays', active: false },
      { label: 'Próximos 7 dias', value: 'nextSevenDays', active: false },
      { label: 'Próximos 15 dias', value: 'nextFifteenDays', active: false },
      { label: 'Mensal', value: 'month', active: false }
    ]

    this.periodSelected = 'daily'
  }

  public changePeriod(event: any): void {
    this.periodSelected = event
    this.getDetails()
  }

  public checkCompaniesList(): void {
    if (this.selectedCompanies.length === 0) {
      this.showErrorResponse('Por favor, selecione pelo menos uma empresa')
      this.placeholderCompanies = 'Selecione a(s) empresa(s)'
    }
    else this.getInformations(true)
  }
}
