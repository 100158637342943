import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ConfigService } from 'app/shared/services/config.service';
import { UtilService } from 'app/shared/services/util.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { TitleService } from 'app/shared/services/title.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-cadastro-informacao-empresa',
  templateUrl: './cadastro-informacao-empresa.component.html',
  styleUrls: ['./cadastro-informacao-empresa.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadastroInformacaoComponent implements OnInit, OnDestroy {

  currentStateFormaConhecimento = 'initial';
  changeStateFormaConhecimento() {
    this.currentStateFormaConhecimento = this.currentStateFormaConhecimento === 'initial' ? 'final' : 'initial';
  }

  currentStateGenero = 'final';
  changeStateGenero() {
    this.currentStateGenero = this.currentStateGenero === 'final' ? 'initial' : 'final';
  }

  currentStateProfissao = 'final';
  changeStateProfissao() {
    this.currentStateProfissao = this.currentStateProfissao === 'final' ? 'initial' : 'final';
  }

  private sessionSubscription: Subscription;
  pt: any;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  msgs: Message[];
  msgsModal: Message[];
  listaProfissao: Array<any> = [];
  listaGenero: Array<any> = [];
  listaFormaConhecimento: Array<any> = [];
  checkedAll: boolean = false;
  modalFormaConhecimentoNew: boolean = false;
  codigoFormaConhecimentoNew: string = null;
  descricaoFormadeConhecimentoNew: string = null;
  modalGeneroNew: boolean = false;
  codigoGeneroNew: string = null;
  descricaoGeneroNew: string = null;
  modalProfissaoNew: boolean = false;
  codigoProfissaoNew: string = null;
  descricaoProfissaoNew: string = null;
  modalEditFormaConhecimento: boolean = false;
  idEditFormaConhecimento: any;
  codigoEditFormaConhecimento: string = null;
  descricaoEditFormaConhecimento: string = null;
  FormaConhecimentoAtivo: boolean = true;
  modalEditGenero: boolean = false;
  idEditGenero: any;
  codigoEditGenero: string = null;
  descricaoEditGenero: string = null;
  GeneroAtivo: boolean = true;
  modalEditProfissao: boolean = false;
  idEditProfissao: any;
  codigoEditProfissao: string = null;
  descricaoEditProfissao: string = null;
  ProfissaoAtivo: boolean = true;

  //export excel
  colslistaFormaConhecimento: any[];
  colslistaGenero: any[];
  colslistaProfissao: any[];

  opcoesAtivo: SelectItem[] = [{ label: 'Todos', value: '' }, { label: 'Sim', value: true }, { label: 'Não', value: false }];
 

  constructor(private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private messageService: MessageService,
  ) { }

  @ViewChild('dt1') dt1: Table
  exportForm() {
    this.dt1.exportCSV()
  }
  @ViewChild('dt2') dt2: Table
  exportGen() {
    this.dt2.exportCSV()
  }
  @ViewChild('dt3') dt3: Table
  exportPro() {
    this.dt3.exportCSV()
  }

  public toolbarMain = new Toolbar()
  public toolbarMainGenero = new Toolbar()
  public toolbarMainProfissao = new Toolbar()

  toolbarMainIni = [
    { icon: 'add', text: 'Nova', tooltip: 'Adicionar Forma de Conhecimento', visible: true, disabled: false, color: 'default', onClick: () => this.openNovaFormaConhecimento() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.dt1.exportCSV() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideFormaConhecimento()
        this.changeStateFormaConhecimento()
      
      }
    },
  ]
  toolbarMainIniGenero = [
    { icon: 'add', text: 'Novo', tooltip: 'Adiconar Gênero', visible: true, disabled: false, color: 'default', onClick: () => this.openGenero() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.dt2.exportCSV() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideGenero()
        this.changeStateGenero()
      
      }
    },
  ]
  toolbarMainIniProfissao = [
    { icon: 'add', text: 'Novo', tooltip: 'Adiconar Gênero', visible: true, disabled: false, color: 'default', onClick: () => this.openProfissao() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.dt3.exportCSV() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideProfissao()
        this.changeStateProfissao()
      
      }
    },
  ]

  ngOnInit(): void {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarMainGenero.setAll(this.toolbarMainIniGenero)
    this.toolbarMainProfissao.setAll(this.toolbarMainIniProfissao)
    this.toolbarService.hidden = true
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.checkedAll = false;
    this.titlePrincipal = 'Cadastros de Informações';
    this.subtitle = 'Definição de Parâmetros do Cadastro de Participantes';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.modalFormaConhecimentoNew = false;
    this.modalGeneroNew = false;
    this.modalProfissaoNew = false;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();

    });

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.colslistaFormaConhecimento = [
      { field: 'codigo_formaconhecimento', header: 'CÓDIGO' },
      { field: 'descricao_formaconhecimento', header: 'DESCRIÇÃO' },
      { field: 'ativo_formaconhecimento', header: 'ATIVO' },
    ];

    this.colslistaGenero = [
      { field: 'codigo_genero', header: 'CÓDIGO' },
      { field: 'descricao_genero', header: 'DESCRIÇÃO' },
      { field: 'ativo_genero', header: 'ATIVO' },
    ];

    this.colslistaProfissao = [
      { field: 'codigo_profissao', header: 'CÓDIGO' },
      { field: 'descricao_profissao', header: 'DESCRIÇÃO' },
      { field: 'ativo_profissao', header: 'ATIVO' },
    ];

  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

      this.modalFormaConhecimentoNew = false;
      this.modalGeneroNew = false;
      this.modalProfissaoNew = false;

      this.getProfissoes();
      this.getGeneros();
      this.getFormasConhecimento();
    }

  }


  getProfissoes() {

    var dadosRetornados = [];

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-profissoes', this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          console.log(res);
          for (let line of res) {
            dadosRetornados.push({
              "id": line["id"], "codigo_profissao": line["codigo"],
              "descricao_profissao": line["nome"], "ativo_profissao": line["ativo"],
              "acao": ""
            });
          }
          this.listaProfissao = dadosRetornados;
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );

  }

  getGeneros() {

    var dadosRetornados = [];

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-generos', this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          console.log(res);
          for (let line of res) {
            dadosRetornados.push({
              "id": line["id"], "codigo_genero": line["codigo"],
              "descricao_genero": line["nome"], "ativo_genero": line["ativo"],
              "acao": ""
            });
          }
          this.listaGenero = dadosRetornados;
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );

  }

  getFormasConhecimento() {

    var dadosRetornados = [];

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-formas-conhecimento', this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          console.log(res);
          for (let line of res) {
            dadosRetornados.push({
              "id": line["id"], "codigo_formaconhecimento": line["codigo"],
              "descricao_formaconhecimento": line["nome"], "ativo_formaconhecimento": line["ativo"],
              "acao": ""
            });
          }
          this.listaFormaConhecimento = dadosRetornados;
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );

  }

  addFormaConhecimento() {
    if (isNullOrUndefined(this.codigoFormaConhecimentoNew)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Código' é obrigatório." });

    } else if (isNullOrUndefined(this.descricaoFormadeConhecimentoNew)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Descrição' é obrigatório." });

    } else {

      let dados = {
        empresa_id: this.dblinkedSessionService.empresa.id.toString(),
        codigo: this.codigoFormaConhecimentoNew.toString(),
        descricao: this.descricaoFormadeConhecimentoNew.toString(),
      }

      this.httpService.wait();
      this.httpService.post('/custom/cadastro/inserir-forma-conhecimento', dados)
        .subscribe(
          res => {
            console.log(res);
            this.httpService.done();
            if (!res["error"]) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              this.getFormasConhecimento();
              this.modalFormaConhecimentoNew = false;
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: res["message"] });
            }
          },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  addGenero() {
    if (isNullOrUndefined(this.codigoGeneroNew)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Código' é obrigatório." });

    } else if (isNullOrUndefined(this.descricaoGeneroNew)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Descrição' é obrigatório." });

    } else {

      let dados = {
        empresa_id: this.dblinkedSessionService.empresa.id.toString(),
        codigo: this.codigoGeneroNew.toString(),
        descricao: this.descricaoGeneroNew.toString(),
      }

      console.log(dados);



      this.httpService.wait();
      this.httpService.post('/custom/cadastro/inserir-genero', dados)
        .subscribe(
          res => {
            console.log(res);
            this.httpService.done();
            if (!res["error"]) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              this.getGeneros();
              this.modalGeneroNew = false;
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: res["message"] });
            }
          },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  addProfissao() {
    if (isNullOrUndefined(this.codigoProfissaoNew)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Código' é obrigatório." });

    } else if (isNullOrUndefined(this.descricaoProfissaoNew)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Descrição' é obrigatório." });

    } else {

      let dados = {
        empresa_id: this.dblinkedSessionService.empresa.id.toString(),
        codigo: this.codigoProfissaoNew.toString(),
        descricao: this.descricaoProfissaoNew.toString(),
      }

      console.log(dados);



      this.httpService.wait();
      this.httpService.post('/custom/cadastro/inserir-profissao', dados)
        .subscribe(
          res => {
            console.log(res);
            this.httpService.done();
            if (!res["error"]) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              this.getProfissoes();
              this.modalProfissaoNew = false;
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: res["message"] });
            }
          },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  editFormaConhecimento() {
    if (isNullOrUndefined(this.codigoEditFormaConhecimento)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Código' é obrigatório." });

    } else if (isNullOrUndefined(this.descricaoEditFormaConhecimento)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Descrição' é obrigatório." });

    } else {

      let dados = {
        id: this.idEditFormaConhecimento,
        empresa_id: this.dblinkedSessionService.empresa.id.toString(),
        codigo: this.codigoEditFormaConhecimento,
        descricao: this.descricaoEditFormaConhecimento.toString(),
        ativo: this.FormaConhecimentoAtivo.toString(),
      }

      console.log(dados);



      this.httpService.wait();
      this.httpService.post('/custom/cadastro/editar-forma-conhecimento', dados)
        .subscribe(
          res => {
            console.log(res);
            this.httpService.done();
            if (!res["error"]) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              this.modalEditFormaConhecimento = false;
              this.getFormasConhecimento();
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: res["message"] });
            }
          },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }

  }

  editGenero() {
    if (isNullOrUndefined(this.codigoEditGenero)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Código' é obrigatório." });

    } else if (isNullOrUndefined(this.descricaoEditGenero)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Descrição' é obrigatório." });

    } else {

      let dados = {
        id: this.idEditGenero,
        empresa_id: this.dblinkedSessionService.empresa.id.toString(),
        codigo: this.codigoEditGenero,
        descricao: this.descricaoEditGenero.toString(),
        ativo: this.GeneroAtivo.toString(),
      }

      console.log(dados);



      this.httpService.wait();
      this.httpService.post('/custom/cadastro/editar-genero', dados)
        .subscribe(
          res => {
            console.log(res);
            this.httpService.done();
            if (!res["error"]) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              this.modalEditGenero = false;
              this.getGeneros();
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: res["message"] });
            }
          },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  editProfissao() {
    if (isNullOrUndefined(this.codigoEditProfissao)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Código' é obrigatório." });

    } else if (isNullOrUndefined(this.descricaoEditProfissao)) {


      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: "O campo 'Descrição' é obrigatório." });

    } else {

      let dados = {
        id: this.idEditProfissao,
        empresa_id: this.dblinkedSessionService.empresa.id.toString(),
        codigo: this.codigoEditProfissao,
        descricao: this.descricaoEditProfissao.toString(),
        ativo: this.ProfissaoAtivo.toString(),
      }

      console.log(dados);



      this.httpService.wait();
      this.httpService.post('/custom/cadastro/editar-profissao', dados)
        .subscribe(
          res => {
            console.log(res);
            this.httpService.done();
            if (!res["error"]) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              this.modalEditProfissao = false;
              this.getProfissoes();
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'warn', detail: res["message"] });
            }
          },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  excluirFormaConhecimento(row) {
    if (confirm("Confirmar exclusão dessa Forma de Conhecimento?")) {



      this.httpService.wait();
      this.httpService.get('/custom/cadastro/remover-forma-conhecimento', row.id)
        .subscribe(
          res => {
            console.log(res);
            this.httpService.done();
            if (!res["error"]) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              this.getFormasConhecimento();
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'error', detail: res["message"] });
            }
          },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  excluirGenero(row) {
    if (confirm("Confirmar exclusão esse Gênero?")) {



      this.httpService.wait();
      this.httpService.get('/custom/cadastro/remover-genero', row.id)
        .subscribe(
          res => {
            console.log(res);
            this.httpService.done();
            if (!res["error"]) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              this.getGeneros();
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'error', detail: res["message"] });
            }
          },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  excluirProfissao(row) {
    if (confirm("Confirmar exclusão do funcionário?")) {



      this.httpService.wait();
      this.httpService.get('/custom/cadastro/remover-profissao', row.id)
        .subscribe(
          res => {
            console.log(res);
            this.httpService.done();
            if (!res["error"]) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', detail: res["message"] });
              this.getProfissoes();
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'error', detail: res["message"] });
            }
          },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  openEditFormaConhecimento(row) {
    this.codigoEditFormaConhecimento = row.codigo_formaconhecimento;
    this.descricaoEditFormaConhecimento = row.descricao_formaconhecimento;
    this.modalEditFormaConhecimento = true;
    this.FormaConhecimentoAtivo = row.ativo_formaconhecimento;
    this.idEditFormaConhecimento = row.id;
  }

  openEditGenero(row) {
    this.codigoEditGenero = row.codigo_genero;
    this.descricaoEditGenero = row.descricao_genero;
    this.modalEditGenero = true;
    this.GeneroAtivo = row.ativo_genero;
    this.idEditGenero = row.id;
  }

  openEditProfissao(row) {
    this.codigoEditProfissao = row.codigo_profissao;
    this.descricaoEditProfissao = row.descricao_profissao;
    this.modalEditProfissao = true;
    this.ProfissaoAtivo = row.ativo_profissao;
    this.idEditProfissao = row.id;
  }

  openNovaFormaConhecimento() {
    this.modalFormaConhecimentoNew = true;
    this.codigoFormaConhecimentoNew = null;
    this.descricaoFormadeConhecimentoNew = null;
  }

  openGenero() {
    this.modalGeneroNew = true;
    this.codigoGeneroNew = null;
    this.descricaoGeneroNew = null;
  }

  openProfissao() {
    this.modalProfissaoNew = true;
    this.codigoProfissaoNew = null;
    this.descricaoProfissaoNew = null;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {


  }

  /*Show Hide FormaConhecimento*/
  isCardFormaConhecimento: boolean = true;
  ShowHideFormaConhecimento() {
    this.isCardFormaConhecimento = !this.isCardFormaConhecimento;
    this.toolbarMain.setIcon('btn3', this.isCardFormaConhecimento ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn3', this.isCardFormaConhecimento ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide Genero*/
  isCardGenero: boolean = true;
  ShowHideGenero() {
    this.isCardGenero = !this.isCardGenero;
    this.toolbarMainGenero.setIcon('btn3', this.isCardFormaConhecimento ? 'expand_less' : 'expand_more')
    this.toolbarMainGenero.setTooltip('btn3', this.isCardFormaConhecimento ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide Profissao*/
  isCardProfissao: boolean = true;
  ShowHideProfissao() {
    this.isCardProfissao = !this.isCardProfissao;
    this.toolbarMainProfissao.setIcon('btn3', this.isCardFormaConhecimento ? 'expand_less' : 'expand_more')
    this.toolbarMainProfissao.setTooltip('btn3', this.isCardFormaConhecimento ? 'Ver menos' : 'Ver mais')
  }

}




