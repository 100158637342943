import { AuthGroup } from './auth-group.model';
import { AuthCrowd } from './auth-crowd.model';
import { AuthFeature } from './auth-feature.model';


export class AuthApp {
  id: number;
  key: string;
  name: string;
  mailAuth: boolean;
  crowdAuth = false;
  active = true;
  tokenTimeout: NodeJS.Timer;
  authGroups: Array<AuthGroup> = new Array();
  authCrowds: Array<AuthCrowd> = new Array();
  authFeatures: Array<AuthFeature> = new Array();

  constructor(authApp?: AuthApp | number) {
    if (authApp !== undefined && authApp !== null) {
      if (typeof authApp === 'number') {
        this.id = authApp;
      } else {
        this.id = authApp.id;
        this.key = authApp.key;
        this.name = authApp.name;
        this.mailAuth = authApp.mailAuth;
        this.crowdAuth = authApp.crowdAuth;
        this.active = authApp.active;
        this.tokenTimeout = authApp.tokenTimeout;
        this.authGroups = authApp.authGroups || new Array();
        this.authCrowds = authApp.authCrowds || new Array();
        this.authFeatures = authApp.authFeatures || new Array();
      }
    }
  }
}
