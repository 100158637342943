import { AuthCrowd } from './auth-crowd.model';
import { AuthGroup } from './auth-group.model';
import { AuthApp } from './auth-app.model';

export class AuthLoggedUser {
  id: number;
  displayName: string;
  login: string;
  mail: string;
  application: string;
  crowdId: number;
  deptoId: number;

  authCrowds: Array<AuthCrowd> = new Array();
  authGroups: Array<AuthGroup> = new Array();
  authApps: Array<AuthApp> = new Array();

  constructor(loggedUser?: any) {


    if (loggedUser !== undefined && loggedUser !== null) {
      this.id = loggedUser.authUser.authUserId;
      this.displayName = loggedUser.authUser.authUserDisplayName;
      this.login = loggedUser.authUser.authUserLogin;
      this.mail = loggedUser.authUser.authUserMail;
      this.application = loggedUser.authApplication;
      this.crowdId = loggedUser.authCrowdId;
      this.deptoId = loggedUser.deptoId;

      this.authCrowds = loggedUser.authCrowds || new Array();
      this.authGroups = loggedUser.authGroups || new Array();
      this.authApps = loggedUser.authApps || new Array();
    }
  }
}
