export class TipoCcus {
  id: number;
  codigo: number;
  descricao: string;

  constructor(tipoCcus?: TipoCcus | number) {
    if (tipoCcus !== undefined && tipoCcus !== null) {
      if (typeof tipoCcus === 'number') {
        this.id = tipoCcus;
      } else {
        this.id = tipoCcus.id;
        this.codigo = tipoCcus.codigo;
        this.descricao = tipoCcus.descricao;
      }
    }
  }
}
