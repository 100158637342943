import { AuthUser } from 'app/proof/model/auth-user.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Item } from 'app/cadastro/model/item.model';


export class ItemGrupo {

  id: number;
  codigo: string;
  descricao: string;
  dtInicial: Date;
  dtFinal: Date;
  empresa: Empresa;
  porcentagemLimiteDesconto: number;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;

  item: Array<Item> = new Array();

  constructor(itemGrupo?: ItemGrupo | number) {
    if (itemGrupo !== undefined && itemGrupo !== null) {
      if (typeof itemGrupo === 'number') {
        this.id = itemGrupo;
      } else {
        this.id = itemGrupo.id;
        this.codigo = itemGrupo.codigo;
        this.descricao = itemGrupo.descricao;
        this.dtInicial = itemGrupo.dtInicial;
        this.dtFinal = itemGrupo.dtFinal;
        this.empresa = itemGrupo.empresa;
        this.porcentagemLimiteDesconto = itemGrupo.porcentagemLimiteDesconto;
        this.createTimestamp = itemGrupo.createTimestamp;
        this.changeUser = itemGrupo.changeUser;
        this.changeTimestamp = itemGrupo.changeTimestamp;
        this.item = itemGrupo.item;
      }
    }
  }
}
