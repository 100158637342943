import { Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../app.config';
import { Period } from '../../shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { Message, SelectItem } from 'primeng/api';
import { TimezonePipe } from '../../shared/pipes/timezone.pipe';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { TipoDocumento } from 'app/util/model/tipo-documento.model';
import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { Justificativas } from 'app/util/model/justificativas.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { Departamento } from 'app/util/model/departamento.model';
import { Obrigacao } from 'app/centralweb/model/obrigacao.model';
import { Envio } from 'app/centralweb/model/envio.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { EnvioDestinatarios } from 'app/centralweb/model/envio-destinatarios.model';

@Component({
  selector: 'app-pendencias',
  templateUrl: './pendencias.component.html',
  styleUrls: ['./pendencias.component.scss'],
  providers: [CrudService]
})
export class PendenciasComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;

  pendencias: Array<any> = new Array();
  title: string;
  subtitle: string;

  constructor(private configService: ConfigService,
              private titleService: TitleService,
              private toolbarService: ToolbarService,
              private httpService: HttpService,
              private utilService: UtilService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private crudService: CrudService,
              private appComponent: AppComponent) {
  }

  ngOnInit() {
    this.title = 'PENDÊNCIAS';
    this.subtitle = 'RELAÇÃO DAS OBRIGAÇÕES PENDENTES DE ENVIO';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    Promise.resolve(null).then(() => this.toolbarRefresh());
    this.titleService.title = 'RELATÓRIO DE PENDÊNCIAS';

    this.pendencias = new Array<any>();

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.periodChanged(); });
  }

  periodChanged(): void {
    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {

        this.httpService.wait();
        this.httpService.get('/custom/centralweb/get-pendencias', this.dblinkedSessionService.usuario.id)
                        .subscribe(p => this.loadPendencias(p),
                                   error => this.httpService.handleError(error, () => this.periodChanged()),
                                   () => this.httpService.done());
    }
  }

  ngOnDestroy() {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  loadPendencias(value: Array<any>) {
    this.pendencias = new Array();
    value.forEach(element => {
      this.pendencias.push(element);
    });
  }
  private toolbarRefresh() {
    this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-other-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-first-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}
