export class TipoContribuinte {
  id: number;
  descricao: string;

  constructor(tipoContribuinte?: TipoContribuinte | number) {
    if (tipoContribuinte !== undefined && tipoContribuinte !== null) {
      if (typeof tipoContribuinte === 'number') {
        this.id = tipoContribuinte;
      } else {
        this.id = tipoContribuinte.id;
        this.descricao = tipoContribuinte.descricao;
      }
    }
  }
}
