import { CadProj } from 'app/contabil/model/cad-proj.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { PedidoItem } from 'app/financeiro/model/pedido-item.model';
import { MovFin } from './mov-fin.model';


export class FinanceiroRateio {
  id: number;
  movFin: MovFin;
  cadProj: CadProj;
  cadCcus: CadCcus;
  porcentagem: number;

  constructor(financeiroRateio?: FinanceiroRateio | number) {
    if (financeiroRateio !== undefined && financeiroRateio !== null) {
      if (typeof financeiroRateio === 'number') {
        this.id = financeiroRateio;
      } else {
        this.id = financeiroRateio.id;
        this.movFin = financeiroRateio.movFin;
        this.cadProj = financeiroRateio.cadProj;
        this.cadCcus = financeiroRateio.cadCcus;
        this.porcentagem = financeiroRateio.porcentagem;
    }
  }
}
}
