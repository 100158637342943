export class TbModoPrestacao {
  id: number;
  cod: string;
  descricao: string;
  valueAlias: string;

  constructor(tbModoPrestacao?: TbModoPrestacao | number) {
    if (tbModoPrestacao) {
      if (typeof tbModoPrestacao === 'number') {
        this.id = tbModoPrestacao;
      } else {
        this.id = tbModoPrestacao.id;
        this.cod = tbModoPrestacao.cod;
        this.descricao = tbModoPrestacao.descricao;
        this.valueAlias = tbModoPrestacao.valueAlias;
      }
    }
  }
}
