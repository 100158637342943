import { Empresa } from 'app/cadastro/model/empresa.model';
import { isNullOrUndefined } from 'util';
import { Orcamento } from './orcamento.model';





export class Periodo {
  id: number;
  ano: number;
  dataFormatada?: string;
  seqMes: number;
  fimMes?: number;
  dtMesRef: Date;
  empresa: Empresa;

  constructor(periodo?: Periodo | number) {

    if (!isNullOrUndefined(periodo)) {

      if (typeof periodo === 'number') {
        this.id = periodo;
      } else {
        this.id = periodo.id;
        this.ano = periodo.ano;
        this.dataFormatada = periodo.dataFormatada;
        this.seqMes = periodo.seqMes;
        this.fimMes = periodo.fimMes;
        this.dtMesRef = periodo.dtMesRef;
        this.empresa = periodo.empresa;
      }
    }
  }
}
