import { Requisicao } from './requisicao.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { ParticipanteEmpresa } from 'app/cadastro/model/participante-empresa.model';


export class RequisicaoFornecedorSugerido {
  id: number;
  requisicao: Requisicao;
  nome: string;
  email: string;
  area: string;
  telefone: string;
  empresa: any;
  link: string;
  observacao: string;
  participanteEmpresaId: number

  constructor(requisicaoFornecedorSugerido?: RequisicaoFornecedorSugerido | number) {
    if (requisicaoFornecedorSugerido !== undefined && requisicaoFornecedorSugerido !== null) {
      if (typeof requisicaoFornecedorSugerido === 'number') {
        this.id = requisicaoFornecedorSugerido;
      } else {
        this.id = requisicaoFornecedorSugerido.id;
        this.requisicao = requisicaoFornecedorSugerido.requisicao;
        this.nome = requisicaoFornecedorSugerido.nome;
        this.email = requisicaoFornecedorSugerido.email;
        this.area = requisicaoFornecedorSugerido.area;
        this.telefone = requisicaoFornecedorSugerido.telefone;
        this.empresa = requisicaoFornecedorSugerido.empresa;
        this.link = requisicaoFornecedorSugerido.link;
        this.observacao = requisicaoFornecedorSugerido.observacao;
        this.participanteEmpresaId = requisicaoFornecedorSugerido.participanteEmpresaId
      }
    }
  }
}
