import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Empresa } from '../../cadastro/model/empresa.model';
import { UploadType } from '../model/upload-type.model';
import { Processo } from '../model/processo.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'dblinked-siscoserv-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class SiscoservImportComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;

  fileName: string = null;
  files: Array<any> = null;
  zipFileName: string = null;
  fileType: UploadType = null;
  fileTypes: Array<UploadType> = new Array();
  replace: boolean = null;
  msgs: Message[] = null;
  logAdvertencia: Message[] = null;
  processos: Processo[] = null;
  selectedProcessos: Processo[] = new Array();
  modalLog: boolean;
  modalHelp: boolean;
  canBeOverwritten: boolean = true;
  error = false;
  message: string = null;
  duplicated: string = null;
  errors: Array<string> = null;

  private empresaSubscription: Subscription;

  titlePrincipal: string;
  subtitle: string;

  constructor(private titleService: TitleService,
              private toolbarService: ToolbarService,
              private httpService: HttpService,
              private dblinkedSessionService: DblinkedSessionService,
              private messageService: MessageService
              ) {
  }

  ngOnInit(): void {
    this.titlePrincipal = 'IMPORTAR SISCOSERV';
    this.subtitle = 'IMPORT DE DADOS NO SISTEMA';
    this.toolbarService.clear();
    Promise.resolve(null).then(() => this.toolbarRefresh());
    // this.fileTypes.push(new UploadType('siscoserv-ras', 'Documento RAS'));
    // this.fileTypes.push(new UploadType('siscoserv-rvs', 'Documento RVS'));
    // this.fileTypes.push(new UploadType('siscoserv-retorno', 'XML Retorno'));
    this.fileTypes.push(new UploadType('siscoserv-ras-import', 'Importação RAS'));
    this.fileTypes.push(new UploadType('siscoserv-rvs-import', 'Importação RVS'));
    this.fileTypes.push(new UploadType('siscoserv-rp-import', 'Importação RP'));
    this.fileTypes.push(new UploadType('siscoserv-rf-import', 'Importação RF'));
    this.fileTypes.push(new UploadType('siscoserv-xml-ras-import', 'Importação XML-RAS'));
    this.fileTypes.push(new UploadType('siscoserv-xml-rvs-import', 'Importação XML-RVS'));
    this.fileTypes.push(new UploadType('siscoserv-xml-rp-import', 'Importação XML-RP'));
    this.fileTypes.push(new UploadType('siscoserv-xml-rf-import', 'Importação XML-RF'));

    this.toolbarService.clear();
    this.titleService.title = 'SISCOSERV - Processar arquivos';

    this.empresaSubscription = this.dblinkedSessionService.empresaChanged.subscribe(() => {
      this.newFile();
    });
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.newFile();
    }
  }

  getProcessos() {
    this.processos = null;

    if (this.dblinkedSessionService.hasEmpresaSelected) {
      // TODO: Pagination
      this.httpService.wait();
      this.httpService.get('/custom/siscoserv/empresa', this.dblinkedSessionService.empresa.id)
                      .subscribe(processos => this._getProcessos(processos),
                                error => this.httpService.handleError(error, () => this.getProcessos()),
                                () => this.httpService.done());
    }
    this.toolbarRefresh();
  }
  _getProcessos(processos: Array<Processo>) {
    this.processos = processos;
  }

  fileTypeChange() {
    if (this.fileInput) {
      this.fileInput.nativeElement.files = null;
      this.fileChange();
    }
    this.files = null;
    this.zipFileName = null;
    this.error = false;
    this.errors = null;
    this.message = null;
    this.replace = this.fileType.tag === 'siscoserv-retorno' ? false : null;
  }

  fileChange() {
    this.files = this.fileInput.nativeElement.files;
  }

  upload() {
    this.message = null;

    if (this.files.length === 1) {
      this.fileName = this.files[0].name;

      const data: FormData = new FormData();
      data.append('file', this.files[0]);
      data.append('type', this.fileType.tag);
      data.append('replace', this.replace ? 'true' : 'false');
      data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());

      this.httpService.wait();
      this.httpService.post('/custom/upload', data)
                      .subscribe(result => this.fileUploaded(result),
                                 error => this.httpService.handleError(error,
                                                                       () => this.upload(),
                                                                       (message: any) => this.displayErrorMessage(message)),
                                 () => this.httpService.done());
    }
    this.toolbarRefresh();
  }

  overwriteFiles() {
    this.duplicated = null;
    const data: FormData = new FormData();
      data.append('file', this.files[0]);
      data.append('type', this.fileType.tag);
      data.append('replace', this.replace ? 'true' : 'false');
      data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());

    this.httpService.wait();
      this.httpService.get('/custom/siscoserv/overwriteFiles(' +
      this.fileType.tag + ', ' + this.dblinkedSessionService.empresa.id +')')
                      .subscribe(result => this.fileUploaded(result),
                                 error => this.httpService.handleError(error,
                                                                       () => this.upload(),
                                                                       (message: any) => this.displayErrorMessage(message)),
                                 () => this.httpService.done());
  }

  ignoreOverwriteFiles() {
    this.duplicated = null;
    const data: FormData = new FormData();
      data.append('file', this.files[0]);
      data.append('type', this.fileType.tag);
      data.append('replace', this.replace ? 'true' : 'false');
      data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());


    this.httpService.wait();
      this.httpService.get('/custom/siscoserv/ignoreOverwriteFiles(' +
      this.fileType.tag + ', ' + this.dblinkedSessionService.empresa.id +')')
                      .subscribe(result => this.fileUploaded(result),
                                 error => this.httpService.handleError(error,
                                                                       () => this.upload(),
                                                                       (message: any) => this.displayErrorMessage(message)),
                                 () => this.httpService.done());
  }

  newFile() {
    this.message = null;
    this.cancel();
    this.files = null;
    this.fileName = null;
    this.selectedProcessos = new Array();
    this.processos = new Array();
    this.toolbarRefresh();
  }

  cancel() {
    this.fileType = null;
    this.replace = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = null;
    }
    this.message = null;
    this.error = false;
    this.errors = null;
    this.zipFileName = null;
    this.toolbarRefresh();
  }

  displayErrorMessage(error: String | any) {
    this.cancel();
    this.error = true;
    this.message = typeof error === 'string' ? error : error.message;
  }

  fileUploaded(result: any) {
    try {

      this.message = null;
      this.errors = null;
      this.error = false;

      if (typeof result.response === 'string') {
        this.displayErrorMessage(result.response);
      } else {
        this.errors = result.response.errors;

        if (!this.errors) {
          this.processos = result.response.processos;

          switch (this.fileType.tag) {
            case 'siscoserv-retorno':
              this.message = 'Arquivo de retorno processado com sucesso.';
              break;

            case 'siscoserv-ras-import':
            case 'siscoserv-rvs-import':
            case 'siscoserv-rp-import':
            case 'siscoserv-rf-import':
            case 'siscoserv-xml-ras-import':
            case 'siscoserv-xml-rvs-import':
            case 'siscoserv-xml-rp-import':
            case 'siscoserv-xml-rf-import':
            // do...




              result.response.forEach(element => {
                if (element.error) {
                  if (element.message.includes("Dados já existentes no Banco") && (this.canBeOverwritten == true)) {
                    this.duplicated = "duplicated";
                  } else if (element.message.includes("Dados já removidos no Banco") && (this.canBeOverwritten == true)) {
                    this.duplicated = "duplicated";
                  } else if (element.message.includes("Dados não podem ser sobrescritos no Banco")) {
                    this.canBeOverwritten = false;
                  }
                  this.logAdvertencia.push({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência:', detail: element.message });
                }
              });

              if (this.logAdvertencia.length > 0) {
                this.toolbarRefreshLog();
                this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência:',
                                      detail: 'Ocorreram erros que impossibilitaram a ação, verifique os detalhes no log' });
              } else {
                  result.response.forEach(element => {
                    this.messageService.add({severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: element.message});
                  });
                }
            break;

            default:
              this.message = this.processos.length + ' processos enviados com sucesso.';
          }
        }

        if (result.response.file) {
          window.open(this.httpService.url('/custom/file/' + result.response.file), '_blank');
        }
      }
    } catch (error) {
      console.log('\n--> ' + error + '\n\n');
    }
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    if (this.empresaSubscription) {
      this.empresaSubscription.unsubscribe();
    }
  }

  filePath(fileName: string): string {
    return this.httpService.url('/custom/file/' + fileName);
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }
  private toolbarRefresh() {
    this.toolbarService.clear();
    this.toolbarService.add('info', false, 'class-edit-button', 'info', 'Modelo de Planilhas', () => this.abreModalHelp());
    this.toolbarService.add('warning', true, 'class-edit-button', 'warning', 'Visualizar Log');
    this.toolbarService.add('export', true, 'class-edit-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-edit-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }

  private toolbarRefreshLog() {
    this.toolbarService.clear();
    this.toolbarService.add('info', false, 'class-edit-button', 'info', 'Modelo de Planilhas', () => this.abreModalHelp());
    this.toolbarService.add('info', false, 'class-edit-button', 'info', 'Modelo de Planilhas');
    this.toolbarService.add('warning', false, 'class-edit-button', 'warning', 'Visualizar Log', () => this.abreModalLog());
    this.toolbarService.add('export', true, 'class-edit-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-edit-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }

  abreModalLog() {
    this.modalLog = true;
  }

  private abreModalHelp() {
    window.open('https://sites.google.com/linnks.com.br/modelossiscoserv/p%C3%A1gina-inicial', '_blank');
  }
}
