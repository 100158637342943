import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs/Subscription'
import { CrudService } from 'app/shared/services/crud.service'
import { HttpService } from 'app/shared/services/http.service'
import { SessionService } from 'app/shared/services/session.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { SelectItem } from 'app/shared/common/select-item.interface'
import { ConfigService } from 'app/shared/services/config.service'
import { MessageService } from 'primeng/api'
import { Toolbar } from 'app/shared/model/toolbar.model'
import { ToolbarService } from 'app/shared/services/toolbar.service'

@Component({
  selector: 'app-empresa-parametro-financeiro',
  templateUrl: './empresa-parametro-financeiro.component.html',
  styleUrls: ['./empresa-parametro-financeiro.component.scss'],
  providers: [CrudService]
})

export class EmpresaParametroFinanceiroComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription

  //Toolbar
  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { key: 'voltar', icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.voltar() },
    { key: 'editar', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { key: 'excluir', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { key: 'novo', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { key: 'salvar_novo', icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { key: 'salvar_edit', icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]

  //Boolean
  editarConta: boolean = false
  novaConta: boolean = false
  pagamentoSomenteContaPadrao: boolean = false
  recebimentoSomenteContaPadrao: boolean = false
  permiteAlteracaoVenda: boolean = false
  verificaLimiteCompra: boolean = false
  verificaLimiteCredito: boolean = false
  geraFinanceiro: boolean = false

  //String
  title: string = 'Parâmetros financeiros'

  //Array
  listaParametros: Array<any> = new Array()
  selectedRows: Array<any> = new Array()

  //Dropdown e multiselect
  optAlteracaoPrecoVenda: SelectItem[] = [
    { label: 'Todos', value: null },
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
  ]
  optEmpresaConta: SelectItem[] = []
  optResponsaveis: SelectItem[] = []

  //Number
  contaBancariaPagamento: number = null
  contaBancariaRecebimento: number = null
  respPrimeiraAlcada: number = null
  valorPrimeiraAlcada: number = null
  respSegundaAlcada: number = null
  valorSegAlcada: number = null
  respTerceiraAlcada: number = null
  valorTerAlcada: number = null
  respCredito: number = null

  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { if (this.dblinkedSessionService.hasEmpresaSelected) this.carregar() }
    )
  }

  ngOnDestroy() {
    this.sessionSubscription.remove(this.sessionSubscription)
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
  }

  voltar() {
    this.novaConta = false
    this.editarConta = false
    this.changeToolbar()
    this.selectedRows = new Array()
  }

  carregar() {
    this.getResponsaveis()
    this.getEmpresaConta()
    this.loadRegistros()
  }

  getEmpresaConta() {
    this.httpService.wait()
    this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,instituicaoFinanceira,tipoConta/id,tipoConta/descricao,ativo'
      + '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo eq true&$orderby=nome')
      .subscribe(ref => {
        this.optEmpresaConta = [{ label: "Nenhum", value: null }]
        ref.value.forEach(element => {
          this.optEmpresaConta.push({
            label: element.id + ' - ' + element.nome +
              (element.agencia ? ' - AG: ' + element.agencia : '') +
              (element.conta ? ' - CC: ' + element.conta : '') +
              (element.dvConta ? '-' + element.dvConta : '') +
              (element.tipoConta ? ' - ' + element.tipoConta.descricao : ''),
            value: element.id
          })
        })
      },
        error => {
          this.exibirMensagem({ error: true, mensagem: "Não foi possível recuperar as contas bancárias, contate o suporte: " + error.message })
        },
        () => this.httpService.done()
      )
  }

  getResponsaveis() {
    this.httpService.wait()
    this.httpService.get('/custom/cadastro/controle-acesso/usuario?empresa_id=' + this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          if (!res.error) {
            this.optResponsaveis = [{ label: "Nenhum", value: null }]
            res.value.forEach(row => {
              this.optResponsaveis.push({ label: row.nome + " " + row.sobrenome, value: row.id })
            })

          } else {
            this.exibirMensagem({ error: true, mensagem: res.value })
          }
        },
        error => {
          this.exibirMensagem({ error: true, mensagem: "Não foi possível recuperar os responsáveis, contate o suporte: " + error.message })
        },
        () => this.httpService.done()
      )
  }

  criarNovo() {
    this.resetForm()
    this.novaConta = true
    this.changeToolbar()
  }

  loadRegistros() {
    this.httpService.wait()
    this.httpService.get(`/empresaparametrofinanceiro?$select=respLimiteCompraPriAlcada/id,respLimiteCompraPriAlcada/nome,valorPriAlcada,` +
      `respLimiteCompraSegAlcada/id,valorSegAlcada,respLimiteCompraTerAlcada/id,valorTerAlcada,respLimiteCredito/id,respLimiteCredito/nome,` +
      `empresaContaPagamento/id,empresaContaPagamento/nome,empresaContaRecebimento/id,empresaContaRecebimento/nome,permiteAlteracaoPrecoVenda,` +
      `pagamentoSomenteContaPadrao,recebimentoSomenteContaPadrao,verificaLimiteCompra,verificaLimiteCredito,pedidoGeraFinanceiro` +
      `&$filter=empresa/id eq ${this.dblinkedSessionService.empresa.id}`)
      .subscribe(
        res => {
          this.listaParametros = new Array()
          res.value.forEach(item => {
            this.listaParametros.push(item)
          })
          this.httpService.done()
        },
        error => {
          this.exibirMensagem({ error: true, message: "Erro no API ao recuperar os parâmetros: " + error.message })
          this.httpService.done()
        }
      );
  }

  loadEditarRegistro() {
    this.editarConta = true
    this.changeToolbar()
    this.resetForm()

    this.contaBancariaPagamento = this.selectedRows[0].empresaContaPagamento ? this.selectedRows[0].empresaContaPagamento.id : null
    this.contaBancariaRecebimento = this.selectedRows[0].empresaContaRecebimento ? this.selectedRows[0].empresaContaRecebimento.id : null
    this.respPrimeiraAlcada = this.selectedRows[0].respLimiteCompraPriAlcada ? this.selectedRows[0].respLimiteCompraPriAlcada.id : null
    this.valorPrimeiraAlcada = this.selectedRows[0].valorPriAlcada
    this.respSegundaAlcada = this.selectedRows[0].respLimiteCompraSegAlcada ? this.selectedRows[0].respLimiteCompraSegAlcada.id : null
    this.valorSegAlcada = this.selectedRows[0].valorSegAlcada
    this.respTerceiraAlcada = this.selectedRows[0].respLimiteCompraTerAlcada ? this.selectedRows[0].respLimiteCompraTerAlcada.id : null
    this.valorTerAlcada = this.selectedRows[0].valorTerAlcada
    this.respCredito = this.selectedRows[0].respLimiteCredito ? this.selectedRows[0].respLimiteCredito.id : null
    this.pagamentoSomenteContaPadrao = this.selectedRows[0].pagamentoSomenteContaPadrao
    this.recebimentoSomenteContaPadrao = this.selectedRows[0].recebimentoSomenteContaPadrao
    this.permiteAlteracaoVenda = this.selectedRows[0].permiteAlteracaoPrecoVenda
    this.verificaLimiteCompra = this.selectedRows[0].verificaLimiteCompra
    this.verificaLimiteCredito = this.selectedRows[0].verificaLimiteCredito
    this.geraFinanceiro = this.selectedRows[0].pedidoGeraFinanceiro
  }

  salvarNovoRegistro() {
    const infos = {
      valorPriAlcada: this.valorPrimeiraAlcada,
      valorSegAlcada: this.valorSegAlcada,
      valorTerAlcada: this.valorTerAlcada,
      empresaContaPagamento: this.contaBancariaPagamento ? { id: this.contaBancariaPagamento } : null,
      empresaContaRecebimento: this.contaBancariaRecebimento ? { id: this.contaBancariaRecebimento } : null,
      respLimiteCompraPriAlcada: this.respPrimeiraAlcada ? { id: this.respPrimeiraAlcada } : null,
      respLimiteCompraSegAlcada: this.respSegundaAlcada ? { id: this.respSegundaAlcada } : null,
      respLimiteCompraTerAlcada: this.respTerceiraAlcada ? { id: this.respTerceiraAlcada } : null,
      respLimiteCredito: this.respCredito ? { id: this.respCredito } : null,
      pagamentoSomenteContaPadrao: this.pagamentoSomenteContaPadrao,
      recebimentoSomenteContaPadrao: this.recebimentoSomenteContaPadrao,
      permiteAlteracaoPrecoVenda: this.permiteAlteracaoVenda,
      verificaLimiteCompra: this.verificaLimiteCompra,
      verificaLimiteCredito: this.verificaLimiteCredito,
      pedidoGeraFinanceiro: this.geraFinanceiro,
      empresa: {
        id: this.dblinkedSessionService.empresa.id
      }
    }

    this.httpService.wait()
    this.httpService.post('/empresaparametrofinanceiro', infos)
      .subscribe(
        res => {
          this.exibirMensagem({ error: false, message: "Registro gravado com sucesso" })
          this.loadRegistros()
          this.novaConta = false
          this.changeToolbar()
          this.httpService.done()
        },
        error => {
          this.exibirMensagem({ error: true, message: "Erro no API ao salvar os parâmetros: " + error.message })
          this.httpService.done()
        })
  }

  salvarAlteracaoRegistro() {
    const infos = {
      valorPriAlcada: this.valorPrimeiraAlcada,
      valorSegAlcada: this.valorSegAlcada,
      valorTerAlcada: this.valorTerAlcada,
      empresaContaPagamento: this.contaBancariaPagamento ? { id: this.contaBancariaPagamento } : null,
      empresaContaRecebimento: this.contaBancariaRecebimento ? { id: this.contaBancariaRecebimento } : null,
      respLimiteCompraPriAlcada: this.respPrimeiraAlcada ? { id: this.respPrimeiraAlcada } : null,
      respLimiteCompraSegAlcada: this.respSegundaAlcada ? { id: this.respSegundaAlcada } : null,
      respLimiteCompraTerAlcada: this.respTerceiraAlcada ? { id: this.respTerceiraAlcada } : null,
      respLimiteCredito: this.respCredito ? { id: this.respCredito } : null,
      pagamentoSomenteContaPadrao: this.pagamentoSomenteContaPadrao,
      recebimentoSomenteContaPadrao: this.recebimentoSomenteContaPadrao,
      permiteAlteracaoPrecoVenda: this.permiteAlteracaoVenda,
      verificaLimiteCompra: this.verificaLimiteCompra,
      verificaLimiteCredito: this.verificaLimiteCredito,
      pedidoGeraFinanceiro: this.geraFinanceiro,
      id: this.selectedRows[0].id
    }

    this.httpService.wait()
    this.httpService.post('/custom/cadastro/atualizar-empresa-parametro-financeiro', infos)
      .subscribe(
        res => {
          if (res.error) {
            this.exibirMensagem({ error: true, message: res.detail })
            this.httpService.done()
            return
          }

          this.exibirMensagem({ error: false, message: "Registro atualizado com sucesso" })
          this.loadRegistros()
          this.editarConta = false
          this.changeToolbar()
          this.selectedRows = new Array()
          this.httpService.done()
        },
        error => {
          this.exibirMensagem({ error: true, message: "Erro no API ao atualizar os parâmetros: " + error.message })
          this.httpService.done()
        })
  }

  excluirRegistro() {
    if (confirm('Deseja excluir os registros selecionados?')) {
      this.httpService.wait()
      this.httpService.delete('/empresaparametrofinanceiro', this.selectedRows[0].id)
        .subscribe(
          res => {
            this.exibirMensagem({ error: false, message: "Registor excluído com sucesso" })
            this.loadRegistros()
            this.selectedRows = new Array()
            this.changeToolbar()
            this.httpService.done()
          },
          error => {
            this.exibirMensagem({ error: true, message: "Erro no API ao excluir o registro: " + error.message })
            this.httpService.done()
          })
    }
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  exibirMensagem(value: any) {
    let msgs = value.mensagem ? value.mensagem : value.message
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: msgs });

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: msgs });
    }
  }

  changeToolbar() {
    console.log(this.selectedRows)
    this.toolbarMain.setVisible('voltar', this.novaConta || this.editarConta)
    this.toolbarMain.setVisible('editar', !this.novaConta && !this.editarConta && this.selectedRows.length === 1)
    this.toolbarMain.setVisible('excluir', !this.novaConta && !this.editarConta && this.selectedRows.length === 1)
    this.toolbarMain.setVisible('novo', !this.novaConta && !this.editarConta)
    this.toolbarMain.setVisible('salvar_novo', this.novaConta)
    this.toolbarMain.setVisible('salvar_edit', this.editarConta)
  }

  resetForm() {
    this.contaBancariaPagamento = null
    this.contaBancariaRecebimento = null
    this.respPrimeiraAlcada = null
    this.valorPrimeiraAlcada = null
    this.respSegundaAlcada = null
    this.valorSegAlcada = null
    this.respTerceiraAlcada = null
    this.valorTerAlcada = null
    this.respCredito = null
    this.pagamentoSomenteContaPadrao = false
    this.recebimentoSomenteContaPadrao = false
    this.permiteAlteracaoVenda = false
    this.verificaLimiteCompra = false
    this.verificaLimiteCredito = false
    this.geraFinanceiro = false
  }
}
