import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import tools from 'app/util/processing/tools';
import deepCopy from 'app/util/processing/deepcopy';
import { UtilService } from 'app/shared/services/util.service';

@Component({
  selector: 'app-periodo',
  templateUrl: './periodo.component.html',
  styleUrls: ['./periodo.component.scss'],
})
export class PeriodoFiscalComponent implements OnInit, OnDestroy {


  private sessionSubscription: Subscription;
  defaultDate: Date;
  title: string;
  subtitle: string;
  msgs: Message[];
  periodoFis: Array<any> = new Array();
  periodInitialState: Array<any> = new Array();
  tools: tools

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    public dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private utilService: UtilService) {
    this.tools = new tools(this.messageService)
  }

  ngOnInit(): void {
    this.title = 'FISCAL';
    this.titleService.title = 'Período - Fiscal';
    this.subtitle = 'Controle de Períodos - FISCAL';
    this.toolbarService.clear();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
        this.getPeriodos();
      }
    });
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  getPeriodos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'ano': this.dblinkedSessionService.periodo.year.value.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'fiscal/periodos', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.tools.exibirMensagem(res)
            return
          }

          this.periodoFis = res["data"].map((item => {
            let row = item;
            if (row.dt_fechamento !== null && row.dt_fechamento !== '') {
              row.dt_fechamento = this.utilService.toDate(row.dt_fechamento);
            }
            return row;
          }));
          this.periodInitialState = deepCopy(this.periodoFis);
          console.log(this.periodInitialState);

        },
        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao obter os períodos fiscais, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
        },
        () => {
          this.httpService.done();
        }
      )
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  mudarStatusPeriodo(row) {
    if (row.status_id === 1) {
      var acao: string = 'encerrar';
      var tipo: string = 'E';

    } else {
      var acao: string = 'reabrir';
      var tipo: string = 'A';
    }

    if (!confirm('Deseja realmente ' + acao + ' o mês de ' + row.mes + '?')) return

    const infos = {
      ecd_id: row.ecdid,
      user_id: this.sessionService.loggedUser.id,
      tipo: tipo
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'fiscal/periodos', infos)
      .subscribe(
        res => {
          this.httpService.done()
          this.tools.exibirMensagem(res)
          if (!res.error) this.getPeriodos()
        },
        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao atualizar o período fiscal, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  atualizarDataFechamento(row) {
    var tipo: string = 'P';

    const initialState = this.periodInitialState.filter(item => item.ecdid == row.ecdid)[0];

    if (!confirm('Deseja realmente mudar a data de fehcamento do mês de ' + row.mes + '?')) {
      row.dt_fechamento = initialState.dt_fechamento;
      return
    }

    const infos = {
      ecd_id: row.ecdid,
      user_id: this.sessionService.loggedUser.id,
      tipo: tipo,
      dt_fechamento: row.dt_fechamento !== null && row.dt_fechamento !== '' ? row.dt_fechamento.getFullYear() + "-" + (row.dt_fechamento.getMonth() + 1) + "-" + row.dt_fechamento.getDate() : null
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'fiscal/periodos', infos)
      .subscribe(
        res => {
          this.tools.exibirMensagem(res);
          if (!res.error) this.getPeriodos();
          else row.dt_fechamento = initialState.dt_fechamento;
        },
        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao atualizar o período fiscal, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
        }, () => {
          this.httpService.done();
        }
      );
  }
}
