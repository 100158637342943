import { isNullOrUndefined } from "util";
import { EventosFicha } from "./eventos.ficha";

export class EventosFichaDependente {

  id                  : number;
  eventosFicha        : EventosFicha;
  nome                : string;
  cpf                 : string;
  email               : string;
  telefone            : string;
  ingressoId          : number;
  ingressoDescricao   : string;


  constructor(eventosFichaDependente?: EventosFichaDependente | number){
    if ( !isNullOrUndefined(eventosFichaDependente)) {
      if (typeof eventosFichaDependente === 'number') {
        this.id                 = eventosFichaDependente;
      } else {
        this.id                 = eventosFichaDependente.id;
        this.nome               = eventosFichaDependente.nome;
        this.cpf                = eventosFichaDependente.cpf;
        this.email              = eventosFichaDependente.email;
        this.eventosFicha       = eventosFichaDependente.eventosFicha;
        this.ingressoId         = eventosFichaDependente.ingressoId;
        this.ingressoDescricao  = eventosFichaDependente.ingressoDescricao;
      }
    }

  }



}