export class AuthStatus {
  id: number;
  shortDescription: string;
  description: string;

  constructor(authStatus?: AuthStatus | number) {
    if (authStatus !== undefined && authStatus !== null) {
      if (typeof authStatus === 'number') {
        this.id = authStatus;
      } else {
        this.id = authStatus.id;
        this.shortDescription = authStatus.shortDescription;
        this.description = authStatus.description;
      }
    }
  }
}
