import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CrudService } from 'app/shared/services/crud.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ConfigService } from 'app/shared/services/config.service';
import { TitleService } from 'app/shared/services/title.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from 'app/shared/services/http.service';
import { Subscription } from "rxjs/Subscription";
import { Message, SelectItem } from 'primeng/api';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-gestao-orcamento',
  templateUrl: './gestao-orcamento.component.html',
  styleUrls: ['./gestao-orcamento.component.scss'
  ],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',
      })),
      transition('initial=>final', animate('120ms')),
      transition('final=>initial', animate('70ms'))
    ]),
  ]
})

export class GestaoOrcamentoComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = [];
  msgsModal: Message[] = null;
  titlePrincipal: string;
  subtitle: string;
  listVersaoOrc: Array<any>;
  listVisao: Array<any>;
  versaoSelecionada: any;
  versaoEdit: any;
  detailVersao: TreeNode[] = [];
  detailSelecionados: TreeNode[] = [];
  btnOptions: boolean = true;
  listNiveis: Array<any> = [];
  modalEditStatus: boolean = false;
  opcoesStatusOrcamento: SelectItem[];
  opcoesAno: SelectItem[];
  opcoesTipoOrcamento: SelectItem[];
  hasVersaoSelected: boolean = false;
  hasGestorSelected: boolean = false;
  listGestor: Array<any> = [];

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.toolbarService.clear();
    this.titlePrincipal = 'Gerenciamento';
    this.subtitle = 'Controles e Análises de Orçamentos';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.modalEditStatus = false;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.listVersaoOrc = new Array();
          this.listGestor = new Array();
          this.detailVersao = new Array();
          this.versaoSelecionada = null;
          this.versaoEdit = null;
          this.hasVersaoSelected = false;
          this.hasGestorSelected = false;
          this.getVersoesOrcamento();
          this.comboAno();
          this.comboOpcoes();
          this.carregarTipoOrcamento();
        }
      }
    );

    this.listVisao = new Array();
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  getVersoesOrcamento() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'ano': this.dblinkedSessionService.periodo.year.value.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/get-versoes-orcamento-gestao', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
          }

          this.listVersaoOrc = res['mensagem'];
          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        });
  }

  comboOpcoes() {
    this.httpService.wait();
    this.httpService.get('/statusorcamento?$select=id,descricao&$orderby=descricao')
      .subscribe(ref => {
        this.opcoesStatusOrcamento = [];

        ref.value.forEach(element => {
          this.opcoesStatusOrcamento.push({ label: element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboOpcoes()),
        () => this.httpService.done());
  }

  carregarTipoOrcamento() {

    this.httpService.wait();
    this.httpService.get('/tipoorcamento?$select=id,codigo,descricao,ativo&$orderby=descricao&$filter=ativo=true')
      .subscribe(ref => {
        this.opcoesTipoOrcamento = [];
        ref.value.forEach(element => {
          this.opcoesTipoOrcamento.push({ label: element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.comboOpcoes()),
        () => this.httpService.done());

  }

  comboAno() {
    this.httpService.wait();
    this.httpService.get('/custom/orcamento/get-ano-empresa(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(ref => {
        this.opcoesAno = [];

        ref.forEach(element => {
          this.opcoesAno.push({ label: element.ano, value: element.ano });
        });

      },
        error => this.httpService.handleError(error, () => this.comboAno()),
        () => this.httpService.done());
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.msgs = [];
      this.msgs.push({ severity: "success", summary: "Sucesso", detail: value.mensagem });
    } else {
      this.msgs = [];
      this.msgs.push({ severity: "warn", summary: "Erro", detail: value.mensagem });
    }
  }

  hideMessage() {
    this.msgs = null;
    this.msgsModal = null;
  }

  searchRecursive(children, listIgnore) {
    for(let child of children) {
      listIgnore.push(child);

      if(child.children != null && child.children.length > 0) {
        this.searchRecursive(child.children, listIgnore);
      }
    }
  }

  nodeSelect(event) {
    this.listGestor = new Array();
    for(let node of this.detailSelecionados) {
      if(node.data != null && node.data[0] === 'gestor_id') {
        let editavel = node.icon != null;
        this.listGestor.push([node.data[1], editavel]);
        this.hasGestorSelected = true;
      }
    }
  }

  nodeUnselect(event) {
    if(event.node.data != null && event.node.data[0] === "gestor_id") {
      let idx = this.listGestor.findIndex(info => info[0] === event.node.data[1]);
      this.listGestor.splice(idx, 1);
      this.hasGestorSelected = this.listGestor.length > 0;
    }
  }

  onSelectVersao(row) {
    this.versaoSelecionada = row;

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'versaoid': row.versao_id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/get-valores-orcamento-gestao', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();
            return;
          }

          this.listVisao = new Array();
          this.listNiveis = new Array();
          this.detailSelecionados = new Array();
          this.listGestor = new Array();
          this.hasGestorSelected = false;
          this.listNiveis = res["liniveis"];
          this.detailVersao = res["mensagem"]["data"];

          let listVisao = {};
          for (let nivel of res["liniveis"]) {
            listVisao[nivel['key']] = 0;
          }

          this.listVisao.push(listVisao);
          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        });

        this.collapseAll();
  }

  removeTreeNode() {
    if (!confirm('Deseja realmente deletar os itens selecionados?')) return;
    if(this.detailSelecionados.length < 1) return this.exibirMensagem({"error": true, "mensagem": "Selecione ao menos um registro."});

    let highestNivel = this.listNiveis.length - 1;
    let parentNodes = [];
    let listPath = [];

    for(let node of this.detailSelecionados) {
      let nivel = this.listNiveis.findIndex(n => n.key_id === node.data[0]);

      if(nivel < highestNivel) {
        parentNodes = new Array();
        highestNivel = nivel;
        parentNodes.push(node);
      } else if(nivel === highestNivel) {
        parentNodes.push(node);
      }
    }

    //nós que já serão deletados
    let nodesToIgnore = [];
    for(let parent of parentNodes) {
      this.searchRecursive(parent.children, nodesToIgnore);
      listPath.push(parent.data[3]);
    }

    for(let node of this.detailSelecionados) {
      let nivel = this.listNiveis.findIndex(n => n.key_id === node.data[0]);
      if(nivel === highestNivel) continue;

      if(nodesToIgnore.indexOf(node) < 0) {
        listPath.push(node.data[3]);
      }
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
      })
    };

    const infos = {
      versaoid: this.versaoSelecionada.versao_id,
      valuesToRemove: listPath
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'orcamento/delete-valores-orcamento-gestao', infos, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);

          if(!res["error"]) {
            this.getVersoesOrcamento();
            this.versaoSelecionada = null;
          }

          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        }
      )
  }

  lockmanager() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
      })
    };

    const infos = {
      versaoid: this.versaoSelecionada.versao_id,
      userid: this.sessionService.loggedUser.id,
      listGestor: this.listGestor
    };

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'orcamento/status-gestor-versao', infos, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);

          if(!res["error"]) {
            this.listGestor = new Array();
            this.onSelectVersao(this.versaoSelecionada);
          }

          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        }
      )
  }

  editarRegistro() {
    let idx = this.listVersaoOrc.findIndex(versao => versao.versao_id === this.versaoEdit.versao_id);
    let status = this.opcoesStatusOrcamento.find(opcao => opcao.value === this.versaoEdit.status_id);

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
      })
    };

    const infos = {
      versaoid: this.versaoEdit.versao_id,
      userid: this.sessionService.loggedUser.id,
      statusid: this.versaoEdit.status_id
    };

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'orcamento/versao-status', infos, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);

          if(!res["error"]) {
            this.listVersaoOrc[idx].status_id = this.versaoEdit.status_id;
            this.listVersaoOrc[idx].status = status.label;
            this.modalEditStatus = false;
          }

          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        }
      )
  }

  openModalEditStatus(versao) {
    this.modalEditStatus = true;
    this.versaoEdit = {};

    //clone without reference
    for(let entrie of Object.entries(versao)) {
      this.versaoEdit[entrie[0]] = entrie[1];
    }
  }

  formatarDataExtenso() {
    const dt = new Date();
    const month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
    const week = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
    const semana = week[dt.getDay()];
    const mes = month[dt.getMonth()];

    return ("Hoje é " + semana + ", " + dt.getDate() + " de " + mes + " de " + dt.getFullYear() + ".");
  }

  /*Show Hide*/
  isCardVisible: boolean = true;
  ShowHideCard() {
    this.isCardVisible = !this.isCardVisible;
  }

  currentState = 'initial';
  changeState() {
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }

  isTreeVisible: boolean = true;
  ShowHideCardTree() {
    this.isTreeVisible = !this.isTreeVisible;
  }

  currentStateTree = 'initial';
  changeStateTree() {
    this.currentStateTree = this.currentStateTree === 'initial' ? 'final' : 'initial';
  }

  expandAll(){
    this.detailVersao.forEach(node => {
      this.expandRecursive(node, true);
      this.btnOptions = false;
    });
  }

  collapseAll(){
    this.detailVersao.forEach( node => {
      this.expandRecursive(node, false);
      this.btnOptions = true;
    });
  }
}
