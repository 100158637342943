export class StatusOrcamento {
  id: number;
  descricao: string;

  constructor(statusOrcamento?: StatusOrcamento | number) {
    if (statusOrcamento !== undefined && statusOrcamento !== null) {
      if (typeof statusOrcamento === 'number') {
        this.id         = statusOrcamento;
      } else {
        this.id         = statusOrcamento.id;
        this.descricao  = statusOrcamento.descricao;
      }
    }
  }


}
