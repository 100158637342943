import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import exportExcel from 'app/util/processing/exportExcel';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { AppComponent } from '../../app.component';
import { ConfigService } from '../../shared/services/config.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { HttpService } from '../../shared/services/http.service';
import { SessionService } from '../../shared/services/session.service';
import { TitleService } from '../../shared/services/title.service';
import { ToolbarService } from '../../shared/services/toolbar.service';

@Component({
  selector: 'app-report',
  templateUrl: './report-icms-ipi.component.html',
  styleUrls: ['./report-icms-ipi.component.scss'],
})
export class LFIcmsIpiComponent implements OnInit, OnDestroy {
  // TODO: Review INPUTS
  @Input() keyField: 'id';
  @Input() dtIni: 'dtIni';
  @Input() dtFin: 'dtFin';
  @Input() entradaSaida: 'entradaSaida';
  @Input() detalhePorItem: 'detalhePorItem';
  title: string;
  subtitle: string;
  private empresaSubscription: Subscription;
  private initSubscription: Subscription;

  private empresaId: number;
  private params: string;
  icmsIpi: Array<any> = new Array();
  analitico: number;
  detalhesPorItem: boolean;
  exportOrderList: Array<string> = new Array()

  //export excel
  colsicmsIpisDetalhe: any[];
  colsicmsIpis: any[];

  constructor(private titleService: TitleService,
              private httpService: HttpService,
              private toolbarService: ToolbarService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private configService: ConfigService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private appComponent: AppComponent,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    if (this.configService.production) {
      this.appComponent._displaySideNav = false;
    } else {
      setTimeout(() => { this.appComponent._displaySideNav = false; });
    }

    this.activatedRoute.params.subscribe(params => {
        this.empresaId = parseInt(params['id'], 10);
        this.dtIni = params['dtIni'];
        this.dtFin = params['dtFin'];
        this.entradaSaida = params['entradaSaida'];
        this.analitico = parseInt(params['detalhePorItem'], 10);
        if (!this.initSubscription) {
          this.initSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
                                                                          () => { this.carregar(); });
        } else {
          this.carregar();
        }
    });
    this.detalhesPorItem = ((this.analitico === 0) ? true : false);

    this.titleService.title = 'Livro Fiscal - Icms Ipi de ' + this.dtIni.substring(8, 10) + '/' +
                                this.dtIni.substring(5, 7) + '/' + this.dtIni.substring(0, 4) +
                                ' até ' + this.dtFin.substring(8, 10) + '/' + this.dtFin.substring(5, 7) + '/' + this.dtFin.substring(0, 4);
    this.title = 'LIVROS FISCAIS - ICMS / IPI ';
    this.subtitle = 'Livros fiscais - ICMS / IPI de ' + this.dtIni.substring(8, 10) + '/' +
                      this.dtIni.substring(5, 7) + '/' + this.dtIni.substring(0, 4) +
                      ' até ' + this.dtFin.substring(8, 10) + '/' + this.dtFin.substring(5, 7) + '/' + this.dtFin.substring(0, 4);
    if (this.entradaSaida.toString() === '0') {
      this.subtitle += '. Listando Entradas';
    }
    if (this.entradaSaida.toString() === '1') {
      this.subtitle += '. Listando Saidas';
    }
    this.subtitle += (this.detalhesPorItem) ? '. (Analítico)' : '. (Sintético)';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.colsicmsIpisDetalhe = [
      { field: 'dtDoc', header: 'DT_DOC' },
      { field: 'dtES', header: 'DT_E_S' },
      { field: 'codSit', header: 'STATUS' },
      { field: 'codMod', header: 'COD_MOD' },
      { field: 'numDoc', header: 'NUM_DOC' },
      { field: 'cnpjCpf', header: 'CNPJ_CPF' },
      { field: 'ie', header: 'IE' },
      { field: 'razao', header: 'NOME' },
      { field: 'regTrib', header: 'REGIME TRIB' },
      { field: 'codItem', header: 'COD_ITEM' },
      { field: 'descItem', header: 'DESCR_ITEM' },
      { field: 'codNcm', header: 'COD_NCM' },
      { field: 'tpItem', header: 'TIPO_ITEM' },
      { field: 'uf', header: 'UF' },
      { field: 'cfop', header: 'CFOP' },
      { field: 'vlOper', header: 'VL_OPR' },
      { field: 'cstIcms', header: 'CST ICMS' },
      { field: 'vlBcIcms', header: 'VL_BC_ICMS' },
      { field: 'aliqIcms', header: 'ALIQ_ICMS' },
      { field: 'vlIcms', header: 'VL_ICMS' },
      { field: 'vlBcIcmsSt', header: 'VL_BC_ICMS_ST' },
      { field: 'vlIcmsSt', header: 'VL_ICMS_ST' },
      { field: 'cstIpi', header: 'CST IPI' },
      { field: 'aliqIpi', header: 'ALIQ_IPI' },
      { field: 'vlIpi', header: 'VL_IPI' },
      { field: 'chvNfe', header: 'CHV_NFE' }
    ];

    this.colsicmsIpis = [
      { field: 'dtDoc', header: 'DT_DOC' },
      { field: 'dtES', header: 'DT_E_S' },
      { field: 'codSit', header: 'STATUS' },
      { field: 'codMod', header: 'COD_MOD' },
      { field: 'numDoc', header: 'NUM_DOC' },
      { field: 'cnpjCpf', header: 'CNPJ_CPF' },
      { field: 'ie', header: 'IE' },
      { field: 'razao', header: 'NOME' },
      { field: 'regTrib', header: 'REGIME TRIB' },
      { field: 'uf', header: 'UF' },
      { field: 'cfop', header: 'CFOP' },
      { field: 'vlOper', header: 'VL_OPR' },
      { field: 'cstIcms', header: 'CST ICMS' },
      { field: 'vlBcIcms', header: 'VL_BC_ICMS' },
      { field: 'aliqIcms', header: 'ALIQ_ICMS' },
      { field: 'vlIcms', header: 'VL_ICMS' },
      { field: 'vlBcIcmsSt', header: 'VL_BC_ICMS_ST' },
      { field: 'vlIcmsSt', header: 'VL_ICMS_ST' },
      { field: 'cstIpi', header: 'CST IPI' },
      { field: 'aliqIpi', header: 'ALIQ_IPI' },
      { field: 'vlIpi', header: 'VL_IPI' },
      { field: 'chvNfe', header: 'CHV_NFE' }
    ];
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.initSubscription);
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }
  // http://localhost:4200/fiscal/report-icms-ipi/160/2018-07-01/2018-07-01/0/false
  private carregar() {
    this.icmsIpi = new Array<any>();
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.sessionService.destroySubscribe(this.initSubscription);
      this.httpService.wait();
      this.httpService.get('/custom/fiscal/livros-icms-ipi', [this.empresaId, this.dtIni, this.dtFin, this.entradaSaida,
                          this.detalhesPorItem])
      .subscribe(dados => {
        this.icmsIpi = dados;
      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());

    }
  }

  public exportarExcel() {
    let options = [this.dblinkedSessionService.empresa.id, this.dtIni, this.dtFin, this.entradaSaida, this.detalhesPorItem]

    this.httpService.wait()
    this.httpService.get('/custom/fiscal/livros-icms-ipi',options).subscribe((res) => {
        if (res["error"]) {
          this.exibirMensagem(res);
        } else if(res.length > 0) {
          const items = res

          this.exportOrderList = new Array()

          Object.keys(items[0]).forEach((item : string) => {
            this.exportOrderList.push((item))
          });

          if(!this.detalhesPorItem) this.exportOrderList.slice(9,12)

          exportExcel(items, "icmsIpi.xlsx" , this.exportOrderList)

        } else {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Alerta', detail: 'Não foi resgatado nenhum dado' })
        }
        this.httpService.done();
      }
    )
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem })
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem })
    }
  }

  onFilter(event: any) {

  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

}
