import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';


import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { ParticipanteEmpresa } from '../../cadastro/model/participante-empresa.model';
import { OperacaoRegraContabil } from '../../cadastro/model/operacao-regra-contabil.model';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { Toolbar } from 'app/shared/model/toolbar.model';
declare var $: any;


@Component({
  selector: 'app-remessas',
  templateUrl: './remessas.component.html',
  styleUrls: ['./remessas.component.scss'
  ],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class RemessasComponent implements OnInit, OnDestroy {

  @ViewChild('file') fileInput: ElementRef;
  arquivos: FileList;


  // @ViewChildren(ToolbarService) panels;
  private sessionSubscription: Subscription;
  title: string;
  subtitle: string;
  movimentacao: Array<any> = new Array();
  statusOptions: SelectItem[];
  modalEdit: boolean;
  modalDetalhe: boolean;
  saldoAtual: number;
  saldoInicial: number;
  valorFiltro: number;
  valorTransferencia: number;
  todasSelecionadas: boolean;
  modalAlternarConta: boolean;
  modalTransferenciaBancaria: boolean;
  modalOperacao: boolean;
  modalPeriodo: boolean;
  opcoesContas: SelectItem[];
  contaSelecionada: EmpresaConta;
  contaSelecionadaId: any;
  contaOrigem: EmpresaConta;
  contaDestino: EmpresaConta;
  dtTransferencia: Date;
  dtOperacao: Date;
  pt_BR: any;
  empresaConta: EmpresaConta;
  cliente: ParticipanteEmpresa;
  opcoesOperacao: SelectItem[];
  operacaoRegra = new OperacaoRegraContabil;
  clientes: SelectItem[];
  opcoesDebitoCredito: SelectItem[];
  meses: any;
  ocorrenciaMes: any;
  historicoOperacao: any;
  valorOperacao: number;
  recorrencia: any;
  antecipaDiaNaoUtil: any;
  dtFimOccorencia: Date;
  modalOperacaoPagto: boolean;
  private valor: number;
  rowSelectedRemessas: Array<any> = new Array();
  rowSelectedRetornos: Array<any> = new Array();

  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;

  msgs: Message[];
  msgsModal: Message[];

  informacaoSelecionada: any;
  participanteTipo: any;
  relatorioAnalitico: boolean;
  baixadosRecebidos: boolean;

  /*variaveis card */
  tabs: number;
  statusRemessas: boolean = true;
  statusRetornos: boolean = false;

  remessasLista = [];
  idsExcluir = new Array();
  modalRelacaoPagamentos: boolean;
  modalRelacaoRecebimentos: boolean;
  pagamentosLista: any[];
  idExcluir: any;
  checkedAll: boolean;
  retornosLista: any[];
  tab: string;
  retorno: boolean;
  importDisabled: boolean;
  colsRemessa: any[];
  colsRetorno: any[];

  toolbarMainIni = [
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirRemessa()},
    { icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
      this.ShowHIdeRemRetor()
      this.changeStateRemRetor()
    } },
  ]

  public toolbarMain = new Toolbar()

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    // this.toolbarService.clear();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.retorno = false;
    this.tab = "remessas";
    this.title = 'REMESSAS/RETORNOS';
    this.subtitle = 'RELAÇÃO DE REMESSAS E RETORNOS';
    this.modalRelacaoPagamentos = false;
    this.modalRelacaoRecebimentos = false;
    this.dtFimOccorencia = new Date();
    this.defaultDate = new Date();
    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;

    this.statusOptions = [{ label: "Gerado", value: "Gerado" },
                          { label: "Baixado", value: "Baixado" }];



    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.colsRemessa = [
      { field: 'data_criacao', header: 'Data Criação' },
      { field: 'data_criacao', header: 'Horário' },
      { field: 'id', header: 'Identificador' },
      { field: 'qtd_pagamentos', header: 'Qtd. Pagamentos' },
      { field: 'total_pago', header: 'Total Pago' },
      { field: 'status', header: 'Status' }
  ];

  this.colsRetorno = [
    { field: 'data_criacao', header: 'Data Criação' },
    { field: 'data_criacao', header: 'Horário' },
    { field: 'id', header: 'Identificador' },
    { field: 'qtd_pagamentos', header: 'Qtd. Pagamentos' },
    { field: 'total_pago', header: 'Total Pago' },
    { field: 'status', header: 'Status' }
  ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
            this.toolbarEnable();
            let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
        // formatando meses com dois digitos
        let mes = this.defaultDate.getMonth() > 8 ?
                    (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
        this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
        this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

          if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
            mes = this.dblinkedSessionService.periodo.month.value > 8 ?
              this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
            const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
            this.defaultDate = new Date(dt);
            // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
            lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
            this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
            this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();
          }
          this.atualizarPagina();
        }
    });

    this.meses = [];
    for (let mes = 1; mes <= 12; mes++) {
      this.meses.push({ label: mes, value: mes });
    }

    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true;
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
    this.toolbarService.hidden = false;
  }


  onFilter(event: any) {
    this.valorFiltro = 0.00;
    event.filteredValue.forEach(element => {
      this.valor = this.utilService.parseMoney((element.valor));
      this.valorFiltro = this.valorFiltro - this.valor;

    });
    this.saldoAtual = this.saldoInicial + this.valorFiltro;
  }

  atualizarPagina(){
    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() > 8 ?
                    (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() > 8 ?
                    (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
                            this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 8) ?
                            this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }
    this.getRemessas();
    this.getRetornos();
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  toolbarEnable() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('add');
    this.toolbarService.ocultar('edit');


      // this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Opções');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
  }


  getRetornos(){
    if (this.dataIniSearch !== null && this.dataFinSearch !== null) {
      this.httpService.wait();
      this.httpService.get(this.configService.defaultUrlApiUpload + 'custom/financeiro/pagamento/v2/get-retornos',
      [this.dblinkedSessionService.empresa.id, this.dataIniSearch, this.dataFinSearch])
        .subscribe(
          ret => {
            this.retornosLista = [];
            if (ret.mensagem == "erro"){
              this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: ret["erro"] });
            } else{
              try {
                ret.forEach(element => {
                  this.retornosLista.push(element);
                });
              } catch (error) {
                this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: "Erro ao carregar remessas" });
              }
            }
          },
          error => this.httpService.handleError(error, () => this.atualizarPagina()),
          () => this.httpService.done());
    }
  }

  getRemessas(){
    if (this.dataIniSearch !== null && this.dataFinSearch !== null) {
      this.httpService.wait();
      this.httpService.get(this.configService.defaultUrlApiUpload + 'custom/financeiro/pagamento/v2/get-remessas',
      [this.dblinkedSessionService.empresa.id, this.dataIniSearch, this.dataFinSearch])
        .subscribe(
          ret => {
            this.remessasLista = [];
            if (ret.mensagem == "erro"){
              this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: ret["erro"] });
            } else{
              try {
                ret.forEach(element => {
                  this.remessasLista.push(element);
                });
              } catch (error) {
                this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: "Erro ao carregar remessas" });
              }
            }
          },
          error => this.httpService.handleError(error, () => this.atualizarPagina()),
          () => this.httpService.done());
    }

  }

  onRowSelect0(event: any) {
    if (event.remessa_tipo == 'cp') {
      this.modalRelacaoPagamentos = true;
    }
    if (event.remessa_tipo == 'cr') {
      this.modalRelacaoRecebimentos = true;
    }

    this.retorno = false;
    let remessa_id = event["id"];
    // agora pegamos a relação de
    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'custom/financeiro/pagamento/v2/get-relacao-pagamento/'+ remessa_id)
      .subscribe(
        ret => {
          this.pagamentosLista = [];
          ret.forEach(element => {
            this.pagamentosLista.push(element);
          });
        },
        error => this.httpService.handleError(error, () => this.atualizarPagina()),
        () => this.httpService.done());
  }

  onRowSelect1(event: any) {
    this.modalRelacaoPagamentos = true;
    let remessa_id = event["id"];
    // agora pegamos a relação de
    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'custom/financeiro/pagamento/v2/get-relacao-retorno-pagamento/'+ remessa_id)
      .subscribe(
        ret => {
          this.retorno = true;
          this.pagamentosLista = [];
          ret.forEach(element => {
            this.pagamentosLista.push(element);
          });
        },
        error => this.httpService.handleError(error, () => this.atualizarPagina()),
        () => this.httpService.done());
  }

  baixar(remessa_id){
    window.open(this.configService.defaultUrlApiUpload + 'custom/financeiro/pagamento/v2/download-bd/' + remessa_id, '_blank');
    this.atualizarPagina();
  }

  select(id: any, event: any) {
    if (event.selected) {
        this.idsExcluir.splice(id, 1);
        event.selected = true;
        this.toolbarRefreshExcluir();
    } else {
        this.idsExcluir.push(id);
        event.selected = false;
        this.idExcluir = id;
        this.toolbarRefreshExcluir();
    }
  }

  onSelectAll(event: any) {
    this.rowSelectedRemessas = []
    this.rowSelectedRetornos = []

    if (event.checked) {
      if (this.tab == "remessas"){
        this.remessasLista.forEach(item => {
          this.rowSelectedRemessas.push(item.id);
        })
      }
      if (this.tab == "retornos"){
        this.retornosLista.forEach(item => {
          this.rowSelectedRetornos.push(item.id);
        })
      }
    }
    this.refreshToolbar();
  }

  onRowSelect(event: any) {
    if (this.tab == "remessas"){
      this.rowSelectedRemessas.push(event.data.id)
    }
    if (this.tab == "retornos"){
      this.rowSelectedRetornos.push(event.data.id)
    }
    this.refreshToolbar()
  }

  onRowUnselect(event: any) {
    if (this.tab == "remessas"){
      this.rowSelectedRemessas.splice(this.rowSelectedRemessas.indexOf(event.data.id), 1)
    }
    if (this.tab == "retornos"){
      this.rowSelectedRetornos.splice(this.rowSelectedRetornos.indexOf(event.data.id), 1)
    }
    this.refreshToolbar()
  }

  refreshToolbar() {
    if (this.tab == "remessas"){
      this.toolbarMain.setVisible('btn1', this.rowSelectedRemessas.length > 0);
    }
    if (this.tab == "retornos"){
      this.toolbarMain.setVisible('btn1', this.rowSelectedRetornos.length > 0);
    }
  }

  toolbarRefreshExcluir() {
    if (this.idsExcluir.length > 0) {
      this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.excluirRemessa());
    } else {
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirRemessa());
    }
  }

  selectTodos(){
    this.checkedAll = !this.checkedAll;
    this.idsExcluir = new Array();
    this.remessasLista.forEach(e => {
      // let index = this.remessasLista.findIndex(c => c === e.id);

      if (this.checkedAll === false) {
        e.selected = false;
        this.idsExcluir.slice(e.id, 1);
      } else {
        e.selected = true;
        this.idsExcluir.push(e.id);
      }
    })
    this.toolbarRefreshExcluir();

  }

  excluirRemessa(){
    let url = "";
    let info = []
    if (confirm("Deseja realmente excluir esse registro?")){
      this.httpService.wait();
      if (this.tab == "remessas"){
        url = this.configService.defaultUrlApiUpload + 'custom/financeiro/pagamento/v2/delete-remessas';
        info = this.rowSelectedRemessas
      }
      if (this.tab == "retornos"){
        url = this.configService.defaultUrlApiUpload + 'custom/financeiro/pagamento/v2/delete-retornos';
        info = this.rowSelectedRetornos
      }
      this.httpService.post(url, info)
        .subscribe(result => {

          if (result["mensagem"] === "erro") {
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result["erro"] });
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result["sucesso"] });
          }
        },
          error => this.httpService.handleError(error, () => this.atualizarPagina()),
          () => {
            this.httpService.done();
            this.atualizarPagina();
          });
    }

  }

  fileChange() {
    this.arquivos = this.fileInput.nativeElement.files;
    if (this.arquivos.length > 0){
      this.importDisabled = false;
    } else {
      this.importDisabled = true;
    }
  }

  importar() {
    const data: FormData = new FormData();
    for (var i = 0; i < this.arquivos.length; i++) {
      data.append('files[]', this.arquivos[i]);
    }
    data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
    data.append('usuarioId', this.sessionService.loggedUser.id.toString());



    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'custom/financeiro/pagamento/v2/post-retornos', data)
    .subscribe(result => {

      if (result["mensagem"] === "erro") {
        this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result["erro"] });
      } else {
        if(result["mensagem"] === "alerta") this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: result["alerta"] });

        if (result.hasOwnProperty('baixas')){
          try {
            result.baixas.forEach(baixa => {
              baixa.usuario = this.sessionService.loggedUser.id
              this.httpService.post('/custom/financeiro/baixar-titulo', baixa).subscribe(response => {

                const finalResponse = response[0]
                let severity = finalResponse.error ? 'error' : 'success'
                this.messageService.add({
                  severity: severity,
                  key: 'messageLinnks',
                  summary: '',
                  detail: finalResponse.message
                });

              })
            })
          } catch(error){
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: 'Houve um erro ao baixar as movimentações. Contate o suporte' });
          }
        }
        this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result["sucesso"] });
      }
    },
    error => this.httpService.handleError(error, () => this.atualizarPagina()),
    () => {
      this.httpService.done();
      this.atualizarPagina();
    });
  }

  tabChange(event: any) {
    if (event.index == 0){
      this.tab = "remessas";
    }
    if (event.index == 1){
      this.tab = "retornos";
    }
  }

  /*Show Hide Tela*/
  showTelaRemRetor(tipo) {
    this.statusRemessas = false;
    this.statusRetornos = false;
    if (tipo === 'TELA_1') {
      this.statusRemessas = true;
      this.tabs = 0;
      this.tab = "remessas"
    } else if (tipo === 'TELA_2') {
      this.statusRetornos = true;
      this.tabs = 1;
      this.tab = "retornos"
    }
  }

  /*Arrow Up and Down Card*/
  isCardRemRetor: boolean = true;
  ShowHIdeRemRetor() {
    this.isCardRemRetor = !this.isCardRemRetor;
    this.toolbarMain.setIcon('btn2', this.isCardRemRetor ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn2', this.isCardRemRetor ? 'Ver menos' : 'Ver mais')
  }

  currentStateRemRetor = 'initial';
  changeStateRemRetor() {
    this.currentStateRemRetor = this.currentStateRemRetor === 'initial' ? 'final' : 'initial';
  }

}
