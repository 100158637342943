import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Component, OnDestroy, OnInit } from "@angular/core"
import { allOptionsLogAction, allOptionsLogModule, allOptionsLogScreen } from "app/shared/common/log-management/log.types"
import { SkeletonModel } from "app/shared/components/skeleton/models/skeletonModel"
import { Toolbar } from "app/shared/model/toolbar.model"
import { ConfigService } from "app/shared/services/config.service"
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service"
import { SessionService } from "app/shared/services/session.service"
import { TitleService } from "app/shared/services/title.service"
import { ToolbarService } from "app/shared/services/toolbar.service"
import deepCopy from "app/util/processing/deepcopy"
import { MessageService, SelectItem } from "primeng/api"
import { Subscription } from "rxjs/Subscription"


@Component({
  selector: 'log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss'],
})

export class LogComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription

  httpOptions: Object = new Object()
  toolbar: Toolbar = new Toolbar()

  logRows: Array<any> = new Array()
  optionsModule: Array<SelectItem> = allOptionsLogModule
  optionsAction: Array<SelectItem> = allOptionsLogAction
  optionsScreen: Array<SelectItem> = new Array()

  selectedLog: any | null = null

  selectedModule: string | null = null
  selectedScreen: number | null = null
  selectedAction: number | null = null

  showSearchLogModal: boolean = false
  isLoadingNewLogRows: boolean = false
  showDetailsLogModal: boolean = false

  skeletonConfig: SkeletonModel = new SkeletonModel({
    columns: [
      { name: 'DATA', textAlign: 'center', size: '15%' },
      { name: 'MENSAGEM', textAlign: 'left', size: '20%' },
      { name: 'USUÁRIO', textAlign: 'center', size: '15%'},
      { name: 'ID', textAlign: 'center', size: '15%' },
      { name: 'CÓDIGO', textAlign: 'center', size: '15%' },
      { name: 'INFORMAÇÃO EXTRA', textAlign: 'left', size: '20%'}
    ],
    hasFilter: true,
    hasSort: true
  })

  constructor(
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private messageService: MessageService
  ) {
    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }

    this.toolbar.setAll([
      { key: 'btn-view-details', icon: 'search', text: 'Detalhes', tooltip: 'Visualizar detalhes', visible: false, disabled: false, color: 'default', onClick: () => this.changeShowDetailsLogModal(true) },
      { key: 'btn-search-log', icon: 'search', text: 'Buscar', tooltip: 'Buscar LOG', visible: true, disabled: false, color: 'green', onClick: () => this.changeShowSearchLogModal(true) },
    ])
  }

  ngOnInit() {
    this.titleService.title = 'LOG'
    this.toolbarService.hidden = true
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, async () => {})
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks
  }

  get rows() {
    return this.configService.applicationConfig.rows
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage
  }

  public async getLog(): Promise<void> {
    this.getLogValidation()

    const payload = {
      companyId: this.dblinkedSessionService.empresa.id,
      module: this.selectedModule,
      screen: this.selectedScreen,
      action: this.selectedAction
    }

    this.skeletonConfig.startLoad()
    this.isLoadingNewLogRows = true

    this.changeShowSearchLogModal(false)

    try {
      await this.httpClient.post(`${this.configService.defaultUrlApiUpload}log/buscar-log-modulo`, payload, this.httpOptions).toPromise()
      .then(res => this.logRows = res['data'])
      .catch(error => this.showResponse('error', 'Erro ao recuperar LOG, contate o suporte!'))
    } catch {
      this.showResponse('error', 'Houve uma falha interna, contate o suporte!')
    } finally {
      this.skeletonConfig.endLoad()
      this.isLoadingNewLogRows = false
    }
  }

  public changeToolbar(): void {
    this.toolbar.setVisible('btn-view-details', (this.selectedLog !== null))
  }

  private changeShowSearchLogModal(showSearchLogModal: boolean): void {
    this.showSearchLogModal = showSearchLogModal
  }

  public changeOptionsScreen(): void {
    const allOptionsScreen: Array<any> = allOptionsLogScreen
    this.optionsScreen = deepCopy(allOptionsScreen.filter((option: any) => option.module === this.selectedModule))
  }

  private changeShowDetailsLogModal(ShowDetailsLogModal: boolean): void {
    this.selectedLog.informacao_extra = JSON.parse(this.selectedLog.informacao_extra)
    this.selectedLog.informacao_extra = JSON.stringify(this.selectedLog.informacao_extra, undefined, 4)

    this.showDetailsLogModal = ShowDetailsLogModal
  }

  private getLogValidation(): boolean {
    let message: string = null

    if (!this.selectedModule) {
      message = 'O campo módulo é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (!this.selectedScreen) {
      message = 'O campo tela é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (!this.selectedAction) {
      message = 'O campo ação é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    return true
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Atenção: ', detail: message })
      break
    }
  }
}

