import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MessageService, SelectItem } from '../../../../node_modules/primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-config-tipo',
  templateUrl: './tipo.component.html',
  styleUrls: ['./tipo.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ConfigTipoComponent implements OnInit, OnDestroy {
  ////////////////////////////////////////
  // REFATOR SIMON LALLEMENT 21/12/2021 //
  ////////////////////////////////////////

  private sessionSubscription: Subscription

  //Toolbar
  toolbarMainIni = [
    { icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: false, disabled: false, color: 'red', onClick: () => this.excluirTipo() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openEditTipo() },
    { icon: 'add', text: 'Nova', tooltip: 'Nova', visible: true, disabled: false, color: 'green', onClick: () => this.openAddTipo() },
  ]
  public toolbarMain = new Toolbar()

  //Array
  tiposRequisicao: Array<any> = new Array()
  tiposSelecionados: Array<number> = new Array()

  //String
  titlePrincipal: string = 'Configurações RMS'
  subtitle: string = 'Tipo de requisição'
  currentStateRequisicao: string = 'initial'
  descricaoTipo: string = null
  codigoTipo: string = null

  //Boolean
  isCardRequisicao: boolean = true
  checkedAll: boolean = false
  modalTipos: boolean = false
  add: boolean = false
  edit: boolean = false

  //Miscellaneous
  tipoSelecionado: any = {}

  //Number
  relacaoId: number = null

  //Dropdown
  listRelacaoTipo: SelectItem[] = []
  selectedRows: any = [];

  constructor(
    private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.titleService.title = 'Linnks - Tipo RMS'
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.tiposRequisicaoItens()
        this.buscarRelacoes()
      }
    })
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
  }

  openAddTipo() {
    this.modalTipos = true
    this.descricaoTipo = null
    this.codigoTipo = null
    this.relacaoId = null
    this.add = true
    this.edit = false
  }

  buscarRelacoes() {
    this.httpService.wait()
    this.httpService.get(`tipormspedido?$select=id,descricao&$orderby=descricao`)
      .subscribe(ref => {
        this.listRelacaoTipo = []
        ref.value.forEach(element => { this.listRelacaoTipo.push({ label: element.descricao, value: element.id }) })
        this.httpService.done()

      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao obter as relações de tipo, contate o suporte : " + error.message })
          this.httpService.done()
        })
  }

  openEditTipo() {
    this.modalTipos = true
    this.tipoSelecionado = this.tiposRequisicao.find(element => this.selectedRows[0].id === element.id)
    this.descricaoTipo = this.tipoSelecionado.nome
    this.codigoTipo = this.tipoSelecionado.codigo
    this.relacaoId = this.tipoSelecionado.tipo_relac_id
    this.edit = true
  }

  changeStateRequisicao() {
    this.currentStateRequisicao = this.currentStateRequisicao === 'initial' ? 'final' : 'initial'
  }

  showHideRequisicao() {
    this.isCardRequisicao = !this.isCardRequisicao
  }

  validacao(): boolean {
    if (!this.codigoTipo) {
      this.exibirMensagem({ error: true, mensagem: "O campo Código é obrigatório." })
      return true
    }

    if (this.codigoTipo.length !== 3) {
      this.exibirMensagem({ error: true, mensagem: "O campo Código deve conter 3 carateres." })
      return true
    }

    if (!this.descricaoTipo) {
      this.exibirMensagem({ error: true, mensagem: "O campo Descrição é obrigatório." })
      return true
    }

    if (!this.relacaoId) {
      this.exibirMensagem({ error: true, mensagem: "O campo Relação é obrigatório." })
      return true
    }

    let lista = this.tiposRequisicao.map(item => { return this.stringComparavel(item.nome) })

    if((this.edit ? this.stringComparavel(this.tipoSelecionado.nome) !== this.stringComparavel(this.descricaoTipo): true) && lista.includes(this.stringComparavel(this.descricaoTipo))) {
      this.exibirMensagem({ severity: 'warn', mensagem: "A descrição deve ser única." })
      return true
    }

    let lista2 = this.tiposRequisicao.map(item => { return this.stringComparavel(item.codigo) })

    if((this.edit ? this.stringComparavel(this.tipoSelecionado.codigo) !== this.stringComparavel(this.codigoTipo): true) && lista2.includes(this.stringComparavel(this.codigoTipo))) {
      this.exibirMensagem({ severity: 'warn', mensagem: "O código deve ser único." })
      return true
    }

    return false
  }

  stringComparavel(string: string): string {
    return string.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z._ ])/g, '').toLowerCase().trim()
  }

  adicionar() {
    if (this.validacao()) return

    let listaEnviar = {
      codigoTipo: this.codigoTipo,
      nomeTipo: this.descricaoTipo,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id,
      tipo_relac: this.relacaoId
    }

    this.httpService.wait();
    this.httpService.post('/custom/rms/adicionar-tipo', listaEnviar).subscribe(rst => {
      if(rst.error){this.exibirMensagem({ severity: "error", message: rst.detail })}
      else{
        this.exibirMensagem({ severity: "success", message: rst.detail })
        this.tiposRequisicaoItens()
        this.modalTipos = false
        this.add = false
      }
    },
    error => {
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar o tipo, contate o suporte : " + error.message })
      this.resetSelected()
    },
    () =>  this.httpService.done())
  }

  tiposRequisicaoItens() {
    this.httpService.wait();
    this.httpService.get('/custom/rms/listar-tipos', this.dblinkedSessionService.empresa.id)
      .subscribe(rst => {
        this.tiposRequisicao = new Array()
        rst.forEach(element => { this.tiposRequisicao.push(element) })
        this.httpService.done()
        this.resetSelected()
      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao obter os tipos de RMS, contate o suporte : " + error.message })
          this.httpService.done()
          this.resetSelected()
        })
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  atualizar() {
    if (!confirm("Se o código foi alterado, as requisições já existentes não serão alteradas, deseja continuar?")) return
    if (this.validacao()) return

    let listaEnviar = {
      id: this.tipoSelecionado.id,
      codigo: this.codigoTipo,
      nome: this.descricaoTipo,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id,
      tipo_relac_id: this.relacaoId
    }

    this.httpService.wait();
    this.httpService.post('/custom/rms/atualizar-tipo', listaEnviar).subscribe(rst => {
      if(rst.error) this.exibirMensagem({ severity: "error", message: rst.detail })
      else{
        this.exibirMensagem({ severity: "success", message: rst.detail })
        this.tiposRequisicaoItens()
        this.checkToolBar()
        this.modalTipos = false
        this.edit = false
      }

    }, error => {
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar o tipo, contate o suporte : " + error.message })
      this.resetSelected()
    },
    () =>  this.httpService.done())

  }

  excluirTipo() {
    if (confirm("Deseja remover os tipos selecionados?")) {

      let listaEnviar = {
        id: this.selectedRows.map(item => item.id)
      }
      this.httpService.wait();
      this.httpService.post('/custom/rms/deletar-tipo', listaEnviar)
        .subscribe(rst => {
          this.httpService.done()

          if(rst?.error) {
            return this.exibirMensagem(rst);
          }

          this.tiposRequisicaoItens()
          this.checkToolBar()
          this.resetSelected()

        }, error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao excluir os tipos, contate o suporte : " + error.message })
          this.httpService.done()
          this.resetSelected()
        })

    }
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  resetSelected() {
    this.selectedRows = [];
    this.edit = false;
    this.checkToolBar();
  }

  checkToolBar() {
    this.edit = false;
    this.toolbarMain.setVisible('btn1', this.selectedRows.length !== 0);
    this.toolbarMain.setVisible('btn2', this.selectedRows.length === 1);
  }

}
