import { Empresa } from 'app/cadastro/model/empresa.model';
import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { Uf } from 'app/util/model/uf.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Pais } from 'app/util/model/pais.model';
import { ParticipanteTipo } from 'app/cadastro/model/participante-tipo.model';
import { ParticipanteEmpresa } from 'app/cadastro/model/participante-empresa.model';
import { ItemParticipante } from 'app/cadastro/model/item-participante.model';
import { ParticipanteEndereco } from 'app/cadastro/model/participante-endereco.model';
import { RegimeTributario } from 'app/util/model/regime-tributario.model';
import { Participante } from '../../cadastro/model/participante.model';
import { Item } from '../../cadastro/model/item.model';
import { Nfe } from '../../financeiro/model/nfe.model';
import { NfeItemTaxIi } from '../../financeiro/model/nfe-item-tax-ii.model';
import { NfeItemTaxPis } from '../../financeiro/model/nfe-item-tax-pis.model';
import { NfeItemTaxIpi } from '../../financeiro/model/nfe-item-tax-ipi.model';
import { NfeItemTaxCofins } from '../../financeiro/model/nfe-item-tax-cofins.model';
import { NfeItemTaxIcms } from '../../financeiro/model/nfe-item-tax-icms.model';
import { NfeItemTaxIssqn } from '../../financeiro/model/nfe-item-tax-issqn.model';

export class NfeItem {
  id: number;
  nfe: Nfe;
  sequence: string;
  item: Item;
  quantity: number;
  comercialQuantity: number;
  total: boolean;
  unitaryValue: number;
  totalValue: number;
  taxesValue: number;
  cfop: string;
  taxIi: NfeItemTaxIi;
  taxPis: NfeItemTaxPis;
  taxIpi: NfeItemTaxIpi;
  taxCofins: NfeItemTaxCofins;
  taxIcms: NfeItemTaxIcms;
  taxIssqn: NfeItemTaxIssqn;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;

  constructor(nfeItem?: NfeItem | number) {
    if (nfeItem !== undefined && nfeItem !== null) {
      if (typeof nfeItem === 'number') {
        this.id = nfeItem;
      } else {
        this.id = nfeItem.id;
        this.nfe = nfeItem.nfe;
        this.sequence = nfeItem.sequence;
        this.item = nfeItem.item;
        this.quantity = nfeItem.quantity;
        this.comercialQuantity = nfeItem.comercialQuantity;
        this.total = nfeItem.total;
        this.unitaryValue = nfeItem.unitaryValue;
        this.totalValue = nfeItem.totalValue;
        this.taxesValue = nfeItem.taxesValue;
        this.cfop = nfeItem.cfop;
        this.taxIi = nfeItem.taxIi;
        this.taxPis = nfeItem.taxPis;
        this.taxIpi = nfeItem.taxIpi;
        this.taxCofins = nfeItem.taxCofins;
        this.taxIcms = nfeItem.taxIcms;
        this.taxIssqn = nfeItem.taxIssqn;
         this.createTimestamp = nfeItem.createTimestamp;
        this.changeUser = nfeItem.changeUser;
        this.changeTimestamp = nfeItem.changeTimestamp;
      }
    }
  }
}
