import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute, Router, ActivationEnd, NavigationEnd, NavigationStart } from '@angular/router';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { EventoFichaEstorno } from 'app/eventos/model/evento-ficha-estorno.model';
import { MotivoEstorno } from '../model/motivos-estorno.model';
import { MessageService, SelectItem } from 'primeng/api';




@Component({
  selector: 'app-motivo-estorno',
  // templateUrl: './motivo-estorno.component.html',
  //C:\Users\linnks-dev-01\Documents\projetos\central-web-new\src\app\eventos\motivo-estorno\motivo-estorno.component.html
  templateUrl: './motivo-estorno.component.html',
  styleUrls: ['./motivo-estorno.component.scss'],
  providers: [CrudService]
})
export class MotivoEstornoComponent implements OnInit, OnDestroy {


  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  pt_BR: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  file: File;
  // INFORMAÇÔES TABELA
  origemMotivoEstorno: Array<any> = new Array();
  eventoFichaEstorno: EventoFichaEstorno;
  opcoesMotivoEstorno: Array<any> = new Array();
  // FIM DAS INFORMAÇÔES TABELA

  // CHECKALL & TOOLBAR
  novoMotivoEstorno: boolean;
  motivosEstornosEventosView: Array<any> = new Array();
  motivosEstornosEventos: Array<any> = new Array();
  checkedAll: boolean = false;
  eventoFichaEstornoId: number;
  motivoEstorno: MotivoEstorno;
  idFichaEstorno: number;

  modalEditEstorno: boolean;
  importDisabled : boolean = false;

  opcoesComboStatus         : SelectItem[];
  opcoesComboMotivoEstorno  : SelectItem[];
  colsMotivoEstorno         : any[];

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private matDialog: MatDialog,
    private dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService

  ) {
    router.events.forEach((event) => {

      this.toolbarService.clear();
      if (router.url.toString().startsWith('/eventos/motivo-estorno')) {

        // variavel, utilizar replace()

        this.toolbarService.replace('atualizar', this.novoMotivoEstorno, 'class-first-button', 'refresh', 'Atualizar ', () => this.listar());
        this.toolbarService.replace('true', true, 'class-other-button', 'check', 'Aprovar');
        this.toolbarService.replace('true', true, 'class-first-button', 'print', 'Imprimir');
        this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.excluir());
        this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar');
        this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Criar nova requisição', () => this.novo());
      }
    });
  }


  ngOnInit(): void {


    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'PEDIDOS DE ESTORNO';
    this.subtitle = 'Pedidos de Estorno';
    this.titleService.title = 'PEDIDOS DE ESTORNO';
    this.origemMotivoEstorno = [];
    this.modalEditEstorno = false;

    //this.toolbarEnable();
    // Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.colsMotivoEstorno = [
      { field: 'nome', header: 'NOME' },
      { field: 'cpf', header: 'CPF' },
      { field: 'email', header: 'EMAIL' },
      { field: 'fone', header: 'FONE' },
      { field: 'motivo', header: 'MOTIVO' },
      { field: 'observacao', header: 'OBSERVAÇÃO' },
      { field: 'valor', header: 'VALOR' },
      { field: 'descricaoStatus', header: 'STATUS' }
    ];

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {


        this.listar();

      });


  }

  hideMessage() {


  }

  private toolbarEnable() {

    this.toolbarService.replace('atualizar', this.novoMotivoEstorno, 'class-first-button', 'refresh', 'Atualizar Motivos de Estornos', () => this.listar());
    this.toolbarService.replace('check', true, 'class-other-button', 'check', 'Aprovar');
    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir');
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.excluir());
    this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar');
    this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Criar nova requisição', () => this.novo());

  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  listar() {
    if ((this.dblinkedSessionService.hasEmpresaSelected) &&
      (this.dblinkedSessionService.hasEmpresaSelected) && (this.dblinkedSessionService.periodo.month)) {
      this.hideMessage();
      this.origemMotivoEstorno = [];
      this.getComboMotivosEstorno();
      this.carregarLisaMotivosEstornos();
      this.novoMotivoEstorno = false;
      this.motivosEstornosEventos = new Array();
      this.idFichaEstorno = 0;
    }
  } //Final do metodo listar


  carregarLisaMotivosEstornos() {
    this.opcoesMotivoEstorno = new Array();

    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-consulta-motivo-estorno-evento-ficha')
      .subscribe(retorno => {
        console.log(retorno);
        this.opcoesMotivoEstorno = new Array();
        retorno.forEach(element => {
          this.opcoesMotivoEstorno.push(element);
        });
      },
        error => this.httpService.handleError(error, () => this.carregarLisaMotivosEstornos()),
        () => this.httpService.done());

    this.desabilitarToolBar()
  }

  ngOnDestroy(): void {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  novo() { }

  excluir() { }


  selectCheckbox(id: any, event: any) {


        // manipulando listas
    if (event.selected) {
      event.selected = true;

      // impressão de pedido
      const index = this.motivosEstornosEventosView.findIndex(vw => vw === id);


      this.motivosEstornosEventosView.splice(index, 1);
      this.motivosEstornosEventos.splice(this.motivosEstornosEventos.findIndex(e => e.id.toString() === event.id.toString()), 1);
    } else {

      this.motivosEstornosEventosView.push(id);
      this.motivosEstornosEventos.push(event);

      event.selected = false;
    }

    if (this.motivosEstornosEventos.length === 1) {
      //console.log(this.motivosEstornosEventos);
      this.toolbarService.replace('atualizar', this.novoMotivoEstorno, 'class-first-button', 'refresh', 'Atualizar Motivos de Estornos', () => this.listar());
      this.toolbarService.replace('true', true, 'class-other-button', 'check', 'Aprovar');
      this.toolbarService.replace('true', true, 'class-first-button', 'print', 'Imprimir');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.excluir());
      this.toolbarService.replace('edit', false, 'class-edit-button', 'edit', 'Editar', () => this.editarEstorno(event.estorno_id));
      this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Criar nova requisição', () => this.novo());


    } else {
      // this.toolbarService.alterarStatus('save', true, 'Motivo Estorno', () => this.motivosEstornos());
      this.toolbarService.replace('atualizar', this.novoMotivoEstorno, 'class-first-button', 'refresh', 'Atualizar Motivos de Estornos', () => this.listar());
      this.toolbarService.replace('true', true, 'class-other-button', 'check', 'Aprovar');
      this.toolbarService.replace('true', true, 'class-first-button', 'print', 'Imprimir');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.excluir());
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar');
      this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Criar nova requisição', () => this.novo());

    }
  }

  // alertaMotivos() {
  //   alert(this.eventoFichaEstorno.id);
  //   console.log(this.eventoFichaEstorno);
  // }

  desabilitarToolBar() {
    this.toolbarService.replace('atualizar', this.novoMotivoEstorno, 'class-first-button', 'refresh', 'Atualizar Motivos de Estornos', () => this.listar());
    this.toolbarService.replace('true', true, 'class-other-button', 'check', 'Aprovar');
    this.toolbarService.replace('true', true, 'class-first-button', 'print', 'Imprimir');
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.excluir());
    this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar');
    this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Criar nova requisição', () => this.novo());

  }


  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;
    this.motivosEstornosEventosView = new Array();
    this.motivosEstornosEventos = new Array();


    this.motivosEstornosEventos.forEach(me => {
      if (this.checkedAll === false) {
        me.selected = false;
        this.motivosEstornosEventos.slice(me.id, 1);
        this.motivosEstornosEventosView.slice(me.id, 1);
      } else {
        me.selected = true;
        this.motivosEstornosEventos.push(me.id);
        this.motivosEstornosEventosView.push(me);
      }
    });
  }

  editarEstorno(value: any){
    console.log(value);

    try {
      this.file = null;
      document.getElementById('file-name').innerText = '';
    } catch (error) {
      console.log(error);
    }


    this.modalEditEstorno = true;


  }

  solicitarAprovacao(){
    if (confirm('Deseja realmente solicitar a aprovação deste estorno?')) {

      const listasEnviar = {
        usuario              : this.sessionService.loggedUser.id,
        estornoId            : this.motivosEstornosEventos[0].estorno_id
      };

      this.httpService.post('/custom/polozi/solicitar-aprovacao-estorno', listasEnviar)
      .subscribe(result => {



      if (result.error === true) {
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: result.message});
      } else {
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: result.message});
          this.listar();
      }

      this.modalEditEstorno = false;

      },
        error => this.httpService.handleError(error, () => this.solicitarAprovacao()),
        () => this.httpService.done());

    }



  }
  reverterEstorno(){

    if (confirm('Deseja reverter o estorno?')) {
      const listaEnviar = {
        usuarioId            : this.sessionService.loggedUser.id,
        estornoId            : this.motivosEstornosEventos[0].estorno_id
      };

      this.httpService.post('/custom/polozi/reverter-estorno', listaEnviar)
      .subscribe(result => {



      if (result.error === true) {
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: result.message});

      } else {
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: result.message});
          this.listar()
      }

      this.modalEditEstorno = false;

      },
        error => this.httpService.handleError(error, () => this.solicitarAprovacao()),
        () => this.httpService.done());



    }

  }

  importar() {


    if (this.file === null || this.file === undefined || this.file.name.toString() === '') {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: 'Favor selecionar um arquivo!'});
      return ;
    }

    if (confirm('Deseja realmente enviar o comprovante para o cliente?')) {
    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('type', 'comprovante-estorno');
    data.append('usuarioId', this.sessionService.loggedUser.id.toString());
    data.append('estornoId', this.motivosEstornosEventos[0].estorno_id);

    this.httpService.wait();
    this.httpService.post('/custom/upload', data)
                    .subscribe(result => this.uploaded(result),
                               error => this.httpService.handleError(error, () => this.importar()),
                               () => this.httpService.done());
    this.listar() ;
  }
}

fileChange() {
  this.file = this.fileInput.nativeElement.files[0];
}

private uploaded(res?: any) {



  if (res.error === true) {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: res.message});
  } else {
    this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: res.message});
  }

  this.listar();

}

baixarAnexo(){
  if (confirm('Deseja realmente baixar este comprovante?')) {
    window.open(this.httpService.url('/custom/file/' + this.motivosEstornosEventos[0].estorno_id + '/comprovante-estorno'), '_blank');
  }
}

getComboMotivosEstorno(){

  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-combo-motivo-estorno')
    .subscribe(retorno => {
      this.opcoesComboMotivoEstorno = new Array();
      this.opcoesComboMotivoEstorno.push({label: '', value: ''});
      retorno.forEach(element => {
        this.opcoesComboMotivoEstorno.push({label: element.descricao, value: element.descricao});
      });
    },
      error => this.httpService.handleError(error, () => this.getComboMotivosEstorno()),
      () => this.httpService.done());

}


}
