import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Subscription } from 'rxjs/Subscription'
import { ConfigService } from 'app/shared/services/config.service'
import { LoginService } from 'app/shared/services/login.service'
import { SessionService } from 'app/shared/services/session.service'
import { NavigationMenuService } from 'app/shared/services/navigation-menu.service'
import { AuthCrowd } from 'app/proof/model/auth-crowd.model'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { FormValidations } from './form-validations'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup

  viewPassword: boolean = false
  viewPasswordConfirm: boolean = false
  forgetPassword: boolean = false

  public passwordConfirm = ''

  private paramsSubscription: Subscription

  @ViewChild('fieldEmail') fieldEmail: ElementRef
  @ViewChild('fieldPassword') fieldPassword: ElementRef
  @ViewChild('fieldCrowd') fieldCrowd: ElementRef

  constructor(
    private formBuilder: FormBuilder,
    private configService: ConfigService,
    private loginService: LoginService,
    private sessionService: SessionService,
    private activatedRoute: ActivatedRoute,
    private toolbarService: ToolbarService,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
      passwordConfirm: [null, [Validators.required, FormValidations.equalsTo('password')]]
    })

    let url: string
    let error: any
    let login: boolean

    Promise.resolve(null).then(() => this.toolbarService.hidden = true)

    this.paramsSubscription = this.activatedRoute.params.subscribe(params => {
      url = params['url']
      error = params['error']
      login = params['login']
      this.loginService.token = params['token']

      if (!url) {
        url = '/'
      } else if (url.trim().substr(0, 1) !== '/') {
        url = '/' + url.trim()
      }

      if (error) {
        try {
          error = JSON.parse(error)
        } catch (exception) {
          console.log(exception)
        }
      }

      if (login) {
        this.loginService.doLogout(true, true)
        this.loginService.init(url)
      } else if (this.loginService.isLogoutPath(this.router.url)) {
        this.loginService.init(this.router.url)
      } else if (this.loginService.isLoginPath(this.router.url)) {
        if (this.loginService.authenticated) {
          this.loginService.authVerify()
        } else {
          this.loginService.init(url)
          this.sessionService.navigationMenu = new NavigationMenuService(this.configService)
          this.sessionService.navigationMenu.initMenu(url)
        }
      } else if (!this.loginService.authenticated) {
        this.loginService.init(this.router.url)
      }
    })
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
    this.paramsSubscription.unsubscribe()
  }

  doLogin(): void {
    this.loginService.doLogin(this.fieldEmail, this.fieldPassword, this.fieldCrowd)
    this.form.reset()
  }

  logout(): void {
    this.loginService.doLogout()
    this.form.reset()
  }

  reset(): void {
    this.loginService.resetPassword()
  }

  showPassword(): void {
    this.viewPassword = !this.viewPassword
  }

  showPasswordConfirm(): void {
    this.viewPasswordConfirm = !this.viewPasswordConfirm
  }

  inputValidation(input: string): boolean {
    return !this.form.get(input).valid && this.form.get(input).touched
  }

  applyError(input: string): Object {
    return {
      'hasError': this.inputValidation(input)
    }
  }

  resetFormValid(): boolean {
    if(this.form.get('email').status === 'INVALID') return true
    else return false
  }

  onForgetPassword(): void {
    this.forgetPassword = !this.forgetPassword
  }

  tryAgain(): void {
    this.loginService.resetErrorState()
  }

  get resetting(): boolean {
    return this.loginService.token && this.loginService.token.trim() !== ''
  }

  get resetSent(): boolean {
    return this.loginService.resetSent
  }

  get mailAuth(): boolean {
    return this.loginService.mailAuth
  }

  get crowdAuth(): boolean {
    return this.loginService.crowdAuth
  }

  get crowds(): Array<AuthCrowd> {
    return this.loginService.crowds
  }

  get resetDisabled(): boolean {
    return this.resetSent || this.form.get('email').invalid
  }

  get submitDisabled(): boolean {
    return (this.loginService.crowdAuth && this.crowdId <= 0) || this.form.get('email').invalid || this.form.get('password').invalid ||
           (this.resetting && this.password !== this.passwordConfirm)
  }

  get errorMessage(): string {
    return this.loginService.errorMessage
  }

  get resetErrorMessage(): string {
    return this.loginService.resetErrorMessage
  }

  get resetState() {
    return this.loginService.resetState
  }

  get windowTitle(): string {
    return this.loginService.windowTitle
  }

  get email(): string {
    return this.loginService.login
  }
  set email(login: string) {
    this.loginService.login = login
  }

  get password(): string {
    return this.loginService.password
  }
  set password(password: string) {
    this.loginService.password = password
  }

  get crowdId(): number {
    return this.loginService.crowdId
  }
  set crowdId(crowdId: number) {
    this.loginService.crowdId = crowdId
  }
}
