export class IndSitIniPer {
  id: number;
  cod: string;
  descricao: string;

  constructor(indsitiniper?: IndSitIniPer | number) {
    if (indsitiniper !== undefined && indsitiniper !== null) {
      if (typeof indsitiniper === 'number') {
        this.id = indsitiniper;
      } else {
        this.id = indsitiniper.id;
        this.cod = indsitiniper.cod;
        this.descricao = indsitiniper.descricao;
      }
    }
  }
}
