import { Empresa } from 'app/cadastro/model/empresa.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { AuthUser } from 'app/proof/model/auth-user.model';


export class EmpresaParametroBaixa {
  id: number;
  empresa: Empresa;
  nome: string;
  planoContas: PlanoContas;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;

  constructor(empresaParametroBaixa?: EmpresaParametroBaixa | number) {
    if (empresaParametroBaixa !== undefined && empresaParametroBaixa !== null) {
      if (typeof empresaParametroBaixa === 'number') {
        this.id = empresaParametroBaixa;
      } else {
        this.id = empresaParametroBaixa.id;
        this.empresa = empresaParametroBaixa.empresa;
        this.nome = empresaParametroBaixa.nome;
        this.planoContas = empresaParametroBaixa.planoContas;
      }
    }
  }
}
