export class UploadType {
  tag: string;
  caption: string;
  disabled = false;

  constructor(uploadType?: UploadType | string, caption?: string, disabled?: boolean) {
    if (typeof uploadType === 'string') {
      this.tag = uploadType;
      this.caption = caption || null;
      this.disabled = disabled || false;
    } else if (uploadType) {
      this.tag = uploadType.tag;
      this.caption = uploadType.caption;
      this.disabled = uploadType.disabled || false;
    }
  }
}
