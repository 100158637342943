import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, ActivationEnd, NavigationEnd, NavigationStart } from '@angular/router';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { isNullOrUndefined } from 'util';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MessageService, SelectItem } from 'primeng/api';
import { Toolbar } from 'app/shared/model/toolbar.model';
declare var $: any;

@Component({
  selector: 'app-conf-dashboard',
  templateUrl: './conf-dashboard.component.html',
  styleUrls: ['./conf-dashboard.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ConfDashboardComponent implements OnInit, OnDestroy {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  // Functions animated show close box card
  currentStateDash= 'initial';
  currentStateOperacoes= 'initial';

  changeStateDash() {
    this.currentStateDash = this.currentStateDash === 'initial' ? 'final' : 'initial';
  }

  changeStateOperacoes() {
    this.currentStateOperacoes = this.currentStateOperacoes === 'initial' ? 'final' : 'initial';
  }

  title: any;
  subtitle: any;
  msgs = [];

  gerencialId:    number;
  box1:  String;
  box2:  String;
  box3:  String;
  box4:  String;
  box5:  String;
  visualizacaoId: number;

  box1ShowAbsolute: boolean = false;
  box2ShowAbsolute: boolean = false;
  box3ShowAbsolute: boolean = false;
  box4ShowAbsolute: boolean = false;
  box5ShowAbsolute: boolean = false;
  picklistState: boolean = false

  planosGerenciais    : SelectItem[] = [];
  tiposVisualizacao   : SelectItem[] = [];


  opcoesListBox1   : SelectItem[] = [];
  opcoesListBox2  : SelectItem[] = [];
  opcoesListBox3  : SelectItem[] = [];
  opcoesListBox4  : SelectItem[] = [];
  opcoesListBox5  : SelectItem[] = [];

  listBox1    : Array<any> = new Array();
  listBox2    : Array<any> = new Array();
  listBox3    : Array<any> = new Array();
  listBox4    : Array<any> = new Array();
  listBox5    : Array<any> = new Array();


  listaOperacoes : Array<any> = new Array();
  listaOperacoesRelacionadas : Array<any> = new Array();
  listaOperacoesFolhaRelacionadas : Array<any> = new Array();
  legendaDasboard2 : Array<any> = new Array();

  isCardFluxoBI: boolean;

  /*Variaveis Card*/
  statusCambio: boolean = true;
  statusFolha: boolean = false;

  toolbarMainIni = [
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'green', onClick: () => this.salvarParametroDash() },
    { icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
      this.ShowHIdeCardDash()
      this.changeStateDash()
    } },
  ]

  toolbarDetailsIni = [
    { icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
      this.ShowHIdeCardOperacoes()
      this.changeStateOperacoes()
    } },
  ]

  public toolbarMain = new Toolbar()
  public toolbarDetails = new Toolbar()



  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private matDialog: MatDialog,
    private dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService

  ) {

  }


  ngOnInit(): void {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'CONFIGURAÇÕES DASHBOARD';
    this.subtitle = 'Conf. Dashboard';
    this.titleService.title = 'CONFIGURAÇÕES DASHBOARD';

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.gerencialId = 0;
    this.box1 = '';
    this.box2 = '';
    this.box3 = '';
    this.box4 = '';
    this.box5 = '';
    this.visualizacaoId = 0;


    this.tiposVisualizacao = [];
    this.tiposVisualizacao.push({value : 0 , label: 'Selecione'});
    this.tiposVisualizacao.push({value : 1 , label: 'YTD'});
    this.tiposVisualizacao.push({value : 2 , label: 'ANO'});
    this.tiposVisualizacao.push({value : 3 , label: 'MÊS'});

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {


      this.listar();


      });

    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarDetails.setAll(this.toolbarDetailsIni)
  }



  hideMessage() {



  }



  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  listar() {
    if ((this.dblinkedSessionService.hasEmpresaSelected) &&
      (this.dblinkedSessionService.hasEmpresaSelected) && (this.dblinkedSessionService.periodo.month)) {
        this.buscarPlanoGerencial();
        this.buscarOperacoes();

        setTimeout(() => {
          this.getConf();},
        1000);

    }
  }

  ngOnDestroy(): void {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  buscarPlanoGerencial(){

    this.planosGerenciais = [];

    this.httpService.wait();
    this.httpService.get('/planogerencial?$select=id,codigo,nome,niveis,moeda,view&$' +
    'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
      .subscribe(ref => ref.value.forEach(element => {
            this.planosGerenciais.push({value: element.id, label: element.codigo + ' - ' + element.nome});
          }),
        error => this.httpService.handleError(error, () => this.buscarPlanoGerencial()),
        () => this.httpService.done());

  }


  buscarContasGerencial(event: any){
    return new Promise((resolve) => {
    this.opcoesListBox1 = [];
    this.opcoesListBox2 = [];
    this.opcoesListBox3 = [];
    this.opcoesListBox4 = [];
    this.opcoesListBox5 = [];

    this.httpService.wait();
    this.httpService.get('/planogerencialcontas?$select=id,codCta,descrCta,' +
      'codNaturezaConta/id,indCta,nivel,ctaSup/id,sql,ordem&$filter=planoGerencial/id eq ' + event +
      '&$orderby=codCta')
      .subscribe(ref => {
        ref.value.forEach(element => {
            this.opcoesListBox1.push({value: element.id, label: element.codCta + ' - ' + element.descrCta});
            this.opcoesListBox2.push({value: element.id, label: element.codCta + ' - ' + element.descrCta});
            this.opcoesListBox3.push({value: element.id, label: element.codCta + ' - ' + element.descrCta});
            this.opcoesListBox4.push({value: element.id, label: element.codCta + ' - ' + element.descrCta});
            this.opcoesListBox5.push({value: element.id, label: element.codCta + ' - ' + element.descrCta});
        });

        //resolve(); TODO: VERIFICAR UTILIZAÇÃO
      },
        error => this.httpService.handleError(error, () => this.buscarContasGerencial(event)),
        () => this.httpService.done());
    });
  }

  buscarOperacoes(){

    this.httpService.wait();
    this.httpService.get('/operacaoregracontabil?$select=id,descricao'
      + '&$filter=empresa/id eq '
      + this.dblinkedSessionService.empresa.id+' and ativo eq true&$orderby=descricao')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.listaOperacoes.push({ descricao: element.descricao, value: element.id});
        })
        this.picklistState = true
      },
        error => this.httpService.handleError(error, () => this.buscarOperacoes()),
        () => this.httpService.done());

  }


  salvarParametroDash(){

    if (confirm('Deseja realmente salvar esta configuração?')) {




      const listasEnviar = {
        gerencialId: this.gerencialId,
        visualizacaoId: this.visualizacaoId,
        box1: this.box1,
        box2: this.box2,
        box3: this.box3,
        box4: this.box4,
        box5: this.box5,
        box1ShowAbsolute: this.box1ShowAbsolute,
        box2ShowAbsolute: this.box2ShowAbsolute,
        box3ShowAbsolute: this.box3ShowAbsolute,
        box4ShowAbsolute: this.box4ShowAbsolute,
        box5ShowAbsolute: this.box5ShowAbsolute,
        listBox1: this.listBox1,
        listBox2: this.listBox2,
        listBox3: this.listBox3,
        listBox4: this.listBox4,
        listBox5: this.listBox5,
        listaOperacoesRelacionadas: this.listaOperacoesRelacionadas,
        listaOperacoesFolhaRelacionadas: this.listaOperacoesFolhaRelacionadas,
        empresaId: this.dblinkedSessionService.empresa.id,
        authUser: this.sessionService.loggedUser.id

      };

      this.httpService.wait();
      this.httpService.post('/custom/financeiro/salvar-conf-dashboard', listasEnviar)
        .subscribe(result =>
          {
            console.log(result);

            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Msg: ', detail: 'Configuração Salva com Sucesso!' });
          }

        ,
          error => this.httpService.handleError(error, () => this.salvarParametroDash()),
          () => this.httpService.done());

    }
  }

  getConf(){
    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {

      this.httpService.wait();
      this.httpService.get('/custom/financeiro/get-conf-dash(' + this.dblinkedSessionService.empresa.id +
      ')')
        .subscribe(conf =>

          {
            if(!isNullOrUndefined(conf[0])){
              this.listBox1 = new Array();
              this.listBox2 = new Array();
              this.listBox3 = new Array();
              this.listBox4 = new Array();
              this.listBox5 = new Array();

              this.gerencialId = conf[0].gerencialId;
              this.buscarContasGerencial(this.gerencialId);
              this.visualizacaoId = conf[0].visualizacaoId;
              this.box1 = conf[0].box1;
              this.box2 = conf[0].box2;
              this.box3 = conf[0].box3;
              this.box4 = conf[0].box4;
              this.box5 = conf[0].box5;

              this.box1ShowAbsolute = conf[0].show_absolute_value_box1;
              this.box2ShowAbsolute = conf[0].show_absolute_value_box2;
              this.box3ShowAbsolute = conf[0].show_absolute_value_box3;
              this.box4ShowAbsolute = conf[0].show_absolute_value_box4;
              this.box5ShowAbsolute = conf[0].show_absolute_value_box5;

            conf[0].conf.forEach(element => {
              this.listaOperacoesRelacionadas.push({ descricao: element.descricao, value: element.id});
            });


            conf[0].confFolha.forEach(element => {
              this.listaOperacoesFolhaRelacionadas.push({ descricao: element.descricao, value: element.id});

            });


            setTimeout(() => {
              conf[0].confDash.forEach(element => {
                if(element.posicao === 1){
                  this.listBox1.push(element.ctaGerencialId);
                } else if(element.posicao === 2){
                    this.listBox2.push(element.ctaGerencialId);
                  } else if(element.posicao === 3){
                    this.listBox3.push(element.ctaGerencialId);
                  } else if(element.posicao === 4){
                    this.listBox4.push(element.ctaGerencialId);
                  } else if(element.posicao === 5){
                    this.listBox5.push(element.ctaGerencialId);
                  }

              });
            },
            1000);

               }



          },
          error => this.httpService.handleError(error, () => this.getConf()),
          () => this.httpService.done());
      }

  }

  ShowHideFluxoBI(){

  }

  /*Show Hide isCardDash*/
  isCardDash: boolean = true;
  ShowHIdeCardDash(){
    this.isCardDash = !this.isCardDash;
    this.toolbarMain.setIcon('btn2', this.isCardDash ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn2', this.isCardDash ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide isCardOperacoes*/
  isCardOperacoes: boolean = true;
  ShowHIdeCardOperacoes(){
    this.isCardOperacoes = !this.isCardOperacoes;
    this.toolbarDetails.setIcon('btn1', this.isCardOperacoes ? 'expand_less' : 'expand_more')
    this.toolbarDetails.setTooltip('btn1', this.isCardOperacoes ? 'Ver menos' : 'Ver mais')
  }

  /*Start Abas Operações*/
  showTelaCard(tipo) {
    this.statusCambio = false;
    this.statusFolha = false;

    if (tipo === 'TELA_1') {
      this.statusCambio = true;
    }

    else if (tipo === 'TELA_2') {
      this.statusFolha = true;
    }
  }
  /*End Abas Operações*/


}
