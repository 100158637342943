import { Component, OnInit, OnDestroy } from '@angular/core';
import { TitleService } from 'app/shared/services/title.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { UtilService } from 'app/shared/services/util.service';
import { HttpService } from 'app/shared/services/http.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { trigger, state, style, animate, transition } from '@angular/animations'

@Component({
  selector: 'app-relatorio-boletos',
  templateUrl: './relatorio-boleto.component.html',
  styleUrls: ['./relatorio-boleto.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class RelatorioBoletosComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private utilService: UtilService,
    public dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }

  // controles locais
  title: string;
  subtitle: string;
  msgs: Message[] = [];
  msgsModal: Message[] = [];
  // filtros
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;
  modalPeriodo: boolean;
  saldoAtual: number = 0.00;
  valorFiltro: number = 0.00;
  // relatorios
  boletosAtivos: Array<any> = new Array();
  boletosCancelados: Array<any> = new Array();

  idBoletosCheck: Array<any> = new Array();
  opcoesComboAtivoStatus : Array<any> = new Array();
  opcoesComboCanceladoStatus : Array<any> = new Array();
  colsBoletosAtivos :any[] = [];
  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titleService.title = 'Relatório de boletos';
    this.title = 'Movimentação de boletos';
    this.subtitle = 'Movimentação de boletos';
    this.defaultDate = new Date();
    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;
    this.boletosAtivos = new Array<any>();
        this.boletosCancelados = new Array<any>();
        this.modalPeriodo = false;
        this.saldoAtual = 0.00;
        this.valorFiltro = 0.00;
        this.idBoletosCheck = new Array();


    this.colsBoletosAtivos = [
      { field: 'reference', header: 'Ref.' },
      { field: 'dtDueDate', header: 'Vencto' },
      { field: 'dtCreate', header: 'Emissão' },
      { field: 'nome', header: 'Nome' },
      { field: 'cpf', header: 'CPF' },
      { field: 'valor', header: 'Valor' },
      { field: 'status', header: 'status' },
      { field: 'dtLastEvent', header: 'Última Atualização' }
    ];

    this.subscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { setTimeout (() => { this.toolbarEnable(); }, 1000); this.start(); });
  }

  start() {
  // formatação de datas
    let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    let mes = this.defaultDate.getMonth() > 8 ?
                (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
      if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
        mes = this.dblinkedSessionService.periodo.month.value > 8 ?
          this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
        const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
        this.defaultDate = new Date(dt);
        // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
        lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
        this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
        this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

      }

      // final formatação de datas
      this.idBoletosCheck = new Array();
        this.boletosAtivos = new Array<any>();
        this.boletosCancelados = new Array<any>();
        this.opcoesComboAtivoStatus = new Array<any>();
        this.opcoesComboCanceladoStatus = new Array<any>();
        this.modalPeriodo = false;
        this.saldoAtual = 0.00;
        this.valorFiltro = 0.00;
        // buscando as infos
        this.httpService.wait();
        this.httpService.get('/custom/pagseguro/relatorio-boletos-periodo',
        [this.dblinkedSessionService.empresa.id, this.dataIniSearch, this.dataFinSearch])
          .subscribe(retorno => {
            this.boletosCancelados = new Array<any>();
            this.boletosAtivos = new Array<any>();
              retorno.forEach(el => {
                  if (el.status.indexOf('CANCELADO') !== -1 || el.status.indexOf('Cancelado') !== -1) {
                    this.boletosCancelados.push(el);
                    var index = 0;
                    if (this.opcoesComboCanceladoStatus !== null || this.opcoesComboCanceladoStatus !== undefined) {
                      index = this.opcoesComboCanceladoStatus.findIndex(e => e.value === el.status);
                    }
                    if (index === -1) {
                      this.opcoesComboCanceladoStatus.push({label: el.status , value: el.status});
                    }
                    this.opcoesComboCanceladoStatus.sort((a,b) => a.label.localeCompare(b.label));

                  } else {
                    this.boletosAtivos.push(el);
                    this.saldoAtual += parseFloat((el.valor).replace('.', '').replace(',', '.'));
                    var index = 0;
                    if (this.opcoesComboAtivoStatus !== null || this.opcoesComboAtivoStatus !== undefined) {
                      index = this.opcoesComboAtivoStatus.findIndex(e => e.value === el.status);
                    }
                    if (index === -1) {
                      this.opcoesComboAtivoStatus.push({label: el.status , value: el.status});
                    }
                    this.opcoesComboAtivoStatus.sort((a,b) => a.label.localeCompare(b.label));

                  }
              });
          },
            error => this.httpService.handleError(error, () => this.start()),
            () => this.httpService.done());
        // final busca
      }

  }

  periodoChange() {

    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() > 8 ?
                    (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() > 8 ?
                    (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
                    this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
                    this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }
    const d1 = new Date(this.dataIniSearch);
    const d2 = new Date(this.dataFinSearch);
    if (d1 > d2) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Data incial maior que a final. Verifique!' });
      return;
    }
    this.idBoletosCheck = new Array();
    this.boletosAtivos = new Array<any>();
    this.boletosCancelados = new Array<any>();
    this.modalPeriodo = false;
    this.saldoAtual = 0.00;
    this.valorFiltro = 0.00;
    this.toolbarEnable();
    // buscando as infos
    this.httpService.wait();
    this.httpService.get('/custom/pagseguro/relatorio-boletos-periodo',
    [this.dblinkedSessionService.empresa.id, this.dataIniSearch, this.dataFinSearch])
      .subscribe(retorno => {
          retorno.forEach(el => {
            if (el.status.indexOf('CANCELADO') !== -1 || el.status.indexOf('Cancelado') !== -1) {
                this.boletosCancelados.push(el);
              } else {
                this.boletosAtivos.push(el);
                this.saldoAtual += parseFloat((el.valor).replace('.', '').replace(',', '.'));
              }
          });
      },
        error => this.httpService.handleError(error, () => this.start()),
        () => this.httpService.done());
    // final busca
  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.sessionService.destroySubscribe(this.subscription);
  }

  tabChange(event) {

    this.valorFiltro = 0.00;
    this.saldoAtual = 0.00;
    if ( event.index === 0 ) {
      let valor = 0.00;
      let total = 0.00;
      this.boletosAtivos.forEach(element => {
      valor = parseFloat((element.valor).replace('.', '').replace(',', '.'));
        total = total  + valor;
      });
      setTimeout(() => (this.saldoAtual = total) , 100);
    }
    if ( event.index === 1 ) {
      let valor = 0.00;
      let total = 0.00;
      this.boletosCancelados.forEach(element => {
      valor = parseFloat((element.valor).replace('.', '').replace(',', '.'));
        total = total  + valor;
      });
      setTimeout(() => (this.saldoAtual = total) , 100);
    }
  }

  /*public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    console.log(tabChangeEvent);
  }*/

  onFilter(event) {
    this.valorFiltro  = 0.00;
    let valor = 0.00;
    event.filteredValue.forEach(element => {
      valor = parseFloat((element.valor).replace('.', '').replace(',', '.'));
      this.valorFiltro  = this.valorFiltro  + valor;
    });
  }

  private toolbarEnable() {
    const todos = this.buttons.slice();
    if ( todos.length === 0) {
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      this.toolbarService.replace('edit', false,
                                  'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());
      this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
      this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir', );
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
      this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar' );
      this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo');
    } else {
      this.toolbarService.ocultar('voltar');
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.ocultar('save');
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      this.toolbarService.replace('edit', false,
                                  'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());
      this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
      //       this.toolbarService.alterarStatus('check', false, 'Aprovar todos', () => this.modalStatusPedidosLote());
      this.toolbarService.replace('pesquisa', true, 'class-edit-button', 'search', 'Pesquisar', );
      this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar' );
      this.toolbarService.replace('print', true, 'class-edit-button', 'print', 'Imprimir' );
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
      this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar' );
      this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo');
    }
  }

  openModalPeriodo() {
    this.modalPeriodo = true;
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
  }

  selectBoleto(id: any, event: any) {
      const index2 = this.idBoletosCheck.findIndex(c => c.id === id);
      if (event.selected) {
          this.idBoletosCheck.splice(event, 1);
          event.selected = true;
      } else {
          this.idBoletosCheck.push(event);
          event.selected = false;
      }

    if (this.idBoletosCheck.length > 0) {
      this.toolbarService.alterarStatus('check', false, 'Atualizar Status', () => this.atualizarStatusBoleto());
    } else {
      this.toolbarService.alterarStatus('check', true, 'Atualizar Status', () => this.atualizarStatusBoleto());
    }
  }

  atualizarStatusBoleto() {
    if (this.idBoletosCheck.length === 0 ) {
      return;
    }
    const s = (this.idBoletosCheck.length > 1 ? 's' : '');

    let boletos = '';
    this.idBoletosCheck.forEach(boleto => {
      boletos += boleto.idBolRetorno + ',';
    });
      const list = {
        boletosId: boletos.substring(0, (boletos.length - 1)),
        empresa: this.dblinkedSessionService.empresa.id,
        usuario: this.sessionService.loggedUser.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/pagseguro/consultar-transacao', list).subscribe(rst => {
        this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'MSG: ',
                          detail: 'Status do' + s + ' título' + s + ' atualizado' + s + ' com sucesso!'});
        this.periodoChange();
      },
      error => this.httpService.handleError(error, () => this.atualizarStatusBoleto()), () => this.httpService.done());
  }

  hideMessage() {


  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  currentState = 'initial';
  changeCardState() {
    this.toggleIconState();
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }

  iconState: boolean = true;
  toggleIconState() {
    this.iconState = !this.iconState;
  }
}
