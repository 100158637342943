import { Component, OnInit } from '@angular/core';
import { TbCodPais } from '../model/tb-cod-pais.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'siscoserv-pais',
  templateUrl: './pais.component.html',
  styleUrls: ['./pais.component.scss'],
  providers: [CrudService]
})
export class PaisComponent extends DataTableBasicComponent implements OnInit {
  public tbCodPais: TbCodPais = new TbCodPais();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('CÓDIGO', 'cod'),
      new DataTableColumn('NOME', 'nome'),
      new DataTableColumn('CÓDIGO*', 'valueAlias')
    ];

    this.controls = [
      new DataControl('Código', 'cod').setReadOnly(),
      new DataControl('Nome', 'nome').setReadOnly(),
      new DataControl('Código*', 'valueAlias')
    ];
  }
}
