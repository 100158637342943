export class View {
  id: number;
  nome: string;
  descricao: string;
  viewId: View;

  constructor(view?: View | number) {
    if (view !== undefined && view !== null) {
      if (typeof view === 'number') {
        this.id = view;
      } else {
        this.id = view.id;
        this.nome = view.nome;
        this.descricao = view.descricao;
        this.viewId = view.viewId;
      }
    }
  }
}
