import { isNullOrUndefined } from "util";

export class FormularioAgendamentoStatus{

  id                : number;
  descricao         : string;


  constructor(formularioAgendamentoStatus?: FormularioAgendamentoStatus | number) {
    if (!isNullOrUndefined(formularioAgendamentoStatus)) {
      if (typeof formularioAgendamentoStatus === 'number') {
        this.id = formularioAgendamentoStatus;
      } else {
        this.id         = formularioAgendamentoStatus.id;
        this.descricao  = formularioAgendamentoStatus.descricao;

      }
    }
  }

}