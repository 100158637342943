import { Component, OnInit, OnDestroy } from '@angular/core';

import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { isNullOrUndefined } from 'util';
import { TabelaCrm } from "../model/tabela-crm.model";


@Component({
  selector: 'tabela-crm',
  templateUrl: './tabela-crm.component.html',
  styleUrls: [
    './tabela-crm.component.scss'
  ]
})
export class TabelaCrmComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService

  ) {}

  private sessionSubscription: Subscription; // change session event;
  title = '';
  subtitle = '';
  // components;
  listaTabelaCrm :SelectItem[] = [];
  showLista :boolean = false;
  showNew : boolean = false;
  showEdit : boolean = false;
  descricao : string = '';

  configuracaoApiId : number = 0;
  plataformaServicoId : number= 0;
  plataformaId        : number = 0;


  opcaoConfiguracaoApi :SelectItem [] = [];
  opcaoPlataforma :SelectItem[] = [];
  opcaoPlataformaServico :SelectItem[] = [];


  // msgs;
  msgs: Message[] = [];



  // date;
  defaultDate: Date;
  pt_BR: any;
  tabelaCrmId : number = 0;

  tabelaCrm = new TabelaCrm();


  ngOnInit() {
    this.dblinkedSessionService.hidePeriods();

    Promise.resolve(null).then(() => (this.toolbarService.hidden = false));
    Promise.resolve(null).then(() => this.refreshToolbar());

    this.title = 'TABELA CRM ';

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
      }
    );

    this.defaultDate = new Date();
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };


  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  loadComponent(component: string, rowValue: any) {

    // inicializando variáveis para validação posterior;

    // show;

    this.showLista = component === 'lista' ? true : false;
    this.showEdit = component === 'edit' ? true : false;
    this.showNew = component === 'new' ? true : false;
    this.loadListaTabelas();
    if (component === 'new') {
      this.subtitle = 'nova tabela';
      this.descricao = '';

      this.tabelaCrmId = 0;
      this.plataformaId = 0;
      this.plataformaServicoId = 0;
      this.configuracaoApiId = 0;


    } else if (component === 'lista') {
      this.subtitle = 'TABELAS';
      this.opcaoConfiguracaoApi = [];
      this.opcaoConfiguracaoApi.push({value: 0, label: 'Selecione uma configuração de api'});
      this.opcaoPlataforma = [];
      this.opcaoPlataforma.push({value: 0, label: 'Selecione um tipo de plataforma'});
      this.opcaoPlataformaServico = [];
      this.opcaoPlataformaServico.push({value: 0, label: 'Selecione um serviço da plataforma'});
      this.getConfiguracaoApis();

    } else if (component === 'edit'){

      this.tabelaCrmId = rowValue["id"];
      this.descricao = rowValue["descricao"];

      this.configuracaoApiId = rowValue["configuracoesApisId"];

      this.getListaPlataformas(rowValue["configuracoesApisId"]);

      this.plataformaId = rowValue["plataformaPagamentosId"];
      this.getListaComboPlataformaServicos(rowValue["plataformaPagamentosId"]);

      this.plataformaServicoId = rowValue["plataformaPagamentosServicosId"];


    }
  }

  loadListaTabelas(){
    this.httpService.wait();
    this.httpService.get('/custom/crm/get-lista-tabela-crm(' + [this.dblinkedSessionService.empresa.id] + ')')
      .subscribe(
        ret => {

          this.listaTabelaCrm = [];
          ret.forEach(element => {
            this.listaTabelaCrm.push(element);
          });
        },
        error => {
          console.log(error);
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
          this.httpService.done();
        },
        () => this.httpService.done());
  }

  salvar(edit: boolean){


    //criamos um novo pagamento se edit === true
    if (edit === true){
      if (this.validacao()) {
        return;
      }
      let url = '';
      if (this.showNew === true){

        this.adicionarPlataforma();
      } else if (this.showEdit === true){
        this.editarPlataforma()
      }

    // se edit === false, deletamos o tipo
    } else if (edit === false){
      if (confirm('Deseja deletar esse registro?')) {
        this.deletarTabelaCrm();
      }

    }


  }

  adicionarPlataforma(){
    let listasEnviar = {
      tabelaCrmId : this.tabelaCrmId,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuarioId: this.sessionService.loggedUser.id,
      descricao: this.descricao,
      plataformaId: this.plataformaId,
      plataformaServicoId : this.plataformaServicoId,
      configuracaoApiId : this.configuracaoApiId,
      isNovoRegistro: true
    };

    this.httpService.wait();
    this.httpService.post('/custom/crm/salvar-tabela-crm', listasEnviar)
    .subscribe(
      (res) => {
        console.log(res);
        this.loadComponent('lista', null);
        if (res["error"] === false) {

          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'Sucesso',
            detail: res["message"]
          });
        } else{
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: res["message"] });
        }
      },
      error => {
        console.log(error);
        this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Erro." });
        this.httpService.done();
      },
      () => {
        this.httpService.done();
      }
    );


  }
  editarPlataforma(){
    let listasEnviar = {
      tabelaCrmId : this.tabelaCrmId,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuarioId: this.sessionService.loggedUser.id,
      descricao: this.descricao,
      plataformaId: this.plataformaId,
      plataformaServicoId : this.plataformaServicoId,
      configuracaoApiId : this.configuracaoApiId,
      isNovoRegistro: false
    };


    this.httpService.wait();
    this.httpService.post('/custom/crm/salvar-tabela-crm', listasEnviar)
    .subscribe(
      (res) => {

        if (res["error"] === false) {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'Sucesso',
            detail: res["message"]
          });
        } else{
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: res["message"] });
        }
        this.loadComponent('lista', null);
      },
      error => {
        console.log(error);
        this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao salvar." });
        this.httpService.done();
      },
      () => {
        this.httpService.done();
      }
    );


  }

  deletarTabelaCrm(){
    if (confirm('Deseja excluir este registro?')) {
      let listasEnviar = {
        tabelaCrmId : this.tabelaCrmId,
      };
      this.httpService.wait();
      this.httpService.post('/custom/crm/deletar-tabela-crm', listasEnviar)
      .subscribe(
        (res) => {


          if (res["error"] === false) {
            this.loadComponent('lista', null);
            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: 'Sucesso',
              detail: 'Registro excluído com sucesso.'
            });
          } else{
            this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: res["message"] });
          }
        },
        error => {
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao excluir." });
          this.httpService.done();
        },
        () => {
          this.httpService.done();
        }
      );

    }


  }

  private changeSession() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.opcaoConfiguracaoApi = [];
      this.opcaoConfiguracaoApi.push({value: 0, label: 'Selecione uma configuração de api'});
      this.opcaoPlataforma = [];
      this.opcaoPlataforma.push({value: 0, label: 'Selecione um tipo de plataforma'});
      this.opcaoPlataformaServico = [];
      this.opcaoPlataformaServico.push({value: 0, label: 'Selecione um serviço da plataforma'});

      this.loadComponent('lista', null);
      this.getConfiguracaoApis()


    }
  }

  private alterarStatusAtualizar(boo: boolean) {
    this.toolbarService.alterarStatus('atualizar', !boo, 'Atualizar', () => {
      this.changeSession();
    });
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() {

  }

  // app.component.ts;
  private refreshToolbar() {
    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    // this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('edit');
    // this.toolbarService.ocultar('add');
    //this.alterarStatusAtualizar(true);
    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar', () => this.changeSession());
    this.toolbarService.replace(
      'add',
      false,
      'class-new-button',
      'NOVO',
      'Adicionar',
      () => {
        this.loadComponent('new', null);
      }
    );

  }




  validacao(){

    if (!this.dblinkedSessionService.hasEmpresaSelected ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecionar uma empresa!'
      });
      return true;

    }

    if (isNullOrUndefined(this.configuracaoApiId) || this.configuracaoApiId === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma configuração de api!'
      });
      return true;

    }

    if (isNullOrUndefined(this.plataformaId) || this.plataformaId === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma plataforma!'
      });
      return true;

    }

    if (isNullOrUndefined(this.plataformaServicoId) || this.plataformaServicoId === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um serviço de plataforma!'
      });
      return true;

    }

    if (isNullOrUndefined( this.descricao) ||  (!isNullOrUndefined(this.descricao) && this.descricao.trim() === '' ) ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar uma descrição!'
      });
      return true;
    }

    return false;
  }


  getConfiguracaoApis(){
    this.httpService.wait();
    this.httpService.get('/custom/crm/get-lista-plataforma-crm')
      .subscribe(
        ret => {

          this.opcaoConfiguracaoApi = [];
          this.opcaoConfiguracaoApi.push({value: 0, label: 'Selecione uma configuração de api'});
          this.opcaoPlataforma = [];
          this.opcaoPlataforma.push({value: 0, label: 'Selecione um tipo de plataforma'});
          this.opcaoPlataformaServico = [];
          this.opcaoPlataformaServico.push({value: 0, label: 'Selecione um serviço da plataforma'});

          ret.forEach(element => {
            this.opcaoConfiguracaoApi.push({value: element.id, label: element.descricao});
          });
        },
        error => {
          console.log(error);
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
          this.httpService.done();
        },
        () => this.httpService.done());

  }

  getListaPlataformas(value: number){

    this.httpService.wait();
    this.httpService.get('/custom/crm/get-combo-plataforma(' + [value] + ')')
      .subscribe(
        ret => {

          this.opcaoPlataforma = [];
          this.opcaoPlataforma.push({value: 0, label: 'Selecione um tipo de plataforma'});
          ret.forEach(element => {
            this.opcaoPlataforma.push({value: element.id, label: element.descricao});
          });
        },
        error => {
          console.log(error);
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
          this.getListaPlataformas(value);
        },
        () => this.httpService.done());


  }


  getListaComboPlataformaServicos(plataformaPagamentosId: number){

    this.httpService.wait();
    this.httpService.get('/custom/crm/get-combo-plataforma-servico(' + [plataformaPagamentosId] + ')')
      .subscribe(
        ret => {

          this.opcaoPlataformaServico = [];
          this.opcaoPlataformaServico.push({value: 0, label: 'Selecione um serviço da plataforma'});
          ret.forEach(element => {
            this.opcaoPlataformaServico.push({value: element.id, label:'Descricao: ' + element.descricao +' -  Sufixo: ' + element.sufixoServico });
          });
        },
        error => {
          console.log(error);
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
          this.getListaComboPlataformaServicos(plataformaPagamentosId);
        },
        () => this.httpService.done());


  }


}
