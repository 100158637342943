import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../app.config';
import { Period } from '../../shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { Message, SelectItem } from 'primeng/api';
import { TimezonePipe } from '../../shared/pipes/timezone.pipe';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { TipoDocumento } from 'app/util/model/tipo-documento.model';
import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { Justificativas } from 'app/util/model/justificativas.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { Departamento } from 'app/util/model/departamento.model';
import { Obrigacao } from 'app/centralweb/model/obrigacao.model';
import { Envio } from 'app/centralweb/model/envio.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { EnvioDestinatarios } from 'app/centralweb/model/envio-destinatarios.model';
import { Relatorios } from 'app/centralweb/model/relatorios.model';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.scss'],
  providers: [CrudService]
})
export class RelatoriosComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  relatorioSelecionado: Relatorios;
  usuarioSelecionado: Usuario;

  relatorios: Array<Relatorios> = new Array();
  dataIni: Date;
  dataFin: Date;

  opcoesRelatorio: Array<SelectItem> = new Array();
  opcoesUsuario: Array<SelectItem> = new Array();
  title: string;
  subtitle: string;

  constructor(private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent) {
  }

  ngOnInit() {
    this.title = 'RELATÓRIOS GERENCIAIS';
    this.subtitle = 'EXPORTAR RELATÓRIOS DISPONÍVEIS PARA PDF';
    // this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.relatorios = new Array<Relatorios>();
    this.titleService.title = 'Relatórios';

    this.dataIni = new Date();
    this.dataFin = new Date();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.empresaChanged(); });
  }

  empresaChanged(): void {
    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
      this.httpService.wait();
      this.httpService.get('/relatorios?$select=id,nome,descricao,jasper,params&$orderby=nome')
        .subscribe(ref => this.loadRelatorios(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/centralweb/get-users-empresa(' + this.dblinkedSessionService.empresa.id + ')')
        .subscribe(usuarios => this.loadUsuarios(usuarios),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());
    }
  }

  loadRelatorios(value: Array<Relatorios>) {
    this.opcoesRelatorio = new Array();

    this.opcoesRelatorio.push({ label: 'Selecione um relatório', value: null });

    value.forEach(element => {

      this.opcoesRelatorio.push({
        label: element.nome + ' - ' + element.descricao,
        value: element
      });
    });
  }

  loadUsuarios(value: Array<any>) {
    this.opcoesUsuario = new Array();

    this.opcoesUsuario.push({ label: 'Selecione um usuário', value: null });

    value.forEach(element => {

      this.opcoesUsuario.push({
        label: element.nome + ' - ' + element.mail,
        value: element
      });
    });
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  verificaParametroDtIni(value: any) {
    if (value !== null && (value.params !== null && value.params !== undefined)) {
      if (value.params.indexOf('dtIni') >= 0) {
        return true;
      }
    } else {
      return false;
    }
  }

  verificaParametroDtFin(value: any) {
    if (value !== null && (value.params !== null && value.params !== undefined)) {
      if (value.params.indexOf('dtFin') >= 0) {
        return true;
      }
    } else {
      return false;
    }
  }

  verificaParametroUsuarioId(value: any) {
    if (value !== null && (value.params !== null && value.params !== undefined)) {
      if (value.params.indexOf('usuarioId') >= 0) {
        return true;
      }
    } else {
      return false;
    }
  }

  gerarRelatorio() {
    const listasEnviar = {
      dataIni: this.dataIni,
      dataFin: this.dataFin,
      empresaId: this.dblinkedSessionService.empresa.id,
      relatorioId: this.relatorioSelecionado.id,
      usuarioId: this.dblinkedSessionService.usuario.id,
      usuarioSelecionado: this.usuarioSelecionado === undefined ? 0 : this.usuarioSelecionado.id,
      params: this.relatorioSelecionado.params
    };


    // Chamada para gerar o relatório Agenda CERTO
    if (this.relatorioSelecionado.id === 3) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/impressao-central-web-agenda', listasEnviar)
        .subscribe(result => this.download(result),
          error => this.httpService.handleError(error, () => this.gerarRelatorio()),
          () => this.httpService.done());
    }

     // Chamada para gerar o relatório Responsável CERTO
     if (this.relatorioSelecionado.id === 2) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/impressao-central-web-responsavel', listasEnviar)
        .subscribe(result => this.download(result),
          error => this.httpService.handleError(error, () => this.gerarRelatorio()),
          () => this.httpService.done());
     }

    // Chamada para gerar o relatório Envio CERTO
    if (this.relatorioSelecionado.id === 1) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/impressao-central-web-envio', listasEnviar)
        .subscribe(result => this.download(result),
          error => this.httpService.handleError(error, () => this.gerarRelatorio()),
          () => this.httpService.done());
    }

     // Chamada para gerar o relatório Empresas CERTO
    if (this.relatorioSelecionado.id === 4) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/impressao-central-web-empresas', listasEnviar)
        .subscribe(result => this.download(result),
          error => this.httpService.handleError(error, () => this.gerarRelatorio()),
          () => this.httpService.done());
    }
  }

  download(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/cwreport'), '_blank');
    }
  }
}
