import { CadCcus } from "app/contabil/model/cad-ccus.model";
import { CadProj } from "app/contabil/model/cad-proj.model";
import { Area } from "app/orcamento/model/area-model";
import { Subarea } from "app/orcamento/model/subarea-model";

export class AlcadaRateio {
  id: number;
  cadCcus: CadCcus;
  cadProj: CadProj;
  area: Area;
  subarea: Subarea;

  constructor(AlcadaRateio?: AlcadaRateio | number) {
    if (AlcadaRateio !== undefined && AlcadaRateio !== null) {
      if (typeof AlcadaRateio === 'number') {
        this.id = AlcadaRateio;
      } else {
        this.id = AlcadaRateio.id;
        this.cadCcus = AlcadaRateio.cadCcus;
        this.cadProj = AlcadaRateio.cadProj;
        this.area = AlcadaRateio.area;
        this.subarea = AlcadaRateio.subarea;
      }
    }
  }
}