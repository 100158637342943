import { Injectable, TemplateRef } from '@angular/core';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';

import { Util } from 'app/shared/common/util';
import { DataTableColumn } from './model/data-table-column.model';

export class Selected {
  key: number;

  constructor(key: number) {
    this.key = key;
  }
}

class Filtered {
  key: string;
  value: any;

  constructor(key: string, value?: any) {
    this.key = key;
    this.value = value;
  }
}

class Ordered {
  key: string;
  sortIndex: number;
  sortInverted: boolean;

  constructor(key: string, sortIndex: number = null, sortInverted: boolean = false) {
    this.key = key;
    this.sortIndex = sortIndex;
    this.sortInverted = sortInverted;
  }
}

@Injectable()
export class DataTableService {
  selected: Array<Selected> = new Array();
  filtered: Array<Filtered> = new Array();
  ordered: Array<Ordered> = new Array();
  sortIndex = 0;

  lastRouterPath: string;

  // TODO: Orders

  private addCallback: Function;
  private addIcon: string;
  private addName: string;
  private addLabel: string;
  private addDisabled: boolean;
  bulkEditCallback: Function = null;

  private deleteCallback: Function;
  private deleteIcon: string;
  private deleteLabel: string;
  bulkDeleteCallback: Function = null;

  constructor(private toolbarService: ToolbarService) {
  }

  reset(routerPath: string) {
    this.lastRouterPath = routerPath;
    this.selected = new Array();
    this.filtered = new Array();
    this.ordered = new Array();
    this.sortIndex = 0;
  }


  get hasFiltered(): boolean {
    return this.filtered.length > 0 &&
           this.filtered.findIndex(filtered => filtered.value !== undefined &&
                                               filtered.value !== null &&
                                               filtered.value !== '') !== -1; // TODO: undefined OR null
  }

  setFilterValue(column: DataTableColumn, value: any) {
    this.filterColumn(column, value);
  }

  getFilterValue(column: DataTableColumn): any {
    const filtered = this.findFiltered(column);
    if (filtered) {
      return filtered.value;
    }

    return column.type === 'select' || column.type === 'checkbox' ? -2 : undefined;
  }

  filterColumn(column: DataTableColumn, value: any) {
    this.unfilterColumn(column);
    if (value !== undefined) {
      this.filtered.push(new Filtered(column.key, value));
    }
  }

  unfilterColumn(column: DataTableColumn) {
    if (this.findFiltered(column)) {
      const old = this.filtered.slice();
      this.filtered = new Array();
      old.filter(f => f.key !== column.key).forEach(filtered => {
        this.filtered.push(filtered);
      });
    }
  }

  private findFiltered(column: DataTableColumn) {
    return this.filtered.find(f => f.key === column.key);
  }


  get hasOrdered(): boolean {
    return this.ordered.length > 0;
  }

  setOrder(columnKey: string) {
    this.orderColumn(columnKey);
  }

  getOrder(columnKey: string): Ordered {
    return this.findOrdered(columnKey);
  }

  private orderColumn(columnKey: string) {
    const ordered = this.findOrdered(columnKey);
    if (ordered) {
      switch (ordered.sortInverted) {
        case false:
          ordered.sortInverted = true;
          break;
        case true:
          this.unorderColumn(columnKey);
          break;
        default:
          ordered.sortInverted = false;
          break;
      }
    } else {
      this.ordered.push(new Ordered(columnKey, this.sortIndex++));
    }
  }

  private unorderColumn(columnKey: string) {
    if (this.findOrdered(columnKey)) {
      const old = this.ordered.slice();
      this.ordered = new Array();
      old.filter(f => f.key !== columnKey).forEach(ordered => {
        this.ordered.push(ordered);
      });
    }
  }

  private findOrdered(columnKey: string): Ordered {
    return this.ordered.find(f => f.key === columnKey);
  }


  get hasSelected(): boolean {
    return this.selected.length > 0;
  }

  allSelected(items: Array<any>, keyField: string): boolean {
    return this.allVisibleSelected(items, keyField) &&
           this.selected.length === items.length;
  }

  allVisibleSelected(items: Array<any>, keyField: string): boolean {
    return this.selected.length > 0 &&
           items &&
           this.selected.length >= items.length &&
           items.findIndex(item => this.selected.findIndex(selected => selected.key === item[keyField]) === -1) === -1;
  }

  allSelect(items: Array<any>, keyField: any) {
    const allVisibleSelected = this.allVisibleSelected(items, keyField);

    // TODO: Handle all/common checkbox
    if (allVisibleSelected) {
      items.forEach(item => {
        if (this.findSelected(item, keyField)) {
          this.unselectItem(item, keyField);
        }
      });
    } else {
      items.forEach(item => {
        if (!this.findSelected(item, keyField)) {
          this.selectItem(item, keyField);
        }
      });
    }
  }

  singleSelect(item: any, keyField: any) {
    if (this.checked(item, keyField)) {
      this.unselectItem(item, keyField);
    } else {
      this.selectItem(item, keyField);
    }
  }

  checked(item: any, keyField: any) {
    return this.findSelected(item, keyField) !== undefined;
  }

  label(option: any, label: any) {
    let value: any = null;

    if (label === undefined) {
      return null;
    }

    if (option !== undefined && option !== null && option.label !== undefined && option.label !== null && option.label !== '') {
      return option.label;
    }

    if (Util.isArray(label)) {
      value = '';
      label.forEach((l: any) => {
        if (l.substr(0, 1) === ' ') {
          value += l;
        } else {
          if (option !== undefined && option !== null) {
            value += option[l];
          } else {
            value = null;
          }
        }
      });
    } else {
      if (option !== undefined && option !== null) {
        value = option[label];
      }
    }

    return value !== undefined ? value : null;
  }

  private selectItem(item: any, keyField: any) {
    const hasFirstSelected = !this.hasSelected;

    this.selected.push(new Selected(item[keyField]));

    if (hasFirstSelected) {
       this.refreshButtons();
      // quando selecionar a linha remove botões
      // this.toolbarService.clear();
    }
  }

  refreshButtons() {
    if (this.hasSelected) {
      this.toolbarService.clear();
      this.toolbarService.add('export', true, 'class-other-button', 'description', 'Exportar');
      this.toolbarService.add('print', true, 'class-first-button', 'print', 'Imprimir');
      if (typeof this.bulkEditCallback === 'function') {
        const addButton: ToolbarButton = this.toolbarService.get('add');
        if (addButton !== null) {
          this.addCallback = addButton.callback;
          this.addIcon = addButton.icon;
          this.addDisabled = addButton.disabled;
          this.addName = addButton.name;
          this.addLabel = addButton.label;
        }
        this.toolbarService.remove('add');
        this.toolbarService.replace('bulkedit', false, 'class-edit-button', 'edit', 'Editar', () => {
          this.bulkEditCallback();
        });
      }

      if (typeof this.bulkDeleteCallback === 'function') {
        const deleteButton: ToolbarButton = this.toolbarService.get('delete');
        if (deleteButton !== null) {
          this.deleteCallback = deleteButton.callback;
          this.deleteIcon = deleteButton.icon;
          this.deleteLabel = deleteButton.label;
        }
          this.toolbarService.remove('add');
          this.toolbarService.replace('bulkdelete', false, 'class-remove-button', 'delete', 'Excluir', () => {
          this.bulkDeleteCallback();
        });
      }
      this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
    }
  }

  private unselectItem(item: any, keyField: any) {
    const old = this.selected.slice();
    this.selected = new Array();
    old.filter(s => s.key !== item[keyField]).forEach(selected => {
      this.selected.push(selected);
    });

    if (!this.hasSelected) {
      if (typeof this.addCallback === 'function') {
        this.toolbarService.replace('add', this.addDisabled, this.addIcon, this.addName,  this.addLabel, this.addCallback);
      }
      // this.toolbarService.remove('bulkedit');
      // this.toolbarService.remove('bulkdelete');
       this.toolbarService.remove('add');
       this.toolbarService.replace('bulkedit', true, 'class-edit-button', 'edit', 'Editar', () => {
        this.bulkEditCallback();
      });
        this.toolbarService.replace('bulkdelete', true, 'class-remove-button', 'delete', 'Excluir', () => {
          this.bulkDeleteCallback();
        });
        this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Adicionar',   );
    }
  }

  private findSelected(item: any, keyField: any) {
    return this.selected.find(s => s.key === item[keyField]);
  }
}
