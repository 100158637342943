

export class EventosMeioPagamento {
  id: number;
  descricao : string;


  constructor(eventosMeioPagamento?: EventosMeioPagamento | number) {
    if (eventosMeioPagamento !== undefined && eventosMeioPagamento !== null) {
      if (typeof eventosMeioPagamento === 'number') {
        this.id = eventosMeioPagamento;
      } else {
        this.id = eventosMeioPagamento.id;
        this.descricao = eventosMeioPagamento.descricao;

      }
    }
  }

}