import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Util } from 'app/shared/common/util';

import { isNullOrUndefined } from 'util';
import { Consultor } from 'app/eventos/model/consultor.model';
import { Message, MessageService } from 'primeng/api';


@Component({
  selector: 'app-consultor',
  templateUrl: './consultor.component.html',
  styleUrls: ['./consultor.component.scss'],
  providers: [CrudService]
})
export class ConsultorComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  pt_BR: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;


  modalConsultor    : boolean = false;
  listaConsultores  : Array<any> = new Array();
  idLista           : Array<any> = new Array();

    checkedAll = false;
  consultor = new Consultor();


  strDescricaoTipoIngresso          : string;
  editConsultor                     : boolean = false;
  btnSalvarDisabled                 : boolean = false;
  btnCancelarDisabled               : boolean = false;

  colslistaConsultores : any[];



  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService

  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'CONSULTORES';
    this.subtitle = '';
    this.titleService.title = 'CONSULTOR';


    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.modalConsultor = false;
    this.checkedAll = false;

    this.listaConsultores = new Array();

    this.consultor      = new Consultor();
    this.editConsultor  = false;
    this.idLista        = new Array();

    this.colslistaConsultores = [
      { field: 'nome', header: 'NOME' },
      { field: 'email', header: 'EMAIL' },
      { field: 'fone', header: 'TELEFONE' },
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {

        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
          && this.dblinkedSessionService.periodo.year) {
            this.defaultDate = new Date();
            const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
            // formatando meses com dois digitos
            const mes = this.defaultDate.getMonth() > 9 ?
              (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
            this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
            this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

            Promise.resolve(null).then(() => this.toolbarService.hidden = true);
            this.toolbarEnable();


            this.listar();
          }


      });

  }

 listar(){

  this.modalConsultor = false;
  this.checkedAll = false;

  this.listaConsultores = new Array();

  this.consultor      = new Consultor();
  this.editConsultor  = false;
  this.idLista        = new Array();

  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-consultores')
    .subscribe(retorno => {

      this.listaConsultores = new Array();
      retorno.forEach(i =>{
        this.listaConsultores.push(i);
      })
    },
      error => this.httpService.handleError(error, () => this.listar()),
      () => this.httpService.done());

 }


  hideMessage() {



  }





  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;

    this.idLista = new Array();

    this.listaConsultores.forEach(i=> {
      if (!this.checkedAll) {
        i.selected = false;
        this.idLista.slice(i.id, 1);
      } else {
        i.selected = true;
        this.idLista.push(i.id);
      }
    });


    this.configuraToolBar();
  }

  configuraToolBar() {



    if (isNullOrUndefined(this.idLista) || this.idLista.length === 0) {
     this.toolbarService.alterarStatus('remove', true, 'Excluir Consultor', () => this.excluirConsultor());
     this.toolbarService.alterarStatus('add', false, 'Novo Consultor', ()  => this.showModalNovoConsultor());
     this.toolbarService.alterarStatus('export', true, 'Editar Consultor', () =>() => this.showModalEditarConsultor());
    } else {
      if (this.idLista.length === 1) {
        this.toolbarService.alterarStatus('remove', false, 'Excluir Consultor', () => this.excluirConsultor());
        this.toolbarService.alterarStatus('add', true, 'Novo Consultor', ()  => this.showModalNovoConsultor());
        this.toolbarService.alterarStatus('export', false, 'Editar Consultor', ()  => this.showModalEditarConsultor());


      } else if(this.idLista.length > 1)  {
        this.toolbarService.alterarStatus('remove', false, 'Excluir Consultor', () => this.excluirConsultor());
        this.toolbarService.alterarStatus('add', true, 'Novo Consultor', () => this.showModalNovoConsultor());
        this.toolbarService.alterarStatus('export', true, 'Editar Consultor', () => this.showModalEditarConsultor());
      }
    }

  }



  selectCheckbox(id: any, event: any) {



    if (event.selected) {
       event.selected = true;
       const index = this.idLista.findIndex(i => i === id);
       this.idLista.splice(index,1);

    } else {
      this.idLista.push(id);
    }

    this.configuraToolBar();

  }






  onFilterEvento(event: any) {
  }

  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('edit');


    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.ngOnInit()
    );
    this.toolbarService.ocultar('save');

   // this.toolbarService.replace('more_vert', true, 'class-edit-button', 'more_vert', 'Opções');
    this.toolbarService.ocultar('pesquisa');

    //this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir Pedido',     );
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('export', true, 'class-edit-button', 'edit', 'Editar Palestra', () => this.showModalEditarConsultor() );
    //this.toolbarService.replace('edit', true, 'class-edit-button', 'list', 'Alterar Títulos Selecionados');

    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo Consultor',    () => this.showModalNovoConsultor()    );

  }










  showModalEditarConsultor() {
    this.modalConsultor = true;
    this.hideMessage();
    this.editConsultor = true;

    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-consultor-inf(' + this.idLista[0] +')')
      .subscribe(retorno => {

        this.consultor      = new Consultor();
        this.consultor.id   = this.idLista[0];
        this.consultor.nome = retorno[0].nome;
        this.consultor.email = retorno[0].email;
        this.consultor.fone  = retorno[0].fone;

      },
        error => this.httpService.handleError(error, () => this.showModalEditarConsultor()),
        () => this.httpService.done());




  }

  showModalNovoConsultor() {
    this.consultor = new Consultor();
    this.modalConsultor = true;
    this.hideMessage();


  }



  validacao(){

    if (isNullOrUndefined( this.consultor.nome) || this.consultor.nome.length < 3 ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'O vendedor deve ter o mínimo de 3 caracteres!'
      });
      return true;

    }



    return false;
  }


  ngOnDestroy(): void {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  salvarConsultor(){

    if (this.validacao()) {
      return;
    }


    const listaEnviar = {
       nome:     this.consultor.nome,
       email:    this.consultor.email,
       telefone: this.consultor.fone,
       IsNovoRegistro: true
    };

    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-consultor', listaEnviar)
      .subscribe(result => {

        if (result[0].error === true ) {
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        }

        this.listar();
      },
        error => this.httpService.handleError(error, () => this.salvarConsultor()),
        () => this.httpService.done());
  }

  editarConsultor(){

    if (this.validacao()) {
      return;
    }


    const listaEnviar = {
      id              : this.idLista[0],
      nome            : this.consultor.nome,
      email           : this.consultor.email,
      telefone        : this.consultor.fone,
      IsNovoRegistro  : false
   };

   this.httpService.wait();
   this.httpService.post('/custom/polozi/salvar-consultor', listaEnviar)
     .subscribe(result => {
      if (result[0].error === true ) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      }
      this.listar();
     },
       error => this.httpService.handleError(error, () => this.salvarConsultor()),
       () => this.httpService.done());


  }


 excluirConsultor(){
   if (confirm('Deseja excluir o(s) consultor(es) selecionado(s)?')) {

    const listaEnviar = {
      ids              : this.idLista

   };

   this.httpService.wait();
   this.httpService.post('/custom/polozi/deletar-consultor', listaEnviar)
     .subscribe(result => {
      if (result[0].error === true ) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      }
      this.listar();
     },
       error => this.httpService.handleError(error, () => this.salvarConsultor()),
       () => this.httpService.done());


   }
 }




}
