export class Moeda {
  id: number;
  codigo: string;
  descricao: string;
  simbolo: string;
  codigoPais: string;
  pais: string;
  tipo: string;
  dataExclusaoPtax: string;
  taxa : number;

  constructor(moeda?: Moeda | number) {
    if (moeda !== undefined && moeda !== null) {
      if (typeof moeda === 'number') {
        this.id = moeda;
      } else {
        this.id = moeda.id;
        this.codigo = moeda.codigo;
        this.descricao = moeda.descricao;
        this.simbolo = moeda.simbolo;
        this.codigoPais = moeda.codigoPais;
        this.pais = moeda.pais;
        this.tipo = moeda.tipo;
        this.dataExclusaoPtax = moeda.dataExclusaoPtax;
        this.taxa = moeda.taxa;
      }
    }
  }
}
