export class CodEntRef {
  id: number;
  cod: string;
  descricao: string;

  constructor(codentref?: CodEntRef | number) {
    if (codentref !== undefined && codentref !== null) {
      if (typeof codentref === 'number') {
        this.id = codentref;
      } else {
        this.id = codentref.id;
        this.cod = codentref.cod;
        this.descricao = codentref.descricao;
      }
    }
  }
}
