import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Empresa } from '../model/empresa.model';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { DeptoEmpresa } from '../model/depto-empresa.model';

@Component({
  selector: 'app-depto-empresa',
  templateUrl: './depto-empresa.component.html',
  styleUrls: [
    './depto-empresa.component.scss'
  ]
})
export class DeptoEmpresaComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) { }

  private sessionSubscription: Subscription; // change session event;

  title = '';
  subtitle = '';

  lista: Array<DeptoEmpresa> = new Array();
  deptoEmpresa: DeptoEmpresa;

  // components;
  showLista = false;
  showNew = false;
  showEdit = false;
  modalUploadItens = false;

  un: number = null;

  colsDeptoEmpresa: any[];

  // msgs;
  msgs: Message[] = [];
  // this.messageService.add({
  // severity: 'success',key: 'messageLinnks',
  // summary: 'Sucesso',
  // detail: 'Informações cadastradas.'
  // });

  // data;
  defaultDate: Date;
  pt_BR: any;


  @ViewChild('file') fileInput: ElementRef;
  file: File = null;
  filename: string = null;


  ngOnInit() {
    this.dblinkedSessionService.hidePeriods();

    Promise.resolve(null).then(() => (this.toolbarService.hidden = false));
    Promise.resolve(null).then(() => this.refreshToolbar());

    this.title = 'Departamento Empresa';

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
        this.listar();
      }
    );

    this.defaultDate = new Date();
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.colsDeptoEmpresa = [
      { field: 'descricao', header: 'DESCRIÇÃO' }
    ];

  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  private changeSession() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      setTimeout(() => {

        this.loadComponent('lista', null);
        this.listar();
      }, 500);

    }
  }

  private alterarStatusAtualizar(boo: boolean) {
    setTimeout(() => {
      this.toolbarService.alterarStatus('atualizar', !boo, 'Atualizar', () => {
        this.changeSession();
      });

    }, 500);

  }



  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() {

  }

  public loadComponent(component: string, rowValue: any) {
    this.deptoEmpresa = new DeptoEmpresa();

    // inicializando variáveis para validação posterior;
    this.deptoEmpresa.descricao = null;

    // show;
    this.showLista = component === 'lista' ? true : false;
    this.showEdit = component === 'edit' ? true : false;
    this.showNew = component === 'new' ? true : false;

    if (component === 'new') {
      this.subtitle = '';
    } else if (component === 'lista') {
      this.subtitle = '';

      // recarrega objetos alterados não salvos;

    } else if (component === 'edit') {
      this.subtitle = 'edição Depto Empresa';

      this.deptoEmpresa = rowValue;
    }
  }


  // app.component.ts;
  private refreshToolbar() {
    this.toolbarService.ocultar('voltar');
    // this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    //this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('import');
    // this.toolbarService.ocultar('add');

    this.alterarStatusAtualizar(true);

    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.ngOnInit()
    );
    this.toolbarService.replace(
      'add',
      false,
      'class-new-button',
      'NOVO',
      'Adicionar',
      () => {
        this.loadComponent('new', null);
      }
    );
  }

  salvarNovoRegistro() {



    console.log(this.deptoEmpresa);

    if (this.validacao()) {
      return;
    }

    const listaEnviar = {
      deptoEmpresa: this.deptoEmpresa,
      usuarioId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id,
      IsNovoRegistro: true
    };


    this.httpService.wait();
    this.httpService.post('/custom/cadastro/salvar-depto-empresa', listaEnviar)
      .subscribe(result => {
        console.log(result);
        if (result.error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result.detail
          });
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result.detail
          });

          setTimeout(() => {
            this.listar();
          }, 500);

        }

      },
        error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());


  }

  editarRegistro() {

    console.log(this.deptoEmpresa);

    if (this.validacao()) {
      return;
    }

    const listaEnviar = {
      deptoEmpresa: this.deptoEmpresa,
      usuarioId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id,
      IsNovoRegistro: false
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/salvar-depto-empresa', listaEnviar)
      .subscribe(result => {
        console.log(result);
        if (result.error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result.detail
          });
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result.detail
          });
          this.listar();
        }


      },
        error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());




  }


  deletarRegistro() {


    if (confirm('Deseja excluir este registro?')) {
      // delete;
      // ----------------------------------------
      this.httpService.wait();
      this.httpService
        .post('/custom/cadastro/deletar-depto-empresa', {
          deptoEmpresaId: this.deptoEmpresa.id
        })
        .subscribe(
          rst => {
            this.messageService.add({
              severity: rst.severity,
              summary: rst.summary,
              detail: rst.detail
            });
            if (rst.severity === 'success') {
              this.changeSession();
            }
          },
          error =>
            this.httpService.handleError(error, () => this.deletarRegistro()),
          () => this.httpService.done()
        );
    }

  }


  validacao() {

    if (this.deptoEmpresa.descricao === null || this.deptoEmpresa.descricao === undefined || this.deptoEmpresa.descricao.trim() === '') {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'O campo "DESCRIÇÃO" deve ser preenchido.'
      });
      return true;
    }

    return false;
  }

  listar() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {


      this.loadComponent('lista', null);

      this.httpService.wait();
      this.httpService.get('/custom/cadastro/listar-depto-empresa(' + [this.dblinkedSessionService.empresa.id] + ')')
        .subscribe(retorno => {
          this.lista = new Array();
          retorno.forEach(r => {
            this.lista.push(r);

          });
        },
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());



    }
  }


}

