export class CstPiscofins {
  id: number;
  cst: string;
  descricao: string;

  constructor(cstpiscofins?: CstPiscofins | number) {
    if (cstpiscofins !== undefined && cstpiscofins !== null) {
      if (typeof cstpiscofins === 'number') {
        this.id = cstpiscofins;
      } else {
        this.id = cstpiscofins.id;
        this.cst = cstpiscofins.cst;
        this.descricao = cstpiscofins.descricao;
      }
    }
  }
}
