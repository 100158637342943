import { Moeda } from '../../util/model/moeda.model';
import { PlanoGerencial } from './plano-gerencial.model';
import { CodNaturezaConta } from '../../util/model/cod-natureza-conta.model';
import { PlanoGerencialContas } from './plano-gerencial-contas.model';
import { Idioma } from '../../util/model/idioma.model';

export class PlanoGerencialContasInternacionalizacao {
  id: number;
  planoGerencialContas: PlanoGerencialContas;
  idioma: Idioma;
  codCta: string;
  descrCta: string;

  constructor(planoGerencialContasInternacionalizacao?: PlanoGerencialContasInternacionalizacao | number) {
    if (planoGerencialContasInternacionalizacao !== undefined && planoGerencialContasInternacionalizacao !== null) {
      if (typeof planoGerencialContasInternacionalizacao === 'number') {
        this.id = planoGerencialContasInternacionalizacao;
      } else {
        this.id = planoGerencialContasInternacionalizacao.id;
        this.planoGerencialContas = planoGerencialContasInternacionalizacao.planoGerencialContas;
        this.idioma = planoGerencialContasInternacionalizacao.idioma;
        this.codCta = planoGerencialContasInternacionalizacao.codCta;
        this.descrCta = planoGerencialContasInternacionalizacao.descrCta;
    }
  }
}
}
