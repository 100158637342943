export class TipoFrete {
  id: number;
  codigo: number;
  descricao: string;

  constructor(tipoFrete?: TipoFrete | number) {
    if (tipoFrete !== undefined && tipoFrete !== null) {
      if (typeof tipoFrete === 'number') {
        this.id = tipoFrete;
      } else {
        this.id = tipoFrete.id;
        this.codigo = tipoFrete.codigo;
        this.descricao = tipoFrete.descricao;
      }
    }
  }
}
