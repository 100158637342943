import { Departamento } from 'app/util/model/departamento.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';

export class Destinatarios {
  id: number;
 empresa: Empresa;
 nome: string;
 mail: string;
 tipoArquivo: Array<TipoArquivo> = new Array();

constructor(destinatarios?: Destinatarios | number) {
    if (destinatarios !== undefined && destinatarios !== null) {
      if (typeof destinatarios === 'number') {
        this.id = destinatarios;
      } else {
        this.id = destinatarios.id;
        this.empresa = destinatarios.empresa;
        this.nome = destinatarios.nome;
        this.mail = destinatarios.mail;
        this.tipoArquivo = destinatarios.tipoArquivo || new Array();
      }
    }
  }
}
