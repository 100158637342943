import { EcfIdentQualif } from 'app/util/model/ecf-ident-qualif.model';
import { Empresa } from 'app/cadastro/model/empresa.model';

export class Signatarios {
  id: number;
  identNom: string;
  identCpf: string;
  identQualif: string;
  ecfIdentQualif: EcfIdentQualif = new EcfIdentQualif();
  codAssin: string;
  indCrc: string;
  email: string;
  fone: string;
  ufCrc: string;
  numSeqCrc: string;
  dtCrc: Date;
  indRespLegal: boolean;
  empresa: Empresa;

  constructor(signatarios?: Signatarios | number) {
    if (signatarios !== undefined && signatarios !== null) {
      if (typeof signatarios === 'number') {
        this.id = signatarios;
      } else {
        this.id = signatarios.id;
        this.identNom = signatarios.identNom;
        this.identCpf = signatarios.identCpf;
        this.identQualif = signatarios.identQualif;
        this.ecfIdentQualif = signatarios.ecfIdentQualif || new EcfIdentQualif();
        this.codAssin = signatarios.codAssin;
        this.indCrc = signatarios.indCrc;
        this.email = signatarios.email;
        this.fone = signatarios.fone;
        this.ufCrc = signatarios.ufCrc;
        this.numSeqCrc = signatarios.numSeqCrc;
        this.dtCrc = signatarios.dtCrc;
        this.indRespLegal = signatarios.indRespLegal;
        this.empresa = signatarios.empresa;
      }
    }
  }
}
