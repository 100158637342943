

export class TbTipoLcto {
  id: number;
  cod: string;
  descricao: string;

  constructor(tbTipoLcto?: TbTipoLcto | number) {
    if (tbTipoLcto !== undefined && tbTipoLcto !== null) {
      if (typeof tbTipoLcto === 'number') {
        this.id = tbTipoLcto;
      } else {
        this.id = tbTipoLcto.id;
        this.cod = tbTipoLcto.cod;
        this.descricao = tbTipoLcto.descricao;

      }
    }
  }
}
