interface ToolbarDropDownChild {
  optionKey?: string
  label: string
  callback: Function
  visible?: boolean
}

export class ToolbarOption {
  key?: string
  icon: string
  text?: string
  title?: string
  tooltip?: string
  visible?: boolean
  disabled?: boolean
  color: 'green' | 'default' | 'red' | 'purple'
  onClick?: Function
  options?: Array<ToolbarDropDownChild>
  dropdown?: boolean

  constructor(option?: ToolbarOption) {
    const voidFunction = () => { };

    this.key = option.key ?? '';
    this.icon = option.icon;
    this.text = option.text ?? '';
    this.title = option.title ?? '';
    this.tooltip = option.tooltip ?? '';
    this.visible = option.visible ?? true;
    this.disabled = option.disabled ?? false;
    this.color = option.color;
    this.onClick = option.onClick ?? voidFunction;
    this.dropdown = option.dropdown ?? false;
    this.options = option.options ?? [];
    if (this.dropdown) {
      this.options.forEach(opt => {
        opt.visible = opt.visible ?? true;
      })
    }
  }
}