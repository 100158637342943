import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
// import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
// import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService, SelectItem } from 'primeng/api';
// import { isNullOrUndefined } from 'util';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { Classificacao } from '../model/classificacao-model';
import { Table } from 'primeng/table';
import { Toolbar } from 'app/shared/model/toolbar.model';



@Component({
  selector: 'app-classificacao',
  templateUrl: './classificacao.component.html',
  styleUrls: ['./classificacao.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ClassificacaoComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  //msgs: Message[] = [];
  titlePrincipal: string;
  subtitle: string;
  modalNew: boolean = false;
  modalEdit: boolean = false;
  editTool: boolean = false;
  newCodigo: string;
  newDescricao: string;
  editCodigo: string;
  editDescricao: string;
  areaCodigo: string;
  areaDescricao: string;
  subareaCodigo: string;
  subareaDescricao: string;
  listaClassificacoes: Array<Classificacao>;
  listaSubareas: Array<any>;
  projetosLdb: SelectItem[];
  classificacaoId: number;
  subareaId: number;
  listaAreaSubareaDisponiveis: Array<any>;
  listaAreaSubareaVinculados: Array<any>;
  listaSegmentosDisponiveis: Array<any>;
  listaSegmentosVinculados: Array<any>;
  statusTelaClass: boolean;
  TelaCond: boolean = false;
  statusTelaSubarea: boolean;
  statusTelaSub: boolean;
  statusTelaCond: boolean;
  sql: string;
  newClassificacao: boolean;

  cp: boolean;
  cc: boolean;

  centrosDeCustos: SelectItem[];
  centroDeCustoId: number;
  listaProjetosMapeados: Array<any> = new Array();
  listaProjetosAMapear: Array<any> = new Array();

  colslistaClassificacoes: any[] = [];
  colslistacc: any[] = [];
  colslistacp: any[] = [];
  centrosCusto: Array<any> = new Array();
  projetos: Array<any> = new Array();
  // checkedAll : boolean = false;


  constructor(
    // private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }

  // data() {
  //   this.dtCorte = new Date(this.timezonePipe.transform(this.dtCorte));
  // }
  @ViewChild('dt1') dt1: Table
  export() {
    this.dt1.exportCSV()
  }
  
  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: false, disabled: false, color: 'default', onClick: () => this.dt1.exportCSV() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openEditModal() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.delete() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.openNewModal() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardTable()
        this.changeStateTable()
      
      }
    },
  ]

  ngOnInit(): void {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.titlePrincipal = 'CONFIGURAÇÕES ORÇAMENTO';
    this.subtitle = 'CADASTRO DE CLASSIFICAÇÃO';

    this.colslistaClassificacoes = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'dataFormatada', header: 'ÚLTIMA EDIÇÃO' }
    ];

    this.colslistacc = [
      { field: 'codCcus', header: 'CÓDIGO' },
      { field: 'descrCcus', header: 'DESCRIÇÃO' }
    ];

    this.colslistacp = [
      { field: 'codProj', header: 'CÓDIGO' },
      { field: 'descrProj', header: 'DESCRIÇÃO' }
    ];

    this.colslistaClassificacoes = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRICÃO' },
      { field: 'dataFormatada', header: 'ÚLTIMA EDIÇÃO' }
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.loadClassificacoes();
        this.buscarProjetos();
        this.buscarCentroCusto();
        this.statusTelaClass = true;
        this.statusTelaSubarea = false;
        this.statusTelaCond = false;
        this.statusTelaSub = false;
        this.statusTelaCond = false;
        this.sql = '';
        this.cp = false;
        this.cc = false;
        this.newClassificacao = false;
        //       this.change();

        this.toolbarMain.setVisible('btn1', true)
        this.toolbarMain.setVisible('btn2', false)
        this.toolbarMain.setVisible('btn3', false)
        this.toolbarMain.setVisible('btn4', true)
        this.toolbarMain.setVisible('btn5', true)
      }
    });
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }



  // n funciona
  // salvarCondicao() {

  //   let dados = {
  //     empresaid: this.dblinkedSessionService.empresa.id.toString(),
  //     classificacaoId: this.classificacaoId,
  //     sql: this.sql
  //   };

  //   this.httpService.wait();
  //   this.httpService.post('/custom/orcamento/salvar-condicao-classificacao', dados)
  //     .subscribe(
  //       res => {
  //         this.exibirMensagem({ sucess: true, mensagem: 'Registro vinculado com sucesso!' });
  //         this.httpService.done();

  //       },
  //       err => {
  //         this.exibirMensagem({ error: true, mensagem: 'Não foi possível vincular esse registro!' });
  //         console.log(err);
  //         this.httpService.done();
  //       }
  //     );


  // }

  // onRowSelect(row) {
  //   this.classificacaoId = row.id;
  //   this.areaCodigo = row.codigo;
  //   this.areaDescricao = row.descricao;
  //   this.cc = true;
  //   this.cp = false;
  //   this.TelaCond = true;
  // }

  editClassificacao() {

    if ((!this.editCodigo) || (!this.editDescricao)) {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Preencha os dois campos.' });
      //this.msgs = [];
      //this.msgs.push({ severity: 'warn', summary: '', detail: 'Preencha os dois campos.' });
    } else {
      const dados = {
        classificacaoId: this.newClassificacao ? 0 : this.classificacaoId,
        codigo: this.editCodigo.toString(),
        descricao: this.editDescricao.toString(),
        userid: this.sessionService.loggedUser.id.toString(),
        empresaId: this.dblinkedSessionService.empresa.id
      };
      this.httpService.wait();
      this.httpService.post('/custom/orcamento/edit-classificacao', dados)
        .subscribe(
          res => {
            this.exibirMensagem({ error: false, mensagem: 'Classificação alterada com sucesso!' });
            this.httpService.done();
          },
          err => {
            this.exibirMensagem({ error: true, mensagem: 'Não foi possível editar esse registro!' });
            console.log(err);
            this.httpService.done();
          }
        );
      this.modalEdit = false;
      this.modalNew = false;
    }
  }


  loadClassificacoes() {
    const dados = {
      Authorization: this.configService.bearerApi,
      empresaid: this.dblinkedSessionService.empresa.id.toString()
    };
    this.httpService.wait();
    this.httpService.post('/custom/orcamento/get-classificacoes', dados)
      .subscribe(
        res => {
          this.listaClassificacoes = [];
          res.result.forEach(element => {
            this.listaClassificacoes.push(element);
          });
          this.listaClassificacoes.sort((a, b) => {
            if (a.codigo < b.codigo) { return -1; }
            if (a.codigo > b.codigo) { return 1; }
            return 0;
          });
          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: err });
          console.log(err);
          this.httpService.done();
        }
      );
  }


  hideMessage() {

  }

  selectTodos(event: any) {
    if (event.checked) {
      this.listaClassificacoes.map((area) => {
        area.selected = true;
        this.editTool = true;

        this.toolbarMain.setVisible('btn1', true)
        this.toolbarMain.setVisible('btn2', false)
        this.toolbarMain.setVisible('btn3', true)
        this.toolbarMain.setVisible('btn4', true)
        this.toolbarMain.setVisible('btn5', true)
      });
    } else {
      this.listaClassificacoes.map((area) => {
        area.selected = false;
        this.editTool = false;

        this.toolbarMain.setVisible('btn1', true)
        this.toolbarMain.setVisible('btn2', false)
        this.toolbarMain.setVisible('btn3', false)
        this.toolbarMain.setVisible('btn4', true)
        this.toolbarMain.setVisible('btn5', true)
      });
    }
  }

  selectCheckbox(event: any){
    this.listaClassificacoes.map((area) => {
      if(area.id == event.data.id && area.codigo == event.data.codigo){
        area.selected = true;
      }
    })
    this.validateEdit();

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', true)
    this.toolbarMain.setVisible('btn3', true)
    this.toolbarMain.setVisible('btn4', true)
    this.toolbarMain.setVisible('btn5', true)
  }

  unselectCheckbox(event: any){
    this.listaClassificacoes.map((area) => {
      console.log('area')
      console.log(area)
      if(area.id == event.data.id && area.codigo == event.data.codigo){
        area.selected = false;
      }
    })
    this.validateEdit();

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', true)
    this.toolbarMain.setVisible('btn5', true)
  }

  validateEdit() {
    const result = this.listaClassificacoes.filter(classificacao => {
      return classificacao.selected ? true : false;
    });

    if (result.length > 1) {
      this.editTool = true;
    } else {
      this.editTool = false;
    }
  }

  // Revisar se abre o modal com as informações já salvas

  openEditModal() {
    const classificacaoSelected = this.listaClassificacoes.filter(classificacao => {
      return classificacao.selected ? true : false;
    });
    if (classificacaoSelected.length > 0) {
      this.modalEdit = true;
      this.newClassificacao = false;
      this.classificacaoId = parseInt(classificacaoSelected[0].id);
      this.editCodigo = classificacaoSelected[0].codigo;
      this.editDescricao = classificacaoSelected[0].descricao;
    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um registro para editar." });
    }
  }


  openNewModal() {
    this.newClassificacao = true;
    this.modalNew = true;
    this.editCodigo = '';
    this.editDescricao = '';
  }


  addClassificacao() {
    if ((this.newCodigo.toString() === '') ||
      (this.newDescricao.toString() === '')) {


      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Preencha os dois campos." });

    } else {
      const invalidCode = this.listaClassificacoes.filter((classificacao) => {
        return classificacao.codigo == this.newCodigo ? true : false;
      });
      if (invalidCode.length > 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Este código já está sendo utilizado por outra classificação." });
        //this.msgs = [];
        //this.msgs.push({ severity: 'warn', summary: '', detail: 'Este código já está sendo utilizado por outra classificação.' });
      } else {
        this.modalNew = false;
        const dados = {
          Authorization: this.configService.bearerApi,
          empresaid: this.dblinkedSessionService.empresa.id.toString(),
          userid: this.sessionService.loggedUser.id.toString(),
          codigo: this.newCodigo.toString(),
          descricao: this.newDescricao.toString(),
        };
        this.httpService.wait();
        this.httpService.post('/custom/orcamento/add-classificacao', dados)
          .subscribe(
            res => {
              this.exibirMensagem({ error: false, mensagem: res.detail });
              this.httpService.done();
              this.loadClassificacoes();
              this.newCodigo = '';
              this.newDescricao = '';
            },
            err => {
              this.exibirMensagem({ error: true, mensagem: err });
              console.log(err);
              this.httpService.done();
            }
          );
        this.modalNew = false;
      }
    }
  }

  delete() {
    const result = this.listaClassificacoes.filter((classificacao) => {
      return classificacao.selected ? true : false;
    });

    if (result.length > 0) {
      if (!confirm(`Tem certeza que deseja excluir ${result.length} registro${result.length > 1 ? 's' : ''}?`)) { return; }

      // tslint:disable-next-line: no-shadowed-variable
      const dados = result.map((classificacao) => {
        return {
          empresaid: this.dblinkedSessionService.empresa.id.toString(),
          classificacaoId: classificacao.id,
        };
      });

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/delete-classificacao', dados)
        .subscribe(
          res => {
            this.exibirMensagem({
              error: false,
              mensagem: `Classificaç${result.length > 1 ? 'ões' : 'ão'} deletad${result.length > 1 ? 'as' : 'a'} com sucesso!`
            });
            this.httpService.done();
            this.loadClassificacoes();
          },
          err => {
            this.exibirMensagem({ error: true, mensagem: 'Não foi possível excluir esse registro!' });
            this.httpService.done();
            console.log(err);
          }
        );
    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um registro para excluir." });
    }
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
    this.ngOnInit();
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  //   salvarRelacionamento(){

  //     if (this.validacao()) {
  //       return;
  //     }


  //     if (confirm('Deseja realmente salvar este relacionamento?')) {
  //       const req = {
  //         centroDeCustoId        : this.centroDeCustoId,
  //         // listaProjetosAMapear    : this.listaProjetosAMapear,
  //         listaProjetosMapeados   : this.listaProjetosMapeados,
  //         empresaId               : this.empresa.id
  //       };

  //     // console.log('req:   '  + JSON.stringify(req));

  //
  //       this.httpService.wait();
  //       this.httpService.post('/custom/cadastro/salvar-relacionamento-centro-custo-projetos', req)
  //       .subscribe(result => {
  //         if(result.error){
  //           this.messageService.add({
  //             severity: result.severity,
  //             summary:  result.summary,
  //             detail:   result.detail
  //           }];
  //         }else{
  //           this.messageService.add({
  //             severity: result.severity,
  //             summary:  result.summary,
  //             detail:   result.detail
  //           }];
  //         }
  //         this.change();
  //         this.centroDeCustoId = 0;
  //         },
  //           error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
  //           () => this.httpService.done());
  //     }
  //   }

  //   validacao(){
  //

  //     if (isNullOrUndefined( this.centroDeCustoId) || this.centroDeCustoId === 0) {
  //       this.messageService.add({
  //         severity: 'warn',key: 'messageLinnks',
  //         summary: 'MSG: ',
  //         detail: 'Favor selecionar um Centro de Custo'
  //       }];
  //       return true;
  //     }


  //     if (isNullOrUndefined(this.empresa.id) || this.empresa.id === 0 ) {
  //       this.messageService.add({
  //         severity: 'warn',key: 'messageLinnks',
  //         summary: 'MSG: ',
  //         detail: 'Favor selecionar uma empresa'
  //       }];
  //       return true;
  //     }

  //     return false;

  //   }

  //   tabChange(event: any){
  //     this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Relacionamento', () => this.salvarRelacionamento());
  //   }


  //   buscarProjetos(){

  //     this.listaProjetosMapeados = new Array();
  //     this.listaProjetosAMapear = new Array();

  //     if (this.centroDeCustoId > 0){
  //       this.httpService.wait();
  //       this.httpService.get('custom/cadastro/get-projetos', [this.dblinkedSessionService.empresa.id, this.centroDeCustoId])
  //         .subscribe(retorno => {
  //           retorno.forEach(element => {
  //               if(element.relac){
  //               this.listaProjetosMapeados.push(element);
  //             }else{
  //               this.listaProjetosAMapear.push(element);
  //             }
  //           });
  //         }, error => this.httpService.handleError(error, () => this.change()),
  //           () => this.httpService.done() );
  //     }
  //   }


  //Show/hide cards
  isCardTable: boolean = true;
  isCardVinculacao: boolean = true;

  currentStateTable = 'initial';
  currentStateVinculacao = 'initial';

  ShowHIdeCardTable() {
    this.isCardTable = !this.isCardTable;
    this.toolbarMain.setIcon('btn5', this.isCardTable ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn5', this.isCardTable ? 'Ver menos' : 'Ver mais')
  }

  ShowHIdeCardVinculacao() {
    this.isCardVinculacao = !this.isCardVinculacao;
  }

  changeStateTable() {
    this.currentStateTable = this.currentStateTable === 'initial' ? 'final' : 'initial';
  }

  changeStateVinculacao() {
    this.currentStateVinculacao = this.currentStateVinculacao === 'initial' ? 'final' : 'initial';
  }

  //Telas
  showTelaClassificacao(tipo) {
    this.listaAreaSubareaDisponiveis = new Array();
    this.listaAreaSubareaVinculados = new Array();

    if (tipo === 'TELA_1') {
      this.statusTelaClass = true;
      this.statusTelaSubarea = false;
      this.statusTelaCond = false;
      this.subareaId = null;
    } else if (tipo === 'TELA_2') {
      this.statusTelaSubarea = true;
      this.statusTelaClass = false;
      this.classificacaoId = null;
      this.statusTelaCond = false;
      this.statusTelaCond = false;
    } else if (tipo === 'TELA_SUB') {
      this.statusTelaSub = true;
      this.statusTelaCond = false;
      this.statusTelaCond = false;
    } else if (tipo === 'TELA_COND') {
      this.statusTelaCond = true;
      this.statusTelaSub = false;
      this.statusTelaCond = false;
      this.cc = true;
    } else if (tipo === 'CC') {
      this.cc = true;
      this.cp = false;
    } else if (tipo === 'CP') {
      this.cc = false;
      this.cp = true;
    }
  }

  buscarProjetos() {
    this.projetos = new Array();
    this.httpService.wait();
    this.httpService.get('/cadproj?$select=id,codProj,descrProj&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      '&$orderby=codProj').subscribe(cp => this.projetos = cp.value,
        error => this.httpService.handleError(error, () => this.buscarProjetos()),
        () => this.httpService.done());

  }

  buscarCentroCusto() {
    this.centrosCusto = new Array();
    this.httpService.wait();
    this.httpService.get('/cadccus?$select=id,codCcus,descrCcus&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      '&$orderby=codCcus').subscribe(cc => this.centrosCusto = cc.value,
        error => this.httpService.handleError(error, () => this.buscarCentroCusto()),
        () => this.httpService.done());
  }

  onSelectCp(event: any) {

    if (isNullOrUndefined(this.sql)) {
      this.sql = '';
    }

    this.sql = this.sql + ' ' + event.codProj;

  }

  onSelectCc(event: any) {

    if (isNullOrUndefined(this.sql)) {
      this.sql = '';
    }

    this.sql = this.sql + ' ' + event.codCcus;
  }


}
