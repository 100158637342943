export class TbTipoVinc {
  id: number;
  cod: string;
  descricao: string;
  valueAlias: string;

  constructor(tbTipoVinc?: TbTipoVinc | number) {
    if (tbTipoVinc) {
      if (typeof tbTipoVinc === 'number') {
        this.id = tbTipoVinc;
      } else {
        this.id = tbTipoVinc.id;
        this.cod = tbTipoVinc.cod;
        this.descricao = tbTipoVinc.descricao;
        this.valueAlias = tbTipoVinc.valueAlias;
      }
    }
  }
}
