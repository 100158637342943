import { Regra } from 'app/cadastro/model/regra.model';
import { ViewCampos } from 'app/cadastro/model/view-campos.model';

export class RegraCamposGroup {

  id: number;
  regra: Regra;
  viewCampos: ViewCampos;

constructor(regraCamposGroup?: RegraCamposGroup | number) {
    if (regraCamposGroup !== undefined && regraCamposGroup !== null) {
      if (typeof regraCamposGroup === 'number') {
        this.id = regraCamposGroup;
      } else {
        this.id = regraCamposGroup.id;
        this.regra = regraCamposGroup.regra;
        this.viewCampos = regraCamposGroup.viewCampos;
      }
    }
  }
}
