import { MovParteB } from 'app/ecf/model/mov-parte-b.model';
import { MovParteAIrpj } from 'app/ecf/model/mov-parte-a-irpj.model';

export class MovParteBLcto {
  id: number;
  movParteB: MovParteB;
  data: Date;
  valor: number;
  indLcto: string;
  descricao: string;
  movParteAIrpj: MovParteAIrpj;

  constructor(movparteblcto?: MovParteBLcto | number) {
    if (movparteblcto !== undefined && movparteblcto !== null) {
      if (typeof movparteblcto === 'number') {
        this.id = movparteblcto;
      } else {
        this.id = movparteblcto.id;
        this.movParteB = movparteblcto.movParteB || new MovParteB();
        this.data = movparteblcto.data;
        this.valor = movparteblcto.valor;
        this.indLcto = movparteblcto.indLcto;
        this.descricao = movparteblcto.descricao;
        this.movParteAIrpj = movparteblcto.movParteAIrpj || new MovParteAIrpj();
      }
    }
  }
}
