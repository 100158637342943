import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Empresa } from '../../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, SelectItem, MessageService } from 'primeng/api';
import { StatusOrcamento } from 'app/util/model/status-orcamento.model';
import { Versao } from '../../model/versao.model';
import { TipoOrcamento } from 'app/orcamento/model/tipo-orcamento.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Moeda } from 'app/util/model/moeda.model';
import { ConfiguracaoImportacao } from 'app/orcamento/model/configuracao-importacao.model';
import { OpcaoMoedaVersao } from 'app/orcamento/model/opcao-moeda-versao.model';
import { TreeNode } from 'primeng/api';
import { Toolbar } from 'app/shared/model/toolbar.model';
import exportExcel from 'app/util/processing/exportExcel';


@Component({
  selector: 'app-orcamento-versao',
  templateUrl: './orcamento-versao.component.html',
  styleUrls: ['./orcamento-versao.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class OrcamentoVersaoComponent implements OnInit, OnDestroy {
  ///////////////////////////////////////////
  //REFATOR POR SIMON LALLEMENT 12/06/2021//
  //////////////////////////////////////////

  private sessionSubscription: Subscription;

  //Booleans
  modalState: boolean = false;
  boolCriarStatus: boolean = false;
  boolEditStatus: boolean = false;
  clonar: boolean = false;
  origemReal: boolean = false;
  usarRealizado: boolean = false;
  foreConstant: boolean = true;
  foreConstantProp: boolean = false;
  checkedAll: boolean = false;
  btnOptions: boolean = true;
  isCardSelectOrca: boolean = true
  isCardSelectGestao: boolean = true

  //Listas
  listaOrcamentoVersao: Array<any> = new Array();
  opcoesTaxaVersao: Array<any> = new Array();
  colsListaOrcamentoVersao: Array<any> = new Array();
  listaConfig: Array<any> = new Array();
  selectedBudgets: Array<any> = new Array();

  //Dropdowns
  opcoesAno: SelectItem[] = [];
  opcoesConfigOrca: SelectItem[] = [];
  opcoesStatusOrcamento: SelectItem[] = [];
  opcoesMoedaPadrao: SelectItem[] = [];
  opcoesVersoes: SelectItem[] = [];
  opcoesAnoOrigem: SelectItem[] = [];
  opcoesMeses: SelectItem[] = [
    { "label": "Janeiro", "value": 1 },
    { "label": "Fevereiro", "value": 2 },
    { "label": "Março", "value": 3 },
    { "label": "Abril", "value": 4 },
    { "label": "Maio", "value": 5 },
    { "label": "Junho", "value": 6 },
    { "label": "Julho", "value": 7 },
    { "label": "Agosto", "value": 8 },
    { "label": "Setembro", "value": 9 },
    { "label": "Outubro", "value": 10 },
    { "label": "Novembro", "value": 11 },
    { "label": "Dezembro", "value": 12 }
  ];

  //Classes
  versao: Versao = new Versao();

  //String
  headerModal: string = null;
  titlePrincipal: string = null;
  subtitle: string = null;

  //Numbers
  versaoOrigem: number = 0;
  mesInitRealizado: number = 0;
  mesFinRealizado: number = 0;
  anoRealizado: number = 0;
  anoRealOrigem: number = 0;

  /*Messagens
  msgs: Message[] = [];
  msgsModal: Message[] = [];*/

  // Prime Tree
  versionDetails: TreeNode[] = [];
  detailSelecionados: TreeNode[] = [];
  listNiveis: Array<any> = [];
  versaoSelecionada: any;
  listVersaoOrc: Array<any>;
  listGestor: Array<any> = [];
  listVisao: any[];
  hasGestorSelected: boolean;

  //String
  currentOrcaVers: string = 'initial'
  currentStateGestao: string = 'initial'


  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private httpClient: HttpClient) {
  }

  public toolbarMain = new Toolbar()
  public toolbarMainGestao = new Toolbar()

  toolbarMainIni = [
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.carregarRegistro() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirRegistro() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.exibirModalNovoRegistro() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.exportAll() }
  ]

  toolbarMainIniGestao = [
    { icon: 'lock', text: 'Travar', tooltip: 'Travar Gestor', visible: false, disabled: false, color: 'default', onClick: () => this.lockmanager() },
    { icon: 'arrow_upward', text: 'Recolher', tooltip: 'Recolher Todos', visible: false, disabled: false, color: 'default', onClick: () => this.collapseAll() },
    { icon: 'arrow_downward', text: 'Expandir', tooltip: 'Expandir Todos', visible: true, disabled: false, color: 'default', onClick: () => this.expandAll() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.removeTreeNode() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideGestao()
        this.changeStateGestao()

      }
    },
  ]

  ngOnInit(): void {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarMainGestao.setAll(this.toolbarMainIniGestao)
    this.toolbarService.hidden = true;
    this.instanciarVersao();
    this.titleService.title = 'Versão Orçamento';
    this.titlePrincipal = 'CONFIGURAÇÕES ORÇAMENTO';
    this.subtitle = 'Versão do Orçamento';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });

    this.colsListaOrcamentoVersao = [
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'tipo', header: 'TIPO' },
      { field: 'ano', header: 'ANO' },
      { field: 'config_descricao', header: 'CONFIGURAÇÃO' },
      { field: 'status', header: 'STATUS' },
      { field: 'dt_encerramento', header: 'DATA ENCERRAMENTO' },
      { field: 'usuario', header: 'USUÁRIO' },
    ];
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  instanciarVersao(): void {
    this.versao = new Versao();
    this.versao.moeda = new Moeda();
    this.versao.statusOrcamento = new StatusOrcamento();
    this.versao.configVersao = new ConfiguracaoImportacao();
    this.versao.configVersao.tipoOrcamento = new TipoOrcamento();
    this.versao.taxaBudgetId = new OpcaoMoedaVersao();
    this.versao.taxaRealizadoId = new OpcaoMoedaVersao();
    this.versao.empresa = new Empresa();
  }

  instanciarConfig(): void {
    this.listaConfig.forEach(element => {
      if (element.id === this.versao.configVersao.id) {
        this.versao.configVersao.tipoOrcamento.id = element.tipo_id;
      }
    });
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected
      && this.dblinkedSessionService.hasPeriodoSelected
      && this.dblinkedSessionService.periodo.year.value
      && this.dblinkedSessionService.periodo.month.value
      && this.sessionService.loggedUser.id) {

      this.selectedBudgets = new Array<any>();

      this.carregarOrcamentoVersoes();
      this.comboAno();
      this.carregarConfigOrcamento();
      this.comboStatus();
      this.comboMoedas();
      this.comboTaxaVersao();
      this.getVersaoOrca();
      this.comboAnoReal();
      this.handleSelect();
    }

  }

  comboMoedas() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'favorite': 'true'
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'util/moeda', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.displayMessage(res);
            //this.exibirMensagem(res);

          } else {
            this.opcoesMoedaPadrao = [];
            res["data"].forEach(element => { this.opcoesMoedaPadrao.push({ label: element.codigo, value: element.id }) });
            this.httpService.done();
          }
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagem({ "error": true, "mensagem": "Não foi possível carregar a lista de moedas." });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getVersaoOrca() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/versao', httpOptions)
      .subscribe(
        res => {
          this.opcoesVersoes = [];
          res["data"].forEach(element => { this.opcoesVersoes.push({ "label": element.descricao, "value": element.id }) });
          this.httpService.done();
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagem({ "error": true, "mensagem": "Não foi possível carregar a lista das versões." });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /*exibirMensagem(value: any) {

    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  exibirMensagemModal(value: any) {
    this.msgsModal = [];
    if (!value.error) {
      this.msgsModal.push({ severity: 'success', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.msgsModal.push({ severity: 'warn', summary: 'Erro', detail: value.mensagem });
    }
  }*/

  carregarOrcamentoVersoes() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        ano: this.dblinkedSessionService.periodo.year.value.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/versao', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.displayMessage(res);
            //this.exibirMensagem(res);

          } else {
            this.listaOrcamentoVersao = [];
            res["data"].forEach(element => { this.listaOrcamentoVersao.push(element) });

            this.handleSelect();
          }
          this.httpService.done();
        },
        // err => {
        //   this.displayMessage(err);
        //   //this.exibirMensagem({ "error": true, "mensagem": "Não foi possível carregar as versões dessa empresa:" + err.message });
        //   console.log(err);
        //   this.httpService.done();
        // }
      );
  }

  comboStatus() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/status', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.displayMessage(res);
            //this.exibirMensagem(res);

          } else {
            this.opcoesStatusOrcamento = [];
            res["data"].forEach(element => { this.opcoesStatusOrcamento.push({ label: element.descricao, value: element.id }) });
          }
          this.httpService.done();
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagem({ "error": true, "mensagem": "Não foi possível carregar os status de orçamento." });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  comboTaxaVersao() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/opcao-taxa', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.displayMessage(res);
            //this.exibirMensagem(res);

          } else {
            this.opcoesTaxaVersao = [];
            res["data"].forEach(element => { this.opcoesTaxaVersao.push(element) });
          }
          this.httpService.done();
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagem({ "error": true, "mensagem": "Não foi possível carregar os tipos de taxa." });
          console.log(err);
          this.httpService.done();
        }
      );

  }

  comboAno() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/ano', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.displayMessage(res);
            //this.exibirMensagem(res);

          } else {
            this.opcoesAno = [];
            res["data"].forEach(element => { this.opcoesAno.push({ label: element.ano, value: element.ano }) });
          }
          this.httpService.done();
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagem({ "error": true, "mensagem": "Não foi possível carregar os períodos dessa empresa." });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  comboAnoReal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/ano-real', httpOptions)
      .subscribe(
        res => {
          this.opcoesAnoOrigem = [];
          res["data"].forEach(element => { this.opcoesAnoOrigem.push({ "label": element.ano_ref, "value": element.ano_ref }) });
          this.httpService.done();
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagem({ "error": true, "mensagem": "Não foi possível carregar os anos de realizado dessa empresa." });
          console.log(err);
          this.httpService.done();
        }
      );
  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {

  }

  cancelar() {
    this.modalState = false;
    this.boolCriarStatus = false;
    this.boolEditStatus = false;
  }

  criarNovoRegistro() {
    this.comboAnoReal()

    if (this.validacao()) {
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        origreal: this.origemReal.toString(),
        anoorigem: this.anoRealOrigem.toString(),
        versaoorigem: this.versaoOrigem.toString(),
        userid: this.sessionService.loggedUser.id.toString(),
        usarrealizado: this.usarRealizado.toString(),
        anorealizado: this.anoRealizado.toString(),
        mesinitreal: this.mesInitRealizado.toString(),
        mesfinreal: this.mesFinRealizado.toString(),
        constante: this.foreConstant.toString(),
        clonar: this.clonar.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'orcamento/versao', this.versao, httpOptions)
      .subscribe(
        res => {
          if (!res["error"]) {
            this.boolCriarStatus = false;
            this.carregarOrcamentoVersoes();
          }
          this.displayMessage(res);
          this.httpService.done();
          this.change()
          this.closeDialog()
        },
      );
  }

  handleSelect(): void {
    this.toolbarMain.setVisible("btn1", this.selectedBudgets.length  === 1 ? true : false);
    this.toolbarMain.setVisible("btn2", this.selectedBudgets.length  >= 1 ? true : false);
  }

  handleSelectManagement(): void {
    this.toolbarMainGestao.setVisible("btn1", this.detailSelecionados.length >= 1 ? true : false);
    this.toolbarMainGestao.setVisible("btn4", this.detailSelecionados.length >= 1 ? true : false);
    this.controlNodeState();
  }

  handleExpandNode(): void {
    this.controlNodeState();
  }

  handleColapseNode(): void {
    this.toolbarMainGestao.setVisible("btn2", false);
    this.toolbarMainGestao.setVisible("btn3", true);
  }

  controlNodeState(): void {
    let isExpanded: boolean = false;
    this.versionDetails.forEach(node => {
      node.children.forEach(nodeChildren => {
        if(nodeChildren.children.length === 0) {
          if(node.expanded) {
            isExpanded = true;
            return isExpanded
          } else {
            isExpanded = false;
            return isExpanded
          }
        } else {
          nodeChildren.children.forEach(e => {
            if(e.expanded) {
              isExpanded = true;
              return isExpanded
            } else {
              isExpanded = false;
              return isExpanded
            }
          })
        }
      });
    });
    this.toolbarMainGestao.setVisible("btn2", isExpanded ? true : false);
    this.toolbarMainGestao.setVisible("btn3", !isExpanded ? true : false);
  }

  selectTodos() {
    this.checkedAll = !this.checkedAll;
    if (this.checkedAll) {
      this.listaOrcamentoVersao.forEach(element => {
        element.selected = true;
        this.handleSelect();
      });

    } else {
      this.listaOrcamentoVersao.forEach(element => {
        element.selected = false;
        this.handleSelect();
      });
    }
  }

  excluirRegistro() {
    if (confirm('Deseja excluir o(s) registro(s) selecionado(s)?')) {
      let arrayIds = new Array<any>();
      for(let version of this.selectedBudgets) {
        arrayIds.push(version.id)
      }

      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
          versaoid: arrayIds
        })
      };

      this.httpService.wait();
      this.httpClient.delete(this.configService.defaultUrlApiUpload + 'orcamento/versao', httpOptions)
        .subscribe(
          res => {
            if (!res["error"]) {
              this.boolEditStatus = false;
              this.carregarOrcamentoVersoes();
            }
            this.displayMessage(res);
            //this.exibirMensagem(res)
            this.httpService.done();
          },
          err => {
            this.displayMessage(err);
            //this.exibirMensagemModal({ "error": true, "mensagem": "Erro no API: não foi possível excluir a(s) versão(ões) selecionada(s)." });
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  carregarRegistro() {
    this.modalState = true;
    this.boolCriarStatus = false;
    this.boolEditStatus = true;
    for (let line of this.selectedBudgets) {
      if (line.id === this.selectedBudgets[0].id) {
        this.versao = new Versao();
        this.versao.ano = line.ano;
        this.versao.configVersao = new ConfiguracaoImportacao(line.configuracao_importacao_id);
        this.versao.configVersao.tipoOrcamento = new TipoOrcamento(line.tipo_id);
        this.versao.descricao = line.descricao;
        this.versao.empresa = new Empresa(line.empresa_id);
        this.versao.id = line.id;
        this.versao.moeda = new Moeda(line.moeda_id);
        this.versao.statusOrcamento = new StatusOrcamento(line.status_orcamento_id);
        this.versao.taxaBudgetId = new OpcaoMoedaVersao(line.taxa_budget_id);
        this.versao.taxaRealizadoId = new OpcaoMoedaVersao(line.taxa_realizado_id);
        this.headerModal = "Editar versão " + this.versao.descricao;
        this.clonar = line.clonar;
        this.origemReal = line.origem_real;
        this.versaoOrigem = line.versao_origem;
        this.anoRealOrigem = line.ano_origem;
        this.usarRealizado = line.usar_realizado;
        this.anoRealizado = line.ano_real;
        this.mesInitRealizado = line.mes_min_forecast;
        this.mesFinRealizado = line.mes_max_forecast;
        this.foreConstant = line.constant;
        this.foreConstantProp = !this.foreConstant;
        break;
      }
    }
  }

  editarRegistro() {
    if (this.validacao()) {
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        userid: this.sessionService.loggedUser.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'orcamento/versao', this.versao, httpOptions)
      .subscribe(
        res => {
          if (!res["error"]) {
            this.carregarOrcamentoVersoes();
          }
          this.displayMessage(res);
          //this.exibirMensagem(res)
          this.httpService.done();
          this.change();
          this.closeDialog();
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagemModal({ "error": true, "mensagem": "Erro no API: não foi possível editar a versão de orçamento." });
          console.log(err);
          this.httpService.done();
        }
      );
  }


  exibirModalNovoRegistro() {
    this.modalState = true;
    this.boolCriarStatus = true;
    this.boolEditStatus = false;
    this.headerModal = "Nova versão";
    this.instanciarVersao();
    this.versao.empresa.id = this.dblinkedSessionService.empresa.id;
    this.versao.statusOrcamento.id = 1;
    this.versao.moeda.id = 1;
    this.versao.taxaBudgetId.id = 1;
    this.versao.taxaRealizadoId.id = 1;
    this.clonar = false;
    this.origemReal = false;
    this.anoRealOrigem = 0;
    this.versaoOrigem = 0;
    this.usarRealizado = false;
    this.anoRealizado = 0;
    this.mesInitRealizado = 0;
    this.mesFinRealizado = 0;
    this.foreConstant = true;
    this.foreConstantProp = false;

  }

  singleConstant() {
    this.foreConstant = false
  }
  singleConstantProp() {
    this.foreConstantProp = false
  }


  carregarConfigOrcamento() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/config', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.displayMessage(res);
            //this.exibirMensagem(res)

          } else {
            this.opcoesConfigOrca = [];
            this.listaConfig = new Array();
            res["data"].forEach(element => {
              this.opcoesConfigOrca.push({ label: element.descricao, value: element.id });
              this.listaConfig.push(element);
            });
          }

          this.httpService.done();
        },
        err => {
          this.displayMessage(err);
          //this.exibirMensagem({ "error": true, "mensagem": "Não foi possível carregar as configurações de versão." })
          console.log(err);
          this.httpService.done();
        }
      );
  }

  calculoTipoChange(event, id) {
    id === 0 ? this.foreConstantProp = !event : this.foreConstant = !event;
  }

  validacao() {

    if (this.versao.ano === null || this.versao.ano === undefined || this.versao.ano === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O campo Ano é obrigatório!' });
      //this.exibirMensagemModal({ "error": true, "mensagem": 'O campo Ano é obrigatório!' });
      return true;
    }
    if (this.versao.configVersao.id === null || this.versao.configVersao.id === undefined || this.versao.configVersao.id === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O campo Configuração é obrigatório!' });
      //this.exibirMensagemModal({ "error": true, "mensagem": 'O campo Configuração é obrigatório!' });
      return true;
    }
    if (this.versao.descricao === null || this.versao.descricao === undefined || this.versao.descricao.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O campo Descrição é obrigatório!' });
      //this.exibirMensagemModal({ "error": true, "mensagem": 'O campo Descrição é obrigatório!' });
      return true;
    }
    if (this.versao.statusOrcamento.id === null || this.versao.statusOrcamento.id === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O campo Status é obrigatório!' });
      //this.exibirMensagemModal({ "error": true, "mensagem": 'O campo Status é obrigatório!' });
      return;
    }
    if (this.versao.moeda.id === null || this.versao.moeda.id === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O campo Moeda é obrigatório!' });
      //this.exibirMensagemModal({ "error": true, "mensagem": 'O campo Moeda é obrigatório!' });
      return true;
    }
    if (this.clonar === true) {
      if ((this.origemReal === true && ((this.anoRealOrigem === null || this.anoRealOrigem == undefined) || (this.anoRealOrigem === 0))) || (this.usarRealizado === true && ((this.versaoOrigem === null || this.versaoOrigem === undefined) || (this.versaoOrigem === 0))) || (this.usarRealizado === false && this.origemReal === false && ((this.versaoOrigem === null || this.versaoOrigem === undefined) || (this.versaoOrigem === 0)))) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O campo Origem é obrigatório!' });
        //this.exibirMensagemModal({ "error": true, "mensagem": 'O campo Origem é obrigatório!' });
        return true;
      }
    }
    if (this.clonar === true && this.usarRealizado === true) {
      if ((this.anoRealizado === null || this.anoRealizado === undefined) || (this.anoRealizado === 0)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O campo Ano do Realizado é obrigatório!' });
        //this.exibirMensagemModal({ "error": true, "mensagem": 'O campo Ano do Realizado é obrigatório!' });
        return true;
      } if ((this.mesInitRealizado === null || this.mesInitRealizado === undefined) || (this.mesInitRealizado === 0)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O campo Mês Inicial é obrigatório!' });
        //this.exibirMensagemModal({ "error": true, "mensagem": 'O campo Mês Inicial é obrigatório!' });
        return true;
      } if ((this.mesFinRealizado === null || this.mesFinRealizado === undefined) || (this.mesFinRealizado === 0)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'O campo Mês Final é obrigatório!' });
        //this.exibirMensagemModal({ "error": true, "mensagem": 'O campo Mês Final é obrigatório!' });
        return true;
      }
    }
    return false;
  }

  ShowHideGestao() {
    this.isCardSelectGestao = !this.isCardSelectGestao;
    this.toolbarMainGestao.setIcon('btn5', this.isCardSelectGestao ? 'expand_less' : 'expand_more')
    this.toolbarMainGestao.setTooltip('btn5', this.isCardSelectGestao ? 'Ver menos' : 'Ver mais')
  }

  changeStateOrcaVers() {
    this.currentOrcaVers = this.currentOrcaVers === 'initial' ? 'final' : 'initial'
  }

  changeStateGestao() {
    this.currentStateGestao = this.currentStateGestao === 'initial' ? 'final' : 'initial';
  }

  closeDialog() {
    this.modalState = false;
    this.boolCriarStatus = false;
    this.boolEditStatus = false;
  }

  // NgPrime Tree functions

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  searchRecursive(children, listIgnore) {
    for (let child of children) {
      listIgnore.push(child);

      if (child.children != null && child.children.length > 0) {
        this.searchRecursive(child.children, listIgnore);
      }
    }
  }

  expandAll() {
    this.versionDetails.forEach(node => {
      this.expandRecursive(node, true);
      this.btnOptions = false;
    });
    this.toolbarMainGestao.setVisible('btn2', true)
    this.toolbarMainGestao.setVisible('btn3', false)
  }

  collapseAll() {
    this.versionDetails.forEach(node => {
      this.expandRecursive(node, false);
      this.btnOptions = true;
    });
    this.toolbarMainGestao.setVisible('btn2', false)
    this.toolbarMainGestao.setVisible('btn3', true)
  }

  getVersoesOrcamento() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'ano': this.dblinkedSessionService.periodo.year.value.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/get-versoes-orcamento-gestao', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.displayMessage(res);
            //this.exibirMensagem(res);
          }

          this.listVersaoOrc = res['mensagem'];
          this.httpService.done();
        }, err => {
          console.log(err);
          this.displayMessage(err);
          //this.exibirMensagem(err);
          this.httpService.done();
        });
  }

  removeTreeNode() {
    if (!confirm('Deseja realmente deletar os itens selecionados?')) return;
    if (this.detailSelecionados.length < 1) return this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione ao menos um registro!' });

    let highestNivel = this.listNiveis.length - 1;
    let parentNodes = [];
    let listPath = [];

    for (let node of this.detailSelecionados) {
      let nivel = this.listNiveis.findIndex(n => n.key_id === node.data[0]);

      if (nivel < highestNivel) {
        parentNodes = new Array();
        highestNivel = nivel;
        parentNodes.push(node);
      } else if (nivel === highestNivel) {
        parentNodes.push(node);
      }
    }

    //nós que já serão deletados
    let nodesToIgnore = [];
    for (let parent of parentNodes) {
      this.searchRecursive(parent.children, nodesToIgnore);
      listPath.push(parent.data[3]);
    }

    for (let node of this.detailSelecionados) {
      let nivel = this.listNiveis.findIndex(n => n.key_id === node.data[0]);
      if (nivel === highestNivel) continue;

      if (nodesToIgnore.indexOf(node) < 0) {
        listPath.push(node.data[3]);
      }
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
      })
    };

    const infos = {
      versaoid: this.versaoSelecionada.id,
      valuesToRemove: listPath
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'orcamento/delete-valores-orcamento-gestao', infos, httpOptions)
      .subscribe(
        res => {
          this.displayMessage(res);
          //this.exibirMensagem(res);

          if (!res["error"]) {
            this.getVersoesOrcamento();
            this.versaoSelecionada = null;
          }

          this.httpService.done();
        }, err => {
          console.log(err);
          this.displayMessage(err);
          //this.exibirMensagem(err);
          this.httpService.done();
        }
      )
  }

  lockmanager() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
      })
    };

    const infos = {
      versaoid: this.versaoSelecionada.id,
      userid: this.sessionService.loggedUser.id,
      listGestor: this.listGestor
    };

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'orcamento/status-gestor-versao', infos, httpOptions)
      .subscribe(
        res => {
          this.displayMessage(res);
          //this.exibirMensagem(res);

          if (!res["error"]) {
            this.listGestor = new Array();
            this.onSelectVersao(this.versaoSelecionada);
          }

          this.httpService.done();
        }, err => {
          console.log(err);
          this.displayMessage(err);
          //this.exibirMensagem(err);
          this.httpService.done();
        }
      )
  }

  onSelectVersao(row) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'versaoid': row.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/get-valores-orcamento-gestao', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.versaoSelecionada = null;
            this.displayMessage(res);
            //this.exibirMensagem(res);
            this.httpService.done();
            return;
          }

          this.listVisao = new Array();
          this.listNiveis = new Array();
          this.detailSelecionados = new Array();
          this.listGestor = new Array();
          this.hasGestorSelected = false;
          this.listNiveis = res["liniveis"];
          this.versionDetails = res["mensagem"]["data"];
          this.versaoSelecionada = row;

          let listVisao = {};
          for (let nivel of res["liniveis"]) {
            listVisao[nivel['key']] = 0;
          }

          this.listVisao.push(listVisao);
          this.httpService.done();
        }, err => {
          console.log(err);
          this.displayMessage(err);
          //this.exibirMensagem(err);
          this.httpService.done();
        });

    this.collapseAll();
  }

  nodeSelect(event) {
    this.listGestor = new Array();
    for (let node of this.detailSelecionados) {
      if (node.data != null && node.data[0] === 'gestor_id') {
        let editavel = node.icon != null;
        this.listGestor.push([node.data[1], editavel]);
        this.hasGestorSelected = true;
      }
    }
    this.handleSelectManagement();
  }


  nodeUnselect(event) {
    if (event.node.data != null && event.node.data[0] === "gestor_id") {
      let idx = this.listGestor.findIndex(info => info[0] === event.node.data[1]);
      this.listGestor.splice(idx, 1);
      this.hasGestorSelected = this.listGestor.length > 0;
    }
    this.handleSelectManagement();
  }

  //novo método para exibir resposta
  displayMessage(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  exportAll(): void {
    const orderList = ['descricao', 'tipo', 'ano', 'configuracao', 'status', 'dataEncerramento', 'usuario']
    let aux = [];
    this.listaOrcamentoVersao.forEach(item => {
      aux.push({
        descricao: item.descricao,
        tipo: item.tipo,
        ano: item.ano,
        configuracao: item.config_descricao,
        status: item.status,
        dataEncerramento: item.dt_encerramento,
        usuario: item.usuario
      });
    });
    exportExcel(aux, "Versão Orçamento", orderList);
  }
}
