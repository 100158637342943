import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel'
import { Toolbar } from 'app/shared/model/toolbar.model'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpService } from 'app/shared/services/http.service'
import { SessionService } from 'app/shared/services/session.service'
import { SidebarService } from 'app/shared/services/sidebar.service'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { MessageService, SelectItem } from 'primeng/api'
import { Subscription } from 'rxjs'
import exportExcel from 'app/util/processing/exportExcel'
import { Table } from 'primeng/table'
import { PysocketService } from 'app/shared/services/socket/pysocket.service'
import { TabChanger } from 'app/shared/components/dashboards/components/interfaces/dash-changer'

@Component({
  selector: 'app-requisicao-material',
  templateUrl: './requisicao-material.component.html',
  styleUrls: ['./requisicao-material.component.scss'],
})

export class RequisicaoMaterialComponent implements OnInit, OnDestroy {

  protected sessionSubscription: Subscription

  selectedRequisitions: Array<any> = new Array()
  tableDataOutflow: Array<any> = new Array()
  tableDataInflow: Array<any> = new Array()
  exportOrderList: Array<any> = new Array()
  tabMovement: Array<TabChanger> = new Array()

  requisitionSelected: Object = new Object()

  dateIni: Date = new Date()
  dateFin: Date = new Date()
  selectedDateIni: Date = new Date()
  selectedDateFin: Date = new Date()
  defaultDate: Date = new Date()

  selectedDate: string
  tabMovementSelected: string

  userId: number = null
  empresaId: number = null
  matrizId: number = null
  totalEntradas: number = 0
  totalSaidas: number = 0

  showFilter: boolean
  formNewRequisition: boolean
  isGestor: boolean
  startSaveRMS: boolean = false
  isEditRMS: boolean = false
  startEditRMS: boolean = false
  isDisabled: boolean = false
  bothFlowsSearched: boolean
  loadingTable: boolean = true
  hasTimeCourse: boolean = false

  // Filtro das tabelas
  applicantsTable: SelectItem[] = []
  prioritiesTable: SelectItem[] = []
  statusTable: SelectItem[] = []
  destinysTable: SelectItem[] = []
  objectsTable: SelectItem[] = []
  approversTable: SelectItem[] = []

  applicantsFilterTable: any
  prioritiesFilterTable: any
  statusFilterTable: any
  destinysFilterTable: any
  objectFilterTable: any
  approversFilterTable: any
  creationDataFilterTable: any
  deliverynDataFilterTable: any
  keyFilterTable: any
  titleFilterTbale: any
  valueFilterTable: any

  @ViewChild('dt') tableRequisitions: Table

  toolbarMainIni = [
    { key: 'btn-export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar excel', visible: true, disabled: false, color: 'default', onClick: () => exportExcel(this.tabMovementSelected == 's' ? this.tableDataOutflow : this.tableDataInflow, 'requisições de material', this.exportOrderList) },
    { key: 'btn-refresh', icon: 'update', text: 'Atualizar', tooltip: 'Atualizar RMS', visible: true, disabled: false, color: 'default', onClick: () => { this.resetVars(false), this.getRequisitions() } },
    { key: 'btn-approve', icon: 'checklist', text: 'Solicitar aprovação', tooltip: 'Solicitar aprovação', visible: false, disabled: false, color: 'default', onClick: () => this.solicitingApprovingRMS() },
    { key: 'btn-delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.deletingRMS() },
    { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.showFormRequisition(true, true) },
    { key: 'btn-filter', icon: 'filter_alt', text: 'Filtrar', tooltip: 'Filtrar', visible: true, disabled: false, color: 'default', onClick: () => this.showFilterModal(true) },
    { key: 'btn-new', icon: 'add', text: 'Nova', tooltip: 'Nova requisição', visible: true, disabled: false, color: 'green', onClick: () => this.showFormRequisition(true) },
    { key: 'btn-cancel', icon: 'clear', text: 'Cancelar', tooltip: 'Cancelar', visible: false, disabled: false, color: 'default', onClick: () => this.cancelForm() },
    { key: 'btn-side', icon: 'view_sidebar', text: 'Sidebar', tooltip: 'Abrir sidebar', visible: false, disabled: false, color: 'default', onClick: () => {} },
    { key: 'btn-save', icon: 'save', text: 'Salvar', tooltip: 'Salvar requisição', visible: false, disabled: false, color: 'green', onClick: () => this.savingRMS() },
    { key: 'btn-loading', icon: 'hourglass_full', text: 'Carregando...', tooltip: '', visible: false, disabled: true, color: 'default', onClick: () => {} }
  ]

  btnArr: Array<string> = ['btn-export', 'btn-refresh', 'btn-filter', 'btn-new', 'btn-cancel', 'btn-approve', 'btn-delete', 'btn-edit', 'btn-save', 'btn-side']

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'DT. CRIAÇÃO', size: '10%', textAlign: 'center' },
      { name: 'DT. ENTREGA', size: '10%', textAlign: 'center' },
      { name: 'KEY', size: '5%', textAlign: 'center' },
      { name: 'DESTINO', size: '10%', textAlign: 'center' },
      { name: 'OBJETO', size: '10%', textAlign: 'center' },
      { name: 'TÍTULO', size: '20%', textAlign: 'left' },
      { name: 'PRIORIDADE', size: '10%', textAlign: 'left' },
      { name: 'REQUISITANTE', size: '12%', textAlign: 'left' },
      { name: 'APROVADOR', size: '12%', textAlign: 'left' },
      { name: 'VALOR (R$)', size: '10%', textAlign: 'right' },
      { name: 'STATUS', size: '12%', textAlign: 'center' },
      { name: 'AÇÃO', size: '45px', textAlign: 'center' },
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true,
  })

  public toolbarMain = new Toolbar()

  constructor(
    protected toolbarService: ToolbarService,
    protected dblinkedSessionService: DblinkedSessionService,
    protected sessionService: SessionService,
    protected httpService: HttpService,
    protected configService: ConfigService,
    protected sidebarService: SidebarService,
    protected messageService: MessageService,
    protected socketService: PysocketService
  ) { this.toolbarService.hidden = true }

  public ngOnInit(): void {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.hasTimeCourse = false

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,() => {
      if(this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
        this.hasTimeCourse = true
        this.startTabs()
        this.initialize()
      }
    })
  }

  public ngOnDestroy(): void {
    this.toolbarService.hidden = false
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  public get rowsPerPage(): Array<any> {
    return this.configService.applicationConfig.rowsPerPage
  }

  public get rows(): number {
    return this.configService.applicationConfig.rows
  }

  public get pageLinks(): number {
    return this.configService.applicationConfig.pageLinks
  }

  private initialize(): void {
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.userId = this.sessionService.loggedUser.id
    this.empresaId = this.dblinkedSessionService.empresa.id
    this.matrizId = this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id
    this.exportOrderList = ['_dtCriacao', '_dtEntrega', 'key', 'tipo_pedido', 'objeto_descr', 'titulo', 'prioridade_nome', 'requisitante_nome', 'labelAprovador', 'valor', 'status']


    this.resetVars(true)
    this.showFormRequisition(false)

    this.getDefaultDateFilter()


  }

  private resetVars(resetDate: boolean): void {
    this.loadingTable = true
    this.showFilter = false
    this.bothFlowsSearched = false
    this.totalEntradas = 0
    this.totalSaidas = 0

    if(resetDate && this.dblinkedSessionService.periodo.year && this.dblinkedSessionService.periodo.month) {
      this.dateIni = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dateFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
    }

    if (this.dblinkedSessionService.usuario.nivel && this.dblinkedSessionService.usuario.nivel > 4) this.isGestor = true
    else this.isGestor = false

    this.selectedRequisitions = new Array()
  }

  private async getRequisitions(hasStartLoading: boolean = true): Promise<void> {
    let dateIniStr: string = this.dateIni.getFullYear() + "-" + (this.dateIni.getMonth() + 1) + "-" + this.dateIni.getDate()
    let dateFinStr: string = this.dateFin.getFullYear() + "-" + (this.dateFin.getMonth() + 1) + "-" + this.dateFin.getDate()
    this.loadingTable = true

    const payload = [ dateIniStr, dateFinStr, this.empresaId, this.userId, this.selectedDate, this.tabMovementSelected]

    this.resetFilters()

    if(hasStartLoading) this.skeletonConfig.startLoad()

    try {
      await this.httpService.get('custom/rms/listar-rms', payload).toPromise().then(res => {
        if(!res.error) {

            res.totalRegistros.map(item => {
              this.totalEntradas = ((item.entrada == null) ? 0 : item.entrada)
              this.totalSaidas = ((item.saida == null) ? 0 : item.saida)
            })

          if (this.tabMovementSelected == 's'){
            this.tableDataOutflow = new Array()

            this.tableDataOutflow = res.rms.map(item => {
              item.labelAprovador = this.setApproverLabel(item)
              item._dtCriacao = this.convertTimestamp(item.dtCriacao)
              item._dtEntrega = this.convertTimestamp(item.dtEntrega)

              return item
            })
            this.tableDataOutflow.forEach(item => {this.setTableFilters(this.tabMovementSelected,item)})
          }

          else{
            this.tableDataInflow = new Array()

            this.tableDataInflow = res.rms.map(item => {
              item.labelAprovador = this.setApproverLabel(item)
              item._dtCriacao = this.convertTimestamp(item.dtCriacao)
              item._dtEntrega = this.convertTimestamp(item.dtEntrega)

              return item
            })
            this.tableDataInflow.forEach(item => {this.setTableFilters(this.tabMovementSelected,item)})
          }

        } else this.showResponse('error', res.message)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregas as requisições! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.skeletonConfig.endLoad()
      this.loadingTable = false
    }
  }

  private savingRMS(): void {
    this.startSaveRMS = true
  }

  private editingRMS(): void {
    this.startEditRMS = true
  }

  private async deletingRMS(): Promise<void> {
    if (confirm('Deseja excluir as requisições selecionadas?')) {
      const payload = { idRequisicao: this.selectedRequisitions.map(item => item.id) }
      this.loadingToolbar()

      try {
        await this.httpService.post('/custom/rms/rms-excluirAll', payload).toPromise().then(res => {
          if(!res.error) this.getRequisitions()
          this.showResponse((res.error ? 'error' : 'success'), res.message)
        }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao deletar as requisições! Contate o suporte'))
      } catch(err) {
        this.showResponse('error', 'Falha na comunicação com o servidor!')
      } finally {
        this.resetVars(false)
        this.changeToolbar()
      }
    }
  }

  private async solicitingApprovingRMS(): Promise<void> {
    this.loadingToolbar()

    const payload = {
      empresaId: this.empresaId,
      origemId: 3,
      itemId: this.selectedRequisitions.map(item => { return item.id }),
      userId: this.sessionService.loggedUser.id
    }

    try {
      await this.httpService.post('/custom/workflow/criar-card-aprovacao', payload).toPromise().then(res => {
        this.showResponse((res.error ? 'error' : 'success'),  res.response || res.message || res.mensagem || res.msg)

        if (!res.error) {
          this.getRequisitions()
          const username = this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome
          this.socketService.requestApproval(res.listTodoCardId, res.listNotificacaoId, this.sessionService.loggedUser.id, username)
        }
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao solicitar aprovação das requisições! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.resetVars(false)
      this.changeToolbar()
    }
  }

  public async finishedSavingRMS(event): Promise<void> {
    await this.delay(10)

    this.startSaveRMS = false
    if(event.finished) {
      this.getRequisitions()
      this.showFormRequisition(false)
      this.showResponse('success', event.message)
    }
  }

  public async finishedEditingRMS(event): Promise<void> {
    await this.delay(10)

    this.startEditRMS = false
    if(event.finished) {
      await this.getRequisitions()
      this.showFormRequisition(false)
      this.showResponse('success', event.message)
    }
  }

  async getDefaultDateFilter(): Promise<void> {
    this.skeletonConfig.startLoad()
    this.loadingTable = true

    try {
      await this.httpService.get(`/custom/rms/recuperar-data-padrao-rms(${this.matrizId})`).toPromise().then((res: any) => {
        if(!res.error) this.selectedDate = res.data == 0 ? 'deliveryDay' : 'creationDay'
      })
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar o filtro de data padrão! Contate o suporte' })
    } finally {
      this.getRequisitions(false)
    }
  }

  public rowSidebar(todoCardAprovacaoId: number): void {
    this.sidebarService.rowSelected([todoCardAprovacaoId])
    this.sidebarService.open(true)
    this.sidebarService.selectTab(0)
  }

  public changeToolbar(): void {
    this.toolbarMain.setVisible('btn-export', !this.formNewRequisition)
    this.toolbarMain.setVisible('btn-refresh', !this.formNewRequisition)
    this.toolbarMain.setVisible('btn-filter', !this.formNewRequisition)
    this.toolbarMain.setVisible('btn-new', !this.formNewRequisition)
    this.toolbarMain.setVisible('btn-cancel', this.formNewRequisition)
    this.toolbarMain.setVisible('btn-delete', this.selectedRequisitions.length > 0)
    this.toolbarMain.setVisible('btn-edit', this.selectedRequisitions.length === 1)

    this.toolbarMain.setVisible('btn-save', this.formNewRequisition)
    this.toolbarMain.setTooltip('btn-save', this.isEditRMS ? 'Salvar alterações' : 'Salvar')
    this.toolbarMain.setClick('btn-save', () => { this.isEditRMS ? this.editingRMS() : this.savingRMS()})

    this.toolbarMain.setVisible('btn-side', (this.formNewRequisition && this.isEditRMS))
    this.toolbarMain.setClick('btn-side', () => this.rowSidebar(this.requisitionSelected['todoCardAprovacaoId']))

    this.toolbarMain.setVisible('btn-approve', this.selectedRequisitions.length > 0 && !(this.selectedRequisitions.filter(item => item.status_id && ![2, 10].includes(item.status_id)).length > 0))

    this.toolbarMain.setVisible('btn-loading', false)

    this.isDisabled = false
  }

  private loadingToolbar(): void {
    this.isDisabled = true

    this.btnArr.forEach(item => {
      this.toolbarMain.setVisible(item, false)
    })
    this.toolbarMain.setVisible('btn-loading', true)
  }

  public disabledToolbar(disabled: boolean): void {
    this.btnArr.forEach(item => {
      this.toolbarMain.setDisabled(item, disabled)
    })
  }

  private showFormRequisition(show: boolean, edit: boolean = false): void {
    if(edit) {
      this.isEditRMS = true
      this.requisitionSelected = this.selectedRequisitions[0]
    }

    this.formNewRequisition = show
    this.selectedRequisitions = new Array()
    if(!show) this.startSaveRMS = false, this.isEditRMS = false, this.requisitionSelected = null
    this.changeToolbar()
    this.resetFilters()
  }

  private cancelForm(): void {
    if(confirm('Deseja cancelar? Todas suas alterações serão perdidas!')) this.showFormRequisition(false)
  }

  public showFilterModal(show: boolean): void {
    this.showFilter = show

    if(show) {
      this.selectedDateIni = this.dateIni
      this.selectedDateFin = this.dateFin
    } else {
      this.dateIni = this.selectedDateIni
      this.dateFin = this.selectedDateFin
    }
  }

  public saveFilterModal(): void {
    this.showFilter = false
    this.resetVars(false)
    this.getRequisitions()
  }

  private setApproverLabel(arr): string {
    let res: string = 'Sem aprovador'
    if (arr.aprovadores && arr.aprovadores.length > 0) {
      arr.aprovadores = arr.aprovadores.filter(line => line.substituto === false)
      res = (arr.aprovadores.length === 1) ? arr.aprovadores[0].fullname : 'Vários'
    }
    return res
  }

  public listApprovers(data: any): String {
    let i: number = 0
    let res = 'Aprovadores: '

    if (data.length === 0) res = 'Sem aprovador'
    else if (data.length === 1) res = data[0].fullname
    else data.forEach(item => {
      i++
      res += item.fullname

      if (i !== data.length) res += ', '
    })

    return res
  }

  public formatDateExtensive(): String {
    const dt = new Date()
    const monthExtensive = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const weekExtensive = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]

    const month = monthExtensive[dt.getMonth()]
    const week = weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }

  public convertTimestamp(timestamp: any): String {
    const date = new Date(timestamp)
    date.setHours(date.getHours()+3)
    const day = date.getDate() > 9 ? date.getDate() : '0'+date.getDate()
    const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : '0'+(date.getMonth()+1)

    const fullDate = day + '/' + month + '/' + date.getFullYear()

    return fullDate
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Atenção: ', detail: message })
      break
    }
  }

  protected async delay(milliseconds: number): Promise<void>{
    return new Promise(function(resolve){ setTimeout(resolve, milliseconds) })
  }

  private setTableFilters(tab : string, item: Object): void {

      if(item['requisitante_nome']) this.applicantsTable.push({ label: item['requisitante_nome'].toUpperCase(), value: item['requisitante_nome'] })
      if(item['prioridade_nome']) this.prioritiesTable.push({ label: item['prioridade_nome'].toUpperCase(), value: item['prioridade_nome'] })
      if(item['status']) this.statusTable.push({ label: item['status'].toUpperCase(), value: item['status'] })
      if(item['tipo_pedido']) this.destinysTable.push({ label: item['tipo_pedido'].toUpperCase(), value: item['tipo_pedido'] })
      if(item['objeto_descr']) this.objectsTable.push({ label: item['objeto_descr'].toUpperCase(), value: item['objeto_descr'] })
      if(item['labelAprovador']) this.approversTable.push({ label: item['labelAprovador'].toUpperCase(), value: item['labelAprovador'] })

      let callBack = (a,b) => { return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0) }

      this.applicantsTable = this.setUniqueArr(this.applicantsTable, 'value').sort(callBack)
      this.prioritiesTable = this.setUniqueArr(this.prioritiesTable, 'value').sort(callBack)
      this.statusTable = this.setUniqueArr(this.statusTable, 'value').sort(callBack)
      this.destinysTable = this.setUniqueArr(this.destinysTable, 'value').sort(callBack)
      this.objectsTable = this.setUniqueArr(this.objectsTable, 'value').sort(callBack)
      this.approversTable = this.setUniqueArr(this.approversTable, 'value').sort(callBack)

  }

  private setUniqueArr(arr: Array<any>, parameter: string): Array<any> {
    const objectMap = arr.reduce((map, object) => {
      map.set(object[parameter], object)
      return map
    }, new Map())

    return Array.from(objectMap, ([_, value]) => value)
  }

  public startTabs(): void {

    this.tabMovementSelected = 's'
    this.bothFlowsSearched = false
  }

  private resetFilters() : void {
    this.applicantsTable = new Array()
    this.prioritiesTable = new Array()
    this.statusTable = new Array()
    this.destinysTable = new Array()
    this.objectsTable = new Array()
    this.approversTable = new Array()

    this.applicantsFilterTable = null
    this.prioritiesFilterTable = null
    this.statusFilterTable = null
    this.destinysFilterTable = null
    this.objectFilterTable = null
    this.approversFilterTable = null
    this.creationDataFilterTable = null
    this.deliverynDataFilterTable = null
    this.keyFilterTable = null
    this.titleFilterTbale = null
    this.valueFilterTable = null

    if (this.tableRequisitions) {
      this.tableRequisitions.filters = {}
      this.tableRequisitions.filteredValue = null
      this.tableRequisitions.sortField = null
      this.tableRequisitions.sortOrder = 1
    }
  }

  public changeTab(event: any) {
    this.tabMovementSelected = event
    if(!this.bothFlowsSearched) {

        this.bothFlowsSearched = true
        this.getRequisitions()
    }
  }
}