import { Component, forwardRef, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractValueAccessor } from 'app/shared/common/abstract-value-acessor';
import { HttpService } from 'app/shared/services/http.service';
import { ScrollService } from 'app/shared/services/scroll.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UploadComponent),
    multi: true
  }],
})
export class UploadComponent extends AbstractValueAccessor {
  @Input() autoupload: boolean | string = false;
  @Input() preview: boolean | string = false;
  @Input() readonly: boolean | string = false;
  @Input() chooseTip = 'Selecionar arquivo';
  @Input() anotherTip = 'Selecionar outro arquivo';
  @Input() sendTip = 'Enviar arquivo';
  @Input() clearTip = 'Cancelar';
  @Input() label = 'Upload';
  @Input() required = false; // TODO: Implement
  @Output() clear: EventEmitter<any> = new EventEmitter();
  @Output() select: EventEmitter<any> = new EventEmitter();
  @Output() upload: EventEmitter<any> = new EventEmitter();

  @ViewChild('filePreview') private filePreview: ElementRef;
  @ViewChild('uploadContainer') private uploadContainer: ElementRef;

  zoom: boolean;
  private img: any;

  private files: any;

  error = false;
  message: string;

  constructor(private httpService: HttpService,
              private scrollService: ScrollService) {
    super();
  }

  get file(): any {
    return this.files && this.files.length === 1 ? this.files[0] : null;
  }

  get fileValue(): any {
    return this.value ? this.value : '';
  }

  fileClear(fileInput?: any) {
    if (this.file || this.isAutoUpload) {
      this.clear.next(this.file);
    }

    if (fileInput) {
      fileInput.value = null;
    }

    this.value = null;
    this.files = [];
    this.updatePreview();
  }

  fileSelect(fileInput: any) {
    this.fileClear();

    fileInput.click();
  }

  fileChange(event: any) {
    try {
      this.files = event.target.files;
      this.updatePreview();

      if (this.isAutoUpload) {
        this.fileUpload(event.target);
      }
    } catch (exception) {
      this.files = [];
    }

    this.select.next(this.file);

    event.stopPropagation();
  }

  updatePreview() {
    if (this.displayPreview) {
      this.filePreview.nativeElement.src = '';

      if (this.file) {
        const reader = new FileReader();
        reader.onload = (readerEvent: any) => {
          this.filePreview.nativeElement.src = readerEvent.target.result;
        };
        reader.readAsDataURL(this.file);
      }
    }
  }

  zoomIn() {
    this.zoom = true;

    this.scrollService.update();

    setTimeout(() => {
      this.img = this.uploadContainer.nativeElement.querySelector('.zoom-container img');

      // Safari fix
      this.img.style.visibility = 'hidden';
      setTimeout(() => {
        this.img.style.visibility = 'visible';
      }, 250);
    }, 1);
  }

  zoomOut() {
    this.zoom = false;
  }

  fileUpload(fileInput: any) {
    this.files = fileInput.files;

    if (this.file) {
      this.message = null;
      this.error = false;

      const data: FormData = new FormData();
      data.append('file', this.file);

      this.httpService.wait();
      this.httpService.post('/file', data)
                      .subscribe(result => {
                                   this.message = result.message;

                                   if (!result.error) {
                                     this.value = result.url;
                                     this.upload.next(this.value);

                                     if (!this.message) {
                                       this.message = 'Arquivo enviado com sucesso.';
                                     }
                                   } else {
                                     this.fileClear();

                                     if (!this.message) {
                                       this.message = 'Erro ao enviar arquivo.';
                                     }
                                   }
                                 },
                                 error => this.httpService.handleError(error, () => this.fileUpload(fileInput)),
                                 () => {
                                   fileInput.value = null;
                                   this.httpService.done();
                                 });
    }
  }

  get top(): number {
    return this.scrollService.top;
  }

  get left(): number {
    return this.scrollService.left;
  }

  get imgTop(): number {
    return this.img ? (this.scrollService.height / 2) - (this.img.offsetHeight / 2) : 0;
  }

  get imgLeft(): number {
    return this.img ? (this.scrollService.width / 2) - (this.img.offsetWidth / 2) : 0;
  }

  get isAutoUpload() {
    return this.isTrue(this.autoupload);
  }

  get isReadOnly() {
    return this.isTrue(this.readonly);
  }

  get displayPreview() {
    return this.isTrue(this.preview);
  }

  private isTrue(value: boolean | string): boolean {
    return value && value !== 'false';
  }
}
