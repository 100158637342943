import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Participante } from '../../cadastro/model/participante.model';
import { ParticipanteEmpresa } from '../../cadastro/model/participante-empresa.model';
import { Item } from '../../cadastro/model/item.model';
import { TipoDesconto } from '../../util/model/tipo-desconto.model';
import { OperacaoRegra } from '../../cadastro/model/operacao-regra.model';
import { PedidoStatus } from '../../financeiro/model/pedido-status.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ConfigService } from 'app/shared/services/config.service';
import { CnpjPipe } from 'app/shared/pipes/cnpj.pipe';
import { Router, ActivatedRoute } from '@angular/router';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { FormaPagamento } from '../../util/model/forma-pagamento.model';
import { isNullOrUndefined } from 'util';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-item-participante-relac',
  templateUrl: './item-participante-relac.component.html',
  styleUrls: ['./item-participante-relac.component.scss'],
  providers: [CrudService]
})
export class ItemParticipanteRelacComponent extends DataTableBasicComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  opcoesItens: SelectItem[];
  opcoesItensEmpresa: SelectItem[];
  // opcoesParticipante: SelectItem[];
  itensParticipante: any;
  itensVinculadosParticipante: any;
  itensVinculadosOutros: any;
  itensEmpresa: any;
  msgs: Message[];
  contadorItensParticipante: number = 0;
  contadorItensVinculadosParticipante: number = 0;
  contadorItensOutros: number = 0;

  item: Item;
  participante: Participante;

  labelSelect: string;

  private cnpjPipe: CnpjPipe = new CnpjPipe();
  itensParticipanteSelecionados: string[] = [];

  title: String;
  subtitle: String;
  todasSelecionadas: boolean;
  todasSelecionadasVinculados: boolean;
  idItens: Array<any> = new Array();
  idItensVinculados: Array<any> = new Array();
  addDisabled: boolean;
  removeDisabled: boolean;
  editar: boolean;
  aba1: boolean;
  aba2: boolean;
  itensRelacionado: boolean = false
  colsItensParticipante : any[] = [];
  colsItensVinculadosParticipante : any[] = [];
  colsItensVinculadosOutros : any[] = [];
  modalFornecedor: boolean = false

  constructor(private configService: ConfigService,
    private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private toolbarService: ToolbarService,
    private messageService: MessageService) {
    super(crudService);
  }

  @ViewChild('dt0') dt0: Table
  export() {
    this.dt0.exportCSV()
  }
  @ViewChild('dt20') dt20: Table
  exportRel1() {
    this.dt0.exportCSV()
  }
  @ViewChild('dt30') dt30: Table
  exportRel2() {
    this.dt30.exportCSV()
  }


  public toolbarMain = new Toolbar()
  public toolbarMainRel = new Toolbar()

  toolbarMainIni = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Relacionamento de Itens XML', visible: true, disabled: false, color: 'default', onClick: () => this.dt0.exportCSV() },
  ]
  toolbarMainIniRel = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Itens Relacionados', visible: false, disabled: false, color: 'default', onClick: () => this.dt20.exportCSV() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Outros Itens', visible: false, disabled: false, color: 'default', onClick: () => this.dt30.exportCSV() }
  ]

  ngOnInit() {
    // TODO: REVIEW (IMPORTANT!)
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarMainRel.setAll(this.toolbarMainIniRel)
    this.toolbarService.hidden = true
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.dblinkedSessionService.hidePeriods();
    this.item = new Item();
    this.participante = new Participante();
    this.itensParticipante = '';
    this.itensVinculadosParticipante = '';
    this.itensVinculadosOutros = new Array();
    this.labelSelect = 'Selecione os itens';
    this.itensParticipanteSelecionados = [];
    this.title = 'RELACIONAMENTO DOS ITENS COM FORNECEDOR';
    this.subtitle = 'RELACIONAMENTO DOS ITENS ';
    this.todasSelecionadas = false;
    this.todasSelecionadasVinculados = false;
    this.addDisabled = false;
    this.removeDisabled = false;
    this.idItens = new Array();
    this.idItensVinculados = new Array();
    this.itensEmpresa = new Array();
    this.editar = false;


    this.colsItensParticipante = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRIÇÃO' }
    ];

    this.colsItensVinculadosParticipante = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRIÇÃO' }
    ];

    this.colsItensVinculadosOutros = [
      { field: 'codigoForn', header: 'CÓDIGO' },
      { field: 'descriForn', header: 'DESCRIÇÃO' },
      { field: 'codigoInt', header: 'CÓDIGO' },
      { field: 'descriInt', header: 'DESCRIÇÃO' }
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.empresaChanged(); });

  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  empresaChanged() {

    if (this.dblinkedSessionService.hasEmpresaSelected) {

      // Promise.resolve(null).then(() => this.toolbarRefresh());
      this.toolbarService.hidden = true;
      this.itensParticipante = new Array();
      this.itensVinculadosParticipante = new Array();
      this.itensVinculadosOutros = new Array();
      this.participante = new Participante()

      this.httpService.wait();
      this.httpService.get('/custom/cadastro/buscar-itens-empresa(' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ')')
        .subscribe(ref => this.loadItensEmpresa(ref),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/item?$select=id,codigo,descricao&$filter=operacao eq null and empresa/id eq ' +
        this.dblinkedSessionService.empresa.id)
        .subscribe(ref => this.loadItens(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      // this.httpService.wait();
      // this.httpService.get('/custom/cadastro/buscar-participantes-empresa(' + this.dblinkedSessionService.empresa.id + ',' + 2 + ')')
      //   .subscribe(ref => this.loadParticipante(ref),
      //     error => this.httpService.handleError(error, () => this.empresaChanged()),
      //     () => this.httpService.done());

    }
  }

  loadItens(value: Array<Item>) {

    this.opcoesItens = [];

    value.forEach(element => {
      this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
    });
  }

  // loadParticipante(value: Array<any>) {

  //   this.opcoesParticipante = [];


  //   value.forEach(element => {
  //     element.label = element.codigo + ' - ' + element.nome + ' - ' + this.cnpjPipe.transform(element.cnpj);
  //     this.opcoesParticipante.push({ label: element.label, value: element.participanteId });
  //   });

  // }

  buscarItensParticipanteEmpresa(){

    if(!isNullOrUndefined(this.participante.id)){
      this.buscarItensParticipante();
    }

  }

  buscarItensParticipante() {

    if(isNullOrUndefined(this.participante.id) || isNullOrUndefined(this.item.id)){
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: 'É necessário selecionar um item e um fornecedor!' });
      return;
    }

    this.idItensVinculados = new Array();
    this.idItens = new Array();
    this.todasSelecionadasVinculados = false;
    this.todasSelecionadas = false;
    this.aba1 = true;
    this.aba2 = false;

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/buscar-itens-participante(' + (!isNullOrUndefined(this.participante.id) ? this.participante.id : 0)  + ',' +
      + this.dblinkedSessionService.empresa.id + ',' + (!isNullOrUndefined(this.item.id) ? this.item.id : 0)  + ')')
      .subscribe(ref => this.loadItensParticipante(ref),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/buscar-itens-relacionados-participante(' + ( !isNullOrUndefined( this.participante.id) ? this.participante.id : 0) + ',' +
      + this.dblinkedSessionService.empresa.id + ',' + (!isNullOrUndefined( this.item.id) ? this.item.id : 0) + ')')
      .subscribe(ref => this.loadItensVinculadosParticipante(ref),
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

        this.itensRelacionado = true
        this.toolbarMainRel.setVisible('btn1', true)
  }

  showTela(tipo: string){
    if(!this.aba1){
      this.aba1 = true;
      this.aba2 = false;

      this.toolbarMainRel.setVisible('btn1', true)
      this.toolbarMainRel.setVisible('btn2', false)
    } else {
      this.aba1 = false;
      this.aba2 = true;

      this.toolbarMainRel.setVisible('btn1', false)
      this.toolbarMainRel.setVisible('btn2', true)
    }

  }

  loadItensParticipante(value: Array<any>) {
    this.itensParticipante = new Array();
    value.forEach(element => {this.itensParticipante.push(element) });
    if (this.itensParticipante !== null || this.itensParticipante !== undefined) {
      this.contadorItensParticipante = this.itensParticipante.length;
    } else{
      this.contadorItensParticipante = 0;
    }

  }

  loadItensEmpresa(value: Array<any>) {
    this.itensEmpresa = value;
    this.opcoesItensEmpresa = [];
    value.forEach(element => {
      this.opcoesItensEmpresa.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });

    });
  }

  onRowSelect(event: any) {
    this.item = event.data;
    this.editar = true;
  }

  loadItensVinculadosParticipante(value: Array<any>) {
    this.contadorItensVinculadosParticipante = 0;
    this.contadorItensOutros = 0;

    this.itensVinculadosParticipante = new Array();
    this.itensVinculadosOutros = new Array();
    value.forEach(element => {
      if(element.esteItem){
        this.itensVinculadosParticipante.push(element);
      } else{
        this.itensVinculadosOutros.push(element);
      }

    });

    if (this.itensVinculadosParticipante !== null || this.itensVinculadosOutros !== undefined) {
      this.contadorItensVinculadosParticipante = this.itensVinculadosParticipante.length;
    } else {
      this.contadorItensVinculadosParticipante = 0;
    }

    if (this.itensVinculadosOutros !== null || this.itensVinculadosOutros !== undefined) {
      this.contadorItensOutros = this.itensVinculadosOutros.length;
    } else{
      this.contadorItensOutros = 0;
    }

    console.log(this.contadorItensOutros);
  }

  selectCheckbox(event: any) {
    this.todasSelecionadas = false;

    this.idItens.push(event.data.id);
  }

  unselectCheckbox(event){
    const index = this.idItens.findIndex(c => c === event.data.id);
    this.idItens.splice(index, 1);
  }

  selectCheckboxVinculados(event: any) {
    this.todasSelecionadasVinculados = false;
    this.idItensVinculados.push(event.data.id);
    event.selected = false;
  }

  unselectCheckboxVinculados(event: any){
    const index = this.idItensVinculados.findIndex(c => c === event.data.id);
    this.idItensVinculados.splice(index, 1);
    event.selected = true;
  }

  add() {
    const listasEnviar = {
      item: this.item.id,
      participante: this.participante.id,
      itemParticipante: this.idItens,
      usuario: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id
    };



    this.httpService.wait();
    this.httpService.post('/custom/cadastro/relacionar-itens-participantes', listasEnviar)
      .subscribe(result =>
        {
          this.buscarItensParticipante();

          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Msg: ', detail: 'Item relacionado com sucesso!' });
        }

      ,
        error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
        () => this.httpService.done());
  }

  remove() {
    const listasEnviar = {
      item: this.item.id,
      participante: this.participante.id,
      itemParticipante: this.idItensVinculados,
      empresaId: this.dblinkedSessionService.empresa.id
    };



    this.httpService.wait();
    this.httpService.post('/custom/cadastro/remover-relacionamento-itens-participantes', listasEnviar)
      .subscribe(result => {
        this.buscarItensParticipante();

        this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Msg: ', detail: 'Relação removida com sucesso!' });
      },
        error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
        () => this.httpService.done());
  }

  onFilterItensPart(event: any){
    this.contadorItensParticipante = event.filteredValue.length;
  }

  onFilterItensVincPart(event: any){
    this.contadorItensVinculadosParticipante = event.filteredValue.length;
  }

  onFilterItensVincOutros(event: any){
    this.contadorItensOutros = event.filteredValue.length;
  }

  selectTodos(event: any) {
    if (event.checked === true) {
      this.todasSelecionadas = true;
      this.idItens = new Array();
      this.itensParticipante.forEach(form => {
        form.selected = true;
        this.idItens.push(form.id);
      });
    } else {
      this.todasSelecionadas = false;
      this.itensParticipante.forEach(form => {
        form.selected = false;
        this.idItens = new Array();
      });
    }
  }

  selectTodosVinculados(event: any) {
    if (event.checked === true) {
      this.todasSelecionadasVinculados = true;
      this.idItensVinculados = new Array();
      this.itensVinculadosParticipante.forEach(form => {
        form.selected = true;
        this.idItensVinculados.push(form.id);
      });
    } else {
      this.todasSelecionadasVinculados = false;
      this.itensVinculadosParticipante.forEach(form => {
        form.selected = false;
        this.idItensVinculados = new Array();
      });
    }

  }

  salvarRelacionamento() {
    const listasEnviar = {
      item: this.item.id,
      participante: this.participante.id,
      itemParticipante: this.itensParticipanteSelecionados,
      empresaId: this.dblinkedSessionService.empresa.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/relacionar-itens-participantes', listasEnviar)
      .subscribe(result => this.empresaChanged(),
        error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
        () => this.httpService.done());
  }

  private toolbarRefresh() {
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
    // this.toolbarService.ocultar('check');
    // this.toolbarService.ocultar('info');
    // this.toolbarService.ocultar('msg');
    // this.toolbarService.ocultar('save');
   /* this.toolbarService.clear();
    this.toolbarService.add('back', false, 'class-back-button', 'undo', 'Voltar', () => this.ngOnInit());
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Criar nova regra contábil');*/
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  voltar() {
    this.item = new Item();
    this.participante = new Participante();
    this.itensParticipante = '';
    this.itensVinculadosParticipante = '';
    this.itensParticipanteSelecionados = [];
    this.todasSelecionadas = false;
    this.todasSelecionadasVinculados = false;
    this.addDisabled = false;
    this.removeDisabled = false;
    this.idItens = new Array();
    this.idItensVinculados = new Array();
    this.itensEmpresa = new Array();
    this.editar = false;
    this.empresaChanged();
  }

  hideMessage() {


  }
   /*FullScreen PagRec*/
   fullPagRec: boolean = false;
   fullScreenPagRec(){
     this.fullPagRec = !this.fullPagRec;
   }

   currentStatePagRec = 'initial';

   changeStatePagRec() {
    this.currentStatePagRec = this.currentStatePagRec === 'initial' ? 'final' : 'initial';
  }

  salvarParticipante(event) {
    let participante = event.selectedParticipant
    this.participante.id = participante.id
    this.participante.codigo = participante.cod_part
    this.participante.cnpj = participante.cpf_cnpj
    this.participante.fantasia = participante.fantasia
    this.buscarItensParticipante()
  }
}
