export class InstituicaoFinanceira {
  id: number;
  codigo: string;
  descricao: string;

  constructor(instituicaoFinanceira?: InstituicaoFinanceira | number) {
    if (instituicaoFinanceira !== undefined && instituicaoFinanceira !== null) {
      if (typeof instituicaoFinanceira === 'number') {
        this.id = instituicaoFinanceira;
      } else {
        this.id = instituicaoFinanceira.id;
        this.codigo = instituicaoFinanceira.codigo;
        this.descricao = instituicaoFinanceira.descricao;
      }
    }
  }
}
