import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { LocalidadeGrupoMunicipio} from 'app/financeiro/model/localidade-grupo-municipio.model';
import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';

@Component({
  selector: 'app-localidade-grupo-municipio',
  templateUrl: './localidade-grupo-municipio.component.html',
  styleUrls: ['./localidade-grupo-municipio.component.scss'],
  providers: [CrudService]
})
export class LocalidadeGrupoMunicipioComponent extends DataTableBasicComponent
  implements OnInit, OnDestroy {

  public localidadeGrupoMunicipio: LocalidadeGrupoMunicipio = new LocalidadeGrupoMunicipio();

  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeViewSubscription: Subscription;

  entityFilter: string = null;
  selectedUF = '';

  constructor(
    private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService
  ) {
    super(crudService);
  }

  ngOnInit() {
    this.empresaSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.empresaChanged,
      () => {
        this.empresaChanged();
      }
    );

    this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((localidadeGrupoMunicipio: LocalidadeGrupoMunicipio) => {


      localidadeGrupoMunicipio.municipioIbge = new MunicipioIbge(this.crudService.form.get('municipioIbge').value);


    });

    this.beforeViewSubscription  = this.crudService.beforeView.subscribe((localidadeGrupoMunicipio: LocalidadeGrupoMunicipio) => {

      this.httpService.wait();
      this.httpService.get('/municipioibge?$select=uf' +
      '&$filter=id eq ' + localidadeGrupoMunicipio.municipioIbge.id)
      .subscribe(ref => this.loadUF(localidadeGrupoMunicipio, ref.value),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
      () => this.httpService.done());

    });


  }

  loadUF(localidadeGrupoMunicipio: LocalidadeGrupoMunicipio, value: any) {
    value.forEach(element => {
      localidadeGrupoMunicipio.selectedUF = element.uf;
    });
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
    this.sessionService.destroySubscribe(this.beforeViewSubscription);
  }

  empresaChanged() {


    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.columns = [
        new DataTableColumn('Localidade Grupo', 'localidadeGrupo'),
        new DataTableColumn('UF', 'municipioIbge.uf')
          .setTypeSelect()
          .setOptions({ url: '/uf', value: 'id', label: 'uf' }),
        new DataTableColumn('Município', 'municipioIbge')
          .setTypeSelect()
          .setOptions({ url: '/municipioibge?$filter=uf/id eq #parentValue', parent: 'municipioIbge.uf',
                        value: 'id', label: 'nome'})
      ];

      this.controls = [

        new DataControl('Localidade Grupo', 'localidadeGrupo')
          .setRequired()
          .setTypeSelect()
          .setTransient(true)
          .setOptions({ url: '/localidadegrupo', value: 'id', label: 'descricao' }),

        new DataControl('UF', 'municipioIbge.uf')
          .setTypeSelect()
          .setOptions({ url: '/uf', value: 'id', label: 'uf' }),
        new DataControl('Município', 'municipioIbge')
          .setTypeSelect()
          .setOptions({ url: '/municipioibge?$filter=uf/id eq #parentValue', parent: 'municipioIbge.uf',
                        value: 'id', label: 'nome'}),

        new DataControl('Município', 'municipioIbge')
          .setDisabled(this.selectedUF === '')
          .setRequired()
          .setTypeSelect()
          .setOptions({
            value: 'id',
            label: ['nome']
          }),
        ];
    }
  }
}
