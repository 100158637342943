import { Component, Inject, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Participante } from 'app/cadastro/model/participante.model';
import { ParticipanteTipo } from '../model/participante-tipo.model';
import { HttpService } from 'app/shared/services/http.service';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from 'app/shared/services/config.service';
import { merge } from 'rxjs/observable/merge';
import { startWith } from 'rxjs/operator/startWith';
import { CpfPipe } from 'app/shared/pipes/cpf.pipe';
import { CnpjPipe } from 'app/shared/pipes/cnpj.pipe';
import { ParticipanteParticipanteTipo } from 'app/cadastro/model/participante-participante-tipo.model';
import { ParticipanteEmpresa } from 'app/cadastro/model/participante-empresa.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Util } from 'app/shared/common/util';
import { ParticipanteEndereco } from '../model/participante-endereco.model';
import { ValidatorsService } from '../../shared/services/validators.service';
import { EnderecoTipo } from '../../util/model/endereco-tipo.model';
import { ParticipanteContato } from 'app/cadastro/model/participante-contato.model';
import { Endereco } from '../model/endereco.model';
import { MunicipioIbge } from '../../util/model/municipio-ibge.model';
import { Uf } from '../../util/model/uf.model';
import { Pais } from '../../util/model/pais.model';
import { LazyLoadEvent } from 'primeng/api';

export const MAT_DIALOG_CONFIG: MatDialogConfig = {
  autoFocus: true,
  closeOnNavigation: true,
  disableClose: true,
  hasBackdrop: true,
  height: '520px',
  minHeight: '60%',
  maxHeight: '80%',
  width: '760px',
  minWidth: '60%',
  maxWidth: '90%'
};

@Component({
  selector: 'app-participante-dialog',
  templateUrl: './participante-dialog.component.html',
  styleUrls: ['./participante-dialog.component.scss']
})
export class ParticipanteDialogComponent implements OnInit, AfterViewInit {
  formGroup: FormGroup;

  columns = ['codigo', 'fantasia', 'nome', 'id'];
  @ViewChild(MatSort) matSort: MatSort;
  matTableDataSource = new MatTableDataSource();

  @ViewChild('focusInitial') focusInitial: ElementRef;
  @ViewChild('focusSearch') focusSearch: ElementRef;

  participanteTipos: Array<ParticipanteTipo>;
  participante: Participante;
  requestedParticipanteTipoId: number;
  currentParticipanteTipoId: number;

  searchTerm = '';
  private searchTimeout: NodeJS.Timer;
  searchNotFound: boolean;
  private oldCnpjOrCpf: string;
  isCpf = false;
  isCnpj = false;

  totalRecords: number;

  action: string;

  private currentTypeParticipantes: Array<Participante>;

  private paisDefault = 105;
  private municipioIbgeExterior = 5569;

  paises: Array<Pais> = new Array();
  ufs: Array<Uf> = new Array();
  municipios: Array<MunicipioIbge> = new Array();

  participantes: Array<any> = new Array();

  constructor(private formBuilder: FormBuilder,
              private configService: ConfigService,
              private cpfPipe: CpfPipe,
              private cnpjPipe: CnpjPipe,
              private httpService: HttpService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              public matDialogRef: MatDialogRef<ParticipanteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.participanteTipoId) {
        this.requestedParticipanteTipoId = data.participanteTipoId !== 3 ? data.participanteTipoId : null;
      }

      if (data.action && !Util.isEmpty(data.action)) {
        this.action = (data.action.trim() === data.action.trim().toUpperCase() ? ' E ' : ' e ') + data.action.trim();
      }
    }
  }

  ngOnInit() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.loadParticipanteTipos();
      this.loadParticipantes(this.requestedParticipanteTipoId);

      this.httpService.loadList('/pais?$select=id,nome&$orderby=nome', this.paises);
      this.httpService.loadList('/uf?$select=id,uf&$orderby=uf', this.ufs);
    }
  }

  ngAfterViewInit() {
    this.matTableDataSource.sort = this.matSort;
  }

  onChangePais(paisId: number) {
    console.log(paisId);
    const ufControl = this.formGroup.get('uf');
    if (paisId === this.paisDefault) {
      ufControl.enable();
    } else {
      ufControl.setValue(undefined);
      ufControl.disable();
    }
  }

  onChangeUf(ufId: number, callback: Function = null) {
    this.httpService.loadList('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + ufId, this.municipios, callback);
  }

  private loadParticipanteTipos() {
    this.httpService.wait();
    this.httpService.get('/participantetipo?$select=id,descricao&$filter=id ne 3&$orderby=descricao')
                    .subscribe(data => this.participanteTipos = data.value,
                               error => this.httpService.handleError(error, () => this.loadParticipanteTipos()),
                               () => this.httpService.done());
  }

  private loadParticipantes(participanteTipoId: number = null, sort: Sort = null) {
    this.currentParticipanteTipoId = participanteTipoId;

    this.getParticipantes();

 }

  newParticipante() {
    this.isCnpj = false;
    this.isCpf = false;
    this.oldCnpjOrCpf = null;

    this.formGroup = this.formBuilder.group([]);

    this.formGroup.addControl('cnpj', new FormControl({ value: null, disabled: false },
                              [Validators.required, Validators.minLength(11), Validators.maxLength(14), ValidatorsService.cpfOrCnpj]));

    this.formGroup.addControl('nome', new FormControl({ value: null, disabled: true },
                              [Validators.required, Validators.minLength(3), Validators.maxLength(60)]));

    this.formGroup.addControl('fantasia', new FormControl({ value: null, disabled: true },
                              [Validators.required, Validators.minLength(3), Validators.maxLength(60)]));

    this.formGroup.addControl('logradouro', new FormControl({ value: null, disabled: true },
                              [Validators.required, Validators.minLength(3), Validators.maxLength(60)]));

    this.formGroup.addControl('numero', new FormControl({ value: null, disabled: true },
                              [Validators.required, Validators.minLength(1), Validators.maxLength(10)]));

    this.formGroup.addControl('complemento', new FormControl({ value: null, disabled: true },
                              [Validators.maxLength(60)]));

    this.formGroup.addControl('bairro', new FormControl({ value: null, disabled: true },
                              [Validators.required, Validators.minLength(1), Validators.maxLength(60)]));

    this.formGroup.addControl('pais', new FormControl({ value: this.paisDefault, disabled: true }, [Validators.required]));

    this.formGroup.addControl('uf', new FormControl({ value: null, disabled: true }, [Validators.required]));

    this.formGroup.addControl('municipioIbge', new FormControl({ value: null, disabled: true }, [Validators.required]));

    this.formGroup.addControl('contato', new FormControl({ value: null, disabled: true },
                              [Validators.required, Validators.minLength(3), Validators.maxLength(30)]));

    this.formGroup.addControl('email', new FormControl({ value: null, disabled: true },
                              [Validators.required, Validators.minLength(3), Validators.maxLength(60)]));

    this.formGroup.addControl('fone', new FormControl({ value: null, disabled: true },
                              [Validators.required, Validators.minLength(7), Validators.maxLength(15)]));

    this.focus();
  }

  selectParticipanteTipo(participanteTipoId: number = null) {
    this.formGroup = null;
    this.participante = null;
    this.loadParticipantes(participanteTipoId, null);
  }

  selectParticipante(participante: Participante = null) {
    const _participante: Participante = Util.clone(participante || this.participante);



    if (_participante.participanteEmpresas &&
        _participante.participanteEmpresas.findIndex(pe => pe['participanteTipoId'] === this.requestedParticipanteTipoId &&
                                                           pe['empresaId'] === this.empresa.id) === -1) {
      this.saveParticipante(_participante);
    } else {
      delete(_participante.participanteEmpresas);

      this.fixParticipante(_participante);

      this.matDialogRef.close(_participante);
    }
  }

  selectRow(participante: Participante) {
    console.log(participante);
    if (this.participante === participante) {
      this.participante = null;
    } else {
      this.participante = participante;
    }
  }

  saveParticipante(participante: Participante = null) {
    const _participante = participante ? participante : this.participante;

    const participanteEmpresa = new ParticipanteEmpresa();

    if (this.formGroup) {
      participanteEmpresa.fantasia = this.formGroup.get('fantasia').value;
    } else {
      participanteEmpresa.fantasia = _participante.fantasia;
    }

    participanteEmpresa.empresa = new Empresa(this.empresa.id);
    participanteEmpresa.participanteTipo = new ParticipanteTipo(this.requestedParticipanteTipoId || 1);

    if (_participante && _participante.nome) {
      participanteEmpresa.participante = Util.clone(_participante);

      if (!_participante.id) {
        participanteEmpresa.participante.fantasia = participanteEmpresa.fantasia;
      }
    } else {
      participanteEmpresa.participante = new Participante(this.formGroup.getRawValue());
      if (this.isCpf) {
        participanteEmpresa.participante.cpf = participanteEmpresa.participante.cnpj;
        participanteEmpresa.participante.cnpj = null;
      }

      let municipioIbge = +this.formGroup.get('municipioIbge').value;
      if (municipioIbge < 1) {
        municipioIbge = this.municipioIbgeExterior;
      }

      const endereco = new Endereco(this.formGroup.getRawValue());
      endereco.municipioIbge = new MunicipioIbge(municipioIbge);
      endereco.pais = new Pais(this.formGroup.get('pais').value);

      if (municipioIbge === this.municipioIbgeExterior) {
        // TODO: Use city as string (new field @ cadastro.endereco ??) OR ignore city ?
      }

      const participanteEndereco = new ParticipanteEndereco();
      participanteEndereco.endereco = endereco;
      participanteEndereco.enderecoTipo = new EnderecoTipo(1);
      participanteEndereco.principal = true;
      participanteEndereco.participante = participanteEmpresa.participante;
      participanteEmpresa.participante.participanteEnderecos = new Array();
      participanteEmpresa.participante.participanteEnderecos.push(participanteEndereco);

      const participanteContato = new ParticipanteContato(this.formGroup.getRawValue());
      participanteContato.principal = true;
      participanteContato.participante = participanteEmpresa.participante;
      participanteEmpresa.participante.participanteContatos = new Array();
      participanteEmpresa.participante.participanteContatos.push(participanteContato);
    }

    delete(participanteEmpresa.participante.participanteEmpresas);

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/participanteempresa', participanteEmpresa)
                    .subscribe(newParticipanteEmpresa => {
                                 const newParticipante = newParticipanteEmpresa.participante;
                                 newParticipante['codigo'] = newParticipanteEmpresa.codigo;
                                 newParticipante.fantasia = newParticipanteEmpresa.fantasia;

                                 this.selectParticipante(newParticipante);
                               },
                               error => this.httpService.handleError(error, () => this.saveParticipante(participante)),
                               () => this.httpService.done());
  }

  cancel(canClose: boolean = true) {
    if (this.formGroup) {
      this.formGroup = null;
      this.focus();
    } else if (canClose) {
      this.matDialogRef.close();
    }
  }

  focus() {
    setTimeout(() => this.focusInitial.nativeElement.focus(), 100);
  }

  filter() {
    this.participante = null;
    this.matTableDataSource.filter = this.searchTerm.trim().toLowerCase();

    if (this.matTableDataSource.filteredData.length === 1) {
      const participante = <Participante>this.matTableDataSource.filteredData[0];
      this.fixParticipante(participante);
      this.selectRow(participante);
    }
  }

  search(cnpjOrCpf: string, withoutTimeout: boolean = false) {
    if (!withoutTimeout) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.search(cnpjOrCpf, true);
      }, 750);

      this.isCpf = cnpjOrCpf && cnpjOrCpf.length === 11;
      this.isCnpj = cnpjOrCpf && cnpjOrCpf.length === 14;

      return;
    }

    if (cnpjOrCpf && (cnpjOrCpf.length === 11 || cnpjOrCpf.length === 14) && cnpjOrCpf !== this.oldCnpjOrCpf) {
      if (!Util.isCnpjOrCpf(cnpjOrCpf)) {
        return;
      }

      this.searchNotFound = false;
      this.oldCnpjOrCpf = cnpjOrCpf;

      if (this.formGroup) {
        this.formGroup.disable();
      }
      this.httpService.wait();
      this.httpService.get('/custom/cadastro/participante', [cnpjOrCpf, this.empresa.id])
                      .subscribe(data => {
                                  this.participante = new Participante();
                                  this.participante.cnpj = this.isCnpj ? cnpjOrCpf : null;
                                  this.participante.cpf = this.isCpf ? cnpjOrCpf : null;

                                  let participante = new Participante();

                                  if (!data.error) {
                                    const externalResponse = data.externalResponse;

                                    if (externalResponse) {
                                      participante.fantasia = externalResponse.tradeName || externalResponse.name;
                                      participante.nome = externalResponse.name;
                                    } else {
                                      participante = data;

                                      this.participante.id = participante.id;
                                      this.participante.nome = participante.nome;

                                      if (participante.participanteEmpresas) {
                                        participante.participanteEmpresas.forEach(pe => {
                                          if (pe.participanteTipo.id === this.requestedParticipanteTipoId) {
                                            this.cancel(false);
                                            this.searchTerm = cnpjOrCpf;
                                            this.selectParticipanteTipo(this.requestedParticipanteTipoId);
                                          }
                                        });
                                      }
                                    }

                                    if (this.formGroup) {
                                      this.fixParticipante(participante);

                                      this.formGroup.get('fantasia').setValue(participante.fantasia);
                                      this.formGroup.get('nome').setValue(participante.nome);

                                      const endereco: Endereco = participante.participanteEnderecos.find(p => p.principal).endereco;
                                      this.formGroup.get('logradouro').setValue(endereco.logradouro);
                                      this.formGroup.get('numero').setValue(endereco.numero);
                                      this.formGroup.get('complemento').setValue(endereco.complemento);
                                      this.formGroup.get('bairro').setValue(endereco.bairro);
                                      this.formGroup.get('pais').setValue(endereco.pais ? endereco.pais.id : undefined);
                                      this.formGroup.get('uf').setValue(endereco.municipioIbge && endereco.municipioIbge.uf ?
                                                                        endereco.municipioIbge.uf.id : undefined);
                                      if (endereco.municipioIbge && endereco.municipioIbge.uf && endereco.municipioIbge.uf.id) {
                                        this.onChangeUf(endereco.municipioIbge.uf.id, () => {
                                          this.formGroup.get('municipioIbge').setValue(endereco.municipioIbge ?
                                                                                       endereco.municipioIbge.id : undefined);
                                        });
                                      }

                                      const contato: ParticipanteContato = participante.participanteContatos.find(p => p.principal);
                                      this.formGroup.get('contato').setValue(contato.contato);
                                      this.formGroup.get('email').setValue(contato.email);
                                      this.formGroup.get('fone').setValue(contato.fone);

                                      this.focusSearch.nativeElement.focus();
                                    }
                                  } else {
                                    this.clearAfterNotFound();
                                  }
                                 },
                                 error => {
                                   if (this.formGroup) {
                                     this.formGroup.enable();
                                   }
                                   this.httpService.handleError(error,
                                                                () => this.search(cnpjOrCpf, withoutTimeout),
                                                                () => this.clearAfterNotFound());
                                   },
                                 () => {
                                   if (this.formGroup) {
                                     this.formGroup.enable();
                                   }
                                   this.httpService.done();
                                 });
    }
  }

  searchSubmit() {
    if (this.participante) {
      this.selectParticipante();
    }
  }

  private clearAfterNotFound() {
    this.searchNotFound = true;

    this.formGroup.setValue({
      cnpj: this.formGroup.get('cnpj').value,
      nome: null,
      fantasia: null,
      logradouro: null,
      numero: null,
      complemento: null,
      bairro: null,
      pais: this.paisDefault,
      uf: null,
      municipioIbge: null,
      contato: null,
      email: null,
      fone: null
    });

    this.formGroup.get('nome').enable();
    this.formGroup.get('fantasia').enable();
    this.formGroup.get('logradouro').enable();
    this.formGroup.get('numero').enable();
    this.formGroup.get('complemento').enable();
    this.formGroup.get('bairro').enable();
    this.formGroup.get('pais').enable();
    this.formGroup.get('uf').enable();
    this.formGroup.get('municipioIbge').enable();
    this.formGroup.get('contato').enable();
    this.formGroup.get('email').enable();
    this.formGroup.get('fone').enable();
  }

  private fixParticipante(participante: Participante) {
    if (participante.participanteEmpresas) {
      const participanteTipoId = this.currentParticipanteTipoId > 0 ? this.currentParticipanteTipoId : this.requestedParticipanteTipoId;

      let participanteEmpresa;
      if (participanteTipoId > 0) {
        participanteEmpresa = participante.participanteEmpresas.find(pe => pe['participanteTipoId'] === participanteTipoId &&
                                                                           pe['empresaId'] === this.empresa.id);
      }
      if (!participanteEmpresa) {
        participanteEmpresa = participante.participanteEmpresas.find(pe => pe['empresaId'] === this.empresa.id);
      }

      if (participanteEmpresa) {
        participante['codigo'] = participanteEmpresa.codigo;

        if (participanteEmpresa.fantasia && participanteEmpresa.fantasia.trim() !== '') {
          participante.fantasia = participanteEmpresa.fantasia;
        }
      }
    }
    if (participante.fantasia === undefined || participante.fantasia === null || participante.fantasia.trim() === '') {
      participante.fantasia = participante.nome.trim();
    }
    participante.cpfPiped = participante.cpf ? this.cpfPipe.transform(participante.cpf) : null;
    participante.cnpjPiped = participante.cnpj ? this.cnpjPipe.transform(participante.cnpj) : null;
  }

  get participanteTipoDescricao(): string {
    if (this.participanteTipos && this.requestedParticipanteTipoId !== 3) {
      const participanteTipo = this.participanteTipos.find(pt => pt.id === this.requestedParticipanteTipoId);
      if (participanteTipo) {
        return participanteTipo.descricao.toLowerCase();
      }
    }

    return 'participante';
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get canSelect(): boolean {
    return !this.formGroup && this.participante && this.participante.participanteEmpresas &&
           this.participante.participanteEmpresas.findIndex(pe => pe['participanteTipoId'] === this.requestedParticipanteTipoId &&
                                                                  pe['empresaId'] === this.empresa.id) !== -1;
  }

  get canCopy(): boolean {
    return !this.formGroup && this.participante && this.participante.participanteEmpresas &&
           this.participante.participanteEmpresas.filter(pe => pe['empresaId'] === this.empresa.id).length === 1 &&
           this.participante.participanteEmpresas.findIndex(pe => pe['empresaId'] === this.empresa.id &&
                                                                  pe['participanteTipoId'] === this.requestedParticipanteTipoId) === -1;
  }

  get canSave(): boolean {
    return this.formGroup !== undefined && this.formGroup !== null;
  }

  get isRequiredType(): boolean {
    return this.currentParticipanteTipoId === this.requestedParticipanteTipoId ||
           (this.currentParticipanteTipoId === null && this.participante && this.participante.participanteEmpresas &&
            this.participante.participanteEmpresas.findIndex(pe => pe['participanteTipoId'] === this.requestedParticipanteTipoId &&
                                                                   pe['empresaId'] === this.empresa.id) !== -1);
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  async getOutrosParticipantes(event: LazyLoadEvent) {
    console.log(event);
    await this.getParticipantes(event.first, 10, event.filters, event.sortField, event.sortOrder);
  }

  async getParticipantes(offset: number = 0, limit: number = 10, filters: Object = {}, sortField: string = "", sortOrder: number = 0, defaultParam: boolean = true) {


    const data = {
      company_id: this.empresa.id,
      participanteTipoId: this.requestedParticipanteTipoId,
      offset,
      limit,
      filters,
      sortField,
      sortOrder
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/buscar-part-empresa', data)
      .subscribe(result => {
        this.participantes = result.data;
        this.totalRecords = result.totalRecords

      },
        error => this.httpService.handleError(error, () => this.getParticipantes()),
        () => this.httpService.done());
    }



}
