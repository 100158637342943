import { Empresa } from 'app/cadastro/model/empresa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { MvaNcmRelac } from './mva-ncm-relac.model';
import { MvaUfRelac } from './mva-uf-relac.model';

export class Mva {
  ativo: boolean
  changeTimestamp: Date
  changeUser: AuthUser
  createTimestamp: Date
  createUser: AuthUser
  empresa: Empresa = new Empresa()
  id: number
  ncm: Array<MvaNcmRelac> = new Array()
  nome: String
  uf: Array<MvaUfRelac> = new Array()
  valor: any

  constructor(mva?: Mva | number) {
    if (mva !== undefined && mva !== null) {
      if (typeof mva === 'number') {
        this.id = mva
      } else {
        this.ativo = mva.ativo
        this.changeTimestamp = mva.changeTimestamp
        this.changeUser = mva.changeUser
        this.createTimestamp = mva.createTimestamp
        this.createUser = mva.createUser
        this.empresa = mva.empresa
        this.id = mva.id
        this.ncm = mva.ncm
        this.nome = mva.nome
        this.uf = mva.uf
        this.valor = mva.valor
      }
    }
  }
}
