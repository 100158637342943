import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { MessageService, SelectItem } from 'primeng/api';
import { Empresa } from '../../cadastro/model/empresa.model';
import { TipoOrcamento } from 'app/orcamento/model/tipo-orcamento.model';
import { ConfiguracaoImportacao } from 'app/orcamento/model/configuracao-importacao.model';
import { PlanoGerencial } from 'app/contabil/model/plano-gerencial.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Versao } from '../model/versao.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Toolbar } from 'app/shared/model/toolbar.model'
import { Table } from 'primeng/table';
import exportExcel from 'app/util/processing/exportExcel';

declare var $: any;


@Component({
  selector: 'app-configuracao-importacao',
  templateUrl: './configuracao-importacao.component.html',
  styleUrls: ['./configuracao-importacao.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class ConfiguracaoImportacaoComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  pt: any;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  valid: boolean;
  modalState: boolean = false;
  boolCriarconfiguracaoImportacao: boolean = false;
  boolEditconfiguracaoImportacao: boolean = false;
  checkedAll: boolean = false;
  arrayIds: Array<any> = [];
  listaImportacaConfiguracao: Array<any> = [];
  tipoOrcamento: TipoOrcamento;
  opcoesTipoOrcamento: SelectItem[];
  opcoesPlanoGerencial: SelectItem[];
  opcoesTipoVisao: SelectItem[];
  cloneVisionsOptions: SelectItem[];
  configuracaoImportacao: ConfiguracaoImportacao;
  boolselecionarGerencial: boolean = false;
  boolselecionarContabil: boolean = false;
  tipoOrcamentoId: any = 0;
  opcoesVersoes: SelectItem[] = []

  listTipoVisao = [];
  planoGerencial: PlanoGerencial;
  planoGerencialId: any = 0;
  relPbi: any = 0;

  colslistaImportacao: any[] = [];
  relatorios: SelectItem[];

  selectedConfigurations: Array<any> = new Array();

  //export excel
  colsListaOrcamentoVersao: any[];

  public toolbarMain = new Toolbar();
  toolbarMainInit = [
    {icon: "edit", text: "Editar", tooltip: "Editar Configurações", visible: false, disabled: false, color: 'default', onClick: () => this.carregarInformacaoRegistro(this.selectedConfigurations[0].id)},
    {icon: "delete", text: "Excluir", tooltip: "Excluir Configurações", visible: false, disabled: false, color: 'red', onClick: () => this.excluirRegistro()},
    {icon: "add", text: "Adicionar", tooltip: "Adicionar Configurações", visible: true, disabled: false, color: 'green', onClick: () => this.showModalNovoRegistro()},
    {icon: "upload_file", text: "Exportar", tooltip: "Exportar Excel", visible: true, disabled: false, color: 'default', onClick: () => this.exportAll()}
  ];

  constructor(private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private messageService: MessageService,
    private httpClient: HttpClient,
    private dblinkedSessionService: DblinkedSessionService) {
  }

  ngOnInit(): void {

    this.toolbarMain.setAll(this.toolbarMainInit);

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.tipoOrcamento = new TipoOrcamento();
    this.modalState = false;
    this.boolCriarconfiguracaoImportacao = false;
    this.boolEditconfiguracaoImportacao = false;
    this.checkedAll = false;
    this.opcoesTipoOrcamento = [];
    this.opcoesPlanoGerencial = [];
    this.opcoesTipoVisao = [];
    this.cloneVisionsOptions = [];
    this.configuracaoImportacao = new ConfiguracaoImportacao();
    this.configuracaoImportacao.empresa = new Empresa();
    this.configuracaoImportacao.tipoOrcamento = new TipoOrcamento();
    this.configuracaoImportacao.planoGerencial = new PlanoGerencial();
    this.planoGerencial = new PlanoGerencial();
    this.boolselecionarContabil = false;
    this.boolselecionarGerencial = false;
    this.tipoOrcamentoId = 0;
    this.planoGerencialId = 0;
    this.relPbi = 0;

    this.colslistaImportacao = [
      { field: 'descricao', header: 'DESCRICÃO' },
      { field: 'tipoOrcamento.descricao', header: 'TIPO' },
      { field: 'planoGerencial.nome', header: 'P. GERENCIAL' },
      { field: 'selecionarProjeto"', header: 'PROJETO' },
      { field: 'selecionarCentroCusto', header: 'C.CUSTO' },
      { field: 'selecionarContabil', header: 'CONTABIL' },
      { field: 'selecionarGerencial', header: 'GERENCIAL' }
    ];

    this.titlePrincipal = 'Configurações Orçamento';
    // this.titleService.title = 'Linnks - Us Gaap';
    this.subtitle = 'Configurações de importação';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.toolbarService.hidden = true;
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.colsListaOrcamentoVersao = [
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'tipoOrcamento.descricao', header: 'TIPO' },
      { field: 'planoGerencial.nome', header: 'P. GERENCIAL' },
      { field: 'selecionarProjeto', header: 'PROJETO ' },
      { field: 'selecionarCentroCusto', header: 'C. CUSTO ' },
      { field: 'selecionarContabil', header: 'CONTABIL ' },
      { field: 'selecionarGerencial', header: 'GERENCIAL ' }
    ];
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

      this.tipoOrcamento = new TipoOrcamento();
      this.selectedConfigurations = new Array<any>();
      this.modalState = false;
      this.boolCriarconfiguracaoImportacao = false;
      this.boolEditconfiguracaoImportacao = false;
      this.checkedAll = false;
      this.opcoesTipoOrcamento = [] = [];
      this.opcoesPlanoGerencial = [] = [];
      this.opcoesTipoVisao = [];
      this.cloneVisionsOptions = [];
      this.listTipoVisao = [];
      this.configuracaoImportacao = new ConfiguracaoImportacao();
      this.configuracaoImportacao.empresa = new Empresa();
      this.configuracaoImportacao.tipoOrcamento = new TipoOrcamento();
      this.configuracaoImportacao.planoGerencial = new PlanoGerencial();
      this.configuracaoImportacao.versaoReal = new Versao()
      this.planoGerencial = new PlanoGerencial();
      this.boolselecionarContabil = false;
      this.boolselecionarGerencial = false;
      this.tipoOrcamentoId = 0;
      this.planoGerencialId = 0;
      this.relPbi = 0;

      this.carregarImportacaoTipos();
      this.carregarTipoOrcamentos();
      this.carregarTipoVisao();
      this.carregarVersaoOrcamento();
      this.getVersoesOrca();
      this.handleSelect();
      this.relatorios = [];
      this.relatorios.push({ label: 'Selecione', value: 0 });

      setTimeout(() => {
        this.httpService.wait();
        this.httpService.get('custom/pbi/listar-relatorios')
          .subscribe(retorno => {

            retorno.forEach(element => {
              this.relatorios.push({ label: element.nome, value: element.id });
            });

          }, error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());
      }, 500);
    }
  }

  getVersoesOrca() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/versao', httpOptions)
      .subscribe(
        res => {
          this.opcoesVersoes = [];
          res["data"].forEach(element => { this.opcoesVersoes.push({ "label": element.descricao, "value": element.id }) });
          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ "error": true, "mensagem": "Não foi possível carregar a lista das versões." });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  carregarImportacaoTipos() {
    this.httpService.wait();
    this.httpService.get('/configuracaoimportacao?$select=id,descricao,selecionarGerencial,selecionarContabil,selecionarCentroCusto,selecionarProjeto,empresa/id,planoGerencial/id' +
      ',tipoOrcamento/id,tipoOrcamento/codigo,tipoOrcamento/descricao,planoGerencial/id,planoGerencial/codigo,planoGerencial/nome,relatorioPbi/id' +
      ',selecionarArea,selecionarSubarea,visaoNivel1/id,visaoNivel2/id,visaoNivel3/id,visaoNivel4/id,visaoNivel5/id,dadosReaisErp,taxaCorporacao,ptax,podeConsolidar,podeMovimentar' +
      ',visaoMensal,visaoYtd,esconderLinhas,visaoDetalhado,versaoReal/id'
      + '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=descricao')
      .subscribe(ref => {
        console.log(ref)
        this.listaImportacaConfiguracao = [];
        ref.value.forEach(element => {
          if (element.relatorioPbi === null || element.relatorioPbi === undefined) {
            element.relatorioPbi = [];
            element.relatorioPbi.id = 0;
          }
          this.listaImportacaConfiguracao.push(element);

        });
      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }

  carregarTipoOrcamentos() {
    this.httpService.wait();
    this.httpService.get('/tipoorcamento?$select=id,codigo,descricao,ativo&$filter=ativo=true')
      .subscribe(ref => {

        this.opcoesTipoOrcamento = [];
        ref.value.forEach(e => {
          this.opcoesTipoOrcamento.push({ label: e.codigo + ' - ' + e.descricao, value: e.id });
        });

      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }

  carregarTipoVisao() {
    this.httpService.wait();
    this.httpService.get('/tipovisao?$select=id,descricao')
      .subscribe(ref => {
        this.opcoesTipoVisao = [];
        ref.value.forEach(e => {
          this.opcoesTipoVisao.push({ label: e.descricao, value: e.id });

          //clones the values ​​of the "opcoesTipoVisao" array so that they are not lost in the "splice" method
          this.cloneVisionsOptions = Array.from(this.opcoesTipoVisao);
        });
      },
        error => this.httpService.handleError(error, () => this.carregarTipoVisao()),
        () => this.httpService.done());
  }

  carregarVersaoOrcamento() {
    this.httpService.wait();
    this.httpService.get('/planogerencial?$select=id,codigo,nome'
      + '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      '&$orderby=nome')
      .subscribe(ref => {

        this.opcoesPlanoGerencial = [];
        ref.value.forEach(element => {
          this.opcoesPlanoGerencial.push({ label: element.codigo + ' - ' + element.nome, value: element.id });
        });

      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  /*hideMessage() {
    this.msgs = [];
    this.msgsModal = [];
  }*/

  criarNovoRegistro() {

    if (this.validacao()) {
      return;
    }

    let listaEnviar = {
      configuracaoImportacao: this.configuracaoImportacao,
      tipoOrcamentoId: this.configuracaoImportacao.tipoOrcamento.id,
      planoGerencialId: this.configuracaoImportacao.planoGerencial ? this.configuracaoImportacao.planoGerencial.id : 0,
      usuarioId: this.sessionService.loggedUser.id,
      criarNovoImportacaoTipo: true,
      relPbi: this.relPbi,
      nivel1: this.listTipoVisao[0].value,
      nivel2: this.listTipoVisao[1] ? this.listTipoVisao[1].value : null,
      nivel3: this.listTipoVisao[2] ? this.listTipoVisao[2].value : null,
      nivel4: this.listTipoVisao[3] ? this.listTipoVisao[3].value : null,
      nivel5: this.listTipoVisao[4] ? this.listTipoVisao[4].value : null,
    };

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/salvar-importacao-configuracao', listaEnviar)
      .subscribe(rst => {
        if (rst.error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Não foi possível registar essa configuração'
          });
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Configuração cadastrada com sucesso!'
          });
          this.closeDialog();
          this.change();
        }
      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }

  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;

    this.listaImportacaConfiguracao.forEach(e => {
      if (this.checkedAll) {
        const i = this.listaImportacaConfiguracao.findIndex(p => p === e);
        this.listaImportacaConfiguracao[i].selected = true;
      } else {
        this.listaImportacaConfiguracao.forEach(e => {
          e.selected = false;
          const i = this.listaImportacaConfiguracao.findIndex(p => p === e);
        });
      }
    });

    this.handleSelect();
  }

  excluirRegistro() {
    if (confirm('Deseja excluir o(s) registro(s) selecionado(s)?')) {

      let arrayIds = new Array<any>();
      for(let configuration of this.selectedConfigurations) {
        arrayIds.push(configuration.id)
      }

      let listaEnviar = {
        importarTipoIds: arrayIds
      };

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/excluir-configuracao-importacao', listaEnviar)
        .subscribe(rst => {

          if (rst.error === true) {
            this.exibirMensagem({ error: true, mensagem: ' Não foi possível deletar essa configuração.' });
          } else {
            this.exibirMensagem({ error: false, mensagem: 'Registro(os) excluídos com sucesso!' });
          }
          this.change();

        },
          error => this.httpService.handleError(error, () => this.excluirRegistro()),
          () => this.httpService.done());

      arrayIds = [];
    }
  }

  carregarInformacaoRegistro(value: any) {
    this.modalState = true;
    this.boolCriarconfiguracaoImportacao = false;
    this.boolEditconfiguracaoImportacao = true;
    this.configuracaoImportacao = new ConfiguracaoImportacao();
    this.configuracaoImportacao.tipoOrcamento = new TipoOrcamento();
    this.configuracaoImportacao.empresa = new Empresa();
    this.configuracaoImportacao.planoGerencial = new PlanoGerencial();
    this.configuracaoImportacao.versaoReal = new Versao()

    let i = this.listaImportacaConfiguracao.findIndex(e => e.id === value);

    this.configuracaoImportacao.id = value;
    this.configuracaoImportacao.descricao = this.listaImportacaConfiguracao[i].descricao;
    this.configuracaoImportacao.selecionarCentroCusto = this.listaImportacaConfiguracao[i].selecionarCentroCusto;
    this.configuracaoImportacao.selecionarProjeto = this.listaImportacaConfiguracao[i].selecionarProjeto;
    this.configuracaoImportacao.selecionarGerencial = this.listaImportacaConfiguracao[i].selecionarGerencial;
    this.configuracaoImportacao.selecionarContabil = this.listaImportacaConfiguracao[i].selecionarContabil;
    this.configuracaoImportacao.selecionarArea = this.listaImportacaConfiguracao[i].selecionarArea;
    this.configuracaoImportacao.selecionarSubarea = this.listaImportacaConfiguracao[i].selecionarSubarea;
    this.configuracaoImportacao.dadosReaisErp = this.listaImportacaConfiguracao[i].dadosReaisErp;
    this.configuracaoImportacao.tipoOrcamento = this.listaImportacaConfiguracao[i].tipoOrcamento;
    this.listaImportacaConfiguracao[i].planoGerencial ? this.configuracaoImportacao.planoGerencial = this.listaImportacaConfiguracao[i].planoGerencial : this.configuracaoImportacao.planoGerencial.id = 0;
    this.configuracaoImportacao.relatorioPbi = this.listaImportacaConfiguracao[i].relatorioPbi;
    this.configuracaoImportacao.taxaCorporacao = this.listaImportacaConfiguracao[i].taxaCorporacao;
    this.configuracaoImportacao.ptax = this.listaImportacaConfiguracao[i].ptax;
    this.configuracaoImportacao.podeMovimentar = this.listaImportacaConfiguracao[i].podeMovimentar;
    this.configuracaoImportacao.podeConsolidar = this.listaImportacaConfiguracao[i].podeConsolidar;
    this.configuracaoImportacao.visaoDetalhada = this.listaImportacaConfiguracao[i].visaoDetalhado;
    this.configuracaoImportacao.visaoMensal = this.listaImportacaConfiguracao[i].visaoMensal;
    this.configuracaoImportacao.visaoYtd = this.listaImportacaConfiguracao[i].visaoYtd;
    this.configuracaoImportacao.esconderLinhas = this.listaImportacaConfiguracao[i].esconderLinhas;
    this.listaImportacaConfiguracao[i].versaoReal ? this.configuracaoImportacao.versaoReal = this.listaImportacaConfiguracao[i].versaoReal : this.configuracaoImportacao.versaoReal.id = 0;

    if (this.listaImportacaConfiguracao[i].visaoNivel1) {
      let linhaTipo = this.opcoesTipoVisao.find(element => element.value === this.listaImportacaConfiguracao[i].visaoNivel1.id);
      this.listTipoVisao.push({ label: linhaTipo.label, value: this.listaImportacaConfiguracao[i].visaoNivel1.id });
      this.opcoesTipoVisao.splice(this.opcoesTipoVisao.indexOf(linhaTipo), 1);
    }

    if (this.listaImportacaConfiguracao[i].visaoNivel2) {
      let linhaTipo = this.opcoesTipoVisao.find(element => element.value === this.listaImportacaConfiguracao[i].visaoNivel2.id);
      this.listTipoVisao.push({ label: linhaTipo.label, value: this.listaImportacaConfiguracao[i].visaoNivel2.id });
      this.opcoesTipoVisao.splice(this.opcoesTipoVisao.indexOf(linhaTipo), 1);
    }

    if (this.listaImportacaConfiguracao[i].visaoNivel3) {
      let linhaTipo = this.opcoesTipoVisao.find(element => element.value === this.listaImportacaConfiguracao[i].visaoNivel3.id);
      this.listTipoVisao.push({ label: linhaTipo.label, value: this.listaImportacaConfiguracao[i].visaoNivel3.id });
      this.opcoesTipoVisao.splice(this.opcoesTipoVisao.indexOf(linhaTipo), 1);
    }

    if (this.listaImportacaConfiguracao[i].visaoNivel4) {
      let linhaTipo = this.opcoesTipoVisao.find(element => element.value === this.listaImportacaConfiguracao[i].visaoNivel4.id);
      this.listTipoVisao.push({ label: linhaTipo.label, value: this.listaImportacaConfiguracao[i].visaoNivel4.id });
      this.opcoesTipoVisao.splice(this.opcoesTipoVisao.indexOf(linhaTipo), 1);
    }

    if (this.listaImportacaConfiguracao[i].visaoNivel5) {
      let linhaTipo = this.opcoesTipoVisao.find(element => element.value === this.listaImportacaConfiguracao[i].visaoNivel5.id);
      this.listTipoVisao.push({ label: linhaTipo.label, value: this.listaImportacaConfiguracao[i].visaoNivel5.id });
      this.opcoesTipoVisao.splice(this.opcoesTipoVisao.indexOf(linhaTipo), 1);
    }
  }

  editarRegistro() {
    if (this.validacao()) {
      return;
    }

    let listaEnviar = {
      importacaoTipoId: this.selectedConfigurations[0].id,
      configuracaoImportacao: this.configuracaoImportacao,
      planoGerencialId: this.configuracaoImportacao.planoGerencial ? this.configuracaoImportacao.planoGerencial.id : 0,
      usuarioId: this.sessionService.loggedUser.id,
      tipoOrcamentoId: this.configuracaoImportacao.tipoOrcamento.id,
      relPbi: this.relPbi,
      criarNovoImportacaoTipo: false,
      nivel1: this.listTipoVisao[0].value,
      nivel2: this.listTipoVisao[1] ? this.listTipoVisao[1].value : null,
      nivel3: this.listTipoVisao[2] ? this.listTipoVisao[2].value : null,
      nivel4: this.listTipoVisao[3] ? this.listTipoVisao[3].value : null,
      nivel5: this.listTipoVisao[4] ? this.listTipoVisao[4].value : null,
    };

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/salvar-importacao-configuracao', listaEnviar)
      .subscribe(rst => {
        if (rst.error === true) {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'MSG: ', detail: 'Não foi possível editar essa configuração.' });
          //this.msgs = [{ severity: 'error', summary: 'MSG: ', detail: 'Não foi possível editar essa configuração' }];

        } else {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: 'Configuração editada com sucesso!' });
          //this.msgs = [{ severity: 'success', summary: 'MSG: ', detail: 'Configuração editada com sucesso!' }];
          this.boolEditconfiguracaoImportacao = false;
          this.change();
        }
      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }

  cancelar() {
    this.modalState = false;
    this.boolCriarconfiguracaoImportacao = false;
    this.boolEditconfiguracaoImportacao = false;
    this.resetArrayVisionsOptions();
  }

  contagemNiveis(): number {
    const arrayFiltrado = this.listTipoVisao.filter((tipo) => {
      if (tipo.value) {
        return tipo;
      }
    });

    this.listTipoVisao = arrayFiltrado;
    return arrayFiltrado.length;
  }

  validacao() {
    if (!this.configuracaoImportacao.tipoOrcamento.id) {
      this.exibirMensagem({ error: true, mensagem: 'Selecione um tipo de orçamento.' });
      return true;
    }

    if (!this.configuracaoImportacao.descricao) {
      this.exibirMensagem({ error: true, mensagem: 'Informe uma descrição.' });
      return true;
    }

    if (this.configuracaoImportacao.selecionarGerencial && !this.configuracaoImportacao.planoGerencial) {
      this.exibirMensagem({ error: true, mensagem: 'Selecione um plano gerencial.' });
      return true;
    }

    if (this.listTipoVisao.length < 1 || this.listTipoVisao.length > 5) {
      this.exibirMensagem({ error: true, mensagem: 'Os níveis vinculados devem ter entre 1 e 5 itens.' });
      return true;
    }

    return false;
  }
  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  showModalNovoRegistro() {
    this.modalState = true;
    this.boolCriarconfiguracaoImportacao = true;
    this.boolEditconfiguracaoImportacao = false;

    this.configuracaoImportacao = new ConfiguracaoImportacao();
    this.configuracaoImportacao.empresa = new Empresa();
    this.configuracaoImportacao.tipoOrcamento = new TipoOrcamento();
    this.configuracaoImportacao.planoGerencial = new PlanoGerencial();
    this.configuracaoImportacao.empresa = this.dblinkedSessionService.empresa;
    this.configuracaoImportacao.dadosReaisErp = true;
    this.configuracaoImportacao.selecionarContabil = true;
    this.configuracaoImportacao.visaoDetalhada = true;
    this.configuracaoImportacao.visaoMensal = true;
    this.configuracaoImportacao.esconderLinhas = true;
  }

  closeDialog() {
    this.modalState = false;
    this.boolCriarconfiguracaoImportacao = false;
    this.boolEditconfiguracaoImportacao = false;
    this.resetArrayVisionsOptions();
  }

  mudarSelecaoPlano(id: number) {
    if (id === 1) {
      this.configuracaoImportacao.selecionarContabil = !this.configuracaoImportacao.selecionarGerencial;

    } else {
      this.configuracaoImportacao.selecionarGerencial = !this.configuracaoImportacao.selecionarContabil;
    }
  }

  handleSelect(): void {
    this.toolbarMain.setVisible("btn1", this.selectedConfigurations.length  === 1 ? true : false);
    this.toolbarMain.setVisible("btn2", this.selectedConfigurations.length  >= 1 ? true : false);
  }

  exportAll() {
    let exportList = [];
    const orderList = ['descricao', 'tipo', 'planoGerencial', 'projeto', 'centroCusto', 'contabil', 'gerencial']
    this.listaImportacaConfiguracao.forEach(item => {
      exportList.push({
        descricao: item.descricao,
        tipo: item.tipoOrcamento.descricao,
        planoGerencial: item.planoGerencial ? item.planoGerencial.nome : null,
        projeto: item.selecionarProjeto,
        centroCusto: item.selecionarCentroCusto,
        contabil: item.selecionarContabil,
        gerencial: item.selecionarGerencial
      });
    })
    exportExcel(exportList, "Configurações Orçamento", orderList)
  }

  //redefines the "opcoesTipoVisao" array passing to it the values ​​of its clone (cloneVisionsOptions)
  resetArrayVisionsOptions(): void {
    this.listTipoVisao = [];
    this.opcoesTipoVisao = Array.from(this.cloneVisionsOptions);
  }
}