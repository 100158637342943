import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Subscription } from 'rxjs/Subscription';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { TpConfigRelat } from '../models/tp-config-relat.model';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.scss'],
})
export class RelatoriosTPComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;

  msgs: Message[] = null;
  msgsModal: Message[] = null;
  title: string;
  subtitle: string;

  //relatório
  tpConfigRelat: TpConfigRelat;
  modalRelatorio: boolean = false
  dataRef: Date = new Date();
  pt: any;
  opcoesMeses: SelectItem[] = [];
  mesIni: number = null;
  mesFin: number = null;

  constructor(private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private messageService: MessageService,
    private dblinkedSessionService: DblinkedSessionService) { }

  ngOnInit(): void {
    this.title = 'Relatório';
    this.subtitle = 'Transfer Pricing';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    let meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    for(let i in meses) {
      this.opcoesMeses.push({ label: meses[i], value: i });
    }

    console.log(this.opcoesMeses)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
          this.tpConfigRelat = new TpConfigRelat();
          this.getRelatorios();
        }
      });
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  exibirMensagem(value: any) {
    const detail = (value.mensagem || value.message || value.response || value.msg);
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail });
    }
  }

  hideMessage() {
    this.msgs = [];
  }

  showDialogRelatorio() {
    this.modalRelatorio = true;
  }

  getRelatorios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'fiscal/tp-config-relat', httpOptions)
      .subscribe(
        res => {
          this.tpConfigRelat = new TpConfigRelat();

          if (!res["error"]) {
            if (res["relatorio"] != null) {
              let relatorio = res["relatorio"];

              this.tpConfigRelat.id = relatorio.id;
              this.tpConfigRelat.nome = relatorio.nome;
              this.tpConfigRelat.codigo = relatorio.codigo;
              this.exibirMensagem(res);
            } else {
              this.exibirMensagem({ error: true, mensagem: "Nenhum Relatório foi encontrado" });
            }
          } else {
            this.exibirMensagem(res);
          }

          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        }
      );
  }

  gerarRelatorio() {
    if(this.mesIni == null) return this.exibirMensagem({"error": true, "mensagem": "Selecione o mês inicial."});
    if(this.mesFin == null) return this.exibirMensagem({"error": true, "mensagem": "Selecione o mês final."});

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      }),
      responseType: 'blob' as 'json'
    };

    let dtIni = new Date(this.dblinkedSessionService.periodo.year.value, this.mesIni, 1);
    let dtFin = new Date(this.dblinkedSessionService.periodo.year.value, parseInt(this.mesFin+"") + 1, 0);
    //getting month name
    let monthIni = dtIni.toLocaleDateString('default', { month: 'long' })
    let monthFin = dtFin.toLocaleDateString('default', { month: 'long' })

    const infos = {
      configid: this.tpConfigRelat.id,
      empresaid: this.dblinkedSessionService.empresa.id,
      dtIni: dtIni.getFullYear() + "-" + (dtIni.getMonth() + 1) + "-" + dtIni.getDate(),
      dtFin: dtFin.getFullYear() + "-" + (dtFin.getMonth() + 1) + "-" + dtFin.getDate(),
      user_id: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'util/relat-sobre-demanda', infos, httpOptions)
      .subscribe(
        (res: Blob) => {
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(res)
            let fileName = `CALC_TP_${this.dblinkedSessionService.empresa.nomeFantasia}_${this.dblinkedSessionService.periodo.year.value}(${monthIni}-${monthFin}).xlsx`
            link.href = url
            link.download = fileName
            link.click()
            window.URL.revokeObjectURL(url)
            this.httpService.done()
        }, err => {
          this.blobError(err)
          this.httpService.done()
        })
  }

  private blobError(httpErrorBlob: HttpErrorResponse): void {
    const fileBlob = new Blob([httpErrorBlob.error], {type: 'application/json'})
    const fileReader = new FileReader()

    fileReader.readAsText(fileBlob)

    fileReader.addEventListener('loadend', (fileProcessed: ProgressEvent<FileReader>) =>
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: JSON.parse(fileProcessed.target?.result as string)?.message ?? 'Falha ao realizar download do arquivo!' })
    )
  }
}
