import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService } from 'primeng/api';
import { isNullOrUndefined } from 'util';
import { CnpjPipe } from '../../shared/pipes/cnpj.pipe';
import { stringify } from 'querystring';

@Component({
  selector: 'app-distrib-gp',
  templateUrl: './distrib-gp.component.html',
  styleUrls: ['./distrib-gp.component.scss'],
})
export class DistribGPComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;
  private cnpjPipe: CnpjPipe = new CnpjPipe();

  msgs: Message[] = [];
  titlePrincipal: string;
  subtitle: string;

  empresas : SelectItem[];
  empresaId : number;
  listaGatewaysAtribuidos : Array<any> = new Array();
  listaGateways : Array<any> = new Array();

  picklistState: boolean = false

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }


  data() {
    // this.dtCorte = new Date(this.timezonePipe.transform(this.dtCorte));
  }


  ngOnInit(): void {
    // requisição

    this.titlePrincipal = 'CONFIGURAÇÕES';
    this.subtitle = 'Distribuição de Gateways';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.empresaId = 0;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });
    }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }


  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

        this.listaGatewaysAtribuidos = new Array();
        this.listaGateways = new Array();

        this.buscarGateways();

      // renderizando a toolbar
      setTimeout(() => {
        this.toolbarEdit();
      }, 500);
    }
  }

  onFilterRms(event: any) {
    console.log('event: ' + event);
  }

  export() {
    // alert('export');
  }

  pesquisa() {
    // alert('pesquisa');
  }


 hideMessage() {

  }



  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  salvarRelacionamento(){

    if (this.validacao()) {
      return;
    }


    if (confirm('Deseja realmente salvar este relacionamento?')) {
      const req = {
        empresaId                 : this.dblinkedSessionService.empresa.id,
        listaGateways             : this.listaGateways,
        listaGatewaysAtribuidos   : this.listaGatewaysAtribuidos,
      };



      this.httpService.wait();
      this.httpService.post('/custom/cadastro/salvar-relacionamento-conf-gateway', req)
      .subscribe(result => {
        if(result.error){
          this.messageService.add({
            severity: result.severity,
            summary:  result.summary,
            detail:   result.detail
          });
        }else{
          this.messageService.add({
            severity: result.severity,
            summary:  result.summary,
            detail:   result.detail
          });
        }
        this.change();

        },
          error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
          () => this.httpService.done());

    }



  }



  validacao(){




    if (isNullOrUndefined(this.dblinkedSessionService.empresa.id) || this.dblinkedSessionService.empresa.id === 0 ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma empresa'
      });
      return true;
    }

    return false;

  }

  tabChange(event: any){
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Relacionamento', () => this.salvarRelacionamento());
  }


  buscarGateways(){
    this.listaGatewaysAtribuidos = new Array();
    this.listaGateways = new Array();

    this.picklistState = false

    this.httpService.wait();
    this.httpService.get('custom/cadastro/get-lista-gateways-relac', [this.dblinkedSessionService.empresa.id])
      .subscribe(retorno => {
        this.listaGatewaysAtribuidos  = new Array();
        this.listaGateways            = new Array();
        this.picklistState = true
        retorno.forEach(element => {
            if(element.relacionado){
            this.listaGatewaysAtribuidos.push(element);
          }else{
            this.listaGateways.push(element);
          }
        });
      }, error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done() );
  }

  private toolbarEdit() {
    const todos = this.buttons.slice();
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('undo');

    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Relacionamento', () => this.salvarRelacionamento());


  }


}