import { AuthUser } from 'app/proof/model/auth-user.model';


export class ComissaoBase {
  id: number;
  codigo: string;
  descricao: string;
  valueFormula: string;
  paymentFormula: string;
  dtInicial: Date;
  dtFinal: Date;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;

  constructor(comissaoBase?: ComissaoBase | number) {
    if (comissaoBase !== undefined && comissaoBase !== null) {
      if (typeof comissaoBase === 'number') {
        this.id = comissaoBase;
      } else {
        this.id = comissaoBase.id;
        this.codigo = comissaoBase.codigo;
        this.descricao = comissaoBase.descricao;
        this.valueFormula = comissaoBase.valueFormula;
        this.paymentFormula = comissaoBase.paymentFormula;
        this.dtInicial = comissaoBase.dtInicial;
        this.dtFinal = comissaoBase.dtFinal;
        this.createTimestamp = comissaoBase.createTimestamp;
        this.changeUser = comissaoBase.changeUser;
        this.changeTimestamp = comissaoBase.changeTimestamp;
      }
    }
  }
}
