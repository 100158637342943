import { isNullOrUndefined } from "util";
import { FormularioCamposCarga } from 'app/eventos/model/formulario-campos-carga.model';
import { FormularioAgendamentoStatus } from 'app/eventos/model/formulario-agendamento-status.model';
import { FormularioMotivoCancelamento } from 'app/eventos/model/formulario-motivo-cancelamento.model';
import { FormularioMotivoReagendamento} from 'app/eventos/model/formulario-motivo-reagendamento.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Seminarista } from 'app/eventos/model/seminarista.model';

export class FormularioAgendamentoHistorico{

  id                            : number;
  formularioCamposCarga         : FormularioCamposCarga;
  formularioCamposCargaId       : number;
  formularioAgendamentoStatus   : FormularioAgendamentoStatus;
  formularioAgendamentoStatusId : number;
  formularioMotivoCancelamento  : FormularioMotivoCancelamento;
  formularioMotivoCancelamentoId  : number;
  formularioMotivoReagendamentoId : number;
  formularioMotivoReagendamento   : FormularioMotivoReagendamento;

  observacao                      : string;
  data                            : Date;
  dataReagendamento               : Date;
  dataInicio                      : Date;
  dataFinal                       : Date;
  dataCancelamento                : Date;
  usuarioResponsavelId            : number
  usuarioResponsavel              : Seminarista;

  constructor(formularioAgendamentoHistorico?: FormularioAgendamentoHistorico | number) {
    if (!isNullOrUndefined(formularioAgendamentoHistorico)) {
      if (typeof formularioAgendamentoHistorico === 'number') {
        this.id = formularioAgendamentoHistorico;
      } else {
        this.id                      = formularioAgendamentoHistorico.id;
        this.formularioCamposCarga   = formularioAgendamentoHistorico.formularioCamposCarga;
        this.formularioCamposCargaId = formularioAgendamentoHistorico.formularioCamposCargaId;
        this.formularioAgendamentoStatus    = formularioAgendamentoHistorico.formularioAgendamentoStatus;
        this.formularioAgendamentoStatusId  = formularioAgendamentoHistorico.formularioAgendamentoStatusId;
        this.formularioMotivoCancelamento   = formularioAgendamentoHistorico.formularioMotivoCancelamento;
        this.formularioMotivoCancelamentoId = formularioAgendamentoHistorico.formularioMotivoCancelamentoId;
        this.observacao                     = formularioAgendamentoHistorico.observacao;
        this.dataReagendamento              = formularioAgendamentoHistorico.dataReagendamento;
        this.formularioMotivoReagendamento  = formularioAgendamentoHistorico.formularioMotivoReagendamento;
        this.formularioMotivoReagendamentoId = formularioAgendamentoHistorico.formularioMotivoReagendamentoId;
        this.dataInicio                      = formularioAgendamentoHistorico.dataInicio;
        this.dataFinal                       = formularioAgendamentoHistorico.dataFinal;
        this.dataCancelamento                = formularioAgendamentoHistorico.dataCancelamento;
        this.usuarioResponsavelId            = formularioAgendamentoHistorico.usuarioResponsavelId;
        this.usuarioResponsavel              = formularioAgendamentoHistorico.usuarioResponsavel;
      }
    }
  }

}