import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { LoginService } from 'app/shared/services/login.service';
import { AppComponent } from '../../app.component';
import { CrudService } from 'app/shared/services/crud.service';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Period } from 'app/shared/components/period-select/period.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-lcto-encerramento',
  templateUrl: './lcto-encerramento.component.html',
  styleUrls: ['./lcto-encerramento.component.scss'],
  providers: [CrudService]
})
export class EcdLctoEncerramentoComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  disabled = false;

  lancamentoPartida: Array<any> = new Array();
  previewLcto: Array<any> = new Array();
  planoContasOpcoes: Array<PlanoContas> = new Array();
  opcoesPlanoContas: SelectItem[];
  contaSelecionada: PlanoContas;
  resultado: number;
  title: string;
  subtitle: string;
  dtEncerramento: Date;
  constructor(private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent) {
  }

  ngOnInit(): void {
    this.title = 'LANÇAMENTO DE ENCERRAMENTO';
    this.subtitle = 'RESULTADO DO EXERCÍCIO';
    this.titleService.title = 'LANÇAMENTO DE ENCERRAMENTO';
    // Promise.resolve(null).then(() => this.toolbarRefresh());
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.dtEncerramento = new Date();


    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.change(); });
  }

  loadParteB(value: Array<PlanoContas>) {
    this.planoContasOpcoes = value;
    if (this.planoContasOpcoes.length > 0) {
      this.planoContasOpcoes.filter(p => p.codNaturezaConta.id === 3).forEach(element => {
        this.opcoesPlanoContas.push({ label: element.codCta + ' - ' + element.descrCta, value: element });
      });
    }
  }

  ngOnDestroy() {
    // this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    // this.toolbarService.clear();

    this.opcoesPlanoContas = [];
    this.opcoesPlanoContas.push({ label: 'Selecione uma conta de Patrimonio Líquido', value: null });

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasYearSelected) {
      this.httpService.wait();
      this.httpService.get('/cadecd?$select=status/id' +
        '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
        ' and period(dtIni,dtFin) eq ' + this.dblinkedSessionService.periodo.year.value)
        .subscribe(ecd => {
          this.disabled = ecd.value.find(e => this.isHomologado(e)) !== undefined;

          if (this.disabled) {
            this.load();
          } else {
            this.httpService.wait();
            this.httpService.get('/cadecf?$select=status/id' +
              '&$filter=empresa/id eq ' + this.empresa.id +
              ' and period(dtIni,dtFin) eq ' + this.year)
              .subscribe(ecf => {
                this.disabled = ecf.value.find(e => this.isHomologado(e)) !== undefined;

                this.load();
              },
                error => this.httpService.handleError(error, () => this.change()),
                () => this.httpService.done());
          }
        },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
    }
    // this.toolbarRefresh();
  }

  load() {
    this.httpService.wait();
    this.httpService.get('/custom/ecd/planocontas', [this.dblinkedSessionService.empresa.id, this.year])
      .subscribe(planoContasList => this.loadParteB(planoContasList.value),
        error => this.httpService.handleError(error, () => this.load()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/custom/ecd/partida-encerramento(' +
      this.dblinkedSessionService.empresa.id + ',' + this.year +')')
      .subscribe(lancamentoPartida => this.lancamentoPartida = lancamentoPartida,
        error => this.httpService.handleError(error, () => this.load()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/custom/ecd/previewencerramento(' +
      this.dblinkedSessionService.empresa.id + ',' + this.year  +  ',' + this.month + ')')
      .subscribe(previewLcto => this.previewLcto = previewLcto,
        error => this.httpService.handleError(error, () => this.load()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/custom/ecd/previewencerramentovalor(' +
      this.dblinkedSessionService.empresa.id + ',' + this.year  +  ',' + this.month + ')')
      .subscribe(resultado => this.resultado = resultado,
        error => this.httpService.handleError(error, () => this.load()),
        () => this.httpService.done());

    // this.toolbarRefresh();
  }

  deletarEncerramento() {
    if (confirm('Deseja deletar o lançamento de encerramento?')) {
      this.httpService.wait();
      this.httpService.post('/custom/ecd/deletarencerramento(' + this.year + ',' + this.dblinkedSessionService.empresa.id + ')')
        .subscribe(result => {
          alert('Lançamento deletado com sucesso!');
          this.change();
        },
          error => this.httpService.handleError(error, () => this.deletarEncerramento()),
          () => this.httpService.done());
    }
  }

  lancarEncerramento() {
    if(isNullOrUndefined(this.dtEncerramento)){
      alert('É necessário informar a data de encerramento');
      return;
    }else{
      if (confirm('Deseja executar a rotina de encerramento?')) {

        const listasEnviar = {
          contaSelecionada: this.contaSelecionada.id,
          empresa: this.dblinkedSessionService.empresa.id,
          dtEncerramento: this.dtEncerramento
        };

        this.httpService.wait();
        this.httpService.post('/custom/ecd/lancarencerramento',listasEnviar)
          .subscribe(result => {
            alert('Lançamento efetuado com sucesso!');
            this.change();
          },
            error => this.httpService.handleError(error, () => this.lancarEncerramento()),
            () => this.httpService.done());
      }
    }


  }

   /*private toolbarRefresh() {
   this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }*/

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get periodo(): Period {
    return this.dblinkedSessionService.periodo;
  }

  get year(): number {
    return this.dblinkedSessionService.periodo.year.value;
  }

  get month(): number {
    return this.dblinkedSessionService.periodo.month.value;
  }

  isApurado(ecdOrEcf: any) {
    return ecdOrEcf.status && ecdOrEcf.status.id === 2;
  }

  isHomologado(ecdOrEcf: any) {
    return ecdOrEcf.status && ecdOrEcf.status.id === 3;
  }
}
