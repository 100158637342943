import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { PlanoGerencial } from '../model/plano-gerencial.model';
import { PlanoGerencialContas } from '../model/plano-gerencial-contas.model';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from '../../shared/services/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilService } from '../../shared/services/util.service';
import { FormGroup } from '@angular/forms';
import { CodNaturezaConta } from '../../util/model/cod-natureza-conta.model';
import { SelectItem, Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ConfigService } from '../../shared/services/config.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { PlanoContas } from '../model/plano-contas.model';
import { CadCcus } from '../model/cad-ccus.model';
import { CadProj } from '../model/cad-proj.model';
import { Idioma } from '../../util/model/idioma.model';
import { PlanoGerencialContasInternacionalizacao } from '../model/plano-gerencial-contas-internacionalizacao.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { isNullOrUndefined } from 'util';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ViewCampos } from 'app/cadastro/model/view-campos.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';
import exportExcel from 'app/util/processing/exportExcel';


@Component({
  selector: 'app-planogerencialnew',
  templateUrl: './planogerencialnew.component.html',
  styleUrls: ['./planogerencialnew.component.scss'
  ],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class PlanoGerencialNewComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public planogerencialcontas: PlanoGerencialContas = new PlanoGerencialContas();

  planoGerencial: PlanoGerencial = new PlanoGerencial();

  private sessionSubscription: Subscription;
  private beforeViewSubscription: Subscription;

  public contasForm: FormGroup;

  entityFilter: string = null;

  newPc: boolean;
  editPc: boolean;
  editGerenciais: boolean;
  viewPc: boolean;

  newCpg: boolean;
  editCpg: boolean;
  viewCpg: boolean;
  viewAbas: boolean;
  checkedAll: boolean;
  liplanoGerencial: Array<any> = new Array();
  licontaGerencial: Array<any> = new Array();
  msgs: Message[];
  msgsAdv: Message[];

  planoContasListCheckAll: boolean;
  planoContasListRelacionadosCheckAll: boolean;

  title: String;
  subtitle: String;
  showvizualizacao: boolean;
  opcoesPlanoGerencial: SelectItem[];
  opcoesNatureza: SelectItem[];
  opcoesIndicador: SelectItem[];
  opcoesNivel: SelectItem[];
  opcoesEmpresa: SelectItem[];
  opcoesContas: SelectItem[];
  opcoesMoeda: SelectItem[];
  opcoesView: SelectItem[];
  opcoesIdioma: SelectItem[];
  opcoesCentroCusto: SelectItem[];
  opcoesProjeto: SelectItem[];
  contasGerenciais: any;
  planosGerenciais: any;

  idSelecionado: Array<any> = new Array();
  idContaSelecionado: Array<any> = new Array();
  centrosCusto: Array<any> = new Array();
  views: Array<any> = new Array();
  projetos: Array<any> = new Array();
  selectedContasNaoVinc: Array<any> = new Array();
  selectedContasVinc: Array<any> = new Array();


  contaSelecionada = false;
  planoContasSelectedList: Array<PlanoContas> = new Array();
  planoContasGerencialRelacList: Array<number> = new Array();
  contasInternacionalizacao: Array<PlanoGerencialContasInternacionalizacao> = new Array();

  centroCusto: CadCcus = new CadCcus();
  cadProj: CadProj = new CadProj();
  idioma: Idioma = new Idioma();

  modalPlanoGerencial: boolean;
  modalContaPlanoGerencial: boolean;
  modalInternacionalizacao: boolean;
  cloneModal: boolean;
  empresaClone: Empresa;

  aba1: boolean = true;
  aba2: boolean = false;
  aba3: boolean = false;
  // aba4: boolean = false;
  vinculacao: boolean = true;
  cc: boolean = true;
  cp: boolean = false;
  view: boolean = false;
  viewContaGer: boolean = false;
  modalUploadGerencial = false;
  modalClonePG = false;
  listEmpresas: Array<any> = [];
  empresasSelecionadas: Array<number> = [];
  colSQL: boolean = false;
  invertResulta: boolean = false;

  planoContasList: Array<any> = new Array();
  planoContasListVisiveis: Array<any> = new Array();
  planoContasVincListVisiveis: Array<any> = new Array();
  planoContasVincList: Array<any> = new Array();

  planoGerencialCodigo: string;
  planoGerencialCloneCodigo: string;
  planoGerencialNome: string;
  planoGerencialNomeNew: string;
  planoGerencialNivel: number;
  planoGerencialMoeda: number;
  planoGerencialView: number;
  planoGerencialId: number;
  listaGerencialId: Array<any> = new Array();
  planogerencialsel: number;
  planogerencialcod: number;
  opcoesRecbDeb: SelectItem[];

  planoid: number;
  ordemseq: number;
  contaselecionados: boolean;
  contaGerencialId: Array<any> = new Array();


  viewCampos: Array<ViewCampos> = new Array();

  //Configurações da conta para relatório
  confLineAbove: boolean = false;
  confLineBelow: boolean = false;
  confBold: boolean = false;
  confLineColor: string = null;
  isLinhaCor: boolean = false;
  confFontColor: string = null;
  isFonteCor: boolean = false;

  selectedPlanoGerencialContaId: number = null;
  isVinculacaoPg: boolean = false;
  opcoesPgVinculacao: SelectItem[];
  selectedPgVinculacao: Array<number> = new Array();
  selectAllPgcVinculacao: boolean = false;
  listPgcVinculacao: Array<any> = new Array();

  //Condição total
  opcoesCondicaoTotal: SelectItem[] = [{ "label": null, "value": null },
  { "label": ">", "value": ">" },
  { "label": "≥", "value": ">=" },
  { "label": "<", "value": "<" },
  { "label": "≤", "value": "<=" },
  { "label": "=", "value": "==" }
  ];
  numeroCondicaoTotal: number = 0;

  //Tipo Ccus
  opcoesTipoCcus: SelectItem[] = []
  vincTipoCcusId: number = null

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private messageService: MessageService,
    private httpClient: HttpClient) {
    super(crudService);
  }
  @ViewChild('file') fileInput: ElementRef;
  file: File = null;
  filename: string = null;

  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { key: 'btn-clone-plan', icon: 'content_copy', text: 'Clonar Plano', tooltip: 'Clonar Plano', visible: false, disabled: false, color: 'default', onClick: () => this.showModalclonarPlano()},
    { key: 'btn-edit-plan', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.editPg()},
    { key: 'btn-delete-plan', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.removePg() },
    { key: 'btn-export-plan', icon: 'text_snippet', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.exportExcelAll() },
    { key: 'btn-add-plan', icon: 'add', text: 'Novo', tooltip: 'Novo Plano Gerencial', visible: true, disabled: false, color: 'default', onClick: () => this.addPg() },
    // editar
    { key: 'btn-back', icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.voltarEdit() },
    { key: 'btn-upload', icon: 'backup', text: '', tooltip: 'Upload plano Gerencial', visible: false, disabled: false, color: 'default', onClick: () => this.showModalUploadGerencial() },
    { key: 'btn-edit-account', icon: 'edit', text: 'Editar', tooltip: 'Editar Conta', visible: false, disabled: false, color: 'default', onClick: () => this.editarContaPlanoGerencial() },
    { key: 'btn-delete-account', icon: 'delete', text: 'Remover', tooltip: 'Remover Conta', visible: false, disabled: false, color: 'default', onClick: () => this.removePc() },
    { key: 'btn-export-account', icon: 'text_snippet', text: 'Exportar', tooltip: 'Exportar', visible: false, disabled: false, color: 'default', onClick: () => this.exportPgc() },
    { key: 'btn-add-account', icon: 'add', text: 'Novo', tooltip: 'Nova Conta', visible: false, disabled: false, color: 'default', onClick: () => this.addConta() },
    { key: 'btn-export-report', icon: 'summarize', text: 'Vinculações', tooltip: 'Vinculações de contas', visible: false, disabled: false, color: 'default', onClick: () => this.baixarRelatorioVinculacoes() },
    {
      key: 'btn-expand-colapse', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.changeStateMov()
        this.ShowHideSelectItem()
      }
    },
  ]

  // (click)="ShowHIdeMov()" (click)="changeStateMov()"

  ngOnInit() {
    // TODO: REVIEW (IMPORTANT!)
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.modalPlanoGerencial = false;
    this.editCpg = false;
    this.editPc = false;
    this.viewCpg = false;
    this.editGerenciais = false;
    this.contaselecionados = false;
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.title = 'PLANO GERENCIAL';
    this.subtitle = 'CADASTRE E CONTROLE O PLANO GERENCIAL E SUAS CONTAS';

    this.contaGerencialId = [];
    this.listaGerencialId = [];
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.empresaChanged(); });
  }

  isCardSelectItem: boolean = true;
  ShowHideSelectItem() {
    this.isCardSelectItem = !this.isCardSelectItem;
    this.toolbarMain.setIcon('btn12', this.isCardSelectItem ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn12', this.isCardSelectItem ? 'Ver menos' : 'Ver mais')
  }

  @ViewChild('dt') dt: Table
  exportMov() {
    this.dt.exportCSV()
  }

  checkCorFonte() {
    if (this.isFonteCor) {
      this.confFontColor = null;
    }
  }

  selectCheckboxContainer(data){
    setTimeout(() => {
      document.getElementById('checkboxContainer'+data.id).click();
    },100);
  };

  exportPgc() {
    const infos = {
      pg_id: this.planoGerencial.id
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'contabil/export-plano-contas-gerencial', infos)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();

          } else {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0'
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/export-plano-contas-gerencial', httpOptions2)
              .subscribe(
                (res: any) => {
                  if (res["error"]) {
                    this.exibirMensagem(res);

                  } else {
                    let link = document.createElement('a');
                    let url = window.URL.createObjectURL(res);
                    let fileName = "Relatório gerencial.xlsx";
                    link.href = url;
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(url);
                  }

                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o relatório: " + err.message });
                  console.log(err);
                  this.httpService.done();
                });
          }
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível gerar o relatório: " + err.message });
          console.log(err);
          this.httpService.done();
        });
  }

  showModalUploadGerencial() {
    this.modalUploadGerencial = true;
    this.file = null;
    this.filename = null;
  }

  showModalclonarPlano() {
    Promise.resolve(this.getListEmpresas()).then(res => {
      this.modalClonePG = true;
      this.planoGerencialCloneCodigo = this.planosGerenciais.find(element => this.listaGerencialId[0] === element.id).codigo;
      this.planoGerencialNomeNew = this.planosGerenciais.find(element => this.listaGerencialId[0] === element.id).nome;
      this.colSQL = true;
    });
  }

  checkCorLinha() {
    if (this.isLinhaCor) {
      this.confLineColor = null;
    }
  }

  empresaChanged() {

    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.handleChangeCheckBoxState()
      // this.changeToolbarUn()
      // this.toolbarMain.setVisible('btn8', false)
      // this.toolbarMain.setVisible('btn5', true)
      // this.toolbarMain.setVisible('btn6', false)
      // this.toolbarMain.setVisible('btn7', false)
      // this.toolbarMain.setVisible('btn12', true)
      // this.toolbarMain.setVisible('btn11', false)
      // this.toolbarMain.setVisible('btn9', false)
      // this.toolbarMain.setVisible('btn10', false)

      this.planoContasListCheckAll = false;
      this.planoContasListVisiveis = new Array();
      this.planoContasListRelacionadosCheckAll = false;
      this.checkedAll = false;

      this.planoGerencialCodigo = '';
      this.planoGerencialNome = '';
      this.planoGerencialNivel = 0;
      this.planoGerencialMoeda = 0;
      this.planoGerencialView = 0;
      this.planoGerencialId = 0;
      this.modalPlanoGerencial = false;
      this.contaselecionados = false;
      this.planogerencialcontas = new PlanoGerencialContas();
      this.planogerencialcontas.planoGerencial = new PlanoGerencial();
      this.planogerencialcontas.codNaturezaConta = new CodNaturezaConta();
      this.planogerencialcontas.codNaturezaConta.id = 0;
      this.planogerencialcontas.nivel = 1;
      this.planogerencialcontas.ctaSup = new PlanoGerencialContas();
      this.planogerencialcontas.ctaSup.id = 0;
      this.planogerencialcontas.ordem = 0;
      this.planogerencialcontas.inverterSinal = false;
      this.modalUploadGerencial = false;
      this.liplanoGerencial = new Array();
      this.licontaGerencial = new Array();
      this.getListEmpresas();
      this.viewCampos = new Array();
      this.listaGerencialId = new Array();

      this.opcoesNivel = [];
      for (let index = 1; index <= 5; index++) {
        this.opcoesNivel.push({ label: index.toString(), value: index });
      }

      this.opcoesIndicador = [];
      this.opcoesIndicador.push({ label: 'Nenhum', value: null });
      this.opcoesIndicador.push({ label: 'A', value: 'A' });
      this.opcoesIndicador.push({ label: 'S', value: 'S' });
      this.opcoesIndicador.push({ label: 'T', value: 'T' });
      this.opcoesIndicador.push({ label: 'N', value: 'N' });
      this.opcoesIndicador.push({ label: 'E', value: 'E' });

      this.opcoesRecbDeb = [];
      this.opcoesRecbDeb.push({ label: 'Nenhum', value: null });
      this.opcoesRecbDeb.push({ label: 'R', value: 'R' });
      this.opcoesRecbDeb.push({ label: 'D', value: 'D' });


      this.httpService.wait();
      this.httpService.get('/moeda?$select=id,codigo')
        .subscribe(ref => this.loadMoedas(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/view?$select=id,nome')
        .subscribe(ref => this.loadViews(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/planogerencial?$select=id,codigo,nome,niveis,moeda,view/id&$' +
        'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
        .subscribe(ref => this.planosGerenciais = ref.value,
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/codnaturezaconta?$select=id,cod,descricao')
        .subscribe(ref => this.loadNatureza(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait()
      this.httpService.get('/tipoccus?$select=id,codigo,descricao')
        .subscribe(ref => {
          this.opcoesTipoCcus.push({label: "Nenhum", value: null})
          ref.value.forEach(row => this.opcoesTipoCcus.push({label: row.codigo + " - " + row.descricao, value: row.id}))
        },
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());
    }
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.messageService.add({ severity: 'warn', summary: 'Erro', detail: value.mensagem });
    }
  }

  loadMoedas(value: Array<any>) {

    this.opcoesMoeda = [];
    this.opcoesMoeda.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesMoeda.push({ label: element.codigo, value: element.id });
    });
  }

  loadViews(value: Array<any>) {

    this.opcoesView = [];
    this.opcoesView.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesView.push({ label: element.nome, value: element.id });
    });
  }


  onRowSelect(event: any) {
    this.editGerenciais = true;
    this.planogerencialsel = event.id;

    this.title = 'EDITAR PLANO GERENCIAL';
    this.subtitle = 'EDITANDO PLANO GERENCIAL: ' + event.codigo + ' - ' + event.nome;
    this.planoGerencial = event;
    this.planoGerencialView = event.view !== null ? event.view.id : 0;
    this.buscarGerencialContas();

    // this.toolbarMain.setVisible('btn7', false)
    // this.toolbarMain.setVisible('btn6', true)
    // this.toolbarMain.setVisible('btn5', false)
    // this.toolbarMain.setVisible('btn8', false)
    // this.toolbarMain.setVisible('btn6', true)
    // this.toolbarMain.setVisible('btn11', true)
    // this.toolbarMain.setVisible('btn12', true)
    this.handleChangeCheckBoxState()
  }


  buscarGerencialContas() {
    this.modalContaPlanoGerencial = false;
    this.contaselecionados = false;
    this.httpService.wait();
    this.httpService.get('/planogerencialcontas?$select=id,codCta,descrCta,' +
      'codNaturezaConta/id,codNaturezaConta/descricao,codNaturezaConta/cod,indCta,nivel,ctaSup/id,ctaSup/codCta,ctaSup/descrCta,sql,ordem,linhaAcima,' +
      'linhaAbaixo,linhaNegrito,linhaCor,fonteCor,' +
      'comparadorCondicaoTotal,numeroCondicaoTotal,condTotal,indRd,inverterSinal,isPorcentagem,isPgVinculacao' +
      '&$filter=planoGerencial/id eq ' + this.planoGerencial.id +
      '&$orderby=ordem,codCta')
      .subscribe(ref => {
        this.invertResulta = false;
        this.contaGerencialId = [];
        for (let conta of ref.value) {
          if (conta.codNaturezaConta != null) {
            conta.codNaturezaConta['codDescNatureza'] = conta.codNaturezaConta.cod + " - " + conta.codNaturezaConta.descricao;
          }
          if (conta.ctaSup != null) {
            conta.ctaSup['codDescCtaSup'] = conta.ctaSup.codCta + " - " + conta.ctaSup.descrCta;
          }
        }
        this.contasGerenciais = ref.value;
      },
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

  }


  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }


  get rows() {
    return this.configService.applicationConfig.rows;
  }


  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  hideMessage() {
    this.messageService = null;
  }


  /*Show Hide Mov*/
  isCardMov: boolean = true;
  ShowHIdeMov() {
    this.isCardMov = !this.isCardMov;
  }


  currentStateMov = 'initial';
  changeStateMov() {
    this.currentStateMov = this.currentStateMov === 'initial' ? 'final' : 'initial';
  }


  currentStateVin = 'initial';
  changeStateVin() {
    this.currentStateVin = this.currentStateVin === 'initial' ? 'final' : 'initial';
  }


  /*Show Hide Mov*/
  isCardVin: boolean = true;
  ShowHIdeVin() {
    this.isCardVin = !this.isCardVin;
  }


  onRowSelectConta(event: any) {
    if (event.indCta == "A" || event.indCta == "T" || event.indCta == "E") {
      this.viewPc = false;
      this.viewCpg = true;
      this.newPc = false;
      this.newCpg = false;
      this.editCpg = false;
      this.editPc = true;
      this.viewAbas = true;

      event.ctaSup = event.ctaSup != null ? event.ctaSup : new PlanoContas(0);

      this.planogerencialcontas = event;

      this.title = 'EDITAR CONTA DO PLANO GERENCIAL';
      this.subtitle = 'EDITANDO CONTA: ' + this.planogerencialcontas.codCta + ' - ' + this.planogerencialcontas.descrCta;

      if (this.planogerencialcontas.indCta === 'T' || this.planogerencialcontas.indCta === 'E') {
        this.showvizualizacao = false;
        this.vinculacao = false;
        this.cc = false;
        this.aba3 = true;
        this.viewContaGer = true;

      } else {
        this.showvizualizacao = true;
        this.vinculacao = true;
        this.cc = false;
        this.aba3 = false;
        this.viewContaGer = false;
        this.selectedPlanoGerencialContaId = event.id;
        this.isVinculacaoPg = event.isPgVinculacao
        this.selectedPgVinculacao = new Array();
        this.opcoesPgVinculacao = this.planosGerenciais.filter(pg => pg.id != this.planoGerencial.id).map(pg => {
          return { value: pg.id, label: `${pg.codigo} - ${pg.nome}` }
        });
        this.listPgcVinculacao = new Array();

        if(event.isPgVinculacao)
          this.getPgcVinculacao();
        else
          this.buscarVinculacoes();
      }
    } else {
      this.viewCpg = false;
      this.editPc = false;
      this.viewAbas = false;
    }
  }


  changePgVinculacao() {
    this.httpService.wait();
    this.httpService.get('/planogerencialcontas?$select=id,codCta,descrCta&$filter=planoGerencial/id in (' + this.selectedPgVinculacao.join(',') + ') and indCta eq \'A\'' +
      '&$orderby=ordem,codCta')
      .subscribe(ref => this.listPgcVinculacao = ref.value,
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());
  }


  checkAllPgcVinculacao(event) {
    this.listPgcVinculacao = this.listPgcVinculacao.map(pgc => { pgc['checked'] = event.checked; return pgc; })
  }


  getPgcVinculacao() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'contaId': this.selectedPlanoGerencialContaId.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/plano-gerencial-vinculacao', httpOptions)
      .subscribe(
        res => {
          if(res['error']) {
            this.exibirMensagem(res);
          } else {
            for(let pg_id of res['lipg']) this.selectedPgVinculacao.push(pg_id);

            this.httpService.wait();
            this.httpService.get('/planogerencialcontas?$select=id,codCta,descrCta&$filter=planoGerencial/id in (' + this.selectedPgVinculacao.join(',') + ') and indCta eq \'A\'' +
              '&$orderby=ordem,codCta')
              .subscribe(ref => {
                for(let conta of ref.value) {
                  if(res['licontas'].includes(conta.id)) conta['checked'] = true;
                }

                this.listPgcVinculacao = ref.value
              }, error => this.httpService.handleError(error, () => this.empresaChanged()),
                () => this.httpService.done());
          }

          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API: " + err.message });
          console.log(err);
          this.httpService.done();
      });
  }


  savePgcVinculacao() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
      })
    };

    let dictVinculacao = {}
    dictVinculacao[this.selectedPlanoGerencialContaId] =  this.listPgcVinculacao.filter(pgc => pgc.checked).map(pgc => { return pgc.id });
    const payload = { dictVinculacao }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'contabil/plano-gerencial-vinculacao', payload, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API: " + err.message });
          console.log(err);
          this.httpService.done();
      });
  }


  voltarCpg() {
      this.viewCpg = false;
      this.title = 'EDITAR PLANO GERENCIAL';
      // this.subtitle = 'EDITANDO PLANO GERENCIAL: ' + event.codigo + ' - ' + event.nome;
  }


  toggleCondicaoTotal() {
    this.planogerencialcontas.comparadorCondicaoTotal = null;
    this.planogerencialcontas.numeroCondicaoTotal = null;
  }


  voltarEdit() {
    if (confirm('Deseja realmente voltar? as alterações não salvas serão perdidas')) {
      this.editPc = false;
      this.editGerenciais = false;
      this.contaselecionados = false;
      this.selectTodos(false);
      this.selectTodosConta(false);
      this.voltarCpg()
      this.title = 'PLANO GERENCIAL';
      this.subtitle = 'CADASTRE E CONTROLE O PLANO GERENCIAL E SUAS CONTAS';
      this.empresaChanged();
    }
  }


  salvarConta() {
    const req = {
      pgc: this.planogerencialcontas,
    };

    if (confirm('Deseja realmente salvar esta condição?')) {
      if (this.planogerencialcontas.condTotal && this.planogerencialcontas.comparadorCondicaoTotal == null) {
        this.exibirMensagem({ "error": true, "mensagem": "Selecione uma condição." });
        return;

      } else if (this.planogerencialcontas.condTotal && this.planogerencialcontas.numeroCondicaoTotal == null) {
        this.exibirMensagem({ "error": true, "mensagem": "Indique um número para a condição" });
        return;
      }

      this.messageService.clear();
      this.httpService.wait();
      this.httpService.post('/custom/gerencial/salvar-pgc', req)
        .subscribe(result => {
          if (result.error) {
            this.messageService.add ({
              key: 'messageLinnks',
              severity: result.severity,
              summary: result.summary,
              detail: result.detail
            });
          } else {
            this.messageService.add ({
              key: 'messageLinnks',
              severity: result.severity,
              summary: result.summary,
              detail: result.detail
            });
          }

        },
          error => this.httpService.handleError(error, () => this.salvarConta()),
          () => this.httpService.done());

    }
  }


  showTela(tipo) {
    this.aba1 = false;
    this.aba2 = false;
    this.aba3 = false;
    // this.aba4 = false;
    this.vinculacao = false;
    this.cc = false;
    this.cp = false;
    this.view = false;
    this.viewContaGer = false;

    if (tipo === 'TELA_1') {
      this.aba1 = true;
      this.aba2 = false;
      this.aba3 = false;

    } else if (tipo === 'TELA_2') {
      this.aba2 = true;
      this.aba1 = false;
      this.aba3 = false;

    } else if (tipo === 'TELA_3') {
      this.aba3 = true;
      this.aba2 = false;
      this.aba1 = false;
      this.cc = true;

    } else if (tipo === 'VINCULACAO') {
      this.aba1 = true;
      this.aba2 = false;
      this.aba3 = false;
      this.vinculacao = true;
      this.buscarVinculacoes();

    } else if (tipo === 'CC') {
      this.aba3 = true;
      this.cc = true;
      this.cp = false;
      this.view = false;
      this.viewContaGer = false;
      this.buscarCentroCusto();

    } else if (tipo === 'CP') {
      this.aba3 = true;
      this.cc = false;
      this.cp = true;
      this.view = false;
      this.viewContaGer = false;
      this.buscarProjetos();

    } else if (tipo === 'VW') {
      this.aba3 = true;
      this.cc = false;
      this.cp = false;
      this.view = true;
      this.viewContaGer = false;
      this.buscarViews();

    } else if (tipo === 'GERENCIAL') {
      this.aba3 = true;
      this.cc = false;
      this.cp = false;
      this.view = false;
      this.viewContaGer = true;
    }
  }


  buscarProjetos() {
    this.projetos = new Array();
    this.httpService.wait();
    this.httpService.get('/cadproj?$select=id,codProj,descrProj&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      '&$orderby=codProj').subscribe(cp => this.projetos = cp.value,
        error => this.httpService.handleError(error, () => this.buscarProjetos()),
        () => this.httpService.done());
  }


  buscarCentroCusto() {
    this.centrosCusto = new Array();
    this.httpService.wait();
    this.httpService.get('/cadccus?$select=id,codCcus,descrCcus&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      '&$orderby=codCcus').subscribe(cc => this.centrosCusto = cc.value,
        error => this.httpService.handleError(error, () => this.buscarCentroCusto()),
        () => this.httpService.done());
  }


  buscarVinculacoes() {
    this.planoContasListRelacionadosCheckAll = false;
    this.planoContasListCheckAll = false;
    this.selectedContasVinc = new Array()
    this.selectedContasNaoVinc = new Array()

    this.httpService.wait();
    this.httpService.get('/custom/ecd-report/buscarPlanoContasVinculacao(' + this.dblinkedSessionService.empresa.id + ',' +
      + this.dblinkedSessionService.periodo.year.value + ',' + this.planoGerencial.id + ',' + this.planogerencialcontas.id + ')')
      .subscribe(ref => this.loadContas(ref.value),
        error => this.httpService.handleError(error, () => this.buscarVinculacoes()),
        () => this.httpService.done());
  }


  buscarViews() {

    this.views = new Array();
    this.httpService.wait();
    this.httpService.get('/viewcampos?$select=id,campo,descricao,tipo&$filter=view/id eq ' + this.planoGerencialView
      + '&$orderby=campo')
      .subscribe(ref => this.loadViewCampos(ref.value),
        error => this.httpService.handleError(error, () => this.buscarViews()),
        () => this.httpService.done());
  }


  loadViewCampos(value: Array<ViewCampos>) {
    this.viewCampos = value;
  }


  loadContas(event: any) {
    this.planoContasList = new Array();
    this.planoContasVincList = new Array();

    if (!event.error) {
      event.forEach(element => {
        if (!element.estaConta) {
          this.planoContasList.push(element);
        } else {
          this.planoContasVincList.push(element);
        }
      });
    } else {
      this.messageService.add ({ key: 'messageLinnks',
        severity: 'warn',
        summary: 'MSG:',
        detail: event.message
      });

    }
  }


  selectTodosRelacionar(event: any) {
    if (!this.planoContasListCheckAll) {
      this.planoContasList.forEach(element => {
        const index = this.planoContasListVisiveis.findIndex(c => c.id === element.id);
        if (index >= 0 || this.planoContasListVisiveis.length === 0) {
          element.selected = true;
        }
      });
    } else {
      this.planoContasList.forEach(element => {
        const index = this.planoContasListVisiveis.findIndex(c => c.id === element.id);
        if (index >= 0 || this.planoContasListVisiveis.length === 0) {
          element.selected = false;
        }
      });
    }
  }


  selectTodosRelacionados(event: any) {
    if (!this.planoContasListRelacionadosCheckAll) {

      this.planoContasVincList.forEach(element => {
        const index = this.planoContasVincListVisiveis.findIndex(c => c.id === element.id);
        if (index >= 0 || this.planoContasVincListVisiveis.length === 0) {
          element.selected = true;
        }
      });
    } else {
      this.planoContasVincList.forEach(element => {
        const index = this.planoContasVincListVisiveis.findIndex(c => c.id === element.id);
        if (index >= 0 || this.planoContasVincListVisiveis.length === 0) {
          element.selected = false;
        }
      });
    }
  }


  onFilterRelacionar(event: any) {
    this.planoContasListVisiveis = new Array();
    event.filteredValue.forEach(element => {
      this.planoContasListVisiveis.push(element);
    });
  }


  onFilterRelacionadas(event: any) {
    this.planoContasVincListVisiveis = new Array();

    event.filteredValue.forEach(element => {
      this.planoContasVincListVisiveis.push(element);
    });
  }


  add() {
    if (confirm('Deseja realmente vincular estas contas?')) {
      // let pgc = new Array();

      // this.planoContasList.forEach(element => {
      //   if (element.selected) {
      //     pgc.push(element);
      //   }
      // });

      const req = {
        pgc: this.selectedContasNaoVinc,
        conta: this.planogerencialcontas.id,
        tipo_ccus_id: this.vincTipoCcusId
      };

      this.messageService.clear();
      this.httpService.wait();
      this.httpService.post('/custom/gerencial/vincular-gerencial', req)
        .subscribe(result => {
          this.buscarVinculacoes();
        },
          error => this.httpService.handleError(error, () => this.add()),
          () => this.httpService.done());
    }
  }


  remove() {
    if (confirm('Deseja realmente desvincular estas contas?')) {

      // let pgc = new Array();

      // this.planoContasVincList.forEach(element => {
      //   if (element.selected) {
      //     pgc.push(element);
      //   }
      // });


      const req = {
        pgc: this.selectedContasVinc,
        conta: this.planogerencialcontas.id
      };


      this.messageService.clear();
      this.httpService.wait();
      this.httpService.post('/custom/gerencial/desvincular-gerencial', req)
        .subscribe(result => {
          this.buscarVinculacoes();
        },
          error => this.httpService.handleError(error, () => this.remove()),
          () => this.httpService.done());
    }
  }


  marcarAnaliticas(event: any, bool: boolean) {
    if (event.data.selected) {

      this.planoContasList.forEach(element => {
        if (element.pcsId === event.data.id) {
          if (bool) {
            element.selected = false;
          } else {
            element.selected = true;
          }
          this.marcarAnaliticas(element, false);
        }
      });

    } else {

      this.planoContasList.forEach(element => {
        if (element.pcsId === event.data.id) {
          if (bool) {
            element.selected = true;
          } else {
            element.selected = false;
          }
          this.marcarAnaliticas(element, false);
        }
      });
    }
  }


  marcarAnaliticasRelacionadas(event: any, bool: boolean) {
    if (event.data.selected) {

      this.planoContasVincList.forEach(element => {
        if (element.pcsId === event.data.id) {
          if (bool) {
            element.selected = false;
          } else {
            element.selected = true;
          }
          this.marcarAnaliticasRelacionadas(element, false);
        }
      });
    } else {
      this.planoContasVincList.forEach(element => {
        if (element.pcsId === event.id) {
          if (bool) {
            element.selected = true;
          } else {
            element.selected = false;
          }
          this.marcarAnaliticasRelacionadas(element, false);
        }
      });
    }
  }


  // changeToolbarEdit(){
  //   this.toolbarMain.setVisible('btn1', false)
  //   this.toolbarMain.setVisible('btn2', false)
  //   this.toolbarMain.setVisible('btn3', false)
  //   this.toolbarMain.setVisible('btn4', false)
  //   this.toolbarMain.setVisible('btn6', true)

  //   this.toolbarMain.setVisible('btn7', true)
  //   this.toolbarMain.setVisible('btn8', true)
  //   this.toolbarMain.setVisible('btn9', true)
  //   this.toolbarMain.setVisible('btn10', true)
  //   this.toolbarMain.setVisible('btn11', false)
  //   this.toolbarMain.setVisible('btn12', true)
  // }
  // changeToolbarEditUn(){
  //   this.toolbarMain.setVisible('btn2', false)
  //   this.toolbarMain.setVisible('btn7', false)
  //   this.toolbarMain.setVisible('btn8', false)
  //   this.toolbarMain.setVisible('btn5', false)
  //   this.toolbarMain.setVisible('btn9', false)
  //   this.toolbarMain.setVisible('btn10', false)
  //   this.toolbarMain.setVisible('btn11', true)
  //   this.toolbarMain.setVisible('btn12', true)
  // }


  selectCheckboxConta(id: any, event: any) {
    this.planogerencialcod = event.codCta;
    this.planoid = id;
    this.ordemseq = event.ordem;
    this.confBold = event.linhaNegrito;
    this.confLineAbove = event.linhaAcima;
    this.confLineBelow = event.linhaAbaixo;
    this.confLineColor = event.linhaCor;
    this.confFontColor = event.fonteCor;
    this.idContaSelecionado = new Array();
    if (this.confLineColor != null) {
      this.isLinhaCor = true;
    }
    if (this.confFontColor != null) {
      this.isFonteCor = true;
    }

    if (event.selected) {
      this.contaselecionados = false;
      // this.changeToolbarEditUn()

    } else {
      this.contaselecionados = true;
      // this.changeToolbarEdit()
    }

    const index = this.contaGerencialId.findIndex(item => item.id === id);
    if (index !== -1) {
      event.selected = true;
      this.contaselecionados = true;
      // this.changeToolbarEditUn()

      var i = 0;
      this.contaGerencialId.forEach(element => {
        if (element["id"] === id) {
          this.contaGerencialId.splice(i, 1);
        }
        i = i + 1;
      });

    } else {
      this.idContaSelecionado.push(id);
      event.selected = false;
      // this.changeToolbarEdit()
      this.contaGerencialId.push({
        "id": event.id, "ordem": event.ordem, "plano_gerencial": this.planoGerencial.id,
        "cod_cta": event.codCta, "indicador": event.indCta
      });
    }

    this.handleChangeCheckBoxState()
  }

  // changeToolbar(){
  //   this.toolbarMain.setVisible('btn1', true)
  //   this.toolbarMain.setVisible('btn2', true)
  //   this.toolbarMain.setVisible('btn3', true)
  //   this.toolbarMain.setVisible('btn4', true)
  //   this.toolbarMain.setVisible('btn5', false)
  // }

  // changeToolbarUn(){
  //   this.toolbarMain.setVisible('btn1', false)
  //   this.toolbarMain.setVisible('btn2', false)
  //   this.toolbarMain.setVisible('btn3', false)
  //   this.toolbarMain.setVisible('btn4', false)
  //   this.toolbarMain.setVisible('btn5', true)
  // }

  selectCheckbox(event: any) {
    this.planoGerencialCodigo = event.data.codigo;
    this.planoGerencialNome = event.data.nome;
    this.planoGerencialNivel = event.data.niveis;
    this.planoGerencialMoeda = event.data.moeda !== null ? event.data.moeda.id : 0;
    this.planoGerencialView = event.data.view !== null ? event.data.view.id : 0;
    this.planoGerencialId = event.data.id;
    this.listaGerencialId.push(event.data.id);
    // this.changeToolbar()
    this.handleChangeCheckBoxState()

  }

  unselectCheckbox(event: any){
    this.planoGerencialCodigo = '';
    this.planoGerencialNome = '';
    this.planoGerencialNivel = 0;
    this.planoGerencialMoeda = 0;
    this.planoGerencialView = 0;
    var pgid: number = this.listaGerencialId.indexOf(event.data.id, 0);
    if (pgid > -1) {
      this.listaGerencialId.splice(pgid, 1);
    }
    // this.changeToolbarUn()
    this.handleChangeCheckBoxState()
  }


  selectCta(event: any) {
    if (event.data.indCta === 'S') {
      this.marcarAnaliticas(event, true);
    } else {
        this.planoContasList.forEach(element => {
          if (element.pcsId === event.data.pcsId) {
            element.selected = true;
          }
        });
    }
  }


  selectCtaRelacionada(event: any) {
    if (event.data.indCta === 'S') {
      this.marcarAnaliticasRelacionadas(event, true);

    } else {
      if (event.data.selected) {
        this.planoContasVincList.forEach(element => {
          if (element.id === event.data.pcsId) {
            element.selected = false;
          }
        });
      }
    }
  }


  planoContasListStyleClass(event: any): string {
    let classes = '';
    if (event.indCta === 'S') {
      classes += 'bold ';
    } else if (event.indCta === 'T') {
      classes += 'bold';
    }
    return classes.trim();
  }


  addPg() {
    this.modalPlanoGerencial = true;
    this.newPc = true;
    this.planoGerencialId = 0;
    this.planoGerencialCodigo = '';
    this.planoGerencialNome = '';
    this.planoGerencialNivel = 1;
    this.planoGerencialMoeda = 0;
    this.planoGerencialView = 0;
  }


  editPg() {
    this.modalPlanoGerencial = true;
    this.editPc = true;
  }


  atualizarPlanoGerencial() {
    if (!this.validarPlanoGerencial()) {
      this.messageService.add ({
        key: 'messageLinnks',
        severity: 'warn',
        summary: 'MSG:',
        detail: 'Campos Obrigatórios precisam ser preenchidos!'
      });
      return;
    } else {

      const listasEnviar = {
        codigo: this.planoGerencialCodigo,
        nome: this.planoGerencialNome,
        niveis: this.planoGerencialNivel,
        moeda: this.planoGerencialMoeda,
        view: this.planoGerencialView,
        usuario: this.sessionService.loggedUser.id,
        plano: this.planoGerencialId,
        empId: this.dblinkedSessionService.empresa.id
      };


      this.httpService.wait();
      this.httpService.post('/custom/ecd/atualizar-plano-gerencial', listasEnviar)
        .subscribe(() => {
          this.messageService.add ({
            key: 'messageLinnks',
            severity: 'success',
            summary: 'MSG:',
            detail: 'Plano Gerencial salvo com sucesso!'
          });
          this.ngOnInit();
        },
          error => this.httpService.handleError(error, () => this.atualizarPlanoGerencial()),
          () => this.httpService.done());
    }

  }


  validarPlanoGerencial(): boolean {

    if (this.planoGerencialCodigo === '') {
      return false;
    }

    if (this.planoGerencialNome === '') {
      return false;
    }

    if (this.planoGerencialNivel === 0) {
      return false;
    }

    if (this.planoGerencialMoeda === 0) {
      return false;
    }

    if (this.planoGerencialView === 0) {
      return false;
    }

    return true;

  }


  removePg() {
    if (confirm('Deseja excluir este plano e as contas associadas?')) {
      let lipg: Array<number> = new Array();
      for (let line of this.listaGerencialId){
        lipg.push(line.id);
      }
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization : this.configService.bearerApi,
          lipg : this.listaGerencialId.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.delete(this.configService.defaultUrlApiUpload + 'contabil/plano-gerencial', httpOptions)
        .subscribe(
          res => {
            if (res["error"]) {
              this.exibirMensagem(res);

            } else {
              this.exibirMensagem(res);
              this.listaGerencialId = new Array
              this.empresaChanged();
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem({ error: true, mensagem: "Erro no API: " + err.message });
            console.log(err);
            this.httpService.done();
          }
        );
    }
    this.handleChangeCheckBoxState()
  }


  removePc() {
    if (confirm('Todas as contas selecionadas e todas as contas associadas serão excluídas, confirme ?')) {
      let lipgc: Array<number> = new Array();
      for (let line of this.contaGerencialId) {
        lipgc.push(line.id);
      }

      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
          lipgc: lipgc.toString(),
          pgid: this.planoGerencial.id.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.delete(this.configService.defaultUrlApiUpload + 'contabil/plano-gerencial-contas', httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            if (!res["error"]) {
              this.buscarGerencialContas();
            }
            this.contaGerencialId = new Array
            this.httpService.done();
          },
          err => {
            this.exibirMensagem({ error: true, mensagem: "Erro no API: " + err.message });
            console.log(err);
            this.httpService.done();
          }
        );
    }
    this.handleChangeCheckBoxState()
  }


  addConta() {

    this.modalContaPlanoGerencial = true;
    this.editCpg = false;

    this.planogerencialcontas = new PlanoGerencialContas();
    this.planogerencialcontas.planoGerencial = new PlanoGerencial();
    this.planogerencialcontas.codNaturezaConta = new CodNaturezaConta();
    this.planogerencialcontas.codNaturezaConta.id = 0;
    this.planogerencialcontas.nivel = 1;
    this.planogerencialcontas.ctaSup = new PlanoGerencialContas();
    this.planogerencialcontas.ctaSup.id = 0;
    this.planogerencialcontas.inverterSinal = false;
    this.confBold = false;
    this.confLineAbove = false;
    this.confLineBelow = false;
    this.confLineColor = null;
    this.confFontColor = null;
    this.opcoesRecbDeb = [];
    this.opcoesRecbDeb.push({ label: 'Nenhum', value: null });
    this.opcoesRecbDeb.push({ label: 'R', value: 'R' });
    this.opcoesRecbDeb.push({ label: 'D', value: 'D' });
    this.handleChangeCheckBoxState()

  }


  procurarContaSuperior() {


    if (!isNullOrUndefined(this.planoGerencial.id) &&
      !isNullOrUndefined(this.planogerencialcontas.nivel) &&
      !isNullOrUndefined(this.planogerencialcontas.codNaturezaConta.id)) {
      this.httpService.wait();
      this.httpService.get('/custom/ecd/conta-gerencial-sup(' + this.planoGerencial.id + ',' +
        + this.planogerencialcontas.nivel + ',' + this.planogerencialcontas.codNaturezaConta.id + ')')
        .subscribe(ref => this.loadContasSup(ref),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());
    }

  }


  loadContasSup(value: Array<any>) {

    this.opcoesContas = [];
    this.opcoesContas.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesContas.push({ label: element.codCta + ' - ' + element.descrCta, value: element.id });
    });
  }

  loadNatureza(value: Array<CodNaturezaConta>) {

    this.opcoesNatureza = [];
    this.opcoesNatureza.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      this.opcoesNatureza.push({ label: element.cod + ' - ' + element.descricao, value: element.id });
    });
  }

  save() {

    this.messageService.clear();

    if (this.planogerencialcontas.codCta === undefined || this.planogerencialcontas.codCta === "") {
      this.messageService.add ({
        key: 'messageLinnks',
        severity: 'error',
        summary: 'Erro',
        detail: 'o Campo Código deve ser preenchido.'
      });
      return;
    }

    if (this.planogerencialcontas.ordem === undefined || this.planogerencialcontas.ordem === null) {
      this.messageService.add ({
        key: 'messageLinnks',
        severity: 'error',
        summary: 'Erro',
        detail: 'o campo Ordem deve preenchido.'
      });

      return;
    }

    if (this.planogerencialcontas.ordem < 0) {
      this.messageService.add ({
        key: 'messageLinnks',
        severity: 'error',
        summary: 'Erro',
        detail: 'o campo Ordem deve ser maior ou igual a  0.'
      });

      return;
    }
    if (this.planogerencialcontas.descrCta === undefined || this.planogerencialcontas.descrCta === "") {
      this.messageService.add ({
        key: 'messageLinnks',
        severity: 'error',
        summary: 'Erro',
        detail: 'o Campo Descrição deve ser preenchido.'
      });
      return;
    }

    if (this.planogerencialcontas.indCta === undefined || this.planogerencialcontas.indCta === null) {
      this.messageService.add ({
        key: 'messageLinnks',
        severity: 'error',
        summary: 'Erro',
        detail: 'o Campo Indicador deve ser preenchido.'
      });
      return;
    }

    if (this.planogerencialcontas.codNaturezaConta.id === undefined || this.planogerencialcontas.codNaturezaConta.id === null
      || this.planogerencialcontas.codNaturezaConta.id === 0) {
      this.messageService.add ({
        key: 'messageLinnks',
        severity: 'error',
        summary: 'Erro',
        detail: 'o Campo Natureza deve ser preenchido.'
      });
      return;
    }

    const listasEnviar = {
      planoGerencial: this.planoGerencial.id,
      codCta: this.planogerencialcontas.codCta,
      descCta: this.planogerencialcontas.descrCta,
      nivel: this.planogerencialcontas.nivel,
      codNatureza: this.planogerencialcontas.codNaturezaConta.id,
      ctaSup: this.planogerencialcontas.ctaSup.id,
      indCta: this.planogerencialcontas.indCta,
      usuario: this.sessionService.loggedUser.id,
      ordem: this.planogerencialcontas.ordem,
      inverterSinal: this.planogerencialcontas.inverterSinal,
      confLineAbove: this.confLineAbove,
      confLineBelow: this.confLineBelow,
      confLineColor: this.confLineColor,
      confFontColor: this.confFontColor,
      confLineBold: this.confBold,
      indRd: this.planogerencialcontas.indRd,
      isPorcentagem: this.planogerencialcontas.isPorcentagem
    };

    this.httpService.wait();
    this.httpService.post('/custom/ecd/cadastrar-conta-gerencial', listasEnviar)
      .subscribe(result => this.buscarGerencialContas(),
        error => this.httpService.handleError(error, () => this.save()),
        () => this.httpService.done());

    this.handleChangeCheckBoxState()
  }

  editarConta() {
    const listasEnviar = {
      planoGerencial: this.planoGerencial.id,
      codCta: this.planogerencialcontas.codCta,
      descCta: this.planogerencialcontas.descrCta,
      nivel: this.planogerencialcontas.nivel,
      codNatureza: this.planogerencialcontas.codNaturezaConta.id,
      ctaSup: this.planogerencialcontas.ctaSup.id,
      indCta: this.planogerencialcontas.indCta,
      usuario: this.sessionService.loggedUser.id,
      contaGerencial: this.planogerencialcontas.id,
      ordem: this.planogerencialcontas.ordem,
      inverterSinal: this.planogerencialcontas.inverterSinal,
      planoid: this.planoid,
      ultordem: this.ordemseq,
      confLineAbove: this.confLineAbove,
      confLineBelow: this.confLineBelow,
      confLineColor: this.confLineColor,
      confFontColor: this.confFontColor,
      confLineBold: this.confBold,
      indRd: this.planogerencialcontas.indRd,
      isPorcentagem: this.planogerencialcontas.isPorcentagem

    };

    this.httpService.wait();
    this.httpService.post('/custom/ecd/editar-conta-gerencial', listasEnviar)
      .subscribe(result => this.buscarGerencialContas(),
        error => this.httpService.handleError(error, () => this.editarConta()),
        () => this.httpService.done());

    this.handleChangeCheckBoxState()
  }

  editarContaPlanoGerencial() {
    this.editCpg = true;
    this.modalContaPlanoGerencial = true;
    this.httpService.wait();
    this.httpService.get('/planogerencialcontas?$select=id,codCta,descrCta,inverterSinal,isPorcentagem,' +
      'codNaturezaConta/id,indCta,nivel,ctaSup/id,indRd,ordem&$filter=id eq ' + this.idContaSelecionado)
      .subscribe(ref => {

        this.modalContaPlanoGerencial = true;
        this.planogerencialcontas = ref.value[0];
        if (!ref.value[0].ctaSup) {
          this.planogerencialcontas.ctaSup = new PlanoGerencialContas();
        }
        this.procurarContaSuperior();
        this.planogerencialcontas.inverterSinal = ref.value[0].inverterSinal
      },
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());
  }

  onSelectView(event: any) {

    if (isNullOrUndefined(this.planogerencialcontas.sql)) {
      this.planogerencialcontas.sql = '';
    }


    this.planogerencialcontas.sql = this.planogerencialcontas.sql + ' {' + event.codCta + '}';

  }

  onSelectViewCampo(event: any) {


    if (isNullOrUndefined(this.planogerencialcontas.sql)) {
      this.planogerencialcontas.sql = '';
    }

    this.planogerencialcontas.sql = this.planogerencialcontas.sql + ' ' + event.campo;

  }

  onSelectCp(event: any) {

    if (isNullOrUndefined(this.planogerencialcontas.sql)) {
      this.planogerencialcontas.sql = '';
    }

    this.planogerencialcontas.sql = this.planogerencialcontas.sql + ' ' + event.codProj;
  }

  onSelectCc(event: any) {

    if (isNullOrUndefined(this.planogerencialcontas.sql)) {
      this.planogerencialcontas.sql = '';
    }

    this.planogerencialcontas.sql = this.planogerencialcontas.sql + ' ' + event.codCcus;
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
    this.filename = this.file.name;
  }

  importar() {
    const data: FormData = new FormData();
    data.append('file', this.file);

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'planogerencialid': this.planoGerencial.id.toString(),
        'userid': this.sessionService.loggedUser.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'workstats/upload-contasgc', data, httpOptions)
      .subscribe(result => {
        this.exibirMensagem(result)
        if (result["error"]) {
          if (result["data"]) {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0'
              }),
              responseType: 'blob' as 'json'
            };
            this.httpService.wait();
            this.httpClient.get(this.configService.defaultUrlApiUpload + 'workstats/upload-contasgc', httpOptions2)
              .subscribe(
                (res: Blob) => {
                  if (res["error"]) {
                    this.exibirMensagem(res);

                  } else {
                    let link = document.createElement('a');
                    let url = window.URL.createObjectURL(res);
                    let fileName = "log.txt";
                    link.href = url;
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(url);
                  }

                  this.httpService.done()
                },
                err => {
                  this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o log: " + err.message });
                  console.log(err);
                  this.httpService.done()
                })
          } else {
            this.httpService.done()
          }
        } else {
          this.modalUploadGerencial = false
          this.buscarGerencialContas()
          this.httpService.done()
        }
      },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao fazer o upload, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        });
  }

  getListEmpresas() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'authuser': this.sessionService.loggedUser.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'workstats/planogerencialnew/getempresausuario', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            this.empresasSelecionadas = [];
            this.listEmpresas = [];
            res["data"].forEach(element => { this.listEmpresas.push({ value: element["id"], label: element["razao"] }); });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API: " + err.message });
          console.log(err);
          this.httpService.done();
        }

      );
  }
  clonarPlano() {
    if (!this.empresasSelecionadas) {
      this.exibirMensagem({ error: true, mensagem: "Selecione pelo menos uma empresa." });
      return;
    }

    else if (!this.planoGerencialCloneCodigo) {
      this.exibirMensagem({ error: true, mensagem: "Indicar um código para o novo plano gerencial." });
      return;
    }

    else if (!this.planoGerencialNomeNew) {
      this.exibirMensagem({ error: true, mensagem: "Indicar uma descrição para o novo plano gerencial." });
      return;
    }

    else {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi
        })
      };

      const payload = {
        codigo: this.planoGerencialCloneCodigo,
        descricao: this.planoGerencialNomeNew,
        id: this.planosGerenciais.find(element => this.listaGerencialId[0] === element.id).id,
        sql: this.colSQL,
        empresas_dest: this.empresasSelecionadas,
        empresa_id: this.dblinkedSessionService.empresa.id,
        user_id: this.sessionService.loggedUser.id
      }

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'workstats/clonar-pg', payload, httpOptions)
        .subscribe(
          res => {
            if (res["error"]) {
              this.exibirMensagem(res);

            } else {
              this.exibirMensagem(res);
              this.modalClonePG = false;
              this.empresaChanged();
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem({ error: true, mensagem: "Erro no API: " + err.message });
            console.log(err);
            this.httpService.done();
          }
        );
    }
  };

  // changeToolbarTodos(){
  //   this.toolbarMain.setVisible('btn2', true)
  //   this.toolbarMain.setVisible('btn3', true)
  //   this.toolbarMain.setVisible('btn4', true)
  // }
  // changeToolbarTodosUn(){
  //   this.toolbarMain.setVisible('btn2', false)
  //   this.toolbarMain.setVisible('btn3', false)
  //   this.toolbarMain.setVisible('btn4', false)
  // }

  selectTodos(event) {

    this.liplanoGerencial = this.planosGerenciais;
    this.listaGerencialId = new Array();
    if (event.checked == true) {
      // this.changeToolbarTodos()
      this.liplanoGerencial.forEach(rst => {
        rst.selected = true;
        this.listaGerencialId.push(rst.id);
        // this.checkedAll = true;
      });
    } else {
      // this.changeToolbarTodosUn()
      this.liplanoGerencial.forEach(rst => {
        rst.selected = false;
        this.listaGerencialId = new Array();
        // this.checkedAll = false;
      });
    }
    this.handleChangeCheckBoxState()
  }
  selectTodosConta(event) {
    this.licontaGerencial = this.contasGerenciais;
    this.contaGerencialId = new Array();
    if (event.checked === true) {
      // this.changeToolbarEdit()
      this.licontaGerencial.forEach(rst => {
        rst.selected = true;
        this.contaGerencialId.push({
          "id": rst.id, "ordem": rst.ordem, "plano_gerencial": this.planoGerencial.id,
          "cod_cta": rst.codCta
        });
        this.planoContasListCheckAll = true;
      });
    } else {
      this.licontaGerencial.forEach(rst => {
        // this.changeToolbarEditUn()
        rst.selected = false;
        this.contaGerencialId = new Array();
        this.planoContasListCheckAll = false;
      });
    }
    this.handleChangeCheckBoxState()
  }

  handleChangeCheckBoxState() {
    let oneGerencialsSelectedCondition = this.listaGerencialId.length == 1 && !this.editGerenciais ? true : false
    let moreThanOneGerencialsSelectedCondition = this.listaGerencialId.length >= 1 && !this.editGerenciais ? true : false

    let oneAccountSelectedCondition = this.contaGerencialId.length == 1 && this.editGerenciais ? true : false
    let moreThanOneAccountSelectedCondition = this.contaGerencialId.length >= 1 && this.editGerenciais ? true : false

    this.toolbarMain.setVisible('btn-back', this.editGerenciais)
    this.toolbarMain.setVisible('btn-add-account', this.editGerenciais)
    this.toolbarMain.setVisible('btn-upload', this.editGerenciais)
    this.toolbarMain.setVisible('btn-export-account', this.editGerenciais)
    this.toolbarMain.setVisible('btn-edit-account', oneAccountSelectedCondition)
    this.toolbarMain.setVisible('btn-delete-account', moreThanOneAccountSelectedCondition)

    this.toolbarMain.setVisible('btn-add-plan', !this.editGerenciais)
    this.toolbarMain.setVisible('btn-export-plan', !this.editGerenciais)
    this.toolbarMain.setVisible('btn-clone-plan', oneGerencialsSelectedCondition)
    this.toolbarMain.setVisible('btn-edit-plan', oneGerencialsSelectedCondition)
    this.toolbarMain.setVisible('btn-delete-plan', moreThanOneGerencialsSelectedCondition)
    this.toolbarMain.setVisible('btn-export-report', oneGerencialsSelectedCondition)
  }

  baixarRelatorioVinculacoes() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi
      }),
      responseType: 'blob' as 'json'
    }

    const userId = this.sessionService.loggedUser.id.toString()
    const empresaId = this.dblinkedSessionService.empresa.id.toString()
    const planoGerencialId = this.listaGerencialId[0].toString()
    const params = `?userId=${userId}&empresaId=${empresaId}&planoGerencialId=${planoGerencialId}`

    const urlCompleta = this.configService.defaultUrlApiUpload + '/workstats/relatorio-vinculacao-plano-gerencial' + params

    try {
      this.httpService.wait()
      this.httpClient.get(urlCompleta, httpOptions).toPromise().then((res: any) => {
        let link = document.createElement('a')
        let url = window.URL.createObjectURL(res)
        link.href = url
        link.download = "relatorio-vinculacao-plano-gerencial.xlsx"
        link.click()
        window.URL.revokeObjectURL(url)
      }).catch(err => this.exibirMensagem(true + 'Houve um erro ao recuperar seu relatório! Contate o suporte'))
    } catch(err) {
      this.exibirMensagem({ error: true, mensagem: "Erro ao baixar o relatório de vinculações." })
    } finally {
      this.httpService.done()
    }
  }

  exportExcelAll() {
    let exportList = []
    const orderList = ['id', 'codigo', 'nome', 'moeda']
    this.planosGerenciais.forEach(plano => {
      exportList.push({
        id: plano.id,
        codigo: plano.codigo,
        nome: plano.nome,
        moeda: plano.moeda.codigo
      })
    })
    exportExcel(exportList, "Plano Gerencial", orderList)
  }
}
