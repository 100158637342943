import { Cest } from './cest-model';
import { Ncm } from './ncm-model';
import { ItemSegmento } from './item-segmento-model';

export class CestNcmSegmento {
  id: number;
  cest: Cest;
  ncm: Ncm;
  itemSegmento: ItemSegmento;

  constructor(cestNcmSegmento?: CestNcmSegmento | number) {
    if (cestNcmSegmento !== undefined && cestNcmSegmento !== null) {
      if (typeof cestNcmSegmento === 'number') {
        this.id = cestNcmSegmento;
      } else {
        this.id = cestNcmSegmento.id;
        this.cest = cestNcmSegmento.cest;
        this.ncm = cestNcmSegmento.ncm;
        this.itemSegmento = cestNcmSegmento.itemSegmento;
      }
    }
  }
}
