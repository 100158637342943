import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  providers: []
})
export class ToolbarComponent {

  constructor() { }

  @Input() value: any;
}
