import { TabelaCrm } from 'app/crm/model/tabela-crm.model';

export class TabelaCrmDetalhe {
  id: number;
  tabelaCrm : TabelaCrm;
  campo     : string;
  posicao   : number;
  tabelaCrmId : number;


  constructor(tabelaCrmDetalhe?: TabelaCrmDetalhe | number) {
    if (tabelaCrmDetalhe !== undefined && tabelaCrmDetalhe !== null) {
      if (typeof tabelaCrmDetalhe === 'number') {
        this.id = tabelaCrmDetalhe;
      } else {
        this.id = tabelaCrmDetalhe.id;
        this.tabelaCrm = tabelaCrmDetalhe.tabelaCrm;
        this.campo = tabelaCrmDetalhe.campo;
        this.posicao = tabelaCrmDetalhe.posicao;
        this.tabelaCrmId = tabelaCrmDetalhe.tabelaCrmId;
      }
    }
  }
}
