import { Component, OnDestroy, OnInit } from '@angular/core';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import exportExcel from 'app/util/processing/exportExcel';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from '../../shared/services/config.service';
import LogManagement from 'app/shared/common/log-management/log-control';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';

@Component({
  selector: 'app-ordens',
  templateUrl: './ordens.component.html',
  styleUrls: ['./ordens.component.scss']
})
export class OrdensComponent implements OnInit, OnDestroy {

  constructor(
    protected toolbarService: ToolbarService,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private messageService: MessageService,
    private httpClient: HttpClient
  ) { this.toolbarService.hidden = true }

  title: string;
  tituloModal: string;
  tituloBotaoModal: string;

  ordSelecionadas: Array<any> = new Array()
  dadosTabela: Array<any> = new Array()
  dadosTabelaItens: Array<any> = new Array()

  mostrarFiltro: boolean;

  tipoModal: string;
  mostrarModal: boolean;
  mostrarCamposModal = {
    mostrarQtde: false,
    mostrarAlmox: false,
    mostrarItem: false,
    mostrarHist: false
  };
  modalVisualizacao: boolean;
  checkboxDesativada: boolean = false;

  empresaId: number;
  totalLinhas: number;

  ordemProd: {
    idReq: number,
    data: Date,
    local: any,
    codItem: any,
    historico: string,
    qtde: string
  }
  boolEditar: boolean;

  listaItem: SelectItem[];
  listaAlmoxarifado: SelectItem[];
  listaNovoES: SelectItem[] = [
    { label: "Entrada", value: { id: 1, name: "Entrada" } },
    { label: "Saída", value: { id: 2, name: "Saída" } }
  ]

  requisicaoAlmoxCarregando: boolean;
  requisicaoItemCarregando: boolean;

  listaES: SelectItem[] = [
    { label: "E", value: 1 },
    { label: "S", value: 2 }
  ]

  dataIni: Date = new Date()
  dataFin: Date = new Date()
  dataSelecionadaIni: Date = new Date()
  dataSelecionadaFin: Date = new Date()

  ordemExportar = ["data", "documento", "codigoItem", "historico", "almoxarifado", "tipoEntradaSaida", "qtde", "valor"]
  ordemExportarItens = ["item", "alm", "historico", "descricao", "qtde", "valor"]

  private sessionSubscription: Subscription
  private logManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  toolbarMainIni = [
    { key: 'btn-export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar excel', visible: true, disabled: false, color: 'default', onClick: () => exportExcel(this.dadosTabela, 'Ordens de Produção', this.ordemExportar) },
    { key: 'btn-refresh', icon: 'update', text: 'Atualizar', tooltip: 'Atualizar', visible: true, disabled: false, color: 'default', onClick: () => this.reiniciarTabela(false) },
    { key: 'btn-filter', icon: 'filter_alt', text: 'Filtrar', tooltip: 'Filtrar', visible: true, disabled: false, color: 'default', onClick: () => this.mostrarModalFiltro(true) },
    { key: 'btn-search', icon: 'search', text: 'Visualizar', tooltip: 'Visualizar', visible: false, disabled: false, color: 'default', onClick: () => this.visualizarOrdemProd() },
    { key: 'btn-reply', icon: 'reply', text: 'Estornar', tooltip: 'Estornar', visible: false, disabled: false, color: 'default', onClick: () => this.prepararEstorno() },
    { key: 'btn-delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirOrdens() },
    { key: 'btn-print', icon: 'print', text: 'Imprimir', tooltip: 'Imprimir', visible: false, disabled: false, color: 'default', onClick: () => this.imprimirOp() },
    { key: 'btn-reprocessar', icon: 'update', text: 'Reprocessar', tooltip: 'Reprocessar', visible: false, disabled: false, color: 'default', onClick: () => this.reprocessarOp() },
    { key: 'btn-new', icon: 'add', text: 'Nova', tooltip: 'Nova requisição', visible: true, disabled: false, color: 'green', onClick: () => this.prepararCriarOrdemProd() },
    { key: 'btn-loading', icon: 'hourglass_full', text: 'Carregando...', tooltip: '', visible: false, disabled: true, color: 'default', onClick: () => { } }
  ]
  btnArr: Array<string> = ['btn-export', 'btn-refresh', 'btn-filter', 'btn-new', 'btn-delete', 'btn-edit', 'btn-save', 'btn-print', 'btn-reply', 'btn-search', 'btn-edit']
  public toolbarMain = new Toolbar()

  toolbarItensIni = [
    { key: 'btn-export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar excel', visible: true, disabled: false, color: 'default', onClick: () => exportExcel(this.dadosTabelaItens, 'Itens ordem de produção', this.ordemExportarItens) },
  ]
  public toolbarItens = new Toolbar()

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'DATA', size: '10%', textAlign: 'center' },
      { name: 'CÓDIGO', size: '10%', textAlign: 'center' },
      { name: 'ITEM', size: '20%', textAlign: 'left' },
      { name: 'HISTÓRICO', size: '20%', textAlign: 'left' },
      { name: 'ALMOXARIFADO', size: '15%', textAlign: 'left' },
      { name: 'TIPO', size: '5%', textAlign: 'left' },
      { name: 'QTDE', size: '10%', textAlign: 'left' },
      { name: 'VALOR', size: '10%', textAlign: 'left' },
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true,
  })

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.title = 'Ordens de Produção';
    this.tituloModal = '';
    this.toolbarItens.setAll(this.toolbarItensIni)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
        this.empresaId = this.dblinkedSessionService.empresa.id
        this.reiniciarTabela(true)
        this.buscarListaItem()
        this.buscarListaAlmoxarifado()
      }
    })
  }

  reiniciarTabela(resetarData) {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarCarregando()

    if (resetarData && this.dblinkedSessionService.periodo.year && this.dblinkedSessionService.periodo.month) {
      this.dataIni = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dataFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
    }

    this.ordSelecionadas = [];
    this.dadosTabela = [];
    this.receberOrdens();
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.atualizarToolbar()
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  async receberOrdens(event: LazyLoadEvent = {}): Promise<void> {
    const payload = {
      empresaId: this.empresaId,
      dtIni: this.dataPadraoReq(this.dataIni),
      dtFin: this.dataPadraoReq(this.dataFin),
      limit: event.rows,
      offset: event.first,
      sortOrder: event.sortOrder,
      sortField: event.sortField,
      filtros: event.filters
    }

    this.skeletonConfig.startLoad()

    try {
      await this.httpService.post('custom/estoque/get-ops', payload).toPromise().then(res => {
        this.dadosTabela = res.itens
        this.totalLinhas = res.totalLinhas

      }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao carregar as ordens de produção! Contate o suporte' }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

  public dataPadraoReq(data: Date): String {
    return data.toISOString().split('T')[0]
  }

  private async excluirOrdens(): Promise<void> {
    if (confirm('Deseja excluir as ordens de produção selecionadas?')) {
      const payload = {
        idsSelecionados: this.ordSelecionadas.map(item => item.requisicaoId),
        usuario: this.sessionService.loggedUser.id
      }
      this.toolbarCarregando()
      this.checkboxDesativada = true;

      let url = '/custom/estoque/v2/excluir-op'
      try {
        await this.httpService.post(url, payload).toPromise()
        .then(res => {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: res.message })
          res.opExcluidas.forEach(op => {
            //Gravar o log
              this.logManagement.registerLog(
                LogModule.STOCK,
                LogScreen.PRODUCTION_ORDER,
                url,
                LogAction.DELETE,
                op.id,
                op.doc,
                "Excluiu uma ordem de produção",
                payload
              )
          })



          })
          .catch(err => {
            if (err.status === 400) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: err.error.message })
              this.downloadLog(err.error.file)
              err.error.opExcluidas.forEach(op => {
                //Gravar o log
                  this.logManagement.registerLog(
                    LogModule.STOCK,
                    LogScreen.PRODUCTION_ORDER,
                    url,
                    LogAction.DELETE,
                    op.id,
                    op.doc,
                    "Excluiu uma ordem de produção",
                    payload
                  )
              })
            }
          })
      } catch (err) {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro no API ao excluir as OP, contate o suporte: ' + err.message })
      } finally {
        this.checkboxDesativada = false
        this.reiniciarTabela(false)
        this.receberOrdens()
      }
    }
  }

  private prepararEstorno() {
    if (this.ordSelecionadas[0].documento.startsWith('EOP')) {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro', detail: 'Estornos não podem ser estornados!'
      });
      return;
    }

    this.mostrarModal = true;
    this.tipoModal = "estornar";

    this.mostrarCamposModal = {
      mostrarQtde: false,
      mostrarAlmox: false,
      mostrarItem: false,
      mostrarHist: true
    };
    this.modalVisualizacao = false;

    this.tituloModal = 'Estornar ordem de produção';
    this.tituloBotaoModal = 'Salvar';
    this.ordemProd = {
      idReq: this.ordSelecionadas[0].requisicaoId,
      data: new Date(this.ordSelecionadas[0].dataReq),
      local: '',
      codItem: '',
      historico: this.ordSelecionadas[0].historico,
      qtde: '0'
    }
  }

  private estornarOrdem() {
    if (!this.ordemProd.data || this.ordemProd.historico == '') {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Data e histórico são obrigatórios!' })
      return
    }

    const payload = {
      data: this.ordemProd.data.toISOString(),
      historico: this.ordemProd.historico,
      idOp: this.ordSelecionadas[0].requisicaoId,
      userId: this.sessionService.loggedUser.id
    }

    let url = '/custom/estoque/v2/estornar-op'

    this.httpService.wait()
    this.httpService.post(url, payload)
      .subscribe(
        res => {
          this.httpService.done()
          if (res.error) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: res.message })

          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: res.message })
            this.reiniciarTabela(false)
            this.fecharModal()

            //Gravar o log
            this.logManagement.registerLog(
              LogModule.STOCK,
              LogScreen.PRODUCTION_ORDER,
              url,
              LogAction.CREATE,
              this.ordSelecionadas[0].requisicaoId,
              this.ordSelecionadas[0].doc,
              "Estornou uma ordem de produção",
              payload
            )

          }
        },
        error => {
          this.httpService.done()
          if (error.status === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: error.error.message })
          else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: "Erro no API ao estornar a OP, contate o suporte: " + error.message })
        }
      )
  }

  convertTimestamp(timestamp: any): String {
    const date = new Date(timestamp)
    date.setHours(date.getHours() + 3)
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)

    const fullDate = day + '/' + month + '/' + date.getFullYear()

    return fullDate
  }

  public formatDateExtensive(): String {
    const dt = new Date()
    const monthExtensive = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const weekExtensive = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]

    const month = monthExtensive[dt.getMonth()]
    const week = weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }

  private toolbarCarregando(): void {

    this.btnArr.forEach(item => {
      this.toolbarMain.setVisible(item, false)
    })
    this.toolbarMain.setVisible('btn-loading', true)
  }

  fecharModal() {
    this.mostrarModal = false;
  }

  concluirModal() {
    switch (this.tipoModal) {
      case 'criar':
        this.criarOrdemProd()
        break;
      case 'editar':
        this.editarOrdemProd()
        break;
      case 'estornar':
        this.estornarOrdem()
        break;
      case 'visualizar':
        this.fecharModal()
        break;
    }
  }

  private prepararCriarOrdemProd() {
    this.mostrarModal = true;
    this.tipoModal = "criar";

    this.mostrarCamposModal = {
      mostrarQtde: true,
      mostrarAlmox: true,
      mostrarItem: true,
      mostrarHist: true
    };
    this.modalVisualizacao = false;

    this.tituloModal = 'Criar ordem de produção';
    this.tituloBotaoModal = 'Salvar';

    this.ordemProd = {
      idReq: 0,
      data: new Date(),
      local: '',
      codItem: '',
      historico: '',
      qtde: '0'
    }
  }

  private criarOrdemProd() {
    this.ordemProd.idReq = 0
    this.boolEditar = false

    this.reqOrdemProd()
  }

  private visualizarOrdemProd() {

    this.mostrarModal = true;
    this.tipoModal = "visualizar";

    console.log(this.ordSelecionadas);

    this.mostrarCamposModal = {
      mostrarQtde: true,
      mostrarAlmox: true,
      mostrarItem: true,
      mostrarHist: true
    };
    this.modalVisualizacao = true;

    this.ordemProd = {
      idReq: this.ordSelecionadas[0].requisicaoId,
      data: new Date(this.ordSelecionadas[0].dataReq),
      local: this.listaAlmoxarifado.find((almox) => { return almox.label == this.ordSelecionadas[0].almoxarifado }).value,
      codItem: this.listaItem.find((item) => { return item.label == this.ordSelecionadas[0].codigoItem }).value,
      historico: this.ordSelecionadas[0].historico,
      qtde: this.ordSelecionadas[0].qtde
    }

    console.log(this.ordemProd);

    this.dadosTabelaItens = this.ordSelecionadas[0].movList
    this.tituloModal = 'Visualizar ordem de produção - ' + this.ordSelecionadas[0].documento;
    this.tituloBotaoModal = 'Ok';
  }

  private prepararEditarOrdemProd() {
    if (this.ordSelecionadas[0].documento.startsWith('EOP')) {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro', detail: 'Estornos não podem ser editados!'
      });
      return;
    }

    this.mostrarModal = true;
    this.tipoModal = "editar";

    this.mostrarCamposModal = {
      mostrarQtde: true,
      mostrarAlmox: true,
      mostrarItem: true,
      mostrarHist: true
    };
    this.modalVisualizacao = false;

    this.tituloModal = 'Editar ordem de produção';
    this.tituloBotaoModal = 'Salvar';
    this.ordemProd = {
      idReq: this.ordSelecionadas[0].requisicaoId,
      data: new Date(this.ordSelecionadas[0].dataReq),
      local: this.listaAlmoxarifado.find((almox) => { return almox.label == this.ordSelecionadas[0].almoxarifado }).value,
      codItem: this.listaItem.find((item) => { return item.label == this.ordSelecionadas[0].codigoItem }).value,
      historico: this.ordSelecionadas[0].historico,
      qtde: this.ordSelecionadas[0].qtde
    }
  }

  private editarOrdemProd() {
    this.boolEditar = true

    this.reqOrdemProd()
  }

  private reqOrdemProd() {

    if (!this.validacaoModal()) return

    const payload = {
      data: this.ordemProd.data,
      almoxarifadoId: this.ordemProd.local.id,
      itemId: this.ordemProd.codItem.id,
      historico: this.ordemProd.historico,
      qtde: this.ordemProd.qtde,
      empresa: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id
    }

    let url = '/custom/estoque/v2/ordem-producao'
    this.httpService.wait()
    this.httpService.post(url, payload)
      .subscribe(
        res => {
          this.httpService.done()
          if (res.error) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: res.message })

          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: res.message })
            this.reiniciarTabela(false)
            this.fecharModal()

            //Gravar o log
            this.logManagement.registerLog(
              LogModule.STOCK,
              LogScreen.PRODUCTION_ORDER,
              url,
              LogAction.CREATE,
              null,
              this.ordemProd.historico,
              "Realizou uma nova ordem de produção",
              payload
            )
          }
        },
        error => {
          this.httpService.done()
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: "Erro ao criar a ordem de produção, contate o suporte: " + error.message })
        }
      )
  }

  async buscarListaItem(event: LazyLoadEvent = {}): Promise<void> {
    try {
      this.requisicaoItemCarregando = true
      await this.httpService.post('/custom/estoque/get-item-op(' + this.dblinkedSessionService.empresa.id + ')')
        .toPromise().then(res => {
          this.listaItem = []
          res.forEach(item => {
            this.listaItem.push({
              label: item.codigo + ' - ' + item.descricao + ' - ' + item.dadosAdi,
              value: item
            });
          })
        }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao carregar a lista de itens! Contate o suporte' }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.requisicaoItemCarregando = false
    }
  }

  async buscarListaAlmoxarifado(event: LazyLoadEvent = {}): Promise<void> {
    try {
      this.requisicaoAlmoxCarregando = true
      await this.httpService.get('/empresaalmoxarifado?$select=id,codigo,descricao' +
        '&$filter=empresa/id eq ' +
        this.dblinkedSessionService.empresa.id +
        '&$orderby=codigo')
        .toPromise().then(res => {
          this.listaAlmoxarifado = []
          res.value.forEach(local => {
            this.listaAlmoxarifado.push({
              label:
                local.codigo + ' - ' + local.descricao,
              value: local
            });
          });

        }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao carregar a lista de almoxarifados! Contate o suporte' }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.requisicaoAlmoxCarregando = false
    }
  }

  public get rowsPerPage(): Array<any> {
    return this.configService.applicationConfig.rowsPerPage
  }

  public get rows(): number {
    return this.configService.applicationConfig.rows
  }

  public get pageLinks(): number {
    return this.configService.applicationConfig.pageLinks
  }

  public atualizarToolbar(): void {
    this.toolbarMain.setVisible('btn-delete', this.ordSelecionadas.length > 0)
    this.toolbarMain.setVisible('btn-print', this.ordSelecionadas.length === 1)
    this.toolbarMain.setVisible('btn-reply', this.ordSelecionadas.length === 1)
    this.toolbarMain.setVisible('btn-search', this.ordSelecionadas.length === 1)
    this.toolbarMain.setVisible('btn-edit', this.ordSelecionadas.length === 1)
    this.toolbarMain.setVisible('btn-reprocessar', this.ordSelecionadas.length === 1)

    this.toolbarMain.setVisible('btn-loading', false)
  }

  validacaoModal(): boolean {
    if (parseFloat(this.ordemProd.qtde) <= 0) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'A quantidade deve ser maior que zero!' })
      return false
    }
    if (Object.keys(this.ordemProd.local).length == 0) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Selecione o Almoxarifado!' })
      return false
    }
    if (Object.keys(this.ordemProd.codItem).length == 0) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Selecione o Item!' })
      return false
    }
    if (this.ordemProd.historico == '' || !this.ordemProd.historico) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Preencha o histórico!' })
      return false
    }
    return true
  }

  private imprimirOp() {
    if (confirm('Deseja imprimir esta Ordem de Produção?')) {
      const itens = {
        idsSelecionados: this.ordSelecionadas[0].requisicaoId,
        empresaId: this.dblinkedSessionService.empresa.id
      };

      this.imprimirMovInternaOp(itens);
    }
  }

  private imprimirMovInternaOp(obj) {

    this.httpService.wait();
    this.httpService.post('/custom/estoque/impressao-op', obj).subscribe(
      result => {

        if (result.error) {
          this.reiniciarTabela(false);
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'Erro: ',
            detail: result.message
          });
        } else {

          this.exportedPedido(result)

        }

      },
      error =>
        this.httpService.handleError(error, () => this.imprimirMovInternaOp(obj)),
      () => this.httpService.done()
    );
  }

  private async reprocessarOp() {
    if (confirm('Deseja realmente reprocessar esta Ordem de Produção?')) {
      const payload = {
        movId: this.ordSelecionadas[0].requisicaoId,
        authUser: this.sessionService.loggedUser.id
      }

      this.toolbarCarregando()

      let url = '/custom/estoque/v2/reprocessar-op'
      try {
        await this.httpService.post(url, payload).toPromise()
        .then(res => {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: res.message })

          //Gravar o log
            this.logManagement.registerLog(
              LogModule.STOCK,
              LogScreen.PRODUCTION_ORDER,
              url,
              LogAction.EDIT,
              this.ordSelecionadas[0].requisicaoId,
              this.ordSelecionadas[0].documento,
              "Reprocessou uma ordem de produção",
              payload
            )
          })
          .catch(err => {
            if (err.status === 400) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: err.error.message })
                //Gravar o log
                this.logManagement.registerLog(
                  LogModule.STOCK,
                  LogScreen.PRODUCTION_ORDER,
                  url,
                  LogAction.EDIT,
                  this.ordSelecionadas[0].requisicaoId,
                  this.ordSelecionadas[0].documento,
                  "Reprocessou uma ordem de produção",
                  payload
                )
            }
          })
      } catch (err) {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro no API ao reprocessar a OP, contate o suporte: ' + err.message })
      } finally {
        this.reiniciarTabela(false)
        this.receberOrdens()
      }
    }
  }

  exportedPedido(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/ordensproducao'), '_blank');
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
    }
  }

  public saveFilterModal(): void {
    this.mostrarFiltro = false
    this.receberOrdens()
  }

  public mostrarModalFiltro(mostrar: boolean): void {
    this.mostrarFiltro = mostrar

    if (mostrar) {
      this.dataSelecionadaIni = this.dataIni
      this.dataSelecionadaFin = this.dataFin
    } else {
      this.dataIni = this.dataSelecionadaIni
      this.dataFin = this.dataSelecionadaFin
    }
  }

  downloadLog(file: string) {
    window.open(this.httpService.url("/custom/file/" + file),"_blank")
  }

}

