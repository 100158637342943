import { Component, OnInit } from '@angular/core';
import { MovLctoContabil } from '../model/mov-lcto-contabil.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'app-mov-lcto-contabil',
  templateUrl: './mov-lcto-contabil.component.html',
  styleUrls: ['./mov-lcto-contabil.component.scss'],
  providers: [CrudService]
})
export class MovLctoContabilComponent extends DataTableBasicComponent implements OnInit {
  public movLctoContabil: MovLctoContabil = new MovLctoContabil();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
    new DataTableColumn('Num Lcto', 'numLcto'),
    new DataTableColumn('Lote', 'lote'),
    new DataTableColumn('Data Lcto', 'dtLcto').setTypeDate(),
    new DataTableColumn('Valor', 'vlLcto'),
    new DataTableColumn('Indicador D/C', 'indDc'),
    new DataTableColumn('Numero Arquivo', 'numArq'),
    new DataTableColumn('Hist Padrão', 'cadHistPadrao.descrHist'),
    new DataTableColumn('Hist Complementar', 'histCompl'),
    new DataTableColumn('Participante', 'cadParticipante.nome'),
    new DataTableColumn('Ind Lcto', 'indLcto'),
    new DataTableColumn('Centro de Custo', 'cadCcus.descrCcus'),
    new DataTableColumn('Plano de Contas', 'planoContas.codCta')
    ];

    this.controls = [
    new DataControl('Num Lcto', 'numLcto')
        .setRequired()
        .setMaxLength(45),
    new DataControl('Lote', 'lote')
        .setRequired()
        .setMaxLength(45),
    new DataControl('Data Lcto', 'dtLcto')
        .setTypeDate()
        .setRequired(),
    new DataControl('Valor', 'vlLcto')
        .setTypeNumber()
        .setRequired(),
    new DataControl('Indicador D/C', 'indDc')
        .setRequired()
        .setMaxLength(1),
    new DataControl('Numero Arquivo', 'numArq')
        .setRequired()
        .setMaxLength(255),
    new DataControl('Hist Padrão', 'cadHistPadrao')
        .setTypeSelect()
        .setOptions({ url: '/cadhistpadrao', value: 'id', label: 'descrHist' }),
    new DataControl('Hist Complementar', 'histCompl')
        .setRequired()
        .setMaxLength(255),
    new DataControl('Participante', 'cadParticipante')
        .setTypeSelect()
        .setOptions({ url: '/cadparticipante', value: 'id', label: 'nome' }),
    new DataControl('Ind Lcto', 'indLcto')
        .setRequired()
        .setMaxLength(1),
    new DataControl('Centro de Custo', 'cadCcus')
        .setTypeSelect()
        .setOptions({ url: '/cadccus?$filter=ativo eq true', value: 'id', label: 'descrCcus' }),
    new DataControl('Plano de Contas', 'planoContas')
        .setTypeSelect()
        .setOptions({ url: '/custom/ecd/planocontas', value: 'id', label: 'codCta' })
    ];
  }
}
