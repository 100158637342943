import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CodEntRef } from '../../util/model/cod-ent-ref.model';
import { Config } from '../../app.config';
import { Empresa } from '../../cadastro/model/empresa.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { PlanoContas } from '../../contabil/model/plano-contas.model';
import { PlanoContasRefRelac } from '../../contabil/model/plano-contas-ref-relac.model';
import { TbIrpjM300Relac } from '../../ecf/model/tb-irpj-m300-relac.model';
import { TbCsllM350Relac } from '../../ecf/model/tb-csll-m350-relac.model';
import { TitleService } from 'app/shared/services/title.service';
import { CadCcus } from '../../contabil/model/cad-ccus.model';
import { PlanoContasRfb } from '../../contabil/model/plano-contas-rfb.model';
import { TbIrpjM300 } from '../../ecf/model/tb-irpj-m300.model';
import { TbCsllM350 } from '../../ecf/model/tb-csll-m350.model';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
  selector: 'app-teste',
  templateUrl: './plano-contas-ref-relac.component.html',
  styleUrls: ['./plano-contas-ref-relac.component.scss'],
})

export class PlanoContasRefRelacComponent implements OnInit, OnDestroy {
  @ViewChild('datatable') datatable: any;

  mapeamento: any;

  planoContasCount = 0;
  planoContasList: Array<PlanoContas> = new Array();
  planoContas: PlanoContas;

  opcoesRfb: Array<SelectItem> = new Array();
  opcoesAdicaoIrpj: Array<SelectItem> = new Array();
  opcoesExclusaoIrpj: Array<SelectItem> = new Array();
  opcoesAdicaoCsll: Array<SelectItem> = new Array();
  opcoesExclusaoCsll: Array<SelectItem> = new Array();
  opcoesCentroCusto: Array<SelectItem> = new Array();

  planoContasRefRelacList: Array<PlanoContasRefRelac> = new Array();
  planoContasRefRelac: PlanoContasRefRelac;

  tbIrpjM300RelacList: Array<TbIrpjM300Relac> = new Array();
  tbIrpjM300Relac: TbIrpjM300Relac;

  tbCsllM350Relac: TbCsllM350Relac;
  tbCsllM350RelacList: TbCsllM350Relac[];

  titlePrincipal: string;
  subtitle: string;

  private reload = false;

  private empresaSubscription: Subscription;

  constructor(private configService: ConfigService,
              private toolbarService: ToolbarService,
              private titleService: TitleService,
              private httpService: HttpService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService) {
    this.titleService.title = 'Mapeamento Referencial: RFB/IRPJ/CSLL';
  }

  ngOnInit() {
    this.titlePrincipal = 'PLANO CONTAS REFERENCIAL - RELAC';
    this.subtitle = 'RELACIONE O PLANO DE CONTAS DA EMPRESA COM O PLANO DE CONTAS DA RECEITA FEDERAL';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    // Promise.resolve(null).then(() => this.toolbarRefresh());
    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
                                                                 () => { this.load(); });
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  load() {
    this.planoContas = null;

    this.cancel();

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasYearSelected) {
      this.opcoesRfb = new Array();
      this.opcoesRfb.push({ label: 'Selecione uma conta referencial', value: null });
      this.httpService.wait();
      this.httpService.get('/planocontasrfb?$select=id,codCta,descrCta,tipoCta&$orderby=codCta')
                      .subscribe(planoContasRfbList => {
                                   planoContasRfbList.value.forEach(planoContasRfb => {
                                     this.opcoesRfb.push({ label: planoContasRfb.codCta + ' - ' + planoContasRfb.descrCta,
                                                           value: planoContasRfb.id,
                                                           data: planoContasRfb });
                                   });
                                 },
                                 error => this.httpService.handleError(error, () => this.load()),
                                 () => this.httpService.done());

      this.opcoesAdicaoIrpj = new Array();
      this.opcoesAdicaoIrpj.push({ label: 'Selecione uma conta de adição', value: null });
      this.httpService.wait();
      this.httpService.get('/custom/ecd/tb-irpj-m300-adi', [this.year, this.dblinkedSessionService.empresa.id])
                      .subscribe(tbIrpjM300List => {
                                   tbIrpjM300List.forEach(tbIrpjM300 => {
                                     this.opcoesAdicaoIrpj.push({ label: tbIrpjM300.cod + ' - ' + tbIrpjM300.descricao,
                                                                  value: tbIrpjM300.id,
                                                                  data: tbIrpjM300 });
                                   });
                                 },
                                 error => this.httpService.handleError(error, () => this.load()),
                                 () => this.httpService.done());

      this.opcoesAdicaoCsll = new Array();
      this.opcoesAdicaoCsll.push({ label: 'Selecione uma conta de adição', value: null });
      this.httpService.wait();
      this.httpService.get('/custom/ecd/tb-csll-m350-adi', [this.year, this.dblinkedSessionService.empresa.id])
                      .subscribe(tbCsllM350List => {
                                   tbCsllM350List.forEach(tbCsllM350 => {
                                     this.opcoesAdicaoCsll.push({ label: tbCsllM350.cod + ' - ' + tbCsllM350.descricao,
                                                                  value: tbCsllM350.id,
                                                                  data: tbCsllM350 });
                                   });
                                 },
                                 error => this.httpService.handleError(error, () => this.load()),
                                 () => this.httpService.done());

      this.opcoesExclusaoIrpj = new Array();
      this.opcoesExclusaoIrpj.push({ label: 'Selecione uma conta de exclusão', value: null });
      this.httpService.wait();
      this.httpService.get('/custom/ecd/tb-irpj-m300-exc', [this.year, this.dblinkedSessionService.empresa.id])
                      .subscribe(tbIrpjM300List => {
                                   tbIrpjM300List.forEach(tbIrpjM300 => {
                                     this.opcoesExclusaoIrpj.push({ label: tbIrpjM300.cod + ' - ' + tbIrpjM300.descricao,
                                                                    value: tbIrpjM300.id,
                                                                    data: tbIrpjM300 });
                                   });
                                 },
                                 error => this.httpService.handleError(error, () => this.load()),
                                 () => this.httpService.done());

      this.opcoesExclusaoCsll = new Array();
      this.opcoesExclusaoCsll.push({ label: 'Selecione uma conta de exclusão', value: null });
      this.httpService.wait();
      this.httpService.get('/custom/ecd/tb-csll-m350-exc', [this.year, this.dblinkedSessionService.empresa.id])
                      .subscribe(tbCsllM350List => {
                                   tbCsllM350List.forEach(tbCsllM350 => {
                                     this.opcoesExclusaoCsll.push({ label: tbCsllM350.cod + ' - ' + tbCsllM350.descricao,
                                                                    value: tbCsllM350.id,
                                                                    data: tbCsllM350 });
                                   });
                                 },
                                 error => this.httpService.handleError(error, () => this.load()),
                                 () => this.httpService.done());

      this.opcoesCentroCusto = new Array();
      this.opcoesCentroCusto.push({ label: '(Nenhum)', value: null });
      this.httpService.wait();
      this.httpService.get('/cadccus?$select=id,codCcus,descrCcus' +
                                   '&$filter=ativo eq true and empresa/id eq ' + this.dblinkedSessionService.empresa.id +
                                           ' and period(dtInicial,dtFinal) eq ' + this.year +
                                   '&$orderby=codCcus')
                      .subscribe(cadCcusList => {
                                   cadCcusList.value.forEach(cadCcus => {
                                     this.opcoesCentroCusto.push({ label: cadCcus.codCcus + ' - ' + cadCcus.descrCcus,
                                                                   value: cadCcus.id,
                                                                   data: cadCcus });
                                   });
                                 },
                                 error => this.httpService.handleError(error, () => this.load()),
                                 () => this.httpService.done());

      this.lazyLoadPlanosContas({ first: -1, rows: this.rows }, true);
    }
    this.toolbarRefresh();
  }

  loadIrpjAndCsll() {
    this.cancel();

    this.tbIrpjM300RelacList = new Array<TbIrpjM300Relac>();
    this.tbCsllM350RelacList = new Array<TbCsllM350Relac>();

    this.httpService.wait();
    this.httpService.get('/tbirpjm300relac?$select=id,cadCcus/id,cadCcus/codCcus,cadCcus/descrCcus,' +
                         'planoContas/codCta,planoContas/descrCta,tbIrpjM300Adicao/cod,' +
                         'tbIrpjM300Adicao/descricao,tbIrpjM300Exclusao/cod,' +
                         'tbIrpjM300Exclusao/descricao&$filter=planoContas/id eq ' + this.planoContas.id +
                                  ' and period(dtInicial,dtFinal) eq ' + this.year)
                    .subscribe(tbIrpjM300RelacList => {
                                 this.tbIrpjM300RelacList = tbIrpjM300RelacList.value;
                                 this.tbIrpjM300RelacList.forEach(tbIrpjM300Relac => {
                                   if (!tbIrpjM300Relac.cadCcus || !tbIrpjM300Relac.cadCcus.id) {
                                    tbIrpjM300Relac.cadCcus = new CadCcus();
                                   }
                                 });
                               },
                               error => this.httpService.handleError(error, () => this.loadIrpjAndCsll()),
                               () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/tbcsllm350relac?$select=id,cadCcus/id,cadCcus/codCcus,cadCcus/descrCcus,planoContas/codCta,' +
                         'planoContas/descrCta,tbCsllM350Adicao/id,tbCsllM350Adicao/cod,tbCsllM350Adicao/descricao,tbCsllM350Exclusao/id,' +
                         'tbCsllM350Exclusao/cod,tbCsllM350Exclusao/descricao&$filter=planoContas/id eq ' + this.planoContas.id +
                         ' and period(dtInicial,dtFinal) eq ' + this.year)
                    .subscribe(tbCsllM350RelacList => {
                                 this.tbCsllM350RelacList = tbCsllM350RelacList.value;
                                 this.tbCsllM350RelacList.forEach(tbCsllM350Relac => {
                                   if (!tbCsllM350Relac.cadCcus || !tbCsllM350Relac.cadCcus.id) {
                                    tbCsllM350Relac.cadCcus = new CadCcus();
                                   }
                                 });
                               },
                               error => this.httpService.handleError(error, () => this.loadIrpjAndCsll()),
                               () => this.httpService.done());
  }

  updateMapeamento(planocontas: Array<any> = null, skip: boolean = false) {
    this.reload = false;

    this.httpService.wait();
    this.httpService.get('/custom/ecd/mapeamento', [this.dblinkedSessionService.empresa.id + ',' + this.year])
                    .subscribe(mapeamento => this.mapeamento = mapeamento,
                               error => this.httpService.handleError(error, () => this.updateMapeamento(planocontas, skip)),
                               () => this.httpService.done());

    if (!skip) {
      if (planocontas) {
        this.planoContasList = planocontas;
      } else {
        this.lazyLoadPlanosContas({ first: 0, rows: this.rows });
      }
    }
  }

  lazyLoadPlanosContas(event: any, reload: boolean = this.reload) {
    if (event && this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasYearSelected) {
      if (this.datatable && event.first === -1) {
        this.datatable.reset();

        if (reload) {
          this.updateMapeamento(null, true);
        }
      } else if (event.first > -1) {
        this.httpService.wait();
        this.httpService.post('/custom/ecd/planocontas(' + this.dblinkedSessionService.empresa.id + ',' + this.year + ')', event)
                        .subscribe(planosContas => {
                                    this.planoContasCount = +planosContas['@odata.count'];
                                    this.planoContasList = planosContas.value;
                                  },
                                  error => this.httpService.handleError(error, () => this.lazyLoadPlanosContas(event, reload)),
                                  () => this.httpService.done());

        if (reload || !this.mapeamento) {
          this.updateMapeamento(null, true);
        }
      }
    }
    this.toolbarRefresh();
  }

  cancel() {
    this.planoContasRefRelac = new PlanoContasRefRelac();
    this.planoContasRefRelac.cadCcus = new CadCcus();
    this.planoContasRefRelac.planoContasRfb = new PlanoContasRfb();

    this.tbIrpjM300Relac = new TbIrpjM300Relac();
    this.tbIrpjM300Relac.cadCcus = new CadCcus();
    this.tbIrpjM300Relac.tbIrpjM300Adicao = new TbIrpjM300();
    this.tbIrpjM300Relac.tbIrpjM300Exclusao = new TbIrpjM300();

    this.tbCsllM350Relac = new TbCsllM350Relac();
    this.tbCsllM350Relac.cadCcus = new CadCcus();
    this.tbCsllM350Relac.tbCsllM350Adicao = new TbCsllM350();
    this.tbCsllM350Relac.tbCsllM350Exclusao = new TbCsllM350();
  }

  editPlanoContas(event: any) {
    if (event.data.indCta === 'A') {
      this.planoContas = this.clone(event.data);

      this.planoContasRefRelacList = new Array();

      this.httpService.wait();
      this.httpService.get('/planocontasrefrelac?$select=cadCcus/codCcus,cadCcus/descrCcus,planoContasRfb/codCta,planoContasRfb/descrCta' +
                            '&$filter=planoContas/id eq ' + this.planoContas.id +
                                    ' and period(dtInicial,dtFinal) eq ' + this.year)
                      .subscribe(planoContasRefRelacList => {
                                   this.planoContasRefRelacList = planoContasRefRelacList.value;
                                   this.planoContasRefRelacList.forEach(planoContasRefRelac => {
                                     if (!planoContasRefRelac.cadCcus || !planoContasRefRelac.cadCcus.id) {
                                       planoContasRefRelac.cadCcus = new CadCcus();
                                     }
                                   });
                                 },
                                 error => this.httpService.handleError(error, () => this.editPlanoContas(event)),
                                 () => this.httpService.done());

      this.loadIrpjAndCsll();
    }
    this.toolbarRefresh();
  }


  addPlanoContasRefRelac() {
    this.httpService.wait();
    this.httpService.post('/custom/ecd/planocontasrefrelac(' + this.year + ')',
                          this.dataPlanoContasRefRelac(this.planoContasRefRelac, this.planoContas))
                    .subscribe(id => {
                                 this.loadPlanoContasRefRelac(id);

                                 this.reload = true;
                               },
                               error => this.httpService.handleError(error, () => this.addPlanoContasRefRelac()),
                               () => this.httpService.done());
  }

  addTbIrpjM300Relac() {
    this.httpService.wait();
    this.httpService.post('/custom/ecd/tbirpjm300relac(' + this.year + ')',
                          this.dataTbIrpjM300Relac(this.tbIrpjM300Relac, this.planoContas))
                    .subscribe(() => this.loadIrpjAndCsll(),
                               error => this.httpService.handleError(error, () => this.addTbIrpjM300Relac()),
                               () => this.httpService.done());
  }

  addTbCsllM350Relac() {
    this.httpService.wait();
    this.httpService.post('/custom/ecd/tbcsllm350relac(' + this.year + ')',
                          this.dataTbCsllM350Relac(this.tbCsllM350Relac, this.planoContas))
                    .subscribe(() => this.loadIrpjAndCsll(),
                               error => this.httpService.handleError(error, () => this.addTbCsllM350Relac()),
                               () => this.httpService.done());
  }


  get planoContasRefRelacSelection(): any {
    return this.selection(this.planoContasRefRelacList, this.planoContasRefRelac);
  }

  get tbIrpjM300RelacSelection(): any {
    return this.selection(this.tbIrpjM300RelacList, this.tbIrpjM300Relac);
  }

  get tbCsllM350RelacSelection(): any {
    return this.selection(this.tbCsllM350RelacList, this.tbCsllM350Relac);
  }

  private selection(list: Array<any>, item: any): any {
    return item && item.id ? list.find(r => r.id === item.id) : null;
  }


  editPlanoContasRefRelac(event: any) {
    this.planoContasRefRelac = this.clone(event.data);
  }

  editTbIrpjM300Relac(event: any) {
    this.tbIrpjM300Relac = this.clone(event.data);
  }

  editTbCsllM350Relac(event: any) {
    this.tbCsllM350Relac = this.clone(event.data);
  }


  updatePlanoContasRefRelac() {
    this.httpService.wait();
    this.httpService.put('/custom/ecd/planocontasrefrelac', [this.year, this.planoContasRefRelac.id],
                         this.dataPlanoContasRefRelac(this.planoContasRefRelac, this.planoContas))
                    .subscribe(id => {
                                 this.loadPlanoContasRefRelac(id, this.planoContasRefRelac.id);
                               },
                               error => this.httpService.handleError(error, () => this.updatePlanoContasRefRelac()),
                               () => this.httpService.done());
  }

  updateTbIrpjM300Relac() {
    this.httpService.wait();
    this.httpService.put('/custom/ecd/tbirpjm300relac', [this.year, this.tbIrpjM300Relac.id],
                         this.dataTbIrpjM300Relac(this.tbIrpjM300Relac, this.planoContas))
                    .subscribe(() => this.loadIrpjAndCsll(),
                               error => this.httpService.handleError(error, () => this.updateTbIrpjM300Relac()),
                               () => this.httpService.done());
  }

  updateTbCsllM350Relac() {
    this.httpService.wait();
    this.httpService.put('/custom/ecd/tbcsllm350relac', [this.year, this.tbCsllM350Relac.id],
                         this.dataTbCsllM350Relac(this.tbCsllM350Relac, this.planoContas))
                    .subscribe(() => this.loadIrpjAndCsll(),
                               error => this.httpService.handleError(error, () => this.updateTbCsllM350Relac()),
                               () => this.httpService.done());
  }


  deletePlanoContasRefRelac(planoContasRefRelac: PlanoContasRefRelac) {
    if (confirm('Deseja excluir o registro atual?')) {
      this.httpService.wait();
      this.httpService.delete('/custom/ecd/planocontasrefrelac', [this.year, planoContasRefRelac.id])
                      .subscribe(() => {
                                   this.planoContasRefRelacList = this.planoContasRefRelacList.filter(p => p.id !== planoContasRefRelac.id);

                                   this.cancel();
                                   this.reload = true;
                                 },
                                 error => this.httpService.handleError(error, () => this.deletePlanoContasRefRelac(planoContasRefRelac)),
                                 () => this.httpService.done());
    }
  }

  deleteTbIrpjM300Relac(tbIrpjM300Relac: TbIrpjM300Relac) {
    if (confirm('Deseja excluir o registro atual?')) {
      this.httpService.wait();
      this.httpService.delete('/custom/ecd/tbirpjm300relac', [this.year, tbIrpjM300Relac.id])
                      .subscribe(() => this.loadIrpjAndCsll(),
                                 error => this.httpService.handleError(error, () => this.deleteTbIrpjM300Relac(tbIrpjM300Relac)),
                                 () => this.httpService.done());
    }
  }

  deleteTbCsllM350Relac(tbCsllM350Relac: TbCsllM350Relac) {
    if (confirm('Deseja excluir o registro atual?')) {
      this.httpService.wait();
      this.httpService.delete('/custom/ecd/tbcsllm350relac', [this.year, tbCsllM350Relac.id])
                      .subscribe(() => this.loadIrpjAndCsll(),
                                 error => this.httpService.handleError(error, () => this.deleteTbCsllM350Relac(tbCsllM350Relac)),
                                 () => this.httpService.done());
    }
  }


  get editPlanoContasRefRelacDisabled(): boolean {
    return this.editDisabled(this.planoContasRefRelacList, this.planoContasRefRelac);
  }

  get editTbIrpjM300RelacDisabled(): boolean {
    return this.editDisabled(this.tbIrpjM300RelacList, this.tbIrpjM300Relac);
  }

  get editTbCsllM350RelacDisabled(): boolean {
    return this.editDisabled(this.tbCsllM350RelacList, this.tbCsllM350Relac);
  }

  private editDisabled(list: Array<any>, item: any): any {
    return list && list.length > 0 && !item.id;
  }


  get addPlanoContasRefRelacDisabled(): boolean {
    if (!this.planoContasRefRelac || !this.planoContasRefRelac.planoContasRfb || !this.planoContasRefRelac.planoContasRfb.id ||
        (this.planoContasRefRelacList && this.planoContasRefRelacList.length === 1)) {
      return true;
    }

    const opcaoRfb = this.opcoesRfb.find(o => o.value === this.planoContasRefRelac.planoContasRfb.id);
    if (!opcaoRfb || opcaoRfb.data && opcaoRfb.data.tipoCta === 'S') {
      return true;
    }

    return !this.planoContasRefRelac.cadCcus ||
           this.planoContasRefRelacList.find(p => !p.cadCcus ||
                                                  (p.cadCcus && this.planoContasRefRelac.cadCcus &&
                                                   p.cadCcus.id === this.planoContasRefRelac.cadCcus.id)) !== undefined ||
           this.planoContasRefRelacList.find(p => (p.planoContasRfb && this.planoContasRefRelac &&
                                                   p.planoContasRfb.id === this.planoContasRefRelac.planoContasRfb.id)) !== undefined;
  }

  get addTbIrpjM300RelacDisabled(): boolean {
    if (!this.tbIrpjM300Relac ||
        !this.tbIrpjM300Relac.tbIrpjM300Adicao || !this.tbIrpjM300Relac.tbIrpjM300Adicao.id ||
        !this.tbIrpjM300Relac.tbIrpjM300Exclusao || !this.tbIrpjM300Relac.tbIrpjM300Exclusao.id ||
        (this.tbIrpjM300RelacList && this.tbIrpjM300RelacList.length === 1)) {
      return true;
    }

    const opcaoAdicaoIrpj = this.opcoesAdicaoIrpj.find(o => o.value === this.tbIrpjM300Relac.tbIrpjM300Adicao.id);
    if (!opcaoAdicaoIrpj) {
      return true;
    }

    const opcaoExclusaoIrpj = this.opcoesExclusaoIrpj.find(o => o.value === this.tbIrpjM300Relac.tbIrpjM300Exclusao.id);
    if (!opcaoExclusaoIrpj) {
      return true;
    }

    return !this.tbIrpjM300Relac.cadCcus ||
           this.tbIrpjM300RelacList.find(r => !r.cadCcus ||
                                              (r.cadCcus && this.tbIrpjM300Relac.cadCcus &&
                                               r.cadCcus.id === this.tbIrpjM300Relac.cadCcus.id)) !== undefined ||
           this.tbIrpjM300RelacList.find(r => (r.tbIrpjM300Adicao && r.tbIrpjM300Exclusao && this.tbIrpjM300Relac &&
                                               (r.tbIrpjM300Adicao.id === this.tbIrpjM300Relac.tbIrpjM300Adicao.id ||
                                                r.tbIrpjM300Exclusao.id === this.tbIrpjM300Relac.tbIrpjM300Exclusao.id))) !== undefined;
  }

  get addTbCsllM350RelacDisabled(): boolean {
    if (!this.tbCsllM350Relac ||
        !this.tbCsllM350Relac.tbCsllM350Adicao || !this.tbCsllM350Relac.tbCsllM350Adicao.id ||
        !this.tbCsllM350Relac.tbCsllM350Exclusao || !this.tbCsllM350Relac.tbCsllM350Exclusao.id ||
        (this.tbCsllM350RelacList && this.tbCsllM350RelacList.length === 1)) {
      return true;
    }

    const opcaoAdicaoCsll = this.opcoesAdicaoCsll.find(o => o.value === this.tbCsllM350Relac.tbCsllM350Adicao.id);
    if (!opcaoAdicaoCsll) {
      return true;
    }

    const opcaoExclusaoCsll = this.opcoesExclusaoCsll.find(o => o.value === this.tbCsllM350Relac.tbCsllM350Exclusao.id);
    if (!opcaoExclusaoCsll) {
      return true;
    }

    return !this.tbCsllM350Relac.cadCcus ||
           this.tbCsllM350RelacList.find(r => !r.cadCcus ||
                                              (r.cadCcus && this.tbCsllM350Relac.cadCcus &&
                                               r.cadCcus.id === this.tbCsllM350Relac.cadCcus.id)) !== undefined ||
           this.tbCsllM350RelacList.find(r => (r.tbCsllM350Adicao && r.tbCsllM350Exclusao && this.tbCsllM350Relac &&
                                               (r.tbCsllM350Adicao.id === this.tbCsllM350Relac.tbCsllM350Adicao.id ||
                                                r.tbCsllM350Exclusao.id === this.tbCsllM350Relac.tbCsllM350Exclusao.id))) !== undefined;
  }


  get updatePlanoContasRefRelacDisabled(): boolean {
    if (!this.planoContasRefRelac || !this.planoContasRefRelac.id ||
        !this.planoContasRefRelac.planoContasRfb || !this.planoContasRefRelac.planoContasRfb.id ||
        !this.planoContasRefRelacList || this.planoContasRefRelacList.length === 0) {
      return true;
    }

    const opcaoRfb = this.opcoesRfb.find(o => o.value === this.planoContasRefRelac.planoContasRfb.id);
    if (!opcaoRfb || opcaoRfb.data && opcaoRfb.data.tipoCta === 'S') {
      return true;
    }

    return this.planoContasRefRelacList.find(r => r.planoContasRfb.id === this.planoContasRefRelac.planoContasRfb.id &&
                                                  r.cadCcus.id === this.planoContasRefRelac.cadCcus.id) !== undefined;
  }

  get updateTbIrpjM300RelacDisabled(): boolean {
    if (!this.tbIrpjM300Relac || !this.tbIrpjM300Relac.id ||
        !this.tbIrpjM300Relac.tbIrpjM300Adicao || !this.tbIrpjM300Relac.tbIrpjM300Adicao.id ||
        !this.tbIrpjM300Relac.tbIrpjM300Exclusao || !this.tbIrpjM300Relac.tbIrpjM300Exclusao.id ||
        !this.tbIrpjM300RelacList || this.tbIrpjM300RelacList.length === 0) {
      return true;
    }

    const opcaoAdicaoIrpj = this.opcoesAdicaoIrpj.find(o => o.value === this.tbIrpjM300Relac.tbIrpjM300Adicao.id);
    if (!opcaoAdicaoIrpj) {
      return true;
    }

    const opcaoExclusaoIrpj = this.opcoesExclusaoIrpj.find(o => o.value === this.tbIrpjM300Relac.tbIrpjM300Exclusao.id);
    if (!opcaoExclusaoIrpj) {
      return true;
    }

    return this.tbIrpjM300RelacList.find(r => r.tbIrpjM300Adicao.id === this.tbIrpjM300Relac.tbIrpjM300Adicao.id &&
                                              r.tbIrpjM300Exclusao.id === this.tbIrpjM300Relac.tbIrpjM300Exclusao.id &&
                                              r.cadCcus.id === this.tbIrpjM300Relac.cadCcus.id) !== undefined;
  }

  get updateTbCsllM350RelacDisabled(): boolean {
    if (!this.tbCsllM350Relac || !this.tbCsllM350Relac.id ||
        !this.tbCsllM350Relac.tbCsllM350Adicao || !this.tbCsllM350Relac.tbCsllM350Adicao.id ||
        !this.tbCsllM350Relac.tbCsllM350Exclusao || !this.tbCsllM350Relac.tbCsllM350Exclusao.id ||
        !this.tbCsllM350RelacList || this.tbCsllM350RelacList.length === 0) {
      return true;
    }

    const opcaoAdicaoCsll = this.opcoesAdicaoCsll.find(o => o.value === this.tbCsllM350Relac.tbCsllM350Adicao.id);
    if (!opcaoAdicaoCsll) {
      return true;
    }

    const opcaoExclusaoCsll = this.opcoesExclusaoCsll.find(o => o.value === this.tbCsllM350Relac.tbCsllM350Exclusao.id);
    if (!opcaoExclusaoCsll) {
      return true;
    }

    return this.tbCsllM350RelacList.find(r => r.tbCsllM350Adicao.id === this.tbCsllM350Relac.tbCsllM350Adicao.id &&
                                              r.tbCsllM350Exclusao.id === this.tbCsllM350Relac.tbCsllM350Exclusao.id &&
                                              r.cadCcus.id === this.tbCsllM350Relac.cadCcus.id) !== undefined;
  }


  get cancelPlanoContasRefRelacDisabled(): boolean {
    return !this.planoContasRefRelac.id && this.addPlanoContasRefRelacDisabled;
  }

  get cancelTbIrpjM300RelacDisabled(): boolean {
    return !this.tbIrpjM300Relac.id && this.addTbIrpjM300RelacDisabled;
  }

  get cancelTbCsllM350RelacDisabled(): boolean {
    return !this.tbCsllM350Relac.id && this.addTbCsllM350RelacDisabled;
  }


  private dataPlanoContasRefRelac(planoContasRefRelac: PlanoContasRefRelac, planoContas: PlanoContas) {
    const data = { id: planoContasRefRelac.id,
                   planoContas: { id: planoContas.id },
                   planoContasRfb: { id: planoContasRefRelac.planoContasRfb.id },
                   cadCcus: undefined };
    if (planoContasRefRelac.cadCcus.id) {
      data.cadCcus = { id: planoContasRefRelac.cadCcus.id };
    }

    return data;
  }

  private dataTbIrpjM300Relac(tbIrpjM300Relac: TbIrpjM300Relac, planoContas: PlanoContas) {
    const data = { id: tbIrpjM300Relac.id,
                   planoContas: { id: planoContas.id },
                   tbIrpjM300Adicao: { id: tbIrpjM300Relac.tbIrpjM300Adicao.id },
                   tbIrpjM300Exclusao: { id: tbIrpjM300Relac.tbIrpjM300Exclusao.id },
                   cadCcus: undefined };
    if (tbIrpjM300Relac.cadCcus && tbIrpjM300Relac.cadCcus.id) {
      data.cadCcus = { id: tbIrpjM300Relac.cadCcus.id };
    }

    return data;
  }

  private dataTbCsllM350Relac(tbCsllM350Relac: TbCsllM350Relac, planoContas: PlanoContas) {
    const data = { id: tbCsllM350Relac.id,
                   planoContas: { id: planoContas.id },
                   tbCsllM350Adicao: { id: tbCsllM350Relac.tbCsllM350Adicao.id },
                   tbCsllM350Exclusao: { id: tbCsllM350Relac.tbCsllM350Exclusao.id },
                   cadCcus: undefined };
    if (tbCsllM350Relac.cadCcus.id) {
      data.cadCcus = { id: tbCsllM350Relac.cadCcus.id };
    }

    return data;
  }


  private loadPlanoContasRefRelac(id: number, oldId: number = id) {
    this.httpService.wait();
    this.httpService.get('/planocontasrefrelac?$select=cadCcus/codCcus,cadCcus/descrCcus,planoContasRfb/codCta,planoContasRfb/descrCta', id)
                    .subscribe(planoContasRefRelac => {
                                 if (!planoContasRefRelac.value.cadCcus) {
                                   planoContasRefRelac.value.cadCcus = {};
                                 }

                                 this.planoContasRefRelacList = this.planoContasRefRelacList.filter(p => p.id !== id && p.id !== oldId);

                                 this.planoContasRefRelacList.push(planoContasRefRelac.value);

                                 this.cancel();
                               },
                               error => this.httpService.handleError(error, () => this.loadPlanoContasRefRelac(id)),
                               () => this.httpService.done());
  }

  planoContasListStyleClass(planoContas: PlanoContas): string {
    let classes = '';

    if (planoContas.indCta === 'S') {
      classes += 'bold locked ';
    }

    return classes.trim();
  }

  private clone(object: any) {
    if (object === undefined || object === null) {
      return object;
    }

    return JSON.parse(JSON.stringify(object));
  }


  get title(): string {
    return this.titleService.title;
  }

  get planoContasListDistinct(): Array<PlanoContas | any> {
    const distinctPlanoContasList: Array<PlanoContas | any> = new Array();

    this.planoContasList.forEach((planoContas: PlanoContas | any) => {
      if (distinctPlanoContasList.findIndex(distinct => distinct.codCta === planoContas.codCta) === -1) {
        switch (planoContas.status) {
          case true:
            planoContas.status = 'MAPEADO';
            break;
          case false:
            planoContas.status = 'PENDENTE';
            break;
        }

        distinctPlanoContasList.push(planoContas);
      }
    });

    return distinctPlanoContasList;
  }

  private toolbarRefresh() {
    // this.toolbarService.clear();
    if (this.planoContas) {
      this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.load());
      // this.toolbarService.add('back', false, 'class-back-button', 'undo', 'Voltar', () => this.back());
    } else {
      this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.load());
    }
    /*this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');*/
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get year(): number {
    return this.dblinkedSessionService.periodo.year.value;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows50;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}
