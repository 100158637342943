import { Empresa } from "app/cadastro/model/empresa.model";
import { Area } from "app/orcamento/model/area-model";
import { MunicipioIbge } from "app/util/model/municipio-ibge.model";
import { Pais } from "app/util/model/pais.model";
import { isNullOrUndefined } from "util";
import { Cargo } from "./cargo.model";

export class Funcionario {
  id: number
  empresa: Empresa
  cargo: Cargo
  nome: string = null
  codigo: number = null
  admissao: Date = null
  nascimento: Date = null
  titulacao: string = null
  telefone: string = null
  cpf: string = null
  rg: string = null
  banco: string = null
  agencia: string = null
  conta: string = null
  email: string = null
  pais: Pais
  cidade: MunicipioIbge
  sexo: string = null
  gerente: Funcionario
  graduacao: string = null
  especializacao: string = null
  carreira: Date = null
  area: Area
  atuacao: number = null
  ativo: boolean = true
  demissao: Date = null

  constructor(funcionario?: Funcionario | number) {
    if (!isNullOrUndefined(funcionario)) {
      if (typeof funcionario === 'number') {
        this.id = funcionario;

      } else {
        this.id = funcionario.id
        this.empresa = funcionario.empresa
        this.nome = funcionario.nome
        this.codigo = funcionario.codigo
        this.admissao = funcionario.admissao
        this.nascimento = funcionario.nascimento
        this.titulacao = funcionario.titulacao
        this.telefone = funcionario.telefone
        this.cpf = funcionario.cpf
        this.rg = funcionario.rg
        this.banco = funcionario.banco
        this.agencia = funcionario.agencia
        this.conta = funcionario.conta
        this.email = funcionario.email
        this.pais = funcionario.pais
        this.cidade = funcionario.cidade
        this.sexo = funcionario.sexo
        this.gerente = funcionario.gerente
        this.graduacao = funcionario.graduacao
        this.especializacao = funcionario.especializacao
        this.carreira = funcionario.carreira
        this.area = funcionario.area
        this.atuacao = funcionario.atuacao
        this.ativo = funcionario.ativo
        this.demissao = funcionario.demissao
      }
    }
  }
}
