import { CadHistPadrao } from 'app/contabil/model/cad-hist-padrao.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { Lancamento } from 'app/contabil/model/lancamento.model';
import { CadParticipante } from 'app/contabil/model/cad-participante.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { CadProj } from './cad-proj.model';
import { SelectItem } from 'primeng/api';

export class LancamentoPartida {
  id: number;
  indDc: string;
  valor: number;
  numArq: string;
  histCompl: string;
  cadHistPadrao: CadHistPadrao;
  cadCcus: CadCcus = new CadCcus();
  cadParticipante: CadParticipante = new CadParticipante();
  lancamento: Lancamento = new Lancamento();
  planoContas: PlanoContas = new PlanoContas();
  cadProj: CadProj = new CadProj();
  opcoesProjetos: SelectItem[];

constructor(lancamentopartida?: LancamentoPartida | number) {
    if (lancamentopartida !== undefined && lancamentopartida !== null) {
      if (typeof lancamentopartida === 'number') {
        this.id = lancamentopartida;
      } else {
        this.id = lancamentopartida.id;
        this.indDc = lancamentopartida.indDc;
        this.valor = lancamentopartida.valor;
        this.numArq = lancamentopartida.numArq;
        this.histCompl = lancamentopartida.histCompl;
        this.cadHistPadrao = lancamentopartida.cadHistPadrao;
        this.cadCcus = lancamentopartida.cadCcus || new CadCcus();
        this.cadParticipante = lancamentopartida.cadParticipante || new CadParticipante();
        this.lancamento = lancamentopartida.lancamento || new Lancamento();
        this.planoContas = lancamentopartida.planoContas || new PlanoContas();
        this.cadProj = lancamentopartida.cadProj || new CadProj();
        this.opcoesProjetos = lancamentopartida.opcoesProjetos;
      }
    }
  }
}
