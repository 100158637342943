export class TbNbs {
  id: number;
  cod: string;
  descricao: string;

  constructor(tbNbs?: TbNbs | number) {
    if (tbNbs) {
      if (typeof tbNbs === 'number') {
        this.id = tbNbs;
      } else {
        this.id = tbNbs.id;
        this.cod = tbNbs.cod;
        this.descricao = tbNbs.descricao;
      }
    }
  }
}
