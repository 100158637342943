export class ModNf {
  id: number;
  codigo: string;
  descricao: string;

  constructor(modNf?: ModNf | number) {
    if (modNf !== undefined && modNf !== null) {
      if (typeof modNf === 'number') {
        this.id = modNf;
      } else {
        this.id = modNf.id;
        this.codigo = modNf.codigo;
        this.descricao = modNf.descricao;
      }
    }
  }
}
