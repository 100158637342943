export class PlataformaTipo {
  id        : number;
  descricao : string;

  constructor(plataformaTipo?: PlataformaTipo | number) {
    if (plataformaTipo !== undefined && plataformaTipo !== null) {
      if (typeof plataformaTipo === 'number') {
        this.id = plataformaTipo;
      } else {
        this.id         = plataformaTipo.id;
        this.descricao  = plataformaTipo.descricao;

      }
    }
  }
}
