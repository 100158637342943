import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { isNullOrUndefined } from 'util';
import { Message } from 'primeng/api';


@Component({
  selector: 'app-consulta-clientes',
  templateUrl: './consulta-clientes.component.html',
  styleUrls: ['./consulta-clientes.component.scss'],
  providers: [CrudService]
})
export class ConsultaClientesComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  pt_BR: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;


  checkedAll = false;

  modalParticipantePorEvento = false;
  modalEditarParticipante: Boolean;

  listaClientes   : Array<any> = new Array();
  modalInfCliente : boolean = false;
  selectedIndex   : number = 0;
  participanteId  : number = 0;
  depId           : number = 0;
  listaEventosParticipante : Array<any> = new Array();
  listaEventosPagamento    : Array<any> = new Array();
  listaExport              : Array<any|any|any|any|any|any|any|any|any|any|any> = new Array();
  nome            : string = '';
  cpf             : string = '';
  fone            : string = '';
  email           : string = '';
  cidade          : string = '';
  uf              : string = '';
  rua             : string = '';
  numero          : string = '';
  cep             : string = '';
  bairro          : string = '';
  cnpj            : string = '';
  aba1: boolean = true;
  aba2: boolean;
  aba3 :boolean;
  colslistaClientes : any[];
  colslistaEventosParticipante : any[];
  colslistaEventosPagamento : any[];

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'CLIENTES';
    this.subtitle = '';
    this.titleService.title = 'CLIENTES';


    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };


    this.colslistaClientes = [
      { field: 'nomeCpfCnpj', header: 'NOME' },
      { field: 'fone', header: 'FONE' },
      { field: 'email', header: 'EMAIL' }
    ];

    this.colslistaEventosParticipante = [
      { field: 'dataInicio', header: 'DT INICIO EV.' },
      { field: 'localCompleto', header: 'LOCAL' },
      { field: 'codigoEvento', header: 'COD. EV.' },
      { field: 'descricao', header: 'EVENTO' },
      { field: 'codigoIngresso', header: 'INGRESSO' },
      { field: 'valor', header: 'VALOR' },
      { field: 'statusDescricao', header: 'STATUS' },
      { field: 'origem', header: 'ORIGEM' },
      { field: 'deptoDescricao', header: 'DEPTO' }
    ];

    this.colslistaEventosPagamento = [
      { field: 'codigoEvento', header: 'COD. EV.' },
      { field: 'descricaoEvento', header: 'EVENTO' },
      { field: 'descricaoFormaPagamento', header: 'FORMA PAG.' },
      { field: 'nomeBanco', header: 'BANCO' },
      { field: 'agencia', header: 'AGÊNCIA' },
      { field: 'conta', header: 'CONTA' },
      { field: 'dataPrimeiroVencimento', header: 'DT PRI. VENC.' },
      { field: 'parcela', header: 'QTD. PARC.' },
      { field: 'numeroCartao', header: 'N. CARTÃO' },
      { field: 'autorizacao', header: 'AUTORIZAÇÃO' },
      { field: 'numeroDocumento', header: 'N. DOC' },
      { field: 'numeroTerminal', header: 'N. TERM.' },
      { field: 'numeroEstabelecimento', header: 'N. EST.' },
      { field: 'valorParcela', header: 'VL PARC.' },
      { field: 'valorPagamento', header: 'VL TOTAL.' }
    ];


    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();


    this.checkedAll = false;

    this.modalParticipantePorEvento = false;

    this.modalEditarParticipante          = false;


    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {


        this.toolbarEnable();
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

            this.listar();

        }
        setTimeout(() => {

        }, 1000);

      });

  }


  hideMessage() {




  }


  configuraToolBar() {

  }



  onFilterEvento(event: any) {
  }

  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('edit');


    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.listar()
    );
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Nova Palestra',   () => ''     );

  }


  listar() {
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-lista-cliente-eventos(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(retorno => {
        this.listaClientes = new Array();
        retorno.forEach(element => {
          this.listaClientes.push(element);
        });

      },
        error => this.httpService.handleError(error, () => this.listar()),
        () => this.httpService.done());



  }


  ngOnDestroy(): void {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  tabChange(event: any){


    switch (event.index) {

      case 1:
        this.getListaEventos(this.participanteId, this.depId, this.nome);
        break;

      case 2: // pagamento
          this.getListaPagamentos(this.participanteId, this.depId, this.nome);
          break;

      default:
          this.getInfParticipante(this.participanteId, this.depId, this.nome);
          break;
    }


  }

  showModalInfCliente(value : any){

    this.nome   = '';
    this.cpf    = '';
    this.fone   = '';
    this.email  = '';
    this.cidade = '';
    this.uf     = '';
    this.rua    = '';
    this.numero = '';
    this.cep    = '';
    this.bairro = '';
    this.listaEventosParticipante = new Array();
    this.listaEventosPagamento    = new Array();
    this.participanteId = 0;
    this.selectedIndex  = 0;
    this.participanteId = value.participanteId;
    this.depId          = value.depId;
    this.nome           = value.nome;

    this.aba1 = true;
    this.aba2 = false;
    this.aba3 = false;

    //getInfParticipante(participanteId : number,depId : number , participanteNome :string)
    this.getInfParticipante(this.participanteId, this.depId, this.nome)

    this.modalInfCliente = true;
  }

 getListaEventos(participanteId: number, depId: number, participanteNome :string){


    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-lista-eventos-participante('+ [participanteId, depId, participanteNome]  +')')
      .subscribe(retorno => {

        this.listaEventosParticipante = new Array();
        retorno.forEach(element => {
          this.listaEventosParticipante.push(element);
        });

      },
        error => this.httpService.handleError(error, () => this.getListaEventos(participanteId, depId, participanteNome )),
        () => this.httpService.done());


 }

 getListaPagamentos(participanteId: number, depId: number, participanteNome :string){
  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-lista-pagamento-eventos-participante('+ [participanteId, depId, participanteNome]  +')')
    .subscribe(retorno => {

      this.listaEventosPagamento = new Array();
      retorno.forEach(element => {
        this.listaEventosPagamento.push(element);
      });

    },
      error => this.httpService.handleError(error, () => this.getListaEventos(participanteId, depId, participanteNome )),
      () => this.httpService.done());


}

 getInfParticipante(participanteId : number,depId : number , participanteNome :string){
   //if (participanteId > 0) {
    try {
      this.httpService.wait();
      this.httpService.get('/custom/polozi/get-inf-participante('+ [participanteId, depId , participanteNome] +')')
        .subscribe(retorno => {

          this.cpf    = '';
          this.fone   = '';
          this.email  = '';
          this.cidade = '';
          this.uf     = '';
          this.rua    = '';
          this.numero = '';
          this.cep    = '';
          this.bairro = '';
          this.cnpj   = '';
          retorno.forEach(element => {
            this.fone = element.fone;
            this.email = element.email ;
            this.rua   = element.logradouro;
            this.cidade = element.nomeCidade;
            this.numero = element.numero;
            this.cpf    = element.cpf;
            this.uf     = element.uf;
            this.cep    = element.cep;
            this.bairro = element.bairro;
            this.cnpj   = element.cnpj;

          });

        },
          error => this.httpService.handleError(error, () => this.getInfParticipante( participanteId, this.depId, participanteNome)),
          () => this.httpService.done());

    } catch (error) {

    }


  // }

 }

 exportarCSV(){

  this.listaExport = new Array();
  this.listaExport.push({ 'nome': this.nome, 'cpf': this.cpf, 'cnpj': this.cnpj , 'fone': this.fone,'email': this.email , 'cep': this.cep, 'rua': this.rua, 'numero': this.numero, 'bairro': this.bairro, 'cidade': this.cidade, 'uf': this.uf  } );
  this.downloadFile(this.listaExport,'download');
}

downloadFile(data, filename='data') {

  let csvData = this.ConvertToCSV(data, [ 'nome','cpf', 'cnpj','fone', 'email', 'cep', 'rua', 'numero', 'bairro', 'cidade', 'uf' ]);

  let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}


ConvertToCSV(objArray, headerList) {
  let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  let row = '';
  for (let index in headerList) {
  row += headerList[index] + ',';
  }
  row = row.slice(0, -1);
  str += row + '\r\n';
  for (let i = 0; i < array.length; i++) {
  let line = '';
  let contador = 0;
  for (let index in headerList) {
    let head = headerList[index];
    if(contador > 0){
      line += ',' + array[i][head];
    } else {
      line += array[i][head];
    }

    contador++;
  }
  str += line + '\r\n';
  }
  return str;
}


showTela(tipo: string){
  if(tipo === '1'){
    this.aba1 = true;
    this.aba2 = false;
    this.aba3 = false;

  } else if(tipo === '2')  {
    this.aba1 = false;
    this.aba2 = true;
    this.aba3 = false;
    this.getListaEventos(this.participanteId, this.depId, this.nome);

  } else{
    this.aba1 = false;
    this.aba2 = false;
    this.aba3 = true;
    this.getListaPagamentos(this.participanteId, this.depId, this.nome);

  }

}

gerarRelatorioExcel(value: any){
  console.log(value);
}


onFilter(event: any){

}


}
