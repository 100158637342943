import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../app.config';
import { Period } from '../../shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { TimezonePipe } from '../../shared/pipes/timezone.pipe';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { TipoDocumento } from 'app/util/model/tipo-documento.model';
import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { Justificativas } from 'app/util/model/justificativas.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { Departamento } from 'app/util/model/departamento.model';
import { Obrigacao } from 'app/centralweb/model/obrigacao.model';
import { Envio } from 'app/centralweb/model/envio.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { EnvioDestinatarios } from 'app/centralweb/model/envio-destinatarios.model';
import { CompileReflector } from '@angular/compiler';
import { ComprovantePagamento } from 'app/centralweb/model/comprovante-pagamento.model';

@Component({
  selector: 'app-enviados',
  templateUrl: './enviados.component.html',
  styleUrls: ['./enviados.component.scss'],
  providers: [CrudService]
})
export class EnviadosComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  events: any[];

  recorrencias: SelectItem[];
  recorrenciasEditar: SelectItem[];
  departamentos: any[];
  documentos: any[];
  gatilhos: any[];
  tiposArquivo: any[];
  codigosObrigacao: any[];
  qtdes: any[];
  dias: any[];
  tipoDocumento: any[];
  gatilhosEditar: any[];
  envioDestinatariosList: Array<EnvioDestinatarios> = new Array();
  empresas: Array<Empresa> = new Array();
  obrigacoes: Array<any> = new Array();

  envios: Array<any> = new Array();
  empresasSelecionadas: Empresa[];
  obrigacao: Obrigacao;
  obrigacaoEditar: Obrigacao;
  tipoDocumentoSelecionado: TipoDocumento;
  exbirModalRecorrencia: boolean;
  exbirModalObrigacao: boolean;
  exibirModalEnvio: boolean;
  exibirModalHistoricoEnvio: boolean;
  dtIni: Date;
  qtdeOcorrencia: number;
  aCada: number;
  diaMes: number;
  dtFin: Date;

  file: File;
  files: Array<any>;

  dom: boolean;
  seg: boolean;
  ter: boolean;
  qua: boolean;
  qui: boolean;
  sex: boolean;
  sab: boolean;
  antecipaDiaNaoUtil: boolean;
  verificaPagamento: boolean;

  obrigSelecionada: any;

  btnDisabled: boolean;


  pt: any;

  msgs: Message[];

  uploadedFiles: any[] = [];

  justificativa: string;
  mensagem: string;

  vlPrincipal: number;
  vlMulta: number;
  vlJuros: number;
  vlSaldoRecolher: number;

  vlSaldoCredor: number;

  vlImposto1: number;
  vlImposto2: number;
  vlImposto3: number;
  vlImposto4: number;

  envioExibir: Envio;

  comprovantePagamento: ComprovantePagamento;

  title: string;
  subtitle: string;

  constructor(private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent,
    private messageService: MessageService) {
  }

  ngOnInit() {
    this.title = 'ENVIADOS';
    this.subtitle = 'RELAÇÃO DAS OBRIGAÇÕES ENVIADAS';
    Promise.resolve(null).then(() => this.toolbarRefresh());
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titleService.title = 'CentralWeb';
    this.files = new Array();
    this.obrigSelecionada = '';
    this.justificativa = '';
    this.mensagem = '';
    this.obrigacoes = new Array<Obrigacao>();
    this.envioDestinatariosList = new Array<EnvioDestinatarios>();
    this.empresas = new Array<Empresa>();
    this.obrigacoes = new Array<Obrigacao>();
    this.empresasSelecionadas = new Array<Empresa>();
    this.tipoDocumentoSelecionado = new TipoDocumento();
    this.obrigacao = new Obrigacao();
    this.obrigacao.recorrencia = new Recorrencia();
    this.obrigacao.tipoArquivo = new TipoArquivo();
    this.obrigacao.codigosObrigacoes = new CodigosObrigacoes();
    this.envios = new Array<any>();
    this.envioExibir = new Envio();
    this.envioExibir.usuario = new Usuario();
    this.envioExibir.justificativa = new Justificativas();
    this.verificaPagamento = false;

    this.obrigacao.departamento = new Departamento();
    this.obrigacaoEditar = new Obrigacao();
    this.obrigacaoEditar.codigosObrigacoes = new CodigosObrigacoes();
    this.obrigacaoEditar.recorrencia = new Recorrencia();
    this.obrigacaoEditar.tipoArquivo = new TipoArquivo();
    this.obrigacaoEditar.gatilhoObrigacao = new GatilhoObrigacao();

    this.comprovantePagamento = new ComprovantePagamento();

    this.tipoDocumento = [];
    this.recorrencias = [];
    this.recorrenciasEditar = [];
    this.exbirModalRecorrencia = false;
    this.exbirModalObrigacao = false;
    this.exibirModalEnvio = false;
    this.exibirModalHistoricoEnvio = false;
    this.qtdes = [];
    this.dias = [];
    this.gatilhosEditar = [];
    this.recorrenciasEditar = [];
    this.dtIni = new Date();
    this.dtFin = new Date();
    this.qtdeOcorrencia = 0;
    this.aCada = 1;
    this.diaMes = 1;

    this.dom = false;
    this.seg = false;
    this.ter = false;
    this.qua = false;
    this.qui = false;
    this.sex = false;
    this.sab = false;
    this.antecipaDiaNaoUtil = false;

    this.dias.push({ label: 1, value: 1 });
    this.dias.push({ label: 2, value: 2 });
    this.dias.push({ label: 3, value: 3 });
    this.dias.push({ label: 4, value: 4 });
    this.dias.push({ label: 5, value: 5 });
    this.dias.push({ label: 6, value: 6 });
    this.dias.push({ label: 7, value: 7 });
    this.dias.push({ label: 8, value: 8 });
    this.dias.push({ label: 9, value: 9 });
    this.dias.push({ label: 10, value: 10 });
    this.dias.push({ label: 11, value: 11 });
    this.dias.push({ label: 12, value: 12 });
    this.dias.push({ label: 13, value: 13 });
    this.dias.push({ label: 14, value: 14 });
    this.dias.push({ label: 15, value: 15 });
    this.dias.push({ label: 16, value: 16 });
    this.dias.push({ label: 17, value: 17 });
    this.dias.push({ label: 18, value: 18 });
    this.dias.push({ label: 19, value: 19 });
    this.dias.push({ label: 20, value: 20 });
    this.dias.push({ label: 21, value: 21 });
    this.dias.push({ label: 22, value: 22 });
    this.dias.push({ label: 23, value: 23 });
    this.dias.push({ label: 24, value: 24 });
    this.dias.push({ label: 25, value: 25 });
    this.dias.push({ label: 26, value: 26 });
    this.dias.push({ label: 27, value: 27 });
    this.dias.push({ label: 28, value: 28 });
    this.dias.push({ label: 29, value: 29 });
    this.dias.push({ label: 30, value: 30 });
    this.dias.push({ label: 31, value: 31 });

    this.qtdes.push({ label: 1, value: 1 });
    this.qtdes.push({ label: 2, value: 2 });
    this.qtdes.push({ label: 3, value: 3 });
    this.qtdes.push({ label: 4, value: 4 });
    this.qtdes.push({ label: 5, value: 5 });
    this.qtdes.push({ label: 6, value: 6 });
    this.qtdes.push({ label: 7, value: 7 });
    this.qtdes.push({ label: 8, value: 8 });
    this.qtdes.push({ label: 9, value: 9 });
    this.qtdes.push({ label: 10, value: 10 });

    this.vlPrincipal = 0.00;
    this.vlMulta = 0.00;
    this.vlJuros = 0.00;
    this.vlSaldoRecolher = 0.00;
    this.vlSaldoCredor = 0.00;

    this.vlImposto1 = 0.00;
    this.vlImposto2 = 0.00;
    this.vlImposto3 = 0.00;
    this.vlImposto4 = 0.00;

    this.btnDisabled = true;


    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.periodChanged(); });
  }

  periodChanged(): void {

    this.vlPrincipal = 0.00;
    this.vlMulta = 0.00;
    this.vlJuros = 0.00;
    this.vlSaldoRecolher = 0.00;

    this.comprovantePagamento = new ComprovantePagamento();

    this.exibirModalHistoricoEnvio = false;

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasYearSelected) {
      this.httpService.wait();
      this.httpService.get('/tipoarquivo?$select=id,nome,descricao' +
        '&$orderby=nome')
        .subscribe(ref => this.loadTiposProjeto(ref.value),
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/departamento?$select=id,cod,descricao' +
        '&$orderby=cod')
        .subscribe(ref => this.loadDepartamentos(ref.value),
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/gatilhoobrigacao?$select=id,cod,descricao' +
        '&$orderby=cod')
        .subscribe(ref => this.loadGatilhos(ref.value),
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/empresa?$select=id,cnpj,razaoSocial&$orderby=razaoSocial')
        .subscribe(ref => this.loadEmpresa(ref.value),
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/recorrencia?$select=id,cod,descricao&$orderby=id')
        .subscribe(ref => this.loadRecorrencia(ref.value),
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/centralweb/get-envios', [this.dblinkedSessionService.periodo.year.value,
      this.dblinkedSessionService.periodo.month.value,
      this.dblinkedSessionService.empresa.id,this.sessionService.loggedUser.id ])
        .subscribe(formalizacoes => this.loadEnvios(formalizacoes),
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());
    }
  }

  loadEmpresa(value: Array<Empresa>) {
    this.empresas = value.filter(e => this.empresasSelecionadas.findIndex(es => es.id === e.id) === -1);
  }

  loadRecorrencia(value: Array<any>) {
    this.recorrencias = [];
    this.recorrenciasEditar = [];
    value.forEach(element => {
      this.recorrencias.push({ label: element.cod, value: element });
      if (element.id !== 1) {
        this.recorrenciasEditar.push({ label: element.cod, value: element });
      }
    });
  }

  loadTiposProjeto(value: Array<any>) {
    this.tiposArquivo = new Array();
    value.forEach(element => {
      this.tiposArquivo.push(element);
    });
  }

  loadDepartamentos(value: Array<any>) {
    this.departamentos = new Array();
    value.forEach(element => {
      this.departamentos.push(element);
    });
  }

  loadTipoDocumentos(value: Array<any>) {
    this.tipoDocumento = [];
    value.forEach(element => {
      this.tipoDocumento.push({ label: element.cod, value: element });
    });
  }

  loadGatilhos(value: Array<any>) {
    this.gatilhos = new Array();
    this.gatilhosEditar = [];
    value.forEach(element => {
      this.gatilhos.push(element);

      this.gatilhosEditar.push({ label: element.descricao, value: element });
    });
  }

  carregarObrigacao(id: any) {
    this.httpService.wait();
    this.httpService.get('/codigosobrigacoes?$select=id,cod,descricao' +
      '&$filter=tipoArquivo/id eq ' + id.value.id +
      '&$orderby=cod')
      .subscribe(ref => this.loadCodigosObrigacao(ref.value),
      error => this.httpService.handleError(error, () => this.carregarObrigacao(id)),
      () => this.httpService.done());
  }

  modalRecorrencia() {
    this.exbirModalRecorrencia = true;
  }

  criarObrigacao() {
    const listasEnviar = {
      obrigacao: this.obrigacao,
      recorrencia: this.obrigacao.recorrencia.id,
      tipoArquivo: this.obrigacao.tipoArquivo.id,
      codigosObrigacoes: this.obrigacao.codigosObrigacoes.id,
      departamento: this.obrigacao.departamento.id,
      usuario: this.dblinkedSessionService.usuario.id,
      dtIni: this.dtIni,
      dtFin: this.dtFin,
      aCada: this.aCada,
      dom: this.dom,
      seg: this.seg,
      ter: this.ter,
      qua: this.qua,
      qui: this.qui,
      sex: this.sex,
      sab: this.sab,
      qtdeOcorrencia: this.qtdeOcorrencia,
      diaMes: this.diaMes,
      antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
      empresas: this.empresasSelecionadas,
      gatilhoObrigacao: this.obrigacao.gatilhoObrigacao.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/cadastrar-obrigacao', listasEnviar)
      .subscribe(result => this.periodChanged(),
      error => this.httpService.handleError(error, () => this.criarObrigacao()),
      () => this.httpService.done());
  }

  atualizarRecorrencia() {
    if (confirm('Todas as recorrências seguintes desta obrigação serão alteradas, deseja continuar?')) {
      const listasEnviar = {
        obrigacao: this.obrigacaoEditar,
        recorrencia: this.obrigacaoEditar.recorrencia.id,
        tipoArquivo: this.obrigacaoEditar.tipoArquivo.id,
        codigosObrigacoes: this.obrigacaoEditar.codigosObrigacoes.id,
        departamento: this.obrigacaoEditar.departamento.id,
        usuario: this.dblinkedSessionService.usuario.id,
        dtIni: this.dtIni,
        dtFin: this.dtFin,
        aCada: this.aCada,
        dom: this.dom,
        seg: this.seg,
        ter: this.ter,
        qua: this.qua,
        qui: this.qui,
        sex: this.sex,
        sab: this.sab,
        qtdeOcorrencia: this.qtdeOcorrencia,
        diaMes: this.diaMes,
        antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
        empresas: this.empresasSelecionadas,
        gatilhoObrigacao: this.obrigacaoEditar.gatilhoObrigacao.id,
        obrigacaoAnterior: this.obrigacaoEditar.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/centralweb/atualizar-recorrencias', listasEnviar)
        .subscribe(result => this.tabChange(1),
        error => this.httpService.handleError(error, () => this.criarObrigacao()),
        () => this.httpService.done());
    }
  }

  atualizarObrigacao() {
    const listasEnviar = {
      vencimento: this.dtIni,
      id: this.obrigacaoEditar.id,
      gatilho: this.obrigacaoEditar.gatilhoObrigacao.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/atualizar-obrigacao-unico', listasEnviar)
      .subscribe(result => this.tabChange(1),
      error => this.httpService.handleError(error, () => this.criarObrigacao()),
      () => this.httpService.done());
  }

  loadCodigosObrigacao(value: Array<any>) {
    this.codigosObrigacao = new Array();
    value.forEach(element => {
      this.codigosObrigacao.push(element);
    });
  }

  ngOnDestroy() {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  tabChange(event: any) {
    this.exbirModalObrigacao = false;
    this.exibirModalEnvio = false;

    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-envios', [this.dblinkedSessionService.periodo.year.value,
    this.dblinkedSessionService.periodo.month.value,
    this.dblinkedSessionService.empresa.id])
      .subscribe(formalizacoes => this.loadEnvios(formalizacoes),
      error => this.httpService.handleError(error, () => this.tabChange(2)),
      () => this.httpService.done());
  }

  loadEnvios(value: Array<any>) {
    this.envios = new Array();
    value.forEach(element => {
      this.envios.push(element);
    });
  }

  onRowSelect(event: any) {
    this.obrigacaoEditar = event.data;

    this.exbirModalObrigacao = true;
  }

  onRowSelectEnvio(event: any) {
    this.httpService.wait();
    this.httpService.get('/envio?$select=id,dtEnvio,usuario/id,usuario/nome,usuario/sobrenome,vlPrincipal,vlMulta,vlJuros,' +
      'vlSaldoRecolher,vlSaldoCredor,vlImposto1,vlImposto2,vlImposto3,vlImposto4,tipoDocumento/cod,' +
      'justificativa/descricao,detalhesJustificativa,mensagem,obrigacao/codigosObrigacoes/cod,' +
      'obrigacao/tipoArquivo/id,outrosDestinatarios,obrigacao/codigosObrigacoes/id&$filter=id eq ' + event.data.envioId)
      .subscribe(ref => this.loadEnvio(ref.value),
      error => this.httpService.handleError(error, () => this.onRowSelectEnvio(event)),
      () => this.httpService.done());

  }

  loadConfirmacao(value: any) {
    this.envioDestinatariosList = value;
  }

  loadVerificacaoPagamento(value: Array<any>) {

    if (value[0] === undefined) {

      this.verificaPagamento = true;

    } else {

      this.verificaPagamento = false;

    }



  }

  loadEnvio(value: any) {
    this.exibirModalHistoricoEnvio = true;
    this.envioExibir = value[0];

    const data: FormData = new FormData();
    data.append('envioId', this.envioExibir.id.toString());


    this.httpService.wait();
    this.httpService.post('/custom/centralweb/get-files-to-send-comp', data)
      .subscribe(formalizacoes => this.loadFiles(formalizacoes),
      error => this.httpService.handleError(error, () => this.tabChange(2)),
      () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/comprovantepagamento?$select=id,dtConfirmacao,vlPrincipal,vlMulta,vlJuros,vlSaldoRecolher,envio/id,usuario/id' +
                         '&$filter=envio/id eq ' + this.envioExibir.id)
      .subscribe(ref => this.loadComprovantePagamento(ref.value),
      error => this.httpService.handleError(error, () => this.loadEnvio(value)),
      () => this.httpService.done());


    this.httpService.wait();
    this.httpService.get('/tributospagamentorelac?$select=id' +
                         '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
                                 ' and codigosObrigacoes/id eq ' + this.envioExibir.obrigacao.codigosObrigacoes.id)
      .subscribe(ref => this.loadVerificacaoPagamento(ref.value),
      error => this.httpService.handleError(error, () => this.loadEnvio(value)),
      () => this.httpService.done());
    this.httpService.wait();
    this.httpService.get('/enviodestinatarios?$select=id,mail,dtHrConfirmacao&$filter=envio/id eq ' + this.envioExibir.id)
      .subscribe(ref => this.loadConfirmacao(ref.value),
      error => this.httpService.handleError(error, () => this.loadEnvio(value)),
      () => this.httpService.done());


  }

  loadComprovantePagamento(event: ComprovantePagamento) {

    this.comprovantePagamento = event[0];

    if (this.comprovantePagamento !== undefined) {

      this.vlPrincipal = this.comprovantePagamento.vlPrincipal;
      this.vlMulta = this.comprovantePagamento.vlMulta;
      this.vlJuros = this.comprovantePagamento.vlJuros;
      this.vlSaldoRecolher = this.comprovantePagamento.vlSaldoRecolher;

    }


  }

  deletarObrigacao(id: number) {
    if (confirm('Deseja realmente remover esta obrigação?')) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/deletarobrigacao(' + id + ')')
        .subscribe(() => this.tabChange(1),
        error => this.httpService.handleError(error, () => this.deletarObrigacao(id)),
        () => this.httpService.done());
    }
  }

  deletarObrigacoes(id: number) {
    if (confirm('Deseja realmente remover esta obrigação e todas as seguintes?')) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/deletarobrigacoes(' + id + ')')
        .subscribe(() => this.tabChange(1),
        error => this.httpService.handleError(error, () => this.deletarObrigacao(id)),
        () => this.httpService.done());
    }
  }

  alertObrig(event: any) {
    this.httpService.wait();
    this.httpService.get('/tipodocumento?$select=id,cod,descricao' +
      '&$orderby=cod')
      .subscribe(ref => this.loadTipoDocumentos(ref.value),
      error => this.httpService.handleError(error, () => this.alertObrig(event)),
      () => this.httpService.done());

    this.obrigSelecionada = event;
    this.exibirModalEnvio = true;
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
  }

  importar() {
    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('type', 'cw-comp');
    data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
    data.append('envioId', this.envioExibir.id.toString());

    this.httpService.wait();
    this.httpService.post('/custom/upload', data)
      .subscribe(result => this.uploaded(result),
      error => this.httpService.handleError(error, () => this.importar()),
      () => this.httpService.done());
  }

  private uploaded(result?: any) {

    const data: FormData = new FormData();
    data.append('envioId', this.envioExibir.id.toString());

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/get-files-to-send-comp', data)
      .subscribe(formalizacoes => this.loadFiles(formalizacoes),
      error => this.httpService.handleError(error, () => this.tabChange(2)),
      () => this.httpService.done());

    /*this.messageService.add({
       severity: result.error ? 'error' : 'success',
       detail: result.message || 'Upload efetuado com sucesso!'
     }];*/
  }

  private sent(result?: any) {

    this.messageService.add({
      severity: result.error ? 'error' : 'success',
      detail: result.message || 'Obrigação Enviada com Sucesso!'
    });
  }

  calcSaldoRecolher() {
    this.vlSaldoRecolher = this.utilService.parseDecimal(this.vlPrincipal) +
      this.utilService.parseDecimal(this.vlMulta) + this.utilService.parseDecimal(this.vlJuros);
  }

  cancelarEnvio() {
    this.exbirModalObrigacao = false;
    this.exibirModalEnvio = false;
    this.mensagem = '';
    this.justificativa = '';
    this.files = new Array();
    this.tipoDocumentoSelecionado = new TipoDocumento();
    this.vlPrincipal = 0.00;
    this.vlMulta = 0.00;
    this.vlJuros = 0.00;
    this.vlSaldoRecolher = 0.00;
    this.vlSaldoCredor = 0.00;

    this.vlImposto1 = 0.00;
    this.vlImposto2 = 0.00;
    this.vlImposto3 = 0.00;
    this.vlImposto4 = 0.00;
  }

  loadFiles(event: any) {
    this.files = event;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {
    this.file = null;

  }


  export(event: any) {
    this.httpService.wait();
    this.httpService.post('/custom/centralweb/export-files(' + event.envioId + ')')
      .subscribe(result => this.download(result),
      error => this.httpService.handleError(error, () => this.export(event)),
      () => this.httpService.done());
  }



  download(result: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/' + result.envioId + '/cw'), '_blank');
    }
  }

  salvarPagamento() {
    const data: FormData = new FormData();
    data.append('envioId', this.envioExibir.id.toString());
    data.append('vlPrincipal', this.utilService.parseDecimal(this.vlPrincipal).toString());
    data.append('vlMulta', this.utilService.parseDecimal(this.vlMulta).toString());
    data.append('vlJuros', this.utilService.parseDecimal(this.vlJuros).toString());
    data.append('vlSaldoRecolher', this.utilService.parseDecimal(this.vlSaldoRecolher).toString());
    data.append('usuarioId', this.dblinkedSessionService.usuario.id.toString());


    this.httpService.wait();
    this.httpService.post('/custom/centralweb/salvar-comprovante-pagamento', data)
      .subscribe(result => this.periodChanged(),
      error => this.httpService.handleError(error, () => this.salvarPagamento),
      () => this.httpService.done());

  }

  atualizarPagamento() {

    this.comprovantePagamento.vlPrincipal = this.vlPrincipal;
    this.comprovantePagamento.vlMulta = this.vlMulta;
    this.comprovantePagamento.vlJuros = this.vlJuros;
    this.comprovantePagamento.vlSaldoRecolher = this.vlSaldoRecolher;

    this.httpService.wait();
    this.httpService.put('/comprovantepagamento', this.comprovantePagamento.id, this.comprovantePagamento)
      .subscribe(() => this.periodChanged(),
      error => this.httpService.handleError(error, () => this.atualizarPagamento()),
      () => this.httpService.done());
  }

  excluirComprovantePagamento() {

    if (confirm('Deseja excluir o comprovante e seus anexos?')) {

      this.httpService.wait();
      this.httpService.delete('/comprovantepagamento', this.comprovantePagamento.id.toString())
        .subscribe(() => this.periodChanged(),
        error => this.httpService.handleError(error, () => this.excluirComprovantePagamento()),
        () => this.httpService.done());

    }
  }
  private toolbarRefresh() {
    this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-other-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-first-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }

  onFilter(event: any){

  }

}
