export class TipoUtilizacao {
  id: number;
  cod: any;
  descr: string;

  constructor(tipoUtilizacao?: TipoUtilizacao | number) {
    if (tipoUtilizacao !== undefined && tipoUtilizacao !== null) {
      if (typeof tipoUtilizacao === 'number') {
        this.id = tipoUtilizacao;
      } else {
        this.id = tipoUtilizacao.id;
        this.cod = tipoUtilizacao.cod;
        this.descr = tipoUtilizacao.descr;
      }
    }
  }
}
