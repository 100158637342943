import { ImpostoRegraFiscal } from 'app/cadastro/model/imposto-regra-fiscal.model';
import { OperacaoRegraFiscal } from 'app/cadastro/model/operacao-regra-fiscal.model';
import { CstIpi } from 'app/util/model/cst-ipi.model';
import { OperacaoRegraFiscalDetalheCfopRelac } from 'app/cadastro/model/operacao-regra-fiscal-detalhe-cfop-relac.model';
import { CstPiscofins } from 'app/util/model/cst-piscofins.model';
import { RegimeIncidencia } from 'app/util/model/regime-incidencia.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { TipoArquivo } from '../../util/model/tipo-arquivo.model';

export class OperacaoRegraFiscalDetalhe {
  id: number;
  empresa: Empresa;
  operacaoRegraFiscal: OperacaoRegraFiscal;
  impostoRegraFiscal: ImpostoRegraFiscal;
  cstIpi: CstIpi;
  cstPisCofins: CstPiscofins;
  lucroReal: boolean;
  lucroPresumido: boolean;
  simples: boolean;
  naoContribuinte: boolean;
  inscritoMunicipio: boolean;
  regimeIncidencia: RegimeIncidencia;
  somaIpiBc: boolean;
  csosn: String;
  operacaoRegraFiscalDetalheCfopRelacList: Array<OperacaoRegraFiscalDetalheCfopRelac>;
  codEnqIpiId: number;
  modBcIcmsId: number;


  constructor(operacaoRegraFiscalDetalhe?: OperacaoRegraFiscalDetalhe | number) {
    if (operacaoRegraFiscalDetalhe !== undefined && operacaoRegraFiscalDetalhe !== null) {
      if (typeof operacaoRegraFiscalDetalhe === 'number') {
        this.id = operacaoRegraFiscalDetalhe;
      } else {
        this.id = operacaoRegraFiscalDetalhe.id;
        this.empresa = operacaoRegraFiscalDetalhe.empresa;
        this.impostoRegraFiscal = operacaoRegraFiscalDetalhe.impostoRegraFiscal;
        this.cstIpi = operacaoRegraFiscalDetalhe.cstIpi;
        this.cstPisCofins = operacaoRegraFiscalDetalhe.cstPisCofins;
        this.lucroReal = operacaoRegraFiscalDetalhe.lucroReal;
        this.lucroPresumido = operacaoRegraFiscalDetalhe.lucroPresumido;
        this.simples = operacaoRegraFiscalDetalhe.simples;
        this.naoContribuinte = operacaoRegraFiscalDetalhe.naoContribuinte;
        this.inscritoMunicipio = operacaoRegraFiscalDetalhe.inscritoMunicipio;
        this.regimeIncidencia = operacaoRegraFiscalDetalhe.regimeIncidencia;
        this.somaIpiBc = operacaoRegraFiscalDetalhe.somaIpiBc;
        this.operacaoRegraFiscalDetalheCfopRelacList = operacaoRegraFiscalDetalhe.operacaoRegraFiscalDetalheCfopRelacList;
        this.csosn = operacaoRegraFiscalDetalhe.csosn;
        this.codEnqIpiId = operacaoRegraFiscalDetalhe.codEnqIpiId;
        this.modBcIcmsId = operacaoRegraFiscalDetalhe.modBcIcmsId;
      }
    }
  }
}
