import { SelectItem } from "primeng/api";

export enum LogModule {
  ACCOUNTING = 'contabil',
  REGISTRATION = 'cadastro',
  FINANCE = 'financeiro',
  FISCAL = 'fiscal',
  PURCHASES = 'compras',
  SALES = 'vendas',
  STOCK = 'estoque'
}

export enum LogAction {
  CREATE = 'criação',
  EDIT = 'edição',
  DELETE = 'deleção'
}

export enum LogScreen {
  ACCOUNT_ENTRY = 'lancamento',
  ACCOUNT_PLAN = 'plano-contas',
  ACC_RULE_OPERATION = 'operacao-regra-contabil',
  FISCAL_RULE_OPERATION = 'operacao-regra-fiscal',
  INVOICES = 'notas-fiscais',
  MOVEMENT = 'movimentacao',
  OPERATION_RULE = 'operacao-regra',
  PARTICIPANTS = 'clientes-fornecedores',
  PAYMENTS = 'pagamentos',
  PRODUCT_PURCHASES_ORDER = 'pedido-compra-produto',
  PRODUCT_SALES_ORDER = 'pedido-venda-produto',
  PRODUCTION_ORDER = 'ordem-producao',
  PRODUCTS_AND_SERVICES = 'produtos-servicos',
  RECEIPTS = 'recebimentos',
  SERVICE_PURCHASES_ORDER = 'pedido-compra-servico',
  SERVICE_SALES_ORDER = 'pedido-venda-servico',
  STOCK_MOVEMENT = 'movimentacao-interna',
  TAX_BOOK_EDIT = 'edit-livro'
}

export const allOptionsLogModule: Array<SelectItem> = [
  { label: 'Cadastro', value: LogModule.REGISTRATION },
  { label: 'Compras', value: LogModule.PURCHASES },
  { label: 'Contabilidade', value: LogModule.ACCOUNTING },
  { label: 'Estoque', value: LogModule.STOCK },
  { label: 'Financeiro', value: LogModule.FINANCE },
  { label: 'Fiscal', value: LogModule.FISCAL },
  { label: 'Vendas', value: LogModule.SALES }
]

export const allOptionsLogAction: Array<SelectItem> = [
  { label: 'Criação', value: LogAction.CREATE },
  { label: 'Edição', value: LogAction.EDIT },
  { label: 'Exclusão', value: LogAction.DELETE }
]

export const allOptionsLogScreen: Array<any> = [
  { label: 'Clientes e Fornecedores', value: LogScreen.PARTICIPANTS, module: LogModule.REGISTRATION },
  { label: 'Edição Livro Fiscal', value: LogScreen.TAX_BOOK_EDIT, module: LogModule.FISCAL },
  { label: 'Lançamentos', value: LogScreen.ACCOUNT_ENTRY, module: LogModule.ACCOUNTING },
  { label: 'Movimentação', value: LogScreen.MOVEMENT, module: LogModule.FINANCE },
  { label: 'Movimentação Interna', value: LogScreen.STOCK_MOVEMENT, module: LogModule.STOCK },
  { label: 'Ordem de Produção', value: LogScreen.PRODUCTION_ORDER, module: LogModule.STOCK },
  { label: 'Notas fiscais', value: LogScreen.INVOICES, module: LogModule.PURCHASES },
  { label: 'Notas fiscais', value: LogScreen.INVOICES, module: LogModule.SALES },
  { label: 'Pagamentos', value: LogScreen.PAYMENTS, module: LogModule.FINANCE },
  { label: 'Pedido de venda de produto', value: LogScreen.PRODUCT_SALES_ORDER, module: LogModule.SALES },
  { label: 'Pedido de venda de serviço', value: LogScreen.SERVICE_SALES_ORDER, module: LogModule.SALES },
  { label: 'Pedido de compra de produto', value: LogScreen.PRODUCT_PURCHASES_ORDER, module: LogModule.PURCHASES },
  { label: 'Pedido de compra de serviço', value: LogScreen.SERVICE_PURCHASES_ORDER, module: LogModule.PURCHASES },
  { label: 'Plano de contas', value: LogScreen.ACCOUNT_PLAN, module: LogModule.REGISTRATION },
  { label: 'Produtos e serviços', value: LogScreen.PRODUCTS_AND_SERVICES, module: LogModule.REGISTRATION },
  { label: 'Recebimentos', value: LogScreen.RECEIPTS, module: LogModule.FINANCE },
  { label: 'Regra operação', value: LogScreen.OPERATION_RULE, module: LogModule.REGISTRATION },
  { label: 'Regra operação contábil', value: LogScreen.ACC_RULE_OPERATION, module: LogModule.REGISTRATION },
  { label: 'Regra operação fiscal', value: LogScreen.FISCAL_RULE_OPERATION, module: LogModule.REGISTRATION }
]

