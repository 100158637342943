import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs/Subscription'
import { CrudService } from 'app/shared/services/crud.service'
import { HttpService } from 'app/shared/services/http.service'
import { SessionService } from 'app/shared/services/session.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { Message, MessageService, SelectItem } from 'primeng/api'
import { ConfigService } from 'app/shared/services/config.service'
import { trigger, state, style, animate, transition } from '@angular/animations'
import { Toolbar } from 'app/shared/model/toolbar.model'
import { Table } from 'primeng/table'

@Component({
  selector: 'app-cad-encargos',
  templateUrl: './cad-encargos.component.html',
  styleUrls: ['./cad-encargos.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadEncargosComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription
  @ViewChild('dt') dt: Table

  //String
  currentStateBenef: string = 'initial'
  descricaoEdit: string = null
  descricaoNew: string = null
  subtitle: string = 'Cadastro de encargos e provisões'
  title: string = 'ENCARGOS E PROVISÕES'

  //Boolean
  fullBenef: boolean = false
  isCardBenef: boolean = true
  isPorcentEdit: boolean = true
  isPorcentNew: boolean = true
  modalEdit: boolean = false
  modalNew: boolean = false

  //Toolbar
  public toolbarMain = new Toolbar()
  toolbarMainIni = [
    { key: 'export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.dt.exportCSV() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.edit() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.deleteEncargo() },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddEncargo() },
    {
      key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.showHideCardBenef()
        this.changeStateBenef()
      }
    },
  ]

  //Array
  benefSelecionados: Array<any> = new Array()
  colsEncargos: Array<any> = [
    { field: 'codigo', header: 'CÓDIGO' },
    { field: 'descricao_contrato', header: 'DESCRIÇÃO CONTRATO' },
    { field: 'descricao_encargo', header: 'ENCARGO' },
    { field: 'valor_porcent', header: 'PORCENTAGEM' },
    { field: 'valor', header: 'VALOR FIXO' }
  ]
  encargoLista: Array<any> = new Array()
  provSelecionados: Array<any> = new Array()
  salSelecionados: Array<any> = new Array()

  //Dropdown e multiselect
  benefList: SelectItem[] = []
  provList: SelectItem[] = []
  salList: SelectItem[] = []
  tipoCargoList: SelectItem[] = []

  //Number
  encargoIdEdit: number = null
  tipoCargoIdNew: number = null
  valorEdit: number = null
  valorNew: number = null
  valorPorcentEdit: number = null
  valorPorcentNew: number = null

  constructor(private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    this.toolbarService.clear()
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.getEncargos()
          this.getTipoCargo()
          this.getBeneficios()
          this.getSalRec()
          this.toolbarInitial()
        }
      })
  }

  ngOnDestroy() {
    this.toolbarService.clear()
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  toolbarInitial() {
    this.toolbarMain.setVisible('export', true)
    this.toolbarMain.setVisible('expand', true)
    this.toolbarMain.setVisible('add', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
  }


  getSalRec() {
    this.salList = []
    this.salList.push({ label: "Nenhum", value: "" })
    this.salList.push({ label: "Salário base", value: "SALARIO" })
    this.salList.push({ label: "Rescisão", value: "RESCISAO" })
  }

  getBeneficios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    }

    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/beneficios', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"]
          this.benefList = []
          for (let line of result) {
            this.benefList.push({ "label": line["codigo"] + " - " + line["descricao"], "value": line["id"] })
          }
        },
        err => {this.exibirMensagem({error: true, mensagem: "Erro no APi ao obter os benefícios, contatar o suporte: " + err.message})}
      );
  }

  getTipoCargo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    }

    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/tipo-cargo', httpOptions)
      .subscribe(
        res => {
          let result = res["data"]
          this.tipoCargoList = []
          for (let line of result) {
            this.tipoCargoList.push({ "label": line["codigo"] + " - " + line["descricao"], "value": line["id"] })
          }
        },
        err => { this.exibirMensagem({error: true, mensagem: "Erro no APi ao obter os tipos de cargo, contatar o suporte: " + err.message}) }
      );
  }

  addEncargo() {
    if (this.tipoCargoIdNew === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Contrato' é obrigatório." })

    } else if (this.descricaoNew === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Descrição' é obrigatório." })

    } else if ((this.valorPorcentNew === null && this.isPorcentNew) || (this.valorNew === null && !this.isPorcentNew)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Valor' é obrigatório." })

    } else if (this.benefSelecionados.length === 0 && this.salSelecionados.length === 0 && this.provSelecionados.length === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "Selecione pelo menos um item para calcular o encargo." })

    } else {
      let valor: number;
      if (this.isPorcentNew) {
        valor = this.valorPorcentNew

      } else {
        valor = this.valorNew
      }

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'tipocargoid': this.tipoCargoIdNew.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'descricao': this.descricaoNew.toString(),
          'isporcent': this.isPorcentNew.toString(),
          'valor': valor.toString(),
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'beneflist': this.benefSelecionados.toString(),
          'sallist': this.salSelecionados.toString(),
          'provlist': this.provSelecionados.toString()
        })
      }

      this.httpService.wait()
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/encargos', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res)
            this.getEncargos()
            this.httpService.done()
            this.modalNew = false
            this.toolbarInitial()
          },
          err => {
            this.exibirMensagem({error: true, mensagem: "Erro no API ao salvar o encargo, contatar o suporte: " + err.message})
            this.httpService.done()
          }
        )
    }
  }

  getEncargos() {
    const httpOptions = { headers: new HttpHeaders({'Authorization': this.configService.bearerApi}) }
    this.httpService.wait()
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/encargos?empresaId=' + this.dblinkedSessionService.empresa.id, httpOptions)
      .subscribe(
        res => {
          this.encargoLista = new Array()
          this.provList = []
          res["data"].forEach(row => {
            this.encargoLista.push(row)
            if (row["isprovferias"] || row["isprov13sal"]) {
              this.provList.push({ "label": row["codigo"] + " - " + row["descricao_encargo"], "value": row["id"] })
            }
          })
          this.httpService.done()
        },
        err => {
          this.exibirMensagem({error: true, mensagem: "Erro no API ao obter os encargos, contatar o suporte: " + err.message})
          this.httpService.done()
        }
      );
  }

  openAddEncargo() {
    this.modalNew = true
    this.isPorcentNew = true
    this.valorPorcentNew = null
    this.valorNew = null
    this.descricaoNew = null
    this.tipoCargoIdNew = null
    this.benefSelecionados = new Array()
    this.provSelecionados = new Array()
    this.salSelecionados = new Array()
  }

  onRowSelect(row) {
    this.descricaoEdit = row.descricao_encargo
    this.encargoIdEdit = row.id
    this.isPorcentEdit = row.is_porcent
    this.valorEdit = row.valor
    this.valorPorcentEdit = row.valor_porcent
    this.benefSelecionados = new Array()
    this.benefSelecionados = row.libenef
    this.salSelecionados = new Array()
    this.salSelecionados = row.lidiversos
    this.provSelecionados = new Array()
    this.provSelecionados = row.liprov
    row.selected = false
    this.toolbarMain.setVisible('edit', true)
    this.toolbarMain.setVisible('delete', true)
  }

  onRowUnselect(row) {
    row.selected = true
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
  }

  edit() {
    this.modalEdit = true
  }

  deleteEncargo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'encargoid': this.encargoIdEdit.toString()
      })
    }

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/encargos', httpOptions)
      .subscribe(
        res => {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Não é possível excluir esta vinculação' })
          this.httpService.done()
          this.modalEdit = false
          this.getEncargos()
          this.toolbarInitial()
        },
        err => {
          this.exibirMensagem({error: true, mensagem: "Erro no API ao excluir o encargo, contatar o suporte: " + err.message})
          console.log(err)
          this.httpService.done()
        }
      )
  }

  editEncargo() {
    if (this.descricaoEdit === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Descrição' é obrigatório." })

    } else if ((this.valorPorcentEdit === null && this.isPorcentEdit) || (this.valorEdit === null && !this.isPorcentEdit)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Valor' é obrigatório." })

    } else if (this.benefSelecionados.length === 0 && this.salSelecionados.length === 0 && this.provSelecionados.length === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "Selecione pelo menos um item para calcular o encargo." })

    } else {
      let valor: number
      if (this.isPorcentEdit) {
        valor = this.valorPorcentEdit

      } else {
        valor = this.valorEdit
      }

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'encargoid': this.encargoIdEdit.toString(),
          'descricao': this.descricaoEdit.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'isporcent': this.isPorcentEdit.toString(),
          'valor': valor.toString(),
          'beneflist': this.benefSelecionados.toString(),
          'sallist': this.salSelecionados.toString(),
          'provlist': this.provSelecionados.toString()
        })
      }

      this.httpService.wait()
      this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/encargos', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res)
            this.httpService.done()
            this.modalEdit = false
            this.getEncargos()
            this.toolbarInitial()
          },
          err => {
            this.exibirMensagem({error: true, mensagem: "Erro no API ao atualizar o encargo, contatar o suporte: " + err.message})
            this.httpService.done()
          }
        );
    }
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem })

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem })
    }
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks
  }

  get rows() {
    return 50
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage
  }

  showHideCardBenef() {
    this.isCardBenef = !this.isCardBenef
    this.toolbarMain.setIcon('expand', this.isCardBenef ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('expand', this.isCardBenef ? 'Ver menos' : 'Ver mais')
  }

  changeStateBenef() {
    this.currentStateBenef = this.currentStateBenef === 'initial' ? 'final' : 'initial'
  }
}
