import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ParticipanteEmpresa} from 'app/cadastro/model/participante-empresa.model';
import { Empresa } from 'app/cadastro/model/empresa.model';

@Component({
  selector: 'app-participante',
  templateUrl: './participante.component.html',
  styleUrls: ['./participante.component.scss'],
  providers: [CrudService]
})
export class ParticipanteComponent extends DataTableBasicComponent
  implements OnInit, OnDestroy {

  public participanteEmpresa: ParticipanteEmpresa = new ParticipanteEmpresa();

  private empresaSubscription: Subscription;

  entityFilter: string = null;

  constructor(
    private crudService: CrudService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService
  ) {
    super(crudService);
  }

  ngOnInit() {
    this.empresaSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.empresaChanged,
      () => {
        this.empresaChanged();
      }
    );
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.entityFilter = 'empresa/id eq ' + this.dblinkedSessionService.empresa.id;

      this.columns = [
        new DataTableColumn('CÓDIGO', 'codigo')/*,
        new DataTableColumn('CNPJ', 'participante.cnpj'),
        new DataTableColumn('RAZÃO SOCIAL', 'razao'),
        new DataTableColumn('NOME FANTASIA', 'fantasia'),

        new DataTableColumn('CPF', 'cpf'),
        new DataTableColumn('CIDADE', 'municipioIbge.nome')*/
      ];

      this.controls = [
        new DataControl('CÓDIGO', 'codigo')
          .setTypeText()
          .setMinLength(0)
          .setMaxLength(100)/*,
          new DataControl('CNPJ', 'participante.cnpj')
          .setTypeText()
          .setMinLength(14)
          .setMaxLength(14),
        new DataControl('Fantasia', 'fantasia')
          .setTypeText()
          .setMinLength(0)
          .setMaxLength(50),

        new DataControl('CPF', 'cpf')
          .setTypeText()
          .setMinLength(0)
          .setMaxLength(11),
        new DataControl('Data Ativação', 'dtInicial')
          .setTypeDate(),
        new DataControl('Data Desativação', 'dtFinal')
          .setTypeDate()*/
        ];
    }
  }
}
