
import { Pedido } from 'app/financeiro/model/pedido.model';
import { OperacaoRegra } from 'app/cadastro/model/operacao-regra.model';
import { Item } from 'app/cadastro/model/item.model';
import { ItemPrecoVenda } from 'app/cadastro/model/item-preco-venda.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { TipoDesconto } from 'app/util/model/tipo-desconto.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadProj } from 'app/contabil/model/cad-proj.model';
import { CstIpi } from '../../util/model/cst-ipi.model';
import { CstIcms } from '../../util/model/cst-icms.model';
import { CstPiscofins } from '../../util/model/cst-piscofins.model';
import { CstIcmsTabelaB } from '../../util/model/cst-icms-tabela-b.model';
import { CstIcmsTabelaA } from '../../util/model/cst-icms-tabela-a.model';
import { PedidoItemRateio } from './pedido-item-rateio.model';
import { Uf } from '../../util/model/uf.model';
import { TransporteTipo } from '../../util/model/tipo-transporte-model';
import { ImportacaoAdicao } from './pedido-importacao.model';
import { PedidoExportacao } from './pedido-exportacao.model';
import { TipoIntermediacao } from '../../util/model/tipo-intermediacao-model';
import { UnidadeMedida } from 'app/util/model/unidade-medida.model';
import { NfeItem } from './nfe-item.model';
import { EmpresaAlmoxarifado } from 'app/cadastro/model/empresa-almoxarifado.model';

export class PedidoItem {
  id:                     number;
  pedido:                 Pedido;
  item:                   Item;
  itemFornecedor:         string;
  quantidade:             number;
  cfop:                   string;
  cest:                   string;
  ncm:                    string;
  valorUnit:              any;
  tipoDesconto:           TipoDesconto;
  valorDesconto:          any;
  valorFrete:             any;
  valorSeguro:            any;
  valorOutro:             any;
  valorTotal:             any;
  valorTotalProduto:      any;
  // ipi
  cstIpi:                 CstIpi;
  classeIpi:              string;
  codigoIpi:              string;
  valorBcIpi:             any;
  aliquotaIpi:            any;
  valorIpi:               any;
  // icms
  cstIcmsTabelaB:         CstIcmsTabelaB;
  cstIcmsTabelaA:         CstIcmsTabelaA;
  modBcIcms:              any;
  percRedBcIcms:          any;
  valorBcIcms:            any;
  aliquotaIcms:           any;
  valorIcms:              any;
  valorBcIcmsSt:          any;
  aliquotaIcmsSt:         any;
  modBcIcmsSt:            any;
  valorIcmsSt:            any;
  csosn:                  any;
  aliqCredSn:             number
  valorCredSn:            number
  // pis
  cstPis:                 CstPiscofins;
  valorBcPis:             any;
  vlrAliquotaPis:         any;
  perAliquotaPis:         any;
  qtdVendaPis:            any;
  valorPis:               any;
  // cofins
  cstCofins:              CstPiscofins;
  valorBcCofins:          any;
  vlrAliquotaCofins:      any;
  perAliquotaCofins:      any;
  qtdVendaCofins:         any;
  valorCofins:            any;
  // FCP
  valorBCIcmsFcpUfDest:         any;
  percBaseCalculoIcmsFcpUfDest: any;
  aliquotaInternaUfTermino:     any;
  aliquotaInterestadual:        any;
  percentualPartilhaUfTermino:  any;
  valorIcmsPartilhaUfInicio:    any;
  valorIcmsPartilhaUfTermino:   any;
  aliquotaFcpUfTermino:         any;
  valorFcpUfTermino:            any;
  // IPI
  percentualMercadoriaDevolvida: any;
  valorIpiDevolvido:             any;
  // importacao
  valorBcImport:                 any;
  valorDespImport:               any;
  valorIofImport:                any;
  valorImpostoImport:            any;
  numeroDi:                      any;
  dataRegistroImport: Date;
  codigoExportador: any;
  // desenbaraço aduana
  ufDesembaraco: Uf;
  dataDesembaraco: Date;
  localDesembaraco: any;
  transporteTipo: TransporteTipo;
  tipoIntermediacao: TipoIntermediacao;
  valorAFRMM: number;
  cnpjAdquirente: any;
  ufAdquirente: Uf;
  pedidoItensImportAdicao: Array<ImportacaoAdicao> = new Array(); // ImportacaoAdicao;


  // DIFAL
  valorOperSemIcmsDifal:         any;
  aliqInternaDifal:              any;
  aliqInterestadualDifal:        any;
  valorOperComIcmsDifal:         any;
  icmsOrigemCreditoDifal:        any;
  icmsDestinoDebitoDifal:        any;
  valorRecolherDifal:            any;

  // Exportação
  numeroAto: any;
  pedidoItensExportacao: Array<PedidoExportacao> = new Array(); // PedidoExportacao;

  dadosAdicionais: string;

  operacaoRegra: OperacaoRegra;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;

  numSequence: number;

  pedidoItensRateio: Array<PedidoItemRateio> = new Array();

  unidadeMedida: UnidadeMedida;
  unidadeMedidaTributavel: UnidadeMedida;
  quantidadeTributavel: number;
  valorUnitTributavel: any;

  itemFornecedorReferenciado: NfeItem;

  empresaAlmoxarifado: EmpresaAlmoxarifado;

  almoxarifadoDestino: EmpresaAlmoxarifado;
  lote: string;
  historicoEstoque: string;
  dtValidadeEstoque: Date;

  valorCide: any;
  valorSimples: any;
  valorPisRet: any;
  valorCofinsRet: any;
  valorCsll: any;
  valorIss: any;
  valorIssRet: any;
  valorInss: any;
  valorIrrf: any;

  temIrrf: boolean;
  isServico: boolean;

  valorSaving:             any;
  observacaoSaving:     string;

  codigoPedidoPart:     string;
  codigoProdPart:       string;

  constructor(pedidoItem?: PedidoItem | number) {
    if (pedidoItem !== undefined && pedidoItem !== null) {
      if (typeof pedidoItem === 'number') {
        this.id = pedidoItem;
      } else {
        this.id = pedidoItem.id;
        this.pedido = pedidoItem.pedido;
        this.item = pedidoItem.item;
        this.itemFornecedor = pedidoItem.itemFornecedor;
        this.quantidade = pedidoItem.quantidade;
        this.valorUnit = pedidoItem.valorUnit;
        this.cfop = pedidoItem.cfop;
        this.cest = pedidoItem.cest;
        this.ncm = pedidoItem.ncm;
        this.valorFrete = pedidoItem.valorFrete;
        this.tipoDesconto = pedidoItem.tipoDesconto;
        this.valorDesconto = pedidoItem.valorDesconto;

        this.cstIpi = pedidoItem.cstIpi;
        this.classeIpi = pedidoItem.classeIpi;
        this.codigoIpi = pedidoItem.codigoIpi;
        this.valorBcIpi = pedidoItem.valorBcIpi;
        this.aliquotaIpi = pedidoItem.aliquotaIpi;
        this.valorIpi = pedidoItem.valorIpi;

        // this.cstIcms = pedidoItem.cstIcms;
        this.cstIcmsTabelaB = pedidoItem.cstIcmsTabelaB;
        this.cstIcmsTabelaA = pedidoItem.cstIcmsTabelaA;
        // this.origemIcms = pedidoItem.origemIcms;
        this.modBcIcms = pedidoItem.modBcIcms;
        this.percRedBcIcms = pedidoItem.percRedBcIcms;
        this.valorBcIcms = pedidoItem.valorBcIcms;
        this.aliquotaIcms = pedidoItem.aliquotaIcms;
        this.valorIcms = pedidoItem.valorIcms;
        this.valorBcIcmsSt = pedidoItem.valorBcIcmsSt;
        this.aliquotaIcmsSt = pedidoItem.aliquotaIcmsSt;
        this.modBcIcmsSt = pedidoItem.modBcIcmsSt;
        this.valorIcmsSt = pedidoItem.valorIcmsSt;
        this.csosn = pedidoItem.csosn;
        this.aliqCredSn = pedidoItem.aliqCredSn
        this.valorCredSn = pedidoItem.valorCredSn

        this.cstPis = pedidoItem.cstPis;
        this.valorBcPis = pedidoItem.valorBcPis;
        this.vlrAliquotaPis = pedidoItem.vlrAliquotaPis;
        this.perAliquotaPis = pedidoItem.perAliquotaPis;
        this.qtdVendaPis = pedidoItem.qtdVendaPis;
        this.valorPis = pedidoItem.valorPis;

        this.cstCofins = pedidoItem.cstCofins;
        this.valorBcCofins = pedidoItem.valorBcCofins;
        this.vlrAliquotaCofins = pedidoItem.vlrAliquotaCofins;
        this.perAliquotaCofins = pedidoItem.perAliquotaCofins;
        this.qtdVendaCofins = pedidoItem.qtdVendaCofins;
        this.valorCofins = pedidoItem.valorCofins;

        this.valorBcImport = pedidoItem.valorBcImport;
        this.valorDespImport = pedidoItem.valorDespImport;
        this.valorIofImport = pedidoItem.valorIofImport;
        this.valorImpostoImport = pedidoItem.valorImpostoImport;
        this.numeroDi = pedidoItem.numeroDi;
        this.dataRegistroImport = pedidoItem.dataRegistroImport;
        this.codigoExportador = pedidoItem.codigoExportador;
        this.ufDesembaraco = pedidoItem.ufDesembaraco;
        this.dataDesembaraco = pedidoItem.dataDesembaraco;
        this.localDesembaraco = pedidoItem.localDesembaraco;
        this.transporteTipo = pedidoItem.transporteTipo;
        this.tipoIntermediacao = pedidoItem.tipoIntermediacao;
        this.valorAFRMM = pedidoItem.valorAFRMM;
        this.ufAdquirente = pedidoItem.ufAdquirente;
        this.cnpjAdquirente = pedidoItem.cnpjAdquirente;

        this.valorTotalProduto = pedidoItem.valorTotalProduto;
        this.valorTotal = pedidoItem.valorTotal;
        this.valorSeguro = pedidoItem.valorSeguro;
        this.valorOutro = pedidoItem.valorOutro;

        this.dadosAdicionais = pedidoItem.dadosAdicionais;
        this.operacaoRegra = pedidoItem.operacaoRegra;
        this.createTimestamp = pedidoItem.createTimestamp;
        this.changeUser = pedidoItem.changeUser;
        this.changeTimestamp = pedidoItem.changeTimestamp;
        this.pedidoItensRateio = pedidoItem.pedidoItensRateio;
        this.pedidoItensImportAdicao = pedidoItem.pedidoItensImportAdicao;

        this.numeroAto = pedidoItem.numeroAto;
        this.pedidoItensExportacao = pedidoItem.pedidoItensExportacao;

        // FCP
        this.valorBCIcmsFcpUfDest = pedidoItem.valorBCIcmsFcpUfDest;
        this.percBaseCalculoIcmsFcpUfDest = pedidoItem.percBaseCalculoIcmsFcpUfDest;
        this.aliquotaInternaUfTermino = pedidoItem.aliquotaInternaUfTermino;
        this.aliquotaInterestadual = pedidoItem.aliquotaInterestadual;
        this.percentualPartilhaUfTermino = pedidoItem.percentualPartilhaUfTermino;
        this.valorIcmsPartilhaUfInicio = pedidoItem.valorIcmsPartilhaUfInicio;
        this.valorIcmsPartilhaUfTermino = pedidoItem.valorIcmsPartilhaUfTermino;
        this.aliquotaFcpUfTermino = pedidoItem.aliquotaFcpUfTermino;
        this.valorFcpUfTermino = pedidoItem.valorFcpUfTermino;
        // IPI
        this.percentualMercadoriaDevolvida = pedidoItem.percentualMercadoriaDevolvida;
        this.valorIpiDevolvido = pedidoItem.valorIpiDevolvido;
        this.numSequence = pedidoItem.numSequence;

        this.unidadeMedida  = pedidoItem.unidadeMedida;
        this.unidadeMedidaTributavel = pedidoItem.unidadeMedidaTributavel;
        this.quantidadeTributavel = pedidoItem.quantidadeTributavel;
        this.valorUnitTributavel = pedidoItem.valorUnitTributavel;
        this.itemFornecedorReferenciado = pedidoItem.itemFornecedorReferenciado;

        this.empresaAlmoxarifado = pedidoItem.empresaAlmoxarifado;
        this.almoxarifadoDestino =  pedidoItem.almoxarifadoDestino;
        this.lote =  pedidoItem.lote;
        this.historicoEstoque =  pedidoItem.historicoEstoque;
        this.dtValidadeEstoque =  pedidoItem.dtValidadeEstoque;

        this.valorCide        = pedidoItem.valorCide;
        this.valorSimples     = pedidoItem.valorSimples;
        this.valorPisRet      = pedidoItem.valorPisRet;
        this.valorCofinsRet   = pedidoItem.valorCofinsRet;
        this.valorCsll        = pedidoItem.valorCsll;
        this.valorIss         = pedidoItem.valorIss;
        this.valorIssRet      = pedidoItem.valorIssRet;
        this.valorInss        = pedidoItem.valorInss;
        this.valorIrrf        = pedidoItem.valorIrrf;
        this.temIrrf          = pedidoItem.temIrrf;
        this.isServico        = pedidoItem.isServico;

        this.valorSaving      = pedidoItem.valorSaving;
        this.observacaoSaving = pedidoItem.observacaoSaving;

        this.codigoPedidoPart = pedidoItem.codigoPedidoPart;
        this.codigoProdPart   = pedidoItem.codigoProdPart;

        this.valorOperSemIcmsDifal =        pedidoItem.valorOperSemIcmsDifal;
        this.aliqInternaDifal=              pedidoItem.aliqInternaDifal;
        this.aliqInterestadualDifal=        pedidoItem.aliqInterestadualDifal;
        this.valorOperComIcmsDifal=         pedidoItem.valorOperComIcmsDifal;
        this.icmsOrigemCreditoDifal=        pedidoItem.icmsOrigemCreditoDifal;
        this.icmsDestinoDebitoDifal=        pedidoItem.icmsDestinoDebitoDifal;
        this.valorRecolherDifal=            pedidoItem.valorRecolherDifal;
      }
    }
  }
}
