import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { LocalidadeGrupo } from 'app/financeiro/model/localidade-grupo.model';

@Component({
  selector: 'app-localidade-grupo',
  templateUrl: './localidade-grupo.component.html',
  styleUrls: ['./localidade-grupo.component.scss'],
  providers: [CrudService]
})
export class LocalidadeGrupoComponent extends DataTableBasicComponent
  implements OnInit, OnDestroy {

  public localidadeGrupo: LocalidadeGrupo = new LocalidadeGrupo();

  private empresaSubscription: Subscription;

  entityFilter: string = null;

  constructor(
    private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService
  ) {
    super(crudService);
  }

  ngOnInit() {
    this.empresaSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.empresaChanged,
      () => {
        this.empresaChanged();
      }
    );



  }


  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  empresaChanged() {


    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.columns = [
        new DataTableColumn('Descrição', 'descricao')
      ];

      this.controls = [

        new DataControl('Descrição', 'descricao')
          .setRequired()
          .setMinLength(1)
          .setMaxLength(50)

        ];
    }
  }
}
