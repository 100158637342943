import { ItemGrupo } from 'app/cadastro/model/item-grupo.model';
import { ImpostoRegraFiscal } from 'app/cadastro/model/imposto-regra-fiscal.model';


export class ItemGrupoTributacao {

  id: number;
  itemGrupo: ItemGrupo;
  impostoRegraFiscal: ImpostoRegraFiscal;
  aliquotaImposto: number;
  reducaoBc: number;
  valorPauta: number;

  constructor(itemGrupoTributacao?: ItemGrupoTributacao | number) {
    if (itemGrupoTributacao !== undefined && itemGrupoTributacao !== null) {
      if (typeof itemGrupoTributacao === 'number') {
        this.id = itemGrupoTributacao;
      } else {
        this.id = itemGrupoTributacao.id;
        this.itemGrupo = itemGrupoTributacao.itemGrupo;
        this.impostoRegraFiscal = itemGrupoTributacao.impostoRegraFiscal;
        this.aliquotaImposto = itemGrupoTributacao.aliquotaImposto;
        this.reducaoBc = itemGrupoTributacao.reducaoBc;
        this.valorPauta = itemGrupoTributacao.valorPauta;
      }
    }
  }
}
