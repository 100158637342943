import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AppComponent } from '../../app.component';
import { Config } from '../../app.config';
import { PlanoContas } from '../../contabil/model/plano-contas.model';
import { TitleService } from 'app/shared/services/title.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { PysocketService } from 'app/shared/services/socket/pysocket.service';
import { Service } from 'app/shared/services/socket/protocol/Service';
import { Action } from 'app/shared/services/socket/protocol/Action';
import { Method } from 'app/shared/services/socket/protocol/Method';
import idv4 from 'app/util/processing/idv4';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-report',
  templateUrl: './report-balancete-mensal.component.html',
  styleUrls: ['./report-balancete-mensal.component.scss'],
})
export class EcdReportBalanceteMensalComponent implements OnInit, OnDestroy {
  // TODO: Review INPUTS
  @Input() keyField: 'id';
  @Input() dtIni: 'dtIni';
  @Input() dtFin: 'dtFin';
  @Input() encerramento: 'encerramento';
  @Input() idioma: 'idioma';

  private empresaSubscription: Subscription;
  private initSubscription: Subscription;

  private empresaId: number;
  private params: string;

  balancete: Array<any>;
  selectedPc: any;

  titlePrincipal: string;
  subtitle: string;

  private messagesSubscription: Subscription
  isLoadingInProgress: boolean = false
  loadingProgress: number = 0
  tokenProgressoSocket: string  = null;

  constructor(private titleService: TitleService,
              private httpService: HttpService,
              private toolbarService: ToolbarService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private configService: ConfigService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private appComponent: AppComponent,
              private socketService: PysocketService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
      setTimeout(() => { this.appComponent._displaySideNav = false; });

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);


    this.activatedRoute.params.subscribe(params => {
        this.empresaId = parseInt(params['id'], 10);
        this.dtIni = params['dtIni'];
        this.dtFin = params['dtFin'];
        this.encerramento = params['encerramento'];
        this.idioma = params['idioma'];

        if (!this.initSubscription) {
          this.initSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
                                                                          () => { this.load(); });
        } else {
          this.load();
        }
    });

    this.titleService.title = 'ECD - Relatórios/Balancete Mensal ' + this.dtIni.substring(5, 7) + '/' + this.dtIni.substring(0, 4);
    if (this.dtIni.substring(0, 7) !== this.dtFin.substring(0, 7)) {
      this.titleService.title += ' até ' + this.dtFin.substring(5, 7) + '/' + this.dtFin.substring(0, 4);
    }

    this.titlePrincipal = 'BALANCETE MENSAL';
    this.subtitle = 'PERÍODO: ' + this.dtIni.substring(5, 7) + '/' + this.dtIni.substring(0, 4);
    if (this.dtIni.substring(0, 7) !== this.dtFin.substring(0, 7)) {
      this.subtitle += ' até ' + this.dtFin.substring(5, 7) + '/' + this.dtFin.substring(0, 4);
    }

    this.messagesSubscription = this.socketService.socketData.subscribe((response) => {
      if (response.service === Service.NOTIFICATION && response.action === Action.LOADING && response.method === Method.GET) {
        this.loadingManagement(response)
      }
    })
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.initSubscription);
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  onRowSelect(event: any) {
    if (event.indCta === 'A') {
      window.open('/ecd/report-balancete-mensal-lcto/' + this.empresaId + '/' + event.codCta +
                                                   '/' + this.dtIni + '/' + this.dtFin +
                                                   '/balancete' + '/' + this.encerramento,
                  '_blank');
    }
  }

  private load() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.sessionService.destroySubscribe(this.initSubscription);
      this.initProgressLoading();

      const infos = {
        empresaId: this.empresaId,
        tokenProgressoSocket: this.tokenProgressoSocket,
        dataIni: this.dtIni,
        dataFin: this.dtFin,
        encerramento: this.encerramento,
        idioma: this.idioma,
        user_id: this.sessionService.loggedUser.id
      };
      this.httpService.post('/custom/ecd-report/balanceteperiodo', infos)
                      .subscribe(async balancete => {
                                  await new Promise(f => setTimeout(f, 1500));
                                  this.isLoadingInProgress = false

                                  if(balancete.error){
                                    this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: balancete.message })
                                    return;
                                  }else{
                                    this.balancete = balancete;

                                    if (this.dblinkedSessionService.empresa.id !== this.empresaId) {
                                      this.httpService.wait();
                                      this.httpService.get('/empresa?$select=id,cnpj,razaoSocial', this.empresaId)
                                                      .subscribe(empresa => this.dblinkedSessionService.empresaChanged.next(empresa.value),
                                                                  error => this.httpService.handleError(error, () => this.load()),
                                                                  () => this.httpService.done());
                                    }

                                    if (!this.empresaSubscription) {
                                      this.empresaSubscription = this.dblinkedSessionService.empresaChanged.subscribe(empresa => {
                                        if (this.dblinkedSessionService.hasEmpresaSelected) {
                                          this.router.navigate(['/ecd/report-balancete-mensal/', empresa.id,
                                                                                                 this.dtIni, this.dtFin, this.encerramento]);
                                        }
                                      });
                                    }
                                  }



                                },
                                error => this.httpService.handleError(error, () => this.load()),
                                () => {
                                  this.httpService.done()
                                });
    }
  }

  rowStyleClass(planoContas: PlanoContas): string {
    return planoContas.indCta === 'S' ? 'bold' : '';
    // TODO: FIX => return this.planoContasService.rowStyleClass(planoContas);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  planoContasListStyleClass(planoContas: any): string {
    let classes = '';
    console.log(planoContas);

    if (planoContas.indCta === 'S') {
      classes += 'bold ';
    }

    return classes.trim();
  }

  loadingManagement(data: any){
    if(data.content.tokenProgressoSocket == this.tokenProgressoSocket){
      this.loadingProgress = Math.round(data.content.progress)
    }
  }

  initProgressLoading(){
    this.loadingProgress = 0
    this.tokenProgressoSocket = idv4()
    this.isLoadingInProgress = true
  }
}
