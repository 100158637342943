import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { AppComponent } from '../../app.component';
import { Config } from '../../app.config';
import { PlanoContas } from '../../contabil/model/plano-contas.model';
import { ScrollService } from 'app/shared/services/scroll.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { PysocketService } from 'app/shared/services/socket/pysocket.service';
import { Service } from 'app/shared/services/socket/protocol/Service';
import { Action } from 'app/shared/services/socket/protocol/Action';
import { Method } from 'app/shared/services/socket/protocol/Method';
import idv4 from 'app/util/processing/idv4';

@Component({
  selector: 'app-report',
  templateUrl: './report-plano-contas.component.html',
  styleUrls: ['./report-plano-contas.component.scss'],
})

export class EcdReportPlanoContasComponent implements OnInit, OnDestroy {
  // TODO: Review INPUTS
  @Input() keyField = 'id';

  private empresaSubscription: Subscription;
  private initSubscription: Subscription;

  private empresaId: number;
  private params: string;

  planoContas: Array<PlanoContas> = new Array();

  private messagesSubscription: Subscription
  isLoadingInProgress: boolean = false
  loadingProgress: number = 0
  tokenProgressoSocket: string  = null;

  constructor(private titleService: TitleService,
              private httpService: HttpService,
              private configService: ConfigService,
              private scrollService: ScrollService,
              private toolbarService: ToolbarService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private appComponent: AppComponent,
              private socketService: PysocketService) {
  }

  ngOnInit(): void {
    if (this.configService.production) {
      this.appComponent._displaySideNav = false;
    } else {
      setTimeout(() => { this.appComponent._displaySideNav = false; });
    }

    this.messagesSubscription = this.socketService.socketData.subscribe((response) => {
      if (response.service === Service.NOTIFICATION && response.action === Action.LOADING && response.method === Method.GET) {
        this.loadingManagement(response)
      }
    })

    this.activatedRoute.params.subscribe(params => {
        this.empresaId = parseInt(params['id'], 10);

        if (!this.initSubscription) {
          this.initSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
                                                                            () => { this.load(); });
        } else {
          this.load();
        }
    });

    this.titleService.title = 'ECD - Relatórios/Plano de Contas';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.initSubscription);
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  private load() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.sessionService.destroySubscribe(this.initSubscription);
      this.initProgressLoading();
      const infos = {
        empresaId: this.empresaId,
        tokenProgressoSocket: this.tokenProgressoSocket,
        usuario: this.sessionService.loggedUser.id,
        year: this.dblinkedSessionService.periodo.year.value
      };
      this.httpService.post('/custom/ecd/planocontas-carregamento', infos)
                      .subscribe(planoContas => {
                                   this.planoContas = planoContas.value;

                                   if (this.dblinkedSessionService.empresa.id !== this.empresaId) {
                                     this.httpService.get('/empresa?$select=id,cnpj,razaoSocial', this.empresaId)
                                                     .subscribe(empresa => this.dblinkedSessionService.empresaChanged.next(empresa.value),
                                                                error => this.httpService.handleError(error, () => this.load()),
                                                                () => this.httpService.done());
                                   }

                                   if (!this.empresaSubscription) {
                                     this.empresaSubscription = this.dblinkedSessionService.empresaChanged.subscribe(empresa => {
                                       if (this.dblinkedSessionService.hasEmpresaSelected) {
                                         this.router.navigate(['/ecd/report-plano-contas/', empresa.id]);
                                       }
                                     });
                                   }
                                 },
                                 error => this.httpService.handleError(error, () => this.load()),
                                 () => {
                                  this.httpService.done()
                                  this.isLoadingInProgress = false;
                                 });
    }
  }

  rowStyleClass(planoContas: PlanoContas): string {
    return planoContas.indCta === 'S' ? 'bold' : '';
    // TODO: FIX =>  return this.planoContasService.rowStyleClass(planoContas);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  loadingManagement(data: any){
    if(data.content.tokenProgressoSocket == this.tokenProgressoSocket){
      this.loadingProgress = Math.round(data.content.progress)
    }
  }

  initProgressLoading(){
    this.loadingProgress = 0
    this.tokenProgressoSocket = idv4()
    this.isLoadingInProgress = true
  }
}
