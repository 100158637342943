import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-cad-benef-encargo-cta-relac',
  templateUrl: './cad-benef-encargo-cta-relac.component.html',
  styleUrls: ['./cad-benef-encargo-cta-relac.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadBenefEncargoCtaRelac implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = null;
  msgsModal: Message[] = null;
  subtitle: string;
  title: string;
  benefLista: Array<any> = new Array();
  encargoLista: Array<any> = new Array();
  eventoLista: Array<any> = new Array();
  salRecLista: Array<any> = new Array();

  //Variaveis de modal
  modalNew: boolean = false;
  modalEdit: boolean = false;
  isBenef: boolean = false;
  isEncargo: boolean = false;
  isEvento: boolean = false;
  isSalario: boolean = false;
  benefSelecionado: Array<any> = new Array();
  encargoSelecionado: Array<any> = new Array();
  eventoSelecionado: Array<any> = new Array();
  descrSelecionada: Array<any> = new Array();
  tipoCcusSelecionado: number = null;
  tipoEventoSelecionado: string = null;
  contaSelecionada: number = null;
  contaSelecionadaAdm: number = null;
  contaSelecionadaProd: number = null;
  idEdit: number = null;
  benefList: SelectItem[] = [];
  encargoList: SelectItem[] = [];
  eventoList: SelectItem[] = [];
  tipoCcusList: SelectItem[] = [];
  contasList: SelectItem[] = [];
  tipoEventoList: SelectItem[] = [];
  descrList: SelectItem[] = [];

  //Variaveis da tela
  statusTela1: boolean = true;
  statusTela2: boolean = false;
  statusTela3: boolean = false;
  statusTela4: boolean = false;

  //export excel
  colsBenefLista: any[];
  colsEncargoLista: any[];
  colsEventoLista: any[];
  colsSalRecLista: any[];

  constructor(private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private messageService: MessageService) { }


  @ViewChild('dt1') dt1: Table
  exportBenef() {
    this.dt1.exportCSV()
  }
  @ViewChild('dt2') dt2: Table
  exportEncar() {
    this.dt2.exportCSV()
  }
  @ViewChild('dt3') dt3: Table
  exportEvent() {
    this.dt3.exportCSV()
  }
  @ViewChild('dt4') dt4: Table
  exportSald() {
    this.dt4.exportCSV()
  }

  public toolbarBenef = new Toolbar();
  public toolbarEncar = new Toolbar();
  public toolbarEvent = new Toolbar();
  public toolbarSald = new Toolbar();

  toolbarBenefIni = [
    { key: 'export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.dt1.exportCSV() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.edit() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => { } },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddBenefVinc() },
    {
      key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardBenef()
        this.changeStateBenef()
      }
    },
  ]

  toolbarEncarIni = [
    { key: 'export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.dt2.exportCSV() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.edit() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => { } },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddEncargoVinc() },
    {
      key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardBenef()
        this.changeStateBenef()
      }
    },
  ]

  toolbarEventIni = [
    { key: 'export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.dt3.exportCSV() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.edit() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => { } },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddEventoVinc() },
    {
      key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardBenef()
        this.changeStateBenef()
      }
    },
  ]

  toolbarSaldIni = [
    { key: 'export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.dt4.exportCSV() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.edit() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => { } },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddSalVinc() },
    {
      key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardBenef()
        this.changeStateBenef()
      }
    },
  ]

  ngOnInit() {
    this.toolbarBenef.setAll(this.toolbarBenefIni)
    this.toolbarEncar.setAll(this.toolbarEncarIni)
    this.toolbarEvent.setAll(this.toolbarEventIni)
    this.toolbarSald.setAll(this.toolbarSaldIni)
    this.title = 'VINCULAÇÃO';
    this.subtitle = 'Vinculação de benefícios e encargos com conta contábil';
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.toolbarService.clear()
          this.toolbarService.hidden = true;
          //Tipo evento list
          this.tipoEventoList.push({ "label": "Vencimento (V)", "value": "V" });
          this.tipoEventoList.push({ "label": "Desconto (D)", "value": "D" });
          this.tipoEventoList.push({ "label": "Total (T)", "value": "T" });
          this.tipoEventoList.push({ "label": "Provisão (P)", "value": "P" });

          //Outros dropdown
          this.descrList = [];
          this.descrList.push({ "label": "Salário", "value": "SALARIO" });
          this.descrList.push({ "label": "Rescisão", "value": "RESCISAO" });

          //Outros GET
          this.getBenefRelac();
          this.getEncargoRelac();
          this.getEventoRelac();
          this.getSalRecRelac();
          this.getBeneficios();
          this.getEncargos();
          this.getCta();
        }

      });

    this.colsBenefLista = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'nome', header: 'BENEFÍCIO' },
      { field: 'tipo_ccus', header: 'TIPO CENTRO DE CUSTO' },
      { field: 'cod_cta', header: 'CÓD. CTA ' },
      { field: 'descr_cta', header: 'DESCR. CTA ' }
    ];
    this.colsEncargoLista = [
      { field: 'encargo', header: 'ENCARGO' },
      { field: 'tipo_ccus', header: 'TIPO CENTRO DE CUSTO' },
      { field: 'cod_cta', header: 'CÓD. CTA ' },
      { field: 'descr_cta', header: 'DESCR. CTA ' }
    ];
    this.colsEventoLista = [
      { field: 'cod_evento', header: 'CÓDIGO' },
      { field: 'descr_evento', header: 'EVENTO' },
      { field: 'tipo_evento', header: 'TIPO' },
      { field: 'tipo_ccus', header: 'TIPO CENTRO DE CUSTO' },
      { field: 'cod_cta', header: 'CÓD. CTA ' },
      { field: 'descr_cta', header: 'DESCR. CTA ' }
    ];
    this.colsSalRecLista = [
      { field: 'cod_evento', header: 'CÓDIGO' },
      { field: 'descr_evento', header: 'EVENTO' },
      { field: 'tipo_evento', header: 'TIPO' },
      { field: 'tipo_ccus', header: 'TIPO CENTRO DE CUSTO' },
      { field: 'cod_cta', header: 'CÓD. CTA ' },
      { field: 'descr_cta', header: 'DESCR. CTA ' }
    ];
  }

  toolbarBenefInitial() {
    this.toolbarBenef.setVisible('export', true)
    this.toolbarBenef.setVisible('edit', false)
    this.toolbarBenef.setVisible('delete', false)
    this.toolbarBenef.setVisible('add', true)
    this.toolbarBenef.setVisible('expand', true)
  }
  toolbarEncarInitial() {
    this.toolbarEncar.setVisible('export', true)
    this.toolbarEncar.setVisible('edit', false)
    this.toolbarEncar.setVisible('delete', false)
    this.toolbarEncar.setVisible('add', true)
    this.toolbarEncar.setVisible('expand', true)
  }
  toolbarEventInitial() {
    this.toolbarEvent.setVisible('export', true)
    this.toolbarEvent.setVisible('edit', false)
    this.toolbarEvent.setVisible('delete', false)
    this.toolbarEvent.setVisible('add', true)
    this.toolbarEvent.setVisible('expand', true)
  }
  toolbarSaldInitial() {
    this.toolbarSald.setVisible('export', true)
    this.toolbarSald.setVisible('edit', false)
    this.toolbarSald.setVisible('delete', false)
    this.toolbarSald.setVisible('add', true)
    this.toolbarSald.setVisible('expand', true)
  }

  ngOnDestroy() {
    this.toolbarService.clear()
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  edit() {
    this.modalEdit = true
  }

  getEventos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'tipo': this.tipoEventoSelecionado.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/eventos', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.eventoList = new Array();
          for (let line of result) {
            this.eventoList.push({ "label": (line["codigo"] === null || line["codigo"] === "" ? "" : line["codigo"] + " - ") + line["descricao"], "value": line["id"] });
          }
          this.httpService.done()
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done()
        }
      );
  }

  getSalRecRelac() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/ctasalrecrelac', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.salRecLista = new Array();
          for (let line of result) {
            this.salRecLista.push(line);

          }
          this.httpService.done()
        },
        err => {
          this.httpService.done()
          this.exibirMensagem(err);
          console.log(err);
        }
      );
  }

  addBenefVinc() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'userid': this.sessionService.loggedUser.id.toString(),
        'beneficioid': this.benefSelecionado.toString(),
        'ctaadmid': this.contaSelecionadaAdm.toString(),
        'ctaprodid': this.contaSelecionadaProd.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/ctabenefrelac', null, httpOptions)
      .subscribe(
        res => {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Vinculação cadastrada com sucesso!'
          });
          this.httpService.done();
          this.modalNew = false;
          this.getBenefRelac();
          this.toolbarBenefInitial();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  addEncargoVinc() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'userid': this.sessionService.loggedUser.id.toString(),
        'encargoid': this.encargoSelecionado.toString(),
        'ctaadmid': this.contaSelecionadaAdm.toString(),
        'ctaprodid': this.contaSelecionadaProd.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/ctaencargorelac', null, httpOptions)
      .subscribe(
        res => {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Vinculação cadastrada com sucesso!'
          });
          this.httpService.done();
          this.modalNew = false;
          this.getEncargoRelac();
          this.toolbarEncarInitial();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  addEventoVinc() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'userid': this.sessionService.loggedUser.id.toString(),
        'eventoid': this.eventoSelecionado.toString(),
        'ctaadmid': this.contaSelecionadaAdm.toString(),
        'ctaprodid': this.contaSelecionadaProd.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/ctaeventorelac', null, httpOptions)
      .subscribe(
        res => {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Vinculação cadastrada com sucesso!'
          });
          this.httpService.done();
          this.modalNew = false;
          this.getEventoRelac();
          this.toolbarEventInitial();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  addSalarioVinc() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'userid': this.sessionService.loggedUser.id.toString(),
        'descr': this.descrSelecionada.toString(),
        'ctaadmid': this.contaSelecionadaAdm.toString(),
        'ctaprodid': this.contaSelecionadaProd.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/ctasalrecrelac', null, httpOptions)
      .subscribe(
        res => {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Vinculação cadastrada com sucesso!'
          });
          this.modalNew = false;
          this.getSalRecRelac();
          this.httpService.done();
          this.toolbarSaldInitial();
        },
        err => {
          this.exibirMensagem(err);
          this.httpService.done()
        }
      );
  }

  editBenefVinc() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'relacid': this.idEdit.toString(),
        'ctaadmid': this.contaSelecionadaAdm === null ? "0" : this.contaSelecionadaAdm.toString(),
        'ctaprodid': this.contaSelecionadaProd === null ? "0" : this.contaSelecionadaProd.toString(),
        'userid': this.sessionService.loggedUser.id.toString(),
        'beneficioid': this.benefSelecionado.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/ctabenefrelac', null, httpOptions)
      .subscribe(
        res => {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Vinculação editada com sucesso!'
          });
          this.httpService.done();
          this.modalEdit = false;
          this.getBenefRelac();
          this.toolbarBenefInitial();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  editSalarioVinc() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'relacid': this.idEdit.toString(),
        'userid': this.sessionService.loggedUser.id.toString(),
        'ctaadmid': this.contaSelecionadaAdm === null ? "0" : this.contaSelecionadaAdm.toString(),
        'ctaprodid': this.contaSelecionadaProd === null ? "0" : this.contaSelecionadaProd.toString(),
        'descr': this.descrSelecionada.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/ctasalrecrelac', null, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.modalEdit = false;
          this.getSalRecRelac();
          this.httpService.done();
          this.toolbarSaldInitial();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done()
        }
      );
  }

  showTelaVinculacao(tipo) {
    this.statusTela1 = false;
    this.statusTela2 = false;
    this.statusTela3 = false;
    this.statusTela4 = false;

    if (tipo === 'TELA_1') {
      this.statusTela1 = true;

    } else if (tipo === 'TELA_2') {
      this.statusTela2 = true;

    } else if (tipo === 'TELA_3') {
      this.statusTela3 = true;

    } else if (tipo === 'TELA_4') {
      this.statusTela4 = true;
    }
  }

  onEventoSelect(row) {
    this.toolbarEvent.setClick('delete', () => this.excluirEvento(row))
    this.toolbarEvent.setVisible('edit', true)
    this.toolbarEvent.setVisible('delete', true)
    this.isBenef = false;
    this.isEncargo = false;
    this.isEvento = true;
    this.isSalario = false;
    this.contaSelecionada = row.cta_id;
    row.selected = false
    this.contaSelecionadaAdm = null;
    this.contaSelecionadaProd = null;
    this.contaSelecionada = row.cta_id;
    if (row.tipo_ccus_id === 1) {
      this.contaSelecionadaAdm = row.cta_id;
      this.contaSelecionadaProd = null;

    } else if (row.tipo_ccus_id === 2) {
      this.contaSelecionadaAdm = null;
      this.contaSelecionadaProd = row.cta_id;

    }
    this.idEdit = row.id === null ? "0" : row.id;
    this.eventoSelecionado = [];
    this.eventoSelecionado.push(row.evento_id)

  }

  onEventoUnselect(row) {
    row.selected = true
    this.toolbarEvent.setVisible('edit', false)
    this.toolbarEvent.setVisible('delete', false)
  }

  excluirEvento(row) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'vincid': row.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/ctaeventorelac', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.getEventoRelac();
          this.toolbarEventInitial();
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  openAddEventoVinc() {
    this.modalNew = true;
    this.isEncargo = false;
    this.isBenef = false;
    this.isEvento = true;
    this.isSalario = false;
    this.tipoEventoSelecionado = null;
    this.eventoSelecionado = [];
    this.contaSelecionadaAdm = 0;
    this.contaSelecionadaProd = 0;
  }

  openAddSalVinc() {
    this.modalNew = true;
    this.isEncargo = false;
    this.isBenef = false;
    this.isEvento = false;
    this.isSalario = true;
    this.contaSelecionadaAdm = 0;
    this.contaSelecionadaProd = 0;
    this.descrSelecionada = null;
    this.contaSelecionadaAdm = 0;
    this.contaSelecionadaProd = 0;
  }

  editEncargoVinc() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'relacid': this.idEdit.toString(),
        'ctaadmid': this.contaSelecionadaAdm === null ? "0" : this.contaSelecionadaAdm.toString(),
        'ctaprodid': this.contaSelecionadaProd === null ? "0" : this.contaSelecionadaProd.toString(),
        'userid': this.sessionService.loggedUser.id.toString(),
        'encargoid': this.encargoSelecionado.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/ctaencargorelac', null, httpOptions)
      .subscribe(
        res => {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Vinculação editada com sucesso!'
          });
          this.httpService.done();
          this.modalEdit = false;
          this.getEncargoRelac();
          this.toolbarEncarInitial();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  editEventoVinc() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'relacid': this.idEdit.toString(),
        'ctaadmid': this.contaSelecionadaAdm === null ? "0" : this.contaSelecionadaAdm.toString(),
        'ctaprodid': this.contaSelecionadaProd === null ? "0" : this.contaSelecionadaProd.toString(),
        'userid': this.sessionService.loggedUser.id.toString(),
        'eventoid': this.eventoSelecionado.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/ctaeventorelac', null, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.modalEdit = false;
          this.getEventoRelac();
          this.toolbarEventInitial();
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getCta() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/planocontas', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.contasList = new Array();
          for (let line of result) {
            this.contasList.push({ "label": line["cod_cta"] + " - " + line["descr_cta"], "value": line["id"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getBeneficios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/beneficios', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.benefList = new Array();
          for (let line of result) {
            if (line["codigo"] === null) {
              this.benefList.push({ "label": line["nome"], "value": line["id"] });
            } else {
              this.benefList.push({ "label": line["codigo"] + " - " + line["nome"], "value": line["id"] });
            }
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getEncargos() {
    const httpOptions = {headers: new HttpHeaders({'Authorization': this.configService.bearerApi})}
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/encargos?empresaId=' + this.dblinkedSessionService.empresa.id, httpOptions)
      .subscribe(
        res => {
          this.encargoList = new Array()
          res["data"].forEach(row => {
            this.encargoList.push({ "label": row["codigo"] + " - " + row["descricao_encargo"], "value": row["id"] })
          })
        },
        err => this.exibirMensagem({error: true, mensagem: "Erro no API ao obter os encargos, contatar o suporte: " + err.message}))
  }

  getBenefRelac() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/ctabenefrelac', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.benefLista = new Array();
          for (let line of result) {
            this.benefLista.push(line);
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getEventoRelac() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/ctaeventorelac', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.eventoLista = new Array();
          for (let line of result) {
            this.eventoLista.push(line);
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getEncargoRelac() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/ctaencargorelac', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.encargoLista = new Array();
          for (let line of result) {
            this.encargoLista.push(line);
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  onBenefSelect(row) {
    this.toolbarBenef.setClick('delete', () => this.excluirBenef(row))
    this.toolbarBenef.setVisible('edit', true)
    this.toolbarBenef.setVisible('delete', true)
    this.isBenef = true;
    this.isEncargo = false;
    this.isEvento = false;
    this.isSalario = false;
    this.contaSelecionadaAdm = null;
    this.contaSelecionadaProd = null;
    row.selected = false
    this.contaSelecionada = row.cta_id;
    if (row.tipo_ccus_id === 1) {
      this.contaSelecionadaAdm = row.cta_id;
      this.contaSelecionadaProd = null;

    } else if (row.tipo_ccus_id === 2) {
      this.contaSelecionadaAdm = null;
      this.contaSelecionadaProd = row.cta_id;

    }
    this.idEdit = row.id === null ? "0" : row.id;
    this.benefSelecionado = [];
    this.benefSelecionado.push(row.benef_id)
  }

  onBenefUnselect(row) {
    row.selected = true
    this.toolbarBenef.setVisible('edit', false)
    this.toolbarBenef.setVisible('delete', false)
  }

  onEncargoSelect(row) {
    this.toolbarEncar.setClick('delete', () => this.excluirEncargo(row))
    this.toolbarEncar.setVisible('edit', true)
    this.toolbarEncar.setVisible('delete', true)
    this.isBenef = false;
    this.isEncargo = true;
    this.isEvento = false;
    this.isSalario = false;
    this.contaSelecionada = row.cta_id;
    row.selected = false
    this.contaSelecionadaAdm = null;
    this.contaSelecionadaProd = null;
    if (row.tipo_ccus_id === 1) {
      this.contaSelecionadaAdm = row.cta_id;
      this.contaSelecionadaProd = null;

    } else if (row.tipo_ccus_id === 2) {
      this.contaSelecionadaAdm = null;
      this.contaSelecionadaProd = row.cta_id;

    }
    this.idEdit = row.id === null ? "0" : row.id;
    this.encargoSelecionado = [];
    this.encargoSelecionado.push(row.encargo_id)
  }

  onEncargoUnselect(row) {
    row.selected = true
    this.toolbarEncar.setVisible('edit', false)
    this.toolbarEncar.setVisible('delete', false)
  }

  onSalarioSelect(row) {
    this.toolbarSald.setClick('delete', () => this.excluirSalario(row))
    this.toolbarSald.setVisible('edit', true)
    this.toolbarSald.setVisible('delete', true)
    this.isBenef = false;
    this.isEncargo = false;
    this.isEvento = false;
    this.isSalario = true;
    this.contaSelecionada = row.cta_id;
    this.contaSelecionadaAdm = null;
    this.contaSelecionadaProd = null;
    row.selected = false
    if (row.tipo_ccus_id === 1) {
      this.contaSelecionadaAdm = row.cta_id;
      this.contaSelecionadaProd = null;

    } else if (row.tipo_ccus_id === 2) {
      this.contaSelecionadaAdm = null;
      this.contaSelecionadaProd = row.cta_id;

    }
    this.idEdit = row.id === null ? "0" : row.id;
    this.descrSelecionada = row.nome;
  }
  onSalarioUnselect(row) {
    row.selected = true
    this.toolbarSald.setVisible('edit', false)
    this.toolbarSald.setVisible('delete', false)
  }

  hideMessage() {


  }

  excluirBenef(row) {
    if (this.validacaoBenefEx()) {
      return;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'vincid': row.id.toString(),
      })
    };

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/ctabenefrelac', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.getBenefRelac();
          this.toolbarBenefInitial();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  excluirEncargo(row) {
    if (this.validacaoEncarcoEx()) {
      return;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'vincid': row.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/ctaencargorelac', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.getEncargoRelac();
          this.toolbarEncarInitial();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  excluirSalario(row) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'vincid': row.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/ctasalrecrelac', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.getSalRecRelac();
          this.httpService.done();
          this.toolbarSaldInitial();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done()
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  openAddBenefVinc() {
    this.modalNew = true;
    this.isBenef = true;
    this.isEncargo = false;
    this.isEvento = false;
    this.isSalario = false;
    this.benefSelecionado = [];
    this.contaSelecionadaAdm = 0;
    this.contaSelecionadaProd = 0;
  }

  openAddEncargoVinc() {
    this.modalNew = true;
    this.isBenef = false;
    this.isEncargo = true;
    this.isEvento = false;
    this.isSalario = false;
    this.encargoSelecionado = [];
    this.contaSelecionadaAdm = 0;
    this.contaSelecionadaProd = 0;
  }

  validacaoEncarcoEx(): boolean {
    if (this.contaSelecionadaAdm === null && this.contaSelecionadaProd === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Warn', detail: 'Não é possível excluir esta vinculação' });

      return false;
    }

  }
  validacaoBenefEx(): boolean {
    if (this.contaSelecionadaAdm === null && this.contaSelecionadaProd === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Warn', detail: 'Não é possível excluir esta vinculação' });

      return false;
    }

  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return 50;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  isCardBenef: boolean = true;
  ShowHIdeCardBenef() {
    this.isCardBenef = !this.isCardBenef;
    this.toolbarBenef.setIcon('expand', this.isCardBenef ? 'expand_less' : 'expand_more')
    this.toolbarEncar.setIcon('expand', this.isCardBenef ? 'expand_less' : 'expand_more')
    this.toolbarEvent.setIcon('expand', this.isCardBenef ? 'expand_less' : 'expand_more')
    this.toolbarSald.setIcon('expand', this.isCardBenef ? 'expand_less' : 'expand_more')
    this.toolbarBenef.setTooltip('expand', this.isCardBenef ? 'Ver menos' : 'Ver mais')
    this.toolbarEncar.setTooltip('expand', this.isCardBenef ? 'Ver menos' : 'Ver mais')
    this.toolbarEvent.setTooltip('expand', this.isCardBenef ? 'Ver menos' : 'Ver mais')
    this.toolbarSald.setTooltip('expand', this.isCardBenef ? 'Ver menos' : 'Ver mais')
  }

  currentStateBenef = 'initial';
  changeStateBenef() {
    this.currentStateBenef = this.currentStateBenef === 'initial' ? 'final' : 'initial';
  }

  fullBenef: boolean = false;
  fullScreenBenef() {
    this.fullBenef = !this.fullBenef;
  }

}
