import { Empresa } from 'app/cadastro/model/empresa.model';

export class TbPrejAcumuladoCsll {
    id: number;
    empresa: Empresa;
    periodo:  number;
    valorPrejuizo: number;

    constructor(tbprejacumuladocsll?: TbPrejAcumuladoCsll | number) {
    if (tbprejacumuladocsll !== undefined && tbprejacumuladocsll !== null) {
      if (typeof tbprejacumuladocsll === 'number') {
        this.id = tbprejacumuladocsll;
      } else {
        this.id = tbprejacumuladocsll.id;
        this.empresa = tbprejacumuladocsll.empresa;
        this.periodo = tbprejacumuladocsll.periodo;
        this.valorPrejuizo = tbprejacumuladocsll.valorPrejuizo;
      }
    }
  }
}
