import { SelectItem } from './../../shared/common/select-item.interface';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { Md5Service } from 'app/shared/services/md5.service';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { Departamento } from 'app/util/model/departamento.model';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ValidatorsService } from 'app/shared/services/validators.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class AuthProfileComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;

  @ViewChild('formContainer')
  formContainer: ElementRef;

  formValues: any;

  usuario: Usuario;
  usuarioSelecionado: Usuario;
  verif: boolean;

  defaultPass = '******';

  formSubscription: Subscription;
  sessionSubscription: Subscription;
  gestores: any;
  departamentos: any;

  usersAll: any;

  niveis: any;
  title: string;
  subtitle: string;

  constructor(private formBuilder: FormBuilder,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private router: Router,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private validatorsService: ValidatorsService
  ) {
  }

  ngOnInit() {
    this.titleService.title = 'Perfil de usuário';
    this.title = 'Usuários';
    this.subtitle = 'Usuários';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.sessionService.initSubscribe(this.dblinkedSessionService.sessionLoaded, () => this.load());
  }

  ngOnDestroy() {
    // this.toolbarService.clear();
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.formSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  load() {
    if (this.dblinkedSessionService.usuario && this.dblinkedSessionService.usuario.id && this.dblinkedSessionService.empresa) {
      if (this.dblinkedSessionService.usuario.login.endsWith('@linnks.com.br') && this.dblinkedSessionService.usuario.nivel > 2) {
        this.verif = true;

        this.httpService.wait();
        this.httpService.get('/usuario?$select=nome,sobrenome,cpf,login,telefone,gestor/id,gestor/nome,mail,' +
          'gestor/sobrenome,nivel,authUser/id,departamento/id,departamento/cod,visualizaLog&$orderby=nome')
          .subscribe(usuario => this.allUsersLoaded(usuario.value),
            error => this.httpService.handleError(error, () => this.load()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/departamento?$select=id,cod&$orderby=cod')
          .subscribe(usuario => this.departamentosLoaded(usuario.value),
            error => this.httpService.handleError(error, () => this.load()),
            () => this.httpService.done());

      } else {
        this.verif = false;
      }

      this.niveis = [
        { label: 1, id: '1' },
        { label: 2, id: '2' },
        { label: 3, id: '3' },
        { label: 4, id: '4' },
        { label: 5, id: '5' },
        { label: 6, id: '6' }
      ];

      this.httpService.wait();
      this.httpService.get('/usuario?$select=nome,sobrenome,cpf,login,telefone,gestor/id,gestor/nome,mail,' +
        'gestor/sobrenome,nivel,authUser/id,departamento/id,departamento/cod,visualizaLog',
        this.dblinkedSessionService.usuario.id)
        .subscribe(usuario => this.usuarioLoaded(usuario.value),
          error => this.httpService.handleError(error, () => this.load()),
          () => this.httpService.done());
    }
  }

  allUsersLoaded(value: Array<Usuario>) {
    this.usersAll = []
    this.usersAll.push({label: 'Selecione', value: 0 });
    value.forEach(element => {
      this.usersAll.push({
        label: `${element.nome} ${element.sobrenome}`,
        value: element.id
      });
    });
  }

  departamentosLoaded(value: Array<Departamento>) {
    this.departamentos = []
    this.departamentos.push({label: 'Selecione', value: null });
    value.forEach(element => {
      this.departamentos.push({
        label: element.cod,
        value: element.id
      });
    });
  }

  gestoresLoaded(value: Array<any>) {
    this.gestores = []
    this.gestores.push({label: 'Selecione', value: 0 });
    value.forEach(element => {
      this.gestores.push({
        label: `${element.nome} ${element.sobrenome}`,
        value: element.id
      });
    });
  }

  userChange() {
    this.httpService.wait();
    this.httpService.get('/usuario?$select=id,nome,sobrenome,cpf,login,telefone,gestor/id,gestor/nome,' +
      'gestor/sobrenome,nivel,authUser/id,departamento/id,departamento/cod,visualizaLog,mail'
      , this.usuarioSelecionado.id)
      .subscribe(usuario => this.usuarioLoaded(usuario.value),
        error => this.httpService.handleError(error, () => this.load()),
        () => this.httpService.done());

  }

  usuarioLoaded(usuario: Usuario) {
    this.usuario = new Usuario(usuario);

    this.httpService.wait();
    this.httpService.get('/custom/centralweb/gestores-cw', [this.dblinkedSessionService.empresa.id, this.dblinkedSessionService.usuario.id])
      .subscribe(u => this.gestoresLoaded(u),
        error => this.httpService.handleError(error, () => this.usuarioLoaded(usuario)),
        () => this.httpService.done());

    this.formGroup = this.formBuilder.group([]);

    this.formGroup.addControl('displayName',
      new FormControl({ value: this.usuario.nome, disabled: false },
        [Validators.required, Validators.minLength(3), Validators.maxLength(25)]));
    this.formGroup.addControl('fullName',
      new FormControl({ value: this.usuario.nome + ' ' + this.usuario.sobrenome, disabled: true },
        [Validators.required, Validators.minLength(10), Validators.maxLength(60)]));
    this.formGroup.addControl('celular',
      new FormControl({ value: this.usuario.celular, disabled: false },
        [Validators.maxLength(20)]));
    this.formGroup.addControl('phone',
      new FormControl({ value: this.usuario.telefone, disabled: false },
        [Validators.maxLength(20)]));
    this.formGroup.addControl('mail',
      new FormControl({
        value: this.usuario.mail, disabled: true
      }, [Validators.required, Validators.email, Validators.minLength(10), Validators.maxLength(60)]));
    this.formGroup.addControl('cpf',
      new FormControl({ value: this.usuario.cpf, disabled: true },
        [Validators.required, Validators.minLength(14), Validators.maxLength(14)]));
    this.formGroup.addControl('pass',
      new FormControl({ value: '', disabled: false },
        [Validators.minLength(6), Validators.maxLength(50)]));
    this.formGroup.addControl('passConfirm',
      new FormControl({ value: '', disabled: false },
        [Validators.minLength(6), Validators.maxLength(50)]));

    this.formGroup.addControl('departamento',
      new FormControl({
        value: this.usuario.departamento !== null ? this.usuario.departamento.id : null, disabled: this.usuario.nivel < 3
      }, null));
    this.formGroup.addControl('gestor',
      new FormControl({
        value: this.usuario.gestor !== null ? this.usuario.gestor.id : null, disabled: this.usuario.nivel < 3
      }, null));

    this.formGroup.addControl('nivel',
      new FormControl({
        value: this.usuario.nivel, disabled: this.usuario.nivel < 3
      }, [Validators.required]));

    this.formGroup.addControl('visualizaLog',
      new FormControl({
        value: this.usuario.visualizaLog, disabled: this.usuario.nivel < 2
      }, [Validators.required]));

    this.formValues = this.formGroup.getRawValue();
    /*
        this.formSubscription = this.formGroup.statusChanges.subscribe(status => {
          this.toolbarService.clear();
          this.toolbarService.replace('undo', false, 'class-back-button', 'undo', 'Desfazer alterações', () => {
            this.formGroup.reset(this.formValues);
            this.toolbarService.clear(); this.toolbarRefresh();
          });
          this.toolbarService.replace('export', true, 'class-export-button', 'description', 'Exportar', () => this.save());
          this.toolbarService.replace('print', true, 'class-print-button', 'print', 'Imprimir', () => this.save());
          this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.save());
          this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar', () => this.save());
          if (status === 'VALID' && this.equalPasswords) {
            this.toolbarService.replace('save', false, 'class-save-button',  'SALVAR', 'Salvar alterações', () => {
              this.save();
            });
          } else {
            this.toolbarService.replace('save', true, 'class-save-button',  'SALVAR', 'Salvar', () => this.save());
            // this.toolbarService.remove('save');
          }
        }); */

    // TODO: Move
    setTimeout(() => {
      const field = this.formContainer.nativeElement.querySelector('input, textarea');
      if (field) {
        field.focus();
      }
    }, 100);
  }

  save() {
    const user: any = this.formGroup.getRawValue();

    if (this.formGroup.get('pass').value && !this.validatorsService.validaSenha(this.formGroup.get('pass').value, this.formGroup.get('passConfirm').value)) {
      return;
    }

    if (user.pass === this.defaultPass) {
      user.pass = undefined;
    } else if (user.pass && user.pass.trim() !== '') {
      user.pass = new Md5Service().b64_md5(user.pass);
    }
    user.passConfirm = undefined;
    user.authUser = this.usuario.authUser.id;

    this.httpService.wait();
    this.httpService.put('/custom/usuario', null, user)
      .subscribe(() => this.postSave(user),
        error => this.httpService.handleFormError('Usuário', this.formGroup, error, () => this.save()),
        () => this.httpService.done());
  }

  postSave(user: any) {
    this.load();
    // this.sessionService.loggedUser.displayName = user.displayName;

    // TODO: Success message (before redirect)
    this.router.navigateByUrl('/dashboard');
  }

  get displayConfirm(): boolean {
    return this.formGroup.get('pass') &&
      this.formGroup.get('pass').value !== undefined &&
      this.formGroup.get('pass').value !== null &&
      this.formGroup.get('pass').value !== '' &&
      this.formGroup.get('pass').value !== this.defaultPass &&
      this.formGroup.get('pass').valid;
  }

  get equalPasswords(): boolean {
    return this.formGroup.get('pass').value === this.formGroup.get('passConfirm').value;
  }

}
