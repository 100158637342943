import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { MessageService } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TitleService } from 'app/shared/services/title.service';

@Component({
  selector: 'app-cad-periodos',
  templateUrl: './cad-periodos.component.html',
  styleUrls: ['./cad-periodos.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadPeriodoComponent implements OnInit, OnDestroy {
  // Functions animated show close box card
  currentStatePeriodo = 'initial'

  private sessionSubscription: Subscription;

  subtitle: string = 'Controle de períodos RH'
  title: string = 'PERÍODOS'
  modalNew: boolean = false
  modalEdit: boolean = false
  periodosLista: Array<any> = new Array()
  rowSelected: boolean = false
  listVersao: Array<any> = new Array()
  isCardPeriodo: boolean = true

  //Variaveis do periodo
  periodoId: number = null
  dtInicialNew: Date = null
  dtFimNew: Date = null
  orcamentoVersaoIdNew: number = null
  dtInicialEdit: Date = null
  dtFimEdit: Date = null
  orcamentoVersaoIdEdit: number = null
  idEdit: number = null

  //export excel
  colsPeriodosLista = [
    { field: 'descricao', header: 'DESCRIÇÃO' },
    { field: 'dt_ini', header: 'DATA INICIAL' },
    { field: 'dt_fin', header: 'DATA FINAL ' }
  ]

  constructor(private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private titleService: TitleService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.titleService.title = 'RH - Controle de períodos'
    this.toolbarService.clear()
    this.toolbarService.hidden = true
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.rowSelected = false
          this.getVersaoOrca()
          this.getPeriodos()
        }
      })
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  changeStatePeriodo() {
    this.currentStatePeriodo = this.currentStatePeriodo === 'initial' ? 'final' : 'initial';
  }

  getVersaoOrca() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/versao', httpOptions)
      .subscribe(
        res => {
          this.listVersao = [];
          var result = res["data"];
          for (let line of result) {
            this.listVersao.push({ "label": line["descricao"], "value": line["id"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getPeriodos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/periodos', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res["mensagem"]);
          } else {
            let result = res["mensagem"];
            this.periodosLista = new Array();
            for (let line of result) {
              this.periodosLista.push(line);
            }
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  openEditPeriodo(row) {
    this.periodoId = row.id;
    this.modalEdit = true;
    this.dtInicialEdit = new Date();
    this.dtFimEdit = new Date();
    this.orcamentoVersaoIdEdit = this.listVersao.find(v => v.label === row.descricao).value;
    this.idEdit = row.id;
  }

  openAddPeriodo() {
    this.modalNew = true;
    this.dtInicialNew = new Date();;
    this.dtFimNew = new Date();;
    this.orcamentoVersaoIdNew = 0;
  }

  addPeriodo() {
    if (this.orcamentoVersaoIdNew === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "Selecione a versão do orçamento" })

    } else {
      const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }

      const payload = {
        empresaId: this.dblinkedSessionService.empresa.id,
        userId: this.sessionService.loggedUser.id,
        dtInicial: this.dtInicialNew.getFullYear() + '-' + (this.dtInicialNew.getMonth() + 1) + '-' + this.dtInicialNew.getDate(),
        dtFim: this.dtFimNew.getFullYear() + '-' + (this.dtFimNew.getMonth() + 1) + '-' + this.dtFimNew.getDate(),
        orcamentoVersaoId: this.orcamentoVersaoIdNew
      }

      this.httpService.wait()
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/periodos', payload, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res)
            this.getPeriodos()
            this.httpService.done()
            this.modalNew = false
          },
          err => {
            this.exibirMensagem(err)
            this.httpService.done()
          }
        )
    }
  }

  deletePeriodo(row) {
    if (confirm("Isso irá excluir o período, tem certeza?")) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'versaoid': row.id.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/periodos', httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.httpService.done();
            this.modalEdit = false;
            this.getPeriodos();
            this.rowSelected = false;
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  editPeriodo() {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi})}

    const payload = {
      empresaId: this.dblinkedSessionService.empresa.id,
      userId: this.sessionService.loggedUser.id,
      dtInicial: this.dtInicialEdit.getFullYear() + '-' + (this.dtInicialEdit.getMonth() + 1) + '-' + this.dtInicialEdit.getDate(),
      dtFim: this.dtFimEdit.getFullYear() + '-' + (this.dtFimEdit.getMonth() + 1) + '-' + this.dtFimEdit.getDate(),
      orcamentoVersaoId: this.orcamentoVersaoIdEdit
    }

    this.httpService.wait()
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/periodos', payload, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
          this.modalEdit = false
          this.getPeriodos()
        },
        err => {
          this.exibirMensagem(err)
          this.httpService.done()
        }
      )
  }

  mudarStatusPeriodo(row) {
    if (row.status_id === 1) {
      var acao: string = 'encerrar';
      var tipo: string = 'E';

    } else {
      var acao: string = 'reabrir';
      var tipo: string = 'A';
    }

    if (confirm('Deseja realmente ' + acao + ' esse período?')) {

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'versaoId': row.id.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'tipo': tipo.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/status-periodo', null, httpOptions)
        .subscribe(
          res => {
            if (res["error"]) {
              this.exibirMensagem(res);

            } else {
              this.getPeriodos();
            }
            this.httpService.done();
          },
          err => {
            console.log(err);
            this.exibirMensagem(err);
            this.httpService.done();
          }
        );
    }
  }
  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return [10, 20, 50, 100]
  }

  ShowHIdeCardPeriodo() {
    this.isCardPeriodo = !this.isCardPeriodo;
  }

}
