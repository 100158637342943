import { Component, OnInit, OnDestroy } from '@angular/core';

import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-cadastro-menu',
  templateUrl: './cadastro-menu.component.html',
  styleUrls: [
    './cadastro-menu.component.scss'
  ],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadastroMenuComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {}

  private sessionSubscription: Subscription; // change session event;

  title = '';
  subtitle = '';

  // Collapse Card
  currentStateCadastroMenu = 'initial';
  changeStateCadastroMenu() {
    this.currentStateCadastroMenu = this.currentStateCadastroMenu === 'initial' ? 'final' : 'initial';
  }

  // components;
  tiposLista = [];
  showLista = false;
  showNew = false;
  showEdit = false;
  codigo = '';
  descricao = '';
  cnabFormaId = '';
  tipoSelecionadoId = 0;

  // dropdowns;
  formaCnabList: SelectItem[] = [];
  formaCnab: number = null;


  // msgs;
  msgs: Message[] = [];
  msgsModal: Message[] = null;

  // date;
  defaultDate: Date;
  pt: any;

  //variaveis
  tCadastroMenu: Array<Object>;
  modalEdit: boolean = false;
  modalNew: boolean = false;
  idEdit: number = null;
  urlEdit: string = null;
  dataEdit: string = null;
  ativoEdit: boolean = true;

  urlNew: string = null;

  //datepipe
  datePipePt: DatePipe = new DatePipe('pt-BR');

  opcoesAtivo: SelectItem[];

  ngOnInit() {
    this.dblinkedSessionService.hidePeriods();

    this.title = 'Controle de Acesso';
    this.subtitle = 'Cadastro de Menu';

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        // pegamos a listagem de menus
        this.listCadMenu();

        // iniciamos a toolbar
        this.refreshToolbar();

      }
    );

    this.defaultDate = new Date();

  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() {

  }


  // app.component.ts;
  private refreshToolbar() {
    this.toolbarService.clear();
    this.toolbarService.add('refresh', false, 'class-first-button', 'refresh', 'Atualizar', () => this.listCadMenu());
    this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Adicionar', () => this.openNewCadMenu());
  }

  /*Show Hide isCardCadastroMenu*/
  isCardCadastroMenu: boolean = true;
  ShowHideCardCadastroMenu() {
    this.isCardCadastroMenu = !this.isCardCadastroMenu;
  }

  // Edit Items Modal
  openEditCadMenu(row) {
    this.modalEdit = true;
    this.idEdit = row.id;
    this.urlEdit = row.url;
    this.dataEdit = row.data;
    this.ativoEdit = row.ativo;
  }

  // New Item Modal
  openNewCadMenu() {
    this.modalNew = true;
  }

  //On Filter Table
  onFilter(event: any) {

  }

  listCadMenu() {
    // função que atualiza a página e busca os menus
    this.modalNew = false;
    this.tCadastroMenu = [];
    this.urlNew = '';
    this.getComboAtivo();

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/controle-acesso/cadastro-menu-list').subscribe(
      retorno => {
        if (retorno.error){
          alert('Não foi possível buscar os menus');
          return;
        }
        retorno.value.forEach( linha => {
          this.tCadastroMenu.push({
            id: linha['id'],
            data: this.datePipePt.transform(new Date(linha['change_timestamp']), "dd/MM/yyyy"),
            url: linha['permissions_url'],
            ativo: linha['active']
          });
        });
      },
      error => {
        this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
        this.httpService.done();
      },
      () => this.httpService.done()
    );
  }

  createCadMenu() {
    // função que cria um menu
    let objeto = {
      'permissions_url' : this.urlNew
    }
    this.httpService.wait();
    this.httpService.post('custom/cadastro/controle-acesso/cadastro-menu-post', objeto).subscribe(
      retorno => {
        if (retorno.error){
          alert('O menu provavelmente já existe');
          return;
        }
      this.listCadMenu();

      },
      error => {
        this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
        this.httpService.done();
      },
      () => this.httpService.done()
    );
  }

  editCadMenu() {
    // função que edita um menu
    let objeto = {
      'menu_id': this.idEdit,
      'permissions_url' : this.urlEdit,
      'active': this.ativoEdit
    }
    this.httpService.wait();
    this.httpService.post('custom/cadastro/controle-acesso/cadastro-menu-put', objeto).subscribe(
      retorno => {
        if (retorno.error){
          alert('Não foi possível editar o menu');
          return;
        }
      this.modalEdit = false;

      this.listCadMenu();

      },
      error => {
        this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao editar menu." });
        this.httpService.done();
      },
      () => this.httpService.done()
    );
  }

  deleteCadMenu(row) {
    // função que deleta um menu
    if (confirm("Isso irá excluir este item, tem certeza?")) {
      this.httpService.wait();
      this.httpService.get('/custom/cadastro/controle-acesso/cadastro-menu-delete?menu_id=' + row.id).subscribe(
        retorno => {
          if (retorno.error){
            alert('Não foi possível deletar o menu');
            return;
          }
          this.listCadMenu();
        },
        error => {
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
          this.httpService.done();
        },
        () => this.httpService.done()
      );
    }
  }

  getComboAtivo() {
    this.opcoesAtivo = [];
    this.opcoesAtivo.push({label: 'Sim', value: 'true'});
    this.opcoesAtivo.push({label: 'Não', value: 'false'});
  }

}
