import { Empresa } from '../../cadastro/model/empresa.model';
import { TbCsllM350 } from 'app/ecf/model/tb-csll-m350.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { TbIndRelacao } from 'app/ecf/model/tb-ind-relacao.model';
import { TbTipoLcto } from 'app/ecf/model/tb-tipo-lcto.model';
import { MovApurReal } from 'app/ecf/model/mov-apur-real.model';
import { MovSaldosContabeis } from 'app/contabil/model/mov-saldos-contabeis.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';

export class MovParteACsll {
  id: number;
  data: Date;
  hist: string;
  valor: number;
  tbCsllM350: TbCsllM350 = new TbCsllM350();
  planoContas?: PlanoContas = new PlanoContas();
  cadCcus?: CadCcus = new CadCcus();
  tbIndRelacao?: TbIndRelacao = new TbIndRelacao();
  tbTipoLcto?: TbTipoLcto = new TbTipoLcto();
  movApurReal?: MovApurReal = new MovApurReal();
  movSaldosContabeis?: MovSaldosContabeis = new MovSaldosContabeis();
  cadEcd: CadEcd;

  constructor(movParteACsll?: MovParteACsll | number) {
    if (movParteACsll !== undefined && movParteACsll !== null) {
      if (typeof movParteACsll === 'number') {
        this.id = movParteACsll;
      } else {
        this.id = movParteACsll.id;
        this.data = movParteACsll.data ? new Date(movParteACsll.data) : null;
        this.hist = movParteACsll.hist;
        this.valor = movParteACsll.valor;
        this.tbCsllM350 = movParteACsll.tbCsllM350 || new TbCsllM350();
        this.planoContas = movParteACsll.planoContas || new PlanoContas();
        this.cadCcus = movParteACsll.cadCcus || new CadCcus();
        this.tbIndRelacao = movParteACsll.tbIndRelacao || new TbIndRelacao();
        this.tbTipoLcto = movParteACsll.tbTipoLcto || new TbTipoLcto();
        this.movApurReal = movParteACsll.movApurReal || new MovApurReal();
        this.movSaldosContabeis = movParteACsll.movSaldosContabeis || new MovSaldosContabeis();
        this.cadEcd = movParteACsll.cadEcd || new CadEcd();


      }
    }
  }
}
