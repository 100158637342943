import { FluxoCaixa } from './fluxo_caixa';
import { GrupoFluxoCaixa } from './fluxo_caixa_grupo';

export class FormulaDetalhe {
  id: number;
  operador: String;
  codigoGrupo: String;
  grupoFluxoCaixa: GrupoFluxoCaixa;
  fluxoCaixa: FluxoCaixa; // codigo da linha do grupo que origina a formula

  constructor(formulaDetalhe?: FormulaDetalhe | number) {
    if (formulaDetalhe !== undefined && formulaDetalhe !== null) {
      if (typeof formulaDetalhe === 'number') {
        this.id = formulaDetalhe;
      } else {
        this.id = formulaDetalhe.id;
        this.operador = formulaDetalhe.operador;
        this.codigoGrupo = formulaDetalhe.codigoGrupo;
        this.grupoFluxoCaixa = formulaDetalhe.grupoFluxoCaixa;
        this.fluxoCaixa = formulaDetalhe.fluxoCaixa;
      }
    }
  }
}
