import { Envio } from 'app/centralweb/model/envio.model';
import { Usuario } from 'app/cadastro/model/usuario.model';

export class ComprovantePagamento {
  id: number;
  envio: Envio;
  dtConfirmacao: Date;
  vlPrincipal: number;
  vlMulta: number;
  vlJuros: number;
  vlSaldoRecolher: number;
  usuario: Usuario;

  constructor(comprovantepagamento?: ComprovantePagamento | number) {
    if (comprovantepagamento !== undefined && comprovantepagamento !== null) {
      if (typeof comprovantepagamento === 'number') {
        this.id = comprovantepagamento;
      } else {
        this.id = comprovantepagamento.id;
        this.envio = comprovantepagamento.envio;
        this.dtConfirmacao = comprovantepagamento.dtConfirmacao;
        this.vlPrincipal = comprovantepagamento.vlPrincipal;
        this.vlMulta = comprovantepagamento.vlMulta;
        this.vlJuros = comprovantepagamento.vlJuros;
        this.vlSaldoRecolher = comprovantepagamento.vlSaldoRecolher;
        this.usuario = comprovantepagamento.usuario;
      }
    }
  }
}
