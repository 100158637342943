import { isNullOrUndefined } from "util";

export class TipoEntradaSaida {
  id: number;
  descricao: string;

  constructor(tipoEntradaSaida?: TipoEntradaSaida | number) {
    if (tipoEntradaSaida !== undefined && tipoEntradaSaida !== null) {
      if (typeof tipoEntradaSaida === 'number') {
        this.id = tipoEntradaSaida;
      } else {
        this.id = tipoEntradaSaida.id;
        this.descricao = tipoEntradaSaida.descricao;
      }
    }
  }
}
