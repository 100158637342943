import { Empresa } from 'app/cadastro/model/empresa.model';

export class MovParteB {
  id: number;
  cod: string;
  descricao: string;
  saldoInicial: number;
  natureza: string;
  data: Date;
  empresa: Empresa;

  constructor(movParteB?: MovParteB | number) {
    if (movParteB !== undefined && movParteB !== null) {
      if (typeof movParteB === 'number') {
        this.id = movParteB;
      } else {
        this.id = movParteB.id;
        this.cod = movParteB.cod;
        this.descricao = movParteB.descricao;
        this.saldoInicial = movParteB.saldoInicial;
        this.natureza = movParteB.natureza;
        this.data = movParteB.data;
        this.empresa = movParteB.empresa || new Empresa();
      }
    }
  }
}
