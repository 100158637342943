export class TipoConta {
    id: number;
    descricao: string;

    constructor(tipoConta?: TipoConta | number) {
      if (tipoConta !== undefined && tipoConta !== null) {
        if (typeof tipoConta === 'number') {
          this.id = tipoConta;
        } else {
          this.id = tipoConta.id;
          this.descricao = tipoConta.descricao;
        }
      }
    }
  }
