import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule, Title } from '@angular/platform-browser'
import { CommonModule, registerLocaleData, DatePipe } from '@angular/common'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import localeBR from '@angular/common/locales/pt'

import { AppComponent } from './app.component'
import { AppRoutingModule, appRoutedComponents } from './app.routing'
import { Config } from './app.config'

import { DndModule } from 'ngx-drag-drop'
import { DragulaModule } from 'ng2-dragula'
import { FileDropModule } from 'ngx-file-drop'
import { NgbModule, NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'
import { NgxDropzoneModule } from 'ngx-dropzone'

import { AccordionModule } from 'primeng/accordion'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { ChartModule } from 'primeng/chart'
import { CheckboxModule } from 'primeng/checkbox'
import { ChipModule  } from 'primeng/chip'
import { ConfirmationService } from 'primeng/api'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DialogModule } from 'primeng/dialog'
import { DividerModule } from 'primeng/divider'
import { DropdownModule } from 'primeng/dropdown'
import { EditorModule } from 'primeng/editor'
import { FieldsetModule } from 'primeng/fieldset'
import { FileUploadModule } from 'primeng/fileupload'
import { FullCalendarModule } from 'primeng/fullcalendar'
import { InputMaskModule } from 'primeng/inputmask'
import { InputNumberModule } from 'primeng/inputnumber'
import { KeyFilterModule } from 'primeng/keyfilter'
import { MenubarModule } from 'primeng/menubar'
import { MenuModule } from 'primeng/menu'
import { MessagesModule } from 'primeng/messages'
import { MultiSelectModule } from 'primeng/multiselect'
import { PanelModule } from 'primeng/panel'
import { PickListModule } from 'primeng/picklist'
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton'
import { SelectButtonModule } from 'primeng/selectbutton'
import { SidebarModule } from 'primeng/sidebar'
import { SkeletonModule } from 'primeng/skeleton'
import { StepsModule } from 'primeng/steps'
import { TableModule } from 'primeng/table'
import { TabViewModule } from 'primeng/tabview'
import { ToastModule } from 'primeng/toast'
import { TreeModule } from 'primeng/tree'
import { TreeTableModule } from 'primeng/treetable'

import { HighchartsChartModule } from 'highcharts-angular'
import { CalendarModule as CMAComponent } from 'angular-calendar'
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg'

import { DemoUtilsModule } from './financeiro/calendario/demo-utils/module'
import { I18n, CustomDatepickerI18n } from './centralweb/work-flow/CustomDatepickerI18n'
import { MaterialModule } from './shared/common/material.module'
import { NgbDatePTParserFormatter } from './centralweb/work-flow/NgbDatePTParserFormatter'

import { DateDirective } from 'app/shared/directives/date.directive'
import { DateTimeDirective } from 'app/shared/directives/date-time.directive'
import { FileDirective } from 'app/shared/directives/file.directive'
import { MoneyDirective } from 'app/shared/directives/money.directive'
import { NumbersOnlyDirective } from './shared/directives/numbers-only.directive'

import { CepPipe } from 'app/shared/pipes/cep.pipe'
import { CnpjPipe } from 'app/shared/pipes/cnpj.pipe'
import { CpfPipe } from 'app/shared/pipes/cpf.pipe'
import { MoneyPipe } from 'app/shared/pipes/money.pipe'
import { PluralPipe } from 'app/shared/pipes/plural.pipe'
import { SafeHtmlPipe } from 'app/shared/pipes/safeHtml.pipe'
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe'
import { WeightPipe } from 'app/shared/pipes/weight.pipe'

import { AnexoUploadService } from 'app/shared/services/anexo-upload.service'
import { AuthService } from 'app/shared/services/auth.service'
import { ConfigService } from 'app/shared/services/config.service'
import { CountryService } from 'app/shared/services/country.service'
import { DataTableService } from 'app/shared/components/data-table/data-table.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpService } from 'app/shared/services/http.service'
import { JsogService } from 'app/shared/services/jsog.service'
import { LoginService } from 'app/shared/services/login.service'
import { Md5Service } from 'app/shared/services/md5.service'
import { NavigationMenuService } from 'app/shared/services/navigation-menu.service'
import { PedidoService } from 'app/shared/services/pedido.service'
import { PysocketService } from 'app/shared/services/socket/pysocket.service'
import { ScrollService } from 'app/shared/services/scroll.service'
import { SessionService } from 'app/shared/services/session.service'
import { SidebarService } from 'app/shared/services/sidebar.service'
import { SocketService } from 'app/shared/services/socket.service'
import { TitleService } from 'app/shared/services/title.service'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { UtilService } from 'app/shared/services/util.service'
import { ValidatorsService } from 'app/shared/services/validators.service'

import { AnexoComponent } from 'app/shared/components/anexo/anexo.component'
import { AppDialogModule } from 'app/shared/components/dialog/dialog.component'
import { AutorizacaoDebitoComponent } from './centralweb/autorizacao-debito/autorizacao-debito.component'
import { CalendarComponent } from './shared/components/calendar/calendar/calendar.component'
import { CalendarioComponent } from './financeiro/calendario/calendario.component'
import { CentralAprovacaoComponent } from './centralweb/central-aprovacao/central-aprovacao.component'
import { CfgApiAdmComponent } from './api/configuracoes/cfg-api-adm/cfg-api-adm.component'
import { CfgApiClientComponent } from './api/configuracoes/cfg-api-client/cfg-api-client.component'
import { CfgConciliadoraComponent } from './api/configuracoes/components/cfg-conciliadora/cfg-conciliadora.component'
import { CfgEyeComponent } from './api/configuracoes/components/cfg-eye/cfg-eye.component'
import { CfgFoxbitComponent } from './api/configuracoes/components/cfg-foxbit/cfg-foxbit.component'
import { CfgMateraComponent } from './api/configuracoes/components/cfg-matera/cfg-matera.component'
import { CfgBs2Component } from './api/configuracoes/components/cfg-bs2/cfg-bs2.component'
import { CfgPayLivreComponent } from './api/configuracoes/components/cfg-paylivre/cfg-paylivre.component'
import { CfgAsaasComponent } from './api/configuracoes/components/cfg-asaas/cfg-asaas.component'
import { CfgTrayComponent } from './api/configuracoes/components/cfg-tray/cfg-tray.component'
import { DashBigNumberComponent } from 'app/shared/components/dashboards/components/dash-big-number/dash-big-number.component'
import { DashChangerComponent } from './shared/components/dashboards/components/dash-changer/dash-changer.component'
import { DashFinancialSummaryComponent } from 'app/shared/components/dashboards/dash-financial-summary/dash-financial-summary.component'
import { DashGraphicsComponent } from 'app/shared/components/dashboards/components/dash-graphics/dash-graphics.component'
import { DashMainComponent } from 'app/shared/components/dashboards/dash-main/dash-main.component'
import { DashPurchasesComponent } from 'app/shared/components/dashboards/dash-purchases/dash-purchases.component'
import { DashRmsComponent } from './shared/components/dashboards/dash-rms/dash-rms.component';
import { DashStockListComponent } from './shared/components/dashboards/dash-stock-list/dash-stock-list.component';
import { DashSalesComponent } from 'app/shared/components/dashboards/dash-sales/dash-sales.component'
import { DashTableComponent } from 'app/shared/components/dashboards/components/dash-table/dash-table.component'
import { DataTableComponent } from 'app/shared/components/data-table/data-table.component'
import { DynamicTableComponent } from 'app/shared/components/dynamic-table/dynamic-table.component'
import { EmpresaContaRefComponent } from './cadastro/empresa-conta-ref/empresa-conta-ref.component'
import { FichaEstoqueComponent } from './estoque/ficha-estoque/ficha-estoque.component'
import { FichaEstoqueDetalheComponent } from './estoque/ficha-estoque-detalhe/ficha-estoque-detalhe.component'
import { FluxoAprovComponent } from './shared/components/fluxo-aprov/fluxo-aprov.component'
import { FormMaterialRequestComponent } from './rms/requisicao-material/form-material-request/form-material-request.component'
import { ImageComponent } from 'app/shared/components/image/image.component'
import { ItemRefComponent } from './cadastro/item-ref/item-ref.component'
import { ModalParticipanteComponent } from 'app/shared/components/modal-participante/modal-participante.component'
import { ModalPlanoContasComponent } from 'app/shared/components/modal-plano-contas/modal-plano-contas.component'
import { MonitoramentoComponent } from './api/monitoramento/monitoramento.component'
import { MovimentacaoInternaComponent } from './estoque/movimentacao-interna/movimentacao-interna.component'
import { MultiSelectComponent } from 'app/shared/components/multi-select/multi-select.component'
import { NfeFinEditComponent } from './shared/components/nfe-fin-edit/nfe-fin-edit.component'
import { NotificationComponent } from 'app/shared/components/notification/notification.component'
import { PaisIbgeComponent } from './util/pais-ibge/pais-ibge.component'
import { ParticipanteDialogComponent } from 'app/cadastro/participante-dialog/participante-dialog.component'
import { ParticipanteEmpresaComponent } from './cadastro/participante-empresa/participante-empresa.component'
import { ParticipanteEventosDialogComponent } from './eventos/participante-eventos-dialog/participante-eventos-dialog.component'
import { PedidoEditComponent } from './shared/components/pedido-edit/pedido-edit.component'
import { PeriodSelectComponent } from 'app/shared/components/period-select/period-select.component'
import { RegistroExportacaoComponent } from './fiscal/registro-exportacao/registro-exportacao.component'
import { RegraContabilOrigemComponent } from './cadastro/regra-contabil-origem/regra-contabil-origem.component'
import { ReinfEventosDetalheComponent } from './reinf/eventos-detalhe/eventos-detalhe.component'
import { RelatorioDetalheComponent } from './financeiro/relatorio-detalhe/relatorio-detalhe.component'
import { RelatorioPedidosComponent } from './financeiro/relatorio-pedidos/relatorio-pedidos.component'
import { RemessasComponent } from './financeiro/remessas/remessas.component'
import { SidebarComponent } from 'app/shared/components/sidebar/sidebar.component'
import { SkeletonComponent } from 'app/shared/components/skeleton/skeleton.component'
import { TableComponent } from 'app/shared/components/table/table.component'
import { TipoPagamentoComponent } from './cadastro/tipo-pagamento/tipo-pagamento.component'
import { ToolbarComponent } from './shared/components/toolbar/toolbar.component'
import { UploadComponent } from 'app/shared/components/upload/upload.component'
import { VinculacaoRegraContabilComponent } from './cadastro/vinculacao-regra-contabil/vinculacao-regra-contabil.component'
import { WindowSidebarComponent } from 'app/shared/components/sidebar/window-sidebar/window-sidebar.component'
import { WorkFlowComponent } from './centralweb/work-flow/work-flow.component'
import { WorkFlowProjetoComponent } from './centralweb/work-flow-projeto/work-flow-projeto.component'
@NgModule({
  declarations: [
    CepPipe,
    CnpjPipe,
    CpfPipe,
    DateDirective,
    DateTimeDirective,
    FileDirective,
    MoneyDirective,

    MoneyPipe,
    NumbersOnlyDirective,
    PluralPipe,
    SafeHtmlPipe,
    TimezonePipe,
    WeightPipe,

    AnexoComponent,
    AppComponent,
    appRoutedComponents,
    AutorizacaoDebitoComponent,
    CalendarComponent,
    CalendarioComponent,
    CentralAprovacaoComponent,
    CfgApiAdmComponent,
    CfgApiClientComponent,
    CfgConciliadoraComponent,
    CfgEyeComponent,
    CfgFoxbitComponent,
    CfgMateraComponent,
    CfgBs2Component,
    CfgPayLivreComponent,
    CfgAsaasComponent,
    CfgTrayComponent,
    DashBigNumberComponent,
    DashChangerComponent,
    DashFinancialSummaryComponent,
    DashGraphicsComponent,
    DashMainComponent,
    DashPurchasesComponent,
    DashRmsComponent,
    DashStockListComponent,
    DashSalesComponent,
    DashTableComponent,
    DataTableComponent,
    DynamicTableComponent,
    EmpresaContaRefComponent,
    FichaEstoqueComponent,
    FichaEstoqueDetalheComponent,
    FluxoAprovComponent,
    FormMaterialRequestComponent,
    ImageComponent,
    ItemRefComponent,
    ModalParticipanteComponent,
    ModalPlanoContasComponent,
    MonitoramentoComponent,
    MovimentacaoInternaComponent,
    MultiSelectComponent,
    NfeFinEditComponent,
    NotificationComponent,
    PaisIbgeComponent,
    ParticipanteDialogComponent,
    ParticipanteEmpresaComponent,
    ParticipanteEventosDialogComponent,
    PedidoEditComponent,
    PeriodSelectComponent,
    RegistroExportacaoComponent,
    RegistroExportacaoComponent,
    RegraContabilOrigemComponent,
    ReinfEventosDetalheComponent,
    RelatorioDetalheComponent,
    RelatorioPedidosComponent,
    RemessasComponent,
    SidebarComponent,
    SkeletonComponent,
    TableComponent,
    TipoPagamentoComponent,
    ToolbarComponent,
    UploadComponent,
    VinculacaoRegraContabilComponent,
    WindowSidebarComponent,
    WorkFlowComponent,
    WorkFlowProjetoComponent,
  ],
  imports: [
    AccordionModule,
    AppDialogModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CalendarModule,
    ChartModule,
    CheckboxModule,
    ChipModule,
    CMAComponent.forRoot(),
    CommonModule,
    ConfirmDialogModule,
    DemoUtilsModule,
    DialogModule,
    DividerModule,
    DndModule,
    DragulaModule.forRoot(),
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileDropModule,
    FileUploadModule,
    FlexLayoutModule,
    FormsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    FullCalendarModule,
    HighchartsChartModule,
    HttpClientModule,
    InputMaskModule,
    InputNumberModule,
    KeyFilterModule,
    MaterialModule,
    MenubarModule,
    MenuModule,
    MessagesModule,
    MultiSelectModule,
    NgbModule,
    NgxDropzoneModule,
    PanelModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RouterModule.forChild([{ path: '', component: CalendarioComponent }]),
    SelectButtonModule,
    SidebarModule,
    SkeletonModule,
    StepsModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TreeModule,
    TreeTableModule,
  ],
  providers: [
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    AnexoUploadService,
    AuthService,
    CepPipe,
    CnpjPipe,
    Config,
    ConfigService,
    ConfirmationService,
    CountryService,
    CpfPipe,
    DataTableService,
    DatePipe,
    DblinkedSessionService,
    HttpService,
    JsogService,
    LoginService,
    Md5Service,
    NavigationMenuService,
    PedidoService,
    PysocketService,
    SafeHtmlPipe,
    ScrollService,
    SessionService,
    SidebarService,
    SocketService,
    Title,
    TitleService,
    ToolbarService,
    UtilService,
    ValidatorsService,
  ],
  entryComponents: [
    ParticipanteDialogComponent,
    ParticipanteEventosDialogComponent
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeBR)
  }
}
