import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { HttpService } from '../../shared/services/http.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from '../../shared/services/session.service';
import { UtilService } from '../../shared/services/util.service';
import { ConfigService } from '../../shared/services/config.service';
import { Router } from '@angular/router';
import * as Highcharts from "highcharts/highstock";
import HC_exportData from "highcharts/modules/export-data";
HC_exportData(Highcharts);
declare var $: any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})

export class DashboardVendasComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  pedidosCompra: Array<any> = new Array();
  pedidosNegociacao: Array<any> = new Array();
  pedidosRecusados: Array<any> = new Array();
  pedidosFinalizados: Array<any> = new Array();

  valorAtualCompra: number;
  valorAtualNegocia: number;
  valorAtualRecusa: number;
  valorAtualFinaliza: number;

  private valor: number;

  // grafico nacional e internacional
  graficoProdNac: number;
  graficoProdInt: number;
  graficoServNac: number;
  graficoServInt: number;

  pedidosMesProd: Array<any> = new Array();
  pedidosMesServ: Array<any> = new Array();

  itensTopFive: Array<any> = new Array();
  itensTopFiveDetal: Array<any> = new Array();
  empresasTopFive: Array<any> = new Array();
  modalMaisInfo: boolean;
  tituloModal: String;
  empTopFiveDetal: Array<any> = new Array();
  tituloModalEmp: String;
  modalMaisInfoEmp: boolean;

  contadorLinha: number = 0;

  //export excel
  colsitensTopFiveDetal: any[];
  colsempTopFiveDetal: any[];
  dataIni: Date;
  dataFin: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  defaultDate: Date;


  constructor(
    private titleService: TitleService,
    private httpService: HttpService,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private utilService: UtilService,
    private toolbarService: ToolbarService, private router: Router) {

  }


  public chartData: any;
  public chartOptions: any = {
    legend: {
      // position: 'bottom',
      labels: {
        fontColor: '#9B9B9B',
        fontFamily: 'font-family: "Lato", sans-serif !important;'
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += ((Math.round(tooltipItem.yLabel * 100)) / 100).toLocaleString('pt-BR') + '';
          return label;
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontColor: '#9B9B9B'
        }
      }],
      yAxes: [{
        display: false,
        gridLines: {
          display: false
        },
        ticks: {
          fontColor: '#9B9B9B'
        }
      }]
    },
    responsive: false,
    maintainAspectRatio: false
  };
  /*public chartColors = [{
    backgroundColor: [ '#36A2EB' ]
  }, {
    backgroundColor: [ '#FFCE56' ]
  }];*/

  public chartColors = [{
    backgroundColor: 'rgba(54, 162, 235, 1)'
  }, {
    backgroundColor: 'rgba(255, 206, 86, 1)'
  }];

  // Chart 6
  public chartProd: any;
  public chartServ: any;

  chartPizzaProdOptions = {
    tooltips: {
      mode: 'label',
      callbacks: {
        label: function (tooltipItem, data) {
          const indice = tooltipItem.index;
          return [data.labels[indice] + ': R$ ' +
            (Math.round((data.datasets[0].data[indice] + 0.00001) * 100) / 100).toLocaleString('pt-BR') + ' ('
            + ((Math.round((data.datasets[0].data[indice]) * 100)) /
              (Math.round((data.datasets[0].data[0] + data.datasets[0].data[1]) * 100) / 100)).toFixed(2) + '%)'];
        }
      }
    },
    scales: {
      xAxes: [{
        display: false,
      }],
      yAxes: [{
        display: false
      }]
    },
    legend: {
      position: 'right',
      labels: {
        boxWidth: 12,
        fontColor: '#9B9B9B',
        fontFamily: 'font-family: "Lato", sans-serif !important;'
      }
    },
    responsive: false,
    maintainAspectRatio: false
  };

  chartPizzaServOptions = {
    tooltips: {
      mode: 'label',
      callbacks: {
        label: function (tooltipItem, data) {
          const indice = tooltipItem.index;
          return [data.labels[indice] + ': R$ ' +
            (Math.round((data.datasets[0].data[indice] + 0.00001) * 100) / 100).toLocaleString('pt-BR') + ' ('
            + ((Math.round((data.datasets[0].data[indice]) * 100)) /
              (Math.round((data.datasets[0].data[0] + data.datasets[0].data[1]) * 100) / 100)).toFixed(2) + '%)'];
        }
      }
    },
    scales: {
      xAxes: [{
        display: false,
      }],
      yAxes: [{
        display: false
      }]
    },
    legend: {
      position: 'right',
      labels: {
        boxWidth: 12,
        fontColor: '#9B9B9B',
        fontFamily: 'font-family: "Lato", sans-serif !important;'
      }
    },
    responsive: false,
    maintainAspectRatio: false
  };

  colorPizzaProd = [{
    backgroundColor: ['#FF6384', '#36A2EB']
  }];


  colorPizzaServ = [{
    backgroundColor: ['#00BFA5', '#FFCE56']
  }];

  // Resize charts
  // @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  ngOnInit(): void {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.titleService.title = 'Dashboard Vendas';
    setTimeout(() => {
      // const resizeCharts = () => this.charts.forEach(chart => chart.chart.resize());

      // Initial resize
      // resizeCharts();

      // For performance reasons resize charts on delayed resize event
      // this.layoutService.on('resize.dashboard-2', resizeCharts);
    });
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.inicializar(); });

    this.contadorLinha = 0;

    this.colsitensTopFiveDetal = [
      { field: 'codItem', header: 'CÓD. ITEM' },
      { field: 'descrItem', header: 'DESC. ITEM' },
      { field: 'quantidade', header: 'QUANTIDADE' },
      { field: 'valorTotal', header: 'VALOR TOTAL' },
      { field: 'porcentagem', header: 'PORC. (%)' }
    ];

    this.colsempTopFiveDetal = [
      { field: 'codEmpresa', header: 'CÓD. CLIENTE' },
      { field: 'nomeEmpresa', header: 'NOME' },
      { field: 'quantidade', header: 'QUANTIDADE' },
      { field: 'valorTotal', header: 'VALOR TOTAL' },
      { field: 'porcentagem', header: 'PORC. (%)' }
    ];
  }

  loadDonutChart(container, value, firstPalet = false) {
    new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: "pie",
        renderTo: container,
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormatter: function () {
          if (this.y >= 1e6) {
            return `${(this.y / 1e6).toFixed(3)}M - (${this.percentage.toFixed(
              0
            )}%)`;
          } else if (this.y >= 1e3) {
            return `${(this.y / 1e3).toFixed(3)}K - (${this.percentage.toFixed(
              0
            )}%)`;
          }
          return `${this.y} - (${this.percentage.toFixed(0)}%)`;
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          colors: firstPalet ? ['#FF6384', '#36A2EB'] : ['#00BFA5', '#FFCE56'],
        },
      },
      series: [
        {
          name: "VL_AJUSTE",
          type: "pie",
          data: value,
        },
      ],
    });
  }

  loadColumnChart(container, value) {
    new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: 'column',
        renderTo: container,
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      xAxis: {
        categories: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        crosshair: true
      },
      tooltip: {
        pointFormatter: function () {
          if (this.y >= 1e6) {
            return `${(this.y / 1e6).toFixed(3)}M`;
          } else if (this.y >= 1e3) {
            return `${(this.y / 1e3).toFixed(3)}K `;
          }
          return `${this.y}`;
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          pointWidth: 15
        }
      }, colors: [
        '#49a3eb',
        '#f7ce56',
      ],
      series: value
    });
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  inicializar() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

      this.modalMaisInfo = false;
      this.modalMaisInfoEmp = false;

      this.chartProd = [{
        data: [50, 0],
        borderWidth: 1
      }];
      this.chartServ = [{
        data: [0, 50],
        borderWidth: 1
      }];

      const mes = this.dblinkedSessionService.periodo.month.value > 8 ?
        this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
      const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
      this.defaultDate = new Date(dt);

      let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
      // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
      lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
      this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
      this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

      this.httpService.wait();
      this.httpService.get('/custom/pedido/get-pedidos(' + this.dblinkedSessionService.empresa.id +
        ',' + 'pv' + ',' + this.dataIniSearch + ','
        + this.dataFinSearch + ',' + this.sessionService.loggedUser.id + ')')
        .subscribe(pedidos => { this.carregarTotalPedidos(pedidos.data); this.carregarGraficoNacInt(pedidos.data); },
          error => this.httpService.handleError(error, () => this.inicializar()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/pedido/get-pedidos-geral-dashboard(' + this.dblinkedSessionService.empresa.id +
        ',' + '2' + ',' + this.dblinkedSessionService.periodo.year.value + ')')
        .subscribe(pedidos => { this.carregarTotalPedidosMes(pedidos); },
          error => this.httpService.handleError(error, () => this.inicializar()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/pedido/get-top-five-itens-dashboard(' + this.dblinkedSessionService.empresa.id +
        ',' + '2' + ',' + this.dblinkedSessionService.periodo.year.value + ')')
        .subscribe(pedidos => { this.carregarTopFiveIt(pedidos); },
          error => this.httpService.handleError(error, () => this.inicializar()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/pedido/get-top-five-empresa-dashboard(' + this.dblinkedSessionService.empresa.id +
        ',' + '2' + ',' + this.dblinkedSessionService.periodo.year.value + ')')
        .subscribe(pedidos => { this.carregarTopFiveEmp(pedidos); },
          error => this.httpService.handleError(error, () => this.inicializar()),
          () => this.httpService.done());

    }
  }

  /* 1 - Venda Produto
  2 - Venda Serviço
  3 - Aquisição Produto
  4 - Aquisição Serviço*/
  carregarTotalPedidosMes(value: Array<any>) {
    this.pedidosMesProd = new Array();
    this.pedidosMesServ = new Array();
    let ctrlMes = 1;
    let vlrProdAnteriror = 0.00;
    let vlrServAnteriror = 0.00;

    value.forEach(element => {
      if (element.pedidoStatus === 'Finalizado') {
        // verificando o mes corrente
        if (element.mes === ctrlMes) {
          // atribuindo mes corrente
          ctrlMes = element.mes;
          // validando o tipo de pedido
          if (element.idTipoPedido === 2) { // 'Venda de Serviço'
            vlrServAnteriror = vlrServAnteriror + this.utilService.parseMoney(element.valorTotal);
          } else if (element.idTipoPedido === 1) { // 'Venda de Produto'
            vlrProdAnteriror = vlrProdAnteriror + this.utilService.parseMoney(element.valorTotal);
          }
        } else {

          // passando os valores do mes anterior.
          this.pedidosMesProd.push(vlrProdAnteriror);
          this.pedidosMesServ.push(vlrServAnteriror);

          // troca de mes
          ctrlMes++;
          // validando meses sem movimentação
          if (element.mes > ctrlMes) {
            for (let index = ctrlMes; index < element.mes; index++) {
              this.pedidosMesProd.push(0.00);
              this.pedidosMesServ.push(0.00);
            }
          }
          // atribuindo mes corrente
          ctrlMes = element.mes;

          // zerando variáveis
          vlrProdAnteriror = 0.00;
          vlrServAnteriror = 0.00;

          // setando os valores correntes
          if (element.idTipoPedido === 2) { // 'Venda de Serviço'
            vlrServAnteriror = this.utilService.parseMoney(element.valorTotal);
          } else if (element.idTipoPedido === 1) { // 'Venda de Produto'
            vlrProdAnteriror = this.utilService.parseMoney(element.valorTotal);
          }
        }
      }
    });
    // passando os valores do ultimo mes.
    this.pedidosMesProd.push(vlrProdAnteriror);
    this.pedidosMesServ.push(vlrServAnteriror);

    // alimentando o gráfico
    this.chartData = [{
      name: 'Produtos',
      data: this.pedidosMesProd,
    }, {
      name: 'Serviços',
      data: this.pedidosMesServ,
    }];

    this.loadColumnChart('chart_3', this.chartData)
  }

  carregarGraficoNacInt(value: Array<any>) {
    this.graficoProdNac = 0.00;
    this.graficoProdInt = 0.00;
    this.graficoServNac = 0.00;
    this.graficoServInt = 0.00;

    if (value !== undefined && value !== null) {
      value.forEach(element => {
        if (element.pedidoStatus === 'Finalizado') {
          if (element.pedidoTipo === 'Venda de Produto') {
            if (element.codigo.indexOf('I') >= 0) {
              this.valor = this.utilService.parseMoney(element.valorTotal);
              this.graficoProdInt = this.graficoProdInt + this.valor;
            } else {
              this.valor = this.utilService.parseMoney(element.valorTotal);
              this.graficoProdNac = this.graficoProdNac + this.valor;
            }
          } else if (element.pedidoTipo === 'Venda de Serviço') {
            if (element.codigo.indexOf('I') >= 0) {
              this.valor = this.utilService.parseMoney(element.valorTotal);
              this.graficoServInt = this.graficoServInt + this.valor;
            } else {
              this.valor = this.utilService.parseMoney(element.valorTotal);
              this.graficoServNac = this.graficoServNac + this.valor;
            }
          }
        }
      });
    }

    this.chartProd = [
      { name: "Nacional", y: this.graficoProdNac },
      { name: "Internacional", y: this.graficoProdInt },
    ];
    this.chartServ = [
      { name: "Nacional", y: this.graficoServNac },
      { name: "Internacional", y: this.graficoServInt },
    ];
    this.loadDonutChart('chart_1', this.chartProd, true)
    this.loadDonutChart('chart_2', this.chartServ)
  }

  carregarTotalPedidos(value: Array<any>) {
    this.pedidosCompra = new Array();
    this.pedidosNegociacao = new Array();
    this.pedidosRecusados = new Array();
    this.pedidosFinalizados = new Array();

    this.valorAtualCompra = 0.00;
    this.valorAtualNegocia = 0.00;
    this.valorAtualRecusa = 0.00;
    this.valorAtualFinaliza = 0.00;

    value.forEach(element => {
      if (element.pedidoTipo === 'Venda de Produto' || element.pedidoTipo === 'Venda de Serviço') {
        this.valor = this.utilService.parseMoney(element.valorTotal);
        if (element.pedidoStatus === 'Em negociação'
          || element.pedidoStatus === 'Aguardando Aprovação' || element.pedidoStatus === 'Pré-Aprovado') {
          this.pedidosNegociacao.push(element);
          this.valor = this.utilService.parseMoney(element.valorTotal);
          this.valorAtualNegocia = this.valorAtualNegocia + this.valor;
        } else if (element.pedidoStatus === 'Aprovado' || element.pedidoStatus === 'Venda' || element.pedidoStatus === 'Compra'
          || element.pedidoStatus === 'Draft') {
          this.pedidosCompra.push(element);
          this.valor = this.utilService.parseMoney(element.valorTotal);
          this.valorAtualCompra = this.valorAtualCompra + this.valor;
        } else if (element.pedidoStatus === 'Recusado' || element.pedidoStatus === 'Excluído' || element.pedidoStatus === 'Cancelado') {
          this.pedidosRecusados.push(element);
          this.valor = this.utilService.parseMoney(element.valorTotal);
          this.valorAtualRecusa = this.valorAtualRecusa + this.valor;
        } else if (element.pedidoStatus === 'Finalizado') {
          this.pedidosFinalizados.push(element);
          this.valor = this.utilService.parseMoney(element.valorTotal);
          this.valorAtualFinaliza = this.valorAtualFinaliza + this.valor;
        }
      }
    });

  }

  carregarTopFiveIt(value: Array<any>) {
    this.itensTopFive = new Array();
    value.forEach(it => {
      if (it.valorUnit) {
        it.valorUnit = this.utilService.parseDecimalString4(it.valorUnit);
      }
      if (it.valorTotal) {
        it.valorTotal = this.utilService.parseMoneyString(it.valorTotal);
      }
      this.itensTopFive.push(it);
    });
  }

  carregarTopFiveEmp(value: Array<any>) {
    this.empresasTopFive = new Array();
    value.forEach(emp => {
      if (emp.valorTotal) {
        emp.valorTotal = this.utilService.parseMoneyString(emp.valorTotal);
      }
      this.empresasTopFive.push(emp);
    });
  }

  detalharTopFiveItem() {
    this.modalMaisInfo = true;
    this.tituloModal = 'Itens mais vendidos no ano de ' + this.dblinkedSessionService.periodo.year.value;

    this.itensTopFiveDetal = new Array();
    this.httpService.wait();
    this.httpService.get('/custom/pedido/get-all-itens-dashboard(' + this.dblinkedSessionService.empresa.id +
      ',' + '2' + ',' + this.dblinkedSessionService.periodo.year.value + ')')
      .subscribe(itens => {
        this.itensTopFiveDetal = itens;

        if (this.itensTopFiveDetal !== null || this.itensTopFiveDetal !== undefined) {
          this.contadorLinha = this.itensTopFiveDetal.length;
        }
        else {
          this.contadorLinha = 0;
        }

      }, error => this.httpService.handleError(error, () => this.inicializar()),
        () => this.httpService.done());

  }

  detalharTopFiveEmp() {
    this.modalMaisInfoEmp = true;
    this.tituloModalEmp = 'Principais Clientes no ano de ' + this.dblinkedSessionService.periodo.year.value;

    this.empTopFiveDetal = new Array();
    this.httpService.wait();
    this.httpService.get('/custom/pedido/get-all-empresa-dashboard(' + this.dblinkedSessionService.empresa.id +
      ',' + '2' + ',' + this.dblinkedSessionService.periodo.year.value + ')')
      .subscribe(emps => {
        this.empTopFiveDetal = emps;

        if (this.empTopFiveDetal !== null || this.empTopFiveDetal !== undefined) {
          this.contadorLinha = this.empTopFiveDetal.length;
        }
        else {
          this.contadorLinha = 0;
        }

      }, error => this.httpService.handleError(error, () => this.inicializar()),
        () => this.httpService.done());

  }

  resetModal() {
    this.modalMaisInfo = false;
    this.modalMaisInfoEmp = false;
  }

  formatarDataExtenso() {
    const dt = new Date();
    const month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const week = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    const semana = week[dt.getDay()];
    const mes = month[dt.getMonth()];
    return 'Hoje é ' + semana + ', ' + dt.getDate() + ' de ' + mes + ' de ' + dt.getFullYear() + '.';
  }

  atualizaOne() {
    this.chartData = [{
      label: 'Produtos',
      data: [24, 92, 77, 90, 91, 78, 28, 49, 23, 81, 15, 0],
      borderWidth: 0
    }, {
      label: 'Servicos',
      data: [38, 34, 48, 3, 5, 21, 27, 4, 33, 40, 46, 47],
      borderWidth: 0
    }];
    this.httpService.wait();
    this.httpService.get('/custom/pedido/get-pedidos-geral-dashboard(' + this.dblinkedSessionService.empresa.id +
      ',' + '2' + ',' + this.dblinkedSessionService.periodo.year.value + ')')
      .subscribe(pedidos => { this.carregarTotalPedidosMes(pedidos); },
        error => this.httpService.handleError(error, () => this.inicializar()),
        () => this.httpService.done());
  }

  atualizaTwo() {
    this.chartProd = [{
      data: [50, 0],
      borderWidth: 1
    }];
    this.chartServ = [{
      data: [0, 50],
      borderWidth: 1
    }];

    const mes = this.dblinkedSessionService.periodo.month.value > 8 ?
      this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
    const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
    this.defaultDate = new Date(dt);

    let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
    // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
    lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
    this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

    this.httpService.wait();
    this.httpService.get('/custom/pedido/get-pedidos(' + this.dblinkedSessionService.empresa.id +
      ',' + 'pc' + ',' + this.dataIniSearch + ','
      + this.dataFinSearch + ',' + this.sessionService.loggedUser.id + ')')
      .subscribe(pedidos => { this.carregarTotalPedidos(pedidos.data); this.carregarGraficoNacInt(pedidos.data); },
        error => this.httpService.handleError(error, () => this.inicializar()),
        () => this.httpService.done());
  }

  abrirPagina() {
    this.router.navigate(['./pedido-venda/pedido']);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  onFilter(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }
}

