import { isNullOrUndefined } from "util";
import {GetNetPagamentoStatus} from 'app/getnet/model/getnet-pagamento-status.model'
import {GetNetPagamento} from 'app/getnet/model/getnet-pagamento-cartao-credito.model'


export class GetNetPagamentoParcela{

  id                : number;
  getNetPagamento   : GetNetPagamento;
  getNetPagamentoId : number;
  getNetPagamentoStatus : GetNetPagamentoStatus;
  getNetPagamentoStatusId : number;
  pagarEm : Date;
  paymentId : string;
  parcelaNum : number;
  parcelaTotal : number;
  valorTotal   : number;
  valorPago    : number;
  valorEstorno : number;

  constructor(getNetPagamentoParcela?: GetNetPagamentoParcela | number) {
    if (!isNullOrUndefined(getNetPagamentoParcela)) {
      if (typeof getNetPagamentoParcela === 'number') {
        this.id = getNetPagamentoParcela;
      } else {
        this.id             = getNetPagamentoParcela.id;
        this.getNetPagamento = getNetPagamentoParcela.getNetPagamento;
        this.getNetPagamentoId = getNetPagamentoParcela.getNetPagamentoId;
        this.getNetPagamentoStatus = getNetPagamentoParcela.getNetPagamentoStatus;
        this.getNetPagamentoStatusId = getNetPagamentoParcela.getNetPagamentoStatusId;
        this.pagarEm  = getNetPagamentoParcela.pagarEm;
        this.paymentId = getNetPagamentoParcela.paymentId;
        this.parcelaNum = getNetPagamentoParcela.parcelaNum;
        this.parcelaTotal = getNetPagamentoParcela.parcelaTotal;
        this.valorTotal   = getNetPagamentoParcela.valorTotal;
        this.valorPago    = getNetPagamentoParcela.valorPago;
        this.valorEstorno = getNetPagamentoParcela.valorEstorno;


      }
    }
  }

}