// import { Component, ViewChild, ElementRef } from '@angular/core';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
// import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
// import { SelectItem } from 'app/shared/common/select-item.interface';
// import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
// import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
// import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
// import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService, SelectItem } from 'primeng/api';
// import { isNullOrUndefined } from 'util';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'certificados-digitais',
  templateUrl: './certificados-digitais.component.html',
  styleUrls: ['./certificados-digitais.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CertificadosDigitaisComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  msgs: Message[] = [];
  titlePrincipal: string;
  subtitle: string;
  modalNew: boolean = false;
  modalEdit: boolean = false;
  newCodigo: string;
  newDescricao: string;
  editCodigo: string;
  editDescricao: string;
  certificadoId: number;
  nomeFantasia: string;
  areaDescricao: string;
  listaCertificados: Array<any>;
  projetosLdb: SelectItem[];
  listaUsuariosDisponiveis: Array<any>;
  listaUsuariosVinculados: Array<any>;

  dataExpiracao: Date;
  oldDataExpiracao: Date;
  password: string;
  oldPassword: string;
  diasAviso: number;
  oldDiasAviso: number;
  file: File;
  validated_file: boolean;
  input_file: any;


  //export excel
  colslistaCertificados: any[];

  constructor(
    // private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    //     private utilService: UtilService,
    private sessionService: SessionService,
    //     private modalService: NgbModal,
    private httpClient: HttpClient,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }


  ngOnInit(): void {

    this.titlePrincipal = 'CERTIFICADOS DIGITAIS';
    this.subtitle = 'CONFIGURAÇÕES DE PRAZOS DE VENCIMENTO E NOTIFICAÇÕES';

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.toolbarEnable();
        this.loadCertificados();
        this.dataExpiracao = null;
        this.password = null;
        this.diasAviso = null;
        this.listaUsuariosDisponiveis = null;
        this.listaUsuariosVinculados = null;
        this.validated_file = false;
      }
    });

    this.colslistaCertificados = [
      { field: 'nomeFantasia', header: 'NOME FANTASIA' },
      { field: 'dataExpiracao', header: 'DATA EXPIRAÇÃO' },
      { field: 'dataModificacao', header: 'DATA ÚLTIMA MODIFICAÇÃO' }
    ];
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  loadUsuariosRelac() {
    if ((this.certificadoId != null) && (this.certificadoId != undefined)) {
      let dados = {
        certificadoId: this.certificadoId,
      }

      this.httpService.wait();
      this.httpService.post('/custom/fiscal/get-usuarios-relac', dados)
        .subscribe(
          res => {
            this.listaUsuariosDisponiveis = new Array();
            this.listaUsuariosVinculados = new Array();
            let resultVinc = res["result_vinculados"];
            for (let line of resultVinc) {
              this.listaUsuariosVinculados.push({ nome: line[1], sobrenome: line[2],
                email: line[3], id: line[0] })
            }
            let resultDisp = res["result_disponiveis"];
            for (let line of resultDisp) {
              this.listaUsuariosDisponiveis.push({ nome: line[1], sobrenome: line[2],
                email: line[3], id: line[0] })
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  salvarVinculacaoUsuarios() {
    if ((!isNullOrUndefined(this.listaUsuariosDisponiveis))
      && (!isNullOrUndefined(this.listaUsuariosVinculados))
      && (!isNullOrUndefined(this.certificadoId))) {

      /* Criar as listas contendo os IDs dos registros disponíveis e vinculados */
      var listaDisp: Array<any> = new Array();
      var listaVinc: Array<any> = new Array();
      for (let line of this.listaUsuariosDisponiveis) {
        listaDisp.push(line.id);
      }
      for (let line of this.listaUsuariosVinculados) {
        listaVinc.push(line.id);
      }

      let dados = {
        dadosDisponiveis: listaDisp,
        dadosVinculados: listaVinc,
        empresaId: this.dblinkedSessionService.empresa.id.toString(),
        certificadoId: this.certificadoId
      }

      this.httpService.wait();
      this.httpService.post('/custom/fiscal/salvar-vinculacao-certificado-usuario', dados)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.httpService.done();

            /* Após o término desse método, atualizar os dados de data de vencimento,
            senha e dias para o disparo de aviso, nesta ordem. */
            setTimeout(() => {
              this.atualizarDados();
            }, 500);

            /* Finalmente, atualizar os dados dos certificados e seus
            usuários relacionados. */
            setTimeout(() => {
              this.loadCertificados();
              this.loadUsuariosRelac();
            }, 500);

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    } else {

      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Aviso:', detail: 'Favor selecionar um Certificado Digital acima.' });
    }
  }

  /* Função que atualiza a senha, a data de expiração e o número de dias
  para emissão de um aviso em um Certificado Digital */
  atualizarDados() {

    /* Não atualizar a senha, caso nenhum valor seja fornecido */
    if (!((!isNullOrUndefined(this.password))
      && (this.password.length > 0))) {
      this.password = null;
    }

    /* Não atualizar a data de expiração, caso nenhum valor seja fornecido */
    if (!((!isNullOrUndefined(this.dataExpiracao))
      && (this.dataExpiracao.toString().length > 0))) {
      this.dataExpiracao = null;
    }

    /* Não atualizar o número de dias para um aviso, caso nenhum valor seja fornecido */
    if (!((!isNullOrUndefined(this.diasAviso))
      && (this.diasAviso.toString().length > 0))) {
      this.diasAviso = null;
    }

    let dados = {
      password: this.password,
      dataExpiracao: this.dataExpiracao,
      diasAviso: this.diasAviso,
      certificadoId: this.certificadoId
    }

    console.log(dados);

    this.httpService.wait();
    this.httpService.post('/custom/fiscal/atualizar-dados-certificado-digital', dados)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          console.log(res);

          // Se o retorno foi bem-sucedido, atualizar as variáveis
          if (!res.error) {
            if (this.password != null) {
              this.oldPassword = this.password;
            } else {
              this.password = this.oldPassword;
            }

            if (this.dataExpiracao != null) {
              this.oldDataExpiracao = this.dataExpiracao;
            } else {
              this.dataExpiracao = this.oldDataExpiracao;
            }

            if (this.diasAviso != null) {
              this.oldDiasAviso = this.diasAviso;
            } else {
              this.diasAviso = this.oldDiasAviso;
            }

          }
          this.httpService.done();

        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

    // Se tiver um arquivo de Certificado Digital, fazer o seu upload
    this.loadDadosCertificado();
  }


  fileChange() {

    this.file = this.fileInput.nativeElement.files[0];
    if (this.file.name.substr(this.file.name.length - 4) != '.pfx') {
      this.validated_file = false;
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Arquivo inválido. Favor importar um arquivo .PFX' });
    } else {
      this.validated_file = true;
    }

  }

  fileSanitize(file: string) {
    return file.replace(/[^a-zA-Z0-9_-]/g, '').toLowerCase();
  }

  loadDadosCertificado() {
    let id = this.dblinkedSessionService.empresa.id;
    if (id !== null || this.dblinkedSessionService.hasEmpresaSelected) {
      const url = id !== null ?
        '/custom/empresa/' + id + '/certificate' :
        '/custom/empresa/cnpj(' + this.dblinkedSessionService.empresa.cnpj + ')/certificate';

      this.httpService.wait();
      this.httpService.get(url)
        .subscribe(dadosCertificado => {

          /* Com os dados retornados contendo algumas informações sobre um
          Certificado Digital, realizar seu upload na pasta destino */
          this.fileUpload(dadosCertificado);
          this.httpService.done();
        },
          error => {
            () => this.httpService.done();
          })
    }
  }

  fileUpload(dadosCertificado) {
    var nomeFantasia = this.dblinkedSessionService.empresa.nomeFantasia;
    var razaoSocial = this.dblinkedSessionService.empresa.razaoSocial;
    var empresaId = this.dblinkedSessionService.empresa.id;

    if ((this.validated_file === true) && (dadosCertificado != null)
      && (dadosCertificado["certificateFound"] == true)) {

      const data: FormData = new FormData();
      data.append('file', this.file);
      data.append('type', 'pfx');
      data.append('replace', 'true');
      data.append('certificadoId', this.certificadoId.toString());
      data.append('destination', '/home/ec2-user/certificates/' + empresaId + '.' +
        this.fileSanitize(nomeFantasia || razaoSocial) + '.pfx');

      this.httpService.wait();
      this.httpService.post('/custom/upload', data)
        .subscribe(result => console.log("Result = " + result),
          error => this.httpService.handleError(error, () => this.fileUpload(dadosCertificado)),
          () => this.httpService.done());
    } else {
      console.log("Não foi possível realizar o upload de um arquivo .PFX");
    }
  }

  onCertificadoRowSelect(row) {
    this.certificadoId = row.id;
    this.nomeFantasia = row.nomeFantasia;
    this.dataExpiracao = new Date(row.dataExpiracao.concat("T12:00:00"));
    this.oldDataExpiracao = new Date(row.dataExpiracao.concat("T12:00:00"));
    this.password = row.senha;
    this.oldPassword = row.senha;
    this.diasAviso = row.diasDisparoAviso;
    this.oldDiasAviso = row.diasDisparoAviso;

    this.loadUsuariosRelac();
  }

  loadCertificados() {
    let dados = {
      Authorization: this.configService.bearerApi,
      empresaid: this.dblinkedSessionService.empresa.id.toString()
    }
    this.httpService.wait();
    this.httpService.post('/custom/fiscal/get-certificados', dados)
      .subscribe(
        res => {
          this.listaCertificados = new Array();
          let result = res["result"];
          for (let line of result) {
            this.listaCertificados.push({
              id: line[0], nomeFantasia: line[1], dataExpiracao: line[2],
              dataModificacao: line[3], senha: line[4], diasDisparoAviso: line[5]
            });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  // Função que envia um e-mail notificando sobre o Vencimento de um Certificado Digital
  dispararEmailAviso(row) {

    // Inicialmente, checar se o envelope selecionado é da mesma linha destacada pelo usuário.
    if (row.id == this.certificadoId) {

      // Depois, verificar se um Certificado Digital foi selecionado e se há usuários vinculados.
      if ((!isNullOrUndefined(this.listaUsuariosVinculados))
        && (this.listaUsuariosVinculados.toString().length > 0)
        && (!isNullOrUndefined(this.certificadoId))) {

        /* Criar a lista contendo os e-mails que devem receber uma mensagem referente
        ao vencimento de um Certificado Digital */
        var listaEmails: Array<any> = new Array();
        var listaNomes: Array<any> = new Array();
        for (let line of this.listaUsuariosVinculados) {
          listaEmails.push(line.email);
          listaNomes.push(line.nome + " " + line.sobrenome);
        }

        let dados = {
          listaNomes: listaNomes,
          listaEmails: listaEmails,
          certificadoId: this.certificadoId,
          nomeFantasia: this.nomeFantasia,
          dataExpiracao: this.dataExpiracao
        }

        this.httpService.wait();
        this.httpService.post('/custom/fiscal/enviar-email-aviso', dados)
          .subscribe(
            res => {
              this.exibirMensagem(res);
              this.httpService.done();
            },
            err => {
              this.exibirMensagem(err);
              console.log(err);
              this.httpService.done();
            }
          );
      } else {

        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Aviso:', detail: 'Não há usuários vinculados ou nenhum certificado foi selecionado.' });
      }
    }
  }

  hideMessage() {

  }

  toolbarEnable() {
    //     const todos = this.buttons.slice();
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('add');
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('remove');
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  //Show/hide cards
  isCardTable: boolean = true;
  isCardVinculacao: boolean = true;

  currentStateTable = 'initial';
  currentStateVinculacao = 'initial';

  ShowHIdeCardTable() {
    this.isCardTable = !this.isCardTable;
  }

  ShowHIdeCardVinculacao() {
    this.isCardVinculacao = !this.isCardVinculacao;
  }

  changeStateTable() {
    this.currentStateTable = this.currentStateTable === 'initial' ? 'final' : 'initial';
  }

  changeStateVinculacao() {
    this.currentStateVinculacao = this.currentStateVinculacao === 'initial' ? 'final' : 'initial';
  }

}
