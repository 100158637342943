/*INICIO IMPORTAÇÃO DE BIBLIOTECAS*/
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from '../../shared/services/session.service';
import { ConfigService } from '../../shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as Highcharts from 'highcharts/highstock';
import HC_exportData from 'highcharts/modules/export-data';
import { Message, MessageService, SelectItem } from 'primeng/api';
import exportExcel from 'app/util/processing/exportExcel';

HC_exportData(Highcharts);
declare var $: any;
/*FIM IMPORTAÇÃO DE BIBLIOTECAS*/

@Component({
  selector: 'app-dashboard2',
  templateUrl: './dashboard2.component.html',
  styleUrls: ['./dashboard2.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
/*FIM DECLARAÇÃO COMPONENTE*/
export class DashboardEstoque2Component implements OnInit, OnDestroy {

  dtInicial: Date;
  dtFinal: Date;
  defaultDate: Date;
  DefInitDate: Date;
  DefFinalDate: Date;
  liglobal: Array<any>;
  pt_BR: any;
  modalCommentarios: boolean;
  rowComments: Array<any>;
  criacaoComentario: boolean;
  edicaoComentario: boolean;
  comentario: string;
  rowSelected: any;
  idComentario: number;
  msgs: Message[] = null;
  idMascara: number;
  listMascaras: Array<any>;
  liAlmoxarifadoId2: Array<number> = new Array()
  liAlmoxarifadoId: Array<number> = new Array()
  liItemId: Array<number> = new Array()
  filiais: Array<number> = new Array()

  columnsModelAdendosPosicaoEstoque = [];
  columnsModelPosicaoEstoque = [];
  columnsModelEstoqueNegativo = [];
  columnsModelResumoPorMovimento = [];
  columnsModelTipoDoItem = [];
  columnsModelMovimentacaoDeEstoque = [];
  columnsModelResumoPorItem = [];
  columnsModelResumoPorNF = [];
  columnsModelRelacaoDeItens = [];

  /* Dropdown and Table*/
  sdoAlmox: Array<any> = new Array()
  sdoGrpEstoq: Array<any> = new Array()
  sdoTipoItem: Array<any> = new Array()
  sdoNeg: Array<any> = new Array()
  posEstoque: Array<any> = new Array()
  posEstoqueClone: Array<any> = new Array()
  listAlmoxarifado: SelectItem[] = []
  movEs: Array<any> = new Array()
  movTipoItem: Array<any> = new Array()
  movEstoque: Array<any> = new Array()
  movItem: Array<any> = new Array()
  nfPart: Array<any> = new Array()
  relacItemCusto: Array<any> = new Array()
  evolucaoQtde: any = {}
  evolucaoTipoItem: any = {}
  evolucaoSaldo: any = {}
  top5itens: any = {}
  top5itensCusto: any = {}
  valoresMom: any = {}
  listItem: SelectItem[] = []

  //Totais
  totalQtdeSdoAlmox: number = 0
  totalValorSdoAlmox: number = 0
  totalValorSdoInit: number = 0
  totalQtdeItensNeg: number = 0
  totalValorItensNeg: number = 0
  totalQtdeMov: number = 0
  totalValorMov: number = 0
  totalQtdeMovEntrada: number = 0
  totalValorMovEntrada: number = 0
  totalQtdeMovSaida: number = 0
  totalValorMovSaida: number = 0

  /*Variaveis*/
  statusAlmoxarifado: boolean = true;
  statusGrupoDeEstoque: boolean = false;
  statusTipoDeItem: boolean = true;
  statusEstoqueNegativo: boolean = false;

  //Variaveis de tela
  statusTela1: boolean = true;
  statusTela2: boolean = false;
  statusTela3: boolean = false;
  statusTela4: boolean = false;

  //export excel
  colsstatusAlmoxarifado: any[];
  colsstatusGrupoDeEstoque: any[];
  colsstatusTipoDeItem: any[];
  colsstatusEstoqueNegativo: any[];
  colsPosicaoEstoque: any[];
  colsResumoMovimento: any[];
  colsResumoTipoItem: any[];
  colsResumoMov: any[];
  colsResumoItem: any[];
  colsResumoNf: any[];
  colsCustoMedio: any[];

  //Exportar Excel
  orderListAlmo = ["descricao", "qtd_fin", "saldo_fin", "gt"]
  orderListGrupoEstoque = ["descricao", "qtd_fin", "saldo_fin", "gt"]
  orderListTipoItem = ["descricao", "qtd_fin", "saldo_fin", "gt"]
  orderListItemNegativo = ["cod_item", "almox", "qtd_fin", "saldo_fin"]
  orderListPosEstoque = ["descricao", "almox", "qtde_ini", "valor_ini", "qtde_entrada", "valor_entrada", "qtde_saida", "valor_saida", "qtde_fin", "valor_fin"]
  orderListResumoMov = ["mov", "qtde", "valor"]
  orderListResumoTipoItem = ["descricao", "qtde", "valor"]
  orderListMovEstoque = ["data", "mov", "item", "almox", "nf", "historico", "qtde_entrada", "valor_entrada", "qtde_saida", "valor_saida"]
  orderListResumoItem = ["descricao", "qtde", "valor"]
  orderListNF = ["data", "nf", "participante", "almox", "qtde_entrada", "valor_entrada", "qtde_saida", "valor_saida"]
  orderListRelacaoItem = ["item", "periodo", "almox", "qtde", "valor", "custo", "mom"]

  // Functions animated show close box card
  currentStatePosicaoEstoque = 'initial';
  currentStateTable2 = 'initial';
  currentStateItensNegativo = 'initial';
  currentStateResumoMovimento = 'initial';
  currentStateResumoTipoItem = 'initial';
  currentStateMovimentacaoEstoque = 'initial';
  currentStateResumoItem = 'initial';
  currentStateResumoNfPart = 'initial';
  currentStateSaldoEstoqueQtde = 'initial';
  currentStateSaldoEstoqueReais = 'initial';
  currentStateSaldoEstoqueTipoItem = 'initial';
  currentStateEstoqueTopFive = 'initial';
  currentStateEvolucaoCustoMedio = 'initial';
  currentStateEstoqueTopFive2 = 'initial';
  currentStateRelacaoItens = 'initial';

  changeStatePosicaoEstoque() {
    this.currentStatePosicaoEstoque = this.currentStatePosicaoEstoque === 'initial' ? 'final' : 'initial';
  }

  changeStateTable2() {
    this.currentStateTable2 = this.currentStateTable2 === 'initial' ? 'final' : 'initial';
  }

  changeStateItensNegativo() {
    this.currentStateItensNegativo = this.currentStateItensNegativo === 'initial' ? 'final' : 'initial';
  }

  changeStateResumoMovimento() {
    this.currentStateResumoMovimento = this.currentStateResumoMovimento === 'initial' ? 'final' : 'initial';
  }

  changeStateResumoTipoItem() {
    this.currentStateResumoTipoItem = this.currentStateResumoTipoItem === 'initial' ? 'final' : 'initial';
  }

  changeStateMovimentacaoEstoque() {
    this.currentStateMovimentacaoEstoque = this.currentStateMovimentacaoEstoque === 'initial' ? 'final' : 'initial';
  }

  changeStateResumoItem() {
    this.currentStateResumoItem = this.currentStateResumoItem === 'initial' ? 'final' : 'initial';
  }

  changeStateResumoNfPart() {
    this.currentStateResumoNfPart = this.currentStateResumoNfPart === 'initial' ? 'final' : 'initial';
  }

  changeStateSaldoEstoqueQtde() {
    this.currentStateSaldoEstoqueQtde = this.currentStateSaldoEstoqueQtde === 'initial' ? 'final' : 'initial';
  }

  changeStateSaldoEstoqueReais() {
    this.currentStateSaldoEstoqueReais = this.currentStateSaldoEstoqueReais === 'initial' ? 'final' : 'initial';
  }

  changeStateSaldoEstoqueTipoItem() {
    this.currentStateSaldoEstoqueTipoItem = this.currentStateSaldoEstoqueTipoItem === 'initial' ? 'final' : 'initial';
  }

  changeStateEstoqueTopFive() {
    this.currentStateEstoqueTopFive = this.currentStateEstoqueTopFive === 'initial' ? 'final' : 'initial';
  }

  changeStateEvolucaoCustoMedio() {
    this.currentStateEvolucaoCustoMedio = this.currentStateEvolucaoCustoMedio === 'initial' ? 'final' : 'initial';
  }

  changeStateEstoqueTopFive2() {
    this.currentStateEstoqueTopFive2 = this.currentStateEstoqueTopFive2 === 'initial' ? 'final' : 'initial';
  }

  changeStateRelacaoItens() {
    this.currentStateRelacaoItens = this.currentStateRelacaoItens === 'initial' ? 'final' : 'initial';
  }

  //Highcharts Estoque Qtde
  loadChartEstoqueQtde(container) {
    var chart = new Highcharts.Chart({
      chart: {
        type: 'area',
        renderTo: container
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.evolucaoQtde.eixoX
      },
      yAxis: {
        title: {
          text: null
        }
      },
      plotOptions: {
        area: {
          fillOpacity: 0.5
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        showInLegend: false,
        name: 'Quantidade',
        data: this.evolucaoQtde.data,
        type: 'area'
      }]
    });
  }

  //Highcharts Estoque Reais
  loadChartEstoqueReais(container) {
    var chart = new Highcharts.Chart({
      chart: {
        type: 'area',
        renderTo: container
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.evolucaoSaldo.eixoX
      },
      yAxis: {
        title: {
          text: null
        }
      },
      plotOptions: {
        area: {
          fillOpacity: 0.5
        },
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        showInLegend: false,
        name: 'Saldo',
        data: this.evolucaoSaldo.data,
        type: 'area'
      }]
    });
  }

  //Highcharts Estoque Tipo do Item
  loadChartEstoqueTipoItem(container) {
    var chart = new Highcharts.Chart({
      chart: {
        type: 'line',
        renderTo: container
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: this.evolucaoTipoItem.eixoX
      },
      yAxis: {
        title: {
          text: null
        }
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      credits: {
        enabled: false
      },
      series: this.evolucaoTipoItem.data
    });
  }

  //Highcharts Estoque Top Five
  loadChartEstoqueTopFive(container, page) {
    var chart = new Highcharts.Chart({
      chart: {
        type: 'column',
        renderTo: container
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: page === 1 ? this.top5itens.eixoX : this.top5itensCusto.eixoX
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
        labels: {
          format: '{value} %'
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray'
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        floating: false,
        width: 175,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
        itemMarginTop: 3,
        itemMarginBottom: 3,
        itemStyle: {
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        },
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      credits: {
        enabled: false
      },
      series: page === 1 ? this.top5itens.data : this.top5itensCusto.data
    });
  }

  //Highcharts Evolucao Custo Medio
  loadChartEvolucaoCustoMedio(container) {
    var chart = new Highcharts.Chart({
      chart: {
        zoomType: 'xy',
        renderTo: container
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: [{
        categories: this.valoresMom.eixoX,
        crosshair: true
      }],
      yAxis: [{
        labels: {
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        title: {
          text: null,
        }
      }, {
        title: {
          text: null,
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        labels: {
          format: '{value} %',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        x: -5,
        verticalAlign: 'top',
        y: -15,
        floating: false,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || // theme
          'rgba(255,255,255,0.25)'
      },
      series: [{
        name: 'Valor',
        type: 'column',
        color: '#007bff',
        data: this.valoresMom.data.valor,
        tooltip: {
          valueSuffix: null
        }

      }, {
        name: 'MoM (%)',
        type: 'spline',
        color: '#dc3545',
        yAxis: 1,
        data: this.valoresMom.data.mom,
        tooltip: {
          valueSuffix: null
        }
      }]
    });
  }

  private sessionSubscription: Subscription;
  constructor(
    private titleService: TitleService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    /*HIDE SIDEBAR FULLSCREEN (DO NOT REMOVE)*/
    $(document).ready(() => {
      $(".btn-full").click(
        function () {
          $(".mat-drawer-side").toggleClass('d-none');
        }
      );

      $('body')
        .off('click.dropdown touchstart.dropdown.data-api', '.dropdown')
        .on('click.dropdown touchstart.dropdown.data-api', '.dropdown form', function (e) { e.stopPropagation() })
    });

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.titleService.title = 'Dashboard Estoque';

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
          this.filiais = this.dblinkedSessionService.filiais.map(companyBranche => companyBranche.id)

          this.getAlmoxarifados()
          this.getItens()
          this.getDashValues()
          this.getPosicaoEstoque()
          this.getEvolucaoEstoque()
          this.getEvolucaoCusto()
          this.getEvolucaoCustoItem()
          this.setColumnsModelExport()
        }
      }
    );
  }

  exportExcelAll(item, fileName, list?) {
    exportExcel(item, fileName, list)
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  getEvolucaoCusto() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        mes: this.dblinkedSessionService.periodo.month.value < 10 ? "0" + this.dblinkedSessionService.periodo.month.value.toString() : this.dblinkedSessionService.periodo.month.value.toString(),
        ano: this.dblinkedSessionService.periodo.year.value.toString(),
        almoxarifadoid: this.liAlmoxarifadoId.toString(),
        filiais: this.filiais.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "estoque/dashboard/get-evolucao-custo", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res)
          } else {
            this.top5itensCusto = res["data"]["top5itens"]
            this.valoresMom = res["data"]["valormom"]
            // this.loadChartEstoqueTopFive('chart_estoque_top_five_2', 2)
            // this.loadChartEvolucaoCustoMedio('chart_custo_medio')
          }

          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar a evolução de custo, contate o suporte: " + err.message });
        }
      );
  }

  getEvolucaoCustoItem() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        mes: this.dblinkedSessionService.periodo.month.value < 10 ? "0" + this.dblinkedSessionService.periodo.month.value.toString() : this.dblinkedSessionService.periodo.month.value.toString(),
        ano: this.dblinkedSessionService.periodo.year.value.toString(),
        almoxarifadoid: this.liAlmoxarifadoId.toString(),
        itemid: this.liItemId.toString(),
        filiais: this.filiais.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "estoque/dashboard/get-relacao-item", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res)
          } else {
            this.relacItemCusto = res["data"]
          }

          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar a relação custo/item, contate o suporte: " + err.message });
        }
      );
  }

  getEvolucaoEstoque() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        mes: this.dblinkedSessionService.periodo.month.value < 10 ? "0" + this.dblinkedSessionService.periodo.month.value.toString() : this.dblinkedSessionService.periodo.month.value.toString(),
        ano: this.dblinkedSessionService.periodo.year.value.toString(),
        filiais: this.filiais.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "estoque/dashboard/get-evolucao-estoque", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res)
          } else {
            this.evolucaoQtde = res["data"]["evolucao_qtde"]
            this.evolucaoTipoItem = res["data"]["evolucao_tipo_item"]
            this.evolucaoSaldo = res["data"]["evolucao_saldo"]
            this.top5itens = res["data"]["top5itens"]
          }

          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar a evolução de estoque, contate o suporte: " + err.message });
        }
      );
  }

  getItens() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "estoque/itens", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res)
          } else {
            this.listItem = res["data"]
          }

          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar a lista dos itens, contate o suporte: " + err.message });
        }
      );
  }


  getAlmoxarifados() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        filiais: this.filiais.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "estoque/almoxarifados", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res)
          } else {
            this.listAlmoxarifado = res["data"]
          }

          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar a lista dos almoxarifados, contate o suporte: " + err.message });
        }
      );
  }

  getDashValues() {
    let mes_atu = this.dblinkedSessionService.periodo.month.value < 10 ? "0" + this.dblinkedSessionService.periodo.month.value.toString() : this.dblinkedSessionService.periodo.month.value.toString()
    let mes_ante = this.dblinkedSessionService.periodo.month.value === 1 ? "12" : (this.dblinkedSessionService.periodo.month.value - 1) < 10 ? "0" + (this.dblinkedSessionService.periodo.month.value - 1).toString() : (this.dblinkedSessionService.periodo.month.value - 1).toString()
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        filiais: this.filiais.toString(),
        mes: mes_atu,
        ano: this.dblinkedSessionService.periodo.year.value.toString(),
        mesante: mes_ante,
        anoante: mes_ante === "12" ? (this.dblinkedSessionService.periodo.year.value - 1).toString() : this.dblinkedSessionService.periodo.year.value.toString()
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "estoque/dashboard/get-saldos-mov", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res)
          } else {
            this.sdoAlmox = res["data"]["saldo_almoxarifado"]
            this.sdoGrpEstoq = res["data"]["saldo_grupo_estoque"]
            this.sdoTipoItem = res["data"]["saldo_tipo_item"]
            this.sdoNeg = res["data"]["itens_negativos"]
            this.movEs = res["data"]["mov_es"]
            this.movTipoItem = res["data"]["mov_tipo_item"]
            this.movEstoque = res["data"]["mov_estoque"]
            this.movItem = res["data"]["mov_item"]
            this.nfPart = res["data"]["nf_part"]

            this.totalQtdeSdoAlmox = res["data"]["total_qtde"]
            this.totalValorSdoAlmox = res["data"]["total_valor"]
            this.totalValorSdoInit = res["data"]["total_valor_ini"]
            this.totalQtdeItensNeg = res["data"]["total_qtde_itens_neg"]
            this.totalValorItensNeg = res["data"]["total_valor_itens_neg"]
            this.totalQtdeMov = res["data"]["total_qtde_mov"]
            this.totalValorMov = res["data"]["total_valor_mov"]
            this.totalQtdeMovEntrada = res["data"]["total_qtde_mov_entrada"]
            this.totalQtdeMovSaida = res["data"]["total_qtde_mov_saida"]
            this.totalValorMovEntrada = res["data"]["total_valor_mov_entrada"]
            this.totalValorMovSaida = res["data"]["total_valor_mov_saida"]
          }
          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar os valores do dashboard, contate o suporte: " + err.message });
        }
      );
  }

  getPosicaoEstoque() {
    let dtCorteDate: Date = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 1)
    let dtCorte = new Date(dtCorteDate.setDate(dtCorteDate.getDate() - 1))

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        almoxarifadoid: this.liAlmoxarifadoId2.toString(),
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        filiais: this.filiais.toString(),
        dtcorte: dtCorte.getFullYear() + "-" + (dtCorte.getMonth() + 1)  + "-" + dtCorte.getDate()
      })
    }

    this.httpService.wait()
    this.httpClient.get(this.configService.defaultUrlApiUpload + "estoque/dashboard/get-posicao-estoque", httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) this.exibirMensagem(res)
          else this.posEstoque = res["data"]

          this.httpService.done()
        },
        (err) => {
          this.httpService.done()
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar os valores da posição de estoque, contate o suporte: " + err.message })
        }
      );

    this.posEstoqueClone = new Array()

  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({
        severity: 'sucesso',
        key: 'messageLinnks',
        detail: value.mensagem
      });
    } else {
      this.messageService.add({
        severity: 'warn',
        key: 'messageLinnks',
        detail: value.mensagem
      });
    }
  }

  setColumnsModelExport() {
    this.columnsModelAdendosPosicaoEstoque = [
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'qtd_fin', header: 'QTD FINAL' },
      { field: 'saldo_fin', header: 'SALDO FINAL' },
      { field: 'gt', header: '%GT' },
    ];

    this.columnsModelEstoqueNegativo = [
      { field: 'cod_item', header: 'CÓD. ITEM' },
      { field: 'almox', header: 'ALMOX' },
      { field: 'qtd_fin', header: 'QTD FINAL' },
      { field: 'saldo_fin', header: 'SALDO FIN' },
    ];


    this.columnsModelPosicaoEstoque = [
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'almox', header: 'ALMOXARIFADO' },
      { field: 'qtde_ini', header: 'QTD SALDOS INI' },
      { field: 'valor_ini', header: 'VL. SALDO INI' },
      { field: 'qtde_entrada', header: 'QTD ENTRADA' },
      { field: 'valor_entrada', header: 'VL. ENTRADA' },
      { field: 'qtde_saida', header: 'QTD SAÍDA' },
      { field: 'valor_saida', header: 'VL. SAÍDA' },
      { field: 'qtde_fin', header: 'QTD SALDO FIN' },
      { field: 'valor_fin', header: 'VL. SALDO FIN' },
    ];

    this.columnsModelResumoPorMovimento = [
      { field: 'mov', header: 'MOV' },
      { field: 'qtde', header: 'QTDE' },
      { field: 'valor', header: 'VALOR' },
    ];

    this.columnsModelTipoDoItem = [
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'qtde', header: 'QTDE' },
      { field: 'valor', header: 'VALOR' },
    ];

    this.columnsModelMovimentacaoDeEstoque = [
      { field: 'data', header: 'DATA' },
      { field: 'mov', header: 'MOV' },
      { field: 'item', header: 'ITEM' },
      { field: 'almox', header: 'ALMOX' },
      { field: 'nf', header: 'NF' },
      { field: 'historico', header: 'HISTÓRICO' },
      { field: 'qtde_entrada', header: 'QTDE ENTRADA' },
      { field: 'valor_entrada', header: 'VALOR ENTRADA' },
      { field: 'qtde_saida', header: 'QTDE SAÍDA' },
      { field: 'valor_saida', header: 'VALOR SAÍDA' },
    ];

    this.columnsModelResumoPorItem = [
      { field: 'descricao', header: 'ITEM' },
      { field: 'qtde', header: 'QTDE' },
      { field: 'valor', header: 'VALOR' },
    ];

    this.columnsModelResumoPorNF = [
      { field: 'data', header: 'DATA' },
      { field: 'nf', header: 'NF' },
      { field: 'participante', header: 'PARTICIPANTE' },
      { field: 'almox', header: 'ALMOX' },
      { field: 'qtde_entrada', header: 'QTDE ENTRADA' },
      { field: 'valor_entrada', header: 'VALOR ENTRADA' },
      { field: 'qtde_saida', header: 'QTDE SAÍDA' },
      { field: 'valor_saida', header: 'VALOR SAÍDA' },
    ];

    this.columnsModelRelacaoDeItens = [
      { field: 'item', header: 'ITEM' },
      { field: 'periodo', header: 'PERÍODO' },
      { field: 'almox', header: 'ALMOX' },
      { field: 'qtde_sdo_final', header: 'QTD SDO FINAL' },
      { field: 'vl_sdo_final', header: 'VL SDO FINAL' },
      { field: 'vl_custo_medio', header: 'VL CUSTO MÉDIO' },
      { field: 'mom_cm', header: 'MOM CM' },
    ];
  }

  onFilter(event: any) {
  }

  /*Show Hide isCardPosicaoEstoque*/
  isCardPosicaoEstoque: boolean = true;
  ShowHIdeCardPosicaoEstoque() {
    this.isCardPosicaoEstoque = !this.isCardPosicaoEstoque;
  }

  /*Show Hide isCardTable2*/
  isCardTable2: boolean = true;
  ShowHIdeCardTable2() {
    this.isCardTable2 = !this.isCardTable2;
  }

  /*Show Hide isCardItensNegativo*/
  isCardItensNegativo: boolean = true;
  ShowHIdeCardItensNegativo() {
    this.isCardItensNegativo = !this.isCardItensNegativo;
  }

  /*Show Hide isCardResumoMovimento*/
  isCardResumoMovimento: boolean = true;
  ShowHIdeCardResumoMovimento() {
    this.isCardResumoMovimento = !this.isCardResumoMovimento;
  }

  /*Show Hide isCardResumoTipoItem*/
  isCardResumoTipoItem: boolean = true;
  ShowHIdeCardResumoTipoItem() {
    this.isCardResumoTipoItem = !this.isCardResumoTipoItem;
  }

  /*Show Hide isCardMovimentaçãoEstoque*/
  isCardMovimentacaoEstoque: boolean = true;
  ShowHIdeCardMovimentacaoEstoque() {
    this.isCardMovimentacaoEstoque = !this.isCardMovimentacaoEstoque;
  }

  /*Show Hide isCardResumoItem*/
  isCardResumoItem: boolean = true;
  ShowHIdeCardResumoItem() {
    this.isCardResumoItem = !this.isCardResumoItem;
  }

  /*Show Hide isCardResumoNfPart*/
  isCardResumoNfPart: boolean = true;
  ShowHIdeCardResumoNfPart() {
    this.isCardResumoNfPart = !this.isCardResumoNfPart;
  }

  /*Show Hide isCardEvolucaoEstoqueQtde*/
  isCardEvolucaoEstoqueQtde: boolean = true;
  ShowHIdeCardEvolucaoEstoqueQtde() {
    this.isCardEvolucaoEstoqueQtde = !this.isCardEvolucaoEstoqueQtde;
  }

  /*Show Hide isCardEvolucaoEstoqueReais*/
  isCardEvolucaoEstoqueReais: boolean = true;
  ShowHIdeCardEvolucaoEstoqueReais() {
    this.isCardEvolucaoEstoqueReais = !this.isCardEvolucaoEstoqueReais;
  }

  /*Show Hide isCardEvolucaoEstoqueTipoItem*/
  isCardEvolucaoEstoqueTipoItem: boolean = true;
  ShowHIdeCardEvolucaoEstoqueTipoItem() {
    this.isCardEvolucaoEstoqueTipoItem = !this.isCardEvolucaoEstoqueTipoItem;
  }

  /*Show Hide isCardEstoqueTopFive*/
  isCardEstoqueTopFive: boolean = true;
  ShowHIdeCardEstoqueTopFive() {
    this.isCardEstoqueTopFive = !this.isCardEstoqueTopFive;
  }

  /*Show Hide isCardEvolucaoCustoMedio*/
  isCardEvolucaoCustoMedio: boolean = true;
  ShowHIdeCardEvolucaoCustoMedio() {
    this.isCardEvolucaoCustoMedio = !this.isCardEvolucaoCustoMedio;
  }

  /*Show Hide isCardEstoqueTopFive2*/
  isCardEstoqueTopFive2: boolean = true;
  ShowHIdeCardEstoqueTopFive2() {
    this.isCardEstoqueTopFive2 = !this.isCardEstoqueTopFive2;
  }

  /*Show Hide isCardRelacaoItens*/
  isCardRelacaoItens: boolean = true;
  ShowHIdeCardRelacaoItens() {
    this.isCardRelacaoItens = !this.isCardRelacaoItens;
  }

  /*Open Close DropDown Date*/
  dropdate: boolean = false;
  DropdownDateEvent(e: Event) {
    this.dropdate = !this.dropdate;
  }

  getStringFromHtml(text) {
    const html = text;
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }


  /*INICIO DECLARAÇÃO DAS ABAS DA TABELA 2*/
  showTelaCard2(tipo) {
    this.statusAlmoxarifado = false;
    this.statusGrupoDeEstoque = false;

    if (tipo === 'TELA_1') {
      this.statusAlmoxarifado = true;
      this.dropdate = false;
    }

    else if (tipo === 'TELA_2') {
      this.statusGrupoDeEstoque = true;
      this.dropdate = false;
    }
  }
  /*FIM DECLARAÇÃO DAS ABAS DA TABELA 2*/

  /*INICIO DECLARAÇÃO DAS ABAS DA TABELA 3*/
  showTelaCard3(tipo) {
    this.statusTipoDeItem = false;
    this.statusEstoqueNegativo = false;

    if (tipo === 'TELA_1') {
      this.statusTipoDeItem = true;
      this.dropdate = false;
    }

    else if (tipo === 'TELA_2') {
      this.statusEstoqueNegativo = true;
      this.dropdate = false;
    }

  }
  /*FIM DECLARAÇÃO DAS ABAS DA TABELA 3*/

  /*INICIO DECLARAÇÃO DAS TELAS*/
  showTelaDashboard(tipo) {
    this.statusTela1 = false;
    this.statusTela2 = false;
    this.statusTela3 = false;
    this.statusTela4 = false;

    if (tipo === 'TELA_1') {
      this.statusTela1 = true;
      this.dropdate = false;

    } else if (tipo === 'TELA_2') {
      this.statusTela2 = true;
      this.dropdate = false;

    } else if (tipo === 'TELA_3') {
      this.statusTela3 = true;
      this.dropdate = false;
      this.loadChartEstoqueQtde('chart_estoque_qtde');
      this.loadChartEstoqueReais('chart_estoque_reais');
      this.loadChartEstoqueTipoItem('chart_estoque_tipo_item');
      this.loadChartEstoqueTopFive('chart_estoque_top_five', 1);

    } else if (tipo === 'TELA_4') {
      this.statusTela4 = true;
      this.dropdate = false;
      this.loadChartEstoqueTopFive('chart_estoque_top_five_2', 2);
      this.loadChartEvolucaoCustoMedio('chart_custo_medio');
    }
  }
  /*FIM DECLARAÇÃO DAS TELAS*/

  calcFooter(data, key) {
    let response = 0;
    (data.filteredValue ? data.filteredValue : data._value).forEach(item => {
      response += item[key]
    })
    return response
  }

  /*FullScreen Table*/
  fullTable: boolean = false;
  fullScreenTable() {
    this.fullTable = !this.fullTable;
  }

  /*FullScreen TableMovEstoque*/
  fullTableMovEstoque: boolean = false;
  fullScreenTableEstoque() {
    this.fullTableMovEstoque = !this.fullTableMovEstoque;
  }

  /*FullScreen EvolucaoEstoqueQtde*/
  fullEvolucaoEstoqueQtde: boolean = false;
  fullScreenEvolucaoEstoqueQtde() {
    this.fullEvolucaoEstoqueQtde = !this.fullEvolucaoEstoqueQtde;
  }

  /*FullScreen EvolucaoEstoqueReais*/
  fullEvolucaoEstoqueReais: boolean = false;
  fullScreenEvolucaoEstoqueReais() {
    this.fullEvolucaoEstoqueReais = !this.fullEvolucaoEstoqueReais;
  }

  /*FullScreen EvolucaoEstoqueTipoItem*/
  fullEvolucaoEstoqueTipoItem: boolean = false;
  fullScreenEvolucaoEstoqueTipoItem() {
    this.fullEvolucaoEstoqueTipoItem = !this.fullEvolucaoEstoqueTipoItem;
  }

  /*FullScreen EstoqueTopFive*/
  fullEstoqueTopFive: boolean = false;
  fullScreenEstoqueTopFive() {
    this.fullEstoqueTopFive = !this.fullEstoqueTopFive;
  }

  /*FullScreen EvolucaoCustoMedio*/
  fullEvolucaoCustoMedio: boolean = false;
  fullScreenEvolucaoCustoMedio() {
    this.fullEvolucaoCustoMedio = !this.fullEvolucaoCustoMedio;
  }

  /*FullScreen EstoqueTopFive2*/
  fullEstoqueTopFive2: boolean = false;
  fullScreenEstoqueTopFive2() {
    this.fullEstoqueTopFive2 = !this.fullEstoqueTopFive2;
  }

  /*FullScreen RelacaoItens*/
  fullRelacaoItens: boolean = false;
  fullScreenRelacaoItens() {
    this.fullRelacaoItens = !this.fullRelacaoItens;
  }

  /*INÍCIO FORMATAR DATA EXTENSO*/
  formatarDataExtenso() {
    const dt = new Date();

    const month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const week = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

    const semana = week[dt.getDay()];
    const mes = month[dt.getMonth()];

    return 'Hoje é ' + semana + ', ' + dt.getDate() + ' de ' + mes + ' de ' + dt.getFullYear() + '.';
  }
  /*FIM FORMATAR DATA EXTENSO*/

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  async exportPosicaoEstoque() {

    let dtCorteDate: Date = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 1)
    let dtCorte = new Date(dtCorteDate.setDate(dtCorteDate.getDate() - 1))

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'Cache-Control': 'no-store, max-age=0'
      }),
      responseType: 'blob' as 'json',
    }

    const payload = {
      usuario: this.sessionService.loggedUser.id,
      almoxarifados: this.liAlmoxarifadoId2,
      dt_corte: dtCorte.getFullYear() + "-" + (dtCorte.getMonth() + 1)  + "-" + dtCorte.getDate(),
      empresa_id: this.dblinkedSessionService.empresa.id,
      filiais: this.filiais
    }

    this.httpService.wait()
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'estoque/relatorio-posicao-estoque', payload, httpOptions).subscribe(
      (response: Blob) => {
        let link = document.createElement('a')
        let url = window.URL.createObjectURL(response)
        let fileName = "posicao_estoque.xlsx"
        link.href = url
        link.download = fileName
        link.click()
        window.URL.revokeObjectURL(url)
        this.httpService.done()
      },
      responseError => {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao exportar a posição de estoque, contate o suporte" })
        this.httpService.done()
      }
    )
  }

  calcFooterCusto(data, key1, key2) {
    let qtde = 0;
    let valor = 0;
    (data.filteredValue ? data.filteredValue : data._value).forEach(item => {
      qtde += item[key1]
      valor += item[key2]
    })
    return qtde === 0 ? 0 : valor / qtde
  }

}