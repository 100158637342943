import { AuthApp } from './auth-app.model';
import { AuthUser } from './auth-user.model';
import { AuthStatus } from './auth-status.model';

export class AuthLog {
  id: number;
  message: string;
  authUser: AuthUser = new AuthUser();
  authStatus: AuthStatus = new AuthStatus();
  authApp: AuthApp = new AuthApp();

  constructor(authLog?: AuthLog | number) {
    if (authLog !== undefined && authLog !== null) {
      if (typeof authLog === 'number') {
        this.id = authLog;
      } else {
        this.id = authLog.id;
        this.message = authLog.message;
        this.authUser = authLog.authUser;
        this.authStatus = authLog.authStatus;
        this.authApp = authLog.authApp;
      }
    }
  }
}
