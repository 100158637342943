import { Component, OnInit } from '@angular/core';
import { TbReg } from '../model/tb-reg.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'siscoserv-tipo-registro',
  templateUrl: './tipo-registro.component.html',
  styleUrls: ['./tipo-registro.component.scss'],
  providers: [CrudService]
})
export class TipoRegistroComponent extends DataTableBasicComponent implements OnInit {
  public tbReg: TbReg = new TbReg();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('CÓDIGO', 'cod'),
      new DataTableColumn('DESCRIÇÃO', 'descricao')
    ];

    this.controls = [
      new DataControl('Código', 'cod').setReadOnly(),
      new DataControl('Descrição', 'descricao').setReadOnly()
    ];
  }
}
