export class TbReg {
  id: number;
  cod: string;
  descricao: string;

  constructor(tbReg?: TbReg | number) {
    if (tbReg) {
      if (typeof tbReg === 'number') {
        this.id = tbReg;
      } else {
        this.id = tbReg.id;
        this.cod = tbReg.cod;
        this.descricao = tbReg.descricao;
      }
    }
  }
}
