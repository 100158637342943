import { Injectable } from '@angular/core';
import { PlanoContas } from '../../contabil/model/plano-contas.model';

// TODO: Move from services
export class PlanoContasService {
  constructor(private type: string) {
  }

  check(planoContasFull: Array<PlanoContas>,
        planoContas: PlanoContas,
        selected: boolean) {
    planoContas.selected = selected;

    let set = false;

    planoContasFull.forEach(plano => {
      if (plano.id === planoContas.id) {
        set = true;
      } else if (set) {
        if (plano.nivel > planoContas.nivel) {
          plano.selected = planoContas.selected;
        } else {
          set = false;
        }
      }
    });

    this.check_parents(planoContasFull, planoContas);
  }

  check_parents(planoContasFull: Array<PlanoContas>, planoContas: PlanoContas = null) {
    if (planoContas.parent && planoContas.parent.id) {
      let all = false;

      const parent = planoContasFull.find(p => p.id === planoContas.parent.id);

      planoContasFull.filter(plano => plano.id !== parent.id &&
                                      plano.nivel === planoContas.nivel &&
                                      parent.children.findIndex(c => c === plano.id) > -1)
                     .forEach(plano => {
                       if (!plano.selected) {
                         all = false;
                       }
                     });

      parent.selected = all;

      this.check_parents(planoContasFull, parent);
    }
  }

  add(planoContasFull: Array<PlanoContas>, planoContasSelecionados: Array<PlanoContas>, type: string = this.type) {
    planoContasFull.filter(plano => plano.selected &&
                                    plano.indCta === 'A' &&
                                    !this.disabled(planoContasSelecionados, plano, type))
                   .forEach(plano => {
                     planoContasSelecionados.push(new PlanoContas(plano));
                   });
  }

  toAdd(planoContasFull: Array<PlanoContas>, planoContasSelecionados: Array<PlanoContas>, type: string = this.type): Array<PlanoContas> {
    const result: Array<PlanoContas> = new Array();

    planoContasFull.filter(plano => plano.selected &&
                                    plano.indCta === 'A' &&
                                    !this.disabled(planoContasSelecionados, plano, type))
                   .forEach(plano => {
                     result.push(new PlanoContas(plano));
                   });

    return result;
  }

  remove(planoContasFull: Array<PlanoContas>, planoContasSelecionados: Array<PlanoContas>, type: string = this.type) {
    planoContasSelecionados.filter(p => p.selected).forEach(plano => {
      const pcf = planoContasFull.find(p => p.id === plano.id);
      pcf.selected = false;
      pcf.planoContasRefRelacList = null;

      plano.selected = false;
      planoContasSelecionados.splice(planoContasSelecionados.indexOf(plano), 1);
    });
  }

  toRemove(planoContasFull: Array<PlanoContas>, planoContasSelecionados: Array<PlanoContas>, type: string = this.type) {
    return planoContasSelecionados.filter(p => p.selected);
  }

  disabled(planoContasSelecionados: Array<PlanoContas>, planoContas: PlanoContas, type: string = this.type): boolean {
    const disabled = planoContasSelecionados.find(p => planoContas && p.id === planoContas.id) !== undefined;

    switch (type) {
      case 'ref-relac':
        return disabled || (planoContas && planoContas.planoContasRefRelacList && planoContas.planoContasRefRelacList.length > 0);
      case 'csll':
        return disabled || (planoContas && planoContas.tbCsllM350Relac && planoContas.tbCsllM350Relac.id !== undefined);
      case 'irpf':
        return disabled || (planoContas && planoContas.tbIrpjM300Relac && planoContas.tbIrpjM300Relac.id !== undefined);
        case 'gerencial':
        return disabled || (planoContas && planoContas.planoContasGerencialRelacList
          && planoContas.planoContasGerencialRelacList.length > 0);
      default:
        return disabled;
    }
  }

  rowStyleClass(planoContas: PlanoContas): string {
    return planoContas.indCta === 'S' ? 'bold' : '';
  }
}
