import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import deepCopy from 'app/util/processing/deepcopy';
import exportExcel from 'app/util/processing/exportExcel';
import { Subscription } from 'rxjs/Subscription';
import { isNullOrUndefined } from 'util';
import { Message, MessageService } from '../../../../node_modules/primeng/api';
import { Empresa } from '../../cadastro/model/empresa.model';
import { ConfigService } from '../../shared/services/config.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { HttpService } from '../../shared/services/http.service';
import { SessionService } from '../../shared/services/session.service';
import { TitleService } from '../../shared/services/title.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { UtilService } from '../../shared/services/util.service';

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class LivrosFiscaisComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  title: string;
  subtitle: string;
  dtIni: Date;
  dtFin: Date;
  defaultDate: Date;
  modalIcmsIpi: boolean;
  modalLivroFiscal: boolean;
  modalPcc: boolean;
  modalRetidos: boolean;
  modalDemaisDocs: boolean;
  modalIcmsIpiHtml: boolean;
  modalDifal: boolean;
  modalDifalNaoContrib: boolean;
  modalIcmsSt: boolean;
  detalhePorItem: boolean;
  entradaSaida: any;
  servico: any;
  tipos: any[];
  tiposRetido: any[];
  listaRelatFiscal: any[];
  relatorioSelecionado: number;
  msgs: Message[];
  modalIcms: boolean;
  modalPisCofins: boolean = false;
  listaRelatGer: Array<any> = new Array();
  dicRelatComposto: any = {};
  datasIndividuais: boolean = false;
  relatGerSelecionado: any;
  modalRelatorioGer: boolean = false;
  dataRef: Date = new Date();
  periodo: Date = new Date();
  statusRelExt: boolean = false;
  opcoesRelatoriosExternos: any[];
  relExterno: number;
  exportOrderList: Array<string> = new Array()

  existeRelatorioExterno: boolean = false;


  exibirDtIni: boolean = false;
  exibirDtFin: boolean = false;
  exibirFilial: boolean = false;
  filialRelatorio: string = '';

  colunasRelatorioRetidoFonte = [];

  constructor(
    private titleService: TitleService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.title = "LIVROS FISCAIS";
    this.titleService.title = "Livros fiscais";
    this.relatGerSelecionado = {};
    this.detalhePorItem = false

    this.defaultDate = new Date();
    this.tipos = [];
    this.tipos.push({ label: "Selecione", value: "999" });
    this.tipos.push({ label: "Entrada", value: 0 });
    this.tipos.push({ label: "Saída", value: 1 });
    this.tipos.push({ label: "Ambos", value: 2 });
    this.tiposRetido = [];
    this.tiposRetido.push({ label: "Selecione", value: "999" });
    this.tiposRetido.push({ label: "Serviços Prestados", value: 1 });
    this.tiposRetido.push({ label: "Serviços Tomados", value: 0 });
    this.tiposRetido.push({ label: "Ambos", value: 2 });

    this.listaRelatFiscal = [];
    this.listaRelatFiscal.push({ label: "ICMS", value: 1 });
    this.listaRelatFiscal.push({ label: "IPI", value: 0 });

    Promise.resolve(null).then(() => (this.toolbarService.hidden = true));
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.change();
      }
    );
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (
      this.dblinkedSessionService.empresa &&
      this.dblinkedSessionService.empresa.id
    ) {
      if (
        this.dblinkedSessionService.periodo.month.value !==
        this.utilService.currentMonth(this.defaultDate) + 1
      ) {
        const mes =
          this.dblinkedSessionService.periodo.month.value.toString.length === 2
            ? this.dblinkedSessionService.periodo.month.value
            : "0" + this.dblinkedSessionService.periodo.month.value;
        const dt =
          this.dblinkedSessionService.periodo.year.value +
          "-" +
          mes +
          "-01 12:00:00";
        this.defaultDate = new Date(dt);
      }
      this.dtIni = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dtFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.modalIcmsIpi = false;
      this.modalRetidos = false;
      this.modalDemaisDocs = false;
      this.modalIcmsIpiHtml = false;
      this.detalhePorItem = false;
      this.modalLivroFiscal = false;
      this.modalPcc = false;
      this.entradaSaida = "999";
      this.servico = "999";
      this.getConfigRelatorios();

      this.httpService.get('/custom/ecd-report/possui-relat-externo(' +
      this.dblinkedSessionService.empresa.id +')')
      .subscribe(ref => this.relExt(ref),
      error => this.httpService.handleError(error, () => this.change()),
      () => this.httpService.done());
    }
  }

  exibirModal(tipo) {
    if (tipo === "ICMS_IPI") {
      this.modalIcmsIpi = true;
    } else if (tipo === "RETIDOS_NA_FONTE") {
      this.modalRetidos = true;
    } else if (tipo === "DEMAIS_DOCUMENTOS") {
      this.modalDemaisDocs = true;
    } else if (tipo === "DIFAL") {
      this.modalDifal = true;
    } else if (tipo === "DIFAL_NAO_CONTRIB") {
      this.modalDifalNaoContrib = true;
    } else if (tipo === "ICMS_ST") {
      this.modalIcmsSt = true;
    } else if (tipo === "ICMS_IPI_HTML") {
      this.modalIcmsIpiHtml = true;
    } else if (tipo === "LIVRO_FISCAL") {
      this.modalLivroFiscal = true;
    } else if (tipo === "CSRF") {
      this.modalPcc = true;
    } else if (tipo === "PIS_COFINS") {
      this.periodo = new Date(
        this.dblinkedSessionService.periodo.year.value,
        this.dblinkedSessionService.periodo.month.value - 1,
        1
      );
      this.modalPisCofins = true;
    } else {
      this.relatGerSelecionado = tipo;
      this.modalRelatorioGer = true;
      this.dataRef = new Date(
        this.dblinkedSessionService.periodo.year.value,
        this.dblinkedSessionService.periodo.month.value - 1,
        1
      );
    }
  }

  reportPc(data: any) {
    let _date: any[];
    let inicio: string;
    let final: string;
    let detalhe: number;
    detalhe = this.detalhePorItem ? 0 : 1;
    _date = this.utilService.dateToStringDate(this.dtIni).split("/");
    inicio = _date[2] + "-" + _date[1] + "-" + _date[0];
    _date = this.utilService.dateToStringDate(this.dtFin).split("/");
    final = _date[2] + "-" + _date[1] + "-" + _date[0];
    if (data === "icmsIpi") {
      this.windowOpen(
        "/fiscal/report-icms-ipi/" +
        this.dblinkedSessionService.empresa.id +
        "/" +
        inicio +
        "/" +
        final +
        "/" +
        this.entradaSaida +
        "/" +
        detalhe,
        "_blank"
      );
    } else if (data === "retidosFonte") {
      this.windowOpen(
        "/fiscal/report-retidos-fonte/" +
        this.dblinkedSessionService.empresa.id +
        "/" +
        inicio +
        "/" +
        final +
        "/" +
        this.servico,
        "_blank"
      );
    } else if (data === "demaisDocs") {
      this.windowOpen(
        "/fiscal/report-demais-docs/" +
        this.dblinkedSessionService.empresa.id +
        "/" +
        inicio +
        "/" +
        final +
        "/" +
        this.servico,
        "_blank"
      );
    } else if (data === "difal") {
       //Header da requisiçãox

       _date = this.utilService.dateToStringDate(this.dtIni).split("/");
    inicio = _date[2] + "-" + _date[1] + "-" + _date[0];
    _date = this.utilService.dateToStringDate(this.dtFin).split("/");
    final = _date[2] + "-" + _date[1] + "-" + _date[0];

     const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'Cache-Control': 'no-store, max-age=0'
      }),
      responseType: 'blob' as 'json',
    }

    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      data_inicial: inicio,
      data_final: final,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    try {
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'fiscal/relatorio-difal', infos, httpOptions).toPromise()
        .then((res: any) => {
          let link = document.createElement('a')
          let url = window.URL.createObjectURL(res)
          let fileName = "Relatório Difal.xlsx"
          link.href = url
          link.download = fileName
          link.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((err: HttpErrorResponse) => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.message })
        })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.httpService.done()
    }



  } else if (data === "difalNaoContrib") {
    //Header da requisiçãox

    _date = this.utilService.dateToStringDate(this.dtIni).split("/");
 inicio = _date[2] + "-" + _date[1] + "-" + _date[0];
 _date = this.utilService.dateToStringDate(this.dtFin).split("/");
 final = _date[2] + "-" + _date[1] + "-" + _date[0];

  const httpOptions = {
   headers: new HttpHeaders({
     'Authorization': this.configService.bearerApi,
     'Cache-Control': 'no-store, max-age=0'
   }),
   responseType: 'blob' as 'json',
 }

 const infos = {
   empresa_id: this.dblinkedSessionService.empresa.id,
   data_inicial: inicio,
   data_final: final,
   user_id: this.sessionService.loggedUser.id
 }

 this.httpService.wait()
 try {
   this.httpClient.post(this.configService.defaultUrlApiUpload + 'fiscal/relatorio-difal-nao-contribuinte', infos, httpOptions).toPromise()
     .then((res: any) => {
       let link = document.createElement('a')
       let url = window.URL.createObjectURL(res)
       let fileName = "Relatório Difal Não Contribuinte.xlsx"
       link.href = url
       link.download = fileName
       link.click()
       window.URL.revokeObjectURL(url)
     })
     .catch((err: HttpErrorResponse) => {
       this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.message })
     })
 } catch (err) {
   this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
 } finally {
   this.httpService.done()
 }



} else if (data === "icmsSt") {
  //Header da requisiçãox

  _date = this.utilService.dateToStringDate(this.dtIni).split("/");
inicio = _date[2] + "-" + _date[1] + "-" + _date[0];
_date = this.utilService.dateToStringDate(this.dtFin).split("/");
final = _date[2] + "-" + _date[1] + "-" + _date[0];

const httpOptions = {
 headers: new HttpHeaders({
   'Authorization': this.configService.bearerApi,
   'Cache-Control': 'no-store, max-age=0'
 }),
 responseType: 'blob' as 'json',
}

const infos = {
 empresa_id: this.dblinkedSessionService.empresa.id,
 data_inicial: inicio,
 data_final: final,
 user_id: this.sessionService.loggedUser.id
}

this.httpService.wait()
try {
 this.httpClient.post(this.configService.defaultUrlApiUpload + 'fiscal/relatorio-icms-st', infos, httpOptions).toPromise()
   .then((res: any) => {
     let link = document.createElement('a')
     let url = window.URL.createObjectURL(res)
     let fileName = "Relatório ICMS ST.xlsx"
     link.href = url
     link.download = fileName
     link.click()
     window.URL.revokeObjectURL(url)
   })
   .catch((err: HttpErrorResponse) => {
     this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.message })
   })
} catch (err) {
 this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
} finally {
 this.httpService.done()
}



} else if (data === "livrofiscal") {
  //Header da requisiçãox

  _date = this.utilService.dateToStringDate(this.dtIni).split("/");
inicio = _date[2] + "-" + _date[1] + "-" + _date[0];
_date = this.utilService.dateToStringDate(this.dtFin).split("/");
final = _date[2] + "-" + _date[1] + "-" + _date[0];

const httpOptions = {
 headers: new HttpHeaders({
   'Authorization': this.configService.bearerApi,
   'Cache-Control': 'no-store, max-age=0'
 }),
 responseType: 'blob' as 'json',
}

const infos = {
 empresa_id: this.dblinkedSessionService.empresa.id,
 data_inicial: inicio,
 data_final: final,
 user_id: this.sessionService.loggedUser.id
}

this.httpService.wait()
try {
 this.httpClient.post(this.configService.defaultUrlApiUpload + 'fiscal/relatorio-livro-fiscal', infos, httpOptions).toPromise()
   .then((res: any) => {
     let link = document.createElement('a')
     let url = window.URL.createObjectURL(res)
     let fileName = "Relatório LIVRO FISCAL.xlsx"
     link.href = url
     link.download = fileName
     link.click()
     window.URL.revokeObjectURL(url)
   })
   .catch((err: HttpErrorResponse) => {
     this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.message })
   })
} catch (err) {
 this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
} finally {
 this.httpService.done()
}



}else if (data === "livroCsrf") {
  //Header da requisiçãox

  _date = this.utilService.dateToStringDate(this.dtIni).split("/");
inicio = _date[2] + "-" + _date[1] + "-" + _date[0];
_date = this.utilService.dateToStringDate(this.dtFin).split("/");
final = _date[2] + "-" + _date[1] + "-" + _date[0];

const httpOptions = {
 headers: new HttpHeaders({
   'Authorization': this.configService.bearerApi,
   'Cache-Control': 'no-store, max-age=0'
 }),
 responseType: 'blob' as 'json',
}

const infos = {
 empresa_id: this.dblinkedSessionService.empresa.id,
 data_inicial: inicio,
 data_final: final,
 user_id: this.sessionService.loggedUser.id
}

this.httpService.wait()
try {
 this.httpClient.post(this.configService.defaultUrlApiUpload + 'fiscal/relatorio-pagamento-retidos', infos, httpOptions).toPromise()
   .then((res: any) => {
     let link = document.createElement('a')
     let url = window.URL.createObjectURL(res)
     let fileName = "Relatório APURAÇÃO CSRF.xlsx"
     link.href = url
     link.download = fileName
     link.click()
     window.URL.revokeObjectURL(url)
   })
   .catch((err: HttpErrorResponse) => {
     this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.message })
   })
} catch (err) {
 this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
} finally {
 this.httpService.done()
}



}
    this.change();
  }

  gerarRelatorioIcmsIpiRetidoFonte(tipoDoc: string) {
    let _date: any[];
    let inicio: string;
    let final: string;

    _date = this.utilService.dateToStringDate(this.dtIni).split("/");
    inicio = _date[2] + "-" + _date[1] + "-" + _date[0];
    _date = this.utilService.dateToStringDate(this.dtFin).split("/");
    final = _date[2] + "-" + _date[1] + "-" + _date[0];

    this.resetColunasExport()

    let urlDoc = ""
    let optionsDoc

    if(tipoDoc == "icmsIpi") {
      urlDoc = '/custom/fiscal/livros-icms-ipi'
      optionsDoc = [this.dblinkedSessionService.empresa.id, inicio, final, this.entradaSaida, this.detalhePorItem]
    }
    else if(tipoDoc == "retidosFonte") {
      urlDoc = '/custom/fiscal/livros-retidos-fonte'
      optionsDoc = [this.dblinkedSessionService.empresa.id, inicio, final, this.servico]
    } else if(tipoDoc == "demaisDocs") {
      urlDoc = '/custom/fiscal/livros-demais-docs'
      optionsDoc = [this.dblinkedSessionService.empresa.id, inicio, final, this.entradaSaida]
    }

    this.httpService.wait();
    this.httpService.get(
        urlDoc,
        optionsDoc
      )
      .subscribe(
        (res) => {

          if (res["error"]) {
            this.exibirMensagem(res);
          } else if(res.length > 0) {
            //Transforma o json em Excel
            const items = res

            this.exportOrderList = new Array()

            Object.keys(items[0]).forEach((item : string) => {
              this.exportOrderList.push((item))
            });

            if(!this.detalhePorItem) this.exportOrderList.slice(9,12)

            if (tipoDoc == "retidosFonte") {
              items.forEach(item => {
                item['dtSaida'] = deepCopy(item.dtEntrada)
                item['dtEntradaSaida'] = deepCopy(item.dtEntrada)
              })

              if (this.servico === 1) this.colunasRelatorioRetidoFonte.splice(0, 2)
              else if (this.servico === 0)  {
                this.colunasRelatorioRetidoFonte.splice(0, 1)
                this.colunasRelatorioRetidoFonte.splice(1, 1)
              }
              else if (this.servico === 2) this.colunasRelatorioRetidoFonte.splice(1, 2)

              this.exportOrderList = deepCopy(this.colunasRelatorioRetidoFonte)
            }

            exportExcel(items, tipoDoc+".xlsx" , this.exportOrderList)

          } else {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Alerta', detail: 'Não foi resgatado nenhum dado' })
          }
          this.httpService.done();
        }
      )
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  windowOpen(url: string, target?: string) {
    window.open(url, target);
  }

  baixarIcmsIpi() {
    var tipo: string = "";
    if (this.relatorioSelecionado === 0) {
      tipo = "ipi";
    } else {
      tipo = "icms";
    }

    //Header da requisiçãox
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        mes: this.dblinkedSessionService.periodo.month.value.toString(),
        ano: this.dblinkedSessionService.periodo.year.value.toString(),
        tipo: tipo,
        userid: this.sessionService.loggedUser.id.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .post(
        this.configService.defaultUrlApiUpload + "fiscal/relatorio-icms-ipi",
        null,
        httpOptions
      )
      .subscribe(
        (res) => {
          this.exibirMensagem(res);

          if (!res["error"]) {
            const httpOptions2 = {
              headers: new HttpHeaders({
                Authorization: this.configService.bearerApi,
                "Cache-Control": "no-store, max-age=0",
              }),

              responseType: "blob" as "json",
            };

            this.httpClient
              .get(
                this.configService.defaultUrlApiUpload +
                "/fiscal/baixar-relatorio-icms-ipi",
                httpOptions2
              )
              .subscribe(
                (res) => {
                  window.open(
                    this.configService.defaultUrlApiUpload +
                    "fiscal/baixar-relatorio-icms-ipi"
                  );
                },
                (err) => {
                  this.exibirMensagem(err);
                  console.log(err);
                }
              );
          }

          this.httpService.done();
        },
        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  exibirMensagem(value: any) {

    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  carregarMesAno() {
    this.httpService.wait();
    this.httpService.get("/custom/cadastro/menus-grupos?authUserId=").subscribe(
      (data) => {
        // a api retorna menu.permissions.url que o usuário tem acesso.
        data.value.forEach((retorno) => { });
      },
      (error) =>
        this.httpService.handleError(error, () => this.carregarMesAno()),
      () => this.httpService.done()
    );
  }

  gerarRelatorioGerencialExcel() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
      }),
    };

    let config_list = [];
    if (this.relatGerSelecionado.is_composta) {
      for (let conf of this.dicRelatComposto[this.relatGerSelecionado.id]) {
        if (this.datasIndividuais && conf["dataRef"] == null) {
          this.exibirMensagem({
            error: true,
            mensagem:
              "Informe uma data para todos os relatórios ou desmarque a opção de datas individuais.",
          });
          return;
        }

        config_list.push({
          empresa_id: this.dblinkedSessionService.empresa.id,
          dt_ref: this.datasIndividuais
            ? [
              conf["dataRef"].getDate(),
              conf["dataRef"].getMonth() + 1,
              conf["dataRef"].getFullYear(),
            ]
            : [
              this.dataRef.getDate(),
              this.dataRef.getMonth() + 1,
              this.dataRef.getFullYear(),
            ],
          config_id: conf["config_relat_ref_id"],
          worksheet_title: conf["worksheet_title"],
        });
      }
    } else {
      config_list = [
        {
          empresa_id: this.dblinkedSessionService.empresa.id,
          dt_ref: [
            this.dataRef.getDate(),
            this.dataRef.getMonth() + 1,
            this.dataRef.getFullYear(),
          ],
          config_id: this.relatGerSelecionado.id,
        },
      ];
    }

    const infos = {
      list_infos: config_list,
      is_composta: this.relatGerSelecionado.is_composta,
      user_id: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpClient
      .post(
        this.configService.defaultUrlApiUpload + "workstats/relat-gerencial",
        infos,
        httpOptions
      )
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();
          } else {
            const httpOptions2 = {
              headers: new HttpHeaders({
                Authorization: this.configService.bearerApi,
                "Cache-Control": "no-store, max-age=0",
                'userid': this.sessionService.loggedUser.id.toString()
              }),
              responseType: "blob" as "json",
            };

            this.httpClient
              .get(
                this.configService.defaultUrlApiUpload +
                "workstats/relat-gerencial",
                httpOptions2
              )
              .subscribe(
                (res: Blob) => {
                  let link = document.createElement("a");
                  let url = window.URL.createObjectURL(res);
                  let fileName = "Relatório gerencial.xlsx";
                  link.href = url;
                  link.download = fileName;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                (err) => {
                  if (err["error"] === null || err["error"] === undefined) {
                    this.msgs.push({
                      severity: "warn",
                      summary: "Erro",
                      detail:
                        "Um erro inesperado foi detectado no API. Entre o contato com o suporte.",
                    });
                  } else {
                    this.exibirMensagem(err);
                    console.log(err);
                    this.httpService.done();
                  }
                }
              );
          }
        },
        (err) => {
          if (err["error"] === null || err["error"] === undefined) {
            this.msgs.push({
              severity: "warn",
              summary: "Erro",
              detail:
                "Um erro inesperado foi detectado no API. Entre o contato com o suporte.",
            });
          } else {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        }
      );
  }

  gerarRelatorioPisCofins() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
      }),
    };

    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      periodo: this.periodo.getMonth() < 10 ? "0" + (this.periodo.getMonth() + 1).toString() + this.periodo.getFullYear().toString() : (this.periodo.getMonth() + 1).toString() + this.periodo.getFullYear().toString(),
      user_id: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + "fiscal/relat-pis-cofins", infos, httpOptions)
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();
          } else {
            const httpOptions2 = {
              headers: new HttpHeaders({
                Authorization: this.configService.bearerApi,
                "Cache-Control": "no-store, max-age=0",
                'userId': this.sessionService.loggedUser.id.toString()
              }),
              responseType: "blob" as "json",
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + "fiscal/relat-pis-cofins", httpOptions2)
              .subscribe(
                (res: Blob) => {
                  let link = document.createElement("a");
                  let url = window.URL.createObjectURL(res);
                  let fileName = "Apuração PIS-COFINS.xlsx";
                  link.href = url;
                  link.download = fileName;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                (err) => {
                  if (err["error"] === null || err["error"] === undefined) {
                    this.msgs.push({
                      severity: "warn",
                      summary: "Erro",
                      detail:
                        "Um erro inesperado foi detectado no API. Entre o contato com o suporte.",
                    });
                  } else {
                    this.exibirMensagem(err);
                    console.log(err);
                    this.httpService.done();
                  }
                }
              );
          }
        },
        (err) => {
          if (err["error"] === null || err["error"] === undefined) {
            this.msgs.push({
              severity: "warn",
              summary: "Erro",
              detail:
                "Um erro inesperado foi detectado no API. Entre o contato com o suporte.",
            });
          } else {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        }
      );
  }

  renderizarRelatorioGerencial() {
    var data_ref: string = this.dataRef.getDate() + '-' + (this.dataRef.getMonth() + 1).toString() + '-' + this.dataRef.getFullYear();
    window.open('/workstats/render-relatorios-gerenciais/' + this.dblinkedSessionService.empresa.id.toString() + '/' + this.relatGerSelecionado.id + '/' + this.relatGerSelecionado.plano_gerencial_id + '/' + data_ref, '_blank');
  }

  getConfigRelatorios() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        modulo: '4'
      }),
    };

    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload + "cadastro/config-relat",
        httpOptions
      )
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res);
          } else {
            this.listaRelatGer = new Array();
            for (var line of res["mensagem"]) {
              this.listaRelatGer.push(line);
            }

            this.dicRelatComposto = res["dicconfigs_compostas"];
          }
          this.httpService.done();
        },
        (err) => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  carregarValores(config) {
    let replicar = false;

    for (let dicConfig of this.dicRelatComposto[this.relatGerSelecionado.id]) {
      if (config["id"] === dicConfig["id"]) replicar = true;

      if (replicar) {
        dicConfig["dataRef"] = config["dataRef"];
      }
    }
  }


  relExt(value: any){
    this.opcoesRelatoriosExternos = [];
        this.opcoesRelatoriosExternos.push({ label: 'Selecione', value: null });

        this.existeRelatorioExterno = value.length > 0 ? true : false;

    value.forEach(element => {
      this.opcoesRelatoriosExternos.push({ label: element.nome, value: element.id, param: element.parametro });
    });

  }


  gerarRelatorioExterno(){
    const listasEnviar = {
      cnpj: this.dblinkedSessionService.empresa.cnpj,
      dtIni: this.dtIni,
      dtFin: this.dtFin,
      relExterno: this.relExterno,
      filial: this.filialRelatorio
    };
    this.httpService.wait();
    this.httpService.post('/custom/ecd-report/gerar-relatorio-externo', listasEnviar)
      .subscribe(result => this.downloadRelExt(result),
        error => this.httpService.handleError(error, () => this.gerarRelatorioExterno()),
        () => this.httpService.done());
  }

  downloadRelExt(result: any) {


      this.windowOpen(this.httpService.url('/custom/file/' + result.file), '_blank');

  }

  relatorioExterno(event: any){


    this.exibirDtIni  = false;
    this.exibirDtFin  = false;
    this.exibirFilial = false;
    this.filialRelatorio = '';

    this.opcoesRelatoriosExternos.forEach(element => {


      if(!isNullOrUndefined(element.value)){



        if(element.value == event.value){

          if(element.param.toString().match('dtIni')){

            this.exibirDtIni = true;
          }
          if(element.param.toString().match('dtFin')){

            this.exibirDtFin = true;
          }
          if(element.param.toString().match('filial')){

            this.exibirFilial = true;
          }
      }
      }


    });


  }

  showDialogRelatorio(tipo) {

  if (tipo === 'EXT') {
        this.statusRelExt = true;


  }
}

reportPcOld(data: any) {
  let _date: any[];
  let inicio: string;
  let final: string;
  let detalhe: number;
  detalhe = this.detalhePorItem ? 0 : 1;
  _date = this.utilService.dateToStringDate(this.dtIni).split("/");
  inicio = _date[2] + "-" + _date[1] + "-" + _date[0];
  _date = this.utilService.dateToStringDate(this.dtFin).split("/");
  final = _date[2] + "-" + _date[1] + "-" + _date[0];
  if (data === "icmsIpi") {
    this.windowOpen(
      "/fiscal/report-icms-ipi/" +
      this.dblinkedSessionService.empresa.id +
      "/" +
      inicio +
      "/" +
      final +
      "/" +
      this.entradaSaida +
      "/" +
      detalhe,
      "_blank"
    );
  } else if (data === "retidosFonte") {
    this.windowOpen(
      "/fiscal/report-retidos-fonte/" +
      this.dblinkedSessionService.empresa.id +
      "/" +
      inicio +
      "/" +
      final +
      "/" +
      this.servico,
      "_blank"
    );
  } else if (data === "demaisDocs") {
    this.windowOpen(
      "/fiscal/report-demais-docs/" +
      this.dblinkedSessionService.empresa.id +
      "/" +
      inicio +
      "/" +
      final +
      "/" +
      this.entradaSaida,
      "_blank"
    );
  }
  this.change();
}

  resetColunasExport() {
    this.colunasRelatorioRetidoFonte = [
      'dtEntradaSaida',
      'dtEntrada',
      'dtSaida',
      'dtEmissao',
      'cnpj',
      'razao',
      'mun',
      'uf',
      'regTrib',,
      'status_cnpj',
      'atualizado',
      'numDoc',
      'status',
      'codList',
      'codItem',
      'descItem',
      'vlrOper',
      'deducao',
      'vlrServico',,
      'aliqIssqn',
      'vlrIssqn',
      'aliqIrrf',,
      'vlIrrf',
      'aliqCsrf',
      'vlCsrf',
      'aliqInss',
      'vlrInss',
    ];
  }
}
