import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { ConfigService } from "app/shared/services/config.service";
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service";
import { HttpService } from "app/shared/services/http.service";
import { SessionService } from "app/shared/services/session.service";
import { UtilService } from "app/shared/services/util.service";
import { Message } from "primeng/api";
import { isNullOrUndefined } from "util";


@Component({
  selector: 'app-pedido-edit',
  templateUrl: './pedido-edit.component.html',
  styleUrls: ['./pedido-edit.component.scss'],
  animations: []
})

export class PedidoEditComponent implements OnInit, OnDestroy {

  @Input() pedidoId : any;
  @Input() userId   : any;

  checkPerm         : boolean;
  editPerm         : boolean;
  parcelas          : Array<any>
  pt_BR             : any;
  msgs              : Message[];
  part              : String;
  codPed            : String;
  status            : String;
  codigoControle    : String;

  constructor(
    private configService: ConfigService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
  ) {


  }

  ngOnInit() {

    this.msgs = [];
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    setTimeout(() => {
      this.empresaChanged()
    }, 2000);


  }

  ngOnDestroy() {

  }


  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.editPerm = false;

      this.httpService.wait();
      this.httpService.get('/custom/permissoes/get-permissao-wf('+ this.dblinkedSessionService.empresa.id+',' + this.userId + ',' + ('pedido') +')')
      .subscribe(ret => this.verificaPermissao(ret),
      error => this.httpService.handleError(error, () => this.empresaChanged()),
      () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/pedido/buscar-infos-pedido('+this.pedidoId+')')
      .subscribe(ret => this.getPedidoId(ret),
      error => this.httpService.handleError(error, () => this.empresaChanged()),
      () => this.httpService.done());


    }

  }

  verificaPermissao(event: any){
    this.checkPerm = event.check;

  }



  hideMessage(){
    this.msgs = [];
  }



  editar(){
    this.msgs = [];
    if(!this.checkPerm){
      this.msgs.push({ severity: 'warn', summary: '', detail: 'Você não tem permissão para editar financeiro, somente leitura' });
      this.editPerm = false;
    } else {
      this.editPerm = true;
    }
  }

  getPedidoId(event: any){
    console.log(event);

    this.part              = event.participante.fantasia;
    this.codPed            = event.codigo;
    this.status            = event.pedidoStatus.description;
    this.codigoControle    = event.codigoControle;

  }



}
