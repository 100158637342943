import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Period } from 'app/shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { AppComponent } from '../../app.component';
import { CrudService } from 'app/shared/services/crud.service';
import { Regra } from 'app/cadastro/model/regra.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';
import { GatilhoObrigacaoDisparo } from 'app/centralweb/model/gatilho-obrigacao-disparo.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';

@Component({
  selector: 'app-gatilho-obrigacao',
  templateUrl: './gatilho-obrigacao.component.html',
  styleUrls: ['./gatilho-obrigacao.component.scss'],
  providers: [CrudService]
})
export class GatilhoObrigacaoComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  gatilhoObrigacao: GatilhoObrigacao;
  gatilhoEditar: GatilhoObrigacao;
  gatilhos: any[];
  modalEditarGatilho: boolean;
  gatilhoDisparo: GatilhoObrigacaoDisparo;
  gatilhoDisparoList: Array<GatilhoObrigacaoDisparo>;
  dias: any[];
  title: string;
  subtitle: string;

  constructor(private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent) {
  }

  ngOnInit() {
    this.title = 'GATILHOS DE OBRIGAÇÃO';
    this.subtitle = 'CONFIGURAR GATILHOS DE OBRIGAÇÃO PARA EMITIR ALERTAS AO USUÁRIO REFERENTES AO VENCIMENTO DE OBRIGAÇÃO';
    // this.toolbarRefresh();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titleService.title = 'Configurar Gatilho de Obrigaçao';
    this.gatilhoObrigacao = new GatilhoObrigacao();
    this.gatilhos = new Array<any>();
    this.modalEditarGatilho = false;
    this.gatilhoEditar = new GatilhoObrigacao();
    this.gatilhoDisparo = new GatilhoObrigacaoDisparo();
    this.gatilhoDisparoList = new Array<GatilhoObrigacaoDisparo>();
    this.dias = [];
    this.gatilhoDisparo.gatilhoObrigacao = new GatilhoObrigacao();

    this.dias.push({ label: '0', value: 0 });
    for (let i = 1; i < 61; i++) {
      this.dias.push({ label: i, value: i });
    }

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => this.carregarInfos());
  }

  carregarInfos() {

    this.gatilhoEditar.gatilhoObrigacaoDisparoList = new Array<GatilhoObrigacaoDisparo>();

    this.httpService.wait();
    this.httpService.get('/gatilhoobrigacao?$select=id,descricao' +
      '&$orderby=descricao')
      .subscribe(ref => this.loadGatilhos(ref.value),
      error => this.httpService.handleError(error, () => this.carregarInfos()),
      () => this.httpService.done());
  }


  ngOnDestroy() {

    this.toolbarService.hidden = false;
    // this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  adicionarGatilho() {
    this.gatilhoDisparo.gatilhoObrigacao = this.gatilhoEditar;

    this.gatilhoDisparoList.push(this.gatilhoDisparo);

    this.atualizarLista();

    this.httpService.wait();
    this.httpService.post('/gatilhoobrigacaodisparo', this.gatilhoDisparo)
      .subscribe(() => this.carregarGatilho(),
      error => this.httpService.handleError(error, () => this.adicionarGatilho()),
      () => this.httpService.done());
  }


  loadGatilhos(value: Array<any>) {

    this.gatilhos = new Array();
    this.gatilhos.push({ label: 'Selecione um Gatilho', value: null });
    value.forEach(element => {
      if (element.descricao !== 'Nenhum Aviso') {
        this.gatilhos.push({ label: element.descricao, value: element });
      }
    });
  }

  salvarGatilho() {
    this.httpService.wait();
    this.httpService.post('/gatilhoobrigacao', this.gatilhoObrigacao)
      .subscribe(() => this.ngOnInit(),
      error => this.httpService.handleError(error, () => this.salvarGatilho()),
      () => this.httpService.done());
  }

  deletarGatilho() {
    if (confirm('Deseja realmente excluir este gatilho?')) {
      this.httpService.wait();
      this.httpService.delete('/gatilhoobrigacao', this.gatilhoEditar.id)
        .subscribe(() => this.ngOnInit(),
        error => this.httpService.handleError(error, () => this.deletarGatilho()),
        () => this.httpService.done());
    }
  }

  carregarGatilho() {
    if (this.gatilhoEditar) {
      this.modalEditarGatilho = true;
      this.httpService.wait();
      this.httpService.get('/gatilhoobrigacaodisparo?$select=id,dias,gatilhoObrigacao' +
        '&$filter=gatilhoObrigacao/id eq ' + this.gatilhoEditar.id +
        '&$orderby=id')
        .subscribe(ref => this.loadDisparos(ref.value),
        error => this.httpService.handleError(error, () => this.carregarGatilho()),
        () => this.httpService.done());
    } else {
      this.gatilhoDisparoList = new Array();
      this.modalEditarGatilho = false;
    }
  }

  loadDisparos(value: Array<any>) {
    this.gatilhoDisparoList = new Array();
    value.forEach(element => {
      if (element.descricao !== 'Nenhum Aviso') {
        this.gatilhoDisparoList.push(element);
      }
    });

    this.atualizarLista();
  }

  removerDisparo(event: any) {
    if (confirm('Deseja realmente excluir este disparo?')) {
      const index = this.gatilhoDisparoList.findIndex(c => c.dias === event.dias);
      this.gatilhoDisparoList.splice(index, 1);

      this.httpService.wait();
      this.httpService.delete('/gatilhoobrigacaodisparo', event.id.toString())
        .subscribe(() => this.atualizarLista(),
        error => this.httpService.handleError(error, () => this.removerDisparo(event)),
        () => this.httpService.done());
    }
  }

  atualizarLista() {
    this.dias = [];

    this.dias.push({ label: 'dias', value: null });
    this.dias.push({ label: '0', value: 0 });

    if ([1, 2, 3].indexOf(this.gatilhoDisparoList.length) > -1) {
      for (let i = 1; i < this.gatilhoDisparoList[this.gatilhoDisparoList.length - 1].dias; i++) {
        this.dias.push({ label: i, value: i });
      }
    } else {
      for (let i = 1; i < 61; i++) {
        this.dias.push({ label: i, value: i });
      }
    }
  }
 /*  private toolbarRefresh() {
    this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  } */
}
