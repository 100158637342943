import { Component, OnInit } from '@angular/core';
import { CadEcf } from '../model/cad-ecf.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'app-cad-ecf',
  templateUrl: './cad-ecf.component.html',
  styleUrls: ['./cad-ecf.component.scss'],
  providers: [CrudService]
})
export class CadEcfComponent extends DataTableBasicComponent implements OnInit {
  public cadEcf: CadEcf = new CadEcf();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('Escrituração', 'escrRetif'),
      new DataTableColumn('Num Rec Ant', 'numRecAnt'),
      new DataTableColumn('Data Ini', 'dtIni'),
      new DataTableColumn('Data Fin', 'dtFin'),
      new DataTableColumn('Tipo Ecf', 'tipoEcf'),
      new DataTableColumn('Empresa', 'empresa.razaoSocial')
    ];

    this.controls = [
      new DataControl('Escrituração', 'escrRetif')
          .setRequired()
          .setMinLength(1)
          .setMaxLength(1),
      new DataControl('Numero', 'numRecAnt')
          .setRequired()
          .setMinLength(2)
          .setMaxLength(255),
      new DataControl('Data Ini', 'dtIni')
          .setRequired()
          .setMinLength(2)
          .setMaxLength(255),
      new DataControl('Data Fim', 'dtFin')
          .setRequired()
          .setMinLength(2)
          .setMaxLength(255),
      new DataControl('Tipo', 'tipoEcf')
          .setRequired()
          .setMinLength(1)
          .setMaxLength(1),
      new DataControl('Empresa', 'empresa')
          .setTypeSelect()
          .setOptions({ url: '/empresa', value: 'id', label: 'razaoSocial' })
    ];
  }
}
