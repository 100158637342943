import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { UtilService } from 'app/shared/services/util.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { TitleService } from 'app/shared/services/title.service';
import { LancamentoPartida } from 'app/contabil/model/lancamento-partida.model';
import { Lancamento } from 'app/contabil/model/lancamento.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadHistPadrao } from 'app/contabil/model/cad-hist-padrao.model';
import { CadProj } from '../model/cad-proj.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { isNullOrUndefined } from 'util';
import { LazyLoadEvent, Message, MessageService, SelectItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import LogManagement from 'app/shared/common/log-management/log-control';
import { HttpClient } from '@angular/common/http';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';

@Component({
  selector: 'app-lancamento',
  templateUrl: './lancamento.component.html',
  styleUrls: ['./lancamento.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ],

})

export class LancamentoComponent implements OnInit, OnDestroy {
  dataIni: Date;
  dataFin: Date;
  planoContasSelecionado: PlanoContas;
  numLcto: string;
  lote: string;
  historico: string;
  valorPesquisaDe: any;
  valorPesquisaAte: any;
  modalLctoContabil: boolean;
  novoLcto: boolean;
  checkBoxTodos: boolean;

  private sessionSubscription: Subscription;
  planoContas: Array<PlanoContas> = new Array();
  lancamentoContabil: Lancamento;
  lctos: Array<any> = new Array();
  filteredLctos: Array<any> = new Array();
  opcoesPlanoContas: SelectItem[];
  campo: SelectItem[];
  idLctos: Array<any> = new Array();
  selectedReleases: Array<any> = new Array();

  opcoesDebitoCredito: SelectItem[];
  newPartidaLancamento: LancamentoPartida;
  private empresaSubscription: Subscription;
  title: string;
  subtitle: string;

  lcto: Lancamento;
  lctoPartidas: Array<LancamentoPartida> = new Array();
  centroCustosOpcoes: Array<CadCcus> = new Array();
  opcoesCentroCustos: SelectItem[];
  opcoesProjetos: SelectItem[];
  opcoesCadHist: SelectItem[];
  valorLancamento: number;
  valorLancamentoCredito: number;
  valorLancamentoDebito: number;
  msgs: Message[];
  msgsModal: Message[];

  planoContasSelecionados: Array<number> = new Array();
  planoContasEnviar: number[];
  lctoEditar: any;
  editarLcto: boolean;
  defaultDate: Date;
  validaCampos: boolean;
  primeiroDiadoMes: Date;
  ultimoDiadiaMes: Date;
  listaEnviar: any;
  centroCustoObrigatorio: boolean;
  centroCustoId: number;
  opcoesPlanoContasEscolhido: any;
  opcoesCadHistEscolhido: any;
  opcoesCentroCustosEscolhido: any;
  opcoesProjetosEscolhido: any;

  colsLancamentos: any[];

  modalLancamentoClone: boolean;
  loteClone: any = undefined;
  dataClone: Date = undefined;

  currentStatePesquisaLancamentos = 'initial';
  currentStateResultadosLancamentos = 'initial';
  currentStateResultadosDetalhesLancamento = 'initial';

  exibePesquisa: boolean;
  exibeResultados: boolean;
  exibeDetalhesResultado: boolean;

  // Variáveis usadas na clonagem de lançamentos
  dadosClonagem: any;
  operacaoClonagem: boolean = false;
  nomeLoteOriginal: any;

  // Variáveis usadas na recorrência de lançamentos
  meses: any;
  ocorrenciaMes: any;
  recorrencia: any;
  antecipaDiaNaoUtil: any;
  dtFimOcorrencia: Date;

  //Modal contas
  modalContasMultiplas: boolean = false
  listaContas: Array<any> = new Array()
  totalContas: number = null
  selectedContas: Array<any> = new Array()
  modalContasUnicas: boolean = false
  partidaSelecionada: any = {}

  redirectNewLaunch: boolean
  visualizarContaAutomatica: boolean

  private LogManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.title = 'LANÇAMENTOS';
    this.subtitle = 'INFORMAÇÕES SOBRE LANÇAMENTOS E CONTRA-PARTIDA DOS LANÇAMENTOS';
    this.titleService.title = 'LANÇAMENTOS CONTÁBEIS';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.planoContasSelecionado = null;
    this.numLcto = '';
    this.lote = '';
    this.historico = '';
    this.valorPesquisaDe = '';
    this.valorPesquisaAte = '';
    this.defaultDate = new Date();

    this.primeiroDiadoMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth(), 1);
    this.ultimoDiadiaMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);

    this.dataIni = this.primeiroDiadoMes;
    this.dataFin = this.ultimoDiadiaMes;

    this.listaEnviar = null;
    this.visualizarContaAutomatica = true;

    this.colsLancamentos = [
      { field: 'contaContabilDesc', header: 'CONTA CONTÁBIL' },
      { field: 'indDc', header: 'D/C' },
      { field: 'valor', header: 'VALOR' },
      { field: 'cadHistPadraoDesc', header: 'HIST PADRÃO' },
      { field: 'histCompl', header: 'HIST COMPLEMENTAR' },
      { field: 'cadCcusDesc', header: 'CENTRO DE CUSTO' },
      { field: 'cadProjDesc', header: 'PROJETO' },
    ]

    this.exibePesquisa = true;
    this.exibeResultados = false;
    this.exibeDetalhesResultado = false;
    this.modalLancamentoClone = false;

    this.meses = [];
    this.meses.push({ label: "SELECIONE", value: null });
    for (let mes = 1; mes <= 12; mes++) {
      this.meses.push({ label: mes, value: mes });
    }

    this.toolbarService.clear();

    this.activatedRoute.params.subscribe(params => {
      this.redirectNewLaunch = params.action == 'newLaunch'
    })

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if(this.redirectNewLaunch) this.criarLcto()
      else this.carregar()
    })

  }

  carregar() {

    if (!this.dblinkedSessionService.hasEmpresaSelected || !this.dblinkedSessionService.hasYearSelected) {
      return;
    }

    this.novoLcto = false;
    this.valorLancamento = 0.00;
    this.valorLancamentoCredito = 0.00;
    this.valorLancamentoDebito = 0.00;
    this.checkBoxTodos = false;
    this.validaCampos = false;
    this.lctoEditar = '';
    this.editarLcto = false;
    this.carregarDadosParametroContabil();

    this.opcoesPlanoContasEscolhido = undefined;
    this.opcoesCadHistEscolhido = undefined;
    this.opcoesCentroCustosEscolhido = undefined;
    this.opcoesProjetosEscolhido = undefined;

    this.recorrencia = false;
    this.antecipaDiaNaoUtil = false;

    if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
      const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
        this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
      const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
      this.defaultDate = new Date(dt);
      this.primeiroDiadoMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth(), 1);
      this.ultimoDiadiaMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
      this.dataIni = this.primeiroDiadoMes;
      this.dataFin = this.ultimoDiadiaMes;
    }

    this.lctos = new Array();
    this.filteredLctos = new Array();
    this.modalLctoContabil = false;
    this.lancamentoContabil = new Lancamento();
    this.idLctos = new Array();
    this.newPartidaLancamento = new LancamentoPartida();

    this.opcoesDebitoCredito = [];

    if (this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id) {

      this.opcoesCentroCustos = [];
      this.opcoesCadHist = [];
      this.centroDeCustoObrigatorio();

      this.opcoesDebitoCredito.push({ label: 'D', value: 'D' });
      this.opcoesDebitoCredito.push({ label: 'C', value: 'C' });

      // this.httpService.wait();
      // this.httpService.get('/custom/cadastro/get-planocontas-completo',
      //   [this.dblinkedSessionService.empresa.id, this.dblinkedSessionService.periodo.year.value])
      //   .subscribe(ref => {
      //     this.opcoesPlanoContas = [];
      //     this.planoContasSelecionados = []
      //     ref.forEach(element => {
      //       if (element.indCta === 'A') {
      //         this.opcoesPlanoContas.push({ label: element.codCta + ' - ' + element.descrCta, value: element.id });
      //       }

      //     });

      //     if (!isNullOrUndefined(this.opcoesPlanoContas) && this.opcoesPlanoContas.length > 0) {
      //       this.opcoesPlanoContas.map(item => this.planoContasSelecionados.push(item.value));
      //     }

      //   },
      //     error => this.httpService.handleError(error, () => this.carregar()),
      //     () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/cadccus?$select=id,dtAlt,codCcus,descrCcus' +
        '&$filter=empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id) +
        ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value +
        '&$orderby=codCcus')
        .subscribe(cadCcus => this.centroCustoLoad(cadCcus.value),
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/cadhistpadrao?$select=id,codHist,descrHist' +
        '&$filter=empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id) +
        ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value + '&$orderby=codHist')
        .subscribe(ref => this.loadCadHist(ref.value),
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());
    }

    this.exibePesquisa = true;
    this.exibeResultados = false;
    this.exibeDetalhesResultado = false;

  }

  /* As 3 funções abaixo controlam a abertura e o fechamento dos cards
  usados nas telas de Lançamentos */
  changeStatePesquisaLancamentos() {
    this.currentStatePesquisaLancamentos = this.currentStatePesquisaLancamentos === 'initial' ? 'final' : 'initial';
  }

  changeStateResultadosLancamentos() {
    this.currentStateResultadosLancamentos = this.currentStateResultadosLancamentos === 'initial' ? 'final' : 'initial';
  }

  changeStateResultadosDetalhesLancamento() {
    this.currentStateResultadosDetalhesLancamento = this.currentStateResultadosDetalhesLancamento === 'initial' ? 'final' : 'initial';
  }

  /* Show/Hide Card Pesquisa Lançamentos */
  isCardPesquisaLancamentos: boolean = true;
  showHideCardPesquisaLancamentos() {
    this.isCardPesquisaLancamentos = !this.isCardPesquisaLancamentos;
  }

  /* Show/Hide Card Resultados Lançamentos */
  isCardResultadosLancamentos: boolean = true;
  showHideCardResultadosLancamentos() {
    this.isCardResultadosLancamentos = !this.isCardResultadosLancamentos;
  }

  /* Show/Hide Card Detalhes Resultado Lançamentos */
  isCardDetalhesResultadoLancamentos: boolean = true;
  showHideCardDetalhesResultadoLancamentos() {
    this.isCardDetalhesResultadoLancamentos = !this.isCardDetalhesResultadoLancamentos;
  }

  /* Função que relaciona um id com uma descrição de conta contábil,
  centro de custo ou projeto */
  verificaContaContabil(event: any, dados: any, tipoDado: any) {


    if (event.planoContas) {

      if (tipoDado === "conta") {
        event.planoContas.id = dados;
        event.contaContabilDesc = dados.descrCta;
        this.opcoesPlanoContasEscolhido = undefined;

      } else if (tipoDado === "centroCusto") {
        event.cadCcus.id = dados;
        event.cadProj.id = null;
        this.opcoesCentroCustosEscolhido = undefined;

        if (event.cadCcus.id != null) {
          event.cadCcusDesc = this.obterCadCcus(event);
        } else {
          event.cadCcusDesc = 'SELECIONE';
        }

        this.carregarProjetos(event);

      } else if (tipoDado === "projeto") {
        event.cadProj.id = dados;
        event.cadProjDesc = this.obterCadProj(event, event.opcoesProjetos);
        this.opcoesProjetosEscolhido = undefined;

      }

      if ((!event.planoContas.id || !event.planoContas) && event.valor !== 0) {

        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Partida com valor sem Conta Contábil informada!' });
      }
    } else if (event.valor !== 0) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Partida com valor sem Conta Contábil informada!' });
    }

  }

  loadCadHist(value: Array<any>) {
    this.opcoesCadHist = [];

    this.opcoesCadHist.push({ label: 'SELECIONE', value: null });
    this.opcoesCadHist.push({ label: '', value: null });
    value.forEach(element => {
      this.opcoesCadHist.push({ label: element.codHist + ' - ' + element.descrHist, value: element.id });
    });
  }

  centroCustoLoad(value: Array<CadCcus>) {
    this.centroCustosOpcoes = value;
    this.opcoesCentroCustos = [];
    this.opcoesCentroCustos.push({ label: 'SELECIONE', value: null });
    this.opcoesCentroCustos.push({ label: '', value: null });
    if (this.centroCustosOpcoes.length > 0) {
      this.centroCustosOpcoes.forEach(element => {
        this.opcoesCentroCustos.push({ label: element.codCcus + ' - ' + element.descrCcus, value: element.id });

      });
    }

  }

  /* Função que calcula o valor total envolvido em um lançamento.
  Para que este seja válido, a soma deve ser igual a zero. */
  calculaValorLancamento() {

    this.valorLancamento = 0.00;
    this.valorLancamentoCredito = 0.00;
    this.valorLancamentoDebito = 0.00;
    this.lctoPartidas.forEach(element => {
      if (element.planoContas) {

        if (element.indDc === 'D') {
          this.valorLancamento = this.utilService.parseDecimal(this.valorLancamento) + this.utilService.parseDecimal(element.valor);
          this.valorLancamentoDebito = this.utilService.parseDecimal(this.valorLancamentoDebito) + this.utilService.parseDecimal(element.valor);

          // Exibir o valor total de débito com limite de 2 casas decimais
          this.valorLancamentoDebito = parseFloat(this.valorLancamentoDebito.toFixed(2));
        } else if (element.indDc === 'C') {
          this.valorLancamento = this.utilService.parseDecimal(this.valorLancamento) - this.utilService.parseDecimal(element.valor);
          this.valorLancamentoCredito = this.utilService.parseDecimal(this.valorLancamentoCredito) + this.utilService.parseDecimal(element.valor);

          // Exibir o valor total de crédito com limite de 2 casas decimais
          this.valorLancamentoCredito = parseFloat(this.valorLancamentoCredito.toFixed(2));
        }

        if (!element.planoContas.id && element.valor !== 0.00) {

          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Partida com valor sem Conta Contábil informada!' });
        }
      } else if (element.valor !== 0.00) {

        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Partida com valor sem Conta Contábil informada!' });
      }

    });

  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionSubscription.remove(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  /* Função que faz uma busca por Lançamentos com o uso de alguns filtros,
  retornando os resultados obtidos. */
  pesquisar() {

    this.exibeResultados = true;


    this.validaCampos = true;
    this.nomeLoteOriginal = null;

    if (!this.dataIni) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Campo Data Inicial é obrigatório para a pesquisa' });
      this.validaCampos = false;
    }

    if (!this.dataFin) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Campo Data Final é obrigatório para a pesquisa' });
      this.validaCampos = false;
    }

    if (this.dataIni || this.dataFin) {
      if ((this.dataFin) < (this.dataIni)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Data inicial deve ser menor ou igual a data final!' });
        this.validaCampos = false;
      }
    }

    // if (!(this.planoContasSelecionados.length > 0) && !this.lote && !this.numLcto) {
    //   this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'É necessário selecionar uma conta contábil para a pesquisa' });
    //   this.validaCampos = false;
    // } else {
    //   this.planoContasEnviar = [];
    //   this.planoContasSelecionados.forEach(element => {
    //     this.planoContasEnviar.push(element);
    //   });
    // }

    if (this.valorPesquisaDe || this.valorPesquisaAte) {
      if (this.utilService.parseDecimal(this.valorPesquisaAte) < this.utilService.parseDecimal(this.valorPesquisaDe)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Valor inicial deve ser menor ou igual ao valor final!' });
        this.validaCampos = false;
      }
    }

    if (this.validaCampos) {
      const listasEnviar = {
        dtIni: this.dataIni,
        dtFin: this.dataFin,
        lote: this.lote,
        numeroLcto: this.numLcto,
        historico: this.historico,
        valorPesquisaDe: this.utilService.parseDecimal(this.valorPesquisaDe),
        valorPesquisaAte: this.utilService.parseDecimal(this.valorPesquisaAte),
        planoContas: this.planoContasSelecionados,
        usuario: this.sessionService.loggedUser.id,
        empresa: this.dblinkedSessionService.empresa.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/ecd/pesquisar-lancamentos', listasEnviar)
        .subscribe(result => this.carregarLctos(result),
          error => this.httpService.handleError(error, () => this.pesquisar()),
          () => this.httpService.done());

    }
  }

  /* Função que remove uma linha do grid relacionado a uma partida de um Lançamento */
  removerLinha(event: any) {
    if (confirm('Deseja excluir esta partida do Lançamento?')) {
      const index = this.lctoPartidas.findIndex(c => c === event);
      this.lctoPartidas.splice(index, 1);

      this.calculaValorLancamento();
    }
  }


  pesquisarLcto(lcto: any) {
    this.modalLctoContabil = true;

    this.httpService.wait();
    this.httpService.get('/lancamento?$select=id,numero,lote,dataLcto,indLcto,ativo&$filter=id eq ' + lcto.lctoId)
      .subscribe(ref => this.loadLcto(ref.value),
        error => this.httpService.handleError(error, () => this.pesquisarLcto(lcto)),
        () => this.httpService.done());
  }

  loadLcto(event: any) {

    this.lcto = event[0];
    this.lcto.dataLcto = this.utilService.dateWithoutTimeZone(event[0].dataLcto);

    this.httpService.wait();
    this.httpService.get('/lancamentopartida?$select=id,valor,indDc,planoContas/id,planoContas/codCta,planoContas/descrCta,' +
      'planoContas/indCta,numArq,cadHistPadrao/id,histCompl,cadCcus/id,cadProj/id' +
      '&$filter=lancamento/id eq ' + event[0].id + '&$orderby=valor')
      .subscribe(ref => {
        this.loadLctoPartida(ref.value);
      },
        error => this.httpService.handleError(error, () => this.loadLcto(event)),
        () => this.httpService.done());

  }

  loadLctoPartida(event: any) {

    event.forEach(element => {
      if (!element.cadCcus) {
        element.cadCcus = new CadCcus();
      } else {
        element.cadCcus = new CadCcus(element.cadCcus.id);
        element.cadCcusDesc = this.obterCadCcus(element);
      }
      if (!element.cadProj) {
        element.cadProj = new CadProj();
      } else {
        element.cadProj = new CadProj(element.cadProj.id);
        this.carregarProjetos(element);
      }
      if (!element.planoContas) {
        element.planoContas = new PlanoContas();
      } else {
        element.contaContabilDesc = element.planoContas.codCta + " - " + element.planoContas.descrCta
        element.planoContas = new PlanoContas(element.planoContas.id);
      }
      if (!element.cadHistPadrao) {
        element.cadHistPadrao = new CadHistPadrao();
      } else {
        element.cadHistPadrao = new CadHistPadrao(element.cadHistPadrao.id);
        element.cadHistPadraoDesc = this.obterCadHist(element);
      }

      element.valor = this.utilService.parseDecimal(element.valor);
    });

    this.lctoPartidas = event;
    this.novoLcto = true;

    // Caso seja feita uma operação de clonagem de lançamento, chamar o método "iniciarClonagem()"
    if (this.operacaoClonagem == true) {
      Promise.resolve(this.calculaValorLancamento()).then(() => this.iniciarClonagem());
    } else {
      this.calculaValorLancamento();
    }

  }

  carregarLctos(event: any) {

    this.lctos = new Array();

    if (event.length > 0) {
      this.lctos = event;
      this.filteredLctos = event;
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Nenhum resultado encontrado' });
    }

  }

  /* Função que cria o grid a ser preenchido com dados de um novo Lançamento */
  criarLcto() {
    console.log('chamou')
    this.editarLcto = false;

    this.exibePesquisa = false;
    this.exibeResultados = false;
    this.exibeDetalhesResultado = true;

    this.novoLcto = true;
    this.lcto = new Lancamento();
    this.lcto.dataLcto = this.defaultDate;
    this.lctoPartidas = new Array();

    for (let i = 0; i < 1; i++) {

      const partidaD = new LancamentoPartida();
      partidaD.planoContas = new PlanoContas();
      partidaD.cadCcus = new CadCcus();
      partidaD.cadProj = new CadProj();
      partidaD.cadHistPadrao = new CadHistPadrao();
      partidaD.indDc = 'D';
      partidaD.valor = 0.00;
      this.lctoPartidas.push(partidaD);

      const partidaC = new LancamentoPartida();
      partidaC.planoContas = new PlanoContas();
      partidaC.cadCcus = new CadCcus();
      partidaC.cadProj = new CadProj();
      partidaC.cadHistPadrao = new CadHistPadrao();
      partidaC.indDc = 'C';
      partidaC.valor = 0.00;
      this.lctoPartidas.push(partidaC);

    }

    this.valorLancamento = 0;
    this.valorLancamentoCredito = 0.00;
    this.valorLancamentoDebito = 0.00;

  }

  /* Função que salva/atualiza os dados de um Lançamento no Banco de Dados */
  salvarLcto() {


    this.lcto.lancamentoPartidaList = new Array();
    this.lcto.lancamentoPartidaList = this.lctoPartidas;

    if (this.lctoPartidas.length > 0) {

      this.lctoPartidas.forEach(element => {
        element.valor = this.utilService.parseDecimal(element.valor);

      });

      const listaEnviar = {
        infos: this.lcto,
        partidas: this.lctoPartidas,
        usuario: this.sessionService.loggedUser.id,
        empresa: this.dblinkedSessionService.empresa.id,
        editarLcto: this.editarLcto,
        centroCustoObrigatorio: this.centroCustoObrigatorio
      };

      this.httpService.wait();
      this.httpService.post('custom/ecd/salvar-lcto-contabil', listaEnviar)
        .subscribe(result => {
          if (result[0].error === true) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: result[0].message });
          } else {
            if (this.recorrencia == false) {
              this.LogManagement.registerLog(
                LogModule.ACCOUNTING,
                LogScreen.ACCOUNT_ENTRY,
                'custom/ecd/salvar-lcto-contabil',
                this.editarLcto ? LogAction.EDIT : LogAction.CREATE,
                this.editarLcto ? this.lcto.id : result[0].id,
                this.editarLcto ? this.lcto.numero : result[0].numero,
                "O lançamento " + (this.editarLcto ? this.lcto.numero + " for alterado" : result[0].numero + " foi criado"),
                listaEnviar)

              this.carregar();
              this.pesquisar();

              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result[0].message });
            }

          }
        },
          error => this.httpService.handleError(error, () => this.salvarLcto()),
          () => this.httpService.done());

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Nenhuma partida válida!" });
    }
  }

  /* Função que salva os dados de um ou mais Lançamentos recorrentes no Banco de Dados */
  salvarLctosRecorrencia(listaDatas: Date[]) {


    this.editarLcto = false;

    this.lcto.lancamentoPartidaList = new Array();
    this.lcto.lancamentoPartidaList = this.lctoPartidas;

    if (this.lctoPartidas.length > 0) {

      this.lctoPartidas.forEach(element => {
        element.valor = this.utilService.parseDecimal(element.valor);
      });

      this.gravarDadosRecorrencia(listaDatas, 0);
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Nenhuma partida válida!" });
    }

  }

  /* Realizar a gravação dos Lançamentos recorrentes em intervalos de 2 em 2 segundos
  (a fim de garantir que seja possível finalizar a operação no Banco de Dados antes de uma nova inserção) */
  gravarDadosRecorrencia(listaDatas: Date[], i: any) {
    setTimeout(() => {
      this.lcto.dataLcto = this.utilService.dateWithoutTimeZone(listaDatas[i].toString());

      const listaEnviar = {
        infos: this.lcto,
        partidas: this.lctoPartidas,
        usuario: this.sessionService.loggedUser.id,
        empresa: this.dblinkedSessionService.empresa.id,
        editarLcto: this.editarLcto,
        centroCustoObrigatorio: this.centroCustoObrigatorio
      };

      this.httpService.wait();
      this.httpService.post('custom/ecd/salvar-lcto-contabil', listaEnviar)
        .subscribe(result => {

          if (result[0].error === true) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: result[0].message });
          } else if ((result[0].error === false) && (i == listaDatas.length)) {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: 'Recorrência de Lançamentos finalizada com sucesso!' })
            this.LogManagement.registerLog(
              LogModule.ACCOUNTING,
              LogScreen.ACCOUNT_ENTRY,
              'custom/ecd/salvar-lcto-contabil',
              LogAction.CREATE,
              result[0].id,
              result[0].numero,
              "O lançamento " + result[0].numero + " foi criado",
              listaEnviar)
          }
        },
          error => this.httpService.handleError(error, () => this.salvarLctosRecorrencia(listaDatas)),
          () => this.httpService.done());

      // Chamar o método novamente até que todas as inserções sejam realizadas
      i++;
      if (i < listaDatas.length) {
        this.gravarDadosRecorrencia(listaDatas, i);
      }
    }, 2000);

  }

  /* Função que adiciona uma nova linha no grid de um Lançamento, a ser preenchida
  com dados de uma partida relacionada. */
  addItem() {
    const partida = new LancamentoPartida();
    partida.planoContas = new PlanoContas();
    partida.cadCcus = new CadCcus();
    partida.cadHistPadrao = new CadHistPadrao();
    partida.cadProj = new CadProj();

    partida.indDc = 'D'
    partida.histCompl = '';
    this.lctoPartidas.push(partida);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  editLcto() {

    this.httpService.wait();
    this.httpService.get('/lancamento?$select=id,numero,lote,dataLcto,indLcto,ativo,lctoManual,cadEcd/id,cadEcd/status/id' +
      '&$filter=id eq ' + this.lctoEditar.lctoId)
      .subscribe(ref => { this.loadLcto(ref.value) },
        error => this.httpService.handleError(error, () => this.editLcto()),
        () => this.httpService.done());
  }

  centroDeCustoObrigatorio() {

    this.httpService.wait();
    this.httpService.get('/custom/pedido/centro-de-custo-obrigatorio(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(element => {
        this.centroCustoObrigatorio = element.cadCcusObrigatorio;
      },
        error => this.httpService.handleError(error, () => this.centroDeCustoObrigatorio()),
        () => this.httpService.done());
  }

  /* A partir do centro de custo, carregar os possíveis valores de projeto para
  um registro de um Lançamento */
  carregarProjetos(event) {

    event.opcoesProjetos = [];

    /* Caso não exista nenhum centro de custo selecionado, a busca por projetos relacionados
    não deve ser realizada. */
    if (!((event.cadCcus.id == null) || (event.cadCcus.id == undefined))) {

      this.httpService.wait();
      this.httpService.get('/custom/financeiro/get-projetos(' + (this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id) + ','
        + event.cadCcus.id + ')')
        .subscribe(proj => {

          event.opcoesProjetos.push({ label: 'SELECIONE', value: null });
          event.opcoesProjetos.push({ label: '', value: null });
          proj.forEach(pj => {
            event.opcoesProjetos.push({ label: pj.codProj + ' - ' + pj.descrProj, value: pj.id });
          });

          // Obter a descrição desse projeto a partir do seu ID
          event.cadProjDesc = this.obterCadProj(event, event.opcoesProjetos);
        },
          error => this.httpService.handleError(error, () => this.carregarProjetos(event)),
          () => this.httpService.done());
    }
  }

  alteraCadHist(event: any, cadHist: any) {
    event.cadHistPadrao.id = cadHist;
    event.cadHistPadraoDesc = this.obterCadHist(event);

    this.opcoesCadHistEscolhido = undefined;
  }

  obterCadHist(cadHist) {
    var resultado;

    this.opcoesCadHist.forEach(element => {
      if (element["value"] === cadHist.cadHistPadrao.id) {
        resultado = element["label"];
        cadHist.cadHistPadraoDesc = element["label"];
      }
    });

    return resultado;
  }

  /* Função que obtém a descrição de um Centro de Custo a partir do seu ID */
  obterCadCcus(cadCcus) {
    var resultado;

    this.opcoesCentroCustos.forEach(element => {
      if (element["value"] === cadCcus.cadCcus.id) {
        resultado = element["label"];
        cadCcus.cadCcusDesc = element["label"];
      }
    });

    return resultado;
  }

  /* Função que obtém a descrição de um Projeto a partir do seu ID */
  obterCadProj(cadProj, opcoesProjetos) {
    var resultado;

    if (opcoesProjetos != undefined) {
      opcoesProjetos.forEach(element => {
        if (element["value"] === cadProj.cadProj.id) {
          resultado = element["label"];
          cadProj.cadProjDesc = element["label"];
        }
      });
    }

    return resultado;
  }

  /* Função que realiza o controle dos registros selecionados a partir de um filtro aplicado */
  onFilter(event: any) {

    this.filteredLctos.forEach(rst => {
      rst.selected = false;
    });

    this.filteredLctos = new Array();
    this.idLctos = new Array();

    event.filteredValue.forEach(element => {
      this.filteredLctos.push(element);
    });

    this.checkBoxTodos = false;

  }

  /* Função que realiza o controle dos detalhes de lançamento filtrados */
  onFilterDetalhesLancamento(event: any) {

    this.valorLancamento = 0.00;
    event.filteredValue.forEach(element => {

      // Calcular o somatório dos valores para os itens filtrados
      if (element.planoContas) {

        if (element.indDc === 'D') {
          this.valorLancamento = this.utilService.parseDecimal(this.valorLancamento) + this.utilService.parseDecimal(element.valor);
        } else if (element.indDc === 'C') {
          this.valorLancamento = this.utilService.parseDecimal(this.valorLancamento) - this.utilService.parseDecimal(element.valor);
        }

        if (!element.planoContas.id && element.valor !== 0.00) {

          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Partida com valor sem Conta Contábil informada!' });
        }
      } else if (element.valor !== 0.00) {

        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Partida com valor sem Conta Contábil informada!' });
      }
    });

  }

  /* Função que retorna à primeira tela de Lançamentos, alterando as flags dos 3 cards
  para exibí-los ou não. */
  voltarPesquisaLancamentos() {
    this.exibeDetalhesResultado = false;
    this.exibePesquisa = true;
    this.exibeResultados = true;
  }

  /* Função que abre a tela de edição de um Lançamento
  e exibe as suas partidas relacionadas */
  editarRegistroLancamento(event) {
    this.exibePesquisa = false;
    this.exibeResultados = false;
    this.exibeDetalhesResultado = true;

    this.lctoEditar = event;
    this.editarLcto = true;

    this.opcoesPlanoContasEscolhido = undefined;
    this.opcoesCadHistEscolhido = undefined;
    this.opcoesCentroCustosEscolhido = undefined;
    this.opcoesProjetosEscolhido = undefined;

    this.editLcto();
  }

  /* Função que remove o Lançamento da linha selecionada pelo usuário. */
  removerRegistroLancamento(event) {
    this.lctoEditar = event;
    this.editarLcto = true;

    if (confirm('Deseja realmente excluir este Lançamento?')) {

      this.listaEnviar = null;

      this.listaEnviar = {
        lctoId: this.lctoEditar.lctoId,
        usuario: this.sessionService.loggedUser.id,
      };

      this.httpService.wait();
      this.httpService.post('custom/ecd/excluir-lcto-contabil', this.listaEnviar)
        .subscribe(result => {


          if (result[0].error === true) {

            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: result[0].message });
          } else {
            this.LogManagement.registerLog(
              LogModule.ACCOUNTING,
              LogScreen.ACCOUNT_ENTRY,
              'custom/ecd/excluir-lcto-contabil',
              LogAction.DELETE,
              this.lctoEditar.lctoId,
              this.lctoEditar.numLcto,
              "O lançamento " + this.lctoEditar.numLcto + " foi excluído",
              this.listaEnviar)
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result[0].message });
          }
          this.carregar();
          this.pesquisar();
        },
          error => this.httpService.handleError(error, () => this.salvarLcto()),
          () => this.httpService.done());
    }
  }

  /* Função que remove um ou mais Lançamentos selecionados no checkbox pelo usuário. */
  removerRegistrosSelecionados() {

    if (confirm('Deseja realmente excluir este(s) Lançamento(s)?')) {
      this.listaEnviar = null;
      const listId = this.idLctos.map(item => item.lctoId)

      if (listId !== null && listId !== undefined && listId.length > 0) {
        this.listaEnviar = {
          listlctoId: listId,
          usuario: this.sessionService.loggedUser.id,
        };

        this.httpService.wait();
        this.httpService.post('custom/ecd/excluir-lcto-contabil', this.listaEnviar)
          .subscribe(result => {

            if (result[0].error === true) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: result[0].message });
            } else {
              this.idLctos.forEach(row => {
                this.LogManagement.registerLog(
                  LogModule.ACCOUNTING,
                  LogScreen.ACCOUNT_ENTRY,
                  'custom/ecd/excluir-lcto-contabil',
                  LogAction.DELETE,
                  row.lctoId,
                  row.numLcto,
                  "O lançamento " + row.numLcto + " foi excluído",
                  this.listaEnviar)
              })

              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result[0].message });
            }
            this.carregar();
            this.pesquisar();
          },
            error => this.httpService.handleError(error, () => this.salvarLcto()),
            () => this.httpService.done());

      } else {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Nenhum registro selecionado." });
      }

    }
  }

  /* Função que faz a validação de um Lançamento antes de salvá-lo/atualizá-lo
  no Banco de Dados */
  validarLancamentos() {

    var partidasValidas = true;
    var linhaInvalida;


    /* Inicialmente, verificar se existe um nome de lote */
    if (this.lcto.lote == null || this.lcto.lote == undefined
      || this.lcto.lote == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Favor inserir um nome de lote." });
    } else {

      /* Verificar se alguma das partidas de um lançamento não possui conta contábil ou valor maior que zero
      ou Hist Complementar vazio. */
      for (var i = 0; i < this.lctoPartidas.length; i++) {
        if ((this.lctoPartidas[i].valor == 0) || (this.lctoPartidas[i].planoContas.id == null) || (this.lctoPartidas[i].planoContas.id == undefined)
          || (!this.lctoPartidas[i].histCompl)) {
          partidasValidas = false;
          linhaInvalida = i;
        }
      }

      /* Caso os dados obrigatórios de um Lançamento estejam preenchidos,
      fazer a validação quanto à data e ao nome de lote, verificando se existe
      um Lançamento no sistema com os mesmos dados.
      Em caso afirmativo, não realizar nenhuma gravação. */
      if (partidasValidas == true) {

        if (this.recorrencia == false) {
          // Validação para o Lançamento a ser gravado
          this.validarNomeLote();
        } else {

          // Realizar a validação dos parâmetros usados na recorrência de um lançamento
          if ((this.ocorrenciaMes == null) || (this.ocorrenciaMes == undefined)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Favor selecionar a periodiciade da recorrência." });

          } else if ((this.dtFimOcorrencia == null) || (this.dtFimOcorrencia == undefined)) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Favor selecionar a data final da recorrência." });
          } else {

            /* Se todos os parâmetros estiverem preenchidos, obter as datas dos
            Lançamentos recorrentes. */
            var dataPrimeiroLancamento = this.utilService.dateWithoutTimeZone(this.lcto.dataLcto.toString());
            const dataPrimeiroLancamentoFixed = new Date(dataPrimeiroLancamento);
            var diferencaMeses = (this.dtFimOcorrencia.getTime() - dataPrimeiroLancamentoFixed.getTime()) / (1000 * 3600 * 24 * 30)
            var numeroRecorrencias = Math.floor(diferencaMeses / this.ocorrenciaMes);

            var listaDatasRecorrencia: Date[] = [];

            // Iniciar um loop que irá obter as datas dos Lançamentos recorrentes
            for (var i = 0; i < numeroRecorrencias; i++) {
              var novaData = this.utilService.dateWithoutTimeZone(dataPrimeiroLancamentoFixed.toString());

              // Obter a data de um Lançamento recorrente
              var novaDataAjustada = this.obterDataRecorrencia(novaData, this.antecipaDiaNaoUtil,
                novaData.getMonth() + this.ocorrenciaMes * (i + 1));
              listaDatasRecorrencia.push(novaDataAjustada);
            }

            /* Com a lista de datas construída, realizar a validação dos Lançamentos
            recorrentes para nome de lote e data. */
            this.validarNomeLoteRecorrencia(listaDatasRecorrencia);

          }
        }

        /* Caso contrário, enviar mensagem de aviso ao usuário. */
      } else {
        linhaInvalida = linhaInvalida + 1;
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Partida inválida na linha " + linhaInvalida + ". Conta contábil e/ou valor e/ou hist complementar faltantes." });
      }
    }
  }

  /* Função que retorna a data da próxima recorrência de um lançamento */
  obterDataRecorrencia(novaData: Date, antecipaDiaNaoUtil: boolean,
    proximoMes: any) {

    // Primeiro, verificar se houve alteração no ano.
    var proximoMesAjustado;
    if (proximoMes > 11) {
      var anosAcrescentar = Math.floor(proximoMes / 12);
      novaData = new Date(novaData.setFullYear(novaData.getFullYear() + anosAcrescentar));
      proximoMesAjustado = proximoMes - (12 * anosAcrescentar);
    } else {
      proximoMesAjustado = proximoMes;
    }

    // Na sequência, fazer o ajuste (se necessário) do dia do lançamento no mês.
    if (novaData.getDate() > 28) {

      // Tratamento para o mês de Fevereiro
      if (proximoMesAjustado == 1) {
        // Ano bissexto
        if ((novaData.getFullYear() % 2 == 0) && (novaData.getFullYear() % 3 > 0)) {
          novaData = new Date(novaData.setDate(29));
          // Ano não-bissexto
        } else {
          novaData = new Date(novaData.setDate(28));
        }
        // Outros meses do ano
      } else {
        if ((novaData.getDate() == 31) && ([3, 5, 8, 10].includes(proximoMesAjustado))) {
          novaData = new Date(novaData.setDate(30));
        }
      }
    }
    novaData = new Date(novaData.setMonth(proximoMesAjustado));

    var novaDataAjustada = new Date(novaData);

    // Verificar se a data deve ser modificada para que ela seja um dia útil
    if (this.antecipaDiaNaoUtil == true) {
      if (novaDataAjustada.getDay() == 0) {  // Domingo
        return new Date(novaDataAjustada.setDate(novaDataAjustada.getDate() - 2));
      } else if (novaDataAjustada.getDay() == 6) {  // Sábado
        return new Date(novaDataAjustada.setDate(novaDataAjustada.getDate() - 1));
      } else {
        return novaDataAjustada;
      }
    } else {
      return novaDataAjustada;
    }
  }

  /* Função que abre o modal com os dados do lançamento "clone" a ser criado. */
  abrirModalLancamentoClone(event: any) {
    this.modalLancamentoClone = true;
    this.dadosClonagem = event;
    this.nomeLoteOriginal = event.lote;
  }

  /* Função que valida os dados fornecidos no modal e salva o "clone" de um lançamento
  no sistema, da mesma forma que é feito com um lançamento criado do zero. */
  validarLancamentoClone() {

    // Inicialmente, realizar a validação dos campos preenchidos no modal

    if (this.loteClone == null || this.loteClone == undefined
      || this.loteClone == "") {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Digite o nome do lote." });


    } else if (this.loteClone == this.nomeLoteOriginal) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "O nome do lote deve ser diferente para o lançamento a ser clonado." });
    } else if (this.dataClone == null || this.dataClone == undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione uma data de lançamento." });

      // Caso o lote e a data no modal estejam preenchidos, gravar o novo lançamento.
    } else {
      this.editarLcto = false;

      this.lctoEditar = this.dadosClonagem;
      this.operacaoClonagem = true;
      this.editLcto();
    }
  }

  /* Função que realiza as operações para clonar um lançamento */
  iniciarClonagem() {
    /* Inicialmente, adicionar os dados do modal no lançamento
    a ser gravado no Banco de Dados */
    this.lcto.dataLcto = this.dataClone;
    this.lcto.lote = this.loteClone;

    // Na sequência, chamar a função que salva o lançamento clonado
    this.salvarLcto();

    // Finalmente, fechar o modal e fazer um "reset" nas variáveis contidas nele.
    this.modalLancamentoClone = false;
    this.dataClone = undefined;
    this.loteClone = undefined;
    this.operacaoClonagem = false;
  }

  resetarDadosRecorrencia() {
    this.recorrencia = false;
    this.ocorrenciaMes = undefined;
    this.dtFimOcorrencia = undefined;
    this.antecipaDiaNaoUtil = false;

    this.carregar();
    this.pesquisar();
  }

  /* Função que faz a validação de um Lançamento a ser gravado
  a partir do nome de lote e data */
  validarNomeLote() {
    const listasEnviar = {
      dtLcto: this.lcto.dataLcto,
      lote: this.lcto.lote,
      usuarioId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id,
      listaDatas: null,
      editar: this.editarLcto.toString()
    };

    this.httpService.wait();
    this.httpService.post('/custom/ecd/pesquisar-lancamentos-lote', listasEnviar)
      .subscribe(result => {
        if (result["error"] == false) {

          /* Caso a validação seja bem-sucedida, iniciar a gravação do Lançamento */
          if (result["empty"] == true) {
            this.salvarLcto();
          } else {

            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Lançamento já existente com mesmo lote e data. Favor alterar um desses valores.' });
          }
        } else {

          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Erro ao fazer validação para nome de lote e data.' });
        }
      },
        error => this.httpService.handleError(error, () => this.pesquisar()),
        () => this.httpService.done());

  }

  /* Função que faz a validação de um conjunto de Lançamentos
  recorrentes a serem gravados a partir do nome de lote e data */
  validarNomeLoteRecorrencia(listaDatas: Date[]) {
    const listasEnviar = {
      dtLcto: this.lcto.dataLcto,
      lote: this.lcto.lote,
      usuarioId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id,
      listaDatas: listaDatas,
      editar: this.editarLcto.toString()
    };

    this.httpService.wait();
    this.httpService.post('/custom/ecd/pesquisar-lancamentos-lote', listasEnviar)
      .subscribe(result => {
        if (result["error"] == false) {

          /* Caso a validação seja bem-sucedida, iniciar a inserção dos Lançamentos recorrentes.
          Ao finalizar, resetar todas as variáveis usadas na recorrência de Lançamentos. */
          if (result["empty"] == true) {
            Promise.resolve(this.salvarLcto()).then(() =>
              this.salvarLctosRecorrencia(listaDatas)).then(() => this.resetarDadosRecorrencia());
          } else {

            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Lançamento já existente com mesmo lote e data. Favor alterar um desses valores.' });
          }
        } else {

          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Erro ao fazer validação para nome de lote e data.' });
        }
      },
        error => this.httpService.handleError(error, () => this.pesquisar()),
        () => this.httpService.done());
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem })

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem })
    }
  }

  selecionarContasMultiplas(event) {
    this.selectedContas = event.selectedAccount;
    this.planoContasSelecionados = new Array();
    this.selectedContas.forEach(row => this.planoContasSelecionados.push(row.id));
  }

  loadContasUnicas(row: any) {
    this.partidaSelecionada = row;
    this.selectedContas = new Array();
    this.modalContasUnicas = true;
  }

  selecionarContasUnica(row: any) {
    const { selectedAccount } = row;
    this.partidaSelecionada.planoContas.id = selectedAccount.id
    this.partidaSelecionada.contaContabilDesc = selectedAccount.codCta + " - " + selectedAccount.descrCta
  }

  carregarDadosParametroContabil() {

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-empresa-parametro-contabil', this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          if (res["error"] == false) {
            for (let line of res["result"]) {


              this.visualizarContaAutomatica = line["visualizaContaAutomatica"]

            }
          }

        },
        error => this.httpService.handleError(error, () => this.carregarDadosParametroContabil()),
        () => this.httpService.done());

  }
}
