import { Component, OnInit, OnDestroy } from '@angular/core';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from '../../shared/services/http.service';
import { UtilService } from '../../shared/services/util.service';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { SelectItem, Message } from 'primeng/api';
import { ConfigService } from '../../shared/services/config.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { GrupoContabilizacaoItem } from '../model/grupo-contabilizacao-item.model';
import { PlanoContas } from '../../contabil/model/plano-contas.model';
import { Item } from '../model/item.model';

@Component({
  selector: 'app-item-contabil',
  templateUrl: './item-contabil.component.html',
  styleUrls: ['./item-contabil.component.scss'],
  providers: [CrudService]
})

export class ItemContabilComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;
  private beforeViewSubscription: Subscription;

  grupoContabilizacaoItem: GrupoContabilizacaoItem;
  grupoContabilizacaoList: Array<GrupoContabilizacaoItem> = new Array();

  msgs: Message[];
  msgsAdv: Message[];

  title: String;
  subtitle: String;
  planoContasList: Array<PlanoContas> = new Array();
  itensList: Array<any> = new Array();
  itensReferenciadosList: Array<any> = new Array();

  exibirItemContabil: boolean;
  todasSelecionadas: boolean;
  idSelecionados: Array<any> = new Array();

  contadorLinha: number;

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private configService: ConfigService,
    private toolbarService: ToolbarService) {

  }

  ngOnInit() {
    // TODO: REVIEW (IMPORTANT!)
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);


    this.todasSelecionadas = false;

    this.toolbarService.hidden = true;

    this.title = 'ITEM CONTÁBIL';
    this.subtitle = 'CADASTRE E CONTROLE OS ITENS CONTÁBEIS';

    this.exibirItemContabil = false;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.empresaChanged(); });

    this.contadorLinha=0;
  }

  empresaChanged() {

    if (this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.hasEmpresaSelected) {

        // this.httpService.wait();
        this.httpService.get('/custom/ecd/planocontas', [this.dblinkedSessionService.empresa.id,
        this.dblinkedSessionService.periodo.year.value])
          .subscribe(planoContasList => {

            this.planoContasList = planoContasList.value;
            if(this.planoContasList !== null || this.planoContasList !== undefined){
              this.contadorLinha = this.planoContasList.length;
          }
          else{
              this.contadorLinha = 0;
          }

            this.planoContasList.forEach(pc => {
              pc.selected = false;
            });

          },
            error => this.httpService.handleError(error,
              () => this.empresaChanged()),
            () => this.httpService.done());


            this.httpService.wait();
            this.httpService.get('/custom/cadastro/item-contabil', [this.dblinkedSessionService.empresa.id])
              .subscribe(itens => {

                this.itensList = new Array();

                itens.forEach(element => {
                  if(this.itensList !== null || this.itensList !== undefined){
                    this.contadorLinha = this.itensList.length;
                }
                else{
                    this.contadorLinha = 0;
                }
                  this.itensList.push(element);
                });
                this.toolbarRefresh();
              },
                error => this.httpService.handleError(error,
                  () => this.empresaChanged()),
                () => this.httpService.done());
    }
  }

  onRowSelect(event) {
    console.log(event);
    this.title = 'RELACIONANDO ITENS COM A CONTA CONTÁBIL';
    this.subtitle = event.data.codCta + ' - ' + event.data.descrCta;
    this.exibirItemContabil = true;

    this.httpService.wait();
            this.httpService.get('/custom/cadastro/item-contabil-referenciados', [this.dblinkedSessionService.empresa.id, event.data.id])
              .subscribe(itens => {

                this.itensReferenciadosList = new Array();

                itens.forEach(element => {
                  if(this.itensReferenciadosList !== null || this.itensReferenciadosList !== undefined){
                    this.contadorLinha = this.itensReferenciadosList.length;
                }
                else{
                    this.contadorLinha = 0;
                }
                  this.itensReferenciadosList.push(element);
                });

              },
                error => this.httpService.handleError(error,
                  () => this.onRowSelect(event)),
                () => this.httpService.done());
  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.dblinkedSessionService.hidePeriods();
    // this.toolbarService.clear();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  selecionarTodos() {

    this.idSelecionados = new Array();

    if (!this.todasSelecionadas) {
  this.itensList.forEach(element => {
      element.selected = true;
  });
  this.todasSelecionadas = true;
} else {
  this.itensList.forEach(element => {
    element.selected = false;
  });
  this.todasSelecionadas = false;
  }
  }

  hideMessage() {

  }

  hideMessageAdv() {

  }


  check(id: any, event: any) {

    const index = this.idSelecionados.findIndex(c => c === id);
    if (event.selected) {
      event.selected = false;
      this.idSelecionados.splice(event, 1);
    } else {
      event.selected = true;
      this.idSelecionados.push(event);

    }

    console.log(this.idSelecionados);
  }

  add() {

  }

  remove(){

  }

  onFilter(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }

  private toolbarRefresh() {
    this.toolbarService.alterarStatus('atualizar', false, 'Atualizar relatório', () => this.empresaChanged());
    /*this.toolbarService.clear();
    this.toolbarService.add('back', false, 'class-back-button', 'undo', 'Voltar', () => this.ngOnInit());
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Adicionar Plano Gerencial');*/
  }
}
