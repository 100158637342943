import { Empresa } from 'app/cadastro/model/empresa.model';
import { Participante } from 'app/cadastro/model/participante.model';
import { ParticipanteTipo } from 'app/cadastro/model/participante-tipo.model';
import { Segmento } from 'app/util/model/segmento.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { TipoContribuinte } from 'app/util/model/tipo-contribuinte-model';
import { TipoAssinante } from 'app/util/model/tipo-assinante.model';
import { TipoClienteServicosComunicacao } from 'app/util/model/tipo-cliente-servicos-comunicacao.model';


export class ParticipanteEmpresa {
  id: number
  empresaId: number;
  participanteId: number;
  participanteTipoId: number;

  empresa: Empresa;
  participante: Participante;
  fantasia: string;
  codigo: string;
  dtInicial: Date;
  dtFinal: Date;
  participanteTipo: ParticipanteTipo;
  segmento: Segmento;
  limiteCredito: number;
  limiteCompra: number;
  inscricaoCene: boolean;
  orgaoPublico: boolean;
  contribuinte: boolean;
  contribuinteIpi: boolean;
  beneficioIcms: boolean;
  beneficioPis: boolean;
  beneficioCofins: boolean;
  beneficioIpi: boolean;
  ativo: boolean = true;
  /*
    complemento: string;
    contato: string;
    email: string;
    fone: string;*/
  destinatarios: string;
  nif: string;

  planoContas: PlanoContas;
  tipoContribuinte = new TipoContribuinte();

  porcentagemComissao: number;
  permiteAlterarComissao: boolean;

  tipoAssinante = new TipoAssinante();
  tipoClienteServicosComunicacao = new TipoClienteServicosComunicacao();

  constructor(participanteEmpresa?: ParticipanteEmpresa | number) {
    if (participanteEmpresa !== undefined && participanteEmpresa !== null) {
      if (typeof participanteEmpresa === 'number') {
        this.id = participanteEmpresa;
      } else {
        this.id = participanteEmpresa.id
        this.empresaId = participanteEmpresa.empresaId;
        this.participanteId = participanteEmpresa.participanteId;
        this.participanteTipoId = participanteEmpresa.participanteTipoId;
        this.empresa = participanteEmpresa.empresa;
        this.participante = participanteEmpresa.participante;
        this.codigo = participanteEmpresa.codigo;
        this.fantasia = participanteEmpresa.fantasia;
        this.dtInicial = participanteEmpresa.dtInicial;
        this.dtFinal = participanteEmpresa.dtFinal;
        this.participanteTipo = participanteEmpresa.participanteTipo;
        this.segmento = participanteEmpresa.segmento;
        this.limiteCredito = participanteEmpresa.limiteCredito;
        this.limiteCompra = participanteEmpresa.limiteCompra;
        /*this.contato = participanteEmpresa.contato;
        this.email = participanteEmpresa.email;
        this.fone = participanteEmpresa.fone;
        this.complemento = participanteEmpresa.complemento;*/
        this.inscricaoCene = participanteEmpresa.inscricaoCene;
        this.destinatarios = participanteEmpresa.destinatarios;
        this.planoContas = participanteEmpresa.planoContas;
        this.tipoContribuinte = participanteEmpresa.tipoContribuinte;
        this.ativo = participanteEmpresa.ativo;
        this.porcentagemComissao = participanteEmpresa.porcentagemComissao;
        this.permiteAlterarComissao = participanteEmpresa.permiteAlterarComissao;
        this.tipoAssinante = participanteEmpresa.tipoAssinante;
        this.tipoClienteServicosComunicacao = participanteEmpresa.tipoClienteServicosComunicacao;
      }
    }
  }
}


