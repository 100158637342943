import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { LazyLoadEvent, Message, MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
declare var $: any;

import { Column } from 'app/shared/components/dynamic-table/models/Column';
import { DynamicTable } from 'app/shared/components/dynamic-table/models/DynamicTable';
import { DynamicTableConfiguration } from 'app/shared/components/dynamic-table/models/DynamicTableConfiguration';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { FinanceiroRateio } from '../model/financeiro-rateio.model';
import IdentificacaoParticipante from '../model/identificacao-participante';
import TipoTitulo from '../model/tipo-titulo';
import Titulo from '../model/titulo';
import idv4 from 'app/util/processing/idv4';

@Component({
  selector: 'app-extrato-ofx',
  templateUrl: './extrato-ofx.component.html',
  styleUrls: ['./extrato-ofx.component.scss'
  ],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ExtratoOfxComponent implements OnInit, OnDestroy {
  /////////////////////////////
  // REFATOR SIMON LALLEMENT //
  // 27/07/2021              //
  /////////////////////////////

  private sessionSubscription: Subscription;

  // Strings
  title: string = 'CONCILIAÇÃO FINANCEIRO';
  query: string = "";

  //Messages
  msgs: Message[] = [];

  //Boolean
  statusExtratoOfx: boolean = true;
  statusCentralWeb: boolean = false;
  modalPeriodo: boolean = false;
  modalBanco: boolean = false;
  modalParticipante: boolean = false;
  todosOfxSelecionados: boolean = false
  verificarTitulos: boolean = false
  statusConciliado: boolean = false
  statusNaoConciliado: boolean = true
  atualizarBelvo: boolean = false
  checkedAllCw: boolean = false
  statusPendenteOfx: boolean = false
  statusPendenteCw: boolean = false
  participante_obrigatorio: boolean = false
  ccus_obrigatorio: boolean = false
  buscarCw: boolean = true
  modalFiltros: boolean = false
  callOfx: boolean = false
  bigVolume: boolean = false

  //Array
  ofx: Array<any> = new Array();
  banco_selecionados: Array<any> = new Array();
  liTitulosAVerificar: Array<any> = new Array();
  liOfxSelecionados: Array<number> = new Array();
  listaParticipantes: Array<any> = new Array();
  titulosFinanceiro: Array<any> = new Array()
  titulosFinanceiroNaoConc: Array<any> = new Array()
  liTitulosFin: Array<any> = new Array()
  liTitulosFinConc: Array<any> = new Array()
  centralWeb: Array<any> = new Array()
  liCwSelecionados: Array<number> = new Array()
  params: Array<any> = new Array()
  selectedOfx: Array<any> = new Array()

  //Numbers
  totalTransactions: number = 0;
  totalPeriodo: number = 0;
  totalParticipantes: number = 0;
  totalFinanceiro: number = 0
  totalFinanceiroNaoConc: number = 0
  totalCentralWeb: number = 0;
  valorTotal: number = 0

  //Dropdown
  opcoesCentroCusto: SelectItem[] = [];
  opcoesTipoBaixa: SelectItem[] = [];
  opcoesProjeto: SelectItem[] = [];
  listaEmpresasConta: SelectItem[] = [];

  //Date
  dataIni: Date = new Date();
  dataFin: Date = new Date();

  //Miscellaneous
  rowSelecionada: any = {};
  participanteSelecionado: any = {};
  filtros: any = {};

  //Multiselect
  bancos: SelectItem[] = [];
  meses: SelectItem[] = [];

  //Tabela Titulos
  dynamicTableVisible = false;
  isTransferencia = false;
  modalRateio = false;
  porcentagemCc: string;
  contadorRateio: number;
  centroCustoId: number;
  existeProjetosRelacionados: boolean;
  cc: any;
  proj: any;
  financeiroRateio = new FinanceiroRateio();
  movimentacaoRateios: Array<FinanceiroRateio> = new Array();
  rateioCentroCusto = 100;
  cardOfx = true;
  currentStateCardOfx: string = 'initial';
  aplicarTodosParticipantes = false;
  aplicarTodosRateios = false;
  opcoesOperacaoPagamento: Array<SelectItem> = [];
  opcoesOperacaoRecebimento: Array<SelectItem> = [];

  toolbarMainIni = [
    { icon: 'visibility', text: 'Movimentação', tooltip: 'Movimentação fianceira', visible: false, disabled: false, color: 'default', onClick: () => this.abrirTitulosFin() },
    { icon: 'add', text: 'Criar', tooltip: 'Criar', visible: true, disabled: false, color: 'default', dropdown: true, options: [{ label: 'Títulos', callback: () => this.criarTitulos() }, { label: 'Transferências', callback: () => this.criarTransferencias() },] },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.gerarRelatorio() },
    { icon: 'filter_alt', text: 'Filtrar', tooltip: 'Filtrar', visible: true, disabled: false, color: 'default', onClick: () => this.openModalFiltros() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirExtrato() },
    { icon: 'done_all', text: 'Conciliar', tooltip: 'Conciliar', visible: true, disabled: false, color: 'green', onClick: () => this.atualizarPagina(true) },
    { icon: 'payments', text: 'Gerar', tooltip: 'Gerar', visible: false, disabled: false, color: 'green', onClick: () => { } },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.showOFX()
        this.changeStateCardOfx()
      }
    }
  ]

  public toolbarMain = new Toolbar();
  public dynamicTable: DynamicTable = null
  public updateDynamicTable: any = undefined;
  public resetDynamicTable: any = undefined;

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private httpClient: HttpClient,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.toolbarService.hidden = true;
    this.titleService.title = 'Conciliação Financeiro'

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected
          && this.dblinkedSessionService.empresa.id) {
          this.dataIni = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
          this.dataFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
          this.ofx = new Array()
          // this.banco_selecionados = new Array()
          this.atualizarPaginaOnChange()
          this.callOfx = true
        }
      });
    this.toolbarMain.setAll(this.toolbarMainIni);
  }


  atualizarPaginaOnChange() {
    this.verificarTitulos = false;
    this.liOfxSelecionados = new Array();
    this.liTitulosFin = new Array();
    this.liTitulosFinConc = new Array();
    this.todosOfxSelecionados = false;
    this.bigVolume = false;
    this.loadDynamicTableResources();
    this.getCentroCustoItems();
    this.getEmpresasConta();
    // this.getOfxParticipantes();
    this.getDistinctBanks();
    this.getTipoBaixaItems();
    this.getValorTotalCw();
    this.getValorTotalOfx();
    this.getValorTotalFin();
    this.getValorTotalFinNaoConc();
    if (!this.dblinkedSessionService.empresa.altoVolume && this.callOfx) {
      this.getOfxData();
      this.getCentralWebData()
    }
  }

  checkBigVolume(conciliar: boolean = false, event: LazyLoadEvent = {}) {
    if (!this.dblinkedSessionService.empresa.altoVolume) {
      this.getOfxData(conciliar, event);
      // this.getCentralWebData()

    } else if (this.dblinkedSessionService.empresa.altoVolume && this.bigVolume) {
      this.getOfxData(conciliar, event);
      // this.getCentralWebData()
    }
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
  }

  changeStateCardOfx(): void { this.currentStateCardOfx = this.currentStateCardOfx === 'initial' ? 'final' : 'initial'; }

  showOFX() {
    this.cardOfx = !this.cardOfx;
    this.toolbarMain.setIcon('btn9', this.cardOfx ? 'expand_less' : 'expand_more');
    this.toolbarMain.setTooltip('btn9', this.cardOfx ? 'Ver menos' : 'Ver mais');
  }

  loadDynamicTableResources() {
    this.opcoesOperacaoPagamento = [];

    this.httpService.wait();
    this.httpService.get('/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 2 and tipoRegraContabil/id eq 3'
      + ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo eq true&$orderby=descricao asc')
      .subscribe(res => {
        res.value.forEach(element => { this.opcoesOperacaoPagamento.push({ label: element.descricao, value: element.id }); });
      }, error => this.httpService.handleError(error, () => this.loadDynamicTableResources()),
        () => this.httpService.done());

    this.opcoesOperacaoRecebimento = []
    this.httpService.wait();
    this.httpService.get('/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 1 and tipoRegraContabil/id eq 3'
      + ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo eq true&$orderby=descricao asc')
      .subscribe(res => {
        res.value.forEach(element => { this.opcoesOperacaoRecebimento.push({ label: element.descricao, value: element.id }); });
      }, error => this.httpService.handleError(error, () => this.loadDynamicTableResources()),
        () => this.httpService.done());
  }

  async criarTitulos(): Promise<void> {

    if (this.liOfxSelecionados.length == 0) {
      this.exibirMensagem({ severity: 'warn', message: 'É necessário selecionar pelo menos um extrato com status PENDENTE ou VERIFICAR.' })
      return
    }

    this.dynamicTable = undefined
    // Criando os dados que estarão nas linhas da coluna, neste caso os dados são Titulos, alguns campos já são preenchidos com os dados que vem dos extratos
    const content: Array<Titulo> = [];

    this.liOfxSelecionados.forEach(selectedId => {
      const validIds = [];

      this.ofx.forEach((extrato) => {
        if (extrato.id == selectedId && (extrato.descr_status == "PENDENTE" || extrato.descr_status == "VERIFICAR") && !validIds.includes(extrato.id)) {

          const str: string = extrato.desc_participante;
          const [participanteFantasy, participantDocument, participantCode] = str.split(' - ', 3).map(info => info.trim());

          const titulo: Titulo = {
            extratoId: extrato.id,
            dtOperacao: extrato.data,
            operacaoRegra: extrato.regra_contabil.id,
            cliente: extrato.participante_id,
            cliente_fantasia: extrato.fantasia,
            participanteContasId: null,
            valorOperacao: extrato.valor,
            historicoOperacao: extrato.descricao_simplificada,
            rateio: [],
            tipo: extrato.valor > 0 ? TipoTitulo.RECEBIMENTO : TipoTitulo.PAGAMENTO,
            empresa: this.dblinkedSessionService.empresa.id,
            tituloNfe: false,
            linkFinanceiro: null,
            usuario: this.sessionService.loggedUser.id,
            empresaConta: extrato.empresa_conta_id,
            novoRegistro: true,
            tipoPagamentoId: 0,
            nDocumento: null,
            provisao: false,
            dtEmissao: extrato.data,
            dtCompetencia: extrato.data,
            moeda: { moedaSelecionada: 0, valorTaxa: 0 },
            recorrencia: { antecipaDiaNaoUtil: false, dtFimOccorencia: null, ocorrenciaMes: null },
            baixar: true,
            codBarras: null,
            codReceita: null,
            dtApuracao: null,
            idRecolhimento: null,
            digitoIdRecolhimento: null,
            codRecolhimento: null,
            codPagamentoGps: null,
            identificaoParticipante: IdentificacaoParticipante.CODIGO,
            participanteCodigo: participantCode,
            participanteDocumento: participantDocument,
            participanteFantasia: participanteFantasy,
            recorrente: false,
            dtPrevisaoBaixa: extrato.data,
            dtVencimento: extrato.data,
            parcelaId: 1,
            valorTotal: extrato.valor,
            valorPrincipal: extrato.valor,
            end_to_end_id: null,
            participante_empresa_id: extrato.part_emp_id,
            valor_saldo: extrato.valor,
            status_escrituracao_id: 2,
            tipo_entrada_saida: extrato.valor > 0 ? 1 : 2,
            pedido_id: null,
            status_id: 1
          }
          validIds.push(titulo.extratoId);
          content.push(titulo);
        }
      })
    });

    if (content.length == 0) {
      this.exibirMensagem({ severity: 'warn', message: 'Todos extratos selecionados já estão conciliados, por favor selecione extratos com o status PENDENTE ou VERIFICAR.' });
      return;
    }

    if (content.length < this.liOfxSelecionados.length) this.exibirMensagem({ severiry: 'warn', message: 'Alguns extratos já estão conciliados, estes foram automaticamente removidos da seleção.' });

    // Buscando as opções que estarão presentes nos dropdowns

    // Definindo as cooolunas da tabela dinamica: key de representação do objeto a ser manipulado, name da coluna, tipo de interação da coluna
    const columns: Array<Column> = [
      { key: 'dtOperacao', name: 'Data', style: { align: 'center' }, type: { readOnly: { active: true } } },
      { key: 'operacaoRegra', name: 'Operação', type: { multiOptions: { key: 'tipo', active: true, options: { CP: { options: this.opcoesOperacaoPagamento, label: 'Operações de Pagamento' }, CR: { options: this.opcoesOperacaoRecebimento, label: 'Operações de Recebimento' } } } } },
      { key: 'empresaConta', name: 'Conta da empresa', type: { selectItem: { active: true, options: this.listaEmpresasConta, readOnly: true } } },
      { key: 'cliente', name: 'Participante', targetKey: 'cliente_fantasia', obrigatory: this.participante_obrigatorio, type: { modal: { active: true, label: 'Editar participante', labelByValue: 'cliente_fantasia' } } },
      { key: 'valorOperacao', name: 'Valor', style: { align: 'right' }, type: { readOnly: { active: true } } },
      { key: 'historicoOperacao', name: 'Histórico', style: { align: 'left' }, type: { input: { active: true } } },
      { key: 'linkFinanceiro', name: 'Link', style: { align: 'left' }, type: { input: { active: true } } },
      { key: 'nDocumento', name: 'Nº Documento', style: { align: 'left' }, type: { input: { active: true } } },
      { key: 'rateio', name: 'Rateio', obrigatory: this.ccus_obrigatorio, type: { modal: { active: true, label: 'Editar rateio' } } },
    ];

    // Instanciando a tabela declarando seu Nome, o conteudo que preencherá as linhas, a função que irá salvar os dados, e as colunas0
    this.isTransferencia = false;
    this.showOFX();
    this.changeStateCardOfx();

    const configuration: DynamicTableConfiguration = {
      name: 'Criação de títulos em lote',
      content,
      columns,
      buttons: null,
      lazy: false,
      totalRecords: null,
      form: false,
      selectable: true,
      closable: true,
      deletable: false
    }

    this.dynamicTable = new DynamicTable(configuration);
    this.resetDynamicTable = { reset: true };
    this.updateDynamicTable = { update: false };
    this.dynamicTableVisible = true;
  }

  criarTransferencias(): Promise<void> {

    if (this.liOfxSelecionados.length == 0) {
      this.exibirMensagem({ severity: 'warn', message: 'É necessário selecionar pelo menos um extrato com status PENDENTE ou VERIFICAR.' });
      return;
    }

    this.dynamicTable = undefined;

    // Criando os dados que estarão nas linhas da coluna, neste caso os dados são Titulos, alguns campos já são preenchidos com os dados que vem dos extratos
    const content: Array<Titulo> = [];

    this.liOfxSelecionados.forEach(selectedId => {
      const validIds = []

      this.ofx.forEach((extrato) => {
        if (extrato.id == selectedId && (extrato.descr_status == "PENDENTE" || extrato.descr_status == "VERIFICAR") && !validIds.includes(extrato.id)) {
          const str: string = extrato.desc_participante
          const [participanteFantasy, participantDocument, participantCode] = str.split(' - ', 3).map(info => info.trim())

          const titulo: Titulo = {
            extratoId: extrato.id,
            dtOperacao: extrato.data,
            cliente: extrato.participante_id,
            cliente_fantasia: extrato.fantasia,
            participanteContasId: null,
            valorOperacao: extrato.valor,
            historicoOperacao: extrato.descricao_simplificada,
            rateio: [],
            tipo: extrato.valor > 0 ? TipoTitulo.RECEBIMENTO : TipoTitulo.PAGAMENTO,
            empresa: this.dblinkedSessionService.empresa.id,
            tituloNfe: false,
            linkFinanceiro: '',
            usuario: this.sessionService.loggedUser.id,
            empresaConta: extrato.valor < 0 ? extrato.empresa_conta_id : null,
            empresaContaDestino: extrato.valor > 0 ? extrato.empresa_conta_id : null,
            novoRegistro: true,
            tipoPagamentoId: 0,
            nDocumento: '',
            provisao: false,
            dtEmissao: extrato.data,
            dtCompetencia: extrato.data,
            moeda: { moedaSelecionada: 0, valorTaxa: 0 },
            recorrencia: { antecipaDiaNaoUtil: false, dtFimOccorencia: null, ocorrenciaMes: null },
            baixar: true,
            codBarras: null,
            codReceita: null,
            dtApuracao: extrato.data,
            idRecolhimento: null,
            digitoIdRecolhimento: null,
            codRecolhimento: null,
            codPagamentoGps: null,
            identificaoParticipante: IdentificacaoParticipante.DOCUMENTO,
            participanteCodigo: participantCode,
            participanteDocumento: participantDocument,
            participanteFantasia: participanteFantasy,
            recorrente: false,
            dtPrevisaoBaixa: extrato.data,
            dtVencimento: extrato.data,
            parcelaId: 1,
            valorTotal: extrato.valor,
            valorPrincipal: extrato.valor,
            end_to_end_id: null,
            participante_empresa_id: null,
            valor_saldo: extrato.valor,
            status_escrituracao_id: 2,
            tipo_entrada_saida: extrato.valor > 0 ? 1 : 2,
            pedido_id: null,
            status_id: 5,
            operacaoRegra: 0,
            uuid: idv4()
          }

          validIds.push(titulo.extratoId);
          content.push(titulo);
        }
      })
    });

    if (content.length == 0) {
      this.exibirMensagem({ severity: 'warn', message: 'Todos extratos selecionados já estão conciliados, por favor selecione extratos com o status PENDENTE ou VERIFICAR.' });
      return;
    }

    if (content.length < this.liOfxSelecionados.length) this.exibirMensagem({ severiry: 'warn', message: 'Alguns extratos já estão conciliados, estes foram automaticamente removidos da seleção.' });

    // Buscando as opções que estarão presentes nos dropdowns

    // Definindo as colunas da tabela dinamica: key de representação do objeto a ser manipulado, name da coluna, tipo de interação da coluna
    const columns: Array<Column> = [
      { key: 'dtOperacao', name: 'Data', style: { align: 'center' }, type: { readOnly: { active: true } } },
      { key: 'empresaConta', name: 'Conta de origem', type: { selectItem: { active: true, options: this.listaEmpresasConta } } },
      { key: 'empresaContaDestino', name: 'Conta destino', type: { selectItem: { active: true, options: this.listaEmpresasConta } } },
      { key: 'historicoOperacao', name: 'Histórico', style: { align: 'left' }, type: { input: { active: true } } },
      { key: 'valorOperacao', name: 'Valor', style: { align: 'right' }, type: { readOnly: { active: true } } },
    ];

    // Instanciando a tabela declarando seu Nome, o conteudo que preencherá as linhas, a função que irá salvar os dados, e as colunas
    this.isTransferencia = true;
    this.showOFX();
    this.changeStateCardOfx();

    const configuration: DynamicTableConfiguration = {
      name: 'Criação de transferências em lote',
      content,
      columns,
      buttons: null,
      lazy: false,
      totalRecords: null,
      form: false,
      selectable: true,
      closable: true,
      deletable: false
    }

    this.dynamicTable = new DynamicTable(configuration);
    this.resetDynamicTable = { reset: true };
    this.updateDynamicTable = { update: false };
    this.dynamicTableVisible = true;
  }

  async salvarTitulos(titulos: Array<Titulo>): Promise<void> {

    let warnings = null;
    if (this.isTransferencia) {
      warnings = await this.validacaoTransferencias(titulos)
    } else {
      warnings = await this.validacaoTitulos(titulos)
    }

    if (warnings.length == 0) {
      let content = JSON.parse(JSON.stringify(titulos))

      content.forEach(item => {
        item['cc_cod'] = '';
        item['proj_cod'] = '';
        item['porcentagem'] = '';
        item.valorMulta = 0;
        item.valorDesconto = 0;
        item.valorJuros = 0;
        item.valorOperacao = Math.abs(item.valorOperacao);
        item.valorPrincipal = Math.abs(item.valorPrincipal);
        item.valorTotal = Math.abs(item.valorTotal);
        item.valor_saldo = Math.abs(item.valor_saldo);
      })

      if (this.isTransferencia) {
        const movimentacoes = content.map((titulo): Titulo => {
          const movimentacao = { ...titulo }
          movimentacao.tipo = titulo.tipo == TipoTitulo.PAGAMENTO ? TipoTitulo.RECEBIMENTO : TipoTitulo.PAGAMENTO
          movimentacao.empresaConta = movimentacao.tipo == TipoTitulo.RECEBIMENTO ? titulo.empresaContaDestino : titulo.empresaConta
          movimentacao.uuid = titulo.uuid
          titulo.empresaConta = titulo.tipo == TipoTitulo.RECEBIMENTO ? titulo.empresaContaDestino : titulo.empresaConta

          delete titulo.empresaContaDestino
          delete movimentacao.empresaContaDestino

          return movimentacao
        })

        content = [...content, ...movimentacoes]
      }

      try {
        const data = {
          empresaid: this.dblinkedSessionService.empresa.id,
          cnpj: [null, this.dblinkedSessionService.empresa.cnpj],
          userid: this.sessionService.loggedUser.id,
          movimentacoes: content,
          conciliar: true,
          transferencia: this.isTransferencia
        };

        this.httpService.wait();
        const response = await this.httpService.post(`${this.configService.defaultUrlApiUpload}financeiro/criar-titulos`, data).toPromise();
        this.httpService.done();

        if (response["error"]) {
          this.exibirMensagem({ severity: 'warn', message: response['mensagem'] });
          if (response['data']) {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0'
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/criar-titulos', httpOptions2)
              .subscribe(
                (res: Blob) => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);
                  let fileName = "log.txt";
                  link.href = url;
                  link.download = fileName;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem({ severity: "error", mensagem: "Erro no API: não foi possível baixar o log: " + err.message });
                  console.log(err);
                });
          }
          return
        }
        else {
          this.exibirMensagem({ severity: 'success', message: response['mensagem'] });
        }


        this.dynamicTable.removeItens(titulos.map(titulo => titulo['index']))

        this.resetDynamicTable = { reset: false };
        this.updateDynamicTable = { update: true };

        if (!this.dynamicTable.hasAnyElement()) {
          this.cardOfx = true;
          this.currentStateCardOfx = 'initial';
          this.dynamicTableVisible = false;
          this.dynamicTable = null;
          this.atualizarPagina()
        }

      } catch (e) {
        this.exibirMensagem({ severity: e.status != 500 ? 'warn' : 'error', message: e.error.mensagem });
        this.httpService.done();
      }

    } else {
      for (let error of warnings) {
        this.exibirMensagem(error)
      }
    }
  }

  incluirRateioCentroCusto(): void {
    let total = 0;
    this.dynamicTable.content[this.dynamicTable.index][this.dynamicTable.key].forEach(element => {
      total += parseFloat(element.porcentagem)
    });

    const porcentagem = parseFloat(this.porcentagemCc);

    let restante = 100 - (total + porcentagem);

    this.opcoesCentroCusto.forEach(item => {
      if (item.value == this.cc.value) {
        this.cc['id'] = item.value;
        this.cc['codCcus'] = item.label.split(' ', 1)[0];
        this.cc['descrCcus'] = item.label.split(' - ', 2)[1];
      }
    })

    this.opcoesProjeto.forEach(item => {
      if (item.value == this.proj.value) {
        this.proj['id'] = item.value;
        this.proj['codProj'] = item.label.split(' ', 1)[0];
        this.proj['descrProj'] = item.label.split(' - ', 2)[1];
      }
    })

    if (this.existeProjetosRelacionados && !this.proj.id) {
      this.exibirMensagem({ severity: 'error', message: 'Necessário selecionar um projeto.' });
      return;
    }

    if (!this.cc.id) {
      this.exibirMensagem({ severity: 'error', message: 'Necessário selecionar um centro de custo.' });
      return;
    }

    if (restante < 0) {
      this.exibirMensagem({ severity: 'error', message: 'Valor do rateio não pode ultrapssar 100%.' })
      return;
    }

    this.financeiroRateio = new FinanceiroRateio();
    this.rateioCentroCusto = restante;
    this.financeiroRateio.id = this.contadorRateio;
    this.financeiroRateio.cadProj = this.proj;
    this.financeiroRateio.cadCcus = this.cc;
    this.financeiroRateio.porcentagem = porcentagem;

    this.contadorRateio++;
    this.proj = null;
    this.cc = null;
    this.porcentagemCc = null;
    this.dynamicTable.updateValue(this.financeiroRateio);
  }

  adicionarRateioTodos(): void {
    if (this.aplicarTodosRateios) {
      const response = this.dynamicTable.applyValueInAllRows(this.dynamicTable.content[this.dynamicTable.index][this.dynamicTable.key]);
      this.exibirMensagem(response);
    }

    this.modalRateio = false;
  }

  removerRateioCentroCusto(event: any): void {
    if (confirm('Deseja excluir este rateio?')) {
      const index = this.dynamicTable.content[this.dynamicTable.index][this.dynamicTable.key].indexOf(event);
      this.dynamicTable.content[this.dynamicTable.index][this.dynamicTable.key].splice(index, 1);
      this.dynamicTable.updateState(false);

      this.exibirMensagem({ severity: 'warn', message: 'Rateio removido.' });
    }

    this.rateioCentroCusto = 100;
    this.dynamicTable.content[this.dynamicTable.index][this.dynamicTable.key].forEach(element => {
      this.rateioCentroCusto = this.rateioCentroCusto - this.utilService.parseDecimal(element.porcentagem);
    });
  }

  validacaoTransferencias(content: Array<Titulo>): Promise<Array<any>> {
    const validacao = new Promise<Array<any>>((resolve) => {

      let message = [];
      const missingAccount = { severity: 'warn', message: 'É necessário informar a conta destino em todas linhas selecionadas.' }
      const invalidAccount = { severity: 'warn', message: 'Conta destino não pode ser a conta de origem.' }
      const InvalidnDocumento = { severity: 'warn', message: 'Numero de documento não pode conter mais de 9 digitos.' }

      content.forEach(titulo => {
        if (titulo.nDocumento) {
          if (titulo.nDocumento.length > 9) {
            if (!message.includes(InvalidnDocumento)) message.push(InvalidnDocumento);
          }
        }

        if (titulo.empresaContaDestino) {
          if (titulo.empresaConta == titulo.empresaContaDestino) {
            if (!message.includes(invalidAccount)) message.push(invalidAccount);
          }
        }

        if (!titulo.empresaContaDestino) {
          if (!message.includes(missingAccount)) message.push(missingAccount);
        }
      })
      resolve(message);
    })

    return validacao;
  }
  validacaoTitulos(content: Array<Titulo>): Promise<Array<any>> {

    const validacao = new Promise<Array<any>>((resolve) => {

      let message = [];
      const invalidOperation = { severity: 'warn', message: 'É obrigatório informar o tipo de operação em todas as linhas selecionadas.' }
      const partObrigatory = { severity: 'warn', message: 'É obrigatório informar um participante em todas as linhas selecionadas.' }
      const rateioObrigatory = { severity: 'warn', message: 'É obrigatório informar o rateio em todas as linhas selecionadas.' }
      const InvalidnDocumento = { severity: 'warn', message: 'Numero de documento não pode conter mais de 9 digitos.' }

      content.forEach(titulo => {
        if (!titulo.operacaoRegra) {
          if (!message.includes(invalidOperation)) message.push(invalidOperation);
        }

        if (titulo.tipo == TipoTitulo.PAGAMENTO) {
          const tipoRegra = this.opcoesOperacaoPagamento.filter(item => {
            if (item.value == titulo.operacaoRegra) return true
          })

          if (tipoRegra.length == 0) {
            if (!message.includes(invalidOperation)) message.push(invalidOperation);
          }
        }

        if (titulo.tipo == TipoTitulo.RECEBIMENTO) {
          const tipoRegra = this.opcoesOperacaoRecebimento.filter(item => {

            if (item.value == titulo.operacaoRegra) return true
          })

          if (tipoRegra.length == 0) {
            if (!message.includes(invalidOperation)) message.push(invalidOperation);
          }
        }

        if (this.participante_obrigatorio) {
          if (!titulo.cliente) {
            if (!message.includes(partObrigatory)) message.push(partObrigatory);
          }
        }

        if (titulo.nDocumento) {
          if (titulo.nDocumento.length > 9) {
            if (!message.includes(InvalidnDocumento)) message.push(InvalidnDocumento);
          }
        }

        if (this.ccus_obrigatorio) {
          if (titulo.rateio.length == 0) {
            if (!message.includes(rateioObrigatory)) message.push(rateioObrigatory);
          }
        }
      })
      resolve(message);
    })

    return validacao;
  }

  openModal(event): void {
    if (event.key == 'rateio') {
      this.modalRateio = event.active;
    } else if (event.key == 'cliente') {
      this.modalParticipante = event.active;
      // this.getOfxParticipantes()
    }
  }

  fecharModalRateio(): void { this.modalRateio = false; }


  onInputDate(event: any) {
    let value = event.target.value.replace(/^(\d{1,2})\/?(\d{1,2})\/?(\d{1,4})/, '$1$2$3').split(/\D/)[0],
      response = '';

    if (value.slice(0, 2).length > 0) {
      response = value.slice(0, 2) <= 31 ? value.slice(0, 2) : ''
      if (value.slice(2, 4).length > 0) {
        response += '/' + (value.slice(2, 4) <= 12 ? value.slice(2, 4) : '')
        if (value.slice(4, 8).length > 0) {
          response += '/' + (value.slice(6, 7) >= 1 && value.slice(6, 7) <= 3 ? value.slice(4, 8) : value.slice(4, 6))
        }
      }
    }
    event.target.value = response
  }

  fecharDynamicTable(event: boolean) {
    if (!event) {
      if (confirm('Tem certeza que deseja fechar a tabela? Todo progresso não salvo será perdido. Continuar?')) {
        this.dynamicTableVisible = event
        this.atualizarPagina()

      };
    }
  }

  //////

  abrirTitulosFin() {
    this.getTitulosFinanceiros()
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  atualizarPagina(conciliar: boolean = false) {
    this.cardOfx = true;
    this.currentStateCardOfx = 'initial';
    this.dynamicTableVisible = false;
    this.dynamicTable = null;
    this.bigVolume = true
    this.getCentroCustoItems()
    this.getEmpresasConta();
    // this.getOfxParticipantes()
    this.getDistinctBanks()
    this.getTipoBaixaItems()
    this.meses = [];
    this.meses.push({ label: 'Selecionar', value: 0 });
    for (let mes = 1; mes <= 12; mes++) {
      this.meses.push({ label: `${mes}`, value: mes });
    }
    if (this.statusCentralWeb) {
      this.liCwSelecionados = new Array()
      this.getCentralWebData()
      this.getValorTotalCw()
    } else if (this.statusExtratoOfx) {
      this.verificarTitulos = false
      this.liOfxSelecionados = new Array()
      this.liTitulosFin = new Array()
      this.liTitulosFinConc = new Array()
      this.todosOfxSelecionados = false
      this.getValorTotalOfx()
      this.getValorTotalFin()
      this.getValorTotalFinNaoConc()
      this.getOfxData(conciliar)
    }
  }

  fecharModalFiltros() {
    this.bigVolume = true
    this.atualizarPagina()
    this.modalFiltros = false
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPageXLarge;
  }

  get rows() {
    return 25
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  openModalPeriodo() {
    this.modalPeriodo = true
  }

  openModalBanco() {
    this.modalBanco = true
  }

  getTitulosFinanceiros() {
    let dtIni: string = this.dataIni.getFullYear() + "-"
      + ((this.dataIni.getMonth() + 1) < 10 ? "0" + (this.dataIni.getMonth() + 1) : (this.dataIni.getMonth() + 1)) + "-"
      + this.dataIni.getDate()

    let dtFin: string = this.dataFin.getFullYear() + "-"
      + ((this.dataFin.getMonth() + 1) < 10 ? "0" + (this.dataFin.getMonth() + 1) : (this.dataFin.getMonth() + 1)) + "-"
      + this.dataFin.getDate()

    let row = this.ofx.find(row => row.id === this.liOfxSelecionados[0])

    if (row) {
      const info = {
        empresa_id: this.dblinkedSessionService.empresa.id,
        li_ofx: this.liOfxSelecionados,
        dt_ini: dtIni,
        dt_fin: dtFin,
        empresa_conta_id: row.empresa_conta_id
      }

      this.httpService.wait();
      this.httpService.post(this.configService.defaultUrlApiUpload + 'get_titulos_financeiros/', info)
        .subscribe(result => {
          if (result["severity"] === "error") {
            this.exibirMensagem(result)

          } else {
            this.verificarTitulos = true
            this.titulosFinanceiro = new Array()
            this.titulosFinanceiroNaoConc = new Array()
            this.liTitulosFin = new Array()
            this.liTitulosFinConc = new Array()
            result["data"]["conciliados"].forEach(element => {
              this.titulosFinanceiro.push(element)
              this.selectTitulo(element.id, element)
              element.selected = true
            })
            result["data"]["sem_conciliacao"].forEach(element => this.titulosFinanceiroNaoConc.push(element))

            this.statusNaoConciliado = true
            this.statusConciliado = false
            if (this.titulosFinanceiro.length > 0) {
              this.statusConciliado = true
              this.statusNaoConciliado = false
            }
          }
          this.httpService.done()
        },
          err => {
            console.log(err)
            this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + err.message })
            this.httpService.done()
          });
    }

  }

  //vir
  select(id: number, row: any) {
    if (!row.selected) {
      this.liOfxSelecionados.push(id)

    } else {
      this.liOfxSelecionados.splice(this.liOfxSelecionados.indexOf(id), 1)

    }

    this.statusPendenteOfx = false
    this.liOfxSelecionados.forEach(element => {
      let rowOfx = this.ofx.find(row => row.id === element)
      if (rowOfx.status.id === 1) {
        this.statusPendenteOfx = true
      }
    })

    this.toolbarMain.setVisible('btn7', this.statusPendenteOfx);
    this.toolbarMain.setClick('btn7', () => this.gerarFinanceiro());

    this.getValorTotalOfx()

    if (this.liOfxSelecionados.length) {
      this.toolbarMain.setVisible('btn5', true);
      this.abrirTitulosFin()

    } else {
      this.verificarTitulos = false;
      this.toolbarMain.setVisible('btn5', false);
    }
  }

  selectCw(id: number, row: any) {
    row.selected = !row.selected
    if (row.selected) {
      this.liCwSelecionados.push(id)

    } else {
      this.liCwSelecionados.splice(this.liCwSelecionados.indexOf(id), 1)

    }

    this.statusPendenteCw = false
    this.liCwSelecionados.forEach(element => {
      let rowCw = this.centralWeb.find(row => row.envio_id === element)
      if (rowCw.status.id === 1) {
        this.statusPendenteCw = true
      }
    })

    this.toolbarMain.setVisible('btn7', true);
    this.toolbarMain.setClick('btn7', () => this.gerarFinanceiroCw());

    if (this.liCwSelecionados.length === 0) {
      this.toolbarMain.setVisible('btn7', false)
    }


    this.getValorTotalCw()
  }

  selectTodosCw() {
    this.liCwSelecionados = new Array();
    this.checkedAllCw = !this.checkedAllCw

    if (this.checkedAllCw) {
      for (let line of this.centralWeb) {
        this.liCwSelecionados.push(line["envio_id"])
        line["selected"] = true
      }
      this.toolbarMain.setVisible('btn7', true);
      this.toolbarMain.setClick('btn7', () => this.gerarFinanceiroCw());

    } else {
      for (let line of this.centralWeb) {
        line["selected"] = false
      }
      this.toolbarMain.setVisible('btn7', false);
    }

    this.getValorTotalCw();
  }

  selectTodos() {
    this.liOfxSelecionados = new Array();
    this.todosOfxSelecionados = !this.todosOfxSelecionados

    if (this.todosOfxSelecionados) {
      this.totalPeriodo = this.valorTotal
      this.toolbarMain.setVisible('btn5', true);
      for (let line of this.ofx) {
        this.liOfxSelecionados.push(line["id"])
        line["selected"] = true
      }

    } else {
      this.totalPeriodo = 0
      this.toolbarMain.setVisible('btn5', false);
      for (let line of this.ofx) {
        line["selected"] = false
      }
    }

  }

  excluirExtrato() {
    if (confirm('Deseja realmente excluir todos os registros selecionados?')) {
      let dtIni: string = this.dataIni.getFullYear() + "-"
        + ((this.dataIni.getMonth() + 1) < 10 ? "0" + (this.dataIni.getMonth() + 1) : (this.dataIni.getMonth() + 1)) + "-"
        + this.dataIni.getDate()

      let dtFin: string = this.dataFin.getFullYear() + "-"
        + ((this.dataFin.getMonth() + 1) < 10 ? "0" + (this.dataFin.getMonth() + 1) : (this.dataFin.getMonth() + 1)) + "-"
        + this.dataFin.getDate()

      const infos = {
        list_id: this.liOfxSelecionados,
        tudo: this.todosOfxSelecionados,
        filtros: this.filtros,
        bancos: this.banco_selecionados,
        dt_ini: dtIni,
        dt_fin: dtFin,
        empresa_id: this.dblinkedSessionService.empresa.id
      }

      this.httpService.wait();
      this.httpService.post(this.configService.defaultUrlApiUpload + 'delete_ofx_data/', infos)
        .subscribe(result => {
          this.exibirMensagem(result)
          this.atualizarPagina()
          this.httpService.done()
        },
          err => {
            console.log(err)
            this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + err.message })
            this.httpService.done()
          });
    }
  }

  getOfxData(conciliar: boolean = false, event: LazyLoadEvent = {}) {

    let dtIni: string = this.dataIni.getFullYear() + "-"
      + ((this.dataIni.getMonth() + 1) < 10 ? "0" + (this.dataIni.getMonth() + 1) : (this.dataIni.getMonth() + 1)) + "-"
      + this.dataIni.getDate()

    let dtFin: string = this.dataFin.getFullYear() + "-"
      + ((this.dataFin.getMonth() + 1) < 10 ? "0" + (this.dataFin.getMonth() + 1) : (this.dataFin.getMonth() + 1)) + "-"
      + this.dataFin.getDate()

    this.filtros = event.filters

    const paramload = {
      offset: event.first,
      limit: event.rows,
      sort_order: event.sortOrder,
      sort_field: event.sortField,
      filters: event.filters,
      bancos: this.banco_selecionados,
      conciliar: conciliar
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'get_ofx_data/' + dtIni + '_' + dtFin + '_' + this.dblinkedSessionService.empresa.id, paramload)
      .subscribe(
        res => {
          if (res["severity"] === "error") {
            this.exibirMensagem(res)

          } else {
            this.ofx = new Array();
            this.liOfxSelecionados = new Array()
            this.selectedOfx = new Array()
            this.liTitulosAVerificar = new Array()
            this.query = res["data"].query;
            this.params = res["data"].params;
            res["data"]["transactions"].forEach(element => {
              this.ofx.push(element)
            });
            res["data"]["conciliar"].forEach(element => {
              this.liTitulosAVerificar.push(element)
            })
            this.totalTransactions = res["data"]["total_trans"];
            this.valorTotal = res["data"]["valor_total"]
            this.participante_obrigatorio = res["data"]["obrigatorios"]["participante_obrigatorio"]
            this.ccus_obrigatorio = res["data"]["obrigatorios"]["cad_ccus_obrigatorio"]
            if (conciliar && this.liTitulosAVerificar.length) {
              this.conciliarTitulos()
            }
          }
          this.httpService.done();
        },
        err => {
          console.log(err)
          this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + err.message })
          this.httpService.done()
        });
  }

  openModalFiltros() {
    this.modalFiltros = true
  }

  getDistinctBanks() {
    let lastDayofCurrentMonth: Date = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)

    let dtIni: string = this.dblinkedSessionService.periodo.year.value + "-"
      + (this.dblinkedSessionService.periodo.month.value < 10 ? "0" + (this.dblinkedSessionService.periodo.month.value) : this.dblinkedSessionService.periodo.month.value) + "-"
      + "01"

    let dtFin: string = lastDayofCurrentMonth.getFullYear() + "-"
      + (lastDayofCurrentMonth.getMonth() < 8 ? "0" + (lastDayofCurrentMonth.getMonth() + 1) : (lastDayofCurrentMonth.getMonth() + 1)) + "-"
      + lastDayofCurrentMonth.getDate()

    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'get_distinct_banks/' + dtIni + '_' + dtFin
      + '_' + this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          if (res["severity"] === "error") {
            this.exibirMensagem(res)

          } else {
            this.bancos = []
            res["data"].forEach(element => this.bancos.push(element))
          }
          this.httpService.done()

        }, err => {
          console.log(err)
          this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + err.message })
          this.httpService.done()
        });
  }

  getValorTotalOfx() {
    this.totalPeriodo = 0
    this.liOfxSelecionados.forEach(id => {
      let lineofx = this.ofx.find(line => id === line["id"])
      this.totalPeriodo += lineofx.valor
    })
    this.totalPeriodo = Math.round(this.totalPeriodo * 100) / 100
  }

  getValorTotalFinNaoConc() {
    this.totalFinanceiroNaoConc = 0
    this.liTitulosFin.forEach(row => this.totalFinanceiroNaoConc += row.valor)
    this.totalFinanceiroNaoConc = Math.round(this.totalFinanceiroNaoConc * 100) / 100
  }

  getValorTotalFin() {
    this.totalFinanceiro = 0
    this.liTitulosFinConc.forEach(row => this.totalFinanceiro += row.valor)
    this.totalFinanceiro = Math.round(this.totalFinanceiro * 100) / 100
  }

  openModalParticipante(rowData) {
    this.modalParticipante = true;
    this.rowSelecionada = rowData;
  }

  estocarSelecaoParticipante(rowData) {
    this.participanteSelecionado = rowData.data;
  }

  verificarAntesConciliar() {
    this.liOfxSelecionados.forEach(ofx_id => {
      if (this.ofx.find(row => row.id === ofx_id).status.id === 3) {
        this.exibirMensagem({ severity: "warn", message: "Um dos extratos selecionados já está conciliado." })
        return
      }
    })

    if (this.totalPeriodo !== this.totalFinanceiroNaoConc) {
      this.exibirMensagem({ severity: "warn", message: "O valor dos extratos deve ser igual ao valor dos títulos selecionados." })
      return
    }

    this.liTitulosAVerificar = new Array()
    this.liOfxSelecionados.forEach(ofx_id => {
      this.liTitulosAVerificar.push(this.ofx.find(row => row.id === ofx_id))
    })

    this.liTitulosFin.forEach(element => {
      this.liTitulosAVerificar[0].status.nfe_fin.push({
        nfe_fin_id: element.id,
        mov_fin_id: element.mov_fin_id,
        data: element.data,
        status: element.status,
        tipo_baixa_id: this.liTitulosAVerificar[0].tipo_baixa_id,
        valor: element.valor
      })
    })

    this.conciliarTitulos()
  }

  salvarParticipante(event) {

    try {

      this.participanteSelecionado = event.selectedParticipant;
      this.aplicarTodosParticipantes = event.applyAll;

      if (this.aplicarTodosParticipantes) {
        this.salvarParticipanteTodos();

      } else {
        this.rowSelecionada.participante_id = this.participanteSelecionado.id;
        this.rowSelecionada.participante_tipo_id = this.participanteSelecionado.participante_tipo_id;

        let participante_desc = null;

        if (this.participanteSelecionado.cpf_cnpj) {
          this.rowSelecionada.desc_participante = this.participanteSelecionado.fantasia + " - " + this.participanteSelecionado.cod_part + " - " + this.participanteSelecionado.cpf_cnpj;
          participante_desc = this.participanteSelecionado.fantasia + " - " + this.participanteSelecionado.cod_part
        } else {
          this.rowSelecionada.desc_participante = this.participanteSelecionado.fantasia + " - " + this.participanteSelecionado.cod_part + " - EXTERIOR";
          participante_desc = this.participanteSelecionado.fantasia + " - " + this.participanteSelecionado.cod_part
        }

        if (this.dynamicTable) {
          if (this.dynamicTable.key == 'cliente') {
            const response = this.dynamicTable.updateValue(this.participanteSelecionado.id, participante_desc)
            this.dynamicTable.updateValue(this.participanteSelecionado.fantasia, null, 'participanteFantasia')
            this.dynamicTable.updateValue(this.participanteSelecionado.cod_part, null, 'participanteCodigo')
            this.dynamicTable.updateValue(this.participanteSelecionado.cpf_cnpj, null, 'participanteDocumento')
            this.dynamicTable.updateValue(this.participanteSelecionado.part_emp_id, null, 'participante_empresa_id')
            this.modalParticipante = false;
            this.exibirMensagem(response)

            return
          }
        }



        // Propor as duplicações
        let listaRegraIguais: Array<any> = new Array()
        let tabela: Array<any> = new Array()
        let msgsConfirm: string = ""
        if (this.statusExtratoOfx) {
          tabela = this.ofx
          msgsConfirm = 'Deseja atualizar o mesmo participante para todos os extratos de \'' + this.rowSelecionada.regra_contabil.descricao.toUpperCase() + '\'?'
          this.ofx.forEach(element => {
            if (element.regra_contabil.id === this.rowSelecionada.regra_contabil.id && this.rowSelecionada.regra_contabil.id) {
              listaRegraIguais.push(element.id)
            }
          })

        } else {
          tabela = this.centralWeb
          msgsConfirm = 'Deseja atualizar o mesmo participante para todas as obrigações de ' + this.rowSelecionada.nome_tipo_arquivo.toUpperCase() + '?'
          this.centralWeb.forEach(element => {
            if (element.nome_tipo_arquivo === this.rowSelecionada.nome_tipo_arquivo) {
              listaRegraIguais.push(element.id)
            }
          })
        }

        if (listaRegraIguais.length > 1 && confirm(msgsConfirm)) {
          tabela.forEach(element => {
            listaRegraIguais.forEach(entrada => {
              if (entrada === element.id) {
                element.participante_id = this.rowSelecionada.participante_id
                element.participante_tipo_id = this.rowSelecionada.participante_tipo_id
                element.desc_participante = this.rowSelecionada.desc_participante
              }
            })
          })
        }
      }

      this.modalParticipante = false;
    } catch (err) {
      console.log(err)
    }
  }

  salvarParticipanteTodos() {
    this.rowSelecionada.participante_id = this.participanteSelecionado.id;
    this.rowSelecionada.participante_tipo_id = this.participanteSelecionado.participante_tipo_id;

    let participante_desc = null;

    if (this.participanteSelecionado.cpf_cnpj) {
      this.rowSelecionada.desc_participante = this.participanteSelecionado.fantasia + " - " + this.participanteSelecionado.cod_part + " - " + this.participanteSelecionado.cpf_cnpj;
      participante_desc = this.participanteSelecionado.fantasia + " - " + this.participanteSelecionado.cod_part
    } else {
      this.rowSelecionada.desc_participante = this.participanteSelecionado.fantasia + " - " + this.participanteSelecionado.cod_part + " - EXTERIOR";
      participante_desc = this.participanteSelecionado.fantasia + " - " + this.participanteSelecionado.cod_part
    }

    if (this.dynamicTable) {
      const response = this.dynamicTable.applyValueInAllRows(this.participanteSelecionado.id, participante_desc)
      this.dynamicTable.applyValueInAllRows(this.participanteSelecionado.fantasia, null, 'cliente_fantasia')
      this.dynamicTable.applyValueInAllRows(this.participanteSelecionado.fantasia, null, 'participanteFantasia')
      this.dynamicTable.applyValueInAllRows(this.participanteSelecionado.cod_part, null, 'participanteCodigo')
      this.dynamicTable.applyValueInAllRows(this.participanteSelecionado.cpf_cnpj, null, 'participanteDocumento')
      this.dynamicTable.applyValueInAllRows(this.participanteSelecionado.part_emp_id, null, 'participante_empresa_id')
      this.exibirMensagem(response)
    }

  }

  getOfxParticipantes(event: LazyLoadEvent = {}) {
    const paramload = {
      offset: event.first,
      limit: event.rows,
      sort_order: event.sortOrder,
      sort_field: event.sortField,
      filters: event.filters
    }

    if (this.modalParticipante) {

      this.httpService.wait();
      this.httpService.post(this.configService.defaultUrlApiUpload + 'get_distinct_participants/' + this.dblinkedSessionService.empresa.id, paramload)
        .subscribe(res => {
          if (res["severity"] === "error") {
            this.exibirMensagem(res)

          } else {
            this.listaParticipantes = []
            res["data"]["participantes"].forEach(element => { this.listaParticipantes.push(element) })
            this.totalParticipantes = res["data"]["total_part"];
          }

          this.httpService.done();

        }, error => {
          this.exibirMensagem({ severity: 'error', message: "Erro no API, contate o suporte: " + error.message });
          console.log(error)
          this.httpService.done();
        });
    }
  }

  getCentroCustoItems() {
    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'get_opcoes_centro_custo/' + this.dblinkedSessionService.empresa.id)
      .subscribe(
        result => {
          if (result["severity"] === "error") {
            this.exibirMensagem(result)

          } else {
            this.opcoesCentroCusto = [];
            result["data"].forEach(element => this.opcoesCentroCusto.push(element))
          }
          this.httpService.done()

        }, error => {
          this.exibirMensagem({ severity: 'error', message: "Erro no API, contate o suporte: " + error.message });
          console.log(error)
          this.httpService.done();
        })
  }

  deepCopy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map((item) => this.deepCopy(item))
      : source instanceof Date
        ? new Date(source.getTime())
        : source && typeof source === "object"
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
            Object.defineProperty(
              o,
              prop,
              Object.getOwnPropertyDescriptor(source, prop)
            );
            o[prop] = this.deepCopy(source[prop]);
            return o;
          }, Object.create(Object.getPrototypeOf(source)))
          : (source as T);
  }

  getProjetos(row, onlyList = false) {
    // Buscar projetos
    this.httpService.wait()
    this.httpService.get(this.configService.defaultUrlApiUpload + 'get_opcoes_projeto/' + row.centro_custo_id.value)
      .subscribe(
        res => {
          if (res["severity"] === "error") {
            this.exibirMensagem(res)
            this.httpService.done()

          } else {

            if (onlyList) {
              this.opcoesProjeto = [];
              res["data"].forEach(element => this.opcoesProjeto.push(element))
              this.httpService.done()
              return
            }

            this.opcoesProjeto = []
            row.lista_projetos = []
            row.descr_projeto = 'SELECIONE'
            row.projeto_id = null
            res["data"].forEach(element => this.opcoesProjeto.push(element))
            row.lista_projetos = this.deepCopy(this.opcoesProjeto)
            if (this.statusExtratoOfx) {
              this.incluirCentroCustoSelecionado(row)

            } else {
              this.listarProjetosParaCcSelecionado(row)
            }

            this.httpService.done()
          }
        }, error => {
          this.exibirMensagem({ severity: 'error', message: "Erro no API, contate o suporte: " + error.message })
          console.log(error)
          this.httpService.done()
        })

  }

  incluirCentroCustoSelecionado(row) {
    // Colocar o valor correto no objeto
    row.desc_centro_custo = row.centro_custo_id.label
    row.centro_custo_id = row.centro_custo_id.value

    // Propor as duplicações
    let listaRegraIguais: Array<any> = new Array()
    this.ofx.forEach(element => {
      if (element.regra_contabil.id === row.regra_contabil.id && row.regra_contabil.id) {
        listaRegraIguais.push(element.id)
      }
    })

    if (listaRegraIguais.length > 1 && confirm('Deseja atualizar o mesmo centro de custo para todos os extratos de \'' + row.regra_contabil.descricao.toUpperCase() + '\'?')) {
      this.ofx.forEach(element => {
        listaRegraIguais.forEach(ofx => {
          if (ofx === element.id) {
            element.desc_centro_custo = row.desc_centro_custo
            element.centro_custo_id = row.centro_custo_id
            element.lista_projetos = this.deepCopy(this.opcoesProjeto)
          }
        })

      })
    }
  }

  incluirProjetoSelecionado(row) {
    // Colocar o valor correto no objeto
    row.desc_projeto = row.projeto_id.label
    row.projeto_id = row.projeto_id.value

    // Propor as duplicações
    let listaRegraIguais: Array<any> = new Array()
    this.ofx.forEach(element => {
      if (element.regra_contabil.id === row.regra_contabil.id && element.centro_custo_id === row.centro_custo_id && row.regra_contabil.id) {
        listaRegraIguais.push(element.id)
      }
    })

    if (listaRegraIguais.length > 1 && confirm('Deseja atualizar o mesmo projeto para todos os extratos de \'' + row.regra_contabil.descricao.toUpperCase() + '\'?')) {
      this.ofx.forEach(element => {
        listaRegraIguais.forEach(ofx => {
          if (ofx === element.id) {
            element.desc_projeto = row.desc_projeto
            element.projeto_id = row.projeto_id
          }
        })
      })
    }
  }

  gerarFinanceiro() {
    let valid: boolean = true

    let listaRegistrosOfx: Array<any> = new Array()
    this.liOfxSelecionados.forEach(ofx_id => {
      let row = this.ofx.find(row => row.id === ofx_id)
      if (!row.tipo_baixa_id) {
        this.exibirMensagem({ severity: "warn", message: "Um dos extratos selecionados está sem tipo de baixa." })
        valid = false
      }

      if (row.status.id === 3) {
        this.exibirMensagem({ severity: "warn", message: "Um dos extratos selecionados já está conciliado." })
        valid = false
      }

      if (!row.regra_contabil.id) {
        this.exibirMensagem({ severity: "warn", message: "Um dos extratos selecionados está sem operação." })
        valid = false
      }

      if (!row.participante_id && this.participante_obrigatorio) {
        this.exibirMensagem({ severity: "warn", message: "Um dos extratos selecionados está sem participante." })
        valid = false
      }

      if (!row.centro_custo_id && this.ccus_obrigatorio) {
        this.exibirMensagem({ severity: "warn", message: "Um dos extratos selecionados está sem centro de custo." })
        valid = false
      }

      let registroOfx = {
        id: row.id,
        operacaoRegra: row.regra_contabil.id,
        participanteId: row.participante_id,
        participanteTipoId: row.participante_tipo_id,
        valorOperacao: row.valor,
        historicoOperacao: row.descricao,
        dtOperacao: row.data,
        empresaConta: row.empresa_conta_id,
        centroCustoId: row.centro_custo_id,
        projetoId: row.projeto_id,
        porcentagem: 100,
        status: row.status,
        tipoBaixa: row.tipo_baixa_id
      }
      listaRegistrosOfx.push(registroOfx)
    })

    let listaEnviar = {
      registrosOfx: listaRegistrosOfx,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id
    }

    if (valid) {
      this.httpService.wait();
      this.httpService.post('/custom/financeiro/gerar_titulos_financeiro/', listaEnviar)
        .subscribe(result => {
          this.exibirMensagem(result)
          this.atualizarPagina()
          this.httpService.done()
        }, error => {
          this.exibirMensagem({ severity: 'error', message: "Erro no API, contate o suporte: " + error.message })
          console.log(error)
          this.httpService.done()
        });
    }
  }

  desfazerConciliacao() {
    if (confirm("Essa ação irá devolver todos os títulos do(s) extrato(s) selecionado(s) para não conciliado.")) {
      this.httpService.wait();
      this.httpService.post(this.configService.defaultUrlApiUpload + 'ofx/desfazer_conciliacao/', this.liOfxSelecionados)
        .subscribe(result => {
          this.exibirMensagem(result)
          this.atualizarPagina()
          this.httpService.done()
        }, error => {
          this.exibirMensagem({ severity: 'error', message: "Erro no API, contate o suporte: " + error.message })
          console.log(error)
          this.httpService.done()
        });
    }
  }

  incluirTipoBaixaSelecionado(row) {
    // Colocar o valor correto no objeto
    row.desc_tipo_baixa = row.tipo_baixa_id.label
    row.tipo_baixa_id = row.tipo_baixa_id.value

    // Propor as duplicações
    let listaRegraIguais: Array<any> = new Array()
    this.ofx.forEach(element => {
      if (element.regra_contabil.id === row.regra_contabil.id && row.regra_contabil.id) {
        listaRegraIguais.push(element.id)
      }
    })

    if (listaRegraIguais.length > 1 && confirm('Deseja atualizar o mesmo tipo de baixa para todos os extratos de \'' + row.regra_contabil.descricao.toUpperCase() + '\'?')) {
      this.ofx.forEach(element => {
        listaRegraIguais.forEach(ofx => {
          if (ofx === element.id) {
            element.desc_tipo_baixa = row.desc_tipo_baixa
            element.tipo_baixa_id = row.tipo_baixa_id
          }
        })
      })
    }
  }

  getTipoBaixaItems() {
    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'get_opcoes_tipo_baixa/')
      .subscribe(
        opcoes => {
          if (opcoes["severity"] === "error") {
            this.exibirMensagem(opcoes)

          } else {
            this.opcoesTipoBaixa = []
            opcoes["data"].forEach(element => { this.opcoesTipoBaixa.push(element) })
          }
          this.httpService.done()

        },
        error => {
          this.exibirMensagem({ severity: 'error', message: "Erro no API, contate o suporte: " + error.message })
          console.log(error)
          this.httpService.done()
        })
  }

  selectTitulo(id: number, row: any) {
    // if (!row.selected) {
    //   this.liTitulosFinConc.push(id)
    // } else {
    //   this.liTitulosFinConc.splice(this.liTitulosFinConc.indexOf(id), 1)
    // }

    const posicaoIdLiTitulosFin = this.liTitulosFinConc.indexOf(row)
    if(posicaoIdLiTitulosFin === -1) this.liTitulosFinConc.push(row)
    else this.liTitulosFinConc.splice(posicaoIdLiTitulosFin, 1)

    this.getValorTotalFin()
  }

  selectTituloNaoConc() {
    this.getValorTotalFinNaoConc()
  }

  conciliarTitulos() {
    let listaEnviar = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id,
      listaFinanceiro: this.liTitulosAVerificar
    }

    this.exibirMensagem({ severity: "info", message: "Conciliação em andamento..." })
    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/gerar-baixa-ofx', listaEnviar)
      .subscribe(
        res => {
          if (res['error']) {
            this.exibirMensagem(res);
            if (res['data']) {
              const httpOptions2 = {
                headers: new HttpHeaders({
                  'Authorization': this.configService.bearerApi,
                  'Cache-Control': 'no-store, max-age=0'
                }),
                responseType: 'blob' as 'json'
              };

              this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/gerar-baixa-ofx', httpOptions2)
                .subscribe(
                  (res: Blob) => {
                    let link = document.createElement('a');
                    let url = window.URL.createObjectURL(res);
                    let fileName = "log.txt";
                    link.href = url;
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    this.httpService.done();
                    this.atualizarPagina()
                  },
                  err => {
                    this.exibirMensagem({ severity: "error", mensagem: "Erro no API, não foi possível baixar o log: " + err.message });
                    console.log(err);
                    this.httpService.done();
                  });
            } else {
              this.httpService.done();
            }

          } else {
            this.exibirMensagem(res);
            this.atualizarPagina()
            this.httpService.done();
          }
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro o API ao conciliar os títulos, entre em contato com o suporte: " + err.message });
          console.log(err);
          this.httpService.done();
        }
      );

    // this.httpService.wait();
    // this.httpService.post('/custom/financeiro/gerar-baixa-ofx/', listaEnviar)
    //   .subscribe(
    //     result => {
    //       this.exibirMensagem(result)
    //       this.atualizarPagina();
    //       this.httpService.done();

    //     }, error => {
    //       console.log(error)
    //       this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + error.message })
    //       this.httpService.done()
    //     })
  }

  showTelaOfxCentralWeb(tipo) {
    this.statusExtratoOfx = false;
    this.statusCentralWeb = false;
    this.toolbarMain.setVisible('btn5', false);
    if (tipo === 'TELA_1') {
      this.statusExtratoOfx = true;
      this.toolbarMain.setVisible('btn6', true);
      this.toolbarMain.setVisible('btn7', this.liOfxSelecionados.length > 0);
      this.toolbarMain.setVisible('btn5', this.liOfxSelecionados.length > 0);
    } else if (tipo === 'TELA_2') {
      this.statusCentralWeb = true;
      this.toolbarMain.setVisible('btn2', false)
      this.toolbarMain.setVisible('btn6', false);
      this.toolbarMain.setVisible('btn5', false);
      this.toolbarMain.setVisible('btn7', this.liCwSelecionados.length > 0);
      if (this.buscarCw) {
        this.buscarCw = false
        this.atualizarPagina()
      }

    }
  }

  showTelaConciliacao(tipo) {
    this.statusConciliado = false;
    this.statusNaoConciliado = false;
    if (tipo === 'TELA_1') {
      this.statusConciliado = true;
    } else if (tipo === 'TELA_2') {
      this.statusNaoConciliado = true;
    }
  }

  getEmpresasConta() {
    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'get_empresas_conta/' + this.dblinkedSessionService.empresa.id)
      .subscribe(empresaConta => {
        if (empresaConta["severity"] === "error") {
          this.exibirMensagem(empresaConta)

        } else {
          this.listaEmpresasConta = new Array()
          empresaConta["data"].forEach(element => { this.listaEmpresasConta.push(element) });
        }
        this.httpService.done()
      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + error.message })
          this.httpService.done()
        })
  }

  getCentralWebData() {
    let dtIni: string = this.dataIni.getFullYear() + "-"
      + ((this.dataIni.getMonth() + 1) < 10 ? "0" + (this.dataIni.getMonth() + 1) : (this.dataIni.getMonth() + 1)) + "-"
      + this.dataIni.getDate()

    let dtFin: string = this.dataFin.getFullYear() + "-"
      + ((this.dataFin.getMonth() + 1) < 10 ? "0" + (this.dataFin.getMonth() + 1) : (this.dataFin.getMonth() + 1)) + "-"
      + this.dataFin.getDate()

    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'get_central_web_data/' + dtIni + '_' + dtFin
      + '_' + this.dblinkedSessionService.empresa.id)
      .subscribe(ref => {
        if (ref["severity"] === "error") {
          this.exibirMensagem(ref)

        } else {
          this.centralWeb = new Array()
          ref["data"].forEach(element => this.centralWeb.push(element))
        }
        this.httpService.done()
      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + error.message })
          this.httpService.done()
        })

  }

  getValorTotalCw() {
    this.totalCentralWeb = 0
    this.liCwSelecionados.forEach(id => {
      let lineCw = this.centralWeb.find(line => id === line["envio_id"])
      this.totalCentralWeb += lineCw.valor
    })
    this.totalCentralWeb = Math.round(this.totalCentralWeb * 100) / 100
  }

  gerarFinanceiroCw() {
    let validado: boolean = true
    let listaRegistrosCw: Array<any> = new Array()
    this.liCwSelecionados.forEach(cw_id => {
      let row = this.centralWeb.find(row => row.envio_id === cw_id)
      if (!row.empresa_conta_id) {
        this.exibirMensagem({ severity: "warn", message: "A conta bancária é um paramêtro obrigatório." })
        validado = false
        return
      }

      if (!row.operacao_regra_contabil_id) {
        this.exibirMensagem({ severity: "warn", message: "A operação é um paramêtro obrigatório." })
        validado = false
        return
      }

      if (!row.data_fato_gerador) {
        this.exibirMensagem({ severity: "warn", message: "A data do fato gerador é obrigatória." })
        validado = false
        return
      }

      let registroCw = {
        id: row.envio_id,
        operacaoRegra: row.operacao_regra_contabil_id,
        participanteId: row.participante_id,
        participanteTipoId: row.participante_tipo_id,
        valorOperacao: row.valor,
        historicoOperacao: row.obrigacao,
        dtOperacao: row.data_vencimento,
        empresaContaId: row.empresa_conta_id,
        centroCustoId: row.centro_custo_id,
        projetoId: row.projeto_id,
        porcentagem: 100,
        status: row.status,
        dtApuracao: row.data_fato_gerador
      }
      listaRegistrosCw.push(registroCw);
    })

    let listaEnviar = {
      registrosCw: listaRegistrosCw,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id
    }

    if (!validado) return

    this.httpService.wait();
    this.httpService.post('/custom/financeiro/gerar_titulos_financeiro_centralweb/', listaEnviar)
      .subscribe(result => {
        this.exibirMensagem(result)
        this.atualizarPagina()
        this.httpService.done()
      }, error => {
        console.log(error)
        this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + error.message })
        this.httpService.done()
      })
  }

  listarProjetosParaCcSelecionado(row) {
    // Colocar o valor correto no objeto
    row.desc_centro_custo = row.centro_custo_id.label
    row.centro_custo_id = row.centro_custo_id.value

    // Propor as duplicações
    let listaRegraIguais: Array<any> = new Array()
    this.centralWeb.forEach(element => {
      if (element.nome_tipo_arquivo === row.nome_tipo_arquivo) {
        listaRegraIguais.push(element.envio_id)
      }
    })

    if (listaRegraIguais.length > 1 && confirm('Deseja atualizar o mesmo centro de custo para todas as obrigações de ' + row.nome_tipo_arquivo.toUpperCase() + '?')) {
      this.centralWeb.forEach(element => {
        listaRegraIguais.forEach(cw => {
          if (cw === element.envio_id) {
            element.desc_centro_custo = row.desc_centro_custo
            element.centro_custo_id = row.centro_custo_id
          }
        })

      })
    }
  }

  hideMessage() {
    this.msgs = [];
  }

  incluirEmpresaContaSelecionadoCw(row) {
    // Colocar o valor correto no objeto
    row.desc_empresa_conta = row.empresa_conta_id.label
    row.empresa_conta_id = row.empresa_conta_id.value

    // Propor as duplicações
    let listaRegraIguais: Array<any> = new Array()
    this.centralWeb.forEach(element => {
      if (element.nome_tipo_arquivo === row.nome_tipo_arquivo) {
        listaRegraIguais.push(element.envio_id)
      }
    })

    if (listaRegraIguais.length > 1 && confirm('Deseja atualizar a mesma conta bancária para todas as obrigações de ' + row.nome_tipo_arquivo.toUpperCase() + '?')) {
      this.centralWeb.forEach(element => {
        listaRegraIguais.forEach(cw => {
          if (cw === element.envio_id) {
            element.desc_empresa_conta = row.desc_empresa_conta
            element.empresa_conta_id = row.empresa_conta_id
          }
        })

      })
    }
  }

  incluirProjetoSelecionadoCw(row) {
    // Colocar o valor correto no objeto
    row.desc_projeto = row.projeto_id.label
    row.projeto_id = row.projeto_id.value

    // Propor as duplicações
    let listaRegraIguais: Array<any> = new Array()
    this.centralWeb.forEach(element => {
      if (element.nome_tipo_arquivo === row.nome_tipo_arquivo) {
        listaRegraIguais.push(element.envio_id)
      }
    })

    if (listaRegraIguais.length > 1 && confirm('Deseja atualizar o mesmo projeto para todas as obrigações de ' + row.nome_tipo_arquivo.toUpperCase() + '?')) {
      this.centralWeb.forEach(element => {
        listaRegraIguais.forEach(cw => {
          if (cw === element.envio_id) {
            element.desc_projeto = row.desc_projeto
            element.projeto_id = row.projeto_id
          }
        })

      })
    }
  }

  gerarRelatorio() {
    let dtIni: string = this.dataIni.getFullYear() + "-"
      + ((this.dataIni.getMonth() + 1) < 10 ? "0" + (this.dataIni.getMonth() + 1) : (this.dataIni.getMonth() + 1)) + "-"
      + this.dataIni.getDate()

    let dtFin: string = this.dataFin.getFullYear() + "-"
      + ((this.dataFin.getMonth() + 1) < 10 ? "0" + (this.dataFin.getMonth() + 1) : (this.dataFin.getMonth() + 1)) + "-"
      + this.dataFin.getDate()

    let payload = {
      empresaid: this.dblinkedSessionService.empresa.id,
      dtIni: dtIni,
      dtFin: dtFin,
      tipo: this.statusCentralWeb ? 'central' : 'extrato',
      query: this.statusCentralWeb ? "" : this.query,
      params: this.statusCentralWeb ? [] : this.params,
      table: this.statusCentralWeb ? this.centralWeb : [],
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/relatorio-extrato-ofx', payload)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          if (res["error"]) {
            this.httpService.done()
            return
          }

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          }

          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/relatorio-extrato-ofx', httpOptions2)
            .subscribe(
              (res: Blob) => {
                let link = document.createElement('a');
                let url = window.URL.createObjectURL(res);
                let fileName = this.statusCentralWeb ? `Relatóro de central-web.xlsx` : `Relatório de extrato ofx.xlsx`;
                link.href = url;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
                this.httpService.done();
              }, err => {
                this.exibirMensagem({ "error": true, "mensagem": "Erro no API ao baixar o relatório, contate o suporte: " + err.message })
                this.httpService.done()
              });

        }, err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API ao gerar o relatório, contate o suporte: " + err.message })
          this.httpService.done()
        });
  }

  onRowSelect() {
    this.liOfxSelecionados = new Array()
    this.totalPeriodo = 0
    this.selectedOfx.forEach(row => {
      this.liOfxSelecionados.push(row.id)
      this.totalPeriodo = Math.round((this.totalPeriodo + row.valor) * 100) / 100
    })

    this.statusPendenteOfx = true;
    if (this.liOfxSelecionados.length > 0) {
      this.toolbarMain.setVisible('btn1', true)
      this.toolbarMain.setVisible('btn5', true)
    } else {
      this.verificarTitulos = false
    }
  }

  onRowUnselect() {
    this.liOfxSelecionados = new Array()
    this.totalPeriodo = 0
    this.selectedOfx.forEach(row => {
      this.liOfxSelecionados.push(row.id)
      this.totalPeriodo = Math.round((this.totalPeriodo + row.valor) * 100) / 100
    })

    this.statusPendenteOfx = true;
    if (this.liOfxSelecionados.length > 0) {
      this.toolbarMain.setVisible('btn1', true)
      this.toolbarMain.setVisible('btn5', true)
    } else {
      this.verificarTitulos = false
    }
  }
}
