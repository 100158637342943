import { AuthUser } from 'app/proof/model/auth-user.model';
import { TodoProjeto } from './todo-projeto.model';

export class TodoPrioridade {
  id: number;
  nome: string;

  constructor(todoPrioridade?: TodoPrioridade | number) {
    if (todoPrioridade !== undefined && todoPrioridade !== null) {
      if (typeof todoPrioridade === 'number') {
        this.id = todoPrioridade;
      } else {
        this.id = todoPrioridade.id;
        this.nome = todoPrioridade.nome;
      }
    }
  }
}
