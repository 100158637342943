import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from 'app/shared/services/http.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { SessionService } from 'app/shared/services/session.service';
import { MessageService, SelectItem } from 'primeng/api';
import { ConfigPattern } from '../../interfaces/paylivre';
import { resetConfigPattern } from '../../utils/reset-paylivre-form';

@Component({
  selector: 'app-cfg-paylivre',
  templateUrl: './cfg-paylivre.component.html',
  styleUrls: ['./cfg-paylivre.component.scss']
})
export class CfgPayLivreComponent implements OnInit, OnChanges {

  @Input() isRender: boolean
  @Input() isSave: boolean
  @Input() isConfigExists: boolean
  @Output() isValid = new EventEmitter()

  httpOptions: Object = new Object()

  optProviders: Array<SelectItem> = new Array()
  optCustomers: Array<SelectItem> = new Array()
  optCompanyAccounts: Array<SelectItem> = new Array()

  selectedAccountCompanies: Array<SelectItem> = new Array()

  _isConfigExists: boolean = false
  loadingConfigPattern: boolean = false
  loadingOptProvider: boolean = true
  loadingIconOptProvider: boolean = false
  loadingOptCustomer: boolean = true
  loadingIconOptCustomer: boolean = false
  loadingOptCompanyAccounts: boolean = false
  loadingIconOptCompanyAccounts: boolean = false

  empresaId: number = null
  userId: number = null

  config: ConfigPattern = resetConfigPattern()

  constructor(
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private httpService: HttpService,
    private configService: ConfigService
  ) { }

  public ngOnInit(): void {
    this.empresaId = this.dblinkedSessionService.empresa.id
    this.userId = this.sessionService.loggedUser.id

    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    this.isValid.emit({ validatedSteps: false })
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.isSave && changes.isSave.currentValue) this.saveConfigPattern()
    if(changes.isConfigExists && changes.isConfigExists.currentValue) this._isConfigExists = true
    if(changes.isRender && changes.isRender.currentValue) this.initialize(this._isConfigExists)
  }

  private async initialize(isConfigExists: boolean): Promise<void> {
    this.isValid.emit({ validatedSteps: true })

    if (isConfigExists) this.loadingConfigPattern = true

    await this.getOptCompanyAccounts()
    if (isConfigExists) this.getConfigPattern()
  }

  private async getConfigPattern(): Promise<void> {
    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/cadastro/configuracao-api?empresaId=${this.empresaId}&codigo=PLVRE`, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          this.config.chaveAcesso = res['data'][0].chave_api
        } else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar os dados padrões da sua configuração! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.getBankReconciliationRelationship()
    }
  }

  private async getOptCompanyAccounts(): Promise<void> {
    this.loadingOptCompanyAccounts = true
    this.loadingIconOptCompanyAccounts = true

    try {
      await this.httpService.get('/empresaconta?$select=id,nome&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo=true and tipoConta/id=1&$orderby=nome').toPromise().then(res => {
        this.optCompanyAccounts = new Array()
        res.value.forEach(item => { this.optCompanyAccounts.push({ label: item.nome, value: item.id }) })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as contas da empresa! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptCompanyAccounts = false
      this.loadingIconOptCompanyAccounts = false
    }
  }

  private async getBankReconciliationRelationship(): Promise<void> {
    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/cadastro/banco-conciliacao-relac?empresaId=${this.empresaId}&schedulerId=17`, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) this.selectedAccountCompanies = res['data']
        else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao recuperar as contas bancárias! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingConfigPattern = false
    }
  }

  private async saveConfigPattern(): Promise<void> {
    this.isValid.emit({ validatedSteps: true })
    this.validationConfig()

    const payload = {
      empresa_id: this.empresaId,
      user_id: this.userId,
      codigo: 'PLVRE',
      chave_api: this.config.chaveAcesso
    }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/configuracao-api', payload, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200){
          this.saveFamilyRelationship()
          this.saveCompanyAccounts()
        }
        else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar os dados padrões da sua configuração! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async saveFamilyRelationship(): Promise<void> {
    const httpOptions: Object = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    const payload: Object = { empresa_id: this.dblinkedSessionService.empresa.id, familia_id: 3 }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/empresa-familia', payload, httpOptions).toPromise().catch(err =>
        this.showResponse('error', err.status + ' - Houve um erro ao salvar a família de API Conciliadora!')
      )
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async saveCompanyAccounts(): Promise<void> {
    const payload = {
      empresa_id: this.empresaId,
      scheduler_id: 17,
      libancos: this.selectedAccountCompanies,
      user_id: this.userId
    }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/banco-conciliacao-relac', payload, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] !== 200) this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar as contas bancárias! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }


  public validationConfig(): void {
    this.isValid.emit({ validatedSteps: (this.config.chaveAcesso && this.selectedAccountCompanies.length > 0) })
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso: ', detail: message })
      break
    }
  }

}
