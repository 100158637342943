import { Pedido } from 'app/financeiro/model/pedido.model';
import { AuthUser } from 'app/proof/model/auth-user.model';

export class PedidoAprovacao {
  id: number;
  pedido: Pedido;
  mail: string;
  responsavelAprovacao: boolean;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;


  constructor(pedidoAprovacao?: PedidoAprovacao | number) {
    if (pedidoAprovacao !== undefined && pedidoAprovacao !== null) {
      if (typeof pedidoAprovacao === 'number') {
        this.id = pedidoAprovacao;
      } else {
        this.id = pedidoAprovacao.id;
        this.pedido = pedidoAprovacao.pedido;
        this.mail = pedidoAprovacao.mail;
        this.responsavelAprovacao = pedidoAprovacao.responsavelAprovacao;
        this.createTimestamp = pedidoAprovacao.createTimestamp;
        this.changeUser = pedidoAprovacao.changeUser;
        this.changeTimestamp = pedidoAprovacao.changeTimestamp;
      }
    }
  }
}
