import { Empresa } from 'app/cadastro/model/empresa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { TipoRegraContabil } from 'app/util/model/tipo-regra-contabil.model';
import { OperacaoRegraContabilDetalhe } from 'app/cadastro/model/operacao-regra-contabil-detalhe.model';
import { TipoEntradaSaida } from '../../util/model/tipo-entrada-saida.model';
import { TipoOperacao } from 'app/util/model/tipo-operacao.model';
import { isNullOrUndefined } from "util";

export class OperacaoRegraContabil {
  id: number;
  tipoRegraContabil: TipoRegraContabil;
  descricao: string;
  empresa: Empresa;
  dtInicial: Date;
  dtFinal: Date;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;
  tipoEntradaSaida: TipoEntradaSaida;
  tipoOperacao: TipoOperacao;
  novoRegistro: boolean;
  podeEditar: boolean;
  nfId: number;
  grupoEmpresaOperacaoRegraContabilOrigemId: number
  ativo: boolean
  tipoRegraDescricao: string
  tipoOperacaoDescricao: string
  statusRegra: string

  operacaoRegraContabilDetalheList: Array<OperacaoRegraContabilDetalhe>;

  constructor(operacaoRegraContabil?: OperacaoRegraContabil | number) {
    if (operacaoRegraContabil !== undefined && operacaoRegraContabil !== null) {
      if (typeof operacaoRegraContabil === 'number') {
        this.id = operacaoRegraContabil;
      } else {
        this.id = operacaoRegraContabil.id;
        this.tipoRegraContabil = operacaoRegraContabil.tipoRegraContabil;
        this.descricao = operacaoRegraContabil.descricao;
        this.empresa = operacaoRegraContabil.empresa;
        this.dtInicial = operacaoRegraContabil.dtInicial;
        this.dtFinal = operacaoRegraContabil.dtFinal;
        this.createTimestamp = operacaoRegraContabil.changeTimestamp;
        this.changeUser = operacaoRegraContabil.changeUser;
        this.changeTimestamp = operacaoRegraContabil.changeTimestamp;
        this.tipoEntradaSaida = operacaoRegraContabil.tipoEntradaSaida;
        this.operacaoRegraContabilDetalheList = operacaoRegraContabil.operacaoRegraContabilDetalheList;
        this.tipoOperacao = operacaoRegraContabil.tipoOperacao;
        this.novoRegistro = operacaoRegraContabil.novoRegistro;
        this.podeEditar = operacaoRegraContabil.podeEditar;
        this.nfId = operacaoRegraContabil.nfId;
        this.grupoEmpresaOperacaoRegraContabilOrigemId = operacaoRegraContabil.grupoEmpresaOperacaoRegraContabilOrigemId
        this.ativo = operacaoRegraContabil.ativo
        this.tipoRegraDescricao = operacaoRegraContabil.tipoRegraDescricao;
        this.tipoOperacaoDescricao = operacaoRegraContabil.tipoRegraDescricao;
        this.statusRegra = operacaoRegraContabil.statusRegra;
      }
    }
  }
}
