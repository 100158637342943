export class TipoDesconto {
  id: number;
  cod: string;
  descricao: string;

  constructor(tipoDesconto?: TipoDesconto | number) {
    if (tipoDesconto !== undefined && tipoDesconto !== null) {
      if (typeof tipoDesconto === 'number') {
        this.id = tipoDesconto;
      } else {
        this.id = tipoDesconto.id;
        this.cod = tipoDesconto.cod;
        this.descricao = tipoDesconto.descricao;
      }
    }
  }
}
