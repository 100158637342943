import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Empresa } from '../../cadastro/model/empresa.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { UtilService } from 'app/shared/services/util.service';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Toolbar } from 'app/shared/model/toolbar.model';
import exportExcel from 'app/util/processing/exportExcel'

@Component({
  selector: 'app-cad-ccus',
  templateUrl: './cad-ccus.component.html',
  styleUrls: ['./cad-ccus.component.scss'],
  providers: [CrudService]
})
export class CadCcusComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public CadCcus: CadCcus = new CadCcus();
  public registroCadCcus: Array<CadCcus> = new Array();

  ids: Array<any> = new Array();

  selectCad: Array<any> = new Array()

  //Boolean
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;
  ativo: boolean = false;
  projetoRelac: boolean = false;

  //Strings
  codigo: string;
  descricao: string;
  tipo: any;
  dataInicial: string;
  dataFinal: string;

  //Select
  opcoesProjeto: SelectItem[] =  [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
  opcoesAtivo: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
  opcoesCentroCusto: SelectItem[] = [{ label: 'Administrativo', value: "Administrativo" }, { label: 'Produtivo', value: "Produtivo" }];
  opcoesTipo: SelectItem[]
  exportarCentroCusto = ['codCcus', 'descrCcus', 'dtInicialFormatoBrasil', 'dtFinalFormatoBrasil', 'tipoCcusDescricao', 'ativoLabel', 'projetosRelacionadosLabel']

  //Number
  totalPeriodo: number = 0;

  msgs: Message[];

  title = 'Cadastro Projeto';
  subtitle = "projeto";
  entityPath = 'cadccus';
  params = '?$select=codCcus,descrCcus,dtInicial,dtFinal,tipoCcus/descricao,ativo,projetosRelacionados&$count=true';
  skip = 0;
  top = 50;
  readonly = false;

  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeViewSubscription: Subscription;
  private beforeEditSubscription: Subscription;

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private utilService: UtilService,
    private configService: ConfigService,
    ) {
    super(crudService);
  }
  public toolbarCadCcus = new Toolbar()

  toolbarMainCadCcus = [
    { key: "voltar", icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: "edit", icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { key: "delete", icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { key: "add", icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { key: "saveNew", icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { key: "save", icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
    { key: "export", icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar excel', visible: true, disabled: false, color: 'default', onClick: () => exportExcel(this.registroCadCcus, 'centro-de-custo', this.exportarCentroCusto) },
  ]


  ngOnInit() {
    this.toolbarService.hidden = true
    this.toolbarCadCcus.setAll(this.toolbarMainCadCcus)
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.carregar()
        }
      }
    );
  }
  carregar() {
    this.title = 'CENTRO DE CUSTO';
  this.subtitle = 'INFORMAÇÕES SOBRE CENTRO DE CUSTO';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.selectCad = []
    this.limpaCamposForm();
    this.loadRegistros();

    this.toolbarCadCcus.setVisible('voltar', false)
    this.toolbarCadCcus.setVisible('edit', false)
    this.toolbarCadCcus.setVisible('delete', false)
    this.toolbarCadCcus.setVisible('add', true)
    this.toolbarCadCcus.setVisible('saveNew', false)
    this.toolbarCadCcus.setVisible('save', false)
  }


  loadItems() {
    this.opcoesTipo = new Array();
    this.opcoesTipo.push({label: 'Nenhum', value: 0 });
    this.httpService.wait()
    this.httpService.get(`tipoccus?$select=id,codigo,descricao&$orderby=codigo,descricao`)
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesTipo.push({
            label: `${element.codigo} - ${element.descricao}`,
            value: element.id
          });
          this.httpService.done()
        });
      }, error => this.httpService.handleError(error, () => this.httpService.done()));
  }

  criarNovo() {
    this.loadItems()
    this.novaConta = true;
    this.title = 'Cadastrar Centro de Custo';

    this.toolbarCadCcus.setVisible('voltar', true)
    this.toolbarCadCcus.setVisible('edit', false)
    this.toolbarCadCcus.setVisible('delete', false)
    this.toolbarCadCcus.setVisible('add', false)
    this.toolbarCadCcus.setVisible('saveNew', true)
    this.toolbarCadCcus.setVisible('save', false)
  }

  loadRegistros() {
    this.httpService.wait()
    this.httpService
      .get(`/cadccus?$select=id,codCcus,descrCcus,dtInicial,dtFinal,tipoCcus/descricao,ativo,projetosRelacionados&$filter=empresa/id eq
            ${this.dblinkedSessionService.empresa.id}
             &$orderby=codCcus`)
      .subscribe(
        (retorno) => {
          this.registroCadCcus = new Array();
          retorno.value.forEach(item => {
            this.registroCadCcus.push({
              ...item,
              dtInicialFormatoBrasil: this.converteDataParaFormatoBrasileiro(item.dtInicial),
              dtFinalFormatoBrasil: this.converteDataParaFormatoBrasileiro(item.dtFinal),
              tipoCcusDescricao: item.tipoCcus?.descricao,
              ativoLabel: item.ativo ? 'Ativo' : 'Inativo',
              projetosRelacionadosLabel: item.projetosRelacionados ? 'Sim' : 'Não'
            });
            this.httpService.done()
          })
          this.httpService.done()
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar Centro de Custo';
    this.editarConta = true;

    this.toolbarCadCcus.setVisible('voltar', true)
    this.toolbarCadCcus.setVisible('edit', false)
    this.toolbarCadCcus.setVisible('delete', true)
    this.toolbarCadCcus.setVisible('add', false)
    this.toolbarCadCcus.setVisible('saveNew', false)
    this.toolbarCadCcus.setVisible('save', true)

    this.selectCad = []
    this.loadItems()
    this.limpaCamposForm();
    this.httpService.wait()
    this.httpService.get(`/cadccus(${this.ids[0]})?$select=codCcus,descrCcus,dtInicial,dtFinal,tipoCcus/id,tipoCcus/codigo,tipoCcus/descricao,ativo,projetosRelacionados`)
      .subscribe(plano => {
        this.tipo = new CadCcus()
        plano.value.tipoCcus ? this.tipo = plano.value.tipoCcus.id : this.tipo = null
        this.codigo = plano.value.codCcus;
        this.descricao = plano.value.descrCcus;
        this.dataInicial = this.adicionaUmDia(plano.value.dtInicial);
        this.dataInicial = this.converteDataParaFormatoBrasileiro(this.dataInicial);
        this.dataFinal = this.adicionaUmDia(plano.value.dtFinal);
        this.dataFinal = this.converteDataParaFormatoBrasileiro(this.dataFinal);
        this.ativo = plano.value.ativo;
        this.projetoRelac = plano.value.projetosRelacionados;
        this.httpService.done()
      },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
  }

  filtrarVazio(jsondata) {
    if(this.tipo === null || this.tipo == '') {
       delete jsondata['tipoCcus']
    }

  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      ativo: this.ativo,
      codCcus: this.codigo,
      descrCcus: this.descricao,
      descricao: this.tipo,
      dtInicial: this.dataInicial,
      dtFinal: this.dataFinal,
      projetosRelacionados: this.projetoRelac,
      empresa: {
        id: this.dblinkedSessionService.empresa.id
      },
      tipoCcus: {
        id: this.tipo
      },
    };
    this.filtrarVazio(jsonData);


    this.httpService.wait();
    this.httpService.post('/cadccus?$select=id,codCcus,descrCcus,dtInicial,dtFinal,tipoCcus/descricao,ativo,projetosRelacionados', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Centro de Custo cadastrado com sucesso!',
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const listasEnviar = {
      id: this.ids[0],
      codCcus: this.codigo,
      descrCcus: this.descricao,
      dtInicial: this.converteDataParaFormatoAmericano(this.dataInicial),
      dtFinal: this.converteDataParaFormatoAmericano(this.dataFinal),
      projetosRelacionados: this.projetoRelac,
      ativo: this.ativo,
      tipoCcus: {
        id: this.tipo
      },

    };

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/cadccus', key, listasEnviar, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Centro de Custo alterado com sucesso!',
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do Centro de Custo!',
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      const listasEnviar = {
        idsContaContabil: this.ids,
      };
      this.httpService.wait();
      this.httpService.delete('/cadccus', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Centro de Custo excluído com sucesso!',
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());
    }
  }

  onRowSelect(event: any) {
    this.ids = new Array()
    this.ids.push(event.data.id);
    event.selected = false;
    this.changeToolbar()
  }

  onRowUnselect(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.changeToolbarUn()
  }

  changeToolbar() {
    this.toolbarCadCcus.setVisible('edit', true)
    this.toolbarCadCcus.setVisible('delete', true)
  }

  changeToolbarUn(){
    this.toolbarCadCcus.setVisible('edit', false)
    this.toolbarCadCcus.setVisible('delete', false)
  }

  validacao(): boolean {
    if ((this.codigo === null || this.codigo === undefined) || this.codigo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório' });
      return true;
    }
    if ((this.descricao === null || this.descricao === undefined) || this.descricao.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição é Obrigatória' });
      return true;
    }

    if ((this.dataInicial === null || this.dataInicial === undefined) || this.dataInicial.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data Inicial é Obrigatória' });
      return true;
    }

    if ((this.dataFinal === null || this.dataFinal === undefined) || this.dataFinal.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data Final é Obrigatória' });
      return true;
    }

     if ((this.tipo === '')) {
       this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Selecione o tipo é Obrigatória' });
    return true;
     }

    return false;
  }

  private converteDataParaFormatoBrasileiro(milisegundos: string) {
    const d = new Date(milisegundos);

    return this.utilService.dateToStringDate(d);
  }

  private adicionaUmDia = (milisegundos) => milisegundos + 86400000;

  private converteDataParaFormatoAmericano(data) {
    let formatoBrasileiro;

    if (typeof data === 'object') {//GMT...
      const d = new Date(data);
      formatoBrasileiro = this.utilService.dateToStringDate(d);
    } else {//dd-mm-YYYY
      formatoBrasileiro = data;
    }

    const [dia, mes, ano] = formatoBrasileiro.split('/');

    return `${ano}-${mes}-${dia}`;
  }

  private limpaCamposForm() {
    this.codigo = '';
    this.descricao = '';
    this.dataInicial = '';
    this.dataFinal = '';
    this.tipo = '';
    this.ativo = false;
    this.projetoRelac = false;
  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.sessionSubscription.remove(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }
  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }
  get loading(): boolean {
    return this.httpService.loading;
  }
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
