import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { GrupoTributacao } from 'app/cadastro/model/grupo-tributacao.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { UtilService } from 'app/shared/services/util.service';

@Component({
  selector: 'app-grupo-tributacao',
  templateUrl: './grupo-tributacao.component.html',
  styleUrls: ['./grupo-tributacao.component.scss'],
  providers: [CrudService]
})
export class GrupoTributacaoComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public grupoTributacao: GrupoTributacao = new GrupoTributacao();
  id: Number = 0;

  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private afterViewSubscription: Subscription;
  private beforeEditSubscription: Subscription;
  ctrlSave: boolean;
  codigo: string;
  descricao: string;
  dtIni: Date;

  entityFilter: string = null;

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService) {
    super(crudService);
  }

  ngOnInit() {

    this.ctrlSave = true;

    this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((grupoTributacao: GrupoTributacao) => {
      grupoTributacao.changeUser = new AuthUser(this.dblinkedSessionService.usuario.id);

      if ( this.ctrlSave ) {
        grupoTributacao.empresa = new Empresa(this.dblinkedSessionService.empresa.id);
      }

    });

    this.afterViewSubscription = this.crudService.afterView.subscribe((grupoTributacao: GrupoTributacao) => {
      const parentControlItem = this.controls.find(control => control.key === 'item');

      this.crudService.fillOptions(parentControlItem,
        '/custom/cadastro/itens-grupo(' + this.dblinkedSessionService.empresa.id + ',' +
        grupoTributacao.id + ')');
    });

    this.beforeEditSubscription = this.crudService.beforeEdit.subscribe((grupoTributacao: GrupoTributacao) => {
      this.ctrlSave = false;

    });


    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
      () => { this.empresaChanged(); });

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.entityFilter = 'empresa/id eq ' + this.dblinkedSessionService.empresa.id;

      this.columns = [
        new DataTableColumn('Código', 'codigo'),
        new DataTableColumn('Descrição', 'descricao'),
        new DataTableColumn('Data Inicial', 'dtInicial').setTypeDate(),
        new DataTableColumn('Data Final', 'dtFinal').setTypeDate()
      ];

      this.controls = [
        new DataControl('Código', 'codigo')
          .setRequired()
          .setMaxLength(255),
          new DataControl('Descrição', 'descricao')
          .setRequired()
          .setMaxLength(255),
          new DataControl('Itens', 'item')
          .setTypeMultiSelect()
          .setOptions({ url: '/custom/cadastro/itens-grupo(' + this.dblinkedSessionService.empresa.id + ',' +
        this.id + ')',
          value: 'id', label: ['descricao'] }),
          new DataControl('Impostos', 'aliquotaTributo')
          .setTypeMultiSelect()
          .setOptions({ url: '/aliquotatributo?$select=id,nome&$filter=empresa/id eq '
          + this.dblinkedSessionService.empresa.id + '&$orderby=nome', value: 'id', label: 'nome' }),
          new DataControl('Data Inicial', 'dtInicial')
          .setRequired()
          .setTypeDate(),
        new DataControl('Data Final', 'dtFinal')
          .setTypeDate()
      ];
    }
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }
}
