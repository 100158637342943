import { AuthUser } from 'app/proof/model/auth-user.model';
import { FormaPagamento } from 'app/util/model/forma-pagamento.model';

export class FormaPagamentoDetalhe {
  id: number;
  formaPagamento: FormaPagamento;
  dias: number;
  porcentagem: number;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;
  parcela: number;
  acrescentaMes: number;
  txAdmVista: number;
  txAdmParcelado: number;
  antecipacaoVista: number;
  antecipacaoParcelado: number;
  payday: number;
  splitPagamento: Array<any>

  constructor(formaPagamentoDetalhe?: FormaPagamentoDetalhe | number) {
    if (formaPagamentoDetalhe !== undefined && formaPagamentoDetalhe !== null) {
      if (typeof formaPagamentoDetalhe === 'number') {
        this.id = formaPagamentoDetalhe;
      } else {
        this.id = formaPagamentoDetalhe.id;
        this.formaPagamento = formaPagamentoDetalhe.formaPagamento;
        this.dias = formaPagamentoDetalhe.dias;
        this.porcentagem = formaPagamentoDetalhe.porcentagem;
        this.createTimestamp = formaPagamentoDetalhe.createTimestamp;
        this.changeUser = formaPagamentoDetalhe.changeUser;
        this.changeTimestamp = formaPagamentoDetalhe.changeTimestamp;
        this.parcela = formaPagamentoDetalhe.parcela;
        this.acrescentaMes = formaPagamentoDetalhe.acrescentaMes;
        this.txAdmVista = formaPagamentoDetalhe.txAdmVista;
        this.txAdmParcelado = formaPagamentoDetalhe.txAdmParcelado;
        this.antecipacaoVista = formaPagamentoDetalhe.antecipacaoVista;
        this.antecipacaoParcelado = formaPagamentoDetalhe.antecipacaoParcelado;
        this.payday = formaPagamentoDetalhe.payday;
        this.splitPagamento = formaPagamentoDetalhe.splitPagamento
      }
    }
  }
}
