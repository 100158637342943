import { Component, OnInit } from '@angular/core';
import { CadParticipanteRelac } from '../model/cad-participante-relac.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'app-cad-participante-relac',
  templateUrl: './cad-participante-relac.component.html',
  styleUrls: ['./cad-participante-relac.component.scss'],
  providers: [CrudService]
})
export class CadParticipanteRelacComponent extends DataTableBasicComponent implements OnInit {
  public cadParticipanteRelac: CadParticipanteRelac = new CadParticipanteRelac();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
    new DataTableColumn('Cod Rel', 'codRel.cod'),
    new DataTableColumn('Data Ini', 'dtIniRel').setTypeDate(),
    new DataTableColumn('Data Fin', 'dtFinRel').setTypeDate(),
    new DataTableColumn('Cod Participante', 'cadParticipante.nome')

    ];

    this.controls = [
    new DataControl('Cod Rel', 'codRel')
        .setTypeSelect()
        .setOptions({ url: '/codrel', value: 'id', label: 'cod' }),
    new DataControl('Data Ini', 'dtIniRel')
        .setTypeDate()
        .setRequired(),
    new DataControl('Data Fin', 'dtFinRel')
        .setTypeDate()
        .setRequired(),
    new DataControl('Cod Participante', 'cadParticipante')
        .setTypeSelect()
        .setOptions({ url: '/cadparticipante', value: 'id', label: 'nome' })
    ];
  }
}
