import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { Empresa } from '../../cadastro/model/empresa.model';

@Component({
  selector: 'app-periodo',
  templateUrl: './periodo.component.html',
  styleUrls: ['./periodo.component.scss'],
})

export class PeriodoEstoqueComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription
  titlePrincipal: string
  subtitle: string
  periodoEstoque: Array<any> = new Array()
  listaProcessosUsuario: Array<any> = new Array()

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.toolbarService.hidden = true
    this.titlePrincipal = 'ESTOQUE'
    this.titleService.title = 'Período - Estoque'
    this.subtitle = 'Controle de Períodos - ESTOQUE'
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {this.change()})
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
    this.dblinkedSessionService.showPeriods()
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

      this.atualizarProcessos()
      this.httpService.wait();
      this.httpService.get('/custom/estoque/estoque-buscar-periodo(' + this.dblinkedSessionService.empresa.id +
        ',' + this.dblinkedSessionService.periodo.year.value + ')')
        .subscribe(res => {
          if (res.error) {

            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
          } else {
            this.periodoEstoque = new Array();
            res.value.forEach(element => {
              console.log(element);
              this.periodoEstoque.push(element);
            });
          }
        },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
    }
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  encerrarPeriodo(value: any) {
    if (confirm('Deseja realmente encerrar o mês de ' + value.mes + '?')) {
      const listasEnviar = {
        cadEcdId: value.id,
        usuarioId: this.sessionService.loggedUser.id,
        tipo: 'E'
      }

      this.httpService.wait()
      this.httpService.post('/custom/estoque/estoque-status-periodo', listasEnviar)
        .subscribe(res => {
          if (res.error) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: res.message })
            this.change()
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: res.message })
            this.change()
          }
        },
          error => this.httpService.handleError(error, () => this.encerrarPeriodo(value)),
          () => this.httpService.done())
    }
  }

  abrirPeriodo(value: any) {
    if (confirm('Deseja realmente abrir o mês de ' + value.mes + '?')) {

      const listasEnviar = {
        cadEcdId: value.id,
        usuarioId: this.sessionService.loggedUser.id,
        tipo: 'A'
      };

      this.httpService.wait();
      this.httpService.post('/custom/estoque/estoque-status-periodo', listasEnviar)
        .subscribe(res => {
          if (res.error) {

            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
            this.change();
          } else {

            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
            this.change();
          }

        },
          error => this.httpService.handleError(error, () => this.abrirPeriodo(value)),
          () => this.httpService.done());

    }
  }

  confirmRecalcularCusto(value: any) {
    if (value.statusId === 2) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Esse mês está fechado, não é possível recalcular o custo' })
    } else {
      if (confirm('Deseja realmente recalcular o custo ? Não será possível movimentar o estoque enquanto o processamento está ocorrendo.')) {
        this.recalcularCusto(value)
      }
    }
  }

  transferirSaldo(value: any) {
    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      ecd_id: value.id,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + "estoque/recalcular-saldo", infos)
    .subscribe(
      res => {
        this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: res.message })
        this.httpService.done()
        this.encerrarPeriodo(value)
      },
      (err: HttpErrorResponse) => {
        if (err.status === 400) this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: err.error.message })
        else this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: "Erro no API ao recalcular o saldo, contate o suporte: " + err.message })
        this.httpService.done()
      }
    )

  }

  recalcularCusto(value: any) {
    const infos = {
      cadEcdId: value.id,
      userId: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    this.httpService.post('/custom/estoque/recalcular-custo', infos)
    .subscribe(
      res => {
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: '', detail: res.message })
        this.atualizarProcessos()
        this.httpService.done()
      },
      (err: HttpErrorResponse) => {
        if (err.status === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: err.error.message })
        else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: "Erro no API ao recalcular o custo, contate o suporte: " + err.message })
        this.httpService.done()
      }
    )
  }

  atualizarProcessos(){
    this.listaProcessosUsuario = new Array()
    this.httpService.get('/custom/estoque/status-reprocessamento(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(
        ref => this.listaProcessosUsuario = ref,
        error => this.httpService.handleError(error, () => this.change()),
    () => this.httpService.done())
  }

}
