
import { Pedido } from 'app/financeiro/model/pedido.model';
import { OperacaoRegra } from 'app/cadastro/model/operacao-regra.model';
import { Item } from 'app/cadastro/model/item.model';
import { ItemPrecoVenda } from 'app/cadastro/model/item-preco-venda.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { TipoDesconto } from 'app/util/model/tipo-desconto.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadProj } from 'app/contabil/model/cad-proj.model';
import { Nfe } from 'app/financeiro/model/nfe.model';
import { CstIpi } from '../../util/model/cst-ipi.model';
import { CstIcms } from '../../util/model/cst-icms.model';
import { CstPiscofins } from '../../util/model/cst-piscofins.model';
import { StatusEscrituracao } from '../../util/model/status-escrituracao.model';
import { PlanoContas } from './plano-contas.model';

export class EscrituracaoEntrada {
  id: number;
  pedido: Pedido;
  nfe: Nfe;
  dtLcto: Date;
  planoContas: PlanoContas;
  codCta: string;
  descrCta: string;
  indDc: string;
  vlTotal: any;
  cadCcus: CadCcus;
  codCcus: string;
  descrCcus: string;
  vlCc: any;
  cadProj: CadProj;
  codProj: string;
  descrProj: string;
  vlCp: any;
  statusEscrituracao: StatusEscrituracao;
  histCompl: string;


  constructor(escrituracaoEntrada?: EscrituracaoEntrada | number) {
    if (escrituracaoEntrada !== undefined && escrituracaoEntrada !== null) {
      if (typeof escrituracaoEntrada === 'number') {
        this.id = escrituracaoEntrada;
      } else {
        this.id = escrituracaoEntrada.id;
        this.pedido = escrituracaoEntrada.pedido;
        this.nfe = escrituracaoEntrada.nfe;
        this.dtLcto = escrituracaoEntrada.dtLcto;
        this.planoContas = escrituracaoEntrada.planoContas;
        this.codCta = escrituracaoEntrada.codCta;
        this.descrCta = escrituracaoEntrada.descrCta;
        this.indDc = escrituracaoEntrada.indDc;
        this.vlTotal = escrituracaoEntrada.vlTotal;
        this.cadCcus = escrituracaoEntrada.cadCcus;
        this.codCcus = escrituracaoEntrada.codCcus;
        this.descrCcus = escrituracaoEntrada.descrCcus;
        this.vlCc = escrituracaoEntrada.vlCc;
        this.cadProj = escrituracaoEntrada.cadProj;
        this.codProj = escrituracaoEntrada.codProj;
        this.descrProj = escrituracaoEntrada.descrProj;
        this.vlCp = escrituracaoEntrada.vlCp;
        this.statusEscrituracao = escrituracaoEntrada.statusEscrituracao;
        this.histCompl = escrituracaoEntrada.histCompl;
      }
    }
  }
}
