import { HttpService } from '../../../services/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { SessionService } from 'app/shared/services/session.service'
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api'
import { TabChanger } from '../components/interfaces/dash-changer'
import { TableContent } from '../components/interfaces/dash-table'
import { DashMainComponent } from '../dash-main/dash-main.component'

@Component({
  selector: 'app-dash-stock-list',
  templateUrl: './dash-stock-list.component.html',
  styleUrls: ['./dash-stock-list.component.scss']
})
export class DashStockListComponent extends DashMainComponent implements OnInit, OnDestroy {

  stockControlTable: TableContent
  stockBuyListTable: TableContent

  isLoading: boolean
  isTableLoading: boolean = false
  isTableExportLoading: boolean = false
  loadingOptWarehouses: boolean
  loadingOptIconWarehouses: boolean

  tabStock: any
  periodSelected: any

  customExport: Function = () => {}

  stockControlList: Array<any> = new Array()
  stockBuyList: Array<any> = new Array()
  availableWarehouses : Array<SelectItem> = new Array()
  selectedWarehouses : Array<number> = new Array()
  almoxarifados: Array<any> = new Array();

  placeholderWarehouse: string = 'Selecione um almoxarifado'

  periodOptions: Array<TabChanger> = new Array()
  tabOptionsTable: Array<TabChanger> = new Array()

  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService,
    private configService: ConfigService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    public messageService: MessageService
  ) { super(messageService, dblinkedSessionService) }

  public ngOnInit(): void {
    this.startVars()
    this.startTabs()
    this.initialize()
  }

  public ngOnDestroy(): void {
    this.startVars()
  }

  public async initialize(): Promise <void>{
    this.isLoading = true
    await this.getWarehouses()
    this.getStockDetails(false)
  }

  public async getWarehouses(): Promise<void> {
    try{
      await this.httpService.get('/custom/cadastro/get-almoxarifado(' + this.dblinkedSessionService.empresa.id + ')').toPromise().then(rst => {
        rst.forEach( item => this.availableWarehouses.push({ label: item.descricao, value: item.id }))
      }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao carregar o grupo de empresas! Contate o suporte'))
    } catch (err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    }
    finally {
      if (this.availableWarehouses.length > 0){
        this.placeholderWarehouse = this.availableWarehouses[0]['label']
        this.selectedWarehouses.push(this.availableWarehouses[0]['value'])
      }
      else {
        this.placeholderWarehouse = this.dblinkedSessionService.empresa.nomeFantasia.toString()
        this.selectedWarehouses = []
      }
      this.loadingOptWarehouses = false
      this.loadingOptIconWarehouses = false
    }
  }

  private async getStockDetails(isLoading: boolean, lazyLoading: LazyLoadEvent = {}): Promise<void> {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }

    const payload = {
      empresaId: this.dblinkedSessionService.empresa.id,
      warehouseIds: this.selectedWarehouses,
      dtInicial: this.getDate('initial', this.periodSelected),
      dtFinal: this.getDate('final', this.periodSelected),
      limit: lazyLoading.rows,
      offset: lazyLoading.first,
      sort_order: lazyLoading.sortOrder,
      sort_field: lazyLoading.sortField,
      filters: lazyLoading.filters,
    }
    this.isTableLoading = true
    if(isLoading) this.isLoading = true

    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'homepage/dash-stock-details', payload, httpOptions).toPromise().then(res => {
        if(!res['error']) {
          this.stockControlTable.rows = new Array()
          this.stockBuyListTable.rows = new Array()

          res['data'].stockList.details.forEach(item => item['item_bom_id'] != 0 ? this.stockControlTable.rows.push(item) : null)
          this.stockControlTable.totalRecords = res['data'].stockList.totalRegisters

          res['data'].stockBuyList.details.forEach(item => this.stockBuyListTable.rows.push(item))
          this.stockBuyListTable.totalRecords = res['data'].stockBuyList.totalRegisters

        }
        else this.showErrorResponse(res['mensagem'])
      }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao carregar os detalhes da dashboard! Contate o suporte'))
    } catch(err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.isTableLoading = false
      this.isLoading = false
    }
  }

  public async exportDetails() {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi, }) }

    const httpOptions2 = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        userId: this.sessionService.loggedUser.id.toString(),
        empresaId: this.dblinkedSessionService.empresa.id.toString(),
      }),
      responseType: 'blob' as 'json'
    }

    const payload = {
      empresaId: this.dblinkedSessionService.empresa.id,
      userId: this.sessionService.loggedUser.id,
      dtInicial: this.getDate('initial', this.periodSelected),
      dtFinal: this.getDate('final', this.periodSelected),
      mov: 's',
    }

    this.isTableExportLoading = true

    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + '/homepage/dash-buy-sell/relat', payload, httpOptions).toPromise().then(res => {
        if(!res["error"]) this.httpClient.get(this.configService.defaultUrlApiUpload + '/homepage/dash-buy-sell/relat', httpOptions2).toPromise().then((res: any) => {
          let link = document.createElement('a')
          let url = window.URL.createObjectURL(res)
          link.href = url
          link.download = "relatorio-movimentacao-detalhada-vendas.xlsx"
          link.click()
          window.URL.revokeObjectURL(url)
        }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao recuperar seu relatório! Contate o suporte'))
      }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao gerar seu relatório! Contate o suporte'))
    } catch(err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.isTableExportLoading = false
    }
  }

  private startVars(): void {
    this.stockControlList = new Array()
    this.stockBuyList = new Array()

    this.stockControlTable = {
      cols: [
        { title: 'Produto', width: 10, align: 'left', key: 'descr_item', type: 'text' },
        { title: 'Disponível', width: 5, align: 'left', key: 'qtde_saldo', type: 'text' },
        { title: 'Consumo diário', width: 5, align: 'left', key: 'consumo', type: 'text' },
        { title: 'Produzivel', width: 5, align: 'left', key: 'qtde_produzivel', type: 'text' },
        { title: 'Item necessário', width: 10, align: 'left', key: 'item_emergencial', type: 'text' },
        { title: 'Almoxarifado', width: 10, align: 'left', key: 'descr_almoxarifado', type: 'text' }
      ],
      rows: new Array(),
      totalRecords: 0
    }

    this.stockBuyListTable = {
      cols: [
        { title: 'Produto', width: 15, align: 'left', key: 'descr_item', type: 'text' },
        { title: 'Disponível', width: 4, align: 'right', key: 'qtde_saldo', type: 'text' },
        { title: 'Consumo médio', width: 4, align: 'right', key: 'consumo', type: 'text' },
        { title: 'Almoxarifado', width: 10, align: 'left', key: 'descr_almoxarifado', type: 'text' }
      ],
      rows: new Array(),
      totalRecords: 0
    }
  }

  private startTabs(): void {

    this.tabOptionsTable = [
      { label: 'Controle de produto', value: 'control', active: true },
      { label: 'Lista de Compras', value: 'buyList', active: false }
    ]

    this.periodSelected = 'month'
    this.tabStock = 'control'

  }

  public changePeriod(event: any): void {
    this.periodSelected = event
    this.getStockDetails(true)
  }

  public changeTabTable(event: any): void {
    this.tabStock = event
  }

  public changeFilterDetails(lazyLoading: LazyLoadEvent = {}): void {
    this.getStockDetails(true,lazyLoading)
  }

  public checkWarehousesList(){
    if (this.selectedWarehouses.length === 0) {
      this.showErrorResponse('Por favor, selecione pelo menos um almoxarifado')
      this.placeholderWarehouse = 'Selecione o(s) almoxarifado(s)'
    }
    else this.getStockDetails(false)
  }
}