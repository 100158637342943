import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';

export class GatilhoObrigacaoDisparo {
    id: number;
    gatilhoObrigacao: GatilhoObrigacao;
    dias: number;

    constructor(gatilhoobrigacaodisparo?: GatilhoObrigacaoDisparo | number) {
      if (gatilhoobrigacaodisparo !== undefined && gatilhoobrigacaodisparo !== null) {
        if (typeof gatilhoobrigacaodisparo === 'number') {
          this.id = gatilhoobrigacaodisparo;
        } else {
          this.id = gatilhoobrigacaodisparo.id;
          this.gatilhoObrigacao = gatilhoobrigacaodisparo.gatilhoObrigacao;
          this.dias = gatilhoobrigacaodisparo.dias;
        }
      }
    }
  }
