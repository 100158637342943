import { Component, OnInit, OnDestroy } from '@angular/core';
import { CrudService } from 'app/shared/services/crud.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { Empresa } from '../../cadastro/model/empresa.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { BeneficioFiscal } from '../model/beneficio-fiscal.model';
import { Message, MessageService } from 'primeng/api';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-beneficio-fiscal',
  templateUrl: './beneficio-fiscal.component.html',
  styleUrls: ['./beneficio-fiscal.component.scss'],
  providers: [CrudService]
})
export class BeneficioFiscalComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public beneficioFiscal: BeneficioFiscal = new BeneficioFiscal();
  public registroBeneficio: Array<BeneficioFiscal> = new Array();

  ids: Array<any> = new Array();

  //Boolean
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;

  //Any
  codigo: any;

  //Strings
  nome: string;

  msgs: Message[];

  title = 'BENEFÍCIOS FISCAIS';
  subtitle = "BENEFÍCIO FISCAL";

  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeViewSubscription: Subscription;
  private beforeEditSubscription: Subscription;

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private utilService: UtilService,
    private configService: ConfigService,
    ) {
    super(crudService);
  }
  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]

  ngOnInit() {
    // TODO: REVIEW (IMPORTANT!)
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
    );
  }

  list: Array<number> = new Array();

  selectCheckboxContainer(data){
    setTimeout(() => {
      document.getElementById('checkboxContainer'+data.id).click();
    },100);
  };


  carregar() {
    this.title = 'BENEFÍCIOS FISCAIS';
    this.subtitle = 'BENEFÍCIO FISCAL';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.limpaCamposForm();
    this.loadRegistros();

    this.toolbarMain.setVisible('btn1', false)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', true)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', false)
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'CADASTRAR BENEFÍCIO FISCAL';

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', true)
    this.toolbarMain.setVisible('btn6', false)
  }

  loadRegistros() {
    this.httpService
      .get(`/beneficiofiscal?$select=cod,nome&$orderby=cod`)
      .subscribe(
        (retorno) => {
          this.registroBeneficio = new Array();
          retorno.value.forEach(item => {
            this.registroBeneficio.push({
              ...item,
            });
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'EDITAR BENEFÍCIO FISCAL';
    this.editarConta = true;

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', true)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', true)

    this.limpaCamposForm();

    this.httpService.get(`/beneficiofiscal(${this.ids[0]})?$select=cod,nome&$count=true`)
      .subscribe(plano => {
        this.codigo = plano.value.cod;
        this.nome = plano.value.nome;
      },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      cod: this.codigo,
      nome: this.nome
    };

    this.httpService.wait();
    this.httpService.post('/beneficiofiscal', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const listasEnviar = {
      cod: this.codigo,
      nome: this.nome
    };

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/beneficiofiscal', key, listasEnviar, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do projeto!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      const listasEnviar = {
        idsContaContabil: this.ids,
      };
      this.httpService.wait();
      this.httpService.delete('/beneficiofiscal', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Projeto excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());
    }
  }

  selectCheckbox(event: any) {
    this.ids.push(event.data.id);
    event.selected = false;
    this.changeToolbar()
  }

  unselectCheckbox(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.changeToolbarUn()
  }

  changeToolbar() {
    this.toolbarMain.setVisible('btn2', true)
    this.toolbarMain.setVisible('btn3', true)
  }

  changeToolbarUn(){
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
  }

  validacao(): boolean {
    if ((this.codigo === null || this.codigo === undefined) || this.codigo.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório' });
      return true;
    }
    if ((this.nome === null || this.nome === undefined) || this.nome.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nome é Obrigatório' });
      return true;
    }
    return false;
  }

  private limpaCamposForm() {
   this.codigo = ''
   this.nome = '';
  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.sessionSubscription.remove(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }
  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }
  get loading(): boolean {
    return this.httpService.loading;
  }
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
