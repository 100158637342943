import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { DH_NOT_SUITABLE_GENERATOR } from 'constants';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Util } from 'app/shared/common/util';
import { isNullOrUndefined } from 'util';
import { TabelaCrm} from '../model/tabela-crm.model';
import { TabelaCrmDetalhe} from '../model/tabela-crm-detalhe.model';
import { Message, MessageService, SelectItem } from 'primeng/api';



@Component({
  selector: 'app-tabela-crm-detalhe',
  templateUrl: './tabela-crm-detalhe.component.html',
  styleUrls: ['./tabela-crm-detalhe.component.scss'],
  providers: [CrudService]
})
export class TabelaCrmDetalheComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  pt_BR: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;



  checkedAll : boolean = false;


  listTabelaCrmDetalhe  : Array<any> = new Array();
  opcoesComboStatus : Array<any> = new Array();
  idLista : Array<any> = new Array();


  dataIni: Date;
  dataFin: Date;

  boolModalEditar: boolean = false;
  boolModalNew   : boolean = false;
  opcoesTabelaCrm : SelectItem[] = [];
  tabelaCrmId : number = 0;
  tabelaCrm = new TabelaCrm();
  tabelaCrmDetalhe = new TabelaCrmDetalhe();
  showTabela : boolean = true;

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'CRM';
    this.subtitle = 'Tabela Crm Detalhe ';
    this.titleService.title = 'Tabela Crm Detalhe';



    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.defaultDate = new Date();
    this.dataIni = null;
    this.dataFin = null;
    this.dataFinSearch = null;
    this.dataIniSearch = null;
    this.idLista = new Array();

    this.tabelaCrmDetalhe = new TabelaCrmDetalhe();
    this.tabelaCrmDetalhe.tabelaCrm = new TabelaCrm();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();

    this.checkedAll = false;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {

        this.toolbarEnable();
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

            const mes =
            this.dblinkedSessionService.periodo.month.value.toString.length === 2
              ? this.dblinkedSessionService.periodo.month.value
              : '0' + this.dblinkedSessionService.periodo.month.value;
          const dt =
            this.dblinkedSessionService.periodo.year.value +
            '-' +
            mes +
            '-01 12:00:00';
          this.defaultDate = new Date(dt);
          this.getComboTabelaCrm();
          this.showTabela = true;
          this.boolModalNew = false;
          this.boolModalEditar = false;

        }
        setTimeout(() => {
          this.configuraToolBar()
        }, 1000);

      });

  }


  hideMessage() {


  }

  configuraToolBar() {
    if (!isNullOrUndefined(this.idLista) && this.idLista.length === 1 ) {
      this.toolbarService.alterarStatus('edit', false, 'Editar Registro', () => this.modalEditar(this.idLista[0]));
      this.toolbarService.alterarStatus('add', true, 'Novo Registro', () => this.novoRegistro() );
      this.toolbarService.alterarStatus('remove', false, 'Excluir registro', () => this.excluirRegistro() );
    } else if( !isNullOrUndefined(this.idLista) && this.idLista.length > 1){
      this.toolbarService.alterarStatus('add', true, 'Novo Registro', () => this.novoRegistro() );
      this.toolbarService.alterarStatus('edit', true, 'Editar Registro', () => this.modalEditar(this.idLista[0]));
      this.toolbarService.alterarStatus('remove', false, 'Excluir registro', () => this.excluirRegistro() );
    } else{
      this.toolbarService.alterarStatus('edit', true, 'Editar Registro', () => '');
      this.toolbarService.alterarStatus('add', false, 'Novo Registro', () => this.novoRegistro() );
      this.toolbarService.alterarStatus('remove', true, 'Excluir registro', () => this.excluirRegistro() );

    }

  }

  onFilterEvento(event: any) {
  }

  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('print');
    //this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');

    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.buscarTabelaCrmDetalhe()
    );
    //this.toolbarService.replace('save', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
    this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar Registro', () => '');
    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo',   () => this.novoRegistro()     );

  }


  listar() {
    this.checkedAll = false;
    this.idLista = new Array();

    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

      setTimeout(()=> this.configuraToolBar(), 500);

  }


  ngOnDestroy(): void {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

selectCheckbox(event: any) {
  this.idLista.push(event.data.id);

  setTimeout(()=> this.configuraToolBar(), 500);
}

unselectCheckbox(event: any){
  const index = this.idLista.findIndex(i => i === event.data.id);

  this.idLista.splice(index,1);
}

selectTodos(event: any) {

  this.checkedAll = !this.checkedAll;
  this.idLista = new Array();
  this.listTabelaCrmDetalhe.forEach(i=> {
    if (!this.checkedAll) {
      i.selected = false;
      this.idLista.slice(i.id, 1);
    } else {

      i.selected = true;
      this.idLista.push(i.id);

    }
  });

  setTimeout(() => this.configuraToolBar() ,100)

}


modalEditar(id : any){
  this.showTabela = false;
  this.boolModalEditar = true;
  this.tabelaCrmDetalhe = new TabelaCrmDetalhe();
  this.tabelaCrmDetalhe.tabelaCrm = new TabelaCrm();

  let index = this.listTabelaCrmDetalhe.findIndex(e=> e.id === id);
  this.tabelaCrmDetalhe.id = id;
  this.tabelaCrmDetalhe.campo = this.listTabelaCrmDetalhe[index].campo;
  this.tabelaCrmDetalhe.posicao = this.listTabelaCrmDetalhe[index].posicao;
  this.tabelaCrmDetalhe.tabelaCrmId = this.listTabelaCrmDetalhe[index].tabelaCrmId;
  this.tabelaCrmDetalhe.tabelaCrm.id = this.listTabelaCrmDetalhe[index].tabelaCrmId;


}

salvarTabelaCrmDetalhe(){
  console.log('Salvar Registro novo!');
  console.log('tabelaCrmDetalhe : ' + JSON.stringify( this.tabelaCrmDetalhe));

  if (this.validacao()) {
    return;
  }

  const listaEnviar = {
    tabelaCrmDetalhe     : this.tabelaCrmDetalhe,
    usuarioId            : this.sessionService.loggedUser.id,
    empresaId            : this.dblinkedSessionService.empresa.id,
    IsNovoRegistro       : true
 };

 this.httpService.wait();
 this.httpService.post('/custom/crm/salvar-tabela-crm-detalhe', listaEnviar)
   .subscribe(result => {

     if (result.error) {
       this.messageService.add({
         severity: 'error',key: 'messageLinnks',
         summary: 'MSG: ',
         detail: result.message
       });
     } else {
       this.messageService.add({
         severity: 'success',key: 'messageLinnks',
         summary: 'MSG: ',
         detail: result.message
       });
     }
     this.boolModalEditar = false;
     this.boolModalNew = false;
     this.showTabela = true;
     this.buscarTabelaCrmDetalhe();
   },
     error => this.httpService.handleError(error, () => this.salvarTabelaCrmDetalhe()),
     () => this.httpService.done());


}

editarTabelaCrmDetalhe(){
  console.log('Editando registro!');
  console.log('tabelaCrmDetalhe : ' + JSON.stringify( this.tabelaCrmDetalhe));

  if (this.validacao()) {
    return;
  }

  const listaEnviar = {
    tabelaCrmDetalhe     : this.tabelaCrmDetalhe,
    usuarioId            : this.sessionService.loggedUser.id,
    empresaId            : this.dblinkedSessionService.empresa.id,
    IsNovoRegistro       : false
 };

 this.httpService.wait();
 this.httpService.post('/custom/crm/salvar-tabela-crm-detalhe', listaEnviar)
   .subscribe(result => {

     if (result.error) {
       this.messageService.add({
         severity: 'error',key: 'messageLinnks',
         summary: 'MSG: ',
         detail: result.message
       });
     } else {
       this.messageService.add({
         severity: 'success',key: 'messageLinnks',
         summary: 'MSG: ',
         detail: result.message
       });
     }
     this.boolModalEditar = false;
     this.boolModalNew = false;
     this.showTabela = true;
     this.buscarTabelaCrmDetalhe();
   },
     error => this.httpService.handleError(error, () => this.editarTabelaCrmDetalhe()),
     () => this.httpService.done());


}


validacao(){


  if (this.tabelaCrmDetalhe.tabelaCrmId === null || this.tabelaCrmDetalhe.tabelaCrmId === undefined || this.tabelaCrmDetalhe.tabelaCrmId === 0) {
    this.messageService.add({
      severity: 'warn',key: 'messageLinnks',
      summary: 'MSG: ',
      detail: 'Favor selecionar uma tabela!'
    });
    return true;
  }


  if (isNullOrUndefined( this.tabelaCrmDetalhe.campo) || this.tabelaCrmDetalhe.campo.toString() ==='' ) {
    this.messageService.add({
      severity: 'warn',key: 'messageLinnks',
      summary: 'MSG: ',
      detail: 'Favor digitar o campo!'
    });
    return true;

  }

  if (isNullOrUndefined( this.tabelaCrmDetalhe.posicao) || this.tabelaCrmDetalhe.posicao.toString() ==='' ) {
    this.messageService.add({
      severity: 'warn',key: 'messageLinnks',
      summary: 'MSG: ',
      detail: 'Favor digitar a posição do campo!'
    });
    return true;

  }

  if ( this.tabelaCrmDetalhe.posicao < 1 || this.tabelaCrmDetalhe.posicao >40 ) {
    this.messageService.add({
      severity: 'warn',key: 'messageLinnks',
      summary: 'MSG: ',
      detail: 'A posição do campo deve ficar entre 1 a 40!'
    });
    return true;

  }

  return false;
}


  novoRegistro(){
    console.log('estou aqui');
    this.showTabela = false;
    this.boolModalNew = true;
    this.tabelaCrmDetalhe = new TabelaCrmDetalhe();
    this.tabelaCrmDetalhe.tabelaCrm = new TabelaCrm();
    this.tabelaCrmDetalhe.tabelaCrm.id = this.tabelaCrmId;
    this.tabelaCrmDetalhe.tabelaCrmId = this.tabelaCrmId;

  }



  buscarTabelaCrmDetalhe(){
    this.checkedAll = false;
    this.idLista = new Array();
    this.httpService.wait();
    this.httpService.get('/custom/crm/get-lista-campos-tabela-crm-detalhe(' + [this.tabelaCrmId ] +')')
      .subscribe(retorno => {
        console.log(retorno);
        this.listTabelaCrmDetalhe = new Array();
        retorno.forEach(element => {
          this.listTabelaCrmDetalhe.push(element);
        });


      },
        error => this.httpService.handleError(error, () => this.buscarTabelaCrmDetalhe()),
        () => this.httpService.done());



       setTimeout(()=> this.configuraToolBar(), 500);

  }

  getComboTabelaCrm(){
    this.checkedAll = false;
    this.httpService.wait();
    this.httpService.get('/custom/crm/get-combo-campos-tabela-crm(' + [this.dblinkedSessionService.empresa.id ] +')')
      .subscribe(retorno => {
        this.opcoesTabelaCrm = [];
        retorno.forEach(element => {

          this.opcoesTabelaCrm.push({value: element.id, label: element.descricao});

        });


      },
        error => this.httpService.handleError(error, () => this.getComboTabelaCrm()),
        () => this.httpService.done());

        setTimeout(()=> this.configuraToolBar(), 500);

  }

  excluirRegistro(){
    if (confirm('Deseja excluir esse registro?')) {
        const listaEnviar = {
          ids                   : this.idLista,
      };

      this.httpService.wait();
      this.httpService.post('/custom/crm/deletar-tabela-crm-detalhe', listaEnviar)
        .subscribe(result => {

          if (result.error) {
            this.messageService.add({
              severity: 'error',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: result.message
            });
          } else {
            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: result.message
            });
          }
          this.boolModalEditar = false;
          this.boolModalNew = false;
          this.checkedAll = false;
          this.buscarTabelaCrmDetalhe();
        },
          error => this.httpService.handleError(error, () => this.excluirRegistro()),
          () => this.httpService.done());

      }
    }

}
