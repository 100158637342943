import { Component, /*HostListener, */Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { HttpService } from 'app/shared/services/http.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { Message, SelectItem } from 'primeng/api';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ConfigService } from 'app/shared/services/config.service';
import { DatePipe } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-linnks-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class TableComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;

  /*Variáveis que recebem os parâmetros*/
  @Input() cols         : any;
  @Input() value        : any;
  @Input() filter       : any;
  @Input() exportable   : boolean;
  @Input() paginator    : boolean;
  @Input() globalFilter : boolean;
  @Input() titulo       : any;
  @Input() onlyTable    : boolean;
  contador              : number;
  statusOptions         : SelectItem[];
  statusMovimentacao    : boolean = true;


  constructor(private titleService         : TitleService,
              private httpService          : HttpService,
              private toolbarService       : ToolbarService,
              private sessionService       : SessionService,
              public dblinkedSessionService: DblinkedSessionService,
              public elementRef            : ElementRef,
              private configService        : ConfigService,
              private datePipe             : DatePipe) {
  }

  ngOnInit(): void {

    this.contador = this.value.length;

  }

  ngOnDestroy() {

    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);

  }
  /* checkbox */
  /* edição coluna */
  /* verificar quando vem informação em branco*/
  /* Limpar Filtro MultiSelect */
  /* Fazer Ordenação das opções */
public getOptions(field: any, style: any): SelectItem[]{

  var str = new String(style);
  var splits = str.split(";");

  this.statusOptions = [];

  this.value.forEach(element => {

    if (this.statusOptions.findIndex(s => s.value === element[field]) === -1 && element[field]) {

      this.statusOptions.push({label: splits[0] === "data" ? this.transformDate(element[field]) : element[field], value: element[field]});

    }

  });

  return this.statusOptions;

}

get rowsPerPage() {
  return this.configService.applicationConfig.rowsPerPage;
}

get rows() {
  return this.configService.applicationConfig.rows;
}

get pageLinks() {
  return this.configService.applicationConfig.pageLinks;
}

onFilter(event: any) {

this.contador = 0;

event.filteredValue.forEach(element => {
  this.contador = this.contador + 1;
});

}

transformDate(date) {
  return this.datePipe.transform(date, "dd/MM/yyyy");
}

showTelaMovVenc(tipo) {
  this.statusMovimentacao = false;

  if (tipo === 'TELA_1') {
    this.statusMovimentacao = true;

  }

}

isCardMovVenc: boolean = true;
ShowHIdeMovVenc(){
  this.isCardMovVenc = !this.isCardMovVenc;
}

currentStateMovVenc = 'initial';
  changeStateMovVenc() {
    this.currentStateMovVenc = this.currentStateMovVenc === 'initial' ? 'final' : 'initial';
  }

  public getMyStyles(event: any) {

    var str = new String(event);
    var splits = str.split(";");

    let myStyles = {
       'text-align': (splits[0] === "texto" ? 'left' : splits[0] === "valor" ? 'right' : 'center'),
       'width': splits[1]+'%',
       'font-size':'12px',
       'overflow': 'hidden',
       'text-overflow': 'ellipsis',
       'white-space': 'nowrap'
       };
    return myStyles;
}



}