import { Component, OnDestroy, OnInit } from '@angular/core';
import { Column } from 'app/shared/components/dynamic-table/models/Column';
import { DynamicTableConfiguration } from 'app/shared/components/dynamic-table/models/DynamicTableConfiguration';
import { DynamicTable } from 'app/shared/components/dynamic-table/models/DynamicTable';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-regra-contabil-origem',
  templateUrl: './regra-contabil-origem.component.html',
  styleUrls: ['./regra-contabil-origem.component.scss']
})
export class RegraContabilOrigemComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  title = 'Cadastro'
  subtitle = "Cadastro de operação de regra contábil de origem externa."

  msgs: Array<any> = [];

  public dynamicTable = null;
  updateDynamicTable = null;

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private toolbarService: ToolbarService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.toolbarService.hidden = true;
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      async () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected
          && this.dblinkedSessionService.empresa.id) {
          await this.atualizarPagina();
        }
      })
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
  }

  async atualizarPagina(): Promise<void> {
    this.httpService.wait();
    await this.buildDynamicTable();
    this.httpService.done();
  }

  async loadDinamicTableContent(): Promise<{ operacoesCadastradas, operacoesContabeis }> {

    const operacoesContabeis = await this.getOperacaoRegraContabil();
    const operacoesCadastradas = await this.getOperacaoRegraContabilExterna();

    return {
      operacoesCadastradas,
      operacoesContabeis
    }
  }

  async getOperacaoRegraContabilExterna(): Promise<any> {
    const response = await this.httpService.get('custom/cadastro/operacoes-origem', [this.dblinkedSessionService.empresa.id]).toPromise()
    return response.operacoes
  }

  async getOperacaoRegraContabil(): Promise<Array<SelectItem>> {

    const operacoes = [];

    const pagamentos = await this.httpService.get('/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 2 and tipoRegraContabil/id eq 3'
      + ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo eq true&$orderby=descricao asc').toPromise();

    const recebimentos = await this.httpService.get('/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 1 and tipoRegraContabil/id eq 3'
      + ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo eq true&$orderby=descricao asc').toPromise();

    pagamentos.value.forEach(element => { operacoes.push({ label: element.descricao, value: element.id })})
    recebimentos.value.forEach(element => { operacoes.push({ label: element.descricao, value: element.id })})

    return operacoes
  }

  async buildDynamicTable(): Promise<void> {

    const { operacoesCadastradas, operacoesContabeis } = await this.loadDinamicTableContent();

    const columns: Array<Column> = [
      { key: 'idOrigem', name: 'ID de origem', type: { input: { active: true } } },
      { key: 'descricao', name: 'Operação', type: { input: { active: true } } },
      { key: 'operacaoRegraContabilId', name: 'Operação Contábil', type: { selectItem: { active: true, options: operacoesContabeis, readOnly: true } } },
    ];

    const configuration: DynamicTableConfiguration = {
      name: 'Operações de origem externa',
      content: operacoesCadastradas,
      columns: columns,
      buttons: null,
      lazy: false,
      totalRecords: null,
      form: true,
      selectable: true,
      closable: false,
      deletable: true
    };

    this.dynamicTable = new DynamicTable(configuration);
  }

  async salvarAlteracoes(operacoes: Array<any>): Promise<void> {

    if(this.isValid(operacoes)){
      const data = {
        userId: this.sessionService.loggedUser.id,
        empresaId: this.dblinkedSessionService.empresa.id,
        operacoes
      }

      try {
        this.httpService.wait();
        const response = await this.httpService.post('custom/cadastro/operacao-regra-contabil-origem', data).toPromise();
        this.atualizarPagina();
        this.messageService.add(response)
      } catch (error) {
        this.messageService.add(error)
      } finally {
        this.httpService.done();
      }
    } else {
      this.messageService.add({
        severity: 'warn',
        detail: "Cada ID precisa ser único. por favor verifique todos os ID's na tabela",
        key: 'messageLinnks',
        summary: 'Atenção'
      })
    }
  }

  async deletarOperacoes(operacoes: Array<any>): Promise<void> {

    operacoes = operacoes.map(operacao => operacao.id);

    const data = { operacoes };

    try {
      this.httpService.wait();
      const response = await this.httpService.post('custom/cadastro/deletar-operacoes-origem', data).toPromise();
      this.atualizarPagina();
      this.messageService.add(response)
    } catch (error) {
      this.messageService.add(error)
    } finally {
      this.httpService.done();
    }
  }

  isValid(operacoes: Array<any>){
    const ids = []
    let valid = true

    operacoes.forEach(operacao => {
      if(ids.includes(operacao.idOrigem)){
        valid = false
      }else{
        ids.push(operacao.idOrigem)
      }
    })

    return valid
  }

  catchMessage(event){
    this.messageService.add({
      severity: event.severity,
      detail: event.message,
      key: event.key,
      summary: event.summary
    })
  }
}
