import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { TbIrpjM300 } from 'app/ecf/model/tb-irpj-m300.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';

export class TbIrpjM300Relac {
  id: number;
  planoContas: PlanoContas;
  tbIrpjM300Adicao: TbIrpjM300;
  tbIrpjM300Exclusao: TbIrpjM300;
  cadCcus: CadCcus;

  constructor(tbirpjm300relac?: TbIrpjM300Relac | number | any ) {
    if (tbirpjm300relac !== undefined && tbirpjm300relac !== null) {
      if (typeof tbirpjm300relac === 'number') {
        this.id = tbirpjm300relac;
      } else {
        this.id = tbirpjm300relac.id;
        this.cadCcus = tbirpjm300relac.cadCcus || new CadCcus();
        this.planoContas = tbirpjm300relac.planoContas || new PlanoContas();
        this.tbIrpjM300Adicao = tbirpjm300relac.tbIrpjM300Adicao || new TbIrpjM300();
        this.tbIrpjM300Exclusao = tbirpjm300relac.tbIrpjM300Exclusao || new TbIrpjM300();
      }
    }
  }
}
