import { Component, OnInit } from '@angular/core';
import { Tipi } from '../model/tipi.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { UtilService } from 'app/shared/services/util.service';
import { HttpService } from 'app/shared/services/http.service';
import { ConfigService } from 'app/shared/services/config.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-tipi',
  templateUrl: './tipi.component.html',
  styleUrls: ['./tipi.component.scss'],
  providers: [CrudService]
})
export class TipiComponent extends DataTableBasicComponent implements OnInit {
  public tipi: Tipi = new Tipi();
  public registrosTipi: Array<Tipi> = new Array();
  formulario: FormGroup;

  private sessionSubscription: Subscription;

  ctrlSave: boolean;
  entityFilter: string = null;
  editarConta: boolean;
  novaConta: boolean;
  ids: Array<any> = new Array();
  selectTipi: Array<any> = new Array();

  ncm: any;
  ex: any;
  descricao: any;
  aliqIpi: any;
  capitulo: any;
  limitereintegra: any;
  dtIni: any;
  dtFim: any;
  padis: any;

  title = 'Tipi';
  subtitle = "Tipi";

  constructor(crudService: CrudService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private httpService: HttpService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private messageService: MessageService,
    ) {
    super(crudService);
  }

  public toolbarMain = new Toolbar();

  toolbarMainIni = [
    { key: 'voltar',  icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { key: 'saveNew', icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { key: 'save', icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]

  ngOnInit() {
    this.toolbarService.hidden = true;
    this.toolbarMain.setAll(this.toolbarMainIni);

    this.limpaCamposForm();
    // this.createForm(new Tipi());

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
      );
  }

  // createForm(tipi: Tipi){
  //   this.formulario = new FormGroup({
  //     ncm: new FormControl(tipi.ncm),
  //     ex: new FormControl(tipi.ex),
  //     descricao: new FormControl(tipi.descricao),
  //     aliqIpi: new FormControl(tipi.aliqIpi),
  //     capitulo: new FormControl(tipi.capitulo),
  //     limitereintegra: new FormControl(tipi.limitereintegra),
  //     dtIni: new FormControl(tipi.dtIni),
  //     dtFim: new FormControl(tipi.dtFim),
  //     padis: new FormControl(tipi.padis),
  //   })
  // }

  carregar() {
    this.title = 'Tipi';
    this.subtitle = 'Tipi';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.selectTipi = new Array();

    this.limpaCamposForm();

    this.toolbarInitial();

    this.loadRegistros();
  }

  toolbarInitial(){
    this.toolbarMain.setVisible('voltar', false)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.toolbarMain.setVisible('add', true)
    this.toolbarMain.setVisible('saveNew', false)
    this.toolbarMain.setVisible('save', false)
  }

  toolbarNew(){
    this.toolbarMain.setVisible('voltar', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.toolbarMain.setVisible('add', false)
    this.toolbarMain.setVisible('saveNew', true)
    this.toolbarMain.setVisible('save', false)
  }

  toolbarEdit(){
    this.toolbarMain.setVisible('voltar', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', true)
    this.toolbarMain.setVisible('add', false)
    this.toolbarMain.setVisible('saveNew', false)
    this.toolbarMain.setVisible('save', true)
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar';

    this.toolbarNew();

  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService
      .get(`/tipi?$select=ncm,ex,descricao,aliqIpi,limitereintegra,padis&$count=true&$skip=0&$orderby=ncm`)
      .subscribe(
        (retorno) => {
          this.registrosTipi = new Array();
          retorno.value.forEach(item => {
            this.registrosTipi.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar';
    this.editarConta = true;
    this.selectTipi = []

    this.limpaCamposForm();

    this.toolbarEdit();

    this.httpService.wait();
    this.httpService.get(`/tipi(${this.ids[0]})?$select=ncm,ex,descricao,aliqIpi,capitulo,limitereintegra,dtIni,dtFim,padis&$count=true`)
      .subscribe(item => {
          this.ncm = item.value.ncm,
          this.ex = item.value.ex,
          this.descricao = item.value.descricao,
          this.aliqIpi = item.value.aliqIpi,
          this.capitulo = item.value.capitulo,
          this.limitereintegra = item.value.limitereintegra,
          this.dtIni = this.converteDataParaFullText(this.adicionaUmDia(item.value.dtIni)),
          this.dtFim = this.converteDataParaFullText(this.adicionaUmDia(item.value.dtFim)),
          this.padis = item.value.padis
      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonEnvio = {
      ncm: this.ncm,
      ex: this.ex,
      descricao: this.descricao,
      aliqIpi: this.aliqIpi,
      capitulo: this.capitulo,
      limitereintegra: this.limitereintegra,
      dtIni: this.dtIni,
      dtFim: this.dtFim,
      padis: this.padis,
      empresa: {
        id: this.dblinkedSessionService.empresa.id
      },
    }

    this.httpService.wait();
    this.httpService.post('/tipi', jsonEnvio)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const listasEnviar = {
      ncm: this.ncm,
      ex: this.ex,
      descricao: this.descricao,
      aliqIpi: this.aliqIpi,
      capitulo: this.capitulo,
      limitereintegra: this.limitereintegra,
      dtIni: this.converteDataParaFullText(this.dtIni),
      dtFim: this.converteDataParaFullText(this.dtFim),
      padis: this.padis,
    }

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/tipi', key, listasEnviar, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      this.httpService.wait();
      this.httpService.delete('/tipi', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Registro excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
    }
  }

  onRowSelect(event: any) {
    this.ids = new Array();
    this.ids.push(event.data.id);
    event.selected = false;
    this.toolbarMain.setVisible('edit', true)
    this.toolbarMain.setVisible('delete', true)
  }

  onRowUnselect(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
  }

  validacao(): boolean {
      if (this.ncm === undefined || this.ncm === null || this.ncm.length < 1 || this.ncm.length > 8) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'NCM inválido' });
        return true;
      }

      if (this.descricao === undefined || this.descricao === null || this.descricao == '' || this.descricao.length < 1) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição inválida' });
        return true;
      }

      if (this.capitulo === undefined || this.capitulo === null || this.capitulo == '' || this.capitulo.length < 1) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Capítulo inválido' });
        return true;
      }

      if (this.dtIni === undefined || this.dtIni === null || this.dtIni == '') {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data Inicial inválida' });
        return true;
      }
      
    return false;
  }

  provisorio(){}

  private adicionaUmDia = (milisegundos) => {
    if(milisegundos === null || milisegundos == ''){
      return null;
    }

    return milisegundos + 86400000;
  };

  private converteDataParaFullText(data) {
    if(data === null || data === ''){
      return null;
    }

    let novoFormato;
    novoFormato = new Date(data);

    return novoFormato;
  }

  private limpaCamposForm() {
    this.ncm = null;
    this.ex = '';
    this.descricao = '';
    this.aliqIpi = '';
    this.capitulo = '';
    this.limitereintegra = '';
    this.dtIni = '';
    this.dtFim = '';
    this.padis = '';
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
