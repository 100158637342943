import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { SessionService } from 'app/shared/services/session.service'
import { MessageService, SelectItem } from 'primeng/api'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { HttpService } from 'app/shared/services/http.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { Subscription } from 'rxjs'
import { PysocketService } from 'app/shared/services/socket/pysocket.service'
import { Method } from 'app/shared/services/socket/protocol/Method'
import { Action } from 'app/shared/services/socket/protocol/Action'
import { AppComponent } from 'app/app.component'
import { Service } from 'app/shared/services/socket/protocol/Service'

@Component({
  selector: 'app-window-sidebar',
  templateUrl: './window-sidebar.component.html',
  styleUrls: ['./window-sidebar.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({
      })),
      state('final', style({
        opacity: '0',
        height: '0',
        visibility: 'collapse'
      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class WindowSidebarComponent implements OnInit {

  private sessionSubscription: Subscription
  private messagesSubscription: Subscription

  comment: String = ''
  filterPlaceholder: String

  contentFilter
  tooltipApprove
  tooltipRefuse

  idSelected: any

  userId: number
  originSelected: number
  itensSelected: number = 0
  tab: number
  msgEdited: number
  clicksComment: number = 0
  clicksActivities: number = 0
  showingOff: number = 5
  totalValue: number
  cardApprover: number
  sectionSelected: number
  itemSelected: Array<any> = new Array()

  display: boolean = false
  showPreview: boolean = false
  showComments: boolean = false
  showAttachments: boolean = false
  showActivity: boolean = false
  showJustification: boolean = false
  isWriteMessage: boolean = false
  isNewFile: boolean = false
  approvIsSelected: boolean = false
  isCollapseAll: boolean = false
  isEditMessage: boolean = false
  isLoading: boolean = false
  btnLoading: boolean = false
  contentLoading: boolean = false
  mentiosIsDoc: boolean = false
  withComments: boolean = true
  onlyApprov: boolean = false
  canApprove: boolean = false

  rowsSelected: Array<any> = new Array()
  cloneRowsSelected: Array<any> = new Array()
  cardsSelected: Array<any> = new Array()
  filterSelected: Array<any> = new Array()
  messages: Array<any> = new Array()
  attachments: Array<any> = new Array()
  activities: Array<any> = new Array()
  cloneMessages: Array<any> = new Array()
  cloneAttachments: Array<any> = new Array()
  cloneActivities: Array<any> = new Array()
  currentAttachmentState: Array<any> = new Array()
  attachmentState: Array<any> = new Array()
  currentActivityState: Array<any> = new Array()
  activityState: Array<any> = new Array()
  currentPreviewState: Array<any> = new Array()
  previewState: Array<any> = new Array()
  itensApprov: Array<any> = new Array()
  documentList: Array<any> = new Array()
  mentionOptions: Array<any> = new Array()
  mentionList: Array<any> = new Array()
  itemApportionment: Array<any> = new Array()
  approvSelected: Array<any> = new Array()
  approves: Array<any> = new Array()

  filterOptions: SelectItem[]
  sections: SelectItem[]
  origins: SelectItem[]

  file: File = null

  cursorInfo: any

  @ViewChild('file') fileInput: ElementRef
  @ViewChild('fileChoosen') fileName: ElementRef<HTMLSpanElement>
  @ViewChild("backdrop") $backdrop: ElementRef<HTMLDivElement>
  @ViewChild("highlights") $highlights: ElementRef<HTMLDivElement>
  @ViewChild("textarea") $textarea: ElementRef<HTMLTextAreaElement>
  @ViewChild("comments") $comments: ElementRef<HTMLDivElement>

  constructor(
    private sessionService: SessionService,
    private messageService: MessageService,
    private httpService: HttpService,
    private dblinkedSessionService: DblinkedSessionService,
    private socketService: PysocketService,
    private appComponent: AppComponent,
  ) { }

  ngOnInit(): void {
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,() => {
      if(this.dblinkedSessionService.hasEmpresaSelected){
        this.initialize()
      }
    })

    this.appComponent.showMenu = false
    this.appComponent._displaySideNav = false

    this.cardsSelected = JSON.parse(localStorage.getItem("rows"))

    this.isLoading = true
    this.getInformations()

    this.messagesSubscription = this.socketService.socketData.subscribe((response) => {
      if(response.service === Service.APPROVAL_CENTER) {
        switch (response.action) {
          case Action.COMMENT:
            this.onComments(response)
          break
          case Action.ATTACHMENT:
            this.onAttachments(response)
          break
          case Action.ACTIVITY:
            this.onActivities(response)
          break
          case Action.APPROVE:
            this.selectedInformations(response.content.listCardId)
          break
        }
      }
    })
  }

  initialize(): void {
    this.userId = this.sessionService.loggedUser.id
    this.changeTab(0)
    this.getUsers()
    this.getOrigins()
  }

  async getInformations() {
    const payload = {
      listCardId: this.cardsSelected
    }

    try {
      const response = await this.httpService.post('/custom/workflow/detalhe-card-aprovacao', payload).toPromise()
      this.httpService.done()

      this.approves = response

    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Ocorreu um erro ao carregar as aprovações! Contate o suporte.' })
    }

    this.isLoading = false
  }

  async selectedInformations(id: Array<any>) {
    const payload = {
      listCardId: id
    }

    this.contentLoading = true

    try {
      await this.httpService.post('/custom/workflow/detalhe-card-aprovacao', payload).toPromise().then(res => {
        console.log(res)
        res.forEach(item => {
          if(item.aprovadores && item.aprovadores.length > 0) {
            item.labelAprovador = item.aprovadores.length == 1? item.aprovadores[0].fullName : 'Vários'
          }
        })

        this.approvSelected = res
        this.tooltipApprove = res[0].destinoAprov
        this.tooltipRefuse = res[0].destinoRecusa
      })

      this.itemApportionment = new Array()
      this.messages = new Array()

      this.attachments.forEach(item => {
        item.files = new Array()
      })
      this.cloneAttachments.forEach(item => {
        item.files = new Array()
      })

      this.documentList = new Array()

      this.clicksComment = 0
      this.clicksActivities = 0

      this.getFiles()

      this.comment = ''
      this.originSelected = null

      this.httpService.done()
      this.contentLoading = false

    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Ocorreu um erro ao carregar a aprovação! Contate o suporte.' })
    }
  }

  async approve(isApprove: boolean): Promise<void> {
    const payload = {
      userId: this.userId,
      aprovar: isApprove,
      empresaId: this.approvSelected[0].empresaId,
      listTodoCardId: [this.approvSelected[0].id]
    }


    this.btnLoading = true

    try {
      await this.httpService.post('/custom/workflow/aprovar-recusar-card', payload).toPromise().then(res => {

        this.messageService.add({
          severity: res.error ? 'error' : 'success',
          key: 'messageLinnks',
          summary: res.error ? 'Erro: ' : 'Sucesso: ',
          detail: res.response
        })

        if(!res.error) {
          let aux = []

          res.listStatusCard.forEach(item => {
            aux.push(item.todoCardId)
            const payload = {
              service: Service.APPROVAL_CENTER,
              action: Action.APPROVE,
              method: Method.PUT,
              content: {
                message: isApprove ? 'aprovada' : 'recusada',
                status: item.newStatus,
                stage: item.newEtapa,
                userId: this.userId,
                cardId: item.todoCardId,
                userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome
              }
            }

            this.socketService.send(payload)
          })

          const payload = {
            service: Service.APPROVAL_CENTER,
            action: Action.APPROVE,
            method: Method.PUT,
            content: {
              listCardId: aux,
            }
          }

          this.socketService.send(payload)
          this.socketService.sendNotification(res.listNotificacaoId)
        }
      })

      this.httpService.done()
      this.btnLoading = false

    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Não foi possível realizar a operação. Contate o suporte!' })
    }
  }

  getComments(): void {
    this.messages = new Array()

    const payload = {
      service: Service.APPROVAL_CENTER,
      action: Action.COMMENT,
      method: Method.GET,
      content: {
        cardId: this.approvSelected[0].id
      }
    }

    this.socketService.send(payload)
  }

  saveComment(): void {
    if (this.validationComment()) {
      return
    }

    const payload = {
      service: Service.APPROVAL_CENTER,
      action: Action.COMMENT,
      method: Method.POST,
      content: {
        cardId: this.approvSelected[0].id,
        message: this.comment,
        userId: this.userId,
        userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
      }
    }

    this.socketService.send(payload)

    this.comment = ''
  }

  updateComment(): void {
    if (this.validationComment()) {
      return
    }

    const payload = {
      service: Service.APPROVAL_CENTER,
      action: Action.COMMENT,
      method: Method.PUT,
      content: {
        id: this.msgEdited,
        message: this.comment,
        userId: this.userId,
        cardId: this.approvSelected[0].id,
        userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
      }
    }

    this.socketService.send(payload)

    this.isEditMessage = false
    this.msgEdited = null
    this.comment = ''
  }

  deleteComment(event: any): void {
    if (confirm('O comentário será exclúido do histórico da aprovação')) {
      const payload = {
        service: Service.APPROVAL_CENTER,
        action: Action.COMMENT,
        method: Method.DELETE,
        content: {
          id: event.id,
          userId: this.userId,
          cardId: this.approvSelected[0].id,
          userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
        }
      }

      this.socketService.send(payload)
    }
  }

  getFiles(): void {
    const payload = {
      service: Service.APPROVAL_CENTER,
      action: Action.ATTACHMENT,
      method: Method.GET,
      content: {
        cardId: this.approvSelected[0].id,
      }
    }

    this.socketService.send(payload)
  }

  async saveFile(): Promise<void> {
    if (this.validationAttachment()) {
      return
    }

    const payload: FormData = new FormData()
    payload.append('file', this.file)
    payload.append('type', 'workflow')
    payload.append('projetoId', this.approvSelected[0].empresaId.toString())
    payload.append('categoria', 'aprovacao')
    payload.append('cardId', this.approvSelected[0].id)
    payload.append('fileName', this.file.name)

    this.btnLoading = true

    try {
      await this.httpService.post('/custom/upload', payload).toPromise().then((res) => {
        const payload = {
          service: Service.APPROVAL_CENTER,
          action: Action.ATTACHMENT,
          method: Method.POST,
          content: {
            origemId: this.originSelected,
            path: res.path,
            name: this.file.name,
            userId: this.userId,
            cardId: this.approvSelected[0].id,
            userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
          }
        }

        this.socketService.send(payload)
      })

      this.httpService.done()
      this.btnLoading = false

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Seu arquivo foi anexado!' })

    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Seu arquivo não foi anexado corretamente! Contate o suporte' })
    }

    this.originSelected = null
    this.file = null
    this.fileName.nativeElement.innerHTML = ''
  }

  async deleteFile(event: any) {
    if (confirm('O arquivo será exclúido do histórico da aprovação')) {
      const payload = {
        projetoId: this.approvSelected[0].empresaId,
        cardId: this.approvSelected[0].id,
        anexoNome: event.name,
        anexoCategoria: 'aprovacao'
      }

      this.btnLoading = true

      try {
        await this.httpService.post('/custom/workflow/delete-anexo-card', payload).toPromise().then(res => {
          const payload = {
            service: Service.APPROVAL_CENTER,
            action: Action.ATTACHMENT,
            method: Method.DELETE,
            content: {
              id: event.id,
              userId: this.userId,
              cardId: this.approvSelected[0].id,
              userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
            }
          }

          this.socketService.send(payload)
        })

        this.httpService.done()
        this.btnLoading = false

        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Seu arquivo foi removido!' })

      } catch (err) {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Seu arquivo não foi removido corretamente! Contate o suporte' })
      }
    }
  }

  getActivities(): void {
    this.activities = new Array()
    this.cloneActivities = new Array()

    const payload = {
      service: Service.APPROVAL_CENTER,
      action: Action.ACTIVITY,
      method: Method.GET,
      content: {
        cardId: this.approvSelected[0].id,
      }
    }

    this.socketService.send(payload)
  }

  async getUsers(): Promise<void> {
    try {
      await this.httpService.get('/custom/usuario/todos-usuarios-empresa/'+this.approvSelected[0].empresaId).toPromise().then(res => {
        this.mentionList = new Array()
        res.users.forEach(item => {
          this.mentionList.push({
            name: item.alias,
            id: item.id
          })
        })
      })
      this.httpService.done()
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Ocorreu um erro ao carregar os usuários! Contate o suporte.' })
    }
  }

  async getOrigins(): Promise<void> {
    try {
      await this.httpService.get('/custom/workflow/origem').toPromise().then(res => {
        this.attachments = new Array()
        this.cloneAttachments = new Array()
        this.origins = new Array()

        res.forEach(item =>  {
          this.attachments.unshift({
            section: item.descricao,
            id: item.id,
            files: []
          })
          this.cloneAttachments.unshift({
            section: item.descricao,
            id: item.id,
            files: []
          })
          this.origins.unshift({
            label: item.descricao, value: item.id
          })
        })
      })

      this.httpService.done()

      this.attachments.forEach(item => {
        this.currentAttachmentState.push({ label: 'initial' })
        this.attachmentState.push({ label: true })
      })

    } catch (err){
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Ocorreu um erro ao carregar a aprovação! Contate o suporte.' })
    }
  }

  downloadAttachment(event: any) {
    window.open(this.httpService.url('/custom/file/' + this.approvSelected[0].empresaId + '/' + this.rowsSelected[0].id + '/aprovacao/' + event.name + '/workflow'),'_blank')
  }

  onComments(data: any) {
    if(this.approvSelected[0].id === data.content.cardId) {
      switch (data.method) {
        case 'get':
          this.messages.push(data.content)
        break
        case 'post':
          this.messages.unshift(data.content)
        break
        case 'put':
          this.messages.forEach(msg => {
            if(msg.id === data.content.id) {
              msg.message = data.content.message
            }
          })
        break
        case 'delete':
          this.messages = this.messages.filter(line => line.id !== data.content.id)
        break
      }

      this.cloneMessages = this.messages
    }

    if (this.tab === 1) setTimeout(() => {
      this.$comments.nativeElement.scrollTop = this.$comments.nativeElement.scrollHeight
    }, 10)
  }

  onAttachments(data: any) {
    if(this.approvSelected[0].id === data.content.cardId) {
      switch (data.method) {
        case 'get':
          this.attachments.forEach(item => {
            if(data.content.origem_id === item.id) {
              item.files.push({
                id: data.content.id,
                path: data.content.path,
                name: data.content.name
              })
            }
          })

          this.cloneAttachments.forEach(item => {
            if(data.content.origem_id === item.id) {
              item.files.push({
                id: data.content.id,
                path: data.content.path,
                name: data.content.name
              })
            }
          })

          this.documentList.push({
            id: data.content.id,
            name: data.content.name,
          })
        break
        case 'post':
          this.attachments.forEach(item => {
            if(data.content.origemId === item.id) {
              item.files.unshift({
                id: data.content.id,
                path: data.content.path,
                name: data.content.name
              })
            }
          })

          this.cloneAttachments.forEach(item => {
            if(data.content.origemId === item.id) {
              item.files.unshift({
                id: data.content.id,
                path: data.content.path,
                name: data.content.name
              })
            }
          })

          this.documentList.push({
            id: data.content.id,
            name: data.content.name,
          })
        break
        case 'delete':
          this.attachments.forEach(item => {
            item.files = item.files.filter(line => line.id !== data.content.id)
          })

          this.cloneAttachments.forEach(item => {
            item.files = item.files.filter(line => line.id !== data.content.id)
          })

          this.documentList = this.documentList.filter(line => line.id !== data.content.id)
        break
      }
    }
  }

  onActivities(data: any) {
    if(this.approvSelected[0].id === data.content.cardId) {
      if(data.method === 'get') this.activities.push(data.content)
      else this.activities.unshift(data.content)

      this.cloneActivities = this.activities
    }
  }

  selectApprov(event: any): void {
    this.itemSelected = []

    this.approves.forEach(item => {
      if (item.id === event) {
        item.isSelected = !item.isSelected

        if (item.isSelected) {
          this.itemSelected.push(item.id)
        } else {
          this.itemSelected = []
        }
      } else item.isSelected = false
    })

    this.changeTab(0)
    if(this.itemSelected.length > 0) this.selectedInformations(this.itemSelected)
  }

  selectItemApprov(event: any): void {
    this.approvSelected[0].itens.forEach(item => {
      if (item.id === event.id) {
        item.isSelected = !item.isSelected

        if(item.isSelected) this.itemApportionment = item.rateio
        else this.itemApportionment = []

      } else item.isSelected = false
    })
  }

  showMore(){
    this.showingOff += 5
  }

  showLess(){
    if(this.showingOff > 5) this.showingOff -= 5
  }

  possibleApprove(): boolean {
    if(this.approvSelected.length >= 1) {
      if(this.approvSelected[0].status === 'APROVADO' || this.approvSelected[0].status === 'PEDIDO CRIADO' || this.approvSelected[0].status === 'RECUSADO') return false
      else {
        if(this.approvSelected.length === 1) {
          if(this.approvSelected[0].aprovadores.find(line => line.authUserId === this.userId)) return true
          else return false
        } else return true
      }
    }
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0]
    this.fileName.nativeElement.innerHTML = this.file.name
  }

  editMessage(event: any): void {
    this.isEditMessage = true
    this.msgEdited = event.id
    this.comment = event.message
  }

  isUser(userName: string): String {
    let profile = userName.split(" ")
    return profile[0][0] + profile[1][0]
  }

  changeTab(type: number): void {
    this.isCollapseAll = false

    switch(type) {
      case 0:
        this.showPreview = true
        this.showComments = false
        this.showAttachments = false
        this.showActivity = false
        this.showJustification = false
        this.tab = 0
      break
      case 1:
        this.showPreview = false
        this.showComments = true
        this.showAttachments = false
        this.showActivity = false
        this.showJustification = false
        this.tab = 1
        setTimeout(() => {
          this.$comments.nativeElement.scrollTop = this.$comments.nativeElement.scrollHeight
        }, 5)
        if(this.clicksComment === 0) this.getComments()
        this.clicksComment++
        this.filterPlaceholder = 'Pesquise por mensagens'
      break
      case 2:
        this.showPreview = false
        this.showComments = false
        this.showAttachments = true
        this.showActivity = false
        this.showJustification = false
        this.tab = 2
        this.filterPlaceholder = 'Pesquise por anexos'
      break
      case 3:
        this.showPreview = false
        this.showComments = false
        this.showAttachments = false
        this.showActivity = true
        this.showJustification = false
        this.tab = 3
        this.filterPlaceholder = 'Pesquise por atividades'
        if(this.clicksActivities === 0) this.getActivities()
        this.clicksActivities++
      break
      case 4:
        this.showPreview = false
        this.showComments = false
        this.showAttachments = false
        this.showActivity = false
        this.showJustification = true
        this.tab = 4
      break
    }
  }

  cardAttachmentsState(type): void {
    this.currentAttachmentState[type].label = this.currentAttachmentState[type].label === 'initial' ? 'final' : 'initial'
    this.attachmentState[type].label = !this.attachmentState[type].label
  }

  cardActivityState(type): void {
    this.currentActivityState[type].label = this.currentActivityState[type].label === 'initial' ? 'final' : 'initial'
    this.activityState[type].label = !this.activityState[type].label
  }

  cardPreviewState(type): void {
    this.currentPreviewState[type].label = this.currentPreviewState[type].label === 'initial' ? 'final' : 'initial'
    this.previewState[type].label = !this.previewState[type].label
  }

  collapseAll(tab: number): void {
    this.isCollapseAll = !this.isCollapseAll

    let currentLabelState, labelState

    if(this.isCollapseAll) {
      currentLabelState = 'final'
      labelState = false
    } else {
      currentLabelState = 'initial'
      labelState = true
    }

    switch (tab) {
      case 0:
        this.currentPreviewState.forEach(item => {
          item.label = currentLabelState
        })
        this.previewState.forEach(item => {
          item.label = labelState
        })
      break
      case 2:
        this.currentAttachmentState.forEach(item => {
          item.label = currentLabelState
        })
        this.attachmentState.forEach(item => {
          item.label = labelState
        })
      break
      case 3:
        this.currentActivityState.forEach(item => {
          item.label = currentLabelState
        })
        this.activityState.forEach(item => {
          item.label = labelState
        })
      break
    }
  }

  validationComment(): boolean {
    if ((this.comment === null || this.comment === undefined) || this.comment.trim().length < 1 ) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Seu comentário não pode ser vazio!' })
      return true
    }
  }

  validationAttachment(): boolean {
    if (this.originSelected === null || this.originSelected === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nenhuma seção foi selecionada!' })
      return true
    }

    if (this.file === null || this.file === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nenhum anexo foi selecionada!' })
      return true
    }
  }

  onFilter(tab: number): void {
    switch (tab) {
      case 1:
        if(this.contentFilter) {
          this.messages = this.cloneMessages.filter(line => line.message.toLowerCase().normalize("NFD").includes(this.contentFilter.toLowerCase().normalize("NFD")))
        } else {
          this.messages = this.cloneMessages
        }
      break
      case 2:
        if(this.contentFilter) {
          this.attachments.forEach(item => {
            this.cloneAttachments.forEach(elem => {
              if(item.id === elem.id) {
                item.files = elem.files.filter(line => line.name.toLowerCase().normalize("NFD").includes(this.contentFilter.toLowerCase().normalize("NFD")))
              }
            })
          })
        } else {
          this.attachments.forEach(item => {
            this.cloneAttachments.forEach(elem => {
              if(item.id === elem.id) {
                item.files = elem.files
              }
            })
          })
        }
      break
      case 3:
        if(this.contentFilter) {
          this.activities = this.cloneActivities.filter(line => line.action.toLowerCase().normalize("NFD").includes(this.contentFilter.toLowerCase().normalize("NFD")))
        } else {
          this.activities = this.cloneActivities
        }
      break
    }
  }

  showMessages(): void {
    if(this.withComments) this.activities = this.cloneActivities.filter(line => line.key !== 'comment')
    else this.activities = this.cloneActivities

    this.withComments = !this.withComments
  }

  onlyApprovs(): void {
    this.onlyApprov = !this.onlyApprov

    if(this.onlyApprov) this.activities = this.cloneActivities.filter(line => line.key === 'approve')
    else this.activities = this.cloneActivities
  }

  convertDate(dateTime): String {
    const date = new Date(dateTime)
    const today = new Date()

    const fullDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    const fullToday = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
    const yesterday = (today.getDate() - 1) + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()

    if(fullDate === fullToday) return 'Hoje às ' + date.getHours() + 'hr' + date.getMinutes()
    else if(fullDate === yesterday) return 'Ontem às ' + date.getHours() + 'hr' + date.getMinutes()
    else return fullDate + ' às ' + date.getHours() + 'hr' + date.getMinutes()
  }

  convertTimestamp(timestamp): String {
    const date = new Date(timestamp)

    date.setHours(date.getHours()+3)

    const day = date.getDate() > 9 ? date.getDate() : '0'+date.getDate()
    const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : '0'+(date.getMonth()+1)

    const fullDate = day + '/' + month + '/' + date.getFullYear()
    return fullDate
  }

  verifyKey(keyEvent): void {
    let showUserMentions = false, showDocMentions = false

    const userRgx = new RegExp(/(\s\@\w*$)|(^\@\w*)/g),
      docRgx = new RegExp(/(\s\#\w*$)|(^\#\w*)/g),
      position = keyEvent.target.selectionStart,
      inputValue = this.comment.slice(0, position)

    showUserMentions = inputValue.match(userRgx) !== null
    showDocMentions = inputValue.match(docRgx) !== null

    this.mentionControler(showUserMentions || showDocMentions, position, showDocMentions)
  }

  mentionControler(visible, position = 0, isDoc = false): void {
    const optionsElement: Element = document.getElementById('mentionOptions')
    if (visible) {
      this.cursorInfo = { position }
      this.mentionOptions = isDoc ? this.documentList : this.mentionList
      this.mentiosIsDoc = isDoc
      optionsElement.classList.remove('hidden')
    } else {
      optionsElement.classList.add('hidden')
    }
  }

  onSelectMention(id): void {
    try {
      let { position } = this.cursorInfo,
        userRgx = new RegExp(/\@\w*/g),
        docRgx = new RegExp(/\#\w*/g)

      const selectedUser = `${(this.mentiosIsDoc ? this.documentList : this.mentionList)
        .filter((option) => option.id == id)[0].name
        }`

      const usedSlice = this.comment.slice(0, position)
      const restSlice = this.comment.slice(position, this.comment.length)

      const matches = Array.from(usedSlice.matchAll(this.mentiosIsDoc ? docRgx : userRgx))

      const currentMatch = matches[matches.length - 1]

      const startPosition = currentMatch.index + 1
      const endPosition = startPosition + currentMatch[0].length - 1

      const startText = usedSlice.slice(0, startPosition)
      const endText = usedSlice.slice(endPosition, usedSlice.length)

      this.comment = `${startText}${selectedUser}${endText} ${restSlice}`

    } catch (err) {
      console.log(err)
    }
    this.mentionControler(false)
  }

  get highlightedText(): String {
    return this.applyHighlights(this.comment)
  }

  applyHighlights(text): String {
    let userHighlight = "<span class='user' style='color: #0074DD; font-size:13px; font-weight: bolder;'>$&</span>",
      docHighlight = "<span class='doc' style='color: #f5461b; font-size:13px; font-weight: bolder;'>$&</span>",
      response = '',
      highlightNodes: NodeListOf<ChildNode>
    if (text != '') {
      response = text
      if(response) {
        this.mentionList.forEach(x => response = response.replace(new RegExp(`@${x.name}`, 'g'), userHighlight))
        this.documentList.forEach(x => response = response.replace(new RegExp(`#${x.name}`, 'g'), docHighlight))
      }

      if(this.$highlights) {
        highlightNodes = this.$highlights.nativeElement.childNodes
        if (highlightNodes.length > 0) {
          highlightNodes.forEach((node: any) => {
            const style = "padding: 1px 2px; border-radius: 4px; font-size:14px;"
            switch (node.className) {
              case "user":
                node.style = style + "background: #0074DD40"
                break
              case "doc":
                node.style = style + "background: #f5461b80"
                break
            }
          })
        }
      }
    }

    return response
  }

  handleScroll(): void {
    var scrollTop = this.$textarea.nativeElement.scrollTop
    this.$backdrop.nativeElement.scrollTop = scrollTop

    var scrollLeft = this.$textarea.nativeElement.scrollLeft
    this.$backdrop.nativeElement.scrollLeft = scrollLeft
  }

}