import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { PlanoContas } from '../model/plano-contas.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { CodNaturezaConta } from '../../util/model/cod-natureza-conta.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { UtilService } from 'app/shared/services/util.service';

@Component({
  selector: 'app-planocontas',
  templateUrl: './planocontas.component.html',
  styleUrls: ['./planocontas.component.scss'],
  providers: [CrudService]
})
export class PlanoContasComponent  extends DataTableBasicComponent implements OnInit, OnDestroy {
  public planocontas: PlanoContas = new PlanoContas();

  private sessionSubscription: Subscription;
  private beforeViewSubscription: Subscription;

  entityFilter: string = null;

  private parentNivel = 1;

  private dtInicial: string = this.utilService.firstDayOfYear([2000]).format('YYYY-MM-DD');
  private dtFinal: string = this.utilService.lastDayOfYear([2100]).format('YYYY-MM-DD');

  constructor(private crudService: CrudService,
              private httpService: HttpService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private utilService: UtilService) {
    super(crudService);
  }

  ngOnInit() {
    // TODO: REVIEW (IMPORTANT!)
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.beforeViewSubscription = this.crudService.beforeAdd.subscribe((planoContas: PlanoContas) => {

      planoContas.ativo = true;
      planoContas.permiteLancamentoManual = true;
      planoContas.dtFinal = new Date('2100-12-31');

    });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
                                                                 () => { this.empresaChanged(); });
  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.hasEmpresaSelected) {
      this.entityFilter = 'empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ' +
      'period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value;

      this.columns = [
        new DataTableColumn('Código', 'codCta')
          .setFilterStartsWith(),
        new DataTableColumn('Descrição', 'descrCta'),
        new DataTableColumn('Indicador', 'indCta')
          .setTypeSelect()
          .setOptions({ static: true,
                        value: 'value',
                        label: 'label',
                        values: [{ value: 'A', label: 'A - Analítica' },
                                 { value: 'S', label: 'S - Sintética' }] }),
        new DataTableColumn('Nível', 'nivel'),
        new DataTableColumn('Natureza', 'codNaturezaConta')
          .setTypeSelect()
          .setOptions({ url: '/codnaturezaconta', prompt: 'Todas', value: 'id', label: 'descricao'}),
        new DataTableColumn('Data Inicial', 'dtInicial')
          .setTypeDate(),
        new DataTableColumn('Ativo', 'ativo')
          .setTypeCheckBox()
      ];

      this.controls = [
        new DataControl('Empresa', 'empresa')
          .setDisabled()
          .setTypeSelect()
          .setOptions({ url: '/empresa?$filter=id eq ' + this.dblinkedSessionService.empresa.id, value: 'id', label: 'razaoSocial' })
          .setRequired(),
        new DataControl('Código da Conta', 'codCta')
          .setRequired()
          .setMaxLength(255),
        new DataControl('Natureza da Conta', 'codNaturezaConta')
          .setTypeSelect()
          .setOptions({ url: '/codnaturezaconta', value: 'id', label: ['cod', ' - ', 'descricao'] }),
        new DataControl('Indicador', 'indCta')
          .setRequired()
          .setTypeSelect()
          .setOptions({ static: true,
                        value: 'value',
                        label: 'label',
                        values: [{ value: 'A', label: 'A - Analítica' },
                                 { value: 'S', label: 'S - Sintética' }] }),
        new DataControl('Nível', 'nivel')
          .setTypeNumber()
          .setMin(1)
          .setMax(8)
          .setRequired()
          .setOnChange((dataControl: DataControl, value: any) => {
              const parentControl = this.controls.find(control => control.key === 'parent');
              const parentFormControl = this.crudService.form.get('parent');

              parentControl.setDisabled(value <= 0);

              if (value > 1) {
                this.parentNivel = value - 1;
                // TODO: REVIEW (IMPORTANT!)
                this.crudService.fillOptions(parentControl,
                                             '/custom/ecd/planocontas(' + this.dblinkedSessionService.empresa.id + ',' +
                                                                      this.dblinkedSessionService.periodo.year.value + ',' +
                                                                      this.parentNivel + ')');
                parentFormControl.enable();
                parentControl.setRequired();
              } else {
                parentFormControl.setValue(null);
                parentFormControl.disable();
                parentControl.setRequired(false);
              }
            }),
        new DataControl('Conta Superior', 'parent')
          .setTypeSelect()
          .setOptions({ value: 'id',
                        label: ['codCta', ' - ', 'descrCta'] }),
        new DataControl('Descrição', 'descrCta')
          .setRequired()
          .setMaxLength(255),
          new DataControl('Descrição Inglês', 'descrEn')
          .setMaxLength(255),
          new DataControl('Descrição Espanhol', 'descrEs')
          .setMaxLength(255),
        new DataControl('Data Inicial', 'dtInicial')
          .setRequired()
          .setTypeDate(),
        new DataControl('Data Final', 'dtFinal')
          .setRequired()
          .setTypeDate(),
        new DataControl('Data Alteração', 'dtAlt')
          .setTypeDate()
          .setReadOnly(),
          new DataControl('Permite Lançamento Manual', 'permiteLancamentoManual')
          .setTypeCheckBox(),
        new DataControl('Ativo', 'ativo')
          .setTypeCheckBox()
      ];
    }
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }
}
