import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Column } from "./../../shared/components/dynamic-table/models/Column";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription } from "rxjs/Subscription";
import { CrudService } from "app/shared/services/crud.service";
import { HttpService } from "app/shared/services/http.service";
import { SessionService } from "app/shared/services/session.service";
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ToolbarService } from "app/shared/services/toolbar.service";
import { Message, MessageService, SelectItem } from "primeng/api";
import { ConfigService } from "app/shared/services/config.service";
import { isNullOrUndefined } from "util";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { TitleService } from "app/shared/services/title.service";
import { Toolbar } from "app/shared/model/toolbar.model";
import { Table } from "primeng/table";

@Component({
  selector: "app-cad-rateio",
  templateUrl: "./cad-rateio.component.html",
  styleUrls: ["./cad-rateio.component.scss"],
  providers: [CrudService],
  animations: [
    trigger("changeDivSize", [
      state("initial", style({})),
      state(
        "final",
        style({
          opacity: "0",
          height: "0",
          padding: "0px 15px",
        })
      ),
      transition("initial=>final", animate("200ms")),
      transition("final=>initial", animate("200ms")),
    ]),
  ],
})
export class CadRateioComponent implements OnInit, OnDestroy {
  @ViewChild("dt") dt: Table;
  private sessionSubscription: Subscription;

  msgs: Message[] = null;
  msgsModal: Message[] = null;
  subtitle: string;
  title: string;
  modalNew: boolean = false;
  modalEdit: boolean = false;
  rateioLista: Array<any> = new Array();
  // liccus: Array<string> = new Array();
  // liproj: Array<string> = new Array();
  tabelaRateio: Array<any> = new Array();
  liprojtabela: Array<any> = new Array();
  // licolspan: any = null;

  //Variaveis do rateio
  funcList: SelectItem[] = [];
  funcIdNew: number = null;
  ccusList: SelectItem[] = [];
  ccusIdNew: number = null;
  projList: SelectItem[] = [];
  projIdNew: number = null;
  porcentagemNew: number = null;
  projOk: boolean = false;
  ccusIdEdit: number = null;
  ratIdEdit: number = null;
  projIdEdit: number = null;
  porcentagemEdit: number = null;
  repetirPeriod: boolean = true;
  liRowSelected: Array<any> = new Array();

  //export excel
  colsRateioLista: any[];

  //Toolbar
  public toolbarMain = new Toolbar();
  toolbarMainIni = [
    {
      icon: "add",
      text: "Adicinar",
      tooltip: "Adicionar Rateio",
      visible: true,
      disabled: false,
      color: "default",
      onClick: () => this.openAddRateio(),
    },
    {
      icon: "edit",
      text: "Editar",
      tooltip: "Editar Rateio",
      visible: false,
      disabled: false,
      color: "default",
      onClick: () => {
        this.onEditRateio();
      },
    },
    {
      icon: "delete",
      text: "Excluir",
      tooltip: "Excluir Rateio",
      visible: false,
      disabled: false,
      color: "default",
      onClick: () => {
        this.deleteRateio();
      },
    },
    {
      icon: "upload_file",
      text: "Exportar",
      tooltip: "Exportar Excel",
      visible: true,
      disabled: false,
      color: "default",
      onClick: () => this.dt.exportCSV(),
    },
    {
      icon: "save",
      text: "Salvar",
      tooltip: "Salvar Alterações",
      visible: false,
      disabled: false,
      color: "default",
      onClick: () => this.salvarRateio(),
    },
  ];

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private messageService: MessageService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.title = "RATEIO";
    this.subtitle = "Cadastro de rateio";
    this.titleService.title = "Rateio RH";
    this.toolbarService.clear();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.toolbarMain.setAll(this.toolbarMainIni);
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (
          this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected
        ) {
          this.getCcus();
          this.getFunc();
          this.getRateio();
        }
      }
    );

    this.colsRateioLista = [
      { field: "codigo", header: "CÓDIGO" },
      { field: "nome", header: "NOME" },
      { field: "porcent", header: "PORCENTAGEM" },
      { field: "ccus", header: "CENTRO DE CUSTO " },
      { field: "proj", header: "CÓD. PROJ. " },
    ];
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  refreshToolbar() {
    this.toolbarMain.setVisible("btn1", true);
    this.toolbarMain.setVisible("btn2", false);
    this.toolbarMain.setVisible("btn3", false);
    this.toolbarMain.setVisible("btn4", true);
    this.toolbarMain.setVisible("btn5", false);

    if (this.statusTela1) {
      if (this.liRowSelected.length === 1) {
        this.toolbarMain.setVisible("btn2", true);
        this.toolbarMain.setVisible("btn3", true);
      } else if (this.liRowSelected.length > 1) {
        this.toolbarMain.setVisible("btn3", true);
      }
    } else {
      this.toolbarMain.setVisible("btn1", false);
      this.toolbarMain.setVisible("btn4", false);
      this.toolbarMain.setVisible("btn5", true);
    }
  }

  getFunc() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload + "rh/funcionarios",
        httpOptions
      )
      .subscribe(
        (res) => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();
            return;
          }
          this.funcList = [];
          res["data"].forEach((row) => {
            if (row["ativo"])
              this.funcList.push({
                label: row["codigo"] + " - " + row["nome"],
                value: row["id"],
              });
          });
          this.httpService.done();
        },
        (error) => {
          console.log(error);
          this.httpService.done();
          this.exibirMensagem({
            error: true,
            mensagem:
              "Erro no API ao obter os funcionários, contate o suporte: " +
              error.message,
          });
        }
      );
  }

  getProj(ccusid) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        ccusid: ccusid.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload + "contabil/proj",
        httpOptions
      )
      .subscribe(
        (res) => {
          let result = res["mensagem"];
          this.projList = [];
          for (let line of result) {
            this.projList.push({
              label: line["cod_proj"] + " - " + line["descr_proj"],
              value: line["id"],
            });
          }
          if (this.projList.length > 0) {
            this.projOk = true;
          } else {
            this.projOk = false;
          }
          this.httpService.done();
        },
        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getCcus() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .get(
        this.configService.defaultUrlApiUpload + "contabil/ccus",
        httpOptions
      )
      .subscribe(
        (res) => {
          let result = res["mensagem"];
          this.ccusList = [];
          for (let line of result) {
            this.ccusList.push({
              label: line["cod_ccus"] + " - " + line["descr_ccus"],
              value: line["id"],
            });
          }
          this.httpService.done();
        },
        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getRateio() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        ano: this.dblinkedSessionService.periodo.year.value.toString(),
        mes: this.dblinkedSessionService.periodo.month.value.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .get(this.configService.defaultUrlApiUpload + "rh/rateio", httpOptions)
      .subscribe(
        (res) => {
          let result = res["mensagem"]["main"];
          this.rateioLista = [];
          for (let line of result) {
            this.rateioLista.push(line);
          }

          this.buildTabela(res["mensagem"]);
          this.httpService.done();
        },
        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  buildTabela(res) {
    // this.liccus = new Array();
    // this.liproj = new Array();
    this.tabelaRateio = new Array();
    this.liprojtabela = new Array();
    // this.licolspan = new Array();

    // for (let line of res["liccus"]) {
    //   this.liccus.push(line);
    // }

    // for (let line of res["liproj"]) {
    //   this.liproj.push(line);
    // }

    for (let line of res["litabela"]) {
      this.tabelaRateio.push(line);
    }

    for (let line of res["liprojccus"]) {
      this.liprojtabela.push(line);
    }

    // this.licolspan = res["dicccus"];
    // setTimeout(() => {
    //   this.atualizarHeader();
    // }, 500);
  }

  salvarRateio() {
    let totalok = true;

    /*
    for (let line in this.tabelaRateio) {
      if (line["total"] > 100) {
        this.messageService.add({
          severity: "warn",
          key: "messageLinnks",
          summary: "Erro",
          detail:
            "O total do rateio do funcionário " +
            line["funcionario"] +
            " é superior a 100%. \
                                                                    Por favor, corrija antes de salvar as alterações.",
        });
        totalok = false;
      }
      */

    //I-BrunoAmaral

    this.tabelaRateio.forEach((line, idx) => {
      let total: number = 0;
      for (let key in line) {
        if (key != "funcionario" && key != "total" && key != "isSelected") {
          total = line[key];
          total = Math.round(total * 100) / 100;
        }

        if (total > 100) {
          this.messageService.add({
            severity: "warn",
            key: "messageLinnks",
            summary: "Advertência",
            detail:
              "Não é possível Salvar as Alterações, o Rateio do" +
              line["funcionario"] +
              " é superior a 100%. \
                                                                       Por favor, corrija antes de salvar as alterações.",
          });

          totalok = false;
        }
      }
    });

    //F-BrunoAmaral

    if (totalok) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
          userid: this.sessionService.loggedUser.id.toString(),
          ano: this.dblinkedSessionService.periodo.year.value.toString(),
          mes: this.dblinkedSessionService.periodo.month.value.toString(),
          empresaid: this.dblinkedSessionService.empresa.id.toString(),
        }),
      };

      this.httpService.wait();
      this.httpClient
        .post(
          this.configService.defaultUrlApiUpload + "rh/fullrateio",
          this.tabelaRateio,
          httpOptions
        )
        .subscribe(
          (res) => {
            if (!res["error"]) {
              this.getRateio();
            }
            this.exibirMensagem(res);
            this.httpService.done();
          },
          (err) => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  calcularTotal() {
    this.tabelaRateio.forEach((line, idx) => {
      let total: number = 0;
      for (let key in line) {
        if (key != "funcionario" && key != "total" && key != "isSelected") {
          total = line[key];
          total = Math.round(total * 100) / 100;
          //console.log(this.calcularTotal)
        }
        if (line[key] === null) {
          this.tabelaRateio[idx][key] = 0;
        }
        if (total > 100) {
          this.messageService.add({
            severity: "warn",
            key: "messageLinnks",
            summary: "Erro",
            detail:
              "O total do rateio do funcionário" +
              line["funcionario"] +
              " é superior a 100%. \
                                                                         Por favor, corrija antes de salvar as alterações.",
          });

          this.tabelaRateio[idx][key]["total"] = total;
        }
      }
    });
  }
  somadascolunas() {}

  existemColunasDinamicas() {
    return this.liprojtabela.length > 0 ? true : false;
  }

  addRateio() {
    if (!this.repetirPeriod) {
      if (isNullOrUndefined(this.funcIdNew)) {
        this.messageService.add({
          severity: "warn",
          key: "messageLinnks",
          summary: "Erro",
          detail: "O campo 'Funcionário' é obrigatório.",
        });
      } else if (isNullOrUndefined(this.ccusIdNew)) {
        this.messageService.add({
          severity: "warn",
          key: "messageLinnks",
          summary: "Erro",
          detail: "O campo 'Centro de custo' é obrigatório.",
        });
      } else if (isNullOrUndefined(this.projIdNew) && this.projOk) {
        this.messageService.add({
          severity: "warn",
          key: "messageLinnks",
          summary: "Erro",
          detail: "O campo 'Projeto' é obrigatório.",
        });
      } else if (isNullOrUndefined(this.porcentagemNew)) {
        this.messageService.add({
          severity: "warn",
          key: "messageLinnks",
          summary: "Erro",
          detail: "O campo 'Porcentagem' é obrigatório.",
        });
      } else {
        if (!this.projOk) {
          this.projIdNew = 0;
        }
      }
    } else {
      this.ccusIdNew = 0;
      this.projIdNew = 0;
      this.porcentagemNew = 0;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        funcid: this.funcIdNew.toString(),
        mes: this.dblinkedSessionService.periodo.month.value.toString(),
        ano: this.dblinkedSessionService.periodo.year.value.toString(),
        userid: this.sessionService.loggedUser.id.toString(),
        ccus: this.ccusIdNew.toString(),
        cproj: this.projIdNew.toString(),
        percentagem: this.porcentagemNew.toString(),
        repetir: this.repetirPeriod.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .post(
        this.configService.defaultUrlApiUpload + "rh/rateio",
        null,
        httpOptions
      )
      .subscribe(
        (res) => {
          if (!res["error"]) {
            this.getRateio();
            this.modalNew = false;
          }
          this.exibirMensagem(res);
          this.httpService.done();
        },
        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  openAddRateio() {
    this.modalNew = true;
    this.porcentagemNew = null;
    this.ccusIdNew = null;
    this.projIdNew = null;
    this.funcIdNew = null;
    this.projOk = false;
  }

  hideMessage() {}

  onRowSelect(event) {
    this.liRowSelected.push(event.data);
    this.refreshToolbar();
  }

  onRowUnselect(event) {
    let idx: number = this.liRowSelected.findIndex(
      (row) => row.id === event.data.id
    );
    this.liRowSelected.splice(idx, 1);
    this.refreshToolbar();
  }

  onEditRateio() {
    let row: any = this.liRowSelected[0];
    this.ccusIdEdit = row.ccus_id;
    this.ratIdEdit = row.id;
    this.porcentagemEdit = row.porcent;
    this.projIdEdit = row.proj_id;
    if (!isNullOrUndefined(this.projIdEdit)) {
      this.getProj(this.ccusIdEdit);
      this.projOk = true;
    } else {
      this.projOk = false;
    }
    this.modalEdit = true;
  }

  deleteRateio() {
    if (confirm("Deseja realmente excluir os rateios selecionados?")) {
      let liId: Array<number> = new Array();
      this.liRowSelected.forEach((row) => liId.push(row.id));

      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.configService.bearerApi,
          ratid: liId.toString(),
        }),
      };

      this.httpService.wait();
      this.httpClient
        .delete(
          this.configService.defaultUrlApiUpload + "rh/rateio",
          httpOptions
        )
        .subscribe(
          (res) => {
            this.exibirMensagem(res);
            this.httpService.done();
            this.modalEdit = false;
            this.getRateio();
            this.liRowSelected = new Array();
            this.refreshToolbar();
          },
          (err) => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  editRateio() {
    if (!this.projOk) {
      this.projIdEdit = 0;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        ratid: this.ratIdEdit.toString(),
        userid: this.sessionService.loggedUser.id.toString(),
        porcentagem: this.porcentagemEdit.toString(),
        ccus: this.ccusIdEdit.toString(),
        cproj: this.projIdEdit.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient
      .put(
        this.configService.defaultUrlApiUpload + "rh/rateio",
        null,
        httpOptions
      )
      .subscribe(
        (res) => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.modalEdit = false;
          this.getRateio();
          this.liRowSelected = new Array();
          this.refreshToolbar();
        },
        (err) => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({
        severity: "success",
        key: "messageLinnks",
        summary: "Sucesso",
        detail: value.mensagem,
      });
    } else {
      this.messageService.add({
        severity: "warn",
        key: "messageLinnks",
        summary: "Erro",
        detail: value.mensagem,
      });
    }
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return 50;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  /*Show Hide*/
  isCardBenef: boolean = true;
  ShowHIdeCardBenef() {
    this.isCardBenef = !this.isCardBenef;
  }

  currentStateBenef = "initial";
  changeStateBenef() {
    this.currentStateBenef =
      this.currentStateBenef === "initial" ? "final" : "initial";
  }

  fullBenef: boolean = false;
  fullScreenBenef() {
    this.fullBenef = !this.fullBenef;
  }

  statusTela1: boolean = true;
  statusTela2: boolean = false;

  showTelaBeneficios(tipo) {
    this.statusTela1 = false;
    this.statusTela2 = false;

    if (tipo === "TELA_1") {
      this.statusTela1 = true;
    } else if (tipo === "TELA_2") {
      this.statusTela2 = true;
    }
    this.refreshToolbar();
  }
}
