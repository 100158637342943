import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';

@Component({
  selector: 'app-relatorio-fluxo-caixa',
  templateUrl: './relatorio-fluxo-caixa.component.html',
  styleUrls: ['./relatorio-fluxo-caixa.component.scss'],
})

export class RelatorioFluxoCaixaComponent implements OnInit, OnDestroy {
  ecdList: Array<any> = null;
  private sessionSubscription: Subscription;

  titlePrincipal: string;
  subtitle: string;
  cols: any[];
  contents: any[];
  table: any[];
  fluxoCaixa: Array<any> = new Array();
  dias: any;
  conteudo: any;
  saldoInicial: number;
  expandValores: Array<any> = new Array();
  footerValores: Array<any> = new Array();
  idNfesView: Array<any> = new Array();
  exibirPedidos: boolean;
  tipo: any;

  constructor(
    private titleService: TitleService,
    configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService
  ) { }

  ngOnInit(): void {
    this.titlePrincipal = 'RELATÓRIO FLUXO DE CAIXA';
    this.subtitle = 'RELATÓRIO FLUXO DE CAIXA';
    this.titleService.title = 'Fluxo de Caixa';
    this.fluxoCaixa = new Array<any>();
    this.expandValores = new Array<any>();
    this.footerValores = new Array<any>();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.saldoInicial = 0.00;
    this.exibirPedidos =  true;
    this.idNfesView = new Array();
    this.tipo = '';
    // Promise.resolve(null).then(() => this.toolbarRefresh());
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.exibirPedidos =  true; this.listarDados(); this.footerTable(); });
  }

  listarDados() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
        this.httpService.wait();
        this.httpService.get('/custom/financeiro/relatorio-fluxo-caixa',
        [this.dblinkedSessionService.empresa.id,
          this.dblinkedSessionService.periodo.month.value, this.dblinkedSessionService.periodo.year.value, this.exibirPedidos])
        .subscribe(retorno => { this.formatarTabela(retorno); },
        error => this.httpService.handleError(error, () => this.listarDados()),
        () => this.httpService.done());
      }
    }

  formatarTabela(retorno) {
    this.fluxoCaixa = retorno;
      this.dias = [];
      this.dias.push({ field: 'descricao', header: 'Descrição' });
      for (let dia = 1;  dia <= 31; dia++) {
        this.dias.push({ field: dia, header: dia });
      }
      this.dias.push({ field: 'total', header: 'Total' });
      this.cols = this.dias;
      this.contents = this.fluxoCaixa;
  }

  onRowExpand(rowIndex) {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
        this.httpService.wait();
        this.httpService.get('/custom/financeiro/relatorio-fluxo-caixa-expand',
        [this.dblinkedSessionService.empresa.id,
          this.dblinkedSessionService.periodo.month.value, this.dblinkedSessionService.periodo.year.value, rowIndex.data.ids])
        .subscribe(retorno => {

          this.expandValores = retorno;
          /*retorno.forEach(element => {
            if (element.nf.indexOf('P') !== -1 || element.nf.indexOf('A') !== -1) {
              this.tipo = 'Pedido';
            } else {
              this.tipo = 'NF';
            }
          });*/
        },
        error => this.httpService.handleError(error, () => this.listarDados()),
        () => this.httpService.done());
      }
    }

  footerTable() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
        this.httpService.wait();
        this.httpService.get('/custom/financeiro/relatorio-fluxo-caixa-footer',
        [this.dblinkedSessionService.empresa.id,
          this.dblinkedSessionService.periodo.month.value, this.dblinkedSessionService.periodo.year.value, this.exibirPedidos])
        .subscribe(footer => {
          this.footerValores = footer;
          this.toolbarRefresh();
        },
        error => this.httpService.handleError(error, () => this.listarDados()),
        () => this.httpService.done());
    }
  }

  ngOnDestroy() {
    // this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }



  change() {
    if (!this.dblinkedSessionService.hasEmpresaSelected || !this.dblinkedSessionService.hasYearSelected) {
      return;
    }
  }

  exibirOcultaPedidos(event: any) {
    this.exibirPedidos = !event.checked;
    this.listarDados(); this.footerTable();
  }

  selectCheckbox(id: any, event: any) {
    const index = this.idNfesView.findIndex(c => c === id);
    if (event.selected) {
        this.idNfesView.splice(index, 1);
        event.selected = true;
    } else {
        this.idNfesView.push(id);
        event.selected = false;
    }
    if (this.idNfesView.length > 0) {
      this.toolbarService.alterarStatus('print', false, 'Visualizar Danfe', () => this.visualizarDanfe());
      /*this.toolbarService.clear();
      this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar XML');
      this.toolbarService.add('print', false, 'class-print-button', 'print', 'Visualizar Danfe', () => this.visualizarDanfe());
      this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar');
      this.toolbarService.add('add', true, 'class-new-button', 'ESCRITURAR NF', 'Escriturar NF');*/
    } else {
      // this.toolbarRefresh();
      this.toolbarService.alterarStatus('print', true, 'Visualizar Danfe', () => this.visualizarDanfe());
    }
  }
  visualizarDanfe() {
    this.idNfesView.forEach(nfeId => {
      window.open('/danfe/' + nfeId,
                  '_blank');
    });
    this.inicializar();
  }

  private toolbarRefresh() {
    // this.toolbarService.ocultar('check');
    // this.toolbarService.ocultar('info');
    // this.toolbarService.ocultar('save');
    /*
    this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');*/
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  onFilter($event) {
    // console.log($event);
  }

  inicializar() {
    this.fluxoCaixa = new Array<any>();
    this.expandValores = new Array<any>();
    this.footerValores = new Array<any>();
    this.saldoInicial = 0.00;
    this.exibirPedidos =  true;
    this.idNfesView = new Array();
    this.tipo = '';
    this.listarDados(); this.footerTable();
    this.toolbarService.alterarStatus('print', true, 'Visualizar Danfe', () => this.visualizarDanfe());
  }
}
