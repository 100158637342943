import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { UtilService } from 'app/shared/services/util.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Period } from 'app/shared/components/period-select/period.model';
import { Config } from '../../app.config';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { MovParteB } from '../../ecf/model/mov-parte-b.model';
import { MovParteBLcto } from '../../ecf/model/mov-parte-b-lcto.model';
import { MovParteBRelac } from '../../ecf/model/mov-parte-b-relac.model';
import { PlanoContas } from '../../contabil/model/plano-contas.model';
import { PlanoContasService } from 'app/shared/services/plano-contas.service';
import { Empresa } from '../../cadastro/model/empresa.model';
import { ConfigService } from 'app/shared/services/config.service';

@Component({
    selector: 'app-teste',
    templateUrl: './parte-b.component.html',
    styleUrls: ['./parte-b.component.scss'],
})
export class ParteBComponent implements OnInit, OnDestroy {
    private sessionSubscription: Subscription;

    periodo: Period;
    movParteB: MovParteB;
    movParteBLcto: MovParteBLcto;
    movParteBPostList: Array<MovParteB>;
    movParteBList: Array<MovParteB>;
    movParteBMesesList: Array<MovParteBLcto>;
    movParteBRelac: Array<MovParteBRelac>;
    selected: boolean;
    planoContasFull: Array<PlanoContas> = new Array();
    planoContasSelecionados: Array<PlanoContas> = new Array();
    modalLctoManual: boolean;

    opcoes: SelectItem[];
    indicadorLcto: SelectItem[];
    meses: SelectItem[];
    mesSelecionado: number;
    movParteBDemonstrativo: Array<any> = new Array();
    movParteBDemonstrativoTotal: Array<any> = new Array();

    maxDate: Date;

    private planoContasService: PlanoContasService = new PlanoContasService('irpj');

    titlePrincipal: string;
    subtitle: string;

    constructor(private configService: ConfigService,
                private httpService: HttpService,
                private sessionService: SessionService,
                private dblinkedSessionService: DblinkedSessionService,
                private utilService: UtilService,
                private titleService: TitleService) {
        this.titleService.title = 'Parte B (IRPJ)';
    }

    ngOnInit() {
        this.titlePrincipal = 'PARTE B - IRPJ';
        this.subtitle = 'DETALHAMENTO DA PARTE B - VISÃO IRPJ';
        this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

        this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
                                                                             () => { this.periodChanged(); });
    }

  ngOnDestroy() {
      this.dblinkedSessionService.hidePeriods();

      this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  periodChanged() {
        if (!this.dblinkedSessionService.hasEmpresaSelected || !this.dblinkedSessionService.hasYearSelected) {
          return;
        }

        this.movParteBLcto = new MovParteBLcto();

        this.movParteB = new MovParteB();
        this.selected = false;
        this.movParteBRelac = new Array<MovParteBRelac>();
        this.modalLctoManual = false;
        this.movParteBDemonstrativo = new Array<any>();
        this.movParteBDemonstrativoTotal = new Array<any>();

        this.opcoes = [];
        this.opcoes.push({label: 'Selecione', value: null});
        this.opcoes.push({label: 'D', value: 'D'});
        this.opcoes.push({label: 'C', value: 'C'});

        this.indicadorLcto = [];
        this.indicadorLcto.push({label: 'Selecione', value: null});
        this.indicadorLcto.push({label: 'Base de cálculo negativa da CSLL', value: 'B'});
        this.indicadorLcto.push({label: 'Crédito', value: 'C'});
        this.indicadorLcto.push({label: 'Débito', value: 'D'});
        this.indicadorLcto.push({label: 'Prejuízo do Exercício', value: 'P'});

        this.meses = [];
        this.meses.push({label: 'Selecione', value: null});
        this.meses.push({label: 'Janeiro', value: 1});
        this.meses.push({label: 'Fevereiro', value: 2});
        this.meses.push({label: 'Março', value: 3});
        this.meses.push({label: 'Abril', value: 4});
        this.meses.push({label: 'Maio', value: 5});
        this.meses.push({label: 'Junho', value: 6});
        this.meses.push({label: 'Julho', value: 7});
        this.meses.push({label: 'Agosto', value: 8});
        this.meses.push({label: 'Setembro', value: 9});
        this.meses.push({label: 'Outubro', value: 10});
        this.meses.push({label: 'Novembro', value: 11});
        this.meses.push({label: 'Dezembro', value: 12});

    this.movParteB = new MovParteB();
    this.selected = false;
    this.movParteBRelac = new Array<MovParteBRelac>();

    const d = this.utilService.lastDayOfYear([this.year - 1]).toDate();
    this.maxDate = this.utilService.utcDate(d);
    this.movParteB.data = this.maxDate;

    this.httpService.wait();
    this.httpService.get('/movparteb?$&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa
    .id + '&$orderby=data')
            .subscribe(movParteBList => this.movParteBList = movParteBList.value,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());
    }

    salvarConta() {
      this.movParteB.saldoInicial = this.utilService.parseDecimal(this.movParteB.saldoInicial);
      this.movParteB.empresa = new Empresa(this.dblinkedSessionService.empresa.id);
      this.httpService.wait();
      this.httpService.post('/movparteb', this.movParteB)
                      .subscribe(() => this.success(),
                                 error => this.httpService.handleError(error, () => this.salvarConta()),
                                 () => this.httpService.done());
    }

    success() {
        this.periodChanged();


    }

    excluirParteB(movParteB: MovParteB) {
        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletemovparteb', movParteB.id)
                .subscribe(() => this.periodChanged(),
                error => this.httpService.handleError(error, () => this.excluirParteB(movParteB)),
                () => this.httpService.done());
        }
    }

    excluirParteBLcto(movParteB: MovParteBLcto) {
        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletemovparteblcto', movParteB.id)
                            .subscribe(() => this.periodChanged(),
                                       error => this.httpService.handleError(error, () => this.excluirParteBLcto(movParteB)),
                                       () => this.httpService.done());
        }
    }

    editar(event: any) {

        if (event.type === 'row') {
            this.movParteB = event.data;
        } else {
            this.movParteB = event;
        }

        this.selected = true;

        this.httpService.wait();
        this.httpService.get('/custom/ecd/planocontas', [this.dblinkedSessionService.empresa.id, this.year])
                        .subscribe(planoContas => this.planoContasFull = planoContas.value,
                                   error => this.httpService.handleError(error, () => this.editar(event)),
                                   () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/planocontasrelacparteb/' + this.movParteB.cod + '/' + this.year)
                        .subscribe(planoContasSelecionados => this.planoContasSelecionados = planoContasSelecionados,
                                   error => this.httpService.handleError(error, () => this.editar(event)),
                                   () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/movparteblcto?$select=id,descricao,indLcto,valor,data' +
                             '&$filter=movParteB/id eq ' + this.movParteB.id + ' and movParteAIrpj eq null')
                        .subscribe(movParteBMesesList => this.movParteBMesesList = movParteBMesesList.value,
                                   error => this.httpService.handleError(error, () => this.editar(event)),
                                   () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/movpartebdemonstrativo-mensal', [this.movParteB.id, 12, this.year])
                        .subscribe(movParteBDemonstrativo => this.loadDemonstrativo(movParteBDemonstrativo),
                                   error => this.httpService.handleError(error, () => this.editar(event)),
                                   () => this.httpService.done());


    }


    loadDemonstrativo(value: Array<any>) {
        this.movParteBDemonstrativo = value;
    }

    loadDemonstrativoTotal(value: Array<any>) {
        this.movParteBDemonstrativoTotal = value;
    }

    atualizarMapeamento() {
        const listasEnviar = {
            movParteB: this.movParteB,
            listaPlanoContas: this.planoContasSelecionados,
            ano: this.year,
            movListMeses: this.movParteBMesesList
        };

        this.httpService.wait();
        this.httpService.post('/custom/ecd/atualizarmovpartebrelac', listasEnviar)
            .subscribe(() => this.periodChanged(),
            error => this.httpService.handleError(error, () => this.atualizarMapeamento()),
            () => this.httpService.done());
    }

    exibirLctoManual() {
      this.modalLctoManual = true;
    }

    salvarLancamentoManual() {
      const d = this.utilService.lastDayOfMonth([this.year, this.mesSelecionado - 1]).toDate();
      this.movParteBLcto.data = this.utilService.utcDate(d);

      this.movParteBLcto.movParteB = this.movParteB;

      this.movParteBLcto.valor = this.utilService.parseDecimal(this.movParteBLcto.valor);

      this.httpService.wait();
      this.httpService.post('/movparteblcto', this.movParteBLcto)
                      .subscribe(() => this.editar(this.movParteBLcto.movParteB),
                                 error => this.httpService.handleError(error, () => this.salvarLancamentoManual()),
                                 () => this.httpService.done());
    }

    atualizarSaldoUtilizadoParteB(tbRelac: Array<MovParteBLcto>) {
      this.httpService.wait();
      this.httpService.put('/custom/ecd/atualizarrelacparteamovparteb', 1, tbRelac) // TODO: FIX
                      .subscribe(() => this.success(),
                                error => this.httpService.handleError(error, () => this.atualizarSaldoUtilizadoParteB(tbRelac)),
                                () => this.httpService.done());
    }

    check(planoContas: PlanoContas, selected: boolean) {
      this.planoContasService.check(this.planoContasFull, planoContas, selected);
    }

    selecionar() {
      this.planoContasService.add(this.planoContasFull, this.planoContasSelecionados);
    }

    desmarcar() {
      this.planoContasService.remove(this.planoContasFull, this.planoContasSelecionados);
    }

    planoContasDisabled(planoContas: PlanoContas): boolean {
      return this.planoContasService.disabled(this.planoContasSelecionados, planoContas) ||
             (planoContas && planoContas.tbIrpjM300Relac && planoContas.tbIrpjM300Relac.id !== undefined); // TODO: FIX
    }

    rowStyleClass(planoContas: PlanoContas): string {
      return planoContas.indCta === 'S' ? 'bold' : '';
      // TODO: FIX => return this.planoContasService.rowStyleClass(planoContas);
    }

    get title(): string {
      return this.titleService.title;
    }

    onTabChange(event: any) {

       if (event.index === 0 || event.index === 12) {
           this.httpService.wait();
        this.httpService.get('/custom/ecd/movpartebdemonstrativo-mensal', [this.movParteB.id, 12, this.year])
                        .subscribe(movParteBDemonstrativo => this.loadDemonstrativo(movParteBDemonstrativo),
                                   error => this.httpService.handleError(error, () => this.editar(event)),
                                   () => this.httpService.done());
       } else {
           this.httpService.wait();
        this.httpService.get('/custom/ecd/movpartebdemonstrativo-mensal', [this.movParteB.id, event.index, this.year])
                        .subscribe(movParteBDemonstrativo => this.loadDemonstrativo(movParteBDemonstrativo),
                                   error => this.httpService.handleError(error, () => this.editar(event)),
                                   () => this.httpService.done());
       }

    }

    get year(): number {
      return this.dblinkedSessionService.periodo.year.value;
    }

    get rowsPerPage() {
      return this.configService.applicationConfig.rowsPerPage;
    }
    get rows() {
      return this.configService.applicationConfig.rows;
    }
    get pageLinks() {
      return this.configService.applicationConfig.pageLinks;
    }
}
