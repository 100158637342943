import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';

export class MovSaldosContabeis {
  id: number;
  dtIni: Date;
  vlSldIni: number;
  indDcIni: string;
  dtFin: Date;
  vlSldFin: number;
  indDcFin: string;
  cadCcus: CadCcus;
  planoContas: PlanoContas;
  cadEcd: CadEcd;

  constructor(movsaldoscontabeis?: MovSaldosContabeis | number) {
    if (movsaldoscontabeis !== undefined && movsaldoscontabeis !== null) {
      if (typeof movsaldoscontabeis === 'number') {
        this.id = movsaldoscontabeis;
      } else {
        this.id = movsaldoscontabeis.id;
        this.dtIni = movsaldoscontabeis.dtIni;
        this.vlSldIni = movsaldoscontabeis.vlSldIni;
        this.indDcIni = movsaldoscontabeis.indDcIni;
        this.dtFin = movsaldoscontabeis.dtFin;
        this.vlSldFin = movsaldoscontabeis.vlSldFin;
        this.indDcFin = movsaldoscontabeis.indDcFin;
        this.cadCcus = movsaldoscontabeis.cadCcus || new CadCcus();
        this.planoContas = movsaldoscontabeis.planoContas || new PlanoContas();
        this.cadEcd = movsaldoscontabeis.cadEcd;
      }
    }
  }
}
