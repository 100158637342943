import { Injectable } from '@angular/core';

import { Subject } from 'rxjs/Subject';

@Injectable()
export class ScrollService {
  scroll: Subject<any> = new Subject();

  target: any = null;

  top = 0;
  left = 0;
  width = 0;
  height = 0;

  constructor() {
  }

  update(target?: any) {
    if (target) {
      this.target = target;
    }

    if (this.target) {
      this.top = this.target.scrollTop;
      this.left = this.target.scrollLeft;
      this.width = this.target.offsetWidth;
      this.height = this.target.offsetHeight;
    }
  }
}
