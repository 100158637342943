import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { TitleService } from 'app/shared/services/title.service';
import { HttpService } from 'app/shared/services/http.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Subscription } from 'rxjs';
import * as pbi from 'powerbi-client';
import { Message, MessageService } from 'primeng/api';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-bi.component.html',
  styleUrls: ['./dashboard-bi.component.scss'],
})
export class DashboardOrcamentoBIComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  models: any;
  permissions: any;
  viewMode: any;
  config: any;
  @ViewChild('powerBIReports') powerBIReports: ElementRef;

  msgs: Message[] = [];
  relatorioGerado: boolean;
  dtAtualizacao: Date;
  report: pbi.Report;
  mesFiltro: string;
  anoFiltro: string;
  anoCompletoFiltro: string;

  constructor(private titleService: TitleService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    public elementRef: ElementRef,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.titleService.title = "Dashboard - BI";
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.change();
      });
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  change() {

    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

      let mes: string;
      switch (this.dblinkedSessionService.periodo.month.value) {
        default:
          mes = '';
          break;
        case 1:
          mes = 'jan';
          break;
        case 2:
          mes = 'fev';
          break;
        case 3:
          mes = 'mar';
          break;
        case 4:
          mes = 'abr';
          break;
        case 5:
          mes = 'mai';
          break;
        case 6:
          mes = 'jun';
          break;
        case 7:
          mes = 'jul';
          break;
        case 8:
          mes = 'ago';
          break;
        case 9:
          mes = 'set';
          break;
        case 10:
          mes = 'out';
          break;
        case 11:
          mes = 'nov';
          break;
        case 12:
          mes = 'dez';
          break;
      }

      this.mesFiltro = mes;
      this.anoFiltro = this.dblinkedSessionService.periodo.year.value.toString().substring(2, 5);
      this.anoCompletoFiltro = this.dblinkedSessionService.periodo.year.value.toString();

      let reportContainer = <HTMLElement>document.getElementById('pbi-report');
      let reportPrint;
      let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      reportPrint = powerbi.reset(reportContainer);

      this.toolbarEnable();

      this.config = [];

      this.httpService.wait();
      this.httpService.get('/custom/pbi/report-pbi-orcamento', [this.sessionService.loggedUser.id])
        .subscribe(rst => {

          if (rst.error) {
            this.messageService.add({
              severity: 'warn',key: 'messageLinnks',
              summary: '',
              detail: rst.message
            });

          } else {
            this.relatorioGerado = true;
            this.dtAtualizacao = rst.dtAtualizacao;
            this.models = pbi.models;
            this.permissions = this.models.Permissions.All;
            this.viewMode = this.models.ViewMode.View;

            this.config = {
              type: rst.type,
              tokenType: this.models.TokenType.Embed,
              accessToken: rst.accessToken,
              embedUrl: rst.embedUrl,
              id: rst.reportId,
              permissions: this.permissions,
              viewMode: this.viewMode,
              settings: {
                filterPaneEnabled: rst.filterPaneEnabled,
                navContentPaneEnabled: rst.navContentPaneEnabled,
                background: this.models.BackgroundType.Transparent
              }
            };

            let reportContainer = <HTMLElement>document.getElementById("pbi-report");
            let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
            this.report = <pbi.Report>powerbi.embed(reportContainer, this.config);
            if (rst.filtraEmpresa && !rst.filtraPeriodo) {
              let basicFilterEmpresa: pbi.models.IBasicFilter = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: rst.tabelaFiltroEmpresa,
                  column: rst.colunaFiltroEmpresa
                },
                operator: "In",
                values: [this.dblinkedSessionService.empresa.id],
                filterType: 1
              }

              this.report.on("loaded", () => {
                this.report.setFilters([basicFilterEmpresa])
                  .catch(errors => {

                  });
              });

            } else if (!rst.filtraEmpresa && rst.filtraPeriodo) {
              let basicFilterPeriodo: pbi.models.IBasicFilter = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: rst.tabelaFiltroPeriodo,
                  column: rst.colunaFiltroPeriodo
                },
                operator: "In",
                values: [rst.colunaFiltroPeriodo === 'MesAno' || rst.colunaFiltroPeriodo === 'mes_ano' ? this.mesFiltro + "-" + this.anoFiltro : this.dblinkedSessionService.periodo.year.value],
                filterType: 1
              }

              this.report.on("loaded", () => {
                this.report.setFilters([basicFilterPeriodo])
                  .catch(errors => {
                  });
              });

            } else if (rst.filtraEmpresa && rst.filtraPeriodo) {
              let basicFilterEmpresa: pbi.models.IBasicFilter = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: rst.tabelaFiltroEmpresa,
                  column: rst.colunaFiltroEmpresa
                },
                operator: "In",
                values: [this.dblinkedSessionService.empresa.id],
                filterType: 1
              }

              let basicFilterPeriodo: pbi.models.IBasicFilter = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: rst.tabelaFiltroPeriodo,
                  column: rst.colunaFiltroPeriodo
                },
                operator: "In",
                values: [rst.colunaFiltroPeriodo === 'MesAno' || rst.colunaFiltroPeriodo === 'mes_ano' ? this.mesFiltro + "-" + this.anoFiltro : this.dblinkedSessionService.periodo.year.value],
                filterType: 1
              }

              this.report.on("loaded", () => {
                this.report.setFilters([basicFilterEmpresa, basicFilterPeriodo])
                  .catch(errors => {

                  });
              });
            }
            if (this.relatorioGerado) {
              this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.change());
              this.toolbarService.alterarStatus('atualizar', false, 'Atualizar', () => { this.atualizarRelatorio(); });
              this.toolbarService.alterarStatus('print', false, 'Imprimir', () => this.print());
            }
          }
        },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
    }
  }

  hideMessage() {

  }

  print() {
    let reportContainer = <HTMLElement>document.getElementById('pbi-report');
    let reportPrint;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    reportPrint = powerbi.get(reportContainer);

    reportPrint.print().catch(error => {
      console.log(error);
    });
  }

  atualizarRelatorio() {
    let reportContainer = <HTMLElement>document.getElementById('pbi-report');
    let reportRefresh;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    reportRefresh = powerbi.get(reportContainer);

    reportRefresh.refresh().catch(error => {
      console.log(error);
    });

    reportRefresh.reload().catch(error => {
      console.log(error);
    });
  }

  private toolbarEnable() {
    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.alterarStatus('voltar', true, 'Voltar', () => this.atualizarRelatorio());
    this.toolbarService.replace('atualizar', true, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.atualizarRelatorio());
    this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir');
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');
    this.toolbarService.replace('add', true, 'class-new-button', 'ATUALIZAR',
      'Criar novo relatório', () => this.atualizarRelatorio());
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }
}
