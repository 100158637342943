import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MessageService, SelectItem } from '../../../../node_modules/primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-config-origem',
  templateUrl: './origem.component.html',
  styleUrls: ['./origem.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ConfigOrigemComponent implements OnInit, OnDestroy {
  ////////////////////////////////////////
  // REFATOR SIMON LALLEMENT 28/12/2021 //
  ////////////////////////////////////////

  private sessionSubscription: Subscription

  //Toolbar
  toolbarMainIni = [
    { icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: false, disabled: false, color: 'red', onClick: () => this.excluirOrigem() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openEditOrigem() },
    { icon: 'add', text: 'Nova', tooltip: 'Nova', visible: true, disabled: false, color: 'green', onClick: () => this.openAddOrigem() },
  ]
  public toolbarMain = new Toolbar()

  //Array
  origemRequisicao: Array<any> = new Array()
  origSelected: Array<number> = new Array()

  //String
  titlePrincipal: string = 'Configurações RMS'
  subtitle: string = 'Origem'
  currentStateOrigem: string = 'initial'
  descricaoOrigem: string = null

  //Boolean
  isCardOrigem: boolean = true
  checkedAll: boolean = false
  modalOrigem: boolean = false
  add: boolean = false
  edit: boolean = false

  //Miscellaneous
  origemSelecionada: any = {}

  //Number
  relacaoId: number = null

  selectedRows: any = []

  //Dropdown
  listRelacaoOrigem: SelectItem[] = []

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.titleService.title = 'Linnks - Origem RMS'
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.origemRequisicaoItens()
        this.buscarRelacoes()
      }
    })
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
  }

  openAddOrigem() {
    this.modalOrigem = true
    this.descricaoOrigem = null
    this.relacaoId = null
    this.add = true
  }

  buscarRelacoes() {
    this.httpService.wait()
    this.httpService.get(`origemrmspedido?$select=id,descricao&$orderby=descricao`)
      .subscribe(ref => {
        this.listRelacaoOrigem = []
        ref.value.forEach(element => { this.listRelacaoOrigem.push({ label: element.descricao, value: element.id }) })
        this.httpService.done()

      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao obter as relações de origem, contate o suporte : " + error.message })
          this.httpService.done()
        })
  }

  openEditOrigem() {
    this.modalOrigem = true
    this.origemSelecionada = this.origemRequisicao.find(element => this.selectedRows[0].id === element.id)
    this.descricaoOrigem = this.origemSelecionada.nome
    this.relacaoId = this.origemSelecionada.origem_relac_id
    this.edit = true
  }

  excluirOrigem() {
    if (confirm("Deseja remover as origens selecionadas?")) {
      let listaEnviar = {
        id: this.selectedRows.map(item => item.id)
      }

      this.httpService.wait();
      this.httpService.post('/custom/rms/deletar-origem', listaEnviar)
        .subscribe(rst => {
          this.httpService.done();

          if(rst?.error) {
            return this.exibirMensagem(rst);
          }

          this.origemRequisicaoItens();
          this.checkToolBar();
          this.resetSelected()
        }, error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao excluir as origens, contate o suporte : " + error.message })
          this.httpService.done();
          this.resetSelected()
        })
    }
  }

  changeStateOrigem() {
    this.currentStateOrigem = this.currentStateOrigem === 'initial' ? 'final' : 'initial'
  }

  showHideOrigem() {
    this.isCardOrigem = !this.isCardOrigem
  }

  adicionar() {
    if (this.validacao()) return

    let listaEnviar = {
      nomeTipo: this.descricaoOrigem,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id,
      tipo_relac: this.relacaoId
    }

    this.httpService.wait();
    this.httpService.post('/custom/rms/adicionar-origem', listaEnviar).subscribe(rst => {
      if(rst.error){this.exibirMensagem({ severity: "error", message: rst.detail })}
      else{
        this.exibirMensagem({ severity: "success", message: rst.detail })
        this.origemRequisicaoItens()
        this.modalOrigem = false
        this.add = false
      }
    },
    error => {
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar a origem, contate o suporte : " + error.message })
      this.resetSelected()
    },
    () => this.httpService.done())
  }

  validacao(): boolean {
    if (!this.descricaoOrigem) {
      this.exibirMensagem({ severity: 'warn', mensagem: "O campo Descrição é obrigatório." })
      return true
    }

    if (!this.relacaoId) {
      this.exibirMensagem({ severity: 'warn', mensagem: "O campo Relação é obrigatório." })
      return true
    }

    let lista = this.origemRequisicao.map(item => { return this.stringComparavel(item.nome) })

    if((this.edit ? this.stringComparavel(this.origemSelecionada.nome) !== this.stringComparavel(this.descricaoOrigem): true) && lista.includes(this.stringComparavel(this.descricaoOrigem))) {
      this.exibirMensagem({ severity: 'warn', mensagem: "A descrição deve ser única." })
      return true
    }

    return false
  }

  stringComparavel(string: string): string {
    return string.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z._ ])/g, '').toLowerCase().trim()
  }

  origemRequisicaoItens() {
    this.httpService.wait()
    this.httpService.get('/custom/rms/listar-origem', this.dblinkedSessionService.empresa.id)
      .subscribe(rst => {
        this.origemRequisicao = new Array()
        rst.forEach(element => { this.origemRequisicao.push(element) })
        this.httpService.done()
        this.resetSelected()
      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao obter as origens, contate o suporte : " + error.message })
          this.httpService.done()
          this.resetSelected()
        })
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  atualizar() {
    if (this.validacao()) return

    let listaEnviar = {
      id: this.origemSelecionada.id,
      nome: this.descricaoOrigem,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id,
      origem_relac_id: this.relacaoId
    }

    this.httpService.wait()
    this.httpService.post('/custom/rms/atualizar-origem', listaEnviar).subscribe(rst => {
      if(rst.error){this.exibirMensagem({ severity: "error", message: rst.detail })}
      else{
        this.exibirMensagem({ severity: "success", message: rst.detail })
        this.origemRequisicaoItens()
        this.checkToolBar()
        this.modalOrigem = false
        this.edit = false
      }
    }, error => {
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar a origem, contate o suporte : " + error.message })
      this.resetSelected()
    },
    () => this.httpService.done())
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  resetSelected() {
    this.selectedRows = [];
    this.checkToolBar();
  }

  checkToolBar() {
    this.toolbarMain.setVisible('btn1', this.selectedRows.length !== 0);
    this.toolbarMain.setVisible('btn2', this.selectedRows.length === 1);
  }

}
