import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { isNullOrUndefined } from 'util';
import { Empresa } from '../../cadastro/model/empresa.model';
import { TipoOrcamento } from 'app/orcamento/model/tipo-orcamento.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
declare var $: any;


@Component({
  selector: 'app-tipo-orcamento',
  templateUrl: './tipo-orcamento.component.html',
  styleUrls: ['./tipo-orcamento.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class TipoOrcamentoComponent implements OnInit, OnDestroy {
  currentStateTipoOrca = 'initial';
  changeStateTipoOrca() {
    this.currentStateTipoOrca = this.currentStateTipoOrca === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  pt: any;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  boolCriarTipoOrcamento: boolean = false;
  boolEditTipoOrcamento: boolean = false;
  checkedAll: boolean = false;
  listaIdsSelecionados: Array<any> = [];
  listaTipoOrcamento: Array<any> = [];
  tipoOrcamento: TipoOrcamento;

  //export excel
  colsListaTipoOrcamento: any[];

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.tipoOrcamento = new TipoOrcamento();
    this.listaIdsSelecionados = new Array<any>();
    this.boolCriarTipoOrcamento = false;
    this.boolEditTipoOrcamento = false;
    this.checkedAll = false;


    this.titlePrincipal = 'Configurações Orçamento';
    // this.titleService.title = 'Linnks - Us Gaap';
    this.subtitle = 'Tipo Orçamento';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();

    });

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.colsListaTipoOrcamento = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'ativo', header: 'ATIVO' }
    ];

  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

      this.tipoOrcamento = new TipoOrcamento();
      this.listaIdsSelecionados = new Array<any>();
      this.boolCriarTipoOrcamento = false;
      this.boolEditTipoOrcamento = false;
      this.checkedAll = false;

      this.carregarTipoOrcamentos();
    }

  }


  carregarTipoOrcamentos() {
    this.httpService.wait();
    this.httpService.get('/custom/orcamento/listar-tipo-orcamento')
      .subscribe(ref => {
        this.listaTipoOrcamento = [];
        ref.forEach(element => {
          this.listaTipoOrcamento.push(element);
        });

      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());

  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  criarNovoRegistro() {

    if (this.validacao()) {
      return;
    }

    this.boolCriarTipoOrcamento = false;
    this.boolEditTipoOrcamento = false;

    let listaEnviar = {
      codigo: this.tipoOrcamento.codigo,
      descricao: this.tipoOrcamento.descricao,
      ativo: this.tipoOrcamento.ativo,
      criarNovoTipoVersao: true

    }

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/salvar-tipo-orcamento', listaEnviar)
      .subscribe(rst => {
        if (rst.error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst.detail
          });

        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst.detail
          });
          this.change();
        }
      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }

  selectCheckbox(id: any, event: any) {

    if (event.selected) {
      const index = this.listaTipoOrcamento.findIndex(i => i === id);
      this.listaIdsSelecionados.splice(index, 1);

    } else {
      this.listaIdsSelecionados.push(id);

    }
  }

  selectTodos(event: any) {

    this.checkedAll = !this.checkedAll;

    this.listaTipoOrcamento.forEach(e => {
      if (this.checkedAll) {
        const i = this.listaTipoOrcamento.findIndex(p => p === e);
        this.listaTipoOrcamento[i].selected = true;
        this.listaIdsSelecionados.push(this.listaTipoOrcamento[i].id);

      } else {
        this.listaTipoOrcamento.forEach(e => {
          e.selected = false;
          const i = this.listaTipoOrcamento.findIndex(p => p === e);
          this.listaIdsSelecionados.splice(i, 1);

        });

      }
    })
  }

  excluirRegistro() {
    if (confirm('Deseja excluir o(s) registro(s) selecionado(s)?')) {

      let listaEnviar = {
        versaoId: this.listaIdsSelecionados
      }

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/excluir-tipo-orcamento', listaEnviar)
        .subscribe(rst => {

          if (rst.error === true) {
            this.messageService.add({
              severity: 'error', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.detail
            });

          } else {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.detail
            });
            // this.carregarOrcamentoVersoes();
          }
          this.change();

        },
          error => this.httpService.handleError(error, () => this.excluirRegistro()),
          () => this.httpService.done());
    }

  }


  carregarRegistro() {

    this.boolCriarTipoOrcamento = false;
    this.boolEditTipoOrcamento = true;

    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirRegistro());
    this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.carregarInformacaoRegistro(this.listaIdsSelecionados[0]));
    this.toolbarService.alterarStatus('add', true, 'NOVO', () => this.showModalNovoTipoOrcamento());
  }

  carregarInformacaoRegistro(value: any) {
    this.boolCriarTipoOrcamento = false;
    this.boolEditTipoOrcamento = true;
    this.tipoOrcamento = new TipoOrcamento();

    let i = this.listaTipoOrcamento.findIndex(e => e.id === value);

    this.tipoOrcamento.id = value.id;
    if (!isNullOrUndefined(this.listaTipoOrcamento[i].codigo)) {
      this.tipoOrcamento.codigo = this.listaTipoOrcamento[i].codigo;
    }

    if (!isNullOrUndefined(this.listaTipoOrcamento[i].descricao)) {
      this.tipoOrcamento.descricao = this.listaTipoOrcamento[i].descricao;
    }

    if (!isNullOrUndefined(this.listaTipoOrcamento[i].ativo)) {
      this.tipoOrcamento.ativo = this.listaTipoOrcamento[i].ativo;
    }



    this.toolbarService.alterarStatus('voltar', false, 'voltar', () => this.ngOnInit());
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Editar Registro', () => this.editarRegistro());


  }


  editarRegistro() {

    if (this.validacao()) {
      return;
    }

    this.boolCriarTipoOrcamento = false;
    this.boolEditTipoOrcamento = false;

    let listaEnviar = {
      tipoOrcamentoId: this.listaIdsSelecionados[0],
      codigo: this.tipoOrcamento.codigo,
      descricao: this.tipoOrcamento.descricao,
      ativo: this.tipoOrcamento.ativo,
      criarNovoTipoVersao: false

    }

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/salvar-tipo-orcamento', listaEnviar)
      .subscribe(rst => {

        if (rst.error === true) {
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst.detail
          });

        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst.detail
          });
          this.change();
        }
      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());








  }


  cancelar() {
    this.boolCriarTipoOrcamento = false;
    this.boolEditTipoOrcamento = false;
    this.change();
  }

  validacao() {


    if (isNullOrUndefined(this.tipoOrcamento.descricao) || this.tipoOrcamento.descricao === "") {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar uma descrição!'
      });
      return true;
    }

  }
  showModalNovoTipoOrcamento() {
    this.boolCriarTipoOrcamento = true;
    this.tipoOrcamento = new TipoOrcamento();
    this.tipoOrcamento.ativo = true;
  }

  /*Show Hide TipoOrc*/
  isCardTipoOrca: boolean = true;
  ShowHideTipoOrca() {
    this.isCardTipoOrca = !this.isCardTipoOrca;
  }

  closeDialog() {
    this.boolCriarTipoOrcamento = false;
    this.boolEditTipoOrcamento = false;
    this.change();
  }

}