import { TipoBaixa } from "app/util/model/tipo-baixa.model";
import { FormaPagamento } from "app/util/model/forma-pagamento.model";

export class EventosFichaPagamento {
  id: number;
  eventoFichaId: number;
  valor: any;
  tipoPagto: number;
  parcela: number;
  numeroCartao: String;
  dtValidade: Date;
  ccv: String;
  empresaContaId: number;
  dtPrimeiroVencimento: Date;
  valorParcela: String;
  depositoId: number;
  numCheque: string;
  descTipoPagto: string;
  formaPagamento: FormaPagamento;
  autorizacao       : string;
  numeroDocumento   : string;
  numeroTerminal    : string;
  numeroEstabelecimento : string;
  tipoBaixaId       : number;

  constructor(eventosFichaPagamento?: EventosFichaPagamento | number) {
    if (eventosFichaPagamento !== undefined && eventosFichaPagamento !== null) {
      if (typeof eventosFichaPagamento === 'number') {
        this.id = eventosFichaPagamento;
      } else {
        this.id = eventosFichaPagamento.id;
        this.eventoFichaId = eventosFichaPagamento.eventoFichaId;
        this.valor = eventosFichaPagamento.valor;
        this.numeroCartao = eventosFichaPagamento.numeroCartao;
        this.dtValidade = eventosFichaPagamento.dtValidade;
        this.ccv = eventosFichaPagamento.ccv;
        this.parcela = eventosFichaPagamento.parcela;
        this.eventoFichaId = eventosFichaPagamento.eventoFichaId;
        this.dtPrimeiroVencimento = eventosFichaPagamento.dtPrimeiroVencimento;
        this.valorParcela = eventosFichaPagamento.valorParcela;
        this.numCheque = eventosFichaPagamento.numCheque;
        this.descTipoPagto   = eventosFichaPagamento.descTipoPagto;
        this.formaPagamento  = eventosFichaPagamento.formaPagamento;
        this.autorizacao     = eventosFichaPagamento.autorizacao;
        this.numeroDocumento = eventosFichaPagamento.numeroDocumento;
        this.numeroTerminal  = eventosFichaPagamento.numeroTerminal;
        this.numeroEstabelecimento = eventosFichaPagamento.numeroEstabelecimento;
        this.tipoBaixaId           = eventosFichaPagamento.tipoBaixaId;


      }
    }
  }

}
