// import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
// import { TitleService } from 'app/shared/services/title.service';
// import { BaseChartDirective } from 'ng2-charts';
// import PerfectScrollbar from 'perfect-scrollbar';
// import { ToolbarService } from '../../shared/services/toolbar.service';
// import { SelectItem } from '../../shared/common/select-item.interface';
// import { HttpService } from '../../shared/services/http.service';
// import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
// import { Subscription } from 'rxjs/Subscription';
// import { SessionService } from '../../shared/services/session.service';
// import { UtilService } from '../../shared/services/util.service';
// import { ConfigService } from '../../shared/services/config.service';


// @Component({
//   selector: 'app-dashboard',
//   templateUrl: './dashboard.component.html',
//   styleUrls: ['./dashboard.component.scss'],
// })

// export class DashboardFinanceiroComponent implements OnInit, OnDestroy {
//   isRTL: boolean;
//   opcoesContas: SelectItem[];
//   contaId: number;
//   valoresGrafico: any[] = new Array();
//   valoresGraficoDia: any[] = new Array();
//   valoresEntradaDia: any[] = new Array();
//   valoresSaidaDia: any[] = new Array();
//   valorPgtos: any;
//   valorRcbtos: any;
//   totalTitVencidoPgto: any;
//   totalTitVencidoRcto: any;
//   relTitulos: Array<any> = new Array();
//   vencidos: Array<any> = new Array();
//   modalRecebimentos: boolean;
//   modalPagamentos: boolean;
//   modalVencidos: boolean;
//   tituloModal: String;
//   defaultDate: Date;
//   saldoCC: any;
//   saldoInvest: any;
//   saldoCaixinha: any;

//   dataIniSearch: any;
//   dataFinSearch: any;

//   private sessionSubscription: Subscription;
//   constructor(
//     private titleService: TitleService,
//     private httpService: HttpService,
//     private configService: ConfigService,
//     private dblinkedSessionService: DblinkedSessionService,
//     private sessionService: SessionService,
//     private utilService: UtilService,
//     private toolbarService: ToolbarService) {
//     this.isRTL = true; // this.isLstRTL;
//   }

//   public chartData: Array<any>  = [{
//     label: 'Saldo Mensal',
//     data: [93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8],
//     borderWidth: 1
//   }];
//   public chartLabels: Array<any> = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto',
//                             'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

//   public chartOptions: any = {
//     legend: {
//       // position: 'bottom',
//       labels: {
//           fontColor: '#9B9B9B',
//           fontFamily: 'font-family: "Lato", sans-serif !important;'
//       }
//     },
//     tooltips: {
//       callbacks: {
//           label: function(tooltipItem, data) {
//               let label = data.datasets[tooltipItem.datasetIndex].label || '';
//               if (label) {
//                   label += ': ';
//               }
//               label += ((Math.round(tooltipItem.yLabel * 100)) / 100).toLocaleString('pt-BR') + '';
//               return label;
//           }
//       }
//     },
//     scales: {
//       xAxes: [{
//         gridLines: {
//           display: false
//         },
//         ticks: {
//           fontColor: '#9B9B9B'
//         }
//       }],
//       yAxes: [{
//         gridLines: {
//           display: false
//         },
//         ticks: {
//           fontColor: '#9B9B9B'
//           // stepSize: 50000
//         }
//       }]
//     },
//     responsive: false,
//     maintainAspectRatio: false
//   };

//   public chartColors: Array<any> = [{
//     backgroundColor: 'rgba(28,180,255,.05)',
//     borderColor: '#0064B7' // 'rgba(28,180,255,1)'
//   }, {
//     backgroundColor: 'rgba(136, 151, 170, 0.1)',
//     borderColor: '#8897aa'
//   }];

//   public chartDataDay: Array<any>  = [{
//     label: 'Entradas',
//     data: [93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 10, 45, 29, 87, 58, 29, 31],
//     borderWidth: 1
//   }, {
//     label: 'Saídas',
//     data: [93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 10, 45, 29, 87, 58, 29, 31],
//     borderWidth: 1
//   }, {
//     label: 'Saldo Diário',
//     data: [93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 93, -25, 95, 59, 46, 68, -4, 41, 10, -5, 11, -8, 10, 45, 29, 87, 58, 29, 31],
//     borderWidth: 1
//   }];

//   public chartLabelsDay: Array<any> = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16',
//                                       '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30' , '31'];

//   public chartColorsDay: Array<any> = [{
//     backgroundColor: 'rgba(2,26,212,0.00)',
//     borderColor: 'rgba(2,26,212, 1)'
//   }, {
//     backgroundColor: 'rgba(235,19,19,0.00)',
//     borderColor: 'rgba(235,19,18,1)'
//   }, {
//     backgroundColor: 'rgba(2,188,118,.09)',
//     borderColor: 'rgba(2,188,118,1)'
//   }];

//   @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
//   ngOnInit(): void {
//     this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
//     Promise.resolve(null).then(() => this.toolbarService.hidden = true);
//     this.titleService.title = 'Dashboard Financeiro';

//     setTimeout(() => {
//       const resizeCharts = () => this.charts.forEach(chart => chart.chart.resize());
//       // Initial resize
//       resizeCharts();
//       // For performance reasons resize charts on delayed resize event
//       // this.layoutService.on('resize.DashboardFinanceiroComponent', resizeCharts);
//     });
//     this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
//       () => { this.inicializar(); });

//   }

//   inicializar() {
//     this.defaultDate = new Date();
//     this.dataIniSearch = null;
//     this.dataFinSearch = null;
//     if (this.dblinkedSessionService.hasEmpresaSelected &&
//       this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
//       && this.dblinkedSessionService.periodo.year) {
//         let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
//         // formatando meses com dois digitos
//         let mes = this.defaultDate.getMonth().toString().length === 2 ?
//                     (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
//         this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
//         this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();
//         if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
//           mes = this.dblinkedSessionService.periodo.month.value > 8 ?
//             this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
//           const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
//           this.defaultDate = new Date(dt);
//           // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
//           lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
//           this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
//           this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();
//         }

//         this.chartData  = [{
//           label: 'Saldo Mensal',
//           data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
//           borderWidth: 1
//         }];
//         this.chartDataDay = [{
//           label: 'Entradas',
//           data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
//           borderWidth: 1
//         }, {
//           label: 'Saídas',
//           data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
//           borderWidth: 1
//         }, {
//           label: 'Saldo Diário',
//           data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
//           borderWidth: 1
//         }];

//         this.contaId = 0;
//         this.valorPgtos = 0.00;
//         this.valorRcbtos = 0.00;
//         this.totalTitVencidoRcto = 0.00;
//         this.totalTitVencidoPgto = 0.00;
//         this.modalRecebimentos = false;
//         this.modalPagamentos = false;
//         this.modalVencidos = false;
//         // Contas da empesa
//         this.comboContas();
//         // grafico evolução saldo
//         const ano = this.dblinkedSessionService.periodo.year.value.toString();
//         this.httpService.wait();
//         this.httpService.get('/custom/financeiro/grafico-evolucao-saldo(' + this.dblinkedSessionService.empresa.id + ',' +
//                               ano + ',' + this.contaId + ')').subscribe(saldo => this.loadGrafico(saldo),
//           error => this.httpService.handleError(error, () => this.inicializar()), () => this.httpService.done());
//         // formatando datas
//         const dt = new Date();
//         const date = dt.getFullYear() + '-' + (((dt.getMonth() + 1) < 10) ? '0' : '') + (dt.getMonth() + 1) + '-' +
//                     ((dt.getDate() < 10) ? '0' : '') + dt.getDate();
//         // proximos venctos/pgtos
//         this.httpService.wait();
//         this.httpService.get('/custom/financeiro/cr-cp-empresa-periodo-dashboard(' + this.dblinkedSessionService.empresa.id + ',' +
//                               date + ',cp)').subscribe(saldo => this.loadPgtos(saldo),
//           error => this.httpService.handleError(error, () => this.inicializar()),
//           () => this.httpService.done());
//           // proximos venctos/pgtos
//           this.httpService.wait();
//           this.httpService.get('/custom/financeiro/cr-cp-empresa-periodo-dashboard(' + this.dblinkedSessionService.empresa.id + ',' +
//                               date + ',cr)').subscribe(saldo => this.loadRectos(saldo),
//           error => this.httpService.handleError(error, () => this.inicializar()),
//           () => this.httpService.done());

//         // calculando total de vencidos
//         this.loadVencidos();
//         // saldos contas
//         this.saldoCaixaMoeda();
//         this.saldoContaCorrente();
//         this.saldoContaInvestimento();

//         this.loadGraficoDia();
//     }
//   }

//   loadGraficoDia() {
//     let dt  = '';
//     if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
//       const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
//                 this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
//       dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
//     } else {
//       dt = this.dblinkedSessionService.periodo.year.value + '-' + (this.utilService.currentMonth(this.defaultDate) + 1) + '-01 12:00:00';
//     }
//     const date = new Date(dt);
//     const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//     const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

//     this.valoresGraficoDia = new Array();
//     this.valoresEntradaDia = new Array();
//     this.valoresSaidaDia  = new Array();

//     let valorDia = 0.00;
//     let valorEnt = 0.00;
//     let valorSai = 0.00;
//     let valor = 0.00;
//     let data;
//     let dataCtrl = firstDay.getDate();
//     console.log('dataCtrl: ' + dataCtrl);
//     console.log('lastDay: ' + lastDay.getDate());

//     this.httpService.wait();
//     /*this.httpService.get('/custom/financeiro/movimentacao-extrato-periodo',
//       [this.dblinkedSessionService.empresa.id,
//       this.dblinkedSessionService.periodo.month.value, this.dblinkedSessionService.periodo.year.value,
//       this.contaId])*/
//       this.httpService.get('/custom/financeiro/movimentacao-extrato-periodo',
//         [this.dblinkedSessionService.empresa.id, this.dataIniSearch, this.dataFinSearch, this.contaId,
//           this.dblinkedSessionService.periodo.month.value, this.dblinkedSessionService.periodo.year.value])
//       .subscribe(retorno => {

//         retorno.forEach(element => {

//           if (element.dt_vcto !== undefined) {
//             // set data do movimentação
//             data = element.dt_vcto.split('/');
//             data = +data[0];
//             // verificando se for mesmo dia
//             if (dataCtrl === data) {
//                 valorDia = this.utilService.parseDecimal(element.saldoAtual);
//                 if (element.tipo === 'cr' || element.tipo === 'C') {
//                   valor = this.utilService.parseDecimal((element.valor));
//                   valorEnt = valorEnt + valor;
//                 } else {
//                   valor = this.utilService.parseDecimal((element.valor));
//                   valorSai = valorSai + valor;
//                 }
//             } else { // troca de dia
//               // setando o valor do dia anterior
//               this.valoresGraficoDia.push(valorDia); // .toFixed(2)
//               this.valoresEntradaDia.push(valorEnt);
//               this.valoresSaidaDia.push(valorSai);

//               // data do movimento
//               data = element.dt_vcto.split('/');
//               data = +data[0];

//               // dia de controle incremento
//               dataCtrl++;

//               // verificando se algum dia não tem movimento
//               if (data > dataCtrl ) {
//                 for (let i = dataCtrl; i < data; i++) {
//                   this.valoresGraficoDia.push(valorDia); // .toFixed(2)
//                   this.valoresEntradaDia.push(0.00);
//                   this.valoresSaidaDia.push(0.00);
//                   dataCtrl++;
//                 }
//               }

//               // setando novo saldo
//               valorDia = this.utilService.parseDecimal(element.saldoAtual);
//               valor = 0.00;
//               valorEnt = 0.00;
//               valorSai = 0.00;
//               if (element.tipo === 'cr' || element.tipo === 'C') {
//                 valor = this.utilService.parseDecimal((element.valor));
//                 valorEnt = valorEnt + valor;
//               } else {
//                 valor = this.utilService.parseDecimal((element.valor));
//                 valorSai = valorSai + valor;
//               }
//             }
//           } else { // fim da verificação data e pega o saldo inicial
//             valorDia = this.utilService.parseDecimal(element.saldoInicial);
//           }

//         });
//         // ultimo dia do mes
//         this.valoresGraficoDia.push(valorDia);
//         this.valoresEntradaDia.push(valorEnt);
//         this.valoresSaidaDia.push(valorSai);

//         // completando 31 dias
//         for (let i = dataCtrl; i < lastDay.getDate(); i++) {
//           this.valoresGraficoDia.push(valorDia);
//           this.valoresEntradaDia.push(0.00);
//           this.valoresSaidaDia.push(0.00);
//         }
//         this.chartDataDay = [{
//           label: 'Entradas',
//           data: this.valoresEntradaDia,
//           borderWidth: 1
//         }, {
//           label: 'Saídas',
//           data: this.valoresSaidaDia,
//           borderWidth: 1
//         }, {
//           label: 'Saldo Diário',
//           data:  this.valoresGraficoDia,
//           borderWidth: 1
//         }];

//       }, error => this.httpService.handleError(error, () => this.loadGraficoDia()),
//       () => this.httpService.done());

//   }

//   alternarContas() {
//     const ano = this.dblinkedSessionService.periodo.year.value.toString() ;
//     this.httpService.wait();
//     this.httpService.get('/custom/financeiro/grafico-evolucao-saldo(' + this.dblinkedSessionService.empresa.id + ',' +
//                           ano + ',' + this.contaId + ')')
//       .subscribe(saldo => {this.loadGrafico(saldo); this.loadGraficoDia(); },
//             error => this.httpService.handleError(error, () => this.inicializar()),
//             () => this.httpService.done());

//   }

//   comboContas() {
//     this.opcoesContas = [];
//     this.httpService.wait();
//     this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,tipoConta/id&$'
//     + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
//     '&$orderby=nome')
//       .subscribe(ref => {
//         ref.value.forEach(element => {
//           if (element.tipoConta.id !== 10) {
//             this.opcoesContas.push({ label: element.nome, value: element.id });
//           }
//         });
//       },
//         error => this.httpService.handleError(error, () => this.comboContas()),
//         () => this.httpService.done());
//   }

//   loadGrafico(value: Array<any>) {
//     this.valoresGrafico = new Array();
//     // console.log('itens: ' + JSON.stringify(value, null, ''));
//     value.forEach(element => {
//       this.valoresGrafico.push(this.utilService.parseDecimal(element.valor));
//     });
//     this.chartData = [{
//       label: 'Saldo Mensal',
//       data: this.valoresGrafico,
//       borderWidth: 1
//     }];
//   }

//   loadPgtos(value: Array<any>) {
//     this.valorPgtos = 0.00;
//     value.forEach(element => {
//       if (element.status !== 'BAIXADO') {
//         this.valorPgtos = this.valorPgtos + this.utilService.parseMoney((element.valor));
//       }
//     });
//    }

//    loadRectos(value: Array<any>) {
//      this.valorRcbtos = 0.00;
//      value.forEach(element => {
//       if (element.status !== 'BAIXADO') {
//         this.valorRcbtos = this.valorRcbtos + this.utilService.parseMoney((element.valor));
//       }
//     });
//   }

//    loadVencidos() {
//     this.totalTitVencidoRcto = 0.00;
//     this.totalTitVencidoPgto = 0.00;
//     if (this.dblinkedSessionService.hasEmpresaSelected) {
//       // vencidos a pagar
//       this.httpService.wait();
//       this.httpService.get('/custom/financeiro/cr-cp-empresa-vencidos',
//       [this.dblinkedSessionService.empresa.id, 'cp'])
//         .subscribe(ref => {

//           ref.forEach(element => {
//             if (element.status === 'VENCIDO') {
//               const valor = this.utilService.parseMoney((element.valor));
//               this.totalTitVencidoPgto = this.totalTitVencidoPgto + valor;
//             }
//           });
//         },
//           error => this.httpService.handleError(error, () => this.loadVencidos()),
//           () => this.httpService.done());

//       // vencidos a receber
//       this.httpService.wait();
//       this.httpService.get('/custom/financeiro/cr-cp-empresa-vencidos',
//       [this.dblinkedSessionService.empresa.id, 'cr'])
//         .subscribe(ref => {
//           ref.forEach(element => {
//             if (element.status === 'VENCIDO') {
//               const valor = this.utilService.parseMoney((element.valor));
//               this.totalTitVencidoRcto = this.totalTitVencidoRcto + valor;
//             }
//           });
//         },
//           error => this.httpService.handleError(error, () => this.loadVencidos()),
//           () => this.httpService.done());
//     }
//   } //

//   detalharTitulos(tipo: String = null) {
//     this.modalPagamentos = (tipo === 'cp') ? true : false;
//     this.modalRecebimentos = (tipo === 'cr') ? true : false;
//     this.tituloModal = (tipo === 'cr') ? 'Títulos a Receber no Período' : 'Títulos a Pagar no Período';

//     this.relTitulos = new Array();
//     this.httpService.wait();
//     this.httpService.get('/custom/financeiro/cr-cp-empresa-periodo',
//     /*[this.dblinkedSessionService.empresa.id,
//     this.dblinkedSessionService.periodo.month.value, this.dblinkedSessionService.periodo.year.value, tipo])*/
//     [this.dblinkedSessionService.empresa.id, this.dataIniSearch, this.dataFinSearch, tipo])
//     .subscribe( ref => {
//       // this.relTitulos = this.relTitulos.filter(item => item.status !== 'BAIXADO');
//       this.relTitulos = ref.filter(item => item.status !== 'BAIXADO');
//     }, error => this.httpService.handleError(error, () => this.detalharTitulos(tipo)),
//     () => this.httpService.done());
//   }

//   detalharTitulosVencidos(parametro: String = null) {
//     this.modalVencidos = true;
//     this.tituloModal = (parametro === 'cr') ? 'Títulos Vencidos à Receber' : 'Títulos Vencidos à Pagar';
//     this.httpService.wait();
//     this.httpService.get('/custom/financeiro/cr-cp-empresa-vencidos',
//     [this.dblinkedSessionService.empresa.id, parametro])
//       .subscribe(ref => {
//         this.vencidos = ref;
//       }, error => this.httpService.handleError(error, () => this.detalharTitulosVencidos(parametro)),
//         () => this.httpService.done());
//   }

//   saldoContaCorrente() {
//     const mes = (this.defaultDate.getMonth() + 1);
//     this.saldoCC = 0.00;
//     this.httpService.wait();
//     this.httpService.get('/custom/financeiro/dashboard-saldo-por-conta',
//       [this.dblinkedSessionService.empresa.id,
//         this.dataIniSearch, this.dataFinSearch, 1]) // 1 - CC
//       .subscribe(ref => {
//         ref.forEach(element => {
//             const valor = this.utilService.parseMoney((element.valor));
//             this.saldoCC = this.saldoCC + valor;
//         });
//       }, error => this.httpService.handleError(error, () => this.saldoContaCorrente()),
//         () => this.httpService.done());
//   }

//   saldoContaInvestimento() {
//     this.saldoInvest = 0.00;
//     const mes = (this.defaultDate.getMonth() + 1);
//     this.httpService.wait();
//     this.httpService.get('/custom/financeiro/dashboard-saldo-por-conta',
//       [this.dblinkedSessionService.empresa.id,
//         this.dataIniSearch, this.dataFinSearch, 4]) // 4 - investimento
//       .subscribe(ref => {
//         ref.forEach(element => {
//             const valor = this.utilService.parseMoney((element.valor));
//             this.saldoInvest = this.saldoInvest + valor;
//         });
//       }, error => this.httpService.handleError(error, () => this.saldoContaInvestimento()),
//         () => this.httpService.done());
//     this.httpService.wait();
//     this.httpService.get('/custom/financeiro/dashboard-saldo-por-conta',
//       [this.dblinkedSessionService.empresa.id,
//         this.dataIniSearch, this.dataFinSearch, 3]) // 3 - Conta Aplicação
//       .subscribe(ref => {
//         ref.forEach(element => {
//             const valor = this.utilService.parseMoney((element.valor));
//             this.saldoInvest = this.saldoInvest + valor;
//         });
//       }, error => this.httpService.handleError(error, () => this.saldoContaInvestimento()),
//         () => this.httpService.done());
//   }

//   saldoCaixaMoeda() {
//     const mes = (this.defaultDate.getMonth() + 1);
//     this.saldoCaixinha = 0.00;
//     this.httpService.wait();
//     this.httpService.get('/custom/financeiro/dashboard-saldo-por-conta',
//       [this.dblinkedSessionService.empresa.id,
//         this.dataIniSearch, this.dataFinSearch, 6]) // 6 - Caixinha
//       .subscribe(ref => {
//         ref.forEach(element => {
//             const valor = this.utilService.parseMoney((element.valor));
//             this.saldoCaixinha = this.saldoCaixinha + valor;
//         });
//       }, error => this.httpService.handleError(error, () => this.saldoCaixaMoeda()),
//         () => this.httpService.done());
//   }

//   resetModal() {
//     this.modalRecebimentos = false;
//     this.modalPagamentos = false;
//     this.modalVencidos = false;
//   }

//   formatarDataExtenso() {
//     const dt = new Date();

//     const month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
//                   'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
//     const week  = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

//     const semana = week[dt.getDay()];
//     const mes = month[dt.getMonth()];

//     return 'Hoje é ' + semana + ', ' + dt.getDate() + ' de ' + mes + ' de ' + dt.getFullYear() + '.';

//   }

//   ngOnDestroy() {
//     this.toolbarService.hidden = false;
//     this.dblinkedSessionService.hidePeriods();
//     this.sessionService.destroySubscribe(this.sessionSubscription);
//   }

//   get isLstRTL() {
//     // console.log(document.documentElement.getAttribute('dir') );
//     // console.log(document.body.getAttribute('dir') );
//     return document.documentElement.getAttribute('dir') === 'rtl' ||
//            document.body.getAttribute('dir') === 'rtl';
//   }

//   get rowsPerPage() {
//     return this.configService.applicationConfig.rowsPerPage;
//   }
//   get rows() {
//     return this.configService.applicationConfig.rows;
//   }
//   get pageLinks() {
//     return this.configService.applicationConfig.pageLinks;
//   }
// }

import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { TitleService } from 'app/shared/services/title.service';
import { HttpService } from 'app/shared/services/http.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Subscription } from 'rxjs';
import { settings } from 'cluster';
import * as pbi from 'powerbi-client';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { Message, MessageService } from '../../../../node_modules/primeng/api';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardFinanceiroComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  models: any;
  permissions: any;
  viewMode: any;
  config: any;
  @ViewChild('powerBIReports') powerBIReports: ElementRef;

  msgs: Message[] = [];
  relatorioGerado: boolean;
  dtAtualizacao: Date;
  report: pbi.Report;
  mesFiltro: string;
  anoFiltro: string;
  anoCompletoFiltro: string;

  private pbiContainerElement: HTMLElement;

  constructor(private titleService: TitleService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    public elementRef: ElementRef,
    private messageService: MessageService) {
  }

  ngOnInit(): void {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.change();
      });
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  change() {

    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

        let mes: string;
        switch (this.dblinkedSessionService.periodo.month.value) {
          default:
            mes = '';
            break;
          case 1:
            mes = 'jan';
            break;
          case 2:
            mes = 'fev';
            break;
          case 3:
            mes = 'mar';
            break;
          case 4:
            mes = 'abr';
            break;
          case 5:
            mes = 'mai';
            break;
          case 6:
            mes = 'jun';
            break;
          case 7:
            mes = 'jul';
            break;
          case 8:
            mes = 'ago';
            break;
          case 9:
            mes = 'set';
            break;
          case 10:
            mes = 'out';
            break;
          case 11:
            mes = 'nov';
            break;
          case 12:
            mes = 'dez';
            break;

        }

      this.mesFiltro = mes;
      this.anoFiltro= this.dblinkedSessionService.periodo.year.value.toString().substring(2,5);
      this.anoCompletoFiltro = this.dblinkedSessionService.periodo.year.value.toString();

      let reportContainer = <HTMLElement>document.getElementById('pbi-report');
      let reportPrint;
      let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      reportPrint = powerbi.reset(reportContainer);

      this.toolbarEnable();

      this.config = [];

      this.httpService.wait();
      this.httpService.get('/custom/pbi/report-pbi-fin', [this.sessionService.loggedUser.id])
      .subscribe(rst => {

        if (rst.error) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: '',
            detail: rst.message
          });

        } else {

          this.relatorioGerado = true;

          this.dtAtualizacao = rst.dtAtualizacao;

          this.models = pbi.models;
          this.permissions = this.models.Permissions.All;
          this.viewMode = this.models.ViewMode.View;

          this.config = {
            type: rst.type,
            tokenType: this.models.TokenType.Embed,
            accessToken: rst.accessToken,
            embedUrl: rst.embedUrl,
            id: rst.reportId,
            permissions: this.permissions,
            viewMode: this.viewMode,
            settings: {
              filterPaneEnabled: rst.filterPaneEnabled,
              navContentPaneEnabled: rst.navContentPaneEnabled,
              background: this.models.BackgroundType.Transparent
            }
          };

          let reportContainer = <HTMLElement>document.getElementById("pbi-report");
          let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

          this.report = <pbi.Report>powerbi.embed(reportContainer, this.config);


          if (rst.filtraEmpresa && !rst.filtraPeriodo) {

            let basicFilterEmpresa: pbi.models.IBasicFilter = {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: rst.tabelaFiltroEmpresa,
                column: rst.colunaFiltroEmpresa
              },
              operator: "In",
              values: [this.dblinkedSessionService.empresa.id],
              filterType: 1 // pbi.models.FilterType.BasicFilter,
            }

            this.report.on("loaded", () => {


              this.report.setFilters([basicFilterEmpresa])
                .catch(errors => {

                });
            });

          } else if (!rst.filtraEmpresa && rst.filtraPeriodo) {

            let basicFilterPeriodo: pbi.models.IBasicFilter = {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: rst.tabelaFiltroPeriodo,
                column: rst.colunaFiltroPeriodo
              },
              operator: "In",
              values: [rst.colunaFiltroPeriodo === 'MesAno' || rst.colunaFiltroPeriodo === 'mes_ano' ? this.mesFiltro + "-" + this.anoCompletoFiltro : this.dblinkedSessionService.periodo.year.value],
              filterType: 1 // pbi.models.FilterType.BasicFilter,
            }

            this.report.on("loaded", () => {


              this.report.setFilters([basicFilterPeriodo])
                .catch(errors => {

                });
            });

          } else if (rst.filtraEmpresa && rst.filtraPeriodo) {


            let basicFilterEmpresa: pbi.models.IBasicFilter = {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: rst.tabelaFiltroEmpresa,
                column: rst.colunaFiltroEmpresa
              },
              operator: "In",
              values: [this.dblinkedSessionService.empresa.id],
              filterType: 1 // pbi.models.FilterType.BasicFilter,
            }


            let basicFilterPeriodo: pbi.models.IBasicFilter = {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: rst.tabelaFiltroPeriodo,
                column: rst.colunaFiltroPeriodo
              },
              operator: "In",
              values: [rst.colunaFiltroPeriodo === 'MesAno' || rst.colunaFiltroPeriodo === 'mes_ano' ? this.mesFiltro + "-" + this.anoCompletoFiltro : this.dblinkedSessionService.periodo.year.value],
              filterType: 1 // pbi.models.FilterType.BasicFilter,
            }

            this.report.on("loaded", () => {


              this.report.setFilters([basicFilterEmpresa, basicFilterPeriodo])
                .catch(errors => {

                });
            });

          }



          if (this.relatorioGerado) {
            this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.change());
            this.toolbarService.alterarStatus('atualizar', false, 'Atualizar', () => { this.atualizarRelatorio(); });
            this.toolbarService.alterarStatus('print', false, 'Imprimir', () => this.print());
          }

        }




      },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());

    }
  }




  hideMessage() {

  }

  print() {


    let reportContainer = <HTMLElement>document.getElementById('pbi-report');
    let reportPrint;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    reportPrint = powerbi.get(reportContainer);

    reportPrint.print().catch(error => {
      console.log(error);
    });

  }

  atualizarRelatorio() {

    let reportContainer = <HTMLElement>document.getElementById('pbi-report');
    let reportRefresh;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    reportRefresh = powerbi.get(reportContainer);

    reportRefresh.refresh().catch(error => {
      console.log(error);
    });

    reportRefresh.reload().catch(error => {
      console.log(error);
    });
  }

  private toolbarEnable() {
    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    const todos = this.buttons.slice();
    this.toolbarService.alterarStatus('voltar', true, 'Voltar', () => this.atualizarRelatorio());
    this.toolbarService.replace('atualizar', true, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.atualizarRelatorio());
    this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir');
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');
    this.toolbarService.replace('add', true, 'class-new-button', 'ATUALIZAR',
      'Criar novo relatório', () => this.atualizarRelatorio());
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }


}
