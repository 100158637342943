import { Component, OnInit, OnDestroy } from '@angular/core';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { HttpService } from '../../shared/services/http.service';
import { Moeda } from '../../util/model/moeda.model';

@Component({
  selector: 'app-exp-orca',
  templateUrl: './exp-orca.component.html',
  styleUrls: ['./exp-orca.component.scss'],
})
export class ExportOrcamentoComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;

  msgs: Message[] = [];
  title: string = "Exportação de orçamento";
  subtitle: string = "Orçamento gerencial";
  modalOrca: boolean = false;
  versaoList: SelectItem[] = [];
  versaoId: number = null;
  opcoesClass: SelectItem[] = [];
  classSelecionadas: Array<string> = [];
  opcoesArea: SelectItem[] = [];
  areaSelecionadas: Array<string> = [];
  opcoesCcus: SelectItem[] = [];
  ccusSelecionados: Array<string> = [];
  opcoesProj: SelectItem[] = [];
  projSelecionados: Array<string> = [];
  opcoesMoeda: SelectItem[];
  moeda: Moeda = null;//new Moeda();
  empresaOk: boolean = false;
  opcoesModelo: SelectItem[] = [];
  modeloSelect: number= null

  configList: SelectItem[] = [];
  configId: number = null;

  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpClient: HttpClient,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id) {
          this.toolbarEnable();

          this.httpService.wait();
          this.httpService.get('/moeda?$select=id,codigo')
            .subscribe(ref => this.loadMoedas(ref.value),
              error => this.httpService.handleError(error, () => this.ngOnInit()),
              () => this.httpService.done());
          if (this.dblinkedSessionService.empresa.id === 1 ||
            this.dblinkedSessionService.empresa.id === 115 ||
            this.dblinkedSessionService.empresa.id === 133 ||
            this.dblinkedSessionService.empresa.id === 152 ||
            this.dblinkedSessionService.empresa.id === 158 ||
            this.dblinkedSessionService.empresa.id === 234) {
              this.empresaOk = true;
          }

          this.loadModeloRelatorio();
        }
      });
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
  }

  baixarOrca() {
    if(this.dblinkedSessionService.empresa.id !== 1
       && this.dblinkedSessionService.empresa.id !== 115
        && this.dblinkedSessionService.empresa.id !== 133) {

      let listaEnviar = {
        versaoId: this.versaoId,
        classificacoes : this.classSelecionadas,
        areas : this.areaSelecionadas,
        ccus : this.ccusSelecionados,
        empresaId : this.dblinkedSessionService.empresa.id,
        projs : this.projSelecionados,
        configId: this.configId
      }

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/export-orcamento-default', listaEnviar)
        .subscribe(result => this.download(result),
          error => this.httpService.handleError(error, () => this.baixarOrca()),
          () => this.httpService.done());
    } else if(this.modeloSelect === 1) {
      //Header da requisição
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'areas': this.areaSelecionadas,
          'centrosCusto': this.ccusSelecionados,
          'classificacoes': this.classSelecionadas,
          'versaoId': this.versaoId.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'orcamento/relatorio-basebi', null, httpOptions)
        .subscribe(res => {
          this.exibirMensagem(res);

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0'
            }),

            responseType: 'blob' as 'json'
          };

          this.httpService.wait();
          this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/baixar-relatorio-basebi', httpOptions2).subscribe(
            res => {
              let link = document.createElement('a');
              let url = window.URL.createObjectURL(res);
              let fileName = `orcamento.xlsx`;

              link.href = url;
              link.download = fileName;
              link.click();

              window.URL.revokeObjectURL(url);
              this.httpService.done();
          }, err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          });

          this.httpService.done();
        }, err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        });
    } else {
      let listaEnviar = {
        versaoId: this.versaoId,
        classificacoes : this.classSelecionadas,
        areas : this.areaSelecionadas,
        ccus : this.ccusSelecionados,
        empresaId : this.dblinkedSessionService.empresa.id,
        projs : this.projSelecionados,
      }

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/export-orcamento', listaEnviar)
        .subscribe(result => this.download(result),
          error => this.httpService.handleError(error, () => this.baixarOrca()),
          () => this.httpService.done());
    }
  }

  download(result: any) {
    console.log(result);
    if (!result.error) {
      this.windowOpen(this.httpService.url('/custom/file/' + result.file), '_blank');
    }
  }

  windowOpen(url: string, target?: string) {
    setTimeout(() => {
      window.open(url, target), 1;
    });
  }

  toolbarEnable() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('add');
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('remove');
  }

  hideMessage() {

  }

  getVersaoOrca() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/versao', httpOptions)
      .subscribe(
        res => {
          this.versaoList = [];
          var result = res["data"];
          for (let line of result) {
            this.versaoList.push({ "label": line["descricao"], "value": line["id"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  exibirMensagem(value: any) {

    if(!value.error){
      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem});

    } else {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro', detail: value.mensagem});
    }
  }

  getClassi() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/classi', httpOptions)
      .subscribe(
        res => {
          this.opcoesClass = [];
          var result = res["mensagem"];
          for (let line of result) {
            this.opcoesClass.push({ "label": line["descricao"], "value": line["descricao"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getArea() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/area', httpOptions)
      .subscribe(
        res => {
          this.opcoesArea = [];
          var result = res["mensagem"];
          for (let line of result) {
            this.opcoesArea.push({ "label": line["descricao"], "value": line["descricao"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getCcus() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/ccus', httpOptions)
      .subscribe(
        res => {
          this.opcoesCcus = [];
          var result = res["mensagem"];
          for (let line of result) {
            this.opcoesCcus.push({ "label": line["cod_ccus"] + " - " + line["descr_ccus"], "value": line["cod_ccus"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getProj() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/proj', httpOptions)
      .subscribe(
        res => {
          this.opcoesProj = [];
          var result = res["mensagem"];
          for (let line of result) {
            this.opcoesProj.push({ "label": line["cod_proj"] + " - " + line["descr_proj"], "value": line["cod_proj"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  showDialogRelatorio() {
    this.modalOrca = true;
    this.versaoId = null;
    this.classSelecionadas = [];
    this.areaSelecionadas = [];
    this.ccusSelecionados = [];
    this.getVersaoOrca();
    this.getClassi();
    this.getArea();
    this.getCcus();
    this.getProj();
    this.carregarImportacaoTipos();
  }

  showDialogRelatorioOrc() {
    this.modalOrca = true;
    this.versaoId = null;
    this.classSelecionadas = [];
    this.areaSelecionadas = [];
    this.ccusSelecionados = [];
    this.getVersaoOrca();
    this.getClassi();
    this.getArea();
    this.getCcus();
    this.getProj();
    this.carregarImportacaoTipos();

  }

  carregarImportacaoTipos(){


    this.httpService.wait();
    this.httpService.get('/configuracaoimportacao?$select=id,descricao'
     + '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=descricao')
      .subscribe(ref => {

        this.configList = [];
        ref.value.forEach(element => {
          this.configList.push({ "label": element.descricao, "value": element.id });
        });

      },
        error => this.httpService.handleError(error, () => this.carregarImportacaoTipos()),
        () => this.httpService.done());

  }

  loadMoedas(value: Array<any>) {
    this.opcoesMoeda = [];
    this.opcoesMoeda.push({ label: 'Nenhum', value: null });

    value.forEach(element => {
      this.opcoesMoeda.push({ label: element.codigo, value: element });
    });
  }

  loadModeloRelatorio() {
    this.opcoesModelo = [];

    this.opcoesModelo.push({ label: "Tradicional", value: 0 })
    this.opcoesModelo.push({ label: "Base BI", value: 1 })
  }
}
