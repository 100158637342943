import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ScrollService } from 'app/shared/services/scroll.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent {
  @Input() src = '';
  @Input() alt = '';

  @ViewChild('imageContainer') private imageContainer: ElementRef;

  zoom: boolean;
  private img: any;

  constructor(private scrollService: ScrollService) {
  }

  zoomIn() {
    this.zoom = true;

    this.scrollService.update();

    setTimeout(() => {
      this.img = this.imageContainer.nativeElement.querySelector('.zoom-container img');

      // Safari fix
      this.img.style.visibility = 'hidden';
      setTimeout(() => {
        this.img.style.visibility = 'visible';
      }, 250);
    }, 1);
  }

  zoomOut() {
    this.zoom = false;
  }

  get top(): number {
    return this.scrollService.top;
  }

  get left(): number {
    return this.scrollService.left;
  }

  get imgTop(): number {
    return this.img ? (this.scrollService.height / 2) - (this.img.offsetHeight / 2) : 0;
  }

  get imgLeft(): number {
    return this.img ? (this.scrollService.width / 2) - (this.img.offsetWidth / 2) : 0;
  }
}
