import { isNullOrUndefined } from "util";
import { PalestraCliente} from './palestra-cliente.model';
import { PalestraClienteStatus} from './palestra-cliente-status.model';
import { AuthUser} from 'app/proof/model/auth-user.model';

export class PalestraClienteObservacoes{

  id                            : number;
  palestraCliente               : PalestraCliente;
  palestraClienteStatus         : PalestraClienteStatus;
  observacao                    : string;
  dataRecusa                    : Date;
  dataRetorno                   : Date ;
  usuarioResponsavel            : AuthUser;
  usuarioResponsavelId          : number;


  constructor(palestraClienteObservacoes?: PalestraClienteObservacoes | number) {
    if (!isNullOrUndefined(palestraClienteObservacoes)) {
      if (typeof palestraClienteObservacoes === 'number') {
        this.id = palestraClienteObservacoes;
      } else {
        this.id                           = palestraClienteObservacoes.id;
        this.palestraCliente              = palestraClienteObservacoes.palestraCliente;
        this.palestraClienteStatus        = palestraClienteObservacoes.palestraClienteStatus;
        this.observacao                   = palestraClienteObservacoes.observacao;
        this.dataRecusa                   = palestraClienteObservacoes.dataRecusa;
        this.dataRetorno                  = palestraClienteObservacoes.dataRetorno;
        this.usuarioResponsavelId         = palestraClienteObservacoes.usuarioResponsavelId;
        this.usuarioResponsavel           = palestraClienteObservacoes.usuarioResponsavel;
      }
    }
  }

}