import {Carteira} from 'app/eventos/model/carteira.model';
import {Palestra} from 'app/eventos/model/palestra.model';
import { isNullOrUndefined } from 'util';

export class PalestraCarteiraPorcentagem{
  id                      : number;
  porcentagem             : number;
  carteira                : Carteira;
  carteiraId              : number;
  palestra                : Palestra;
  palestraId              : number;
  descricaoCarteira       : string;

  constructor(palestraCarteiraPorcentagem?: PalestraCarteiraPorcentagem | number) {

    if (!isNullOrUndefined(palestraCarteiraPorcentagem)) {
      if (typeof palestraCarteiraPorcentagem === 'number') {
        this.id = palestraCarteiraPorcentagem;
      } else {
        this.id                   = palestraCarteiraPorcentagem.id;
        this.palestra             = palestraCarteiraPorcentagem.palestra;
        this.palestraId           = palestraCarteiraPorcentagem.palestraId;
        this.carteira             = palestraCarteiraPorcentagem.carteira;
        this.carteiraId           = palestraCarteiraPorcentagem.carteiraId;
        this.porcentagem          = palestraCarteiraPorcentagem.porcentagem;
        this.descricaoCarteira    = palestraCarteiraPorcentagem.descricaoCarteira;

      }

    }

  }


}