import { Component, OnInit } from '@angular/core'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpService } from 'app/shared/services/http.service'
import { MessageService, SelectItem } from 'primeng/api'
import { SessionService } from 'app/shared/services/session.service'
import { SidebarService } from 'app/shared/services/sidebar.service'
import { Subscription } from 'rxjs'
import { Toolbar } from 'app/shared/model/toolbar.model'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import deepCopy from 'app/util/processing/deepcopy'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Component({
  selector: 'app-autorizacao-debito',
  templateUrl: './autorizacao-debito.component.html',
  styleUrls: ['./autorizacao-debito.component.scss']
})
export class AutorizacaoDebitoComponent implements OnInit {

  private sessionSubscription: Subscription

  numDocumentFilterTable: any
  dataEntregaFilterTable: any
  businessNameFilterTable: any
  historicFilterTable: any
  prioritiesFilterTable: any
  paymentTypeFilterTable: any
  parcelFilterTable: any
  applicantsFilterTable: any
  valueFilterTable: any

  clearArr: Array<any> = new Array()
  rowSelected: Array<any> = new Array()
  tableData: Array<any>  = new Array()
  tableDataApproved: Array<any> = new Array()
  tableDataLoading: Array<any> = new Array()
  tableDataPending: Array<any> = new Array()
  tableDataRefused: Array<any> = new Array()
  listApprovalStatus: Array<any> = ['APROVADO', 'PEDIDO CRIADO', 'TITULO CRIADO', 'BAIXADO', 'FINANCEIRO APROVADO', 'PEDIDO APROVADO', 'RMS APROVADO', 'PENDENTE', 'AGUARDANDO AUTORIZAÇÃO']

  cancel: boolean = false
  flowUses: boolean = false
  hasTimeCourse: boolean = false
  isDebitAuthorizer: boolean = false
  modalPaymentGenerate: boolean = false
  refuse: boolean = false
  showConcluded: boolean = false
  showPending: boolean = false
  showRefused: boolean = false
  tableLoading: boolean = true

  dateInitial: Date = new Date()
  dateFinal: Date = new Date()

  tab: number = 0
  totalValue: number = 0

  prioritiesTable: SelectItem[] = []
  applicantsTable: SelectItem[] = []

  currentState: string = 'initial'
  insertedCode: string = ''

  toolbarMainIni = [
    { key: 'refusePayment', icon: 'block', text: 'Recusar', tooltip: 'Recusar', visible: false, disabled: false, color: 'default', onClick: () => this.openModalRefusePayment() },
    { key: 'paymentGenerate', icon: 'payments', text: 'Autorizar', tooltip: 'Autorizar', visible: false, disabled: false, color: 'default', onClick: () => this.openModalPaymentGenerate() },
    { key: 'cancelPayment', icon: 'cancel', text: 'Cancelar', tooltip: 'Cancelar', visible: false, disabled: false, color: 'default', onClick: () => this.openModalCancelPayment() },
    { icon: 'refresh', text: 'Atualizar', tooltip: 'Atualizar dados', visible: true, disabled: false, color: 'default', onClick: () => this.refreshTable() }
  ]

  public toolbarMain = new Toolbar()

  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpClient: HttpClient,
    private httpService: HttpService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private sidebarService: SidebarService,
    private toolbarService: ToolbarService,
  ) { }

  ngOnInit(): void {
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.tableDataLoading = [{}, {}, {}]

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,() => {
      if(this.dblinkedSessionService.hasEmpresaSelected) this.initialize()
    })
  }

  ngOnDestroy(): void {
    this.toolbarService.hidden = false
    this.sessionSubscription.remove(this.sessionSubscription)
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  async initialize(): Promise<void> {
    this.hasTimeCourse = false

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
      this.dateInitial = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dateFinal = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
      this.hasTimeCourse = true
    }

    this.getInformations()
    this.changeScreen(0)
    this.getCompanyParameter()
    this.getDebitAuthorizer()
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected
  }

  get loading(): boolean {
    return this.httpService.loading
  }

  get rowsPerPage(): Array<any> {
    return this.configService.applicationConfig.rowsPerPage
  }

  get rows(): number {
    return this.configService.applicationConfig.rows
  }

  get pageLinks(): number {
    return this.configService.applicationConfig.pageLinks
  }

  // requests

  async getInformations() {
    this.tableLoading = true

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi
      }),
    }

    try {
      await this.httpClient.get(this.configService.defaultUrlApiUpload + 'workflow/listar-pagamentos-autorizar/' + this.dblinkedSessionService.empresa.id + "/" + this.convertTimestampForDateSQL(this.dateInitial.getTime()) + "/" + this.convertTimestampForDateSQL(this.dateFinal.getTime()), httpOptions).toPromise().then((res: any) => {
        this.tableDataPending = new Array()
        this.tableDataApproved = new Array()
        this.tableDataRefused = new Array()

        res.data.forEach(item => {
          item.dtCriacao = this.convertTimestamp(item.data_criacao)
          item.dtEntrega = this.convertTimestamp(item.data_entrega)
          item.aprovacaoDescr = item.status_aprovacao_descr
          if (item.status_aprovacao_descr === "AGUARDANDO AUTORIZAÇÃO") this.tableDataPending.push(item)
          else if (item.status_aprovacao_descr === "AGENDADO" || item.status_aprovacao_descr === "FINANCEIRO APROVADO" || item.status_aprovacao_descr === "BAIXADO") this.tableDataApproved.push(item)
          else if (item.status_aprovacao_descr === "AUTORIZAÇÃO RECUSADA") this.tableDataRefused.push(item)
        })
      })
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os pagamentos! Contate o suporte!' })
    }

    this.tableLoading = false

    this.onFilter()
  }

  redirectFunction() {
    if (this.refuse) this.refusedPayment()
    else if (this.cancel) this.paymentCancel()
    else this.paymentGenerate()
  }

  paymentGenerate() {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    const httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }),
      responseType: 'blob' as 'json'
    }
    const payload = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id,
      financeiros: this.rowSelected,
      isFlowUses: this.flowUses,
      statusList: this.listApprovalStatus,
      user_auth_token: this.insertedCode
    }
    this.httpService.wait()
    this.httpClient.post(this.configService.octaremoteUrl + "/asaas/pagamento", payload, httpOptions)
      .subscribe(
        res => {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res["message"] })
          this.modalPaymentGenerate = false
          this.refreshTable()
          this.httpService.done()
        },
        err => {
          if (err["error"].message) {
            if (err["status"] === 400) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: err["error"].message })
              this.httpClient.post(this.configService.octaremoteUrl + "/asaas/pagamento-log", payload, httpOptions2)
              .subscribe((res: any) => {
                  let link = document.createElement('a')
                  let url = window.URL.createObjectURL(res)
                  let fileName = 'log-pagamentos.txt'
                  link.href = url
                  link.download = fileName
                  link.click()
                  window.URL.revokeObjectURL(url)
                  this.refreshTable()
                },
                err => {
                  this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro na API: não foi possível baixar o log. Por favor, contate o suporte." })
                  this.httpService.done()
                })
            }
            else if (err["status"] === 401) {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err["error"]["message"] })
            }
            else{
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Ocorreu um erro ao gerar o pagamento. Por favor, contate o suporte." })
            }
          }
          else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao gerar o pagamento, contate o suporte."})
          this.httpService.done()

          this.modalPaymentGenerate = false
        }
      )
  }

  refusedPayment() {
    this.refuse = false
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi
      }),
    }

    const payload = {
      payments: this.rowSelected,
      user_auth_token: this.insertedCode,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpClient.post(this.configService.defaultUrlApiUpload + 'workflow/recusa-pagamentos', payload, httpOptions).subscribe(
      res => {
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Mensagem', detail: res["message"] })
        this.modalPaymentGenerate = false
        this.refreshTable()
      },
      err => {
        if (err["status"] === 401) {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err["error"]["message"] })
        }
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao recusar os pagamentos! Contate o suporte!' })
        this.modalPaymentGenerate = false
        this.refreshTable()
      })
  }

  async getCompanyParameter(): Promise<void> {
    try {
      await this.httpService.get('/empresa?$select=usarAprovacao&$filter=id eq ' + this.dblinkedSessionService.empresa.id).toPromise().then(res => {
        res.value.forEach(item => {
          this.flowUses = item.usarAprovacao
        })
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao carregar os paramêtros da empresa! Contate o suporte' })
    }
  }

  paymentCancel() {
    this.cancel = false

    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    const httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }),
      responseType: 'blob' as 'json'
    }
    const payload = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id,
      nfe_fin_list: this.rowSelected,
      user_auth_token: this.insertedCode
    }
    this.httpService.wait()
    this.httpClient.post(this.configService.octaremoteUrl + "/asaas/cancelar-agendamento", payload, httpOptions)
    .subscribe(
      res => {
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res["message"] })
        this.modalPaymentGenerate = false
        this.refreshTable()
        this.httpService.done()
      },
      err => {
        if (err["error"].message) {
          if (err["status"] === 400) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: err["error"].message })
            this.httpClient.post(this.configService.octaremoteUrl + "/asaas/cancelar-agendamento-log", payload, httpOptions2)
            .subscribe((res: any) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                let fileName = 'log-cancelamento-pagamentos.txt'
                link.href = url
                link.download = fileName
                link.click()
                window.URL.revokeObjectURL(url)
              },
              err => {
                this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro na API: não foi possível baixar o log: " + err.message })
                this.httpService.done()
              })
          }
          else if (err["status"] === 401) {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err["error"]["message"] })
          }
          else{
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err["error"].message })
          }
        }
        else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro no API ao gerar o pagamento, contate o suporte: " + err.message })

        this.httpService.done()
        this.modalPaymentGenerate = false
      }
    )
  }

  async getDebitAuthorizer(): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi
      }),
    }

    this.isDebitAuthorizer = false

    try {
      await this.httpClient.get(this.configService.defaultUrlApiUpload + 'workflow/recuperar-autorizadores/' + this.dblinkedSessionService.empresa.id, httpOptions).toPromise().then((res: any) => {
        res.data.forEach(debitAuthorizer => {
          if (debitAuthorizer === this.sessionService.loggedUser.id) this.isDebitAuthorizer = true
        })
      })
    } catch(err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao recuperar os autorizadores de débito! Contate o suporte!' })
    }
  }

  //Utils

  refreshTable() {
    this.tableLoading = true
    this.tableData = new Array()
    this.totalValue = 0
    this.rowSelected = []

    this.getInformations()
    this.changeScreen(this.tab)
  }

  openModalPaymentGenerate() {
    this.insertedCode = ''
    this.modalPaymentGenerate = true
  }

  openModalRefusePayment() {
    this.insertedCode = ''
    this.refuse = true
    this.modalPaymentGenerate = true
  }

  openModalCancelPayment() {
    this.insertedCode = ''
    this.cancel = true
    this.modalPaymentGenerate = true
  }

  changeToolbar(event: any = {}) {
    this.toolbarMain.setVisible('paymentGenerate', (this.isDebitAuthorizer && this.rowSelected.length > 0 && this.tab === 0))
    this.toolbarMain.setVisible('refusePayment', (this.isDebitAuthorizer && this.rowSelected.length > 0 && this.tab === 0))
    this.toolbarMain.setVisible('cancelPayment', (this.isDebitAuthorizer && this.rowSelected.length > 0 && this.tab === 1))
  }

  formatDateExtensive(): String {
    const dt = new Date()
    const monthExtensive = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const weekExtensive = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]

    const month = monthExtensive[dt.getMonth()]
    const week = weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }

  convertTimestamp(timestamp): string {
    let fullDate: string = ''

    if(timestamp){
      const date = new Date(timestamp)
      date.setHours(date.getHours()+3)
      const day = date.getDate() > 9 ? date.getDate() : '0'+date.getDate()
      const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : '0'+(date.getMonth()+1)

      fullDate = day + '/' + month + '/' + date.getFullYear()
    }

    return fullDate
  }

  changeScreen(type: number): void {
    this.tableData = new Array()

    switch(type) {
      case 0:
        this.showPending = true
        this.showConcluded = false
        this.showRefused = false
        this.tab = 0
      break
      case 1:
        this.showPending = false
        this.showConcluded = true
        this.showRefused = false
        this.tab = 1
      break
      case 2:
        this.showPending = false
        this.showConcluded = false
        this.showRefused = true
        this.tab = 2
      break
    }

    this.rowSelected = []
    this.clearArr = []

    this.onFilter()
  }

  setUniqueArr(arr: Array<any>, parameter: string): Array<any> {
    const objectMap = arr.reduce((map, object) => {
      map.set(object[parameter], object)
      return map
    }, new Map())

    return Array.from(objectMap, ([_, value]) => value)
  }

  convertTimestampForDateSQL(timestamp): string {
    let fullDate: string = ''

    if(timestamp){
      const date = new Date(timestamp)
      date.setHours(date.getHours()+3)
      const day = date.getDate() > 9 ? date.getDate() : '0'+date.getDate()
      const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : '0'+(date.getMonth()+1)

      fullDate = date.getFullYear() + '-' + month + '-' + day
    }

    return fullDate
  }

  //Rows

  onSelectAll(event: any): void {
    this.rowSelected = []
    if(event.checked) this.rowSelected = deepCopy(this.clearArr)

    if(this.rowSelected.length === 0) {
      this.sidebarService.open(false)
      this.sidebarService.clearRow()
    }

    this.changeToolbar(event.data)
  }

  onRowSelect(event: any): void {
    this.rowSelected.push(event.data)

    this.changeToolbar(event.data)
  }

  onRowUnselect(event: any): void {
    this.rowSelected = this.rowSelected.filter(line => line.id !== event.data.id)

    this.changeToolbar(event.data)
  }

  onFilter(): void {
    this.totalValue = 0

    switch (this.tab) {
      case 0:
        this.tableData = this.tableDataPending
      break
      case 1:
        this.tableData = this.tableDataApproved
      break
      case 2:
        this.tableData = this.tableDataRefused
      break
    }

    this.resetFiltersTable()

    this.tableData.forEach(item => {
      this.totalValue += item.valor_total
      this.setTableFilters(item)
    })
  }

  // filters

  setTableFilters(item: Object): void {
    if(item['prioridade_descr']) this.prioritiesTable.push({ label: item['prioridade_descr'].toUpperCase(), value: item['prioridade_descr'] })
    if(item['requisitante_full_name']) this.applicantsTable.push({ label: item['requisitante_full_name'].toUpperCase(), value: item['requisitante_full_name'] })

    let callBack = (a,b) => { return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0) }

    this.prioritiesTable = this.setUniqueArr(this.prioritiesTable, 'value').sort(callBack)
    this.applicantsTable = this.setUniqueArr(this.applicantsTable, 'value').sort(callBack)
  }

  resetFiltersTable(): void {
    this.prioritiesTable = new Array()
    this.applicantsTable = new Array()

    this.numDocumentFilterTable = null
    this.dataEntregaFilterTable = null
    this.businessNameFilterTable = null
    this.historicFilterTable = null
    this.prioritiesFilterTable = null
    this.paymentTypeFilterTable = null
    this.parcelFilterTable = null
    this.applicantsFilterTable = null
    this.valueFilterTable = null
  }

}
