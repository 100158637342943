import { Directive, ElementRef, Input, Output, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';
import { UtilService } from 'app/shared/services/util.service';

@Directive({
  providers: [NgModel],
  selector: '[ngModel][money]'
})
export class MoneyDirective implements AfterViewInit {
  @Input() ngModel: any;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  private input: HTMLInputElement;
  private lastValue: string = null;

  private decimal = ',';

  constructor(private elementRef: ElementRef,
    private model: NgModel,
    private utilService: UtilService) { }

  ngAfterViewInit() {
    this.input = this.elementRef.nativeElement;
    this.lastValue = this.input.value;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(keyboardEvent: any) {
    let value = this.input.value;

    switch (true) {
      // Decimal (point or comma)
      case [110, 188, 190].indexOf(keyboardEvent.keyCode) > -1:
        break;

      // Numbers
      case keyboardEvent.keyCode >= 96 && keyboardEvent.keyCode <= 105:
      case keyboardEvent.keyCode >= 48 && keyboardEvent.keyCode <= 57:
      case ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(keyboardEvent.key) > -1:
        const parts = value.split(this.decimal);
        return parts.length !== 2 || parts[1].length !== 2 || this.input.selectionStart <= parts[0].length;

      // All others
      default:
        return true;
    }

    // Decimal (point or comma)
    if ([110, 188, 190].indexOf(keyboardEvent.keyCode) > -1 &&
      ((value.indexOf(this.decimal) === -1 && this.input.selectionStart > value.length - 3) ||
        ((value.indexOf(this.decimal) === -1 || (value.indexOf(this.decimal) >= this.input.selectionStart &&
          value.indexOf(this.decimal) <= this.input.selectionEnd &&
          this.input.selectionStart !== this.input.selectionEnd))
          && value.length - (this.input.selectionEnd - this.input.selectionStart) < 3))) { // TODO: Review

      if (this.input.selectionStart === value.length) {
        value += this.decimal;
      } else {
        value = value.substr(0, this.input.selectionStart) + this.decimal + value.substr(this.input.selectionEnd);
      }

      const selectionStart = this.input.selectionStart;
      this.input.value = value;
      this.lastValue = this.input.value;
      this.ngModelChange.next(this.input.value);

      setTimeout(() => {
        this.input.selectionStart = selectionStart + this.decimal.length;
        this.input.selectionEnd = this.input.selectionStart;
      }, 1);
    }

    return false;
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    const parts: any[] = event.target.value.toString().replace(this.decimal, '.').split('.');

    if (parts.length > 2) {
      event.target.value = this.lastValue;
      this.ngModelChange.next(this.input.value);

      return false;
    }

    if (parts.length === 2 && !isNaN(parts[0]) && !isNaN[parts[1]] && parts[1].length > 2) {
      event.target.value = parts[0] + this.decimal + parts[1].substr(0, 2);
      this.lastValue = this.input.value;
      this.ngModelChange.next(this.input.value);

      return false;
    }

    if (isNaN(event.target.value.toString().replace(this.decimal, '.'))) {
      event.target.value = this.lastValue;
      this.ngModelChange.next(this.input.value);

      return false;
    }

    this.lastValue = this.input.value;
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    event.target.value = this.utilService.parseMoneyString(event.target.value);
  }
}
