import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Moment } from 'moment';
import { UtilService } from '../../services/util.service';
import { Period, Year, Month, MonthLabels } from './period.model';
import { AbstractValueAccessor } from './abstract-value-acessor';

@Component({
  selector: 'app-period-select',
  templateUrl: './period-select.component.html',
  styleUrls: ['./period-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PeriodSelectComponent,
    multi: true,
  }],
})
export class PeriodSelectComponent extends AbstractValueAccessor implements OnInit {
  @Input() separator = ' de ';
  @Input() displayMonths: boolean | string = true;
  @Input() displayCurrentYear: boolean | string = false;
  @Input() displayAll: boolean | string = false;
  @Input() months = 12;
  @Input() nextMonths = 0;
  @Input() years = 5;
  @Input() prompt = 'Selecione';
  @Input() placeholder = 'Período';
  @Input() floating: boolean | string = false;
  @Input() filter: boolean | string = false;
  @Input() required: boolean | string = false;
  @Output() change: EventEmitter<Period> = new EventEmitter();

  @ViewChild('input')
  private inputField: ElementRef;

  periods: Array<Period> = new Array();
  valuePeriod: Period = undefined;

  filterValue: string = null;

  constructor(private utilService: UtilService) {
    super();
  }

  ngOnInit(): void {
    if (this.displayMonths === 'false') {
      this.displayMonths = false;
    }
    if (this.displayCurrentYear === 'false') {
      this.displayCurrentYear = false;
    }
    if (this.displayAll === 'false') {
      this.displayAll = false;
    }
    if (this.floating === 'false') {
      this.floating = false;
    }
    if (this.filter === 'false') {
      this.filter = false;
    }
    if (this.required === 'false') {
      this.required = false;
    }

    const moment: Moment = this.utilService.moment();
    let year: number = moment.year();

    if (this.displayMonths) {
      let month: number = moment.month() + 1;

      if (this.nextMonths > 0) {
        for (let i = 0; i < this.nextMonths; i++) {
          month = month + 1;
          if (month > 12) {
            month = 12;
            year++;
          }
        }
      }

      for (let i = 0; i < this.months; i++) {
        this.periods.push(new Period(
          new Year(year, this.displayCurrentYear || moment.year() !== year ? year.toString() : null),
          new Month(month)
        ));

        month = month - 1;
        if (month < 1) {
          month = 12;
          year--;
        }
      }
    } else {
      if (!this.displayCurrentYear) {
        year--;
      }

      for (let i = 0; i < this.years; i++) {
        this.periods.push(new Period(
          new Year(year, year.toString() )
        ));

        year--;
      }
    }
  }

  onOpen() {
    if (this.inputField && this.inputField.nativeElement) {
      this.inputField.nativeElement.focus();
    }
  }

  onSelect(value?: Period) {
    if (this.valuePeriod === undefined || this.valuePeriod !== value) {
      this.change.next(value || null);
    }
  }

  label(period: Period): string {
    return (this.displayMonths ? period.month.label : '') +
           (period.year.label ? (this.displayMonths ? this.separator : '') + period.year.label : '');
  }

  search(): Array<Period> {
    if (this.filterValue === null || this.filterValue.trim() === '') {
      return this.periods;
    }

    return this.periods.filter(period => {
      const label = this.label(period).trim().toLocaleLowerCase();
      const filterValue = this.filterValue.trim().toLocaleLowerCase();

      switch (this.filter) {
        default:
          return label.startsWith(filterValue);
        case 'contains':
          return label.indexOf(filterValue) > -1;
      }
    });
  }
}
