import { AuthUser } from 'app/proof/model/auth-user.model';
import { TodoPainel } from './todo-painel.model';
import { TodoPrioridade } from './todo-prioridade.model';
import { TodoCardChecklist } from './todo-card-checklist.model';
import { TodoCardChecklistAtividades } from './todo-card-checklist-atividades.model';
import { TodoEtiquetas } from './todo-etiquetas.model';
import { TodoCardComentarios } from './todo-card-comentarios.model';
import { Participante } from 'app/cadastro/model/participante.model';
import { Requisicao } from 'app/rms/model/requisicao.model';
import { Pedido } from 'app/financeiro/model/pedido.model';
import { NfeFin } from 'app/financeiro/model/nfe-fin.model';

export class TodoCard {
  id: number;
  codigo: string;
  titulo: string;
  descricao: string;
  responsavel: AuthUser;
  responsavelSub: AuthUser;
  corBg: string;
  posicao: number;
  prioridade: TodoPrioridade;
  todoPainel: TodoPainel;
  todoPainelOld: number;
  dtIni: Date;
  dtFin: Date;
  arquivado: boolean;
  excluido: boolean;
  cardId: number;
  usuario: AuthUser;
  necessitaAprovacao: boolean;
  usuarioAprovador: AuthUser;
  checklist: Array<TodoCardChecklist>;
  etiquetas:  Array<TodoEtiquetas>;
  comentarios: Array<TodoCardComentarios>;
  participantes: Array<Participante>;

  rms: Requisicao;
  pedido: Pedido;
  nfeFin: NfeFin;

  constructor(todoCard?: TodoCard | number) {
    if (todoCard !== undefined && todoCard !== null) {
      if (typeof todoCard === 'number') {
        this.id = todoCard;
      } else {
        this.id = todoCard.id;
        this.codigo = todoCard.codigo;
        this.titulo = todoCard.titulo;
        this.descricao = todoCard.descricao;
        this.responsavel = todoCard.responsavel;
        this.responsavelSub = todoCard.responsavelSub;
        this.corBg = todoCard.corBg;
        this.posicao = todoCard.posicao;
        this.prioridade = todoCard.prioridade;
        this.todoPainel = todoCard.todoPainel;
        this.todoPainelOld = todoCard.todoPainelOld;
        this.dtIni = todoCard.dtIni;
        this.dtFin = todoCard.dtFin;
        this.arquivado = todoCard.arquivado;
        this.excluido = todoCard.excluido;
        this.cardId = todoCard.cardId;
        this.usuario = todoCard.usuario;
        this.necessitaAprovacao = todoCard.necessitaAprovacao;
        this.usuarioAprovador = todoCard.usuarioAprovador;
        this.checklist = todoCard.checklist;
        this.etiquetas = todoCard.etiquetas;
        this.comentarios = todoCard.comentarios;
        this.participantes = todoCard.participantes;
        this.rms = todoCard.rms;
        this.pedido = todoCard.pedido;
        this.nfeFin = todoCard.nfeFin;
      }
    }
  }
}
