import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-report',
  templateUrl: './report-evolucao-mensal.component.html',
  styleUrls: ['./report-evolucao-mensal.component.scss'],
  providers: [CrudService]
})
export class EcdReportEvolucaoMensalComponent implements OnInit, OnDestroy {
  // TODO: Review INPUTS
  @Input() keyField = 'id';
  @Input() year = 'year';
  @Input() cc = 'cc';
  @Input() cp = 'cp';
  @Input() idioma = 'idioma';
  @Input() mesSelecionado = 'mes';

  private sessionSubscription: Subscription;

  private keyValue: string;
  private params: string;
  evoAtivo: Array<any>;
  evoResultado: Array<any>;
  dre: Array<any>;
  selectedPc: any;
  titlePrincipal: string;
  subtitle: string;
  frozenCols: any[] = [];
  scrollableCols: any[] = [];

  constructor(private titleService: TitleService,
              private toolbarService: ToolbarService,
              private httpService: HttpService,
              private utilService: UtilService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private configService: ConfigService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private crudService: CrudService,
              private appComponent: AppComponent) {
  }

  ngOnInit(): void {

    setTimeout(() => { this.appComponent._displaySideNav = false; });

    this.activatedRoute.params.subscribe(params => {
        this.keyValue = params['id'];
        this.year = params['year'];
        this.cc = params['cc'];
        this.cp = params['cp'];
        this.idioma = params['idioma'];
        this.mesSelecionado = params['mes'];
    });

    this.scrollableCols = [
      { field: 'jan', header: 'JAN', width: 90  },
      { field: 'fev', header: 'FEV', width: 90  },
      { field: 'mar', header: 'MAR', width: 90  },
      { field: 'abr', header: 'ABR', width: 90  },
      { field: 'mai', header: 'MAI', width: 90  },
      { field: 'jun', header: 'JUN', width: 90  },
      { field: 'jul', header: 'JUL', width: 90  },
      { field: 'ago', header: 'AGO', width: 90  },
      { field: 'set', header: 'SET', width: 90  },
      { field: 'out', header: 'OUT', width: 90  },
      { field: 'nov', header: 'NOV', width: 90  },
      { field: 'dez', header: 'DEZ', width: 90  },
    ];


    this.frozenCols = [
      { field: 'codCta', header: 'Código', width: 400  }
    ];



    this.titleService.title = 'ECD - Evolução Mensal';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
        this.httpService.wait();
        this.httpService.get('/custom/ecd-report/evolucaoativopassivo('
        + this.keyValue + ',' + this.year + ',' + this.idioma + ')')
            .subscribe(evoAtivo => this.evoAtivo = evoAtivo,
            error => this.httpService.handleError(error, () => this.ngOnInit()),
            () => this.httpService.done());


            this.httpService.wait();
        this.httpService.get('/custom/ecd-report/evolucaoresultado('
        + this.keyValue + ',' + this.year + ',' + this.cc + ',' + this.cp + ',' + this.idioma + ')')
            .subscribe(evoResultado => this.evoResultado = evoResultado,
            error => this.httpService.handleError(error, () => this.ngOnInit()),
            () => this.httpService.done());

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
                                                                         () => { this.change(); });

  this.titlePrincipal = 'EVOLUÇÃO MENSAL';
  this.subtitle = 'PERÍODO: ' + this.mesSelecionado + '/' + this.year;
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    // this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    // this.toolbarService.clear();
  }

  onRowSelectA(event: any, mes: number) {
    console.log('event: ' + event);
    console.log('mes: ' +  mes);
    if (event.indCta === 'A') {

      const cod = event.codCta.split(' - ')[0];

      const endDate = this.utilService.lastDayOfMonth([this.dblinkedSessionService.periodo.year.value, mes - 1]);

      window.open('/ecd/report-balancete-mensal-lcto/' + this.keyValue + '/' + cod + '/' +
                                                         this.year + '-' + mes + '-01' + '/' +
                                                         this.year + '-' + mes  + '-' + endDate.get('D') +
                                                         '/evolucaoap/false', '_blank');
    }
  }

  onRowSelectR(event: any, mes: number) {
    if (event.indCta === 'A') {
      const cod = event.codCta.split(' - ')[0];

      const endDate = this.utilService.lastDayOfMonth([this.dblinkedSessionService.periodo.year.value, mes - 1]);

      window.open('/ecd/report-balancete-mensal-lcto/' + this.keyValue + '/' + cod + '/' +
                                                         this.year + '-' + mes + '-01' + '/' +
                                                         this.year + '-' + mes  + '-' + endDate.get('D') +
                                                         '/evolucaoap/false', '_blank');
    }
  }
}
