import { Injectable } from '@angular/core';

import { HttpService } from './../../shared/services/http.service';

@Injectable()
export class EcdEcfService {
  private ecdOrEcfList: Array<any> = null;

  constructor(private httpService: HttpService) {
  }

  load(empresa: number, year: number) {
    let tempList;
    this.ecdOrEcfList = null;

    // TODO: Use only cadEcd OR cadEcf ??
    this.httpService.wait();
    this.httpService.get('/cadecf?$select=dtIni,dtFin,status/id' +
                                '&$filter=empresa/id eq ' + empresa +
                                        ' and period(dtIni,dtFin) eq ' + year)
                    .subscribe(cadEcd => {
                                   tempList = cadEcd.value || new Array();

                                   this.httpService.wait();
                                   this.httpService.get('/cadecd?$select=dtIni,dtFin,status/id' +
                                                               '&$filter=empresa/id eq ' + empresa +
                                                                       ' and period(dtIni,dtFin) eq ' + year)
                                                   .subscribe(cadEcf => {
                                                                this.ecdOrEcfList = (cadEcf.value || new Array()).concat(tempList);
                                                              },
                                                              error => this.httpService.handleError(error, () => this.load(empresa, year)),
                                                              () => this.httpService.done());
                              },
                              error => this.httpService.handleError(error, () => this.load(empresa, year)),
                              () => this.httpService.done());
  }

  get isLoaded(): boolean {
    return this.ecdOrEcfList !== null;
  }

  get hasApurado(): boolean {
    if (this.isLoaded) {
      return this.ecdOrEcfList && this.ecdOrEcfList.findIndex(e => e.status && (e.status.id === 2 || e.status.id === 3)) > -1;
    }

    return null;
  }

  get hasNotApurado(): boolean {
    if (this.isLoaded) {
      return this.ecdOrEcfList && this.ecdOrEcfList.findIndex(e => !e.status || (e.status.id !== 2 && e.status.id !== 3)) > -1;
    }

    return null;
  }

  get allApurado(): boolean {
    return this.isLoaded ? !this.hasNotApurado : null;
  }

  get hasHomologado(): boolean {
    if (this.isLoaded) {
      return this.ecdOrEcfList && this.ecdOrEcfList.findIndex(e => e.status && e.status.id === 3) > -1;
    }

    return null;
  }

  get hasNotHomologado(): boolean {
    if (this.isLoaded) {
      return this.ecdOrEcfList && this.ecdOrEcfList.findIndex(e => !e.status || e.status.id !== 3) > -1;
    }

    return null;
  }

  get allHomologado(): boolean {
    return this.isLoaded ? !this.hasNotHomologado : null;
  }
}
