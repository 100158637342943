import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { TitleService } from 'app/shared/services/title.service';
import { HttpService } from 'app/shared/services/http.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Subscription } from 'rxjs';
import { settings } from 'cluster';
import * as pbi from 'powerbi-client';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { Message, MessageService } from '../../../../node_modules/primeng/api';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.scss'],
})
export class RelatoriosPbiComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  models: any;
  permissions: any;
  viewMode: any;
  config: any;
  @ViewChild('powerBIReports') powerBIReports: ElementRef;

  private pbiContainerElement: HTMLElement;
  opcoesRelatorios: SelectItem[];
  idRelatorio: number;
  titlePrincipal: string;
  subtitle: string;
  relatorioGerado: boolean;
  dtAtualizacao: Date;
  msgs: Message[] = [];
  relatorioEscolhido = 0;
  mesFiltro: string;
  anoFiltro: string;
  anoCompletoFiltro: string;

  relatoriosPbi: Array<any> = new Array();
  relatoriosPbiFav: Array<any> = new Array();
  atualizacoesList: Array<any> = new Array();
  report: pbi.Report;

  modalAtualizacoes: boolean;

  constructor(private titleService: TitleService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    public elementRef: ElementRef,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.titlePrincipal = 'Workstats';
    this.subtitle = ''
    this.titleService.title = 'Relatórios - PowerBi';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.change();
      });
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  change() {

    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

      this.idRelatorio = 0;
      this.relatorioGerado = false;
      this.relatoriosPbi = new Array();
      this.relatoriosPbiFav = new Array();
      this.atualizacoesList = new Array();
      this.modalAtualizacoes = false;

      let mes: string;
      switch (this.dblinkedSessionService.periodo.month.value) {
        default:
          mes = '';
          break;
        case 1:
          mes = 'jan';
          break;
        case 2:
          mes = 'fev';
          break;
        case 3:
          mes = 'mar';
          break;
        case 4:
          mes = 'abr';
          break;
        case 5:
          mes = 'mai';
          break;
        case 6:
          mes = 'jun';
          break;
        case 7:
          mes = 'jul';
          break;
        case 8:
          mes = 'ago';
          break;
        case 9:
          mes = 'set';
          break;
        case 10:
          mes = 'out';
          break;
        case 11:
          mes = 'nov';
          break;
        case 12:
          mes = 'dez';
          break;

      }

      this.mesFiltro = mes;
      this.anoFiltro= this.dblinkedSessionService.periodo.year.value.toString().substring(2,5);
      this.anoCompletoFiltro = this.dblinkedSessionService.periodo.year.value.toString();


      let reportContainer = <HTMLElement>document.getElementById('pbi-report');
      let reportPrint;
      let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      reportPrint = powerbi.reset(reportContainer);


      this.toolbarEnable();

      this.httpService.wait();
      this.httpService.get('/custom/pbi/buscar-report-pbi-usr', [this.sessionService.loggedUser.id])
        .subscribe(retorno => {
          retorno.forEach(el => {
            if (el.favoritado) {
              this.relatoriosPbiFav.push(el);
            } else {
              this.relatoriosPbi.push(el);
            }

          });
        },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());

    }
  }

  gerarRelatorio(relId: any) {

    this.relatorioEscolhido = relId;


    this.config = [];

    this.httpService.wait();
    this.httpService.get('/custom/pbi/report-pbi', [this.sessionService.loggedUser.id, relId])
      .subscribe(rst => {

        if (rst.error) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: '',
            detail: rst.message
          });

        } else {

          this.relatorioGerado = true;

          this.dtAtualizacao = rst.dtAtualizacao;

          this.models = pbi.models;
          this.permissions = this.models.Permissions.All;
          this.viewMode = this.models.ViewMode.View;

          this.config = {
            type: rst.type,
            tokenType: this.models.TokenType.Embed,
            accessToken: rst.accessToken,
            embedUrl: rst.embedUrl,
            id: rst.reportId,
            permissions: this.permissions,
            viewMode: this.viewMode,
            settings: {
              filterPaneEnabled: rst.filterPaneEnabled,
              navContentPaneEnabled: rst.navContentPaneEnabled,
              background: this.models.BackgroundType.Transparent
            }
          };

          let reportContainer = <HTMLElement>document.getElementById("pbi-report");
          let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

          this.report = <pbi.Report>powerbi.embed(reportContainer, this.config);


          if (rst.filtraEmpresa && !rst.filtraPeriodo) {

            let basicFilterEmpresa: pbi.models.IBasicFilter = {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: rst.tabelaFiltroEmpresa,
                column: rst.colunaFiltroEmpresa
              },
              operator: "In",
              values: [this.dblinkedSessionService.empresa.id],
              filterType: 1 // pbi.models.FilterType.BasicFilter,
            }

            this.report.on("loaded", () => {


              this.report.setFilters([basicFilterEmpresa])
                .catch(errors => {

                });
            });

          } else if (!rst.filtraEmpresa && rst.filtraPeriodo) {

            let basicFilterPeriodo: pbi.models.IBasicFilter = {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: rst.tabelaFiltroPeriodo,
                column: rst.colunaFiltroPeriodo
              },
              operator: "In",
              values: [rst.colunaFiltroPeriodo === 'MesAno' || rst.colunaFiltroPeriodo === 'mes_ano' ? this.mesFiltro + "-" + this.anoCompletoFiltro : this.dblinkedSessionService.periodo.year.value],
              filterType: 1 // pbi.models.FilterType.BasicFilter,
            }

            this.report.on("loaded", () => {


              this.report.setFilters([basicFilterPeriodo])
                .catch(errors => {

                });
            });

          } else if (rst.filtraEmpresa && rst.filtraPeriodo) {


            let basicFilterEmpresa: pbi.models.IBasicFilter = {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: rst.tabelaFiltroEmpresa,
                column: rst.colunaFiltroEmpresa
              },
              operator: "In",
              values: [this.dblinkedSessionService.empresa.id],
              filterType: 1 // pbi.models.FilterType.BasicFilter,
            }


            let basicFilterPeriodo: pbi.models.IBasicFilter = {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: rst.tabelaFiltroPeriodo,
                column: rst.colunaFiltroPeriodo
              },
              operator: "In",
              values: [rst.colunaFiltroPeriodo === 'MesAno' || rst.colunaFiltroPeriodo === 'mes_ano' ? this.mesFiltro + "-" + this.anoCompletoFiltro : this.dblinkedSessionService.periodo.year.value],
              filterType: 1 // pbi.models.FilterType.BasicFilter,
            }

            this.report.on("loaded", () => {


              this.report.setFilters([basicFilterEmpresa, basicFilterPeriodo])
                .catch(errors => {

                });
            });

          }



          if (this.relatorioGerado) {
            this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.change());
            this.toolbarService.alterarStatus('atualizar', false, 'Atualizar', () => { this.atualizarRelatorio(); });
            this.toolbarService.alterarStatus('print', false, 'Imprimir', () => this.print());
          }

        }




      },
        error => this.httpService.handleError(error, () => this.gerarRelatorio(relId)),
        () => this.httpService.done());
  }

  infoRelatorioFavorito(event: any, bool: any) {

    if (confirm(bool ? 'Deseja realmente adicionar este relatório aos favoritos?' : 'Deseja realmente remover este relatório dos favoritos?')) {

      this.httpService.wait();
      this.httpService.get('/custom/pbi/report-fav', [event.id, bool, this.sessionService.loggedUser.id])
        .subscribe(retorno => {
          this.change();
        },
          error => this.httpService.handleError(error, () => this.infoRelatorioFavorito(event, bool)),
          () => this.httpService.done());
    }
  }

  hideMessage() {

  }

  print() {


    let reportContainer = <HTMLElement>document.getElementById('pbi-report');
    let reportPrint;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    reportPrint = powerbi.get(reportContainer);

    reportPrint.print().catch(error => {
      console.log(error);
    });

  }

  atualizarRelatorio() {

    let reportContainer = <HTMLElement>document.getElementById('pbi-report');
    let reportRefresh;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    reportRefresh = powerbi.get(reportContainer);

    reportRefresh.refresh().catch(error => {
      console.log(error);
    });

    reportRefresh.reload().catch(error => {
      console.log(error);
    });
  }

  private toolbarEnable() {
    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    const todos = this.buttons.slice();
    this.toolbarService.alterarStatus('voltar', true, 'Voltar', () => this.atualizarRelatorio());
    this.toolbarService.replace('atualizar', true, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.atualizarRelatorio());
    this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir');
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');
    this.toolbarService.replace('add', true, 'class-new-button', 'ATUALIZAR',
      'Criar novo relatório', () => this.atualizarRelatorio());
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  atualizarViewMaterializada(event: any){
    if (confirm('Deseja realmente atualizar? Esta operação poderá durar alguns minutos')) {
      this.httpService.wait();
      this.httpService.get('/custom/pbi/atualizar-view', [event.id, this.sessionService.loggedUser.id])
        .subscribe(retorno => {
          if(retorno.error){

            this.messageService.add({
              severity: 'warn',key: 'messageLinnks',
              summary: '',
              detail: retorno.message
            });

          }else{

            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: '',
              detail: retorno.message
            });

            this.change();
          }

        },
          error => this.httpService.handleError(error, () => this.atualizarViewMaterializada(event)),
          () => this.httpService.done());
    }
  }

  atualizarDataSet(event: any){
    if (confirm('Deseja realmente atualizar? Esta operação poderá durar alguns minutos')) {

      this.httpService.wait();
      this.httpService.get('/custom/pbi/atualizar-dataset', [event.id, this.sessionService.loggedUser.id])
        .subscribe(retorno => {
          if(retorno.error){

            this.messageService.add({
              severity: 'warn',key: 'messageLinnks',
              summary: '',
              detail: retorno.message
            });

          }else{

            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: '',
              detail: retorno.message
            });

            this.change();
          }

        },
          error => this.httpService.handleError(error, () => this.atualizarDataSet(event)),
          () => this.httpService.done());
    }
  }

  historicoAtualizacoes(event: any){

      this.atualizacoesList = new Array();
      this.httpService.wait();
      this.httpService.get('/custom/pbi/buscar-atualizacoes', [event.id, this.sessionService.loggedUser.id])
        .subscribe(retorno => {
          if(retorno.error){

            this.messageService.add({
              severity: 'warn',key: 'messageLinnks',
              summary: '',
              detail: 'Erro ao buscar as atualizações do BI, contate o suporte'
            });

          } else {


            retorno.value.forEach(element => {
              this.atualizacoesList.push(element);

            });

            this.modalAtualizacoes = true;

          }

        },
          error => this.httpService.handleError(error, () => this.historicoAtualizacoes(event)),
          () => this.httpService.done());

  }


}
