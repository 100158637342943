import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { TipoFatura } from 'app/util/model/tipo-fatura.model';
import { promise } from 'protractor';
import { FaturaItem } from '../model/fatura-item.model';
import { Participante } from 'app/cadastro/model/participante.model';
import { Moeda } from 'app/util/model/moeda.model';
import { Fatura } from '../model/fatura.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { FaturaRateio } from '../model/fatura-rateio.model';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { CadProj } from 'app/contabil/model/cad-proj.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { DH_NOT_SUITABLE_GENERATOR } from 'constants';
import { isNullOrUndefined } from 'util';
import { Depto } from 'app/cadastro/model/depto.model';
import { Message, MessageService, SelectItem } from 'primeng/api';


@Component({
  selector: 'app-fatura',
  templateUrl: './fatura.component.html',
  styleUrls: ['./fatura.component.scss'],
  providers: [CrudService]
})
export class FaturaComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  fatura                    : Array<any> = new Array();
  faturaAberta              : Array<any> = new Array();
  faturaCont                : Array<any> = new Array();
  faturaEnc                 : Array<any> = new Array();
  faturaAguardandoAprovacao : Array<any> = new Array();
  faturaAprovado            : Array<any> = new Array();
  faturaReprovado           : Array<any> = new Array();

  faturaAtual: Fatura;
  userLista: AuthUser;
  nameFileUpload: String;


  todosSelecionados: boolean;
  idFaturaView: Array<any> = new Array();
  idFaturaExcluirView: Array<any> = new Array();
  faturaSelecionados: Array<any> = new Array();

  modalFatura: boolean;
  modalFaturaItem: boolean;
  editFaturaItem: boolean;
  editFatura: boolean;
  modalDadosFaturaItem: boolean;
  contadorRateio: number;
  contadorItem: number;
  /*Fatura*/
  numeroCartao: number;
  nomeFavorecido: any;
  dtVencimento: Date;
  defaultDate: Date;
  dtFechamento: Date;
  key: any;
  financeiroPorItem: any;

  faturaItem = new FaturaItem();

  // opcoesPlanoContas: SelectItem[];
  contaSelecionada: number = null
  planoConta: PlanoContas = new PlanoContas()

  opcoesTipoFatura: SelectItem[];
  tipoFaturaSelecionada: number;

  // opcoesParticipante: SelectItem[];
  participanteSelecionada: number;

  // opcoesPlanoContasItem: SelectItem[];
  contaSelecionadaItem: number;

  opcoesMoeda: SelectItem[];
  moedaSelecionadaItem: number;

  msgsModalRateio: Message[] = [];
  rateioCentroCusto: number;
  modalCentroCusto: boolean;
  opcoesCentroCusto: SelectItem[];
  cc: any;
  opcoesProjetos: SelectItem[];
  proj: any;
  porcentagemCc: number;
  porcentagemCadProj: number;
  rateioCadProj: number;
  faturaRateio: FaturaRateio;

  dataIni: Date;
  dataFin: Date;
  modalPeriodo: boolean;
  dataIniSearch: any;
  dataFinSearch: any;

  cartaoCredito: boolean;
  rdv: boolean;
  notaDebito: boolean;

  msgsModal: Message[];

  mostraIcones: boolean;
  iconesSelecionado: any;
  numero: any;

  alteraItem: boolean;
  labelTipoFatura: String;

  creditoEstorno: string[] = ['Despesa', 'Receita/Estorno'];
  creditoEstornoValue: string;

  file: File;
  files: Array<any> = new Array();
  chaveUpload: String;

  indexTab: number;

  modalTaxas: boolean;
  msgsModalTaxa: Message[];
  opcoesMoedaFaturaItens: SelectItem[];
  moedaSelecionadaItemTaxa: number;
  moedasitens: Array<Moeda> = new Array();
  itemComplemento: boolean;

  modalFaturaItemComplemento: boolean;

  dataContabilizacao: Date;
  status: number;
  faturaId: number;
  isDepesaDireta: boolean;
  valorTotalItem: any;
  valorTotalItemCompl: any;
  botaoNovaFatura: boolean;
  labelCabecalhoFatura: any;
  valueTaxa: any;
  checkedAll = false;
  checkedAllContab = false;
  checkedAllEncerrado = false;
  codigoAbas: number;

  //Aprovador
  isAprov                    : Boolean = false;
  aprovadores                : Array<any> = new Array();
  opcoesAprovador            : SelectItem[];
  deparatamento              : Depto;
  aprovadorFatura            : AuthUser;
  isSameDepto                : boolean;
  isSalvarESolicitarAprovacao: boolean;

  modalAprovarLote:           boolean;
  centroCustoId:              number;
  centroCustoObrigatorio:     boolean
  existeProjetosRelacionados: boolean;
  existeContaResultado:       boolean;
  modalContasUnicas: boolean = false

  //export excel
  colsFaturaAberta: any[];
  colsFaturaAguardandoAprovacao: any[];
  colsFaturaAprovado: any[];
  colsFaturaReprovado: any[];
  colsFaturaCont: any[];
  colsFaturaEnc: any[];

  participante: Participante = new Participante()
  modalParticipante: boolean = false



  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }





  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.fatura = new Array<any>();
    this.faturaAberta               = new Array<any>();
    this.faturaCont                 = new Array<any>();
    this.faturaEnc                  = new Array<any>();
    this.faturaAguardandoAprovacao  = new Array<any>();
    this.faturaAprovado             = new Array<any>();
    this.faturaReprovado            = new Array<any>();
    this.modalAprovarLote           = false;


    this.files = new Array();
    this.dataContabilizacao = new Date();
    this.status = 1;

    this.idFaturaView = new Array();
    this.idFaturaExcluirView = new Array();
    this.faturaSelecionados = new Array();
    this.title = 'DESPESA DIRETA';
    this.subtitle = 'Todas as despesas diretas';
    this.titleService.title = 'DESPESA DIRETA';
    this.modalFatura = false;
    this.modalFaturaItem = false;
    this.modalDadosFaturaItem = false;
    this.editFaturaItem = false;
    this.editFatura = false;
    this.faturaItem = new FaturaItem();
    // centro de custo
    this.porcentagemCc = 0;
    this.porcentagemCadProj = 0;
    this.cc = [];
    this.proj = [];
    this.rateioCentroCusto = 100;
    this.rateioCadProj = 100;
    this.contadorRateio = 0;
    this.modalPeriodo = false;
    this.dataIniSearch = null;
    this.dataFinSearch = null;

    this.cartaoCredito = true;
    this.rdv = true;
    this.notaDebito = true;

    this.msgsModalTaxa = [];
    this.mostraIcones = true;
    this.iconesSelecionado = '0';
    this.numero = 0;
    this.alteraItem = false;
    this.labelTipoFatura = '';
    this.creditoEstornoValue = '';
    this.indexTab = 0;
    this.modalTaxas = false;
    this.modalFaturaItemComplemento = false;
    this.faturaId = null;
    this.isDepesaDireta = false;
    this.valorTotalItem = 0;
    this.valorTotalItemCompl = 0;
    this.botaoNovaFatura = false;
    this.labelCabecalhoFatura = '';
    this.valueTaxa = 0;
    this.codigoAbas = 0;
    this.centroDeCustoObrigatorio();

    //Iniciando método não usado no html
    this.isReadOnly();

    //Aprovador
    this.isSalvarESolicitarAprovacao = false;
    this.deparatamento = this.dblinkedSessionService.usuario.departamento;

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.defaultDate = new Date();
    this.faturaAtual = new Fatura();

    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    this.colsFaturaAberta = [
        { field: 'nome_favorecido', header: 'NOME/HISTÓRICO' },
        { field: 'numero', header: 'NÚMERO' },
        { field: 'data_vencimento', header: 'VENCIMENTO' },
        { field: 'descricao_tipo_fatura', header: 'TIPO FATURA' },
        { field: 'valorTotal', header: 'TOTAL' },
        { field: 'status', header: 'STATUS' }
    ];
    this.colsFaturaAguardandoAprovacao = [
      { field: 'nome_favorecido', header: 'NOME/HISTÓRICO' },
      { field: 'numero', header: 'NÚMERO' },
      { field: 'data_vencimento', header: 'VENCIMENTO' },
      { field: 'descricao_tipo_fatura', header: 'TIPO FATURA' },
      { field: 'valorTotal', header: 'TOTAL' },
      { field: 'status', header: 'STATUS' }
    ];
    this.colsFaturaAprovado = [
      { field: 'nome_favorecido', header: 'NOME/HISTÓRICO' },
      { field: 'numero', header: 'NÚMERO' },
      { field: 'data_vencimento', header: 'VENCIMENTO' },
      { field: 'descricao_tipo_fatura', header: 'TIPO FATURA' },
      { field: 'valorTotal', header: 'TOTAL' },
      { field: 'status', header: 'STATUS' }
    ];
    this.colsFaturaReprovado = [
      { field: 'nome_favorecido', header: 'NOME/HISTÓRICO' },
      { field: 'numero', header: 'NÚMERO' },
      { field: 'data_vencimento', header: 'VENCIMENTO' },
      { field: 'descricao_tipo_fatura', header: 'TIPO FATURA' },
      { field: 'valorTotal', header: 'TOTAL' },
      { field: 'status', header: 'STATUS' }
    ];
    this.colsFaturaCont = [
      { field: 'nome_favorecido', header: 'NOME/HISTÓRICO' },
      { field: 'numero', header: 'NÚMERO' },
      { field: 'data_vencimento', header: 'VENCIMENTO' },
      { field: 'descricao_tipo_fatura', header: 'TIPO FATURA' },
      { field: 'valorTotal', header: 'TOTAL' },
      { field: 'status', header: 'STATUS' }
    ];
    this.colsFaturaEnc = [
      { field: 'nome_favorecido', header: 'NOME/HISTÓRICO' },
      { field: 'numero', header: 'NÚMERO' },
      { field: 'data_vencimento', header: 'VENCIMENTO' },
      { field: 'descricao_tipo_fatura', header: 'TIPO FATURA' },
      { field: 'valorTotal', header: 'TOTAL' },
      { field: 'status', header: 'STATUS' }
    ];

  }

  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');


  }

  showTipoIcone(tipo) {
    this.iconesSelecionado = '0';
    this.iconesSelecionado = tipo;
    this.isDepesaDireta = false;

    if (tipo === '1') {
      this.mostraIcones = false;
      this.subtitle = 'Selecionado : Cartão de Crédito';

    } else if (tipo === '2') {
      this.mostraIcones = false;
      this.subtitle = 'Selecionado : Relatório Despesa de Viagem';
    } else if (tipo === '3') {
      this.mostraIcones = false;
      this.subtitle = 'Selecionado : Nota de Débito';
    } else if (tipo === '4') {
      this.mostraIcones = false;
      this.subtitle = 'Selecionado : Despesa Direta';
      this.isDepesaDireta = true;
    }

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.listar(tipo);
      });

  }

  private fomartaData(arg: Date) {

    let dia = null;
    let mes = null;


    dia = arg.getDate().toString();
    mes = arg.getMonth() + 1;
    mes = mes.toString();
    const ano = arg.getFullYear().toString();

    if (dia.length === 1) {
      dia = '0' + dia;
    }

    if (mes.length === 1) {
      mes = '0' + mes;
    }

    return ano + '-' + mes + '-' + (dia);
  }



  salvarContabilizar(acao: any) {

    let msgConfirmacao: string;

    switch (acao) {
      case 1:
        msgConfirmacao = 'Deseja realmente contabilizar esta fatura?';
        break;
      case 2:
        msgConfirmacao = 'Deseja realmente descontabilizar esta fatura?';
        break;
      case 3:
        msgConfirmacao = 'Deseja realmente contabilizar este complemento?';
        break;
      case 4:
        msgConfirmacao = 'Deseja realmente descontabilizar este complemento?';
        break;
      case 5:
        msgConfirmacao = 'Deseja realmente encerrar esta fatura?';
        break;
      case 6:
        msgConfirmacao = 'Deseja realmente abrir esta fatura?';
        break;

      default:
        break;
    }

    if (confirm(msgConfirmacao)) {
      this.moedasitens.forEach(element => {
        const codigoMoeda = element.codigo;
        const taxa = this.utilService.parseDecimal4(element.taxa);
        if (taxa === 0) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'A taxa não pode ser vazio. Verifique!'
          });
          return;
        }

        if (this.itemComplemento === false) {
          this.faturaAtual.faturaItem.forEach(itenselement => {
            if (itenselement.moeda.codigo === codigoMoeda) {
              itenselement.taxa = taxa;

              const index = this.faturaAtual.faturaItem.findIndex(c => c.id === itenselement.id);
              this.faturaAtual.faturaItem.splice(index, 1, itenselement);

            }
          });
        } else {
          this.faturaAtual.faturaItemComplemento.forEach(itenselement => {
            if (itenselement.moeda.codigo === codigoMoeda) {
              itenselement.taxa = taxa;

              const index = this.faturaAtual.faturaItemComplemento.findIndex(c => c.id === itenselement.id);
              this.faturaAtual.faturaItemComplemento.splice(index, 1, itenselement);
            }
          });
        }


      });

      const listasEnviar = {
        id: this.faturaAtual.id,
        moedasItens: this.moedasitens,
        dataContabilizacao: this.utilService.utcDate(this.dataContabilizacao),
        acao: acao,
        itemComplemento: this.itemComplemento,
        aprovador      : this.aprovadorFatura,
        departamento   : this.dblinkedSessionService.usuario.departamento,
        empresa        : this.dblinkedSessionService.empresa.id

      };

      this.httpService.wait();
      this.httpService.post('/custom/fatura/acao-fatura', listasEnviar)
        .subscribe(result => {
          if (result[0].error) {
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result[0].message });
            this.listar(this.iconesSelecionado);
            this.modalTaxas = false;
            this.modalFaturaItem = false;
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result[0].message });
            this.listar(this.iconesSelecionado);
            this.modalTaxas = false;
            this.modalFaturaItem = false;
          }
        },
          error => this.httpService.handleError(error, () => this.salvarContabilizar(acao)),
          () => this.httpService.done());
    }
  }


  salvarFaturaItem() {

    if (((this.status === 2 || this.status === 3 || this.status === 4) && this.faturaAtual.faturaItemComplemento.length === 0)
      || ((this.status === 1 || this.status === 3) && this.faturaAtual.faturaItemComplemento.length > 0)) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Status da Fatura não permite a inclusão de itens!'
      });
      return;
    }

    if (this.faturaAtual.faturaItem.length === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'o item da lista não pode ser vazio. Verifique!'
      });
      return;

    }

    if (this.status === 1) {

      const listasEnviar = {
        id              : this.faturaAtual.id,
        faturaItem      : this.faturaAtual.faturaItem,
        itemComplemento : false
        //aprovador       : this.aprovadorFatura,
        //deparatamento   : this.sessionService.loggedUser.deptoId,

      };

      this.httpService.wait();
      this.httpService.post('/custom/fatura/criar-fatura', listasEnviar)
        .subscribe(result => {

          if (result.error === true) {
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: 'Fatura salva com sucesso!' });
          }
          this.modalFatura = false;
          this.modalFaturaItem = false;
          this.listar(this.iconesSelecionado);
        },
          error => this.httpService.handleError(error, () => this.salvarFaturaItem()),
          () => this.httpService.done());

    }

    if (this.faturaAtual.faturaItemComplemento.length > 0) {
      const listasEnviarComplemento = {
        id: this.faturaAtual.id,
        faturaItem: this.faturaAtual.faturaItemComplemento,
        itemComplemento: true
      };

      this.httpService.wait();
      this.httpService.post('/custom/fatura/criar-fatura', listasEnviarComplemento)
        .subscribe(result => {
          if (result.error === true) {
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: 'Itens Salvo com sucesso' });
          }
          this.modalFatura = false;
          this.modalFaturaItem = false;
          this.listar(this.iconesSelecionado);
        },
          error => this.httpService.handleError(error, () => this.salvarFaturaItem()),
          () => this.httpService.done());

    }

    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirFaturaView());
    this.toolbarService.alterarStatus('print', true, 'Imprimir Fatura', () => this.imprimirFatura());
    this.toolbarService.alterarStatus('edit', true, 'Listar Itens', () => this.visualizarFaturaItem(event, true));
    this.toolbarService.alterarStatus('export', true, 'Editar Fatura', () => this.displayEditFaturaDialog());
    this.idFaturaView = new Array();



  }

  //Salvar fatura e solicitar aprovacao
  salvarFaturaeSolicitarAprovacao(){
    this.isSalvarESolicitarAprovacao = true;
    this.salvarFatura();
  }
  //Final salvar fatura e solicitar aprovacao

  //Inicio Salvar fatura
  salvarFatura() {

    if (this.numeroCartao === null) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Número do Cartão não pode ser vazio. Verifique!'
      });
      return;

    }


    if (this.nomeFavorecido.length === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Nome Favorecido não pode ser vazio. Verifique!'
      });
      return;

    }

    if (this.contaSelecionada === null || this.contaSelecionada === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Centro de Custo não pode ser vazio. Verifique!'
      });
      return;

    }

    if (this.tipoFaturaSelecionada === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Tipo Fatura não pode ser vazio. Verifique!'
      });
      return;

    }

    if (this.key.length === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'key não pode ser vazio. Verifique!'
      });
      return;

    }

    const faturabkp = this.fatura;
    this.faturaId != null ? this.alterarFatura() : this.incluirFatura();
    this.idFaturaView = new Array();



  }

  //Final salvar Fatura

  alterarFatura() {
    const listasEnviar = {
      id: this.faturaId,
      empresa: this.dblinkedSessionService.empresa.id.toString(),
      planoContas: this.contaSelecionada.toString(),
      statusFatura: '1',
      tipoFatura: this.tipoFaturaSelecionada.toString(),
      authUser: this.sessionService.loggedUser.id.toString(),
      numero: this.numeroCartao,
      nome_favorecido: this.nomeFavorecido,
      data_vencimento: this.dtVencimento,
      data_fechamento: this.dtFechamento,
      taxa: '0',
      key: this.key,
      aprovador                   : this.aprovadorFatura,
      financeiroPorItem           : this.financeiroPorItem,
      departamento                : this.dblinkedSessionService.usuario.departamento,
      isSalvarESolicitarAprovacao : this.isSalvarESolicitarAprovacao

    };
    console.log("editar fatura: " + JSON.stringify(listasEnviar) );
    this.httpService.wait();
    this.httpService.post('/custom/fatura/editar-fatura', listasEnviar)
      .subscribe(result => {

        if (result.error === true) {
          this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
        } else {
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: 'Fatura salva com sucesso!' });
        }
        this.modalFatura = false;
        this.modalFaturaItem = false;
        this.listar(this.iconesSelecionado);

      },
        error => this.httpService.handleError(error, () => this.alterarFatura()),
        () => this.httpService.done());
  }

  incluirFatura() {

    const listasEnviar = {
      id                : 0,
      empresa           : this.dblinkedSessionService.empresa.id.toString(),
      planoContas       : this.contaSelecionada.toString(),
      statusFatura      : '1',
      tipoFatura        : this.tipoFaturaSelecionada.toString(),
      authUser          : this.sessionService.loggedUser.id.toString(),
      numero            : this.numeroCartao,
      nome_favorecido   : this.nomeFavorecido,
      data_vencimento   : this.fomartaData(this.dtVencimento),
      data_fechamento   : this.fomartaData(this.dtFechamento),
      taxa              : '0',
      key                         : this.key,
      departamento                : this.dblinkedSessionService.usuario.departamento,
      aprovador                   : this.aprovadorFatura,
      financeiroPorItem           : this.financeiroPorItem,
      isSalvarESolicitarAprovacao : this.isSalvarESolicitarAprovacao

    };

    this.httpService.wait();
    this.httpService.post('/custom/fatura/criar-fatura', listasEnviar)
      .subscribe(result => {


        if (result.error === true) {
          this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
        } else {
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result.message });
          this.listar(this.iconesSelecionado);
          this.modalFatura = false;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarFatura()),
        () => this.httpService.done());

  }


  controlCentroCusto(cadCustoId) {
    this.modalCentroCusto = true;

    this.httpService.wait();
    this.httpService.get('/cadccus?$select=id,codCcus,descrCcus,ativo' +
      '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value + '&$orderby=codCcus')
      .subscribe(cc => this.comboCcus(cc.value),
        error => this.httpService.handleError(error, () => this.controlCentroCusto(cadCustoId)),
        () => this.httpService.done());

    if(!isNullOrUndefined(cadCustoId)){
      this.centroCustoId = cadCustoId;
    }else{
      this.centroCustoId = 0;
    }

    this.httpService.wait();
    this.httpService.get('/custom/financeiro/get-projetos(' + this.dblinkedSessionService.empresa.id + ','
    + this.centroCustoId + ')')
      .subscribe(proj => {
        this.comboProjeto(proj);
      },
        error => this.httpService.handleError(error, () => this.controlCentroCusto(cadCustoId)),
        () => this.httpService.done());

  }

  comboCcus(value: Array<any>) {
    this.opcoesCentroCusto = [];
    this.opcoesCentroCusto.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      if(element.ativo){
        element.label = element.codCcus + ' - ' + element.descrCcus;
        this.opcoesCentroCusto.push(element);
      }
    });
  }

  comboProjeto(value: Array<any>) {
    this.opcoesProjetos = [];
    this.opcoesProjetos.push({ label: 'Nenhum', value: null });
    if(value.length < 1){
      this.existeProjetosRelacionados = false;
    }
    value.forEach(element => {
      element.label = element.codProj + ' - ' + element.descrProj;
      this.opcoesProjetos.push(element);
      this.existeProjetosRelacionados = element.existeProjetosRelacionados;
    });
  }

  //combo aprovador



  incluirRateioCentroCusto() {

    if (this.cc.length === 0) {
      this.msgsModalRateio = [{
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Centro de Custo não pode ser vazio. Verifique!'
      }];
      return;

    }

    if(this.existeProjetosRelacionados && isNullOrUndefined(this.proj.id)){
      this.msgsModalRateio = [{
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Projeto não pode ser vazio. Verifique!'
      }];
      return;
    }

    if (this.porcentagemCc === 0) {
      this.msgsModalRateio = [{
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Porcentagem não pode ser 0. Verifique!'
      }];
      return;

    }

    if ((this.rateioCentroCusto <= 0) || (this.rateioCentroCusto < this.porcentagemCc)) {
      this.msgsModalRateio = [{
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'O valor do rateio ultrapassa os 100%, verifique!'
      }];
      return;
    }


    this.faturaRateio = new FaturaRateio();
    this.rateioCentroCusto = this.rateioCentroCusto - this.utilService.parseDecimal(this.porcentagemCc);
    this.faturaRateio.id = this.contadorRateio;
    this.faturaRateio.cadProj = this.proj;
    this.faturaRateio.cadCcus = this.cc;
    this.faturaRateio.porcentagem = this.porcentagemCc;
    this.userLista = new AuthUser();
    this.userLista.id = this.sessionService.loggedUser.id;
    this.faturaRateio.authUser = this.userLista;
    this.faturaItem.faturaRateio.push(this.faturaRateio);
    this.contadorRateio++;
    this.cc = '';
    this.proj = '';
    this.porcentagemCc = 0;
  }


  removerRateioCentroCusto(event: any) {
    if (confirm('Deseja excluir este rateio?')) {
      const index = this.faturaItem.faturaRateio.indexOf(event);
      this.faturaItem.faturaRateio.splice(index, 1);
    }
    this.rateioCentroCusto = 100;
    this.faturaItem.faturaRateio.forEach(element => {
      this.rateioCentroCusto = this.rateioCentroCusto - this.utilService.parseDecimal(element.porcentagem);
    });
  }

  fecharModalRateio() {
    this.modalCentroCusto = false;
    this.rateioCentroCusto = 100;
  }

  tipoFaturaChanged() {
    this.httpService.wait();
    this.httpService.get('/tipofatura?$select=id,descricao&$orderby=descricao')
      .subscribe(ref => this.loadTipoFatura(ref.value),
        error => this.httpService.handleError(error, () => this.tipoFaturaChanged()),
        () => this.httpService.done());
  }

  loadTipoFatura(array: Array<TipoFatura>) {
    this.opcoesTipoFatura = [];
    this.opcoesTipoFatura.push({ label: 'Selecionar Tipo Fatura', value: null });

    array.forEach(element => {
      this.opcoesTipoFatura.push({ label: element.descricao, value: element.id });

    });


  }



  // contaContabilChanged() {
  //   this.httpService.wait();
  //   this.httpService.get('/planocontas?$select=id,codCta,descrCta,indCta,codNaturezaConta/cod' +
  //     '&$filter=ativo eq true and empresa/id eq ' + this.dblinkedSessionService.empresa.id +
  //     ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value +
  //     ' and indCta eq ' + '\'' + 'A' + '\'' + '&$orderby=codCta')
  //     .subscribe(ref => this.loadPlanoContas(ref.value),
  //       error => this.httpService.handleError(error, () => this.contaContabilChanged()),
  //       () => this.httpService.done());
  // }


  // loadPlanoContas(array: Array<PlanoContas>) {

  //   this.opcoesPlanoContas = [];
  //   this.opcoesPlanoContas.push({ label: 'Selecionar Conta', value: null });

  //   array.forEach(element => {
  //     if (element.indCta === 'A') {
  //       this.opcoesPlanoContas.push({ label: element.codCta + ' - ' + element.descrCta, value: element.id });
  //     }
  //   });
  // }


  // contaContabilItemChanged() {
  //   this.httpService.wait();
  //   this.httpService.get('/planocontas?$select=id,codCta,descrCta,indCta,codNaturezaConta/cod' +
  //     '&$filter=ativo eq true and empresa/id eq ' + this.dblinkedSessionService.empresa.id +
  //     ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value +
  //     ' and indCta eq ' + '\'' + 'A' + '\'' + '&$orderby=codCta')
  //     .subscribe(ref => this.loadPlanoContasItem(ref.value),
  //       error => this.httpService.handleError(error, () => this.contaContabilItemChanged()),
  //       () => this.httpService.done());
  // }


  // loadPlanoContasItem(array: Array<PlanoContas>) {

  //   this.opcoesPlanoContasItem = [];
  //   this.opcoesPlanoContasItem.push({ label: 'Selecionar Conta', value: null });

  //   array.forEach(element => {
  //     if (element.indCta === 'A') {
  //       this.opcoesPlanoContasItem.push({ label: element.codCta + ' - ' + element.descrCta, value: element.id });
  //     }
  //   });
  // }


  // participanteChanged() {
  //   this.httpService.wait();
  //   this.httpService.get('/participante?$select=id,nome' +
  //     '&$filter=participanteEmpresas/empresaId eq ' + this.dblinkedSessionService.empresa.id +
  //     '&$orderby=nome')
  //     .subscribe(ref => this.loadParticipante(ref.value),
  //       error => this.httpService.handleError(error, () => this.participanteChanged()),
  //       () => this.httpService.done());
  // }


  // loadParticipante(array: Array<Participante>) {

  //   this.opcoesParticipante = [];
  //   this.opcoesParticipante.push({ label: 'Selecionar Participante', value: null });

  //   array.forEach(element => {
  //     this.opcoesParticipante.push({ label: element.nome, value: element.id });
  //   });
  // }


  moedaChanged() {
    this.httpService.wait();
    this.httpService.get('/moeda?$select=id,codigo,descricao' +
      '&$filter=id gt 3&$orderby=codigo')
      .subscribe(ref => this.loadMoeda(ref.value),
        error => this.httpService.handleError(error, () => this.moedaChanged()),
        () => this.httpService.done());
  }


  loadMoeda(array: Array<Moeda>) {

    this.opcoesMoeda = [];
    this.opcoesMoeda.push({ label: 'BRL', value: 1 });
    this.opcoesMoeda.push({ label: 'USD', value: 2 });
    this.opcoesMoeda.push({ label: 'EUR', value: 3 });
    this.opcoesMoeda.push({ label: '------------', value: null });

    array.forEach(element => {
      this.opcoesMoeda.push({ label: element.codigo, value: element.id });
    });

  }

  displayEditFaturaDialog() {
    this.editFatura                  = true;
    this.modalFatura                 = true;
    this.botaoNovaFatura             = false;
    this.isSalvarESolicitarAprovacao = false;
    this.contaSelecionada = null
    this.planoConta = new PlanoContas()
    this.labelCabecalhoFatura = '';
    this.labelCabecalhoFatura = this.iconesSelecionado.toString() === '1' ? ' do Cartao de Crédito' :
      this.iconesSelecionado.toString() === '2' ? ' do RDV' :
        this.iconesSelecionado.toString() === '4' ? ' da Despesa Direta' : ' da Nota de Débito';
    // this.contaContabilChanged();
    this.tipoFaturaChanged();
    this.carregarAprovadorTecnico();
    this.httpService.wait();
    this.httpService.get('/custom/fatura/get-faturas-por-id(' + this.faturaId + ','+ this.sessionService.loggedUser.id + ')')
      .subscribe(faturaPorId => this.loadFaturaPorId(faturaPorId),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());

  }

  loadFaturaPorId(value: Array<any>) {

    value.forEach(element => {

      this.faturaId = element.id;
      this.nomeFavorecido = element.nome_favorecido;
      this.numeroCartao = element.numero;
      this.key = element.key;
      this.dtVencimento = element.data_vencimento;
      this.dtFechamento = element.data_fechamento;
      this.contaSelecionada = element.planoContasId;
      this.tipoFaturaSelecionada = element.tipo_fatura;
      this.financeiroPorItem = element.financeiroporitem;
      this.aprovadorFatura   = element.usuarioAprovadorId;
      this.isSameDepto       = element.isSameDepto;


    });

  }




  displayFaturaDialog() {
    this.modalFatura = true;
    this.numeroCartao = null;
    this.nomeFavorecido = '';
    this.key = '';
    this.dtVencimento = new Date();
    this.dtFechamento = new Date();
    // this.contaContabilChanged();
    this.tipoFaturaChanged();
    this.tipoFaturaSelecionada = null;
    this.contaSelecionada = null;
    this.financeiroPorItem = false;
    this.tipoFaturaSelecionada = this.iconesSelecionado;
    this.faturaId = null;
    this.labelCabecalhoFatura = '';
    this.labelCabecalhoFatura = this.iconesSelecionado.toString() === '1' ? ' do Cartao de Crédito' :
      this.iconesSelecionado.toString() === '2' ? ' do RDV' :
        this.iconesSelecionado.toString() === '4' ? ' da Despesa Direta' : ' da Nota de Débito';
    this.botaoNovaFatura = true;
    this.carregarAprovadorTecnico();
    this.aprovadorFatura = null;

  }

  visualizarFaturaItem(event: any, readOnly: boolean) {

    this.modalFaturaItem = true;
    this.editFaturaItem = true;
    this.faturaItem = new FaturaItem();
    this.faturaItem.participante = new Participante();
    this.faturaItem.participante.id = null;

    this.faturaItem.planoContas = new PlanoContas();
    this.faturaItem.planoContas.id = null;

    this.faturaItem.moeda = new Moeda();
    this.faturaItem.moeda.id = null;

    this.faturaItem.authUser = new AuthUser();
    this.faturaItem.authUser.id = null;

    this.faturaAtual.faturaItem = new Array();
    this.faturaAtual.faturaItemComplemento = new Array();


    this.faturaItem.id = event.id;
    this.faturaItem.dataCompra = new Date();
    // this.participanteChanged();
    this.participante = new Participante()
    this.planoConta = new PlanoContas()
    // this.contaContabilItemChanged();
    this.moedaChanged();
    this.faturaAtual.id = event.id;

    this.numero = 0;
    this.numero = event.numero;
    this.contadorItem = 0;
    this.itemComplemento = false;

    /*
      TODO: REMOVER COMPARAÇÃO COM DESCRIÇÃO
    */
    if (event.status === 'EM ABERTO') {
      this.status = 1;
      this.dataContabilizacao = new Date();
    } else if (event.status === 'CONTABILIZADO') {
      this.status = 2;
    } else if (event.status === 'ENCERRADO') {
      this.status = 3;
    } else if (event.status === 'CONTABILIZADO PARCIAL') {
      this.status = 4;
    } else if (event.status === 'AGUARDANDO APROVAÇÃO') {
      this.status = 5;
    } else if (event.status === 'APROVADO') {
      this.status = 6;
    } else if (event.status === 'REPPROVADO') {
      this.status = 7;
    }

    this.valorTotalItem = 0;
    this.valorTotalItemCompl = 0;

    this.httpService.wait();
    this.httpService.get('/custom/fatura/get-faturasItens(' + this.faturaAtual.id + ')')
      .subscribe(pedidos => this.loadFaturaItens(pedidos),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());

  }

  loadFaturaItens(value: Array<any>) {
    this.carregaValorTotalitens(0);

    value.forEach(element => {
      const participante = new Participante();
      const planoContas = new PlanoContas();
      const moeda = new Moeda();

      participante.id = element.participanteId;
      element.participante = participante;
      planoContas.id = element.planoContasId;
      element.planoContas = planoContas;
      moeda.id = element.moedaId;
      moeda.codigo = element.codigoMoeda;
      element.moeda = moeda;
      this.userLista = new AuthUser();
      this.userLista.id = this.sessionService.loggedUser.id;
      element.authUser = this.userLista;
      element.dataCompra = this.utilService.dateWithoutTimeZone(element.dataCompra);
      element.qtdeParcelas = element.qtde_parcelas;
      element.valorParcela = this.utilService.parseDecimalString6(element.valorParcela);
      this.dataContabilizacao = this.utilService.dateWithoutTimeZone(element.dataContabilizacao);
      if (element.dataContabilizacao === null) {
        this.dataContabilizacao = new Date();
      }
      if (element.creditoEstorno === 'R') {
        element.valorParcela = (element.valorParcela).replace(',', '.') * - 1;
        element.valorParcela = this.utilService.parseDecimalString6(element.valorParcela);
      }


      this.faturaItem = new FaturaItem(element);

      if (element.complemento === false) {
        this.faturaAtual.faturaItem.push(this.faturaItem);
      } else {
        this.faturaAtual.faturaItemComplemento.push(this.faturaItem);
      }

    });
  }

  carregaValorTotalitens(isComplemento: number) {
    this.httpService.wait();
    this.httpService.get('/custom/fatura/get-faturasValorTotalItens(' + this.faturaAtual.id + ',' + isComplemento + ')')
      .subscribe(total => this.loadfaturasValorTotalItens(total),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());

  }

  loadfaturasValorTotalItens(value: Array<any>) {
    value.forEach(element => {
      if (this.itemComplemento === false) {
        this.valorTotalItem = 0;
        this.valorTotalItem = element.valorTotal;
      } else {
        this.valorTotalItemCompl = 0;
        this.valorTotalItemCompl = element.valorTotal;
      }
    });

  }



  displayComplemento() {
    this.labelTipoFatura = '';
    this.labelTipoFatura = this.faturaSelecionados[0].tipo_fatura === 1 ? 'Complemento Cartao de Crédito' :
      this.faturaSelecionados[0].tipo_fatura === 2 ? 'Complemento RDV' :
        this.faturaSelecionados[0].tipo_fatura === 4 ? 'Despesa Direta' : 'Complemento Nota de Débito';

    this.carregaValorTotalitens(1);
    this.modalFaturaItemComplemento = true;
    this.itemComplemento = true;
  }


  displayDadosFaturaItemDialog() {


    this.msgsModalTaxa = [];
    if (((this.status === 2 || this.status === 3 || this.status === 4) && !this.itemComplemento)
      || ((this.status === 1 || this.status === 3) && this.itemComplemento)) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Status da Fatura não permite a inclusão de itens!'
      });
      return;
    }

    this.modalDadosFaturaItem = true;
    this.alteraItem = false;
    this.faturaItem = new FaturaItem();
    this.faturaItem.participante = new Participante();
    this.faturaItem.participante.id = null;

    this.faturaItem.planoContas = new PlanoContas();
    this.faturaItem.planoContas.id = null;

    this.faturaItem.moeda = new Moeda();
    this.faturaItem.moeda.id = null;

    this.faturaItem.authUser = new AuthUser();
    this.faturaItem.authUser.id = null;

    this.faturaItem.dataCompra = new Date();
    this.participanteSelecionada = null;
    this.contaSelecionadaItem = null;
    this.moedaSelecionadaItem = null;

    this.faturaItem.historico = null;
    this.faturaItem.qtdeParcelas = null;
    this.faturaItem.valorParcela = null;

    this.creditoEstornoValue = '';
    this.moedaSelecionadaItem = 1;
    const date = new Date();
    this.nameFileUpload = '';
    this.nameFileUpload = date.getTime().toString();
    this.files = new Array();

  }



  addTaxa(tipoDaFatura: number) {
    if ((tipoDaFatura === 1) || (tipoDaFatura === 3)) {
      this.salvarContabilizar(tipoDaFatura);
    } else {
      this.modalTaxas = true;
      this.moedaSelecionadaItemTaxa = null;
      this.moedasitens = new Array<Moeda>();
      this.getMoedaFaturaItens();
    }
  }

  getMoedaFaturaItens() {
    this.httpService.get('/custom/fatura/get-moedaFaturaItens(' + this.faturaAtual.id + ',' + this.itemComplemento + ')')
      .subscribe(moedasitens => this.loadMoedaFaturaItens(moedasitens),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());



  }

  loadMoedaFaturaItens(value: Array<any>) {
    this.moedasitens = value;
    value.forEach(element => {
      if (element.dataLcto !== null) {
        this.dataContabilizacao = element.dataLcto;
      }
    });

  }


  addItemRateio() {
    /*if (this.faturaItem.faturaRateio) {
      if (this.faturaItem.faturaRateio.length > 0) {
        this.editarCentroCusto();
        this.controlCentroCusto();
      } else {*/
    if (this.faturaItem.faturaRateio !== undefined) {
      this.modalCentroCusto = true;
      this.controlCentroCusto(0);
    } else {
      this.modalCentroCusto = true;
      this.faturaItem.faturaRateio = new Array();
      this.controlCentroCusto(0);
    }
    /*}
  }*/
  }

  addItemLista() {

    if (((this.status === 2 || this.status === 3 || this.status === 4) && !this.itemComplemento) ||
      ((this.status === 1 || this.status === 3) && this.itemComplemento)) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Status da Fatura não permite esta ação' });
      return;
    }


    if ((this.faturaItem.historico === null) || (this.faturaItem.historico === '')) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'historico não pode ser vazio. Verifique!'
      });
      return;

    }

    if (this.contaSelecionadaItem === null) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Conta Contabil não pode ser vazio. Verifique!'
      });
      return;
    } else

      if (this.moedaSelecionadaItem === null) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Moeda não pode ser vazio. Verifique!'
        });
        return;
      } else


        if (!(this.utilService.parseDecimal6(this.faturaItem.valorParcela) > 0.00)) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Valor da Compra não pode ser zeros ou vazio. Verifique!'
          });
          return;
        } else if ((!((this.faturaItem.qtdeParcelas) > 0)) && (!this.isDepesaDireta)) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Quantidade da Parcela não pode ser zeros ou vazio. Verifique!'
          });
          return;
        } else if (this.creditoEstornoValue === '') {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Crédito ou Estorno não pode ser vazio. Verifique!'
          });
          return;
        }


    // validação centro de custo @Guilherme
    if(this.centroCustoObrigatorio  &&
       (isNullOrUndefined(this.faturaItem.faturaRateio) || this.faturaItem.faturaRateio.length < 1)){
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Para prosseguir, Insira um Centro de Custo!'
      });
      return;
    }


    this.faturaItem.participante.id = this.participanteSelecionada;
    this.faturaItem.planoContas.id = this.contaSelecionadaItem;
    this.faturaItem.moeda.id = this.moedaSelecionadaItem;
    const codMoeda = this.opcoesMoeda.find(o => o.value === this.moedaSelecionadaItem).label;
    this.faturaItem.moeda.codigo = codMoeda;
    if (!this.alteraItem) {
      this.contadorItem++;
    }
    this.faturaItem.id = this.contadorItem;


    this.userLista = new AuthUser();
    this.userLista.id = this.sessionService.loggedUser.id;

    this.faturaItem.authUser = this.userLista;
    this.faturaItem.creditoEstorno = this.creditoEstornoValue.substring(0, 1);
    this.faturaItem.chaveUploadArquivo = this.nameFileUpload.toString();
    if (this.files.length > 0) {
      this.faturaItem.contemArquivo = 1;
    }
    this.faturaItem.complemento = this.itemComplemento;

    if (this.isDepesaDireta) {
      this.faturaItem.qtdeParcelas = 1;
      if (this.faturaItem.taxa === undefined) {
        this.faturaItem.taxa = 1;
      } else {
        this.faturaItem.taxa = this.utilService.parseDecimal6(this.faturaItem.taxa);
      }
    } else {
      this.faturaItem.taxa = 0;
    }
    this.faturaItem.addItem = true;

    if (this.creditoEstornoValue.substring(0, 1) === 'R') {
      this.faturaItem.valorParcela = (this.faturaItem.valorParcela).replace(',', '.') * - 1;
    }

    if (this.itemComplemento === false) {
      this.valorTotalItem = 0;
      this.faturaAtual.faturaItem.push(this.faturaItem);
    } else {
      this.valorTotalItemCompl = 0;
      this.faturaAtual.faturaItemComplemento.push(this.faturaItem);
    }
    this.recalculaValorTotalItem();

    if (!this.alteraItem) {
      this.limpaLista();
    }
    this.modalDadosFaturaItem = false;

    const item = {
      id: this.faturaAtual.id,
      faturaItem: this.faturaAtual.faturaItem[this.faturaAtual.faturaItem.length - 1],
      itemComplemento: false
    };

    this.httpService.wait();
    this.httpService.post('/custom/fatura/criar-item', item)
      .subscribe(result => { },
        error => this.httpService.handleError(error, () => this.salvarFaturaItem()),
        () => this.httpService.done());
  }

  recalculaValorTotalItem() {
    if (this.itemComplemento === false) {
      if (this.faturaAtual.faturaItem.length === 0) {
        this.valorTotalItem = 0;
      }

      this.faturaAtual.faturaItem.forEach(element => {
        if (this.isDepesaDireta) {
          this.faturaItem.qtdeParcelas = 1;
          this.valorTotalItem = this.utilService.parseDecimal(this.valorTotalItem) +
            (this.utilService.parseDecimal(element.taxa) *
              this.utilService.parseDecimal(element.valorParcela));
        } else {
          this.valorTotalItem = this.utilService.parseDecimal(this.valorTotalItem) +
            this.utilService.parseDecimal(element.valorParcela);
        }

      });
    } else {
      if (this.faturaAtual.faturaItemComplemento.length === 0) {
        this.valorTotalItemCompl = 0;
      }

      this.faturaAtual.faturaItemComplemento.forEach(element => {
        if (this.isDepesaDireta) {
          this.faturaItem.qtdeParcelas = 1;
          this.valorTotalItemCompl = this.utilService.parseDecimal(this.valorTotalItemCompl) +
            (this.utilService.parseDecimal(element.taxa) *
              this.utilService.parseDecimal(element.valorParcela));
          this.faturaItem.taxa = this.utilService.parseDecimal6(element.taxa);
        } else {
          this.faturaItem.taxa = 0;
          this.valorTotalItemCompl = this.utilService.parseDecimal(this.valorTotalItemCompl) +
            this.utilService.parseDecimal(element.valorParcela);
        }
      });

    }

  }

  limpaLista() {
    this.faturaItem = new FaturaItem();
    this.participanteSelecionada = null;
    this.contaSelecionadaItem = null;
    this.moedaSelecionadaItem = null;
    this.faturaItem = new FaturaItem();
    this.faturaItem.participante = new Participante();
    this.faturaItem.participante.id = null;

    this.faturaItem.planoContas = new PlanoContas();
    this.faturaItem.planoContas.id = null;

    this.faturaItem.moeda = new Moeda();
    this.faturaItem.moeda.id = null;
    this.faturaItem.dataCompra = new Date();
  }




  onTabChange(tabIndex) {
    this.codigoAbas = 0;
    this.codigoAbas = tabIndex;

    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirFaturaView());
    this.toolbarService.alterarStatus('print', true, 'Imprimir Fatura', () => this.imprimirFatura());
    this.toolbarService.alterarStatus('edit', true, 'Listar Itens', () => this.visualizarFaturaItem(event, true));
    this.toolbarService.alterarStatus('export', true, 'Editar Fatura', () => this.displayEditFaturaDialog());
    this.toolbarService.alterarStatus('check', true, 'Aprovar', () => this.aprovarLote());
    this.toolbarService.alterarStatus('mail', true, 'Solicitar', () => this.solicitarAprovacaoLote());

    this.idFaturaView = new Array();
    this.idFaturaExcluirView = new Array();
    this.checkedAll = false;
    this.checkedAllContab = false;
    this.checkedAllEncerrado = false;

    this.configuraCheckFaturaAberta();
    this.configuraCheckFaturaContabilizada();
    this.configuraCheckFaturaEncerrada();
    this.configuraCheckFaturaAguardandoAprovacao();
    this.configuraCheckFaturaAprovado();
    this.configuraCheckFaturaReprovado();
  }

  configuraCheckFaturaAberta() {
    const faturaAbertaAux: Array<any> = new Array();
    this.faturaAberta.forEach(element => {
      element.selected = false;
      faturaAbertaAux.push(element);
    });
    this.faturaAberta = new Array();
    this.faturaAberta = faturaAbertaAux;
  }

  //Aguardando aprovacao
  configuraCheckFaturaAguardandoAprovacao() {
    const faturaAbertaAguradandoAprovacaoAux: Array<any> = new Array();
    this.faturaAguardandoAprovacao.forEach(element => {
      element.selected = false;
      faturaAbertaAguradandoAprovacaoAux.push(element);
    });
    this.faturaAguardandoAprovacao = new Array();
    this.faturaAguardandoAprovacao = faturaAbertaAguradandoAprovacaoAux;
  }

  //Aprovado
  configuraCheckFaturaAprovado() {

    const faturaAprovadoAux: Array<any> = new Array();
    this.faturaAprovado.forEach(element => {
      element.selected = false;
      faturaAprovadoAux.push(element);
    });
    this.faturaAprovado = new Array();
    this.faturaAprovado = faturaAprovadoAux;

  }

  //Reprovado
  configuraCheckFaturaReprovado() {
    const faturaReprovadoAux: Array<any> = new Array();
    this.faturaReprovado.forEach(element => {
      element.selected = false;
      faturaReprovadoAux.push(element);
    });
    this.faturaReprovado = new Array();
    this.faturaReprovado = faturaReprovadoAux;
  }

  configuraCheckFaturaContabilizada() {
    const faturaContabilizadaAux: Array<any> = new Array();
    this.faturaCont.forEach(element => {
      element.selected = false;
      faturaContabilizadaAux.push(element);
    });
    this.faturaCont = new Array();
    this.faturaCont = faturaContabilizadaAux;
  }

  configuraCheckFaturaEncerrada() {
    const faturaEncerradaAux: Array<any> = new Array();
    this.faturaEnc.forEach(element => {
      element.selected = false;
      faturaEncerradaAux.push(element);
    });
    this.faturaEnc = new Array();
    this.faturaEnc = faturaEncerradaAux;
  }

  selectTodosEncerrado(event: any) {
    this.checkedAllEncerrado = !this.checkedAllEncerrado;
    this.idFaturaView = new Array();
    this.idFaturaExcluirView = new Array();

    if (this.codigoAbas === 2) {
      this.faturaEnc.forEach(pc => {
        if (this.checkedAllEncerrado === false) {
          pc.selected = false;
          this.idFaturaView.slice(pc.id, 1);
          this.idFaturaExcluirView.slice(pc.id, 1);
        } else {
          pc.selected = true;
          this.idFaturaView.push(pc.id);
          this.idFaturaExcluirView.push(pc);
        }
      });
    }
    this.configuraToolBar();
  }


  selectTodosContab(event: any) {
    this.checkedAllContab = !this.checkedAllContab;
    this.idFaturaView = new Array();
    this.idFaturaExcluirView = new Array();

    if (this.codigoAbas === 1) {
      this.faturaCont.forEach(pc => {
        if (this.checkedAllContab === false) {
          pc.selected = false;
          this.idFaturaView.slice(pc.id, 1);
          this.idFaturaExcluirView.slice(pc.id, 1);
        } else {
          pc.selected = true;
          this.idFaturaView.push(pc.id);
          this.idFaturaExcluirView.push(pc);
        }
      });
    }
    this.configuraToolBar();
  }

  selectTodosAberta(event: any) {
    this.checkedAll = !this.checkedAll;
    this.idFaturaView = new Array();
    this.idFaturaExcluirView = new Array();

    if (this.codigoAbas === 0) {
      this.faturaAberta.forEach(pc => {
        if (this.checkedAll === false) {
          pc.selected = false;
          this.idFaturaView.slice(pc.id, 1);
          this.idFaturaExcluirView.slice(pc.id, 1);
        } else {
          pc.selected = true;
          this.idFaturaView.push(pc.id);
          this.idFaturaExcluirView.push(pc);
        }
      });
    } else if (this.codigoAbas === 1) {
      this.faturaAguardandoAprovacao.forEach(pc => {
        if (this.checkedAll === false) {
          pc.selected = false;
          this.idFaturaView.slice(pc.id, 1);
          this.idFaturaExcluirView.slice(pc.id, 1);
        } else {
          pc.selected = true;
          this.idFaturaView.push(pc.id);
          this.idFaturaExcluirView.push(pc);
        }
      });
    } else if (this.codigoAbas === 2) {
      this.faturaAprovado.forEach(pc => {
        if (this.checkedAll === false) {
          pc.selected = false;
          this.idFaturaView.slice(pc.id, 1);
          this.idFaturaExcluirView.slice(pc.id, 1);
        } else {
          pc.selected = true;
          this.idFaturaView.push(pc.id);
          this.idFaturaExcluirView.push(pc);
        }
      });
    } else if (this.codigoAbas === 3) {
      this.faturaReprovado.forEach(pc => {
        if (this.checkedAll === false) {
          pc.selected = false;
          this.idFaturaView.slice(pc.id, 1);
          this.idFaturaExcluirView.slice(pc.id, 1);
        } else {
          pc.selected = true;
          this.idFaturaView.push(pc.id);
          this.idFaturaExcluirView.push(pc);
        }
      });
    } else if (this.codigoAbas === 4) {
      this.faturaCont.forEach(pc => {
        if (this.checkedAll === false) {
          pc.selected = false;
          this.idFaturaView.slice(pc.id, 1);
          this.idFaturaExcluirView.slice(pc.id, 1);
        } else {
          pc.selected = true;
          this.idFaturaView.push(pc.id);
          this.idFaturaExcluirView.push(pc);
        }
      });
    } else if (this.codigoAbas === 5) {
      this.faturaEnc.forEach(pc => {
        if (this.checkedAll === false) {
          pc.selected = false;
          this.idFaturaView.slice(pc.id, 1);
          this.idFaturaExcluirView.slice(pc.id, 1);
        } else {
          pc.selected = true;
          this.idFaturaView.push(pc.id);
          this.idFaturaExcluirView.push(pc);
        }
      });
    }

    this.configuraToolBar();
  }

  configuraToolBar() {
    if (this.idFaturaView.length > 0) {
      this.toolbarService.alterarStatus('remove', false, 'Excluir todos!', () => this.excluirFaturaView());
      if(this.codigoAbas === 0){
        this.toolbarService.alterarStatus('mail', false, 'Solicitar Aprovação', () => this.solicitarAprovacaoLote());
      } else if(this.codigoAbas === 1){
        this.toolbarService.alterarStatus('check', false, 'Aprovar', () => this.aprovarLote());
      }else{
        this.toolbarService.alterarStatus('check', true, 'Aprovar', () => this.displayEditFaturaDialog());
        this.toolbarService.alterarStatus('mail', true, 'Solicitar Aprovação', () => this.displayEditFaturaDialog());
      }
    } else {
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirFaturaView());
      this.toolbarService.alterarStatus('check', true, 'Aprovar', () => this.displayEditFaturaDialog());
      this.toolbarService.alterarStatus('mail', true, 'Solicitar Aprovação', () => this.displayEditFaturaDialog());
      // TODO
    }
    this.toolbarService.alterarStatus('print', true, 'Imprimir Fatura', () => this.imprimirFatura());
    this.toolbarService.alterarStatus('edit', true, 'Listar Itens', () => this.visualizarFaturaItem(event, true));
    this.toolbarService.alterarStatus('export', true, 'Editar Fatura', () => this.displayEditFaturaDialog());
  }

  excluirFaturaView() {
    if (confirm('Deseja realmente excluir esta Fatura?')) {

      const faturaIds = new Array();
      this.idFaturaExcluirView.forEach(element => {
        faturaIds.push(element.id);
      });

      const fatura = {
        idFatura: faturaIds
      };

      this.httpService.wait();
      this.httpService.post('/custom/fatura/delete-fatura', fatura)
        .subscribe(result => {
          this.messageService.add({
            severity: result.severity,
            summary: result.summary,
            detail: result.detail
          });
          this.listar(this.iconesSelecionado);

          this.faturaId = null;

        },
          error => this.httpService.handleError(error, () => this.excluirFaturaView()),
          () => this.httpService.done());

      this.idFaturaExcluirView.forEach(element => {
        const index = this.idFaturaView.findIndex(vw => vw === element.id);
        this.idFaturaView.splice(index, 1);
      });
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirFaturaView());
      this.toolbarService.alterarStatus('print', true, 'Imprimir Fatura', () => this.imprimirFatura());
      this.toolbarService.alterarStatus('edit', true, 'Listar Itens', () => this.visualizarFaturaItem(event, true));
      this.toolbarService.alterarStatus('export', true, 'Editar Fatura', () => this.displayEditFaturaDialog());
    }
  }



  selectCheckbox(id: any, event: any) {
    // manipulando listas
    if (event.selected) {
      event.selected = true;
      // impressão de pedido
      const index = this.idFaturaView.findIndex(vw => vw === id);
      this.idFaturaView.splice(index, 1);

      // controle select todos
      this.faturaSelecionados.forEach(element => {
        if (element.id === event.id) {
          element.selected = true;
        }
      });
      this.idFaturaExcluirView.splice(this.idFaturaExcluirView.findIndex(e => e.id.toString() === event.id.toString()), 1);
    } else {

      this.idFaturaView.push(id);
      this.idFaturaExcluirView.push(event);
      event.selected = false;
      const i = this.faturaSelecionados.findIndex(p => p.id === id);
      if (i === -1) {
        this.faturaSelecionados.push(event);
      }
    }

    if (this.idFaturaExcluirView.length > 0) {
      this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.excluirFaturaView());
    } else {
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirFaturaView());

    }
    this.labelTipoFatura = '';
    this.labelTipoFatura = this.iconesSelecionado.toString() === '1' ? 'Extrato Cartao de Crédito' :
      this.iconesSelecionado.toString() === '2' ? 'Extrato RDV' :
        this.iconesSelecionado.toString() === '4' ? 'Despesa Direta' : 'Extrato Nota de Débito';

    if (this.idFaturaExcluirView.length === 1) {
      this.toolbarService.alterarStatus('print', false, 'Imprimir Fatura', () => this.imprimirFatura());
      this.faturaId = this.idFaturaExcluirView[0].id;
      this.toolbarService.alterarStatus('edit', false, 'Listar Itens', () => this.visualizarFaturaItem(event, true));
      this.toolbarService.alterarStatus('export', false, 'Editar Fatura', () => this.displayEditFaturaDialog());
      if(this.codigoAbas === 0){
        this.toolbarService.alterarStatus('mail', false, 'Solicitar Aprovação', () => this.solicitarAprovacaoLote());
      } else if(this.codigoAbas === 1){
        this.toolbarService.alterarStatus('check', false, 'Aprovar', () => this.aprovarLote());
      }else{
        this.toolbarService.alterarStatus('check', true, 'Aprovar', () => this.displayEditFaturaDialog());
      this.toolbarService.alterarStatus('mail', true, 'Solicitar Aprovação', () => this.displayEditFaturaDialog());
      }

    } else {
      this.toolbarService.alterarStatus('print', true, 'Imprimir Fatura', () => this.imprimirFatura());
      this.toolbarService.alterarStatus('edit', true, 'Listar Itens', () => this.visualizarFaturaItem(event, true));
      this.toolbarService.alterarStatus('export', true, 'Editar Fatura', () => this.displayEditFaturaDialog());
      this.toolbarService.alterarStatus('check', true, 'Aprovar', () => this.displayEditFaturaDialog());
      this.toolbarService.alterarStatus('mail', true, 'Solicitar Aprovação', () => this.displayEditFaturaDialog());

    }

   if (this.idFaturaExcluirView.length > 0 && this.codigoAbas === 0 ) {
      this.toolbarService.alterarStatus('mail', false, 'Solicitar Aprovação', () => this.solicitarAprovacaoLote());
   } else if (this.idFaturaExcluirView.length > 0 && this.codigoAbas === 1 ) {
    this.toolbarService.alterarStatus('check', false, 'Aprovar', () => this.aprovarLote());
  } else {
      this.toolbarService.alterarStatus('mail', true, 'Solicitar Aprovação', () => this.solicitarAprovacaoLote());
      this.toolbarService.alterarStatus('check', true, 'Aprovar', () => this.aprovarLote());
   }



  }


  itemOnRowSelect(event: any, origin: any) {
    this.alteraItem = true;
    this.modalDadosFaturaItem = true;
    if (event.creditoEstorno === 'D') {
      this.creditoEstornoValue = 'Despesa';
    } else {
      this.creditoEstornoValue = 'Receita/Estorno';
    }

    this.indexTab = origin;

    this.faturaItem = new FaturaItem(event);
    this.faturaItem.faturaRateio = new Array<FaturaRateio>();
    this.faturaItem.valorParcela = this.utilService.parseDecimalString(this.faturaItem.valorParcela);
    this.faturaItem.valorParcela = this.faturaItem.valorParcela.replace('-', '');

    if (this.isDepesaDireta) {
      this.faturaItem.qtdeParcelas = 1;
      this.faturaItem.taxa = this.utilService.parseDecimalString6(this.faturaItem.taxa);
    } else {
      this.faturaItem.taxa = 0;
    }
    this.faturaItem.dataCompra = this.utilService.dateWithoutTimeZone(event.dataCompra);
    this.faturaItem.participante = new Participante(event.participante);
    this.faturaItem.planoContas = new PlanoContas(event.planoContas);
    this.faturaItem.moeda = new Moeda(event.moeda);
    this.chaveUpload = '';
    this.chaveUpload = this.faturaItem.chaveUploadArquivo;

    this.participanteSelecionada = this.faturaItem.participante.id;
    this.contaSelecionadaItem = this.faturaItem.planoContas.id;
    this.moedaSelecionadaItem = this.faturaItem.moeda.id;

    this.contadorItem = this.faturaItem.id;
    if (!this.faturaItem.addItem) {
      this.carregaItemRateio(event.id);
    }
    this.files = new Array();
    this.getFiles();


  }

  carregaItemRateio(idItem: number) {
    this.httpService.wait();
    this.httpService.get('/custom/fatura/get-faturasItensRateio(' + idItem + ')')
      .subscribe(rateio => this.loadFaturaItensRateio(rateio),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());
  }

  loadFaturaItensRateio(value: Array<any>) {
    value.forEach(element => {
      const cadProj = new CadProj();
      cadProj.codProj = element.cod_proj;
      cadProj.descrProj = element.descr_proj;
      cadProj.id = element.cad_projId;
      element.cadProj = cadProj;



      const cadCcus = new CadCcus();
      cadCcus.codCcus = element.cod_ccus;
      cadCcus.descrCcus = element.descr_ccus;
      cadCcus.id = element.cad_ccusId;
      element.cadCcus = cadCcus;
      this.cc = cadCcus;

      this.faturaItem.faturaRateio.push(element);
    });

  }


  exluirItemLista() {
    // removendo item selecionado
    if (confirm('Deseja excluir este item?')) {


      if (((this.status === 2 || this.status === 3 || this.status === 4) && !this.itemComplemento)
        || ((this.status === 1 || this.status === 3) && this.itemComplemento)) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Status da Fatura não permite a inclusão de itens!'
        });
        return;
      }

      if (!this.itemComplemento) {
        const index = this.faturaAtual.faturaItem.findIndex(c => c.id === this.faturaItem.id);
        this.faturaAtual.faturaItem.splice(index, 1);
        this.modalDadosFaturaItem = false;
        this.valorTotalItem = 0;
      } else {
        const index = this.faturaAtual.faturaItemComplemento.findIndex(c => c.id === this.faturaItem.id);
        this.faturaAtual.faturaItemComplemento.splice(index, 1);
        this.modalDadosFaturaItem = false;
        this.valorTotalItemCompl = 0;
      }
      this.recalculaValorTotalItem();

    }


  }

  exluirItemListaGrid(rowValue: any) {

    if (confirm('Deseja excluir este item?')) {
      this.httpService.wait();

      this.httpService.post('/custom/fatura/excluir-item(' + rowValue.id.toString() + ', ' + rowValue.complemento.toString() + ')')
        .subscribe(result => { },
          error => this.httpService.handleError(error, () => { }),
          () => this.httpService.done());

      if (((this.status === 2 || this.status === 3 || this.status === 4) && !this.itemComplemento)
        || ((this.status === 1 || this.status === 3) && this.itemComplemento)) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Status da Fatura não permite a inclusão de itens!'
        });
        return;
      }

      if (!this.itemComplemento) {
        const index = this.faturaAtual.faturaItem.findIndex(c => c.id === rowValue.id);
        this.faturaAtual.faturaItem.splice(index, 1);
        this.valorTotalItem = 0;

      } else {
        const index = this.faturaAtual.faturaItemComplemento.findIndex(c => c.id === rowValue.id);
        this.faturaAtual.faturaItemComplemento.splice(index, 1);
        this.valorTotalItemCompl = 0;
      }
      this.recalculaValorTotalItem();

    }
  }

  alterarItemLista() {
    if (((this.status === 2 || this.status === 3 || this.status === 4) && !this.itemComplemento)
      || ((this.status === 1 || this.status === 3) && this.itemComplemento)) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Status da Fatura não permite a inclusão de itens!'
      });
      return;
    }

    if (this.creditoEstornoValue.substring(0, 1) === 'R') {
      this.faturaItem.valorParcela = (this.faturaItem.valorParcela).replace(',', '.') * - 1;
    }

    if(this.centroCustoObrigatorio  &&
      (isNullOrUndefined(this.faturaItem.faturaRateio) || this.faturaItem.faturaRateio.length < 1)){
     this.messageService.add({
       severity: 'warn',key: 'messageLinnks',
       summary: 'MSG',
       detail: 'Para prosseguir, Insira um Centro de Custo!'
      });
     return;
   }

    if (this.itemComplemento === false) {
      // removendo item selecionado
      const index = this.faturaAtual.faturaItem.findIndex(c => c.id === this.faturaItem.id);
      this.faturaItem.creditoEstorno = this.creditoEstornoValue.substring(0, 1);
      this.faturaItem.moeda.id = this.moedaSelecionadaItem;
      const codMoeda = this.opcoesMoeda.find(o => o.value === this.moedaSelecionadaItem).label;
      this.faturaItem.moeda.codigo = codMoeda;
      if (this.files.length > 0) {
        this.faturaItem.contemArquivo = 1;
      }
      this.faturaAtual.faturaItem.splice(index, 1, this.faturaItem);
      this.valorTotalItem = 0;
      this.recalculaValorTotalItem();

      this.faturaItem = new FaturaItem();
      const item = {
        itemId: this.faturaAtual.faturaItem[index].id,
        faturaItem: this.faturaAtual.faturaItem[index],
        itemComplemento: false,
        usuario: this.faturaAtual.faturaItem[index].authUser.id,
      };

      this.httpService.wait();
      this.httpService.post('/custom/fatura/atualizar-linha-item', item)
        .subscribe(result => { },
          error => this.httpService.handleError(error, () => this.salvarFaturaItem()),
          () => this.httpService.done());

    } else {
      // removendo item selecionado
      const index = this.faturaAtual.faturaItemComplemento.findIndex(c => c.id === this.faturaItem.id);
      this.faturaItem.creditoEstorno = this.creditoEstornoValue.substring(0, 1);
      this.faturaItem.moeda.id = this.moedaSelecionadaItem;
      const codMoeda = this.opcoesMoeda.find(o => o.value === this.moedaSelecionadaItem).label;
      this.faturaItem.moeda.codigo = codMoeda;
      if (this.files.length > 0) {
        this.faturaItem.contemArquivo = 1;
      }
      this.faturaAtual.faturaItemComplemento.splice(index, 1, this.faturaItem);
      this.valorTotalItemCompl = 0;
      this.recalculaValorTotalItem();
      this.faturaItem = new FaturaItem();

    }

    this.modalDadosFaturaItem = false;

  }

  cancelarModalComplemento() {
    this.labelTipoFatura = '';
    this.labelTipoFatura = this.faturaSelecionados[0].tipo_fatura === 1 ? 'Extrato Cartao de Crédito' :
      this.faturaSelecionados[0].tipo_fatura === 2 ? 'Extrato RDV' :
        this.faturaSelecionados[0].tipo_fatura === 4 ? 'Despesa Direta' : 'Extrato Nota de Débito';
    this.itemComplemento = false;
    this.modalFaturaItemComplemento = false;

  }

  editarCentroCusto() {
    this.modalCentroCusto = true;
    this.rateioCentroCusto = 100;
    this.faturaItem.faturaRateio.forEach(element => {
      this.rateioCentroCusto = this.rateioCentroCusto - this.utilService.parseDecimal(element.porcentagem);
    });
  }



  onFilterFaturaItem(event: any) {
    this.valorTotalItem = 0;
    event.filteredValue.forEach(element => {
      this.valorTotalItem += parseFloat(element.valorParcela.replace(',', '.'));
    });
  }


  onFilterFatura(event: any) {
    event.filteredValue.forEach(element => {
    });
  }

  listar(tipoFatura: String) {
    this.fatura = new Array<any>();
    this.faturaAberta = new Array<any>();
    this.faturaCont = new Array<any>();
    this.faturaEnc = new Array<any>();
    this.faturaAguardandoAprovacao  = new Array<any>();
    this.faturaAprovado             = new Array<any>();
    this.faturaReprovado            = new Array<any>();


    this.idFaturaExcluirView = new Array();
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

      this.carregarAprovadorTecnico();
      this.fatura = new Array();
      this.httpService.wait();

      this.httpService.get('/custom/fatura/get-faturas',
        [this.dblinkedSessionService.periodo.year.value,
        this.dblinkedSessionService.periodo.month.value,
        this.dblinkedSessionService.empresa.id,
        tipoFatura,
        this.sessionService.loggedUser.id
        ])
        .subscribe(retorno => {
          retorno.forEach(r => {
            r.selected = false;
            this.fatura.push(r);

            /*
               TODO: REMOVER COMPARAÇÃO COM DESCRIÇÃO
            */
            if (r.status === 'EM ABERTO') {
              this.faturaAberta.push(r);
            }

            if (r.status === 'CONTABILIZADO' || r.status === 'CONTABILIZADO PARCIAL') {
              this.faturaCont.push(r);
            }

            if (r.status === 'ENCERRADO') {
              this.faturaEnc.push(r);
            }

            if (r.status ==='APROVADO') {
              this.faturaAprovado.push(r);
            }

            if (r.status === 'REPROVADO') {
               this.faturaReprovado.push(r);
            }

            if (r.status === 'AGUARDANDO APROVAÇÃO') {
              this.faturaAguardandoAprovacao.push(r);
            }

          });
        },
          error => this.httpService.handleError(error, () => this.listar(tipoFatura)),
          () => this.httpService.done());
    }
    setTimeout(() => {
      this.mostraBotoes();
    }, 1000);
  }

  mostraBotoes() {
    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    //this.ocultarBotoes();

    this.loadOpcoes();
  }

  private toolbarEnable() {
    this.toolbarService.clear();

    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
    () => this.listar(this.iconesSelecionado));

    this.toolbarService.add('mail', true, 'class-other-button', 'mail', 'Solicitar!');
    this.toolbarService.add('check', true, 'class-other-button', 'check', 'Aprovar!');

    this.toolbarService.replace('save', false,
      'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());

    this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Opções');
    this.toolbarService.ocultar('pesquisa');

    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir Pedido', () => this.imprimirFatura());
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('export', true, 'class-edit-button', 'edit', 'Editar Fatura', () => this.displayEditFaturaDialog());
    this.toolbarService.replace('edit', true, 'class-edit-button', 'list', 'Alterar Títulos Selecionados');

    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Nova Fatura', () => this.displayFaturaDialog());

  }

  imprimirFatura() {
    this.idFaturaView.forEach(faturaId => {
      //window.open(this.httpService.url('/custom/file/' + faturaId + '/despesadiretaView'), '_blank');
      this.httpService.wait();
      this.httpService.get('/custom/fatura/impressao-despesas(' + faturaId +')')
        .subscribe(result => this.exportedFatura(result),
          error => this.httpService.handleError(error, () => this.imprimirFatura()),
          () => this.httpService.done());
    });

    this.idFaturaView = new Array();
  }

  exportedFatura(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/print_fatura'), '_blank');
    } else {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: result.message });
   }
  }

  loadOpcoes() {
    this.toolbarEnable();
    const childActions: Array<ToolbarButton> = new Array();
    this.idFaturaView = new Array();

    childActions.push({
      icon: '',
      label: 'Cartão de Credito',
      callback: () => this.buscaCartaoCredito()
    });
    childActions.push({
      icon: '',
      label: 'Relatório de Despesa de Viagem',
      callback: () => this.buscaRdv()
    });

    childActions.push({
      icon: '',
      label: 'Nota de Debito',
      callback: () => this.buscaNotaDebito()
    });

    childActions.push({
      icon: '',
      label: 'Despesa Direta',
      callback: () => this.buscaDespesaDireta()
    });


    this.toolbarService.get('more_vert').child = childActions;
  }

  buscaDespesaDireta() {
    this.limpaVariaveis();
    this.iconesSelecionado = '4';
    this.subtitle = 'Selecionado : Despesa Direta';
    this.listar(this.iconesSelecionado);
    this.isDepesaDireta = true;

  }

  buscaCartaoCredito() {
    this.limpaVariaveis();
    this.iconesSelecionado = '1';
    this.subtitle = 'Selecionado : Cartão de Crédito';
    this.listar(this.iconesSelecionado);
    this.isDepesaDireta = false;
  }

  buscaRdv() {
    this.limpaVariaveis();
    this.iconesSelecionado = '2';
    this.subtitle = 'Selecionado : Relatório Despesa de Viagem';
    this.listar(this.iconesSelecionado);
    this.isDepesaDireta = false;
  }

  buscaNotaDebito() {
    this.limpaVariaveis();
    this.iconesSelecionado = '3';
    this.subtitle = 'Selecionado : Nota de Débito';
    this.listar(this.iconesSelecionado);
    this.isDepesaDireta = false;
  }

  limpaVariaveis() {

    this.faturaAberta = new Array<any>();
    this.faturaCont = new Array();
    this.faturaEnc = new Array();
    this.faturaAguardandoAprovacao  = new Array();
    this.faturaAprovado             = new Array();
    this.faturaReprovado            = new Array();
  }



  atualizarVoltar() {
    this.modalPeriodo = false;
    this.faturaAberta = new Array<any>();
    this.faturaCont = new Array();
    this.faturaEnc = new Array();
    this.faturaAguardandoAprovacao  = new Array();
    this.faturaAprovado             = new Array();
    this.faturaReprovado            = new Array();


    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() > 8 ?
        (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() > 8 ?
        (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
        this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
        this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }
    const d1 = new Date(this.dataIniSearch);
    const d2 = new Date(this.dataFinSearch);
    if (d1 > d2) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Data incial maior que a final. Verifique!' });
      return;
    }
    this.modalPeriodo = false;
    this.listarPeriodo();




  }


  listarPeriodo() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
      this.fatura = new Array<any>();

      this.httpService.wait();
      this.httpService.get('/custom/fatura/get-faturas-extrato-periodo',
        [this.dblinkedSessionService.empresa.id, this.dataIniSearch, this.dataFinSearch, this.iconesSelecionado])
        .subscribe(retorno => {

          retorno.forEach(r => {
            r.selected = false;
            this.fatura.push(r);

            /*
               TODO: REMOVER COMPARAÇÃO COM DESCRIÇÃO
            */

           if (r.status === 'EM ABERTO') {
            this.faturaAberta.push(r);
          }

          if (r.status === 'CONTABILIZADO' || r.status === 'CONTABILIZADO PARCIAL') {
            this.faturaCont.push(r);
          }

          if (r.status === 'ENCERRADO') {
            this.faturaEnc.push(r);
          }

          if (r.status ==='APROVADO') {
            this.faturaAprovado.push(r);
          }

          if (r.status === 'REPROVADO') {
             this.faturaReprovado.push(r);
          }

          if (r.status === 'AGUARDANDO APROVAÇÃO') {
            this.faturaAguardandoAprovacao.push(r);
          }

          });



        },
          error => this.httpService.handleError(error, () => this.listarPeriodo()),
          () => this.httpService.done());


    }
  }


  openModalPeriodo() {
    this.modalPeriodo = true;
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
  }


  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
    this.uploadArquivo();
  }

  uploadArquivo() {



    if (!this.file.name.match(/.pdf/)) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Somente é permitido documento com a extensão .PDF. Verifique!'
      });
      return;
    }

    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('fileName', this.file.name);
    data.append('type', 'fatura');
    data.append('categoria', ((!this.alteraItem) ? this.nameFileUpload.toString() : this.chaveUpload.toString()));
    data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
    data.append('faturaId', this.faturaAtual.id.toString());
    this.httpService.wait();
    this.httpService.post('/custom/upload', data)
      .subscribe(result => {
        this.files.push({ name: this.file.name });
        this.fileInput.nativeElement.value = '';
        this.file = undefined;
      },
        error => this.httpService.handleError(error, () => this.uploadArquivo()),
        () => this.httpService.done());
  }

  getFiles() {

    if (this.chaveUpload !== '') {
      this.httpService.wait();
      this.httpService.post('/custom/fatura/get-files(' + this.dblinkedSessionService.empresa.id.toString() + ',' +
        this.faturaAtual.id + ',' + this.chaveUpload + ') ')
        .subscribe(result => {
          result.forEach(r => {
            this.files.push({ name: r.name });

          });


        },
          error => this.httpService.handleError(error, () => this.excluirAnexo(event)),
          () => this.httpService.done());
    }
  }

  // inicio combo aprovador

  comboAprovador(value: Array<any>) {
    this.aprovadores = new Array();
    this.opcoesAprovador = [];
    value.forEach(element => {

      this.opcoesAprovador.push({ label: element.nome, value: element.usrId });
      this.aprovadores.push(element);
    });
  }


  validaAprov() {
    if (this.faturaAtual.usuarioAprovador) {
      this.fatura.forEach(element => {
        if (this.sessionService.loggedUser.id === element.usrId) {
          this.isAprov = true;
        }
      });
    }
  }



  carregarAprovadorTecnico() {
    this.httpService.wait();
    this.httpService.get('/custom/fatura/buscar-aprovador-fatura('
      + this.dblinkedSessionService.empresa.id.toString() + ',' + this.sessionService.loggedUser.id + ')')
      .subscribe(ref => {

        this.comboAprovador(ref);
      },
        error => this.httpService.handleError(error, () => this.carregaItemRateio),
        () => this.httpService.done());

  }



  // final combo aprovador

  excluirAnexo(event: any) {
    this.httpService.wait();
    this.httpService.post('/custom/fatura/excluir-arquivos-fatura(' + this.dblinkedSessionService.empresa.id.toString() + ',' +
      this.faturaAtual.id + ',' + event.name + ',' + ((!this.alteraItem) ? this.nameFileUpload : this.chaveUpload) + ') ')
      .subscribe(result => {
        const index = this.files.findIndex(f => f.name === event.name);
        this.files.splice(index, 1);
      },
        error => this.httpService.handleError(error, () => this.excluirAnexo(event)),
        () => this.httpService.done());
  }

  baixarAnexo(event: any) {
    window.open(
      this.httpService.url(
        '/custom/file/' + this.dblinkedSessionService.empresa.id.toString() + '/' +
        this.faturaAtual.id + '/' + event.name + '/' + ((!this.alteraItem) ? this.nameFileUpload : this.chaveUpload) + '/fatura'
      ),
      '_blank'
    );
  }

  hideMessage() {



  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  solicitarAprovacaoLote(){
    if (confirm('Deseja realmente solicitar a aprovação?')) {

      let idsEnviar = new Array();

      this.idFaturaExcluirView.forEach(element => {
        idsEnviar.push(element.id);
      });

      const listasEnviar = {
        ids: idsEnviar,
        idUsuario: this.sessionService.loggedUser.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/fatura/solicitar-aprovacao-lote', listasEnviar)
        .subscribe(result => {


        if (result.error === true) {
          this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
        } else {
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result.message });
          this.listar(this.iconesSelecionado);
          this.modalFatura = false;
        }

      },
          error => this.httpService.handleError(error, () => this.solicitarAprovacaoLote()),
          () => this.httpService.done());
    }
  }

  aprovarLote(){
    this.modalAprovarLote           = true;
  }

  aprovarFaturaLote(){
    if (confirm('Deseja realmente aprovar estas faturas?')) {
      let idsEnviar = new Array();

      this.idFaturaExcluirView.forEach(element => {
        idsEnviar.push(element.id);
      });

      const listasEnviar = {
        ids: idsEnviar,
        idUsuario: this.sessionService.loggedUser.id,
        status: 'aprova'
      };

      this.httpService.wait();
      this.httpService.post('/custom/fatura/aprovar-recusa-fatura-lote', listasEnviar)
        .subscribe(result => {


          if (result.error === true) {
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result.message });
            this.listar(this.iconesSelecionado);
            this.modalAprovarLote = false;
          }


        },
          error => this.httpService.handleError(error, () => this.solicitarAprovacaoLote()),
          () => this.httpService.done());
    }
  }

  isReadOnly(){ }

  recusarFaturaLote(){
    if (confirm('Deseja realmente recusar estas faturas?')) {
      let idsEnviar = new Array();

      this.idFaturaExcluirView.forEach(element => {
        idsEnviar.push(element.id);
      });

      const listasEnviar = {
        ids: idsEnviar,
        idUsuario: this.sessionService.loggedUser.id,
        status: 'recusa'
      };

      this.httpService.wait();
      this.httpService.post('/custom/fatura/aprovar-recusa-fatura-lote', listasEnviar)
        .subscribe(result => {



          if (result.error === true) {
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result.message });
            this.listar(this.iconesSelecionado);
            this.modalAprovarLote = false;
          }

        },
          error => this.httpService.handleError(error, () => this.solicitarAprovacaoLote()),
          () => this.httpService.done());
      }
    }

    centroDeCustoObrigatorio(){

      this.httpService.wait();
      this.httpService.get('/custom/pedido/centro-de-custo-obrigatorio(' + this.dblinkedSessionService.empresa.id + ')')
        .subscribe(element => {
          this.centroCustoObrigatorio = element.cadCcusObrigatorio;
          console.log("centroCustoObrigatorio: " + JSON.stringify(this.centroCustoObrigatorio));
        },
          error => this.httpService.handleError(error, () => this.centroDeCustoObrigatorio()),
            () => this.httpService.done());
    }

    isContaResultado(planoContaId){
      console.log("planoContaId: " + JSON.stringify(planoContaId));

      this.httpService.wait();
      this.httpService.get('/custom/financeiro/is-conta-resultado(' + planoContaId + ')')
        .subscribe(element => {
          this.existeContaResultado = element.isContaResultado;
          console.log("existeContaResultado: " + JSON.stringify(this.existeContaResultado));
        },
          error => this.httpService.handleError(error, () => this.isContaResultado(planoContaId)),
            () => this.httpService.done());
    }

  selecionarContasUnica(event) {
    let ctaSelecionada = event.selectedAccount
    this.contaSelecionada = ctaSelecionada.id
    this.planoConta.codCta = ctaSelecionada.codCta
    this.planoConta.descrCta = ctaSelecionada.descrCta
    this.planoConta.id = ctaSelecionada.id
    if (this.modalDadosFaturaItem) {
      this.isContaResultado(ctaSelecionada.id)
      this.contaSelecionadaItem = ctaSelecionada.id
    }
  }

  salvarParticipante(event) {
    let partSelecionado = event.selectedParticipant
    this.participanteSelecionada = partSelecionado.id
    this.participante.id = partSelecionado.id
    this.participante.codigo = partSelecionado.cod_part
    this.participante.fantasia = partSelecionado.fantasia
    this.participante.cnpj = partSelecionado.cpf_cnpj
  }
}
