import { Empresa } from "app/cadastro/model/empresa.model";
import { OrigemRmsPedido } from "app/rms/model/origem-rms-pedido.model";

export class Origem {
  id: number;
  nome: string;
  empresa: Empresa;
  origemRmsPedido: OrigemRmsPedido

  constructor(origem?: Origem | number) {
    if (origem !== undefined && origem !== null) {
      if (typeof origem === 'number') {
        this.id = origem;
      } else {
        this.id = origem.id;
        this.nome = origem.nome;
        this.empresa = origem.empresa;
        this.origemRmsPedido = origem.origemRmsPedido
      }
    }
  }
}
