import { isNullOrUndefined } from "util";
import { Eventos } from './eventos.model';

export class Plano{

  id                : number;
  eventos           : Eventos;
  parcela           : number;
  valor             : number;
  valorUnit         : number;
  recorrencia       : boolean;

  constructor(plano?: Plano | number) {
    if (!isNullOrUndefined(plano)) {
      if (typeof plano === 'number') {
        this.id = plano;
      } else {
        this.id         = plano.id;
        this.eventos    = plano.eventos;
        this.parcela    = plano.parcela;
        this.valor      = plano.valor;
        this.valorUnit  = plano.valorUnit;
        this.recorrencia = plano.recorrencia;

      }
    }
  }

}