import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { Departamento } from 'app/util/model/departamento.model';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Envio } from 'app/centralweb/model/envio.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { Relatorios } from 'app/centralweb/model/relatorios.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';

export class RelatorioAgendado {
  id: number;
  relatorio: Relatorios;
  recorrencia: Recorrencia;
  empresa: Empresa;
  dtEnvio: Date;
  usuarioInclusao: Usuario;
  dtHrInclusao: Date;
  antecipaDataNaoUtil: boolean;
  diaMes: number;
  repeteCada: number;
  dtInicial: Date;
  dtFinal: Date;
  ocorrencias: number;
  diaUtil: boolean;
  seg: boolean;
  ter: boolean;
  qua: boolean;
  qui: boolean;
  sex: boolean;
  sab: boolean;
  dom: boolean;
 destinatarios: string;

constructor(relatorioagendado?: RelatorioAgendado | number) {
    if (relatorioagendado !== undefined && relatorioagendado !== null) {
      if (typeof relatorioagendado === 'number') {
        this.id = relatorioagendado;
      } else {
        this.id = relatorioagendado.id;
        this.relatorio = relatorioagendado.relatorio;
        this.recorrencia = relatorioagendado.recorrencia;
        this.empresa = relatorioagendado.empresa;
        this.dtEnvio = relatorioagendado.dtEnvio;
        this.usuarioInclusao = relatorioagendado.usuarioInclusao;
        this.dtHrInclusao = relatorioagendado.dtHrInclusao;
        this.antecipaDataNaoUtil = relatorioagendado.antecipaDataNaoUtil;
        this.diaMes = relatorioagendado.diaMes;
        this.repeteCada = relatorioagendado.repeteCada;
        this.dtInicial = relatorioagendado.dtInicial;
        this.dtFinal = relatorioagendado.dtFinal;
        this.ocorrencias = relatorioagendado.ocorrencias;
        this.diaUtil = relatorioagendado.diaUtil;
        this.seg = relatorioagendado.seg;
        this.ter = relatorioagendado.ter;
        this.qua = relatorioagendado.qua;
        this.qui = relatorioagendado.qui;
        this.sex = relatorioagendado.sex;
        this.sab = relatorioagendado.sab;
        this.dom = relatorioagendado.dom;
        this.destinatarios = relatorioagendado.destinatarios;
      }
    }
  }
}
