import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Util } from 'app/shared/common/util';
import { isNullOrUndefined, isDate } from 'util';
import { Message, MessageService, SelectItem } from 'primeng/api';


@Component({
  selector: 'app-evento-pagamento-pagseguro',
  templateUrl: './evento-pagamento-pagseguro.component.html',
  styleUrls: ['./evento-pagamento-pagseguro.component.scss'],
  providers: [CrudService]
})
export class EventoPagamentoPagSeguroComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  modalSelecionarData           : boolean = false;
  modalEditarPagamento          : boolean = false;
  listaPagamentosPagSeguro      : Array<any> = new Array();
  listaParcelasPessoa           : Array<any> = new Array();
  idLista                       : Array<any> = new Array();

    checkedAll    = false;

   dataIni    : Date ;
   dataFin    : Date ;
   congelarParcela : boolean = false;


  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };




  //Usado no modal de edicao de parcela
  dataCobrarEm                : Date;
  valorPagamento              : number = 0.00;
  pagamentoPagseguroParcelaId : number = 0;
  isPodeEditar                : boolean = false;
  parcelaReferencia           : string = null;
  nome                        : string = null;
  cpf                         : string = null;
  telefone                    : string = null;
  descricaoEvento             : string = null;
  mensagemPagSeguro           : string = null;
  datePipe: any;

  modalEstorno : boolean = false;
  valorEstorno : number  = 0.00;
  valorTotalReembolsado :  number = 0.00;
  saldoReembolso        :  number = 0.00;
  boolCongelarParcela   : boolean = false;
  disableCongelarParcela : boolean = false;
  isPodeEstornar         : boolean = false;
  cartaoNome             : string  = '';
  cartaoDigito           : string  = '';
  justificativaEstorno   : string = '';
  selectedIndex          : number = 0;
  listaJustificativaPagSeguro  : Array<any> = new Array();

  opcoesComboStatus       : SelectItem[];
  opcoesComboRecorrencia  : SelectItem[];
  opcoesComboCongelado    : SelectItem[];
  colsPagamentosPagSeguro : any[];

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title              = 'PAGAMENTO PAG SEGURO';
    this.subtitle           = '';
    this.titleService.title = '';

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();

    this.modalSelecionarData   = false;
    this.checkedAll         = false;

    this.listaPagamentosPagSeguro = new Array();


    this.idLista        = new Array();

    this.colsPagamentosPagSeguro = [
      { field: 'nome', header: 'NOME' },
      { field: 'cpf', header: 'CPF' },
      { field: 'telefone', header: 'TELEFONE' },
      { field: 'descricaoCompleta', header: 'DESCRIÇÃO' },
      { field: 'parcelaCompleta', header: 'PARCELA' },
      { field: 'cobrarEm', header: 'COBRAR EM' },
      { field: 'pagoEm', header: 'PAGO EM' },
      { field: 'valorTotal', header: 'VL TOTAL' },
      { field: 'valorPago', header: 'VL PAGO' },
      { field: 'valorReembolso', header: 'VL REEMBOLSO' },
      { field: 'statusPagSeguroPortugues', header: 'ST PagSeguro' },
      { field: 'parcelaCongeladaPortugues', header: 'CONGELADO' },
      { field: 'recorrencia', header: 'RECORRENCIA' }
    ];


    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {

        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
          && this.dblinkedSessionService.periodo.year) {
            const ano = this.dblinkedSessionService.periodo.year.value;
            const lastDay = new Date(ano, this.dblinkedSessionService.periodo.month.value + 1, 0); // ultimo dia do mês
            // formatando meses com dois digitos
            let mes = this.dblinkedSessionService.periodo.month.value > 8 ?
              (+this.dblinkedSessionService.periodo.month.value  ) :'0'  + (+this.dblinkedSessionService.periodo.month.value );

            this.dataIniSearch = ano + '-' + mes + '-01';
            this.dataFinSearch = ano + '-' + mes + '-' + lastDay.getDate();

            this.listar();
          }


      });



  }

 listar(){

  this.modalSelecionarData        = false;
  this.checkedAll                 = false;
  this.listaPagamentosPagSeguro   = new Array();
  this.idLista                    = new Array();
  this.toolbarEnable();
  this.getComboStatusPagSeguro();
  this.getComboCongelado();
  this.getComboRecorrencia();

  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-lista-pagamentos-pagseguro',[ this.dblinkedSessionService.empresa.id , this.dataIniSearch , this.dataFinSearch ])
    .subscribe(retorno => {

      this.listaPagamentosPagSeguro = new Array();
      retorno.forEach(i =>{
        this.listaPagamentosPagSeguro.push(i);
      })
    },
      error => this.httpService.handleError(error, () => this.listar()),
      () => this.httpService.done());

 }

  hideMessage() {




  }

  configuraToolBar() {

    this.toolbarService.replace('add', false, 'class-new-button', 'SELECIONAR', 'SELECIONAR',    () => this.showModalSelecionarData()    );
  }


  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

      Promise.resolve(null).then(() => this.toolbarService.hidden = false);
      this.toolbarService.ocultar('voltar');
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.ocultar('more_vert');
      this.toolbarService.ocultar('print');
      this.toolbarService.ocultar('edit');
      this.toolbarService.ocultar('pesquisa');
      this.toolbarService.ocultar('export');
      this.toolbarService.ocultar('save');
      this.toolbarService.ocultar('remove');

      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
        () => this.listar()
      );

      this.toolbarService.replace('add', false, 'class-new-button', 'SELECIONE', 'Selecionar Data',    () => this.showModalSelecionarData()    );


  }


  validacao(){
    if (isNullOrUndefined( this.dataCobrarEm) || this.dataCobrarEm.toString() === '' ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor fornecer uma data para a cobrança'
      });
      return true;

    } else{
      if (isDate(this.dataCobrarEm)) {

        var dataAtual  = new Date();
        var dataCobrar = new Date();

        dataCobrar.setDate(this.dataCobrarEm.getDate());
        dataCobrar.setMonth(this.dataCobrarEm.getMonth());
        dataCobrar.setFullYear(this.dataCobrarEm.getFullYear());

        if (!(dataAtual < dataCobrar) ) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'A data de cobrança não pode ser uma data inferior a data de amanhã'
          });
          return true;
        }

      } else {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor preencher com uma data válida'
        });
        return true;
      }

    }

    if (isNullOrUndefined( this.valorPagamento) || this.valorPagamento.toString() === '' ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar um valor'
      });
      return true;

    } else{
      var valorPagamento = new Number(this.valorPagamento.toString().replace(",","."));
      if ( valorPagamento < 1) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'O valor à ser pago não pode ser menor que 1'
        });
        return true;
      }
    }


    return false;
  }


  ngOnDestroy(): void {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  salvarEdicaoParcelaPagamento(){

    if (this.validacao()) {
      return;
    }

    const listaEnviar = {
       pagamentoPagseguroParcelaId  : this.pagamentoPagseguroParcelaId,
       dataCobrarEm     : this.dataCobrarEm,
       valorPagamento   : this.valorPagamento,
       usuarioId        : this.sessionService.loggedUser.id,
       congelarParcela  : this.congelarParcela,
       IsNovoRegistro   : false
    };


    this.httpService.wait();
    this.httpService.post('/custom/polozi/editar-parcela-pagamento-pagseguro', listaEnviar)
      .subscribe(result => {
        this.modalEditarPagamento = false;
        if (result[0].error) {
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        }

        this.listar();
      },
        error => this.httpService.handleError(error, () => this.salvarEdicaoParcelaPagamento()),
        () => this.httpService.done());
  }




 changeDataIni(event : any){

    this.dataFin  = new Date();
    var data      = new Date();
    data          = event;
    const mes     = data.getMonth() > 8 ?
    (+data.getMonth() + 1) : '0' + (+data.getMonth() + 1);

    const lastDay = new Date(data.getFullYear(), data.getMonth() + 1, 0); // ultimo dia do mês

    this.dataFin.setDate(lastDay.getDate());
    this.dataFin.setMonth(lastDay.getMonth());


}

showModalEditarPagamento(value: any){

 if (isNullOrUndefined(value.statusPagSeguro) || (value.statusPagSeguro === ''  )  ) {
   this.isPodeEditar = true;
 } else {
  this.isPodeEditar = false;
 }

 if (!isNullOrUndefined(value.statusPagSeguro) && (value.statusPagSeguro === 'PAID')) {
   this.isPodeEstornar = true;
 } else {
  this.isPodeEstornar = false;
 }

 this.pagamentoPagseguroParcelaId = 0;
 this.nome = null;
 this.cpf  = null;
 this.dataCobrarEm          = null;
 this.valorPagamento        = 0.00;
 this.valorTotalReembolsado = 0.00;
 this.saldoReembolso        = 0.00;
 this.parcelaReferencia     = null;
 this.mensagemPagSeguro     = null;
 this.cartaoNome            = null;
 this.cartaoDigito          = null;


 this.nome      = value.nome;
 this.cpf       = value.cpf;
 this.telefone  = value.telefone;
 this.descricaoEvento   = value.descricaoCompleta;
 this.cartaoNome        = value.cartaoNome;
 this.cartaoDigito      = value.ultimoDigitoCartao;
 this.justificativaEstorno = value.justificativaEstorno;
 this.congelarParcela      = value.parcelaCongelada;

 if (!isNullOrUndefined(value.cobrarEm) || value.cobrarEm !== '') {
   var str = value.cobrarEm.toString();
   var ano : number;
   var mes : number;
   var dia : number;

   ano = str.substring(str.lastIndexOf("/")+1, str.lastIndexOf("/")+5);
   mes = str.substring(str.indexOf("/")+1, str.lastIndexOf("/"))
   dia = str.substring(0, str.indexOf("/"));


   this.dataCobrarEm = new Date(ano, mes-1,dia);

 }
 this.mensagemPagSeguro = value.pagSeguroMensagem;
 this.parcelaReferencia = value.parcelaCompleta;
 this.valorPagamento    = value.valorTotal;
 this.pagamentoPagseguroParcelaId = value.pagamentoPagseguroParcelaId;
 this.valorTotalReembolsado = value.valorReembolso;

 try {
  var valorTotal : number = 0.00;
  var valorReembolso : number = 0.00;

  if (value.valorTotal.includes(".") && value.valorTotal.includes(",")) {
    valorTotal = Number.parseFloat( Number.parseFloat(value.valorTotal.replace(".","").replace(",",".")).toFixed(2));
  } else {
    valorTotal = Number.parseFloat( Number.parseFloat(value.valorTotal.replace(",",".")).toFixed(2));
  }

  if (value.valorReembolso.includes(".") && value.valorReembolso.includes(",")) {
    valorReembolso = Number.parseFloat( Number.parseFloat(value.valorReembolso.replace(".","").replace(",",".")).toFixed(2));
  } else {
    valorReembolso = Number.parseFloat( Number.parseFloat(value.valorReembolso.replace(",",".")).toFixed(2));
  }

  this.saldoReembolso = Math.abs( valorTotal - valorReembolso );

 } catch (error) {
  this.saldoReembolso = 0.00;
 }

 this.selectedIndex         = 0;
 this.modalEditarPagamento  = true;


}

 listarParcelasPessoa(value: any){

  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-lista-pagamentos-pagseguro', [ 38 ])
    .subscribe(retorno => {
      this.listaParcelasPessoa = new Array();
      retorno.forEach(i =>{
        this.listaParcelasPessoa.push(i);
      })
    },
      error => this.httpService.handleError(error, () => this.listarParcelasPessoa(value)),
      () => this.httpService.done());

 }

showModalSelecionarData(){
  this.modalSelecionarData = true;
  var today = new Date(this.dataFinSearch);
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
  //this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
  var novaDataFin = new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate());
  this.dataFin = this.utilService.dateWithoutTimeZone(novaDataFin.toString());
  this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);

}

listarPagamentos(){
  try {

    this.subtitle           = '';
    //this.subtitle           = 'PAGAMENTOS DE  '+ this.transformDate(dataI) + ' ATÉ ' + this.transformDate(this.dataFin);
    this.listar();

  } catch (error) {
    this.subtitle           = '';
    this.listar();
  }
}

transformDate(date) {
  //return this.datePipe.transform(date, 'yyyy/MM/dd');
  return this.datePipe.transform(date, "dd/MM/yyyy");
}

atualizarVoltar(){
  if (this.dataIni !== null && this.dataFin !== null) {
    const mesIni = this.dataIni.getMonth() > 8 ?
                  (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
    const mesFin = this.dataFin.getMonth() > 8 ?
                  (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
    this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
                  this.dataIni.getDate() : '0' + this.dataIni.getDate());
    this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
                  this.dataFin.getDate() : '0' + this.dataFin.getDate());
  } else{
    this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Favor selecionar uma data!' });
    return;

  }

  const d1 = new Date(this.dataIniSearch);
  const d2 = new Date(this.dataFinSearch);
  if (d1 > d2) {
    this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Data incial maior que a final. Verifique!' });
    return;
  }

  this.listar();
}

estornarPagamento(){
  this.modalEstorno         = true;
  this.valorEstorno         = this.valorPagamento ;
  this.justificativaEstorno = '';

}

salvarEstorno(){

  if (this.validacaoEstorno()) {
    return;
  }


  const listaEnviar = {
    pagamentoPagseguroParcelaId  : this.pagamentoPagseguroParcelaId,
    valorEstorno          : this.valorEstorno,
    usuarioId             : this.sessionService.loggedUser.id,
    justificativaEstorno  : this.justificativaEstorno,
    congelarParcela       : this.boolCongelarParcela

 };

 this.httpService.wait();
 this.httpService.post('/custom/polozi/salvar-estorno-pagseguro', listaEnviar)
   .subscribe(result => {
     this.modalEstorno         = false;
     this.modalEditarPagamento = false;

     if (result.error) {
       this.messageService.add({
         severity: 'error',key: 'messageLinnks',
         summary: 'MSG: ',
         detail: result.message
       });
     } else {
       this.messageService.add({
         severity: 'success',key: 'messageLinnks',
         summary: 'MSG: ',
         detail: result.message
       });
     }

     this.listar();
   },
     error => this.httpService.handleError(error, () => this.salvarEstorno()),
     () => this.httpService.done());



}

  validacaoEstorno(){
    var valorPagamento : number = 0.00;
    var valorEstorno   : number = 0.00;
    var valorTotalReembolsado  : number = 0.00;
    var saldoReembolso         : number = 0.00;

    if (this.valorPagamento.toString().includes(",") && this.valorPagamento.toString().includes(".")) {
      valorPagamento         = Number.parseFloat( this.valorPagamento.toString().replace(".","").replace(",","."));
    } else {
      valorPagamento         = Number.parseFloat( this.valorPagamento.toString().replace(",","."));
    }

    if (this.valorEstorno.toString().includes(",") && this.valorEstorno.toString().includes(".")) {
      valorEstorno         = Number.parseFloat( this.valorEstorno.toString().replace(".","").replace(",","."));
    } else {
      valorEstorno         = Number.parseFloat( this.valorEstorno.toString().replace(",","."));
    }

    if (this.valorTotalReembolsado.toString().includes(",") && this.valorTotalReembolsado.toString().includes(".")) {
      valorTotalReembolsado   = Number.parseFloat( this.valorTotalReembolsado.toString().replace(".","").replace(",","."));
    } else {
      valorTotalReembolsado   = Number.parseFloat( this.valorTotalReembolsado.toString().replace(",","."));
    }

    saldoReembolso         = Math.abs( valorPagamento - valorTotalReembolsado);

    if (valorEstorno < 1.00) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'O valor de estorno tem que ser maior que 1!'
      });
      return true;
    }


    if ( valorEstorno > saldoReembolso ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'O valor de estorno é maior que o valor pago!'
      });
      return true;
    }
      return false;
  }

  verificarValorEstornado(){

    if (!isNullOrUndefined(this.valorEstorno)) {
      var valorEstorno    : number = 0.00;
      var saldoReembolso  : number = 0.00;

      if (this.valorEstorno.toString().includes(",") && this.valorEstorno.toString().includes(".")) {
        valorEstorno = Number.parseFloat(this.valorEstorno.toString().replace(".","").replace(",","."))
      } else {
        valorEstorno = Number.parseFloat(this.valorEstorno.toString().replace(",","."))
      }

      if (this.saldoReembolso.toString().includes(",") && this.saldoReembolso.toString().includes(".")) {
        saldoReembolso = Number.parseFloat(this.saldoReembolso.toString().replace(".","").replace(",","."))
      } else {
        saldoReembolso = Number.parseFloat(this.saldoReembolso.toString().replace(",","."))
      }

        if (valorEstorno === saldoReembolso) {
          this.disableCongelarParcela = true;
          this.boolCongelarParcela  = true;
        } else{
          this.disableCongelarParcela = false;
          this.boolCongelarParcela    = false;
        }
    }
  }


  tabChange(event: any){

    switch (event.index) {
      case 1:
        this.getListaComentario();

        break;

      default:
        break;
    }
  }

  getListaComentario(){

    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-lista-justificativas-pagseguro',[ this.pagamentoPagseguroParcelaId ])
      .subscribe(retorno => {

        this.listaJustificativaPagSeguro = new Array();
        retorno.forEach(i =>{
          this.listaJustificativaPagSeguro.push(i);
        })
      },
        error => this.httpService.handleError(error, () => this.listar()),
        () => this.httpService.done());

  }


  getComboStatusPagSeguro(){
    this.opcoesComboStatus = [];

    this.opcoesComboStatus.push({label: '', value: ''});
    this.opcoesComboStatus.push({label: 'Autorizado', value: 'Autorizado'});
    this.opcoesComboStatus.push({label: 'Aguardando', value: 'Aguardando'});
    this.opcoesComboStatus.push({label: 'Cancelado', value: 'Cancelado'});
    this.opcoesComboStatus.push({label: 'Pago', value: 'Pago'});
    this.opcoesComboStatus.push({label: 'Recusado', value: 'Recusado'});

  }

getComboCongelado(){
  this.opcoesComboCongelado = [];
  this.opcoesComboCongelado.push({label: 'Sim', value: 'Sim'});
  this.opcoesComboCongelado.push({label: 'Não', value: 'Não'});
}

getComboRecorrencia(){
  this.opcoesComboRecorrencia = [];
  this.opcoesComboRecorrencia.push({label: 'Sim', value: 'Sim'});
  this.opcoesComboRecorrencia.push({label: 'Não', value: 'Não'});

}

 congelarParcelas(){

  if (confirm('Deseja congelar esta parcela e as parcelas futuras do cliente: ' + this.nome + ' ?')) {

    const listaEnviar = {
      pagamentoPagseguroParcelaId  : this.pagamentoPagseguroParcelaId,
      usuarioId        : this.sessionService.loggedUser.id,
      congelarParcela  : this.congelarParcela,
      IsNovoRegistro   : false
   };


    this.httpService.wait();
    this.httpService.post('/custom/polozi/congelar-parcelas-pagamento-pagseguro', listaEnviar)
      .subscribe(result => {
        this.modalEditarPagamento = false;
        if (result[0].error) {
         this.messageService.add({
           severity: 'error',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result[0].message
         });
       } else {
         this.messageService.add({
           severity: 'success',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result[0].message
         });
       }

       this.listar();
     },
       error => this.httpService.handleError(error, () => this.congelarParcelas()),
       () => this.httpService.done());


  }


 }



}


