import { Component, OnInit, OnDestroy } from '@angular/core';
import { TitleService } from 'app/shared/services/title.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { UtilService } from 'app/shared/services/util.service';
import { HttpService } from 'app/shared/services/http.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-fichas-conf-email',
  templateUrl: './fichas-conf-email.component.html',
  styleUrls: ['./fichas-conf-email.component.scss']
})
export class FichasConfEmailComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private utilService: UtilService,
    public dblinkedSessionService: DblinkedSessionService
  ) { }

  // controles locais
  title: string;
  subtitle: string;
  msgs: Message[] = [];
  msgsModal: Message[] = [];
  pt_BR: any;
  // filtros
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;
  modalPeriodo: boolean;
  saldoAtual: number;
  valorFiltro: number;
  // tabela
  fichas: Array<any> = new Array();
  fichasSelecionadas: Array<any> = new Array();
  fichasFiltradas: Array<any> = new Array();
  ids: Array<any> = new Array();

  idBoletosCheck: Array<any> = new Array();
  todosSelecionados: boolean;

  opcoesComboUf : SelectItem[];
  opcoesComboEvento: SelectItem[];
  colsFichas : any[] = [];

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titleService.title = 'Confirmação de Leitura';
    this.title = 'Eventos';
    this.subtitle = 'Visualizar confirmações de leitura';
    this.defaultDate = new Date();
    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;
    this.fichas = new Array<any>();
    this.modalPeriodo = false;
    this.saldoAtual = 0.00;
    this.valorFiltro = 0.00;
    this.idBoletosCheck = new Array();


    this.todosSelecionados = false;

    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.colsFichas = [
      { field: 'dataEvento', header: 'Data Evento' },
      { field: 'descricaoEvento', header: 'Evento' },
      { field: 'cidadeEvento', header: 'Cidade' },
      { field: 'ufEvento', header: 'UF' },
      { field: 'nome', header: 'Nome' },
      { field: 'email', header: 'E-mail' },
      { field: 'fone', header: 'Fone' },
      { field: 'cpf', header: 'CPF' },
      { field: 'dataConfLeitura', header: 'Confirmação Leitura' },
      { field: 'dataConfClick', header: 'Confirmação Li e Aceito' }
    ];

    this.subscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { setTimeout(() => { this.toolbarEnable(); }, 1000); this.start(); });
  }

  start() {
    // formatação de datas
    let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    let mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
      if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
        mes = this.dblinkedSessionService.periodo.month.value > 8 ?
          this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
        const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
        this.defaultDate = new Date(dt);
        // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
        lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
        this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
        this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

      }

      this.ids = [];
      this.todosSelecionados = false;
      this.toolbarEnable();

      // final formatação de datas
      this.idBoletosCheck = new Array();
      this.modalPeriodo = false;
      this.saldoAtual = 0.00;
      this.valorFiltro = 0.00;
      this.getComboUf();

      // buscando as infos
      this.httpService.wait();
      this.httpService.get('/custom/evento/listar-conf-mail-ficha('+this.dblinkedSessionService.empresa.id+')')
        .subscribe(retorno => {
          this.opcoesComboEvento = [];
          this.fichas = retorno;

          retorno.forEach(element => {
            var index = 0;
            if ((this.opcoesComboEvento !== null || this.opcoesComboEvento !== undefined)) {
              index = this.opcoesComboEvento.findIndex(e => e.value === element.descricaoEvento);
            }

            if (index === -1) {
              this.opcoesComboEvento.push({label: element.descricaoEvento , value: element.descricaoEvento});
            }

          });

        },
          error => this.httpService.handleError(error, () => this.start()),
          () => this.httpService.done());
      // final busca
    }

  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.sessionService.destroySubscribe(this.subscription);
  }

  private toolbarEnable() {
    const todos = this.buttons.slice();
    if (todos.length === 0) {
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      // this.toolbarService.replace('edit', false,
      //                             'class-button-gray', 'ATUALIZAR', 'ATUALIZAR', () => this.openModalPeriodo());
      this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
      this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');
      this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Novo');
    } else {
      this.toolbarService.ocultar('voltar');
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.ocultar('save');
      this.toolbarService.ocultar('edit');
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      // this.toolbarService.replace('edit', false,
      //                             'class-button-gray', 'ATUALIZAR', 'ATUALIZAR', () => this.openModalPeriodo());
      this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
      //       this.toolbarService.alterarStatus('check', false, 'Aprovar todos', () => this.modalStatusPedidosLote());
      this.toolbarService.replace('pesquisa', true, 'class-edit-button', 'search', 'Pesquisar');
      this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar');
      this.toolbarService.replace('print', true, 'class-edit-button', 'print', 'Imprimir');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');
      this.toolbarService.replace('add', true, 'class-new-button', 'ATUALIZAR', 'ATUALIZAR');
    }
  }


  hideMessage() {


  }

  rowStyleClass(fichas: any): string {
    return fichas.status === 2 ||  fichas.status === 6 ? 'bold' : '';
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  getComboUf(){

    this.httpService.wait();
    this.httpService.get('/uf?$select=id,uf&$orderby=uf').subscribe(ref => {

      this.opcoesComboUf = [];
      this.opcoesComboUf.push({label: '', value: ''});
      ref.value.forEach(element => {
        this.opcoesComboUf.push({label: element.uf, value: element.uf});
      });

    },
      error => this.httpService.handleError(error, () => this.getComboUf()),
      () => this.httpService.done());



  }



}
