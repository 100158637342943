import { Fatura } from "./fatura.model";
import { Participante } from "app/cadastro/model/participante.model";
import { PlanoContas } from "app/contabil/model/plano-contas.model";
import { Moeda } from "app/util/model/moeda.model";
import { AuthUser } from "app/proof/model/auth-user.model";
import { FaturaRateio } from "./fatura-rateio.model";

export class FaturaItem {
  id: number;
  fatura: Fatura;
  dataCompra : Date;
  participante : Participante;
  planoContas : PlanoContas;
  authUser : AuthUser;
  historico :String;
  moeda : Moeda;
  valorCompra : any;
  qtdeParcelas : any;
  valorParcela : any;
  taxa : any;
  creditoEstorno : string;
  contemArquivo : number;
  chaveUploadArquivo : string;
  complemento : boolean;
  addItem: boolean;
  faturaRateio: Array<FaturaRateio>;

  constructor(faturaItem?: FaturaItem | number) {
    if (faturaItem !== undefined && faturaItem !== null) {
      if (typeof faturaItem === 'number') {
        this.id = faturaItem;
      } else {
        this.id = faturaItem.id;
        this.fatura = faturaItem.fatura;
        this.dataCompra = faturaItem.dataCompra;
        this.participante = faturaItem.participante;
        this.planoContas = faturaItem.planoContas;
        this.historico = faturaItem.historico;
        this.moeda = faturaItem.moeda;
        this.valorCompra = faturaItem.valorCompra;
        this.qtdeParcelas = faturaItem.qtdeParcelas;
        this.valorParcela = faturaItem.valorParcela;
        this.authUser = faturaItem.authUser;
        this.taxa = faturaItem.taxa;
        this.creditoEstorno = faturaItem.creditoEstorno;
        this.contemArquivo = faturaItem.contemArquivo;
        this.chaveUploadArquivo = faturaItem.chaveUploadArquivo;
        this.faturaRateio = faturaItem.faturaRateio;
        this.complemento = faturaItem.complemento;
        this.addItem = faturaItem.addItem;
      }
    }
  }




}