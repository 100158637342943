import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { Pais } from 'app/util/model/pais.model';
import { Uf } from 'app/util/model/uf.model';

export class Endereco {
  id: number;
  logradouro: string;
  numero: string;
  complemento: string;
  cep: string;
  fone: string;
  bairro: string;
  municipioIbge: MunicipioIbge;
  pais: Pais;
  cidade: string;
  uf: Uf

  constructor(endereco?: Endereco | number) {
    if (endereco !== undefined && endereco !== null) {
      if (typeof endereco === 'number') {
        this.id = endereco;
      } else {
        this.id = endereco.id;
        this.logradouro = endereco.logradouro;
        this.numero = endereco.numero;
        this.complemento = endereco.complemento;
        this.cep = endereco.cep;
        this.fone = endereco.fone;
        this.bairro = endereco.bairro;
        this.municipioIbge = endereco.municipioIbge;
        this.pais = endereco.pais;
        this.cidade = endereco.cidade;
        this.uf = endereco.uf;
      }
    }
  }
}
