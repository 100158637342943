import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from 'app/shared/services/config.service';
import { Subscription } from 'rxjs';
import { UtilService } from 'app/shared/services/util.service';
import { HttpService } from 'app/shared/services/http.service';

@Component({
  selector: 'app-teste',
  templateUrl: './importar-fp.component.html',
  styleUrls: ['./importar-fp.component.scss']
})

export class RHImportarPlanilhasComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;

  file: File = null;
  filename: string = null;
  listTipoArquivo: Array<any> = new Array();
  idTipoArquivo: any;
  tipo: number;
  versaoId: number = null;
  empresaId: number = null;
  listVersao: Array<any> = new Array();
  configOrcaId: number = null;
  listConfigOrca: Array<any> = new Array();

  sobrescrever_arquivo: boolean = false;
  sobrescrever_dados: boolean = false;
  verificar_premissas: boolean = true

  afd_file: any;
  msgs: any;

  private sessionSubscription: Subscription;

  constructor(private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private httpService: HttpService,
    private utilservice: UtilService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.listTipoArquivo.push({ label: 'Marcação de ponto eletrônico (AFD)', value: 0 });
    this.listTipoArquivo.push({ label: 'Folha de pagamento (Excel)', value: 1 });
    this.listTipoArquivo.push({ label: 'Folha de pagamento - Complemento (Excel)', value: '1a' });
    this.listTipoArquivo.push({ label: 'Provisão de 13° salário (Excel)', value: 2 });
    this.listTipoArquivo.push({ label: 'Provisão de férias (Excel)', value: 3 });
    this.listTipoArquivo.push({ label: 'Rateio (Excel)', value: 4 });
    this.listTipoArquivo.push({ label: 'Orçamento RH (MdO)', value: 5 });
    this.listTipoArquivo.push({ label: 'Orçamento RH (Premissas)', value: 6 });

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
          this.getVersaoOrca();
        }
      });
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe()
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
    this.filename = this.file.name;

    // Verificar se o arquivo a ser importado é do tipo .txt
    if (this.file.name.substr(this.file.name.length - 4) == '.txt') {
      var fileReader = new FileReader();

      fileReader.onloadend = (e) => {
        this.afd_file = fileReader.result;
      }

      fileReader.readAsText(this.file, 'cp1252');
    }
  }

  importar() {
    // Arquivo AFD
    if (this.idTipoArquivo == 0) {
      this.importarAfd();

    } else if (this.idTipoArquivo === 5) {
      this.importarMdOGestores()
      // const httpOptions = {
      //   headers: new HttpHeaders({
      //     'empresaid': this.dblinkedSessionService.empresa.id.toString(),
      //     'versaoid': this.versaoId.toString(),
      //     'Authorization': this.configService.bearerApi
      //   })
      // };

      // this.httpService.wait()
      // this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/verifpremissas', httpOptions)
      //   .subscribe(
      //     res => {
      //       this.httpService.done();
      //       if (res["mensagem"]) {
      //         if (confirm("Premissas para essa versão já consta no banco de dados. Deseja sobrepôr?")) {
      //           this.fazerUpload();
      //         }
      //       } else {
      //         this.fazerUpload();
      //       }
      //     },

      //     err => {
      //       this.httpService.done();
      //       this.msgs = []
      //       this.msgs.push({ severity: 'warn', summary: 'Erro', detail: err });
      //       console.log(err)
      //     }
      //   );

    } else if (this.idTipoArquivo === 6) {
      this.importarPremissasMdO()

    } else {
      if (this.idTipoArquivo === '1a') {
        this.tipo = 1
      } else {
        this.tipo = this.idTipoArquivo.toString()
      }

      const httpOptions = {
        headers: new HttpHeaders({
          'tipo': this.tipo.toString(),
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'mes': this.dblinkedSessionService.periodo.month.value < 10 ? '0' + this.dblinkedSessionService.periodo.month.value.toString() : this.dblinkedSessionService.periodo.month.value.toString(),
          'ano': this.dblinkedSessionService.periodo.year.value.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'Authorization': this.configService.bearerApi
        })
      };

      const formData = new FormData();
      formData.append('file', this.file);

      this.httpService.wait();
      if (!this.utilservice.validArquivo(this.filename)) {
        this.httpClient.post(this.configService.defaultUrlApiUpload + 'contabil/confirm', formData, httpOptions)
          .subscribe(
            res => {
              this.verificarPeriodo(res);
            },

            err => {
              console.log(err)
            },
            () => this.httpService.done()
          );
      }

      else {
        this.msgs = [];
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Aviso:', detail: 'O nome do arquivo não pode conter carateres acentuados e/ou especiais.' });
      }
    }
  }

  getVersaoOrca() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'ano': this.dblinkedSessionService.periodo.year.value.toString(),
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/versao', httpOptions)
      .subscribe(
        res => {
          this.listVersao = [];
          var result = res["data"];
          for (let line of result) {
            this.listVersao.push({ "label": line["descricao"], "value": line["id"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  fazerUpload() {
    if (this.idTipoArquivo != 5) {
      const httpOptions = {
        headers: new HttpHeaders({
          'tipo': this.tipo.toString(),
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'mes': this.dblinkedSessionService.periodo.month.value < 10 ? '0' + this.dblinkedSessionService.periodo.month.value.toString() : this.dblinkedSessionService.periodo.month.value.toString(),
          'ano': this.dblinkedSessionService.periodo.year.value.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'Authorization': this.configService.bearerApi
        })
      };

      const formData = new FormData();
      formData.append('file', this.file);
      let route = 'contabil/upload';
      if (this.idTipoArquivo === '1a') {
        route = 'contabil/upload_comp';
      }

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + route, formData, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
          },

          err => {
            console.log(err)
            this.exibirMensagem(err);
          },
          () => this.httpService.done()
        );
    } else if (this.idTipoArquivo === 5) {

      if (!this.utilservice.validArquivo(this.filename)) {
        const httpOptions = {
          headers: new HttpHeaders({
            'empresaid': this.dblinkedSessionService.empresa.id.toString(),
            'userid': this.sessionService.loggedUser.id.toString(),
            'versaoid': this.versaoId.toString(),
            'Authorization': this.configService.bearerApi,
            'confid': this.configOrcaId === null ? "0" : this.configOrcaId.toString()
          })
        };

        const formData = new FormData();
        formData.append('file', this.file);

        this.httpService.wait()
        this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/premissas_old', formData, httpOptions)
          .subscribe(
            res => {
              this.exibirMensagem(res);
            },

            err => {
              console.log(err)
              this.exibirMensagem(err);
            },
            () => this.httpService.done()
          );

      } else {

        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Aviso:', detail: 'O nome do arquivo não pode conter carateres acentuados e/ou especiais.' });
      }

    }
  }

  verificarPeriodo(value: any) {
    if (value === 1) {
      if (confirm('Período já possui informações, deseja substituir?')) {
        this.fazerUpload();
      }

    } else if (value === 0) {
      this.fazerUpload();

    } else if (value === 2) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Aviso:', detail: 'Período Encerrado, não é possível fazer alterações!' });

    } else if (value.error) {
      this.exibirMensagem(value);
    }
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  get importDisabled(): boolean {
    return !this.dblinkedSessionService.hasEmpresaSelected || !this.file;
  }

  hideMessage() {
    this.file = null;
    this.filename = null;

    document.getElementById('file-name').innerText = '';
  }


  importarAfd() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
      })
    };

    // Verificar se o arquivo a ser importado é do tipo .txt
    if (this.file.name.substr(this.file.name.length - 4) != '.txt') {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Aviso:', detail: 'Arquivo inválido. Favor importar um arquivo de texto (formato .txt)' });
    } else {

      const afd_data = {
        dados: this.afd_file,
        empresa_id: this.dblinkedSessionService.empresa.id.toString(),
        usuario_id: this.sessionService.loggedUser.id,
        sobrescrever: this.sobrescrever_arquivo || this.sobrescrever_dados,
        sobrescrever_arquivo: this.sobrescrever_arquivo,
        sobrescrever_dados: this.sobrescrever_dados
      }

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'afd/ler-dados-afd', afd_data, httpOptions)
        .subscribe(result => {

          this.msgs = [];

          // Caso o retorno tenha produzido algum erro, exibir a mensagem ao usuário.
          if (result["error"] === true) {
            this.msgs.push({ severity: 'error', summary: '', detail: result["message"] });
          } else {
            if (result["warn"] === true) {
              if (result["arquivo_repetido"] === true) {
                if (confirm('Atenção! Este arquivo já foi gravado. Deseja sobrescrever seus dados?')) {
                  this.sobrescrever_arquivo = true;
                  this.sobrescrever_dados = false;
                  this.importar();
                } else {
                  this.msgs.push({ severity: 'warn', summary: '', detail: 'Nenhum dado gravado: Sobrescrita cancelada.' });
                  this.sobrescrever_arquivo = false;
                  this.sobrescrever_dados = false;
                }
              } else {
                if (confirm('Atenção! Um ou mais registros referentes a este arquivo já foram gravados. Deseja sobrescrevê-los?')) {
                  this.sobrescrever_arquivo = false;
                  this.sobrescrever_dados = true;
                  this.importar();
                } else {
                  this.msgs.push({ severity: 'warn', summary: '', detail: 'Nenhum dado gravado: Sobrescrita cancelada.' });
                  this.sobrescrever_arquivo = false;
                  this.sobrescrever_dados = false;
                }
              }

            } else {
              this.msgs.push({ severity: 'success', summary: '', detail: result["message"] });
              this.sobrescrever_arquivo = false;
              this.sobrescrever_dados = false;
            }
          }

          this.httpService.done();
        },

          error => this.httpService.handleError(error,
            () => this.importar()),
          () => this.httpService.done());
    }

  }

  importarPremissasMdO() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaId': this.dblinkedSessionService.empresa.id.toString(),
        'userId': this.sessionService.loggedUser.id.toString(),
        'versaoId': this.versaoId.toString(),
        'verificar': this.verificar_premissas.toString()
      })
    };

    const formData = new FormData();
    formData.append('file', this.file);

    this.msgs = []
    this.msgs.push({ severity: "info", summary: "Aviso", detail: "Importação em andamento, pode demorar alguns minutos." })
    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/import/premissas', formData, httpOptions)
      .subscribe(result => {
        if (result["error"]) {
          this.exibirMensagem(result)
          if (result["data"]) {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0'
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/import/premissas', httpOptions2)
              .subscribe(
                res => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);
                  let fileName = "log.txt";
                  link.href = url;
                  link.download = fileName;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem({ severity: "error", mensagem: "Erro no API: não foi possível baixar o log: " + err.message });
                  console.log(err);
                  this.httpService.done();
                });

          } else {
            this.httpService.done();
          }

        } else if (result["data"] && !result["error"]) {
          if (confirm("Já existem dados no banco de dados para essa versão, deseja sobrepôr?")) {
            this.verificar_premissas = false
            this.importarPremissasMdO()
          }

        } else {
          this.exibirMensagem(result)
        }

        this.httpService.done();
      },

        error => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao importar as premissas, entre em contato com o suporte: " + error.message });
          console.log(error);
          this.httpService.done();
        });
  }

  importarMdOGestores() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaId': this.dblinkedSessionService.empresa.id.toString(),
        'userId': this.sessionService.loggedUser.id.toString(),
        'versaoId': this.versaoId.toString(),
        'verificar': this.verificar_premissas.toString(),
        'ano': this.dblinkedSessionService.periodo.year.value.toString()
      })
    };

    const formData = new FormData();
    formData.append('file', this.file);

    this.msgs = []
    this.msgs.push({ severity: "info", summary: "Aviso", detail: "Importação em andamento, pode demorar alguns minutos." })
    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/import/mdo', formData, httpOptions)
      .subscribe(result => {
        if (result["error"]) {
          this.exibirMensagem(result)
          if (result["data"]) {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0'
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/import/mdo', httpOptions2)
              .subscribe(
                res => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);
                  let fileName = "log.txt";
                  link.href = url;
                  link.download = fileName;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem({ severity: "error", mensagem: "Erro no API: não foi possível baixar o log: " + err.message });
                  console.log(err);
                  this.httpService.done();
                });

          } else {
            this.httpService.done();
          }

        } else if (result["data"] && !result["error"]) {
          if (confirm("Já existem dados no banco de dados para essa versão, deseja sobrepôr?")) {
            this.verificar_premissas = false
            this.importarMdOGestores()
          }

        } else {
          this.exibirMensagem(result)
        }

        this.httpService.done();
      },

        error => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao importar a mão de obra, entre em contato com o suporte: " + error.message });
          console.log(error);
          this.httpService.done();
        });
  }
}
