import { ConfigPattern } from "../interfaces/asaas"

export function resetConfigPattern(): ConfigPattern {
  let configPattern: ConfigPattern = {
    razao: null,
    cnpj: null,
    telefone: null,
    logradouro: null,
    numero: null,
    complemento: null,
    bairro: null,
    cep: null,
    email: null,
    chaveApi: null,
    usuario: null,
    walletId: null,
    accountStatus: null,
    onboardingUrl: null
  }

  return {...configPattern}
}