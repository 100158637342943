import { Component, OnInit, OnDestroy } from '@angular/core';
import { CrudService } from 'app/shared/services/crud.service';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { HttpService } from 'app/shared/services/http.service';
import { Mi } from '../model/mi.model';
import { SessionService } from 'app/shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { RespCadItem } from '../model/resp-cad-item.model';
import { AuthUser } from 'app/proof/model/auth-user.model';

import { Toolbar } from 'app/shared/model/toolbar.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { MessageService } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';

@Component({
  selector: 'app-resp-cad-item',
  templateUrl: './resp-cad-item.component.html',
  styleUrls: ['./resp-cad-item.component.scss'],
  providers: [CrudService]
})
export class RespCadItemComponent extends DataTableBasicComponent
implements OnInit, OnDestroy {
  public respCadItem: RespCadItem = new RespCadItem();
  public registrosResponsaveis: Array<RespCadItem> = new Array();
  public empresaSelecionada: number;

  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeEditSubscription: Subscription;

  entityFilter: string = null;
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;
  ids: Array<any> = new Array();
  responsaveis: string;


  title = 'RESPONSÁVEIS CADASTRO DE ITEM';
  subtitle = "RESPONSÁVEL";

  buttonToolbar = this.getButtonToolbarContent();
  toolbarMainIni = this.getToolbarMainIniContent();

  public toolbarMain = new Toolbar();

  private sessionChanged: Subscription;

  constructor(
    private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private toolbarService: ToolbarService,
    private messageService: MessageService,
    private configService: ConfigService,
  ) {
    super(crudService);
  }

  ngOnInit() {
    this.toolbarService.hidden = true;
    this.dblinkedSessionService.hidePeriods();
    this.ctrlSave = true;
    this.toolbarMain.setAll(this.toolbarMainIni);

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
      );

    this.beforeSaveSubscription = this.crudService.beforeSave.subscribe(
      (respCadItem: RespCadItem) => {

        if ( this.ctrlSave ) {
          respCadItem.empresa = new Empresa(this.dblinkedSessionService.empresa.id);
          respCadItem.authUser = new AuthUser(this.sessionService.loggedUser.id);
        }

      }
    );

    this.beforeEditSubscription = this.crudService.beforeEdit.subscribe((respCadItem: RespCadItem) => {
      this.ctrlSave = false;
    });

    this.sessionChanged = this.sessionService.initSubscribe(
      this.dblinkedSessionService.empresaChanged,
      () => {
        this.empresaChanged();
      }
    );
  }

  carregar() {
    this.title = 'RESPONSÁVEIS CADASTRO DE ITEM';
    this.subtitle = 'RESPONSÁVEL';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();

    this.carregaBotoesToolbarInicial();
    this.limpaCamposForm();

    this.loadRegistros();
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar';

    this.carregaBotoesToolbarFormNovo();
  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService
      .get(`/respcaditem?$select=responsaveis&$count=true&$skip=0&$orderby=responsaveis`)
      .subscribe(
        (retorno) => {
          this.registrosResponsaveis = new Array();
          retorno.value.forEach(item => {
            this.registrosResponsaveis.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar';
    this.editarConta = true;

    this.carregaBotoesToolbarFormEditar();
    this.limpaCamposForm();

    this.httpService.wait();
    this.httpService.get(`/respcaditem(${this.ids[0]})?$select=responsaveis&$count=true`)
      .subscribe(item => {
        this.responsaveis = item.value.responsaveis;
      },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      responsaveis: this.responsaveis,
      empresa: {
        id: this.dblinkedSessionService.empresa.id,
      },
      authUser: new AuthUser(this.sessionService.loggedUser.id)
    };

    this.httpService.wait();
    this.httpService.post('/respcaditem', jsonData)
      .subscribe(result => {
        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      responsaveis: this.responsaveis,
    };

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/respcaditem', key, jsonData, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      this.httpService.wait();
      this.httpService.delete('/respcaditem', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Registro excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());
    }
  }

  selectCheckboxContainer(data){
    setTimeout(() => {
      document.getElementById('checkboxContainer'+data.id).click();
    },100);
  };

  selectCheckbox(event: any) {
    this.ids.push(event.data.id);
    event.selected = false;
    this.filtraFuncoesToolbar();
  }

  unselectCheckbox(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.filtraFuncoesToolbar();
  }

  filtraFuncoesToolbar() {
    if (this.ids.length === 1) {
      this.toolbarMain.setDisabled(this.buttonToolbar.excluir, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.editar, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
    }else {
      this.toolbarMain.setDisabled(this.buttonToolbar.excluir, true);
      this.toolbarMain.setDisabled(this.buttonToolbar.editar, true);
      this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
    }
  }


  validacao(): boolean {
    if ((this.responsaveis === null || this.responsaveis === undefined) || this.responsaveis.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório' });
      return true;
    }

    return false;
  }

  private getButtonToolbarContent(){
    return {
      voltar: 'btn1',
      excluir: 'btn2',
      editar: 'btn3',
      adicionarNovo: 'btn4',
      salvarNovo: 'btn5',
      salvarAlteracao: 'btn6',
    }
  }

  provisorio(){}

  private getToolbarMainIniContent(){
    return [
      { icon: 'undo', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: true, color: 'default', onClick: () => this.carregar() },
      { icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: true, disabled: true, color: 'red', onClick: () => this.excluirRegistro() },
      { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: true, disabled: true, color: 'default', onClick: () => this.loadEditarRegistro() },
      { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.criarNovo() },
      { icon: 'check', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: true, disabled: false, color: 'green', onClick: () => this.salvarNovoRegistro() },
      { icon: 'check', text: 'Salvar', tooltip: 'Salvar Alteração', visible: true, disabled: false, color: 'green', onClick: () => this.salvarAlteracaoRegistro() },
    ];
  }

  private carregaBotoesToolbarFormEditar(){
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, true);

    this.toolbarMain.setDisabled(this.buttonToolbar.voltar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.salvarAlteracao, false);
  }

  private carregaBotoesToolbarFormNovo(){
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, true);

    this.toolbarMain.setDisabled(this.buttonToolbar.voltar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.salvarNovo, false);
  }

  private carregaBotoesToolbarInicial(){
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.excluir, true);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, true);

    this.toolbarMain.setDisabled(this.buttonToolbar.excluir, true);
    this.toolbarMain.setDisabled(this.buttonToolbar.editar, true);
    this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
  }

  private ocultaBotoesToolbar(){
    this.toolbarMain.setVisible(this.buttonToolbar.voltar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.excluir, false);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, false);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, false);
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionChanged);
  }

  private empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.entityFilter =
        'empresa/id eq ' + this.dblinkedSessionService.empresa.id;

      this.columns = [
        new DataTableColumn('Responsáveis', 'responsaveis')
      ];

      this.controls = [
        new DataControl('Responsáveis', 'responsaveis').setRequired()
      ];
    }
  }


  private limpaCamposForm() {
    this.responsaveis = '';
  }


  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
