import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { MessageService, SelectItem } from 'primeng/api';
import { ConfigPattern } from '../../interfaces/eye';
import { resetConfigPattern } from '../../utils/reset-eye-form';

@Component({
  selector: 'app-cfg-eye',
  templateUrl: './cfg-eye.component.html',
  styleUrls: ['./cfg-eye.component.scss']
})
export class CfgEyeComponent implements OnInit, OnChanges {

  @Input() isRender: boolean
  @Input() isSave: boolean
  @Input() isConfigExists: boolean
  @Output() isValid = new EventEmitter()

  httpOptions: Object = new Object()

  _isConfigExists: boolean = false
  loadingConfigPattern: boolean = false
  loadingOptClient: boolean = false
  loadingIconOptClient: boolean = false
  loadingIconOptItens: boolean = false
  modalParticipante: boolean = false

  empresaId: number = null
  userId: number = null
  tipoParticipanteId: number = null

  codigoCliente: string = null
  nomeFantasiaCliente: string = null

  optItens: SelectItem[] = []

  config: ConfigPattern = resetConfigPattern()

  constructor(
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  public ngOnInit(): void {
    this.empresaId = this.dblinkedSessionService.empresa.id
    this.userId = this.sessionService.loggedUser.id

    this.isValid.emit({ validatedSteps: false })
    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    this.getOptItens()
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.isSave && changes.isSave.currentValue) this.saveConfigPattern()
    if(changes.isConfigExists && changes.isConfigExists.currentValue) this._isConfigExists = true
    if(changes.isRender && changes.isRender.currentValue) this.initialize(this._isConfigExists)
  }

  private async initialize(isConfigExists: boolean): Promise<void> {
    this.isValid.emit({ validatedSteps: true })

    if (isConfigExists) this.getConfigPattern()
  }

  private async getConfigPattern(): Promise<void> {
    this.loadingConfigPattern = true

    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/cadastro/configuracao-api?empresaId=${this.empresaId}&codigo=EYE`, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          this.config.usuario = res['data'][0].usuario
          this.config.senha = res['data'][0].senha
          this.config.cliente_padrao_id = res['data'][0].cliente_padrao_id
          this.codigoCliente = res['data'][0].codigo_cliente
          this.nomeFantasiaCliente = res['data'][0].fantasia_cliente
          this.config.taxaServicoId = res['data'][0].taxa_servico_id
        } else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar os dados padrões da sua configuração! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.validationConfig()
      this.loadingConfigPattern = false
    }
  }

  private async saveConfigPattern(): Promise<void> {
    this.isValid.emit({ validatedSteps: true })
    this.validationConfig()

    const payload = {
      empresa_id: this.empresaId,
      user_id: this.userId,
      codigo: 'EYE',
      cliente_padrao_id: this.config.cliente_padrao_id,
      taxa_servico_id: this.config.taxaServicoId,
      usuario: this.config.usuario,
      senha: this.config.senha
    }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/configuracao-api', payload, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) this.saveFamilyRelationship()
        else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar os dados padrões da sua configuração! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async saveFamilyRelationship(): Promise<void> {
    const httpOptions: Object = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    const payload: Object = { empresa_id: this.dblinkedSessionService.empresa.id, familia_id: 1 }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/empresa-familia', payload, httpOptions).toPromise().catch(err =>
        this.showResponse('error', err.status + ' - Houve um erro ao salvar a família de API Matera!')
      )
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  public loadCliente(tipoParticipanteId: number) {
    this.tipoParticipanteId = tipoParticipanteId
    this.modalParticipante = true
  }

  public salvarCliente(data) {
    this.codigoCliente = data.selectedParticipant.cod_part
    this.nomeFantasiaCliente = data.selectedParticipant.fantasia
    this.config.cliente_padrao_id = data.selectedParticipant.part_emp_id
    this.validationConfig()
  }

  public validationConfig(): void {
    this.isValid.emit({ validatedSteps: ( this.config.cliente_padrao_id ) })
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso: ', detail: message })
      break
    }
  }

  private async getOptItens(): Promise<void> {
    this.loadingIconOptItens = true
    const infos = {
      operacao: 1,
      tipo_item: [1],
      empresa_id: this.empresaId
    }

    try {
      await this.httpClient.post(`${this.configService.defaultUrlApiUpload}cadastro/itens-filtrados`, infos, this.httpOptions).toPromise().then(res => {
        res["data"].forEach(element => this.optItens.push({label: element.codigo + " - " + element.descricao, value: element.id}))
      }).catch(err => this.showResponse('error', 'Houve um erro ao buscar os itens: ' + err.error.message))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingIconOptItens = false
    }
  }

}
