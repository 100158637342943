import { Component, OnInit, Input } from '@angular/core';
import { SkeletonModel } from './models/skeletonModel';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})

export class SkeletonComponent implements OnInit {
  @Input() config: SkeletonModel;
  constructor() {
  }

  ngOnInit(): void {
  }

}
