export class TodoAlcadasAcoes {
  id: number;
  descricao: string;

  constructor(todoAlcadasAcoes?: TodoAlcadasAcoes | number) {
    if (todoAlcadasAcoes !== undefined && todoAlcadasAcoes !== null) {
      if (typeof todoAlcadasAcoes === 'number') {
        this.id = todoAlcadasAcoes;
      } else {
        this.id = todoAlcadasAcoes.id;
        this.descricao = todoAlcadasAcoes.descricao;
      }
    }
  }
}
