import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { EmpresaConta } from '../model/empresa-conta.model';

@Component({
  selector: 'app-tipo-pagamento',
  templateUrl: './tipo-pagamento.component.html',
  styleUrls: [
    './tipo-pagamento.component.scss'
  ]
})
export class TipoPagamentoComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {}

  private sessionSubscription: Subscription;

  title = '';
  subtitle = '';

  // components;
  tiposLista = [];
  showLista = false;
  showNew = false;
  showEdit = false;
  codigo = '';
  descricao = '';
  cnabFormaId = '';
  tipoSelecionadoId = 0;
  instrucaoBoleto = null;
  codigoCarteira = null;
  conta: EmpresaConta;
  diasExpiracao: number = 0
  qtdeDiasDesconto: number = 0

  // dropdowns;
  formaCnabList: SelectItem[] = [];
  expirationDaysOptions: SelectItem[] = [];
  expirationDaysOptionsSantander: SelectItem[] = [];
  formaCnab: number = null;
  opcoesContaBancaria: SelectItem[];
  contaSelecionadaId: any;

  // msgs;
  msgs: Message[] = [];

  // date;
  defaultDate: Date;
  pt_BR: any;

  contasBancariasCompletas = []
  instituicaoFinanceira: number = null
  variacaoCarteira: string = null

  expirationDaysOptionsPreDefined: boolean = false
  minExpirationdays: number = 0
  maxExpirationdays: number = 0

  optLevy: Array<any> = [
    {value: "FIXED", label: "Fixo"},
    {value: "PERCENTAGE", label: "Porcentagem"}
  ]
  tipo_juros: string = null
  valor_juros: number = 0
  tipo_multa: string = null
  valor_multa: number = 0
  tipo_desconto: string = null
  valor_desconto: number = 0

  clonagem: boolean = false
  clonagemNaPropriaEmpresa: boolean = false
  empresaRealizaAlteracoes: boolean = false
  cadastroGrupoEmpresa: boolean = false
  tiposPagamentosSelecionados: Array<any> = new Array()
  empresasGrupo: SelectItem[] = new Array()
  empresasGrupoSelecionadas: Array<any> = new Array()

  modalGrupoEmpresas: boolean = false
  tituloModalGrupoEmpresas: string = ''
  descricaoModalGrupoEmpresas: string = ''

  novoCodigo: string = null
  novaDescricao: string = null


  public toolbarTipoPag = new Toolbar()

  toolbarMainTipoPag = [
    { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadComponent('edit', this.tiposPagamentosSelecionados[0]) },
    { key: 'btn-delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.cadastroGrupoEmpresa ? this.excluirTipoPagamentoGrupoEmpresa() : this.excluirTipoPagamento() },
    { key: 'btn-save-new', icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoSalvamento() },
    { key: 'btn-save-edit', icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoSalvamento() },
    { key: 'btn-add', icon: 'add', text: 'Nova', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.novaConta() },
    { key: 'btn-clone', icon: 'content_copy', text: 'Clonar Regra', tooltip: 'Clonar Regra', visible: false, disabled: false, color: 'default', onClick: () => this.setarVariaveisClonagem(true) },
    {
      key: 'btn-clone-expanded', icon: 'content_copy', text: '', tooltip: 'Clonar', visible: false, disabled: false, color: 'default', dropdown: true,
      options: [
        { label: 'Clonar no grupo de empresas', callback: () => this.setarVariaveisClonagem(false) },
        { optionKey: 'btn-unique-clone', label: 'Clonar somente na empresa atual', callback: () => this.setarVariaveisClonagem(true) },
      ]
    },
    { key: 'btn-refresh', icon: 'replay', text: 'Atualizar', tooltip: 'Atualizar', visible: false, disabled: false, color: 'default', onClick: () => this.changeSession() },
    { key: 'btn-back', icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.voltar() },

  ]

  ngOnInit() {
    this.toolbarTipoPag.setAll(this.toolbarMainTipoPag)
    this.toolbarService.hidden = true
    this.dblinkedSessionService.hidePeriods();
    Promise.resolve(null).then(() => this.refreshToolbar());

    this.title = 'Cobranças e pagamentos';

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
      }
    );

    this.instituicaoFinanceira = null
    this.variacaoCarteira = null

    this.defaultDate = new Date();
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.expirationDaysOptionsSantander = [
      { label: '15 dias', value: 2 },
      { label: '30 dias', value: 3 },
    ]

    this.minExpirationdays = 1
    this.maxExpirationdays = 999
    this.tiposPagamentosSelecionados = new Array()
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  public loadComponent(component: string, rowValue: any) {
    this.tiposPagamentosSelecionados = new Array()

    // inicializando variáveis para validação posterior;

    // show;
    this.showLista = component === 'lista' ? true : false
    this.showEdit = component === 'edit' ? true : false
    this.showNew = component === 'new' ? true : false

    this.loadFormaCnabList()
    this.loadTiposPagamentos()

    if (component === 'new') {
      this.subtitle = 'novo tipo de pagamento'
      this.tipoSelecionadoId = 0
      this.descricao = ""
      this.codigo = ""
      this.formaCnab = 0
      this.instrucaoBoleto = ""
      this.codigoCarteira = ""
      this.contaSelecionadaId = new EmpresaConta()
      this.variacaoCarteira = ""
      this.diasExpiracao = 0
      this.qtdeDiasDesconto = 0
      this.valor_desconto = 0
      this.valor_juros = 0
      this.valor_multa = 0
      this.tipo_desconto = null
      this.tipo_juros = null
      this.tipo_multa = null
    } else if (component === 'lista') {
      this.subtitle = 'tipos de pagamento'

    } else if (component === 'edit'){

      this.tipoSelecionadoId = rowValue["id"];
      this.descricao = rowValue["descricao"];
      this.codigo = rowValue["codigo"];
      this.formaCnab = rowValue["cnab_id"];
      this.instrucaoBoleto = rowValue["instrucaoBoleto"];
      this.codigoCarteira = rowValue["codigoCarteira"];
      this.contaSelecionadaId = rowValue["empresaContaId"]
      this.variacaoCarteira = rowValue["variacaoCarteira"]
      this.diasExpiracao = rowValue["expiracaoPix"]
      this.qtdeDiasDesconto = rowValue["qtdeDiasDesconto"]
      this.valor_desconto = rowValue["valorDesconto"]
      this.valor_juros = rowValue["valorJuros"]
      this.valor_multa = rowValue["valorMulta"]
      this.tipo_desconto = rowValue["tipoDesconto"]
      this.tipo_juros = rowValue["tipoJuros"]
      this.tipo_multa = rowValue["tipoMulta"]
      if(this.formaCnab === 1) {
        this.loadContasBancarias()
      }
    }

    this.changeToolbar()
  }

  novaConta(){
    this.showLista = false
    this.showNew = true
    this.changeToolbar()
  }

  voltar(){
    this.showLista = true
    this.showNew = false
    this.showEdit = false
    this.clonagem = false
    this.clonagemNaPropriaEmpresa = false
    this.modalGrupoEmpresas = false

    this.novoCodigo = null
    this.novaDescricao = null
    this.descricao = ""
    this.codigo = ""
    this.formaCnab = 0

    this.minExpirationdays = 1
    this.maxExpirationdays = 999

    this.tiposPagamentosSelecionados = new Array()

    this.changeSession()
  }

  loadTiposPagamentos(){
    this.httpService.wait()
    this.httpService.get('/custom/cadastro/get-tipos-pagamentos('+ this.dblinkedSessionService.empresa.id + ')').subscribe(
      ret => {
        this.tiposLista = []
        ret.forEach(element => {
          this.tiposLista.push(element)
        })
      },
      error => {
        console.log(error)
        this.httpService.done()
      },
      () => this.httpService.done()
    )
  }


  loadFormaCnabList(){
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-cnab-forma')
      .subscribe(
        ret => {
          this.formaCnabList = [];
          this.formaCnabList.push({ label: 'Selecione', value: null});
          ret.forEach(element => {
            if (element["cnab_descricao"] != null ){
              this.formaCnabList.push({ label: element["cnab_descricao"], value: element["cnab_id"] });
            }
          });
        },
        error => {
          console.log(error)
          this.httpService.done();
        },
        () => this.httpService.done());
  }

  montarPayload() {
    let payload = {
      tipoPagamentoId: this.tipoSelecionadoId,
      codigo: this.codigo,
      descricao: this.descricao,
      cnabFormaId: this.formaCnab,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuarioId: this.sessionService.loggedUser.id,
      instrucaoBoleto: this.instrucaoBoleto,
      codigoCarteira: this.codigoCarteira,
      empresaContaId: this.contaSelecionadaId,
      variacaoCarteira: this.variacaoCarteira,
      expiracaoPix: ~~this.diasExpiracao,
      qtdeDiasDesconto: ~~this.qtdeDiasDesconto,
      tipoDesconto: this.tipo_desconto,
      valorDesconto: this.valor_desconto,
      tipoMulta: this.tipo_multa,
      valorMulta: this.valor_multa,
      tipoJuros: this.tipo_juros,
      valorJuros: this.valor_juros
    }

    return payload
  }


  salvar(){
    if (this.validacao()) return

    if (this.formaCnab == 0) this.formaCnab = null

    const payload = this.montarPayload()

    let url = this.showNew ? '/custom/cadastro/salvar-tipo-pagamento' : '/custom/cadastro/editar-tipo-pagamento'

    this.httpService.wait()
    this.httpService.post(url, payload).subscribe(
      response => {
        this.exibirMensagem(response)
        this.loadComponent('lista', null);
        this.changeSession()
        this.descricao = ""
        this.codigo = ""
        this.formaCnab = 0
      },
      error => {
        this.messageService.add({severity: 'error', key: 'messageLinnks', summary: '', detail: "Falha ao cadastrar."})
        this.httpService.done()
      },
      () => this.httpService.done()
    )
  }

  excluirTipoPagamento() {
    this.httpService.wait()
    this.httpService.post('/custom/cadastro/excluir-tipo-pagamento('+ this.tipoSelecionadoId +')').subscribe(
      (res) => {
        this.loadComponent('lista', null)

        if (res["error"] === false) {
          this.messageService.add({severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Tipo de pagamento excluído com sucesso.'})
          this.voltar()
          this.resetFields(true)
        } else this.messageService.add({severity: 'error', key: 'messageLinnks', summary: '', detail: "Falha ao excluir."})
      },
      error => {
        this.messageService.add({severity: 'error', key: 'messageLinnks', summary: '', detail: "Falha ao excluir."})
        this.httpService.done()
      },
      () => {
        this.httpService.done()
      }
    )
  }

  private changeSession() {
    this.minExpirationdays = 1
    this.maxExpirationdays = 999

    if (!this.dblinkedSessionService.hasEmpresaSelected) return

    this.loadComponent('lista', null)
    this.carregarDadosGrupoEmpresa()
    this.changeToolbar()
  }

  private alterarStatusAtualizar(boo: boolean) {
    this.toolbarService.alterarStatus('atualizar', !boo, 'Atualizar', () => {
      this.changeSession()
    })
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  validacao(): boolean {
    if ((this.descricao === null || this.descricao === undefined) || this.descricao.trim().length < 1) {
      this.messageService.add({
        severity: 'warn',
        key: 'messageLinnks',
        summary: 'Advertência',
        detail: 'Descrição é Obrigatória'
      });
      return true;
    }
    if ((this.codigo === null || this.codigo === undefined) || this.codigo.trim().length < 1) {
      this.messageService.add({
        severity: 'warn',
        key: 'messageLinnks',
        summary: 'Advertência',
        detail: 'Código é Obrigatório'
      });
      return true;
    }
    if ((this.formaCnab === null || this.formaCnab === undefined)) {
      this.messageService.add({
        severity: 'warn',
        key: 'messageLinnks',
        summary: 'Advertência',
        detail: 'Forma Bancária é Obrigatória'
      });
      return true;
    }
    if ((this.instrucaoBoleto !== null && this.instrucaoBoleto !== undefined && this.instrucaoBoleto !== "")) {
      let ocorrencias = this.instrucaoBoleto.match(/(\r\n|\n|\r)/gm)
      ocorrencias = ocorrencias ? ocorrencias.length : 0

      if(ocorrencias > 7) {
        this.messageService.add({
          severity: 'warn',
          key: 'messageLinnks',
          summary: 'Advertência',
          detail: 'Apenas 7 linhas são permitidas nas instruções do boleto.'
        });
        return true;
      }

      let linhas = this.instrucaoBoleto.split('\n')

      for(let linha of linhas) {
        if(linha.length > 90) {
          this.messageService.add({
            severity: 'warn',
            key: 'messageLinnks',
            summary: 'Advertência',
            detail: 'São permitidos apenas 90 caracteres por linha.'
          });
          return true;
        }
      }
    }

    if(this.formaCnab === 1) {
      if(this.instituicaoFinanceira === 55 && this.diasExpiracao > 999) {
        this.messageService.add({
          severity: 'warn',
          key: 'messageLinnks',
          summary: 'Advertência',
          detail: 'São permitidos apenas 3 números na data de expiração.'
        });
        return true;
      } else if((this.instituicaoFinanceira !== 55 && this.instituicaoFinanceira !== 116) && this.diasExpiracao > 99) {
        this.messageService.add({
          severity: 'warn',
          key: 'messageLinnks',
          summary: 'Advertência',
          detail: 'São permitidos apenas 2 números na data de expiração.'
        });
        return true;
      }
    }

    return false;
  }


  hideMessage() { }

  private milliseconds2Date(arg: string) {
    const d = new Date(arg);
    return this.utilService.dateWithoutTimeZone(d.toDateString());
  }

  // app.component.ts;
  private refreshToolbar() {
    this.toolbarService.ocultar('voltar');
    // this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('edit');
    // this.toolbarService.ocultar('add');

    this.alterarStatusAtualizar(true);

    this.toolbarService.replace(
      'add',
      false,
      'class-new-button',
      'NOVO',
      'Adicionar',
      () => {
        this.loadComponent('new', null);
      }
    );
  }

  resetFields(resetAll) {
    this.minExpirationdays = 1
    this.maxExpirationdays = 999

    if(resetAll) {
      this.descricao = "";
      this.codigo = "";
      this.formaCnab = 0;
    }

    this.codigoCarteira = null
    this.instrucaoBoleto = null
    this.contaSelecionadaId = null
    this.variacaoCarteira = null

    if(this.formaCnab === 1) {
      this.loadContasBancarias()
    }
  }

  loadContasBancarias() {
    this.httpService.wait();
    this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,instituicaoFinanceira,tipoConta/id,ativo'
      + '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id
      + '&$orderby=nome'
    )
    .subscribe(
      ref => {
        if(ref.value !== null && ref.value !== undefined && ref.value.length > 0) {
          this.opcoesContaBancaria = []
          this.contasBancariasCompletas = []
          this.opcoesContaBancaria.push({ label: 'Nenhum', value: null })

          ref.value.forEach(conta => {
            if (conta.ativo) {
              this.opcoesContaBancaria.push({ label: conta.nome, value: conta.id })
              this.contasBancariasCompletas.push(conta)
            }
          })

          this.selectInstituicaoFinanceira()
        }
      },
      error => {
        this.messageService.add({
          severity: 'error',
          key: 'messageLinnks',
          summary: '',
          detail: "Erro ao buscar as contas da empresa."
        });
        this.httpService.done();
      },
      () => this.httpService.done()
    );
  }

  selectInstituicaoFinanceira() {
    let instituicaoFinanceira

    // let contaSelecionada = contaId !== null && contaId !==  undefined ? contaId : this.contaSelecionadaId
    this.contasBancariasCompletas.map(conta => {
      if(this.contaSelecionadaId === conta.id) {
        instituicaoFinanceira = conta.instituicaoFinanceira.id
      }
    })

    this.instituicaoFinanceira = Number(instituicaoFinanceira)

    if(isNaN(this.instituicaoFinanceira) || this.instituicaoFinanceira === null || this.instituicaoFinanceira === undefined) {
      this.variacaoCarteira = null
      this.diasExpiracao = 0
    }

    if(this.formaCnab === 1) this.changeValuesOfExpirationDays()
  }

  changeValuesOfExpirationDays() {
    if(this.instituicaoFinanceira === 116) {
      this.expirationDaysOptionsPreDefined = true
      this.expirationDaysOptions = this.expirationDaysOptionsSantander
    } else {
      this.expirationDaysOptionsPreDefined = false
    }
  }

  exibirMensagem(response) {
    if(response.error) this.messageService.add({severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: response.message})
    else {
      this.messageService.add({severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: response.message})
      this.voltar()
    }
  }

  async carregarDadosGrupoEmpresa() {
    if (this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.hasEmpresaSelected) {
      await this.verificarSeEmpresaPodeRealizarModificacoes()
      if (this.empresaRealizaAlteracoes) await this.getCadastroGrupoEmpresas()
      this.changeToolbar()
    }
  }

  async getCadastroGrupoEmpresas() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.get(`/empresa?$select=cadastroGrupoEmpresa&$filter=id eq ${companyId}`).subscribe(
      res => {
        this.cadastroGrupoEmpresa = res.value[0].cadastroGrupoEmpresa ? true : false

        if(this.cadastroGrupoEmpresa) this.getEmpresasGrupo()
      },
      error => this.httpService.handleError(error, () => this.getCadastroGrupoEmpresas())
    )
  }

  getEmpresasGrupo() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.get(`/empresagrupocadastrorelac?$select=id,empresaRelac/nomeFantasia&$filter=empresa/id eq ` + companyId).subscribe(
      res => {
        this.empresasGrupo = new Array()
        this.empresasGrupoSelecionadas = new Array()

        this.empresasGrupoSelecionadas.push(companyId)

        res.value.forEach(company => {
          this.empresasGrupo.push({
            label: company.empresaRelac.nomeFantasia,
            value: company.empresaRelac.id
          })

          this.empresasGrupoSelecionadas.push(company.empresaRelac.id)
        })
      },
      error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro ao buscar as empresas do grupo' })
    )
  }

  async verificarSeEmpresaPodeRealizarModificacoes() {
    const companyId = this.dblinkedSessionService.empresa.id
    this.empresaRealizaAlteracoes = false

    try {
      await this.httpService.get(`/custom/cadastro/verifica-grupo-empresa-permite-alteracao(${companyId})`).toPromise()
      .then(res => this.empresaRealizaAlteracoes = res.permissoes.permiteAlteracaoFormaPagamento)
      .catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }

  selectRow(value: any) {
    if(this.tiposPagamentosSelecionados.length >= 1) this.tipoSelecionadoId = this.tiposPagamentosSelecionados[0].id
    else this.tipoSelecionadoId = 0

    this.changeToolbar()
  }

  unselectRow(value: any) {
    if(this.tiposPagamentosSelecionados.length >= 1) this.tipoSelecionadoId = this.tiposPagamentosSelecionados[0].id
    else this.tipoSelecionadoId = 0

    this.changeToolbar()
  }

  changeToolbar() {
    const umTipoSelecionadoEmpresaRealizaAlteracoes = this.showLista && this.tiposPagamentosSelecionados.length === 1 && this.empresaRealizaAlteracoes
    const umOuMaisTipoSelecionadoEmpresaRealizaAlteracoes = this.showLista && this.tiposPagamentosSelecionados.length >= 1 && this.empresaRealizaAlteracoes
    const condicaoExlcusao = umTipoSelecionadoEmpresaRealizaAlteracoes || (this.showEdit && this.empresaRealizaAlteracoes) || (umOuMaisTipoSelecionadoEmpresaRealizaAlteracoes && this.cadastroGrupoEmpresa)

    this.toolbarTipoPag.setVisible('btn-clone', umTipoSelecionadoEmpresaRealizaAlteracoes && !this.cadastroGrupoEmpresa)

    this.toolbarTipoPag.setVisible('btn-clone-expanded', umOuMaisTipoSelecionadoEmpresaRealizaAlteracoes && this.cadastroGrupoEmpresa)
    this.toolbarTipoPag.setOptionVisible('btn-clone-expanded', 'btn-unique-clone', umTipoSelecionadoEmpresaRealizaAlteracoes)

    this.toolbarTipoPag.setVisible('btn-refresh', this.showLista)
    this.toolbarTipoPag.setVisible('btn-back', !this.showLista)
    this.toolbarTipoPag.setVisible('btn-edit', umTipoSelecionadoEmpresaRealizaAlteracoes)
    this.toolbarTipoPag.setVisible('btn-delete', condicaoExlcusao)
    this.toolbarTipoPag.setVisible('btn-add', this.showLista && this.empresaRealizaAlteracoes)
    this.toolbarTipoPag.setVisible('btn-save-new', this.showNew && this.empresaRealizaAlteracoes)
    this.toolbarTipoPag.setVisible('btn-save-edit', this.showEdit && this.empresaRealizaAlteracoes)
    this.toolbarTipoPag.setVisible('btn-save-edit', this.showEdit && this.empresaRealizaAlteracoes)
  }

  determinarMetodoSalvamento(clonagem = false) {
    if(clonagem && this.cadastroGrupoEmpresa && !this.clonagemNaPropriaEmpresa) {
      this.clonagem = true

      this.modalGrupoEmpresas = true
      this.tituloModalGrupoEmpresas = 'Clonagem de regra fiscal em várias empresas'
      this.descricaoModalGrupoEmpresas = 'Em quais outras empresas você deseja clonar esta regra fiscal?'
    } else if ((clonagem && this.clonagemNaPropriaEmpresa) || (clonagem && !this.cadastroGrupoEmpresa)) {
      this.clonagem = true

      this.modalGrupoEmpresas = true
      this.tituloModalGrupoEmpresas = 'Clonagem de regra fiscal'
      this.descricaoModalGrupoEmpresas = ''
    } else {
      const dadosInvalidos = this.validacao()

      if(dadosInvalidos) return

      if(this.cadastroGrupoEmpresa) {
        this.modalGrupoEmpresas = true
        this.tituloModalGrupoEmpresas = this.showNew ? 'Criação de regra fiscal em várias empresas' : 'Edição de regra fiscal em várias empresas'
        this.descricaoModalGrupoEmpresas = this.showNew ? 'Em quais outras empresas você deseja criar esta regra fiscal?' : 'Em quais outras empresas você deseja editar esta regra fiscal?'
      } else this.salvar()
    }
  }

  setarVariaveisClonagem(clonagemNaPropriaEmpresa: boolean) {
    this.clonagemNaPropriaEmpresa = clonagemNaPropriaEmpresa
    this.determinarMetodoSalvamento(true)
  }

  salvarTipoPagamentoGrupoEmpresa() {
    let payload = this.montarPayload()

    payload['empresasGrupoIds'] = this.empresasGrupoSelecionadas

    const url = this.showNew ? '/custom/grupo-empresa/salvar-tipo-pagamento-grupo-empresa' : '/custom/grupo-empresa/editar-tipo-pagamento-grupo-empresa'

    this.httpService.wait()
    this.httpService.post(url, payload).subscribe(
      response => this.exibirMensagem(response),
      error => this.httpService.handleError(error, () => this.salvarTipoPagamentoGrupoEmpresa()),
      () => this.httpService.done()
    )
  }

  validarClonagemNaPropriaEmpresa(): boolean {
    if ((this.novaDescricao === null || this.novaDescricao === undefined) || this.novaDescricao.trim().length < 1) {
      this.messageService.add({severity: 'warn', key: 'messageLinnks',  summary: 'Advertência', detail: 'Descrição é Obrigatória'})
      return true
    }

    if ((this.novoCodigo === null || this.novoCodigo === undefined) || this.novoCodigo.trim().length < 1) {
      this.messageService.add({severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório'})
      return true
    }
  }

  clonarTipoPagamentoGrupoEmpresa() {
    const empresaId = this.dblinkedSessionService.empresa.id

    let empresasSelecionadasFiltrada = new Array()
    let tiposPagamentosIds = new Array()
    let tiposPagamentosCodigos = new Array()

    if(this.clonagemNaPropriaEmpresa) {
      const dadosInvalidos = this.validarClonagemNaPropriaEmpresa()

      if(dadosInvalidos) return

      empresasSelecionadasFiltrada.push(empresaId)
      tiposPagamentosCodigos.push(this.novoCodigo)
    } else {
      empresasSelecionadasFiltrada = this.empresasGrupoSelecionadas.filter(id => (id !== empresaId))
    }

    this.tiposPagamentosSelecionados.forEach(tipoPagamento => {
      tiposPagamentosIds.push(tipoPagamento.id)

      if(!this.clonagemNaPropriaEmpresa) tiposPagamentosCodigos.push(tipoPagamento.codigo)
    })

    if(this.clonagemNaPropriaEmpresa && ((tiposPagamentosIds.length > 1 || tiposPagamentosCodigos.length > 1) || empresasSelecionadasFiltrada.length > 1)) {
      this.messageService.add({severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Você só pode clonar um tipo de pagamento por vez na própria empresa'})
      return
    }

    const payload = {
      tipoPagamentoIdList: tiposPagamentosIds,
      tipoPagamentoCodigoList: tiposPagamentosCodigos,
      empresasGrupoIds: empresasSelecionadasFiltrada,
      clonagemNaPropriaEmpresa: this.clonagemNaPropriaEmpresa,
      novoCodigo: this.novoCodigo,
      novaDescricao: this.novaDescricao
    }

    this.httpService.wait()
    this.httpService.post('/custom/grupo-empresa/clonar-tipo-pagamento-grupo-empresa', payload).subscribe(
      response => this.exibirMensagem(response),
      error => this.httpService.handleError(error, () => this.clonarTipoPagamentoGrupoEmpresa()),
      () => this.httpService.done()
    )
  }

  excluirTipoPagamentoGrupoEmpresa() {
    let textoConfirmacaoExclusao = "Deseja excluir este item? Essa ação afetará as possíveis empresas do seu grupo."

    if (!confirm(textoConfirmacaoExclusao)) return

    let tiposPagamentosIds = new Array()

    if(this.showEdit)
      tiposPagamentosIds.push(this.tipoSelecionadoId)
    else {
      this.tiposPagamentosSelecionados.forEach(tipoPagamento => {
        tiposPagamentosIds.push(tipoPagamento.id)
      })
    }

    const payload = { tipoPagamentoIdList: tiposPagamentosIds }

    this.httpService.wait()
    this.httpService.post('/custom/grupo-empresa/excluir-tipo-pagamento-grupo-empresa', payload).subscribe(
      response => this.exibirMensagem(response),
      error => this.httpService.handleError(error, () => this.excluirTipoPagamentoGrupoEmpresa()),
      () => this.httpService.done()
    )
  }
}