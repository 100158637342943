import { MovParteBCsll } from 'app/ecf/model/mov-parte-b-csll.model';
import { MovParteACsll } from 'app/ecf/model/mov-parte-a-csll.model';

export class MovParteBCsllLcto {
  id: number;
  movParteB: MovParteBCsll;
  data: Date;
  valor: number;
  indLcto: string;
  descricao: string;
  movParteACsll: MovParteACsll;

  constructor(movpartebcslllcto?: MovParteBCsllLcto | number) {
    if (movpartebcslllcto !== undefined && movpartebcslllcto !== null) {
      if (typeof movpartebcslllcto === 'number') {
        this.id = movpartebcslllcto;
      } else {
        this.id = movpartebcslllcto.id;
        this.movParteB = movpartebcslllcto.movParteB || new MovParteBCsll();
        this.data = movpartebcslllcto.data;
        this.valor = movpartebcslllcto.valor;
        this.indLcto = movpartebcslllcto.indLcto;
        this.descricao = movpartebcslllcto.descricao;
        this.movParteACsll = movpartebcslllcto.movParteACsll || new MovParteACsll();
      }
    }
  }
}
