import { Component, OnInit } from "@angular/core";
import { CrudService } from "app/shared/services/crud.service";
import { DataTableBasicComponent } from "app/shared/components/data-table/data-table-basic.component";
import { AuthApp } from "app/proof/model/auth-app.model";
import { ConfigService } from "app/shared/services/config.service";
import { DataTableColumn } from "app/shared/components/data-table/model/data-table-column.model";
import { DataControl } from "app/shared/components/data-table/model/data-control.model";
import { Toolbar } from "app/shared/model/toolbar.model";
import { ToolbarService } from "app/shared/services/toolbar.service";
import { FormGroup, FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { SessionService } from "app/shared/services/session.service";
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service";
import { HttpService } from "app/shared/services/http.service";
import { MessageService } from "primeng/api";
import { SelectItem } from "app/shared/common/select-item.interface";

@Component({
  selector: "app-app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [CrudService],
})
export class AuthAppComponent
  extends DataTableBasicComponent
  implements OnInit
{
  public authApp: AuthApp = new AuthApp();
  public registrosAplicacoes: Array<AuthApp> = new Array();
  grupos = new Array();
  selectedGrupos = new Array();
  selectedGruposAll = false;
  formulario: FormGroup;

  title: string;
  subtitle: string;
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;
  opcoesAtivo: SelectItem[]= [{ label: 'Todos', value: '' }, { label: 'Sim', value: true }, { label: 'Não', value: false }];


  ids: Array<any> = new Array();

  buttonToolbar = this.getButtonToolbarContent();
  toolbarMainIni = this.getToolbarMainIniContent();



  public toolbarMain = new Toolbar();

  private sessionSubscription: Subscription;

  constructor(
    private crudService: CrudService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private messageService: MessageService,
    private dblinkedSessionService: DblinkedSessionService
  ) {
    super(crudService);
  }

  ngOnInit() {
    this.toolbarService.hidden = true;
    this.toolbarMain.setAll(this.toolbarMainIni);
    this.createForm(new AuthApp());

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
    );
  }

  createForm(authApp: AuthApp) {
    this.formulario = new FormGroup({
      name: new FormControl(authApp.name),
      key: new FormControl(authApp.key),
      tokenTimeout: new FormControl(authApp.tokenTimeout),
      active: new FormControl(authApp.active),
      mailAuth: new FormControl(authApp.mailAuth),
    });
  }

  criarNovo() {
    this.novaConta = true;
    this.title = "Cadastrar";
    this.carregaBotoesToolbarFormNovo();

    this.loadGrupos();
  }

  carregar() {
    this.title = "Aplicações";
    this.subtitle = "Aplicações";
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, true);

    this.ocultaBotoesToolbar();
    this.limpaCamposForm();
    this.loadGrupos();

    this.loadRegistros();
  }

  loadGrupos() {
    this.httpService.wait();
    this.httpService
      .get(`/authgroup?$select=id,name&$orderby=name`, null, "proof")
      .subscribe(
        (retorno) => {
          this.grupos = new Array();
          retorno.value.forEach((item) => {
            this.grupos.push(item);
          });
        },
        (error) => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService
      .get(
        `/authapp?$select=name,key,active,mailAuth&$count=true&$skip=0&$orderby=name`,
        null,
        "proof"
      )
      .subscribe(
        (retorno) => {
          this.registrosAplicacoes = new Array();
          retorno.value.forEach((item) => {
            this.registrosAplicacoes.push(item);
          });
        },
        (error) => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.loadGrupos();
    this.title = "Editar";
    this.editarConta = true;

    this.carregaBotoesToolbarFormEditar();
    this.limpaCamposForm();

    this.httpService.wait();
    this.httpService
      .get(
        `/authapp(${this.ids[0]})?$select=name,key,tokenTimeout,active,mailAuth,authGroups/id,authGroups/name&$count=true`,
        null,
        "proof"
      )
      .subscribe(
        (item) => {
          this.formulario.setValue({
            name: item.value.name,
            key: item.value.key,
            tokenTimeout: item.value.tokenTimeout,
            active: item.value.active,
            mailAuth: item.value.mailAuth,
          });
          this.selectedGrupos = item.value.authGroups.map((grupo) =>
            String(grupo.id)
          );
        },
        (error) => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }
  excluirRegistro() {
    if (confirm("Deseja deletar os registros selecionados? ")) {
      this.httpService.wait();
      this.httpService.delete("/authapp", this.ids, "proof").subscribe(
        (result) => {
          if (result === null) {
            this.messageService.add({
              severity: "success",
              key: "messageLinnks",
              summary: "",
              detail: "Registro excluído com sucesso!",
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: "warn",
              key: "messageLinnks",
              summary: "",
              detail: "Falha na exclusão. Tente novamente!",
            });
          }
        },
        (error) => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
    }
  }

  salvarNovoRegistro() {
    if (this.validacao(this.formulario)) {
      return;
    }

    if (this.formulario.value.tokenTimeout === null) {
      this.formulario.patchValue({
        tokenTimeout: 0,
      });
    }

    const jsonData = JSON.parse(JSON.stringify(this.formulario.value));
    jsonData["authGroups"] = this.selectedGrupos.map((grupo) => {
      return {
        id: grupo,
      };
    });

    this.httpService.wait();
    this.httpService.post("/authapp", jsonData, "proof").subscribe(
      (result) => {
        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: "success",
            key: "messageLinnks",
            summary: "Sucesso  ",
            detail: "Registro cadastrado com sucesso!",
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: "warn",
            key: "messageLinnks",
            summary: "Advertência",
            detail: "Falha no cadastro. Tente novamente",
          });
          this.carregar();
          return;
        }
      },
      (error) =>
        this.httpService.handleError(error, () => this.salvarNovoRegistro()),
      () => this.httpService.done()
    );
  }

  salvarAlteracaoRegistro() {
    if (this.validacao(this.formulario)) {
      return;
    }

    if (this.formulario.value.tokenTimeout === null) {
      this.formulario.patchValue({
        tokenTimeout: 0,
      });
    }

    const jsonData = JSON.parse(JSON.stringify(this.formulario.value));
    jsonData["authGroups"] = this.selectedGrupos.map((grupo) => {
      return {
        id: grupo,
      };
    });

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch("/authapp", key, jsonData, "proof").subscribe(
      (result) => {
        if (result === null) {
          this.messageService.add({
            severity: "success",
            key: "messageLinnks",
            summary: "Sucesso  ",
            detail: "Registro alterado com sucesso!",
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: "warn",
            key: "messageLinnks",
            summary: "Advertência",
            detail: "Falha na alteração do registro!",
          });
          this.carregar();
          return;
        }
      },
      (error) =>
        this.httpService.handleError(error, () =>
          this.salvarAlteracaoRegistro()
        ),
      () => this.httpService.done()
    );
  }

  selectGruposAll() {
    if (this.selectedGruposAll) {
      this.selectedGrupos = this.grupos.map((grupo) => String(grupo.id));
    } else {
      this.selectedGrupos = new Array();
    }
  }

  selectCheckboxContainer(data) {
    setTimeout(() => {
      document.getElementById("checkboxContainer" + data.id).click();
    }, 100);
  }

  selectCheckbox(event: any) {
    this.ids.push(event.data.id);
    this.filtraFuncoesToolbar();
    this.carregaBotoesToolbarInicial();
  }

  unselectCheckbox(event: any) {
    const index = this.ids.findIndex((c) => c === event.data.id);
    this.ids.splice(index, 1);
    this.filtraFuncoesToolbar();
    this.ocultaBotoesToolbar();
  }

  filtraFuncoesToolbar() {
    if (this.ids.length === 1) {
      this.toolbarMain.setDisabled(this.buttonToolbar.excluir, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.editar, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
    } else {
      this.toolbarMain.setDisabled(this.buttonToolbar.excluir, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.editar, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
    }
  }

  validacao(formulario): boolean {
    if (
      formulario.controls.name.status == "INVALID" ||
      formulario.value.name.trim() == "" ||
      formulario.value.name.length < 1 ||
      formulario.value.name.length > 30
    ) {
      this.messageService.add({
        severity: "warn",
        key: "messageLinnks",
        summary: "Advertência",
        detail: "Aplicação inválida",
      });
      return true;
    }

    if (
      formulario.controls.key.status == "INVALID" ||
      formulario.value.key.trim() == "" ||
      formulario.value.key.length < 1 ||
      formulario.value.key.length > 30
    ) {
      this.messageService.add({
        severity: "warn",
        key: "messageLinnks",
        summary: "Advertência",
        detail: "ID inválido",
      });
      return true;
    }

    return false;
  }

  private getButtonToolbarContent() {
    return {
      voltar: "btn1",
      excluir: "btn2",
      editar: "btn3",
      adicionarNovo: "btn4",
      salvarNovo: "btn5",
      salvarAlteracao: "btn6",
    };
  }

  private getToolbarMainIniContent() {
    return [
      {
        icon: "undo",
        text: "Voltar",
        tooltip: "Voltar",
        visible: true,
        disabled: true,
        color: "default",
        onClick: () => this.carregar(),
      },
      {
        icon: "delete",
        text: "Excluir",
        tooltip: "Excluir",
        visible: true,
        disabled: true,
        color: "default",
        onClick: () => this.excluirRegistro(),
      },
      {
        icon: "edit",
        text: "Editar",
        tooltip: "Editar",
        visible: true,
        disabled: true,
        color: "default",
        onClick: () => this.loadEditarRegistro(),
      },
      {
        icon: "add",
        text: "Novo",
        tooltip: "Novo",
        visible: true,
        disabled: false,
        color: "green",
        onClick: () => this.criarNovo(),
      },
      {
        icon: "check",
        text: "Salvar",
        tooltip: "Salvar Novo Registro",
        visible: true,
        disabled: false,
        color: "default",
        onClick: () => this.salvarNovoRegistro(),
      },
      {
        icon: "check",
        text: "Salvar",
        tooltip: "Salvar Alteração",
        visible: true,
        disabled: false,
        color: "default",
        onClick: () => this.salvarAlteracaoRegistro(),
      },
    ];
  }

  private carregaBotoesToolbarFormEditar() {
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, true);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, false);

    this.toolbarMain.setDisabled(this.buttonToolbar.voltar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.salvarAlteracao, false);
  }

  private carregaBotoesToolbarFormNovo() {
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, true);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, false);

    this.toolbarMain.setDisabled(this.buttonToolbar.voltar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.salvarNovo, false);
  }

  private carregaBotoesToolbarInicial() {
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.excluir, true);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, false);

    this.toolbarMain.setDisabled(this.buttonToolbar.excluir, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.editar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
  }

  private ocultaBotoesToolbar() {
    this.toolbarMain.setVisible(this.buttonToolbar.voltar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.excluir, false);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, false);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, true);
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  private limpaCamposForm() {
    this.formulario.reset(new AuthApp());
    this.selectedGrupos = new Array();
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}

