export const MonthLabels: Array<string> = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export class Month {
  value: number;
  label: string;

  constructor(month?: Month | number, label?: string) {
    if (month instanceof Month) {
      this.value = month ? month.value : null;
      this.label = month ? month.label || MonthLabels[month.value - 1] : null;
    } else {
      this.value = month;
      this.label = label ? label : (month ? MonthLabels[month - 1] : null);
    }
  }
}

export class Year {
  value: number;
  label: string;

  constructor(year?: Year | number, label?: string) {
    if (year instanceof Year) {
      this.value = year ? year.value : null;
      this.label = year ? year.label : null;
    } else {
      this.value = year;
      this.label = label !== undefined ? label : (year ? year.toString() : null);
    }
  }
}

export class Period {
  month: Month = null;
  year: Year = null;
  id: string = null; // https://github.com/primefaces/primeng/issues/1937

  constructor(period?: Period | Year, month?: Month) {
    if (period instanceof Period) {
      this.year = period ? period.year : null;
      this.month = period ? period.month : null;
    } else {
      this.year = period;
      this.month = month;
    }

    if (this.year && this.year.value) {
      this.id = this.year.value.toString() +
                ('0' + (this.month ? this.month.value.toString() : '00')).substr(-2);
    }
  }
}
