export class TbCsllN660 {
  id: number;
  cod:  string;
  descricao:  string;
  dtIni:  string;
  dtFin:  string;
  ordem:  string;
  tipo:  string;
  formato:  string;
  linhaEcf:  string;
  formula:  string;



  constructor(tbcslln660?: TbCsllN660 | number) {
    if (tbcslln660 !== undefined && tbcslln660 !== null) {
      if (typeof tbcslln660 === 'number') {
        this.id = tbcslln660;
      } else {
        this.id = tbcslln660.id;
        this.cod = tbcslln660.cod;
        this.descricao = tbcslln660.descricao;
        this.dtIni = tbcslln660.dtIni;
        this.dtFin = tbcslln660.dtFin;
        this.ordem = tbcslln660.ordem;
        this.tipo = tbcslln660.tipo;
        this.formato = tbcslln660.formato;
        this.linhaEcf = tbcslln660.linhaEcf;
        this.formula = tbcslln660.formula;

            }
    }
  }
}
