import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmpresaAlmoxarifado } from 'app/cadastro/model/empresa-almoxarifado.model';
import { Item } from 'app/cadastro/model/item.model';
import { Mi } from 'app/cadastro/model/mi.model';
import { Participante } from 'app/cadastro/model/participante.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';
import { NfeItem } from 'app/financeiro/model/nfe-item.model';
import { Nfe } from 'app/financeiro/model/nfe.model';
import LogManagement from 'app/shared/common/log-management/log-control';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { TipoEntradaSaida } from 'app/util/model/tipo-entrada-saida.model';
import { UnidadeMedida } from 'app/util/model/unidade-medida.model';
import exportExcel from 'app/util/processing/exportExcel';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from '../../shared/services/config.service';
import { MovInterna } from '../model/mov-interna-model';
import { MovInternaItem } from '../model/movimentacao-interna-item.model';

@Component({
  selector: 'app-movimentacao-interna',
  templateUrl: './movimentacao-interna.component.html',
  styleUrls: ['./movimentacao-interna.component.scss']
})

export class MovimentacaoInternaComponent implements OnInit, OnDestroy {


  constructor(
    protected toolbarService: ToolbarService,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private messageService: MessageService,
    private httpClient: HttpClient
  ) { this.toolbarService.hidden = true }

  title: string;

  movSelecionadas: Array<any> = new Array()
  dadosTabela: Array<any> = new Array()

  mostrarModalNovo: boolean;
  mostrarFiltro: boolean;

  empresaId: number;
  totalLinhas: number;
  movInterna: MovInterna = new MovInterna();

  listaMI: SelectItem[];
  listaItem: SelectItem[];
  listaAlmoxarifado: SelectItem[];
  listaParticipante: SelectItem[];
  listaNovoES: SelectItem[] = [
    { label: "Entrada", value: { id: 1, name: "Entrada" } },
    { label: "Saída", value: { id: 2, name: "Saída" } }
  ]

  mostrarQtde: boolean;
  mostrarValor: boolean;
  mostrarTransfAlmoxarifado: boolean;
  mostrarTransfParticipante: boolean;
  mostrarTransformacao: boolean;
  mostrarTabelaItens: boolean;

  requisicaoMiCarregando: boolean;
  requisicaoItemCarregando: boolean;
  requisicaoAlmoxCarregando: boolean;
  requisicaoPartCarregando: boolean;


  tipoUnidadeMedida: Array<UnidadeMedida> = new Array();
  opcoesUnidadeMedida: SelectItem[];

  listaES: SelectItem[] = [
    { label: "E", value: 1 },
    { label: "S", value: 2 }
  ]

  dataIni: Date = new Date()
  dataFin: Date = new Date()
  dataSelecionadaIni: Date = new Date()
  dataSelecionadaFin: Date = new Date()

  ordemExportar = ["data", "doc", "mi", "local", "codItem", "descricao", "tipoEntradaSaida", "qtde", "valor"]

  private sessionSubscription: Subscription;
  private logManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  toolbarMainIni = [
    { key: 'btn-export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar excel', visible: true, disabled: false, color: 'default', onClick: () => this.newExportMovInterna() },
    { key: 'btn-refresh', icon: 'update', text: 'Atualizar', tooltip: 'Atualizar', visible: true, disabled: false, color: 'default', onClick: () => this.reiniciarTabela(false) },
    { key: 'btn-filter', icon: 'filter_alt', text: 'Filtrar', tooltip: 'Filtrar', visible: true, disabled: false, color: 'default', onClick: () => this.mostrarModalFiltro(true) },
    { key: 'btn-delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirMovimentações() },
    { key: 'btn-print', icon: 'print', text: 'Imprimir', tooltip: 'Imprimir', visible: false, disabled: false, color: 'default', onClick: () => this.imprimir() },
    { key: 'btn-reprocessar', icon: 'update', text: 'Reprocessar', tooltip: 'Reprocessar', visible: true, disabled: false, color: 'default', onClick: () => this.reprocessarMi() },
    { key: 'btn-new', icon: 'add', text: 'Nova', tooltip: 'Nova requisição', visible: true, disabled: false, color: 'green', onClick: () => { this.inicializarModalNovo(), this.mostrarModalNovo = true } },
    { key: 'btn-loading', icon: 'hourglass_full', text: 'Carregando...', tooltip: '', visible: false, disabled: true, color: 'default', onClick: () => { } }
  ]
  btnArr: Array<string> = ['btn-export', 'btn-refresh', 'btn-filter', 'btn-new', 'btn-cancel', 'btn-approve', 'btn-delete', 'btn-edit', 'btn-save', 'btn-side']

  public toolbarMain = new Toolbar()

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'DATA', size: '10%', textAlign: 'center' },
      { name: 'CÓDIGO', size: '10%', textAlign: 'center' },
      { name: 'MI', size: '15%', textAlign: 'left' },
      { name: 'ALMOXARIFADO', size: '15%', textAlign: 'left' },
      { name: 'ITEM', size: '15%', textAlign: 'left' },
      { name: 'HISTÓRICO', size: '10%', textAlign: 'left' },
      { name: 'E/S', size: '5%', textAlign: 'left' },
      { name: 'QTDE', size: '10%', textAlign: 'left' },
      { name: 'VALOR', size: '10%', textAlign: 'left' },
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true,
  })

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.title = 'Movimentação Interna';
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
        this.empresaId = this.dblinkedSessionService.empresa.id
        this.reiniciarTabela(true)

        this.httpService.loadLists([
          { url: '/unidademedida?$select=id,codigo&$filter=unidadePadrao eq true&$orderby=codigo', destination: this.tipoUnidadeMedida },
        ]);
      }
    })
  }

  reiniciarTabela(resetarData) {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarCarregando()

    if (resetarData && this.dblinkedSessionService.periodo.year && this.dblinkedSessionService.periodo.month) {
      this.dataIni = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dataFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
    }

    this.movSelecionadas = [];
    this.dadosTabela = [];
    this.receberMovimentacoes();
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.atualizarToolbar()
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  async receberMovimentacoes(event: LazyLoadEvent = {}): Promise<void> {
    const payload = {
      empresaId: this.empresaId,
      usuarioId: this.sessionService.loggedUser.id,
      dtIni: this.dataPadraoReq(this.dataIni),
      dtFin: this.dataPadraoReq(this.dataFin),
      limit: event.rows,
      offset: event.first,
      sortOrder: event.sortOrder,
      sortField: event.sortField,
      filtros: event.filters
    }

    this.skeletonConfig.startLoad()

    try {
      await this.httpService.post(this.configService.defaultUrlApiUpload + '/estoque/get-mov-interna', payload).toPromise().then(res => {

        this.dadosTabela = res.data.itens
        this.totalLinhas = res.data.totalLinhas

      }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao carregar as movimentações internas! Contate o suporte' }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

  public dataPadraoReq(data: Date): String {
    const dataStr = data.toISOString().split('T')[0]

    return dataStr
  }

  private async excluirMovimentações(): Promise<void> {
    if (confirm('Deseja excluir as movimentações selecionadas?')) {
      const payload = { idsSelecionados: this.movSelecionadas.map(item => item.id) }
      this.toolbarCarregando()

      try {
        await this.httpService.post('/custom/estoque/delete-mov-interna', payload).toPromise().then(res => {
          if (!res.error) this.receberMovimentacoes()
          this.messageService.add({ severity: (res.error ? 'error' : 'success'), key: 'messageLinnks', summary: (res.error ? 'Erro' : 'Sucesso'), detail: res.message })
        }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao deletar as requisições! Contate o suporte' }))
      } catch (err) {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
      } finally {
        this.reiniciarTabela(false)
      }
    }
  }


  public convertTimestamp(timestamp: any): String {
    const date = new Date(timestamp)
    date.setHours(date.getHours() + 3)
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)

    const fullDate = day + '/' + month + '/' + date.getFullYear()

    return fullDate
  }

  public formatDateExtensive(): String {
    const dt = new Date()
    const monthExtensive = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    const weekExtensive = ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]

    const month = monthExtensive[dt.getMonth()]
    const week = weekExtensive[dt.getDay()]

    return (`Hoje é ${week}, ${dt.getDate()} de ${month} de ${dt.getFullYear()}`)
  }

  private toolbarCarregando(): void {

    this.btnArr.forEach(item => {
      this.toolbarMain.setVisible(item, false)
    })
    this.toolbarMain.setVisible('btn-loading', true)
  }

  inicializarModalNovo() {
    this.movInterna = new MovInterna();
    this.movInterna.id = 0;
    this.movInterna.dtMov = new Date();
    this.movInterna.codigo = '';
    this.movInterna.historico = '';
    this.movInterna.valor = 0.00;
    this.movInterna.cadEcd = new CadEcd();
    this.movInterna.nfe = new Nfe();
    this.movInterna.mi = new Mi();
    this.movInterna.movInternaItem = new Array();

    this.opcoesUnidadeMedida = [];
    this.opcoesUnidadeMedida.push({ label: 'Selecione Unidade Medida', value: null });
    this.tipoUnidadeMedida.forEach(element => {
      this.opcoesUnidadeMedida.push({ label: element.codigo, value: element });
    });


    this.criarItemVazio(1);

    this.buscarListaMi()
    this.buscarListaItem()
    this.buscarListaAlmoxarifado()
    this.buscarListaParticipante()

  }

  fecharModalNovo() {
    this.mostrarModalNovo = false;
    this.mostrarTabelaItens = false;
  }

  itemAlterado(row: any) {
    if (Object.keys(this.movInterna.movInternaItem[this.movInterna.movInternaItem.length - 1].item).length !== 0) {
      this.criarItemVazio(1);
    }

    this.listaItem.forEach(element => {
      if (element.value === row.item.id) {
        row.item.codigo = element.label.split(" - ")[0]
        row.item.descricao = element.label.split(" - ")[1]
        return
      }
    })
  }

  criarMovInt() {

    this.movInterna.movInternaItem = this.movInterna.movInternaItem.filter(miItem => Object.keys(miItem.item).length)

    if (!this.validacaoNovo()) return

    const payload = {
      movInterna: this.movInterna,
      empresa: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id,
      edit: false
    }

    try {
      let url = '/custom/estoque/v2/movimentacao-interna'
      this.httpService.wait()
      this.httpService.post(url, payload)
        .subscribe(
          res => {
            if (res.error) {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: res.message })
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: res.message })
              this.reiniciarTabela(false)
              this.fecharModalNovo()

              //Gravar o log
              this.logManagement.registerLog(
                LogModule.STOCK,
                LogScreen.STOCK_MOVEMENT,
                url,
                LogAction.CREATE,
                res.data,
                this.movInterna.historico,
                "Realizou uma nova movimentação interna",
                payload
              )
            }
          },
          error => this.httpService.handleError(error, () => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: error.status + ' - Houve um erro ao carregar a lista de itens! Contate o suporte' })),
          () => this.httpService.done()
        )
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }

  alterarMI(mi: Mi) {
    this.mostrarTabelaItens = true

    this.mostrarQtde = mi.quantidade
    this.mostrarValor = mi.valor
    this.mostrarTransfAlmoxarifado = mi.transfAlmoxarifado
    this.mostrarTransfParticipante = mi.transfParticipante
    this.mostrarTransformacao = mi.transformacao
  }

  async buscarListaMi(event: LazyLoadEvent = {}): Promise<void> {
    try {
      this.requisicaoMiCarregando = true
      await this.httpService.get(
        '/mi?$select=id,codigo,descricao,tipo,quantidade,valor,transfAlmoxarifado,transfParticipante,transformacao' +
        '&$filter=empresa/id eq ' +
        (this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id) +
        ' and ativo eq true&$orderby=codigo'
      )
        .toPromise().then(res => {
          this.listaMI = []
          res.value.forEach(MI => {
            this.listaMI.push({
              label: MI.codigo + ' - ' + MI.descricao + ' - ' + MI.tipo.descricao,
              value: MI
            });
          });

        }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao carregar a lista de MI! Contate o suporte' }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.requisicaoMiCarregando = false
    }
  }

  async buscarListaItem(event: LazyLoadEvent = {}): Promise<void> {
    try {
      this.requisicaoItemCarregando = true
      await this.httpService.post('/custom/estoque/get-mov-interna-item(' + (this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id) + ')')
        .toPromise().then(res => {
          this.listaItem = []
          res.forEach(item => {
            this.listaItem.push({
              label: item.codigo + ' - ' + item.descricao,
              value: item.id
            });
          });

        }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao carregar a lista de itens! Contate o suporte' }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.requisicaoItemCarregando = false
    }
  }

  async buscarListaAlmoxarifado(event: LazyLoadEvent = {}): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        filiais: this.dblinkedSessionService.filiaisSelecionadas.toString()
      })
    };

    try {
      this.requisicaoAlmoxCarregando = true
      await this.httpClient.get(this.configService.defaultUrlApiUpload + "estoque/almoxarifados", httpOptions).toPromise()
        .then(
          res => {
            if (res["error"]) {
              this.exibirMensagem(res)
            } else {
              this.listaAlmoxarifado = []
              this.listaAlmoxarifado.push({ label: 'Selecione', value: null }, { label: 'Nenhum', value: null })
              res["data"].forEach(row => this.listaAlmoxarifado.push(row))
            }
          })
        .catch(err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar a lista dos almoxarifados, contate o suporte: " + err.message })
        })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro ao buscar a lista dos almoxarifados, contate o suporte: " + err.message })
    } finally {
      this.requisicaoAlmoxCarregando = false
    }
  }

  async buscarListaParticipante(event: LazyLoadEvent = {}): Promise<void> {
    try {
      this.requisicaoPartCarregando = true
      await this.httpService.post('/custom/estoque/get-mov-interna-participante(' + (this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id) + ')')
        .toPromise().then(res => {
          this.listaParticipante = []
          res.forEach(part => {
            this.listaParticipante.push({
              label: part.codigo + ' - ' + part.fantasia,
              value: part.peId
            });
          });

        }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao carregar a lista de participantes! Contate o suporte' }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.requisicaoPartCarregando = false
    }
  }

  public get rowsPerPage(): Array<any> {
    return this.configService.applicationConfig.rowsPerPage
  }

  public get rows(): number {
    return this.configService.applicationConfig.rows
  }

  public get pageLinks(): number {
    return this.configService.applicationConfig.pageLinks
  }

  public atualizarToolbar(): void {
    this.toolbarMain.setVisible('btn-delete', this.movSelecionadas.length > 0)
    this.toolbarMain.setVisible('btn-print', this.movSelecionadas.length === 1)

    this.toolbarMain.setVisible('btn-loading', false)
  }

  criarItemVazio(quantidade) {
    for (let i = 0; i < quantidade; i++) {
      let movIntItem = new MovInternaItem();
      movIntItem.movInterna = new MovInterna();
      movIntItem.item = new Item();
      movIntItem.itemId = 0;
      movIntItem.empresaAlmoxarifadoOrig = new EmpresaAlmoxarifado();
      movIntItem.empresaAlmoxarifadoOrigId = 0;
      movIntItem.empresaAlmoxarifadoDest = new EmpresaAlmoxarifado();
      movIntItem.empresaAlmoxarifadoDestId = 0;
      movIntItem.qtde = 0.00;
      movIntItem.valor = 0.00;
      movIntItem.participanteOrig = new Participante();
      movIntItem.participanteOrigId = 0;
      movIntItem.participanteDest = new Participante();
      movIntItem.participanteDestId = 0;
      movIntItem.nfeItem = new NfeItem();
      movIntItem.nfeItemId = 0;
      movIntItem.tipoEntradaSaida = new TipoEntradaSaida();
      movIntItem.tipoEntradaSaidaId = 0;
      movIntItem.unidadeMedida = new UnidadeMedida();
      movIntItem.unidadeMedidaId = 0;
      this.movInterna.movInternaItem.push(movIntItem);
    }
  }

  validacaoNovo(): boolean {
    if (Object.keys(this.movInterna.mi).length == 0) {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro', detail: 'Selecione o MI!'
      })
      return false;
    }
    if (Object.keys(this.movInterna.movInternaItem).length == 0) {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro', detail: 'Selecione pelo menos um item!'
      })
      this.criarItemVazio(1);
      return false;
    }

    return true;
  }

  private imprimirMovInterna(obj) {

    this.httpService.wait();
    this.httpService.post('/custom/estoque/impressao-mi', obj).subscribe(
      result => {

        if (result.error) {
          this.reiniciarTabela(false);
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'Erro: ',
            detail: result.message
          });
        } else {

          this.exportedPedido(result)

        }

      },
      error =>
        this.httpService.handleError(error, () => this.imprimirMovInterna(obj)),
      () => this.httpService.done()
    );
  }

  exportedPedido(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/movimentacaointerna'), '_blank');
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: result.message })
    }
  }

  private imprimir() {
    if (confirm('Deseja imprimir esta movimentação?')) {
      const itens = {
        idsSelecionados: this.movSelecionadas.map(mov => mov.id),
        empresaId: this.dblinkedSessionService.empresa.id
      }
      this.imprimirMovInterna(itens);
    }
  }

  public saveFilterModal(): void {
    this.mostrarFiltro = false
    this.receberMovimentacoes()
  }

  public mostrarModalFiltro(mostrar: boolean): void {
    this.mostrarFiltro = mostrar

    if (mostrar) {
      this.dataSelecionadaIni = this.dataIni
      this.dataSelecionadaFin = this.dataFin
    } else {
      this.dataIni = this.dataSelecionadaIni
      this.dataFin = this.dataSelecionadaFin
    }
  }

  dadosItem(rowData) {

    if (rowData.item.id) {
      this.httpService.wait();
      this.httpService.get('custom/cadastro/buscar-infos-unidade-medida', rowData.item.id)
        .subscribe(retorno => {

          this.opcoesUnidadeMedida = [];
          this.opcoesUnidadeMedida.push({ label: 'Nenhum', value: null });
          let contador = 1;
          retorno.forEach(element => {
            if (contador == 1) {
              rowData.unidadeMedida = new UnidadeMedida();
              rowData.unidadeMedida.id = element.id;
              rowData.unidadeMedida.codigo = element.codigo;
            }

            element.label = element.codigo;
            this.opcoesUnidadeMedida.push(element);

            contador++;
          });



        }, error => this.httpService.handleError(error, () => this.dadosItem(rowData)),
          () => this.httpService.done());
    }
  }

  private reprocessarMi() {
    if (confirm('Deseja realmente reprocessar essa(s) MI?')) {
      const obj = {
        idsSelecionados: this.movSelecionadas.map(item => item.id),
        empresaId: this.dblinkedSessionService.empresa.id,
        userId: this.sessionService.loggedUser.id
      }

      this.httpService.wait()
      let url = '/custom/estoque/v2/reprocessar-mi'
      this.httpService.post(url, obj).subscribe(
        result => {
          //Gravar o log
          this.movSelecionadas.forEach(mov => {
            this.logManagement.registerLog(
              LogModule.STOCK,
              LogScreen.STOCK_MOVEMENT,
              url,
              LogAction.EDIT,
              mov.id,
              mov.descricao,
              "Reprocessou essa movimentação",
              obj
            )
          })

          this.reiniciarTabela(false)
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result.message })
          this.httpService.done()
        },
        error => {
          this.httpService.done()
          if (error.status === 400) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: error.error.message })
          } else {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Erro interno ao reprocessar a MI, contate o suporte: " + error.message })
          }
        })
    }
  }

  popularAlmox(row: any, tipo: number) {
    this.listaAlmoxarifado.forEach(element => {
      if (tipo === 1 && element.value === row.empresaAlmoxarifadoOrig.id) {
        row.empresaAlmoxarifadoOrig.codigo = element.label.split(" - ")[0]
        return
      }

      else if (tipo === 2 && element.value === row.empresaAlmoxarifadoDest.id) {
        row.empresaAlmoxarifadoDest.codigo = element.label.split(" - ")[0]
        return
      }
    })
  }

  popularPart(row: any, tipo: number) {
    this.listaParticipante.forEach(element => {
      if (tipo === 1 && element.value === row.participanteOrig.participanteEmpresaId) {
        row.participanteOrig.codigo = element.label.split(" - ")[0]
        row.participanteOrig.fantasia = element.label.split(" - ")[1]
        return
      }

      else if (tipo === 2 && element.value === row.participanteDest.participanteEmpresaId) {
        row.participanteDest.codigo = element.label.split(" - ")[0]
        row.participanteDest.fantasia = element.label.split(" - ")[1]
        return
      }
    })
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  newExportMovInterna()  {

    const payload = {
      empresaId: this.empresaId,
      usuarioId: this.sessionService.loggedUser.id,
      dtIni: this.dataPadraoReq(this.dataIni),
      dtFin: this.dataPadraoReq(this.dataFin)
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      }),
      responseType: 'blob' as 'json'
    }

    this.messageService.add({ severity: 'info',key: 'messageLinnks', summary: 'Aviso: ', detail: "Seu relatório está sendo gerado e em breve estará disponível!" })

    try {
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'estoque/exportar-movimentacao-interna', payload, httpOptions).toPromise().then(
        (response: Blob) => {
          let link = document.createElement('a');
          let url = window.URL.createObjectURL(response);
          let fileName = `Relatório de movimentacao interna.xlsx`;
          link.href = url;
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(url);
        }
      ).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: err.status + ' - Houve um erro ao carregar a lista de MI! Contate o suporte' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Falha na comunicação com o servidor!' })
    }
  }
}
