import { MovParteADeducoes } from 'app/ecf/model/mov-parte-a-deducoes.model';
import { MovSaldosContabeis } from 'app/contabil/model/mov-saldos-contabeis.model';

export class TbRelacMovParteADeducoesSaldosContabeis {
    id: number;
    movParteADeducoes: MovParteADeducoes;
    movSaldosContabeis: MovSaldosContabeis;
    saldoRelacionado: number;

    constructor(tbRelacMovParteADeducoesSaldosContabeis?: TbRelacMovParteADeducoesSaldosContabeis | number) {
        if (tbRelacMovParteADeducoesSaldosContabeis !== undefined && tbRelacMovParteADeducoesSaldosContabeis !== null) {
            if (typeof tbRelacMovParteADeducoesSaldosContabeis === 'number') {
                this.id = tbRelacMovParteADeducoesSaldosContabeis;
            } else {
                this.id = tbRelacMovParteADeducoesSaldosContabeis.id;
                this.movParteADeducoes = tbRelacMovParteADeducoesSaldosContabeis.movParteADeducoes || new MovParteADeducoes();
                this.movSaldosContabeis = tbRelacMovParteADeducoesSaldosContabeis.movSaldosContabeis || new MovSaldosContabeis();
                this.saldoRelacionado = tbRelacMovParteADeducoesSaldosContabeis.saldoRelacionado;

            }
        }
    }
}
