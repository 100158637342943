import { Mediation } from "../interfaces/mediation"

export function resetMediation(): Mediation {
  let mediation: Mediation = {
    nome_fantasia: null,
    empresa_id: null,
    tipo_cobranca_id_cashout: null,
    tipo_cobranca_id_qrcode: null,
    valor_cashout: 0,
    valor_qrcode: 0,
    cliente_padrao_id: null,
    fornecedor_padrao_id: null,
    operacao_regra_contabil_entrada_id: null,
    operacao_regra_contabil_saida_id: null,
  }

  return {...mediation}
}