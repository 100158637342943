import { Empresa } from 'app/cadastro/model/empresa.model';
import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Pais } from 'app/util/model/pais.model';
import { ParticipanteParticipanteTipo } from 'app/cadastro/model/participante-participante-tipo.model';


export class ParticipanteTipo {
  id: number;
  descricao: string;
  changeUser: AuthUser;
  changeTimestamp: Date;

  constructor(participanteTipo?: ParticipanteTipo | number) {
    if (participanteTipo !== undefined && participanteTipo !== null) {
      if (typeof participanteTipo === 'number') {
        this.id = participanteTipo;
      } else {
        this.id = participanteTipo.id;
        this.descricao = participanteTipo.descricao;
        this.changeUser = participanteTipo.changeUser;
        this.changeTimestamp = participanteTipo.changeTimestamp;
      }
    }
  }
}
