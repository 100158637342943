import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { MovTermoAe } from '../model/mov-termo-ae.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-mov-termo-ae',
  templateUrl: './mov-termo-ae.component.html',
  styleUrls: ['./mov-termo-ae.component.scss'],
  providers: [CrudService]
})
export class MovTermoAeComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public movtermoae: MovTermoAe = new MovTermoAe();
  public registroMovTermoAe: Array<MovTermoAe> = new Array();

  ids: Array<any> = new Array();

  selectMov: Array<any> = new Array()

  //Boolean
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;
  showEdit: boolean = false
  dropdown: boolean = false


  //Strings
  natLivro: String;
  termFixoAber: String;
  nomeAuditor: String;
  cvmAuditor: String;
  termFixoEncer: String;

  //Number
  numOrdem: Number;
  nire: Number;

  //any
  dtArquiv: any;
  dtArqConv: any;
  dtExSocial: any;
  cad_ecd: any;

  //Select
  opcoesTipo: SelectItem[]

  msgs: Message[];

  title = 'TERMO DE ABERTURA E ENCERRAMENTO';
  subtitle = "TERMO DE ABERTURA E ENCERRAMENTO";
  skip = 0;
  top = 50;
  readonly = false;

  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeViewSubscription: Subscription;
  private beforeEditSubscription: Subscription;

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private utilService: UtilService,
    private configService: ConfigService,
  ) {
    super(crudService);
  }
  public toolbarMov = new Toolbar()

  toolbarMainMov = [
    { key: "atualizar", icon: 'replay', text: 'Atualizar', tooltip: 'Atualizar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: "voltar", icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: "edit", icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { key: "delete", icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { key: "add", icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { key: "saveNew", icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { key: "save", icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]

  ngOnInit() {
    this.toolbarService.hidden = true
    this.toolbarMov.setAll(this.toolbarMainMov)
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.carregar()
        }
      }
    );
  }
  toolbarInitial(){
    this.toolbarMov.setVisible('atualizar', true)
    this.toolbarMov.setVisible('voltar', false)
    this.toolbarMov.setVisible('edit', false)
    this.toolbarMov.setVisible('delete', false)
    this.toolbarMov.setVisible('add', true)
    this.toolbarMov.setVisible('saveNew', false)
    this.toolbarMov.setVisible('save', false)
  }
  toolbarNew(){
    this.toolbarMov.setVisible('atualizar', false)
    this.toolbarMov.setVisible('voltar', true)
    this.toolbarMov.setVisible('edit', false)
    this.toolbarMov.setVisible('delete', false)
    this.toolbarMov.setVisible('add', false)
    this.toolbarMov.setVisible('saveNew', true)
    this.toolbarMov.setVisible('save', false)
  }
  toolbarEdit(){
    this.toolbarMov.setVisible('atualizar', false)
    this.toolbarMov.setVisible('voltar', true)
    this.toolbarMov.setVisible('edit', false)
    this.toolbarMov.setVisible('delete', true)
    this.toolbarMov.setVisible('add', false)
    this.toolbarMov.setVisible('saveNew', false)
    this.toolbarMov.setVisible('save', true)
  }
  carregar() {
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.selectMov = []
    this.limpaCamposForm();
    this.loadRegistros();
    this.toolbarInitial()
  }

  criarNovo() {
    this.novaConta = true;
    this.loadItems()
    this.toolbarNew()
  }

  loadItems() {
    this.opcoesTipo = new Array();
    this.opcoesTipo.push({ label: '0', value: 0 });
    this.httpService.wait()
    this.httpService.get(`cadecd?$filter=empresa/id eq
    ${this.dblinkedSessionService.empresa.id}
     and period(dtIni,dtFin) eq
     ${this.dblinkedSessionService.periodo.year.value}
     &$top=1&$orderby=dtIni desc&$select=id,id`)
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesTipo.push({
            label: element.id,
            value: element.id
          });
          this.httpService.done()
        });
      }, error => this.httpService.handleError(error, () => this.httpService.done()));
  }

  loadRegistros() {
    this.loadItems()
    this.httpService.wait()
    this.httpService
      .get(`/movtermoae?$select=dnrcAbert,numOrd,natLivr,nire,dtArq,dtArqConv,dtExSocial,nomeAuditor,codCvmAuditor,dnrcEncer,cadEcd/id&$filter=cadEcd/empresa/id eq
      ${this.dblinkedSessionService.empresa.id}
      &$orderby=dnrcAbert`)
      .subscribe(
        (retorno) => {
          this.registroMovTermoAe = new Array();
          retorno.value.forEach(item => {
            this.registroMovTermoAe.push({
              ...item,
              dtArquivo: this.convertDate(item.dtArquiv),
              dtArquivoConvert: this.convertDate(item.dtArqConv),
              dtEnceSocial: this.convertDate(item.dtExSocial),
            });
            this.httpService.done()
          })
          this.httpService.done()
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar Centro de Custo';
    this.editarConta = true;

    this.loadItems()
    this.toolbarEdit()

    this.selectMov = []
    this.limpaCamposForm();
    this.httpService.wait()
    this.httpService.get(`/movtermoae(${this.ids[0]})?$select=dnrcAbert,numOrd,natLivr,nire,dtArq,dtArqConv,dtExSocial,nomeAuditor,codCvmAuditor,dnrcEncer,cadEcd/id,cadEcd/id&$count=true`)
      .subscribe(plano => {
        this.termFixoAber = plano.value.dnrcAbert;
        this.numOrdem = plano.value.numOrd;
        this.natLivro = plano.value.natLivr;
        this.nire = plano.value.nire;
        this.dtArquiv = this.dateDay(plano.value.dtArq);
        this.dtArquiv = this.convertDate(this.dtArquiv);
        this.dtArqConv = this.dateDay(plano.value.dtArqConv);
        this.dtArqConv = this.convertDate(this.dtArqConv);
        this.dtExSocial = this.dateDay(plano.value.dtExSocial);
        this.dtExSocial = this.convertDate(this.dtExSocial);
        this.nomeAuditor = plano.value.nomeAuditor;
        this.cvmAuditor = plano.value.codCvmAuditor;
        this.termFixoEncer = plano.value.dnrcEncer;
        this.cad_ecd = new MovTermoAe()
        plano.value.cadEcd ? this.cad_ecd = plano.value.cadEcd.id : this.cad_ecd = null
        this.httpService.done()
      },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      dnrcAbert: this.termFixoAber,
      numOrd: this.numOrdem,
      natLivr: this.natLivro,
      nire: this.nire,
      dtArq: this.dtArquiv,
      dtArqConv: this.dtArqConv,
      dtExSocial: this.dtExSocial,
      nomeAuditor: this.nomeAuditor,
      codCvmAuditor: this.cvmAuditor,
      dnrcEncer: this.termFixoEncer,
      empresa: {
        id: this.dblinkedSessionService.empresa.id
      },
      cadEcd: {
        id: this.cad_ecd
      },
    };

    this.httpService.wait();
    this.httpService.post('/movtermoae', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  filtrarVazio(jsondata) {
    if (this.cvmAuditor === null || this.cvmAuditor == '') {
      delete jsondata['codCvmAuditor']
    }
    if (this.nomeAuditor === null || this.nomeAuditor == '') {
      delete jsondata['nomeAuditor']
    }
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const listasEnviar = {
      id: this.ids[0],
      dnrcAbert: this.termFixoAber,
      numOrd: this.numOrdem,
      natLivr: this.natLivro,
      nire: this.nire,
      dtArq: this.convertDateFormat(this.dtArquiv),
      dtArqConv: this.convertDateFormat(this.dtArqConv),
      dtExSocial: this.convertDateFormat(this.dtExSocial),
      nomeAuditor: this.nomeAuditor,
      codCvmAuditor: this.cvmAuditor,
      dnrcEncer: this.termFixoEncer,
    };
    this.filtrarVazio(listasEnviar);

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/movtermoae', key, listasEnviar)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do projeto!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      const listasEnviar = {
        idsContaContabil: this.ids,
      };
      this.httpService.wait();
      this.httpService.delete('/movtermoae', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Projeto excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());
    }
  }

  onRowSelect(event: any) {
    this.ids = new Array()
    this.ids.push(event.data.id);
    event.selected = false;
    this.changeToolbar()
  }

  onRowUnselect(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.changeToolbarUn()
  }

  changeToolbar() {
    this.toolbarMov.setVisible('edit', true)
    this.toolbarMov.setVisible('delete', true)
  }

  changeToolbarUn() {
    this.toolbarMov.setVisible('edit', false)
    this.toolbarMov.setVisible('delete', false)
  }

  validacao(): boolean {
    if ((this.termFixoAber === null || this.termFixoAber === undefined) || this.termFixoAber.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Termo Fixo de Abertura é Obrigatório' });
      return true;
    }
    if ((this.numOrdem === null || this.numOrdem === undefined)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Número de ordem é Obrigatório' });
      return true;
    }
    if ((this.natLivro === null || this.natLivro === undefined) || this.natLivro.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Natureza do livro é Obrigatória' });
      return true;
    }
    if ((this.nire === null || this.nire === undefined)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nire é Obrigatório' });
      return true;
    }
    if ((this.dtArquiv === null || this.dtArquiv === undefined) || this.dtArquiv.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data Arquivamento é Obrigatória' });
      return true;
    }
    if ((this.dtArqConv === null || this.dtArqConv === undefined) || this.dtArqConv.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data Arquivamento.Conv é Obrigatória' });
      return true;
    }
    if ((this.dtExSocial === null || this.dtExSocial === undefined) || this.dtExSocial.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Data Encerramento.Ex.Social é Obrigatória' });
      return true;
    }
    if ((this.termFixoEncer === null || this.termFixoEncer === undefined) || this.termFixoEncer.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Termo Fixo de Encerramento é Obrigatório' });
      return true;
    }
    if ((this.cad_ecd === null || this.cad_ecd === undefined) || this.cad_ecd.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'CAD ECD é Obrigatório' });
      return true;
    }
    return false;
  }

  private convertDate(milisegundos: string) {
    const d = new Date(milisegundos);

    return this.utilService.dateToStringDate(d);
  }

  private dateDay = (milisegundos) => milisegundos + 86400000;

  private convertDateFormat(data) {
    let formatoBrasileiro;

    if (typeof data === 'object') {//GMT...
      const d = new Date(data);
      formatoBrasileiro = this.utilService.dateToStringDate(d);
    } else {//dd-mm-YYYY
      formatoBrasileiro = data;
    }

    const [dia, mes, ano] = formatoBrasileiro.split('/');

    return `${ano}-${mes}-${dia}`;
  }

  private limpaCamposForm() {
    this.termFixoAber = '';
    this.numOrdem = null
    this.natLivro = '';
    this.nire = null
    this.dtArquiv = '';
    this.dtArqConv = '';
    this.dtExSocial = '';
    this.nomeAuditor = '';
    this.cvmAuditor = '';
    this.termFixoEncer = '';
    this.cad_ecd = null
  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.sessionSubscription.remove(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }
  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }
  get loading(): boolean {
    return this.httpService.loading;
  }
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
