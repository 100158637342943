import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { Message, MessageService } from 'primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';
declare var $: any;

@Component({
  selector: 'cadastro-regra-ofx',
  templateUrl: './cadastro-regra-ofx.component.html',
  styleUrls: ['./cadastro-regra-ofx.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadastroRegraOfxComponent implements OnInit, OnDestroy {
  /////////////////////////////
  // REFATOR SIMON LALLEMENT //
  // 26/07/2021              //
  /////////////////////////////

  private sessionSubscription: Subscription;

  //Strings
  titlePrincipal: string = 'CADASTRO OFX';
  currentStateOfxRegras: string = 'initial';

  //Messages
  msgs: Message[] = [];

  //Boolean
  isCardOfxRegras: boolean = true;

  //Dropdown
  operacoesRegrasContabeis: SelectItem[] = [];

  //Number
  operacaoEscolhida: number = 0;

  //Array
  listaOFXMemoRelacionar: Array<any> = new Array();
  listaOFXMemoRelacionados: Array<any> = new Array();

  toolbarMainIni = [
    { icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
      this.ShowHideOfxRegras()
      this.changeStateOfxRegras()
    } },
  ]

  public toolbarMain = new Toolbar();

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService) { }

  ngOnInit() {
    this.titleService.title = 'Cadastro de regras OFX';
    this.toolbarService.hidden = true;
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });

    this.toolbarMain.setAll(this.toolbarMainIni)
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.httpService.wait();
      this.httpService.get(this.configService.defaultUrlApiUpload + 'get_distinct_operacao_regra_contabil/' + this.dblinkedSessionService.empresa.id)
        .subscribe(
          res => {
            if (res["severity"] === "error") {
              this.exibirMensagem(res)

            } else {
              this.operacoesRegrasContabeis = [];
              res["data"].forEach(element => { this.operacoesRegrasContabeis.push(element) });
            }
            this.httpService.done()
          },
          err => {
            console.log(err)
            this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + err.message })
            this.httpService.done()
          }
        )
    }
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }
  exibirMensagem(obj) {
    this.msgs = []
    if (obj.severity === "error") {
      this.msgs.push({ severity: 'error', summary: 'Erro', detail: obj.message })

    } else if (obj.severity === "warn") {
      this.msgs.push({ severity: 'warn', summary: 'Atenção', detail: obj.message })

    } else if (obj.severity === "info") {
      this.msgs.push({ severity: 'info', summary: 'Informação', detail: obj.message })

    } else {
      this.msgs.push({ severity: 'success', summary: 'Sucesso', detail: obj.message })
    }
  }

  hideMessage() {
    this.msgs = [];
  }

  atualizarRegrasOFX() {
    if (this.operacaoEscolhida === 0) {
      this.exibirMensagem({ "severity": "warn", "message": "Selecione uma operação contabil." })
      return
    }

    const info = {
      limemo: this.listaOFXMemoRelacionados,
      regra_contabil_id: this.operacaoEscolhida,
      user_id: this.sessionService.loggedUser.id,
      empresa_id: this.dblinkedSessionService.empresa.id
    };

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'insert_ofx_rule', info)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.buscarListaOFXMemo()
          this.httpService.done()
        },
        err => {
          console.log(err)
          this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + err.message })
          this.httpService.done()
        }
      )
  }

  buscarListaOFXMemo() {
    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'get_distinct_associated_memo_with_rules/' + this.dblinkedSessionService.empresa.id + '_' + this.operacaoEscolhida)
      .subscribe(
        res => {
          if (res["severity"] === "error") {
            this.exibirMensagem(res)

          } else {
            this.listaOFXMemoRelacionados = new Array();
            this.listaOFXMemoRelacionar = new Array();
            res["data"][0].forEach(element => this.listaOFXMemoRelacionar.push(element))
            res["data"][1].forEach(element => this.listaOFXMemoRelacionados.push(element))
          }
          this.httpService.done()
        },
        err => {
          console.log(err)
          this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + err.message })
          this.httpService.done()
        }
      )
  }

  ShowHideOfxRegras() {
    this.isCardOfxRegras = !this.isCardOfxRegras;

    this.toolbarMain.setIcon('btn1', this.isCardOfxRegras ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn1', this.isCardOfxRegras ? 'Ver menos' : 'Ver mais')
  }

  changeStateOfxRegras() {
    this.currentStateOfxRegras = this.currentStateOfxRegras === 'initial' ? 'final' : 'initial';
  }
}
