import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { isNullOrUndefined } from 'util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message, MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpService } from 'app/shared/services/http.service';
import { Table } from 'primeng/table';
import { Toolbar } from 'app/shared/model/toolbar.model';
declare var $: any;

@Component({
  selector: 'app-indices',
  templateUrl: './indices.component.html',
  styleUrls: ['./indices.component.scss'
  ],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class IndicesComponent implements OnInit, OnDestroy {
  currentStateIndice = 'initial';
  changeStateIndice() {
    this.currentStateIndice = this.currentStateIndice === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  title: string;
  subtitle: string;
  indicesLista: Array<any> = new Array();
  msgs: Message[] = null;
  dtInicial: Date = new Date();
  dtFinal: Date = new Date();
  DefFinalDate: Date;
  DefInitDate: Date;
  listIndices: Array<any> = new Array();
  idIndices: number = 0;
  listaExport: Array<any | any | any | any> = new Array();
  colsIndice: any[];

  @ViewChild('dt2') dt2: Table;

  toolbarMainIni = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.dt2.exportCSV() },
    { icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
      this.ShowHideIndice()
      this.changeStateIndice()
    } },
  ]

  public toolbarMain = new Toolbar();

  constructor(
    private configService: ConfigService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private messageService: MessageService,
    private httpService: HttpService
  ) { }

  ngOnInit() {

    this.DefFinalDate = new Date();
    this.DefInitDate = new Date(this.DefFinalDate.getTime() - 31104000000);
    this.dtInicial = this.DefInitDate
    this.dtFinal = this.DefFinalDate
    this.title = 'ÍNDICES';
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
          this.listarIndices();
          this.getIndices();
          this.toolbarEnable();
        }
      });

    this.colsIndice = [
      { field: 'indice', header: 'INDICE' },
      { field: 'mes', header: 'MÊS' },
      { field: 'ano', header: 'ANO' },
      { field: 'taxa', header: 'TAXA' },
      { field: 'acum_ano', header: 'ACUM. ANO' },
      { field: 'acum_12_meses', header: 'ACUM. 12 MESES' }
    ];

    this.toolbarMain.setAll(this.toolbarMainIni)
  }

  ngOnDestroy() {

    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);

  }

  listarIndices() {
    this.listIndices = new Array();
    this.listIndices.push({ label: "Todos", value: 0 });
    this.listIndices.push({ label: "SELIC (COPOM)", value: 1 });
    this.listIndices.push({ label: "INPC (IBGE)", value: 2 });
    this.listIndices.push({ label: "IPCA (IBGE)", value: 3 });
    this.listIndices.push({ label: "IGP-M (FGV)", value: 4 });
  }

  getIndices() {
    let dtinit: Date;
    let dtfin: Date;
    if (isNullOrUndefined(this.dtInicial)) {
      dtinit = this.DefInitDate;
      dtfin = this.DefFinalDate;

    } else {
      dtinit = this.dtInicial;
      dtfin = this.dtFinal;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'dtinicial': dtinit.getFullYear() + "-" + (dtinit.getMonth() + 1) + "-" + dtinit.getDate(),
        'dtfinal': dtfin.getFullYear() + "-" + (dtfin.getMonth() + 1) + "-" + dtfin.getDate(),
        'indice': this.idIndices.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/indices', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            this.indicesLista = new Array();
            res["data"].forEach(element => { this.indicesLista.push(element) });
          }
          this.httpService.done();
        },
        err => {
          this.httpService.done();
          console.log(err);
          this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível recuperar os índices, contate o suporte." });
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return 20;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  toolbarEnable() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('add');
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('remove');
  }

  onFilter(event: any) {

  }

  /*Show Hide Indice*/
  isCardIndice: boolean = true;
  ShowHideIndice() {
    this.isCardIndice = !this.isCardIndice;

    this.toolbarMain.setIcon('btn2', this.isCardIndice ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn2', this.isCardIndice ? 'Ver menos' : 'Ver mais')
  }
}
