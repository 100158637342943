import { TipoIngresso}        from './tipo-ingresso.model';
import { isNullOrUndefined }  from 'util';



export class PalestraIngresso{
    id                        : number;
    tipoIngressoid            : number;
    quantidade                : number;
    tipoIngressoDescricao     : string;


   constructor(palestraIngresso?: PalestraIngresso | number){
      if (!isNullOrUndefined(palestraIngresso)) {
         if (typeof palestraIngresso === 'number') {
            this.id = palestraIngresso;

         } else {
            this.id                    = palestraIngresso.id;
            this.tipoIngressoid        = palestraIngresso.tipoIngressoid;
            this.quantidade            = palestraIngresso.quantidade;
            this.tipoIngressoDescricao = palestraIngresso.tipoIngressoDescricao;

         }
      }

   }









}