import { Component, OnInit, OnDestroy, ɵConsole } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { PlanoContas } from '../model/plano-contas.model';
import { TipoAjustesUsGaap } from 'app/util/model/tipo-ajustes-us-gaap';
import { LancamentosFormalizacao } from '../model/lancamentos-formalizacao.model';
import { CadCcus } from '../model/cad-ccus.model';
import { PlanoGerencialContas } from '../model/plano-gerencial-contas.model';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-us-gaap',
  templateUrl: './us-gaap.component.html',
  styleUrls: ['./us-gaap.component.scss'],
})
export class UsGaapComponent implements OnInit, OnDestroy {


  private sessionSubscription: Subscription;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  tipoajustesusGaapSelecionada: string;
  historico: string;

  contasNaoMapeadas: Array<any> = new Array();
  periodoUsGaap: Array<any> = new Array();
  brGaapReport: Array<any> = new Array();
  usGaapReport: Array<any> = new Array();
  lctos: Array<any> = new Array();

  lcto: any;
  currentYear: any;
  planoLocal: any;
  valor: any;
  debitoPc: any;
  creditoPc: any;
  debitoPgc: any;
  creditoPgc: any;
  ccus: any;
  tipoAjuste: any;

  colsGaapLocal: any[] = [];
  colsGaapHost: any[] = [];
  colsGaapContaNaoMapeada: any[] = [];

  msgs: Message[];
  msgsModal: Message[];
  msgsNaoMapeadas: Message[];

  opcoesPlanoContas: SelectItem[];
  opcoesCentroCusto: SelectItem[];
  opcoesPlanoContasGerencial: SelectItem[];
  opcoesTipoAjuste: SelectItem[];
  opcoesTipoAjustesUsGaap: SelectItem[];
  opcoesGerenciais: SelectItem[];

  tipo: number;
  contaSelecionada: number;
  idReferencial: number;
  valorTotalDeb: number;
  valorTotalCred: number;
  idLcto: number = 0;

  exibirRelacionamento: boolean;
  exibirAjustes: boolean;
  exibirNaoMapeadas: boolean;
  clonarVisible: boolean = false;

  currentStatePesquisaLancamentos = 'initial';
  currentStateResultadosLancamentos = 'initial';

  primeiroDiadoMes: Date;
  ultimoDiadiaMes: Date;
  dataLcto: Date;

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient) {
  }

  ngOnInit(): void {

    this.tipo = 9;

    this.titlePrincipal = 'US GAAP';
    this.subtitle = 'Relatórios US GAAP'
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.brGaapReport = new Array();
    this.exibirRelacionamento = false;
    this.exibirAjustes = false;
    this.idReferencial = 0;
    this.periodoUsGaap = new Array();
    this.contasNaoMapeadas = new Array();
    this.exibirNaoMapeadas = false;
    this.historico = '';
    this.planoLocal =true;

    this.valorTotalDeb = 0;
    this.valorTotalCred = 0;
    this.defaultDate = new Date();


     this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {

          this.activatedRoute.params.subscribe(params => {

            if (this.tipo === 9 || this.tipo !== params.tipo) {
              this.tipo = params.tipo;
              this.change();
            }
          });

        }

      });

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

        if(this.tipo > 0){

          const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
        this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
        const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
        this.defaultDate = new Date(dt);
        this.primeiroDiadoMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth(), 1);
        this.ultimoDiadiaMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
        this.dataLcto = new Date(dt);

        this.buscarCadastros();
        this.buscarLctos();

        this.httpService.wait();
        this.httpService.get('/planogerencial?$select=id,codigo,nome,niveis,moeda&$' +
          'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=codigo')
          .subscribe(ref => this.loadPlanoGerenciais(ref.value),
            error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());

            this.httpService.wait();
            this.httpService.get('/custom/ecd-report/usgaap-gerencial(' + this.dblinkedSessionService.empresa.id + ')')
              .subscribe(res => {
                if (res.error) {

                  this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
                } else {
                  this.idReferencial = res.value;
                }
              },
                error => this.httpService.handleError(error, () => this.change()),
                () => this.httpService.done());

        }else {
          this.httpService.wait();
        this.httpService.get('/custom/ecd-report/usgaap-buscar-periodo(' + this.dblinkedSessionService.empresa.id +
          ',' + this.dblinkedSessionService.periodo.year.value + ')')
          .subscribe(res => {
            if (res.error) {

              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
            } else {

              this.periodoUsGaap = new Array();
              this.hideMessage();
              res.value.forEach(element => {
                this.periodoUsGaap.push(element);
              });
            }
          },
            error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());
        }



    }
 }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {


  }

  onTabChange(event: any) {

    if (event === 1) {
      this.httpService.wait();
      this.httpService.get('/custom/ecd-report/usgaap(' + this.dblinkedSessionService.empresa.id +
        ',' + this.dblinkedSessionService.periodo.month.value + ',' + this.dblinkedSessionService.periodo.year.value + ',US)')
        .subscribe(res => {
          if (res.error) {

            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
            this.usGaapReport = new Array();
          } else {
            this.usGaapReport = new Array();
            this.hideMessage();
            res.value.forEach(element => {
              this.usGaapReport.push(element);
            });
          }
        },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
    } else {
      this.httpService.wait();
      this.httpService.get('/custom/ecd-report/usgaap(' + this.dblinkedSessionService.empresa.id +
        ',' + this.dblinkedSessionService.periodo.month.value + ',' + this.dblinkedSessionService.periodo.year.value + ',BR)')
        .subscribe(res => {
          if (res.error) {

            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
            this.brGaapReport = new Array();
          } else {
            this.brGaapReport = new Array();
            this.hideMessage();
            res.value.forEach(element => {
              this.brGaapReport.push(element);
            });
          }
        },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
    }
  }

  editar() {
    this.exibirRelacionamento = true;
  }

  atualizarRelacionamento() {
    const listasEnviar = {
      planoGerencial: this.idReferencial,
      empresaId: this.dblinkedSessionService.empresa.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/ecd-report/salvar-relacionamento-usgaap', listasEnviar)
      .subscribe(res => {
        if (res.error) {

          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
          this.change();
          this.exibirRelacionamento = false;
        } else {

          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
          this.change();
          this.exibirRelacionamento = false;
        }

      },
        error => this.httpService.handleError(error, () => this.atualizarRelacionamento()),
        () => this.httpService.done());
  }

  loadPlanoGerenciais(value: any) {

    this.opcoesGerenciais = [];
    this.opcoesGerenciais.push({ label: 'Selecione o Gerencial', value: 0 });
    value.forEach(element => {
      this.opcoesGerenciais.push({ label: element.codigo + ' - ' + element.nome, value: element.id });
    });

  }

  ajustes() {
    this.exibirAjustes = true;
  }

    validarAjustes(ajustes: any) {

    let valorDeb: number;
    let valorCred: number;


    ajustes.forEach(element => {
      if (element.planoContas) {
        if (element.planoContas.codCta) {
          valorDeb = valorDeb + element.valor;
        }
      }


      if (element.planoContasCredito) {
        if (element.planoContasCredito.id) {
          valorCred = valorCred + element.valor;
        }
      }



    });

    if (valorDeb !== valorCred) {
      alert('Valor de Débito e Crédito estão diferentes!');
    }

  }


  buscarUsGaapAjustes() {
    this.httpService.wait();
    this.httpService.get('/tipoajustesusgaap?$select=id,nome' + '&$orderby=nome')
      .subscribe(ref => this.loadUsGaapAjustes(ref.value),
        error => this.httpService.handleError(error, () => this.buscarUsGaapAjustes()),
        () => this.httpService.done());
  }

  loadUsGaapAjustes(array: Array<TipoAjustesUsGaap>) {

    this.opcoesTipoAjustesUsGaap = [];
    this.opcoesTipoAjustesUsGaap.push({ label: 'Selecionar', value: null });

    array.forEach(element => {
      if (element.nome) {
        this.opcoesTipoAjustesUsGaap.push({ label: element.nome, value: element });
      }
    });
  }




  encerrarPeriodo(value: any) {

    console.log(value);

    if (confirm('Deseja realmente ' + (value.statusId === 2 ? 'abrir' : 'encerrar')+' o mês de ' + value.mes + '?')) {
      const listasEnviar = {
        cadEcdId: value.id,
        usuarioId: this.sessionService.loggedUser.id,
        tipo: (value.statusId === 2 ? 'A' : 'E')
      };

      this.httpService.wait();
      this.httpService.post('/custom/ecd-report/usgaap-status-periodo', listasEnviar)
        .subscribe(res => {
          if (res.error) {

            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
            this.change();
          } else {

            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
            this.change();
          }

        },
          error => this.httpService.handleError(error, () => this.encerrarPeriodo(value)),
          () => this.httpService.done());

    }
  }

  abrirPeriodo(value: any) {
    if (confirm('Deseja realmente abrir o mês de ' + value.mes + '?')) {

      const listasEnviar = {
        cadEcdId: value.id,
        usuarioId: this.sessionService.loggedUser.id,
        tipo: 'A'
      };

      this.httpService.wait();
      this.httpService.post('/custom/ecd-report/usgaap-status-periodo', listasEnviar)
        .subscribe(res => {
          if (res.error) {

            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
            this.change();
          } else {

            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: res.message });
            this.change();
          }

        },
          error => this.httpService.handleError(error, () => this.encerrarPeriodo(value)),
          () => this.httpService.done());

    }
  }

  verificarContasNaoMapeadas() {
    this.exibirNaoMapeadas = true;
    this.msgsNaoMapeadas = [];
  }
  onRowSelect(event: any) {

    //this.dblinkedSessionService.periodo.month.value
    //this.dblinkedSessionService.periodo.year.value

    const lastDay = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value + 1, 0);

    const mes = this.dblinkedSessionService.periodo.month.value < 10 ? '0' + this.dblinkedSessionService.periodo.month.value.toString() : this.dblinkedSessionService.periodo.month.value.toString();

    console.log(event);
    window.open('/ecd/report-balancete-mensal-lcto/' + this.dblinkedSessionService.empresa.id + '/' + event.codCta +
      '/' + this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01' +
      '/' + this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate() + '/balancete' + '/false', '_blank');

  }





  buscarCadastros() {
    this.httpService.wait();
    this.httpService.get('/custom/usgaap/usgaap-buscar-cadastros(' + this.dblinkedSessionService.empresa.id +
      ',' + this.dblinkedSessionService.periodo.year.value + ')')
      .subscribe(res => {

         console.log(res);
        this.opcoesPlanoContas = [];
        this.opcoesPlanoContas.push({ label: 'Selecione', value: null });

        this.opcoesCentroCusto = [];
        this.opcoesCentroCusto.push({ label: 'Selecione', value: null });

        this.opcoesPlanoContasGerencial = [];
        this.opcoesPlanoContasGerencial.push({ label: 'Selecione', value: null });

        this.opcoesTipoAjuste = [];
        this.opcoesTipoAjuste.push({ label: 'Selecione', value: null });

         res.forEach(element => {
          if(element.tipo === 'PC'){
            let pc = new PlanoContas();
            pc.id = element.id;
            pc.codCta = element.codCta;
            pc.descrCta = element.descrCta;
            pc.indCta = element.indCta;
            pc.nivel= element.nivel;
            this.opcoesPlanoContas.push({ label: element.codCta + ' - ' + element.descrCta, value: pc });
          } else if(element.tipo === 'CC'){
            let cc = new CadCcus();
            cc.id = element.id;
            cc.codCcus = element.codCcus;
            cc.descrCcus = element.descrCcus;
            this.opcoesCentroCusto.push({ label: element.codCcus + ' - ' + element.descrCcus, value: cc });
          } else if(element.tipo === 'PGC'){
            let pgc = new PlanoGerencialContas();
            pgc.id = element.id;
            pgc.codCta = element.codCta;
            pgc.descrCta = element.descrCta;
            this.opcoesPlanoContasGerencial.push({ label: element.codCta + ' - ' + element.descrCta, value: pgc });
          } else if(element.tipo === 'TA'){
            let ta = new TipoAjustesUsGaap();
            ta.id = element.id;
            ta.nome = element.nome;
            this.opcoesTipoAjuste.push({ label: element.nome, value: ta });
          }



         });

      },
        error => this.httpService.handleError(error, () => this.buscarCadastros()),
        () => this.httpService.done());
  }

  changeStatePesquisaLancamentos() {
    this.currentStatePesquisaLancamentos = this.currentStatePesquisaLancamentos === 'initial' ? 'final' : 'initial';
  }

  changeStateResultadosLancamentos() {
    this.currentStateResultadosLancamentos = this.currentStateResultadosLancamentos === 'initial' ? 'final' : 'initial';
  }

  salvarLctoUsGaap(){
    if (confirm('Deseja realmente salvar este lançamento?')) {

      if((isNullOrUndefined(this.debitoPc) && isNullOrUndefined(this.debitoPgc)) && isNullOrUndefined(this.creditoPc) && isNullOrUndefined(this.creditoPgc)){
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'É Obrigatório selecionar uma conta'});
        return;
      }

      if(isNullOrUndefined(this.dataLcto)){
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'É Obrigatório selecionar uma data'});
        return;
      }

      if(isNullOrUndefined(this.tipoAjuste)){
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'É Obrigatório selecionar um Tipo de Ajuste'});
        return;
      }

      if(isNullOrUndefined(this.historico)){
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'É Obrigatório prrencher o histórico'});
        return;
      }

      if(isNullOrUndefined(this.valor)){
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: 'É Obrigatório informar o valor'});
        return;
      }


      const listasEnviar = {
        dataLcto: this.dataLcto,
        historico: this.historico,
        valor: this.valor,
        debitoPc: this.debitoPc,
        creditoPc: this.creditoPc,
        debitoPgc: this.debitoPgc,
        creditoPgc: this.creditoPgc,
        ccus: this.ccus,
        tipoAjuste: this.tipoAjuste,
        local: this.planoLocal,
        usuario: this.sessionService.loggedUser.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/usgaap/usgaap-ajustes', listasEnviar)
        .subscribe(result => {

          if(!result.error){
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: result.message});
            this.buscarLctos();
          }else{
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: result.message});
            return;
          }



        },
          error => this.httpService.handleError(error, () => this.salvarLctoUsGaap()),
          () => this.httpService.done());

     }


  }

  buscarLctos() {

    this.lctos = new Array();
    this.httpService.wait();
    this.httpService.get('/custom/usgaap/usgaap-buscar-lctos(' + this.dblinkedSessionService.empresa.id +
      ',' + this.dblinkedSessionService.periodo.year.value + ')')
      .subscribe(res => {



        res.value.forEach(element => {
          this.lctos.push(element);
        });



      },
        error => this.httpService.handleError(error, () => this.buscarLctos()),
        () => this.httpService.done());
  }

  excluirLcto(id: any) {
    if (confirm('Deseja realmente excluir este lançamento?')) {

      this.httpService.wait();
      this.httpService.get('/custom/usgaap/excluir-lcto(' + id + ',' + this.dblinkedSessionService.empresa.id+')')
        .subscribe(res => {

          if(!res.error){
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: res.message});
            this.buscarLctos();
          }else{
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: res.message});
            return;
          }


        },
          error => this.httpService.handleError(error, () => this.excluirLcto(id)),
          () => this.httpService.done());

    }
  }

  modalClonar(id: any){
    this.clonarVisible = true;
    this.idLcto = id;
  }

  clonarLcto() {



      const listasEnviar = {
        dataLcto: this.dataLcto,
        idLcto: this.idLcto,
        usuario: this.sessionService.loggedUser.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/usgaap/clonar-lcto', listasEnviar)
        .subscribe(result => {

          if(!result.error){
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: result.message});
            this.buscarLctos();
            this.clonarVisible = false;
          }else{
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: result.message});
            return;
          }


        },
          error => this.httpService.handleError(error, () => this.clonarLcto()),
          () => this.httpService.done());

  }

  async baixarRelatorioUsGaap() {
    const infos = {
      mes: this.dblinkedSessionService.periodo.month.value,
      ano: this.dblinkedSessionService.periodo.year.value,
      empresa_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'Cache-Control': 'no-store, max-age=0'
      }),
      responseType: 'blob' as 'json',
    }

    this.httpService.wait()
    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'contabil/relatorio-usgaap', infos, httpOptions).toPromise()
        .then((res: any) => {
          let link = document.createElement('a')
          let url = window.URL.createObjectURL(res)
          let fileName = "Relatório USGaap.xlsx"
          link.href = url
          link.download = fileName
          link.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((err: HttpErrorResponse) => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.message })
        })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.httpService.done()
    }
  }

}
