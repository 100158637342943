import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from './../shared/model/toolbar-button.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as Highcharts from 'highcharts';

declare var $: any;


@Component({
  selector: 'app-projetodbl',
  templateUrl: './projeto-dbl.component.html',
  styleUrls: ['./projeto-dbl.component.scss', './../shared/styles/centralweb.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ],

})

export class DblComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;
  arquivos: FileList;

  // Functions animated show close box card
  currentStateStatus = 'initial';
  currentStateVencer = 'initial';
  currentStateItemSelecionado = 'initial';
  currentStateProcessadosDetalhe = 'initial';
  currentStateProcessadosDetalheRegraRelatorio = 'initial';
  currentStateProcessados = 'initial';
  currentStateGerarApuracao = 'initial';
  currentStateGerarApuracaoSub = 'initial';
  currentStateDetalhePis = 'initial';
  currentStateDetalheCofins = 'initial';
  currentStateDetalheIcmsst = 'initial';

  changeStateStatus() {
    this.currentStateStatus = this.currentStateStatus === 'initial' ? 'final' : 'initial';
  }
  changeStateVencer() {
    this.currentStateVencer = this.currentStateVencer === 'initial' ? 'final' : 'initial';
  }
  changeStateItemSelecionado() {
    this.currentStateItemSelecionado = this.currentStateItemSelecionado === 'initial' ? 'final' : 'initial';
  }
  changeStateProcessados() {
    this.currentStateProcessados = this.currentStateProcessados === 'initial' ? 'final' : 'initial';
  }
  changeStateProcessadosDetalhe() {
    this.currentStateProcessadosDetalhe = this.currentStateProcessadosDetalhe === 'initial' ? 'final' : 'initial';
  }
  changeStateProcessadosDetalheRegrasRelatorios() {
    this.currentStateProcessadosDetalheRegraRelatorio = this.currentStateProcessadosDetalheRegraRelatorio === 'initial' ? 'final' : 'initial';
  }
  changeStateGerarApuracao() {
    this.currentStateGerarApuracao = this.currentStateGerarApuracao === 'initial' ? 'final' : 'initial';
  }
  changeStateGerarApuracaoSub() {
    this.currentStateGerarApuracaoSub = this.currentStateGerarApuracaoSub === 'initial' ? 'final' : 'initial';
  }
  changeStateDetalhePis() {
    this.currentStateDetalhePis = this.currentStateDetalhePis === 'initial' ? 'final' : 'initial';
  }
  changeStateDetalheCofins() {
    this.currentStateDetalheCofins = this.currentStateDetalheCofins === 'initial' ? 'final' : 'initial';
  }
  changeStateDetalheIcmsst() {
    this.currentStateDetalheIcmsst = this.currentStateDetalheIcmsst === 'initial' ? 'final' : 'initial';
  }

  // variables DBL
  title: string;
  tProcess: any[] = [];
  tFilteredProcess: any[] = [];
  tRegras: any[] = [];
  tRegrasDetalhe: any[] = [];
  tRelatoriosDetalhe: any[] = [];
  tRelatorios: any[] = [];
  tDetalhePis: any[] = [];
  tDetalheContribuicaoPis: any[] = [];
  tAjustePis: any[] = [];
  tAjusteContribuicaoPis: any[] = [];
  tCreditoPis: any[] = [];
  tControleCreditoPis: any[] = [];
  tControleRetidosPis: any[] = [];
  tReceitasPis: any[] = [];
  tContribuicaoPis: any[] = [];
  tipoArquivo: any[];
  tipoAjustePis: any[];
  tBaseCreditoPis: any[] = [];
  tBaseContribuicaoPis: any[] = [];
  tContribuicaoRetidaFonte: any[] = [];
  tF100: any[] = [];
  tF120: any[] = [];
  tF130: any[] = [];
  tApuracaoICMS: any[] = [];
  tApuracaoBeneficioIncentivo: any[] = [];
  tApuracaoICMSST: any[];
  tApuracaoIPI: any[];
  tApuracaoIPIConsolidados: any[];
  tApuracaoIPIAjuste: any[];
  tApuracaoICMSSTDetalhe: any[];
  codAjustePis: any[];
  codContriSocial: any[];
  tipoCreditoPis: any[];
  aliqAjustePis: any[];
  naturezaRetFonte: any[];
  indTipoOper: any[]
  naturezaBaseCredito: any[];
  identBensGrupo: any[];
  indOrigemBem: any[];
  indUtiliBens: any[];
  indNumParc: any[];
  cstPIS: any[];
  cstCOFINS: any[];
  codBaseNatureza: any[];
  indOrigemCredito: any[];
  origemCredito: any[];
  tipoCredito: any[];
  naturezaReceita: any[];
  indicadorCondJuridica: any[];
  AjusteApurDedu: any[];
  TipoAjusteIPI: any[];
  OrigemDocIPI: any[];
  CodAjusteApuracaoIPI: any[];
  statusArquivo: any[];
  statusRisco: any[];
  dtInicial: Date;
  dtFinal: Date;
  dtInicialStr: String;
  dtFinalStr: String;
  defaultDate: Date;
  DefInitDate: Date;
  DefFinalDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;
  meses: any;
  idsExcluir = new Array();
  idExcluir: any;
  checkedAll: boolean;
  msgs: Message[] = [];
  msgsModal: Message[];
  url_environment: any;
  processadoDetalhe: boolean;
  gerarApuracao: boolean;
  gerarApuracaoIcmsIpi: boolean;
  statusRegras: boolean = true;
  statusRelatorios: boolean = false;
  modalAjustePis: boolean;
  modalAjusteContribuicaoPis: boolean;
  modalBasePis: boolean;
  modalContribuicaoPis: boolean;
  modalControleCredito: boolean;
  modalControleRetidoFonte: boolean;
  modalContribuicaoRetidaFonte: boolean;
  modalF100: boolean;
  modalF120: boolean;
  modalF130: boolean;
  modalBeneficioIncentivo: boolean;
  modalAjusteApuracaoIPI: boolean;
  modalAjusteCofins: boolean;
  modalAjusteContribuicaoCofins: boolean;
  modalCentralEdicao: boolean;
  filtroStatus: any;
  filtroTipo: any;

  tCurrentApuracaoICMS: any;
  tCurrentM200Id: any;

  tCurrentApuracaoIPI: any;

  // ID do registro selecionado na tela inicial
  tIdReg0Selecionado: any;

  // Variáveis para as tabelas de COFINS
  tCreditoCofins: any[] = [];
  tContribuicaoCofins: any[] = [];
  tReceitasCofins: any[] = [];
  tControleCreditoCofins: any[] = [];
  tControleRetidosCofins: any[] = [];
  tAjusteCofins: any[] = [];
  tDetalheCofins: any[] = [];
  tDetalheContribuicaoCofins: any[] = [];
  tAjusteContribuicaoCofins: any[] = [];
  tBaseCreditoCofins: any[] = [];
  tBaseContribuicaoCofins: any[] = [];
  modalBaseCofins: boolean;
  modalContribuicaoCofins: boolean;

  // Dicionário contendo os nomes das tabelas disponíveis na Central de Edição
  centralEdicaoOpcoes: any[];

  // Lista contendo os campos da tabela selecionada na Central de Edição
  colunasCentralEdicao: any[];

  // Listas contendo os campos das tabelas-filhas obtidas na Central de Edição
  colunasTabelaFilha: any[];
  colunasTabelaFilha2: any[];

  // Variáveis usadas na Central de Edição
  centralEdicaoEscolhido: any;
  tabelaBusca: any;
  tabelaFilha: any;
  tabelaFilha2: any;
  tDadosCentralEdicao: any[];
  modalEditarDadoCentralEdicao: boolean;
  colunaSelecionadaCentralEdicao: any;
  idSelecionadoCentralEdicao: any;
  colunaSelecionadaTabelaFilha: any;
  idSelecionadoTabelaFilha: any;
  colunaSelecionadaTabelaFilha2: any;
  idSelecionadoTabelaFilha2: any;
  novoValorCentralEdicao: any;
  tabelaPaiCentralEdicao: boolean;  // Indica se a tabela selecionada possui tabelas "filhas"
  modalTabelaFilha: boolean;
  dadosTabelaFilha: any[];
  dadosTabelaFilha2: any[];
  containTabelaFilha2: boolean;
  tipoTabelaSelecionadaCentralEdicao: any;

  // Variáveis utilizadas na inserção/remoção de dados para Crédito PIS
  codAjustePisEscolhido: any;
  tipoAjustePisEscolhido: any;
  descricaoAjustePis: any;
  numeroDocumentoAjustePis: any;
  valorAjustePis: any;
  dtAjustePis: any;
  idCreditoPis: any;
  tipoCreditoPisM100: any;
  aliqAjustePisM100: any;

  // Variáveis utilizadas na inserção/remoção de dados para Contribuição PIS
  codAjusteContribuicaoPisEscolhido: any;
  tipoAjusteContribuicaoPisEscolhido: any;
  descricaoAjusteContribuicaoPis: any;
  numeroDocumentoAjusteContribuicaoPis: any;
  valorAjusteContribuicaoPis: any;
  dtAjusteContribuicaoPis: any;
  idCreditoContribuicaoPis: any;
  idM200: any;
  aliqAjustePisM210: any;
  codContribuicaoPisM210: any;

  // Variáveis utilizadas na inserção/remoção de dados para Crédito COFINS
  codAjusteCofinsEscolhido: any;
  tipoAjusteCofinsEscolhido: any;
  descricaoAjusteCofins: any;
  numeroDocumentoAjusteCofins: any;
  valorAjusteCofins: any;
  dtAjusteCofins: any;
  idCreditoCofins: any;
  tipoCreditoCofinsM500: any;
  aliqAjusteCofinsM500: any;

  // Variáveis utilizadas na inserção/remoção de dados para Contribuição COFINS
  codAjusteContribuicaoCofinsEscolhido: any;
  tipoAjusteContribuicaoCofinsEscolhido: any;
  descricaoAjusteContribuicaoCofins: any;
  numeroDocumentoAjusteContribuicaoCofins: any;
  valorAjusteContribuicaoCofins: any;
  dtAjusteContribuicaoCofins: any;
  idCreditoContribuicaoCofins: any;
  idM600: any;
  aliqAjusteCofinsM610: any;
  codContribuicaoCofinsM610: any;

  // Variáveis utilizadas na inserção/remoção de dados para a tabela F100
  tipoOperacaoF100: any;
  codParticipanteF100: any;
  codItemF100: any;
  vlOperacaoF100: any;
  dtOperacaoF100: any;
  codNaturezaBaseCreditoF100: any;
  indOrigCredF100: any;
  codContaContabilF100: any;
  codCentroCustosF100: any;
  descDocF100: any;
  cstPisF100: any;
  vlBcPisF100: any;
  aliqPisF100: any;
  vlPisF100: any;
  cstCofinsF100: any;
  vlBcCofinsF100: any;
  aliqCofinsF100: any;
  vlCofinsF100: any;

  // Variáveis utilizadas na inserção/remoção de dados para a tabela F120
  identBensGrupoF120: any;
  indOrigBemF120: any;
  indUtilBemF120: any;
  vlEncargoF120: any;
  parcelasEncargoF120: any;
  codNaturezaBaseCreditoF120: any;
  indOrigCredF120: any;
  codContaContabilF120: any;
  codCentroCustosF120: any;
  descDocF120: any;
  cstPisF120: any;
  vlBcPisF120: any;
  aliqPisF120: any;
  vlPisF120: any;
  cstCofinsF120: any;
  vlBcCofinsF120: any;
  aliqCofinsF120: any;
  vlCofinsF120: any;

  // Variáveis utilizadas na inserção/remoção de dados para a tabela F130
  identBensGrupoF130: any;
  indOrigBemF130: any;
  indUtilBemF130: any;
  vlAquisicaoF130: any;
  mesAnoAquisicaoF130: any;
  parcelasAquisicaoF130: any;
  indNumParcF130: any;
  codNaturezaBaseCreditoF130: any;
  vlBaseCalcCredF130: any;
  codContaContabilF130: any;
  codCentroCustosF130: any;
  descDocF130: any;
  cstPisF130: any;
  vlBcPisF130: any;
  aliqPisF130: any;
  vlPisF130: any;
  cstCofinsF130: any;
  vlBcCofinsF130: any;
  aliqCofinsF130: any;
  vlCofinsF130: any;

  // Variáveis utilizadas na inserção/remoção de dados para controle de créditos fiscais
  origemCreditosFiscaisEscolhido: any;
  tipoCreditosFiscaisEscolhido: any;
  cnpjCreditosFiscais: any;
  dtApuracaoCreditosFiscais: any;
  apuradoPeriodoCreditosFiscais: any;
  vlCredApuCreditosFiscais: any;
  vlTotalCreditosFiscais: any;
  utilRessarcCreditosFiscais: any;
  dispUtilizacaoCreditosFiscais: any;
  objPedidoRessarcCreditosFiscais: any;
  opcoesCreditosFiscais: any;
  extemporaneoCreditosFiscais: any;
  utilDescCreditosFiscais: any;
  utilCompCreditosFiscaisAtual: any;
  descEscrituracaoCreditosFiscais: any;
  utilCompCreditosFiscaisAnteriores: any;
  utilOutrosCreditosFiscais: any;
  utilPeriodoApurCreditosFiscais: any;

  /* Variável indicando se o modal do controle de créditos fiscais
  é para dados de PIS ou COFINS (true em caso afirmativo; false, caso contrário) */
  controleCreditosFiscaisPis: boolean;

  // Variáveis utilizadas na inserção/remoção de dados para Controle dos Valores Retidos na Fonte
  natControleRetFonteEscolhido: any;
  vlTotalControleRetFonte: any;
  vlPedRessarcControleRetFonte: any;
  saldoUtilizarFuturoControleRetFonte: any;
  dtOperacaoControleRetFonte: any;
  vlDeduzidoContribuicaoControleRetFonte: any;
  vlUtilizadoDeclaracaoControleRetFonte: any;

  /* Variável indicando se o modal do controle retido na fonte
  é para dados de PIS ou COFINS (true em caso afirmativo; false, caso contrário) */
  controleRetidoFontePis: boolean;

  /* Variáveis utilizadas na inserção/remoção de dados para a
  contribuição retida na fonte (F600PisCofins) */
  naturezaRetFonteEscolhido: any;
  naturezaReceitaEscolhido: any;
  indicadorCondJuridicaEscolhido: any;
  vlBaseCalcCredF600: any;
  vlTotalRetF600: any;
  dtF600: any;
  codReceitaF600: any;
  cnpjFontePagadora: any;
  parcPis: any;
  parcCofins: any;

  tCampos: any[];

  // ID do item de apuração ICMS-ST selecionado
  idE200: any;

  // Variáveis para o Modal ICMS Ajuste Benefício/Incentivo
  ajusteApurDeduEscolhido: any;
  descricaoComplementarE111: any;
  vlAjusteApuracaoE111: any;

  // Variáveis para o Modal Ajuste de Apuração de IPI
  tipoAjusteIPIEscolhido: any;
  origemDocIPIEscolhido: any;
  numeroDocumentoE530: any;
  vlAjusteE530: any;
  codAjusteApuracaoIPIEscolhido: any;
  descricaoResumidaE530: any;

  /* Variáveis que armazenam os dados do registro da tela inicial
  selecionado pelo usuário */
  tCurrentDataInicio: any;
  tCurrentDataFim: any;
  tCurrentCodigo: any;
  tCurrentRazaoSocial: any;
  tCurrentCnpj: any;
  tCurrentTipo: any;
  tCurrentStatus: any;
  tCurrentPeriodo: any;

  /* Variáveis que armazenam os dados da regra selecionada antes
  da abertura do modal correspondente. */
  tCurrentRegraModalCodigo: any;
  tCurrentRegraModalDescricao: any;
  tCurrentRegraModalNome: any;

  /* Variáveis que armazenam os dados do relatório selecionado antes
  da abertura do modal correspondente. */
  tCurrentRelatorioModalCodigo: any;
  tCurrentRelatorioModalDescricao: any;
  tCurrentRelatorioModalNome: any;

  // Variáveis usadas na alteração de valores retidos de PIS e COFINS
  vlRetidoNaoCumulativoPis: any;
  vlRetidoCumulativoPis: any;
  vlRetidoNaoCumulativoCofins: any;
  vlRetidoCumulativoCofins: any;

  //Menu Apuração
  statusPis: boolean = true;
  statusCofins: boolean = false;
  statusContribuicao: boolean = false;
  statusF100: boolean = false;
  statusF120: boolean = false;
  statusF130: boolean = false;
  pisDetalhe: boolean;
  cofinsDetalhe: boolean;

  //Menu Apuração ICMS
  apuracaoICMS: boolean = true;
  apuracaoICMSST: boolean = false;
  apuracaoIPI: boolean = false;
  icmsstDetalhe: boolean;

  //Menu Apuração Sub ICMS
  ajusteBeneficioIncentivo: boolean = true;
  saldoCredor: boolean = false;
  valorSaldoCredorIcms: any;

  //Menu Apuração Sub ICMS-ST
  apuracaoICMSSTsub: boolean = true;

  //Menu Apuração Sub IPI
  valoresConsolidados: boolean = true;
  ipiAjuste: boolean = false;
  ipiSaldoCredor: boolean = false;
  valorSaldoCredorIpi: any;

  //PIS SUB
  creditoPisPasep: boolean = true;
  contribuicaoPisPasep: boolean = false;
  receitasPisPasep: boolean = false;
  creditosPisPasep: boolean = false;
  retidosPisPasep: boolean = false;

  //PIS CRÉDITO
  statusBaseCalculoPis: boolean = true;
  statusAjusteCreditoPis: boolean = false;

  //COFINS CRÉDITO
  statusBaseCalculoCofins: boolean = true;
  statusAjusteCreditoCofins: boolean = false;

  // PIS CONTRIBUICAO
  statusDetalheContribuicaoPis = true;
  statusAjusteContribuicaoPis = false;
  statusRetidoFontePis = false;

  // COFINS CONTRIBUICAO
  statusDetalheContribuicaoCofins = true;
  statusAjusteContribuicaoCofins = false;
  statusRetidoFonteCofins = false;

  //COFINS SUB
  creditoCofins: boolean = true;
  contribuicaoCofins: boolean = false;
  receitasCofins: boolean = false;
  creditosCofins: boolean = false;
  retidosCofins: boolean = false;

  // Variáveis de referência para exibir o modal dinâmico de crédito PIS
  modalBasePisNatBcCred: any;
  modalBasePisAliquota: any;
  modalBasePisTipoCredito: any;

  // Variáveis de referência para exibir o modal dinâmico de crédito COFINS
  modalBaseCofinsNatBcCred: any;
  modalBaseCofinsAliquota: any;
  modalBaseCofinsTipoCredito: any;

  isRTL: boolean;

  contadorLinha: number = 0;

  //COLUNAS PARA p-table
  colsFilteredProcess: any[] = [];
  colstRelatorios: any[] = [];
  colstRegra: any[] = [];
  colsTCreditoPis: any[] = [];
  colsTContribuicaoPis: any[] = [];
  colsTReceitasPis: any[] = [];
  colsTControleCreditoPis: any[] = [];
  colsTControleRetidosPis: any[] = [];
  colsTCreditoCofins: any[] = [];
  colsTContribuicaoCofins: any[] = [];
  colsTReceitasCofins: any[] = [];
  colsTControleCreditoCofins: any[] = [];
  colsTControleRetidosCofins: any[] = [];
  colsTContribuicaoRetidaFonte: any[] = [];
  colsTF100: any[] = [];
  colsTF120: any[] = [];
  colsTF130: any[] = [];
  colstApuracaoICMS: any[] = [];
  colsTApuracaoICMSST: any[] = [];
  colsTApuracaoIPI: any[] = [];
  colsTApuracaoBeneficioIncentivo: any[] = [];
  colsTApuracaoIPIConsolidados: any[] = [];
  colsTApuracaoIPIAjuste: any[] = [];
  colstDetalhePis: any[] = [];
  colsdttAjustePis: any[] = [];
  colstDetalheContribuicaoPis: any[] = [];
  colstAjusteContribuicaoPis: any[] = [];
  colstDetalheCofins: any[] = [];
  colstAjusteCofins: any[] = [];
  colsTDetalheContribuicaoCofins: any[] = [];
  colstAjusteContribuicaoCofins: any[] = [];
  colstBaseCreditoPis: any[] = [];
  colstBaseContribuicaoPis: any[] = [];
  colstBaseContribuicaoCofins: any[] = [];
  colstBaseCreditoCofins: any[] = [];
  colsTApuracaoICMSSTDetalhe: any[] = [];


  private sessionSubscription: Subscription;

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { this.isRTL = true; }

  ngOnInit(): void {
    //this.toolbarEnable();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'DBL';
    this.defaultDate = new Date();
    this.DefFinalDate = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 1);
    this.DefInitDate = new Date(this.DefFinalDate.getTime() - 2592000000);
    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;

    this.colsFilteredProcess = [
      { field: 'dtIni', header: 'DATA INÍCIO' },
      { field: 'dtFim', header: 'DATA FIM' },
      { field: 'cod', header: 'CÓDIGO' },
      { field: 'razao_social', header: 'RAZÃO SOCIAL' },
      { field: 'cnpj', header: 'CNPJ' },
      { field: 'tipo', header: 'TIPO' },
      { field: 'status', header: 'STATUS' }
    ];

    this.colstRelatorios = [
      { field: 'cod', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRIÇÃO' }
    ];

    this.colstRegra = [
      { field: 'cod', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'ocorrencia', header: 'OCORRÊNCIAS' },
      { field: 'risco', header: 'GRAU DE RISCO' },
      { field: 'justificativa', header: 'JUSTIFICATIVA' }

    ];

    this.colsTCreditoPis = [
      { field: 'tipo_credito', header: 'TIPO DE CRÉDITO' },
      { field: 'vl_bc_pis', header: 'VL. BC PIS' },
      { field: 'aliq_pis', header: 'ALIQ. PIS' },
      { field: 'vl_pis', header: 'VL. PIS' },
      { field: 'vl_total_credito', header: 'VL. TOTAL CRÉDITO' },
      { field: 'vl_total_ajuste_acrescimo', header: 'VL. TOTAL AJUSTE ACRÉSCIMO' },
      { field: 'vl_total_ajuste_reducao', header: 'VL. TOTAL AJUSTE REDUÇÃO' },
      { field: 'vl_total_credito_diferido', header: 'VL. TOTAL CRÉDITO DIFERIDO' },
      { field: 'vl_total_disponivel_perido', header: 'VL. TOTAL CRÉDITO DISPONÍVEL NO PERÍODO' },
      { field: 'vl_total_disponivel_descontado', header: 'VL. TOTAL DE CREDITO DISPONIVEL DESCONTADO CONTRIBUICAO APURADA' },
      { field: 'saldo_periodo_futuro', header: 'SALDO A UTILIZAR EM PERIODOS FUTUROS' }

    ];

    this.colsTCreditoCofins = [
      { field: 'tipo_credito', header: 'TIPO DE CRÉDITO' },
      { field: 'vl_bc_cofins', header: 'VL. BC COFINS' },
      { field: 'aliq_cofins', header: 'ALIQ. COFINS' },
      { field: 'vl_cofins', header: 'VL. COFINS' },
      { field: 'vl_total_credito', header: 'VL. TOTAL CRÉDITO' },
      { field: 'vl_total_ajuste_acrescimo', header: 'VL. TOTAL AJUSTE ACRÉSCIMO' },
      { field: 'vl_total_ajuste_reducao', header: 'VL. TOTAL AJUSTE REDUÇÃO' },
      { field: 'vl_total_credito_diferido', header: 'VL. TOTAL CRÉDITO DIFERIDO' },
      { field: 'vl_total_disponivel_perido', header: 'VL. TOTAL CRÉDITO DISPONÍVEL NO PERÍODO' },
      { field: 'vl_total_disponivel_descontado', header: 'VL. TOTAL DE CREDITO DISPONIVEL DESCONTADO CONTRIBUICAO APURADA' },
      { field: 'saldo_periodo_futuro', header: 'SALDO A UTILIZAR EM PERIODOS FUTUROS' }

    ];

    this.colsTContribuicaoPis = [
      { field: 'contr_periodo', header: 'CONTRIBUIÇÃO NÃO CUMULATIVA DO PERÍODO' },
      { field: 'cred_descontado', header: 'CRÉDITO DESCONTADO APURADO NO PRÓPRIO PERIODO' },
      { field: 'valor_cred_descontado', header: 'VL. DE CREDITO DESCONTADO APURADO EM PERIODO DE APURAÇÃO ANTERIOR' },
      { field: 'vl_total_contribuicao', header: 'VL. TOTAL DA CONTRIBUICAO NÃO CUMULATIVA' },
      { field: 'vl_retido_fonte', header: 'VL. RETIDO FONTE (NÃO CUMULATIVO)' },
      { field: 'outras_deducoes', header: 'OUTRAS DEDUÇÕES NÃO CUMULATIVO' },
      { field: 'vl_contribuicao_pagar', header: 'VL. CONTRIBUIÇÃO NÃO CUMULATIVA A PAGAR' },
      { field: 'vl_total_contribuicao_periodo', header: 'VL. TOTAL CONTRIBUIÇÃO CUMULATIVA DO PERÍODO' },
      { field: 'vl_retido_fonte_cumulativo', header: 'VL. RETIDO NA FONTE (CUMULATIVO)' },
      { field: 'outras_deducoes_cumulativo', header: 'OUTRAS DEDUÇÕES CUMULATIVO' },
      { field: 'vl_contribuicao_cumulativa_pagar', header: 'VL. CONTRIBUIÇÃO CUMULATIVA A PAGAR' },
      { field: 'vl_contribuicao_total_pagar', header: 'VL. TOTAL DA CONTRIBUIÇÃO A PAGAR' },

    ];

    this.colsTContribuicaoCofins = [
      { field: 'contr_periodo', header: 'CONTRIBUIÇÃO NÃO CUMULATIVA DO PERÍODO' },
      { field: 'cred_descontado', header: 'CRÉDITO DESCONTADO APURADO NO PRÓPRIO PERIODO' },
      { field: 'valor_cred_descontado', header: 'VL. DE CREDITO DESCONTADO APURADO EM PERIODO DE APURAÇÃO ANTERIOR' },
      { field: 'vl_total_contribuicao', header: 'VL. TOTAL DA CONTRIBUICAO NÃO CUMULATIVA' },
      { field: 'vl_retido_fonte', header: 'VL. RETIDO FONTE (NÃO CUMULATIVO)' },
      { field: 'outras_deducoes', header: 'OUTRAS DEDUÇÕES NÃO CUMULATIVO' },
      { field: 'vl_contribuicao_pagar', header: 'VL. CONTRIBUIÇÃO NÃO CUMULATIVA A PAGAR' },
      { field: 'vl_total_contribuicao_periodo', header: 'VL. TOTAL CONTRIBUIÇÃO CUMULATIVA DO PERÍODO' },
      { field: 'vl_retido_fonte_cumulativo', header: 'VL. RETIDO NA FONTE (CUMULATIVO)' },
      { field: 'outras_deducoes_cumulativo', header: 'OUTRAS DEDUÇÕES CUMULATIVO' },
      { field: 'vl_contribuicao_cumulativa_pagar', header: 'VL. CONTRIBUIÇÃO CUMULATIVA A PAGAR' },
      { field: 'vl_contribuicao_total_pagar', header: 'VL. TOTAL DA CONTRIBUIÇÃO A PAGAR' },

    ];

    this.colsTReceitasPis = [
      { field: 'cst_pis', header: 'CST PIS' },
      { field: 'vl_item', header: 'VL. ITEM' },
      { field: 'vl_bc_pis', header: 'VL. BC PIS' },
      { field: 'aliq_pis', header: 'ALIQ. PIS' },
      { field: 'vl_pis', header: 'VL. PIS' }

    ];

    this.colsTReceitasCofins = [
      { field: 'cst_cofins', header: 'CST COFINS' },
      { field: 'vl_item', header: 'VL. ITEM' },
      { field: 'vl_bc_cofins', header: 'VL. BC COFINS' },
      { field: 'aliq_cofins', header: 'ALIQ. COFINS' },
      { field: 'vl_cofins', header: 'VL. COFINS' }

    ];

    this.colsTControleCreditoPis = [
      { field: 'periodo_apuracao', header: 'PERIODO AP.' },
      { field: 'origem_credito', header: 'ORIGEM DO CRÉDITO' },
      { field: 'cnpj_cedente_credito', header: 'CNPJ DO CEDENTE DO CRÉDITO' },
      { field: 'tipo_credito', header: 'TIPO DO CRÉDITO' },
      { field: 'vl_credito_periodo', header: 'VL. DO CRÉDITO APURADO NO PERÍODO' },
      { field: 'vl_credito_extemporaneo', header: 'VL. DO CRÉDITO EXTEMPORÂNEO' },
      { field: 'vl_credito_apurado', header: 'VL. TOTAL DO CRÉDITO APURADO' },
      { field: 'vl_credito_desconto', header: 'VL. DO CRÉDITO UTILIZADO MEDIANTE DESCONTO' },
      { field: 'vl_credito_ressarcimento', header: 'VL. DO CRÉDITO UTILIZADO MEDIANTE PEDIDO DE RESSARCIMENTO' },
      { field: 'vl_credito_intermediaria', header: 'VL. DO CRÉDITO UTILIZADO MEDIANTE DECLARAÇÃO DE COMPENSAÇÃO INTERMEDIÁRIA' },
      { field: 'saldo_credito_escrituracao', header: 'SALDO DO CRÉDITO DISPONÍVEL PARA UTILIZAÇÃO NESTE PERÍODO DE ESCRITURAÇÃO' },
      { field: 'vl_descontado_escrituracao', header: 'VL. DO CRÉDITO DESCONTADO NESTE PERÍODO DE ESCRITURAÇÃO' },
      { field: 'vl_descontado_ressarcimento', header: 'VL. DO CRÉDITO OBJETO DE PEDIDO DE RESSARCIMENTO NESTE PERÍODO DE ESCRITURAÇÃO' },
      { field: 'vl_compensacao_periodo', header: 'VL. DO CRÉDITO UTILIZADO MEDIANTE DECLARAÇÃO DE COMPENSAÇÃO INTERMEDIÁRIA NESTE PERÍODO' },
      { field: 'vl_credito_incorporacao', header: 'VL. DO CRÉDITO TRANSFERIDO EM EVENTO DE CISÃO, FUSÃO OU INCORPORAÇÃO' },
      { field: 'vl_credito_outra', header: 'VL. DO CRÉDITO UTILIZADO POR OUTRAS FORMAS' },
      { field: 'saldo_apuracao_futuro', header: 'SALDO DE CRÉDITOS A UTILIZAR EM PERIODO DE APURAÇÃO FUTURO' }
    ];

    this.colsTControleCreditoCofins = [
      { field: 'periodo_apuracao', header: 'PERIODO AP.' },
      { field: 'origem_credito', header: 'ORIGEM DO CRÉDITO' },
      { field: 'cnpj_cedente_credito', header: 'CNPJ DO CEDENTE DO CRÉDITO' },
      { field: 'tipo_credito', header: 'TIPO DO CRÉDITO' },
      { field: 'vl_credito_periodo', header: 'VL. DO CRÉDITO APURADO NO PERÍODO' },
      { field: 'vl_credito_extemporaneo', header: 'VL. DO CRÉDITO EXTEMPORÂNEO' },
      { field: 'vl_credito_apurado', header: 'VL. TOTAL DO CRÉDITO APURADO' },
      { field: 'vl_credito_desconto', header: 'VL. DO CRÉDITO UTILIZADO MEDIANTE DESCONTO' },
      { field: 'vl_credito_ressarcimento', header: 'VL. DO CRÉDITO UTILIZADO MEDIANTE PEDIDO DE RESSARCIMENTO' },
      { field: 'vl_credito_intermediaria', header: 'VL. DO CRÉDITO UTILIZADO MEDIANTE DECLARAÇÃO DE COMPENSAÇÃO INTERMEDIÁRIA' },
      { field: 'saldo_credito_escrituracao', header: 'SALDO DO CRÉDITO DISPONÍVEL PARA UTILIZAÇÃO NESTE PERÍODO DE ESCRITURAÇÃO' },
      { field: 'vl_descontado_escrituracao', header: 'VL. DO CRÉDITO DESCONTADO NESTE PERÍODO DE ESCRITURAÇÃO' },
      { field: 'vl_descontado_ressarcimento', header: 'VL. DO CRÉDITO OBJETO DE PEDIDO DE RESSARCIMENTO NESTE PERÍODO DE ESCRITURAÇÃO' },
      { field: 'vl_compensacao_periodo', header: 'VL. DO CRÉDITO UTILIZADO MEDIANTE DECLARAÇÃO DE COMPENSAÇÃO INTERMEDIÁRIA NESTE PERÍODO' },
      { field: 'vl_credito_incorporacao', header: 'VL. DO CRÉDITO TRANSFERIDO EM EVENTO DE CISÃO, FUSÃO OU INCORPORAÇÃO' },
      { field: 'vl_credito_outra', header: 'VL. DO CRÉDITO UTILIZADO POR OUTRAS FORMAS' },
      { field: 'saldo_apuracao_futuro', header: 'SALDO DE CRÉDITOS A UTILIZAR EM PERIODO DE APURAÇÃO FUTURO' }
    ];

    this.colsTControleRetidosPis = [
      { field: 'natureza_retencao', header: 'NATUREZA DA RETENÇÃO NA FONTE' },
      { field: 'periodo_recebimento', header: 'PERÍODO DO RECEBIMENTO E DA RETENÇÃO' },
      { field: 'vl_total_retencao', header: 'VL. TOTAL DA RETENÇÃO' },
      { field: 'vl_retencao_devida', header: 'VL DA RETENÇÃO DEDUZIDA DA CONTRIBUIÇÃO DEVIDA' },
      { field: 'vl_retencao_ressarcimento', header: 'VL. DA RETENÇÃO UTILIZADA MEDIANTE PEDIDO DE RESSARCIMENTO' },
      { field: 'vl_retencao_compensacao', header: 'VL. DA RETENÇÃO UTILIZADA MEDIANTE DECLARAÇÃO DE COMPENSAÇÃO' },
      { field: 'saldo_retencao_futuros', header: 'SALDO DE RETENÇÃO A UTILIZAR EM PERÍODOS DE APURAÇÃO FUTUROS' }
    ];

    this.colsTControleRetidosCofins = [
      { field: 'natureza_retencao', header: 'NATUREZA DA RETENÇÃO NA FONTE' },
      { field: 'periodo_recebimento', header: 'PERÍODO DO RECEBIMENTO E DA RETENÇÃO' },
      { field: 'vl_total_retencao', header: 'VL. TOTAL DA RETENÇÃO' },
      { field: 'vl_retencao_devida', header: 'VL DA RETENÇÃO DEDUZIDA DA CONTRIBUIÇÃO DEVIDA' },
      { field: 'vl_retencao_ressarcimento', header: 'VL. DA RETENÇÃO UTILIZADA MEDIANTE PEDIDO DE RESSARCIMENTO' },
      { field: 'vl_retencao_compensacao', header: 'VL. DA RETENÇÃO UTILIZADA MEDIANTE DECLARAÇÃO DE COMPENSAÇÃO' },
      { field: 'saldo_retencao_futuros', header: 'SALDO DE RETENÇÃO A UTILIZAR EM PERÍODOS DE APURAÇÃO FUTUROS' }
    ];

    this.colsTContribuicaoRetidaFonte = [
      { field: 'natureza_retencao', header: 'NATUREZA DA RETENÇÃO NA FONTE' },
      { field: 'data_retencao', header: 'DATA RETENÇÃO' },
      { field: 'vl_base_retencao', header: 'VL. BASE RETENÇÃO' },
      { field: 'vl_total_retencao', header: 'VL TOTAL RETENÇÃO' },
      { field: 'cod_receita', header: 'CÓD. RECEITA' },
      { field: 'natureza_receita', header: 'NATUREZA RECEITA' },
      { field: 'cnpj_pagadora', header: 'CNPJ PAGADORA' },
      { field: 'cond_pesso', header: 'CONDIÇÃO PESSO' },
      { field: 'parcela_pis', header: 'PARCELA PIS' },
      { field: 'parcela_cofins', header: 'PARCELA COFINS' }
    ];

    this.colsTF100 = [
      { field: 'ind_oper', header: 'IND. OPER.' },
      { field: 'cod_part', header: 'CÓD. PART.' },
      { field: 'cod_item', header: 'CÓD. ITEM' },
      { field: 'data_oper', header: 'DATA OPER.' },
      { field: 'vl_oper', header: 'VL. OPER.' },
      { field: 'cst_pis', header: 'CST PIS' },
      { field: 'vl_bc_pis', header: 'VL. BC PIS' },
      { field: 'aliq_pis', header: 'ALIQ. PIS' },
      { field: 'vl_pis', header: 'VL. PIS' },
      { field: 'cst_cofins', header: 'CST COFINS' },
      { field: 'vl_bc_cofins', header: 'VL. BC COFINS' },
      { field: 'aliq_cofins', header: 'ALIQ. COFINS' },
      { field: 'vl_cofins', header: 'VL. COFINS' },
      { field: 'nat_bc_cred', header: 'NAT. BC CRED.' },
      { field: 'ind_orig_cred', header: 'IND. ORIG. CRED.' },
      { field: 'cod_cta', header: 'CÓD. CTA.' },
      { field: 'cod_cc', header: 'CÓD. CC.' },
      { field: 'desc_doc_oper', header: 'DESC. DOC. OPER.' }
    ];

    this.colsTF120 = [
      { field: 'nat_bc_cred', header: 'NAT. BC CRED.' },
      { field: 'indent_bem_imob', header: 'INDENT. BEM IMOB.' },
      { field: 'ind_orig_cred', header: 'IND. ORIG. CRED.' },
      { field: 'ind_util_bem_imob', header: 'IND. UTIL. BEM IMOB.' },
      { field: 'vl_oper_dep', header: 'VL. OPER. DEP.' },
      { field: 'parc_oper_nao_bc_cred', header: 'PARC. OPER. NÃO BC CRED.' },
      { field: 'cst_pis', header: 'CST PIS' },
      { field: 'vl_bc_pis', header: 'VL. BC PIS' },
      { field: 'aliq_pis', header: 'ALIQ. PIS' },
      { field: 'vl_pis', header: 'VL. PIS' },
      { field: 'cst_cofins', header: 'CST COFINS' },
      { field: 'vl_bc_cofins', header: 'VL. BC COFINS' },
      { field: 'aliq_cofins', header: 'ALIQ. COFINS' },
      { field: 'vl_cofins', header: 'VL. COFINS' },
      { field: 'cod_cta', header: 'CÓD. CTA.' },
      { field: 'cod_cc', header: 'CÓD. CC.' },
      { field: 'desc_bem_imob', header: 'DESC. BEM IMOB.' }
    ];

    this.colsTF130 = [
      { field: 'nat_bc_cred', header: 'NAT. BC CRED.' },
      { field: 'indent_bem_imob', header: 'INDENT. BEM IMOB.' },
      { field: 'ind_orig_cred', header: 'IND. ORIG. CRED.' },
      { field: 'ind_util_bem_imob', header: 'IND. UTIL. BEM IMOB.' },
      { field: 'mes_oper_aquis', header: 'MÊS OPER. AQUIS.' },
      { field: 'vl_oper_aquis', header: 'VL. OPER. AQUIS.' },
      { field: 'parc_oper_nao_bc_cred', header: 'PARC. OPER. NÃO BC CRED.' },
      { field: 'vl_bc_cred', header: 'VL. BC CRED' },
      { field: 'ind_num_parc', header: 'IND. NR. PARC.' },
      { field: 'cst_pis', header: 'CST PIS' },
      { field: 'vl_bc_pis', header: 'VL. BC PIS' },
      { field: 'aliq_pis', header: 'ALIQ. PIS' },
      { field: 'vl_pis', header: 'VL. PIS' },
      { field: 'cst_cofins', header: 'CST COFINS' },
      { field: 'vl_bc_cofins', header: 'VL. BC COFINS' },
      { field: 'aliq_cofins', header: 'ALIQ. COFINS' },
      { field: 'vl_cofins', header: 'VL. COFINS' },
      { field: 'cod_cta', header: 'CÓD. CTA.' },
      { field: 'cod_cc', header: 'CÓD. CC.' },
      { field: 'desc_bem_imob', header: 'DESC. BEM IMOB.' }
    ];

    this.colstDetalheContribuicaoPis = [
      { field: 'cod_contribuicao', header: 'CÓD. CONTRIBUIÇÃO' },
      { field: 'vl_receita', header: 'VL. RECEITA' },
      { field: 'bc_pis', header: 'BC PIS' },
      { field: 'aliq_pis', header: 'ALIQ. PIS' },
      { field: 'valor_pis', header: 'VL. PIS' }
    ];

    this.colsTDetalheContribuicaoCofins = [
      { field: 'cod_contribuicao', header: 'CÓD. CONTRIBUIÇÃO' },
      { field: 'vl_receita', header: 'VL. RECEITA' },
      { field: 'bc_cofins', header: 'BC COFINS' },
      { field: 'aliq_cofins', header: 'ALIQ. COFINS' },
      { field: 'valor_cofins', header: 'VL. COFINS' }
    ];

    this.colstBaseContribuicaoPis = [
      { field: 'reg', header: 'REGRA' },
      { field: 'data', header: 'DATA' },
      { field: 'num_nf', header: 'NUM NF' },
      { field: 'cfop', header: 'CFOP' },
      { field: 'participante', header: 'PARTICIPANTE' },
      { field: 'descr_item', header: 'DESCR_ITEM' },
      { field: 'tipo', header: 'TIPO' },
      { field: 'cst_pis', header: 'CST PIS' },
      { field: 'aliq_pis', header: 'ALIQ PIS' },
      { field: 'valor_item', header: 'VL. ITEM' },
      { field: 'vl_bc_pis', header: 'VL_BC_PIS' },
      { field: 'valor_pis', header: 'VL. PIS' }
    ];

    this.colstDetalheCofins = [
      { field: 'natureza_base_credito', header: 'NATUREZA DE BASE DE CRÉDITO' },
      { field: 'cst', header: 'CST' },
      { field: 'bc_cofins', header: 'BC COFINS' },
      { field: 'bc_cofins_prop', header: 'BC COFINS PROP' },
      { field: 'aliq_cofins', header: 'ALIQ. COFINS' },
      { field: 'valor_cofins', header: 'VL. COFINS' }
    ];

    this.colstDetalhePis = [
      { field: 'natureza_base_credito', header: 'NATUREZA DE BASE DE CRÉDITO' },
      { field: 'cst', header: 'CST' },
      { field: 'bc_pis', header: 'BC PIS' },
      { field: 'bc_pis_prop', header: 'BC PIS PROP' },
      { field: 'aliq_pis', header: 'ALIQ. PIS' },
      { field: 'valor_pis', header: 'VL. PIS' }
    ];

    this.colstBaseCreditoPis = [
      { field: 'reg', header: 'REG' },
      { field: 'data', header: 'DATA' },
      { field: 'num_nf', header: 'NUM NF' },
      { field: 'cfop', header: 'CFOP' },
      { field: 'participante', header: 'PARTICIPANTE' },
      { field: 'descr_item', header: 'DESCR_ITEM' },
      { field: 'tipo', header: 'TIPO' },
      { field: 'cst_pis', header: 'CST PIS' },
      { field: 'aliq_pis', header: 'ALIQ PIS' },
      { field: 'nat_bc_cred', header: 'NAT BC CRED' },
      { field: 'valor_item', header: 'VL. ITEM' },
      { field: 'vl_bc_pis', header: 'VL BC PIS' },
      { field: 'valor_pis', header: 'VL. PIS' }
    ];

    this.colstBaseContribuicaoCofins = [
      { field: 'reg', header: 'REG' },
      { field: 'data', header: 'DATA' },
      { field: 'num_nf', header: 'NUM NF' },
      { field: 'cfop', header: 'CFOP' },
      { field: 'participante', header: 'PARTICIPANTE' },
      { field: 'descr_item', header: 'DESCR_ITEM' },
      { field: 'tipo', header: 'TIPO' },
      { field: 'cst_cofins', header: 'CST COFINS' },
      { field: 'aliq_cofins', header: 'ALIQ COFINS' },
      { field: 'valor_item', header: 'VL. ITEM' },
      { field: 'vl_bc_cofins', header: 'VL_BC_COFINS' },
      { field: 'valor_cofins', header: 'VL. COFINS' },
    ];

    this.colstAjusteContribuicaoPis = [
      { field: 'data', header: ' DATA REF' },
      { field: 'tipo_ajuste', header: ' TIPO AJUSTE' },
      { field: 'cod_ajuste', header: ' CÓD. AJUSTE' },
      { field: 'descricao', header: ' DESCRIÇÃO' },
      { field: 'num_doc', header: ' NUM_DOC' },
      { field: 'vl_ajuste', header: ' VL. AJUSTE' }
    ];

    this.colstBaseCreditoCofins = [
      { field: 'reg', header: 'REGRA' },
      { field: 'data', header: 'DATA' },
      { field: 'num_nf', header: 'NUM NF' },
      { field: 'cfop', header: 'CFOP' },
      { field: 'participante', header: 'PARTICIPANTE' },
      { field: 'descr_item', header: 'DESCR_ITEM' },
      { field: 'tipo', header: 'TIPO' },
      { field: 'cst_cofins', header: 'CST COFINS' },
      { field: 'aliq_cofins', header: 'ALIQ COFINS' },
      { field: 'nat_bc_cred', header: 'NAT BC CRED' },
      { field: 'valor_item', header: 'VL. ITEM' },
      { field: 'vl_bc_cofins', header: 'VL BC COFINS' },
      { field: 'valor_cofins', header: 'VL. COFINS' }
    ];

    this.colstAjusteContribuicaoCofins = [
      { field: 'data', header: 'DATA REF.' },
      { field: 'tipo_ajuste', header: 'TIPO AJUSTE' },
      { field: 'cod_ajuste', header: 'CÓD. AJUSTE' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'num_doc', header: 'NUM_DOC' },
      { field: 'vl_ajuste', header: 'VL. AJUSTE' }
    ];


    this.colstAjusteCofins = [
      { field: 'data', header: 'DATA REF.' },
      { field: 'tipo_ajuste', header: 'TIPO AJUSTE' },
      { field: 'cod_ajuste', header: 'CÓD. AJUSTE' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'num_doc', header: 'NUM_DOC' },
      { field: 'vl_ajuste', header: 'VL. AJUSTE' }
    ];

    this.colstBaseCreditoCofins = [
      { field: 'reg', header: 'REGRA' },
      { field: 'data', header: 'DATA' },
      { field: 'num_nf', header: 'NUM NF' },
      { field: 'cfop', header: 'CFOP' },
      { field: 'participante', header: 'PARTICIPANTE' },
      { field: 'descr_item', header: 'DESCR_ITEM' },
      { field: 'tipo', header: 'TIPO' },
      { field: 'cst_cofins', header: 'CST COFINS' },
      { field: 'aliq_cofins', header: 'ALIQ COFINS' },
      { field: 'nat_bc_cred', header: 'NAT BC CRED' },
      { field: 'valor_item', header: 'VL. ITEM' },
      { field: 'vl_bc_cofins', header: 'VL BC COFINS' },
      { field: 'valor_cofins', header: 'VL. COFINS' }
    ];

    this.colsdttAjustePis = [
      { field: 'data', header: 'DATA REF.' },
      { field: 'tipo_ajuste', header: 'TIPO AJUSTE' },
      { field: 'cod_ajuste', header: 'CÓD. AJUSTE' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'num_doc', header: 'NUM_DOC' },
      { field: 'vl_ajuste', header: 'VL. AJUSTE' }
    ];

    this.colstApuracaoICMS = [
      { field: 'vl_tot_debitos', header: 'VL. TOTAL DOS DÉBITOS DO IMPOSTO' },
      { field: 'vl_aj_debitos', header: 'VL. TOTAL AJUSTES A DÉBITO (DOC FISCAL)' },
      { field: 'vl_tot_aj_debitos', header: 'VL. TOTAL DOS AJUSTES A DÉBITO' },
      { field: 'vl_estornos_cred', header: 'VL. TOTAL DOS ESTORNOS DE CRÉDITOS' },
      { field: 'vl_tot_creditos', header: 'VL. TOTAL DOS CRÉDITOS DO IMPOSTO' },
      { field: 'vl_aj_creditos', header: 'VL. TOTAL AJUSTES A CRÉDITO (DOC FISCAL)' },
      { field: 'vl_tot_aj_creditos', header: 'VL. TOTAL DOS AJUSTES A CRÉDITO' },
      { field: 'vl_estornos_deb', header: 'VL. TOTAL DOS ESTORNOS DE CRÉDITOS' },
      { field: 'vl_sld_credor_ants', header: 'SALDO CREDOR DO PERÍODO ANTERIOR' },
      { field: 'vl_sld_apurado', header: 'VL. DO SALDO DEVEDOR' },
      { field: 'vl_tot_ded', header: 'VL. TOTAL DAS DEDUÇÕES' },
      { field: 'vl_icms_recolher', header: 'VL. TOTAL DO ICMS A RECOLHER' },
      { field: 'vl_sld_credor_transportar', header: 'VL. DO SALDO CREDOR DO ICMS' },
      { field: 'deb_exp', header: 'VL. RECOLHIDOS OU A RECOLHER, EXTRA-APURAÇÃO' }

    ];

    this.colsTApuracaoICMSST = [
      { field: 'uf', header: 'UF' },
      { field: 'dt_inicial', header: 'DATA INICIAL' },
      { field: 'dt_final', header: 'DATA FINAL' }

    ];

    this.colsTApuracaoIPI = [
      { field: 'saldo_cred_peri_ant', header: 'SALDO CREDOR DO PERÍODO ANTERIOR' },
      { field: 'vl_debitos_ipi', header: 'VL. DOS DÉBITOS DO IPI' },
      { field: 'vl_creditos_ipi', header: 'VL. DOS CRÉDITOS DO IPI' },
      { field: 'vl_outros_debitos_ipi', header: 'VL. OUTROS DÉBITOS DO IPI' },
      { field: 'vl_outros_creditos_ipi', header: 'VL. OUTROS CRÉDITOS DO IPI' },
      { field: 'vl_saldo_cred_per', header: 'VL. SALDO CREDOR NO PERÍODO' },
      { field: 'vl_saldo_devedor_recolher', header: 'VL. SALDO DEVEDOR A RECOLHER' }

    ];

    this.colsTApuracaoBeneficioIncentivo = [
      { field: 'cod_apuracao_deducao', header: 'CÓD. AJUSTE DA APURAÇÃO/DEDUÇÃO' },
      { field: 'desc_complementar', header: 'DESCRIÇÃO COMPLEMENTAR' },
      { field: 'vl_ajuste_apuracao', header: 'VL. DO AJUSTE DA APURAÇÃO' }

    ];

    this.colsTApuracaoIPIConsolidados = [
      { field: 'cfop', header: 'CFOP' },
      { field: 'cst_ipi', header: 'CST IPI' },
      { field: 'vl_contabil', header: 'VL. CONTÁBIL' },
      { field: 'bc_ipi', header: 'BC IPI' },
      { field: 'vl_ipi', header: 'VL. IPI' }

    ];

    this.colsTApuracaoIPIAjuste = [
      { field: 'tipo_ajuste', header: 'TIPO DE AJUSTE' },
      { field: 'cod_ajuste', header: 'CÓD. AJUSTE' },
      { field: 'origem_doc', header: 'ORIGEM DOCUMENTO' },
      { field: 'num_doc', header: 'Nº DOCUMENTO' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'vl_ajuste', header: 'VL. AJUSTE' }

    ];

    this.colsTApuracaoICMSSTDetalhe = [
      { field: 'indicador_movimento', header: 'INDICADOR DE MOVIMENTO' },
      { field: 'saldo_cred_peri_ant_st', header: 'SALDO CREDOR DO PERÍODO ANTERIOR - ST' },
      { field: 'vl_total_icms_st_devolucao', header: 'VL. TOTAL ICMS ST DEVOL. MERCADORIAS' },
      { field: 'vl_total_icms_st_ressarcimentos', header: 'VL. TOTAL ICMS ST - RESSARCIMENTOS' },
      { field: 'vl_total_outros_creditos_st', header: 'VL. TOTAL - OUTROS CRÉDITOS ST' },
      { field: 'vl_ajuste_icms_st_doc_fiscal', header: 'VL. AJUSTES A CRÉDITO ICMS ST (DOC FISCAL)' },
      { field: 'vl_total_retido_icms_st', header: 'VL. TOTAL DO ICMS RETIDO ST' },
      { field: 'vl_total_outros_st', header: 'VL. TOTAL - OUTROS DÉBITOS ST' },
      { field: 'vl_ajuste_debito_icms_st', header: 'VL. AJUSTES A DÉBITO ICMS ST (DOC FISCAL)' },
      { field: 'vl_saldo_devedor', header: 'VL. DO SALDO DEVEDOR ANTES DAS DEDUÇÕES' },
      { field: 'vl_total_deducoes_st', header: 'VL. TOTAL DAS DEDUÇÕES ST' },
      { field: 'vl_total_icms', header: 'VL. TOTAL DO ICMS A RECOLHER' },
      { field: 'vl_saldo_credor_icms_st', header: 'VL. DO SALDO CREDOR DO ICMS' },
      { field: 'vl_recolhidos_recolher_extra', header: 'VL. RECOLHIDOS OU A RECOLHER, EXTRA-APURAÇÃO' }

    ]

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

          this.toolbarEnable();

          let mes = this.dblinkedSessionService.periodo.month.value > 9 ?
            this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
          const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
          this.defaultDate = new Date(dt);
          // validando data inicial e final
          let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
          this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
          this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

          if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
            mes = this.dblinkedSessionService.periodo.month.value > 9 ?
              this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
            const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
            this.defaultDate = new Date(dt);
            // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
            lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
            this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
            this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();
          }

          this.atualizarPagina();
        }
      });

    this.meses = [];
    for (let mes = 1; mes <= 12; mes++) {
      this.meses.push({ label: mes, value: mes });
    }

    /*TABLES */

    this.centralEdicaoOpcoes = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '0150 - TABELA DE CADASTRO DO PARTICIPANTE', value: '0150 - TABELA DE CADASTRO DO PARTICIPANTE' },
      { label: '0200 - TABELA DE IDENTIFICAÇÃO DO ITEM (PRODUTOS E SERVIÇOS)', value: '0200 - TABELA DE IDENTIFICAÇÃO DO ITEM (PRODUTOS E SERVIÇOS)' },
      { label: 'A100 - DOCUMENTO - NOTA FISCAL DE SERVIÇO', value: 'A100 - DOCUMENTO - NOTA FISCAL DE SERVIÇO' },
      { label: 'C100 - DOCUMENTO - NOTA FISCAL', value: 'C100 - DOCUMENTO - NOTA FISCAL' },
      { label: 'C500 - NOTA FISCAL/CONTA DE ENERGIA ELÉTRICA', value: 'C500 - NOTA FISCAL/CONTA DE ENERGIA ELÉTRICA' },
      { label: 'D100 - AQUISIÇÃO DE SERVIÇOS DE TRANSPORTE', value: 'D100 - AQUISIÇÃO DE SERVIÇOS DE TRANSPORTE' },
      { label: '1100 - CONTROLE DE CRÉDITOS FISCAIS – PIS/PASEP', value: '1100 - CONTROLE DE CRÉDITOS FISCAIS – PIS/PASEP' },
      { label: '1500 - CONTROLE DE CRÉDITOS FISCAIS – COFINS', value: '1500 - CONTROLE DE CRÉDITOS FISCAIS – COFINS' },
      { label: '1300 - CONTROLE DOS VALORES RETIDOS NA FONTE – PIS/PASEP', value: '1300 - CONTROLE DOS VALORES RETIDOS NA FONTE – PIS/PASEP' },
      { label: '1700 - CONTROLE DOS VALORES RETIDOS NA FONTE – COFINS', value: '1700 - CONTROLE DOS VALORES RETIDOS NA FONTE – COFINS' },
      { label: 'C400 - EQUIPAMENTO ECF', value: 'C400 - EQUIPAMENTO ECF' },
    ];

    /*SELECTS */
    this.tipoArquivo = [
      { label: 'PIS/COFINS', value: 'PIS/COFINS' },
      { label: 'ICMS/IPI', value: 'ICMS/IPI' },
    ];

    this.statusArquivo = [
      { label: 'EM ABERTO', value: 'EM ABERTO' },
      { label: 'APURADO', value: 'APURADO' },
      { label: 'HOMOLOGADO', value: 'HOMOLOGADO' },
      { label: 'APURADO/HOMOLOGADO', value: 'APURADO/HOMOLOGADO' },
    ];

    this.statusRisco = [
      { label: 'ERRO', value: 'ERRO' },
      { label: 'ADVERTÊNCIA', value: 'ADVERTÊNCIA' },
    ];

    this.tipoAjustePis = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '0 - Ajuste de redução', value: '0 - Ajuste de redução' },
      { label: '1 - Ajuste de acréscimo', value: '1 - Ajuste de acréscimo' },
    ];

    this.codAjustePis = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '01 - Ajuste Oriundo de Ação Judicial', value: '01 - Ajuste Oriundo de Ação Judicial' },
      { label: '02 - Ajuste Oriundo de Processo Administrativo', value: '02 - Ajuste Oriundo de Processo Administrativo' },
      { label: '03 - Ajuste Oriundo da Legislação Tributária', value: '03 - Ajuste Oriundo da Legislação Tributária' },
      { label: '04 - Ajuste Oriundo Especificamente do RTT', value: '04 - Ajuste Oriundo Especificamente do RTT' },
      { label: '05 - Ajuste Oriundo de Outras Situações', value: '05 - Ajuste Oriundo de Outras Situações' },
      { label: '06 - Estorno', value: '06 - Estorno' },
      { label: '07 - Ajuste Oriundo da Legislação Tributária  Adoção do Regime de Caixa  CPRB (Lei nº 12.546/2011, art. 9º, § 12)', value: '07 - Ajuste Oriundo da Legislação Tributária  Adoção do Regime de Caixa  CPRB (Lei nº 12.546/2011, art. 9º, § 12)' },
      { label: '08 - Ajuste Oriundo da Legislação Tributária  Diferimento de Valores a Recolher no Período  CPRB (Lei nº 12.546/2011, art. 9º, § 12)', value: '08 - Ajuste Oriundo da Legislação Tributária  Diferimento de Valores a Recolher no Período  CPRB (Lei nº 12.546/2011, art. 9º, § 12)' },
      { label: '09 - Ajuste Oriundo da Legislação Tributária  Adição de Valores Diferidos em Período(s) Anterior(es)  CPRB (Lei nº 12.546/2011, art. 9º, § 12)', value: '09 - Ajuste Oriundo da Legislação Tributária  Adição de Valores Diferidos em Período(s) Anterior(es)  CPRB (Lei nº 12.546/2011, art. 9º, § 12)' },
    ];

    this.codContriSocial = [
      { label: '030', value: '030' },
      { label: '040', value: '040' },
    ];

    this.tipoCreditoPis = [
      { label: '102 - Crédito vinculado à receita tributada no mercado interno – Alíquotas Diferenciadas', value: '102 - Crédito vinculado à receita tributada no mercado interno – Alíquotas Diferenciadas' },
      { label: '101 - Crédito vinculado à receita tributada no mercado interno – Alíquota Básica', value: '101 - Crédito vinculado à receita tributada no mercado interno – Alíquota Básica' },
    ];

    this.aliqAjustePis = [
      { label: '2,10', value: '2,10' },
      { label: '1,65', value: '1,65' },
    ];

    this.naturezaRetFonte = [
      { label: 'Selecione', value: 'Selecione' },
      { label: ' 01 - Retenção por Órgãos, Autarquias e Fundações Federais', value: ' 01 - Retenção por Órgãos, Autarquias e Fundações Federais' },
      { label: ' 02 - Retenção por outras Entidades da Adm. Pública Federal', value: ' 02 - Retenção por outras Entidades da Adm. Pública Federal' },
      { label: ' 03 - Retenção por Pessoas Jurídicas de Direito Privado', value: ' 03 - Retenção por Pessoas Jurídicas de Direito Privado' },
      { label: ' 04 - Retenção por Sociedade Cooperativa', value: ' 04 - Retenção por Sociedade Cooperativa' },
      { label: ' 05 - Retenção por Fabricante de Máquinas e Veículos', value: ' 05 - Retenção por Fabricante de Máquinas e Veículos' },
      { label: ' 99 - Outras Retenções', value: ' 99 - Outras Retenções' },
    ];

    this.naturezaReceita = [
      { label: 'Selecione', value: 'Selecione' },
      { label: ' 0 - Receita de Natureza Não Cumulativa', value: ' 0 - Receita de Natureza Não Cumulativa' },
      { label: ' 1 - Receita de Natureza Cumulativa', value: ' 1 - Receita de Natureza Cumulativa' },
    ];

    this.indicadorCondJuridica = [
      { label: 'Selecione', value: 'Selecione' },
      { label: ' 0 - Beneficiária da Retenção / Recolhimento', value: ' 0 - Beneficiária da Retenção / Recolhimento' },
      { label: ' 1 - Responsável pela Retenção / Recolhimento', value: ' 1 - Responsável pela Retenção / Recolhimento' },
    ];

    this.indTipoOper = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '0 - Operação Representativa de Aquisição, Custos, Despesa ou Encargos, ou Receitas, Sujeita à Incidência de Crédito de PIS/Pasep ou Cofins (CST50 a 66).', value: '0 - Operação Representativa de Aquisição, Custos, Despesa ou Encargos, ou Receitas, Sujeita à Incidência de Crédito de PIS/Pasep ou Cofins (CST50 a 66).' },
      { label: '1 - Operação Representativa de Receita Auferida Sujeita ao Pagamentoda Contribuição para o PIS/Pasep e da Cofins (CST 01,02,03 ou 05).', value: '1 - Operação Representativa de Receita Auferida Sujeita ao Pagamentoda Contribuição para o PIS/Pasep e da Cofins (CST 01,02,03 ou 05).' },
      { label: '2 - Operação Representativa de Receita Auferida Não Sujeita ao Pagamentoda Contribuição para o PIS/Pasep e da Cofins (CST 04,06,07,08,09,49 ou 99)', value: '2 - Operação Representativa de Receita Auferida Não Sujeita ao Pagamentoda Contribuição para o PIS/Pasep e da Cofins (CST 04,06,07,08,09,49 ou 99)' },
    ];

    this.codBaseNatureza = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '01 - Aquisição de bens para revenda', value: '01 - Aquisição de bens para revenda' },
      { label: '02 - Aquisição de bens utilizados como insumo', value: 'Aquisição de bens utilizados como insumo' },
      { label: '03 - Aquisição de serviços utilizados como insumo', value: '03 - Aquisição de serviços utilizados como insumo' },
      { label: '04 - Energia elétrica e térmica, inclusive sob a forma de vapor', value: '04 - Energia elétrica e térmica, inclusive sob a forma de vapor' },
      { label: '05 - Aluguéis de prédios', value: '05 - Aluguéis de prédios' },
      { label: '06 - Aluguéis de máquinas e equipamentos', value: '06 - Aluguéis de máquinas e equipamentos' },
      { label: '07 - Armazenagem de mercadoria e frete na operação de venda', value: '07 - Armazenagem de mercadoria e frete na operação de venda' },
      { label: '08 - Contraprestações de arrendamento mercantil', value: '08 - Contraprestações de arrendamento mercantil' },
      { label: '09 - Máquinas, equipamentos e outros bens incorporados ao ativo imobilizado (crédito sobre encargos de depreciação).', value: '09 - Máquinas, equipamentos e outros bens incorporados ao ativo imobilizado (crédito sobre encargos de depreciação).' },
    ];

    this.indOrigemCredito = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '0 - Operação no Mercado Interno', value: '0 - Operação no Mercado Interno' },
      { label: '1 - Operação de Importação', value: '1 - Operação de Importação' },
    ];

    this.origemCredito = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '01 - Crédito decorrente de operações próprias', value: '01 - Crédito decorrente de operações próprias' },
      { label: '02 - Crédito transferidos por pessoa jurídica sucedida', value: '02 - Crédito transferidos por pessoa jurídica sucedida' },
    ];

    this.TipoAjusteIPI = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '0 - Ajuste a débito', value: '0 - Ajuste a débito' },
      { label: '1 - Ajuste a crédito', value: '1 - Ajuste a crédito' },
    ];

    this.OrigemDocIPI = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '0 - Processo Judicial', value: '0 - Processo Judicial' },
      { label: '1 - Processo Administrativo', value: '1 - Processo Administrativo' },
      { label: '2 - PER/DCOMP', value: '2 - PER/DCOMP' },
      { label: '9 - Outros', value: '9 - Outros' },
    ];

    this.naturezaBaseCredito = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '09 - Crédito com Base nos Encargos de Depreciação', value: '09 - Crédito com Base nos Encargos de Depreciação' },
      { label: '11 - Crédito com Base nos Encargos de Amortização', value: '11 - Crédito com Base nos Encargos de Amortização' },
    ];

    this.identBensGrupo = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '01 - Edificações e Benfeitorias em Imóveis Próprios', value: '01 - Edificações e Benfeitorias em Imóveis Próprios' },
      { label: '02 - Edificações e Benfeitorias em Imóveis de Terceiros', value: '02 - Edificações e Benfeitorias em Imóveis de Terceiros' },
      { label: '03 - Instalações', value: '03 - Instalações' },
      { label: '04 - Máquinas', value: '04 - Máquinas' },
      { label: '05 - Equipamentos', value: '05 - Equipamentos' },
      { label: '06 - Veículos', value: '06 - Veículos' },
      { label: '99 - Outros', value: '99 - Outros' },
    ];

    this.indOrigemBem = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '0 – Aquisição no Mercado Interno', value: '0 – Aquisição no Mercado Interno' },
      { label: '1 – Aquisição no Mercado Externo', value: '1 – Aquisição no Mercado Externo' },
    ];

    this.indUtiliBens = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '1 – Produção de Bens Destinados a Venda', value: '1 – Produção de Bens Destinados a Venda' },
      { label: '2 – Prestação de Serviços', value: '2 – Prestação de Serviços' },
      { label: '3 – Locação a Terceiros', value: '3 – Locação a Terceiros' },
      { label: '9 – Outros', value: '9 – Outros' },
    ];

    this.indNumParc = [
      { label: 'Selecione', value: 'Selecione' },
      { label: '1 – Integral (Mês de Aquisição)', value: '1 – Integral (Mês de Aquisição)' },
      { label: '2 – 12 Meses', value: '2 – 12 Meses' },
      { label: '3 – 24 Meses', value: '3 – 24 Meses' },
      { label: '4 – 48 Meses', value: '4 – 48 Meses' },
      { label: '5 – 6 Meses (Embalagens de bebidas frias)', value: '5 – 6 Meses (Embalagens de bebidas frias)' },
      { label: '9 – Outra periodicidade definida em Lei', value: '9 – Outra periodicidade definida em Lei' },
    ];

    /*HIDE SIDEBAR FULLSCREEN (DO NOT REMOVE)*/
    $(document).ready(() => {
      $(".btn-full").click(
        function () {
          $(".mat-drawer-side").toggleClass('d-none');
        }
      );

      $(".close-full").click(
        function () {
          $(".mat-drawer-side").removeClass('d-none');
        }
      );

      /*OVERFLOW TABS DBL*/
      $("#tabcontent div").hide();
      $("#tabcontent div:eq('0')").show();
      $("ul#tabs li:eq('0')").addClass("active-item");
      $("ul#tabs2 li:eq('0')").addClass("active-item-2");
      $("ul#tabs3 li:eq('0')").addClass("active-item-3");

      $("ul#tabs li").click(function () {
        var i = $(this).index();
        $("#tabcontent div:not(:eq(" + i + "))").hide();
        $("#tabcontent div:eq(" + i + ")").fadeIn("slow", "linear");
        centerLI(this, 'ul#tabs');
        $("ul#tabs li").each(function () {
          $(this).removeClass('active-item');
        });
        $(this).addClass("active-item");

      });

      $("ul#tabs2 li").click(function () {
        var i = $(this).index();
        $("#tabcontent div:not(:eq(" + i + "))").hide();
        $("#tabcontent div:eq(" + i + ")").fadeIn("slow", "linear");
        centerLI(this, 'ul#tabs2');
        $("ul#tabs2 li").each(function () {
          $(this).removeClass('active-item-2');
        });
        $(this).addClass("active-item-2");

      });

      $("ul#tabs3 li").click(function () {
        var i = $(this).index();
        $("#tabcontent div:not(:eq(" + i + "))").hide();
        $("#tabcontent div:eq(" + i + ")").fadeIn("slow", "linear");
        centerLI(this, 'ul#tabs3');
        $("ul#tabs3 li").each(function () {
          $(this).removeClass('active-item-3');
        });
        $(this).addClass("active-item-3");

      });

      $(".arrow").click(function () {
        $("ul#tabs").scrollLeft(0);
      });
      $(".arrow2").click(function () {
        $("ul#tabs2").scrollLeft(0);
      });
      $(".arrow3").click(function () {
        $("ul#tabs3").scrollLeft(0);
      });
      //http://stackoverflow.com/a/33296765/350421
      function centerLI(target, outer) {
        var out = $(outer);
        var tar = $(target);
        var x = out.width() - 50;
        var y = tar.outerWidth(true);
        var z = tar.index();
        var q = 0;
        var m = out.find('li');
        for (var i = 0; i < z; i++) {
          q += $(m[i]).outerWidth(true);
        }
        //out.scrollLeft(Math.max(0, q - (x - y)/2));
        out.animate({
          scrollLeft: Math.max(0, q - (x - y) / 2)
        }, 500);
      }
    });
    // this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    // Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    // this.titleService.title = 'Dashboard Financeiro';
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  /*ATUALIZAR PÁGINA */
  atualizarPagina() {
    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() > 9 ?
        (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() > 9 ?
        (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 9) ?
        this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
        this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }

    this.filtroStatus = ["EM ABERTO", "APURADO", "HOMOLOGADO", "APURADO/HOMOLOGADO"];
    this.filtroTipo = ["PIS/COFINS", "ICMS/IPI"];

    // Carregar os dados de PIS/COFINS e ICMS/IPI na tela inicial do projeto DBL
    this.loadDados(false);

  }

  //On Filter Table
  onFilter(event: any) {

  }

  filtrarDados() {
    this.tFilteredProcess = [];
    this.tProcess.forEach(element => {
      if (this.filtroStatus.includes(element.status.toUpperCase())
        && this.filtroTipo.includes(element.tipo.toUpperCase())) {
        this.tFilteredProcess.push(element);
      }
    });
    console.log(this.tFilteredProcess);
  }

  formatarDataExtenso() {
    const dt = new Date();

    const month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const week = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

    const semana = week[dt.getDay()];
    const mes = month[dt.getMonth()];

    return 'Hoje é ' + semana + ', ' + dt.getDate() + ' de ' + mes + ' de ' + dt.getFullYear() + '.';

  }

  get isLstRTL() {
    return document.documentElement.getAttribute('dir') === 'rtl' ||
      document.body.getAttribute('dir') === 'rtl';
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  toolbarEnable() {
    console.log('barra');
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('add');
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('remove');

    // this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Opções');
    // this.toolbarService.replace('voltar', false, 'class-first-button', 'undo', 'Voltar para lista', () => this.processadoDetalhe=false);
    // this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');

  }

  /*Show Hide Processados*/
  isCardProcessados: boolean = true;
  ShowHIdeCardProcessados() {
    this.isCardProcessados = !this.isCardProcessados;
  }

  /*Show Hide Processados Detalhe*/
  isCardProcessadosDetalhe: boolean = true;
  ShowHIdeCardProcessadosDetalhe() {
    this.isCardProcessadosDetalhe = !this.isCardProcessadosDetalhe;
  }

  /*Show Hide Processados Detalhe Regra/Relatório*/
  isCardProcessadosDetalheRegraRelatorio: boolean = true;
  ShowHIdeCardProcessadosDetalheRegraRelatorio() {
    this.isCardProcessadosDetalheRegraRelatorio = !this.isCardProcessadosDetalheRegraRelatorio;
  }


  /* Função que obtém os dados de regras e relatórios relacionados
  a um registro selecionado */
  showProcessadoDetalhe(event: any) {

    // Atualizar as variáveis que armazenam os dados do registro selecionado
    this.tCurrentDataInicio = event.dtIni;
    this.tCurrentDataFim = event.dtFim;
    this.tCurrentCodigo = event.cod;
    this.tCurrentRazaoSocial = event.razao_social;
    this.tCurrentCnpj = event.cnpj;
    this.tCurrentTipo = event.tipo;
    this.tCurrentStatus = event.status;
    this.tCurrentPeriodo = event.periodo;

    this.tIdReg0Selecionado = event.id;

    console.log("this.tIdReg0Selecionado = " + this.tIdReg0Selecionado);

    this.processadoDetalhe = true;

    this.tCampos = [];
    this.tCurrentRegraModalCodigo = undefined;
    this.tCurrentRegraModalDescricao = undefined;
    this.tCurrentRelatorioModalCodigo = undefined;
    this.tCurrentRelatorioModalDescricao = undefined;
    this.getRegrasRelacionadas(event);
    this.getRelatoriosRelacionados(event);

  }

  getRegrasRelacionadas(event: any) {

    console.log(event);
    this.tRegras = [];
    this.tRegrasDetalhe = [];

    let dadosRetornados = [];

    var codigoTipoDado;

    if (this.tCurrentTipo == "PIS/COFINS") {
      codigoTipoDado = 5;
    } else if (this.tCurrentTipo == "ICMS/IPI") {
      codigoTipoDado = 4;
    }

    this.httpService.wait();
    this.httpService.get('/custom/dbl/analisarregra-new',
      [event.cnpj.toString(), event.periodo.toString(), this.sessionService.loggedUser.id,
        codigoTipoDado])
      .subscribe(
        res => {
          console.log("Busca de regras relacionadas efetuada com sucesso!");
          console.log(res);
          for (let line of res) {
            dadosRetornados.push({
              "cod": line["cod"], "descricao": line["descricao"], "ocorrencia": line["count"], "risco": line["grau"], "justificativa": line["nome"],
              "regraId": line["regraId"], "nome": line["nome"]
            });
          }
          this.tRegras = dadosRetornados;

          if (this.tRegras !== null || this.tRegras !== undefined) {
            this.contadorLinha = this.tRegras.length;
          } else {
            this.contadorLinha = 0;
          }

          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getRelatoriosRelacionados(event: any) {

    console.log(event);
    this.tRelatorios = [];
    this.tRelatoriosDetalhe = [];

    let dadosRetornados = [];

    var codigoTipoDado;

    if (this.tCurrentTipo == "PIS/COFINS") {
      codigoTipoDado = 5;
    } else if (this.tCurrentTipo == "ICMS/IPI") {
      codigoTipoDado = 4;
    }

    this.httpService.wait();
    this.httpService.get('/custom/dbl/analisarrelatorio-new',
      [event.cnpj.toString(), event.periodo.toString(), this.sessionService.loggedUser.id,
        codigoTipoDado])
      .subscribe(
        res => {
          console.log("Busca de relatórios relacionados efetuada com sucesso!");
          console.log(res);
          for (let line of res) {
            dadosRetornados.push({
              "cod": line["cod"], "descricao": line["descricao"],
              "regraId": line["regraId"], "nome": line["nome"]
            });
          }
          this.tRelatorios = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Verificar se a apuração a ser feita envolve dados
  de PIS/COFINS ou de ICMS/IPI */
  showGerarApuracao() {
    if (this.tCurrentTipo == "PIS/COFINS") {
      this.showGerarApuracaoPisCofins();
    } else {
      this.showGerarApuracaoICMSIPI();
    }
  }

  /*CARD GERAL GERAR APROVACAO PIS/COFINS */
  showGerarApuracaoPisCofins() {
    this.gerarApuracao = !this.gerarApuracao;
    this.pisDetalhe = false;
    this.cofinsDetalhe = false;

    // Obter os dados de Crédito de PIS/PASEP
    this.getCreditoPisPasep();

    // Obter os dados de Contribuição de PIS/PASEP
    this.getContribuicaoPisPasep();

    // Obter os dados de Receitas Isentas de PIS/PASEP
    this.getReceitasIsentasPisPasep();

    // Obter os dados de Controle de Créditos Fiscais para PIS/PASEP
    this.getControleCreditosPisPasep();

    // Obter os dados de Controle dos Valores retidos na Fonte para PIS/PASEP
    this.getControleValoresFontePisPasep();

    // Obter os dados de Crédito de COFINS
    this.getCreditoCofins();

    // Obter os dados de Contribuição de COFINS
    this.getContribuicaoCofins();

    // Obter os dados de Receitas Isentas de COFINS
    this.getReceitasIsentasCofins();

    // Obter os dados de Controle de Créditos Fiscais para COFINS
    this.getControleCreditoCofins();

    // Obter os dados de Controle dos Valores retidos na Fonte para COFINS
    this.getControleValoresFonteCofins();

    // Obter os dados referentes à tabela RegF100PisCofins
    this.getDadosF100();

    // Obter os dados referentes à tabela RegF120PisCofins
    this.getDadosF120();

    // Obter os dados referentes à tabela RegF130PisCofins
    this.getDadosF130();

    // Obter os dados referentes à tabela RegF600PisCofins
    this.getContribuicoesRetidasFonte();

  }

  /*CARD GERAL GERAR APROVACAO ICMS/IPI */
  showGerarApuracaoICMSIPI() {
    this.gerarApuracaoIcmsIpi = !this.gerarApuracaoIcmsIpi;
    this.icmsstDetalhe = false;

    this.getApuracaoICMS();
    this.getApuracaoIPI();
    this.getApuracaoICMSST();
    this.getApuracaoBeneficioIncentivo();
    this.getValoresConsolidadosIPI();
    this.getApuracaoIPIAjuste();
  }


  /* Função que retorna os dados da tabela M100PisCofins (Crédito PIS/PASEP)
  relacionados a um período e a um CNPJ */
  getCreditoPisPasep() {

    console.log("Entra na função de obter crédito de PIS/PASEP");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-credito-pis-pasep', dados)
      .subscribe(
        res => {
          console.log("Busca de dados de crédito PIS/PASEP efetuada com sucesso!");
          console.log(res);
          for (let line of res) {
            dadosRetornados.push({
              "id": line["id"], "tipo_credito": line["tipoCredito"],
              "vl_bc_pis": line["vlBcPis"], "aliq_pis": line["aliqPis"], "vl_pis": line["vlPis"], "vl_total_credito": line["vlTotalCredito"], "vl_total_ajuste_acrescimo": line["vlAjusAcres"],
              "vl_total_ajuste_reducao": line["vlAjusReduc"], "vl_total_credito_diferido": "0.00", "vlCredDif": line["vlCredDif"],
              "vl_total_disponivel_periodo": line["vlCredDisp"], "vl_total_disponivel_descontado": line["vlCredDesc"], "saldo_periodo_futuro": line["sldCred"],
              "acao": ""
            });
          }
          this.tCreditoPis = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }

  /* Função que retorna os dados da tabela M500PisCofins (Crédito COFINS)
  relacionados a um período e a um CNPJ */
  getCreditoCofins() {

    console.log("Entra na função de obter crédito de COFINS");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-credito-cofins', dados)
      .subscribe(
        res => {
          console.log("Busca de dados de crédito COFINS efetuada com sucesso!");
          console.log(res);
          for (let line of res) {
            dadosRetornados.push({
              "id": line["id"], "tipo_credito": line["tipoCredito"],
              "vl_bc_cofins": line["vlBcCofins"], "aliq_cofins": line["aliqCofins"], "vl_cofins": line["vlCofins"], "vl_total_credito": line["vlTotalCredito"], "vl_total_ajuste_acrescimo": line["vlAjusAcres"],
              "vl_total_ajuste_reducao": line["vlAjusReduc"], "vl_total_credito_diferido": "0.00", "vlCredDif": line["vlCredDif"],
              "vl_total_disponivel_periodo": line["vlCredDisp"], "vl_total_disponivel_descontado": line["vlCredDesc"], "saldo_periodo_futuro": line["sldCred"],
              "acao": ""
            });
          }
          this.tCreditoCofins = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }


  /* Função que retorna os dados da tabela M200PisCofins (Contribuição PIS/PASEP)
  relacionados a um período e a um CNPJ */
  getContribuicaoPisPasep() {

    console.log("Entra na função de obter contribuição de PIS/PASEP");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-contribuicao-pis-pasep', dados)
      .subscribe(
        res => {
          console.log("Busca de dados de contribuição PIS/PASEP efetuada com sucesso!");
          for (let line of res) {
            dadosRetornados.push({
              "id": line["id"], "contr_periodo": line["vl_tot_cont_nc_per"],
              "cred_descontado": line["vl_tot_cred_desc"], "valor_cred_descontado": line["tot_cred_desc_ant"], "vl_total_contribuicao": line["vl_tot_cont_nc_dev"], "vl_retido_fonte": line["vl_ret_nc"], "outras_deducoes": line["vl_out_ded_nc"],
              "vl_contribuicao_pagar": line["vl_cont_nc_rec"], "vl_total_contribuicao_periodo": line["vl_tot_cont_cum_per"], "vl_retido_fonte_cumulativo": line["vl_ret_cum"],
              "outras_deducoes_cumulativo": line["vl_out_ded_cum"], "vl_contribuicao_cumulativa_pagar": line["vl_cont_cum_rec"], "vl_contribuicao_total_pagar": line["vl_tot_cont_rec"],
              "acao": ""
            });
          }
          this.tContribuicaoPis = dadosRetornados;
          this.tCurrentM200Id = dadosRetornados[0].id;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }

  /* Função que retorna os dados da tabela M600PisCofins (Contribuição COFINS)
  relacionados a um período e a um CNPJ */
  getContribuicaoCofins() {

    console.log("Entra na função de obter contribuição de COFINS");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-contribuicao-cofins', dados)
      .subscribe(
        res => {
          console.log("Busca de dados de contribuição COFINS efetuada com sucesso!");
          for (let line of res) {
            dadosRetornados.push({
              "id": line["id"], "contr_periodo": line["vl_tot_cont_nc_per"],
              "cred_descontado": line["vl_tot_cred_desc"], "valor_cred_descontado": line["tot_cred_desc_ant"], "vl_total_contribuicao": line["vl_tot_cont_nc_dev"], "vl_retido_fonte": line["vl_ret_nc"], "outras_deducoes": line["vl_out_ded_nc"],
              "vl_contribuicao_pagar": line["vl_cont_nc_rec"], "vl_total_contribuicao_periodo": line["vl_tot_cont_cum_per"], "vl_retido_fonte_cumulativo": line["vl_ret_cum"],
              "outras_deducoes_cumulativo": line["vl_out_ded_cum"], "vl_contribuicao_cumulativa_pagar": line["vl_cont_cum_rec"], "vl_contribuicao_total_pagar": line["vl_tot_cont_rec"],
              "acao": ""
            });
          }
          this.tContribuicaoCofins = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }

  /* Função que retorna os dados da view detal_deb_piscofins
  das receitas isentas de PIS/PASEP
 relacionadas a um período e a um CNPJ */
  getReceitasIsentasPisPasep() {

    console.log("Entra na função de obter receitas isentas de PIS/PASEP");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-receitas-isentas-pis-pasep', dados)
      .subscribe(
        res => {
          console.log("Busca de dados de receitas isentas de PIS/PASEP efetuada com sucesso!");
          console.log(res);
          for (let line of res) {
            dadosRetornados.push({
              "cst_pis": line["cst"], "vl_item": line["vlItem"],
              "vl_bc_pis": line["vlBc"], "aliq_pis": line["aliq"], "vl_pis": line["vl"]
            });
          }
          this.tReceitasPis = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }

  /* Função que retorna os dados da view detal_deb_piscofins
  das receitas isentas de COFINS
 relacionadas a um período e a um CNPJ */
  getReceitasIsentasCofins() {

    console.log("Entra na função de obter receitas isentas de COFINS");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-receitas-isentas-cofins', dados)
      .subscribe(
        res => {
          console.log("Busca de dados de receitas isentas de COFINS efetuada com sucesso!");
          console.log(res);
          for (let line of res) {
            dadosRetornados.push({
              "cst_cofins": line["cst"], "vl_item": line["vlItem"],
              "vl_bc_cofins": line["vlBc"], "aliq_cofins": line["aliq"], "vl_cofins": line["vl"]
            });
          }
          this.tReceitasCofins = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }

  /* Função que retorna os dados da tabela reg_1100_piscofins
  relacionadas a um período e a um CNPJ */
  getControleCreditosPisPasep() {

    console.log("Entra na função de obter controle de créditos fiscais de PIS/PASEP");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-controle-creditos-pis-pasep', dados)
      .subscribe(
        res => {
          console.log("Busca de controle de créditos fiscais para PIS/PASEP efetuada com sucesso!");
          for (let line of res) {
            dadosRetornados.push({
              "periodo_apuracao": line["per_apu_cred"], "origem_credito": line["orig_cred"],
              "cnpj_cedente_credito": line["cnpj_suc"], "tipo_credito": line["tipo_credito"], "vl_credito_periodo": line["vl_cred_apu"],
              "vl_credito_extemporaneo": line["vl_cred_ext_apu"], "vl_credito_apurado": line["vl_tot_cred_apu"], "vl_credito_desconto": line["vl_cred_desc_pa_ant"],
              "vl_credito_ressarcimento": line["vl_cred_per_pa_ant"], "vl_credito_intermediaria": line["vl_cred_dcomp_pa_ant"],
              "saldo_credito_escrituracao": line["sd_cred_disp_efd"], "vl_descontado_escrituracao": line["vl_cred_desc_efd"], "vl_descontado_ressarcimento": line["vl_cred_per_efd"],
              "vl_compensacao_periodo": line["vl_cred_dcomp_efd"], "vl_credito_incorporacao": line["vl_cred_trans"], "vl_credito_outras": line["vl_cred_out"],
              "saldo_apuracao_futuro": line["sld_cred_fim"], "id": line["id"], "acao": ""
            });
          }
          this.tControleCreditoPis = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }

  /* Função que retorna os dados da tabela reg_1500_piscofins
  relacionadas a um período e a um CNPJ */
  getControleCreditoCofins() {

    console.log("Entra na função de obter controle de créditos fiscais de COFINS");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-controle-creditos-cofins', dados)
      .subscribe(
        res => {
          console.log("Busca de controle de créditos fiscais para COFINS efetuada com sucesso!");
          for (let line of res) {
            dadosRetornados.push({
              "periodo_apuracao": line["per_apu_cred"], "origem_credito": line["orig_cred"],
              "cnpj_cedente_credito": line["cnpj_suc"], "tipo_credito": line["tipo_credito"], "vl_credito_periodo": line["vl_cred_apu"],
              "vl_credito_extemporaneo": line["vl_cred_ext_apu"], "vl_credito_apurado": line["vl_tot_cred_apu"], "vl_credito_desconto": line["vl_cred_desc_pa_ant"],
              "vl_credito_ressarcimento": line["vl_cred_per_pa_ant"], "vl_credito_intermediaria": line["vl_cred_dcomp_pa_ant"],
              "saldo_credito_escrituracao": line["sd_cred_disp_efd"], "vl_descontado_escrituracao": line["vl_cred_desc_efd"], "vl_descontado_ressarcimento": line["vl_cred_per_efd"],
              "vl_compensacao_periodo": line["vl_cred_dcomp_efd"], "vl_credito_incorporacao": line["vl_cred_trans"], "vl_credito_outras": line["vl_cred_out"],
              "saldo_apuracao_futuro": line["sld_cred_fim"], "id": line["id"], "acao": ""
            });
          }
          this.tControleCreditoCofins = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }

  /* Função que retorna os dados da tabela reg_1300_piscofins
  relacionadas a um período e a um CNPJ */
  getControleValoresFontePisPasep() {

    console.log("Entra na função de obter controle de valores retidos na fonte de PIS/PASEP");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-controle-valores-fonte-pis-pasep', dados)
      .subscribe(
        res => {
          console.log("Busca de controle de valores retidos na fonte para PIS/PASEP efetuada com sucesso!");
          for (let line of res) {
            dadosRetornados.push({
              "natureza_retencao": line["natureza_retencao_fonte"], "periodo_recebimento": line["pr_rec_ret"],
              "vl_total_retencao": line["vl_ret_apu"], "vl_retencao_devida": line["vl_ret_ded"], "vl_retencao_ressarcimento": line["vl_ret_per"], "vl_retencao_compensacao": line["vl_ret_dcomp"],
              "saldo_retencao_futuros": line["sld_ret"], "id": line["id"], "acao": ""
            });
          }
          this.tControleRetidosPis = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }

  /* Função que retorna os dados da tabela reg_1700_piscofins
  relacionadas a um período e a um CNPJ */
  getControleValoresFonteCofins() {

    console.log("Entra na função de obter controle de valores retidos na fonte de COFINS");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-controle-valores-fonte-cofins', dados)
      .subscribe(
        res => {
          console.log("Busca de controle de valores retidos na fonte para COFINS efetuada com sucesso!");
          for (let line of res) {
            dadosRetornados.push({
              "natureza_retencao": line["natureza_retencao_fonte"], "periodo_recebimento": line["pr_rec_ret"],
              "vl_total_retencao": line["vl_ret_apu"], "vl_retencao_devida": line["vl_ret_ded"], "vl_retencao_ressarcimento": line["vl_ret_per"], "vl_retencao_compensacao": line["vl_ret_dcomp"],
              "saldo_retencao_futuros": line["sld_ret"], "id": line["id"], "acao": ""
            });
          }
          this.tControleRetidosCofins = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }

  /* Função que retorna os dados da tabela reg_f100_piscofins
  relacionadas a um período e a um CNPJ */
  getDadosF100() {

    console.log("Entra na função de obter dados da tabela RegF100PisCofins");
    var dtOper;

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-dados-f100', dados)
      .subscribe(
        res => {
          console.log("Busca de dados para a tabela RegF100PisCofins efetuada com sucesso!");
          for (let line of res) {

            var aux = line["dtOper"];
            if (aux != null) {
              var formattedData = new Date(parseFloat(aux));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              dtOper = day + '/' + month + '/' + year;
            } else {
              dtOper = null;
            }

            dadosRetornados.push({
              "id": line["id"], "ind_oper": line["indOper"], "cod_part": line["codPart"], "cod_item": line["codItem"],
              "data_oper": dtOper, "vl_oper": line["vlOper"], "cst_pis": line["cstPis"], "vl_bc_pis": line["vlBcPis"],
              "aliq_pis": line["aliqPis"], "vl_pis": line["vlPis"], "cst_cofins": line["cstCofins"], "vl_bc_cofins": line["vlBcCofins"],
              "aliq_cofins": line["aliqCofins"], "vl_cofins": line["vlCofins"], "nat_bc_cred": line["natBcCred"], "ind_orig_cred": line["indOrigCred"],
              "cod_cta": line["codCta"], "cod_cc": line["codCcus"], "desc_doc_oper": line["descDocOper"], "acao": ""
            });
          }
          this.tF100 = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }

  /* Função que retorna os dados da tabela reg_f120_piscofins
  relacionadas a um período e a um CNPJ */
  getDadosF120() {

    console.log("Entra na função de obter dados da tabela RegF120PisCofins");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-dados-f120', dados)
      .subscribe(
        res => {
          console.log("Busca de dados para a tabela RegF120PisCofins efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "id": line["id"], "nat_bc_cred": line["natBcCred"], "indent_bem_imob": line["identBemImob"], "ind_orig_cred": line["indOrigCred"], "ind_util_bem_imob": line["indUtilBemImob"],
              "vl_oper_dep": line["vlOperDep"], "parc_oper_nao_bc_cred": line["parcOperNaoBcCred"], "cst_pis": line["cstPis"], "vl_bc_pis": line["vlBcPis"], "aliq_pis": line["aliqPis"], "vl_pis": line["vlPis"],
              "cst_cofins": line["cstCofins"], "vl_bc_cofins": line["vlBcCofins"],
              "aliq_cofins": line["aliqCofins"], "vl_cofins": line["vlCofins"], "cod_cta": line["codCta"], "cod_cc": line["codCcus"], "desc_bem_imob": line["descBemImob"], "acao": ""
            });
          }
          this.tF120 = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }


  /* Função que retorna os dados da tabela reg_f130_piscofins
  relacionadas a um período e a um CNPJ */
  getDadosF130() {

    this.codNaturezaBaseCreditoF130 = "10";

    console.log("Entra na função de obter dados da tabela RegF130PisCofins");

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-dados-f130', dados)
      .subscribe(
        res => {
          console.log("Busca de dados para a tabela RegF130PisCofins efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "id": line["id"], "nat_bc_cred": line["natBcCred"], "indent_bem_imob": line["identBemImob"], "ind_orig_cred": line["indOrigCred"], "ind_util_bem_imob": line["indUtilBemImob"],
              "mes_oper_aquis": line["mesOperAquis"], "vl_oper_aquis": line["vlOperAquis"], "parc_oper_nao_bc_cred": line["parcOperNaoBcCred"], "vl_bc_cred": line["vlBcCred"], "ind_num_parc": line["indNarcParc"],
              "cst_pis": line["cstPis"], "vl_bc_pis": line["vlBcPis"], "aliq_pis": line["aliqPis"], "vl_pis": line["vlPis"], "cst_cofins": line["cstCofins"], "vl_bc_cofins": line["vlBcCofins"],
              "aliq_cofins": line["aliqCofins"], "vl_cofins": line["vlCofins"], "cod_cta": line["codCta"], "cod_cc": line["codCcus"], "desc_bem_imob": line["descBemImob"], "acao": ""
            });
          }
          this.tF130 = dadosRetornados;
          console.log(this.tF130);
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }


  /* Função que retorna os dados da tabela reg_f600_piscofins
  relacionadas a um período e a um CNPJ */
  getContribuicoesRetidasFonte() {

    console.log("Entra na função de obter contribuições retidas na fonte");
    var dtRet;

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-contribuicoes-retidas-fonte', dados)
      .subscribe(
        res => {
          console.log("Busca de contribuições retidas na fonte efetuada com sucesso!");
          console.log(res);
          for (let line of res) {

            var aux = line["dtRet"];
            if (aux != null) {
              var formattedData = new Date(parseFloat(aux));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              dtRet = day + '/' + month + '/' + year;
            } else {
              dtRet = null;
            }

            dadosRetornados.push({
              "natureza_retencao": line["indNatRet"], "data_retencao": dtRet,
              "vl_base_retencao": line["vlBcRet"], "vl_total_retencao": line["vlRet"], "cod_receita": line["codRec"],
              "natureza_receita": line["indNatRec"], "cnpj_pagadora": line["cnpj"], "cond_pesso": line["indDec"], "parcela_pis": line["vlRetPis"],
              "parcela_cofins": line["vlRetCofins"], "id": line["id"], "acao": ""
            });
          }
          this.tContribuicaoRetidaFonte = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }


  /*CARD PIS DETALHE */
  showPisDetalhe(event: any) {

    console.log(event);

    this.pisDetalhe = true;
    this.cofinsDetalhe = false;

    this.idCreditoPis = event.id;
    this.aliqAjustePisM100 = event.aliq_pis;
    this.tipoCreditoPisM100 = event.tipo_credito;
    this.modalBasePisTipoCredito = event.tipo_credito;

    this.getDetalhesCreditoPisPasep(event);
    this.getAjusteCreditoPisPasep(event);

  }

  /*CARD PIS CONTRIBUIÇÕES DETALHE */
  showPisContribuicaoDetalhe(event: any) {
    this.pisDetalhe = true;
    this.cofinsDetalhe = false;

    this.idM200 = event.id;

    this.getDetalhesContribuicaoPisPasep(event);

    this.getAjusteContribuicaoPisPasep(event);
  }

  /* Função que obtém os ajustes de crédito para um registro de PIS/PASEP */
  getAjusteContribuicaoPisPasep(event: any) {

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      idM200: event.id,
    }

    var dtReferencia: any;
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-ajuste-contribuicao-pis', dados)
      .subscribe(
        res => {
          console.log(res);
          console.log("Busca de ajustes de contribuição para PIS/PASEP efetuada com sucesso!");
          for (let line of res) {

            var aux = line["dtReferencia"];
            if (aux != null) {
              var formattedData = new Date(parseFloat(aux));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              dtReferencia = day + '/' + month + '/' + year;
            } else {
              dtReferencia = null;
            }

            dadosRetornados.push({
              "data": dtReferencia, "tipo_ajuste": line["indAj"],
              "cod_ajuste": line["codAj"], "descricao": line["descricao"], "num_doc": line["numDoc"],
              "vl_ajuste": line["vlAj"], "id": line["id"], "acao": ""
            });
          }
          this.tAjusteContribuicaoPis = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }



  /* Função que obtém os detalhes de crédito para um registro de PIS/PASEP */
  getDetalhesCreditoPisPasep(event: any) {

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      idM100: event.id,
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-detalhes-credito-pis', dados)
      .subscribe(
        res => {
          console.log("Busca de detalhes de crédito para PIS/PASEP efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "natureza_base_credito": line["natBcCred"], "cst": line["cst"], "bc_pis": line["bcPis"], "bc_pis_prop": line["bcPisProp"],
              "aliq_pis": line["aliqPis"], "valor_pis": line["valorPis"], "acao": ""
            });
          }
          this.tDetalhePis = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os detalhes de contribuição para um registro de PIS/PASEP */
  getDetalhesContribuicaoPisPasep(event: any) {

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      idM200: event.id,
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-detalhes-contribuicao-pis', dados)
      .subscribe(
        res => {
          console.log("Busca de detalhes de contribuição para PIS/PASEP efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "cod_contribuicao": line["cod_cont"], "vl_receita": line["vlItem"], "bc_pis": line["vlBc"],
              "aliq_pis": line["aliq"], "valor_pis": line["vl"], "id": line["id"], "acao": ""
            });
          }
          if (res.length > 0) {
            this.tDetalheContribuicaoPis = dadosRetornados;
          } else {
            this.tDetalheContribuicaoPis = null;
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os ajustes de crédito para um registro de PIS/PASEP */
  getAjusteCreditoPisPasep(event: any) {

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      idM100: event.id,
    }

    var dtReferencia: any;
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-ajuste-credito-pis', dados)
      .subscribe(
        res => {
          console.log(res);
          console.log("Busca de ajustes de crédito para PIS/PASEP efetuada com sucesso!");
          for (let line of res) {

            var aux = line["dtReferencia"];
            if (aux != null) {
              var formattedData = new Date(parseFloat(aux));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              dtReferencia = day + '/' + month + '/' + year;
            } else {
              dtReferencia = null;
            }

            dadosRetornados.push({
              "data": dtReferencia, "tipo_ajuste": line["indAj"],
              "cod_ajuste": line["codAj"], "descricao": line["descricao"], "num_doc": line["numDoc"],
              "vl_ajuste": line["vlAj"], "id": line["id"], "acao": ""
            });
          }
          this.tAjustePis = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }


  /*CARD COFINS DETALHE */
  showCofinsDetalhe(event: any) {
    this.cofinsDetalhe = true;
    this.pisDetalhe = false;

    this.idCreditoCofins = event.id;
    this.aliqAjusteCofinsM500 = event.aliq_cofins;
    this.tipoCreditoCofinsM500 = event.tipo_credito;

    this.modalBaseCofinsTipoCredito = event.tipo_credito;

    this.getDetalhesCreditoCofins(event);
    this.getAjusteCreditoCofins(event);

  }

  /* Função que obtém os ajustes de crédito para um registro de COFINS */
  getAjusteCreditoCofins(event: any) {

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      idM500: event.id,
    }

    var dtReferencia: any;
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-ajuste-credito-cofins', dados)
      .subscribe(
        res => {
          console.log(res);
          console.log("Busca de ajustes de crédito para COFINS efetuada com sucesso!");
          for (let line of res) {

            var aux = line["dtReferencia"];
            if (aux != null) {
              var formattedData = new Date(parseFloat(aux));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              dtReferencia = day + '/' + month + '/' + year;
            } else {
              dtReferencia = null;
            }

            dadosRetornados.push({
              "data": dtReferencia, "tipo_ajuste": line["indAj"],
              "cod_ajuste": line["codAj"], "descricao": line["descricao"], "num_doc": line["numDoc"],
              "vl_ajuste": line["vlAj"], "id": line["id"], "acao": ""
            });
          }
          this.tAjusteCofins = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /*CARD COFINS CONTRIBUIÇÕES DETALHE */
  showCofinsContribuicaoDetalhe(event: any) {
    this.cofinsDetalhe = true;
    this.pisDetalhe = false;

    this.idM600 = event.id;

    this.getDetalhesContribuicaoCofins(event);

    this.getAjusteContribuicaoCofins(event);

  }

  /* Função que obtém os detalhes de crédito para um registro de COFINS */
  getDetalhesCreditoCofins(event: any) {

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      idM500: event.id,
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-detalhes-credito-cofins', dados)
      .subscribe(
        res => {
          console.log("Busca de detalhes de crédito para COFINS efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "natureza_base_credito": line["natBcCred"], "cst": line["cst"], "bc_cofins": line["bcCofins"], "bc_cofins_prop": line["bcCofinsProp"],
              "aliq_cofins": line["aliqCofins"], "valor_cofins": line["valorCofins"], "acao": ""
            });
          }
          this.tDetalheCofins = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os detalhes de contribuição para um registro de COFINS */
  getDetalhesContribuicaoCofins(event: any) {

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      idM600: event.id,
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-detalhes-contribuicao-cofins', dados)
      .subscribe(
        res => {
          console.log("Busca de detalhes de crédito para COFINS efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "cod_contribuicao": line["cod_cont"], "vl_receita": line["vlItem"], "bc_cofins": line["vlBc"],
              "aliq_cofins": line["aliq"], "valor_cofins": line["vl"], "id": line["id"], "acao": ""
            });
          }
          if (res.length > 0) {
            this.tDetalheContribuicaoCofins = dadosRetornados;
          } else {
            this.tDetalheContribuicaoCofins = null;
          }

          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os ajustes de crédito para um registro de COFINS */
  getAjusteContribuicaoCofins(event: any) {

    let dadosRetornados = [];
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      idM600: event.id,
    }

    var dtReferencia: any;
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-ajuste-contribuicao-cofins', dados)
      .subscribe(
        res => {
          console.log(res);
          console.log("Busca de ajustes de contribuição para COFINS efetuada com sucesso!");
          for (let line of res) {

            var aux = line["dtReferencia"];
            if (aux != null) {
              var formattedData = new Date(parseFloat(aux));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              dtReferencia = day + '/' + month + '/' + year;
            } else {
              dtReferencia = null;
            }

            dadosRetornados.push({
              "data": dtReferencia, "tipo_ajuste": line["indAj"],
              "cod_ajuste": line["codAj"], "descricao": line["descricao"], "num_doc": line["numDoc"],
              "vl_ajuste": line["vlAj"], "id": line["id"], "acao": ""
            });
          }
          this.tAjusteContribuicaoCofins = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /*CARD ICMS-ST DETALHE */
  showIcmsstDetalhe(event: any) {
    this.icmsstDetalhe = true;
    this.idE200 = event.id

    this.getApuracaoICMSSTDetalhe();
  }

  /*Show Hide Pis Detalhe*/
  isCardDetalhePis: boolean = true;
  ShowHIdeCardPisDetalhe() {
    this.isCardDetalhePis = !this.isCardDetalhePis;
  }

  /*Show Hide ICMS-ST Detalhe*/
  isCardIcmsSt: boolean = true;
  ShowHIdeIcmsStsDetalhe() {
    this.isCardIcmsSt = !this.isCardIcmsSt;
  }

  /*Show Hide Cofins Detalhe*/
  isCardDetalheCofins: boolean = true;
  ShowHIdeCardCofinsDetalhe() {
    this.isCardDetalheCofins = !this.isCardDetalheCofins;
  }

  /*Show Hide Item Selecionado*/
  isCardItemSelecionado: boolean = true;
  ShowHIdeCardItemSelecionado() {
    this.isCardItemSelecionado = !this.isCardItemSelecionado;
  }

  /*Show Hide Gerar Apuração*/
  isCardGerarApuracao: boolean = true;
  ShowHIdeGerarApuracao() {
    this.isCardGerarApuracao = !this.isCardGerarApuracao;
  }

  /*Show Hide Gerar Apuração Sub*/
  isCardGerarApuracaoSub: boolean = true;
  ShowHIdeGerarApuracaoSub() {
    this.isCardGerarApuracaoSub = !this.isCardGerarApuracaoSub;
  }

  /* Função que abre o modal para exibir os detalhes sobre uma regra específica. */
  openCardRegra(event: any) {

    this.tCurrentRegraModalCodigo = event.cod;
    this.tCurrentRegraModalDescricao = event.descricao;
    this.tCurrentRegraModalNome = event.nome;

    this.getDetalhesCamposRegras(event, "regra");
  }

  // Função que obtém o conteúdo de uma regra ou relatório
  getDetalhesRegras(event: any, tipoDado, camposRetornados) {

    console.log(event);

    let dadosRetornados = [];
    var dict = {}; // create an empty array
    this.httpService.wait();
    this.httpService.get('/custom/dbl/regra',
      [event.regraId, this.tCurrentCnpj.toString(), this.tCurrentPeriodo.toString()])
      .subscribe(
        res => {
          console.log(res);
          for (let line of res) {
            var aux = line["values"];
            for (let i = 0; i < camposRetornados.length; i++) {
              if (camposRetornados[i].toString() == "dt_doc") {
                var formattedData = new Date(parseFloat(aux[i]));

                let month = String(formattedData.getMonth() + 1);
                let day = String(formattedData.getDate());
                const year = String(formattedData.getFullYear());

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                dict[camposRetornados[i].toString()] = String(day + '/' + month + '/' + year);
              } else {
                dict[camposRetornados[i].toString()] = String(aux[i]);
              }
            }
            dadosRetornados.push(dict);
            dict = {};
          }

          if (tipoDado == "regra") {
            this.tRegrasDetalhe = dadosRetornados;
          } else if (tipoDado == "relatorio") {
            this.tRelatoriosDetalhe = dadosRetornados;
          }

          this.tCampos = camposRetornados;

          console.log(this.tCampos);

          this.httpService.done();

        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getDetalhesCamposRegras(event: any, tipoDado) {

    console.log(event);

    let camposRetornados = [];

    this.tRegrasDetalhe = [];
    this.tRelatoriosDetalhe = [];

    this.httpService.wait();
    this.httpService.get('/custom/dbl/regracampos',
      [event.regraId])
      .subscribe(
        res => {
          for (let line of res) {
            var item = Object.keys(line);

            camposRetornados.push(item[0].toLowerCase());
          }
          console.log(camposRetornados);
          this.httpService.done();

          this.getDetalhesRegras(event, tipoDado, camposRetornados);
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que abre o modal para exibir os detalhes sobre um relatório específico. */
  openCardRelatorio(event: any) {

    this.tCurrentRelatorioModalCodigo = event.cod;
    this.tCurrentRelatorioModalDescricao = event.descricao;
    this.tCurrentRelatorioModalNome = event.nome;

    console.log(event);

    this.getDetalhesCamposRegras(event, "relatorio");
  }

  /*MODAL Ajuste Base Crédito PIS */
  openModalBaseCreditoPis(event: any) {
    this.modalBasePis = true;

    this.modalBasePisNatBcCred = event.natureza_base_credito;
    this.modalBasePisAliquota = event.aliq_pis;

    this.getDadosModalBasePis(event)
  }

  /* Função que retorna os dados a serem exibidos no modal relacionado
  da base de crédito PIS/PASEP */
  getDadosModalBasePis(event) {

    this.tBaseCreditoPis = [];

    let dadosRetornados = [];
    var data;
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      aliq: event.aliq_pis,
      cst: event.cst,
      natBc: event.natureza_base_credito,
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-dados-modal-base-credito-pis', dados)
      .subscribe(
        res => {
          console.log("Busca de dados para o modal de crédito PIS/PASEP efetuada com sucesso!");
          console.log(res);
          for (let line of res) {

            var aux = line["data"];
            if (aux != null) {
              var formattedData = new Date(parseFloat(aux));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              data = day + '/' + month + '/' + year;
            } else {
              data = null;
            }

            dadosRetornados.push({
              "reg": line["regra"], "data": data, "num_nf": line["num_doc"],
              "cfop": line["cfop"], "participante": line["participante"], "descr_item": line["descricaoItem"],
              "tipo": line["tipo"], "cst_pis": line["cst"], "aliq_pis": line["aliqPis"], "nat_bc_cred": line["natBcCred"], "valor_item": line["vlItem"],
              "vl_bc_pis": line["vlBcPis"], "valor_pis": line["vlPis"]
            });
          }

          this.tBaseCreditoPis = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /*MODAL Ajuste Base Crédito COFINS */
  openModalBaseCreditoCofins(event: any) {
    this.modalBaseCofins = true;

    this.modalBaseCofinsNatBcCred = event.natureza_base_credito;
    this.modalBaseCofinsAliquota = event.aliq_cofins;

    this.getDadosModalBaseCofins(event)
  }

  /* Função que retorna os dados a serem exibidos no modal relacionado
  da base de crédito COFINS */
  getDadosModalBaseCofins(event) {

    console.log(event);

    this.tBaseCreditoCofins = [];

    let dadosRetornados = [];
    var data;
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      aliq: event.aliq_cofins,
      cst: event.cst,
      natBc: event.natureza_base_credito,
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-dados-modal-base-credito-cofins', dados)
      .subscribe(
        res => {
          console.log("Busca de dados para o modal de crédito COFINS efetuada com sucesso!");
          console.log(res);
          for (let line of res) {

            var aux = line["data"];
            if (aux != null) {
              var formattedData = new Date(parseFloat(aux));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              data = day + '/' + month + '/' + year;
            } else {
              data = null;
            }

            dadosRetornados.push({
              "reg": line["regra"], "data": data, "num_nf": line["num_doc"],
              "cfop": line["cfop"], "participante": line["participante"], "descr_item": line["descricaoItem"],
              "tipo": line["tipo"], "cst_cofins": line["cst"], "aliq_cofins": line["aliqCofins"], "nat_bc_cred": line["natBcCred"], "valor_item": line["vlItem"],
              "vl_bc_cofins": line["vlBcCofins"], "valor_cofins": line["vlCofins"]
            });
          }

          this.tBaseCreditoCofins = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }


  /*MODAL Ajuste PIS */
  openModalAjustePIS() {
    this.modalAjustePis = true;

    this.codAjustePisEscolhido = undefined;
    this.tipoAjustePisEscolhido = undefined;
    this.descricaoAjustePis = undefined;
    this.numeroDocumentoAjustePis = undefined;
    this.valorAjustePis = undefined;
    this.dtAjustePis = undefined;
  }

  /*MODAL Ajuste COFINS */
  openModalAjusteCofins() {
    this.modalAjusteCofins = true;

    this.codAjusteCofinsEscolhido = undefined;
    this.tipoAjusteCofinsEscolhido = undefined;
    this.descricaoAjusteCofins = undefined;
    this.numeroDocumentoAjusteCofins = undefined;
    this.valorAjusteCofins = undefined;
    this.dtAjusteCofins = undefined;
  }

  /*MODAL Ajuste Contribuição PIS */
  openModalAjusteContribuicaoPIS() {

    /* Esse modal só poderá ser aberto com algum registro vindo da tabela
    M210PisCofins. Caso contrário, um novo registro não poderá criado
    e uma mensagem de aviso deverá ser emitida. */
    if ((this.idCreditoContribuicaoPis != null) && (this.idCreditoContribuicaoPis != undefined)) {
      this.modalAjusteContribuicaoPis = true;

      this.codAjusteContribuicaoPisEscolhido = undefined;
      this.tipoAjusteContribuicaoPisEscolhido = undefined;
      this.descricaoAjusteContribuicaoPis = undefined;
      this.numeroDocumentoAjusteContribuicaoPis = undefined;
      this.valorAjusteContribuicaoPis = undefined;
      this.dtAjusteContribuicaoPis = undefined;

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "Favor selecionar um registro contido em 'Detalhamento da Contribuição'." });
    }
  }

  /*MODAL Ajuste Contribuição COFINS */
  openModalAjusteContribuicaoCofins() {

    /* Esse modal só poderá ser aberto se algum registro vindo da tabela
    M610PisCofins. Caso contrário, um novo registro não poderá criado
    e uma mensagem de aviso deverá ser emitida. */
    if ((this.idCreditoContribuicaoCofins != null) && (this.idCreditoContribuicaoCofins != undefined)) {
      this.modalAjusteContribuicaoCofins = true;

      this.codAjusteContribuicaoCofinsEscolhido = undefined;
      this.tipoAjusteContribuicaoCofinsEscolhido = undefined;
      this.descricaoAjusteContribuicaoCofins = undefined;
      this.numeroDocumentoAjusteContribuicaoCofins = undefined;
      this.valorAjusteContribuicaoCofins = undefined;
      this.dtAjusteContribuicaoCofins = undefined;

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "Favor selecionar um registro contido em 'Detalhamento da Contribuição COFINS'." });
    }
  }

  /*MODAL Detalhamento da Contribuicao PIS */
  openModalContribuicaoPis(event: any) {
    this.modalContribuicaoPis = true;

    this.getDadosModalContribuicaoPis(event)
  }

  /* Função que retorna os dados a serem exibidos no modal relacionado
 da base de contribuição PIS/PASEP */
  getDadosModalContribuicaoPis(event) {

    this.tBaseContribuicaoPis = [];

    let dadosRetornados = [];
    var data;
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      aliq: event.aliq_pis,
      cst: event.cod_contribuicao.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-dados-modal-base-contribuicao-pis', dados)
      .subscribe(
        res => {
          console.log("Busca de dados para o modal de crédito PIS/PASEP efetuada com sucesso!");
          console.log(res);
          for (let line of res) {

            var aux = line["data"];
            if (aux != null) {
              var formattedData = new Date(parseFloat(aux));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              data = day + '/' + month + '/' + year;
            } else {
              data = null;
            }

            dadosRetornados.push({
              "reg": line["regra"], "data": data, "num_nf": line["num_doc"],
              "cfop": line["cfop"], "participante": line["participante"], "descr_item": line["descricaoItem"],
              "tipo": line["tipo"], "cst_pis": line["cst"], "aliq_pis": line["aliqPis"], "valor_item": line["vlItem"],
              "vl_bc_pis": line["vlBcPis"], "valor_pis": line["vlPis"]
            });
          }

          this.tBaseContribuicaoPis = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }


  /* MODAL Contribuição Cofins */
  openModalContribuicaoCofins(event: any) {
    this.modalContribuicaoCofins = true;

    this.getDadosModalContribuicaoCofins(event);
  }

  /* Função que retorna os dados a serem exibidos no modal relacionado
  da base de contribuição PIS/PASEP */
  getDadosModalContribuicaoCofins(event) {

    console.log("Entrou na função de Modal de COFINS");
    console.log(event);

    this.tBaseContribuicaoCofins = [];

    let dadosRetornados = [];
    var data;
    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      aliq: event.aliq_cofins,
      cst: event.cod_contribuicao.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-dados-modal-base-contribuicao-cofins', dados)
      .subscribe(
        res => {
          console.log("Busca de dados para o modal de crédito COFINS efetuada com sucesso!");
          console.log(res);
          for (let line of res) {

            var aux = line["data"];
            if (aux != null) {
              var formattedData = new Date(parseFloat(aux));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              data = day + '/' + month + '/' + year;
            } else {
              data = null;
            }

            dadosRetornados.push({
              "reg": line["regra"], "data": data, "num_nf": line["num_doc"],
              "cfop": line["cfop"], "participante": line["participante"], "descr_item": line["descricaoItem"],
              "tipo": line["tipo"], "cst_cofins": line["cst"], "aliq_cofins": line["aliqCofins"], "valor_item": line["vlItem"],
              "vl_bc_cofins": line["vlBcCofins"], "valor_cofins": line["vlCofins"]
            });
          }

          this.tBaseContribuicaoCofins = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /*MODAL Controle de Créditos Fiscais */
  openModalControleCredito(tipoDado) {
    this.modalControleCredito = true;

    this.origemCreditosFiscaisEscolhido = undefined;
    this.tipoCreditosFiscaisEscolhido = undefined;
    this.cnpjCreditosFiscais = undefined;
    this.dtApuracaoCreditosFiscais = undefined;
    this.apuradoPeriodoCreditosFiscais = undefined;
    this.vlCredApuCreditosFiscais = undefined;
    this.utilRessarcCreditosFiscais = undefined;
    this.dispUtilizacaoCreditosFiscais = undefined;
    this.objPedidoRessarcCreditosFiscais = undefined;
    this.opcoesCreditosFiscais = undefined;
    this.extemporaneoCreditosFiscais = undefined;
    this.utilDescCreditosFiscais = undefined;
    this.utilCompCreditosFiscaisAtual = undefined;
    this.descEscrituracaoCreditosFiscais = undefined;
    this.utilCompCreditosFiscaisAnteriores = undefined;
    this.utilOutrosCreditosFiscais = undefined;
    this.utilPeriodoApurCreditosFiscais = undefined;

    if (tipoDado === "pis") {
      this.controleCreditosFiscaisPis = true;
    } else if (tipoDado === "cofins") {
      this.controleCreditosFiscaisPis = false;
    }

    // Carregar os tipos de crédito
    this.getTiposCredito();

  }

  /*MODAL Controle Retido Fonte */
  openModalControleRetidoFonte(tipoDado) {
    this.modalControleRetidoFonte = true;

    this.natControleRetFonteEscolhido = undefined;
    this.vlTotalControleRetFonte = undefined;
    this.vlPedRessarcControleRetFonte = undefined;
    this.saldoUtilizarFuturoControleRetFonte = undefined;
    this.dtOperacaoControleRetFonte = undefined;
    this.vlDeduzidoContribuicaoControleRetFonte = undefined;
    this.vlUtilizadoDeclaracaoControleRetFonte = undefined;

    if (tipoDado === "pis") {
      this.controleRetidoFontePis = true;
    } else if (tipoDado === "cofins") {
      this.controleRetidoFontePis = false;
    }

    // Carregar os códigos de índices de natureza retidos na fonte
    this.getCodigosIndNatRetFonte();
  }

  /*MODAL ICMS Ajuste Benefício/Incentivo */
  openModalAjusteBeneficio() {
    this.modalBeneficioIncentivo = true;

    this.ajusteApurDeduEscolhido = undefined;
    this.descricaoComplementarE111 = undefined;
    this.vlAjusteApuracaoE111 = undefined;

    // Carregar os códigos de ajuste para a tabela RegE111
    this.getAjustesCodigoRegE111();
  }

  /*MODAL Ajuste Apuração IPI */
  openModalAjusteApuracaoIPI() {
    this.modalAjusteApuracaoIPI = true;

    this.tipoAjusteIPIEscolhido = undefined;
    this.origemDocIPIEscolhido = undefined;
    this.numeroDocumentoE530 = undefined;
    this.vlAjusteE530 = undefined;
    this.codAjusteApuracaoIPIEscolhido = undefined;
    this.descricaoResumidaE530 = undefined;

    // Carregar os códigos de ajuste para a tabela RegE530
    this.getAjustesCodigoRegE530();
  }

  /*Modal Contribuição Retida na Fonte */
  addRetencao() {
    this.modalContribuicaoRetidaFonte = true;

    this.naturezaRetFonteEscolhido = undefined;
    this.indicadorCondJuridicaEscolhido = undefined;
    this.vlBaseCalcCredF600 = undefined;
    this.vlTotalRetF600 = undefined;
    this.cnpjFontePagadora = undefined;
    this.codReceitaF600 = undefined;
    this.naturezaReceitaEscolhido = undefined;
    this.dtF600 = undefined;
    this.parcPis = undefined;
    this.parcCofins = undefined;

    // Carregar os códigos de índices de natureza retidos na fonte
    this.getCodigosIndNatRetFonte();

  }

  /*Modal F100: Demais Documentos */
  addF100() {
    this.modalF100 = true;
    this.tipoOperacaoF100 = undefined;
    this.codNaturezaBaseCreditoF100 = undefined;
    this.indOrigCredF100 = undefined;
    this.cstPisF100 = undefined;
    this.cstCofinsF100 = undefined;
    this.codParticipanteF100 = undefined;
    this.codItemF100 = undefined;
    this.vlOperacaoF100 = undefined;
    this.dtOperacaoF100 = undefined;
    this.codContaContabilF100 = undefined;
    this.codCentroCustosF100 = undefined;
    this.descDocF100 = undefined;
    this.vlBcPisF100 = undefined;
    this.aliqPisF100 = undefined;
    this.vlPisF100 = undefined;
    this.vlBcCofinsF100 = undefined;
    this.aliqCofinsF100 = undefined;
    this.vlCofinsF100 = undefined;

    // Carregar os códigos de CST (válidos para PIS/COFINS)
    this.getCodigosCstPisCofins();
  }

  /*Modal F120: Bens Incorporados ao Ativo - Depreciação/Amortização */
  addF120() {
    this.modalF120 = true;
    this.identBensGrupoF120 = undefined;
    this.codNaturezaBaseCreditoF120 = undefined;
    this.indUtilBemF120 = undefined;
    this.cstPisF120 = undefined;
    this.cstCofinsF120 = undefined;
    this.parcelasEncargoF120 = undefined;
    this.indOrigBemF120 = undefined;
    this.vlEncargoF120 = undefined;
    this.codContaContabilF120 = undefined;
    this.codCentroCustosF120 = undefined;
    this.descDocF120 = undefined;
    this.vlBcPisF120 = undefined;
    this.aliqPisF120 = undefined;
    this.vlPisF120 = undefined;
    this.vlBcCofinsF120 = undefined;
    this.aliqCofinsF120 = undefined;
    this.vlCofinsF120 = undefined;

    // Carregar os códigos de CST (válidos para PIS/COFINS)
    this.getCodigosCstPisCofins();
  }

  /*Modal F130: Bens Incorporados ao Ativo - Aquisição/Contribuição */
  addF130() {
    this.modalF130 = true;
    this.identBensGrupoF130 = undefined;
    this.indUtilBemF130 = undefined;
    this.cstPisF130 = undefined;
    this.cstCofinsF130 = undefined;
    this.indNumParcF130 = undefined;
    this.parcelasAquisicaoF130 = undefined;
    this.indOrigBemF130 = undefined;
    this.vlAquisicaoF130 = undefined;
    this.vlBaseCalcCredF130 = undefined;
    this.codContaContabilF130 = undefined;
    this.codCentroCustosF130 = undefined;
    this.descDocF130 = undefined;
    this.vlBcPisF130 = undefined;
    this.aliqPisF130 = undefined;
    this.vlPisF130 = undefined;
    this.vlBcCofinsF130 = undefined;
    this.aliqCofinsF130 = undefined;
    this.vlCofinsF130 = undefined;
    this.mesAnoAquisicaoF130 = undefined;

    // Carregar os códigos de CST (válidos para PIS/COFINS)
    this.getCodigosCstPisCofins();
  }

  /* Filter Link */
  showTypeLink(tipo) {

    this.statusRegras = false;
    this.statusRelatorios = false;

    if (tipo === 'LINK_1') {
      this.statusRegras = true;
      this.tCurrentRelatorioModalCodigo = undefined;
      this.tCurrentRelatorioModalDescricao = undefined;
    } else if (tipo === 'LINK_2') {
      this.statusRelatorios = true;
      this.tCurrentRegraModalCodigo = undefined;
      this.tCurrentRegraModalDescricao = undefined;
    }
  }

  /* Filter Link Apuração */
  showTypeApuracao(tipo) {
    this.statusPis = false;
    this.statusCofins = false;
    this.statusContribuicao = false;
    this.statusF100 = false;
    this.statusF120 = false;
    this.statusF130 = false;

    if (tipo === 'LINK_1') {
      this.statusPis = true;
      this.statusCofins = false;
      this.cofinsDetalhe = false;
      this.statusContribuicao = false;
      this.statusF100 = false;
      this.statusF120 = false;
      this.statusF130 = false;
    } else if (tipo === 'LINK_2') {
      this.statusPis = false;
      this.pisDetalhe = false;
      this.statusCofins = true;
      this.statusContribuicao = false;
      this.statusF100 = false;
      this.statusF120 = false;
      this.statusF130 = false;
    } else if (tipo === 'LINK_3') {
      this.statusPis = false;
      this.statusCofins = false;
      this.statusContribuicao = true;
      this.statusF100 = false;
      this.statusF120 = false;
      this.statusF130 = false;
      this.pisDetalhe = false;
      this.cofinsDetalhe = false;
    }
    else if (tipo === 'LINK_4') {
      this.statusPis = false;
      this.statusCofins = false;
      this.statusContribuicao = false;
      this.statusF100 = true;
      this.statusF120 = false;
      this.statusF130 = false;
      this.pisDetalhe = false;
      this.cofinsDetalhe = false;
    }
    else if (tipo === 'LINK_5') {
      this.statusPis = false;
      this.statusCofins = false;
      this.statusContribuicao = false;
      this.statusF100 = false;
      this.statusF120 = true;
      this.statusF130 = false;
      this.pisDetalhe = false;
      this.cofinsDetalhe = false;
    }
    else if (tipo === 'LINK_6') {
      this.statusPis = false;
      this.statusCofins = false;
      this.statusContribuicao = false;
      this.statusF100 = false;
      this.statusF120 = false;
      this.statusF130 = true;
      this.pisDetalhe = false;
      this.cofinsDetalhe = false;
    }
  }

  /* Filter Link SubApuração */
  showTypeSubApuracao(tipo) {
    this.creditoPisPasep = false;
    this.contribuicaoPisPasep = false;
    this.receitasPisPasep = false;
    this.creditosPisPasep = false;
    this.retidosPisPasep = false;

    if (tipo === 'LINK_1') {
      this.creditoPisPasep = true;
    } else if (tipo === 'LINK_2') {
      this.contribuicaoPisPasep = true;
    } else if (tipo === 'LINK_3') {
      this.receitasPisPasep = true;
      this.pisDetalhe = false;
    } else if (tipo === 'LINK_4') {
      this.creditosPisPasep = true;
      this.pisDetalhe = false;
    } else if (tipo === 'LINK_5') {
      this.retidosPisPasep = true;
      this.pisDetalhe = false;
    }
  }

  /* Filter Link Apuração ICMS/IPI */
  showTypeApuracaoICMSIPI(tipo) {
    this.apuracaoICMS = false;
    this.apuracaoICMSST = false;
    this.apuracaoIPI = false;

    if (tipo === 'LINK_1') {
      this.apuracaoICMS = true;
      this.apuracaoICMSST = false;
      this.apuracaoIPI = false;
      this.icmsstDetalhe = false;
    } else if (tipo === 'LINK_2') {
      this.apuracaoICMS = false;
      this.apuracaoICMSST = true;
      this.apuracaoIPI = false;
      this.saldoCredor = false;
    } else if (tipo === 'LINK_3') {
      this.apuracaoICMS = false;
      this.apuracaoICMSST = false;
      this.apuracaoIPI = true;
      this.icmsstDetalhe = false;
    }
  }

  /* Filter Link SubApuração ICMS (1) */
  showTypeSubApuracaoICMS(tipo) {
    this.ajusteBeneficioIncentivo = false;
    this.saldoCredor = false;
    this.icmsstDetalhe = false;


    if (tipo === 'LINK_1') {
      this.ajusteBeneficioIncentivo = true;
    } else if (tipo === 'LINK_2') {
      this.saldoCredor = true;
    }
  }

  /* Filter Link SubApuração IPI (3) */
  showTypeSubApuracaoIPI(tipo) {
    this.valoresConsolidados = false;
    this.ipiAjuste = false;
    this.ipiSaldoCredor = false;
    this.icmsstDetalhe = false;

    if (tipo === 'LINK_1') {
      this.valoresConsolidados = true;
    } else if (tipo === 'LINK_2') {
      this.ipiAjuste = true;
    } else if (tipo === 'LINK_3') {
      this.ipiSaldoCredor = true;
    }
  }

  /* Filter Link Detalhe PIS CRÉDITO */
  showTypeDetalhePis(tipo) {
    this.statusBaseCalculoPis = false;
    this.statusAjusteCreditoPis = false;
    this.statusRetidoFontePis = false;

    if (tipo === 'LINK_1') {
      this.statusBaseCalculoPis = true;
    } else if (tipo === 'LINK_2') {
      this.statusAjusteCreditoPis = true;
    } else if (tipo === 'LINK_3') {
      this.statusRetidoFontePis = true;
    }
  }

  /* Filter Link Detalhe COFINS CRÉDITO */
  showTypeDetalheCofins(tipo) {
    this.statusBaseCalculoCofins = false;
    this.statusAjusteCreditoCofins = false;
    this.statusRetidoFonteCofins = false;

    if (tipo === 'LINK_1') {
      this.statusBaseCalculoCofins = true;
    } else if (tipo === 'LINK_2') {
      this.statusAjusteCreditoCofins = true;
    } else if (tipo === 'LINK_3') {
      this.statusRetidoFonteCofins = true;
    }
  }

  /* Filter Link Detalhe PIS CONTRIBUICAO */
  showTypeDetalheContribuicaoPis(tipo) {
    this.statusDetalheContribuicaoPis = false;
    this.statusAjusteContribuicaoPis = false;
    this.statusRetidoFontePis = false;

    if (tipo === 'LINK_1') {
      this.statusDetalheContribuicaoPis = true;
    } else if (tipo === 'LINK_2') {
      this.statusAjusteContribuicaoPis = true;
    } else if (tipo === 'LINK_3') {
      this.statusRetidoFontePis = true;
    }
  }

  /* Filter Link Detalhe COFINS CONTRIBUICAO */
  showTypeDetalheContribuicaoCofins(tipo) {
    this.statusDetalheContribuicaoCofins = false;
    this.statusAjusteContribuicaoCofins = false;
    this.statusRetidoFonteCofins = false;

    if (tipo === 'LINK_1') {
      this.statusDetalheContribuicaoCofins = true;
    } else if (tipo === 'LINK_2') {
      this.statusAjusteContribuicaoCofins = true;
    } else if (tipo === 'LINK_3') {
      this.statusRetidoFonteCofins = true;
    }
  }

  showTypeSubApuracaoCofins(tipo) {
    this.creditoCofins = false;
    this.contribuicaoCofins = false;
    this.receitasCofins = false;
    this.creditosCofins = false;
    this.retidosCofins = false;

    if (tipo === 'LINK_1') {
      this.creditoCofins = true;
    } else if (tipo === 'LINK_2') {
      this.contribuicaoCofins = true;
    } else if (tipo === 'LINK_3') {
      this.receitasCofins = true;
      this.cofinsDetalhe = false;
    } else if (tipo === 'LINK_4') {
      this.creditosCofins = true;
      this.cofinsDetalhe = false;
    } else if (tipo === 'LINK_5') {
      this.retidosCofins = true;
      this.cofinsDetalhe = false;
    }
  }

  /*BACK CARD */
  backCards() {
    this.processadoDetalhe = false;
    this.gerarApuracao = false;
    this.gerarApuracaoIcmsIpi = false;
    this.pisDetalhe = false;
    this.cofinsDetalhe = false;
    this.icmsstDetalhe = false;
  }

  /*Open Close DropDown Date*/
  dropdate: boolean = false;
  DropdownDateEvent() {
    this.dropdate = !this.dropdate;
  }

  /*Atualizar Período*/
  atualizarTudo() {
    this.dropdate = false;

    // Verificar se as duas datas estão preenchidas
    if (this.dtInicial == null || this.dtInicial == undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: 'Selecione uma data inicial para filtro.' });

    } else if (this.dtFinal == null || this.dtFinal == undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: 'Selecione uma data final para filtro.' });

      // Verificar se a data final é posterior à data inicial.
    } else {
      if (this.dtInicial > this.dtFinal) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: 'Data inicial maior que a final. Verifique!' });

      } else {
        let month = String(this.dtInicial.getMonth() + 1);
        let day = String(this.dtInicial.getDate());
        let year = String(this.dtInicial.getFullYear());

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        this.dtInicialStr = year + '-' + month + '-' + day;

        month = String(this.dtFinal.getMonth() + 1);
        day = String(this.dtFinal.getDate());
        year = String(this.dtFinal.getFullYear());

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        this.dtFinalStr = year + '-' + month + '-' + day;

        this.loadDados(true);
      }
    }

  }

  /* Função que carrega os dados referentes aos registros de PIS/COFINS
  e de ICMS/IPI, exibindo o retorno do BD na tela principal */
  loadDados(filtroData) {

    var dados;

    /* Checar se a busca envolve um filtro de data ou não */
    if (filtroData == false) {
      dados = {
        usuarioId: this.sessionService.loggedUser.id.toString(),
        dataInicio: this.dataIniSearch.toString(),
        dataFim: this.dataFinSearch.toString(),
      }
    } else {
      dados = {
        usuarioId: this.sessionService.loggedUser.id.toString(),
        dataInicio: this.dtInicialStr,
        dataFim: this.dtFinalStr,
      }

      this.dataIniSearch = this.dtInicialStr;
      this.dataFinSearch = this.dtFinalStr;
    }

    this.tProcess = [];

    var hrefAcesso = window.location.href.toString();
    console.log(hrefAcesso);

    /* Verificar qual método deve ser usado para obter os dados (o atual ou o antigo).
    Para isso, checar se o link usado no acesso está ligado a dbl.linnks.com.br.
    Em caso negativo, usar o método atual; senão, usar o método antigo */
    if (!hrefAcesso.includes("dbl.linnks")) {

      dados["empresaId"] = this.dblinkedSessionService.empresa.id;

      // Inicialmente, obter os dados de PIS/COFINS
      this.httpService.wait();
      this.httpService.post('/custom/dbl/arquivos-piscofins', dados)
        .subscribe(
          res => {
            console.log("Busca de PIS/COFINS efetuada com sucesso!");
            console.log(res);
            for (let line of res) {
              this.tProcess.push({
                "id": line["idReg0"], "data": line["dtInclusao"], "dtIni": line["dtIni"],
                "dtFim": line["dtFim"], "cod": line["cod_ver"],
                "razao_social": line["nome"], "cnpj": line["cnpj"], "tipo": "PIS/COFINS",
                "status": line["status"], "periodo": line["periodo"], "acao": ""
              });
            }

            this.httpService.done();

            // Caso a operação acima seja bem-sucedida, obter os dados ligados aos impostos ICMS/IPI
            this.httpService.wait();
            this.httpService.post('/custom/dbl/arquivos-icms', dados)
              .subscribe(
                res => {
                  console.log("Busca de ICMS/IPI efetuada com sucesso!");
                  console.log(res);
                  for (let line of res) {
                    this.tProcess.push({
                      "id": line["idReg0"], "data": line["dtInclusao"], "dtIni": line["dtIni"],
                      "dtFim": line["dtFim"], "cod": line["cod_ver"],
                      "razao_social": line["nome"], "cnpj": line["cnpj"], "tipo": "ICMS/IPI",
                      "status": line["status"], "periodo": line["periodo"], "acao": ""
                    });
                  }

                  this.tFilteredProcess = this.tProcess;
                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem(err);
                  console.log(err);
                  this.httpService.done();
                }
              );

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );

    } else {

      // Inicialmente, obter os dados de PIS/COFINS
      this.httpService.wait();
      this.httpService.post('/custom/dbl/arquivos-piscofins-old', dados)
        .subscribe(
          res => {
            console.log("Busca de PIS/COFINS no método antigo efetuada com sucesso!");
            console.log(res);
            for (let line of res) {
              this.tProcess.push({
                "id": line["idReg0"], "data": line["dtInclusao"], "dtIni": line["dtIni"],
                "dtFim": line["dtFim"], "cod": line["cod_ver"],
                "razao_social": line["nome"], "cnpj": line["cnpj"], "tipo": "PIS/COFINS",
                "status": line["status"], "periodo": line["periodo"], "acao": ""
              });
            }

            this.httpService.done();

            // Caso a operação acima seja bem-sucedida, obter os dados ligados aos impostos ICMS/IPI
            this.httpService.wait();
            this.httpService.post('/custom/dbl/arquivos-icms-old', dados)
              .subscribe(
                res => {
                  console.log("Busca de ICMS/IPI no método antigo efetuada com sucesso!");
                  console.log(res);
                  for (let line of res) {
                    this.tProcess.push({
                      "id": line["idReg0"], "data": line["dtInclusao"], "dtIni": line["dtIni"],
                      "dtFim": line["dtFim"], "cod": line["cod_ver"],
                      "razao_social": line["nome"], "cnpj": line["cnpj"], "tipo": "ICMS/IPI",
                      "status": line["status"], "periodo": line["periodo"], "acao": ""
                    });
                  }

                  this.tFilteredProcess = this.tProcess;
                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem(err);
                  console.log(err);
                  this.httpService.done();
                }
              );

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );

    }

  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }


  /* Função que produz um relatório Excel contendo os dados de um modal dinâmico */
  gerarRelatorioExcelModalPisCofins(tipoDado) {

    var dadosModal;
    var codRegra;
    var nomeRegra;
    var descricaoRegra;
    if (tipoDado === "regra") {
      dadosModal = this.tRegrasDetalhe;
      codRegra = this.tCurrentRegraModalCodigo;
      nomeRegra = this.tCurrentRegraModalNome;
      descricaoRegra = this.tCurrentRegraModalDescricao;
    } else if (tipoDado === "relatorio") {
      dadosModal = this.tRelatoriosDetalhe;
      codRegra = this.tCurrentRelatorioModalCodigo;
      nomeRegra = this.tCurrentRelatorioModalNome;
      descricaoRegra = this.tCurrentRelatorioModalDescricao;
    }

    let dados = {
      dadosModal: dadosModal,
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      nomeFantasia: this.dblinkedSessionService.empresa.nomeFantasia,
      periodo: this.tCurrentPeriodo.toString(),
      camposHeader: this.tCampos,
      codRegra: codRegra,
      nomeRegra: nomeRegra,
      descricaoRegra: descricaoRegra,
    }
    this.httpService.wait();
    this.httpService.post('/custom/dbl/gerar-relatorio-excel-pis-cofins', dados)
      .subscribe(
        res => {
          // Disponibilizar o arquivo de saída para Download, caso a sua geração seja bem-sucedida
          if (res["error"] === false) {
            window.open(this.httpService.url('/custom/file/' + res["file"] + '/keep'), '_blank');
            console.log("Produção do relatório Excel efetuada com sucesso!");
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

  }


  /* Função que adiciona um novo registro à tabela regM110PisCofins,
  que contém os ajustes de crédito PIS. */
  adicionarAjustePis() {

    console.log("Entra na função que adiciona ajuste de crédito de PIS");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.codAjustePisEscolhido == null || this.codAjustePisEscolhido == undefined
      || this.codAjustePisEscolhido == "" || this.codAjustePisEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um código de ajuste." });
    } else if (this.tipoAjustePisEscolhido == null || this.tipoAjustePisEscolhido == undefined
      || this.tipoAjustePisEscolhido == "" || this.tipoAjustePisEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um tipo de ajuste." });
    } else if (this.valorAjustePis == null || this.valorAjustePis == undefined
      || this.valorAjustePis == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor." });
    } else {

      /* Realizar tratamento para os campos opcionais */
      if (this.numeroDocumentoAjustePis == null || this.numeroDocumentoAjustePis == undefined
        || this.numeroDocumentoAjustePis == "") {
        this.numeroDocumentoAjustePis = "";
      }

      if (this.descricaoAjustePis == null || this.descricaoAjustePis == undefined
        || this.descricaoAjustePis == "") {
        this.descricaoAjustePis = "";
      }

      if (this.dtAjustePis == null || this.dtAjustePis == undefined
        || this.dtAjustePis == "") {
        this.dtAjustePis = "";
      }

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        codAjuste: this.codAjustePisEscolhido,
        tipoAjustePis: this.tipoAjustePisEscolhido,
        descricaoAjuste: this.descricaoAjustePis,
        numeroDocumento: this.numeroDocumentoAjustePis,
        valorAjuste: this.valorAjustePis,
        dtAjuste: this.dtAjustePis,
        idCreditoPis: this.idCreditoPis,
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-ajuste-pis', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.modalAjustePis = false;
              let parametro = { id: this.idCreditoPis };
              this.getAjusteCreditoPisPasep(parametro);
              this.getCreditoPisPasep();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que atribui a um conjunto de variáveis os valores do registro
  da tabela M210PisCofins selecionado. */
  obterValoresM210(event: any) {
    this.idCreditoContribuicaoPis = event.id;
    this.aliqAjustePisM210 = event.aliq_pis;
    this.codContribuicaoPisM210 = event.cod_contribuicao;
    console.log(this.idCreditoContribuicaoPis);
    console.log(this.aliqAjustePisM210);
    console.log(this.codContribuicaoPisM210);
  }

  /* Função que adiciona um novo registro à tabela regM220PisCofins,
  que contém os ajustes de contribuição PIS. */
  adicionarAjusteContribuicaoPis() {

    console.log("Entra na função que adiciona ajuste de contribuição PIS");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.codAjusteContribuicaoPisEscolhido == null || this.codAjusteContribuicaoPisEscolhido == undefined
      || this.codAjusteContribuicaoPisEscolhido == "" || this.codAjusteContribuicaoPisEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um código de ajuste." });
    } else if (this.tipoAjusteContribuicaoPisEscolhido == null || this.tipoAjusteContribuicaoPisEscolhido == undefined
      || this.tipoAjusteContribuicaoPisEscolhido == "" || this.tipoAjusteContribuicaoPisEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um tipo de ajuste." });
    } else if (this.valorAjusteContribuicaoPis == null || this.valorAjusteContribuicaoPis == undefined
      || this.valorAjusteContribuicaoPis == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor." });
    } else {

      /* Realizar tratamento para os campos opcionais */
      if (this.numeroDocumentoAjusteContribuicaoPis == null || this.numeroDocumentoAjusteContribuicaoPis == undefined
        || this.numeroDocumentoAjusteContribuicaoPis == "") {
        this.numeroDocumentoAjusteContribuicaoPis = "";
      }

      if (this.descricaoAjusteContribuicaoPis == null || this.descricaoAjusteContribuicaoPis == undefined
        || this.descricaoAjusteContribuicaoPis == "") {
        this.descricaoAjusteContribuicaoPis = "";
      }

      if (this.dtAjusteContribuicaoPis == null || this.dtAjusteContribuicaoPis == undefined
        || this.dtAjusteContribuicaoPis == "") {
        this.dtAjusteContribuicaoPis = "";
      }

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        codAjuste: this.codAjusteContribuicaoPisEscolhido,
        tipoAjustePis: this.tipoAjusteContribuicaoPisEscolhido,
        descricaoAjuste: this.descricaoAjusteContribuicaoPis,
        numeroDocumento: this.numeroDocumentoAjusteContribuicaoPis,
        valorAjuste: this.valorAjusteContribuicaoPis,
        dtAjuste: this.dtAjusteContribuicaoPis,
        idCreditoPis: this.idCreditoContribuicaoPis,
        usuarioId: this.dblinkedSessionService.empresa.id.toString()
      }
      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-ajuste-contribuicao-pis', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.modalAjusteContribuicaoPis = false;
              let parametro = { id: this.idM200 };
              this.getAjusteContribuicaoPisPasep(parametro);
              this.getDetalhesContribuicaoPisPasep(parametro);
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que adiciona um novo registro à tabela regM510PisCofins,
  que contém os ajustes de crédito COFINS. */
  adicionarAjusteCofins() {

    console.log("Entra na função que adiciona ajuste de crédito de COFINS");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.codAjusteCofinsEscolhido == null || this.codAjusteCofinsEscolhido == undefined
      || this.codAjusteCofinsEscolhido == "" || this.codAjusteCofinsEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um código de ajuste." });
    } else if (this.tipoAjusteCofinsEscolhido == null || this.tipoAjusteCofinsEscolhido == undefined
      || this.tipoAjusteCofinsEscolhido == "" || this.tipoAjusteCofinsEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um tipo de ajuste." });
    } else if (this.valorAjusteCofins == null || this.valorAjusteCofins == undefined
      || this.valorAjusteCofins == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor." });
    } else {

      /* Realizar tratamento para os campos opcionais */
      if (this.numeroDocumentoAjusteCofins == null || this.numeroDocumentoAjusteCofins == undefined
        || this.numeroDocumentoAjusteCofins == "") {
        this.numeroDocumentoAjusteCofins = "";
      }

      if (this.descricaoAjusteCofins == null || this.descricaoAjusteCofins == undefined
        || this.descricaoAjusteCofins == "") {
        this.descricaoAjusteCofins = "";
      }

      if (this.dtAjusteCofins == null || this.dtAjusteCofins == undefined
        || this.dtAjusteCofins == "") {
        this.dtAjusteCofins = "";
      }

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        codAjuste: this.codAjusteCofinsEscolhido,
        tipoAjusteCofins: this.tipoAjusteCofinsEscolhido,
        descricaoAjuste: this.descricaoAjusteCofins,
        numeroDocumento: this.numeroDocumentoAjusteCofins,
        valorAjuste: this.valorAjusteCofins,
        dtAjuste: this.dtAjusteCofins,
        idCreditoCofins: this.idCreditoCofins,
        usuarioId: this.dblinkedSessionService.empresa.id.toString()
      }
      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-ajuste-cofins', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.modalAjusteCofins = false;
              let parametro = { id: this.idCreditoCofins };
              this.getAjusteCreditoCofins(parametro);
              this.getCreditoCofins();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que atribui a um conjunto de variáveis os valores do registro
  da tabela M610PisCofins selecionado. */
  obterValoresM610(event: any) {
    this.idCreditoContribuicaoCofins = event.id;
    this.aliqAjusteCofinsM610 = event.aliq_cofins;
    this.codContribuicaoCofinsM610 = event.cod_contribuicao;
    console.log(this.idCreditoContribuicaoCofins);
    console.log(this.aliqAjusteCofinsM610);
    console.log(this.codContribuicaoCofinsM610);
  }

  /* Função que adiciona um novo registro à tabela regM620PisCofins,
  que contém os ajustes de contribuição COFINS. */
  adicionarAjusteContribuicaoCofins() {

    console.log("Entra na função que adiciona ajuste de contribuição COFINS");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.codAjusteContribuicaoCofinsEscolhido == null || this.codAjusteContribuicaoCofinsEscolhido == undefined
      || this.codAjusteContribuicaoCofinsEscolhido == "" || this.codAjusteContribuicaoCofinsEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um código de ajuste." });
    } else if (this.tipoAjusteContribuicaoCofinsEscolhido == null || this.tipoAjusteContribuicaoCofinsEscolhido == undefined
      || this.tipoAjusteContribuicaoCofinsEscolhido == "" || this.tipoAjusteContribuicaoCofinsEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um tipo de ajuste." });
    } else if (this.valorAjusteContribuicaoCofins == null || this.valorAjusteContribuicaoCofins == undefined
      || this.valorAjusteContribuicaoCofins == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor." });
    } else {

      /* Realizar tratamento para os campos opcionais */
      if (this.numeroDocumentoAjusteContribuicaoCofins == null || this.numeroDocumentoAjusteContribuicaoCofins == undefined
        || this.numeroDocumentoAjusteContribuicaoCofins == "") {
        this.numeroDocumentoAjusteContribuicaoCofins = "";
      }

      if (this.descricaoAjusteContribuicaoCofins == null || this.descricaoAjusteContribuicaoCofins == undefined
        || this.descricaoAjusteContribuicaoCofins == "") {
        this.descricaoAjusteContribuicaoCofins = "";
      }

      if (this.dtAjusteContribuicaoCofins == null || this.dtAjusteContribuicaoCofins == undefined
        || this.dtAjusteContribuicaoCofins == "") {
        this.dtAjusteContribuicaoCofins = "";
      }

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        codAjuste: this.codAjusteContribuicaoCofinsEscolhido,
        tipoAjusteCofins: this.tipoAjusteContribuicaoCofinsEscolhido,
        descricaoAjuste: this.descricaoAjusteContribuicaoCofins,
        numeroDocumento: this.numeroDocumentoAjusteContribuicaoCofins,
        valorAjuste: this.valorAjusteContribuicaoCofins,
        dtAjuste: this.dtAjusteContribuicaoCofins,
        idCreditoCofins: this.idCreditoContribuicaoCofins,
        usuarioId: this.dblinkedSessionService.empresa.id.toString()
      }
      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-ajuste-contribuicao-cofins', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.modalAjusteContribuicaoCofins = false;
              console.log("Adição de registro de contribuição COFINS executada com sucesso!");
              console.log(res);
              let parametro = { id: this.idM600 };
              this.getAjusteContribuicaoCofins(parametro);
              this.getDetalhesContribuicaoCofins(parametro);
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que remove um registro relacionado a um ajuste de crédito PIS */
  removerAjustePis(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-ajuste-pis(' + event.id + ", " + this.tCurrentCnpj.toString() + ", " + this.tCurrentPeriodo.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              let parametro = { id: this.idCreditoPis };
              this.getAjusteCreditoPisPasep(parametro);
              this.getCreditoPisPasep();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que remove um registro relacionado a um ajuste de contribuição PIS */
  removerAjusteContribuicaoPis(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-ajuste-contribuicao-pis(' + event.id + ", " + this.tCurrentCnpj.toString() + ", " + this.tCurrentPeriodo.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              let parametro = { id: this.idM200 };
              this.getAjusteContribuicaoPisPasep(parametro);
              this.getDetalhesContribuicaoPisPasep(parametro);
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que remove um registro relacionado a um ajuste de crédito COFINS */
  removerAjusteCofins(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-ajuste-cofins(' + event.id + ", " + this.tCurrentCnpj.toString() + ", " + this.tCurrentPeriodo.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              let parametro = { id: this.idCreditoCofins };
              this.getAjusteCreditoCofins(parametro);
              this.getCreditoCofins();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que remove um registro relacionado a u ajuste de contribuição COFINS */
  removerAjusteContribuicaoCofins(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-ajuste-contribuicao-cofins(' + event.id + ", " + this.tCurrentCnpj.toString() + ", " + this.tCurrentPeriodo.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              let parametro = { id: this.idM600 };
              this.getAjusteContribuicaoCofins(parametro);
              this.getDetalhesContribuicaoCofins(parametro);
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que adiciona dados à tabela RegF100PisCofins */
  adicionarDadosF100() {
    console.log("Entra na função que adiciona dados à tabela F100PisCofins");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.tipoOperacaoF100 == null || this.tipoOperacaoF100 == undefined
      || this.tipoOperacaoF100 == "" || this.tipoOperacaoF100 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um tipo de operação." });
    } else if (this.vlOperacaoF100 == null || this.vlOperacaoF100 == undefined
      || this.vlOperacaoF100 == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor de operação." });
    } else if (this.dtOperacaoF100 == null || this.dtOperacaoF100 == undefined
      || this.dtOperacaoF100 == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione uma data de operação." });
    } else if (this.cstPisF100 == null || this.cstPisF100 == undefined
      || this.cstPisF100 == "" || this.cstPisF100 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor de CST PIS." });
    } else if (this.cstCofinsF100 == null || this.cstCofinsF100 == undefined
      || this.cstCofinsF100 == "" || this.cstCofinsF100 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor de CST COFINS." });
    } else {

      /* Realizar tratamento para os campos opcionais */
      if (this.codParticipanteF100 == null || this.codParticipanteF100 == undefined
        || this.codParticipanteF100 == "") {
        this.codParticipanteF100 = "";
      }

      if (this.codNaturezaBaseCreditoF100 == null || this.codNaturezaBaseCreditoF100 == undefined
        || this.codNaturezaBaseCreditoF100 == "" || this.codNaturezaBaseCreditoF100 == "Selecione") {
        this.codNaturezaBaseCreditoF100 = "";
      }

      if (this.indOrigCredF100 == null || this.indOrigCredF100 == undefined
        || this.indOrigCredF100 == "" || this.indOrigCredF100 == "Selecione") {
        this.indOrigCredF100 = "";
      }

      if (this.codItemF100 == null || this.codItemF100 == undefined
        || this.codItemF100 == "") {
        this.codItemF100 = "";
      }

      if (this.codContaContabilF100 == null || this.codContaContabilF100 == undefined
        || this.codContaContabilF100 == "") {
        this.codContaContabilF100 = "";
      }

      if (this.codCentroCustosF100 == null || this.codCentroCustosF100 == undefined
        || this.codCentroCustosF100 == "") {
        this.codCentroCustosF100 = "";
      }

      if (this.descDocF100 == null || this.descDocF100 == undefined
        || this.descDocF100 == "") {
        this.descDocF100 = "";
      }

      if (this.vlBcPisF100 == null || this.vlBcPisF100 == undefined
        || this.vlBcPisF100 == "") {
        this.vlBcPisF100 = "";
      }

      if (this.aliqPisF100 == null || this.aliqPisF100 == undefined
        || this.aliqPisF100 == "") {
        this.aliqPisF100 = "";
      }

      if (this.vlPisF100 == null || this.vlPisF100 == undefined
        || this.vlPisF100 == "") {
        this.vlPisF100 = "";
      }

      if (this.vlBcCofinsF100 == null || this.vlBcCofinsF100 == undefined
        || this.vlBcCofinsF100 == "") {
        this.vlBcCofinsF100 = "";
      }

      if (this.aliqCofinsF100 == null || this.aliqCofinsF100 == undefined
        || this.aliqCofinsF100 == "") {
        this.aliqCofinsF100 = "";
      }

      if (this.vlCofinsF100 == null || this.vlCofinsF100 == undefined
        || this.vlCofinsF100 == "") {
        this.vlCofinsF100 = "";
      }

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        tipoOperacao: this.tipoOperacaoF100,
        codNatureza: this.codNaturezaBaseCreditoF100,
        indOrigCred: this.indOrigCredF100,
        cstPis: this.cstPisF100,
        cstCofins: this.cstCofinsF100,
        codParticipante: this.codParticipanteF100,
        codItem: this.codItemF100,
        vlOperacao: this.vlOperacaoF100,
        dtOperacao: this.dtOperacaoF100,
        codContaContabil: this.codContaContabilF100,
        codCentroCusto: this.codCentroCustosF100,
        descDoc: this.descDocF100,
        vlBcPis: this.vlBcPisF100,
        aliqPis: this.aliqPisF100,
        vlPis: this.vlPisF100,
        vlBcCofins: this.vlBcCofinsF100,
        aliqCofins: this.aliqCofinsF100,
        vlCofins: this.vlCofinsF100,
        usuarioId: this.dblinkedSessionService.empresa.id.toString()
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-dados-f100', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              console.log("Adição de registro na tabela F100PisCofins executada com sucesso!");
              this.getDadosF100();
              this.modalF100 = false;
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }


  /* Função que faz a remoção de um registro da tabela RegF100PisCofins */
  removerDadosF100(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-dados-f100(' + event.id + ", " + this.tCurrentPeriodo.toString() + ", " + this.tCurrentCnpj.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.getDadosF100();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que realiza as somas e subtrações dos valores preenchidos no modal
  relacionado à tabela F120Piscofins */
  alterarValoresF120() {

    var field6, field7;

    if ((this.vlEncargoF120 == undefined) || (this.vlEncargoF120.toString() == "")) {
      field6 = 0.00;
    } else {
      field6 = parseFloat(this.vlEncargoF120.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.parcelasEncargoF120 == undefined) || (this.parcelasEncargoF120.toString() == "")) {
      field7 = 0.00;
    } else {
      field7 = parseFloat(this.parcelasEncargoF120.toString().replace(',', '.')).toFixed(2);
    }

    var somaAux = parseFloat(field6) - parseFloat(field7);
    this.vlBcPisF120 = somaAux.toFixed(2);
    this.vlBcCofinsF120 = somaAux.toFixed(2);

  }

  /* Função que adiciona dados à tabela RegF120PisCofins */
  adicionarDadosF120() {
    console.log("Entra na função que adiciona dados à tabela F120PisCofins");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.codNaturezaBaseCreditoF120 == null || this.codNaturezaBaseCreditoF120 == undefined
      || this.codNaturezaBaseCreditoF120 == "" || this.codNaturezaBaseCreditoF120 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um código para o cálculo da base do crédito." });
    } else if (this.identBensGrupoF120 == null || this.identBensGrupoF120 == undefined
      || this.identBensGrupoF120 == "" || this.identBensGrupoF120 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um código para a identificação dos bens." });
    } else if (this.indUtilBemF120 == null || this.indUtilBemF120 == undefined
      || this.indUtilBemF120 == "" || this.indUtilBemF120 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um indicador da utilização dos bens incorporados." });
    } else if (this.vlEncargoF120 == null || this.vlEncargoF120 == undefined
      || this.vlEncargoF120 == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor de encargo." });
    } else if (this.cstPisF120 == null || this.cstPisF120 == undefined
      || this.cstPisF120 == "" || this.cstPisF120 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor de CST PIS." });
    } else if (this.cstCofinsF120 == null || this.cstCofinsF120 == undefined
      || this.cstCofinsF120 == "" || this.cstCofinsF120 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor de CST COFINS." });
    } else {

      /* Realizar tratamento para os campos opcionais */
      if (this.parcelasEncargoF120 == null || this.parcelasEncargoF120 == undefined
        || this.parcelasEncargoF120 == "" || this.parcelasEncargoF120 <= 0) {
        this.parcelasEncargoF120 = "";
      }

      if (this.indOrigBemF120 == null || this.indOrigBemF120 == undefined
        || this.indOrigBemF120 == "" || this.indOrigBemF120 == "Selecione") {
        this.indOrigBemF120 = "";
      }

      if (this.codContaContabilF120 == null || this.codContaContabilF120 == undefined
        || this.codContaContabilF120 == "") {
        this.codContaContabilF120 = "";
      }

      if (this.codCentroCustosF120 == null || this.codCentroCustosF120 == undefined
        || this.codCentroCustosF120 == "") {
        this.codCentroCustosF120 = "";
      }

      if (this.descDocF120 == null || this.descDocF120 == undefined
        || this.descDocF120 == "") {
        this.descDocF120 = "";
      }

      if (this.vlBcPisF120 == null || this.vlBcPisF120 == undefined
        || this.vlBcPisF120 == "") {
        this.vlBcPisF120 = "";
      }

      if (this.aliqPisF120 == null || this.aliqPisF120 == undefined
        || this.aliqPisF120 == "") {
        this.aliqPisF120 = "";
      }

      if (this.vlPisF120 == null || this.vlPisF120 == undefined
        || this.vlPisF120 == "") {
        this.vlPisF120 = "";
      }

      if (this.vlBcCofinsF120 == null || this.vlBcCofinsF120 == undefined
        || this.vlBcCofinsF120 == "") {
        this.vlBcCofinsF120 = "";
      }

      if (this.aliqCofinsF120 == null || this.aliqCofinsF120 == undefined
        || this.aliqCofinsF120 == "") {
        this.aliqCofinsF120 = "";
      }

      if (this.vlCofinsF120 == null || this.vlCofinsF120 == undefined
        || this.vlCofinsF120 == "") {
        this.vlCofinsF120 = "";
      }

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        identBensGrupo: this.identBensGrupoF120,
        codNatureza: this.codNaturezaBaseCreditoF120,
        indUtilBem: this.indUtilBemF120,
        cstPis: this.cstPisF120,
        cstCofins: this.cstCofinsF120,
        parcelasEncargo: this.parcelasEncargoF120,
        indOrigBem: this.indOrigBemF120,
        vlEncargo: this.vlEncargoF120,
        codContaContabil: this.codContaContabilF120,
        codCentroCusto: this.codCentroCustosF120,
        descDoc: this.descDocF120,
        vlBcPis: this.vlBcPisF120,
        aliqPis: this.aliqPisF120,
        vlPis: this.vlPisF120,
        vlBcCofins: this.vlBcCofinsF120,
        aliqCofins: this.aliqCofinsF120,
        vlCofins: this.vlCofinsF120,
        usuarioId: this.dblinkedSessionService.empresa.id.toString()
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-dados-f120', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              console.log("Adição de registro na tabela F120PisCofins executada com sucesso!");
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.getDadosF120();
              this.modalF120 = false;
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }


  /* Função que faz a remoção de um registro da tabela RegF120PisCofins */
  removerDadosF120(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-dados-f120(' + event.id + ", " + this.tCurrentPeriodo.toString() + ", " + this.tCurrentCnpj.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.getDadosF120();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que realiza as somas e subtrações dos valores preenchidos no modal
  relacionado à tabela F130Piscofins */
  alterarValoresF130() {

    var field7, field8;

    if ((this.vlAquisicaoF130 == undefined) || (this.vlAquisicaoF130.toString() == "")) {
      field7 = 0.00;
    } else {
      field7 = parseFloat(this.vlAquisicaoF130.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.parcelasAquisicaoF130 == undefined) || (this.parcelasAquisicaoF130.toString() == "")) {
      field8 = 0.00;
    } else {
      field8 = parseFloat(this.parcelasAquisicaoF130.toString().replace(',', '.')).toFixed(2);
    }

    var somaAux = parseFloat(field7) - parseFloat(field8);
    this.vlBaseCalcCredF130 = somaAux.toFixed(2);

  }


  /* Função que adiciona dados à tabela RegF130PisCofins */
  adicionarDadosF130() {
    console.log("Entra na função que adiciona dados à tabela F130PisCofins");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.identBensGrupoF130 == null || this.identBensGrupoF130 == undefined
      || this.identBensGrupoF130 == "" || this.identBensGrupoF130 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um código para a identificação dos bens." });
    } else if (this.indUtilBemF130 == null || this.indUtilBemF130 == undefined
      || this.indUtilBemF130 == "" || this.indUtilBemF130 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um indicador da utilização dos bens incorporados." });
    } else if (this.vlAquisicaoF130 == null || this.vlAquisicaoF130 == undefined
      || this.vlAquisicaoF130 == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor de aquisição." });
    } else if (this.indNumParcF130 == null || this.indNumParcF130 == undefined
      || this.indNumParcF130 == "" || this.indNumParcF130 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um indicador de número de parcelas a serem apropriadas." });
    } else if (this.vlBaseCalcCredF130 == null || this.vlBaseCalcCredF130 == undefined
      || this.vlBaseCalcCredF130 == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor de base de crédito." });
    } else if (this.cstPisF130 == null || this.cstPisF130 == undefined
      || this.cstPisF130 == "" || this.cstPisF130 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor de CST PIS." });
    } else if (this.cstCofinsF130 == null || this.cstCofinsF130 == undefined
      || this.cstCofinsF130 == "" || this.cstCofinsF130 == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um valor de CST COFINS." });
    } else {

      /* Realizar tratamento para os campos opcionais */
      if (this.parcelasAquisicaoF130 == null || this.parcelasAquisicaoF130 == undefined
        || this.parcelasAquisicaoF130 == "" || this.parcelasAquisicaoF130 <= 0) {
        this.parcelasAquisicaoF130 = "";
      }

      if (this.indOrigBemF130 == null || this.indOrigBemF130 == undefined
        || this.indOrigBemF130 == "" || this.indOrigBemF130 == "Selecione") {
        this.indOrigBemF130 = "";
      }

      if (this.codContaContabilF130 == null || this.codContaContabilF130 == undefined
        || this.codContaContabilF130 == "") {
        this.codContaContabilF130 = "";
      }

      if (this.codCentroCustosF130 == null || this.codCentroCustosF130 == undefined
        || this.codCentroCustosF130 == "") {
        this.codCentroCustosF130 = "";
      }

      if (this.mesAnoAquisicaoF130 == null || this.mesAnoAquisicaoF130 == undefined
        || this.mesAnoAquisicaoF130 == "") {
        this.mesAnoAquisicaoF130 = "";
      }

      if (this.descDocF130 == null || this.descDocF130 == undefined
        || this.descDocF130 == "") {
        this.descDocF130 = "";
      }

      if (this.vlBcPisF130 == null || this.vlBcPisF130 == undefined
        || this.vlBcPisF130 == "") {
        this.vlBcPisF130 = "";
      }

      if (this.aliqPisF130 == null || this.aliqPisF130 == undefined
        || this.aliqPisF130 == "") {
        this.aliqPisF130 = "";
      }

      if (this.vlPisF130 == null || this.vlPisF130 == undefined
        || this.vlPisF130 == "") {
        this.vlPisF130 = "";
      }

      if (this.vlBcCofinsF130 == null || this.vlBcCofinsF130 == undefined
        || this.vlBcCofinsF130 == "") {
        this.vlBcCofinsF130 = "";
      }

      if (this.aliqCofinsF130 == null || this.aliqCofinsF130 == undefined
        || this.aliqCofinsF130 == "") {
        this.aliqCofinsF130 = "";
      }

      if (this.vlCofinsF130 == null || this.vlCofinsF130 == undefined
        || this.vlCofinsF130 == "") {
        this.vlCofinsF130 = "";
      }

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        identBensGrupo: this.identBensGrupoF130,
        codNatureza: this.codNaturezaBaseCreditoF130,
        indUtilBem: this.indUtilBemF130,
        cstPis: this.cstPisF130,
        cstCofins: this.cstCofinsF130,
        indNumParc: this.indNumParcF130,
        parcelasAquisicao: this.parcelasAquisicaoF130,
        indOrigBem: this.indOrigBemF130,
        vlAquisicao: this.vlAquisicaoF130,
        vlBaseCalcCred: this.vlBaseCalcCredF130,
        codContaContabil: this.codContaContabilF130,
        codCentroCusto: this.codCentroCustosF130,
        descDoc: this.descDocF130,
        vlBcPis: this.vlBcPisF130,
        aliqPis: this.aliqPisF130,
        vlPis: this.vlPisF130,
        vlBcCofins: this.vlBcCofinsF130,
        aliqCofins: this.aliqCofinsF130,
        vlCofins: this.vlCofinsF130,
        mesAnoAquisicaoF130: this.mesAnoAquisicaoF130,
        usuarioId: this.dblinkedSessionService.empresa.id.toString()
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-dados-f130', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              console.log("Adição de registro na tabela F130PisCofins executada com sucesso!");
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.getDadosF130();
              this.modalF130 = false;
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }


  /* Função que faz a remoção de um registro da tabela RegF130PisCofins */
  removerDadosF130(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-dados-f130(' + event.id + ", " + this.tCurrentPeriodo.toString() + ", " + this.tCurrentCnpj.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.getDadosF130();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  hideMessage() {
  }


  /* Função que abre o modal da Central de Edição. */
  openModalCentralEdicao() {

    if (this.tCurrentTipo == "PIS/COFINS") {
      this.modalCentralEdicao = true;
      this.modalTabelaFilha = false;

      this.centralEdicaoEscolhido = 'Selecione';
      this.tDadosCentralEdicao = [];
    }
  }

  /* Função que obtém os dados a serem exibidos no Modal da Central de Edição */
  buscarDadosCentralEdicao() {
    console.log("Entra na função de buscar dados para a Central de Edição");

    if (this.centralEdicaoEscolhido != "Selecione") {

      /* Verificar de qual tabela os dados deverão ser obtidos */
      if (this.centralEdicaoEscolhido == "0150 - TABELA DE CADASTRO DO PARTICIPANTE") {
        this.tabelaPaiCentralEdicao = false;
        this.tabelaBusca = "reg_0150_piscofins";
        this.colunasCentralEdicao = ["id", "reg", "cod_part", "nome", "cod_pais", "cnpj", "cpf", "ie", "cod_mun",
          "suframa", "endereco", "num", "compl", "bairro", "id_0140", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      } else if (this.centralEdicaoEscolhido == '0200 - TABELA DE IDENTIFICAÇÃO DO ITEM (PRODUTOS E SERVIÇOS)') {
        this.tabelaPaiCentralEdicao = false;
        this.tabelaBusca = "reg_0200_piscofins";
        this.colunasCentralEdicao = ["id", "reg", "cod_item", "descr_item", "cod_barra", "cod_ant_item", "unid_inv", "tipo_item", "cod_ncm",
          "ex_ipi", "cod_gen", "cod_lst", "aliq_icms", "id_0140", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      } else if (this.centralEdicaoEscolhido == 'A100 - DOCUMENTO - NOTA FISCAL DE SERVIÇO') {
        this.tabelaPaiCentralEdicao = true;
        this.tabelaBusca = "reg_a100_piscofins";
        this.colunasCentralEdicao = ["id", "reg", "ind_oper", "ind_emit", "cod_part", "cod_sit", "ser", "sub", "num_doc",
          "chv_nfse", "dt_doc", "dt_exe_serv", "vl_doc", "ind_pgto", "vl_desc", "vl_bc_pis", "vl_pis", "vl_bc_cofins", "vl_cofins",
          "vl_pis_ret", "vl_cofins_ret", "vl_iss",
          "id_a010", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      } else if (this.centralEdicaoEscolhido == 'C100 - DOCUMENTO - NOTA FISCAL') {
        this.tabelaPaiCentralEdicao = true;
        this.tabelaBusca = "reg_c100_piscofins";
        this.colunasCentralEdicao = ["id", "reg", "ind_oper", "ind_emit", "cod_part", "cod_mod", "cod_sit", "ser", "num_doc",
          "chv_nfe", "dt_doc", "dt_e_s", "vl_doc", "ind_pgto", "vl_desc", "vl_abat_nt", "vl_merc", "ind_frt", "vl_frt",
          "vl_seg", "vl_out_da", "vl_bc_icms", "vl_icms", "vl_bc_icms_st", "vl_icms_st", "vl_ipi", "vl_pis", "vl_cofins", "vl_pis_st", "vl_cofins_st",
          "id_c010", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      } else if (this.centralEdicaoEscolhido == 'C500 - NOTA FISCAL/CONTA DE ENERGIA ELÉTRICA') {
        this.tabelaPaiCentralEdicao = true;
        this.tabelaBusca = "reg_c500_piscofins";
        this.colunasCentralEdicao = ["id", "reg", "cod_part", "cod_mod", "cod_sit", "ser", "sub", "num_doc",
          "dt_doc", "dt_e_s", "vl_doc", "ind_pgto", "vl_icms", "cod_inf", "vl_pis", "vl_cofins",
          "id_c010", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      } else if (this.centralEdicaoEscolhido == 'D100 - AQUISIÇÃO DE SERVIÇOS DE TRANSPORTE') {
        this.tabelaPaiCentralEdicao = true;
        this.tabelaBusca = "reg_d100_piscofins";
        this.colunasCentralEdicao = ["id", "reg", "ind_oper", "ind_emit", "cod_part", "cod_mod", "cod_sit", "ser", "sub", "num_doc",
          "chv_cte", "dt_doc", "dt_a_p", "tp_cte", "chv_cte_ref", "vl_doc", "vl_desc", "ind_frt", "vl_serv", "vl_bc_icms", "vl_icms",
          "vl_nt", "cod_inf", "cod_cta", "id_d010", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      } else if (this.centralEdicaoEscolhido == '1100 - CONTROLE DE CRÉDITOS FISCAIS – PIS/PASEP') {
        this.tabelaPaiCentralEdicao = false;
        this.tabelaBusca = "reg_1100_piscofins";
        this.colunasCentralEdicao = ["id", "reg", "per_apu_cred", "orig_cred", "cnpj_suc", "cod_cred", "vl_cred_apu", "vl_cred_ext_apu", "vl_tot_cred_apu",
          "vl_cred_desc_pa_ant", "vl_cred_per_pa_ant", "vl_cred_dcomp_pa_ant", "sd_cred_disp_efd", "vl_cred_desc_efd", "vl_cred_per_efd", "vl_cred_dcomp_efd",
          "vl_cred_trans", "vl_cred_out", "sld_cred_fim",
          "id_1001", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      } else if (this.centralEdicaoEscolhido == '1500 - CONTROLE DE CRÉDITOS FISCAIS – COFINS') {
        this.tabelaPaiCentralEdicao = false;
        this.tabelaBusca = "reg_1500_piscofins";
        this.colunasCentralEdicao = ["id", "reg", "per_apu_cred", "orig_cred", "cnpj_suc", "cod_cred", "vl_cred_apu", "vl_cred_ext_apu", "vl_tot_cred_apu",
          "vl_cred_desc_pa_ant", "vl_cred_per_pa_ant", "vl_cred_dcomp_pa_ant", "sd_cred_disp_efd", "vl_cred_desc_efd", "vl_cred_per_efd", "vl_cred_dcomp_efd",
          "vl_cred_trans", "vl_cred_out", "sld_cred_fim",
          "id_1001", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      } else if (this.centralEdicaoEscolhido == '1300 - CONTROLE DOS VALORES RETIDOS NA FONTE – PIS/PASEP') {
        this.tabelaPaiCentralEdicao = false;
        this.tabelaBusca = "reg_1300_piscofins";
        this.colunasCentralEdicao = ["id", "reg", "ind_nat_ret", "pr_rec_ret", "vl_ret_apu", "vl_ret_ded", "vl_ret_per",
          "vl_ret_dcomp", "sld_ret",
          "id_1001", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      } else if (this.centralEdicaoEscolhido == '1700 - CONTROLE DOS VALORES RETIDOS NA FONTE – COFINS') {
        this.tabelaPaiCentralEdicao = false;
        this.tabelaBusca = "reg_1700_piscofins";
        this.colunasCentralEdicao = ["id", "reg", "ind_nat_ret", "pr_rec_ret", "vl_ret_apu", "vl_ret_ded", "vl_ret_per",
          "vl_ret_dcomp", "sld_ret",
          "id_1001", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      } else if (this.centralEdicaoEscolhido == 'C400 - EQUIPAMENTO ECF') {
        this.tabelaPaiCentralEdicao = false;
        this.tabelaBusca = "reg_c400_piscofins";
        this.colunasCentralEdicao = ["id", "reg", "cod_mod", "ecf_mod", "ecf_fab", "ecf_cx",
          "id_c010", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      }
      console.log(this.centralEdicaoEscolhido);
      console.log(this.tabelaBusca);

      let dados = {
        usuarioId: this.sessionService.loggedUser.id,
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        tabelaBusca: this.tabelaBusca,
      }

      let dadosRetornados = [];
      var dict = {}; // create an empty array
      this.httpService.wait();
      this.httpService.post('/custom/dbl/get-dados-central-edicao', dados)
        .subscribe(
          res => {
            console.log("Obtenção de dados para Central de Edição executada com sucesso!");
            console.log(res);

            for (let line of res) {
              var aux = line["values"];
              for (let i = 0; i < this.colunasCentralEdicao.length; i++) {

                // Algumas colunas não serão exibidas no Modal, logo, ignorá-las.
                if ((this.colunasCentralEdicao[i].toString() != "reg") &&
                  (this.colunasCentralEdicao[i].toString() != "cnpj_aud") &&
                  (this.colunasCentralEdicao[i].toString() != "periodo") &&
                  (this.colunasCentralEdicao[i].toString() != "dt_hr_inclusao") &&
                  (this.colunasCentralEdicao[i].toString().substr(0, 3) != "id_")) {

                  // Em caso de um valor nulo ou inexistente, preencher a célula com "-"
                  if (aux[i] == null || aux[i] == undefined || aux[i] == "") {
                    dict[this.colunasCentralEdicao[i].toString()] = "-";
                  } else {

                    if (this.colunasCentralEdicao[i].toString().substr(0, 3) == "dt_") {
                      var formattedData = new Date(parseFloat(aux[i]));

                      let month = String(formattedData.getMonth() + 1);
                      let day = String(formattedData.getDate());
                      const year = String(formattedData.getFullYear());

                      if (month.length < 2) month = '0' + month;
                      if (day.length < 2) day = '0' + day;

                      dict[this.colunasCentralEdicao[i].toString()] = day + '/' + month + '/' + year;
                    } else {
                      dict[this.colunasCentralEdicao[i].toString()] = aux[i];
                    }
                  }
                }
              }

              dadosRetornados.push(dict);
              dict = {};
            }

            this.tDadosCentralEdicao = dadosRetornados;
            console.log(this.tDadosCentralEdicao);

            /* Ao final do processo, deve-se remover os nomes das colunas não usadas no Modal.
            Neste caso, trata-se das duas primeiras colunas e das quatro últimas. */
            this.colunasCentralEdicao.shift();
            this.colunasCentralEdicao.shift();
            this.colunasCentralEdicao.pop();
            this.colunasCentralEdicao.pop();
            this.colunasCentralEdicao.pop();
            this.colunasCentralEdicao.pop();

            this.httpService.done();

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que busca os dados de uma tabela-filha para a Central de Edição */
  buscarDadosTabelaFilhaCentralEdicao(event: any) {
    this.abrirModalTabelaFilha(event);

    this.idSelecionadoCentralEdicao = event.id;

    var nomeTabelaPai = "";
    var nomeColunaFk = "";

    if (this.centralEdicaoEscolhido == 'A100 - DOCUMENTO - NOTA FISCAL DE SERVIÇO') {
      this.tabelaFilha = "reg_a170_piscofins";
      this.colunasTabelaFilha = ["id", "reg", "num_item", "cod_item", "descr_compl", "vl_item", "vl_desc", "nat_bc_cred", "ind_orig_cred",
        "cst_pis", "vl_bc_pis", "aliq_pis", "vl_pis", "cst_cofins", "vl_bc_cofins", "aliq_cofins", "vl_cofins", "cod_cta", "cod_ccus",
        "id_a100", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      nomeTabelaPai = "reg_a100_piscofins";
      nomeColunaFk = "id_a100";
    } else if (this.centralEdicaoEscolhido == 'C100 - DOCUMENTO - NOTA FISCAL') {
      this.tabelaFilha = "reg_c170_piscofins";
      this.colunasTabelaFilha = ["id", "reg", "num_item", "cod_item", "descr_compl", "qtd", "unid", "vl_item", "vl_desc", "ind_mov", "cst_icms",
        "cfop", "cod_nat", "vl_bc_icms", "aliq_icms", "vl_icms", "vl_bc_icms_st", "aliq_st", "vl_icms_st", "ind_apur", "cst_ipi",
        "cod_enq", "vl_bc_ipi", "aliq_ipi", 'vl_ipi', "cst_pis", "vl_bc_pis", "aliq_pis", "quant_bc_pis", "aliq_pis_quant",
        "vl_pis", "cst_cofins", "vl_bc_cofins", "aliq_cofins", "quant_bc_cofins", "aliq_cofins_quant",
        "vl_cofins", "cod_cta",
        "id_c100", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      nomeTabelaPai = "reg_c100_piscofins";
      nomeColunaFk = "id_c100";
    } else if (this.centralEdicaoEscolhido == 'C500 - NOTA FISCAL/CONTA DE ENERGIA ELÉTRICA') {
      this.tabelaFilha = "reg_c501_piscofins";
      this.colunasTabelaFilha = ["id", "reg", "cst_pis", "vl_item", "nat_bc_cred", "vl_bc_pis", "aliq_pis",
        "vl_pis", "cod_cta",
        "id_c500", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      nomeTabelaPai = "reg_c500_piscofins";
      nomeColunaFk = "id_c500";
    } else if (this.centralEdicaoEscolhido == 'D100 - AQUISIÇÃO DE SERVIÇOS DE TRANSPORTE') {
      this.tabelaFilha = "reg_d101_piscofins";
      this.colunasTabelaFilha = ["id", "reg", "ind_nat_frt", "vl_item", "cst_pis", "nat_bc_cred", "vl_bc_pis", "aliq_pis",
        "vl_pis", "cod_cta",
        "id_d100", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      nomeTabelaPai = "reg_d100_piscofins";
      nomeColunaFk = "id_d100";
    }

    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      tabelaBusca: this.tabelaFilha,
      fk: this.idSelecionadoCentralEdicao,
      nomeTabelaPai: nomeTabelaPai,
      nomeColunaFk: nomeColunaFk
    }

    let dadosRetornados = [];
    var dict = {}; // create an empty array
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-dados-tabela-filha-central-edicao', dados)
      .subscribe(
        res => {
          console.log("Obtenção de dados para uma tabela-filha em Central de Edição executada com sucesso!");
          console.log(res);

          for (let line of res) {
            var aux = line["values"];
            for (let i = 0; i < this.colunasTabelaFilha.length; i++) {

              // Algumas colunas não serão exibidas no Modal, logo, ignorá-las.
              if ((this.colunasTabelaFilha[i].toString() != "cnpj_aud") &&
                (this.colunasTabelaFilha[i].toString() != "periodo") &&
                (this.colunasTabelaFilha[i].toString() != "dt_hr_inclusao") &&
                (this.colunasTabelaFilha[i].toString().substr(0, 3) != "id_")) {

                // Em caso de um valor nulo ou inexistente, preencher a célula com "-"
                if (aux[i] == null || aux[i] == undefined || aux[i] == "") {
                  dict[this.colunasTabelaFilha[i].toString()] = "-";
                } else {

                  if (this.colunasTabelaFilha[i].toString().substr(0, 3) == "dt_") {
                    var formattedData = new Date(parseFloat(aux[i]));

                    let month = String(formattedData.getMonth() + 1);
                    let day = String(formattedData.getDate());
                    const year = String(formattedData.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;

                    dict[this.colunasTabelaFilha[i].toString()] = day + '/' + month + '/' + year;
                  } else {
                    dict[this.colunasTabelaFilha[i].toString()] = aux[i];
                  }
                }
              }
            }

            dadosRetornados.push(dict);
            dict = {};
          }

          this.dadosTabelaFilha = dadosRetornados;
          console.log(this.dadosTabelaFilha);

          /* Ao final do processo, deve-se remover os nomes das colunas não usadas no Modal.
          Neste caso, trata-se da primeira coluna e das quatro últimas. */
          this.colunasTabelaFilha.shift();
          this.colunasTabelaFilha.pop();
          this.colunasTabelaFilha.pop();
          this.colunasTabelaFilha.pop();
          this.colunasTabelaFilha.pop();

          this.httpService.done();

          /* Verificar se existe uma segunda tabela-filha a ser carregada.
          Em caso afirmativo, chamar o método correspondente. */
          if ((this.tabelaFilha === "reg_c501_piscofins")
            || (this.tabelaFilha === "reg_d101_piscofins")) {
            this.buscarDadosTabelaFilha2CentralEdicao();
          }

        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que busca os dados de uma segunda tabela-filha para a Central de Edição */
  buscarDadosTabelaFilha2CentralEdicao() {

    var nomeTabelaPai;
    var nomeColunaFk;

    if (this.centralEdicaoEscolhido == 'C500 - NOTA FISCAL/CONTA DE ENERGIA ELÉTRICA') {
      this.tabelaFilha2 = "reg_c505_piscofins";
      this.colunasTabelaFilha2 = ["id", "reg", "cst_cofins", "vl_item", "nat_bc_cred", "vl_bc_cofins", "aliq_cofins",
        "vl_cofins", "cod_cta",
        "id_c500", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      nomeTabelaPai = "reg_c500_piscofins";
      nomeColunaFk = "id_c500";
    } else if (this.centralEdicaoEscolhido == 'D100 - AQUISIÇÃO DE SERVIÇOS DE TRANSPORTE') {
      this.tabelaFilha2 = "reg_d105_piscofins";
      this.colunasTabelaFilha2 = ["id", "reg", "ind_nat_frt", "vl_item", "cst_cofins", "nat_bc_cred", "vl_bc_cofins", "aliq_cofins",
        "vl_cofins", "cod_cta",
        "id_d100", "cnpj_aud", "periodo", "dt_hr_inclusao"];
      nomeTabelaPai = "reg_d100_piscofins";
      nomeColunaFk = "id_d100";
    }

    let dados = {
      usuarioId: this.sessionService.loggedUser.id,
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      tabelaBusca: this.tabelaFilha2,
      fk: this.idSelecionadoCentralEdicao,
      nomeTabelaPai: nomeTabelaPai,
      nomeColunaFk: nomeColunaFk
    }

    let dadosRetornados = [];
    var dict = {}; // create an empty array
    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-dados-tabela-filha-central-edicao', dados)
      .subscribe(
        res => {
          console.log("Obtenção de dados para a tabela-filha 2 em Central de Edição executada com sucesso!");
          console.log(res);

          for (let line of res) {
            var aux = line["values"];
            for (let i = 0; i < this.colunasTabelaFilha2.length; i++) {

              // Algumas colunas não serão exibidas no Modal, logo, ignorá-las.
              if ((this.colunasTabelaFilha2[i].toString() != "cnpj_aud") &&
                (this.colunasTabelaFilha2[i].toString() != "periodo") &&
                (this.colunasTabelaFilha2[i].toString() != "dt_hr_inclusao") &&
                (this.colunasTabelaFilha2[i].toString().substr(0, 3) != "id_")) {

                // Em caso de um valor nulo ou inexistente, preencher a célula com "-"
                if (aux[i] == null || aux[i] == undefined || aux[i] == "") {
                  dict[this.colunasTabelaFilha2[i].toString()] = "-";
                } else {

                  if (this.colunasTabelaFilha2[i].toString().substr(0, 3) == "dt_") {
                    var formattedData = new Date(parseFloat(aux[i]));

                    let month = String(formattedData.getMonth() + 1);
                    let day = String(formattedData.getDate());
                    const year = String(formattedData.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;

                    dict[this.colunasTabelaFilha2[i].toString()] = day + '/' + month + '/' + year;
                  } else {
                    dict[this.colunasTabelaFilha2[i].toString()] = aux[i];
                  }
                }
              }
            }

            dadosRetornados.push(dict);
            dict = {};
          }

          this.dadosTabelaFilha2 = dadosRetornados;
          console.log(this.dadosTabelaFilha2);

          /* Ao final do processo, deve-se remover os nomes das colunas não usadas no Modal.
          Neste caso, trata-se da primeira coluna e das quatro últimas. */
          this.colunasTabelaFilha2.shift();
          this.colunasTabelaFilha2.pop();
          this.colunasTabelaFilha2.pop();
          this.colunasTabelaFilha2.pop();
          this.colunasTabelaFilha2.pop();

          this.httpService.done();

        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que altera, no Banco de Dados,
  um registro selecionado na Central de Edição */
  salvarAlteracoesCentralEdicao(event: any, tipoTabela) {

    var dados;
    if (tipoTabela == "principal") {
      dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        dados: event,
        tabelaBusca: this.tabelaBusca,
      }
    } else if (tipoTabela == "filha") {
      dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        dados: event,
        tabelaBusca: this.tabelaFilha,
      }
    } else if (tipoTabela == "filha2") {
      dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        dados: event,
        tabelaBusca: this.tabelaFilha2,
      }
    }

    if (confirm('Deseja modificar esse registro no Banco de Dados?')) {
      this.httpService.wait();
      this.httpService.post('/custom/dbl/modificar-dados-central-edicao', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              if (tipoTabela === "principal") {
                this.buscarDadosCentralEdicao();
              } else if ((tipoTabela === "filha") || (tipoTabela === "filha2")) {
                this.buscarDadosTabelaFilhaCentralEdicao({ id: this.idSelecionadoCentralEdicao });
              }
              this.httpService.done();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Não foi possível modificar o registro a partir da Central de Edição." });
            }
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }

  }


  /* Função que remove um registro selecionado na Central de Edição */
  removerRegistroCentralEdicao(event: any, tipoTabela) {

    var dados;

    if (tipoTabela == "principal") {
      dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        id: event.id,
        tabelaBusca: this.tabelaBusca,
      }
    } else if (tipoTabela == "filha") {
      dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        id: event.id,
        tabelaBusca: this.tabelaFilha,
      }
    } else if (tipoTabela == "filha2") {
      dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        id: event.id,
        tabelaBusca: this.tabelaFilha2,
      }
    }

    if (confirm('Deseja deletar esse registro?')) {
      this.httpService.wait();
      this.httpService.post('/custom/dbl/remover-dados-central-edicao', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              if (tipoTabela === "principal") {
                this.buscarDadosCentralEdicao();
              } else if ((tipoTabela === "filha") || (tipoTabela === "filha2")) {
                this.buscarDadosTabelaFilhaCentralEdicao({ id: this.idSelecionadoCentralEdicao });
              }
              this.httpService.done();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: "Não foi possível remover o registro a partir da Central de Edição." });
            }
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Abrir o Modal para editar um dado contido na Central de Edição */
  abrirModalCentralEdicao(event: any, col, tipoTabela) {

    this.modalEditarDadoCentralEdicao = true;
    this.novoValorCentralEdicao = undefined;

    /* Verificar se a alteração do dado ocorrerá em uma tabela principal ou em uma tabela-filha */
    if (tipoTabela == 'principal') {
      this.tipoTabelaSelecionadaCentralEdicao = "principal";
      this.colunaSelecionadaCentralEdicao = col;
      this.idSelecionadoCentralEdicao = event["id"];
    } else if (tipoTabela == "filha") {
      this.tipoTabelaSelecionadaCentralEdicao = "filha";
      this.colunaSelecionadaTabelaFilha = col;
      this.idSelecionadoTabelaFilha = event["id"];
    } else if (tipoTabela == "filha2") {
      this.tipoTabelaSelecionadaCentralEdicao = "filha2";
      this.colunaSelecionadaTabelaFilha2 = col;
      this.idSelecionadoTabelaFilha2 = event["id"];
    }
  }

  /* Função que altera o valor de um item selecionado na Central de Edição */
  atualizarDadoCentralEdicao() {

    var i = 0;
    var found = false;

    /* Caso o dado digitado pelo usuário seja "" (vazio), atribuir um "-" à Central de Edição */
    if (this.novoValorCentralEdicao == null || this.novoValorCentralEdicao == undefined
      || this.novoValorCentralEdicao == "") {
      this.novoValorCentralEdicao = "-";
    }

    /* Verificar se a alteração de valor ocorrerá para uma tabela principal ou para uma tabela filha */
    if (this.tipoTabelaSelecionadaCentralEdicao === "principal") {
      while (found == false) {
        var linha = this.tDadosCentralEdicao[i];
        if (linha["id"] === this.idSelecionadoCentralEdicao) {
          this.tDadosCentralEdicao[i][this.colunaSelecionadaCentralEdicao] = this.novoValorCentralEdicao;
          found = true;
          this.modalEditarDadoCentralEdicao = false;
        }
        i = i + 1;
      }
    } else if (this.tipoTabelaSelecionadaCentralEdicao === "filha") {
      while (found == false) {
        var linha = this.dadosTabelaFilha[i];
        if (linha["id"] === this.idSelecionadoTabelaFilha) {
          this.dadosTabelaFilha[i][this.colunaSelecionadaTabelaFilha] = this.novoValorCentralEdicao;
          found = true;
          this.modalEditarDadoCentralEdicao = false;
        }
        i = i + 1;
      }
    } else if (this.tipoTabelaSelecionadaCentralEdicao === "filha2") {
      while (found == false) {
        var linha = this.dadosTabelaFilha2[i];
        if (linha["id"] === this.idSelecionadoTabelaFilha2) {
          this.dadosTabelaFilha2[i][this.colunaSelecionadaTabelaFilha2] = this.novoValorCentralEdicao;
          found = true;
          this.modalEditarDadoCentralEdicao = false;
        }
        i = i + 1;
      }
    }
  }

  /* Abrir o Modal para uma tabela-filha da selecionada na Central de Edição */
  abrirModalTabelaFilha(event: any) {
    this.modalTabelaFilha = true;
    this.dadosTabelaFilha = [];
    this.dadosTabelaFilha2 = [];

    /* Verificar se esse Modal terá 2 tabelas-filhas ou não */
    if ((this.tabelaBusca === "reg_c500_piscofins")
      || (this.tabelaBusca === "reg_d100_piscofins")) {
      this.containTabelaFilha2 = true;
    } else {
      this.containTabelaFilha2 = false;
    }
  }

  adicionarContribuicaoRetidaFonte() {
    console.log("Entra na função que adiciona dados à contribuição retida na fonte.");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.naturezaRetFonteEscolhido == null || this.naturezaRetFonteEscolhido == undefined
      || this.naturezaRetFonteEscolhido == "" || this.naturezaRetFonteEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um Indicador de Natureza da Retenção na Fonte." });
    } else if (this.vlBaseCalcCredF600 == null || this.vlBaseCalcCredF600 == undefined
      || this.vlBaseCalcCredF600 == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione uma base de cálculo da retenção." });
    } else if (this.vlTotalRetF600 == null || this.vlTotalRetF600 == undefined
      || this.vlTotalRetF600 == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o valor total retido na fonte." });
    } else if (this.dtF600 == null || this.dtF600 == undefined
      || this.dtF600 == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione uma data de retenção." });
    } else if (this.cnpjFontePagadora == null || this.cnpjFontePagadora == undefined
      || this.cnpjFontePagadora == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o CNPJ da fonte pagadora." });
    } else if (this.indicadorCondJuridicaEscolhido == null || this.indicadorCondJuridicaEscolhido == undefined
      || this.indicadorCondJuridicaEscolhido == "" || this.indicadorCondJuridicaEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um indicador da condição da pessoa jurídica." });
    } else if (this.parcPis == null || this.parcPis == undefined
      || this.parcPis == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o valor da parcela de PIS." });
    } else if (this.parcCofins == null || this.parcCofins == undefined
      || this.parcCofins == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o valor da parcela de COFINS." });
    } else if (this.codReceitaF600 != null && this.codReceitaF600 != undefined
      && this.codReceitaF600 != "" && this.codReceitaF600.length > 4) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "O código da receita deve possuir no máximo 4 dígitos." });
    } else {

      /* Realizar tratamento para os campos opcionais */
      if (this.codReceitaF600 == null || this.codReceitaF600 == undefined
        || this.codReceitaF600 == "") {
        this.codReceitaF600 = "";
      }

      if (this.naturezaReceitaEscolhido == null || this.naturezaReceitaEscolhido == undefined
        || this.naturezaReceitaEscolhido == "" || this.naturezaReceitaEscolhido == "Selecione") {
        this.naturezaReceitaEscolhido = "";
      }

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        naturezaRetFonte: this.naturezaRetFonteEscolhido,
        indicadorCondJuridica: this.indicadorCondJuridicaEscolhido,
        vlBaseCalcCredF600: this.vlBaseCalcCredF600,
        vlTotalRetF600: this.vlTotalRetF600,
        cnpjFontePagadora: this.cnpjFontePagadora,
        codReceitaF600: this.codReceitaF600,
        naturezaReceitaEscolhido: this.naturezaReceitaEscolhido,
        dtOperacao: this.dtF600,
        parcPis: this.parcPis,
        parcCofins: this.parcCofins,
        usuarioId: this.dblinkedSessionService.empresa.id.toString()
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-dados-f600', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              console.log("Adição de contribuição retida na fonte executada com sucesso!");
              this.getContribuicoesRetidasFonte();
              this.modalContribuicaoRetidaFonte = false;
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que faz a remoção de um registro da tabela RegF600PisCofins
  (contribuição retida na fonte) */
  removerDadosF600(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-dados-f600(' + event.id + ", " + this.tCurrentPeriodo.toString() + ", " + this.tCurrentCnpj.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.getContribuicoesRetidasFonte();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que realiza as somas e subtrações dos valores preenchidos no modal
  de Controle dos Valores Retidos na Fonte (Reg1300Piscofins e Reg1700Piscofins) */
  alterarValoresControleRetidoFonte() {

    console.log("Entra na função alterarValoresControleRetidoFonte()");

    var field4, field5, field6, field7;

    if ((this.vlTotalControleRetFonte == undefined) || (this.vlTotalControleRetFonte.toString() == "")) {
      field4 = 0.00;
    } else {
      field4 = parseFloat(this.vlTotalControleRetFonte.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.vlDeduzidoContribuicaoControleRetFonte == undefined) || (this.vlDeduzidoContribuicaoControleRetFonte.toString() == "")) {
      field5 = 0.00;
    } else {
      field5 = parseFloat(this.vlDeduzidoContribuicaoControleRetFonte.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.vlPedRessarcControleRetFonte == undefined) || (this.vlPedRessarcControleRetFonte.toString() == "")) {
      field6 = 0.00;
    } else {
      field6 = parseFloat(this.vlPedRessarcControleRetFonte.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.vlUtilizadoDeclaracaoControleRetFonte == undefined) || (this.vlUtilizadoDeclaracaoControleRetFonte.toString() == "")) {
      field7 = 0.00;
    } else {
      field7 = parseFloat(this.vlUtilizadoDeclaracaoControleRetFonte.toString().replace(',', '.')).toFixed(2);
    }

    var somaAux = parseFloat(field4) - parseFloat(field5) - parseFloat(field6) - parseFloat(field7);
    this.saldoUtilizarFuturoControleRetFonte = somaAux.toFixed(2);

  }


  /* Função que adiciona um registro ligado a um Controle Retido na Fonte
  (PIS ou COFINS) */
  adicionarControleRetidoFonte() {
    console.log("Entra na função que adiciona dados de controle retido na fonte");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.natControleRetFonteEscolhido == null || this.natControleRetFonteEscolhido == undefined
      || this.natControleRetFonteEscolhido == "" || this.natControleRetFonteEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um indicador de natureza retida na fonte." });
    } else if (this.dtOperacaoControleRetFonte == null || this.dtOperacaoControleRetFonte == undefined
      || this.dtOperacaoControleRetFonte == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o período do recebimento e da retenção." });
    } else if (this.vlTotalControleRetFonte == null || this.vlTotalControleRetFonte == undefined
      || this.vlTotalControleRetFonte == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o valor total da retenção." });
    } else if (this.vlDeduzidoContribuicaoControleRetFonte == null || this.vlDeduzidoContribuicaoControleRetFonte == undefined
      || this.vlDeduzidoContribuicaoControleRetFonte == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o valor da Retenção deduzida da Contribuição." });
    } else if (this.vlPedRessarcControleRetFonte == null || this.vlPedRessarcControleRetFonte == undefined
      || this.vlPedRessarcControleRetFonte == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o valor da Retenção utilizada mediante Pedido de Restituição." });
    } else if (this.vlUtilizadoDeclaracaoControleRetFonte == null || this.vlUtilizadoDeclaracaoControleRetFonte == undefined
      || this.vlUtilizadoDeclaracaoControleRetFonte == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o valor da Retenção utilizada mediante Declaração de Compensação." });
    } else if (this.saldoUtilizarFuturoControleRetFonte == null || this.saldoUtilizarFuturoControleRetFonte == undefined
      || this.saldoUtilizarFuturoControleRetFonte == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o saldo de retenção." });
    } else {

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        natControleRetFonteEscolhido: this.natControleRetFonteEscolhido,
        vlTotalControleRetFonte: this.vlTotalControleRetFonte,
        vlPedRessarcControleRetFonte: this.vlPedRessarcControleRetFonte,
        saldoUtilizarFuturoControleRetFonte: this.saldoUtilizarFuturoControleRetFonte,
        dtOperacaoControleRetFonte: this.dtOperacaoControleRetFonte,
        vlDeduzidoContribuicaoControleRetFonte: this.vlDeduzidoContribuicaoControleRetFonte,
        vlUtilizadoDeclaracaoControleRetFonte: this.vlUtilizadoDeclaracaoControleRetFonte,
        usuarioId: this.sessionService.loggedUser.id,
        controleRetidoFontePis: this.controleRetidoFontePis
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-dados-controle-retido-fonte', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              console.log("Adição de registro de controle retido na fonte executada com sucesso!");

              // Verificar se os dados a serem carregados são de PIS ou COFINS
              if (this.controleRetidoFontePis === true) {
                this.getControleValoresFontePisPasep();
              } else {
                this.getControleValoresFonteCofins();
              }

              this.modalControleRetidoFonte = false;
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que faz a remoção de um registro da tabela Reg1300PisCofins
  (controle de valor retido na fonte para PIS) */
  removerDados1300(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-dados-1300(' + event.id + ", " + this.tCurrentPeriodo.toString() + ", " + this.tCurrentCnpj.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.getControleValoresFontePisPasep();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que faz a remoção de um registro da tabela Reg1700PisCofins
 (controle de valor retido na fonte para COFINS) */
  removerDados1700(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-dados-1700(' + event.id + ", " + this.tCurrentPeriodo.toString() + ", " + this.tCurrentCnpj.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.getControleValoresFonteCofins();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que adiciona um registro ligado a um Controle de Créditos Fiscais
  (PIS ou COFINS) */
  adicionarControleCreditosFiscais() {
    console.log("Entra na função que adiciona dados de controle de créditos fiscais");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.dtApuracaoCreditosFiscais == null || this.dtApuracaoCreditosFiscais == undefined
      || this.dtApuracaoCreditosFiscais == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o período de apuração do crédito." });
    } else if (this.origemCreditosFiscaisEscolhido == null || this.origemCreditosFiscaisEscolhido == undefined
      || this.origemCreditosFiscaisEscolhido == "" || this.origemCreditosFiscaisEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um indicador da origem do crédito." });
    } else if (this.tipoCreditosFiscaisEscolhido == null || this.tipoCreditosFiscaisEscolhido == undefined
      || this.tipoCreditosFiscaisEscolhido == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o código do tipo de crédito." });
    } else if (this.vlCredApuCreditosFiscais == null || this.vlCredApuCreditosFiscais == undefined
      || this.vlCredApuCreditosFiscais == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o valor total do crédito apurado na Escrituração Fiscal Digital." });
    } else if (this.utilDescCreditosFiscais == null || this.utilDescCreditosFiscais == undefined
      || this.utilDescCreditosFiscais == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o valor do crédito usado mediante desconto." });
    } else if (this.dispUtilizacaoCreditosFiscais == null || this.dispUtilizacaoCreditosFiscais == undefined
      || this.dispUtilizacaoCreditosFiscais == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione o saldo de crédito disponível." });
    } else {

      /* Realizar tratamento para os campos opcionais */
      if (this.cnpjCreditosFiscais == null || this.cnpjCreditosFiscais == undefined
        || this.cnpjCreditosFiscais == "") {
        this.cnpjCreditosFiscais = "";
      }

      if (this.extemporaneoCreditosFiscais == null || this.extemporaneoCreditosFiscais == undefined
        || this.extemporaneoCreditosFiscais.toString() == "") {
        this.extemporaneoCreditosFiscais = 0.00;
      }

      if (this.apuradoPeriodoCreditosFiscais == null || this.apuradoPeriodoCreditosFiscais == undefined
        || this.apuradoPeriodoCreditosFiscais.toString() == "") {
        this.apuradoPeriodoCreditosFiscais = 0.00;
      }

      if (this.descEscrituracaoCreditosFiscais == null || this.descEscrituracaoCreditosFiscais == undefined
        || this.descEscrituracaoCreditosFiscais.toString() == "") {
        this.descEscrituracaoCreditosFiscais = 0.00;
      }

      if (this.objPedidoRessarcCreditosFiscais == null || this.objPedidoRessarcCreditosFiscais == undefined
        || this.objPedidoRessarcCreditosFiscais.toString() == "") {
        this.objPedidoRessarcCreditosFiscais = 0.00;
      }

      if (this.opcoesCreditosFiscais == null || this.opcoesCreditosFiscais == undefined
        || this.opcoesCreditosFiscais.toString() == "") {
        this.opcoesCreditosFiscais = 0.00;
      }

      if (this.utilCompCreditosFiscaisAtual == null || this.utilCompCreditosFiscaisAtual == undefined
        || this.utilCompCreditosFiscaisAtual.toString() == "") {
        this.utilCompCreditosFiscaisAtual = 0.00;
      }

      if (this.utilCompCreditosFiscaisAnteriores == null || this.utilCompCreditosFiscaisAnteriores == undefined
        || this.utilCompCreditosFiscaisAnteriores.toString() == "") {
        this.utilCompCreditosFiscaisAnteriores = 0.00;
      }

      if (this.utilDescCreditosFiscais == null || this.utilDescCreditosFiscais == undefined
        || this.utilDescCreditosFiscais.toString() == "") {
        this.utilDescCreditosFiscais = 0.00;
      }

      if (this.utilOutrosCreditosFiscais == null || this.utilOutrosCreditosFiscais == undefined
        || this.utilOutrosCreditosFiscais.toString() == "") {
        this.utilOutrosCreditosFiscais = 0.00;
      }

      if (this.utilPeriodoApurCreditosFiscais == null || this.utilPeriodoApurCreditosFiscais == undefined
        || this.utilPeriodoApurCreditosFiscais.toString() == "") {
        this.utilPeriodoApurCreditosFiscais = 0.00;
      }

      if (this.utilRessarcCreditosFiscais == null || this.utilRessarcCreditosFiscais == undefined
        || this.utilRessarcCreditosFiscais.toString() == "") {
        this.utilRessarcCreditosFiscais = 0.00;
      }

      if (this.dispUtilizacaoCreditosFiscais == null || this.dispUtilizacaoCreditosFiscais == undefined
        || this.dispUtilizacaoCreditosFiscais.toString() == "") {
        this.dispUtilizacaoCreditosFiscais = 0.00;
      }

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        origemCreditosFiscaisEscolhido: this.origemCreditosFiscaisEscolhido,
        tipoCreditosFiscaisEscolhido: this.tipoCreditosFiscaisEscolhido,
        cnpjCreditosFiscais: this.cnpjCreditosFiscais,
        dtApuracaoCreditosFiscais: this.dtApuracaoCreditosFiscais,
        apuradoPeriodoCreditosFiscais: this.apuradoPeriodoCreditosFiscais,
        vlCredApuCreditosFiscais: this.vlCredApuCreditosFiscais,
        utilRessarcCreditosFiscais: this.utilRessarcCreditosFiscais,
        dispUtilizacaoCreditosFiscais: this.dispUtilizacaoCreditosFiscais,
        objPedidoRessarcCreditosFiscais: this.objPedidoRessarcCreditosFiscais,
        opcoesCreditosFiscais: this.opcoesCreditosFiscais,
        extemporaneoCreditosFiscais: this.extemporaneoCreditosFiscais,
        utilDescCreditosFiscais: this.utilDescCreditosFiscais,
        utilCompCreditosFiscaisAtual: this.utilCompCreditosFiscaisAtual,
        descEscrituracaoCreditosFiscais: this.descEscrituracaoCreditosFiscais,
        utilCompCreditosFiscaisAnteriores: this.utilCompCreditosFiscaisAnteriores,
        utilOutrosCreditosFiscais: this.utilOutrosCreditosFiscais,
        utilPeriodoApurCreditosFiscais: this.utilPeriodoApurCreditosFiscais,
        usuarioId: this.sessionService.loggedUser.id,
        tipoDado: this.controleCreditosFiscaisPis
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-dados-controle-creditos-fiscais', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              console.log("Adição de registro de controle retido na fonte executada com sucesso!");

              // Verificar se os dados a serem carregados são de PIS ou COFINS
              if (this.controleCreditosFiscaisPis == true) {
                this.getControleCreditosPisPasep();
              } else {
                this.getControleCreditoCofins();
              }

              this.modalControleCredito = false;
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que faz a remoção de um registro da tabela Reg1100PisCofins
  (controle de créditos fiscais para PIS) */
  removerDados1100(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-dados-1100(' + event.id + ", " + this.tCurrentPeriodo.toString() + ", " + this.tCurrentCnpj.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.getControleCreditosPisPasep();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que faz a remoção de um registro da tabela Reg1500PisCofins
 (controle de créditos fiscais para COFINS) */
  removerDados1500(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-dados-1500(' + event.id + ", " + this.tCurrentPeriodo.toString() + ", " + this.tCurrentCnpj.toString() + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              this.getControleCreditoCofins();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que realiza as somas e subtrações dos valores preenchidos no modal
  de Controle de Créditos Fiscais (Reg1100Piscofins e Reg1500Piscofins) */
  alterarValoresCreditosFiscais() {

    console.log("Entra na função alterarValoresCreditosFiscais()");

    var field6, field7, field9, field10, field11, field13, field14, field15, field16, field17;

    if ((this.apuradoPeriodoCreditosFiscais == undefined) || (this.apuradoPeriodoCreditosFiscais.toString() == "")) {
      field6 = 0.00;
    } else {
      field6 = parseFloat(this.apuradoPeriodoCreditosFiscais.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.extemporaneoCreditosFiscais == undefined) || (this.extemporaneoCreditosFiscais.toString() == "")) {
      field7 = 0.00;
    } else {
      field7 = parseFloat(this.extemporaneoCreditosFiscais.toString().replace(',', '.')).toFixed(2);
    }

    var somaAux = parseFloat(field6) + parseFloat(field7);

    this.vlCredApuCreditosFiscais = somaAux.toFixed(2);

    if ((this.utilDescCreditosFiscais == undefined) || (this.utilDescCreditosFiscais.toString() == "")) {
      field9 = 0.00;
    } else {
      field9 = parseFloat(this.utilDescCreditosFiscais.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.utilRessarcCreditosFiscais == undefined) || (this.utilRessarcCreditosFiscais.toString() == "")) {
      field10 = 0.00;
    } else {
      field10 = parseFloat(this.utilRessarcCreditosFiscais.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.utilCompCreditosFiscaisAnteriores == undefined) || (this.utilCompCreditosFiscaisAnteriores.toString() == "")) {
      field11 = 0.00;
    } else {
      field11 = parseFloat(this.utilCompCreditosFiscaisAnteriores.toString().replace(',', '.')).toFixed(2);
    }

    somaAux = parseFloat(this.vlCredApuCreditosFiscais) - parseFloat(field9) - parseFloat(field10) - parseFloat(field11);

    this.dispUtilizacaoCreditosFiscais = somaAux.toFixed(2);

    if ((this.descEscrituracaoCreditosFiscais == undefined) || (this.descEscrituracaoCreditosFiscais.toString() == "")) {
      field13 = 0.00;
    } else {
      field13 = parseFloat(this.descEscrituracaoCreditosFiscais.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.objPedidoRessarcCreditosFiscais == undefined) || (this.objPedidoRessarcCreditosFiscais.toString() == "")) {
      field14 = 0.00;
    } else {
      field14 = parseFloat(this.objPedidoRessarcCreditosFiscais.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.utilCompCreditosFiscaisAtual == undefined) || (this.utilCompCreditosFiscaisAtual.toString() == "")) {
      field15 = 0.00;
    } else {
      field15 = parseFloat(this.utilCompCreditosFiscaisAtual.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.opcoesCreditosFiscais == undefined) || (this.opcoesCreditosFiscais.toString() == "")) {
      field16 = 0.00;
    } else {
      field16 = parseFloat(this.opcoesCreditosFiscais.toString().replace(',', '.')).toFixed(2);
    }

    if ((this.utilOutrosCreditosFiscais == undefined) || (this.utilOutrosCreditosFiscais.toString() == "")) {
      field17 = 0.00;
    } else {
      field17 = parseFloat(this.utilOutrosCreditosFiscais.toString().replace(',', '.')).toFixed(2);
    }

    somaAux = parseFloat(this.dispUtilizacaoCreditosFiscais) - parseFloat(field13) - parseFloat(field14) - parseFloat(field15) - parseFloat(field16) - parseFloat(field17);

    this.utilPeriodoApurCreditosFiscais = somaAux.toFixed(2);

  }


  /* Função que obtém os dados de apuração ICMS (Reg_E110) */
  getApuracaoICMS() {
    console.log("Entra na função de obter dados de apuração ICMS");

    let dadosRetornados = [];
    let dados = {
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-apuracao-icms', dados)
      .subscribe(
        res => {
          console.log("Busca de dados de apuração ICMS efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "id": line["id"], "vl_tot_debitos": line["vl_tot_debitos"], "vl_aj_debitos": line["vl_aj_debitos"],
              "vl_tot_aj_debitos": line["vl_tot_aj_debitos"], "vl_estornos_cred": line["vl_estornos_cred"],
              "vl_tot_creditos": line["vl_tot_creditos"], "vl_aj_creditos": line["vl_aj_creditos"],
              "vl_tot_aj_creditos": line["vl_tot_aj_creditos"], "vl_estornos_deb": line["vl_estornos_deb"],
              "vl_sld_credor_ant": line["vl_sld_credor_ant"], "vl_sld_apurado": line["vl_sld_apurado"],
              "vl_tot_ded": line["vl_tot_ded"], "vl_icms_recolher": line["vl_icms_recolher"], "vl_sld_credor_transportar": line["vl_sld_credor_transportar"],
              "deb_exp": line["deb_exp"]
            });
          }
          this.tApuracaoICMS = dadosRetornados;
          this.tCurrentApuracaoICMS = dadosRetornados[0].id;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os dados de apuração ICMS-ST (Reg_E200) */
  getApuracaoICMSST() {
    console.log("Entra na função de obter dados de apuração ICMS-ST");

    let dadosRetornados = [];
    let dados = {
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-apuracao-icms-st', dados)
      .subscribe(
        res => {
          console.log("Busca de dados de apuração ICMS-ST efetuada com sucesso!");
          for (let line of res) {

            var data_inicial = line["dt_ini"];
            if (data_inicial != null) {
              var formattedData = new Date(parseFloat(data_inicial));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              data_inicial = day + '/' + month + '/' + year;
            } else {
              data_inicial = null;
            }

            var data_final = line["dt_fin"];
            if (data_final != null) {
              var formattedData = new Date(parseFloat(data_final));

              let month = String(formattedData.getMonth() + 1);
              let day = String(formattedData.getDate());
              const year = String(formattedData.getFullYear());

              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              data_final = day + '/' + month + '/' + year;
            } else {
              data_final = null;
            }

            dadosRetornados.push({
              "id": line["id"], "uf": line["uf"], "dt_inicial": data_inicial, "dt_final": data_final,
              "acao": ""
            });
          }
          this.tApuracaoICMSST = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os dados de apuração IPI (Reg_E520) */
  getApuracaoIPI() {
    console.log("Entra na função de obter dados de apuração IPI");

    let dadosRetornados = [];
    let dados = {
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-apuracao-ipi', dados)
      .subscribe(
        res => {
          console.log("Busca de dados de apuração IPI efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "id": line["id"], "saldo_cred_peri_ant": line["saldo_cred_peri_ant"], "vl_debitos_ipi": line["vl_debitos_ipi"],
              "vl_creditos_ipi": line["vl_creditos_ipi"], "vl_outros_debitos_ipi": line["vl_outros_debitos_ipi"],
              "vl_outros_creditos_ipi": line["vl_outros_creditos_ipi"], "vl_saldo_cred_per": line["vl_saldo_cred_per"],
              "vl_saldo_devedor_recolher": line["vl_saldo_devedor_recolher"]
            });
          }
          this.tApuracaoIPI = dadosRetornados;
          if (res.length > 0) {
            this.tCurrentApuracaoIPI = dadosRetornados[0].id;
          } else {
            this.tCurrentApuracaoIPI = null;
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os dados da tabela RegE111 */
  getApuracaoBeneficioIncentivo() {
    console.log("Entra na função de obter dados de RegE111");

    let dadosRetornados = [];
    let dados = {
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-apuracao-beneficio-incentivo', dados)
      .subscribe(
        res => {
          console.log("Busca de dados na tabela RegE111 efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "id": line["id"], "cod_apuracao_deducao": line["cod_apuracao_deducao"],
              "desc_complementar": line["desc_complementar"], "vl_ajuste_apuracao": line["vl_ajuste_apuracao"],
            });
          }
          this.tApuracaoBeneficioIncentivo = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os dados da tabela RegE510 */
  getValoresConsolidadosIPI() {
    console.log("Entra na função de obter dados de RegE510");

    let dadosRetornados = [];
    let dados = {
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-valores-consolidados-ipi', dados)
      .subscribe(
        res => {
          console.log("Busca de dados na tabela RegE510 efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "id": line["id"], "cfop": line["cfop"], "cst_ipi": line["cst_ipi"],
              "vl_contabil": line["vl_contabil"], "bc_ipi": line["bc_ipi"], "vl_ipi": line["vl_ipi"]
            });
          }
          this.tApuracaoIPIConsolidados = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os dados da tabela RegE530 */
  getApuracaoIPIAjuste() {
    console.log("Entra na função de obter dados de RegE530");

    let dadosRetornados = [];
    let dados = {
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-apuracao-ipi-ajuste', dados)
      .subscribe(
        res => {
          console.log("Busca de dados na tabela RegE530 efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "id": line["id"], "vl_ajuste": line["vl_ajuste"], "origem_doc": line["origem_doc"],
              "num_doc": line["num_doc"], "descricao": line["descricao"], "tipo_ajuste": line["tipo_ajuste"],
              "cod_ajuste": line["cod_ajuste"], "acao": ""
            });
          }
          this.tApuracaoIPIAjuste = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os dados da tabela RegE210 */
  getApuracaoICMSSTDetalhe() {
    console.log("Entra na função de obter dados de RegE210");

    let dadosRetornados = [];
    let dados = {
      cnpj: this.tCurrentCnpj.toString(),
      periodo: this.tCurrentPeriodo.toString(),
      idE200: this.idE200
    }

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-apuracao-icms-st-detalhe', dados)
      .subscribe(
        res => {
          console.log("Busca de dados na tabela RegE210 efetuada com sucesso!");
          for (let line of res) {

            dadosRetornados.push({
              "id": line["id"], "indicador_movimento": line["indicador_movimento"], "saldo_cred_peri_ant_st": line["saldo_cred_peri_ant_st"],
              "vl_total_icms_st_devolucao": line["vl_total_icms_st_devolucao"], "vl_total_icms_st_ressarcimentos": line["vl_total_icms_st_ressarcimentos"],
              "vl_total_outros_creditos_st": line["vl_total_outros_creditos_st"],
              "vl_ajuste_icms_st_doc_fiscal": line["vl_ajuste_icms_st_doc_fiscal"], "vl_total_retido_icms_st": line["vl_total_retido_icms_st"],
              "vl_total_outros_st": line["vl_total_outros_st"], "vl_ajuste_debito_icms_st": line["vl_ajuste_debito_icms_st"],
              "vl_saldo_devedor": line["vl_saldo_devedor"], "vl_total_deducoes_st": line["vl_total_deducoes_st"],
              "vl_total_icms": line["vl_total_icms"], "vl_saldo_credor_icms_st": line["vl_saldo_credor_icms_st"],
              "vl_recolhidos_recolher_extra": line["vl_recolhidos_recolher_extra"], "acao": ""
            });
          }
          this.tApuracaoICMSSTDetalhe = dadosRetornados;
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  // Função que adiciona dados à tabela RegE111
  adicionarDadosRegE111() {
    console.log("Entra na função que adiciona dados à tabela RegE111");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.ajusteApurDeduEscolhido == null || this.ajusteApurDeduEscolhido == undefined
      || this.ajusteApurDeduEscolhido == "" || this.ajusteApurDeduEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um código de ajuste de apuração/dedução." });
    } else {

      /* Realizar tratamento para os campos opcionais */
      if (this.descricaoComplementarE111 == null || this.descricaoComplementarE111 == undefined
        || this.descricaoComplementarE111 == "") {
        this.descricaoComplementarE111 = "";
      }

      if (this.vlAjusteApuracaoE111 == null || this.vlAjusteApuracaoE111 == undefined
        || this.vlAjusteApuracaoE111 == "") {
        this.vlAjusteApuracaoE111 = "";
      }

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        ajusteApurDeduEscolhido: this.ajusteApurDeduEscolhido.toString(),
        descricaoComplementarE111: this.descricaoComplementarE111.toString(),
        vlAjusteApuracaoE111: this.vlAjusteApuracaoE111.toString(),
        e110Id: this.tCurrentApuracaoICMS
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-dados-e111', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              console.log("Adição de registro na tabela RegE111 executada com sucesso!");

              // Com a operação bem-sucedida, atualizar os dados das tabelas RegE110 e RegE111
              this.getApuracaoBeneficioIncentivo();
              this.getApuracaoICMS()
              this.modalBeneficioIncentivo = false;
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  // Função que adiciona dados à tabela RegE530
  adicionarDadosRegE530() {
    console.log("Entra na função que adiciona dados à tabela RegE530");

    /* Checar se todos os campos obrigatórios estão preenchidos.
    Em caso negativo, enviar uma mensagem de aviso ao usuário. */
    if (this.tipoAjusteIPIEscolhido == null || this.tipoAjusteIPIEscolhido == undefined
      || this.tipoAjusteIPIEscolhido == "" || this.tipoAjusteIPIEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um tipo de ajuste de IPI." });
    } else if (this.origemDocIPIEscolhido == null || this.origemDocIPIEscolhido == undefined
      || this.origemDocIPIEscolhido == "" || this.origemDocIPIEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione a origem do documento." });
    } else if (this.codAjusteApuracaoIPIEscolhido == null || this.codAjusteApuracaoIPIEscolhido == undefined
      || this.codAjusteApuracaoIPIEscolhido == "" || this.codAjusteApuracaoIPIEscolhido == "Selecione") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um código de ajuste." });
    } else if (this.numeroDocumentoE530 == null || this.numeroDocumentoE530 == undefined
      || this.numeroDocumentoE530 == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Selecione um número de documento." });
    } else if (this.vlAjusteE530 == null || this.vlAjusteE530 == undefined
      || this.vlAjusteE530 == "") {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: "Digite um valor de ajuste." });
    }
    else {

      /* Realizar tratamento para os campos opcionais */
      if (this.descricaoResumidaE530 == null || this.descricaoResumidaE530 == undefined
        || this.descricaoResumidaE530 == "") {
        this.descricaoResumidaE530 = "";
      }

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        tipoAjusteIPIEscolhido: this.tipoAjusteIPIEscolhido.toString(),
        origemDocIPIEscolhido: this.origemDocIPIEscolhido.toString(),
        codAjusteApuracaoIPIEscolhido: this.codAjusteApuracaoIPIEscolhido.toString(),
        numeroDocumentoE530: this.numeroDocumentoE530.toString(),
        vlAjusteE530: this.vlAjusteE530.toString(),
        descricaoResumidaE530: this.descricaoResumidaE530.toString(),
        e520Id: this.tCurrentApuracaoIPI
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/adicionar-dados-e530', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              console.log("Adição de registro na tabela RegE530 executada com sucesso!");

              // Com a operação bem-sucedida, atualizar os dados das tabelas RegE520 e RegE530
              this.getApuracaoIPI();
              this.getApuracaoIPIAjuste();
              this.modalAjusteApuracaoIPI = false;
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que faz a remoção de um registro da tabela RegE111 */
  removerDadosE111(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-dados-e111(' + event.id + ", " + event.cod_apuracao_deducao.substring(0, 9) + ", " + event.vl_ajuste_apuracao + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });

              // Com a operação bem-sucedida, atualizar os dados das tabelas RegE110 e RegE111
              this.getApuracaoBeneficioIncentivo();
              this.getApuracaoICMS()
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que faz a remoção de um registro da tabela RegE530 */
  removerDadosE530(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      this.httpService.wait();
      this.httpService.get('/custom/dbl/remover-dados-e530(' + event.id + ", " + event.tipo_ajuste + ", " + event.vl_ajuste + ')')
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });

              // Com a operação bem-sucedida, atualizar os dados das tabelas RegE520 e RegE530
              this.getApuracaoIPI();
              this.getApuracaoIPIAjuste();
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  // Função que atualiza o saldo credor para um registro de ICMS
  atualizarSaldoCredorICMS() {
    if (confirm('Você deseja realmente atualizar o saldo credor?')) {

      if (this.valorSaldoCredorIcms == null || this.valorSaldoCredorIcms == undefined
        || this.valorSaldoCredorIcms.toString() == "") {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: "Favor selecionar um valor para o saldo credor de ICMS." });
      } else {
        let dados = {
          cnpj: this.tCurrentCnpj.toString(),
          periodo: this.tCurrentPeriodo.toString(),
          valorSaldoCredorIcms: this.valorSaldoCredorIcms,
        }

        this.httpService.wait();
        this.httpService.post('/custom/dbl/atualizar-saldo-credor-icms', dados)
          .subscribe(
            res => {
              if (res["error"] == false) {
                this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
                this.getApuracaoICMS();
              } else {
                this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
              }

              this.httpService.done();
            },
            err => {
              this.exibirMensagem(err);
              console.log(err);
              this.httpService.done();
            }
          );
      }

    }
  }

  // Função que atualiza o saldo credor para um registro de IPI
  atualizarSaldoCredorIPI() {
    if (confirm('Você deseja realmente atualizar o saldo credor?')) {

      if (this.valorSaldoCredorIpi == null || this.valorSaldoCredorIpi == undefined
        || this.valorSaldoCredorIpi.toString() == "") {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: "Favor selecionar um valor para o saldo credor de IPI." });
      } else {
        let dados = {
          cnpj: this.tCurrentCnpj.toString(),
          periodo: this.tCurrentPeriodo.toString(),
          valorSaldoCredorIpi: this.valorSaldoCredorIpi,
        }

        this.httpService.wait();
        this.httpService.post('/custom/dbl/atualizar-saldo-credor-ipi', dados)
          .subscribe(
            res => {
              if (res["error"] == false) {
                this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
                this.getApuracaoIPI();
              } else {
                this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
              }

              this.httpService.done();
            },
            err => {
              this.exibirMensagem(err);
              console.log(err);
              this.httpService.done();
            }
          );
      }

    }
  }

  // Função que atualiza o valor retido para um registro de PIS
  atualizarValorRetidoPis() {
    if (confirm('Você deseja realmente atualizar o valor de Retido na Fonte?')) {

      if (this.vlRetidoNaoCumulativoPis == null || this.vlRetidoNaoCumulativoPis == undefined
        || this.vlRetidoNaoCumulativoPis.toString() == "") {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: "Favor selecionar um valor retido não-cumulativo para PIS." });
      } else if (this.vlRetidoCumulativoPis == null || this.vlRetidoCumulativoPis == undefined
        || this.vlRetidoCumulativoPis.toString() == "") {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: "Favor selecionar um valor retido cumulativo para PIS." });
      } else {
        let dados = {
          cnpj: this.tCurrentCnpj.toString(),
          periodo: this.tCurrentPeriodo.toString(),
          valorSaldoCredorIpi: this.valorSaldoCredorIpi,
          vlRetidoNaoCumulativoPis: this.vlRetidoNaoCumulativoPis,
          vlRetidoCumulativoPis: this.vlRetidoCumulativoPis
        }

        this.httpService.wait();
        this.httpService.post('/custom/dbl/atualizar-valor-retido-pis', dados)
          .subscribe(
            res => {
              if (res["error"] == false) {
                this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              } else {
                this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
              }

              this.httpService.done();
            },
            err => {
              this.exibirMensagem(err);
              console.log(err);
              this.httpService.done();
            }
          );
      }

    }
  }

  // Função que atualiza o valor retido para um registro de COFINS
  atualizarValorRetidoCofins() {
    if (confirm('Você deseja realmente atualizar o valor de Retido na Fonte?')) {

      if (this.vlRetidoNaoCumulativoCofins == null || this.vlRetidoNaoCumulativoCofins == undefined
        || this.vlRetidoNaoCumulativoCofins.toString() == "") {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: "Favor selecionar um valor retido não-cumulativo para COFINS." });
      } else if (this.vlRetidoCumulativoCofins == null || this.vlRetidoCumulativoCofins == undefined
        || this.vlRetidoCumulativoCofins.toString() == "") {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', detail: "Favor selecionar um valor retido cumulativo para COFINS." });
      } else {
        let dados = {
          cnpj: this.tCurrentCnpj.toString(),
          periodo: this.tCurrentPeriodo.toString(),
          valorSaldoCredorIpi: this.valorSaldoCredorIpi,
          vlRetidoNaoCumulativoCofins: this.vlRetidoNaoCumulativoCofins,
          vlRetidoCumulativoCofins: this.vlRetidoCumulativoCofins
        }

        this.httpService.wait();
        this.httpService.post('/custom/dbl/atualizar-valor-retido-cofins', dados)
          .subscribe(
            res => {
              if (res["error"] == false) {
                this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
              } else {
                this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
              }

              this.httpService.done();
            },
            err => {
              this.exibirMensagem(err);
              console.log(err);
              this.httpService.done();
            }
          );
      }

    }
  }

  /* Função que atualiza o status de dados gravados */
  gravarDados() {
    if (confirm('Você deseja realmente atualizar o status?')) {

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        tipoDado: this.tCurrentTipo,
        m200PisCofinsId: this.tCurrentM200Id,
        e110Id: this.tCurrentApuracaoICMS,
        usuarioLogin: this.sessionService.loggedUser.login,
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/gravar-dados', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que atualiza o status de dados homologados */
  homologarDados() {
    if (confirm('Você deseja realmente atualizar o status?')) {

      let dados = {
        cnpj: this.tCurrentCnpj.toString(),
        periodo: this.tCurrentPeriodo.toString(),
        tipoDado: this.tCurrentTipo,
        m200PisCofinsId: this.tCurrentM200Id,
        e110Id: this.tCurrentApuracaoICMS,
        usuarioLogin: this.sessionService.loggedUser.login,
      }

      this.httpService.wait();
      this.httpService.post('/custom/dbl/homologar-dados', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: res["message"] });
            } else {
              this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
            }

            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  /* Função que obtém os códigos de ajuste da tabela RegE111 */
  getAjustesCodigoRegE111() {

    this.AjusteApurDedu = [{ label: 'Selecione', value: 'Selecione' }];

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-codigos-ajuste-reg-e111')
      .subscribe(
        res => {
          for (let line of res) {
            this.AjusteApurDedu.push({ label: line["codigo"], value: line["codigo"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os códigos de ajuste da tabela RegE530 */
  getAjustesCodigoRegE530() {

    this.CodAjusteApuracaoIPI = [{ label: 'Selecione', value: 'Selecione' }];

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-codigos-ajuste-reg-e530')
      .subscribe(
        res => {
          for (let line of res) {
            this.CodAjusteApuracaoIPI.push({ label: line["codigo"], value: line["codigo"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os códigos de ajuste da tabela RegE111 */
  getCodigosIndNatRetFonte() {

    this.naturezaRetFonte = [{ label: 'Selecione', value: 'Selecione' }];

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-codigos-ind-nat-ret-fonte')
      .subscribe(
        res => {
          for (let line of res) {
            this.naturezaRetFonte.push({ label: line["codigo"], value: line["codigo"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os códigos de CST, usados em PIS/COFINS */
  getCodigosCstPisCofins() {

    this.cstPIS = [{ label: 'Selecione', value: 'Selecione' }];
    this.cstCOFINS = [{ label: 'Selecione', value: 'Selecione' }];

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-codigos-cst-pis-cofins')
      .subscribe(
        res => {
          for (let line of res) {
            this.cstPIS.push({ label: line["codigo"], value: line["codigo"] });
            this.cstCOFINS.push({ label: line["codigo"], value: line["codigo"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que obtém os tipos de crédito, usados em PIS/COFINS */
  getTiposCredito() {

    this.tipoCredito = [{ label: 'Selecione', value: 'Selecione' }];

    this.httpService.wait();
    this.httpService.post('/custom/dbl/get-tipos-credito')
      .subscribe(
        res => {
          for (let line of res) {
            this.tipoCredito.push({ label: line["codigo"], value: line["codigo"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  /* Função que remove um registro relacionado a um dado de PIS/COFINS ou ICMS/IPI */
  removerRegistro(event: any) {
    if (confirm('Deseja deletar esse registro?')) {

      if (event.tipo == "PIS/COFINS") {

        this.httpService.wait();
        this.httpService.get('/custom/dbl/delete-piscofins(' + event.id + ')')
          .subscribe(
            res => {
              if (res["error"] == false) {
                this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: "Remoção de registro de PIS/COFINS executada com sucesso." });
                // Carregar os dados de PIS/COFINS e ICMS/IPI na tela inicial do projeto DBL
                this.loadDados(false);
              } else {
                this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
              }
              this.httpService.done();
            },
            err => {
              this.exibirMensagem(err);
              console.log(err);
              this.httpService.done();
            }
          );

      } else if (event.tipo == "ICMS/IPI") {
        this.httpService.wait();
        this.httpService.get('/custom/dbl/delete-icmsipi(' + event.id + ')')
          .subscribe(
            res => {
              if (res["error"] == false) {
                this.messageService.add({ severity: 'success', key: 'messageLinnks', detail: "Remoção de registro de ICMS/IPI executada com sucesso." });
                // Carregar os dados de PIS/COFINS e ICMS/IPI na tela inicial do projeto DBL
                this.loadDados(false);
              } else {
                this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["message"] });
              }
              this.httpService.done();
            },
            err => {
              this.exibirMensagem(err);
              console.log(err);
              this.httpService.done();
            }
          );
      }
    }
  }

  onFilterRegra(event: any) {

    this.contadorLinha = event.filteredValue.length;

  }

}
