export class TipoAjustesUsGaap {
  id: number;
  nome: string;

  constructor(tipoajustesusgaap?: TipoAjustesUsGaap | number) {
    if (tipoajustesusgaap !== undefined && tipoajustesusgaap !== null) {
      if (typeof tipoajustesusgaap === 'number') {
        this.id = tipoajustesusgaap;
      } else {
        this.id = tipoajustesusgaap.id;
        this.nome = tipoajustesusgaap.nome;
      }
    }
  }
}
