import { Empresa } from "app/cadastro/model/empresa.model";
import { Area } from "app/orcamento/model/area-model";
import { MunicipioIbge } from "app/util/model/municipio-ibge.model";
import { Pais } from "app/util/model/pais.model";
import { isNullOrUndefined } from "util";
import { Cargo } from "./cargo.model";
import { Funcionario } from "./funcionario.model";

export class Dependente {
  id: number = null
  nome: string = null
  tipo: string = null
  nascimento: Date = null
  funcionario: Funcionario

  constructor(dependente?: Dependente | number) {
    if (!isNullOrUndefined(dependente)) {
      if (typeof dependente === 'number') {
        this.id = dependente;

      } else {
        this.id = dependente.id
        this.nome = dependente.nome
        this.nascimento = dependente.nascimento
        this.tipo = dependente.tipo
        this.funcionario = dependente.funcionario
      }
    }
  }
}
