import { Empresa } from '../../cadastro/model/empresa.model';

export class Segmento {
  id: number;
  descricao: string;
  empresa: Empresa;
  codigo: string;

  constructor(segmento?: Segmento | number) {
    if (segmento !== undefined && segmento !== null) {
      if (typeof segmento === 'number') {
        this.id = segmento;
      } else {
        this.id = segmento.id;
        this.descricao = segmento.descricao;
        this.empresa = segmento.empresa;
        this.codigo = segmento.codigo;
      }
    }
  }
}
