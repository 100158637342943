export class IndNatFrt {
  id: number;
  codigo: string;
  descricao: string;

  constructor(indNatFrt?: IndNatFrt | number) {
    if (indNatFrt !== undefined && indNatFrt !== null) {
      if (typeof indNatFrt === 'number') {
        this.id = indNatFrt;
      } else {
        this.id = indNatFrt.id;
        this.codigo = indNatFrt.codigo;
        this.descricao = indNatFrt.descricao;
      }
    }
  }
}
