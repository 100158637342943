import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MatDialog } from '@angular/material/dialog';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss'],
  providers: [CrudService]
})
export class CadastroPowerBiComponent implements OnInit, OnDestroy {


  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  pt_BR: any;
  defaultDate: Date;
  msgs: Message[];
  empresas: Array<any> = new Array();
  relatorios: Array<any> = new Array();
  idReportCheck: Array<any> = new Array();
  selectCad: Array<any> = new Array();
  exibeTable: boolean;

  opcoesTipoRelatorio: SelectItem[];
  opcoesBoolean: SelectItem[];
  opcoesEmpresa: SelectItem[];

  id: number;
  nome: string;
  tipoRelatorio: number;
  reportId: string;
  groupId: string;
  datasetId: string;
  empresa: number;
  habilitaFiltro: boolean;
  habilitaTab: boolean;
  viewMat: string;

  filtraEmpresa: boolean;
  edit: boolean = false;
  tabelaFiltroEmpresa: string;
  colunaFiltroEmpresa: string;
  filtraPeriodo: boolean;
  tabelaFiltroPeriodo: string;
  colunaFiltroPeriodo: string;
  colsrelatorios: any[] = [];

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private matDialog: MatDialog,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }

  @ViewChild('dtrelatorios') dtrelatorios: Table
  exportMov() {
    this.dtrelatorios.exportCSV()
  }

  public toolbarMain = new Toolbar();

  toolbarMainIni = [
    { key: 'voltar',  icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.listar() },
    { key: 'atualizar',  icon: 'replay', text: 'Atualizar', tooltip: 'Atualizar', visible: true, disabled: false, color: 'default', onClick: () => this.listar() },
    { key: 'export',  icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.dtrelatorios.exportCSV()},
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.editarRelatorio() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRelatorio() },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarRelatorio() },
    { key: 'save', icon: 'save', text: 'Salvar', tooltip: 'Salvar Relatório', visible: false, disabled: false, color: 'default', onClick: () => this.salvarRelatorio() },
  ]

  ngOnInit() {
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'RELATÓRIOS PowerBI';
    this.subtitle = 'Cadastro dos relatórios PowerBi';
    this.titleService.title = 'RELATÓRIOS POWERBI';

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.listar()
      );

    this.toolbarInitial();

    this.exibeTable = true;
    this.tipoRelatorio = 0;
    this.reportId = "";
    this.nome = "";
    this.groupId = "";
    this.datasetId = "";
    this.empresa = 0;
    this.id = 0;
    this.habilitaFiltro = false;
    this.habilitaTab = false;
    this.filtraEmpresa = false;
    this.filtraPeriodo = false;
    this.tabelaFiltroEmpresa = "";
    this.colunaFiltroEmpresa = "";
    this.tabelaFiltroPeriodo = "";
    this.colunaFiltroPeriodo = "";
    this.viewMat = "";
    this.colsrelatorios = [
      { field: 'relId', header: 'ID' },
      { field: 'nome', header: 'NOME' },
      { field: 'tpDescricao', header: 'TIPO RELATÓRIO' },
      { field: 'reportId', header: 'REPORT ID' },
      { field: 'groupId', header: 'GROUP ID' }
    ];


    this.getEmpresas();

    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);

    // this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
    //   () => { setTimeout(() => { this.toolbarEnable(); }, 1000); this.listar(); });
   

  }

  toolbarInitial(){
    this.toolbarMain.setVisible('voltar', false)
    this.toolbarMain.setVisible('atualizar', true)
    this.toolbarMain.setVisible('export', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.toolbarMain.setVisible('add', true)
    this.toolbarMain.setVisible('save', false)
  }

  toolbarNew(){
    this.toolbarMain.setVisible('atualizar', false)
    this.toolbarMain.setVisible('export', false)
    this.toolbarMain.setVisible('voltar', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.toolbarMain.setVisible('add', false)
    this.toolbarMain.setVisible('save', true)
  }

  toolbarEdit(){
    this.toolbarMain.setVisible('atualizar', false)
    this.toolbarMain.setVisible('export', false)
    this.toolbarMain.setVisible('voltar', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', true)
    this.toolbarMain.setVisible('add', false)
    this.toolbarMain.setVisible('save', true)
  }


  listar(): void {
    this.toolbarInitial();
    this.opcoesTipoRelatorio = [];
    this.opcoesTipoRelatorio.push({ label: 'Nenhum', value: 0 });
    this.opcoesTipoRelatorio.push({ label: 'Report', value: 1 });
    this.opcoesTipoRelatorio.push({ label: 'Dashboard', value: 2 });

    this.opcoesBoolean = [];
    this.opcoesBoolean.push({ label: 'NÃO', value: false });
    this.opcoesBoolean.push({ label: 'SIM', value: true });

    this.exibeTable = true;
    this.relatorios = new Array();
    this.idReportCheck = new Array();
    this.selectCad = new Array();

    this.httpService.wait();
    this.httpService.get('/custom/pbi/buscar-rel-pbi')
      .subscribe(retorno => {
        retorno.forEach(el => {
          this.relatorios.push(el);
        });
      },
        error => this.httpService.handleError(error, () => this.listar()),
        () => this.httpService.done());
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  selectRelatorio(event: any) {
    this.toolbarMain.setVisible('edit', true)
    this.toolbarMain.setVisible('delete', true)
    // const index2 = this.idReportCheck.findIndex(c => c.id === event.data.id);
    this.idReportCheck.push(event.data);
    event.data.selected = true;
    this.selectCad = []

    this.id = event.data.relId;
    this.nome = event.data.nome;
    this.tipoRelatorio = event.data.tpId;
    this.reportId = event.data.reportId;
    this.groupId = event.data.groupId;
    this.datasetId = event.data.datasetId;
    this.empresa = event.data.empresa_id !== null ? event.data.empresa_id : 0;
    this.habilitaFiltro = event.data.habilita_filtro;
    this.habilitaTab = event.data.habilita_tab;
    this.filtraEmpresa = event.data.filtraEmpresa;
    this.tabelaFiltroEmpresa = event.data.tabelaFiltroEmpresa;
    this.colunaFiltroEmpresa = event.data.colunaFiltroEmpresa;
    this.filtraPeriodo = event.data.filtraPeriodo;
    this.tabelaFiltroPeriodo = event.data.tabelaFiltroPeriodo;
    this.colunaFiltroPeriodo = event.data.colunaFiltroPeriodo;
    this.viewMat = event.data.viewMat;

    this.checkToolbar();
  }

  unselectRelatorio(event: any){
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.idReportCheck.splice(event.data, 1);
    event.selected = false;

    this.id = 0;
    this.nome = "";
    this.tipoRelatorio = 0;
    this.reportId = "";
    this.groupId = "";
    this.empresa = 0;
    this.habilitaFiltro = false;
    this.habilitaTab = false;
    this.datasetId = "";
    this.viewMat = "";

    this.checkToolbar();
  }

  checkToolbar() : void {
    if (this.idReportCheck.length === 1) {
      this.toolbarService.replace('remove', false, 'class-remove-button', 'delete', 'Excluir', () => this.excluirRelatorio());
      this.toolbarService.replace('msg', false, 'class-edit-button', 'edit', 'Editar', () => this.editarRelatorio());
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO',
        'Criar Novo Relatório', () => this.criarRelatorio());
    } else if (this.idReportCheck.length !== 1) {
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.excluirRelatorio());
      this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar', () => this.editarRelatorio());
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO',
        'Criar Novo Relatório', () => this.criarRelatorio());
    }
  }


  excluirRelatorio() {
    if (confirm('Deseja realmente excluir este relatório?')) {

      const map = {
        id: this.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/pbi/excluir-report', map)
        .subscribe(result => {

          this.messageService.add({ severity: result.severity, summary: result.summary, detail: result.detail });
          this.listar();
        },
          error => this.httpService.handleError(error, () => this.excluirRelatorio()),
          () => this.httpService.done());
    }
  }

  criarRelatorio() {
    this.toolbarNew();
    this.edit = false;
    this.exibeTable = false;
    this.id = 0;
    this.nome = "";
    this.tipoRelatorio = 0;
    this.reportId = "";
    this.groupId = "";
    this.datasetId = "";
    this.empresa = 0;
    this.habilitaFiltro = false;
    this.habilitaTab = false;
    this.habilitaFiltro = false;
    this.habilitaTab = false;
    this.filtraEmpresa = false;
    this.filtraPeriodo = false;
    this.tabelaFiltroEmpresa = "";
    this.colunaFiltroEmpresa = "";
    this.tabelaFiltroPeriodo = "";
    this.colunaFiltroPeriodo = "";
    this.viewMat = "";


    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.listar());
    this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar', () => this.editarRelatorio());
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR',
      'Salvar Relatório', () => this.salvarRelatorio());
  }

  editarRelatorio() {
    this.edit = true;
    this.toolbarEdit();
    this.exibeTable = false;

    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.listar());
    this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar', () => this.editarRelatorio());
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR',
      'Salvar Relatório', () => this.salvarRelatorio());
  }

  salvarRelatorio() {

    let form = this.validarInformacoes();
    if (form) {
      const listasEnviar = {
        id: this.id,
        nome: this.nome,
        tipoRelatorio: this.tipoRelatorio,
        reportId: this.reportId,
        groupId: this.groupId,
        datasetId: this.datasetId,
        empresa: this.empresa,
        habilitaFiltro: this.habilitaFiltro,
        habilitaTab: this.habilitaTab,
        usuario: this.sessionService.loggedUser.id,
        filtraEmpresa: this.filtraEmpresa,
        filtraPeriodo: this.filtraPeriodo,
        tabelaFiltroEmpresa: this.tabelaFiltroEmpresa,
        colunaFiltroEmpresa: this.colunaFiltroEmpresa,
        tabelaFiltroPeriodo: this.tabelaFiltroPeriodo,
        colunaFiltroPeriodo: this.colunaFiltroPeriodo,
        viewMat: this.viewMat
      };

      this.httpService.wait();
      this.httpService.post('/custom/pbi/salvar-relatorio', listasEnviar)
        .subscribe(result => {

          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Relatório Salvo com Sucesso!' });
          this.listar()
        },
          error => this.httpService.handleError(error, () => this.salvarRelatorio()),
          () => this.httpService.done());
    }
  }

  validarInformacoes(): boolean {
    let formValido = true;
    if (!this.nome) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nome é Obrigatório!' });
      formValido = false;
    }
    if (this.tipoRelatorio === 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Tipo de Relatório é Obrigatório!' });
      formValido = false;
    }
    if (!this.reportId) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'ReportId/Dashboard é obrigatório!' });
      formValido = false;
    }
    if (!this.groupId) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'GroupId é obrigatório!' });
      formValido = false;
    }
    if (!this.datasetId) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'DatasetId é obrigatório!' });
      formValido = false;
    }

    if (this.filtraEmpresa && (!this.tabelaFiltroEmpresa || !this.colunaFiltroEmpresa)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Se existe filtro por empresa precisa ser informado tabela e coluna!' });
      formValido = false;
    }

    if (this.filtraPeriodo && (!this.tabelaFiltroPeriodo || !this.colunaFiltroPeriodo)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Se existe filtro por período precisa ser informado tabela e coluna!' });
      formValido = false;
    }

    return formValido;
  }

  getEmpresas() {
    this.opcoesEmpresa = [];
    this.opcoesEmpresa.push({ label: 'Selecione a Empresa', value: 0 });
    this.httpService.wait();
    this.httpService.get('/custom/pbi/buscar-emp')
      .subscribe(retorno => {
        retorno.forEach(el => {

          this.opcoesEmpresa.push({ label: el.cnpj, value: el.id });
        });
      },
        error => this.httpService.handleError(error, () => this.getEmpresas()),
        () => this.httpService.done());
  }

  hideMessage() {

  }

}
