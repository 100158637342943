import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import LogManagement from 'app/shared/common/log-management/log-control';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { PedidoService } from 'app/shared/services/pedido.service';
import { SessionService } from 'app/shared/services/session.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { isNullOrUndefined } from 'util';
import { OperacaoRegraContabil } from '../../cadastro/model/operacao-regra-contabil.model';
import { ParticipanteEmpresa } from '../../cadastro/model/participante-empresa.model';
import { Participante } from '../../cadastro/model/participante.model';
import { CadCcus } from '../../contabil/model/cad-ccus.model';
import * as cadProjModel from '../../contabil/model/cad-proj.model';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';
import { FinanceiroRateio } from '../model/financeiro-rateio.model';

@Component({
  selector: 'app-movimentacao',
  templateUrl: './movimentacao.component.html',
  styleUrls: ['./movimentacao.component.scss'
  ],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class MovimentacaoComponent implements OnInit, OnDestroy {
  currentStateMov = 'initial';
  changeStateMov() {
    this.currentStateMov = this.currentStateMov === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  title: string;
  subtitle: string; e
  movimentacao: Array<any> = new Array();
  statusOptions: SelectItem[];
  modalEdit: boolean;
  modalDetalhe: boolean;
  saldoAtual: number = 0;
  saldoInicial: number = 0;
  valorFiltro: number = 0;
  valorSaldoPaginacao: number;
  valorTransferencia: number;
  valorTransferenciaReal: number;
  todasSelecionadas: boolean;
  modalAlternarConta: boolean;
  modalTransferenciaBancaria: boolean;
  modalOperacao: boolean;
  modalPeriodo: boolean;
  opcoesContas: SelectItem[];
  contaSelecionada: EmpresaConta;
  contaSelecionadaId: any;
  contaOrigem: EmpresaConta = null;
  contaDestino: EmpresaConta = null;
  dtTransferencia: Date;
  dtOperacao: Date;
  empresaConta: EmpresaConta;
  cliente: ParticipanteEmpresa;
  opcoesOperacao: SelectItem[];
  operacaoRegra = new OperacaoRegraContabil;
  clientes: SelectItem[];
  opcoesDebitoCredito: SelectItem[];
  meses: any;
  ocorrenciaMes: any;
  historicoOperacao: any;
  valorOperacao: number;
  recorrencia: any;
  antecipaDiaNaoUtil: any;
  dtFimOccorencia: Date;
  modalOperacaoPagto: boolean;
  contaOrigemAsaas: boolean = false
  solicitarAutorizacao: boolean = false
  private valor: number;
  statusId: number

  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;

  informacaoSelecionada: any;
  participanteTipo: any;
  relatorioAnalitico: boolean;
  baixadosRecebidos: boolean;

  // centro de custo e projeto
  movimentacaoRateios: Array<FinanceiroRateio> = new Array();
  financeiroRateio = new FinanceiroRateio();
  centroCusto = new CadCcus;
  cadProj = new cadProjModel.CadProj;
  opcoesCentroCusto: SelectItem[];
  opcoesProjetos: SelectItem[];
  modalCentroCusto: boolean;
  proj: any;
  cc: any;
  porcentagemCc: number;
  rateioCentroCusto: number;
  rateioCadProj: number;
  contadorRateio: number;
  centroCustoObrigatorio: boolean;
  existeContaResultado: boolean;
  existeProjetosRelacionados: boolean;
  centroCustoId: number;

  // para pagar por CNAB @octavio
  tipoSelecionadoId: any;
  cnabFormaSelecionadaId: any;
  nDocumento: any;
  codBarras: any;
  codReceita: any;
  dtApuracao: any;
  idRecolhimento: any;
  digitoIdRecolhimento: any;
  participanteContasId: any;
  codRecolhimento: any;
  codPagamentoGps: any;
  tiposLista: any[];
  contasFornecedorLista: any[];
  codReceitaLista: any[];
  participanteId: any;
  temNF: boolean;
  tituloNfe: boolean;
  // fim @octavio

  cols: any[];
  totalRecords: number;
  saldoIni: number;
  loading = false;
  colsMovimentacao: any[];
  query: string = "";
  opcoesMoeda: SelectItem[];

  moedaSelecionadaId: number;
  confTaxaId: number;
  permiteAlterarMoeda: boolean;
  permiteMultiMoeda: boolean;
  valorTaxa: number;
  valorReais: number;
  contaBancariaAsaasId: number

  filtroPadrao: boolean;


  edicaoTransferencia: boolean;

  carregarNovamente: boolean = true
  httpOptions: Object = new Object()
  contasPadrao: Array<number> = new Array()


  //vir
  toolbarMainIni = [
    {
      icon: 'more_vert', text: '', tooltip: 'Opções', visible: true, disabled: false, color: 'default', dropdown: true,
      options: [
        { label: 'Expandir relatório', callback: () => this.relAnalitico() },
        { label: 'Exibir baixados', callback: () => this.relBaixadosRecebidos() },
        { label: 'Atualizar Saldo', callback: () => this.atualizarSaldo() },
      ]
    },
    { icon: 'refresh', text: '', tooltip: 'Atualizar Relatório', visible: true, disabled: false, color: 'default', onClick: () => this.atualizarVoltar() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.gerarRelatorio() },
    { icon: 'filter_alt', text: 'Filtrar', tooltip: 'Filtrar', visible: true, disabled: false, color: 'default', onClick: () => this.openModalPeriodo() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirTransferencia() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.editarTransferencia() },

    {
      icon: 'save', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', dropdown: true,
      options: [
        { label: 'Transferência Bancária', callback: () => this.displayTransferenciaDialog() }
      ]
    },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeMov()
        this.changeStateMov()
      }
    },
  ]

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'VENCTO/BAIXA', size: '10%', textAlign: 'left' },
      { name: 'PREVISÃO', size: '10%', textAlign: 'left' },
      { name: 'EMISSÃO', size: '10%', textAlign: 'left' },
      { name: 'CONTA', size: '12%', textAlign: 'left' },
      { name: 'NOME', size: '12%', textAlign: 'left' },
      { name: 'RAZÃO SOCIAL', size: '12%', textAlign: 'left' },
      { name: 'OPERAÇÃO', size: '12%', textAlign: 'left' },
      { name: 'HISTÓRICO', size: '12%', textAlign: 'left' },
      { name: 'DOC', size: '9%', textAlign: 'left' },
      { name: 'Parc', size: '5%', textAlign: 'left' },
      { name: 'Valor', size: '9%', textAlign: 'left' },
      { name: 'Saldo', size: '9%', textAlign: 'left' },
      { name: 'Status', size: '10%', textAlign: 'left' }
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true
  })

  public toolbarMain = new Toolbar();
  private logManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpClient: HttpClient,
    private pedidoService: PedidoService,
    private messageService: MessageService
  ) {

    this.cols = [
      { field: 'dt_vcto_form', header: 'VENCTO/BAIXA' },
      { field: 'dt_previsao_form', header: 'PREVISÃO' },
      { field: 'dt_emissao_form', header: 'EMISSÃO' },
      { field: 'nomeConta', header: 'CONTA' },
      { field: 'nome', header: 'NOME' },
      { field: 'operacao', header: 'OPERAÇÃO' },
      { field: 'historico', header: 'HISTÓRICO' },
      { field: 'nf', header: 'DOC' },
      { field: 'parcela', header: 'PARCELA' },
      { field: 'valor', header: 'VALOR' },
      { field: 'saldoAtual', header: 'SALDO' },
      { field: 'status', header: 'STATUS' },
    ];

    this.totalRecords = 100;
  }

  ngOnInit() {
    this.toolbarService.clear();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'MOVIMENTAÇÃO BANCÁRIA';
    this.subtitle = 'Todas as contas';
    this.titleService.title = 'Movimentação Bancária';
    this.movimentacao = new Array<any>();
    this.modalEdit = false;
    this.modalDetalhe = false;
    this.todasSelecionadas = false;
    this.modalAlternarConta = false;
    this.modalTransferenciaBancaria = false;
    this.modalOperacao = false;
    this.modalOperacaoPagto = false;
    this.modalPeriodo = false;
    this.saldoAtual = 0.00;
    this.saldoInicial = 0.00;
    this.valorFiltro = 0.00;
    this.valorTransferencia = 0.00;
    this.valorTransferenciaReal = 0.00;
    this.dtTransferencia = new Date();
    this.edicaoTransferencia = false;
    this.moedaSelecionadaId = 1;
    this.valorTaxa = 1.00;

    this.contaSelecionada = new EmpresaConta();
    this.contaSelecionadaId = null;
    this.cliente = new ParticipanteEmpresa();
    this.operacaoRegra = new OperacaoRegraContabil;

    this.valorOperacao = 0.00;
    this.historicoOperacao = '';
    this.dtOperacao = new Date();
    this.recorrencia = '';
    this.ocorrenciaMes = 1;
    this.antecipaDiaNaoUtil = '';
    this.dtFimOccorencia = new Date();
    this.defaultDate = new Date();
    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;
    this.filtroPadrao = true;

    this.baixadosRecebidos = false;
    this.statusId = null

    // centro de custo e projetos
    this.centroCusto = new CadCcus;
    this.cadProj = new cadProjModel.CadProj;
    this.modalCentroCusto = false;
    this.porcentagemCc = 0;
    this.cc = [];
    this.proj = [];
    this.rateioCentroCusto = 100;
    this.rateioCadProj = 100;
    this.movimentacaoRateios = new Array();
    // this.financeiroRateio = new FinanceiroRateio;
    this.relatorioAnalitico = true;
    this.tituloNfe = false;
    this.query = "";

    this.carregarNovamente = true

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
          this.inicialize()
        }
      });

    this.meses = [];
    for (let mes = 1; mes <= 12; mes++) {
      this.meses.push({ label: mes, value: mes });
    }

    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    //this.alternarContaSelecionada()

    this.contaOrigemAsaas = false
    this.solicitarAutorizacao = false
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
    this.toolbarService.hidden = false
  }

  async inicialize() {
    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    await Promise.all([
      this.buscarContasPadrao(),
      this.loadParametroFinanceiro(),
      this.loadContas(),
      this.buscarInformacoesCentralAutorizacao()

    ]).finally(() => {
      let lastDay = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0); // ultimo dia do mês

      let mes = this.dblinkedSessionService.periodo.month.value > 9 ?
        this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;

      this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
      this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

      this.opcoesMoeda = [];
      this.pedidoService.getMoedaDesc(this.opcoesMoeda);

      this.subtitle = 'Todas as contas'

      if (this.contaSelecionada) {
        if (this.contaSelecionada.id) {
          this.subtitle = 'CONTA: ' + this.contaSelecionada.nome +
            (this.contaSelecionada.agencia ? ' - AG: ' + this.contaSelecionada.agencia : '') +
            (this.contaSelecionada.conta ? ' - CC: ' + this.contaSelecionada.conta : '') +
            (this.contaSelecionada.dvConta ? '-' + this.contaSelecionada.dvConta : '');
          this.listar01(this.filtroPadrao);
        } else {
          this.listar01(this.filtroPadrao);
        }
      } else {
        this.listar01(this.filtroPadrao);
      }

      this.subtitle += '. Período selecionado: ' + '01/' + mes + '/' + this.dblinkedSessionService.periodo.year.value
        + ' à ' + lastDay.getDate() + '/' + mes + '/' + this.dblinkedSessionService.periodo.year.value
    })
  }

  listar01(filtroPadrao: boolean) {

    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
      this.statusOptions = [];
      this.movimentacao = new Array<any>();
      this.contaOrigemAsaas = false

      this.filtroPadrao = filtroPadrao;

      this.getHistoryTransactions();

    }

    setTimeout(() => {
      this.loadNovaTransacao();
    }, 1000);
  }

  onRowSelect(event: any) {
    this.informacaoSelecionada = '';

    if ((event.data.nfe_fin_id !== 0) && (!event.data.operacao || (event.data.operacao === 'TRANSFERÊNCIA ENTRE CONTAS'))) {
      this.informacaoSelecionada = event.data;
      this.toolbarMain.setVisible('btn5', true)
      this.toolbarMain.setVisible('btn6', true)
    } else {
      this.toolbarMain.setVisible('btn5', false)
      this.toolbarMain.setVisible('btn6', false)
    }
  }

  onRowUnselect() {
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', false)
  }

  alternarContaSelecionada() {
    this.dataIni = this.dataIniSearch;
    this.dataFin = this.dataIniSearch;
    this.modalAlternarConta = false;
    if (this.contaSelecionada && this.contaSelecionada.nome) {
      this.subtitle = 'CONTA: ' + this.contaSelecionada.nome +
        (this.contaSelecionada.agencia ? ' - AG: ' + this.contaSelecionada.agencia : '') +
        (this.contaSelecionada.conta ? ' - CC: ' + this.contaSelecionada.conta : '') +
        (this.contaSelecionada.dvConta ? '-' + this.contaSelecionada.dvConta : '');
      this.listar01(false);
    } else {
      this.subtitle = 'Todas as contas';
      this.listar01(false);
    }
    let dataIni = `${this.dataIniSearch.substring(8, 10)}/${this.dataIniSearch.substring(5, 7)}/${this.dataIniSearch.substring(0, 4)}`,
      dataFin = `${this.dataFinSearch.substring(8, 10)}/${this.dataFinSearch.substring(5, 7)}/${this.dataFinSearch.substring(0, 4)}`;
    this.subtitle += `. Período selecionado: ${dataIni} à ${dataFin}`
  }

  loadContas() {
    this.skeletonConfig.startLoad()
    this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,instituicaoFinanceira,tipoConta/id,tipoConta/descricao,ativo'
      + '&$filter=empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId ? this.dblinkedSessionService.empresa.matrizId : this.dblinkedSessionService.empresa.id)
      + ' and ativo eq true&$orderby=nome')
      .subscribe(ref => {
        this.opcoesContas = [];
        this.opcoesContas.push({ label: 'Nenhum', value: null });

        ref.value.forEach(element => {
          if (!element.ativo) return

          if (element.instituicaoFinanceira !== null && element.instituicaoFinanceira !== undefined) {
            let labelConta = element.id.toString()

            if (element.nome !== null && element.nome !== undefined && element.nome !== '') labelConta += ` - ${element.nome}`
            if (element.agencia !== null && element.agencia !== undefined && element.agencia !== '') labelConta += ` - AG: ${element.agencia}`
            if (element.conta !== null && element.conta !== undefined && element.conta !== '') labelConta += ` - CC: ${element.conta}`
            if (element.dvConta !== null && element.dvConta !== undefined && element.dvConta !== '') labelConta += ` - ${element.conta}`
            if (element.tipoConta.descricao !== null && element.tipoConta.descricao !== undefined && element.tipoConta.descricao !== '') labelConta += ` - ${element.tipoConta.descricao}`

            this.opcoesContas.push({
              label: labelConta,
              value: element
            })

          } else this.opcoesContas.push({ label: element.id + ' - ' + element.nome, value: element })
        })
      },
        error => this.httpService.handleError(error, () => this.loadContas()),
        () => this.skeletonConfig.endLoad());

  }

  excluirTransferencia() {
    if (confirm('Deseja excluir esta transferência?')) {
      const listasEnviar = {
        movFinDetalId: this.informacaoSelecionada.mov_fin_detal_id,
        usuario: this.sessionService.loggedUser.id,
        empresaId: this.dblinkedSessionService.empresa.id
      }

      this.httpService.wait()
      let url = '/custom/financeiro/v2/excluir-transferencia'
      this.httpService.post(url, listasEnviar)
        .subscribe(
          result => {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result.message })
            this.listar01(false)
            this.httpService.done()

            //Gravando no log
            this.logManagement.registerLog(
              LogModule.FINANCE,
              LogScreen.MOVEMENT,
              url,
              LogAction.DELETE,
              this.informacaoSelecionada.mov_fin_detal_id,
              this.informacaoSelecionada.uuid,
              "Excluiu uma transferência",
              listasEnviar
            )
          },
          (error: HttpErrorResponse) => {
            if (error.status === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: error.error.message })
            else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: error.message })
            this.httpService.done()
          })
    }
  }

  loadNovaTransacao() {
    this.toolbarEnable();
    const childTransac: Array<ToolbarButton> = new Array();
    childTransac.push({
      icon: '',
      label: 'Transferência Bancária',
      callback: () => this.displayTransferenciaDialog()
    });
    /*childTransac.push({
      icon: '',
      label: 'Recebimento',
      callback: () => this.displayRecebimentoDialog(1)
    });
    childTransac.push({
      icon: '',
      label: 'Pagamento',
      callback: () => this.displayPgmtoDialog(2)
    });*/
    this.toolbarService.get('add').child = childTransac;
    /**
     * botoes de novas ações
     */
    const childActions: Array<ToolbarButton> = new Array();
    childActions.push({
      icon: '',
      label: (this.relatorioAnalitico ? 'Voltar relatório' : 'Expandir relatório'),
      callback: () => this.relAnalitico()
    });
    childActions.push({
      icon: '',
      label: (this.baixadosRecebidos ? 'Exibir todos' : 'Exibir baixados'),
      callback: () => this.relBaixadosRecebidos()
    });
    childActions.push({
      icon: '',
      label: 'Atualizar Saldo',
      callback: () => this.atualizarSaldo()
    });
    this.toolbarService.get('more_vert').child = childActions;
  }

  relBaixadosRecebidos() {
    this.baixadosRecebidos = !this.baixadosRecebidos;
    this.atualizarVoltar();
    this.changeOpt();
  }

  relAnalitico() {
    this.relatorioAnalitico = !this.relatorioAnalitico;
    this.atualizarVoltar();
    this.changeOpt();
  }

  changeOpt() {
    let opt = [
      { label: (this.relatorioAnalitico ? 'Voltar relatório' : 'Expandir relatório'), callback: () => this.relAnalitico() },
      { label: (this.baixadosRecebidos ? 'Exibir todos' : 'Exibir baixados'), callback: () => this.relBaixadosRecebidos() },
      { label: 'Atualizar Saldo', callback: () => this.atualizarSaldo() }
    ]
    this.toolbarMain.setOptions('btn1', opt)
  }

  salvarPagamento() {

    if (this.Validacao() === true) {
      return
    }

    const listasEnviar = {
      // movFinId: this.idMovFin,
      empresa: this.dblinkedSessionService.empresa.id,
      operacaoRegra: this.operacaoRegra.id,
      cliente: this.cliente.codigo,
      valorOperacao: this.utilService.parseMoney(this.valorOperacao),

      tituloNfe: this.tituloNfe,

      historicoOperacao: this.historicoOperacao,
      dtOperacao: this.dtOperacao,
      recorrencia: this.recorrencia,
      ocorrenciaMes: this.ocorrenciaMes,
      antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
      dtFimOccorencia: this.dtFimOccorencia,
      usuario: this.sessionService.loggedUser.id,
      empresaConta: this.contaSelecionadaId,
      // Salvar RateioCentroCusto
      rateio: this.movimentacaoRateios,
      novoRegistro: this.operacaoRegra.novoRegistro,

      // para pagar por CNAB @octavio
      tipoPagamentoId: this.tipoSelecionadoId,
      nDocumento: this.nDocumento,
      codBarras: this.codBarras,
      codReceita: this.codReceita,
      dtApuracao: this.dtApuracao,
      idRecolhimento: this.idRecolhimento,
      digitoIdRecolhimento: this.digitoIdRecolhimento,
      participanteContasId: this.participanteContasId,
      codRecolhimento: this.codRecolhimento,
      codPagamentoGps: this.codPagamentoGps,

      tipo: 'cp'


    };


    this.httpService.wait();
    this.httpService.post('/custom/financeiro/salvar-titulo', listasEnviar)
      .subscribe(result => {

        if (result[0].error === true) {

          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'MSG:',
            detail: result[0].message,

          });

          this.modalOperacaoPagto = false;
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG:',
            detail: result[0].message,

          });

          this.modalOperacaoPagto = false;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarPagamento()),
        () => this.httpService.done());
  }

  private loadParticipantes(participanteTipoId: number = null) {
    let lista = '/participante?$select=id,nome,fantasia,cpf,cnpj,participanteEmpresas/codigo,participanteEmpresas/fantasia' +
      '&$filter=participanteEmpresas/empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' and participanteEmpresas/participanteTipo/id eq ' + participanteTipoId;
    lista += '&$orderby= nome';
    this.httpService.wait();
    this.httpService.get(lista)
      .subscribe(ref => this.comboParticipante(ref.value),
        error => this.httpService.handleError(error, () => this.loadParticipantes(participanteTipoId)),
        () => this.httpService.done());
  }

  comboParticipante(value: Array<Participante>) {
    this.clientes = [];
    this.clientes.push({ label: 'Selecione', value: null });
    value.forEach(element => {

      let cnpj = '';

      if (element.cnpj) {
        cnpj = element.cnpj.toString().substring(0, 2) + '.' + element.cnpj.toString().substring(2, 5) + '.'
          + element.cnpj.toString().substring(5, 8) + '/' + element.cnpj.toString().substring(8, 12) + '-'
          + element.cnpj.toString().substring(12, 14);
      }

      if (element.fantasia) {
        this.clientes.push({
          label: element.participanteEmpresas[0].codigo + ' - ' +
            element.participanteEmpresas[0].fantasia + ' - ' + cnpj, value: element.id
        });
      } else {
        this.clientes.push({
          label: element.participanteEmpresas[0].codigo + ' - ' +
            element.participanteEmpresas[0].fantasia + ' - ' + cnpj, value: element.id
        });
      }
    });
  }

  salvarRecebimento() {

    if (this.Validacao() === true) {
      return;
    }


    const listasEnviar = {
      // movFinId: this.idMovFin,
      empresa: this.dblinkedSessionService.empresa.id,
      operacaoRegra: this.operacaoRegra.id,
      cliente: this.cliente.codigo,
      valorOperacao: this.utilService.parseMoney(this.valorOperacao),
      historicoOperacao: this.historicoOperacao,
      dtOperacao: this.dtOperacao,
      recorrencia: this.recorrencia,
      ocorrenciaMes: this.ocorrenciaMes,
      antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
      dtFimOccorencia: this.dtFimOccorencia,
      usuario: this.sessionService.loggedUser.id,
      empresaConta: this.contaSelecionadaId,
      // Salvar RateioCentroCusto
      rateio: this.movimentacaoRateios,
      novoRegistro: this.operacaoRegra.novoRegistro,
      tipo: 'cr'
    };
    this.httpService.wait();
    this.httpService.post('/custom/financeiro/salvar-titulo', listasEnviar)
      .subscribe(result => {

        if (result[0].error === true) {

          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'Mensagem',
            detail: result[0].message,

          });

          this.modalOperacao = false;
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'Mensagem',
            detail: result[0].message,

          });
          this.modalOperacao = false;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarRecebimento()),
        () => this.httpService.done());
  }

  displayTransferenciaDialog() {
    if (this.contaSelecionada) {

      this.edicaoTransferencia = false;
      this.dtTransferencia = this.defaultDate
      this.contaDestino = null
      this.contaOrigem = null
      this.modalTransferenciaBancaria = true
      this.valorTaxa = 1
      this.moedaSelecionadaId = 1
      this.valorTransferencia = 0
      this.valorTransferenciaReal = 0
      this.historicoOperacao = null
      // this.permiteMultiMoeda = false


    }
  }

  loadParticipanteTipo() {

    // switch (this.contaDestino.tipoConta.id) {
    //   case 9:
    //     this.participanteTipo = 1;
    //     break;
    //   case 2:
    //     this.participanteTipo = 2;
    //     break;
    //   case 8:
    //     this.participanteTipo = 2;
    //     break;
    //   default:
    //     this.participanteTipo = 0;
    //     break;
    // }

    this.loadParticipantes(this.participanteTipo);
  }

  transferencia() {

    if (this.contaOrigem === this.contaDestino) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Conta destino deve ser diferente da origem' })
      return

    } else if (isNullOrUndefined(this.contaDestino)) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Conta destino deverá ser informada' })
      return

    } else if (isNullOrUndefined(this.contaOrigem)) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Conta origem deverá ser informada' })
      return

    } else if (this.utilService.parseMoney(this.valorTransferenciaReal) <= 0) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Necessário informar valor' })
      return
    }

    let transfId = null
    let novo = true
    if (this.informacaoSelecionada?.uuid) {
      transfId = this.informacaoSelecionada?.uuid
      novo = false
    }

    if (!novo && this.statusId != null && (this.statusId === 135 || this.statusId === 137)) this.solicitarAutorizacao = true

    const listasEnviar = {
      uuid: transfId,
      userId: this.sessionService.loggedUser.id,
      contaOrigemId: this.contaOrigem.id,
      contaDestinoId: this.contaDestino.id,
      moedaId: this.moedaSelecionadaId,
      dataMov: this.convertDateToStringDate(this.dtTransferencia),
      valor: this.utilService.parseMoney(this.valorTransferenciaReal),
      txMoeda: this.utilService.parseDecimal4(this.valorTaxa),
      historico: this.historicoOperacao,
      empresaId: this.dblinkedSessionService.empresa.id,
      movFinDetalId: this.informacaoSelecionada?.mov_fin_detal_id,
      solicitarAutorizacao: this.solicitarAutorizacao
    }

    let url = '/custom/financeiro/v2/transferencia-bancaria'
    this.httpService.wait()
    this.httpService.post(url, listasEnviar)
      .subscribe(
        result => {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result.message })
          let uuidCriado: string = result.data
          this.modalTransferenciaBancaria = false
          this.listar01(false);
          this.httpService.done()

          //Gravando no log
          this.logManagement.registerLog(
            LogModule.FINANCE,
            LogScreen.MOVEMENT,
            url,
            novo ? LogAction.CREATE : LogAction.EDIT,
            novo ? null : this.informacaoSelecionada.mov_fin_detal_id,
            novo ? uuidCriado : transfId,
            novo ? "Criou uma movimentação bancária" : "Editou uma transferência bancária",
            listasEnviar
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === 400) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: error.error.message })
          else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: error.message })
          this.httpService.done()
        }
      )
  }

  // start centro de custo e projeto
  controlCentroCusto(cadCustoId = null) {

    if ((this.rateioCentroCusto <= 0) || (this.rateioCentroCusto < this.porcentagemCc)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'O valor do rateio é 100%, selecione um rateio para alterar!'
      });
      return;
    }
    this.modalCentroCusto = true;
    this.contadorRateio = 0;

    this.httpService.wait();
    this.httpService.get('/cadccus?$select=id,codCcus,descrCcus,projetosRelacionados,ativo' +
      '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value + '&$orderby=codCcus')
      .subscribe(cc => this.comboCcus(cc.value),
        error => this.httpService.handleError(error, () => this.controlCentroCusto(cadCustoId)),
        () => this.httpService.done());

    if (!isNullOrUndefined(cadCustoId)) {
      this.centroCustoId = cadCustoId;
    } else {
      this.centroCustoId = 0;
    }

    this.httpService.wait();
    this.httpService.get('/custom/financeiro/get-projetos(' + this.dblinkedSessionService.empresa.id + ','
      + this.centroCustoId + ')')
      .subscribe(proj => {
        this.comboProjeto(proj);
      },
        error => this.httpService.handleError(error, () => this.controlCentroCusto(cadCustoId)),
        () => this.httpService.done());
  }

  comboCcus(value: Array<any>) {
    this.opcoesCentroCusto = [];
    this.opcoesCentroCusto.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      if (element.ativo) {
        element.label = element.codCcus + ' - ' + element.descrCcus;
        this.opcoesCentroCusto.push(element);
      }

    });
  }

  comboProjeto(value: Array<any>) {
    this.opcoesProjetos = [];
    this.opcoesProjetos.push({ label: 'Nenhum', value: null });
    if (value.length < 1) {
      this.existeProjetosRelacionados = false;
    }
    value.forEach(element => {
      element.label = element.codProj + ' - ' + element.descrProj;
      this.opcoesProjetos.push(element);
      this.existeProjetosRelacionados = element.existeProjetosRelacionados;
    });
  }

  fecharModalRateio() {
    this.modalCentroCusto = false;
    // this.rateioCentroCusto = 100;
  }

  removerRateioCentroCusto(event: any) {
    if (confirm('Deseja excluir este rateio?')) {
      const index = this.movimentacaoRateios.indexOf(event);
      this.movimentacaoRateios.splice(index, 1);
    }
    this.rateioCentroCusto = 100;
    this.movimentacaoRateios.forEach(element => {
      this.rateioCentroCusto = Math.round((this.rateioCentroCusto - this.utilService.parseDecimal(element.porcentagem)) * 100) / 100;
    });
  }

  incluirRateioCentroCusto() {
    if ((this.rateioCentroCusto <= 0) || (this.rateioCentroCusto < this.porcentagemCc)) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'O valor do rateio ultrapassa os 100%, verifique!',

      });

      return;
    }

    if (this.existeProjetosRelacionados && isNullOrUndefined(this.proj.id)) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Para prosseguir, Insira um Projeto!',

      });


      return;
    }

    if (isNullOrUndefined(this.cc.id)) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Para prosseguir, Insira um Centro de Custo!',

      });

      return;
    }

    this.financeiroRateio = new FinanceiroRateio();
    this.rateioCentroCusto = Math.round((this.rateioCentroCusto - this.utilService.parseDecimal(this.porcentagemCc)) * 100) / 100;
    this.financeiroRateio.id = this.contadorRateio;
    this.financeiroRateio.cadProj = this.proj;
    this.financeiroRateio.cadCcus = this.cc;
    this.financeiroRateio.porcentagem = this.porcentagemCc;

    this.movimentacaoRateios.push(this.financeiroRateio);
    this.contadorRateio++;
    this.proj = [];
    this.porcentagemCc = 0;
  }

  editarCentroCusto() {
    this.modalCentroCusto = true;
    this.rateioCentroCusto = 100;
    this.movimentacaoRateios.forEach(element => {
      this.rateioCentroCusto = Math.round((this.rateioCentroCusto - this.utilService.parseDecimal(element.porcentagem)) * 100) / 100;
      this.contadorRateio++;
    });
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  atualizarVoltar() {

    // start centro de custo e projetos
    this.centroCusto = new CadCcus;
    this.cadProj = new cadProjModel.CadProj;
    this.modalCentroCusto = false;
    this.porcentagemCc = 0;
    this.cc = [];
    this.proj = [];
    this.rateioCentroCusto = 100;
    this.rateioCadProj = 100;
    this.movimentacaoRateios = new Array();
    // end centro de custo e projetos

    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() > 8 ?
        (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() > 8 ?
        (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 9) ?
        this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
        this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }

    if (this.dataIni > this.dataFin) {

      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Data inicial maior que a final. Verifique!',

      });
      return;
    }

    let dataIni = `${this.dataIniSearch.substring(8, 10)}/${this.dataIniSearch.substring(5, 7)}/${this.dataIniSearch.substring(0, 4)}`,
      dataFin = `${this.dataFinSearch.substring(8, 10)}/${this.dataFinSearch.substring(5, 7)}/${this.dataFinSearch.substring(0, 4)}`;

    if (this.contaSelecionada && this.contaSelecionada.nome) {
      this.subtitle = 'CONTA: ' + this.contaSelecionada.nome +
        (this.contaSelecionada.agencia ? ' - AG: ' + this.contaSelecionada.agencia : '') +
        (this.contaSelecionada.conta ? ' - CC: ' + this.contaSelecionada.conta : '') +
        (this.contaSelecionada.dvConta ? '-' + this.contaSelecionada.dvConta : '');
    } else {
      this.subtitle = 'Todas as contas';
    }

    this.subtitle += `. Período selecionado: ${dataIni} à ${dataFin}`
    this.modalPeriodo = false;
    if (this.contaSelecionada) {
      if (this.contaSelecionada.id) {
        this.listar01(false);
      } else {
        this.listar01(false);
      }
    } else {
      this.listar01(false);
    }
  }

  private toolbarEnable() {
    const todos = this.buttons.slice();

    if (todos.length === 0) {
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.atualizarVoltar());
      this.toolbarService.replace('edit', false,
        'class-button-gray', 'Alterar Período/Conta', 'Alterar Período/Conta', () => this.openModalPeriodo());
      this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Opções');
      // this.toolbarService.replace('download', true, 'class-other-button', 'cloud_download', 'Download', );
      // this.toolbarService.replace('cancel', true, 'class-other-button class-botao-margin', 'block', 'Cancelar', );
      // this.toolbarService.replace('export', true, 'class-other-button', 'description', 'Exportar', );
      this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir',);
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir',);
      this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');


      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo');
    } else {
      this.toolbarService.ocultar('voltar');
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.atualizarVoltar());
      this.toolbarService.replace('edit', false,
        'class-button-gray', 'Alterar Período/Conta', 'Alterar Período/Conta', () => this.openModalPeriodo());

      this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Opções');
      this.toolbarService.replace('pesquisa', true, 'class-edit-button', 'search', 'Pesquisar',);
      this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar');

      this.toolbarService.replace('print', true, 'class-edit-button', 'print', 'Imprimir');
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir',);
      this.toolbarService.replace('msg', true, 'class-edit-button', 'edit', 'Editar');

      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo');
    }
  }

  openModalPeriodo() {
    this.modalPeriodo = true
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch)
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch)
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  Validacao() {

    if ((isNullOrUndefined(this.operacaoRegra.id) || this.operacaoRegra.id === 0) && !this.tituloNfe) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor selecionar uma operação!',

      });

      return true;
    }


    if (isNullOrUndefined(this.dtOperacao)) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor selecionar uma data de Operação!',

      });

      return true;
    }


    if (isNullOrUndefined(this.contaSelecionadaId) || this.contaSelecionadaId === 0) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor selecionar uma conta!',

      });

      return true;
    }

    if (isNullOrUndefined(this.valorOperacao) || this.valorOperacao <= 0) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor digitar o valor da operação!',

      });

      return true;
    }

    if ((isNullOrUndefined(this.historicoOperacao) || this.historicoOperacao === '') && !this.tituloNfe) {


      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor digitar o histórico da operação!',

      });

      return true;
    }

    // validação para CNAB @octavio
    if ([1, 5].includes(this.cnabFormaSelecionadaId) && (this.cliente.codigo == '' || isNullOrUndefined(this.cliente.codigo))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor selecionar um fornecedor.',

      });

      return true;
    }

    if ([5].includes(this.cnabFormaSelecionadaId) && (this.participanteContasId == '' || isNullOrUndefined(this.participanteContasId))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor selecionar a conta de um fornecedor.',

      });

      return true;
    }

    if ([1, 3, 6].includes(this.cnabFormaSelecionadaId) && (this.codBarras === '' || isNullOrUndefined(this.codBarras))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor inserir um código de barras.',

      });

      return true;
    }

    if ([1, 3, 6].includes(this.cnabFormaSelecionadaId) && (this.codBarras === '' || isNullOrUndefined(this.codBarras))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor inserir um código de barras.',

      });

      return true;
    }

    if ([2, 3, 4, 6].includes(this.cnabFormaSelecionadaId) && (this.dtApuracao === '' || isNullOrUndefined(this.dtApuracao))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor inserir a data de apuração.',

      });

      return true;
    }

    if ([2].includes(this.cnabFormaSelecionadaId) && (this.codReceita === '' || isNullOrUndefined(this.codReceita))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor inserir o código da receita.',

      });

      return true;
    }


    if (this.cnabFormaSelecionadaId == 4 && (this.codPagamentoGps === '' || isNullOrUndefined(this.codPagamentoGps))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor inserir o código de pagamento.',

      });

      return true;
    }

    if (this.cnabFormaSelecionadaId == 3 && (this.codRecolhimento === '' || isNullOrUndefined(this.codRecolhimento))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor inserir o código de recolhimento.',

      });

      return true;
    }


    if (this.cnabFormaSelecionadaId == 3 && (this.idRecolhimento === '' || isNullOrUndefined(this.idRecolhimento))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor inserir o id de recolhimento.',

      });

      return true;
    }


    if (this.cnabFormaSelecionadaId == 3 && (this.digitoIdRecolhimento === '' || isNullOrUndefined(this.digitoIdRecolhimento))) {

      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: 'Favor inserir o dígito do id de recolhimento.',

      });
      return true;
    }

    // validação centro de custo @Guilherme
    if (this.centroCustoObrigatorio && this.existeContaResultado) {
      if (this.movimentacaoRateios.length < 1) {

        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG:',
          detail: 'Para prosseguir, Insira um Centro de Custo!',

        });

        return true;
      }
    }

    return false;
  }

  existeContaResultadoParaOperacaoRegra(operacaoRegraContabilId) {

    this.httpService.wait();
    this.httpService.get('/custom/financeiro/existe-conta-resultado-para-operacao-regra(' + operacaoRegraContabilId + ')')
      .subscribe(element => {
        this.existeContaResultado = element.existeContaResultado;
      },
        error => this.httpService.handleError(error, () => this.existeContaResultadoParaOperacaoRegra(operacaoRegraContabilId)),
        () => this.httpService.done());
  }

  //pegando as contas do participante e setando a principal como 'default' @octavio 23/03/2020
  loadParticipanteContas(idParticipante) {
    this.httpService.wait();
    this.httpService.get('/custom/financeiro/get-contas-participante(' + idParticipante + ')')
      .subscribe(
        ret => {
          this.contasFornecedorLista = [];
          ret.forEach(element => {
            this.contasFornecedorLista.push({
              label: element["banco"],
              value: element["contaId"]
            });

          });
        },
        error => {
          console.log(error);
          this.httpService.done();
        },
        () => {
          this.httpService.done();
        });
  }

  onInputDate(event: any) {
    let value = event.target.value.replace(/^(\d{1,2})\/?(\d{1,2})\/?(\d{1,4})/, '$1$2$3').split(/\D/)[0],
      response = '';

    if (value.slice(0, 2).length > 0) {
      response = value.slice(0, 2) <= 31 ? value.slice(0, 2) : ''
      if (value.slice(2, 4).length > 0) {
        response += '/' + (value.slice(2, 4) <= 12 ? value.slice(2, 4) : '')
        if (value.slice(4, 8).length > 0) {
          response += '/' + (value.slice(6, 7) >= 1 && value.slice(6, 7) <= 3 ? value.slice(4, 8) : value.slice(4, 6))
        }
      }
    }
    event.target.value = response
  }

  onChangeContaFornecedor(event) {
    this.loadParticipanteContas(event.value);

  }

  onChangeTipoPagamento(event) {
    this.codBarras = '';
    this.codReceita = '';
    this.dtApuracao = '';
    this.codPagamentoGps = '';
    this.codRecolhimento = '';
    this.idRecolhimento = '';
    this.digitoIdRecolhimento = '';
    for (let i = 0; i < this.tiposLista.length; i++) {
      if (this.tiposLista[i]["value"] === event["value"]) {
        this.cnabFormaSelecionadaId = this.tiposLista[i]["cnab_id"];
      }
    }
  }

  /*Show Hide Mov*/
  isCardMov: boolean = true;
  ShowHIdeMov() {
    this.isCardMov = !this.isCardMov;
    this.toolbarMain.setIcon('btn7', this.isCardMov ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn7', this.isCardMov ? 'Ver menos' : 'Ver mais')
  }

  async getMoreTransactions(event: LazyLoadEvent) {
    if (this.carregarNovamente) await this.getHistoryTransactions(event.first, event.rows, event.filters, event.sortField, event.sortOrder, false)
    else this.carregarNovamente = true
  }

  async getHistoryTransactions(offset: number = 0, limit: number = 15, filters: Object = {}, sortField: string = "", sortOrder: number = 0, defaultParam: boolean = true) {

    if (this.dblinkedSessionService.hasEmpresaSelected) {

      if (!this.dblinkedSessionService.empresa.altoVolume || !this.filtroPadrao) {
        const infos = {
          empresaContaId: this.contaSelecionada ? this.contaSelecionada.id : null,
          empresaContaPadraoId: this.contasPadrao,
          dtIni: this.dataIniSearch,
          dtFin: this.dataFinSearch,
          empresaId: this.dblinkedSessionService.empresa.id,
          filiaisSelecionadas: this.dblinkedSessionService.filiaisSelecionadas,
          limit: limit,
          offset: offset,
          sortOrder: sortOrder,
          sortField: sortField,
          filters: filters
        }

        this.skeletonConfig.startLoad()
        this.httpClient.post(this.configService.defaultUrlApiUpload + 'financeiro/buscar-movimentacao-bancaria', infos, this.httpOptions)
          .subscribe(
            result => {
              this.informacaoSelecionada = null
              this.movimentacao = result["data"]["mov"]
              this.totalRecords = result["data"]["total"]
              this.saldoInicial = result["data"]["saldo_ini"]
              this.valorFiltro = result["data"]["valor_mov"]
              this.saldoAtual = this.saldoInicial + this.valorFiltro
              this.statusOptions = []
              result["data"]["status"].forEach(status => this.statusOptions.push({ label: status, value: status }))
              this.skeletonConfig.endLoad()
            },
            error => {
              this.skeletonConfig.endLoad()
              if (error.error.message) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: error.error.message + " " + error.error.data })
              else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar as movimentações, contate o suporte: ' + error.message })
            }
          )
      }
    }
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({
        severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem
      });

    } else {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem
      });
    }
  }

  gerarRelatorio() {
    let payload = {
      empresaContaId: this.contaSelecionada ? this.contaSelecionada.id : null,
      empresaContaPadraoId: this.contasPadrao,
      dtIni: this.dataIniSearch,
      dtFin: this.dataFinSearch,
      empresaId: this.dblinkedSessionService.empresa.id,
      filiaisSelecionadas: this.dblinkedSessionService.filiaisSelecionadas,
      userId: this.sessionService.loggedUser.id
    }

    this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aguarde', detail: 'Essa operação pode demorar alguns minutos, por favor aguarde.' })
    this.httpService.wait()
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'financeiro/relatorio-mov-bancaria', payload, this.httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          if (res["error"]) return this.httpService.done()

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          };

          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/relatorio-mov-bancaria', httpOptions2)
            .subscribe(
              (res: Blob) => {
                let link = document.createElement('a');
                let url = window.URL.createObjectURL(res);
                let fileName = `Relatório de movimentação bancária.xlsx`;
                link.href = url;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
                this.httpService.done();
              }, err => {
                this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível baixar o relatório." });
                console.log(err);
                this.httpService.done();
              });

        }, err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível gerar o relatório." });
          console.log(err);
          this.httpService.done();
        });
  }

  onChangeContaBancaria(event: any, parametro: string) {
    if (parametro === 'select') {
      if (this.confTaxaId != 0 && event.value.id) {

        this.httpService.wait();
        this.httpService.get('/custom/financeiro/buscar-conf-taxa',
          [this.confTaxaId, event.value.id, this.utilService.dateFormat(this.dtTransferencia), 0])
          .subscribe(ref => {
            this.valorTaxa = this.utilService.parseDecimal4(ref.taxa);
            this.moedaSelecionadaId = ref.moeda > 1 ? ref.moeda : 1;

          },
            error => this.httpService.handleError(error, () => this.onChangeContaBancaria(event, parametro)),
            () => this.httpService.done());
      }

      if (this.contaOrigem.id === this.contaBancariaAsaasId) this.contaOrigemAsaas = true
      else this.contaOrigemAsaas = false
    } else if (parametro === 'moeda') {

      if (this.confTaxaId != 0) {

        this.httpService.wait();
        this.httpService.get('/custom/financeiro/buscar-conf-taxa',
          [this.confTaxaId, this.contaOrigem.id, this.utilService.dateFormat(this.dtTransferencia), this.moedaSelecionadaId])
          .subscribe(ref => {

            this.valorTaxa = this.utilService.parseDecimal4(ref.taxa);
            this.moedaSelecionadaId = ref.moeda > 1 ? ref.moeda : 1;

          },
            error => this.httpService.handleError(error, () => this.onChangeContaBancaria(event, parametro)),
            () => this.httpService.done());
      }


    } else {
      if (this.confTaxaId != 0) {
        this.httpService.wait();
        this.httpService.get('/custom/financeiro/buscar-conf-taxa',
          [this.confTaxaId, event.value.id, this.utilService.dateFormat(this.dtTransferencia), 0])
          .subscribe(ref => {

            this.valorTaxa = this.utilService.parseDecimal4(ref.taxa);
            this.moedaSelecionadaId = ref.moeda > 1 ? ref.moeda : 1;

          },
            error => this.httpService.handleError(error, () => this.onChangeContaBancaria(event, parametro)),
            () => this.httpService.done());
      }
    }
  }

  loadParametroFinanceiro() {
    this.skeletonConfig.startLoad()
    this.httpService.get('/empresaparametrofinanceiro?$select=visualizaPedido,visualizaProvisao,participanteObrigatorio,confTaxa/id,permiteAlterarMoeda,permiteMultiMoeda&$filter=empresa/id eq '
      + this.dblinkedSessionService.empresa.id)
      .subscribe(ret => {
        ret.value.forEach(element => {
          this.confTaxaId = isNullOrUndefined(element.confTaxa) ? 0 : element.confTaxa.id;
          this.permiteAlterarMoeda = element.permiteAlterarMoeda;
          this.permiteMultiMoeda = element.permiteMultiMoeda;
          if (!element.permiteMultiMoeda) {
            this.valorTaxa = 1;
            this.moedaSelecionadaId = 1;
          }
        });
        this.skeletonConfig.endLoad()
      },
        error => {
          this.httpService.handleError(error, () => this.loadParametroFinanceiro())
          this.skeletonConfig.endLoad()
        })
  }

  convertValorMoeda() {
    this.valorTransferenciaReal = this.utilService.parseDecimal(this.utilService.parseDecimal(this.valorTransferencia) * this.utilService.parseDecimal4(this.valorTaxa))
  }

  atualizarSaldo() {

    if (confirm('Deseja realmente recalcular os saldos financeiros?')) {

      //Verificar que tem uma conta
      if (!this.contaSelecionada.id) {
        this.exibirMensagem({ error: true, mensagem: "É necessário selecionar uma conta só para recalcular o saldo." })
        return
      }

      //Buscar o ecd_id correspondente
      let dtIni: string = this.dblinkedSessionService.periodo.year.value + "-" + (this.dblinkedSessionService.periodo.month.value) + "-1"

      this.httpService.wait()
      this.httpService.get("/cadecd?$select=id&$filter=empresa/id eq " + this.dblinkedSessionService.empresa.id + " and dtIni eq '" + dtIni + "'")
        .subscribe(
          res => {
            const infos = {
              empresa_id: this.dblinkedSessionService.empresa.id,
              ecd_id: res.value[0].id,
              user_id: this.sessionService.loggedUser.id,
              contas: this.contaSelecionada.id
            }

            this.httpService.wait()
            this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/recalcular-saldo-fin', infos)
              .subscribe(
                res => {
                  this.exibirMensagem(res)
                  this.httpService.done()
                  this.getHistoryTransactions()
                },
                err => {
                  console.log(err)
                  this.exibirMensagem({ error: true, mensagem: "Erro no API ao recalcular os saldos, contate o suporte: " + err.message })
                  this.httpService.done()
                }
              );

          },
          err => {
            console.log(err)
            this.exibirMensagem({ error: true, mensagem: "Erro ao procurar o id do periodo, contate o suporte: " + err.message })
            return
          },
          () => this.httpService.done()
        )
    }

    // if (confirm('Esta operação pode levar alguns minutos, deseja continuar?')) {


    //   this.httpService.wait();
    //   this.httpService.get('/custom/ecd/get-cad-ecd-recalcula-saldo',
    //     [this.dblinkedSessionService.empresa.id,this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value])
    //     .subscribe(retorno => {
    //           if(retorno.erro){
    //             this.msgs = [];
    //             this.msgs.push({ severity: 'warn', summary: 'MSG:', detail: retorno.message });
    //           } else {
    //             this.msgs = [];
    //             this.msgs.push({ severity: 'success', summary: 'Sucesso', detail: retorno.message });
    //           }



    //     },
    //       error => this.httpService.handleError(error, () => this.atualizarSaldo()),
    //       () => this.httpService.done());



    // }

  }

  editarTransferencia() {

    this.modalTransferenciaBancaria = true
    this.edicaoTransferencia = true

    this.httpService.wait()
    this.httpService.get('/custom/financeiro/buscar-info-transferencia(' + this.informacaoSelecionada.mov_fin_detal_id + ')')
      .subscribe(result => {
        this.dtTransferencia = new Date(Number(result.data.split("/")[2]), Number(result.data.split("/")[1]) - 1, Number(result.data.split("/")[0]))
        this.historicoOperacao = result.historicoOperacao
        this.valorTransferenciaReal = result.valor
        this.moedaSelecionadaId = result.moedaId
        this.valorTaxa = result.taxaMoeda
        this.valorTransferencia = this.valorTransferenciaReal / this.valorTaxa
        this.statusId = result.statusId

        this.opcoesContas.forEach(conta => {
          if (conta.value?.id === result.empresaOrigemId) {
            this.contaOrigem = conta.value
          }
        })

        this.opcoesContas.forEach(conta => {
          if (conta.value?.id === result.empresaDestinoId) {
            this.contaDestino = conta.value
          }
        })
      },
        error => this.httpService.handleError(error, () => this.editarTransferencia()),
        () => this.httpService.done());

  }

  convertDateToStringDate(data: Date): string {
    return data.getFullYear() + "-" + (data.getMonth() + 1 < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1)) + "-" + (data.getDate() < 10 ? "0" + data.getDate() : data.getDate())
  }

  async buscarContasPadrao() {
    this.skeletonConfig.startLoad()
    await this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/contas-bancarias-padrao?empresaId=' + this.dblinkedSessionService.empresa.id, this.httpOptions)
    .toPromise()
    .then(
        res => {
          this.contasPadrao = new Array()
          res["data"].forEach(row => this.contasPadrao.push(row.id))
          this.skeletonConfig.endLoad()
        },
        error => {
          this.skeletonConfig.endLoad()
          this.contasPadrao = new Array()
          if (error.error.message) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: error.error.message })
          else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar as contas padrões da empresa, contate o suporte: ' + error.message })
        }
      )
  }

  buscarInformacoesCentralAutorizacao() {
    this.httpService.wait()
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'workflow/busca-infos-autorizacoes/' + this.dblinkedSessionService.empresa.id, this.httpOptions).subscribe(res => {
      this.contaBancariaAsaasId = res["data"]["conta_asaas_id"]
    }, error => {
      if (error.status != 400) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro ao buscar informações das autorizações de pagamento!'})
    })
  }
}
