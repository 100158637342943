import { OnInit, OnDestroy, Component, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from 'app/shared/services/session.service';
import { UtilService } from 'app/shared/services/util.service';
import { isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs/Subject';
import { CrudService } from 'app/shared/services/crud.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { ConfigService } from 'app/shared/services/config.service';
import { isNullOrUndefined } from 'util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { TipoBaixa } from 'app/util/model/tipo-baixa.model';
import { Message } from 'primeng/api';
import { SelectItem } from 'app/shared/common/select-item.interface';

declare var $: any;
@Component({
  selector: 'app-calendario-new',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'calendario-new.component.html',
  styleUrls: ['./calendario-new.component.scss'
  ],
  providers: [CrudService],
})

export class CalendarioNewComponent implements OnInit, OnDestroy {

  currentState = 'initial';
  ctrlClick: boolean = false;
  calendarioUrl: string;
  viewDate: Date = new Date();
  view: string = 'month';
  msgs: Message[];
  listaEventos: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();

  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;
  periodo: string;
  private sessionSubscription: Subscription;
  activeDayIsOpen: boolean;
  locale: string = 'pt';
  linkCalendario: string;

  corRecebimentoAberto: string = '#488aff';
  corRecebimentoBaixado: string = '#488a99';
  corPagamentoAberto: string = '#ff2222';
  corPagamentoBaixado: string = '#992222';
  corDesconhecida: string = '#999999'
  pt_BR: any;
  statusTela1: boolean = true;

  //Modal variables
  modalEdicao: boolean = false;
  modalBaixar: boolean = false;
  editData: Date;
  dataBaixa: Date;
  opcaoDataBanco: Array<any>;
  selectedOption: any;
  selectBaixa: Array<any>;
  editId: number;
  idBaixa: number;
  dicView: { [id: number]: string };
  isTipoBaixa: boolean = true;
  opcoesTiposBaixa: SelectItem[];
  opcoesContaBancaria: SelectItem[];
  tipoBaixa: TipoBaixa;
  contaSelecionada: EmpresaConta;

  /*FullScreen Calendar*/
  fullCalendar: boolean = false;

  /*Show Hide Calendar*/
  isCardCalendar: boolean = true;

  //Para edição e exclusão de evento
  // actions: CalendarEventAction[] = [
  //   {
  //     label: ' <i class="fas fa-pencil-alt"></i>',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       this.openModalEvento(event);
  //     },
  //   },
  //   {
  //     label: ' <i class="fas fa-bolt"></i>',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       this.openModalBaixa(event);
  //     },
  //   },
  // ];

  constructor(
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private router: Router,
  ) { }

  //para o título abrir em uma nova janela com ctrl+click
  // @HostListener('window:keydown.control', ['$event'])
  // @HostListener('window:keyup.control', ['$event'])
  // keyEvent(event: KeyboardEvent) {
  //   if (event.type == "keydown") {
  //     this.ctrlClick = true;
  //   }
  //   else if (event.type == "keyup") {
  //     this.ctrlClick = false;
  //   }
  // }

  ngOnInit() {
    // /*HIDE SIDEBAR FULLSCREEN (DO NOT REMOVE)*/
    // $(document).ready(() => {
    //   $(".btn-full").click(
    //     function () {
    //       $(".mat-drawer-side").toggleClass('d-none');
    //     }
    //   );
    // });

    // this.pt_BR = {
    //   firstDayOfWeek: 0,
    //   dayNames: [
    //     'Domingo',
    //     'Segunda-Feira',
    //     'Terça-Feira',
    //     'Quarta-Feira',
    //     'Quinta-Feira',
    //     'Sexta-Feira',
    //     'Sábado'
    //   ],
    //   dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    //   dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    //   monthNames: [
    //     'Janeiro',
    //     'Fevereiro',
    //     'Março',
    //     'Abril',
    //     'Maio',
    //     'Junho',
    //     'Julho',
    //     'Agosto',
    //     'Setembro',
    //     'Outubro',
    //     'Novembro',
    //     'Dezembro'
    //   ],
    //   monthNamesShort: [
    //     'Jan',
    //     'Fev',
    //     'Mar',
    //     'Abr',
    //     'Mai',
    //     'Jun',
    //     'Jul',
    //     'Ago',
    //     'Set',
    //     'Out',
    //     'Nov',
    //     'Dez'
    //   ],
    //   today: 'Hoje',
    //   clear: 'Limpar'
    // };

    // this.calendarioUrl = this.configService.defaultUrlApiUpload;

    this.defaultDate = new Date();
    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;
    this.toolbarEnable();
  }

  ngOnDestroy() {
    // this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  changeState() {
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }

  // eventClicked({ event }: { event: CalendarEvent }): void {
  //   let url = '';
  //   if ([this.corPagamentoAberto,
  //   this.corPagamentoBaixado].includes(event.color.primary)) {
  //     url = '/receb-pagto/cp/baixar/' + event.id;
  //   }
  //   else if ([this.corRecebimentoAberto,
  //   this.corRecebimentoBaixado].includes(event.color.primary)) {
  //     url = '/receb-pagto/cr/baixar/' + event.id;
  //   }

  //   if (url.length > 4) {
  //     this.router.navigateByUrl(url);
  //   }
  // }

  // dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

  //   if (isSameMonth(date, this.viewDate)) {
  //     if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
  //       this.activeDayIsOpen = false;
  //     }
  //     else {
  //       this.activeDayIsOpen = true;
  //     }
  //     this.viewDate = date;
  //   }
  // }

  // onEditFieldChange(item) {
  //   this.selectedOption = item;
  //   if (item === 'alteraConta') {
  //   }
  // }

  // getEventos() {

  // }

  toolbarEnable() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('add');
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('remove');
  }

  ShowHIdeCardCalendar() {
    this.isCardCalendar = !this.isCardCalendar;
  }

  fullScreenCalendar() {
    this.fullCalendar = !this.fullCalendar;
  }

  // showTela(tipo) {
  //   this.statusTela1 = false;

  //   if (tipo === 'TELA_1') {
  //     this.statusTela1 = true;

  //   }

  // }

  // /*MODAL EVENTO */
  // openModalEvento(event) {
  //   this.selectedOption = null;
  //   this.opcaoDataBanco = new Array();
  //   this.editData = event.start;
  //   this.editId = event.id;
  //   this.opcaoDataBanco.push({ label: 'Selecione', value: '' });
  //   this.opcaoDataBanco.push({ label: 'Data Vencimento', value: 'alteraData' });
  //   this.opcaoDataBanco.push({ label: 'Conta bancária', value: 'alteraConta' });

  //   this.httpService.wait();
  //   this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,instituicaoFinanceira,tipoConta/id'
  //     + '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id
  //     + '&$orderby=nome')
  //     .subscribe(ref => this.loadContasBancarias(ref.value),
  //       error => this.httpService.handleError(error, () => this.openModalEvento(event)),
  //       () => this.httpService.done());

  //   this.modalEdicao = true;
  // }

  // loadTipoBaixa(array: Array<TipoBaixa>) {
  //   this.tipoBaixa = new TipoBaixa();
  //   this.tipoBaixa.id = 0;
  //   this.opcoesTiposBaixa = [];
  //   array.forEach(tipoBaixa => {
  //     this.opcoesTiposBaixa.push({ label: tipoBaixa.descricao, value: tipoBaixa.id });
  //   });
  // }

  // loadContasBancarias(array: Array<EmpresaConta>) {
  //   this.contaSelecionada = new EmpresaConta();
  //   this.opcoesContaBancaria = [];
  //   array.forEach(conta => {
  //     this.opcoesContaBancaria.push({ label: conta.nome, value: conta.id });
  //   });

  // }

  // hideMessage() {
  //   this.msgs = [];
  // }

  // atualizarEvento() {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization': this.configService.bearerApi,
  //       'data': this.editData.toString(),
  //       'id': this.editId.toString(),
  //       'userid': this.sessionService.loggedUser.id.toString(),
  //       'view': this.dicView[this.editId].toString(),
  //       'conta': this.contaSelecionada.toString()
  //     })
  //   };

  //   this.modalEdicao = false;
  //   this.httpService.wait();
  //   this.httpClient.post(this.configService.defaultUrlApiUpload + 'financeiro/calendario/editevento', null, httpOptions)
  //     .subscribe(
  //       res => {
  //         this.httpService.done();
  //       },
  //       err => {
  //         this.exibirMensagem(err);
  //         this.httpService.done();
  //         console.log(err);
  //       }
  //     );

  // }

  // exibirMensagem(value: any) {
  //   if (!value.error) {
  //     this.msgs = [];
  //     this.msgs.push({ severity: 'success', summary: 'Sucesso', detail: value.mensagem });

  //   } else {
  //     this.msgs = [];
  //     this.msgs.push({ severity: 'warn', summary: 'Erro', detail: value.mensagem });
  //   }
  // }

  // prebaixa() {
  //   if (confirm('Confirma baixa?')) {
  //     const httpOptions = {
  //       headers: new HttpHeaders({
  //         'Authorization': this.configService.bearerApi,
  //         'id': this.idBaixa.toString(),
  //         'databaixa': this.dataBaixa.toString()
  //       })
  //     };

  //     this.httpService.wait();
  //     this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/calendario/getelementosbaixar', httpOptions)
  //       .subscribe(
  //         res => {
  //           this.httpService.done();
  //           this.baixar(res["mensagem"]);
  //           console.log(res)
  //         },
  //         err => {
  //           this.exibirMensagem(err);
  //           this.httpService.done();
  //           console.log(err);
  //         }
  //       );
  //   }
  // }

  // baixar(elements) {

  //   this.modalBaixar = false;
  //   const listasEnviar = {
  //     nfeFinId: elements.id,
  //     valorBaixa: elements.valor,
  //     valorMulta: 0,
  //     valorJuros: 0,
  //     valorDesconto: 0,
  //     dataBaixa: elements.dataBaixa,
  //     dataNovoVencimento: null,
  //     tipoBaixa: this.tipoBaixa,
  //     empresaConta: elements.empresacontaid,
  //     usuario: this.sessionService.loggedUser.id,
  //     valorCompensado: 0,
  //     rateio: null,
  //     rateioJurosMulta: 0,
  //     titulosQueForamCompensados: []
  //   };

  //   this.httpService.wait();
  //   this.httpService.post('/custom/financeiro/baixar-titulo', listasEnviar)
  //     .subscribe(result => {
  //       this.msgs = [];

  //       if (result[0].error === true) {
  //         this.msgs.push({ severity: 'error', summary: '', detail: result[0].message });
  //       } else {
  //         this.msgs.push({ severity: 'success', summary: '', detail: result[0].message });
  //       }
  //     },
  //       error => this.httpService.handleError(error, () => this.baixar(elements)),
  //       () => this.httpService.done());
  // }

  // checkTipoBaixa(value: any) {
  //   if (!isNullOrUndefined(value) && value > 0) {
  //     this.isTipoBaixa = false;
  //   } else {
  //     this.isTipoBaixa = true;
  //   }
  // }

  // openModalBaixa(event) {
  //   this.dataBaixa = this.viewDate;
  //   this.tipoBaixa = new TipoBaixa();
  //   this.isTipoBaixa = true;
  //   this.idBaixa = event.id;

  //   this.httpService.wait();
  //   this.httpService.get('/tipobaixa?$select=id,descricao'
  //     + '&$orderby=descricao')
  //     .subscribe(ref => this.loadTipoBaixa(ref.value),
  //       error => this.httpService.handleError(error, () => this.openModalBaixa(event)),
  //       () => this.httpService.done());

  //   this.modalBaixar = true;
  // }
}