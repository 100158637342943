export class CstIcmsTabelaA {
  id: number;
  cod: string;
  descricao: string;

  constructor(cstIcmsTabelaA?: CstIcmsTabelaA | number) {
    if (cstIcmsTabelaA !== undefined && cstIcmsTabelaA !== null) {
      if (typeof cstIcmsTabelaA === 'number') {
        this.id = cstIcmsTabelaA;
      } else {
        this.id = cstIcmsTabelaA.id;
        this.cod = cstIcmsTabelaA.cod;
        this.descricao = cstIcmsTabelaA.descricao;
      }
    }
  }
}
