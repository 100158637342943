import { AuthUser } from 'app/proof/model/auth-user.model';
import { TodoProjeto } from './todo-projeto.model';

export class TodoProjetoUsuarios {
  id: number;
  usuario: AuthUser;
  administrador: boolean;
  favoritado = false;
  todoProjeto: TodoProjeto;

  constructor(todoProjetoUsuarios?: TodoProjetoUsuarios | number) {
    if (todoProjetoUsuarios !== undefined && todoProjetoUsuarios !== null) {
      if (typeof todoProjetoUsuarios === 'number') {
        this.id = todoProjetoUsuarios;
      } else {
        this.id = todoProjetoUsuarios.id;
        this.usuario = todoProjetoUsuarios.usuario;
        this.administrador = todoProjetoUsuarios.administrador;
        this.favoritado = todoProjetoUsuarios.favoritado;
        this.todoProjeto = todoProjetoUsuarios.todoProjeto;
      }
    }
  }
}
