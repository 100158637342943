export class Recorrencia {
    id: number;
    cod: string;
    descricao: string;

    constructor(recorrencia?: Recorrencia | number) {
      if (recorrencia !== undefined && recorrencia !== null) {
        if (typeof recorrencia === 'number') {
          this.id = recorrencia;
        } else {
          this.id = recorrencia.id;
          this.cod = recorrencia.cod;
          this.descricao = recorrencia.descricao;
        }
      }
    }
  }
