import { RegraGrupo } from 'app/cadastro/model/regra-grupo.model';
import { ViewCampos } from 'app/cadastro/model/view-campos.model';
import { OperadoresLogicos } from 'app/util/model/operadores-logicos.model';
import { OperadoresComparacao } from 'app/util/model/operadores-comparacao.model';
import { OperadoresMatematicos } from 'app/util/model/operadores-matematicos.model';

export class RegraCondicao {
  id: number;
  regraGrupo: RegraGrupo;
  regraFormula: boolean;
  viewCamposA: ViewCampos;
  operadoresLogicos: OperadoresLogicos;
  operadoresComparacao: OperadoresComparacao;
  operadoresMatematicos: OperadoresMatematicos;
  viewCamposB: ViewCampos;
  sqlFormula: string;
  sqlLivre: string;

  constructor(regraCondicao?: RegraCondicao | number) {
    if (regraCondicao !== undefined && regraCondicao !== null) {
      if (typeof regraCondicao === 'number') {
        this.id = regraCondicao;
      } else {
        this.id = regraCondicao.id;
        this.regraGrupo = regraCondicao.regraGrupo;
        this.regraFormula = regraCondicao.regraFormula;
        this.viewCamposA = regraCondicao.viewCamposA;
        this.operadoresLogicos = regraCondicao.operadoresLogicos;
        this.operadoresComparacao = regraCondicao.operadoresComparacao;
        this.operadoresMatematicos = regraCondicao.operadoresMatematicos;
        this.viewCamposB = regraCondicao.viewCamposB;
        this.sqlFormula = regraCondicao.sqlFormula;
        this.sqlLivre = regraCondicao.sqlLivre;
      }
    }
  }
}
