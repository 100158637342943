import { AuthUser } from "app/proof/model/auth-user.model";
import { Palestra} from "app/eventos/model/palestra.model"
import { isNullOrUndefined } from "util";

export class PalestraCliente{

  id                : number;
  palestra          : Palestra;
  nomeCompleto      : string;
  telefone          : string;
  email             : string;
  empresaNome       : string;
  cpf               : string;
  isNovoCliente     : boolean;

  constructor(palestraCliente?: PalestraCliente | number) {
    if (!isNullOrUndefined(palestraCliente)) {
      if (typeof palestraCliente === 'number') {
        this.id = palestraCliente;
      } else {
        this.id               = palestraCliente.id;
        this.palestra         = palestraCliente.palestra;
        this.nomeCompleto     = palestraCliente.nomeCompleto;
        this.email            = palestraCliente.email;
        this.empresaNome      = palestraCliente.empresaNome;
        this.telefone         = palestraCliente.telefone;
        this.cpf              = palestraCliente.cpf;
        this.isNovoCliente    = palestraCliente.isNovoCliente;

      }
    }
  }

}