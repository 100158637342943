import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { SocketService } from './socket.service';
import { NavigationMenuService } from './navigation-menu.service';
import { AuthLoggedUser } from 'app/proof/model/auth-logged-user.model';
import { HttpService } from 'app/shared/services/http.service';
import { ConfigService } from './config.service';

class Server {
  databaseOffset: number;
  offset: number;
  shift: number;

  constructor(server?: Server) {
    if (server) {
      this.databaseOffset = server.databaseOffset;
      this.offset = server.offset;
      this.shift = (this.databaseOffset - this.offset) * -1;
    }
  }
}

@Injectable()
export class SessionService {
  loggedUser: AuthLoggedUser;

  navigationMenu: NavigationMenuService = null;

  loaded = false;

  server: Server = null;

  sessionLoaded: Subject<any> = new Subject();
  sessionSubscription: Subscription;

  private hasConnected = false;
  connectedSubscription: Subscription;

  editing = false;

  constructor(private configService: ConfigService,
              private socketService: SocketService,
              private httpService: HttpService) {
    this.socketService.ping();

    this.destroySubscribe(this.sessionSubscription);
    this.sessionSubscription = this.initSubscribe(this.sessionLoaded, () => {
      this.loaded = true;
    });

    this.destroySubscribe(this.connectedSubscription);
    this.connectedSubscription = this.initSubscribe(this.httpService.connected, (connected: boolean) => {
      if (this.configService.useServerInfo && connected !== false && this.hasConnected) {
        this.loadServerInfo();
      }
      this.hasConnected = true;
    });
  }

  loadServerInfo() {
    this.httpService.wait();
    this.httpService.get('/server-info')
                    .subscribe(server => this.server = new Server(server),
                               error => this.httpService.handleError(error, () => this.loadServerInfo(), () => console.warn(error)),
                               () => this.httpService.done());
  }

  initSubscribe(s: Subject<any>, f: Function): Subscription {
    let loaded = false;

    const sessionSubscription = s.subscribe(() => {
      if (loaded) {
        f();
      }
    });

    f();
    loaded = true;

    return sessionSubscription;
  }

  destroySubscribe(...subscriptions: Array<Subscription>): void {
    subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
