import { Component, OnInit, OnDestroy } from '@angular/core';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { Mi } from '../model/mi.model';
import { SessionService } from 'app/shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { Message, SelectItem } from 'primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { TipoEntradaSaida } from 'app/util/model/tipo-entrada-saida.model';
import { Empresa } from '../model/empresa.model';

@Component({
  selector: 'app-mi',
  templateUrl: './mi.component.html',
  styleUrls: ['./mi.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class MiComponent implements OnInit, OnDestroy {
  ///////////////////////////////////////////
  //REFATOR POR SIMON LALLEMENT 09/11/2021//
  //////////////////////////////////////////

  private sessionSubscription: Subscription;

  //Strings
  title: string = 'Cadastro de regra de movimentação interna de estoque'
  currentStateMi: string = 'initial';
  headerModal: string = null;

  //Message
  msgs: Message[] = []
  msgsModal: Message[] = []

  //Boolean
  boolCriarStatus: boolean = false
  boolEditStatus: boolean = false

  //Lista
  listaMi: Array<any> = new Array()

  //Multiselect
  opcoesBool: SelectItem[] = [
    { label: "ATIVO", value: true },
    { label: "INATIVO", value: false }
  ]

  opcoesTipo: SelectItem[] = [{ label: 'Entrada', value: "Entrada" }, { label: 'Saída', value: "Saída" }];

  //Toolbar
  toolbarMainIni = [
    { icon: 'add', text: 'Nova', tooltip: 'Nova Regra', visible: true, disabled: false, color: 'green', onClick: () => this.exibirModalNovoRegistro() }
  ]

  //Class
  mi: Mi = new Mi()
  empresa: Empresa = new Empresa()

  //Dropdown
  opcoesTipoES: SelectItem[] = []

  public toolbarMain = new Toolbar();

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private toolbarService: ToolbarService
  ) {}

  ngOnInit() {
    this.mi = new Mi()
    this.mi.tipo = new TipoEntradaSaida()
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni);
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.empresa.id = this.dblinkedSessionService.empresa.id
          this.loadRegistros()
          this.loadEntradaSaida()
        }
      }
    );
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  exibirModalNovoRegistro() {
    this.headerModal = "Nova regra"
    this.mi = new Mi()
    this.mi.tipo = new TipoEntradaSaida()
    this.boolCriarStatus = true
    this.boolEditStatus = false
  }

  exibirModalEditRegistro(row) {
    this.headerModal = "Editar regra"
    this.mi = row.data
    this.boolCriarStatus = false
    this.boolEditStatus = true

  }

  closeDialog() {
    this.boolCriarStatus = false;
    this.boolEditStatus = false;
  }

  validacao() {
    if (!this.mi.codigo || this.mi.codigo.length === 0) {
      this.exibirMensagemModal({ error: true, status: 'O código da regra é obrigatório' });
      return true
    }

    if (!this.mi.descricao || this.mi.descricao.length === 0) {
      this.exibirMensagemModal({ error: true, status: 'A descrição da regra é obrigatório' });
      return true
    }

    if (!this.mi.tipo || !this.mi.tipo.id) {
      this.exibirMensagemModal({ error: true, status: 'O tipo da regra é obrigatório' });
      return true
    }

    return false
  }

  criarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      descricao: this.mi.descricao,
      quantidade: this.mi.quantidade,
      transfAlmoxarifado: this.mi.transfAlmoxarifado,
      transfParticipante: this.mi.transfParticipante,
      transformacao: this.mi.transformacao,
      valor: this.mi.valor,
      empresa: this.empresa,
      tipo: this.mi.tipo,
      codigo: this.mi.codigo,
      ativo: true
    };

    this.httpService.wait()
    this.httpService.post('/mi', jsonData)
      .subscribe(result => {
        this.exibirMensagem({ error: false, status: 'Registro cadastrado com sucesso!' });
        this.boolCriarStatus = false
        this.boolEditStatus = false
        this.loadRegistros();
        this.httpService.done()
      }, error => {
        console.log(error)
        this.exibirMensagem({ error: true, status: "Erro ao criar a nova regra, contate o suporte: " + error.message });
        this.httpService.done()
      }
      );
  }

  editarRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      descricao: this.mi.descricao,
      valor: this.mi.valor,
      transfAlmoxarifado: this.mi.transfAlmoxarifado,
      quantidade: this.mi.quantidade,
      transfParticipante: this.mi.transfParticipante,
      transformacao: this.mi.transformacao,
      tipo: this.mi.tipo,
      codigo: this.mi.codigo
    };

    this.httpService.wait()
    this.httpService.patch('/mi', this.mi.id, jsonData)
      .subscribe(
        result => {
          this.exibirMensagem({ error: false, status: 'Regra alterada com sucesso!' });
          this.boolCriarStatus = false
          this.boolEditStatus = false
          this.loadRegistros()
          this.httpService.done()
        },
        error => {
          console.log(error)
          this.exibirMensagem({ error: true, status: "Erro ao alterar a regra, contate o suporte: " + error.message })
          this.httpService.done()
        });
  }

  ecluirRegistro() {
    if (confirm('Deseja deletar esse registro?')) {
      this.httpService.wait();
      this.httpService.delete('/mi', this.mi.id)
        .subscribe(
          result => {
            this.exibirMensagem({ error: false, status: 'Registro excluído com sucesso!' });
            this.boolCriarStatus = false
            this.boolEditStatus = false
            this.loadRegistros()
            this.httpService.done()
          },
          error => {
            console.log(error)
            this.exibirMensagem({ error: true, status: "Erro ao deletar a regra, contate o suporte: " + error.message })
            this.httpService.done()
          });
    }
  }

  verifRegraTransf(tipo: number) {
    if (tipo === 1 && this.mi.valor) {
      this.mi.transfAlmoxarifado = false
      this.mi.transfParticipante = false
      this.mi.transformacao = false
    }

    if (tipo === 2 && this.mi.transfAlmoxarifado) {
      this.mi.valor = false
      this.mi.transfParticipante = false
      this.mi.transformacao = false
    }

    if (tipo === 3 && this.mi.transfParticipante) {
      this.mi.valor = false
      this.mi.transfAlmoxarifado = false
      this.mi.transformacao = false
    }

    if (tipo === 4 && this.mi.transformacao) {
      this.mi.valor = false
      this.mi.transfParticipante = false
      this.mi.transfAlmoxarifado = false
      this.mi.quantidade = true
    }
  }

  loadEntradaSaida() {
    this.httpService.get(`/tipoentradasaida?$select=id,descricao&$orderby=descricao`)
      .subscribe(
        ref => {
          this.opcoesTipoES = [];
          ref.value.forEach(element => {
            this.opcoesTipoES.push({
              label: element.descricao,
              value: element.id
            });
          });
        },
        error => {
          console.log(error)
          this.exibirMensagem({ error: true, status: "Erro ao carregar a lista de entrada/saída, contate o suporte: " + error.message })
        }
      );
  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService.get(`/mi?$select=codigo,descricao,tipo/descricao,quantidade,valor,transfAlmoxarifado,transfParticipante,transformacao&$filter=empresa/id eq ${this.dblinkedSessionService.empresa.id}&$orderby=codigo`)
      .subscribe(
        (retorno) => {
          this.listaMi = new Array()
          retorno.value.forEach(item => { this.listaMi.push(item) })
        },
        error => {
          console.log(error)
          this.exibirMensagem({ error: true, status: "Erro ao carregar a lista de MI, contate o suporte: " + error.message })
        },
        () => this.httpService.done()
      );
  }

  exibirMensagem({ status, error }) {
    this.msgs = [];
    if (!error) {
      this.msgs.push({ severity: 'success', summary: 'Sucesso', detail: status });

    } else {
      this.msgs.push({ severity: 'warn', summary: 'Erro', detail: status });
    }
  }

  exibirMensagemModal({ status, error }) {
    this.msgsModal = [];
    if (!error) {
      this.msgsModal.push({ severity: 'success', summary: 'Sucesso', detail: status });

    } else {
      this.msgsModal.push({ severity: 'warn', summary: 'Erro', detail: status });
    }
  }

  hideMessage() {
    this.msgs = []
    this.msgsModal = []
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}







