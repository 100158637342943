import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Empresa } from '../../cadastro/model/empresa.model';
import { DirfDeclaracao } from './dirf.declaracao.model';
import { ScrollService } from 'app/shared/services/scroll.service';

@Component({
  selector: 'app-dblinked-dirf',
  templateUrl: './dirf.component.html',
  styleUrls: ['./dirf.component.scss'],
})
export class DirfComponent implements OnInit, OnDestroy {
  @ViewChild('datatable') datatable: any;

  formGroup: FormGroup;
  private formControls: Array<FormControl> = new Array();
  private formTimeout: NodeJS.Timer;
  private toolbarTimeout: NodeJS.Timer;

  files: any;
  exportedFile: string;

  declaracao: DirfDeclaracao;
  declaracoes: Array<DirfDeclaracao> = new Array();
  historico: Array<any> = new Array();
  historicoCount = 0;

  currentDeclaracao: any;
  private filterTimeout: NodeJS.Timer;

  declaracaoEnviada: boolean;
  declaracaoRetificadora: boolean;

  private empresaSubscription: Subscription;
  private formSubscription: Subscription;

  private messageTimeout: NodeJS.Timer;
  message: string = null;
  error = false;

  private scrollSubscription: Subscription;
  private skip = 0;
  private top: number = this.configService.datatableTop;

  title: string;
  subtitle: string;
  colsDeclaracoes : any[] = [];
  public expandedRows = {};
  expanded : boolean = false;

  constructor(private titleService: TitleService,
              private toolbarService: ToolbarService,
              private configService: ConfigService,
              private httpService: HttpService,
              private formBuilder: FormBuilder,
              private utilService: UtilService,
              private scrollService: ScrollService,
              private dblinkedSessionService: DblinkedSessionService) {
  }

  ngOnInit(): void {
    this.title = 'DIRF';
    this.subtitle = ' Relatório DIRF ';
    // this.toolbarService.clear();
    this.titleService.title = 'DIRF';

    this.formControls = new Array();
    this.formControls['cpf'] = new FormControl();
    this.formControls['naturezaJuridica'] = new FormControl();
    this.formControls['declaracaoEnviada'] = new FormControl({ value: false, disabled: true });
    this.formControls['recibo'] = new FormControl();
    this.formControls['envio'] = new FormControl();
    this.formControls['declaracaoRetificadora'] = new FormControl({ value: false, disabled: true });
    this.formControls['reciboOriginal'] = new FormControl();
    this.formControls['socioOstensivo'] = new FormControl();
    this.formControls['depositoJudicial'] = new FormControl();
    this.formControls['clubeInvestimento'] = new FormControl();
    this.formControls['rendimentosExterior'] = new FormControl();
    this.formControls['planoSaude'] = new FormControl();
    this.formControls['olimpiadas2016'] = new FormControl();
    this.formControls['situacaoEspecial'] = new FormControl({ value: false, disabled: true });
    this.formControls['situacaoEspecialData'] = new FormControl();
    this.formControls['recursoTesouroNacional'] = new FormControl();
    this.formControls['fundacaoPublica'] = new FormControl();

    this.createForm(() => {
      this.empresaSubscription = this.dblinkedSessionService.empresaChanged.subscribe(() => {
        this.getDeclaracoes();
      });

      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.getDeclaracoes();
      }
    });
  }

  ngOnDestroy() {
    if (this.empresaSubscription) {
      this.empresaSubscription.unsubscribe();
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }

    // this.toolbarService.clear();
  }

  createForm(callback?: Function) {
    this.formGroup = this.formBuilder.group(this.formControls);

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    this.formSubscription = this.formGroup.valueChanges.subscribe(value => {
      clearTimeout(this.toolbarTimeout);
      this.toolbarTimeout = setTimeout(() => this.toolbarRefresh(), 100);
    });

    if (callback && typeof callback === 'function') {
      callback();
    }
  }

  getDeclaracoes(declaracao?: DirfDeclaracao) {
    this.fileClear();

    if (this.empresa) {
      this.httpService.wait();
      this.httpService.get('/dirfdeclaracao?$select=changeDatetime,layout/id,responsavelCpf,recibo,envio,exported,anoReferencia,' +
                           'anoCalendario,socioOstensivo,depositoJudicial,clubeInvestimento,rendimentosExterior,planoSaude,' +
                           'olimpiadas2016,situacaoEspecial,situacaoEspecialData,usuario/nome,usuario/sobrenome,usuario/login,' +
                           'declaracaoOriginal/id,declaracaoOriginal/recibo,naturezaJuridica/id,empresa/id,' +
                           'recursoTesouroNacional,fundacaoPublica' +
                           '&$filter=empresa/id eq ' + this.empresa.id +
                           '&$orderby=anoReferencia desc,anoCalendario desc,changeDatetime desc')
                      .subscribe(declaracoes => {
                                   this.declaracoes = new Array();
                                   declaracoes.value.forEach((d: DirfDeclaracao) => {
                                     this.declaracoes.push(new DirfDeclaracao(d));
                                   });

                                   if (declaracao) {
                                     setTimeout(() => {
                                       this.select({ data: this.declaracoes.find(d => d.id === declaracao.id) });

                                       const input = this.datatable.el.nativeElement.querySelector('div.expanded mat-input input');
                                       if (input) {
                                         input .focus();
                                       }
                                     }, 100);
                                   } else {
                                     this.declaracao = null;
                                     this.currentDeclaracao = null;

                                     this.toolbarTimeout = setTimeout(() => this.toolbarRefresh(), 100);
                                   }
                                 },
                                 error => this.httpService.handleError(error, () => this.getDeclaracoes(declaracao)),
                                 () => this.httpService.done());
    }
  }

  unselect(event: any) {
    this.declaracao = null;
    this.currentDeclaracao = null;

    this.declaracaoChanged();
  }

  select(event: any) {
    try {
      console.log(event);
      // TODO: Fixes - The specified value "1486000800000" does not conform to the required format, "yyyy-MM-dd".
      if (this.declaracao && this.declaracao.id === event.data.id) {
        this.declaracao = null;
        this.currentDeclaracao = null;
      } else {
        this.declaracao = this.clone(event.data);
        this.currentDeclaracao = event.data;
      }

      this.skip = 0;

      this.datatable.expandedRows = [this.currentDeclaracao]; // TODO: Review (remove or if local???)

      this.declaracaoChanged();
    } catch (error) {
      console.log(error);
    }

  }

  declaracaoChanged(upload?: boolean, skip?: number) {
    this.exportedFile = null;

    if (this.declaracao) {
      this.httpService.wait();
      this.httpService.get('/dirfhistorico?$select=data,irrfBc,irrfValue,pccBc,pisValue,cofinsValue,csllValue,' +
                           'dirfParticipante/cnpjOrCpf,dirfParticipante/razaoSocialOrNome,codReceitaRfb/cod,codReceitaRfb/descricao' +
                           '&$filter=declaracao/id eq ' + this.declaracao.id +
                           '&$orderby=codReceitaRfb/cod,dirfParticipante/cnpjOrCpf,data' +
                           '&$skip=' + (skip || 0) +
                           '&$top=' + this.top +
                           '&$count=true')
                      .subscribe(historico => {
                                   if ((skip || 0) === 0) {
                                     this.historico = new Array();
                                   }
                                   this.historicoCount = historico['@odata.count'];
                                   this.historico = this.historico.concat(historico.value);

                                   this.fileClear(upload);

                                   if (!upload) {
                                    this.createForm();
                                   }

                                   this.toolbarRefresh();


                                   if (this.scrollSubscription) {
                                     this.scrollSubscription.unsubscribe();
                                   }

                                   this.scrollSubscription = this.scrollService.scroll.subscribe((content: any) => {
                                     if (this.historico.length === this.historicoCount) {
                                       return;
                                     }

                                     const rows = content.querySelectorAll('.dirf-historico-datatable tbody tr');
                                     const lastRow: any = rows[rows.length - 1];

                                     if (lastRow === undefined) {
                                       return;
                                     }

                                     const mark = lastRow.offsetTop - window.innerHeight; // TODO: Review (- content.offsetTop ??)
                                     if (!this.httpService.loading && mark < content.scrollTop) {
                                       this.skip += this.top;
                                       this.declaracaoChanged(false, this.skip);
                                     }
                                   });
                                 },
                                 error => this.httpService.handleError(error, () => this.declaracaoChanged(upload, skip)),
                                 () => this.httpService.done());
    } else {
      this.toolbarRefresh();
    }
  }

  filter(datatable: any, col: any, event: any, wait?: boolean) {

    clearTimeout(this.filterTimeout);
    if (wait) {
      this.filterTimeout = setTimeout(() => this.filter(datatable, col, event), 900);
    } else {
      datatable.filter(event.target.value, col.field, col.filterMatchMode);
    }
  }

  /*get reciboOriginal(): string {
    return this.declaracao.declaracaoOriginal ? this.declaracao.declaracaoOriginal.recibo : null;
  }
  set reciboOriginal(recibo: string) {
    this.httpService.wait();
    this.httpService.get('/dirfdeclaracao?$select=id,recibo&$filter=recibo eq \'' + recibo + '\'')
                    .subscribe(declaracao => this.declaracao.declaracaoOriginal = declaracao.value,
                                error => this.httpService.handleError(error),
                                () => this.httpService.done());
  }*/

  create() {
    this.httpService.wait();
    this.httpService.post('/custom/dirf/create', this.empresa.id)
                    .subscribe(result => {
                                 if (typeof result !== 'string') {
                                   this.getDeclaracoes(new DirfDeclaracao(result));
                                 }
                                 this.toolbarRefresh();
                                 this.fileClear();
                               },
                               error => this.httpService.handleError(error, () => this.create()),
                               () => this.httpService.done());
  }

  save() {
    if (!this.declaracaoEnviada) {
      this.declaracao.envio = null;
      this.declaracao.recibo = null;
    }

    if (!this.declaracaoRetificadora) {
      this.declaracao.declaracaoOriginal = null;
    }

    this.declaracao.envio = null;
    this.declaracao.recibo = null;

    const declaracao = this.clone(this.declaracao);
    declaracao.historico = undefined;

    if (!declaracao.naturezaJuridica || (!declaracao.naturezaJuridica.id && declaracao.naturezaJuridica.id !== 0)) {
      declaracao.naturezaJuridica = undefined;
    }

    this.httpService.wait();
    this.httpService.put('/dirfdeclaracao', this.declaracao.id, declaracao)
                    .subscribe(result => {
                                 this.createForm(() => this.getDeclaracoes(this.declaracao));
                               },
                               error => this.httpService.handleError(error, () => this.save()),
                               () => this.httpService.done());
  }

  export() {
    this.httpService.wait();
    this.httpService.post('/custom/dirf/export(' + this.declaracao.id + ')', null)
                    .subscribe(result => {
                                 if (!result.error) { // TODO: REVIEW
                                   this.exportedFile = result.file;
                                   window.open(this.filePath(this.exportedFile), '_blank');
                                 } else {
                                   alert(result.message);
                                 }
                               },
                               error => this.httpService.handleError(error, () => this.export()),
                               () => this.httpService.done());
  }
  private exported(result: any) {
    if (!result.error) {
      this.exportedFile = result.file;
      window.open(this.filePath(this.exportedFile), '_blank');
    } else {
      alert(result.message);
    }
  }

  childRemove(id?: number) {
    if (id) {
      if (confirm('Deseja excluir o registro de pagamento selecionado?')) {
        this.httpService.wait();
        this.httpService.delete('/dirfhistorico', id)
                        .subscribe(result => {
                                     const oldCount: number = this.historico.length;
                                     this.historico = this.historico.filter(h => h.id !== id);
                                     this.historicoCount = this.historicoCount - (oldCount - this.historico.length);

                                     this.toolbarRefresh();
                                   },
                                   error => this.httpService.handleError(error, () => this.childRemove(id)),
                                   () => this.httpService.done());
      }
    } else {
      if (confirm('Deseja excluir todos os registros de pagamento?')) {
        this.httpService.wait();
        this.httpService.post('/custom/dirf/clear(' + this.declaracao.id + ')')
                        .subscribe(result => {
                                    this.historico = [];
                                    this.toolbarRefresh();
                                   },
                                   error => this.httpService.handleError(error, () => this.childRemove()),
                                   () => this.httpService.done());
      }
    }
  }

  remove() {
    if (confirm('Deseja excluir a declaração selecionada?')) {
      this.httpService.wait();
      this.httpService.delete('/dirfdeclaracao', this.declaracao.id)
                      .subscribe(result => {
                                   this.declaracoes = this.declaracoes.filter(declaracao => declaracao.id !== this.declaracao.id);
                                   this.declaracao = null;
                                   this.currentDeclaracao = null;

                                   this.toolbarRefresh();
                                   this.fileClear();
                                 },
                                 error => this.httpService.handleError(error, () => this.remove()),
                                 () => this.httpService.done());
    }
  }

  get file(): any {
    return this.files && this.files.length === 1 ? this.files[0] : null;
  }

  get fileName(): string {
    return this.file ? this.file.name : null;
  }

  private fileClear(upload?: boolean) {
    if (!upload) {
      this.message = null;
      this.error = false;
    }
    this.files = [];
  }

  fileChange(event: any) {
    try {
      this.files = event.srcElement.files;
    } catch (exception) {
      this.files = [];
    }
  }

  fileUpload(fileInput: any) {
    this.files = fileInput.files;

    if (this.file) {
      this.message = null;
      this.error = false;

      const data: FormData = new FormData();
      data.append('file', this.file);
      data.append('type', 'dirf');
      data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
      data.append('dirfDeclaracaoId', this.declaracao.id ? this.declaracao.id.toString() : null);

      this.httpService.wait();
      this.httpService.post('/custom/upload', data)
                      .subscribe(result => {
                                   this.message = result.message;
                                   this.error = result.error;
                                   clearTimeout(this.messageTimeout);

                                   if (!result.error) {
                                     if (!this.message) {
                                       this.message = 'As informações de pagamentos foram importadas com sucesso.';
                                       this.messageTimeout = setTimeout(() => this.message = null, 10000);
                                     }

                                     this.toolbarRefresh();
                                     this.declaracaoChanged(true);
                                  }
                                 },
                                 error => this.httpService.handleError(error, () => this.fileUpload(fileInput)),
                                 () => {
                                   fileInput.value = null;
                                   this.httpService.done();
                                 });
    }
  }

  cancel() {
    this.httpService.wait();

    this.createForm(() => {
      this.declaracao = this.clone(this.currentDeclaracao);
    });

    if (this.declaracao.envio || this.declaracao.recibo) {
      this.declaracaoEnviada = true;
    } else {
      this.declaracaoEnviada = false;
    }

    this.httpService.done();
  }

  filePath(fileName: string, remove?: boolean): string {
    return this.httpService.url('/custom/file/' + fileName + (!remove ? '/keep' : ''));
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  private toolbarRefresh() {
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.cancel());
    this.toolbarService.alterarStatus('atualizar', false, 'Atualizar', () => this.getDeclaracoes());
    this.toolbarService.alterarStatus('export', true, 'Expotar', () => this.export());
    this.toolbarService.alterarStatus('remove', true, 'Excluir declaração', () => this.remove());
    this.toolbarService.alterarStatus('save', true, 'Salvar alterações para a declaração', () => this.save());
    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar nova declaração', () => this.create());

    if (this.declaracao) {
      if (this.formGroup && this.formGroup.dirty) {
        this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.cancel());
        this.toolbarService.alterarStatus('save', false, 'Salvar alterações para a declaração', () => this.save());
        // this.toolbarService.add('undo', false, 'undo', 'undo', 'Desfazer alterações para a declaração', () => this.cancel());
        // this.toolbarService.add('save', false, 'done', 'done', 'Salvar alterações para a declaração', () => this.save());
      } else {
        if ((this.declaracao.naturezaJuridica.id || this.declaracao.naturezaJuridica.id === 0) &&
            this.declaracao.responsavelCpf && this.declaracao.responsavelCpf.length >= 11 &&
            this.historico && this.historico.length > 0) {
            this.toolbarService.alterarStatus('export', false, 'Gerar arquivo para envio de declaração', () => this.export());
          // this.toolbarService.add('export', false, 'cloud_download', 'cloud_download',
          //                        'Gerar arquivo para envio de declaração', () => this.export());
        }

        if (!this.declaracao.envio) {
          this.toolbarService.alterarStatus('remove', false, 'Excluir declaração', () => this.remove());
          this.toolbarService.alterarStatus('atualizar', true, 'Atualizar', () => this.getDeclaracoes());
          this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.getDeclaracoes());
        }
      }
    } else if (!this.declaracoes || this.declaracoes.length === 0) {
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar nova declaração', () => this.create());
    }
  }

  private clone(object: any) {
    if (object === undefined || object === null) {
      return object;
    }

    return JSON.parse(JSON.stringify(object));
  }
}
