export class ColumnFormulaPgc {
  id: number;
  configRelatColumnId: number;
  pgcId: number;
  columnIndice: number;
  columnReferenciaId: number;
  columnReferenciaIndice: number;;
  formula: string = null;
  isValor: boolean = true;
  isPercent: boolean = false;

  constructor(columnFormulaPgc?: ColumnFormulaPgc | number) {
    if (columnFormulaPgc !== undefined && columnFormulaPgc !== null) {
      if (typeof columnFormulaPgc === 'number') {
        this.id = columnFormulaPgc;
      } else {
        this.id = columnFormulaPgc.id;
        this.configRelatColumnId = columnFormulaPgc.configRelatColumnId;
        this.pgcId = columnFormulaPgc.pgcId;
        this.columnReferenciaId = columnFormulaPgc.columnReferenciaId;
        this.columnIndice = columnFormulaPgc.columnIndice;
        this.columnReferenciaIndice = columnFormulaPgc.columnReferenciaIndice,
        this.formula = columnFormulaPgc.formula;
        this.isValor = columnFormulaPgc.isValor;
        this.isPercent = columnFormulaPgc.isPercent;
      }
    }
  }
}