/* tslint:disable:max-line-length */

import { Injectable } from '@angular/core';
import { Validators, AbstractControl } from '@angular/forms';
import { Util } from '../common/util';

@Injectable()
export class ValidatorsService extends Validators {
    static email(controlOrString: AbstractControl | string): { [key: string]: boolean } {
        const regEx = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

        return <{ [key: string]: boolean }>ValidatorsService.validate(controlOrString, regEx);
    }

    static cnpj(controlOrString: AbstractControl | string): { [key: string]: boolean } {
        const regEx = /^([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})$/; // /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/

        return <{ [key: string]: boolean }>ValidatorsService.validate(controlOrString, regEx, true);
    }

    static cpf(controlOrString: AbstractControl | string): { [key: string]: boolean } {
      const regEx = /^([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})$/; // /^(\d{3}\.?\d{3}\.?\d{3}\-?\d{2})$/

      return <{ [key: string]: boolean }>ValidatorsService.validate(controlOrString, regEx, true);
    }

    static cpfOrCnpj(controlOrString: AbstractControl | string): { [key: string]: boolean } {
      const regEx = /^([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2}|[0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})$/;

      return <{ [key: string]: boolean }>ValidatorsService.validate(controlOrString, regEx, true);
    }

    private static validate(controlOrString: AbstractControl | string, regEx: RegExp, check: boolean = false): { [key: string]: boolean } | boolean {
      let value;
      if (controlOrString instanceof AbstractControl) {
        value = controlOrString.value;
      } else {
        value = controlOrString;
      }

      if (Util.isEmpty(value) || !regEx.test(value.trim())) {
        return ValidatorsService.invalid(controlOrString);
      }

      if (check) {
        value = value.trim();

        switch (value.length) {
          case 11:
            if (!Util.isCpf(value)) {
              return ValidatorsService.invalid(controlOrString);
            }
            break;
          case 14:
            if (!Util.isCnpj(value)) {
              return ValidatorsService.invalid(controlOrString);
            }
            break;
        }
      }

      return null;
    }

    private static invalid(controlOrString: AbstractControl | string): { [key: string]: boolean } | boolean {
      if (controlOrString instanceof AbstractControl) {
        return { invalid: true };
      } else {
        return false;
      }
    }

    validaSenha(senha: string, senha2: string): Boolean {
      if (senha !== senha2) {
        alert("A confirmação da nova senha falhou. Tente novamente.");
        return false;
      }
      if (!(senha.length <= 15 && senha.length >= 6)) {
        alert("A senha precisa ter entre 6 e 15 caracteres.");
        return false;
      }

      let regex = new RegExp(/^(.*[a-z].*)$/);
      if (regex.test(senha) === false) {
        alert("A senha precisa ter pelo menos uma letra minúscula");
        return false;
      }

      regex = new RegExp(/^(.*[A-Z].*)$/);
      if (regex.test(senha) === false) {
        alert("A senha precisa ter pelo menos uma letra maiúscula");
        return false;
      }

      regex = new RegExp(/^(.*[-+_!@#$%&*].*)$/);
      if (regex.test(senha) === false) {
        alert("A senha precisa ter pelo menos um caracter especial -+_!@#$%&*");
        return false;
      }

      // caso passe em tudo, return true
      return true;
    }
}
