import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Config } from '../../app.config';
import { Period } from 'app/shared/components/period-select/period.model';
import { PlanoContasRfb } from 'app/contabil/model/plano-contas-rfb.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { PlanoContasService } from 'app/shared/services/plano-contas.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
    selector: 'app-teste',
    templateUrl: './plano-contas-ref-relac-new.component.html',
    styleUrls: ['./plano-contas-ref-relac-new.component.scss'],
})
export class PlanoContasRefRelacNewComponent implements OnInit, OnDestroy {
  mapeamento: any;

  planoContasRfbList: Array<PlanoContasRfb> = new Array();
  planoContasRfb: PlanoContasRfb;

  planoContasList: Array<PlanoContas> = new Array();
  planoContasSelectedList: Array<PlanoContas> = new Array();

  planoContasRefRelacList: Array<number> = new Array();

  contaSelecionada = false;

  contadorLinha: number = 0;
  contadorLinhaSelected: number = 0;

  private sessionSubscription: Subscription;

  private planoContasService: PlanoContasService = new PlanoContasService('ref-relac');

  titlePrincipal: string;
  subtitle: string;

  constructor(private configService: ConfigService,
              private httpService: HttpService,
              private toolbarService: ToolbarService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private titleService: TitleService) {
    this.titleService.title = 'Mapeamento Referencial: Plano de Contas - Receita Federal do Brasil';
  }

  ngOnInit() {
    this.titlePrincipal = 'PLANO CONTAS REFERENCIAL';
    this.subtitle = 'RELACIONE O PLANO DE CONTAS DA RECEITA FEDERAL COM O PLANO DE CONTAS DA EMPRESA';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    // Promise.resolve(null).then(() => this.toolbarRefresh());
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
                                                                          () => { this.periodChanged(); });
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    // this.toolbarService.clear();
    this.sessionService.destroySubscribe(this.sessionSubscription);

    this.contadorLinha = 0;
    this.contadorLinhaSelected = 0;
  }

  periodChanged() {
      if (!this.dblinkedSessionService.hasEmpresaSelected || !this.dblinkedSessionService.hasYearSelected) {
        return;
      }

      this.contaSelecionada = false;

      this.httpService.wait();
      this.httpService.get('/planocontasrfb?$select=id,codCta,parent/codCta,codNatureza,descrCta,nivelCta,tipoCta&$orderby=codCta')
                      .subscribe(planoContas => this.planoContasRfbList = planoContas.value,
                                  error => this.httpService.handleError(error, () => this.periodChanged()),
                                  () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/ecd/mapeamento', [this.dblinkedSessionService.empresa.id, this.year])
                      .subscribe(mapeamento => this.mapeamento = mapeamento,
                                  error => this.httpService.handleError(error, () => this.periodChanged()),
                                  () => this.httpService.done());
  }

  editPlanoContasRfb(event: PlanoContasRfb | any) {
      this.planoContasRfb = event instanceof PlanoContasRfb ? event : new PlanoContasRfb(event.data);

      if (this.planoContasRfb.tipoCta !== 'S') {
          this.httpService.wait();
          this.httpService.get('/custom/ecd/planosreferenciados', [this.year,
                                                                  this.dblinkedSessionService.empresa.id,
                                                                  this.planoContasRfb.id])
                          .subscribe(listaReferenciados => {
                                      this.httpService.wait();
                                      this.httpService.get('/custom/ecd/planocontas', [this.dblinkedSessionService.empresa.id, this.year])
                                                      .subscribe(planoContasList => {
                                                                  this.contaSelecionada = true;
                                                                  this.toolbarRefresh();

                                                                  this.planoContasList = planoContasList.value;

                                                                  if(this.planoContasList !== null || this.planoContasList !== undefined){
                                                                    this.contadorLinha = this.planoContasList.length;
                                                                    }
                                                                      else{
                                                                          this.contadorLinha = 0;
                                                                    }

                                                                  this.planoContasList.forEach(pc => {
                                                                    pc.selected = pc.planoContasRefRelacList &&
                                                                                  pc.planoContasRefRelacList.length > 0;
                                                                  });

                                                                  this.planoContasList
                                                                      .filter(p => listaReferenciados.findIndex(s => s.id === p.id) > -1)
                                                                      .forEach(p => {
                                                                        p.planoContasRefRelacList
                                                                          .forEach(relac => this.planoContasRefRelacList.push(relac.id));
                                                                      });
                                                                  this.planoContasSelectedList = listaReferenciados;

                                                                  if(this.planoContasSelectedList !== null || this.planoContasSelectedList !== undefined){
                                                                    this.contadorLinhaSelected = this.planoContasSelectedList.length;
                                                                    }
                                                                      else{
                                                                          this.contadorLinhaSelected = 0;
                                                                    }

                                                                },
                                                                error => this.httpService.handleError(error,
                                                                                                      () => this.editPlanoContasRfb(event)),
                                                                () => this.httpService.done());
                                    },
                                    error => this.httpService.handleError(error, () => this.editPlanoContasRfb(event)),
                                    () => this.httpService.done());
      }

  }

  back() {
      this.periodChanged();
  }

  check(event) {
    this.planoContasService.check(this.planoContasList, event.data, true);
  }

  uncheck(event) {
    this.planoContasService.check(this.planoContasList, event.data, false);
  }

  private dataPlanoContasRefRelac(planoContasRfb: PlanoContasRfb, planoContas: PlanoContas) {
    const data = { id: undefined,
                   planoContas: { id: planoContas.id },
                   planoContasRfb: { id: planoContasRfb.id }};

    return data;
  }

  add() {
    const newItems = this.planoContasService.toAdd(this.planoContasList, this.planoContasSelectedList);

    newItems.forEach((planoContas, i) => {
      const planoContasRefRelac = this.dataPlanoContasRefRelac(this.planoContasRfb, planoContas);

      this.httpService.wait();
      this.httpService.post('/custom/ecd/planocontasrefrelac(' + this.year + ')',
                            planoContasRefRelac)
                      .subscribe(id => {
                                   if (i === newItems.length - 1) {
                                     this.editPlanoContasRfb(this.planoContasRfb);
                                   }
                                 },
                                 error => this.httpService.handleError(error, () => this.add()),
                                 () => this.httpService.done());
    });
  }

  get addDisabled(): boolean {
    return this.planoContasService.toAdd(this.planoContasList, this.planoContasSelectedList).length < 1;
  }

  remove() {
    const oldItems = this.planoContasService.toRemove(this.planoContasList, this.planoContasSelectedList);
    oldItems.forEach((item, i) => {
      const planoContas = this.planoContasList.find(p => p.id === item.id);
      planoContas.planoContasRefRelacList.forEach(relac => {
        this.httpService.wait();
        this.httpService.delete('/custom/ecd/planocontasrefrelac', [this.year, relac.id])
                        .subscribe(() => {
                                     if (i === oldItems.length - 1) {
                                       this.editPlanoContasRfb(this.planoContasRfb);
                                     }
                                   },
                                   error => this.httpService.handleError(error, () => this.remove()),
                                   () => this.httpService.done());
      });
    });
  }

  get removeDisabled(): boolean {
    return this.planoContasService.toRemove(this.planoContasList, this.planoContasSelectedList).length < 1;
  }

  planoContasDisabled(planoContas: PlanoContas): boolean {
    return this.planoContasService.disabled(this.planoContasSelectedList, planoContas);
  }

  planoContasListStyleClass(planoContas: PlanoContas): string {
    let classes = '';
    if (planoContas.indCta === 'S') {
      classes += 'bold ';
    }
    return classes.trim();
  }

  planoContasRfbListStyleClass(planoContasRfb: PlanoContasRfb): string {
    let classes = '';
    if (planoContasRfb.tipoCta !== 'A') {
      classes += 'bold locked ';
    }
    return classes.trim();
  }

  private toolbarRefresh() {
    // this.toolbarService.clear();
    if (this.contaSelecionada) {
      this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.back());
      // this.toolbarService.add('back', false, 'class-back-button', 'undo', 'Voltar', () => this.back());
    } else {
      this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.back());
    }
    /*this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');*/
  }

  get title(): string {
    return this.titleService.title;
  }

  get year(): number {
    return this.dblinkedSessionService.periodo.year.value;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  onFilterplanoContasRfbList(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }

  onFilterplanoContasList(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }
  onFilterplanoContasSelectedList(event: any) {
    this.contadorLinhaSelected = event.filteredValue.length;
  }
}
