export class IndNire {
  id: number;
  cod: string;
  descricao: string;

  constructor(indnire?: IndNire | number) {
    if (indnire !== undefined && indnire !== null) {
      if (typeof indnire === 'number') {
        this.id = indnire;
      } else {
        this.id = indnire.id;
        this.cod = indnire.cod;
        this.descricao = indnire.descricao;
      }
    }
  }
}
