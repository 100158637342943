import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Toolbar } from "app/shared/model/toolbar.model";
import { MoneyPipe } from 'app/shared/pipes/money.pipe';
import { ConfigService } from "app/shared/services/config.service";
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service";
import { HttpService } from "app/shared/services/http.service";
import { SessionService } from "app/shared/services/session.service";
import { TitleService } from "app/shared/services/title.service";
import { ToolbarService } from "app/shared/services/toolbar.service";
import { UtilService } from "app/shared/services/util.service";
import exportExcel from "app/util/processing/exportExcel";
import { MessageService, SelectItem, TreeNode } from "primeng/api";
import { Subscription } from "rxjs/Subscription";
import { FluxoCaixa } from "../model/fluxo_caixa";


@Component({
  selector: 'app-fluxo-caixa',
  templateUrl: './fluxo-caixa.component.html',
  styleUrls: ['./fluxo-caixa.component.scss'
  ]
})

export class FluxoCaixaComponent implements OnInit, OnDestroy {
  ////////////////////////////////////////
  // REFATOR SIMON LALLEMENT 21/01/2022 //
  ////////////////////////////////////////

  private sessionSubscription: Subscription;

  //String
  title: string = 'FLUXO DE CAIXA'
  dataAtual: string = null
  modalTitle: string = null
  descrVersao: string = null
  tipoMetodoVersao: string = null

  //Boolean
  exibirFluxoRelatorio: boolean = false
  modalFluxoCaixa: boolean = false
  modalFluxoCaixaDetal: boolean = false
  baixados: boolean = true
  isExpanded: boolean = false
  versionamento: boolean = false
  modalVersao: boolean = false

  //Array
  listafluxosIni: Array<any> = new Array()
  empresaContas: Array<number> = new Array()
  columns: Array<string> = new Array()
  valoresTabela: Array<any> = new Array()
  linhasFluxo: Array<any> = new Array()
  linhasFluxoVersao: Array<any> = new Array()
  valoresTabelaDetal: Array<any> = new Array()
  columnsVersUp: Array<string> = new Array()
  columnsVersDown: Array<Array<string>> = new Array()
  columnsVers: Array<string> = ['Atual', 'Projetado', 'Var. R$', 'Var. %']
  colsFluxo: Array<any> = [
    { field: 'dtEmissao', header: 'EMISSÃO' },
    { field: 'dtVcto', header: 'VENCTO/BAIXA' },
    { field: 'nome', header: 'NOME' },
    { field: 'operacao', header: 'OPERAÇÃO' },
    { field: 'historico', header: 'HISTÓRICO' },
    { field: 'doc', header: 'DOCUMENTO' },
    { field: 'valor', header: 'VALOR' }
  ]
  liMeses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

  //Multiselect e dropdown
  opcoesVisaoFluxoCaixa: SelectItem[] = [
    { label: 'Selecione', value: null },
    { label: 'Diária', value: 1 },
    { label: 'Semanal', value: 2 },
    { label: 'Mensal', value: 3 }
  ]
  opcoesMeses: SelectItem[] = [
    { label: 'Selecione', value: null },
    { label: 'Janeiro', value: 1 },
    { label: 'Fevereiro', value: 2 },
    { label: 'Março', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Maio', value: 5 },
    { label: 'Junho', value: 6 },
    { label: 'Julho', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Setembro', value: 9 },
    { label: 'Outubro', value: 10 },
    { label: 'Novembro', value: 11 },
    { label: 'Dezembro', value: 12 }
  ]
  opcoesAnos: SelectItem[] = []
  opcoesEmpresaConta: SelectItem[] = []
  opcoesVersoes: SelectItem[] = []

  //Classes
  fluxoCaixa: FluxoCaixa = new FluxoCaixa()

  //Number
  visaoFluxoCaixa: number = 3
  visaoFluxoCaixaMes: number = null
  visaoFluxoCaixaAno: number = null
  mesControl: number = null

  //Toolbar
  toolbarMainIni = [
    { icon: 'arrow_back_ios_new', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: false, color: 'default', onClick: () => this.start() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.exportar() },
    { icon: 'refresh', text: 'Recarregar', tooltip: 'Atualizar relatório', visible: true, disabled: false, color: 'default', onClick: () => this.relatorioFluxoCaixa() },
    { key: 'versao', icon: 'compare', text: 'Versão', tooltip: 'Carregar versão', visible: false, disabled: false, color: 'default', onClick: () => this.openModalVersao("get") },
    { key: 'camera', icon: 'photo_camera', text: 'Capturar', tooltip: 'Salvar versão', visible: false, disabled: false, color: 'default', onClick: () => this.openModalVersao("post") },
    { icon: 'expand', tooltip: 'Expandir / Colapsar', visible: true, disabled: false, color: 'default', onClick: () => this.expandCollapseAll(this.isExpanded) }
  ]

  public toolbarMain = new Toolbar()

  //Miscellaneous
  grupoCodigoRelac: any = {}
  dicFluxo: any = {}

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private httpClient: HttpClient,
    private utilService: UtilService,
  ) { }

  private moneyPipe: MoneyPipe = new MoneyPipe(this.utilService)

  ngOnInit() {
    this.toolbarService.hidden = true
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.titleService.title = 'Fluxo de Caixa'

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
        this.start()
      }
    })
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
  }

  start() {
    this.exibirFluxoRelatorio = false
    this.visaoFluxoCaixa = 3
    this.visaoFluxoCaixaMes = this.dblinkedSessionService.periodo.month.value
    this.versionamento = false
    this.descrVersao = null
    this.checkVersao()
    this.listRelatorios()
    this.listAnosFluxoCaixa()
  }

  listAnosFluxoCaixa() {
    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/listar-fluxo-caixa-anos/' + this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }

          this.opcoesAnos = []
          res["data"].forEach(row => this.opcoesAnos.push(row))
          this.visaoFluxoCaixaAno = this.dblinkedSessionService.periodo.year.value
          this.httpService.done()
        },
        error => {
          this.exibirMensagem({ severity: "error", message: "Erro no API ao obter a lista dos anos, contate o suporte : " + error.message })
          this.httpService.done()
        })
  }

  listRelatorios() {
    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/listar-fluxo-caixa/' + this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }

          this.listafluxosIni = new Array()
          res["data"].forEach(row => this.listafluxosIni.push(row))
          this.httpService.done()
        },
        error => {
          this.exibirMensagem({ severity: "error", message: "Erro no API ao obter a lista de fluxo de caixa, contate o suporte : " + error.message })
          this.httpService.done()
        })
  }

  listEmpresaConta() {
    this.httpService.wait();
    this.httpService.get(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/listar-fluxo-caixa-empresas-contas?fluxoId=' + this.fluxoCaixa.id + '&userId=' + this.sessionService.loggedUser.id)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }

          this.opcoesEmpresaConta = new Array()
          this.empresaContas = new Array()
          res["data"]["padrao"].forEach(row => this.opcoesEmpresaConta.push({ label: row.nome, value: row.id }))
          res["data"]["favorita"].forEach(row => this.empresaContas.push(row.id))
          this.modalFluxoCaixa = true
          this.httpService.done()
        },
        error => {
          this.exibirMensagem({ severity: "error", message: "Erro no API ao obter a lista de contas, contate o suporte : " + error.message })
          this.httpService.done()
        })
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  relatorioFluxoCaixa() {
    if (!this.visaoFluxoCaixa) {
      this.exibirMensagem({ error: true, mensagem: "Informar a visão." })
      return
    }

    if (((this.visaoFluxoCaixa === 1 || this.visaoFluxoCaixa === 2) && !this.visaoFluxoCaixaMes) ||
      (this.visaoFluxoCaixa === 3 && !this.visaoFluxoCaixaAno)) {
      this.exibirMensagem({ error: true, mensagem: "Informar o periodo." })
      return
    }

    if (this.empresaContas.length === 0) {
      this.exibirMensagem({ error: true, mensagem: "Informar pelo menos uma conta." })
      return
    }

    this.dictionary(this.visaoFluxoCaixaMes, this.visaoFluxoCaixaAno)

    let dt_ini: string = null
    let dt_fin: string = null

    if (this.visaoFluxoCaixa === 1) {
      this.mesControl = this.visaoFluxoCaixaMes
      dt_ini = this.dblinkedSessionService.periodo.year.value + "-" + this.visaoFluxoCaixaMes + "-1"

      let dt_fin_temp: Date = new Date(this.dblinkedSessionService.periodo.year.value, this.visaoFluxoCaixaMes, 0)
      dt_fin = dt_fin_temp.getFullYear() + "-" + (dt_fin_temp.getMonth() + 1) + "-" + dt_fin_temp.getDate()

    } else if (this.visaoFluxoCaixa === 2) {
      //Data inicial
      let dt_init_mes: Date = new Date(this.dblinkedSessionService.periodo.year.value, this.visaoFluxoCaixaMes - 1, 1) //Pegar o primeiro dia do mês
      let day: number = dt_init_mes.getDay() //Extrair o número do dia
      let diff: number = dt_init_mes.getDate() - day + (day === 0 ? -6 : 1) //Calcular a diferença entre o 1 dia do mês e a segunda da mesma semana
      let dt_init_mes_week: Date = new Date(dt_init_mes.setDate(diff)) //Criar uma nova data que é o primeiro dia da semana
      dt_ini = dt_init_mes_week.getFullYear() + "-" + (dt_init_mes_week.getMonth() + 1) + "-" + dt_init_mes_week.getDate()

      //Data final
      let dt_fin_mes: Date = new Date(this.dblinkedSessionService.periodo.year.value, this.visaoFluxoCaixaMes, 0) //Pegar o último dia do mês
      day = dt_fin_mes.getDay() //Extrair o número do dia
      diff = dt_fin_mes.getDate() + 7 - (day === 0 ? 7 : day) //Calcular a diferença entre o último dia do mês e a segunda da mesma semana
      let dt_fin_mes_week: Date = new Date(dt_fin_mes.setDate(diff)) //Criar uma nova data que é o primeiro dia da semana
      dt_fin = dt_fin_mes_week.getFullYear() + "-" + (dt_fin_mes_week.getMonth() + 1) + "-" + dt_fin_mes_week.getDate()

    } else {
      this.mesControl = this.dblinkedSessionService.periodo.month.value
      dt_ini = this.visaoFluxoCaixaAno + "-1-1"
      dt_fin = this.visaoFluxoCaixaAno + "-12-31"
    }

    const infos: any = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      fluxo_id: this.fluxoCaixa.id,
      visao: this.visaoFluxoCaixa,
      contas: this.empresaContas,
      dt_ini: dt_ini,
      dt_fin: dt_fin,
      baixados: this.baixados
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/fluxo-caixa/', infos)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }
          this.modalFluxoCaixa = false
          this.columns = res["data"]["colunas"]
          this.linhasFluxo = res["data"]["linhas"]

          this.exibirFluxoRelatorio = true
          this.httpService.done()
        },
        error => {
          this.exibirMensagem({ severity: "error", message: "Erro no API ao obter o fluxo de caixa, contate o suporte : " + error.message })
          this.httpService.done()
        })
  }

  buildTabelaFluxo() {
    this.valoresTabela = new Array()
    this.linhasFluxo.forEach(value => {
      let linhaTemp: any = {}
      linhaTemp.descricao = this.grupoCodigoRelac[value]
      linhaTemp.codigo = value
      this.columns.forEach(data => {
        linhaTemp[data] = this.dicFluxo[data][value]
      })
      this.valoresTabela.push(linhaTemp)
    })
  }

  atualizarContasUsuario() {
    if (this.empresaContas.length === 0) return

    const info = {
      fluxo_id: this.fluxoCaixa.id,
      user_id: this.sessionService.loggedUser.id,
      contas: this.empresaContas
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/atualizar-contas-favoritas', info)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
          }
          this.httpService.done()
        },
        err => {
          this.exibirMensagem({ error: true, mensagen: "Erro no API ao salvar as contas favoritas, contate o suporte: " + err.message })
          this.httpService.done()
        }
      );
  }

  showDialogDetal(col: string, rowData: any, versao: boolean=false) {

    if (versao) {
      let idxMes = col.split("_")[1]
      col = this.liMeses[idxMes] + " " + this.visaoFluxoCaixaAno
    }

    const codigo = rowData['codigo']
    const alias = rowData['Descrição']

    let operacaoUnica = false
    let operacaoId = null
    if (rowData.hasOwnProperty("operacaoId")) {
      operacaoUnica = rowData.hasOwnProperty("operacaoId") ? true : false
      operacaoId = rowData["operacaoId"]
    }

    const info = {
      codigo: codigo,
      data: col,
      visao: this.visaoFluxoCaixa,
      fluxo_id: this.fluxoCaixa.id,
      contas: this.empresaContas,
      baixados: this.baixados,
      operacao_unica: operacaoUnica,
      operacao_id: operacaoId,
      empresa_id: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/fluxo-caixa-detalhe', info)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }
          this.valoresTabelaDetal = res["data"]
          this.modalTitle = alias
          this.modalFluxoCaixaDetal = true
          this.httpService.done()
        },
        err => {
          this.exibirMensagem({ error: true, mensagen: "Erro no API ao obter o detalhe da operação, contate o suporte: " + err.message })
          this.httpService.done()
        }
      );
  }

  showDialogFluxoCaixa(event: any) {
    this.fluxoCaixa = new FluxoCaixa()
    this.fluxoCaixa.id = event.id
    this.fluxoCaixa.nomeFluxo = event.nome_fluxo
    this.listEmpresaConta()
  }

  fluxoCaixaChange(tipo: number) {
    this.visaoFluxoCaixa = tipo
    if (this.visaoFluxoCaixa === 1 || this.visaoFluxoCaixa === 2) {
      this.visaoFluxoCaixaMes = this.mesControl
      this.versionamento = false
      this.checkVersao()

    } else {
      if (!this.visaoFluxoCaixaAno) this.visaoFluxoCaixaAno = this.dblinkedSessionService.periodo.year.value
    }

    this.relatorioFluxoCaixa()
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage
  }

  get rows() {
    return 50
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks
  }

  verificarHeader(event: any, desc: any) {
    if (event === 'Total' && (desc === 'SALDO INICIAL' || desc === 'SALDO FINAL')) return true
    return false
  }

  dictionary(mes, ano) {
    let date: string = null;
    mes === 1 ? date = `| Janeiro de ${ano}` : null
    mes === 2 ? date = `| Fevereiro de ${ano}` : null
    mes === 3 ? date = `| Março de ${ano}` : null
    mes === 4 ? date = `| Abril de ${ano}` : null
    mes === 5 ? date = `| Maio de ${ano}` : null
    mes === 6 ? date = `| Junho de ${ano}` : null
    mes === 7 ? date = `| Julho de ${ano}` : null
    mes === 8 ? date = `| Agosto de ${ano}` : null
    mes === 9 ? date = `| Setembro de ${ano}` : null
    mes === 10 ? date = `| Outubro de ${ano}` : null
    mes === 11 ? date = `| Novembro de ${ano}` : null
    mes === 12 ? date = `| Dezembro de ${ano}` : null
    this.dataAtual = date
  }

  exportar() {
    if (this.empresaContas.length === 0) {
      this.exibirMensagem({ "error": true, "mensagem": "Selecionar pelo menos uma conta." })
      return
    }


    let dt_ini: string = null
    let dt_fin: string = null

    if (this.visaoFluxoCaixa === 1) {
      this.mesControl = this.visaoFluxoCaixaMes
      dt_ini = this.dblinkedSessionService.periodo.year.value + "-" + this.visaoFluxoCaixaMes + "-1"

      let dt_fin_temp: Date = new Date(this.dblinkedSessionService.periodo.year.value, this.visaoFluxoCaixaMes, 0)
      dt_fin = dt_fin_temp.getFullYear() + "-" + (dt_fin_temp.getMonth() + 1) + "-" + dt_fin_temp.getDate()

    }

    else if (this.visaoFluxoCaixa === 2) {
      //Data inicial
      let dt_init_mes: Date = new Date(this.dblinkedSessionService.periodo.year.value, this.visaoFluxoCaixaMes - 1, 1) //Pegar o primeiro dia do mês
      let day: number = dt_init_mes.getDay() //Extrair o número do dia
      let diff: number = dt_init_mes.getDate() - day + (day === 0 ? -6 : 1) //Calcular a diferença entre o 1 dia do mês e a segunda da mesma semana
      let dt_init_mes_week: Date = new Date(dt_init_mes.setDate(diff)) //Criar uma nova data que é o primeiro dia da semana
      dt_ini = dt_init_mes_week.getFullYear() + "-" + (dt_init_mes_week.getMonth() + 1) + "-" + dt_init_mes_week.getDate()

      //Data final
      let dt_fin_mes: Date = new Date(this.dblinkedSessionService.periodo.year.value, this.visaoFluxoCaixaMes, 0) //Pegar o último dia do mês
      day = dt_fin_mes.getDay() //Extrair o número do dia
      diff = dt_fin_mes.getDate() + 7 - (day === 0 ? 7 : day) //Calcular a diferença entre o último dia do mês e a segunda da mesma semana
      let dt_fin_mes_week: Date = new Date(dt_fin_mes.setDate(diff)) //Criar uma nova data que é o primeiro dia da semana
      dt_fin = dt_fin_mes_week.getFullYear() + "-" + (dt_fin_mes_week.getMonth() + 1) + "-" + dt_fin_mes_week.getDate()
    }

    else {
      this.mesControl = this.dblinkedSessionService.periodo.month.value
      dt_ini = this.visaoFluxoCaixaAno + "-1-1"
      dt_fin = this.visaoFluxoCaixaAno + "-12-31"
    }

    const payload: any = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      fluxo_id: this.fluxoCaixa.id,
      visao: this.visaoFluxoCaixa,
      contas: this.empresaContas,
      dt_ini: dt_ini,
      dt_fin: dt_fin,
      baixados: this.baixados,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/relatorio', payload)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
          if (res["error"]) return

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          };

          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/relatorio', httpOptions2)
            .subscribe(
              (res: Blob) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                link.href = url
                link.download = "Relatório de fluxo de caixa.xlsx"
                link.click()
                window.URL.revokeObjectURL(url)
              }, err => {
                this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível baixar o relatório." })
              });

        }, err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível gerar o relatório." })
          this.httpService.done()
        });
  }

  exportarExcelDetalhe() {
    const colunasExportar = ["dt_emissao", "dt_vencimento", "nome", "operacao", "historico", "doc", "valor"]
    exportExcel(this.valoresTabelaDetal, 'relatorio_fluxo_de_caixa_detalhe', colunasExportar)
  }

  setContentRow(rowValue) {
    if (isNaN(rowValue)) return rowValue
    else return this.moneyPipe.transform(rowValue)
  }

  setClassRow(rowValue) {
    if (!isNaN(rowValue) && rowValue < 0) return 'cls_001_03'
    else return ''
  }


  checkClickableCellFirstCondition(rowCode, column) {
    if (rowCode.indexOf('.') === -1 || (rowCode.indexOf('.') !== -1 && column == 'Descrição')) return true
    else return false
  }

  checkClickableCellSecondCondition(rowCode, column) {
    if (rowCode.indexOf('.') !== -1 && column != 'Descrição' && rowCode.indexOf('S') === -1) return true
    else return false
  }

  checkClickableCellThirdCondition(rowCode, column) {
    if (rowCode.indexOf('.') !== -1 && column != 'Descrição' && rowCode.indexOf('S') !== -1) return true
    else return false
  }

  expandCollapseAll(isExpanded: boolean) {
    isExpanded ? this.collapseAll() : this.expandAll()
  }

  public expandAll(): void {
    const temp = [...(this.versionamento ? this.linhasFluxoVersao : this.linhasFluxo)]

    temp.forEach((node: TreeNode) => {
      this.expandCollapseRecursive(node, true);
    });

    if (this.versionamento) this.linhasFluxoVersao = [...temp]
    else this.linhasFluxo = [...temp]

    this.isExpanded = true
  }

  public collapseAll(): void {
    const temp = [...(this.versionamento ? this.linhasFluxoVersao : this.linhasFluxo)]

    temp.forEach((node: TreeNode) => {
      this.expandCollapseRecursive(node, false);
    });

    if (this.versionamento) this.linhasFluxoVersao = [...temp]
    else this.linhasFluxo = [...temp]

    this.isExpanded = false

  }

  private expandCollapseRecursive(node: TreeNode, isExpand: boolean): void {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandCollapseRecursive(childNode, isExpand);
      });
    }
  }

  openModalVersao(metodo: string) {
    this.modalVersao = true
    this.tipoMetodoVersao = metodo
    if (metodo === 'get') this.listarVersoes()
  }

  checkVersao() {
    this.toolbarMain.setVisible('versao', this.versionamento)
    this.toolbarMain.setVisible('camera', this.versionamento)
    if (this.versionamento) {
      this.columnsVersUp = this.columns.slice(1)
      this.columnsVersDown = new Array()
      for (let i = 0; i < 12; i++) {
        this.columnsVers.forEach(item => this.columnsVersDown.push([item, `${item}_${i}`]))
      }
    }

    this.treeTableComVersao()

  }

  excluirVersao() {
    if (confirm("Tem certeza que deseja excluir essa versão?")) {
      const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
      this.httpService.wait()
      this.httpClient.delete(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/versao?descricao=' + this.descrVersao
        + '&empresaId=' + this.dblinkedSessionService.empresa.id + '&fluxoCaixaId=' + this.fluxoCaixa.id, httpOptions)
        .subscribe(
          res => {
            this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: res["message"] })
            this.listarVersoes()
            this.httpService.done()
          },
          err => {
            if (err.status === 400 && err.error && err.error.message) {
              this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: '', detail: err.error.message })
            } else if (err.status === 500 && err.error && err.error.message) {
              this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: '', detail: err.error.message + err.error.data })
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: '', detail: "Erro no API ao excluir a versão, contate o suporte: " + err.message })
            }

            this.httpService.done()
          }
        );
    }
  }

  carregarVersao() {
    //Verificar se tem descrição selecionada
    if (!this.descrVersao) {
      this.exibirMensagem({ severity: "warn", message: "Favor selecionar uma versão." })
      return
    }

    this.httpService.wait()
    this.httpService.get(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/versao?descricao=' + this.descrVersao + '&fluxoCaixaId=' + this.fluxoCaixa.id)
      .subscribe(
        res => {
          this.treeTableComVersao(res.data)
          this.modalVersao = false
          this.httpService.done()
        },
        err => {
          if (err.status === 400 && err.error && err.error.message) {
            this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: '', detail: err.error.message })
          } else if (err.status === 500 && err.error && err.error.message) {
            this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: '', detail: err.error.message + err.error.data })
          } else {
            this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: '', detail: "Erro no API ao salvar a versão, contate o suporte: " + err.message })
          }

          this.httpService.done()
        }
      );
  }

  salvarVersao(verificar: boolean = true) {
    //Verificar se tem descrição
    if (!this.descrVersao || this.descrVersao.trim().length === 0) {
      this.exibirMensagem({ severity: "warn", message: "Favor entrar uma descrição para essa versão." })
      return
    }

    //Salvar no banco
    const info = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id,
      dados: this.linhasFluxo,
      fluxo_caixa_id: this.fluxoCaixa.id,
      descr_versao: this.descrVersao,
      verificar: verificar
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/versao', info)
      .subscribe(
        res => {
          this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: res["message"] })
          this.modalVersao = false
          this.httpService.done()
        },
        err => {
          if (err.status === 400 && err.error && err.error.data && confirm("Deseja sobrepôr essa versão?")) {
            this.salvarVersao(false)
          } else if (err.status === 400 && err.error && err.error.message) {
            this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: '', detail: err.error.message })
          } else if (err.status === 500 && err.error && err.error.message) {
            this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: '', detail: err.error.message + err.error.data })
          } else {
            this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: '', detail: "Erro no API ao salvar a versão, contate o suporte: " + err.message })
          }

          this.httpService.done()
        }
      );

  }

  listarVersoes() {
    this.httpService.wait()
    this.httpService.get(this.configService.defaultUrlApiUpload + 'financeiro/fluxo-caixa/listar-versao?fluxoCaixaId=' + this.fluxoCaixa.id)
      .subscribe(
        res => {
          this.opcoesVersoes = [{ label: "Selecione uma versão", value: null }]
          res.data.forEach(element => this.opcoesVersoes.push({ label: element.descr_versao, value: element.descr_versao }));
          this.httpService.done()
        },
        err => {
          if (err.status === 400 && err.error && err.error.message) {
            this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: '', detail: err.error.message })
          } else if (err.status === 500 && err.error && err.error.message) {
            this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: '', detail: err.error.message + err.error.data })
          } else {
            this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: '', detail: "Erro no API ao salvar a versão, contate o suporte: " + err.message })
          }

          this.httpService.done()
        }
      );
  }

  treeTableComVersao(versao: Array<any> = new Array()) {

    //Copiar dentro de um novo objeto
    this.linhasFluxoVersao = [...this.linhasFluxo]

    //Refatorar o novo objeto para se adaptar ao novo layout
    this.linhasFluxoVersao.forEach(grupo => {
      for (let i = 0; i < 12; i++) {
        this.columnsVers.forEach((item, idx) => {
          let key = `${item}_${i}`
          if (idx === 0) grupo.data[key] = grupo.data[`${this.liMeses[i]} ${this.visaoFluxoCaixaAno}`]
          else if (idx === 1) grupo.data[key] = 0 //Será preenchido na próxima etapa
          else if (idx === 2) grupo.data[key] = grupo.data[`${this.columnsVers[1]}_${i}`] - grupo.data[`${this.columnsVers[0]}_${i}`]
          else if (idx === 3) grupo.data[key] = this.variacao(grupo.data[`${this.columnsVers[1]}_${i}`], grupo.data[`${this.columnsVers[0]}_${i}`])
        })
      }

      grupo.children.forEach(alias => {
        for (let i = 0; i < 12; i++) {
          this.columnsVers.forEach((item, idx) => {
            let key = `${item}_${i}`
            if (idx === 0) alias.data[key] = alias.data[`${this.liMeses[i]} ${this.visaoFluxoCaixaAno}`]
            else if (idx === 1) alias.data[key] = 0 //Será preenchido na próxima etapa
            else if (idx === 2) alias.data[key] = alias.data[`${this.columnsVers[1]}_${i}`] - alias.data[`${this.columnsVers[0]}_${i}`]
            else if (idx === 3) alias.data[key] = this.variacao(alias.data[`${this.columnsVers[1]}_${i}`], alias.data[`${this.columnsVers[0]}_${i}`])
          })
        }

        alias.children.forEach(operacao => {
          for (let i = 0; i < 12; i++) {
            this.columnsVers.forEach((item, idx) => {
              let key = `${item}_${i}`
              if (idx === 0) operacao.data[key] = operacao.data[`${this.liMeses[i]} ${this.visaoFluxoCaixaAno}`]
              else if (idx === 1) operacao.data[key] = 0 //Será preenchido na próxima etapa
              else if (idx === 2) operacao.data[key] = operacao.data[`${this.columnsVers[1]}_${i}`] - operacao.data[`${this.columnsVers[0]}_${i}`]
              else if (idx === 3) operacao.data[key] = this.variacao(operacao.data[`${this.columnsVers[1]}_${i}`], operacao.data[`${this.columnsVers[0]}_${i}`])
            })
          }
        })
      })
    })

    //Preencher com os dados da versão se tiver
    versao.forEach(line => {
      let idxMes = (line.mes) - 1
      let operacaoTransf = line.transferencia_entrada_saida_id ? line.transferencia_entrada_saida_id === 1 ? -2 : -1 : null

      this.linhasFluxoVersao.forEach(grupo => {
        if (grupo.data.codigo === line.cod_grupo && !line.operacao_id && !line.transferencia_entrada_saida_id) {
          this.columnsVers.forEach((item, idx) => {
            let key = `${item}_${idxMes}`
            if (idx === 1) grupo.data[key] = line.valor
            else if (idx === 2) grupo.data[key] = grupo.data[`${this.columnsVers[1]}_${idxMes}`] - grupo.data[`${this.columnsVers[0]}_${idxMes}`]
            else if (idx === 3) grupo.data[key] = this.variacao(grupo.data[`${this.columnsVers[1]}_${idxMes}`], grupo.data[`${this.columnsVers[0]}_${idxMes}`])
          })

        } else {
          grupo.children.forEach(alias => {
            if (alias.data.codigo === line.cod_grupo && !line.operacao_id && !line.transferencia_entrada_saida_id) {
              this.columnsVers.forEach((item, idx) => {
                let key = `${item}_${idxMes}`
                if (idx === 1) alias.data[key] = line.valor
                else if (idx === 2) alias.data[key] = alias.data[`${this.columnsVers[1]}_${idxMes}`] - alias.data[`${this.columnsVers[0]}_${idxMes}`]
                else if (idx === 3) alias.data[key] = this.variacao(alias.data[`${this.columnsVers[1]}_${idxMes}`], alias.data[`${this.columnsVers[0]}_${idxMes}`])
              })

            } else {
              alias.children.forEach(operacao => {
                if (operacao.data.codigo === line.cod_grupo && (line.operacao_id === operacao.data.operacaoId || line.operacao_id === operacaoTransf)) {
                  this.columnsVers.forEach((item, idx) => {
                    let key = `${item}_${idxMes}`
                    if (idx === 1) operacao.data[key] = line.valor
                    else if (idx === 2) operacao.data[key] = operacao.data[`${this.columnsVers[1]}_${idxMes}`] - operacao.data[`${this.columnsVers[0]}_${idxMes}`]
                    else if (idx === 3) operacao.data[key] = this.variacao(operacao.data[`${this.columnsVers[1]}_${idxMes}`], operacao.data[`${this.columnsVers[0]}_${idxMes}`])
                  })
                }
              })
            }
          })
        }
      })
    })
  }

  variacao(nbr1: number, nbr2: number): number {
    return nbr2 != 0 ? (nbr1 - nbr2) / Math.abs(nbr2) * 100 : 0
  }

}