import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { Message, MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
  selector: 'app-teste',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ContabilImportComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;

  file: File;
  replace: boolean;
  title: any;

  msgs: Message[] = null;

  constructor(private titleService: TitleService,
              private httpService: HttpService,
              private toolbarService: ToolbarService,
              private dblinkedSessionService: DblinkedSessionService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.title = 'IMPORTAR';
    this.titleService.title = 'Importar arquivo ECD';
    // this.toolbarService.clear();
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
  }

  ngOnDestroy() {
    // this.toolbarService.clear();
    this.toolbarService.hidden = false;
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
  }

  importar() {
    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('type', 'ecd');
    data.append('replace', this.replace ? 'true' : 'false');
    data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());

    this.httpService.wait();
    this.httpService.post('/custom/upload', data)
                    .subscribe(result => this.uploaded(result),
                               error => this.httpService.handleError(error, () => this.importar()),
                               () => this.httpService.done());
  }

  private uploaded(result?: any) {
    this.messageService.add({
      severity: result.error ? 'error' : 'success',
      detail: result.message || 'Upload efetuado com sucesso!'
    });
  }

  get importDisabled(): boolean {
    return !this.dblinkedSessionService.hasEmpresaSelected || !this.file;
  }

  hideMessage() {
    this.file = null;

  }
}
