import { AuthUser } from 'app/proof/model/auth-user.model';

export class Pais {
  id: number;
  nome: string;
  iso: string;
  codigoBacen: number;
  changeUser: AuthUser;
  changeTimestamp: Date;

  constructor(codpais?: Pais | number) {
    if (codpais !== undefined && codpais !== null) {
      if (typeof codpais === 'number') {
        this.id = codpais;
      } else {
        this.id = codpais.id;
        this.nome = codpais.nome;
        this.iso = codpais.iso;
        this.codigoBacen = codpais.codigoBacen;
        this.changeUser = codpais.changeUser;
        this.changeTimestamp = codpais.changeTimestamp;
      }
    }
  }
}
