import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Toolbar } from "app/shared/model/toolbar.model";
import { ConfigService } from "app/shared/services/config.service";
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from "app/shared/services/http.service";
import { SessionService } from "app/shared/services/session.service";
import { TitleService } from "app/shared/services/title.service";
import { ToolbarService } from "app/shared/services/toolbar.service";
import tools from "app/util/processing/tools";
import { LazyLoadEvent, MessageService, SelectItem } from "primeng/api";
import { Subscription } from "rxjs/Subscription";
import * as Highcharts from 'highcharts/highstock';
import exportExcel from "app/util/processing/exportExcel";


@Component({
  selector: 'app-dashboard-new3',
  templateUrl: './dashboard-new3.component.html',
  styleUrls: ['./dashboard-new3.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class DashboardNewComponent3 implements OnInit, OnDestroy {

  ////////////////////////////////////////
  // REFATOR SIMON LALLEMENT 02/02/2022 //
  ////////////////////////////////////////

  //Boolean
  statusTela1: boolean = true
  statusTela2: boolean = false
  statusTela3: boolean = false
  statusLink1: boolean = true
  statusLink2: boolean = false
  statusLink3: boolean = false
  showDatas: boolean = false
  baixados: boolean = true
  useCalendar: boolean = false
  isCardTable: boolean = true
  fullAcumulado: boolean = false
  isCardAcumulado: boolean = true
  isCardMov: boolean = true
  modalDetalheMov: boolean = false
  modalComentarios: boolean = false
  criacaoComentario: boolean = false
  edicaoComentario: boolean = false
  isFiltered: boolean = false

  //Number
  idMascara: number = null
  saldoinicialano: number = 0
  saldofinalano: number = 0
  saldomesanomov: number = 0
  lisaldofinalcptotal: number = 0
  lisaldofinalcrtotal: number = 0
  lisaldomovimentacao: number = 0
  idComentario: number = null
  totalMov: number = 0
  contaDetalhe: number = null
  totalDetalhe: number = 0
  offsetMov: number = null
  offsetDet: number = null
  limitMov: number = null
  limitDet: number = null
  sortOrderMov: number = null
  sortOrderDet: number = null

  //Dropdown e multiselect
  listMascaras: SelectItem[] = []
  listEmpresas: SelectItem[] = []

  //Date
  dtInicial1: Date = new Date()
  dtFinal1: Date = new Date()
  dtInicial2: Date = new Date()
  dtFinal2: Date = new Date()
  ultimaAtu: Date = null

  //Array
  empresasSelecionadas: Array<number> = new Array()
  licontas: Array<string> = new Array()
  licontasreduced: Array<string> = new Array()
  lidays: Array<string> = new Array()
  lidaysglobal: Array<string> = new Array()
  liweeks: Array<string> = new Array()
  liweeksglobal: Array<string> = new Array()
  limonths: Array<string> = new Array()
  lisaldoinicialdiatotal: Array<number> = new Array()
  limovimentacaodiatotal: Array<number> = new Array()
  lisaldofinaldiatotal: Array<number> = new Array()
  lisaldoinicialsemanatotal: Array<number> = new Array()
  limovimentacaosemanatotal: Array<number> = new Array()
  lisaldofinalsemanatotal: Array<number> = new Array()
  lisaldoinicialmestotal: Array<number> = new Array()
  limovimentacaomestotal: Array<number> = new Array()
  lisaldofinalmestotal: Array<number> = new Array()
  licpmes: Array<number> = new Array()
  licrmes: Array<number> = new Array()
  liglobal: Array<any> = new Array()
  filterTableValues: Array<any> = new Array()
  movTable: Array<any> = new Array()
  rowComments: Array<any> = new Array()

  //Toolbar
  toolbarMainIni = [
    { icon: 'refresh', text: 'Atualizar dados', tooltip: 'Atualizar', visible: true, disabled: false, color: 'default', onClick: () => this.atualizarView() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.exportSaldosExcel() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideCardTable()
        this.changeStateTable()
      }
    },
  ]

  toolbarChartsIni = [
    { icon: 'fullscreen', text: '', tooltip: 'Tela cheia', visible: true, disabled: false, color: 'default', onClick: () => this.fullScreenAcumulado() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideCardAcumulado()
        this.changeStateAcumulado()
      }
    },
  ]

  toolbarMovIni = [
    { icon: 'refresh', text: 'Atualizar dados', tooltip: 'Atualizar', visible: true, disabled: false, color: 'default', onClick: () => this.atualizarView() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.exportMovimentacao() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideCardMov()
        this.changeStateMov()
      }
    },
  ]

  public toolbarMain = new Toolbar()
  public toolbarCharts = new Toolbar()
  public toolbarMov = new Toolbar()

  //Tools
  tools: tools

  //String
  currentStateTable: String = 'initial'
  currentStateAcumulado: String = 'initial'
  currentStateMov: String = 'initial'
  comentario: String = null
  diaMinDetalhe: String = null
  diaMaxDetalhe: String = null
  sortFieldMov: String = null
  sortFieldDet: String = null

  //Dicionários
  saldosdias: any = {}
  saldossemanas: any = {}
  saldosmeses: any = {}
  lisaldoinicialdias: any = {}
  limovimentacaodias: any = {}
  lisaldofinaldias: any = {}
  lisaldoinicialsemana: any = {}
  limovimentacaosemana: any = {}
  lisaldofinalsemana: any = {}
  lisaldoinicialmes: any = {}
  limovimentacaomes: any = {}
  lisaldofinalmes: any = {}
  diccontas: any = {}
  diccrpcp: any = {}
  rowSelected: any = {}
  filtersMov: any = {}
  filtersDet: any = {}

  private sessionSubscription: Subscription

  constructor(
    private titleService: TitleService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private messageService: MessageService

  ) {
    this.tools = new tools(this.messageService)
  }

  ngOnInit() {
    this.titleService.title = "Saldos em conta"
    this.toolbarService.hidden = true
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarCharts.setAll(this.toolbarChartsIni)
    this.toolbarMov.setAll(this.toolbarMovIni)
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      async () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
          this.useCalendar = false
          this.dtInicial1 = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
          this.dtFinal1 = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
          this.dtInicial2 = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
          this.dtFinal2 = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
          await this.getListEmpresas()
          this.getLastAtuView()
          this.getFluxoDay()
          this.getMascaras()
          if (this.statusTela2 && this.empresasSelecionadas.length > 0) this.getMovimentacoes()
        }
      });
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  changeStateMov() {
    this.currentStateMov = this.currentStateMov === 'initial' ? 'final' : 'initial';
  }

  changeStateAcumulado() {
    this.currentStateAcumulado = this.currentStateAcumulado === 'initial' ? 'final' : 'initial'
  }

  changeStateTable() {
    this.currentStateTable = this.currentStateTable === 'initial' ? 'final' : 'initial'
  }

  exportSaldosExcel() {
    let dtinit: String = null
    if (this.useCalendar) {
      dtinit = this.dtInicial1.getFullYear() + "-1-1"

    } else {
      dtinit = this.dblinkedSessionService.periodo.year.value + "-1-1"
    }

    let dtfin: String = this.dtFinal1.getFullYear() + "-" + (this.dtFinal1.getMonth() + 1) + "-" + this.dtFinal1.getDate()


    const infos = {
      empresa_id: this.empresasSelecionadas,
      lidays: this.lidays,
      liweeks: this.liweeks,
      licontas: this.licontasreduced,
      licontas_global: this.licontas,
      user_id: this.sessionService.loggedUser.id,
      dtinit: dtinit,
      dtfinal: dtfin,
      baixados: this.baixados
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/relatorio-saldo-em-conta', infos)
      .subscribe(
        res => {
          this.tools.exibirMensagem(res)
          this.httpService.done()
          if (res["error"]) return

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          }

          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/relatorio-saldo-em-conta', httpOptions2)
            .subscribe(
              (res: any) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                link.href = url
                link.download = "Relatóro saldo em contas.xlsx"
                link.click()
                window.URL.revokeObjectURL(url)
              }, err => {
                this.tools.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível baixar o relatório." })
                console.log(err)
              })

        },
        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao gerar o relatório de saldos em conta, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
          this.httpService.done()
        });
  }

  getMascaras() {
    this.httpService.wait()
    this.httpService.get(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/get-mascaras/' + this.dblinkedSessionService.empresa.id)
      .subscribe(
        res => {
          this.httpService.done()
          if (res.error) {
            this.tools.exibirMensagem(res)
            return
          }
          this.listMascaras = []
          res.data.forEach(row => this.listMascaras.push({ label: row.nome_fluxo, value: row.id }))
        },
        error => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao obter as máscaras, contate o suporte: " + error.message, severity: "error" })
          console.log(error)
          this.httpService.done()
        }
      )
  }

  getFluxoDay() {

    let dtinit: String = null
    if (this.useCalendar) {
      dtinit = this.dtInicial1.getFullYear() + "-1-1"

    } else {
      dtinit = this.dblinkedSessionService.periodo.year.value + "-1-1"
    }

    let dtfin: String = this.dtFinal1.getFullYear() + "-" + (this.dtFinal1.getMonth() + 1) + "-" + this.dtFinal1.getDate()

    const infos = {
      empresa_id: this.empresasSelecionadas,
      dtinit: dtinit,
      dtfinal: dtfin,
      baixados: this.baixados
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/saldoemconta', infos)
      .subscribe(
        res => {
          this.httpService.done()
          if (res.error) {
            this.tools.exibirMensagem(res)
            return
          }

          this.extractResult(res.data)

        },
        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao carregar os saldos em conta, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  extractResult(result: any) {

    //Extração das linhas de label
    this.licontas = result.licontas
    this.lidaysglobal = result.lidays
    this.liweeksglobal = result.liweeks
    this.limonths = result.limonths
    this.saldosdias = result.lisaldosdays
    this.saldossemanas = result.lisaldosweek
    this.saldosmeses = result.lisaldosmonth
    this.diccontas = result.diccontas
    this.diccrpcp = result.diccrcp

    //Reinicializar variaveis
    this.lisaldoinicialdiatotal = new Array()
    this.limovimentacaodiatotal = new Array()
    this.lisaldofinaldiatotal = new Array()
    this.lisaldoinicialsemanatotal = new Array()
    this.limovimentacaosemanatotal = new Array()
    this.lisaldofinalsemanatotal = new Array()
    this.lisaldoinicialmestotal = new Array()
    this.limovimentacaomestotal = new Array()
    this.lisaldofinalmestotal = new Array()
    this.lisaldoinicialdias = {}
    this.limovimentacaodias = {}
    this.lisaldofinaldias = {}
    this.lisaldoinicialsemana = {}
    this.limovimentacaosemana = {}
    this.lisaldofinalsemana = {}
    this.lisaldoinicialmes = {}
    this.limovimentacaomes = {}
    this.lisaldofinalmes = {}
    this.lidays = new Array()
    this.liweeks = new Array()
    this.saldoinicialano = 0
    this.saldofinalano = 0
    this.saldomesanomov = 0
    this.licontasreduced = new Array()
    this.licpmes = new Array()
    this.licrmes = new Array()

    //Cálculo dos bigNumbers
    let keyBeginYear: string = this.lidaysglobal[0]
    let keyEndYear: string = this.lidaysglobal[this.lidaysglobal.length - 1]
    for (let conta in this.saldosdias) this.saldoinicialano += this.saldosdias[conta].saldos_init[keyBeginYear]
    for (let conta in this.saldosdias) this.saldofinalano += this.saldosdias[conta].saldos_fin[keyEndYear]
    this.saldomesanomov = this.saldofinalano - this.saldoinicialano

    //Extração só dos dias e semanas que interessam
    let dtInitStr: string = (this.dtInicial1.getDate() < 10 ? ("0" + this.dtInicial1.getDate()) : this.dtInicial1.getDate()) + "/" +
      ((this.dtInicial1.getMonth() + 1) < 10 ? ("0" + (this.dtInicial1.getMonth() + 1)) : (this.dtInicial1.getMonth() + 1)) + "/" +
      this.dtInicial1.getFullYear()
    let foundDay: boolean = false
    this.lidaysglobal.forEach(day => {
      if (day === dtInitStr) foundDay = true
      if (foundDay) this.lidays.push(day)
    })

    let weeknumber: number = this.ISO8601_week_no(this.dtInicial1)
    let ano: number = this.ISO8601_week_no(this.dtInicial1) < this.ISO8601_week_no(this.dtFinal1) ? this.dtInicial1.getFullYear() : this.dtInicial1.getFullYear() - 1
    let keyWeekStr: string = weeknumber + "-" + ano
    let foundWeek: boolean = false
    this.liweeksglobal.forEach(week => {
      if (week === keyWeekStr) foundWeek = true
      if (foundWeek) this.liweeks.push(week)
    })

    //Extração dos valores
    let contasToExclude: Set<number> = new Set()
    for (let conta in this.saldosdias) {
      let somaContaSdoInit = 0
      let somaContaMov = 0
      let somaContaSdoFin = 0
      this.lisaldoinicialdias[conta] = new Array()
      this.limovimentacaodias[conta] = new Array()
      this.lisaldofinaldias[conta] = new Array()
      this.lidays.forEach(day => {
        this.lisaldoinicialdias[conta].push(this.saldosdias[conta].saldos_init[day])
        this.limovimentacaodias[conta].push(this.saldosdias[conta].mov[day])
        this.lisaldofinaldias[conta].push(this.saldosdias[conta].saldos_fin[day])
        somaContaSdoInit += this.saldosdias[conta].saldos_init[day]
        somaContaMov += this.saldosdias[conta].mov[day]
        somaContaSdoFin += this.saldosdias[conta].saldos_fin[day]
      })
      if (somaContaSdoInit < 0.01 && somaContaMov < 0.01 && somaContaSdoFin < 0.01 && somaContaSdoInit > -0.01 && somaContaMov > -0.01 && somaContaSdoFin > -0.01) contasToExclude.add(Number(conta))
    }

    for (let conta in this.saldossemanas) {
      this.lisaldoinicialsemana[conta] = new Array()
      this.limovimentacaosemana[conta] = new Array()
      this.lisaldofinalsemana[conta] = new Array()
      this.liweeks.forEach(week => {
        this.lisaldoinicialsemana[conta].push(this.saldossemanas[conta].saldos_init[week])
        this.limovimentacaosemana[conta].push(this.saldossemanas[conta].mov[week])
        this.lisaldofinalsemana[conta].push(this.saldossemanas[conta].saldos_fin[week])
      })
    }

    let contasToExcludeGlobal: Set<number> = new Set()
    for (let conta in this.saldosmeses) {
      let somaContaSdoInit = 0
      let somaContaMov = 0
      let somaContaSdoFin = 0
      this.lisaldoinicialmes[conta] = new Array()
      this.limovimentacaomes[conta] = new Array()
      this.lisaldofinalmes[conta] = new Array()
      this.limonths.forEach(month => {
        this.lisaldoinicialmes[conta].push(this.saldosmeses[conta].saldos_init[month])
        this.limovimentacaomes[conta].push(this.saldosmeses[conta].mov[month])
        this.lisaldofinalmes[conta].push(this.saldosmeses[conta].saldos_fin[month])
        somaContaSdoInit += this.saldosmeses[conta].saldos_init[month]
        somaContaMov += this.saldosmeses[conta].mov[month]
        somaContaSdoFin += this.saldosmeses[conta].saldos_fin[month]
      })
      if (somaContaSdoInit < 0.01 && somaContaMov < 0.01 && somaContaSdoFin < 0.01 && somaContaSdoInit > -0.01 && somaContaMov > -0.01 && somaContaSdoFin > -0.01) contasToExcludeGlobal.add(Number(conta))
    }

    //Cálculo dos subtotais
    this.lidays.forEach((day, i) => {
      let somaInit = 0
      let somaMov = 0
      let somaFin = 0
      for (let conta in this.lisaldoinicialdias) somaInit += this.lisaldoinicialdias[conta][i]
      for (let conta in this.limovimentacaodias) somaMov += this.limovimentacaodias[conta][i]
      for (let conta in this.lisaldofinaldias) somaFin += this.lisaldofinaldias[conta][i]
      this.lisaldoinicialdiatotal.push(somaInit)
      this.limovimentacaodiatotal.push(somaMov)
      this.lisaldofinaldiatotal.push(somaFin)
    })

    this.liweeks.forEach((week, i) => {
      let somaInit = 0
      let somaMov = 0
      let somaFin = 0
      for (let conta in this.lisaldoinicialsemana) somaInit += this.lisaldoinicialsemana[conta][i]
      for (let conta in this.limovimentacaosemana) somaMov += this.limovimentacaosemana[conta][i]
      for (let conta in this.lisaldofinalsemana) somaFin += this.lisaldofinalsemana[conta][i]
      this.lisaldoinicialsemanatotal.push(somaInit)
      this.limovimentacaosemanatotal.push(somaMov)
      this.lisaldofinalsemanatotal.push(somaFin)
    })

    this.limonths.forEach((month, i) => {
      let somaInit = 0
      let somaMov = 0
      let somaFin = 0
      for (let conta in this.lisaldoinicialmes) somaInit += this.lisaldoinicialmes[conta][i]
      for (let conta in this.limovimentacaomes) somaMov += this.limovimentacaomes[conta][i]
      for (let conta in this.lisaldofinalmes) somaFin += this.lisaldofinalmes[conta][i]
      this.lisaldoinicialmestotal.push(somaInit)
      this.limovimentacaomestotal.push(somaMov)
      this.lisaldofinalmestotal.push(somaFin)
    })

    //Excluir as contas do periodo atual
    this.licontasreduced = [...this.licontas]
    contasToExclude.forEach(conta => {
      for (let contaStr in this.diccontas) {
        if (this.diccontas[contaStr] === conta) {
          this.licontasreduced.splice(this.licontasreduced.indexOf(contaStr), 1)
          break
        }
      }
    })

    // Excluir o resto das contas
    contasToExcludeGlobal.forEach(conta => {
      for (let contaStr in this.diccontas) {
        if (this.diccontas[contaStr] === conta) {
          this.licontas.splice(this.licontas.indexOf(contaStr), 1)
          break
        }
      }
    })

    for (let mes in this.diccrpcp) {
      this.licpmes.push(this.diccrpcp[mes].CP)
      this.licrmes.push(this.diccrpcp[mes].CR)
    }

    this.popSaldoDiaChart()
  }

  popSaldoDiaChart() {

    Highcharts.setOptions({
      lang: {
        decimalPoint: ",",
        thousandsSep: '.'
      }
    })

    var chart = new Highcharts.Chart({
      credits: { enabled: false },
      chart: {
        type: 'column',
        renderTo: 'saldo_acumulado_chart'
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      title: {
        text: ""
      },
      xAxis: [{
        categories: this.limonths,
        crosshair: true
      }],
      yAxis: {
        title: {
          text: "",
          reserveSpace: false
        }
      },
      tooltip: {
        valuePrefix: "R$ ",
        valueDecimals: 2,
        shared: true
      },
      series: [{
        type: 'column',
        name: "Pagamentos",
        data: this.licpmes,
        color: "#D95656"
      },
      {
        type: 'column',
        name: "Recebimentos",
        data: this.licrmes,
        color: "#5F91E6"
      },
      {
        type: 'spline',
        name: "Saldo acumulado",
        data: this.lisaldofinalmestotal,
        color: "#C0C0C0",
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y.toLocaleString('pt-Br', {
              maximumFractionDigits: 0
            })
          }
        }
      }]
    }


    );
  }

  ISO8601_week_no(dt) {
    let tdt: Date = new Date(dt.valueOf())
    let dayn: number = (dt.getDay() + 6) % 7
    tdt.setDate(tdt.getDate() - dayn + 3)
    let firstThursday = tdt.valueOf()
    tdt.setMonth(0, 1)
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7)
    }
    return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000)
  }

  toggleAddComentario() {
    this.criacaoComentario = true
    this.edicaoComentario = false
  }

  toggleEditComentario(c: any) {
    this.criacaoComentario = false
    this.edicaoComentario = true
    this.comentario = c.descricao
    this.idComentario = c.id
  }

  editarComentario() {
    const infos = {
      user_id: this.sessionService.loggedUser.id,
      comentario: this.comentario,
      id: this.idComentario
    }

    this.httpService.wait()
    this.httpService.put(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/comentarios', null, infos)
      .subscribe(
        res => {
          this.httpService.done()
          this.tools.exibirMensagem(res)
          if (res.error) return

          this.edicaoComentario = false
          this.comentario = null
          this.onRowSelect(this.rowSelected)
          this.getMovimentacoes()
        },

        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao editar o comentário, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  criarComentario() {
    const infos = {
      user_id: this.sessionService.loggedUser.id,
      comentario: this.comentario,
      nfe_fin_id: this.rowSelected.id
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/comentarios', infos)
      .subscribe(
        res => {
          this.httpService.done()
          this.tools.exibirMensagem(res)
          if (res.error) return

          this.criacaoComentario = false
          this.comentario = null
          this.onRowSelect(this.rowSelected)
          this.getMovimentacoes()
        },

        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao salvar o comentário, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  onRowSelect(value: any) {
    this.rowSelected = value
    this.comentario = null
    if (value["comentarios"] === "") {
      this.criacaoComentario = true
      this.edicaoComentario = false

    } else {
      this.criacaoComentario = false
      this.edicaoComentario = true
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'nfid': value.id.toString()
      })
    };

    this.httpService.wait()
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/comentarios', httpOptions)
      .subscribe(
        res => {
          this.httpService.done()
          if (res["error"]) {
            this.tools.exibirMensagem(res)
            return
          }

          this.rowComments = new Array()
          res["data"].forEach(row => this.rowComments.push(row))
          this.modalComentarios = true
        },
        error => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao buscar os comentários, contate o suporte: " + error.message, severity: "error" })
          console.log(error)
          this.httpService.done()
        }
      )
  }

  atualizarTudo(val: number) {
    if (val == 1) {
      this.useCalendar = true
      this.showDatas = false
      this.getFluxoDay()

    } else if (val == 2) {
      this.useCalendar = true
      this.showDatas = false
      this.getMovimentacoes()

    } else {
      this.getFluxoDay()
      this.getMovimentacoes()
    }
  }

  deleteComment(c: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'id': c.id.toString()
      })
    };

    this.httpService.wait()
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/comentarios', httpOptions)
      .subscribe(
        res => {
          this.httpService.done()
          this.tools.exibirMensagem(res)
          if (res["error"]) return
          this.onRowSelect(this.rowSelected)
          this.getMovimentacoes()
        },
        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao excluir o comentário, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  getMovimentacoes(event: LazyLoadEvent = {}) {

    if (this.empresasSelecionadas.length === 0) return

    let dtinit: String = this.dtInicial2.getFullYear() + "-" + (this.dtInicial2.getMonth() + 1) + "-" + this.dtInicial2.getDate()
    let dtfin: String = this.dtFinal2.getFullYear() + "-" + (this.dtFinal2.getMonth() + 1) + "-" + this.dtFinal2.getDate()

    //Necessário para exportar o relatório
    this.offsetMov = event.first
    this.limitMov = event.rows
    this.sortOrderMov = event.sortOrder
    this.sortFieldMov = event.sortField
    this.filtersMov = event.filters

    const infos = {
      empresa_id: this.empresasSelecionadas,
      dt_min: dtinit,
      dt_max: dtfin,
      baixados: this.baixados,
      idMascara: this.idMascara,
      offset: this.offsetMov,
      limit: this.limitMov,
      sort_order: this.sortOrderMov,
      sort_field: this.sortFieldMov,
      filters: this.filtersMov
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/movimentacao', infos)
      .subscribe(
        res => {
          this.httpService.done()
          if (res.error) {
            this.tools.exibirMensagem(res)
            return
          }

          this.liglobal = new Array()
          res.data.data.forEach(row => { this.liglobal.push(row) })
          this.totalMov = res.data.total
          this.lisaldofinalcptotal = res.data.totalCp
          this.lisaldofinalcrtotal = res.data.totalCr
          this.lisaldomovimentacao = this.lisaldofinalcrtotal + this.lisaldofinalcptotal
        },
        error => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao carregar a movimentação, contate o suporte: " + error.message, severity: "error" })
          console.log(error)
          this.httpService.done()
        }
      )
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage
  }

  get rows() {
    return this.configService.applicationConfig.rows
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks
  }

  ShowHideCardTable() {
    this.isCardTable = !this.isCardTable
    this.toolbarMain.setIcon('btn2', this.isCardTable ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn2', this.isCardTable ? 'Ver menos' : 'Ver mais')
  }


  ShowHideCardAcumulado() {
    this.isCardAcumulado = !this.isCardAcumulado;
    this.toolbarCharts.setIcon('btn2', this.isCardAcumulado ? 'expand_less' : 'expand_more')
    this.toolbarCharts.setTooltip('btn2', this.isCardAcumulado ? 'Ver menos' : 'Ver mais')
  }

  fullScreenAcumulado() {
    this.fullAcumulado = !this.fullAcumulado
    this.toolbarCharts.setIcon('btn1', this.fullAcumulado ? 'fullscreen_exit' : 'fullscreen')
    this.toolbarCharts.setTooltip('btn1', this.fullAcumulado ? 'Sair de tela cheia' : 'Tela cheia')
  }

  ShowHideCardMov() {
    this.isCardMov = !this.isCardMov
    this.toolbarMov.setIcon('btn2', this.isCardMov ? 'expand_less' : 'expand_more')
    this.toolbarMov.setTooltip('btn2', this.isCardMov ? 'Ver menos' : 'Ver mais')
  }

  showTelaDashboard(tipo) {
    this.statusTela1 = false
    this.statusTela2 = false

    if (tipo === 'TELA_1') {
      this.statusTela1 = true
      this.showDatas = false

    } else if (tipo === 'TELA_2') {
      this.statusTela2 = true
      this.showDatas = false
      if (this.empresasSelecionadas.length > 0 && this.liglobal.length === 0) this.getMovimentacoes()
    }
  }

  showTypeDashboard(tipo) {

    this.statusLink1 = false
    this.statusLink2 = false
    this.statusLink3 = false

    if (tipo === 'LINK_1') {
      this.statusLink1 = true
      this.showDatas = false

    } else if (tipo === 'LINK_2') {
      this.statusLink2 = true
      this.showDatas = false

    } else if (tipo === 'LINK_3') {
      this.statusLink3 = true
      this.showDatas = false
    }
  }

  dropdownDateEvent() {
    this.showDatas = !this.showDatas
  }

  getStringFromHtml(text) {
    const html = text
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }

  //   gerarRelatorio() {


  //     this.config = [];

  //     if (this.relatorioEscolhido > 0) {
  //       this.httpService.wait();
  //       this.httpService.get('/custom/pbi/report-pbi', [this.sessionService.loggedUser.id, this.relatorioEscolhido])
  //         .subscribe(rst => {

  //           if (rst.error) {
  //             this.messageService.add({
  //               severity: 'warn', key: 'messageLinnks',
  //               summary: '',
  //               detail: rst.message
  //             });

  //           } else {

  //             this.models = pbi.models;
  //             this.permissions = this.models.Permissions.All;
  //             this.viewMode = this.models.ViewMode.View;

  //             this.config = {
  //               type: rst.type,
  //               tokenType: this.models.TokenType.Embed,
  //               accessToken: rst.accessToken,
  //               embedUrl: rst.embedUrl,
  //               id: rst.reportId,
  //               permissions: this.permissions,
  //               viewMode: this.viewMode,
  //               settings: {
  //                 filterPaneEnabled: rst.filterPaneEnabled,
  //                 navContentPaneEnabled: rst.navContentPaneEnabled,
  //                 background: this.models.BackgroundType.Transparent
  //               }
  //             };

  //             let reportContainer = <HTMLElement>document.getElementById("pbi-report");
  //             let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

  //             this.report = <pbi.Report>powerbi.embed(reportContainer, this.config);


  //             if (rst.filtraEmpresa && !rst.filtraPeriodo) {

  //               let basicFilterEmpresa: pbi.models.IBasicFilter = {
  //                 $schema: "http://powerbi.com/product/schema#basic",
  //                 target: {
  //                   table: rst.tabelaFiltroEmpresa,
  //                   column: rst.colunaFiltroEmpresa
  //                 },
  //                 operator: "In",
  //                 values: [this.dblinkedSessionService.empresa.id],
  //                 filterType: 1 // pbi.models.FilterType.BasicFilter,
  //               }

  //               this.report.on("loaded", () => {


  //                 this.report.setFilters([basicFilterEmpresa])
  //                   .catch(errors => {

  //                   });
  //               });

  //             } else if (!rst.filtraEmpresa && rst.filtraPeriodo) {

  //               let basicFilterPeriodo: pbi.models.IBasicFilter = {
  //                 $schema: "http://powerbi.com/product/schema#basic",
  //                 target: {
  //                   table: rst.tabelaFiltroPeriodo,
  //                   column: rst.colunaFiltroPeriodo
  //                 },
  //                 operator: "In",
  //                 values: [rst.colunaFiltroPeriodo === 'MesAno' || rst.colunaFiltroPeriodo === 'mes_ano' ? this.mesFiltro + "-" + this.anoCompletoFiltro : this.dblinkedSessionService.periodo.year.value],
  //                 filterType: 1 // pbi.models.FilterType.BasicFilter,
  //               }

  //               this.report.on("loaded", () => {


  //                 this.report.setFilters([basicFilterPeriodo])
  //                   .catch(errors => {

  //                   });
  //               });

  //             } else if (rst.filtraEmpresa && rst.filtraPeriodo) {


  //               let basicFilterEmpresa: pbi.models.IBasicFilter = {
  //                 $schema: "http://powerbi.com/product/schema#basic",
  //                 target: {
  //                   table: rst.tabelaFiltroEmpresa,
  //                   column: rst.colunaFiltroEmpresa
  //                 },
  //                 operator: "In",
  //                 values: [this.dblinkedSessionService.empresa.id],
  //                 filterType: 1 // pbi.models.FilterType.BasicFilter,
  //               }


  //               let basicFilterPeriodo: pbi.models.IBasicFilter = {
  //                 $schema: "http://powerbi.com/product/schema#basic",
  //                 target: {
  //                   table: rst.tabelaFiltroPeriodo,
  //                   column: rst.colunaFiltroPeriodo
  //                 },
  //                 operator: "In",
  //                 values: [rst.colunaFiltroPeriodo === 'MesAno' || rst.colunaFiltroPeriodo === 'mes_ano' ? this.mesFiltro + "-" + this.anoCompletoFiltro : this.dblinkedSessionService.periodo.year.value],
  //                 filterType: 1 // pbi.models.FilterType.BasicFilter,
  //               }

  //               this.report.on("loaded", () => {


  //                 this.report.setFilters([basicFilterEmpresa, basicFilterPeriodo])
  //                   .catch(errors => {

  //                   });
  //               });

  //             }
  //           }
  //         },
  //           error => this.httpService.handleError(error, () => this.gerarRelatorio()),
  //           () => this.httpService.done());
  //     }
  //   }

  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 2 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  openDetalhes(conta: string, key: number, tipo: string) {
    if (tipo == 'DIAS') {
      this.diaMinDetalhe = this.lidays[key]
      this.diaMaxDetalhe = this.lidays[key]


    } else if (tipo == 'SEMANAS') {
      let semana: string = this.liweeks[key]
      let diamin: Date = this.getDateOfISOWeek(semana.split("-")[0], semana.split("-")[1])
      let diamax: Date = this.getDateOfISOWeek(semana.split("-")[0], semana.split("-")[1])
      diamax.setDate(diamax.getDate() + 6)

      diamin < this.dtInicial1 ? diamin = this.dtInicial1 : diamin
      diamax > this.dtFinal1 ? diamax = this.dtFinal1 : diamax

      this.diaMinDetalhe = diamin.getDate() + "/" + (diamin.getMonth() + 1) + "/" + diamin.getFullYear()
      this.diaMaxDetalhe = diamax.getDate() + "/" + (diamax.getMonth() + 1) + "/" + diamax.getFullYear()

    } else if (tipo == 'MESES') {
      let mes: string = this.limonths[key]
      let anoStr: string = mes.split(" - ")[1]
      let mesStr: string = mes.split(" - ")[0]
      let dataMax: Date = new Date(Number(anoStr), this.monthAbrToNumber(mesStr), 0)

      this.diaMinDetalhe = "1/" + this.monthAbrToNumber(mesStr) + "/" + anoStr
      this.diaMaxDetalhe = dataMax.getDate() + "/" + (dataMax.getMonth() + 1) + "/" + dataMax.getFullYear()
    }

    this.contaDetalhe = Number(conta.split(" - ")[0])
    this.onFlowCashSelect()
  }

  onFlowCashSelect(event: LazyLoadEvent = {}) {

    if (!this.diaMinDetalhe) return

    //Necessário para gerar o relatório
    this.offsetDet = event.first
    this.limitDet = event.rows
    this.sortOrderDet = event.sortOrder
    this.sortFieldDet = event.sortField
    this.filtersDet = event.filters

    const infos = {
      dtmin: this.diaMinDetalhe,
      dtmax: this.diaMaxDetalhe,
      conta: this.contaDetalhe,
      baixados: this.baixados,
      offset: this.offsetDet,
      limit: this.limitDet,
      sort_order: this.sortOrderDet,
      sort_field: this.sortFieldDet,
      filters: this.filtersDet
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/saldoemcontadetalhe', infos)
      .subscribe(
        res => {
          this.httpService.done()
          if (res.error) {
            this.tools.exibirMensagem(res)
            return
          }

          this.movTable = new Array()
          res.data.data.forEach(row => this.movTable.push(row))
          this.totalDetalhe = res.data.total
          this.modalDetalheMov = true
        },
        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao carregar o detalhe, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
          this.httpService.done()
        })
  }

  async getListEmpresas() {
    this.httpService.wait()

    try {
      await this.httpService.get(this.configService.defaultUrlApiUpload + 'cadastro/getgroupempresas/' + this.dblinkedSessionService.empresa.id + "-" + this.sessionService.loggedUser.id).toPromise()
        .then(res => {
          this.httpService.done()
          if (res.error) {
            this.tools.exibirMensagem(res)
            return
          }
          this.listEmpresas = []
          res.data.forEach(row => this.listEmpresas.push({ value: row.id, label: row.fantasia }))
          if (!this.listEmpresas.length) this.listEmpresas.push({ value: this.dblinkedSessionService.empresa.id, label: this.dblinkedSessionService.empresa.nomeFantasia })

          this.empresasSelecionadas = new Array()
          this.empresasSelecionadas.push(this.dblinkedSessionService.empresa.id)

        })

    } catch (err) {
      this.tools.exibirMensagem({ mensagem: "Erro no API ao carregar o grupo de empresa, contate o suporte: " + err.message, severity: "error" })
      console.log(err)
      this.httpService.done()
    }
  }

  //   private loadBi() {

  //     this.httpService.wait();
  //     this.httpService.get('custom/pbi/buscar-bi-usuario',
  //       [this.sessionService.loggedUser.id, this.dblinkedSessionService.empresa.id])
  //       .subscribe(retorno => {
  //         retorno.forEach(element => {
  //           if (element.selected) {
  //             this.relatorioEscolhido = element.id;
  //           }
  //         });

  //       }, error => this.httpService.handleError(error, () => this.loadBi()),
  //         () => this.httpService.done());

  //   }


  monthAbrToNumber(mes: string): number {
    if (mes === 'Jan') return 1
    if (mes === 'Fev') return 2
    if (mes === 'Mar') return 3
    if (mes === 'Abr') return 4
    if (mes === 'Mai') return 5
    if (mes === 'Jun') return 6
    if (mes === 'Jul') return 7
    if (mes === 'Ago') return 8
    if (mes === 'Set') return 9
    if (mes === 'Out') return 10
    if (mes === 'Nov') return 11
    if (mes === 'Dez') return 12
  }

  onFilter(e) {
    if (Object.keys(e.filters).length != 0) {
      this.isFiltered = true;
      this.filterTableValues = e.filteredValue;
    } else this.isFiltered = false;
  }

  calcFooterTotal() {
    let total: number = 0;
    const data = this.isFiltered ? this.filterTableValues : this.liglobal
    data.forEach(row => total += row.valor)
    return total
  }

  exportarDetalhe() {
    if (!this.diaMinDetalhe) return
    if (this.empresasSelecionadas.length === 0) return

    const infos = {
      empresa_id: this.empresasSelecionadas,
      dtmin: this.diaMinDetalhe,
      dtmax: this.diaMaxDetalhe,
      conta: this.contaDetalhe,
      baixados: this.baixados,
      offset: this.offsetDet,
      limit: this.limitDet,
      sort_order: this.sortOrderDet,
      sort_field: this.sortFieldDet,
      filters: this.filtersDet,
      user_id: this.sessionService.loggedUser.id
    }

    this.tools.exibirMensagem({ mensagem: "Geração do relatório em andamento, pode levar alguns minutos...", severity: "info" })
    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/relatorio-detalhe-movimentacao', infos)
      .subscribe(
        res => {
          this.tools.exibirMensagem(res)
          this.httpService.done()
          if (res["error"]) return

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          }

          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/relatorio-detalhe-movimentacao', httpOptions2)
            .subscribe(
              (res: any) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                link.href = url
                link.download = "Relatóro detalhe movimentações.xlsx"
                link.click()
                window.URL.revokeObjectURL(url)
              }, err => {
                this.tools.exibirMensagem({ mensagem: "Erro no API ao baixar o relatório, contate o suporte: " + err.message, severity: "error" })
                console.log(err)
              })

        },
        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao gerar o relatório de detalhe de movimentações, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
          this.httpService.done()
        });
  }

  atualizarView() {
    if (!confirm("Deseja atualizar os dados? Essa operação pode demorar alguns minutos.")) return

    const infos = {
      view_name: "financeiro.vw_mov_fin_mat"
    }

    this.httpService.wait()
    this.tools.exibirMensagem({ mensagem: "Atualização, por favor aguarde...", severity: "info" })
    this.httpService.post(this.configService.defaultUrlApiUpload + 'log/refresh-vw-mat', infos)
      .subscribe(
        res => {
          this.httpService.done()
          this.tools.exibirMensagem(res)
          if (res.error) return

          this.getLastAtuView()
          this.getFluxoDay()
          this.getMovimentacoes()
        },
        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao atualizar os dados, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
          this.httpService.done()
        })
  }

  getLastAtuView() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        viewname: "vw_mov_fin_mat"
      })
    };

    this.httpClient.get(this.configService.defaultUrlApiUpload + 'log/refresh-vw-mat', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.tools.exibirMensagem(res)
            return
          }

          this.ultimaAtu = res["data"].data
        },
        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao obter a última atualização dos dados, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
        })
  }

  exportMovimentacao() {
    if (this.empresasSelecionadas.length === 0) return

    let dtinit: String = this.dtInicial2.getFullYear() + "-" + (this.dtInicial2.getMonth() + 1) + "-" + this.dtInicial2.getDate()
    let dtfin: String = this.dtFinal2.getFullYear() + "-" + (this.dtFinal2.getMonth() + 1) + "-" + this.dtFinal2.getDate()

    const infos = {
      empresa_id: this.empresasSelecionadas,
      dt_min: dtinit,
      dt_max: dtfin,
      baixados: this.baixados,
      idMascara: this.idMascara,
      offset: this.offsetMov,
      limit: this.limitMov,
      sort_order: this.sortOrderMov,
      sort_field: this.sortFieldMov,
      filters: this.filtersMov,
      user_id: this.sessionService.loggedUser.id
    }

    this.tools.exibirMensagem({ mensagem: "Geração do relatório em andamento, pode levar alguns minutos...", severity: "info" })
    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/relatorio-movimentacao', infos)
      .subscribe(
        res => {
          this.tools.exibirMensagem(res)
          this.httpService.done()
          if (res["error"]) return

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          }

          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/relatorio-movimentacao', httpOptions2)
            .subscribe(
              (res: any) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                link.href = url
                link.download = "Relatóro movimentações.xlsx"
                link.click()
                window.URL.revokeObjectURL(url)
              }, err => {
                this.tools.exibirMensagem({ mensagem: "Erro no API ao baixar o relatório, contate o suporte: " + err.message, severity: "error" })
                console.log(err)
              })

        },
        err => {
          this.tools.exibirMensagem({ mensagem: "Erro no API ao gerar o relatório de movimentações, contate o suporte: " + err.message, severity: "error" })
          console.log(err)
          this.httpService.done()
        });
  }
}

