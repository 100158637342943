import { isNullOrUndefined } from "util";

export class TipoIngresso{

  id          :   number;
  descricao   :   string;


  constructor(tipoingresso?: TipoIngresso | number){
      if (!isNullOrUndefined(tipoingresso)) {
          if (typeof tipoingresso === 'number') {
              this.id   = tipoingresso;
          } else {
              this.id           = tipoingresso.id;
              this.descricao    = tipoingresso.descricao;
          }
      }
  }


}