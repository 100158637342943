import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from '../../../shared/model/toolbar-button.model';
import { FluxoCaixa } from '../../../financeiro/model/fluxo_caixa';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { GrupoFluxoCaixa } from '../../../financeiro/model/fluxo_caixa_grupo';
import { TipoGrupoFluxo } from 'app/util/model/tipo-fluxo-caixa-model';
import { AliasGrupoFluxoCaixa } from '../../../financeiro/model/fluxo_caixa_grupo_alias';
import { FormulaDetalhe } from '../../../financeiro/model/fluxo_caixa_formula_detal';

@Component({
  selector: 'app-configuracao-dashboard',
  templateUrl: './configuracao-dashboard.component.html',
  styleUrls: [
    './configuracao-dashboard.component.scss'
  ],
  providers: [CrudService]
})
export class ConfigBudgetDashboardComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;
  title: string;
  subtitle: string;
  pt_BR: any;
  msgs: Message[] = [];
  msgsModal: Message[] = [];

  //export excel
  colslistaCtrl: any[];

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) {}

  // tabela dados fluxos
  listaCtrl: Array<any> = new Array();
  // select check box
  // tabela dados fluxos
  listaOperacaoRegras: Array<any> = new Array();
  listaOperacaoRegrasSelecionadas: Array<any> = new Array();
  // select check box
  filterOrigem: Array<any> = new Array();
  filterDestino: Array<any> = new Array();
  selectAllOrigem: boolean;
  selectAllDest: boolean;
  idSelecionado: Array<any> = new Array();
  operacaoSelecionada: Array<any> = new Array();
  operacaoAdicionada: Array<any> = new Array();

  // fluxo de caixa
  fluxoCaixa = new FluxoCaixa();
  grupoFluxoCaixa = new GrupoFluxoCaixa();
  aliasGrupo = new AliasGrupoFluxoCaixa();
  modalFluxoCaixa: boolean;
  modalFluxoCaixaGrupos: boolean;
  modalAliasGrupo: boolean;
  // select
  opcoesEmpresaConta: SelectItem[];
  opcoesFluxoCaixa: SelectItem[];
  tiposFluxoCaixa: SelectItem[];
  opcoesGrupoFluxoCaixa: SelectItem[];
  opcoesAliasFluxoCaixa: SelectItem[];

  // vinculação
  vinculaGrupoOperacao: boolean;

  // formulas
  formulaDetalList: Array<any> = new Array();
  formulaDetalhe = new FormulaDetalhe();
  modalFormula: boolean;
  modalFormulaDetal: boolean;
  operadorCtrl: boolean; // verificando se tem operado na ultima posição;

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'CONTROLE DO FLUXO DE CAIXA';
    this.titleService.title = 'Fluxo de Caixa Control';
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.vinculaGrupoOperacao = false;
        this.start();
      }
    );

    this.colslistaCtrl = [
      { field: 'nomeFantasia', header: 'EMPRESA' },
      { field: 'nomeFluxo', header: 'NOME FLUXO' },
      { field: 'createTimestamp', header: 'CRIADO EM' },
      { field: 'userNome', header: 'USUÁRIO' },
      { field: 'changeTimestamp', header: 'ALTERADO EM' }
    ];
  }
  ngOnDestroy() {
    // this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  start() {
    this.subtitle = 'Criar, alterar e excluir as configurações do relatório';
    Promise.resolve(null).then(() => this.toolbarSetButtons());

    this.listaCtrl = new Array();
    this.listaOperacaoRegras = new Array();
    this.listaOperacaoRegrasSelecionadas = new Array();

    this.selectAllOrigem  = false;
    this.selectAllDest = false;
    this.filterOrigem = new Array();
    this.filterDestino = new Array();
    this.idSelecionado = new Array();
    this.operacaoSelecionada = new Array();
    this.operacaoAdicionada = new Array();

    this.modalFluxoCaixa = false;
    this.modalFluxoCaixaGrupos = false;
    this.modalAliasGrupo = false;
    this.vinculaGrupoOperacao = false;

    // fluxo de caixa
    this.fluxoCaixa = new FluxoCaixa();
    this.fluxoCaixa.empresa = new Empresa();
    this.fluxoCaixa.changeUser = new AuthUser();
    // grupo fluxo
    this.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.grupoFluxoCaixa.fluxoCaixa = new FluxoCaixa();
    this.grupoFluxoCaixa.tipoGrupoFluxo = new TipoGrupoFluxo();
    this.fluxoCaixa.grupoFluxoCaixa = new Array();
    this.fluxoCaixa.empresaContas = new Array();

    // formulas
    this.modalFormula = false;
    this.modalFormulaDetal = false;
    this.formulaDetalhe = new FormulaDetalhe();
    this.formulaDetalList = new Array();
    this.operadorCtrl = false;

    if (
      this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected &&
      this.dblinkedSessionService.periodo.month) {

      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        authUserId: this.sessionService.loggedUser.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/fluxocaixa/fluxo_caixa-ctrl-list', infos)
      .subscribe(retorno => {
        this.listaCtrl = (retorno);
        this.comboOptFluxoCaixa();
        this.comboEmpresaConta();
        this.comboTiposFluxoCaixa();
      },
      error => this.httpService.handleError(error, () => this.start()),
      () => this.httpService.done());

    }
  }

  selectCheckbox(id: any, event: any) {
    if (event.selected) {
      const index = this.idSelecionado.findIndex(c => c === id);
      this.idSelecionado.splice(index, 1);
      event.selected = true;
    } else {
      this.idSelecionado.push(id);
      this.fluxoCaixa.id = event.id;
      this.fluxoCaixa.nomeFluxo = event.nomeFluxo;
      this.fluxoCaixa.empresa = new Empresa();
      this.fluxoCaixa.empresa.id = event.empresaId;
      this.fluxoCaixa.changeUser = new AuthUser();
      this.fluxoCaixa.changeUser.id = event.changeUserId;
      event.selected = false;
    }

    if (this.idSelecionado.length > 0 && this.idSelecionado.length < 2) {
      this.toolbarEditButtons(event);
    } else {
      // this.fluxoCaixa = new FluxoCaixa();
      this.toolbarSetButtons();
    }
  }

  // Criando e alterando fluxo de caixa
  openModalFluxo(ctrl: boolean) {
    this.fluxoCaixa.empresaContas = new Array();

    // novo fluxo
    if (ctrl) {
      this.fluxoCaixa = new FluxoCaixa();
      this.fluxoCaixa.empresa = new Empresa();
      this.fluxoCaixa.changeUser = new AuthUser();
      this.modalFluxoCaixa = true;

      // editando fluxo
    } else {
      this.modalFluxoCaixa = true;
      this.httpService.get('/contafluxocaixa?$select=id,empresaConta/id,fluxoCaixa/id&$filter=fluxoCaixa/id eq ' + this.fluxoCaixa.id)
    .subscribe(ref => {
      ref.value.forEach(element => {
        this.fluxoCaixa.empresaContas.push(element.empresaConta.id);
      });
    }, error => this.httpService.handleError(error, () => this.openModalFluxo(ctrl)),
    () => this.httpService.done());
    }
  }

  comboEmpresaConta() {
    this.opcoesEmpresaConta = [];
    this.opcoesEmpresaConta.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/empresaconta?$select=id,nome&$filter=empresa/id eq ' +
                            this.dblinkedSessionService.empresa.id + ' and id ne 7&$orderby=nome')
    .subscribe(ref => {
      ref.value.forEach(element => {
        this.opcoesEmpresaConta.push({ label: element.nome, value: element.id });
      });
    }, error => this.httpService.handleError(error, () => this.comboEmpresaConta()),
    () => this.httpService.done());
  }

  // Salvando fluxo de caixa
  fluxoCaixaSalvar(ctrl: boolean) {
    // console.log('Salvar fluxo Caixa >>>>> ' +  JSON.stringify( this.fluxoCaixa, null, ''));
    if (this.fluxoCaixa.nomeFluxo === null || this.fluxoCaixa.nomeFluxo === undefined || this.fluxoCaixa.nomeFluxo === '') {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Informe o Nome do Fluxo para prosseguir!'
      });
      return;
    }

    // novo fluxo
    if (!ctrl) {
      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        authUserId: this.sessionService.loggedUser.id,
        nomeFluxo: this.fluxoCaixa.nomeFluxo,
        contas: this.fluxoCaixa.empresaContas,
        alterar: false
      };
      this.httpService.wait();
      this.httpService.post('/custom/fluxocaixa/fluxo_caixa-salvar', infos)
        .subscribe(rst => {
          this.fluxoCaixa.id = rst.id;
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'MSG: ', detail: 'Fluxo salvo com sucesso!' });
          this.start();
        }, error => this.httpService.handleError(error, () => this.fluxoCaixaSalvar(ctrl)),
        () => this.httpService.done()
      );
    } else { // alterando fluxo
      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        authUserId: this.sessionService.loggedUser.id,
        nomeFluxo: this.fluxoCaixa.nomeFluxo,
        fluxoId: this.fluxoCaixa.id,
        contas: this.fluxoCaixa.empresaContas,
        alterar: true
      };
      this.httpService.wait();
      this.httpService.post('/custom/fluxocaixa/fluxo_caixa-salvar', infos)
        .subscribe(rst => {
          this.fluxoCaixa.id = rst.id;
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'MSG: ', detail: 'Fluxo alterado com sucesso!' });
          this.start();
          // const index = this.listaCtrl.findIndex(i => i.id === this.fluxoCaixa.id);
          // this.listaCtrl.splice(index, 1, this.fluxoCaixa);
        }, error => this.httpService.handleError(error, () => this.fluxoCaixaSalvar(ctrl)),
        () => this.httpService.done()
      );
    }
    this.modalFluxoCaixa = false;
  }

  removerFluxo() {
    if (confirm('Deseja remover este Fluxo de Caixa?')) {
      this.fluxoCaixa.excluirFluxo = true;
      this.httpService.wait();
      this.httpService.put('/fluxocaixa', this.fluxoCaixa.id, this.fluxoCaixa)
        .subscribe(() => {
          /*
          const index = this.listaCtrl.findIndex(l => l.id ===  this.fluxoCaixa.id);
          this.listaCtrl.splice(index, 1);*/
          this.start();
        }, error => this.httpService.handleError(error, () => this.removerFluxo()),
          () => this.httpService.done());
    }
  }

  onRowSelect(event: any) {

    this.modalFluxoCaixaGrupos = true;
    this.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.grupoFluxoCaixa.fluxoCaixa = new FluxoCaixa();
    this.grupoFluxoCaixa.fluxoCaixa.id = event.id;
    this.grupoFluxoCaixa.tipoGrupoFluxo = new TipoGrupoFluxo();
    this.fluxoCaixa.grupoFluxoCaixa = new Array();
    this.httpService.wait();
    this.httpService.get('/grupofluxocaixa?$select=id,codigoGrupo,descricao,fluxoCaixa/id,fluxoCaixa/nomeFluxo,tipoGrupoFluxo/id,' +
                          'tipoGrupoFluxo/descricao&$filter=fluxoCaixa/id eq ' + event.id + '&$orderby=codigoGrupo')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.fluxoCaixa.grupoFluxoCaixa.push(element);
        });
      }, error => this.httpService.handleError(error, () => this.onRowSelect(event)),
      () => this.httpService.done());

  }

  comboTiposFluxoCaixa() {
    this.tiposFluxoCaixa = [];
    this.tiposFluxoCaixa.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/tipogrupofluxo?$select=id,descricao&$orderby=descricao')
    .subscribe(ref => {
      ref.value.forEach(element => {
        this.tiposFluxoCaixa.push({ label: element.descricao, value: element.id });
      });
    },
    error => this.httpService.handleError(error, () => this.comboTiposFluxoCaixa()),
    () => this.httpService.done());
  }

  comboOptFluxoCaixa() {
    this.opcoesFluxoCaixa = [];
    this.opcoesFluxoCaixa.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/fluxocaixa?$select=id,nomeFluxo,empresa/id&$filter=empresa/id eq ' +
                            this.dblinkedSessionService.empresa.id + ' and excluirFluxo ne true&$orderby=nomeFluxo')
    .subscribe(ref => {
      ref.value.forEach(element => {
        this.opcoesFluxoCaixa.push({ label: element.nomeFluxo, value: element.id });
      });
    },
    error => this.httpService.handleError(error, () => this.comboOptFluxoCaixa()),
    () => this.httpService.done());
  }

  grupoFluxoCaixaAdd() {
    if (this.grupoFluxoCaixa.codigoGrupo === null || this.grupoFluxoCaixa.codigoGrupo === undefined ||
        this.grupoFluxoCaixa.codigoGrupo === ''  || this.grupoFluxoCaixa.descricao === undefined ||
        this.grupoFluxoCaixa.descricao === '' || this.grupoFluxoCaixa.tipoGrupoFluxo.id === undefined ||
        this.grupoFluxoCaixa.tipoGrupoFluxo.id === null) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Informe o Grupo, o Codigo do Grupo e a Descrição para prosseguir!'
      });
      return;
    }
    const fluxoId = this.grupoFluxoCaixa.fluxoCaixa.id;
    const tipoId = this.grupoFluxoCaixa.tipoGrupoFluxo.id;

    // salvar
    if (this.grupoFluxoCaixa.id === undefined) {
      this.httpService.wait();
      this.httpService.postdb('/grupofluxocaixa', this.grupoFluxoCaixa)
      .subscribe(rst => {
        this.grupoFluxoCaixa.id = rst.id;
           // buscando nomes nos options
          const descricao = this.tiposFluxoCaixa.find(i => i.value === tipoId).label;
          this.grupoFluxoCaixa.tipoGrupoFluxo.descricao = descricao;
          // setando no array
          this.fluxoCaixa.grupoFluxoCaixa.push(this.grupoFluxoCaixa);
          // zerando variaveis
          this.grupoFluxoCaixa = new GrupoFluxoCaixa();
          this.grupoFluxoCaixa.fluxoCaixa = new FluxoCaixa();
          this.grupoFluxoCaixa.fluxoCaixa.id = fluxoId;
          this.grupoFluxoCaixa.tipoGrupoFluxo = new TipoGrupoFluxo();
          this.grupoFluxoCaixa.tipoGrupoFluxo.id = null;

        }, error => this.httpService.handleError(error, () => this.grupoFluxoCaixaAdd()),
        () => this.httpService.done()
      );

    } else { // alterar

      this.httpService.wait();
      this.httpService.put('/grupofluxocaixa', this.grupoFluxoCaixa.id, this.grupoFluxoCaixa)
      .subscribe(() => {

          const index = this.fluxoCaixa.grupoFluxoCaixa.findIndex(i => i.id === this.grupoFluxoCaixa.id);
          // buscando nomes nos options
          const descricao = this.tiposFluxoCaixa.find(i => i.value === this.grupoFluxoCaixa.tipoGrupoFluxo.id).label;
          this.grupoFluxoCaixa.tipoGrupoFluxo.descricao = descricao;
          // setando no array
          this.fluxoCaixa.grupoFluxoCaixa.splice(index, 1, this.grupoFluxoCaixa);

          // zerando variaveis
          this.grupoFluxoCaixa = new GrupoFluxoCaixa();
          this.grupoFluxoCaixa.fluxoCaixa = new FluxoCaixa();
          this.grupoFluxoCaixa.fluxoCaixa.id = fluxoId;
          this.grupoFluxoCaixa.tipoGrupoFluxo = new TipoGrupoFluxo();
          this.grupoFluxoCaixa.tipoGrupoFluxo.id = null;

        },
        error =>
          this.httpService.handleError(error, () => this.grupoFluxoCaixaAdd()),
        () => this.httpService.done()
      );
    }

  }

  grupofluxoCaixaSalvar() {
    this.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.modalFluxoCaixaGrupos = false;
  }

  editarGrupoFluxoCaixa(event: any) {
    this.grupoFluxoCaixa = new GrupoFluxoCaixa(event);
  }

  removerGrupoFluxoCaixa(event: any) {
    if (confirm('Deseja excluir esta linha?')) {
      this.httpService.wait();
      this.httpService.delete('/grupofluxocaixa', event.id.toString())
        .subscribe(() => {
          const index = this.fluxoCaixa.grupoFluxoCaixa.findIndex(c => c.id ===  event.id);
          this.fluxoCaixa.grupoFluxoCaixa.splice(index, 1);
        },error => this.httpService.handleError(error, () => this.removerGrupoFluxoCaixa(event)),
          () => this.httpService.done());
    }
  }

  // Modal Alias linhas Fluxo de caixa
  criarAliasGrupo(event: any) {
    this.grupoFluxoCaixa = new GrupoFluxoCaixa(event);
    // this.fluxoCaixa = new FluxoCaixa(event.fluxoCaixa);
    this.grupoFluxoCaixa.aliasGrupoList = new Array();
    this.aliasGrupo = new AliasGrupoFluxoCaixa();
    this.aliasGrupo.grupoFluxoCaixa = new GrupoFluxoCaixa(this.grupoFluxoCaixa);
    // zerando a lista de alias
    this.opcoesAliasFluxoCaixa = [];
    // abrindo o modal
    this.modalAliasGrupo = true;

    this.httpService.wait();
    this.httpService.get('/aliasgrupofluxocaixa?$select=id,codigoAlias,posicaoAlias,nomeAlias,grupoFluxoCaixa/id,' +
                          'grupoFluxoCaixa/descricao&$filter=grupoFluxoCaixa/id eq ' + this.grupoFluxoCaixa.id + '&$orderby=codigoAlias')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.grupoFluxoCaixa.aliasGrupoList.push(element);
        });
        // ordenando o array
        // this.grupoFluxoCaixa.aliasGrupoList.sort((a, b) => a.codigoAlias.localeCompare(b.codigoAlias));
      }, error => this.httpService.handleError(error, () => this.onRowSelect(event)),
      () => this.httpService.done());
  }

  // adicioanando o apelido para linha do grupo
  aliasGruposFluxoCaixaAdd() {

    if (!this.aliasGrupo.codigoAlias || !this.aliasGrupo.nomeAlias) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Informe o Codigo do Grupo e a nome para prosseguir!'
      });
      return;
    }

     // salvar
     if (this.aliasGrupo.id === undefined) {

      this.httpService.wait();
      this.httpService.post('/aliasgrupofluxocaixa', this.aliasGrupo)
      .subscribe(rst => {
        this.aliasGrupo.id = rst.id;
          // setando o apelido no array
          this.grupoFluxoCaixa.aliasGrupoList.push(this.aliasGrupo);
          // ordenando o array
          // this.grupoFluxoCaixa.aliasGrupoList.sort((a, b) => a.codigoAlias.localeCompare(b.codigoAlias));
          // zerando variaveis
          this.aliasGrupo = new AliasGrupoFluxoCaixa();
          this.aliasGrupo.grupoFluxoCaixa = new GrupoFluxoCaixa(this.grupoFluxoCaixa);
        }, error => this.httpService.handleError(error, () => this.aliasGruposFluxoCaixaAdd()),
        () => this.httpService.done());

    } else { // alterar

        this.httpService.wait();
        this.httpService.put('/aliasgrupofluxocaixa', this.aliasGrupo.id, this.aliasGrupo)
        .subscribe(() => {

          const index = this.grupoFluxoCaixa.aliasGrupoList.findIndex(i => i.id === this.aliasGrupo.id);
          // buscando nomes nos options
          /*const nomeFluxo = this.opcoesFluxoCaixa.find(i => i.value === this.aliasGrupo.id).label;
          this.grupoFluxoCaixa.fluxoCaixa.nomeFluxo = nomeFluxo;*/
          // setando no array
          this.grupoFluxoCaixa.aliasGrupoList.splice(index, 1, this.aliasGrupo);
          // ordenando o array
          // this.grupoFluxoCaixa.aliasGrupoList.sort((a, b) => a.codigoAlias.localeCompare(b.codigoAlias));
          // zerando variaveis
          this.aliasGrupo = new AliasGrupoFluxoCaixa();
          this.aliasGrupo.grupoFluxoCaixa = new GrupoFluxoCaixa(this.grupoFluxoCaixa);
        },
        error =>
          this.httpService.handleError(error, () => this.aliasGruposFluxoCaixaAdd()),
        () => this.httpService.done()
      );

    }
  }
  // salvar nome do alias
  salvarAliasGrupo() {
    this.modalAliasGrupo = false;
    this.aliasGrupo = new AliasGrupoFluxoCaixa();
  }

  editarAliasGrupo(event: any) {
    this.aliasGrupo = new AliasGrupoFluxoCaixa(event);
  }

  removerAliasGrupo(event: any) {
    if (confirm('Deseja excluir esta linha?')) {
      this.httpService.wait();
      this.httpService.delete('/aliasgrupofluxocaixa', event.id.toString())
        .subscribe(() => {
          const index = this.grupoFluxoCaixa.aliasGrupoList.findIndex(c => c.id ===  event.id);
          this.grupoFluxoCaixa.aliasGrupoList.splice(index, 1);
        }, error => this.httpService.handleError(error, () => this.removerAliasGrupo(event)),
          () => this.httpService.done());
    }
  }

  // cancelando todas as ediçoes
  cancelarEdicaoGrupo() {
    // variaveis d controle
    const fluxoId = this.grupoFluxoCaixa.fluxoCaixa.id;
    const lst = this.grupoFluxoCaixa.aliasGrupoList;
    // zerando variaveis
    this.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.grupoFluxoCaixa.fluxoCaixa = new FluxoCaixa();
    this.grupoFluxoCaixa.fluxoCaixa.id = fluxoId;
    this.grupoFluxoCaixa.tipoGrupoFluxo = new TipoGrupoFluxo();
    this.grupoFluxoCaixa.tipoGrupoFluxo.id = null;
    this.grupoFluxoCaixa.aliasGrupoList = lst;
  }

  cancelarEdicaoAlias() {
    this.aliasGrupo = new AliasGrupoFluxoCaixa();
    this.aliasGrupo.grupoFluxoCaixa = this.grupoFluxoCaixa;
  }
  // abrir modal pré formulario de composição
  abrirFormularioVinculos() {
    this.opcoesGrupoFluxoCaixa = [];
    this.opcoesAliasFluxoCaixa = [];
    this.vinculaGrupoOperacao = true;
    this.comboGruposFluxo();
    this.subtitle = 'RELACIONAMENTO: ' + this.fluxoCaixa.nomeFluxo;
    this.toolbarService.alterarStatus('atualizar', true, 'Atualizar relatório', () => this.start() );
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.start() );
  }


  comboAliasFluxoCaixa() {
    this.aliasGrupo = new AliasGrupoFluxoCaixa();
    this.listaOperacaoRegras = new Array();
    this.listaOperacaoRegrasSelecionadas = new Array();
    this.opcoesAliasFluxoCaixa = [];
    this.opcoesAliasFluxoCaixa.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/aliasgrupofluxocaixa?$select=id,codigoAlias,posicaoAlias,nomeAlias' +
                        '&$filter=grupoFluxoCaixa/id eq ' + this.grupoFluxoCaixa.id + '&$orderby=codigoAlias')
    .subscribe(ref => {
      ref.value.sort((a, b) => a.codigoAlias.localeCompare(b.codigoAlias));
      ref.value.forEach(element => {
        this.opcoesAliasFluxoCaixa.push({ label: element.codigoAlias + ' - ' + element.nomeAlias, value: element.id });
      });
    },
    error => this.httpService.handleError(error, () => this.comboAliasFluxoCaixa()),
    () => this.httpService.done());
  }

  // abrir formulario de composição
  vincularGrupoOperacao(event: any, origem: boolean) {
    // se as opções não existirem
    this.fluxoCaixa.id = this.grupoFluxoCaixa.fluxoCaixa.id;
    if ((this.opcoesAliasFluxoCaixa === undefined || origem)) {
      this.comboAliasFluxoCaixa();
    }
    if ((this.opcoesGrupoFluxoCaixa === undefined || origem)) {
      this.comboGruposFluxo();
    }
    // se não tiver um Alias definido
    setTimeout(() => {
      if (this.aliasGrupo.id === undefined) {
        this.aliasGrupo = new AliasGrupoFluxoCaixa(event);
      }
      if (this.aliasGrupo.id === undefined) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Selecione uma linha para prosseguir!'
        });
        return;
      }
      this.continua();
    } , 500);
  }

  continua() {
    this.modalFluxoCaixaGrupos = false;
    this.modalAliasGrupo = false;
    this.vinculaGrupoOperacao = true;
    // alterando o status dos botoes
    this.toolbarService.alterarStatus('atualizar', (this.vinculaGrupoOperacao), 'Atualizar relatório', () => this.start() );
    this.toolbarService.alterarStatus('voltar', !(this.vinculaGrupoOperacao), 'Voltar', () => this.start() );
    this.toolbarService.alterarStatus('pesquisa', true,  'Add Formula', () => this.start() );
    this.toolbarService.alterarStatus('check', true, 'Relacionar Grupo e Operações', () => this.abrirFormularioVinculos());
    this.toolbarService.alterarStatus('edit', true, 'Editar Fluxo',  () => this.openModalFluxo(false));
    // console.log(`Antes fluxo: ${this.fluxoCaixa.id} -> grupo ${this.grupoFluxoCaixa.id} -> alias ${this.aliasGrupo.id}`);
    const fluxo = this.fluxoCaixa;
    const grupo = this.grupoFluxoCaixa;
    const linha = this.aliasGrupo;
    this.fluxoCaixa = new FluxoCaixa(fluxo);
    this.grupoFluxoCaixa = new GrupoFluxoCaixa(grupo);
    this.aliasGrupo = new AliasGrupoFluxoCaixa(linha);
    // console.log(`Depois fluxo: ${this.fluxoCaixa.id} -> grupo ${this.grupoFluxoCaixa.id} -> alias ${this.aliasGrupo.id}`);
    if (
      this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected &&
      this.dblinkedSessionService.periodo.month && this.aliasGrupo.id) {
      const infos = {
        empresaId: this.dblinkedSessionService.empresa.id,
        authUserId: this.sessionService.loggedUser.id,
      };
      // buscando operações relacionadas
      this.httpService.wait();
      this.httpService.post('/custom/fluxocaixa/fluxo_caixa-list-relac', infos)
      .subscribe(retorno => {
        this.listaOperacaoRegrasSelecionadas = (retorno);

        // buscando todas as operações
        this.httpService.wait();
        this.httpService.post('/custom/fluxocaixa/fluxo_caixa-list-operacoes', infos)
        .subscribe(ret => {
          if (this.listaOperacaoRegrasSelecionadas.length === 0 ) {
            this.listaOperacaoRegras = ret;
          } else {
            const list = new Array();
            ret.forEach(element => {
              const index = this.listaOperacaoRegrasSelecionadas.findIndex(l => l.id === element.id );
              if (index === -1 ) {
                list.push(element);
              }
            });
            this.listaOperacaoRegras = list;
          }
          // listar apenas os relacionamnetos do alias selecionado
          const listCtrl = this.listaOperacaoRegrasSelecionadas;
          this.listaOperacaoRegrasSelecionadas = new Array();
          listCtrl.forEach(element => {
            if (element.aliasId === this.aliasGrupo.id) {
              this.listaOperacaoRegrasSelecionadas.push(element);
              // controle filtro lista adcionadas
              this.filterDestino.push(element);
            }
          });

        },
        error => this.httpService.handleError(error, () => this.continua()),
        () => this.httpService.done());
        // final buscando todas as operações

      // continuação buscando operações relacionadas
      },
      error => this.httpService.handleError(error, () => this.continua()),
      () => this.httpService.done());

    } // if empresa selecionada
  } // final metodo

  // adicionando itens na segunda lista
  selectOperacao(id: any, event: any) {
    // console.log('event>>>>> ' +  JSON.stringify( event, null, ''));
    this.selectAllOrigem = false;
    if (event.selected) {
      const index = this.operacaoSelecionada.findIndex(o => o.id === event.id);
      this.operacaoSelecionada.splice(index, 1);
      event.selected = true;
    } else {
      this.operacaoSelecionada.push(event);
      event.selected = false;
    }
  }

  adicionarOperacaoLista() {

    this.operacaoSelecionada.forEach(element => {
      if (element.selected) {
        element.selected = false;
        // adicionando item da lista de operações selecionadas
        this.listaOperacaoRegrasSelecionadas.push(element);
        // controle filtro lista adcionadas
        this.filterDestino.push(element);
      }
    });
    const listClone = this.listaOperacaoRegras;
    this.listaOperacaoRegras = new Array();
    listClone.forEach(element => {
      const index = this.listaOperacaoRegrasSelecionadas.findIndex(l => l.id === element.id );
      if (index === -1) {
        this.listaOperacaoRegras.push(element);
      }
    });
    // limpando a lista de selecionadas
    this.operacaoSelecionada = new Array();

    if (this.listaOperacaoRegrasSelecionadas.length > 0 ) {
      this.salvarComposicao();
    }
  }

  // remover itens da segunda lista
  selectOperacaoRemove(id: any, event: any) {
    // console.log('event>>>>> ' +  JSON.stringify( event, null, ''));
    this.selectAllDest = false;

    if (event.selected) {
      const index = this.operacaoAdicionada.findIndex(o => o.id === event.id);
      this.operacaoAdicionada.splice(index, 1);
      event.selected = true;
    } else {
      this.operacaoAdicionada.push(event);
      event.selected = false;
    }
  }

  // removendo itens na segunda lista
  removerOperacaoLista() {

    const listClone = this.listaOperacaoRegrasSelecionadas;
    this.listaOperacaoRegrasSelecionadas = new Array();
    // this.listaOperacaoRegras = new Array();
    listClone.forEach(element => {
      if (!element.selected) {
        this.listaOperacaoRegrasSelecionadas.push(element);
      } else {
        element.selected = false;
        this.listaOperacaoRegras.push(element);
      }
    });

    const listCtrl = this.listaOperacaoRegras.sort();
    this.listaOperacaoRegras = new Array();
    listCtrl.forEach(element => {
        this.listaOperacaoRegras.push(element);
    });

    this.operacaoAdicionada = new Array();

    //if (this.listaOperacaoRegrasSelecionadas.length > 0 ) {
      this.salvarComposicao();
    //}
  }


  salvarComposicao() {

   /* console.log(`fluxo: ${this.fluxoCaixa.id} -> grupo ${this.grupoFluxoCaixa.id} -> alias ${this.aliasGrupo.id}`);
    console.log('event>>>>> ' +  JSON.stringify(this.listaOperacaoRegrasSelecionadas, null, ''));*/

    if (this.aliasGrupo.id === undefined || this.aliasGrupo.id === null) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecione uma linha para prosseguir!'
      });
      return;
    }

    const infos = {
      empresaId: this.dblinkedSessionService.empresa.id,
      authUserId: this.sessionService.loggedUser.id,
      aliasId: this.aliasGrupo.id,
      operacoes: this.listaOperacaoRegrasSelecionadas,
      alterar: false
    };
    this.httpService.wait();
    this.httpService.post('/custom/fluxocaixa/fluxo_caixa-vincular-operacaoes', infos)
      .subscribe(rst => {
        if (rst.error === true) {
          this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Erro', detail: rst.message });
          // this.start();
        } else {
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: rst.message });
          // this.start();
        }
      }, error => this.httpService.handleError(error, () => this.salvarComposicao()),
      () => this.httpService.done()
    );

  }
  openModalFormulaDetal(event: any) {
    console.log('event>>>>> ' +  JSON.stringify( event, null, ''));
    this.fluxoCaixa.id = event.fluxoCaixa.id;
    this.fluxoCaixa.nomeFluxo = event.fluxoCaixa.nomeFluxo;
    this.grupoFluxoCaixa = new GrupoFluxoCaixa(event);
    this.comboGruposFluxoFormula();
    this.modalFormulaDetal = true;
    this.operadorCtrl = false;
    this.formulaDetalList = new Array();
    this.formulaDetalhe = new FormulaDetalhe();
    // salvo a posição linha do grupo
    this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
    this.formulaDetalhe.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.formulaDetalhe.fluxoCaixa = new FluxoCaixa(this.fluxoCaixa);
    this.httpService.wait();
    this.httpService.get('/formuladetalhe?$select=id,operador,fluxoCaixa/id,fluxoCaixa/nomeFluxo,' +
                        'grupoFluxoCaixa/id,grupoFluxoCaixa/descricao' +
                        '&$filter=fluxoCaixa/id eq ' + event.fluxoCaixa.id + ' and codigoGrupo eq ' + event.codigoGrupo + '&$orderby=id')
    .subscribe(ref => {
        ref.value.forEach(element => {
          this.formulaDetalList.push(element);
        });
        },
    error => this.httpService.handleError(error, () => this.openModalFormulaDetal(event)),
    () => this.httpService.done());
  }

  // dropdown com todos os grupos para compor as formulas
  comboGruposFluxoFormula() {
    this.opcoesGrupoFluxoCaixa = [];
    this.opcoesFluxoCaixa.push({ label: 'Selecione', value: null });
    this.httpService.wait(); // and tipoGrupoFluxo/id ne 2
    this.httpService.get('/grupofluxocaixa?$select=id,codigoGrupo,descricao&$filter=fluxoCaixa/id eq '
                          + this.fluxoCaixa.id + '&$orderby=codigoGrupo')
    .subscribe(ref => {
      ref.value.forEach(element => {
        this.opcoesGrupoFluxoCaixa.push({ label: element.codigoGrupo + ' - ' + element.descricao, value: element.id });
      });
    },
    error => this.httpService.handleError(error, () => this.comboGruposFluxoFormula()),
    () => this.httpService.done());
  }

  formulaDetalheAdd() {

    if (!this.formulaDetalhe.grupoFluxoCaixa.id) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecione um Grupo para prosseguir!'
      });
      return;
    }
    // validando o tipo de operador.
    if (this.formulaDetalhe.operador) {
      if ((this.formulaDetalhe.operador.indexOf('-') === -1) && (this.formulaDetalhe.operador.indexOf('+')  === -1)) {
        this.operadorCtrl = false;
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Somente operador de + ou - são aceitos!'
        });
        return;
      }
    } else {
      this.operadorCtrl = false;
    }
    // validando se foi informado um operador

    // salvar
    if (this.formulaDetalhe.id === undefined) {

     this.httpService.wait();
     this.httpService.postdb('/formuladetalhe', this.formulaDetalhe)
     .subscribe(rst => {
       this.formulaDetalhe.id = rst.id;
         // setando o apelido no array
         const descricao = this.opcoesGrupoFluxoCaixa.find(i => i.value === this.formulaDetalhe.grupoFluxoCaixa.id).label;
         this.formulaDetalhe.grupoFluxoCaixa.descricao = descricao.split(' - ')[1];
         this.formulaDetalList.push(this.formulaDetalhe);
         // zerando variaveis
         this.formulaDetalhe = new FormulaDetalhe();
         // salvo a posição linha do grupo
          this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
         this.formulaDetalhe.grupoFluxoCaixa = new GrupoFluxoCaixa();
         this.formulaDetalhe.fluxoCaixa = new FluxoCaixa(this.fluxoCaixa);
       }, error => this.httpService.handleError(error, () => this.formulaDetalheAdd()),
       () => this.httpService.done());

   } else { // alterar

       this.httpService.wait();
       this.httpService.put('/formuladetalhe', this.formulaDetalhe.id, this.formulaDetalhe)
       .subscribe(() => {

        const descricao = this.opcoesGrupoFluxoCaixa.find(i => i.value === this.formulaDetalhe.grupoFluxoCaixa.id).label;
         this.formulaDetalhe.grupoFluxoCaixa.descricao = descricao.split(' - ')[1];
         const index = this.formulaDetalList.findIndex(i => i.id === this.formulaDetalhe.id);
         // setando no array
         this.formulaDetalList.splice(index, 1, this.formulaDetalhe);

         // zerando variaveis
         this.formulaDetalhe = new FormulaDetalhe();
         // salvo a posição linha do grupo
         this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
         this.formulaDetalhe.grupoFluxoCaixa = new GrupoFluxoCaixa();
         this.formulaDetalhe.fluxoCaixa = new FluxoCaixa(this.fluxoCaixa);
       },
       error =>
         this.httpService.handleError(error, () => this.formulaDetalheAdd()),
       () => this.httpService.done()
     );

   }
 }

  cancelarFormulaDetalhe() {
    this.operadorCtrl = false;
    this.formulaDetalhe = new FormulaDetalhe();
    // salvo a posição linha do grupo
    this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
    this.formulaDetalhe.grupoFluxoCaixa = new GrupoFluxoCaixa();
    this.formulaDetalhe.fluxoCaixa = new FluxoCaixa(this.fluxoCaixa);
  }

  salvarFormulaDetalhe() {

    this.operadorCtrl = false;

      const size = this.formulaDetalList.length;
      let i = 1;
      this.formulaDetalList.forEach(formula => {
        if (i < size) {
          if (formula.operador === '' || formula.operador === undefined) {
            this.messageService.add({
              severity: 'warn',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'A fórmula está inconsistente, favor confira a sequencia do cálculo!'
            });
            this.operadorCtrl = true;
            return;
          }
        } else {
          if (formula.operador !== '' && formula.operador !== undefined && formula.operador !== null) {
            this.messageService.add({
              severity: 'warn',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'A fórmula está inconsistente, o último grupo não deve conter operador!'
            });
            this.operadorCtrl = true;
            return;
          }
        }
        i++;
      });
      if(!this.operadorCtrl) {
        this.operadorCtrl = false;
        this.modalFormulaDetal = false;
        this.formulaDetalList = new Array();
        this.comboGruposFluxo();
      }
    // }
  }

  editarFormulaDetalhe(event: any) {
    // console.log('event>>>>> ' +  JSON.stringify( event, null, ''));
    this.formulaDetalhe = new FormulaDetalhe(event);
    this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
  }

  removerFormulaDetalhe(event: any) {
    // console.log('event>>>>> ' +  JSON.stringify( event, null, ''));
    if (confirm('Deseja excluir esta formula?')) {
      this.httpService.wait();
      this.httpService.delete('/formuladetalhe', event.id.toString())
        .subscribe(() => {
          const index = this.formulaDetalList.findIndex(i => i.id === this.formulaDetalhe.id);
          this.formulaDetalList.splice(index, 1);
          this.formulaDetalhe = new FormulaDetalhe();
          this.formulaDetalhe.codigoGrupo = this.grupoFluxoCaixa.codigoGrupo;
          this.formulaDetalhe.grupoFluxoCaixa = new GrupoFluxoCaixa();
          this.formulaDetalhe.fluxoCaixa = new FluxoCaixa(this.fluxoCaixa);
        }, error => this.httpService.handleError(error, () => this.removerFormulaDetalhe(event)),
          () => this.httpService.done());
    }
  }

  // abrir modal pré formulario de composição
  comboGruposFluxo() {
    this.opcoesGrupoFluxoCaixa = [];
    this.opcoesFluxoCaixa.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/grupofluxocaixa?$select=id,codigoGrupo,descricao&$filter=fluxoCaixa/id eq '
                          + this.fluxoCaixa.id + ' and tipoGrupoFluxo/id eq 2&$orderby=codigoGrupo')
    .subscribe(ref => {
      ref.value.forEach(element => {
        this.opcoesGrupoFluxoCaixa.push({ label: element.codigoGrupo + ' - ' + element.descricao, value: element.id });
      });
    },
    error => this.httpService.handleError(error, () => this.comboGruposFluxo()),
    () => this.httpService.done());
  }

  private toolbarSetButtons() {
    const todos = this.buttons.slice();
    if ( todos.length === 0) {
      this.toolbarService.replace('voltar', true, 'class-first-button', 'undo', 'Voltar', );
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      this.toolbarService.replace('check', true, 'class-edit-button', 'check', 'Atualizar Status');
      // this.toolbarService.replace('pesquisa', true, 'class-edit-button', 'playlist_add', 'Formula', );
      this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar' );
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
      this.toolbarService.replace('save', true, 'class-first-button', 'save', 'Imprimir', );
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar' );
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar novo Fluxo',  () => this.openModalFluxo(true));
    } else {
      this.toolbarService.ocultar('download');
      this.toolbarService.ocultar('cancel');
      this.toolbarService.ocultar('check');
      this.toolbarService.ocultar('info');
      this.toolbarService.ocultar('msg');
      this.toolbarService.ocultar('print');
      this.toolbarService.replace('voltar', true, 'class-first-button', 'undo', 'Voltar', );
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.start());
      this.toolbarService.replace('check', true, 'class-edit-button', 'share', 'Atualizar Status');
      // this.toolbarService.replace('pesquisa', true, 'class-edit-button', 'functions', 'Formula', );
      this.toolbarService.replace('export', true, 'class-first-button', 'description', 'Exportar' );
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
      this.toolbarService.replace('save', true, 'class-edit-button', 'save', 'Imprimir' );
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar' );
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar novo Fluxo',  () => this.openModalFluxo(true));
    }
  }

  toolbarEditButtons(event: any) {
    // verificando se tem grupos cadastrados;
    this.toolbarService.alterarStatus('check', !(event.grupos > 0), 'Relacionar Grupo e Operações',
                                      () => this.abrirFormularioVinculos());
    this.toolbarService.alterarStatus('edit', false, 'Editar Fluxo',  () => this.openModalFluxo(false));
    this.toolbarService.alterarStatus('remove', false, 'Remover Fluxo',  () => this.removerFluxo());
  }

  onFilterOrigem(event: any) {
    this.filterOrigem = new Array();
    this.selectAllOrigem  = false;

    event.filteredValue.forEach(element => {
      element.selected = false;
      this.filterOrigem.push(element);
    });
    // console.log('filter Origem>>>>> ' +  JSON.stringify( this.filterOrigem, null, ''));
  }

  onSelectAllOrigem(event: any) {
    /*console.log('event --> ' + event);
    console.log('event checked --> ' + event.checked );
    console.log('selectAllOrigem --> ' + this.selectAllOrigem );*/
    this.operacaoSelecionada = new Array();
    // chegara como false a primeira vez.
     if (event.checked) {
      this.selectAllOrigem = true;
      this.listaOperacaoRegras.forEach(form => {
        const index = this.filterOrigem.findIndex(o => o.id === form.id );
        //console.log('index --> ' +index);
        if (index !== -1) {
          form.selected = true;
          this.operacaoSelecionada.push(form);
        } else {
          form.selected = false;
        }
      });
    } else {
      this.selectAllOrigem = false;
      this.listaOperacaoRegras.forEach(form => {
        form.selected = false;
      });
    }
  }

  onFilterDestino(event: any) {
    this.filterDestino = new Array();
    this.selectAllDest = false;
    event.filteredValue.forEach(element => {
      element.selected = false;
      this.filterDestino.push(element);
    });
  }

  onSelectAllDestino(event: any) {
    this.operacaoAdicionada = new Array();

    // chegara como false a primeira vez.
     if (event.checked) {
      this.selectAllDest = true;
      this.listaOperacaoRegrasSelecionadas.forEach(form => {
        const index = this.filterDestino.findIndex(o => o.id === form.id );
        if (index !== -1) {
          form.selected = true;
          this.operacaoAdicionada.push(form);
        } else {
          form.selected = false;
        }
      });
    } else {
      this.selectAllDest = false;
      this.listaOperacaoRegrasSelecionadas.forEach(form => {
        form.selected = false;
      });
    }
  }

  hideMessage() {


  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 46 ) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Somente são aceitos números e pontos(".")!'
      });
      return false;
    }
    return true;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10; // this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}
