import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel'
import { ConfigService } from 'app/shared/services/config.service'
import exportExcel from 'app/util/processing/exportExcel'
import { LazyLoadEvent } from 'primeng/api'
import { TableContent } from '../interfaces/dash-table'

@Component({
  selector: 'app-dash-table[data][isLoading][isLazy][hasFilter][hasPaginator][hasFooter][hasExport][isScrollable]',
  templateUrl: './dash-table.component.html',
  styleUrls: ['./dash-table.component.scss']
})
export class DashTableComponent implements OnChanges {

  @Input() data: TableContent
  @Input() isLoading: boolean
  @Input() isLazy: boolean
  @Input() hasFilter: boolean
  @Input() hasPaginator: boolean
  @Input() hasFooter: boolean
  @Input() hasExport: boolean
  @Input() isScrollable: boolean
  @Input() isTableLoading: boolean
  @Input() isTableExportLoading: boolean = false
  @Input() showShadowBox: boolean = true
  @Input() customExport: Function = null

  @Output() appliedFilter = new EventEmitter()

  skeletonConfig: SkeletonModel = new SkeletonModel({
    columns: [],
    hasFilter: false,
    hasSort: true,
    hasSelection: false,
    styleClass: 'table-system2'
  })

  constructor(
    private configService: ConfigService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.data !== null && this.data !== undefined) {
      let cols: Array<any> = new Array()

      this.data.cols.forEach(item => cols.push({ name: item.title, textAlign: item.align, size: `${item.width}%` }))

      this.skeletonConfig.setColumns(cols)
      this.skeletonConfig.hasFilter = this.hasFilter

    }

    if(changes.isTableLoading !== null && changes.isTableLoading !== undefined) {
      if(changes.isTableLoading.currentValue) this.skeletonConfig.startLoad()
      else this.skeletonConfig.endLoad()
    }
  }

  public get rowsPerPage(): Array<any> {
    return this.configService.applicationConfig.rowsPerPage
  }

  public get rows(): number {
    return this.configService.applicationConfig.rows
  }

  public get pageLinks(): number {
    return this.configService.applicationConfig.pageLinks
  }

  public applyFilter(event: LazyLoadEvent = {}): void {
    this.appliedFilter.emit(event)
  }

  public exportExcel(): void {
    if(this.customExport !== null) this.customExport()
    else exportExcel(this.data.rows, `dashboard-homepage-${this.data.title || '-tabela'}`, this.data.cols.map(item => { return item.key }))
  }
}
