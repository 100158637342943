import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Period } from 'app/shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { AppComponent } from '../../app.component';
import { CrudService } from 'app/shared/services/crud.service';
import { View } from 'app/cadastro/model/view.model';
import { ViewCampos } from 'app/cadastro/model/view-campos.model';
import { OperadoresComparacao } from 'app/util/model/operadores-comparacao.model';
import { OperadoresLogicos } from 'app/util/model/operadores-logicos.model';
import { OperadoresMatematicos } from 'app/util/model/operadores-matematicos.model';
import { Regra } from 'app/cadastro/model/regra.model';
import { RegraCondicao } from 'app/cadastro/model/regra-condicao.model';
import { RegraGrupo } from 'app/cadastro/model/regra-grupo.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { RegraCamposSelect } from 'app/cadastro/model/regra-campos-select.model';
import { RegraCamposOrder } from 'app/cadastro/model/regra-campos-order.model';
import { RegraCamposGroup } from 'app/cadastro/model/regra-campos-group.model';
import { AuthUser } from 'app/proof/model/auth-user.model';

@Component({
  selector: 'app-regra',
  templateUrl: './regra.component.html',
  styleUrls: ['./regra.component.scss'],
  providers: [CrudService]
})
export class RegraComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  views: Array<View> = new Array();
  condicoes: Array<String> = new Array();
  viewCampos: Array<ViewCampos> = new Array();
  viewCamposFull: Array<ViewCampos> = new Array();
  opcoesViews: SelectItem[];
  opcoesCampos: SelectItem[];
  opcoesCamposFormula: SelectItem[];
  opcoesCondicoes: SelectItem[];
  opcoesSinais: SelectItem[];
  opcoesCampoFormula: SelectItem[];
  camposDisponiveis: ViewCampos[];
  camposSelecionados: ViewCampos[];
  camposSelecionadosFinal: ViewCampos[];
  camposAgrupados: ViewCampos[];
  camposAgrupadosFinal: ViewCampos[];
  camposOrdenados: ViewCampos[];
  query: string;
  queryEnviar: Array<String> = new Array();
  where: string;
  campoFormula: string;
  advertencia: string[];
  mes: number;
  periodo: string;
  incluir: string;
  incluirFormula: string;
  booleanHabilitado: boolean;
  tipoSelecionado: string;
  campoSelecionado: string;
  condicaoSelecionada: string;
  sinalSelecionado: string;
  booleanNovoGrupo: boolean;
  naoExibirZero: boolean;

  previewRegra: Array<any> = new Array();
  queryTestada: boolean;
  erroQuery: boolean;
  camposListaEnviar: Array<String> = new Array();


  opcoesOperadoresComparacao: SelectItem[];
  opcoesOperadoresComparacaoCondicao: SelectItem[];
  opcoesOperadoresComparacaoFormula: SelectItem[];
  opcoesOperadoresLogicos: SelectItem[];
  opcoesOperadoresLogicosGrupo: SelectItem[];
  opcoesOperadoresMatematicos: SelectItem[];
  operadoresComparacao: Array<OperadoresComparacao> = new Array();
  operadoresComparacaoCondicao: Array<OperadoresComparacao> = new Array();
  operadoresComparacaoFormula: Array<OperadoresComparacao> = new Array();
  operadoresLogicos: Array<OperadoresLogicos> = new Array();
  operadoresMatematicos: Array<OperadoresMatematicos> = new Array();

  regraRelatorio: SelectItem[];
  grauRisco: SelectItem[];


  regra: any;
  regraCondicao: RegraCondicao;
  regraGrupo: RegraGrupo;
  condicaoQuery: string;
  titlePrincipal: string;
  subtitle: string;

  constructor(private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent) {
  }

  ngOnInit() {
    this.titlePrincipal = 'REGRA';
    this.subtitle = 'CADASTRAR REGRAS';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.periodChanged(); });
  }

  periodChanged(): void {

    this.regra = new Regra();
    this.regraCondicao = new RegraCondicao();
    this.regraCondicao.operadoresComparacao = new OperadoresComparacao();
    this.regraGrupo = new RegraGrupo();
    this.regra.regraGrupo = new Array<RegraGrupo>();
    this.regraGrupo.regraCondicao = new Array<RegraCondicao>();
    this.regra.regraCamposSelect = new Array<RegraCamposSelect>();
    this.regra.regraCamposGroup = new Array<RegraCamposGroup>();
    this.regra.regraCamposOrder = new Array<RegraCamposOrder>();
    this.queryEnviar = new Array<String>();
    this.condicoes = new Array<String>();
    this.views = new Array<View>();
    this.viewCampos = new Array<ViewCampos>();
    this.viewCamposFull = new Array<ViewCampos>();
    this.operadoresComparacao = new Array<OperadoresComparacao>();
    this.operadoresComparacaoFormula = new Array<OperadoresComparacao>();
    this.operadoresMatematicos = new Array<OperadoresMatematicos>();
    this.operadoresLogicos = new Array<OperadoresLogicos>();
    this.previewRegra = new Array<any>();

    this.opcoesCampos = [];
    this.opcoesCamposFormula = [];
    this.opcoesCondicoes = [];
    this.opcoesSinais = [];
    this.opcoesCampoFormula = [];
    this.camposSelecionados = [];
    this.camposSelecionadosFinal = [];
    this.camposAgrupados = [];
    this.camposAgrupadosFinal = [];
    this.camposOrdenados = [];


    this.campoFormula = '';
    this.where = '';
    this.booleanHabilitado = false;
    this.incluir = '';
    this.incluirFormula = '';
    this.tipoSelecionado = '';
    this.campoSelecionado = '';
    this.condicaoSelecionada = '';
    this.sinalSelecionado = '';
    this.booleanNovoGrupo = false;
    this.naoExibirZero = true;
    this.condicaoQuery = '';

    this.queryTestada = false;

    this.opcoesOperadoresComparacao = [];
    this.opcoesOperadoresComparacaoCondicao = [];
    this.opcoesOperadoresComparacaoFormula = [];
    this.opcoesOperadoresMatematicos = [];
    this.opcoesOperadoresLogicos = [];
    this.opcoesOperadoresLogicosGrupo = [];

    this.opcoesCampos = [];
    this.opcoesCamposFormula = [];
    this.opcoesViews = [];

    this.opcoesCampoFormula.push({ label: '(', value: '(' });
    this.opcoesCampoFormula.push({ label: ')', value: ')' });

    this.regraRelatorio = [];
    this.regraRelatorio.push({ label: 'Regra', value: true });
    this.regraRelatorio.push({ label: 'Relatório', value: false });

    this.grauRisco = [];
    this.grauRisco.push({ label: 'Advertência', value: 'Advertência' });
    this.grauRisco.push({ label: 'Erro', value: 'Erro' });
    this.grauRisco.push({ label: 'Crítico', value: 'Crítico' });

    this.httpService.wait();
    this.httpService.get('/view?$select=nome,descricao')
      .subscribe(ref => this.loadViews(ref.value),
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());

    this.titleService.title = 'Criação de Regras e Relatórios';

    this.httpService.wait();
    this.httpService.get('/operadoresmatematicos?$select=id,name,alias&$orderby=alias')
      .subscribe(ref => this.loadOperadoresMatematicos(ref.value),
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());


    this.httpService.wait();
    this.httpService.get('/operadorescomparacao?$select=id,name,alias&$orderby=alias')
      .subscribe(ref => this.loadOperadoresFormula(ref.value),
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());

  }

  incluirCampoFormula() {
    this.campoFormula = this.campoFormula + this.incluirFormula;
    this.incluirFormula = '';
  }

  onChangeCampoComp(event: any) {

    this.campoFormula = this.campoFormula + ' ' + event.value.name + ' ';

  }

  abreParenteses() {
    this.campoFormula = this.campoFormula + ' (';
  }

  fechaParenteses() {
    this.campoFormula = this.campoFormula + ' )';
  }

  incluirSoma() {
    this.campoFormula = this.campoFormula + ' + ';
  }

  incluirSub() {
    this.campoFormula = this.campoFormula + ' - ';
  }

  incluirMult() {
    this.campoFormula = this.campoFormula + ' * ';
  }

  incluirDiv() {
    this.campoFormula = this.campoFormula + ' / ';
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    // this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  onChangeValue() {

    this.httpService.wait();
    this.httpService.get('/viewcampos?$select=id,campo,descricao,tipo&$filter=view/id eq ' + this.regra.view.id
      + '&$orderby=campo')
      .subscribe(ref => this.loadViewCampos(ref.value),
        error => this.httpService.handleError(error, () => this.onChangeValue()),
        () => this.httpService.done());

  }

  onChangeCampos(event: any) {

    this.tipoSelecionado = event.value.tipo;
    this.campoSelecionado = event.value.campo;

    this.opcoesCondicoes = [];

    this.httpService.wait();
    this.httpService.get('/operadoreslogicos?$select=id,name,alias&$orderby=alias')
      .subscribe(ref => this.loadOperadoresLogicos(ref.value),
        error => this.httpService.handleError(error, () => this.onChangeCampos(event)),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/operadorescomparacao?$select=id,name,alias&$orderby=alias')
      .subscribe(ref => this.loadOperadoresComparacao(ref.value),
        error => this.httpService.handleError(error, () => this.onChangeCampos(event)),
        () => this.httpService.done());

  }

  limparCampoFormula() {
    this.campoFormula = '';
  }

  onChangeCampoFormula(event: any) {
    if (event.value.tipo === 'valor') {
      this.campoFormula = this.campoFormula + ' SUM(' + event.value.campo + ')';
    } else {
      this.campoFormula = this.campoFormula + ' ' + event.value.campo + ' ';
    }

    if (event.value.tipo === 'aliquota') {
      this.camposSelecionadosFinal.push(event.value.campo);
    }
  }

  onChangeSinalFormula(event: any) {
    this.campoFormula = this.campoFormula + ' ' + event.value;
  }

  onChangeSinalMatematico(event: any) {
    this.campoFormula = this.campoFormula + ' ' + event.value.name;
  }

  onChangeCondicoes(event: any) {
    this.condicaoSelecionada = event.value;
  }

  onChangeSinais(event: any) {
    this.sinalSelecionado = event.value;
  }

  loadViews(value: Array<View>) {
    this.opcoesViews = [];

    this.views = value;

    this.opcoesViews.push({ label: 'Selecione uma view', value: null });

    this.views.forEach(element => {
      this.opcoesViews.push({ label: element.nome + ' - ' + element.descricao, value: element });
    });

  }

  loadViewCampos(value: Array<ViewCampos>) {

    this.viewCampos = [];
    this.opcoesCampos = [];
    this.opcoesCampos.push({ label: 'Selecione o campo', value: null });
    this.viewCampos = value;
    this.viewCampos.forEach(element => {
      this.viewCamposFull.push(element);
      this.opcoesCampos.push({ label: element.campo, value: element });
      if (element.tipo === 'valor' || element.tipo === 'aliquota') {
        this.opcoesCamposFormula.push({ label: element.campo, value: element });
      }
    });
  }




  loadOperadoresComparacao(value: Array<OperadoresComparacao>) {
    this.operadoresComparacao = [];
    this.operadoresComparacaoCondicao = [];
    this.opcoesOperadoresComparacao = [];
    this.opcoesOperadoresComparacaoCondicao = [];
    this.opcoesOperadoresComparacao.push({ label: 'Selecione Comparação', value: null });
    this.opcoesOperadoresComparacaoCondicao.push({ label: 'Selecione Comparação', value: null });

    this.operadoresComparacao = value;
    this.operadoresComparacao.forEach(element => {
      if (this.tipoSelecionado.startsWith('texto') && (element.name !== '<' && element.name !== '>'
        && element.name !== '>=' && element.name !== '<=')) {
        this.opcoesOperadoresComparacao.push({ label: element.alias, value: element });
      } else if ((this.tipoSelecionado === 'valor' || this.tipoSelecionado === 'aliquota') && (element.name !== 'ilike'
        && element.name !== 'NOT ilike'
        && element.name !== 'ilike (\'' && element.name !== 'NOT ilike (\'')) {
        this.opcoesOperadoresComparacao.push({ label: element.alias, value: element });
      }
      this.opcoesOperadoresComparacaoCondicao.push({ label: element.alias, value: element });
    });
  }

  loadOperadoresFormula(value: Array<OperadoresComparacao>) {
    this.operadoresComparacaoFormula = [];
    this.opcoesOperadoresComparacaoFormula = [];
    this.opcoesOperadoresComparacaoFormula.push({ label: 'Selecione uma Comparação', value: null });

    this.operadoresComparacaoFormula = value;
    this.operadoresComparacaoFormula.forEach(element => {

      if (element.name !== 'ilike' && element.name !== 'NOT ilike' && element.name !== 'ilike (\''
        && element.name !== 'NOT ilike (\'') {
        this.opcoesOperadoresComparacaoFormula.push({ label: element.alias, value: element });

      }
    });
  }

  loadOperadoresMatematicos(value: Array<OperadoresMatematicos>) {
    this.operadoresMatematicos = [];
    this.opcoesOperadoresMatematicos = [];

    this.opcoesOperadoresMatematicos.push({ label: 'Operador Matemático', value: null });

    this.operadoresMatematicos = value;
    this.operadoresMatematicos.forEach(element => {
      this.opcoesOperadoresMatematicos.push({ label: element.alias, value: element });
    });
  }

  loadOperadoresLogicos(value: Array<OperadoresLogicos>) {
    this.operadoresLogicos = [];
    this.opcoesOperadoresLogicos = [];
    this.opcoesOperadoresLogicosGrupo = [];


    this.opcoesOperadoresLogicos.push({ label: 'Operador Lógico', value: 'AND' });
    this.opcoesOperadoresLogicosGrupo.push({ label: 'Operador Lógico', value: 'AND' });
    this.opcoesOperadoresLogicosGrupo.push({ label: 'Remover Operador', value: null });

    this.operadoresLogicos = value;
    this.operadoresLogicos.forEach(element => {
      this.opcoesOperadoresLogicos.push({ label: element.alias, value: element });
      this.opcoesOperadoresLogicosGrupo.push({ label: element.alias, value: element });
    });
  }

  carregarOpcoes() {


    this.operadoresLogicos.forEach(element => {
      this.opcoesOperadoresLogicos.push({ label: element.alias, value: element });
    });

    this.operadoresMatematicos.forEach(element => {
      this.opcoesOperadoresMatematicos.push({ label: element.alias, value: element });
    });

    this.operadoresComparacao.forEach(element => {
      this.opcoesOperadoresComparacao.push({ label: element.alias, value: element });
    });
  }

  onChangeCondicaoNovoGrupo(event: any) {
    this.condicoes.push(event.value);
  }

  onTabOpen(event: any) {

    if (event.index === 0) {
      const list = [];
      this.query = '';
      this.queryTestada = false;
      this.camposSelecionadosFinal = [];
      this.camposAgrupados = [];
      this.camposAgrupadosFinal = new Array<any>();
      this.camposOrdenados = new Array<any>();
    } else if (event.index === 2) {
      const list = [];
      this.queryTestada = false;
      this.query = '';
      this.opcoesSinais = [];
      this.opcoesSinais.push({ label: 'Selecione uma condição', value: null });
      this.opcoesSinais.push({ label: 'E', value: ' AND' });
      this.opcoesSinais.push({ label: 'OU', value: ' OR' });

      this.camposAgrupadosFinal = new Array<any>();
      this.camposOrdenados = new Array<any>();
      this.regra.regraCamposSelect = new Array<any>();

    } else if (event.index === 4) {
      this.camposAgrupadosFinal = new Array<any>();
      this.camposOrdenados = new Array<any>();
      this.regra.regraCamposSelect = new Array<any>();
      this.query = '';
      const list = [];
      this.previewRegra = new Array<any>();
      this.queryTestada = false;
      this.camposAgrupadosFinal = [];
      this.camposSelecionados.forEach(element => {
        if (element.tipo !== 'valor') {
          this.camposAgrupadosFinal.push(element);
          this.camposAgrupados.push(element);
        }
      });
    } else if (event.index === 5) {

      this.previewRegra = new Array<any>();
      this.advertencia = [];
      this.query = 'select ';

      this.regra.regraCamposSelect = new Array<any>();

      let ordemSelect = 0;
      this.camposSelecionados.forEach(element => {
        if (element.tipo === 'valor') {
          this.query = this.query + 'coalesce(SUM(' + element.campo + '),0.00) as ' + element.campo + ',';
        } else {
          this.query = this.query + element.campo + ',';
        }

        const selectPush = new RegraCamposSelect();
        selectPush.viewCampos = element;
        selectPush.regra = this.regra;
        selectPush.ordem = ordemSelect;
        this.regra.regraCamposSelect.push(selectPush);
        ordemSelect++;
      });



      if (this.campoFormula) {
        this.query = this.query + 'ROUND(' + this.campoFormula + ',2) as formula';
        const selectPushFormula = new RegraCamposSelect();
        selectPushFormula.regra = this.regra;
        selectPushFormula.campoFormula = this.campoFormula;
        selectPushFormula.campoFormulaTipo = 'valor';
        selectPushFormula.ordem = ordemSelect + 1;
        this.regra.regraCamposSelect.push(selectPushFormula);
      }
      this.query = this.query + ' FROM ' + this.regra.view.nome;
      this.query = this.query.replace(', FROM', ' FROM');

      this.mes = this.dblinkedSessionService.periodo.month.value;
      if (this.mes < 10) {
        this.periodo = '0' + this.mes.toString();
      } else {
        this.periodo = this.mes.toString();
      }

      const list = [];
      this.condicaoQuery = '';

      if (!this.regra.sql) {

        this.regra.regraGrupo.forEach(element => {
          if (element.operadoresLogicos !== null) {
            this.condicaoQuery = '(' + this.condicaoQuery + ')' + ' ' + element.operadoresLogicos.name + ' (';
          }
          element.regraCondicao.forEach(a => {

            if (a.operadoresLogicos !== null && a.operadoresLogicos !== undefined) {
              const operLog = new OperadoresLogicos(a.operadoresLogicos);
              const operComp = new OperadoresComparacao(a.operadoresComparacao);
              if (operComp.name === 'is null' || operComp.name === 'is not null') {
                if (operComp.name === 'is null') {
                  this.condicaoQuery = this.condicaoQuery + ' ' + operLog.name + ' ' + a.viewCamposA.campo
                    + ' ' + operComp.name + '  or ' + a.viewCamposA.campo + ' = \'\'';
                } else {
                  this.condicaoQuery = this.condicaoQuery + ' ' + operLog.name + ' ' + a.viewCamposA.campo
                    + ' ' + operComp.name + '  or ' + a.viewCamposA.campo + ' != \'\'';

                }

              } else if (operComp.alias === 'Contém' || operComp.alias === 'Não Contém') {
                this.condicaoQuery = this.condicaoQuery + ' ' + operLog.name + ' ' + a.viewCamposA.campo + ' '
                  + operComp.name + a.sqlLivre + '%\')';

              } else if (operComp.alias === 'Começa Com' || operComp.alias === 'Não Começa Com') {
                this.condicaoQuery = this.condicaoQuery + ' ' + operLog.name + ' ' + a.viewCamposA.campo + ' '
                  + operComp.name + a.sqlLivre + '%\')';

              } else {
                const campoLivre = (a.viewCamposA.tipo.startsWith('texto')) ? '\'' + a.sqlLivre + '\'' : a.sqlLivre;
                this.condicaoQuery = this.condicaoQuery + ' ' + operLog.name + ' ' + a.viewCamposA.campo + ' '
                  + operComp.name + ' ' + campoLivre;
              }


            } else {
              const operComp = new OperadoresComparacao(a.operadoresComparacao);
              const campoLivre = (a.viewCamposA.tipo.startsWith('texto')) ? '\'' + a.sqlLivre + '\'' : a.sqlLivre;
              if (operComp.name === 'is null' || operComp.name === 'is not null') {
                if (operComp.name === 'is null') {
                  this.condicaoQuery = this.condicaoQuery + ' ' + a.viewCamposA.campo + ' '
                    + operComp.name + '  or ' + a.viewCamposA.campo + ' = \'\'';
                } else {
                  this.condicaoQuery = this.condicaoQuery + ' ' + a.viewCamposA.campo + ' '
                    + operComp.name + '  or ' + a.viewCamposA.campo + ' != \'\'';
                }

              } else if (operComp.alias === 'Contém' || operComp.alias === 'Não Contém') {
                this.condicaoQuery = this.condicaoQuery + ' ' + a.viewCamposA.campo + ' ' +
                  operComp.name + a.sqlLivre + '%\')';

              } else if (operComp.alias === 'Começa Com' || operComp.alias === 'Não Começa Com') {
                this.condicaoQuery = this.condicaoQuery + ' ' + a.viewCamposA.campo + ' ' +
                  operComp.name + a.sqlLivre + '%\')';

              } else {
                this.condicaoQuery = this.condicaoQuery + ' ' + a.viewCamposA.campo + ' '
                  + operComp.name + ' ' + campoLivre;
              }


            }
          });
          if (element.regraCondicao.length > 0) {
            list.push(1);
          }
        });
      } else {
        this.condicaoQuery = this.regra.sql + ')';
      }

      let parenteses: string;
      parenteses = '';

      for (const i of list) {
        parenteses = parenteses + ')';
      }

      this.query = this.query + ' WHERE (' + this.condicaoQuery + parenteses + ' AND (cnpj_aud = \''
        + this.dblinkedSessionService.empresa.cnpj + '\' AND periodo = \''
        + this.periodo
        + this.dblinkedSessionService.periodo.year.value + '\')';

      this.query = this.query + ' GROUP BY ';
      this.camposAgrupados.forEach(element => {
        this.query = this.query + element.campo + ',';
        const groupPush = new RegraCamposGroup();
        groupPush.viewCampos = element;
        groupPush.regra = this.regra;
        this.regra.regraCamposGroup.push(groupPush);
      });
      this.query = this.query.replace(', GROUP BY', ' GROUP BY');

      if (this.naoExibirZero && this.campoFormula) {
        this.query = this.query + ' HAVING ROUND(' + this.campoFormula + ',2) <> 0.00';
      }

      this.query = this.query.replace(', HAVING', ' HAVING');

      this.query = this.query + ' ORDER BY ';
      this.camposOrdenados.forEach(element => {
        this.query = this.query + element.campo + ',';
        const orderPush = new RegraCamposOrder();
        orderPush.viewCampos = element;
        orderPush.regra = this.regra;
        this.regra.regraCamposOrder.push(orderPush);

      });

      this.query = this.query.replace(', ORDER BY', ' ORDER BY');

      this.query = this.query + ' LIMIT 10;';
      this.query = this.query.replace(', LIMIT', ' LIMIT');

      if (this.camposOrdenados.length === 0) {
        this.query = this.query.replace('ORDER BY', '');
      }

      if (this.camposAgrupados.length === 0) {
        this.query = this.query.replace('GROUP BY', '');
      }

      if (this.camposSelecionadosFinal.length > 0 && this.camposAgrupados.length > 0) {
        this.advertencia.push('Todos os campos devem estar no agrupamento');
      }
    }
  }

  testarRegra() {

    this.camposSelecionados.forEach(element => {
      this.camposListaEnviar.push(element.campo);
    });
    if (this.campoFormula) {
      this.camposListaEnviar.push('formula');
    }

    const listasEnviar = {
      query: this.query,
      camposSelecionados: this.camposListaEnviar
    };



    this.httpService.wait();
    this.httpService.post('/custom/dbl/testaregraok', listasEnviar)
      .subscribe(result => this.testeResult(result),
        error => this.httpService.handleError(error, () => this.testarRegra()),
        () => this.httpService.done());

  }


  testeResult(res: any) {

    if (res.result === 'erro') {
      this.erroQuery = true;
      this.queryTestada = true;
    } else {
      this.erroQuery = false;
      this.queryTestada = true;
      this.previewRegra = res.result;

    }
  }

  limparCondicao() {
    this.sinalSelecionado = '';
    this.where = '';
    this.booleanHabilitado = false;
  }

  incluirCondicao() {

    if (this.tipoSelecionado.startsWith('texto')) {
      if (this.condicaoSelecionada === 'ilike') {
        this.where = this.where + this.sinalSelecionado + ' ' + this.campoSelecionado + ' ilike (\'%' + this.incluir + '%\')';
      } else if (this.condicaoSelecionada === 'NOT ilike') {
        this.where = this.where + this.sinalSelecionado + ' ' + this.campoSelecionado + ' NOT ilike (\'%' + this.incluir + '%\')';
      } else if (this.condicaoSelecionada === 'ilike (') {
        this.where = this.where + this.sinalSelecionado + ' ' + this.campoSelecionado + ' ilike (\'' + this.incluir + '%\')';
      } else if (this.condicaoSelecionada === 'NOT ilike (') {
        this.where = this.where + this.sinalSelecionado + ' ' + this.campoSelecionado + ' NOT ilike (\'%' + this.incluir + '\')';
      } else {
        this.where = this.where + this.sinalSelecionado + ' ' + this.campoSelecionado + ' ' + this.condicaoSelecionada +
          '\'' + this.incluir + '\'';
      }
    } else {
      this.where = this.where + this.sinalSelecionado + ' ' + this.campoSelecionado + ' ' + this.condicaoSelecionada + this.incluir;
    }
    this.booleanHabilitado = true;
    this.incluir = '';
    this.sinalSelecionado = ' OR ';
  }

  incluirCondicaoOk() {
    this.regraCondicao.regraGrupo = this.regraGrupo;
    this.regraGrupo.regraCondicao.push(this.regraCondicao);
    this.regraGrupo.regra = this.regra;
    this.regraCondicao = new RegraCondicao();
    this.regraCondicao.operadoresComparacao = new OperadoresComparacao();

  }

  incluirGrupoOk() {


    this.regra.regraGrupo.forEach(element => {
      if (element.regraCondicao.length === 0) {
        this.regraGrupo.operadoresLogicos = null;
        this.regra.regraGrupo.splice(element.id, 1);
      }
    });

    if (this.regra.regraGrupo.length === 0) {
      this.regraGrupo.operadoresLogicos = null;
    } else {
      this.regraGrupo.operadoresLogicos = new OperadoresLogicos(1);
    }

    this.regra.regraGrupo.push(this.regraGrupo);
    this.regraGrupo = new RegraGrupo();
    this.regraGrupo.regraCondicao = new Array<RegraCondicao>();

  }

  excluirCondicao1(cond: RegraCondicao) {



    this.regraGrupo.regraCondicao.forEach(element => {

      if (element === cond) {
        this.regraGrupo.regraCondicao.splice(element.id, 1);
      }
      this.regraGrupo.regraCondicao.forEach(condicao => {
        condicao.operadoresLogicos = null;
      });
    });

  }

  excluirCondicao(cond: RegraCondicao) {

    this.regra.regraGrupo.forEach(element => {

      element.regraCondicao.forEach(condicao => {

        if (condicao === cond) {
          element.regraCondicao.splice(element.id, 1);
        }
        element.regraCondicao.forEach(condicao1 => {
          condicao1.operadoresLogicos = null;
        });
      });
      this.regra.regraGrupo.forEach(element1 => {
        element1.operadoresLogicos = null;

      });

    });

  }

  removerCondicao(cond: string) {
    const index = this.condicoes.findIndex(c => c === cond);
    this.condicoes.splice(index, 1);
  }

  editarCondicao(cond: string) {
    this.where = cond;
    this.booleanHabilitado = true;

    const index = this.condicoes.findIndex(c => c === cond);
    this.condicoes.splice(index, 1);

    if (cond === 'AND' || cond === 'OR') {
      alert('alterar condicao');
    }
  }

  salvarRegra() {

    this.regra.ativa = false;
    this.regra.homologada = false;
    this.regra.authUser = new AuthUser(this.sessionService.loggedUser.id);

    this.httpService.wait();
    this.httpService.post('/custom/dbl/salvarRegra', this.regra)
      .subscribe(() => this.periodChanged(),
        error => this.httpService.handleError(error, () => this.salvarRegra()),
        () => this.httpService.done());

  }

  success() {
    alert('Regra salva com Sucesso!');
  }

}
