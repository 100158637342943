import { Item } from "app/cadastro/model/item.model";
import { UnidadeMedida } from "app/util/model/unidade-medida.model";
import { EmpresaAlmoxarifado } from "app/cadastro/model/empresa-almoxarifado.model";

export class ItemBomArvore {
  id: number;
  itemBom: Item;
  almoxarifado: EmpresaAlmoxarifado;
  item: Item;
  un: UnidadeMedida;
  qtde: number;

  constructor(itemBomArvore?: ItemBomArvore | number) {
    if (itemBomArvore !== undefined && itemBomArvore !== null) {
      if (typeof itemBomArvore === 'number') {
        this.id = itemBomArvore;
      } else {
        this.id = itemBomArvore.id;
        this.itemBom = itemBomArvore.itemBom;
        this.almoxarifado = itemBomArvore.almoxarifado;
        this.item = itemBomArvore.item;
        this.un = itemBomArvore.un;
        this.qtde = itemBomArvore.qtde;
      }
    }
  }
}
