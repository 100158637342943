import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractValueAccessor } from 'app/shared/common/abstract-value-acessor';

@Component({
  selector: 'app-multi-select',
  providers: [{ provide: NG_VALUE_ACCESSOR,
                useExisting: forwardRef(() => MultiSelectComponent),
                multi: true }],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent extends AbstractValueAccessor {
  @Input() label: string;
  @Input() options: any = new Array();
  @Output() valueChange: EventEmitter<{}> = new EventEmitter();
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;

  constructor() {
    super();
  }

  cancelWhenReadonly(event: any): any {
    if (this.readonly) {
      event.preventDefault();
      return false;
    }
  }

  selected(option: any): boolean {
    return this.value && this.value.find(v => v[this.options.value] === option[this.options.value]) !== undefined;
  }

  changeCallback(option: any, event: any): void {
    if (this.readonly) {
      return;
    }

    const value = option[this.options.value];

    if (!event.checked) {
      this.value = this.value.filter(v => v[this.options.value] !== value);
    } else {
      const v = new Object();
      v[this.options.value] = value;
      this.value.push(v);
    }

    this.valueChange.next(this.value);
  }

  clear() {
    this.toggleAll();
  }

  toggleAll(all?: any) {
    if (this.readonly) {
      return;
    }

    const _all = all !== undefined && all !== null ? all.checked : false;
    const value: Array<any> = new Array();

    this.options.values.forEach((i: any) => {
      if (_all) {
        value.push({});
        value[value.length - 1][this.options.value] = i[this.options.value];
      }
    });

    this.value = value;
    this.valueChange.next(this.value);
  }
}
