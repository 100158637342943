import { Component, OnInit } from '@angular/core';
import { TbNaoNif } from '../model/tb-nao-nif.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'siscoserv-nao-nif',
  templateUrl: './nao-nif.component.html',
  styleUrls: ['./nao-nif.component.scss'],
  providers: [CrudService]
})
export class NaoNifComponent extends DataTableBasicComponent implements OnInit {
  public tbNaoNif: TbNaoNif = new TbNaoNif();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('CÓDIGO', 'cod'),
      new DataTableColumn('DESCRIÇÃO', 'descricao'),
      new DataTableColumn('CÓDIGO*', 'valueAlias')
    ];

    this.controls = [
      new DataControl('Código', 'cod').setReadOnly(),
      new DataControl('Descrição', 'descricao').setReadOnly(),
      new DataControl('Código*', 'valueAlias')
    ];
  }
}
