import { CodEntRef } from 'app/util/model/cod-ent-ref.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { PlanoContasRfb } from 'app/contabil/model/plano-contas-rfb.model';

export class PlanoContasRefRelac {
  id: number;
  codEntRef: CodEntRef;
  cadCcus: CadCcus;
  planoContasRfb: PlanoContasRfb;
  planoContas: PlanoContas;
  dtInicial: Date;
  dtFinal: Date;

  constructor(planocontasrefrelac?: PlanoContasRefRelac | number | any) {
    if (planocontasrefrelac !== undefined && planocontasrefrelac !== null) {
      if (typeof planocontasrefrelac === 'number') {
        this.id = planocontasrefrelac;
      } else {
        this.id = planocontasrefrelac.id;
        this.codEntRef = planocontasrefrelac.codEntRef || new CodEntRef();
        this.cadCcus = planocontasrefrelac.cadCcus || new CadCcus();
        this.planoContasRfb = planocontasrefrelac.planoContasRfb || new PlanoContasRfb();
        this.planoContas = planocontasrefrelac.planoContas || new PlanoContas();
        this.dtInicial = planocontasrefrelac.dtInicial;
        this.dtFinal = planocontasrefrelac.dtFinal;
      }
    }
  }
}
