import { Empresa } from './empresa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { MunicipioIbge } from '../../util/model/municipio-ibge.model';
import { Uf } from '../../util/model/uf.model';
import { Departamento } from 'app/util/model/departamento.model';

export class Usuario {
  id: number;
  nome: string;
  sobrenome: string;
  nivel: number;
  ativo: boolean;
  cargo: string;
  gestor: Usuario;
  rg: string;
  cpf: string;
  dtAdmi: Date;
  dtDemi: Date;
  telefone: string;
  celular: string;
  endereco: string;
  login: string;
  senha: string;
  departamento: Departamento;
  municipioIbge: MunicipioIbge;
  uf: Uf;
  authUser: AuthUser;
  projetoDblRelacs: Array<any> = new Array();
  empresas: Array<Empresa> = new Array();
  visualizaLog: boolean;
  mail: string;

  constructor(usuario?: Usuario | number | any) {
    if (usuario !== undefined && usuario !== null) {
      if (typeof usuario === 'number') {
        this.id = usuario;
      } else {
        this.id = usuario.id;
        this.nome = usuario.nome;
        this.sobrenome = usuario.sobrenome;
        this.nivel = usuario.nivel;
        this.ativo = usuario.ativo;
        this.cargo = usuario.cargo;
        this.gestor = usuario.gestor;
        this.rg = usuario.rg;
        this.cpf = usuario.cpf;
        this.dtAdmi = usuario.dtAdmi;
        this.dtDemi = usuario.dtDemi;
        this.telefone = usuario.telefone;
        this.celular = usuario.celular;
        this.endereco = usuario.endereco;
        this.login = usuario.login;
        this.senha = usuario.senha;
        this.departamento = usuario.departamento;
        this.municipioIbge = usuario.municipioIbge;
        this.uf = usuario.uf;
        this.authUser = usuario.authUser;
        this.projetoDblRelacs = usuario.projetoDblRelacs;
        this.empresas = usuario.empresas;
        this.visualizaLog = usuario.visualizaLog;
        this.mail = usuario.mail;
      }
    }
  }


}
