import { Component, OnDestroy, OnInit } from '@angular/core';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vinculacao-regra-contabil',
  templateUrl: './vinculacao-regra-contabil.component.html',
  styleUrls: ['./vinculacao-regra-contabil.component.scss']
})
export class VinculacaoRegraContabilComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  title = 'Cadastro'
  subtitle = "Vinculação entre operações de regra contábil"

  msgs = []

  opcoesOperacaoPagamento: Array<SelectItem> = [];
  opcoesOperacaoRecebimento: Array<SelectItem> = [];
  operacoesVinculadas = [];
  operacoesDisponiveis = [];
  operacaoSelecionada: any = null;

  toolbarMainIni = [
    { icon: 'done_all', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'green', onClick: () => { this.vincularOperacoes() } },
  ]

  public toolbarMain = new Toolbar();

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private toolbarService: ToolbarService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.toolbarService.hidden = true;
    this.toolbarMain.setAll(this.toolbarMainIni);

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      async () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected
          && this.dblinkedSessionService.empresa.id) {
          this.atualizarPagina();
        }
      })
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
  }

  async atualizarPagina(): Promise<void> {
    this.operacaoSelecionada = null;
    this.opcoesOperacaoPagamento = [];
    this.opcoesOperacaoRecebimento =  [];
    this.operacoesDisponiveis = [];
    this.operacoesVinculadas = [];

    await this.getOperacaoRegraContabil();
  }

  async buscarOperacoesRelacionadas(): Promise<void> {
    try {
      this.httpService.wait();
      const response = await this.httpService.get('custom/cadastro/operacoes-origem-vinculadas', [this.operacaoSelecionada, this.dblinkedSessionService.empresa.id]).toPromise();

      this.operacoesVinculadas = response.vinculados;
      this.operacoesDisponiveis = response.disponiveis;


      this.messageService.add(response)

    } catch (error) {
      this.messageService.add(error)
    } finally {
      this.httpService.done();
    }
  }

  async getOperacaoRegraContabil(): Promise<void> {
    this.opcoesOperacaoPagamento = [];
    this.opcoesOperacaoRecebimento = [];

    this.httpService.wait()
    const response = await this.httpService.get('/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 2 and tipoRegraContabil/id eq 3'
      + ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo eq true&$orderby=descricao asc').toPromise()

    response.value.forEach(element => { this.opcoesOperacaoPagamento.push({ label: element.descricao, value: element.id }); });

    const response2 = await this.httpService.get('/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq 1 and tipoRegraContabil/id eq 3'
    + ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and ativo eq true&$orderby=descricao asc').toPromise()

    response2.value.forEach(element => { this.opcoesOperacaoRecebimento.push({ label: element.descricao, value: element.id }); });

    this.httpService.done();

  }

  async vincularOperacoes(): Promise<void> {

    const data = {
      operacoesDisponiveis: this.operacoesDisponiveis,
      operacoesVinculadas: this.operacoesVinculadas,
      operacaoId: this.operacaoSelecionada,
      userId: this.sessionService.loggedUser.id
    };

    try {
      this.httpService.wait();
      const response = await this.httpService.post('/custom/cadastro/vincular-operacoes-origem', data).toPromise();
      this.messageService.add(response)

      this.atualizarPagina();
    } catch (error) {
      this.messageService.add(error)
    } finally {
      this.httpService.done();
    }
  }
}
