import { AuthUser } from 'app/proof/model/auth-user.model';
import { TodoCardChecklist } from './todo-card-checklist.model';

export class TodoCardChecklistAtividades {
  id: number;
  todoCardChecklist: TodoCardChecklist;
  descricao: string;
  concluida: boolean;
  usuario: AuthUser;
  posicao: any;

  constructor(
    todoCardChecklistAtividades?: TodoCardChecklistAtividades | number
  ) {
    if (
      todoCardChecklistAtividades !== undefined &&
      todoCardChecklistAtividades !== null
    ) {
      if (typeof todoCardChecklistAtividades === 'number') {
        this.id = todoCardChecklistAtividades;
      } else {
        this.id = todoCardChecklistAtividades.id;
        this.todoCardChecklist = todoCardChecklistAtividades.todoCardChecklist;
        this.descricao = todoCardChecklistAtividades.descricao;
        this.concluida = todoCardChecklistAtividades.concluida;
        this.posicao = todoCardChecklistAtividades.posicao;
        this.usuario = todoCardChecklistAtividades.usuario;
      }
    }
  }
}
