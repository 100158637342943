import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {
  constructor() {
  }

  transform(value: string): string {
    if (value === undefined || value === null || value.trim() === '') {
      return value;
    }

    let suffix: string;
    switch (value.substr(-1)) {
      case 'a':
      case 'e':
      case 'i':
      case 'o':
        suffix = 's';
        break;
      case 'r':
        suffix = 'es';
        break;
    }

    return value.trim() + suffix;
  }
}
