import { isNullOrUndefined } from "util";
import {Eventos } from 'app/eventos/model/eventos.model';
import {Plano} from 'app/eventos/model/plano.model';
import {GetNetPagamentoStatus} from 'app/getnet/model/getnet-pagamento-status.model'

export class GetNetPagamento{

  id                : number;
  valorTotal        : number;
  orderId           : string;
  nome              : string;
  cartaoNome        : string;
  cartaoToken       : string;
  cpf               : string;
  evento            : Eventos;
  eventoId          : number;
  eventoDescricao   : string;
  plano             : Plano;
  planoId           : number;
  quantidade        : number;
  telefone          : string;
  email             : string;
  parcela           : string;
  sellerId          : string;
  horaBrasil        : Date;
  ultimoDigitoCartao : string;
  customerId         : string;
  getNetPagamentoStatus : GetNetPagamentoStatus
  getNetPagamentoStatusId : number;
  mensagem     : string
  valorPago    : number;
  paymentId    : string;


  constructor(getNetPagamento?: GetNetPagamento | number) {
    if (!isNullOrUndefined(getNetPagamento)) {
      if (typeof getNetPagamento === 'number') {
        this.id = getNetPagamento;
      } else {
        this.id             = getNetPagamento.id;
        this.valorTotal     = getNetPagamento.valorTotal;
        this.orderId        = getNetPagamento.orderId;
        this.nome           = getNetPagamento.nome;
        this.cartaoNome     = getNetPagamento.cartaoNome;
        this.cartaoToken    = getNetPagamento.cartaoToken;
        this.cpf            = getNetPagamento.cpf;
        this.evento         = getNetPagamento.evento;
        this.eventoId       = getNetPagamento.eventoId;
        this.eventoDescricao = getNetPagamento.eventoDescricao;
        this.plano          = getNetPagamento.plano;
        this.planoId        = getNetPagamento.planoId;
        this.quantidade     = getNetPagamento.quantidade;
        this.telefone       = getNetPagamento.telefone;
        this.email          = getNetPagamento.email;
        this.parcela        = getNetPagamento.parcela;
        this.sellerId       = getNetPagamento.sellerId;
        this.horaBrasil     = getNetPagamento.horaBrasil;
        this.ultimoDigitoCartao = getNetPagamento.ultimoDigitoCartao;
        this.customerId         = getNetPagamento.customerId;
        this.getNetPagamentoStatus = getNetPagamento.getNetPagamentoStatus;
        this.getNetPagamentoStatusId = getNetPagamento.getNetPagamentoStatusId;
        this.mensagem                = getNetPagamento.mensagem;
        this.valorPago               = getNetPagamento.valorPago;
        this.paymentId               = getNetPagamento.paymentId;

      }
    }
  }

}