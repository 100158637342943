export class TbCodPais {
  id: number;
  cod: string;
  nome: string;
  valueAlias: string;

  constructor(tbCodPais?: TbCodPais | number) {
    if (tbCodPais) {
      if (typeof tbCodPais === 'number') {
        this.id = tbCodPais;
      } else {
        this.id = tbCodPais.id;
        this.cod = tbCodPais.cod;
        this.nome = tbCodPais.nome;
        this.valueAlias = tbCodPais.valueAlias;
      }
    }
  }
}
