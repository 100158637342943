import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { UtilService } from 'app/shared/services/util.service';

@Injectable()
export class ToolbarService {
  hidden = false;
  buttons: Array<ToolbarButton> = new Array();

  init: Subject<any> = new Subject();

  constructor(private utilService: UtilService) {
  }

  clear(): void {
    this.buttons = new Array();
  }

  get(key: string): ToolbarButton {
    const buttons = this.buttons.filter(button => button.key === key);
    return buttons !== undefined && buttons !== null && buttons.length === 1 ? buttons[0] : null;
  }

  add(key: string, disabled: boolean, icon: string, name: string, label: string,
          callback: Function = null, always: boolean = false): ToolbarButton {
    if (always || !this.get(key)) {
      this.buttons.push(new ToolbarButton(key, icon, name, label, callback, true, disabled));

      return this.buttons[this.buttons.length - 1];
    }
  }

  replace(key: string, disabled: boolean, icon: string, name: string, label: string, callback?: Function): void {
    if (this.get(key)) {
      this.remove(key);
    }

    this.add(key, disabled, icon, name, label, callback, true);
  }

  remove(key: string): void {
    const old = this.buttons.slice();
    this.clear();
    old.filter(button => button.key !== key).forEach(button => {
      this.buttons.push(button);
    });
  }

  setCallback(key: string, callback: Function): void {
    this.get(key).callback = callback;
  }

  alterarStatus(key: string, disabled: boolean, label: string, callback: Function): void {
    const botoes = this.buttons.filter(button => button.key === key);
    const botao = (botoes !== undefined && botoes !== null && botoes.length === 1) ? botoes[0] : null;
    botao.callback = callback;
    botao.disabled = disabled;
    botao.label = label;
  }

  ocultar(key: string): void {
    const todos = this.buttons.slice();
    this.clear();
    todos.filter(button => button.key !== key).forEach(button => {
      this.buttons.push(button);
    });
  }

  print(selector: string = 'table') {
    this.utilService.print(selector);
  }
}
