import { Component, OnInit, OnDestroy, ViewChildren, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Message, SelectItem } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'
  ],
  providers: [CrudService]
})
export class SiscoservStatusComponent implements OnInit, OnDestroy {
  @ViewChildren(ToolbarService) panels;

  defaultDate: Date;

  meses: any;

  msgs: Message[];

  pt_BR: any;

  rasResults: Array<any> = new Array();

  rvsResults: Array<any> = new Array();

  rpResults: Array<any> = new Array();

  rfResults: Array<any> = new Array();

  private sessionSubscription: Subscription;

  private tabName: any = 'RAS';

  private tabResults: Array<any> = this.rasResults;

  title: String = 'STATUS';
  subtitle: String = 'VISUALIZAÇÃO DE STATUS DOS REGISTROS IMPORTADOS';

  //export excel
  colsrasResults: any[];
  colsrvsResults: any[];
  colsrpResults: any[];
  colsrfResults: any[];

  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService
  ) {}

  ngOnInit() {
    this.toolbarService.clear();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 3, 2);

    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.changePeriod(); });

    this.meses = [];
    for (let mes = 1;  mes <= 12; mes++) {
      this.meses.push({ label:  mes, value: mes });
    }

    this.changePeriod();

    this.toolbarRefresh();

    this.colsrasResults = [
      { field: 'rasNumProcesso', header: 'NÚMERO PROCESSO' },
      { field: 'rasCodigoReceita', header: 'CÓDIGO RECEITA' },
      { field: 'numOperacaoEmpresa', header: 'NÚMERO OPERAÇÃO' },
      { field: 'rasDtInicio', header: 'DATA INÍCIO' },
      { field: 'rasDtConclusao', header: 'DATA CONCLUSÃO' },
      { field: 'rasModPrestacao', header: 'MODO PRESTAÇÃO' },
      { field: 'rasCodFornecedor', header: 'CÓDIGO FORNECEDOR' },
      { field: 'rasNomeFornecedor', header: 'NOME FORNECEDOR' },
      { field: 'rasEndereco', header: 'ENDEREÇO' },
      { field: 'rasNif', header: 'NIF' },
      { field: 'rasTipVinculacao', header: 'TIPO VINCULAÇÃO' },
      { field: 'rasCodImportador', header: 'CÓDIGO IMPORTADOR' },
      { field: 'rasMoeda', header: 'MOEDA' },
      { field: 'rasDescServico', header: 'DESCRIÇÃO SERVIÇO' },
      { field: 'rasCodNbs', header: 'CÓDIGO NBS' },
      { field: 'rasValor', header: 'VALOR' },
      { field: 'rasStatus', header: 'STATUS' }
    ];

    this.colsrvsResults = [
      { field: 'rvsNumProcesso', header: 'NÚMERO PROCESSO' },
      { field: 'rvsCodigoReceita', header: 'CÓDIGO RECEITA' },
      { field: 'numOperacaoEmpresa', header: 'NÚMERO OPERAÇÃO' },
      { field: 'rvsDtInicio', header: 'DATA INÍCIO' },
      { field: 'rvsDtConclusao', header: 'DATA CONCLUSÃO' },
      { field: 'rvsModPrestacao', header: 'MODO PRESTAÇÃO' },
      { field: 'rvsCodCliente', header: 'CÓDIGO CLIENTE' },
      { field: 'rvsNomeCliente', header: 'NOME CLIENTE' },
      { field: 'rvsEndereco', header: 'ENDEREÇO' },
      { field: 'rvsNif', header: 'NIF' },
      { field: 'rvsTipVinculacao', header: 'TIPO VINCULAÇÃO' },
      { field: 'rvsCodExportador', header: 'CÓDIGO EXPORTADOR' },
      { field: 'rvsMoeda', header: 'MOEDA' },
      { field: 'rvsDescServico', header: 'DESCRIÇÃO SERVIÇO' },
      { field: 'rvsCodNbs', header: 'CÓDIGO NBS' },
      { field: 'rvsValor', header: 'VALOR' },
      { field: 'rvsStatus', header: 'STATUS' }
    ];

    this.colsrpResults = [
      { field: 'rpNumProcesso', header: 'RAS SELECIONADO' },
      { field: 'rasCodigoReceita', header: 'CÓDIGO RECEITA RAS' },
      { field: 'rpCodProcesso', header: 'NÚMERO PROCESSO RP' },
      { field: 'rpCodigoReceita', header: 'CÓDIGO RECEITA RP' },
      { field: 'numOperacaoEmpresa', header: 'NÚMERO OPERAÇÃO' },
      { field: 'rpNumDocumento', header: 'NUM DOCUMENTO' },
      { field: 'rpDtPagto', header: 'DATA PAGTO' },
      { field: 'rpVlrPago', header: 'VALOR PAGO' },
      { field: 'rpStatus', header: 'STATUS' }
    ];

    this.colsrfResults = [
      { field: 'rfNumProcesso', header: 'RVS RELACIONADO' },
      { field: 'rvsCodigoReceita', header: 'CÓDIGO RECEITA RVS' },
      { field: 'rfCodProcesso', header: 'NÚMERO PROCESSO RF' },
      { field: 'rfCodigoReceita', header: 'CÓDIGO RECEITA RF' },
      { field: 'numOperacaoEmpresa', header: 'NÚMERO OPERAÇÃO' },
      { field: 'rfNumNf', header: 'NUM NF' },
      { field: 'rfDtFaturamento', header: 'DATA FAT' },
      { field: 'rfVlrFaturado', header: 'VALOR FATURADO' },
      { field: 'rfStatus', header: 'STATUS' }
    ];
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionSubscription.remove(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  private changePeriod() {

    if (this.dblinkedSessionService.hasEmpresaSelected &&
        this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
          this.loadGrids();
          this.defaultDate = new Date();
          if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
            const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
                      this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
            const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
            this.defaultDate = new Date(dt);
          }
      }
    }

  check(event: any) {
    if (!event.selected) {
      event.selected = false;
    } else {
        event.selected = true;
    }
  }

  checkAll(event: any) {
    this.tabFilter();

    if (event.checked === true) {
      this.tabResults.forEach(rst => {
        rst.selected = true;
      });
    } else {
      this.tabResults.forEach(rst => {
        rst.selected = false;
      });
    }
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {

  }

  private loadGrids() {
    this.loadGridRas();
    this.loadGridRvs();
    this.loadGridRp();
    this.loadGridRf();
  }

  private loadGridRas() {
    this.httpService.wait();
    this.httpService.get('/custom/siscoserv/get-ras-rvs-periodo(' +
    this.dblinkedSessionService.empresa.id + ',' + this.dblinkedSessionService.periodo.month.value + ','
    + this.dblinkedSessionService.periodo.year.value + ',RAS-status)')
    .subscribe(ref => {
    this.rasResults = ref;
    },
    error => this.httpService.handleError(error, () => this.changePeriod()),
    () => this.httpService.done());
  }

  private loadGridRvs() {
    this.httpService.wait();
    this.httpService.get('/custom/siscoserv/get-ras-rvs-periodo(' +
    this.dblinkedSessionService.empresa.id + ',' + this.dblinkedSessionService.periodo.month.value + ','
    + this.dblinkedSessionService.periodo.year.value + ',RVS-status)')
    .subscribe(ref => {
    this.rvsResults = ref;
    },
    error => this.httpService.handleError(error, () => this.changePeriod()),
    () => this.httpService.done());
  }

  private loadGridRp() {
    this.httpService.wait();
    this.httpService.get('/custom/siscoserv/get-rp-rf-periodo(' +
    this.dblinkedSessionService.empresa.id + ',' + this.dblinkedSessionService.periodo.month.value + ','
    + this.dblinkedSessionService.periodo.year.value + ',RP-status)')
    .subscribe(ref => {
    this.rpResults = ref;
    },
    error => this.httpService.handleError(error, () => this.changePeriod()),
    () => this.httpService.done());
  }

  private loadGridRf() {
    this.httpService.wait();
    this.httpService.get('/custom/siscoserv/get-rp-rf-periodo(' +
    this.dblinkedSessionService.empresa.id + ',' + this.dblinkedSessionService.periodo.month.value + ','
    + this.dblinkedSessionService.periodo.year.value + ',RF-status)')
    .subscribe(ref => {
    this.rfResults = ref;
    },
    error => this.httpService.handleError(error, () => this.changePeriod()),
    () => this.httpService.done());
  }

  private tabFilter() {
    this.tabName = document.getElementById('findme').textContent;

    switch (this.tabName) {
      case 'RAS': {
        this.tabResults = this.rasResults;
        break;
      }
      case 'RVS': {
        this.tabResults = this.rvsResults;
        break;
      }
      case 'RP': {
        this.tabResults = this.rpResults;
        break;
      }
      case 'RF': {
        this.tabResults = this.rfResults;
        break;
      }
      default: {
      }
    }
  }

  private toolbarRefresh() {
    this.toolbarService.clear();
    this.toolbarService.add('info', true, 'class-edit-button', 'info', 'Modelo de Planilhas');
    this.toolbarService.add('warning', true, 'class-edit-button', 'warning', 'Visualizar Log');
    this.toolbarService.add('export', true, 'class-edit-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-edit-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }

}
