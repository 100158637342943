export class ProjetoDbl {
  id: number;
  cod: string;
  descricao: string;

  constructor(projetodbl?: ProjetoDbl | number) {
    if (projetodbl !== undefined && projetodbl !== null) {
      if (typeof projetodbl === 'number') {
        this.id = projetodbl;
      } else {
        this.id = projetodbl.id;
        this.cod = projetodbl.cod;
        this.descricao = projetodbl.descricao;
      }
    }
  }
}
