import { AuthUser } from 'app/proof/model/auth-user.model';
import { TodoProjeto } from './todo-projeto.model';

export class TodoEtiquetas {
  id: number;
  nome: string;
  descricao: string;
  corBg: string;
  todoProjeto: TodoProjeto;
  usuario: AuthUser;

  constructor(todoEtiquetas?: TodoEtiquetas | number) {
    if (todoEtiquetas !== undefined && todoEtiquetas !== null) {
      if (typeof todoEtiquetas === 'number') {
        this.id = todoEtiquetas;
      } else {
        this.id = todoEtiquetas.id;
        this.nome = todoEtiquetas.nome;
        this.descricao = todoEtiquetas.descricao;
        this.corBg = todoEtiquetas.corBg;
        this.todoProjeto = todoEtiquetas.todoProjeto;
        this.usuario = todoEtiquetas.usuario;
      }
    }
  }
}
