import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthUser } from 'app/proof/model/auth-user.model';
import { CrudService } from 'app/shared/services/crud.service';
import { Depto } from 'app/cadastro/model/depto.model';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { TodoProjeto } from '../model/todo-projeto.model';
import { TodoProjetoUsuarios } from '../model/todo-projeto-usuarios.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Router } from '@angular/router';
import { TitleService } from 'app/shared/services/title.service';
import { TodoEtiquetas } from '../model/todo-etiquetas.model';

@Component({
  selector: 'app-work-flow-projeto',
  templateUrl: './work-flow-projeto.component.html',
  styleUrls: [
    './work-flow-projeto.component.scss',
    '../../shared/styles/centralweb.scss'
  ],
  providers: [CrudService]
})
export class WorkFlowProjetoComponent implements OnInit, OnDestroy {
  constructor(
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private titleService: TitleService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private router: Router,
    private messageService: MessageService
  ) {}

  private sessionSubscription: Subscription; // change session event;

  // *data;
  defaultDate: Date;
  pt_BR: any;
  isRTL = true;

  // *msgs;
  msgs: Message[] = [];
  msgsModal: Message[] = [];
  title: string;
  subtitle: string;

  // painéis;
  projetoList = [];
  projetoFavList = [];

  // modal buscar projeto;
  modalBuscarProjeto: boolean;
  mbpProjeto = null;
  mbpProjetoList: SelectItem[] = [];

  // modal criar projeto;
  modalCriarProjeto: boolean;
  modalEtiqueta = false;
  mcpTituloModal = 'criar projeto';
  mcpNome: any = null;
  mcpKey: any = null;
  mcpCor: any = '#0064B7';
  mcpDepartamento: any;
  mcpDepartamentoList: SelectItem[];
  mcpUsuarios: Array<any>;
  mcpUsuariosList: SelectItem[];
  mcpAdms: Array<any>;
  mcpAdmsList: SelectItem[];
  etiqueta = new TodoEtiquetas();
  etiquetas: Array<TodoEtiquetas> = [];
  addParticipantes = false;

  // modal desarquivar;
  modalDesarquivar = false;
  mdCards = false;
  mdOption = [];
  mdOptionList: SelectItem[] = [];

  // misc;
  userLogado: any;

  ngOnInit() {
    this.dblinkedSessionService.hidePeriods();
    Promise.resolve(null).then(() => (this.toolbarService.hidden = false)); // gilson;
    Promise.resolve(null).then(() => this.refreshToolbar());
    this.titleService.title = 'CentralWeb - WorkFlow';
    this.title = 'workflow';
    this.subtitle = 'projetos';
    this.defaultDate = new Date();
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
      }
    );
  }

  ngOnDestroy() {
    this.dblinkedSessionService._showPeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  private changeSession() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.loadDepartamento();
      this.loadProjeto();
      this.loadUsuarios();
    }
  }

  hideMessage() {


  }

  loadAdms() {
    this.refreshAdms(false);
    const mcpAdmsOld = this.mcpAdms;
    this.mcpAdms = [];
    // lista de participantes selecionados;
    this.mcpUsuarios.forEach(element => {
      // lista de participantes completa;
      if (this.sessionService.loggedUser.id !== element) {
        const index = this.mcpUsuariosList.find(c => c.value === element);
        // lista de adms selecionáveis;
        this.mcpAdmsList.push({
          label: index.label,
          value: index.value
        });
      }
    });

    // lista de adms selecionados;
    mcpAdmsOld.forEach(element => {
      if (this.mcpUsuarios.find(c => c === element)) {
        this.mcpAdms.push(element);
      }
    });
    if (mcpAdmsOld.find(c => c === this.userLogado.value)) {
      this.mcpAdms.push(this.userLogado.value);
    }
  }

  private loadDepartamento() {
    this.mcpDepartamentoList = [];

    this.httpService.wait();
    this.httpService
      .get('/depto?$select=id,descricao&$orderby=descricao')
      .subscribe(
        rst => {
          this.mcpDepartamentoList.push({
            label: 'Selecione',
            value: null
          });
          this.mcpDepartamento = null;

          rst.value.forEach(element => {
            this.mcpDepartamentoList.push({
              label: element.descricao,
              value: element.id
            });
          });
        },
        error =>
          this.httpService.handleError(error, () => this.loadDepartamento()),
        () => this.httpService.done()
      );
  }

  private loadProjeto() {
    this.mbpProjetoList = [];
    this.mdOptionList = [];
    this.projetoList = [];
    this.projetoFavList = [];

    const map = { userId: this.sessionService.loggedUser.id };
    this.mbpProjetoList.push({label: 'Selecione', value: '' });
    this.httpService.post('/custom/workflow/select-projeto', map).subscribe(
      rst => {
        rst.forEach(element => {
          if (!element.arquivado) {
            this.mbpProjetoList.push({
              label: element.nome,
              value: element.id
            });
            if (element.favoritado) {
              this.projetoFavList.push({
                nome: element.nome,
                favoritado: element.favoritado,
                corBg: element.corBg,
                key: element.key,
                depto: element.depto,
                descricao: element.descricao,
                id: element.id
              });
            } else {
              this.projetoList.push({
                nome: element.nome,
                favoritado: element.favoritado,
                corBg: element.corBg,
                key: element.key,
                depto: element.depto,
                descricao: element.descricao,
                id: element.id
              });
            }
          } else {
            this.mdOptionList.push({
              label: element.nome,
              value: element.id
            });
          }
        });

        this.refreshToolbar();
      },
      error => this.httpService.handleError(error, () => this.loadProjeto()),
      () => this.httpService.done()
    );
  }

  private loadUsuarios() {
    this.mcpUsuarios = [];
    this.mcpUsuariosList = [];

    this.httpService.wait();
    this.httpService
      .post('/custom/workflow/load-usuarios',
      {
        empresaId: this.dblinkedSessionService.empresa.id,
        projetoId: null
      }
      )
      .subscribe(
        rst => {
          rst.forEach(element => {
            this.mcpUsuariosList.push({
              label: element.fullName,
              value: element.id
            });
          });

          // Salva as inforamações do user logado;
          const index = this.mcpUsuariosList.find(
            c => c.value === this.sessionService.loggedUser.id
          );
          this.userLogado = { label: index.label, value: index.value };

          this.refreshAdms(true);

          // Remove o user logado da lista de participantes selecionáveis (participante como default);
          this.mcpUsuariosList.splice(
            this.mcpUsuariosList.findIndex(
              c => c.value === this.sessionService.loggedUser.id
            ),
            1
          );
        },
        error => this.httpService.handleError(error, () => this.loadUsuarios()),
        () => this.httpService.done()
      );
  }

  refreshAdms(boo: boolean) {
    // Adms selecionáveis;
    this.mcpAdmsList = [];
    this.mcpAdmsList.push({
      label: this.userLogado.label,
      value: this.userLogado.value
    });
    // Adms selecionados;
    if (boo) {
      this.mcpAdms = [];
      this.mcpAdms.push(this.userLogado.value);
    }
  }

  favoritarProjeto(idProjeto: number) {
    const map = {
      idProjeto: idProjeto,
      idUser: this.sessionService.loggedUser.id
    };
    this.httpService.post('/custom/workflow/favoritar-projeto', map).subscribe(
      rst => {
        const idx = this.projetoList.findIndex(i => i.id === idProjeto);
        if (idx !== -1) {
          this.projetoFavList.push(
            this.projetoList.find(i => i.id === idProjeto)
          );
          this.projetoList.splice(idx, 1);
        } else {
          const idf = this.projetoFavList.findIndex(i => i.id === idProjeto);
          this.projetoList.push(
            this.projetoFavList.find(i => i.id === idProjeto)
          );
          this.projetoFavList.splice(idf, 1);
        }
      },
      error =>
        this.httpService.handleError(error, () =>
          this.favoritarProjeto(idProjeto)
        ),
      () => this.httpService.done()
    );
  }

  criarProjeto() {
    let boo = false;
    if (this.mcpNome === null) {
      boo = true;
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Erro',
        detail: 'Campo obrigatório: NOME'
      });
    }
    if (this.mcpKey === null) {
      boo = true;
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Erro',
        detail: 'Campo obrigatório: KEY'
      });
    }
    if (this.mcpDepartamento === null) {
      boo = true;
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Erro',
        detail: 'Campo obrigatório: DEPARTAMENTO'
      });
    }
    if (this.mcpCor === null) {
      boo = true;
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Erro',
        detail: 'Campo obrigatório: COR'
      });
    }
    // if (this.mcpUsuarios.length === 0) {
    //   boo = true;
    //   this.messageService.add({
    //     severity: 'error',key: 'messageLinnks',
    //     summary: 'Erro',
    //     detail: 'Campo obrigatório: PARTICIPANTES'
    //   });
    // }
    if (this.mcpAdms.length === 0) {
      boo = true;
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Erro',
        detail: 'Campo obrigatório: ADMINISTRADORES'
      });
    }

    if (!boo) {
      // Cadastra o projeto;
      // ----------------------------------------
      const todoProjeto = new TodoProjeto();
      todoProjeto.nome = this.mcpNome;
      todoProjeto.corBg = this.mcpCor;
      todoProjeto.addParticipantes = this.addParticipantes;
      todoProjeto.depto = new Depto(this.mcpDepartamento);
      todoProjeto.key = this.mcpKey;
      todoProjeto.arquivado = false;
      todoProjeto.empresa = new Empresa(this.dblinkedSessionService.empresa.id);
      todoProjeto.usuario = new AuthUser(this.sessionService.loggedUser.id);

      this.httpService.wait();
      this.httpService.post('/todoprojeto', todoProjeto).subscribe(
        rst => {
          // Cadastra os participantes;
          // ------------------------------------
          this.mcpUsuarios.push(this.userLogado.value);
          this.mcpUsuarios.forEach(element => {
            const todoProjetoUsuarios = new TodoProjetoUsuarios();
            todoProjetoUsuarios.usuario = new AuthUser(element);
            todoProjetoUsuarios.administrador = this.mcpAdms.find(
              c => c === element
            )
              ? true
              : false;
            todoProjetoUsuarios.todoProjeto = new TodoProjeto(rst.id);

            this.httpService.wait();
            this.httpService
              .post('/todoprojetousuarios', todoProjetoUsuarios)
              .subscribe(
                rst2 => {
                  if (element === this.sessionService.loggedUser.id) {
                    this.buscarProjeto(false, rst.id);
                  }
                },
                error =>
                  this.httpService.handleError(error, () =>
                    this.criarProjeto()
                  ),
                () => this.httpService.done()
              );
          });
        },
        error => this.httpService.handleError(error, () => this.criarProjeto()),
        () => this.httpService.done()
      );
    }
  }

  buscarProjeto(boo: boolean, id: any) {
    this.router.navigate(['./centralweb/work-flow/' + id + '/0']);
  }

  refreshModal() {
    this.modalCriarProjeto = false;
    this.mcpNome = null;
    this.mcpKey = null;
    this.mcpCor = '#0064B7';
    this.addParticipantes = false;
    this.mcpDepartamento = null;
    this.mcpUsuarios = [];

    this.modalBuscarProjeto = false;
    this.mbpProjeto = null;

    this.modalDesarquivar = false;

    this.refreshAdms(true);
  }

  // Lista de btns (app.component.ts);
  private refreshToolbar() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('save');

    this.toolbarService.alterarStatus('atualizar', false, 'Atualizar', () =>
      this.changeSession()
    );

    this.toolbarService.alterarStatus(
      'pesquisa',
      this.mbpProjetoList.length > 0 ? false : true,
      'Buscar projeto',
      () => {
        this.modalBuscarProjeto = true;
      }
    );

    this.toolbarService.replace(
      'unarchive',
      this.mdOptionList.length > 0 ? false : true,
      'class-first-button',
      'unarchive',
      'Desarquivar projetos',
      () => this.openModalDesarquivar()
    );

    this.toolbarService.replace(
      'add',
      false,
      'class-new-button',
      'NOVO',
      'Criar projeto',
      () => this.abrirModalNovo()
    );
  }

  abrirModalNovo() {
    this.mcpTituloModal = 'criar projeto';
    this.modalCriarProjeto = true;
    this.mcpNome = null;
    this.mcpKey = null;
    this.mcpCor = '#0064B7';
    this.addParticipantes = false;
    this.mcpDepartamento = null;
    this.mcpUsuarios = [];
    this.modalBuscarProjeto = false;
    this.mbpProjeto = null;
    this.refreshAdms(true);
  }

  openModalDesarquivar() {
    this.mdOption = [];
    this.modalDesarquivar = true;
  }

  desarquivarProjetos() {
    if (
      confirm(
        'Deseja desarquivar ' +
          (this.mdOption.length === 1 ? 'este projeto?' : 'estes projetos?')
      )
    ) {
      this.httpService.wait();
      this.httpService
        .post('/custom/workflow/desarquivar-projetos', this.mdOption)
        .subscribe(
          () => {
            this.refreshModal();
            this.changeSession();
          },
          error =>
            this.httpService.handleError(error, () =>
              this.desarquivarProjetos()
            ),
          () => this.httpService.done()
        );
    }
  }
}
