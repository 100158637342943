import { Empresa } from "app/cadastro/model/empresa.model";

export class Tipo {
  id: number;
  nome: string;
  empresa: Empresa;

  constructor(tipo?: Tipo | number) {
    if (tipo !== undefined && tipo !== null) {
      if (typeof tipo === 'number') {
        this.id = tipo;
      } else {
        this.id = tipo.id;
        this.nome = tipo.nome;
        this.empresa = tipo.empresa;
      }
    }
  }
}
