import { HttpClient, HttpHeaders } from "@angular/common/http"
import { ConfigService } from "app/shared/services/config.service"
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service"
import { LogAction, LogModule, LogScreen } from "./log.types"
import { SessionService } from "app/shared/services/session.service"

export default class LogManagement {
  constructor(
    private httpClient: HttpClient,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private sessionService: SessionService
  ) { }

  public registerLog(module: LogModule, screen: LogScreen, path: string, action: LogAction, registerId: number | null = null, registerCode: string | null = null, message: string = '', extraInformation: Object | null = null): void {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }

    const payload = {
      companyId: this.dblinkedSessionService.empresa.id,
      userId: this.sessionService.loggedUser.id,
      module: module,
      screen: screen,
      path: path,
      action: action,
      registerId: registerId,
      registerCode: registerCode,
      message: message,
      extraInformation: JSON.stringify(extraInformation, getCircularReplacer())
    }

    //Função que verifica se um objeto é circular e substitui o objeto por uma string escrita "[Circular]"
    function getCircularReplacer() {
      const ancestors = [];
      return function (key, value) {
        if (typeof value !== "object" || value === null) {
          return value;
        }
        // `this` is the object that value is contained in,
        // i.e., its direct parent.
        while (ancestors.length > 0 && ancestors[ancestors.length - 1] !== this) {
          ancestors.pop();
        }
        if (ancestors.includes(value)) {
          return "[Circular]";
        }
        ancestors.push(value);
        return value;
      };
    }

    this.httpClient.post(`${this.configService.defaultUrlApiUpload}log/gravar-log-modulo`, payload, httpOptions).subscribe()
  }
}
