import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../app.config';
import { Period } from '../../shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { Message, SelectItem } from 'primeng/api';
import { TimezonePipe } from '../../shared/pipes/timezone.pipe';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { TipoDocumento } from 'app/util/model/tipo-documento.model';
import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { Justificativas } from 'app/util/model/justificativas.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { Departamento } from 'app/util/model/departamento.model';
import { Obrigacao } from 'app/centralweb/model/obrigacao.model';
import { Envio } from 'app/centralweb/model/envio.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { EnvioDestinatarios } from 'app/centralweb/model/envio-destinatarios.model';
import { Relatorios } from 'app/centralweb/model/relatorios.model';
import { TributosPagamentoRelac } from 'app/centralweb/model/tributos-pagamento-relac.model';

@Component({
  selector: 'app-tributos-pagamento-relac',
  templateUrl: './tributos-pagamento-relac.component.html',
  styleUrls: ['./tributos-pagamento-relac.component.scss'],
  providers: [CrudService]
})
export class TributosPagamentoRelacComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  codigosObrigacoes: Array<CodigosObrigacoes> = new Array();
  codigosObrigacoesSelecionados: CodigosObrigacoes[];
  usuarios: Array<Usuario> = new Array();
  opcoesusuarios: SelectItem[];
  tributosPagamentoRelac = new TributosPagamentoRelac();
  usuarioResponsavel = new Usuario();
  title: string;
  subtitle: string;

  constructor(private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent) {
  }

  ngOnInit() {
    this.title = 'RELACIONAR TRIBUTOS';
    this.subtitle = 'SELECIONE OS TRIBUTOS QUE DEVERÃO CONTER A CONFIRMAÇÃO DO PAGAMENTO';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.toolbarRefresh();
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.codigosObrigacoes = new Array<CodigosObrigacoes>();
      this.codigosObrigacoesSelecionados = new Array<CodigosObrigacoes>();
      this.tributosPagamentoRelac = new TributosPagamentoRelac();
      this.usuarioResponsavel = new Usuario();
      this.usuarioResponsavel.id = 0;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.empresaChanged(); });
  }

  empresaChanged(): void {
    if (this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.hasEmpresaSelected) {

      this.codigosObrigacoes = new Array<CodigosObrigacoes>();
      this.codigosObrigacoesSelecionados = new Array<CodigosObrigacoes>();
      this.usuarios = new Array<Usuario>();
      this.tributosPagamentoRelac = new TributosPagamentoRelac();
      this.usuarioResponsavel = new Usuario();

      this.httpService.wait();
      this.httpService.get('/custom/centralweb/get-users-empresa(' + this.dblinkedSessionService.empresa.id + ')')
        .subscribe(usuarios => this.loadUsuarios(usuarios),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

    }
  }

  buscarObrigacoes(){
    this.httpService.wait();
      this.httpService.get('/tributospagamentorelac?$select=id,usuarioResponsavelComprovante/id,usuarioResponsavelComprovante/nome,' +
        'usuarioResponsavelComprovante/login,codigosObrigacoes' +
        '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and usuarioResponsavelComprovante/id eq ' + this.usuarioResponsavel.id+
        '&$orderby=codigosObrigacoes/cod')
        .subscribe(ref => this.loadProjetosSelecionados(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged),
          () => this.httpService.done());
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    // this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  loadProjetosSelecionados(value: any) {

    /*this.tributosPagamentoRelac = new TributosPagamentoRelac();

    this.tributosPagamentoRelac = value;

    if (this.tributosPagamentoRelac[0] !== null && this.tributosPagamentoRelac[0] !== undefined) {
      this.usuarioResponsavel = this.tributosPagamentoRelac[0].usuarioResponsavelComprovante;
    }*/

    this.codigosObrigacoesSelecionados = new Array();

    value.forEach(element => {
      this.codigosObrigacoesSelecionados.push(element.codigosObrigacoes);
    });

    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-obrigacoes-cadastradas(' + this.dblinkedSessionService.empresa.id + ','
    +this.usuarioResponsavel.id+')')
      .subscribe(obrig => this.loadCodigosObrigacoes(obrig),
        error => this.httpService.handleError(error, () => this.loadProjetosSelecionados(value)),
        () => this.httpService.done());

  }

  loadCodigosObrigacoes(value: Array<CodigosObrigacoes>) {

    this.codigosObrigacoes = value.filter(c => this.codigosObrigacoesSelecionados.findIndex(cs => cs.id === c.id) === -1);
  }

  loadUsuarios(value: Array<Usuario>) {
    this.opcoesusuarios = new Array();
    this.opcoesusuarios.push({ label: 'Selecione um Responsável', value: null });
    value.forEach(element => {

      this.opcoesusuarios.push({ label: element.nome + ' - ' + element.mail,
     value: element.id
     });
    });
  }

  atualizarRelacao() {

    const listaViewEnviar = {
      responsavelId: this.usuarioResponsavel.id,
      codigosObrigacoesSelecionados: this.codigosObrigacoesSelecionados,
      empresaId: this.dblinkedSessionService.empresa.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/atualizar-informacoes-pagamento', listaViewEnviar)
      .subscribe(result => this.empresaChanged(),
        error => this.httpService.handleError(error, () => this.atualizarRelacao()),
        () => this.httpService.done());


  }

  private toolbarRefresh() {
      this.toolbarService.clear();
      this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
      this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
      this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
      this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
    }

}
