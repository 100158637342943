import { Component, OnInit } from '@angular/core';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { HttpService } from 'app/shared/services/http.service';
import { ConfigService } from 'app/shared/services/config.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SessionService } from 'app/shared/services/session.service';

import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-auditor-participante',
  templateUrl: './participante.component.html',
  styleUrls: ['./participante.component.scss'],
  providers: [CrudService]
})
export class AuditorParticipanteComponent extends DataTableBasicComponent implements OnInit {
  public participante: any = {};
  public registrosParticipantes: Array<any> = new Array();
  public empresas: Array<any> = new Array();
  private empresaSubscription: Subscription;
  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeEditSubscription: Subscription;

  title: string;
  subtitle: string;
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;

  cnpj: string;
  razao: string;
  fantasia: string;
  empresa: number;
  codigo: any;
  ids: Array<any> = new Array();
  selectedEmpresa: number;

  buttonToolbar = this.getButtonToolbarContent();
  toolbarMainIni = this.getToolbarMainIniContent();

  public toolbarMain = new Toolbar();

  constructor(
    crudService: CrudService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private configService: ConfigService,
    private httpService: HttpService,
    private messageService: MessageService,
    private toolbarService: ToolbarService) {
    super(crudService);
  }

  ngOnInit() {
    this.toolbarService.hidden = true;
    this.carregarToolbar();
    this.ctrlSave = true;
    // Promise.resolve(null).then(() => this.ocultaBotoesToolbar());
    this.toolbarMain.setAll(this.toolbarMainIni);

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
    );

    this.empresaSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.empresaChanged,
      () => {
        this.empresaChanged();
      }
    );

    // this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((aliquotaTributo: AliquotaTributo) => {
    //   if (this.ctrlSave) {
    //     this.beforeSave(aliquotaTributo);
    //   }
    // });
    // this.beforeEditSubscription = this.crudService.beforeEdit.subscribe((aliquotaTributo: AliquotaTributo) => {
    //   this.ctrlSave = false;
    // });


  }

  empresaChanged(){
    this.columns = [
      new DataTableColumn('CNPJ', 'cnpj').setNoWrap(),
      new DataTableColumn('RAZÃO', 'razao'),
      new DataTableColumn('FANTASIA', 'fantasia'),
      new DataTableColumn('EMPRESA', 'empresa')
        .setTypeSelect()
        .setOptions({ url: '/empresa',
                      value: 'idempresa', label: 'razaosocial', prompt: 'Todas' })
    ];

    this.controls = [
      new DataControl('Cnpj', 'cnpj')
        .setRequired()
        .setMinLength(14)
        .setMaxLength(18),
      new DataControl('Código', 'codigo')
        .setRequired()
        .setMinLength(1)
        .setMaxLength(50),
      new DataControl('Razão', 'razao')
        .setRequired()
        .setMinLength(5)
        .setMaxLength(100),
     new DataControl('Fantasia', 'fantasia')
        .setRequired()
        .setMinLength(5)
        .setMaxLength(50),
      new DataControl('Empresa', 'empresa')
        .setTypeSelect()
        .setOptions({ url: '/empresa', value: 'idempresa', label: ['razaosocial', ' - ', 'cnpj'] })
    ];
  }


  carregar(){
    this.title = 'PARTICIPANTES';
    this.subtitle = 'PARTICIPANTES';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();

    this.carregaBotoesToolbarInicial();
    this.limpaCamposForm();

    this.loadRegistros();
  }


  loadRegistros(){
    this.httpService.wait();
    this.httpService
    .get(`/participante?$select=cnpj,razao,fantasia,empresa/idempresa&$count=true&$skip=0&$orderby=cnpj`, null, 'auditor')
    .subscribe(
      (retorno) => {
          this.registrosParticipantes = new Array();
          retorno.value.forEach(item => {
            this.registrosParticipantes.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar';
    this.editarConta = true;
    this.carregaBotoesToolbarFormEditar();

    this.loadEmpresas();

    this.limpaCamposForm();
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Alteração', () => this.salvarAlteracaoRegistro());
    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.provisorio());

    this.httpService.wait();
    this.httpService.get(`/participante(${this.ids[0]})?$select=cnpj,codigo,razao,fantasia,empresa/idempresa,empresa/razaosocial,empresa/cnpj&$count=true`, null, 'auditor')
      .subscribe(item => {
        this.cnpj = item.value.cnpj;
        this.razao = item.value.razao;
        this.fantasia = item.value.fantasia;
        this.empresa = item.value.empresa;
        this.codigo = item.value.codigo;
      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());
  }


  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      cnpj: this.cnpj,
      codigo: this.codigo,
      empresa: {
        empresa_id: this.selectedEmpresa,
      },
      fantasia: this.fantasia,
      razao: this.razao,
    };

    this.httpService.wait();
    this.httpService.post('/participante', jsonData, 'auditor')
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro cadastrado com sucesso!'
            });
            this.carregar();
        }else{
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
            });
            this.carregar();
            return;
        }
      }, error => this.httpService.handleError(error, () =>  this.salvarNovoRegistro()),
      () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      cnpj: this.cnpj,
      codigo: this.codigo,
      empresa: {
        idempresa: this.selectedEmpresa,
      },
      fantasia: this.fantasia,
      razao: this.razao,
    };

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/participante', key, jsonData, 'auditor')
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
      error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
      () => this.httpService.done());
  }


  loadEmpresas(){
    this.httpService.wait();
    this.httpService
    .get(`/empresa?$select=idempresa,razaosocial,cnpj&$orderby=razaosocial,cnpj`, null, 'auditor')
    .subscribe(
      (retorno) => {
          this.empresas = new Array();
          retorno.value.forEach(item => {
            this.empresas.push({
              ...item,
              razaoCnpj: `${item.razaosocial} - ${item.cnpj}`
            });
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar';
    this.carregaBotoesToolbarFormNovo();

    this.loadEmpresas();
  }

  selectCheckboxContainer(data){
    setTimeout(() => {
      document.getElementById('checkboxContainer'+data.id).click();
    },100);
  };

  selectCheckbox(event: any){
    this.ids.push(event.data.id);
    this.filtraFuncoesToolbar();
  }

  unselectCheckbox(event: any){
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    this.filtraFuncoesToolbar();
  }

  filtraFuncoesToolbar() {
    if (this.ids.length === 1) {
      this.toolbarMain.setDisabled(this.buttonToolbar.excluir, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.editar, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
    }else {
      this.toolbarMain.setDisabled(this.buttonToolbar.excluir, true);
      this.toolbarMain.setDisabled(this.buttonToolbar.editar, true);
      this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
    }
  }

  provisorio(){

  }

  validacao(): boolean {
    if ((this.cnpj === null || this.cnpj === undefined) || this.cnpj.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'CNPJ é Obrigatório' });
      return true;
    }
    if ((this.codigo === null || this.codigo === undefined) || this.codigo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório' });
      return true;
    }
    if ((this.razao === null || this.razao === undefined) || this.razao.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Razão é Obrigatório' });
      return true;
    }
    if ((this.fantasia === null || this.fantasia === undefined) || this.fantasia.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Fantasia é Obrigatório' });
      return true;
    }

    return false;
  }


  private getButtonToolbarContent(){
    return {
      voltar: 'btn1',
      excluir: 'btn2',
      editar: 'btn3',
      adicionarNovo: 'btn4',
      salvarNovo: 'btn5',
      salvarAlteracao: 'btn6',
    }
  }

  private getToolbarMainIniContent(){
    return [
      { icon: 'undo', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: true, color: 'default', onClick: () => this.carregar() },
      { icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: true, disabled: true, color: 'red', onClick: () => this.provisorio() },
      { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: true, disabled: true, color: 'default', onClick: () => this.loadEditarRegistro() },
      { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.criarNovo() },
      { icon: 'check', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: true, disabled: false, color: 'green', onClick: () => this.salvarNovoRegistro() },
      { icon: 'check', text: 'Salvar', tooltip: 'Salvar Alteração', visible: true, disabled: false, color: 'green', onClick: () => this.salvarAlteracaoRegistro() },
    ];
  }

  private carregaBotoesToolbarFormEditar(){
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, true);

    this.toolbarMain.setDisabled(this.buttonToolbar.voltar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.salvarAlteracao, false);
  }

  private carregaBotoesToolbarFormNovo(){
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, true);

    this.toolbarMain.setDisabled(this.buttonToolbar.voltar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.salvarNovo, false);
  }

  private carregaBotoesToolbarInicial(){
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.excluir, true);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, true);

    this.toolbarMain.setDisabled(this.buttonToolbar.excluir, true);
    this.toolbarMain.setDisabled(this.buttonToolbar.editar, true);
    this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
  }

  private ocultaBotoesToolbar(){
    this.toolbarMain.setVisible(this.buttonToolbar.voltar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.excluir, false);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, false);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, false);
  }

  private carregarToolbar() {
    this.toolbarService.add('voltar', true, 'class-first-button', 'undo', 'Voltar',);
    // pesquisa fixo, utilizar alterarStatus() os demais alterarStatus() e/ou ocultar()
    this.toolbarService.add('atualizar', true, 'class-other-button', 'refresh', 'Atualizar',);
    this.toolbarService.add('check', true, 'class-other-button', 'check', 'Finalizar',);
    this.toolbarService.add('info', true, 'class-other-button', 'info', 'Info',);
    this.toolbarService.add('msg', true, 'class-other-button', 'warning', 'Visualizar',);
    this.toolbarService.add('pesquisa', true, 'class-first-button', 'search', 'Pesquisar',);
    // print fixo, utilizar alterarStatus() os demais alterarStatus() e/ou ocultar()
    this.toolbarService.add('download', true, 'class-other-button', 'cloud_download', 'Download',);
    this.toolbarService.add('cancel', true, 'class-other-button', 'block', 'Cancelar',);
    this.toolbarService.add('export', true, 'class-other-button', 'description', 'Exportar',);
    // this.toolbarService.add('import', true, 'class-other-button', 'description', 'Importar', );
    this.toolbarService.add('print', true, 'class-first-button', 'print', 'Imprimir',);
    // edit fixo, utilizar alterarStatus()
    this.toolbarService.add('save', true, 'class-other-button', 'save', 'Voltar',);
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir',);
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar',);
    // variavel, utilizar replace()
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Criar novo',);
  }

  private limpaCamposForm(){
    this.razao = '';
    this.fantasia = '';
    this.codigo = '';
    this.cnpj = '';
    this.selectedEmpresa = null;
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
}
