import { AuthUser } from 'app/proof/model/auth-user.model';

export class PedidoStatus {
  id: number;
  description: string;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;


  constructor(pedidoStatus?: PedidoStatus | number) {
    if (pedidoStatus !== undefined && pedidoStatus !== null) {
      if (typeof pedidoStatus === 'number') {
        this.id = pedidoStatus;
      } else {
        this.id = pedidoStatus.id;
        this.description = pedidoStatus.description;
        this.createTimestamp = pedidoStatus.createTimestamp;
        this.changeUser = pedidoStatus.changeUser;
        this.changeTimestamp = pedidoStatus.changeTimestamp;
      }
    }
  }
}
