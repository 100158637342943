import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { Config } from '../../app.config';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { PlanoContasService } from 'app/shared/services/plano-contas.service';
import { TbCsllM350 } from '../../ecf/model/tb-csll-m350.model';
import { TbCsllM350Relac } from '../../ecf/model/tb-csll-m350-relac.model';
import { PlanoContas } from '../model/plano-contas.model';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
    selector: 'app-teste',
    templateUrl: './plano-contas-ref-relac-csll.component.html',
    styleUrls: ['./plano-contas-ref-relac-csll.component.scss'],
})

export class PlanoContasRefRelacCsllComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  tbCsllM350List: Array<TbCsllM350> = new Array();

  tbCsllM350RelacList: Array<TbCsllM350Relac> = new Array();
  tbCsllM350Relac: TbCsllM350Relac = new TbCsllM350Relac();

  planoContasList: Array<PlanoContas> = new Array();
  planoContasSelectedList: Array<PlanoContas> = new Array();

  opcoesCentroCusto: Array<SelectItem> = new Array();
  cadCcusId: number;

  contadorLinha: number = 0;
  contadorLinhaSelected: number = 0;
  contadorLinhaMapeadas: number = 0;

  private planoContasService: PlanoContasService = new PlanoContasService('csll');
  titlePrincipal: string;
  subtitle: string;
  titleSecond: string;

  constructor(private configService: ConfigService,
              private httpService: HttpService,
              private sessionService: SessionService,
              private toolbarService: ToolbarService,
              private dblinkedSessionService: DblinkedSessionService,
              private titleService: TitleService) {
    this.titleService.title = 'Mapeamento IRPJ: Adição/Exclusão (M300)';
  }

  ngOnInit() {
    this.titlePrincipal = 'REFERENCIAR CSLL';
    this.subtitle = 'RELACIONE O PLANO DE CONTAS DA EMPRESA COM AS CONTAS DE ADIÇÃO E EXCLUSÃO DE CSLL';
    this.titleSecond = 'CONTAS MAPEADAS';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    // Promise.resolve(null).then(() => this.toolbarRefresh());
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
                                                                 () => { this.periodChanged(); });

    this.contadorLinha = 0;
    this.contadorLinhaSelected = 0;
    this.contadorLinhaMapeadas = 0;
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  periodChanged() {
    if (!this.dblinkedSessionService.hasPeriodoSelected || !this.dblinkedSessionService.hasEmpresaSelected) {
      return;
    }

    this.back();

    this.opcoesCentroCusto = new Array();
    this.opcoesCentroCusto.push({ label: '(Nenhum)', value: null });
    this.httpService.wait();
    this.httpService.get('/cadccus?$select=id,codCcus,descrCcus' +
                                 '&$filter=ativo eq true and empresa/id eq ' + this.dblinkedSessionService.empresa.id +
                                         ' and period(dtInicial,dtFinal) eq ' + this.year +
                                 '&$orderby=codCcus')
                    .subscribe(cadCcusList => {
                                 cadCcusList.value.forEach(cadCcus => {
                                   this.opcoesCentroCusto.push({ label: cadCcus.codCcus + ' - ' + cadCcus.descrCcus,
                                                                 value: cadCcus.id,
                                                                 data: cadCcus });
                                 });
                               },
                               error => this.httpService.handleError(error, () => this.periodChanged()),
                               () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/custom/ecd/tb-csll-m350', [this.year, this.dblinkedSessionService.empresa.id])
                  .subscribe(tbCsllM350List => {
                               this.tbCsllM350List = tbCsllM350List;

                               this.setTbCsllM350ListUsed();

                               if(this.tbCsllM350List !== null || this.tbCsllM350List !== undefined){
                                this.contadorLinha = this.tbCsllM350List.length;
                                }
                                  else{
                                      this.contadorLinha = 0;
                                }

                             },
                             error => this.httpService.handleError(error, () => this.periodChanged()),
                             () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/custom/ecd/planocontas', [this.dblinkedSessionService.empresa.id , this.year])
                    .subscribe(planoContas => {
                        this.planoContasList = planoContas.value;
                        if(this.planoContasList !== null || this.planoContasList !== undefined){
                              this.contadorLinha = this.planoContasList.length;
                          }
                            else{
                                this.contadorLinha = 0;
                          }
                        } ,
                               error => this.httpService.handleError(error, () => this.periodChanged()),
                               () => this.httpService.done());



    this.httpService.wait();
    this.httpService.get('/tbcsllm350relac?$select=cadCcus/id,tbCsllM350Adicao/cod,tbCsllM350Adicao/descricao,' +
                                                  'tbCsllM350Exclusao/cod,tbCsllM350Exclusao/descricao,' +
                                                  'planoContas/codCta,planoContas/descrCta,planoContas/indCta,planoContas/nivel' +
                                         '&$filter=planoContas/ativo eq true' +
                                                 ' and planoContas/empresa/id eq ' + this.dblinkedSessionService.empresa.id +
                                                 ' and period(dtInicial,dtFinal) eq ' + this.year +
                                         '&$orderby=tbCsllM350Adicao/cod')
                    .subscribe(tbCsllM350RelacList => {
                                 this.tbCsllM350RelacList = tbCsllM350RelacList.value;

                                 this.setTbCsllM350ListUsed();
                               },
                               error => this.httpService.handleError(error, () => this.periodChanged()),
                               () => this.httpService.done());
  }

  private setTbCsllM350ListUsed() {
    this.tbCsllM350List.forEach(tbCsllM350 => {
      tbCsllM350.used = false;
    });

    this.tbCsllM350RelacList.forEach(tbCsllM350Relac => {
      this.tbCsllM350List.forEach(tbCsllM350 => {
        if (tbCsllM350.id === tbCsllM350Relac.tbCsllM350Adicao.id ||
          tbCsllM350.id === tbCsllM350Relac.tbCsllM350Exclusao.id) {
          tbCsllM350.used = true;
        }
      });
    });
  }

  selectTbCsllM350(event: any) {
    if (event.data.tipo === 'E') {
      switch (event.data.tipoLanc) {
        case 'A':
          this.tbCsllM350Relac.tbCsllM350Adicao = this.clone(event.data);
          break;
        case 'E':
          this.tbCsllM350Relac.tbCsllM350Exclusao = this.clone(event.data);
          break;
      }
    }

    this.cadCcusId = null;

    this.loadTbCsllM350();
  }

  editTbCsllM350Relac(event: any) {
    this.tbCsllM350Relac = this.clone(event.data);

    this.cadCcusId = this.tbCsllM350Relac.cadCcus ? this.tbCsllM350Relac.cadCcus.id : null;

    this.loadTbCsllM350();
  }

  loadTbCsllM350() {
    const tbCsllM350Relac = this.tbCsllM350Relac;

    if (tbCsllM350Relac.tbCsllM350Adicao && tbCsllM350Relac.tbCsllM350Adicao.id &&
      tbCsllM350Relac.tbCsllM350Exclusao && tbCsllM350Relac.tbCsllM350Exclusao.id) {
      this.httpService.wait();
      this.httpService.get('/planocontas?$select=codCta,descrCta,indCta,nivel,tbCsllM350Relac/cadCcus/id,' +
                                                'tbCsllM350Relac/tbCsllM350Adicao/id,tbCsllM350Relac/tbCsllM350Exclusao/id' +
                                       '&$filter=ativo eq true' +
                                               ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id +
                                               ' and tbCsllM350Relac/tbCsllM350Adicao/id eq ' + tbCsllM350Relac.tbCsllM350Adicao.id +
                                               ' and tbCsllM350Relac/tbCsllM350Exclusao/id eq ' + tbCsllM350Relac.tbCsllM350Exclusao.id +
                                               ' and period(dtInicial,dtFinal) eq ' + this.year)
                      .subscribe(planoContas => {
                                   this.planoContasSelectedList = planoContas.value.slice();

                                   if(this.planoContasSelectedList !== null || this.planoContasSelectedList !== undefined){
                                    this.contadorLinhaSelected = this.planoContasSelectedList.length;
                                    }
                                      else{
                                          this.contadorLinhaSelected = 0;
                                    }


                                   this.planoContasList.forEach(p => p.selected = false);
                                 },
                                 error => this.httpService.handleError(error, () => this.loadTbCsllM350()),
                                 () => this.httpService.done());
    }
    // this.toolbarRefresh();
  }

  back() {
    this.tbCsllM350Relac = new TbCsllM350Relac();
    this.planoContasSelectedList = new Array();
  }

  get tbCsllM350RelacListDistinct(): Array<TbCsllM350Relac> {
    const tbCsllM350RelacList: Array<TbCsllM350Relac> = new Array();

    this.tbCsllM350RelacList.forEach(tbCsllM350Relac => {
      if (!tbCsllM350RelacList.find(distinct => distinct.tbCsllM350Adicao &&
                                                distinct.tbCsllM350Adicao.id === tbCsllM350Relac.tbCsllM350Adicao.id &&
                                                distinct.tbCsllM350Exclusao &&
                                                distinct.tbCsllM350Exclusao.id === tbCsllM350Relac.tbCsllM350Exclusao.id)) {
        tbCsllM350RelacList.push(tbCsllM350Relac);
      }
    });

    if(this.tbCsllM350RelacList !== null || this.tbCsllM350RelacList !== undefined){
      this.contadorLinhaMapeadas = this.tbCsllM350RelacList.length;
    }
      else{
          this.contadorLinhaMapeadas = 0;
    }


    return tbCsllM350RelacList;
  }

  get tbCsllM350RelacSelection(): any {
    return this.selection(this.tbCsllM350RelacListDistinct, this.tbCsllM350Relac);
  }

  private selection(list: Array<any>, item: any): any {
    return item && item.id ? list.find(r => r.id === item.id) : null;
  }

  check(planoContas: PlanoContas, selected: boolean) {
    this.planoContasService.check(this.planoContasList, planoContas, selected);
  }

  private dataTbCsllM350Relac(tbCsllM350Relac: TbCsllM350Relac, planoContas?: PlanoContas) {
    const data = { id: tbCsllM350Relac.id,
                   planoContas: { id: planoContas ? planoContas.id : tbCsllM350Relac.planoContas.id },
                   tbCsllM350Adicao: { id: tbCsllM350Relac.tbCsllM350Adicao.id },
                   tbCsllM350Exclusao: { id: tbCsllM350Relac.tbCsllM350Exclusao.id },
                   cadCcus: undefined };
    if (this.cadCcusId) {
      data.cadCcus = { id: this.cadCcusId };
    }

    return data;
  }

  add() {
    const newItems = this.planoContasService.toAdd(this.planoContasList, this.planoContasSelectedList);
    const planoContas = newItems[0];

    const tbCsllM350Relac: TbCsllM350Relac | any = this.dataTbCsllM350Relac(this.tbCsllM350Relac, planoContas);

    this.httpService.wait();
    this.httpService.post('/custom/ecd/tbcsllm350relac(' + this.year + ')', tbCsllM350Relac)
                    .subscribe(id => {
                                  tbCsllM350Relac.id = id;
                                  tbCsllM350Relac.tbCsllM350Adicao.cod = this.tbCsllM350Relac.tbCsllM350Adicao.cod;
                                  tbCsllM350Relac.tbCsllM350Adicao.descricao = this.tbCsllM350Relac.tbCsllM350Adicao.descricao;
                                  tbCsllM350Relac.tbCsllM350Exclusao.cod = this.tbCsllM350Relac.tbCsllM350Exclusao.cod;
                                  tbCsllM350Relac.tbCsllM350Exclusao.descricao = this.tbCsllM350Relac.tbCsllM350Exclusao.descricao;

                                  this.tbCsllM350RelacList.push(tbCsllM350Relac);

                                  this.planoContasList.find(p => p.id === planoContas.id).selected = false;

                                  if (newItems.length > 1) {
                                    this.add();
                                  } else {
                                    this.loadTbCsllM350();
                                    this.setTbCsllM350ListUsed();
                                  }
                                },
                                error => this.httpService.handleError(error, () => this.add()),
                                () => this.httpService.done());
  }

  get addDisabled(): boolean {
    return !this.planoContasSelectedList ||
           this.planoContasService.toAdd(this.planoContasList, this.planoContasSelectedList).length < 1;
  }

  update() {
    if (this.planoContasSelectedList && this.planoContasSelectedList.length > 0) {
      const planoContas = this.planoContasSelectedList.find(p => (this.cadCcusId && !p.tbCsllM350Relac.cadCcus) ||
                                                                  this.cadCcusId !== p.tbCsllM350Relac.cadCcus.id);

      if (planoContas) {
        const tbCsllM350Relac: TbCsllM350Relac | any = this.dataTbCsllM350Relac(planoContas.tbCsllM350Relac, planoContas);

        tbCsllM350Relac.cadCcus = null;
        if (this.cadCcusId) {
          tbCsllM350Relac.cadCcus = { id: this.cadCcusId };
        }

        this.httpService.wait();
        this.httpService.patch('/tbcsllm350relac', tbCsllM350Relac.id, tbCsllM350Relac)
                        .subscribe(() => {
                                     planoContas.tbCsllM350Relac.cadCcus = this.clone(tbCsllM350Relac.cadCcus);

                                     this.tbCsllM350RelacList.find(r => r.id === planoContas.tbCsllM350Relac.id)
                                                             .cadCcus = this.clone(tbCsllM350Relac.cadCcus);

                                     this.update();
                                   },
                                   error => this.httpService.handleError(error, () => this.update()),
                                   () => this.httpService.done());
      }
    }
  }

  remove(tbCsllM350Relac?: TbCsllM350Relac) {
    const ids: Array<number> = new Array();

    if (tbCsllM350Relac) {
      if (!confirm('Deseja excluir o relacionamento selecionado?')) {
        return;
      }

      this.tbCsllM350RelacList.filter(r => r.tbCsllM350Adicao.id === tbCsllM350Relac.tbCsllM350Adicao.id &&
                                           r.tbCsllM350Exclusao.id === tbCsllM350Relac.tbCsllM350Exclusao.id)
                              .forEach(r => ids.push(r.id));
    } else {
      const oldItems = this.clone(this.planoContasService.toRemove(this.planoContasList, this.planoContasSelectedList));
      oldItems.forEach(oldItem => {
        ids.push(this.tbCsllM350RelacList.find(r => r.planoContas.id === oldItem.id).id);
      });
    }

    this.httpService.wait();
    this.httpService.post('/custom/ecd/delete-tbcsllm350relac(' + this.year + ')', ids)
                    .subscribe(() => {
                                 this.tbCsllM350RelacList = this.tbCsllM350RelacList.filter(r => ids.indexOf(r.id) === -1);

                                 this.planoContasList.filter(p => ids.indexOf(p.id) > -1).forEach(p => {
                                   p.selected = false;
                                 });

                                 this.loadTbCsllM350();

                                 this.setTbCsllM350ListUsed();
                               },
                               error => this.httpService.handleError(error, () => this.remove()),
                               () => this.httpService.done());
  }

  get removeDisabled(): boolean {
    return !this.planoContasSelectedList ||
           this.planoContasService.toRemove(this.planoContasList, this.planoContasSelectedList).length < 1;
  }

  planoContasDisabled(planoContas: PlanoContas): boolean {
    return this.planoContasService.disabled(this.planoContasSelectedList, planoContas) ||
           (planoContas && planoContas.tbCsllM350Relac && planoContas.tbCsllM350Relac.id !== undefined);
  }

  planoContasListStyleClass(planoContas: PlanoContas): string {
    let classes = '';

    if (planoContas.indCta === 'S') {
      classes += 'bold ';
    }

    return classes.trim();
  }

  tbCsllM350ListStyleClass(tbCsllM350: TbCsllM350): string {
    let classes = '';


    if (tbCsllM350.tipo !== 'E') {
      classes += 'bold locked ';
    }

    if (tbCsllM350.used) {
      classes += 'line-through locked ';
    }


    return classes.trim();
  }

  private clone(object: any) {
    if (object === undefined || object === null) {
      return object;
    }

    return JSON.parse(JSON.stringify(object));
  }

  /*private toolbarRefresh() {
    /*this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }*/

  get title(): string {
    return this.titleService.title;
  }

  get year(): number {
    return this.dblinkedSessionService.periodo.year.value;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  onFiltertbCsllM350List(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }

  onFilterplanoContasList(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }

  onFilterplanoContasSelectedList(event: any) {
    this.contadorLinhaSelected = event.filteredValue.length;
  }

  onFiltertbCsllM350RelacListDistinct(event: any) {
    this.contadorLinhaMapeadas = event.filteredValue.length;
  }


}

