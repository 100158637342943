import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
  selector: 'app-teste',
  templateUrl: './teste.component.html',
  styleUrls: ['./teste.component.scss'],
})

export class TesteComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  titlePrincipal: string;
  subtitle: string;
  infosPedido: any;
  msgs: Message[] = [];



  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private activatedRoute: ActivatedRoute,
    private dblinkedSessionService: DblinkedSessionService
  ) {}

  ngOnInit(): void {
    this.titleService.title = 'Título de página';
    this.titlePrincipal = 'titulo';
    this.subtitle = 'subtitulo';
    // this.messageService.add({ severity :'info', summary:'Success', detail:'Form Submitted' });

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
   // this.toolbarRefresh();

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.listaPedido();
      }
    );


  }


  listaPedido() {
    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

      this.httpService.wait();
      this.httpService.get('/custom/pedido/get-pedidos-table(' + this.dblinkedSessionService.empresa.id + ',' + this.dblinkedSessionService.periodo.month.value + ',' + this.dblinkedSessionService.periodo.year.value + ')').subscribe(
        pedidos => {
          this.infosPedido = pedidos;
        },
        error => this.httpService.handleError(error, () => this.listaPedido()),
        () => this.httpService.done()
      );

    }
  }



  ngOnDestroy() {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }



  change() {
    if (!this.dblinkedSessionService.hasEmpresaSelected || !this.dblinkedSessionService.hasYearSelected) {
      return;
    }
  }



  onFilter($event) {
    console.log($event);
  }


  print1() {
    alert('print');
  }

  print(selector: string = 'container-impressao') {
    document.getElementById('div-botoes').style.display = 'none';
    this.utilService.print(selector);
  }

  private toolbarRefresh() {
    this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }



  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

}
