import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { HttpHeaders, HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-importacao-ofx',
  templateUrl: './importacao-ofx.component.html',
  styleUrls: ['./importacao-ofx.component.scss'],
})

export class ImportacaoOfxComponent implements OnInit, OnDestroy {

  //////////////////////////////
  // Refator SIMON LALLEMENT  //
  // 23/07/2021               //
  //////////////////////////////

  //String
  title: any

  //Messages
  msgs: Message[] = []

  //File
  file: File = null

  //Array
  liTitulosAVerificar: Array<any> = new Array()

  //tipos de importação
  opcoesImportacao: SelectItem[] = new Array();
  tipoImportacao: number;

  //Elements in-page
  @ViewChild('file') fileInput: ElementRef

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpClient: HttpClient, private messageService: MessageService
  ) { }

  ngOnInit() {
    this.title = 'ARQUIVOS'
    this.titleService.title = 'Importar arquivo'
    this.toolbarService.hidden = true
    this.opcoesImportacao = [
      { label: 'Extrato OFX', value: 1 },
      { label: 'Movimentação bancária', value: 2 }
    ];
    this.tipoImportacao = 1;
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0]
  }

  importar() {
    switch (this.tipoImportacao) {
      case 1:
        this.importarOfx(true);
        break;

      case 2:
        this.importarMovimentacoes();
        break;
    }
  }

  importarOfx(verif_ofx: boolean=false) {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      const formData = new FormData()
      formData.append('file', this.file)

      this.exibirMensagem({ severity: "info", message: "Importação em andamento. Pode demorar alguns minutos." })
      this.httpService.wait()
      this.httpService.post(this.configService.defaultUrlApiUpload + 'ofx/read_ofx_file/' + this.dblinkedSessionService.empresa.id + "_" + this.sessionService.loggedUser.id + "_" + verif_ofx, formData)
        .subscribe(result => {
          if (result["severity"] === "success") {
            this.liTitulosAVerificar = result["data"]

          } else if (result["severity"] === "info") {
            if (confirm("Existem transações no mesmo período para essa conta, deseja sobrepôr?")) {
              this.importarOfx()
            }
            this.httpService.done()
            return
          }

          this.exibirMensagem(result)
          this.httpService.done()

          if (this.liTitulosAVerificar.length > 0) {
            this.conciliarTitulos()
          }
        },
          error => {
            console.log(error)
            this.exibirMensagem({ severity: "error", message: "Erro no API, contate o suporte : " + error.message })
            this.httpService.done()
          })

    } else {
      this.exibirMensagem({ severity: "warn", message: "Selecione uma empresa primeiro." })
    }
  }

  importarMovimentacoes() {
    if (!this.dblinkedSessionService.hasEmpresaSelected) return this.exibirMensagem({ severity: "warn", message: "Selecione uma empresa primeiro." });

    const httpOptions = {
      headers: new HttpHeaders({
        'userid': this.sessionService.loggedUser.id.toString(),
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'Authorization': this.configService.bearerApi,
      })
    };

    const formData = new FormData();
    formData.append('file', this.file);
    this.exibirMensagem({ severity: "info", message: "Upload do financeiro em andamento... pode demorar alguns minutos." })
    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'financeiro/upload-mov', formData, httpOptions)
      .subscribe(
        res => {
          if (res['error']) {
            this.exibirMensagem({severity: "warn", message: res["mensagem"]})
            if (res['data']) {
              const httpOptions2 = {
                headers: new HttpHeaders({
                  'Authorization': this.configService.bearerApi,
                  'Cache-Control': 'no-store, max-age=0'
                }),
                responseType: 'blob' as 'json'
              };

              this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/upload-mov', httpOptions2)
                .subscribe(
                  (res: Blob) => {
                    let link = document.createElement('a');
                    let url = window.URL.createObjectURL(res);
                    let fileName = "log.txt";
                    link.href = url;
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    this.httpService.done();
                  },
                  err => {
                    this.exibirMensagem({ severity: "error", mensagem: "Erro no API: não foi possível baixar o log: " + err.message });
                    console.log(err);
                    this.httpService.done();
                  });
            } else {
              this.httpService.done();
            }

          } else {
            this.exibirMensagem(res);
            this.httpService.done();
          }
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro o API, entre em contato com o suporte: " + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  conciliarTitulos() {
    let listaEnviar = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id,
      listaFinanceiro: this.liTitulosAVerificar
    }

    this.exibirMensagem({ severity: "info", message: "Conciliação em andamento..." })
    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/gerar-baixa-ofx', listaEnviar)
      .subscribe(
        res => {
          if (res['error']) {
            this.exibirMensagem(res);
            if (res['data']) {
              const httpOptions2 = {
                headers: new HttpHeaders({
                  'Authorization': this.configService.bearerApi,
                  'Cache-Control': 'no-store, max-age=0'
                }),
                responseType: 'blob' as 'json'
              };

              this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/gerar-baixa-ofx', httpOptions2)
                .subscribe(
                  (res: Blob) => {
                    let link = document.createElement('a');
                    let url = window.URL.createObjectURL(res);
                    let fileName = "log.txt";
                    link.href = url;
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    this.httpService.done();
                  },
                  err => {
                    this.exibirMensagem({ severity: "error", mensagem: "Erro no API, não foi possível baixar o log: " + err.message });
                    console.log(err);
                    this.httpService.done();
                  });
            } else {
              this.httpService.done();
            }

          } else {
            this.exibirMensagem(res);
            this.httpService.done();
          }
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro o API ao conciliar os títulos, entre em contato com o suporte: " + err.message });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  exibirMensagem(obj) {
    if (obj.severity === "error") {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem
      })

    } else if (obj.severity === "warn") {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem
      })

    } else if (obj.severity === "info") {
      this.messageService.add({
        severity: 'info', key: 'messageLinnks', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem
      })

    } else {
      this.messageService.add({
        severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem
      })
    }
  }

  hideMessage() {
    this.msgs = []
  }

  onChangeImportacao() {
    this.file = null;
  }
}
