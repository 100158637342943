import { View } from 'app/cadastro/model/view.model';
import { ProjetoDbl } from 'app/cadastro/model/projeto-dbl.model';

export class ViewProjetoDblRelac {

  id: number;
  view: View;
  projetoDbl: ProjetoDbl;

constructor(viewprojetodblrelac?: ViewProjetoDblRelac | number) {
    if (viewprojetodblrelac !== undefined && viewprojetodblrelac !== null) {
      if (typeof viewprojetodblrelac === 'number') {
        this.id = viewprojetodblrelac;
      } else {
        this.id = viewprojetodblrelac.id;
        this.view = viewprojetodblrelac.view;
        this.projetoDbl = viewprojetodblrelac.projetoDbl;
      }
    }
  }
}
