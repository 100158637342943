import { isNullOrUndefined } from "util";
import { Consultor} from "app/eventos/model/consultor.model"


export class Carteira{

  id                : number;
  nome              : string;
  consultor         : Consultor;
  backoffice        : Carteira;
  recebeAutomatico  : boolean;


  constructor(carteira?: Carteira | number) {
    if (!isNullOrUndefined(carteira)) {
      if (typeof carteira === 'number') {
        this.id = carteira;
      } else {
        this.id         = carteira.id;
        this.consultor  = carteira.consultor;
        this.nome       = carteira.nome;
        this.backoffice = carteira.backoffice;
        this.recebeAutomatico = carteira.recebeAutomatico;

      }
    }
  }

}