export enum Action {
  AUTH = 'auth',
  COMMENT = 'comment',
  ATTACHMENT = 'attachment',
  ACTIVITY = 'activity',
  APPROVE = 'approve',
  HISTORIC = 'historic',
  FINANCIAL = 'financial',
  ORDER = 'order',
  RMS = 'rms',
  NULL = 'not informed',
  LOADING = 'loading'
}