import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { PlanoContasRfb } from '../model/plano-contas-rfb.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { PlanoGerencial } from '../model/plano-gerencial.model';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { HttpService } from '../../shared/services/http.service';
import { SessionService } from '../../shared/services/session.service';
import { Empresa } from '../../cadastro/model/empresa.model';


@Component({
  selector: 'app-planogerencial',
  templateUrl: './planogerencial.component.html',
  styleUrls: ['./planogerencial.component.scss'],
  providers: [CrudService]
})
export class PlanoGerencialComponent extends DataTableBasicComponent implements OnInit {
  public planogerencial: PlanoGerencial = new PlanoGerencial();

  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;

  entityFilter: string = null;


  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  ngOnInit() {

    this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((planogerencial: PlanoGerencial) => {

      planogerencial.empresa = new Empresa(this.dblinkedSessionService.empresa.id);

      console.log(planogerencial);
    });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
      () => { this.empresaChanged(); });

  }

  empresaChanged() {

    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.entityFilter = 'empresa/id eq ' + this.dblinkedSessionService.empresa.id;

    this.columns = [
     new DataTableColumn('Codigo', 'codigo'),
     new DataTableColumn('Nome', 'nome'),
     new DataTableColumn('Níveis', 'niveis'),
     new DataTableColumn('Moeda', 'moeda.codigo'),

    ];

    this.controls = [
        new DataControl('Codigo', 'codigo')
        .setRequired()
        .setMaxLength(10),
        new DataControl('Nome', 'nome')
        .setRequired()
        .setMaxLength(150),
        new DataControl('Níveis', 'niveis')
        .setRequired()
        .setMaxLength(1),
        new DataControl('Moeda', 'moeda')
        .setTypeSelect()
        .setOptions({ url: '/moeda', value: 'id', label: 'codigo' }),
        new DataControl('View', 'view')
        .setTypeSelect()
        .setOptions({ url: '/view', value: 'id', label: 'nome' })

    ];
  }
}

get hasEmpresaSelected(): boolean {
  return this.dblinkedSessionService.hasEmpresaSelected;
}

get loading(): boolean {
  return this.httpService.loading;
}

}
