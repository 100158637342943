export class ToolbarButton {
    key?: string;
    visible?: boolean;
    disabled?: boolean;
    icon: string;
    name?: string;
    label?: string;
    callback?: Function;
    child?: Array<ToolbarButton>;

    constructor(key: string, icon: string, name: string, label?: string, callback?: Function, visible?: boolean, disabled?: boolean) {
        this.key = key;
        this.disabled = disabled;
        this.icon = icon;
        this.name = name;
        this.label = label;
        this.callback = callback || null;
        this.visible = visible || true;
    }
}
