import { Component, OnInit } from '@angular/core';
import { MovBalancete } from '../model/mov-balancete.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'app-mov-balancete',
  templateUrl: './mov-balancete.component.html',
  styleUrls: ['./mov-balancete.component.scss'],
  providers: [CrudService]
})
export class MovBalanceteComponent extends DataTableBasicComponent implements OnInit {
  public movBalancete: MovBalancete = new MovBalancete();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
    new DataTableColumn('Cod Cta', 'codCta'),
    new DataTableColumn('Desc Cta', 'descrCta'),
    new DataTableColumn('Saldo Ini', 'sldIni'),
    new DataTableColumn('Ini D/C', 'indDcSldIni'),
    new DataTableColumn('Valor Deb', 'vlDeb'),
    new DataTableColumn('Valor Cred', 'vlCred'),
    new DataTableColumn('Saldo Fin', 'sldFin'),
    new DataTableColumn('Fin D/C', 'indDcSldFin'),
    new DataTableColumn('Empresa', 'empresa.razaoSocial'),
    new DataTableColumn('Data Ini', 'dtIni').setTypeDate(),
    new DataTableColumn('Data Fin', 'dtFin').setTypeDate()

    ];

    this.controls = [
    new DataControl('Cod Cta', 'codCta')
        .setRequired()
        .setMinLength(2)
        .setMaxLength(45),
    new DataControl('Desc Cta', 'descrCta')
        .setRequired()
        .setMinLength(2)
        .setMaxLength(255),
    new DataControl('Saldo Ini', 'sldIni')
        .setTypeNumber()
        .setRequired(),
    new DataControl('Ini D/C', 'indDcSldIni')
        .setRequired()
        .setMinLength(1)
        .setMaxLength(1),
    new DataControl('Valor Deb', 'vlDeb')
        .setTypeNumber()
        .setRequired(),
    new DataControl('Valor Cred', 'vlCred')
        .setTypeNumber()
        .setRequired(),
    new DataControl('Saldo Fin', 'sldFin')
        .setTypeNumber()
        .setRequired(),
    new DataControl('Fin D/C', 'indDcSldFin')
        .setRequired()
        .setMinLength(1)
        .setMaxLength(1),
    new DataControl('Empresa', 'empresa')
        .setTypeSelect()
        .setOptions({ url: '/empresa', value: 'id', label: 'razaoSocial' }),
    new DataControl('Data Ini', 'dtIni')
        .setTypeDate()
        .setRequired(),
    new DataControl('Data Fin', 'dtFin')
        .setTypeDate()
        .setRequired()

    ];
  }
}
