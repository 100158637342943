import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Table } from 'primeng/table';
import { Subscription } from "rxjs/Subscription";
import { HttpService } from "app/shared/services/http.service";
import { ConfigService } from "app/shared/services/config.service";
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Message, MessageService, SelectItem } from "primeng/api";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardRH implements OnInit, OnDestroy {

  modalInclusion: boolean = false;
  modalEdition: boolean = false;
  modalFilter: boolean = false;
  fullTable: boolean = false;
  allowsGroup: boolean = false;
  showVersionOptions: boolean = true;
  editionState: boolean = false;
  isFiltered: boolean = false;

  functionOptions: SelectItem[] = [];
  levelOptions: SelectItem[] = [];
  optionsKey1: SelectItem[] = [];
  optionsKey2: SelectItem[] = [];
  optionsKey3: SelectItem[] = [];

  allccus: any;
  allarea: any;
  allproj: any;
  allsubarea: any;
  allfunctions: any;
  alllevels: any;
  usedccus: any;
  usedarea: any;
  usedproj: any;
  usedsubarea: any;
  filterccus: any;
  filterarea: any;
  filterproj: any;
  filtersubarea: any;

  companyOptions: SelectItem[] = [];
  selectedCompanyOptions: SelectItem[] = [];

  tableValues: Array<any> = new Array();
  companysToEdit: Array<any> = new Array();
  tableDeleted: Array<any> = new Array();
  tableEdited: Array<any> = new Array();
  filterTableValues: Array<any> = new Array();
  tableInclusion: Array<any> = new Array();
  tableEdition: Array<any> = new Array();
  editionColumn: Array<any> = new Array();
  rowSelected: Array<any> = new Array();
  versionsList: Array<any> = [];

  months: Array<any> = [
    ["Jan", "Janeiro"],
    ["Fev", "Fevereiro"],
    ["Mar", "Março"],
    ["Abr", "Abril"],
    ["Mai", "Maio"],
    ["Jun", "Junho"],
    ["Jul", "Julho"],
    ["Ago", "Agosto"],
    ["Set", "Setembro"],
    ["Out", "Outubro"],
    ["Nov", "Novembro"],
    ["Dez", "Dezembro"],
  ];

  filtersData = {
    ccus_id: [],
    proj_id: [],
    area_id: [],
    subarea_id: [],
  }

  aboveOptions = {
    key1: [],
    key2: [],
    key3: [],
  }

  keysInfo: any;
  levels: number = 0
  selectedCompany: any = [];
  toFilterCompany: any = [];
  loggedUserId: number;
  selectedVersionId: number;
  currentYear: string;

  toolbarMainIni = [
    { color: 'default', icon: 'arrow_back_ios', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: false, onClick: () => this.backToOptions() },
    { color: 'default', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, onClick: () => this.export() },
    { color: 'red', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, onClick: () => this.removeBatch() },
    { color: 'default', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, onClick: () => this.changeState('edition') },
    { color: 'default', icon: 'add', text: 'Adicionar', tooltip: 'Adicionar', visible: true, disabled: false, onClick: () => this.changeState('inclusion') },
    { color: 'green', icon: 'save', text: 'Salvar', tooltip: 'Salvar Edição', visible: true, disabled: false, onClick: () => this.saveAllLines() },
  ]

  pt_BR = {
    firstDayOfWeek: 0,
    dayNames: [
      'Domingo',
      'Segunda-Feira',
      'Terça-Feira',
      'Quarta-Feira',
      'Quinta-Feira',
      'Sexta-Feira',
      'Sábado'
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez'
    ],
    today: 'Hoje',
    clear: 'Limpar'
  };

  public toolbarMain = new Toolbar();
  private sessionSubscription: Subscription;

  constructor(
    private toolbarService: ToolbarService,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private messageService: MessageService,
    private httpClient: HttpClient,) {
    this.toolbarService.hidden = true
  }

  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni);
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
          this.loggedUserId = this.sessionService.loggedUser.id;
          this.toFilterCompany = [this.dblinkedSessionService.empresa.id];
          this.selectedCompany = this.toFilterCompany;
          this.currentYear = this.dblinkedSessionService.periodo.year.label;
          this.getCompanys();
          this.getVersionList();
          this.getCompanys();
          this.backToOptions();
        }
      }
    );

  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  convertId(id, type) {
    let response = ''
    switch (type) {
      case 'ccus_id':
        this.selectedCompany.forEach(compid => {
          if (this.allccus[compid] != undefined)
            this.allccus[compid].forEach(item => {
              if (item.id == id)
                response = item.descricao
            });
        })
        break;
      case 'proj_id':
        this.selectedCompany.forEach(compid => {
          if (this.allproj[compid] != undefined)
            this.allproj[compid].forEach(item => {
              if (item.id == id)
                response = item.descricao
            });
        })
        break;
      case 'area_id':
        this.selectedCompany.forEach(compid => {
          if (this.allarea[compid] != undefined)
            this.allarea[compid].forEach(item => {
              if (item.id == id)
                response = item.descricao
            });
        })
        break;
      case 'subarea_id':
        this.selectedCompany.forEach(compid => {
          if (this.allsubarea[compid] != undefined)
            this.allsubarea[compid].forEach(item => {
              if (item.id == id)
                response = item.descricao
            });
        })
        break;
      case 'cargo':
        this.selectedCompany.forEach(compid => {
          if (this.allfunctions[compid] != undefined)
            this.allfunctions[compid].forEach(item => {
              if (item.id == id)
                response = item.descricao
            });
        })
        break;
      case 'nivel':
        this.selectedCompany.forEach(compid => {
          if (this.alllevels[compid] != undefined)
            this.alllevels[compid].forEach(item => {
              if (item.id == id)
                response = item.descricao
            });
        })
        break;
      case 'company':
        this.companyOptions.forEach(item => {
          if (item.value == id)
            response = item.label
        });
        break;
    }
    return response
  }

  resetVars() {
    this.tableValues = [];
    this.tableDeleted = [];
    this.tableInclusion = [];
    this.tableEdition = [];
    this.rowSelected = [];
    this.tableEdited = [];
    this.toolbarBatch();
  }

  backToOptions() {
    this.resetVars();
    this.toFilterCompany = [this.dblinkedSessionService.empresa.id]
    this.selectedCompany = this.toFilterCompany
    this.filtersData = {
      ccus_id: [],
      proj_id: [],
      area_id: [],
      subarea_id: [],
    }
    this.onFilter({ filters: [] })
    this.showVersionOptions = true;
  }

  onFilter(e) {
    if (Object.keys(e.filters).length != 0) {
      this.isFiltered = true;
      this.filterTableValues = e.filteredValue;
    } else this.isFiltered = false;
  }

  saveNewLines(table, edition = false) {
    const data = {
      premissas: this.changeTableDate(table),
      versao_id: this.selectedVersionId,
      empresas: this.selectedCompany
    };

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + "rh/dashboard/premissas", data)
      .subscribe(
        (res: any) => {
          if (!res["error"]) {
            if (edition) {
              res["data"].forEach(element => {
                this.tableValues = this.tableValues.map(item => {
                  if (element.id == item.id) {
                    item = element
                  }
                  return item
                })
              })
              this.tableEdited = [];
            } else {
              res["data"].forEach(element => this.tableValues.push(element))
            }
            this.exibirMensagem(res)
          } else {
            this.exibirMensagem({ "error": true, "mensagem": "Erro ao salvar os novos funcionários, contate o suporte: " + res.mensagem })
          }
          this.httpService.done();
          this.rowSelected = [];
          this.table.reset();
          this.onFilter({ filters: [] });
          this.toolbarBatch();
        },
        (err) => {
          this.httpService.done()
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao salvar os novos funcionários, contate o suporte: " + err.message })
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({
        severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: value.mensagem
      });
    } else {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Erro  ', detail: value.mensagem
      });
    }
  }

  saveAllLines() {
    const infos = {
      premissas: this.changeTableDate(this.tableValues),
      versao_id: this.selectedVersionId,
      empresas: this.selectedCompany,
      user_id: this.loggedUserId,
      litodelete: this.tableDeleted,
    };

    this.httpService.wait()
    this.httpService.put(this.configService.defaultUrlApiUpload + "rh/dashboard/premissas", null, infos)
      .subscribe(
        (res: any) => {
          if (res["error"]) {
            this.exibirMensagem(res);
          } else {
            this.getInformations()
          }
          this.httpService.done();
          this.exibirMensagem({ "error": false, "mensagem": "Salvo com Sucesso" });
        },
        (err) => {
          this.httpService.done();
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao gravar as premissas no banco de dados, contate o suporte: " + err.message });
        }
      );
  }

  changeTableDate(array) {
    return array.map((element, index) => {
      element.data_fin = this.convertDate(element.data_fin)
      element.data_ini = this.convertDate(element.data_ini)
      if (element.dt_reajuste) element.dt_reajuste = this.convertDate(element.dt_reajuste)
      return element
    });
  }

  convertFormat(array, id) {
    let response = []
    if (array[id] !== undefined)
      array[id].forEach(item => response.push({ label: `${item.codigo} - ${item.descricao}`, value: item.id }))
    return response
  }

  getFiltersInformations(versionId, canGroup, modalFilter, getInfos, forFilters) {
    this.selectedVersionId = versionId;
    this.allowsGroup = canGroup;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresas': this.selectedCompany.toString(),
        'versaoid': this.selectedVersionId.toString(),
        'userid': this.loggedUserId.toString()
      })
    };
    this.httpService.wait()
    this.httpClient.get(this.configService.defaultUrlApiUpload + "rh/dashboard/listas_infos", httpOptions)
      .subscribe(
        (res: any) => {
          if (res["error"]) {
            this.exibirMensagem(res);
          } else {
            this.modalFilter = modalFilter
            this.keysInfo = res.data.dicvisu
            this.levels = this.keysInfo.niveis
            if (forFilters) {
              this.filterccus = res.data.ccusinpremissa
              this.filterarea = res.data.areasinpremissa
              this.filterproj = res.data.projetosinpremissa
              this.filtersubarea = res.data.subareasinpremissa
              this.setOptions(true)
            }
            if (!forFilters || getInfos) {
              this.allccus = res.data.ccusnotinpremissa
              this.allarea = res.data.areasnotinpremissa
              this.allproj = res.data.projetosnotinpremissa
              this.allsubarea = res.data.subareasnotinpremissa
              this.usedccus = res.data.ccusinpremissa
              this.usedarea = res.data.areasinpremissa
              this.usedproj = res.data.projetosinpremissa
              this.usedsubarea = res.data.subareasinpremissa
              this.allfunctions = res.data.cargos
              this.alllevels = res.data.niveis
              this.setFilters()
              this.getInformations()
            }

          }
          this.httpService.done();
        },
        (err) => {
          this.httpService.done();
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar as premissas, contate o suporte: " + err.message });
        }
      );
  }

  convertAllFormat(array: any) {
    let response = []
    this.selectedCompany.forEach(id => {
      if (array[id] !== undefined)
        array[id].forEach(item => response.push({ label: `${item.codigo} - ${item.descricao}`, value: item.id }))
    });
    return response
  }

  setFilters() {
    this.functionOptions = this.getKeyOptions('function', 0, false, true)
    this.levelOptions = this.getKeyOptions('level', 0, false, true)
    if ([1, 2, 3].includes(this.levels)) {
      this.optionsKey1 = this.getKeyOptions(this.keysInfo['1'][1], 0, false, true)
      if (this.modalFilter) this.optionsKey1.forEach(element => this.filtersData[this.keysInfo['1'][1]].push(element.value))
    }
    if ([2, 3].includes(this.levels)) {
      this.optionsKey2 = this.getKeyOptions(this.keysInfo['2'][1], 0, false, true)
      if (this.modalFilter) this.optionsKey2.forEach(element => this.filtersData[this.keysInfo['2'][1]].push(element.value))
    }
    if (3 == this.levels) {
      this.optionsKey3 = this.getKeyOptions(this.keysInfo['3'][1], 0, false, true)
      if (this.modalFilter) this.optionsKey3.forEach(element => this.filtersData[this.keysInfo['3'][1]].push(element.value))
    }
  }

  groupingOptions(key, update) {
    let aux = []
    //vir
    this.selectedCompany.forEach((compid, index) => {
      if (index == 0) {
        this.getSelectedKey(this.keysInfo[key][1])[compid].forEach(item =>
          aux.push({ label: `${item.codigo} - ${item.descricao}`, value: item.id, cod: item.codigo })
        );
      } else {
        this.getSelectedKey(this.keysInfo[key][1])[compid].forEach(item => {
          let trigger = null;
          aux.forEach((elem, idx) => {
            if (item.codigo == elem.cod) trigger = idx;
          })
          if (trigger != null) {
            if (Array.isArray(aux[trigger].value)) aux[trigger].value = [item.id, ...aux[trigger].value]
            else aux[trigger].value = [item.id, aux[trigger].value]

          } else aux.push({ label: `${item.codigo} - ${item.descricao}`, value: item.id, cod: item.codigo })
        })
      }
    });

    this.aboveOptions['key' + key] = aux

    if (update) {
      if ([2, 3].includes(this.levels)) this.formatAboveFilters('1', '2', this.keysInfo['2'][1])
      if (this.levels === 3) this.formatAboveFilters('2', '3', this.keysInfo['3'][1])
    }

  }

  setOptions(update) {
    this.filtersData = {
      ccus_id: [],
      proj_id: [],
      area_id: [],
      subarea_id: [],
    }
    if ([1, 2, 3].includes(this.levels)) {
      this.groupingOptions('1', update);
      this.aboveOptions.key1.forEach(element => this.filtersData[this.keysInfo['1'][1]].push(element.value))
    }
    if ([2, 3].includes(this.levels)) {
      this.groupingOptions('2', update);
      this.aboveOptions.key2.forEach(element => this.filtersData[this.keysInfo['2'][1]].push(element.value))
    }
    if (this.levels === 3) {
      this.groupingOptions('3', update);
      this.aboveOptions.key3.forEach(element => this.filtersData[this.keysInfo['3'][1]].push(element.value))
    }
  }

  getKeyOptions(key, id, filter = false, all = false) {
    if (id == 0)
      id = this.dblinkedSessionService.empresa.id
    let response = []
    switch (key) {
      case 'ccus_id':
        response = all ? this.convertAllFormat(this.usedccus) : this.convertFormat(!filter ? this.allccus : this.usedccus, id);
        break;
      case 'proj_id':
        response = all ? this.convertAllFormat(this.usedproj) : this.convertFormat(!filter ? this.allproj : this.usedproj, id);
        break;
      case 'area_id':
        response = all ? this.convertAllFormat(this.usedarea) : this.convertFormat(!filter ? this.allarea : this.usedarea, id);
        break;
      case 'subarea_id':
        response = all ? this.convertAllFormat(this.usedsubarea) : this.convertFormat(!filter ? this.allsubarea : this.usedsubarea, id);
        break;
      case 'function':
        response = all ? this.convertAllFormat(this.allfunctions) : this.convertFormat(this.allfunctions, id);
        break;
      case 'level':
        response = all ? this.convertAllFormat(this.alllevels) : this.convertFormat(this.alllevels, id);
        break;
    }
    return response
  }


  getVersionList() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'ano': this.currentYear,
      })
    };
    this.httpService.wait()
    this.httpClient.get(this.configService.defaultUrlApiUpload + "rh/dashboard/lista_versoes", httpOptions)
      .subscribe(
        (res: any) => {
          if (res["error"]) {
            this.exibirMensagem(res);
          } else {
            this.versionsList = res.data
          }
          this.httpService.done();
        },
        (err) => {
          this.httpService.done();
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar as versões de orçamento, contate o suporte: " + err.message });
        }
      );
  }

  setArr(arr: any) {
    let res = [];
    arr.forEach(item => {
      if (Array.isArray(item)) {
        res.push(...item)
      } else {
        res.push(item)
      }
    })
    return res
  }

  getInformations() {
    if ([1, 2, 3].includes(this.levels) && this.filtersData[this.keysInfo['1'][1]].length === 0) {
      this.exibirMensagem({ error: true, mensagem: "Selecionar pelo menos um item na lista de " + this.keysInfo['1'][0] })
      return
    }

    if ([2, 3].includes(this.levels) && this.filtersData[this.keysInfo['2'][1]].length === 0) {
      this.exibirMensagem({ error: true, mensagem: "Selecionar pelo menos um item na lista de " + this.keysInfo['2'][0] })
      return
    }

    if (this.levels === 3 && this.filtersData[this.keysInfo['3'][1]].length === 0) {
      this.exibirMensagem({ error: true, mensagem: "Selecionar pelo menos um item na lista de " + this.keysInfo['3'][0] })
      return
    }

    const infos = {
      empresas: this.toFilterCompany,
      versaoid: this.selectedVersionId,
      areas: this.setArr(this.filtersData.area_id),
      ccus: this.setArr(this.filtersData.ccus_id),
      subareas: this.setArr(this.filtersData.subarea_id),
      proj: this.setArr(this.filtersData.proj_id)
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + "rh/dashboard/getpremissas", infos)
      .subscribe(
        (res: any) => {
          if (res["error"]) {
            this.exibirMensagem(res);
          } else {
            this.tableValues = res.data.lifunc;
            this.getCompanyOptions()
            this.showVersionOptions = false;
            this.renderEditionColumn();
          }
          this.httpService.done();
        },
        (err) => {
          this.httpService.done();
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar os filtros, contate o suporte: " + err.message });
        }

      );

  }

  onCompanyChange() {
    this.selectedCompany = this.toFilterCompany;
    this.getFiltersInformations(this.selectedVersionId, this.allowsGroup, false, false, true);
  }

  update() {
    this.selectedCompany = this.toFilterCompany;
    this.getFiltersInformations(this.selectedVersionId, this.allowsGroup, false, true, false);
    this.resetVars();
  }

  getSelectedKey(key) {
    let response;
    switch (key) {
      case 'ccus_id':
        response = this.filterccus
        break;
      case 'proj_id':
        response = this.filterproj
        break;
      case 'area_id':
        response = this.filterarea
        break;
      case 'subarea_id':
        response = this.filtersubarea
        break;
    }
    return response
  }

  formatAboveFilters(active, changed, key) {
    let optionsKey = []
    let aux = this.filtersData[this.keysInfo[changed][1]]
    this.filtersData[this.keysInfo[changed][1]] = []
    if (this.filtersData[this.keysInfo[active][1]].length > 0) {
      this.filtersData[this.keysInfo[changed][1]] = aux
      this.selectedCompany.forEach(compid => {
        console.log(this.getSelectedKey(key), compid)
        this.getSelectedKey(key)[compid].forEach(item =>
          item[this.keysInfo[active][1]].forEach(itemId =>
            this.filtersData[this.keysInfo[active][1]].forEach(keyId => {
              if (Array.isArray(keyId)) {
                keyId.forEach(arrId => {
                  if (itemId === arrId) optionsKey.push({ label: `${item.codigo} - ${item.descricao}`, value: item.id })
                })
              }
              if (itemId === keyId) optionsKey.push({ label: `${item.codigo} - ${item.descricao}`, value: item.id })
            })
          )
        )
      })
      if (optionsKey.length) this.aboveOptions['key' + changed] = optionsKey
      else this.groupingOptions(changed, false)
    } else this.groupingOptions(changed, false)
  }

  setAboveFilters(key, level) {
    //vir
    switch (level) {
      case 0:
        if ([2, 3].includes(this.levels)) this.formatAboveFilters('1', '2', this.keysInfo['2'][1])
        if (this.levels === 3) this.formatAboveFilters('2', '3', this.keysInfo['3'][1])
        break
      case 1:
        this.formatAboveFilters('1', '2', key)
        break;
      case 2:
        this.formatAboveFilters('2', '3', key)
        break;
    }
  }

  getCompanyOptions() {
    if (this.selectedCompany.length > 1) {
      let response = []
      this.selectedCompany.forEach(id => {
        this.companyOptions.forEach(item => { if (item.value == id) response.push(item) })
      });
      this.selectedCompanyOptions = response
    }
  }

  addInclusionLine() {
    this.tableInclusion.push({
      key1: '',
      key2: '',
      key3: '',
      qtd: 1,
      empresa_id: this.dblinkedSessionService.empresa.id,
      nome: '',
      cargo_atu_id: '',
      nivel_atu_id: '',
      data_fin: '',
      data_ini: '',
      lineId: this.idv4(),
    })
  }

  addEditionLine() {
    this.tableEdition.push({
      column: '',
      value: '',
      dt_reajuste: '',
      demissao: false,
      valor_rescisao: 0,
      empresa_id: this.dblinkedSessionService.empresa.id,
      lineId: this.idv4(),
      columnDropdown: true,
      columnCalendar: false,
      columnWage: false,
      rescisao: false,
      startDate: false,
      columnDateIni: false,
    })
  }

  removeInclusionLine(id) {
    this.tableInclusion = this.tableInclusion.filter(line => line.lineId != id)
  }

  removeEditionLine(id) {
    this.tableEdition = this.tableEdition.filter(line => line.lineId != id)
  }
  @ViewChild('dt') table: Table;
  removeFromMain(id) {
    this.tableValues = this.tableValues.filter(line => line.id != id)
    this.tableDeleted.push(id)
    this.table.reset()
    this.onFilter({ filters: [] })
  }

  saveInclusion() {
    let data = []
    this.tableInclusion.forEach(person => {
      for (let i = 1; i <= person.qtd; i++) {
        let obj = {
          ...person,
          data_fin: person.data_fin,
          data_ini: person.data_ini,
        }
        for (let i = 1; i <= this.levels; i++) {
          obj[this.keysInfo[i][1]] = person['key' + i]
        }
        data.push(obj)
      }
    })
    this.saveNewLines(data)
    this.modalInclusion = false;
  }

  convertDate(str) {
    if (!/^\d{4}-\d{2}-\d{2}/.test(str)) {
      str = str.toString().slice(0, 16)
      let date = new Date(str);
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      let day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;
      return [year, month, day].join("-");
    }
    else return str
  }

  tooltipDate(str) {
    if (str) {
      str = str.toString().slice(0, 16)
      let date = new Date(str);
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      return [month, year].join("/");

    } else {
      return "Não informado"
    }
  }

  incompletField() {
    let date = false, exit = false
    this.tableEdition.forEach(edited => {
      if (edited.startDate && !exit) {
        if (edited.dt_reajuste == '')
          date = true
        else {
          date = false
          exit = true
        }
      }
    })
    return date
  }

  saveEdition() {
    if (this.incompletField()) {
      this.exibirMensagem({ "error": true, "mensagem": "Erro ao salvar, campo data incompleto " })
      return
    }

    this.rowSelected.forEach(row => {
      let isKey = false
      this.tableEdition.forEach(edited => {
        this.tableValues.forEach(table => {
          if (row == table.id) {

            table[edited.column] = edited.value
            table.valor_rescisao = edited.valor_rescisao
            table.demissao = edited.demissao
            for (let i = 1; i <= this.levels; i++) {
              if (this.keysInfo[i][1] == edited.column) isKey = true
            }
            if (!isKey && edited.dt_reajuste !== '') table.dt_reajuste = edited.dt_reajuste
            if (table.demissao)
              table.dt_reajuste = table.data_fin

          }
        })
      })
    })

    this.rowSelected.forEach(id => {
      this.tableValues.forEach(table => {
        let index = null
        if (table.id == id) {
          for (let i = 1; i <= this.levels; i++) {
            if (Array.isArray(table[this.keysInfo[i][1]])) {
              let value = null
              this.getKeyValues(this.keysInfo[i][1])[table.empresa_id].forEach(item => {
                table[this.keysInfo[i][1]].forEach(keyId => {
                  if (item.id == keyId)
                    value = item.id
                })
              })
              if (value !== null) table[this.keysInfo[i][1]] = value
            }
          }
          this.tableEdited.forEach((line, ind) => { if (line.id == table.id) index = ind })
          if (index != null)
            this.tableEdited[index] = table;
          else this.tableEdited.push(table);
        }
      })
    })
    this.rowSelected = [];
    this.tableEdited = this.verifyKeys(this.tableEdited)
    this.saveNewLines(this.tableEdited, true);
    this.changeState('edition');
  }

  verifyKeys(array) {
    array.map(item => {
      for (let i = 1; i <= this.levels; i++) {
        if (i != 1) {
          let hasKeyRelated = false, isKeyRelated = false;
          this.getKeyValues(this.keysInfo[i][1])[item.empresa_id].forEach(option => {
            if (option[this.keysInfo[i - 1][1]].includes(item[this.keysInfo[i - 1][1]])) {
              hasKeyRelated = true
              if (option.id == item[this.keysInfo[i][1]]) {
                isKeyRelated = true
              }
            }
          })
          if (hasKeyRelated && !isKeyRelated) item[this.keysInfo[i][1]] = null
        }
      }
    });
    return array
  }

  renderEditionColumn() {
    this.editionColumn = [
      { label: 'Nome', value: 'nome' },
      { label: 'Função', value: 'cargo_proj_id' },
      { label: 'Nível', value: 'nivel_proj_id' },
      { label: 'Data Início', value: 'data_ini' },
      { label: 'Data Final', value: 'data_fin' },
      { label: 'Salário', value: 'salario_proj' }
    ]
    for (let i = 1; i <= this.levels; i++) {
      this.editionColumn.push({
        label: this.keysInfo[i][0],
        value: this.keysInfo[i][1]
      })
    }
  }

  getOptions(type: any) {
    let res = [], toEditData = [];
    switch (type) {
      case 'cargo_proj_id':
        res = this.functionOptions
        break
      case 'empresa':
        res = this.selectedCompanyOptions
        break
      case 'nivel_proj_id':
        res = this.levelOptions
        break
    }

    if (res.length == 0) {
      this.rowSelected.forEach(id => {
        this.tableValues.forEach(table => {
          if (table.id == id) {
            toEditData.push(table)
          }
        })
      })
    }

    if ([1, 2, 3].includes(this.levels)) {
      if (type == this.keysInfo['1'][1]) {
        res = this.getEditionOptions(this.keysInfo['1'][1])
      }
    }
    if ([2, 3].includes(this.levels)) {
      if (type == this.keysInfo['2'][1]) {
        let relatedId = null;
        this.tableEdition.forEach(item => {
          if (item.column == this.keysInfo['1'][1])
            relatedId = item.value
        })
        if (relatedId == null) {
          relatedId = []
          toEditData.forEach(line => {
            if (!relatedId.includes(line[this.keysInfo['1'][1]])) relatedId.push(line[this.keysInfo['1'][1]])
          })
        }
        res = this.getEditionOptions(this.keysInfo['2'][1], relatedId, this.keysInfo['1'][1])
      }
    }
    if (this.levels == 3) {
      if (type == this.keysInfo['3'][1]) {
        let relatedId = null;
        this.tableEdition.forEach(item => {
          if (item.column == this.keysInfo['2'][1])
            relatedId = item.value
        })
        if (relatedId == null) {
          relatedId = []
          toEditData.forEach(line => {
            if (!relatedId.includes(line[this.keysInfo['2'][1]])) relatedId.push(line[this.keysInfo['2'][1]])
          })
        }
        res = this.getEditionOptions(this.keysInfo['3'][1], relatedId, this.keysInfo['2'][1])
      }
    }
    return res
  }

  getAddOptions(key, company, relatedId = null, relatedKey = '') {
    return this.editFormat(this.getKeyValues(key), [company], relatedId, relatedKey);
  }

  getEditionOptions(key, relatedId = null, relatedKey = '') {
    return this.editFormat(this.getKeyValues(key), this.companysToEdit, relatedId, relatedKey);
  }

  getKeyValues(key) {
    let response = []
    switch (key) {
      case 'ccus_id':
        response = this.allccus;
        break;
      case 'proj_id':
        response = this.allproj;
        break;
      case 'area_id':
        response = this.allarea;
        break;
      case 'subarea_id':
        response = this.allsubarea;
        break;
    }
    return response
  }

  editFormat(array: any, selectedCompany: any, relatedId, key) {
    let response = [], auxOptions = []
    selectedCompany.forEach((companyId) => {
      if (relatedId !== null) {
        array[companyId].forEach(item => {
          auxOptions.push({ label: `${item.codigo} - ${item.descricao}`, value: item.id, cod: item.codigo })
          if (Array.isArray(relatedId)) {
            relatedId.forEach(relId => {
              if (item[key].includes(relId)) {
                response.push({ label: `${item.codigo} - ${item.descricao}`, value: item.id, cod: item.codigo })
              }
            })
          } else {
            if (item[key].includes(relatedId))
              response.push({ label: `${item.codigo} - ${item.descricao}`, value: item.id, cod: item.codigo })
          }
        })
      } else {
        array[companyId].forEach(item => response.push({ label: `${item.codigo} - ${item.descricao}`, value: item.id, cod: item.codigo }))
      }
    });
    if (response.length == 0)
      response = auxOptions

    return this.removeDuplicate(response)
  }

  removeDuplicate(array) {
    let response = []
    array.forEach(item => {
      let index = null
      response.forEach((option, ind) => {
        if (item.cod == option.cod) index = ind;
      })
      if (index !== null) {
        if (Array.isArray(response[index].value))
          response[index] = { ...item, value: [item.value, ...response[index].value] }
        else
          response[index] = { ...item, value: [item.value, response[index].value] }
      } else {
        response.push(item)
      }
    })
    return response
  }

  getCompanys() {
    this.httpService.wait()
    this.httpService.get(this.configService.defaultUrlApiUpload + "cadastro/getgroupempresas/" + this.dblinkedSessionService.empresa.id + "-" + this.sessionService.loggedUser.id)
      .subscribe(
        res => {
          if (res.error) {
            this.exibirMensagem(res)
          } else {
            this.companyOptions = []
            res.data.forEach(row => this.companyOptions.push({value: row.id, label: row.fantasia }))
          }

          this.httpService.done()
        },
        err => {
          this.httpService.done()
          this.exibirMensagem({ "error": true, "mensagem": "Erro ao buscar os grupos de empresa, contate o suporte: " + err.message })
          console.log(err)
        }
      )
  }

  removeBatch() {
    this.rowSelected.forEach(row => {
      this.tableValues = this.tableValues.filter(line => line.id != row)
      this.tableDeleted.push(row)
    })
    this.rowSelected = [];
    this.table.reset();
    this.onFilter({ filters: [] });
    this.toolbarBatch();
  }

  changeState(tag: string) {
    switch (tag) {
      case 'screen':
        let element: any = document.querySelector(".mat-sidenav");
        this.fullTable = !this.fullTable;
        if (this.fullTable) element.style.zIndex = 0;
        else element.style.zIndex = 2;
        break
      case 'inclusion':
        this.tableInclusion = [];
        this.modalInclusion = !this.modalInclusion;
        break
      case 'edition':
        this.tableEdition = [];
        this.companysToEdit = [];
        this.rowSelected.forEach(id => {
          this.tableValues.forEach(table => {
            if (table.id == id && !this.companysToEdit.includes(table.empresa_id)) {
              this.companysToEdit.push(table.empresa_id)
            }
          })
        })
        this.modalEdition = !this.modalEdition;
        break
      case 'filter':
        this.modalFilter = !this.modalFilter;
        break
    }
  }

  calcFooterTotal(month) {
    let response = 0;
    (this.isFiltered ? this.filterTableValues : this.tableValues).forEach(item => {
      response += item[month]
    })
    return response
  }

  export() {
    const infos = {
      liempresas: this.toFilterCompany,
      versao_id: this.selectedVersionId,
      user_id: this.sessionService.loggedUser.id,
      liarea: this.setArr(this.filtersData.area_id),
      liccus: this.setArr(this.filtersData.ccus_id),
      lisubarea: this.setArr(this.filtersData.subarea_id),
      liproj: this.setArr(this.filtersData.proj_id)
    };

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'rh/export/relatorio-premissas', infos)
      .subscribe(res => {
        this.exibirMensagem(res);
        if (!res["error"]) {
          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0'
            }),

            responseType: 'blob' as 'json'
          };

          this.httpService.wait();
          this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/export/relatorio-premissas', httpOptions2).subscribe(
            (res: Blob) => {
              let link = document.createElement('a');
              let url = window.URL.createObjectURL(res);
              let fileName = `base_premissas_RH.xlsx`;

              link.href = url;
              link.download = fileName;
              link.click();

              window.URL.revokeObjectURL(url);
              this.httpService.done();
            }, err => {
              this.exibirMensagem({ error: true, mensagem: "Erro ao baixar o arquivo, contate o suporte: " + err.message });
              this.httpService.done();
            });
        }

        this.httpService.done();
      }, err => {
        this.exibirMensagem({ error: true, mensagem: "Erro ao gerar o arquivo, contate o suporte: " + err.message });
        this.httpService.done();
      });
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  idv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx-xxyyxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  onInputDate(event: any) {
    let value = event.target.value.replace(/^(\d{1,2})\/?(\d{1,2})\/?(\d{1,4})/, '$1$2$3').split(/\D/)[0],
      response = '';

    if (value.slice(0, 2).length > 0) {
      response = value.slice(0, 2) <= 31 ? value.slice(0, 2) : ''
      if (value.slice(2, 4).length > 0) {
        response += '/' + (value.slice(2, 4) <= 12 ? value.slice(2, 4) : '')
        if (value.slice(4, 8).length > 0) {
          response += '/' + (value.slice(6, 7) >= 1 && value.slice(6, 7) <= 3 ? value.slice(4, 8) : value.slice(4, 6))
        }
      }
    }
    event.target.value = response
  }

  onSelectAll(event: any) {
    this.rowSelected = [];
    if (event.checked) {
      (this.isFiltered ? this.filterTableValues : this.tableValues).forEach(item => {
        this.rowSelected.push(item.id);
      })
    }
    this.toolbarBatch();
  }

  onRowSelect(event: any) {
    this.rowSelected.push(event.data.id);
    this.toolbarBatch();
  }

  onRowUnselect(event: any) {
    this.rowSelected.splice(this.rowSelected.indexOf(event.data.id), 1)
    this.toolbarBatch();
  }

  toolbarBatch() {
    this.toolbarMain.setVisible('btn4', this.rowSelected.length > 0);
    this.toolbarMain.setVisible('btn3', this.rowSelected.length > 0);
  }

  changeEditionState(e: any) {
    this.tableValues.forEach(item => {
      if (item.id == e) {
        item.isEdit = !item.isEdit;
        if (!item.isEdit)
          this.saveNewLines([item], true)
      }
    })
  }

  changeColumn(e, line) {

    this.tableEdition.forEach(item => {
      if (item.lineId == line) {
        switch (e) {
          case 'nome':
            item.columnDropdown = false;
            item.columnCalendar = false;
            item.columnWage = false;
            item.rescisao = false;
            item.startDate = false;
            item.columnDateIni = false;
            item.columnText = true;
            break;
          case 'data_ini':
            item.columnDropdown = false;
            item.columnCalendar = true;
            item.columnWage = false;
            item.rescisao = false;
            item.startDate = false;
            item.columnDateIni = true;
            item.columnText = false;
            break;
          case 'data_fin':
            item.columnDropdown = false;
            item.columnCalendar = true;
            item.columnWage = false;
            item.rescisao = true;
            item.startDate = false;
            item.columnDateIni = false;
            item.columnText = false;
            break;
          case 'salario_proj':
            item.columnDropdown = false;
            item.columnCalendar = false;
            item.columnWage = true;
            item.rescisao = false;
            item.startDate = true;
            item.columnDateIni = false;
            item.columnText = false;
            break;
          case 'nivel_proj_id':
            item.columnDropdown = true;
            item.columnCalendar = false;
            item.columnWage = false;
            item.rescisao = false;
            item.startDate = true;
            item.columnDateIni = false;
            item.columnText = false;
            break;
          case 'cargo_proj_id':
            item.columnDropdown = true;
            item.columnCalendar = false;
            item.columnWage = false;
            item.rescisao = false;
            item.startDate = true;
            item.columnDateIni = false;
            item.columnText = false;
            break;
          default:
            item.columnDropdown = true;
            item.columnCalendar = false;
            item.columnWage = false;
            item.rescisao = false;
            item.startDate = false;
            item.columnDateIni = false;
            item.columnText = false;
            break;
        }
      }
    })
  }

  getTooltip(data, type) {
    let response = '';
    switch (type) {
      case 'cargo':
        if (data.cargo_proj_id !== null)
          response = `Atual: ${this.convertId(data.cargo_atu_id, 'cargo')} \n Projetado: ${this.convertId(data.cargo_proj_id, 'cargo')} \n Data: ${this.tooltipDate(data.dt_reajuste)}`
        else
          response = `${this.convertId(data.cargo_atu_id, 'cargo')}`
        break;
      case 'nivel':
        if (data.nivel_proj_id !== null)
          response = `Atual: ${this.convertId(data.nivel_atu_id, 'nivel')} \n Projetado: ${this.convertId(data.nivel_proj_id, 'nivel')} \n Data: ${this.tooltipDate(data.dt_reajuste)}`
        else
          response = `${this.convertId(data.nivel_atu_id, 'nivel')}`
        break;
    }
    return response
  }

  formatarDataExtenso() {
    const dt = new Date();
    const month = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    const week = [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ];
    const semana = week[dt.getDay()];
    const mes = month[dt.getMonth()];

    return (`Hoje é ${semana}, ${dt.getDate()} de ${mes} de ${dt.getFullYear()}`);
  }
}
