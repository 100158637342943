import { Component, OnInit, OnDestroy } from '@angular/core';
import { Empresa } from '../../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, SelectItem, MessageService } from 'primeng/api';
import { Periodo } from 'app/orcamento/model/periodo.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';
import exportExcel from 'app/util/processing/exportExcel';
declare var $: any;

@Component({
  selector: 'app-perido-budget',
  templateUrl: './periodo-budget.component.html',
  styleUrls: ['./periodo-budget.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class PeriodoBudgetComponent implements OnInit, OnDestroy {

  currentStatePeriodo = 'initial';
  changeStatePeriodo() {
    this.currentStatePeriodo = this.currentStatePeriodo === 'initial' ? 'final' : 'initial';
  }

  currentStateMeses = 'initial';
  changeStateMeses() {
    this.currentStateMeses = this.currentStateMeses === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;

  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  msgs: Message[] = null;
  msgsModal: Message[] = null;
  boolCriarPeriodo: boolean = false;
  boolExibiMeses: boolean = false;
  checkedAll: boolean = false;
  selectedPeriods: Array<any> = [];
  listaAnosSelecionados: Array<any> = [];
  periodo: Periodo;
  opcoesMes: SelectItem[];
  listaPeriodos: Array<any> = [];
  listaPeriodoAnos: Array<any> = [];
  listaPeriodoMeses: Array<any> = [];
  meses: string[];
  initialDate: Date
  finalDate: Date

  //export excel
  colsListaPeriodoAnos: any[];
  colsListaMeses: any[];

  constructor(
    private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    public messageService: MessageService) {
  }

  public toolbarMain = new Toolbar()
  toolbarMainIni = [
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluirPeriodo() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.showModalNovo() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.exportMainTable() }
  ];

  public toolbarView = new Toolbar()
  toolbarViewIni = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.exportViewTable() }
  ];

  ngOnInit(): void {
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarView.setAll(this.toolbarViewIni)
    this.selectedPeriods = new Array<any>();
    this.listaAnosSelecionados = new Array<any>();
    this.boolCriarPeriodo = false;
    this.checkedAll = false;
    this.periodo = new Periodo();
    this.opcoesMes = [];
    this.meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    this.meses.forEach((mes, index) => {
      this.opcoesMes.push({ label: mes, value: index });
    });

    this.titlePrincipal = 'Configurações Orçamento';
    this.subtitle = 'Definição de Período';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });

    this.colsListaPeriodoAnos = [
      { field: 'ano', header: 'ANO' },
      { field: 'descricaoCompleta', header: 'PERÍODO' }
    ];

    this.colsListaMeses = [
      { field: 'ano_data', header: 'ANO' },
      { field: 'mes_data', header: 'MÊS' }
    ];

  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

      this.boolExibiMeses = false;
      this.boolCriarPeriodo = false;
      this.selectedPeriods = new Array<any>();
      this.listaAnosSelecionados = new Array<any>();
      this.boolCriarPeriodo = false;
      this.checkedAll = false;
      this.periodo = new Periodo();
      this.opcoesMes = [];
      this.meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
      this.meses.forEach((mes, index) => {
        this.opcoesMes.push({ label: mes, value: index });
      });
      this.carregarOrcamentoPeriodoAnos();
    }
  }

  showModalNovo() {
    this.hideMessage();
    this.boolCriarPeriodo = true;
    this.periodo = new Periodo();
  }

  carregarOrcamentoPeriodoAnos() {

    if (this.dblinkedSessionService.empresa.id !== null && this.dblinkedSessionService.empresa.id !== undefined) {

      this.httpService.wait();
      this.httpService.get('/custom/orcamento/get-periodo-anos(' + this.dblinkedSessionService.empresa.id + ')')
        .subscribe(ref => {

          this.listaPeriodoAnos = [];
          ref.forEach(element => {

            if (element.mes_incial === "01" || element.mes_incial === "1") {
              element.mes_incial = "Janeiro";
            }
            if (element.mes_incial === "02" || element.mes_incial === "2") {
              element.mes_incial = "Fevereiro";
            }
            if (element.mes_incial === "03" || element.mes_incial === "3") {
              element.mes_incial = "Março";
            }
            if (element.mes_incial === "04" || element.mes_incial === "4") {
              element.mes_incial = "Abril";
            }
            if (element.mes_incial === "05" || element.mes_incial === "5") {
              element.mes_incial = "Maio";
            }
            if (element.mes_incial === "06" || element.mes_incial === "6") {
              element.mes_incial = "Junho";
            }
            if (element.mes_incial === "07" || element.mes_incial === "7") {
              element.mes_incial = "Julho";
            }
            if (element.mes_incial === "08" || element.mes_incial === "8") {
              element.mes_incial = "Agosto";
            }
            if (element.mes_incial === "09" || element.mes_incial === "9") {
              element.mes_incial = "Setembro";
            }
            if (element.mes_incial === "10") {
              element.mes_incial = "Outubro";
            }
            if (element.mes_incial === "11") {
              element.mes_incial = "Novembro";
            }
            if (element.mes_incial === "12" || element.mes_incial === "0") {
              element.mes_incial = "Dezembro";
            }
            if (element.mes_final === "01" || element.mes_final === "1") {
              element.mes_final = "Janeiro";
            }
            if (element.mes_final === "02" || element.mes_final === "2") {
              element.mes_final = "Fevereiro";
            }
            if (element.mes_final === "03" || element.mes_final === "3") {
              element.mes_final = "Março";
            }
            if (element.mes_final === "04" || element.mes_final === "4") {
              element.mes_final = "Abril";
            }
            if (element.mes_final === "05" || element.mes_final === "5") {
              element.mes_final = "Maio";
            }
            if (element.mes_final === "06" || element.mes_final === "6") {
              element.mes_final = "Junho";
            }
            if (element.mes_final === "07" || element.mes_final === "7") {
              element.mes_final = "Julho";
            }
            if (element.mes_final === "08" || element.mes_final === "8") {
              element.mes_final = "Agosto";
            }
            if (element.mes_final === "09" || element.mes_final === "9") {
              element.mes_final = "Setembro";
            }
            if (element.mes_final === "10") {
              element.mes_final = "Outubro";
            }
            if (element.mes_final === "11") {
              element.mes_final = "Novembro";
            }
            if (element.mes_final === "12" || element.mes_final === "0") {
              element.mes_final = "Dezembro";
            }
            element.selected = false;
            this.listaPeriodoAnos.push(element);
          });
        },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
    }
  }

  carregarOrcamentoPeriodoMeses(ano: any) {

    if (this.dblinkedSessionService.empresa.id !== null && this.dblinkedSessionService.empresa.id !== undefined) {

      this.httpService.wait();
      this.httpService.get('/custom/orcamento/get-periodo-meses(' + this.dblinkedSessionService.empresa.id + ',' + ano + ')')
        .subscribe(ref => {
          this.listaPeriodoMeses = [];
          ref.forEach(element => {

            if (element.mes_data === "01" || element.mes_data === "1") {
              element.mes_data = "Janeiro";
            }
            if (element.mes_data === "02" || element.mes_data === "2") {
              element.mes_data = "Fevereiro";
            }
            if (element.mes_data === "03" || element.mes_data === "3") {
              element.mes_data = "Março";
            }
            if (element.mes_data === "04" || element.mes_data === "4") {
              element.mes_data = "Abril";
            }
            if (element.mes_data === "05" || element.mes_data === "5") {
              element.mes_data = "Maio";
            }
            if (element.mes_data === "06" || element.mes_data === "6") {
              element.mes_data = "Junho";
            }
            if (element.mes_data === "07" || element.mes_data === "7") {
              element.mes_data = "Julho";
            }
            if (element.mes_data === "08" || element.mes_data === "8") {
              element.mes_data = "Agosto";
            }
            if (element.mes_data === "09" || element.mes_data === "9") {
              element.mes_data = "Setembro";
            }
            if (element.mes_data === "10") {
              element.mes_data = "Outubro";
            }
            if (element.mes_data === "11") {
              element.mes_data = "Novembro";
            }
            if (element.mes_data === "12" || element.mes_data === "0") {
              element.mes_data = "Dezembro";
            }
            element
            this.listaPeriodoMeses.push(element);
          });

          this.boolExibiMeses = true;

        },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
    }
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  criarNovoPeriodo() {

    if (this.validacao()) {
      return;
    }
    let meses = [];
    let temp = new Date(this.initialDate);
    temp.setDate(1);
    while (temp <= this.finalDate) {
      const newDate = new Date(temp);
      const testData = `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
      meses.push(testData);
      temp.setMonth(temp.getMonth() + 1);
    }

    let listaEnviar = {
      ano: this.initialDate.getFullYear(),
      listaMeses: meses,
      usuario: this.sessionService.loggedUser.id,
      empresaId: this.empresa.id
    }

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/criar-periodo-orcamento', listaEnviar)
      .subscribe(rst => {

        if (rst.error === true) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'Erro: ',
            detail: rst.detail
          });
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'Sucesso: ',
            detail: rst.detail
          });
        }
        this.change();

      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }

  selectCheckbox(ano: any, event: any) {

    if (event.selected) {
      const index = this.listaPeriodoAnos.findIndex(i => i === ano);
      this.listaAnosSelecionados.splice(index, 1);

    } else {
      this.listaAnosSelecionados.push(ano);
    }
  }

  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;

    this.listaPeriodoAnos.forEach(e => {
      if (this.checkedAll) {
        const i = this.listaPeriodoAnos.findIndex(p => p === e);
        this.listaPeriodoAnos[i].selected = true;
        this.listaAnosSelecionados.push(this.listaPeriodoAnos[i].ano);
        this.handleSelect();
      } else {
        this.listaPeriodoAnos.forEach(e => {
          e.selected = false;
          const i = this.listaPeriodoAnos.findIndex(p => p === e);
          this.listaAnosSelecionados.splice(i, 1);
          this.handleSelect();
        });
      }
    });
  }

  excluirPeriodo() {
    if (confirm('Deseja excluir o(s) registro(s) selecionado(s)?')) {

     const ids = [];
      this.selectedPeriods.forEach(item => {
        item.listaPeriodos.forEach(element => {
          ids.push(element.id)
        });
      })

      let listaEnviar = {};
      listaEnviar = {
        empresaId: this.empresa.id,
        lista: ids,
      }
      this.httpService.wait();
      this.httpService.post('/custom/orcamento/excluir-periodo-orcamento', listaEnviar)
        .subscribe(rst => {

          if (rst.error === true) {
            this.messageService.add({
              severity: 'error', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.detail
            });
          } else {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.detail
            });
          }
          this.change();
          this.handleSelect();
        },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
      }
  }

  cancelar() {
    this.change();
  }

  hideMessage() {
    this.msgs = [];
    this.msgsModal = [];
  }

  validacao() {
    if (this.initialDate === null || this.initialDate === undefined) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Atenção: ', detail: 'Favor selecionar o mês inicial.'
      });
      return true;
    }
    if (this.finalDate === null || this.initialDate === undefined) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Atenção: ', detail: 'Favor selecionar o mês final.'
      });
      return true;
    }
    if (this.initialDate > this.finalDate) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Atenção: ', detail: 'Mês final precisa ser posterior ao mês inicial.'
      });
      return true;
    }
  }

  handleSelect(): void {
    this.toolbarMain.setVisible("btn1", this.selectedPeriods.length  >= 1 ? true : false);
  }

  /*Show Hide Periodo*/
  isCardPeriodo: boolean = true;
  ShowHidePeriodo() {
    this.isCardPeriodo = !this.isCardPeriodo;
  }

  /*Show Hide Meses*/
  isCardMeses: boolean = true;
  ShowHideMeses() {
    this.isCardMeses = !this.isCardMeses;
  }

  exportMainTable(): void {
    const orderList = ['ano', 'periodo'];
    let exportList = [];
    if(this.listaPeriodoAnos.length > 0) {
      this.listaPeriodoAnos.forEach(item => {
        exportList.push({
          ano: item.ano,
          periodo: item.descricaoCompleta
        });
      });
    }
    if(exportList.length > 0) {
      exportExcel(exportList, "DEFINIÇÃO DE PERÍODO", orderList);
    }
  }

  exportViewTable(): void {
    let exportList = [];
    const exportOrder = ['ano', 'periodo']
    if(this.listaPeriodoMeses.length > 0) {
      this.listaPeriodoMeses.forEach(item => {
        exportList.push({
          ano: item.ano,
          periodo: item.descricaoCompleta
        });
      });
    }

    if(exportList.length > 0) {
      exportExcel(exportList, "VISUALIZAÇÕES DE PERÍODO", exportOrder);
    }
  }
}
