import { Empresa } from "app/cadastro/model/empresa.model";
import { AlcadaRateio } from "./alcada-rateio.model";

export class Alcada {
  id: number;
  descricao: string;
  codigo: string;
  valorMin: number;
  valorMax: number;
  empresa: Empresa;
  alcadaRateio: AlcadaRateio;
  hasValorMin: boolean;
  hasValorMax: boolean;

  constructor(Alcada?: Alcada | number) {
    if (Alcada !== undefined && Alcada !== null) {
      if (typeof Alcada === 'number') {
        this.id = Alcada;
      } else {
        this.id = Alcada.id;
        this.descricao = Alcada.descricao;
        this.codigo = Alcada.codigo;
        this.valorMin = Alcada.valorMin;
        this.valorMax = Alcada.valorMax;
        this.empresa = Alcada.empresa;
        this.alcadaRateio = Alcada.alcadaRateio;
        this.hasValorMin = Alcada.hasValorMin;
        this.hasValorMax = Alcada.hasValorMax;
      }
    }
  }
}