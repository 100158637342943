import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CadProj } from 'app/contabil/model/cad-proj.model';
import { Message, MessageService } from '../../../../node_modules/primeng/api';
import { OperacaoRegra } from 'app/cadastro/model/operacao-regra.model';

@Component({
  selector: 'app-evento-proj-relac',
  templateUrl: './evento-proj-relac.component.html',
  styleUrls: ['./evento-proj-relac.component.scss'],
})
export class EventoProjRelacComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = [];

  titlePrincipal: string;
  subtitle: string;

  listaUsuarios: Array<any> = new Array();
  listaAprovadores: Array<any> = new Array();

  projetos: SelectItem[];
  opcoesOperacaoFin: SelectItem[];
  opcoesOperacaoEmissao: SelectItem[];
  projId: number;
  operacaoFinId: number;
  operacaoEmissaoId: number;
  adto: boolean;

  listaEventosRelacionar: Array<any> = new Array();
  listaEventosRelacionados: Array<any> = new Array();

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    // requisição

    this.titlePrincipal = 'CONFIGURAÇÕES';
    this.subtitle = 'RELACIONAMENTO DE PROJETOS E EVENTOS';
    this.titleService.title = 'RELACIONAMENTO DE PROJETOS E EVENTOS';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.projId = 0;
    this.operacaoFinId = 0;
    this.operacaoEmissaoId = 0;
    this.adto = false;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });
    }

  ngOnDestroy() {
    // this.toolbarService.clear();
    // this.toolbarService.hidden = false;
    // this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  data() {
    // this.dtCorte = new Date(this.timezonePipe.transform(this.dtCorte));
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

        this.listaEventosRelacionar = new Array();
        this.listaEventosRelacionados = new Array();
        this.projId = 0;
        this.operacaoFinId = 0;
        this.operacaoEmissaoId = 0;
        this.adto = false;

        this.projetos = [];
        this.projetos.push({ label: 'Selecione o projeto', value: 0 });

        this.httpService.wait();
         this.httpService.get('custom/polozi/listar-projetos',
          [this.dblinkedSessionService.empresa.id])
          .subscribe(retorno => {
            retorno.forEach(element => {
              this.projetos.push({ label: element.codProj, value: element.id });
            });

            this.httpService.get('/operacaoregra?$select=id,cod,descricao,operacaoRegraContabil/id,operacaoRegraContabil/tipoRegraContabil/id,'
            + 'operacaoRegraFiscal/id&$filter=tipoEntradaSaida/id eq 2 and ativo eq true and empresa/id eq '
            + this.dblinkedSessionService.empresa.id)
            .subscribe(ref => this.loadOperacaoFin(ref.value),
              error => this.httpService.handleError(error, () => this.change()),
              () => this.httpService.done());

              this.httpService.get('/operacaoregra?$select=id,cod,descricao,operacaoRegraContabil/id,operacaoRegraContabil/tipoRegraContabil/id,'
            + 'operacaoRegraFiscal/id&$filter=tipoEntradaSaida/id eq 2 and ativo eq true and empresa/id eq '
            + this.dblinkedSessionService.empresa.id)
            .subscribe(ref => this.loadOperacaoEmissao(ref.value),
              error => this.httpService.handleError(error, () => this.change()),
              () => this.httpService.done());

          }, error => this.httpService.handleError(error, () => this.change()),
            () => this.httpService.done());

      // renderizando a toolbar
      setTimeout(() => {
        this.toolbarEdit();
      }, 500);
    }
  }

  onFilterRms(event: any) {
    console.log('event: ' + event);
  }

  export() {
    // alert('export');
  }

  pesquisa() {
    // alert('pesquisa');
  }


 hideMessage() {

  }

  private toolbarEdit() {
    const todos = this.buttons.slice();
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.alterarStatus('pesquisa', true, 'Detalhes Rateio', () => this.pesquisa());
    this.toolbarService.alterarStatus('download', true, 'Download Anexos', () => this.export());
    this.toolbarService.alterarStatus('voltar', true, 'Voltar', () => this.ngOnInit());
    this.toolbarService.alterarStatus('atualizar', true, 'Atualizar RMS', () => this.change());
    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.ngOnInit());
    this.toolbarService.alterarStatus('save', true, 'Salvar requisição', () => this.ngOnInit());
    this.toolbarService.alterarStatus('edit', true, 'Editar requisição', () => this.ngOnInit());
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Relacionamento', () => this.salvarRelacionamento());
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  salvarRelacionamento(){
    if (confirm('Deseja realmente salvar este relacionamento?')) {
      const req = {
        proj: this.projId,
        listaEventosRelacionar: this.listaEventosRelacionar,
        listaEventosRelacionados: this.listaEventosRelacionados,
        operacaoFin: this.operacaoFinId,
        operacaoEmissao: this.operacaoEmissaoId,
        adto: this.adto
      };

      if(this.adto && this.operacaoFinId === 0){

        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Para Adiantamento é necessário informar a Operação Financeira'
        });
        return;
      }else if(this.operacaoEmissaoId === 0){

        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'É necessário informar a Operação de Emissão'
        });
        return;

      }


      this.httpService.wait();
      this.httpService.post('/custom/polozi/salvar-relacionamento-proj', req)
        .subscribe(result => {
          let existeErro = false;
          result.forEach(element => {
            if(element.error){
              existeErro = true;
              this.messageService.add({
                severity: element.severity,
                summary: 'MSG: ',
                detail: element.message
              });
            }
          });
          if(!existeErro){
            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Alteração salva com sucesso!'
            });
          }


          this.change();
        },
          error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
          () => this.httpService.done());
    }
  }

  buscarEventos(){

    this.listaEventosRelacionados = new Array();
    this.listaEventosRelacionar = new Array();

    if(this.projId > 0){
      this.httpService.wait();
      // this.httpService.get('custom/rms/listar-usuarios-relacionados',
      this.httpService.get('custom/polozi/listar-eventos-relacionados',
        [this.projId, this.dblinkedSessionService.empresa.id])
        .subscribe(retorno => {
          retorno.forEach(element => {
            console.log(element);
            if(element.isEvt){
              if(element.relac){
                this.listaEventosRelacionados.push(element);
              } else {
                this.listaEventosRelacionar.push(element);
              }
            } else if (!element.isEvt){
              this.operacaoFinId = element.operacaoFinId;
              this.operacaoEmissaoId = element.operacaoEmissaoId;
              this.adto = element.adto;
            }
          });

        }, error => this.httpService.handleError(error, () => this.buscarEventos()),
          () => this.httpService.done());

    }
  }

  loadOperacaoFin(value: Array<OperacaoRegra>) {

    this.opcoesOperacaoFin = [];
    this.opcoesOperacaoFin.push({ label: 'Selecione a Operação', value: 0 });
    value.forEach(element => {
      if ((element.operacaoRegraContabil !== null)) {
        if(element.operacaoRegraContabil.tipoRegraContabil.id === 3){
          this.opcoesOperacaoFin.push({ label: element.cod + ' - ' + element.descricao, value: element.id });
        }
      }
    });
  }

  loadOperacaoEmissao(value: Array<OperacaoRegra>) {

    this.opcoesOperacaoEmissao = [];
    this.opcoesOperacaoEmissao.push({ label: 'Selecione a Operação', value: 0 });
    value.forEach(element => {
      if ((element.operacaoRegraContabil !== null)) {
        if(element.operacaoRegraContabil.tipoRegraContabil.id === 2){
            this.opcoesOperacaoEmissao.push({ label: element.cod + ' - ' + element.descricao, value: element.id });
        }
      }
    });
  }


}
