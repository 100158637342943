import { Empresa } from 'app/cadastro/model/empresa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';


export class EmpresaCnae {
  id: number;
  empresa: Empresa;
  cnae: string;
  descricao: string;
  retidoPrestador: boolean;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;

  constructor(empresaCnae?: EmpresaCnae | number) {
    if (empresaCnae !== undefined && empresaCnae !== null) {
      if (typeof empresaCnae === 'number') {
        this.id = empresaCnae;
      } else {
        this.id = empresaCnae.id;
        this.empresa = empresaCnae.empresa;
        this.cnae = empresaCnae.cnae;
        this.descricao = empresaCnae.descricao;
        this.retidoPrestador = empresaCnae.retidoPrestador;
        this.createTimestamp = empresaCnae.createTimestamp;
        this.changeUser = empresaCnae.changeUser;
        this.changeTimestamp = empresaCnae.changeTimestamp;
      }
    }
  }
}
