import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { Eventos } from './eventos.model';


export class EventoEmpresaContaRelac {
  id: number;
  evento: Eventos;
  empresaConta: EmpresaConta;


  constructor(eventoEmpresaContaRelac?: EventoEmpresaContaRelac | number) {
    if (eventoEmpresaContaRelac !== undefined && eventoEmpresaContaRelac !== null) {
      if (typeof eventoEmpresaContaRelac === 'number') {
        this.id = eventoEmpresaContaRelac;
      } else {
        this.id = eventoEmpresaContaRelac.id;
        this.evento = eventoEmpresaContaRelac.evento;
        this.empresaConta = eventoEmpresaContaRelac.empresaConta;
      }
    }
  }

}