import { Directive, ElementRef, Input, Output, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[numbersOnly]'
})
export class NumbersOnlyDirective implements AfterViewInit {
  @Input() ngModel: any;
  @Output() ngModelChange: EventEmitter<{}> = new EventEmitter();

  private input: HTMLInputElement;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.input = this.elementRef.nativeElement;
  }

  @HostListener('keydown', [ '$event' ])
  onKeyDown(keyboardEvent: KeyboardEvent) {
    return keyboardEvent.keyCode === 8 || keyboardEvent.keyCode === 9 || keyboardEvent.keyCode === 46 ||
           keyboardEvent.keyCode === 37 || keyboardEvent.keyCode === 39 ||
           keyboardEvent.keyCode >= 96 && keyboardEvent.keyCode <= 105 ||
           keyboardEvent.keyCode >= 48 && keyboardEvent.keyCode <= 57 ||
           ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(keyboardEvent.key) !== -1 ||
           ((keyboardEvent.ctrlKey || keyboardEvent.metaKey) &&
            (keyboardEvent.keyCode === 67 || keyboardEvent.keyCode === 86 || keyboardEvent.keyCode === 88));
  }

  @HostListener('input', [ '$event' ])
  onInput(event: any) {
    event.target.value = this.fix(event);
    this.ngModelChange.next(this.input.value);
  }

  @HostListener('blur', [ '$event' ])
  onBlur(event: any) {
    event.target.value = this.fix(event);
  }

  private fix(event: any) {
    if (event.target.value !== undefined && event.target.value !== null) {
      event.target.value = event.target.value.trim();

      let prefix = '';
      while (event.target.value.substr(0, 1) === '0') {
        prefix += '0';
        event.target.value = event.target.value.substr(1).trim();
      }

      event.target.value = prefix + (parseInt(event.target.value, 10) || '');
    }

    return event.target.value;
  }
}
