import { Empresa } from "app/cadastro/model/empresa.model";

export class Objeto {
  id: number;
  nome: string;
  empresa: Empresa;

  constructor(objeto?: Objeto | number) {
    if (objeto !== undefined && objeto !== null) {
      if (typeof objeto === 'number') {
        this.id = objeto;
      } else {
        this.id = objeto.id;
        this.nome = objeto.nome;
        this.empresa = objeto.empresa;
      }
    }
  }
}
