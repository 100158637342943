import { AuthUser } from 'app/proof/model/auth-user.model';
import { TodoCard } from './todo-card.model';

export class TodoPartCardRelac {
  id: number;
  usuario: AuthUser;
  todoCard: TodoCard;
  executando: boolean;
  seguindo: boolean;

  constructor(todoPartCardRelac?: TodoPartCardRelac | number) {
    if (todoPartCardRelac !== undefined && todoPartCardRelac !== null) {
      if (typeof todoPartCardRelac === 'number') {
        this.id = todoPartCardRelac;
      } else {
        this.id = todoPartCardRelac.id;
        this.usuario = todoPartCardRelac.usuario;
        this.todoCard = todoPartCardRelac.todoCard;
        this.executando = todoPartCardRelac.executando;
        this.seguindo = todoPartCardRelac.seguindo;
      }
    }
  }
}
