import { Regra } from 'app/cadastro/model/regra.model';
import { ViewCampos } from 'app/cadastro/model/view-campos.model';

export class RegraCamposOrder {
  id: number;
  regra: Regra;
  viewCampos: ViewCampos;

  constructor(regraCamposOrder?: RegraCamposOrder | number) {
    if (regraCamposOrder !== undefined && regraCamposOrder !== null) {
      if (typeof regraCamposOrder === 'number') {
        this.id = regraCamposOrder;
      } else {
        this.id = regraCamposOrder.id;
        this.regra = regraCamposOrder.regra;
        this.viewCampos = regraCamposOrder.viewCampos;
      }
    }
  }
}
