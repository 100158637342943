import { Empresa } from 'app/cadastro/model/empresa.model';

export class GrupoComissao {
  id: number;
  codigo: string;
  descricao: string;
  porcentagem: number;
  dtInicial: Date|string;
  dtFinal: Date|string;
  empresa: Empresa;
  ativo: boolean;
  porcentagemGerente: number;
  porcentagemDesconto: number;
  gerenteId: number;

  constructor(grupoComissao?: GrupoComissao | number) {
    if (grupoComissao !== undefined && grupoComissao !== null) {
      if (typeof grupoComissao === 'number') {
        this.id = grupoComissao;
      } else {
        this.id = grupoComissao.id;
        this.codigo = grupoComissao.codigo;
        this.descricao = grupoComissao.descricao;
        this.porcentagem = grupoComissao.porcentagem;
        this.dtInicial = grupoComissao.dtInicial;
        this.dtFinal = grupoComissao.dtFinal;
        this.empresa = grupoComissao.empresa;
        this.ativo = grupoComissao.ativo;
        this.porcentagemGerente = grupoComissao.porcentagemGerente;
        this.porcentagemDesconto = grupoComissao.porcentagemDesconto;
        this.gerenteId = grupoComissao.gerenteId;
      }
    }
  }
}
