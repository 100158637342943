import { Component, OnInit } from '@angular/core';

import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
  selector: 'app-xml-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  providers: [CrudService]
})
export class XmlCompanyComponent extends DataTableBasicComponent implements OnInit {
  public company: any = {};

  constructor(crudService: CrudService,
              private toolbarService: ToolbarService) {
    super(crudService);
  }

  ngOnInit() {
   // this.toolbarRefresh();
    this.columns = [
      new DataTableColumn('Razão', 'name'),
      new DataTableColumn('Fantasia', 'fantasy'),
      new DataTableColumn('CNPJ ou CPF', 'cnpjOrCpf')
        .setNoWrap(),
      new DataTableColumn('Ativa', 'active')
        .setTypeCheckBox()
    ];

    this.controls = [
      new DataControl('Razão', 'name')
        .setRequired()
        .setMinLength(5)
        .setMaxLength(60),
     new DataControl('Fantasia', 'fantasy')
        .setRequired()
        .setMinLength(5)
        .setMaxLength(60),
      new DataControl('CNPJ ou CPF', 'cnpjOrCpf')
        .setRequired()
        .setMinLength(14)
        .setMaxLength(18),
      new DataControl('Ativa', 'active')
        .setTypeCheckBox()
    ];
  }

  /*private toolbarRefresh() {
    this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
    this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
  }*/
}
