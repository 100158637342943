import { Usuario } from 'app/cadastro/model/usuario.model';
import { Recorrencia } from '../../util/model/recorrencia.model';

export class CheckList {
  id: number;
  dataCorte: Date;
  descricao: string;
  anexo: boolean;
  titulo: string;
  usuario: Usuario;
  recorrencia: Recorrencia;

  constructor(checkList?: CheckList | number) {
    if (checkList !== undefined && checkList !== null) {
      if (typeof checkList === 'number') {
        this.id = checkList;
      } else {
        this.id = checkList.id;
        this.dataCorte = checkList.dataCorte;
        this.titulo = checkList.titulo;
        this.descricao = checkList.descricao;
        this.anexo = checkList.anexo;
        this.usuario = checkList.usuario;
        this.recorrencia = checkList.recorrencia;
      }
    }
  }
}
