export class FormaEscritContabil {
  id: number;
  cod: string;
  descricao: string;

  constructor(formaescritcontabil?: FormaEscritContabil | number) {
    if (formaescritcontabil !== undefined && formaescritcontabil !== null) {
      if (typeof formaescritcontabil === 'number') {
        this.id = formaescritcontabil;
      } else {
        this.id = formaescritcontabil.id;
        this.cod = formaescritcontabil.cod;
        this.descricao = formaescritcontabil.descricao;
      }
    }
  }
}
