import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { TitleService } from 'app/shared/services/title.service';
import { Moeda } from '../../util/model/moeda.model';
import { SelectItem } from 'primeng/api'
import idv4 from "app/util/processing/idv4";

@Component({
  selector: 'app-report',
  templateUrl: './relatorios-gerenciais.component.html',
  styleUrls: ['./relatorios-gerenciais.component.scss'],
})

export class RelatoriosOrcamentoComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;

  listaRelat: Array<any> = new Array();
  dicRelatComposto: any = {};
  datasIndividuais: boolean = false;
  relatorio: any;
  modalRelatorio: boolean = false;
  dataRef: Date = new Date();
  title: string = "Exportação de orçamento";
  subtitle: string = "Orçamento gerencial";
  msgs: Message[];
  msgsModal: Message[];
  empresaId: any;

  modalDespesas: any;
  modalVendas: any;
  centroCustoSelecionados: Array<any> = new Array();
  centroCustoOpcoes: any[];
  mesIni: any;
  mesFin: any;
  meses: any[];
  classificacaoEscolhidos: any[];
  classificacaoOpcoes: any[];
  separarArquivo: boolean = false;

  modalOrca: boolean = false;
  versaoList: SelectItem[] = [];
  versaoId: number = null;
  opcoesClass: SelectItem[] = [];
  classSelecionadas: Array<string> = [];
  opcoesArea: SelectItem[] = [];
  areaSelecionadas: Array<string> = [];
  opcoesCcus: SelectItem[] = [];
  ccusSelecionados: Array<string> = [];
  opcoesProj: SelectItem[] = [];
  projSelecionados: Array<string> = [];
  opcoesMoeda: SelectItem[];
  moeda: Moeda = null;//new Moeda();
  empresaOk: boolean = false;
  opcoesModelo: SelectItem[] = [];
  modeloSelect: number = null

  configList: SelectItem[] = [];
  configId: number = null;
  listaVersoes: Array<any> = new Array();
  versao: any;
  listEmpresas: SelectItem[] = [];
  empresasSelecionadas: Array<number> = new Array();
  opcoesCentroCustos: SelectItem[] = [];
  opcoesProjetos: SelectItem[] = [];
  projetosSelecionados: Array<number> = new Array();
  opcoesMoedas: SelectItem[] = [];
  moedaDash: number = 1;
  utilizarTxDif: boolean = false;
  txMoeda: number = 1;
  visaoDash: string = 'all';
  visoesDash: SelectItem[] = [
    { value: "mes", label: "Mensal" },
    { value: "trim", label: "Trimestral" },
    { value: "all", label: "As duas" }
  ];
  hideLines: boolean = true;
  modalOrcaDash: boolean = false;

  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private titleService: TitleService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private httpService: HttpService) { }

  ngOnInit(): void {
    this.toolbarService.clear();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = "Exportação orçamento";
    this.subtitle = "GERENCIAIS PARA EXPORTAR EM EXCEL";
    this.relatorio = {};
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.getConfigRelatorios();
        this.loadModeloRelatorio();
        this.getVersoesGerencial();
        this.empresaId = this.dblinkedSessionService.empresa.id;
      });

  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  getMoeda() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        ano: this.versao.ano.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "util/moedas", httpOptions).toPromise()
      .then(res => {
        if (!res["error"]) {
          this.opcoesMoedas = [];
          res["mensagem"].forEach(element => { this.opcoesMoedas.push({ value: element["id"], label: element["cod"] }); });

        } else {
          this.exibirMensagem(res);
        }
        this.httpService.done();
      })
      .catch(err => {
        this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível buscar as moedas." })
        console.log(err);
        this.httpService.done();
      });
  }

  getProjOrca() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        versaoid: this.versao.versao_id.toString(),
        ccusid: this.centroCustoSelecionados.toString(),
        ano: this.versao.ano.toString()
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "orcamento/proj", httpOptions)
      .toPromise()
      .then(res => {
        if (!res["error"]) {
          this.opcoesProjetos = [];
          res["mensagem"].forEach(element => { this.opcoesProjetos.push({ value: element["cod_proj"], label: element["cod_proj"] + " - " + element["descr_proj"] }); });

        } else {
          this.exibirMensagem(res);
        }
        this.httpService.done();
      })
      .catch(err => {
        this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível buscar os projetos." })
        console.log(err);
        this.httpService.done();
      });
  }

  getCcusOrca() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        versao: this.versao.versao.toString(),
        empresaid: this.empresasSelecionadas.toString(),
        userid: this.sessionService.loggedUser.id.toString(),
        ano: this.versao.ano.toString()
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "orcamento/ccus", httpOptions)
      .toPromise()
      .then(res => {
        if (!res["error"]) {
          this.opcoesCentroCustos = [];
          this.opcoesCentroCustos.push({ label: "Não informado", value: 0 });
          res["mensagem"].forEach(element => { this.opcoesCentroCustos.push({ value: element["cod_ccus"], label: element["cod_ccus"] + " - " + element["descr_ccus"] }); });

        } else {
          this.exibirMensagem(res);
        }
        this.httpService.done();
      })
      .catch(err => {
        this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível buscar os centros de custo." })
        console.log(err);
        this.httpService.done();
      });
  }

  async getListEmpresas() {
    this.httpService.wait()
    await this.httpService.get(this.configService.defaultUrlApiUpload + "cadastro/getgroupempresas/" + this.dblinkedSessionService.empresa.id + "-" + this.sessionService.loggedUser.id)
      .toPromise()
      .then(res => {
        if (!res.error) {
          this.listEmpresas = []
          this.empresasSelecionadas = []
          res.data.forEach(element => this.listEmpresas.push({ value: element.id, label: element.fantasia }))
          this.empresasSelecionadas.push(this.dblinkedSessionService.empresa.id)

        } else {
          this.exibirMensagem(res)
        }
        this.httpService.done()
      })
      .catch(err => {
        this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível buscar o grupo de empresas. Contate o suporte: " + err.message })
        console.log(err)
        this.httpService.done()
      })
  }

  getVersoesGerencial() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        ano: this.dblinkedSessionService.periodo.year.value.toString(),
      }),
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + "orcamento/dashboard/get-versoes-orcamento", httpOptions)
      .toPromise()
      .then(res => {
        if (!res["error"]) {
          this.listaVersoes = new Array();
          res["data"].forEach(element => { this.listaVersoes.push(element); });

        } else {
          this.exibirMensagem(res);
        }
        this.httpService.done();
      })
      .catch(err => {
        this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível buscar as versões de orçamento." })
        console.log(err)
        this.httpService.done()
      });
  }

  gerarRelatorioGerencialExcel() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    let config_list = [];
    if (this.relatorio.is_composta) {
      for (let conf of this.dicRelatComposto[this.relatorio.id]) {
        if (this.datasIndividuais && conf['dataRef'] == null) {
          this.exibirMensagem({ error: true, mensagem: "Informe uma data para todos os relatórios ou desmarque a opção de datas individuais." });
          return;
        }

        config_list.push({
          'empresa_id': this.dblinkedSessionService.empresa.id,
          'dt_ref': this.datasIndividuais ? [conf['dataRef'].getDate(), conf['dataRef'].getMonth() + 1, conf['dataRef'].getFullYear()] : [this.dataRef.getDate(), this.dataRef.getMonth() + 1, this.dataRef.getFullYear()],
          'config_id': conf['config_relat_ref_id'],
          'worksheet_title': conf['worksheet_title']
        });
      }
    } else {
      config_list = [{
        'empresa_id': this.dblinkedSessionService.empresa.id,
        'dt_ref': [this.dataRef.getDate(), this.dataRef.getMonth() + 1, this.dataRef.getFullYear()],
        'config_id': this.relatorio.id
      }];
    }

    const infos = {
      list_infos: config_list,
      is_composta: this.relatorio.is_composta,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', infos, httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();

          } else {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0',
                'userid': this.sessionService.loggedUser.id.toString()
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', httpOptions2)
              .subscribe(
                (res: Blob) => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);
                  let fileName = "Relatório gerencial.xlsx";
                  link.href = url;
                  link.download = fileName;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o relatório." });
                  console.log(err);
                  this.httpService.done();
                });
          }
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o relatório." });
          console.log(err);
          this.httpService.done();
        });
  }

  renderizarRelatorioGerencial() {
    var data_ref: string = this.dataRef.getDate() + '-' + (this.dataRef.getMonth() + 1).toString() + '-' + this.dataRef.getFullYear();
    window.open('/workstats/render-relatorios-gerenciais/' + this.dblinkedSessionService.empresa.id.toString() + '/' + this.relatorio.id + '/' + this.relatorio.plano_gerencial_id + '/' + data_ref, '_blank');
  }

  getConfigRelatorios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'modulo': '5'
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            this.listaRelat = new Array();
            for (var line of res["mensagem"]) {
              this.listaRelat.push(line);
            }

            this.dicRelatComposto = res["dicconfigs_compostas"];
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  showDialogRelatorioGerencial(relat) {
    this.relatorio = relat;
    this.modalRelatorio = true;
    this.dataRef = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1);

  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  hideMessage() {

  }

  carregarValores(config) {
    let replicar = false;

    for (let dicConfig of this.dicRelatComposto[this.relatorio.id]) {
      if (config['id'] === dicConfig['id']) replicar = true;

      if (replicar) {
        dicConfig['dataRef'] = config['dataRef'];
      }
    }
  }

  /* Função que carrega os Centros de Custos a serem exibidos no modal de despesas */
  getCentrosCustos() {

    this.centroCustoSelecionados = [];
    this.centroCustoOpcoes = [];

    this.httpService.wait();
    this.httpService.get('/cadccus?$select=id,dtAlt,codCcus,descrCcus' +
      '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      '&$orderby=codCcus')
      .subscribe(
        res => {
          for (let line of res.value) {
            this.centroCustoOpcoes.push({
              label: line.codCcus + " - " + line.descrCcus, value: line.id
            });
          }

          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  exibirModal(event) {
    if (event == 'despesas') {

      this.meses = [
        { label: 'Selecione', value: null },
        { label: 'Janeiro', value: 1 },
        { label: 'Fevereiro', value: 2 },
        { label: 'Março', value: 3 },
        { label: 'Abril', value: 4 },
        { label: 'Maio', value: 5 },
        { label: 'Junho', value: 6 },
        { label: 'Julho', value: 7 },
        { label: 'Agosto', value: 8 },
        { label: 'Setembro', value: 9 },
        { label: 'Outubro', value: 10 },
        { label: 'Novembro', value: 11 },
        { label: 'Dezembro', value: 12 },
      ];

      this.separarArquivo = false;
      this.getCentrosCustos();
      this.getArea();
      this.modalDespesas = true;
      this.modalVendas = false;
    }else{
      this.modalVendas = true;
      this.modalDespesas = false;
    }
  }

  fecharModalDespesas() {
    this.modalDespesas = false;
    this.centroCustoSelecionados = [];
    this.mesIni = 0;
    this.mesFin = 0;
    this.modalVendas = false;
  }

  gerarRelatorioDespesas() {

    //this.msgs = [];

    /* Realizar as validações */
    if (this.mesFin == undefined || this.mesFin == null
      || this.mesFin == 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Sucesso', detail: 'Selecione um mês de corte para o filtro.' });
      //this.msgs.push({ severity: 'warn', detail: 'Selecione um mês de corte para o filtro.' });
    } else if (this.areaSelecionadas == undefined || this.areaSelecionadas == null
      || this.areaSelecionadas.length == 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Sucesso', detail: 'Selecione ao menos uma área para filtro.' });
      //this.msgs.push({ severity: 'warn', detail: 'Selecione ao menos uma área para filtro.' });
    } else {

      let inicio: string;
      if (this.mesIni < 10) {
        inicio = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesIni + '-01';
      } else {
        inicio = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesIni + '-01';
      }

      let final: string;

      if (this.mesFin === 1 || this.mesFin === 3 || this.mesFin === 5 || this.mesFin === 7 ||
        this.mesFin === 8 || this.mesFin === 10 || this.mesFin === 12) {
        if (this.mesFin < 10) {
          final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-31';
        } else {
          final = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesFin + '-31';
        }
      } else if (this.mesFin === 4 || this.mesFin === 6 || this.mesFin === 9 || this.mesFin === 11) {
        if (this.mesFin < 10) {
          final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-30';
        } else {
          final = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesFin + '-30';
        }
      } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2016') {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
      } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2020') {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
      } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2024') {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
      } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() !== '2016') {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-28';
      }

      var anoAnterior = this.dblinkedSessionService.periodo.year.value - 3;
      var inicioAnosAnteriores = anoAnterior.toString() + '-01-01';
      var finalAnosAnteriores = (anoAnterior + 1).toString() + '-12-31';

      var mes;
      var mesAnterior;
      var mesAnteriorNum;
      var mesPosteriorNum;
      if (this.mesFin === 1) {
        mes = "Janeiro"
        mesAnterior = "Dezembro"
        mesAnteriorNum = "12";
        mesPosteriorNum = "02";
      } else if (this.mesFin === 2) {
        mes = "Fevereiro"
        mesAnterior = "Janeiro"
        mesAnteriorNum = "01";
        mesPosteriorNum = "03";
      } else if (this.mesFin === 3) {
        mes = "Março"
        mesAnterior = "Fevereiro"
        mesAnteriorNum = "02";
        mesPosteriorNum = "04";
      } else if (this.mesFin === 4) {
        mes = "Abril"
        mesAnterior = "Março"
        mesAnteriorNum = "03";
        mesPosteriorNum = "05";
      } else if (this.mesFin === 5) {
        mes = "Maio"
        mesAnterior = "Abril"
        mesAnteriorNum = "04";
        mesPosteriorNum = "06";
      } else if (this.mesFin === 6) {
        mes = "Junho"
        mesAnterior = "Maio"
        mesAnteriorNum = "05";
        mesPosteriorNum = "07";
      } else if (this.mesFin === 7) {
        mes = "Julho"
        mesAnterior = "Junho"
        mesAnteriorNum = "06";
        mesPosteriorNum = "08";
      } else if (this.mesFin === 8) {
        mes = "Agosto"
        mesAnterior = "Julho"
        mesAnteriorNum = "07";
        mesPosteriorNum = "09";
      } else if (this.mesFin === 9) {
        mes = "Setembro"
        mesAnterior = "Agosto"
        mesAnteriorNum = "08";
        mesPosteriorNum = "10";
      } else if (this.mesFin === 10) {
        mes = "Outubro"
        mesAnterior = "Setembro"
        mesAnteriorNum = "09";
        mesPosteriorNum = "11";
      } else if (this.mesFin === 11) {
        mes = "Novembro"
        mesAnterior = "Outubro"
        mesAnteriorNum = "10";
        mesPosteriorNum = "12";
      } else if (this.mesFin === 12) {
        mes = "Dezembro"
        mesAnterior = "Novembro"
        mesAnteriorNum = "11";
        mesPosteriorNum = "01";
      }

      const listasEnviar = {
        cnpj: this.dblinkedSessionService.empresa.cnpj,
        empresaId: this.dblinkedSessionService.empresa.id,
        mes: mes,
        mesAnterior: mesAnterior.substring(0, 3),
        mesAnteriorNum: mesAnteriorNum.toString(),
        mesPosteriorNum: mesPosteriorNum.toString(),
        numMes: this.mesFin,
        ano: this.dblinkedSessionService.periodo.year.value,
        periodoFinal: final,
        separarArquivo: this.separarArquivo,
        areaSelecionadas: this.areaSelecionadas,
        usuario: this.sessionService.loggedUser.id,
        periodoInicial: inicio,
        periodoInicialAnterior: inicioAnosAnteriores,
        periodoFinalAnterior: finalAnosAnteriores,
      };

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/relatorio-despesas', listasEnviar)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add(res);
              //this.msgs.push({ severity: 'success', detail: "Geração de relatório concluída com sucesso." });
              this.windowOpen(this.httpService.url('/custom/file/' + res.file), '_blank');
              this.fecharModalDespesas();
            } else {
              this.messageService.add(res);
              //this.msgs.push({ severity: 'error', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );

    }
  }

  // Rel Gerencial

  baixarOrca() {
    if (this.dblinkedSessionService.empresa.id !== 1
      && this.dblinkedSessionService.empresa.id !== 115
      && this.dblinkedSessionService.empresa.id !== 133) {

      let listaEnviar = {
        versaoId: this.versaoId,
        classificacoes: this.classSelecionadas,
        areas: this.areaSelecionadas,
        ccus: this.ccusSelecionados,
        empresaId: this.dblinkedSessionService.empresa.id,
        projs: this.projSelecionados,
        configId: this.configId
      }

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/export-orcamento-default', listaEnviar)
        .subscribe(result => this.download(result),
          error => this.httpService.handleError(error, () => this.baixarOrca()),
          () => this.httpService.done());
    } else if (this.modeloSelect === 1) {
      //Header da requisição
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'areas': this.areaSelecionadas,
          'centrosCusto': this.ccusSelecionados,
          'classificacoes': this.classSelecionadas,
          'versaoId': this.versaoId.toString(),
        })
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'orcamento/relatorio-basebi', null, httpOptions)
        .subscribe(res => {
          this.exibirMensagem(res);

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0'
            }),

            responseType: 'blob' as 'json'
          };

          this.httpService.wait();
          this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/baixar-relatorio-basebi', httpOptions2).subscribe(
            (res: Blob) => {
              let link = document.createElement('a');
              let url = window.URL.createObjectURL(res);
              let fileName = `orcamento.xlsx`;

              link.href = url;
              link.download = fileName;
              link.click();

              window.URL.revokeObjectURL(url);
              this.httpService.done();
            }, err => {
              this.exibirMensagem(err);
              console.log(err);
              this.httpService.done();
            });

          this.httpService.done();
        }, err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        });
    } else {

      /* O usuário deve selecionar ao menos uma área.
      Caso contrário, uma mensagem de aviso deve ser emitida. */
      if (this.areaSelecionadas.length < 1) {
        this.msgsModal = [];
        this.msgsModal.push({ severity: 'warn', detail: 'Favor selecionar ao menos uma área.' });
      } else {
        let listaEnviar = {
          versaoId: this.versaoId,
          classificacoes: this.classSelecionadas,
          areas: this.areaSelecionadas,
          ccus: this.ccusSelecionados,
          empresaId: this.dblinkedSessionService.empresa.id,
          projs: this.projSelecionados,
          separarArquivo: this.separarArquivo.toString()
        }

        this.httpService.wait();
        this.httpService.post('/custom/orcamento/export-orcamento', listaEnviar)
          .subscribe(result => this.download(result),
            error => this.httpService.handleError(error, () => this.baixarOrca()),
            () => this.httpService.done());
      }
    }
  }

  download(result: any) {
    console.log(result);
    if (!result.error) {
      this.windowOpen(this.httpService.url('/custom/file/' + result.file), '_blank');
    }
  }

  windowOpen(url: string, target?: string) {
    setTimeout(() => {
      window.open(url, target), 1;
    });
  }

  getVersaoOrca() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/versao', httpOptions)
      .subscribe(
        res => {
          this.versaoList = [];
          var result = res["data"];
          for (let line of result) {
            this.versaoList.push({ "label": line["descricao"], "value": line["id"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getClassi() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/classi', httpOptions)
      .subscribe(
        res => {
          this.opcoesClass = [];
          var result = res["mensagem"];
          for (let line of result) {
            this.opcoesClass.push({ "label": line["descricao"], "value": line["descricao"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getArea() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'authuserid': this.sessionService.loggedUser.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/area-desc', httpOptions)
      .subscribe(
        res => {
          this.opcoesArea = [];
          var result = res["data"];
          for (let line of result) {
            this.opcoesArea.push({ "label": line["desc"], "value": line["desc"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getCcus() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/ccus', httpOptions)
      .subscribe(
        res => {
          this.opcoesCcus = [];
          var result = res["mensagem"];
          for (let line of result) {
            this.opcoesCcus.push({ "label": line["cod_ccus"] + " - " + line["descr_ccus"], "value": line["cod_ccus"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getProj() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/proj', httpOptions)
      .subscribe(
        res => {
          this.opcoesProj = [];
          var result = res["mensagem"];
          for (let line of result) {
            this.opcoesProj.push({ "label": line["cod_proj"] + " - " + line["descr_proj"], "value": line["cod_proj"] });
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err)
          console.log(err);
          this.httpService.done();
        }
      );
  }

  showDialogRelatorio() {
    this.modalOrca = true;
    this.versaoId = null;
    this.classSelecionadas = [];
    this.areaSelecionadas = [];
    this.ccusSelecionados = [];
    this.getVersaoOrca();
    this.getClassi();
    this.getArea();
    this.getCcus();
    this.getProj();
    this.carregarImportacaoTipos();
  }

  showDialogRelatorioOrc() {
    this.modalOrca = true;
    this.versaoId = null;
    this.classSelecionadas = [];
    this.areaSelecionadas = [];
    this.ccusSelecionados = [];
    this.getVersaoOrca();
    this.getClassi();
    this.getArea();
    this.getCcus();
    this.getProj();
    this.carregarImportacaoTipos();

  }

  carregarImportacaoTipos() {


    this.httpService.wait();
    this.httpService.get('/configuracaoimportacao?$select=id,descricao'
      + '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=descricao')
      .subscribe(ref => {

        this.configList = [];
        ref.value.forEach(element => {
          this.configList.push({ "label": element.descricao, "value": element.id });
        });

      },
        error => this.httpService.handleError(error, () => this.carregarImportacaoTipos()),
        () => this.httpService.done());

  }

  loadMoedas(value: Array<any>) {
    this.opcoesMoeda = [];
    this.opcoesMoeda.push({ label: 'Nenhum', value: null });

    value.forEach(element => {
      this.opcoesMoeda.push({ label: element.codigo, value: element });
    });
  }

  loadModeloRelatorio() {
    this.opcoesModelo = [];

    this.opcoesModelo.push({ label: "Tradicional", value: 0 })
    this.opcoesModelo.push({ label: "Base BI", value: 1 })
  }

  showDialogDashOrca(versao) {
    this.versao = versao;
    this.modalOrcaDash = true;
    this.centroCustoSelecionados = new Array();
    this.projetosSelecionados = new Array();
    this.moedaDash = 1;
    this.utilizarTxDif = false;
    this.txMoeda = 1;
    this.visaoDash = 'all';
    this.hideLines = true;
    Promise.resolve(this.getListEmpresas()).then((res) => {
      this.getCcusOrca();
    });
    this.getMoeda();
  }

  baixarDashOrca() {
    if (!this.empresasSelecionadas) {
      this.exibirMensagem({ "error": true, "mensagem": "Selecione pelo menos uma empresa." });
    }

    else if (!this.moedaDash) {
      this.exibirMensagem({ "error": true, "mensagem": "Selecione uma moeda." });
    }

    else if (this.utilizarTxDif && (!this.txMoeda || this.txMoeda <= 0)) {
      this.exibirMensagem({ "error": true, "mensagem": "A taxa simulada deve ser positiva." });
    }

    else if (!this.visaoDash) {
      this.exibirMensagem({ "error": true, "mensagem": "Selecione uma visão." });
    }

    else {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'escondernulos': this.hideLines.toString(),
          visao: this.visaoDash
        })
      };

      const infos = {
        empresa_id: this.empresasSelecionadas,
        ano: this.dblinkedSessionService.periodo.year.value,
        liccus: this.centroCustoSelecionados,
        liproj: this.projetosSelecionados,
        liarea: [],
        lisubarea: [],
        moeda_id: this.moedaDash,
        utilizarTxDif: this.utilizarTxDif,
        txMoeda: this.txMoeda,
        first: false,
        versao: this.versao,
        user_id: this.sessionService.loggedUser.id,
        empresaConectadaId: this.dblinkedSessionService.empresa.id,
        tokenProgressoSocket: idv4(),
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'orcamento/relat-orca-dash', infos, httpOptions)
        .toPromise()
        .then(res => {
          if (!res["error"]) {
            this.exibirMensagem(res);
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0'
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/relat-orca-dash', httpOptions2)
              .toPromise()
              .then((res: Blob) => {
                let link = document.createElement('a');
                let url = window.URL.createObjectURL(res);
                let fileName = 'Relatorio_orcamento_gerencial.xlsx';

                link.href = url;
                link.download = fileName;
                link.click();

                window.URL.revokeObjectURL(url);
                this.modalOrcaDash = false;
                this.httpService.done();
              })
              .catch(err => {
                this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível baixar o relatório." })
                console.log(err)
                this.httpService.done();
              });



          } else {
            this.exibirMensagem(res);
            this.httpService.done();
          }
        })
        .catch(err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: não foi possível gerar o relatório." })
          console.log(err)
          this.httpService.done();
        });
    }
  }

  gerarRelatorioVendas() {


      const listasEnviar = {
        empresaId: this.dblinkedSessionService.empresa.id,
        ano: this.dblinkedSessionService.periodo.year.value,
      };

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/objetivo-vendas', listasEnviar)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add(res);
              this.windowOpen(this.httpService.url('/custom/file/' + res.file), '_blank');
              this.fecharModalDespesas();
            } else {
              this.messageService.add(res);
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );

    }


}
