import { Component, OnInit, OnDestroy } from '@angular/core';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { CadCcus}       from 'app/contabil/model/cad-ccus.model';
import { isNull, isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from 'app/cadastro/model/usuario.model';

@Component({
  selector: 'app-controle-orcamento-fini',
  templateUrl: './controle-orcamento-fini.component.html',
  styleUrls: ['./controle-orcamento-fini.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ControleOrcamentoFiniComponent implements OnInit, OnDestroy {

  currentStateControlOrca = 'initial';
  disabledEditBtn: boolean = true;
  disabledAjusteBtn: boolean = false;
  changeStateControlOrca() {
    this.currentStateControlOrca = this.currentStateControlOrca === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  /*msgs: Message[];
  msgsModal: Message[];
  msgsNaoMapeadas: Message[];*/
  tipo: number;
  contasNaoMapeadas       : Array<any> = new Array();
  despesas                : Array<any> = new Array();
  periodoOrcamentoEntrada : Array<any> = new Array();
  listaOrcamento          : Array<any> = new Array();
  listaPedidos            : Array<any> = new Array();
  listaProvisoes          : Array<any> = new Array();
  listaVersoesOrcamentos  : Array<any> = new Array();
  ajustesOrcamento        : Array<any> = new Array();
  listaRazoes             : Array<any> = new Array();
  linhasSelecionadas      : Array<string> = [];

  exibirRelacionamento: boolean;
  exibirAjustes: boolean;
  exibirNaoMapeadas: boolean;
  exibirIntercompany: boolean;
  exibirImpressao: boolean;
  exibirRazao: boolean;
  exibirRazaoEdit: boolean;
  alterarRazaoGeral: boolean = false;
  alterarRazaoMes: boolean = false;
  alterarRazaoOcorrencia: boolean = false;
  alterarPelaContaContabil: boolean = false;
  idEdit: number;

  razaoSocialEditOrig :String = '';
  razaoSocialEdit :String = '';
  razaoSocialSimplificadaEditOrig :String = '';
  codCtaContabil :String = '';
  razaoSocialSimplificadaEdit :String = '';

  opcoesPlanoContas: SelectItem[];
  contaSelecionada: number;

  opcoesTipoAjustesUsGaap: SelectItem[];
  tipoajustesusGaapSelecionada: string;

  opcoesGerenciais      : SelectItem[];
  idReferencial         : number;
  idsOrcamentosEntrada          : Array<any> = new Array();
  idsOrcamentosEntradaOnFilter  : Array<any> = new Array();
  idsOrcamentosEventosEntrada   : Array<any> = new Array();
  idOrcamento                   : number = 0;


  lcto                      : any;
  valorTotalDeb             : number;
  valorTotalCred            : number;
  checkedAll                : boolean = false;
  modalAplicarFiltro        : boolean = false;
  opcoesCentroCusto         : SelectItem[];
  cadCcus                   : CadCcus = new CadCcus();
  centroCustoSelecionadoId  : number = 0;
  centroCustoSelecionado    : CadCcus = new CadCcus();
  checkboxdisabled          : boolean = false;
  idTeste                   : number = 0;
  selecionarTodosDisabled   : boolean = true;
  tabIndexSelecionado       : number = 0;
  modalPeriodo              : boolean = false;
  dataIni            : any = '';
  dataFin            : any = '';
  isProcurarPorData         :boolean = false;
  isNovoParametroData : any = null;
  filtro                    : string = '';
  novoAjuste                : boolean = false;


  idOrcamentos: Array<any> = new Array();
  checkBoxTodos: boolean;
  filteredLctos: Array<any> = new Array();
  editarSegmento: boolean = false;
  editarArea: boolean = false;
  editarSubArea: boolean = false;
  editarClassificacao: boolean = false;
  editarConta2: boolean = false;

  opcoesArea: SelectItem[] = [];
  opcoesSegmento: SelectItem[] = [];
  opcoesGerencial: SelectItem[] = [];
  opcoesSubArea: SelectItem[] = [];
  opcoesClassificacao: SelectItem[] = [];
  opcoesDescrConta2: SelectItem[] = [];

  segmento: String = '';
  gerencial: String = '';
  area: String = '';
  subarea: String = '';
  classificacao: String = '';
  descrCta2: String = '';
  vlrIntercompany: any;
  historico: String = '';
  codCliFor: String = '';
  vlrAjustes: any;

    constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private datePipe: DatePipe,
    private httpClient: HttpClient,
    private messageService: MessageService) {
  }

  ngOnInit(): void {

    //Promise.resolve(null).then(() => this.toolbarService.hidden = true);

    this.tipo = 9;

    this.titlePrincipal = 'CONTROLE DESPESAS REAIS';
    // this.titleService.title = 'Linnks - Us Gaap';
    this.subtitle = '';
    this.historico = '';
    this.codCliFor = '';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.vlrIntercompany = 0.00;
    this.vlrAjustes = 0.00;
    this.exibirRelacionamento = false;
    this.exibirIntercompany = false;
    this.exibirAjustes = false;
    this.idReferencial = 0;
    this.periodoOrcamentoEntrada = new Array();
    this.listaPedidos      = new Array();
    this.listaProvisoes    = new Array();
    this.contasNaoMapeadas = new Array();
    this.exibirNaoMapeadas = false;
    this.toolbarService.hidden = true
    this.idOrcamentos = new Array();


    this.valorTotalDeb            = 0;
    this.valorTotalCred           = 0;
    this.centroCustoSelecionadoId = 0;
    this.centroCustoSelecionado   = new CadCcus();
    this.checkboxdisabled         = false;
    this.idTeste                  = 0;
    this.selecionarTodosDisabled  = true;
    this.tabIndexSelecionado      = 0;
    this.modalPeriodo             = false;
    this.isProcurarPorData        = false;
    this.dataIni                  = null;
    this.dataFin                  = null;
    this.novoAjuste               = false;

    this.activatedRoute.params.subscribe(params => {

      if(this.tipo === 9 || this.tipo !== params.tipo ){
        this.tipo = params.tipo;
        this.change();
      }
    });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {


      this.change();

    });

  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

        this.idOrcamento = 0;
        this.filtro = '';

        this.contasNaoMapeadas            = [];
        this.periodoOrcamentoEntrada      = [];
        this.idsOrcamentosEventosEntrada  = [];
        this.listaPedidos                 = [];
        this.listaProvisoes               = [];

        this.checkedAll       = false;
        this.modalPeriodo     = false;
        this.getArea();
        this.getSegmento();
        this.getGerencial();
        this.getSubarea();
        this.getClassificacao();
        this.getAjustesGerencial();
        this.getDescrConta2();

        if (isNullOrUndefined(this.centroCustoSelecionadoId) || this.centroCustoSelecionadoId === 0) {
          return;
        }

        if (isNullOrUndefined(this.tabIndexSelecionado)) {
          this.tabIndexSelecionado = 0;
        }
      }
    }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPageLarge;
  }

  get rows() {
    return this.configService.applicationConfig.rows50;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  /*hideMessage() {
   // this.msgs = [];
    this.msgsModal = [];
  }*/


  getArea() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'authuserid': this.sessionService.loggedUser.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/area-desc', httpOptions)
      .subscribe(
        res => {
          this.opcoesArea = [];
          var result = res["data"];
          for (let line of result) {
            this.opcoesArea.push({ "label": line["desc"], "value": line["desc"] });
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  getGerencial(){


    this.httpService.wait();
    this.httpService.get('/custom/orcamento/listar-contas-gerencial(' + this.dblinkedSessionService.empresa.id + ','+33+')')
      .subscribe(ref => {

        this.opcoesGerencial = [];
        this.opcoesGerencial.push({ "label":'Selecione uma conta Gerencial', "value": null });
        ref.forEach(element => {
          this.opcoesGerencial.push({ "label":element.nome, "value": element.id });
        });

      },
        error => this.httpService.handleError(error, () => this.getGerencial()),
        () => this.httpService.done());
  }

  getSegmento(){


    this.httpService.wait();
    this.httpService.get('/custom/orcamento/get-segmento(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(ref => {

        this.opcoesSegmento = [];
        ref.forEach(element => {
          this.opcoesSegmento.push({ "label":element, "value": element });
        });

      },
        error => this.httpService.handleError(error, () => this.getSegmento()),
        () => this.httpService.done());
  }

  getSubarea(){


    this.httpService.wait();
    this.httpService.get('/custom/orcamento/get-subarea(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(ref => {

        this.opcoesSubArea = [];
        ref.forEach(element => {
          this.opcoesSubArea.push({ "label":element, "value": element });
        });

      },
        error => this.httpService.handleError(error, () => this.getSubarea()),
        () => this.httpService.done());
  }

  getClassificacao(){


    this.httpService.wait();
    this.httpService.get('/custom/orcamento/get-classificacao(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(ref => {

        this.opcoesClassificacao = [];
        ref.forEach(element => {
          this.opcoesClassificacao.push({ "label":element, "value": element });
        });

      },
        error => this.httpService.handleError(error, () => this.getSegmento()),
        () => this.httpService.done());
  }

  getDescrConta2(){

    this.httpService.wait();
    this.httpService.get('/custom/orcamento/get-descr-conta2(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(ref => {

        this.opcoesDescrConta2 = [];
        ref.forEach(element => {
          this.opcoesDescrConta2.push({ "label":element, "value": element });
        });

      },
        error => this.httpService.handleError(error, () => this.getDescrConta2()),
        () => this.httpService.done());

  }


    editar(){
      //this.exibirRelacionamento = true;
    }

    ajustes(){
      this.exibirAjustes = true;
    }

    transformDate(date) {
      return this.datePipe.transform(date, 'dd/MM/yyyy');
    }


    /*Show Hide ControlOrca*/
    isCardControlOrca: boolean = true;
    ShowHideControlOrca(){
      this.isCardControlOrca = !this.isCardControlOrca;
    }


    buscarInfoReal(){
      const listaEnviar = {
        dataIni   : this.datePipe.transform(this.dataIni, 'dd/MM/yyyy'),
        dataFin   : this.datePipe.transform(this.dataFin, 'dd/MM/yyyy'),
        filtro    : this.filtro
      };



      console.log(listaEnviar);

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/buscar-infos-reais', listaEnviar)
      .subscribe(res => {

        if(res.error){
          this.displayMessage(res);
          //this.msgs = [];
          //this.msgs.push({ severity: 'warn', summary: 'MSG: ', detail: res.message });
        }else{
          this.idOrcamentos = new Array();
          this.despesas = new Array();
          this.filteredLctos = new Array();
          res.data.forEach(element => {
            this.despesas.push(element);
            this.filteredLctos.push(element);
          });

          this.displayMessage(res);
          //this.msgs = [];
          //this.msgs.push({ severity: 'success', summary: 'MSG: ', detail: res.message });

        }
        },
        error => this.httpService.handleError(error, () => this.buscarInfoReal()),
        () => this.httpService.done());
    }


  // Função que controla um registro selecionado pelo usuário no checkbox
  check(event: any) {
    console.log(event);
    this.idOrcamentos.push(event.data.orcId);
    this.verificarBotaoEdit();
  }

  uncheck(event: any){
    console.log(event);
    const index = this.idOrcamentos.findIndex(c => c === event.data.orcId);
    this.idOrcamentos.splice(index, 1);
    this.verificarBotaoEdit();
  }

   // Função que controla os registros selecionados pelo checkbox quando o usuário clica no "Select All"
   checkAll() {

    if(this.idOrcamentos.length > 0){
      this.idOrcamentos = new Array();
    }else{

      this.idOrcamentos = new Array();
     this.filteredLctos.forEach(rst => {
        rst.selected = true;
        this.idOrcamentos.push(rst.orcId);
      });

    this.verificarBotaoEdit();
    }


  }

   /* Função que realiza o controle dos registros selecionados a partir de um filtro aplicado */
   onFilter(event: any) {

    this.filteredLctos.forEach(rst => {
      rst.selected = false;
    });

    this.filteredLctos = new Array();
    this.idOrcamentos = new Array();

    event.filteredValue.forEach(element => {
      this.filteredLctos.push(element);
    });

    this.checkBoxTodos = false;
    this.verificarBotaoEdit();
  }

  verificarBotaoEdit(){

    if (this.idOrcamentos) {
      this.disabledEditBtn = false;
      this.selecionarTodosDisabled = false;
      this.disabledAjusteBtn = true;
    } else {
      this.disabledEditBtn = true;
      this.selecionarTodosDisabled = true;
      this.disabledAjusteBtn = false;
    }


  }

  salvarAlteracoes(){
    if (confirm('Deseja realmente salvar estas alterações?')) {

      const listaEnviar = {
        listaOrcamentos   : this.idOrcamentos,
        area              : this.area,
        segmento          : this.segmento,
        subarea           : this.subarea,
        classificacao     : this.classificacao,
        usuario           : this.sessionService.loggedUser.id,
        empresaId         : this.dblinkedSessionService.empresa.id,
        descrCta2         : this.descrCta2

      };

      console.log(listaEnviar);

      this.httpService.wait();
      this.httpService.post('/custom/orcamento/atualizar-alteracoes-despesas', listaEnviar)
      .subscribe(res => {

        if(res.error){
          this.displayMessage(res);


        }else{
          this.displayMessage(res);
          this.exibirRelacionamento = false
          this.segmento = '';
          this.area = '';
          this.subarea = '';
          this.classificacao = '';
          this.descrCta2 = '';

        }
        },
        error => this.httpService.handleError(error, () => this.salvarAlteracoes()),
        () => this.httpService.done());

    }
  }


  valorIntercompany(){
    this.exibirIntercompany = true;
    this.vlrIntercompany    = 0.00;

    const listaBuscarIntercompany = {
      empresaId    : this.dblinkedSessionService.empresa.id,
      mes          : this.dblinkedSessionService.periodo.month.value,
      ano          : this.dblinkedSessionService.periodo.year.value,
    };

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/get-valor-intercompany', listaBuscarIntercompany )
    .subscribe(res => {
      if(res.error){
        this.displayMessage(res);
        //this.msgs = [];
        //this.msgs.push({ severity: 'warn', summary: 'MSG: ', detail: res.message });



      }else{

        this.vlrIntercompany = this.utilService.parseDecimal(res.data);

      }},
    error => this.httpService.handleError(error, () => this.valorIntercompany()),
    () => this.httpService.done());
  }

  salvarIntecompany(){
    if (confirm('Deseja realmente salvar este valor Intercompany?')) {

    const listaBuscarIntercompany = {
      empresaId    : this.dblinkedSessionService.empresa.id,
      mes          : this.dblinkedSessionService.periodo.month.value,
      ano          : this.dblinkedSessionService.periodo.year.value,
      valor        : this.utilService.parseDecimal(this.vlrIntercompany)
    };

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/post-valor-intercompany', listaBuscarIntercompany )
    .subscribe(res => {
      if(res.error){
        this.displayMessage(res);
        //this.msgs = [];
        //this.msgs.push({ severity: 'warn', summary: 'MSG: ', detail: res.message });

      }else{
        this.displayMessage(res);
        //this.msgs = [];
        //this.msgs.push({ severity: 'success', summary: 'MSG: ', detail: 'Valor Salvo' });
      }},
    error => this.httpService.handleError(error, () => this.salvarIntecompany()),
    () => this.httpService.done());
    }
  }

  reprocessarAreas(){
    if (confirm('Deseja realmente reprocessar as áreas/subáreas do mês ' + this.dblinkedSessionService.periodo.month.value + '/'+this.dblinkedSessionService.periodo.year.value+ '?')) {

      const listaBuscarIntercompany = {
      empresaId    : this.dblinkedSessionService.empresa.id,
      mes          : this.dblinkedSessionService.periodo.month.value,
      ano          : this.dblinkedSessionService.periodo.year.value,
    };

    this.httpService.wait();
    this.httpService.post('/custom/orcamento/get-reprocessar-area-subarea', listaBuscarIntercompany )
    .subscribe(res => {
      if(res.error){
        this.displayMessage(res);
        //this.msgs = [];
        //this.msgs.push({ severity: 'warn', summary: 'MSG: ', detail: res.message });
      }},
    error => this.httpService.handleError(error, () => this.reprocessarAreas()),
    () => this.httpService.done());
  }
  }

  alternarCentroCusto(){
    this.modalAplicarFiltro = true;
  }

  changeDataIni(event : any){

    this.dataFin = event;

  }

  salvarAjustes(){

    //this.msgsModal = [];

       if(isNullOrUndefined(this.gerencial)){
        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'É necessário informar o gerencial' });
          //this.msgsModal.push({ severity: 'warn', summary: 'MSG: ', detail: 'É necessário informar o gerencial' });
          return;
        }else{
          if(this.gerencial == ''){
            this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'É necessário informar o gerencial' });
            //this.msgsModal.push({ severity: 'warn', summary: 'MSG: ', detail: 'É necessário informar o gerencial' });
            return;
          }
        }

        if(!isNullOrUndefined(this.historico)){
          if(this.historico === ''){
            this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'É necessário informar o histórico' });
            //this.msgsModal.push({ severity: 'warn', summary: 'MSG: ', detail: 'É necessário informar o histórico' });
            return;

        }
      } else{
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'É necessário informar o histórico' });
          //this.msgsModal.push({ severity: 'warn', summary: 'MSG: ', detail: 'É necessário informar o histórico' });
          return;
        }


      if(isNullOrUndefined(this.vlrAjustes)){
        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'É necessário informar o valor do ajuste' });
        //this.msgsModal.push({ severity: 'warn', summary: 'MSG: ', detail: 'É necessário informar o valor do ajuste' });
        return;
      }else{
        if(this.vlrAjustes === 0.00 || this.vlrAjustes === 0){
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'É necessário informar o valor do ajuste' });
          //this.msgsModal.push({ severity: 'warn', summary: 'MSG: ', detail: 'Valor do Ajuste deverá ser diferente de zero' });
         return;
        }
      }

        if (confirm('Deseja realmente salvar este ajuste do mês ' + this.dblinkedSessionService.periodo.month.value + '/'+this.dblinkedSessionService.periodo.year.value+ '?')) {

          const listaAjustes = {
            empresaId    : this.dblinkedSessionService.empresa.id,
            mes          : this.dblinkedSessionService.periodo.month.value,
            ano          : this.dblinkedSessionService.periodo.year.value,
            valor        : this.utilService.parseDecimal(this.vlrAjustes),
            historico    : this.historico,
            gerencial    : this.gerencial,
            usuarioId    : this.sessionService.loggedUser.id,
            excluir      : false,
            codCliFor    : this.codCliFor

          };

          this.httpService.wait();
          this.httpService.post('/custom/orcamento/post-ajustes-gerencial-pel', listaAjustes )
          .subscribe(res => {
            if(res.error){
              this.displayMessage(res);
              //this.msgs = [];
              //this.msgs.push({ severity: 'warn', summary: 'MSG: ', detail: res.message });
            }else{
              this.displayMessage(res);
              //this.msgs = [];
              //this.msgs.push({ severity: 'success', summary: 'MSG: ', detail: res.message });
              this.novoAjuste = false;
              this.getAjustesGerencial();
            }},
          error => this.httpService.handleError(error, () => this.salvarIntecompany()),
          () => this.httpService.done());
          }

    }

    excluirAjuste(idAjuste: number){

      if (confirm('Deseja realmente excluir este ajuste?')) {

        const listaAjustes = {
          idAjuste     : idAjuste,
          excluir      : true

        };

        this.httpService.wait();
        this.httpService.post('/custom/orcamento/post-ajustes-gerencial-pel', listaAjustes )
        .subscribe(res => {
          if(res.error){
            this.displayMessage(res);
            //this.msgs = [];
            //this.msgs.push({ severity: 'warn', summary: 'MSG: ', detail: res.message });

          }else{
            this.displayMessage(res);
            //this.msgs = [];
            //this.msgs.push({ severity: 'success', summary: 'MSG: ', detail: res.message });
            this.getAjustesGerencial();
            this.historico = '';
            this.vlrAjustes = 0;
            this.gerencial = '';
          }},
        error => this.httpService.handleError(error, () => this.excluirAjuste(idAjuste)),
        () => this.httpService.done());


      }

    }

    getAjustesGerencial(){


      this.httpService.wait();
      this.httpService.get('/custom/orcamento/get-ajustes-gerencial(' + this.dblinkedSessionService.empresa.id + ','
                                                                      + this.dblinkedSessionService.periodo.month.value + ','
                                                                      + this.dblinkedSessionService.periodo.year.value +')')
        .subscribe(ref => {
          this.ajustesOrcamento = [];
          ref.forEach(element => {
            this.ajustesOrcamento.push(element);
          });

        },
          error => this.httpService.handleError(error, () => this.getAjustesGerencial()),
          () => this.httpService.done());
    }

    impressao(){
      this.exibirImpressao= true;
    }


    razaoSocial(){
      this.exibirRazao= true;

      this.httpService.wait();
      this.httpService.get('/custom/orcamento/get-razao-social(' + this.dblinkedSessionService.empresa.id + ','
                                                                      + this.dblinkedSessionService.periodo.month.value + ','
                                                                      + this.dblinkedSessionService.periodo.year.value +')')
                                                                      .subscribe(ref => {
                                                                        this.listaRazoes = [];
                                                                        ref.forEach(element => {

                                                                          this.listaRazoes.push(element);
                                                                        });

                                                                      },
                                                                        error => this.httpService.handleError(error, () => this.razaoSocial()),
                                                                        () => this.httpService.done());
                                                                  }
    realizarImpressao(){

      if(this.linhasSelecionadas.length === 0){
        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'Selecione uma linha para realizar a impressão' });
        //this.msgsModal = [];
        //this.msgsModal.push({ severity: 'warn', summary: 'MSG: ', detail: 'SELECIONE UMA LINHA PARA REALIZAR A IMPRESSÃO' });
        return;
      }

      if (confirm('Deseja realmente realizar a impressão?')) {

        const listaImpressao = {
          linhas       : this.linhasSelecionadas,
          empresaId    : this.dblinkedSessionService.empresa.id,
          mes          : this.dblinkedSessionService.periodo.month.value,
          ano          : this.dblinkedSessionService.periodo.year.value,
          gerencial    : 33

        };


        this.httpService.wait();
        this.httpService.post('/custom/orcamento/impressao-linha-gerencial', listaImpressao )
        .subscribe(res => {
          if(res.error){
            this.displayMessage(res);
            //this.msgsModal = [];
            //this.msgsModal.push({ severity: 'warn', summary: 'MSG: ', detail: res.message });
          }else{
            this.displayMessage(res);
            //this.msgsModal = [];
            //this.msgsModal.push({ severity: 'success', summary: 'MSG: ', detail: res.message });
            this.windowOpen(this.httpService.url('/custom/file/' + res.file), '_blank');
          }},
        error => this.httpService.handleError(error, () => this.realizarImpressao()),
        () => this.httpService.done());


      }

    }

    windowOpen(url: string, target?: string) {
      setTimeout(() => {
        window.open(url, target), 1;
      });
    }

    visualizarRazao(value: any){
      console.log(value);
      this.exibirRazaoEdit = true;

      this.razaoSocialEditOrig = value.razaoCliFor;
      this.razaoSocialSimplificadaEditOrig = value.razaoSimplificada;
      this.codCtaContabil = value.codCta;

      this.razaoSocialEdit = value.razaoCliFor;
      this.razaoSocialSimplificadaEdit = value.razaoSimplificada;

      this.idEdit = value.id;

    }


    salvarAlteracao() {



      if (isNullOrUndefined(this.razaoSocialSimplificadaEdit) || this.razaoSocialSimplificadaEdit ===''){

        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: 'É necessário preencher o campo Razão' });
        return;

      } else if((this.alterarRazaoGeral && (this.alterarRazaoMes || this.alterarRazaoOcorrencia))
      || (this.alterarRazaoMes && (this.alterarRazaoGeral || this.alterarRazaoOcorrencia))
      || (this.alterarRazaoOcorrencia && (this.alterarRazaoGeral || this.alterarRazaoMes))){


        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: 'É permitido apenas uma alternativa' });
        return;
      } else if (!this.alterarRazaoGeral && !this.alterarRazaoMes && !this.alterarRazaoOcorrencia){
        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: 'É necessário selecionar uma alternativa' });
        return;
      }

      if (confirm('Deseja realmente salvar esta alteração?')) {

        const listaAjustes = {
          idEdit                  : this.idEdit,
          razaoSocial             : this.razaoSocialEdit,
          razaoSocialEdit         : this.razaoSocialSimplificadaEdit,
          alterarRazaoGeral       : this.alterarRazaoGeral,
          alterarRazaoMes         : this.alterarRazaoMes,
          alterarRazaoOcorrencia  : this.alterarRazaoOcorrencia,
          alterarPelaContaContabil: this.alterarPelaContaContabil,
          codCtaContabil          : this.codCtaContabil,
          usuario                 : this.sessionService.loggedUser.id
        };

        console.log(listaAjustes);

        this.httpService.wait();
        this.httpService.post('/custom/orcamento/post-ajuste-razao-social', listaAjustes )
        .subscribe(res => {
          if(res.error){


            this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: res.message });

          }else{

            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: res.message });
            this.exibirRazaoEdit = false;
            this.razaoSocial();


          }},
        error => this.httpService.handleError(error, () => this.salvarAlteracao()),
        () => this.httpService.done());


      }
    }


    displayMessage(value: any) {
      if (!value.error) {
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });
      } else {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
      }
    }



}
