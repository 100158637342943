import { isNullOrUndefined } from "util";
import { Formulario } from 'app/eventos/model/formulario.model';

export class FormularioCamposCarga{

  id                : number;
  formulario        : Formulario;
  formularioId      : number;
  nome              : string;
  cpf               : string;
  fone              : string;
  campo1            : string;
  campo2            : string;
  campo3            : string;
  campo4            : string;
  campo5            : string;
  campo6            : string;
  campo7            : string;
  campo8            : string;
  campo9            : string;
  campo10           : string;
  campo11           : string;
  campo12           : string;
  campo13           : string;
  campo14           : string;
  campo15           : string;
  campo16           : string;
  campo17           : string;
  campo18           : string;
  campo19           : string;
  campo20           : string;

  constructor(formularioCamposCarga?: FormularioCamposCarga | number) {
    if (!isNullOrUndefined(formularioCamposCarga)) {
      if (typeof formularioCamposCarga === 'number') {
        this.id = formularioCamposCarga;
      } else {
        this.id           = formularioCamposCarga.id;
        this.formulario   = formularioCamposCarga.formulario;
        this.formularioId = formularioCamposCarga.formularioId;
        this.nome         = formularioCamposCarga.nome;
        this.cpf          = formularioCamposCarga.cpf;
        this.fone         = formularioCamposCarga.fone;
        this.campo1       = formularioCamposCarga.campo1;
        this.campo2       = formularioCamposCarga.campo2;
        this.campo3       = formularioCamposCarga.campo3;
        this.campo4       = formularioCamposCarga.campo4;
        this.campo5       = formularioCamposCarga.campo5;
        this.campo6       = formularioCamposCarga.campo6;
        this.campo7       = formularioCamposCarga.campo7;
        this.campo8       = formularioCamposCarga.campo8;
        this.campo9       = formularioCamposCarga.campo9;
        this.campo10      = formularioCamposCarga.campo10;
        this.campo11      = formularioCamposCarga.campo11;
        this.campo12      = formularioCamposCarga.campo12;
        this.campo13      = formularioCamposCarga.campo13;
        this.campo14      = formularioCamposCarga.campo14;
        this.campo15      = formularioCamposCarga.campo15;
        this.campo16      = formularioCamposCarga.campo16;
        this.campo17      = formularioCamposCarga.campo17;
        this.campo18      = formularioCamposCarga.campo18;
        this.campo19      = formularioCamposCarga.campo19;
        this.campo20      = formularioCamposCarga.campo20;


      }
    }
  }

}