import { Injectable } from '@angular/core';
import { DblinkedSessionService } from './dblinked-session.service';
import { HttpService } from './http.service';
import { SessionService } from './session.service';
import { Action } from './socket/protocol/Action';
import { Method } from './socket/protocol/Method';
import { Service } from './socket/protocol/Service';
import { PysocketService } from './socket/pysocket.service';

@Injectable({
  providedIn: 'root'
})
export class AnexoUploadService {

  constructor(
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private socketService: PysocketService,
  ) { }

  fileNameValidation(name) {
    let replacedName = name.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z. ])/g, '')
    replacedName = replacedName.replaceAll(" ", "_")

    return replacedName
  }

  saveFileUpload(type: string, categoria: string, files: Array<any>, cardIdName?: string, cardId?: any, ) {
    try {
      files.forEach((item) => {
        const payload: FormData = new FormData()
        payload.append('file', item)
        payload.append('type', type)
        payload.append('projetoId', this.dblinkedSessionService.empresa.id.toString())
        payload.append('categoria', categoria)
        payload.append(cardIdName, cardId)
        payload.append('fileName', this.fileNameValidation(item.name))
        this.httpService.post('/custom/upload', payload).toPromise().then((res) => {
          const payload = {
            service: Service.APPROVAL_CENTER,
            action: Action.ATTACHMENT,
            method: Method.POST,
            content: {
              origemId: 3,
              path: res.path,
              name: this.fileNameValidation(item.name),
              userId: this.sessionService.loggedUser.id,
              cardId: cardId,
              userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
            }
          }
          this.socketService.send(payload)
        })
      })
    } catch (err) {
      return
    }
  }
}
