import { Injectable } from '@angular/core';

@Injectable()
export class JsogService {
  private nextId = 0;
  private JSOG_OBJECT_ID = '__jsogObjectId';

  constructor() {}

  private isArray(obj: any): any {
    return Array.isArray(obj) || Object.prototype.toString.call(obj) === '[object Array]';
  }

  private hasCustomJsonificaiton(obj: any): any {
    return obj.toJSON != null;
  }

  private idOf(obj: any): any {
    if (!obj[this.JSOG_OBJECT_ID]) {
        obj[this.JSOG_OBJECT_ID] = '' + (this.nextId++);
    }

    return obj[this.JSOG_OBJECT_ID];
  }

  private encodeObject(original: any, sofar: any): any {
    let result, value;
    const id = this.idOf(original);

    if (sofar[id]) {
      return {
          '@ref': id
      };
    }

    result = sofar[id] = {
      '@id': id
    };

    for (const key in original) {
      if (original.hasOwnProperty(key)) {
        value = original[key];

        if (key !== this.JSOG_OBJECT_ID) {
          result[key] = this.doEncode(value, sofar);
        }
      }
    }

    return result;
  }

  private encodeArray(original: any, sofar: any): any {
    const t = this;

    return (function() {
      const results = [];

      for (let i = 0; i < original.length; i++) {
        results.push(t.doEncode(original[i], sofar));
      }

      return results;
    })();
  }

  private doEncode(original: any, sofar: any): any {
    if (original == null) {
      return original;
    } else if (this.hasCustomJsonificaiton(original)) {
      return original;
    } else if (this.isArray(original)) {
      return this.encodeArray(original, sofar);
    } else if (typeof original === 'object') {
      return this.encodeObject(original, sofar);
    } else {
      return original;
    }
  }


  private decodeObject(encoded: any, found: any): any {
    let id, result, value;

    let ref = encoded['@ref'];

    if (ref != null) {
      ref = ref.toString();
    }

    if (ref != null) {
      return found[ref];
    }

    result = {};

    id = encoded['@id'];

    if (id != null) {
      id = id.toString();
    }

    if (id) {
      found[id] = result;
    }

    for (const key in encoded) {
      if (encoded.hasOwnProperty(key)) {
        value = encoded[key];

        if (key !== '@id') {
          result[key] = this.doDecode(value, found);
        }
      }
    }

    return result;
  }

  private decodeArray(encoded: any, found: any): any {
    const t = this;

    return (function() {
      const results = [];

      for (let i = 0; i < encoded.length; i++) {
        results.push(t.doDecode(encoded[i], found));
      }

      return results;
    })();
  }

  private doDecode(encoded: any, found: any): any {
    if (encoded == null) {
      return encoded;
    } else if (this.isArray(encoded)) {
      return this.decodeArray(encoded, found);
    } else if (typeof encoded === 'object') {
      return this.decodeObject(encoded, found);
    } else {
      return encoded;
    }
  }


  decode(encoded: any): any {
    return this.doDecode(encoded, {});
  }

  encode(original: any): any {
    return this.doEncode(original, {});
  }

  stringify(obj: any): string {
    return JSON.stringify(this.encode(obj));
  }

  parse(str: string): any {
    return this.decode(JSON.parse(str));
  }
}
