export class CodRel {
  id: number;
  cod: string;
  descricao: string;

  constructor(codrel?: CodRel | number) {
    if (codrel !== undefined && codrel !== null) {
      if (typeof codrel === 'number') {
        this.id = codrel;
      } else {
        this.id = codrel.id;
        this.cod = codrel.cod;
        this.descricao = codrel.descricao;
      }
    }
  }
}
