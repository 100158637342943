import { Component, OnInit, OnDestroy } from '@angular/core'
import { Toolbar } from 'app/shared/model/toolbar.model'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpService } from 'app/shared/services/http.service'
import { SessionService } from 'app/shared/services/session.service'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { MessageService, SelectItem } from 'primeng/api'
import { Subscription } from 'rxjs'
@Component({
  selector: 'app-aliquota-tributo',
  templateUrl: './aliquota-tributo.component.html',
  styleUrls: ['./aliquota-tributo.component.scss'],
  providers: []
})
export class AliquotaTributoComponent implements OnInit, OnDestroy {

  title: String = 'Ali­quota tributo'
  subtitle: String = 'Informações sobre aliquota tributo'

  private sessionSubscription: Subscription

  dataTable: Array<any> = new Array()
  rowSelected: Array<any> = new Array()

  formscreen: boolean = false
  tablescreen: boolean = true

  nome: string
  aliquotaImposto: number
  reducaoBc: number
  valorPauta: number
  impostoRegraFiscalValue: any

  impostoRegraFiscal: SelectItem[] = []

  toolbarMainIni = [
    { icon: 'arrow_back_ios_new', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.back() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.deleteAliquota() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.changeScreen('form', false) },
    { icon: 'add', text: 'Adicionar', tooltip: 'Adicionar', visible: true, disabled: false, color: 'green', onClick: () => this.changeScreen('form', true) },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: false, disabled: false, color: 'green', onClick: () => {} },
  ]

  public toolbarMain = new Toolbar()

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private configService: ConfigService,
    private toolbarService: ToolbarService) {}

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.loadInformations()
        this.getOptions()
      }
    )

    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
    this.sessionSubscription.remove(this.sessionSubscription)
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected
  }

  get loading(): boolean {
    return this.httpService.loading
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage
  }

  get rows() {
    return this.configService.applicationConfig.rows
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks
  }

  reload() {
    this.changeScreen('screen', false)
    this.rowSelected = []
    this.loadInformations()
    this.getOptions()
  }

  back() {
    if(confirm('As alterações feitas serão perdidas. Voltar mesmo assim?')) {
      this.changeScreen('screen', false)
    }
  }

  loadInformations() {
    // this.httpService.wait()
    this.httpService.get(`/aliquotatributo?$select=nome,impostoRegraFiscal/nome,aliquotaImposto,reducaoBc,valorPauta&$count=true&$top=50&$filter=empresa/id eq ${this.dblinkedSessionService.empresa.id}&$orderby=nome`).subscribe((res) => {
      this.dataTable = new Array()
      res.value.forEach(elem => {
        this.dataTable.push(elem)
      })
    },
      err => this.httpService.handleError(err, () => this.reload()),
      () => this.httpService.done()
    )
  }

  getOptions() {
    // this.httpService.wait()
    this.httpService.get('/impostoregrafiscal?$select=id,nome&$orderby=nome').subscribe((res) => {
      this.impostoRegraFiscal = []
      res.value.forEach(elem => {
        this.impostoRegraFiscal.push({
          label: elem.nome,
          value: elem.id
        })
      })
    },
      err => this.httpService.handleError(err, () => this.reload()),
      () => this.httpService.done()
    )
  }

  loadAliquota(add) {
    if(!add) {
      this.httpService.wait()
      this.httpService.get(`aliquotatributo(${this.rowSelected})?$select=nome,impostoRegraFiscal/id,impostoRegraFiscal/nome,aliquotaImposto,reducaoBc,valorPauta&$count=true`).subscribe((res) => {
        console.log(res.value)
        this.nome = res.value.nome
        this.aliquotaImposto = res.value.aliquotaImposto
        this.reducaoBc = res.value.reducaoBc
        this.valorPauta = res.value.valorPauta || '0.00'
        this.impostoRegraFiscalValue = res.value.impostoRegraFiscal.id
      },
        err => this.httpService.handleError(err, () => this.reload()),
        () => this.httpService.done()
      )
      this.toolbarMain.setClick('btn5', () => this.editAliquota())
    } else {
      this.nome = ''
      this.aliquotaImposto = null
      this.reducaoBc = null
      this.valorPauta = null
      this.impostoRegraFiscalValue = ''
      this.toolbarMain.setClick('btn5', () => this.saveAliquota())
    }
  }

  saveAliquota() {
    if (this.validation()) {
      return
    }

    const data = {
      aliquotaImposto: this.aliquotaImposto,
      nome: this.nome,
      reducaoBc: this.reducaoBc,
      valorPauta: this.valorPauta,
      empresa: { id: this.dblinkedSessionService.empresa.id },
      impostoRegraFiscal: { id: this.impostoRegraFiscalValue }
    }

    this.httpService.wait()
    this.httpService.post('aliquotatributo', data).subscribe((res) => {
      if (!(res === null || res === undefined)) {
        this.messageService.add({
          severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Aliquota cadastrado com sucesso!'
        })
        this.reload()
      }else{
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente!'
        })
        this.reload()
        return
      }
    },
      err => this.httpService.handleError(err, () => this.reload()),
      () => this.httpService.done()
    )
  }

  editAliquota() {
    if (this.validation()) {
      return
    }

    const data = {
      aliquotaImposto: this.aliquotaImposto.toString(),
      nome: this.nome,
      reducaoBc: this.reducaoBc.toString(),
      valorPauta: this.valorPauta.toString(),
      impostoRegraFiscal: { id: this.impostoRegraFiscalValue }
    }

    this.httpService.wait()
    this.httpService.patch('/aliquotatributo', this.rowSelected, data, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Aliquota alterada com sucesso!'
          })
          this.reload()
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração da aliquota!'
          })
          this.reload()
          return
        }
      },
      error => this.httpService.handleError(error, () => this.reload()),
      () => this.httpService.done())
  }

  deleteAliquota() {
    if (confirm('Deseja deletar o registro selecionado? ')) {
      this.httpService.wait()
      this.httpService.delete(`aliquotatributo(${this.rowSelected})`).subscribe((res) => {
        if (res === null || res === undefined) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Aliquota deletado com sucesso!'
          })
          this.reload()
        }else{
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha ao deletar aliquota. Tente novamente!'
          })
          this.reload()
          return
        }
      },
        err => this.httpService.handleError(err, () => this.reload()),
        () => this.httpService.done()
      )
    }
  }

  changeScreen(screen, add) {
    if(screen === 'form') {
      this.formscreen = true
      this.tablescreen = false
      this.loadAliquota(add)
    } else {
      this.formscreen = false
      this.tablescreen = true
      this.rowSelected = []
    }

    this.toolbarMain.setVisible('btn1', (screen === 'form'))
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', !(screen === 'form'))
    this.toolbarMain.setVisible('btn5', (screen === 'form'))
  }

  onRowSelect() {
    this.toolbarMain.setVisible('btn2', true)
    this.toolbarMain.setVisible('btn3', true)
  }

  onRowUnselect() {
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
  }

  validation() {
    if ((this.nome === null || this.nome === undefined) || this.nome.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nome é um campo obrigatório' })
      return true
    }

    if(this.aliquotaImposto === null || this.aliquotaImposto === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Aliquota é um campo obrigatório' })
      return true
    }

    if(this.reducaoBc === null || this.reducaoBc === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Redução BC é um campo obrigatório' })
      return true
    }
  }


}