import { Component, OnInit } from '@angular/core';
import { Uf } from '../model/uf.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { MessageService } from 'primeng/api';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { SessionService } from 'app/shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Subscription } from 'rxjs/Subscription';
import { Empresa } from 'app/cadastro/model/empresa.model';

@Component({
  selector: 'app-uf',
  templateUrl: './uf.component.html',
  styleUrls: ['./uf.component.scss'],
  providers: [CrudService]
})
export class UfComponent extends DataTableBasicComponent implements OnInit {
  // public uf: Uf = new Uf();
  public registrosUf: Array<Uf> = new Array();

  private sessionSubscription: Subscription;

  ctrlSave: boolean;
  entityFilter: string = null;
  editarConta: boolean;
  novaConta: boolean;
  ids: Array<any> = new Array();
  selectUF: Array<any> = new Array();

  title = 'Uf';
  subtitle = "Uf";

  codigoIbge: any;
  uf: string;
  periodo: any;

  constructor(crudService: CrudService,
    private messageService: MessageService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private configService: ConfigService,
    private httpService: HttpService) {
    super(crudService);
  }

  public toolbarMain = new Toolbar();

  toolbarMainIni = [
    { key: 'voltar',  icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { key: 'saveNew', icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { key: 'save', icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]



  ngOnInit() {
    this.toolbarService.hidden = true;
    this.toolbarMain.setAll(this.toolbarMainIni);

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
      );
  }

  carregar() {
    this.title = 'Uf';
    this.subtitle = 'Uf';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.selectUF = new Array();

    this.limpaCamposForm();

    this.toolbarInitial();

    this.loadRegistros();
  }

  toolbarInitial(){
    this.toolbarMain.setVisible('voltar', false)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.toolbarMain.setVisible('add', true)
    this.toolbarMain.setVisible('saveNew', false)
    this.toolbarMain.setVisible('save', false)
  }

  toolbarNew(){
    this.toolbarMain.setVisible('voltar', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    this.toolbarMain.setVisible('add', false)
    this.toolbarMain.setVisible('saveNew', true)
    this.toolbarMain.setVisible('save', false)
  }

  toolbarEdit(){
    this.toolbarMain.setVisible('voltar', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', true)
    this.toolbarMain.setVisible('add', false)
    this.toolbarMain.setVisible('saveNew', false)
    this.toolbarMain.setVisible('save', true)
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar';

    this.toolbarNew();

  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService
      .get(`/uf?$select=uf,codIbge&$count=true&$orderby=uf`)
      .subscribe(
        (retorno) => {
          this.registrosUf = new Array();
          retorno.value.forEach(item => {
            this.registrosUf.push(item);
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar';
    this.editarConta = true;
    this.selectUF = []

    this.limpaCamposForm();

    this.toolbarEdit();

    this.httpService.wait();
    this.httpService.get(`/uf(${this.ids[0]})?$select=uf,codIbge,periodo&$count=true`)
      .subscribe(item => {
        this.codigoIbge = item.value.codIbge;
        this.uf = item.value.uf;
        this.periodo = item.value.periodo;
      },
      error => this.httpService.handleError(error, () => this.carregar()),
      () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonEnvio = {
      codIbge: this.codigoIbge,
      uf: this.uf,
      periodo: this.periodo
    }

    this.httpService.wait();
    this.httpService.post('/uf', jsonEnvio)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonEnvio = {
      codIbge: this.codigoIbge,
      uf: this.uf,
      periodo: this.periodo
    }

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/uf', key, jsonEnvio, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      this.httpService.wait();
      this.httpService.delete('/uf', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Registro excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
    }
  }

  onRowSelect(event: any) {
    this.ids = new Array();
    this.ids.push(event.data.id);
    event.selected = false;
    this.toolbarMain.setVisible('edit', true)
    this.toolbarMain.setVisible('delete', true)
  }

  onRowUnselect(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
  }

  validacao(): boolean {
    if (this.codigoIbge === undefined || this.codigoIbge === null || this.codigoIbge == '' || this.codigoIbge.length != 2) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código inválido' });
      return true;
    }

    if (this.uf === undefined || this.uf === null || this.uf == '' || this.uf.length != 2) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Uf inválida' });
      return true;
    }

    if (this.periodo === undefined || this.periodo === null || this.periodo == '') {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Periodo inválida' });
      return true;
    }

    if (this.periodo.length != 8) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Periodo deve conter 8 dígitos' });
      return true;
    }

    return false;
  }

  provisorio(){}

  private limpaCamposForm() {
    this.periodo = null;
    this.codigoIbge = null;
    this.uf = null;
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
