import { Empresa } from 'app/cadastro/model/empresa.model';
import { ConfiguracoesApis} from 'app/cadastro/model/configuracoes-apis.model'
import { PlataformaPagamentos } from "app/util/model/plataforma-pagamentos.model";
import { PlataformaPagamentosServicos } from "app/util/model/plataforma-pagamentos-servicos.model";

export class TabelaCrm {
  id: number;
  empresa : Empresa;
  configuracoesApis: ConfiguracoesApis;
  descricao : string;
  plataformaPagamentos : PlataformaPagamentos;
  plataformaPagamentosServicos : PlataformaPagamentosServicos;
  plataformaPagamentosId : number;
  plataformaPagamentosServicosId : number;
  configuracoesApisId : number;

  constructor(tabelaCrm?: TabelaCrm | number) {
    if (tabelaCrm !== undefined && tabelaCrm !== null) {
      if (typeof tabelaCrm === 'number') {
        this.id = tabelaCrm;
      } else {
        this.id = tabelaCrm.id;
        this.empresa = tabelaCrm.empresa;
        this.configuracoesApis = tabelaCrm.configuracoesApis;
        this.descricao = tabelaCrm.descricao;
        this.plataformaPagamentosServicos = tabelaCrm.plataformaPagamentosServicos;
        this.plataformaPagamentos = tabelaCrm.plataformaPagamentos;
        this.plataformaPagamentosId = tabelaCrm.plataformaPagamentosId;
        this.plataformaPagamentosServicosId = tabelaCrm.plataformaPagamentosServicosId;
        this.configuracoesApisId = tabelaCrm.configuracoesApisId;

      }
    }
  }
}
