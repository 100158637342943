import { Regra } from 'app/cadastro/model/regra.model';
import { ViewCampos } from 'app/cadastro/model/view-campos.model';

export class RegraCamposSelect {
  id: number;
  regra: Regra;
  viewCampos: ViewCampos;
  campoFormula: string;
  campoFormulaTipo: string;
  ordem: number;

  constructor(regraCamposSelect?: RegraCamposSelect | number) {
    if (regraCamposSelect !== undefined && regraCamposSelect !== null) {
      if (typeof regraCamposSelect === 'number') {
        this.id = regraCamposSelect;
      } else {
        this.id = regraCamposSelect.id;
        this.regra = regraCamposSelect.regra;
        this.viewCampos = regraCamposSelect.viewCampos;
        this.campoFormula = regraCamposSelect.campoFormula;
        this.campoFormulaTipo = regraCamposSelect.campoFormulaTipo;
        this.ordem = regraCamposSelect.ordem;
      }
    }
  }
}
