import { isNullOrUndefined } from "util";
import {Eventos} from 'app/eventos/model/eventos.model';
import {Plano} from 'app/eventos/model/plano.model';
export class GetNetPlano{

  id                : number;
  descricao         : string;
  evento            : Eventos;
  eventoId          : number;
  plano             : Plano;
  planoId           : number;
  amount            : number;
  description       : string;
  salesTax          : number;
  getnetPlanId      : string;
  productType       : string;
  status            : string;
  createDate        : Date;
  createDatePt      : string;
  sellerId          : string;
  installment       : number;
  billingCycle      : number;
  getnetType        : string;


  constructor(getNetPlano?: GetNetPlano | number) {
    if (!isNullOrUndefined(getNetPlano)) {
      if (typeof getNetPlano === 'number') {
        this.id = getNetPlano;
      } else {
        this.id             = getNetPlano.id;
        this.descricao      = getNetPlano.descricao;
        this.evento         = getNetPlano.evento;
        this.eventoId       = getNetPlano.eventoId;
        this.plano          = getNetPlano.plano;
        this.planoId        = getNetPlano.planoId;
        this.amount         = getNetPlano.amount;
        this.description    = getNetPlano.description;
        this.salesTax       = getNetPlano.salesTax;
        this.getnetPlanId   = getNetPlano.getnetPlanId;
        this.productType    = getNetPlano.productType;
        this.status         = getNetPlano.status;
        this.createDate     = getNetPlano.createDate;
        this.sellerId       = getNetPlano.sellerId;
        this.installment    = getNetPlano.installment;
        this.billingCycle   = getNetPlano.billingCycle;
        this.getnetType     = getNetPlano.getnetType;
        this.createDatePt   = getNetPlano.createDatePt;

      }
    }
  }

}