export class CodigosCBenef {
  id: number;
  codigo: string;
  descricao: string;

  constructor(codigosCBenef?: CodigosCBenef | number) {
    if (codigosCBenef !== codigosCBenef && codigosCBenef !== null) {
      if (typeof codigosCBenef === 'number') {
        this.id = codigosCBenef;
      } else {
        this.id = codigosCBenef.id;
        this.codigo = codigosCBenef.codigo;
        this.descricao = codigosCBenef.descricao;
      }
    }
  }
}
