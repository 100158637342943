import { MovParteACsll } from 'app/ecf/model/mov-parte-a-csll.model';
import { MovSaldosContabeis } from 'app/contabil/model/mov-saldos-contabeis.model';

export class TbRelacMovParteACsllSaldosContabeis {
    id: number;
    movParteACsll?: MovParteACsll;
    movSaldosContabeis?: MovSaldosContabeis;
    saldoRelacionado: number;

    saldoRelacionadoEditing: number = null;
    changed = false;

    constructor(tbRelacMovParteACsllSaldosContabeis?: TbRelacMovParteACsllSaldosContabeis | number) {
        if (tbRelacMovParteACsllSaldosContabeis !== undefined && tbRelacMovParteACsllSaldosContabeis !== null) {
            if (typeof tbRelacMovParteACsllSaldosContabeis === 'number') {
                this.id = tbRelacMovParteACsllSaldosContabeis;
            } else {
                this.id = tbRelacMovParteACsllSaldosContabeis.id;
                this.movParteACsll = tbRelacMovParteACsllSaldosContabeis.movParteACsll || new MovParteACsll();
                this.movSaldosContabeis = tbRelacMovParteACsllSaldosContabeis.movSaldosContabeis || new MovSaldosContabeis();
                this.saldoRelacionado = tbRelacMovParteACsllSaldosContabeis.saldoRelacionado;

                this.saldoRelacionadoEditing = tbRelacMovParteACsllSaldosContabeis.saldoRelacionadoEditing || null;
                this.changed = tbRelacMovParteACsllSaldosContabeis.changed || false;
            }
        }
    }
}
