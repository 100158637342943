import { CadAglut } from 'app/contabil/model/cad-aglut.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';

export class CadAglutRelac {
  id: number;
  cadAglut: CadAglut = new CadAglut();
  planoContas: PlanoContas = new PlanoContas();
  cadCcus: CadCcus = new CadCcus();

  constructor(cadaglutrelac?: CadAglutRelac | number) {
    if (cadaglutrelac !== undefined && cadaglutrelac !== null) {
      if (typeof cadaglutrelac === 'number') {
        this.id = cadaglutrelac;
      } else {
          this.id = cadaglutrelac.id;
          this.cadAglut = cadaglutrelac.cadAglut || new CadAglut();
          this.planoContas = cadaglutrelac.planoContas || new PlanoContas();
          this.cadCcus = cadaglutrelac.cadCcus || new CadCcus();
      }
    }
  }
}
