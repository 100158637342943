import { Empresa } from '../../cadastro/model/empresa.model';
import { PrioridadeRequisicao } from './prioridade-requisicao.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Moeda } from 'app/util/model/moeda.model';
import { Origem } from 'app/util/model/origem.model';
import { Nda } from './nda.model';
import { Objeto } from './objeto.model';
import { RequisicaoStatus } from './requisicao-status.model';
import { RequisicaoItemRateio } from './requisicao-item-rateio.model';
import { RequisicaoItem } from './requisicao-item.model';
import { RequisicaoFornecedorSugerido } from './requisicao-fornecedor-sugerido.model';
import { Categoria } from './categoria.model';
import { Tipo } from './tipo.model';
import { Padis } from './padis.model';
import { LeiDoBem } from './lei-do-bem.model';
import { TipoAnexo } from './tipo-anexo.model';
import { AprovadorTecnico } from './aprovador-tecnico.model';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { FormaPagamento } from 'app/util/model/forma-pagamento.model';

export class Requisicao {
  id: number;
  titulo: string;
  descricao: string;
  prioridade: PrioridadeRequisicao;
  requisitante: AuthUser;
  dtEntrega: Date;
  dtEmissao: Date
  dtFaturamentoAutomatico: Date
  origem: Origem;
  moeda: Moeda;
  nda: Nda;
  objeto: Objeto;
  objetoOutro: string;
  necessidade: string;
  resultadoEsperado: string;
  compraDirigida: string;
  requisicaoStatus: RequisicaoStatus;
  gestorAprovador: AuthUser;
  empresa: any;
  categoria: Categoria;
  tipo: Tipo;
  padis: Padis;
  leiDoBem: LeiDoBem;
  tipoAnexo: TipoAnexo;
  tipoPagamento: number;
  empresaConta: EmpresaConta = new EmpresaConta();
  formaPagamento: FormaPagamento = new FormaPagamento();
  aprovadorTecnico: AprovadorTecnico;
  expresso: boolean; //Coluna expresso
  txMoeda: any;

  pedidoParcela: any = new Array();

  pedidoItem: Array<RequisicaoItem> = new Array();

  sugestaoFornecedores: Array<RequisicaoFornecedorSugerido> = new Array();

  infos: any;

  constructor(requisicao?: Requisicao | number) {
    if (requisicao !== undefined && requisicao !== null) {
      if (typeof requisicao === 'number') {
        this.id = requisicao;
      } else {
        this.id = requisicao.id;
        this.titulo = requisicao.titulo;
        this.descricao = requisicao.descricao;
        this.prioridade = requisicao.prioridade;
        this.requisitante = requisicao.requisitante;
        this.dtEntrega = requisicao.dtEntrega;
        this.origem = requisicao.origem;
        this.moeda = requisicao.moeda;
        this.nda = requisicao.nda;
        this.objeto = requisicao.objeto;
        this.objetoOutro = requisicao.objetoOutro;
        this.necessidade = requisicao.necessidade;
        this.resultadoEsperado = requisicao.resultadoEsperado;
        this.compraDirigida = requisicao.compraDirigida;
        this.requisicaoStatus = requisicao.requisicaoStatus;
        this.gestorAprovador = requisicao.gestorAprovador;
        this.empresa = requisicao.empresa;
        this.categoria = requisicao.categoria;
        this.tipo = requisicao.tipo;
        this.padis = requisicao.padis;
        this.leiDoBem = requisicao.leiDoBem;
        this.tipoAnexo = requisicao.tipoAnexo;
        this.pedidoItem = requisicao.pedidoItem;
        this.sugestaoFornecedores = requisicao.sugestaoFornecedores;
        this.aprovadorTecnico = requisicao.aprovadorTecnico;
        this.expresso = requisicao.expresso;
        this.infos = requisicao.infos;
        this.tipoPagamento = requisicao.tipoPagamento;
        this.empresaConta = requisicao.empresaConta;
        this.formaPagamento = requisicao.formaPagamento;
        this.pedidoParcela = requisicao.pedidoParcela;
        this.dtEmissao = requisicao.dtEmissao;
        this.dtFaturamentoAutomatico = requisicao.dtFaturamentoAutomatico;
        this.txMoeda = requisicao.txMoeda;
      }
    }
  }
}
