import { Empresa } from "app/cadastro/model/empresa.model";
import { isNullOrUndefined } from "util";
import { TipoOrcamento } from "./tipo-orcamento.model";
import { Versao } from "./versao.model";
import { PlanoGerencial } from "app/contabil/model/plano-gerencial.model";
import { TipoVisao } from "./tipo-visao.model";

export class ConfiguracaoImportacao{
  id : number;
  descricao : string;
  empresa : Empresa;
  tipoOrcamento : TipoOrcamento;
  planoGerencial: PlanoGerencial;
  selecionarGerencial : boolean;
  selecionarContabil : boolean;
  selecionarCentroCusto : boolean;
  selecionarProjeto : boolean;
  relatorioPbi : number;
  selecionarArea : boolean;
  selecionarSubarea : boolean;
  visaoNivel1 : TipoVisao;
  visaoNivel2 : TipoVisao;
  visaoNivel3 : TipoVisao;
  visaoNivel4 : TipoVisao;
  visaoNivel5 : TipoVisao;
  dadosReaisErp : boolean;
  isNovoRegistro : boolean;
  taxaCorporacao : boolean;
  ptax : boolean
  visaoMensal : boolean;
  visaoDetalhada : boolean;
  visaoYtd : boolean;
  esconderLinhas : boolean;
  podeConsolidar: boolean;
  podeMovimentar: boolean;
  versaoReal: Versao

  constructor(configuracaoImportacao?: ConfiguracaoImportacao | number){

    if (!isNullOrUndefined(configuracaoImportacao)) {

      if (typeof  configuracaoImportacao === 'number') {
        this.id = configuracaoImportacao;
      } else {
        this.id = configuracaoImportacao.id;
        this.descricao = configuracaoImportacao.descricao;
        this.empresa = configuracaoImportacao.empresa;
        this.tipoOrcamento = configuracaoImportacao.tipoOrcamento;
        this.selecionarGerencial = configuracaoImportacao.selecionarGerencial;
        this.selecionarContabil = configuracaoImportacao.selecionarContabil;
        this.selecionarCentroCusto = configuracaoImportacao.selecionarCentroCusto;
        this.selecionarProjeto = configuracaoImportacao.selecionarProjeto;
        this.planoGerencial = configuracaoImportacao.planoGerencial;
        this.isNovoRegistro = configuracaoImportacao.isNovoRegistro;
        this.relatorioPbi = configuracaoImportacao.relatorioPbi;
        this.selecionarArea = configuracaoImportacao.selecionarArea;
        this.selecionarSubarea = configuracaoImportacao.selecionarSubarea;
        this.visaoNivel1 = configuracaoImportacao.visaoNivel1;
        this.visaoNivel2 = configuracaoImportacao.visaoNivel2;
        this.visaoNivel3 = configuracaoImportacao.visaoNivel3;
        this.visaoNivel4 = configuracaoImportacao.visaoNivel4;
        this.visaoNivel5 = configuracaoImportacao.visaoNivel5;
        this.dadosReaisErp = configuracaoImportacao.dadosReaisErp;
        this.taxaCorporacao = configuracaoImportacao.taxaCorporacao;
        this.ptax = configuracaoImportacao.ptax;
        this.visaoDetalhada = configuracaoImportacao.visaoDetalhada;
        this.visaoMensal = configuracaoImportacao.visaoMensal;
        this.visaoYtd = configuracaoImportacao.visaoYtd;
        this.esconderLinhas = configuracaoImportacao.esconderLinhas;
        this.podeConsolidar = configuracaoImportacao.podeConsolidar;
        this.podeMovimentar = configuracaoImportacao.podeMovimentar;
        this.versaoReal = configuracaoImportacao.versaoReal
      }
    }
  }
}
