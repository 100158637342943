export class Tipi {
  id: number;
  ncm: string;
  ex: number;
  descricao: string;
  aliqIpi: string;
  capitulo: string;
  limitereintegra: string;
  dtIni: Date;
  dtFim: Date;
  padis: string;

  constructor(tipi?: Tipi | number) {
    if (tipi !== undefined && tipi !== null) {
      if (typeof tipi === 'number') {
        this.id = tipi;
      } else {
        this.id = tipi.id;
        this.ncm = tipi.ncm;
        this.ex = tipi.ex;
        this.descricao = tipi.descricao;
        this.aliqIpi = tipi.aliqIpi;
        this.capitulo = tipi.capitulo;
        this.limitereintegra = tipi.limitereintegra;
        this.dtIni = tipi.dtIni;
        this.dtFim = tipi.dtFim;
        this.padis = tipi.padis;
      }
    }
  }
}
