import { CadHistPadrao } from 'app/contabil/model/cad-hist-padrao.model';
import { CadParticipante } from 'app/contabil/model/cad-participante.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';

export class MovLctoContabil {
  id: number;
  numLcto: string;
  lote: string;
  dtLcto: Date;
  vlLcto: number;
  indDc: string;
  numArq: string;
  cadHistPadrao: CadHistPadrao = new CadHistPadrao();
  histCompl: string;
  cadParticipante: CadParticipante = new CadParticipante();
  indLcto: string;
  cadCcus: CadCcus = new CadCcus();
  planoContas: PlanoContas = new PlanoContas();

  constructor(movlctocontabil?: MovLctoContabil | number) {
    if (movlctocontabil !== undefined && movlctocontabil !== null) {
      if (typeof movlctocontabil === 'number') {
        this.id = movlctocontabil;
      } else {
        this.id = movlctocontabil.id;
        this.numLcto = movlctocontabil.numLcto;
        this.lote = movlctocontabil.lote;
        this.dtLcto = movlctocontabil.dtLcto;
        this.vlLcto = movlctocontabil.vlLcto;
        this.indDc = movlctocontabil.indDc;
        this.numArq = movlctocontabil.numArq;
        this.cadHistPadrao = movlctocontabil.cadHistPadrao || new CadHistPadrao();
        this.histCompl = movlctocontabil.histCompl;
        this.cadParticipante = movlctocontabil.cadParticipante || new CadParticipante();
        this.indLcto = movlctocontabil.indLcto;
        this.cadCcus = movlctocontabil.cadCcus || new CadCcus();
        this.planoContas = movlctocontabil.planoContas || new PlanoContas();
      }
    }
  }
}
