import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { LazyLoadEvent, MessageService, SelectItem } from '../../../../node_modules/primeng/api';
import { HttpService } from '../../shared/services/http.service';
import { CnpjPipe } from 'app/shared/pipes/cnpj.pipe';

@Component({
  selector: 'app-relatorio',
  templateUrl: './relatorio.component.html',
  styleUrls: ['./relatorio.component.scss'],
})

export class RelatorioComponent implements OnInit, OnDestroy {

  ////////////////////////////
  // Refator: 11/01/2022    //
  // Por: Simon Lallement   //
  ////////////////////////////

  private sessionSubscription: Subscription
  private cnpjPipe: CnpjPipe = new CnpjPipe()

  //Date
  dtCorte: Date = new Date()
  dtInit: Date = new Date()
  dtFin: Date = new Date()

  //String
  titlePrincipal: string = 'RELATÓRIOS FINANCEIROS'

  //Boolean
  statusAsaas: boolean = false
  statusCr: boolean = false
  statusCp: boolean = false
  statusAdf: boolean = false
  statusAdc: boolean = false
  statusRR: boolean = false
  statusRP: boolean = false
  statusBo: boolean = false
  statusMov: boolean = false
  statusMovRat: boolean = false
  datasIndividuais: boolean = false
  modalRelatorio: boolean = false
  titulosAberto: boolean = false
  modalParticipante: boolean = false
  semParticipante: boolean = true
  baixados: boolean = true
  visuProjetos: boolean = false
  consolidaFilial: boolean = false

  //Dropdown/Multiselect
  opcoesParticipantes: SelectItem[] = []
  opcoesContas: SelectItem[] = []
  opcoesEmpresas: SelectItem[] = []
  opcoesFluxoCaixa: SelectItem[] = []

  //Array
  liparticipantes: Array<number> = new Array()
  listaRelat: Array<any> = new Array()
  listaParticipantes: Array<any> = new Array()
  selectedParticipantes: Array<any> = new Array()
  empresasSelecionadas: Array<any> = new Array()
  contasSelecionadas: Array<any> = new Array()

  //Miscellaneous
  dicRelatComposto: any = {}
  relatorioGerSelecionado: any = {}

  //Number
  contaSelecionada: number = 0
  empresaId: number = null
  totalParticipantes: number = 0
  participanteTipoId: number = null
  fluxoSelecionado: number = null

  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.toolbarService.hidden = true
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
          this.empresaId = this.dblinkedSessionService.empresa.id
          this.getConfigRelatorios();
        }
      })
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  baixarAdiantamentos(tipo: string) {
    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      dt_corte: this.dtCorte.getFullYear() + "-"
        + ((this.dtCorte.getMonth() + 1) < 10 ? "0" + (this.dtCorte.getMonth() + 1) : (this.dtCorte.getMonth() + 1)) + "-"
        + (this.dtCorte.getDate() < 10 ? "0" + this.dtCorte.getDate() : this.dtCorte.getDate()),
      tipo: tipo,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/relat-adiantamentos', infos)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }

          this.exibirMensagem(res)
          this.httpService.done()
          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          };

          this.httpService.wait();
          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/relat-adiantamentos', httpOptions2)
            .subscribe(
              (res: Blob) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                let fileName = 'relatorio_adiantamentos.xlsx'

                link.href = url
                link.download = fileName
                link.click()

                window.URL.revokeObjectURL(url)
                this.httpService.done()
              }, err => {
                this.exibirMensagem({ error: true, mensagem: "Erro no API ao baixar o relatporio de adiantamentos, contate o suporte: " + err.message })
                console.log(err)
                this.httpService.done()
              })
        },
        err => {
          this.exibirMensagem("Erro no API ao gerar o relatório, contate o suporte: " + err.message)
          console.log(err)
          this.httpService.done()
        }
      );
  }

  baixarTitRel(tipo: string) {

    const payload = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      dt_corte: this.dtCorte.getFullYear() + "-" + (this.dtCorte.getMonth() + 1) + "-" + this.dtCorte.getDate(),
      tipo: tipo,
      user_id: this.sessionService.loggedUser.id,
      consolidaFilial: this.consolidaFilial,
      filiaisSelecionadas: this.dblinkedSessionService.filiaisSelecionadas
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/relat-titulos', payload)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()
            return
          }

          this.exibirMensagem(res)
          this.httpService.done()
          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          };

          this.httpService.wait()
          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/relat-titulos', httpOptions2).subscribe(
            (res: Blob) => {
              let link = document.createElement('a')
              let url = window.URL.createObjectURL(res)
              let fileName = 'relatorio_cr_cp.xlsx'

              link.href = url
              link.download = fileName
              link.click()

              window.URL.revokeObjectURL(url)
              this.httpService.done()
            }, err => {
              this.exibirMensagem({ error: true, mensagem: "Erro no API ao baixar o relatório, contate o suporte: " + err.message })
              console.log(err)
              this.httpService.done()
            });
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao gerar o relatório, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  baixarRelatorio(tipo: number) {
    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      dt_init: this.dtInit.getFullYear() + "-" + (this.dtInit.getMonth() + 1) + "-" + this.dtInit.getDate(),
      dt_fin: this.dtFin.getFullYear() + "-" + (this.dtFin.getMonth() + 1) + "-" + this.dtFin.getDate(),
      participantes: this.liparticipantes,
      tipo: tipo === 0 ? "CP" : "CR",
      titulos_abertos: this.titulosAberto,
      consolidaFilial: this.consolidaFilial,
      filiaisSelecionadas: this.dblinkedSessionService.filiaisSelecionadas,
      userId: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/relat-contas', infos)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
          this.liparticipantes = new Array()
          this.semParticipante = true
          if (res["error"]) return

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          };

          this.httpService.wait();
          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/relat-contas', httpOptions2).subscribe(
            (res: Blob) => {
              let link = document.createElement('a')
              let url = window.URL.createObjectURL(res)
              let fileName = "relatorio_pag_receb.xlsx"

              link.href = url
              link.download = fileName
              link.click()

              window.URL.revokeObjectURL(url)
              this.httpService.done()
            }, err => {
              this.exibirMensagem({ error: true, mensagem: "Erro no API ao baixar o relatório, contate o suporte: " + err.message })
              console.log(err)
              this.httpService.done()
            });
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao gerar o relatório, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  baixarRelatorioBo() {
    const listasEnviar = {
      empresa: this.dblinkedSessionService.empresa.id,
      mesIni: this.dtCorte.getFullYear() + "-" + (this.dtCorte.getMonth() + 1) + "-" + this.dtCorte.getDate(),
      ano: this.dblinkedSessionService.periodo.year.value,
      remessa: this.titulosAberto,

    };

    this.httpService.wait()
    this.httpService.post('custom/ecd-report/impressao-bordero', listasEnviar)
      .subscribe(
        result => {
          if (result.error) return
          window.open(this.httpService.url('/custom/file/' + result.file + '/financeiro'), '_blank')
          this.httpService.done()
        },
        error => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao gerar o relatório, contate o suporte: " + error.message })
          console.log(error)
          this.httpService.done()
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem })

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem })
    }
  }

  showDialogRelatorio(tipo) {
    if (tipo === 'CP') {
      this.statusCp = true
      this.dtCorte = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
    }
    else if (tipo === 'CR') {
      this.statusCr = true
      this.dtCorte = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
    }
    else if (tipo === 'ADF') {
      this.statusAdf = true
      this.dtCorte = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
    }
    else if (tipo === 'ADC') {
      this.statusAdc = true
      this.dtCorte = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
    }
    else if (tipo === 'RR') {
      this.statusRR = true
      this.dtInit = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dtFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
      this.selectedParticipantes = new Array();
      this.liparticipantes = new Array();
      this.semParticipante = true
      this.titulosAberto = false

    } else if (tipo === 'RP') {
      this.statusRP = true
      this.dtInit = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dtFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
      this.selectedParticipantes = new Array();
      this.liparticipantes = new Array();
      this.semParticipante = true
      this.titulosAberto = false

    } else if (tipo === 'BO') {
      this.statusBo = true
      this.dtCorte = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
      this.titulosAberto = false

    }
    else if (tipo === 'MOV') {
      this.statusMov = true
      this.dtInit = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dtFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
      this.getContasBancarias()

    } else if (tipo === 'ASAAS') {
      this.statusAsaas = true
      this.dtInit = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dtFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)

    } else if (tipo === 'MOV_RAT') {
      this.statusMovRat = true
      this.baixados = true
      this.getListEmpresas()
      this.getFluxoCaixa()
      this.dtInit = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dtFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)

    } else {
      this.relatorioGerSelecionado = tipo;
      this.modalRelatorio = true;
      this.dtCorte = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
    }
  }

  getContasBancarias() {
    this.httpService.wait();
    this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,instituicaoFinanceira,tipoConta/id,ativo&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
      .subscribe(
        ref => {
          this.opcoesContas = [];
          this.opcoesContas.push({ label: 'Nenhum', value: null });
          ref.value.forEach(element => {
            if (element.ativo) {
              if (element.instituicaoFinanceira) {
                this.opcoesContas.push({
                  label: element.nome + ' - AG: ' + element.agencia + ' - CC: '
                    + element.conta + '-' + element.dvConta, value: element.id
                });
              } else {
                this.opcoesContas.push({ label: element.nome, value: element.id });
              }
            }
          });
          this.httpService.done()
        },
        error => {
          console.log(error)
          this.exibirMensagem({ error: true, mensagem: "Erro ao recuperar as contas bancarias, contate o suporte: " + error.message })
          this.httpService.done()
        });
  }

  baixarRelatorioMov() {
    const infos = {
      empresaContaId: this.contaSelecionada,
      empresaContaPadraoId: [this.contaSelecionada],
      dtIni: this.dtInit.getFullYear() + "-" +
        ((this.dtInit.getMonth() + 1) < 10 ? "0" + (this.dtInit.getMonth() + 1) : (this.dtInit.getMonth() + 1)) + "-" +
        (this.dtInit.getDate() < 10 ? "0" + this.dtInit.getDate() : this.dtInit.getDate()),
      dtFin: this.dtFin.getFullYear() + "-" +
        ((this.dtFin.getMonth() + 1) < 10 ? "0" + (this.dtFin.getMonth() + 1) : (this.dtFin.getMonth() + 1)) + "-" +
        (this.dtFin.getDate() < 10 ? "0" + this.dtFin.getDate() : this.dtFin.getDate()),
      empresaId: this.dblinkedSessionService.empresa.id,
      filiaisSelecionadas: this.dblinkedSessionService.filiaisSelecionadas,
      userId: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/relatorio-mov-bancaria', infos)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
          if (res["error"]) return
          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),

            responseType: 'blob' as 'json'
          };

          this.httpService.wait();
          this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/relatorio-mov-bancaria', httpOptions2).subscribe(
            (res: any) => {
              let link = document.createElement('a')
              let url = window.URL.createObjectURL(res)
              let fileName = "Relatório de movimentações.xlsx"
              link.href = url
              link.download = fileName
              link.click()

              window.URL.revokeObjectURL(url)
              this.httpService.done()
            }, err => {
              this.exibirMensagem({ error: true, mensagem: "Erro ao baixar o relatório de movimentações, contate o suporte: " + err.message })
              console.log(err)
              this.httpService.done()
            });
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao gerar o relatório de movimentações, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  getConfigRelatorios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'modulo': '3'
      })
    };

    this.httpService.wait()
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            this.listaRelat = new Array();
            for (var line of res["mensagem"]) {
              this.listaRelat.push(line);
            }

            this.dicRelatComposto = res["dicconfigs_compostas"];
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao buscar os relatórios gerenciais, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        }
      );
  }

  gerarRelatorioGerencialExcel() {
    let config_list = [];
    if (this.relatorioGerSelecionado.is_composta) {
      for (let conf of this.dicRelatComposto[this.relatorioGerSelecionado.id]) {
        if (this.datasIndividuais && conf['dataRef'] == null) {
          this.exibirMensagem({ error: true, mensagem: "Informe uma data para todos os relatórios ou desmarque a opção de datas individuais." });
          return;
        }

        config_list.push({
          'empresa_id': this.dblinkedSessionService.empresa.id,
          'dt_ref': this.datasIndividuais ? [conf['dataRef'].getDate(), conf['dataRef'].getMonth() + 1, conf['dataRef'].getFullYear()] : [this.dtCorte.getDate(), this.dtCorte.getMonth() + 1, this.dtCorte.getFullYear()],
          'config_id': conf['config_relat_ref_id'],
          'worksheet_title': conf['worksheet_title']
        });
      }
    } else {
      config_list = [{
        'empresa_id': this.dblinkedSessionService.empresa.id,
        'dt_ref': [this.dtCorte.getDate(), this.dtCorte.getMonth() + 1, this.dtCorte.getFullYear()],
        'config_id': this.relatorioGerSelecionado.id
      }];
    }

    const infos = {
      list_infos: config_list,
      is_composta: this.relatorioGerSelecionado.is_composta,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', infos)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
          if (res["error"]) return

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userid': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          };

          this.httpClient.get(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', httpOptions2)
            .subscribe(
              (res: Blob) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                let fileName = "Relatório gerencial.xlsx"
                link.href = url
                link.download = fileName
                link.click()
                window.URL.revokeObjectURL(url)
                this.httpService.done()
              },
              err => {
                this.exibirMensagem({ "error": true, "mensagem": "Erro no API ao baixar o relatório, contate o suporte: " + err.message })
                console.log(err)
                this.httpService.done()
              });
        },
        err => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API ao gerar o relatório, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        });
  }

  renderizarRelatorioGerencial() {
    var data_ref: string = this.dtCorte.getDate() + '-' + (this.dtCorte.getMonth() + 1).toString() + '-' + this.dtCorte.getFullYear();
    window.open('/workstats/render-relatorios-gerenciais/' + this.dblinkedSessionService.empresa.id.toString() + '/' + this.relatorioGerSelecionado.id + '/' + this.relatorioGerSelecionado.plano_gerencial_id + '/' + data_ref, '_blank');
  }

  loadParticipantes(tipo: number) {
    this.participanteTipoId = tipo
    this.modalParticipante = true
    // this.getParticipantes()
  }

  carregarValores(config) {
    let replicar = false;

    for (let dicConfig of this.dicRelatComposto[this.relatorioGerSelecionado.id]) {
      if (config['id'] === dicConfig['id']) replicar = true;

      if (replicar) {
        dicConfig['dataRef'] = config['dataRef'];
      }
    }
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  getParticipantes(event: LazyLoadEvent = {}) {
    const paramload = {
      offset: event.first,
      limit: event.rows,
      sort_order: event.sortOrder,
      sort_field: event.sortField,
      filters: event.filters,
      participante_tipo_id: this.participanteTipoId
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'get_distinct_participants/' + this.dblinkedSessionService.empresa.id, paramload)
      .subscribe(res => {
        if (res["severity"] === "error") {
          this.exibirMensagem(res)

        } else {
          this.listaParticipantes = []
          res["data"]["participantes"].forEach(element => { this.listaParticipantes.push(element) })
          this.totalParticipantes = res["data"]["total_part"];
        }

        this.httpService.done();

      }, error => {
        this.exibirMensagem({ severity: 'error', message: "Erro no API, contate o suporte: " + error.message });
        console.log(error)
        this.httpService.done();
      });
  }

  salvarParticipante(event) {
    this.liparticipantes = new Array();
    event.selectedParticipant.forEach(row => this.liparticipantes.push(row.id));
    if (this.semParticipante) this.liparticipantes.push(0);
    this.modalParticipante = false;
  }

  async baixarRelatorioAsaas() {
    const infos = {
      start: this.dtInit.getFullYear() + "-" + (this.dtInit.getMonth() + 1) + "-" + this.dtInit.getDate(),
      end: this.dtFin.getFullYear() + "-" + (this.dtFin.getMonth() + 1) + "-" + this.dtFin.getDate(),
      user_id: this.sessionService.loggedUser.id
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'Cache-Control': 'no-store, max-age=0'
      }),
      responseType: 'blob' as 'json',
    }

    this.httpService.wait()
    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'financeiro/relatorio-asaas', infos, httpOptions).toPromise()
        .then((res: any) => {
          let link = document.createElement('a')
          let url = window.URL.createObjectURL(res)
          let fileName = "Relatório de cobranças.xlsx"
          link.href = url
          link.download = fileName
          link.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((err: HttpErrorResponse) => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.message })
        })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.httpService.done()
    }
  }

  async getListEmpresas() {
    this.httpService.wait()

    try {
      await this.httpService.get(this.configService.defaultUrlApiUpload + 'cadastro/getgroupempresas/' + this.dblinkedSessionService.empresa.id + "-" + this.sessionService.loggedUser.id).toPromise()
        .then(res => {
          if (res.error) {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: res.mensagem })
            return
          }
          this.opcoesEmpresas = []
          this.empresasSelecionadas = new Array()
          res.data.forEach(row => this.opcoesEmpresas.push({ value: row.id, label: row.fantasia }))
          this.empresasSelecionadas.push(this.dblinkedSessionService.empresa.id)
          if (!this.opcoesEmpresas.length) this.opcoesEmpresas.push({ value: this.dblinkedSessionService.empresa.id, label: this.dblinkedSessionService.empresa.nomeFantasia })
          this.getContasBancariasMov()
        })

    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro ao recuperar as empresas do grupo, contate o suporte: ' + err })
    } finally {
      this.httpService.done()
    }
  }

  async getContasBancariasMov() {
    this.httpService.wait()

    try {
      await this.httpService.get(this.configService.defaultUrlApiUpload + 'cadastro/contas-bancarias-padrao?empresaId=' + this.empresasSelecionadas).toPromise()
        .then(res => {
          if (res.error) {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: res.mensagem })
            return
          }
          this.opcoesContas = []
          this.contasSelecionadas = new Array()
          res.data.forEach(row => {
            this.opcoesContas.push({ value: row.id, label: row.nome })
            this.contasSelecionadas.push(row.id)
          })
        })

    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro ao recuperar as contas da(s) empresa(s), contate o suporte: ' + err })
    } finally {
      this.httpService.done()
    }
  }

  async getFluxoCaixa() {
    this.httpService.wait()

    try {
      await this.httpService.get(this.configService.defaultUrlApiUpload + 'financeiro/dashboard/get-mascaras/' + this.dblinkedSessionService.empresa.id).toPromise()
        .then(res => {
          if (res.error) {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: res.mensagem })
            return
          }
          this.opcoesFluxoCaixa = [{
            label: "Nenhum", value: null
          }]
          this.fluxoSelecionado = null
          res.data.forEach(row => {
            this.opcoesFluxoCaixa.push({ value: row.id, label: row.nome_fluxo })
            if (row.favorito) this.fluxoSelecionado = row.id
          })
        })

    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro ao recuperar os fluxos de caixa da empresa, contate o suporte: ' + err })
    } finally {
      this.httpService.done()
    }
  }

  async baixarRelatorioMovRateio() {
    if (!this.dtInit) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: "Selecione uma data inicial" })
      return
    }

    if (!this.dtFin) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: "Selecione uma data final" })
      return
    }

    if (this.dtFin < this.dtInit) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: "A data final deve ser posterior à data inicial" })
      return
    }

    if (!this.empresasSelecionadas || this.empresasSelecionadas.length === 0) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: "Selecione pelo menos uma empresa" })
      return
    }

    if (!this.contasSelecionadas || this.contasSelecionadas.length === 0) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: "Selecione pelo menos uma conta" })
      return
    }

    const infos = {
      start: this.dtInit.getFullYear() + "-" + (this.dtInit.getMonth() + 1) + "-" + this.dtInit.getDate(),
      end: this.dtFin.getFullYear() + "-" + (this.dtFin.getMonth() + 1) + "-" + this.dtFin.getDate(),
      user_id: this.sessionService.loggedUser.id,
      empresas_id: this.empresasSelecionadas,
      baixados: this.baixados,
      empresas_contas_id: this.contasSelecionadas,
      fluxo_caixa_id: this.fluxoSelecionado,
      visu_projetos: this.visuProjetos
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'Cache-Control': 'no-store, max-age=0'
      }),
      responseType: 'blob' as 'json',
    }

    this.httpService.wait()
    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'financeiro/relatorio-movimentacao-rateiada', infos, httpOptions).toPromise()
        .then((res: any) => {
          let link = document.createElement('a')
          let url = window.URL.createObjectURL(res)
          let fileName = "Relatório de movimentação com rateio.xlsx"
          link.href = url
          link.download = fileName
          link.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((err: HttpErrorResponse) => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.message })
        })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Erro ao baixar o relatório, contate o suporte:' + err.message })
    } finally {
      this.httpService.done()
    }
  }

}


