import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../app.config';
import { Period } from '../../shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { Message, SelectItem } from 'primeng/api';
import { TimezonePipe } from '../../shared/pipes/timezone.pipe';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { TipoDocumento } from 'app/util/model/tipo-documento.model';
import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { Justificativas } from 'app/util/model/justificativas.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { Departamento } from 'app/util/model/departamento.model';
import { Obrigacao } from 'app/centralweb/model/obrigacao.model';
import { Envio } from 'app/centralweb/model/envio.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';

@Component({
  selector: 'app-confirmar-recebimento',
  templateUrl: './confirmar-recebimento.component.html',
  styleUrls: ['./confirmar-recebimento.component.scss'],
  providers: [CrudService]
})
export class ConfirmarRecebimentoComponent implements OnInit, OnDestroy {
  @Input() token = 'token';

  private sessionSubscription: Subscription;

  constructor(private configService: ConfigService,
              private titleService: TitleService,
              private toolbarService: ToolbarService,
              private httpService: HttpService,
              private utilService: UtilService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private crudService: CrudService,
              private appComponent: AppComponent) {
  }

  ngOnInit() {

    /*if (this.configService.production) {
        this.appComponent._displaySideNav = false;
      } else {
        setTimeout(() => { this.appComponent._displaySideNav = false; });
      }*/

    this.appComponent._displaySideNav = false;

    this.activatedRoute.params.subscribe(params => {
        this.token = params['token'];
    });

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/confirmar-recebimento(' + this.token + ')')
                    .subscribe(result => this.sucessoToken(),
                               error => this.httpService.handleError(error, () => this.ngOnInit()),
                               () => this.httpService.done());
  }

  sucessoToken() {
      alert('ok');
  }

  ngOnDestroy() {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }


}
