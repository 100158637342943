import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cnpj'
})
export class CnpjPipe implements PipeTransform {
  transform(value: any, args?: any[]): any {
    if (value !== undefined && value !== null && value.toString().trim() !== '') {
      const newValue = value.replace(/\D+/g, '');

      if (newValue.length !== 14) {
        return value;
      }

      return newValue.substr(0, 2) + '.' +
             newValue.substr(2, 3) + '.' +
             newValue.substr(5, 3) + '/' +
             newValue.substr(8, 4) + '-' +
             newValue.substr(12, 2);
    }

    return null;
  }
}
