import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService, SelectItem, LazyLoadEvent } from 'primeng/api';
import { ParticipanteEmpresa } from '../../cadastro/model/participante-empresa.model';
import { ParticipanteTipo } from '../../cadastro/model/participante-tipo.model';
import { RegimeTributario } from '../../util/model/regime-tributario.model';
import { Segmento } from '../../util/model/segmento.model';
import { Pais } from '../../util/model/pais.model';
import { Participante } from '../../cadastro/model/participante.model';
import { ConfigService } from 'app/shared/services/config.service';
import { UtilService } from 'app/shared/services/util.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { CountryService } from 'app/shared/services/country.service';
import { Util } from 'app/shared/common/util';
import { PlanoContas } from '../../contabil/model/plano-contas.model';
import { ParticipanteEndereco } from '../model/participante-endereco.model';
import { Endereco } from '../model/endereco.model';
import { EnderecoTipo } from 'app/util/model/endereco-tipo.model';
import { Uf } from 'app/util/model/uf.model';
import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { TitleService } from 'app/shared/services/title.service';
import { ParticipanteContato } from '../model/participante-contato.model';
import { ParticipanteDadoBancario } from '../model/participante-dado-bancario.model';
import { InstituicaoFinanceira } from 'app/util/model/instituicao-financeira.model';
import { TipoContribuinte } from 'app/util/model/tipo-contribuinte-model';
import { isNullOrUndefined } from 'util';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { TipoAssinante } from 'app/util/model/tipo-assinante.model';
import { TipoClienteServicosComunicacao } from 'app/util/model/tipo-cliente-servicos-comunicacao.model';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
import { ParticipanteChavePix } from '../model/participante-chave-pix.model';
import LogManagement from 'app/shared/common/log-management/log-control';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';

@Component({
  selector: 'app-participante-empresa',
  templateUrl: './participante-empresa.component.html',
  styleUrls: ['./participante-empresa.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ],
  providers: [CrudService]
})

export class ParticipanteEmpresaComponent implements OnInit, OnDestroy {
  public participante: Participante;
  public participanteEmpresa: ParticipanteEmpresa = new ParticipanteEmpresa();
  public participanteEndereco: ParticipanteEndereco;
  public participanteContato: ParticipanteContato;
  public participanteDadoBancario: ParticipanteDadoBancario;

  public participanteList: Array<any>;
  private sessionSubscription: Subscription;
  cnpjVerificar: string;
  modalParticipante: boolean;

  msgs: Message[];
  msgsModal: Message[];
  tiposExistentes: Array<any>;

  existeFornecedor: boolean;
  existeCliente: boolean;
  title: String;
  subtitle: String;


  criar: boolean;
  editar: boolean;
  isCnpj: boolean;
  pesquisaApi: boolean = true;
  isCpf: boolean;
  exterior: boolean;
  manual: boolean;
  readOnly: boolean;
  contaPrincipal: boolean;
  invalido: boolean = false;

  participanteEditarId: number;
  codigo: string;

  modalEndereco: boolean = false;
  modalContato: boolean;
  modalDadosBancarios: boolean;
  modalBeneficios: boolean;
  modalDadosPix: boolean

  hideButtons: boolean;
  checkedAll: boolean;

  countries: any;

  public paises: SelectItem[] = [];
  public ufs: SelectItem[] = [];
  public cidades: SelectItem[] = [];
  opcoesPlanoContas: SelectItem[];
  opcoesContribuinte: SelectItem[];
  opcoesTipoAssinante: SelectItem[];
  opcoesTipoClienteServicosComunicacao: SelectItem[];
  tipos: SelectItem[];
  tiposProfissao: SelectItem[];
  tiposGenero: SelectItem[];
  tiposFormaConhecimento: SelectItem[];
  enderecoTipos: SelectItem[];
  bancosTipos: SelectItem[];
  segmentos: SelectItem[];
  opcoesStatusParticipante: SelectItem[] = [{ label: 'Ativo', value: 'ativo' }, { label: 'Inativo', value: 'inativo' }];
  opcoesTiposPix: SelectItem[] = [{ label: 'Selecione', value: null }, { label: 'CPF', value: 1 }, { label: 'CNPJ', value: 2 }, { label: 'EMAIL', value: 3 }, { label: 'TELEFONE', value: 4 }, { label: 'EVP', value: 5 }];
  segmentoSelecionado: number;
  regimeTributario: SelectItem[];
  segmentoCliente: Segmento;
  segmentoFornecedor: Segmento;
  defaultDate: Date;
  totalRecords: number = 0;
  principal: boolean;
  currentScreen: string;

  parametroFoneObrigatorio = false;
  telefone: string;
  participantesSelecionados: Array<ParticipanteEmpresa> = new Array();
  showPf: boolean = true;
  showPj: boolean = true;
  showExterior: boolean = true;
  showInativos: boolean = false;
  lazyLoadEvent: LazyLoadEvent = null;
  modalUploadParticipante: boolean = false;
  chavesPix: Array<any> = new Array()
  chavePixModal: ParticipanteChavePix
  chavePixTemporaria: string
  tipoChavePixTemporario: number

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'CODIGO', size: '8%', textAlign: 'left' },
      { name: 'NOME', size: '15%', textAlign: 'left' },
      { name: 'FANTASIA', size: '15%', textAlign: 'left' },
      { name: 'CNPJ/CPF', size: '13%', textAlign: 'left' },
      { name: 'INSC. ESTADUAL', size: '12%', textAlign: 'left' },
      { name: 'REG. TRIBUTÁRIO', size: '13%', textAlign: 'left' },
      { name: 'SITUAÇÃO CNPJ', size: '9%', textAlign: 'left' },
      { name: 'ATUALIZADO EM', size: '8%', textAlign: 'left' },
      { name: 'UF', size: '8%', textAlign: 'left' },
      { name: 'STATUS PARTICIPANTE', size: '8%', textAlign: 'left' },
      { name: 'CADASTRADO POR', size: '8%', textAlign: 'left' },
      { name: 'CADASTRADO EM', size: '8%', textAlign: 'left' },
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true,
  })
  tipo: string;

  private LogManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  constructor(private configService: ConfigService,
    private titleService: TitleService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private toolbarService: ToolbarService,
    private countryService: CountryService,
    private messageService: MessageService,
    private httpClient: HttpClient) {

    this.pesquisaApi = true;
    this.manual = false;

  }
  @ViewChild('file') fileInput: ElementRef;
  file: File = null;
  filename: string = null;

  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { key: 'button-back', icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.voltar() },
    { key: 'button-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.onRowSelectParticipante() },
    { key: 'button-reactivate', icon: 'replay', text: 'Reativar', tooltip: 'Reativar', visible: false, disabled: false, color: 'default', onClick: () => this.inverterStatusParticipante() },
    { key: 'button-delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.cadastroGrupoEmpresas ? this.excluirParticipanteGrupoEmpresa() : this.excluirParticipante() },
    { key: 'button-export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: false, disabled: false, color: 'default', onClick: () => this.exportarParticipantes() },
    { key: 'button-import', icon: 'cloud_upload', text: 'Importar', tooltip: 'Importar', visible: false, disabled: false, color: 'default', onClick: () => this.modalImportParticipante() },
    { key: 'button-create', icon: 'add', text: 'Criar', tooltip: 'Criar', visible: false, disabled: false, color: 'default', onClick: () => this.criarParticipante() },
    { key: 'button-clone', icon: 'content_copy', text: 'Clonar Participante', tooltip: 'Clonar Participante no grupo de empresas', visible: false, disabled: false, color: 'default', onClick: () => this.popularModalGrupoEmpresa(this.clonarParticipanteGrupoEmpresa, true) },

  ]

  empresaRealizaAlteracoes: boolean = false
  cadastroGrupoEmpresas: boolean = false
  empresasGrupo: SelectItem[] = new Array()
  empresasGrupoSelecionadas: Array<any> = new Array()
  placeHolderEmpresasSelecionadas: String = 'Nenhuma empresa selecionada'
  modalGrupoEmpresas: boolean = false
  tituloModalGrupoEmpresas: string = ''
  descricaoModalGrupoEmpresas: string = ''
  funcaoModalGrupoEmpresas: Function

  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    this.titleService.title = 'Cadastro de Clientes e Fornecedores';
    this.title = 'CLIENTES E FORNECEDORES';
    this.subtitle = 'CADASTRE, EDITE OU EXCLUA OS PARTICIPANTES';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.carregarDadosGrupoEmpresa()
          this.empresaChanged()
        }
      });

    this.ufs = [];
    this.cidades = [];

    this.getCountries();
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  getCountries() {
    this.countryService.getCountries().subscribe(countries => {

      this.countries = countries.map((country) => new Object({
        label: country.fone,
        value: country.iso
      }));

    }, err => {
      console.log('Erro ao listar os países', err);
    })
  }

  async empresaChanged(): Promise<void> {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.pesquisaApi = true;
      this.parametroFoneObrigatorio = false;
      this.dblinkedSessionService.hidePeriods();
      this.modalEndereco = false;
      this.modalContato = false;
      this.modalDadosBancarios = false;
      this.modalBeneficios = false;
      this.modalDadosPix = false
      this.contaPrincipal = false;
      this.principal = false;
      this.cnpjVerificar = '';
      this.defaultDate = new Date();
      this.participante = new Participante();
      this.participante.participanteEnderecos = new Array();
      this.participante.participanteDadosBancarios = new Array();
      this.participante.participanteContatos = new Array();
      this.participante.participanteEmpresas = new Array();
      this.participantesSelecionados = new Array();
      this.participante.regimeTributario = new RegimeTributario();
      this.participanteEmpresa = new ParticipanteEmpresa();
      this.participanteEndereco = new ParticipanteEndereco()
      this.participanteEndereco.endereco = new Endereco();
      this.participanteEndereco.endereco.cidade = '';
      this.participanteEndereco.endereco.pais = new Pais();
      this.participanteEndereco.enderecoTipo = new EnderecoTipo();
      this.participanteContato = new ParticipanteContato();
      this.participanteContato.participanteEmpresa = new ParticipanteEmpresa();
      this.participanteEmpresa.participanteTipo = new ParticipanteTipo();
      this.participanteEmpresa.inscricaoCene = false;
      this.participanteEmpresa.segmento = new Segmento();
      this.participanteEmpresa.tipoContribuinte = new TipoContribuinte();
      this.participanteEmpresa.tipoAssinante = new TipoAssinante();
      this.participanteEmpresa.tipoClienteServicosComunicacao = new TipoClienteServicosComunicacao();
      this.participanteEmpresa.segmento.id = 0;
      this.participanteEmpresa.porcentagemComissao = 0;
      this.participanteEmpresa.permiteAlterarComissao = false;
      this.modalParticipante = false;
      this.tiposExistentes = new Array();
      this.existeFornecedor = false;
      this.existeCliente = false;
      this.editar = false;
      this.isCpf = false;
      this.isCnpj = false;
      this.codigo = 'NE';
      this.exterior = false;
      this.manual = false;
      this.hideButtons = true;
      this.checkedAll = false;
      this.showPf = true;
      this.showPj = true;
      this.showExterior = true;
      this.showInativos = false;
      this.lazyLoadEvent = null;
      this.readOnly = false;
      this.currentScreen = "c";
      this.modalUploadParticipante = false;

      this.participanteList = new Array();

      this.criar = false;
      this.editar = false;

      // this.toolbarMain.setVisible('btn1', false)
      // this.toolbarMain.setVisible('btn2', false)
      // this.toolbarMain.setVisible('btn3', false)
      // this.toolbarMain.setVisible('btn4', false)
      // this.toolbarMain.setVisible('btn5', true)
      // this.toolbarMain.setVisible('btn6', true)
      // this.toolbarMain.setVisible('btn7', true)
      // this.toolbarMain.setVisible('btn8', true)
      this.changeToolbar()
      this.participanteList = new Array();

      this.participante_list_lazy_off(0, 15, {}, null, null)
      this.getTypeParticipants()
      this.getSegment()
      this.getTaxRegime()
      this.getCountry()
      this.getTypeAddress()
      this.getUfs()
      this.getTypeBank()
      this.getTypeTaxpayer()
      this.getTypeSubscriber()
      this.getTypeCommunication()
      this.getKnowledgeForms()
      this.getGenres()
      this.getProfessions()
    }
  }

  async getTypeParticipants(): Promise<void> {
    try {
      await this.httpService.get('/participantetipo?$select=id,descricao&$orderby=descricao').toPromise().then(res => this.loadTipos(res.value))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os tipos de participantes! Contate o suporte' })
    }
  }

  async getSegment(): Promise<void> {
    try {
      await this.httpService.get('/segmento?$select=id,codigo,descricao&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=codigo').toPromise().then(res => this.loadSegmentos(res.value))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os segmentos! Contate o suporte' })
    }
  }

  async getTaxRegime(): Promise<void> {
    try {
      await this.httpService.get('/regimetributario?$select=id,descricao&$orderby=descricao').toPromise().then(res => this.loadRegimeTributario(res.value))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os regimes tributários! Contate o suporte' })
    }
  }

  async getCountry(): Promise<void> {
    try {
      await this.httpService.get('/pais?$select=id,nome&$orderby=nome').toPromise().then(res => this.paisesLoaded(res.value))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os países! Contate o suporte' })
    }
  }

  showTela(tipo: string) {
    this.currentScreen = tipo;
    this.participante_list_lazy_off(0, 15, {}, null, null);
  }

  changeMenuOption() {
    if (this.lazyLoadEvent)
      this.participante_list_lazy_off(0, 15, this.lazyLoadEvent.filters, this.lazyLoadEvent.sortField, this.lazyLoadEvent.sortOrder);
    else
      this.participante_list_lazy_off(0, 15, {}, null, null);
  }

  selectTodos(event) {
    this.participantesSelecionados = new Array();

    if (event.checked) {
      for (let participante of this.participanteList) {
        this.participantesSelecionados.push(participante);
        participante['selected'] = event.checked;
      }
    }
  }

  selectCheckbox(event) {
    this.participantesSelecionados.push(event.data);
    this.changeToolbar()

    // this.toolbarMain.setVisible('btn4', true)
    // this.toolbarMain.setVisible('btn2', false)

    // if (this.participantesSelecionados.length === 1) {
    //   this.toolbarMain.setVisible('btn2', true)
    // }

    // for (let participante of this.participantesSelecionados) {
    //   if (participante['ativo'] === false) {
    //     this.toolbarMain.setVisible('btn3', true)
    //   }
    // }

  }

  unselectCheckbox(event) {
    let idx = this.participantesSelecionados.findIndex(p => p.participanteId == event.data.participanteId && p.codigo == event.data.codigo);
    this.participantesSelecionados.splice(idx, 1);
    this.changeToolbar()

    // this.toolbarMain.setVisible('btn2', false)

    // if (this.participantesSelecionados.length === 1) {
    //   this.toolbarMain.setVisible('btn2', true)
    // }
    // else if (this.participantesSelecionados.length < 1) {
    //   this.toolbarMain.setVisible('btn3', false)
    //   this.toolbarMain.setVisible('btn4', false)
    // }
  }

  loadPlanoContas(array: Array<PlanoContas>) {
    this.opcoesPlanoContas = [];
    this.opcoesPlanoContas.push({ label: 'Selecionar Conta', value: null });
    array.forEach(element => {
      if (element.indCta === 'A') {
        this.opcoesPlanoContas.push({ label: element.codCta + ' - ' + element.descrCta, value: element.id });
      }
    });
  }

  paisesLoaded(value: Array<Pais>) {
    this.paises = new Array();
    value.forEach(element => {
      this.paises.push({ label: element.nome, value: element.id });
    });
  }

  onRowSelectParticipante() {
    let participante = this.participantesSelecionados[0];

    this.telefone = '';
    this.participanteEmpresa = new ParticipanteEmpresa();
    this.participanteEditarId = participante.participanteId;
    this.participanteEmpresa.codigo = participante.codigo;
    this.cnpjVerificar = participante['cnpj'];
    this.participante.fantasia = participante.fantasia;
    this.participanteEmpresa.fantasia = participante.fantasia;

    this.editarParticipante(participante.codigo);

    this.changeToolbar()
    // this.toolbarMain.setVisible('btn1', true)
    // this.toolbarMain.setVisible('btn2', false)
    // this.toolbarMain.setVisible('btn3', false)
    // this.toolbarMain.setVisible('btn4', false)
    // this.toolbarMain.setVisible('btn5', false)
    // this.toolbarMain.setVisible('btn6', false)
    // this.toolbarMain.setVisible('btn7', false)
    // this.toolbarMain.setVisible('btn8', false)
  }

  criarParticipante() {
    this.telefone = '';
    this.criar = true;
    this.participanteEmpresa = new ParticipanteEmpresa();
    this.participanteEmpresa.participanteTipo = new ParticipanteTipo();
    this.participanteEmpresa.planoContas = new PlanoContas();
    this.participanteEmpresa.tipoContribuinte = new TipoContribuinte();
    this.participanteEmpresa.tipoAssinante = new TipoAssinante();
    this.participanteEmpresa.tipoClienteServicosComunicacao = new TipoClienteServicosComunicacao();
    this.participanteEmpresa.segmento = new Segmento();
    this.participante.participanteContatos = new Array();
    this.participante.participanteEnderecos = new Array();
    this.participante.participanteEmpresas = new Array();
    this.participante.participanteDadosBancarios = new Array();
    //importante
    this.participanteEmpresa.contribuinte = false;
    this.participanteEmpresa.orgaoPublico = false;
    this.participanteEmpresa.contribuinteIpi = false;
    this.participanteEmpresa.beneficioIcms = false;
    this.participanteEmpresa.beneficioIpi = false;
    this.participanteEmpresa.beneficioPis = false;
    this.participanteEmpresa.beneficioCofins = false;

    this.changeToolbar()
    // this.toolbarMain.setVisible('btn1', true)
    // this.toolbarMain.setVisible('btn2', false)
    // this.toolbarMain.setVisible('btn3', false)
    // this.toolbarMain.setVisible('btn4', false)
    // this.toolbarMain.setVisible('btn5', false)
    // this.toolbarMain.setVisible('btn6', false)
    // this.toolbarMain.setVisible('btn7', false)
    // this.toolbarMain.setVisible('btn8', false)

  }

  inverterStatusParticipante() {

    const data = {
      empresaId: this.dblinkedSessionService.empresa.id,
      authUser: this.sessionService.loggedUser.id,
      listParticipante: this.participantesSelecionados
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/inverter-status-participante', data)
      .subscribe(ref => {
        this.exibirMensagem(ref);
        if (!ref.error) this.participante_list_lazy_off(0, 15, {}, null, null);

      }, error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());
  }

  async participante_list_lazy_off(offset: number = 0, limit: number = 15, filters, sortField, sortOrder): Promise<void> {
    try {
      const filtrosAdicionais = {
        'participanteTipo': this.currentScreen == 'a' ? 5 : this.currentScreen == 'c' ? 1 : this.currentScreen == 'f' ? 2 : 4,
        'showPf': this.showPf,
        'showPj': this.showPj,
        'showExterior': this.showExterior,
        'showInativos': this.showInativos
      }

      const data = {
        empresaId: this.dblinkedSessionService.empresa.id,
        offset,
        limit,
        filters,
        sortField,
        sortOrder,
        filtrosAdicionais
      }

      this.participanteList = new Array()
      this.participantesSelecionados = new Array()
      this.checkedAll = false

      this.skeletonConfig.startLoad()

      await this.httpService.post('/custom/cadastro/buscar-participantes-por-empresa', data).toPromise().then(res => {
        if (res.totalRecords) this.totalRecords = res.totalRecords[0]
        this.participanteList = res.participantes
      })

      this.skeletonConfig.endLoad()
    } catch (err) {
      this.empresaChanged()
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os participantes! Contate o suporte' })
    }
  }

  async participante_lazy_on(event: LazyLoadEvent) {
    if (!this.dblinkedSessionService.hasEmpresaSelected) return;
    this.lazyLoadEvent = event;

    await this.participante_list_lazy_off(event.first, event.rows, event.filters, event.sortField, event.sortOrder);
  };

  pesquisar() {

    this.pesquisaApi = true;
    this.manual = false;
    this.exterior = false;
    if (Util.isCnpjOrCpf(this.cnpjVerificar)) {
      this.cnpjVerificar = this.cnpjVerificar.replace(/[^\d]+/g, '');
      if (Util.isCnpj(this.cnpjVerificar)) {
        this.isCnpj = true;
        this.isCpf = false;
        this.exterior = false;
        this.manual = false;
      }
      if (Util.isCpf(this.cnpjVerificar)) {
        this.isCpf = true;
        this.isCnpj = false;
        this.exterior = false;
        this.manual = false;
        this.readOnly = false;
      }
      this.httpService.wait();
      this.httpService.get('/participantetipo?$select=id,descricao&$orderby=descricao')
        .subscribe(ref => this.loadTipos(ref.value),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/cadastro/consulta-cnpj(' + this.cnpjVerificar + ',' +
        this.dblinkedSessionService.empresa.id + ',' + this.sessionService.loggedUser.id.toString() + ')')
        .subscribe(ref => {
          if (ref.length === 0) {
            this.httpService.wait();
            this.httpService.get('/custom/cadastro/consulta-cnpj(' + this.cnpjVerificar + ',' +
              this.dblinkedSessionService.empresa.id + ',' + this.sessionService.loggedUser.id.toString() + ')')
              .subscribe(data => {
                if (data.value === undefined) {
                  this.messageService.add({
                    severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: 'Instabilidade na Consulta com a Receita Federal'
                      + ', informe o suporte!'
                  });
                  this.manual = true; // habilita o preenchimento manual;
                  // this.empresaChanged();
                  return;
                } else {
                  this.pesquisar();
                }
              },
                error => this.httpService.handleError(error, () => this.pesquisar()),
                () => this.httpService.done());
          } else {

            if (ref[0].error !== undefined) {
              this.messageService.add({
                severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: ref[0].error + ', Verifique!'
              });
              this.empresaChanged();
              return;
            }
            if (ref.length > 1) {
              if (ref[1].warn !== undefined) {
                this.messageService.add({
                  severity: 'warn', key: 'messageLinnks', summary: 'MSG: ', detail: ref[1].warn + ', Verifique!'
                });
              }
            }

            this.participante.id = ref[0].id;
            this.participante.nome = ref[0].nome;
            this.participante.fantasia = ref[0].fantasia;
            this.participante.ie = ref[0].ie;
            this.participante.im = ref[0].im;
            this.participante.cnae = ref[0].cnae;
            this.participante.suframa = ref[0].suframa;
            this.participante.dtSuframa = this.utilService.dateWithoutTimeZone(ref[0].dtSuframa);
            this.participante.regimeTributario.descricao = ref[0].regimeTributacao;
            this.participante.regimeTributario.id = ref[0].regimeTributacaoId;

            this.participanteEmpresa.fantasia = ref[0].fantasia;
            this.participanteEmpresa.participanteId = ref[0].id;
            this.participanteEmpresa.inscricaoCene = false;
            this.participante.dtNascimento = ref[0].dtNascimento;
            this.participante.genero = ref[0].genero;
            this.participante.profissao = ref[0].profissao;
            this.participante.formaConhecimento = ref[0].formaConhecimento;
            this.participante.observacoes = ref[0].observacoes;
            this.participante.rg = ref[0].rg;
            this.participante.estadoCivil = ref[0].estadoCivil;

            // this.codigo = ((ref[0].codigo !== null) ? ref[0].codigo : 'NE');

            if (ref[0].cep !== null) {
              //setando o endereço:
              this.participanteEndereco = new ParticipanteEndereco();
              //this.participanteEndereco.participante = new Participante();
              this.participanteEndereco.endereco = new Endereco();
              this.participanteEndereco.endereco.pais = new Pais();
              this.participanteEndereco.endereco.uf = new Uf();
              this.participanteEndereco.endereco.municipioIbge = new MunicipioIbge();

              this.participanteEndereco.enderecoTipo = new EnderecoTipo();
              const d = this.enderecoTipos.find(t => t.value === 1); //endereço comercial
              if (d) {
                this.participanteEndereco.enderecoTipo.descricao = d.label;
                this.participanteEndereco.enderecoTipo.id = 1;
              }

              /** remover o tipo de endereço */
              const index = this.enderecoTipos.findIndex(c => c.value === 1);
              if (this.isCnpj) {
                this.enderecoTipos.splice(index, 1);
              }

              /** fim remover o tipo de endereço */
              this.participanteEndereco.endereco.id = null;
              this.participanteEndereco.endereco.logradouro = ref[0].logradouro;
              this.participanteEndereco.endereco.cidade = ref[0].cidade;

              this.participanteEndereco.endereco.numero = ref[0].numero;
              this.participanteEndereco.endereco.bairro = ref[0].bairro;
              this.participanteEndereco.endereco.complemento = ref[0].complemento;
              this.participanteEndereco.endereco.cep = ((ref[0].cep !== null) ? ref[0].cep.replace(/[^\d]+/g, '') : null);
              const indexUf = this.ufs.find(u => u.label === ref[0].uf);
              if (indexUf) {
                this.participanteEndereco.endereco.uf.id = indexUf.value;
                this.participanteEndereco.endereco.uf.uf = indexUf.label;
              }

              this.participanteEndereco.endereco.municipioIbge.id = ref[0].cidadeId;
              this.participanteEndereco.endereco.municipioIbge.nome = ref[0].cidadeNome;
              this.participanteEndereco.endereco.pais.nome = ref[0].pais;
              this.participanteEndereco.principal = !isNullOrUndefined(ref[0].logradouro) ? true : false;

              if (!isNullOrUndefined(ref[0].logradouro)) {
                this.participante.participanteEnderecos.push(this.participanteEndereco);
              }

              //fim dos endereços
              this.readOnly = true;
            } else {
              if (this.isCnpj) {
                this.messageService.add({
                  severity: 'warn',
                  key: 'messageLinnks',
                  summary: 'MSG: ',
                  detail: 'CEP não encontrado, preencha o endereço manualmente.'
                });
              }
            }

            this.httpService.wait();
            this.httpService.get('/custom/cadastro/buscar-participante-empresa(' + ref[0].id + ','
              + this.dblinkedSessionService.empresa.id + ',' + this.codigo + ')')
              .subscribe(part => this.verifParticipante(part),
                error => this.httpService.handleError(error, () => this.pesquisar()),
                () => this.httpService.done());

          }
        }, error => this.httpService.handleError(error, () => this.pesquisar()),
          () => this.httpService.done());

      // } else {




      //   this.tipos = this.tipos.splice(0, 4);

      //   this.httpService.wait();
      //   this.httpService.get('/participantetipo?$select=id,descricao&$orderby=descricao')
      //     .subscribe(ref => console.log(ref.value),
      //       error => this.httpService.handleError(error, () => this.empresaChanged()),
      //       () => this.httpService.done());
      // }
    } else {
      this.hideButtons = true;

      this.messageService.add({
        severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: 'Dados Inválidos, informe um CNPJ / CPF válido!'
      });
      this.isCnpj = false;
      this.isCpf = false;

    }
  }

  verifParticipante(value: Array<any>) {
    console.log(this.participanteEmpresa)
    if (value.length <= 1) {
      if (value[0].participante_tipo_id === 1 && value[0].existePart) {
        this.httpService.wait();
        this.httpService.get('/participantetipo?$select=id,descricao' +
          '&$orderby=descricao')
          .subscribe(ref => this.loadTipos(ref.value, 1),
            error => this.httpService.handleError(error, () => this.empresaChanged()),
            () => this.httpService.done());

        //  this.participanteEmpresa = new ParticipanteEmpresa();
        this.participanteEmpresa.participanteTipo = new ParticipanteTipo();
        this.participanteContato = new ParticipanteContato();
        this.participanteContato.participanteEmpresa = new ParticipanteEmpresa();

        // this.participanteEmpresa.complemento = value[0].complemento;
        // this.participanteEmpresa.codigo = value[0].codigo;
        this.participanteEmpresa.inscricaoCene = value[0].inscricaoCene;
        //this.participanteEmpresa.suframa = value[0].suframa;
        if (this.editar) this.participanteEmpresa.participanteTipo.id = 1
        this.participanteEmpresa.limiteCredito = value[0].limiteCredito;
        this.participanteEmpresa.limiteCompra = value[0].limiteCompra;
        this.participanteEmpresa.destinatarios = value[0].destinatarios;
        // contribuinte
        this.participanteEmpresa.beneficioIcms = value[0].beneficioIcms;
        this.participanteEmpresa.beneficioPis = value[0].beneficioPis;
        this.participanteEmpresa.beneficioCofins = value[0].beneficioCofins;
        this.participanteEmpresa.beneficioIpi = value[0].beneficioIpi;
        this.participanteEmpresa.contribuinte = value[0].contribuinte;
        this.participanteEmpresa.contribuinteIpi = value[0].contribuinteIpi;
        this.participanteEmpresa.orgaoPublico = value[0].orgaoPublico;
        //segmento
        this.segmentoSelecionado = value[0].segmentoId;

      } else if ((value[0].participante_tipo_id === 2 || value[0].participante_tipo_id === 5) && value[0].existePart) {


        this.httpService.wait();
        this.httpService.get('/participantetipo?$select=id,descricao' +
          '&$orderby=descricao')
          .subscribe(ref => this.loadTipos(ref.value, value[0].participante_tipo_id),
            error => this.httpService.handleError(error, () => this.empresaChanged()),
            () => this.httpService.done());

        this.participanteEmpresa.participanteTipo = new ParticipanteTipo();
        this.participanteContato.participanteEmpresa = new ParticipanteEmpresa();

        /*this.participanteEmpresa.complemento = value[0].complemento;
        this.participanteEmpresa.contato = value[0].contato;
        this.participanteEmpresa.fone = value[0].fone;
        this.participanteEmpresa.email = value[0].email;
        this.participanteEmpresa.codigo = value[0].codigo;*/
        this.participanteEmpresa.inscricaoCene = value[0].inscricaoCene;
        if (this.editar) this.participanteEmpresa.participanteTipo.id = value[0].participante_tipo_id
        this.participanteEmpresa.limiteCredito = value[0].limiteCredito;
        this.participanteEmpresa.limiteCompra = value[0].limiteCompra;
        this.participanteEmpresa.destinatarios = value[0].destinatarios;
        // contribuinte
        this.participanteEmpresa.beneficioIcms = value[0].beneficioIcms;
        this.participanteEmpresa.beneficioPis = value[0].beneficioPis;
        this.participanteEmpresa.beneficioCofins = value[0].beneficioCofins;
        this.participanteEmpresa.beneficioIpi = value[0].beneficioIpi;
        this.participanteEmpresa.contribuinte = value[0].contribuinte;
        this.participanteEmpresa.contribuinteIpi = value[0].contribuinteIpi;
        this.participanteEmpresa.orgaoPublico = value[0].orgaoPublico;
        this.segmentoSelecionado = value[0].segmentoId;

      } else if (value[0].participante_tipo_id === 4 && value[0].existePart) {



        this.httpService.wait();
        this.httpService.get('/participantetipo?$select=id,descricao' +
          '&$orderby=descricao')
          .subscribe(ref => this.loadTipos(ref.value, 4),
            error => this.httpService.handleError(error, () => this.empresaChanged()),
            () => this.httpService.done());

        this.participanteEmpresa.participanteTipo = new ParticipanteTipo();
        this.participanteContato.participanteEmpresa = new ParticipanteEmpresa();

        /*this.participanteEmpresa.complemento = value[0].complemento;
        this.participanteEmpresa.contato = value[0].contato;
        this.participanteEmpresa.fone = value[0].fone;
        this.participanteEmpresa.email = value[0].email;
        this.participanteEmpresa.codigo = value[0].codigo;*/
        this.participanteEmpresa.inscricaoCene = value[0].inscricaoCene;
        if (this.editar) this.participanteEmpresa.participanteTipo.id = 4
        this.participanteEmpresa.limiteCredito = value[0].limiteCredito;
        this.participanteEmpresa.limiteCompra = value[0].limiteCompra;
        this.participanteEmpresa.destinatarios = value[0].destinatarios;
        // contribuinte
        this.participanteEmpresa.beneficioIcms = value[0].beneficioIcms;
        this.participanteEmpresa.beneficioPis = value[0].beneficioPis;
        this.participanteEmpresa.beneficioCofins = value[0].beneficioCofins;
        this.participanteEmpresa.beneficioIpi = value[0].beneficioIpi;
        this.participanteEmpresa.contribuinte = value[0].contribuinte;
        this.participanteEmpresa.contribuinteIpi = value[0].contribuinteIpi;
        this.participanteEmpresa.orgaoPublico = value[0].orgaoPublico;
        this.segmentoSelecionado = value[0].segmentoId;

      } else if (!value[0].existePart) {

        this.httpService.wait();
        this.httpService.get('/participantetipo?$select=id,descricao' +
          '&$orderby=descricao')
          .subscribe(ref => this.loadTipos(ref.value),
            error => this.httpService.handleError(error, () => this.empresaChanged()),
            () => this.httpService.done());

      }
    } else {

    }

  }

  onFilterParticipante(event) {

  }

  editarParticipante(codigo: any) {
    this.editar = true;
    this.participanteEmpresa.participanteTipo = new ParticipanteTipo();
    this.participante = new Participante();
    this.participanteEmpresa = new ParticipanteEmpresa();
    this.participanteEmpresa.planoContas = new PlanoContas();
    this.participanteEmpresa.planoContas.id = null;
    this.participante.regimeTributario = new RegimeTributario()
    this.participante.participanteEnderecos = new Array();
    this.participante.participanteContatos = new Array();
    this.participante.participanteDadosBancarios = new Array();
    this.participanteEmpresa.tipoAssinante = new TipoAssinante();
    this.participanteEmpresa.tipoClienteServicosComunicacao = new TipoClienteServicosComunicacao();
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/buscar-cadastro-participante-id(' + this.participanteEditarId + ',' +
      this.dblinkedSessionService.empresa.id + ',' + codigo + ')')
      .subscribe(ref => {
        console.log(ref);

        this.hideButtons = false;
        this.contaPrincipal = false;
        this.participante.id = ref[0].id;
        this.participante.nome = ref[0].nome;
        if (ref[0].cpf) {
          this.isCpf = true;
          this.isCnpj = false;
          this.readOnly = false;
        } else if (ref[0].cnpj) {
          this.isCnpj = true;
          this.isCpf = false;
          this.readOnly = false;
        } else {
          this.manual = true;
          //this.contaSelecionada = ref[0].planoContas;
        }
        this.participante.fantasia = ref[0].fantasia;
        this.participante.ie = ref[0].ie;
        this.participante.im = ref[0].im;
        this.participante.cnae = ref[0].cnae;
        this.participante.suframa = ref[0].suframa;
        this.participante.dtSuframa = this.utilService.dateWithoutTimeZone(ref[0].dtSuframa)
        this.participante.regimeTributario.descricao = ref[0].regimeTributacao;
        this.participante.regimeTributario.id = ref[0].regimeTributacaoId;
        this.chavesPix = ref[0].chavesPix
        this.participanteEmpresa.fantasia = ref[0].fantasia;
        this.participanteEmpresa.codigo = ref[0].codigo;
        this.participanteEmpresa.planoContas = new PlanoContas();
        this.participanteEmpresa.planoContas.id = ref[0].planoContas;

        this.httpService.wait();
        this.httpService.get('/planocontas?$select=id,codCta,descrCta,indCta,codNaturezaConta/cod' +
          '&$filter=id eq ' + ref[0].planoContas + '&$orderby=codCta')
          .subscribe(ref => this.loadPlanoContas(ref.value),
            error => this.httpService.handleError(error, () => this.editarParticipante(codigo)),
            () => this.httpService.done());

        this.participanteEmpresa.tipoContribuinte.id = ref[0].tipoContribuinteId;
        this.participanteEmpresa.tipoAssinante.id = ref[0].tipoAssinanteId;
        this.participanteEmpresa.tipoClienteServicosComunicacao.id = ref[0].tipoClienteServicosComunicacao;
        this.participanteEmpresa.nif = ref[0].nif;
        this.participanteEmpresa.ativo = ref[0].ativo;
        this.participante.dtNascimento = ref[0].dtNascimento;
        this.participante.genero = ref[0].genero;
        this.participante.profissao = ref[0].profissao;
        this.participante.formaConhecimento = ref[0].formaConhecimento;
        this.participante.observacoes = ref[0].observacoes;
        this.participante.rg = ref[0].rg;
        this.participante.estadoCivil = ref[0].estadoCivil;

        //setando o endereços:
        ref[0].enderecos.forEach(end => {
          this.participanteEndereco = new ParticipanteEndereco();
          //this.participanteEndereco.participante = new Participante();
          this.participanteEndereco.endereco = new Endereco();
          this.participanteEndereco.endereco.pais = new Pais();
          this.participanteEndereco.endereco.uf = new Uf();
          this.participanteEndereco.endereco.municipioIbge = new MunicipioIbge();

          this.participanteEndereco.id = end.partEndId;
          this.participanteEndereco.enderecoTipo = new EnderecoTipo();
          this.participanteEndereco.principal = end.principal;
          const d = this.enderecoTipos.find(t => t.value === end.partEndTipoId);
          if (d !== undefined) { // casos onde ja esxista endereço antigo na base
            this.participanteEndereco.enderecoTipo.descricao = d.label;
            this.participanteEndereco.enderecoTipo.id = end.partEndTipoId;

            /** remover o tipo de endereço */
            const i = this.enderecoTipos.findIndex(c => c.value === end.partEndTipoId);
            if (i !== undefined) { // casos onde ja esxista tipos duplicados (exceção)
              this.enderecoTipos.splice(i, 1);
            }
            /** fim remover o tipo de endereço */
            this.participanteEndereco.endereco.id = end.id;
            this.participanteEndereco.endereco.logradouro = end.logradouro;
            this.participanteEndereco.endereco.cidade = end.cidade;

            if (!end.cidadeId || end.cidadeId === 5569) {
              this.exterior = true;
              this.manual = false;
            }

            this.participanteEndereco.endereco.numero = end.numero;
            this.participanteEndereco.endereco.bairro = end.bairro;
            this.participanteEndereco.endereco.complemento = end.complemento;
            this.participanteEndereco.endereco.cep = ((end.cep !== null) ? end.cep.replace(/[^\d]+/g, '') : '');
            if (end.uf != null) {
              this.participanteEndereco.endereco.uf.id = end.ufId;
              this.participanteEndereco.endereco.uf.uf = end.uf;
            }
            this.participanteEndereco.endereco.municipioIbge.id = end.cidadeId;
            this.participanteEndereco.endereco.municipioIbge.nome = end.cidade;
            this.participanteEndereco.endereco.pais.id = end.paisId;
            this.participanteEndereco.endereco.pais.nome = end.pais;
            this.participante.participanteEnderecos.push(this.participanteEndereco);

          }
        });

        //contato
        //setando o contato:
        ref[0].contatos.forEach(contact => {
          let partContato = new ParticipanteContato();
          partContato.participanteEmpresa = new ParticipanteEmpresa();
          partContato.participanteEmpresa.participanteId = this.participante.id;
          partContato.participanteEmpresa.codigo = this.participanteEmpresa.codigo;
          partContato.id = contact.id;
          partContato.dtInicial = contact.dtInicial;
          partContato.dtFinal = contact.dtFinal;
          partContato.tipoContato = contact.tipoContato;
          partContato.contato = contact.contato;
          partContato.fone = contact.fone;
          partContato.email = contact.email;
          partContato.principal = contact.principal;
          if (!(this.principal)) {
            this.principal = contact.principal;
          }
          this.participante.participanteContatos.push(partContato);
        });

        //contas
        //setando o conta:
        ref[0].contas.forEach(cont => {
          this.participanteDadoBancario = new ParticipanteDadoBancario();
          this.participanteDadoBancario.banco = new InstituicaoFinanceira();
          this.participanteDadoBancario.participanteEmpresa = new ParticipanteEmpresa();
          this.participanteDadoBancario.participanteEmpresa.participanteId = this.participante.id;
          this.participanteDadoBancario.participanteEmpresa.codigo = this.participanteEmpresa.codigo;
          this.participanteDadoBancario.id = cont.id;
          this.participanteDadoBancario.agencia = cont.agencia;
          this.participanteDadoBancario.contaNumero = cont.contaNumero;
          this.participanteDadoBancario.contaDv = cont.contaDv;
          this.participanteDadoBancario.tipoConta = cont.tipoConta;
          this.participanteDadoBancario.contaPrincipal = cont.contaPrincipal;
          //this.participanteDadoBancario.tipo_pix = cont.tipo_pix;
          //this.participanteDadoBancario.chave_pix = cont.chave_pix;
          if (!(this.contaPrincipal)) {
            this.contaPrincipal = cont.contaPrincipal;
          }
          if (cont.bancoId != null) {
            this.participanteDadoBancario.banco.id = cont.bancoId;
            this.participanteDadoBancario.banco.codigo = cont.bancoCod;
            this.participanteDadoBancario.banco.descricao = cont.bancoDesc;
          }
          this.participante.participanteDadosBancarios.push(this.participanteDadoBancario);
        });


        this.httpService.wait();
        this.httpService.get('/custom/cadastro/buscar-participante-empresa(' + this.participanteEditarId + ','
          + this.dblinkedSessionService.empresa.id + ',' + this.participanteEmpresa.codigo + ')')
          .subscribe(part => this.verifParticipante(part),
            error => this.httpService.handleError(error, () => this.editarParticipante(codigo)),
            () => this.httpService.done());
      }, error => this.httpService.handleError(error, () => this.editarParticipante(codigo)),
        () => this.httpService.done());
  }

  excluirParticipante() {
    if (!confirm('Deseja realmente excluir este participante?')) return;
    //this.participantesSelecionados
    let listExcluir = [];
    for (let participante of this.participanteList)
      if (participante.selected) listExcluir.push({ 'id': participante.participanteId, 'codigo': participante.codigo });

    const listaEnviar = {
      listParticipante: listExcluir,
      empresa: this.dblinkedSessionService.empresa.id,
      authUser: this.sessionService.loggedUser.id,
      participantesSelecionados: this.participantesSelecionados
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/excluir-participante', listaEnviar)
      .subscribe(result => {
        console.log(listExcluir)
        this.participantesSelecionados.forEach(row => {
          this.LogManagement.registerLog(
            LogModule.REGISTRATION,
            LogScreen.PARTICIPANTS,
            '/custom/cadastro/excluir-participante',
            LogAction.DELETE,
            row.participanteId,
            row.codigo,
            "O participante " + row.codigo + " foi excluído",
            listaEnviar)
        })
        this.resultadoEdicao(result)
        },
        error => this.httpService.handleError(error, () => this.excluirParticipante()),
        () => this.httpService.done());
  }

  loadTipos(value: Array<any>, typeToExclude: number = null) {
    this.tipos = [];
    this.tipos.push({
      label: 'Selecione o tipo de Participante',
      value: 0
    });

    value.forEach(element => {
      if (element.id != typeToExclude) {
        this.tipos.push({
          label: element.descricao,
          value: element.id
        })
      }
    })
  }

  loadSegmentos(value: Array<Segmento>) {
    this.segmentos = [];
    this.segmentos.push({
      label: 'Selecione o Segmento',
      value: 0
    });
    value.forEach(element => {
      this.segmentos.push({
        label: element.codigo + " - " + element.descricao,
        value: element.id
      });
    });
  }

  loadRegimeTributario(value: Array<RegimeTributario>) {
    //this.regimes = new Array();
    this.regimeTributario = [];
    value.forEach(element => {
      this.regimeTributario.push({
        label: element.descricao,
        value: element.id
      });
    });
  }

  async getTypeAddress(): Promise<void> {
    try {
      this.enderecoTipos = [{ label: 'Selecione', value: null }]
      await this.httpService.get('/enderecotipo?$select=id,descricao&$orderby=descricao').toPromise().then(res =>
        res.value.forEach(item => this.enderecoTipos.push({ label: item.descricao, value: item.id }))
      )
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os tipos de endereços! Contate o suporte' })
    }
  }

  async getUfs(): Promise<void> {
    try {
      this.ufs = [{ label: 'Selecione', value: null }]
      await this.httpService.get('/uf?$select=id,uf&$orderby=uf').toPromise().then(res =>
        res.value.forEach(item => this.ufs.push({ label: item.uf, value: item.id }))
      )
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar as UFs! Contate o suporte' })
    }
  }

  async getTypeBank(): Promise<void> {
    try {
      this.bancosTipos = [{ label: 'Selecione', value: null }]
      await this.httpService.get('/instituicaofinanceira?$select=id,codigo,descricao&$orderby=codigo').toPromise().then(res =>
        res.value.forEach(item => this.bancosTipos.push({ label: item.codigo + ' - ' + item.descricao, value: item.id }))
      )
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os tipos de bancos! Contate o suporte' })
    }
  }

  async getTypeTaxpayer(): Promise<void> {
    try {
      this.opcoesContribuinte = [{ label: 'Selecione', value: null }]
      await this.httpService.get('/tipocontribuinte?$select=id,descricao&$orderby=descricao').toPromise().then(res =>
        res.value.forEach(item => this.opcoesContribuinte.push({ label: item.descricao, value: item.id }))
      )
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os tipos de contribuinte! Contate o suporte' })
    }
  }

  async getTypeSubscriber(): Promise<void> {
    try {
      this.opcoesTipoAssinante = [{ label: 'Selecione', value: null }]
      await this.httpService.get('/tipoassinante?$select=id,cod,descr&$orderby=cod').toPromise().then(res =>
        res.value.forEach(item => this.opcoesTipoAssinante.push({ label: (item.cod + ' - ' + item.descr), value: item.id }))
      )
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os tipos de assinatura! Contate o suporte' })
    }
  }

  async getTypeCommunication(): Promise<void> {
    try {
      this.opcoesTipoClienteServicosComunicacao = [{ label: 'Selecione', value: null }]
      await this.httpService.get('/tipoclienteservicoscomunicacao?$select=id,cod,descr&$orderby=cod').toPromise().then(res =>
        res.value.forEach(item => this.opcoesTipoClienteServicosComunicacao.push({ label: (item.cod + ' - ' + item.descr), value: item.id }))
      )
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os tipos de serviços de comunicação! Contate o suporte' })
    }
  }

  loadMunicipioIbge(event: any) {
    if (!isNullOrUndefined(event.value)) {
      this.httpService.wait();
      this.httpService.get('/municipioibge?$select=id,nome&$filter=uf/id eq ' + event.value + '&$orderby=nome')
        .subscribe(ref => {
          this.cidades = [];
          this.cidades.push({ label: 'Selecione', value: null });
          ref.value.forEach(element => {
            this.cidades.push({
              label: element.nome,
              value: element.id
            });
          });
        }, error => this.httpService.handleError(error, () => this.loadMunicipioIbge(event)), () => this.httpService.done());
    }

  }

  validacao() {
    let qtdUsuario = 0
    let endPrincipal = false
    let contador = 0
    this.participante.participanteEnderecos.forEach(element => {
      if (element.principal) {
        endPrincipal = true
        contador++
      }
    })

    if (!endPrincipal) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Para salvar é preciso informar pelo menos um endereço principal!' })
      return false

    } else {
      if (contador > 1) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Somente um endereço pode estar cadastrado como principal!' })
        return false
      }
    }

    if ((this.participanteEmpresa.participanteTipo.id === 1 || this.participanteEmpresa.participanteTipo.id === 3) && !this.participanteEmpresa.destinatarios) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Para o tipo de participante cliente/ambos um e-mail de participante deverá ser informado!' })
      return false

    } else {
      if (this.participanteEmpresa.destinatarios) {
        if (this.participanteEmpresa.destinatarios.indexOf(";") != -1) {
          var splitted = this.participanteEmpresa.destinatarios.split(";")
          let invalido = false
          splitted.forEach(element => {
            if (!Util.validacaoEmail(element.trim())) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'O e-mail ' + element + ' é inválido!' })
              invalido = true
            }
          })
          if (invalido) return

        } else {
          if (!Util.validacaoEmail(this.participanteEmpresa.destinatarios.trim())) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'O e-mail ' + this.participanteEmpresa.destinatarios + ' é inválido!' })
            return false
          }
        }
      }
    }

    if (!isNullOrUndefined(this.participante.im)) {
      if (this.participante.im !== '') {
        if (!Util.isNumber(this.participante.im)) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'A Inscrição Municipal deverá conter somente números!' })
          return false

        } else if (this.participante.im.length > 14) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'A Inscrição Municipal deverá conter no máximo 14 caracteres!' })
          return false
        }
      }
    }

    if (!isNullOrUndefined(this.participante.ie)) {
      if (this.participante.ie !== '') {
        if (this.participante.ie.length > 14) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'A Inscrição Estadual deverá conter no máximo 14 caracteres!' })
          return false
        }
      }
    }

    this.participanteList.forEach(element => {
      //Verifica se o usuário tem algum perfil cadastrado
      if (element.cnpj == this.cnpjVerificar || element.cpf == this.cnpjVerificar) {
        qtdUsuario++
        //Verifica se o usuário tem ambos perfis cadastrados
        if (qtdUsuario >= 2 && String(this.participanteEmpresa.participanteTipo) == '3') {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Já existe um cadastro com esse CNPJ/CPF!' })
          return false

        } else {
          //Verifica se o usuário tem o perfil de Fornecedor
          if (element.tipo == "Fornecedor" && String(this.participanteEmpresa.participanteTipo) == '2') {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Já existe um cadastro de fornecedor com esse CNPJ/CPF!' })
            return false

          } else if (element.tipo == "Cliente" && String(this.participanteEmpresa.participanteTipo) == '1') {
            if (this.participanteEmpresa.tipoContribuinte.id != 8 && (this.participante.ie != undefined || this.participante.ie != "null")) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Já existe um cadastro de cliente com esse CNPJ/CPF!' })
              return false
            }
          }
        }
      }
    })

    if (this.parametroFoneObrigatorio && (isNullOrUndefined(this.telefone) || this.telefone === '')) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Para salvar é preciso informar o telefone.' })
      return false
    }

    if (!isNullOrUndefined(this.dblinkedSessionService.empresa.segmentoObrigatorio)) {
      if (this.segmentoSelecionado === 0 && this.dblinkedSessionService.empresa.segmentoObrigatorio) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Para Salvar é preciso informar um Segmento!' })
        return false
      }
    }

    // se for CPF, invocar metodo salvar CPF - gck 06.05
    if (Util.isCnpj(this.cnpjVerificar)) {
      this.isCnpj = true
      this.isCpf = false
    }
    // se for CPF, invocar metodo salvar CPF - gck 06.05
    else if (Util.isCpf(this.cnpjVerificar)) {
      this.isCnpj = false
      this.isCpf = true
      //Verifica se o participante é Produtor Rural
      if (this.participanteEmpresa.tipoContribuinte.id === 8 && (this.participante.ie === undefined || this.participante.ie == "null")) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Dados Inválidos, informe a inscrição estadual!' })
        return false
      }

      //Verifica se é Isento ou Representante comercial
      if ((this.participante.regimeTributario.id === 5 || this.participanteEmpresa.tipoContribuinte.id === 7) && (this.participante.ie === undefined || this.participante.ie == "null")) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Por favor, preencha o campo inscrição estadual com ISENTO!' })
        return false
      }
    }
    if (isNullOrUndefined(this.participanteEmpresa.fantasia)) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: 'Informe o Nome Fantasia!' })
      return false
    }

    if (isNullOrUndefined(this.participante.nome)) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: 'Informe o Nome Completo!' })
      return false

    } else {
      if (this.participante.nome == '') {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: 'Informe o Nome Completo!' })
        return false
      }
    }

    if (!(Util.isCnpjOrCpf(this.cnpjVerificar)) && !this.exterior) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: 'Dados Inválidos, informe um CNPJ / CPF válido!' })
      return false
    }

    //Verifica se é Zona Franca ou Área de Livre Comércio
    if (this.participanteEmpresa.tipoContribuinte.id === 3 || this.participanteEmpresa.tipoContribuinte.id === 4) {
      if (this.participante.suframa == undefined || this.participante.suframa == '') {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: 'Dados Inválidos, informe suframa!' })
        return false
      }

      else if (this.participante.dtSuframa == undefined || this.participante.dtSuframa == null) {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: 'Dados Inválidos, informe data de validade!' })
        return false
      }
    }
    return true
  }


  validarSalvarParticipante(): boolean {
    if (this.participanteEmpresa.participanteTipo.id === 0 || this.participanteEmpresa.participanteTipo.id === null
      || this.participanteEmpresa.participanteTipo.id === undefined) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Para salvar é preciso informar o Tipo do Participante!'
      });
      return false
    }

    if (this.participante.participanteEnderecos.length === 0 || this.participante.participanteEnderecos.length === null
      || this.participante.participanteEnderecos.length === undefined) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Para salvar é preciso inserir um Endereço Válido!'
      });
      return false
    }

    if (!this.validacao()) return false

    return true
  }

  salvarParticipante() {
    this.modalEndereco = false;
    this.isCnpj = true;

    this.selecionarEmpresaCriadoraGrupoEmpresas()

    this.participante.cnpj = this.cnpjVerificar;
    this.participante.cpf = '';

    //se for cnpj salva
    if (this.isCnpj) {
      let tipo = '';
      if (this.existeCliente && !this.existeFornecedor) {
        tipo = 'f';
      }
      if (!this.existeCliente && this.existeFornecedor) {
        tipo = 'c';
      }

      if (!this.participanteEmpresa.limiteCredito) {
        this.participanteEmpresa.limiteCredito = 0.00;
      }

      if (!this.participanteEmpresa.limiteCompra) {
        this.participanteEmpresa.limiteCompra = 0.00;
      }

      // atribuindo o participanteempresa a lista
      this.participante.participanteEmpresas = new Array();
      this.participante.participanteEmpresas.push(this.participanteEmpresa);

      const listaEnviar = {
        participante: this.participante,
        tipo: tipo,
        exterior: this.exterior,
        manual: this.manual,
        empresa: this.dblinkedSessionService.empresa.id,
        authUser: this.sessionService.loggedUser.id,
        tipoContribuinte: this.participanteEmpresa.tipoContribuinte.id,
        tipoAssinante: this.participanteEmpresa.tipoAssinante.id,
        tipoClienteServicosComunicacao: this.participanteEmpresa.tipoClienteServicosComunicacao.id,
        dtNascimento: this.participante.dtNascimento,
        profissao: this.participante.profissao,
        genero: this.participante.genero,
        formaConhecimento: this.participante.formaConhecimento,
        observacoes: this.participante.observacoes,
        estadoCivil: this.participante.estadoCivil,
        empresasGrupo: this.empresasGrupoSelecionadas
      };

      const link = this.cadastroGrupoEmpresas ? '/custom/cadastro/vincular-participante-grupo-empresa' : '/custom/cadastro/vincular-participante-empresa'
      this.httpService.wait();
      this.httpService.post(link, listaEnviar).subscribe(
        result => {
          this.LogManagement.registerLog(
            LogModule.REGISTRATION,
            LogScreen.PARTICIPANTS,
            link,
            LogAction.CREATE,
            result.id,
            result.codigo,
            "O participante " + result.codigo + " foi criado",
            listaEnviar)

          this.voltar()
        },
        error => this.httpService.handleError(error, () => this.salvarParticipante()),
        () => this.httpService.done()
      )
    }
  }

  salvarCpfParticipante() {
    this.selecionarEmpresaCriadoraGrupoEmpresas()

    if (this.isCnpj) {
      this.participante.cnpj = this.cnpjVerificar;
    } else {
      this.participante.cpf = this.cnpjVerificar;
    }

    let segmentoCliente = 0;
    let segmentoFornecedor = 0;
    if (segmentoCliente) {
      segmentoCliente = this.segmentoCliente.id;
    }
    if (segmentoFornecedor) {
      segmentoFornecedor = this.segmentoFornecedor.id;
    }

    let tipo = '';
    if (this.existeCliente && !this.existeFornecedor) {
      tipo = 'f';
    }
    if (!this.existeCliente && this.existeFornecedor) {
      tipo = 'c';
    }

    if (!this.participanteEmpresa.limiteCredito) {
      this.participanteEmpresa.limiteCredito = 0.00;
    }

    if (!this.participanteEmpresa.limiteCompra) {
      this.participanteEmpresa.limiteCompra = 0.00;
    }

    // atribuindo o participanteempresa a lista
    this.participante.participanteEmpresas = new Array();
    this.participante.participanteEmpresas.push(this.participanteEmpresa);

    const listaEnviar = {
      participante: this.participante,
      cpf: this.cnpjVerificar,
      nome: this.participante.nome,
      fantasia: this.participanteEmpresa.fantasia,
      participanteTipo: this.participanteEmpresa.participanteTipo,
      segmento: this.participanteEmpresa.segmento.id,
      limiteCredito: this.utilService.parseDecimal(this.participanteEmpresa.limiteCredito),
      limiteCompra: this.utilService.parseDecimal(this.participanteEmpresa.limiteCompra),
      empresa: this.dblinkedSessionService.empresa.id,
      authUser: this.sessionService.loggedUser.id,
      tipo: tipo,
      inscricaoCene: this.participanteEmpresa.inscricaoCene,
      tipoContribuinte: this.participanteEmpresa.tipoContribuinte.id,
      tipoAssinante: this.participanteEmpresa.tipoAssinante.id,
      tipoClienteServicosComunicacao: this.participanteEmpresa.tipoClienteServicosComunicacao.id,
      dtNascimento: this.participante.dtNascimento,
      rg: this.participante.rg,
      profissao: this.participante.profissao,
      genero: this.participante.genero,
      formaConhecimento: this.participante.formaConhecimento,
      observacoes: this.participante.observacoes,
      estadoCivil: this.participante.estadoCivil,
      exterior: this.exterior,
      manual: this.manual,
      destinatarios: this.participanteEmpresa.destinatarios,
      empresasGrupo: this.empresasGrupoSelecionadas,
      chavesPix: this.chavesPix
    };

    const link = this.cadastroGrupoEmpresas ? '/custom/cadastro/criar-participante-grupo-empresa' : '/custom/cadastro/criar-cpf-participante'
    this.httpService.wait();
    this.httpService.post(link, listaEnviar)
      .subscribe(
        result => {
          this.LogManagement.registerLog(
            LogModule.REGISTRATION,
            LogScreen.PARTICIPANTS,
            link,
            LogAction.CREATE,
            result.id,
            result.codigo,
            "O participante " + result.codigo + " foi criado",
            listaEnviar)

          this.resultadoEdicao(result)
        },
        error => this.httpService.handleError(error, () => this.salvarParticipante()),
        () => this.httpService.done()
      );
  }


  validarParticipanteManual(): boolean {
    if (this.participanteEmpresa.participanteTipo.id === 0 || this.participanteEmpresa.participanteTipo.id === null || this.participanteEmpresa.participanteTipo.id === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Para salvar é preciso informar o Tipo do Participante!' })
      return false
    }

    if (this.participante.participanteEnderecos.length === 0 || this.participante.participanteEnderecos.length === null || this.participante.participanteEnderecos.length === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Para salvar é preciso inserir um Endereço Válido!' })
      return false
    }

    if (this.cnpjVerificar !== '') {
      if (!this.validacao()) return false
      if (this.isCnpj) this.participante.cnpj = this.cnpjVerificar
      else this.participante.cpf = this.cnpjVerificar

    } else {
      if ((this.participanteEmpresa.participanteTipo.id === 1 || this.participanteEmpresa.participanteTipo.id === 3) && !this.participanteEmpresa.destinatarios) {
        this.messageService.add({ severity: 'warn', summary: 'MSG', detail: 'Para tipo de participante cliente/ambos um e-mail de participante deverá ser informado!' })
        return false

      } else {
        if (this.participanteEmpresa.destinatarios) {
          if (this.participanteEmpresa.destinatarios.indexOf(";") != -1) {
            var splitted = this.participanteEmpresa.destinatarios.split(";")
            let invalido = false
            splitted.forEach(element => {
              if (!Util.validacaoEmail(element.trim())) {
                this.messageService.add({ severity: 'warn', summary: 'MSG', detail: 'o e-mail ' + element + ' é inválido!' })
                invalido = true
              }
            })
            if (invalido) return false

          } else {
            if (!Util.validacaoEmail(this.participanteEmpresa.destinatarios.trim())) {
              this.messageService.add({ severity: 'warn', summary: 'MSG', detail: 'o e-mail ' + this.participanteEmpresa.destinatarios + ' é inválido!' })
              return false
            }
          }
        }
      }
    }

    if (this.participanteEmpresa.fantasia === undefined) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: 'Informe o Nome Fantasia!' })
      return false
    }

    if (isNullOrUndefined(this.participante.regimeTributario.id)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'É necessário informar o Regime Tributário!' })
      return false
    }

    return true

  }

  salvarParticipanteManual() {
    this.modalEndereco = false;

    this.selecionarEmpresaCriadoraGrupoEmpresas()

    let segmentoCliente = 0
    let segmentoFornecedor = 0
    if (segmentoCliente) segmentoCliente = this.segmentoCliente.id
    if (segmentoFornecedor) segmentoFornecedor = this.segmentoFornecedor.id

    let tipo = ''
    if (this.existeCliente && !this.existeFornecedor) tipo = 'f'
    if (!this.existeCliente && this.existeFornecedor) tipo = 'c'
    if (!this.participanteEmpresa.limiteCredito) this.participanteEmpresa.limiteCredito = 0.00
    if (!this.participanteEmpresa.limiteCompra) this.participanteEmpresa.limiteCompra = 0.00

    this.participante.participanteEmpresas = new Array();
    this.participante.participanteEmpresas.push(this.participanteEmpresa);

    const listaEnviar = {
      participante: this.participante,
      tipo: tipo,
      exterior: this.exterior,
      manual: this.manual,
      empresa: this.dblinkedSessionService.empresa.id,
      authUser: this.sessionService.loggedUser.id,
      tipoContribuinte: this.participanteEmpresa.tipoContribuinte.id,
      tipoAssinante: this.participanteEmpresa.tipoAssinante.id,
      tipoClienteServicosComunicacao: this.participanteEmpresa.tipoClienteServicosComunicacao.id,
      dtNascimento: this.participante.dtNascimento,
      rg: this.participante.rg,
      profissao: this.participante.profissao,
      genero: this.participante.genero,
      formaConhecimento: this.participante.formaConhecimento,
      observacoes: this.participante.observacoes,
      estadoCivil: this.participante.estadoCivil,
      segmento: this.segmentoSelecionado,
      regimeTributario: this.participante.regimeTributario.id,
      empresasGrupo: this.empresasGrupoSelecionadas
    }

    const link = this.cadastroGrupoEmpresas ? '/custom/cadastro/criar-participante-grupo-empresa' : '/custom/cadastro/criar-participante-manual'
    this.httpService.wait()
    this.httpService.post(link, listaEnviar)
      .subscribe(
        result => {
          this.LogManagement.registerLog(
            LogModule.REGISTRATION,
            LogScreen.PARTICIPANTS,
            link,
            LogAction.CREATE,
            result.id,
            result.codigo,
            "O participante " + result.codigo + " foi criado",
            listaEnviar)

          this.resultadoEdicao(result)
        },
        error => this.httpService.handleError(error, () => this.salvarParticipante()),
        () => this.httpService.done()
      )
  }


  validarParticipanteManualExterior(): boolean {
    if (this.participanteEmpresa.participanteTipo.id === 0 || this.participanteEmpresa.participanteTipo.id === null || this.participanteEmpresa.participanteTipo.id === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Para salvar é preciso informar o Tipo do Participante!' })
      return false
    }

    if (this.participante.participanteEnderecos.length === 0 || this.participante.participanteEnderecos.length === null || this.participante.participanteEnderecos.length === undefined) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Para salvar é preciso inserir um Endereço Válido!' })
      return false
    }

    if (this.cnpjVerificar !== '') {
      if (!this.validacao()) return false

    } else {
      if ((this.participanteEmpresa.participanteTipo.id === 1 || this.participanteEmpresa.participanteTipo.id === 3) && !this.participanteEmpresa.destinatarios) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'Para tipo de participante cliente/ambos um e-mail de participante deverá ser informado!' })
        return false

      } else {
        if (this.participanteEmpresa.destinatarios) {
          if (this.participanteEmpresa.destinatarios.indexOf(";") != -1) {
            var splitted = this.participanteEmpresa.destinatarios.split(";")
            let invalido = false
            splitted.forEach(element => {
              if (!Util.validacaoEmail(element.trim())) {
                this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'o e-mail ' + element + ' é inválido!' })
                invalido = true
              }
            })
            if (invalido) return false

          } else {
            if (!Util.validacaoEmail(this.participanteEmpresa.destinatarios.trim())) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG', detail: 'o e-mail ' + this.participanteEmpresa.destinatarios + ' é inválido!' })
              return false
            }
          }
        }
      }
    }

    if (this.participanteEmpresa.fantasia === undefined) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: 'Informe o Nome Fantasia!' })
      return false
    }

    return true
  }

  salvarParticipanteManualExterior() {
    this.modalEndereco = false
    console.log("Passei 1")

    this.selecionarEmpresaCriadoraGrupoEmpresas()

    console.log("Passei 2")
    let segmentoCliente = 0;
    let segmentoFornecedor = 0;
    if (segmentoCliente) segmentoCliente = this.segmentoCliente.id
    if (segmentoFornecedor) segmentoFornecedor = this.segmentoFornecedor.id

    let tipo = ''
    if (this.existeCliente && !this.existeFornecedor) tipo = 'f'
    if (!this.existeCliente && this.existeFornecedor) tipo = 'c'
    if (!this.participanteEmpresa.limiteCredito) this.participanteEmpresa.limiteCredito = 0.00
    if (!this.participanteEmpresa.limiteCompra) this.participanteEmpresa.limiteCompra = 0.00

    this.participante.participanteEmpresas = new Array()
    this.participante.participanteEmpresas.push(this.participanteEmpresa)

    this.participante.regimeTributario.id = 3
    const listaEnviar = {
      participante: this.participante,
      tipo: tipo,
      exterior: this.exterior,
      manual: this.manual,
      empresa: this.dblinkedSessionService.empresa.id,
      authUser: this.sessionService.loggedUser.id,
      tipoContribuinte: this.participanteEmpresa.tipoContribuinte.id,
      tipoAssinante: this.participanteEmpresa.tipoAssinante.id,
      tipoClienteServicosComunicacao: this.participanteEmpresa.tipoClienteServicosComunicacao.id,
      dtNascimento: this.participante.dtNascimento,
      rg: this.participante.rg,
      profissao: this.participante.profissao,
      genero: this.participante.genero,
      formaConhecimento: this.participante.formaConhecimento,
      observacoes: this.participante.observacoes,
      estadoCivil: this.participante.estadoCivil,
    }

    const link = this.cadastroGrupoEmpresas ? '/custom/cadastro/criar-participante-grupo-empresa' : '/custom/cadastro/criar-participante-manual'

    this.httpService.wait()
    this.httpService.post(link, listaEnviar).subscribe(
      result => {
        this.LogManagement.registerLog(
          LogModule.REGISTRATION,
          LogScreen.PARTICIPANTS,
          link,
          LogAction.CREATE,
          result.id,
          result.codigo,
          "O participante " + result.codigo + " foi criado",
          listaEnviar)

        this.resultadoEdicao(result)
      },
      error => this.httpService.handleError(error, () => this.salvarParticipante()),
      () => this.httpService.done()
    )
  }


  salvarAlteracaoParticipante() {
    if (!this.validacao()) return;

    this.selecionarEmpresaCriadoraGrupoEmpresas()

    this.modalEndereco = false;
    if (this.participante.participanteEnderecos.length === 0 || this.participante.participanteEnderecos.length === null
      || this.participante.participanteEnderecos.length === undefined) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG',
        detail: 'Para salvar é preciso inserir um Endereço Válido!'
      });
      return;
    }

    if (!isNullOrUndefined(this.dblinkedSessionService.empresa.segmentoObrigatorio)) {

      if ((this.segmentoSelecionado === 0 || isNullOrUndefined(this.segmentoSelecionado)) && this.dblinkedSessionService.empresa.segmentoObrigatorio) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Para salvar é preciso informar um Segmento!'
        });
        return;
      }
    }


    if (!this.participanteEmpresa.limiteCredito) {
      this.participanteEmpresa.limiteCredito = 0.00;
    }

    if (!this.participanteEmpresa.limiteCompra) {
      this.participanteEmpresa.limiteCompra = 0.00;
    }

    this.participante.participanteEmpresas = new Array();
    this.participante.participanteEmpresas.push(this.participanteEmpresa);

    let data = {}
    data = {
      participante: this.participante,
      exterior: this.exterior,
      manual: this.manual,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuario: this.dblinkedSessionService.usuario.id,
      segmento: this.segmentoSelecionado,
      dtNascimento: this.participante.dtNascimento,
      rg: this.participante.rg,
      profissao: this.participante.profissao,
      genero: this.participante.genero,
      formaConhecimento: this.participante.formaConhecimento,
      observacoes: this.participante.observacoes,
      estadoCivil: this.participante.estadoCivil,
      tipoAssinante: this.participanteEmpresa.tipoAssinante.id,
      tipoClienteServicosComunicacao: this.participanteEmpresa.tipoClienteServicosComunicacao.id,
      empresasGrupo: this.empresasGrupoSelecionadas,
      chavesPix: this.chavesPix,
      userId: this.sessionService.loggedUser.id
    }

    const link = this.cadastroGrupoEmpresas ? '/custom/cadastro/editar-participante-grupo-empresa' : '/custom/cadastro/editar-participante'

    this.httpService.wait()
    this.httpService.post(link, data).subscribe(
      result => {
        this.LogManagement.registerLog(
          LogModule.REGISTRATION,
          LogScreen.PARTICIPANTS,
          link,
          LogAction.EDIT,
          this.participante.id,
          this.participanteEmpresa.codigo,
          "O participante (" + this.participanteEmpresa.codigo + ") foi modificado",
          data)

        this.resultadoEdicao(result)
      },
      error => this.httpService.handleError(error, () => this.salvarAlteracaoParticipante()),
      () => this.httpService.done()
    )
  }


  resultadoEdicao(result: any) {
    if (result.error) {
      this.messageService.add({
        severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: result.message
      });
    } else {
      this.messageService.add({
        severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: result.message
      });
      this.voltar();
    }
  }


  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  voltar() {
    this.cnpjVerificar = '';
    this.participante = new Participante();
    this.participante.participanteEnderecos = new Array();
    this.participante.participanteDadosBancarios = new Array();
    this.participante.regimeTributario = new RegimeTributario();
    this.participanteEmpresa = new ParticipanteEmpresa();
    this.participanteEmpresa.participanteTipo = new ParticipanteTipo();
    this.participanteEmpresa.inscricaoCene = false;
    this.participanteEmpresa.segmento = new Segmento();
    this.participanteEmpresa.segmento.id = 0;
    this.participanteEmpresa.tipoContribuinte = new TipoContribuinte();
    this.participanteEmpresa.tipoAssinante = new TipoAssinante();
    this.participanteEmpresa.tipoClienteServicosComunicacao = new TipoClienteServicosComunicacao();
    this.modalParticipante = false;
    this.segmentoCliente = new Segmento();
    this.segmentoFornecedor = new Segmento();
    this.tiposExistentes = new Array();
    this.existeFornecedor = false;
    this.existeCliente = false;
    this.editar = false;
    this.isCpf = false;
    this.isCnpj = false;
    this.codigo = 'NE';
    this.exterior = false;
    this.hideButtons = true;
    this.readOnly = false;
    this.segmentoSelecionado = 0;
    /* this.logradouro = '';
     this.numero = '';
     this.participanteEmpresa.complemento = '';
     this.bairro = '';
     this.cep = '';
     this.cidade = '';
     this.uf = '';
     this.pais = new Pais();*/
    this.participanteList = new Array();
    this.manual = false;
    this.pesquisaApi = true;

    this.criar = false;
    this.editar = false;
    this.empresaChanged();
    this.modalGrupoEmpresas = false
    this.selecionarTodasEmpresasGrupo()
  }


  novoEndereco() {
    this.modalEndereco = true;
    this.participanteEndereco = new ParticipanteEndereco();
    //this.participanteEndereco.participante = new Participante();
    this.participanteEndereco.endereco = new Endereco();
    this.participanteEndereco.endereco.pais = new Pais();
    this.participanteEndereco.endereco.uf = new Uf();
    this.participanteEndereco.endereco.municipioIbge = new MunicipioIbge();
    this.participanteEndereco.enderecoTipo = new EnderecoTipo();

    if (this.participante.participanteEnderecos.length === 0) {
      this.participanteEndereco.principal = true;
    } else {
      this.participanteEndereco.principal = false;
    }
  }


  adicionarEndereco() {
    if (this.participanteEndereco.enderecoTipo.id === undefined) {
      this.msgsModal = [{ severity: 'warn', summary: 'MSG: ', detail: 'Selecione tipo do endereço!' }];
      return;
    }
    if (this.participanteEndereco.endereco.uf.id === undefined && !this.manual && !this.exterior) {
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG: ',
        detail: 'Selecione um estado!'
      }];
      return;
    }
    if (this.participanteEndereco.endereco.municipioIbge.id === undefined && !this.manual && !this.exterior) {
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG: ',
        detail: 'Selecione um município!'
      }];
      return;
    }

    // PAIS
    if (this.exterior) {
      if (this.participanteEndereco.endereco.pais.id === null || this.participanteEndereco.endereco.pais.id === undefined) {
        this.msgsModal = [{
          severity: 'warn',
          summary: 'MSG',
          detail: 'Selecione o País para Prosseguir!'
        }];
        return;
      }

      if (this.participanteEndereco.endereco.pais.id === 105) {
        this.msgsModal = [{
          severity: 'warn',
          summary: 'MSG',
          detail: 'Paticipante Domiciliado no Exterior, não pode ser Preenchido como Brasil!'
        }];
        return;
      }

      // FIM PAIS
      if (isNullOrUndefined(this.participanteEndereco.endereco.cidade)) {
        // if (this.participanteEndereco.endereco.cidade.length < 5) {
        this.msgsModal = [{
          severity: 'warn',
          summary: 'MSG',
          detail: 'Campo Cidade não pode ser vázio, Verifique!'
        }];
        return;
      }
      // }
    }


    // LOGRADOURO
    if (this.participanteEndereco.endereco.logradouro === null || this.participanteEndereco.endereco.logradouro === undefined) {
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG',
        detail: 'Campo Logradouro não pode ser vázio, Verifique!'
      }];
      return;
    }
    // FIM LOGRADOURO

    // BAIRRO
    if (this.participanteEndereco.endereco.bairro === null || this.participanteEndereco.endereco.bairro === undefined) {
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG',
        detail: 'Campo Bairro não pode ser vázio, Verifique!'
      }];
      return;
    }

    // FIM BAIRRO


    // NÚMERO
    if (this.participanteEndereco.endereco.numero === null || this.participanteEndereco.endereco.numero === undefined) {
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG',
        detail: 'Insira o Número do Local para Prosseguir!'
      }];
      return;
    }
    // FIM NÚMERO


    // CEP
    if (!this.exterior) {
      if (!isNullOrUndefined(this.participanteEndereco.endereco.cep)) {
        if (this.participanteEndereco.endereco.cep === '') {
          this.msgsModal = [{
            severity: 'warn',
            summary: 'MSG',
            detail: 'Insira o CEP para Prosseguir!'
          }];
          return;
        }
      }
    }
    // FIM CEP

    this.modalEndereco = false;
    const d = this.enderecoTipos.find(t => t.value === this.participanteEndereco.enderecoTipo.id);
    this.participanteEndereco.enderecoTipo.descricao = d.label;
    if (this.exterior) {
      this.participanteEndereco.endereco.municipioIbge.id = null;
      this.participanteEndereco.endereco.uf.id = null;
      this.participanteEndereco.endereco.pais.nome = this.paises.filter(pais => pais.value == this.participanteEndereco.endereco.pais.id)[0].label;

    } else {
      const indexCity = this.cidades.find(c => c.value === this.participanteEndereco.endereco.municipioIbge.id);
      this.participanteEndereco.endereco.municipioIbge.nome = indexCity.label;
      const indexUf = this.ufs.find(u => u.value === this.participanteEndereco.endereco.uf.id);
      this.participanteEndereco.endereco.uf.uf = indexUf.label;
    }
    if (this.participanteEndereco.id === undefined) {
      this.participanteEndereco.id = null;
      this.participante.participanteEnderecos.push(this.participanteEndereco);
    } else {
      const i = this.participante.participanteEnderecos.findIndex(e => e == this.participanteEndereco)
      this.participante.participanteEnderecos.splice(i, 1, this.participanteEndereco);
    }
    // removendo o tipo de endereço utilizado
    const index = this.enderecoTipos.findIndex(c => c.value === this.participanteEndereco.enderecoTipo.id);
    this.enderecoTipos.splice(index, 1);
  }


  public async pesquisarCep(): Promise<void> {
    this.participanteEndereco.endereco.cep = this.participanteEndereco.endereco.cep.replace(/[^\d]+/g, '');

    if (this.participanteEndereco.endereco.cep.length !== 8) {
      this.msgsModal = [{ severity: 'warn', summary: 'MSG: ', detail: 'CEP deverá conter 8 caracteres!' }];
      return;
    } else {
      try {
        this.httpService.wait()
        this.httpClient.get(`https://viacep.com.br/ws/${this.participanteEndereco.endereco.cep}/json/`).toPromise().then(res => {
          if (!res['erro']) {
            this.participanteEndereco.endereco.logradouro = res['logradouro'] ? res['logradouro'] : null
            this.participanteEndereco.endereco.bairro = res["bairro"] ? res["bairro"] : null;
            this.participanteEndereco.endereco.uf.uf = res["uf"] ? res["uf"] : null;
            this.participanteEndereco.endereco.municipioIbge.nome = res["localidade"] ? res["localidade"] : null;
            this.participanteEndereco.endereco.numero = '';
            this.participanteEndereco.endereco.complemento = res["complemento"] ? res["complemento"] : null;
            this.obterUF(res["uf"], res["ibge"])
          } else this.resetarEndereco()
        }).catch(err => this.resetarEndereco()).finally(() => this.httpService.done())
      } catch (err) {
        this.resetarEndereco()
      }
    }
  }


  async obterUF(uf, codIbge): Promise<void> {
    this.httpService.wait()
    await this.httpService.get('/uf?$select=id,uf&$filter=uf eq ' + '\'' + uf + '\'').toPromise().then(res => {
      this.participanteEndereco.endereco.uf.id = res.value[0].id;
      if (this.participanteEndereco.endereco.uf.id) {
        this.comboMunicipio();
        setTimeout(() => this.setCidadeBusca(codIbge), 1000);
      }
    })
    this.httpService.done()
  }


  private resetarEndereco(): void {
    this.messageService.add({
      severity: 'warn',
      key: 'messageLinnks',
      summary: 'MSG: ',
      detail: 'CEP não encontrado, preencha o endereço manualmente.'
    });

    this.participanteEndereco.endereco.logradouro = null
    this.participanteEndereco.endereco.bairro = null;
    this.participanteEndereco.endereco.uf.uf = null;
    this.participanteEndereco.endereco.municipioIbge.nome = null;
    this.participanteEndereco.endereco.numero = '';
    this.participanteEndereco.endereco.complemento = null;
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 46) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  enderecoEditar(end: any) {
    if (end.endereco.pais.id === undefined || end.endereco.pais.id === null) {
      const event = { 'value': end.endereco.uf.id };
      this.loadMunicipioIbge(event);
    } else {
      this.exterior = true;
    }

    setTimeout(() => {
      this.participanteEndereco = new ParticipanteEndereco();
      this.participanteEndereco = end;
      this.participanteEndereco.id = end.id;
      if (this.exterior) {
        this.participanteEndereco.endereco.cidade = end.cidade;
      } else {
        this.participanteEndereco.endereco.cidade = end.endereco.municipioIbge.nome;
      }

      this.enderecoTipos.push({ label: end.enderecoTipo.descricao, value: end.enderecoTipo.id });
      this.modalEndereco = true;
    }, 700);
  }


  enderecoExcluir(end) {
    if (confirm('Deseja realmente excluir este endereço?')) {
      const ende = this.participante.participanteEnderecos.findIndex(t => t === end);
      this.participante.participanteEnderecos.splice(ende, 1);
    }
  }


  contatoNovo() {
    this.modalContato = true;
    this.participanteContato = new ParticipanteContato()
    this.participanteContato.participanteEmpresa = new ParticipanteEmpresa();
    this.participanteContato.participanteEmpresa.participanteId = this.participante.id;
    this.participanteContato.participanteEmpresa.codigo = this.participanteEmpresa.codigo;

    if (this.participante.participanteContatos.length === 0) {
      this.participanteContato.principal = true;
    } else {
      this.participanteContato.principal = false;
    }
  }


  atualizaVariavelContatoPrincipal() {
    this.principal = false;
    this.participante.participanteContatos.forEach(element => {
      if (element.principal) {
        this.principal = true;
      }
    });
  }


  contatoAdicionar() {
    this.msgsModal = [];
    if (this.participanteContato.email !== undefined && this.participanteContato.email !== '' && this.participanteContato.email !== null) {
      if (!Util.validacaoEmail(this.participanteContato.email)) {
        this.msgsModal = [{
          severity: 'warn',
          summary: 'MSG: ',
          detail: 'Endereço de email inválido. Verifique!'
        }];
        this.participanteContato.principal = false;
        return;
      }
    }
    if (this.participanteContato.tipoContato === undefined || this.participanteContato.tipoContato === '' || this.participanteContato.tipoContato === null) {
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG: ',
        detail: 'Informe o tipo do Contato. Ex. Comercial!'
      }];
      this.participanteContato.principal = false;
      return;
    }

    this.informaContatoPrincipalMsg();

    this.modalContato = false;

    if (this.participanteContato.id === undefined) {
      this.participanteContato.id = null;
      this.participante.participanteContatos.push(this.participanteContato);
    } else {
      const i = this.participante.participanteContatos.findIndex(c => c == this.participanteContato)
      this.participante.participanteContatos.splice(i, 1, this.participanteContato);
    }
  }

  informaContatoPrincipalMsg() {
    if (this.participante.participanteContatos.length > 0 && (this.participanteContato.principal === false && this.principal === false)) {
      this.msgsModal = [];
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG: ',
        detail: 'Informe o Contato principal!'
      }];
    }
  }

  contatoEditar(contact) {
    this.modalContato = true;
    this.participanteContato = new ParticipanteContato()
    this.participanteContato = contact;
  }

  contatoExcluir(contact) {

    if (contact.principal === true) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Marque outro Contato como principal para exluir este!'
      });
    } else {
      if (confirm('Deseja realmente excluir este Contato?')) {
        const contato = this.participante.participanteContatos.findIndex(t => t === contact);
        this.participante.participanteContatos.splice(contato, 1);
      }
    }
  }

  dadoBancarioNovo() {
    this.msgsModal = [];
    this.modalDadosBancarios = true;
    this.participanteDadoBancario = new ParticipanteDadoBancario;
    this.participanteDadoBancario.participanteEmpresa = new ParticipanteEmpresa();
    this.participanteDadoBancario.banco = new InstituicaoFinanceira();
    this.participanteDadoBancario.participanteEmpresa.participanteId = this.participante.id;
    this.participanteDadoBancario.participanteEmpresa.codigo = this.participanteEmpresa.codigo;

    if (this.participante.participanteDadosBancarios.length === 0) {
      this.participanteDadoBancario.contaPrincipal = true;
    } else {
      this.participanteDadoBancario.contaPrincipal = false;
    }

  }

  dadoBancarioEditar(conta) {
    this.modalDadosBancarios = true;
    this.participanteDadoBancario = new ParticipanteDadoBancario;
    this.participanteDadoBancario = conta;
  }

  dadoBancarioExcluir(conta) {

    if (conta.contaPrincipal === true) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Marque outra Conta como principal para exluir esta!'
      });
    } else {
      if (confirm('Deseja realmente excluir esta Conta?')) {
        const con = this.participante.participanteDadosBancarios.findIndex(t => t === conta);
        this.participante.participanteDadosBancarios.splice(con, 1);
      }
    }
  }

  atualizaVariavelBancarioContaPrincipal() {
    this.contaPrincipal = false;
    this.participante.participanteDadosBancarios.forEach(element => {
      if (element.contaPrincipal) {
        this.contaPrincipal = true;
      }
    });
  }

  dadoBancarioAdicionar() {

    if (this.participanteDadoBancario.tipoConta === undefined || this.participanteDadoBancario.tipoConta === '' || this.participanteDadoBancario.tipoConta === null) {
      this.msgsModal = [];
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG: ',
        detail: 'Informe o tipo da conta. Ex. Conta Corrente!'
      }];
      this.contaPrincipal = false;
      return;
    }

    if (this.participanteDadoBancario.banco.id === undefined) {
      this.msgsModal = [];
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG: ',
        detail: 'Selecione uma Instituição Bancária para prosseguir!'
      }];
      this.contaPrincipal = false;
      return;
    }

    this.informaContaPrincipalMsg();

    if (this.participanteDadoBancario.id === undefined) {
      this.participanteDadoBancario.id = null;
      const bank = this.bancosTipos.find(b => b.value === this.participanteDadoBancario.banco.id);
      this.participanteDadoBancario.banco.codigo = bank.label.split(' - ')[0];
      this.participanteDadoBancario.banco.descricao = bank.label.split(' - ')[1];
      this.participante.participanteDadosBancarios.push(this.participanteDadoBancario);

    } else {
      const bank = this.bancosTipos.find(b => b.value === this.participanteDadoBancario.banco.id);
      this.participanteDadoBancario.banco.codigo = bank.label.split(' - ')[0];
      this.participanteDadoBancario.banco.descricao = bank.label.split(' - ')[1];
      const i = this.participante.participanteDadosBancarios.findIndex(c => c == this.participanteDadoBancario)
      this.participante.participanteDadosBancarios.splice(i, 1, this.participanteDadoBancario);
    }
    this.modalDadosBancarios = false;

  }

  informaContaPrincipalMsg() {
    if (this.participante.participanteDadosBancarios.length > 0 && (this.participanteDadoBancario.contaPrincipal === false && this.contaPrincipal === false)) {
      this.msgsModal = [];
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG: ',
        detail: 'Informe a Conta principal!'
      }];
    }
  }

  contaPrincipalChange(event: any) {
    if ((this.contaPrincipal && event.checked)) {
      this.msgsModal = [];
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG: ',
        detail: 'Ja existe uma conta marcada como principal!'
      }];
    }
    this.contaPrincipal = event.checked;
  }

  cancelarModalBeneficio() {
    this.modalBeneficios = false;
  }

  principalChange(event: any) {
    if ((this.principal && event.checked)) {
      this.msgsModal = [];
      this.msgsModal = [{
        severity: 'warn',
        summary: 'MSG: ',
        detail: 'Já existe um contato marcado como principal!'
      }];
    }
    this.principal = event.checked;
  }

  setCidadeBusca(codigoIbge) {
    if (codigoIbge) {
      this.httpService.wait()
      this.httpService.get('/municipioibge?$select=id,nome&$filter=cod eq ' + '\'' + codigoIbge + '\'')
        .subscribe(
          cid => {
            this.participanteEndereco.endereco.municipioIbge.id = cid.value[0].id;
          },
          error => this.httpService.handleError(error, () => this.pesquisarCep()),
          () => this.httpService.done()
        );

    } else {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Cidade não encontrada, selecione nas opções'
      });
      return;
    }

  }

  comboMunicipio() {
    this.cidades = [];
    this.cidades.push({ label: 'Selecione', value: null });

    this.httpService.wait()
    this.httpService.get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + this.participanteEndereco.endereco.uf.id)
      .subscribe(
        ref => {
          ref.value.forEach(element => {
            this.cidades.push({
              label: element.nome,
              value: element.id
            });
          });
        },
        error =>
          this.httpService.handleError(error, () => this.comboMunicipio()),
        () => this.httpService.done()
      );
  }


  verificarCnpjCpf() {
    if (Util.isCnpjOrCpf(this.cnpjVerificar)) {
      this.cnpjVerificar = this.cnpjVerificar.replace(/[^\d]+/g, '');
      if (Util.isCnpj(this.cnpjVerificar)) {

        this.isCnpj = true;
        this.isCpf = false;
        // this.manual = true;

        if (!isNullOrUndefined(this.participanteEmpresa.participanteTipo.id)) {
          this.validaCnpj(this.dblinkedSessionService.empresa.id, this.cnpjVerificar, this.participanteEmpresa.participanteTipo.id);
        }


      } else {
        this.isCpf = true;
        this.isCnpj = false;
        this.exterior = false;
        // this.manual = true;

        if (!isNullOrUndefined(this.participanteEmpresa.participanteTipo.id)) {
          this.validaCnpj(this.dblinkedSessionService.empresa.id, this.cnpjVerificar, this.participanteEmpresa.participanteTipo.id);
        }
      }
    }
  }

  validaCnpj(empresaId, cnpjOrCpf, participanteTipoId) {


    this.httpService.wait();
    this.httpService.get('/custom/cadastro/valida-cnpj-ou-cpf-cadastrado(' + empresaId + ',' + cnpjOrCpf + ',' + participanteTipoId +
      ')').subscribe(
        ref => {
          if (ref.cnpjCadastrado) {

            this.messageService.add({
              severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Existe um Participante cadastrado com esse CNPJ/CPF e Tipo participante, Verifique!'
            });
            this.empresaChanged();
            return;
          }
        },
        error =>
          this.httpService.handleError(error, () => this.verificarCnpjCpf()),
        () => this.httpService.done()
      );
  }

  currentStateCad = 'initial';


  async getProfessions(): Promise<void> {
    try {
      this.tiposProfissao = [{ label: 'Selecione', value: null }]
      await this.httpService.get('/custom/cadastro/get-profissoes-ativas', this.dblinkedSessionService.empresa.id).toPromise().then(res => {
        for (let line of res) this.tiposProfissao.push({ label: line["profissao"], value: line["id"] })
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar as profissões! Contate o suporte' })
    }
  }

  async getGenres(): Promise<void> {
    try {
      this.tiposGenero = [{ label: 'Selecione', value: null }]
      await this.httpService.get('/custom/cadastro/get-generos-ativos', this.dblinkedSessionService.empresa.id).toPromise().then(res => {
        for (let line of res) this.tiposGenero.push({ label: line["genero"], value: line["id"] });
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar os gêneros! Contate o suporte' })
    }
  }

  async getKnowledgeForms(): Promise<void> {
    try {
      this.tiposFormaConhecimento = [{ label: 'Selecione', value: null }]
      await this.httpService.get('/custom/cadastro/get-formas-conhecimento-ativas', this.dblinkedSessionService.empresa.id).toPromise().then(res => {
        for (let line of res) this.tiposFormaConhecimento.push({ label: line["forma_conhecimento"], value: line["id"] })
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar as formas de conhecimento! Contate o suporte' })
    }
  }

  exibirMensagem({ mensagem, error }) {

    if (!error) {
      this.messageService.add({
        severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: status ? status : mensagem
      });

    } else {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: status ? status : mensagem
      });
    }
  }

  mudarTipoBusca(event: any, acao: any) {

    if (confirm('Deseja mudar a forma de cadastro? as alterações não salvas serão perdidas')) {
      if (acao == 'BUSCA AUTOMÁTICA') {
        this.pesquisaApi = true;
        this.manual = false;

        this.participante.nome = null;
        this.participanteEmpresa.fantasia = null;
        this.participante.cnae = null;
        this.participanteEmpresa.limiteCredito = null;
        this.participante.dtNascimento = null;
        this.participante.genero = null;
        this.participante.profissao = null;
        this.participante.formaConhecimento = null;
        this.participante.observacoes = null;
        this.participante.rg = null;
        this.participante.estadoCivil = null;

        // Fiscal
        this.participante.regimeTributario.descricao = null;
        this.participante.regimeTributario = new RegimeTributario();
        this.participante.ie = null;
        this.participanteEmpresa.contribuinte = false;
        this.participanteEmpresa.contribuinteIpi = false;
        this.participanteEmpresa.orgaoPublico = false;
        this.participanteEmpresa.tipoContribuinte = new TipoContribuinte();
        this.participanteEmpresa.tipoAssinante = new TipoAssinante();
        this.participanteEmpresa.tipoClienteServicosComunicacao = new TipoClienteServicosComunicacao();

        this.participante.suframa = null;
        this.participante.dtSuframa = null;
        this.participante.im = null;
        this.participanteEmpresa.inscricaoCene = false;
        this.participanteEmpresa.destinatarios = null;

        // Endereço
        this.participante.participanteEnderecos = new Array();

        // Contato
        this.participante.participanteContatos = new Array();

        // Dados bancários
        this.participante.participanteDadosBancarios = new Array();

      } else if (acao == 'INCLUSÃO MANUAL') {
        this.pesquisaApi = false;
        this.manual = true;

        this.participante.nome = null;
        this.participanteEmpresa.fantasia = null;
        this.participante.cnae = null;
        this.participanteEmpresa.limiteCredito = null;
        this.participante.dtNascimento = null;
        this.participante.genero = null;
        this.participante.profissao = null;
        this.participante.formaConhecimento = null;
        this.participante.observacoes = null;
        this.participante.rg = null;
        this.participante.estadoCivil = null;

        // Fiscal
        this.participante.regimeTributario.descricao = null;
        this.participante.regimeTributario = new RegimeTributario();
        this.participante.ie = null;
        this.participanteEmpresa.contribuinte = false;
        this.participanteEmpresa.contribuinteIpi = false;
        this.participanteEmpresa.orgaoPublico = false;
        this.participanteEmpresa.tipoContribuinte = new TipoContribuinte();
        this.participanteEmpresa.tipoAssinante = new TipoAssinante();
        this.participanteEmpresa.tipoClienteServicosComunicacao = new TipoClienteServicosComunicacao();
        this.participante.suframa = null;
        this.participante.dtSuframa = null;
        this.participante.im = null;
        this.participanteEmpresa.inscricaoCene = false;
        this.participanteEmpresa.destinatarios = null;

        // Endereço
        this.participante.participanteEnderecos = new Array();

        // Contato
        this.participante.participanteContatos = new Array();

        // Dados bancários
        this.participante.participanteDadosBancarios = new Array();
      }
    } else {
      if (this.manual === false && this.pesquisaApi === false) {//click e cancel em opção já ticada
        if (acao == 'INCLUSÃO MANUAL') {
          this.manual = !this.manual;
        } else {
          this.pesquisaApi = !this.pesquisaApi;
        }
      } else {//click e cancel em opção não ticada
        if (acao == 'INCLUSÃO MANUAL') {
          this.manual = false;
          this.pesquisaApi = true;
        } else {
          this.manual = true;
          this.pesquisaApi = false;
        }
      }
    }

  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
    this.filename = this.file.name;
  }

  modalImportParticipante() {
    this.modalUploadParticipante = true
  }

  importar() {
    const data: FormData = new FormData();
    data.append('file', this.file);

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'userid': this.sessionService.loggedUser.id.toString(),
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/upload-participantes', data, httpOptions)
      .subscribe(res => {
        if (res["error"]) {
          if (res['data']) {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0',
                'userid': this.sessionService.loggedUser.id.toString(),
              }),
              responseType: 'blob' as 'json'
            };
            this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/upload-participantes', httpOptions2)
              .subscribe(
                res => {
                  this.downloadFile(res, "log.txt");
                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o log: " + err.message });
                  console.log(err);
                  this.httpService.done();
                });
          }
        }
        else {
          this.empresaChanged()
        }
        this.exibirMensagem({ error: res["error"], mensagem: res['mensagem'] });
        this.httpService.done();
      },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API: " + err.message });
          console.log(err);
          this.httpService.done();
        });
  }

  exportarParticipantes() {
    const data = {
      empresaId: this.dblinkedSessionService.empresa.id,
      userId: this.sessionService.loggedUser.id,
    };

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'cadastro/exportar-participantes', data)
      .subscribe(res => {
        this.exibirMensagem({ mensagem: res['mensagem'], error: res['error'] });

        if (res['error']) return this.httpService.done();

        const httpOptions2 = {
          headers: new HttpHeaders({
            'Authorization': this.configService.bearerApi,
            'Cache-Control': 'no-store, max-age=0',
            'userid': this.sessionService.loggedUser.id.toString()
          }),

          responseType: 'blob' as 'json'
        };

        this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/exportar-participantes', httpOptions2)
          .subscribe(
            res => {
              this.downloadFile(res, `PARTICIPANTES ${this.dblinkedSessionService.empresa.nomeFantasia.toUpperCase()}.csv`);
              this.httpService.done();
            }, err => {
              this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o relatório: " + err.message });
              console.log(err);
              this.httpService.done();
            });
      }, err => {
        this.exibirMensagem({ error: true, mensagem: "Erro no API: " + err.message });
        console.log(err);
        this.httpService.done();
      });
  }

  downloadFile(url, filename) {
    let link = document.createElement('a');
    let urlObject = window.URL.createObjectURL(url);
    link.href = urlObject;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  buscarPc() {
    this.httpService.wait();
    this.httpService.get('/planocontas?$select=id,codCta,descrCta,indCta,codNaturezaConta/cod' +
      '&$filter=ativo eq true and empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' and indCta eq ' + '\'' + 'A' + '\'' + '&$orderby=codCta')
      .subscribe(ref => this.loadPlanoContas(ref.value),
        error => this.httpService.handleError(error, () => this.buscarPc()),
        () => this.httpService.done());
  }

  formataPorcentagemComissao() {
    if (this.participanteEmpresa.porcentagemComissao > 100) {
      this.participanteEmpresa.porcentagemComissao = 100;
    } else if (this.participanteEmpresa.porcentagemComissao < 0) {
      this.participanteEmpresa.porcentagemComissao = 0;
    } else {
      this.participanteEmpresa.porcentagemComissao = this.utilService.parseDecimal(this.participanteEmpresa.porcentagemComissao);
    }
  }

  changeToolbar() {
    const condicaoPaginaInicialUmSelecionado = this.empresaRealizaAlteracoes && !this.criar && !this.editar && this.participantesSelecionados.length === 1
    const condicaoPaginaInicialUmMaisSelecionado = this.empresaRealizaAlteracoes && !this.criar && !this.editar && this.participantesSelecionados.length >= 1
    const condicaoTelaEdicaoCriacao = this.empresaRealizaAlteracoes && (this.editar || this.criar)
    const condicaoTelaInicial = !this.editar && !this.criar
    const condicaoTelaInicialRestrita = this.empresaRealizaAlteracoes && !this.editar && !this.criar
    const condicaoTelaInicialUmMaisSelecionadoInativo = this.empresaRealizaAlteracoes && !this.editar && !this.criar && this.participantesSelecionados.length >= 1 && this.verificarSeExistemInativosSelecionados()


    this.toolbarMain.setVisible('button-back', condicaoTelaEdicaoCriacao)
    this.toolbarMain.setVisible('button-edit', condicaoPaginaInicialUmSelecionado)
    this.toolbarMain.setVisible('button-reactivate', condicaoTelaInicialUmMaisSelecionadoInativo)
    this.toolbarMain.setVisible('button-delete', condicaoPaginaInicialUmMaisSelecionado)
    this.toolbarMain.setVisible('button-export', condicaoTelaInicial)
    this.toolbarMain.setVisible('button-import', condicaoTelaInicial)
    this.toolbarMain.setVisible('button-create', condicaoTelaInicialRestrita)
    this.toolbarMain.setVisible('button-clone', condicaoPaginaInicialUmMaisSelecionado)
  }

  verificarSeExistemInativosSelecionados() {
    for (let participante of this.participantesSelecionados) {
      if (participante['ativo'] === false) {
        return true
      }
    }

    return false
  }

  async carregarDadosGrupoEmpresa() {
    if (this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.hasEmpresaSelected) {
      await this.verificarSeEmpresaPodeRealizarModificacoes()
      if (this.empresaRealizaAlteracoes) await this.getHabilitarCadastoGrupoEmpresas()
      this.changeToolbar()
    }
  }


  async verificarSeEmpresaPodeRealizarModificacoes() {
    const companyId = this.dblinkedSessionService.empresa.id
    this.empresaRealizaAlteracoes = false

    try {
      await this.httpService.get(`/custom/cadastro/verifica-grupo-empresa-permite-alteracao(${companyId})`).toPromise()
        .then(res => this.empresaRealizaAlteracoes = res.permissoes.permiteAlteracaoParticipante)
        .catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }


  async getHabilitarCadastoGrupoEmpresas() {
    const empresaId = this.dblinkedSessionService.empresa.id

    this.httpService.wait()
    this.httpService.get(`/empresa?$select=cadastroGrupoEmpresa&$filter=id eq ${empresaId}`).subscribe(
      res => {
        this.cadastroGrupoEmpresas = res.value[0].cadastroGrupoEmpresa ? true : false

        if (this.cadastroGrupoEmpresas) this.buscarEmpresasGrupo()
      },
      error => this.httpService.handleError(error, () => this.getHabilitarCadastoGrupoEmpresas()),
      () => this.httpService.done()
    )
  }


  buscarEmpresasGrupo() {
    const empresaId = this.dblinkedSessionService.empresa.id

    this.httpService.wait()
    this.httpService.get(`/empresagrupocadastrorelac?$select=id,empresaRelac/nomeFantasia&$filter=empresa/id eq ` + empresaId).subscribe(
      res => {
        this.empresasGrupo = []
        this.empresasGrupoSelecionadas = []

        res.value.forEach(company => {
          this.empresasGrupo.push({
            label: company.empresaRelac.nomeFantasia,
            value: company.empresaRelac.id
          })

          this.empresasGrupoSelecionadas.push(company.empresaRelac.id)
        })
      },
      error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro ao buscar as empresas do grupo' }),
      () => {
        this.configurarPlaceHolderSelecaoEmpresas()
        this.httpService.done()
      }
    )
  }

  configurarPlaceHolderSelecaoEmpresas() {
    this.placeHolderEmpresasSelecionadas = null
    if (this.empresasGrupoSelecionadas.length > 0) {
      let nomesEmpresas = []

      this.empresasGrupo.forEach(company => {
        if (this.empresasGrupoSelecionadas.indexOf(company.value) !== -1) nomesEmpresas.push(company.label)
      })

      if (nomesEmpresas.length > 0) this.placeHolderEmpresasSelecionadas = nomesEmpresas.join(', ')
    }
  }

  excluirParticipanteGrupoEmpresa() {
    if (!confirm('Deseja realmente excluir este participante? Essa ação irá afetar todas as empresas do grupo')) return;
    this.selecionarEmpresaCriadoraGrupoEmpresas()

    let listaExcluir = [];
    for (let participante of this.participantesSelecionados)
      listaExcluir.push({ 'id': participante.participanteId, 'codigo': participante.codigo });

    const listaEnviar = {
      empresa: this.dblinkedSessionService.empresa.id,
      authUser: this.sessionService.loggedUser.id,
      participantesSelecionados: listaExcluir,
      empresasGrupo: this.empresasGrupoSelecionadas
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/excluir-participante-grupo-empresa', listaEnviar)
      .subscribe(
        result => {
          listaExcluir.forEach(row => {
            this.LogManagement.registerLog(
              LogModule.REGISTRATION,
              LogScreen.PARTICIPANTS,
              '/custom/cadastro/excluir-participante-grupo-empresa',
              LogAction.DELETE,
              row.id,
              row.codigo,
              "O participante " + row.codigo + " foi excluído",
              listaEnviar)
          })

          this.resultadoEdicao(result)

        },
        error => this.httpService.handleError(error, () => this.excluirParticipante()),
        () => this.httpService.done()
      )
  }

  clonarParticipanteGrupoEmpresa() {
    let listaExcluir = []
    for (let participante of this.participantesSelecionados)
      listaExcluir.push({ 'id': participante.participanteId, 'codigo': participante.codigo })

    const participanteId = this.participantesSelecionados[0].participanteId
    const participanteCodigo = this.participantesSelecionados[0].codigo

    let empresasParticipantesClonagem = this.empresasGrupoSelecionadas

    const posicaoEmpresaRequisitante = empresasParticipantesClonagem.indexOf(this.dblinkedSessionService.empresa.id)
    if (posicaoEmpresaRequisitante !== -1) empresasParticipantesClonagem.splice(posicaoEmpresaRequisitante, 1)

    const participantesSelecionadosIds = []
    const participantesSelecionadosCodigos = []

    for (let participante of this.participantesSelecionados) {
      participantesSelecionadosIds.push(participante.participanteId)
      participantesSelecionadosCodigos.push(participante.codigo)
    }

    const listaEnviar = {
      empresaCriadoraId: this.dblinkedSessionService.empresa.id,
      usuarioId: this.sessionService.loggedUser.id,
      empresasGrupo: empresasParticipantesClonagem,
      participantesOriginaisIds: participantesSelecionadosIds,
      codigosOriginaisParticipantes: participantesSelecionadosCodigos
    }

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/clonar-participante-grupo-empresa', listaEnviar).subscribe(
      result => this.resultadoEdicao(result),
      error => this.httpService.handleError(error, () => this.clonarParticipanteGrupoEmpresa()),
      () => this.httpService.done()
    )
  }

  selecionarEmpresaCriadoraGrupoEmpresas() {
    const empresaId = this.dblinkedSessionService.empresa.id
    const posicaoEmpresaIdGrupoEmpresas = this.empresasGrupoSelecionadas.indexOf(empresaId)
    if (posicaoEmpresaIdGrupoEmpresas === -1) this.empresasGrupoSelecionadas.unshift(empresaId)
  }

  selecionarTodasEmpresasGrupo() {
    this.empresasGrupoSelecionadas = new Array()

    this.selecionarEmpresaCriadoraGrupoEmpresas()

    this.empresasGrupo.forEach(empresa => {
      this.empresasGrupoSelecionadas.push(empresa.value)
    })
  }

  determinarMetodoSalvamentoParticipanteGrupoEmpresa(numeroIdentificador: Number) {
    let funcao: Function;
    let dadosValidos: boolean = false;
    console.log(numeroIdentificador)

    switch (numeroIdentificador) {
      case 1:
        dadosValidos = this.validarSalvarParticipante()
        funcao = this.salvarParticipante

        if (dadosValidos && !this.cadastroGrupoEmpresas) this.salvarParticipante()

        break

      case 2:
        dadosValidos = this.validarSalvarParticipante()
        funcao = this.salvarCpfParticipante

        if (dadosValidos && !this.cadastroGrupoEmpresas) this.salvarCpfParticipante()

        break

      case 3:
        dadosValidos = this.validarParticipanteManual()
        funcao = this.salvarParticipanteManual

        if (dadosValidos && !this.cadastroGrupoEmpresas) this.salvarParticipanteManual()

        break

      case 4:
        dadosValidos = this.validarParticipanteManualExterior()
        funcao = this.salvarParticipanteManualExterior

        console.log(dadosValidos)
        console.log(this.cadastroGrupoEmpresas)
        if (dadosValidos && !this.cadastroGrupoEmpresas) this.salvarParticipanteManualExterior()

        break

      case 5:
        dadosValidos = this.validarSalvarParticipante()
        funcao = this.salvarAlteracaoParticipante

        if (dadosValidos && !this.cadastroGrupoEmpresas) this.salvarAlteracaoParticipante()

        break
    }

    if (dadosValidos && this.cadastroGrupoEmpresas) this.popularModalGrupoEmpresa(funcao)
  }

  popularModalGrupoEmpresa(funcaoModal: Function, clonar: boolean = false) {

    this.selecionarTodasEmpresasGrupo()

    this.modalGrupoEmpresas = true

    if (clonar) {
      this.tituloModalGrupoEmpresas = 'Clonagem de participantes em várias empresas'
      this.descricaoModalGrupoEmpresas = 'Em quais outras empresas você deseja clonar este participante?'
    } else {
      this.tituloModalGrupoEmpresas = this.criar ? 'Criação de participantes em várias empresas' : 'Edição de participantes em várias empresas'
      this.descricaoModalGrupoEmpresas = this.criar ? 'Em quais outras empresas você deseja criar este participante?' : 'Em quais outras empresas você deseja editar este participante?'
    }

    this.funcaoModalGrupoEmpresas = funcaoModal
  }

  ativaModalDadosPix() {
    this.chavePixModal = new ParticipanteChavePix
    this.modalDadosPix = true
  }

  dadosPixEditar(chavePix) {
    this.chavePixModal = new ParticipanteChavePix
    this.chavePixModal = chavePix
    this.modalDadosPix = true
  }

  dadosPixExcluir(chavePix) {
    if (confirm('Deseja realmente excluir esta chave pix?')) {
      const chave = this.chavesPix.findIndex(item => item === chavePix);
      this.chavesPix.splice(chave, 1);
    }
  }

  validaDadosPix() {
    this.chavePixModal.descricaoChavePix = this.atribuiDescricaoTipoPixModal(this.chavePixModal.tipoChavePix)
    if (this.chavePixModal.tipoChavePix === undefined || this.chavePixModal.tipoChavePix === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG:', detail: 'Informe o tipo da chave pix!' })
      return
    }
    if (this.chavePixModal.chavePix === undefined || this.chavePixModal.chavePix === '' || this.chavePixModal.chavePix === null) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG:', detail: 'Informe a chave pix!' })
      return
    }
    if (this.chavePixModal.tipoChavePix === 1) {
      if (this.replaceStringNumerica(this.chavePixModal.chavePix).length !== 11) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG:', detail: 'O CPF informado está inválido!' })
        return
      }
    }
    if (this.chavePixModal.tipoChavePix === 2) {
      if (this.replaceStringNumerica(this.chavePixModal.chavePix).length !== 14) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG:', detail: 'O CNPJ informado está inválido!' })
        return
      }
    }
    if (this.chavePixModal.tipoChavePix === 3) {
      if (!this.validarEmail(this.chavePixModal.chavePix)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG:', detail: 'O email informado está inválido!' })
        return
      }
    }
    if (this.chavePixModal.tipoChavePix === 4) {
      if (this.replaceStringNumerica(this.chavePixModal.chavePix).length < 10 || this.replaceStringNumerica(this.chavePixModal.chavePix).length > 12) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'MSG:', detail: 'O telefone informado está inválido!' })
        return
      }
    }

    this.chavesPix.push(this.chavePixModal)
    this.modalDadosPix = false
  }

  atribuiDescricaoTipoPixModal(tipoChavePix): string {
    const descricao = this.opcoesTiposPix.find(tipoPix => tipoPix.value === tipoChavePix)
    return descricao.label
  }

  cancelaModalDadosPix() {
    this.chavePixModal = null
    this.modalDadosPix = false
  }

  replaceStringNumerica(string) {
    string = string.replace(/[^\d]+/g, '')
    return string
  }

  validarEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

}
