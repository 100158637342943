import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { MessageService, SelectItem } from 'primeng/api';
import { ConfigPattern } from '../../interfaces/conciliadora';
import { resetConfigPattern } from '../../utils/reset-conciliadora-form';

@Component({
  selector: 'app-cfg-conciliadora',
  templateUrl: './cfg-conciliadora.component.html',
  styleUrls: ['./cfg-conciliadora.component.scss']
})
export class CfgConciliadoraComponent implements OnInit, OnChanges {

  @Input() isRender: boolean
  @Input() isSave: boolean
  @Input() isConfigExists: boolean
  @Output() isValid = new EventEmitter()

  httpOptions: Object = new Object()

  optCompanyCredAccounts: SelectItem[] = new Array()

  _isConfigExists: boolean = false
  loadingConfigPattern: boolean = false
  loadingOptCompanyCredAccounts: boolean = false
  loadingIconOptCompanyCredAccounts: boolean = false

  empresaId: number = null
  userId: number = null

  config: ConfigPattern = resetConfigPattern()

  constructor(
    private httpService: HttpService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  public ngOnInit(): void {
    this.empresaId = this.dblinkedSessionService.empresa.id
    this.userId = this.sessionService.loggedUser.id

    this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    this.getOptCompanyCredAccounts()
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.isSave && changes.isSave.currentValue) this.saveConfigPattern()
    if(changes.isConfigExists && changes.isConfigExists.currentValue) this._isConfigExists = true
    if(changes.isRender && changes.isRender.currentValue) if(this._isConfigExists) this.initialize()
  }

  private async initialize(): Promise<void> {
    this.getConfigPattern()
  }

  private async getConfigPattern(): Promise<void> {
    this.loadingConfigPattern = true

    try {
      await this.httpClient.get(`${this.configService.octaremoteUrl}/cadastro/configuracao-api?empresaId=${this.empresaId}&codigo=CON`, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) {
          this.config.api_key = res['data'][0].chave_api
          this.config.empresa_conciliadora_id = res['data'][0].usuario
          this.config.senha = res['data'][0].senha
          this.config.conta_empresa_cred_id = res['data'][0].conta_bancaria_id
        } else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar os dados padrões da sua configuração! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingConfigPattern = false
      this.validationConfig()
    }
  }

  private async saveConfigPattern(): Promise<void> {

    const payload = {
      empresa_id: this.empresaId,
      user_id: this.userId,
      codigo: 'CON',
      chave_api: this.config.api_key,
      usuario: this.config.empresa_conciliadora_id,
      senha: this.config.senha,
      empresa_conta_id: this.config.conta_empresa_cred_id
    }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/configuracao-api', payload, this.httpOptions).toPromise().then(res => {
        if(res['codigo'] === 200) this.saveFamilyRelationship()
        else this.showResponse('error', res['message'])
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao salvar os dados padrões da sua configuração! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async saveFamilyRelationship(): Promise<void> {
    const httpOptions: Object = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
    const payload: Object = { empresa_id: this.dblinkedSessionService.empresa.id, familia_id: 3 }

    try {
      await this.httpClient.post(this.configService.octaremoteUrl + '/cadastro/empresa-familia', payload, httpOptions).toPromise().catch(err =>
        this.showResponse('error', err.status + ' - Houve um erro ao salvar a família de API Conciliadora!')
      )
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getOptCompanyCredAccounts(): Promise<void> {
    this.loadingOptCompanyCredAccounts = true
    this.loadingIconOptCompanyCredAccounts = true

    try {
      await this.httpService.get('/empresaconta?$select=id,nome,tipoConta/id,agencia,dvAgencia,conta,dvConta,tipoConta/descricao&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + ' and tipoConta/id eq 2 and ativo=true&$orderby=nome').toPromise().then(res => {
        this.optCompanyCredAccounts = [{ label: 'Nenhuma', value: null }]
        res.value.forEach(item => {
          if(item.tipoConta.id !== 10) {
            let label: string = item.id + " - " + item.nome
            if (item.agencia) label += " - Ag: " + item.agencia
            if (item.dvAgencia) label += "-" + item.dvAgencia
            if (item.conta) label += " - CC: " + item.conta
            if (item.dvConta) label += "-" + item.dvConta
            this.optCompanyCredAccounts.push({ label: label, value: item.id })
          }
        })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as contas da empresa! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptCompanyCredAccounts = false
      this.loadingIconOptCompanyCredAccounts = false
    }
  }

  public validationConfig(): void {
    let message: string = null
    let erro = false

    if(this.config.api_key === null || this.config.api_key.length === 0) {
      erro = true
    }
    if(!this.config.empresa_conciliadora_id) {
      erro = true
    }
    if(this.config.senha === null || this.config.senha.length === 0) {
      erro = true
    }
    if(!this.config.conta_empresa_cred_id) {
      erro = true
    }

    this.isValid.emit({ validatedSteps: (!erro) })
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso: ', detail: message })
      break
    }
  }

}
