import { Empresa } from "app/cadastro/model/empresa.model";
import { FaturaItem } from "./fatura-item.model";
import { PlanoContas } from "app/contabil/model/plano-contas.model";
import { AuthUser } from "app/proof/model/auth-user.model";
import { Departamento } from "app/util/model/departamento.model";
import { FaturaAprovador } from 'app/financeiro/fatura/fatura-aprovador.model';

export class Fatura {
  id: number;
  empresa: Empresa;
  planoContas: PlanoContas;
  statusFatura : string;
  changeUser : AuthUser;
  numero  : String;
  data_vencimento : Date;
  data_fechamento : Date;
  taxa : number;
  usuarioAprovador : AuthUser;
  departamento     : Departamento;



  faturaItem: Array<FaturaItem>;
  faturaItemComplemento: Array<FaturaItem>;

  constructor(fatura?: Fatura | number) {
    if (fatura !== undefined && fatura !== null) {
      if (typeof fatura === 'number') {
        this.id = fatura;
      } else {
        this.id = fatura.id;
        this.empresa = fatura.empresa;
        this.planoContas =  fatura.planoContas;
        this.statusFatura = fatura.statusFatura;
        this.changeUser = fatura.changeUser;
        this.numero  = fatura.numero;
        this.data_vencimento = fatura.data_vencimento;
        this.data_fechamento = fatura.data_fechamento;
        this.taxa = fatura.taxa;

        this.faturaItem = fatura.faturaItem;
        this.faturaItemComplemento = fatura.faturaItemComplemento;
        this.usuarioAprovador      = fatura.usuarioAprovador
        this.departamento          = fatura.departamento;



      }
    }
  }

}