import { Empresa } from 'app/cadastro/model/empresa.model';
import { NfeTaxRet } from 'app/financeiro/model/nfe-tax-ret.model';
import { NfeTaxIssqn } from 'app/financeiro/model/nfe-tax-issqn.model';
import { NfeTaxIcms } from 'app/financeiro/model/nfe-tax-icms.model';
import { NfeItem } from 'app/financeiro/model/nfe-item.model';
import { NfeStatus } from 'app/financeiro/model/nfe-status.model';
import { NfeDestiny } from 'app/financeiro/model/nfe-destiny.model';
import { Operacao } from 'app/util/model/operacao.model';
import { Uf } from 'app/util/model/uf.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Pais } from 'app/util/model/pais.model';
import { ParticipanteTipo } from 'app/cadastro/model/participante-tipo.model';
import { Endereco } from 'app/cadastro/model/endereco.model';
import { ParticipanteEmpresa } from 'app/cadastro/model/participante-empresa.model';
import { ItemParticipante } from 'app/cadastro/model/item-participante.model';
import { ParticipanteEndereco } from 'app/cadastro/model/participante-endereco.model';
import { RegimeTributario } from 'app/util/model/regime-tributario.model';
import { Participante } from '../../cadastro/model/participante.model';

export class Nfe {
  id: number;
  empresa: Empresa;
  version: number;
  key: string;
  nsu: number;
  status: NfeStatus;
  operation: Operacao;
  destiny: NfeDestiny;
  shortXml: string;
  xml: string;
  hasMail: boolean;
  receiverCompany: Participante;
  endereco: Endereco;
  issuerCompany: Participante;
  document: string;
  series: string;
  model: string;
  value: Number;
  emission: Date;
  movement: Date;
  process: Date;
  additionalInfoF: string;
  additionalInfoC: string;
  taxRet: NfeTaxRet;
  taxIssqn: NfeTaxIssqn;
  taxIcms: NfeTaxIcms;
  items: Array<NfeItem> = new Array();
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;
  regExpDesconsiderar: boolean;

  constructor(nfe?: Nfe | number) {
    if (nfe !== undefined && nfe !== null) {
      if (typeof nfe === 'number') {
        this.id = nfe;
      } else {
        this.id = nfe.id;
        this.empresa = nfe.empresa;
        this.version = nfe.version;
        this.key = nfe.key;
        this.nsu = nfe.nsu;
        this.status = nfe.status;
        this.operation = nfe.operation;
        this.destiny = nfe.destiny;
        this.shortXml = nfe.shortXml;
        this.xml = nfe.xml;
        this.hasMail = nfe.hasMail;
        this.receiverCompany = nfe.receiverCompany;
        this.endereco = nfe.endereco;
        this.issuerCompany = nfe.issuerCompany;
        this.document = nfe.document;
        this.series = nfe.series;
        this.model = nfe.model;
        this.value = nfe.value;
        this.emission = nfe.emission;
        this.movement = nfe.movement;
        this.process = nfe.process;
        this.additionalInfoF = nfe.additionalInfoF;
        this.additionalInfoC = nfe.additionalInfoC;
        this.taxRet = nfe.taxRet;
        this.taxIssqn = nfe.taxIssqn;
        this.taxIcms = nfe.taxIcms;
        this.items = nfe.items;
        this.createTimestamp = nfe.createTimestamp;
        this.changeUser = nfe.changeUser;
        this.changeTimestamp = nfe.changeTimestamp;
        this.regExpDesconsiderar = nfe.regExpDesconsiderar;
      }
    }
  }
}
