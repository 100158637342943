export class TbCsllM350 {
  id: number;
  cod:  string;
  descricao:  string;
  dtIni:  string;
  dtFin:  string;
  ordem:  string;
  tipo:  string;
  formato:  string;
  linhaEcf:  string;
  formula:  string;
  tipoLanc:  string;
  used: boolean;

  constructor(tbCsllM350?: TbCsllM350 | number) {
    if (tbCsllM350 !== undefined && tbCsllM350 !== null) {
      if (typeof tbCsllM350 === 'number') {
        this.id = tbCsllM350;
      } else {
        this.id = tbCsllM350.id;
        this.cod = tbCsllM350.cod;
        this.descricao = tbCsllM350.descricao;
        this.dtIni = tbCsllM350.dtIni;
        this.dtFin = tbCsllM350.dtFin;
        this.ordem = tbCsllM350.ordem;
        this.tipo = tbCsllM350.tipo;
        this.formato = tbCsllM350.formato;
        this.linhaEcf = tbCsllM350.linhaEcf;
        this.formula = tbCsllM350.formula;
        this.tipoLanc = tbCsllM350.tipoLanc;
        this.used = tbCsllM350.used;
      }
    }
  }
}
