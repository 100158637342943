import { Requisicao } from './requisicao.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Padis } from './padis.model';
import { LeiDoBem } from './lei-do-bem.model';
import { TipoComparacao } from './tipo-comparacao.model';
import { PrioridadeCriterio } from './prioridade-criterio.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { RequisicaoItemRateio } from '../model/requisicao-item-rateio.model';
import { ItemTipo } from 'app/util/model/item-tipo.model';
import { Item } from 'app/cadastro/model/item.model';
import { Tipi } from 'app/util/model/tipi.model';
import { RequisicaoItemProjeto } from 'app/rms/model/requisicao-item-projeto.model';
import { BeneficioFiscal } from './beneficio-fiscal.model';
import { RequisicaoItemBeneficio } from './requisicao-item-beneficio.model';
import { OperacaoRegra } from 'app/cadastro/model/operacao-regra.model';

export class RequisicaoItem {
  id: number;
  line_id: string;
  requisicao: Requisicao;
  item: string;
  descricao: string;
  descricaoBr: string;
  descricaoEn: string;
  partNumber: string;
  tipi: Tipi;
  tipiCap: Tipi;
  fabricante: string;
  quantidade: number;
  valorUnit: number;
  valorTotal: number;
  valorTotalBrl: number;
  semiCondutor: boolean;
  mostradorInformacao: boolean;
  na: boolean;
  padis: Padis;
  leiDoBem: LeiDoBem;
  prioridadeCriterio: PrioridadeCriterio;
  codigo: number;
  requisicaoItemRateios: Array<RequisicaoItemRateio> = new Array();
  itemTipo: ItemTipo;
  itemCadastrado: boolean;
  itemInt: Item;
  //requisicaoItemProjetos : Array<ProjetoDbl> = new Array();
  requisicaoItemProjetos: Array<RequisicaoItemProjeto> = new Array();
  requisicaoBeneficios: Array<RequisicaoItemBeneficio> = new Array();
  link: string;
  operacaoId: number = null
  operacaoRegra: OperacaoRegra

  constructor(requisicaoItem?: RequisicaoItem | number) {
    if (requisicaoItem !== undefined && requisicaoItem !== null) {
      if (typeof requisicaoItem === 'number') {
        this.id = requisicaoItem;
      } else {
        this.id = requisicaoItem.id;
        this.line_id = requisicaoItem.line_id;
        this.requisicao = requisicaoItem.requisicao;
        this.item = requisicaoItem.item;
        this.descricao = requisicaoItem.descricao;
        this.descricaoBr = requisicaoItem.descricaoBr;
        this.descricaoEn = requisicaoItem.descricaoEn;
        this.partNumber = requisicaoItem.partNumber;
        this.tipi = requisicaoItem.tipi;
        this.fabricante = requisicaoItem.fabricante;
        this.quantidade = requisicaoItem.quantidade;
        this.valorUnit = requisicaoItem.valorUnit;
        this.valorTotal = requisicaoItem.valorTotal;
        this.valorTotalBrl = requisicaoItem.valorTotalBrl;
        this.semiCondutor = requisicaoItem.semiCondutor;
        this.mostradorInformacao = requisicaoItem.mostradorInformacao;
        this.na = requisicaoItem.na;
        this.padis = requisicaoItem.padis;
        this.leiDoBem = requisicaoItem.leiDoBem;
        this.prioridadeCriterio = requisicaoItem.prioridadeCriterio;
        this.codigo = requisicaoItem.codigo;
        this.itemTipo = requisicaoItem.itemTipo;
        this.itemCadastrado = requisicaoItem.itemCadastrado;
        this.itemInt = requisicaoItem.itemInt;
        this.link = requisicaoItem.link;
        this.operacaoId = requisicaoItem.operacaoId
        this.operacaoRegra = requisicaoItem.operacaoRegra
        this.tipiCap = requisicaoItem.tipiCap
      }
    }
  }
}
