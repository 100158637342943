import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ViewProjetoDblRelac } from 'app/cadastro/model/view-projeto-dbl-relac.model';

@Component({
  selector: 'app-view-projeto-dbl-relac',
  templateUrl: './view-projeto-dbl-relac.component.html',
  styleUrls: ['./view-projeto-dbl-relac.component.scss'],
  providers: [CrudService]
})
export class ViewProjetoDblRelacComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public viewprojetodblrelac: ViewProjetoDblRelac = new ViewProjetoDblRelac();

  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;

  entityFilter: string = null;

  constructor(private crudService: CrudService,
              private httpService: HttpService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  ngOnInit() {
    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
                                                                         () => { this.empresaChanged(); });

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.columns = [
        new DataTableColumn('VIEW', 'view')
        .setTypeSelect()
          .setOptions({ url: '/view',
                        value: 'id',
                        label: ['nome'] }),
        new DataTableColumn('Projeto DBL', 'projetoDbl')
           .setTypeSelect()
          .setOptions({ url: '/projetodbl', value: 'id', label: 'descricao' })

      ];


      this.controls = [
        new DataControl('View', 'view')
        .setTypeSelect()
        .setOptions({ url: '/view',
                      value: 'id',
                      label: ['nome'] }),
        new DataControl('Projeto DBL', 'projetoDbl')
          .setTypeSelect()
          .setOptions({ url: '/projetodbl', value: 'id', label: ['cod', ' - ', 'descricao'] })
      ];
    }
  }
}
