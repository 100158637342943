import { ItemTipo } from 'app/util/model/item-tipo.model';
import { ItemGenero } from 'app/util/model/item-genero.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { UnidadeMedida } from 'app/util/model/unidade-medida.model';
import { CodGenItem } from 'app/util/model/cod-gen-item.model';
import { CestNcmSegmento } from 'app/util/model/cest-ncm-segmento.model';
import { Nbs } from 'app/util/model/nbs.model';
import { Tipi } from 'app/util/model/tipi.model';
import { Lst } from 'app/util/model/lst.model';
import { Operacao } from 'app/util/model/operacao.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { EmpresaCnae } from './empresa-cnae.model';
import { RequisicaoItem } from 'app/rms/model/requisicao-item.model';
import { GrupoEstoque } from 'app/estoque/model/grupo-estoque.model';
import { TipoUtilizacao } from 'app/util/model/tipo-utilizacao.model';
import { CodServicoTelecomunicacao } from 'app/util/model/cod-servico-telecomunicacao.model';


export class Item {
  id: number;
  operacao: Operacao;
  codigo: string;
  descricao: string;
  informacoesAdicionais: string;
  unidadeMedida: UnidadeMedida;
  itemTipo: ItemTipo;
  tipi: Tipi;
  itemGenero: ItemGenero;
  aliquotaIcms: number;
  cestNcmSegmento: CestNcmSegmento;
  nbs: Nbs;
  lst: Lst;
  ean13: string;
  dun14: string;
  qtdeDun14: number;
  dtInicial: Date;
  dtFinal: Date;
  empresa: Empresa;
  itemAnterior: Item;
  verificaEstoque: boolean;
  valorVenda: number;
  valorCusto: number;
  planoContas: PlanoContas;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;
  itemFornecedor: string;
  descricaoEn: string;
  descricaoCompleta: string;
  empresaCnae: EmpresaCnae;
  requisicaoItem: RequisicaoItem;
  grupoProdutoEyeMobile: string

  porcentagemLimiteDesconto: number;
  preCadastro: string;

  ncmValid: boolean;
  ncmDtValid: Date;
  ncmUserValid: AuthUser;

  grupoEstoque: GrupoEstoque;
  estoqueMin: number;
  estoqueMax: number;
  itemBom: boolean;
  olhaEstoque: string;

  nve: string;
  pesoL: number;
  pesoB: number;
  cadastrado: boolean

  tipoUtilizacao: TipoUtilizacao;
  codServicoTelecomunicacao: CodServicoTelecomunicacao;

  unidade: string;
  ncm: string;
  ativo: boolean

  grupoEmpresaItemOrigem: Item

  constructor(item?: Item | number) {
    if (item !== undefined && item !== null) {
      if (typeof item === 'number') {
        this.id = item;
      } else {
        this.id = item.id;
        this.operacao = item.operacao;
        this.codigo = item.codigo;
        this.descricao = item.descricao;
        this.descricaoCompleta = item.descricaoCompleta;
        this.informacoesAdicionais = item.informacoesAdicionais;
        this.unidadeMedida = item.unidadeMedida;
        this.itemTipo = item.itemTipo;
        this.tipi = item.tipi;
        this.itemGenero = item.itemGenero;
        this.aliquotaIcms = item.aliquotaIcms;
        this.cestNcmSegmento = item.cestNcmSegmento;
        this.nbs = item.nbs;
        this.lst = item.lst;
        this.ean13 = item.ean13;
        this.dun14 = item.dun14;
        this.dtInicial = item.dtInicial;
        this.dtFinal = item.dtFinal;
        this.empresa = item.empresa;
        this.itemAnterior = item.itemAnterior;
        this.verificaEstoque = item.verificaEstoque;
        this.valorVenda = item.valorVenda;
        this.valorCusto = item.valorCusto;
        this.planoContas = item.planoContas;
        this.createTimestamp = item.createTimestamp;
        this.changeUser = item.changeUser;
        this.changeTimestamp = item.changeTimestamp;
        this.porcentagemLimiteDesconto = item.porcentagemLimiteDesconto;
        this.itemFornecedor = item.itemFornecedor;
        this.descricaoEn = item.descricaoEn;
        this.empresaCnae = item.empresaCnae;
        this.requisicaoItem = item.requisicaoItem;
        this.preCadastro = item.preCadastro;
        this.ncmValid = item.ncmValid;
        this.ncmDtValid = item.ncmDtValid;
        this.ncmUserValid = item.ncmUserValid;
        this.grupoEstoque = item.grupoEstoque;
        this.estoqueMin = item.estoqueMin;
        this.estoqueMax = item.estoqueMax;
        this.itemBom = item.itemBom;
        this.olhaEstoque = item.olhaEstoque;
        this.nve = item.nve;
        this.pesoL = item.pesoL;
        this.pesoB = item.pesoB;
        this.cadastrado = item.cadastrado;
        this.codServicoTelecomunicacao = item.codServicoTelecomunicacao;
        this.ativo = item.ativo
        this.grupoProdutoEyeMobile = item.grupoProdutoEyeMobile
        this.grupoEmpresaItemOrigem = item.grupoEmpresaItemOrigem
      }
    }
  }
}