import { TodoCard } from './todo-card.model';
import { TodoEtiquetas } from './todo-etiquetas.model';

export class TodoEtiquetasCardRelac {
  id: number;
  todoEtiquetas: TodoEtiquetas;
  todoCard: TodoCard;

  constructor(todoEtiquetasCardRelac?: TodoEtiquetasCardRelac | number) {
    if (
      todoEtiquetasCardRelac !== undefined &&
      todoEtiquetasCardRelac !== null
    ) {
      if (typeof todoEtiquetasCardRelac === 'number') {
        this.id = todoEtiquetasCardRelac;
      } else {
        this.id = todoEtiquetasCardRelac.id;
        this.todoEtiquetas = todoEtiquetasCardRelac.todoEtiquetas;
        this.todoCard = todoEtiquetasCardRelac.todoCard;
      }
    }
  }
}
