import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { isNullOrUndefined } from 'util';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Message, MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpService } from 'app/shared/services/http.service';
import { Table } from 'primeng/table';
import { Toolbar } from 'app/shared/model/toolbar.model';
declare var $: any;

@Component({
  selector: 'app-cambio',
  templateUrl: './cambio.component.html',
  styleUrls: ['./cambio.component.scss'
  ],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CambioComponent implements OnInit, OnDestroy {
  currentStateCambio = 'initial';
  changeStateCambio() {
    this.currentStateCambio = this.currentStateCambio === 'initial' ? 'final' : 'initial';
  }
  private sessionSubscription: Subscription;
  title: string;
  subtitle: string;
  cambioLista: Array<any> = new Array();
  dtInicial: Date = null;
  dtFinal: Date = null;
  DefFinalDate: Date = new Date();
  DefInitDate: Date = new Date(this.DefFinalDate.getTime() - 2592000000);
  colsCambio: any;
  listMoeda: Array<any> = new Array();
  idMoeda: number = 0;
  msgs: Message[] = null;
  listaExport: Array<any | any | any | any> = new Array();

  @ViewChild('dt2') dt2: Table;

  toolbarMainIni = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.dt2.exportCSV() },
    { icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
      this.ShowHideCambio()
      this.changeStateCambio()
    } },
  ]

  public toolbarMain = new Toolbar();

  constructor(
    private configService: ConfigService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private messageService: MessageService,
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.toolbarService.hidden = true;
    this.title = 'CÂMBIO';
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
          this.listarMoeda();
          this.getCambio();
        }

      });

    this.colsCambio = [
      { field: 'dt_cotacao', header: 'DATA COTAÇÃO' },
      { field: 'codigo', header: 'CÓDIGO DA MOEDA' },
      { field: 'cotacao_compra_dia', header: 'COTAÇÃO COMPRA' },
      { field: 'cotacao_venda_dia', header: 'COTAÇÃO VENDA' }
    ];

    this.toolbarMain.setAll(this.toolbarMainIni)
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  listarMoeda() {
    this.listMoeda = new Array();
    this.listMoeda.push({ label: "Todas", value: 0 });
    this.listMoeda.push({ label: "Euro", value: 1 });
    this.listMoeda.push({ label: "Dolar", value: 2 });
    this.listMoeda.push({ label: "Iene", value: 106 });
  }

  getCambio() {
    let dtinit: Date;
    let dtfin: Date;

    if (!this.dtInicial) {
      dtinit = this.DefInitDate;
      dtfin = this.DefFinalDate;

    } else {
      dtinit = this.dtInicial;
      dtfin = this.dtFinal;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'dtinicial': dtinit.getFullYear() + "-" + (dtinit.getMonth() + 1) + "-" + dtinit.getDate(),
        'dtfinal': dtfin.getFullYear() + "-" + (dtfin.getMonth() + 1) + "-" + dtfin.getDate(),
        'moeda': this.idMoeda.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/cambio', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {

            this.cambioLista = new Array();
            res["data"].forEach(element => { this.cambioLista.push(element) });
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.exibirMensagem({ error: true, mensagem: "Erro com o API: não foi possível carregar as taxas de câmbio." });
          this.httpService.done();
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return 20;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  /*Show Hide Câmbio*/
  isCardCambio: boolean = true;
  ShowHideCambio() {
    this.isCardCambio = !this.isCardCambio;

    this.toolbarMain.setIcon('btn2', this.isCardCambio ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn2', this.isCardCambio ? 'Ver menos' : 'Ver mais')
  }
}
