import { PlataformaPagamentos } from "./plataforma-pagamentos.model";


export class PlataformaPagamentosServicos {
  id        : number;
  descricao : string;
  sufixo    : string;
  plataformaPagamentos : PlataformaPagamentos;
  idDoBanco : number;
  ativo : boolean;
  temPaginacao : boolean;



  constructor(plataformaPagamentosServicos?: PlataformaPagamentosServicos | number) {
    if (plataformaPagamentosServicos !== undefined && plataformaPagamentosServicos !== null) {
      if (typeof plataformaPagamentosServicos === 'number') {
        this.id = plataformaPagamentosServicos;
      } else {
        this.id         = plataformaPagamentosServicos.id;
        this.descricao  = plataformaPagamentosServicos.descricao;
        this.sufixo     = plataformaPagamentosServicos.sufixo;
        this.plataformaPagamentos = plataformaPagamentosServicos.plataformaPagamentos;
        this.idDoBanco  = plataformaPagamentosServicos.idDoBanco;
        this.ativo      = plataformaPagamentosServicos.ativo;
        this.temPaginacao = plataformaPagamentosServicos.temPaginacao;


      }
    }
  }
}
