import { Component, OnInit, OnDestroy } from '@angular/core';

import { ConfigService } from '../../shared/services/config.service';
import { DblinkedSessionService } from '../../shared/services/dblinked-session.service';
import { HttpService } from '../../shared/services/http.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { SessionService } from '../../shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';

@Component({
  selector: 'app-registro-exportacao',
  templateUrl: './registro-exportacao.component.html',
  styleUrls: [
    './registro-exportacao.component.scss'
  ]
})
export class RegistroExportacaoComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {}

  private sessionSubscription: Subscription; // change session event;

  // Warning;
  msgs: Message[] = [];
  msgsModal: Message[] = [];

  // Data;
  defaultDate: Date;

  title: string;
  subtitle: string;

  lista: Array<any> = [];
  listaAnt: Array<any>;
  idsSelecionados: Array<any>;
  checkAllBtn: boolean;

  // modal:
  // --------------------------------------------
  modal: boolean;
  subChave: String;
  edita: boolean;

  tipDoc: any;
  listaTipDoc: SelectItem[];

  numDec: any;

  dtDec: Date;

  exp: any;
  listaExp: SelectItem[];

  numReg: any;

  dtReg: Date;

  numCon: any;

  dtCon: Date;

  dtAverb: Date;

  dtExpedicao: Date;

  tipCon: any;
  listaTipCon: SelectItem[];

  codPais: any;
  listaCodPais: SelectItem[];

  chvNfe: string;
  colslista : any[] = [];
  // --------------------------------------------

  modalPeriodo: boolean;
  dataIni: Date;
  dataFin: Date;
  dataIniSearch: any;
  dataFinSearch: any;

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    Promise.resolve(null).then(() => (this.toolbarService.hidden = false));

    this.edita = false;

    // Data;
    // ------------------------------------------
    this.defaultDate = new Date();
    // ------------------------------------------

    this.title = 'Registro de Exportação';
    this.subtitle = 'Gerencie os registros de exportação (Reg 1100 e Reg 1105 do SPED Fiscal)';

    this.lista = [];
    this.listaAnt = [];
    this.idsSelecionados = [];
    this.checkAllBtn = false;

    // dropdowns;
    // ------------------------------------------
    this.listaTipDoc = [];
    this.listaTipDoc.push(
      {
        label: 'Selecione',
        value: null
      },
      {
        label: 'Declaração de Exportação',
        value: 0
      },
      {
        label: 'Declaração Simplificada de Exportação',
        value: 1
      },
      {
        label: 'Declaração Única de Exportação',
        value: 2
      }
    );

    this.listaExp = [];
    this.listaExp.push(
      {
        label: 'Selecione',
        value: null
      },
      {
        label: 'Exportação Direta',
        value: 0
      },
      {
        label: 'Exportação Indireta',
        value: 1
      }
    );

    this.getTipConhecimento();

    this.getCodPais();
    // ------------------------------------------

    this.refreshModal();

    this.colslista = [
      { field: 'model', header: 'MODELO' },
      { field: 'series', header: 'SÉRIE' },
      { field: 'document', header: 'NUM DOC' },
      { field: 'key', header: 'CHAVE ELETRÔNICA' },
      { field: 'emission', header: 'DT EMISSÃO' },
      { field: 'codigo', header: 'CÓDIGO DO ITEM' },
      { field: 'status', header: 'STATUS' }
    ];


    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
      }
    );
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.toolbarService.clear();
  }

  cancelar() {
    this.refreshModal();
  }

  selectCheckbox(event: any){
    console.log('event');
    this.chvNfe = '';

    this.idsSelecionados.push(event.data.id);

    this.chvNfe = event.data.key;

    this.toolbarService.alterarStatus('edit', event.data.status === 'Pendente' ||  event.data.status === 'Desconsiderado',
     'Editar', () => this.editarAverbacao());
    this.toolbarService.replace('remove', false, 'class-remove-button', 'delete', 'Desconsiderar', () => this.desconsiderar() );

    this.getIdsSelecionados();
  }

  unselectCheckbox(event: any){
    console.log(event);
    this.chvNfe = '';

    const index = this.idsSelecionados.findIndex(c => c === event.data.id);
    this.idsSelecionados.splice(index, 1);

    this.chvNfe = event.data.key;

    this.toolbarService.alterarStatus('edit', event.data.status === 'Pendente' ||  event.data.status === 'Desconsiderado',
     'Editar', () => this.editarAverbacao());
    this.toolbarService.replace('remove', false, 'class-remove-button', 'delete', 'Desconsiderar', () => this.desconsiderar() );

    this.getIdsSelecionados();
  }

  editarAverbacao() {

    const listasEnviar = {
      cnpj: this.dblinkedSessionService.empresa.cnpj.toString(),
      chvNfe: this.chvNfe
    };

    this.modal = true;
    this.edita = true;

    this.httpService.wait();
    this.httpService.post('/custom/fiscal/get-registro-averb', listasEnviar)
    .subscribe(resultado => {

        this.subChave = this.chvNfe;
        this.tipDoc = resultado[0].indDoc;
        this.numDec = resultado[0].nroDe;
        this.dtDec = resultado[0].dataDe;
        this.exp = resultado[0].natExp;
        this.numReg = resultado[0].nroRe;
        this.dtReg = resultado[0].dtRe;
        this.dtCon = resultado[0].dtChc;
        this.dtAverb = resultado[0].dtAvb;
        this.tipCon = resultado[0].tpChc;
        this.codPais = resultado[0].pais;

    },
          error => this.httpService.handleError(error, () => this.editarAverbacao()),
          () => this.httpService.done());
  }

  desconsiderar() {

    const listasEnviar = {
      cnpj: this.dblinkedSessionService.empresa.cnpj.toString(),
      chvNfe: this.chvNfe
    };

    this.httpService.wait();
    this.httpService.post('/custom/fiscal/desconsiderar-registro-averb', listasEnviar)
    .subscribe(resultado => {

        this.changeSession();
    },
          error => this.httpService.handleError(error, () => this.editarAverbacao()),
          () => this.httpService.done());
  }

  salvarEdicao() {
    this.changeSession();
  }

  checkAll() {
    this.checkAllBtn = !this.checkAllBtn;

    this.idsSelecionados = [];

    if (this.checkAllBtn) {
      this.lista.forEach(rst => {
        rst.selected = true;
        this.idsSelecionados.push(rst.id);
      });
    } else {
      this.lista.forEach(rst => {
        rst.selected = false;
      });
    }

    this.getIdsSelecionados();
  }

  private changeSession() {
    if (
      this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected &&
      this.dblinkedSessionService.periodo.month
    ) {
      this.getNF(false);
    }
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  private getIdsSelecionados() {
    let chave: String = '';

    if (this.idsSelecionados.length === 0) {
      this.toolbarAdd(true);
    }
    if (this.idsSelecionados.length === 1) {
      this.toolbarAdd(false);

      const obj = this.lista.find(c => c.id === this.idsSelecionados[0]);
      chave = obj.key;
    }
    if (this.idsSelecionados.length > 1) {
      let boo = false;

      this.idsSelecionados.forEach(idSelecionado => {
        const obj = this.lista.find(c => c.id === idSelecionado);

        if (chave === '') {
          chave = obj.key;
        } else {
          if (obj.key !== chave) {
            boo = true;
          }
        }
      });

      this.toolbarAdd(boo ? true : false);
    }

    this.subChave = chave;
  }

  private getCodPais() {
    this.httpService.wait();
    this.httpService
      .get('pais?$select=id,nome,codigoBacen&$orderby=nome')
      .subscribe(
        rst => {
          this.loadCodPais(rst.value);
        },
        error => this.httpService.handleError(error, () => this.getCodPais()),
        () => this.httpService.done()
      );
  }

  private getModal(obj) {
    this.httpService.wait();
    this.httpService
      .post(
        '/custom/fiscal/get-reg-exp-modal(' +
          this.dblinkedSessionService.empresa.id +
          ',' +
          this.dblinkedSessionService.periodo.month.value +
          ',' +
          this.dblinkedSessionService.periodo.year.value +
          ')',
        obj
      )
      .subscribe(
        rst => {
          this.refreshModal();
          console.log('RST ----> ' + JSON.parse(JSON.stringify(rst)));
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'Sucesso',
            detail: 'Informações cadastradas.'
          });
        },
        error => this.httpService.handleError(error, () => this.getModal(obj)),
        () => this.httpService.done()
      );
  }

  public getNF(boolean: boolean) {

    this.toolbarService.replace('save', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());

   if(!boolean){


      const mes = this.dblinkedSessionService.periodo.month.value > 8 ?
      this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
      const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
      this.defaultDate = new Date(dt);

      let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
      // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
      lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês

      this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
      this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

      this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
      this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);

    this.httpService.wait();
    this.httpService
      .post(
        '/custom/fiscal/get-reg-exp-nf(' +
          this.dblinkedSessionService.empresa.cnpj.toString() +
          ',' +
          this.dblinkedSessionService.periodo.month.value +
          ',' +
          this.dblinkedSessionService.periodo.year.value +
          ',' +
          this.dataIniSearch.toString() +
          ',' +
          this.dataFinSearch.toString() +
          ')'
      )
      .subscribe(
        rst => {
          rst.forEach(element => {
            if (element.mes === 'anterior') {
              this.listaAnt.push(element);
            } else {
              this.lista.push(element);
              console.log(element);
            }
          });
          // this.lista = rst;
        },
        error => this.httpService.handleError(error, () => this.getNF(false)),
        () => this.httpService.done()
      );

    } else {


// adjust 0 before single digit date
let dateIni = ("0" + this.dataIni.getDate()).slice(-2);
let dateFin = ("0" + this.dataFin.getDate()).slice(-2);

// current month
let monthIni = ("0" + (this.dataIni.getMonth() + 1)).slice(-2);
let monthFin= ("0" + (this.dataFin.getMonth() + 1)).slice(-2);

// current year
let yearIni = this.dataIni.getFullYear();
let yearFin = this.dataFin.getFullYear();

this.dataIniSearch = yearIni + '-' + monthIni + '-' + dateIni;
this.dataFinSearch = yearFin + '-' + monthFin + '-' + dateFin;


      this.httpService.wait();
    this.httpService
      .post(
        '/custom/fiscal/get-reg-exp-nf(' +
          this.dblinkedSessionService.empresa.cnpj.toString() +
          ',' +
          this.dblinkedSessionService.periodo.month.value +
          ',' +
          this.dblinkedSessionService.periodo.year.value +
          ',' +
          this.dataIniSearch.toString() +
          ',' +
          this.dataFinSearch.toString() +
          ')'
      )
      .subscribe(
        rst => {
          rst.forEach(element => {
            if (element.mes === 'anterior') {
              this.listaAnt.push(element);
            } else {
              this.lista.push(element);
              console.log(element);
            }
          });
          // this.lista = rst;
        },
        error => this.httpService.handleError(error, () => this.getNF(false)),
        () => this.httpService.done()
      );

    }



  }

  private getTipConhecimento() {
    this.httpService.wait();
    this.httpService.get('/tipconembarque?$select=id,cod,descricao').subscribe(
      rst => {
        this.loadTipConhecimento(rst.value);
      },
      error =>
        this.httpService.handleError(error, () => this.getTipConhecimento()),
      () => this.httpService.done()
    );
  }

  hideMessage() {


  }

  private loadCodPais(arr: Array<any>) {
    this.listaCodPais = [];

    this.listaCodPais.push({
      label: 'Selecione',
      value: null
    });

    arr.forEach(element => {
      this.listaCodPais.push({
        label: element.nome + ' - ' + element.codigoBacen,
        value: element.id
      });
    });
  }

  private loadTipConhecimento(arr: Array<any>) {
    this.listaTipCon = [];

    this.listaTipCon.push({
      label: 'Selecione',
      value: null
    });

    arr.forEach(element => {
      this.listaTipCon.push({
        label: element.descricao + ' - ' + element.cod,
        value: element.id
      });
    });
  }

  refreshModal() {
    this.modal = false;

    this.tipDoc = null;

    this.numDec = '';

    this.dtDec = this.defaultDate;

    this.exp = null;

    this.numReg = '';

    this.dtReg = this.defaultDate;

    this.numCon = '';

    this.dtCon = this.defaultDate;

    this.dtAverb = this.defaultDate;

    this.dtExpedicao = this.defaultDate;

    this.tipCon = null;

    this.codPais = null;

    this.dataIniSearch = this.defaultDate;
    this.dataFinSearch = this.defaultDate;
    this.dataIni = this.defaultDate;
    this.dataFin = this.defaultDate;
  }

  salvar() {
    let boo = false;

    if (this.tipDoc === null) {
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Campo Obrigatório',
        detail: 'O tipo de documento deve ser informado.'
      });
      boo = true;
    } else if (this.tipDoc === 0) {
      if (this.numReg === '') {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'Campo Obrigatório',
          detail:
            'O número do registro de exportação deve ser informado para o tipo de documento "Declaração de Exportação".'
        });
        boo = true;
      }
      if (this.dtReg === null) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'Campo Obrigatório',
          detail:
            'A data do registro de exportação deve ser informada para o tipo de documento "Declaração de Exportação".'
        });
        boo = true;
      }
    }

    // * Views vinculadas à tabela impossibilitando a alteração do range de 11-14;
    // * Fernando estará vendo depois;
    if (this.numDec.length > 14) {
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Número de Carateres',
        detail: 'O número da declaração deve conter até 14 dígitos.'
      });
      boo = true;
    }

    if (this.dtDec === null) {
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Campo Obrigatório',
        detail: 'A data da declaração deve ser informada.'
      });
      boo = true;
    }

    if (this.exp === null) {
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Campo Obrigatório',
        detail: 'O tipo da exportação deve ser informado.'
      });
      boo = true;
    }

    if (this.numReg.length > 12) {
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Número de Carateres',
        detail: 'O número do registro de exportação deve conter até 12 dígitos.'
      });
      boo = true;
    }

    if (this.numCon.length > 18) {
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Número de Carateres',
        detail:
          'O número do conhecimento de embarque deve conter até 18 dígitos.'
      });
      boo = true;
    }

    if (this.dtAverb === null) {
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Campo Obrigatório',
        detail: 'A data da averbação deve ser informada.'
      });
      boo = true;
    }

    if (this.tipCon === null) {
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Campo Obrigatório',
        detail: 'O tipo de conhecimento deve ser informado.'
      });
      boo = true;
    }

    if (this.codPais === null) {
      this.messageService.add({
        severity: 'error',key: 'messageLinnks',
        summary: 'Campo Obrigatório',
        detail: 'O código do país deve ser informado.'
      });
      boo = true;
    }

    if (!boo) {
      const obj = {
        chave: this.subChave,
        tipDoc: this.tipDoc,
        numDec: this.numDec,
        dtDec: this.dtDec,
        exp: this.exp,
        numReg: this.numReg,
        dtReg: this.dtReg,
        numCon: this.numCon,
        dtCon: this.dtCon,
        dtAverb: this.dtAverb,
        tipCon: this.tipCon,
        codPais: this.codPais,
        edita: this.edita,
        dtExped: this.dtExpedicao
      };

      this.getModal(obj);
    }
  }

  private toolbarAdd(boo: boolean) {
    this.toolbarService.replace(
      'add',
      boo,
      'class-new-button',
      'NOVO',
      'Criar novo',
      () => (this.modal = true)
    );
  }

  /*MODAL ALTERAR PERÍODO */
  openModalPeriodo() {
    this.modalPeriodo = true;
//    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
//    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
  }

  closeModalPeriodo(){
    this.modalPeriodo = false;
    this.dataIni = null;
    this.dataFin = null;
    this.dataIniSearch = null;
    this.dataFinSearch = null;
  }
}
// console.log('\n--> ' + JSON.stringify(rst, null, ''));

