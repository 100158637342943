import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../app.config';
import { Period } from '../../shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { TimezonePipe } from '../../shared/pipes/timezone.pipe';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { TipoDocumento } from 'app/util/model/tipo-documento.model';
import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { Departamento } from 'app/util/model/departamento.model';
import { Obrigacao } from 'app/centralweb/model/obrigacao.model';
import { Envio } from 'app/centralweb/model/envio.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { Justificativas } from 'app/util/model/justificativas.model';
import { Destinatarios } from 'app/centralweb/model/destinatarios.model';
import { EnvioDestinatarios } from 'app/centralweb/model/envio-destinatarios.model';
import { ComprovantePagamento } from '../model/comprovante-pagamento.model';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';
import { timeout } from 'rxjs/operators';
import { DndDropEvent } from 'ngx-drag-drop';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
  providers: [CrudService]
})
export class AgendaComponent implements OnInit, OnDestroy {
  //@ViewChildren('file') fileInput: ElementRef[];
  private sessionSubscription: Subscription;
  fileInput = [];

  onDrop(event: DndDropEvent) {
    var fileList = [];
    fileList[0] = event.event.dataTransfer.files;

    Array.from(fileList[0]).forEach(file => this.fileInput.push(file));
  }

  onSelectfiles(event) {
    if (event.target.files && event.target.files[0]) {
      var tam = event.target.files.length;

      for (let i = 0; i < tam; i++) {
        var reader = new FileReader();

        this.fileInput.push(event.target.files[i]);
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  events: any[];

  recorrencias: SelectItem[];
  recorrenciasEditar: SelectItem[];
  departamentos: any[];
  documentos: any[];
  gatilhos: any[];
  tiposArquivo: any[];
  codigosObrigacao: any[];
  qtdes: any[];
  dias: any[];
  tipoDocumento: any[];
  gatilhosEditar: any[];
  empresas: Array<Empresa> = new Array();
  obrigacoesPendentes: Array<any> = new Array();
  obrigacoesVencer: Array<any> = new Array();
  calendar1: Array<any> = new Array();

  empresasSelecionadas: Empresa[];
  obrigacao: Obrigacao;
  obrigacaoEditar: Obrigacao;
  tipoDocumentoSelecionado: TipoDocumento;
  justificativa: Justificativas;
  exbirModalRecorrencia: boolean;
  exbirModalObrigacao: boolean;
  exibirModalEnvio: boolean;
  enableInput: boolean;
  exibirModalHistoricoEnvio: boolean = false;
  dtIni: Date;
  dtComprovante: Date;
  qtdeOcorrencia: number;
  aCada: number;
  diaMes: number;
  dtFin: Date;

  file: Array<File> = new Array();
  files: Array<any>;
  filesNaoCadastrada: Array<any>;

  dom: boolean;
  seg: boolean;
  ter: boolean;
  qua: boolean;
  qui: boolean;
  sex: boolean;
  sab: boolean;
  antecipaDiaNaoUtil: boolean;

  obrigSelecionada: any;

  btnDisabled: boolean;

  picklistState: boolean = true

  msgs: Message[];
  msgsModal: Message[];

  uploadedFiles: any[] = [];

  detalhesJustificativa: string;
  opcoesJustificativa: Array<SelectItem> = new Array();

  opcoesDepartamento: Array<SelectItem> = new Array();
  responsaveis: Array<Destinatarios> = new Array();

  mensagem: string;

  vlPrincipal: number;
  vlMulta: number;
  vlJuros: number;
  vlSaldoRecolher: number;

  vlSaldoCredor: number;


  vlImposto1: number;
  vlImposto2: number;
  vlImposto3: number;
  vlImposto4: number;
  competencia: Date;
  defaultDate: Date;

  envioExibir: Envio;

  responsaveisSelecionados: Destinatarios[];
  outrosDestinatarios: string;

  envio: Envio;

  modalObrigNaoCadastrada: boolean;

  obrigacaoNaoCadastrada: Obrigacao;

  title: string;
  subtitle: string;

  vencidas: Array<any> = new Array();
  enviadas: Array<any> = new Array();
  envioDestinatariosList: Array<EnvioDestinatarios> = new Array();
  verificaPagamento: boolean;
  comprovantePagamento: ComprovantePagamento;
  desconsideraObrigacao: boolean;


  constructor(private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent,
    private messageService: MessageService) {
  }

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    // this.toolbarService.clear();
    this.titleService.title = 'CentralWeb';
    this.title = 'AGENDA DE OBRIGAÇÕES';
    this.subtitle = 'CADASTRE, ALTERE OU EXCLUA AS OBRIGAÇÕES DESEJADAS';


    this.envioExibir = new Envio();

    this.tipoDocumento = [];
    this.recorrencias = [];
    this.recorrenciasEditar = [];

    this.qtdes = [];
    this.dias = [];
    this.gatilhosEditar = [];
    this.recorrenciasEditar = [];
    this.dtIni = new Date();
    this.dtFin = new Date();
    this.qtdeOcorrencia = 0;
    this.aCada = 1;
    this.diaMes = 1;

    this.dias.push({ label: 1, value: 1 });
    this.dias.push({ label: 2, value: 2 });
    this.dias.push({ label: 3, value: 3 });
    this.dias.push({ label: 4, value: 4 });
    this.dias.push({ label: 5, value: 5 });
    this.dias.push({ label: 6, value: 6 });
    this.dias.push({ label: 7, value: 7 });
    this.dias.push({ label: 8, value: 8 });
    this.dias.push({ label: 9, value: 9 });
    this.dias.push({ label: 10, value: 10 });
    this.dias.push({ label: 11, value: 11 });
    this.dias.push({ label: 12, value: 12 });
    this.dias.push({ label: 13, value: 13 });
    this.dias.push({ label: 14, value: 14 });
    this.dias.push({ label: 15, value: 15 });
    this.dias.push({ label: 16, value: 16 });
    this.dias.push({ label: 17, value: 17 });
    this.dias.push({ label: 18, value: 18 });
    this.dias.push({ label: 19, value: 19 });
    this.dias.push({ label: 20, value: 20 });
    this.dias.push({ label: 21, value: 21 });
    this.dias.push({ label: 22, value: 22 });
    this.dias.push({ label: 23, value: 23 });
    this.dias.push({ label: 24, value: 24 });
    this.dias.push({ label: 25, value: 25 });
    this.dias.push({ label: 26, value: 26 });
    this.dias.push({ label: 27, value: 27 });
    this.dias.push({ label: 28, value: 28 });
    this.dias.push({ label: 29, value: 29 });
    this.dias.push({ label: 30, value: 30 });
    this.dias.push({ label: 31, value: 31 });

    this.qtdes.push({ label: 1, value: 1 });
    this.qtdes.push({ label: 2, value: 2 });
    this.qtdes.push({ label: 3, value: 3 });
    this.qtdes.push({ label: 4, value: 4 });
    this.qtdes.push({ label: 5, value: 5 });
    this.qtdes.push({ label: 6, value: 6 });
    this.qtdes.push({ label: 7, value: 7 });
    this.qtdes.push({ label: 8, value: 8 });
    this.qtdes.push({ label: 9, value: 9 });
    this.qtdes.push({ label: 10, value: 10 });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.periodChanged(); });
  }

  periodChanged(): void {

    this.vencidas = new Array<any>();
    this.enviadas = new Array<any>();
    this.envioDestinatariosList = new Array<EnvioDestinatarios>();
    this.comprovantePagamento = new ComprovantePagamento();

    this.file = new Array();
    this.files = new Array();
    this.filesNaoCadastrada = new Array();

    this.obrigacaoNaoCadastrada = new Obrigacao();
    this.obrigacaoNaoCadastrada.tipoArquivo = new TipoArquivo();

    this.envio = new Envio();
    this.envio.envioDestinatariosList = new Array<EnvioDestinatarios>();


    this.justificativa = new Justificativas();
    this.obrigacoesPendentes = new Array<any>();
    this.obrigacoesVencer = new Array<any>();
    this.empresas = new Array<Empresa>();
    this.empresasSelecionadas = new Array<Empresa>();
    this.tipoDocumentoSelecionado = new TipoDocumento();
    this.obrigacao = new Obrigacao();
    this.obrigacao.recorrencia = new Recorrencia();
    this.obrigacao.tipoArquivo = new TipoArquivo();
    this.obrigacao.codigosObrigacoes = new CodigosObrigacoes();
    this.calendar1 = new Array<any>();

    this.envioExibir = new Envio();
    this.envioExibir.usuario = new Usuario();

    this.obrigacao.departamento = new Departamento();
    this.obrigacaoEditar = new Obrigacao();
    this.obrigacaoEditar.codigosObrigacoes = new CodigosObrigacoes();
    this.obrigacaoEditar.recorrencia = new Recorrencia();
    this.obrigacaoEditar.tipoArquivo = new TipoArquivo();
    this.obrigacaoEditar.gatilhoObrigacao = new GatilhoObrigacao();

    this.obrigSelecionada = '';
    this.detalhesJustificativa = '';
    this.mensagem = '';
    this.outrosDestinatarios = '';
    this.vlPrincipal = 0.00;
    this.vlMulta = 0.00;
    this.vlJuros = 0.00;
    this.vlSaldoRecolher = 0.00;
    this.vlSaldoCredor = 0.00;

    this.vlImposto1 = 0.00;
    this.vlImposto2 = 0.00;
    this.vlImposto3 = 0.00;
    this.vlImposto4 = 0.00;
    this.competencia = null;
    this.defaultDate = new Date();
    this.vlPrincipal = 0.00;
    this.vlMulta = 0.00;
    this.vlJuros = 0.00;
    this.vlSaldoRecolher = 0.00;
    this.comprovantePagamento = new ComprovantePagamento();
    this.exibirModalHistoricoEnvio = false;
    this.btnDisabled = true;
    this.modalObrigNaoCadastrada = false;
    this.dom = false;
    this.seg = false;
    this.ter = false;
    this.qua = false;
    this.qui = false;
    this.sex = false;
    this.sab = false;
    this.antecipaDiaNaoUtil = false;
    this.exbirModalRecorrencia = false;
    this.enableInput = false;
    this.exbirModalObrigacao = false;
    this.exibirModalEnvio = false;
    this.exibirModalHistoricoEnvio = false;
    this.desconsideraObrigacao = false;
    this.verificaPagamento = false;
    this.outrosDestinatarios = '';
    this.competencia = null;

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasYearSelected &&
      this.dblinkedSessionService.usuario) {

      if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
        const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
          this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
        const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
        this.defaultDate = new Date(dt);
      }
      this.httpService.wait();
      this.httpService.get('/custom/centralweb/get-obrig-vencer(' + this.dblinkedSessionService.usuario.id.toString() + ')')
        .subscribe(obrigacoes => this.loadObrigacoes(obrigacoes),
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.responsaveisSelecionados = new Array<Destinatarios>();

      this.httpService.wait();
      this.httpService.get('/departamento?$select=id,cod,descricao' +
        '&$orderby=cod')
        .subscribe(ref => this.loadDepartamentos(ref.value),
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/gatilhoobrigacao?$select=id,cod,descricao' +
        '&$orderby=cod')
        .subscribe(ref => this.loadGatilhos(ref.value),
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/empresa?$select=id,cnpj,razaoSocial&$orderby=razaoSocial')
        .subscribe(ref => this.loadEmpresa(ref.value),
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/recorrencia?$select=id,cod,descricao&$orderby=id')
        .subscribe(ref => this.loadRecorrencia(ref.value),
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.opcoesJustificativa = new Array();
      this.opcoesJustificativa.push({ label: '(Nenhum)', value: null });
      this.httpService.wait();
      this.httpService.get('/justificativas?$select=id,descricao&$orderby=descricao')
        .subscribe(cadCcusList => {
          cadCcusList.value.forEach(just => {
            this.opcoesJustificativa.push({
              label: just.descricao,
              value: just
            });
          });
        },
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.opcoesDepartamento = new Array();
      this.opcoesDepartamento.push({ label: '(Nenhum)', value: null });
      this.httpService.wait();
      this.httpService.get('/departamento?$select=id,cod,descricao&$orderby=cod')
        .subscribe(deptoList => {
          deptoList.value.forEach(depto => {
            this.opcoesDepartamento.push({
              label: depto.cod,
              value: depto
            });
          });
        },
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/centralweb/calendar(' + (this.dblinkedSessionService.periodo.month.value - 1).toString() + ',' +
        this.dblinkedSessionService.periodo.year.value.toString() + ',' + this.dblinkedSessionService.empresa.id + ',' +
        this.dblinkedSessionService.usuario.id + ')')
        .subscribe(formalizacoes => this.loadCalendar1(formalizacoes),
          error => this.httpService.handleError(error, () => this.tabChange(2)),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/centralweb/get-pendencias', this.dblinkedSessionService.usuario.id)
        .subscribe(p => this.loadPendencias(p),
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/centralweb/get-envios', [this.dblinkedSessionService.periodo.year.value,
      this.dblinkedSessionService.periodo.month.value,
      this.dblinkedSessionService.empresa.id, this.dblinkedSessionService.usuario.id])
        .subscribe(enviadas => this.loadEnviadas(enviadas),
          error => this.httpService.handleError(error, () => this.periodChanged()),
          () => this.httpService.done());

    }
  }

  loadEmpresa(value: Array<Empresa>) {
    this.empresas = value.filter(e => this.empresasSelecionadas.findIndex(es => es.id === e.id) === -1);
  }

  loadRecorrencia(value: Array<any>) {
    this.recorrencias = [];
    this.recorrenciasEditar = [];
    value.forEach(element => {
      this.recorrencias.push({ label: element.cod, value: element });
      if (element.id !== 1) {
        this.recorrenciasEditar.push({ label: element.cod, value: element });
      }
    });
  }

  loadTiposProjeto(value: Array<any>) {
    this.tiposArquivo = new Array();
    this.tiposArquivo.push({ label: 'Selecione um tipo de Arquivo', value: null });
    value.forEach(element => {
      this.tiposArquivo.push({ label: element.descricao, value: element });
    });
  }

  loadDepartamentos(value: Array<any>) {
    this.departamentos = new Array();
    value.forEach(element => {
      this.departamentos.push(element);
    });
  }

  loadTipoDocumentos(value: Array<any>) {
    this.tipoDocumento = [];
    this.tipoDocumento.push({ label: 'Selecione o tipo de documento', value: null });
    value.forEach(element => {
      this.tipoDocumento.push({ label: element.cod, value: element });
    });
  }

  loadGatilhos(value: Array<any>) {
    this.gatilhos = new Array();
    this.gatilhosEditar = [];
    value.forEach(element => {
      this.gatilhos.push(element);
      this.gatilhosEditar.push({ label: element.descricao, value: element });
    });
  }

  carregarObrigacao() {
    this.responsaveisSelecionados = new Array();
    this.picklistState = false
    this.httpService.wait();
    this.httpService.get('/codigosobrigacoes?$select=id,cod,descricao' +
      '&$filter=tipoArquivo/id eq ' + this.obrigacaoNaoCadastrada.tipoArquivo.id +
      '&$orderby=cod')
      .subscribe(ref => this.loadCodigosObrigacao(ref.value),
        error => this.httpService.handleError(error, () => this.carregarObrigacao()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/destinatarios?$select=id,nome,mail' +
      '&$filter=tipoArquivo/id eq ' + this.obrigacaoNaoCadastrada.tipoArquivo.id +
      ' and empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      '&$orderby=nome')
      .subscribe(userList => {
        userList.value.forEach(user => {
          this.responsaveisSelecionados.push(user);
        });
        this.picklistState = true
      },
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());
  }

  modalRecorrencia() {
    this.exbirModalRecorrencia = true;
  }

  criarObrigacao() {

    const listasEnviar = {
      obrigacao: this.obrigacao,
      recorrencia: this.obrigacao.recorrencia.id,
      tipoArquivo: this.obrigacao.tipoArquivo.id,
      codigosObrigacoes: this.obrigacao.codigosObrigacoes.id,
      departamento: this.obrigacao.departamento.id,
      usuario: this.dblinkedSessionService.usuario.id,
      dtIni: this.dtIni,
      dtFin: this.dtFin,
      aCada: this.aCada,
      dom: this.dom,
      seg: this.seg,
      ter: this.ter,
      qua: this.qua,
      qui: this.qui,
      sex: this.sex,
      sab: this.sab,
      qtdeOcorrencia: this.qtdeOcorrencia,
      diaMes: this.diaMes,
      antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
      empresas: this.empresasSelecionadas,
      gatilhoObrigacao: this.obrigacao.gatilhoObrigacao.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/cadastrar-obrigacao', listasEnviar)
      .subscribe(result => this.periodChanged(),
        error => this.httpService.handleError(error, () => this.criarObrigacao()),
        () => this.httpService.done());


  }

  atualizarRecorrencia() {

    if (confirm('Todas as recorrências seguintes desta obrigação serão alteradas, deseja continuar?')) {

      const listasEnviar = {
        obrigacao: this.obrigacaoEditar,
        recorrencia: this.obrigacaoEditar.recorrencia.id,
        tipoArquivo: this.obrigacaoEditar.tipoArquivo.id,
        codigosObrigacoes: this.obrigacaoEditar.codigosObrigacoes.id,
        departamento: this.obrigacaoEditar.departamento.id,
        usuario: this.dblinkedSessionService.usuario.id,
        dtIni: this.dtIni,
        dtFin: this.dtFin,
        aCada: this.aCada,
        dom: this.dom,
        seg: this.seg,
        ter: this.ter,
        qua: this.qua,
        qui: this.qui,
        sex: this.sex,
        sab: this.sab,
        qtdeOcorrencia: this.qtdeOcorrencia,
        diaMes: this.diaMes,
        antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
        empresas: this.empresasSelecionadas,
        gatilhoObrigacao: this.obrigacaoEditar.gatilhoObrigacao.id,
        obrigacaoAnterior: this.obrigacaoEditar.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/centralweb/atualizar-recorrencias', listasEnviar)
        .subscribe(result => this.tabChange(1),
          error => this.httpService.handleError(error, () => this.criarObrigacao()),
          () => this.httpService.done());

    }

  }

  atualizarObrigacao() {

    const listasEnviar = {
      vencimento: this.dtIni,
      id: this.obrigacaoEditar.id,
      gatilho: this.obrigacaoEditar.gatilhoObrigacao.id

    };
    this.httpService.wait();
    this.httpService.post('/custom/centralweb/atualizar-obrigacao-unico', listasEnviar)
      .subscribe(result => this.tabChange(1),
        error => this.httpService.handleError(error, () => this.criarObrigacao()),
        () => this.httpService.done());


  }

  loadCodigosObrigacao(value: Array<any>) {
    this.codigosObrigacao = new Array();
    this.codigosObrigacao.push({ label: 'Selecione a Obrigação', value: null });
    value.forEach(element => {
      this.codigosObrigacao.push({ label: element.cod + ' - ' + element.descricao, value: element });
    });
  }


  ngOnDestroy() {
    // this.toolbarService.clear();
    this.cancelarEnvio();
    this.cancelarEnvioObrigacaoNaoSelecionada();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  carregarCalendar() {

    this.exbirModalObrigacao = false;
    this.enableInput = false;
    this.exibirModalEnvio = false;
    this.modalObrigNaoCadastrada = false;
    this.calendar1 = new Array();
    this.mensagem = '';
    this.detalhesJustificativa = '';
    this.file = new Array();
    this.files = new Array();
    this.filesNaoCadastrada = new Array();
    this.tipoDocumentoSelecionado = new TipoDocumento();
    this.vlPrincipal = 0.00;
    this.vlMulta = 0.00;
    this.vlJuros = 0.00;
    this.vlSaldoRecolher = 0.00;
    this.vlSaldoCredor = 0.00;

    this.vlImposto1 = 0.00;
    this.vlImposto2 = 0.00;
    this.vlImposto3 = 0.00;
    this.vlImposto4 = 0.00;
    this.competencia = null;


    this.httpService.wait();
    this.httpService.get('/custom/centralweb/calendar', [(this.dblinkedSessionService.periodo.month.value - 1).toString(),
    this.dblinkedSessionService.periodo.year.value.toString(),
    this.dblinkedSessionService.empresa.id,
    this.dblinkedSessionService.usuario.id])
      .subscribe(formalizacoes => this.loadCalendar1(formalizacoes),
        error => this.httpService.handleError(error, () => this.tabChange(2)),
        () => this.httpService.done());
  }

  tabChange(event: any) {
    /* this.exbirModalObrigacao = false;
     this.exibirModalEnvio = false;
     this.calendar1 = new Array();

     this.httpService.wait();
     this.httpService.get('/obrigacao?$select=id,tipoArquivo,codigosObrigacoes,recorrencia,departamento,dtVencto,gatilhoObrigacao' +
       '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
       '&$orderby=dtVencto')
       .subscribe(ref => this.loadObrigacoes(ref.value),
       error => this.httpService.handleError(error, () => this.ngOnInit()),
       () => this.httpService.done());

     if (event.index === 0) {
       this.httpService.wait();
       this.httpService.get('/custom/centralweb/get-obrig-vencer(' + this.dblinkedSessionService.usuario.id.toString() + ')')
         .subscribe(obrigacoes => this.loadObrigacoes(obrigacoes),
         error => this.httpService.handleError(error, () => this.tabChange(2)),
         () => this.httpService.done());
     }

     if (event.index === 1) {
       if (this.dblinkedSessionService.periodo) {
         this.httpService.wait();
       this.httpService.get('/custom/centralweb/calendar(' + (this.dblinkedSessionService.periodo.month.value - 1).toString() + ',' +
         this.dblinkedSessionService.periodo.year.value.toString() + ',' + this.dblinkedSessionService.empresa.id + ',' +
         this.dblinkedSessionService.usuario.id + ')')
         .subscribe(formalizacoes => this.loadCalendar1(formalizacoes),
         error => this.httpService.handleError(error, () => this.tabChange(2)),
         () => this.httpService.done());

       }
     }
     Promise.resolve(null).then(() => this.toolbarRefresh());
     */
  }

  loadCalendar1(value: Array<any>) {
    this.calendar1 = new Array();
    value.forEach(element => {
      this.calendar1.push(element);
    });

  }

  loadObrigacoes(value: Array<any>) {

    this.obrigacoesPendentes = new Array();
    this.obrigacoesVencer = new Array();
    value.forEach(element => {
      if (element.atraso === true) {
        this.obrigacoesPendentes.push(element);
      } else {
        this.obrigacoesVencer.push(element);
      }


    });

  }

  onRowSelect(event: any) {
    this.obrigacaoEditar = event.data;

    this.exbirModalObrigacao = true;
  }

  deletarObrigacao(id: number) {
    if (confirm('Deseja realmente remover esta obrigação?')) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/deletarobrigacao(' + id + ')')
        .subscribe(result => {

          this.tabChange(1);
        },
          error => this.httpService.handleError(error, () => this.deletarObrigacao(id)),
          () => this.httpService.done());
    }

  }

  deletarObrigacoes(id: number) {
    if (confirm('Deseja realmente remover esta obrigação e todas as seguintes?')) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/deletarobrigacoes(' + id + ')')
        .subscribe(result => {

          this.tabChange(1);
        },
          error => this.httpService.handleError(error, () => this.deletarObrigacao(id)),
          () => this.httpService.done());
    }
  }

  alertObrig(event: any) {
    this.picklistState = false;
    this.responsaveis = new Array<Destinatarios>();
    this.responsaveisSelecionados = new Array<Destinatarios>();
    this.outrosDestinatarios = '';
    this.obrigSelecionada = '';
    this.detalhesJustificativa = '';
    this.mensagem = '';
    this.vlPrincipal = 0.00;
    this.vlMulta = 0.00;
    this.vlJuros = 0.00;
    this.vlSaldoRecolher = 0.00;
    this.vlSaldoCredor = 0.00;

    this.vlImposto1 = 0.00;
    this.vlImposto2 = 0.00;
    this.vlImposto3 = 0.00;
    this.vlImposto4 = 0.00;
    this.competencia = null;
    this.vlPrincipal = 0.00;
    this.vlMulta = 0.00;
    this.vlJuros = 0.00;
    this.vlSaldoRecolher = 0.00;

    this.loadTipoDocumentosOk(event.tipoArquivo);

    this.obrigSelecionada = event;
    this.exibirModalEnvio = true;

    this.httpService.wait();
    this.httpService.get('/destinatarios?$select=id,nome,mail' +
      '&$filter=tipoArquivo/id eq ' + event.tipoArquivo +
      ' and empresa/id eq ' + this.obrigSelecionada.empresaId +
      '&$orderby=nome')
      .subscribe(userList => {
        userList.value.forEach((user: Destinatarios) => {
          this.responsaveisSelecionados.push(user);
        });
        this.picklistState = true;
      },
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());
  }

  loadTipoDocumentosOk(tipoArquivo: any) {

    this.tipoDocumento = [];
    this.tipoDocumento.push({ label: 'Selecione o tipo de documento', value: null });

    this.httpService.wait();
    this.httpService.get('/tipodocumento?$select=id,cod,descricao,tipoArquivo/id' +
      '&$filter=tipoArquivo/id eq ' + tipoArquivo + '&$orderby=cod')
      .subscribe(tipoDocumentoList => {
        tipoDocumentoList.value.forEach(tipo => {
          this.tipoDocumento.push({ label: tipo.cod, value: tipo });
        });
      },
        error => this.httpService.handleError(error, () => this.periodChanged()),
        () => this.httpService.done());
  }

  carregarTipoDocumentos() {
    this.loadTipoDocumentosOk(this.obrigacaoNaoCadastrada.tipoArquivo.id);
  }

  fileChange() {
    this.fileInput.forEach(element => this.file.push(element));
  }

  importar() {
    if (this.fileInput[0] === undefined)
      alert('Selecione um arquivo para anexar');
    else {
      this.fileInput.forEach(file => {
        if (file.name.indexOf('.') === -1) {
          alert('Um dos arquivos anexados está sem extensão, verifique!');
          return;
        }

        const data: FormData = new FormData();
        data.append('file', file);
        data.append('type', 'cw');
        data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
        data.append('obrigacaoId', (this.obrigSelecionada.obrigId.toString()));

        this.httpService.wait();
        this.httpService.post('/custom/upload', data)
          .subscribe(result => this.uploaded(result),
            error => this.httpService.handleError(error, () => this.importar()),
            () => this.httpService.done());
      });
    }
    this.fileInput = [];
  }

  importarNaoCadastrada() {
    if (this.fileInput[0] === undefined)
      alert('Selecione um arquivo para anexar');
    else {
      this.fileInput.forEach(f => {
        const data: FormData = new FormData();
        data.append('file', f);
        data.append('type', 'cw');
        data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
        data.append('obrigacaoId', '0');

        this.httpService.wait();
        this.httpService.post('/custom/upload', data)
          .subscribe(result => this.uploadedNaoCadastrada(result),
            error => this.httpService.handleError(error, () => this.importar()),
            () => this.httpService.done());
      });
    }
    this.fileInput = [];
  }

  excluirAnexo(event: any) {
    if (confirm('Deseja excluir o anexo?')) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/excluir-anexos(' + event.nome + ',' + (this.modalObrigNaoCadastrada ? 0 : this.obrigSelecionada.obrigId.toString()) + ')')
        .subscribe(result => this.uploaded(result),
          error => this.httpService.handleError(error, () => this.tabChange(2)),
          () => this.httpService.done());
    }
  }

  excluirVariosAnexos(event: any) {
    let nomes = [];

    if (this.modalObrigNaoCadastrada)
      for (var i = 0, tam = event.length; i < tam; i++)
        nomes.push(event[i].nome + "");
    else
      event.forEach(e => nomes.push(e.name == null ? e.nome + "" : e.name + ""));

    const map = {
      nomes: nomes,
      id: this.modalObrigNaoCadastrada ? 0 : this.obrigSelecionada.obrigId.toString()
    }

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/excluir-varios-anexos', map)
      .subscribe(result => {
        if (this.modalObrigNaoCadastrada) {
          this.uploadedNaoCadastrada(result);
          this.modalObrigNaoCadastrada = false
        }
        else this.uploaded(result)
      },
        error => this.httpService.handleError(error, () => this.tabChange(2)),
        () => this.httpService.done());
  }



  export(event: any) {
    console.log(event);
    this.httpService.wait();
    this.httpService.post('/custom/centralweb/export-files(' + event.envioId + ')')
      .subscribe(result => this.download(result),
        error => this.httpService.handleError(error, () => this.export(event)),
        () => this.httpService.done());
  }

  download(result: any) {
    console.log(result);
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/' + result.envioId + '/cw'), '_blank');
    }
  }


  private uploaded(result?: any) {
    const data: FormData = new FormData();
    data.append('obrigacaoId', (this.modalObrigNaoCadastrada ? 0 : this.obrigSelecionada.obrigId.toString()));

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/get-files-to-send', data)
      .subscribe(formalizacoes => this.loadFiles(formalizacoes),
        error => this.httpService.handleError(error, () => this.tabChange(2)),
        () => this.httpService.done());
  }

  private uploadedNaoCadastrada(result?: any) {
    const data: FormData = new FormData();
    data.append('obrigacaoId', '0');

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/get-files-to-send', data)
      .subscribe(formalizacoes => this.loadFilesNaoCadastrada(formalizacoes),
        error => this.httpService.handleError(error, () => this.tabChange(2)),
        () => this.httpService.done());
  }

  enviarObrigacao() {
    let validaCampos: boolean;
    validaCampos = true;

    if (this.competencia === null && this.tipoDocumentoSelecionado.descricao.indexOf('RELAT') < 0
      && this.obrigSelecionada.tipoArquivo !== 11) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Valores: ', detail: 'Campo "competência" não pode ser vazio.' });
      validaCampos = false;
    }
    if (validaCampos) {
      let destinatarios = '';
      if (this.responsaveisSelecionados.length > 0) {
        this.responsaveisSelecionados.forEach(element => {
          if (destinatarios) {
            destinatarios = destinatarios + ';' + element.mail;
          } else {
            destinatarios = element.mail;
          }
        });
      }
      if (this.outrosDestinatarios && this.responsaveisSelecionados.length > 0) {
        destinatarios = destinatarios + ';' + this.outrosDestinatarios;
      } else if (this.outrosDestinatarios && this.responsaveisSelecionados.length === 0) {
        destinatarios = this.outrosDestinatarios;
      }
      const data: FormData = new FormData();
      data.append('obrigacaoId', (this.modalObrigNaoCadastrada ? 0 : this.obrigSelecionada.obrigId.toString()));
      data.append('usuarioId', this.dblinkedSessionService.usuario.id.toString());


      if (this.justificativa.id) {
        data.append('detalhesJustificativa', this.detalhesJustificativa);
        data.append('justificativa', this.justificativa.id.toString());
      }

      data.append('mensagem', this.mensagem);
      data.append('vlPrincipal', this.utilService.parseDecimal(this.vlPrincipal).toString());
      data.append('vlMulta', this.utilService.parseDecimal(this.vlMulta).toString());
      data.append('vlJuros', this.utilService.parseDecimal(this.vlJuros).toString());
      data.append('vlSaldoRecolher', this.utilService.parseDecimal(this.vlSaldoRecolher).toString());
      data.append('vlSaldoCredor', this.utilService.parseDecimal(this.vlSaldoCredor).toString());
      data.append('vlImposto1', this.utilService.parseDecimal(this.vlImposto1).toString());
      data.append('vlImposto2', this.utilService.parseDecimal(this.vlImposto2).toString());
      data.append('vlImposto3', this.utilService.parseDecimal(this.vlImposto3).toString());
      data.append('vlImposto4', this.utilService.parseDecimal(this.vlImposto4).toString());
      data.append('competencia', (this.competencia ? this.competencia.toString() : ''));

      if ([1, 3, 6, 7].indexOf(this.obrigSelecionada.tipoArquivo) === -1) {
        data.append('tipoDocumentoId', this.tipoDocumentoSelecionado.id.toString());
      } else {
        data.append('tipoDocumentoId', '6');
      }
      data.append('outrosDestinatarios', destinatarios);

      this.httpService.wait();
      this.httpService.post('/custom/centralweb/enviar-obrigacao', data)
        .subscribe(result => this.periodChanged(),
          error => this.httpService.handleError(error, () => this.importar()),
          () => this.httpService.done());
    }
  }

  enviarObrigacaoNaoSelecionada() {
    let destinatarios = '';

    if (this.responsaveisSelecionados.length > 0) {

      this.responsaveisSelecionados.forEach(element => {
        if (destinatarios) {
          destinatarios = destinatarios + ';' + element.mail;
        } else {
          destinatarios = element.mail;
        }

      });

    }


    if (this.outrosDestinatarios && this.responsaveisSelecionados.length > 0) {
      destinatarios = destinatarios + ';' + this.outrosDestinatarios;
    } else if (this.outrosDestinatarios && this.responsaveisSelecionados.length === 0) {
      destinatarios = this.outrosDestinatarios;
    }

    const data: FormData = new FormData();
    data.append('usuarioId', this.dblinkedSessionService.usuario.id.toString());
    data.append('mensagem', this.mensagem);
    data.append('vlPrincipal', this.utilService.parseDecimal(this.vlPrincipal).toString());
    data.append('vlMulta', this.utilService.parseDecimal(this.vlMulta).toString());
    data.append('vlJuros', this.utilService.parseDecimal(this.vlJuros).toString());
    data.append('vlSaldoRecolher', this.utilService.parseDecimal(this.vlSaldoRecolher).toString());
    data.append('vlSaldoCredor', this.utilService.parseDecimal(this.vlSaldoCredor).toString());
    data.append('vlImposto1', this.utilService.parseDecimal(this.vlImposto1).toString());
    data.append('vlImposto2', this.utilService.parseDecimal(this.vlImposto2).toString());
    data.append('vlImposto3', this.utilService.parseDecimal(this.vlImposto3).toString());
    data.append('vlImposto4', this.utilService.parseDecimal(this.vlImposto4).toString());
    if ([1, 3, 6, 7].indexOf(this.obrigSelecionada.tipoArquivo) === -1) {
      data.append('tipoDocumentoId', this.tipoDocumentoSelecionado.id.toString());
    } else {
      data.append('tipoDocumentoId', '6');
    }
    data.append('outrosDestinatarios', destinatarios);
    data.append('tipoArquivoId', this.obrigacaoNaoCadastrada.tipoArquivo.id.toString());
    data.append('codigosObrigacoesId', this.obrigacaoNaoCadastrada.codigosObrigacoes.id.toString());
    data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());



    this.httpService.wait();
    this.httpService.post('/custom/centralweb/enviar-obrigacao-nao-cadastrada', data)
      .subscribe(result => this.carregarCalendar(),
        error => this.httpService.handleError(error, () => this.importar()),
        () => this.httpService.done());
  }

  private sent(result?: any) {

    this.messageService.add({
      severity: result.error ? 'error' : 'success',
      detail: result.message || 'Obrigação Enviada com Sucesso!'
    });
  }

  calcSaldoRecolher() {
    this.vlSaldoRecolher = this.utilService.parseDecimal(this.vlPrincipal) +
      this.utilService.parseDecimal(this.vlMulta) + this.utilService.parseDecimal(this.vlJuros);
  }

  cancelarEnvio() {
    this.exbirModalObrigacao = false;
    this.exibirModalEnvio = false;
    this.enableInput = false;
    this.mensagem = '';
    this.detalhesJustificativa = '';
    if (this.file.length > 0)
      this.excluirVariosAnexos(this.file);
    this.file = new Array();
    this.files = new Array();
    this.filesNaoCadastrada = new Array();
    this.fileInput = [];
    this.tipoDocumentoSelecionado = new TipoDocumento();
    this.vlPrincipal = 0.00;
    this.vlMulta = 0.00;
    this.vlJuros = 0.00;
    this.vlSaldoRecolher = 0.00;
    this.vlSaldoCredor = 0.00;

    this.vlImposto1 = 0.00;
    this.vlImposto2 = 0.00;
    this.vlImposto3 = 0.00;
    this.vlImposto4 = 0.00;
    // zerando variaveis ao cancelar
    this.file = [];
    this.files = [];
    this.fileInput.forEach(element => {
      element.nativeElement.value = '';
    });
  }

  cancelarEnvioObrigacaoNaoSelecionada() {
    this.obrigacaoNaoCadastrada = new Obrigacao();
    this.enableInput = false;
    this.mensagem = '';
    this.detalhesJustificativa = '';
    if (this.filesNaoCadastrada.length > 0)
      this.excluirVariosAnexos(this.filesNaoCadastrada);
    else
      this.modalObrigNaoCadastrada = false;
    this.file = new Array();
    this.files = new Array();
    this.filesNaoCadastrada = new Array();
    this.fileInput = [];
    this.tipoDocumentoSelecionado = new TipoDocumento();
    this.vlPrincipal = 0.00;
    this.vlMulta = 0.00;
    this.vlJuros = 0.00;
    this.vlSaldoRecolher = 0.00;
    this.vlSaldoCredor = 0.00;

    this.vlImposto1 = 0.00;
    this.vlImposto2 = 0.00;
    this.vlImposto3 = 0.00;
    this.vlImposto4 = 0.00;
  }

  loadFiles(event: any) {
    if (this.modalObrigNaoCadastrada)
      this.filesNaoCadastrada = event;
    else
      this.files = event;
  }

  loadFilesNaoCadastrada(event: any) {
    this.filesNaoCadastrada = [];
    event.forEach(e => {
      this.filesNaoCadastrada.push(e);
    });

  }

  openAnexoSelect() {
    this.enableInput = true;
    setTimeout(() => document.getElementById('file-upload').click(), 500);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {
    this.file = null;

  }

  enviarObrigNaoCadastrada() {
    this.modalObrigNaoCadastrada = true;

    this.responsaveis = new Array<Destinatarios>();
    this.responsaveisSelecionados = new Array<Destinatarios>();

    this.httpService.wait();
    this.httpService.get('/tipoarquivo?$select=id,nome,descricao' +
      '&$orderby=nome')
      .subscribe(ref => this.loadTiposProjeto(ref.value),
        error => this.httpService.handleError(error, () => this.enviarObrigNaoCadastrada()),
        () => this.httpService.done());
  }

  private toolbarRefresh() {
    // casos em que a toolbar vem vazia (session de outra página)
    const todos = this.buttons.slice();
    if (todos.length === 0) {
      this.toolbarService.replace('atualizar', true, 'class-first-button', 'refresh', 'Atualizar relatório',);
      this.toolbarService.replace('download', true, 'class-other-button', 'cloud_download', 'Download',);
      this.toolbarService.replace('cancel', true, 'class-other-button class-botao-margin', 'block', 'Cancelar',);
      this.toolbarService.replace('export', true, 'class-other-button', 'description', 'Exportar',);
      this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir',);
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir',);
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar',);
      this.toolbarService.replace('add', false, 'class-new-button', 'ENVIAR', 'Enviar uma obrigação não cadastrada',
        () => this.enviarObrigNaoCadastrada());
    } else {
      // this.toolbarService.ocultar('check');
      // this.toolbarService.ocultar('info');
      // this.toolbarService.ocultar('msg');
      // this.toolbarService.ocultar('save');
      this.toolbarService.replace('add', false, 'class-new-button', 'ENVIAR', 'Enviar uma obrigação não cadastrada',
        () => this.enviarObrigNaoCadastrada());
    }
    /*this.toolbarService.clear();
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar', () => this.enviarObrigNaoCadastrada());
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir', () => this.enviarObrigNaoCadastrada());
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.enviarObrigNaoCadastrada());
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!', () => this.enviarObrigNaoCadastrada());
    this.toolbarService.add('add', false, 'class-new-button', 'ENVIAR',
                            'Enviar uma obrigação não cadastrada', () => this.enviarObrigNaoCadastrada());*/
  }

  loadPendencias(value: Array<any>) {
    this.vencidas = new Array();
    value.forEach(element => {
      this.vencidas.push(element);
    });
  }

  loadEnviadas(value: Array<any>) {
    this.enviadas = new Array();
    value.forEach(element => {
      this.enviadas.push(element);
    });
    this.toolbarRefresh();
  }

  onRowSelectEnvio(event: any) {
    console.log(event);
    this.httpService.wait();
    this.httpService.get('/envio?$select=id,dtEnvio,usuario/id,usuario/nome,usuario/sobrenome,vlPrincipal,vlMulta,vlJuros,' +
      'vlSaldoRecolher,vlSaldoCredor,vlImposto1,vlImposto2,vlImposto3,vlImposto4,desconsiderar,tipoDocumento/cod,' +
      'justificativa/descricao,detalhesJustificativa,mensagem,obrigacao/codigosObrigacoes/cod,' +
      'obrigacao/tipoArquivo/id,outrosDestinatarios,obrigacao/codigosObrigacoes/id&$filter=id eq ' + event.data.envioId)
      .subscribe(ref => this.loadEnvio(ref.value),
        error => this.httpService.handleError(error, () => this.onRowSelectEnvio(event)),
        () => this.httpService.done());

  }

  loadEnvio(value: any) {
    this.outrosDestinatarios = '';
    this.exibirModalHistoricoEnvio = true;
    this.envioExibir = value[0];
    if (this.envioExibir.desconsiderar === null) {
      this.desconsideraObrigacao = false;
    } else {
      this.desconsideraObrigacao = this.envioExibir.desconsiderar;
    }

    const data: FormData = new FormData();
    data.append('envioId', this.envioExibir.id.toString());


    this.httpService.wait();
    this.httpService.post('/custom/centralweb/get-files-to-send-comp', data)
      .subscribe(formalizacoes => this.loadFiles(formalizacoes),
        error => this.httpService.handleError(error, () => this.tabChange(2)),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/comprovantepagamento?$select=id,dtConfirmacao,vlPrincipal,vlMulta,vlJuros,vlSaldoRecolher,envio/id,usuario/id' +
      '&$filter=envio/id eq ' + this.envioExibir.id)
      .subscribe(ref => this.loadComprovantePagamento(ref.value),
        error => this.httpService.handleError(error, () => this.loadEnvio(value)),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/tributospagamentorelac?$select=id' +
      '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' and codigosObrigacoes/id eq ' + this.envioExibir.obrigacao.codigosObrigacoes.id)
      .subscribe(ref => this.loadVerificacaoPagamento(ref.value),
        error => this.httpService.handleError(error, () => this.loadEnvio(value)),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/enviodestinatarios?$select=id,mail,dtHrConfirmacao&$filter=envio/id eq ' + this.envioExibir.id)
      .subscribe(ref => this.loadConfirmacao(ref.value),
        error => this.httpService.handleError(error, () => this.loadEnvio(value)),
        () => this.httpService.done());

  }

  loadComprovantePagamento(event: ComprovantePagamento) {
    this.comprovantePagamento = event[0];
    if (this.comprovantePagamento !== undefined) {
      this.vlPrincipal = this.comprovantePagamento.vlPrincipal;
      this.vlMulta = this.comprovantePagamento.vlMulta;
      this.vlJuros = this.comprovantePagamento.vlJuros;
      this.vlSaldoRecolher = this.comprovantePagamento.vlSaldoRecolher;
      this.dtComprovante = new Date(this.comprovantePagamento.dtConfirmacao);

    }
  }

  loadConfirmacao(value: any) {
    this.envioDestinatariosList = value;
  }

  loadVerificacaoPagamento(value: Array<any>) {
    if (value[0] === undefined) {
      this.verificaPagamento = true;
    } else {
      this.verificaPagamento = false;
    }
  }

  salvarPagamento() {
    const data: FormData = new FormData();
    data.append('envioId', this.envioExibir.id.toString());
    data.append('vlPrincipal', this.utilService.parseDecimal(this.vlPrincipal).toString());
    data.append('vlMulta', this.utilService.parseDecimal(this.vlMulta).toString());
    data.append('vlJuros', this.utilService.parseDecimal(this.vlJuros).toString());
    data.append('vlSaldoRecolher', this.utilService.parseDecimal(this.vlSaldoRecolher).toString());
    data.append('usuarioId', this.dblinkedSessionService.usuario.id.toString());
    data.append('dtComprovante', this.dtComprovante.toDateString());


    this.httpService.wait();
    this.httpService.post('/custom/centralweb/salvar-comprovante-pagamento', data)
      .subscribe(result => this.periodChanged(),
        error => this.httpService.handleError(error, () => this.salvarPagamento),
        () => this.httpService.done());

  }

  atualizarPagamento() {

    this.comprovantePagamento.vlPrincipal = this.vlPrincipal;
    this.comprovantePagamento.vlMulta = this.vlMulta;
    this.comprovantePagamento.vlJuros = this.vlJuros;
    this.comprovantePagamento.vlSaldoRecolher = this.vlSaldoRecolher;
    this.comprovantePagamento.dtConfirmacao = this.dtComprovante;

    this.httpService.wait();
    this.httpService.put('/comprovantepagamento', this.comprovantePagamento.id, this.comprovantePagamento)
      .subscribe(() => this.periodChanged(),
        error => this.httpService.handleError(error, () => this.atualizarPagamento()),
        () => this.httpService.done());
  }

  excluirComprovantePagamento() {

    if (confirm('Deseja excluir o comprovante?')) {

      this.httpService.wait();
      this.httpService.delete('/comprovantepagamento', this.comprovantePagamento.id.toString())
        .subscribe(() => this.periodChanged(),
          error => this.httpService.handleError(error, () => this.excluirComprovantePagamento()),
          () => this.httpService.done());

    }
  }

  desconsiderarObrigacao() {
    if (confirm('Este envio será "desconsiderado" e não será mais listado, deseja continuar?')) {
      const listasEnviar = {
        id: this.envioExibir.id,
        desconsiderar: !(this.desconsideraObrigacao)
      };
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/desconsiderar-obrigacao', listasEnviar)
        .subscribe(result => {
          this.messageService.add({
            severity: result.error ? 'error' : 'success',
            detail: result.message || 'Obrigação foi alterada com sucesso!'
          }); this.periodChanged();
        },
          error => this.httpService.handleError(error, () => this.desconsiderarObrigacao()),
          () => this.httpService.done());
    }
  }

  cancelarReEnvio() {
    this.exibirModalHistoricoEnvio = false;
    this.outrosDestinatarios = '';
  }

  reEnviarObrigacao() {
    if (confirm('Confirma o reenvio desta obrigação?')) {
      const data: FormData = new FormData();
      data.append('usuarioId', this.dblinkedSessionService.usuario.id.toString());
      data.append('outrosDestinatarios', this.outrosDestinatarios);
      data.append('envioId', this.envioExibir.id.toString());
      data.append('obrigacaoId', this.envioExibir.obrigacao.id.toString());

      this.httpService.wait();
      this.httpService.post('/custom/centralweb/reenviar-obrigacao', data)
        .subscribe(result => {
          this.periodChanged();
          this.messageService.add({
            severity: result.error ? 'error' : 'success',
            detail: 'Obrigação foi reeenviada com sucesso!'
          });
        },
          error => this.httpService.handleError(error, () => this.reEnviarObrigacao()),
          () => this.httpService.done());
    }
  }
  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }
}
