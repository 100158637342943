import { Empresa } from "app/cadastro/model/empresa.model";
import { PlanoContas } from "app/contabil/model/plano-contas.model";
import { AuthUser } from "app/proof/model/auth-user.model";
import { MunicipioIbge } from "app/util/model/municipio-ibge.model";
import { Item } from "app/cadastro/model/item.model";
import { EventosIngressos } from "./eventos-ingressos.model";
import { StatusEvento } from "app/util/model/status-eventos.model";
import { EmpresaConta } from "app/cadastro/model/empresa-conta.model";
import { Seminarista } from "./seminarista.model";
import { EventoEmpresaContaRelac } from "./evento-empresa-conta-relac.model";
import { EventoFormaPgtoRelac } from "./evento-forma-pgto-relac.model";
import { EventosMeioPagamento }  from './eventos-meio-pagamento.model';

export class Eventos {
  id: number;
  empresa: Empresa;
  data: Date;
  changeUser: AuthUser;
  municipioIbge: MunicipioIbge;
  dataInicio: Date;
  dataTermino: Date;
  item: Item;
  eventosIngressos: Array<EventosIngressos>;
  statusEvento: StatusEvento;
  empresaconta: EmpresaConta;
  seminarista: Seminarista;
  eventoEmpresaContaRelac: Array<EventoEmpresaContaRelac>;
  eventoFormaPgtoRelac: Array<EventoFormaPgtoRelac>;
  endereco: string;
  informacoes: string;
  link: string;
  clubeVidaPlena            : Boolean;
  tempoAcessoClubeVidaPlena : number;
  chaveEduzz                : string;
  contrato                  : string;
  eventosMeioPagamento      : EventosMeioPagamento;
  eventosMeioPagamentoId    : number;

  constructor(eventos?: Eventos | number) {
    if (eventos !== undefined && eventos !== null) {
      if (typeof eventos === 'number') {
        this.id = eventos;
      } else {
        this.id = eventos.id;
        this.empresa = eventos.empresa;
        this.data = eventos.data;
        this.changeUser = eventos.changeUser;
        this.municipioIbge = eventos.municipioIbge;
        this.dataInicio = eventos.dataInicio;
        this.dataTermino = eventos.dataTermino;
        this.item = eventos.item;
        this.eventosIngressos = eventos.eventosIngressos;
        this.statusEvento = eventos.statusEvento;
        this.empresaconta = eventos.empresaconta;
        this.seminarista = eventos.seminarista;
        this.eventoEmpresaContaRelac = eventos.eventoEmpresaContaRelac;
        this.eventoFormaPgtoRelac = eventos.eventoFormaPgtoRelac;
        this.endereco = eventos.endereco;
        this.informacoes = eventos.informacoes;
        this.link = eventos.link;
        this.clubeVidaPlena = eventos.clubeVidaPlena;
        this.tempoAcessoClubeVidaPlena = eventos.tempoAcessoClubeVidaPlena;
        this.chaveEduzz = eventos.chaveEduzz;
        this.contrato   = eventos.contrato;
        this.eventosMeioPagamento = eventos.eventosMeioPagamento;
        this.eventosMeioPagamentoId = eventos.eventosMeioPagamentoId;
      }
    }
  }

}