import { isNullOrUndefined } from "util";

export class Formulario{

  id                : number;
  nome              : string;


  constructor(formulario?: Formulario | number) {
    if (!isNullOrUndefined(formulario)) {
      if (typeof formulario === 'number') {
        this.id = formulario;
      } else {
        this.id         = formulario.id;
        this.nome       = formulario.nome;

      }
    }
  }

}