import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Config } from '../../app.config';
import { MenuItem, Message, MessageService, SelectItem } from 'primeng/api';
import { TimezonePipe } from '../../shared/pipes/timezone.pipe';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { FormalizacaoConciliacao } from 'app/contabil/model/formalizacao-conciliacao.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadHistPadrao } from 'app/contabil/model/cad-hist-padrao.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { LancamentosFormalizacao } from 'app/contabil/model/lancamentos-formalizacao.model';
import { TipoLancamento } from 'app/util/model/tipo-lancamento.model';
import { Natureza } from 'app/util/model/natureza.model';
import { Lancamento } from 'app/contabil/model/lancamento.model';
import { LancamentoPartida } from 'app/contabil/model/lancamento-partida.model';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { UtilService } from 'app/shared/services/util.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { MovSaldosContabeis } from 'app/contabil/model/mov-saldos-contabeis.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';

@Component({
  selector: 'app-formalizacao-conciliacao',
  templateUrl: './formalizacao-conciliacao.component.html',
  styleUrls: ['./formalizacao-conciliacao.component.scss'],
  providers: [CrudService]
})
export class FormalizacaoConciliacaoComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;
  public formalizacaoconciliacao: FormalizacaoConciliacao = new FormalizacaoConciliacao();
  formalizacao: FormalizacaoConciliacao = new FormalizacaoConciliacao();
  private timezonePipe: TimezonePipe;
  private empresaSubscription: Subscription;

  entityFilter: string = null;

  private parentNivel = 1;
  centrosCusto: Array<CadCcus> = new Array();
  cadHistPadrao: Array<CadHistPadrao> = new Array();
  cadHist: CadHistPadrao;
  planosContas: Array<PlanoContas> = new Array();
  formalizacoes: Array<FormalizacaoConciliacao> = new Array();
  lancamentosFormalizacao: Array<LancamentosFormalizacao> = new Array();
  newLctoFormalizacao: LancamentosFormalizacao;

  tipoLancamento: Array<TipoLancamento> = new Array();
  natureza: Array<Natureza> = new Array();

  histComplementar: string;


  contaSelecionada: String;

  vlContabil: number;
  vlFinanceiro: number;
  vlRelTitAberto: number;
  vlEstoque: number;
  vlTerceiro: number;
  vlDiferenca: number;
  items: MenuItem[];
  tabIndex: number;

  idSelecionados: Array<any> = new Array();
  idFiltrados: Array<any> = new Array();
  arquivosFormalizacoes: Array<any> = new Array();
  editLancamento: boolean;
  criarLancamento: boolean;
  modalComposicao: boolean;
  existeLog: boolean;
  modalLog: boolean;
  modalAnexo: boolean;
  modalLctoContabil: boolean;
  editarLctoComp: boolean;
  opcoesTipoLancamento: SelectItem[];
  opcoesNatureza: SelectItem[];
  opcoesDebitoCredito: SelectItem[];
  opcoesPlanoContas: SelectItem[];
  opcoesHistPadrao: SelectItem[];
  opcoesCentroCusto: SelectItem[];

  private sessionSubscription: Subscription;
  maxDate: Date;
  minDate: Date;
  defaultDate: Date;

  lancamentosFinanceiro: number;
  lancamentosContabil: number;

  checkPartida: number;
  valorLancamento: number;

  file: File;
  replace: boolean;


  logAdvertencia: Message[] = null;

  planoContas: Array<PlanoContas> = new Array();

  lancamentoContabil = new Lancamento();
  cadEcd = new CadEcd();
  lancamentosPartida: Array<LancamentoPartida> = new Array();
  lancamentoPartida = new LancamentoPartida();
  todasSelecionadas: boolean;
  modalEditarLote: boolean;

  statusAlterar: any;
  logs: Array<any> = new Array();
  title: string;
  subtitle: string;

  acoes: SelectItem[];
  colsformalizacoes :any[] = [];


  constructor(private configService: ConfigService,
    private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private toolbarService: ToolbarService,
    private titleService: TitleService,
    private messageService: MessageService) {
    super(crudService);
  }

  ngOnInit() {
    this.title = ' FORMALIZAÇÕES DE CONCILIAÇÃO';
    this.subtitle = 'INFORMAÇÕES REFERENTES A SALDOS E STATUS DAS FORMALIZAÇÕES NO PERÍODO';
    this.titleService.title = 'Formalizações de Conciliação';
    this.controls = [];
    this.formalizacoes = new Array<FormalizacaoConciliacao>();
    this.centrosCusto = new Array<CadCcus>();
    this.cadHistPadrao = new Array<CadHistPadrao>();
    this.planoContas = new Array<PlanoContas>();
    this.lancamentosFormalizacao = new Array<LancamentosFormalizacao>();
    this.newLctoFormalizacao = new LancamentosFormalizacao();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.lancamentosFinanceiro = 0.00;
    this.lancamentosContabil = 0.00;
    this.cadHist = new CadHistPadrao();
    this.todasSelecionadas = false;
    this.modalEditarLote = false;
    this.statusAlterar = null;
    this.existeLog = false;
    this.modalLog = false;
    this.logs = new Array();
    this.editLancamento = false;
    this.criarLancamento = false;
    this.formalizacao = new FormalizacaoConciliacao();
    this.vlRelTitAberto = 0.00;
    this.vlEstoque = 0.00;
    this.vlTerceiro = 0.00;
    this.tabIndex = 0;

    this.acoes = [];

    this.colsformalizacoes = [
      { field: 'codCta', header: 'CÓDIGO' },
      { field: 'descrCta', header: 'DESCRIÇÃO' },
      { field: 'indCta', header: 'IND' },
      { field: 'nivel', header: 'NÍVEL' },
      { field: 'natConta', header: 'NAT' },
      { field: 'saldoIni', header: 'SALDO INI' },
      { field: 'indDcIni', header: 'D/C' },
      { field: 'debito', header: 'DÉBITO' },
      { field: 'credito', header: 'CRÉDITO' },
      { field: 'saldoFin', header: 'SALDO FIN' },
      { field: 'indDcFin', header: 'D/C' },
      { field: 'diferenca', header: 'DIFERENÇA' },
      { field: 'status', header: 'STATUS' }



  ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.empresaChanged(); });

  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    // this.toolbarService.clear();
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
    }

  empresaChanged() {

    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
        && this.dblinkedSessionService.periodo.year) {

          if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
            const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
              this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
            const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
            this.defaultDate = new Date(dt);
          }

      this.lancamentoContabil = new Lancamento();
      this.lancamentoPartida = new LancamentoPartida();
      this.lancamentosPartida = new Array<LancamentoPartida>();
      this.checkPartida = 0.00;
      this.histComplementar = '';
      this.todasSelecionadas = false;
      this.modalEditarLote = false;
      this.idSelecionados = new Array();
      this.idFiltrados = new Array();
      this.statusAlterar = null;

      this.opcoesTipoLancamento = [];
      this.opcoesTipoLancamento.push({ label: 'Selecione', value: null });
      this.tipoLancamento.forEach(tipo => {
        this.opcoesTipoLancamento.push({ label: tipo.descricao, value: tipo });
      });

      this.opcoesNatureza = [];
      this.opcoesNatureza.push({ label: 'Selecione', value: null });
      this.natureza.forEach(nat => {
        this.opcoesNatureza.push({ label: nat.descricao, value: nat });
      });

      this.opcoesDebitoCredito = [];
      this.opcoesDebitoCredito.push({ label: 'D/C', value: null });
      this.opcoesDebitoCredito.push({ label: 'D', value: 'D' });
      this.opcoesDebitoCredito.push({ label: 'C', value: 'C' });
      this.modalLog = false;

      this.editarLctoComp = false;
      this.arquivosFormalizacoes = new Array<any>();

      this.tipoLancamento = new Array<TipoLancamento>();
      this.natureza = new Array<Natureza>();
      this.httpService.wait();
      this.httpService.get('/natureza?$select=id,cod,descricao')
        .subscribe(natureza => this.natureza = natureza.value,
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/tipolancamento?$select=id,cod,descricao')
        .subscribe(tipoLancamento => this.tipoLancamento = tipoLancamento.value,
        error => this.httpService.handleError(error, () => this.empresaChanged()),
        () => this.httpService.done());

      this.contaSelecionada = '';
      this.modalComposicao = false;
      this.modalAnexo = false;
      this.modalLctoContabil = false;
      this.formalizacao = new FormalizacaoConciliacao();
      this.formalizacao.movSaldosContabeis = new MovSaldosContabeis();
      this.formalizacao.movSaldosContabeis.planoContas = new PlanoContas();
      this.conc();
      this.verificarFormalizacoes();

    }

    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.empresaChanged());
    this.toolbarService.alterarStatus('download', true, 'Fazer upload', () => this.empresaChanged());
    this.toolbarService.alterarStatus('add', true, 'Criar novo', () => this.empresaChanged());
    this.toolbarService.alterarStatus('export', false, 'Exportar Conciliação', () => this.exportarComposicao());
    this.toolbarService.alterarStatus('print', true, 'Imprimir', () => this.imprimirUnico());


  }

  conc() {
    this.httpService.wait();
    this.httpService.get('/custom/conciliacao-report/formalizacao(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.month.value + ',' +
      this.dblinkedSessionService.periodo.year.value + ')')
      .subscribe(formalizacoes => this.loadFormalizacoes(formalizacoes),
      error => this.httpService.handleError(error, () => this.empresaChanged()),
      () => this.httpService.done());
  }

  verificarFormalizacoes() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
        && this.dblinkedSessionService.periodo.year) {
    this.httpService.wait();
    this.httpService.get('/custom/conciliacao/contas-faltantes-conciliacao(' + this.dblinkedSessionService.empresa.id + ',' +
      this.dblinkedSessionService.periodo.month.value + ',' +
      this.dblinkedSessionService.periodo.year.value + ')')
      .subscribe(ctas => this.loadContasFaltantes(ctas),
      error => this.httpService.handleError(error, () => this.empresaChanged()),
      () => this.httpService.done());
        }
  }

  loadContasFaltantes(value: any) {
    if (value.result === 'criou') {
      this.empresaChanged();
    }
  }

  modalEdit() {
    this.modalEditarLote = true;

    this.acoes = [];

    this.acoes.push({ label: 'Selecione a ação', value: null});

    if (this.idFiltrados.length > 0) {
      this.idSelecionados = this.idFiltrados;
    }


    if (this.idSelecionados.findIndex(x => x.status === 'PENDENTE') !== -1) {
      this.acoes.push({ value: 'f', label: 'Formalizar' });
      this.acoes.push({ value: 'na', label: 'N/A' });
    }
    if (this.idSelecionados.findIndex(x => x.status === 'FORMALIZADO') !== -1) {
      this.acoes.push({ value: 'df', label: 'Desfazer Formalização' });
      this.acoes.push({ value: 'h', label: 'Homologar' });
    }
    if (this.idSelecionados.findIndex(x => x.status === 'HOMOLOGADO') !== -1) {
      this.acoes.push({ value: 'dh', label: 'Desfazer Homologação' });
    }
    if (this.idSelecionados.findIndex(x => x.status === 'N/A') !== -1) {
      this.acoes.push({ value: 'df', label: 'Desfazer' });
    }
  }

  modalEditComposicao() {
    this.modalComposicao = true;

  }

  loadCadCcus(value: Array<CadCcus>) {
    this.centrosCusto = value;

    this.opcoesCentroCusto = [];
    this.opcoesCentroCusto.push({ label: 'Selecione', value: null });
    this.centrosCusto.forEach(v => {
      if(v.ativo){
        this.opcoesCentroCusto.push({ label: v.codCcus + ' - ' + v.descrCcus, value: v });
      }

    });
  }

  atualizarValorCheck() {
    this.checkPartida = 0.00;

    this.lancamentosPartida.forEach(pta => {
      if (pta.valor < 0) {
        pta.valor = pta.valor * -1;
      }

      if (pta.indDc === 'C') {
        this.checkPartida = this.checkPartida - this.utilService.parseDecimal(pta.valor);
      } else {
        this.checkPartida = this.checkPartida + this.utilService.parseDecimal(pta.valor);
      }
    });
  }

  excluirPartida(pta: LancamentoPartida) {
    if (confirm('Deseja excluir o registro atual?')) {
      const index = this.lancamentosPartida.findIndex(c => c === pta);
      this.lancamentosPartida.splice(index, 1);

      this.success();
      this.atualizarValorCheck();
    }
  }

  loadCadHist(value: Array<CadHistPadrao>) {
    this.cadHistPadrao = value;

    this.opcoesHistPadrao = [];
    this.opcoesHistPadrao.push({ label: 'Selecione', value: null });
    this.cadHistPadrao.forEach(v => {
      this.opcoesHistPadrao.push({ label: v.codHist + ' - ' + v.descrHist, value: v.id });
    });
  }

  incluirComposicao() {
    this.editarLctoComp = false;
    this.newLctoFormalizacao = new LancamentosFormalizacao();
    this.newLctoFormalizacao.dataLcto = this.maxDate;
    this.modalComposicao = true;
    this.criarLancamento = true;
    this.editLancamento = false;
  }

  incluirPartida() {
    if (this.lancamentoPartida.valor < 0) {
      this.lancamentoPartida.valor = this.lancamentoPartida.valor * -1;
    }

    if (this.cadHist) {
      this.lancamentoPartida.cadHistPadrao = this.cadHist;
    }

    this.lancamentoPartida.histCompl = this.histComplementar;
    this.lancamentosPartida.push(this.lancamentoPartida);
    this.checkPartida = 0.00;

    this.lancamentosPartida.forEach(pta => {
      if (pta.indDc === 'C') {
        this.checkPartida = this.checkPartida - this.utilService.parseDecimal(pta.valor);
      } else {
        this.checkPartida = this.checkPartida + this.utilService.parseDecimal(pta.valor);
      }
    });

    this.lancamentoPartida = new LancamentoPartida();
  }

  loadFormalizacoes(value: Array<any>) {
    this.formalizacoes = value;
  }

  loadPlanoContas(value: Array<PlanoContas>) {
    this.opcoesPlanoContas = [];
    this.planoContas = value;
    this.opcoesPlanoContas.push({ label: 'Selecione', value: null });
    this.planoContas.forEach(pc => {
      if (pc.indCta === 'A') {
        this.opcoesPlanoContas.push({ label: pc.codCta + ' - ' + pc.descrCta, value: pc });
      }
    });

  }

  formalizar() {
    const lista = {
      id: this.formalizacao.id,
      usuario: this.dblinkedSessionService.usuario.id
    };



    this.httpService.wait();
    this.httpService.post('/custom/conciliacao/formalizar', lista)
      .subscribe(result => {
        if (!result[0].error) {
         this.messageService.add({
           severity: 'success',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result[0].message
         });
         this.empresaChanged();
       } else {
         this.messageService.add({
           severity: 'warn',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result[0].message
          });
         this.empresaChanged();
         return;
       }
                       },
      error => this.httpService.handleError(error, () => this.formalizar()),
      () => this.httpService.done());
  }

  homologar() {
    const lista = {
      id: this.formalizacao.id,
      usuario: this.dblinkedSessionService.usuario.id
    };



    this.httpService.wait();
    this.httpService.post('/custom/conciliacao/homologar', lista)
      .subscribe(result => {
        if (!result[0].error) {
         this.messageService.add({
           severity: 'success',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result[0].message
          });
         this.empresaChanged();
       } else {
         this.messageService.add({
           severity: 'warn',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result[0].message
          });
         this.empresaChanged();
         return;
       }
                       },
      error => this.httpService.handleError(error, () => this.formalizar()),
      () => this.httpService.done());
  }

  salvarLcto() {
    this.lancamentoContabil.cadEcd = new CadEcd(this.formalizacao.movSaldosContabeis.cadEcd.id);
    this.lancamentoContabil.cadEcd.empresa = new Empresa(this.dblinkedSessionService.empresa.id);
    this.lancamentoContabil.indLcto = 'N';

    this.lancamentoContabil.lancamentoPartidaList = new Array<any>();

    this.lancamentoContabil.lctoManual = true;

    this.lancamentosPartida.forEach(element => {
      element.lancamento = this.lancamentoContabil;
      const ptaPush = new LancamentoPartida();
      ptaPush.lancamento = this.lancamentoContabil;
      ptaPush.planoContas = new PlanoContas(element.planoContas.id);
      ptaPush.cadHistPadrao.id = element.cadHistPadrao.id;
      ptaPush.indDc = element.indDc;
      ptaPush.histCompl = element.histCompl;
      ptaPush.valor = this.utilService.parseDecimal(element.valor);

      delete ptaPush.cadCcus;
      delete ptaPush.cadHistPadrao;
      delete ptaPush.cadParticipante;

      this.lancamentoContabil.lancamentoPartidaList.push(ptaPush);

    });

    this.lancamentoContabil.ativo = true;

    this.httpService.wait();
    this.httpService.post('/lancamento', this.lancamentoContabil)
      .subscribe(() => this.atualizarSaldo(),
      error => this.httpService.handleError(error, () => this.salvarLcto()),
      () => this.httpService.done());
  }

  atualizarSaldo() {
    this.httpService.wait();
    this.httpService.post('/custom/conciliacao/atualizar-saldo', this.lancamentoContabil)
      .subscribe(result => this.success(),
      error => this.httpService.handleError(error, () => this.atualizarSaldo()),
      () => this.httpService.done());
  }

  abrir() {
    if (confirm('Deseja realmente desfazer esta conciliação?')) {
      const lista = {
        id: this.formalizacao.id,
        usuario: this.dblinkedSessionService.usuario.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/conciliacao/abrir', lista)
        .subscribe(result => this.empresaChanged(),
        error => this.httpService.handleError(error, () => this.abrir()),
        () => this.httpService.done());
    }
  }

  onRowContabil(event: any) {

    if (event.indCta === 'A') {

      window.open('/ecd/report-balancete-mensal-lcto/' + this.dblinkedSessionService.empresa.id + '/' + event.codCta +
        '/' + event.dtIni + '/' + event.dtFin +
        '/balancete' + '/' + false,
        '_blank');
    }
  }

  onRowFinanceiro(event: any) {
    this.newLctoFormalizacao.dataLcto = this.maxDate;

    if (event.indCta === 'A') {
      this.contaSelecionada = event.codCta;

      this.httpService.wait();
      this.httpService.get('/formalizacaoconciliacao?$select=id,movSaldosContabeis,' +
        'movSaldosContabeis/id,movSaldosContabeis/planoContas,' +
        'movSaldosContabeis/planoContas/codCta,formalizado,homologado,vlFinanceiro,' +
        'movSaldosContabeis/vlSldFin,movSaldosContabeis/planoContas/descrCta,' +
        'movSaldosContabeis/cadEcd/id,movSaldosContabeis/cadEcd/dtIni,movSaldosContabeis/cadEcd/dtFin,' +
        'usuarioFormalizado/nome,usuarioFormalizado/sobrenome,usuarioHomologado/nome,' +
        'usuarioHomologado/sobrenome,movSaldosContabeis/cadEcd/status/id,movSaldosContabeis/indDcFin' +
        '&$filter=id eq ' + event.formalizacaoId)
        .subscribe(formalizacao => this.loadFormalizacao(formalizacao),
        error => this.httpService.handleError(error, () => this.onRowFinanceiro(event)),
        () => this.httpService.done());
      this.httpService.wait();
      this.httpService.get('/custom/conciliacao/buscar-log', event.formalizacaoId + ',' + this.dblinkedSessionService.usuario.id)
        .subscribe(formalizacoes => this.listarLogFormalizacao(formalizacoes),
        error => this.httpService.handleError(error, () => this.onRowFinanceiro(event)),
        () => this.httpService.done());



    }
  }

  listarLogFormalizacao(event: any) {
    this.logs = event;
  }

  loadFormalizacao(event: any) {


    this.formalizacao = event.value[0];

    console.log(event);

    this.minDate = new Date(this.formalizacao.movSaldosContabeis.cadEcd.dtIni);
    this.maxDate = new Date(this.formalizacao.movSaldosContabeis.cadEcd.dtFin);

    if (this.formalizacao.movSaldosContabeis.indDcFin === 'D') {
      this.vlContabil = this.formalizacao.movSaldosContabeis.vlSldFin;
    } else {
      this.vlContabil = this.formalizacao.movSaldosContabeis.vlSldFin * - 1;
    }

    this.toolbarService.alterarStatus('atualizar', false, 'Atualizar', () => this.empresaChanged());
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
    this.toolbarService.alterarStatus('export', false, 'Exportar Conciliação', () => this.exportarComposicao());
    this.toolbarService.replace('download', false, 'class-other-button', 'cloud_upload', 'Fazer upload', () => this.incluirAnexo());
    this.toolbarService.replace('cancel', true, 'class-other-button', 'block', 'Cancelar', );
    this.toolbarService.alterarStatus('print', false, 'Imprimir', () => this.imprimirUnico());
    // edit fixo, utilizar alterarStatus()
    this.toolbarService.replace('save', true, 'class-other-button', 'save', 'Voltar', );
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir', );
    //this.toolbarService.replace('edit', false, 'class-edit-button', 'edit', 'Incluir Lançamento', () => this.incluirComposicao());
    // variavel, utilizar replace()
    this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Criar novo', );


    if (this.formalizacao.formalizado || this.formalizacao.homologado) {
        this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.abrir());
        this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Incluir Lançamento', () => this.modalEdit());
        this.toolbarService.alterarStatus('download', true, 'Fazer upload', () => this.empresaChanged());
      }

      if (!this.formalizacao.formalizado && !this.formalizacao.homologado) {
        this.toolbarService.replace('add', false, 'class-new-button', 'FORMALIZAR', 'Formalizar', () => this.formalizar());
      } else if (this.formalizacao.formalizado && !this.formalizacao.homologado) {
      this.toolbarService.replace('add', false, 'class-new-button', 'HOMOLOGAR', 'Homologar', () => this.homologar());
    } else if (this.formalizacao.formalizado && this.formalizacao.homologado) {
      this.toolbarService.replace('add', false, 'class-new-button', 'DESFAZER', 'Desfazer', () => this.abrir());
    }

          this.httpService.wait();
      this.httpService.get('/custom/conciliacao/buscar-lctos-composicao(' + this.formalizacao.id + ')')
        .subscribe(formalizacoes => this.listarLancamentosFormalizacao(formalizacoes),
        error => this.httpService.handleError(error, () => this.success()),
        () => this.httpService.done());

      this.httpService.wait();
    this.httpService.get('/custom/conciliacao/soma-rel-tit-aberto(' + this.formalizacao.id + ')')
      .subscribe(result => this.vlRelTitAberto = result.valorRelFinanceiro,
      error => this.httpService.handleError(error, () => this.loadFormalizacao(event)),
      () => this.httpService.done());

      this.httpService.wait();
    this.httpService.get('/custom/conciliacao/buscar-valor-estoque(' + this.formalizacao.id + ')')
      .subscribe(result => {
        this.vlEstoque = result.valorEstoque
        this.vlTerceiro = result.valorTerceiro},
      error => this.httpService.handleError(error, () => this.loadFormalizacao(event)),
      () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/custom/conciliacao-report/buscar-arquivos', this.formalizacao.id)
      .subscribe(formalizacoes => this.listarArquivosFormalizacao(formalizacoes),
      error => this.httpService.handleError(error, () => this.loadFormalizacao(event)),
      () => this.httpService.done());
  }

  incluirAnexo() {
    this.modalAnexo = true;
  }

  incluirLctoContabil() {
    this.modalLctoContabil = true;

    this.httpService.wait();
    this.httpService.get('/cadccus?$select=id,codCcus,descrCcus,ativo&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
      .subscribe(cadccus => this.loadCadCcus(cadccus.value),
      error => this.httpService.handleError(error, () => this.incluirLctoContabil()),
      () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/cadhistpadrao?$select=id,codHist,descrHist&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
      .subscribe(cadhist => this.loadCadHist(cadhist.value),
      error => this.httpService.handleError(error, () => this.incluirLctoContabil()),
      () => this.httpService.done());


    this.httpService.wait();
    this.httpService.get('/planocontas?$select=id,codCta,descrCta,indCta' +
      '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=codCta')
      .subscribe(plano => this.loadPlanoContas(plano.value),
      error => this.httpService.handleError(error, () => this.incluirLctoContabil()),
      () => this.httpService.done());
  }

  listarArquivosFormalizacao(event: any) {
    event.forEach(element => {
      this.arquivosFormalizacoes.push(element.arquivo);
    });
  }

  imprimir() {

    let ids: Array<number>;
    ids = new Array();

    this.idSelecionados.forEach(id => {
      if (id.formalizacaoId !== undefined && id.selected) {
        ids.push(id.formalizacaoId);
      }
    });

    const lista = {
      id: ids
    };

    this.httpService.wait();
    this.httpService.post('/custom/conciliacao/gerar-pdf-composicao', lista)
      .subscribe(result => this.downloadPdf(result),
      error => this.httpService.handleError(error, () => this.imprimir()),
      () => this.httpService.done());

  }

  exportarComposicao() {

    const lista = {
      mes: this.dblinkedSessionService.periodo.month.value,
      ano: this.dblinkedSessionService.periodo.year.value,
      empresa: this.dblinkedSessionService.empresa.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/conciliacao-report/conciliacao-xls', lista)
      .subscribe(result => this.downloadConc(result),
      error => this.httpService.handleError(error, () => this.exportarComposicao()),
      () => this.httpService.done());
  }

  imprimirUnico() {

    let ids: Array<number>;
    ids = new Array();
    ids.push(this.formalizacao.id);

    const lista = {
      id: ids
    };

    this.httpService.wait();
    this.httpService.post('/custom/conciliacao/gerar-pdf-composicao', lista)
      .subscribe(result => this.downloadPdfUnico(result),
      error => this.httpService.handleError(error, () => this.imprimir()),
      () => this.httpService.done());

  }

  rowStyleClass(planoContas: PlanoContas): string {
    return planoContas.indCta === 'S' ? 'bold' : '';
  }

  salvarLancamentoComposicao() {


      const lista = {
        natureza: this.newLctoFormalizacao.natureza.id,
        tipoLcto: 2,
        valor:  this.utilService.parseDecimal(this.newLctoFormalizacao.valor),
        usuario: this.sessionService.loggedUser.id,
        formalizacaoId: this.formalizacao.id,
        data: this.newLctoFormalizacao.dataLcto,
        descricao: this.newLctoFormalizacao.descricao,
        documento: this.newLctoFormalizacao.documento,
        obs: this.newLctoFormalizacao.obs,
        planoContasId: this.formalizacao.movSaldosContabeis.planoContas.id,
        mes: this.dblinkedSessionService.periodo.month.value,
        ano: this.dblinkedSessionService.periodo.year.value
      };

      this.httpService.wait();
    this.httpService.post('/custom/conciliacao/salvar-lcto-composicao', lista)
      .subscribe(() => this.success(),
      error => this.httpService.handleError(error, () => this.salvarLancamentoComposicao()),
      () => this.httpService.done());

  }

  success() {
    this.modalLctoContabil = false;
    this.modalComposicao = false;
    this.modalAnexo = false;
    this.arquivosFormalizacoes = new Array<any>();
    this.file = null;
    this.newLctoFormalizacao = new LancamentosFormalizacao();
    this.contaSelecionada = '';


          this.httpService.wait();
    this.httpService.get('/custom/conciliacao/buscar-lctos-composicao(' + this.formalizacao.id + ')')
      .subscribe(formalizacoes => this.listarLancamentosFormalizacao(formalizacoes),
      error => this.httpService.handleError(error, () => this.success()),
      () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/custom/conciliacao-report/buscar-arquivos(' + this.formalizacao.id + ')')
      .subscribe(formalizacoes => this.listarArquivosFormalizacao(formalizacoes),
      error => this.httpService.handleError(error, () => this.success()),
      () => this.httpService.done());
  }

  deletarLctoComposicao(event: any) {

    if (confirm('Deseja excluir o registro atual?')) {
      this.httpService.wait();
      this.httpService.post('/custom/conciliacao/deletar-lcto-composicao(' + event.id + ','
      + this.formalizacao.movSaldosContabeis.cadEcd.id + ')')
                      .subscribe(result => this.success(),
                                 error => this.httpService.handleError(error, () => this.deletarLctoComposicao(event)),
                                 () => this.httpService.done());
    }
  }

  editarLctoComposicao(event: any) {
    this.modalComposicao = true;
    this.editLancamento = true;
    this.criarLancamento = false;

    this.httpService.wait();
    this.httpService.get('/lancamentosformalizacao?$select=id,dataLcto,descricao,tipoLancamento/cod,natureza/descricao,natureza/cod,valor' +
      ',dataFim,tipoLancamento,natureza,documento,obs&$filter=id eq ' + event.id)
      .subscribe(lancamentosFormalizacao => this.loadLancamentoFormalizacao(lancamentosFormalizacao.value),
      error => this.httpService.handleError(error, () => this.success()),
      () => this.httpService.done());
  }

  loadLancamentoFormalizacao(event: Array<LancamentosFormalizacao>) {
    this.editarLctoComp = true;
    this.newLctoFormalizacao = new LancamentosFormalizacao();
    this.newLctoFormalizacao = event[0];
    this.newLctoFormalizacao.dataLcto = new Date(event[0].dataLcto);

    if (this.newLctoFormalizacao.dataFim !== null) {
      this.newLctoFormalizacao.dataFim = new Date(event[0].dataFim);
    } else {
      this.newLctoFormalizacao.dataFim = null;
    }
  }

  listarLancamentosFormalizacao(event: any) {
    this.opcoesTipoLancamento = [];
    this.opcoesTipoLancamento.push({ label: 'Selecione', value: null });
    this.tipoLancamento.forEach(tipo => {
      this.opcoesTipoLancamento.push({ label: tipo.descricao, value: tipo });
    });

    this.opcoesNatureza = [];
    this.opcoesNatureza.push({ label: 'Selecione', value: null });
    this.natureza.forEach(nat => {
      this.opcoesNatureza.push({ label: nat.descricao, value: nat });
    });

    this.lancamentosFormalizacao = new Array();
    this.lancamentosFinanceiro = 0.00;
    this.lancamentosContabil = 0.00;
    event.forEach(element => {

      if (element.codigoNatureza === 'D') {
        element.valor = element.valor;
      } else {
        element.valor = element.valor * -1;
      }

        this.lancamentosFormalizacao.push(element);
        if (element.codTipoLcto === 'FIN') {
            this.lancamentosFinanceiro = this.lancamentosFinanceiro + element.valor;
        } else {
          if (element.codigoNatureza === 'D') {
            this.lancamentosContabil = this.lancamentosContabil + element.valor;
          } else {
            this.lancamentosContabil = this.lancamentosContabil - element.valor;
          }
        }
    });
  }

  atualizarLancamentoComposicao() {
    const lista = {
      id: this.newLctoFormalizacao.id,
      data: this.newLctoFormalizacao.dataLcto,
      descricao: this.newLctoFormalizacao.descricao,
      natureza: this.newLctoFormalizacao.natureza.id,
      tipoLcto: this.newLctoFormalizacao.tipoLancamento.id,
      valor:  this.utilService.parseDecimal(this.newLctoFormalizacao.valor),
      dataFim: this.newLctoFormalizacao.dataFim,
      usuario: this.dblinkedSessionService.usuario.id,
      mes: this.dblinkedSessionService.periodo.month.value,
      ano: this.dblinkedSessionService.periodo.year.value,
      empresa: this.dblinkedSessionService.empresa.id,
      obs: this.newLctoFormalizacao.obs,
      documento: this.newLctoFormalizacao.documento
    };

    this.httpService.wait();
    this.httpService.post('/custom/conciliacao/atualizar-lcto-composicao', lista)
      .subscribe(result => {
        console.log(result);
        if (!result[0].error) {
         this.messageService.add({
           severity: 'success',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result[0].message
          });
         this.editarLctoComp = false;
         this.modalComposicao = false;
         this.success();
       } else {
         this.messageService.add({
           severity: 'warn',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result[0].message
          });
         this.editarLctoComp = false;
         this.modalComposicao = false;
         this.success();
       }
                       },
      error => this.httpService.handleError(error, () => this.atualizarLancamentoComposicao()),
      () => this.httpService.done());
  }

  private clone(object: any) {
    if (object === undefined || object === null) {
      return object;
    }

    return JSON.parse(JSON.stringify(object));
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
    this.replace = false;

    this.importar();
  }

  importar() {
    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('type', 'conciliacao');
    data.append('replace', this.replace ? 'true' : 'false');
    data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
    data.append('formalizacaoId', this.formalizacao.id.toString());

    this.httpService.wait();
    this.httpService.post('/custom/upload', data)
      .subscribe(result => this.uploaded(result),
      error => this.httpService.handleError(error, () => this.importar()),
      () => this.httpService.done());
  }

  private uploaded(result?: any) {
    this.messageService.add({
      severity: result.error ? 'error' : 'success',
      detail: result.message || 'Arquivo importado com sucesso!'
    });
    this.success();
  }

  downloadArquivo(event: any) {
    const data: FormData = new FormData();
    data.append('formalizacaoId', this.formalizacao.id.toString());
    data.append('arquivo', event);

    this.httpService.wait();
    this.httpService.post('/custom/conciliacao-report/export', data)
      .subscribe(result => this.download(result),
      error => this.httpService.handleError(error, () => this.downloadArquivo(event)),
      () => this.httpService.done());
  }

  download(result: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/' + this.formalizacao.id + '/conc'), '_blank');
    }
  }

  downloadConc(result: any) {
    if (!result.error) {
    window.open(this.httpService.url('/custom/file/' + result.file + '/concxls'), '_blank');
    }
  }

  downloadPdf(result: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/' +
      this.dblinkedSessionService.empresa.id + '/' +
      this.dblinkedSessionService.periodo.month.value + '/' +
      this.dblinkedSessionService.periodo.year.value + '/concpdf'), '_blank');
    }
  }

  downloadPdfUnico(result: any) {
    if (!result.error) {
      console.log(result);
      window.open(this.httpService.url('/custom/file/' + result.file + '/' + this.formalizacao.id + '/concpdfunico'), '_blank');
    }
  }

  excluirArquivo(event: any) {
    if (confirm('Deseja realmente excluir este arquivo?')) {
      const data: FormData = new FormData();
      data.append('formalizacaoId', this.formalizacao.id.toString());
      data.append('arquivo', event);

      this.httpService.wait();
      this.httpService.post('/custom/conciliacao-report/delete-arquivo-formalizacao', data)
        .subscribe(result => this.success(),
        error => this.httpService.handleError(error, () => this.excluirArquivo(event)),
        () => this.httpService.done());
    }
  }

  hideMessage() {
    this.file = null;




    this.existeLog = false;
  }

  windowOpen(url: string, target?: string) {
    window.open('/tmp/', target);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get importDisabled(): boolean {
    return !this.dblinkedSessionService.hasEmpresaSelected || !this.file;
  }


  check(form: any) {

    this.todasSelecionadas = false;

    const index = this.idSelecionados.findIndex(c => c === form);

    if (form.selected === true) {
      form.selected = false;
      this.idSelecionados.splice(index, 1);
    } else {
      form.selected = true;
      this.idSelecionados.push(form);
    }


    if (this.idSelecionados.length > 0) {
      if (this.idSelecionados.length > 1) {
        this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
        this.toolbarService.alterarStatus('print', false, 'Imprimir', () => this.imprimir());
        this.toolbarService.alterarStatus('remove', true, 'Voltar', () => this.modalEdit());
        this.toolbarService.alterarStatus('edit', false, 'Editar Status da Formalização', () => this.modalEdit());
        this.toolbarService.alterarStatus('export', false, 'Exportar Conciliação', () => this.exportarComposicao());
        this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Adicionar', () => this.modalEdit());
      } else {
        this.idSelecionados.forEach(element => {
          this.formalizacao.id = element.formalizacaoId;
        });
        this.toolbarService.alterarStatus('print', false, 'Imprimir', () => this.imprimirUnico());
      }
    } else {
        this.toolbarService.alterarStatus('export', false, 'Exportar Conciliação', () => this.exportarComposicao());
        this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
        this.toolbarService.alterarStatus('edit', true, 'Editar Status da Formalização', () => this.modalEdit());
        this.toolbarService.alterarStatus('print', true, 'Imprimir', () => this.imprimirUnico());

    }

  }

  onSelectAll(event: any) {
    this.acoes = [];
    // this.toolbarService.clear();
    if (event.checked === true) {
      this.todasSelecionadas = true;
      this.formalizacoes.forEach(form => {

          form.selected = true;
          this.idSelecionados.push(form);

      });

    } else {
      this.todasSelecionadas = false;
      this.formalizacoes.forEach(form => {
        form.selected = false;
        this.idSelecionados = new Array();
      });
    }


    if (this.idSelecionados.length > 0) {
      if (this.idSelecionados.length > 0) {
        this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
        this.toolbarService.alterarStatus('print', false, 'Imprimir', () => this.imprimir());
        this.toolbarService.alterarStatus('remove', true, 'Voltar', () => this.modalEdit());
        this.toolbarService.alterarStatus('export', false, 'Exportar', () => this.exportarComposicao());
        this.toolbarService.alterarStatus('edit', false, 'Editar Status da Formalização', () => this.modalEdit());
        this.toolbarService.replace('add', true, 'class-new-button', 'NOVO', 'Adicionar', () => this.modalEdit());

        /*
        /*this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar', () => this.modalEdit());
        this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir', () => this.modalEdit());
        this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.modalEdit());
        this.toolbarService.add('save', false, 'class-edit-button', 'edit', 'Editar Status da Formalização', () => this.modalEdit());
        this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar', () => this.modalEdit());*/
      }
    } else {
      this.toolbarService.alterarStatus('export', false, 'Exportar', () => this.exportarComposicao());
      this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
        this.toolbarService.alterarStatus('edit', true, 'Editar Status da Formalização', () => this.modalEdit());
      }
  }

  salvarAlteracao() {

    let ids: Array<number>;
    ids = new Array();

    let idsFiltrados: Array<number>;
    idsFiltrados = new Array();

    this.idSelecionados.forEach(id => {
      if (id.formalizacaoId !== undefined && id.selected) {
        ids.push(id.formalizacaoId);
      }
    });

    this.idFiltrados.forEach(id => {
      if (id.formalizacaoId !== undefined && id.selected) {
        idsFiltrados.push(id.formalizacaoId);
      }
    });


    const lista = {
      idSelecionados: ids,
      usuario: this.dblinkedSessionService.usuario.id,
      acao: this.statusAlterar
    };

    this.httpService.wait();
    this.httpService.post('/custom/conciliacao/formalizar-lote', lista)
      .subscribe(result => this.resultadoLote(result),
      error => this.httpService.handleError(error, () => this.formalizar()),
      () => this.httpService.done());
  }

  private resultadoLote(result?: any) {





    result.forEach(element => {
      if (element.error) {
        this.logAdvertencia.push({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência:', detail: element.message });

        this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência:',
                                   detail: 'Ocorreram erros que impossibilitaram a ação, verifique os detalhes no log' });
        this.toolbarService.alterarStatus('msg', false, 'Visualizar Log', () => this.abreModalLog());
      } else {
        this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso:', detail: element.message });
      }
    });


    this.empresaChanged();

  }

  disabledAlteracaoLote() {
    if ((!this.statusAlterar) || ((this.statusAlterar === 'h' || this.statusAlterar === 'dh') &&
        this.dblinkedSessionService.usuario.nivel < 3)) {
      return true;
    }
  }


  onFilter(event: any) {
    console.log(event);
    this.acoes = [];
    this.idFiltrados = new Array();

    event.filteredValue.forEach(element => {
      this.idFiltrados.push(element);
    });
  }

  abreModalLog() {
    this.modalLog = true;

  }

  voltar(){
    this.contaSelecionada = '';
  }

  tabChange(event: any) {
   this.tabIndex = event.index;
   }

}
