export class OperadoresComparacao {
  id: number;
  name: string;
  alias: string;

  constructor(operadoresComparacao?: OperadoresComparacao | number) {
    if (operadoresComparacao !== undefined && operadoresComparacao !== null) {
      if (typeof operadoresComparacao === 'number') {
        this.id = operadoresComparacao;
      } else {
        this.id = operadoresComparacao.id;
        this.name = operadoresComparacao.name;
        this.alias = operadoresComparacao.alias;
      }
    }
  }
}
