import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { Departamento } from 'app/util/model/departamento.model';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Envio } from 'app/centralweb/model/envio.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';

export class Obrigacao {
  id: number;
  tipoArquivo: TipoArquivo;
  codigosObrigacoes: CodigosObrigacoes;
  recorrencia: Recorrencia;
  departamento: Departamento;
  empresa: Empresa;
  dtVencto: Date;
  usuario: Usuario;
  authUserInclusao: AuthUser;
  dtHrInclusao: Date;
  antecipaDataNaoUtil: boolean;
  envioList: Array<Envio>;
  gatilhoObrigacao: GatilhoObrigacao;
  diaMes: number;
  repeteCada: number;
  dtInicial: Date;
  dtFinal: Date;
  ocorrencias: number;
  diaUtil: boolean;
  seg: boolean;
  ter: boolean;
  qua: boolean;
  qui: boolean;
  sex: boolean;
  sab: boolean;
  dom: boolean;


constructor(obrigacao?: Obrigacao | number) {
    if (obrigacao !== undefined && obrigacao !== null) {
      if (typeof obrigacao === 'number') {
        this.id = obrigacao;
      } else {
        this.id = obrigacao.id;
        this.tipoArquivo = obrigacao.tipoArquivo;
        this.codigosObrigacoes = obrigacao.codigosObrigacoes;
        this.recorrencia = obrigacao.recorrencia;
        this.departamento = obrigacao.departamento;
        this.empresa = obrigacao.empresa;
        this.dtVencto = obrigacao.dtVencto;
        this.usuario = obrigacao.usuario;
        this.authUserInclusao = obrigacao.authUserInclusao;
        this.dtHrInclusao =  obrigacao.dtHrInclusao;
        this.antecipaDataNaoUtil  =  obrigacao.antecipaDataNaoUtil;
        this.envioList = obrigacao.envioList;
        this.gatilhoObrigacao = obrigacao.gatilhoObrigacao;
        this.diaMes = obrigacao.diaMes;
        this.repeteCada = obrigacao.repeteCada;
        this.dtInicial = obrigacao.dtInicial;
        this.dtFinal = obrigacao.dtFinal;
        this.ocorrencias = obrigacao.ocorrencias;
        this.diaUtil = obrigacao.diaUtil;
        this.seg = obrigacao.seg;
        this.ter = obrigacao.ter;
        this.qua = obrigacao.qua;
        this.qui = obrigacao.qui;
        this.sex = obrigacao.sex;
        this.sab = obrigacao.sab;
        this.dom = obrigacao.dom;
      }
    }
  }
}
