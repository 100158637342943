import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { Pedido } from 'app/financeiro/model/pedido.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { TipoBaixa } from 'app/util/model/tipo-baixa.model';

export class PedidoParcela {
  id: number;
  pedido: Pedido;
  parcela: number;
  dtVencimento: Date;
  valor: number;
  valorBrl: number;
  historico: String;
  tipoBaixa = new TipoBaixa;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;
  empresaConta: EmpresaConta = new EmpresaConta
  calendarState: boolean = false
  tipoPagamentoId: number
  tipoPagamentoDesc: string



  constructor(pedidoParcela?: PedidoParcela | number) {
    if (pedidoParcela !== undefined && pedidoParcela !== null) {
      if (typeof pedidoParcela === 'number') {
        this.id = pedidoParcela;
      } else {
        this.id = pedidoParcela.id;
        this.pedido = pedidoParcela.pedido;
        this.parcela = pedidoParcela.parcela;
        this.dtVencimento = pedidoParcela.dtVencimento;
        this.valor = pedidoParcela.valor;
        this.valorBrl = pedidoParcela.valorBrl
        this.historico = pedidoParcela.historico;
        this.tipoBaixa = pedidoParcela.tipoBaixa;
        this.createTimestamp = pedidoParcela.createTimestamp;
        this.changeUser = pedidoParcela.changeUser;
        this.changeTimestamp = pedidoParcela.changeTimestamp;
        this.empresaConta = pedidoParcela.empresaConta
        this.calendarState = pedidoParcela.calendarState
        this.tipoPagamentoId = pedidoParcela.tipoPagamentoId
        this.tipoPagamentoDesc = pedidoParcela.tipoPagamentoDesc
      }
    }
  }
}
