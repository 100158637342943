import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpService } from 'app/shared/services/http.service'
import { SessionService } from 'app/shared/services/session.service'
import { SidebarService } from 'app/shared/services/sidebar.service'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { UtilService } from 'app/shared/services/util.service'
import { PysocketService } from 'app/shared/services/socket/pysocket.service'

import { RequisicaoMaterialComponent } from '../requisicao-material.component'

import { MessageService, SelectItem } from 'primeng/api'
import { FileUpload } from 'primeng/fileupload'

import { Service } from 'app/shared/services/socket/protocol/Service'
import { Action } from 'app/shared/services/socket/protocol/Action'
import { Method } from 'app/shared/services/socket/protocol/Method'
import { Util } from 'app/shared/common/util'
import idv4 from 'app/util/processing/idv4'
import deepCopy from 'app/util/processing/deepcopy'

import { Toolbar } from 'app/shared/model/toolbar.model'
import { PedidoParcela } from 'app/financeiro/model/pedido-parcela.model'
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model'
import { RequisicaoFornecedorSugerido } from 'app/rms/model/requisicao-fornecedor-sugerido.model'
import { RequisicaoItem } from 'app/rms/model/requisicao-item.model'
import { Requisicao } from 'app/rms/model/requisicao.model'
import { RequisicaoFinanceiro } from 'app/rms/model/requisicao-financeiro.model'
import { RequisicaoItemRateio } from 'app/rms/model/requisicao-item-rateio.model'
import { Item } from 'app/cadastro/model/item.model'

import {
  resetRequisition,
  resetRequisitionItem,
  resetRequisitionItemApportionment,
  resetSuggestedSupplier,
  resetFinancialRequisition,
  resetInstallmentOrder
} from '../utils/reset-form'

@Component({
  selector: 'app-form-material-request[isRender][isGestor][_empresaId][_matrizId][_userId][isStartingSave][isEditRMS]',
  templateUrl: './form-material-request.component.html',
  styleUrls: ['./form-material-request.component.scss']
})
export class FormMaterialRequestComponent extends RequisicaoMaterialComponent implements OnInit, OnChanges {

  @Input() isRender: boolean
  @Input() isGestor: boolean
  @Input() _empresaId: number
  @Input() _matrizId: number
  @Input() _userId: number
  @Input() isStartingSave: boolean
  @Input() isStartingEdit: boolean
  @Input() isEditRMS: boolean
  @Input() selectedRMS: Object

  @Output() isFinishedSave = new EventEmitter()
  @Output() isFinishedEdit = new EventEmitter()
  @Output() isFormLoading = new EventEmitter()

  requisition: Requisicao = resetRequisition()
  requisitionItem: RequisicaoItem = resetRequisitionItem()
  itemSelected: RequisicaoItem = resetRequisitionItem()
  financialRequisition: RequisicaoFinanceiro = resetFinancialRequisition()
  apportionmentSelected: RequisicaoItemRateio = resetRequisitionItemApportionment()
  suggestedSupplier: RequisicaoFornecedorSugerido = resetSuggestedSupplier()
  providerSelected: RequisicaoFornecedorSugerido = resetSuggestedSupplier()
  installmentOrder: PedidoParcela = resetInstallmentOrder()

  listProviders: Object = new Object()
  requisicaoEdit: Object = new Object()
  requisicaoFinanceiroEdit: Object = new Object()
  rateioEdit: Array<any> = new Array()

  optTypesRequisition: Array<any> = new Array()
  optPaymentForms: Array<any> = new Array()
  optionsKeyCurrency: Array<any> = new Array()
  optObjectsRequisition: Array<any> = new Array()
  optProjectCostCenterRatio: Array<any> = new Array()
  toolbarItemsIni: Array<any> = new Array()
  toolbarApportionmentIni: Array<any> = new Array()
  toolbarProviderIni: Array<any> = new Array()
  files: Array<any> = new Array()

  optCategoriesRequisition: SelectItem[] = []
  optOriginsRequisition: SelectItem[] = []
  optNdasRequisition: SelectItem[] = []
  optPrioritiesRequisition: SelectItem[] = []
  optCurrenciesRequisition: SelectItem[] = []
  optCostCenter: SelectItem[] = []
  optProjects: SelectItem[] = []
  copyOptProjects: SelectItem[] = []
  optOperations: SelectItem[] = []
  optCompanyAccounts: SelectItem[] = []
  optPaymentTypes: SelectItem[] = []
  optItems: SelectItem[] = []
  optNCM: SelectItem[] = []
  optTypesItem: SelectItem[] = []
  optTipis: SelectItem[] = []
  optProviders: SelectItem[] = []
  optLowTypes: SelectItem[] = []

  loadingOptTypesRequisition: boolean = true
  loadingOptObjectsRequisition: boolean = true
  loadingOptCategoriesRequisition: boolean = true
  loadingOptOriginsRequisition: boolean = true
  loadingOptNdasRequisition: boolean = true
  loadingOptPrioritiesRequisition: boolean = true
  loadingOptCurrenciesRequisition: boolean = true
  loadingOptCostCenter: boolean = true
  loadingOptProjects: boolean = true
  loadingOptOperations: boolean = true
  loadingOptCompanyAccounts: boolean = true
  loadingOptPaymentForms: boolean = true
  loadingOptItems: boolean = true
  loadingOptNCM: boolean = true
  loadingOptTypesItem: boolean = true
  loadingOptTipis: boolean = true
  loadingOptProviders: boolean = true
  loadingOptLowTypes: boolean = true
  loadingIconOptTypesRequisition: boolean = false
  loadingIconOptObjectsRequisition: boolean = false
  loadingIconOptCategoriesRequisition: boolean = false
  loadingIconOptOriginsRequisition: boolean = false
  loadingIconOptNdasRequisition: boolean = false
  loadingIconOptPrioritiesRequisition: boolean = false
  loadingIconOptCurrenciesRequisition: boolean = false
  loadingIconOptCostCenter: boolean = false
  loadingIconOptProjects: boolean = false
  loadingIconOptOperations: boolean = false
  loadingIconOptCompanyAccounts: boolean = false
  loadingIconOptPaymentForms: boolean = false
  loadingIconOptItems: boolean = false
  loadingIconOptNCM: boolean = false
  loadingIconOptTypesItem: boolean = false
  loadingIconOptTipis: boolean = false
  loadingIconOptProviders: boolean = false
  loadingIconOptLowTypes: boolean = false
  allowsOtherCurrencies: boolean = false
  foreignRequisition: boolean = false
  isFinancial: boolean = false
  loadingRate: boolean = false
  isTransfer: boolean = false
  isStarling: boolean = false
  isProvider: boolean = true
  showModalItem: boolean = false
  showModalProvider: boolean = false
  isMandatoryOperation: boolean = false
  isMandatoryApportionment: boolean = false
  isMandatoryBankInformation: boolean = false
  loadingItemInformations: boolean = false
  isEditItem: boolean = false
  isSupplierAlreadyRegistered: boolean = true
  isEditProvider: boolean = false
  uploaderState: boolean = false
  exactInstallments: boolean = false
  isFetchParcel: boolean = false
  showModalPaymentForm: boolean = false
  isLoadingForm: boolean = false
  isShowApproval: boolean = false
  isSaveAndApprove: boolean = false
  isAllowsChangingBank: boolean = true
  allowChangeAccount: boolean

  rateId: number
  rateRequestId: number
  typeRequisitionId: number
  objectRequisitionId: number
  totalRequisitionValue: number = 0
  totalRequesitionPercentageApportionment: number = 0
  totalRequisitionInstallments: number = 0
  countProviders: number = 0
  maximumInstallments: number
  numberOfInstallments: number = null
  totalAmountInstallments: number = 0
  requisitionSelectedTodoCardId: number = null
  defaultAccountCompany: number

  firstSalary: Date = new Date()

  messageLoading: string = null

  @ViewChild('fileUploader') uploader: FileUpload

  public toolbarItems: Toolbar = new Toolbar()
  public toolbarApportionment: Toolbar = new Toolbar()
  public toolbarProvider: Toolbar = new Toolbar()

  constructor(
    private utilService: UtilService,
    public toolbarService: ToolbarService,
    public dblinkedSessionService: DblinkedSessionService,
    public sessionService: SessionService,
    public httpService: HttpService,
    public configService: ConfigService,
    public sidebarService: SidebarService,
    public messageService: MessageService,
    public socketService: PysocketService,
  ) { super(toolbarService, dblinkedSessionService, sessionService, httpService, configService, sidebarService, messageService, socketService) }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isRender) {
      this.resetForm()
      this.resetToolbar()

      if (changes.isRender.currentValue) {
        this.getOptCategoriesRequisition()
        this.getOptOriginsRequisition()
        this.getOptNdasRequisition()
        this.getOptPrioritiesRequisition()
        this.getDefaultCompanyAccount()

        if ((changes.isEditRMS && changes.isEditRMS.currentValue) && (changes.selectedRMS && changes.selectedRMS.currentValue)) this.getRequisitionForEdition(this.selectedRMS)
      } else {
        this.optObjectsRequisition = new Array()
        this.loadingOptObjectsRequisition = true
        this.loadingIconOptObjectsRequisition = false

        this.optCategoriesRequisition = new Array()
        this.loadingIconOptCategoriesRequisition = true
        this.loadingIconOptCategoriesRequisition = false

        this.optOriginsRequisition = new Array()
        this.loadingOptOriginsRequisition = true
        this.loadingIconOptOriginsRequisition = false

        this.optNdasRequisition = new Array()
        this.loadingOptNdasRequisition = true
        this.loadingIconOptNdasRequisition = false

        this.optPrioritiesRequisition = new Array()
        this.loadingOptPrioritiesRequisition = true
        this.loadingIconOptPrioritiesRequisition = false

        this.optOperations = new Array()
        this.loadingOptOperations = true
        this.loadingIconOptOperations = false

        this.optProviders = new Array()
        this.loadingOptProviders = true
        this.loadingIconOptProviders = false

        this.files = new Array()
      }
    }

    if (changes._empresaId && changes._empresaId.currentValue) {
      this.getFinancialParameters()
      this.getRmsParameter()
      this.getOptTypesRequisition()
      this.getOptCurrenciesRequisition()
      this.getOptCostCenter()
      this.getOptProjects()
      this.getOptProjectCostCenterRatio()
      this.getOptCompanyAccounts()
      this.getOptPaymentTypes()
      this.getOptTypesItem()
      this.getOptTipis()
      this.getOptLowTypes()
    }

    if (changes.isStartingSave && changes.isStartingSave.currentValue) {
      this.saveRMS()
    }

    if (changes.isStartingEdit && changes.isStartingEdit.currentValue) {
      this.updateRMS()
    }
  }

  private resetForm(): void {
    this.requisition = resetRequisition()
    this.requisitionItem = resetRequisitionItem()
    this.financialRequisition = resetFinancialRequisition()
    this.suggestedSupplier = resetSuggestedSupplier()

    this.itemSelected = null

    this.foreignRequisition = false
    this.isFinancial = false
    this.isTransfer = false
    this.isStarling = false
    this.isShowApproval = true

    this.totalRequisitionValue = 0
    this.totalRequesitionPercentageApportionment = 0
    this.countProviders = 0
    this.numberOfInstallments = 0
  }

  private resetToolbar(): void {
    this.toolbarItemsIni = [
      { key: 'btn-delete', icon: 'delete', text: 'Deletar', tooltip: 'Deletar item', visible: false, disabled: false, color: 'red', onClick: () => this.removeItemInRequisition() },
      { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar item', visible: false, disabled: false, color: 'default', onClick: () => this.openingModalItem(true) },
      { key: 'btn-add', icon: 'add', text: 'Adicionar', tooltip: 'Adicionar', visible: true, disabled: false, color: 'green', onClick: () => this.openingModalItem(false) }
    ]

    this.toolbarApportionmentIni = [
      { key: 'btn-delete', icon: 'delete', text: 'Deletar', tooltip: 'Deletar', visible: false, disabled: false, color: 'red', onClick: () => this.removeApportionmentInItem() },
      { key: 'btn-add', icon: 'add', text: 'Adicionar', tooltip: 'Adicionar', visible: false, disabled: false, color: 'green', onClick: () => this.addApportionmentInItem() },
      { key: 'btn-copy', icon: 'content_copy', text: 'Replicar', tooltip: 'Replicar rateio', visible: false, disabled: false, color: 'default', onClick: () => this.copyApportionmentInItems() },
    ]

    this.toolbarProviderIni = [
      { key: 'btn-delete', icon: 'delete', text: 'Deletar', tooltip: 'Deletar', visible: false, disabled: false, color: 'red', onClick: () => this.removeProviderInRequisition() },
      { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openingModalProvider(true) },
      { key: 'btn-add', icon: 'add', text: 'Adicionar', tooltip: 'Adicionar', visible: true, disabled: false, color: 'green', onClick: () => this.openingModalProvider(false) }
    ]

    this.toolbarItems.setAll(this.toolbarItemsIni)
    this.toolbarApportionment.setAll(this.toolbarApportionmentIni)
    this.toolbarProvider.setAll(this.toolbarProviderIni)
  }

  public async saveRMS(): Promise<void> {
    this.isFinishedSave.emit({ finished: false })
    this.requisitionValidation()
    this.isFormLoading.emit(true)

    this.requisition.dtEntrega = this.utilService.dateWithoutTimeZone(this.requisition.dtEntrega.toDateString())

    const payload = deepCopy({
      requisicao: this.requisition,
      requisicaoFinanceiro: this.financialRequisition,
      rateio: this.isFinancial ? this.requisitionItem.requisicaoItemRateios : [],
      empresa: this._empresaId,
      usuario: this.sessionService.loggedUser.id,
      isSalvareAprovar: this.isSaveAndApprove,
      isFinancial: this.isFinancial
    })

    this.isLoadingForm = true
    this.messageLoading = 'Salvando sua nova requisição!'

    try {
      const response = await this.httpService.post('/custom/rms/rms-salvar', payload).toPromise()

      let promise = new Promise(async (resolve) => {
        if (!response.error) {
          const payload = {
            service: Service.APPROVAL_CENTER,
            action: Action.RMS,
            method: Method.POST,
            content: {
              userId: this._userId,
              cardId: response.todoCardId,
              userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
            }
          }

          if (this.isSaveAndApprove) await this.createApprovalCard(response.reqId)

          this.socketService.send(payload)
          this.saveFile(response.todoCardId)

          resolve(true)
        } else this.showResponse('error', response.message)
      })

      promise.then(res => this.isFinishedSave.emit({ finished: true, message: response.message }))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      if (!this.isSaveAndApprove) this.isLoadingForm = false, this.messageLoading = null, this.isFormLoading.emit(false)
    }
  }

  private async getRequisitionForEdition(selectedRMS: any): Promise<void> {
    this.isLoadingForm = true
    this.isFormLoading.emit(true)
    this.messageLoading = 'Trazendo informações da sua requisição!'

    try {
      this.requisitionSelectedTodoCardId = selectedRMS.todoCardId
      this.requisition.id = selectedRMS.id
      this.requisition.titulo = selectedRMS.titulo
      this.requisition.dtEntrega = selectedRMS.dtEntregaStr
      this.requisition.tipo.id = selectedRMS.tipo
      this.requisition.objeto.id = selectedRMS.objeto
      this.requisition.categoria.id = selectedRMS.categoria
      this.requisition.origem.id = selectedRMS.origem
      this.requisition.nda.id = selectedRMS.nda
      this.requisition.prioridade.id = selectedRMS.prioridade
      this.requisition.moeda.id = selectedRMS.moeda
      this.requisition.txMoeda = selectedRMS.txMoeda
      this.requisition.descricao = selectedRMS.descricao
      this.requisition.tipoPagamento = selectedRMS.tipoPagamento
      this.requisition.formaPagamento = selectedRMS.formaPagamento
      this.requisition.necessidade = selectedRMS.necessidade
      this.requisition.resultadoEsperado = selectedRMS.resultadoEsperado
      this.requisition.compraDirigida = selectedRMS.compraDirigida
      if (selectedRMS.empresaConta) this.requisition.empresaConta.id = selectedRMS.empresaConta.id
      this.requisition.expresso = selectedRMS.expresso ? selectedRMS.expresso : false
      this.foreignRequisition = this.requisition.moeda.id !== 1
      this.isFinancial = ['Pagamento', 'Recebimento'].includes(selectedRMS.tipo_pedido)
      this.isShowApproval = [null, undefined, 2, 10].includes(selectedRMS.status_id)

      let dtEntregaTemp = selectedRMS.dtEntregaStr.toString().split("/")
      this.requisition.dtEntrega = new Date(Number(dtEntregaTemp[2]), Number(dtEntregaTemp[1]) - 1, Number(dtEntregaTemp[0]))

      this.auxChangeTypeRequisition()

      await Promise.all([
        this.getOptObjectsRequisition(),
        this.getOptPaymentForms(),
        this.getOptProviders(),
        this.changePaymentType(true),
      ])

      if (this.isFinancial) {
        await this.getOptOperations()
        this.apportionmentSelected = null
        this.changeToolbarApportionment()
      }

      await this.httpService.get('custom/rms/listar-detalhe-rms', [this.requisition.id]).toPromise().then(res => {
        if (res.requisicaoFinanceiro) {
          this.financialRequisition = res.requisicaoFinanceiro
          this.financialRequisition.dtVencimento = new Date(res.requisicaoFinanceiro.dtVencimento)
          this.isTransfer = this.financialRequisition.isTransferencia
          this.requisitionItem.requisicaoItemRateios = res.listRateio.map(item => {
            item.cadCcus.label = item.cadCcus.id ? (item.cadCcus.codCcus + ' - ' + item.cadCcus.descrCcus) : 'Nenhum'
            item.cadProj.label = item.cadProj.id ? (item.cadProj.codProj + ' - ' + item.cadProj.descrProj) : 'Nenhum'
            return item
          })
          this.totalRequesitionPercentageApportionment = this.requisitionItem.requisicaoItemRateios.reduce((acc, cur) => {
            return acc + cur.porcentagem
          }, this.totalRequesitionPercentageApportionment)
        }

        this.requisition.pedidoItem = res.requisicaoItens.map(item => {
          item.line_id = idv4()
          item.operacaoId = item.operacaoRegra.id
          item.requisicaoItemRateios.map(elem => {
            elem.cadCcus.label = elem.cadCcus.id ? (elem.cadCcus.codCcus + ' - ' + elem.cadCcus.descrCcus) : 'Nenhum'
            elem.cadProj.label = elem.cadProj.id ? (elem.cadProj.codProj + ' - ' + elem.cadProj.descrProj) : 'Nenhum'
            return elem
          })
          return { ...item }
        })

        this.totalRequisitionValue = this.requisition.pedidoItem.reduce((acc, cur) => {
          return acc + cur.valorTotal
        }, this.totalRequisitionValue)

        this.requisition.pedidoParcela = res.pedidoParcela.map(item => {
          item.dtVencimento = new Date(item.dtVencimento)
          this.setInstallmentsInPaymentForm(item, 'lowTypes', 'tipoBaixa', 'descricao')

          return item
        })

        this.requisition.sugestaoFornecedores = res.sugestaoFornecedores
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os detalhes do RMS! Contate o suporte'))

      if (!this.isFinancial) {
        await this.changeObjectRequisition()

        this.itemSelected = this.requisition.pedidoItem[0]
        this.onSelectItem(this.itemSelected)
        this.changeOperation()
      }

      this.requisicaoEdit = JSON.parse(JSON.stringify(this.requisition))
      this.requisicaoFinanceiroEdit = JSON.parse(JSON.stringify(this.financialRequisition))
      this.rateioEdit = JSON.parse(JSON.stringify(this.requisitionItem))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isLoadingForm = false
      this.isFormLoading.emit(false)
      this.messageLoading = null
    }
  }

  private async updateRMS(): Promise<void> {
    this.isFinishedEdit.emit({ finished: false })
    this.requisitionValidation()
    this.isFormLoading.emit(true)

    const payload = deepCopy({
      requisicao: this.requisition,
      requisicaoFinanceiro: this.financialRequisition,
      rateio: this.isFinancial ? this.requisitionItem.requisicaoItemRateios : [],
      empresa: this._empresaId,
      usuario: this.sessionService.loggedUser.id,
      isSalvareAprovar: this.isSaveAndApprove,
      isFinancial: this.isFinancial
    })

    this.isLoadingForm = true
    this.messageLoading = 'Salvando suas alterações!'

    try {
      const response = await this.httpService.post('/custom/rms/rms-salvar', payload).toPromise()

      let promise = new Promise(async (resolve) => {
        if (!response.error) {
          if (this.requisitionSelectedTodoCardId) {
            let payload

            let requisicaoFinanceiroCopy = JSON.parse(JSON.stringify(this.financialRequisition))
            let requisicaoCopy = JSON.parse(JSON.stringify(this.requisition))
            let rateioCopy = JSON.parse(JSON.stringify(this.requisitionItem))
            this.requisicaoFinanceiroEdit = this.parseObject(this.requisicaoFinanceiroEdit)
            this.requisicaoEdit = this.parseObject(this.requisicaoEdit)
            this.rateioEdit = this.parseObject(this.rateioEdit)
            rateioCopy = this.parseObject(rateioCopy)
            requisicaoCopy = this.parseObject(requisicaoCopy)
            requisicaoFinanceiroCopy = this.parseObject(requisicaoFinanceiroCopy)

            payload = {
              service: Service.APPROVAL_CENTER,
              action: Action.RMS,
              method: Method.PUT,
              content: {
                userId: this.sessionService.loggedUser.id,
                cardId: this.requisitionSelectedTodoCardId,
                userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
                compareList: [
                  { before: this.requisicaoEdit, after: requisicaoCopy },
                  { before: this.requisicaoFinanceiroEdit, after: requisicaoFinanceiroCopy },
                  { before: this.isFinancial ? this.rateioEdit : {}, after: this.isFinancial ? rateioCopy : {} }
                ]
              }
            }

            if (this.isSaveAndApprove) await this.createApprovalCard(response.reqId)

            this.socketService.send(payload)

            this.requisicaoFinanceiroEdit = null
            this.requisicaoEdit = null
            this.rateioEdit = null
            rateioCopy = null
            requisicaoCopy = null
            requisicaoFinanceiroCopy = null
          }

          resolve(true)
        } else this.showResponse('error', response.message)
      })

      promise.then(res => this.isFinishedEdit.emit({ finished: true, message: response.message }))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      if (!this.isSaveAndApprove) this.isLoadingForm = false, this.messageLoading = null, this.isFormLoading.emit(false)
    }
  }

  private async createApprovalCard(newCardId: number = null): Promise<void> {
    try {
      let reqId = (newCardId !== null) ? newCardId : this.requisition.id

      const payload = {
        empresaId: this._empresaId,
        origemId: 3,
        itemId: [Number(reqId)],
        userId: this.sessionService.loggedUser.id
      }

      await this.httpService.post('/custom/workflow/criar-card-aprovacao', payload).toPromise().then(res => {
        if (!res.error) {
          const username = this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome
          this.socketService.requestApproval(res.listTodoCardId, res.listNotificacaoId, this.sessionService.loggedUser.id, username)
        }

        this.showResponse(res.error ? 'error' : 'success', res.response || res.message || res.mensagem || res.msg)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao solicitar aprovação do card! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isLoadingForm = false
      this.isFormLoading.emit(false)
    }
  }

  private saveFile(cardId): void {
    try {
      this.files.forEach(item => {
        const payload: FormData = new FormData()
        payload.append('file', item)
        payload.append('type', 'workflow')
        payload.append('projetoId', this._empresaId.toString())
        payload.append('categoria', 'aprovacao')
        payload.append('cardId', cardId)
        payload.append('fileName', this.fileNameReplace(item.name))

        this.httpService.post('/custom/upload', payload).toPromise().then((res) => {
          const payload = {
            service: Service.APPROVAL_CENTER,
            action: Action.ATTACHMENT,
            method: Method.POST,
            content: {
              origemId: 3,
              path: res.path,
              name: this.fileNameReplace(item.name),
              userId: this.sessionService.loggedUser.id,
              cardId: cardId,
              userName: this.dblinkedSessionService.usuario.nome + ' ' + this.dblinkedSessionService.usuario.sobrenome,
            }
          }

          this.socketService.send(payload)
        }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao anexar um de seus arquivos! Contate o suporte'))
      })
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getOptTypesRequisition(): Promise<void> {
    this.loadingOptTypesRequisition = true
    this.loadingIconOptTypesRequisition = true

    try {
      await this.httpService.get('/tipo?$select=id,nome,tipo_rms_pedido/id&$filter=empresa/id eq ' + this._matrizId + '&$orderby=nome').toPromise().then(res => {
        this.optTypesRequisition = new Array()
        res.value.forEach(item => this.optTypesRequisition.push({ label: item.nome.toUpperCase(), value: item.id, type: item.tipo_rms_pedido.id }))
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os tipos de requisições! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptTypesRequisition = false
      this.loadingIconOptTypesRequisition = false
    }
  }

  private async getOptObjectsRequisition(): Promise<void> {
    this.loadingOptObjectsRequisition = true
    this.loadingIconOptObjectsRequisition = true

    try {
      if (this.requisition.tipo.id) {

        const response = await this.httpService.get('/tipoobjetorelac?$select=objeto/id&$filter=tipo/id eq ' + this.requisition.tipo.id + '&$orderby=id').toPromise()

        if (response.value.length > 0) {
          let objIds = ' and id in ('
          response.value.forEach(item => objIds += item.objeto.id + ', ')
          const lastIndex = objIds.lastIndexOf(', ')
          objIds = objIds.substring(0, lastIndex)
          objIds += ')'

          await this.setOptObjectsRequisition(objIds)
        } else {
          this.loadingOptObjectsRequisition = false
          this.loadingIconOptObjectsRequisition = false
        }
      }
    } catch (error) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async setOptObjectsRequisition(objIds: string): Promise<void> {
    try {
      await this.httpService.get('/objeto?$select=id,nome,objeto_rms_pedido/id&$filter=empresa/id eq ' + this._matrizId + objIds + '&$orderby=nome').toPromise().then(res => {
        this.optObjectsRequisition = new Array()
        res.value.forEach(item => this.optObjectsRequisition.push({ label: item.nome.toUpperCase(), value: item.id, type: item.objeto_rms_pedido.id }))
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao definir os objetos de requisição! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptObjectsRequisition = false
      this.loadingIconOptObjectsRequisition = false
    }
  }

  private async getOptCategoriesRequisition(): Promise<void> {
    this.loadingOptCategoriesRequisition = true
    this.loadingIconOptCategoriesRequisition = true

    try {
      await this.httpService.get('/categoria?$select=id,nome&$filter=empresa/id eq ' + this._matrizId + '&$orderby=nome').toPromise().then(res => {
        this.optCategoriesRequisition = [{ label: 'Sem categoria', value: null }]
        res.value.forEach(item => this.optCategoriesRequisition.push({ label: item.nome.toUpperCase(), value: item.id }))
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregas as categorias de requisição! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptCategoriesRequisition = false
      this.loadingIconOptCategoriesRequisition = false
    }
  }

  private async getOptOriginsRequisition(): Promise<void> {
    this.loadingOptOriginsRequisition = true
    this.loadingIconOptOriginsRequisition = true

    try {
      await this.httpService.get('/origem?$select=id,nome,origemRmsPedido/id&$filter=empresa/id eq ' + this._matrizId + '&$orderby=nome').toPromise().then(res => {
        this.optOriginsRequisition = new Array()
        res.value.forEach(item => this.optOriginsRequisition.push({ label: item.nome.toUpperCase(), value: item.id }))
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as origens da requisição! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptOriginsRequisition = false
      this.loadingIconOptOriginsRequisition = false
    }
  }

  private async getOptNdasRequisition(): Promise<void> {
    this.loadingOptNdasRequisition = true
    this.loadingIconOptNdasRequisition = true

    try {
      await this.httpService.get('/nda?$select=id,nome&$filter=empresa/id eq ' + this._matrizId + '&$orderby=nome').toPromise().then(res => {
        this.optNdasRequisition = [{ label: 'Nenhum NDA', value: null }]
        res.value.forEach(item => this.optNdasRequisition.push({ label: item.nome.toUpperCase(), value: item.id }))
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os termos de confidencialidade da requisição! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptNdasRequisition = false
      this.loadingIconOptNdasRequisition = false
    }
  }

  private async getOptPrioritiesRequisition(): Promise<void> {
    this.loadingOptPrioritiesRequisition = true
    this.loadingIconOptPrioritiesRequisition = true

    try {
      await this.httpService.get('/prioridaderequisicao?$select=id,nome,indice&$filter=empresa/id eq ' + this._matrizId + '&$orderby=indice').toPromise().then(res => {
        this.optPrioritiesRequisition = new Array()
        res.value.forEach(item => this.optPrioritiesRequisition.push({ label: item.indice ? item.indice + " - " + item.nome : item.nome, value: item.id }))
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os as prioridades da requisição! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptPrioritiesRequisition = false
      this.loadingIconOptPrioritiesRequisition = false
    }
  }

  private async getOptCurrenciesRequisition(): Promise<void> {
    this.loadingOptCurrenciesRequisition = true
    this.loadingIconOptCurrenciesRequisition = true

    try {
      await this.httpService.get('/moeda?$select=id,codigo,descricao&$orderby=codigo').toPromise().then(res => {
        this.optCurrenciesRequisition = new Array()
        res.value.forEach(item => {
          this.optCurrenciesRequisition.push({ label: item.codigo + ' - ' + item.descricao, value: item.id })
          this.optionsKeyCurrency.push({ key: item.codigo, id: item.id })
        })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as moedas da requisição! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptCurrenciesRequisition = false
      this.loadingIconOptCurrenciesRequisition = false
    }
  }

  private async getOptCostCenter(): Promise<void> {
    this.loadingOptCostCenter = true
    this.loadingIconOptCostCenter = true

    try {
      await this.httpService.get('/cadccus?$select=id,codCcus,descrCcus&$filter=empresa/id eq ' + this._matrizId + ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value + ' and ativo eq true&$orderby=codCcus').toPromise().then(res => {
        this.optCostCenter = [{ label: 'Nenhum', value: null }]
        if (res.value.length > 0) res.value.forEach(item => this.optCostCenter.push({ label: item.codCcus + ' - ' + item.descrCcus, value: item.id }))
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os centros de custo! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptCostCenter = false
      this.loadingIconOptCostCenter = false
    }
  }

  private async getOptProjects(): Promise<void> {
    this.loadingOptProjects = true
    this.loadingIconOptProjects = true

    try {
      await this.httpService.get('/cadproj?$select=id,codProj,descrProj&$filter=empresa/id eq ' + this._matrizId + ' and ativo eq true').toPromise().then(res => {
        this.optProjects = [{ label: 'Nenhum', value: null }]
        res.value.forEach(item => this.optProjects.push({ label: item.codProj + ' - ' + item.descrProj, value: item.id }))

        this.copyOptProjects = deepCopy(this.optProjects)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os projetos! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptProjects = false
      this.loadingIconOptProjects = false
    }
  }

  private async getOptProjectCostCenterRatio(): Promise<void> {
    try {
      await this.httpService.get('/cadccuscadprojrelac?$select=cadCcus/id,cadProj/id&$filter=empresa/id eq ' + this._matrizId).toPromise().then(res => {
        this.optProjectCostCenterRatio = new Array()
        if (res.value && res.value.length > 0) res.value.forEach(item => {
          this.optProjectCostCenterRatio.push({ ccusId: item.cadCcus.id, projId: item.cadProj.id })
        })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as relações de centro de custo e projeto! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getOptOperations(): Promise<void> {
    this.loadingOptOperations = true

    try {
      this.optOperations = new Array()
      let url: string, typeInOut: number

      if (this.isFinancial) {
        url = '/operacaoregracontabil?$select=id,descricao&$filter=tipoEntradaSaida/id eq ' + (this.typeRequisitionId === 3 ? 2 : 1) + ' and tipoRegraContabil/id eq 3' + ' and empresa/id eq ' + this._matrizId + ' and ativo eq true&$orderby=descricao'
      } else {
        if (this.getCombinationRequestId() === 1 || this.getCombinationRequestId() === 2) typeInOut = 2
        else typeInOut = 1

        url = '/operacaoregra?$select=id,cod,descricao,operacaoRegraContabil/id,operacaoRegraContabil/tipoRegraContabil/id,operacaoRegraFiscal/id&$filter=tipoEntradaSaida/id eq ' + typeInOut + ' and empresa/id eq ' + this._matrizId + ' and ativo eq true&$orderby=cod'
      }

      this.loadingIconOptOperations = true

      await this.httpService.get(url).toPromise().then(res => {
        res.value.forEach(item => {
          if (this.isFinancial) this.optOperations.push({ label: item.descricao.toUpperCase(), value: item.id })

          if (!this.isFinancial && item.operacaoRegraContabil !== null && item.operacaoRegraContabil.tipoRegraContabil !== null) {
            if (item.operacaoRegraContabil.tipoRegraContabil.id === (this.objectRequisitionId === 1 ? 1 : 2)) this.optOperations.push({ label: item.cod + ' - ' + item.descricao, value: item.id })
          }
        })
      }).catch(err => {
        this.showResponse('error', err.status + ' - Houve um erro ao carregar as operações! Contate o suporte')
      }).finally(() => {
        this.loadingOptOperations = false
        this.loadingIconOptOperations = false
      })
    } catch (err) {
      if (err) this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getOptCompanyAccounts(): Promise<void> {
    this.loadingOptCompanyAccounts = true
    this.loadingIconOptCompanyAccounts = true

    try {
      await this.httpService.get('/empresaconta?$select=id,nome,tipoConta/id&$filter=empresa/id eq ' + this._matrizId + ' and ativo=true&$orderby=nome').toPromise().then(res => {
        this.optCompanyAccounts = []
        this.optCompanyAccounts.push({ label: 'Nenhuma', value: null })
        res.value.forEach(item => {
          if (item.tipoConta.id !== 10) {
            this.optCompanyAccounts.push({ label: item.id + ' - ' + item.nome, value: item.id })
          }
        })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as contas da empresa! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptCompanyAccounts = false
      this.loadingIconOptCompanyAccounts = false
    }
  }

  private async getDefaultCompanyAccount(): Promise<void> {
    this.loadingOptCompanyAccounts = true
    this.loadingIconOptCompanyAccounts = true

    try {
      await this.httpService.get(`/custom/rms/recuperar-conta-padrao-rms(${this._matrizId})`).toPromise().then(res => {
        if (!this.isEditRMS) this.requisition.empresaConta.id = res.data.idConta
        this.allowChangeAccount = (res.data.alteravel === null) ? true : res.data.alteravel

      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar a conta padrão da empresa! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptCompanyAccounts = false
      this.loadingIconOptCompanyAccounts = false
    }
  }

  private async getOptPaymentTypes(): Promise<void> {
    this.optPaymentTypes = [{ label: 'NENHUM', value: 2 }, { label: 'BANCO', value: 1 }]
  }

  private async getOptPaymentForms(): Promise<void> {
    this.loadingOptPaymentForms = true
    this.loadingIconOptPaymentForms = true

    let paramQuery: string = null
    paramQuery = (this.typeRequisitionId === 1) ? 'isPagamento=true' : 'isRecebimento=true'

    try {
      await this.httpService.get(`/formapagamento?$select=id,descricao,permiteAlterarBanco,empresaConta/id&$filter=empresa/id eq ${this._matrizId} and ativo=true and ${paramQuery}&$orderby=id`).toPromise().then(res => {
        this.optPaymentForms = [{ label: 'Nenhum', value: null }]
        res.value.forEach(item => this.optPaymentForms.push({ label: item.descricao, value: item.id, companyAccount: item.empresaConta?.id || null, isAllowsChangingBank: item.permiteAlterarBanco }))
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as formas de pagamento! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptPaymentForms = false
      this.loadingIconOptPaymentForms = false
    }
  }

  private async getOptItems(): Promise<void> {
    this.loadingOptItems = true
    this.requisitionItem.operacaoRegra.id = this.requisitionItem.operacaoId
    const uri: string = [3, 4].includes(this.getCombinationRequestId()) ? 'buscar-item-compra' : 'buscar-item-venda'
    let url: string

    if (uri) {
      url = `/custom/pedido/${uri}(${this._matrizId},${this.getCombinationRequestId()}${this.requisitionItem.operacaoRegra.id ? ',' + this.requisitionItem.operacaoRegra.id : ',0'})`
    } else throw false

    this.loadingIconOptItems = true

    try {
      await this.httpService.get(url).toPromise().then(res => {
        this.optItems = new Array()
        res.forEach(item => {
          this.optItems.push({
            label: item.codigo + ' - ' + (item.descricaoEn ? item.descricaoEn : item.descricao) + (item.itemFornecedor ? ' - PN: ' + item.itemFornecedor : ''),
            value: item.id
          })
        })
      }).catch(err => {
        this.showResponse('error', err.status + ' - Houve um erro ao carregar as formas de pagamento! Contate o suporte')
      }).finally(() => {
        this.loadingOptItems = false
        this.loadingIconOptItems = false
      })
    } catch (err) {
      if (err) this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getOptNCM(data: number, setDefault: boolean): Promise<void> {
    if (!setDefault) {
      this.loadingOptNCM = true
      this.loadingIconOptNCM = true
    }

    const uri: string = setDefault ? 'get-ncm-id' : 'get-ncm'

    try {
      await this.httpService.post(`/custom/cadastro/${uri}`, { idTipi: data }).toPromise().then(res => {
        this.optNCM = new Array()

        res.forEach(item => {
          this.optNCM.push({ label: item.ncm + ' - ' + item.descricao, value: item.id })

          if (setDefault) {
            this.requisitionItem.tipi.id = item.id
            this.requisitionItem.tipi.ncm = item.ncm
          }
        })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar o NCM do item! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      if (!setDefault) {
        this.loadingOptNCM = false
        this.loadingIconOptNCM = false
      }
    }
  }

  private async getOptTypesItem(): Promise<void> {
    this.loadingOptTypesItem = true
    this.loadingIconOptTypesItem = true

    try {
      await this.httpService.get('/itemtipo?$select=id,codigo,descricao&$orderby=codigo').toPromise().then(res => {
        this.optTypesItem = new Array()
        res.value.forEach(item => this.optTypesItem.push({ label: item.codigo + ' - ' + item.descricao, value: item.id }))
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os tipos! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptTypesItem = false
      this.loadingIconOptTypesItem = false
    }
  }

  private async getOptTipis(): Promise<void> {
    this.loadingOptTipis = true
    this.loadingIconOptTipis = true

    try {
      await this.httpService.get('/custom/financeiro/capitulos-tipi').toPromise().then(res => {
        this.optTipis = new Array()
        res.forEach(item => { this.optTipis.push({ label: (item.Tipis ? (item.Tipis + ' - ') : '') + item.descricao, value: item.id }) })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os tipis! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptTipis = false
      this.loadingIconOptTipis = false
    }
  }

  private async getOptProviders(): Promise<void> {
    this.loadingOptProviders = true
    this.loadingIconOptProviders = true
    const typeRequisitionId = (this.typeRequisitionId === 1 || this.typeRequisitionId === 3) ? 2 : 1

    try {
      await this.httpService.get(`/custom/rms/buscar-fornecedores(${this._matrizId},${(this.isStarling ? 1 : typeRequisitionId)})`).toPromise().then(res => {
        this.optProviders = new Array()
        this.listProviders = new Object()
        res.forEach(item => {
          let labelCNPJ: string = null, labelCPF: string = null

          labelCNPJ = item.cnpj ? ` - CNPJ: ${item.cnpj}` : null
          labelCPF = item.cpf ? ` - CPF: ${item.cpf}` : null

          this.optProviders.push({ label: item.fornecedor + (labelCNPJ || labelCPF || ' - EXTERIOR'), value: item.id })
          this.listProviders[item.id] = item
        })
      }).catch(err => this.showResponse('error', err.status + `- Houve um erro ao carregar os ${(this.isProvider && !this.isStarling) ? 'fornecedores' : 'clientes'} ! Contate o suporte`))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptProviders = false
      this.loadingIconOptProviders = false
    }
  }

  private async getOptLowTypes(): Promise<void> {
    this.loadingOptLowTypes = true
    this.loadingIconOptLowTypes = true

    try {
      await this.httpService.get('/tipobaixa?$select=id,descricao&$orderby=descricao').toPromise().then(res => {
        this.optLowTypes = [{ label: 'Selecione', value: null }]
        res.value.forEach(item => this.optLowTypes.push({ label: item.descricao, value: item.id }))
      }).catch(err => this.showResponse('error', err.status + '- Houve um erro ao carregar os tipos de baixa ! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingOptLowTypes = false
      this.loadingIconOptLowTypes = false
    }
  }

  private async getFinancialParameters(): Promise<void> {
    try {
      await this.httpService.get("/empresaparametrofinanceiro?$select=permiteAlterarMoeda,confTaxa/id,confTaxaPedido/id&$filter=empresa/id eq " + this._matrizId).toPromise().then(res => {
        if (res.value) {
          this.allowsOtherCurrencies = res.value[0].permiteAlterarMoeda
          this.rateId = res.value[0].confTaxa?.id
          this.rateRequestId = res.value[0].confTaxaPedido?.id
        }
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os parâmetros do financeiro! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  private async getRmsParameter(): Promise<void> {
    try {
      await this.httpService.get('/configuracaorms?$select=id,cadCcusObrigatorioRms,operacaoObrigatoria,infosBancariasObrigatorias&$filter=empresa/id eq ' + this._matrizId).toPromise().then(res => {
        if (res.value[0]) {
          this.isMandatoryApportionment = (res.value[0].cadCcusObrigatorioRms !== null) ? res.value[0].cadCcusObrigatorioRms : false
          this.isMandatoryOperation = (res.value[0].operacaoObrigatoria !== null) ? res.value[0].operacaoObrigatoria : false
          this.isMandatoryBankInformation = (res.value[0].infosBancariasObrigatorias !== null) ? res.value[0].infosBancariasObrigatorias : false
        }
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar os parâmetros do rms! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    }
  }

  public async getCurrencyRate(): Promise<void> {
    this.loadingRate = true
    const rateId = this.isFinancial ? this.rateId : this.rateRequestId
    if (this.requisition.moeda.id === 1 || !rateId) {
      this.requisition.txMoeda = 1
      this.loadingRate = false
      return
    }

    try {
      await this.httpService.get(`/custom/financeiro/cotacao-moeda?moedaId=${this.requisition.moeda.id}&taxConfId=${rateId}`).toPromise().then(res => {
        this.requisition.txMoeda = res.error ? 0 : res
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar a taxa da moeda! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingRate = false
    }
  }

  private async getItemInformations(data: any): Promise<void> {
    this.loadingItemInformations = true

    try {
      await this.httpService.get('/item?$select=id,codigo,descricao,descricaoEn,tipi/id,tipi/ncm,itemFornecedor,itemTipo/id&$filter=id eq ' + data.value + '&$orderby=id').toPromise().then(res => {
        res.value.forEach(item => {
          this.requisitionItem.item = item.codigo + ' - ' + item.descricao
          this.requisitionItem.descricaoEn = item.descricaoEn
          this.requisitionItem.descricaoBr = item.descricaoBr
          this.requisitionItem.descricao = item.descricao
          this.requisitionItem.tipi.id = item.tipi ? item.tipi.id : null
          this.requisitionItem.tipi.ncm = item.tipi ? item.tipi.ncm : null
          this.requisitionItem.partNumber = item.itemFornecedor
          this.requisitionItem.itemTipo.id = item.itemTipo.id
          this.requisitionItem.itemInt = new Item()
          this.requisitionItem.itemInt.id = item.id
          this.requisitionItem.itemInt.codigo = item.codigo
          this.requisitionItem.itemInt.descricao = item.descricao
          this.requisitionItem.itemCadastrado = true
          this.getOptNCM(this.requisitionItem.tipi.id, true)
        })
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao carregar as informações do item! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.loadingItemInformations = false
    }
  }

  public async updatePaymentMethod(): Promise<void> {
    this.paymentMethodValidation()

    this.requisition.dtEmissao = this.requisition.dtFaturamentoAutomatico = new Date()

    const payload = {
      pedido: this.requisition,
      empresa: this._matrizId,
      usuario: this.sessionService.loggedUser.id,
      valorTotal: this.totalRequisitionValue,
      moeda: this.requisition.moeda.id,
      txMoeda: this.requisition.txMoeda,
      isPedidoExterior: this.foreignRequisition,
      isPedidoCompraOuVendaProduto: ((this.objectRequisitionId === 1) ? true : false),
      converterTaxa: true
    }

    this.isFetchParcel = true

    try {
      await this.httpService.post('/custom/financeiro/buscar-parcela', payload).toPromise().then(res => {
        if (res.error) {
          this.showResponse('error', res.message)
        } else {
          this.requisition.pedidoParcela = new Array()
          this.totalRequisitionInstallments = 0.00
          this.maximumInstallments = res.maxParcelas
          this.exactInstallments = res.parcelasExatas
          this.isAllowsChangingBank = res.permiteAlterarBanco

          if (res.parcelas !== null && res.parcelas !== undefined) {
            this.requisition.pedidoParcela = res.parcelas
            this.calcPaymentMethod()
          }
        }
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao buscar as parcelas! Contate o suporte'))
    } catch (err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.isFetchParcel = false
    }
  }

  public async changeTypeRequisition(): Promise<void> {
    this.loadingOptTypesRequisition = true
    this.getOptObjectsRequisition()

    this.objectRequisitionId = null, this.requisition.objeto.id = null, this.apportionmentSelected = null
    this.auxChangeTypeRequisition()

    await Promise.all([
      this.getOptOperations(),
      this.getOptProviders(),
      this.changeToolbarApportionment(),
      this.changePaymentType(false)
    ]).finally(() => this.loadingOptTypesRequisition = false)
  }

  private auxChangeTypeRequisition(): void {
    this.requisition.pedidoParcela = new Array()
    this.isStarling = false
    this.typeRequisitionId = this.optTypesRequisition.filter(opt => opt.value === this.requisition.tipo.id)[0].type
    if (this.requisition.tipo.id !== null) ([3, 4].includes(this.typeRequisitionId)) ? this.isFinancial = true : this.isFinancial = false
    if (this.requisition.tipo.id !== null) ([1, 3].includes(this.typeRequisitionId)) ? this.isProvider = true : this.isProvider = false
  }

  public async changeObjectRequisition(): Promise<void> {
    this.objectRequisitionId = this.optObjectsRequisition.filter(opt => opt.value === this.requisition.objeto.id)[0].type
    if (!this.isMandatoryOperation) await this.getOptItems()
    await this.getOptOperations()
  }

  public changeOriginRequisition() {
    this.requisition.origem.id === 1 ? this.foreignRequisition = false : this.foreignRequisition = true
    this.requisition.moeda.id = 1
    this.requisition.txMoeda = 1
  }

  public changeCostCenter(event: any): void {
    let costCenterSelected = this.optCostCenter.filter(line => line.value === event.cadCcus.id)[0]

    this.requisitionItem.requisicaoItemRateios.forEach(item => {
      if (item.id === event.id) {
        item.cadCcus.label = costCenterSelected.label
        item.cadCcus.codCcus = costCenterSelected.label.split(' ')[0]
        item.cadCcus.descrCcus = costCenterSelected.label.split(' ')[2]

        item.cadProj.label = 'Nenhum'
        item.cadProj.id = null
        item.cadProj.codProj = null
        item.cadProj.descrProj = null
      }
    })
  }

  public changeProject(event: any): void {
    let projectSelected = this.optProjects.filter(line => line.value === event.cadProj.id)[0]

    this.requisitionItem.requisicaoItemRateios.forEach(item => {
      if (item.id === event.id) item.cadProj.label = projectSelected.label
    })
  }

  public showAvailableProjects(costCenterId: number): void {
    let relacListId: Array<any> = new Array(), availableProjects: Array<any> = new Array()

    relacListId = this.optProjectCostCenterRatio.map(item => { if (item.ccusId === costCenterId) return item.projId }).filter(line => line !== undefined)
    availableProjects = this.copyOptProjects.map(item => { if (relacListId.includes(item.value) || item.value === null) return item }).filter(line => line !== undefined)

    this.optProjects = availableProjects
  }

  public changeOperation(): void {
    if (this.isFinancial) {
      // -> colocar as paradas do financeiro aqui ainda
    } else {
      this.getOptItems()
    }
  }

  public changeTransfer(): void {
    this.financialRequisition.isTransferencia = this.isTransfer
    this.requisitionItem.requisicaoItemRateios = new Array()
  }

  public changePaymentType(haveInfos: boolean): void {
    if (this.requisition.tipoPagamento === 2) this.requisition.pedidoParcela = new Array()
    if (!haveInfos) this.requisition.formaPagamento.id = null

    if (this.requisition.tipoPagamento && [1, 2].includes(this.typeRequisitionId)) this.getOptPaymentForms()
  }

  public changePaymentForm(): void {
    let selectedPaymentForm: Object = new Object()

    selectedPaymentForm = this.optPaymentForms.find(line => line.value === this.requisition.formaPagamento.id)

    this.isAllowsChangingBank = selectedPaymentForm['isAllowsChangingBank']
    this.requisition.empresaConta.id = selectedPaymentForm['companyAccount']

    if (this.requisition.empresaConta.id !== null) this.updatePaymentMethod()
  }

  public currencySelected(): string {
    let response: string

    this.optionsKeyCurrency.forEach(item => {
      if (item.id === this.requisition.moeda.id) response = item.key
    })

    return `(${response})`
  }

  public changeItem(event: any): void {
    this.getItemInformations(event)
  }

  public changeNCM(data: number): void {
    this.getOptNCM(data, false)
  }

  public changeStarling(): void {
    this.getOptProviders()
  }

  private getCombinationRequestId(): number {
    // let typeRequisitionId: number,
    let combinationId: number
    // typeRequisitionId = (this.typeRequisitionId === 3 || this.typeRequisitionId === 4) ? 2 : 1

    if (this.objectRequisitionId === 1 && this.typeRequisitionId === 2) combinationId = 1
    else if (this.objectRequisitionId === 2 && this.typeRequisitionId === 2) combinationId = 2
    else if (this.objectRequisitionId === 1 && this.typeRequisitionId === 1) combinationId = 3
    else if (this.objectRequisitionId === 2 && this.typeRequisitionId === 1) combinationId = 4

    if (combinationId === undefined) throw false

    return combinationId
  }

  public calcTotalItemValue(): void {
    this.requisitionItem.valorTotal = this.requisitionItem.quantidade * this.requisitionItem.valorUnit
  }

  public calcTotalRequisitionValue(): void {
    this.totalRequisitionValue = 0

    if (this.requisition.pedidoItem) {
      this.requisition.pedidoItem.forEach(item => {
        if (this.allowsOtherCurrencies && this.foreignRequisition) item.valorTotalBrl = item.valorTotal * this.requisition.txMoeda
        this.totalRequisitionValue = this.totalRequisitionValue + item.valorTotal
      })
    }
  }

  public editingApportionment(): void {
    this.totalRequesitionPercentageApportionment = 0
    this.requisitionItem.requisicaoItemRateios.forEach(item => {
      this.totalRequesitionPercentageApportionment = this.totalRequesitionPercentageApportionment + item.porcentagem
    })

    this.changeToolbarApportionment()
  }

  public changeFormItem(change: boolean): void {
    this.requisitionItem = resetRequisitionItem()
    this.requisitionItem.itemCadastrado = change
    if (this.isMandatoryOperation) {
      this.loadingOptItems = true
      this.optItems = new Array()
    }
  }

  public openingModalItem(edit: boolean): void {
    this.showModalItem = !this.showModalItem
    this.isEditItem = edit

    if (this.isEditItem) this.changeOperation(), this.getOptNCM(this.itemSelected.tipiCap.id, false)
    else {
      this.itemSelected = null
      this.onSelectItem()
      this.changeFormItem(this.showModalItem)
    }

    this.calcTotalRequisitionValue()
    if (this.requisition.pedidoParcela.length > 0) this.calcPaymentMethod()
  }

  public onSelectItem(event?: any): void {
    this.requisitionItem = this.itemSelected ? (event.data || event) : resetRequisitionItem()
    this.toolbarItems.setVisible('btn-edit', (this.itemSelected === null || this.itemSelected === undefined) ? false : true)
    this.toolbarItems.setVisible('btn-delete', (this.itemSelected === null || this.itemSelected === undefined) ? false : true)
    this.apportionmentSelected = null
    this.editingApportionment()
  }

  public addItemInRequisition(): void {
    this.itemValidation()
    this.requisitionItem.operacaoRegra.descricao = this.optOperations.filter(operation => operation.value === this.requisitionItem.operacaoId)[0].label
    this.requisitionItem.valorTotal = this.requisitionItem.valorUnit * this.requisitionItem.quantidade
    this.requisitionItem.line_id = idv4()
    this.optNCM.forEach(row => { if (row.value === this.requisitionItem.tipi.id) this.requisitionItem.tipi.ncm = row.label })
    this.requisition.pedidoItem.push({ ...this.requisitionItem })
    this.requisitionItem = resetRequisitionItem()

    this.calcTotalRequisitionValue()
    this.openingModalItem(false)
  }

  public editItemInRequisition(): void {
    this.itemValidation()

    this.requisition.pedidoItem = this.requisition.pedidoItem.filter(line => line.line_id !== this.requisitionItem.line_id)
    this.addItemInRequisition()
    this.openingModalItem(false)
  }

  private removeItemInRequisition(): void {
    this.requisition.pedidoItem = this.requisition.pedidoItem.filter(line => line.line_id !== this.itemSelected.line_id)
    this.itemSelected = null
    this.onSelectItem()
    this.calcTotalRequisitionValue()
    if (this.requisition.pedidoParcela.length > 0) this.calcPaymentMethod()
  }

  public changeToolbarApportionment(): void {
    this.toolbarApportionment.setVisible('btn-add', this.isFinancial || ((this.itemSelected === null || this.itemSelected === undefined) ? false : true))
    this.toolbarApportionment.setVisible('btn-delete', ((this.apportionmentSelected === null || this.apportionmentSelected === undefined)) ? false : true)
    this.toolbarApportionment.setVisible('btn-copy', (this.requisitionItem.requisicaoItemRateios.length > 0 && !this.isFinancial && this.requisition.pedidoItem.length > 1))
  }

  public addApportionmentInItem(): void {
    let apportionment = resetRequisitionItemApportionment()
    apportionment.id = idv4()

    this.requisitionItem.requisicaoItemRateios.push({ ...apportionment })
  }

  private removeApportionmentInItem(): void {
    this.requisitionItem.requisicaoItemRateios = this.requisitionItem.requisicaoItemRateios.filter(line => line.id !== this.apportionmentSelected.id)
    this.apportionmentSelected = null
    this.changeToolbarApportionment()
  }

  private copyApportionmentInItems(): void {
    if (confirm('Deseja replicar este Rateio para todos os itens ?')) this.requisition.pedidoItem.forEach(item => {
      if (item.line_id !== this.requisitionItem.line_id) {
        item.requisicaoItemRateios = new Array()
        item.requisicaoItemRateios = deepCopy(this.requisitionItem.requisicaoItemRateios)
      }
    })
  }

  public changeFormProvider(change: boolean): void {
    this.isSupplierAlreadyRegistered = change
    this.suggestedSupplier = resetSuggestedSupplier()
  }

  public openingModalProvider(edit: boolean): void {
    this.showModalProvider = !this.showModalProvider
    this.isEditProvider = edit

    if (!this.isEditProvider) {
      this.providerSelected = null
      this.onSelectProvider()
      this.changeFormProvider(this.showModalProvider)
    }
  }

  public onSelectProvider(event?: any) {
    this.suggestedSupplier = this.providerSelected ? event.data : resetSuggestedSupplier()
    this.toolbarProvider.setVisible('btn-edit', (this.providerSelected === null || this.providerSelected === undefined) ? false : true)
    this.toolbarProvider.setVisible('btn-delete', (this.providerSelected === null || this.providerSelected === undefined) ? false : true)
  }

  public addProviderInRequisition(): void {
    this.providerValidation()

    if (this.isSupplierAlreadyRegistered) {
      const providerSelected = this.listProviders[this.suggestedSupplier.participanteEmpresaId]

      this.suggestedSupplier.empresa = providerSelected.fornecedor
      this.suggestedSupplier.telefone = providerSelected.fone
      this.suggestedSupplier.email = providerSelected.email
      this.suggestedSupplier.nome = providerSelected.contato
    }

    this.suggestedSupplier.id = this.countProviders

    this.requisition.sugestaoFornecedores.push(this.suggestedSupplier)
    this.changeFormProvider(true)
    this.countProviders++
  }

  public editProviderInRequisition(): void {
    this.providerValidation()

    this.requisition.sugestaoFornecedores = this.requisition.sugestaoFornecedores.filter(line => line.id !== this.providerSelected.id)
    this.addProviderInRequisition()
    this.providerSelected = null
    this.isEditProvider = false
    this.onSelectProvider()
  }

  private removeProviderInRequisition(): void {
    this.requisition.sugestaoFornecedores = this.requisition.sugestaoFornecedores.filter(line => line.id !== this.providerSelected.id)
    this.providerSelected = null
    this.onSelectProvider()
  }

  public calcPaymentMethod(calculate: boolean = false): void {
    if (calculate) {
      this.calcPaymentMethodValidation()

      this.requisition.pedidoParcela = new Array()
      let dueDate: Date = new Date(this.firstSalary), expirationDay: number = dueDate.getDate(), month: number = dueDate.getMonth()

      if (this.numberOfInstallments === 1) {
        this.requisition.pedidoParcela.push(this.createParcel(1, dueDate, this.totalRequisitionValue))
      } else {
        let installmentValue: number = Number((this.totalRequisitionValue / this.numberOfInstallments).toFixed(2)), diff = Number((this.totalRequisitionValue - (installmentValue * this.numberOfInstallments)).toFixed(2)), isMonthIndexCorrect = false

        for (let installments: number = 1; installments <= this.numberOfInstallments; installments++) {
          if (installments > 1) {
            if (dueDate.getMonth() === 0 && expirationDay > 28) {
              dueDate = new Date(dueDate.getFullYear(), 2, 0)

              month = 2
              isMonthIndexCorrect = true
            } else {
              dueDate.setMonth(isMonthIndexCorrect ? dueDate.getMonth() : (dueDate.getMonth() + 1))
              if (dueDate.getDate() !== expirationDay) dueDate = new Date(dueDate.getFullYear(), dueDate.getMonth(), 0)

              month = dueDate.getMonth()
              isMonthIndexCorrect = false
            }
          }

          if (installments === this.numberOfInstallments) installmentValue = installmentValue + diff

          this.requisition.pedidoParcela.push(this.createParcel(installments, dueDate, installmentValue))
          dueDate = new Date(dueDate.getFullYear(), month, expirationDay)
        }
      }
    } else if (this.requisition.pedidoParcela.length > 0) {
      let installmentValue = Number((this.totalRequisitionValue / this.requisition.pedidoParcela.length).toFixed(2)), diff = Number((this.totalRequisitionValue - (installmentValue * this.requisition.pedidoParcela.length)).toFixed(2))

      let aux: Array<any> = deepCopy(this.requisition.pedidoParcela)
      this.requisition.pedidoParcela = new Array()
      aux.forEach((item, idx) => {
        if (idx + 1 === aux.length) installmentValue = installmentValue + diff
        this.requisition.pedidoParcela.push(this.createParcel(idx + 1, item.dtVencimento, installmentValue))
      })
    }

    this.numberOfInstallments = this.requisition.pedidoParcela.length
  }

  private createParcel(installmentNumber: number, dueDate: Date, value: number): PedidoParcela {
    let installmentOrder = resetInstallmentOrder()

    installmentOrder.id = installmentNumber
    installmentOrder.parcela = installmentNumber
    installmentOrder.dtVencimento = new Date(dueDate)
    installmentOrder.valor = value
    installmentOrder.valorBrl = value * this.requisition.txMoeda
    installmentOrder.empresaConta = new EmpresaConta(this.requisition.empresaConta.id)
    installmentOrder.empresaConta.nome = this.requisition.empresaConta.id ? this.optCompanyAccounts.filter(line => line.value === installmentOrder.empresaConta.id)[0].label : 'Nenhuma'
    return deepCopy(installmentOrder)
  }

  public openingModalPaymentForm(): void {
    this.showModalPaymentForm = !this.showModalPaymentForm

    if (this.showModalPaymentForm) this.requisition.pedidoParcela.forEach(parcela => {
      parcela.dtVencimento = new Date(parcela.dtVencimento)
    })
  }

  public saveInstallments(): void {
    this.installmentsValidation()

    this.showModalPaymentForm = false
    this.numberOfInstallments = 0
    this.firstSalary = new Date()
  }

  public setInstallmentsInPaymentForm(rowData: any, column: string, key: string, changeKey: string): void {
    let arrAux: Array<any> = new Array()

    switch (column) {
      case 'companyAccounts':
        arrAux = this.optCompanyAccounts
        break
      case 'lowTypes':
        arrAux = this.optLowTypes
        break
      default:
        throw 'Não é possível acessar essa coluna!'
    }

    arrAux.forEach(item => { if (item.value === rowData[key].id) rowData[key][changeKey] = item.label })

    this.requisition.pedidoParcela.forEach(item => {
      if (item.parcela > rowData.parcela) {
        item[key].id = rowData[key].id
        item[key][changeKey] = rowData[key][changeKey]
      }
    })
  }

  public onUpload(event): void {
    this.files = []
    event['files'].forEach(item => this.files.push(item))
  }

  public onRemove(event): void {
    this.files = this.files.filter(line => line !== event.file)
  }

  public removeFile(file: File, uploader: FileUpload): void {
    const index = uploader.files.indexOf(file)
    uploader.remove(null, index)
  }

  public closeUpload(): void {
    this.uploaderState = !this.uploaderState
    this.uploader.styleClass = (this.uploaderState) ? 'file-uploader mt-3 close-uploader' : 'file-uploader mt-3'
  }

  private fileNameReplace(name: any): string {
    let replacedName = name.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z. ])/g, '')
    replacedName = replacedName.replaceAll(" ", "_")

    return replacedName
  }

  private searchValue(key: string, identification): string {
    const options = {
      tipo: this.optTypesRequisition,
      objeto: this.optObjectsRequisition,
      categoria: this.optCategoriesRequisition,
      origem: this.optOriginsRequisition,
      nda: this.optNdasRequisition,
      moeda: this.optCurrenciesRequisition,
      prioridade: this.optPrioritiesRequisition,
      empresaConta: this.optCompanyAccounts,
      operacaoRegraContabil: this.optOperations,
      tipoPagameneto: this.optPaymentTypes
    }

    try {
      let content = null

      if (Object.keys(options).includes(key)) {
        options[key].forEach((item) => { if (item.value == identification.id) content = item.label })
        return content
      }

      return content
    } catch (error) {
      return null
    }
  }

  private parseObject(object): any {
    for (let [key, value] of Object.entries(object)) {
      const parsedValue = this.searchValue(key, value)
      if (parsedValue) object[key] = parsedValue
    }

    return object
  }

  private itemValidation(): boolean {
    let message: string = null

    if (!this.requisitionItem.item && !this.requisitionItem.itemInt.id) {
      message = 'O campo item é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (!this.requisitionItem.quantidade || this.requisitionItem.quantidade <= 0) {
      message = 'O campo quantidade não pode ser menor ou igual a 0!'
      this.showResponse('info', message)
      throw message
    }

    if (!this.requisitionItem.valorUnit || this.requisitionItem.valorUnit <= 0) {
      message = 'O campo valor unitário não pode ser menor ou igual a 0!'
      this.showResponse('info', message)
      throw message
    }

    if (!this.requisitionItem.operacaoId && this.isMandatoryOperation) {
      message = 'O campo operação é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (this.loadingItemInformations) {
      message = 'Espere o fim do carregamento dos campos!'
      this.showResponse('info', message)
      throw message
    }

    return true
  }

  private percentageValidation(): boolean {
    let message: string = null

    if (this.isMandatoryApportionment && this.requisitionItem.requisicaoItemRateios.length === 0) {
      message = 'O rateio é um campo obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (this.requisitionItem.requisicaoItemRateios.length > 0) {
      if (this.totalRequesitionPercentageApportionment > 100) {
        message = 'A porcentagem de rateio não pode exceder 100%!'
        this.showResponse('info', message)
        throw message
      }

      if (this.totalRequesitionPercentageApportionment < 0) {
        message = 'A porcentagem de rateio não pode ser menor que 0%!'
        this.showResponse('info', message)
        throw message
      }

      if (this.totalRequesitionPercentageApportionment < 100) {
        message = 'A porcentagem de rateio deve ser igual a 100%!'
        this.showResponse('info', message)
        throw message
      }
    }

    return true
  }

  private providerValidation(): boolean {
    let message: string = null

    if (!this.suggestedSupplier.participanteEmpresaId) {
      if (!this.suggestedSupplier.empresa) {
        message = `O campo ${(this.isProvider && !this.isStarling) ? 'fornecedor' : 'cliente'} é obrigatório!`
        this.showResponse('info', message)
        throw message
      }

      if (!this.suggestedSupplier.email || !Util.validacaoEmail(this.suggestedSupplier.email)) {
        message = 'O campo e-mail é inválido!'
        this.showResponse('info', message)
        throw message
      }
    }

    return true
  }

  private calcPaymentMethodValidation(): boolean {
    let message: string = null

    if (this.numberOfInstallments === null || (this.numberOfInstallments < 0)) {
      message = 'Informe a quantidade de parcelas para prosseguir!'
      this.showResponse('info', message)
      throw message
    }

    if (this.numberOfInstallments > this.maximumInstallments) {
      message = `A quantidade de parcela não pode ultrapassar ${this.maximumInstallments}!`
      this.showResponse('info', message)
      throw message
    }

    if (this.firstSalary === null || this.firstSalary === undefined) {
      message = 'Selecione a data do primeiro vencimento para prosseguir!'
      this.showResponse('info', message)
      throw message
    }

    return true
  }

  private paymentMethodValidation(): boolean {
    let message: string = null

    if (this.requisition.formaPagamento.id === null) {
      message = 'Selecione uma forma de pagamento para prosseguir!'
      this.showResponse('info', message)
      throw message
    }

    if (this.requisition.empresaConta.id === null) {
      message = 'Selecione uma conta para prosseguir!'
      this.showResponse('info', message)
      throw message
    }

    return true
  }

  private installmentsValidation(): boolean {
    let message: string = null
    let sumInstallments = this.requisition.pedidoParcela.reduce((acc, cur) => {
      return acc + cur.valor
    }, 0)

    if (Number(sumInstallments.toFixed(2)) !== Number(this.totalRequisitionValue.toFixed(2))) {
      message = 'O valor das parcelas não correspondem ao valor total da requisição!'
      this.showResponse('info', message)
      throw message
    }

    return true
  }

  private requisitionValidation(): boolean {
    let message: string = null

    if (!this.requisition.tipo.id) {
      message = 'O campo tipo da requisição é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (!this.requisition.objeto.id) {
      message = 'O campo objeto é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (!this.requisition.dtEntrega) {
      message = 'O campo data de entrega é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (!this.requisition.origem.id) {
      message = 'O campo origem é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (!this.requisition.prioridade.id) {
      message = 'O campo prioridade é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (!this.requisition.titulo || !this.requisition.titulo.length) {
      message = 'O campo título é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (!this.requisition.descricao || !this.requisition.descricao.length) {
      message = 'O campo descrição é obrigatório!'
      this.showResponse('info', message)
      throw message
    }

    if (this.isFinancial) {
      if (!this.financialRequisition.historico) {
        message = 'O campo histórico é obrigatório!'
        this.showResponse('info', message)
        throw message
      }

      if (this.isTransfer) {
        if (!this.financialRequisition.contaOrigem.id) {
          message = 'O campo conta origem é obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if (!this.financialRequisition.contaDestino.id) {
          message = 'O campo conta destinho é obrigatório!'
          this.showResponse('info', message)
          throw message
        }

      } else {
        if (!this.financialRequisition.operacaoRegraContabil.id && this.isMandatoryOperation) {
          message = 'O campo operação é obrigatório!'
          this.showResponse('info', message)
          throw message
        }

        if (this.financialRequisition.nDocumento && isNaN(this.financialRequisition.nDocumento)) {
          message = 'O campo número do documento só digitos numéricos!'
          this.showResponse('info', message)
          throw message
        }

      }

      if (!this.financialRequisition.valor) {
        message = 'O campo valor é obrigatório!'
        this.showResponse('info', message)
        throw message
      }

      if (this.isMandatoryBankInformation && (this.requisition.empresaConta.id === null || this.requisition.empresaConta.id === undefined)) {
        message = 'Selecione uma conta para prosseguir!'
        this.showResponse('info', message)
        throw message
      }
    } else {
      if (this.requisition.pedidoItem.length === 0) {
        message = 'É necessário inserir pelo menos 01 (um) item!'
        this.showResponse('info', message)
        throw message
      }

      if (this.isMandatoryApportionment) {
        this.requisition.pedidoItem.forEach(item => {
          let totalApportionment: number = 0

          item.requisicaoItemRateios.forEach(elem => {
            if (!elem.cadCcus.id) {
              message = `É necessário inserir pelo menos 01 (um) centro de custo para cada item!`
              this.showResponse('info', message)
              throw message
            }
            totalApportionment += elem.porcentagem
          })

          if (totalApportionment < 100 || totalApportionment > 100) {
            message = `É necessário inserir pelo menos 01 (um) rateio para cada item ou verifique o total do rateio dos itens`
            this.showResponse('info', message)
            throw message
          }
        })
      }

      if (this.isMandatoryBankInformation) {
        if (this.requisition.tipoPagamento !== 1) {
          message = 'É necessário selecionar um tipo de pagamento!'
          this.showResponse('info', message)
          throw message
        }

        this.paymentMethodValidation()

        if (this.requisition.pedidoParcela.length <= 0) {
          message = 'É necessário adicionar uma ou mais parcelas para prosseguir!'
          this.showResponse('info', message)
          throw message
        }
      }
    }

    if (this.requisition.sugestaoFornecedores.length === 0) {
      message = 'É necessário inserir pelo menos 01 (um) fornecedor ou cliente!'
      this.showResponse('info', message)
      throw message
    }

    if (this.requisition.pedidoParcela.length) {
      this.requisition.pedidoParcela.forEach(item => {
        if ([null, undefined, ''].includes(item.dtVencimento)) {
          message = 'A data de vencimento das parcelas é inválida!'
          this.showResponse('info', message)
          throw message
        }
      })
    }

    if (!this.isTransfer) this.percentageValidation()

    return true
  }
}