import { AuthUser } from 'app/proof/model/auth-user.model';

export class PedidoTipo {
  id: number;
  description: string;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;


  constructor(pedidoTipo?: PedidoTipo | number) {
    if (pedidoTipo !== undefined && pedidoTipo !== null) {
      if (typeof pedidoTipo === 'number') {
        this.id = pedidoTipo;
      } else {
        this.id = pedidoTipo.id;
        this.description = pedidoTipo.description;
        this.createTimestamp = pedidoTipo.createTimestamp;
        this.changeUser = pedidoTipo.changeUser;
        this.changeTimestamp = pedidoTipo.changeTimestamp;
      }
    }
  }
}
