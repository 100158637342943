export class PlanoContasRfb {
  id: number;
  codCta: string;
  descrCta: string;
  dtIni: string;
  dtFin: string;
  tipoCta: string;
  nivelCta: string;
  codNatureza: string;
  utilizacao: string;

  parent: PlanoContasRfb;

  constructor(plano?: PlanoContasRfb | number) {
    if (plano !== undefined && plano !== null) {
      if (typeof plano === 'number') {
        this.id = plano;
      } else {
        this.id = plano.id;
        this.codCta = plano.codCta;
        this.descrCta = plano.descrCta;
        this.dtIni = plano.dtIni;
        this.dtFin = plano.dtFin;
        this.tipoCta = plano.tipoCta;
        this.nivelCta = plano.nivelCta;
        this.codNatureza = plano.codNatureza;
        this.utilizacao = plano.utilizacao;

        this.parent = plano.parent;
      }
    }
  }
}
