export class TpConfigRelat {
  id: number;
  nome: string;
  codigo: string;
  empresaId: number;

  constructor(tpConfigRelat?: TpConfigRelat | number) {
    if(tpConfigRelat !== undefined && tpConfigRelat !== null) {
      if(typeof tpConfigRelat === 'number') {
        this.id = tpConfigRelat;
      } else {
        this.id = tpConfigRelat.id;
        this.nome = tpConfigRelat.nome;
        this.codigo = tpConfigRelat.codigo;
        this.empresaId = tpConfigRelat.empresaId;
      }
    }
  }
}