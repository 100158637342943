export class NfeFinalidade {
  id: number;
  descricao: string;

  constructor(nfeFinalidade?: NfeFinalidade | number) {
    if (nfeFinalidade !== undefined && nfeFinalidade !== null) {
      if (typeof nfeFinalidade === 'number') {
        this.id = nfeFinalidade;
      } else {
        this.id = nfeFinalidade.id;
        this.descricao = nfeFinalidade.descricao;
      }
    }
  }
}
