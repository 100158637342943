export class TbCodMoeda {
  id: number;
  cod: string;
  nome: string;
  paisOrig: string;
  valueAlias: string;

  constructor(tbCodMoeda?: TbCodMoeda | number) {
    if (tbCodMoeda) {
      if (typeof tbCodMoeda === 'number') {
        this.id = tbCodMoeda;
      } else {
        this.id = tbCodMoeda.id;
        this.cod = tbCodMoeda.cod;
        this.nome = tbCodMoeda.nome;
        this.paisOrig = tbCodMoeda.paisOrig;
        this.valueAlias = tbCodMoeda.valueAlias;
      }
    }
  }
}
