import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { Nfe } from 'app/financeiro/model/nfe.model';
import { MovFinDetal } from 'app/financeiro/model/mov-fin-detal.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { TipoBaixa } from 'app/util/model/tipo-baixa.model';


export class NfeFin {
  id: number;
  nfe: Nfe;
  movFinDetal: MovFinDetal;
  parcela: number;
  dtVencimentoOriginal: Date;
  dtVencimento: Date;
  valor: number;
  empresaConta: EmpresaConta;
  dtPrevisao: Date;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;
  link: string;
  multa: number;
  juros: number;
  desconto: number;
  valorPrincipal: number;
  empresaContaId: number;
  documento: string;

  infos: any;

  constructor(nfeFin?: NfeFin | number) {
    if (nfeFin !== undefined && nfeFin !== null) {
      if (typeof nfeFin === 'number') {
        this.id = nfeFin;
      } else {
        this.id = nfeFin.id;
        this.nfe = nfeFin.nfe;
        this.movFinDetal = nfeFin.movFinDetal;
        this.parcela = nfeFin.parcela;
        this.dtVencimento = nfeFin.dtVencimento;
        this.dtVencimentoOriginal = nfeFin.dtVencimentoOriginal;
        this.valor = nfeFin.valor;
        this.empresaConta = nfeFin.empresaConta;
        this.dtPrevisao = nfeFin.dtPrevisao;
        this.createTimestamp = nfeFin.createTimestamp;
        this.changeUser = nfeFin.changeUser;
        this.changeTimestamp = nfeFin.changeTimestamp;
        this.infos = nfeFin.infos;
        this.link = nfeFin.link;
        this.multa = nfeFin.multa;
        this.juros = nfeFin.juros;
        this.desconto = nfeFin.desconto;
        this.valorPrincipal = nfeFin.valorPrincipal;
        this.empresaContaId = nfeFin.empresaContaId;
        this.documento = nfeFin.documento;
      }
    }
  }
}
