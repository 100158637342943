import { AuthUser } from 'app/proof/model/auth-user.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { OperacaoRegraFiscal } from 'app/cadastro/model/operacao-regra-fiscal.model';
import { OperacaoRegraContabil } from 'app/cadastro/model/operacao-regra-contabil.model';
import { TipoEntradaSaida } from 'app/util/model/tipo-entrada-saida.model';
import { OperacaoRegraContabilComponent } from '../operacao-regra-contabil/operacao-regra-contabil.component';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { EmpresaAlmoxarifado } from './empresa-almoxarifado.model';
import { IndNatFrt } from 'app/util/model/ind-nat-frt.model';
import { BcCalculoCredito } from 'app/util/model/bc-calculo-credito.model';

export class OperacaoRegra {
id: number;
cod: string;
descricao: string;
tipoEntradaSaida: TipoEntradaSaida;
empresa: Empresa;
geraFinanceiro: boolean = false;
controlaEstoque: boolean = false;
dtInicial: Date;
dtFinal: Date;
createTimestamp: Date;
changeUser: AuthUser;
changeTimestamp: Date;
operacaoRegraFiscal: OperacaoRegraFiscal;
operacaoRegraContabil: OperacaoRegraContabil;
adto: boolean = false;
transfEstoque: boolean = false;
permiteAlteracaoAlmoxarifado: boolean = false;
contaContabilEstoque: PlanoContas;
almoxarifadoOrigem: EmpresaAlmoxarifado;
almoxarifadoDestino: EmpresaAlmoxarifado;
alteraCusto: boolean = false;
dadosAdicionais: string;
tipoRegra: number;
almoxarifadoReserva: EmpresaAlmoxarifado;
utilizaReserva: boolean = false;
utilizaCustoMedio: boolean = false;
utilizaPrecoVenda: boolean = false;
criacaoOp: boolean = false;
transfFilial: boolean = false;
operacaoRegraFiscalEntrada: OperacaoRegraFiscal;
operacaoRegraContabilEntrada: OperacaoRegraContabil;
grupoEmpresaOperacaoRegraOrigemId: number
ativo: boolean
indNatFrt: IndNatFrt;
bcCalculoCredito: BcCalculoCredito;
bloqueiaFiscal: boolean;
utilizaBasePcXml: boolean;

constructor(operacaoRegra?: OperacaoRegra | number) {
    if (operacaoRegra !== undefined && operacaoRegra !== null) {
      if (typeof operacaoRegra === 'number') {
        this.id = operacaoRegra;
      } else {
        this.id = operacaoRegra.id;
        this.cod = operacaoRegra.cod;
        this.descricao = operacaoRegra.descricao;
        this.tipoEntradaSaida = operacaoRegra.tipoEntradaSaida;
        this.empresa = operacaoRegra.empresa;
        this.geraFinanceiro = operacaoRegra.geraFinanceiro;
        this.controlaEstoque = operacaoRegra.controlaEstoque;
        this.dtInicial = operacaoRegra.dtInicial;
        this.dtFinal = operacaoRegra.dtFinal;
        this.createTimestamp = operacaoRegra.createTimestamp;
        this.changeUser = operacaoRegra.changeUser;
        this.changeTimestamp = operacaoRegra.changeTimestamp;
        this.operacaoRegraFiscal = operacaoRegra.operacaoRegraFiscal;
        this.operacaoRegraContabil = operacaoRegra.operacaoRegraContabil;
        this.adto = operacaoRegra.adto;
        this.transfEstoque = operacaoRegra.transfEstoque;
        this.permiteAlteracaoAlmoxarifado = operacaoRegra.permiteAlteracaoAlmoxarifado;
        this.contaContabilEstoque = operacaoRegra.contaContabilEstoque;
        this.almoxarifadoOrigem = operacaoRegra.almoxarifadoOrigem;
        this.almoxarifadoDestino = operacaoRegra.almoxarifadoDestino;
        this.alteraCusto = operacaoRegra.alteraCusto;
        this.dadosAdicionais = operacaoRegra.dadosAdicionais;
        this.tipoRegra = operacaoRegra.tipoRegra;
        this.utilizaReserva = operacaoRegra.utilizaReserva;
        this.almoxarifadoReserva = operacaoRegra.almoxarifadoReserva;
        this.utilizaCustoMedio = operacaoRegra.utilizaCustoMedio;
        this.utilizaPrecoVenda = operacaoRegra.utilizaPrecoVenda
        this.criacaoOp = operacaoRegra.criacaoOp;
        this.transfFilial = operacaoRegra.transfFilial;
        this.operacaoRegraFiscalEntrada = operacaoRegra.operacaoRegraFiscalEntrada;
        this.operacaoRegraContabilEntrada =  operacaoRegra.operacaoRegraContabilEntrada;
        this.grupoEmpresaOperacaoRegraOrigemId = operacaoRegra.grupoEmpresaOperacaoRegraOrigemId;
        this.ativo = operacaoRegra.ativo
        this.indNatFrt = operacaoRegra.indNatFrt;
        this.bcCalculoCredito = operacaoRegra.bcCalculoCredito;
        this.bloqueiaFiscal = operacaoRegra.bloqueiaFiscal;
        this.utilizaBasePcXml = operacaoRegra.utilizaBasePcXml;
      }
    }
  }
}
