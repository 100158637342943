import { Empresa } from 'app/cadastro/model/empresa.model';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import {PlataformaPagamentos} from 'app/util/model/plataforma-pagamentos.model';

export class ConfiguracoesApis {
  id        : number;
  empresa   : Empresa;
  chaveApi  : string;
  codigo    : string;
  email     : string;
  usuario   : string;
  senha     : string;
  descricao : string;
  maxParcela    : number;
  empresaConta  : EmpresaConta;
  split         : boolean;
  marketplace   : boolean;
  ativo         : boolean;
  relacionado   : boolean;
  plataformaPagamentos : PlataformaPagamentos;
  boleto        : boolean;
  cartaoCredito : boolean;
  assinatura    : boolean;

  constructor(configuracoesApis?: ConfiguracoesApis | number) {
    if (configuracoesApis !== undefined && configuracoesApis !== null) {
      if (typeof configuracoesApis === 'number') {
        this.id = configuracoesApis;
      } else {
        this.id       = configuracoesApis.id;
        this.empresa  = configuracoesApis.empresa;
        this.chaveApi = configuracoesApis.chaveApi;
        this.codigo   = configuracoesApis.codigo;
        this.email    = configuracoesApis.email;
        this.usuario  = configuracoesApis.usuario;
        this.senha    = configuracoesApis.senha;
        this.descricao = configuracoesApis.descricao;
        this.maxParcela = configuracoesApis.maxParcela;
        this.empresaConta = configuracoesApis.empresaConta;
        this.split        = configuracoesApis.split;
        this.marketplace  = configuracoesApis.marketplace;
        this.ativo        = configuracoesApis.ativo;
        this.relacionado  = configuracoesApis.relacionado;
        this.plataformaPagamentos = configuracoesApis.plataformaPagamentos;
        this.boleto         = configuracoesApis.boleto;
        this.cartaoCredito  = configuracoesApis.cartaoCredito;
        this.assinatura     = configuracoesApis.assinatura;

      }
    }
  }
}
