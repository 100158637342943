import { Empresa } from "app/cadastro/model/empresa.model";

export class Seminarista {
  id       : number;
  empresa  : Empresa;
  nome     : String;
  email    : string;
  telefone : string;
  urlOutlookEmail : string;


  constructor(seminarista?: Seminarista | number) {
    if (seminarista !== undefined && seminarista !== null) {
      if (typeof seminarista === 'number') {
        this.id = seminarista;
      } else {
        this.id       = seminarista.id;
        this.empresa  = seminarista.empresa;
        this.nome     = seminarista.nome;
        this.email    = seminarista.email;
        this.telefone = seminarista.telefone;
        this.urlOutlookEmail = seminarista.urlOutlookEmail;
      }
    }
  }

}