import { PlataformaTipo } from './plataforma-tipo.model'
import { PlataformaPagamentosServicos } from "./plataforma-pagamentos-servicos.model";

export class PlataformaPagamentos {
  id        : number;
  descricao : string;
  ativo     : boolean;
  chave     : boolean;
  email     : boolean;
  usuario   : boolean;
  senha     : boolean;
  urlBase   : string;
  plataformaTipoId : number;
  plataformaTipo : PlataformaTipo;
  plataformaPagamentosServicos : Array<PlataformaPagamentosServicos>;

  constructor(plataformaPagamentos?: PlataformaPagamentos | number) {
    if (plataformaPagamentos !== undefined && plataformaPagamentos !== null) {
      if (typeof plataformaPagamentos === 'number') {
        this.id = plataformaPagamentos;
      } else {
        this.id         = plataformaPagamentos.id;
        this.descricao  = plataformaPagamentos.descricao;
        this.ativo      = plataformaPagamentos.ativo;
        this.chave      = plataformaPagamentos.chave;
        this.email      = plataformaPagamentos.email;
        this.usuario    = plataformaPagamentos.usuario;
        this.senha      = plataformaPagamentos.senha;
        this.urlBase    = plataformaPagamentos.urlBase;
        this.plataformaTipo = this.plataformaTipo;
        this.plataformaPagamentosServicos = this.plataformaPagamentosServicos;

      }
    }
  }
}
