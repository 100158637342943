import { DataTableColumn } from './model/data-table-column.model';
import { DataControl } from './model/data-control.model';
import { CrudService } from 'app/shared/services/crud.service';

export class DataTableBasicComponent {
  public columns: Array<DataTableColumn>;
  public controls: Array<DataControl>;
  public extras: Array<string>;

  constructor(private _crudService: CrudService) {
  }

  get editing(): boolean {
    return this._crudService.editing;
  }

  get viewing(): boolean {
    return this._crudService.viewing;
  }
  set viewing(viewing: boolean) {
    this._crudService.viewing = viewing;
  }

  get listing() {
    return !this.viewing;
  }
}
