import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { Message, MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-importar-arquivos',
  templateUrl: './importar-arquivos.component.html',
  styleUrls: ['./importar-arquivos.component.scss']
})


export class ImportarArquivoComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;

  private sessionSubscription: Subscription;
  file: File;
  replace: boolean;

  msgs: Message[]   = null;
  idTipoArquivo     : any;
  listTipoArquivo   : Array<any> = new Array();
  listImportar      : Array<any> = new Array();
  inicioPesquisa            : Number ;
  finalPesquisa             : Number ;
  idTipoArquivoSelecionado  : Number ;
  idAnoSelecionado          : Number = 0;




  constructor(private titleService: TitleService,
              private httpService: HttpService,
              private toolbarService: ToolbarService,
              private dblinkedSessionService: DblinkedSessionService,
              private sessionService: SessionService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.titleService.title = 'Importar arquivo';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    // this.toolbarService.clear();


    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();

    });

  }


  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

        this.listTipoArquivo = [];
        this.listTipoArquivo.push({label: 'Selecionar o arquivo', value: 0});
        this.listTipoArquivo.push({label: 'CSV UTF-8 com BOM', value: 1});

        }

    }


  ngOnDestroy() {
    this.toolbarService.hidden = false;
    // this.toolbarService.clear();
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];

  }

  importar() {

    this.removerAcentos(this.file.name.toString());

    if (this.validacao()) {
      return;
    }



    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('type', 'polozi-participante-evento');
    data.append('month', this.dblinkedSessionService.periodo.month.value.toString());
    data.append('year', this.dblinkedSessionService.periodo.year.value.toString());
    data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
    data.append('usuarioId', this.sessionService.loggedUser.id.toString());
    data.append('configuracaoImportacaoId', this.idTipoArquivo.toString() );
    data.append('tipoAnexo', this.file.type);


    this.httpService.wait();
    this.httpService.post('/custom/upload', data)
                    .subscribe(result => this.uploaded(result),
                              error => this.httpService.handleError(error, () => this.importar()),
                              () => this.httpService.done());

  }

  validacao(){

    if (this.idTipoArquivo === 0 || isNullOrUndefined( this.idTipoArquivo) ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar o tipo de arquivo!'
      });
      return true;
    }


    if (!this.file || isNullOrUndefined( this.file) ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um arquivo!'
      });
      return true;
    }

    if (!this.verifaExtensaoArquivoCsv(this.file.name)) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um arquivo de extensão .CSV'
      });
      return true;
    }

    return false;
  }

  verifaExtensaoArquivoExcel(arquivoNome: string): boolean{

    if (arquivoNome.substring(arquivoNome.lastIndexOf(".") + 1, arquivoNome.length).toLocaleLowerCase() ==='xls') {
      return true;
    }

    if (arquivoNome.substring(arquivoNome.lastIndexOf(".") + 1, arquivoNome.length).toLocaleLowerCase() ==='xlsx') {
      return true;
    }

    return false;

  }


  verifaExtensaoArquivoTxt(arquivoNome: string): boolean{

    if (arquivoNome.substring(arquivoNome.lastIndexOf(".") + 1, arquivoNome.length).toLocaleLowerCase() ==='txt') {
      return true;
    }

    return false;

  }

  private uploaded(res?: any) {


    console.log(res);

    if (res.error === true) {

      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Advertência', detail: res.result});
      this.file = null;
      this.ngOnInit();

    } else {
      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: res.result});
      this.file = null;
      this.ngOnInit();

    }

  }

  get importDisabled(): boolean {

    return !this.dblinkedSessionService.hasEmpresaSelected || !this.file;
  // return !this.dblinkedSessionService.hasEmpresaSelected ;
  }

  hideMessage() {
    this.file = null;

  }


  verifaExtensaoArquivoCsv(arquivoNome: string): boolean{

    if (arquivoNome.substring(arquivoNome.lastIndexOf(".") + 1, arquivoNome.length).toLocaleLowerCase() ==='csv') {
      return true;
    }

    return false;

  }

removerAcentos(value : string) :string{
 var  comAcentos :string = "ÄÅÁÂÀÃäáâàãÉÊËÈéêëèÍÎÏÌíîïìÖÓÔÒÕöóôòõÜÚÛüúûùÇç";
 var  semAcentos :string = "AAAAAAaaaaaEEEEeeeeIIIIiiiiOOOOOoooooUUUuuuuCc";

 value = value.replace(/Ä/g,"A" );
 value = value.replace(/Å/g,"A" );
 value = value.replace(/Á/g,"A" );
 value = value.replace(/Â/g,"A" );
 value = value.replace(/À/g,"A" );
 value = value.replace(/Ã/g,"A" );


 value = value.replace(/ä/g,"a" );
 value = value.replace(/á/g,"a" );
 value = value.replace(/â/g,"a" );
 value = value.replace(/à/g,"a" );
 value = value.replace(/ã/g,"a" );

 value = value.replace(/É/g,"E" );
 value = value.replace(/Ê/g,"E" );
 value = value.replace(/Ë/g,"E" );
 value = value.replace(/È/g,"E" );

 value = value.replace(/é/g,"e" );
 value = value.replace(/ê/g,"e" );
 value = value.replace(/ë/g,"e" );
 value = value.replace(/è/g,"e" );


 value = value.replace(/Í/g,"I" );
 value = value.replace(/Î/g,"I" );
 value = value.replace(/Ï/g,"I" );
 value = value.replace(/Ì/g,"I" );

 value = value.replace(/í/g,"i" );
 value = value.replace(/î/g,"i" );
 value = value.replace(/ï/g,"i" );
 value = value.replace(/ì/g,"i" );


 value = value.replace(/Ö/g,"O" );
 value = value.replace(/Ó/g,"O" );
 value = value.replace(/Ô/g,"O" );
 value = value.replace(/Ò/g,"O" );
 value = value.replace(/Õ/g,"O" );


 value = value.replace(/ö/g,"o" );
 value = value.replace(/ó/g,"o" );
 value = value.replace(/ô/g,"o" );
 value = value.replace(/ò/g,"o" );
 value = value.replace(/õ/g,"o" );


 value = value.replace(/Ü/g,"U" );
 value = value.replace(/Ú/g,"U" );
 value = value.replace(/Û/g,"U" );
 value = value.replace(/Ù/g,"U" );

 value = value.replace(/ü/g,"u" );
 value = value.replace(/ú/g,"u" );
 value = value.replace(/û/g,"u" );
 value = value.replace(/ù/g,"u" );

 value = value.replace(/Ç/g,"C" );
 value = value.replace(/ç/g,"c" );

 return value;
}



}
