import { AuthApp } from './auth-app.model';
import { AuthFeatureUrl } from './auth-feature-url.model';
import { AuthGroup } from './auth-group.model';

export class AuthFeature {
  id: number;
  name: string;
  description: string;
  active = true;
  changeTimestamp: number;
  authApp: AuthApp = new AuthApp();
  authGroups: Array<AuthGroup> = new Array();
  authFeatureUrls: Array<AuthFeatureUrl> = new Array();

  constructor(authFeature?: AuthFeature | number) {
    if (authFeature !== undefined && authFeature !== null) {
        if (typeof authFeature === 'number') {
        this.id = authFeature;
      } else {
        this.id = authFeature.id;
        this.name = authFeature.name;
        this.description = authFeature.description;
        this.active = authFeature.active;
        this.changeTimestamp = authFeature.changeTimestamp;
        this.authApp = authFeature.authApp || new AuthApp();
        this.authGroups = authFeature.authGroups || new Array();
        this.authFeatureUrls = authFeature.authFeatureUrls || new Array();
      }
    }
  }
}
