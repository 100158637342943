import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Router } from "@angular/router"
import { ConfigService } from "app/shared/services/config.service"
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service"
import { HttpService } from "app/shared/services/http.service"
import { SessionService } from "app/shared/services/session.service"
import { MessageService } from "primeng/api"

export default class ShortcutsMethods {
  costCentersList = []
  projectsList = []
  accountsList = []

  data: Array<any> = new Array()

  constructor(
    protected router: Router,
    protected configService: ConfigService,
    protected httpClient: HttpClient,
    protected dblinkedSessionService: DblinkedSessionService,
    protected httpService: HttpService,
    protected sessionService: SessionService,
    protected messageService: MessageService
  ) { }

  protected get shortcutsMethods() {
    return {
      newPayment: () => this.newPayment(),
      newReceivement: () => this.newReceivement(),
      newLaunch: () => this.newLaunch(),
      goApprovals: () => this.goApprovals(),
      newRequest: () => this.newRequest(),
      newProductPurchases: () => this.newProductPurchases(),
      newProductSales: () => this.newProductSales(),
      newServicePurchases: () => this.newServicePurchases(),
      newServiceSales: () => this.newServiceSales(),
      reportBillsToPay: () => this.reportBills('CP'),
      reportBillsToReceive: () => this.reportBills('CR'),
      reportMonthBudget: () => this.reportAccounting('balanceteMensal'),
      reportLedger: () => this.reportAccounting('razaoContabil'),
      reportMonthEvolution: () => this.reportAccounting('evolucaoMensal'),
    }
  }

  private newPayment(): void {
    this.router.navigate(['/receb-pagto/cp', { action: 'newPayment' }])
  }

  private newReceivement(): void {
    this.router.navigate(['/receb-pagto/cr', { action: 'newReceivement' }])
  }

  private newLaunch(): void {
    this.router.navigate(['/contabil/lancamento', { action: 'newLaunch' }])
  }

  private goApprovals(): void {
    this.router.navigate(['/centralweb/work-flow/central-aprovacao'])
  }

  private newRequest(): void {
    this.router.navigate(['/rms/requisicao-material', { action: 'newRequest' }])
  }

  private newProductPurchases(): void {
    this.router.navigate(['/pedido-produto-new/pedido-produto-new/0', { action: 'newProductPurchases' }])
  }

  private newProductSales(): void {
    this.router.navigate(['/pedido-produto-new/pedido-produto-new/1', { action: 'newProductSales' }])
  }

  private newServicePurchases(): void {
    this.router.navigate(['/pedido-servico-new/pedido-servico-new/0', { action: 'newServicePurchases' }])
  }

  private newServiceSales(): void {
    this.router.navigate(['/pedido-servico-new/pedido-servico-new/1', { action: 'newServiceSales' }])
  }

  private reportAccounting(selectedReport: string): void {
    const loadLists = this.costCentersList.length == 0 && this.projectsList.length == 0 && this.accountsList.length == 0
    if (loadLists) Promise.all([
      this.loadProjects(),
      this.loadAccounts(),
      this.loadCostCenter()
    ]).then(() => this.generateReport(selectedReport))
    else this.generateReport(selectedReport)
  }

  private async reportBills(type: string): Promise<void> {
    const date = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)

    const payload = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      dt_corte: date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      tipo: type
    }

    const httpOptions2 = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'Cache-Control': 'no-store, max-age=0'
      }),
      responseType: 'blob' as 'json'
    }

    try {
      this.httpService.wait()
      await this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/relat-titulos', payload).toPromise().then(res => {
        if (res["error"]) {
          this.showResponse('error', res.response || res.message || res.mensagem || res.msg)
          return
        }

        this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/relat-titulos', httpOptions2).toPromise().then((resp: Blob) => {
          if(resp['error']) {
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(res)
            const fileName = 'relatorio_cr_cp.xlsx'

            link.href = url
            link.download = fileName
            link.click()

            window.URL.revokeObjectURL(url)
          }
          else this.showResponse('error', resp['response'] || resp['message'] || resp['mensagem'] || resp['msg'])
        }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao gerar seu relatório! Contate o suporte'))

      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao gerar seu relatório! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.httpService.done()
    }
  }

  private async generateReport(selectedReport: string): Promise<void> {
    let start: string, final: string , finalMonth: number, dateStart: Date, dateFinal: Date

    const yearSelected = this.dblinkedSessionService.periodo.year.value
    const monthSelected = this.dblinkedSessionService.periodo.month.value

    dateStart = new Date(yearSelected, monthSelected - 1, 1)
    dateFinal = new Date(yearSelected, monthSelected, 0)
    finalMonth = (dateFinal.getMonth()+1)
    start = yearSelected  + '-' + (finalMonth < 10 ? '0' + finalMonth : finalMonth) + '-01'
    final = yearSelected + '-' + (finalMonth < 10 ? '0' + finalMonth : finalMonth) + '-' + new Date(yearSelected, finalMonth, 0).getDate().toString()

    const listasEnviar = {
      cnpj: this.dblinkedSessionService.empresa.cnpj,
      selectedReport: selectedReport,
      ano: this.dblinkedSessionService.periodo.year.value,
      final: final,
      antesEncerramento: false,
      visaoGerencial: false,
      centrosCusto: this.costCentersList,
      planoContas: this.accountsList,
      projetos: this.projectsList,
      usuario: this.sessionService.loggedUser.id,
      dtIni: dateStart,
      dtFin: dateFinal,
      mesCorte: monthSelected,
      separaCentroCusto: false,
      somenteResultado: false,
      idioma: 'pt-BR',
      inicio: start,
      acumuado: false
    }

    try {
      this.httpService.wait()
      await this.httpService.post('/custom/ecd-report/export-xls-plano-contas', listasEnviar).toPromise().then(res => {
        if (!res.error) window.open(this.httpService.url('/custom/file/' + res.file), '_blank')
        else this.showResponse('error', res.response || res.message || res.mensagem || res.msg)
      }).catch(err => this.showResponse('error', err.status + ' - Houve um erro ao gerar seu relatório! Contate o suporte'))
    } catch(err) {
      this.showResponse('error', 'Falha na comunicação com o servidor!')
    } finally {
      this.httpService.done()
    }
  }

  private async loadAccounts(): Promise<void> {
    if (this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id) {
      try {
        this.httpService.wait()
        await this.httpService.get('/custom/cadastro/get-planocontas-completo', [this.dblinkedSessionService.empresa.id, this.dblinkedSessionService.periodo.year.value]).toPromise().then(res => {
          this.accountsList = [null]
          res.forEach(item => { if (item.indCta === 'A') this.accountsList.push(item.id) })
        }).catch(err => this.showResponse('error', err.status + 'Houve um erro ao carregar as contas! Contate o suporte'))
      } catch(err) {
        this.showResponse('error', 'Falha na comunicação com o servidor!')
      } finally {
        this.httpService.done()
      }
    }
    else this.showResponse('error', 'Selecione uma empresa para prosseguir!')
  }

  private async loadProjects(): Promise<void> {
    if (this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id) {
      try {
        this.httpService.wait()
        await this.httpService.get('/cadproj?$select=id,codProj,descrProj&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=codProj').toPromise().then(res => {
          this.projectsList = [null]
          res.value.forEach(item => this.projectsList.push(item.id))
        }).catch(err => this.showResponse('error', err.status + 'Houve um erro ao carregar os projetos! Contate o suporte'))
      } catch(err) {
        this.showResponse('error', 'Falha na comunicação com o servidor!')
      } finally {
        this.httpService.done()
      }
    }
    else this.showResponse('error', 'Selecione uma empresa para prosseguir!')
  }

  private async loadCostCenter(): Promise<void> {
    if (this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id) {
      try {
        this.httpService.wait()
        await this.httpService.get('/cadproj?$select=id,codProj,descrProj&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=codProj').toPromise().then(res => {
          this.costCentersList = [null]
          res.value.forEach(item => this.costCentersList.push(item.id))
        }).catch(err => this.showResponse('error', err.status + 'Houve um erro ao carregar os centro de custo! Contate o suporte'))
      } catch(err) {
        this.showResponse('error', 'Falha na comunicação com o servidor!')
      } finally {
        this.httpService.done()
      }
    }
    else this.showResponse('error', 'Selecione uma empresa para prosseguir!')
  }

  protected showResponse(severity: string, message: string): void {
    switch (severity) {
      case 'success':
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso: ', detail: message })
      break
      case 'error':
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: message })
      break
      case 'warn':
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: message })
      break
      case 'info':
        this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Atenção: ', detail: message })
      break
    }
  }
}