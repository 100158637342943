import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { CheckList } from '../model/check-list.model';
import { Message, MessageService } from '../../../../node_modules/primeng/api';
import { UtilService } from '../../shared/services/util.service';
import { Recorrencia } from '../../util/model/recorrencia.model';
import { CheckListItem } from '../model/check-list-item.model';
import { Usuario } from '../../cadastro/model/usuario.model';

@Component({
  selector: 'app-checklist',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss'],
  providers: [CrudService]
})

export class CheckListComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;

  private sessionSubscription: Subscription;

  constructor(private configService: ConfigService,
    private titleService: TitleService,
    private httpService: HttpService,
    private utilService: UtilService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }

  title: string;
  subtitle: string;
  msgs: Message[] = [];
  msgsModal: Message[] = [];
  modalNovo: boolean;
  defaultDate: Date;
  checkList: CheckList;
  colunas: any[];
  listarItens: Array<any> = new Array();
  itens: Array<any> = new Array();
  conteudoItem: string;
  conteudoItemEditar: string;
  file: File;
  files: Array<any>;
  listaInicial: Array<any> = new Array();
  listaAndamento: Array<any> = new Array();
  listaConcluido: Array<any> = new Array();
  idCheck: Array<any> = new Array();
  porcentagem: any;
  responsavel: number;
  editar: boolean;

  // recorrencia
  recorrencias: Array<Recorrencia>;
  recorrenciasEditar: Array<Recorrencia>;
  exbirModalRecorrencia: boolean;
  dtIni: Date;
  qtdeOcorrencia: number;
  aCada: number;
  diaMes: number;
  dtFin: Date;
  qtdes: any[];
  dias: any[];

  dom: boolean;
  seg: boolean;
  ter: boolean;
  qua: boolean;
  qui: boolean;
  sex: boolean;
  sab: boolean;
  antecipaDiaNaoUtil: boolean;
  diaUtil: boolean;
  diaUtilSabado: boolean;
  // final recorrencia
  usuarios: any[];
  showItemId: any;
  inputItemId: any;
  idAlterar: number;

  // exportar excel
  colsChecklistNaoIniciado: any[];
  colsChecklistEmAndamento: any[];
  colsChecklistConcluido: any[];

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.modalNovo = false;
    this.exbirModalRecorrencia = false;
    this.checkList = new CheckList();
    this.checkList.usuario = new Usuario();
    // this.toolbarService.clear();
    this.titleService.title = 'CentralWeb - CheckList';
    this.title = 'CHECK LIST DE OBRIGAÇÕES';
    this.subtitle = 'CADASTRE, ALTERE OU EXCLUA OS CHECK LIST DE OBRIGAÇÕES';

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.listar(); });

      this.colsChecklistNaoIniciado = [
        { field: 'dataCorte', header: 'DATA' },
        { field: 'titulo', header: 'TÍTULO' },
        { field: 'descricao', header: 'DESCRIÇÃO' },
        { field: 'andamento', header: 'ANDAMENTO' },
        { field: 'usuario', header: 'RESPONSÁVEL' },
        { field: 'status', header: 'STATUS' }
    ];

      this.colsChecklistEmAndamento = [
        { field: 'dataCorte', header: 'DATA' },
        { field: 'titulo', header: 'TÍTULO' },
        { field: 'descricao', header: 'DESCRIÇÃO' },
        { field: 'andamento', header: 'ANDAMENTO' },
        { field: 'usuario', header: 'RESPONSÁVEL' },
        { field: 'status', header: 'STATUS' }
      ];

      this.colsChecklistConcluido = [
        { field: 'dataCorte', header: 'DATA' },
        { field: 'titulo', header: 'TÍTULO' },
        { field: 'descricao', header: 'DESCRIÇÃO' },
        { field: 'andamento', header: 'ANDAMENTO' },
        { field: 'usuario', header: 'RESPONSÁVEL' },
        { field: 'status', header: 'STATUS' }
      ];

  }

  inicializar() {


    this.modalNovo = false;
    this.editar = false;
    this.defaultDate = new Date();
    this.conteudoItem = '';
    this.checkList = new CheckList();
    this.checkList.usuario = new Usuario();

    this.listaInicial = new Array<any>();
    this.listaAndamento = new Array<any>();
    this.listaConcluido = new Array<any>();
    this.idCheck = new Array();
    this.listarItens = new Array<any>();
    this.porcentagem = 0.00;
    this.files = [];
    this.responsavel = 0;

    // recorrencia
    this.recorrencias = new Array();
    this.checkList.recorrencia = new Recorrencia();
    this.recorrenciasEditar = [];
    this.exbirModalRecorrencia = false;
    this.dtIni = new Date();
    this.dtFin = new Date();
    this.qtdeOcorrencia = 0;
    this.aCada = 1;
    this.diaMes = 1;
    this.qtdes = [];

    this.dom = false;
    this.seg = false;
    this.ter = false;
    this.qua = false;
    this.qui = false;
    this.sex = false;
    this.sab = false;
    this.antecipaDiaNaoUtil = false;
    this.diaUtil = false;
    this.diaUtilSabado = false;
    this.showItemId = -1;
    this.inputItemId = -1;

    this.dias = [];
    for (let dia = 1;  dia <= 31; dia++) {
      this.dias.push({ label:  dia, value: dia });
    }
    for (let qdt = 1;  qdt <= 10; qdt++) {
      this.qtdes.push({ label:  qdt, value: qdt });
    }
    this.comboUsuarios();
  }

  listar() {

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasYearSelected &&
      this.dblinkedSessionService.usuario) {

        this.inicializar();

        if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
          const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
            this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
          const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
          this.defaultDate = new Date(dt);
          // this.checkList.dataCorte = this.defaultDate;
          this.dtIni = this.defaultDate;
          this.dtFin = this.defaultDate;
        } else {
          // this.checkList.dataCorte = (new Date());
          this.dtIni = new Date();
          this.dtFin = new Date();
        }

        // recorrencia
        this.httpService.wait();
        this.httpService.get('/recorrencia?$select=id,cod,descricao&$orderby=id')
          .subscribe(ref => this.loadRecorrencia(ref.value),
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());

      const listasEnviar = {
        empresa: this.dblinkedSessionService.empresa.id,
        usuario: this.dblinkedSessionService.usuario.id,
        ano: this.dblinkedSessionService.periodo.year.value,
        mes: this.dblinkedSessionService.periodo.month.value
      };
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/check-list-listar', listasEnviar)
      .subscribe(result => {
        this.loadLista(result);
      },
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());
    }
  }

  loadLista(value: Array<any>) {
    this.listaInicial = new Array<any>();
    this.listaAndamento = new Array<any>();
    this.listaConcluido = new Array<any>();
    value.forEach(element => {
      if (element.status === 'Não Iniciado') {
        this.listaInicial.push(element);
      } else if (element.status === 'Em andamento') {
        this.listaAndamento.push(element);
      } else {
        this.listaConcluido.push(element);
      }
      // this.lista.push(element);
    });

    this.toolbarRefresh();
    this.responsavel = this.dblinkedSessionService.usuario.id;
  }

  addItem() {

    if (!this.checkList.titulo) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Preencha o título para prosseguir!'
      });
      return;
    }

    this.conteudoItem = '';
    document.getElementById('addItem').style.display = 'none';
    document.getElementById('textoItem').style.display = 'block';
    this.showItemId = -1;
    this.inputItemId = -1;
  }

  addCheckList() {

    if (this.dblinkedSessionService.hasEmpresaSelected) {
      if (!this.checkList.titulo) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Preencha o título para prosseguir!'
        });
        return;
      }
      if (!this.editar) {
        if (this.checkList.recorrencia) {
          if (!(this.checkList.recorrencia.id > 0)) {
            this.messageService.add({
              severity: 'warn',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Selecione a recorrência para prosseguir!'
            });
            return;
          }
        } else {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Selecione a recorrência para prosseguir!'
          });
          return;
        }
      }

      const listasEnviar = {
        empresa: this.dblinkedSessionService.empresa.id,
        responsavel: this.responsavel,
        dataCorte: this.checkList.dataCorte,
        titulo: this.checkList.titulo,
        descricao: this.checkList.descricao,
        checklist: this.checkList.id,
        usuario: this.sessionService.loggedUser.id
      };
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/check-list-salvar', listasEnviar)
          .subscribe(result => {
            // this.checkList.id = result.id;
            this.listar();
          },
          error => this.httpService.handleError(error, () => this.addCheckList()),
          () => this.httpService.done());
    }

  }

  adicionarItem() {

    if ( this.conteudoItem.length > 0 ) {

      if (this.dblinkedSessionService.hasEmpresaSelected) {

        const listasEnviar = {
          empresa: this.dblinkedSessionService.empresa.id,
          responsavel: this.responsavel,
          conteudoItem: this.conteudoItem,
          checklist: this.checkList.id,
          dataCorte: this.checkList.dataCorte,
          usuario: this.sessionService.loggedUser.id,
          titulo: this.checkList.titulo,
          descricao: this.checkList.descricao
        };

        this.httpService.wait();
        this.httpService.post('/custom/centralweb/check-list-item-adicionar', listasEnviar)
            .subscribe(result => {
              this.checkList.id = result.id;
              this.httpService.get('checklistitem?$select=id,descricao,excluido,concluido,checkList/id' +
              '&$filter=checkList/id  eq ' + this.checkList.id + ' and excluido eq false&$orderby=id')
                .subscribe(ref => {  this.setarItens(ref.value); },
                error => this.httpService.handleError(error, () => this.adicionarItem()),
                () => this.httpService.done());
            },
            error => this.httpService.handleError(error, () => this.adicionarItem()),
            () => this.httpService.done());
      }

      this.conteudoItem = '';

    } else {

      this.messageService.add({severity: 'error',key: 'messageLinnks', summary: 'MSG: ', detail: ' Campo item não pode ser vazio!'});

    }

  }

  fecharIncluir() {
    document.getElementById('addItem').style.display = 'block';
    document.getElementById('textoItem').style.display = 'none';
    this.conteudoItem = '';
  }

  editarCheckList(event) {
    this.editar = true;
    this.files = [];
    this.checkList.recorrencia = new Recorrencia();
    document.getElementById('listaItens').style.display = 'none';
    this.checkList = event;
    this.checkList.dataCorte = new Date(event.dataCorte);
    this.responsavel = event.usuarioId;
    this.modalNovo = true;

    this.httpService.wait();
    this.httpService.get('checklistitem?$select=id,descricao,excluido,concluido,checkList/id' +
                        '&$filter=checkList/id  eq ' + event.id + ' and excluido eq false&$orderby=id')
                          .subscribe(ref => { this.setarItens(ref.value); this.uploaded();
                          },
                          error => this.httpService.handleError(error, () => this.editarCheckList(event)),
                          () => this.httpService.done());
  }

  finalizarcheckList(ids: Array<any>) {

    if (confirm('Deseja finalizar o(s) Check List selecionado(s)?')) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/check-list-finalizar(' + this.dblinkedSessionService.empresa.id + ')', ids)
      .subscribe(result => {
        this.messageService.add({
          severity: result.error ? 'error' : 'success',
          summary: 'MSG: ',
          detail: result.message || 'Exclusão realizada com sucesso!'
        });
         this.listar();
      },
      error => this.httpService.handleError(error, () => this.finalizarcheckList(ids)),
      () => this.httpService.done());
    } else {
      this.listar();
    }
  }

  excluirCheckList(ids: Array<any>) {

    if (confirm('Deseja realmente excluir o(s) Check List selecionado(s)?')) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/check-list-excluir(' + this.dblinkedSessionService.empresa.id + ')', ids)
      .subscribe(result => {
        this.messageService.add({
          severity: result.error ? 'error' : 'success',
          summary: 'MSG: ',
          detail: result.message || 'Exclusão realizada com sucesso!'
        });
         this.listar();
      },
      error => this.httpService.handleError(error, () => this.excluirCheckList(ids)),
      () => this.httpService.done());
    }
  }

  removerCheckList() {
    this.idCheck = new Array();
    if (this.checkList.id > 1) {
      if (confirm('Deseja realmente excluir o Check List?')) {
        this.idCheck.push(this.checkList.id);
        this.httpService.wait();
        this.httpService.post('/custom/centralweb/check-list-excluir(' + this.dblinkedSessionService.empresa.id + ')', this.idCheck )
        .subscribe(result => {
          this.messageService.add({
            severity: result.error ? 'error' : 'success',
            summary: 'MSG: ',
            detail: result.message || 'Exclusão realizada com sucesso!'
          });
          this.listar();
        },
        error => this.httpService.handleError(error, () => this.removerCheckList()),
        () => this.httpService.done());
      }
    }
  }

  setarItens(value: Array<any>) {
    this.itens = new Array<CheckListItem>();
    this.listarItens = [];
    this.porcentagem = 0;
    let count: number;
    count = 0;
    if ( value.length > 0 ) {
      value.forEach(element => {
        this.listarItens.push(element);
        if (element.concluido) {
          count++;
        }
      });
      if (count > 0) {
        this.porcentagem = Math.trunc(( count * 100 ) / value.length );
      }
      this.itens = this.listarItens;
      document.getElementById('listaItens').style.display = 'block';
    }
  }

  concluirItem(item: any) {
    this.showItemId = -1;
    this.inputItemId = -1;
    this.idAlterar = 0;
    this.conteudoItemEditar = '';
    this.conteudoItem = '';
    const status: boolean = !item.concluido;
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/check-list-item-fechar(' + item.id + ',' + status + ')')
      .subscribe(() => {
        this.httpService.get('checklistitem?$select=id,descricao,excluido,concluido,checkList/id' +
              '&$filter=checkList/id  eq ' + this.checkList.id + ' and excluido eq false&$orderby=id')
                .subscribe(ref => {  this.setarItens(ref.value); },
                error => this.httpService.handleError(error, () => this.listar()),
                () => this.httpService.done());
      },
        error => this.httpService.handleError(error, () => this.concluirItem(item)),
        () => this.httpService.done());

  }

  alterarItemDiv(i: any, item: any) {
    this.showItemId = i;
    this.inputItemId = i;
    this.idAlterar = item.id;
    this.conteudoItemEditar = item.descricao;
  }

  alterarItem() {

      const listasEnviar = {
        itemId: this.idAlterar,
        conteudoItem: this.conteudoItemEditar,
        checklistId: this.checkList.id
      };
        this.httpService.wait();
        this.httpService.post('/custom/centralweb/check-list-item-alterar', listasEnviar)
        .subscribe(result => {
          this.messageService.add({
            severity: result.error ? 'error' : 'success',
            summary: 'MSG: ',
            detail: result.message || 'Item do Check List alterado com sucesso!'
          });

        this.httpService.get('checklistitem?$select=id,descricao,excluido,concluido,checkList/id' +
              '&$filter=checkList/id  eq ' + this.checkList.id + ' and excluido eq false&$orderby=id')
                .subscribe(ref => {  this.setarItens(ref.value);
                  this.showItemId = -1;
                  this.inputItemId = -1;
                  this.idAlterar = 0;
                  this.conteudoItemEditar = '';
                  this.conteudoItem = '';
                },
                error => this.httpService.handleError(error, () => this.listar()),
                () => this.httpService.done());
        },
        error => this.httpService.handleError(error, () => this.alterarItem()),
        () => this.httpService.done());
  }

  removeItem(idItem: number) {
    this.showItemId = -1;
    this.inputItemId = -1;
    this.idAlterar = 0;
    this.conteudoItemEditar = '';
    this.conteudoItem = '';
    if (confirm('Deseja excluir este item?')) {
      this.httpService.wait();
      this.httpService.get('/custom/centralweb/check-list-item-excluir(' + idItem + ')')
      .subscribe( result => {
        this.messageService.add({
          severity: result.error ? 'error' : 'success',
          summary: 'MSG: ',
          detail: result.message || 'Item do Check List alterado com sucesso!'
        });
        this.httpService.get('checklistitem?$select=id,descricao,excluido,concluido,checkList/id' +
              '&$filter=checkList/id  eq ' + this.checkList.id + ' and excluido eq false&$orderby=id')
                .subscribe(ref => {  this.setarItens(ref.value); },
                error => this.httpService.handleError(error, () => this.listar()),
                () => this.httpService.done());
      },
        error => this.httpService.handleError(error, () => this.removeItem(idItem)),
        () => this.httpService.done());
    }
  }

  onFilter(event: any) {

  }

  select(id: any, event: any) {
    const index = this.idCheck.findIndex(c => c === id);
    if (event.selected) {
        this.idCheck.splice(index, 1);
        event.selected = true;
    } else {
        this.idCheck.push(id);
        event.selected = false;
    }

    if (this.idCheck.length === 1) {
      // this.toolbarService.clear();
      // this.toolbarService.add('refresh', false, 'class-refresh-button', 'refresh', 'Atualizar', () =>  this.listar());
      this.toolbarService.alterarStatus('atualizar', false, 'Atualizar', () => this.listar());

      if (this.dblinkedSessionService.usuario.nivel >= 3 && event.status === 'Concluído' ) {
       // this.toolbarService.add('check', false, 'class-search-button', 'check', 'Finalizar Check List',
       //                         () =>  this.finalizarcheckList(this.idCheck));
       this.toolbarService.alterarStatus('check', false, 'Finalizar Check List', () => this.finalizarcheckList(this.idCheck));
      }
      // this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar', );
      // this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir', );
      if (this.dblinkedSessionService.usuario.nivel >= 3 || event.usuarioId === this.dblinkedSessionService.usuario.id) {

        this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.excluirCheckList(this.idCheck));
        this.toolbarService.alterarStatus('edit', false, 'Editar', () => this.editarCheckList(event));

        // this.toolbarService.add('remove', false, 'class-remove-button', 'delete', 'Excluir', () => this.excluirCheckList(this.idCheck));
        // this.toolbarService.add('edit', false, 'class-edit-button', 'edit', 'Editar!', () => this.editarCheckList(event));
      } else {
        this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirCheckList(this.idCheck));
        this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.editarCheckList(event));

        // this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.excluirCheckList(this.idCheck));
        // this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!', () => this.editarCheckList(event));
      }

      // this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Criar novo check list', () => (this.modalNovo = true));
      // this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar novo check list', () => (this.modalNovo = true));

    } else if (this.idCheck.length > 1) {

      // this.toolbarService.clear();
      // this.toolbarService.add('refresh', false, 'class-refresh-button', 'refresh', 'Atualizar', () =>  this.listar());
      this.toolbarService.alterarStatus('atualizar', false, 'Atualizar', () => this.listar());

      if (this.dblinkedSessionService.usuario.nivel >= 3 && event.status === 'Concluído' ) {
        // this.toolbarService.add('check', false, 'class-search-button', 'check', 'Finalizar Check List',
        //                        () =>  this.finalizarcheckList(this.idCheck));
        this.toolbarService.alterarStatus('checar', false, 'Finalizar Check List', () => this.finalizarcheckList(this.idCheck));
      }

      // this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar', );
      // this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir', );

      if (this.dblinkedSessionService.usuario.nivel >= 3 || event.usuarioId === this.dblinkedSessionService.usuario.id) {
        this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.excluirCheckList(this.idCheck));
        // this.toolbarService.add('remove', false, 'class-remove-button', 'delete', 'Excluir', () => this.excluirCheckList(this.idCheck));
      } else {
        this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirCheckList(this.idCheck));
        // this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.excluirCheckList(this.idCheck));
      }
      this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.editarCheckList(event));

      // this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar novo check list', () => (this.modalNovo = true));
      /*this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!', () => this.editarCheckList(event));
      this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Criar novo check list', () => (this.modalNovo = true));*/

    } else {

      setTimeout(() => {
       this.toolbarRefresh();

      },500);

    }
  }

  hideMessage() {


  }

  importarArquivo() {

      const data: FormData = new FormData();
      data.append('file', this.file);
      data.append('type', 'cl');
      data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
      data.append('checkListId', this.checkList.id.toString());
      this.httpService.wait();
      this.httpService.post('/custom/upload', data)
        .subscribe(result => {
          this.uploaded();
          this.fileInput.nativeElement.value = '';
          this.file = undefined;
        },
        error => this.httpService.handleError(error, () => this.importarArquivo()),
        () => this.httpService.done());
        Promise.resolve(null).then(() =>

        setTimeout(() => {
          this.toolbarRefresh();

         },500));

  }

  private uploaded() {
    const data: FormData = new FormData();
    data.append('checkListId', this.checkList.id.toString());
    data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
    this.httpService.wait();
    this.httpService.post('/custom/centralweb/get-files-to-send-check_list', data)
      .subscribe(res => this.files = (res),
      error => this.httpService.handleError(error, () => this.uploaded()),
      () => this.httpService.done());
  }

  excluirAnexo(event: any) {
    this.httpService.wait();
    this.httpService.post('/custom/centralweb/excluir-arquivos-cl(' + event.nome + ',' + this.checkList.id.toString()
                          + ',' + this.dblinkedSessionService.empresa.id.toString() + ')')
      .subscribe(result => this.uploaded(),
      error => this.httpService.handleError(error, () => this.excluirAnexo(event)),
      () => this.httpService.done());
  }

  baixarAnexo(event: any) {
    this.httpService.wait();
    this.httpService.post('/custom/centralweb/export-file-cl(' + this.checkList.id
                          + ',' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(result => this.download(result),
      error => this.httpService.handleError(error, () => this.baixarAnexo(event)),
      () => this.httpService.done());
  }

  download(result: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/' + result.checkListId
                                        + '/' + this.dblinkedSessionService.empresa.id + '/cl'), '_blank');
    }
  }

  modalRecorrencia() {
    if (this.listarItens.length === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Adicione um item ao check list para prosseguir!'
      });
      return;
    }
    this.exbirModalRecorrencia = true;
  }

  modalNovoAction() {
    if (this.porcentagem === 100 ) {
      this.httpService.wait();
      this.httpService.get('/custom/centralweb/check-list-email-finalizar(' + this.checkList.id + ')')
      .subscribe(result => {
        this.messageService.add({
          severity: result.error ? 'error' : 'success',
          summary: 'MSG: ',
          detail: result.message || 'Email de finalização do Check List enviado com sucesso!'
        });
        this.modalNovo = false;
        this.listar();
      },
      error => this.httpService.handleError(error, () => this.modalNovoAction()),
      () => this.httpService.done());
    } else {
      this.modalNovo = false;
      this.listar();
    }

  }

  loadRecorrencia(value: Array<Recorrencia>) {
    this.recorrencias = new Array();
    this.recorrenciasEditar = new Array();
    value.forEach(element => {
      this.recorrencias.push(element);
      if (element.id !== 1) {
        this.recorrenciasEditar.push(element);
      }

    });
  }

  criarChecklist() {

    const listasEnviar = {
      checklistitens: this.itens,
      checkListId: this.checkList.id,
      recorrencia: this.checkList.recorrencia.id,
      usuarioResponsavel: this.responsavel,
      dtIni: this.dtIni,
      dtFin: this.dtFin,
      aCada: this.aCada,
      dom: this.dom,
      seg: this.seg,
      ter: this.ter,
      qua: this.qua,
      qui: this.qui,
      sex: this.sex,
      sab: this.sab,
      qtdeOcorrencia: this.qtdeOcorrencia,
      diaMes: this.diaMes,
      antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
      diaUtil: this.diaUtil,
      diaUtilSabado: this.diaUtilSabado,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuario: this.sessionService.loggedUser.id,
      editar: this.editar
    };

    this.httpService.wait();
    this.httpService.post('/custom/centralweb/cadastrar-check-list', listasEnviar)
      .subscribe(result => this.sucessoCadastro(result),
      error => this.httpService.handleError(error, () => this.criarChecklist()),
      () => this.httpService.done());
  }

  sucessoCadastro(result: any) {

    if (result.error === true) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Erro', detail: result.message });
      this.listar();
    } else {
      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: result.message });
      this.listar();
    }

  }

  comboUsuarios() {
    this.usuarios = new Array();
    this.httpService.wait();
    this.httpService.get('/custom/centralweb/get-usuarios-cl(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(usuarios => { // this.loadUsuarios(usuarios);
        usuarios.forEach(element => {
           this.usuarios.push({ label: element.nome, value: element.id });
        });
      },
      error => this.httpService.handleError(error, () => this.comboUsuarios()),
      () => this.httpService.done());
  }

  private toolbarRefresh() {
    // this.toolbarService.ocultar('info');

    this.toolbarService.alterarStatus('check', true, 'Finalizar Check List', () => this.finalizarcheckList(this.idCheck));
    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirCheckList(this.idCheck));
    this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.editarCheckList(event));
    this.toolbarService.alterarStatus('atualizar', false, 'Atualizar relatório', () => this.listar());
    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar novo check list', () => (this.modalNovo = true));


    /*this.toolbarService.clear();
    this.toolbarService.add('refresh', false, 'class-refresh-button', 'refresh', 'Atualizar', () => this.listar());
    this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar', );
    this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir', );
    this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir', );
    this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!', );
    this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Criar novo check list', () => (this.modalNovo = true) );*/
  }

  onEnter(value: string) { this.conteudoItem = value; this.adicionarItem(); }

  ngOnDestroy() {
    // this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  fileChange() {
    if (!this.checkList.titulo) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Preencha o título para prosseguir!'
      });
      this.fileInput.nativeElement.value = '';
      this.file = undefined;
      return;
    }
    if (!this.checkList.id) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Adicione pelo menos um item para prosseguir!'
      });
    }
    this.file = this.fileInput.nativeElement.files[0];
    this.importarArquivo();
  }

  tabChange(event: any) {
    this.idCheck = new Array();

    setTimeout(() => {
      this.toolbarRefresh();

     },500);
  }

}
