import { Directive, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[type=file]',
  providers: [ { provide: NG_VALUE_ACCESSOR, useExisting: FileDirective, multi: true } ]
})
export class FileDirective implements ControlValueAccessor {
  value: any;

  @HostListener('change', [ '$event.target.files' ])
  onChange = (_) => {}

  @HostListener('blur', [ '$event' ])
  onTouched = () => {}

  writeValue(value) {}
  registerOnChange(fn: any) { this.onChange = fn; }
  registerOnTouched(fn: any) { this.onTouched = fn; }
}
