import { FluxoCaixa } from './fluxo_caixa';
import { TipoGrupoFluxo } from 'app/util/model/tipo-fluxo-caixa-model';
import { AliasGrupoFluxoCaixa } from './fluxo_caixa_grupo_alias';

export class GrupoFluxoCaixa {
  id: number;
  codigoGrupo: string;
  descricao: string;
  fluxoCaixa: FluxoCaixa;
  tipoGrupoFluxo: TipoGrupoFluxo;
  aliasGrupoList: Array<AliasGrupoFluxoCaixa>;

  constructor(grupoFluxoCaixa?: GrupoFluxoCaixa | number) {
    if (grupoFluxoCaixa !== undefined && grupoFluxoCaixa !== null) {
      if (typeof grupoFluxoCaixa === 'number') {
        this.id = grupoFluxoCaixa;
      } else {
        this.id = grupoFluxoCaixa.id;
        this.codigoGrupo = grupoFluxoCaixa.codigoGrupo;
        this.descricao = grupoFluxoCaixa.descricao;
        this.fluxoCaixa = grupoFluxoCaixa.fluxoCaixa;
        this.tipoGrupoFluxo = grupoFluxoCaixa.tipoGrupoFluxo;
        this.aliasGrupoList = grupoFluxoCaixa.aliasGrupoList;
      }
    }
  }
}
