import { ConfigPattern } from "../interfaces/conciliadora"

export function resetConfigPattern(): ConfigPattern {
  let configPattern: ConfigPattern = {
    api_key: null,
    empresa_conciliadora_id: null,
    senha: null,
    conta_empresa_cred_id: null
  }

  return {...configPattern}
}