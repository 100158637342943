export class Depto {
  id: number;
  descricao: string;

  constructor(depto?: Depto | number) {
    if (depto !== undefined && depto !== null) {
      if (typeof depto === 'number') {
        this.id = depto;
      } else {
        this.id = depto.id;
        this.descricao = depto.descricao;
      }
    }
  }
}
