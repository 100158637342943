import { Empresa } from '../../cadastro/model/empresa.model';

export class TbPrejAcumulado {
    id: number;
    empresa: Empresa;
    periodo:  number;
    valorPrejuizo: number;

    constructor(tbprejacumulado?: TbPrejAcumulado | number) {
    if (tbprejacumulado !== undefined && tbprejacumulado !== null) {
      if (typeof tbprejacumulado === 'number') {
        this.id = tbprejacumulado;
      } else {
        this.id = tbprejacumulado.id;
        this.empresa = tbprejacumulado.empresa;
        this.periodo = tbprejacumulado.periodo;
        this.valorPrejuizo = tbprejacumulado.valorPrejuizo;
      }
    }
  }
}
