import { MessageService } from 'primeng/api';

export default class tools {

  constructor(private messageService: MessageService) { }

  exibirMensagem(value: any): void {
    let summary: string = null
    if (value.severity === 'success') summary = 'Sucesso'
    else if (value.severity === 'info') summary = 'Info'
    else if (value.severity === 'error') summary = 'Erro'
    else if (value.severity === 'warn') summary = 'Advertência'
    this.messageService.add({ severity: value.severity, key: 'messageLinnks', summary: summary, detail: value.mensagem })
  }

}