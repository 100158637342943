import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from "app/shared/services/http.service";
import { ConfigService } from "app/shared/services/config.service";
import { Toolbar } from 'app/shared/model/toolbar.model';
import { MessageService, SelectItem } from "primeng/api";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Subscription } from 'rxjs/Subscription';
import { AuthUser } from "app/proof/model/auth-user.model";
import { Aprovacao } from 'app/centralweb/model/aprovacao.model';
import { Aprovador } from 'app/centralweb/model/aprovador.model';
import { Alcada } from 'app/centralweb/model/alcada.model';
import { AlcadaRateio } from 'app/centralweb/model/alcada-rateio.model';
import { CadCcus } from "app/contabil/model/cad-ccus.model";
import { CadProj } from "app/contabil/model/cad-proj.model";
import { Area } from "app/orcamento/model/area-model";
import { Subarea } from "app/orcamento/model/subarea-model";
import { Empresa } from '../model/empresa.model';

@Component({
  selector: 'app-aprovacao',
  templateUrl: './aprovacao.component.html',
  styleUrls: ['./aprovacao.component.scss']
})
export class CadastroAprovacao implements OnInit, OnDestroy {

  tableAprovacoes: Array<Aprovacao> = new Array();
  listAprovacoes: any = [];
  tableAprovadores: Array<Aprovador> = new Array();
  tableAlcadas: Array<Alcada> = new Array();
  tableRelacionamentoAprovacao: Array<any> = new Array();
  listRelacAprovOrigemDestino: Array<any> = new Array();
  listBlockedOrigemDestinoId: Array<number> = new Array();
  tableRelacAprovOrigemDestino: Array<any> = new Array();
  setOrigemDestinoIdToDelete: Set<number> = new Set();
  tableView: Array<any> = new Array();
  selectOD: Array<any> = new Array();
  selectedApprov: Array<any> = new Array();
  optionsDestinatarios: Array<any> = new Array();

  selectedAprovacoes: any;
  selectedAprovadores: any;
  selectedAprovadorRelac: number = null;
  listSelectedAprovadorRelac: any;
  selectedAlcadas: any;
  selectedRelac: any = {};

  cadModal: boolean = false;
  aprovDetails: boolean = false;
  screenLoaded: boolean = false;
  modalEdit: boolean = false;
  modalAlcada: boolean = false;
  showRelacao = false
  showDetalhes = true
  showAprovacoes: boolean = false;
  showAprovadores: boolean = true;
  showAlcadas: boolean = false;
  hasChanges: boolean = false;
  canEdit: boolean = false;
  showDetails: boolean = false;
  isNewOrigemDestino: boolean = true;
  validaBudget: boolean = false

  alcadaLoaded: boolean = false;
  aprovadoresLoaded: boolean = false;
  relacAprovLoaded: boolean = false;
  relacOrigemLoaded: boolean = false;

  authUserList: SelectItem[] = [];
  aprovadorList: any = [];
  alcadaList: SelectItem[] = [];

  subareaList = [];
  projetoList = [];
  opcoesArea: SelectItem[] = [];
  opcoesSubarea: SelectItem[] = [];
  opcoesCentroCustos: SelectItem[] = [];
  opcoesProjetos: SelectItem[] = [];
  opcoesOrigem: SelectItem[] = [];
  opcoesDestinoAprov: SelectItem[] = [];
  opcoesDestinoRecusa: SelectItem[] = [];
  opcoesDestinoBudget: SelectItem[] = [];
  opcoesAprovacao: SelectItem[] = [];

  loggedUserId: number = 0;
  currentCompany: number = 0;
  modoTravaBudget: number = null

  currentYear: any;
  condition: any;
  cadModalTitle = "";

  newAprovValues: any = {
    ordem: 1,
    codigo: '',
    descricao: '',
    empresaId: this.currentCompany,
    userId: this.loggedUserId
  };

  newAlcadas: any = {
    hasValorMax: true,
    hasValorMin: true,
    valorMin: 0,
    valorMax: 0,
    empresaId: this.currentCompany,
    codigo: '',
    descricao: '',
    rateio: {
      ccusId: null,
      projId: null,
      areaId: null,
      subareaId: null,
    }
  };

  newAprovadores: any = {
    ativo: false,
    authUserId: null,
    aprovadorSubstitutoId: null,
    empresaId: this.currentCompany,
    dtIni: "",
    dtFin: ""
  };

  mainButtons = [
    { color: 'red', icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: false, disabled: false, onClick: () => this.deleteAprovador() },
    { color: 'green', icon: 'add', text: 'Adicionar aprovador', tooltip: 'Adicionar aprovador', visible: true, disabled: false, onClick: () => this.changeState('cadModal') },
    { color: 'green', icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: false, disabled: false, onClick: () => this.onClickUpdate() },
  ]

  requiredFields = {
    "aprovacao": ["descricao"],
    "aprovador": [["authUser", "authUserId"]],
    "alcada": ["descricao"],
    'aprovOrigemDestino': ['origemId', 'destinoAprovId']
  }

  relacOrigemView = {
    1: 'financeiro.vw_pedido_aprovacao',
    2: 'financeiro.vw_financeiro_aprovacao',
    3: 'rms.vw_rms',
    4: null
  }

  public toolbarAprov = new Toolbar();
  private sessionSubscription: Subscription;

  constructor(
    private toolbarService: ToolbarService,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private messageService: MessageService,
  ) {
    this.toolbarService.hidden = true
  }

  ngOnInit(): void {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.toolbarAprov.setAll(this.mainButtons)
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      async () => {
        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
          this.loggedUserId = this.sessionService.loggedUser.id;
          this.currentCompany = this.dblinkedSessionService.empresa.id;
          this.currentYear = this.dblinkedSessionService.periodo.year.label;

          this.getViewCampos();
          this.getOrigem();
          this.getDestino();
          this.getAlcadas();
          await this.getAuthUsers();
          this.getAprovadores();
          this.getRelacAprovacaoOrigemDestino();
          this.getParametroRms()
        }
      }
    );

    this.optionsDestinatarios = [
      { label: 'Cliente', value: true },
      { label: 'Aprovador', value: false }
    ]
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  convertId(id, type) {
    let response = "Não informado"
    switch (type) {
      case 'user':
        this.authUserList.forEach(user => {
          if (id == user.value) response = user.label
        })
        break;
      case 'aprovador':
        this.aprovadorList.find(aprov => {
          if (aprov.value == id) response = aprov.label
        });
        break
      case 'origem':
        const origem = this.opcoesOrigem.find(opcao => opcao.value == id);
        if (origem && origem.label.toLowerCase() != 'selecione') response = origem.label;
        break
      case 'destinoAprov':
        const aprov = this.opcoesDestinoAprov.find(opcao => opcao.value == id);
        if (aprov && aprov.label.toLowerCase() != 'selecione') response = aprov.label;
        break
      case 'destinoRecusa':
        const recusa = this.opcoesDestinoRecusa.find(opcao => opcao.value == id);
        if (recusa && recusa.label.toLowerCase() != 'selecione') response = recusa.label;
        break
      case 'destinoBudget':
        const budget = this.opcoesDestinoBudget.find(opcao => opcao.value == id);
        if (budget && budget.label.toLowerCase() != 'selecione') response = budget.label;
        break
      case 'alcada':
        if (id) {
          this.alcadaList.forEach(item => {
            if (item.value == id)
              response = item.label
          })
        }
        break
      case 'aprovacao':
        if (id) {
          this.listAprovacoes.forEach(item => {
            if (item.value == id)
              response = item.label
          })
        }
        break
    }
    return response
  }

  onClickSaveCad() {
    let toValidate = this.showAprovacoes ? [this.newAprovValues, "aprovacao"] : this.showAprovadores ? [this.newAprovadores, "aprovador"] : [this.newAlcadas, "alcada"];
    if (!this.isValid(toValidate[0], toValidate[1])) return this.showMessage({ error: true, mensagem: "Preencha todos os campos obrigatórios." });

    if (this.showAprovacoes) this.createAprovacoes();
    if (this.showAprovadores) this.createAprovador();
    if (this.showAlcadas) this.createAlcadas();
  }

  onClickUpdate() {
    let toValidate = this.showAprovacoes ? [this.selectedAprovacoes, "aprovacao"] : this.showAprovadores ? [this.selectedAprovadores, "aprovador"] : [this.selectedAlcadas, "alcada"];
    if (!this.isValid(toValidate[0], toValidate[1])) return this.showMessage({ error: true, mensagem: "Preencha todos os campos obrigatórios." });

    if (this.showAprovacoes) {
      if (this.listRelacAprovOrigemDestino.filter(relac => relac.aprovacaoId == this.selectedAprovacoes.id).length > 0
        || this.setOrigemDestinoIdToDelete.size > 0) {
          this.saveRelacAprovacaoOrigemDestino();
      } else {
        this.updateAprovacoes();
      }
    }
    if (this.showAprovadores) this.updateAprovador();
    if (this.showAlcadas) this.updateAlcadas();

    this.selectedRelac = {};
    this.selectOD = [];
    this.selectedApprov = [];
    this.showDetails = false;
    this.isNewOrigemDestino = true;
  }

  onClickViewCampo(campo: string) {
    if (this.selectedRelac.sql == null) this.selectedRelac.sql = "";

    this.selectedRelac.sql += " vw." + campo;
  }

  resetModalValues() {
    this.onRowUnselect();
    this.newAprovValues = {
      ordem: this.tableAprovacoes.length > 0 ? (this.tableAprovacoes[this.tableAprovacoes.length - 1].ordem + 1) : 1,
      codigo: '',
      descricao: '',
      empresaId: this.currentCompany,
      userId: this.loggedUserId
    }
    this.newAlcadas = {
      hasValorMax: true,
      hasValorMin: true,
      valorMin: 0,
      valorMax: 0,
      empresaId: this.currentCompany,
      codigo: '',
      descricao: '',
      rateio: {
        ccusId: null,
        projId: null,
        areaId: null,
        subareaId: null,
      }
    };
    this.newAprovadores = {
      ativo: true,
      authUserId: 0,
      aprovadorSubstitutoId: null,
      empresaId: this.currentCompany,
      dtIni: "",
      dtFin: ""
    };
  }

  changeState(screen) {
    switch (screen) {
      case 'cadModal':
        this.resetModalValues();
        this.cadModal = true;
        break;
      case 'modalEdit':
        this.modalEdit = !this.modalEdit;
        break;
      case 'modalAlcada':
        this.modalAlcada = !this.modalAlcada;
        break;
      case 'showAprovacoes':
        this.showAprovacoes = true
        this.showAprovadores = false
        this.showAlcadas = false
        this.aprovDetails = false
        this.toolbarAprov.setVisible('btn1', false);
        this.toolbarAprov.setClick('btn1', () => this.deleteAprovacao())
        this.toolbarAprov.setText('btn2', 'Adicionar Aprovação')
        this.toolbarAprov.setVisible('btn3', this.hasChanges)
        break;
      case 'showAprovadores':
        this.showAprovadores = true
        this.showAprovacoes = false
        this.showAlcadas = false
        this.aprovDetails = false
        this.toolbarAprov.setVisible('btn1', false)
        this.toolbarAprov.setClick('btn1', () => this.deleteAprovador())
        this.toolbarAprov.setText('btn2', 'Adicionar Aprovador')
        this.toolbarAprov.setVisible('btn3', false)
        break;
      case 'showAlcadas':
        this.showAlcadas = true
        this.showAprovadores = false
        this.showAprovacoes = false
        this.aprovDetails = false
        this.toolbarAprov.setVisible('btn1', false)
        this.toolbarAprov.setClick('btn1', () => this.deleteAlcada())
        this.toolbarAprov.setText('btn2', 'Adicionar Alçada')
        this.toolbarAprov.setVisible('btn3', false)
        break;
      case 'showRelacao':
        this.showRelacao = true
        this.showDetalhes = false
        break;
      case 'showDetalhes':
        this.showRelacao = false
        this.showDetalhes = true
        break;
    }
  }

  getAprovInit() {
    if (!this.alcadaLoaded || !this.aprovadoresLoaded || !this.relacOrigemLoaded) return
    this.getAprovacoes();
  }

  getAprovacoes() {
    this.httpService.wait()
    this.httpService.get("/custom/workflow/get-aprovacao", this.currentCompany)
      .subscribe(
        (res: any) => {
          if (res.error) {
            this.showMessage(res);
          } else {
            this.listAprovacoes = res.map(aprov => { return { label: aprov.codigo ? aprov.codigo + " - " + aprov.descricao : aprov.descricao, value: aprov.id } });
            this.tableAprovacoes = res.map((item) => {
              const aprovacao: Aprovacao = new Aprovacao(item);
              aprovacao.empresa = new Empresa(this.currentCompany)
              return { ...aprovacao, ...this.getRelacData(aprovacao.id) };
            });

            this.screenLoaded = true;
            this.aprovDetails = false;
            this.onRowReorder();
          }
        },
        (err) => {
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro, contate o suporte: " + (err.response || err.message) })
        },
        () => {
          this.httpService.done();
          this.alcadaLoaded = false;
          this.aprovadoresLoaded = false;
          this.relacAprovLoaded = false;
          this.relacOrigemLoaded = false;
        }
      );
  }

  getAprovadores() {
    this.aprovDetails = false;
    this.httpService.wait()
    this.httpService.get("/custom/workflow/get-aprovadores", this.currentCompany)
      .subscribe(
        (res: any) => {
          if (res.error) {
            this.showMessage(res);
          } else {
            this.aprovadorList = res.map((item) => new Object({ label: this.convertId(item.authUserId, 'user'), value: item.id })).sort((aprov1, aprov2) => aprov1.label.toUpperCase() > aprov2.label.toUpperCase() ? 1 : -1);
            this.tableAprovadores = res.map((item) => {
              const aprovador: Aprovador = new Aprovador(item);
              const authUser: AuthUser = new AuthUser(item.authUserId);
              aprovador.authUser = authUser;
              const aprovadorSubstituto: Aprovador = new Aprovador(item.aprovadorSubstitutoId);
              aprovador.aprovadorSubstituto = aprovadorSubstituto;
              aprovador.empresa = new Empresa(this.currentCompany)
              const nomes = {
                nomeAprovador: this.convertId(aprovador.authUser.id, 'user'),
                nomeSubstituto: this.convertId(aprovador.aprovadorSubstituto.id, 'aprovador')
              }
              return { ...aprovador, ...nomes };
            }).sort((aprov1, aprov2) => this.convertId(aprov1.authUser.id, 'user').toUpperCase() > this.convertId(aprov2.authUser.id, 'user').toUpperCase() ? 1 : -1);

            this.aprovDetails = false;
            this.aprovadoresLoaded = true;
            this.getAprovInit();
          }
        },
        (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro, contate o suporte: " + (err.response || err.message) })
        },
        () => this.httpService.done()
      );
  }

  getAlcadas() {
    this.aprovDetails = false;
    this.httpService.wait()
    this.httpService.get("/custom/workflow/alcada/" + this.currentCompany)
      .subscribe(
        (res: any) => {
          if (res.error) {
            this.showMessage(res);
          } else {
            this.alcadaList = res.map((item) => new Object({ label: item.codigo ? item.codigo + " - " + item.descricao : item.descricao, value: item.id }));
            this.alcadaList.unshift({ label: 'Sem Alçada', value: null });
            this.tableAlcadas = res.map((item) => {
              const alcada = new Alcada(item);
              alcada['rateio'] = new Object();

              if (item.alcadaRateio) {
                alcada['rateio'].ccusId = alcada.alcadaRateio.cadCcus ? alcada.alcadaRateio.cadCcus.id : null;
                alcada['rateio'].projId = alcada.alcadaRateio.cadProj ? alcada.alcadaRateio.cadProj.id : null;
                alcada['rateio'].areaId = alcada.alcadaRateio.area ? alcada.alcadaRateio.area.id : null;
                alcada['rateio'].subareaId = alcada.alcadaRateio.subarea ? alcada.alcadaRateio.subarea.id : null;
              }

              return alcada;
            });
            this.aprovDetails = false;
            this.alcadaLoaded = true;
            this.getAprovInit();
          }
        }, (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro, contate o suporte: " + (err.response || err.message) })
        },
        () => this.httpService.done()
      );
  }

  getRelacData(id) {
    const dataRelacOrigim = this.listRelacAprovOrigemDestino.filter(item => item.aprovacaoId == id);

    let response: any = {
      aprovador: "Não informado",
      condicao: "Não informado",
      destinoAprov: "Não informado",
      destinoRec: "Não informado"
    };

    if (dataRelacOrigim.length > 0) {
      if (dataRelacOrigim.length > 1) {
        response = {
          aprovador: "Vários",
          condicao: "Vários",
          destinoAprov: "Vários",
          destinoRec: "Vários"
        }
      } else {
        response.aprovador = dataRelacOrigim[0].aprovacaoConj? "Vários" : this.convertId(dataRelacOrigim[0].aprovadorId, 'aprovador');
        response.condicao = this.convertId(dataRelacOrigim[0].alcadaId, 'alcada');
        response.destinoAprov = this.convertId(dataRelacOrigim[0].destinoAprovId, 'destinoAprov');
        if (response.destinoAprov == "Passar para outra etapa") response.destinoAprov = this.convertId(dataRelacOrigim[0].proxAprovId, 'aprovacao');
        response.destinoRec = this.convertId(dataRelacOrigim[0].destinoRecusaId, 'destinoRecusa');
        if (response.destinoRec == "Passar para outra etapa") response.destinoRec = this.convertId(dataRelacOrigim[0].proxRecusaId, 'aprovacao')
      }
    }

    return response
  }

  getTooltip(list) {
    let response = `Aprovadores: \n`
    if (list) {
      list.forEach(userId => {
        response += `${this.convertId(userId, 'aprovador')} \n`
      })
    }
    return response
  }

  getRelacAprovacaoOrigemDestino() {
    this.httpService.wait()
    this.httpService.get("/custom/workflow/relac-aprovacao-origem-destino", this.currentCompany)
      .subscribe(
        (res: any) => {
          if (!res.error) {
            this.setOrigemDestinoIdToDelete = new Set();
            this.listRelacAprovOrigemDestino = res.listRelac;
            this.listBlockedOrigemDestinoId = res.listBlockedOrigemDestinoId;
            this.relacOrigemLoaded = true;
            this.onRowUnselect();
            this.getAprovInit();
          }
        }, (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro, contate o suporte: " + (err.response || err.message) })
        },
        () => this.httpService.done()
      );
  }

  async getAuthUsers() {
    this.httpService.wait();
    try {
      await this.httpService.get(`/custom/empresa/${this.currentCompany}/authuser`).toPromise()
        .then((res: any) => {
          this.httpService.done();

          if (res.error) return this.showMessage(res);

          this.authUserList = res.map((item) => new Object({ label: item.fullName, value: item.id }))
                                  .sort((user1, user2) => user1.label.toUpperCase() > user2.label.toUpperCase() ? 1 : -1);
        });
    } catch (err) {
      console.log(err)
      this.httpService.done();
      this.showMessage({ "error": true, "mensagem": "Erro, contate o suporte: " + (err.response || err.message) })
    }
  }

  getAuthUserList(userId=0) {
    return this.authUserList.filter(user => user.value == userId || this.tableAprovadores.find(aprov => aprov.authUser.id == user.value) == null);
  }

  getOrigem() {
    this.httpService.wait()
    this.httpService.get(`/custom/workflow/origem`)
      .subscribe(
        (res: any) => {
          if (res.error) {
            this.showMessage(res);
          } else {
            this.opcoesOrigem = res.map((item) => new Object({ label: item.codigo ? `${item.codigo} - ${item.descricao}` : item.descricao, value: item.id }));
          }
        }, (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro, contate o suporte: " + (err.response || err.message) })
        }, () => this.httpService.done()
      );
  }

  getDestino() {
    this.httpService.wait()
    this.httpService.get(`/custom/workflow/destino`)
      .subscribe(
        (res: any) => {
          this.opcoesDestinoAprov = new Array();
          this.opcoesDestinoRecusa = new Array();
          this.opcoesDestinoBudget = new Array()

          for (let item of res) {
            const option = { label: item.codigo ? `${item.codigo} - ${item.descricao}` : item.descricao, value: item.id };

            if (item.isRecusa) this.opcoesDestinoRecusa.push(option);
            else if (item.isBudget) this.opcoesDestinoBudget.push(option)
            else this.opcoesDestinoAprov.push(option);
          }

          this.opcoesDestinoAprov.unshift({ label: "Selecione", value: null });
          this.opcoesDestinoRecusa.unshift({ label: "Selecione", value: null });
          this.opcoesDestinoBudget.unshift({ label: "Selecione", value: null });
        }, (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro, contate o suporte: " + (err.response || err.message) })
        }, () => this.httpService.done()
      );
  }

  getViewCampos() {
    this.httpService.wait()
    this.httpService.get(`/custom/workflow/view-campos-workflow`)
      .subscribe(
        (res: any) => {
          this.tableView = res;
        }, (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro, contate o suporte: " + (err.response || err.message) })
        }, () => this.httpService.done()
      );
  }

  getTableView() {
    return this.tableView.filter(field => field.viewNome == this.relacOrigemView[this.selectedRelac.origemId]);
  }

  changeAprovacaoConj() {
    this.listSelectedAprovadorRelac = new Array();
    this.selectedAprovadorRelac = null;
    this.selectedRelac.aprovadorId = null;
    this.selectedRelac.listAprovadorId = [];
  }

  getAprovadorList(aprovacaoConj): SelectItem[] {
    let ret = [...this.aprovadorList];
    if (this.showAprovadores && this.selectedAprovadores != null) ret = ret.filter(aprov => aprov.value != this.selectedAprovadores.id);
    if (!aprovacaoConj) ret.unshift({ label: 'Sem aprovador', value: null });

    return ret;
  }

  updateAprovacoes() {
    this.httpService.wait()
    this.httpService.put("/custom/workflow/update-aprovacao", this.currentCompany, this.tableAprovacoes)
      .subscribe(
        (res: any) => {
          this.showMessage(res)
          if (res.error) return;

          this.hasChanges = false;
          this.toolbarAprov.setVisible('btn3', false)
          this.getAprovacoes();
        },
        (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro ao salvar aprovação, contate o suporte: " + (err.response || err.message) })
        },
        () => this.httpService.done()
      );
  }

  convertDate(date) {
    if (typeof (date) == 'string') {
      let usaDate = `${date.split('/')[1]}/${date.split('/')[0]}/${date.split('/')[2]}`
      return new Date(usaDate)
    }
    return date
  }

  enableEditOrigemDestino(event) {
    this.selectedRelac = event.data;
    this.selectedRelac.editing = true;
    this.showDetails = true;
    this.isNewOrigemDestino = true;
  }

  disableEditOrigemDestino(event) {
    this.selectedRelac = {};
    this.selectedRelac.editing = false;
    this.showDetails = false;
    this.isNewOrigemDestino = true;
  }

  newOrigemDestino() {
    this.selectedRelac = {
      id: this.idv4()
    };
    this.selectOD = [];
    this.showDetails = true;
    this.isNewOrigemDestino = false;
  }

  cancelOrigemDestino() {
    this.selectedRelac = {};
    this.selectOD = [];
    this.showDetails = false;
    this.isNewOrigemDestino = true;
  }

  addOrigemDestino() {
    if (this.convertId(this.selectedRelac.destinoAprovId, 'destinoAprov').toLowerCase() == 'passar para outra etapa') this.requiredFields['aprovOrigemDestino'].push('proxAprovId');
    let isValid = this.isValid(this.selectedRelac, 'aprovOrigemDestino');
    this.requiredFields['aprovOrigemDestino'] = ['origemId', 'destinoAprovId'];

    if (!isValid) return this.showMessage({ error: true, mensagem: "Preencha todos os campos obrigatórios." });

    if(this.selectedRelac.agrupar) {
      const grupoRelac = this.tableRelacAprovOrigemDestino.filter(relac =>  relac.agrupar && relac.id != this.selectedRelac.id);
      if(grupoRelac && grupoRelac.length > 0) {
        const setAcao = new Set([this.selectedRelac, ...grupoRelac].map(relac => `${relac.origemId}${relac.destinoAprovId}${relac.proxAprovId}${relac.destinoRecusaId}${relac.proxRecusaId}`));

        if(setAcao.size > 1)
          return this.showMessage({ error: true, mensagem: `A aprovação possui condições agrupadas e com ações diferentes.` });
      }
    }

    if (!this.selectedRelac.editing) {
      this.selectedRelac.aprovacaoId = this.selectedAprovacoes.id;
      this.listRelacAprovOrigemDestino.push(this.selectedRelac);
      this.tableRelacAprovOrigemDestino = this.listRelacAprovOrigemDestino.filter(relac => relac.aprovacaoId == this.selectedAprovacoes.id);
    }

    this.selectedRelac.editing = false;
    this.selectedRelac = {};
    this.selectOD = [];

    this.showDetails = false;
    this.isNewOrigemDestino = true;
    this.onClickUpdate()
  }

  removeOrigemDestino(event: any) {
    if(this.listBlockedOrigemDestinoId.find(id => id == event.id)) {
      return this.showMessage({ error: true, mensagem: "Não é possível deletar a condição pois existem cards de aprovação a usando." });
    }

    this.setOrigemDestinoIdToDelete.add(event.id);
    this.listRelacAprovOrigemDestino = this.listRelacAprovOrigemDestino.filter(relac => relac.id != event.id);
    this.tableRelacAprovOrigemDestino = this.listRelacAprovOrigemDestino.filter(relac => relac.aprovacaoId == this.selectedAprovacoes.id);

    if (this.showDetails && this.selectedRelac.id === event.id) {
      this.selectedRelac = {};
      this.selectOD = [];

      this.showDetails = false;
      this.isNewOrigemDestino = true;
    }
  }

  verificaAlcadaAprovador() {
    if (this.selectedRelac.alcadaId && (!this.selectedRelac.aprovadorId && (!this.selectedRelac.listAprovadorId || this.selectedRelac.listAprovadorId.length < 1))) {
      this.selectedRelac.destinoRecusaId = null;
      this.selectedRelac.proxRecusaId = null;
    }
  }

  updateAprovador() {
    let data = [{
      ...this.selectedAprovadores,
      dtIni: this.convertDate(this.selectedAprovadores.dtIni),
      dtFin: this.convertDate(this.selectedAprovadores.dtFin),
    }].map(aprovador => { if (aprovador.aprovadorSubstituto.id == null) aprovador.aprovadorSubstituto = null; return aprovador });
    this.httpService.wait()
    this.httpService.put("/custom/workflow/update-aprovador", this.loggedUserId, data)
      .subscribe(
        (res: any) => {
          this.showMessage(res);
          if (res.error) return;

          this.getAprovadores();
          this.aprovDetails = false;
        },
        (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro ao salvar aprovador, contate o suporte: " + (err.response || err.message) });
        },
        () => this.httpService.done()
      );
  }

  updateAlcadas() {
    if (!this.selectedAlcadas.rateio || this.selectedAlcadas.rateio.ccusId == null && this.selectedAlcadas.rateio.areaId == null) {
      this.selectedAlcadas.alcadaRateio = null;
    } else {
      this.selectedAlcadas.alcadaRateio = new AlcadaRateio();
      this.selectedAlcadas.alcadaRateio.cadCcus = this.selectedAlcadas.rateio.ccusId ? new CadCcus(this.selectedAlcadas.rateio.ccusId) : null;
      this.selectedAlcadas.alcadaRateio.cadProj = this.selectedAlcadas.rateio.projId ? new CadProj(this.selectedAlcadas.rateio.projId) : null;
      this.selectedAlcadas.alcadaRateio.area = this.selectedAlcadas.rateio.areaId ? new Area(this.selectedAlcadas.rateio.areaId) : null;
      this.selectedAlcadas.alcadaRateio.subarea = this.selectedAlcadas.rateio.subareaId ? new Subarea(this.selectedAlcadas.rateio.subareaId) : null;
    }

    this.httpService.wait()
    this.httpService.put("/custom/workflow/alcada", this.loggedUserId, this.selectedAlcadas)
      .subscribe(
        (res: any) => {
          this.showMessage(res);
          if (res.error) return;

          this.getAlcadas();
          this.aprovDetails = false;
        },
        (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro ao salvar alçada, contate o suporte: " + (err.response || err.message) });
        },
        () => this.httpService.done()
      );
  }

  createAprovacoes() {
    const data = {
      aprovacoes: [{ ...this.newAprovValues }],
    };
    this.httpService.wait()
    this.httpService.post("/custom/workflow/create-aprovacao", data)
      .subscribe(
        (res: any) => {
          this.showMessage(res);
          if (res.error) return;
          this.getAprovacoes()
        },
        (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro ao salvar aprovação, contate o suporte: " + (err.response || err.message) })
        },
        () => this.httpService.done()
      );
    this.cadModal = false
  }

  createAprovador() {
    const data = {
      aprovadores: [{ ...this.newAprovadores }],
      userId: this.loggedUserId
    };
    this.httpService.wait()
    this.httpService.post("/custom/workflow/create-aprovador", data)
      .subscribe(
        (res: any) => {
          this.showMessage(res);
          if (res.error) return;
          this.getAprovadores();
        },
        (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro ao salvar aprovador, contate o suporte: " + (err.response || err.message) })
        },
        () => this.httpService.done()
      );
    this.cadModal = false
  }

  createAlcadas() {
    const data = {
      alcada: {
        ...this.newAlcadas,
        valorMin: this.newAlcadas.hasValorMin ? this.newAlcadas.valorMin : null,
        valorMax: this.newAlcadas.hasValorMax ? this.newAlcadas.valorMax : null,
      },
      userId: this.loggedUserId
    };
    this.httpService.wait()
    this.httpService.post("/custom/workflow/alcada", data)
      .subscribe(
        (res: any) => {
          this.showMessage(res);
          if (res.error) return;
          this.getAlcadas();
        },
        (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro ao salvar alçada, contate o suporte: " + (err.response || err.message) })
        },
        () => this.httpService.done()
      );
    this.cadModal = false
  }

  saveRelacAprovacaoOrigemDestino() {
    const origemDestinoToSend = this.listRelacAprovOrigemDestino.filter(relac => relac.aprovacaoId == this.selectedAprovacoes.id)
      .map(relac => {
        relac.id = isNaN(relac.id)? null : relac.id
        return relac;
      });

    const data = {
      empresaId: this.dblinkedSessionService.empresa.id,
      listNewRelac: origemDestinoToSend.filter(relac => relac.id == null),
      listEditRelac: origemDestinoToSend.filter(relac => relac.id != null),
      setRelacId: Array.from(this.setOrigemDestinoIdToDelete),
      userId: this.loggedUserId
    };

    this.httpService.wait()
    this.httpService.post("/custom/workflow/relac-aprovacao-origem-destino", data)
      .subscribe(
        (res: any) => {
          if (res.error) return this.showMessage(res);

          this.updateAprovacoes();
          this.getRelacAprovacaoOrigemDestino();
        },
        (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro ao salvar o relacionamento, contate o suporte: " + (err.response || err.message) })
        },
        () => this.httpService.done()
      );
  }

  deleteAprovacao() {
    if (this.selectedAprovacoes == null) return this.showMessage({ "error": true, "mensagem": "Selecione uma aprovação antes de excluir" });
    if (!confirm("Deseja realmente excluir a aprovação?")) return;

    const data = {
      listId: [this.selectedAprovacoes.id]
    };

    this.httpService.wait()
    this.httpService.post("/custom/workflow/delete-aprovacao", data)
      .subscribe(
        (res: any) => {
          this.showMessage(res);
          if (res.error) return;
          this.getAprovacoes();
          this.getRelacAprovacaoOrigemDestino();
          this.toolbarAprov.setVisible('btn1', false);
        },
        (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro ao salvar aprovação, contate o suporte: " + (err.response || err.message) })
        },
        () => this.httpService.done()
      );
  }

  deleteAprovador() {
    if (this.selectedAprovadores == null) return this.showMessage({ "error": true, "mensagem": "Selecione um aprovador antes de excluir" });
    if (!confirm("Deseja realmente excluir o aprovador?")) return;

    const data = {
      listId: [this.selectedAprovadores.id]
    };

    this.httpService.wait()
    this.httpService.post("/custom/workflow/delete-aprovador", data)
      .subscribe(
        (res: any) => {
          this.showMessage(res);
          if (res.error) return;
          this.getAprovadores();
          this.toolbarAprov.setVisible('btn1', false);
        },
        (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro ao salvar aprovador, contate o suporte: " + (err.response || err.message) })
        },
        () => this.httpService.done()
      );
  }

  deleteAlcada() {
    if (this.selectedAlcadas == null) return this.showMessage({ "error": true, "mensagem": "Selecione uma alçada antes de excluir" });
    if (!confirm("Deseja realmente excluir a alçada?")) return;

    const data = {
      listId: [this.selectedAlcadas.id]
    };

    this.httpService.wait()
    this.httpService.post("/custom/workflow/delete-alcada", data)
      .subscribe(
        (res: any) => {
          this.showMessage(res);
          if (res.error) return;

          this.getAlcadas();
          this.toolbarAprov.setVisible('btn1', false);
        },
        (err) => {
          this.httpService.done();
          console.log(err)
          this.showMessage({ "error": true, "mensagem": "Erro ao salvar alçada, contate o suporte: " + (err.response || err.message) })
        },
        () => this.httpService.done()
      );
  }

  onRowSelect(event) {
    try {
      this.toolbarAprov.setVisible('btn1', true);

      if (this.showAprovacoes) {
        this.selectedAprovacoes = event.data;

        this.opcoesAprovacao = this.tableAprovacoes.filter(aprov => aprov.id != this.selectedAprovacoes.id).map(aprov => { return { label: aprov.codigo ? aprov.codigo + " - " + aprov.descricao : aprov.descricao, value: aprov.id } });
        this.opcoesAprovacao.unshift({ label: 'Selecione', value: null });
        this.selectedRelac = {};
        this.tableRelacAprovOrigemDestino = this.listRelacAprovOrigemDestino.filter(relac => relac.aprovacaoId == this.selectedAprovacoes.id);
        console.log(this.tableRelacAprovOrigemDestino)
      }

      if (this.showAprovadores) this.selectedAprovadores = event.data;
      if (this.showAlcadas) this.selectedAlcadas = event.data;

      this.aprovDetails = true;
    } catch (err) {
      console.log(err)
    }
  }

  onRowUnselect() {
    this.selectedAprovacoes = null;
    this.selectedAprovadores = null;
    this.selectedAlcadas = null;
    this.toolbarAprov.setVisible('btn1', false);
    this.aprovDetails = false;
  }

  onRowReorder() {
    this.hasChanges = true;
    this.toolbarAprov.setVisible('btn3', true)
    this.tableAprovacoes.forEach((element, index) => element.ordem = index + 1);
  }

  formatarDataExtenso() {
    const dt = new Date();
    const month = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    const week = [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ];
    const semana = week[dt.getDay()];
    const mes = month[dt.getMonth()];

    return (`Hoje é ${semana}, ${dt.getDate()} de ${mes} de ${dt.getFullYear()}`);
  }

  showMessage(value: any) {
    let detail = (value.mensagem || value.message || value.response || value.msg);
    if (!value.error) {
      this.messageService.add({
        key: 'messageLinnks',
        severity: "success",
        summary: "Sucesso",
        detail,
      });
    } else {
      this.messageService.add({
        key: 'messageLinnks',
        severity: "warn",
        summary: "Erro",
        detail,
      });
    }
  }

  isValid(object: any, type: string): boolean {
    const requiredFields = this.requiredFields[type];
    if (!requiredFields || requiredFields.length < 1) return true;

    for (let field of requiredFields) {
      if (typeof field == 'string') {
        if (!object.hasOwnProperty(field) || !object[field]) return false;
      } else {
        let hasAtLeastOne = false;
        for (let otherField of field)
          if (object.hasOwnProperty(otherField) && object[otherField]) hasAtLeastOne = true;

        if (!hasAtLeastOne) return false
      }
    }

    return true;
  }

  idv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  getParametroRms() {
    this.httpService.wait()
    this.httpService.get('/configuracaorms?$select=verificaBudget,modoTravaBudget/id&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
      .subscribe(
        (res: any) => {
          res.value.forEach(element => {
            this.validaBudget = element.verificaBudget
            this.modoTravaBudget = element.modoTravaBudget?.id

          })
        },
        (err) => {
          this.httpService.done();
          this.showMessage({ "error": true, "mensagem": "Erro ao buscar a configuração de RMS." })
        },
        () => this.httpService.done()
      );
  }
}
