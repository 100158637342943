import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-periodo',
  templateUrl: './periodo.component.html',
  styleUrls: ['./periodo.component.scss'],
})
export class PeriodoFinanceiroComponent implements OnInit, OnDestroy {


  private sessionSubscription: Subscription;
  defaultDate: Date;
  title: string;
  subtitle: string;
  msgs: Message[];
  periodoFin: Array<any> = new Array();

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    public dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.title = 'FINANCEIRO';
    this.titleService.title = 'Período - Financeiro';
    this.subtitle = 'Controle de Períodos - FINANCEIRO';
    this.toolbarService.clear();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
        this.getPeriodos();
      }
    });
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  getPeriodos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'ano': this.dblinkedSessionService.periodo.year.value.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'financeiro/periodos', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            this.periodoFin = new Array();
            for (let line of res["data"]) {
              this.periodoFin.push(line);
            }
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  hideMessage() {

  }

  async mudarStatusPeriodo(row): Promise<void> {

    try {
      if (confirm('Deseja realmente ' + ((row.statusId === 1) ? 'encerrar' : 'reabrir') + ' o mês de ' + row.mes + '?')) {
        if(row.statusId === 1) await this.recalcularSaldo(row, true)
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': this.configService.bearerApi,
            'ecdid': row.ecdid.toString(),
            'userid': this.sessionService.loggedUser.id.toString(),
            'tipo': ((row.statusId === 1) ? 'E' : 'A')
          })
        }
        const payload = {
          'ecd_id': row.ecdid,
          'user_id': this.sessionService.loggedUser.id,
          'tipo': ((row.statusId === 1) ? 'E' : 'A')
        }

        this.httpService.wait()
        await this.httpClient.post(this.configService.defaultUrlApiUpload + 'financeiro/periodos', payload, httpOptions).toPromise().then(res => {
          if (res["error"]) this.exibirMensagem(res)
          else this.getPeriodos()
        })
      }
    } catch(err) {
      this.exibirMensagem(err);
    }

    this.httpService.done()
  }

  async recalcularSaldo(event: any, notQuestion: boolean=false): Promise<void> {
    try {
      if (notQuestion || confirm('Deseja realmente recalcular os saldos financeiros?')) {
        const payload = {
          empresa_id: this.dblinkedSessionService.empresa.id,
          ecd_id: event.ecdid,
          user_id: this.sessionService.loggedUser.id
        }

        this.httpService.wait()
        await this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/recalcular-saldo-fin', payload).toPromise().then(res => {
          this.exibirMensagem(res)
        })
      }
    } catch(err) {
      this.exibirMensagem({ error: true, mensagem: "Erro no API ao recalcular os saldos, contate o suporte!"})
    }

    this.httpService.done()
  }

}
