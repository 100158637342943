export class Nbs {
  id: number;
  codigo: string;
  descricao: string;

  constructor(nbs?: Nbs | number) {
    if (nbs !== undefined && nbs !== null) {
      if (typeof nbs === 'number') {
        this.id = nbs;
      } else {
        this.id = nbs.id;
        this.codigo = nbs.codigo;
        this.descricao = nbs.descricao;
      }
    }
  }
}
