import { Injectable } from '@angular/core';

@Injectable()
export class Config {
  applicationTitle = 'Linnks';
  defaultApplication = 'centralweb';
  crowdAuth = false;
  uniqueLogin = false;
  useSocket = true;

  navigationTimeout: any = null; // TODO: Fix active menu on redirect

  datatableTop = 50;
  autoEdit = true;

  apiUrls: Object = {
    'proof': 'http://localhost:8080/proof',
    'auditor': 'http://localhost:8080',
    'centralweb': 'http://localhost:8080/centralweb'
  };

  apiTestsUrls: Object = {
    'proof': 'https://teste2.linnks.com.br/proof',
    'auditor': 'https://teste2.linnks.com.br',
    'centralweb': 'https://teste2.linnks.com.br/centralweb'
  };

  apiProductionUrls: Object = {
    'proof': 'https://api.linnks.com.br/proof',
    'auditor': 'https://api.linnks.com.br',
    'centralweb': 'https://api.linnks.com.br/centralweb'
  };

  applicationConfig = {
    rowsPerPage: [5, 10, 15, 20, 50],
    rowsPerPageLarge: [15, 20, 25, 30, 50],
    rowsPerPageXLarge: [25, 50, 100],
    rows: 15,
    rows50: 50,
    rowsLarge: 15,
    pageLinks: 5,
    pageLinksLarge: 5,
    testsEnvironment: true
  };

  wsUrl =  'ws://localhost:8080';
  wsTestUrl = 'wss://teste2.linnks.com.br';
  wsProductionUrl = 'wss://api.linnks.com.br';

  tests = false;

  constructor() {
    try {
      if (window.location.hostname.search('app2') != -1) {
        this.tests = true;
      }
    } catch (exception) {

    }
  }
}
