import { Component, OnInit, OnDestroy } from '@angular/core';

import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Empresa } from '../model/empresa.model';
import { HttpService } from 'app/shared/services/http.service';
import { Message, MessageService } from 'primeng/api';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { isNullOrUndefined } from 'util';
import { PlataformaPagamentos } from 'app/util/model/plataforma-pagamentos.model';
import { PlataformaPagamentosServicos} from 'app/util/model/plataforma-pagamentos-servicos.model';
import { PlataformaTipo } from 'app/util/model/plataforma-tipo.model';
import { element } from 'protractor';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'cadastrar-plataforma',
  templateUrl: './cadastrar-plataforma.component.html',
  styleUrls: [
    './cadastrar-plataforma.component.scss'
  ],
  providers: [MessageService]
})
export class CadastrarPlataformaComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private primengConfig: PrimeNGConfig,
    private messageService: MessageService
  ) {}

  private sessionSubscription: Subscription; // change session event;
  title = '';
  subtitle = '';
  // components;
  tiposLista :SelectItem[] = [];
  showLista :boolean = false;
  showNew : boolean = false;
  showEdit : boolean = false;
  descricao : string = '';
  ativo :boolean = false;
  chave :boolean = false;
  email :boolean = false;
  usuario :boolean = false;
  senha :boolean = false;
  urlBase: string = '';
  plataformaTipoId : number = 0;
  opcaoPlataformaTipo :SelectItem [] = [];


  // msgs;
  msgs: Message[] = [];



  // date;
  defaultDate: Date;
  pt_BR: any;
  plataformaPagamentosId : number = 0;


  opcoesAtivos  : SelectItem[] = [];
  opcoesSenha   : SelectItem[] = [];
  opcoesUsuario : SelectItem[] = [];
  opcoesChave   : SelectItem[] = [];
  opcoesEmail   : SelectItem[] = [];
  editServico : boolean = false;

  plataformaPagamentos = new PlataformaPagamentos();
  plataformaPagamentosServicos = new PlataformaPagamentosServicos();


  ngOnInit() {
    this.dblinkedSessionService.hidePeriods();

    this.primengConfig.ripple = true;

    Promise.resolve(null).then(() => (this.toolbarService.hidden = false));
    Promise.resolve(null).then(() => this.refreshToolbar());
    this.opcoesAtivos = [];
    this.opcoesAtivos.push({value : true , label: 'Sim'});
    this.opcoesAtivos.push({value : false , label: 'Não'});

    this.opcoesSenha = [];
    this.opcoesSenha.push({value : true , label: 'Sim'});
    this.opcoesSenha.push({value : false , label: 'Não'});

    this.opcoesUsuario = [];
    this.opcoesUsuario.push({value : true , label: 'Sim'});
    this.opcoesUsuario.push({value : false , label: 'Não'});

    this.opcoesChave = [];
    this.opcoesChave.push({value : true , label: 'Sim'});
    this.opcoesChave.push({value : false , label: 'Não'});

    this.opcoesEmail = [];
    this.opcoesEmail.push({value : true , label: 'Sim'});
    this.opcoesEmail.push({value : false , label: 'Não'});

    this.title = 'CADASTRAR PLATAFORMA ';

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.plataformaPagamentos = new PlataformaPagamentos();
        this.plataformaPagamentos.plataformaPagamentosServicos = new Array<any>();
        this.plataformaPagamentosServicos = new PlataformaPagamentosServicos();

        this.editServico = false;
        this.changeSession();
      }
    );

    this.defaultDate = new Date();
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };


  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  loadComponent(component: string, rowValue: any) {
    this.editServico = false;
    // inicializando variáveis para validação posterior;

    // show;

    this.showLista = component === 'lista' ? true : false;
    this.showEdit = component === 'edit' ? true : false;
    this.showNew = component === 'new' ? true : false;
    this.loadConfiguracoesAPIs();
    if (component === 'new') {
      this.plataformaPagamentos = new PlataformaPagamentos();
      this.plataformaPagamentos.plataformaTipo = new PlataformaTipo();
      this.plataformaPagamentos.plataformaPagamentosServicos = new Array<PlataformaPagamentosServicos>();
      this.plataformaPagamentosServicos = new PlataformaPagamentosServicos();
      this.editServico = false;
      this.subtitle = 'nova plataforma';
      this.descricao = '';
      this.ativo = true;
      this.plataformaPagamentosId = 0;
      this.chave    = false;
      this.email    = false;
      this.usuario  = false;
      this.senha    = false;
      this.urlBase  = '';
      this.plataformaTipoId = 0;


    } else if (component === 'lista') {
      this.subtitle = 'PLATAFORMAS';

      // console.log(this.tiposLista);
    } else if (component === 'edit'){
      // console.log('esse é o rowValue');
      // console.log(rowValue);
      this.plataformaPagamentosServicos = new PlataformaPagamentosServicos();
      this.plataformaPagamentos = new PlataformaPagamentos();
      this.plataformaPagamentos.plataformaTipo = new PlataformaTipo();
      this.plataformaPagamentos.plataformaPagamentosServicos = new Array<PlataformaPagamentosServicos>();
      this.plataformaPagamentos.id =rowValue["id"];
      this.plataformaPagamentos.descricao = rowValue["descricao"];
      this.plataformaPagamentos.ativo    = rowValue["ativo"];
      this.plataformaPagamentos.chave    = rowValue["chave"];
      this.plataformaPagamentos.email    = rowValue["email"];
      this.plataformaPagamentos.usuario  = rowValue["usuario"];
      this.plataformaPagamentos.senha    = rowValue["senha"];
      this.plataformaPagamentos.urlBase  = rowValue["urlBase"];
      this.plataformaPagamentos.plataformaTipoId = rowValue["plataformaTipoId"];

      this.editServico = false;
      this.plataformaPagamentosId = rowValue["id"];
      this.descricao = rowValue["descricao"];
      this.ativo    = rowValue["ativo"];
      this.chave    = rowValue["chave"];
      this.email    = rowValue["email"];
      this.usuario  = rowValue["usuario"];
      this.senha    = rowValue["senha"];
      this.urlBase  = rowValue["urlBase"];
      this.plataformaTipoId = rowValue["plataformaTipoId"];

      this.carregarListaServicos(rowValue["id"]);

    }
  }

  loadConfiguracoesAPIs(){
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-lista-plataforma')
      .subscribe(
        ret => {

          this.tiposLista = [];
          ret.forEach(element => {
            this.tiposLista.push(element);
          });
        },
        error => {
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
          this.httpService.done();
        },
        () => this.httpService.done());
  }

  salvar(edit: boolean){


    //criamos um novo pagamento se edit === true
    if (edit === true){
      if (this.validacao()) {
        return;
      }
      let url = '';
      if (this.showNew === true){

        this.adicionarPlataforma();
      } else if (this.showEdit === true){
        this.editarPlataforma()
      }

    // se edit === false, deletamos o tipo
    } else if (edit === false){
      if (confirm('Deseja deletar esse registro?')) {
        this.deletarPlataformaPagamento();
      }

    }


  }

  adicionarPlataforma(){
    let listasEnviar = {
      plataformaPagamentos : this.plataformaPagamentos,
      plataformaPagamentosId : this.plataformaPagamentosId,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuarioId: this.sessionService.loggedUser.id,
      isNovoRegistro: true
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/salvar-plataforma', listasEnviar)
    .subscribe(
      (res) => {

        this.loadComponent('lista', null);
        if (res["error"] === false) {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'Sucesso',
            detail: 'Plataforma adicionada com sucesso!.'
          });

          this.messageService.add({severity:'sucess', summary:'Sucesso', detail:'Plataforma adicionada com sucesso!.', key: 't'});
        } else{
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: res["message"] });
        }
      },
      error => {
        console.log(error);
        this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Erro." });
        this.httpService.done();
      },
      () => {
        this.httpService.done();
      }
    );


  }
  editarPlataforma(){
    let listasEnviar = {
      plataformaPagamentos : this.plataformaPagamentos,
      plataformaPagamentosId : this.plataformaPagamentosId,
      empresaId: this.dblinkedSessionService.empresa.id,
      usuarioId: this.sessionService.loggedUser.id,
      isNovoRegistro: false
    };


    this.httpService.wait();
    this.httpService.post('/custom/cadastro/salvar-plataforma', listasEnviar)
    .subscribe(
      (res) => {
        this.loadComponent('lista', null);
        if (res["error"] === false) {
          ({
            severity: 'success',key: 'messageLinnks',
            summary: 'Sucesso',
            detail: 'Plataforma salva com sucesso!.'
          });

          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Plataforma salva com sucesso!.',
            key: 't'
          });


        } else{
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: res["message"] });
        }
      },
      error => {
        console.log(error);
        this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao salvar." });
        this.adicionarPlataforma();
      },
      () => {
        this.httpService.done();
      }
    );


  }

  deletarPlataformaPagamento(){
    console.log('delete plataforma');
    let listasEnviar = {
      plataformaPagamentosId : this.plataformaPagamentosId,
    };
    this.httpService.wait();
    this.httpService.post('/custom/cadastro/delete-plataforma-pagamento', listasEnviar)
    .subscribe(
      (res) => {
        console.log(res);
        this.loadComponent('lista', null);
        if (res["error"] === false) {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'Sucesso',
            detail: 'Registro excluído com sucesso.'
          });
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Registro excluído com sucesso.',
            key: 't'});
        } else{
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: res["message"] });
        }
      },
      error => {
        console.log(error);
        this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao excluir." });
        this.httpService.done();
      },
      () => {
        this.httpService.done();
      }
    );


  }

  private changeSession() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.loadComponent('lista', null);
      this.getPlataformasTipo();
    }
  }

  private alterarStatusAtualizar(boo: boolean) {
    this.toolbarService.alterarStatus('atualizar', !boo, 'Atualizar', () => {
      this.changeSession();
    });
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  hideMessage() {

  }

  // app.component.ts;
  private refreshToolbar() {
    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    // this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('edit');
    // this.toolbarService.ocultar('add');
    //this.alterarStatusAtualizar(true);
    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar', () => this.changeSession());
    this.toolbarService.replace(
      'add',
      false,
      'class-new-button',
      'NOVO',
      'Adicionar',
      () => {
        this.loadComponent('new', null);
      }
    );

  }

  validacao(){

    if (!this.dblinkedSessionService.hasEmpresaSelected ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecionar uma empresa!'
      });
      return true;

    }

    if (isNullOrUndefined(this.plataformaPagamentos.plataformaTipoId) || this.plataformaPagamentos.plataformaTipoId === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um tipo de plataforma!'
      });
      return true;

    }

    if (isNullOrUndefined( this.plataformaPagamentos.descricao) ||  (!isNullOrUndefined(this.plataformaPagamentos.descricao) && this.plataformaPagamentos.descricao.trim() === '' ) ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar uma descrição!'
      });
      return true;
    }

    return false;
  }

  getPlataformasTipo(){
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-lista-plataforma-tipo-combo')
      .subscribe(
        ret => {

          this.opcaoPlataformaTipo = [];
          this.opcaoPlataformaTipo.push({value: 0, label: 'Selecione um tipo de plataforma'});
          ret.forEach(element => {
            this.opcaoPlataformaTipo.push({value: element.id, label: element.descricao});
          });
        },
        error => {
          console.log(error);
          this.messageService.add({severity: 'error',key: 'messageLinnks', summary: '', detail: "Falha ao carregar." });
          this.httpService.done();
        },
        () => this.httpService.done());

  }




  addServico(){
    this.editServico = false;

    if (this.validacaoServicos()) {
      return;
    }

      if (this.plataformaPagamentos.plataformaPagamentosServicos === undefined) {
        this.plataformaPagamentos.plataformaPagamentosServicos = new Array();
      }

      this.plataformaPagamentosServicos.id = (this.plataformaPagamentos.plataformaPagamentosServicos === undefined ? 1 : this.plataformaPagamentos.plataformaPagamentosServicos.length + 1);
      this.plataformaPagamentos.plataformaPagamentosServicos.push( this.plataformaPagamentosServicos);

    this.plataformaPagamentosServicos.idDoBanco = 0;

    this.plataformaPagamentosServicos = new PlataformaPagamentosServicos();


    console.log(this.plataformaPagamentos);

  }



  carregarListaServicos(plataformaPagamentosId: number){
    console.log(plataformaPagamentosId)

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-lista-plataforma-servicos(' + plataformaPagamentosId + ')')
      .subscribe(
        ret => {
          ret.forEach(element =>{
            if (this.plataformaPagamentos.plataformaPagamentosServicos === undefined) {
              this.plataformaPagamentos.plataformaPagamentosServicos = new Array();
            }
            this.plataformaPagamentosServicos = new PlataformaPagamentosServicos();
            this.plataformaPagamentosServicos.plataformaPagamentos = new PlataformaPagamentos()

            this.plataformaPagamentosServicos.id = element.id;
            this.plataformaPagamentosServicos.idDoBanco = element.id;
            this.plataformaPagamentosServicos.plataformaPagamentos.id = element.plataformaPagamentosId;
            this.plataformaPagamentosServicos.descricao = element.descricao;
            this.plataformaPagamentosServicos.sufixo = element.sufixo;
            this.plataformaPagamentosServicos.ativo = element.ativo;
            this.plataformaPagamentosServicos.temPaginacao = element.temPaginacao;

            this.plataformaPagamentos.plataformaPagamentosServicos.push( this.plataformaPagamentosServicos);


          })
          this.plataformaPagamentosServicos = new PlataformaPagamentosServicos();
        },
        error => this.httpService.handleError(error, () => this.carregarListaServicos(plataformaPagamentosId)),
        () => this.httpService.done());


  }

  atualizaServico() {

    if (this.validacaoServicos()) {
      return;
    }

      if (this.plataformaPagamentos.plataformaPagamentosServicos === undefined) {
        this.plataformaPagamentos.plataformaPagamentosServicos = new Array();
      }

      const index = this.plataformaPagamentos.plataformaPagamentosServicos.findIndex(c => c.id === this.plataformaPagamentosServicos.id);
      console.log(index);
      this.plataformaPagamentos.plataformaPagamentosServicos[index].descricao = this.plataformaPagamentosServicos.descricao;
      this.plataformaPagamentos.plataformaPagamentosServicos[index].sufixo = this.plataformaPagamentosServicos.sufixo;
      this.plataformaPagamentos.plataformaPagamentosServicos[index].ativo = this.plataformaPagamentosServicos.ativo;
      this.plataformaPagamentos.plataformaPagamentosServicos[index].temPaginacao = this.plataformaPagamentosServicos.temPaginacao;

      this.editServico = false;
      this.plataformaPagamentosServicos = new PlataformaPagamentosServicos();




  }

  selectServico(value: any){
    console.log(value);
    this.editServico = true;
    this.plataformaPagamentosServicos = new PlataformaPagamentosServicos();
    this.plataformaPagamentosServicos.id = value.id;
    this.plataformaPagamentosServicos.descricao = value.descricao;
    this.plataformaPagamentosServicos.sufixo    = value.sufixo;
    this.plataformaPagamentosServicos.idDoBanco = value.idDoBanco;
    this.plataformaPagamentosServicos.ativo     = value.ativo;
    this.plataformaPagamentosServicos.temPaginacao     = value.temPaginacao;

  }

  removerServico(value: any){
    console.log(value);
    if (confirm('Deseja remover este item?')) {

      const listaEnviar = {
        id              : value.idDoBanco

     };


     //this.httpService.wait();
     this.httpService.post('/custom/cadastro/deletar-plataforma-pagamento-servico', listaEnviar)
       .subscribe(result => {
        if (result[0].error ) {
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        } else {
          const index = this.plataformaPagamentos.plataformaPagamentosServicos.findIndex(c => c.id === value.id);
          this.plataformaPagamentos.plataformaPagamentosServicos.splice(index,1);
        }

       },
         error => this.httpService.handleError(error, () => this.removerServico( value)),
         () => this.httpService.done());


    }

  }

  validacaoServicos(){
    if (isNullOrUndefined(this.plataformaPagamentosServicos.sufixo) || this.plataformaPagamentosServicos.sufixo === '') {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar o sufixo do servico!'
      });
      return true;

    }


    if (isNullOrUndefined(this.plataformaPagamentosServicos.descricao) || this.plataformaPagamentosServicos.descricao === '') {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor digitar a descrição do serviço!'
      });
      return true;

    }

    return false;

  }

}
