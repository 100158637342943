import { truncateSync } from 'fs';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { Subscription } from "rxjs/Subscription";
import { GrupoEstoque } from "../../estoque/model/grupo-estoque.model";
import { Empresa } from "../../cadastro/model/empresa.model";
import { CrudService } from "app/shared/services/crud.service";
import { DataTableBasicComponent } from "app/shared/components/data-table/data-table-basic.component";
import { DataTableColumn } from "app/shared/components/data-table/model/data-table-column.model";
import { DataControl } from "app/shared/components/data-table/model/data-control.model";
import { HttpService } from "app/shared/services/http.service";
import { SessionService } from "app/shared/services/session.service";
import { DblinkedSessionService } from "app/shared/services/dblinked-session.service";
import { Message, MessageService, SelectItem } from "primeng/api";
import { Period } from "../../shared/components/period-select/period.model";
import { Toolbar } from "app/shared/model/toolbar.model";
import { ConfigService } from "app/shared/services/config.service";
import { ToolbarService } from "app/shared/services/toolbar.service";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
  selector: "app-grupo-estoque",
  templateUrl: "./grupo-estoque.component.html",
  styleUrls: ["./grupo-estoque.component.scss"],
  providers: [CrudService],
})
export class GrupoEstoqueComponent
  extends DataTableBasicComponent
  implements OnInit, OnDestroy {
  public grupoEstoque: GrupoEstoque = new GrupoEstoque();
  public gruposEstoque: Array<GrupoEstoque> = new Array();
  public registrosGruposEstoque: Array<GrupoEstoque> = new Array();
  public empresaSelecionada: number;
  msgs: Message[];
  formulario: FormGroup;

  title: string;
  subtitle: string;
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;

  codigo: any;
  descricao: any;

  ids: Array<any> = new Array();

  selectedRows: Array<any> = [];


  buttonToolbar = this.getButtonToolbarContent();
  toolbarMainIni = this.getToolbarMainIniContent();

  public toolbarMain = new Toolbar();

  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeViewSubscription: Subscription;
  private beforeEditSubscription: Subscription;

  entityFilter: string = null;

  constructor(
    private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private messageService: MessageService
  ) {
    super(crudService);
  }

  public toolbar = new Toolbar()

  toolbarIni = [
    { icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]

  ngOnInit() {
    // TODO: REVIEW (IMPORTANT!)
    this.toolbarService.hidden = true;
    this.toolbar.setAll(this.toolbarIni);
    this.createForm(new GrupoEstoque());

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
    );
    this.toolbar.setVisible('btn1', false)
    this.toolbar.setVisible('btn2', false)
    this.toolbar.setVisible('btn3', false)
    this.toolbar.setVisible('btn4', true)
    this.toolbar.setVisible('btn5', false)
    this.toolbar.setVisible('btn6', false)

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.ctrlSave = true;
  }

  createForm(grupoEstoque: GrupoEstoque) {
    this.formulario = new FormGroup({
      codigo: new FormControl(grupoEstoque.codigo),
      descricao: new FormControl(grupoEstoque.descricao),
    });
  }

  criarNovo() {
    this.novaConta = true;
    this.title = "Cadastrar";
    this.toolbar.setVisible('btn1', true)
    this.toolbar.setVisible('btn2', false)
    this.toolbar.setVisible('btn3', false)
    this.toolbar.setVisible('btn4', false)
    this.toolbar.setVisible('btn5', true)
    this.toolbar.setVisible('btn6', false)

    this.limpaCamposForm()
  }

  carregar() {
    this.title = "Cadastro Grupo de Estoque";
    this.subtitle = "Grupo de Estoque";
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();

    this.toolbar.setVisible('btn1', false)
    this.toolbar.setVisible('btn2', false)
    this.toolbar.setVisible('btn3', false)
    this.toolbar.setVisible('btn4', true)
    this.toolbar.setVisible('btn5', false)
    this.toolbar.setVisible('btn6', false)

    this.limpaCamposForm();

    this.loadRegistros();
  }

  loadRegistros() {
    this.httpService.wait();
    this.httpService
      .get(
        `/grupoestoque?$select=codigo,descricao&$count=true&$skip=0&$filter=empresa/id eq ${this.dblinkedSessionService.empresa.id}&$orderby=codigo`
      )
      .subscribe(
        (retorno) => {
          this.registrosGruposEstoque = new Array();
          retorno.value.forEach((item) => {
            this.registrosGruposEstoque.push(item);
          });
        },
        (error) => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = "Editar";
    this.editarConta = true;

    this.toolbar.setVisible('btn1', true)
    this.toolbar.setVisible('btn2', false)
    this.toolbar.setVisible('btn3', false)
    this.toolbar.setVisible('btn4', false)
    this.toolbar.setVisible('btn5', false)
    this.toolbar.setVisible('btn6', true)

    this.limpaCamposForm();

    this.httpService.wait();
    this.httpService
      .get(`/grupoestoque(${this.ids[0]})?$select=codigo,descricao&$count=true`)
      .subscribe(plano => {
        this.codigo = plano.value.codigo;
        this.descricao = plano.value.descricao;
      },
        (error) => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      codigo: this.codigo,
      descricao: this.descricao,
      empresa: {
        id: this.dblinkedSessionService.empresa.id
      },

    };

    this.httpService.wait();
    this.httpService.post('/grupoestoque', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  // salvarNovoRegistro() {
  //   if (this.validacao(true)) {
  //     return;
  //   }
  //   const jsonData = {
  //     codigo: this.codigo,
  //     descricao: this.descricao,
  //     empresa: {
  //       id: this.dblinkedSessionService.empresa.id
  //     },
  //   };

  //   this.httpService.wait();
  //   this.httpService.post("/grupoestoque", jsonData).subscribe(
  //     (result) => {
  //       if (!(result === null || result === undefined)) {
  //         this.messageService.add({
  //           severity: "success",
  //           key: "messageLinnks",
  //           summary: "Sucesso  ",
  //           detail: "Registro cadastrado com sucesso!",
  //         });
  //         this.carregar();
  //       } else {
  //         this.messageService.add({
  //           severity: "warn",
  //           key: "messageLinnks",
  //           summary: "Advertência",
  //           detail: "Falha no cadastro. Tente novamente",
  //         });
  //         this.carregar();
  //         return;
  //       }
  //     },
  //     (error) =>
  //       this.httpService.handleError(error, () => this.salvarNovoRegistro()),
  //     () => this.httpService.done()
  //   );
  // }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const listasEnviar = {
      id: this.ids[0],
      codigo: this.codigo,
      descricao: this.descricao,
    };

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService
      .patch("/grupoestoque", key, listasEnviar, null)
      .subscribe(
        (result) => {
          if (result === null) {
            this.messageService.add({
              severity: "success",
              key: "messageLinnks",
              summary: "Sucesso  ",
              detail: "Registro alterado com sucesso!",
            });
            this.carregar();
          } else {
            this.messageService.add({
              severity: "warn",
              key: "messageLinnks",
              summary: "Advertência",
              detail: "Falha na alteração do registro!",
            });
            this.carregar();
            return;
          }
        },
        (error) =>
          this.httpService.handleError(error, () =>
            this.salvarAlteracaoRegistro()
          ),
        () => this.httpService.done()
      );
  }

  excluirRegistro() {
    if (confirm("Deseja deletar os registros selecionados? ")) {
      this.httpService.wait();
      this.httpService.delete("/grupoestoque", this.ids).subscribe(
        (result) => {
          if (result === null) {
            this.messageService.add({
              severity: "success",
              key: "messageLinnks",
              summary: "",
              detail: "Registro excluído com sucesso!",
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: "warn",
              key: "messageLinnks",
              summary: "",
              detail: "Falha na exclusão. Tente novamente!",
            });
          }
        },
        (error) => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
    }
  }

  selectCheckboxContainer(data) {
      document.getElementById('mycheck' + data.id).click();
  };

  changeToolbar() {
    this.toolbar.setVisible('btn2', true)
    this.toolbar.setVisible('btn3', true)
  }

  changeToolbarUn() {
    this.toolbar.setVisible('btn2', false)
    this.toolbar.setVisible('btn3', false)
  }

  selectCheckbox(event: any) {
    this.ids = [];

    this.ids.push(event.data.id);
    event.selected = false;
    this.changeToolbar()

    const check = this.ids[this.ids.length - 1];
    this.ids.length = 0;
    this.ids.push(check)
    console.log(this.ids)
  }

  unselectCheckbox(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.changeToolbarUn()

    const check = this.ids[this.ids.length - 1];
    this.ids.length = 0;
    this.ids.push(check)
    console.log(this.ids)
  }

  filtraFuncoesToolbar() {
    if (this.ids.length === 1) {
      this.toolbarMain.setDisabled(this.buttonToolbar.excluir, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.editar, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
    } else {
      this.toolbarMain.setDisabled(this.buttonToolbar.excluir, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.editar, false);
      this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
    }
  }

  validacao(): boolean {
    if ((this.codigo === null || this.codigo === undefined) || this.codigo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório' });
      return true;
    }
    if ((this.descricao === null || this.descricao === undefined) || this.descricao.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição é Obrigatória' });
      return true;
    }
    return false;
  }

  private getButtonToolbarContent() {
    return {
      voltar: "btn1",
      excluir: "btn2",
      editar: "btn3",
      adicionarNovo: "btn4",
      salvarNovo: "btn5",
      salvarAlteracao: "btn6",
    };
  }

  private getToolbarMainIniContent() {
    return [
      {
        icon: "undo",
        text: "Voltar",
        tooltip: "Voltar",
        visible: true,
        disabled: true,
        color: "default",
        onClick: () => this.carregar(),
      },
      {
        icon: "delete",
        text: "Excluir",
        tooltip: "Excluir",
        visible: true,
        disabled: true,
        color: "default",
        onClick: () => this.excluirRegistro(),
      },
      {
        icon: "edit",
        text: "Editar",
        tooltip: "Editar",
        visible: true,
        disabled: true,
        color: "default",
        onClick: () => this.loadEditarRegistro(),
      },
      {
        icon: "add",
        text: "Novo",
        tooltip: "Novo",
        visible: true,
        disabled: false,
        color: "green",
        onClick: () => this.criarNovo(),
      },
      {
        icon: "check",
        text: "Salvar",
        tooltip: "Salvar Novo Registro",
        visible: true,
        disabled: false,
        color: "default",
        onClick: () => this.salvarNovoRegistro(),
      },
      {
        icon: "check",
        text: "Salvar",
        tooltip: "Salvar Alteração",
        visible: true,
        disabled: false,
        color: "default",
        onClick: () => this.salvarAlteracaoRegistro(),
      },
    ];
  }

  private carregaBotoesToolbarFormEditar() {
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, true);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, false);

    this.toolbarMain.setDisabled(this.buttonToolbar.voltar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.salvarAlteracao, false);
  }

  private carregaBotoesToolbarFormNovo() {
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.voltar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, true);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, false);

    this.toolbarMain.setDisabled(this.buttonToolbar.voltar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.salvarNovo, false);
  }

  private carregaBotoesToolbarInicial() {
    this.ocultaBotoesToolbar();

    this.toolbarMain.setVisible(this.buttonToolbar.excluir, true);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, true);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, false);

    this.toolbarMain.setDisabled(this.buttonToolbar.excluir, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.editar, false);
    this.toolbarMain.setDisabled(this.buttonToolbar.adicionarNovo, false);
  }

  private ocultaBotoesToolbar() {
    this.toolbarMain.setVisible(this.buttonToolbar.voltar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.excluir, false);
    this.toolbarMain.setVisible(this.buttonToolbar.editar, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarNovo, false);
    this.toolbarMain.setVisible(this.buttonToolbar.salvarAlteracao, false);
    this.toolbarMain.setVisible(this.buttonToolbar.adicionarNovo, true);
  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    // this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  limpaCamposForm() {
    this.codigo = '';
    this.descricao = '';
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() { }
}
