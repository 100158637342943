import { Rateio } from "./rateio.model";
import { CadEcd } from "app/contabil/model/cad-ecd.model";
import { Nfe } from "app/financeiro/model/nfe.model";
import { MovInternaItem } from "./movimentacao-interna-item.model";
import { Mi } from "app/cadastro/model/mi.model";

export class MovInterna {
  id: number;
  dtMov: Date;
  codigo: string;
  historico: string;
  doc: string;
  valor: number;
  rateio: Rateio;
  cadEcd: CadEcd;
  nfe: Nfe;
  mi: Mi;
  movInternaItem: Array<MovInternaItem>;

  constructor(movInterna?: MovInterna | number) {
    if (movInterna !== undefined && movInterna !== null) {
      if (typeof movInterna === 'number') {
        this.id = movInterna;
      } else {
        this.id = movInterna.id;
        this.dtMov = movInterna.dtMov;
        this.codigo = movInterna.codigo;
        this.historico = movInterna.historico;
        this.doc = movInterna.doc;
        this.valor = movInterna.valor;
        this.rateio = movInterna.rateio;
        this.cadEcd = movInterna.cadEcd;
        this.nfe = movInterna.nfe;
        this.mi = movInterna.mi;
        this.movInternaItem = movInterna.movInternaItem;
      }
    }
  }
}
