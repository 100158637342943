import { Empresa } from 'app/cadastro/model/empresa.model';

export class GrupoEstoque {
  id: number;
  empresa: Empresa;
  codigo: string;
  descricao: string;


  constructor(grupoEstoque?: GrupoEstoque | number) {
    if (grupoEstoque !== undefined && grupoEstoque !== null) {
      if (typeof grupoEstoque === 'number') {
        this.id = grupoEstoque;
      } else {
        this.id = grupoEstoque.id;
        this.empresa = grupoEstoque.empresa;
        this.codigo = grupoEstoque.codigo;
        this.descricao = grupoEstoque.descricao;
      }
    }
  }
}
