export class ItemGenero {
  id: number;
  codigo: string;
  descricao: string;

  constructor(itemGenero?: ItemGenero | number) {
    if (itemGenero !== undefined && itemGenero !== null) {
      if (typeof itemGenero === 'number') {
        this.id = itemGenero;
      } else {
        this.id = itemGenero.id;
        this.codigo = itemGenero.codigo;
        this.descricao = itemGenero.descricao;
      }
    }
  }
}
