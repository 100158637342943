import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MessageService } from '../../../../node_modules/primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-config-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ConfigCategoriaComponent implements OnInit, OnDestroy {
  ////////////////////////////////////////
  // REFATOR SIMON LALLEMENT 28/12/2021 //
  ////////////////////////////////////////


  private sessionSubscription: Subscription

  //Toolbar
  toolbarMainIni = [
    { icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: false, disabled: false, color: 'red', onClick: () => this.excluirCat() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openEditCat() },
    { icon: 'add', text: 'Nova', tooltip: 'Nova', visible: true, disabled: false, color: 'green', onClick: () => this.openAddCat() },
  ]
  public toolbarMain = new Toolbar()

  //Array
  categoriaRequisicao: Array<any> = new Array()
  catSelected: Array<number> = new Array()
  selectedRows: Array<any> = new Array()

  //String
  titlePrincipal: string = 'Configurações RMS'
  subtitle: string = 'Categoria'
  currentStateCategoria: string = 'initial'
  descricaoCategoria: string = null

  //Boolean
  isCardCategoria: boolean = true
  checkedAll: boolean = false
  modalCategoria: boolean = false
  add: boolean = false
  edit: boolean = false

  //Miscellaneous
  catSelecionada: any = {}

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.titleService.title = 'Linnks - Categoria RMS'
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.categoriaRequisicaoItens()
      }
    })
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
  }

  openAddCat() {
    this.modalCategoria = true
    this.descricaoCategoria = null
    this.add = true
  }

  openEditCat() {
    this.modalCategoria = true
    this.catSelecionada = this.categoriaRequisicao.find(element => this.selectedRows[0].id === element.id)
    this.descricaoCategoria = this.catSelecionada.nome
    this.edit = true
  }

  excluirCat() {
    if (confirm("Deseja remover as categorias selecionadas?")) {

      let listaEnviar = {
        id: this.selectedRows.map(item => item.id)
      }

      this.httpService.wait();
      this.httpService.post('/custom/rms/deletar-categoria', listaEnviar)
        .subscribe(rst => {
          this.httpService.done()

          if(rst?.error) {
            return this.exibirMensagem(rst);
          }

          this.categoriaRequisicaoItens()
          this.checkToolBar()
          this.resetSelected()
        }, error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao excluir as categorias, contate o suporte : " + error.message })
          this.httpService.done()
          this.resetSelected()
        })
    }
  }

  changeStateCategoria() {
    this.currentStateCategoria = this.currentStateCategoria === 'initial' ? 'final' : 'initial'
  }

  showHideCategoria() {
    this.isCardCategoria = !this.isCardCategoria
  }

  adicionar() {
    if (this.validacao()) return

    let listaEnviar = {
      nomeTipo: this.descricaoCategoria,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait();
    this.httpService.post('/custom/rms/adicionar-categoria', listaEnviar).subscribe(rst => {
      if(rst.error){this.exibirMensagem({ severity: "error", message: rst.detail })}
      else{
        this.exibirMensagem({ severity: "success", message: rst.detail })
        this.categoriaRequisicaoItens()
        this.modalCategoria = false
        this.add = false
      }
    },
    error => {
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar a categoria, contate o suporte : " + error.message })
      this.resetSelected()
    },
    () => this.httpService.done())
  }

  validacao(): boolean {
    if (!this.descricaoCategoria) {
      this.exibirMensagem({ error: true, mensagem: "O campo Descrição é obrigatório." })
      return true
    }
    if(this.categoriaRequisicao.map(item => { return item.nome.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z._ ])/g, '').toLowerCase().trim() }).includes(this.descricaoCategoria.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z._ ])/g, '').toLowerCase().trim())) {
      this.exibirMensagem({ severity: 'warn', mensagem: "A descrição deve ser única." })
      return true
    }

    return false
  }


  categoriaRequisicaoItens() {
    this.httpService.wait();
    this.httpService.get('/custom/rms/listar-categoria', this.dblinkedSessionService.empresa.id)
      .subscribe(rst => {
        this.categoriaRequisicao = new Array()
        rst.forEach(element => { this.categoriaRequisicao.push(element) })
        this.httpService.done()
        this.resetSelected()
      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao obter as categorias, contate o suporte : " + error.message })
          this.httpService.done()
          this.resetSelected()
        })
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  atualizar() {
    if (this.validacao()) return

    let listaEnviar = {
      id: this.catSelecionada.id,
      nome: this.descricaoCategoria,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait();
    this.httpService.post('/custom/rms/atualizar-categoria', listaEnviar).subscribe(rst => {
      if(rst.error){this.exibirMensagem({ severity: "error", message: rst.detail })}
      else{
        this.exibirMensagem({ severity: "success", message: rst.detail })
        this.categoriaRequisicaoItens()
        this.checkToolBar()
        this.modalCategoria = false
        this.edit = false
      }
    }, error => {
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar a categoria, contate o suporte : " + error.message })
      this.resetSelected()
    },
    () => this.httpService.done())
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  resetSelected() {
    this.selectedRows = [];
    this.edit = false;
    this.checkToolBar();
  }

  checkToolBar() {
    this.edit = false;
    this.toolbarMain.setVisible('btn1', this.selectedRows.length !== 0);
    this.toolbarMain.setVisible('btn2', this.selectedRows.length === 1);
  }

}
