import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { isNullOrUndefined } from 'util';
import { Consultor } from 'app/eventos/model/consultor.model';
import { Carteira } from 'app/eventos/model/carteira.model';
import { Message, MessageService, SelectItem } from 'primeng/api';


@Component({
  selector: 'app-carteira',
  templateUrl: './carteira.component.html',
  styleUrls: ['./carteira.component.scss'],
  providers: [CrudService]
})
export class CarteiraComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  pt_BR: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;


  modalCarteira           : boolean = false;
  listaCarteira           : Array<any> = new Array();
  idLista                 : Array<any> = new Array();
  consultorSelecionadoId  : number = null;
  consultorBackOfficeId   : number = null;


  checkedAll    = false;
  consultor     = new Consultor();
  carteira      = new Carteira();
  backoffice      = new Carteira();


  editCarteira                     : boolean = false;
  btnSalvarDisabled                : boolean = false;
  btnCancelarDisabled              : boolean = false;
  opcoesConsultores                : SelectItem[] = [];
  opcoesCarteiras                  : SelectItem[] = [];
  opcoesStatus                     : SelectItem[] = [];

  colslistaCarteira                : any[];


  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title              = 'CARTEIRA';
    this.subtitle           = '';
    this.titleService.title = 'CARTEIRA';


    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();



    this.modalCarteira      = false;
    this.checkedAll         = false;


    this.listaCarteira = new Array();

    this.consultor      = new Consultor();
    this.carteira       = new Carteira();
    this.carteira.backoffice = new Carteira();
    this.backoffice     = new Carteira();
    this.editCarteira   = false;
    this.idLista        = new Array();
    this.opcoesConsultores = [];
    this.opcoesCarteiras = [];
    this.consultorSelecionadoId = null;
    this.consultorBackOfficeId  = null;

    this.colslistaCarteira = [
      { field: 'carteiraNome', header: 'CARTEIRA' },
      { field: 'consultorNome', header: 'CONSULTOR' },
      { field: 'email', header: 'EMAIL' },
      { field: 'recebeAutomaticoPt', header: 'BAL. AUTOM.' }
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {

        if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasYearSelected &&
          this.dblinkedSessionService.usuario) {
            this.listar();
            this.listaConsultores();

          }

      });

  }

 listar(){

  this.modalCarteira      = false;
  this.checkedAll         = false;

  this.listaCarteira = new Array();
  this.consultor      = new Consultor();
  this.carteira       = new Carteira();
  this.carteira.backoffice = new Carteira();
  this.editCarteira   = false;
  this.idLista        = new Array();
  this.opcoesConsultores = [];
  this.consultorSelecionadoId = null;
  this.consultorBackOfficeId  = null;

  this.opcoesStatus = [];
  this.opcoesStatus.push({label: 'Sim', value: 'Sim' });
  this.opcoesStatus.push({label: 'Não', value: 'Não' });

  this.configuraToolBar()
  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-carteira')
    .subscribe(retorno => {

      this.listaCarteira = new Array();
      this.opcoesCarteiras = [];
      this.opcoesCarteiras.push({label: 'Selecione uma carteira', value: 0});
      retorno.forEach(i =>{
        this.listaCarteira.push(i);
        this.opcoesCarteiras.push({label: i.carteiraNome, value: i.id});
      })
    },
      error => this.httpService.handleError(error, () => this.listar()),
      () => this.httpService.done());



 }

 listaConsultores(){
  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-consultores')
    .subscribe(retorno => {
      this.opcoesConsultores = [];
      this.opcoesConsultores.push({label: 'Selecione um consultor', value: 0});
      retorno.forEach(i =>{
        this.opcoesConsultores.push({label: i.nome + ' - ' + i.email , value: i.id});
      })
    },
      error => this.httpService.handleError(error, () => this.listar()),
      () => this.httpService.done());

 }


  hideMessage() {




  }

  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;

    this.idLista = new Array();

    this.listaCarteira.forEach(i=> {
      if (!this.checkedAll) {
        i.selected = false;
        this.idLista.slice(i.id, 1);
      } else {
        i.selected = true;
        this.idLista.push(i.id);
      }
    });


    this.configuraToolBar();
  }

  configuraToolBar() {
    if (isNullOrUndefined(this.idLista) || this.idLista.length === 0) {
     this.toolbarService.alterarStatus('remove', true, 'Excluir Carteira', () => this.excluirCarteira());
     this.toolbarService.alterarStatus('add', false, 'Nova Carteira', ()  => this.showModalNovaCarteira());
     this.toolbarService.alterarStatus('export', true, 'Editar Carteira', () =>() => this.showModalEditarCarteira());
    } else {
      if (this.idLista.length === 1) {
        this.toolbarService.alterarStatus('remove', false, 'Excluir Carteira', () => this.excluirCarteira());
        this.toolbarService.alterarStatus('add', true, 'Nova Carteira', ()  => this.showModalNovaCarteira());
        this.toolbarService.alterarStatus('export', false, 'Editar Carteira', ()  => this.showModalEditarCarteira());


      } else if(this.idLista.length > 1)  {
        this.toolbarService.alterarStatus('remove', false, 'Excluir Carteira', () => this.excluirCarteira());
        this.toolbarService.alterarStatus('add', true, 'Nova Carteira', () => this.showModalNovaCarteira());
        this.toolbarService.alterarStatus('export', true, 'Editar Carteira', () => this.showModalEditarCarteira());
      }
    }

  }



  selectCheckbox(id: any, event: any) {

    if (event.selected) {
       event.selected = true;
       const index = this.idLista.findIndex(i => i === id);
       this.idLista.splice(index,1);

    } else {
      this.idLista.push(id);
    }

    this.configuraToolBar();

  }



  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('edit');

    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.ngOnInit()
    );
    this.toolbarService.ocultar('save');

   // this.toolbarService.replace('more_vert', true, 'class-edit-button', 'more_vert', 'Opções');
    this.toolbarService.ocultar('pesquisa');

    //this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir Pedido',     );
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('export', true, 'class-edit-button', 'edit', 'Editar Palestra', () => this.showModalEditarCarteira() );
   // this.toolbarService.replace('edit', true, 'class-edit-button', 'list', 'Alterar Títulos Selecionados');

    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Nova Carteira',    () => this.showModalNovaCarteira()    );

  }










  showModalEditarCarteira() {
    this.modalCarteira = true;
    this.hideMessage();
    this.editCarteira = true;

    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-carteira-inf(' + this.idLista[0] +')')
      .subscribe(retorno => {


        this.carteira      = new Carteira();
        this.carteira.id   = this.idLista[0];
        this.carteira.nome = retorno[0].nome;
        this.carteira.consultor = new Consultor();
        this.carteira.consultor = retorno[0].consultorId;
        this.carteira.backoffice = retorno[0].consultorBackOfficeId;
        this.consultorSelecionadoId = retorno[0].consultorId;
        this.consultorBackOfficeId = retorno[0].consultorBackOfficeId;
        this.carteira.recebeAutomatico = retorno[0].recebeAutomatico;

        let index = this.opcoesConsultores.findIndex(i=> i.value === retorno[0].consultorId);
        this.opcoesConsultores[index];
      },
        error => this.httpService.handleError(error, () => this.showModalEditarCarteira()),
        () => this.httpService.done());




  }

  showModalNovaCarteira() {
    this.listaConsultores();
    this.carteira             = new Carteira();
    this.carteira.consultor   = new Consultor();
    this.modalCarteira = true;
    this.carteira.recebeAutomatico = true;
    this.hideMessage();


  }



  validacao(){

    if (isNullOrUndefined(this.consultorSelecionadoId) || this.consultorSelecionadoId === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um consultor!'
      });
      return true;
    }


    if (isNullOrUndefined( this.carteira.nome) || this.carteira.nome.length < 3 ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'O carteira deve ter o mínimo de 3 caracteres!'
      });
      return true;

    }





    return false;
  }


  ngOnDestroy(): void {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  salvarCarteira(){

    if (this.validacao()) {
      return;
    }



    const listaEnviar = {
       carteira       : this.carteira,
       usuarioId      : this.sessionService.loggedUser.id,
       IsNovoRegistro : true
    };


    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-carteira', listaEnviar)
      .subscribe(result => {

        if (result[0].error === true ) {
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        }

        this.listar();

      },
        error => this.httpService.handleError(error, () => this.salvarCarteira()),
        () => this.httpService.done());
  }

  editarCarteira(){

    if (this.validacao()) {
      return;
    }


    const listaEnviar = {
      id              : this.idLista[0],
      carteira        : this.carteira,
      usuarioId       : this.sessionService.loggedUser.id,
      IsNovoRegistro  : false
   };

   this.httpService.wait();
   this.httpService.post('/custom/polozi/salvar-carteira', listaEnviar)
     .subscribe(result => {
      if (result[0].error === true ) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      }
      this.listar();

     },
       error => this.httpService.handleError(error, () => this.salvarCarteira()),
       () => this.httpService.done());


  }


 excluirCarteira(){
   if (confirm('Deseja excluir a(s) carteiras(es) selecionada(s)?')) {

    const listaEnviar = {
      ids              : this.idLista

   };

   this.httpService.wait();
   this.httpService.post('/custom/polozi/deletar-carteira', listaEnviar)
     .subscribe(result => {
      if (result[0].error === true ) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result[0].message
        });
      }
      this.listar();
     },
       error => this.httpService.handleError(error, () => this.salvarCarteira()),
       () => this.httpService.done());


   }
 }

 onChangeConsultor(event : any){
  this.consultorSelecionadoId = event.value;

 }

 onChangeBackOffice(event : any){
  this.consultorBackOfficeId = event.value;

 }


}
