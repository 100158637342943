import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { TitleService } from 'app/shared/services/title.service';
import { isNullOrUndefined } from 'util';
import { Service } from 'app/shared/services/socket/protocol/Service';
import { Action } from 'app/shared/services/socket/protocol/Action';
import { Method } from 'app/shared/services/socket/protocol/Method';
import { PysocketService } from 'app/shared/services/socket/pysocket.service';
import idv4 from 'app/util/processing/idv4';

@Component({
  selector: 'app-report',
  templateUrl: './relatorios-gerenciais.component.html',
  styleUrls: ['./relatorios-gerenciais.component.scss'],
})

export class RelatoriosGerenciaisComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;

  listaRelat: Array<any> = new Array();
  dicRelatComposto: any = {};
  datasIndividuais: boolean = false;
  relatorio: any;
  modalRelatorio: boolean = false;
  dataRef: Date = new Date();
  titlePrincipal: string;
  msgs: Message[];

  opcoesRelatoriosExternos: any[];
  relExterno: number;

  existeRelatorioExterno: boolean = false;

  exibirDtIni: boolean = false;
  exibirDtFin: boolean = false;
  exibirFilial: boolean = false;
  filialRelatorio: string = '';
  statusRelExt: boolean = false;
  dtIni: Date;
  dtFin: Date;
  tokenProgressoSocket: string = null;
  private messagesSubscription: Subscription
  isLoadingInProgress: boolean = false
  loadingProgress: number = 0

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private titleService: TitleService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private socketService: PysocketService
  ) { }

  ngOnInit(): void {
    this.toolbarService.clear();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titleService.title = 'Relatórios Gerenciais';
    this.titlePrincipal = 'RELATÓRIOS';
    this.relatorio = {};

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.getConfigRelatorios();

          this.httpService.get('/custom/ecd-report/possui-relat-externo(' +
            this.dblinkedSessionService.empresa.id + ')')
            .subscribe(ref => this.relExt(ref),
              error => this.httpService.handleError(error, () => this.ngOnInit()),
              () => this.httpService.done());

        }
      });

    this.messagesSubscription = this.socketService.socketData.subscribe((response) => {
      if (response.service === Service.NOTIFICATION && response.action === Action.LOADING && response.method === Method.GET) {
        this.loadingManagement(response)
      }
    })
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  gerarRelatorioGerencialExcel() {
    this.loadingProgress = 0
    this.modalRelatorio = false
    this.isLoadingInProgress = true
    this.tokenProgressoSocket = idv4()

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    let config_list = [];
    if (this.relatorio.is_composta) {
      for (let conf of this.dicRelatComposto[this.relatorio.id]) {
        if (this.datasIndividuais && conf['dataRef'] == null) {
          this.exibirMensagem({ error: true, mensagem: "Informe uma data para todos os relatórios ou desmarque a opção de datas individuais." });
          return;
        }

        config_list.push({
          'empresa_id': this.dblinkedSessionService.empresa.id,
          'dt_ref': this.datasIndividuais ? [conf['dataRef'].getDate(), conf['dataRef'].getMonth() + 1, conf['dataRef'].getFullYear()] : [this.dataRef.getDate(), this.dataRef.getMonth() + 1, this.dataRef.getFullYear()],
          'config_id': conf['config_relat_ref_id'],
          'worksheet_title': conf['worksheet_title']
        });
      }
    } else {
      config_list = [{
        'empresa_id': this.dblinkedSessionService.empresa.id,
        'dt_ref': [this.dataRef.getDate(), this.dataRef.getMonth() + 1, this.dataRef.getFullYear()],
        'config_id': this.relatorio.id
      }];
    }

    const infos = {
      list_infos: config_list,
      is_composta: this.relatorio.is_composta,
      user_id: this.sessionService.loggedUser.id,
      empresaConectadaId: this.dblinkedSessionService.empresa.id,
      tokenProgressoSocket: this.tokenProgressoSocket
    }

    this.httpClient.post(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', infos, httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();

          } else {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0',
                'userid': this.sessionService.loggedUser.id.toString()
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', httpOptions2)
              .subscribe(
                (res: Blob) => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);
                  let fileName = "Relatório gerencial.xlsx";
                  link.href = url;
                  link.download = fileName;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                err => {
                  if (err["error"] === null || err["error"] === undefined) {
                    this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "Um erro inesperado foi detectado no API. Entre o contato com o suporte." });

                  } else {
                    this.exibirMensagem(err);
                    console.log(err);
                    this.httpService.done();
                  }
                }
              );
          }
        },
        err => {
          if (err["error"] === null || err["error"] === undefined) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "Um erro inesperado foi detectado no API. Entre o contato com o suporte." });

          } else {
            this.exibirMensagem(err)
            console.log(err);
            this.httpService.done();
          }
        },
        async () => {
          await new Promise(f => setTimeout(f, 1500));
          this.isLoadingInProgress = false
        }
      );
  }

  renderizarRelatorioGerencial() {
    var data_ref: string = this.dataRef.getDate() + '-' + (this.dataRef.getMonth() + 1).toString() + '-' + this.dataRef.getFullYear();
    window.open('/workstats/render-relatorios-gerenciais/' + this.dblinkedSessionService.empresa.id.toString() + '/' + this.relatorio.id + '/' + this.relatorio.plano_gerencial_id + '/' + data_ref, '_blank');
  }

  getConfigRelatorios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            this.listaRelat = new Array();
            for (var line of res["mensagem"]) {
              this.listaRelat.push(line);
            }

            this.dicRelatComposto = res["dicconfigs_compostas"];
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível recuperar as configurações do relatório." });
          console.log(err);
          this.httpService.done();
        }
      );
  }

  carregarValores(config) {
    let replicar = false;

    for (let dicConfig of this.dicRelatComposto[this.relatorio.id]) {
      if (config['id'] === dicConfig['id']) replicar = true;

      if (replicar) {
        dicConfig['dataRef'] = config['dataRef'];
      }
    }
  }

  showDialogRelatorio(relat) {
    this.relatorio = relat;
    this.datasIndividuais = false;
    this.modalRelatorio = true;
    this.dataRef = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1);

  }

  exibirMensagem(value: any) {
    this.msgs = [];
    if (!value.error) {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  hideMessage() {
    this.msgs = [];
  }

  relExt(value: any) {
    this.opcoesRelatoriosExternos = [];
    this.opcoesRelatoriosExternos.push({ label: 'Selecione', value: null });

    this.existeRelatorioExterno = value.length > 0 ? true : false;

    value.forEach(element => {
      this.opcoesRelatoriosExternos.push({ label: element.nome, value: element.id, param: element.parametro });
    });

  }


  gerarRelatorioExterno() {
    const listasEnviar = {
      cnpj: this.dblinkedSessionService.empresa.cnpj,
      dtIni: this.dtIni,
      dtFin: this.dtFin,
      relExterno: this.relExterno,
      filial: this.filialRelatorio
    };
    this.httpService.wait();
    this.httpService.post('/custom/ecd-report/gerar-relatorio-externo', listasEnviar)
      .subscribe(result => this.downloadRelExt(result),
        error => this.httpService.handleError(error, () => this.gerarRelatorioExterno()),
        () => this.httpService.done());
  }

  windowOpen(url: string, target?: string) {
    window.open(url, target);
  }

  downloadRelExt(result: any) {


    this.windowOpen(this.httpService.url('/custom/file/' + result.file), '_blank');

  }

  relatorioExterno(event: any) {


    this.exibirDtIni = false;
    this.exibirDtFin = false;
    this.exibirFilial = false;
    this.filialRelatorio = '';

    this.opcoesRelatoriosExternos.forEach(element => {


      if (!isNullOrUndefined(element.value)) {



        if (element.value == event.value) {

          if (element.param.toString().match('dtIni')) {

            this.exibirDtIni = true;
          }
          if (element.param.toString().match('dtFin')) {

            this.exibirDtFin = true;
          }
          if (element.param.toString().match('filial')) {

            this.exibirFilial = true;
          }
        }
      }


    });


  }

  showDialogRelatorioExt(tipo) {

    if (tipo === 'EXT') {
      this.statusRelExt = true;


    }
  }

  loadingManagement(data: any) {
    if (data.content.tokenProgressoSocket == this.tokenProgressoSocket) {
      this.loadingProgress = Math.round(data.content.progress)
    }
  }

}
