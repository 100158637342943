import { AuthUser } from 'app/proof/model/auth-user.model';
import { TodoProjeto } from './todo-projeto.model';

export class TodoPainel {
  id: number;
  titulo: string;
  corBg: string;
  posicao: number;
  todoProjeto: TodoProjeto;
  usuario: AuthUser;
  painelFinal: boolean;
  arquivado: boolean;
  necessitaAprovacao: boolean;
  usuarioAprovador: AuthUser;

  constructor(todoPainel?: TodoPainel | number) {
    if (todoPainel !== undefined && todoPainel !== null) {
      if (typeof todoPainel === 'number') {
        this.id = todoPainel;
      } else {
        this.id = todoPainel.id;
        this.titulo = todoPainel.titulo;
        this.corBg = todoPainel.corBg;
        this.posicao = todoPainel.posicao;
        this.todoProjeto = todoPainel.todoProjeto;
        this.usuario = todoPainel.usuario;
        this.painelFinal = todoPainel.painelFinal;
        this.arquivado = todoPainel.arquivado;
        this.necessitaAprovacao = todoPainel.necessitaAprovacao;
        this.usuarioAprovador = todoPainel.usuarioAprovador;
      }
    }
  }
}
