import { Empresa } from "app/cadastro/model/empresa.model";

export class Categoria {
  id: number;
  nome: string;
  empresa: Empresa;

  constructor(categoria?: Categoria | number) {
    if (categoria !== undefined && categoria !== null) {
      if (typeof categoria === 'number') {
        this.id = categoria;
      } else {
        this.id = categoria.id;
        this.nome = categoria.nome;
        this.empresa = categoria.empresa;
      }
    }
  }
}
