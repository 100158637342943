import { Empresa } from "app/cadastro/model/empresa.model";
import { Area } from "./area-model";

export class Subarea {
  id: string;
  empresa: Empresa;
  area: Area;
  descricao: string;
  codigo: string;
  deletado: boolean;
  condicao: string;

  constructor(Subarea: Subarea) {
    this.id = Subarea.id;
    this.empresa = Subarea.empresa;
    this.area = Subarea.area;
    this.descricao = Subarea.descricao;
    this.codigo = Subarea.codigo;
    this.deletado = Subarea.deletado;
    this.condicao = Subarea.condicao;
  }
}



