import { ViewChild, ElementRef } from '@angular/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpService } from 'app/shared/services/http.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs/Subscription';
import { Versao } from 'app/orcamento/model/versao.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'app/shared/services/config.service';
import { UtilService } from 'app/shared/services/util.service';
import { Message, MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-importar-arquivos-orcamento',
  templateUrl: './importar-arquivos-orcamento.component.html',
  styleUrls: ['./importar-arquivos-orcamento.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})


export class OrcamentoImportArquivoComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef;

  private sessionSubscription: Subscription;
  file: File;
  replace: boolean;

  titlePrincipal: string;
  subtitle: string;

  msgs: Message[] = null;
  idTipoArquivo: number = 0;

  listTipoArquivo: Array<any> = [
    { label: "Trbr - Break down", value: 1 },
    { label: "Trbr - Entrada", value: 2 },
    { label: "Trbr - Pedido", value: 3 },
    { label: "Trbr - Razão Provisão por Centro de custo", value: 4 },
    { label: "Trbr - Balancete", value: 5 },
    { label: "Trbr - Despesas por CC (Real) ", value: 6 }
  ];

  listTipoArquivoFini: Array<any> = [
    { label: "TEMPLATE RECEITA", value: 'RECEITA' },
    { label: "TEMPLATE DESPESAS", value: 'DESPESA' },
    { label: "TEMPLATE VENDA", value: 'VENDA' }
  ];



  listImportar: Array<any> = new Array();

  listVersoesAberta: Array<any> = new Array();
  listVersoesFini: Array<any> = new Array();
  public boolContabil: boolean = false;
  public boolGerencial: boolean = false;
  public boolProjeto: boolean = false;
  public boolCentroCusto: boolean = false;
  public boolTemOrcamento: boolean = false;
  public boolSubsPedido: boolean = false;


  inicioPesquisa: Number;
  finalPesquisa: Number;
  idAnoSelecionado: Number = 0;
  idVersaoSelecionada: number = 0;
  idTipoArquivoSelecionado: string = '';
  versao: Versao;
  empresaId: number = null;
  opcoesArea: SelectItem[] = [];
  areaSelecionadas: Array<string> = null;
  filename: string = null;


  constructor(private titleService: TitleService,
    private httpService: HttpService,
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private utilservice: UtilService,
    private sessionService: SessionService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.titlePrincipal = 'IMPORTAR ARQUIVOS';
    this.subtitle = 'IMPORTAR ARQUIVOS DO ORÇAMENTO';

    this.titleService.title = 'Importar arquivo de orçamento';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.versao = new Versao();
    this.boolTemOrcamento = false;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();

      this.getArea();

    });

  }

  //Show/hide cards
  isCardImport: boolean = true;

  currentStateImport = 'initial';


  ShowHIdeCardImport() {
    this.isCardImport = !this.isCardImport;
  }

  changeStateImport() {
    this.currentStateImport = this.currentStateImport === 'initial' ? 'final' : 'initial';
  }

  getVersaoOrca() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        empresaid: this.dblinkedSessionService.empresa.id.toString(),
        ano: this.dblinkedSessionService.periodo.year.value.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/versao', httpOptions)
      .subscribe(
        res => {
          this.listVersoesFini = [];
          this.listVersoesAberta = [];
          var result = res["data"];
          for (let line of result) {
            this.listVersoesFini.push({ "label": line["descricao"], "value": line["id"] });
            if (line["status_orcamento_id"] === 1) {
              this.listVersoesAberta.push({ label: line["descricao"], value: line["id"] });
            }
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem({error: true, mensagem: "Erro ao obter as versões. Contate o suporte: " + err.message})
              console.log(err);
              this.httpService.done();
        }
      );
  }

  getArea() {
    if (this.dblinkedSessionService.empresa.id === 1 ||
      this.dblinkedSessionService.empresa.id === 115 ||

      this.dblinkedSessionService.empresa.id === 133) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'authuserid': this.sessionService.loggedUser.id.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/area-desc', httpOptions)
        .subscribe(
          res => {
            this.opcoesArea = [];
            var result = res["data"];
            for (let line of result) {
              this.opcoesArea.push({ "label": line["desc"], "value": line["desc"] });
            }
            this.httpService.done()
          },
            err => {
              this.exibirMensagem({error: true, mensagem: "Erro ao obter as áreas. Contate o suporte: " + err.message})
              console.log(err);
              this.httpService.done();
            }
          );
    }
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

      this.empresaId = this.dblinkedSessionService.empresa.id;
      this.listVersoesAberta = new Array();
      this.idVersaoSelecionada = 0;
      this.idAnoSelecionado = 0;
      this.boolCentroCusto = false;
      this.boolContabil = false;
      this.boolGerencial = false;
      this.boolProjeto = false;
      this.getVersaoOrca();
    }

  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
    this.filename = this.file.name;

  }

  importar() {

    if (this.dblinkedSessionService.empresa.id != 1 && this.dblinkedSessionService.empresa.id != 115 && this.dblinkedSessionService.empresa.id != 133) {

      if (this.validacao()) {
        return;
      }

      if (this.boolTemOrcamento) {
        if (!confirm("Deseja substituir a versão do Orçamento / Forecast  ?")) {
          return;
        }
      }

      const data: FormData = new FormData();
      data.append('file', this.file);
      data.append('type', 'orcamento');
      data.append('month', this.dblinkedSessionService.periodo.month.value.toString());
      data.append('year', this.dblinkedSessionService.periodo.year.value.toString());
      data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
      data.append('usuarioId', this.sessionService.loggedUser.id.toString());
      data.append('tipoAnexo', this.file.type);
      data.append('tipoArquivo', this.idTipoArquivo.toString());
      if (!isNullOrUndefined(this.idVersaoSelecionada)) {
        data.append('orcamentoVersaoId', this.idVersaoSelecionada.toString());
      }
      data.append('boolSubsPedido', this.boolSubsPedido.toString());

      this.httpService.wait();
      this.httpService.post('/custom/upload', data)
        .subscribe(
          (res) => {
            if (res["error"]){
              this.messageService.add({severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: res["result"] })
            }
            else {
              this.messageService.add({
                severity: 'success', key: 'messageLinnks',
                summary: 'Sucesso',
                detail: res["result"]
              });
            }
          },
          error => this.httpService.handleError(error, () => this.importar()),
            () => this.httpService.done()
        )
    }

    else {

      if (this.idTipoArquivoSelecionado === 'RECEITA') {

        const data: FormData = new FormData();
        data.append('file', this.file);
        data.append('type', 'orcamento-upload-receita');
        data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
        data.append('usuarioId', this.sessionService.loggedUser.id.toString());

        this.httpService.wait();
        this.httpService.post('/custom/upload', data)
          .subscribe(result => this.uploaded(result),
            error => this.httpService.handleError(error, () => this.importar()),
            () => this.httpService.done());

      } else if (this.idTipoArquivoSelecionado === 'VENDA') {

        const data: FormData = new FormData();
        data.append('file', this.file);
        data.append('type', 'objetivo-vendas');
        data.append('empresaId', this.dblinkedSessionService.empresa.id.toString());
        data.append('usuarioId', this.sessionService.loggedUser.id.toString());
        if (!isNullOrUndefined(this.idVersaoSelecionada)) {
          data.append('orcamentoVersaoId', this.idVersaoSelecionada.toString());
        }else{
          data.append('orcamentoVersaoId', '0');
        }

        this.httpService.wait();
        this.httpService.post('/custom/upload', data)
          .subscribe(result => this.uploaded(result),
            error => this.httpService.handleError(error, () => this.importar()),
            () => this.httpService.done());

      } else {
        if (this.idVersaoSelecionada === null || this.idVersaoSelecionada < 1) {
          this.msgs = [{
            severity: 'warn',
            summary: 'MSG: ',
            detail: 'Favor selecionar uma versão!'
          }];
        } else if (this.areaSelecionadas.length === 0) {
          this.msgs = [{
            severity: 'warn',
            summary: 'MSG: ',
            detail: 'Favor selecionar pelo menos uma área'
          }];

        } else {
          if (!this.utilservice.validArquivo(this.filename)) {
            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'versaoid': this.idVersaoSelecionada.toString(),
                'area': this.areaSelecionadas.toString()
              })
            };

            this.httpService.wait();
            this.httpClient.get(this.configService.defaultUrlApiUpload + 'orcamento/confirmversao', httpOptions)
              .subscribe(
                res => {
                  this.httpService.done();
                  if (res["mensagem"]) {
                    if (confirm("Deseja substituir a versão do Orçamento / Forecast  ?")) {
                      this.importFini();
                    }
                  } else {
                    this.importFini();
                  }

                },
                err => {
                  console.log(err);
                  this.httpService.done();
                }
              );
          }

          else {
            this.msgs = [];
            this.msgs.push({ severity: 'warn', summary: 'Aviso:', detail: 'O nome do arquivo não pode conter carateres acentuados e/ou especiais.' });
          }
        }
      }

    }
  }

  importFini() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'userid': this.sessionService.loggedUser.id.toString(),
        'area': this.areaSelecionadas.toString(),
        'versaoid': this.idVersaoSelecionada.toString()
      })
    };

    const formData = new FormData();
    formData.append('file', this.file);

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'orcamento/import', formData, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.areaSelecionadas = null;

        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  validacao() {
    if ((this.idTipoArquivo === 0 || isNullOrUndefined(this.idTipoArquivo)) && this.dblinkedSessionService.empresa.id === 277) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar o tipo de arquivo!'
      });
      return true;
    }
    if (!this.file || isNullOrUndefined(this.file)) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um arquivo!'
      });
      return true;
    }

    switch (this.idTipoArquivo) {
      case 1:
        if (!this.verifaExtensaoArquivoExcel(this.file.name.toString())) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O arquivo precisa ser no formato xlsx ou xls !'
          });
          return true;
        }

        if (isNullOrUndefined(this.idVersaoSelecionada) || this.idVersaoSelecionada < 1) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Favor selecionar uma versão!'
          });
          return true;
        }

        break;

      case 2:

        if (!this.verifaExtensaoArquivoExcel(this.file.name.toString())) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O arquivo precisa ser no formato xlsx ou xls !'
          });
          return true;
        }


        break;

      case 3:

        if (!this.verifaExtensaoArquivoExcel(this.file.name.toString())) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O arquivo precisa ser no formato xlsx ou xls !'
          });
          return true;
        }



        break;

      case 4:

        if (!this.verifaExtensaoArquivoTxt(this.file.name.toString())) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O arquivo precisa ser no formato txt !'
          });
          return true;
        }


        break;

      case 5:

        if (!this.verifaExtensaoArquivoTxt(this.file.name.toString())) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O arquivo precisa ser no formato txt!'
          });
          return true;
        }

        break;

      case 6:

        if (!this.verifaExtensaoArquivoTxt(this.file.name.toString())) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O arquivo precisa ser no formato txt!'
          });
          return true;
        }



        if (isNullOrUndefined(this.idVersaoSelecionada) || this.idVersaoSelecionada < 1) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Favor selecionar uma versão!'
          });
          return true;
        }

        break;

      default:
        if (!this.verifaExtensaoArquivoCsv(this.file.name.toString())) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O arquivo precisa ser no formato csv!'
          });
          return true;
        }
    }
    return false;
  }

  verifaExtensaoArquivoExcel(arquivoNome: string): boolean {

    if (arquivoNome.substring(arquivoNome.lastIndexOf(".") + 1, arquivoNome.length).toLocaleLowerCase() === 'xls') {
      return true;
    }

    if (arquivoNome.substring(arquivoNome.lastIndexOf(".") + 1, arquivoNome.length).toLocaleLowerCase() === 'xlsx') {
      return true;
    }

    return false;

  }


  verifaExtensaoArquivoTxt(arquivoNome: string): boolean {

    if (arquivoNome.substring(arquivoNome.lastIndexOf(".") + 1, arquivoNome.length).toLocaleLowerCase() === 'txt') {
      return true;
    }

    return false;

  }

  private uploaded(res?: any) {

    this.msgs = [];
    console.log(res.result);

    if (res.result.error === true) {

      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Advertência', detail: res.result.message });
      this.file = null;
      this.ngOnInit();

    } else {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: res.result.message });
      this.file = null;
      this.ngOnInit();

    }

  }

  get importDisabled(): boolean {

    return !this.dblinkedSessionService.hasEmpresaSelected || !this.file;
  }

  hideMessage() {
    this.file = null;

  }

  verifaExtensaoArquivoCsv(arquivoNome: string): boolean {

    if (arquivoNome.substring(arquivoNome.lastIndexOf(".") + 1, arquivoNome.length).toLocaleLowerCase() === 'csv') {
      return true;
    }

    return false;

  }

  verificarSeTemAVersaoSelecionada(event: any) {

    let versaoId = !isNullOrUndefined(event.value) ? event.value : 0;

    this.httpService.wait();
    this.httpService.get('/custom/orcamento/verificar-orcamento-cadastrado(' + this.dblinkedSessionService.empresa.id + ',' + versaoId + ')')
      .subscribe(res => {
        if (res[0].temVersao) {
          this.boolTemOrcamento = true;
        } else {
          this.boolTemOrcamento = false;
        }
      },
        error => this.httpService.handleError(error, () => this.httpService.done()), () => this.httpService.done());




  }



}
