export class TipoArquivo {
    id: number;
    nome: string;
    descricao: string;

    constructor(tipoarquivo?: TipoArquivo | number) {
      if (tipoarquivo !== undefined && tipoarquivo !== null) {
        if (typeof tipoarquivo === 'number') {
          this.id = tipoarquivo;
        } else {
          this.id = tipoarquivo.id;
          this.nome = tipoarquivo.nome;
          this.descricao = tipoarquivo.descricao;
        }
      }
    }
  }
