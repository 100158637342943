import { Empresa } from '../../cadastro/model/empresa.model';
import { AuthUser } from '../../proof/model/auth-user.model';
import { TipoFeriado } from '../../util/model/tipo-feriado.model';


export class Feriados {
    id: number;
    data: Date;
    empresa: Empresa;
    authUserInclusao: AuthUser;
    descricao: string;
    tipoFeriado: TipoFeriado;

    constructor(feriados?: Feriados | number) {
      if (feriados !== undefined && feriados !== null) {
        if (typeof feriados === 'number') {
          this.id = feriados;
        } else {
          this.id = feriados.id;
          this.data = feriados.data;
          this.empresa = feriados.empresa;
          this.authUserInclusao = feriados.authUserInclusao;
          this.descricao = feriados.descricao;
          this.tipoFeriado = feriados.tipoFeriado;
        }
      }
    }
  }
