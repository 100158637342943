import { Injectable } from '@angular/core';

import * as _momentTimezone from 'moment-timezone';
import * as _moment from 'moment';
import { HttpService } from './http.service';
import { SelectItem } from 'primeng/api';
import { DblinkedSessionService } from './dblinked-session.service';
import { Pedido } from 'app/financeiro/model/pedido.model';
import { UtilService } from './util.service';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { element } from 'protractor';

interface LoadMethods {
  wait: Function,
  done: Function
}

@Injectable()
export class PedidoService {


  constructor(private httpService: HttpService,
    public dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService) {

  }

  getPedidoStatus(pedidoTipo: number, opcoesStatusPedido: SelectItem[], loadMethods: LoadMethods = null) {

    if (loadMethods)
      loadMethods.wait();
    else
      this.httpService.wait();

    this.httpService.get('/pedidostatus?$select=id,description&$orderby=id')
      .subscribe(rst => {
        rst.value.forEach(element => {
          if ((element.id === 1 || element.id === 4)
            && (pedidoTipo === 1 || pedidoTipo === 2)) {
            opcoesStatusPedido.push({ label: element.description, value: element.id });
          } else if ((element.id === 1 || element.id === 7)
            && (pedidoTipo === 3 || pedidoTipo === 4)) {
            opcoesStatusPedido.push({ label: element.description, value: element.id });
          } else if ((element.id === 12)
            && ((pedidoTipo === 2) || (pedidoTipo === 3) || (pedidoTipo === 1))) {
            opcoesStatusPedido.push({ label: element.description, value: element.id });
          }
        });
      },
        error => this.httpService.handleError(error, () => this.getPedidoStatus(pedidoTipo, opcoesStatusPedido)),
        () => {
          if (loadMethods)
            loadMethods.done();
          else
            this.httpService.done();
        });

  }

  getPedidoStatusAprovacao(pedidoTipo: number, opcoesStatusPedido: SelectItem[], loadMethods: LoadMethods = null) {
    if (loadMethods)
      loadMethods.wait();
    else
      this.httpService.wait();

    this.httpService.get('/pedidostatus?$select=id,description&$orderby=id')
      .subscribe(rst => {
        rst.value.forEach(element => {
          if ((element.id === 1 || element.id === 4)
            && (pedidoTipo === 1 || pedidoTipo === 2)) {
            opcoesStatusPedido.push({ label: element.description, value: element.id });
          } else if ((element.id === 1 || element.id === 7 || element.id === 3 || element.id === 2)
            && (pedidoTipo === 3 || pedidoTipo === 4)) {
            opcoesStatusPedido.push({ label: element.description, value: element.id });
          } else if ((element.id === 12)
            && ((pedidoTipo === 2) || (pedidoTipo === 3) || (pedidoTipo === 1))) {
            opcoesStatusPedido.push({ label: element.description, value: element.id });
          }
        });
      },
        error => this.httpService.handleError(error, () => this.getPedidoStatus(pedidoTipo, opcoesStatusPedido)),
        () => {
          if (loadMethods)
            loadMethods.done();
          else
            this.httpService.done();
        });

  }

  getModNfs(pedidoTipo: number, tipo: string, opcoesModelo: SelectItem[], loadMethods: LoadMethods = null) {

    if (loadMethods)
      loadMethods.wait();
    else
      this.httpService.wait();

    this.httpService.get('/modnf?$select=id,codigo,descricao&$orderby=codigo')
      .subscribe(rst => {
        opcoesModelo.push({ label: ('Nenhum'), value: null });
        rst.value.forEach(element => {
          if (tipo === 's') {
            if (element.codigo === 'NFS' || element.codigo === '100') {
              opcoesModelo.push({ label: (element.codigo + ' - ' + element.descricao), value: element.id });
            }
          } else {
            if (element.codigo !== 'NFS' && element.codigo !== '100') {
              opcoesModelo.push({ label: (element.codigo + ' - ' + element.descricao), value: element.id });
            }

          }

        });
      },
        error => this.httpService.handleError(error, () => this.getModNfs(pedidoTipo, tipo, opcoesModelo)),
        () => {
          if (loadMethods)
            loadMethods.done();
          else
            this.httpService.done();
        });

  }

  getMoeda(opcoesMoeda: SelectItem[], loadMethods: LoadMethods = null) {

    if (loadMethods)
      loadMethods.wait();
    else
      this.httpService.wait();

    this.httpService.get('/moeda?$select=id,codigo,descricao,simbolo,pais&$orderby=codigo')
      .subscribe(rst => {
        opcoesMoeda.push({ label: ('Nenhum'), value: null });
        rst.value.forEach(element => {
          opcoesMoeda.push({ label: element.codigo, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.getMoeda(opcoesMoeda)),
        () => {
          if (loadMethods)
            loadMethods.done();
          else
            this.httpService.done();
        });

  }

  getMoedaDesc(opcoesMoeda: SelectItem[]) {

    this.httpService.get('/moeda?$select=id,codigo,descricao,simbolo,pais&$orderby=codigo')
      .subscribe(rst => {
        opcoesMoeda.push({ label: ('Nenhum'), value: null });
        rst.value.forEach(element => {
          opcoesMoeda.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.getMoedaDesc(opcoesMoeda)),
        () => this.httpService.done());

  }

  getOperacao(pedidoTipo: number, tipo: string, opcoesOperacao: SelectItem[], pedidoMisto: boolean, loadMethods: LoadMethods = null) {

    /*
    PedidoTipo:
    1 - Venda de Produto
    2 - Venda de Serviço
    3 - Aquisição de Produto
    4 - Aquisição de Serviço

    Tipo:
    p - produto,
    s - serviço

    TipoEntradaSaida:
    1 - Entrada,
    2 - Saída

    TipoRegraContabil:
    1 - Produto,
    2 - Serviço
    */
    if (loadMethods)
      loadMethods.wait();
    else
      this.httpService.wait();

    opcoesOperacao.push({ label: ('Nenhum'), value: null });

    if (pedidoTipo === 1 || pedidoTipo === 2) {
      this.httpService.get('/operacaoregra?$select=id,cod,descricao,operacaoRegraContabil/id,operacaoRegraContabil/tipoRegraContabil/id,'
        + 'operacaoRegraFiscal/id&$filter=tipoEntradaSaida/id eq 2 and ativo eq true and empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + '&$orderby=cod')
        .subscribe(ref => {
          ref.value.forEach(element => {
            if (!isNullOrUndefined(element.operacaoRegraContabil)) {
              if (!isNullOrUndefined(element.operacaoRegraContabil.tipoRegraContabil)) {
                if ((tipo === 'p' || pedidoMisto) && element.operacaoRegraContabil.tipoRegraContabil.id === 1) {
                  opcoesOperacao.push({ label: element.cod + ' - ' + element.descricao, value: element.id });
                } else if ((tipo === 's' || pedidoMisto) && element.operacaoRegraContabil.tipoRegraContabil.id === 2) {
                  opcoesOperacao.push({ label: element.cod + ' - ' + element.descricao, value: element.id });
                }
              }
            }
          });
        },
          error => this.httpService.handleError(error, () => this.getOperacao(pedidoTipo, tipo, opcoesOperacao, pedidoMisto)),
          () => {
            if (loadMethods)
              loadMethods.done();
            else
              this.httpService.done();
          });
    } else {
      this.httpService.get('/operacaoregra?$select=id,cod,descricao,operacaoRegraContabil/id,operacaoRegraContabil/tipoRegraContabil/id,'
        + 'operacaoRegraFiscal/id&$filter=tipoEntradaSaida/id eq 1 and ativo eq true and empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + '&$orderby=cod')
        .subscribe(ref => {
          ref.value.forEach(element => {
            if (!isNullOrUndefined(element.operacaoRegraContabil)) {
              if (!isNullOrUndefined(element.operacaoRegraContabil.tipoRegraContabil)) {
                if ((tipo === 'p' || pedidoMisto) && element.operacaoRegraContabil.tipoRegraContabil.id === 1) {
                  opcoesOperacao.push({ label: element.cod + ' - ' + element.descricao, value: element.id });
                } else if ((tipo === 's' || pedidoMisto) && (element.operacaoRegraContabil.tipoRegraContabil.id === 2 || element.operacaoRegraContabil.tipoRegraContabil.id === 5)) {
                  opcoesOperacao.push({ label: element.cod + ' - ' + element.descricao, value: element.id });
                }
              }
            }
          });
        },
          error => this.httpService.handleError(error, () => this.getOperacao(pedidoTipo, tipo, opcoesOperacao, pedidoMisto)),
          () => {
            if (loadMethods)
              loadMethods.done();
            else
              this.httpService.done();
          });
    }
  }

  carregarItensCombo(pedidoTipo: number, tipo: string, opcoesItens: SelectItem[], operacaoRegraId: number, isServico: boolean) {



    opcoesItens = [];
    if (pedidoTipo === 2 || pedidoTipo === 1) {
      this.httpService.wait();
      this.httpService.get('/custom/pedido/buscar-item-venda('
        + this.dblinkedSessionService.empresa.id + ',' + pedidoTipo + ',' + operacaoRegraId + ')')
        .subscribe(ref => {
          ref.forEach(element => {

            if ((tipo === 'p' && !isServico) && element.itemTipo !== 10) {
              opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
            } else if ((tipo === 's' || isServico) && element.itemTipo === 10) {
              opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
            } else if ((tipo === 'p' && isServico) && element.itemTipo === 10) {
              opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
            } else {
              opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
            }
          });
        },
          error => this.httpService.handleError(error, () => this.carregarItensCombo(pedidoTipo, tipo, opcoesItens, operacaoRegraId, isServico)),
          () => this.httpService.done());
    } else {
      this.httpService.wait();
      this.httpService.get('/custom/pedido/buscar-item-compra('
        + this.dblinkedSessionService.empresa.id + ',' + pedidoTipo + ',' + operacaoRegraId + ')')
        .subscribe(ref => {
          ref.forEach(element => {
            if ((tipo === 'p' && !isServico) && element.itemTipo !== 10) {
              opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
            } else if ((tipo === 's' || isServico) && (element.itemTipo === 10 || element.itemTipo === 12)) {
              opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
            } else if ((tipo === 'p' && isServico) && (element.itemTipo === 10 || element.itemTipo === 12)) {
              opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
            }
          });
        },
          error => this.httpService.handleError(error, () => this.carregarItensCombo(pedidoTipo, tipo, opcoesItens, operacaoRegraId, isServico)),
          () => this.httpService.done());

    }
  }

  carregarContas(opcoesContas: SelectItem[], loadMethods: LoadMethods = null) {
    if (loadMethods)
      loadMethods.wait();
    else
      this.httpService.wait();

    this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,tipoConta/id&$'
      + 'filter=empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) +
      ' and ativo=true&$orderby=nome')
      .subscribe(ref => {
        opcoesContas.push({ label: 'Nenhum', value: null });
        ref.value.forEach(element => {
          if (element.tipoConta.id !== 10) {
            opcoesContas.push({ label: element.nome, value: element.id });
          }
        });
      },
        error => this.httpService.handleError(error, () => this.carregarContas(opcoesContas, loadMethods)),
        () => {
          if (loadMethods)
            loadMethods.done();
          else
            this.httpService.done();
        });
  }


  carregarFormaPagto(opcoesFormaPagamento: SelectItem[], tipo: number, loadMethods: LoadMethods = null) {
    if (loadMethods)
      loadMethods.wait();
    else
      this.httpService.wait();

    this.httpService.get('/formapagamento?$select=id,descricao'
      + '&$filter=empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ' and ativo=true and ' + (tipo == 0 ? 'isPagamento' : 'isRecebimento') + '=true&$orderby=id')
      .subscribe(ref => {
        opcoesFormaPagamento.push({ label: 'Nenhum', value: null });
        ref.value.forEach(forma => {
          opcoesFormaPagamento.push({ label: forma.descricao, value: forma.id });
        });
      },
        error => this.httpService.handleError(error, () => this.carregarFormaPagto(opcoesFormaPagamento, tipo)),
        () => {
          if (loadMethods)
            loadMethods.done();
          else
            this.httpService.done();
        });
  }

  carregarIncoterm(opcoesIncoterm: SelectItem[], loadMethods: LoadMethods = null) {
    if (loadMethods)
      loadMethods.wait();
    else
      this.httpService.wait();

    this.httpService.get('/tipoincoterm?$select=id,codigo&$orderby=codigo')
      .subscribe(inco => {
        opcoesIncoterm.push({ label: 'Nenhum', value: null });
        inco.value.forEach(element => {
          opcoesIncoterm.push({ label: element.codigo, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.carregarIncoterm(opcoesIncoterm)),
        () => {
          if (loadMethods)
            loadMethods.done();
          else
            this.httpService.done();
        });
  }

  carregarTiposBaixa(opcoesTipoBaixa: SelectItem[], loadMethods: LoadMethods = null) {

    opcoesTipoBaixa.push({ label: 'Selecione', value: null });

    if (loadMethods)
      loadMethods.wait();
    else
      this.httpService.wait();

    this.httpService.get('/tipobaixa?$select=id,descricao&$orderby=descricao')
      .subscribe(ref => {
        ref.value.forEach(element => {
          opcoesTipoBaixa.push({ label: element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.carregarTiposBaixa(opcoesTipoBaixa)),
        () => {
          if (loadMethods)
            loadMethods.done();
          else
            this.httpService.done();
        });
  }

  carregarCentroCusto(opcoesCentroCusto: SelectItem[], loadMethods: LoadMethods = null) {

    if (loadMethods)
      loadMethods.wait();
    else
      this.httpService.wait();

    this.httpService.get('/cadccus?$select=id,codCcus,descrCcus,projetosRelacionados,ativo' +
      '&$filter=ativo eq true and empresa/id eq ' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) +
      ' and period(dtInicial,dtFinal) eq ' + this.dblinkedSessionService.periodo.year.value + '&$orderby=codCcus')
      .subscribe(cc => {
        opcoesCentroCusto.push({ label: 'Nenhum', value: null });
        cc.value.forEach(element => {
          if (element.ativo) {
            element.label = element.codCcus + ' - ' + element.descrCcus;
            opcoesCentroCusto.push(element);
          }
        });
      },
        error => this.httpService.handleError(error, () => this.carregarCentroCusto(opcoesCentroCusto)),
        () => {
          if (loadMethods)
            loadMethods.done();
          else
            this.httpService.done();
        });
  }


  carregarProjetos(opcoesProjeto: SelectItem[], centroCustoId, loadMethods: LoadMethods = null) {

    if (loadMethods)
      loadMethods.wait();
    else
      this.httpService.wait();

    this.httpService.get('/custom/financeiro/get-projetos(' + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId) + ','
      + centroCustoId + ')')
      .subscribe(proj => {

        opcoesProjeto.push({ label: 'Nenhum', value: null });

        proj.forEach(element => {
          element.label = element.codProj + ' - ' + element.descrProj;
          opcoesProjeto.push(element);
        });

      }, error => this.httpService.handleError(error, () => this.carregarProjetos(opcoesProjeto, centroCustoId)),
        () => {
          if (loadMethods)
            loadMethods.done();
          else
            this.httpService.done();
        });

  }




  buscarPreviaContabil(pedido: Pedido, visualizacaoContabil: Array<any>) {
    const listasEnviar = {
      pedido: JSON.stringify(pedido, null, ''),
      empresa: this.dblinkedSessionService.empresa.id,
    };
    this.httpService.post('/custom/pedido/buscar-contabilidade-pedido', listasEnviar)
      .subscribe(res => {

        res.forEach(e => {
          visualizacaoContabil.push(e);

        });
      },
        error => this.httpService.handleError(error, () => this.buscarPreviaContabil(pedido, visualizacaoContabil)),
        () => this.httpService.done());

  }

  buscarPreviaFiscal(pedido: Pedido, visualizacaoFiscal: Array<any>) {

    pedido.valorInss = 0.00;
    pedido.valorIr = 0.00;
    pedido.valorCsll = 0.00;
    pedido.valorPis = 0.00;
    pedido.valorCofins = 0.00;
    pedido.valorIssRetido = 0.00;
    pedido.valorIss = 0.00;
    pedido.valorOutrasRetencoes = 0.00;
    pedido.valorPisFaturamento = 0.00;
    pedido.valorCofinsFaturamento = 0.00;

    const listasEnviar = {
      pedido: JSON.stringify(pedido, null, ''),
      empresa: this.dblinkedSessionService.empresa.id,
    };

    this.httpService.post('/custom/pedido/buscar-impostos-pedido', listasEnviar)
      .subscribe(res => {

        console.log('PreviaFiscal: ' + JSON.stringify(res));
        visualizacaoFiscal = new Array()

        res.value.forEach(element => {
          pedido.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedido.valorInss) + this.utilService.parseDecimal(element.valorInss));
          pedido.valorIr = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedido.valorIr) + this.utilService.parseDecimal(element.valorIrrf));
          pedido.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedido.valorCsll) + this.utilService.parseDecimal(element.valorCsll));
          pedido.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedido.valorPis) + this.utilService.parseDecimal(element.valorPisRet));
          pedido.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedido.valorCofins) + this.utilService.parseDecimal(element.valorCofinsRet));
          pedido.valorPisFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedido.valorPisFaturamento) + this.utilService.parseDecimal(element.valorPis));
          pedido.valorCofinsFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedido.valorCofinsFaturamento) + this.utilService.parseDecimal(element.valorCofins));
          pedido.valorIssRetido = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedido.valorIssRetido) + this.utilService.parseDecimal(element.valorIssRet));
          pedido.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedido.valorIss) + this.utilService.parseDecimal(element.valorIss));
          pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(this.utilService.parseDecimal(pedido.valorOutrasRetencoes) + this.utilService.parseDecimal(element.valorOutrasRetencoes));

          visualizacaoFiscal.push(element);
        });


      }, error => this.httpService.handleError(error, () => this.buscarPreviaFiscal(pedido, visualizacaoFiscal)),
        () => this.httpService.done());

  }




}
