import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TipoFatura } from 'app/util/model/tipo-fatura.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { Uf } from 'app/util/model/uf.model';
import { Eventos } from '../model/eventos.model';
import { Item } from 'app/cadastro/model/item.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { EventosIngressos } from '../model/eventos-ingressos.model';
import { StatusEvento } from 'app/util/model/status-eventos.model';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { EventosFicha } from '../model/eventos.ficha';
import { Util } from 'app/shared/common/util';
import { Seminarista } from '../model/seminarista.model';
import { EventoEmpresaContaRelac } from '../model/evento-empresa-conta-relac.model';
import { FormaPagamento } from 'app/util/model/forma-pagamento.model';
import { EventoFormaPgtoRelac } from '../model/evento-forma-pgto-relac.model';
import { TipoBaixa } from 'app/util/model/tipo-baixa.model';
import { isNullOrUndefined } from 'util';
import { EventosMeioPagamento } from '../model/eventos-meio-pagamento.model';
import { Message, MessageService, SelectItem } from 'primeng/api';


@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss'],
  providers: [CrudService]
})
export class EventosComponent implements OnInit, OnDestroy {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;

  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;

  eventos: Array<any> = new Array();
  modalEventos: boolean;
  municipios: Array<MunicipioIbge> = new Array();
  ufs: Array<Uf> = new Array();
  dtVencimento: Date;
  dtFechamento: Date;
  eventosObj = new Eventos();
  eventosIngressosObj = new EventosIngressos();
  municipioIbge = new MunicipioIbge();
  itens: Array<Item> = new Array();
  opcoesItens: SelectItem[];
  itensSelecionada: number;

  opcoesUF: SelectItem[];
  ufSelecionada: number;

  opcoesMunicipio: SelectItem[];
  municipioSelecionado: number;

  modalIngressos = false;
  opcoesTipoIngresso: SelectItem[];

  tpingresso: SelectItem[];
  tpingressoValue: number;
  checkedAll = false;
  idEventoView: Array<any> = new Array();
  idEventoExcluirView: Array<any> = new Array();
  editarEvento: boolean;
  eventoId: number;
  modalParticipantePorEvento = false;
  modalWhatsInfo: boolean;
  msgWhatsInfo: string;

  statusEvento: SelectItem[];
  desabilitaStatus: boolean;

  editarEventoIngresso: Boolean;
  opcoesDepositoBancario: SelectItem[];
  nomeCurso: String;
  lstparticipantePorEventos: Array<any> = new Array();
  idParticipnantePorEventosExcluir: Array<any> = new Array();

  modalEditarParticipante: Boolean;
  eventosFicha = new EventosFicha();
  opcoesEventos: SelectItem[];
  opcoesIngressos: SelectItem[];
  valorPacote: any;
  valorPromocional: any;

  opcoesTipoPagamento: SelectItem[];

  isCreditoAVista: boolean;
  isCreditoParcelado: boolean;
  isCartaoDebito: boolean;
  isDinheiro: boolean;
  isCheque: boolean;
  isDeposito: boolean;
  opcoesPalestrante: SelectItem[];
  formasPgto: SelectItem[];
  contasBancarias: SelectItem[];
  formasPgtoSelec: Array<number> = [];
  contasBancariasSelec: Array<number> = [];
  modalSelecionarData : boolean = false;
  msgsModal     : Message[] = [];
  dataIni    : Date ;
  dataFin    : Date ;
  desabilitarTempoAcessoClubeVidaPlena : Boolean = true;
  opcoesUf : SelectItem[];
  opcoesUfSelecionados : SelectItem[];
  opcoesStatusCombo : SelectItem[];
  opcoesStatusSelecionados : SelectItem[];
  eventosMeioPagamento = new EventosMeioPagamento();
  opcoesMeioPagametno = new Array();
  modalContrato : boolean = false;
  opcoesComboMeioPagamento  = new Array();
  event : any;
  colsEventos: any[];
  colslstparticipantePorEventos : any[] = [];

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'EVENTOS';
    this.subtitle = 'Todos os eventos';
    this.titleService.title = 'EVENTOS';
    this.modalContrato = false;

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês

    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();


    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();

    this.eventos = new Array<any>();
    this.eventosIngressosObj = new EventosIngressos();
    this.modalEventos = false;
    this.checkedAll = false;
    this.idEventoView = new Array();
    this.idEventoExcluirView = new Array();
    this.editarEvento = false;
    this.eventoId = 0;
    this.modalParticipantePorEvento = false;
    this.editarEventoIngresso = false;

    this.nomeCurso = '';
    this.lstparticipantePorEventos = new Array<any>();
    this.idParticipnantePorEventosExcluir = new Array<any>();

    this.modalEditarParticipante = false;

    this.valorPacote = 0;
    this.valorPromocional = 0;
    this.msgWhatsInfo = '';


    this.statusEventoChanged();

    this.colsEventos = [
      { field: 'saleId', header: 'SALE ID' },
      { field: 'clientName', header: 'NOME' },
      { field: 'clientEmail', header: 'EMAIL' },
      { field: 'clientDocument', header: 'DOCUMENTO' },
      { field: 'clientCel', header: 'FONE' },
      { field: 'salePaymentMethod', header: 'MET. PAGAMENT.' },
      { field: 'saleTotal', header: 'VALOR' },
      { field: 'contentId', header: 'COD EDUZZ' },
      { field: 'contentTitle', header: 'EVT. NOME' },
      { field: 'dataCriacaoEduzz', header: 'DT CRIA EDUZZ' },
      { field: 'dataPagamenoEduzz', header: 'DT PAGAMENTO' },
      { field: 'saleStatusName', header: 'STATUS' }
  ];

  this.colslstparticipantePorEventos = [
    { field: 'nome', header: 'Nome' },
    { field: 'codigo', header: 'Ingresso' },
    { field: 'tipoPagto', header: 'Tipo Pagto' },
    { field: 'valor', header: 'Valor' },
  ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        const ano = this.dblinkedSessionService.periodo.year.value;
        const lastDay = new Date(ano, this.dblinkedSessionService.periodo.month.value + 0, 0); // ultimo dia do mês
        // formatando meses com dois digitos
        let mes = this.dblinkedSessionService.periodo.month.value > 8 ?
          (+this.dblinkedSessionService.periodo.month.value  ) :'0'  + (+this.dblinkedSessionService.periodo.month.value );

        this.dataIniSearch = ano + '-' + mes + '-01';
        this.dataFinSearch = ano + '-' + mes + '-' + lastDay.getDate();

        this.listar();
      });

  }



  carregaFormasPgto() {
    this.formasPgto = [];

    this.httpService.wait();
    this.httpService.get('/formapagamento?$select=id,descricao,tipoBaixa/id,empresaConta/id&$'
      + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' &$orderby=descricao')
      .subscribe(ref => {
        ref.value.forEach(element => {
          if(this.entraTipoBaixa(element.tipoBaixa, element.empresaConta)){
            this.formasPgto.push({ label: element.descricao, value: element.id });
          }
        });
      },
        error => this.httpService.handleError(error, () => this.carregaFormasPgto()),
        () => this.httpService.done());
  }

  carregaContaBancarias() {
    this.contasBancarias = [];

    this.httpService.wait();
    this.httpService.get('/empresaconta?$select=id,nome&$'
      + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' &$orderby=nome')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.contasBancarias.push({ label: element.nome, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.carregaContaBancarias()),
        () => this.httpService.done());
  }

  carregaPalestrante() {
    this.opcoesPalestrante = [];

    this.httpService.wait();
    this.httpService.get('/seminarista?$select=id,nome&$'
      + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' &$orderby=nome')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesPalestrante.push({ label: element.nome, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.carregaPalestrante()),
        () => this.httpService.done());
  }


  validaTipoPagamento(tipo: any) {
    this.isCreditoAVista = false;
    this.isCreditoParcelado = false;
    this.isCartaoDebito = false;
    this.isDinheiro = false;
    this.isCheque = false;
    this.isDeposito = false;
    this.eventosFicha.parceladoem = '';
    this.eventosFicha.numeroCartao = '';
    this.eventosFicha.ccv = '';

    if (tipo === 1) {
      this.isCreditoAVista = true;
      this.eventosFicha.dtValidade = new Date();
    } else if (tipo === 2) {
      this.isCreditoParcelado = true;
      this.eventosFicha.dtValidade = new Date();
    } else if (tipo === 3) {
      this.isCartaoDebito = true;
    } else if (tipo === 4) {
      this.isDinheiro = true;
    } else if (tipo === 5) {
      this.isCheque = true;
    } else {
      this.isDeposito = true;
    }

  }


  hideMessage() {


  }

  /*carregaEmpresaConta() {
    this.opcoesDepositoBancario = [];
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.httpService.wait();
      this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,tipoConta/descricao&$'
        + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
        ' and tipoConta/id=1&$orderby=nome')
        .subscribe(ref => {
          ref.value.forEach(element => {
            this.opcoesDepositoBancario.push({
              label: element.nome + ' : ' + ' Ag : ' +
                element.agencia + ' CC : ' + element.conta + '-' + element.dvConta
              , value: element.id
            });
          });
        },
          error => this.httpService.handleError(error, () => this.carregaEmpresaConta()),
          () => this.httpService.done());
    }

  }*/



  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;
    this.idEventoView = new Array();
    this.idEventoExcluirView = new Array();

    this.eventos.forEach(pc => {
      if (this.checkedAll === false) {
        pc.selected = false;
        this.idEventoView.slice(pc.id, 1);
        this.idEventoExcluirView.slice(pc.id, 1);
      } else {
        pc.selected = true;
        this.idEventoView.push(pc.id);
        this.idEventoExcluirView.push(pc);
      }
    });
    this.configuraToolBar();
  }

  configuraToolBar() {
    if (this.idEventoView.length > 0) {
      this.toolbarService.alterarStatus('remove', false, 'Excluir todos!', () => this.excluirEventosView());
    } else {
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirEventosView());
    }
    this.toolbarService.alterarStatus('export', true, 'Editar Fatura', () => this.showModalEditarEventos());
  }



  selectCheckbox(id: any, event: any) {
    // manipulando listas
    if (event.selected) {
      event.selected = true;
      // impressão de pedido
      const index = this.idEventoView.findIndex(vw => vw === id);
      this.idEventoView.splice(index, 1);

      this.idEventoExcluirView.splice(this.idEventoExcluirView.findIndex(e => e.id.toString() === event.id.toString()), 1);
    } else {

      this.idEventoView.push(id);
      this.idEventoExcluirView.push(event);
      event.selected = false;
    }
    this.eventoId = 0;
    this.eventoId = id;

    if (this.idEventoExcluirView.length > 0) {
      this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.excluirEventosView());
    } else {
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirEventosView());

    }

    if (this.idEventoExcluirView.length === 1) {
      this.nomeCurso = '';
      this.nomeCurso = event.item;
      this.toolbarService.alterarStatus('edit', false, 'Listar Participantes', () => this.showmodalParticipantePorEventos());
      this.toolbarService.alterarStatus('export', false, 'Editar Eventos', () => this.showModalEditarEventos());
    } else {
      this.toolbarService.alterarStatus('edit', true, 'Listar Participantes', () => this.showmodalParticipantePorEventos());
      this.toolbarService.alterarStatus('export', true, 'Editar Eventos', () => this.showModalEditarEventos());
    }
  }

  showmodalParticipantePorEventos() {
    this.modalParticipantePorEvento = true;
    this.lstparticipantePorEventos = new Array<any>();
    this.idParticipnantePorEventosExcluir = new Array<any>();
    this.httpService.wait();
    this.httpService.get('/custom/evento/get-participantePorEvento(' + this.eventoId + ')')
      .subscribe(retorno => {
        retorno.forEach(r => {
          this.lstparticipantePorEventos.push(r);
        });
      },
        error => this.httpService.handleError(error, () => this.listar()),
        () => this.httpService.done());

  }

  exluirItemListaGrid(rowValue: any) {
    if (confirm('Deseja excluir este item?')) {
      const index = this.lstparticipantePorEventos.findIndex(c => c.id === rowValue.id);
      this.lstparticipantePorEventos.splice(index, 1);
      this.idParticipnantePorEventosExcluir.push(rowValue.id);

    }
  }

  validaParticipanteIngresso() {
    if (this.idParticipnantePorEventosExcluir.length > 0) {


      const fichaIds = new Array();
      this.idParticipnantePorEventosExcluir.forEach(element => {
        fichaIds.push(element);
      });

      const fichas = {
        idFicha: fichaIds
      };

      this.httpService.wait();
      this.httpService.post('/custom/evento/deleta-ficha', fichas)
        .subscribe(result => {
        },
          error => this.httpService.handleError(error, () => this.validaParticipanteIngresso()),
          () => this.httpService.done());


    }
    this.modalParticipantePorEvento = false;
  }

  itemOnRowSelect(event: any) {
    this.ufChanged();
    this.carregaEventos();


    this.httpService.wait();
    this.httpService.get('/custom/evento/get-participante(' + event.participanteId + ',' + event.evento_id + ')')
      .subscribe(particip => this.loadParticipantePorId(particip),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());
    this.modalEditarParticipante = true;

  }
  loadParticipantePorId(value: Array<any>) {
    this.eventosFicha = new EventosFicha();

    value.forEach(element => {
      this.eventosFicha.participanteId = element.id;
      this.eventosFicha.nome = element.nome;
      this.eventosFicha.cpf = element.cpf;
      this.eventosFicha.nome = element.nome;
      this.eventosFicha.telefone = element.fone;
      this.eventosFicha.email = element.email;
      this.eventosFicha.cep = element.cep;
      this.eventosFicha.logradouro = element.logradouro;
      this.eventosFicha.numero = element.numero;
      this.eventosFicha.bairro = element.bairro;
      this.eventosFicha.complemento = element.complemento;
      this.eventosFicha.ufId = element.uf_id;
      setTimeout(() => this.setValueFicha(element), 1000);
      this.eventosFicha.valor = this.utilService.parseDecimalString(element.valor);
      this.eventosFicha.eventoFichaId = element.evento_ficha_id;
      this.eventosFicha.tipoPagto = element.tipo_pagto;
      this.validaTipoPagamento(this.eventosFicha.tipoPagto);
      this.eventosFicha.parceladoem = element.parcela;
      this.eventosFicha.numeroCartao = element.numero_cartao;
      this.eventosFicha.dtValidade = this.utilService.dateWithoutTimeZone(element.data_validade);
      this.eventosFicha.ccv = element.ccv;
      this.eventosFicha.depositoId = element.empresa_conta_id;

    });
    this.comboMunicipio();
    this.carregaTipoPagamento();

  }
  setValueFicha(element) {
    this.eventosFicha.municipioId = element.municipio_ibge_id;
    this.eventosFicha.eventoId = element.evento_id;
    this.eventosFicha.ingressosId = element.evento_ingresso_id;
    this.buscarIngressos(this.eventosFicha.eventoId);
    this.selectIngresso(this.eventosFicha.ingressosId);
  }

  carregaTipoPagamento() {
    this.opcoesTipoPagamento = [];
    this.opcoesTipoPagamento.push({ label: 'CRÉDITO À VISTA', value: 1 });
    this.opcoesTipoPagamento.push({ label: 'CRÉDITO PARCELADO', value: 2 });
    this.opcoesTipoPagamento.push({ label: 'CARTÃO DE DÉBITO', value: 3 });
    this.opcoesTipoPagamento.push({ label: 'DINHEIRO', value: 4 });
    this.opcoesTipoPagamento.push({ label: 'CHEQUE', value: 5 });
    this.opcoesTipoPagamento.push({ label: 'DEPÓSITO BANCÁRIO', value: 6 });
  }

  buscarIngressos(eventSelecionado: any) {
    this.httpService.wait();
    this.httpService.get('/custom/evento/get-eventos-ingressos-por-id(' + eventSelecionado + ')')
      .subscribe(listEventos => this.loadListaBuscarIngressos(listEventos),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());

    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.httpService.wait();
      this.httpService.get('/custom/evento/get-eventos-participante-conta(' + eventSelecionado + ')')
        .subscribe(listEventos => this.loadListaEventosParticipanteConta(listEventos),
          error => this.httpService.handleError(error, () => this.ngOnInit()),
          () => this.httpService.done());
    }

  }

  loadListaBuscarIngressos(value: Array<any>) {
    this.opcoesIngressos = [];
    value.forEach(r => {
      this.opcoesIngressos.push({
        label: 'Valor Pacote:' +
          this.utilService.parseDecimalString(r.valorPacote) + ' ' + r.codigo, value: r.id
      });

    });

  }

  loadListaEventosParticipanteConta(value: Array<any>) {
    this.opcoesDepositoBancario = [];
    value.forEach(element => {
      this.opcoesDepositoBancario.push({
        label: element.empresaContaNome + ' : ' + ' Ag : ' +
          element.empresaContaAgencia + ' CC : ' + element.empresaContaConta + '-' + element.empresaContaDvConta
        , value: element.empresaContaId
      });

    });

  }

  carregaEventos() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
      this.opcoesEventos = [];
      this.httpService.wait();
      this.httpService.get('/custom/evento/get-eventos-participante',
        [this.dblinkedSessionService.periodo.year.value,
        this.dblinkedSessionService.periodo.month.value,
        this.dblinkedSessionService.empresa.id
        ])
        .subscribe(retorno => {
          retorno.forEach(r => {
            this.opcoesEventos.push({ label: r.dataInicio + '-' + r.descricao.toUpperCase(), value: r.id });
          });
        },
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());
    }
  }

  pesquisarCep() {

    this.eventosFicha.cep = this.eventosFicha.cep.replace(/[^\d]+/g, '');

    if (this.eventosFicha.cep.length !== 8) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'CEP deverá conter 8 caracteres!'
      });
      return;

    } else {

      this.httpService.wait();
      this.httpService.get('/custom/service/get-endereco(' + this.eventosFicha.cep + ')')
        .subscribe(
          rst => {

            if (!rst.errors) {
              this.eventosFicha.logradouro = rst.logradouro;
              this.eventosFicha.bairro = rst.bairro;
              this.eventosFicha.ufId = rst.uf;
              this.eventosFicha.municipioId = rst.localidade;
              this.eventosFicha.numero = '';
              this.eventosFicha.complemento = '';
              // uf
              this.httpService.wait();
              this.httpService.get('/uf?$select=id,uf&$filter=uf eq ' + '\'' + rst.uf + '\'').subscribe(
                ref => {
                  this.eventosFicha.ufId = ref.value[0].id;
                  if (this.eventosFicha.ufId) {
                    this.comboMunicipio();
                    // busca cidade
                    setTimeout(() => this.setCidadeBusca(rst.ibge), 1000);
                    // fim busca cidade
                  }
                },
                error => this.httpService.handleError(error, () => this.pesquisarCep()),
                () => this.httpService.done());
              // fim uf

            } else {

              rst.errors.forEach(element => {
                this.eventosFicha.logradouro = '';
                this.eventosFicha.bairro = '';
                this.eventosFicha.ufId = null;
                this.eventosFicha.municipioId = null;
                this.eventosFicha.numero = '';
                this.eventosFicha.complemento = '';

                if (element.code === 40001) {
                  this.messageService.add({
                    severity: 'warn',key: 'messageLinnks',
                    summary: 'MSG: ',
                    detail: 'CEP Inválido!'
                  });


                } else {
                  this.messageService.add({
                    severity: 'warn',key: 'messageLinnks',
                    summary: 'MSG: ',
                    detail: element.message
                  });
                }
              });
              return;
            }

          }, error => this.httpService.handleError(error, () => this.pesquisarCep()),
          () => this.httpService.done()
        );

    }

  }


  setCidadeBusca(codigoIbge) {

    if (codigoIbge) {

      this.httpService.wait();
      this.httpService.get('/municipioibge?$select=id,nome&$filter=cod eq ' + '\'' + codigoIbge + '\'')
        .subscribe(
          cid => {
            this.eventosFicha.municipioId = cid.value[0].id;
          },
          error =>
            this.httpService.handleError(error, () => this.pesquisarCep()),
          () => this.httpService.done());

    } else {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Cidade não encontrada, selecione nas opções'
      });
      return;
    }

  }

  comboMunicipio() {
    this.opcoesMunicipio = [];
    this.opcoesMunicipio.push({ label: 'Selecione', value: null });
    this.httpService.wait();
    this.httpService.get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + this.eventosFicha.ufId)
      .subscribe(
        ref => {
          ref.value.forEach(element => {
            this.opcoesMunicipio.push({
              label: element.nome,
              value: element.id
            });
          });
        },
        error =>
          this.httpService.handleError(error, () => this.comboMunicipio()),
        () => this.httpService.done()
      );
  }

  onFilterEvento(event: any) {
  }

  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    //this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');

    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.listar()
    );
    this.toolbarService.ocultar('save');
//<i class="fas fa-calendar-alt"></i>
    this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Opções');
    this.toolbarService.replace('pesquisa', false, 'class-edit-button', 'search', 'Pesquisar por data',() => this.showModalSelecionarData()  );
    //this.toolbarService.ocultar('pesquisa');

    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir Pedido',
    );
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('export', true, 'class-edit-button', 'edit', 'Editar Evento', () => this.showModalEditarEventos()
    );
    this.toolbarService.replace('edit', true, 'class-edit-button', 'list', 'Alterar Títulos Selecionados');

    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo Evento',
      () => this.showModalEventos()
    );

  }


  listar() {

    this.eventos = new Array<any>();
    this.idEventoView = new Array();
    this.idEventoExcluirView = new Array();
    this.toolbarEnable();
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
      this.eventos = new Array();

      this.getComboStatus();
      this.getComboUf();
      this.getComboOpcoesMeioPagamento();

      this.httpService.wait();
      this.httpService.get('/custom/evento/get-eventos',
        [this.dataIniSearch,
         this.dataFinSearch,
        this.dblinkedSessionService.empresa.id
        ])
        .subscribe(retorno => {
          console.log(retorno);
          retorno.forEach(r => {
            r.selected = false;
            this.eventos.push(r);
          });
        },
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());

      //this.carregaEmpresaConta();
      this.carregaFormasPgto();
      this.carregaContaBancarias();

    }
    setTimeout(() => {
      this.loadOpcoes();
    }, 1000);


  }

  loadOpcoes() {
    this.idEventoView = new Array();
    this.httpService.wait();
    this.httpService.get('/statusevento?$select=id,descricao&$filter=id eq 1 or id eq 2  &$orderby=descricao')
      .subscribe(ref => this.loadOpcoesEvento(ref.value),
        error => this.httpService.handleError(error, () => this.statusEventoChanged()),
        () => this.httpService.done());
  }

  loadOpcoesEvento(array: Array<StatusEvento>) {
    const childActions: Array<ToolbarButton> = new Array();
    array.forEach(element => {

        childActions.push({
          icon: '',
          label: element.descricao,
          callback: () => this.buscaStatusEvento(element.id)
        });


    });

    this.toolbarService.get('more_vert').child = childActions;
  }


  buscaStatusEvento(tipo: any) {


    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
      this.eventos = new Array();
      this.httpService.wait();
      this.httpService.get('/custom/evento/get-eventos-opcoes',
        [this.dblinkedSessionService.periodo.year.value,
        this.dblinkedSessionService.periodo.month.value,
        this.dblinkedSessionService.empresa.id,
          tipo
        ])
        .subscribe(retorno => {
          retorno.forEach(r => {
            r.selected = false;
            this.eventos.push(r);
          });
        },
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());
    }

  }


  statusEventoChanged() {
    this.httpService.wait();
    this.httpService.get('/statusevento?$select=id,descricao&$orderby=descricao')
      .subscribe(ref => this.loadStatusEvento(ref.value),
        error => this.httpService.handleError(error, () => this.statusEventoChanged()),
        () => this.httpService.done());
  }

  loadStatusEvento(array: Array<TipoFatura>) {

    this.statusEvento = [];
    this.statusEvento.push({ label: 'Selecionar Status', value: null });

    array.forEach(element => {
      if (element.id !== 3) {
        this.statusEvento.push({ label: element.descricao, value: element.id });
      }


    });


  }




  onChangeUf(ufId: number, callback: Function = null) {
    this.httpService.loadList('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + ufId,
      this.municipios,
      callback);
  }

  showModalEditarEventos() {

    this.carregaPalestrante();
    this.editarEvento = true;
    this.modalContrato = false;
    this.editarEventoIngresso = false;

    this.desabilitaStatus = false;
    this.contasBancariasSelec = new Array();
    this.formasPgtoSelec = new Array();
    this.finalizarCarregamentoEvento();

    this.httpService.wait();
    this.httpService.get('/custom/evento/get-eventos-por-id(' + this.eventoId + ')')
      .subscribe(listEventos => {console.log(listEventos); this.loadListaEventosPorId(listEventos)},
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());
  }

  loadListaEventosPorId(value: Array<any>) {
    console.log(value);
    value.forEach(element => {

      this.eventosObj = new Eventos();
      this.eventosObj.id = element.id;
      this.eventosObj.data = new Date();

      if (!isNullOrUndefined(element.uf)) {
        this.ufSelecionada = element.uf;
        this.buscarUf(this.ufSelecionada);
      } else {
        this.ufSelecionada = 0;
      }

      this.eventosObj.municipioIbge = new MunicipioIbge();

      this.eventosObj.municipioIbge.id = element.municipioId;
      if (!isNullOrUndefined(element.dataInicio)) {
        this.eventosObj.dataInicio = this.utilService.dateWithoutTimeZone(element.dataInicio);
      }else{
        this.eventosObj.dataInicio = null;
      }

      if (!isNullOrUndefined(element.dataTermino)) {
        this.eventosObj.dataTermino = this.utilService.dateWithoutTimeZone(element.dataTermino);
      } else {
        this.eventosObj.dataTermino = null;
      }

      this.eventosObj.item = new Item();
      this.eventosObj.item.id = element.itemId;
      this.eventosObj.statusEvento = new StatusEvento();
      this.eventosObj.statusEvento.id = element.statusEventoId;
      this.eventosObj.empresaconta = new EmpresaConta();
      this.eventosObj.empresaconta.id = element.empresaContaId;
      this.eventosObj.seminarista = new Seminarista();

      if (!isNullOrUndefined(element.seminaristaId)) {
        this.eventosObj.seminarista.id = element.seminaristaId;
      }else{
        this.eventosObj.seminarista.id = 0;
      }

      this.eventosObj.endereco = element.endereco;
      this.eventosObj.informacoes = element.informacoes;
      this.eventosObj.link = element.link;
      if (!isNullOrUndefined(element.clubeVidaPlena)) {
        this.eventosObj.clubeVidaPlena = element.clubeVidaPlena;
        this.desabilitarTempoAcessoClubeVidaPlena = !element.clubeVidaPlena;
      } else {
        this.eventosObj.clubeVidaPlena = false;
        this.desabilitarTempoAcessoClubeVidaPlena = true;
      }

      if (!isNullOrUndefined(element.tempoAcessoClubeVidaPlena)) {
        this.eventosObj.tempoAcessoClubeVidaPlena = element.tempoAcessoClubeVidaPlena;
      } else{
        this.eventosObj.tempoAcessoClubeVidaPlena = 0;
      }

      this.eventosObj.chaveEduzz = element.chaveEduzz;
      this.eventosObj.contrato   = element.contrato;
      this.eventosObj.eventosMeioPagamentoId = element.eventosMeioPagamentoId;
      this.eventosObj.eventosMeioPagamento = new EventosMeioPagamento();
      this.eventosObj.eventosMeioPagamento.id = element.eventoMeioPagamentoId;

      this.formasPgtoSelec = new Array();
        element.formaPagtoRelac.forEach(element => {
        this.formasPgtoSelec.push(element.idForma);
      });

      this.contasBancariasSelec = new Array();
        element.empContaRelac.forEach(element => {
        this.contasBancariasSelec.push(element.idConta);
      });

    });

    this.carregaIngressos();
    this.getComboMeiosPagamento();

    this.modalEventos = true;
  }

  finalizarCarregamentoEvento() {
    this.eventosObj.changeUser = new AuthUser();
    this.eventosObj.changeUser.id = this.sessionService.loggedUser.id;
    this.httpService.loadList('/uf?$select=id,uf&$orderby=uf', this.ufs);

    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.itensChanged();
      this.eventosObj.empresa = new Empresa();
      this.eventosObj.empresa.id = this.dblinkedSessionService.empresa.id;
    }

    this.ufChanged();
    if (!isNullOrUndefined(this.ufSelecionada)) {
      this.buscarUf(this.ufSelecionada);
    }else{
      this.buscarUf(0);
    }


    this.carregaIngressos();
  }

  carregaIngressos() {
    this.httpService.wait();
    this.httpService.get('/custom/evento/get-eventos-ingressos-por-id(' + this.eventoId + ')')
      .subscribe(listEventos => this.loadListaEventosIngressosPorId(listEventos),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());
  }

  loadListaEventosIngressosPorId(value: Array<any>) {
    this.eventosObj.eventosIngressos = new Array();
    value.forEach(element => {
      this.eventosIngressosObj = new EventosIngressos();
      this.eventosIngressosObj.id = element.id;
      this.eventosIngressosObj.codigo = element.codigo;
      this.eventosIngressosObj.eventos = new Eventos();
      this.eventosIngressosObj.eventos.id = element.eventoId;
      this.eventosIngressosObj.descricao = element.descricao;
      this.eventosIngressosObj.descricaoComplemento = element.descricao_complementar;
      this.eventosIngressosObj.valorPacote = element.valorPacote;
      this.eventosIngressosObj.valorPromocional = element.valorPromocional;
      this.eventosIngressosObj.link             = element.link;
      this.eventosObj.eventosIngressos.push(this.eventosIngressosObj);

    });
  }




  showModalEventos() {
    this.modalEventos = true;
    this.editarEvento = false;
    this.modalContrato = false;
    this.carregaPalestrante();
    this.editarEventoIngresso = false;
    this.modalIngressos = false;
    this.eventosObj = new Eventos();
    this.eventosObj.id = 0;
    this.ufSelecionada = 0;
    this.eventosObj.dataInicio = new Date();
    this.eventosObj.dataTermino = new Date();
    this.eventosObj.municipioIbge = new MunicipioIbge();
    this.eventosObj.municipioIbge.id = null;
    this.eventosObj.item = new Item();
    this.eventosObj.item.id = null;
    this.eventosObj.changeUser = new AuthUser();
    this.eventosObj.changeUser.id = this.sessionService.loggedUser.id;
    this.eventosObj.data = new Date();
    this.eventosObj.statusEvento = new StatusEvento();
    this.eventosObj.statusEvento.id = 1;
    this.eventosObj.empresaconta = new EmpresaConta();
    this.eventosObj.empresaconta.id = null;
    this.eventosObj.seminarista = new Seminarista();
    this.eventosObj.seminarista.id = null;
    this.contasBancariasSelec = new Array();
    this.formasPgtoSelec = new Array();
    this.eventosObj.clubeVidaPlena = false;
    this.eventosObj.tempoAcessoClubeVidaPlena = 0;
    this.eventosObj.eventosMeioPagamento = new EventosMeioPagamento();
    this.eventosObj.eventosMeioPagamento.id = null
    this.eventosObj.eventosIngressos = new Array();

    this.httpService.loadList('/uf?$select=id,uf&$orderby=uf', this.ufs);

    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.itensChanged();
      this.eventosObj.empresa = new Empresa();
      this.eventosObj.empresa.id = this.dblinkedSessionService.empresa.id;
    }

    this.ufChanged();
    this.desabilitaStatus = true;
    this.getComboMeiosPagamento();

  }

  showModalIngressos() {
    this.tpingresso = [];
    this.tpingressoValue = 0;
    this.eventosIngressosObj = new EventosIngressos();

    if (!this.editarEvento) {
      this.eventosObj.eventosIngressos = new Array();
    }

    if (isNullOrUndefined(this.eventosObj.eventosIngressos) || this.eventosObj.eventosIngressos.length === 0) {
      this.eventosObj.eventosIngressos = new Array();
    }

    this.getComtoTipoIngresso();
    this.modalIngressos = true;

  }

  addIngressos() {

    if ((this.tpingressoValue === null) || (this.tpingressoValue === 0)) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Tipo do Ingresso não pode ser vazio. Verifique!'
      });
      return;

    }

    if ((this.utilService.parseDecimal6(this.eventosIngressosObj.valorPromocional) >
      this.utilService.parseDecimal6(this.eventosIngressosObj.valorPacote))) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Valor da Promocional não pode ser maior que o Valor do Pacote. Verifique!'
      });
      return;
    }


    //this.eventosIngressosObj.codigo = this.tpingressoValue === 1 ? 'GOLD' : this.tpingressoValue === 2 ? 'VIP' : this.tpingressoValue === 3 ? 'DIAMOND' : this.tpingressoValue === 5 ? 'TOPÁZIO' : 'BONUS';
    this.eventosIngressosObj.codigo = this.tpingresso.find(e => e.value === this.tpingressoValue).label;
    this.eventosIngressosObj.id = 0;
    if (this.eventosIngressosObj !== null || this.eventosIngressosObj !== undefined) {
      this.eventosObj.eventosIngressos.push(this.eventosIngressosObj);
      this.tpingressoValue = 0;

    }
    this.eventosIngressosObj = new EventosIngressos();


  }

  itemOnRowSelectIngresso(event: any) {

    this.editarEventoIngresso = true;
    event.valorPacote = this.utilService.parseDecimalString(event.valorPacote);
    event.valorPromocional = this.utilService.parseDecimalString(event.valorPromocional);
    //this.tpingressoValue = this.eventosIngressosObj.codigo === 'GOLD' ? 1 : this.eventosIngressosObj.codigo === 'VIP' ? 2 : 3;
    this.tpingressoValue = this.tpingresso.find(e => e.label === event.codigo).value;
    this.eventosIngressosObj = new EventosIngressos(event);

  }

  onFilterIngresso(event: any) {
  }

  removerIngressosGrid(event: any) {
    if (confirm('Deseja excluir este ingresso?')) {
            const index = this.eventosObj.eventosIngressos.indexOf(event);
      this.eventosObj.eventosIngressos.splice(index, 1);
    }
  }

  exluirIngressos() {

  }

  alterarIngressos() {


    if ((this.utilService.parseDecimal6(this.eventosIngressosObj.valorPromocional) >
      this.utilService.parseDecimal6(this.eventosIngressosObj.valorPacote))) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Valor da Promocional não pode ser maior que o Valor do Pacote. Verifique!'
      });
      return;
    }

    const index = this.eventosObj.eventosIngressos.findIndex(c => c.id === this.eventosIngressosObj.id);
    this.eventosObj.eventosIngressos.splice(index, 1, this.eventosIngressosObj);
    this.eventosIngressosObj = new EventosIngressos();
    this.tpingressoValue = 0;
    this.editarEventoIngresso = false;

  }


  excluirEventosView() {
    if (confirm('Deseja realmente excluir esta Evento?')) {
      const EventosIds = new Array();
      this.idEventoExcluirView.forEach(element => {
        EventosIds.push(element.id);
      });

      const event = {
        idEventos: EventosIds
      };

      this.httpService.wait();
      this.httpService.post('/custom/evento/delete-eventos', event)
        .subscribe(result => {
          this.messageService.add({
            severity: result.severity,
            summary: result.summary,
            detail: result.detail
          });
          this.listar();
        },
          error => this.httpService.handleError(error, () => this.excluirEventosView()),
          () => this.httpService.done());

      this.idEventoExcluirView.forEach(element => {
        const index = this.idEventoView.findIndex(vw => vw === element.id);
        this.idEventoView.splice(index, 1);
      });
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirEventosView());
      /*this.toolbarService.alterarStatus('print', true, 'Imprimir Fatura', () => this.imprimirFatura());
      this.toolbarService.alterarStatus('edit', true, 'Listar Itens',  () => this.visualizarFaturaItem(event, true));
      this.toolbarService.alterarStatus('export', true, 'Editar Fatura', () => this.displayEditFaturaDialog());*/
    }
  }



  buscarUf(ufs: any) {

    this.httpService.wait();
    this.httpService.get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + ufs + '&$orderby=nome')
      .subscribe(ref => this.loadMunicipio(ref.value),
        error => this.httpService.handleError(error, () => this.buscarUf(ufs)),
        () => this.httpService.done());

  }

  loadMunicipio(array: Array<MunicipioIbge>) {
    this.opcoesMunicipio = [];
    this.opcoesMunicipio.push({ label: 'Selecionar Municipio', value: null });
    array.forEach(r => {
      this.opcoesMunicipio.push({ label: r.nome, value: r.id });

    });


  }

  ufChanged() {
    this.httpService.wait();
    this.httpService.get('/uf?$select=id,uf&$orderby=uf').subscribe(ref => this.loadUf(ref.value),
      error => this.httpService.handleError(error, () => this.ufChanged()),
      () => this.httpService.done());
  }

  loadUf(array: Array<Uf>) {
    this.opcoesUF = [];
    this.opcoesUF.push({ label: 'Selecionar UF', value: 0 });

    array.forEach(r => {
      this.opcoesUF.push({ label: r.uf, value: r.id });

    });
  }


  itensChanged() {
    this.httpService.wait();
    this.httpService.get('/item?$select=id,descricao' +
      '&$filter=changeUser/id ne 1 and operacao/id eq 1 and itemTipo/id eq 10 ' +
      ' &$orderby=descricao')
      .subscribe(ref => this.loadItens(ref.value),
        error => this.httpService.handleError(error, () => this.itensChanged()),
        () => this.httpService.done());
  }
  loadItens(array: Array<Item>) {
    this.opcoesItens = [];
    this.opcoesItens.push({ label: 'Selecionar Item', value: null });

    array.forEach(element => {
      this.opcoesItens.push({ label: element.descricao, value: element.id });

    });
  }


  salvarEvento() {


    this.carregaContaBancariaObjeto();
    this.carregaFormaPagtoObjeto();


    if (this.eventosObj.item.id === null || this.eventosObj.item.id === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Item não pode ser vazio. Verifique!'
      });
      return;

    }


    if ( isNullOrUndefined(this.eventosObj.eventosMeioPagamento) || ( isNullOrUndefined(this.eventosObj.eventosMeioPagamento.id)) || this.eventosObj.eventosMeioPagamento.id === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar o meio de pagamento!'
      });
      return;
    }


    if (this.eventosObj.statusEvento.id === null || this.eventosObj.statusEvento.id === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Status não pode ser vazio. Verifique!'
      });
      return;

    }

  if (this.eventosObj.seminarista.id === null || this.eventosObj.seminarista.id === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Palestrante não pode ser vazio. Verifique!'
      });
      return;

    }

    if (!isNullOrUndefined( this.eventosObj.clubeVidaPlena)) {
      if (this.eventosObj.clubeVidaPlena === true) {
        if (isNullOrUndefined(this.eventosObj.tempoAcessoClubeVidaPlena) || this.eventosObj.tempoAcessoClubeVidaPlena < 1) {
            this.messageService.add({
              severity: 'warn',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'O tempo de acesso não pode ser menor que 1'
            });
            return;
        }


        if (isNullOrUndefined(this.eventosObj.tempoAcessoClubeVidaPlena) || this.eventosObj.tempoAcessoClubeVidaPlena > 240) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O tempo de acesso não pode ser maior que 240'
          });
          return;
      }

      }
    }

    const listaEnviar = {
      eventosObj            :  this.eventosObj,
      empresa               : this.dblinkedSessionService.empresa.id,
      changeUser            : this.sessionService.loggedUser.id,
   };


    this.httpService.wait();
    this.httpService.post('/custom/evento/criar-evento', listaEnviar)
      .subscribe(result => {

        if (result.error === true) {
          this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
        } else {
          this.listar();
          this.modalEventos = false;
        }

      },
        error => this.httpService.handleError(error, () => this.salvarEvento()),
        () => this.httpService.done());


    this.idEventoView = new Array();

  }

  selectIngresso(event: any) {
    this.valorPacote = 0;

    this.httpService.wait();
    this.httpService.get('/eventoingresso?$select=id,valorPacote,valorPromocional&$orderby=id&$filter=id eq ' + event)
      .subscribe(
        ref => {
          ref.value.forEach(element => {
            this.valorPacote = element.valorPacote;
            this.valorPromocional = element.valorPromocional;

          });
        },
        error =>
          this.httpService.handleError(error, () => this.comboMunicipio()),
        () => this.httpService.done()
      );

  }

  salvaParticipante() {


    let erro = false;

    if (this.eventosFicha.nome === '' || this.eventosFicha.nome === null || this.eventosFicha.nome === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Nome não pode ser vazio. Verifique!'
      });
      erro = true;
    }

    if (this.eventosFicha.cpf === null || this.eventosFicha.cpf === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'CPF não pode ser vazio. Verifique!'
      });
      erro = true;
    }


    if (this.eventosFicha.cpf.length < 11) {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'É Obrigatório informar CPF!' });
      erro = true;
    }


    if (!Util.isCpf(this.eventosFicha.cpf.toString().replace('.', '').replace('-', ''))) {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: 'CPF Inválido!' });
      erro = true;
    }

    if (this.eventosFicha.telefone === '' || this.eventosFicha.telefone === null || this.eventosFicha.telefone === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Telefone não pode ser vazio. Verifique!'
      });
      erro = true;
    }

    if (this.eventosFicha.telefone.length < 10) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Telefone inválido. Verifique!'
      });
      erro = true;
    }


    if (this.eventosFicha.email === '' || this.eventosFicha.email === null || this.eventosFicha.email === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Email não pode ser vazio. Verifique!'
      });
      erro = true;
    }

    if (!Util.validacaoEmail(this.eventosFicha.email)) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Email inválido. Verifique!'
      });
      erro = true;

    }


    if (this.eventosFicha.cep === '' || this.eventosFicha.cep === null || this.eventosFicha.cep === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'CEP não pode ser vazio. Verifique!'
      });
      erro = true;
    }


    if (this.eventosFicha.logradouro === '' || this.eventosFicha.logradouro === null || this.eventosFicha.logradouro === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Logradouro não pode ser vazio. Verifique!'
      });
      erro = true;
    }

    if (this.eventosFicha.numero === '' || this.eventosFicha.numero === null || this.eventosFicha.numero === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Número não pode ser vazio. Verifique!'
      });
      erro = true;
    }


    if (this.eventosFicha.bairro === '' || this.eventosFicha.bairro === null || this.eventosFicha.bairro === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Bairro não pode ser vazio. Verifique!'
      });
      erro = true;
    }


    if (this.eventosFicha.municipioId === null || this.eventosFicha.municipioId === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Municipio não pode ser vazio. Verifique!'
      });
      erro = true;
    }


    if (this.eventosFicha.ufId === null || this.eventosFicha.ufId === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'UF não pode ser vazio. Verifique!'
      });
      erro = true;
    }

    if (this.eventosFicha.valor === '' || this.eventosFicha.valor === null || this.eventosFicha.valor === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Valor não pode ser vazio. Verifique!'
      });
      erro = true;
    }

    if (this.eventosFicha.valor > this.valorPacote) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Valor não pode ser maior que o valor do pacote. Verifique!'
      });
      erro = true;

    }

    if (this.eventosFicha.valor < this.valorPromocional) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Valor não pode ser menor que o valor do promocional ' + this.utilService.parseDecimalString(this.valorPromocional)
          + '. Verifique!'
      });
      erro = true;

    }

    if (this.eventosFicha.eventoId === 0 || this.eventosFicha.eventoId === null || this.eventosFicha.eventoId === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Evento não pode ser vazio. Verifique!'
      });
      erro = true;
    }

    if (this.eventosFicha.ingressosId === 0 || this.eventosFicha.ingressosId === null || this.eventosFicha.ingressosId === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Ingressos não pode ser vazio. Verifique!'
      });
      erro = true;
    }

    if (this.eventosFicha.parceladoem === null || this.eventosFicha.parceladoem === undefined) {
      this.eventosFicha.parceladoem = '0';
    }
    if (this.eventosFicha.numeroCartao === null || this.eventosFicha.numeroCartao === undefined) {
      this.eventosFicha.numeroCartao = '';
    }

    if (this.eventosFicha.ccv === null || this.eventosFicha.ccv === undefined) {
      this.eventosFicha.ccv = '';
    }

    if (!erro) {
      this.eventosFicha.empresaId = this.dblinkedSessionService.empresa.id;
      this.eventosFicha.authUserId = this.sessionService.loggedUser.id;
      this.eventosFicha.id = -1;

      if (this.eventosFicha.complemento === null || this.eventosFicha.complemento === undefined) {
        this.eventosFicha.complemento = '';
      }

      this.httpService.wait();
      this.httpService.post('/custom/evento/criar-evento-ficha', this.eventosFicha)
        .subscribe(result => {

          if (result.error === 'true') {
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result.message });
          }
        },
          error => this.httpService.handleError(error, () => this.salvaParticipante()),
          () => this.httpService.done());


    }

  }




  ngOnDestroy(): void {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  onFilterEventos(event: any) {
    event.filteredValue.forEach(element => {
    });
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  gerarPedidos() {
    if (confirm('Os pedidos deste evento serão gerados, deseja continuar')) {

      const evento = {
        eventoId: this.eventoId
      };


      this.httpService.wait();
      this.httpService.post('/custom/evento/gerar-pedidos-evento', evento)
        .subscribe(result => {

          if (result.error === 'true') {
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.message });
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result.message });
          }
        },
          error => this.httpService.handleError(error, () => this.gerarPedidos()),
          () => this.httpService.done());
    }

  }

  onChangeIngresso(){

    this.httpService.wait();
      this.httpService.get('/custom/polozi/get-ingresso',
        [this.tpingressoValue])
        .subscribe(retorno => {
          this.eventosIngressosObj.descricao            = retorno[0].descricao;
          this.eventosIngressosObj.descricaoComplemento = retorno[0].descricaoComplementar;

        },
          error => this.httpService.handleError(error, () => this.onChangeIngresso()),
          () => this.httpService.done());
  }

  visualizarFicha(event: any) {
    this.httpService.wait();
    this.httpService.get('/custom/polozi/imprimir-evento-ficha-polozi(' + event.id + ')')
    .subscribe(result => this.exportedFicha(result),
    error => this.httpService.handleError(error, () => this.visualizarFicha(event)),
    () => this.httpService.done());
}

exportedFicha(result?: any) {

  if (!result.error) {
    window.open(this.httpService.url('/custom/file/' + result.file + '/polozi_jasper'), '_blank');
  } else {
    this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Advertência', detail: result.message });
  }
}


carregaContaBancariaObjeto(){
  this.eventosObj.eventoEmpresaContaRelac = new Array();
  this.contasBancariasSelec.forEach(element => {
  let eventoEmpresaContaRelac = new EventoEmpresaContaRelac();
  eventoEmpresaContaRelac.empresaConta = new EmpresaConta();
  eventoEmpresaContaRelac.empresaConta.id = element;
  this.eventosObj.eventoEmpresaContaRelac.push(eventoEmpresaContaRelac);

});

}

carregaFormaPagtoObjeto(){
  this.eventosObj.eventoFormaPgtoRelac = new Array();
  this.formasPgtoSelec.forEach(element => {
  let eventoFormaPgtoRelac = new EventoFormaPgtoRelac();
  eventoFormaPgtoRelac.formaPagamento = new FormaPagamento();
  eventoFormaPgtoRelac.formaPagamento.id = element;
  this.eventosObj.eventoFormaPgtoRelac.push(eventoFormaPgtoRelac);

});

}

entraTipoBaixa(tipoBaixa: TipoBaixa, empresaConta: EmpresaConta) : boolean {

  if(tipoBaixa !== null){
    if(tipoBaixa.id === 1 || tipoBaixa.id === 2 || tipoBaixa.id === 3 || tipoBaixa.id === 4
      || tipoBaixa.id === 5 || tipoBaixa.id === 6 || tipoBaixa.id === 12 || tipoBaixa.id === 13){
        if(tipoBaixa.id === 3 || tipoBaixa.id === 4 || tipoBaixa.id === 12 || tipoBaixa.id === 13){
          if(empresaConta === null || empresaConta === undefined || !empresaConta.id){
            return false;
          }else{
            return true;
          }
        } else{
          return true;
        }

      }else{
        return false;
      }
  }else{
    return false;
  }

}

enviarMensagemParticipantes(tipo: any) {
  const message = tipo == 'email' ? 'e-mail' : 'WhatsApp';
  if (confirm('Deseja realmente enviar ' + message + ' a todos os participantes deste evento?')) {

    const params = {
      evento  :this.eventoId,
      message :message
    };

    this.httpService.wait();
      this.httpService.post('/custom/polozi/enviar-msgs', params)
        .subscribe(result => {
          this.messageService.add({
            severity: result.severity,
            summary: result.summary,
            detail: result.detail
          });
          this.listar();
          this.modalEventos = false;
        },
          error => this.httpService.handleError(error, () => this.enviarMensagemParticipantes(tipo)),
          () => this.httpService.done());
  }
}

enviarMsgWhatsInfo() {

  if(isNullOrUndefined(this.msgWhatsInfo) || this.msgWhatsInfo === ''){
    this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: 'Deve ser informada uma mensagem para envio' });
    return;
  }


  if (confirm('Deseja realmente enviar esta mensagem a todos os participantes deste evento?')) {

    const params = {
      evento  :this.eventoId,
      message :'info',
      mensagem:this.msgWhatsInfo
    };

    this.httpService.wait();
      this.httpService.post('/custom/polozi/enviar-msgs', params)
        .subscribe(result => {
          this.messageService.add({
            severity: result.severity,
            summary: result.summary,
            detail: result.detail
          });
          this.listar();
          this.modalEventos = false;
        },
          error => this.httpService.handleError(error, () => this.enviarMsgWhatsInfo()),
          () => this.httpService.done());
  }
}

gerarCheckIn() {
  if (confirm('Deseja realmente gerar o check-in deste evento?')) {

    const params = {
      evento  :this.eventoId,
    };

    this.httpService.wait();
      this.httpService.post('/custom/evento/gerar-check-in', params)
        .subscribe(result => {
          this.messageService.add({
            severity: result.severity,
            summary: result.summary,
            detail: result.detail
          });
          this.listar();
          this.modalEventos = false;
        },
          error => this.httpService.handleError(error, () => this.gerarCheckIn()),
          () => this.httpService.done());
  }
}

getComtoTipoIngresso(){
  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-combo-tipo-ingresso')
    .subscribe(retorno => {
      this.tpingresso = [];
      this.tpingresso.push({ label: 'Selecionar Ingresso', value: 0 });
      retorno.forEach(element =>{
        this.tpingresso.push({label: element.codigo, value: element.id});
      });
    },
      error => this.httpService.handleError(error, () => this.getComtoTipoIngresso()),
      () => this.httpService.done());

 }

 abrirModalWhatsInfo(){
  this.modalWhatsInfo = true;
 }

 showModalSelecionarData(){
  this.modalSelecionarData = true;
  var today = new Date(this.dataFinSearch);
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
  //this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
  var novaDataFin = new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate());
  this.dataFin = this.utilService.dateWithoutTimeZone(novaDataFin.toString());
  this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);

}

atualizarVoltar(){
  if (this.dataIni !== null && this.dataFin !== null) {
    const mesIni = this.dataIni.getMonth() > 8 ?
                  (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
    const mesFin = this.dataFin.getMonth() > 8 ?
                  (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
    this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
                  this.dataIni.getDate() : '0' + this.dataIni.getDate());
    this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
                  this.dataFin.getDate() : '0' + this.dataFin.getDate());
  } else{
    this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Favor selecionar uma data!' });
    return;

  }

  const d1 = new Date(this.dataIniSearch);
  const d2 = new Date(this.dataFinSearch);
  if (d1 > d2) {
    this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Data incial maior que a final. Verifique!' });
    return;
  }
  this.modalSelecionarData = false;
  this.listar();
}

gerarLinkPagto(){
  if (confirm('Deseja realmente gerar o link de pagamento deste evento?')) {

    const params = {
      evento  :this.eventoId
    };

    this.httpService.wait();
      this.httpService.post('/custom/polozi/gerar-link-pagto', params)
        .subscribe(result => {
          this.messageService.add({
            severity: result.severity,
            summary: result.summary,
            detail: result.detail
          });
          this.listar();
          this.modalEventos = false;
        },
          error => this.httpService.handleError(error, () => this.gerarLinkPagto()),
          () => this.httpService.done());
  }
}

habilitaDesabilitaTempoAcesso(value: any){

  if (!isNullOrUndefined(value)) {
    this.desabilitarTempoAcessoClubeVidaPlena = value;

    if (!value === false) {
      this.eventosObj.tempoAcessoClubeVidaPlena = 0;
    }


  } else {
    this.desabilitarTempoAcessoClubeVidaPlena = true;
  }

}


getComboStatus(){

  this.httpService.wait();
  this.httpService.get('/statusevento?$select=id,descricao&$orderby=descricao')
    .subscribe(ref => {
      this.opcoesStatusCombo        = [];
      this.opcoesStatusSelecionados = [];
      ref.value.forEach(e => {
        if (e.id !== 3) {
          this.opcoesStatusCombo.push({label: e.descricao , value: e.descricao});
        }
      })
      if (this.opcoesStatusCombo === null || this.opcoesStatusCombo === undefined) {
        this.opcoesStatusCombo.map(item => this.opcoesStatusSelecionados.push(item.value));;
      }
    },
      error => this.httpService.handleError(error, () => this.getComboStatus()),
      () => this.httpService.done());
}



getComboUf(){

  this.httpService.wait();
  this.httpService.get('/uf?$select=id,uf&$orderby=uf').subscribe(ref => {

    this.opcoesUf = [];
    this.opcoesUfSelecionados = [];
    this.opcoesUf.push({label: '', value: ''});
    ref.value.forEach(element => {
      this.opcoesUf.push({label: element.uf, value: element.uf});
    });
    if (this.opcoesUf === null || this.opcoesUf === undefined) {
      this.opcoesUf.map(item => this.opcoesUfSelecionados.push(item.value));
    }
  },
    error => this.httpService.handleError(error, () => this.getComboUf()),
    () => this.httpService.done());



}

getComboMeiosPagamento(){

  this.httpService.wait();
  this.httpService.get('/custom/evento/get-eventos-meio-pagamento')
    .subscribe(ref => {
      this.opcoesMeioPagametno = new Array();
      this.opcoesMeioPagametno.push({label: 'Selecione o meio de pagamento', value: 0});
      ref.forEach(element => {
        this.opcoesMeioPagametno.push({label: element.descricao, value: element.id});
      });
    },
      error => this.httpService.handleError(error, () => this.getComboMeiosPagamento()),
      () => this.httpService.done());

}

getComboOpcoesMeioPagamento(){
  this.httpService.wait();
  this.httpService.get('/custom/evento/get-eventos-meio-pagamento')
    .subscribe(ref => {
      this.opcoesComboMeioPagamento = new Array();
      this.opcoesComboMeioPagamento.push({label: '', value: ''});
      ref.forEach(element => {
        this.opcoesComboMeioPagamento.push({label: element.descricao, value: element.descricao});
      });
    },
      error => this.httpService.handleError(error, () => this.getComboOpcoesMeioPagamento()),
      () => this.httpService.done());
}

showModalContrato(){
  this.modalContrato = true;

}

editarCentroCusto(){}

anexoChange(event: any){}

onFilter(event: any) {


}

}
