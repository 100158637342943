

export class TbIndRelacao {
  id: number;
  cod: string;
  descricao: string;

  constructor(tbIndRelacao?: TbIndRelacao | number) {
    if (tbIndRelacao !== undefined && tbIndRelacao !== null) {
      if (typeof tbIndRelacao === 'number') {
        this.id = tbIndRelacao;
      } else {
        this.id = tbIndRelacao.id;
        this.cod = tbIndRelacao.cod;
        this.descricao = tbIndRelacao.descricao;

      }
    }
  }
}
