import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { Signatarios } from 'app/cadastro/model/signatarios.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Empresa } from 'app/cadastro/model/empresa.model';

import { Toolbar } from 'app/shared/model/toolbar.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-signatarios',
  templateUrl: './signatarios.component.html',
  styleUrls: ['./signatarios.component.scss'],
  providers: [CrudService]
})
export class SignatariosComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public signatarios: Signatarios = new Signatarios();
  public registrosSignatarios: Array<Signatarios> = new Array();

  private sessionSubscription: Subscription;
  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeEditSubscription: Subscription;
  ctrlSave: boolean;
  entityFilter: string = null;
  editarConta: boolean;
  novaConta: boolean;
  ids: Array<any> = new Array();
  selectSig: Array<any> = new Array();

  title = 'Signatários';
  subtitle = "Signatários";

  nomeSignatario: string;
  cpfCnpj: string;
  email: string;
  fone: string;
  numeroDoCrc: string;
  ufCrc: string;
  numeroSequencialCrc: string;
  dataValCrc: string;
  identRespLegal: string;
  ecfIdentQualifOp: SelectItem[];

  selectedEcfIdentQualif: any;

  opcoes: SelectItem[] =  [{ label: 'Sim', value: true }, { label: 'Não', value: false }];

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private configService: ConfigService,
    private messageService: MessageService,
    private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  public toolbarSig = new Toolbar()

  toolbarMainSig = [
    { key: "atualizar", icon: 'replay', text: 'Atualizar', tooltip: 'Atualizar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: "voltar", icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: "edit", icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { key: "delete", icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { key: "add", icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { key: "saveNew", icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { key: "save", icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.toolbarService.hidden = true
    this.toolbarSig.setAll(this.toolbarMainSig)
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.carregar()
        }
      }
    );
  }

  carregar() {
    this.title = 'SIGNATÁRIOS';
    this.subtitle = 'SIGNATÁRIOS';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.selectSig = new Array()

    this.toolbarInitial()

    this.limpaCamposForm();

    this.loadRegistros();
  }

  toolbarInitial() {
    this.toolbarSig.setVisible('atualizar', true)
    this.toolbarSig.setVisible('voltar', false)
    this.toolbarSig.setVisible('edit', false)
    this.toolbarSig.setVisible('delete', false)
    this.toolbarSig.setVisible('add', true)
    this.toolbarSig.setVisible('saveNew', false)
    this.toolbarSig.setVisible('save', false)
  }

  toolbarNew() {
    this.toolbarSig.setVisible('atualizar', false)
    this.toolbarSig.setVisible('voltar', true)
    this.toolbarSig.setVisible('edit', false)
    this.toolbarSig.setVisible('delete', false)
    this.toolbarSig.setVisible('add', false)
    this.toolbarSig.setVisible('saveNew', true)
    this.toolbarSig.setVisible('save', false)
  }

  toolbarEdit() {
    this.toolbarSig.setVisible('atualizar', false)
    this.toolbarSig.setVisible('voltar', true)
    this.toolbarSig.setVisible('edit', false)
    this.toolbarSig.setVisible('delete', true)
    this.toolbarSig.setVisible('add', false)
    this.toolbarSig.setVisible('saveNew', false)
    this.toolbarSig.setVisible('save', true)
  }

  criarNovo() {
    this.novaConta = true;
    this.title = 'Cadastrar';

    this.toolbarNew()

    this.loadEcf();
  }

  loadEcf() {
    this.ecfIdentQualifOp = new Array();
    this.httpService.wait()
    this.httpService.get(`ecfidentqualif?$select=id,cod,descricao&$orderby=cod,descricao`)
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.ecfIdentQualifOp.push({
            label: `${element.cod} - ${element.descricao}`,
            value: element.id
          });
          this.httpService.done()
        });
      }, error => this.httpService.handleError(error, () => this.httpService.done()));
  }

  loadRegistros() {
    this.httpService.wait()
    this.httpService
    .get(`/signatarios?$select=identNom,identCpf,email,indCrc,ufCrc,dtCrc,indRespLegal,ecfIdentQualif/descricao&$filter=empresa/id eq ${this.dblinkedSessionService.empresa.id}&$orderby=identNom`)
      .subscribe(
        retorno => {
          this.registrosSignatarios = new Array();
          retorno.value.forEach(item => {
            this.registrosSignatarios.push({
              ...item,
              dataCrc: this.convertDate(item.dtCrc),
            });
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar';
    this.editarConta = true;
    this.selectSig = []

    this.toolbarEdit()

    this.limpaCamposForm();
    this.loadEcf();

    this.httpService.wait();
    this.httpService.get(`/signatarios(${this.ids[0]})?$select=identNom,identCpf,email,fone,indCrc,ufCrc,numSeqCrc,dtCrc,indRespLegal,ecfIdentQualif/id,ecfIdentQualif/cod,ecfIdentQualif/descricao&$count=true`)
      .subscribe(item => {
        this.selectedEcfIdentQualif = new Signatarios()
        item.value.ecfIdentQualif ? this.selectedEcfIdentQualif = item.value.ecfIdentQualif.id : this.selectedEcfIdentQualif = null
        this.nomeSignatario = item.value.identNom;
        this.cpfCnpj = item.value.identCpf;
        this.email = item.value.email;
        this.fone = item.value.fone;
        this.numeroDoCrc = item.value.indCrc;
        this.ufCrc = item.value.ufCrc;
        this.dataValCrc = this.dateDay(item.value.dtCrc);
        this.dataValCrc = this.convertDate(this.dataValCrc);
        this.identRespLegal = item.value.indRespLegal;
        this.numeroSequencialCrc = item.value.numSeqCrc;
      },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      identNom: this.nomeSignatario,
      identCpf: this.cpfCnpj,
      email: this.email,
      fone: this.fone,
      indCrc: this.numeroDoCrc,
      ufCrc: this.ufCrc,
      numSeqCrc: this.numeroSequencialCrc,
      dtCrc: this.dataValCrc,
      indRespLegal: this.identRespLegal,
      ecfIdentQualif: {
        id: this.selectedEcfIdentQualif.toString()
      },
      empresa: {
        id: this.dblinkedSessionService.empresa.id
      },
    };

    this.httpService.wait();
    this.httpService.post('/signatarios', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          console.log(jsonData)
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonEnviar = {
      identNom: this.nomeSignatario,
      identCpf: this.cpfCnpj,
      email: this.email,
      fone: this.fone,
      indCrc: this.numeroDoCrc,
      ufCrc: this.ufCrc,
      numSeqCrc: this.numeroSequencialCrc,
      dtCrc: this.converteDataParaFullText(this.dataValCrc),
      indRespLegal: this.identRespLegal,
      ecfIdentQualif: {
        id: this.selectedEcfIdentQualif.toString()
      }
    };

    this.formataJsonEnvio(jsonEnviar);

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/signatarios', key, jsonEnviar, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Registro alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do registro!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }


  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      this.httpService.wait();
      this.httpService.delete('/signatarios', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Registro excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());
    }
  }


  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
  }

  changeToolbar() {
    this.toolbarSig.setVisible('edit', true)
    this.toolbarSig.setVisible('delete', true)
  }
  changeToolbarUn() {
    this.toolbarSig.setVisible('edit', false)
    this.toolbarSig.setVisible('delete', false)
  }

  onRowSelect(event: any) {
    this.ids = new Array()
    this.ids.push(event.data.id);
    event.selected = false;
    this.changeToolbar()
  }

  onRowUnselect(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.changeToolbarUn()
  }

  formataJsonEnvio(data) {
    if (this.selectedEcfIdentQualif.id === null || this.selectedEcfIdentQualif.id === 0) {
      delete data['ecfIdentQualif']
    }
  }

  validacao(): boolean {
    if ((this.nomeSignatario === null || this.nomeSignatario === undefined) || this.nomeSignatario.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'NOME SIGNATÁRIO É OBRIGATÓRIO' });
      return true;
    }
    if ((this.cpfCnpj === null || this.cpfCnpj === undefined) || this.cpfCnpj.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'CPF/CNPJ É OBRIGATÓRIO' });
      return true;
    }
    if ((this.email === null || this.email === undefined)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'EMAIL É OBRIGATÓRIO' });
      return true;
    }
    if ((this.fone === null || this.fone === undefined)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'FONE É OBRIGATÓRIO' });
      return true;
    }
    if ((this.selectedEcfIdentQualif === null || this.selectedEcfIdentQualif === undefined) || this.selectedEcfIdentQualif.lenght <= 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'ECF IDENT QUALIF É OBRIGATÓRIO' });
      return true;
    }
    return false;
  }

  convertDate(milisegundos: string) {
    if (milisegundos == '' || milisegundos == null) {
      return null
    }

    const d = new Date(milisegundos);

    return this.utilService.dateToStringDate(d);
  }

  dateDay = (milisegundos) => {
    if (milisegundos == '' || milisegundos == null) {
      return null
    }
    return milisegundos + 86400000
  };

  private converteDataParaFullText(data) {
    if (data == '' || data == null) {
      return null
    }
    let novoFormato;

    if (typeof data === 'object') {//GMT... (recebe GMT quando usuário altera o campo)
      novoFormato = new Date(data);
    } else {// dd/mm/YYYY (recebe brasileiro quando campo não foi alterado)
      let [dia, mes, ano] = data.split('/');
      novoFormato = new Date(ano, mes - 1, dia);
    }

    return novoFormato;
  }

  private limpaCamposForm() {
    this.nomeSignatario = null;
    this.cpfCnpj = null;
    this.email = null;
    this.fone = null;
    this.numeroDoCrc = null;
    this.ufCrc = null;
    this.dataValCrc = null;
    this.identRespLegal = null;
    this.numeroSequencialCrc = null;
    this.selectedEcfIdentQualif = null;
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
