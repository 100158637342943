import { Empresa } from "app/cadastro/model/empresa.model";
import { AuthUser } from "app/proof/model/auth-user.model";
import { isNullOrUndefined } from "util";

export class Vendedor {
  id: number;
  empresa: Empresa;
  nome: string;
  cadnovovendedor: boolean;
  changeUser: AuthUser;
  codigo: string;
  email: string;
  telefone: string;
  cpf: string;
  visualizaOutrosVendedores: boolean;
  ativo: boolean = true;
  dtAdmissao: Date|string;
  valorFixo: number;

  constructor(vendedor?: Vendedor | number) {
    if (!isNullOrUndefined(vendedor)) {
      if (typeof vendedor === 'number') {
        this.id = vendedor;
      } else {
        this.id = vendedor.id;
        this.empresa = vendedor.empresa;
        this.nome = vendedor.nome;
        this.cadnovovendedor = vendedor.cadnovovendedor;
        this.changeUser = vendedor.changeUser;
        this.codigo = vendedor.codigo;
        this.email = vendedor.email;
        this.telefone = vendedor.telefone;
        this.cpf = vendedor.cpf;
        this.visualizaOutrosVendedores = vendedor.visualizaOutrosVendedores;
        this.ativo = vendedor.ativo;
        this.dtAdmissao = vendedor.dtAdmissao;
        this.valorFixo = vendedor.valorFixo;
      }
    }
  }
}
