import { Component, OnInit } from '@angular/core';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { SessionService } from 'app/shared/services/session.service';
import { SidebarService } from 'app/shared/services/sidebar.service';
import { Action } from 'app/shared/services/socket/protocol/Action';
import { Method } from 'app/shared/services/socket/protocol/Method';
import { Service } from 'app/shared/services/socket/protocol/Service';
import { PysocketService } from 'app/shared/services/socket/pysocket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  private messagesSubscription: Subscription
  private sessionSubscription: Subscription

  audio = new Audio()

  contentFilter: String

  notificationList: Array<any> = new Array()
  cloneNotificationList: Array<any> = new Array()
  offSet: number = 0
  limit: number = 50
  showMoreEnable: boolean = true

  constructor(
    private socketService: PysocketService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private sidebarService: SidebarService
  ) { }

  ngOnInit(): void {
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) this.socketService.socketState.subscribe(state => { if(state) this.initialize() })
    })

    this.messagesSubscription = this.socketService.socketData.subscribe((response) => {
      if (response.service === Service.NOTIFICATION && response.action !== Action.LOADING) this.onNotifications(response)
    })
  }

  initialize(): void {
    this.getNotifications()
    this.audio.src = '../../../../assets/sounds/octa-notification.ogg'
  }

  getNotifications(): void {
    const payload = {
      service: Service.NOTIFICATION,
      action: Action.HISTORIC,
      method: Method.GET,
      content: {
        userId: this.sessionService.loggedUser.id,
        lazyload: {
          off_set: this.offSet,
          limit: this.limit
        }
      }
    }

    this.socketService.send(payload)
  }

  updateNotifications(data: Array<any>): void {
    data.forEach(item => {
      const payload = {
        service: Service.NOTIFICATION,
        action: Action.HISTORIC,
        method: Method.PUT,
        content: {
          id: item.id
        }
      }

      this.socketService.send(payload)
    })
  }

  updateNotification(data): void {
    const payload = {
      service: Service.NOTIFICATION,
      action: Action.HISTORIC,
      method: Method.PUT,
      content: {
        id: data.id
      }
    }

    this.socketService.send(payload)

    this.sidebarService.rowSelected([data.relationship_id])
    this.sidebarService.open(true)

    if(data.notification_type === '1') this.sidebarService.selectTab(1)
  }

  deleteNotifications(data: Array<any>): void {
    if(confirm('Todas as notificações atuais serão removidas!')) {
      data.forEach(item => {
        const payload = {
          service: Service.NOTIFICATION,
          action: Action.HISTORIC,
          method: Method.DELETE,
          content: {
            id: item.id,
          }
        }

        this.socketService.send(payload)
      })
    }
  }

  onNotifications(data: any): void {
    switch (data.method) {
      case Method.GET:
        if(data.content["more_notifications"].length === 0 || data.content["more_notifications"].length < this.limit){
          this.showMoreEnable = false;
        }
        if(this.offSet === 0){
          this.notificationList = new Array()
          this.notificationList = data.content["more_notifications"]
        }else{
          this.notificationList = [...this.notificationList, ...data.content["more_notifications"]]
        }

      break
      case Method.POST:
        this.notificationList.unshift(data.content)
        this.audio.play()
      break
      case Method.PUT:
        this.notificationList.forEach(item => {
          if(item.id === data.content.id) {
            item.read = true
          }
        })
      break
      case Method.DELETE:
        this.notificationList = []
      break
    }

    this.cloneNotificationList = this.notificationList
  }

  notificationsCount(): boolean {
    let aux = 0
    this.notificationList.forEach(item => {
      if(item.read === false) {
        aux++
      }
    })

    if(aux > 0) return true
    else return false
  }

  onFilter(): void {
    if (this.contentFilter) this.notificationList = this.cloneNotificationList.filter(line => line.message.toLowerCase().normalize("NFD").includes(this.contentFilter.toLowerCase().normalize("NFD")) || line.sender_name.toLowerCase().normalize("NFD").includes(this.contentFilter.toLowerCase().normalize("NFD")))
    else this.notificationList = this.cloneNotificationList
  }

  isUser(userName: string) {
    const splitedName: Array<string> = userName.split(" ")
    let displayLabel: string = ''

    if(splitedName.length === 2) displayLabel = `${splitedName[0][0]}${splitedName[1][0] ?? ''}`
    else if(splitedName.length === 1) displayLabel = `${splitedName[0][0] ?? '-'}`
    else displayLabel = '-'

    return displayLabel
  }

  convertDate(dateTime): String {
    const date = new Date(dateTime)
    const today = new Date()

    const fullDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    const fullToday = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
    const yesterday = (today.getDate() - 1) + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()

    if (fullDate === fullToday) return 'Hoje às ' + date.getHours() + 'hr' + date.getMinutes()
    else if (fullDate === yesterday) return 'Ontem às ' + date.getHours() + 'hr' + date.getMinutes()
    else return fullDate + ' às ' + date.getHours() + 'hr' + date.getMinutes()
  }

  showMore(){
    this.offSet += this.limit;
    this.getNotifications();
  }

}
