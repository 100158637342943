
import { Pedido } from 'app/financeiro/model/pedido.model';
import { OperacaoRegra } from 'app/cadastro/model/operacao-regra.model';
import { Item } from 'app/cadastro/model/item.model';
import { ItemPrecoVenda } from 'app/cadastro/model/item-preco-venda.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { TipoDesconto } from 'app/util/model/tipo-desconto.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadProj } from 'app/contabil/model/cad-proj.model';
import { Nfe } from 'app/financeiro/model/nfe.model';
import { CstIpi } from '../../util/model/cst-ipi.model';
import { CstIcms } from '../../util/model/cst-icms.model';
import { CstPiscofins } from '../../util/model/cst-piscofins.model';
import { StatusEscrituracao } from '../../util/model/status-escrituracao.model';

export class EscrituracaoNfEntrada {
  id: number;
  pedido: Pedido;
  numPed: string;
  nfe: Nfe;
  numDoc: string;
  codMod: string;
  codPart: string;
  codItem: string;
  dtDoc: Date;
  dtEs: Date;
  ncm: string;
  movEstoque: boolean;
  codOper: string;
  cfop: string;
  qtde: any;
  vlUnit: any;
  vlDesc: any;
  vlProd: any;
  vlFrete: any;
  vlSeguro: any;
  vlOutro: any;
  cstIcms: string;
  vlRedBcIcms: any;
  vlBcIcms: any;
  aliqIcms: any;
  vlIcms: any;
  cstIpi: string;
  vlBcIpi: any;
  aliqIpi: any;
  vlIpi: any;
  vlBcIcmsSt: any;
  aliqIcmsSt: any;
  vlIcmsSt: any;
  cstPis: string;
  vlBcPis: any;
  aliqPis: any;
  vlPis: any;
  vlBcPisRet: any;
  vlPisRet: any;
  cstCofins: string;
  vlBcCofins: any;
  aliqCofins: any;
  vlCofins: any;
  vlBcCofinsRet: any;
  vlCofinsRet: any;
  vlDespAduImport: any;
  vlIof: any;
  vlBcIi: any;
  aliqIi: any;
  vlIi: any;
  vlInssRet: any;
  vlIrrf: any;
  vlCsllRet: any;
  vlBcIss: any;
  aliqIss: any;
  vlIss: any;
  vlIssRet: any;
  vlOutrasRet: any;
  vlFcp: any;
  vlTot: any;
  dadosAdicionais: string;
  chvNfe: string;
  statusEscrituracao: StatusEscrituracao;
  modBcIcms: string;

  constructor(escrituracaoNfEntrada?: EscrituracaoNfEntrada | number) {
    if (escrituracaoNfEntrada !== undefined && escrituracaoNfEntrada !== null) {
      if (typeof escrituracaoNfEntrada === 'number') {
        this.id = escrituracaoNfEntrada;
      } else {
        this.id = escrituracaoNfEntrada.id;
        this.pedido = escrituracaoNfEntrada.pedido;
        this.numPed = escrituracaoNfEntrada.numPed;
        this.nfe = escrituracaoNfEntrada.nfe;
        this.numDoc = escrituracaoNfEntrada.numDoc;
        this.codMod = escrituracaoNfEntrada.codMod;
        this.codPart = escrituracaoNfEntrada.codPart;
        this.codItem = escrituracaoNfEntrada.codItem;
        this.dtDoc = escrituracaoNfEntrada.dtDoc;
        this.dtEs = escrituracaoNfEntrada.dtEs;
        this.ncm = escrituracaoNfEntrada.ncm;
        this.movEstoque = escrituracaoNfEntrada.movEstoque;
        this.codOper = escrituracaoNfEntrada.codOper;
        this.cfop = escrituracaoNfEntrada.cfop;
        this.qtde = escrituracaoNfEntrada.qtde;
        this.vlUnit = escrituracaoNfEntrada.vlUnit;
        this.vlDesc = escrituracaoNfEntrada.vlDesc;
        this.vlProd = escrituracaoNfEntrada.vlProd;
        this.vlFrete = escrituracaoNfEntrada.vlFrete;
        this.vlSeguro = escrituracaoNfEntrada.vlSeguro;
        this.vlOutro = escrituracaoNfEntrada.vlOutro;
        this.cstIcms = escrituracaoNfEntrada.cstIcms;
        this.vlRedBcIcms = escrituracaoNfEntrada.vlRedBcIcms;
        this.vlBcIcms = escrituracaoNfEntrada.vlBcIcms;
        this.aliqIcms = escrituracaoNfEntrada.aliqIcms;
        this.vlIcms = escrituracaoNfEntrada.vlIcms;
        this.cstIpi = escrituracaoNfEntrada.cstIpi;
        this.vlBcIpi = escrituracaoNfEntrada.vlBcIpi;
        this.aliqIpi = escrituracaoNfEntrada.aliqIpi;
        this.vlIpi = escrituracaoNfEntrada.vlIpi;
        this.vlBcIcmsSt = escrituracaoNfEntrada.vlBcIcmsSt;
        this.aliqIcmsSt = escrituracaoNfEntrada.aliqIcmsSt;
        this.vlIcmsSt = escrituracaoNfEntrada.vlIcmsSt;
        this.cstPis = escrituracaoNfEntrada.cstPis;
        this.vlBcPis = escrituracaoNfEntrada.vlBcPis;
        this.aliqPis = escrituracaoNfEntrada.aliqPis;
        this.vlPis = escrituracaoNfEntrada.vlPis;
        this.vlBcPisRet = escrituracaoNfEntrada.vlBcPisRet;
        this.vlPisRet = escrituracaoNfEntrada.vlPisRet;
        this.cstCofins = escrituracaoNfEntrada.cstCofins;
        this.vlBcCofins = escrituracaoNfEntrada.vlBcCofins;
        this.aliqCofins = escrituracaoNfEntrada.aliqCofins;
        this.vlCofins = escrituracaoNfEntrada.vlCofins;
        this.vlBcCofinsRet = escrituracaoNfEntrada.vlBcCofinsRet;
        this.vlCofinsRet = escrituracaoNfEntrada.vlCofinsRet;
        this.vlDespAduImport = escrituracaoNfEntrada.vlDespAduImport;
        this.vlIof = escrituracaoNfEntrada.vlIof;
        this.vlBcIi = escrituracaoNfEntrada.vlBcIi;
        this.aliqIi = escrituracaoNfEntrada.aliqIi;
        this.vlIi = escrituracaoNfEntrada.vlIi;
        this.vlInssRet = escrituracaoNfEntrada.vlInssRet;
        this.vlIrrf = escrituracaoNfEntrada.vlIrrf;
        this.vlCsllRet = escrituracaoNfEntrada.vlCsllRet;
        this.vlBcIss = escrituracaoNfEntrada.vlBcIss;
        this.aliqIss = escrituracaoNfEntrada.aliqIss;
        this.vlIss = escrituracaoNfEntrada.vlIss;
        this.vlIssRet = escrituracaoNfEntrada.vlIssRet;
        this.vlOutrasRet = escrituracaoNfEntrada.vlOutrasRet;
        this.vlFcp = escrituracaoNfEntrada.vlFcp;
        this.vlTot = escrituracaoNfEntrada.vlTot;
        this.dadosAdicionais = escrituracaoNfEntrada.dadosAdicionais;
        this.chvNfe = escrituracaoNfEntrada.chvNfe;
        this.statusEscrituracao = escrituracaoNfEntrada.statusEscrituracao;
        this.modBcIcms = escrituracaoNfEntrada.modBcIcms;
      }
    }
  }
}
