import { Component, Input } from '@angular/core'
import { LineData } from '../interfaces/dash-big-number'

@Component({
  selector: 'app-dash-big-number[type][isLoading]',
  templateUrl: './dash-big-number.component.html',
  styleUrls: ['./dash-big-number.component.scss']
})
export class DashBigNumberComponent {
  @Input() type: 'line' | 'big'
  @Input() lineData: LineData
  @Input() isLoading: boolean

  sortDirection: boolean = false
  sortIcon: string = null

  public sortData(): void {
    if(this.type === 'line') {
      this.sortDirection = !this.sortDirection

      let all = {
        value: this.lineData.lines.filter(line => line.title === 'Todas as contas')[0].value,
        title: this.lineData.lines.filter(line => line.title === 'Todas as contas')[0].title
      }

      this.lineData.lines = this.lineData.lines.filter(line => line.title !== 'Todas as contas').sort((a, b) => { return ((this.sortDirection) ? (a.value - b.value) : (b.value - a.value)) })
      this.lineData.lines.unshift(all)

      this.sortIcon =  this.sortDirection ? 'keyboard_double_arrow_down' : 'keyboard_double_arrow_up'
    }
  }
}
