import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GrupoEstoque } from 'app/estoque/model/grupo-estoque.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { CestNcmSegmento } from 'app/util/model/cest-ncm-segmento.model';
import { CodServicoTelecomunicacao } from 'app/util/model/cod-servico-telecomunicacao.model';
import { ItemTipo } from 'app/util/model/item-tipo.model';
import { Lst } from 'app/util/model/lst.model';
import { Nbs } from 'app/util/model/nbs.model';
import { Operacao } from 'app/util/model/operacao.model';
import { Tipi } from 'app/util/model/tipi.model';
import { TipoUtilizacao } from 'app/util/model/tipo-utilizacao.model';
import { UnidadeMedida } from 'app/util/model/unidade-medida.model';
import deepCopy from 'app/util/processing/deepcopy';
import { LazyLoadEvent, Message, MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { EmpresaCnae } from '../model/empresa-cnae.model';
import { Empresa } from '../model/empresa.model';
import { Item } from '../model/item.model';
import LogManagement from 'app/shared/common/log-management/log-control';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';


@Component({
  selector: 'app-item-ref',
  templateUrl: './item-ref.component.html',
  styleUrls: [
    './item-ref.component.scss'
  ]
})
export class ItemRefComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription

  item: Item

  lista: Array<Item> = new Array()
  selectedItens: Array<Item> = new Array()
  catalogosId: Array<string> = new Array()
  grupoProdutosId: Array<string> = new Array()
  msgs: Message[] = new Array()
  grupoEstoqueList: SelectItem[] = new Array()
  codCestList: SelectItem[] = new Array()
  lstList: SelectItem[] = new Array()
  cnaeList: SelectItem[] = new Array()
  nbsList: SelectItem[] = new Array()
  ncmList: SelectItem[] = new Array()
  operacaoList: SelectItem[] = new Array()
  tipiList: SelectItem[] = new Array()
  tipoList: SelectItem[] = new Array()
  unList: SelectItem[] = new Array()
  tipoUtilizacaoList: SelectItem[] = new Array()
  codServicoTelecomunicacaoList: SelectItem[] = new Array()
  preCadTable: SelectItem[] = new Array()
  olhaEstoqueTable: SelectItem[] = new Array()
  activeOptions: SelectItem[] = new Array()
  grupoProdutoList: SelectItem[] = new Array()
  catalogosList: SelectItem[] = new Array()
  optItens: SelectItem[] = []
  origemList: SelectItem[] = new Array();
  codigoRendimentoList: SelectItem[] = new Array()
  categoriaList: SelectItem[] = new Array()

  tipi: number = null
  operacao: number = null
  codigoRendimento: number = null
  categoria: number = null
  ncm: number = null
  nbs: number = null
  cnae: number = null
  lst: number = null
  codCest: number = null
  codServicoTelecomunicacao: number = null
  grupoEstoque: number = null
  tipo: number = null
  tipoUtilizacao: number = null
  un: number = null
  file: File = null
  filename: string = null
  itemDescription: string = ""
  itemPartNumber: string = ""
  origem: number = null

  ativo: boolean = true

  showLista: boolean = true
  showNew: boolean = false
  showEdit: boolean = false
  showInactives: boolean = false
  modalUploadItens: boolean = false
  ctrAliquotaIcms: boolean = false
  ctrCest: boolean = false
  ctrEan13: boolean = false
  ctrLst: boolean = false
  ctrNbs: boolean = false
  ctrNcm: boolean = false
  ctrOperacao: boolean = false
  ctrTipi: boolean = false
  ctrUn: boolean = false
  ctrVerificaEstoque: boolean = false
  ctrCnae: boolean = false
  validarNcm: boolean = false
  valEstoque: boolean = false
  modalBatchEdit: boolean = false
  showMoreOptions: boolean = true
  modalClone: boolean = false
  cloneInOwnCompany: boolean = false
  eyeMobileUser: boolean = false

  reqItemId: number
  estoqueMin: number
  estoqueMax: number
  totalRecords: number = 0
  lotCostValue: number = null
  lotSaleValue: number = null
  minimumLotStock: number = null
  tipoLayoutImportacao: number = null
  itemIdConversao: number = null

  defaultDate: Date

  registerGroupCompanies: boolean = false
  modalGroup: boolean = false
  informationValid: boolean = false
  companiesGroup: SelectItem[] = new Array()
  selectedCompaniesGroup: Array<any> = new Array()

  modalGroupDataTitle: string = 'Criação de itens em várias empresas'
  modalGroupDataLabel: string = 'Em quais outras empresas você deseja criar este item?'
  placeholderSelectedCompanies: string = 'Selecione a(s) empresa(s)'

  companyCanMakeChanges: boolean = false;
  modalConversao: boolean = false;
  fatorConversao: number = null;
  fatorId: number = 0;
  unidadeMedidaConversaoId: number = 0;
  unidadeMedidaCodigo: String;
  editConversao: boolean = false;
  opcoesUnidadeMedida: SelectItem[];
  fatoresConversao: Array<any> = new Array();

  listLayout: SelectItem[] = [
    { label: "Cadastro de item", value: 0 },
    { label: "Cadastro de fator de conversão (EyeMobile)", value: 1 },
  ]

  rowFator: any = {
    fatorConversao: 0,
    unidadeMedida: null,
    itemEyeId: null,
    id: null,
    codigoUnidadeMedida: null,
    itemEye: null,
  }

  private LogManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  @ViewChild('file') fileInput: ElementRef

  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    {
      key: 'btn-clone-expanded', icon: 'content_copy', text: '', tooltip: 'Clonar', visible: false, disabled: false, color: 'default', dropdown: true,
      options: [
        { label: 'Clonar no grupo de empresas', callback: () => this.setModalClone(true, false) },
        { optionKey: 'btn-unique-clone', label: 'Clonar somente na empresa atual', callback: () => this.setModalClone(true, true) },
      ]
    },
    { key: 'btn-navigate', icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.resetVars(false) },
    { key: 'btn-refresh', icon: 'refresh', text: 'Atualizar', tooltip: 'Atualizar', visible: true, disabled: false, color: 'default', onClick: () => this.resetVars(true) },
    { key: 'btn-export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: false, disabled: false, color: 'default', onClick: () => this.exportItems() },
    { key: 'btn-upload', icon: 'cloud_upload', text: 'Importar', tooltip: 'Importar', visible: true, disabled: false, color: 'default', onClick: () => this.showModalImportItens() },
    { key: 'btn-edit-batch', icon: 'edit', text: 'Editar', tooltip: 'Editar em lote', visible: false, disabled: false, color: 'default', onClick: () => this.openingModalBatchEdit() },
    { key: 'btn-edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => { } },
    { key: 'btn-clone', icon: 'content_copy', text: 'Clonar', tooltip: 'Clonar', visible: false, disabled: false, color: 'default', onClick: () => this.setModalClone(true, true) },
    { key: 'btn-delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.excluir() },
    { key: 'btn-add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.criarNovo() },
    { key: 'btn-save', icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: false, disabled: false, color: 'green', onClick: () => this.determineMethodToSave() },
  ]

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'CÓDIGO', textAlign: 'left' },
      { name: 'PART NUMBER', textAlign: 'left' },
      { name: 'DESCRIÇÃO', textAlign: 'left', size: '20%' },
      { name: 'DESCRIÇÃO INGLÊS', textAlign: 'left' },
      { name: 'UN', textAlign: 'left' },
      { name: 'NCM', textAlign: 'left' },
      { name: 'ATIVO', textAlign: 'left' },
      { name: 'PRÉ CAD', textAlign: 'left' },
      { name: 'MOV ESTOQUE', textAlign: 'left' },
      { name: 'ESTOQUE MINÍMO', textAlign: 'left' },
      { name: 'VALOR CUSTO', textAlign: 'right' },
      { name: 'VALOR VENDA', textAlign: 'right' },
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true
  })

  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private messageService: MessageService
  ) { this.toolbarService.hidden = true, this.dblinkedSessionService.hidePeriods() }

  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) this.initialize()
    })
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  private async initialize() {
    await this.checkIfCompanyCanMakeChanges()
    this.loadCapituloTipi()
    this.loadCodCest()
    this.loadItemTipo()
    this.loadLst()
    this.loadGrupoEstoqueList()
    this.loadNbs()
    this.loadCodigoRendimento();
    this.loadCategoriaItem();
    this.loadOperacao()
    this.loadUN()
    this.loadTipoUtilizacao()
    this.loadCodServicoComunicacao()
    this.loadCnaeLst()
    this.loadValidaEstoque()
    this.resetVars(true)
    this.eyeMobileUser = false
    this.checkEyeMobileUser()

    this.preCadTable = [
      { label: 'PENDENTE', value: false },
      { label: '', value: true }
    ]

    this.olhaEstoqueTable = [
      { label: 'SIM', value: true },
      { label: 'NÃO', value: false }
    ]

    this.activeOptions = [
      { label: 'ATIVO', value: true },
      { label: 'INATIVO', value: false }
    ]

    this.getRegisterGroupCompanies()
  }

  private resetVars(reload: boolean): void {
    if (reload) this.loadItens()

    this.item = new Item()
    this.defaultDate = new Date()

    this.validarNcm = false
    this.showLista = true
    this.showEdit = false
    this.showNew = false
    this.validarNcm = false
    this.modalUploadItens = false
    this.showMoreOptions = true
    this.modalClone = false
    this.cloneInOwnCompany = false

    this.item.descricao = null
    this.item.dtInicial = null
    this.codCest = null
    this.lst = null
    this.nbs = null
    this.ncm = null
    this.operacao = null
    this.codigoRendimento = null
    this.categoria = null
    this.origem = 0
    this.tipi = null
    this.tipo = null
    this.un = null
    this.cnae = null
    this.grupoEstoque = null
    this.grupoProdutosId = new Array()
    this.catalogosId = new Array()

    this.estoqueMin = 0
    this.estoqueMax = 0

    this.itemDescription = ""
    this.itemPartNumber = ""

    // this.ativo = true
    this.modalGroup = false

    this.changeToolbar()

    this.informationValid = false
    this.placeholderSelectedCompanies = 'Selecione a(s) empresa(s)'
  }

  getNCM(rowValue: any) {
    const id = rowValue.tipi !== null ? rowValue.tipi.id : null;

    if (id !== null) {
      this.httpService
        .get('/tipi?$select=id,ncm,descricao&$filter=id eq ' + id)
        .subscribe(
          rst => {
            this.ncmList = new Array()
            rst.value.forEach(obj => {
              this.ncmList.push({
                label: obj.ncm + ' - ' + obj.descricao,
                value: obj.id
              });
            });

            this.ncm = id;
          },
          error =>
            this.httpService.handleError(error, () => this.getNCM(rowValue)),
          () => this.httpService.done()
        );
    } else {
      this.ncm = null;
    }
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  private loadCapituloTipi() {
    this.httpService.get('/custom/financeiro/capitulos-tipi').subscribe(
      rst => {
        this.tipiList = new Array()
        rst.forEach(obj => {
          this.tipiList.push({
            label: obj.ncm + ' - ' + obj.descricao,
            value: obj.id
          })
        })
      },
      error =>
        this.httpService.handleError(error, () => this.loadCapituloTipi()),
      () => this.httpService.done()
    )
  }

  private loadCodCest() {
    this.httpService.get('/cestncmsegmento?$select=id,cest/id,cest/codigo,cest/descricao&$orderby=cest/codigo').subscribe(
      rst => {
        this.codCestList = new Array()
        rst.value.forEach(obj => {
          if (obj.cest !== null) {
            this.codCestList.push({
              label: obj.cest.codigo + ' - ' + obj.cest.descricao,
              value: obj.id
            })
          }
        })
      },
      error => this.httpService.handleError(error, () => this.loadCodCest()),
      () => this.httpService.done()
    )
  }

  private loadItemTipo() {
    this.httpService.get('/itemtipo?$select=id,codigo,descricao&$orderby=codigo').subscribe(
      rst => {
        this.tipoList = new Array()
        rst.value.forEach(obj => {
          this.tipoList.push({
            label: obj.codigo + ' - ' + obj.descricao,
            value: obj.id
          })
        })
      },
      error => this.httpService.handleError(error, () => this.loadItemTipo()),
      () => this.httpService.done()
    )
  }

  public async loadItens(event: LazyLoadEvent = {}): Promise<void> {
    const payload = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      limit: event.rows,
      offset: event.first,
      sort_order: event.sortOrder,
      sort_field: event.sortField,
      filters: event.filters,
      showInactives: this.showInactives,
      usaEyeMobile: this.eyeMobileUser
    }

    this.reqItemId = 0
    this.totalRecords = 0
    this.selectedItens = new Array()
    this.changeToolbar()
    this.skeletonConfig.startLoad()

    try {
      await this.httpService.post('/custom/cadastro/get-cad-item', payload).toPromise().then(res => {
        if (!res.error) {
          this.lista = res.data.map((item => {
            item.preCadastro = item.cadastrado ? '' : 'PENDENTE'
            item.olhaEstoque = item.verificaEstoque ? 'SIM' : 'NÃO'
            return item
          }))

          this.totalRecords = res.total
        } else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: res.message })
      }).catch(err => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: err.status + ' - Houve um erro ao carregar os itens!' }))
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

  private loadCnaeLst() {
    this.httpService.get('/empresacnae?$select=id,cnae,descricao&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=cnae').subscribe(
      rst => {
        this.cnaeList = [{ label: 'Selecione um cnae', value: null }]
        rst.value.forEach(obj => {
          this.cnaeList.push({
            label: obj.cnae + ' - ' + obj.descricao,
            value: obj.id
          })
        })
      },
      error => this.httpService.handleError(error, () => this.loadCnaeLst()),
      () => this.httpService.done()
    )
  }

  private loadGrupoEstoqueList() {
    this.httpService.get('/grupoestoque?$select=id,codigo,descricao&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id + '&$orderby=codigo').subscribe(
      rst => {
        this.grupoEstoqueList = [{ label: 'Selecione um Grupo de Estoque', value: null }]
        rst.value.forEach(obj => {
          this.grupoEstoqueList.push({
            label: obj.codigo + ' - ' + obj.descricao,
            value: obj.id
          })
        })
      },
      error => this.httpService.handleError(error, () => this.loadGrupoEstoqueList()),
      () => this.httpService.done()
    )
  }

  private loadLst() {
    this.httpService.get('/lst?$select=id,codigo,descricao&$orderby=codigo,descricao').subscribe(
      rst => {
        this.lstList = new Array()
        rst.value.forEach(obj => {
          if (obj.cest !== null) {
            this.lstList.push({
              label: obj.codigo + ' - ' + obj.descricao,
              value: obj.id
            })
          }
        })
      },
      error => this.httpService.handleError(error, () => this.loadLst()),
      () => this.httpService.done()
    )
  }

  private loadNbs() {
    this.httpService.get('/nbs?$select=id,codigo,descricao&$orderby=codigo,descricao').subscribe(
      rst => {
        this.nbsList = new Array()
        rst.value.forEach(obj => {
          if (obj.cest !== null) {
            this.nbsList.push({
              label: obj.codigo + ' - ' + obj.descricao,
              value: obj.id
            })
          }
        })
      },
      error => this.httpService.handleError(error, () => this.loadNbs()),
      () => this.httpService.done()
    )
  }

  private loadCodigoRendimento() {
    this.httpService.get('/codigorendimento?$select=id,cod,descricao&$orderby=cod,descricao').subscribe(
      rst => {
        this.codigoRendimentoList = new Array()
        rst.value.forEach(obj => {
          if (obj.cest !== null) {
            this.codigoRendimentoList.push({
              label: obj.cod + ' - ' + obj.descricao,
              value: obj.id
            })
          }
        })
      },
      error => this.httpService.handleError(error, () => this.loadCodigoRendimento()),
      () => this.httpService.done()
    )
  }

  private loadCategoriaItem() {
    this.httpService.get('/categoriaitem?$select=id,descricao&$orderby=descricao').subscribe(
      rst => {
        this.categoriaList = new Array()
        rst.value.forEach(obj => {
          if (obj.cest !== null) {
            this.categoriaList.push({
              label: obj.descricao,
              value: obj.id
            })
          }
        })
      },
      error => this.httpService.handleError(error, () => this.loadCategoriaItem()),
      () => this.httpService.done()
    )
  }

  loadNCM() {
    this.httpService.post('/custom/cadastro/get-ncm', { idTipi: this.tipi }).subscribe(
      rst => {
        this.ncmList = new Array()
        rst.forEach(obj => {
          this.ncmList.push({
            label: obj.ncm + ' - ' + obj.descricao,
            value: obj.id
          })
        })
      },
      error => this.httpService.handleError(error, () => this.loadNCM()),
      () => this.httpService.done()
    )
  }

  private loadOperacao() {
    this.httpService.get('/operacao?$select=id,descricao&$orderby=descricao').subscribe(
      rst => {
        this.operacaoList = new Array()
        rst.value.forEach(obj => {
          if (obj.cest !== null) {
            this.operacaoList.push({
              label: obj.descricao,
              value: obj.id
            })
          }
        })

        this.origemList = new Array();

        this.origemList.push({
          label: 'Mercado Interno',
          value: 0
        });
        this.origemList.push({
          label: 'Mercado Externo',
          value: 1
        });
        this.origemList.push({
          label: 'Interno/Externo',
          value: 2
        });

      },
      error => this.httpService.handleError(error, () => this.loadOperacao()),
      () => this.httpService.done()
    )
  }

  private loadUN() {
    this.httpService.get('/unidademedida?$select=id,codigo&$filter=unidadePadrao eq true &$orderby=codigo').subscribe(
      rst => {
        this.unList = new Array();
        this.unList.push({
          label: 'SELECIONE A UNIDADE',
          value: 0,
        });

        rst.value.forEach(obj => {
          this.unList.push({
            label: obj.codigo,
            value: obj.id
          })
        })
      },
      error => this.httpService.handleError(error, () => this.loadUN()),
      () => this.httpService.done()
    )
  }

  private loadTipoUtilizacao() {
    this.httpService.get('/tipoutilizacao?$select=id,cod,descr&$orderby=cod').subscribe(
      rst => {
        this.tipoUtilizacaoList = new Array()
        rst.value.forEach(obj => {
          this.tipoUtilizacaoList.push({
            label: (obj.cod + '-' + obj.descr),
            value: obj.id
          })
        })
      },
      error => this.httpService.handleError(error, () => this.loadTipoUtilizacao()),
      () => this.httpService.done()
    )
  }

  private loadCodServicoComunicacao() {
    this.httpService.get('/codservicotelecomunicacao?$select=id,cod,descr&$orderby=cod').subscribe(
      rst => {
        this.codServicoTelecomunicacaoList = new Array()
        rst.value.forEach(obj => {
          this.codServicoTelecomunicacaoList.push({
            label: (obj.cod + '-' + obj.descr),
            value: obj.id
          })
        })
      },
      error => this.httpService.handleError(error, () => this.loadCodServicoComunicacao()),
      () => this.httpService.done()
    )
  }

  onInputDate(event: any) {
    let value = event.target.value.replace(/^(\d{1,2})\/?(\d{1,2})\/?(\d{1,4})/, '$1$2$3').split(/\D/)[0],
      response = '';

    if (value.slice(0, 2).length > 0) {
      response = value.slice(0, 2) <= 31 ? value.slice(0, 2) : ''
      if (value.slice(2, 4).length > 0) {
        response += '/' + (value.slice(2, 4) <= 12 ? value.slice(2, 4) : '')
        if (value.slice(4, 8).length > 0) {
          response += '/' + (value.slice(6, 7) >= 1 && value.slice(6, 7) <= 3 ? value.slice(4, 8) : value.slice(4, 6))
        }
      }
    }
    event.target.value = response
  }

  salvar(alreadyValidated: boolean = false) {
    this.informationValid = alreadyValidated
    if (!alreadyValidated) this.informationValid = this.validateData()

    if (this.informationValid) {
      if (this.item.ean13) {
        this.item.ean13 = (this.item.ean13.trim() ? this.item.ean13 : null)
      }

      if (this.showEdit) {
        const map = {
          idUser: this.sessionService.loggedUser.id,
          id: this.item.id,
          tipo: this.tipo,
          descricao: this.item.descricao,
          descriaoEn: this.item.descricaoEn,
          descricaoCompleta: this.item.descricaoCompleta,
          un: this.un,
          ncm: this.ncm,
          itemFornecedor: this.item.itemFornecedor,
          ativo: this.item.ativo,
          aliqIcms: this.item.aliquotaIcms,
          dtInicial: this.item.dtInicial,
          dtFinal: this.item.dtFinal,
          ean13: this.item.ean13,
          informacoesAdicionais: this.item.informacoesAdicionais,
          vlrCusto: this.item.valorCusto,
          vlrVenda: this.item.valorVenda,
          codCest: this.codCest,
          controlaEstoque: this.item.verificaEstoque,
          operacao: this.operacao,
          codLst: this.lst,
          codNbs: this.nbs,
          empresaCnae: this.cnae,
          grupoEstoque: this.grupoEstoque,
          estoqueMin: this.estoqueMin,
          estoqueMax: this.estoqueMax,
          itemBom: this.item.itemBom,
          nve: this.item.nve,
          pesoL: this.item.pesoL,
          pesoB: this.item.pesoB,
          tipoUtilizacao: this.tipoUtilizacao,
          codServicoTelecomunicacao: this.codServicoTelecomunicacao,
          empresaId: this.dblinkedSessionService.empresa.id,
          reqItemId: this.reqItemId,
          companiesGroup: this.selectedCompaniesGroup,
          itemCode: this.item.codigo,
          grupoEmpresaItemOrigemId: this.item.grupoEmpresaItemOrigem.id,
          conversao: this.fatoresConversao,
          eyeMobileUser: this.eyeMobileUser,
          origemProduto: this.origem,
          codigoRendimento: this.codigoRendimento,
          categoria: this.categoria

        }

        let updateLink = this.registerGroupCompanies ? '/custom/cadastro/update-item-grupo' : '/custom/cadastro/update-item'

        this.httpService.wait()
        this.httpService.post(updateLink, map)
          .subscribe(
            (res) => {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Item alterado com sucesso.' })

              this.LogManagement.registerLog(
                LogModule.REGISTRATION,
                LogScreen.PRODUCTS_AND_SERVICES,
                this.registerGroupCompanies ? '/custom/cadastro/update-item-grupo' : '/custom/cadastro/update-item',
                LogAction.EDIT,
                this.item.id,
                this.item.codigo,
                "O item " + res.codigo + " foi modificado",
                map)

              if (this.eyeMobileUser && this.grupoProdutosId.length > 0) this.salvarCatalogoRelac(this.item.id)
              else this.resetVars(true)
            },
            error =>
              this.httpService.handleError(error, () => this.salvar()),
            () => this.httpService.done()
          );

      } else if (this.showNew) {
        if (this.registerGroupCompanies && this.selectedCompaniesGroup.length > 1) {
          this.createItemGroup()

        } else {
          this.httpService.wait();
          this.httpService.get('/custom/cadastro/codigo-item(' +
            this.tipo + ',' + this.dblinkedSessionService.empresa.id + ',' + this.item.operacao.id + ')').subscribe(
              ref => {
                this.item.codigo = ref.codigo;
                // insert;
                // ------------------------------------
                this.httpService.wait();
                this.httpService.post('/item', this.item).subscribe(
                  res => {
                    this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: 'Item cadastrado com sucesso.' })

                    this.LogManagement.registerLog(
                      LogModule.REGISTRATION,
                      LogScreen.PRODUCTS_AND_SERVICES,
                      '/item',
                      LogAction.CREATE,
                      res.id,
                      this.item.codigo,
                      "O item " + this.item.codigo + " foi criado",
                      this.item)

                    if (this.eyeMobileUser && this.catalogosId.length > 0) this.salvarCatalogoRelac(res.id)
                    else this.resetVars(true)
                  },
                  error =>
                    this.httpService.handleError(error, () => this.salvar()),
                  () => this.httpService.done()
                );
              },
              error => this.httpService.handleError(error, () => this.salvar()),
              () => this.httpService.done()
            );
        }
      }
    }
  }

  saveClone() {
    if (this.cloneInOwnCompany) {
      this.validateItemParameters(this.itemDescription)
    }

    if (!confirm('Deseja realmente duplicar este item?')) return

    const empresaId = this.dblinkedSessionService.empresa.id

    let empresasIds = this.selectedCompaniesGroup
    if (!this.cloneInOwnCompany) empresasIds = this.selectedCompaniesGroup.filter(empresaSelecionadaId => empresaSelecionadaId !== empresaId)

    let itensIds = []
    this.selectedItens.forEach(item => {
      itensIds.push(item.id)
    })

    const payload = {
      partNumber: this.itemPartNumber,
      descricao: this.itemDescription,
      userId: this.sessionService.loggedUser.id,
      itemId: this.selectedItens[0].id,
      itensIds,
      empresaId,
      empresasIds
    }

    let urlClone = (this.registerGroupCompanies && !this.cloneInOwnCompany) ? '/custom/cadastro/clonar-item-grupo' : '/custom/cadastro/clonar-item'

    this.httpService.wait()
    this.httpService.post(urlClone, payload)
      .subscribe(result => {
        if (!result.error) {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso:', detail: 'Item clonado com sucesso!' })
          this.resetVars(true)
          this.setModalClone(false, false)
        } else {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Atenção:', detail: result.message })
        }
      },
        error => this.httpService.handleError(error, () => this.loadItens()),
        () => this.httpService.done())

  }

  async setModalClone(setModalClone: boolean, cloneInOwnCompany: boolean) {
    this.modalClone = setModalClone
    this.cloneInOwnCompany = cloneInOwnCompany
  }

  excluir() {
    let textoDelecao = this.registerGroupCompanies ? "Deseja excluir este item? Essa ação afetará as possíveis empresas do seu grupo." : "Deseja excluir este item?"

    if (confirm(textoDelecao)) {
      this.httpService.wait();
      this.httpService
        .post('/custom/cadastro/delete-item', {
          itemId: this.selectedItens[0].id
        })
        .subscribe(
          rst => {
            this.messageService.add({ key: 'messageLinnks', severity: rst.severity, summary: rst.summary, detail: rst.detail })
            this.LogManagement.registerLog(
              LogModule.REGISTRATION,
              LogScreen.PRODUCTS_AND_SERVICES,
              '/custom/cadastro/delete-item',
              LogAction.DELETE,
              this.selectedItens[0].id,
              this.selectedItens[0].codigo,
              "O item " + this.selectedItens[0].codigo + " foi excluído",
              { itemId: this.selectedItens[0].id })
            if (rst.severity === 'success') {
              this.resetVars(true);
            }
          },
          error =>
            this.httpService.handleError(error),
          () => this.httpService.done()
        );
    }
  }

  setControllers(reset: boolean) {
    // validação pelo id da tabela:
    // 1 - MATERIA REVENDA
    // 2 - MATERIA PRIMA
    // 3 - EMBALAGEM
    // 4 - PRODUTO PROCESSO
    // 5 - PRODUTO ACABADO
    // 6 - SUBPRODUTO
    // 7 - PRODUTO INTERMEDIÁRIO
    // 8 - MATERIAL USO CONSUMO
    // 9 - ATIVO IMOBILIZADO
    // 10 - SERVIÇOS
    // 11 - OUTROS INSUMOS
    // 12 - OUTRAS

    this.ctrCest = true; // desabilitado para todas os tipos ?
    this.showMoreOptions = false

    if (this.tipo < 10 || this.tipo > 10) {
      this.ctrLst = true;
      this.ctrNbs = true;
      this.ctrOperacao = false;
      this.ctrCnae = true;
      this.ctrAliquotaIcms = false;
      this.ctrCest = false;
      this.ctrEan13 = false;
      this.ctrNcm = false;
      this.ctrTipi = false;
      this.ctrUn = false;
      this.ctrVerificaEstoque = false;
    } else if (this.tipo === 10) {
      this.ctrLst = false;
      this.ctrNbs = false;
      this.ctrOperacao = false;
      this.ctrCnae = false;
      this.ctrAliquotaIcms = true;
      this.ctrCest = true;
      this.ctrEan13 = true;
      this.ctrNcm = true;
      this.ctrTipi = true;
      this.ctrUn = true;
      this.ctrVerificaEstoque = true;
    }

    // limpa os campos monitorados;
    if (reset) {
      this.lst = null;
      this.nbs = null;
      this.operacao = null;
      this.origem = 0;
      this.item.aliquotaIcms = null;
      this.codCest = null;
      this.item.ean13 = null;
      this.ncm = null;
      this.tipi = null;
      this.un = null;
      this.item.verificaEstoque = false;
      this.cnae = null;
    }
  }

  validacaoNcm(id: any) {
    if (confirm('Deseja realmente validar estar NCM?')) {
      const map = {
        idUser: this.sessionService.loggedUser.id,
        id: this.item.id,
        status: 'SIM'
      };

      this.httpService.wait();
      this.httpService
        .post('/custom/cadastro/validar-ncm', map)
        .subscribe(
          () => {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'Sucesso',
              detail: 'NCM validada com sucesso!'
            });
          },
          error =>
            this.httpService.handleError(error, () => this.validacaoNcm(id)),
          () => this.httpService.done()
        );

    }
  }

  removerValidacao(id: any) {
    if (confirm('Deseja realmente remover a validação desta NCM?')) {
      const map = {
        idUser: this.sessionService.loggedUser.id,
        id: this.item.id,
        status: 'NÃO'
      };

      this.httpService.wait();
      this.httpService
        .post('/custom/cadastro/validar-ncm', map)
        .subscribe(
          () => {
            this.messageService.add({
              key: 'messageLinnks',
              severity: 'success',
              summary: 'Sucesso',
              detail: 'Validadação removida com sucesso!'
            });
          },
          error =>
            this.httpService.handleError(error, () => this.validacaoNcm(id)),
          () => this.httpService.done()
        );

    }
  }

  showModalImportItens() {
    this.modalUploadItens = true;
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
    this.filename = this.file.name;
  }

  async importar(): Promise<void> {
    const data: FormData = new FormData()
    data.append('file', this.file)

    if (this.tipoLayoutImportacao === 0) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'userid': this.sessionService.loggedUser.id.toString(),
          'empresaid': this.dblinkedSessionService.empresa.id.toString()
        })
      }

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + '/cadastro/upload-itens', data, httpOptions)
        .subscribe(res => {
          if (res["error"]) {
            if (res['data']) {
              const httpOptions2 = {
                headers: new HttpHeaders({
                  'Authorization': this.configService.bearerApi,
                  'Cache-Control': 'no-store, max-age=0',
                  'userid': this.sessionService.loggedUser.id.toString(),
                }),
                responseType: 'blob' as 'json'
              }
              this.httpClient.get(this.configService.defaultUrlApiUpload + '/cadastro/upload-itens', httpOptions2)
                .subscribe(
                  res => {
                    this.downloadFile(res, "log.txt")
                    this.httpService.done();
                  },
                  err => {
                    this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o log: " + err.message })
                    this.httpService.done();
                  });
            }
          }
          else {
            this.loadItens()
          }
          this.exibirMensagem({ error: res["error"], mensagem: res['mensagem'] });
          this.httpService.done();

        },
          err => {
            this.exibirMensagem({ error: true, mensagem: "Erro no API: " + err.message });
            this.httpService.done();
          });
    } else if (this.tipoLayoutImportacao === 1) {
      const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
      this.httpService.wait()
      try {
        await this.httpClient.post(this.configService.octaremoteUrl + '/eyemobile/importar-fator-item?empresaId=' + this.dblinkedSessionService.empresa.id +
          '&userId=' + this.sessionService.loggedUser.id, data, httpOptions).toPromise()
          .then(res => {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: res["message"] })
            this.modalUploadItens = false
          })
      } catch (err) {
        if (err.error.message) this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: err.error.message })
        else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: err.message })
      } finally {
        this.httpService.done()
      }
    }

  }

  exibirMensagem({ mensagem, error }) {
    if (!error) {
      this.messageService.add({
        severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: status ? status : mensagem
      });
    } else {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: status ? status : mensagem
      });
    }
  }

  downloadFile(url, filename) {
    let link = document.createElement('a');
    let urlObject = window.URL.createObjectURL(url);
    link.href = urlObject;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  get importDisabled(): boolean {
    return !this.dblinkedSessionService.hasEmpresaSelected || !this.file;
  }

  uploaded(res?: any) {
    this.msgs = [];
    if (res.result[0].error === true) {
      this.messageService.add({
        key: 'messageLinnks',
        severity: 'warn',
        summary: 'Advertência',
        detail: res.result[0].message
      });
    } else {
      this.messageService.add({
        key: 'messageLinnks',
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Itens importados com Sucesso!'
      });
      this.resetVars(true);
    }
  }

  loadValidaEstoque() {
    this.valEstoque = false

    this.httpService
      .get('/empresa?$select=grupoEstoque&$filter=id eq ' + this.dblinkedSessionService.empresa.id)
      .subscribe(retorno => {
        this.valEstoque = retorno.value[0].grupoEstoque
      },
        error => this.httpService.handleError(error, () => this.loadValidaEstoque()),
        () => this.httpService.done()
      )
  }

  criarNovo() {
    this.showNew = true
    this.showLista = false
    this.item.ativo = true
    this.changeToolbar()
  }

  async editar(rowValue: any) {
    this.showLista = false
    this.showEdit = true
    this.showNew = false

    this.tipo = rowValue.itemTipo !== null ? rowValue.itemTipo.id : null
    this.codCest = rowValue.cestNcmSegmento !== null ? rowValue.cestNcmSegmento.id : null
    this.lst = rowValue.lst !== null ? rowValue.lst.id : null
    this.nbs = rowValue.nbs !== null ? rowValue.nbs.id : null
    this.reqItemId = rowValue.reqItemId ? rowValue.reqItemId : 0
    this.item = rowValue
    this.operacao = rowValue.operacao !== null ? rowValue.operacao.id : null
    this.origem = rowValue.origem != null ? rowValue.origem : 0;
    this.tipi = null
    this.un = rowValue.unidadeMedida !== null ? rowValue.unidadeMedida.id : null
    this.cnae = rowValue.empresaCnae !== null ? rowValue.empresaCnae.id : null
    this.grupoEstoque = rowValue.grupoEstoque !== null ? rowValue.grupoEstoque.id : null
    this.estoqueMin = rowValue.estoqueMin
    this.estoqueMax = rowValue.estoqueMax
    this.tipoUtilizacao = rowValue.tipoUtilizacaoId
    this.codServicoTelecomunicacao = rowValue.codServicoTelecomunicacaoId
    this.item.ativo = rowValue.ativo;
    this.fatoresConversao = rowValue.fatorConversaoList
    this.codigoRendimento = rowValue.codigoRendimento
    this.categoria = rowValue.categoria

    rowValue.dtInicial = new Date(rowValue.dtInicial)
    rowValue.dtInicial.setHours(rowValue.dtInicial.getHours() + 4)

    if (rowValue.dtFinal !== null) {
      rowValue.dtFinal = new Date(rowValue.dtFinal)
      rowValue.dtFinal.setHours(rowValue.dtFinal.getHours() + 4)
    }

    if (rowValue.ncmDtValid !== null) {
      rowValue.ncmDtValid = new Date(rowValue.ncmDtValid)
      rowValue.ncmDtValid.setHours(rowValue.ncmDtValid.getHours() + 4)
    }

    this.setControllers(false)
    this.getNCM(rowValue)
    this.getCatalogoRelac(this.item.id)

    this.changeToolbar()
  }

  public async openingModalBatchEdit() {
    this.lotSaleValue = null
    this.lotCostValue = null
    this.minimumLotStock = null
    this.modalBatchEdit = !this.modalBatchEdit
  }

  public async batchEdit(): Promise<void> {
    if (!Number.isInteger(this.minimumLotStock) && this.minimumLotStock != null) {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Aviso: ', detail: 'O estoque mínino deve ser um número inteiro.' })
      return
    }

    let payload
    let updateBatchItemRoute
    if (this.registerGroupCompanies) {
      updateBatchItemRoute = '/custom/cadastro/update-item-lote-grupo'

      const itensData = this.selectedItens.map(itemSelected => {
        return {
          id: itemSelected.id,
          codigo: itemSelected.codigo,
          grupoEmpresaItemOrigemId: itemSelected.grupoEmpresaItemOrigem.id
        }
      })

      payload = {
        empresa_id: this.dblinkedSessionService.empresa.id,
        user_id: this.sessionService.loggedUser.id,
        vlrVenda: this.lotSaleValue,
        vlrCusto: this.lotCostValue,
        estoqueMin: this.minimumLotStock,
        companyIds: this.selectedCompaniesGroup,
        itens: itensData
      }

    } else {
      updateBatchItemRoute = '/custom/cadastro/update-item-lote'

      payload = {
        empresa_id: this.dblinkedSessionService.empresa.id,
        user_id: this.sessionService.loggedUser.id,
        vlrVenda: this.lotSaleValue,
        vlrCusto: this.lotCostValue,
        estoqueMin: this.minimumLotStock,
        ids: this.selectedItens.map(itemSelected => { return itemSelected.id }),
        companyIds: this.selectedCompaniesGroup,
        itemCodes: this.selectedItens.map(itemSelected => { return itemSelected.codigo })
      }
    }

    this.httpService.wait()

    try {
      await this.httpService.post(updateBatchItemRoute, payload).toPromise().then(res => {
        if (res.error === false) this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso: ', detail: res.message })
        else this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro: ', detail: 'Falha ao atualizar itens!' })
      }).catch(err => this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro: ', detail: err }))
    } catch (err) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.openingModalBatchEdit()
      this.loadItens()
      this.httpService.done()
    }
  }

  public async exportItems() {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi, }) }

    const httpOptions2 = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        userId: this.sessionService.loggedUser.id.toString(),
        empresaId: this.dblinkedSessionService.empresa.id.toString(),
      }),
      responseType: 'blob' as 'json'
    }

    const payload = {
      empresaId: this.dblinkedSessionService.empresa.id,
      userId: this.sessionService.loggedUser.id,
    }

    try {
      this.httpService.wait()
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/exportar-itens/', payload, httpOptions).toPromise().then(res => {
        if (!res["error"]) this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/exportar-itens/', httpOptions2).toPromise().then((res: any) => {
          let link = document.createElement('a')
          let url = window.URL.createObjectURL(res)
          link.href = url
          link.download = "relatorio-produtos-servicos.xlsx"
          link.click()
          window.URL.revokeObjectURL(url)
          this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso: ', detail: ' Relatório gerado com sucesso!' })
        }).catch(err => this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro: ', detail: ' Houve um erro ao recuperar seu relatório! Contate o suporte' }))
      }).catch(err => this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro: ', detail: ' Houve um erro ao gerar seu relatório! Contate o suporte' }))
    } catch (err) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro: ', detail: ' Falha na comunicação com o servidor!' })

    } finally {
      this.httpService.done()
    }
  }

  public validateItemParameters(itemDescription: string): void {
    let message
    if (itemDescription === null || itemDescription.length < 1 || itemDescription === undefined) {
      message = 'A descrição é obrigatória!'
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Atenção:', detail: message })
      throw message
    }
  }

  public changeToolbar(): void {
    this.toolbarMain.setVisible('btn-navigate', !this.showLista && this.companyCanMakeChanges)
    this.toolbarMain.setVisible('btn-refresh', this.showLista)
    this.toolbarMain.setVisible('btn-upload', this.showLista && this.companyCanMakeChanges)
    this.toolbarMain.setVisible('btn-export', this.showLista)
    this.toolbarMain.setVisible('btn-edit', this.showLista && this.selectedItens.length === 1 && this.companyCanMakeChanges)
    this.toolbarMain.setVisible('btn-edit-batch', this.showLista && this.selectedItens.length > 1 && this.companyCanMakeChanges)
    this.toolbarMain.setVisible('btn-clone', this.showLista && this.selectedItens.length === 1 && this.companyCanMakeChanges && !this.registerGroupCompanies)

    this.toolbarMain.setVisible('btn-clone-expanded', this.showLista && this.selectedItens.length >= 1 && this.companyCanMakeChanges && this.registerGroupCompanies)
    this.toolbarMain.setOptionVisible('btn-clone-expanded', 'btn-unique-clone', this.showLista && this.selectedItens.length === 1 && this.companyCanMakeChanges && this.registerGroupCompanies)

    this.toolbarMain.setVisible('btn-delete', this.showLista && this.selectedItens.length === 1 && this.companyCanMakeChanges)
    this.toolbarMain.setVisible('btn-add', this.showLista && this.companyCanMakeChanges)
    this.toolbarMain.setVisible('btn-save', !this.showLista && this.companyCanMakeChanges)
    if (this.selectedItens.length === 1 && this.companyCanMakeChanges) this.toolbarMain.setClick('btn-edit', () => this.editar(this.selectedItens[0]))
  }

  checkEyeMobileUser(): void {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }

    this.httpClient.get(this.configService.octaremoteUrl + '/cadastro/empresa-familia?familiaId=1', httpOptions).subscribe(
      res => {
        if (res['codigo'] === 200) {
          if (res['data'].includes(this.dblinkedSessionService.empresa.id)) {
            this.eyeMobileUser = true
            this.loadCatalogos()
            this.loadItensEyemobile()
          }
        } else this.exibirMensagem({ error: true, mensagem: res["message"] })
      },
      error => {
        this.exibirMensagem({ error: true, mensagem: "Erro ao verificar se a empresa usa EyeMobile, contate o suporte: " + error.message })
      }
    )
  }

  loadGrupoProdutos(): void {
    if (this.catalogosId.length > 0) {
      const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }

      this.httpService.wait()
      this.httpClient.get(this.configService.octaremoteUrl + '/eyemobile/grupo-produtos?empresaId=' + this.dblinkedSessionService.empresa.id + "&catalogos=" + this.catalogosId, httpOptions).subscribe(
        res => {
          if (res['codigo'] === 200) {
            this.grupoProdutoList = []
            res["data"].forEach(row => this.grupoProdutoList.push({ value: { id: row.id, catId: row.cat_id }, label: row.descricao }))
          } else this.exibirMensagem({ error: true, mensagem: res["message"] })
        },
        error => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao buscar os grupos de produtos, contate o suporte: " + error.message })
        },
        () => this.httpService.done()
      )
    }
  }

  loadCatalogos(): void {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }

    this.httpClient.get(this.configService.octaremoteUrl + '/eyemobile/catalogos?empresaId=' + this.dblinkedSessionService.empresa.id, httpOptions).subscribe(
      res => {
        if (res['codigo'] === 200) {
          this.catalogosList = []
          res["data"].forEach(row => this.catalogosList.push({ value: row.id, label: row.descricao }))
        } else this.exibirMensagem({ error: true, mensagem: res["message"] })
      },
      error => {
        this.exibirMensagem({ error: true, mensagem: "Erro no API ao buscar os catálogos, contate o suporte: " + error.message })
      }
    )
  }

  salvarCatalogoRelac(itemId: number): void {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }
    const infos = {
      item_id: itemId,
      catalogos: this.catalogosId,
      grupos_produto: this.grupoProdutosId
    }

    this.httpService.wait()
    this.httpClient.post(this.configService.octaremoteUrl + '/eyemobile/salvar-catalogo-relac', infos, httpOptions).subscribe(
      res => {
        if (res["codigo"] === 200) this.resetVars(true)
        else this.exibirMensagem({ error: true, mensagem: res["message"] })
      },
      error => this.exibirMensagem({ error: true, mensagem: "Erro no API ao salvar a relação de catálogos, contate o suporte: " + error.message }),
      () => this.httpService.done()
    )
  }

  getCatalogoRelac(itemId: number): void {
    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }

    this.httpService.wait()
    this.httpClient.get(this.configService.octaremoteUrl + '/eyemobile/salvar-catalogo-relac?itemId=' + itemId, httpOptions).subscribe(
      res => {
        if (res["codigo"] != 200) {
          this.exibirMensagem({ error: true, mensagem: res["message"] })
          return
        }
        this.catalogosId = new Array()
        this.grupoProdutosId = new Array()
        res["data"]["catalogos"].forEach(element => this.catalogosId.push(element))
        res["data"]["grupos"].forEach(element => this.grupoProdutosId.push(element))
      },
      error => this.exibirMensagem({ error: true, mensagem: "Erro no API ao obter a relação de catálogos, contate o suporte: " + error.message }),
      () => this.httpService.done()
    )
  }

  async getRegisterGroupCompanies() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.wait();
    this.httpService.get(`/empresa?$select=cadastroGrupoEmpresa&$filter=id eq ${companyId}`).subscribe(
      res => {
        this.registerGroupCompanies = res.value[0].cadastroGrupoEmpresa ? true : false

        if (this.registerGroupCompanies) this.getGroupCompanies()
      },
      error => this.httpService.handleError(error, () => this.getRegisterGroupCompanies()),
      () => this.httpService.done()
    )
  }

  getGroupCompanies() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.wait();
    this.httpService.get(`/empresagrupocadastrorelac?$select=id,empresaRelac/nomeFantasia&$filter=empresa/id eq ` + companyId).subscribe(
      res => {
        this.companiesGroup = [];
        this.selectedCompaniesGroup = [];

        this.selectedCompaniesGroup.push(companyId)

        res.value.forEach(company => {
          this.companiesGroup.push({
            label: company.empresaRelac.nomeFantasia,
            value: company.empresaRelac.id
          })

          this.selectedCompaniesGroup.push(company.empresaRelac.id)
        })
      },
      error => {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro ao buscar as empresas do grupo' })
      },
      () => {
        this.setPlaceholderSelectedCompanies()
        this.httpService.done()
      }
    )
  }

  determineMethodToSave() {
    if (this.registerGroupCompanies) {
      this.informationValid = this.validateData()

      if (this.informationValid) {
        if (this.showNew) {
          this.modalGroupDataTitle = 'Criação de itens em várias empresas'
          this.modalGroupDataLabel = 'Em quais outras empresas você deseja criar este item?'
        } else if (this.showEdit) {
          this.modalGroupDataTitle = 'Edição de itens em várias empresas'
          this.modalGroupDataLabel = 'Em quais outras empresas você deseja editar este item?'
        }

        this.modalGroup = true
      }
    } else {
      this.salvar()
    }
  }

  createItemGroup() {
    const payloadItemCode = {
      itemTipo: this.tipo,
      operacaoId: this.item.operacao.id,
      empresasGrupo: this.selectedCompaniesGroup,
    }

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/codigo-item-grupo', payloadItemCode).subscribe(
      ref => {
        this.item.codigo = ref.codigo;

        const payloadCreationItem = {
          item: this.item,
          empresasGrupo: this.selectedCompaniesGroup,
          empresaCriadoraItemId: this.dblinkedSessionService.empresa.id
        }

        this.httpService.wait();
        this.httpService.post('/custom/cadastro/criar-item-grupo', payloadCreationItem).subscribe(
          res => {
            this.messageService.add({
              key: 'messageLinnks',
              severity: res.severity,
              summary: res.summary,
              detail: res.detail
            });
            this.resetVars(true);
          },
          error => this.httpService.handleError(error, () => this.salvar()),
          () => this.httpService.done()
        );
      },
      error => this.httpService.handleError(error, () => this.salvar()),
      () => this.httpService.done()
    );
  }


  validateData(): boolean {
    let checkFirstWave = false
    let checkSecondWave = false

    if (this.tipo === null) {
      checkFirstWave = true
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'O campo "TIPO" deve ser preenchido.'
      })
      return
    }

    if (this.item.descricao === null || this.item.descricao === '') {
      checkFirstWave = true;
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'O campo "DESCRIÇÃO" deve ser preenchido.'
      })
      return
    }

    if (this.item.dtInicial === null) {
      checkFirstWave = true;
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'O campo "DATA INICIAL" deve ser preenchido.'
      })
      return
    }

    if (this.valEstoque === true && this.item.verificaEstoque === true && this.grupoEstoque === null) {
      checkFirstWave = true
      this.messageService.add({
        severity: 'error', key: 'messageLinnks',
        summary: 'Erro',
        detail: 'O campo "Grupo Estoque" deve ser preenchido.'
      })
      return
    }

    if (!checkFirstWave) {
      if (
        this.tipo === 1 ||
        this.tipo === 2 ||
        this.tipo === 3 ||
        this.tipo === 4 ||
        this.tipo === 5 ||
        this.tipo === 6 ||
        this.tipo === 7 ||
        this.tipo === 8 ||
        this.tipo === 9 ||
        this.tipo === 11 ||
        this.tipo === 12
      ) {
        if (this.un === null) {
          checkSecondWave = true;
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'Erro',
            detail: 'O campo "UN" deve ser preenchido.'
          })
          return
        }

        if (this.operacao === null) {
          checkSecondWave = true;
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'Erro',
            detail: 'O campo "OPERAÇÃO" deve ser preenchido.'
          })
          return
        }
      }

      if (
        this.tipo === 1 ||
        this.tipo === 4 ||
        this.tipo === 5 ||
        this.tipo === 11
      ) {
        if (this.ncm === null) {
          checkSecondWave = true;
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'Erro',
            detail: 'O campo "NCM" deve ser preenchido.'
          })
          return
        }

        if (this.operacao === null) {
          checkSecondWave = true;
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'Erro',
            detail: 'O campo "OPERAÇÃO" deve ser preenchido.'
          })
          return
        }
      }

      if (this.tipo === 10) {
        if (this.operacao === null) {
          checkSecondWave = true;
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'Erro',
            detail: 'O campo "OPERAÇÃO" deve ser preenchido.'
          })
          return
        }
        if (this.lst === null) {
          checkSecondWave = true;
          this.messageService.add({
            severity: 'error', key: 'messageLinnks',
            summary: 'Erro',
            detail: 'O campo "COD. LST" deve ser preenchido.'
          })
          return
        }
      }

      if (!checkSecondWave) {
        this.item.changeUser = new AuthUser();
        this.item.changeUser.id = this.sessionService.loggedUser.id;

        this.item.empresa = new Empresa();
        this.item.empresa.id = this.dblinkedSessionService.empresa.id;

        this.item.itemTipo = new ItemTipo();
        this.item.itemTipo.id = this.tipo;

        this.item.estoqueMin = this.estoqueMin;
        this.item.estoqueMax = this.estoqueMax;

        if (this.codCest !== null) {
          this.item.cestNcmSegmento = new CestNcmSegmento();
          this.item.cestNcmSegmento.id = this.codCest;
        }

        if (this.lst !== null) {
          this.item.lst = new Lst();
          this.item.lst.id = this.lst;
        }

        if (this.nbs !== null) {
          this.item.nbs = new Nbs();
          this.item.nbs.id = this.nbs;
        }

        if (this.ncm !== null) {
          this.item.tipi = new Tipi();
          this.item.tipi.id = this.ncm;
        }

        if (this.operacao !== null) {
          this.item.operacao = new Operacao();
          this.item.operacao.id = this.operacao;
        }

        if (this.un !== null) {
          this.item.unidadeMedida = new UnidadeMedida();
          this.item.unidadeMedida.id = this.un;
        }

        if (this.cnae !== null) {
          this.item.empresaCnae = new EmpresaCnae();
          this.item.empresaCnae.id = this.cnae;
        }

        if (this.grupoEstoque !== null) {
          this.item.grupoEstoque = new GrupoEstoque();
          this.item.grupoEstoque.id = this.grupoEstoque;
        }


        if (this.tipoUtilizacao !== null) {
          this.item.tipoUtilizacao = new TipoUtilizacao();
          this.item.tipoUtilizacao.id = this.tipoUtilizacao;
        }

        if (this.codServicoTelecomunicacao !== null) {
          this.item.codServicoTelecomunicacao = new CodServicoTelecomunicacao();
          this.item.codServicoTelecomunicacao.id = this.codServicoTelecomunicacao;
        }
        return true
      }
    }
    return false
  }

  setPlaceholderSelectedCompanies() {
    this.placeholderSelectedCompanies = null
    if (this.selectedCompaniesGroup.length > 0) {
      let companyNames = []

      this.companiesGroup.forEach(company => {
        if (this.selectedCompaniesGroup.indexOf(company.value) !== -1) companyNames.push(company.label)
      })

      if (companyNames.length > 0) this.placeholderSelectedCompanies = companyNames.join(', ')
    }
  }

  async checkIfCompanyCanMakeChanges() {
    const companyId = this.dblinkedSessionService.empresa.id
    this.companyCanMakeChanges = false

    try {
      await this.httpService.get(`/custom/cadastro/verifica-grupo-empresa-permite-alteracao(${companyId})`).toPromise()
        .then(res => this.companyCanMakeChanges = res.permissoes.permiteAlteracaoProdutoServico)
        .catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }

  adicionarConversao() {
    this.rowFator = {
      fatorConversao: 0,
      unidadeMedida: null,
      itemEyeId: null,
      id: null,
      itemEye: null,
      codigoUnidadeMedida: null
    }
    this.modalConversao = true
    this.editConversao = false
  }

  dadosUnidade() {
    this.unidadeMedidaCodigo = this.buscarCodigoUN(this.unidadeMedidaConversaoId)
  }

  excluirFatorConv(event: any) {
    if (confirm('Deseja realmente excluir este fator?')) {
      const index = this.fatoresConversao.findIndex(c => c.id === event.id)
      this.fatoresConversao.splice(index, 1)
    }
  }

  editarFatorConv(event: any) {
    this.rowFator = event
    this.editConversao = true
    this.modalConversao = true
  }

  editSaveFatorConv() {
    if (!this.eyeMobileUser) {
      let val = true
      this.fatoresConversao.forEach(element => {
        if (element.unidadeMedida === this.rowFator.unidadeMedida) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Já possui esta unidade de medida cadastrada' })
          val = false
        }
      })
      if (!val) return

    } else {
      if (!this.rowFator.itemEyeId && (this.tipo === 1 || this.tipo === 5)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'O campo item é obrigatório' })
        return
      }
    }

    if (!this.rowFator.unidadeMedida) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'A unidade medida é um campo obrigatório' })
      return
    }

    if (!this.rowFator.fatorConversao || this.rowFator.fatorConversao <= 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'O fator de conversão deve ser superior a 0' })
      return
    }

    //Colocar os valores correto na tabela
    this.unList.forEach(element => {
      if (element.value === this.rowFator.unidadeMedida) {
        this.rowFator.codigoUnidadeMedida = element.label
        return
      }
    })

    if (this.eyeMobileUser) {
      this.optItens.forEach(element => {
        if (element.value === this.rowFator.itemEyeId) this.rowFator.itemEye = element.label
      })
    }

    this.modalConversao = false

  }

  incluirFatorConv() {
    if (!this.eyeMobileUser) {
      let val = true
      this.fatoresConversao.forEach(element => {
        if (element.unidadeMedida === this.rowFator.unidadeMedida) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Já possui esta unidade de medida cadastrada' })
          val = false
        }
      })
      if (!val) return

    } else {
      if (!this.rowFator.itemEyeId && (this.tipo === 1 || this.tipo === 5)) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'O campo item é obrigatório' })
        return
      }
    }

    if (!this.rowFator.unidadeMedida) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'A unidade medida é um campo obrigatório' })
      return
    }

    if (!this.rowFator.fatorConversao || this.rowFator.fatorConversao <= 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'O fator de conversão deve ser superior a 0' })
      return
    }

    //Colocar os valores correto na tabela
    this.unList.forEach(element => {
      if (element.value === this.rowFator.unidadeMedida) {
        this.rowFator.codigoUnidadeMedida = element.label
        return
      }
    })

    if (this.eyeMobileUser) {
      this.optItens.forEach(element => {
        if (element.value === this.rowFator.itemEyeId) this.rowFator.itemEye = element.label
      })
    }

    this.fatoresConversao.push(deepCopy(this.rowFator))
    this.modalConversao = false
  }

  buscarCodigoUN(id: number): String {



    let res = '';

    this.unList.forEach(element => {

      if (element.value == id) {

        res = element.label;
        return res;
      }

    })
    return res;
  }

  async loadItensEyemobile(): Promise<void> {
    this.skeletonConfig.startLoad()
    const infos = {
      tipo_item: [1, 2, 3, 4, 6, 7, 11, 12],
      empresa_id: this.dblinkedSessionService.empresa.id
    }

    const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.configService.bearerApi }) }

    try {
      await this.httpClient.post(`${this.configService.defaultUrlApiUpload}cadastro/itens-filtrados`, infos, httpOptions).toPromise().then(res => {
        this.optItens = []
        res["data"].forEach(element => this.optItens.push({ label: element.codigo + " - " + element.descricao, value: element.id }))
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: "Erro no API ao buscar os itens, contate o suporte: " + err.message })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }
}