import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { SessionService } from 'app/shared/services/session.service';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Button } from './models/Button';
import { Column } from './models/Column';
import { DynamicTable } from './models/DynamicTable';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})

export class DynamicTableComponent implements OnInit, OnChanges, OnDestroy {
  private sessionSubscription: Subscription;

  // 'value' é a instancia de dinamicTable que fornece os dados que este componente tabela precisará para renderizar e trabalhar
  @Input() value: DynamicTable;
  @Input() resetTableState: any;
  @Input() updateTableContent: any

  // EventEmitters utilitários para o componente chamador ser notificado de algumas ações que acontecem aqui dentro
  @Output() modalStatus: EventEmitter<{ key: string, active: boolean }> = new EventEmitter();
  @Output() contentState: EventEmitter<any> = new EventEmitter();
  @Output() messages: EventEmitter<any> = new EventEmitter();
  @Output() contentTosave: EventEmitter<any> = new EventEmitter();
  @Output() contentToDelete: EventEmitter<any> = new EventEmitter();
  @Output() visible: EventEmitter<boolean> = new EventEmitter();
  @Output() lazyEvents: EventEmitter<LazyLoadEvent> = new EventEmitter();


  // Atributos
  initialDate: Date = null;
  finalDate: Date = null;
  lazy: boolean;
  totalRecords: number;
  name: string;
  content: Array<any>;
  columns: Array<Column>;
  index: number;
  key: string;
  form: boolean;
  selectable: boolean;
  closable: boolean;
  deletable: boolean;
  lockedColumns: Array<Column> = [];
  ObjectState: any;
  modalReplic = false;
  modalFilters = false;
  availableReplicColumns: Array<Column> = [];
  columToReplic: Column;
  valueToReplic: any;
  selectedRows: Array<any> = [];
  selectedColumns: Array<EditableColumn> = [];

  buttons: Array<Button> = [
    { label: 'Salvar', icon: 'done_all', key: null, action: () => this.saveContent() },
  ];

  modalReplicTHLabels = ['COLUNA', 'VALOR', 'AÇÃO']

  pt_BR = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };


  constructor(
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private configService: ConfigService,
  ) { }

  // Para garantir que todos os atributos sejam zerados ao instanciar uma nova tabela
  ngOnDestroy(): void {
    this.resetState()
  }

  ngOnInit(): void {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.setDefaultPeriod()
      })
    this.setButtons(this.value.buttons)
    this.setAll()
  }

  ngOnChanges(changes: SimpleChanges): void {

    try {
      if (changes.updateTableContent.currentValue.update) {
        this.updateContent()
      } else if (changes.resetTableState.currentValue.reset) {
        this.resetState();
        this.setAll();
      }
    }catch(TypeError){
      this.setAll()
    }
  }

  resetState(): void {
    this.initialDate = null;
    this.finalDate = null;
    this.lazy = null;
    this.totalRecords = null;
    this.name = null;
    this.content = [];
    this.columns = [];
    this.index = null;
    this.key = null;
    this.form = false;
    this.selectable = false;
    this.closable = false;
    this.deletable = false;
    this.lockedColumns = [];
    this.ObjectState = null;
    this.modalReplic = false;
    this.modalFilters = false;
    this.availableReplicColumns = [];
    this.columToReplic = null;
    this.valueToReplic = null;
    this.selectedRows = [];
    this.selectedColumns = [];
  }

  // Faz todas atribuições iniciais necessárias
  setAll(): void {
    this.resetState();
    this.lazy = this.value.lazy;
    this.totalRecords = this.value.totalRecords;
    this.name = this.value.name;
    this.form = this.value.form;
    this.selectable = this.value.selectable;
    this.closable = this.value.closable;
    this.deletable = this.value.deletable;
    // this.toolbarMain.setAll(this.toolbarMainIni)
    this.value.content = this.addIndexPropertie(this.value.content);
    this.content = this.value.content;
    this.value.columns = this.createAllColumnsOptionalsProperties(this.value.columns);
    this.value.columns = this.setTargetKey(this.value.columns);
    this.setReplicColumns(this.value.columns);
    this.columns = this.value.columns;
    this.ObjectState = this.value.ObjectState;
  }

  // Atualiza o conteúdo com um valor novo
  updateContent(): void {
    this.index = null;
    this.key = null;
    this.columToReplic = null;
    this.valueToReplic = null;
    this.selectedRows = [];
    this.value.content = this.addIndexPropertie(this.value.content);
    this.content = this.value.content
  }

  // Função que seta o item atual que está sendo editado, esta função é util extremamente nos casos em que o valor editavel vem de fora do componente, como por exemplo um modal, assim, quando for chamado
  // o metodo update da classe DinamicTable, ele sabe qual objeto e chave precisa atualizar com o valor novo
  setEditableState(index: number, key: string): void {
    this.value.index = index
    this.value.key = key
    this.value.selectedItems = this.selectedRows
    this.openModal(key)
  }

  // Ajuda o componente a entender se o dado presente na linha é um numérico ou não, para que ele troque o tipo do input para number e evitar que o usuário insira letras
  isNumber(value): boolean { return typeof value === 'number'; }

  isString(value): boolean { return typeof value === 'string'; }


  // Acrescenta um atributo index em todos objetos para que o componente possa trabalhar corretamente e entender qual linha esta sendo manipulada pelo usuário no momento.
  // Graças a esta função, não é necessário informar esta chave no componente chamador
  addIndexPropertie(content: Array<any>): Array<any> {
    let i = 0
    for (let item of content) {
      item['index'] = i
      i++
    }
    return content
  }

  setTargetKey(columns: Array<Column>): Array<Column> {
    columns.forEach(column => { if (!column.targetKey) column.targetKey = column.key })
    return columns
  }

  // Retorna através do eventEmitter o conteúdo no estado atual
  saveContent(): void {
    if (this.selectedRows.length == 0) {
      this.messages.emit({ severity: 'warn', message: 'Nenhum item selecionado.', key: 'messageLinnks', summary: 'Atenção' })
    } else {
      this.contentTosave.emit(this.selectedRows)
    }
  }

  // Retorna os itens selecionados para o pai poder chamar a rota de exclusão
  deleteContent(): void {
    this.contentToDelete.emit(this.selectedRows)
  }

  // Habilita o modal de replicar valores
  openModalReplic(): void {
    if (this.selectedRows.length < 1) {
      this.messages.emit({ severity: 'warn', message: 'Nenhuma linha selecionada.' })
      return
    } else if (this.availableReplicColumns.length < 1) {
      this.messages.emit({ severity: 'warn', message: 'Nenhuma das colunas disponíveis é editavel.' })
      return
    }

    for (let column of this.availableReplicColumns) {
      if (column.type.multiOptions.active) this.multiOptionCondition(column)
    }

    this.modalReplic = true;
  }

  multiOptionCondition(column: Column) {
    let selected = []

    this.selectedRows.forEach(item => {
      if (!selected.includes(item[column.type.multiOptions.key])) selected.push(item[column.type.multiOptions.key])
    })

    column.type.multiOptions.replicOptions = new Array()

    selected.forEach(item => {

      for (const [key, value] of Object.entries(column.type.multiOptions.options)) {

        if (key == item) {
          const group = {
            label: value['label'],
            value: key,
            items: value['options']
          }

          column.type.multiOptions.replicOptions.push(group)
        }
      }
    })
  }

  // Torna a passagem de parâmetros opcionais mais intuitiva, essa função se encarrega de definir valores falsos para as chaves não informadas,
  // assim na declarão das colunas no componente que esta chamando, não é necessário criar um objeto gigantesco com todos os atributos
  createAllColumnsOptionalsProperties(columns: Array<Column>): Array<Column> {
    for (let item of columns) {
      if (!item.hasOwnProperty('targetKey')) item['targetKey'] = null

      if (!item.hasOwnProperty('style')) item['style'] = { widthInPixels: null, align: null }

      if (!item.hasOwnProperty('obrigatory')) item['obrigatory'] = false

      if (!item.style.hasOwnProperty('align')) item['style']['align'] = 'center'

      if (!item.style.hasOwnProperty('widthInPixels')) item['style']['widthInPixels'] = null

      if (!item.type.hasOwnProperty('selectItem')) item.type['selectItem'] = { readOnly: false, active: false, options: undefined, disableOnUndefined: false, invert: { active: false, secondaryOptions: undefined, tooltipLabel: undefined } }

      if (!item.type.hasOwnProperty('readOnly')) item.type['readOnly'] = { active: false }

      if (!item.type.hasOwnProperty('modal')) item.type['modal'] = { active: false, label: undefined }

      if (!item.type.hasOwnProperty('checkbox')) item.type['checkbox'] = { active: false, label: undefined }

      if (!item.type.hasOwnProperty('input')) item.type['input'] = { active: false }

      if (!item.type.hasOwnProperty('multiOptions')) item.type['multiOptions'] = { active: false, options: null, key: null, replicOptions: null }

      if (!item.type.multiOptions.hasOwnProperty('readOnly')) item.type.multiOptions['readOnly'] = false

      if (!item.type.selectItem.hasOwnProperty('readOnly')) item.type.selectItem['readOnly'] = false

      if (!item.type.selectItem.hasOwnProperty('invert')) item.type.selectItem['invert'] = { active: false, secondaryOptions: undefined, tooltipLabel: undefined }

      if (!item.type.modal.hasOwnProperty('labelByValue')) item.type.modal['labelByValue'] = undefined
    }

    return columns
  }

  // in progress
  // toggleLock(column: Column, frozen: boolean, index: number) { this.lockedColumns.push(column); }

  // Função de auxílio quando o tipo de coluna reversível está ativado
  reverse(index: number, key: string): void {
    if (this.content[index][key] === null) {
      this.content[index][key] = undefined
    } else if (this.content[index][key] === undefined) {
      this.content[index][key] = null
    }

    for (let column of this.columns) {
      if (column.type.selectItem.disableOnUndefined && column.key != key) {
        if (this.content[index][column.key] === undefined) {
          this.content[index][column.key] = null
        } else if (this.content[index][column.key] === null) {
          this.content[index][column.key] = undefined
        }
      }
    }
  }

  // Verifica todas as colunas e adiciona apenas as colunas que podem estar presentes no modal de replicar os valores
  setReplicColumns(columns: Array<Column>): void {
    this.availableReplicColumns = [];

    columns.forEach(column => {
      if (column.type.modal.active == false && column.type.readOnly.active == false && column.type.selectItem.readOnly == false && column.type.multiOptions.readOnly == false) {

        if (!this.availableReplicColumns.includes(column)) {
          this.availableReplicColumns.push(column)
        }
      }
    })

    if (this.availableReplicColumns.length > 0) {
      this.selectedColumns = [{ column: null, columnValue: null }]
    }
  }

  // Aplica o valor em todas as linhas na determinada key
  applyValueInAllRows(): void {
    const selectedIndex = this.selectedRows.map(item => item.index)

    this.content.forEach(item => {
      if (selectedIndex.includes(item.index)) {
        this.selectedColumns.forEach(selectedColumn => { if (selectedColumn.column) item[selectedColumn.column.key] = selectedColumn.columnValue })
      }
    })

    this.modalReplic = false
    this.setReplicColumns(this.columns)
    this.messages.emit({ severity: 'success', message: 'Todos os valores foram aplicados com sucesso.' })
    this.value.content = this.content
  }

  // Emite o evento avisando o componente chamador a visibilidade deve ser desativada
  closeDynamicTable(): void {
    this.visible.emit(false);
    this.resetState();
  }

  // Emite um evento avisando ao componente pai que o modal com a chave 'x' pode ser aberto
  openModal(key: string): void { this.modalStatus.emit({ key: key, active: true }); }

  // Abre o modal de filtros
  openModalFilters(): void { this.modalFilters = true; }

  // Adiciona os botões que vierem por parametro ao começo da lista
  setButtons(buttons: Array<Button>): void {
    if (this.deletable) this.buttons.unshift({ label: 'Excluir', icon: 'delete', key: null, action: () => this.deleteContent() })
    if (this.selectable) this.buttons.unshift({ label: 'Editar', icon: 'edit', key: null, action: () => this.openModalReplic() })
    if (this.lazy) this.buttons.unshift({ label: 'Filtros', icon: 'filter_alt', key: null, action: () => this.openModalFilters() })
    if (this.form) this.buttons.unshift({ label: 'Novo', icon: 'add', key: null, action: () => this.newContent() })
    if (buttons) {
      buttons.forEach(button => {
        if (button.key && !button.action) button.action = () => this.openModal;

        this.buttons.unshift(button);
      })
    }
  }

  newContent() {
    let newRow = {}

    for (let column of this.columns) {
      newRow[column.key] = null
    }

    this.content.push(newRow)
    this.value.content = this.content
  }

  // Avisa ao componente pai todos os eventos de lazyload da tabela, para que o pai forneça os novos dados
  emitLazyEvent(event: LazyLoadEvent): void { this.lazyEvents.emit(event) }

  // Seta as variaveis de data para o valor padrão
  setDefaultPeriod() {
    this.initialDate = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
    this.finalDate = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
  }

  // emite um evento lazyload para o Pai avisando que um novo periodo foi solicitado e envia o objeto com os dados do periodo
  applyFilters(): void {
    const event: LazyLoadEvent = {
      first: null,
      rows: null,
      sortField: null,
      sortOrder: null,
      multiSortMeta: null,
      filters: {
        initialDate: { value: this.initialDate.toString() },
        finalDate: { value: this.finalDate }
      },
      globalFilter: null
    };

    this.lazyEvents.emit(event);
    this.setDefaultPeriod();
    this.modalFilters = false;
    this.messages.emit({ severity: 'success', message: 'Período alterado com sucesso' });
  }

  addEditableColumn(): void {
    if (this.selectedColumns.length < this.availableReplicColumns.length) {
      this.selectedColumns.push({ column: null, columnValue: null });
      return;
    }
    this.messages.emit({ severity: 'warn', message: `O número máximo de colunas disponíveis é ${this.availableReplicColumns.length}` });
  }

  removeEditableColumn(item: EditableColumn): void { this.selectedColumns.splice(this.selectedColumns.indexOf(item), 1); }

  // Auxiliares de tabela
  get rowsPerPage() { return this.configService.applicationConfig.rowsPerPage; }

  get rows() { return this.configService.applicationConfig.rows; }

  get pageLinks() { return this.configService.applicationConfig.pageLinks; }

}

interface EditableColumn {
  column: Column
  columnValue: any
}
