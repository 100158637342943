import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Message, MessageService } from 'primeng/api';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { ConfigService } from 'app/shared/services/config.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})

export class EcdExportComponent implements OnInit, OnDestroy {
  ecdList: Array<any> = null;
  existeLog: boolean;
  modalLog: boolean;
  private sessionSubscription: Subscription;
  title: string;
  subtitle: string;
  logAdvertencia: Message[] = null;
  pt: any;
  modalEcd: boolean;
  mesIni: any;
  mesFin: any;

  meses: any[];

  msgsModal: Message[];

  toolbarMainIni = [
    { icon: 'add', text: 'Novo', tooltip: 'Inserir novos registros', visible: true, disabled: false, color: 'green', onClick: () => this.abrirModalEcd() },
    { icon: 'download', text: 'Gerar Arquivo', tooltip: 'Gerar Arquivo para SPED', visible: true, disabled: false, color: 'default', onClick: () => this.export() },
    { icon: 'warning', text: 'Abrir Logger', tooltip: 'Abrir Logger', visible: false, disabled: false, color: 'default', onClick: () => this.modalLog = true },
  ]

  public toolbarMain = new Toolbar();

  constructor(private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private configService: ConfigService) {
  }

  ngOnInit(): void {
    this.title = 'ECD - SPED CONTÁBIL';
    this.subtitle = 'INSERIR DADOS E EXPORTAR ARQUIVO .TXT A SER USADO NO SPED CONTÁBIL';
    this.titleService.title = 'ECD - DOWNLOAD';

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.existeLog = false;

    this.toolbarService.clear();

    this.modalEcd = false;

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.sessionChange(); });

    this.toolbarMain.setAll(this.toolbarMainIni);
    this.changeToolbar();
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }


  changeToolbar() {
    this.toolbarMain.setVisible('btn3', this.existeLog ? true : false);
  }

  sessionChange() {

    this.meses = [];
    this.meses.push({ label: 'Selecione', value: 0 });

    var indice_mes = 1;
    this.pt.monthNames.forEach(e => {
      this.meses.push({ label: e, value: indice_mes });
      indice_mes = indice_mes + 1;
    });

    if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasYearSelected) {
      this.httpService.wait();
      this.httpService.get('/cadecd' +
        '?$select=dtIni,dtFin,status/id,apuracaoUser/fullName,apuracaoDatetime' +
        '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
        ' and period(dtIni,dtFin) eq ' + this.dblinkedSessionService.periodo.year.value +
        '&$orderby=dtIni')
        .subscribe(cadecd => {
          this.ecdList = cadecd.value;
          this.ecdList.forEach(ecd => {
            console.log(ecd);
            ecd.homologado = ecd.status.id === 3;
            ecd.apurado = ecd.homologado || ecd.status.id === 2;
          });

          this.checarDadosCarregados();
        },
          error => this.httpService.handleError(error, () => this.sessionChange()),
          () => this.httpService.done());
    }
  }

  checarDadosCarregados() {
    if (this.ecdList && this.ecdList.length > 0) {
      this.toolbarMain.setVisible('btn2', true);
    } else {
      this.toolbarMain.setVisible('btn2', false);
    }
  }

  onSelectAllStatus(undo: boolean, statusId: number) {
    let process: string;

    switch (statusId) {
      case 2:
        if (this.allApuracaoDisabled) {
          return false;
        }
        process = 'apuração';
        break;
      case 3:
        if (this.allHomologacaoDisabled) {
          return false;
        }
        process = 'homologação';
        break;
    }

    if (confirm('Deseja ' + (undo ? 'desfazer' : 'efetuar') + ' a ' + process + ' de todo o período?')) {
      this.httpService.wait();
      this.httpService.post('/custom/ecd/status(' + this.dblinkedSessionService.empresa.id + ',' +
        this.dblinkedSessionService.periodo.year.value + ',' +
        (undo ? statusId - 1 : statusId) + ')')
        .subscribe(() => {
          this.ecdList.forEach(ecd => {
            ecd.status.id = undo ? statusId - 1 : statusId;
            ecd.homologado = ecd.status.id === 3;
            ecd.apurado = ecd.homologado || ecd.status.id === 2;
          });

          //  this.onSelect();
        },
          error => this.httpService.handleError(error, () => this.onSelectAllStatus(undo, statusId)),
          () => this.httpService.done());

      return true;
    }

    return false;
  }

  onClick(row: any, statusId: number, disabled: boolean, event?: any) {
    if (!disabled && (!event || event.keyCode === 32)) {
      let undo: boolean;
      let process: string;

      switch (statusId) {
        case 2:
          undo = row.apurado;
          process = 'apuração';
          break;
        case 3:
          undo = row.homologado;
          process = 'homologação';
          break;
      }

      if (confirm('Deseja ' + (!undo ? 'desfazer' : 'efetuar') + ' a ' + process + ' do período?')) {
        this.onChange(row);
      } else {
        if (statusId == 2) {
          row.apurado = !row.apurado;
        } else if (statusId == 3) {
          row.homologado = !row.homologado;
        }
      }
    }

    return false;
  }

  onChange(row: any) {
    if (row.status.id === 1) this.recalcularSaldo(row)

    if (row.homologado) {
      row.status.id = 3;
    } else if (row.apurado) {
      row.status.id = 2;
    } else {
      row.status.id = 1;
    }

    this.httpService.wait();
    this.httpService.post('/custom/ecd/status(' + row.id + ',' + row.status.id + ')')
      .subscribe(result => this.resultado(result),
        error => this.httpService.handleError(error, () => this.onChange(row)),
        () => this.httpService.done());
  }

  resultado(result: any) {
    this.logAdvertencia = [];
    this.existeLog = false;

    result.forEach(element => {
      if (element.error) {
        this.logAdvertencia.push({ severity: 'warn', summary: 'Advertência:', detail: element.message });
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência:', detail: element.message });
        this.existeLog = true;

        this.messageService.add({
          severity: 'warn',
          key: 'messageLinnks',
          summary: 'Advertência:',
          detail: 'Ocorreram erros que impossibilitaram a ação, verifique os detalhes no log'
        });
      } else {
        this.messageService.add({
          severity: 'success',
          key: 'messageLinnks',
          summary: 'Sucesso:',
          detail: element.message
        });
      }
    });

    this.changeToolbar();
    this.sessionChange();
  }

  export() {
    if (confirm('O arquivo txt do SPED Contábil será exportado. Deseja continuar?')) {
      this.httpService.wait();
      this.httpService.post('/custom/ecd/export(' + this.dblinkedSessionService.empresa.cnpj + ',' +
        this.dblinkedSessionService.periodo.year.value + ')')
        .subscribe(result => this.download(result),
          error => this.httpService.handleError(error, () => this.export()),
          () => this.httpService.done());
    }
  }

  download(result: any) {
    if (!result.error) {
      if (!this.ecdList || this.ecdList.length === 0) {
        this.sessionChange();
      }

      window.open(this.httpService.url('/custom/file/' + result.file), '_blank');
    }
  }

  get selected(): Array<any> {
    if (!this.ecdList) {
      return null;
    }

    return this.ecdList.filter(ecd => ecd.selected);
  }

  get hasSelected(): boolean {
    return this.selected && this.selected.length > 0;
  }

  get hasNotSelected(): boolean {
    return this.ecdList && this.selected && this.ecdList.length === this.selected.length;
  }

  get hasSelectedApurado(): boolean {
    return this.selected && this.selected.findIndex(ecf => ecf.apurado) > -1;
  }

  get hasApurado(): boolean {
    return this.ecdList && this.ecdList.findIndex(ecf => ecf.apurado) > -1;
  }

  get hasNotApurado(): boolean {
    return this.ecdList && this.ecdList.findIndex(ecf => !ecf.apurado) > -1;
  }

  get hasAllApurado(): boolean {
    return this.hasApurado && !this.hasNotApurado;
  }

  get hasAllNotApurado(): boolean {
    return !this.hasApurado && this.hasNotApurado;
  }

  get hasHomologado(): boolean {
    return this.ecdList && this.ecdList.findIndex(ecf => ecf.homologado) > -1;
  }

  get hasNotHomologado(): boolean {
    return this.ecdList && this.ecdList.findIndex(ecf => !ecf.homologado) > -1;
  }

  get hasAllHomologado(): boolean {
    return this.hasHomologado && !this.hasNotHomologado;
  }

  get hasAllNotHomologado(): boolean {
    return !this.hasHomologado && this.hasNotHomologado;
  }

  get allApuracaoDisabled(): boolean {
    return this.hasHomologado || (this.hasApurado && !this.hasAllApurado);
  }

  get allHomologacaoDisabled(): boolean {
    return this.hasNotApurado || (this.hasHomologado && !this.hasAllHomologado);
  }

  get allApuracaoIndeterminate(): boolean {
    return this.hasApurado && !this.hasAllApurado;
  }

  get allHomologacaoIndeterminate(): boolean {
    return this.hasHomologado && !this.hasAllHomologado;
  }

  get allSelectedIndeterminate(): boolean {
    return this.hasSelected && !this.hasNotSelected;
  }


  hideMessage() {
    this.logAdvertencia = [];


    this.existeLog = false;
  }

  abrirModalEcd() {
    this.modalEcd = true;
  }

  /* Método que grava um ou mais registros novos na tabela
  contabil.cad_ecd para uma empresa */
  inserirNovoEcd() {
    this.msgsModal = [];

    /* Inicialmente, realizar a validação do intervalo de
    meses selecionado */
    if ((this.mesIni == null) || (this.mesIni == undefined) ||
      (this.mesIni == 0)) {
      this.msgsModal.push({ severity: 'warn', summary: 'Erro', detail: "Favor inserir um mês inicial." });
    } else if ((this.mesFin == null) || (this.mesFin == undefined) ||
      (this.mesFin == 0)) {
      this.msgsModal.push({ severity: 'warn', summary: 'Erro', detail: "Favor inserir um mês final." });
    } else if (this.mesIni > this.mesFin) {
      this.msgsModal.push({ severity: 'warn', summary: 'Erro', detail: "O mês inicial deve ser anterior ao mês final." });
    } else {

      /* Caso as validações estejam OK, iniciar a inserção dos dados. */
      let dados = {
        usuarioId: this.sessionService.loggedUser.id,
        mesInicial: this.mesIni,
        mesFinal: this.mesFin,
        ano: this.dblinkedSessionService.periodo.year.value,
        empresaId: this.dblinkedSessionService.empresa.id
      }

      this.httpService.wait();
      this.httpService.post('/custom/ecd/inserir-novo-ecd', dados)
        .subscribe(
          res => {
            if (res["error"] == false) {
              this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: res["message"] });
              this.sessionChange();
            } else {
              this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: res["message"] });
            }
            this.httpService.done();
          },
          err => {
            console.log(err);
            this.httpService.done();
          }
        );

      this.modalEcd = false;
      this.mesIni = null;
      this.mesFin = null;

    }

  }

  recalcularSaldo(event: any) {
    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      ecd_id: event.id,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/recalcular-saldo-fin', infos)
      .subscribe(
        res => {
          this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: res["mensagem"] })
          this.httpService.done()
        },
        err => {
          console.log(err)
          this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Sucesso', detail: "Erro no API ao recalcular os saldos, contate o suporte: " + err.message })
          this.httpService.done()
        }
      );
  }

}
