import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { EcdEcfService } from './../../contabil/services/ecf-ecf.service';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';
import { MovParteACsll } from 'app/ecf/model/mov-parte-a-csll.model';
import { MovParteADeducoesCsll } from 'app/ecf/model/mov-parte-a-deducoes-csll.model';
import { TbCsllM350 } from 'app/ecf/model/tb-csll-m350.model';
import { TbCsllN660 } from 'app/ecf/model/tb-csll-n660.model';
import { MovSaldosContabeis } from 'app/contabil/model/mov-saldos-contabeis.model';
import { TbRelacMovParteACsllSaldosContabeis } from 'app/ecf/model/tb-relac-mov-parte-a-csll-saldos-contabeis.model';
import { TbRelacMovParteADeducoesSaldosContabeisCsll } from 'app/ecf/model/tb-relac-mov-parte-a-deducoes-saldos-contabeis-csll.model';
import { TbIndRelacao } from 'app/ecf/model/tb-ind-relacao.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { MovParteBCsll } from 'app/ecf/model/mov-parte-b-csll.model';
import { MovParteBCsllLcto } from 'app/ecf/model/mov-parte-b-csll-lcto.model';
import { TbPrejAcumuladoCsll } from 'app/ecf/model/tb-prej-acumulado-csll.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { UtilService } from 'app/shared/services/util.service';
import { TitleService } from 'app/shared/services/title.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { isNullOrUndefined } from 'util';
import { promise } from 'protractor';

@Component({
    selector: 'app-teste',
    templateUrl: './parte-a-m300-csll.component.html',
    styleUrls: ['./parte-a-m300-csll.component.scss'],
    providers: [EcdEcfService]
})

export class ParteAM300CsllComponent implements OnInit, OnDestroy {
    planoSelecionados: any; // TODO: REVIEW
    contasSelecionadas: any; // TODO: REVIEW
    contasSelecionadasDeducao: any; // TODO: REVIEW

    private sessionSubscription: Subscription;

    cadEcd: Array<CadEcd>;

    modalConta: boolean;
    modalContaDeducoes: boolean;
    modalMapeamento: boolean;
    modalMapeamentoExcluir: boolean;
    modalMapeamentoDeducoes: boolean;
    modalSelecao: boolean;
    modalSelecaoDeducoes: boolean;
    modalInexistente: boolean;
    modalPrejuizoAcumulado: boolean;

    movParteA: MovParteACsll;
    movParteADeducoes: MovParteADeducoesCsll;
    movParteAListAno: Array<MovParteACsll> = new Array();
    movParteADeducoesListAno: Array<MovParteADeducoesCsll> = new Array();

    tbIrpj: TbCsllM350;
    tbN620: TbCsllN660;

    listaList: Array<any>;
    listaListExc: Array<any>;
    listaListDed: Array<any>;
    listaListSoma: Array<any>;
    listaListSomaExc: Array<any>;
    listaListSomaDeducoes: Array<any>;
    listaListSomaResultado: Array<any>;
    listaSomaResultadoAntesIrpj: Array<any>;
    listaSomaLucroAntesCompensacao: Array<any>;
    listaPrejFiscalCompensado: Array<any>;
    listaSaldoPrejFiscalCompensar: Array<any>;
    listaCompensacaoPrejPeriodoAnterior: Array<any>;
    listaLucroAjustado: Array<any>;
    listaImpCsll: Array<any>;
    listaAdicImpRenda: Array<any>;
    listaTotalCsll: Array<any>;
    listaTotalAntecCsll: Array<any>;
    listaValorLiqRecolher: Array<any>;

    listSomaCsll: Array<any>;
    balancetesSelecionados: Array<MovSaldosContabeis> = new Array();
    balancete: Array<MovSaldosContabeis> = new Array();
    movParteAList: Array<MovParteACsll> = new Array();
    movParteADeducoesList: Array<MovParteADeducoesCsll> = new Array();
    movParteANew: Array<MovParteACsll> = new Array();
    tbRelacIrpj: Array<TbRelacMovParteACsllSaldosContabeis>;
    tbRelacIrpjList: Array<TbRelacMovParteACsllSaldosContabeis>;
    tbRelacDeducoes: Array<TbRelacMovParteADeducoesSaldosContabeisCsll>;
    tbRelacDeducoesList: Array<TbRelacMovParteADeducoesSaldosContabeisCsll>;

    tbCsllM350ListAll: Array<TbCsllM350> = new Array();
    tbCsllM350Selecionados: Array<TbCsllM350> = new Array();

    indicadorLcto: SelectItem[];

    tbIrpjM300Relacionados: Array<TbCsllM350> = new Array();

    tbCsllN660ListAll: Array<TbCsllN660> = new Array();
    tbIrpjN620Selecionados: Array<TbCsllN660> = new Array();
    tbIndRelacao: Array<TbIndRelacao> = new Array();
    planoContas: Array<PlanoContas> = new Array();
    planoContasFull: Array<PlanoContas> = new Array();

    newTbRelacIrpj: TbRelacMovParteACsllSaldosContabeis;
    atuaTbRelacIrpj: TbRelacMovParteACsllSaldosContabeis;
    atuaTbRelacDeducoes: TbRelacMovParteADeducoesSaldosContabeisCsll;
    saldosRelacionados: Array<TbRelacMovParteACsllSaldosContabeis> = new Array();
    saldosRelacionadosDeducoes: Array<TbRelacMovParteADeducoesSaldosContabeisCsll> = new Array();
    display = false;
    tipoConta: number;
    mes: number;

    msgs: Message[] = [];

    opcoesMovParteB: SelectItem[];
    movParteBList: Array<MovParteBCsll> = new Array();
    movParteBOpcoes: Array<MovParteBCsll> = new Array();
    movParteBSelecionados: Array<MovParteBCsll> = new Array();
    addParteB: boolean;
    movParteBRelacionados: Array<MovParteBCsllLcto>;
    movParteBLcto: MovParteBCsllLcto;

    movParteBDemonstrativo: any = new Array(); // TODO: REVIEW
    desabilitarLancamentos: boolean;
    desabilitarLancamentosDeducoes: boolean;

    tbPrejAcumulado: TbPrejAcumuladoCsll = new TbPrejAcumuladoCsll();

    private editSaldosRelacionadosTimeout: NodeJS.Timer;

    titlePrincipal: string;
    subtitle: string;

    contadorLinha: number = 0;
    contadorLinhaN660: number = 0;


    constructor(private httpService: HttpService,
                private sessionService: SessionService,
                private dblinkedSessionService: DblinkedSessionService,
                private utilService: UtilService,
                private titleService: TitleService,
                private ecdEcfService: EcdEcfService,
                private messageService: MessageService) {
      this.titleService.title = 'Livro de Apuração do Lucro Real (CSLL)';
    }

    ngOnInit() {
        this.titlePrincipal = 'LIVRO DE APURAÇÃO DO LUCRO REAL (LALUR - CSLL)';
        this.subtitle = 'CÁLCULO DO LALUR - VISÃO M350 (CSLL)';
        this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

        this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
                                                                     () => { this.periodChanged(); });

        this.contadorLinha = 0;
        this.contadorLinhaN660 = 0;
    }

    ngOnDestroy() {
      this.dblinkedSessionService.hidePeriods();

      this.sessionService.destroySubscribe(this.sessionSubscription);
    }

    periodChanged() {
        if (!this.dblinkedSessionService.hasEmpresaSelected || !this.dblinkedSessionService.hasPeriodoSelected) {
          return;
        }

        this.ecdEcfService.load(this.dblinkedSessionService.empresa.id, this.year);

        this.tbPrejAcumulado = new TbPrejAcumuladoCsll();
        this.tbPrejAcumulado.empresa = new Empresa(this.empresa.id);
        this.tbPrejAcumulado.periodo = this.year - 1;
        this.tbPrejAcumulado.valorPrejuizo = 0.00;

        this.display = true;
        this.tipoConta = null;
        this.mes = 0;
        this.tbCsllM350Selecionados = new Array();
        this.tbIrpjM300Relacionados = new Array();
        this.tbIrpjN620Selecionados = new Array();
        this.balancetesSelecionados = new Array();
        this.movParteA = new MovParteACsll();
        this.movParteBLcto = new MovParteBCsllLcto();
        this.movParteADeducoes = new MovParteADeducoesCsll();
        this.addParteB = false;
        this.movParteBDemonstrativo = new Array<any>();
        this.desabilitarLancamentos = false;
        this.desabilitarLancamentosDeducoes = false;
        this.listaListSoma = new Array<any>();
        this.listaListSomaExc = new Array<any>();
        this.listSomaCsll = new Array<any>();
        this.saldosRelacionados = new Array<TbRelacMovParteACsllSaldosContabeis>();
        this.saldosRelacionadosDeducoes = new Array<TbRelacMovParteADeducoesSaldosContabeisCsll>();
        this.movParteBRelacionados = new Array();

        this.tbRelacIrpjList = new Array();
        this.tbRelacDeducoesList = new Array();

        /*this.httpService.wait();
        this.httpService.get('/custom/ecd/m350', this.empresa.id)
            .subscribe(tbCsllM350List => this.tbCsllM350ListAll = tbCsllM350List.value,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());*/

        this.httpService.wait();
        this.httpService.get('/custom/ecd/m350Relac', this.empresa.id)
            .subscribe(tbIrpjM300Relacionados => this.tbIrpjM300Relacionados = tbIrpjM300Relacionados.value,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/tbindrelacao?$select=id,cod,descricao')
            .subscribe(tbIndRelacaoList => this.tbIndRelacao = tbIndRelacaoList.value,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/tbcsll', [this.year, this.empresa.id])
            .subscribe(tbIrpjList => this.listaList = tbIrpjList,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/tbcsllexc', [this.year, this.empresa.id])
            .subscribe(tbIrpjListExc => this.listaListExc = tbIrpjListExc,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/tbdeducoescsll', [this.year, this.empresa.id])
            .subscribe(listaListDed => this.listaListDed = listaListDed,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());


        this.httpService.wait();
        this.httpService.get('/custom/ecd/tbsomadeducoescsll', [this.year, this.empresa.id])
            .subscribe(listaListSomaDeducoes => this.listaListSomaDeducoes = listaListSomaDeducoes,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['lucro_prej_acum', this.year, this.empresa.id])
            .subscribe(listaListSomaResultado => this.listaListSomaResultado = listaListSomaResultado,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['irpj_csll', this.year, this.empresa.id])
            .subscribe(listSomaCsll => this.listSomaCsll = listSomaCsll,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['lucro_prej_acum_antes_irpj_csll', this.year, this.empresa.id])
            .subscribe(listaSomaResultadoAntesIrpj => this.listaSomaResultadoAntesIrpj = listaSomaResultadoAntesIrpj,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['adicoes_csll', this.year, this.empresa.id])
            .subscribe(listaListSoma => this.listaListSoma = listaListSoma,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['exclusoes_csll', this.year, this.empresa.id])
            .subscribe(listaListSomaExc => this.listaListSomaExc = listaListSomaExc,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['lucro_antes_comp_prej_fiscal_csll', this.year, this.empresa.id])
                        .subscribe(listaSomaLucroAntesCompensacao =>
                                     this.listaSomaLucroAntesCompensacao = listaSomaLucroAntesCompensacao,
                                   error => this.httpService.handleError(error, () => this.periodChanged()),
                                   () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['prej_fiscal_compensar_csll', this.year, this.empresa.id])
            .subscribe(listaPrejFiscalCompensado => this.listaPrejFiscalCompensado = listaPrejFiscalCompensado,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['prej_fiscal_compensado_csll', this.year, this.empresa.id])
                        .subscribe(l => this.listaCompensacaoPrejPeriodoAnterior = l,
                                   error => this.httpService.handleError(error, () => this.periodChanged()),
                                   () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['saldo_prej_compensar_csll', this.year, this.empresa.id])
            .subscribe(listaSaldoPrejFiscalCompensar => this.listaSaldoPrejFiscalCompensar = listaSaldoPrejFiscalCompensar,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['lucro_ajustado_csll', this.year, this.empresa.id])
            .subscribe(listaLucroAjustado => this.listaLucroAjustado = listaLucroAjustado,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['csll_sobre_lucro', this.year, this.empresa.id])
            .subscribe(listaImpCsll => this.listaImpCsll = listaImpCsll,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listapartea', ['total_csll_recolher', this.year, this.empresa.id])
            .subscribe(listaTotalCsll => this.listaTotalCsll  = listaTotalCsll,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listaparteafunction', ['csll_recolher', this.year, this.empresa.id, 1])
            .subscribe(listaTotalAntecCsll => this.listaTotalAntecCsll  = listaTotalAntecCsll,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/custom/ecd/listaparteafunction', ['csll_recolher', this.year, this.empresa.id, 2])
            .subscribe(listaValorLiqRecolher => this.listaValorLiqRecolher = listaValorLiqRecolher,
            error => this.httpService.handleError(error, () => this.periodChanged()),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/tbprejacumuladocsll?$select=id,periodo,valorPrejuizo' +
                                             '&$filter=empresa/id eq ' + this.empresa.id +
                                             ' and periodo eq ' + (this.year - 1))
                        .subscribe(tbPrejAcumulado => {
                                     if (tbPrejAcumulado.value.length === 1) {
                                       this.tbPrejAcumulado = new TbPrejAcumuladoCsll(tbPrejAcumulado.value[0]);
                                     }
                                   },
                                   error => this.httpService.handleError(error, () => this.periodChanged()),
                                   () => this.httpService.done());

        this.indicadorLcto = [];
        this.indicadorLcto.push({label: 'Selecione', value: null});
        this.indicadorLcto.push({label: 'Base de cálculo negativa da CSLL', value: 'B'});
        this.indicadorLcto.push({label: 'Crédito', value: 'C'});
        this.indicadorLcto.push({label: 'Débito', value: 'D'});
        this.indicadorLcto.push({label: 'Prejuízo do Exercício', value: 'P'});

    }

    excMovParteA(id: number) {
        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletetbmovparteacsll', [id, this.empresa.id])
                .subscribe(() => this.periodChanged(),
                error => this.httpService.handleError(error, () => this.excMovParteA(id)),
                () => this.httpService.done());
        }
    }

    excMovParteADeducoes(id: number) {
        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletetbmovparteadeducoescsll', [id, this.empresa.id])
                .subscribe(() => this.periodChanged(),
                error => this.httpService.handleError(error, () => this.excMovParteADeducoes(id)),
                () => this.httpService.done());
        }
    }


    /*Método acionado quando o usuário clica em alguma Conta e Mês*/
    onContaSelect(idIrpj: number, mes: number) {

        this.desabilitarLancamentos = false;
        this.tbIrpj = new TbCsllM350(idIrpj);
        this.mes = mes;
        this.modalConta = true;
        this.modalSelecao = false;
        this.verifMes(mes);

        this.httpService.wait();
        this.httpService.get('/movparteacsll?$select=id,data,hist,valor,tbCsllM350,tbIndRelacao,tbIndRelacao/id,tbIndRelacao/cod,' +
            'tbIndRelacao/descricao,planoContas/id,planoContas/codCta,planoContas/descrCta' +
            '&$filter=tbCsllM350/id eq ' + this.tbIrpj.id +
            ' and data eq ' + this.movParteA.data.getTime() +
            ' and cadEcd/empresa/id eq ' + this.empresa.id)
            .subscribe(movVerif => this.existRelac(movVerif.value),
            error => this.httpService.handleError(error, () => this.onContaSelect(idIrpj, mes)),
            () => this.httpService.done());

        this.httpService.wait();
        this.httpService.get('/movparteacsll?$select=id,data,hist,valor,tbCsllM350,tbIndRelacao,tbIndRelacao/id,tbIndRelacao/cod,' +
            'tbIndRelacao/descricao,planoContas/id,planoContas/codCta,planoContas/descrCta,cadEcd/empresa/id&$' +
            'filter=tbCsllM350/id eq ' + this.tbIrpj.id + '&$orderby=data')
            .subscribe(movParteAListAno => this.movParteAListAno = movParteAListAno.value,
            error => this.httpService.handleError(error, () => this.onContaSelect(idIrpj, mes)),
            () => this.httpService.done());

        this.opcoesMovParteB = [];
        this.opcoesMovParteB.push({ label: 'Selecione uma conta da Parte B', value: null });

        this.httpService.wait();
        this.httpService.get('/movpartebcsll?$&$filter=empresa/id eq ' + this.empresa.id + '&$orderby=data')
                        .subscribe(movParteBList => this.loadParteB(movParteBList.value),
                                   error => this.httpService.handleError(error, () => this.periodChanged()),
                                   () => this.httpService.done());
    }

    loadParteB(value: Array<MovParteBCsll>) {
        this.movParteBOpcoes = value;

        this.movParteBOpcoes.forEach(element => {
        this.opcoesMovParteB.push({ label: element.cod + ' - ' + element.descricao, value: element });
        });

    }



    onContaSelectDeducoes(idN620: number, mes: number) {
        this.periodChanged();
        this.desabilitarLancamentosDeducoes = false;
        this.tbN620 = new TbCsllN660(idN620);
        this.mes = mes;

        this.modalContaDeducoes = true;
        this.modalSelecaoDeducoes = false;

        this.verifMes(mes);

        this.httpService.wait();
        this.httpService.get('/movparteadeducoescsll?$select=id,data,hist,valor,tbCsllN660,tbIndRelacao,tbIndRelacao/id,tbIndRelacao/cod,' +
            'tbIndRelacao/descricao,planoContas/id,planoContas/codCta,planoContas/descrCta' +
            '&$filter=tbCsllN660/id eq ' + this.tbN620.id +
            ' and data eq ' + this.movParteADeducoes.data.getTime() +
            ' and cadEcd/empresa/id eq ' + this.empresa.id)
            .subscribe(movDedVerif => this.existRelacDeducoes(movDedVerif.value),
            error => this.httpService.handleError(error, () => this.onContaSelectDeducoes(idN620, mes)),
            () => this.httpService.done());


        this.httpService.wait();
        this.httpService.get('/movparteadeducoescsll?$select=id,data,hist,valor,tbCsllN660,tbIndRelacao,tbIndRelacao/id,tbIndRelacao/cod,' +
            'tbIndRelacao/descricao,planoContas/id,planoContas/codCta,planoContas/descrCta' +
            '&$filter=tbCsllN660/id eq ' + this.tbN620.id + ' and cadEcd/empresa/id eq ' + this.empresa.id +
            '&$orderby=data')
            .subscribe(movParteADeducoesListAno => this.movParteADeducoesListAno = movParteADeducoesListAno.value,
            error => this.httpService.handleError(error, () => this.onContaSelectDeducoes(idN620, mes)),
            () => this.httpService.done());

    }

    existRelac(param: any) {

        this.movParteA = new MovParteACsll(param[0]);
        this.movParteBRelacionados = new Array();

        /*verifica se o movParteA existe*/
        if (this.movParteA.id === null || this.movParteA.id === undefined) {
            this.modalInexistente = true;
            this.modalConta = false;
        }

        if (this.movParteA.id) {
            this.movParteA.tbTipoLcto = null;
            this.movParteA.movApurReal = null;
            this.movParteA.cadCcus = null;
            this.movParteA.planoContas = this.movParteA.planoContas ?
            this.planoContas.find(planoContas => planoContas.id === this.movParteA.planoContas.id) : null;
            this.movParteA.tbIndRelacao = this.movParteA.tbIndRelacao ?
            this.tbIndRelacao.find(relacao => relacao.id === this.movParteA.tbIndRelacao.id) : null;
            this.movParteA.data = this.movParteA.data;

            // this.saldosRelacionados

            this.httpService.wait();
            this.httpService.get('/tbrelacmovparteacsllsaldoscontabeis?$select=id,saldoRelacionado,' +
            'movSaldosContabeis/cadEcd/dtIni,movSaldosContabeis/id,' +
                'movSaldosContabeis/planoContas/codCta,movSaldosContabeis/planoContas/descrCta,' +
                'movSaldosContabeis/cadCcus/codCcus,movSaldosContabeis/cadCcus/descrCcus,movSaldosContabeis/indDcIni,' +
                'movSaldosContabeis/vlSldIni,movSaldosContabeis/indDcFin,movSaldosContabeis/vlSldFin' +
                '&$filter=movParteACsll/id eq ' + this.movParteA.id)
                // '&$orderby=movSaldosContabeis/planoContas/codCta')
                .subscribe(saldosRelacionados => {
                    this.saldosRelacionados = saldosRelacionados.value;
                    if (this.saldosRelacionados && this.saldosRelacionados.length > 0) {
                        this.desabilitarLancamentos = true;
                    }

                },
                error => this.httpService.handleError(error, () => this.existRelac(param)),
                () => this.httpService.done());

            this.httpService.wait();
            this.httpService.get('/movpartebcslllcto?$select=id,data,valor,indLcto,descricao,movParteACsll/id,movParteB/id,' +
            'movParteB/cod,movParteB/descricao&$filter=movParteACsll/id eq ' + this.movParteA.id)
            .subscribe(movParteBRelacionados => {
                this.movParteBRelacionados = movParteBRelacionados.value;
                if (this.movParteBRelacionados && this.movParteBRelacionados.length > 0) {
                        this.desabilitarLancamentos = true;
                    }
            },
            error => this.httpService.handleError(error, () => this.existRelac(param)),
            () => this.httpService.done());

        } else {
            this.httpService.wait();
            this.httpService.get('/tbrelacmovparteacsllsaldoscontabeis?$select=id,saldoRelacionado,movSaldosContabeis,' +
            'movSaldosContabeis/id,movSaldosContabeis/planoContas/codCta,movSaldosContabeis/planoContas/descrCta,' +
            'movSaldosContabeis/indDcIni,movSaldosContabeis/vlSldIni,movSaldosContabeis/indDcFin,' +
            'movSaldosContabeis/vlSldFin,movParteACsll/id' +
                '&$filter=movParteACsll/id is null')
                .subscribe(tbRelacIrpj => this.tbRelacIrpj = tbRelacIrpj.value,
                error => this.httpService.handleError(error, () => this.existRelac(param)),
                () => this.httpService.done());
        }
    }

 existRelacDeducoes(param: any) {
        this.movParteADeducoes = new MovParteADeducoesCsll(param[0]);

        /*verifica se o movParteA existe*/
        if (this.movParteADeducoes.id === null || this.movParteADeducoes.id === undefined) {
            this.modalInexistente = true;
            this.modalContaDeducoes = false;
        }

        if (this.movParteADeducoes.id) {

            this.movParteADeducoes.planoContas = this.movParteADeducoes.planoContas ?
            this.planoContas.find(planoContas => planoContas.id === this.movParteADeducoes.planoContas.id) : null;
            this.movParteADeducoes.tbIndRelacao = this.movParteADeducoes.tbIndRelacao ?
            this.tbIndRelacao.find(relacao => relacao.id === this.movParteADeducoes.tbIndRelacao.id) : null;
            this.movParteADeducoes.data = this.movParteADeducoes.data;


            this.httpService.wait();
            this.httpService.get('/tbrelacmovparteadeducoessaldoscontabeiscsll?$select=id,saldoRelacionado,' +
            'movSaldosContabeis/cadEcd/dtIni,movSaldosContabeis/id,movSaldosContabeis/planoContas/codCta,movSaldosContabeis' +
            '/planoContas/descrCta,movSaldosContabeis/indDcIni,movSaldosContabeis/vlSldIni,movSaldosContabeis/indDcFin' +
            ',movSaldosContabeis/vlSldFin' +
                '&$filter=movParteADeducoesCsll/id eq ' + this.movParteADeducoes.id).subscribe(saldosRelacionadosDeducoes => {
                    this.saldosRelacionadosDeducoes = saldosRelacionadosDeducoes.value;
                    if (this.saldosRelacionadosDeducoes.length > 0) {
                        this.desabilitarLancamentosDeducoes = true;
                    }

                },
                error => this.httpService.handleError(error, () => this.existRelacDeducoes(param)),
                () => this.httpService.done());
        } else {
            this.httpService.wait();
            this.httpService.get('/tbrelacmovparteacsllsaldoscontabeis?$select=id,saldoRelacionado,movSaldosContabeis,' +
            'movSaldosContabeis/id,movSaldosContabeis/planoContas/codCta,movSaldosContabeis/planoContas/descrCta,' +
            'movSaldosContabeis/indDcIni,movSaldosContabeis/vlSldIni,movSaldosContabeis/indDcFin,movSaldosContabeis' +
            '/vlSldFin,movParteACsll/id' +
                '&$filter=movParteACsll/id is null')
                .subscribe(tbRelacDeducoes => this.tbRelacDeducoes = tbRelacDeducoes.value,
                error => this.httpService.handleError(error, () => this.existRelacDeducoes(param)),
                () => this.httpService.done());
        }
    }
    exibirModalSelecao() {
        this.modalSelecao = true;

        this.httpService.wait();
        this.httpService.get('/movsaldoscontabeis?$select=id,cadEcd/dtIni,vlSldIni,indDcIni,cadEcd/dtFin,vlSldFin,' +
            'indDcFin,planoContas/id,planoContas/codCta,planoContas/descrCta,' +
            'cadCcus/codCcus&$filter=cadEcd/dtIni eq ' + this.movParteA.data.getTime() + ' and + ' +
            'planoContas/empresa/id eq ' + this.empresa.id )
            // 'planoContas/empresa/id eq ' + this.empresa.id + '&$orderby=planoContas/codCta')
            .subscribe(balancete => this.balancete = balancete.value,
            error => this.httpService.handleError(error, () => this.exibirModalSelecao()),
            () => this.httpService.done());
    }
    exibirModalSelecaoDeducoes() {
        this.modalSelecaoDeducoes = true;

        this.httpService.wait();
        this.httpService.get('/movsaldoscontabeis?$select=id,cadEcd/dtIni,vlSldIni,indDcIni,cadEcd/dtFin,vlSldFin,indDcFin,' +
                             'planoContas/id,planoContas/codCta,planoContas/descrCta' +
            '&$filter=cadEcd/dtIni eq ' + this.movParteADeducoes.data.getTime() + ' and ' +
            'planoContas/empresa/id eq ' + this.empresa.id )
            //'planoContas/empresa/id eq ' + this.empresa.id + '&$orderby=planoContas/codCta')
            .subscribe(balancete => this.balancete = balancete.value,
            error => this.httpService.handleError(error, () => this.exibirModalSelecaoDeducoes()),
            () => this.httpService.done());
    }

    adicionarPrejuizoAcumulado() {
      this.modalPrejuizoAcumulado = true;
    }

    get valorPrejuizo(): number {
      return this.tbPrejAcumulado && this.tbPrejAcumulado.valorPrejuizo > 0 ? this.tbPrejAcumulado.valorPrejuizo : 0.00;
    }

    fixValorPrejuizo() {
      this.tbPrejAcumulado.valorPrejuizo = this.utilService.parseDecimal(this.tbPrejAcumulado.valorPrejuizo);
      if (!this.tbPrejAcumulado.valorPrejuizo || this.tbPrejAcumulado.valorPrejuizo < 0) {
        this.tbPrejAcumulado.valorPrejuizo = 0.00;
      }
    }

    salvarValorPrejuizo() {
      this.fixValorPrejuizo();

      if (this.tbPrejAcumulado.id > 0) {
        this.httpService.wait();
        this.httpService.patch('/tbprejacumuladocsll', this.tbPrejAcumulado.id, this.tbPrejAcumulado)
                        .subscribe(() => this.success(),
                                  error => this.httpService.handleError(error, () => this.salvarValorPrejuizo()),
                                  () => this.httpService.done());

        this.modalPrejuizoAcumulado = false;
      } else {
        this.httpService.wait();
        this.httpService.post('/tbprejacumuladocsll', this.tbPrejAcumulado)
                        .subscribe(() => this.success(),
                                  error => this.httpService.handleError(error, () => this.salvarValorPrejuizo()),
                                  () => this.httpService.done());
      }
    }

    alerta() {
        this.periodChanged();
        this.modalConta = false;
        this.modalContaDeducoes = false;
        this.modalMapeamento = false;
        this.modalSelecao = false;
        this.modalMapeamentoDeducoes = false;
        this.tbRelacIrpjList = new Array();
        this.tbRelacDeducoesList = new Array();
        this.movParteAList = new Array();
        this.balancetesSelecionados = new Array();
    }

    /*Método Utilizado para definir qual mês será realizada a busca */
    verifMes(mes: number) {
      const monthStr = ('0' + mes.toString()).substr(mes.toString().length - 1);
      this.movParteA.data = new Date(this.year + '-' + monthStr + '-01 12:00:00');
      this.movParteADeducoes.data = new Date(this.year + '-' + monthStr + '-01 12:00:00');
    }

    salvarMapeamento(movSaldos: MovSaldosContabeis) {
        this.newTbRelacIrpj = new TbRelacMovParteACsllSaldosContabeis();
        this.newTbRelacIrpj.movParteACsll = new MovParteACsll(this.movParteA);
        this.newTbRelacIrpj.movSaldosContabeis = new MovSaldosContabeis(movSaldos);

        this.httpService.wait();
        this.httpService.post('/tbrelacmovparteacsllsaldoscontabeis', this.newTbRelacIrpj)
            .subscribe(() => this.successMapeamento(),
            error => this.httpService.handleError(error, () => this.salvarMapeamento(movSaldos)),
            () => this.httpService.done());

    }

    adicionarMapeamento() {

        this.balancetesSelecionados.forEach(tbRelacMap => {
            const tb = new TbRelacMovParteACsllSaldosContabeis();
            tb.movSaldosContabeis = new MovSaldosContabeis(tbRelacMap.id);
            tb.movParteACsll = new MovParteACsll(this.movParteA.id);
            tb.saldoRelacionado = tbRelacMap.vlSldFin;
            this.tbRelacIrpjList.push(tb);
        });

        this.httpService.wait();
        this.httpService.post('/custom/ecd/salvarlistatbrelacmovparteacsllsaldoscontabeis', this.tbRelacIrpjList)
            .subscribe(() => this.atualizarSaldoMovParteA(),
            error => this.httpService.handleError(error, () => this.adicionarMapeamento()),
            () => this.httpService.done());

    }

    adicionarMapeamentoDeducoes() {

        this.balancetesSelecionados.forEach(tbRelacDedMap => {
            const tbDed = new TbRelacMovParteADeducoesSaldosContabeisCsll();
            tbDed.movSaldosContabeis = new MovSaldosContabeis(tbRelacDedMap.id);
            tbDed.movParteADeducoesCsll = new MovParteADeducoesCsll(this.movParteADeducoes.id);
            tbDed.saldoRelacionado = tbRelacDedMap.vlSldFin;
            this.tbRelacDeducoesList.push(tbDed);
        });

        this.httpService.wait();
        this.httpService.post('/custom/ecd/salvarlistatbrelacmovparteadeducoessaldoscontabeiscsll', this.tbRelacDeducoesList)
            .subscribe(() => this.onContaSelectDeducoes(this.tbN620.id, this.mes),
            error => this.httpService.handleError(error, () => this.adicionarMapeamentoDeducoes()),
            () => this.httpService.done());

    }

    successMapeamento() {
        this.httpService.wait();
        this.httpService.get('/tbrelacmovparteacsllsaldoscontabeis?$select=id,saldoRelacionado,movSaldosContabeis,movSaldosContabeis/id,' +
            'movSaldosContabeis/planoContas/codCta,movSaldosContabeis/planoContas/descrCta,movSaldosContabeis/indDcIni,' +
            'movSaldosContabeis/vlSldIni,movSaldosContabeis/indDcFin,movSaldosContabeis/vlSldFin,movParteACsll/id' +
            '&$filter=movParteACsll' + (this.movParteA ? '/id eq ' + this.movParteA.id : ' is null'))
            .subscribe(tbRelacIrpj => this.tbRelacIrpj = tbRelacIrpj.value,
            error => this.httpService.handleError(error, () => this.successMapeamento()),
            () => this.httpService.done());
        this.modalSelecao = false;
    }


    select(balanceteSelecionado: MovSaldosContabeis) {
        alert(balanceteSelecionado.planoContas.codCta);
    }

    selected(balanceteSelecionado: MovSaldosContabeis) {
        alert(balanceteSelecionado.planoContas.codCta);
    }

    atualizarMapeamentoDeducoes(tbRelacAtualizar: TbRelacMovParteADeducoesSaldosContabeisCsll) {
        this.httpService.wait();

        this.atuaTbRelacDeducoes = new TbRelacMovParteADeducoesSaldosContabeisCsll(tbRelacAtualizar);
        this.atuaTbRelacDeducoes.movSaldosContabeis = new MovSaldosContabeis(tbRelacAtualizar.movSaldosContabeis);
        this.atuaTbRelacDeducoes.movParteADeducoesCsll = new MovParteADeducoesCsll(tbRelacAtualizar.movParteADeducoesCsll);
        this.httpService.put('/custom/ecd/atualizarrelacparteamovsaldosdeducoescsll', this.atuaTbRelacDeducoes.id, this.atuaTbRelacDeducoes)
            .subscribe(() => this.success(),
            error => this.httpService.handleError(error, () => this.atualizarMapeamentoDeducoes(tbRelacAtualizar)),
            () => this.httpService.done());
    }

    onEditSaldosRelacionados(event: any, callback?: Function) {
      clearTimeout(this.editSaldosRelacionadosTimeout);
      this.editSaldosRelacionadosTimeout = setTimeout(() => {
        const data: TbRelacMovParteACsllSaldosContabeis = this.saldosRelacionados.find(s => s.id === event.data.id);

        if (data) {
          const value: number = this.utilService.parseDecimal(event.data.saldoRelacionado);

          if (data.saldoRelacionadoEditing === null || value !== data.saldoRelacionado || value !== data.saldoRelacionadoEditing) {
            data.saldoRelacionadoEditing = value;
            data.changed = true;
          }
        }

        if (typeof callback === 'function') {
          callback();
        }
      }, 100);
    }

    onEditCompleteSaldosRelacionados(event: any) {
        console.log(event);
      this.onEditSaldosRelacionados(event, () => { this.updateSaldoRelacionado(); });
    }

    updateSaldoRelacionado() {
      const s: TbRelacMovParteACsllSaldosContabeis = this.saldosRelacionados.find(s1 => s1.changed);

      const data = {
        saldoRelacionado: this.utilService.parseDecimal(s.saldoRelacionadoEditing !== null ?
                                                        s.saldoRelacionadoEditing :
                                                        s.saldoRelacionado)
      };

      if (data.saldoRelacionado > s.movSaldosContabeis.vlSldFin) {
        this.exibirErroSaldoRelacionado();
        this.onContaSelect(this.tbIrpj.id, this.mes);
      } else {
        this.httpService.wait();
        this.httpService.patch('/tbrelacmovparteacsllsaldoscontabeis', s.id, data)
                        .subscribe(() => {
                                    s.saldoRelacionado = data.saldoRelacionado;
                                    s.changed = false;

                                    if (this.saldosRelacionados.find(s2 => s2.changed)) {
                                      this.updateSaldoRelacionado();
                                    } else {
                                      this.atualizarSaldoMovParteA();
                                    }
                                  },
                                  error => this.httpService.handleError(error, () => this.updateSaldoRelacionado()),
                                  () => this.httpService.done());
      }
    }

    atualizarMapeamentoListDeducoes(tbRelacAtualizarList: Array<TbRelacMovParteADeducoesSaldosContabeisCsll>) {
        this.httpService.wait();

        tbRelacAtualizarList.forEach(tbRelacionar => {
            tbRelacionar.saldoRelacionado = this.utilService.parseDecimal(tbRelacionar.saldoRelacionado);
            if (tbRelacionar.saldoRelacionado > tbRelacionar.movSaldosContabeis.vlSldFin) {

                try {
                    this.exibirErroSaldoRelacionado();
                    this.onContaSelect(this.tbIrpj.id, this.mes);
                } catch (error) {
                    console.log(error);
                    this.httpService.done()
                }

            } else {
                this.httpService.put('/custom/ecd/atualizarrelacparteamovsaldosdeducoescsll',
                tbRelacionar.id, tbRelacionar.saldoRelacionado)
                    .subscribe(() => this.success(),
                    error => this.httpService.handleError(error, () => this.atualizarMapeamentoListDeducoes(tbRelacAtualizarList)),
                    () => this.httpService.done());
            }

        });
    }

    atualizarSaldoUtilizadoParteB(tbRelac: Array<MovParteBCsllLcto>) {

    this.httpService.wait();
    this.httpService.put('/custom/ecd/atualizarrelacparteamovpartebcsll', 1, tbRelac) // TODO: FIX
                    .subscribe(() => this.atualizarSaldoMovParteA(),
                    error => this.httpService.handleError(error, () => this.atualizarSaldoUtilizadoParteB(tbRelac)),
                    () => this.httpService.done());
    }


    selectTbRelac(movSaldos: MovSaldosContabeis) {

        if (this.balancetesSelecionados && this.selectedTbRelac(movSaldos)) {
            this.balancetesSelecionados.splice(this.balancetesSelecionados.indexOf(movSaldos), 1);
        } else {
            this.balancetesSelecionados.push(movSaldos);
        }
    }

    selectedTbRelac(movSaldos: MovSaldosContabeis): boolean {
        return this.balancetesSelecionados && this.balancetesSelecionados.find(p => p.id === movSaldos.id) !== undefined;
    }

    success() {
        this.saldosRelacionados.filter(s => s.changed).forEach(s => s.changed = false);
        this.periodChanged();
    }

    successMovA(idMovA: number, mes: number) {
        this.saldosRelacionados.filter(s => s.changed).forEach(s => s.changed = false);
        this.onContaSelect(idMovA, mes);
        this.periodChanged();
    }

    excluirMapeamento(tbRelacAtualizar: TbRelacMovParteACsllSaldosContabeis) {

        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletetbrelacmovcsll', tbRelacAtualizar.id)
                .subscribe(() => this.atualizarSaldoMovParteA(),
                error => this.httpService.handleError(error, () => this.excluirMapeamento(tbRelacAtualizar)),
                () => this.httpService.done());
        }
    }

    excluirMapeamentoDeducoes(tbRelacAtualizar: TbRelacMovParteADeducoesSaldosContabeisCsll) {

        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletetbrelacmovdeducoescsll', tbRelacAtualizar.id)
                .subscribe(() => this.success(),
                error => this.httpService.handleError(error, () => this.excluirMapeamentoDeducoes(tbRelacAtualizar)),
                () => this.httpService.done());
        }
    }

    successExclusao() {
        this.periodChanged();
        this.modalConta = false;
        this.modalMapeamento = false;
    }

    excluirMovParteA(movParteA: MovParteACsll) {


        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletemovparteacsll', movParteA.id)
                .subscribe(() => this.successExclusao(),
                error => this.httpService.handleError(error, () => this.excluirMovParteA(movParteA)),
                () => this.httpService.done());
        }
    }

     excluirMovParteADeducoes(movParteA: MovParteADeducoesCsll) {


        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/custom/ecd/deletemovparteadeducoescsll', movParteA.id)
                .subscribe(() => this.successExclusao(),
                error => this.httpService.handleError(error, () => this.excluirMovParteADeducoes(movParteA)),
                () => this.httpService.done());
        }
    }

    exibirModalMapeamento() {
        this.modalMapeamento = true;
    }

    selectedIrpj(tbIrpj: TbCsllM350): boolean {
        return this.tbCsllM350Selecionados && this.tbCsllM350Selecionados.find(p => p.id === tbIrpj.id) !== undefined;
    }

    adicionarConta() {
        this.movParteAList = new Array();
        this.tbCsllM350Selecionados.forEach(tbCsllM350 => {

        this.httpService.wait();
        this.httpService.get('/cadecd?$select=id,indSitEsp/id,indNire/id,indSitIniPer/id,indFinEsc/id,' +
                                              'codHashSub,indGrandePorte/id,empresa/id,dtIni,dtFin,status/id' +
                                      '&$filter=empresa/id eq ' + this.empresa.id +
                                              ' and period(dtIni,dtFin) eq ' + this.dblinkedSessionService.periodo.year.value +
                                      '&$orderby=dtIni')
                .subscribe(ecd => {
                    this.cadEcd = ecd.value;
                    this.cadEcd.forEach(element => {
                    const mov = new MovParteACsll();
                    mov.tbCsllM350 = new TbCsllM350(tbCsllM350);
                    mov.tbIndRelacao = new TbIndRelacao(4);
                    mov.tbTipoLcto = null;
                    mov.movApurReal = null;
                    mov.cadCcus = null;
                    mov.planoContas = null;
                    mov.valor = 0.00;
                    mov.data = new Date(element.dtIni);
                    mov.cadEcd = element;
                    this.movParteAList.push(mov);
                });
                this.httpService.wait();
                    this.httpService.post('/custom/ecd/salvarlistamovpartecsll', this.movParteAList)
                    .subscribe(() => this.alerta(),
                    error => this.httpService.handleError(error, () => this.adicionarConta()),
                    () => this.httpService.done());
                },
                error => this.httpService.handleError(error, () => this.adicionarConta()),
                () => this.httpService.done());

       });
    }

    vincularContaAdicao() {
        this.tbCsllM350ListAll = new Array();

        this.httpService.wait();
        this.httpService.get('/custom/ecd/tb-csll-m350-adi', [this.year, this.empresa.id])
                        .subscribe(tbCsllM350List => {
                            this.planoCsllLoad(tbCsllM350List)
                            if(this.tbCsllM350ListAll !== null || this.tbCsllM350ListAll !== undefined){
                                this.contadorLinha = this.tbCsllM350ListAll.length;
                            }
                                else{
                                    this.contadorLinha = 0;
                            }
                        },

                        error => this.httpService.handleError(error, () => this.vincularContaAdicao()),
                        () => this.httpService.done());

        this.modalMapeamento = true;
        this.tipoConta = 1;
    }

    planoCsllLoad(value: Array<TbCsllM350>) {
        this.tbCsllM350ListAll = value;
        this.contadorLinha = value.length;
    }

        excluirContaAdicao() {
    this.modalMapeamentoExcluir = true;
    }

    vincularContaExclusao() {
        this.tbCsllM350ListAll = new Array();

        this.httpService.wait();
        this.httpService.get('/custom/ecd/tb-csll-m350-exc', [this.year, this.empresa.id])
                        .subscribe(tbCsllM350List => this.planoCsllLoad(tbCsllM350List),
                                   error => this.httpService.handleError(error, () => this.vincularContaExclusao()),
                                   () => this.httpService.done());

        this.modalMapeamento = true;
        this.tipoConta = 2;
    }

    vincularContaDeducao() {
        this.tbCsllN660ListAll = new Array();

        this.httpService.wait();
        this.httpService.get('/tbcslln660?$select=id,cod,descricao&$filter=tipo eq \'E\'&$orderby=id')
            .subscribe(tbCsllN660ListAll => { this.tbCsllN660ListAll = tbCsllN660ListAll.value
                if(this.tbCsllN660ListAll !== null || this.tbCsllN660ListAll !== undefined){
                    this.contadorLinhaN660 = this.tbCsllN660ListAll.length;
                }
                    else{
                        this.contadorLinhaN660 = 0;
                }
            },
            error => this.httpService.handleError(error, () => this.vincularContaDeducao()),
            () => this.httpService.done());


        this.modalMapeamentoDeducoes = true;
    }




    exibirErroSaldoRelacionado() {

        this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'Erro',
            detail: 'Saldo relacionado deverá ser menor do que o saldo final da conta'
        });
    }


    selectDeducao(tbDed: TbCsllN660) {

        if (this.tbIrpjN620Selecionados && this.selectedDeducoes(tbDed)) {
            this.tbIrpjN620Selecionados.splice(this.tbIrpjN620Selecionados.indexOf(tbDed), 1);
        } else {
            this.tbIrpjN620Selecionados.push(tbDed);
        }
    }

    selectedDeducoes(tbDeducoes: TbCsllN660): boolean {
        return this.tbIrpjN620Selecionados && this.tbIrpjN620Selecionados.find(p => p.id === tbDeducoes.id) !== undefined;
    }

    selectCsll(tbCsll: TbCsllM350) {

        if (this.tbCsllM350Selecionados && this.selectedIrpj(tbCsll)) {
            this.tbCsllM350Selecionados.splice(this.tbCsllM350Selecionados.indexOf(tbCsll), 1);
        } else {
            this.tbCsllM350Selecionados.push(tbCsll);
        }
    }

    adicionarContaDeducao() {
        this.movParteADeducoesList = new Array();
        this.tbIrpjN620Selecionados.forEach(tbCsllN660 => {

        this.httpService.wait();
        this.httpService.get('/cadecd?$select=id,indSitEsp/id,indNire/id,indSitIniPer/id,indFinEsc/id,' +
                                              'codHashSub,indGrandePorte/id,empresa/id,dtIni,dtFin,status/id' +
                                      '&$filter=empresa/id eq ' + this.empresa.id +
                                              ' and period(dtIni,dtFin) eq ' + this.dblinkedSessionService.periodo.year.value +
                                      '&$orderby=dtIni')
                .subscribe(ecd => {
                    this.cadEcd = ecd.value;
                    this.cadEcd.forEach(element => {
                    const mov = new MovParteADeducoesCsll();
                    mov.tbCsllN660 = new TbCsllN660(tbCsllN660);
                    mov.tbIndRelacao = new TbIndRelacao(4);
                    mov.planoContas = null;
                    mov.valor = 0.00;
                    mov.data = new Date(element.dtIni);
                    mov.cadEcd = element;
                    this.movParteADeducoesList.push(mov);
                });
                this.httpService.wait();
                    this.httpService.post('/custom/ecd/salvarlistamovpartededucoescsll', this.movParteADeducoesList)
                    .subscribe(() => this.alerta(),
                    error => this.httpService.handleError(error, () => this.adicionarConta()),
                    () => this.httpService.done());
                },
                error => this.httpService.handleError(error, () => this.adicionarConta()),
                () => this.httpService.done());

       });
    }

    atualizarMovParteAMes(movParteAtualizar: MovParteACsll) {
        this.movParteA.valor = this.utilService.parseDecimal(movParteAtualizar.valor);

        this.httpService.wait();
            this.httpService.put('/custom/ecd/atualizar-mov-parte-a-csll', this.movParteA.id, this.movParteA)
            .subscribe(() => this.atualizarSaldoMovParteA(),
            error => this.httpService.handleError(error, () => this.atualizarMovParteAMes(movParteAtualizar)),
            () => this.httpService.done());
    }

    atualizarMovParteADeducoesMes(movParteAtualizar: MovParteADeducoesCsll) {
        this.movParteADeducoes.valor = this.utilService.parseDecimal(movParteAtualizar.valor);
        this.httpService.wait();
            this.httpService.put('/custom/ecd/atualizar-mov-parte-a-deducoes-csll', this.movParteADeducoes.id, this.movParteADeducoes)
            .subscribe(() => this.atualizarSaldoMovParteADeducoes(),
            error => this.httpService.handleError(error, () => this.atualizarMovParteADeducoesMes(movParteAtualizar)),
            () => this.httpService.done());

    }

    atualizarMovParteA(movParteAtualizar: Array<MovParteACsll>) {
      movParteAtualizar.forEach(element => {
        element.valor =  this.utilService.parseDecimal(element.valor);
      });

      this.httpService.wait();
      this.httpService.put('/custom/ecd/atualizarmovparteacsll', 1, movParteAtualizar) // TODO: FIX
                      .subscribe(() => this.atualizarSaldoMovParteA(),
                                error => this.httpService.handleError(error, () => this.atualizarMovParteA(movParteAtualizar)),
                                () => this.httpService.done());
    }

    atualizarSaldoMovParteA() {
      this.httpService.wait();
      this.httpService.put('/custom/ecd/atualizarvalorparteacsll', this.movParteA.id, this.movParteA.id)
                      .subscribe(() => this.successMovA(this.movParteA.tbCsllM350.id, this.mes),
                                 error => this.httpService.handleError(error, () => this.atualizarSaldoMovParteA()),
                                 () => this.httpService.done());
    }

    atualizarSaldoMovParteADeducoes() {
      this.httpService.wait();
      this.httpService.put('/custom/ecd/atualizarvalorparteadeducoescsll', this.movParteADeducoes.id, this.movParteADeducoes.id)
                      .subscribe(() => this.success(),
                                 error => this.httpService.handleError(error, () => this.atualizarSaldoMovParteADeducoes()),
                                 () => this.httpService.done());
    }

    atualizarMovParteADeducoes(movParteAtualizar: Array<MovParteADeducoesCsll>) {
        movParteAtualizar.forEach(element => {
            element.valor =  this.utilService.parseDecimal(element.valor);
            // element.empresa = new Empresa(this.empresa.id);

    });

    this.httpService.wait();
            this.httpService.put('/custom/ecd/atualizarmovparteadeducoescsll', 1, movParteAtualizar) // TODO: FIX
            .subscribe(() => this.success(),
            error => this.httpService.handleError(error, () => this.atualizarMovParteADeducoes(movParteAtualizar)),
            () => this.httpService.done());
    }

    adicionarParteB() {
        this.addParteB = true;
        this.movParteBLcto = new MovParteBCsllLcto();
    }

    selectMovParteB(movParteB: MovParteBCsll) {

        if (this.movParteBSelecionados && this.selectedMovParteB(movParteB)) {
            this.movParteBSelecionados.splice(this.movParteBSelecionados.indexOf(movParteB), 1);
        } else {
            this.movParteBSelecionados.push(movParteB);
        }
    }

    selectedMovParteB(movParteB: MovParteBCsll): boolean {
        return this.movParteBSelecionados && this.movParteBSelecionados.find(p => p.id === movParteB.id) !== undefined;
    }

    adicionarMapeamentoParteB() {
        this.movParteBLcto.data = this.utilService.lastDayOfMonth([this.year, this.mes - 1]).toDate();
        this.movParteBLcto.movParteACsll = new MovParteACsll(this.movParteA.id);

        this.movParteBLcto.valor = this.utilService.parseDecimal(this.movParteBLcto.valor);


        this.httpService.wait();
        this.httpService.post('/movpartebcslllcto', this.movParteBLcto)
                        .subscribe(() => this.atualizarSaldoMovParteA(),
                                   error => this.httpService.handleError(error, () => this.adicionarMapeamentoParteB()),
                                   () => this.httpService.done());
    }

    atualizarParteAValorParteB() {
        this.httpService.wait();
        this.httpService.put('/custom/ecd/atualizarparteapartebcsll', this.movParteA.id, this.movParteBLcto)
        .subscribe(() => this.success(),
        error => this.httpService.handleError(error, () => this.atualizarParteAValorParteB()),
        () => this.httpService.done());
    }


    excluirRelacParteB(movLcto: MovParteBCsllLcto) {
        if (confirm('Deseja excluir o registro atual?')) {
            this.httpService.wait();
            this.httpService.delete('/movpartebcslllcto', movLcto.id)
                            .subscribe(() => this.atualizarSaldoMovParteA(),
                                       error => this.httpService.handleError(error, () => this.excluirRelacParteB(movLcto)),
                                       () => this.httpService.done());
        }
    }

    carregarDemonstrativoParteB() {
      if (this.movParteBLcto.movParteB && this.movParteBLcto.movParteB.id) {
          this.httpService.wait();
        this.httpService.get('/custom/ecd/movpartebdemonstrativo-mensal-csll', [this.movParteBLcto.movParteB.id, this.mes, this.year])
                        .subscribe(movParteBDemonstrativo => this.movParteBDemonstrativo = movParteBDemonstrativo[0],
                                   error => this.httpService.handleError(error, () => this.carregarDemonstrativoParteB()),
                                   () => this.httpService.done());
      }
    }

    get buttonDisabled(): boolean {
      return !this.movParteA.hist && this.utilService.parseDecimal(this.movParteA.valor) > 0;
    }

    get buttonDisabledDeducoes(): boolean {
      return !this.movParteADeducoes.hist && this.utilService.parseDecimal(this.movParteADeducoes.valor) > 0;
    }

    get title(): string {
      return this.titleService.title;
    }

    csllDisabled(event: any) {
       return event.used;
    }

    get year(): number {
      return this.dblinkedSessionService.periodo.year.value;
    }

    get empresa(): Empresa {
      return this.dblinkedSessionService.empresa;
    }

    get hasApurado(): boolean {
      return !this.ecdEcfService.isLoaded || this.ecdEcfService.hasApurado;
    }

    get allApurado(): boolean {
      return !this.ecdEcfService.isLoaded || this.ecdEcfService.allApurado;
    }

    onFilter(event: any) {
        this.contadorLinha = event.filteredValue.length;
      }

    onFilterN660(event: any) {
    this.contadorLinhaN660 = event.filteredValue.length;
    }
}
