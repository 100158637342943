import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { HttpService } from 'app/shared/services/http.service';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { CrudService } from 'app/shared/services/crud.service';
import { ConfigService } from '../../shared/services/config.service';
import { ConfigRelat } from '../../contabil/model/config-relat.model';
import { ColumnRelat } from '../../contabil/model/column-relat.model';
import { ColumnFormulaPgc } from '../../contabil/model/column-formula-pgc.model';
import { ViewCampos } from 'app/cadastro/model/view-campos.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel';
@Component({
  // templateUrl: './accordiondemo.html',
  selector: 'app-config-relat',
  templateUrl: './config-relat.component.html',
  styleUrls: ['./config-relat.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ConfigRelatComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = null;
  msgsModal: Message[] = null;
  title: string;
  formTitle: string;

  //listagem de configurações
  listConfigs: Array<ConfigRelat>;
  isEditing: boolean = false;
  isAdding: boolean = false;
  oneSheetConfig: boolean = false;
  composeConfig: boolean = false;
  selectedConfig: Array<ConfigRelat>;
  dicConfigComposta: any = {}
  listGrupoEmpresa: Array<any> = new Array();
  empresasSelecionadas: Array<number>;

  //Criação e edição de configurações
  addColuna: ColumnRelat;
  configRelat: ConfigRelat;
  newColumns: Array<ColumnRelat>;
  editedColumns: Array<ColumnRelat>;
  deletedColumns: Array<ColumnRelat>;
  requiredFields: Array<string>;
  contasGerenciais: Array<any>;
  isConfigSaved: boolean = false;
  listConfigRelatComposta: Array<any>;
  editingColumnIndice: number;
  listColunaEmpresa: Array<any>;

  //dropdowns e tabelas
  opcoesTipo: SelectItem[];
  opcoesPlanoGerencial: SelectItem[];
  opcoesContas: SelectItem[];
  contasSelecionadas: Array<number>;
  opcoesColunas: SelectItem[];
  opcoesCampoValor: SelectItem[];
  opcoesFiltro: Array<any>;
  opcoesConfig: Array<ConfigRelat>;
  viewCampos: Array<ViewCampos> = new Array();
  limesstr: Array<string> = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
  columnOver: number;
  opcoesCNPJ: any = [];

  //analise vertical
  addFormulaAnalise: ColumnFormulaPgc;
  addColumnFormulasAnalise: Array<ColumnFormulaPgc>;
  newlistFormulasAnalise: Array<ColumnFormulaPgc>;
  deletedFormulasAnalise: Array<number>;
  listFormulasAnalise: Array<ColumnFormulaPgc>;
  columnFormulasAnalise: Array<ColumnFormulaPgc>;
  planoGerencialModulos: Array<string> = new Array();
  opcoesModulos: SelectItem[] = [];
  opcoesTipoVisao: SelectItem[];
  listTipoVisao = [];

  // Functions animated show close box card
  currentStateForm = 'initial';
  currentStateView = 'initial';
  currentStateFormula = 'initial';
  currentStatePreview = 'initial';

  toolbarMainIni = [
    { key: 'back', icon: 'arrow_back', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: false, color: 'default', onClick: () => this.showTela('LIST') },
    { key: 'remove', icon: 'delete', text: 'Remover', tooltip: 'Remover Configuração', visible: false, disabled: false, color: 'red', onClick: () => this.removeConfig() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar Configuração', visible: false, disabled: false, color: 'default', onClick: () => this.showTela('EDIT') },
    { key: 'update', icon: 'refresh', text: 'Atualizar', tooltip: 'Atualizar valores', visible: false, disabled: false, color: 'default', onClick: () => this.calculateColumns() },
    { key: 'save', icon: 'save', text: 'Salvar', tooltip: 'Salvar configuração', visible: false, disabled: false, color: 'default', onClick: () => this.gravarConfig() },
    { key: 'new', icon: 'add', text: 'Adicionar', tooltip: 'Adicionar Configuração', visible: true, disabled: false, color: 'green', onClick: () => this.showTela('ADD') },
  ];

  public toolbarMain = new Toolbar();

  public skeleton = new SkeletonModel({
    columns: [
      { name: '', size: '5%' },
      { name: 'Código', size: '17%' },
      { name: 'Nome', size: '78%' }
    ],
  });

  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private toolbarService: ToolbarService,
    private messageService: MessageService) {
    this.toolbarService.hidden = true;
  }

  ngOnInit(): void {
    this.title = "Configuração de relatório";
    this.formTitle = "Adicionar coluna";
    this.toolbarMain.setAll(this.toolbarMainIni);

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.msgs = [];
          this.msgsModal = [];
          this.selectedConfig = new Array();
          this.opcoesFiltro = new Array();
          this.columnFormulasAnalise = new Array();
          this.oneSheetConfig = true;
          this.composeConfig = false;
          this.planoGerencialModulos = new Array();
          this.listGrupoEmpresa = new Array();
          this.resetConfig();

          this.getConfiguracoes();
          this.getTipos();
          this.getPlanosGerenciais();
          this.getGrupoEmpresa();
          this.loadOpcoesConfig();
          this.loadModules();

          this.opcoesFiltro.push({ label: 'Ano', value: 'DATA.year' }, { label: 'Mês', value: 'DATA.month' }, { label: 'Dia', value: 'DATA.day' });

          this.reloadToolbar();
        }
      });
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  async loadModules() {

    try {
      this.skeleton.startLoad();
      const modules = await this.httpService.get(this.configService.defaultUrlApiUpload + 'workstats/util/modulos').toPromise()

      if (modules.error) {
        this.exibirMensagem(modules)
      } else {
        modules.data.map(module => {
          this.opcoesModulos.push({ label: module.description, value: module.id })
        })
      }

    } catch (error) {
      console.log(error)
      this.exibirMensagem({ error: true, mensagem: "Erro no API, contate o suporte: " + error.message })
    }

    this.skeleton.endLoad();
  }

  resetConfig() {
    //listagem de configurações
    this.listConfigs = new Array<ConfigRelat>();
    this.isEditing = false;
    this.isAdding = false;

    //Criação e edição de configurações
    this.addColuna = new ColumnRelat();
    this.configRelat = new ConfigRelat();
    this.newColumns = new Array<ColumnRelat>();
    this.editedColumns = new Array<ColumnRelat>();
    this.deletedColumns = new Array<ColumnRelat>();
    this.requiredFields = new Array<string>();
    this.contasGerenciais = new Array();
    this.isConfigSaved = false;
    this.configRelat.colunas = new Array<ColumnRelat>();
    this.empresasSelecionadas = new Array();
    this.listColunaEmpresa = new Array();

    //dropdowns e tabelas
    this.opcoesPlanoGerencial = new Array();
    this.opcoesContas = new Array();
    this.contasSelecionadas = new Array<number>();
    this.opcoesCampoValor = new Array();
    this.viewCampos = new Array<ViewCampos>();
    this.columnOver = null;

    //analise vertical
    this.addFormulaAnalise = new ColumnFormulaPgc();
    this.addColumnFormulasAnalise = new Array<ColumnFormulaPgc>();
    this.newlistFormulasAnalise = new Array<ColumnFormulaPgc>();
    this.deletedFormulasAnalise = new Array<number>();
    this.listFormulasAnalise = new Array<ColumnFormulaPgc>();

    this.requiredFields.push("tipo", "qtdDecimais", "label");
    this.getTiposVisao();
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  voltar() {
    this.addFormulaAnalise = new ColumnFormulaPgc();
  }

  showTela(tela: string) {
    switch (tela) {
      case "ADD":
        this.planoGerencialModulos = new Array();
        this.resetConfig();
        this.getTipos();
        this.getPlanosGerenciais();
        this.isAdding = true;
        this.isEditing = false;
        this.isConfigSaved = false;

        break;

      case "EDIT":
        this.configRelat = new ConfigRelat(this.selectedConfig[0]);
        this.listTipoVisao = new Array();

        let linivel = [this.configRelat.visaoNivel1, this.configRelat.visaoNivel2, this.configRelat.visaoNivel3, this.configRelat.visaoNivel4];
        for (let nivel of linivel) {
          if (nivel == null) break;

          let idx = this.opcoesTipoVisao.findIndex(t => t.value === nivel);
          this.listTipoVisao.push(this.opcoesTipoVisao[idx]);
          this.opcoesTipoVisao.splice(idx, 1);

        }

        if (!this.configRelat.isComposta) {
          this.buscarInfosView(true);
          this.getTipos();
          this.getPlanosGerenciais();
          this.getPlanoGerencialModulos();
        } else {
          this.loadOpcoesConfig(true);
        }

        this.oneSheetConfig = !this.configRelat.isComposta;
        this.composeConfig = this.configRelat.isComposta;
        this.isAdding = true;
        this.isEditing = false;
        this.isConfigSaved = true;
        break;

      case "LIST":
        if (this.configRelat.isComposta || (this.newColumns.length > 0 || this.editedColumns.length > 0)) {
          if (!confirm('Deseja realmente voltar? as alterações não salvas serão perdidas.')) break;
        }

        this.isEditing = false;
        this.isAdding = false;
        this.isConfigSaved = false;
        this.oneSheetConfig = true;
        this.composeConfig = false;
        this.selectedConfig = new Array();
        this.resetConfig();
        this.getConfiguracoes();

        break;

      case "normalConfig":
        this.oneSheetConfig = true;
        this.composeConfig = false;
        this.isAdding = false;
        this.isEditing = false;
        this.isConfigSaved = false;
        this.selectedConfig = new Array();
        this.configRelat.isComposta = false;

        break;

      case "configCompose":
        this.oneSheetConfig = false;
        this.composeConfig = true;
        this.isAdding = true;
        this.isEditing = false;
        this.isConfigSaved = false;
        this.selectedConfig = new Array();
        this.configRelat.isComposta = true;
        this.loadOpcoesConfig();

        break;
    }
    this.reloadToolbar();
  }

  removeConfig() {
    if (confirm('Deseja realmente excluir esta configuração?')) {
      let idsConfig = [];

      for (let config of this.selectedConfig) {
        idsConfig.push(config.id.toString());
      }

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'listConfigs': idsConfig
        })
      };

      this.httpService.wait();
      this.httpClient.delete(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.getConfiguracoes();
            this.resetConfig();

            this.isEditing = false;
            this.isAdding = false;
            this.isConfigSaved = false;
            this.oneSheetConfig = true;
            this.composeConfig = false;
            this.selectedConfig = new Array();

            this.httpService.done();
          }, err => {
            console.log(err);
            this.exibirMensagem(err);
            this.httpService.done();
          });
    }
  }

  exibirMensagem(value: any) {
    this.msgs = [];
    if (!value.error) {
      this.messageService.add({
        severity: 'success', key: 'messageLinnks',
        summary: 'MSG:',
        detail: value.mensagem
      });

    } else {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG:',
        detail: value.mensagem
      });
    }
  }

  loadConfig() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'configRelatId': this.configRelat.id.toString(),
        'planoGerencialId': this.configRelat.planoGerencialId.toString(),
        'hideNoValueContas': this.configRelat.hideNoValueContas.toString(),
        'dayRef': "1",
        'monthRef': this.dblinkedSessionService.periodo.month.value.toString(),
        'yearRef': this.dblinkedSessionService.periodo.year.value.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/config-relat-column', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();
            return;
          }

          let colunas = res["columns"];

          for (let col of colunas) {
            let column = new ColumnRelat();
            column.id = col['id'];
            column.configRelatId = col['config_relat_id'];
            column.tipo = col['tipo'];
            column.qtdDecimais = col['qtddecimais'];
            column.sql = col['sql'];
            column.indice = col['indice'];
            column.label = col['label'];
            column.formula = col['formula'];
            column.corFundo = col['corfundo'];
            column.corTexto = col['cortexto'];
            column.isCorFundoCabecalho = col['iscorfundocabecalho'];
            column.isCorFundoColuna = col['iscorfundocoluna'];
            column.isCorTextoCabecalho = col['iscortextocabecalho'];
            column.isCorTextoColuna = col['iscortextocoluna'];
            column.isCabecalhoNegrito = col['isnegritocabecalho'];
            column.isColunaNegrito = col['isnegritocoluna'];
            column.isValor = col['isvalor'];
            column.isPercent = col['ispercent'];
            column.isFormulaPgc = col['isformulapgc'];
            column.viewCampoValor = col['view_campo_valor'];

            this.configRelat.colunas.push(column);
            if (column.tipo !== 2) {
              for (let conta of this.contasGerenciais) {
                if (conta.ind_cta == 'N') {
                  conta[this.alfabeto(column.indice)] = null;

                } else {
                  conta[this.alfabeto(column.indice)] = 0;
                }
              }
            }
          }

          for (let pgcFormula of res["formulas"]) {
            let formula = new ColumnFormulaPgc();

            formula.id = pgcFormula['id'];
            formula.configRelatColumnId = pgcFormula['config_relat_column_id'];
            formula.pgcId = pgcFormula['pgc_id'];
            formula.formula = pgcFormula['formula'];
            formula.columnIndice = pgcFormula['column_indice'];
            formula.columnReferenciaIndice = pgcFormula['column_referencia_indice'];
            formula.columnReferenciaId = pgcFormula['column_referencia_id'];
            formula.isValor = pgcFormula['isvalor'];
            formula.isPercent = pgcFormula['ispercent'];
            let contaIndex = this.contasGerenciais.findIndex(c => c.id === formula.pgcId);

            if (contaIndex >= 0) {
              this.contasGerenciais[contaIndex][this.alfabeto(formula.columnIndice) + "-pgc"] = {};
              this.contasGerenciais[contaIndex][this.alfabeto(formula.columnIndice) + "-pgc"]['isFormulaPgc'] = true;
              this.contasGerenciais[contaIndex][this.alfabeto(formula.columnIndice) + "-pgc"]['isPercent'] = formula.isPercent;
              this.contasGerenciais[contaIndex][this.alfabeto(formula.columnIndice) + "-pgc"]['isValor'] = formula.isValor;
            }

            this.listFormulasAnalise.push(formula);
          }

          for (let colEmpresa of res['columnEmpresa']) {
            let coluna = this.configRelat.colunas.find(col => col.id == colEmpresa.config_relat_column_id);
            if (coluna) {
              if (coluna['empresasSelecionadas'] == null) coluna['empresasSelecionadas'] = [];
              coluna['empresasSelecionadas'].push(colEmpresa.empresa_id);
            }
          }

          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        });
  }

  getConfiguracoes() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.skeleton.startLoad();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', httpOptions).toPromise().then(res => {
      this.listConfigs = new Array();
      this.dicConfigComposta = res["dicconfigs_compostas"]

      res["mensagem"].forEach(config => {
        let configRelat = new ConfigRelat();
        configRelat.id = config['id'];
        configRelat.planoGerencialId = config['plano_gerencial_id'];
        configRelat.nome = config['nome'];
        configRelat.codigo = config['codigo'];
        configRelat.hideNoValueContas = config['hidenovaluecontas'];
        configRelat.isComposta = config['is_composta'];
        configRelat.visaoNivel1 = config['visao_nivel_1'];
        configRelat.visaoNivel2 = config['visao_nivel_2'];
        configRelat.visaoNivel3 = config['visao_nivel_3'];
        configRelat.visaoNivel4 = config['visao_nivel_4'];
        configRelat.showFormulas = config['show_formulas']

        this.listConfigs.push(new ConfigRelat(configRelat));
      });
    }).catch(err => this.exibirMensagem(err)).finally(() => this.skeleton.endLoad())
  }

  loadOpcoesConfig(configSaved = false) {
    this.opcoesConfig = new Array();
    this.listConfigRelatComposta = new Array();

    if (!configSaved) {
      for (let conf of this.listConfigs) {
        if (!conf.isComposta) {
          let configRelat = new ConfigRelat(conf);
          configRelat['config_relat_ref_id'] = conf.id;
          this.opcoesConfig.push(configRelat);
        }
      }
    } else {
      let excludedConfigs = [];
      excludedConfigs.push(this.configRelat.id);

      for (let conf of this.dicConfigComposta[this.configRelat.id]) {
        this.listConfigRelatComposta.push(conf);
        excludedConfigs.push(conf.config_relat_ref_id);
      }

      for (let conf of this.listConfigs) {
        if (excludedConfigs.indexOf(conf.id) < 0 && !conf.isComposta) {
          this.opcoesConfig.push(new ConfigRelat(conf));
        }
      }
    }
  }

  getTiposVisao() {
    this.skeleton.startLoad();
    this.httpService.get('/tipovisao?$select=id,descricao')
      .subscribe(ref => {
        this.opcoesTipoVisao = [];
        this.listTipoVisao = [];
        ref.value.forEach(e => {
          if (e.id !== 2) this.opcoesTipoVisao.push({ label: e.descricao, value: e.id });
        });
      },
        error => this.httpService.handleError(error, () => this.getTiposVisao()),
        () => this.skeleton.endLoad());
  }

  getTipos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.skeleton.startLoad();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/config-relat-column-tipo', httpOptions).toPromise().then(res => {
      if(!res['error']) {
        this.opcoesTipo = new Array<any>()

        res["mensagem"].forEach(tipo => {
          this.opcoesTipo.push({ label: tipo["descricao"], value: tipo["id"] });
          this.addColuna.tipo = this.opcoesTipo[0]['id'];
        });
      }
    }).catch(err => this.exibirMensagem(err)).finally(() => this.skeleton.endLoad())
  }

  getGrupoEmpresa() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaId': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.skeleton.startLoad();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/get-agrupamento-empresa', httpOptions)
      .subscribe(
        res => {
          this.listGrupoEmpresa = res["mensagem"];
          this.skeleton.endLoad();
        },
        err => {
          console.log(err);
          this.exibirMensagem(err);
          this.skeleton.endLoad();
        });
  }

  getPlanosGerenciais() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaId': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.skeleton.startLoad();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/plano_gerencial', httpOptions)
      .subscribe(
        res => {
          this.opcoesPlanoGerencial = new Array<any>();
          let result = res["mensagem"];

          for (let line of result) {
            this.opcoesPlanoGerencial.push({ 'label': line["codigo"] + " - " + line["descricao"], 'value': line["id"] })
          }

          this.skeleton.endLoad();
        },
        err => {
          console.log(err);
          this.exibirMensagem(err);
          this.skeleton.endLoad();
        });
  }

  buscarInfosView(loadConfig = false) {
    this.configRelat.colunas = new Array<ColumnRelat>();
    this.newColumns = new Array<ColumnRelat>();
    this.editedColumns = new Array<ColumnRelat>();

    this.configRelat.colunas.push(new ColumnRelat());
    this.configRelat.colunas[0].label = "Contas";
    this.configRelat.colunas[0].indice = 0;
    this.configRelat.colunas[0].tipo = 1;
    this.configRelat.colunas[0].qtdDecimais = 2;

    if (this.configRelat.planoGerencialId === null && this.configRelat.planoGerencialId === undefined) return;

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaId': this.dblinkedSessionService.empresa.id.toString(),
        'planoGerencialId': this.configRelat.planoGerencialId.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/campos-view-plano-gerencial', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.opcoesCampoValor = new Array();

          if (res["error"]) {
            console.log(res);
            this.exibirMensagem(res);
          } else {
            this.viewCampos = result;

            for (let campo of result) {
              if (campo.tipo != null && campo.tipo.toLowerCase() === "valor") {
                this.opcoesCampoValor.push({ 'label': campo.campo, 'value': campo.id });
              }
            }

            this.buscarGerencialContas(loadConfig);
          }

          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        });
  }

  buscarGerencialContas(loadConfig = false) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'planoGerencialId': this.configRelat.planoGerencialId.toString()
      })
    }

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/plano_gerencial_contas', httpOptions)
      .subscribe(res => {
        this.contasGerenciais = new Array();
        this.opcoesContas = new Array();
        let rowIndexPreview = 1;
        let rowIndexCount = 1;

        for (let conta of res["mensagem"][0]) {
          if (conta['linha_acima']) rowIndexPreview++;

          this.contasGerenciais.push({
            'id': conta.id,
            'cod_cta': conta.cod_cta,
            'sql': conta.sql,
            'nivel': conta.nivel,
            'linha_acima': conta.linha_acima,
            'linha_abaixo': conta.linha_abaixo,
            'linha_negrito': conta.linha_negrito,
            'linha_cor': conta.linha_cor,
            'a': conta.descr_cta,
            'rowIndexPreview': rowIndexPreview,
            'rowIndexExcel': rowIndexPreview + 6,
            'isHidden': false,
            'ind_cta': conta.ind_cta
          });

          rowIndexPreview++;
          rowIndexCount++;

          this.opcoesContas.push({ 'label': conta.cod_cta + " - " + conta.descr_cta, 'value': conta.id });

          if (rowIndexCount === res["mensagem"][0].length && loadConfig) this.loadConfig();
        }

        this.httpService.done();
      },
        err => {
          console.log(err);
          this.exibirMensagem(err);
          this.contasGerenciais = new Array();
          this.httpService.done();
        });
  }

  validaColuna(col: ColumnRelat): boolean {
    if (col === null || col === undefined) return false;

    if (this.configRelat.planoGerencialId === undefined || this.configRelat.planoGerencialId === null) {
      this.exibirMensagem({ error: true, mensagem: "Selecione um plano gerencial!" });
      return false;

    } else {
      let count = 0;

      if (this.isEditing && (this.addColuna.indice >= this.configRelat.colunas.length || this.addColuna.indice < 1)) {
        this.exibirMensagem({ error: true, mensagem: "O índice informado é inválido." });
        return false;
      }

      if (this.empresasSelecionadas.length == 0 && this.listGrupoEmpresa.length > 0 && this.addColuna.tipo == 1) {
        this.exibirMensagem({ error: true, mensagem: "Selecione pelo menos uma empresa!" });
        return false;
      }

      for (let prop of this.requiredFields) {
        if (this.addColuna.tipo != 2 && [null, undefined, ''].indexOf(col[prop]) >= 0) {
          this.exibirMensagem({ error: true, mensagem: "Preencha todos os campos obrigatórios." });
          return false;

        } else if (++count === this.requiredFields.length) { //contornando a asincronia
          if (col.tipo === 1 && col.sql != null) {
            let sqlLowerCase = col.sql.toLowerCase();

            if (sqlLowerCase.indexOf("drop") >= 0 || sqlLowerCase.indexOf("truncate") >= 0 ||
              sqlLowerCase.indexOf("delete") >= 0 || sqlLowerCase.indexOf("cascade") >= 0 ||
              sqlLowerCase.indexOf("insert") >= 0 || sqlLowerCase.indexOf("table") >= 0 ||
              sqlLowerCase.indexOf("update") >= 0 || sqlLowerCase.indexOf("create") >= 0 || (sqlLowerCase.indexOf("select") >= 0 && sqlLowerCase.indexOf("from") >= 0)) {
              this.exibirMensagem({ error: true, mensagem: "Sintaxe não permitida." })
              return false;
            }

          } else if (col.tipo === 3 && !this.addColuna.isFormulaPgc) {
            if (this.addColuna.formula.indexOf(",")) {
              this.addColuna.formula = this.addColuna.formula.replace(/,/g, ".");
              this.exibirMensagem({ error: true, mensagem: "As virgulas serão substituidas por pontos(.)." })
            }

            const reg = /\b[a-zA-Z].*?\b/g;

            try {
              let testFormula = col.formula.replace(reg, '1');
              let result = eval(testFormula);

              if (isNaN(result)) {
                result = 0;
              }

            } catch (err) {
              this.exibirMensagem({ error: true, mensagem: "O formato da formula está incorreto." });
              return false;
            }
          }

          return true;
        }
      }
    }
  }

  adicionarColuna() {
    if (this.addColuna.tipo === 2) {
      this.addColuna.label = "";
      this.addColuna.qtdDecimais = 0;
    }

    if (!this.validaColuna(this.addColuna)) return;

    this.addColuna.indice = this.configRelat.colunas[this.configRelat.colunas.length - 1].indice + 1;

    if (this.addColuna.tipo !== 3) {
      if (this.addColuna.tipo == 1) {
        this.addColuna["empresasSelecionadas"] = this.empresasSelecionadas;
      }

      for (let conta of this.contasGerenciais) {
        conta[this.alfabeto(this.addColuna.indice)] = 0;
      }
    }

    if (this.addColuna.tipo === 3 && !this.atualizarValorFormula(this.addColuna)) return;

    this.configRelat.colunas.push(this.addColuna);
    this.newColumns.push(this.addColuna);
    this.addColuna = new ColumnRelat();
    this.empresasSelecionadas = new Array();
    this.loadOpcoesColunas();

    this.exibirMensagem({ error: false, mensagem: "Coluna adicionada com sucesso!" });
  }

  getColumnToReplace(idx: number) {
    //encontra ou cria o objeto de coluna que será enviado no request para a edição do relatório, de acordo com o indice
    let colToReplace;

    if (this.configRelat.colunas[idx].id) {
      colToReplace = this.editedColumns.find(col => col.id === this.configRelat.colunas[idx].id);
      if (!colToReplace) {
        this.editedColumns.push(new ColumnRelat(this.configRelat.colunas[idx]));
        colToReplace = this.editedColumns[this.editedColumns.length - 1];
      }
    } else {
      colToReplace = this.newColumns.find(col => col.indice === this.configRelat.colunas[idx].indice);
      if (!colToReplace) {
        this.newColumns.push(new ColumnRelat(this.configRelat.colunas[idx]));
        colToReplace = this.newColumns[this.newColumns.length - 1];
      }
    }

    return colToReplace;
  }

  editarColuna() {
    if (!this.validaColuna(this.addColuna)) return;
    this.addColuna.indice = Number(this.addColuna.indice);

    for (let i = 0; i < this.configRelat.colunas.length; i++) {
      if (this.configRelat.colunas[i].indice === this.editingColumnIndice) {
        if (this.addColuna.tipo === 3 && !this.atualizarValorFormula(this.addColuna)) return;
        this.configRelat.colunas[i].formula = this.addColuna.formula;

        //se o indice da coluna mudar
        if (this.addColuna.indice != this.editingColumnIndice) {
          this.newlistFormulasAnalise = this.newlistFormulasAnalise.map(newFormula => {
            if (newFormula.columnIndice == this.editingColumnIndice) newFormula.columnIndice = this.addColuna.indice;
            else if (newFormula.columnIndice == this.addColuna.indice) newFormula.columnIndice = this.addColuna.indice;

            if (newFormula.columnReferenciaIndice == this.editingColumnIndice) newFormula.columnReferenciaIndice = this.addColuna.indice;
            else if (newFormula.columnReferenciaIndice == this.addColuna.indice) newFormula.columnReferenciaIndice = this.addColuna.indice;

            return newFormula;
          });

          let indices = [];
          if (this.addColuna.indice > this.editingColumnIndice) {
            for (let j = this.editingColumnIndice + 1; j <= this.addColuna.indice; j++) {
              let colToReplace = this.getColumnToReplace(j);
              indices.push([this.configRelat.colunas[j].indice, this.configRelat.colunas[j].indice - 1]);
              this.configRelat.colunas[j].indice -= 1;
              for (let key in this.configRelat.colunas[j]) {
                colToReplace[key] = this.configRelat.colunas[j][key];
              }
              this.columnIndiceChanged(this.configRelat.colunas[j].indice + 1, this.configRelat.colunas[j].indice, false);
            }
          } else {
            for (let j = this.addColuna.indice; j < this.editingColumnIndice; j++) {
              let colToReplace = this.getColumnToReplace(j);
              indices.push([this.configRelat.colunas[j].indice, this.configRelat.colunas[j].indice + 1]);
              this.configRelat.colunas[j].indice += 1;
              for (let key in this.configRelat.colunas[j]) {
                colToReplace[key] = this.configRelat.colunas[j][key];
              }

              this.columnIndiceChanged(this.configRelat.colunas[j].indice - 1, this.configRelat.colunas[j].indice, false);
            }
          }

          this.addColuna.formula = this.configRelat.colunas[i].formula;
          indices.push([this.editingColumnIndice, this.addColuna.indice]);
          for (let j in this.contasGerenciais) {
            let copyConta = {};
            for (let key in this.contasGerenciais[j]) {
              let newIdx = null;

              for (let vet of indices) {
                if (this.alfabeto(vet[0]) == key) {
                  newIdx = vet[1];
                  break;
                }
              }

              copyConta[newIdx ? this.alfabeto(newIdx) : key] = this.contasGerenciais[j][key];
            }

            this.contasGerenciais[j] = copyConta;
          }
        }

        this.configRelat.colunas[i] = new ColumnRelat(this.addColuna);
        this.configRelat.colunas[i]['empresasSelecionadas'] = this.empresasSelecionadas;

        this.columnIndiceChanged(this.editingColumnIndice, this.addColuna.indice, false);

        //deixa todas as formulas em lowerCase
        for (let coluna of this.configRelat.colunas) if (coluna.tipo == 3 && !coluna.isFormulaPgc) coluna.formula = coluna.formula.toLowerCase();
        this.checkFormulasAnalise();

        //reordena as colunas pelo indice
        this.configRelat.colunas.sort((col1, col2) => col1.indice > col2.indice ? 1 : -1);

        //coluna ja existe no banco de dados
        if (this.addColuna.id != null) {
          let index = this.editedColumns.findIndex(col => col.id === this.addColuna.id);

          //coluna já foi editada
          if (index >= 0) {
            this.editedColumns[index] = new ColumnRelat(this.addColuna);
            this.editedColumns[index]["empresasSelecionadas"] = this.empresasSelecionadas;
          } else {
            this.editedColumns.push(new ColumnRelat(this.addColuna));
            this.editedColumns[this.editedColumns.length - 1]['empresasSelecionadas'] = this.empresasSelecionadas;
          }
        } else { //coluna não existe no banco de dados
          let index = this.newColumns.findIndex(col => col.indice === this.addColuna.indice);

          if (index >= 0) {
            this.newColumns[index] = new ColumnRelat(this.addColuna);
            this.newColumns[index]['empresasSelecionadas'] = this.empresasSelecionadas;
          }
        }
        break;
      }
    }

    this.addColuna = new ColumnRelat();
    this.empresasSelecionadas = new Array();
    this.isEditing = false;
    this.isAdding = true;
    this.formTitle = "Adicionar coluna";

    this.exibirMensagem({ error: false, mensagem: "Coluna alterada com sucesso!" });
  }

  atualizarValorFormula(col: ColumnRelat, formulaSalva = false): boolean {
    const reg = /\b[a-zA-Z].*?\b/;

    if (!col.isFormulaPgc) {
      for (let conta of this.contasGerenciais) {
        let formulaReal = col.formula;

        try {
          let match = formulaReal.match(reg);

          while (match !== null && match !== undefined) {
            let letra = match[0].toLowerCase();

            if (letra === 'a') {
              this.exibirMensagem({ error: true, mensagem: "A coluna \"A\" não deve ser utilizada na formula." });
              return false;
            }

            if (conta[letra] === undefined || conta[letra] === null) {
              formulaReal = formulaReal.replace(reg, '0');
              this.exibirMensagem({ error: true, mensagem: `Coluna (${letra.toUpperCase()}) não encontrada.` })
              return false;
            }

            formulaReal = formulaReal.replace(reg, "(" + conta[letra] + ")");
            match = formulaReal.match(reg);
          }

          let result = col.isPercent ? eval(formulaReal) * 100 : eval(formulaReal);
          if (isNaN(result) || result === Infinity || result === -Infinity) {
            result = 0;
          }

          conta[this.alfabeto(col.indice)] = result;

        } catch (err) {
          console.log(err);
          this.exibirMensagem({ error: true, mensagem: "Ocorreu um erro ao calcular a formula." });
          return false;
        }
      }
    } else {
      for (let pgcFormula of this.addColumnFormulasAnalise) {
        let contaRef = this.contasGerenciais.find(c => c.id === pgcFormula.pgcId);

        try {
          let valorConta = contaRef == null ? 0 : contaRef[this.alfabeto(pgcFormula.columnReferenciaIndice)] == null ? 0 : contaRef[this.alfabeto(pgcFormula.columnReferenciaIndice)];
          let regColuna = new RegExp(`\\b${this.alfabeto(pgcFormula.columnReferenciaIndice)}+\\b`, 'g');
          let regContas = /\b[a-zA-Z].*?\b/;
          let formulaReal = pgcFormula.formula;
          formulaReal = formulaReal.replace(regColuna, valorConta);
          let match = formulaReal.match(regContas);
          while (match !== null && match !== undefined) {
            let letra = match[0].toLowerCase();

            if (letra[0] === 'a') {
              this.exibirMensagem({ error: true, mensagem: "A coluna \"A\" não deve ser utilizada na formula." });
              return false;
            }

            let colRow = letra.match(/[a-zA-Z]+|[0-9]+/g);
            if (colRow[0] != this.alfabeto(pgcFormula.columnReferenciaIndice)) {
              this.exibirMensagem({ error: true, mensagem: "Não pode ter outras colunas que a coluna de referência na formula (" + this.alfabeto(pgcFormula.columnIndice).toUpperCase() + ")" });
              return false;
            }

            let conta = this.contasGerenciais.find(c => (c.rowIndexPreview + 1).toString() === colRow[1]);
            let valorConta = conta == null ? 0 : conta[colRow[0]] == null ? 0 : conta[colRow[0]];

            formulaReal = formulaReal.replace(regContas, valorConta);
            match = formulaReal.match(regContas);
          }

          let result = col.isPercent ? eval(formulaReal) * 100 : eval(formulaReal);

          if (isNaN(result) || result === Infinity || result === -Infinity) {
            result = 0;
          }

          contaRef[this.alfabeto(pgcFormula.columnIndice)] = pgcFormula.isPercent ? result * 100 : result;
          contaRef[this.alfabeto(pgcFormula.columnIndice) + "-pgc"] = {};
          contaRef[this.alfabeto(pgcFormula.columnIndice) + "-pgc"]['isFormulaPgc'] = true;
          contaRef[this.alfabeto(pgcFormula.columnIndice) + "-pgc"]['isPercent'] = pgcFormula.isPercent;
          contaRef[this.alfabeto(pgcFormula.columnIndice) + "-pgc"]['isValor'] = pgcFormula.isValor;

        } catch (err) {
          console.log(err);
          this.exibirMensagem({ error: true, mensagem: "O formato da formula está incorreto." });
          return false;
        }

        if (!formulaSalva) {
          this.newlistFormulasAnalise.push(pgcFormula);
          this.listFormulasAnalise.push(pgcFormula)
        }

        this.addColumnFormulasAnalise = new Array<ColumnFormulaPgc>();
        this.columnFormulasAnalise = new Array();
      }
    }

    return true;
  }

  cancelEdit() {
    this.addColuna = new ColumnRelat();
    this.columnFormulasAnalise = new Array();

    this.isEditing = false;
    this.isAdding = true;
    this.formTitle = "Adicionar coluna";
  }

  deleteColumn(col: ColumnRelat) {
    if (!confirm('Deseja realmente deletar essa coluna?')) return;

    for (let coluna of this.configRelat.colunas) {
      if (coluna.tipo === 3 && !coluna.isFormulaPgc) {
        if (coluna.formula.toLowerCase().includes(this.alfabeto(col.indice))) {
          this.exibirMensagem({ "error": true, "mensagem": "Não foi possível deletar a coluna pois ela está sendo utilizada na formula da coluna " + this.alfabeto(coluna.indice) });
          return;
        }
      }
    }

    for (let i = 0; i < this.configRelat.colunas.length; i++) {
      if (this.configRelat.colunas[i].indice < col.indice) continue;

      if (this.configRelat.colunas[i].indice === col.indice) {
        this.configRelat.colunas.splice(i--, 1);

        if (col.id != null) {
          this.deletedColumns.push(col);

          this.editedColumns = this.editedColumns.filter(editCol => editCol.id != col.id);
        } else
          this.newColumns = this.newColumns.filter(newCol => newCol.indice != col.indice);

        this.listFormulasAnalise = this.listFormulasAnalise.filter(listFormula => listFormula.columnIndice != col.indice);
        this.newlistFormulasAnalise = this.newlistFormulasAnalise.filter(newFormula => newFormula.columnIndice != col.indice);

      } else {
        let newIndice = this.configRelat.colunas[i].indice - 1;
        this.configRelat.colunas[i].indice = newIndice;

        let regex = new RegExp(`\\b${this.alfabeto(newIndice + 1)}+\\b`, 'g');
        for (let col of this.configRelat.colunas) {
          if (col.tipo === 3) {
            if (!col.isFormulaPgc) {
              col.formula = col.formula.toLowerCase().replace(regex, this.alfabeto(newIndice));
            } else {
              for (let analise of this.listFormulasAnalise) {
                analise.formula.toLowerCase().replace(regex, this.alfabeto(newIndice));
                let index = this.newlistFormulasAnalise.findIndex(a => a.id === analise.id || (a.columnIndice === analise.columnIndice && a.pgcId === analise.pgcId));

                if (index >= 0) {
                  this.newlistFormulasAnalise.splice(index, 1);
                }

                this.newlistFormulasAnalise.push(new ColumnFormulaPgc(analise));
              }
            }
          }
        }

        this.contasGerenciais.forEach(conta => {
          conta[this.alfabeto(newIndice)] = conta[this.alfabeto(newIndice + 1)];
        });

        if (this.configRelat.colunas[i].id != null) {
          let index = this.editedColumns.findIndex(c => c.id === this.configRelat.colunas[i].id);

          if (index >= 0) {
            this.editedColumns[index].indice = newIndice;
          } else {
            this.editedColumns.push(new ColumnRelat(this.configRelat.colunas[i]));
          }
        } else {
          let index = this.newColumns.findIndex(c => c.indice === newIndice + 1);

          if (index >= 0) {
            this.editedColumns[index].indice = newIndice;
          }
        }

      }
    }

    this.loadOpcoesColunas();
  }

  adicionarFormulaPgc() {
    if (this.contasSelecionadas.length === 0) {
      this.exibirMensagem({ error: true, mensagem: "Selecione ao menos uma conta." });
      return;
    }

    if (this.addFormulaAnalise.formula == null || this.addFormulaAnalise.formula === "") {
      this.exibirMensagem({ error: true, mensagem: "Preencha o campo da formula!" });
      return;
    }

    if (this.isEditing)
      this.addFormulaAnalise.columnIndice = this.addColuna.indice;
    else
      this.addFormulaAnalise.columnIndice = this.configRelat.colunas[this.configRelat.colunas.length - 1].indice + 1;

    try {
      this.contasSelecionadas.forEach(id => {
        this.addFormulaAnalise.pgcId = id;
        this.addFormulaAnalise.formula = this.addFormulaAnalise.formula.replace(/,/g, ".");

        let validarFormula = this.addFormulaAnalise.formula;
        let regexColIndice = new RegExp(`\\b${this.alfabeto(this.addFormulaAnalise.columnReferenciaIndice)}+\\b`, 'g');
        let regexColConta = /\b[a-zA-Z].*?\b/g;

        validarFormula = validarFormula.replace(regexColIndice, '1');
        validarFormula = validarFormula.replace(regexColConta, '1');

        let resultado = eval(validarFormula);

        if (isNaN(resultado)) {
          resultado = 0;
        }

        let col = this.configRelat.colunas.find(c => c.indice === this.addFormulaAnalise.columnIndice);
        this.addFormulaAnalise.configRelatColumnId = col != null ? col.id : null;

        col = this.configRelat.colunas.find(c => c.indice === this.addFormulaAnalise.columnReferenciaIndice);
        this.addFormulaAnalise.columnReferenciaId = col != null ? col.id : null;

        //verificando se a conta já possui uma formula
        let index = this.addColumnFormulasAnalise.findIndex(formula => formula.columnIndice === this.addFormulaAnalise.columnIndice && formula.pgcId === this.addFormulaAnalise.pgcId);
        if (index >= 0) {
          this.addFormulaAnalise.id = this.addColumnFormulasAnalise[index].id;
          this.addColumnFormulasAnalise.splice(index, 1);
        }

        index = this.listFormulasAnalise.findIndex(formula => formula.columnIndice === this.addFormulaAnalise.columnIndice && formula.pgcId === this.addFormulaAnalise.pgcId);
        if (index >= 0) {
          this.addFormulaAnalise.id = this.listFormulasAnalise[index].id;
          this.listFormulasAnalise.splice(index, 1);
        }

        this.addFormulaAnalise.formula = this.addFormulaAnalise.formula.toLocaleLowerCase();
        this.addColumnFormulasAnalise.push(new ColumnFormulaPgc(this.addFormulaAnalise));
        this.loadColumnFormulas();
      });

    } catch (err) {
      console.log(err)
      this.exibirMensagem({ error: true, mensagem: "O formato da formula está incorreto." });
      return false;
    }

    this.addFormulaAnalise = new ColumnFormulaPgc();
    this.contasSelecionadas = new Array();
  }

  gravarConfig() {
    if (this.configRelat.colunas.length < 2) { //sempre vai existir uma coluna para as contas
      this.exibirMensagem({ error: true, mensagem: "Adicione pelo menos uma coluna" });
      return;

    } else if (this.configRelat.nome == null || this.configRelat.nome === "") {
      this.exibirMensagem({ error: true, mensagem: "Preencha o campo do nome." });
      return;

    } else if (this.configRelat.codigo == null || this.configRelat.codigo === "") {
      this.exibirMensagem({ error: true, mensagem: "Preencha o campo do código." });
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    const payload = {
      empresaid: this.dblinkedSessionService.empresa.id,
      userid: this.sessionService.loggedUser.id,
      nome: this.configRelat.nome,
      planoGerencialId: this.configRelat.planoGerencialId,
      hideNoValueContas: this.configRelat.hideNoValueContas,
      codigo: this.configRelat.codigo,
      isComposta: false,
      listConfigRelatComposta: [],
      listTipoVisao: this.listTipoVisao,
      showFormulas: this.configRelat.showFormulas
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', payload, httpOptions)
      .subscribe(res => {
        this.exibirMensagem(res);
        if (res["error"]) return this.httpService.done();

        this.configRelat.id = res["configId"];
        this.isConfigSaved = true;
        this.httpService.done();
        this.postColunas();
        this.savePlanoGerencialModulos();
      }, err => {
        console.log(err);
        this.exibirMensagem(err);
        this.httpService.done();
      });
  }

  editarConfig() {
    if (this.configRelat.colunas.length < 2) { //sempre vai existir uma coluna para as contas
      this.exibirMensagem({ error: true, mensagem: "Adicione pelo menos uma coluna" });
      return;
    } else if (this.configRelat.nome == null || this.configRelat.nome === "") {
      this.exibirMensagem({ error: true, mensagem: "Preencha o campo do nome." });
      return;
    } else if (this.configRelat.codigo == null || this.configRelat.codigo === "") {
      this.exibirMensagem({ error: true, mensagem: "Preencha o campo do código." });
      return;
    }

    if (this.configRelat.id === undefined || this.configRelat.id === null) {
      this.exibirMensagem({ error: true, mensagem: "Erro ao salvar a coluna no banco, tente novamente." })
      this.isConfigSaved = false;
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    const payload = {
      empresaid: this.dblinkedSessionService.empresa.id,
      userid: this.sessionService.loggedUser.id,
      configRelatId: this.configRelat.id,
      nome: this.configRelat.nome,
      planoGerencialId: this.configRelat.planoGerencialId,
      hideNoValueContas: this.configRelat.hideNoValueContas,
      codigo: this.configRelat.codigo,
      isComposta: false,
      listConfigRelatComposta: [],
      listTipoVisao: this.listTipoVisao,
      showFormulas: this.configRelat.showFormulas
    }

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', payload, httpOptions)
      .subscribe(res => {
        this.exibirMensagem(res);

        if (res["error"]) return this.httpService.done();

        //novas colunas
        if (this.newColumns != null && this.newColumns.length > 0) {
          this.postColunas();
        }

        //colunas editadas
        if (this.editedColumns != null && this.editedColumns.length > 0) {
          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi
            })
          };

          let payload = {
            empresaid: this.dblinkedSessionService.empresa.id,
            userid: this.sessionService.loggedUser.id,
            configRelatId: this.configRelat.id,
            planoGerencialId: this.configRelat.planoGerencialId,
            columns: this.editedColumns,
            listFormulasAnalise: this.newlistFormulasAnalise.filter(formula => formula.configRelatColumnId != null),
            deletedFormulas: this.deletedFormulasAnalise,
            dayRef: 1,
            monthRef: this.dblinkedSessionService.periodo.month.value,
            yearRef: this.dblinkedSessionService.periodo.year.value
          }

          this.httpService.wait();
          this.httpClient.put(this.configService.defaultUrlApiUpload + 'cadastro/config-relat-column', payload, httpOptions2)
            .subscribe(res => {
              this.exibirMensagem(res);

              if (!res["error"]) {
                this.editedColumns = new Array<ColumnRelat>();
                this.newlistFormulasAnalise = new Array<ColumnFormulaPgc>();
              }

              this.httpService.done();
            }, err => {
              console.log(err);
              this.exibirMensagem(err);
              this.httpService.done();
            });
        }

        //deletar colunas
        if (this.deletedColumns != null && this.deletedColumns.length > 0) {
          let columns_ids = [];

          for (let col of this.deletedColumns) {
            if (col.id != null) {
              columns_ids.push(col.id.toString());
            }
          }

          const httpOptions3 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'columnsids': columns_ids
            })
          };

          this.httpService.wait();
          this.httpClient.delete(this.configService.defaultUrlApiUpload + 'cadastro/config-relat-column', httpOptions3)
            .subscribe(res => {
              this.deletedColumns = new Array();
              this.exibirMensagem(res);
              this.httpService.done();
            }, err => {
              this.deletedColumns = new Array();
              console.log(err);
              this.exibirMensagem(err);
              this.httpService.done();
            });
        }

        if (this.editedColumns != null && this.editedColumns.length > 0 || !(this.newColumns != null && this.newColumns.length > 0)) {
          this.httpService.done();
        }

        this.savePlanoGerencialModulos();
      }, err => {
        console.log(err);
        this.exibirMensagem(err);
        this.httpService.done();
      });
  }

  postColunas() {
    const httpOptions2 = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
      })
    };

    let columnIdIndice = {};

    for (let coluna of this.configRelat.colunas) {
      if (coluna.id != null) {
        columnIdIndice[coluna.indice] = coluna.id;
      }
    }

    let payload = {
      empresaid: this.dblinkedSessionService.empresa.id,
      userid: this.sessionService.loggedUser.id,
      configRelatId: this.configRelat.id,
      planoGerencialId: this.configRelat.planoGerencialId,
      columns: this.newColumns,
      listFormulasAnalise: this.newlistFormulasAnalise,
      columnIdIndice: columnIdIndice,
      dayRef: 1,
      monthRef: this.dblinkedSessionService.periodo.month.value,
      yearRef: this.dblinkedSessionService.periodo.year.value
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/config-relat-column', payload, httpOptions2)
      .subscribe(res => {
        this.exibirMensagem(res);
        if (!res["error"]) {
          let resColumns = res["columns"];
          let resFormulas = res["formulas"];
          this.newColumns = new Array<ColumnRelat>();
          this.isConfigSaved = true;

          resColumns.forEach(resCol => { //resCol -> [colId, colIndice, isFormulaPgc]
            let coluna = this.configRelat.colunas.find(col => col.indice === resCol[1]);
            coluna.id = resCol[0];

            resFormulas.forEach(formula => {
              let i = this.listFormulasAnalise.findIndex(pgcform => pgcform.columnIndice === formula[1] && pgcform.pgcId === formula[2]);

              if (i > -1) {
                this.listFormulasAnalise[i].id = formula[0];
              }
            });
          });
        }

        this.newlistFormulasAnalise = new Array<ColumnFormulaPgc>();
        this.httpService.done();
      }, err => {
        console.log(err);
        this.exibirMensagem(err);
        this.httpService.done();
      });

    this.httpService.done();
  }

  gravarConfigComposta() {
    if (this.configRelat.nome == null || this.configRelat.nome === "") {
      this.exibirMensagem({ error: true, mensagem: "Preencha o campo do nome." });
      return;
    } else if (this.configRelat.codigo == null || this.configRelat.codigo === "") {
      this.exibirMensagem({ error: true, mensagem: "Preencha o campo do código." });
      return;
    } else if (this.listConfigRelatComposta.length < 2) {
      this.exibirMensagem({ error: true, mensagem: "Adicione pelo menos duas configurações." });
      return;
    }

    for (let conf of this.listConfigRelatComposta) {
      if (conf.worksheet_title == null || conf.worksheet_title === "") {
        this.exibirMensagem({ error: true, mensagem: "Adicione um título para todas as abas." });
        return;
      }
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    const payload = {
      empresaid: this.dblinkedSessionService.empresa.id,
      userid: this.sessionService.loggedUser.id,
      nome: this.configRelat.nome,
      codigo: this.configRelat.codigo,
      hideNoValueContas: this.configRelat.hideNoValueContas,
      listConfigRelatComposta: this.listConfigRelatComposta,
      planoGerencialId: null,
      isComposta: true,
      listTipoVisao: this.listTipoVisao,
      showFormulas: this.configRelat.showFormulas
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', payload, httpOptions)
      .subscribe(
        res => {
          if (!res["error"]) {
            this.configRelat.id = res["configId"];
            this.selectedConfig.push(new ConfigRelat(this.configRelat));
            this.isConfigSaved = true;
            this.getConfiguracoes();
          }

          this.exibirMensagem(res);
          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        });
  }

  editarConfigComposta() {
    if (this.configRelat.nome == null || this.configRelat.nome === "") {
      this.exibirMensagem({ error: true, mensagem: "Preencha o campo do nome." });
      return;
    } else if (this.configRelat.codigo == null || this.configRelat.codigo === "") {
      this.exibirMensagem({ error: true, mensagem: "Preencha o campo do código." });
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    const payload = {
      empresaid: this.dblinkedSessionService.empresa.id,
      userid: this.sessionService.loggedUser.id,
      nome: this.configRelat.nome,
      codigo: this.configRelat.codigo,
      planoGerencialId: null,
      hideNoValueContas: this.configRelat.hideNoValueContas,
      isComposta: true,
      listConfigRelatComposta: this.listConfigRelatComposta,
      configRelatId: this.configRelat.id,
      listTipoVisao: this.listTipoVisao,
      showFormulas: this.configRelat.showFormulas
    }

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', payload, httpOptions)
      .subscribe(
        res => {
          if (!res["error"]) {
            this.getConfiguracoes();
          }

          this.exibirMensagem(res);
          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        });
  }

  calculateColumns() {
    if (!this.isConfigSaved) {
      this.exibirMensagem({ error: true, mensagem: 'Salve a configuração antes de realizar essa ação.' });
      return;
    }

    if (this.editedColumns.length > 0 || this.newColumns.length > 0) {
      if (!confirm('Você tem alterações não salvas, essas alterações não serão consideradas no calculo dos novos valroes. Desja continuar?')) {
        return;
      }
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    const payload = {
      empresaid: this.dblinkedSessionService.empresa.id,
      configRelatId: this.configRelat.id,
      planoGerencialId: this.configRelat.planoGerencialId,
      dateRef: [1, this.dblinkedSessionService.periodo.month.value, this.dblinkedSessionService.periodo.year.value]
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/config-relat-column-values', payload, httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();
            return;
          }

          for (let conta of this.contasGerenciais) {
            for (let col of this.configRelat.colunas) {
              if (col.indice === 0 || col.tipo === 2) continue;

              conta[this.alfabeto(col.indice)] = res['diccontas'][conta['id']][this.alfabeto(col.indice)];
            }
          }

          for (let pgcFormula of res["formulas_analise"]) {
            let formula = new ColumnFormulaPgc();

            formula.id = pgcFormula['id'];
            formula.configRelatColumnId = pgcFormula['config_relat_column_id'];
            formula.pgcId = pgcFormula['pgc_id'];
            formula.formula = pgcFormula['formula'];
            formula.columnIndice = pgcFormula['column_indice'];
            formula.columnReferenciaIndice = pgcFormula['column_referencia_indice'];
            formula.columnReferenciaId = pgcFormula['column_referencia_id'];
            formula.isValor = pgcFormula['isvalor'];
            formula.isPercent = pgcFormula['ispercent'];
            let contaIndex = this.contasGerenciais.findIndex(c => c.id === formula.pgcId);

            if (contaIndex >= 0) {
              this.contasGerenciais[contaIndex][this.alfabeto(formula.columnIndice) + "-pgc"] = {};
              this.contasGerenciais[contaIndex][this.alfabeto(formula.columnIndice) + "-pgc"]['isFormulaPgc'] = true;
              this.contasGerenciais[contaIndex][this.alfabeto(formula.columnIndice) + "-pgc"]['isPercent'] = formula.isPercent;
              this.contasGerenciais[contaIndex][this.alfabeto(formula.columnIndice) + "-pgc"]['isValor'] = formula.isValor;
            }

            this.listFormulasAnalise.push(formula);
          }

          this.showHideConta(this.configRelat.hideNoValueContas);
          this.httpService.done();
        }, err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        });
  }

  check(tipo: string) {
    switch (tipo) {
      case "fundoH": {
        if (this.addColuna.isCorFundoCabecalho && !this.addColuna.isCorFundoColuna) {
          this.addColuna.corFundo = null;
        }

        break;
      }
      case "fundoC": {
        if (this.addColuna.isCorFundoColuna && !this.addColuna.isCorFundoCabecalho) {
          this.addColuna.corFundo = null;
        }

        break;
      }
      case "textoH": {
        if (this.addColuna.isCorTextoCabecalho && !this.addColuna.isCorTextoColuna) {
          this.addColuna.corTexto = null;
        }

        break;
      }
      case "textoC": {
        if (this.addColuna.isCorTextoColuna && !this.addColuna.isCorTextoCabecalho) {
          this.addColuna.corTexto = null;
        }

        break;
      }
      case "bold": {
        if (this.addColuna.isCabecalhoNegrito)
          this.addColuna.isColunaNegrito = false;

        break;
      }
      case "valor": {
        this.addColuna.isPercent = false;
        break;
      }
      case "percent": {
        this.addColuna.isValor = false;
        break;
      }
      case "pgcFormula": {
        this.addColuna.isPercent = false;
        this.addColuna.isValor = false;
        this.addColuna.formula = null;
        this.requiredFields = new Array();
        this.requiredFields.push("tipo", "qtdDecimais", "label");
        break;
      }
      case "pgcPercent": {
        this.addFormulaAnalise.isValor = false;
        break;
      }
      case "pgcValor": {
        this.addFormulaAnalise.isPercent = false;
        break;
      }
      case "hide": {
        this.showHideConta(!this.configRelat.hideNoValueContas);
        break;
      }
    }
  }

  changeTipo(tipo: number) {
    this.addColuna = new ColumnRelat();
    this.addColuna.tipo = tipo;

    this.addFormulaAnalise = new ColumnFormulaPgc();
    this.empresasSelecionadas = new Array();
    this.requiredFields = new Array<string>();
    this.requiredFields.push("tipo", "qtdDecimais", "label");

    if (this.addColuna.tipo === 1) {
      this.requiredFields.push("viewCampoValor");
    } else if (this.addColuna.tipo === 3) {
      if (!!this.addColuna.isFormulaPgc) {
        this.requiredFields.push("formula");
      }

      this.loadOpcoesColunas();
    }
  }

  alfabeto(index) {
    let ordA = 'a'.charCodeAt(0);
    let ordZ = 'z'.charCodeAt(0);
    let len = ordZ - ordA + 1;

    let col = "";

    while (index >= 0) {
      col = String.fromCharCode(index % len + ordA) + col;
      index = Math.floor(index / len) - 1;
    }

    return col;
  }

  enableEdit(col) {
    this.formTitle = "Editar coluna";
    this.isAdding = false;
    this.isEditing = true;
    this.changeTipo(col.tipo);

    this.empresasSelecionadas = col['empresasSelecionadas'] ? col['empresasSelecionadas'] : [];
    this.addColuna = new ColumnRelat(col);
    this.loadColumnFormulas();
    this.editingColumnIndice = col.indice;
  }

  editFormulaPgc(formula: ColumnFormulaPgc) {
    this.addFormulaAnalise = new ColumnFormulaPgc(formula);
    this.contasSelecionadas = new Array();
    this.contasSelecionadas.push(formula.pgcId);
  }

  deleteFormulaPgc(formula: ColumnFormulaPgc) {
    if (!confirm('Deseja realmente deletar essa formula?')) return;

    if (formula.id != null) {
      this.deletedFormulasAnalise.push(formula.id);
    }

    this.addColumnFormulasAnalise = this.addColumnFormulasAnalise.filter(analise => analise.pgcId !== formula.pgcId);
    this.listFormulasAnalise = this.listFormulasAnalise.filter(analise => analise.pgcId !== formula.pgcId || analise.columnIndice !== formula.columnIndice);
    this.newlistFormulasAnalise = this.newlistFormulasAnalise.filter(analise => analise.pgcId !== formula.pgcId || analise.columnIndice !== formula.columnIndice);

    let index = this.contasGerenciais.findIndex(c => c.id === formula.pgcId);
    this.contasGerenciais[index][this.alfabeto(formula.columnIndice)] = undefined;
    delete this.contasGerenciais[index][this.alfabeto(formula.columnIndice) + "-pgc"];
    this.loadColumnFormulas();
  }

  onSelectViewCampo(campo: string) {
    if (this.addColuna.sql === null || this.addColuna.sql === undefined)
      this.addColuna.sql = `${campo}`;
    else
      this.addColuna.sql += ` ${campo}`;
  }

  onSelectLabelCampo(campo: string) {
    if (this.addColuna.label === null || this.addColuna.label === undefined) {
      this.addColuna.label = `{${campo}}`;

    } else {
      this.addColuna.label += `{${campo}}`;
    }
  }

  onSelectColumn(coluna: ColumnRelat) {
    if (this.addColuna.tipo === 3 && !this.addColuna.isFormulaPgc) {
      if (coluna.indice === 0) {
        this.exibirMensagem({ error: true, mensagem: "Não é possível utilizar a coluna A em uma formula." });
        return;
      }

      if (this.addColuna.formula === null || this.addColuna.formula === undefined)
        this.addColuna.formula = this.alfabeto(coluna.indice).toUpperCase();
      else
        this.addColuna.formula += ` ${this.alfabeto(coluna.indice).toUpperCase()}`;
    }
  }

  onSelectConta(conta: any, indice: number) {
    if (this.contasSelecionadas.length < 1 || this.addFormulaAnalise.columnReferenciaIndice == null) return;

    if (this.addColuna.isFormulaPgc) {
      if (indice === 0) {
        this.exibirMensagem({ error: true, mensagem: "Não é possível utilizar a coluna A em uma formula." });
        return;
      }

      if (this.addFormulaAnalise.formula != null)
        this.addFormulaAnalise.formula += this.alfabeto(indice) + (conta['rowIndexPreview'] + 1);
      else
        this.addFormulaAnalise.formula = this.alfabeto(indice) + (conta['rowIndexPreview'] + 1);
    }
  }

  onChangeContas(contasSelecionadas: any) {
    if (contasSelecionadas.length === 1) {
      let indice = this.addColuna.indice || this.configRelat.colunas[this.configRelat.colunas.length - 1].indice + 1;
      let formula = this.listFormulasAnalise.find(formula => formula.pgcId === contasSelecionadas[0]) || this.addColumnFormulasAnalise.find(formula => formula.pgcId === contasSelecionadas[0]);

      if (formula != null && indice === formula.columnIndice) {
        this.addFormulaAnalise = formula;
        return;
      }
    }

    if (contasSelecionadas.length > 0 && this.addFormulaAnalise.columnReferenciaIndice != null) {
      let indice = this.alfabeto(this.addFormulaAnalise.columnReferenciaIndice);

      if (this.addFormulaAnalise.formula != null) {
        let reg = new RegExp(`\\b${indice}+\\b`, 'g');
        let match = this.addFormulaAnalise.formula.toLowerCase().match(reg);

        if (match === null) {
          this.addFormulaAnalise.formula = `${indice} ${this.addFormulaAnalise.formula}`;
        }
      } else {
        this.addFormulaAnalise.formula = indice;
      }
    }
  }

  loadOpcoesColunas() {
    this.opcoesColunas = new Array();

    this.configRelat.colunas.forEach(col => {
      if (col.indice !== 0) this.opcoesColunas.push({ label: this.alfabeto(col.indice), value: col.indice });
    });
  }

  checkConfig(event: any) {
    this.selectedConfig.push(event.data);
    this.reloadToolbar();
  }

  uncheckConfig(event: any) {
    let index = this.selectedConfig.findIndex(c => c.id === event.data.id);
    this.selectedConfig.splice(index, 1);
    this.reloadToolbar();
  }

  reloadToolbar() {
    let selectedLength = this.selectedConfig.length;
    this.toolbarMain.setVisible('save', this.isAdding || this.isEditing || this.composeConfig);

    if (this.configRelat.isComposta) {
      if (this.isConfigSaved)
        this.toolbarMain.setClick('save', () => this.editarConfigComposta());
      else
        this.toolbarMain.setClick('save', () => this.gravarConfigComposta());
    } else {
      if (this.isConfigSaved)
        this.toolbarMain.setClick('save', () => this.editarConfig());
      else
        this.toolbarMain.setClick('save', () => this.gravarConfig());
    }

    this.toolbarMain.setVisible('update', !this.configRelat.isComposta && (this.isAdding || this.isEditing));
    this.toolbarMain.setVisible('back', this.isAdding || this.isEditing);
    this.toolbarMain.setVisible('remove', selectedLength > 0);
    this.toolbarMain.setVisible('new', !this.isAdding && !this.isEditing);
    this.toolbarMain.setVisible('edit', selectedLength == 1 && !this.isAdding && !this.isEditing);
  }

  formatarLabel(label: string) {
    if (label.includes("{") && label.includes("}")) {
      var separador: Array<string> = ["{", "}"];
      var lilabel: Array<string> = label.split(new RegExp(separador.join("|"), 'g'));

      for (let i in lilabel) {
        if (lilabel[i].includes("DATA.day")) {
          lilabel[i] = eval(lilabel[i].replace("DATA.day", "1")).toString();

        } else if (lilabel[i].includes("DATA.month")) {
          lilabel[i] = this.limesstr[Number(eval(lilabel[i].replace("DATA.month", this.dblinkedSessionService.periodo.month.value.toString()))) - 1];

        } else if (lilabel[i].includes("DATA.year")) {
          lilabel[i] = eval(lilabel[i].replace("DATA.year", this.dblinkedSessionService.periodo.year.value.toString())).toString();
        }
      }

      return lilabel.join(" ")

    } else {
      return label
    }
  }

  moveColumn(direction: number, indice: number) {
    if (indice + direction >= this.configRelat.colunas.length) return;

    let colToReplace1 = this.getColumnToReplace(indice + direction);
    let colToReplace2 = this.getColumnToReplace(indice);

    this.configRelat.colunas[indice + direction].indice = indice;
    this.configRelat.colunas[indice].indice = indice + direction;

    for (let key in this.configRelat.colunas[indice + direction]) {
      colToReplace1[key] = this.configRelat.colunas[indice + direction][key];
    }

    for (let key in this.configRelat.colunas[indice]) {
      colToReplace2[key] = this.configRelat.colunas[indice][key];
    }

    this.configRelat.colunas.sort((col1, col2) => col1.indice > col2.indice ? 1 : -1);
    this.columnIndiceChanged(indice, indice + direction, true);
    this.checkFormulasAnalise();
    for (let coluna of this.configRelat.colunas) if (coluna.tipo == 3 && !coluna.isFormulaPgc) coluna.formula = coluna.formula.toLowerCase();
  }

  columnIndiceChanged(oldIdx: number, newIdx: number, updateOld: boolean) {
    let newLetter = this.alfabeto(newIdx);
    let oldLetter = this.alfabeto(oldIdx);

    if (updateOld) {
      for (let conta of this.contasGerenciais) {
        let value = conta[oldLetter];
        conta[oldLetter] = conta[newLetter];
        conta[newLetter] = value;
      }
    }

    let regexOld = new RegExp(`\\b${oldLetter}+\\b`, 'g');
    let regexNew = new RegExp(`\\b${newLetter}+\\b`, 'g');
    for (let i in this.configRelat.colunas) {
      if (this.configRelat.colunas[i].tipo == 3) {
        if (!this.configRelat.colunas[i].isFormulaPgc) {
          let formula = this.configRelat.colunas[i].formula;
          formula = formula.replace(regexOld, newLetter.toUpperCase());
          if (updateOld) formula = formula.replace(regexNew, oldLetter).toLowerCase();

          this.configRelat.colunas[i].formula = formula;

          let colToReplace = this.getColumnToReplace(Number(i));
          for (let key in this.configRelat.colunas[i]) {
            colToReplace[key] = this.configRelat.colunas[i][key];
          }
        }
      }
    }

    let lists = [this.listFormulasAnalise];
    for (let list of lists) {
      for (let j in list) {
        let formula = list[j].formula;
        formula = formula.replace(regexOld, newLetter.toUpperCase());

        //substituir o indice antigo pelo indice novo
        let indexOf = formula.indexOf(oldLetter);
        while (indexOf != -1) {
          if (!(indexOf > 0 && formula[indexOf - 1].match(/[a-z]/i)) && !isNaN(Number(formula[indexOf + oldLetter.length]))) {
            let newFormula = formula;
            newFormula = formula.substring(0, indexOf);
            newFormula += newLetter.toUpperCase();
            newFormula += formula.substring(indexOf + oldLetter.length, formula.length);
            formula = newFormula;
          } else {
            let regex = new RegExp(`\\${oldLetter}\\`)
            formula.replace(regex, oldLetter.toUpperCase());
          }

          indexOf = formula.indexOf(oldLetter);
        }

        //substituir o indice novo pelo antigo, somente qundo a edição está sendo feita pelas setinhas
        if (updateOld) {
          formula = formula.replace(regexNew, oldLetter);
          let indexOf = formula.indexOf(newLetter);
          while (indexOf != -1) {
            if (!(indexOf > 0 && formula[indexOf - 1].match(/[a-z]/i)) && !isNaN(Number(formula[indexOf + newLetter.length]))) {
              let newFormula = formula;
              newFormula = formula.substring(0, indexOf);
              newFormula += oldLetter;
              newFormula += formula.substring(indexOf + newLetter.length, formula.length);
              formula = newFormula;
            } else {
              let regex = new RegExp(`\\${newLetter}\\`)
              formula.replace(regex, newLetter.toUpperCase());
            }

            indexOf = formula.indexOf(newLetter);
          }

          //atualizar columnReferenciaIndice e configRelatColumnIndice
          if (list[j].columnIndice == oldIdx)
            list[j].columnIndice = newIdx;
          else if (list[j].columnIndice == newIdx)
            list[j].columnIndice = oldIdx;

          if (list[j].columnReferenciaIndice == oldIdx)
            list[j].columnReferenciaIndice = newIdx;
          else if (list[j].columnReferenciaIndice == newIdx)
            list[j].columnReferenciaIndice = oldIdx;
        }

        list[j].formula = formula;
      }
    }
  }

  checkFormulasAnalise() {
    //verifica se as propriedades columnIndice e columnReferenciaIndice batem com o indice da coluna e deixa todas as formulas em minúsculo.
    let listas = [this.newlistFormulasAnalise, this.listFormulasAnalise];
    for (let i in listas) {
      for (let formula of listas[i]) {
        if (formula.configRelatColumnId != null) {
          let col = this.configRelat.colunas.find(c => c.id == formula.configRelatColumnId);
          if (col) formula.columnIndice = col.indice;

          col = this.configRelat.colunas.find(c => c.id == formula.columnReferenciaId);
          if (col) formula.columnReferenciaIndice = col.indice;
        }

        formula.formula = formula.formula.toLocaleLowerCase();

        if (i == '1') {
          let idx = this.newlistFormulasAnalise.findIndex(f => f.configRelatColumnId == formula.configRelatColumnId && f.pgcId == formula.pgcId);
          if (idx > -1) this.newlistFormulasAnalise.splice(idx, 1);
          this.newlistFormulasAnalise.push(new ColumnFormulaPgc(formula));
        }
      }
    }
  }

  isHidden(conta: any) {
    if (!this.isConfigSaved || this.configRelat.colunas.length <= 1) return false;

    let hidden = true;

    for (let i in this.configRelat.colunas) {
      if (this.configRelat.colunas[i].indice === 0) continue;
      let indice = this.alfabeto(this.configRelat.colunas[i].indice);

      if (this.configRelat.colunas[i].tipo !== 3 && conta[indice] != null && conta[indice] != 0) {
        hidden = false;
        break;
      }
    }

    return hidden;
  }

  getCta(ctaId: number) {
    for (let conta of this.contasGerenciais) {
      if (conta.id === ctaId) return conta;
    }

    return null;
  }

  loadColumnFormulas() {
    this.columnFormulasAnalise = new Array();

    for (let formula of this.listFormulasAnalise) {
      if (formula.columnIndice === this.addColuna.indice) {
        let index = this.columnFormulasAnalise.push(new ColumnFormulaPgc(formula)) - 1;
        let conta = this.getCta(formula.pgcId);
        this.columnFormulasAnalise[index]['pgcDescr'] = conta ? conta.cod_cta ? `${conta.cod_cta} - ${conta.a}` : conta.a : '';
      }
    }

    let indice = this.isAdding ? this.configRelat.colunas[this.configRelat.colunas.length - 1].indice + 1 : this.addColuna.indice;

    for (let formula of this.addColumnFormulasAnalise) {
      if (formula.columnIndice === indice) {
        let index = this.columnFormulasAnalise.push(new ColumnFormulaPgc(formula)) - 1;
        let conta = this.getCta(formula.pgcId);
        this.columnFormulasAnalise[index]['pgcDescr'] = conta ? conta.cod_cta ? `${conta.cod_cta} - ${conta.a}` : conta.a : '';
      }
    }
  }

  showHideConta(hide) {
    if (hide) {
      for (let conta of this.contasGerenciais) {
        conta['isHidden'] = this.isHidden(conta);
      }
    }
  }

  changeStateForm() {
    this.currentStateForm = this.currentStateForm === 'initial' ? 'final' : 'initial';
  }

  changeStateView() {
    this.currentStateView = this.currentStateView === 'initial' ? 'final' : 'initial';
  }

  changeStateFormula() {
    this.currentStateFormula = this.currentStateFormula === 'initial' ? 'final' : 'initial';
  }

  changeStatePreview() {
    this.currentStatePreview = this.currentStatePreview === 'initial' ? 'final' : 'initial';
  }

  /*Show Hide Table*/
  isCardTable: boolean = true;
  ShowHIdeCardTable() {
    this.isCardTable = !this.isCardTable;
  }

  isCardView: boolean = true;
  ShowHideCardView() {
    this.isCardView = !this.isCardView;
  }

  isCardFormula: boolean = true;
  ShowHideCardFormula() {
    this.isCardFormula = !this.isCardFormula;
  }

  isCardPreview: boolean = true;
  ShowHideCardPreview() {
    this.isCardPreview = !this.isCardPreview;
  }

  getClass(col, conta) {

    return col.indice === 0 ? conta['nivel'] === 1 ? 'nivel1' : conta['nivel'] === 2 ? 'nivel2' : conta['nivel'] === 3 ? 'nivel3' : conta['nivel'] === 4 ? 'nivel4' : 'nivel5'
      : col.tipo === 2 ? 'separador' : ''

  }

  getPlanoGerencialModulos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'relatId': this.configRelat.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/plano-gerencial-modulos', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
          }
          this.planoGerencialModulos = new Array();
          for (let mod of res["mensagem"]) {
            this.planoGerencialModulos.push(mod)
          }
          this.httpService.done();
        },

        err => {
          if (err["error"] != null || err["error"] != undefined) {
            this.exibirMensagem(err);

          } else {
            this.msgs = [];
            this.msgs.push({ severity: 'warn', summary: 'Erro', detail: "Não foi possível acessar o API, entre em contato com o suporte." });
            console.log(err);
          }
          this.httpService.done();
        }
      );
  }

  savePlanoGerencialModulos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    const infos = {
      "relatId": this.configRelat.id.toString(),
      //"descricao": this.planoGerencialNome,
      "empresa_id": this.dblinkedSessionService.empresa.id,
      "modulos": this.planoGerencialModulos,
      "user_id": this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'contabil/plano-gerencial-modulos', infos, httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
          }

          this.httpService.done();
        },

        err => {
          if (err["error"] != null || err["error"] != undefined) {
            this.exibirMensagem(err);

          } else {
            this.msgs = [];
            this.msgs.push({ severity: 'warn', summary: 'Erro', detail: "Não foi possível acessar o API, entre em contato com o suporte." });
            console.log(err);
          }
          this.httpService.done();
        }
      );
  }

  selectCheckbox(empresaId, value) {
    if (value.checked) this.empresasSelecionadas.push(empresaId);

    else this.empresasSelecionadas = this.empresasSelecionadas.filter(id => id != empresaId);
  }

  public changeFormulas(): void {
    if(this.configRelat.showFormulas)if(! confirm('Aviso: Esta opção é incompatível com o "inverter sinal" da conta. Isso não impede de gerar o relatório.')) setTimeout(() => {
      this.configRelat.showFormulas = false
    }, 1)
  }
}
