import { AuthUser } from 'app/proof/model/auth-user.model';
import { LocalidadeGrupoMunicipioPK } from 'app/financeiro/model/localidade-grupo-municipio-pk.model';
import { LocalidadeGrupo } from 'app/financeiro/model/localidade-grupo.model';
import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { Uf } from 'app/util/model/uf.model';


export class LocalidadeGrupoMunicipio {

  localidadeGrupoMunicipioPK: LocalidadeGrupoMunicipioPK;
  localidadeGrupo: LocalidadeGrupo;
  municipioIbge: MunicipioIbge;
  selectedUF: Uf;
  changeUser: AuthUser;
  changeTimestamp: Date;

  constructor(localidadeGrupoMunicipio?: LocalidadeGrupoMunicipio | LocalidadeGrupoMunicipioPK) {
    if (localidadeGrupoMunicipio !== undefined && localidadeGrupoMunicipio !== null) {
      if (localidadeGrupoMunicipio instanceof LocalidadeGrupoMunicipioPK) {
        this.localidadeGrupoMunicipioPK = localidadeGrupoMunicipio;
      } else {
        this.localidadeGrupoMunicipioPK = localidadeGrupoMunicipio.localidadeGrupoMunicipioPK;
        this.localidadeGrupo = localidadeGrupoMunicipio.localidadeGrupo;
        this.municipioIbge = localidadeGrupoMunicipio.municipioIbge;
        this.changeUser = localidadeGrupoMunicipio.changeUser;
        this.changeTimestamp = localidadeGrupoMunicipio.changeTimestamp;
     }
    }
  }

}
