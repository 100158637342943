import { CadEcd } from './cad-ecd.model';
import { LancamentoPartida } from './lancamento-partida.model';

export class Lancamento {
  id: number;
  numero: string;
  lote: string;
  dataLcto: Date;
  indLcto: string;
  cadEcd: CadEcd = new CadEcd();
  lancamentoPartidaList: Array<LancamentoPartida>;
  lctoManual: boolean;
  ativo: boolean;

constructor(lancamento?: Lancamento | number) {
    if (lancamento !== undefined && lancamento !== null) {
      if (typeof lancamento === 'number') {
        this.id = lancamento;
      } else {
        this.id = lancamento.id;
        this.numero = lancamento.numero;
        this.lote = lancamento.lote;
        this.dataLcto = lancamento.dataLcto;
        this.indLcto = lancamento.indLcto;
        this.cadEcd = lancamento.cadEcd || new CadEcd();
        this.lancamentoPartidaList = lancamento.lancamentoPartidaList;
        this.lctoManual = lancamento.lctoManual;
        this.ativo = lancamento.ativo;
      }
    }
  }
}
