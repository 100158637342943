import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-cad-lei-do-bem',
  templateUrl: './cad-lei-do-bem.component.html',
  styleUrls: ['./cad-lei-do-bem.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadLeiDoBemComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = [];
  titlePrincipal: string;
  subtitle: string;
  modalNew: boolean = false;
  modalEdit: boolean = false;
  newCodigo: string;
  newDescricao: string;
  editCodigo: string;
  editDescricao: string;
  ldbProjetosLista: Array<any>;
  projetosLdb: SelectItem[];
  projetoLdbId: number;
  listaProjetosDisponiveis: Array<any>;
  listaProjetosVinculados: Array<any>;
  listaSegmentosDisponiveis: Array<any>;
  listaSegmentosVinculados: Array<any>;
  contadorLinha: number;
  colsCadLeiDoBem: any[];


  constructor(
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }

    @ViewChild('dt') dt: Table
  export() {
    this.dt.exportCSV()
  }

    public toolbarMain = new Toolbar()
    public toolbarMainVin = new Toolbar()

  toolbarMainIni = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: false, disabled: false, color: 'default', onClick: () => this.dt.exportCSV() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openEditModal() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.deleteLeiDoBem() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.openNewModal() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardTable()
        this.changeStateTable()
      }
    },
  ]
  toolbarMainIniVin = [
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Vinculação de Projeto', visible: false, disabled: false, color: 'default', onClick: () => this.salvarVinculacaoProjetos() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Vinculação de Segmento', visible: false, disabled: false, color: 'default', onClick: () => this.salvarVinculacaoSegmentos() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: false, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardVinculacao()
        this.changeStateVinculacao()
      }
    },
  ]

  ngOnInit(): void {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarMainVin.setAll(this.toolbarMainIniVin)
    this.toolbarService.hidden = true
    this.titlePrincipal = 'LEI DO BEM';
    this.subtitle = 'VINCULAÇÃO LEI DO BEM';

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.toolbarEnable();
        this.loadTableProjetos();
        this.loadLeiDoBem();
        this.loadProjetosRelac();
        this.loadSegmentosRelac();

        this.toolbarMain.setVisible('btn1', true)
        this.toolbarMain.setVisible('btn2', false)
        this.toolbarMain.setVisible('btn3', false)
        this.toolbarMain.setVisible('btn4', true)
        this.toolbarMain.setVisible('btn5', true)

        this.toolbarMainVin.setVisible('btn1', true)
        this.toolbarMainVin.setVisible('btn2', false)
        this.toolbarMainVin.setVisible('btn3', true)
      }
    });

    this.contadorLinha = 0;

    this.colsCadLeiDoBem = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'data', header: 'ÚLTIMA EDIÇÃO' },
      { field: 'descricao', header: 'DESCRICÃO' }
    ];

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  loadLeiDoBem() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };
    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/leidobem/loadleidobem', httpOptions)
      .subscribe(
        res => {
          this.projetosLdb = new Array();
          let result = res["mensagem"];
          for (let line of result) {
            this.projetosLdb.push(line);
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  loadProjetosRelac() {
    if (!isNullOrUndefined(this.projetoLdbId)) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'ldbid': this.projetoLdbId.toString()
        })
      };
      this.httpService.wait();
      this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/leidobem/loadprojetos', httpOptions)
        .subscribe(
          res => {
            this.listaProjetosDisponiveis = new Array();
            this.listaProjetosVinculados = new Array();
            let result = res["mensagem"];
            for (let line of result) {
              if (line["ldbid"] === this.projetoLdbId) {
                this.listaProjetosVinculados.push(line);
              } else {
                this.listaProjetosDisponiveis.push(line);
              }
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  salvarVinculacaoProjetos() {
    if (!isNullOrUndefined(this.listaProjetosVinculados)) {
      var liprojid: Array<any> = new Array();
      for (let line of this.listaProjetosVinculados) {
        liprojid.push(line.id);
      }

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'liprojid': liprojid.toString(),
          'ldbid': this.projetoLdbId.toString(),
          'userid': this.sessionService.loggedUser.id.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'contabil/leidobem/saveldbprojrelac', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.httpService.done();
            this.loadProjetosRelac();
            this.loadTableProjetos();

          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  onRowSelect(row) {
    this.projetoLdbId = row.id;
    this.editCodigo = row.codigo;
    this.editDescricao = row.descricao;
    this.loadProjetosRelac();
    this.loadSegmentosRelac();

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', true)
    this.toolbarMain.setVisible('btn3', true)
    this.toolbarMain.setVisible('btn4', true)
    this.toolbarMain.setVisible('btn5', true)
  }

  onRowUnselect(){
    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', true)
    this.toolbarMain.setVisible('btn5', true)
  }

  editLeiDoBem() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'ldbid': this.projetoLdbId.toString(),
        'codigo': this.editCodigo.toString(),
        'descricao': this.editDescricao.toString(),
        'userid': this.sessionService.loggedUser.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'contabil/leidobem/editleidobem', null, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.loadTableProjetos();
          this.loadLeiDoBem();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

    this.modalEdit = false;
  }

  deleteLeiDoBem() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'ldbid': this.projetoLdbId.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'contabil/leidobem/deleteleidobem', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.loadTableProjetos();
          this.loadLeiDoBem();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );

    this.modalEdit = false;
  }

  salvarVinculacaoSegmentos() {
    if (!isNullOrUndefined(this.listaSegmentosVinculados)) {
      var lisegid: Array<any> = new Array();
      for (let line of this.listaSegmentosVinculados) {
        lisegid.push(line.id);
      }

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'lisegid': lisegid.toString(),
          'ldbid': this.projetoLdbId.toString(),
          'userid': this.sessionService.loggedUser.id.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'contabil/leidobem/saveldbsegrelac', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.httpService.done();
            this.loadSegmentosRelac();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  loadSegmentosRelac() {
    if (!isNullOrUndefined(this.projetoLdbId)) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'ldbid': this.projetoLdbId.toString()
        })
      };
      this.httpService.wait();
      this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/leidobem/loadsegmentos', httpOptions)
        .subscribe(
          res => {
            this.listaSegmentosDisponiveis = new Array();
            this.listaSegmentosVinculados = new Array();
            let result = res["mensagem"];
            console.log(result)
            for (let line of result) {
              if (line["ldbid"] === this.projetoLdbId) {
                this.listaSegmentosVinculados.push(line);
              } else {
                this.listaSegmentosDisponiveis.push(line);
              }
            }
            this.httpService.done();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  loadTableProjetos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };
    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/leidobem/loadprojrelac', httpOptions)
      .subscribe(
        res => {
          this.ldbProjetosLista = new Array();
          let result = res["mensagem"];
          for (let line of result) {
            this.ldbProjetosLista.push(line);

            if (this.ldbProjetosLista !== null || this.ldbProjetosLista !== undefined) {
              this.contadorLinha = this.ldbProjetosLista.length;
            }
            else {
              this.contadorLinha = 0;
            }
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  hideMessage() {

  }

  toolbarEnable() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('remove');
    this.toolbarService.replace('edit', false, 'class-edit-button', 'edit', 'Editar', () => this.openEditModal());
    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Nova Lei do Bem', () => this.openNewModal());
  }

  openEditModal() {
    this.modalEdit = true;
  }

  openNewModal() {
    this.modalNew = true;
    this.newCodigo = "";
    this.newDescricao = "";
  }

  addLeiDoBem() {
    this.modalNew = false;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'userid': this.sessionService.loggedUser.id.toString(),
        'codigo': this.newCodigo.toString(),
        'descricao': this.newDescricao.toString()
      })
    };
    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'contabil/leidobem/addleidobem', null, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.loadTableProjetos();
          this.loadLeiDoBem();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  //Show/hide cards
  isCardTable: boolean = true;
  isCardVinculacao: boolean = true;

  currentStateTable = 'initial';
  currentStateVinculacao = 'initial';

  ShowHIdeCardTable() {
    this.isCardTable = !this.isCardTable;
  }

  ShowHIdeCardVinculacao() {
    this.isCardVinculacao = !this.isCardVinculacao;
    this.toolbarMainVin.setIcon('btn3', this.isCardVinculacao ? 'expand_less' : 'expand_more')
    this.toolbarMainVin.setTooltip('btn3', this.isCardVinculacao ? 'Ver menos' : 'Ver mais')
  }

  changeStateTable() {
    this.currentStateTable = this.currentStateTable === 'initial' ? 'final' : 'initial';
  }

  changeStateVinculacao() {
    this.currentStateVinculacao = this.currentStateVinculacao === 'initial' ? 'final' : 'initial';
  }

  //Telas
  statusTela1: boolean = true;
  statusTela2: boolean = false;

  showTela(tipo) {
    this.statusTela1 = false;
    this.statusTela2 = false;

    if (tipo === 'TELA_1') {
      this.statusTela1 = true;
      this.toolbarMainVin.setVisible('btn1', true)
      this.toolbarMainVin.setVisible('btn2', false)
      this.toolbarMainVin.setVisible('btn3', true)

    } else if (tipo === 'TELA_2') {
      this.statusTela2 = true;
      this.toolbarMainVin.setVisible('btn1', false)
      this.toolbarMainVin.setVisible('btn2', true)
      this.toolbarMainVin.setVisible('btn3', true)


    }
  }

  onFilter(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }

}
