import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';

@Component({
  selector: 'app-modal-plano-contas',
  templateUrl: './modal-plano-contas.component.html',
  styleUrls: ['./modal-plano-contas.component.scss']
})

export class ModalPlanoContasComponent implements OnInit {

  accountList: Array<any> = [];
  selectedAccount: Array<any> = [];

  totalAccount: number = 0;

  @Input() participanteTipoId: number = null

  @Input() initialState: Array<any> = [];

  @Input() isVisible: boolean = false;
  @Input() isMultiple: boolean = false;

  @Input() saveButtonLabel: string = 'Salvar';
  @Input() title: string = 'Escolher contas';

  @Input() contasAutomaticas: boolean = false;

  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() onSelectAccount = new EventEmitter();

  constructor(
    private httpService: HttpService,
    private configService: ConfigService,
    private messageService: MessageService,
    private dblinkedSessionService: DblinkedSessionService,
  ) {
    this.resetValues();
  }

  ngOnInit() {
    this.resetValues();
  }

  verifyInitial() {
    if (this.initialState.length > 0) this.selectedAccount = this.initialState;
  }

  resetValues() {

    this.accountList = new Array();
    this.selectedAccount = new Array();

    this.totalAccount = 0;

  }

  getAccounts(event: LazyLoadEvent = {}) {

    if (this.isVisible) {

      const paramload = {
        offset: event.first,
        limit: event.rows,
        sort_order: event.sortOrder,
        sort_field: event.sortField,
        filters: event.filters,
        empresa_id: this.dblinkedSessionService.empresa.id,
        ano: this.dblinkedSessionService.periodo.year.value,
        contas_automaticas: this.contasAutomaticas
      }

      this.httpService.wait();
      this.httpService.post(this.configService.defaultUrlApiUpload + 'contabil/plano-contas-lazyload', paramload)
        .subscribe(res => {
          if (res["error"]) {
            this.showMessage(res)
            this.httpService.done();
            return
          }

          this.accountList = []
          res["data"]["lista"].forEach(element => { this.accountList.push(element) })
          this.totalAccount = res["data"]["total"];
          this.httpService.done();

        }, error => {
          this.showMessage({ severity: 'error', message: "Erro no API ao carregar as contas contábeis, contate o suporte: " + error.message });
          console.log(error)
          this.httpService.done();
        });
    };

  }

  showMessage(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  onSave() {
    let response: any = {};

    if (this.isMultiple) {
      this.selectedAccount = this.selectedAccount.filter(item => item)
    }

    response.selectedAccount = this.selectedAccount;

    this.setVisibleState(false);
    this.onSelectAccount.emit(response);
    this.resetValues();
  }

  setVisibleState(state: boolean) {
    this.isVisible = state;
    this.isVisibleChange.emit(this.isVisible);
  }

}
