import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';

import { Util } from 'app/shared/common/util';
import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { ToolbarService } from './toolbar.service';
import { UtilService } from './util.service';

import { Subject } from 'rxjs/Subject';

import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from 'app/shared/services/session.service';

@Injectable()
export class CrudService implements OnDestroy {
  private undoConfirmText = 'As alterações serão perdidas! Deseja continuar?';

  public form: FormGroup;
  public controls: Array<DataControl> = new Array();
  public items: Array<any> = new Array();
  public itemsCount = 0;
  public viewing = false;

  private timeout: any;

  public columns: Array<DataTableColumn> = new Array();
  private entity: any;

  private application: string;
  private entityTitle: string;
  private entityPath: string;
  private entityFilter: string;
  private orderColumns: string;
  private routerPath: string;
  private entityConstructor: typeof Object;
  public key: any;

  private readonly = false;

  private autoEdit = false;

  private filterParams: string = null;
  private orderParams: string = null;
  // private sortIndex = 0;

  beforeList: Subject<any> = new Subject();
  afterList: Subject<any> = new Subject();
  beforeView: Subject<any> = new Subject();
  afterView: Subject<any> = new Subject();
  beforeAdd: Subject<any> = new Subject();
  afterAdd: Subject<any> = new Subject();
  beforeEdit: Subject<any> = new Subject();
  afterEdit: Subject<any> = new Subject();
  beforeSave: Subject<any> = new Subject();
  afterSave: Subject<any> = new Subject();
  beforeRemove: Subject<any> = new Subject();
  afterRemove: Subject<any> = new Subject();

  refresh: Subject<any> = new Subject();

  formSubscription: Subscription;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private configService: ConfigService,
    private utilService: UtilService,
    private toolbarService: ToolbarService,
    private sessionService: SessionService,
    private httpService: HttpService) {
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.formSubscription);

    this.editing = false;
    this.toolbarService.clear();
  }

  init(application: string,
    routerPath: string,
    entityTitle: string,
    entityPath: string,
    entityFilter: string,
    orderColumns: string,
    entity: any,
    key: any,
    entityConstructor: typeof Object) {
    this.application = application;
    this.routerPath = routerPath;
    this.entityTitle = entityTitle;
    this.entityPath = entityPath;
    this.entityFilter = entityFilter;
    this.orderColumns = orderColumns;
    this.entity = entity;
    this.key = key;
    this.entityConstructor = entityConstructor;

    // this._form(entityPath, entity); // TODO: REVIEW
  }

  get(controls: Array<DataControl>,
    entityPath: string,
    keyOrCallback: any = null,
    readonly: boolean = false) {
    this.readonly = readonly;
    this.entityPath = entityPath;
    this.controls = controls;

    if (keyOrCallback === undefined ||
      keyOrCallback === null ||
      typeof keyOrCallback === 'function' ||
      keyOrCallback.toString().trim() === '') {
      const params: string[] = entityPath.indexOf('?') > 0 ? entityPath.substr(entityPath.indexOf('?') + 1).split('&') : [];

      let skip: any = params.find(param => param.indexOf('$skip=') > -1);
      if (skip !== undefined) {
        skip = +skip.substr(6);
      }

      const more: boolean = (skip || 0) > 0;

      entityPath = entityPath.substr(0, this.entityPath.indexOf('?') + 1);

      let first = true;
      params.filter(param => param.trim().indexOf('$orderby=') === -1 &&
        param.trim().indexOf('$filter=') === -1)
        .forEach(param => {
          entityPath += (first ? '' : '&') + param;
          first = false;
        });

      this.buildFilters();

      let _filterParams: string = this.filterParams || '';
      if (this.entityFilter) {
        if (_filterParams) {
          _filterParams += ' and ';
        } else {
          _filterParams = (entityPath.indexOf('?') === -1 ? '?' : '&') + '$filter=';
        }
        _filterParams += this.entityFilter;
      }

      let _orderParams: string = this.orderParams || '';
      if (this.orderColumns) {
        if (_orderParams) {
          _orderParams += ',';
        } else {
          _orderParams = (!_filterParams && entityPath.indexOf('?') === -1 ? '?' : '&') + '$orderby=';
        }
        _orderParams += this.orderColumns;
      }
      this.httpService.wait();
      this.httpService.get(entityPath + _filterParams + _orderParams,
        null,
        this.application)
        .subscribe(items => this.list(entityPath, items, keyOrCallback, more, readonly),
          error => this.httpService.handleError(error,
            () => this.get(controls,
              entityPath,
              keyOrCallback,
              readonly), null, this.application),
          () => this.httpService.done());
    } else {
      this.httpService.wait();
      this.httpService.get(entityPath,
        keyOrCallback,
        this.application)
        .map(item => item.value || item)
        .subscribe(item => this.__view(entityPath, item),
          error => this.httpService.handleError(error,
            () => this.get(controls,
              entityPath,
              keyOrCallback,
              readonly), null, this.application),
          () => this.httpService.done());
    }
  }

  private list(entityPath: string,
    items: any,
    callback?: Function,
    more: boolean = false,
    readonly: boolean = false,
    isUndo: boolean = false) {
    this.entity = null;
    if (items['@odata.count'] !== undefined) {
      this.itemsCount = items['@odata.count'];
    }

    this.beforeList.next({
      items: items.value || items,
      count: this.itemsCount
    });

    if (!more) {
      this.items = new Array();
    }
    this.items = this.items.concat(items.value || items);

    this.toolbarService.clear();
    this.toolbarService.add('refresh', false, 'class-first-button', 'refresh', 'Atualizar', () => this.refresh.next());
    if (!readonly) {
      this.toolbarService.add('refresh', false, 'class-first-button', 'refresh', 'Atualizar', () => this.refresh.next());
      this.toolbarService.add('export', true, 'class-other-button', 'description', 'Exportar', () => this.export(entityPath));
      this.toolbarService.add('print', true, 'class-first-button', 'print', 'Imprimir', () => this.print(entityPath));
      this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir', () => this.remove(entityPath));
      this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar', () => this.edit(entityPath));
      this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Adicionar', () => this.add(entityPath));
    }

    this.viewing = false;
    this.editing = false;

    if (callback && typeof callback === 'function') {
      callback();
    }

    this.afterList.next(this.items);
  }

  print(selector: string = null) {
    this.toolbarService.print(selector);
  }

  view(id: number | string): void {
    this.router.navigate([this.routerPath, id]);
  }

  private __view(entityPath: string, entity: any): void {
    this.entity = new this.entityConstructor(entity);

    this.beforeView.next(this.entity);

    this.toolbarService.clear();

    this.viewing = true;
    this.editing = false;

    this._form(entityPath, entity);

    this.controls.forEach(control => {
      let skip = false;
      let value = Util.getFieldValue(this.entity, control.key);
      const controlColumn = this.columns.find(c => c.key === control.key);

      switch (control.type) {
        case 'date':
          value = this.utilService.timestampToInputFieldDate(value);
          break;
        case 'datetime':
          value = this.utilService.timestampToInputFieldDateTime(value);
          break;
        case 'date-local':
          value = this.utilService.timestampToInputFieldLocalDate(value);
          break;
        case 'datetime-local':
          value = this.utilService.timestampToInputFieldLocalDateTime(value);
          break;
        /*case 'time':
          break;*/ // TODO: REVIEW
        case 'money':
          value = this.utilService.parseMoneyString(value);
          break;
        case 'select':
          switch (value) {
            case undefined:
              value = -2;
              break;
            case null:
              value = -1;
              break;
            default:
              skip = !control.options || !control.options.static;
              break;
          }
          break;
        default:
          skip = true;
          break;
      }

      if (!skip && control.getFormControl().value !== value) {
        this.setFieldValue(control, value);
      }
    });

    this.afterView.next(this.updatedEntity);

    this.controls.filter(control => !control.transient && control.type === 'select').forEach(control => {
      switch (control.getFormControl().value) {
        case undefined:
          control.getFormControl().setValue(-2);
          break;
        case null:
          control.getFormControl().setValue(-1);
          break;
      }
    });
  }

  private _form(entityPath: string, entity: any): void {
    const formControls: Array<FormControl> = new Array();

    this.controls.forEach(control => {
      if (control.options && !control.options.static && control.options.value !== undefined) {
        control.options.values = new Array();
      }

      formControls[control.key] = new FormControl(entity !== undefined && entity !== null ?
        (entity[control.key] !== null &&
          (!control.options || !control.options.url) ? entity[control.key] : null) : null
                                                   /*,control.validators,
                                                      control.asyncValidators*/);
      control.setFormControl(formControls[control.key]);
    });

    this.controls.filter(control => control.disabled).forEach(control => {
      formControls[control.key].disable();
    });

    this.form = this.formBuilder.group(formControls);

    const _controls = this.controls.filter(control => control.options && control.options.value);

    if (_controls.length > 0) {
      _controls.filter(control => control.options.static).forEach(control => {
        this.setOptions(control, control.options.values);
      });

      _controls.filter(control => control.options.url).forEach(control => {
        if (!control.options.parent) {
          this.fillOptions(control, control.options.url, control.options.application);
        } else {
          formControls[control.options.parent].valueChanges.subscribe(value => {
            const _value = control.options.value && value ? value[control.options.value] : value;

            if (_value !== undefined && _value !== null && _value !== -2 && (typeof _value !== 'string' || _value.trim() !== '')) {
              let url = control.options.url.trim();

              if (url.indexOf('#parentValue') > -1) {
                if (_value !== null && _value !== undefined) {
                  url = url.replace('#parentValue', encodeURIComponent(_value));
                }
              } else {
                url += _value;
              }

              this.fillOptions(control, url, control.options.application);

              if (this.editing) {
                const _control = this.controls.find(c => c.options !== undefined && c.options !== null &&
                  c.options.label !== undefined && c.key === control.key);

                if (_control) {
                  this.setFieldValue(_control, undefined);

                  this.controls.filter(c => c.options !== undefined && c.options !== null && c.options.parent === _control.key)
                    .forEach(child => {
                      this.resetOptions(child);
                    });
                }
              }
            }
          });
        }
      });
    }

    this.controls.filter((control: DataControl) => control.onChange !== undefined && control.onChange !== null)
      .forEach((control: DataControl) => {
        formControls[control.key].valueChanges.subscribe((value: any) => {
          control.onChange(control, value);
        });
        formControls[control.key].updateValueAndValidity();
      });
  }

  fillOptions(dataControl: DataControl, url: string, application?: string) {
    if (this.editing && dataControl.options.parent !== undefined && dataControl.options.parent !== null) {
      if (this.controls.find(c => c.key === dataControl.options.parent).options.values.length === 0) {
        return;
      }
    }

    url = this.fixFillUrl(url, dataControl);

    this.httpService.wait();
    this.httpService.get(url.replace(/\./g, '/'), null, application !== undefined ? application : this.application)
      .map(options => options.value || options)
      .subscribe(options => this.setOptions(dataControl, options),
        error => this.httpService.handleError(error,
          () => this.fillOptions(dataControl, url, application),
          null,
          this.application),
        () => this.httpService.done());
  }

  setOptions(control: any, options: Array<any>): void {
    const key: string = control.key;
    const valueKey: any = control.options.value;

    if (this.editing) {
      this.controls.filter(c => c.options !== undefined && c.options !== null && c.options.parent === control.key).forEach(child => {
        child.options.values = [];
        // TODO: Refactoring to clean parent node
        const formControl = this.findFormControl(child.key);
        if (formControl) {
          formControl.updateValueAndValidity();
        }

        // TODO: REVIEW => this.resetOptions(child);

        // TODO: REVIEW
        /*let newKey = {};
        newKey[valueKey] = null;
        newKey[control.options.label] = '(Selecione)';
        child.options.values.unshift(newKey);*/
      });

      // TODO: REVIEW
      if (Util.isArray(Util.getFieldValue(this.entity, control.key))) {
        this.setFieldValue(control, new Array());
      } else if (control.defaultValue !== undefined) {
        this.setFieldValue(control, control.defaultValue);
      }
    }

    options.unshift({ nome: 'Nenhum(a)', id: 0 });
    control.options.values = options;

    // TODO: REVIEW
    /*let newKey = {};
    newKey[valueKey] = null;
    newKey[control.options.label] = '(Selecione)';
    options.unshift(newKey);*/

    const fieldValue = Util.getFieldValue(this.entity, control.key);
    if (fieldValue !== undefined && fieldValue !== null) {
      if (Util.isArray(fieldValue)) {
        const fieldValues = new Array();

        fieldValue.forEach(value => {
          const v = new Object();
          v[control.options.value] = value[control.options.value];
          fieldValues.push(v);
        });

        this.setFieldValue(control, fieldValues);
      } else {
        const v = new Object();
        v[control.options.value] = fieldValue[control.options.value];

        const value = v[control.options.value] !== undefined ? v : control.defaultValue;

        this.setFieldValue(control, value);
      }
    }

    if (control.required && options && options.length === 1) {
      const v = new Object();
      v[control.options.value] = options[0][control.options.value];
      this.setFieldValue(control, v[control.options.value] !== undefined ? v : undefined);

      if (this.editing) {
        control.getFormControl().markAsDirty();
      }
    }

    control.getFormControl().updateValueAndValidity();
  }

  resetOptions(control: DataTableColumn | DataControl) {
    if (this.form && this.form.controls && !this.viewing) {
      this.setFieldValue(control, null);
    }

    if (!control.options.values) {
      control.options.values = new Array();
    }
  }

  optionValue(control: DataControl, option: any, withLabel: boolean = false): any {
    if (control.type === 'select') {
      switch (option) {
        case undefined:
          return -2;
        case null:
          return -1;
      }
    }

    if (control.options && control.options.value) {
      if (control.options.static) {
        return option[control.options.value];
      } else {
        const v = new Object();
        v[control.options.value] = option[control.options.value];
        if (withLabel) {
          v[control.options.label] = option[control.options.label];
        }
        return v;
      }
    }

    return null;
  }

  fixFillUrl(url: string, dataControlOrColumn: DataControl | DataTableColumn) {
    if (url.indexOf('$select') === -1) {
      url += url.indexOf('?') === -1 ? '?' : '&';

      url += '$select=';
      if (dataControlOrColumn.options.value !== undefined && dataControlOrColumn.options.value !== null) {
        if (Util.isArray(dataControlOrColumn.options.value)) {
          let first = true;
          dataControlOrColumn.options.value.forEach((value: any) => {
            if (value.substr(0, 1) !== ' ') {
              if (!first) {
                url += ',';
              }
              first = false;
              url += value.replace(/\./g, '/');
            }
          });
        } else {
          url += dataControlOrColumn.options.value;
        }
      }

      if (dataControlOrColumn.options.label) {
        if (Util.isArray(dataControlOrColumn.options.label)) {
          let first = dataControlOrColumn.options.label === undefined || dataControlOrColumn.options.label === null;
          dataControlOrColumn.options.label.forEach((label: any) => {
            if (label.substr(0, 1) !== ' ') {
              if (!first) {
                url += ',';
              }
              first = false;
              url += label.replace(/\./g, '/');
            }
          });
        } else {
          url += ',' + dataControlOrColumn.options.label;
        }
      }

      if (url.indexOf('$orderby=') === -1) {
        url += '&$orderby=';

        if (dataControlOrColumn.options.label) {
          if (Util.isArray(dataControlOrColumn.options.label)) {
            let first = true;
            dataControlOrColumn.options.label.forEach((label: any) => {
              if (label.substr(0, 1) !== ' ') {
                if (!first) {
                  url += ',';
                }
                first = false;
                url += label.replace(/\./g, '/');
              }
            });
          } else {
            url += dataControlOrColumn.options.label;
          }
        }
      }
    }

    return url;
  }

  private toolbarUpdate() {
    this._toolbarUpdate();

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    this.formSubscription = this.form.valueChanges.subscribe(() => {
      if (this.configService.production) {
        this._toolbarUpdate();
      } else {
        setTimeout(() => this._toolbarUpdate(), 1);
      }
    });
  }
  private _toolbarUpdate() {
    const undoButton = this.toolbarService.buttons.find(button => button.key === 'undo');
    if (undoButton) {
      undoButton.disabled = !this.isNew && this.autoEdit && this.form.pristine;
    }

    const saveButton = this.toolbarService.buttons.find(button => button.key === 'save');
    if (saveButton) {
      saveButton.disabled = this.form.pristine || this.form.invalid;
    }
  }

  undo(): void {
    if (this.form.dirty && !confirm(this.undoConfirmText)) {
      return;
    }

    if (this.autoEdit) {
      this.back();
    } else {
      this.get(this.controls,
        this.entityPath,
        this.key,
        this.readonly);
    }
  }

  back(entityPath: string = this.entityPath): void {
    if (this.router.url.split(';')[0] === this.routerPath) {
      this.get(this.controls,
        entityPath + (this.filterParams ? this.filterParams : '') + (this.orderParams ? this.orderParams : ''),
        null,
        this.readonly);
    } else {
      this.editing = false;
      this.router.navigate([this.routerPath, { preserve: true }]);
    }
  }

  cancel() {
    if (this.form.dirty && !confirm(this.undoConfirmText)) {
      return;
    }

    this.back();
  }

  add(entityPath: string): void {
    this.viewing = true;
    this.editing = true;

    this.entity = new this.entityConstructor();

    this.beforeAdd.next(this.entity);

    this._form(entityPath, this.entity);

    this.toolbarService.clear();

    this.afterAdd.next(this.updatedEntity);
  }

  edit(entityPath: string, auto: boolean = false): void {
    this.beforeEdit.next(this.updatedEntity);

    this.viewing = true;
    this.editing = true;

    this.controls.forEach(control => {
      control.setOriginalValue(Util.clone(control.getFormControl().value));
    });

    this.toolbarService.clear();

    this.autoEdit = auto;

    this.afterEdit.next(this.updatedEntity);
  }

  preSave() {
    this.controls.filter(control => !control.transient &&
      !control.required &&
      control.type === 'select' &&
      control.options !== undefined)
      .forEach(control => {
        const value = control.getFormControl().value;

        if (typeof value === 'object' && value !== null) {
          if (value[control.options.value] === undefined ||
            value[control.options.value] === null ||
            value[control.options.value] === '0: null') {
            this.setFieldValue(control, null);
          }
        }
      });

    this.controls.filter(c => !c.transient &&
      (c.type === 'date' || c.type === 'date-local' || c.type === 'datetime' || c.type === 'datetime-local'))
      .forEach(control => {
        let date: Date = null;
        const value = control.getFormControl().value;

        if (value) {
          switch (control.type) {
            case 'date':
              date = this.utilService.timestampToUtcDate(this.utilService.parseDate(value, 'yyyy-MM-dd'));
              break;
            case 'datetime':
              date = this.utilService.timestampToUtcDate(this.utilService.parseDate(value, 'dd/MM/yyyy HH:mm'));
              break;

            case 'date-local':
              date = this.utilService.timestampToLocalDate(this.utilService.parseDate(value, 'dd/MM/yyyy'));
              break;
            case 'datetime-local':
              date = this.utilService.timestampToLocalDate(this.utilService.parseDate(value, 'yyyy-MM-ddTHH:mm'));
              break;
            /*case 'time':
              break;*/ // TODO: REVIEW
          }
        }

        this.setFieldValue(control, date);
      });


    this.controls.filter(control => !control.transient &&
      (control.type === 'money' || control.type === 'number' || control.type === 'numbers-only'))
      .forEach(control => {
        let value = control.getFormControl().value;
        if (value !== undefined && value !== null) {
          value = value.toString().replace(',', '.');
        }
        this.setFieldValue(control, value);
      });

    this.controls.filter(control => !control.transient && control.type === 'select').forEach(control => {
      switch (+control.getFormControl().value) {
        case -2:
          control.getFormControl().setValue(undefined);
          break;
        case -1:
          control.getFormControl().setValue(null);
          break;
      }
    });
  }

  get updatedEntity(): any {
    const entity = Util.clone(this.entity);

    if (this.form) {
      this.controls.forEach(control => {
        if (control.getFormControl().dirty && control.getFormControl().value !== control.originalValue) {
          Util.setFieldValue(entity, control.key, control.getFormControl().value);
        }
      });
    }

    return entity;
  }

  save(entityPath: string = this.entityPath, title: string = null): void {
    this.preSave();

    const data: any = this.updatedEntity;
    const dirties: Array<string> = new Array();

    this.controls.forEach(control => {
      if (control.getFormControl().dirty || control.getFormControl().value !== control.originalValue) {
        let k = control.key;

        if (dirties.indexOf(control.key) === -1) {
          dirties.push(control.key);
        }

        while (k.indexOf('.') > -1) {
          k = k.substr(0, k.lastIndexOf('.'));
          if (dirties.indexOf(k) === -1) {
            dirties.push(k);
          }
        }
      }
    });

    this.controls.filter(c => c.transient).forEach(c => {
      delete data[c.key];
    });

    if (this.key !== undefined && this.key !== null) {
      this.controls.forEach(control => {
        if (control.getFormControl().pristine || control.getFormControl().value === control.originalValue) {
          delete data[control.key];
        }
      });
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        if (!this.form.contains(key) && dirties.indexOf(key) === -1) {
          delete data[key];
        }
      }
    }

    this.beforeSave.next(data);

    this.controls.filter(control => !control.transient && control.type === 'select').forEach(control => {
      switch (control.getFormControl().value) {
        case undefined:
          control.getFormControl().setValue(-2);
          break;
        case null:
          control.getFormControl().setValue(-1);
          break;
      }
    });


    this.httpService.wait();
    this._save(entityPath,
      this.key,
      data,
      this.application)
      .subscribe(null,
        error => this.httpService.handleCrudError(title, this.controls, error,
          () => this.save(entityPath, title), null, this.application),
        () => this.postSave(entityPath));
  }
  _save(path: string, key: string | number, data: any, application?: string): Observable<any> {
    if (this.key === undefined || this.key === null) {
      return this.httpService.post(path, data, application);
    } else {
      return this.httpService.patch(path, key, data, application);
    }
  }

  postSave(entityPath?: string): void {
    this.editing = false;

    this.httpService.done();

    this.afterSave.next(this.updatedEntity);

    this.back(entityPath);
  }

  remove(entityPath: string = this.entityPath): void {
    if (confirm('Deseja excluir o registro atual?')) {
      this._remove(entityPath);
    }
  }

  export(entityPath: string): void {
    if (confirm('Deseja exportar o relatório atual?')) {
      // this._remove(entityPath);
    }
  }

  _remove(entityPath: string): void {
    this.beforeRemove.next(this.entityPath);

    this.httpService.wait();

    this.httpService.delete(entityPath, this.key, this.application)
      .subscribe(data => this.back(entityPath),
        error => this.httpService.handleError(error, () => this._remove(entityPath), null, this.application),
        () => this.postRemove());
  }

  private postRemove(): void {
    this.httpService.done();

    this.afterRemove.next(this.entityPath);
  }

  findControl(key: string): DataControl {
    return this.controls ? this.controls.find(control => control.key === key) : undefined;
  }
  findColumn(key: string): DataTableColumn {
    return this.columns ? this.columns.find(column => column.key === key) : undefined;
  }
  findFormControl(key: string): FormControl {
    return this.form ? <FormControl>this.form.get(key) : undefined;
  }

  sort(key: string, readonly: boolean) { // TODO: Review trim, case and types
    let orderParam: string = String('');
    this.orderParams = null;

    const columns = this.columns.slice();
    /*columns.sort((a: DataTableColumn, b: DataTableColumn) => {
      if (a.sortIndex === b.sortIndex) {
        return 0;
      } else if (a.sortIndex > b.sortIndex) {
        return 1;
      } else {
        return -1;
      }
    });*/

    columns.filter(column => column.orderClass !== undefined && column.orderClass !== null)
      .forEach(column => {
        orderParam += (orderParam !== '' ? ',' : '') + column.key.replace(/\./g, '/') + ' ' + column.orderClass;
      });

    if (this.entityPath.indexOf('?') > -1) {
      const params: string[] = this.entityPath.substr(this.entityPath.indexOf('?') + 1).split('&');

      this.entityPath = this.entityPath.substr(0, this.entityPath.indexOf('?') + 1);

      let first = true;
      params.filter(param => param.trim().indexOf('$orderby=') === -1 &&
        param.trim().indexOf('$filter=') === -1 &&
        param.trim().indexOf('$skip=') === -1)
        .forEach(param => {
          this.entityPath += (first ? '' : '&') + param;
          first = false;
        });
    }

    if (orderParam.trim() !== '') {
      this.orderParams = '&$orderby=' + orderParam;
    }

    this.get(this.controls,
      this.entityPath + (this.filterParams ? this.filterParams : '') + (this.orderParams ? this.orderParams : ''),
      null,
      readonly);
  }

  filter(readonly: boolean, immediate?: boolean) {
    if (immediate) {
      this._filter(readonly);
    } else {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this._filter(readonly), this.configService.filterTimeout);
    }
  }
  _filter(readonly: boolean) { // TODO: Review trim, case and types
    this.buildFilters();

    this.get(this.controls,
      this.entityPath + (this.filterParams ? this.filterParams : '') + (this.orderParams ? this.orderParams : ''),
      null,
      readonly);
  }

  buildFilters() {
    let filterParam: string = String('');
    let andPrefix: string = String('');

    this.filterParams = '';

    this.columns.forEach(column => {
      if (column.filter !== undefined &&
        (column.filter !== null || column.type === 'select')) {
        let _value: any = column.filter !== undefined && column.filter !== null ? column.filter.toString().trim().toLowerCase() : null;
        let skip = false;

        switch (column.type) {
          case 'checkbox':
          case 'select':
            let operator = ' eq ';

            if (_value !== undefined && _value !== null && _value.trim() !== '' && isNaN(parseInt(_value, 10))) {
              operator = ' like ';
              _value = '\'' + _value.trim() + '\'';
            }

            if (column.options && (_value === null || (column.type === 'select' && +_value === -1))) {
              filterParam += andPrefix + column.key.replace(/\./g, '/') + ' is null';
            } else if (_value !== undefined && +_value !== -2) {
              filterParam += andPrefix + column.key.replace(/\./g, '/') +
                (column.options && !column.options.static && column.options.value ? '/' + column.options.value : '') +
                operator + _value;
            }

            break;

          case 'money':
          case 'number':
            if (_value !== null && _value !== undefined) {
              _value = parseFloat(_value.replace(/\,/g, '.').replace(/[^0-9.]/g, ''));

              if (!isNaN(_value)) {
                filterParam += andPrefix;

                if (column.filterContains) {
                  filterParam += 'contains(' + column.key.replace(/\./g, '/') + ',\'' + encodeURIComponent(_value) + '\')';
                } else if (column.filterStartsWith) {
                  filterParam += 'startswith(' + column.key.replace(/\./g, '/') + ',\'' + encodeURIComponent(_value) + '\')';
                } else if (column.filterEndsWith) {
                  filterParam += 'endswith(' + column.key.replace(/\./g, '/') + ',\'' + encodeURIComponent(_value) + '\')';
                }
              }
            } else {
              skip = true;
            }

            break;

          // TODO: Review timezones
          case 'date':
          case 'date-local':
          case 'datetime':
          case 'datetime-local':
            _value = _value.trim();
            while (_value.substr(_value.length - 1) === '/') {
              _value = _value.substr(0, _value.length - 1);
            }
            while (_value.substr(0, 1) === '/') {
              _value = _value.substr(1);
            }
            _value = _value.trim();

            const date = _value.split('/');

            if (_value.indexOf('/') !== -1) {
              if (_value.length >= 8 && date.length === 3) {
                const hour = date[2].trim().split(' ');
                date[2] = hour[0];
                _value = date[2] + '-' + ('0' + date[1]).substr(-2) + '-' + ('0' + date[0]).substr(-2) +
                  (hour[1] !== undefined && hour[1] !== null && hour[1].trim() !== '' ? ' ' + hour[1] : '');
              } else if (_value.length >= 6 && date.length === 2) {
                _value = date[1] + '-' + ('0' + date[0]).substr(-2) + '-';
              } else if (_value.length >= 3 && date.length === 2) {
                _value = '-' + ('0' + date[1]).substr(-2) + '-' + ('0' + date[0]).substr(-2);
              }
            }

            if (_value.trim() !== '') {
              filterParam += andPrefix + column.key.replace(/\./g, '/') + ' like \'%25' + encodeURIComponent(_value) + '%25\'';
            }
            break;

          /*case 'time':
            break;*/ // TODO: REVIEW

          default:
            if (_value !== undefined && _value !== null && _value.trim() !== '') {
              filterParam += andPrefix;

              if (column.filterContains) {
                filterParam += 'contains(' + column.key.replace(/\./g, '/') + ',\'' + encodeURIComponent(_value) + '\')';
              } else if (column.filterStartsWith) {
                filterParam += 'startswith(' + column.key.replace(/\./g, '/') + ',\'' + encodeURIComponent(_value) + '\')';
              } else if (column.filterEndsWith) {
                filterParam += 'endsWith(' + column.key.replace(/\./g, '/') + ',\'' + encodeURIComponent(_value) + '\')';
              }
            } else {
              skip = true;
            }
        }

        if (!skip) {
          andPrefix = ' and ';
        }
      }
    });

    if (this.entityPath.indexOf('?') > -1) {
      const params: string[] = this.entityPath.substr(this.entityPath.indexOf('?') + 1).split('&');

      this.entityPath = this.entityPath.substr(0, this.entityPath.indexOf('?') + 1);

      let first = true;
      params.filter(param => param.trim().indexOf('$orderby=') === -1 &&
        param.trim().indexOf('$filter=') === -1 &&
        param.trim().indexOf('$skip=') === -1)
        .forEach(param => {
          this.entityPath += (first ? '' : '&') + param;
          first = false;
        });
    }

    if (filterParam.trim() !== '') {
      this.filterParams = filterParam + (this.filterParams !== '' ? ' and ' : '') + this.filterParams;

      let uniqueParams = '';
      this.filterParams.split(' and ').forEach(param => {
        if (param.trim().lastIndexOf(' ') === -1 ||
          uniqueParams.indexOf(param.trim().substr(0, param.trim().lastIndexOf(' '))) === -1) {
          uniqueParams += (uniqueParams !== '' ? ' and ' : '') + param.trim();
        }
      });
      if (uniqueParams === '') {
        uniqueParams = this.filterParams;
      }

      this.filterParams = '&$filter=' + uniqueParams;
    }
  }

  get toolbar(): ToolbarService {
    return this.toolbarService;
  }

  private setFieldValue(control: DataControl | DataTableColumn, value: any) {
    if (control.getFormControl()) {
      control.getFormControl().setValue(this.fixSelectValue(control, value));
    }
  }

  private fixSelectValue(control: DataControl | DataTableColumn, value: any) {
    if (control.type === 'select') {
      switch (value) {
        case undefined:
          return -2;
        case null:
          return -1;
      }
    }

    return value;
  }

  get editing(): boolean {
    return this.sessionService.editing;
  }
  set editing(editing: boolean) {
    this.sessionService.editing = editing;
  }

  get isNew(): boolean {
    return this.editing && this.router.url.split(';')[0] === this.routerPath;
  }
}
