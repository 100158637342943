import { Participante } from 'app/cadastro/model/participante.model';
import { EnderecoTipo } from 'app/util/model/endereco-tipo.model';
import { Endereco } from 'app/cadastro/model/endereco.model';

export class ParticipanteEndereco {
  id: number;
  endereco: Endereco;
  enderecoTipo: EnderecoTipo;
  principal: boolean;
  dtInicial: Date;
  dtFinal: Date;
  participante: Participante;

  constructor(participanteEndereco?: ParticipanteEndereco | number) {
    if (participanteEndereco !== undefined && participanteEndereco !== null) {
      if (typeof participanteEndereco === 'number') {
        this.id = participanteEndereco;
      } else {
        this.id = participanteEndereco.id;
        this.endereco = participanteEndereco.endereco;
        this.enderecoTipo = participanteEndereco.enderecoTipo;
        this.principal = participanteEndereco.principal;
        this.dtInicial = participanteEndereco.dtInicial;
        this.dtFinal = participanteEndereco.dtFinal;
        //this.participante = participanteEndereco.participante;
      }
    }
  }
}
