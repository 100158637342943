import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { UtilService } from 'app/shared/services/util.service';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {
  private decimalPipe = new DecimalPipe('pt');

  constructor(private utilService: UtilService) {
  }

  transform(value: any): string {
    value = String(value)
    if (value !== undefined && value !== null) {
      return this.decimalPipe.transform(this.utilService.parseMoney(value), '1.2-2');
    }
    return null;
  }
}
