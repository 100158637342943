import { isNullOrUndefined } from "util";


export class Consultor{

  id                : number;
  nome              : string;
  email             : string;
  fone              : string;



  constructor(consultor?: Consultor | number) {
    if (!isNullOrUndefined(consultor)) {
      if (typeof consultor === 'number') {
        this.id = consultor;
      } else {
        this.id             = consultor.id;
        this.nome           = consultor.nome;
        this.email          = consultor.email;
        this.fone           = consultor.fone;

      }
    }
  }

}