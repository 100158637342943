import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { trigger, state, style, animate, transition } from '@angular/animations';
@Component({
  selector: 'app-anexo',
  templateUrl: './anexo.component.html',
  styleUrls: ['./anexo.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class AnexoComponent implements OnInit {

  @Input() DragDrop: boolean;
  @Input() files: Array<any> = new Array();
  @Input() acceptFiles: String
  @Input() multiple: boolean = true;

  @Output() filesChange = new EventEmitter<Array<any>>()

  enableInput: boolean;

  fileEmpty: boolean = false

  labelButton: any

  dot = ' '

  constructor() { }

  ngOnInit(): void {
    this.files = new Array();
    this.labelButton = 'Selecionar Arquivo'
  }

  openAnexoSelect() {
    this.enableInput = true;
    setTimeout(() => document.getElementById('file-upload').click(), 500);
  }

  onUpload(event) {
    this.files = event['files'];
    this.filesChange.emit(this.files)

    if(this.DragDrop === false){ 
    if(this.files.length !== null || undefined){
      this.fileEmpty = true
      this.labelButton = `Arquivos Selecionados (${this.files.length})`
    }
      if (this.files.length === 2) {
        this.dot = ','
      }
    }
  }

  onRemove(event) {
    this.files = this.files.filter(line => line !== event.file)
    this.filesChange.emit(this.files)

    if(this.DragDrop === false){ 
      this.labelButton = `Arquivos Selecionados (${this.files.length})`
      if (this.files.length === 0) {
        this.fileEmpty = false
        this.labelButton = 'Selecionar Arquivo'
      }
    }
  }

  removeFile(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file)
    uploader.remove(null, index)
  }
}
