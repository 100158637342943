import { Injectable, Optional } from '@angular/core';

import { ConfigService } from './config.service';
import { NavigationMenu } from 'app/shared/model/navigation-menu.model';
import { MenuConfig } from 'app/app.menu';

@Injectable()
export class NavigationMenuService {
  // TODO: Use router history (?)
  private _history: Array<NavigationMenu> = new Array();
  private _activeMenu: NavigationMenu = null;

  // private navigationTimeout: any; // TODO: Move to here

  private found = false;

  private menuConfig: MenuConfig;

  constructor(private configService: ConfigService) {
    this.menuConfig = new MenuConfig(configService);

    this.menuConfig.menus.forEach((menu: NavigationMenu) => {
      if (menu.permissions && menu.permissions.length > 0) {
        menu.permissions.forEach(permission => {
          if (permission.url && !permission.application) {
            permission.application = configService.defaultApplication;
          }
        });
      }
    });
  }

  get menus(): Array<NavigationMenu> {
    const _menus = (this.currentMenu ? this.currentMenu.menus : this.menuConfig.menus);

    if (this.configService.isTestEnv == true){
      if (_menus) {
        return _menus.filter((menu: NavigationMenu) => menu.active !== false);
      }
    }
    if (this.configService.isTestEnv == false){
      if (_menus) {
        return _menus.filter((menu: NavigationMenu) => menu.active !== false && menu.test !== true);
      }
    }


    return null;
  }

  get previousMenu(): NavigationMenu {
    if (this._history.length > 1) {
      return this._history[this._history.length - 2];
    }

    return null;
  }

  get currentMenu(): NavigationMenu {
    if (this._history.length > 0) {
      return this._history[this._history.length - 1];
    }

    return null;
  }

  get activeMenu(): NavigationMenu {
    return this._activeMenu;
  }
  set activeMenu(menu: NavigationMenu) {
    this._activeMenu = menu;
  }

  get displayBack(): boolean {
    return this.currentMenu !== undefined && this.currentMenu !== null;
  }

  menuBack(): void {
    this._history.pop();
  }

  menuCallback(menu: NavigationMenu): void {
    if (menu.menus) {
      this._history.push(menu);

      if (menu.link) {
        this.activeMenu = menu;
      }
    }
  }

  private setParents(menu: NavigationMenu): void {
    if (menu.menus) {
      for (const m in menu.menus) {
        if (menu.menus.hasOwnProperty(m)) {
          menu.menus[m].parent = menu;

          this.setParents (menu.menus[m]);
        }
      }
    }
  }

  initMenu(url: string) {
    this.menus.forEach((menu: NavigationMenu) => this.setParents(menu));

    while (!this.found && url.indexOf('/') > -1) {
      this.menus.forEach((menu: NavigationMenu) => this.setMenus(menu, url));
      url = url.substr(0, url.lastIndexOf('/'));
    }
  }

  private setMenus(menu: NavigationMenu, url: string): void {
    if (menu.link && menu.link.indexOf(url) > -1) {
      this.found = true;

      let m: NavigationMenu = menu;
      const trail: Array<NavigationMenu> = new Array();

      while (m.parent) {
        trail.push(m.parent);

        m = m.parent;
      }

      trail.reverse().forEach(_menu => this.menuCallback(_menu));

      return;
    }

    if (menu.menus) {
      for (const m in menu.menus) {
        if (menu.menus.hasOwnProperty(m)) {
          this.setMenus (menu.menus[m], url);

          if (this.found) {
            break;
          }
        }
      }
    }
  }

  findMenu(menu: NavigationMenu, parent?: NavigationMenu[]) {
    let found: NavigationMenu = null;

    if (!parent) {
      parent = this.menus;
    }

    for (let i = 0; i < parent.length; i++) {
      const m = parent[i];

      if (m.link === menu.link) {
        found = m;
      } else if (m.menus) { // TODO : REVIEW (else)
        for (let j = 0; j < m.menus.length; j++) {
          const n = this.findMenu(menu, m.menus);

          if (n) {
            found = n;
          }
        }
      }
    }

    if (found) {
      let m: NavigationMenu = found;
      const trail: Array<NavigationMenu> = new Array();

      while (m.parent) {
        trail.push(m.parent);

        m = m.parent;
      }

      this._history = new Array();

      trail.reverse().forEach(_menu => { this.menuCallback(_menu); });
    }

    return found;
  }

  get allMenus() {
    return this.menuConfig.menus;
  }
}
