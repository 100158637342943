import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Moment } from 'moment';
import { SessionService } from './session.service';
import { HttpService } from './http.service';
import { LoginService } from './login.service';
import { UtilService } from './util.service';
import { ConfigService } from './config.service';
import { CnpjPipe } from '../pipes/cnpj.pipe';
import { Usuario } from '../../cadastro/model/usuario.model';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Period, Year, Month } from 'app/shared/components/period-select/period.model';

@Injectable()
export class DblinkedSessionService {
  usuario: Usuario = new Usuario();

  empresas: Array<Empresa> = new Array();
  filiais: Array<Empresa> = new Array();
  filiaisSelecionadas: Array<number> = [];

  empresa: Empresa = null;
  displayEmpresa = true;
  disableEmpresa = false;
  displayFiliais = false;
  // TODO: Move to component/service
  _periods: Array<Period> = new Array();
  periodos: Array<any> = new Array();
  periodo: Period = new Period(); // https://github.com/primefaces/primeng/issues/1937
  private currentPeriod: Period = this.periodo;
  displayPeriodo = false;
  displayAllPeriodos = false;
  displayMonths = true;
  nextMonths = 0;
  displayCurrentYear = false;
  months = 12;
  years = 5;
  separator = ' de ';
  periodId: string = null; // https://github.com/primefaces/primeng/issues/1937

  sessionLoaded: Subject<any> = new Subject();

  empresaChanged: Subject<Empresa> = new Subject();
  periodoChanged: Subject<Period | any> = new Subject();
  sessionChanged: Subject<any> = new Subject();

  private cnpjPipe: CnpjPipe = new CnpjPipe();

  constructor(private sessionService: SessionService,
              private httpService: HttpService,
              private loginService: LoginService,
              private utilService: UtilService,
              private configService: ConfigService) {
    let fromLocalStorage = true;

    this.empresaChanged.subscribe((empresa: Empresa) => {
      this.empresa = empresa;
      this.filiais = new Array();
      console.log(empresa);



      if (empresa) {
        localStorage.setItem('empresa', JSON.stringify(empresa));

        this.refreshPeriods(fromLocalStorage);
        fromLocalStorage = false;

        empresa.filiais.forEach(element => {
          this.filiais.push(element);

        });
      }

      this.sessionChanged.next();
    });

    this.periodoChanged.subscribe((periodo: Period | any) => {
      if (periodo instanceof Period) {
        this.periodo = periodo;
      } else if (periodo) {
        this.periodo = this._periods.find(p => p.id === (typeof periodo === 'object' ? periodo.id : periodo));
      } else {
        this.periodo = null;
      }

      this.currentPeriod = this.periodo;

      this.periodId = this.periodo && this.periodo.id ? this.periodo.id : null;

      if (this.periodo && this.periodo.id) {
        localStorage.setItem('periodo', JSON.stringify(this.periodo));
      }

      this.sessionChanged.next();
    });

    this.httpService.authenticate.subscribe(authenticated => {
      if (authenticated && this.sessionService.loggedUser && this.sessionService.loggedUser.id) {
        this.loadEmpresas();

        this.httpService.connected.subscribe(connected => {
          if (connected && this.empresas.length === 0) {
            this.loadEmpresas();
          }
        });
      } else {
        this.usuario = null;
        this.empresas = new Array();
        this.empresaChanged.next(null);
      }

      this.sessionChanged.next();
    });
  }

  loadEmpresas() {
    this.httpService.wait();

    // modificacao para novas permissoes @octavio
    this.httpService.get(`/custom/cadastro/get-usuario-empresas?auth_user_id=${this.sessionService.loggedUser.id}`)
                    .subscribe(usuarios => this.usuarioLoaded(usuarios.value),
                               error => this.httpService.handleError(error, () => this.loadEmpresas()),
                               () => this.httpService.done());
  }

  usuarioLoaded(usuarios: Array<Usuario>) {
    this.usuario = usuarios && usuarios.length === 1 ? new Usuario(usuarios[0]) : null;

    this.empresas = new Array();

    this.empresaChanged.next(null);

    if (this.usuario && this.usuario.empresas) {
      this.usuario.empresas.forEach(empresa => {

        if (!this.empresas.find(e => e.id === empresa.id)) {
          this.empresas.push(empresa);
        }
      });

      this.empresas.sort((empresa1, empresa2) => {
        if (empresa1.nomeFantasia > empresa2.nomeFantasia) {
          return 1;
        } else if (empresa1.nomeFantasia < empresa2.nomeFantasia) {
          return -1;
        }
        return 0;
      });

      if (this.usuario.empresas.length === 1) {
        if (this.empresa !== this.usuario.empresas[0]) {
          this.empresaChanged.next(this.usuario.empresas[0]);
        }
      } else {
        const data = localStorage.getItem('empresa');
        if (data) {
          const empresa: Empresa = this.empresas.find(e => e.id === (new Empresa(JSON.parse(data))).id);
          if (empresa && this.empresa !== empresa) {
            this.empresaChanged.next(empresa);
          }
        }
      }
    }

    this.sessionLoaded.next();
  }

  cnpj(empresa?: Empresa | string) {
    return this.cnpjPipe.transform(typeof empresa === 'string' ? empresa : empresa.cnpj);
  }

  showPeriods(displayAllPeriodos: boolean = false,
              displayCurrentYear: boolean = false,
              displayMonths: boolean = true,
              months: number = 12,
              years: number = 5,
              separator: string = ' de ') {
    this._showPeriods(displayAllPeriodos, displayCurrentYear, displayMonths, months, null, years, separator);
  }

  showPeriodsWithNextMonths(displayAllPeriodos: boolean = false,
                            displayCurrentYear: boolean = false,
                            months: number = 12,
                            nextMonths: number = 6,
                            years: number = 5,
                            separator: string = ' de ') {
    this._showPeriods(displayAllPeriodos, displayCurrentYear, true, months, nextMonths, years, separator);
  }

  _showPeriods(displayAllPeriodos: boolean = false,
               displayCurrentYear: boolean = false,
               displayMonths: boolean = true,
               months: number = 12,
               nextMonths: number = 0,
               years: number = 5,
               separator: string = ' de ') {
    this.displayAllPeriodos = displayAllPeriodos;
    this.displayCurrentYear = displayCurrentYear;
    this.displayMonths = displayMonths;
    this.nextMonths = nextMonths;
    this.months = months;
    this.years = years;
    this.separator = separator;

    if (this.configService.production) {
      this.displayPeriodo = true;
      this.refreshPeriods(true);
    } else {
      setTimeout(() => {
        this.displayPeriodo = true;
        this.refreshPeriods(true);
      }, 1);
    }

    if (this.periodo !== this.currentPeriod) {
      this.periodoChanged.next(this.periodo);
    }
  }

  hidePeriods() {
    if (this.configService.production) {
      this.displayPeriodo = false;
    } else {
      setTimeout(() => { this.displayPeriodo = false; }, 1);
    }
  }

  refreshPeriods(fromLocalStorage: boolean = false) {
    this._periods = new Array();

    if (this.displayAllPeriodos) {
      this._periods.push(new Period()); // https://github.com/primefaces/primeng/issues/1937
    }

    const moment: Moment = this.utilService.moment();
    let year: number = moment.year();

    for (let i = 12; i > 0; i--) {
      this._periods.push(new Period(
        new Year(2025, '2025'),
        new Month(i)
      ));
    }

    for (let i = 12; i > 0; i--) {
      this._periods.push(new Period(
        new Year(2024, '2024'),
        new Month(i)
      ));
    }

    for (let i = 12; i > 0; i--) {
      this._periods.push(new Period(
        new Year(2023, '2023'),
        new Month(i)
      ));
    }

    for (let i = 12; i > 0; i--) {
      this._periods.push(new Period(
        new Year(2022, '2022'),
        new Month(i)
      ));
    }


    for (let i = 12; i > 0; i--) {
      this._periods.push(new Period(
        new Year(2021, '2021'),
        new Month(i)
      ));
    }

    for (let i = 12; i > 0; i--) {
      this._periods.push(new Period(
        new Year(2020, '2020'),
        new Month(i)
      ));
    }

    for (let i = 12; i > 0; i--) {
      this._periods.push(new Period(
        new Year(2019, '2019'),
        new Month(i)
      ));
    }

    for (let i = 12; i > 0; i--) {
      this._periods.push(new Period(
        new Year(2018, '2018'),
        new Month(i)
      ));
    }

    for (let i = 12; i > 0; i--) {
      this._periods.push(new Period(
        new Year(2017, '2017'),
        new Month(i)
      ));
    }

    this.periodos = new Array();
    this._periods.forEach(periodo => {
      let label = 'Todos';
      if (this.displayMonths) {
        if (periodo && periodo.month) {
          label = periodo.month.label + (periodo.year.label ? this.separator + periodo.year.label : '');
        }
      } else {
        if (periodo && periodo.year) {
          label = periodo.year.label;
        }
      }

      this.periodos.push({
        label: label,
        value: periodo.id
      });
    });


    if (fromLocalStorage) {
      const data = localStorage.getItem('periodo');

      try {
        this.periodo = JSON.parse(data) || new Period(); // https://github.com/primefaces/primeng/issues/1937
      } catch (exception) {
        this.periodo = new Period(); // https://github.com/primefaces/primeng/issues/1937
      }

      this.periodId = this.periodo.id;
    }
  }

  get hasEmpresaSelected(): boolean {
    return this.empresa && this.empresa.id > 0;
  }

  get hasPeriodoSelected(): boolean {
    // https://github.com/primefaces/primeng/issues/1937
    return this.periodo &&
           ((this.periodo.year && this.periodo.year.value !== undefined) ||
            (this.periodo.month && this.periodo.month.value !== undefined));
  }

  get hasYearSelected(): boolean {
    return this.periodo &&
           (this.periodo.year && this.periodo.year.value !== undefined);
  }

  get hasMonthSelected(): boolean {
    return this.periodo &&
           (this.periodo.month && this.periodo.month.value !== undefined);
  }
}
