import { TbIrpjN620 } from 'app/ecf/model/tb-irpj-n620.model';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';
import { TbIndRelacao } from 'app/ecf/model/tb-ind-relacao.model';

export class MovParteADeducoes {
  id: number;
  data: Date;
  hist: string;
  valor: number;
  tbIrpjN620: TbIrpjN620;
  planoContas: PlanoContas;
  cadCcus: CadCcus;
  tbIndRelacao: TbIndRelacao;
  cadEcd: CadEcd;

  constructor(movParteADeducoes?: MovParteADeducoes | number) {
    if (movParteADeducoes !== undefined && movParteADeducoes !== null) {
      if (typeof movParteADeducoes === 'number') {
        this.id = movParteADeducoes;
      } else {
        this.id = movParteADeducoes.id;
        this.data = movParteADeducoes.data ? new Date(movParteADeducoes.data) : null;
        this.hist = movParteADeducoes.hist;
        this.valor = movParteADeducoes.valor;
        this.tbIrpjN620 = movParteADeducoes.tbIrpjN620 || new TbIrpjN620();
        this.planoContas = movParteADeducoes.planoContas || new PlanoContas();
        this.tbIndRelacao = movParteADeducoes.tbIndRelacao || new TbIndRelacao();
        this.cadEcd = movParteADeducoes.cadEcd || new CadEcd();


      }
    }
  }
}
