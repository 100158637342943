import { CadEcd } from './cad-ecd.model';
import { FormaEscritContabil} from '../../util/model/forma-escrit-contabil.model';

export class MovTermoAe {
  id: number;
  dnrcAbert: string;
  numOrd: string;
  natLivr: string;
  qtdLin: string;
  nire: string;
  dtArq: Date;
  dtArqConv: Date;
  dtExSocial: Date;
  nomeAuditor: string;
  codCvmAuditor: string;
  cadEcd: CadEcd = new CadEcd();
  dnrcEncer: string;

  constructor(movtermoae?: MovTermoAe | number) {
    if (movtermoae !== undefined && movtermoae !== null) {
      if (typeof movtermoae === 'number') {
        this.id = movtermoae;
      } else {
        this.id = movtermoae.id;
        this.dnrcAbert = movtermoae.dnrcAbert;
        this.numOrd = movtermoae.numOrd;
        this.natLivr = movtermoae.natLivr;
        this.qtdLin = movtermoae.qtdLin;
        this.nire = movtermoae.nire;
        this.dtArq = movtermoae.dtArq;
        this.dtArqConv = movtermoae.dtArqConv;
        this.dtExSocial = movtermoae.dtExSocial;
        this.nomeAuditor = movtermoae.nomeAuditor;
        this.codCvmAuditor = movtermoae.codCvmAuditor;
        this.cadEcd = movtermoae.cadEcd || new CadEcd();
        this.dnrcEncer = movtermoae.dnrcEncer;
      }
    }
  }
}
