import { Component, OnInit } from '@angular/core';
import { CadAglut } from '../model/cad-aglut.model';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'app-cad-aglut',
  templateUrl: './cad-aglut.component.html',
  styleUrls: ['./cad-aglut.component.scss'],
  providers: [CrudService]
})
export class CadAglutComponent extends DataTableBasicComponent implements OnInit {
  public cadAglut: CadAglut = new CadAglut();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('Código', 'cod'),
      new DataTableColumn('Descrição', 'descricao'),
      new DataTableColumn('Empresa', 'empresa')
        .setTypeSelect()
        .setOptions({ url: '/empresa', value: 'id', label: 'razaoSocial', prompt: 'Todas' })
    ];

    this.controls = [
      new DataControl('Código', 'cod')
        .setRequired()
        .setMinLength(2)
        .setMaxLength(45),
      new DataControl('Descrição', 'descricao')
        .setRequired()
        .setMinLength(5)
        .setMaxLength(255),
        new DataControl('Empresa', 'empresa')
        .setTypeSelect()
        .setOptions({ url: '/empresa', value: 'id', label: 'razaoSocial' })

    ];
  }
}
