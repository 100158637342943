import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Message, MessageService } from 'primeng/api';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common'
import { element } from 'protractor';


@Component({
  selector: 'app-transferir-participante-evento',
  templateUrl: './transferir-participante-evento.component.html',
  styleUrls: ['./transferir-participante-evento.component.scss'],
})
export class TransferirParticipanteEventoComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  pt  : any;
  msgs: Message[] = [];

  titlePrincipal: string;
  subtitle: string;

  carteiras             : SelectItem[];
  carteiraNewConsultor  : SelectItem[];

  public dataIni                  : any = null;
  public dataFin                  : any = null;
  public dados                    : any = null;
  eventoIdOld                        : number = 0;
  eventoIdNew                        : number = 0;
  opcoesComboEventoOld               : SelectItem[] = [];
  opcoesComboEventoNew               : SelectItem[] = [];

  public listaParticipantesOld      : Array<any> = new Array();
  public listaParticipantesNew      : Array<any> = new Array();
  public listaParticipantesNewIds   : Array<any> = new Array();
  public listaIngressoValidacao     : Array<any> = new Array();

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private dblinkedSessionService: DblinkedSessionService,
    private datePipe: DatePipe,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    // requisição

    this.titlePrincipal = 'CONFIGURAÇÕES';
    this.subtitle = 'TRANSFERIR PARTICIPANTES EVENTOS';
    this.titleService.title = '';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);


    this.dataIni      = null;
    this.dataFin      = null;


    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };


    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });
    }

  ngOnDestroy() {
    // this.toolbarService.clear();
    // this.toolbarService.hidden = false;
    // this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  data() {
    // this.dtCorte = new Date(this.timezonePipe.transform(this.dtCorte));
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

        this.opcoesComboEventoOld  = [];
        this.listaParticipantesOld = new Array();
        this.opcoesComboEventoNew  = [];
        this.listaParticipantesNew = new Array();
        this.eventoIdNew = 0;
        this.eventoIdOld = 0;
        this.buscarComboEventosOld();

      // renderizando a toolbar
      setTimeout(() => {
        this.toolbarEdit();
      }, 500);
    }
  }

  onFilterRms(event: any) {
    console.log('event: ' + event);
  }

  export() {
    // alert('export');
  }

  pesquisa() {
    // alert('pesquisa');
  }


 hideMessage() {

  }

  private toolbarEdit() {
    const todos = this.buttons.slice();
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('undo');
    //this.toolbarService.add('undo', true, 'class-edit-button', 'sync_alt', 'Transferir evento');

    // this.toolbarService.alterarStatus('pesquisa', true, 'Detalhes Rateio', () => this.pesquisa());
    // this.toolbarService.alterarStatus('download', true, 'Download Anexos', () => this.export());
    // this.toolbarService.alterarStatus('voltar', true, 'Voltar', () => this.ngOnInit());
    // this.toolbarService.alterarStatus('atualizar', true, 'Atualizar RMS', () => this.change());
    // this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.ngOnInit());
    // this.toolbarService.alterarStatus('save', true, 'Salvar requisição', () => this.ngOnInit());
    //this.toolbarService.alterarStatus('edit', true, 'Editar requisição', () => this.ngOnInit());

    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar ', () => this.salvarRelacionamento());

    //this.toolbarService.alterarStatus('undo', false, 'Transferir evento ficha', () => this.transferirEvento());

  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  salvarRelacionamento(){

    if (this.validacao()) {
      return;
    } else{

    }

    console.log(this.listaIngressoValidacao);
    if (confirm('Deseja realmente salvar este relacionamento?')) {


      const listaEnviar = {
        eventoIdOld                     : this.eventoIdOld,
        eventoIdNew                     : this.eventoIdNew,
        listaParticipantesOld           : this.listaParticipantesOld,
        listaParticipantesNew           : this.listaParticipantesNew,
        usuarioId                       : this.sessionService.loggedUser.id
      };


      this.httpService.wait();
      this.httpService.post('/custom/polozi/salvar-transferencia-participantes', listaEnviar)
        .subscribe(result => {
          let existeErro = false;
          result.forEach(element => {
            if(element.error){
              existeErro = true;
              this.messageService.add({
                severity: element.severity,
                summary: 'MSG: ',
                detail: element.message
              });
            }
          });
          if(!existeErro){
            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'Alteração salva com sucesso!'
            });
          }

          this.change();


        },
          error => this.httpService.handleError(error, () => this.salvarRelacionamento()),
          () => this.httpService.done());


    }

        // this.carteiraId = 0;

        // this.change();





  }

  validacao(){



    if (isNullOrUndefined(this.empresa.id) || this.empresa.id === 0 ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma empresa'
      });
      return true;
    }

    if (isNullOrUndefined(this.eventoIdOld) || this.eventoIdOld === 0 ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um evento antigo!'
      });
      return true;
    }


    if (isNullOrUndefined(this.eventoIdNew) || this.eventoIdNew === 0 ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um evento Novo!'
      });
      return true;
    }


    if (!isNullOrUndefined(this.listaIngressoValidacao) && this.listaIngressoValidacao.length > 0) {
      var isPodeGravar : boolean = true;
      var mensagem  = '';
      this.listaIngressoValidacao.forEach(element=>{

        if ( element.eventoIdNew === 0) {
          console.log('aqui');
          isPodeGravar = false;
          //&#013

        }
      });

      if (isPodeGravar !== true) {
          mensagem = 'FAVOR VERIFICAR A ESTRUTURA DE INGRESSO DOS EVENTOS.  PARA O EVENTOS OLD TEMOS: '
          this.listaIngressoValidacao.forEach(element =>{
            mensagem = mensagem + element.codigoOld + ', ';
          });

          mensagem =  mensagem.substring(0, mensagem.lastIndexOf(',')) + '. ';
          mensagem = mensagem + '\n' +  ' PARA O EVENTO NEW TEMOS: '
          this.listaIngressoValidacao.forEach(element =>{
            mensagem = mensagem + element.codigoNew + ', ';
          });

          mensagem =  mensagem.substring(0, mensagem.lastIndexOf(',')-1) + '. ';

          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: mensagem
          });
          return true;

      }

    }

    return false;

  }

  tabChange(event: any){
    this.toolbarService.replace('add', false, 'class-new-button', 'SALVAR', 'Salvar Relacionamento', () => this.salvarRelacionamento());
  }


  changeDataIni(event : any){

    this.dataFin = event;

  }

buscarComboEventosOld(){
  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-lista-combo-eventos-old',[ this.dblinkedSessionService.empresa.id])
    .subscribe(retorno => {

      this.opcoesComboEventoOld  = [];
      this.listaParticipantesOld = new Array();
      this.opcoesComboEventoNew = [];
      this.listaParticipantesNew = new Array();
      this.opcoesComboEventoOld.push({label: 'Selecione um Evento' , value: 0});
      retorno.forEach(i =>{
        this.opcoesComboEventoOld.push({label: i.dataInicio + ' - ' +  i.nomeCidade + '/' + i.uf  + ' - ' + i.codigo + ' - ' + i.descricao  , value: i.id});
      })
    },
      error => this.httpService.handleError(error, () => this.buscarComboEventosOld()),
      () => this.httpService.done());



}


buscarComboEventosNew(eventosId: number){

  this.opcoesComboEventoNew  = [];
  this.listaParticipantesNew = new Array();
  if (eventosId > 0) {
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-lista-combo-eventos-new',[ this.dblinkedSessionService.empresa.id, eventosId])
      .subscribe(retorno => {

        this.opcoesComboEventoNew  = [];
        this.listaParticipantesNew = new Array();
        this.opcoesComboEventoNew.push({label: 'Selecione um Evento' , value: 0});
        retorno.forEach(i =>{
          this.opcoesComboEventoNew.push({label: i.dataInicio + ' - ' +  i.nomeCidade + '/' + i.uf  + ' - ' + i.codigo + ' - ' + i.descricao  , value: i.id});
        })
      },
        error => this.httpService.handleError(error, () => this.buscarComboEventosNew(eventosId)),
        () => this.httpService.done());

  } else{
    this.opcoesComboEventoNew = [];
    this.listaParticipantesNew = new Array();
  }


}

carregarParticipantesEventoOld(eventoId: number){
  this.listaParticipantesOld = new Array();
  this.listaParticipantesNew = new Array();
  if (eventoId > 0) {
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-lista-participante-evento-old',[  eventoId])
      .subscribe(retorno => {
        this.listaParticipantesOld = new Array();
        retorno.forEach(element => {
          this.listaParticipantesOld.push(element);
        });
      },
        error => this.httpService.handleError(error, () => this.carregarParticipantesEventoOld(eventoId)),
        () => this.httpService.done());

  } else {

  }

}

carregarParticipantesEventoNew(eventoId: number){
  this.listaParticipantesNew = new Array();
  if (eventoId > 0) {
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-lista-participante-evento-new',[  eventoId])
      .subscribe(retorno => {
        this.listaParticipantesNew = new Array();
        retorno.forEach(element => {
          this.listaParticipantesNew.push(element);
          this.listaParticipantesNewIds.push(element.id, element.depId);
        });
      },
        error => this.httpService.handleError(error, () => this.carregarParticipantesEventoOld(eventoId)),
        () => this.httpService.done());

  }

}
 checarIngressos(eventoIdOld: number, eventoIdNew : number){
  this.listaIngressoValidacao = new Array();
  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-checar-ingressos-transferir-participantes',[eventoIdOld, eventoIdNew])
    .subscribe(retorno => {

      this.listaIngressoValidacao = new Array();
      retorno.forEach(element =>{
        this.listaIngressoValidacao.push(element);
      });
    },
      error => this.httpService.handleError(error, () => this.checarIngressos(eventoIdOld, eventoIdNew)),
      () => this.httpService.done());
 }

}
