import { TodoAlcadas } from './todo-alcadas.model';
import { TodoAlcadasAcoes } from './todo-alcadas-acoes.model';
import { TodoPainel } from './todo-painel.model';

export class TodoAlcadasRegras {
  id: number;
  todoAlcadas: TodoAlcadas;
  todoAlcadasAcoes: TodoAlcadasAcoes;
  aprovacao: boolean;
  todoPainelIni: TodoPainel;
  todoPainelFin: TodoPainel;
  condicao: string;

  // campos não vinculados ao banco, somente para tratativa interna:
  update: boolean;
  checkTodoPainelIni: string;
  checkTodoPainelFin: string;

  constructor(todoAlcadasRegras?: TodoAlcadasRegras | number) {
    if (todoAlcadasRegras !== undefined && todoAlcadasRegras !== null) {
      if (typeof todoAlcadasRegras === 'number') {
        this.id = todoAlcadasRegras;
      } else {
        this.id = todoAlcadasRegras.id;
        this.todoAlcadas = todoAlcadasRegras.todoAlcadas;
        this.todoAlcadasAcoes = todoAlcadasRegras.todoAlcadasAcoes;
        this.aprovacao = todoAlcadasRegras.aprovacao;
        this.todoPainelIni = todoAlcadasRegras.todoPainelIni;
        this.todoPainelFin = todoAlcadasRegras.todoPainelFin;
        this.condicao = todoAlcadasRegras.condicao;
        this.update = todoAlcadasRegras.update;
        this.checkTodoPainelIni = todoAlcadasRegras.checkTodoPainelIni;
        this.checkTodoPainelFin = todoAlcadasRegras.checkTodoPainelFin;
      }
    }
  }
}
