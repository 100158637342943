import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { MessageService } from '../../../../node_modules/primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';

@Component({
  selector: 'app-nda-tipo',
  templateUrl: './nda.component.html',
  styleUrls: ['./nda.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class ConfigNdaComponent implements OnInit, OnDestroy {
  ////////////////////////////////////////
  // REFATOR SIMON LALLEMENT 28/12/2021 //
  ////////////////////////////////////////

  private sessionSubscription: Subscription

  //Toolbar
  toolbarMainIni = [
    { icon: 'delete', text: 'Excluir', tooltip: 'Excluir', visible: false, disabled: false, color: 'red', onClick: () => this.excluirNda() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.openEditNda() },
    { icon: 'add', text: 'Nova', tooltip: 'Nova', visible: true, disabled: false, color: 'green', onClick: () => this.openAddNda() },
  ]
  public toolbarMain = new Toolbar()

  //Array
  ndaRequisicao: Array<any> = new Array()
  ndaSelected: Array<number> = new Array()
  selectedRows: Array<any> = new Array()

  //String
  titlePrincipal: string = 'Configurações RMS'
  subtitle: string = 'NDA'
  currentStateNda: string = 'initial'
  descricaoNda: string = null

  //Boolean
  isCardNda: boolean = true
  checkedAll: boolean = false
  modalNda: boolean = false
  add: boolean = false
  edit: boolean = false

  //Miscellaneous
  ndaSelecionada: any = {}

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.titleService.title = 'Linnks - NDA RMS'
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni)

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected) {
        this.ndaRequisicaoItens()
      }
    })
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription)
    this.toolbarService.hidden = false
  }

  openAddNda() {
    this.modalNda = true
    this.descricaoNda = null
    this.add = true
  }

  openEditNda() {
    this.modalNda = true
    this.ndaSelecionada = this.ndaRequisicao.find(element => this.selectedRows[0].id === element.id)
    this.descricaoNda = this.ndaSelecionada.nome
    this.edit = true
  }

  excluirNda() {
    if (confirm("Deseja remover as NDA selecionadas?")) {
      let listaEnviar = {
        id: this.selectedRows.map(item => item.id)
      }

      this.httpService.wait();
      this.httpService.post('/custom/rms/deletar-nda', listaEnviar)
        .subscribe(rst => {
          this.httpService.done();

          if(rst?.error) {
            return this.exibirMensagem(rst);
          }

          this.ndaRequisicaoItens()
          this.checkToolBar()
          this.resetSelected()
        }, error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao excluir as NDA, contate o suporte : " + error.message })
          this.httpService.done()
          this.resetSelected()
        })
    }
  }

  changeStateNda() {
    this.currentStateNda = this.currentStateNda === 'initial' ? 'final' : 'initial'
  }

  showHideNda() {
    this.isCardNda = !this.isCardNda
  }

  adicionar() {
    if (this.validacao()) return

    let listaEnviar = {
      nomeTipo: this.descricaoNda,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait();
    this.httpService.post('/custom/rms/adicionar-nda', listaEnviar).subscribe(rst => {
        if(rst.error) this.exibirMensagem({ severity: rst.error ? 'error' : 'success', message: rst.detail })
        else {
          this.ndaRequisicaoItens()
          this.modalNda = false
          this.add = false
        }
      },
      error => {
        this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar o NDA, contate o suporte : " + error.message })
        this.resetSelected()
      },
      () => this.httpService.done())
  }

  validacao(): boolean {
    if (!this.descricaoNda) {
      this.exibirMensagem({ severity: 'warn', mensagem: "O campo Descrição é obrigatório." })
      return true
    }

    if(this.ndaRequisicao.map(item => { return item.nome.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z._ ])/g, '').toLowerCase().trim() }).includes(this.descricaoNda.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z._ ])/g, '').toLowerCase().trim())) {
      this.exibirMensagem({ severity: 'warn', mensagem: "A descrição deve ser única." })
      return true
    }

    return false
  }

  ndaRequisicaoItens() {
    this.httpService.wait()
    this.httpService.get('/custom/rms/listar-nda', this.dblinkedSessionService.empresa.id)
      .subscribe(rst => {
        this.ndaRequisicao = new Array()
        rst.forEach(element => { this.ndaRequisicao.push(element) })
        this.httpService.done()
        this.resetSelected()
      },
        error => {
          console.log(error)
          this.exibirMensagem({ severity: "error", message: "Erro no API ao obter os NDA, contate o suporte : " + error.message })
          this.httpService.done()
          this.resetSelected()
        })
  }

  exibirMensagem(obj) {
    if (obj.severity === "error" || obj.error) {
      this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "warn") {
      this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Atenção', detail: obj.message ? obj.message : obj.mensagem })

    } else if (obj.severity === "info") {
      this.messageService.add({ key: 'messageLinnks', severity: 'info', summary: 'Informação', detail: obj.message ? obj.message : obj.mensagem })

    } else {
      this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: obj.message ? obj.message : obj.mensagem })
    }
  }

  atualizar() {
    if (this.validacao()) return

    let listaEnviar =  {
      id: this.ndaSelecionada.id,
      nome: this.descricaoNda,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait();
    this.httpService.post('/custom/rms/atualizar-nda', listaEnviar).subscribe(rst => {
      if(rst.error) this.exibirMensagem({ severity: "error", message: rst.detail })
      else {
        this.exibirMensagem({ severity: "success", message: rst.detail })
        this.ndaRequisicaoItens()
        this.checkToolBar()
        this.modalNda = false
        this.edit = false
      }
    }, error => {
      this.exibirMensagem({ severity: "error", message: "Erro no API ao salvar o NDA, contate o suporte : " + error.message })
      this.resetSelected()
    },
    () => this.httpService.done())
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  resetSelected() {
    this.selectedRows = [];
    this.edit = false;
    this.checkToolBar();
  }

  checkToolBar() {
    this.edit = false;
    this.toolbarMain.setVisible('btn1', this.selectedRows.length !== 0);
    this.toolbarMain.setVisible('btn2', this.selectedRows.length === 1);
  }
}
