export class IndFinEsc {
  id: number;
  cod: string;
  descricao: string;

  constructor(indfinesc?: IndFinEsc | number) {
    if (indfinesc !== undefined && indfinesc !== null) {
      if (typeof indfinesc === 'number') {
        this.id = indfinesc;
      } else {
        this.id = indfinesc.id;
        this.cod = indfinesc.cod;
        this.descricao = indfinesc.descricao;
      }
    }
  }
}
