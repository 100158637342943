import { Empresa } from "app/cadastro/model/empresa.model";

export class Nda {
  id: number;
  nome: string;
  empresa: Empresa;

  constructor(nda?: Nda | number) {
    if (nda !== undefined && nda !== null) {
      if (typeof nda === 'number') {
        this.id = nda;
      } else {
        this.id = nda.id;
        this.nome = nda.nome;
        this.empresa = nda.empresa;
      }
    }
  }
}
