import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { PlanoContas } from 'app/contabil/model/plano-contas.model'
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel'
import { Toolbar } from 'app/shared/model/toolbar.model'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpService } from 'app/shared/services/http.service'
import { LoginService } from 'app/shared/services/login.service'
import { SessionService } from 'app/shared/services/session.service'
import { TitleService } from 'app/shared/services/title.service'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { UtilService } from 'app/shared/services/util.service'
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api'
import { Table } from 'primeng/table'
import { Subscription } from 'rxjs/Subscription'
import { isNullOrUndefined } from 'util'
import { CodNaturezaConta } from '../../util/model/cod-natureza-conta.model'
import LogManagement from 'app/shared/common/log-management/log-control'
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types'

@Component({
  selector: 'app-planocontas-new',
  templateUrl: './planocontas-new.component.html',
  styleUrls: [
    './planocontas-new.component.scss'
  ]
})
export class PlanoContasNewComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription
  planoContas: Array<PlanoContas> = new Array()
  selectPlano: Array<any> = new Array()
  planoContasSup: SelectItem[]
  title: string
  subtitle: string
  pt: any
  novaConta: boolean
  editarConta: boolean
  codigo: string
  nivel: number
  descricao: string
  descricaoEn: string
  descricaoEs: string
  naturezaConta: CodNaturezaConta
  indCta: string
  ctaSup: PlanoContas
  ativa: boolean
  permiteLancamentoManual: boolean
  aceitaRateio: boolean
  opcoesNivel: SelectItem[]
  ids: Array<any> = new Array()
  opcoesNatureza: SelectItem[]
  opcoesIndicador: SelectItem[]

  opcoesAtivo: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }]

  filtro: SelectItem[]

  editTool: boolean = false

  contaContabilEditar: PlanoContas
  idCodNatureza: any
  idctaSup: any

  //export excel
  colsplanoContas: any[] = []

  modalClonarPlanoContas: boolean = false
  codigoClonagem: any
  descricaoClonagem: any
  descricaoInglesClonagem: any
  clonarRelacaoRfb: boolean = true

  // Variáveis usadas na conexão entre Plano Contas
  planoContasMapeadas: SelectItem[]
  ctaMapeada: any
  planoContasId: any

  companiesGroup: SelectItem[] = new Array()
  selectedCompaniesGroup: Array<any> = new Array()

  accountCanBeChanged: boolean = false

  placeholderSelectedCompanies: string = 'Selecione a(s) empresa(s)'
  registerGroupCompanies: boolean = false
  companyCanMakeChanges: boolean= false
  tituloModalGrupoEmpresas: string
  descricaoModalGrupoEmpresas: string
  modalGrupoEmpresas: boolean = false
  modalExclusao: boolean = false
  grupoEmpresaPlanoContaOrigem: number

  totalRecords: number = 0
  visualizarContaAutomatica: boolean
  clonagemNaPropriaEmpresa: boolean
  clonagemTotalNoGrupoDeEmpresas: boolean


  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'CÓDIGO', textAlign: 'left', size: '10%' },
      { name: 'DESCRIÇÃO', textAlign: 'left', size: '35%' },
      { name: 'INDICADOR', textAlign: 'left', size: '10%' },
      { name: 'NÍVEL', textAlign: 'left', size: '10%' },
      { name: 'NATUREZA', textAlign: 'left', size: '10%' },
      { name: 'DATA INICIAL', textAlign: 'left', size: '10%' },
      { name: 'ATIVA', textAlign: 'left', size: '10%' },
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true
  })

  private LogManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private httpClient: HttpClient
  ) { }

  @ViewChild('dt') dt: Table
  exportMov() {
    this.dt.exportCSV()
  }

  public toolbarPlano = new Toolbar()

  toolbarMainPlano = [
    {
      key: 'btn-clone-expanded', icon: 'content_copy', text: '', tooltip: 'Clonar', visible: true, disabled: false, color: 'default', dropdown: true,
      options: [
        { optionKey: 'btn-clone-group', label: 'Clonar no grupo de empresas', callback: () => this.setarVariaveisClonagem(false) },
        { optionKey: 'btn-clone-all', label: 'Clonar tudo (Grupo Empresa)', callback: () => this.setarVariaveisClonagem(false, true) },
        { optionKey: 'btn-self-clone', label: 'Clonar somente na empresa atual', callback: () => this.setarVariaveisClonagem(true) },
      ]
    },
    { key: "export", icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: false, disabled: false, color: 'default', onClick: () => this.exportAccountsPlanReport() },
    { key: "voltar", icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { key: "clonar", icon: 'content_copy', text: 'Clonar Conta', tooltip: 'Clonar Conta', visible: false, disabled: false, color: 'default', onClick: () => this.abrirModalClonarPlanoContas() },
    { key: "edit", icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.editarContaContabil() },
    { key: "delete", icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirPlanoContas() },
    { key: "add", icon: 'add', text: 'Novo', tooltip: 'Novo Plano', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { key: "saveNew", icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoParaSalvar() },
    { key: "save", icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração', visible: false, disabled: false, color: 'default', onClick: () => this.determinarMetodoParaSalvar() },
  ]

  ngOnInit(): void {
    this.toolbarPlano.setAll(this.toolbarMainPlano)
    this.title = 'PLANO DE CONTAS'
    this.subtitle = 'INFORMAÇÕES SOBRE PLANO DE CONTAS'
    this.titleService.title = 'PLANO DE CONTAS'
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.planoContas = new Array()
    this.planoContasSup = new Array()
    this.novaConta = false
    this.naturezaConta = new CodNaturezaConta()
    this.ctaSup = new PlanoContas()

    this.toolbarService.hidden = true

    this.httpService
      .get('/codnaturezaconta?$select=id,cod,descricao')
      .subscribe(
        ref => this.loadNatureza(ref.value),
        error => this.httpService.handleError(error, () => this.ngOnInit())
      )

    this.opcoesIndicador = []
    this.opcoesIndicador.push({ label: 'Nenhum', value: null })
    this.opcoesIndicador.push({ label: 'A', value: 'A' })
    this.opcoesIndicador.push({ label: 'S', value: 'S' })

    this.opcoesNivel = []
    this.opcoesNivel.push({ label: '1', value: 1 })
    this.opcoesNivel.push({ label: '2', value: 2 })
    this.opcoesNivel.push({ label: '3', value: 3 })
    this.opcoesNivel.push({ label: '4', value: 4 })
    this.opcoesNivel.push({ label: '5', value: 5 })
    this.opcoesNivel.push({ label: '6', value: 6 })

    this.filtro = []
    this.filtro.push({ label: 'Sim', value: true })
    this.filtro.push({ label: 'Não', value: false })

    this.idCodNatureza = null
    this.idctaSup = null

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    }

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        this.carregarDadosGrupoEmpresa()
        this.carregar()
      }
    )

    this.colsplanoContas = [
      { field: 'codCta', header: 'CÓDIGO' },
      { field: 'descrCta', header: 'DESCRIÇÃO' },
      { field: 'indCta', header: 'INDICADOR' },
      { field: 'nivel', header: 'NÍVEL' },
      { field: 'natConta', header: 'NATUREZA' },
      { field: 'codNaturezaConta.descricao', header: 'NATUREZA' },
      { field: 'dtInicial', header: 'DATA INICIAL' },
      { field: 'ativo', header: 'ATIVA' }
    ]
  }

  async carregar() {
    if (
      this.dblinkedSessionService.hasPeriodoSelected &&
      this.dblinkedSessionService.hasEmpresaSelected
    ) {

      this.title = 'PLANO DE CONTAS'
      this.subtitle = 'INFORMAÇÕES SOBRE PLANO DE CONTAS'
      this.titleService.title = 'PLANO DE CONTAS'

      this.codigo = ''
      this.nivel = 1
      this.descricao = ''
      this.descricaoEn = ''
      this.descricaoEs = ''
      this.naturezaConta = new CodNaturezaConta()
      this.indCta = ''
      this.ctaSup = new PlanoContas()
      this.ativa = true
      this.permiteLancamentoManual = true
      this.aceitaRateio = false

      this.novaConta = false
      this.editarConta = false
      this.ids = new Array()
      this.selectPlano = new Array()

      // Inicializar as variáveis usadas na conexão entre Plano Contas
      this.planoContasMapeadas = []
      this.planoContasId = null
      this.ctaMapeada = null

      // Inicializar as variáveis usadas na clonagem do Plano de Contas
      this.codigoClonagem = null
      this.descricaoClonagem = null
      this.descricaoInglesClonagem = null

      this.modalClonarPlanoContas = false
      this.clonarRelacaoRfb = true

      this.changeToolbar()
      this.loadPlanoContasCompleto()
      this.carregarDadosParametroContabil();
      if (this.companyCanMakeChanges) this.resetarSelecoesGrupoEmpresas()

      this.modalGrupoEmpresas = false
      this.clonagemNaPropriaEmpresa = true
      this.clonagemTotalNoGrupoDeEmpresas = true

      return
    }
  }


  async carregarDadosGrupoEmpresa() {
    if (this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.hasEmpresaSelected) {
      await this.checkIfCompanyCanMakeChanges()
      if (this.companyCanMakeChanges) await this.getRegisterGroupCompanies()
    }
  }


  public async loadPlanoContasCompleto(event: LazyLoadEvent = {}): Promise<void> {
    this.skeletonConfig.startLoad()

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'userid': this.sessionService.loggedUser.id.toString(),
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    }

    const payload = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      limit: event.rows,
      offset: event.first,
      sort_order: event.sortOrder,
      sort_field: event.sortField,
      filters: event.filters,
      ano: Number(this.dblinkedSessionService.periodo.year.value),
      resgatarTodosPlanosContas: true,
      contas_automaticas: this.visualizarContaAutomatica
    }

    try {
      await this.httpClient.post(`${this.configService.defaultUrlApiUpload}/contabil/plano-contas-lazyload`, payload, httpOptions).toPromise().then(
        response => {
          this.planoContas = new Array()

          this.totalRecords = response['data']['total']
          response['data']['lista'].forEach(element => { this.planoContas.push(element) })
        }
      ).catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Erro ao buscar os planos de contas!' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

  loadNatureza(value: Array<CodNaturezaConta>) {
    this.opcoesNatureza = []
    this.opcoesNatureza.push({ label: 'Nenhum', value: 0 })
    value.forEach(element => {
      this.opcoesNatureza.push({
        label: element.cod + ' - ' + element.descricao,
        value: element.id
      })
    })
  }

  alterarNatureza() {
    this.httpService.wait()
    this.httpService.get("/planocontas?$select=id,codCta,descrCta,indCta,nivel,codNaturezaConta/descricao,codNaturezaConta/id,dtInicial" +
      "&$filter=empresa/id eq " + this.dblinkedSessionService.empresa.id + " and period(dtInicial,dtFinal) eq " +
      this.dblinkedSessionService.periodo.year.value +
      " and indCta eq 'S' and nivel eq " +(this.nivel-1)+ "&$orderby=codCta").subscribe(plano => {

        this.planoContasSup = new Array()
        this.planoContasSup.push({
          label: 'Nenhuma',
          value: null
        })
        plano.value.forEach(pc => {
          if (pc.codNaturezaConta.id === this.naturezaConta && pc.nivel < this.nivel) {
            this.planoContasSup.push({
              label: pc.codCta + ' - ' + pc.descrCta,
              value: pc.id
            })
          }
        })

        if (this.novaConta) {
          if (Number(this.naturezaConta) === 4) {
            this.aceitaRateio = true
          } else {
            this.aceitaRateio = false
          }
        }
      },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done())
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods()
    this.sessionSubscription.remove(this.sessionSubscription)
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage
  }
  get rows() {
    return this.configService.applicationConfig.rows
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks
  }

  criarNovo() {
    this.novaConta = true
    this.title = 'CRIANDO NOVA CONTA CONTÁBIL'
    this.changeToolbar()
  }

  validacao(): boolean {
    this.idCodNatureza = null

    if (!isNullOrUndefined(this.naturezaConta) && Number(this.naturezaConta) > 0) {
      this.idCodNatureza = this.naturezaConta
    } else {
      this.idCodNatureza = null
    }
    if (isNullOrUndefined(this.codigo) || this.codigo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Código é Obrigatório' })
      return true
    }
    if (isNullOrUndefined(this.descricao) || this.descricao.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição da conta é Obrigatória' })
      return true
    }
    if (isNullOrUndefined(this.idCodNatureza)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Natureza da conta é Obrigatória' })
      return true
    }
    if (isNullOrUndefined(this.nivel)) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Nível da conta é Obrigatório' })
      return true
    }
    if (!isNullOrUndefined(this.nivel) && this.nivel > 1) {
      if ((!this.planoContasSup) && this.indCta === 'A') {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Conta Superior é Obrigatório' })
        return true
      }
    }
    return false
  }


  salvarConta() {
    if (!isNullOrUndefined(this.ctaSup) && Number(this.ctaSup) > 0) {
      this.idctaSup = this.ctaSup
    } else {
      this.idctaSup = null
    }

    const listasEnviar = {
      codigo: this.codigo,
      descricao: this.descricao,
      descricaoEn: this.descricaoEn,
      descricaoEs: this.descricaoEs,
      naturezaConta: this.naturezaConta,
      indCta: this.indCta,
      nivel: this.nivel,
      ctaSup: this.idctaSup,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id,
      ano: this.dblinkedSessionService.periodo.year.value,
      aceitaRateio: this.aceitaRateio,
      contaMapeada: this.ctaMapeada
    }


    this.httpService.wait()
    this.httpService.post('/custom/ecd/cadastrar-conta-contabil', listasEnviar)
      .subscribe(result => {

        if (!isNullOrUndefined(result)) {
          if (result[0].error === true) {

            this.messageService.add({
              severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result[0].message
            })

            return
          } else {

            this.messageService.add({
              severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: result[0].message
            })

            this.LogManagement.registerLog(
              LogModule.REGISTRATION,
              LogScreen.ACCOUNT_PLAN,
              '/custom/ecd/cadastrar-conta-contabil',
              LogAction.CREATE,
              result[0].id,
              this.codigo,
              `A conta ${this.codigo} foi criada`,
              listasEnviar
            )
            this.carregar()

          }
        }
      }, error => this.httpService.handleError(error, () => this.salvarConta()),
        () => this.httpService.done())

  }

  salvarAlteracaoConta() {
    if (!isNullOrUndefined(this.ctaSup) && Number(this.ctaSup) > 0) {
      this.idctaSup = this.ctaSup
    } else {
      this.idctaSup = null
    }

    const listasEnviar = {
      codigo: this.codigo,
      descricao: this.descricao,
      descricaoEn: this.descricaoEn,
      descricaoEs: this.descricaoEs,
      naturezaConta: this.naturezaConta,
      indCta: this.indCta,
      nivel: this.nivel,
      ctaSup: this.idctaSup,
      usuario: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id,
      ano: this.dblinkedSessionService.periodo.year.value,
      id: this.ids[0],
      ativa: this.ativa,
      permiteLancamentoManual: this.permiteLancamentoManual,
      aceitaRateio: this.aceitaRateio,
      planoContasId: this.planoContasId,
      contaMapeada: this.ctaMapeada
    }

    this.httpService.wait()
    this.httpService.post('/custom/ecd/atualizar-conta-contabil', listasEnviar)
      .subscribe(result => {

        if (!isNullOrUndefined(result)) {
          if (result[0].error === true) {

            this.messageService.add({
              severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result[0].message
            })
            return
          } else {

            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: result[0].message})
            this.LogManagement.registerLog(
              LogModule.REGISTRATION,
              LogScreen.ACCOUNT_PLAN,
              '/custom/ecd/atualizar-conta-contabil',
              LogAction.EDIT,
              this.ids[0],
              this.codigo,
              `A conta ${this.codigo} foi alterada`,
              listasEnviar
            )
            this.carregar()

          }
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoConta()),
        () => this.httpService.done())
  }

  changeToolbar() {
    const conditionInitialScrenn = !this.novaConta && !this.editarConta && this.companyCanMakeChanges
    const conditionInitialScrennAndOneAccountSelected =  conditionInitialScrenn && this.ids.length === 1 && this.companyCanMakeChanges
    const conditionInitialScreenAndMoreThanOneAccountSelected = conditionInitialScrenn && this.ids.length >= 1 && this.companyCanMakeChanges
    const conditionInitialAndCompanyCanMakeChanges = !this.novaConta && !this.editarConta && this.companyCanMakeChanges

    this.toolbarPlano.setVisible('clonar', conditionInitialScrennAndOneAccountSelected && !this.registerGroupCompanies)

    this.toolbarPlano.setVisible('btn-clone-expanded', conditionInitialScrenn && this.registerGroupCompanies)
    this.toolbarPlano.setOptionVisible('btn-clone-expanded', 'btn-clone-group', conditionInitialScrennAndOneAccountSelected && this.registerGroupCompanies)
    this.toolbarPlano.setOptionVisible('btn-clone-expanded', 'btn-clone-all', conditionInitialScrenn && this.registerGroupCompanies)
    this.toolbarPlano.setOptionVisible('btn-clone-expanded', 'btn-self-clone', conditionInitialScrennAndOneAccountSelected && this.registerGroupCompanies)

    this.toolbarPlano.setVisible('edit', conditionInitialScrennAndOneAccountSelected)
    this.toolbarPlano.setVisible('delete', conditionInitialScrennAndOneAccountSelected || conditionInitialScreenAndMoreThanOneAccountSelected || this.editarConta)
    this.toolbarPlano.setVisible('export', !this.novaConta && !this.editarConta)
    this.toolbarPlano.setVisible('add', conditionInitialAndCompanyCanMakeChanges)

    this.toolbarPlano.setVisible('voltar', this.novaConta || this.editarConta)
    this.toolbarPlano.setVisible('saveNew', this.novaConta)
    this.toolbarPlano.setVisible('save', this.editarConta)
  }


  onRowSelect(event: any) {
    this.ids.push(event.data.id)
    event.selected = false
    this.changeToolbar()
  }

  onRowUnselect(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id)
    this.ids.splice(index, 1)
    event.selected = true
    this.changeToolbar()
  }

  selecionarTudo() {
    this.ids = this.selectPlano.map((plano: any) => {return plano.id})
    this.changeToolbar()
  }

  editarContaContabil() {
    this.editarConta = true
    this.selectPlano = []
    this.changeToolbar()

    this.httpService.wait()
    this.httpService.get('/planocontas?$select=id,codCta,descrCta,indCta,nivel,codNaturezaConta/descricao,codNaturezaConta/id,' +
      'dtInicial,descrEn,descrEs,parent/id,ativo,permiteLancamentoManual,aceitaRateio,grupoEmpresaPlanoContaOrigem/id&$filter=id eq ' + this.ids[0] + '&$orderby=codCta')
      .subscribe(plano => {
        this.codigo = plano.value[0].codCta
        this.descricao = plano.value[0].descrCta
        this.descricaoEn = plano.value[0].descrEn
        this.descricaoEs = plano.value[0].descrEs
        this.naturezaConta = plano.value[0].codNaturezaConta.id
        this.nivel = plano.value[0].nivel
        this.indCta = plano.value[0].indCta
        this.ativa = plano.value[0].ativo
        this.permiteLancamentoManual = plano.value[0].permiteLancamentoManual
        this.aceitaRateio = plano.value[0].aceitaRateio
        this.grupoEmpresaPlanoContaOrigem = plano.value[0].grupoEmpresaPlanoContaOrigem ? plano.value[0].grupoEmpresaPlanoContaOrigem.id : null
        this.alterarNatureza()
        if (plano.value[0].parent) {
          this.ctaSup = plano.value[0].parent.id
        }

        if ((plano.value[0].codNaturezaConta.id != null) &&
          (plano.value[0].codNaturezaConta.id != undefined)) {
          this.alterarContasMapeadas(plano.value[0].codNaturezaConta.id)
        }

        this.planoContasId = plano.value[0].id
        if (this.indCta == 'A') {
          this.obterConexaoPlanoContas()
        }

      },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done())

    this.title = 'Editando Conta Contábil'
  }

  excluirPlanoContas() {
    let textoDelecao = this.registerGroupCompanies ?  "Deseja deletar a(s) Conta(s) selecionada(s)? Essa ação pode afetar as possíveis contas existentes nas empresas do Grupo" : "Deseja deletar a(s) Conta(s) selecionada(s)?"

    if (confirm(textoDelecao)) {

      const listasEnviar = {
        idsContaContabil: this.ids,
      }

      this.httpService.wait()
      this.httpService.post('/custom/ecd/deletar-conta-contabil', listasEnviar)
        .subscribe(result => {
          if (!result.error) {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result.message})

            this.selectPlano.forEach(row => {
              this.LogManagement.registerLog(
                LogModule.REGISTRATION,
                LogScreen.ACCOUNT_PLAN,
                '/custom/ecd/deletar-conta-contabil',
                LogAction.DELETE,
                row.id,
                row.codCta,
                `A conta ${row.codCta} foi excluída`,
                listasEnviar
              )
            })

            this.carregar()
            return
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: result.message
            })
          }
        },
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done())
    }
  }

  /* Função que obtém a conta RFB relacionada com a conta que possui id = this.planoContasId */
  obterConexaoPlanoContas() {
    this.httpService.wait()
    this.httpService.get('/custom/ecd/get-conexao-plano-contas(' + this.planoContasId + ')')
      .subscribe(
        res => {
          if (res["error"] == false) {
            this.ctaMapeada = res["id"]
          } else {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: res["message"] })
          }

          this.httpService.done()
        },
        err => {
          this.httpService.done()
        }
      )
  }

  alterarContasMapeadas(naturezaConta: any) {
    // Carregar os dados de Plano Contas RFB, que poderão ser mapeados para uma conta criada/editada
    this.httpService.wait()
    this.httpService.get('/custom/ecd/get-plano-contas-rfb(' + this.dblinkedSessionService.empresa.id
      + "," + naturezaConta + ")")
      .subscribe(
        res => {
          if (res["error"] == false) {

            this.planoContasMapeadas = []
            this.planoContasMapeadas.unshift({ label: '', value: null })
            this.planoContasMapeadas.unshift({ label: 'SELECIONE', value: null })

            for (let line of res["result"]) {
              this.planoContasMapeadas.push({ label: line.cod_cta + ' - ' + line.descr_cta, value: line.id })
            }
          }

        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done())
  }

  /* Método que abre um modal que irá iniciar o processo de clonagem
  para um Plano de Contas */
  abrirModalClonarPlanoContas() {
    this.codigoClonagem = ''
    this.descricaoClonagem = ''
    this.descricaoInglesClonagem = ''
    this.modalClonarPlanoContas = true
  }

  /* Método que realiza a clonagem de um Plano de Contas */
  clonarPlanoContas() {
    let erroValidacao = this.validarClonarPlanoContas()

    if(erroValidacao) return

    var descricaoContaInglesStr
    if ((this.descricaoInglesClonagem == null) || (this.descricaoInglesClonagem == undefined)
      || (this.descricaoInglesClonagem == "")) {
      descricaoContaInglesStr = ""
    } else {
      descricaoContaInglesStr = this.descricaoInglesClonagem
    }

    const listaEnviar = {
      idClonagem: this.ids[0],
      codigoClonagem: this.codigoClonagem,
      descricaoClonagem: this.descricaoClonagem,
      descricaoInglesClonagem: descricaoContaInglesStr,
      usuarioId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id,
      clonarRelacaoRfb: this.clonarRelacaoRfb,
    }

    this.httpService.wait()
    this.httpService.post('custom/ecd/clonar-plano-contas', listaEnviar)
      .subscribe(result => {
        if (result.error === true) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result.message
          })
          this.carregar()
          return
        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: result.message
          })
          this.modalClonarPlanoContas = false
          this.clonarRelacaoRfb = true
          result.selected = true
          this.carregar()
        }
      },
        error => this.httpService.handleError(error, () => this.clonarPlanoContas()),
        () => this.httpService.done())
  }


  async getRegisterGroupCompanies() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.get(`/empresa?$select=cadastroGrupoEmpresa&$filter=id eq ${companyId}`).subscribe(
      res => {
        this.registerGroupCompanies = res.value[0].cadastroGrupoEmpresa ? true : false

        if(this.registerGroupCompanies) this.getGroupCompanies()
      },
      error => this.httpService.handleError(error, () => this.getRegisterGroupCompanies()),
      () => this.changeToolbar()
    )
  }


  getGroupCompanies() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.httpService.get(`/empresagrupocadastrorelac?$select=id,empresaRelac/nomeFantasia&$filter=empresa/id eq ` + companyId).subscribe(
      res => {
        this.companiesGroup = []
        this.selectedCompaniesGroup = []

        this.selectedCompaniesGroup.push(companyId)

        res.value.forEach(company => {
          this.companiesGroup.push({
            label: company.empresaRelac.nomeFantasia,
            value: company.empresaRelac.id
          })

          this.selectedCompaniesGroup.push(company.empresaRelac.id)
        })
      },
      error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Erro ao buscar as empresas do grupo' }),
      () => {
        this.setPlaceholderSelectedCompanies()
      }
    )
  }


  setPlaceholderSelectedCompanies() {
    this.placeholderSelectedCompanies = null
    if(this.selectedCompaniesGroup.length > 0) {
      let companyNames = []

      this.companiesGroup.forEach(company => {
        if(this.selectedCompaniesGroup.indexOf(company.value) !== -1) companyNames.push(company.label)
      })

      if(companyNames.length > 0) this.placeholderSelectedCompanies = companyNames.join(', ')
    }
  }


  async checkIfCompanyCanMakeChanges() {
    const companyId = this.dblinkedSessionService.empresa.id
    this.companyCanMakeChanges = false

    try {
      await this.httpService.get(`/custom/cadastro/verifica-grupo-empresa-permite-alteracao(${companyId})`).toPromise()
      .then(res => this.companyCanMakeChanges = res.permissoes.permiteAlteracaoContaContabil)
      .catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }


  determinarMetodoParaSalvar() {
    if(!this.validacao()) {
      if(this.registerGroupCompanies) {
        this.modalGrupoEmpresas = true
        this.tituloModalGrupoEmpresas = this.novaConta ? 'Criação de contas em várias empresas' : 'Edição de contas em várias empresas'
        this.descricaoModalGrupoEmpresas = this.novaConta ? 'Em quais outras empresas você deseja criar esta conta?' : 'Em quais outras empresas você deseja editar esta conta?'
      } else if(this.novaConta) {
        this.salvarConta()
      } else if(this.editarConta) {
        this.salvarAlteracaoConta()
      }
    }
  }


  salvarNovaContaEmGrupoEmpresas() {
    if (this.ctaSup !== null && this.ctaSup !== undefined && Number(this.ctaSup) > 0) this.idctaSup = this.ctaSup
    else this.idctaSup = null

    const payload = {
      codigo: this.codigo,
      descricao: this.descricao,
      descricaoEn: this.descricaoEn,
      descricaoEs: this.descricaoEs,
      naturezaConta: this.naturezaConta,
      indCta: this.indCta,
      nivel: this.nivel,
      ctaSup: this.idctaSup,
      usuarioId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id,
      ano: this.dblinkedSessionService.periodo.year.value,
      aceitaRateio: this.aceitaRateio,
      contaMapeada: this.ctaMapeada,
      empresasGrupo: this.selectedCompaniesGroup
    }

    this.httpService.wait()
    this.httpService.post('/custom/ecd/cadastrar-conta-contabil-grupo', payload)
      .subscribe(
        response => {
          if(!response.error) this.carregar()
          this.messageService.add({ severity: response.severity, key: 'messageLinnks', summary: response.summary, detail: response.message })
        },
        error => this.httpService.handleError(error, () => this.salvarNovaContaEmGrupoEmpresas()),
        () => this.httpService.done()
      )
  }


  salvarAlteracaoContaEmGrupoEmpresas() {
    if (this.ctaSup !== null && this.ctaSup !== undefined && Number(this.ctaSup) > 0)this.idctaSup = this.ctaSup
    else this.idctaSup = null

    const empresaId = this.dblinkedSessionService.empresa.id
    const empresaAtualSelecionada = this.selectedCompaniesGroup.indexOf(empresaId) !== -1
    let empresasGrupo = this.selectedCompaniesGroup

    if(!empresaAtualSelecionada) empresasGrupo.push(empresaId)

    const payload = {
      codigo: this.codigo,
      descricao: this.descricao,
      descricaoEn: this.descricaoEn,
      descricaoEs: this.descricaoEs,
      naturezaConta: this.naturezaConta,
      indCta: this.indCta,
      nivel: this.nivel,
      ctaSup: this.idctaSup,
      usuarioId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id,
      ano: this.dblinkedSessionService.periodo.year.value,
      id: this.ids[0],
      ativa: this.ativa,
      permiteLancamentoManual: this.permiteLancamentoManual,
      aceitaRateio: this.aceitaRateio,
      planoContasId: this.planoContasId,
      contaMapeada: this.ctaMapeada,
      grupoEmpresaPlanoContaOrigem: this.grupoEmpresaPlanoContaOrigem,
      empresasGrupo
    }

    this.httpService.wait()
    this.httpService.post('/custom/ecd/atualizar-conta-contabil-grupo', payload)
      .subscribe(
        response => {
          if(!response.error) this.carregar()
          this.messageService.add({ severity: response.severity, key: 'messageLinnks', summary: response.summary, detail: response.message })
        },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoContaEmGrupoEmpresas()),
        () => this.httpService.done()
      )
  }

  clonarPlanoContasEmGrupoEmpresas(){
    const empresaId = this.dblinkedSessionService.empresa.id
    let empresasGrupo = this.selectedCompaniesGroup.filter(empresaGrupoId => empresaGrupoId !== empresaId)

    const payload = {
      planoContasIds: this.ids,
      usuarioId: this.sessionService.loggedUser.id,
      empresaId: empresaId,
      clonarRelacaoRfb: this.clonarRelacaoRfb,
      empresasGrupo,
      clonarTodoPlanoContas: this.clonagemTotalNoGrupoDeEmpresas
    }

    this.httpService.wait()
    this.httpService.post('custom/ecd/clonar-plano-contas-grupo', payload).subscribe(
      response => {
        if(!response.error) {
          response.selected = true
          this.carregar()
        }
        this.messageService.add({ severity: response.severity, key: 'messageLinnks', summary: response.summary, detail: response.message })
      },
      error => this.httpService.handleError(error, () => this.clonarPlanoContasEmGrupoEmpresas()),
      () => this.httpService.done()
    )
  }


  validarClonarPlanoContas(){
    let erroValidacao = false

    if ((this.codigoClonagem == null) || (this.codigoClonagem == undefined) || (this.codigoClonagem == "")) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: "Favor inserir um código do novo Plano de Contas." })
      erroValidacao = true
    } else if ((this.descricaoClonagem == null) || (this.descricaoClonagem == undefined)
      || (this.descricaoClonagem == "")) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: "Favor inserir uma descrição do novo Plano de Contas." })
      erroValidacao = true
    } else {
      this.planoContas.forEach(element => {
        if (this.codigoClonagem == element.codCta) {
          erroValidacao = true
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: "Código já existente. Por favor insira um novo código." })
        }
        if (this.descricaoClonagem.toUpperCase() == element.descrCta) {
          erroValidacao = true
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: "Descrição já existente. Por favor insira uma nova descrição." })
        }
      })
    }

    return erroValidacao
  }

  resetarSelecoesGrupoEmpresas() {
    const companyId = this.dblinkedSessionService.empresa.id

    this.selectedCompaniesGroup = []
    this.selectedCompaniesGroup.push(companyId)

    this.companiesGroup.map(company => {
      this.selectedCompaniesGroup.push(company.value)
    })
  }

  async exportAccountsPlanReport() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'userid': this.sessionService.loggedUser.id.toString(),
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      }),
      responseType: 'blob' as 'json'
    }

    const payload = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      ano: Number(this.dblinkedSessionService.periodo.year.value),
      userId: this.sessionService.loggedUser.id,
      resgatarTodosPlanosContas: true
    }

    try {
      await this.httpClient.post(`${this.configService.defaultUrlApiUpload}/contabil/exportar-lazyloading`, payload, httpOptions).toPromise().then(
        (response: Blob) => {
          let link = document.createElement('a');
          let url = window.URL.createObjectURL(response);
          let fileName = `Relatório de Plano de Contas.xlsx`;
          link.href = url;
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(url);
        }
      ).catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Erro ao gerar o relatório!' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }

  carregarDadosParametroContabil() {
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-empresa-parametro-contabil', this.dblinkedSessionService.empresa.id).subscribe(
      res => {
        if (res["error"] == false) {
          for (let line of res["result"]) {
            this.visualizarContaAutomatica = line["visualizaContaAutomatica"]
          }
        }
      },
      error => this.httpService.handleError(error, () => this.carregarDadosParametroContabil()),
      () => this.httpService.done()
    )
  }

  setarVariaveisClonagem(clonagemNaPropriaEmpresa: boolean, clonagemTotalNoGrupoDeEmpresas: boolean = false) {
    this.clonagemNaPropriaEmpresa = clonagemNaPropriaEmpresa
    this.clonagemTotalNoGrupoDeEmpresas = clonagemTotalNoGrupoDeEmpresas
    this.abrirModalClonarPlanoContas()
  }

  determinarMetodoClonagem() {
    if(this.registerGroupCompanies && !this.clonagemNaPropriaEmpresa && !this.clonagemTotalNoGrupoDeEmpresas) this.clonarPlanoContasEmGrupoEmpresas()
    else if(this.clonagemNaPropriaEmpresa) this.clonarPlanoContas()
    else if(this.registerGroupCompanies && this.clonagemTotalNoGrupoDeEmpresas) this.clonarTodoPlanoContasGrupoEmpresa()
  }

  clonarTodoPlanoContasGrupoEmpresa() {
    const empresaId = this.dblinkedSessionService.empresa.id
    let empresasGrupo = this.selectedCompaniesGroup.filter(empresaGrupoId => empresaGrupoId !== empresaId)

    const payload = {
      empresaClonadoraId: empresaId,
      empresasGrupo,
      usuarioId: this.sessionService.loggedUser.id,
      clonarRelacaoRfb: this.clonarRelacaoRfb,
    }

    this.httpService.wait()
    this.httpService.post('custom/ecd/clonar-todo-plano-contas-grupo-empresa', payload).subscribe(
      response => {
        if(!response.error) {
          response.selected = true
          this.carregar()
        }
        this.messageService.add({ severity: response.severity, key: 'messageLinnks', summary: response.summary, detail: response.message })
      },
      error => this.httpService.handleError(error, () => this.clonarPlanoContasEmGrupoEmpresas()),
      () => this.httpService.done()
    )
  }
}
