import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { SessionService } from 'app/shared/services/session.service'
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api'
import { BarChart, ColumnChart, PieChart } from '../components/interfaces/dash-graphics'
import { TabChanger } from '../components/interfaces/dash-changer'
import { TableControl, TableContent } from '../components/interfaces/dash-table'
import { DashMainComponent } from '../dash-main/dash-main.component'
import deepCopy from 'app/util/processing/deepcopy'
import { HttpService } from 'app/shared/services/http.service'

@Component({
  selector: 'app-dash-rms',
  templateUrl: './dash-rms.component.html',
  styleUrls: ['./dash-rms.component.scss']
})
export class DashRmsComponent extends DashMainComponent implements OnInit, OnDestroy {

  isLoading: boolean
  isTableLoading: boolean = false
  isTableExportLoading: boolean = false

  placeholderCompanies: string = 'Selecione a(s) empresa(s)'

  periodSelected: any
  firstTabSelected: any
  secondTabSelected: any
  threeTabSelected: any

  loadingOptCompanies: boolean
  loadingOptIconCompanies: boolean

  tableRequester: TableContent
  tableApprover: TableContent
  tableApprovalDetails: TableContent

  customExport: Function = () => {}

  statusLabel: Array<string> = new Array()
  typeLabel: Array<string> = new Array()
  ccusLabel: Array<string> = new Array()
  projectLabel: Array<string> = new Array()
  availableCompanies: Array<SelectItem> = new Array()
  selectedCompanies: Array<number> = new Array()

  monthlyEvolutionBase: Array<string> = new Array()
  monthlyEvolutionData: Array<ColumnChart> = new Array()
  priorityData: Array<PieChart> = new Array()
  companyData: Array<PieChart> = new Array()
  statusData: Array<BarChart> = new Array()
  typeData: Array<BarChart> = new Array()
  ccusData: Array<BarChart> = new Array()
  projectData: Array<BarChart> = new Array()
  defaultCols: Array<TableControl> = new Array()
  defaultColsDetails: Array<TableControl> = new Array()
  periodOptions: Array<TabChanger> = new Array()
  firstTab: Array<TabChanger> = new Array()
  secondTab: Array<TabChanger> = new Array()
  threeTab: Array<TabChanger> = new Array()

  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService,
    private configService: ConfigService,
    private sessionService: SessionService,
    public dblinkedSessionService: DblinkedSessionService,
    public messageService: MessageService
  ) { super(messageService, dblinkedSessionService) }

  public async ngOnInit(): Promise<void> {
    this.startTabs()
    this.initialize()
  }

  public ngOnDestroy(): void {
    this.startVars()
  }

  private async initialize(): Promise<void> {
    this.isLoading = true
    await this.getCompanyGroup()
    this.getInformations(false)
  }

  public async getInformations(isLoading: boolean): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi
      })
    }

    const payload = {
      list_empresas_id: this.selectedCompanies,
      dtInicial: this.getDate('initial', this.periodSelected),
      dtFinal: this.getDate('final', this.periodSelected)
    }

    if(isLoading) this.isLoading = true

    try {
      await this.httpClient.post(`${this.configService.defaultUrlApiUpload}homepage/dash-rms`, payload, httpOptions).toPromise().then(res => {
          if (!res['error']) {
            this.startVars()
            this.getDetails()

            res['data'].requisitante.data.forEach(item => {
              this.tableRequester.rows.push({ desc: item.desc.toUpperCase(), qtd: item.qtd, perc: item.porcentagem })
              this.tableRequester.cols[1].footerValue += item.qtd
            })

            res['data'].aprovador.data.forEach(item => {
              this.tableApprover.rows.push({ desc: item.desc.toUpperCase(), qtd: item.qtd, perc: item.porcentagem })
              this.tableApprover.cols[1].footerValue += item.qtd
            })

            res['data'].prioridade.data.forEach(item => {
              this.priorityData[0].data.push([item.desc.toUpperCase(), item.qtd])
            })

            res['data'].empresa.data.forEach(item => {
              this.companyData[0].data.push([item.desc.toUpperCase(), item.qtd])
            })

            res['data'].status.data.forEach(item => {
              this.statusLabel.push(item.desc.toUpperCase())
              this.statusData[0].data.push(item.qtd)
            })

            res['data'].tipo.data.forEach(item => {
              this.typeLabel.push(item.desc.toUpperCase())
              this.typeData[0].data.push(item.qtd)
            })

            res['data'].ccus.data.forEach(item => {
              this.ccusLabel.push(item.desc.toUpperCase())
              this.ccusData[0].data.push(item.qtd)
            })

            res['data'].proj.data.forEach(item => {
              this.projectLabel.push(item.desc.toUpperCase())
              this.projectData[0].data.push(item.qtd)
            })

            this.monthlyEvolutionBase = res['data'].evolucao.base_list

            this.monthlyEvolutionData[0].data = res['data'].evolucao.quantidade_mensal
            this.monthlyEvolutionData[1].data = res['data'].evolucao.media_diaria_mensal
          } else this.showErrorResponse(res['mensagem'])
        }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao carregar os dados da dashboard! Contate o suporte'))
    } catch (err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.isLoading = false
    }
  }

  private async getDetails(lazyLoading: LazyLoadEvent = {}): Promise<void> {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi, }) }

    const payload = {
      listEmpresaId: [this.dblinkedSessionService.empresa.id],
      dtInicial: this.getDate('initial', this.periodSelected),
      dtFinal: this.getDate('final', this.periodSelected),
      limit: lazyLoading.rows,
      offset: lazyLoading.first,
      sort_order: lazyLoading.sortOrder,
      sort_field: lazyLoading.sortField,
      filters: lazyLoading.filters,
    }

    this.isTableLoading = true

    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'homepage/dash-rms-details', payload, httpOptions).toPromise()
        .then(res => {
          if (!res['error']) {
            this.tableApprovalDetails.rows = new Array()

            res['data'].detail.forEach(item => this.tableApprovalDetails.rows.push(item))
            this.tableApprovalDetails.totalRecords = res['data'].total_registro
            this.tableApprovalDetails.cols[16].footerValue = res['data'].total_valor
          } else this.showErrorResponse(res['mensagem'])
        }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao carregar os dados da dashboard! Contate o suporte'))
    } catch (err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.isTableLoading = false
    }
  }

  private async getCompanyGroup(): Promise<void> {
    this.loadingOptCompanies = true
    this.loadingOptIconCompanies = true

    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi, }) }

    try {
      await this.httpClient.get(`${this.configService.defaultUrlApiUpload}/cadastro/getgroupempresas/${this.dblinkedSessionService.empresa.id}-${this.sessionService.loggedUser.id}`, httpOptions).toPromise().then(res => {
        if(res['data'].length) res['data'].forEach(item => {
          this.availableCompanies.push({ label: item.fantasia, value: item.id })
        })
        else this.availableCompanies.push({ label: this.dblinkedSessionService.empresa.nomeFantasia, value: this.dblinkedSessionService.empresa.id })
      }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao carregar o grupo de empresas! Contate o suporte'))
    } catch(err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.placeholderCompanies = this.dblinkedSessionService.empresa.nomeFantasia
      this.selectedCompanies.push(this.dblinkedSessionService.empresa.id)
      this.loadingOptCompanies = false
      this.loadingOptIconCompanies = false
    }
  }

  public async exportDetails() {
    const httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi, }) }

    const httpOptions2 = {
      headers: new HttpHeaders({
        Authorization: this.configService.bearerApi,
        userId: this.sessionService.loggedUser.id.toString(),
        listEmpresaId: this.selectedCompanies.toString()
      }),
      responseType: 'blob' as 'json'
    }

    const payload = {
      listEmpresaId: this.selectedCompanies,
      userId: this.sessionService.loggedUser.id,
      dtInicial: this.getDate('initial', this.periodSelected),
      dtFinal: this.getDate('final', this.periodSelected)
    }

    this.isTableExportLoading = true

    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + '/homepage/dash-rms/relat/', payload, httpOptions).toPromise().then(res => {
        if(!res["error"]) this.httpClient.get(this.configService.defaultUrlApiUpload + '/homepage/dash-rms/relat/', httpOptions2).toPromise().then((res: any) => {
          let link = document.createElement('a')
          let url = window.URL.createObjectURL(res)
          link.href = url
          link.download = "relatorio-detalhes-rms.xlsx"
          link.click()
          window.URL.revokeObjectURL(url)
        }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao recuperar seu relatório! Contate o suporte'))
      }).catch(err => this.showErrorResponse(err.status + ' - Houve um erro ao gerar seu relatório! Contate o suporte'))
    } catch(err) {
      this.showErrorResponse('Falha na comunicação com o servidor!')
    } finally {
      this.isTableExportLoading = false
    }
  }

  private startVars(): void {
    this.defaultCols = [
      { title: 'Descrição', width: 50, align: 'left', key: 'desc', type: 'text', footerValue: 'TOTAL' },
      { title: 'Quantidade', width: 25, align: 'right', key: 'qtd', type: 'text', footerValue: 0 },
      { title: 'Total', width: 25, align: 'right', key: 'perc', type: 'text', footerValue: '100%' }
    ]

    this.defaultColsDetails = new Array()

    this.statusLabel = new Array()
    this.typeLabel = new Array()
    this.ccusLabel = new Array()
    this.projectLabel = new Array()

    this.tableRequester = {
      cols: deepCopy(this.defaultCols),
      rows: new Array(),
      totalRecords: 0
    }

    this.tableApprover = {
      cols: deepCopy(this.defaultCols),
      rows: new Array(),
      totalRecords: 0
    }

    this.tableApprovalDetails = {
      title: 'Detalhes',
      subtitle: 'Registro de aprovações',
      cols: [
        { title: 'Data emissão', width: 5, align: 'left', key: 'data_emissao', type: 'text', footerValue: 'TOTAL' },
        { title: 'Data entrega', width: 5, align: 'left', key: 'data_entrega', type: 'text' },
        { title: 'Prioridade', width: 5, align: 'left', key: 'prioridade', type: 'text' },
        { title: 'Tipo', width: 5, align: 'left', key: 'tipo', type: 'text' },
        { title: 'Objeto', width: 5, align: 'left', key: 'objeto', type: 'text' },
        { title: 'Código', width: 5, align: 'left', key: 'codigo', type: 'text' },
        { title: 'Título', width: 10, align: 'left', key: 'titulo', type: 'text' },
        { title: 'Requisitante', width: 5, align: 'left', key: 'requisitante', type: 'text' },
        { title: 'Etapa de aprovação', width: 5, align: 'left', key: 'etapa_aprovacao', type: 'text' },
        { title: 'Status de aprovação', width: 5, align: 'left', key: 'status_aprovacao', type: 'text' },
        { title: 'Aprovador', width: 5, align: 'left', key: 'aprovador', type: 'text' },
        { title: 'Participante', width: 5, align: 'left', key: 'cod_part', type: 'text' },
        { title: 'Razão social', width: 5, align: 'left', key: 'razao_social', type: 'text' },
        { title: 'Centro de custo', width: 5, align: 'left', key: 'detalhe_ccus', type: 'text' },
        { title: 'Projeto', width: 5, align: 'left', key: 'detalhe_proj', type: 'text' },
        { title: 'Rateio', width: 5, align: 'right', key: 'rateio', type: 'money' },
        { title: 'Valor', width: 5, align: 'right', key: 'valor', type: 'money', footerValue: 0 },
      ],
      rows: new Array(),
      totalRecords: 0
    }

    this.priorityData = [{
      type: 'pie',
      name: 'prioridade',
      data: new Array()
    }]

    this.companyData = [{
      type: 'pie',
      name: 'empresa',
      data: new Array()
    }]

    this.statusData = [{
      type: 'bar',
      name: 'quantidade',
      data: new Array()
    }]

    this.typeData = [{
      type: 'bar',
      name: 'quantidade',
      data: new Array()
    }]

    this.ccusData = [{
      type: 'bar',
      name: 'quantidade',
      data: new Array()
    }]

    this.projectData = [{
      type: 'bar',
      name: 'quantidade',
      data: new Array()
    }]

    this.monthlyEvolutionBase = new Array()

    this.monthlyEvolutionData = [{
      type: 'column',
      name: 'Aprovações',
      data: new Array(),
    },
    {
      type: 'spline',
      name: 'Média diária',
      data: new Array(),
      color: 'rgb(192, 192, 192)',
    }]

    this.customExport = () => this.exportDetails()
  }

  private startTabs(): void {
    this.periodOptions = [
      { label: 'Mensal', value: 'month', active: true },
      { label: 'Últimos 7 dias', value: 'lastSevenDays', active: false },
      { label: 'Últimos 15 dias', value: 'lastFifteenDays', active: false },
      { label: 'Últimos 30 dias', value: 'lastThirtyDays', active: false },
    ]

    this.firstTab = [
      { label: 'Prioridade', value: 'priority', active: true },
      { label: 'Status', value: 'status', active: false },
    ]

    this.secondTab = [
      { label: 'Tipo', value: 'type', active: true },
      { label: 'Empresa', value: 'company', active: false },
    ]

    this.threeTab = [
      { label: 'Requisitante', value: 'requester', active: true },
      { label: 'Responsável', value: 'approver', active: false },
    ]

    this.periodSelected = 'month'
    this.firstTabSelected = 'priority'
    this.secondTabSelected = 'type'
    this.threeTabSelected = 'requester'
  }

  public changePeriod(event: any): void {
    this.periodSelected = event
    this.checkCompaniesList()
  }

  public changeTab(event: any, tab: number) {
    switch (tab) {
      case 1:
        this.firstTabSelected = event
        break
      case 2:
        this.secondTabSelected = event
        break
      case 3:
        this.threeTabSelected = event
        break
    }
  }

  public changeFilterDetails(lazyLoading: LazyLoadEvent = {}): void {
    this.getDetails(lazyLoading)
  }

  public checkCompaniesList(): void {
    if (this.selectedCompanies.length === 0) {
      this.showErrorResponse('Por favor, selecione pelo menos uma empresa')
      this.placeholderCompanies = 'Selecione a(s) empresa(s)'
    }
    else this.getInformations(true)
  }
}
