import { Moeda } from '../../util/model/moeda.model';
import { PlanoGerencial } from './plano-gerencial.model';
import { CodNaturezaConta } from '../../util/model/cod-natureza-conta.model';

export class PlanoGerencialContas {
  id: number;
  planoGerencial: PlanoGerencial;
  codCta: string;
  descrCta: string;
  codNaturezaConta: CodNaturezaConta;
  indCta: string;
  nivel: number;
  ctaSup: PlanoGerencialContas;
  sql: string;
  ordem: number;
  inverterSinal: boolean;
  linhaAcima: boolean;
  linhaAbaixo: boolean;
  linhaNegrito: boolean;
  isPorcentagem: boolean;
  linhaCor: string;
  comparadorCondicaoTotal: string;
  numeroCondicaoTotal: number;
  condTotal: boolean;
  indRd: string

  constructor(plano?: PlanoGerencialContas | number) {
    if (plano !== undefined && plano !== null) {
      if (typeof plano === 'number') {
        this.id = plano;
      } else {
        this.id = plano.id;
        this.planoGerencial = plano.planoGerencial;
        this.codCta = plano.codCta;
        this.descrCta = plano.descrCta;
        this.codNaturezaConta = plano.codNaturezaConta;
        this.indCta = plano.indCta;
        this.nivel = plano.nivel;
        this.ctaSup = plano.ctaSup;
        this.sql = plano.sql;
        this.ordem = plano.ordem;
        this.inverterSinal = plano.inverterSinal
        this.linhaAcima = plano.linhaAcima;
        this.linhaAbaixo = plano.linhaAbaixo;
        this.linhaNegrito = plano.linhaNegrito;
        this.isPorcentagem = plano.isPorcentagem;
        this.linhaCor = plano.linhaCor;
        this.comparadorCondicaoTotal = plano.comparadorCondicaoTotal;
        this.numeroCondicaoTotal = plano.numeroCondicaoTotal;
        this.condTotal = plano.condTotal;
        this.indRd = plano.indRd;
      }
    }
  }
}
