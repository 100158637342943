import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Message, MessageService } from 'primeng/api';
import { GrupoEmpresa } from '../model/grupo-empresa.model';
import { Empresa } from '../model/empresa.model';

@Component({
  selector: 'app-cadastro-grupo-empresa.component',
  templateUrl: './cadastro-grupo-empresa.component.html',
  styleUrls: ['./cadastro-grupo-empresa.component.scss'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadastroGruposEmpresasComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  msgs: Message[] = [];
  idEditGrupo: number = null;
  codigoGrupoNew: number = null;
  codigoEditGrupo: number = null;
  descricaoGrupoNew: string = null;
  descricaoEditGrupo: string = null;
  modalNewGroup = false;
  modalEditGrupo = false;
  rowSelected = false;
  listaGruposEmpresas: Array<GrupoEmpresa> = [];
  listaEmpresasDisponiveis: Array<Empresa> = [];
  listaEmpresasVinculadas: Array<Empresa> = [];

  isCardGrupo = true;
  isCardRelacionar = true;

  picklistState: boolean = false

  listaRelacionar: Array<any> = [];
  listaRelacionados: Array<any>;
  grupoSelecionado: any;

  currentStateGrupo = 'initial';
  currentStateRelacionar = 'initial';

  changeStateGrupo() {
    this.currentStateGrupo = this.currentStateGrupo === 'initial' ? 'final' : 'initial';
  }

  changeStateRelacionar() {
    this.currentStateRelacionar = this.currentStateRelacionar === 'initial' ? 'final' : 'initial';
  }

  constructor(private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private messageService: MessageService) { }

  ngOnInit(): void {

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titlePrincipal = 'Cadastros de Grupos';
    this.subtitle = 'Definição de Grupos de Empresas';
    this.modalNewGroup = false;

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  hideMessage() {
    this.msgs = [];
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

      this.rowSelected = false;
      this.modalNewGroup = false;
      this.modalEditGrupo = false;
      this.listaGruposEmpresas = [];
      this.listaEmpresasDisponiveis = [];
      this.listaEmpresasVinculadas = [];
      this.getGrupos();
    }
  }

  // Método que busca as empresas que o usuário tem acesso
  async getEmpresasRelacionadasUsuario() {

    const data = {
      Authorization: this.configService.bearerApi,
      userid: this.sessionService.loggedUser.id,
    };

    this.picklistState = false

    this.httpService.wait();
    const response = await this.httpService.post('/custom/cadastro/get-empresas-relacionadas', data).toPromise();

    response.empresas.map((empresa: Empresa) => {
      this.listaEmpresasDisponiveis.push(empresa);
    });

    this.picklistState = true

    this.httpService.done();
  }

  // Método que busca as empresas que o usuário vinculou ao grupo selecionado
  async getEmpresasVinculadoasUsuario(id: number) {

    const data = {
      Authorization: this.configService.bearerApi,
      grupoid: id
    };

    this.httpService.wait();
    const response = await this.httpService.post('/custom/cadastro/get-empresas-vinculadas', data).toPromise();

    response.empresas.map((empresa: Empresa) => {
      this.listaEmpresasVinculadas.push(empresa);
    });

    this.httpService.done();
  }

  // Método que deverá trazer os novos grupos
  async getGrupos() {

    const data = {
      userid: this.sessionService.loggedUser.id,
    };

    this.httpService.wait();
    const response = await this.httpService.post('/custom/cadastro/get-grupos-empresas', data).toPromise();

    response.grupos.map((grupo: GrupoEmpresa) => {
      this.listaGruposEmpresas.push(grupo);
    });

    this.httpService.done();
  }

  // Método que deverá salvar a relação feita entre o grupo e as empresas selecionadas
  async salvarRelacao() {

    const empresas: Array<Empresa> = this.listaEmpresasVinculadas.filter((empresa: Empresa): boolean => {
      return empresa !== null ? true : false;
    });

    const data = {
      userid: this.sessionService.loggedUser.id,
      grupoid: this.grupoSelecionado.id,
      empresasVinculadas: empresas,
      empresasDisponiveis: this.listaEmpresasDisponiveis,
    };

    this.httpService.wait();
    const response = await this.httpService.post('/custom/cadastro/salvar-relacao-grupo-empresa', data).toPromise();

    this.exibirMensagem(response);
    this.httpService.done();
    this.change();
  }

  // Método de abrir modal para criar novo grupo
  openNovoGrupo() {

    this.modalNewGroup = true;
    this.codigoGrupoNew = null;
    this.descricaoGrupoNew = null;
  }

  // Método de salvar novo grupo
  async addNovoGrupo() {

    const isValid = this.validateInput(this.codigoGrupoNew, this.descricaoGrupoNew);

    if (isValid) {

      const data = {
        userid: this.sessionService.loggedUser.id,
        codigoGrupo: this.codigoGrupoNew.toString(),
        descricaoGrupo: this.descricaoGrupoNew.toString(),
      };

      this.httpService.wait();
      const response = await this.httpService.post('/custom/cadastro/criar-novo-grupo-empresa', data).toPromise();

      this.exibirMensagem(response);
      this.httpService.done();
      this.change();
    } else {
      return;
    }
  }

  // Método de abrir edição de grupo
  openEditGrupo(grupo: GrupoEmpresa) {

    this.modalEditGrupo = true;
    this.idEditGrupo = grupo.id;
    this.codigoEditGrupo = grupo.cod_grupo;
    this.descricaoEditGrupo = grupo.descricao_grupo;
  }

  // Método que salva a edição das rows
  async editGrupo() {

    const isValid = this.validateInput(this.codigoEditGrupo, this.descricaoEditGrupo);

    if (isValid) {

      const data = {
        id: this.idEditGrupo,
        codigo: this.codigoEditGrupo,
        descricao: this.descricaoEditGrupo.toString(),
      };

      this.httpService.wait();
      const response = await this.httpService.post('/custom/cadastro/editar-grupo-empresa', data).toPromise();

      this.exibirMensagem(response);
      this.httpService.done();
      this.change();
    } else {
      return;
    }
  }

  // Método que exclui grupo
  async excluirGrupo(grupo: GrupoEmpresa) {
    if (confirm('Confirmar exclusão desse Grupo?')) {

      const data = {
        grupoid: grupo.id
      };

      this.httpService.wait();
      const response = await this.httpService.post('/custom/cadastro/delete-grupo-empresa', data).toPromise();

      this.exibirMensagem(response);
      this.httpService.done();
      this.change();
    }
  }

  // Método que abre o card de vinculação de empresas e distribui o id selecionado para os métodos que precisam
  onRowSelect(grupo: GrupoEmpresa) {

    this.rowSelected = true;
    this.grupoSelecionado = grupo;
    this.listaEmpresasDisponiveis = [];
    this.listaEmpresasVinculadas = [];
    this.getEmpresasRelacionadasUsuario();
    this.getEmpresasVinculadoasUsuario(grupo.id);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  /*Show Hide Grupo*/
  ShowHideGrupo() {
    this.isCardGrupo = !this.isCardGrupo;
  }

  /*Show Hide Relacionar*/
  ShowHideRelacionar() {
    this.isCardRelacionar = !this.isCardRelacionar;
  }

  // Método auxiliar de exibição do popUp de mensagens
  exibirMensagem({ error, detail }) {
    if (error) { this.messageService.add({ key: 'messageLinnks', severity: 'warn', summary: 'Erro', detail: detail }); }
    else { this.messageService.add({ key: 'messageLinnks', severity: 'success', summary: 'Sucesso', detail: detail }); }
  }

  // Método de validação de input do usuário
  validateInput(cod: number, description: string): boolean {
    if (!cod) {

      const message = {
        error: true,
        detail: `O campo 'Código' é obrigatório.`
      };

      this.exibirMensagem(message);
      return false;

    } else if (!description) {

      const message = {
        error: true,
        detail: `O campo 'Descrição' é obrigatório.`
      };

      this.exibirMensagem(message);
      return false;
    }
    return true;
  }
}




