import { AuthUser } from 'app/proof/model/auth-user.model';
import { TodoCard } from './todo-card.model';

export class TodoCardComentarios {
  id: number;
  todoCard: TodoCard;
  mensagem: string;
  usuario: AuthUser;
  editado: boolean;
  changeTimestamp: Date;

  constructor(todoCardComentarios?: TodoCardComentarios | number) {
    if (todoCardComentarios !== undefined && todoCardComentarios !== null) {
      if (typeof todoCardComentarios === 'number') {
        this.id = todoCardComentarios;
      } else {
        this.id = todoCardComentarios.id;
        this.todoCard = todoCardComentarios.todoCard;
        this.mensagem = todoCardComentarios.mensagem;
        this.usuario = todoCardComentarios.usuario;
        this.editado = todoCardComentarios.editado;
        this.changeTimestamp = todoCardComentarios.changeTimestamp;
      }
    }
  }
}
