import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { ViewCampos } from 'app/cadastro/model/view-campos.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { Empresa } from '../model/empresa.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
  selector: 'app-view-campos',
  templateUrl: './view-campos.component.html',
  styleUrls: ['./view-campos.component.scss'],
  providers: [CrudService]
})
export class ViewCamposComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public viewcampos: ViewCampos = new ViewCampos();
  public registroView: Array<ViewCampos> = new Array();

  ids: Array<any> = new Array();
 
  //Boolean
  ctrlSave: boolean;
  editarConta: boolean;
  novaConta: boolean;

  //Strings
  viewCampo: string;
  viewTipo: string;

  //Any
  viewAlias: any;
  viewDescricao: any;
  viewView: any;

  //Select
  opcoesViewCampos: SelectItem[]
  opcoesM: SelectItem[]
 
  msgs: Message[];

  title = 'VIEW';
  subtitle = "VIEW";
  
  private sessionSubscription: Subscription;
  private beforeSaveSubscription: Subscription;
  private beforeViewSubscription: Subscription;
  private beforeEditSubscription: Subscription;

  entityFilter: string = null;
  getTitulosFinanceiros: any;

  tollbarMaini = []

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private utilService: UtilService,
    private configService: ConfigService,
    ) {
    super(crudService);
  }

  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.carregar() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.loadEditarRegistro() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirRegistro() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.criarNovo() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarNovoRegistro() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvarAlteracaoRegistro() },
  ]



  ngOnInit() {
    // TODO: REVIEW (IMPORTANT!)
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => this.carregar()
    );
  }

  list: Array<number> = new Array();


  selectCheckboxContainer(data){
    setTimeout(() => {
      document.getElementById('checkboxContainer'+data.id).click();
    },100);
  };

 
  carregar() {
    this.title = 'VIEW CAMPOS';
    this.subtitle = 'VIEW CAMPOS';
    this.novaConta = false;
    this.editarConta = false;
    this.ids = new Array();
    this.limpaCamposForm();
    this.loadRegistros();

    this.toolbarMain.setVisible('btn1', false)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', true)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', false)
  }

  loadItems() {
    this.opcoesViewCampos = [];
    this.opcoesViewCampos.push({label: 'Selecione', value: 0 },{label: 'Nenhum', value: 0 });
    this.httpService.get(`view?$select=id,nome&$orderby=nome`)
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesViewCampos.push({
            label: element.nome,
            value: element.id
          });
        });
      }, error => this.httpService.handleError(error, () => this.httpService.done()));
  }
  loadM() {
    this.opcoesM = [];
    this.opcoesM.push({label: 'Selecione', value: null },{label: 'Todos', value: null }, {label: 'Nenhum', value: 0});
    this.httpService.get(`view?$select=id,nome&$orderby=nome`)
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesM.push({
            label: element.nome,
            value: element.id
          });
        });
      }, error => this.httpService.handleError(error, () => this.httpService.done()));
  }

  criarNovo() {
    this.loadItems
    this.loadM
    this.novaConta = true;
    this.title = 'Cadastrar View Campos';

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', true)
    this.toolbarMain.setVisible('btn6', false)
  }

  loadRegistros() {
    this.loadM();
    this.loadItems();
    this.httpService
      .get(`/viewcampos?$select=campo,aliasCampo,descricao,tipo,view/id,view/nome&$count=true&$skip=0&$orderby=campo`)
      .subscribe(
        (retorno) => {
          this.registroView = new Array();
          retorno.value.forEach(item => {
            this.registroView.push({
              ...item,
            });
          })
        },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done()
      );
  }

  loadEditarRegistro() {
    this.title = 'Editar View Campos';
    this.editarConta = true;

    this.toolbarMain.setVisible('btn1', true)
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', true)
    this.toolbarMain.setVisible('btn4', false)
    this.toolbarMain.setVisible('btn5', false)
    this.toolbarMain.setVisible('btn6', true)

    this.limpaCamposForm();
    this.httpService.get(`/viewcampos(${this.ids[0]})?$select=campo,aliasCampo,descricao,tipo,view/id,view/nome&$count=true`)
      .subscribe(plano => {
        this.viewCampo = plano.value.campo;
        this.viewAlias = plano.value.aliasCampo;
        this.viewTipo = plano.value.tipo;
        this.viewDescricao = plano.value.descricao;
        this.viewView = plano.value.view.id;
      },
        error => this.httpService.handleError(error, () => this.carregar()),
        () => this.httpService.done());
  }

  salvarNovoRegistro() {
    if (this.validacao()) {
      return;
    }

    const jsonData = {
      campo: this.viewCampo,
      tipo: this.viewTipo,
      aliasCampo: this.viewAlias,
      descricao: this.viewDescricao,
      view: {id: this.viewView}
    };
   
    this.httpService.wait();
    this.httpService.post('/viewcampos', jsonData)
      .subscribe(result => {

        if (!(result === null || result === undefined)) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto cadastrado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha no cadastro. Tente novamente'
          });
          this.carregar();
          return;
        }
      }, error => this.httpService.handleError(error, () => this.salvarNovoRegistro()),
        () => this.httpService.done());
  }

  salvarAlteracaoRegistro() {
    if (this.validacao()) {
      return;
    }

    const listasEnviar = {
      id: this.ids[0],
      campo: this.viewCampo,
      tipo: this.viewTipo,
      aliasCampo: this.viewAlias,
      descricao: this.viewDescricao,
      view: {id: this.viewView}
    };

    let key = this.ids[0];

    this.httpService.wait();
    this.httpService.patch('/viewcampos', key, listasEnviar, null)
      .subscribe(result => {
        if (result === null) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks', summary: 'Sucesso  ', detail: 'Projeto alterado com sucesso!'
          });
          this.carregar();
        } else {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Falha na alteração do projeto!'
          });
          this.carregar();
          return;
        }
      },
        error => this.httpService.handleError(error, () => this.salvarAlteracaoRegistro()),
        () => this.httpService.done());
  }

  excluirRegistro() {
    if (confirm('Deseja deletar os registros selecionados? ')) {
      const listasEnviar = {
        idsContaContabil: this.ids,
      };
      this.httpService.wait();
      this.httpService.delete('/viewcampos', this.ids)
        .subscribe(result => {
          if (result === null) {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: '',
              detail: 'Projeto excluído com sucesso!'
            });
            this.carregar();
            this.ids = [];
          } else {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: '',
              detail: 'Falha na exclusão. Tente novamente!'
            });
          }
        },
          error => this.httpService.handleError(error, () => this.carregar()),
          () => this.httpService.done());
    }
  }

  selectCheckbox(event: any) {
    this.ids.push(event.data.id);
    event.selected = false;
    this.changeToolbar()
  }

  unselectCheckbox(event: any) {
    const index = this.ids.findIndex(c => c === event.data.id);
    this.ids.splice(index, 1);
    event.selected = true;
    this.changeToolbarUn()
  }

  changeToolbar() {
    this.toolbarMain.setVisible('btn2', true)
    this.toolbarMain.setVisible('btn3', true)
  }

  changeToolbarUn(){
    this.toolbarMain.setVisible('btn2', false)
    this.toolbarMain.setVisible('btn3', false)
  }

  validacao(): boolean {
    if ((this.viewCampo === null || this.viewCampo === undefined) || this.viewCampo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Campo é Obrigatório' });
      return true;
    }
    if ((this.viewAlias === null || this.viewAlias === undefined) || this.viewAlias.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Alias é Obrigatório' });
      return true;
    }
    if ((this.viewDescricao === null || this.viewDescricao === undefined) || this.viewDescricao.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Descrição é Obrigatório' });
      return true;
    }
    if ((this.viewTipo === null || this.viewTipo === undefined) || this.viewTipo.trim().length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Tipo é Obrigatória' });
      return true;
    } 
    if ((this.viewView === null || this.viewView === undefined) || this.viewView.length < 1) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'View é Obrigatória' });
      return true;
    } 
    return false;
  }

  private limpaCamposForm() {
    this.viewCampo = '';
    this.viewAlias = '';
    this.viewDescricao = '';
    this.viewTipo = '';
    this.viewView ='';
  }

  ngOnDestroy() {
    // TODO: REVIEW (IMPORTANT!)
    this.sessionSubscription.remove(this.sessionSubscription);
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }
  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }
  get loading(): boolean {
    return this.httpService.loading;
  }
  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }
  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id ? this.dblinkedSessionService.empresa : null;
  }
}
