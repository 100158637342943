import { AuthUser } from 'app/proof/model/auth-user.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { GrupoFluxoCaixa } from './fluxo_caixa_grupo';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';

export class FluxoCaixa {
  id: number;
  nomeFluxo: string;
  excluirFluxo: boolean;
  empresa: Empresa;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;
  grupoFluxoCaixa: Array<GrupoFluxoCaixa>;
  empresaContas: Array<EmpresaConta>;

  constructor(fluxoCaixa?: FluxoCaixa | number) {
    if (fluxoCaixa !== undefined && fluxoCaixa !== null) {
      if (typeof fluxoCaixa === 'number') {
        this.id = fluxoCaixa;
      } else {
        this.id = fluxoCaixa.id;
        this.nomeFluxo = fluxoCaixa.nomeFluxo;
        this.excluirFluxo = fluxoCaixa.excluirFluxo;
        this.empresa = fluxoCaixa.empresa;
        this.createTimestamp = fluxoCaixa.createTimestamp;
        this.changeUser = fluxoCaixa.changeUser;
        this.changeTimestamp = fluxoCaixa.changeTimestamp;
        this.grupoFluxoCaixa = fluxoCaixa.grupoFluxoCaixa;
        this.empresaContas = fluxoCaixa.empresaContas;
      }
    }
  }
}
