import { Regra } from 'app/cadastro/model/regra.model';
import { OperadoresLogicos } from 'app/util/model/operadores-logicos.model';
import { RegraCondicao } from 'app/cadastro/model/regra-condicao.model';

export class RegraGrupo {
  id: number;
  regra: Regra;
  operadoresLogicos: OperadoresLogicos;
  regraCondicao: Array<RegraCondicao>;

  constructor(regraGrupo?: RegraGrupo | number) {
    if (regraGrupo !== undefined && regraGrupo !== null) {
      if (typeof regraGrupo === 'number') {
        this.id = regraGrupo;
      } else {
        this.id = regraGrupo.id;
        this.regra = regraGrupo.regra;
        this.operadoresLogicos = regraGrupo.operadoresLogicos;
        this.regraCondicao = regraGrupo.regraCondicao;
      }
    }
  }
}
