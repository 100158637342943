export class TipoChavePix {
  id: number;
  descricao: string;

  constructor(tipoChavePix?: TipoChavePix | number) {
    if (tipoChavePix !== undefined && tipoChavePix !== null) {
      if (typeof tipoChavePix === 'number') {
        this.id = tipoChavePix;
      } else {
        this.id = tipoChavePix.id;
        this.descricao = tipoChavePix.descricao;
      }
    }
  }
}