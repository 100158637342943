import { Empresa } from './empresa.model';

export class EmpresaAlmoxarifado {
  id: number;
  codigo: string;
  descricao: string;
  parent: EmpresaAlmoxarifado;
  empresa: Empresa;

  constructor(empresaAlmoxarifado?: EmpresaAlmoxarifado | number) {
    if (empresaAlmoxarifado !== undefined && empresaAlmoxarifado !== null) {
      if (typeof empresaAlmoxarifado === 'number') {
        this.id = empresaAlmoxarifado;
      } else {
        this.id = empresaAlmoxarifado.id;
        this.codigo = empresaAlmoxarifado.codigo;
        this.descricao = empresaAlmoxarifado.descricao;
        this.parent = empresaAlmoxarifado.parent;
        this.empresa = empresaAlmoxarifado.empresa;
      }
    }
  }
}
