import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Empresa } from '../model/empresa.model';
import { EmpresaConta } from '../model/empresa-conta.model';
import { HttpService } from 'app/shared/services/http.service';
import { InstituicaoFinanceira } from 'app/util/model/instituicao-financeira.model';
import { Message, MessageService } from 'primeng/api';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { TipoConta } from 'app/util/model/tipo-conta.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { isNullOrUndefined } from 'util';
import { GrupoTributacao } from '../model/grupo-tributacao.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';
declare var $: any;

@Component({
  selector: 'app-grupo-tributacao-ref',
  templateUrl: './grupo-tributacao-ref.component.html',
  styleUrls: [
    './grupo-tributacao-ref.component.scss'
  ],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class GrupoTributacaoRefComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) {}

  private sessionSubscription: Subscription; // change session event;

  title = '';
  subtitle = '';

  lista: any;
  grupo: any;
  itensRelacionar: any;
  itensRelacionados: any;
  aliquotasRelacionar: any;
  aliquotasRelacionadas: any;

  perfisFiscaisRelacionar: Array<Object>
  perfisFiscaisRelacionados: Array<Object>

  empresaId: number = null

  empresaVinculada: boolean = false

  empresaConta: GrupoTributacao;


  // components;
  showLista = false;
  showNew = false;
  editar = false;


  // msgs;
  msgs: Message[] = [];

  defaultDate: Date;
  pt_BR: any;

  // expand and hide card
  currentStateSelectItem= 'initial';
  currentStateItemSelecionado= 'initial';
  currentStateItens= 'initial';
  currentStateAliquota = 'initial';

  changeStateSelectItem() {
    this.currentStateSelectItem = this.currentStateSelectItem === 'initial' ? 'final' : 'initial';
  }

  changeStateItemSelecionado() {
    this.currentStateItemSelecionado = this.currentStateItemSelecionado === 'initial' ? 'final' : 'initial';
  }

  changeStateItens() {
    this.currentStateItens = this.currentStateItens === 'initial' ? 'final' : 'initial';
  }

  changeStateAliquota() {
    this.currentStateAliquota = this.currentStateAliquota === 'initial' ? 'final' : 'initial';
  }

  public toolbarMain = new Toolbar()
  public toolbarMainItemS = new Toolbar()
  public toolbarMainItemD = new Toolbar()
  public toolbarMainA = new Toolbar()

  toolbarMainIni = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.exportarGruposTributacao() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.criarGrupoTributacao() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideSelectItem()
        this.changeStateSelectItem()
      }
    },
  ]

  toolbarMainIniItemS = [
    { icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: false, color: 'default', onClick: () => this.voltarListaItens() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'default', onClick: () => this.salvarGrupoTributacao() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideItemSelecionado()
        this.changeStateItemSelecionado()
      }
    },
  ]

  toolbarMainIniItemD = [
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideItens()
        this.changeStateItens()
      }
    },
  ]
  toolbarMainIniA = [
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideAliquota()
        this.changeStateAliquota()
      }
    },
  ]


  ngOnInit() {

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.changeSession()
    })

    this.defaultDate = new Date();
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };

  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  private changeSession() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarMainItemS.setAll(this.toolbarMainIniItemS)
    this.toolbarMainItemD.setAll(this.toolbarMainIniItemD)
    this.toolbarMainA.setAll(this.toolbarMainIniA)
    this.toolbarService.hidden = true
    this.dblinkedSessionService.hidePeriods()
    Promise.resolve(null).then(() => (this.toolbarService.hidden = true))
    this.title = 'GRUPO TRIBUTAÇÃO'
    this.editar = false
    this.grupo = new GrupoTributacao()
    this.grupo.id = 0
    this.grupo.codigo = ''
    this.grupo.descricao = ''
    this.grupo.itens = new Array()
    this.grupo.aliquotas = new Array()
    this.itensRelacionados = new Array()
    this.itensRelacionar = new Array()
    this.aliquotasRelacionadas = new Array()
    this.aliquotasRelacionar = new Array()
    this.perfisFiscaisRelacionar = new Array()
    this.perfisFiscaisRelacionados = new Array()
    this.empresaVinculada = false

    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.empresaId = this.dblinkedSessionService.empresa.id

      this.editar = false
      this.grupo.empresa = new Empresa()
      this.grupo.empresa.id = this.dblinkedSessionService.empresa.id

      this.obterEmpresaFamilia()

      this.httpService.wait()
      this.httpService.get('custom/cadastro/get-grupos-tributacao', [this.dblinkedSessionService.empresa.id]).subscribe(
        retorno => this.lista = retorno,
        error => this.httpService.handleError(error, () => this.changeSession()),
        () => this.httpService.done()
      )
    }
  }


  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  verGrupo(value: any){

    console.log(value);
    this.editar = true;
    this.grupo = value;
    this.itensRelacionados = [];
    this.aliquotasRelacionadas = [];
    if (this.empresaVinculada) this.recuperarGrupoTributacaoPerfilFiscal()


    this.httpService.wait();
    this.httpService.get('custom/cadastro/get-aliq-itens-tributacao', [this.dblinkedSessionService.empresa.id, this.grupo.id])
    .subscribe(retorno => {

     this.itensRelacionar = [];
     this.aliquotasRelacionar = [];

     this.itensRelacionar = retorno.itens;
     this.aliquotasRelacionar = retorno.aliquotas;


     value.itensRelacionados.forEach(element => {
      this.itensRelacionados.push(element);
  });

  value.aliquotasRelacionadas.forEach(element => {
    this.aliquotasRelacionadas.push(element);
});

     }, error => this.httpService.handleError(error, () => this.changeSession()),
     () => this.httpService.done());
  }

  /*Show Hide isCardItens*/
  isCardSelectItem: boolean = true;
  ShowHideSelectItem(){
    this.isCardSelectItem = !this.isCardSelectItem;
    this.toolbarMain.setIcon('btn4', this.isCardSelectItem ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn4', this.isCardSelectItem ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide isCardItens*/
  isCardItemSelecionado: boolean = true;
  ShowHideItemSelecionado(){
    this.isCardItemSelecionado = !this.isCardItemSelecionado;
    this.toolbarMainItemS.setIcon('btn4', this.isCardItemSelecionado ? 'expand_less' : 'expand_more')
    this.toolbarMainItemS.setTooltip('btn4', this.isCardItemSelecionado ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide isCardItens*/
  isCardItens: boolean = true;
  ShowHideItens(){
    this.isCardItens = !this.isCardItens;
    this.toolbarMainItemD.setIcon('btn1', this.isCardItens ? 'expand_less' : 'expand_more')
    this.toolbarMainItemD.setTooltip('btn1', this.isCardItens ? 'Ver menos' : 'Ver mais')
  }

  /*Show Hide isCardItens*/
  isCardAliquota: boolean = true;
  ShowHideAliquota(){
    this.isCardAliquota = !this.isCardAliquota;
    this.toolbarMainA.setIcon('btn1', this.isCardAliquota ? 'expand_less' : 'expand_more')
    this.toolbarMainA.setTooltip('btn1', this.isCardAliquota ? 'Ver menos' : 'Ver mais')
  }

  voltarListaItens() {
    this.editar = false;
  }

  salvarGrupoTributacao(){

    if(this.empresaVinculada && this.perfisFiscaisRelacionados.length > 1){
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: "Apenas 1(um) perfil fiscal pode ser viculado"})
      return false
    }

    if(confirm('Deseja salvar este grupo?')){

    let itens = [];

    this.itensRelacionados.forEach(element => {
      itens.push(element.id);
    });

    let aliquotas = [];

    this.aliquotasRelacionadas.forEach(element => {
      aliquotas.push(element.id);
    });


    const listasEnviar = {
      grupo: this.grupo,
      aliquotasRelacionadas: aliquotas,
      itensRelacionados: itens,
      usuarioId: this.sessionService.loggedUser.id,
      empresa: this.dblinkedSessionService.empresa.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/salvar-grupo-tributacao', listasEnviar)
      .subscribe(result =>  {
        if(!result.error){
          if(this.empresaVinculada) this.vincularGrupoTributacaoPerfilFiscal()
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Msg: ', detail: result.message});
          this.changeSession();
        } else {
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: result.message});
        }

      },
        error => this.httpService.handleError(error, () => this.salvarGrupoTributacao()),
        () => this.httpService.done());

    }

  }

  criarGrupoTributacao(){
    this.editar = true;
    this.grupo = new GrupoTributacao();
    this.grupo.id = 0;
    this.grupo.codigo = '';
    this.grupo.descricao = '';
    this.grupo.itens = new Array();
    this.grupo.aliquotas = new Array();
    this.itensRelacionados = new Array();
    this.aliquotasRelacionadas = new Array();
    this.grupo.empresa = new Empresa();
    this.grupo.empresa.id = this.dblinkedSessionService.empresa.id;

    this.httpService.wait();
    this.httpService.get('custom/cadastro/get-aliq-itens-tributacao', [this.dblinkedSessionService.empresa.id, this.grupo.id])
    .subscribe(retorno => {

     this.itensRelacionar = [];
     this.aliquotasRelacionar = [];

     this.itensRelacionar = retorno.itens;
     this.aliquotasRelacionar = retorno.aliquotas;

     }, error => this.httpService.handleError(error, () => this.changeSession()),
     () => this.httpService.done());

  }

  recuperarGrupoTributacaoPerfilFiscal(){

    const httpOptions = { headers: new HttpHeaders({'Authorization': this.configService.bearerApi }) }

    this.httpService.wait()
    this.httpClient.get(this.configService.octaremoteUrl + `/eyemobile/perfil-fiscal?empresaId=${this.empresaId}&grupoTribId=${this.grupo.id}`, httpOptions)
    .subscribe(
      res => {
        if (res["codigo"] === 200) {
          this.perfisFiscaisRelacionar = res['data'].lidisponiveis
          this.perfisFiscaisRelacionados = res['data'].livinculados
        } else {
          this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: "Houve um erro ao carregar os perfis fiscais, contate o suporte: " + res["message"] })
        }
        this.httpService.done()
     }, error => {
      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: '', detail: "Houve um erro ao carregar os perfis fiscais, contate o suporte: " + error.message })
      this.httpService.done()
     })
  }

  vincularGrupoTributacaoPerfilFiscal() {
    const httpOptions = { headers: new HttpHeaders({'Authorization': this.configService.bearerApi }) }

    const perfilFiscalSelecionado = this.perfisFiscaisRelacionados[0]

    const payload = {
      grupo_tributacao_id: this.grupo.id,
      perfis_fiscais: [perfilFiscalSelecionado['perfil_id']]
    }

    this.httpService.wait()
    this.httpClient.post(this.configService.octaremoteUrl + '/eyemobile/perfil-fiscal', payload, httpOptions).subscribe(
      res => {
        if(!res['error']){
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Msg: ', detail: res['message']})
          this.changeSession()
        } else this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: res['message']})
      },
      error => this.httpService.handleError(error, () => this.changeSession()),
      () => this.httpService.done()
    )
  }

  obterEmpresaFamilia() {
    const httpOptions = { headers: new HttpHeaders({'Authorization': this.configService.bearerApi }) }

    this.httpService.wait()
    this.httpClient.get(this.configService.octaremoteUrl + '/cadastro/empresa-familia?familiaId=1', httpOptions).subscribe(
      res => {
        if(res['codigo'] === 200) {
          if(res['data'].includes(this.empresaId)) {
            this.empresaVinculada = true
          }
        } else this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Msg: ', detail: res['message']})
      },
      error => this.httpService.handleError(error, () => this.changeSession()),
      () => this.httpService.done()
    )
  }

  exportarGruposTributacao() {
    this.messageService.add({ severity: 'info',key: 'messageLinnks', summary: 'Aviso: ', detail: "Seu relatório está sendo gerado e em breve estará disponível!" })

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      }),
      responseType: 'blob' as 'json'
    }

    const payload = {
      company_id: this.empresaId,
      user_id: this.sessionService.loggedUser.id
    }

    try {
      this.httpClient.post(`${this.configService.defaultUrlApiUpload}/cadastro/exportar-grupos-tribut`, payload, httpOptions).toPromise().then(
        (response: Blob) => {
          let link = document.createElement('a');
          let url = window.URL.createObjectURL(response);
          let fileName = `Relatório de Grupos de Tributacao.xlsx`;
          link.href = url;
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(url);
        }
      ).catch(error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Erro ao gerar o relatório!' }))
    } catch {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: 'Falha na comunicação com o servidor!' })
    }
  }
}
