import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Empresa } from '../model/empresa.model';
import { EmpresaConta } from '../model/empresa-conta.model';
import { HttpService } from 'app/shared/services/http.service';
import { InstituicaoFinanceira } from 'app/util/model/instituicao-financeira.model';
import { LazyLoadEvent, Message, MessageService } from 'primeng/api';
import { PlanoContas } from 'app/contabil/model/plano-contas.model';
import { SessionService } from 'app/shared/services/session.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { TipoConta } from 'app/util/model/tipo-conta.model';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { Moeda } from 'app/util/model/moeda.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { TipoChavePix } from '../model/tipo-chave-pix';

@Component({
  selector: 'app-empresa-conta-ref',
  templateUrl: './empresa-conta-ref.component.html',
  styleUrls: [
    './empresa-conta-ref.component.scss'
  ],
  providers: []
})
export class EmpresaContaRefComponent implements OnInit, OnDestroy {
  constructor(
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService,
    private utilService: UtilService,
    private messageService: MessageService
  ) { }

  private sessionSubscription: Subscription; // change session event;

  title = '';
  subtitle = '';

  lista: Array<EmpresaConta> = new Array();
  listaContas: Array<any> = new Array()
  empresaConta: EmpresaConta;

  // components;
  showLista = false;
  showNew = false;
  showEdit = false;
  modalContasUnicas: boolean = false

  // dropdowns;
  tipContaList: SelectItem[] = [];
  tipConta: number = null;
  tipoChavesPix: SelectItem[] = [{label: "CHAVE ALEATÓRIA", value: 5}, {label: "CPF", value: 1}, {label: "CNPJ", value: 2}, {label: "EMAIL", value: 3}, {label: "TELEFONE", value: 4}]
  tipoChavePixId: number = null;

  instFinanceiraList: SelectItem[] = [];
  moedasList: SelectItem[] = [];
  instFinanceira: number = null;
  moeda: number = null;

  ctContabil: number = null;
  ctaSelecionada: string = null
  opcoesAtivo: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];

  // Variável usada para a edição de conta bancária
  dataAnterior: any;

  // msgs;
  msgs: Message[] = [];

  // data;
  defaultDate: Date;
  pt_BR: any;

  ativo: boolean = true;
  pagamentosautomatizados: boolean = false;
  convenio: string = null;
  totalContas: number = null
  chavePix: string = null

  saldoInicialAnterior: any;

  public toolbarContaBanc = new Toolbar()

  toolbarMainContaBanc = [
    { icon: 'replay', text: 'Atualizar', tooltip: 'Atualizar', visible: false, disabled: false, color: 'default', onClick: () => this.changeSession() },
    { icon: 'navigate_before', text: 'Voltar', tooltip: 'Voltar', visible: false, disabled: false, color: 'default', onClick: () => this.voltar() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.salvar(false) },
    { icon: 'add', text: 'Nova', tooltip: 'Novo', visible: false, disabled: false, color: 'default', onClick: () => this.novaConta() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Novo Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvar(this.showNew) },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar Alteração de Registro', visible: false, disabled: false, color: 'default', onClick: () => this.salvar(this.showEdit) },
  ]


  ngOnInit() {
    this.toolbarContaBanc.setAll(this.toolbarMainContaBanc)
    this.toolbarService.hidden = true
    this.dblinkedSessionService.hidePeriods();
    Promise.resolve(null).then(() => this.refreshToolbar());

    this.title = 'empresa conta';

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.changeSession();
      }
    );

    this.defaultDate = new Date();
    this.pt_BR = {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.ativo = true;
    this.pagamentosautomatizados = false;
    this.convenio = '';
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  novaConta() {
    this.showLista = false
    this.showNew = true

    this.toolbarContaBanc.setVisible('btn1', false)
    this.toolbarContaBanc.setVisible('btn2', true)
    this.toolbarContaBanc.setVisible('btn3', false)
    this.toolbarContaBanc.setVisible('btn4', false)
    this.toolbarContaBanc.setVisible('btn5', true)
    this.toolbarContaBanc.setVisible('btn6', false)
    this.toolbarContaBanc.setVisible('btn7', false)
  }

  voltar() {
    this.changeSession()
    this.showLista = true
    this.showNew = false
  }

  private changeSession() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.loadComponent('lista', null);
      this.loadInstituicaoFinanceira();
      this.loadMoedas();
      this.loadTipoConta();

      this.toolbarContaBanc.setVisible('btn1', true)
      this.toolbarContaBanc.setVisible('btn2', false)
      this.toolbarContaBanc.setVisible('btn3', false)
      this.toolbarContaBanc.setVisible('btn4', true)
      this.toolbarContaBanc.setVisible('btn5', false)
      this.toolbarContaBanc.setVisible('btn6', false)
      this.toolbarContaBanc.setVisible('btn7', false)
    }
  }

  private alterarStatusAtualizar(boo: boolean) {
    this.toolbarService.alterarStatus('atualizar', !boo, 'Atualizar', () => {
      this.changeSession();
    });
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  loadComponent(component: string, rowValue: any) {

    this.toolbarContaBanc.setVisible('btn1', false)
    this.toolbarContaBanc.setVisible('btn2', true)
    this.toolbarContaBanc.setVisible('btn3', true)
    this.toolbarContaBanc.setVisible('btn4', false)
    this.toolbarContaBanc.setVisible('btn5', false)
    this.toolbarContaBanc.setVisible('btn6', true)
    this.toolbarContaBanc.setVisible('btn7', true)

    this.empresaConta = new EmpresaConta();
    this.dataAnterior = null;
    this.ativo = true;
    this.pagamentosautomatizados = false;

    // inicializando variáveis para validação posterior;
    this.empresaConta.nome = null;
    this.convenio = null;
    this.empresaConta.dtInicial = null;
    this.chavePix = null
    this.tipoChavePixId = null

    // show;
    this.showLista = component === 'lista' ? true : false;
    this.showEdit = component === 'edit' ? true : false;
    this.showNew = component === 'new' ? true : false;

    if (component === 'new') {
    } else if (component === 'lista') {
      this.subtitle = 'contas bancárias';

      // zerando as seleções nas listas;
      this.tipConta = null;
      this.instFinanceira = null;
      this.moeda = null;
      this.ctContabil = null;
      this.ctaSelecionada = null

      // recarrega objetos alterados não salvos;
      this.loadContasBancarias();
    } else if (component === 'edit') {
      this.subtitle = 'editar conta bancária';

      // definindo as seleções nas listas;
      this.tipConta = rowValue.tipoConta.id;
      this.instFinanceira = rowValue.instituicaoFinanceira !== null ? rowValue.instituicaoFinanceira.id : null;
      this.ctContabil = rowValue.planoContas !== null ? rowValue.planoContas.id : null;
      this.ctaSelecionada = rowValue.planoContas ? rowValue.planoContas.codCta + " - " + rowValue.planoContas.descrCta : null
      this.moeda = rowValue.moeda !== null ? rowValue.moeda.id : null;

      // formatação das datas;
      rowValue.dtInicial = this.milliseconds2Date(rowValue.dtInicial);
      this.dataAnterior = rowValue.dtInicial;

      //Solução para a correção da data final vazia quando a conta for editada

      //obtém a conta selecionada
      const currentConta = this.lista.filter(e => e.id == rowValue.id);

      //verifica se o array tem apenas um item (precaução)
      if (currentConta.length === 1) {
        currentConta.forEach(item => {
          //define a data final a ser exibida no input do formulário de edição
          if (item.dtFinal !== null) {
            this.empresaConta.dtFinal = item.dtFinal;
          }
        });
      }

      if (rowValue.dtFinal !== null) {
        if (!isNaN(rowValue.dtFinal)) {
          rowValue.dtFinal = this.milliseconds2Date(rowValue.dtFinal);
        } else {
          rowValue.dtFinal = null;
        }

      }

      this.empresaConta = rowValue;
      this.ativo = rowValue.ativo;
      this.pagamentosautomatizados = rowValue.pagamentosautomatizados;
      this.convenio = rowValue.convenio;
      this.chavePix = rowValue.chavePix
      this.tipoChavePixId = rowValue.tipoChavePix !== null ? rowValue.tipoChavePix.id : null

      this.saldoInicialAnterior = rowValue.saldoInicial;
      if (!this.saldoInicialAnterior) {
        this.saldoInicialAnterior = 0;
      }
    }
  }

  private loadContasBancarias() {
    this.lista = new Array();

    this.httpService.wait();
    this.httpService
      .get('/custom/cadastro/get-contas-bancarias(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(
        rst => {
          const arr = new Array();
          rst.forEach(obj => {
            const empresaConta = new EmpresaConta();

            empresaConta.id = obj.id;
            empresaConta.nome = obj.nome;

            if (empresaConta.tipoConta !== null) {
              empresaConta.tipoConta = new TipoConta();
              empresaConta.tipoConta.id = obj.tipoContaId;
              empresaConta.tipoConta.descricao = obj.tipoContaDescricao;
            } else {
              empresaConta.tipoConta = null;
            }

            if (empresaConta.instituicaoFinanceira !== null) {
              empresaConta.instituicaoFinanceira = new InstituicaoFinanceira();
              empresaConta.instituicaoFinanceira.id =
                obj.instituicaoFinanceiraId;
              empresaConta.instituicaoFinanceira.descricao =
                obj.instituicaoFinanceiraDescricao;
            } else {
              empresaConta.instituicaoFinanceira = null;
            }


            if (empresaConta.moeda !== null) {
              empresaConta.moeda = new Moeda();
              empresaConta.moeda.id =
                obj.moedaId;
              empresaConta.moeda.descricao =
                obj.moedaDescricao;
              empresaConta.moeda.codigo = obj.moedaCodigo;
            } else {
              empresaConta.moeda = null;
            }

            empresaConta.agencia = obj.agencia;
            empresaConta.dvAgencia = obj.agenciaDv;
            empresaConta.conta = obj.conta;
            empresaConta.dvConta = obj.contaDv;
            empresaConta.saldoInicial = obj.saldoInicial;
            empresaConta.saldoAtual = obj.saldoAtual;
            empresaConta.dtInicial = new Date(obj.dtInicial + 'T20:00:00');
            empresaConta.ativo = obj.ativo;
            empresaConta.pagamentosautomatizados = obj.pagamentosautomatizados;
            empresaConta.convenio = obj.convenio;
            empresaConta.codCtaPlanoContas = obj.codCtaPlanoContas;
            empresaConta.codigoTransmissao = obj.codigoTransmissao
            empresaConta.chavePix = obj.chavePix
            if (obj.tipoChavePixId !== "" || obj.tipoChavePixId !== null) {
              empresaConta.tipoChavePix = new TipoChavePix()
              empresaConta.tipoChavePix.id = obj.tipoChavePixId
            }

            empresaConta.dtFinal =
              obj.dtFinal && obj.dtFinal !== null && obj.dtFinal !== "" && obj.dtFinal !== undefined
                ? new Date(obj.dtFinal + 'T20:00:00')
                : null;

            if (empresaConta.planoContas !== null) {
              empresaConta.planoContas = new PlanoContas();
              empresaConta.planoContas.id = obj.planoContasId;
              empresaConta.planoContas.codCta = obj.codCta
              empresaConta.planoContas.descrCta = obj.descrCta
            } else {
              empresaConta.planoContas = null;
            }

            arr.push(empresaConta);
          });

          this.lista = arr;
        },
        error =>
          this.httpService.handleError(error, () => this.loadContasBancarias()),
        () => this.httpService.done()
      );
  }

  private loadInstituicaoFinanceira() {
    this.instFinanceiraList = new Array();

    this.httpService.wait();
    this.httpService
      .get(
        '/instituicaofinanceira?$select=id,codigo,descricao&$orderby=descricao'
      )
      .subscribe(
        rst => {
          rst.value.forEach(obj => {
            this.instFinanceiraList.push({
              label: obj.codigo + ' - ' + obj.descricao,
              value: obj.id
            });
          });
        },
        error =>
          this.httpService.handleError(error, () =>
            this.loadInstituicaoFinanceira()
          ),
        () => this.httpService.done()
      );
  }

  private loadMoedas() {
    this.moedasList = new Array();

    this.httpService.wait();
    this.httpService
      .get(
        '/moeda?$select=id,codigo,descricao&$orderby=descricao'
      )
      .subscribe(
        rst => {
          rst.value.forEach(obj => {
            this.moedasList.push({
              label: obj.codigo + ' - ' + obj.descricao,
              value: obj.id
            });
          });
        },
        error =>
          this.httpService.handleError(error, () =>
            this.loadMoedas()
          ),
        () => this.httpService.done()
      );
  }

  private loadTipoConta() {
    this.tipContaList = new Array();

    this.httpService.wait();
    this.httpService
      .get('/tipoconta?$select=id,descricao&$orderby=descricao')
      .subscribe(
        rst => {
          rst.value.forEach(obj => {
            this.tipContaList.push({
              label: obj.descricao,
              value: obj.id
            });
          });
        },
        error =>
          this.httpService.handleError(error, () => this.loadTipoConta()),
        () => this.httpService.done()
      );
  }

  private milliseconds2Date(arg: string) {
    const d = new Date(arg);
    return this.utilService.dateWithoutTimeZone(d.toDateString());
  }

  // app.component.ts;
  private refreshToolbar() {
    this.toolbarService.ocultar('voltar');
    // this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('edit');
    // this.toolbarService.ocultar('add');

    this.alterarStatusAtualizar(true);

    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Adicionar', () => {
      this.loadComponent('new', null);
    }
    );
  }

  salvar(edit: boolean) {
    let checkFirstWave = false;
    let checkSecondWave = false;

    this.msgs = [];

    if (edit) {
      if (this.empresaConta.codigoTransmissao !== null && this.empresaConta.codigoTransmissao !== undefined && this.instFinanceira !== 116) {
        this.empresaConta.codigoTransmissao = null
      }

      // if (this.empresaConta.codigoTransmissao !== null && this.empresaConta.codigoTransmissao !== undefined && this.empresaConta.codigoTransmissao.length != 20) {
      //   checkFirstWave = true;
      //   this.messageService.add({
      //     key: 'messageLinnks',
      //     severity: 'error',
      //     summary: 'Erro',
      //     detail: 'O campo "CÓDIGO DE TRANSMISSÃO" deve ter 20 dígitos.'
      //   });
      // }

      if (this.empresaConta.nome === null || this.empresaConta.nome === '') {
        checkFirstWave = true;
        this.messageService.add({
          key: 'messageLinnks',
          severity: 'error',
          summary: 'Erro',
          detail: 'O campo "NOME" deve ser preenchido.'
        });
      }

      if (this.tipConta === null) {
        checkFirstWave = true;
        this.messageService.add({
          key: 'messageLinnks',
          severity: 'error',
          summary: 'Erro',
          detail: 'O campo "TIPO DE CONTA" deve ser preenchido.'
        });
      }

      if (this.empresaConta.dtInicial === null) {
        checkFirstWave = true;
        this.messageService.add({
          key: 'messageLinnks',
          severity: 'error',
          summary: 'Erro',
          detail: 'O campo "DATA INICIAL" deve ser preenchido.'
        });
      }


      if (this.empresaConta.dtInicial !== null && this.empresaConta.dtFinal !== null && this.empresaConta.dtInicial > this.empresaConta.dtFinal) {
        checkFirstWave = true;
        this.messageService.add({
          key: 'messageLinnks',
          severity: 'error',
          summary: 'Erro',
          detail: 'A data final não pode ser anterior a data inicial.'
        });
      }

      if (!this.ctContabil && this.tipConta !== 10) {
        checkFirstWave = true;
        this.messageService.add({
          key: 'messageLinnks',
          severity: 'error',
          summary: 'Erro',
          detail: 'O campo "CONTA CONTÁBIL" deve ser preenchido.'
        });
      }

      if (!checkFirstWave) {
        if (this.tipConta != 6 && this.tipConta != 10) {
          if (this.instFinanceira === null) {
            checkSecondWave = true;
            this.messageService.add({
              key: 'messageLinnks',
              severity: 'error',
              summary: 'Erro',
              detail: 'O campo "INSTITUIÇÂO FINANCEIRA" deve ser preenchido.'
            });
          }

          if (this.empresaConta.agencia === null || this.empresaConta.agencia === '') {
            checkSecondWave = true;
            this.messageService.add({
              key: 'messageLinnks',
              severity: 'error',
              summary: 'Erro',
              detail: 'O campo "AGÊNCIA" deve ser preenchido.'
            });
          }

          if (this.empresaConta.conta === null || this.empresaConta.conta === '') {
            checkSecondWave = true;
            this.messageService.add({
              key: 'messageLinnks',
              severity: 'error',
              summary: 'Erro',
              detail: 'O campo "CONTA" deve ser preenchido.'
            });
          }

          if (this.empresaConta.dvConta === null || this.empresaConta.dvConta === '') {
            checkSecondWave = true;
            this.messageService.add({
              key: 'messageLinnks',
              severity: 'error',
              summary: 'Erro',
              detail: 'O campo "DV CONTA" deve ser preenchido.'
            });
          }
        }

        if (!checkSecondWave) {
          this.empresaConta.empresa = new Empresa();
          this.empresaConta.empresa.id = Number(this.dblinkedSessionService.empresa.id);

          this.empresaConta.tipoConta = new TipoConta();
          this.empresaConta.tipoConta.id = Number(this.tipConta);

          if (Number.isInteger(this.instFinanceira)) {
            this.empresaConta.instituicaoFinanceira = new InstituicaoFinanceira();
            this.empresaConta.instituicaoFinanceira.id = Number(this.instFinanceira);
          } else {
            this.empresaConta.instituicaoFinanceira = null;
          }

          if (Number.isInteger(this.moeda)) {
            this.empresaConta.moeda = new Moeda();
            this.empresaConta.moeda.id = Number(this.moeda);
          } else {
            this.empresaConta.moeda = null;
          }

          if (Number.isInteger(this.ctContabil)) {
            this.empresaConta.planoContas = new PlanoContas();
            this.empresaConta.planoContas.id = Number(this.ctContabil);
          } else {
            this.empresaConta.planoContas = null;
          }

          this.empresaConta.ativo = this.ativo;
          this.empresaConta.pagamentosautomatizados = this.pagamentosautomatizados;
          this.empresaConta.convenio = this.convenio;
          this.empresaConta.chavePix = this.chavePix
          if (Number.isInteger(this.tipoChavePixId)) {
            this.empresaConta.tipoChavePix = new TipoChavePix();
            this.empresaConta.tipoChavePix.id = Number(this.tipoChavePixId);
          } else {
            this.empresaConta.tipoChavePix = null;
          }

          //tipo == 1 INSERT
          //tipo == 2 UPDATE
          if (this.showEdit) {
            // update;
            // ------------------------------------

            const listasEnviar = {
              empresaConta: this.empresaConta,
              tipo: 2,
              usuario: this.sessionService.loggedUser.id
            };

            this.httpService.wait();
            this.httpService
              .post('/custom/cadastro/criar-conta-empresa', listasEnviar)
              .subscribe(
                rst => {
                  this.messageService.add({
                    key: rst.key,
                    severity: rst.severity,
                    summary: rst.summary,
                    detail: rst.detail
                  });
                  if (this.dataAnterior > this.empresaConta.dtInicial || this.saldoInicialAnterior != this.empresaConta.saldoInicial) {
                    //Buscar o ecd_id correspondente
                    let dtIni: string = this.empresaConta.dtInicial.getFullYear() + "-" + (this.empresaConta.dtInicial.getMonth() + 1) + "-1"

                    this.httpService.get("/cadecd?$select=id&$filter=empresa/id eq " + this.dblinkedSessionService.empresa.id + " and dtIni eq '" + dtIni + "'")
                      .subscribe(
                        res => {
                          const infos = {
                            empresa_id: this.dblinkedSessionService.empresa.id,
                            ecd_id: res.value[0].id,
                            user_id: this.sessionService.loggedUser.id,
                            contas: this.empresaConta.id
                          }

                          this.httpService.wait()
                          this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/recalcular-saldo-fin', infos)
                            .subscribe(
                              res => {
                              },
                              err => {
                                this.exibirMensagem({ error: true, mensagem: "Erro no API ao recalcular os saldos, contate o suporte: " + err.message })
                              }
                            );

                        },
                        err => {
                          this.exibirMensagem({ error: true, mensagem: "Erro ao procurar o id do periodo, contate o suporte: " + err.message })
                          return
                        },
                      )
                  }
                  this.changeSession()
                  this.httpService.done()
                },
                error =>
                  this.httpService.handleError(error, () => this.salvar(edit)),
                () => this.httpService.done()
              );
          } else if (this.showNew) {
            const listasEnviar = {
              empresaConta: this.empresaConta,
              tipo: 1,
              usuario: this.sessionService.loggedUser.id
            };

            this.httpService.wait();
            this.httpService
              .post('/custom/cadastro/criar-conta-empresa', listasEnviar).subscribe(
                rst => {
                  this.messageService.add({
                    key: rst.key,
                    severity: rst.severity,
                    summary: rst.summary,
                    detail: rst.detail
                  });

                  if (rst.severity == 'success') {
                    this.duplicarSaldosIniciais(rst.contaId)
                  }
                  this.httpService.done()
                },
                error =>
                  this.httpService.handleError(error, () => this.salvar(edit)),
                () => this.httpService.done()
              );
          }
        }
      }
    } else {
      if (confirm('Deseja excluir esta conta?')) {
        const listasEnviar = {
          empresaContaId: this.empresaConta.id
        };


        this.httpService.wait();
        this.httpService
          .post('/custom/cadastro/delete-conta', listasEnviar)
          .subscribe(
            rst => {
              this.messageService.add({
                key: 'messageLinnks',
                severity: rst.severity,
                summary: rst.summary,
                detail: rst.detail
              });
              if (rst.severity === 'success') {
                this.changeSession();
              }
            },
            error =>
              this.httpService.handleError(error, () => this.salvar(false)),
            () => this.httpService.done()
          );
      }
    }
  }

  duplicarSaldosIniciais(empresaContaId: number) {

    const payload = {
      user_id: this.sessionService.loggedUser.id,
      conta: empresaContaId,
      dt_ini: this.empresaConta.dtInicial.getFullYear() + "-" + (this.empresaConta.dtInicial.getMonth() + 1) + "-" + this.empresaConta.dtInicial.getDate(),
      empresa_id: this.dblinkedSessionService.empresa.id,
      saldo: this.empresaConta.saldoInicial
    }

    this.httpService.post(this.configService.defaultUrlApiUpload + 'financeiro/duplicar-saldos-iniciais', payload)
      .subscribe(
        res => {
          if (res.error) this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: res["mensagem"] });
          else this.changeSession()
        },
        err => {
          this.messageService.add({ key: 'messageLinnks', severity: 'error', summary: 'Erro', detail: "Erro ao duplicar os saldos iniciais, contate o suporte: " + err.message });
        })
  }

  exibirMensagem(value: any) {
    if (!value.error) {
      this.msgs = [];
      this.msgs.push({ severity: 'success', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.msgs = [];
      this.msgs.push({ severity: 'warn', summary: 'Erro', detail: value.mensagem });
    }
  }

  getPlanoContas(event: LazyLoadEvent = {}) {
    const paramload = {
      offset: event.first,
      limit: event.rows,
      sort_order: event.sortOrder,
      sort_field: event.sortField,
      filters: event.filters,
      empresa_id: this.dblinkedSessionService.empresa.id,
      ano: this.dblinkedSessionService.periodo.year.value
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'contabil/plano-contas-lazyload', paramload)
      .subscribe(res => {
        if (res["error"]) {
          this.exibirMensagem(res)
          this.httpService.done();
          return
        }

        this.listaContas = []
        res["data"]["lista"].forEach(element => { this.listaContas.push(element) })
        this.totalContas = res["data"]["total"];
        this.httpService.done();

      }, error => {
        this.exibirMensagem({ severity: 'error', message: "Erro no API ao carregar as contas contábeis, contate o suporte: " + error.message });
        console.log(error)
        this.httpService.done();
      });
  }

  loadContasUnicas() {
    this.modalContasUnicas = true
    this.getPlanoContas()
  }

  selecionarContasUnica(row: any) {
    this.ctContabil = row.data.id
    this.ctaSelecionada = row.data.codCta + " - " + row.data.descrCta
  }
}
