import { ItemTipo } from "app/util/model/item-tipo.model";
import { Empresa } from "./empresa.model";
import { isNullOrUndefined } from "util";

export class OperacaoRegraGrupo {
  id: number;
  codigo: string;
  descricao: string;
  vinculaItemTipo: boolean;
  itemTipo: ItemTipo;
  empresa: Empresa;

  constructor(operacaoRegraGrupo?: OperacaoRegraGrupo | number) {
    if(!isNullOrUndefined(operacaoRegraGrupo)){
      if(typeof operacaoRegraGrupo === 'number'){
        this.id = operacaoRegraGrupo;
      }else{
        this.id = operacaoRegraGrupo.id;
        this.codigo = operacaoRegraGrupo.codigo;
        this.descricao = operacaoRegraGrupo.descricao;
        this.vinculaItemTipo = operacaoRegraGrupo.vinculaItemTipo;
        this.itemTipo = operacaoRegraGrupo.itemTipo;
        this.empresa = operacaoRegraGrupo.empresa;
      }
    }
  }
}