export class NfePresencial {
  id: number;
  descricao: string;

  constructor(nfePresencial?: NfePresencial | number) {
    if (nfePresencial !== undefined && nfePresencial !== null) {
      if (typeof nfePresencial === 'number') {
        this.id = nfePresencial;
      } else {
        this.id = nfePresencial.id;
        this.descricao = nfePresencial.descricao;
      }
    }
  }
}
