import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';
declare var $: any;


@Component({
  selector: 'app-segmento-participante',
  templateUrl: './segmento-participante.component.html',
  styleUrls: ['./segmento-participante.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class SegmentoParticipanteComponent implements OnInit, OnDestroy {

  currentStateSeg = 'initial';
  changeStateSeg() {
    this.currentStateSeg = this.currentStateSeg === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;

  msgs: Message[];
  subtitle: string;
  title: string;
  segmentoLista:Array<any>;
  modalNovo: boolean = false;
  modalEdit: boolean = false;
  newCodigo: string;
  newDescricao: string;
  editCodigo: string;
  editDescricao: string;
  editId: number;
  contadorLinha: number;

  constructor(private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private messageService: MessageService
    ) {}


      @ViewChild('dt') dt: Table
      export() {
        this.dt.exportCSV()
      }

  public toolbarMain = new Toolbar()

  toolbarMainIni = [
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.dt.exportCSV() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.openAddSegmento() },
    {
      icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHideSeg()
        this.changeStateSeg()
      }
    },
  ]


  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni)
    this.toolbarService.hidden = true
    this.title = 'SEGMENTOS';
    this.subtitle = 'Cadastro de segmento';
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {
            this.getSegmentos();
            this.toolbarEnable();
        }

      });

    /*HIDE SIDEBAR FULLSCREEN (DO NOT REMOVE)*/
    $(document).ready(() => {
      $(".btn-full").click(
        function() {
          $(".mat-drawer-side").toggleClass('d-none');
          }
        );
    });

    this.contadorLinha=0;
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  getSegmentos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/getsegmentos', null, httpOptions)
      .subscribe(
        res => {
          this.segmentoLista = new Array();
          var lista = res["mensagem"];

          for (let line of lista) {

            this.segmentoLista.push(line);

          if(this.segmentoLista !== null || this.segmentoLista !== undefined){
              this.contadorLinha = this.segmentoLista.length;
          }
          else{
              this.contadorLinha = 0;
          }
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        }
      );
  }

  onFilter(event: any) {
    this.contadorLinha = event.filteredValue.length;
  }

  toolbarEnable() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo segmento', () => this.openAddSegmento());
    this.toolbarService.ocultar('edit');
    this.toolbarService.ocultar('remove');
  }

  openAddSegmento() {
    this.newDescricao = null;
    this.newCodigo = null;
    this.modalNovo = true;
  }

  addSegmento() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'descricao': this.newDescricao.toString(),
        'codigo': this.newCodigo.toString(),
        'userid': this.sessionService.loggedUser.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/addsegmento', null, httpOptions)
      .subscribe(
        res => {
          this.getSegmentos();
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        }
      );
    this.modalNovo = false;
  }

  hideMessage() {

  }

  onRowSelect(row) {
    console.log(row)
    this.modalEdit = true;
    this.editCodigo = row.codigo;
    this.editDescricao = row.segmento;
    this.editId = row.id;
  }

  deleteSegmento() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'segId': this.editId.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'cadastro/deletesegmento', httpOptions)
      .subscribe(
        res => {
          this.getSegmentos();
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        }
      );
    this.modalEdit = false;
  }

  editSegmento() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'segId': this.editId.toString(),
        'codigo': this.editCodigo.toString(),
        'descricao': this.editDescricao.toString(),
        'userid': this.sessionService.loggedUser.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/editsegmento', null, httpOptions)
      .subscribe(
        res => {
          this.getSegmentos();
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.exibirMensagem(err);
          this.httpService.done();
        }
      );
    this.modalEdit = false;
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  /*Show Hide Cadastro de Segmento*/
  isCardSeg: boolean = true;
  ShowHideSeg(){
    this.isCardSeg = !this.isCardSeg;
    this.toolbarMain.setIcon('btn2', this.isCardSeg ? 'expand_less' : 'expand_more')
    this.toolbarMain.setTooltip('btn2', this.isCardSeg ? 'Ver menos' : 'Ver mais')
  }

}
