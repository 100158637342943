import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';

import { AuthUser } from 'app/proof/model/auth-user.model';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { DH_NOT_SUITABLE_GENERATOR } from 'constants';

import { Util } from 'app/shared/common/util';

import { isNullOrUndefined } from 'util';

import { Formulario } from 'app/eventos/model/formulario.model';
import { FormularioPerguntasCampo } from 'app/eventos/model/formulario-perguntas-campo.model';
import { FormularioTipoCampo }  from 'app/eventos/model/formulario-tipo-campo.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpHeaders } from '@angular/common/http';
import { stringify } from 'querystring';
import { DOCUMENT } from '@angular/common';
import { url } from 'inspector';
import { FormularioAgendamentoHistorico } from 'app/eventos/model/formulario-agendamento-historico.model'
import { FormularioCamposCarga } from 'app/eventos/model/formulario-campos-carga.model';
import { FormularioAgendamentoStatus } from 'app/eventos/model/formulario-agendamento-status.model';
import { FormularioMotivoReagendamento } from '../model/formulario-motivo-reagendamento.model';
import { FormularioMotivoCancelamento } from 'app/eventos/model/formulario-motivo-cancelamento.model';
import { ControleGrupoComponent } from 'app/controle-acesso/controle-grupo/controle-grupo.component';
import { Message, MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-formulario-visualizacao-respostas',
  templateUrl: './formulario-visualizacao-respostas.component.html',
  styleUrls: ['./formulario-visualizacao-respostas.component.scss'],
  providers: [CrudService]
})
export class FormularioVisualizacaoRespostasCamposComponent implements OnInit, OnDestroy   {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;

  modalFormularioResposta     : boolean = false;
  modalFormularioAgenda       : boolean = false;
  modalFormularioAgendamento  : boolean = false;
  modalNovoAgendamento        : boolean = false;
  opcoesFormulario            : SelectItem[] = [];
  opcoesSeminarista             : SelectItem [] = [];

  msgs          : Message[] = [];
  msgsModal     : Message[] = [];
  checkedAll    = false;

  formulario    = new Formulario();
  editFormulario                   : boolean = false;
  btnSalvarDisabled                : boolean = false;
  btnCancelarDisabled              : boolean = false;
  formularioPerguntasCampo         : FormularioPerguntasCampo;
  public cabecalhoColunas          = [];
  public listaFormularioRespostas  = [];
  public listaPerguntas            = new Array<any | any | any>();

  formularioId  : number = 0;
  lblFormulario : string = '';
  public nome   : any = null;
  public cpf    : any = null;
  public fone   : any = null;
  public d      : any = null;
  modalSelecionarFormulario : boolean = false;
  seminaristaEmail             : string = '';

  agendaUrl                  : string = '';
  urlSafe                    : SafeResourceUrl = '';
  novoAgendamentoUrl         : string = '';
  urlSafeAgendamento         : SafeResourceUrl = '';
  dataInicioEvento           : Date;
  dataFinalEvento            : Date;
  public descricaoEvento     : string = '';
  palestranteEmail           : string = '';
  tipoAtividade              : string = '';
  listaEmail                 = new Array();

  formularioAgendamentoHistorico = new FormularioAgendamentoHistorico();
  modalCancelarAgendamento   : boolean = false;
  opcoesMotivosCancelamento  : SelectItem [] = [];
  opcoesMotivosReagendamento : SelectItem [] = [];
  formularioCamposCargaId    : number = 0;
  modalReagendar             : boolean = false;
  listaHistorico             : Array<any> = new Array();
  selectedIndex              : number = 0;
  public btnAgendar  : boolean = false;
  public btnCancelar : boolean = false;
  public btnReagendar: boolean = false;
  motivoReagendamentoId : number = 0;
  motivoCancelamentoId  : number = 0
  dataCancelamento : Date;
  ultimaObservacao : string = '';
  private mywindow;

  colsListaFormularioRespostas : any[] = [];
  colsListaHistorico           : any[] = [];

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: [],
    toolbarButtonsXS: [],
    toolbarButtonsSM: [],
    toolbarButtonsMD: [],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
         //console.log('froalaEditor - > inicializado');
      },
    },
  };

  public authorized: boolean = false;
  httpClient: any;
  opcoesComboStatus : Array<any> = new Array();

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    public sanitizer: DomSanitizer,
    private messageService: MessageService


  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title              = 'VISUALIZAÇÃO RESPOSTA FORMULÁRIO';
    this.subtitle           = 'VISUALIZAÇÃO RESPOSTA FORMULÁRIO';
    this.titleService.title = 'VISUALIZAÇÃO RESPOSTA FORMULÁRIO';

    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);


    this.modalFormularioResposta  = false;
    this.checkedAll               = false;
    this.msgs                     = [];
    this.msgsModal                = [];

    this.opcoesFormulario         = new Array();
    this.formulario               = new Formulario();
    this.formularioPerguntasCampo = new FormularioPerguntasCampo();
    this.editFormulario           = false;

    this.listaFormularioRespostas = new Array();
    this.lblFormulario            = "";
    this.modalSelecionarFormulario = false;
    this.seminaristaEmail             ='';
    this.toolbarEnable();

    this.colsListaFormularioRespostas = [
      { field: 'nome', header: 'NOME' },
      { field: 'cpf', header: 'CPF' },
      { field: 'fone', header: 'FONE' },
      { field: 'dataCadastro', header: 'DATA CAD.' },
      { field: 'descricaoStatus', header: 'STATUS' }
    ];

    this.colsListaHistorico = [
      { field: 'data', header: 'DATA' },
      { field: 'dataReagendamento', header: 'DATA RE-AGEN.' },
      { field: 'descricaoStatus', header: 'STATUS' },
      { field: 'descricaoReagendamentoCancelamento', header: 'MOTIVO' },
      { field: 'nomeResponsavel', header: 'RESPONSÁVEL' }
    ];

    this.formularioAgendamentoHistorico = new FormularioAgendamentoHistorico();
    this.formularioAgendamentoHistorico.formularioCamposCarga = new FormularioCamposCarga();
    this.formularioAgendamentoHistorico.formularioAgendamentoStatus = new FormularioAgendamentoStatus();

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.listar();


      });

  }

 listar(){
  this.getComboFormularioAgendamentoStatus();
  this.modalFormularioResposta    = false;
  this.modalFormularioAgenda      = false;
  this.modalFormularioAgendamento = false;

  this.opcoesFormulario         = new Array();
  this.formulario               = new Formulario();

  this.formularioPerguntasCampo = new FormularioPerguntasCampo();
  this.editFormulario   = false;

  this.listaFormularioRespostas  = [] = [];

  this.dataInicioEvento = new Date();
  this.dataFinalEvento  = new Date();
  this.descricaoEvento  = '';
  this.seminaristaEmail   = '';
  this.palestranteEmail = '';
  this.tipoAtividade    = '';

 }

 listarRespostas(){
   try {
      let i = this.opcoesFormulario.findIndex(e=> e.value === this.formularioId);
      this.subtitle = this.opcoesFormulario[i].label;


      this.httpService.wait();
      this.httpService.get('/custom/polozi/get-formulario-lista-resposta',[this.formularioId])
        .subscribe(retorno => {

          this.listaFormularioRespostas = [];
          retorno.forEach(e =>{
            this.listaFormularioRespostas.push(e);
          });

        },
          error => this.httpService.handleError(error, () => this.listarRespostas()),
          () => this.httpService.done());

   } catch (error) {

   }


 }


  hideMessage() {




  }


  ocultarBotoes() {
    // this.toolbarService.ocultar('voltar');
    // this.toolbarService.ocultar('download');
    // this.toolbarService.ocultar('cancel');
    // this.toolbarService.ocultar('check');
    // this.toolbarService.ocultar('info');
    // this.toolbarService.ocultar('print');
    // this.toolbarService.ocultar('search');
    // this.toolbarService.ocultar('save');
    // this.toolbarService.ocultar('pesquisa');
    // this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {
    //const todos = this.buttons.slice();
    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('add');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('save');
    //this.toolbarService.ocultar('atualizar');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('export');

    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => {this.listarRespostas() } );


    this.toolbarService.replace('edit', false, 'class-new-button', 'SELECIONE', 'SELECIONAR FORMULÁRIO',    () => this.showModalSelecionar()    );


  }





  ngOnDestroy(): void {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }



 comboFormularios(){

  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-lista-formularios')
    .subscribe(retorno => {

      this.opcoesFormulario = [];
      retorno.forEach(i =>{
        this.opcoesFormulario.push({label: i.nome , value: i.id});
      })
    },
      error => this.httpService.handleError(error, () => this.comboFormularios()),
      () => this.httpService.done());

 }

 showModalVisualizarFormulario(rowValue: any){
  Promise.resolve(this.comboCarregarConsultores()).then(i =>{

    this.formularioCamposCargaId  = rowValue.id;
    this.lblFormulario            = "";
    this.nome                     = null;
    this.cpf                      = null;
    this.fone                     = null;

    this.lblFormulario            = rowValue.formularioNome;
    this.nome                     = rowValue.nome;
    this.cpf                      = rowValue.cpf;
    this.fone                     = rowValue.fone;
    this.seminaristaEmail           = rowValue.seminaristaEmail;
    this.ultimaObservacao         = rowValue.ultimaObservacao;
    this.selectedIndex            = 0;

    switch (rowValue.formularioAgendamentoStatusId) {
      case 1: //Agendamento
        this.btnAgendar = false;
        this.btnCancelar = true;
        this.btnReagendar = true;
        break;

        case 2: //Re-Agendamento
        this.btnAgendar = false;
        this.btnCancelar = true;
        this.btnReagendar = true;
        break;

        case 3: //Cancelado
        this.btnAgendar = false;
        this.btnCancelar = false;
        this.btnReagendar = false;
        break;

      default:
        this.btnAgendar = true;
        this.btnCancelar = false;
        this.btnReagendar = false;
        break;
    }


    Promise.resolve(this.carregarInf(rowValue)).then(() => this.modalFormularioResposta = true);

});

 }

carregarInf(rowValue: any){


  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-config-montagem-formulario-respostas',[this.formularioId])
  .subscribe(retorno => {

    this.listaPerguntas = [];
    retorno.forEach(e => {
      this.listaPerguntas.push({perguntaDescricao: e.perguntaDescricao, posicaoCampo: e.posicaoCampo, resposta: ''});
    });

    this.httpService.get('/custom/polozi/get-respostas',[rowValue.id])
    .subscribe(retorno2 =>{


      for (let index = 0; index < this.listaPerguntas.length; index++) {

        if (this.listaPerguntas[index].posicaoCampo === 1) {
          this.listaPerguntas[index].resposta = retorno2[0].campo1;
        }

        if (this.listaPerguntas[index].posicaoCampo === 2) {
          this.listaPerguntas[index].resposta = retorno2[0].campo2;
        }

        if (this.listaPerguntas[index].posicaoCampo === 3) {
          this.listaPerguntas[index].resposta = retorno2[0].campo3;
        }

        if (this.listaPerguntas[index].posicaoCampo === 4) {
          this.listaPerguntas[index].resposta = retorno2[0].campo4;
        }


        if (this.listaPerguntas[index].posicaoCampo === 5) {
          this.listaPerguntas[index].resposta = retorno2[0].campo5;
        }

        if (this.listaPerguntas[index].posicaoCampo === 6) {
          this.listaPerguntas[index].resposta = retorno2[0].campo6;
        }

        if (this.listaPerguntas[index].posicaoCampo === 7) {
          this.listaPerguntas[index].resposta = retorno2[0].campo7;
        }

        if (this.listaPerguntas[index].posicaoCampo === 8) {
          this.listaPerguntas[index].resposta = retorno2[0].campo8;
        }

        if (this.listaPerguntas[index].posicaoCampo === 9) {
          this.listaPerguntas[index].resposta = retorno2[0].campo9;
        }

        if (this.listaPerguntas[index].posicaoCampo === 10) {
          this.listaPerguntas[index].resposta = retorno2[0].campo10;
        }

        if (this.listaPerguntas[index].posicaoCampo === 11) {
          this.listaPerguntas[index].resposta = retorno2[0].campo11;
        }

        if (this.listaPerguntas[index].posicaoCampo === 12) {
          this.listaPerguntas[index].resposta = retorno2[0].campo12;
        }

        if (this.listaPerguntas[index].posicaoCampo === 13) {
          this.listaPerguntas[index].resposta = retorno2[0].campo13;
        }

        if (this.listaPerguntas[index].posicaoCampo === 14) {
          this.listaPerguntas[index].resposta = retorno2[0].campo14;
        }


        if (this.listaPerguntas[index].posicaoCampo === 15) {
          this.listaPerguntas[index].resposta = retorno2[0].campo15;
        }


        if (this.listaPerguntas[index].posicaoCampo === 16) {
          this.listaPerguntas[index].resposta = retorno2[0].campo16;
        }

        if (this.listaPerguntas[index].posicaoCampo === 17) {
          this.listaPerguntas[index].resposta = retorno2[0].campo17;
        }

        if (this.listaPerguntas[index].posicaoCampo === 18) {
          this.listaPerguntas[index].resposta = retorno2[0].campo18;
        }

        if (this.listaPerguntas[index].posicaoCampo === 19) {
          this.listaPerguntas[index].resposta = retorno2[0].campo19;
        }

        if (this.listaPerguntas[index].posicaoCampo === 20) {
          this.listaPerguntas[index].resposta = retorno2[0].campo20;
        }


      }
    });

  },
    error => this.httpService.handleError(error, () => this.carregarInf(rowValue)),
    () => this.httpService.done());

}

showModalSelecionar(){
  this.modalSelecionarFormulario = true;
  this.comboFormularios();
}

showModalAgenda(){
  this.atualizarAgenda('');
  this.comboCarregarConsultores();
  this.modalFormularioAgenda = true;
}

comboCarregarConsultores(){

  this.httpService.wait();
  this.httpService.get('/custom/polozi/get-combo-seminarista(' + [this.dblinkedSessionService.empresa.id] + ')' )
  .subscribe(retorno => {

    this.opcoesSeminarista = [];
    this.opcoesSeminarista.push({ label: 'Selecione', value: '' });
    retorno.forEach(element => {
      this.opcoesSeminarista.push({ label: element.nome, value: element.email , title: element.urlOutlookEmail });
    });

  },
    error => this.httpService.handleError(error, () => this.comboCarregarConsultores()),
    () => this.httpService.done());
  }

  atualizarAgenda(consultor: any){
    try {
      var url = '';

      if (!isNullOrUndefined( consultor) && consultor.toString().length > 4 ) {
        this.agendaUrl =  'https://calendar.google.com/calendar/embed?src=' + consultor.toString().toLowerCase()  + '&ctz=America%2FSao_Paulo';
        this.agendaUrl = url;
        this.urlSafe  = this.sanitizer.bypassSecurityTrustResourceUrl(this.agendaUrl);
      } else{
        this.urlSafe  = this.sanitizer.bypassSecurityTrustResourceUrl("");
      }

    } catch (error) {
      console.log(error);
    }

  }

  showModalAgendamento(){
    this.modalFormularioAgendamento = true;
    this.dataInicioEvento = new Date();
    this.dataFinalEvento  = new Date();
    this.descricaoEvento  = '';
    this.palestranteEmail = '';
    this.tipoAtividade    = '';
    this.palestranteEmail = this.seminaristaEmail;
    this.tipoAtividade    =   this.lblFormulario;
    this.listaEmail       = new Array();

    let time =  new Date();
    time.setDate(this.dataInicioEvento.getDate());
    time.setHours(this.dataInicioEvento.getHours() + 2, this.dataInicioEvento.getMinutes() + 30)
    this.dataFinalEvento = time ;
    //this.descricaoEvento = '<strong>' +  'Nome: ' + '</strong>' + this.nome + '<br>';
    this.descricaoEvento = ' ' +  'Nome: ' + ' ' + this.nome + '<br>';
    //this.descricaoEvento =  this.descricaoEvento + '<strong>' + 'CPF: ' + '</strong>' + this.cpf + '<br>';
    this.descricaoEvento =  this.descricaoEvento + ' ' + 'CPF: ' + ' ' + this.cpf + '<br>';
    //this.descricaoEvento =  this.descricaoEvento + '<strong>' + 'Fone: ' + '</strong>' +  this.fone + '<br>';
    this.descricaoEvento =  this.descricaoEvento + ' ' + 'Fone: ' + ' ' +  this.fone + '<br>';

    // this.descricaoEvento =  'Nome: ' +  this.nome + "\n";
    // this.descricaoEvento =  this.descricaoEvento +  'CPF: ' + this.cpf + "\n";
    // this.descricaoEvento =  this.descricaoEvento +  'Fone: '  +  this.fone + "\n";


    for (let i = 0; i < this.listaPerguntas.length; i++) {
      //  this.descricaoEvento =  this.descricaoEvento +  this.listaPerguntas[i].perguntaDescricao +  ' ' +   this.listaPerguntas[i].resposta + "\n"  ;
      // this.descricaoEvento =  this.descricaoEvento + '<strong>' + this.listaPerguntas[i].perguntaDescricao +  ' ' + '</strong>' + this.listaPerguntas[i].resposta + '<br>' ;
      this.descricaoEvento =  this.descricaoEvento + ' ' + this.listaPerguntas[i].perguntaDescricao +  ' ' + ' ' + this.listaPerguntas[i].resposta + '<br>' ;
    }

  }

  atualizaDataFinal(){

    let time =  new Date();
    time.setFullYear(this.dataInicioEvento.getFullYear());
    time.setMonth(this.dataInicioEvento.getMonth());
    time.setDate(this.dataInicioEvento.getDate());
    time.setHours(this.dataInicioEvento.getHours() + 2, this.dataInicioEvento.getMinutes() + 30)
    this.dataFinalEvento = time ;

  }


  salvarAgendamento(){

    if (this.validacao()) {
      return;
    }

    if (confirm('Para continuar com  agendamento certifique-se estar logado no serviço Outlook live')) {
      this.formularioAgendamentoHistorico = new FormularioAgendamentoHistorico();
      this.formularioAgendamentoHistorico.formularioCamposCarga = new FormularioCamposCarga();
      this.formularioAgendamentoHistorico.formularioCamposCarga.id  = this.formularioCamposCargaId;
      this.formularioAgendamentoHistorico.formularioCamposCargaId   = this.formularioCamposCargaId;
      this.formularioAgendamentoHistorico.formularioAgendamentoStatus = new FormularioAgendamentoStatus();
      this.formularioAgendamentoHistorico.formularioAgendamentoStatus.id = 1;
      this.formularioAgendamentoHistorico.formularioAgendamentoStatusId  = 1;
      this.formularioAgendamentoHistorico.observacao                     = this.descricaoEvento;
      this.formularioAgendamentoHistorico.dataInicio                     = this.dataInicioEvento;
      this.formularioAgendamentoHistorico.dataFinal                      = this.dataFinalEvento;


      const listaEnviar = {
        formularioAgendamentoHistorico   : this.formularioAgendamentoHistorico,
        usuarioId                        : this.sessionService.loggedUser.id,
        seminaristaEmail                   : this.seminaristaEmail

    };



    /* voltar aqui Agendamento */

    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-cancelamento-formulario', listaEnviar)
      .subscribe(result => {

        if (result[0].error) {

          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        } else {

          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
          this.listarRespostas()


            if(this.mywindow) {
              this.mywindow.close();
            }
            var dataInicioGoogle = '';
            var dataFinalGoogle = '';

            if (!isNullOrUndefined(this.dataInicioEvento)) {
              let mes = this.dataInicioEvento.getMonth() + 1;
              let minutos = this.dataInicioEvento.getMinutes();
              let hora    = this.dataInicioEvento.getHours();
              dataInicioGoogle = this.dataInicioEvento.getFullYear().toString() + '-'  + ( mes > 9 ? mes : '0' + mes) +'-' + (this.dataInicioEvento.getDate() > 9 ?  this.dataInicioEvento.getDate().toString() : '0' + this.dataInicioEvento.getDate().toString()) + 'T' + (hora > 9 ? hora : '0' + hora) + ':' + (minutos > 9 ? minutos : '0' + minutos ) + ':' + '00'
            }

            if (!isNullOrUndefined(this.dataFinalEvento)) {
              let mes = this.dataFinalEvento.getMonth() + 1;
              let minutos = this.dataFinalEvento.getMinutes();
              let hora    = this.dataFinalEvento.getHours();
                dataFinalGoogle = this.dataFinalEvento.getFullYear().toString() + '-'  + (mes  > 9  ? mes : '0' + mes  ) + '-' + (this.dataFinalEvento.getDate() > 9 ?  this.dataFinalEvento.getDate().toString() : '0' + this.dataFinalEvento.getDate().toString() )+ 'T' + (hora > 9 ? hora : '0' + hora) + ':' + ( minutos > 9 ? minutos : '0' + minutos ) + ':' + '00'
              }
            this.modalCancelarAgendamento = false;

            console.log( this.descricaoEvento);
            console.log(this.removeTagHtml( this.descricaoEvento));

            //https://outlook.live.com/calendar/0/deeplink/compose?startdt=2020-08-12T00:00:00&enddt=2020-08-13T12:00:00&subject=Hello%20World&body=Hello%20World&to=marcelo.feliciano@linnks.com.br
            //https://calendar.google.com/calendar/r/eventedit?text=My+Title&details=My+Description&location=My+Location&dates=20191119T140000/20191122T230000
            //this.mywindow = window.open('https://calendar.google.com/calendar/r/eventedit?text=' + this.tipoAtividade + '&details=' + this.removeTagHtml( this.descricaoEvento)   + '&add='+ this.seminaristaEmail + '&dates='+ dataInicioGoogle  +'/'+ dataFinalGoogle + '&ctz=America/Sao_Paulo', "_blank"  )
            //this.mywindow = window.open('https://outlook.live.com/calendar/0/deeplink/compose?startdt=' + dataInicioGoogle + '&enddt=' + dataFinalGoogle + '&subject=' + this.tipoAtividade + '&body=' + this.descricaoEvento   );
            console.log('http://outlook.office.com/calendar/0/deeplink/compose?startdt=' + dataInicioGoogle + '&enddt=' + dataFinalGoogle + '&subject=' + this.tipoAtividade + '&body=' + this.removeTagHtml( this.descricaoEvento) + '&to=' + this.seminaristaEmail  );
            //this.mywindow = window.open('https://outlook.office.com/calendar/0/deeplink/compose?startdt=' + dataInicioGoogle + '&enddt=' + dataFinalGoogle + '&subject=' + this.tipoAtividade + '&body=' + this.removeTagHtml( this.descricaoEvento) + '&to=' + this.seminaristaEmail  );
            this.mywindow = window.open('http://outlook.office.com/calendar/0/deeplink/compose?startdt=' + dataInicioGoogle + '&enddt=' + dataFinalGoogle + '&subject=' + this.tipoAtividade + '&body=' + this.removeTagHtml( this.descricaoEvento)    );

        }

        // this.modalFormularioAgendamento = false;
        // this.modalFormularioAgenda      = false;
        // this.modalFormularioResposta    = false;
      },
        error => this.httpService.handleError(error, () => this.salvarCancelamentoAgendado()),
        () => this.httpService.done());

      }

  }


  salvarReagendamento(){
    if (this.validacaoReagendamento()) {
      return;
    }

    // para re-agendamento olhar aqui
    if (confirm('Para continuar com  re-agendamento certifique-se estar logado no serviço Outlook live')) {

      this.formularioAgendamentoHistorico = new FormularioAgendamentoHistorico();
      this.formularioAgendamentoHistorico.formularioCamposCarga = new FormularioCamposCarga();
      this.formularioAgendamentoHistorico.formularioCamposCarga.id  = this.formularioCamposCargaId;
      this.formularioAgendamentoHistorico.formularioCamposCargaId   = this.formularioCamposCargaId;
      this.formularioAgendamentoHistorico.formularioAgendamentoStatus = new FormularioAgendamentoStatus();
      this.formularioAgendamentoHistorico.formularioAgendamentoStatus.id = 2;
      this.formularioAgendamentoHistorico.formularioAgendamentoStatusId  = 2;
      this.formularioAgendamentoHistorico.formularioMotivoReagendamentoId = this.motivoReagendamentoId;
      this.formularioAgendamentoHistorico.formularioMotivoReagendamento   = new FormularioMotivoReagendamento();
      this.formularioAgendamentoHistorico.formularioMotivoReagendamento.id = this.motivoReagendamentoId;
      this.formularioAgendamentoHistorico.observacao                     = this.removeTagHtml(this.descricaoEvento) + "<br>" + this.ultimaObservacao;
      this.formularioAgendamentoHistorico.dataInicio                     = this.dataInicioEvento;
      this.formularioAgendamentoHistorico.dataFinal                      = this.dataFinalEvento;
      this.formularioAgendamentoHistorico.dataReagendamento = this.defaultDate;
      this.modalReagendar = false;


      const listaEnviar = {
        formularioAgendamentoHistorico   : this.formularioAgendamentoHistorico,
        usuarioId                        : this.sessionService.loggedUser.id,
        seminaristaEmail                   : this.seminaristaEmail

    };


    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-cancelamento-formulario', listaEnviar)
      .subscribe(result => {

        if (result[0].error) {

          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });
        } else {

          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: result[0].message
          });


            if(this.mywindow) {
              this.mywindow.close();
            }
            var dataInicioGoogle = '';
            var dataFinalGoogle  = '';

            if (!isNullOrUndefined(this.dataInicioEvento)) {
              let mes = this.dataInicioEvento.getMonth() + 1;
              let minutos = this.dataInicioEvento.getMinutes();
              let hora    = this.dataInicioEvento.getHours();
              dataInicioGoogle = this.dataInicioEvento.getFullYear().toString() + '-'  + ( mes > 9 ? mes : '0' + mes) + '-' + (this.dataInicioEvento.getDate() > 9 ?  this.dataInicioEvento.getDate().toString() : '0' + this.dataInicioEvento.getDate().toString()) + 'T' + (hora > 9 ? hora : '0' + hora) + ':' + (minutos > 9 ? minutos : '0' + minutos ) + ':' + '00'

            }

            if (!isNullOrUndefined(this.dataFinalEvento)) {
              let mes = this.dataFinalEvento.getMonth() + 1;
              let minutos = this.dataFinalEvento.getMinutes();
              let hora    = this.dataFinalEvento.getHours();
              dataFinalGoogle = this.dataFinalEvento.getFullYear().toString() + '-'  + (mes  > 9  ? mes : '0' + mes  ) + '-' + (this.dataFinalEvento.getDate() > 9 ?  this.dataFinalEvento.getDate().toString() : '0' + this.dataFinalEvento.getDate().toString() )+ 'T' + (hora > 9 ? hora : '0' + hora ) + ':' + ( minutos > 9 ? minutos : '0' + minutos ) + ':' + '00'
            }

            // let descricaoEventoGoogle : string = '';

            // descricaoEventoGoogle = '<strong>' +  'Nome: ' + '</strong>' + this.nome + '<br>';
            // descricaoEventoGoogle =  descricaoEventoGoogle + '<strong>' + 'CPF: ' + '</strong>' + this.cpf + '<br>';
            // descricaoEventoGoogle =  descricaoEventoGoogle + '<strong>' + 'Fone: ' + '</strong>' +  this.fone + '<br>';
            // for (let i = 0; i < this.listaPerguntas.length; i++) {
            //   descricaoEventoGoogle =  descricaoEventoGoogle + '<strong>' + this.listaPerguntas[i].perguntaDescricao +  ' ' + '</strong>' + this.listaPerguntas[i].resposta + '<br>' ;
            // }

            // this.modalFormularioResposta = false;
            // this.modalReagendar = false;
            // this.modalCancelarAgendamento = false;


            //https://calendar.google.com/calendar/r/eventedit?text=My+Title&details=My+Description&location=My+Location&dates=20191119T140000/20191122T230000
          // this.mywindow = window.open('https://calendar.google.com/calendar/r/eventedit?text=' +'Re-agendamento' + '&details=' + this.removeTagHtml( this.descricaoEvento) + '<br>'+  this.removeTagHtml( this.ultimaObservacao) + '&add='+ this.seminaristaEmail + '&dates='+ dataInicioGoogle  +'/'+ dataFinalGoogle + '&ctz=America/Sao_Paulo', "_blank"  )
            this.mywindow = window.open('http://outlook.office.com/calendar/0/deeplink/compose?startdt=' + dataInicioGoogle + '&enddt=' + dataFinalGoogle + '&subject=' + this.tipoAtividade + '&body=' + this.removeTagHtml( this.descricaoEvento ) + '&to=' + this.seminaristaEmail );
        }

        this.modalFormularioAgendamento = false;
        this.listarRespostas();
      },
        error => this.httpService.handleError(error, () => this.salvarReagendamento()),
        () => this.httpService.done());

    }

  }

  validacao(){

    if (isNullOrUndefined( this.seminaristaEmail) || this.seminaristaEmail === ''  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar um consultor!'
      });
      return true;
    }

    if (isNullOrUndefined( this.lblFormulario) || this.lblFormulario ==='' || this.lblFormulario.toString() === '0' ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar o evento!'
      });
      return true;

    }


    if (isNullOrUndefined( this.dataInicioEvento)  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar a data de inicio do evento!'
      });
      return true;

    }

    if (isNullOrUndefined( this.dataFinalEvento)  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar a data do final do evento!'
      });
      return true;

    }

    if (this.dataInicioEvento > this.dataFinalEvento) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A data de inicio do evento é maior que a data final do evento!'
      });
      return true;
    }

    let dataAtual         = new Date();
    let dataInicioEvento  = new Date()
    dataInicioEvento      = this.dataInicioEvento;


   if ((dataInicioEvento < dataAtual)) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Data de inicio de Evento maior que a data atual!'
      });
      return true;
   }


    return false;
  }

  showNovoAgendamento(){
    this.modalFormularioAgenda = false;
    this.dataInicioEvento = new Date();
    this.dataFinalEvento  = new Date();
    this.descricaoEvento  = '';
    this.palestranteEmail = '';
    this.tipoAtividade    = '';
    this.palestranteEmail = this.seminaristaEmail;
    this.tipoAtividade    =   this.lblFormulario;
    this.listaEmail       = new Array();

    let time =  new Date();
    time.setDate(this.dataInicioEvento.getDate());
    time.setHours(this.dataInicioEvento.getHours() + 2, this.dataInicioEvento.getMinutes() + 30)
    this.dataFinalEvento = time ;
    //this.descricaoEvento = '<strong>' +  'Nome: ' + '</strong>' + this.nome + '<br>';
    this.descricaoEvento = ' ' +  'Nome: ' + ' ' + this.nome + '<br>';
    //this.descricaoEvento =  this.descricaoEvento + '<strong>' + 'CPF: ' + '</strong>' + this.cpf + '<br>';
    this.descricaoEvento =  this.descricaoEvento + ' ' + 'CPF: ' + ' ' + this.cpf + '<br>';
    //this.descricaoEvento =  this.descricaoEvento + '<strong>' + 'Fone: ' + '</strong>' +  this.fone + '<br>';
    this.descricaoEvento =  this.descricaoEvento + ' ' + 'Fone: ' + ' ' +  this.fone + '<br>';

    for (let i = 0; i < this.listaPerguntas.length; i++) {

     // this.descricaoEvento =  this.descricaoEvento + '<strong>' + this.listaPerguntas[i].perguntaDescricao +  ' ' + '</strong>' + this.listaPerguntas[i].resposta + '<br>' ;
     this.descricaoEvento =  this.descricaoEvento + ' ' + this.listaPerguntas[i].perguntaDescricao +  ' ' + ' ' + this.listaPerguntas[i].resposta + '<br>' ;
    }

    if(this.mywindow) {
      this.mywindow.close();
     }
     // para novos agendamentos
     //https://calendar.google.com/calendar/r/eventedit?text=My+Title&details=My+Description&location=My+Location&dates=20191119T140000/20191122T230000
     this.mywindow = window.open('http://calendar.google.com/calendar/r/eventedit?text=' + this.tipoAtividade + '&details=' + this.descricaoEvento + '&add='+ this.seminaristaEmail + '&ctz=America/Sao_Paulo', "_blank"  )
   // this.mywindow = window.open('https://outlook.office.com/calendar/0/deeplink/compose?startdt=' + dataInicioGoogle + '&enddt=' + dataFinalGoogle + '&subject=' + this.tipoAtividade + '&body=' + this.descricaoEvento   );

  }

  showModalCancelarAgenda(){
    this.comboMotivosCancelamento();
    this.descricaoEvento = '';
    this.formularioAgendamentoHistorico = new FormularioAgendamentoHistorico();
    this.formularioAgendamentoHistorico.formularioCamposCarga = new FormularioCamposCarga();
    this.formularioAgendamentoHistorico.formularioCamposCarga.id  = this.formularioCamposCargaId;
    this.formularioAgendamentoHistorico.formularioCamposCargaId   = this.formularioCamposCargaId;
    this.formularioAgendamentoHistorico.formularioAgendamentoStatus = new FormularioAgendamentoStatus();
    this.formularioAgendamentoHistorico.formularioAgendamentoStatus.id = 3;
    this.formularioAgendamentoHistorico.formularioAgendamentoStatusId  = 3;
    this.formularioAgendamentoHistorico.observacao                     = '';

    this.modalCancelarAgendamento = true;
  }

  comboMotivosCancelamento(){
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-lista-formularios-cancelamento')
      .subscribe(retorno => {

        this.opcoesMotivosCancelamento = [];
        this.opcoesMotivosCancelamento.push({label: 'Selecione um motivo', value: 0});
        retorno.forEach(i =>{
          this.opcoesMotivosCancelamento.push({label: i.descricao , value: i.id});
        })
      },
        error => this.httpService.handleError(error, () => this.comboMotivosCancelamento()),
        () => this.httpService.done());

   }

   comboMotivosReagendamento(){
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-lista-formulario-motivo-reagendamento')
      .subscribe(retorno => {

        this.opcoesMotivosReagendamento = [];
        this.opcoesMotivosReagendamento.push({label: 'Selecione um motivo', value: 0});
        retorno.forEach(i =>{
          this.opcoesMotivosReagendamento.push({label: i.descricao , value: i.id});
        })
      },
        error => this.httpService.handleError(error, () => this.comboMotivosReagendamento()),
        () => this.httpService.done());

   }

   salvarCancelamentoAgendado(){
    if (this.validacaoCancelamento()) {
      return;
    }


    this.formularioAgendamentoHistorico = new FormularioAgendamentoHistorico();
    this.formularioAgendamentoHistorico.formularioCamposCarga = new FormularioCamposCarga();
    this.formularioAgendamentoHistorico.formularioCamposCarga.id  = this.formularioCamposCargaId;
    this.formularioAgendamentoHistorico.formularioCamposCargaId   = this.formularioCamposCargaId;
    this.formularioAgendamentoHistorico.formularioAgendamentoStatus = new FormularioAgendamentoStatus();
    this.formularioAgendamentoHistorico.formularioAgendamentoStatus.id = 3;
    this.formularioAgendamentoHistorico.formularioAgendamentoStatusId  = 3;
    this.formularioAgendamentoHistorico.formularioMotivoCancelamento   = new FormularioMotivoCancelamento();
    this.formularioAgendamentoHistorico.formularioMotivoCancelamentoId = this.motivoCancelamentoId;
    this.formularioAgendamentoHistorico.observacao                     = this.removeTagHtml( this.descricaoEvento);
    this.formularioAgendamentoHistorico.dataCancelamento               = this.dataCancelamento;

    this.modalReagendar = false;


    const listaEnviar = {
      formularioAgendamentoHistorico   : this.formularioAgendamentoHistorico,
      usuarioId                        : this.sessionService.loggedUser.id,
      seminaristaEmail                   : this.seminaristaEmail

   };


   this.httpService.wait();
   this.httpService.post('/custom/polozi/salvar-cancelamento-formulario', listaEnviar)
     .subscribe(result => {

       if (result[0].error) {

         this.messageService.add({
           severity: 'error',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result[0].message
         });
       } else {

         this.messageService.add({
           severity: 'success',key: 'messageLinnks',
           summary: 'MSG: ',
           detail: result[0].message
         });


       }

       this.modalCancelarAgendamento   = false;
       this.modalFormularioAgendamento = false;
       this.modalFormularioAgenda      = false;
       this.modalFormularioResposta    = false;
       this.listarRespostas();
     },
       error => this.httpService.handleError(error, () => this.salvarCancelamentoAgendado()),
       () => this.httpService.done());


   }

   validacaoCancelamento(){


    if (isNullOrUndefined(this.dataCancelamento)) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar o evento!'
      });
      return true;
    }


    return false;
   }

   validacaoReagendamento(){

    if (isNullOrUndefined( this.seminaristaEmail) || this.seminaristaEmail === ''  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar um consultor!'
      });
      return true;
    }

    if (isNullOrUndefined( this.motivoReagendamentoId) || this.motivoReagendamentoId === 0   ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar o motivo do reagendamento!'
      });
      return true;

    }


    if (isNullOrUndefined( this.dataInicioEvento)  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar a data de inicio do evento!'
      });
      return true;

    }

    if (isNullOrUndefined( this.dataFinalEvento)  ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor selecionar a data do final do evento!'
      });
      return true;

    }

    if (this.dataInicioEvento > this.dataFinalEvento) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A data de inicio do evento é maior que a data final do evento!'
      });
      return true;
    }



    if (isNullOrUndefined( this.descricaoEvento) || this.descricaoEvento === ''   ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A favor descrever o motivo!'
      });
      return true;

    }

    return false;
   }

   showModalReagendar(){
    this.motivoReagendamentoId = 0;
    this.dataInicioEvento = new Date();
    this.dataFinalEvento  = new Date();
    this.descricaoEvento       = '';
    this.dataInicioEvento = new Date();
    this.dataFinalEvento  = new Date();
    this.formularioAgendamentoHistorico = new FormularioAgendamentoHistorico();
    this.formularioAgendamentoHistorico.formularioCamposCarga = new FormularioCamposCarga();
    this.formularioAgendamentoHistorico.formularioCamposCarga.id  = this.formularioCamposCargaId;
    this.formularioAgendamentoHistorico.formularioCamposCargaId   = this.formularioCamposCargaId;
    this.formularioAgendamentoHistorico.formularioAgendamentoStatus = new FormularioAgendamentoStatus();
    this.formularioAgendamentoHistorico.formularioAgendamentoStatus.id = 2;
    this.formularioAgendamentoHistorico.formularioAgendamentoStatusId  = 2;
    this.formularioAgendamentoHistorico.observacao                     = '';
    this.comboMotivosReagendamento();

    this.modalReagendar = true;
   }

   getHistoricoAgendamento(){
    this.listaHistorico = new Array();
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-formulario-lista-historico',[this.formularioCamposCargaId])
      .subscribe(retorno => {

        this.listaHistorico = new Array();
        retorno.forEach(e =>{
          this.listaHistorico.push(e);
        });

      },
        error => this.httpService.handleError(error, () => this.listarRespostas()),
        () => this.httpService.done());

   }

   tabChange(event: any){


    switch (event.index) {
      case 1:
        this.getHistoricoAgendamento();
        break;

      default:
        break;
    }


   }

   removeTagHtml(str : string){
    let regex = new RegExp('&nbsp;');
    str =  str.replace(regex,' ');
    str = str.replace( /&nbsp;/g, ' ');
    str = str.replace(/&Aacute;/g,'Á');
    str = str.replace(/&aacute;/g,'á');
    str = str.replace(/&Acirc;/g,'Â');
    str = str.replace(/&acirc;/g,'â');
    str = str.replace(/&Agrave;/g,'À');
    str = str.replace(/&agrave;/g,'à');
    str = str.replace(/&Aring;/g,'Å');
    str = str.replace(/&aring;/g,'å');
    str = str.replace(/&Atilde;/g,'Ã');
    str = str.replace(/&atilde;/g,'ã');
    str = str.replace(/&Auml;/g,'Ä');
    str = str.replace(/&auml;/g,'ä');
    str = str.replace(/&AElig;/g,'Æ');
    str = str.replace(/&aelig;/g,'æ');
    str = str.replace(/&Eacute;/g,'É');
    str = str.replace(/&eacute;/g,'é');
    str = str.replace(/&Ecirc;/g,'Ê');
    str = str.replace(/&ecirc;/g,'ê');
    str = str.replace(/&Egrave;/g,'È');
    str = str.replace(/&egrave;/g,'è');
    str = str.replace(/&Euml;/g,'Ë');
    str = str.replace(/&euml;/g,'ë');
    str = str.replace(/&ETH;/g,'Ð');
    str = str.replace(/&eth;/g,'ð');
    str = str.replace(/&Iacute;/g,'Í');
    str = str.replace(/&iacute;/g,'í');
    str = str.replace(/&Icirc;/g,'Î');
    str = str.replace(/&icirc;/g,'î');
    str = str.replace(/&Igrave;/g,'Ì');
    str = str.replace(/&igrave;/g,'ì');
    str = str.replace(/&Iuml;/g,'Ï');
    str = str.replace(/&iuml;/g,'ï');
    str = str.replace(/&Oacute;/g,'Ó');
    str = str.replace(/&oacute;/g,'ó');
    str = str.replace(/&Ocirc;/g,'Ô');
    str = str.replace(/&ocirc;/g,'ô');
    str = str.replace(/&Ograve;/g,'Ò');
    str = str.replace(/&ograve;/g,'ò');
    str = str.replace(/&Oslash;/g,'Ø');
    str = str.replace(/&oslash;/g,'ø');
    str = str.replace(/&Otilde;/g,'Õ');
    str = str.replace(/&otilde;/g,'õ');
    str = str.replace(/&Ouml;/g,'Ö');
    str = str.replace(/&ouml;/g,'ö');
    str = str.replace(/&Uacute;/g,'Ú');
    str = str.replace(/&uacute;/g,'ú');
    str = str.replace(/&Ucirc;/g,'Û');
    str = str.replace(/&ucirc;/g,'û');
    str = str.replace(/&Ugrave;/g,'Ù');
    str = str.replace(/&ugrave;/g,'ù');
    str = str.replace(/&Uuml;/g,'Ü');
    str = str.replace(/&uuml;/g,'ü');
    str = str.replace(/&Ccedil;/g,'Ç');
    str = str.replace(/&ccedil;/g,'ç');
    str = str.replace(/&Ntilde;/g,'Ñ');
    str = str.replace(/&ntilde;/g,'ñ');
    str = str.replace(/&quot;/g, '"');
    str = str.replace(/&amp;/g, '&');
    str = str.replace(/&lt;/g, '<');
    str = str.replace(/&gt;/g, '>');
    str = str.replace(/&copy;/g, '©');
    str = str.replace(/&ordm;/g, 'º');
    str = str.replace(/&ordf;/g, 'ª');
    str = str.replace(/&ldquo;/g, '"');
    str = str.replace(/&rdquo;/g, '"');
    str = str.replace(/%/g, '');
    str = str.replace(/&ndash;/g, '');
    str = str.replace(/\<[\/]{0,1}div[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}iframe class[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}iframe[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}a href[^\>]*\>/g, '');
    //str = str.replace(/\<[\/]{0,1}p[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}ul[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}li[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}ol[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}h1[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}h2[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}h3[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}h4[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}h5[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}h6[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}blockquote[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}p data-track-category[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}span[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}src[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}br[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}a[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}section[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}figure[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}img[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}srcset[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}time[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}hr[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}link[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}<[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}>[^\>]*\>/g, '');
    //str = str.replace(/\<[\/]{0,1}<br>[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}--[^\>]*\>/g, '');
    str = str.replace(/\<[\/]{0,1}strong[^\>]*\>/g, '');



    //str = str.replace(/\<[\/]{0,1}p[^\>]*\>/g, '');
    // str = str.replace(/\<[\/]{0,1}%[^\>]*\>/g, '');


    return str;
  }


  formataEspacoHtml(str : string){
    str = str.replace( /&%20;/g, ' ');
    return str;
  }


  getComboFormularioAgendamentoStatus(){

    this.httpService.wait();
    this.httpService
      .get('/formularioagendamentostatus?$select=id,descricao&$orderby=descricao')
      .subscribe(
        rst => {
          this.opcoesComboStatus = new Array();
          this.opcoesComboStatus.push({label: '', value : ''});

          rst.value.forEach(element => {
            this.opcoesComboStatus.push({label: element.descricao, value : element.descricao});
          });
        },
        error => this.httpService.handleError(error, () => this.getComboFormularioAgendamentoStatus()),
        () => this.httpService.done()
      );

  }

  onFilter(event: any){

  }

}








