import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { TitleService } from 'app/shared/services/title.service'
import { MessageService } from 'primeng/api'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { SessionService } from 'app/shared/services/session.service'
import { Subscription } from 'rxjs/Subscription'
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http'
import { ConfigService } from 'app/shared/services/config.service'
import { UtilService } from 'app/shared/services/util.service'
import { HttpService } from 'app/shared/services/http.service'

@Component({
  selector: 'app-importnf',
  templateUrl: './import-fiscal.component.html',
  styleUrls: ['./import-fiscal.component.scss']
})

export class FiscalImportComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileInput: ElementRef

  private sessionSubscription: Subscription

  file: File
  listTipoArquivo: Array<any> = [
    { label: 'Arquivo da prefeitura de Campinas (EXCEL)', value: 3 },
    { label: 'Arquivo da prefeitura de Jundiaí (CSV)', value: 4 },
    { label: 'Arquivo da prefeitura de São Paulo (CSV)', value: 5 },
    { label: 'Arquivo Importação DCTF (EXCEL)', value: 6 },
    { label: 'Cupom Fiscal (TXT)', value: 7 }
  ]
  listRelatorio: Array<any> = [
    { label: 'Relatório de notas recebidas', value: 1 },
    { label: 'Relatório de notas emitidas', value: 2 }
  ]
  idTipoArquivo: Number
  idRelatorio: Number
  filename: string
  msgerror: any
  isRelatorioMuni: boolean = false
  httpOptions: any = {}
  reader = new FileReader()

  constructor(private titleService: TitleService,
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private httpService: HttpService,
    private configService: ConfigService,
    private utilservice: UtilService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.titleService.title = 'Importar ou exportar arquivos de Notas Fiscais'
    Promise.resolve(null).then(() => this.toolbarService.hidden = true)
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.httpOptions = { headers: new HttpHeaders({'Authorization': this.configService.bearerApi})}
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription)
  }

  changeFileType(tipo) {
    this.isRelatorioMuni = false;
    if (tipo === 3 || tipo === 4 || tipo === 5) {
      this.isRelatorioMuni = true;
    }
  }

  fileChange() {
    this.file = this.fileInput.nativeElement.files[0];
    this.filename = this.file.name;
  }

  confirm() {
    if (this.idTipoArquivo === 6 || this.idTipoArquivo === 7) this.importar()

    else if (this.idTipoArquivo === 3 || this.idTipoArquivo === 4 || this.idTipoArquivo === 5) {
      const httpOptions = {
        headers: new HttpHeaders({
          'tipo': this.idTipoArquivo.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'relatorio': this.idRelatorio.toString(),
          'Authorization': this.configService.bearerApi,
          'mes': this.dblinkedSessionService.periodo.month.value < 10 ? '0' + this.dblinkedSessionService.periodo.month.value.toString() : this.dblinkedSessionService.periodo.month.value.toString(),
          'ano': this.dblinkedSessionService.periodo.year.value.toString(),
        })
      };

    const formData = new FormData();
    formData.append('file', this.file);
    if (!this.utilservice.validArquivo(this.filename)) {
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'fiscal/confirm', formData, httpOptions)
        .subscribe(
          res => {
            this.confirmPeriodo(res);
          },
          err => {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro:', detail: 'Erro no API ao verificar os dados, ocntate o suporte: ' + err.message });
            console.log(err)
          }
        );

    } else {

      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Aviso:', detail: 'O nome do arquivo não pode conter carateres acentuados e/ou especiais.' });
    }
  }
  }

  confirmPeriodo(value: any) {
    if (value === 1) {
      if (confirm('Período já possui informações, deseja substituir?')) {
        this.importar();
      }

    } else if (value === 0) {
      this.importar();

    } else if (value === 2) {

      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Aviso:', detail: 'Período Encerrado, não é possível fazer alterações!' });

    } else if (value.error) {
      this.exibirMensagem(value);
    }
  }

  importar() {
    //Nova rota importação DCTF
    if (this.idTipoArquivo === 6) {
      this.importDctf()
      return
    }

    //O resto permanece o mesmo
    let httpOptions = null;
    //if (this.idTipoArquivo === 3 || this.idTipoArquivo === 4 || this.idTipoArquivo === 5 || this.idTipoArquivo === 6) {
    if (this.idTipoArquivo === 3 || this.idTipoArquivo === 4 || this.idTipoArquivo === 5) {
      httpOptions = {
        headers: new HttpHeaders({
          'tipo': this.idTipoArquivo.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'relatorio': this.idRelatorio === undefined ? '0' : this.idRelatorio.toString(),
          'Authorization': this.configService.bearerApi,
          'mes': this.dblinkedSessionService.periodo.month.value < 10 ? '0' + this.dblinkedSessionService.periodo.month.value.toString() : this.dblinkedSessionService.periodo.month.value.toString(),
          'ano': this.dblinkedSessionService.periodo.year.value.toString()
        })
      };
    }

    else if (this.idTipoArquivo === 7) {
      httpOptions = {
        headers: new HttpHeaders({
          'tipo': this.idTipoArquivo.toString(),
          'userid': this.sessionService.loggedUser.id.toString(),
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'Authorization': this.configService.bearerApi
        })
      };
    }

    const formData = new FormData();
    formData.append('file', this.file);

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'fiscal/importar', formData, httpOptions)
      .subscribe(
        res => {
          this.msgerror = res
          if (this.idTipoArquivo === 6 && this.msgerror.error === false) {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0'
              }),

              responseType: 'blob' as 'json'
            };

            this.httpService.wait();
            this.httpClient.get(this.configService.defaultUrlApiUpload + 'fiscal/baixar-dctf', httpOptions2)
              .subscribe(
                (res: any) => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);

                  link.href = url;
                  let fileName = `dctf_arquivo.dec`;
                  link.download = fileName;
                  link.click();

                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                err => {
                  console.log(err)
                  this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro:', detail: 'Erro no API ao importaro arquivo, contate o suporte: ' + err.message });
                  this.httpService.done();
                });
            this.exibirMensagem(res);
          }

          this.exibirMensagem(res);
          this.httpService.done();
        },
        err => {
          console.log(err)
          this.exibirMensagem({error: true, mensagem: "Erro no API, contate o suporte: " + err.message});
          this.httpService.done();
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });
    }

    else {

      this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  async importDctf() {
    const formData = new FormData()
    formData.append('file', this.file)
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'Cache-Control': 'no-store, max-age=0'
      }),
      responseType: 'blob' as 'json',
    }
    this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aguarde', detail: "O arquivo está sendo gerado, favor aguardar..."})
    this.httpService.wait()
    try {
      await this.httpClient.post(this.configService.defaultUrlApiUpload + 'fiscal/dctf?userId=' + this.sessionService.loggedUser.id, formData, httpOptions).toPromise()
        .then((res: any) => {
          let link = document.createElement('a')
          let url = window.URL.createObjectURL(res)
          let fileName = "arquivo_dctf.dec"
          link.href = url
          link.download = fileName
          link.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((err: HttpErrorResponse) => {
          let response = this.convertBlobErrorToJson(err)
          this.reader.onload = (e) => {
            let res = JSON.parse(e.target.result as string)
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: res["message"] + ": " + res["data"] })
          }

          this.reader.readAsText(response)

    })
    } catch (err) {
        this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro: ', detail: "Erro no API ao gerar o arquivo DCTF, contatar o suporte: "+ err["message"] })
    } finally {
      this.httpService.done()
    }
  }

  convertBlobErrorToJson(httpErrorBlob: HttpErrorResponse){
    const blb = new Blob([httpErrorBlob.error], {type: 'application/json'})
    return blb
  }
}
