import { OperacaoRegraFiscalDetalhe } from 'app/cadastro/model/operacao-regra-fiscal-detalhe.model';
import { CstIcmsTabelaB } from 'app/util/model/cst-icms-tabela-b.model';
import { AuthUser } from 'app/proof/model/auth-user.model';


export class OperacaoRegraFiscalDetalheCfopRelac {
  id: number;
  operacaoRegraFiscalDetalhe: OperacaoRegraFiscalDetalhe;
  cfopOrig: string;
  cfopDest: string;
  cstIcmsTabelaB: CstIcmsTabelaB;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;


  constructor(operacaoRegraFiscalDetalheCfopRelac?: OperacaoRegraFiscalDetalheCfopRelac | number) {
    if (operacaoRegraFiscalDetalheCfopRelac !== undefined && operacaoRegraFiscalDetalheCfopRelac !== null) {
      if (typeof operacaoRegraFiscalDetalheCfopRelac === 'number') {
        this.id = operacaoRegraFiscalDetalheCfopRelac;
      } else {
        this.id = operacaoRegraFiscalDetalheCfopRelac.id;
        this.operacaoRegraFiscalDetalhe = operacaoRegraFiscalDetalheCfopRelac.operacaoRegraFiscalDetalhe;
        this.cfopOrig = operacaoRegraFiscalDetalheCfopRelac.cfopOrig;
        this.cfopDest = operacaoRegraFiscalDetalheCfopRelac.cfopDest;
        this.cstIcmsTabelaB = operacaoRegraFiscalDetalheCfopRelac.cstIcmsTabelaB;
        this.createTimestamp = operacaoRegraFiscalDetalheCfopRelac.changeTimestamp;
        this.changeUser = operacaoRegraFiscalDetalheCfopRelac.changeUser;
        this.changeTimestamp = operacaoRegraFiscalDetalheCfopRelac.changeTimestamp;
      }
    }
  }
}
