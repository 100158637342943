import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { ItemGrupo } from 'app/cadastro/model/item-grupo.model';
import { ItemGrupoTributacao } from 'app/cadastro/model/item-grupo-tributacao.model';
import { ImpostoRegraFiscal } from 'app/cadastro/model/imposto-regra-fiscal.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';

@Component({
  selector: 'app-item-grupo-tributacao',
  templateUrl: './item-grupo-tributacao.component.html',
  styleUrls: ['./item-grupo-tributacao.component.scss'],
  providers: [CrudService]
})
export class ItemGrupoTributacaoComponent extends DataTableBasicComponent implements OnInit, OnDestroy {
  public itemGrupoTributacao: ItemGrupoTributacao = new ItemGrupoTributacao();

  private empresaSubscription: Subscription;
  private beforeSaveSubscription: Subscription;

  entityFilter: string = null;

  constructor(private crudService: CrudService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  ngOnInit() {

    this.beforeSaveSubscription = this.crudService.beforeSave.subscribe((itemGrupoTributacao: ItemGrupoTributacao) => {



      console.log(this.crudService.form.get('itemGrupo').value);

      if (this.crudService.form.get('itemGrupo').value) {
        itemGrupoTributacao.itemGrupo = new ItemGrupo(this.crudService.form.get('itemGrupo').value.id);
      }
    });

    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
      () => { this.empresaChanged(); });

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
    this.sessionService.destroySubscribe(this.beforeSaveSubscription);
  }

  empresaChanged() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.entityFilter = 'empresa/id eq ' + this.dblinkedSessionService.empresa.id;

      this.columns = [
        new DataTableColumn('Item Grupo', 'itemGrupo.descricao'),
        new DataTableColumn('Imposto', 'impostoRegraFiscal.nome'),
        new DataTableColumn('Alíquota', 'aliquotaImposto'),
        new DataTableColumn('Redução BC', 'reducaoBc'),
        new DataTableColumn('Valor Pauta', 'valorPauta')
      ];

      this.controls = [
        new DataControl('Item Grupo', 'itemGrupo')
          .setTypeSelect()
          .setOptions({
            url: '/itemgrupo?$select=id,codigo,descricao'
              + '&$orderby=codigo',
            value: 'id',
            label: ['codigo', ' - ', 'descricao']
          }),
        new DataControl('Imposto', 'impostoRegraFiscal')
          .setTypeSelect()
          .setOptions({
            url: '/impostoregrafiscal?$select=id,nome'
              + '&$orderby=nome',
            value: 'id',
            label: ['nome']
          }),
        new DataControl('Alíquota', 'aliquotaImposto')
        .setRequired(),
        new DataControl('Redução Bc', 'reducaoBc')
        .setRequired(),
        new DataControl('Valor Pauta', 'valorPauta')
        .setTypeMoney()
      ];
    }
  }

  get hasEmpresaSelected(): boolean {
    return this.dblinkedSessionService.hasEmpresaSelected;
  }

  get loading(): boolean {
    return this.httpService.loading;
  }
}
