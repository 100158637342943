import { EventosFichaPagamento } from "./eventos-ficha-pagamento.model";
import { Palestra } from "./palestra.model";
import { EventosFichaDependente} from './evetos-ficha-dependente.model';

export class EventosFicha {
  id: number;
  empresaId: number;
  authUserId: number;
  cpf: String;
  nome: String;
  telefone: String;
  email: String;
  cep: String;
  logradouro: String;
  numero: String;
  bairro: String;
  complemento: String;
  ufId: number;
  municipioId: number;
  eventoId: number;
  ingressosId: number;
  tipoPagto: number;
  depositoId: number;
  valor: String;
  numeroCartao: String;
  dtValidade: Date;
  ccv: String;
  parceladoem: String;
  participanteId: number;
  eventoFichaId: number;
  dtPrimeiroVencimento: Date;
  valorParcela: String;
  fichasPagamento         : Array<EventosFichaPagamento>;
  palestraId              : number;
  atendente               : String;
  vendedorId                : number;
  observacao              : Text;
  statusEventoId          : number;
  novoEventoFicha         : boolean;
  eventoDadosAdicionais   : Text;
  eventosFichaDependente  : Array<EventosFichaDependente>;
  palestra                : Palestra;
  valorTotal              : number;
  dataVenda               : Date;
  depId                   : number;
  cnpj                    : string;
  substituirBoletoAnterior : boolean;
  deptoEmpresaId          : number;


  constructor(eventosFicha?: EventosFicha | number) {
    if (eventosFicha !== undefined && eventosFicha !== null) {
      if (typeof eventosFicha === 'number') {
        this.id = eventosFicha;
      } else {
        this.id = eventosFicha.id;
        this.empresaId = eventosFicha.empresaId;
        this.authUserId = eventosFicha.authUserId;
        this.cpf = eventosFicha.cpf;
        this.nome = eventosFicha.nome;
        this.telefone = eventosFicha.telefone;
        this.email = eventosFicha.email;
        this.cep = eventosFicha.cep;
        this.logradouro = eventosFicha.logradouro;
        this.numero = eventosFicha.numero;
        this.bairro = eventosFicha.bairro;
        this.complemento = eventosFicha.complemento;
        this.ufId = eventosFicha.ufId;
        this.municipioId = eventosFicha.municipioId;
        this.eventoId = eventosFicha.eventoId;
        this.ingressosId = eventosFicha.ingressosId;
        this.tipoPagto = eventosFicha.tipoPagto;
        this.depositoId = eventosFicha.depositoId;
        this.valor = eventosFicha.valor;
        this.numeroCartao = eventosFicha.numeroCartao;
        this.dtValidade = eventosFicha.dtValidade;
        this.ccv = eventosFicha.ccv;
        this.parceladoem = eventosFicha.parceladoem;
        this.participanteId = eventosFicha.participanteId;
        this.eventoFichaId = eventosFicha.eventoFichaId;
        this.dtPrimeiroVencimento = eventosFicha.dtPrimeiroVencimento;
        this.valorParcela = eventosFicha.valorParcela;
        this.fichasPagamento        = eventosFicha.fichasPagamento;
        this.palestraId             = eventosFicha.palestraId;
        this.atendente              = eventosFicha.atendente;
        this.vendedorId               = eventosFicha.vendedorId;
        this.observacao             = eventosFicha.observacao;
        this.statusEventoId         = eventosFicha.statusEventoId;
        this.novoEventoFicha        = eventosFicha.novoEventoFicha;
        this.eventoDadosAdicionais  = eventosFicha.eventoDadosAdicionais;
        this.eventosFichaDependente = eventosFicha.eventosFichaDependente;
        this.palestra               = eventosFicha.palestra;
        this.valorTotal             = eventosFicha.valorTotal;
        this.dataVenda              = eventosFicha.dataVenda;
        this.depId                  = eventosFicha.depId;
        this.cnpj                   = eventosFicha.cnpj;
        this.substituirBoletoAnterior = eventosFicha.substituirBoletoAnterior;
        this.deptoEmpresaId           = eventosFicha.deptoEmpresaId;

      }
    }
  }

}
