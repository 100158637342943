import { Eventos } from './eventos.model';
import { FormaPagamento } from 'app/util/model/forma-pagamento.model';


export class EventoFormaPgtoRelac {
  id: number;
  evento: Eventos;
  formaPagamento: FormaPagamento;


  constructor(eventoFormaPgtoRelac?: EventoFormaPgtoRelac | number) {
    if (eventoFormaPgtoRelac !== undefined && eventoFormaPgtoRelac !== null) {
      if (typeof eventoFormaPgtoRelac === 'number') {
        this.id = eventoFormaPgtoRelac;
      } else {
        this.id = eventoFormaPgtoRelac.id;
        this.evento = eventoFormaPgtoRelac.evento;
        this.formaPagamento = eventoFormaPgtoRelac.formaPagamento;
      }
    }
  }

}