import { Component, OnInit, OnDestroy } from '@angular/core';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { SelectItem } from 'app/shared/common/select-item.interface';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { CadCcus } from 'app/contabil/model/cad-ccus.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';
import { PlanoContas } from '../model/plano-contas.model';
import { CadProj } from '../model/cad-proj.model';
import { isNullOrUndefined } from 'util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { Service } from 'app/shared/services/socket/protocol/Service';
import { Action } from 'app/shared/services/socket/protocol/Action';
import { Method } from 'app/shared/services/socket/protocol/Method';
import { PysocketService } from 'app/shared/services/socket/pysocket.service';
import idv4 from 'app/util/processing/idv4';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class EcdReportComponent implements OnInit, OnDestroy {
  ecdList: Array<any> = null;

  private sessionSubscription: Subscription;

  private timezonePipe: TimezonePipe;
  selectedReport: string;
  pt: any;
  dtIni: Date;
  dtFin: Date;
  dtCorte: Date;
  minDate: Date;
  maxDate: Date;
  defaultDate: Date;

  tiposRelatorios: any[];
  relatorios: any[];
  meses: any[];
  opcoesVisao: any[];
  opcoesGerencial: any[];
  idiomas: any[];
  messtring: String;
  opcoesRelatorios: any[];
  opcoesRelatoriosExternos: any[];
  relExterno: number;

  listaRelat: Array<any> = new Array();
  dicRelatComposto: any = {};
  datasIndividuais: boolean = false;
  relatorioGerSelecionado: any;
  modalRelatorioGer: boolean = false;
  dataRef: Date = new Date();


  tipoRelatorio: string;
  gerencial: number;
  visao: number;
  mesIni: number;
  mesFin: number;
  mesCorte: number;
  relatorioEscolhido: number;
  tipoUsGaap: number;
  centroCustos: Array<CadCcus> = new Array();
  cadEcdList: Array<CadEcd> = new Array();
  centroCustosOpcoes: Array<CadCcus> = new Array();
  opcoesCentroCustos: SelectItem[];
  opcoesNivel: SelectItem[];
  ccSelecionado: CadCcus;
  antesEncerramento: boolean;
  separaCentroCusto: boolean;
  somenteResultado: boolean;
  visaoGerencial: boolean;
  titlePrincipal: string;
  acumulado: boolean;
  visaoTabela: boolean;
  subtitle: string;
  idioma: string;
  leidobemId: Array<number> = new Array()
  projetosLdB: SelectItem[]
  relatorioDreBalanco: string

  statusBalancenteMensal: boolean = false;
  statusEvolucaoMensal: boolean = false;
  statusPlanoDeContas: boolean = false;
  statusBalancoPatrimonial: boolean = false;
  statusLalur: boolean = false;
  statusRazaoContabil: boolean = false;
  statusPl: boolean = false;
  statusTitAberto: boolean = false;
  statusGerencial: boolean = false;
  statusLdB: boolean = false;
  statusPadis: boolean = false;
  statusRelExt: boolean = false;
  ccs: CadCcus[];
  centroCustoSelecionados: CadCcus[];
  nivelSelecionados: number[];

  opcoesPlanoContas: SelectItem[];
  opcoesProjetos: SelectItem[];
  opcoesProjetosRel: SelectItem[];
  opcoesTipoUsGaap: SelectItem[];
  planoContasSelecionados: PlanoContas[];
  projetosSelecionados: CadProj[];
  optDreBalanco: SelectItem[] = [
    { value: null, label: "Selecionar" },
    { value: "dre", label: "DRE" },
    { value: "balanco", label: "Balanço Patrimonial" },
  ]

  primeiroDiadoMes: Date;
  ultimoDiadiaMes: Date;

  existeRelatorioExterno: boolean = false;


  exibirDtIni: boolean = false;
  exibirDtFin: boolean = false;
  exibirFilial: boolean = false;
  filialRelatorio: string = '';
  empresaId: any;
  tokenProgressoSocket: string = null;
  private messagesSubscription: Subscription
  isLoadingInProgress: boolean = false
  loadingProgress: number = 0

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService,
    private socketService: PysocketService) {
    this.timezonePipe = new TimezonePipe();
  }

  ngOnInit(): void {

    this.titlePrincipal = 'RELATÓRIOS';
    this.subtitle = 'RELATÓRIOS CONTÁBEIS PARA VISUALIZAÇÃO NA TELA OU EXPORTAR PARA EXCEL';
    this.relatorioGerSelecionado = {};
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.change();
      });
    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };
    this.defaultDate = new Date();
    this.mesCorte = 0;
    this.separaCentroCusto = false;
    this.somenteResultado = false;
    this.idioma = 'pt-BR';
    this.relatorioEscolhido = 0;
    this.tipoUsGaap = 0;
    this.gerencial = 0;
    this.visao = 0;

    this.opcoesProjetos = [];
    this.projetosSelecionados = [];

    this.opcoesCentroCustos = [];

    this.centroCustoSelecionados = [];


    this.opcoesPlanoContas = [];
    this.planoContasSelecionados = [];

    this.empresaId = this.dblinkedSessionService.empresa.id;

    this.messagesSubscription = this.socketService.socketData.subscribe((response) => {
      if (response.service === Service.NOTIFICATION && response.action === Action.LOADING && response.method === Method.GET) {
        this.loadingManagement(response)
      }
    })
  }

  gerarLdB() {

    if (this.leidobemId.length < 1) {
      this.exibirMensagem({ error: true, mensagem: "Selecionar pelo menos um projeto Lei do Bem" })
      return
    }

    const infos = {
      user_id: this.sessionService.loggedUser.id,
      empresa_id: this.dblinkedSessionService.empresa.id,
      dt_ini: this.dtIni.getFullYear() + "-" + (this.dtIni.getMonth() + 1) + "-" + this.dtIni.getDate(),
      dt_max: this.dtFin.getFullYear() + "-" + (this.dtFin.getMonth() + 1) + "-" + this.dtFin.getDate(),
      lildb: this.leidobemId
    }

    this.httpService.wait()
    this.httpService.post(this.configService.defaultUrlApiUpload + 'contabil/relatorio-ldb', infos)
      .subscribe(
        res => {
          this.exibirMensagem(res)
          this.httpService.done()
          if (res.error) return

          const httpOptions2 = {
            headers: new HttpHeaders({
              'Authorization': this.configService.bearerApi,
              'Cache-Control': 'no-store, max-age=0',
              'userId': this.sessionService.loggedUser.id.toString()
            }),
            responseType: 'blob' as 'json'
          }

          this.httpService.wait()
          this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/relatorio-ldb', httpOptions2)
            .subscribe(
              (res: Blob) => {
                let link = document.createElement('a')
                let url = window.URL.createObjectURL(res)
                let fileName = 'relatorio_ldb.xlsx'

                link.href = url
                link.download = fileName
                link.click()

                window.URL.revokeObjectURL(url)
                this.httpService.done()
              }, err => {
                this.exibirMensagem({ error: true, mensagem: "Erro no API ao baixar o relatório, contate o suporte: " + err.message })
                console.log(err)
                this.httpService.done()
              })


        },
        err => {
          console.log(err)
          this.exibirMensagem({ error: true, mensagem: "Erro no API ao gerar o relatório, contate o suporte: " + err.message })
          this.httpService.done()
        }
      )

    this.statusLdB = false
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  loadLeiDoBem() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };
    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/leidobem/loadleidobem', httpOptions)
      .subscribe(
        res => {
          this.projetosLdB = [];
          let result = res["mensagem"];
          for (let line of result) {
            this.projetosLdB.push(line);
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  centroCustoLoad(value: Array<CadCcus>) {
    this.centroCustosOpcoes = value;
    this.opcoesCentroCustos = [];
    this.centroCustoSelecionados = [];
    this.opcoesCentroCustos.push({ label: 'Em branco', value: null });
    if (this.centroCustosOpcoes.length > 0) {
      this.centroCustosOpcoes.forEach(element => {
        this.opcoesCentroCustos.push({ label: element.codCcus + ' - ' + element.descrCcus, value: element.id });
        if (!isNullOrUndefined(this.opcoesCentroCustos)) {
          this.opcoesCentroCustos.map(item => this.centroCustoSelecionados.push(item.value));
        }
      });
    }

  }

  nivelLoad() {


    this.opcoesNivel = [];

    this.nivelSelecionados = [];
    this.opcoesNivel.push({ label: '1', value: 1 });
    this.opcoesNivel.push({ label: '2', value: 2 });
    this.opcoesNivel.push({ label: '3', value: 3 });
    this.opcoesNivel.push({ label: '4', value: 4 });
    this.opcoesNivel.push({ label: '5', value: 5 });
    this.opcoesNivel.map(item => this.nivelSelecionados.push(item.value));
  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  data() {
    this.dtCorte = new Date(this.timezonePipe.transform(this.dtCorte));
  }

  change() {
    if (!this.dblinkedSessionService.hasEmpresaSelected || !this.dblinkedSessionService.hasYearSelected) {
      return;
    }

    if (!isNullOrUndefined(this.dblinkedSessionService.periodo)) {
      this.primeiroDiadoMes = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 1);
      this.ultimoDiadiaMes = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value + 1, 0);

      this.mesIni = this.dblinkedSessionService.periodo.month.value;
      this.mesFin = this.dblinkedSessionService.periodo.month.value;
      this.mesCorte = this.dblinkedSessionService.periodo.month.value;
      this.dtIni = this.primeiroDiadoMes;
      this.dtFin = this.ultimoDiadiaMes;


    } else {
      this.primeiroDiadoMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth(), 1);
      this.ultimoDiadiaMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);

      this.mesIni = this.defaultDate.getMonth();
      this.mesFin = this.defaultDate.getMonth();
      this.mesCorte = this.defaultDate.getMonth();
      this.dtIni = this.primeiroDiadoMes;
      this.dtFin = this.ultimoDiadiaMes;


    }

    this.opcoesCentroCustos = [];

    if (this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id) {

      if (this.dblinkedSessionService.periodo.month.value !== (this.utilService.currentMonth(this.defaultDate) + 1)) {
        const mes = this.dblinkedSessionService.periodo.month.value.toString.length === 2 ?
          this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
        const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
        this.defaultDate = new Date(dt);
      }
      this.dtIni = this.defaultDate;
      this.dtFin = this.defaultDate;
      this.httpService.wait();
      this.httpService.get('/cadccus?$select=id,dtAlt,codCcus,descrCcus' +
        '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
        '&$orderby=codCcus')
        .subscribe(cadCcus => this.centroCustoLoad(cadCcus.value),
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());

      this.meses = [];
      this.meses.push({ label: 'Selecione', value: 0 });
      this.meses.push({ label: 'Janeiro', value: 1 });
      this.meses.push({ label: 'Fevereiro', value: 2 });
      this.meses.push({ label: 'Março', value: 3 });
      this.meses.push({ label: 'Abril', value: 4 });
      this.meses.push({ label: 'Maio', value: 5 });
      this.meses.push({ label: 'Junho', value: 6 });
      this.meses.push({ label: 'Julho', value: 7 });
      this.meses.push({ label: 'Agosto', value: 8 });
      this.meses.push({ label: 'Setembro', value: 9 });
      this.meses.push({ label: 'Outubro', value: 10 });
      this.meses.push({ label: 'Novembro', value: 11 });
      this.meses.push({ label: 'Dezembro', value: 12 });

      this.httpService.get('/custom/ecd-report/possui-relat-externo(' +
        this.dblinkedSessionService.empresa.id + ')')
        .subscribe(ref => this.relExt(ref),
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());


      this.comboPlanoContas();
      this.comboProjetos();
      this.comboIdiomas();
      this.opcoesRelat();
      this.opcoesUsGaap();
      this.opcoesGerenciais();
      this.opcoesVisoes();
      this.nivelLoad();
      this.getConfigRelatorios();
    }

    this.dtCorte = null;
    this.maxDate = null;
    this.minDate = null;
    this.mesIni = 0;
    this.mesFin = 0;
    this.ccSelecionado = new CadCcus();
    this.antesEncerramento = false;
    this.acumulado = false;
    this.visaoTabela = false;
    this.visaoGerencial = false;

    this.titleService.title = 'ECD - Relatórios';
    this.relatorios = [];
    this.relatorios.push({ label: 'Balancete Mensal', value: 'balanceteMensal' });
    this.relatorios.push({ label: 'Evolução Mensal', value: 'evolucaoMensal' });
    this.relatorios.push({ label: 'Plano de Contas', value: 'planoContas' });
    this.relatorios.push({ label: 'Lalur', value: 'lalur' });
    this.relatorios.push({ label: 'Razão Contábil', value: 'razaoContabil' });
    this.relatorios.push({ label: 'Balanço Patrimonial e DRE', value: 'balancopatrimonial' });
    this.tiposRelatorios = [];
    this.tiposRelatorios.push({ label: 'Contas a Receber', value: 'CR' });
    this.tiposRelatorios.push({ label: 'Contas a Pagar', value: 'CP' });
  }

  download(result: any, bool: boolean) {
    if (!result.error) {

      if (bool) {
        this.windowOpen(this.httpService.url('/custom/file/' + result.file + '/contabilhtml'), '_blank');
      } else {
        this.windowOpen(this.httpService.url('/custom/file/' + result.file), '_blank');
      }

    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: result.message });
    }

    this.httpService.done()
  }

  periodosLoad(element: Array<any>) {
    this.meses = [];
    this.meses.push({ label: 'Selecione', value: 0 });

    element.forEach(e => {
      this.meses.push({ label: e.mes, value: e.valor });
    });
  }

  opcoesRelat() {
    this.opcoesProjetosRel = [];
    this.opcoesProjetosRel.push({ label: 'Selecione', value: 0 });
    this.opcoesProjetosRel.push({ label: 'P&L', value: 1 });
    this.opcoesProjetosRel.push({ label: 'USGAAP', value: 2 });
    this.opcoesProjetosRel.push({ label: 'USGAAP - Mensal Acumulado', value: 3 });
    this.opcoesProjetosRel.push({ label: 'USGAAP - Relatório de Carga', value: 4 });

  }

  opcoesUsGaap() {
    this.opcoesTipoUsGaap = [];
    this.opcoesTipoUsGaap.push({ label: 'Selecione', value: 0 });
    this.opcoesTipoUsGaap.push({ label: 'LOCAL', value: 1 });
    this.opcoesTipoUsGaap.push({ label: 'HOST', value: 2 });
  }

  reportPdf(data: any) {
    let inicio: string;
    if (this.mesIni < 10) {
      inicio = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesIni + '-01';
    } else {
      inicio = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesIni + '-01';
    }

    let final: string;

    if (this.mesFin === 1 || this.mesFin === 3 || this.mesFin === 5 || this.mesFin === 7 ||
      this.mesFin === 8 || this.mesFin === 10 || this.mesFin === 12) {
      if (this.mesFin < 10) {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-31';
      } else {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesFin + '-31';
      }
    } else if (this.mesFin === 4 || this.mesFin === 6 || this.mesFin === 9 || this.mesFin === 11) {
      if (this.mesFin < 10) {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-30';
      } else {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesFin + '-30';
      }
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2016') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2020') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2024') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() !== '2016') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-28';
    }


    this.selectedReport = data;
    this.httpService.wait();
    this.httpService.post('/custom/ecd-report/export(' + this.dblinkedSessionService.empresa.cnpj + ',' +
      this.dblinkedSessionService.periodo.year.value + ',' + this.selectedReport + ','
      + inicio + ','
      + final + ','
      + this.antesEncerramento + ','
      + (this.ccSelecionado && this.ccSelecionado.id ? this.ccSelecionado.id : 0) + ')')
      .subscribe(result => this.download(result, false),
        error => this.httpService.handleError(error, () => this.reportPdf(data)),
        () => this.httpService.done());
  }

  reportXls(data: any) {
    let inicio: string;
    if (this.mesIni < 10) {
      inicio = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesIni + '-01';
    } else {
      inicio = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesIni + '-01';
    }

    let final: string;

    if (this.mesFin === 1 || this.mesFin === 3 || this.mesFin === 5 || this.mesFin === 7 ||
      this.mesFin === 8 || this.mesFin === 10 || this.mesFin === 12) {
      if (this.mesFin < 10) {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-31';
      } else {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesFin + '-31';
      }
    } else if (this.mesFin === 4 || this.mesFin === 6 || this.mesFin === 9 || this.mesFin === 11) {
      if (this.mesFin < 10) {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-30';
      } else {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesFin + '-30';
      }
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2016') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2020') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2024') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() !== '2016') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-28';
    }

    this.selectedReport = data;

    if (this.validationLedger()) return
    if (this.isReportUsingLoading(data)) {
      this.hideReportDialog(data);
      this.initProgressLoading();
    } else {
      this.tokenProgressoSocket = null;
    }
    const listasEnviar = {
      cnpj: this.dblinkedSessionService.empresa.cnpj,
      selectedReport: this.selectedReport,
      ano: this.dblinkedSessionService.periodo.year.value,
      final: final,
      antesEncerramento: this.antesEncerramento,
      visaoGerencial: this.visaoGerencial,
      centrosCusto: this.centroCustoSelecionados,
      planoContas: this.planoContasSelecionados,
      projetos: this.projetosSelecionados,
      usuario: this.sessionService.loggedUser.id,
      dtIni: this.dtIni,
      dtFin: this.dtFin,
      mesCorte: this.mesCorte,
      separaCentroCusto: this.separaCentroCusto,
      somenteResultado: this.somenteResultado,
      idioma: this.idioma,
      inicio: inicio,
      acumuado: this.acumulado,
      visaoTabela: this.visaoTabela,
      filiais: this.dblinkedSessionService.filiaisSelecionadas,
      tokenProgressoSocket: this.tokenProgressoSocket,
      empresaId: this.dblinkedSessionService.empresa.id,
      qtdeContas: this.planoContasSelecionados.length
    };
    if (!this.isReportUsingLoading(data)) {
      this.httpService.wait();
    }
    this.httpService.post('/custom/ecd-report/export-xls-plano-contas', listasEnviar)
      .subscribe(result => {
        this.download(result, false);
        this.isLoadingInProgress = false
      },
        error => this.httpService.handleError(error, () => this.reportXls(data)),
        () => this.httpService.done());

  }

  validationLedger(): boolean {
    if (this.selectedReport === 'razaoContabil') {
      if (this.planoContasSelecionados.length === 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: "Selecione pelo menos 01 (uma) conta para gerar o relatório!" })
        return true
      }

      if (this.centroCustoSelecionados.length === 0) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: "Selecione pelo menos 01 (um) centro de custo para gerar o relatório!" })
        return true
      }

      return false
    }
  }

  reportPl(data: any) {

    const listasEnviar = {
      mesCorte: this.mesFin,
      acumulado: this.acumulado,
      empresa: this.dblinkedSessionService.empresa.id,
      year: this.dblinkedSessionService.periodo.year.value,
      relatorio: this.relatorioEscolhido,
      visao: this.tipoUsGaap

    };

    if (this.relatorioEscolhido === 1) {
      this.httpService.wait();
      this.httpService.post('/custom/ecd-report/export-xls-pl', listasEnviar)
        .subscribe(result => this.download(result, false),
          error => this.httpService.handleError(error, () => this.reportXls(data)),
          () => this.httpService.done());
    } else {
      this.httpService.wait();
      this.httpService.post('/custom/usgaap/usgaap-xls', listasEnviar)
        .subscribe(result => this.download(result, false),
          error => this.httpService.handleError(error, () => this.reportXls(data)),
          () => this.httpService.done());
    }


  }


  relatpatrimonial() {
    this.statusBalancoPatrimonial = false
    this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso', detail: "Estamos processando sua solicitação. Seu arquivo chegará em seu email em breve" })

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'Cache-Control': 'no-store, max-age=0',
        'userid': this.sessionService.loggedUser.id.toString()
      })
    }

    const payload = {
      company_id: this.dblinkedSessionService.empresa.id,
      user_id: this.sessionService.loggedUser.id.toString(),
      month: this.mesCorte,
      year: this.dblinkedSessionService.periodo.year.value
    }

    if (this.relatorioDreBalanco === "balanco") {
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'contabil/exportar-balanco-patrimonial', payload, httpOptions).subscribe(
        (response) => this.exibirMensagem({ error: false, mensagem: response['message'] }),
        responseError => {
          let errorMessage = "Erro ao enviar o Balanço Patrimonial, contate o suporte"
          if(responseError.error && responseError.error.message) errorMessage = responseError.error.message
          this.exibirMensagem({ error: true, mensagem: errorMessage })
        }
      )
    }

    if (this.relatorioDreBalanco == "dre") {
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'contabil/exportar-relatorio-dre', payload, httpOptions).subscribe(
        (response) => this.exibirMensagem({ error: false, mensagem: response['message'] }),
        responseError => {
          let errorMessage = "Erro ao enviar o DRE, contate o suporte"
          if(responseError.error && responseError.error.message) errorMessage = responseError.error.message
          this.exibirMensagem({ error: true, mensagem: errorMessage })
        }
      )
    }
  }

  reportTitAberto(data: any) {

    const listasEnviar = {
      mes: this.mesFin,
      tipo: this.tipoRelatorio,
      empresa: this.dblinkedSessionService.empresa.id,
      ano: this.dblinkedSessionService.periodo.year.value

    };
    this.httpService.wait();
    this.httpService.post('/custom/conciliacao-report/report-tit-aberto', listasEnviar)
      .subscribe(result => this.download(result, false),
        error => this.httpService.handleError(error, () => this.reportXls(data)),
        () => this.httpService.done());

  }

  reportPc(data: any) {
    let inicio: string;
    if (this.mesIni < 10) {
      inicio = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesIni + '-01';
    } else {
      inicio = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesIni + '-01';
    }

    let final: string;

    if (this.mesFin === 1 || this.mesFin === 3 || this.mesFin === 5 || this.mesFin === 7 ||
      this.mesFin === 8 || this.mesFin === 10 || this.mesFin === 12) {
      if (this.mesFin < 10) {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-31';
      } else {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesFin + '-31';
      }
    } else if (this.mesFin === 4 || this.mesFin === 6 || this.mesFin === 9 || this.mesFin === 11) {
      if (this.mesFin < 10) {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-30';
      } else {
        final = this.dblinkedSessionService.periodo.year.value.toString() + '-' + this.mesFin + '-30';
      }
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2016') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2020') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() === '2024') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-29';
    } else if (this.mesFin === 2 && this.dblinkedSessionService.periodo.year.value.toString() !== '2016') {
      final = this.dblinkedSessionService.periodo.year.value.toString() + '-0' + this.mesFin + '-28';
    }


    if (data === 'planoContas') {
      this.windowOpen('/ecd/report-plano-contas/' + this.dblinkedSessionService.empresa.id, '_blank');
    } else if (data === 'balanceteMensal') {
      this.windowOpen('/ecd/report-balancete-mensal/' + this.dblinkedSessionService.empresa.id + '/' + inicio + '/' + final +
        '/' + this.antesEncerramento + '/' + this.idioma, '_blank');
    }
    else if (data === 'evolucaoMensal') {
      let ccs: string;
      if (this.centroCustoSelecionados) {
        this.centroCustoSelecionados.forEach(element => {
          if (element !== undefined) {
            if ((element === null)) {
              if (ccs !== undefined) {
                ccs = ccs + '.' + 'branco';
              } else {
                ccs = 'branco';
              }
            } else {
              if (ccs !== undefined) {
                ccs = ccs + '.' + element;
              } else {
                ccs = '' + element;
              }
            }
          }
        });
      } else {
        ccs = 'nenhum';
      }
      if (ccs === undefined) {
        ccs = 'nenhum';
      }

      let cps: string;
      if (this.projetosSelecionados) {
        this.projetosSelecionados.forEach(element => {
          if (element !== undefined) {
            if ((element === null)) {
              if (cps !== undefined) {
                cps = cps + '.' + 'branco';
              } else {
                cps = 'branco';
              }
            } else {
              if (cps !== undefined) {
                cps = cps + '.' + element;
              } else {
                cps = '' + element;
              }
            }
          }
        });
      } else {
        cps = 'nenhum';
      }
      if (cps === undefined) {
        cps = 'nenhum';
      }
      this.windowOpen('/ecd/report-evolucao-mensal/' + this.dblinkedSessionService.empresa.id + '/' +
        this.mesCorte + '/' + this.dblinkedSessionService.periodo.year.value + '/' +
        ccs + '/' + cps + '/' + this.idioma, '_blank');

    } else if (data === 'lalur') {
      this.windowOpen('/lalur/parte-a-m300-irpj');
    } else if (data === 'gerencial') {
      this.windowOpen('/ecd/report-gerencial/' + this.dblinkedSessionService.empresa.id + '/' + this.mesIni
        + '/' + this.dblinkedSessionService.periodo.year.value + '/' + this.gerencial + '/' + this.visao, '_blank');
    }
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  windowOpen(url: string, target?: string) {
    setTimeout(() => {
      window.open(url, target), 1;
    });
  }

  showDialogRelatorio(tipo) {

    if (!isNullOrUndefined(this.dblinkedSessionService.periodo)) {
      this.primeiroDiadoMes = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1);
      this.ultimoDiadiaMes = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value + 0, 0);

      this.mesIni = this.dblinkedSessionService.periodo.month.value;
      this.mesFin = this.dblinkedSessionService.periodo.month.value;
      this.mesCorte = this.dblinkedSessionService.periodo.month.value;
      this.dtIni = this.primeiroDiadoMes;
      this.dtFin = this.ultimoDiadiaMes;


    } else {
      this.primeiroDiadoMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth(), 1);
      this.ultimoDiadiaMes = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);

      this.mesIni = this.defaultDate.getMonth();
      this.mesFin = this.defaultDate.getMonth();
      this.mesCorte = this.defaultDate.getMonth();
      this.dtIni = this.primeiroDiadoMes;
      this.dtFin = this.ultimoDiadiaMes;

    }
    this.projetosSelecionados = [];
    this.centroCustoSelecionados = [];
    this.planoContasSelecionados = [];

    if (!isNullOrUndefined(this.opcoesProjetos) && this.opcoesProjetos.length > 0) {
      this.opcoesProjetos.map(item => this.projetosSelecionados.push(item.value));
    }

    if (!isNullOrUndefined(this.opcoesCentroCustos) && this.opcoesCentroCustos.length > 0) {
      this.opcoesCentroCustos.map(item => this.centroCustoSelecionados.push(item.value));
    }

    if (!isNullOrUndefined(this.opcoesPlanoContas) && this.opcoesPlanoContas.length > 0) {
      this.opcoesPlanoContas.map(item => this.planoContasSelecionados.push(item.value));
    }

    if (tipo === 'BALANCETE_MENSAL') {

      this.statusBalancenteMensal = true;
    } else if (tipo === 'EVOLUCAO_MENSAL') {
      this.statusEvolucaoMensal = true;
    } else if (tipo === 'PLANO_DE_CONTAS') {
      this.statusPlanoDeContas = true;
    } else if (tipo === 'LALUR') {
      this.statusLalur = true;
    } else if (tipo === 'RAZAO_CONTABIL') {

      this.statusRazaoContabil = true;
    } else if (tipo === 'REPORT_PL') {
      this.statusPl = true;
    } else if (tipo === 'TIT_ABERTO') {
      this.statusTitAberto = true;
    } else if (tipo === 'GERENCIAL') {
      this.statusGerencial = true;
    } else if (tipo === 'BalancoPatrimonial') {
      this.statusBalancoPatrimonial = true;
    }
    else if (tipo === 'LEI_DO_BEM') {
      this.statusLdB = true
      this.dtIni = new Date(this.dblinkedSessionService.periodo.year.value, 0, 1)
      this.dtFin = new Date(this.dblinkedSessionService.periodo.year.value, 11, 31)
      this.loadLeiDoBem()
    }

    else if (tipo === 'PADIS') {
      this.statusPadis = true;
      this.dtIni = new Date(this.dblinkedSessionService.periodo.year.value, 0, 1);
      this.dtFin = new Date(this.dblinkedSessionService.periodo.year.value, 11, 31);

    } else if (tipo === 'EXT') {
      this.statusRelExt = true;

    } else {
      this.relatorioGerSelecionado = tipo;
      this.modalRelatorioGer = true;
      this.dataRef = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1);
    }
  }

  gerarRelatorioPadis() {

    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      dt_ini: this.dtIni.getFullYear() + "-" + (this.dtIni.getMonth() + 1) + "-" + this.dtIni.getDate(),
      dt_fin: this.dtFin.getFullYear() + "-" + (this.dtFin.getMonth() + 1) + "-" + this.dtFin.getDate(),
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpService.post(this.configService.defaultUrlApiUpload + 'contabil/relat-padis', infos)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res)
            this.httpService.done()

          } else {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0',
                'userid': this.sessionService.loggedUser.id.toString()
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'contabil/relat-padis', httpOptions2)
              .subscribe(
                (res: Blob) => {
                  let link = document.createElement('a')
                  let url = window.URL.createObjectURL(res)
                  let fileName = "Relatório PADIS.xlsx"
                  link.href = url
                  link.download = fileName
                  link.click()
                  window.URL.revokeObjectURL(url)
                  this.httpService.done()
                },
                err => {
                  this.exibirMensagem({ error: true, mensagem: "Erro ao baixar o relatório PADIS, contate o suporte: " + err.message })
                  console.log(err)
                  this.httpService.done()
                });
          }
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro ao gerar o relatório PADIS, contate o suporte: " + err.message })
          console.log(err)
          this.httpService.done()
        });
  }

  comboPlanoContas() {
    this.opcoesPlanoContas = [];
    this.planoContasSelecionados = [];



    if (this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id) {


      this.httpService.wait();
      this.httpService.get('/custom/cadastro/get-planocontas-completo',
        [this.dblinkedSessionService.empresa.id, this.dblinkedSessionService.periodo.year.value])
        .subscribe(ref => {

          ref.forEach(element => {

            // SIMON LALLEMENT 11/04/2022
            //Tirei intencionalmente a conta da BS2 da PayLivre temporiaramente até achar um jeito de gerar o razão dele
            if (element.indCta === 'A' && element.id !== 1201129) {
              this.opcoesPlanoContas.push({ label: element.codCta + ' - ' + element.descrCta, value: element.id });
            }

          });

          if (!isNullOrUndefined(this.opcoesPlanoContas) && this.opcoesPlanoContas.length > 0) {
            this.opcoesPlanoContas.map(item => this.planoContasSelecionados.push(item.value));
          }


        }

          , error => this.httpService.handleError(error, () => this.comboPlanoContas()),
          () => this.httpService.done());

    }
  }

  comboProjetos() {
    if (this.dblinkedSessionService.empresa && this.dblinkedSessionService.empresa.id) {
      this.httpService.wait();
      this.httpService.get('/cadproj?$select=id,codProj,descrProj' +
        '&$filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
        '&$orderby=codProj')
        .subscribe(proj => {
          this.opcoesProjetos = [];
          this.opcoesProjetos.push({ label: 'Em branco', value: null });
          proj.value.forEach(pj => {
            this.opcoesProjetos.push({ label: pj.codProj + ' - ' + pj.descrProj, value: pj.id });
          });

        },
          error => this.httpService.handleError(error, () => this.comboProjetos()),
          () => this.httpService.done());
    }
  }

  comboIdiomas() {
    this.idiomas = [];
    this.idiomas.push({ label: 'Português', value: 'pt-BR' });
    this.idiomas.push({ label: 'Inglês', value: 'EN' });
  }

  opcoesGerenciais() {
    this.httpService.wait();
    this.httpService.get('/planogerencial?$select=id,codigo,nome,niveis,moeda,view/id&$' +
      'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id)
      .subscribe(ref => {
        this.opcoesGerencial = [];
        this.opcoesGerencial.push({ label: 'Selecione', value: null });
        ref.value.forEach(ger => {
          this.opcoesGerencial.push({ label: ger.codigo + ' - ' + ger.nome, value: ger.id });
        });

      },
        error => this.httpService.handleError(error, () => this.opcoesGerenciais()),
        () => this.httpService.done());
  }

  opcoesVisoes() {
    this.opcoesVisao = [];
    this.opcoesVisao.push({ label: 'Selecione', value: 0 });
    this.opcoesVisao.push({ label: 'Diária', value: 1 });
    this.opcoesVisao.push({ label: 'Mensal', value: 2 });
  }


  gerarRelatorioGerencialExcel() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi
      })
    };

    let config_list = [];
    if (this.relatorioGerSelecionado.is_composta) {
      for (let conf of this.dicRelatComposto[this.relatorioGerSelecionado.id]) {
        if (this.datasIndividuais && conf['dataRef'] == null) {
          this.exibirMensagem({ error: true, mensagem: "Informe uma data para todos os relatórios ou desmarque a opção de datas individuais." });
          return;
        }

        config_list.push({
          'empresa_id': this.dblinkedSessionService.empresa.id,
          'dt_ref': this.datasIndividuais ? [conf['dataRef'].getDate(), conf['dataRef'].getMonth() + 1, conf['dataRef'].getFullYear()] : [this.dataRef.getDate(), this.dataRef.getMonth() + 1, this.dataRef.getFullYear()],
          'config_id': conf['config_relat_ref_id'],
          'worksheet_title': conf['worksheet_title']
        });
      }
    } else {
      config_list = [{
        'empresa_id': this.dblinkedSessionService.empresa.id,
        'dt_ref': [this.dataRef.getDate(), this.dataRef.getMonth() + 1, this.dataRef.getFullYear()],
        'config_id': this.relatorioGerSelecionado.id
      }];
    }

    const infos = {
      list_infos: config_list,
      is_composta: this.relatorioGerSelecionado.is_composta,
      user_id: this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', infos, httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);
            this.httpService.done();

          } else {
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0',
                'userid': this.sessionService.loggedUser.id.toString()
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'workstats/relat-gerencial', httpOptions2)
              .subscribe(
                (res: Blob) => {
                  let link = document.createElement('a');
                  let url = window.URL.createObjectURL(res);
                  let fileName = "Relatório gerencial.xlsx";
                  link.href = url;
                  link.download = fileName;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  this.httpService.done();
                },
                err => {
                  this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o relatório." });
                  console.log(err);
                  this.httpService.done();
                });
          }
        },
        err => {
          this.exibirMensagem({ error: true, mensagem: "Erro no API: não foi possível baixar o relatório." });
          console.log(err);
          this.httpService.done();
        });
  }

  renderizarRelatorioGerencial() {
    var data_ref: string = this.dataRef.getDate() + '-' + (this.dataRef.getMonth() + 1).toString() + '-' + this.dataRef.getFullYear();
    window.open('/workstats/render-relatorios-gerenciais/' + this.dblinkedSessionService.empresa.id.toString() + '/' + this.relatorioGerSelecionado.id + '/' + this.relatorioGerSelecionado.plano_gerencial_id + '/' + data_ref, '_blank');
  }

  getConfigRelatorios() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString(),
        'modulo': '1'
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/config-relat', httpOptions)
      .subscribe(
        res => {
          if (res["error"]) {
            this.exibirMensagem(res);

          } else {
            this.listaRelat = new Array();
            for (var line of res["mensagem"]) {
              this.listaRelat.push(line);
            }

            this.dicRelatComposto = res["dicconfigs_compostas"];
          }
          this.httpService.done();
        },
        err => {
          console.log(err);
          this.httpService.done();
        }
      );
  }

  carregarValores(config) {
    let replicar = false;

    for (let dicConfig of this.dicRelatComposto[this.relatorioGerSelecionado.id]) {
      if (config['id'] === dicConfig['id']) replicar = true;

      if (replicar) {
        dicConfig['dataRef'] = config['dataRef'];
      }
    }
  }

  relExt(value: any) {
    this.opcoesRelatoriosExternos = [];
    this.opcoesRelatoriosExternos.push({ label: 'Selecione', value: null });

    this.existeRelatorioExterno = value.length > 0 ? true : false;

    value.forEach(element => {
      this.opcoesRelatoriosExternos.push({ label: element.nome, value: element.id, param: element.parametro });
    });

  }


  gerarRelatorioExterno() {
    const listasEnviar = {
      cnpj: this.dblinkedSessionService.empresa.cnpj,
      dtIni: this.dtIni,
      dtFin: this.dtFin,
      relExterno: this.relExterno,
      filial: this.filialRelatorio
    };
    this.httpService.wait();
    this.httpService.post('/custom/ecd-report/gerar-relatorio-externo', listasEnviar)
      .subscribe(result => this.downloadRelExt(result),
        error => this.httpService.handleError(error, () => this.gerarRelatorioExterno()),
        () => this.httpService.done());
  }

  downloadRelExt(result: any) {
    if (result.message !== 'infocarregada') {
      this.windowOpen(this.httpService.url('/custom/file/' + result.file), '_blank');
    } else {
      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: 'Informação carregada com sucesso!' });
    }

  }

  relatorioExterno(event: any) {
    this.exibirDtIni = false;
    this.exibirDtFin = false;
    this.exibirFilial = false;
    this.filialRelatorio = '';

    this.opcoesRelatoriosExternos.forEach(element => {


      if (!isNullOrUndefined(element.value)) {
        if (element.value == event.value) {

          if (element.param.toString().match('dtIni')) {

            this.exibirDtIni = true;
          }
          if (element.param.toString().match('dtFin')) {

            this.exibirDtFin = true;
          }
          if (element.param.toString().match('filial')) {

            this.exibirFilial = true;
          }
        }
      }
    });
  }

  relatBalancetePdf() {
    this.statusBalancenteMensal = false;
    this.initProgressLoading()
    let inicio: string;
    let final: string;
    let ano = this.dblinkedSessionService.periodo.year.value
    let anoString = this.dblinkedSessionService.periodo.year.value.toString()
    inicio = anoString + '-' + (this.mesIni < 10 ? '0' + this.mesIni : this.mesIni) + '-01'
    final = anoString + '-' + (this.mesFin < 10 ? '0' + this.mesFin : this.mesFin) + '-' + new Date(ano, this.mesFin, 0).getDate().toString()

    const infos = {
      empresaId: this.dblinkedSessionService.empresa.id,
      tokenProgressoSocket: this.tokenProgressoSocket,
      dataIni: inicio,
      dataFin: final,
      encerramento: false,
      idioma: this.idioma,
      user_id: this.sessionService.loggedUser.id
    };

    try {
      this.httpService.post('custom/ecd-report/exportar-pdf-balancete-old', infos).subscribe(
        async (res) => {
          await new Promise(f => setTimeout(f, 1500));
          this.isLoadingInProgress = false
          this.download(res, true)
        },
        (err) => {
          this.exibirMensagem({ "error": true, "mensagem": "Erro no API: Contate o suporte." });
          this.httpService.done();
        },

      )
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        key: 'messageLinnks',
        summary: 'Erro',
        detail: 'Houve um erro ao baixar o PDF. Entre o contato com o suporte.'
      })
      this.httpService.done()
    }
  }

  loadingManagement(data: any) {
    if (data.content.tokenProgressoSocket == this.tokenProgressoSocket) {
      this.loadingProgress = Math.round(data.content.progress);
    }
  }

  initProgressLoading() {
    this.loadingProgress = 0
    this.tokenProgressoSocket = idv4()
    this.isLoadingInProgress = true
  }

  hideReportDialog(data) {
    switch (data) {
      case "razaoContabil":
        this.statusRazaoContabil = false;
        break;
      case "balanceteMensal":
        this.statusBalancenteMensal = false;
        break;
      case "evolucaoMensal":
        this.statusEvolucaoMensal = false;
        break;
      case "planoContas":
        this.statusPlanoDeContas = false;
        break;
    }
  }

  isReportUsingLoading(data): boolean {
    if (data == "razaoContabil" || data == "balanceteMensal" || data == "evolucaoMensal" || data == "planoContas") return true;
    return false;
  }


}
