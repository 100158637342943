import { Component, OnInit, OnDestroy } from '@angular/core';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { Vendedor } from '../model/vendedor.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import exportExcel from 'app/util/processing/exportExcel';

@Component({
  selector: 'app-vendedor',
  templateUrl: './vendedor.component.html',
  styleUrls: ['./vendedor.component.scss'],
})
export class VendedorComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = [];
  msgsModal: Message[] = [];

  titlePrincipal: string;
  subtitle: string;

  botaoEditar: boolean = false;
  botaoNovo: boolean = false;
  objEditar: any;

  // Requisição = selectitens
  vendedoresLista: Array<any> = new Array();
  vendedor: Vendedor;
  colsVendedoresLista: any[];

  selectedSellers: Array<any> = new Array();

  orderList = ["codigo","nome", "cpf", "email", "telefone", "dtAdmissao", "valorFixo" ]

  opcoesAtivo: SelectItem[] = [{ label: "ATIVO", value: true }, { label: "INATIVO", value: false }]

  constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService) {
  }

  public toolbarMain = new Toolbar();
  toolbarMainIni = [
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.editar() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'red', onClick: () => this.remover() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'green', onClick: () => this.novo() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => exportExcel(this.vendedoresLista, 'VENDEDORES', this.orderList) }
  ];

  public toolbarRegister = new Toolbar();
  toolbarRegisterIni = [
    { icon: 'arrow_back_ios_new', text: 'Voltar', tooltip: 'Voltar', visible: true, disabled: false, color: 'default', onClick: () => this.change() },
    { icon: 'save', text: 'Salvar', tooltip: 'Salvar', visible: true, disabled: false, color: 'green', onClick: () => this.botaoEditar ? this.atualizar() : this.adicionar() },
  ];

  ngOnInit(): void {
    this.toolbarService.hidden = true
    this.toolbarMain.setAll(this.toolbarMainIni);
    this.toolbarRegister.setAll(this.toolbarRegisterIni);
    this.vendedor = new Vendedor();
    this.titlePrincipal = 'CONFIGURAÇÕES';
    this.subtitle = 'VENDEDOR';
    this.titleService.title = 'Cadastro de Vendedores';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.selectedSellers = new Array<any>();
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    this.selectedSellers = [];
    this.botaoNovo = false;
    this.botaoEditar = false;
    this.handleSelect();
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

      this.vendedores();
    }
  }

  onFilterRms(event: any) {
    console.log('event: ' + event);
  }

  adicionar() {
    if (this.validacao()) {
      return;
    }

    let listaEnviar = {
      nome: this.vendedor.nome,
      codigo: this.vendedor.codigo,
      email: this.vendedor.email,
      telefone: this.vendedor.telefone,
      cpf: this.vendedor.cpf,
      usuario: this.sessionService.loggedUser.id,
      empresaId: this.empresa.id,
      criarnovovendedor: true,
      ativo: this.vendedor.ativo,
      visualiza: this.vendedor.visualizaOutrosVendedores,
      dtAdmissao: this.vendedor.dtAdmissao.toLocaleString('default',{day: 'numeric', month: 'numeric', year: 'numeric'}),
      valorFixo: this.vendedor.valorFixo
    }

    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-vendedor', listaEnviar)
      .subscribe(rst => {
        this.vendedores();
        if (rst.error === true) {
          this.messageService.add({
            key: 'messageLinnks',
            severity: 'error',
            summary: 'MSG: ',
            detail: rst.detail
          });

        } else {
          this.messageService.add({
            key: 'messageLinnks',
            severity: 'success',
            summary: 'MSG: ',
            detail: rst.detail
          });
          this.change()
        }
      },
        error => this.httpService.handleError(error, () => console.log('sucesso')),
        () => this.httpService.done());

  }

  vendedores() {
    try {
      this.httpService.wait();
      this.httpService.get('/custom/polozi/listar-vendedores', this.dblinkedSessionService.empresa.id).subscribe(rst => {
        this.vendedoresLista = new Array();
        rst.forEach(element => {
          this.vendedoresLista.push(element);
        });
      }, error => this.httpService.handleError(error, () => this.vendedores()),
        () => this.httpService.done());

      this.vendedor.nome = "";

    } catch (error) {
      console.log(error);
    }
  }

  remover() {
    if(this.selectedSellers.length > 0) {
      if (confirm("Deseja remover este vendedor?")) {
        if(this.dblinkedSessionService.empresa.id) {
          const listIds = [];
          this.selectedSellers.forEach(element => {
            listIds.push(element.id);
          })

          const list = {
            ids: listIds
          }

          this.httpService.wait();
          this.httpService.post('/custom/polozi/deletar-vendedor', list).subscribe(rst => {
            if (rst.severity === "error") {
              this.messageService.add({
                severity: rst.severity, key: 'messageLinnks',
                summary: 'MSG: ',
                detail: rst.detail
              });
            } else {
              this.messageService.add({
                severity: rst.severity,
                key: 'messageLinnks',
                summary: 'MSG: ',
                detail: rst.detail
              });
            }
            this.change();
            this.vendedores();
          }, error => this.httpService.handleError(error, () => this.vendedores()),
            () => this.httpService.done());
        }
      }
    }
  }

  editar() {
    if(this.selectedSellers.length > 0) {
      const value = this.selectedSellers[0];
      this.botaoNovo = true;
      this.botaoEditar = true;
      this.vendedor.nome = value.nome;
      this.vendedor.codigo = value.codigo;
      this.vendedor.cpf = value.cpf;
      this.vendedor.telefone = value.telefone;
      this.vendedor.email = value.email;
      this.vendedor.visualizaOutrosVendedores = value.visualizaOutrosVendedores;
      this.vendedor.ativo = value.ativo;
      this.vendedor.dtAdmissao = value.dtAdmissao;
      this.vendedor.valorFixo = value.valorFixo;
    }
  }

  atualizar() {
    if (this.validacao()) {
      return;
    }

    if(this.selectedSellers.length > 0) {
      const value = this.selectedSellers[0];
      let listaEnviar = {
        vendedorId: value.id,
        nome: this.vendedor.nome,
        codigo: this.vendedor.codigo,
        email: this.vendedor.email,
        telefone: this.vendedor.telefone,
        cpf: this.vendedor.cpf,
        visualiza: this.vendedor.visualizaOutrosVendedores,
        usuario: this.sessionService.loggedUser.id,
        empresaId: this.empresa.id,
        criarnovovendedor: false,
        ativo: this.vendedor.ativo,
        dtAdmissao: this.vendedor.dtAdmissao.toLocaleString('default',{day: 'numeric', month: 'numeric', year: 'numeric'}),
        valorFixo: this.vendedor.valorFixo
      }

      this.httpService.wait();
      this.httpService.post('/custom/polozi/salvar-vendedor', listaEnviar).subscribe(rst => {
        if (rst.error === true) {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst.detail
          });

        } else {
          this.messageService.add({
            severity: 'success', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst.detail
          });
        }
        this.change();

      }, error => this.httpService.handleError(error, () => this.vendedores()


      ),
        () => this.httpService.done());
    }
  }

  hideMessage() {


  }

  novo() {
    this.botaoNovo = !this.botaoNovo;
    if (this.botaoNovo) {
      this.vendedor.nome = "";
      this.vendedor.codigo = "";
      this.vendedor.telefone = "";
      this.vendedor.cpf = "";
      this.vendedor.email = "";
      this.vendedor.visualizaOutrosVendedores = false;
      this.vendedor.ativo = true;
      this.vendedor.dtAdmissao = new Date().toLocaleDateString();
      this.vendedor.valorFixo = 0;
    }
    else {
      this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Criar novo vendedor', () => this.novo());
    }
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  validacao() {
    if (this.vendedor.nome === null || this.vendedor.nome === undefined || this.vendedor.nome.length < 3) {
      this.messageService.add({
        key: 'messageLinnks',
        severity: 'warn',
        summary: 'MSG: ',
        detail: 'O vendedor deve ter o mínimo de 3 caracteres!'
      });
      return true;
    }
    return false;
  }

  handleSelect() {
    this.toolbarMain.setVisible("btn1", this.selectedSellers.length  === 1 ? true : false);
    this.toolbarMain.setVisible("btn2", this.selectedSellers.length  >= 1 ? true : false);
  }
}
