import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { Regra } from 'app/cadastro/model/regra.model';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';

@Component({
  selector: 'app-grupo-relac',
  templateUrl: './grupo-relac.component.html',
  styleUrls: ['./grupo-relac.component.scss'],
  providers: [CrudService]
})
export class GrupoRelacComponent  extends DataTableBasicComponent implements OnInit, OnDestroy {
  public regra: Regra = new Regra();

  private empresaSubscription: Subscription;

  entityFilter: string = null;

  private parentNivel = 1;

  constructor(private crudService: CrudService,
              private httpService: HttpService,
              private sessionService: SessionService,
              private dblinkedSessionService: DblinkedSessionService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('CÓDIGO', 'codCta') // TODO: Fix
    ];
    this.controls = [];

    this.empresaSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.empresaChanged,
                                                                 () => { this.empresaChanged(); });
  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.empresaSubscription);
  }

  empresaChanged() {

this.columns = [
        new DataTableColumn('Codigo', 'cod'),
        new DataTableColumn('Nome', 'nome'),
        new DataTableColumn('Descrição', 'descricao'),
        new DataTableColumn('Ativa', 'ativa').setTypeCheckBox(),
        new DataTableColumn('Homologada', 'homologada').setTypeCheckBox(),
        new DataTableColumn('Grau de Risco', 'grauRisco'),
        new DataTableColumn('Ordem', 'ordem')
      ];

  }
}
