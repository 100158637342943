import { Component, OnInit } from '@angular/core';
import { CrudService } from 'app/shared/services/crud.service';
import { DataTableBasicComponent } from 'app/shared/components/data-table/data-table-basic.component';
import { AuthStatus } from 'app/proof/model/auth-status.model';
import { DataTableColumn } from 'app/shared/components/data-table/model/data-table-column.model';
import { DataControl } from 'app/shared/components/data-table/model/data-control.model';

@Component({
  selector: 'app-auth-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  providers: [CrudService]
})
export class AuthStatusComponent extends DataTableBasicComponent implements OnInit {
  public authStatus: AuthStatus = new AuthStatus();

  constructor(crudService: CrudService) {
    super(crudService);
  }

  ngOnInit() {
    this.columns = [
      new DataTableColumn('ID', 'id'),
      new DataTableColumn('STATUS', 'name'),
    ];

    this.controls = [
      new DataControl('Status', 'name')
        .setRequired()
        .setMinLength(3),
      new DataControl('Descrição', 'description')
    ];
  }
}
