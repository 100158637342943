import { ColumnRelat } from './column-relat.model';

export class ConfigRelat {

  id: number;
  planoGerencialId: number;
  nome: string;
  codigo: string;
  colunas: Array<ColumnRelat>;
  hideNoValueContas: boolean = false;
  isComposta: boolean = false;
  hasDatasIndividuais: boolean = false;
  visaoNivel1: number;
  visaoNivel2: number;
  visaoNivel3: number;
  visaoNivel4: number;
  showFormulas: boolean = false

  constructor(configRelat?: ConfigRelat | number) {
    if (configRelat !== undefined && configRelat !== null) {
      if (typeof configRelat === 'number') {
        this.id = configRelat;
      } else {
        this.id = configRelat.id;
        this.nome = configRelat.nome;
        this.codigo = configRelat.codigo;
        this.planoGerencialId = configRelat.planoGerencialId;
        this.colunas = configRelat.colunas;
        this.hideNoValueContas = configRelat.hideNoValueContas;
        this.isComposta = configRelat.isComposta;
        this.hasDatasIndividuais = configRelat.hasDatasIndividuais;
        this.visaoNivel1 = configRelat.visaoNivel1;
        this.visaoNivel2 = configRelat.visaoNivel2;
        this.visaoNivel3 = configRelat.visaoNivel3;
        this.visaoNivel4 = configRelat.visaoNivel4;
        this.showFormulas = configRelat.showFormulas
      }
    }
  }
}
