import { AuthUser } from 'app/proof/model/auth-user.model';
import { ThemeSettingsModule } from 'app/shared/styles/vendor/libs/theme-settings/theme-settings.module';
import { TodoPainel } from './todo-painel.model';
import { TodoProjeto } from './todo-projeto.model';

export class TodoProjetoOrigem {
  id: number;
  todoProjeto: TodoProjeto;
  origem: string;
  sql: string;
  addPart: boolean;
  addAdm: boolean;
  responsavel: AuthUser;
  participantes: Array<number>
  marcadores: Array<number>
  campoData: string;
  todoPainel: TodoPainel;


  constructor(todoProjetoOrigem?: TodoProjetoOrigem | number) {
    if (todoProjetoOrigem !== undefined && todoProjetoOrigem !== null) {
      if (typeof todoProjetoOrigem === 'number') {
        this.id = todoProjetoOrigem;
      } else {
        this.id = todoProjetoOrigem.id;
        this.origem = todoProjetoOrigem.origem;
        this.todoProjeto = todoProjetoOrigem.todoProjeto;
        this.addPart = todoProjetoOrigem.addPart;
        this.addAdm = todoProjetoOrigem.addAdm;
        this.responsavel = todoProjetoOrigem.responsavel;
        this.participantes = todoProjetoOrigem.participantes;
        this.marcadores = todoProjetoOrigem.marcadores;
        this.campoData = todoProjetoOrigem.campoData;
        this.todoPainel = todoProjetoOrigem.todoPainel;

      }
    }
  }
}
