import { Empresa } from "app/cadastro/model/empresa.model";
import { isNullOrUndefined } from "util";

export class Cargo {
  id: number
  empresa: Empresa = new Empresa()

  constructor(cargo?: Cargo | number) {
    if (!isNullOrUndefined(cargo)) {
      if (typeof  cargo === 'number') {
        this.id = cargo;

      } else {
        this.id = cargo.id
        this.empresa = cargo.empresa
      }
    }

  }
}
