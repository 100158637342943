import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { DH_NOT_SUITABLE_GENERATOR } from 'constants';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Util } from 'app/shared/common/util';
import { isNullOrUndefined } from 'util';
import {EduzzCarga} from 'app/eduzz/model/eduzz-carga.model';
import { Message, MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-eduzz-carga',
  templateUrl: './eduzz-carga.component.html',
  styleUrls: ['./eduzz-carga.component.scss'],
  providers: [CrudService]
})
export class EduzzCargaComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;

  checkedAll = false;

  modalParticipantePorEvento = false;

  statusEvento: SelectItem[];
  desabilitaStatus: boolean;

  modalEditarParticipante: Boolean;
  modalInfCliente : boolean = false;
  selectedIndex   : number = 0;

  depId           : number = 0;
  listEduzzCarga  : Array<any> = new Array();
  opcoesComboStatus : Array<any> = new Array();
  desabilitaSelecao : boolean = false;
  idLista : Array<any> = new Array();
  modalPeriodo : boolean = false;

  dataIni: Date;
  dataFin: Date;
  eduzzCarga :    EduzzCarga;
  boolModalEditar: boolean = false;

  colsEduzzCarga: any[];

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };
  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'EDUZZ CARGA';
    this.subtitle = '';
    this.titleService.title = 'EDUZZ CARGA';

    this.defaultDate = new Date();
    this.dataIni = null;
    this.dataFin = null;
    this.dataFinSearch = null;
    this.dataIniSearch = null;
    this.idLista = new Array();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();

    this.checkedAll = false;
    this.desabilitaSelecao = false;

    this.modalParticipantePorEvento = false;

    this.modalEditarParticipante          = false;

    this.colsEduzzCarga = [
      { field: 'saleId', header: 'SALE ID' },
      { field: 'clientName', header: 'NOME' },
      { field: 'clientEmail', header: 'EMAIL' },
      { field: 'clientDocument', header: 'DOCUMENTO' },
      { field: 'clientCel', header: 'FONE' },
      { field: 'salePaymentMethod', header: 'MET. PAGAMENT.' },
      { field: 'saleTotal', header: 'VALOR' },
      { field: 'contentId', header: 'COD EDUZZ' },
      { field: 'contentTitle', header: 'EVT. NOME' },
      { field: 'dataCriacaoEduzz', header: 'DT CRIA EDUZZ' },
      { field: 'dataPagamenoEduzz', header: 'DT PAGAMENTO' },
      { field: 'saleStatusName', header: 'STATUS' },
  ];

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {

        this.toolbarEnable();
        if (this.dblinkedSessionService.hasEmpresaSelected &&
          this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

            const mes =
            this.dblinkedSessionService.periodo.month.value.toString.length === 2
              ? this.dblinkedSessionService.periodo.month.value
              : '0' + this.dblinkedSessionService.periodo.month.value;
          const dt =
            this.dblinkedSessionService.periodo.year.value +
            '-' +
            mes +
            '-01 12:00:00';
          this.defaultDate = new Date(dt);

            this.listar();

        }
        setTimeout(() => {
          this.configuraToolBar()
        }, 1000);

      });

  }


  hideMessage() {

  }

  configuraToolBar() {
    if (!isNullOrUndefined(this.idLista) && this.idLista.length === 1 ) {
      this.toolbarService.alterarStatus('edit', false, 'Editar Registro', () => this.modalEditar(this.idLista[0]));
      this.toolbarService.alterarStatus('add', false, 'Gerar Integração', () => this.gerarIntegracao() );
    } else if( !isNullOrUndefined(this.idLista) && this.idLista.length > 1){
      this.toolbarService.alterarStatus('add', false, 'Gerar Integração', () => this.gerarIntegracao() );
      this.toolbarService.alterarStatus('edit', true, 'Editar Registro', () => this.modalEditar(this.idLista[0]));
    } else{
      this.toolbarService.alterarStatus('edit', true, 'Editar Registro', () => '');
      this.toolbarService.alterarStatus('add', true, 'Gerar Integração', () => this.gerarIntegracao() );

    }

  }

  onFilterEvento(event: any) {
  }

  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('remove');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');

    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.listar()
    );
    this.toolbarService.replace('save', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());
    this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar Registro', () => '');

    this.toolbarService.replace('add', true, 'class-new-button', 'GERAR', 'Gerar Integração',   () => this.gerarIntegracao()     );

  }


  listar() {
    this.checkedAll = false;
    this.idLista = new Array();

    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    this.httpService.wait();
    this.httpService.get('/custom/eduzz/eduzz-carga(' + [this.dataIniSearch , this.dataFinSearch] +')')
      .subscribe(retorno => {

        this.listEduzzCarga = new Array();
        this.opcoesComboStatus = new Array();
        retorno.forEach(element => {
          this.listEduzzCarga.push(element);

          var index = 0;

          if (this.opcoesComboStatus !== null || this.opcoesComboStatus !== undefined) {
            index = this.opcoesComboStatus.findIndex(e => e.value === element.saleStatusName);
          }

          if (index === -1) {
            this.opcoesComboStatus.push({label: element.saleStatusName , value: element.saleStatusName});
          }

          this.opcoesComboStatus.sort((a,b) => a.label.localeCompare(b.label));


        });


      },
        error => this.httpService.handleError(error, () => this.listar()),
        () => this.httpService.done());

      setTimeout(()=> this.configuraToolBar(), 500);

  }


  ngOnDestroy(): void {
    this.toolbarService.clear();
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

selectCheckbox(id: any, event: any) {
console.log(id);
 if (event.selected) {
    event.selected = true;
    const index = this.idLista.findIndex(i => i === id);
    this.idLista.splice(index,1);

 } else {
   if (event.desabilitaSelecao === false) {
    this.idLista.push(id);
   }

 }

 setTimeout(()=> this.configuraToolBar(), 500);

}

selectTodos(event: any) {
  this.checkedAll = !this.checkedAll;
  this.idLista = new Array();
  this.listEduzzCarga.forEach(i=> {
    if (!this.checkedAll) {
      i.selected = false;
      this.idLista.slice(i.id, 1);
    } else {
      if (i.desabilitaSelecao === false) {
        i.selected = true;
        this.idLista.push(i.id);

      }
    }
  });

  setTimeout(() => this.configuraToolBar() ,100)

}


openModalPeriodo() {


  this.modalPeriodo = true;
  this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
  this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
}


periodoChange() {



  if (isNullOrUndefined(this.dataIni) || this.dataIni.toString() ==='') {
    this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Favor selecionar uma data inicial. Verifique!' });
    return;

  }

  if (isNullOrUndefined(this.dataFin) || this.dataFin.toString() ==='') {
    this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Favor selecionar uma data final. Verifique!' });
    return;
  }

  if (this.dataIni !== null && this.dataFin !== null) {
    const mesIni = this.dataIni.getMonth() +1 > 8 ?
                  (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
    const mesFin = this.dataFin.getMonth() +1 > 8 ?
                  (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
    this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
                  this.dataIni.getDate() : '0' + this.dataIni.getDate());
    this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
                  this.dataFin.getDate() : '0' + this.dataFin.getDate());
  }
  const d1 = new Date(this.dataIniSearch);
  const d2 = new Date(this.dataFinSearch);
  this.modalPeriodo = false;
  if (d1 > d2) {
    this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Data incial maior que a final. Verifique!' });
    return;
  }


  this.httpService.wait();
  this.httpService.get('/custom/eduzz/eduzz-carga(' + [this.dataIniSearch , this.dataFinSearch] +')')
    .subscribe(retorno => {

      this.listEduzzCarga = new Array();
      this.opcoesComboStatus = new Array();
      retorno.forEach(element => {
        this.listEduzzCarga.push(element);

        var index = 0;

        if (this.opcoesComboStatus !== null || this.opcoesComboStatus !== undefined) {
          index = this.opcoesComboStatus.findIndex(e => e.value === element.saleStatusName);
        }

        if (index === -1) {
          this.opcoesComboStatus.push({label: element.saleStatusName , value: element.saleStatusName});
        }

        this.opcoesComboStatus.sort((a,b) => a.label.localeCompare(b.label));

      });

    },
      error => this.httpService.handleError(error, () => this.periodoChange()),
      () => this.httpService.done());

}

modalEditar(id : any){
  this.boolModalEditar = true;
  this.eduzzCarga = new EduzzCarga();
  let index = this.listEduzzCarga.findIndex(e=> e.id === id);
  this.eduzzCarga.id = id;
  this.eduzzCarga.clientName = this.listEduzzCarga[index].clientName;
  this.eduzzCarga.clientCel = this.listEduzzCarga[index].clientCel;
  this.eduzzCarga.clientEmail = this.listEduzzCarga[index].clientEmail;
  this.eduzzCarga.clientDocument = this.listEduzzCarga[index].clientDocument;
  this.eduzzCarga.contentId = this.listEduzzCarga[index].contentId;

}

salvarEduzzCarga(){

  if (this.validacao()) {
    return;
  }

  const listaEnviar = {
    eduzzCarga         : this.eduzzCarga,
    usuarioId            : this.sessionService.loggedUser.id,
    empresaId            : this.dblinkedSessionService.empresa.id,
    IsNovoRegistro       : false
 };

 this.httpService.wait();
 this.httpService.post('/custom/eduzz/salvar-eduzz-carga', listaEnviar)
   .subscribe(result => {

     if (result.error) {
       this.messageService.add({
         severity: 'error',key: 'messageLinnks',
         summary: 'MSG: ',
         detail: result.message
       });
     } else {
       this.messageService.add({
         severity: 'success',key: 'messageLinnks',
         summary: 'MSG: ',
         detail: result.message
       });
     }
     this.boolModalEditar = false;
     this.listar();
   },
     error => this.httpService.handleError(error, () => this.salvarEduzzCarga()),
     () => this.httpService.done());


}


validacao(){

  if (isNullOrUndefined( this.eduzzCarga.clientName) || this.eduzzCarga.clientName.toString() ==='' ) {
    this.messageService.add({
      severity: 'warn',key: 'messageLinnks',
      summary: 'MSG: ',
      detail: 'Favor digitar um nome para o cliente!'
    });
    return true;

  }

if (!isNullOrUndefined(this.eduzzCarga.clientEmail)) {
  if (!Util.validacaoEmail(this.eduzzCarga.clientEmail)) {
    this.messageService.add({
      severity: 'warn',key: 'messageLinnks',
      summary: 'MSG: ',
      detail: 'Email inválido. Verifique!'
    });
    return true;

  }
}

  return false;
}


  gerarIntegracao(){
    const listaEnviar = {
      ids          : this.idLista,
      usuarioId   : this.sessionService.loggedUser.id,
    };


    this.httpService.wait();

    this.httpService.post('/custom/eduzz/gerar-integracao-eduzz-carga', listaEnviar)
    .subscribe(result => {
      if (result.error) {
        this.messageService.add({
          severity: 'error',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result.message
        });
      } else {
        this.messageService.add({
          severity: 'success',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: result.message
        });
      }
      this.boolModalEditar = false;
      this.listar();
    },
      error => this.httpService.handleError(error, () => this.gerarIntegracao()),
      () => this.httpService.done());


  }

  onFilter(event: any) {

  }

  onRowSelect0(event: any) {

  }



}
