import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';

import { AuthUser } from 'app/proof/model/auth-user.model';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { Uf } from 'app/util/model/uf.model';

import { Item } from 'app/cadastro/model/item.model';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { EventosIngressos } from '../model/eventos-ingressos.model';
import { StatusEvento } from 'app/util/model/status-eventos.model';
import { Util } from 'app/shared/common/util';
import { Seminarista } from '../model/seminarista.model';
import { Vendedor } from '../model/vendedor.model';
import { Palestra } from '../model/palestra.model';
import { isNullOrUndefined } from 'util';
import {TipoIngresso} from '../model/tipo-ingresso.model';
import { PalestraIngresso } from '../model/palestra-ingresso.model';
import { PalestraInformacoesAdicionais} from '../model/palestra-informacoes-adicionais.model';
import { ActivatedRoute } from '@angular/router';
import { PalestraCarteiraPorcentagem} from "../model/palestra-carteira-porcentagem.model";
import { Message, MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-palestra',
  templateUrl: './palestra.component.html',
  styleUrls: ['./palestra.component.scss'],
  providers: [CrudService]
})
export class PalestraComponent implements OnInit, OnDestroy  {

  @ViewChild('file') fileInput: ElementRef;
  private sessionSubscription: Subscription;

  title: any;
  subtitle: any;
  defaultDate: Date;
  dataIniSearch: any;
  dataFinSearch: any;

  palestras: Array<any> = new Array();
  modalPalestras: boolean;
  municipios: Array<MunicipioIbge> = new Array();
  ufs: Array<Uf> = new Array();
  palestraObj = new Palestra();
  eventosIngressosObj = new EventosIngressos();
  municipioIbge = new MunicipioIbge();
  itens: Array<Item> = new Array();
  opcoesVendedor: SelectItem[];
  itensSelecionada: number;

  opcoesUF: SelectItem[];
  ufSelecionada: number;

  opcoesMunicipio: SelectItem[];
  municipioSelecionado: number;

   opcoesTipoIngresso: SelectItem[];

  tpingresso: SelectItem[];
  tpingressoValue: number;
  checkedAll = false;
  idPalestraView: Array<any> = new Array();
  idPalestraExcluirView: Array<any> = new Array();
  editarPalestra: boolean;
  palestraId: number;
  modalParticipantePorEvento = false;

  statusEvento: SelectItem[];
  desabilitaStatus: boolean;

  nomeCurso: String;
  lstparticipantePorEventos: Array<any> = new Array();
  idParticipnantePorEventosExcluir: Array<any> = new Array();

  modalEditarParticipante: Boolean;

  opcoesPalestrante: SelectItem[];

  palestraIngresso                  = new PalestraIngresso();
  palestraIngressoTabela            = new PalestraIngresso();
  tipoIngresso                      = new TipoIngresso();
  strDescricaoTipoIngresso          : string;
  editPalestraIngresso              : boolean = false;
  btnSalvarDisabled                 : boolean = false;
  btnCancelarDisabled               : boolean = false;
  palestraInformacoesAdicionais     = new PalestraInformacoesAdicionais();
  informacoesAdicionais             : Array<any> = new Array();
  parametro                         : string;
  opcoesCarteira                    : SelectItem[];
  palestraCarteiraPorcentagem       = new PalestraCarteiraPorcentagem();
  palestraCarteiraPorcentagemTabela = new PalestraCarteiraPorcentagem();
  editPalestraCarteiraPorcentagem   : boolean = false;
  opcoesStatus : SelectItem[];

  colsPalestras: any[];

  public optionsFroola: Object = {};
  public optionsDescFroola: Object = {
    charCounterCount: false,
    pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons',
      'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI',
      'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists',
      'paragraphFormat', 'table', 'url', 'video', 'wordPaste'], // 'quickInsert',
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align',
      '|', 'insertTable', 'formatOL', 'formatUL', '|', 'undo', 'redo'],
    // Set request type.
    imageUploadMethod: 'POST',
    key: 'LC2A1B1D2B1H5mH4A15A9A7E2E5C3D2F3H3c1Rc2Re1MKI1AKUBWBOR==',

    events: {
      'froalaEditor.initialized': function () {
        // console.log('froalaEditor - > inicializado');
      },
    },
  };

  btnTipoIngresso: boolean = true;
  btnCarteira: boolean = true;

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService
  ) { }


  ngOnInit() {

    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.title = 'Palestra';
    this.subtitle = 'Todas as palestras';
    this.titleService.title = 'PALESTRAS';
    this.activatedRoute.params.subscribe(params => {
      if (params['tipo']) {
        if(this.parametro !== params.tipo){
          this.parametro = params.tipo;
          this.listar();
        }
        this.parametro = params.tipo;
        if (params.tipo === 'pal') {
          this.title = 'PALESTRA';
          this.subtitle = 'Todas as Palestras ';
          this.titleService.title = 'Palestras';
        } else {
          this.title = 'WORKSHOP';
          this.titleService.title = 'Workshop';
          this.subtitle = 'Todos os workshops';
        }
      }
    });
    this.defaultDate = new Date();
    const lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    // formatando meses com dois digitos
    const mes = this.defaultDate.getMonth() > 8 ?
      (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
    this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
    this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();

    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    this.toolbarEnable();

    this.palestras = new Array<any>();

    this.modalPalestras = false;
    this.checkedAll = false;
    this.idPalestraView = new Array();
    this.idPalestraExcluirView = new Array();
    this.editarPalestra = false;
    this.palestraId = 0;
    this.modalParticipantePorEvento = false;


    this.nomeCurso                        = '';
    this.lstparticipantePorEventos        = new Array<any>();
    this.idParticipnantePorEventosExcluir = new Array<any>();
    this.modalEditarParticipante          = false;

    this.palestraIngresso               = new PalestraIngresso();
    this.palestraObj.palestraIngresso   = new Array();
    this.editPalestraIngresso           = false;
    this.palestraCarteiraPorcentagem    = new PalestraCarteiraPorcentagem();
    this.palestraObj.palestraCarteiraPorcentagem = new Array();

    this.colsPalestras = [
      { field: 'codigo', header: 'COD' },
      { field: 'data', header: 'DATA' },
      { field: 'nomeseminarista', header: 'NOME PALESTRANTE' },
      { field: 'empresa', header: 'EMPRESA' },
      { field: 'numero_presentes', header: 'Nº PRESENTES' },
      { field: 'palestra_aberta', header: 'PALESTRA ABERTA' }

  ];



    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        this.listar();
      });

  }






  carregaPalestrante() {
    this.opcoesPalestrante = [];

    this.httpService.wait();
    this.httpService.get('/seminarista?$select=id,nome&$'
      + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' &$orderby=nome')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.opcoesPalestrante.push({ label: element.nome, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.carregaPalestrante()),
        () => this.httpService.done());
  }

  carregarTipoIngresso(){

    this.opcoesTipoIngresso = [];

    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-tipo-ingresso')
      .subscribe(ref => {
        this.opcoesTipoIngresso = [];
        this.opcoesTipoIngresso.push({ label: 'NENHUM INGRESSO SELECIONADO', value: 0 });
        ref.forEach(element => {
          this.opcoesTipoIngresso.push({ label: element.codigo, value: element.id });
        });
      },
        error =>
        this.httpService.handleError(error, () => this.carregarTipoIngresso()),
        () => this.httpService.done());



  }



  carregarVendedor(){


    this.opcoesVendedor = [];

    this.httpService.wait();
    this.httpService.get('/vendedor?$select=id,nome&$'
      + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
      ' &$orderby=nome')
      .subscribe(ref => {
        ref.value.forEach(element => {

          this.opcoesVendedor.push({ label: element.nome, value: element.id });
        });
      },
        error =>
        this.httpService.handleError(error, () => this.carregarVendedor()),
        () => this.httpService.done());



  }


  adicionarIngresso(){

    if (this.validarPalestraIngresso()) {
      return;
    }

    if (isNullOrUndefined(this.palestraObj.palestraIngresso)) {
       this.palestraObj.palestraIngresso = new Array();
    }

    this.palestraIngressoTabela = new PalestraIngresso();



    if ( !this.editPalestraIngresso) {

    this.palestraIngressoTabela.id                    = (this.palestraObj === undefined ? 1 : this.palestraObj.palestraIngresso.length + 1);
    this.palestraIngressoTabela.tipoIngressoDescricao = this.pegarDescricaoTipoIngresso(this.palestraIngresso.tipoIngressoid);
    this.palestraIngressoTabela.tipoIngressoid        = this.palestraIngresso.tipoIngressoid;

      if (!isNullOrUndefined(this.palestraObj.palestraIngresso) && this.palestraObj.palestraIngresso.length >0 ) {
        if (!isNullOrUndefined(this.palestraObj.palestraIngresso.find(e => e.tipoIngressoid === this.palestraIngresso.tipoIngressoid)) && this.palestraObj.palestraIngresso.find(e => e.tipoIngressoid === this.palestraIngresso.tipoIngressoid).quantidade > 0) {
          this.palestraIngressoTabela.quantidade       = this.palestraIngresso.quantidade + this.palestraObj.palestraIngresso.find(e => e.tipoIngressoid === this.palestraIngresso.tipoIngressoid).quantidade;
          let index = this.palestraObj.palestraIngresso.findIndex(pal => pal.tipoIngressoid === this.palestraIngresso.tipoIngressoid);
          this.palestraObj.palestraIngresso.splice(index,1);
         }else{
          this.palestraIngressoTabela.quantidade       = this.palestraIngresso.quantidade;
         }
       }else{
        this.palestraIngressoTabela.quantidade       = this.palestraIngresso.quantidade;
       }



    } else {
      let index = this.palestraObj.palestraIngresso.findIndex( e=> e.id === this.palestraIngresso.id);
      this.palestraObj.palestraIngresso.splice(index,1);

      this.palestraIngressoTabela.id                    = (this.palestraObj === undefined ? 1 : this.palestraObj.palestraIngresso.length + 1);
      this.palestraIngressoTabela.tipoIngressoDescricao = this.pegarDescricaoTipoIngresso(this.palestraIngresso.tipoIngressoid);
      this.palestraIngressoTabela.tipoIngressoid        = this.palestraIngresso.tipoIngressoid;

      if (this.palestraObj.palestraIngresso.findIndex(pal => pal.tipoIngressoid === this.palestraIngresso.tipoIngressoid) > -1) {
        this.palestraIngressoTabela.quantidade       = this.palestraIngresso.quantidade + this.palestraObj.palestraIngresso.find(e => e.tipoIngressoid === this.palestraIngresso.tipoIngressoid).quantidade;
        let index = this.palestraObj.palestraIngresso.findIndex(pal => pal.tipoIngressoid === this.palestraIngresso.tipoIngressoid);
        this.palestraObj.palestraIngresso.splice(index,1);

      } else {

        this.palestraIngressoTabela.quantidade            = this.palestraIngresso.quantidade;
      }



    }

    this.palestraObj.palestraIngresso.push(this.palestraIngressoTabela);


    this.editPalestraIngresso = false;

    this.palestraIngresso     = new PalestraIngresso();
    this.tipoIngresso         = new TipoIngresso();

    this.btnTipoIngresso = true;

  }

  validarPalestraIngresso(){

    if (isNullOrUndefined(this.palestraIngresso.quantidade) || this.palestraIngresso.quantidade < 1) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A quantidade de ingresso deve ser maior que zero!'
      });
      return true;

    }


    if (isNullOrUndefined(this.palestraIngresso.tipoIngressoid) || this.palestraIngresso.tipoIngressoid < 1) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um tipo de ingresso!'
      });
      return true;

    }


    return false;
  }

  pegarDescricaoTipoIngresso(tipoIngressoId: number) :string {

    let strDescricaoTipoIngresso : string = "";
    if (!isNullOrUndefined(tipoIngressoId) && tipoIngressoId > 0) {
      strDescricaoTipoIngresso =   this.opcoesTipoIngresso.find(e => e.value ===tipoIngressoId ).label;
    }

    return strDescricaoTipoIngresso;
  }


  hideMessage() {


  }

  /*carregaEmpresaConta() {
    this.opcoesDepositoBancario = [];
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.httpService.wait();
      this.httpService.get('/empresaconta?$select=id,nome,agencia,conta,dvConta,tipoConta/descricao&$'
        + 'filter=empresa/id eq ' + this.dblinkedSessionService.empresa.id +
        ' and tipoConta/id=1&$orderby=nome')
        .subscribe(ref => {
          ref.value.forEach(element => {
            this.opcoesDepositoBancario.push({
              label: element.nome + ' : ' + ' Ag : ' +
                element.agencia + ' CC : ' + element.conta + '-' + element.dvConta
              , value: element.id
            });
          });
        },
          error => this.httpService.handleError(error, () => this.carregaEmpresaConta()),
          () => this.httpService.done());
    }

  }*/



  selectTodos(event: any) {
    this.checkedAll = !this.checkedAll;
    this.idPalestraView = new Array();
    this.idPalestraExcluirView = new Array();

    this.palestras.forEach(pc => {
      if (this.checkedAll === false) {
        pc.selected = false;
        this.idPalestraView.slice(pc.id, 1);
        this.idPalestraExcluirView.slice(pc.id, 1);
      } else {
        pc.selected = true;
        this.idPalestraView.push(pc.id);
        this.idPalestraExcluirView.push(pc);
      }
    });
    this.configuraToolBar();
  }

  configuraToolBar() {
    if (this.idPalestraView.length > 0) {
      this.toolbarService.alterarStatus('remove', false, 'Excluir todos!', () => this.excluirPalestrasView());
    } else {
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirPalestrasView());
    }
    this.toolbarService.alterarStatus('export', true, 'Editar Palestra', () => this.showModalEditarPalestra());
  }



  selectCheckbox(id: any, event: any) {
    // manipulando listas
    if (event.selected) {
      event.selected = true;
      // impressão de pedido
      const index = this.idPalestraView.findIndex(vw => vw === id);
      this.idPalestraView.splice(index, 1);

      this.idPalestraExcluirView.splice(this.idPalestraExcluirView.findIndex(e => e.id.toString() === event.id.toString()), 1);
    } else {

      this.idPalestraView.push(id);
      this.idPalestraExcluirView.push(event);
      event.selected = false;
    }
    this.palestraId = 0;
    this.palestraId = id;

    if (this.idPalestraExcluirView.length > 0) {
      this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.excluirPalestrasView());
    } else {
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirPalestrasView());

    }

    if (this.idPalestraExcluirView.length === 1) {
      this.nomeCurso = '';
      this.nomeCurso = event.item;
      this.toolbarService.alterarStatus('edit', false, 'Listar Participantes', () => this.showmodalParticipantePorEventos());
      this.toolbarService.alterarStatus('export', false, 'Editar Palestra', () => this.showModalEditarPalestra());
    } else {
      this.toolbarService.alterarStatus('edit', true, 'Listar Participantes', () => this.showmodalParticipantePorEventos());
      this.toolbarService.alterarStatus('export', true, 'Editar Palestra', () => this.showModalEditarPalestra());
    }
  }

  showmodalParticipantePorEventos() {
    this.modalParticipantePorEvento = true;
    this.lstparticipantePorEventos = new Array<any>();
    this.idParticipnantePorEventosExcluir = new Array<any>();
    this.httpService.wait();
    this.httpService.get('/custom/evento/get-participantePorEvento(' + this.palestraId + ')')
      .subscribe(retorno => {
        retorno.forEach(r => {
          this.lstparticipantePorEventos.push(r);
        });
      },
        error => this.httpService.handleError(error, () => this.listar()),
        () => this.httpService.done());

  }

  exluirItemListaGrid(rowValue: any) {
    if (confirm('Deseja excluir este item?')) {
      const index = this.lstparticipantePorEventos.findIndex(c => c.id === rowValue.id);
      this.lstparticipantePorEventos.splice(index, 1);
      this.idParticipnantePorEventosExcluir.push(rowValue.id);

    }
  }






  onFilterEvento(event: any) {
  }

  ocultarBotoes() {
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('search');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
    this.toolbarService.ocultar('export');
  }

  private toolbarEnable() {

    Promise.resolve(null).then(() => this.toolbarService.hidden = false);
    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');

    this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório',
      () => this.listar()
    );
    this.toolbarService.ocultar('save');

    this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Opções');
    this.toolbarService.ocultar('pesquisa');

    this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir Pedido',
    );
    this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir');
    this.toolbarService.replace('export', true, 'class-edit-button', 'edit', 'Editar', () => this.showModalEditarPalestra()
    );
    this.toolbarService.replace('edit', true, 'class-edit-button', 'list', 'Alterar Títulos Selecionados');

    this.toolbarService.replace('add', false, 'class-new-button', 'NOVO', 'Novo',
      () => this.showModalPalestras()
    );

  }


  listar() {
    this.palestras                      = new Array<any>();
    this.idPalestraView                 = new Array();
    this.idPalestraExcluirView          = new Array();
    this.palestraObj.palestraIngresso   = new Array();
    this.palestraObj.palestraCarteiraPorcentagem = new Array();

    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

        if (this.parametro === 'pal') {
          this.title = 'PALESTRA';
          this.subtitle = 'Todas as Palestras ';
          this.titleService.title = 'Palestras';
        } else {
          this.title = 'WORKSHOP';
          this.titleService.title = 'Workshop';
          this.subtitle = 'Todos os workshops';
        }

        this.opcoesStatus = [];
        this.opcoesStatus.push({label: 'Sim', value: 'Sim' });
        this.opcoesStatus.push({label: 'Não', value: 'Não' });

        this.carregaPalestrante();
        this.carregarVendedor();
        this.httpService.loadList('/uf?$select=id,uf&$orderby=uf', this.ufs);
        this.palestras = new Array();
      this.httpService.wait();
      this.httpService.get('/custom/polozi/listar-palestras',
        [this.dblinkedSessionService.periodo.year.value,
        this.dblinkedSessionService.periodo.month.value,
        this.dblinkedSessionService.empresa.id,
        this.sessionService.loggedUser.id,
        this.parametro
        ])
        .subscribe(retorno => {
          console.log(retorno);
          this.toolbarEnable();
          this.palestras = new Array();
          retorno.forEach(r => {
            r.selected = false;
            this.palestras.push(r);
          });
        },
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());






    }
    setTimeout(() => {

    }, 1000);


  }



  loadOpcoesEvento(array: Array<StatusEvento>) {
    const childActions: Array<ToolbarButton> = new Array();
    array.forEach(element => {
      childActions.push({
        icon: '',
        label: element.descricao,
        callback: () => this.buscaStatusEvento(element.id)
      });
    });
    this.toolbarService.get('more_vert').child = childActions;
  }


  buscaStatusEvento(tipo: any) {


    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month) {

      this.httpService.wait();
      this.httpService.get('/custom/evento/get-eventos-opcoes',
        [this.dblinkedSessionService.periodo.year.value,
        this.dblinkedSessionService.periodo.month.value,
        this.dblinkedSessionService.empresa.id,
          tipo
        ])
        .subscribe(retorno => {
          this.palestras = new Array();
          retorno.forEach(r => {
            r.selected = false;
            this.palestras.push(r);
          });
        },
          error => this.httpService.handleError(error, () => this.listar()),
          () => this.httpService.done());
    }

  }






carregarPalestra(){
    this.palestraObj = new Palestra();
    this.palestraObj.id = 0;
    this.ufSelecionada = 0;
    this.palestraObj.data = new Date();

    this.palestraObj.municipioibge = new MunicipioIbge();
    this.palestraObj.municipioibge.id = null;
    this.palestraObj.changeUser = new AuthUser();
    this.palestraObj.changeUser.id = this.sessionService.loggedUser.id;
    this.palestraObj.data = new Date();

    this.palestraObj.empresa = new Empresa();
    this.palestraObj.empresa.id = null;
    this.palestraObj.palestrante = new Seminarista();
    this.palestraObj.palestrante.id = null;
    this.palestraObj.palestraAberta = null;
    this.palestraObj.agendadaPalestrante = null;
    this.palestraObj.descontoFolha       = null;

    this.palestraObj.vendedor           = new Vendedor();
    this.palestraObj.vendedor.id        = null;
    this.palestraObj.criarNovaPalestra  = true;
    this.palestraObj.strempresa         = null;

    this.palestraObj.palestraIngresso   = new Array();

    this.palestraIngresso               = new PalestraIngresso();
    this.tipoIngresso                   = new TipoIngresso();
    this.palestraIngressoTabela         = new PalestraIngresso();
    this.palestraCarteiraPorcentagem    = new PalestraCarteiraPorcentagem();



}


  onChangeUf(ufId: number, callback: Function = null) {
    this.httpService.loadList('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + ufId,
      this.municipios,
      callback);
  }

  showModalEditarPalestra() {
    this.modalPalestras = true;
    this.getComboCarteira();
    this.hideMessage();


//this.loadListaPalestraPorId(listPalestras)
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-palestra-por-id(' + this.palestraId + ',' + this.sessionService.loggedUser.id +')')
      .subscribe(listPalestras => this.loadListaPalestraPorId(listPalestras),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());


    this.editarPalestra = true;
    this.modalPalestras = true

    this.desabilitaStatus = false;



  }

  loadListaPalestraPorId(value: Array<any>) {
    this.carregarTipoIngresso();
    this.getComboCarteira();

     value.forEach(element => {

      this.palestraIngresso                = new PalestraIngresso();
      this.tipoIngresso                    = new TipoIngresso();
      this.palestraCarteiraPorcentagem     = new PalestraCarteiraPorcentagem();

      this.palestraObj                      = new Palestra();
      this.palestraObj.id                   = element.id;
      this.palestraObj.palestrante          = new Seminarista();
      this.palestraObj.palestrante.id       = element.palestranteId;
      this.ufSelecionada                    = element.ufId;
      this.palestraObj.data                 = this.utilService.dateWithoutTimeZone(element.datapalestra);
      this.palestraObj.municipioibge        = new MunicipioIbge();
      this.palestraObj.municipioibge.id     = element.municipioibgeId;

      this.palestraObj.changeUser           = new AuthUser();
      this.palestraObj.changeUser.id        = this.sessionService.loggedUser.id;
      this.palestraObj.endereco             = element.endereco;

      this.palestraObj.palestraAberta       = element.palestraAberta;
      this.palestraObj.agendadaPalestrante  = element.agendadapalestra;
      this.palestraObj.descontoFolha        = element.descontofolha;
      this.palestraObj.numeroPresentes    = element.numeropresentes;
      this.palestraObj.observacao         = element.observacao;
      this.palestraObj.captureLink        = element.captureLink;
      this.palestraObj.vendedor           = new Vendedor();
      this.palestraObj.vendedor.id        = element.vendedorId;
      this.palestraObj.criarNovaPalestra  = false;
      this.palestraObj.strempresa         = element.empresanome;

      this.carregarPalestraIngresso(element.id);

      this.palestraIngresso.id              = null;
      this.palestraIngresso.tipoIngressoid  = null;
      this.palestraIngresso.quantidade      = null;
      this.palestraIngresso.tipoIngressoDescricao   = null;
      this.tipoIngresso.id                          = null;

      this.palestraCarteiraPorcentagem.id         = null;
      this.palestraCarteiraPorcentagem.carteiraId = null;
      this.palestraCarteiraPorcentagem.porcentagem =  null;
      this.palestraCarteiraPorcentagem.descricaoCarteira = null;
      this.palestraCarteiraPorcentagem.palestra   = null;

      this.carregarInformacoesAdicionais(element.id);
      this.carregarPalestraPorcentagemId(element.id);

    });
   this.finalizarCarregamentoPalestra();

    this.modalPalestras = true;
  }

  finalizarCarregamentoPalestra() {
    // this.palestraObj.changeUser = new AuthUser();
    // this.palestraObj.changeUser.id = this.sessionService.loggedUser.id;


    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.palestraObj.empresa = new Empresa();
      this.palestraObj.empresa.id = this.dblinkedSessionService.empresa.id;
    }

    this.buscarUf(this.ufSelecionada);
    this.ufChanged();



  }

carregarPalestraIngresso(palestraId: number){

  this.httpService.get('/custom/polozi/get-palestraIngresso-palestraId(' + palestraId + ')')
  .subscribe(ref => {

    this.palestraIngresso     = new PalestraIngresso();
    ref.forEach(element => {
          if (this.palestraObj.palestraIngresso === undefined) {
            this.palestraObj.palestraIngresso = new Array();
        }

        this.palestraIngresso.id = ( isNullOrUndefined(this.palestraObj)  ? 1 : this.palestraObj.palestraIngresso.length + 1);
        this.palestraIngresso.tipoIngressoDescricao = element.tipoIngressoCodigo;
        this.palestraIngresso.quantidade            = element.quantidade;
        this.palestraIngresso.tipoIngressoid        = element.tipoIngressoId;

        this.palestraObj.palestraIngresso.push(this.palestraIngresso);
        this.palestraIngresso     = new PalestraIngresso();
        this.tipoIngresso         = new TipoIngresso();

    });
  },
    error =>
    this.httpService.handleError(error, () => this.carregarTipoIngresso()),
    () => this.httpService.done());




}


  showModalPalestras() {


    this.hideMessage();
    this.modalPalestras = true;
    this.carregarVendedor();
    this.editarPalestra = false;
    this.carregaPalestrante();
    this.carregarTipoIngresso();
    this.getComboCarteira();


    this.palestraObj = new Palestra();
    this.palestraObj.id = 0;
    this.ufSelecionada = 0;
    this.palestraObj.data = new Date();

    this.palestraObj.municipioibge = new MunicipioIbge();
    this.palestraObj.municipioibge.id = null;
    this.palestraObj.changeUser = new AuthUser();
    this.palestraObj.changeUser.id = this.sessionService.loggedUser.id;
    this.palestraObj.data = new Date();


    this.palestraObj.empresa = new Empresa();
    this.palestraObj.empresa.id = null;
    this.palestraObj.palestrante = new Seminarista();
    this.palestraObj.palestrante.id = null;
    this.palestraObj.palestraAberta = null;
    this.palestraObj.agendadaPalestrante = null;
    this.palestraObj.descontoFolha       = null;

    this.palestraObj.vendedor           = new Vendedor();
    this.palestraObj.vendedor.id        = null;
    this.palestraObj.criarNovaPalestra  = true;
    this.palestraObj.strempresa         = null;
    this.palestraObj.observacao         = null;
    this.palestraObj.captureLink        = null;

    this.palestraObj.palestraIngresso             = new Array<PalestraIngresso>();
    this.palestraObj.palestraCarteiraPorcentagem  = new Array<PalestraCarteiraPorcentagem>();
    this.palestraCarteiraPorcentagem              = new PalestraCarteiraPorcentagem();




    this.httpService.loadList('/uf?$select=id,uf&$orderby=uf', this.ufs);

    if (this.dblinkedSessionService.hasEmpresaSelected) {

      this.palestraObj.empresa = new Empresa();
      this.palestraObj.empresa.id = this.dblinkedSessionService.empresa.id;
    }

    this.ufChanged();
    this.desabilitaStatus = true;

  }


  excluirPalestrasView() {
    if (confirm('Deseja realmente excluir esta Palestra?')) {
      const PalestrasIds = new Array();
      this.idPalestraExcluirView.forEach(element => {
        PalestrasIds.push(element.id);
      });

      const event = {
        idPalestras: PalestrasIds
      };


      this.httpService.wait();
      this.httpService.post('/custom/polozi/deletar-palestra', event)
        .subscribe(result => {
          this.messageService.add({
            severity: result.severity,
            summary: result.summary,
            detail: result.detail
          });
          this.listar();
        },
          error => this.httpService.handleError(error, () => this.excluirPalestrasView()),
          () => this.httpService.done());

      this.idPalestraExcluirView.forEach(element => {
        const index = this.idPalestraView.findIndex(vw => vw === element.id);
        this.idPalestraView.splice(index, 1);
      });
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirPalestrasView());
      // this.toolbarService.alterarStatus('print', true, 'Imprimir Fatura', () => this.imprimirFatura());
      // this.toolbarService.alterarStatus('edit', true, 'Listar Itens',  () => this.visualizarFaturaItem(event, true));
      // this.toolbarService.alterarStatus('export', true, 'Editar Fatura', () => this.displayEditFaturaDialog());
    }
  }



  buscarUf(ufs: any) {
    this.httpService.wait();
    this.httpService.get('/municipioibge?$select=id,nome&$orderby=nome&$filter=uf/id eq ' + ufs + '&$orderby=nome')
      .subscribe(ref => this.loadMunicipio(ref.value),
        error => this.httpService.handleError(error, () => this.buscarUf(ufs)),
        () => this.httpService.done());

  }

  loadMunicipio(array: Array<MunicipioIbge>) {
    this.opcoesMunicipio = [];
    this.opcoesMunicipio.push({ label: 'Selecionar Municipio', value: null });
    array.forEach(r => {
      this.opcoesMunicipio.push({ label: r.nome, value: r.id });

    });


  }

  ufChanged() {
    this.httpService.wait();
    this.httpService.get('/uf?$select=id,uf&$orderby=uf').subscribe(ref => this.loadUf(ref.value),
      error => this.httpService.handleError(error, () => this.ufChanged()),
      () => this.httpService.done());
  }

  loadUf(array: Array<Uf>) {
    this.opcoesUF = [];
    this.opcoesUF.push({ label: 'Selecionar UF', value: 0 });

    array.forEach(r => {
      this.opcoesUF.push({ label: r.uf, value: r.id });

    });
  }



  loadVendedores(array: Array<Vendedor>) {
    this.opcoesVendedor = [];
    this.opcoesVendedor.push({ label: 'Selecione um vendedor', value: null });

    array.forEach(element => {
      this.opcoesVendedor.push({ label: element.nome, value: element.id });

    });
  }


  validacao(){

    if (isNullOrUndefined( this.palestraObj.palestrante.id ) ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar um seminarista. Verifique!'
      });
      return true;

    }

    // if (isNullOrUndefined(this.palestraObj.strempresa) || this.palestraObj.strempresa.trim().length < 1    ) {
    //   this.messageService.add({
    //     severity: 'warn',key: 'messageLinnks',
    //     summary: 'MSG: ',
    //     detail: 'Favor preencher o nome da empresa!'
    //   });
    //   return true;
    // }

    if (isNullOrUndefined( this.dblinkedSessionService.empresa.id)) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Você não está associado à nenhuma empresa!'
      });
      return true;

    }

    /*if (isNullOrUndefined(this.palestraObj.numeroPresentes)  ) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'O número de participantes tem que ser igual ou maior que zero!'
        });
        return true;

    } else{
        if (this.palestraObj.numeroPresentes < 1) {
          this.messageService.add({
            severity: 'warn',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O número de participantes tem que ser igual ou maior que zero!'
          });
          return true;

        }
    }*/

    if (this.ufSelecionada === null || this.ufSelecionada === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'UF não pode ser vazio. Verifique!'
      });
      return true;

    }

    if (this.palestraObj.municipioibge.id === null || this.palestraObj.municipioibge.id === undefined) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Cidade não pode ser vazio. Verifique!'
      });
      return true;

    }


    if (isNullOrUndefined( this.palestraObj.data) || this.palestraObj.data.toString() === '' ) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma data!'
      });
      return true;

    }


  /*if (isNullOrUndefined(this.palestraObj.numeroPresentes) || this.palestraObj.numeroPresentes < 0 ) {
    this.messageService.add({
      severity: 'warn',key: 'messageLinnks',
      summary: 'MSG: ',
      detail: 'O número de participantes não pode ser menor que zero!'
    });
    return true;

  }*/


  if (!isNullOrUndefined( this.palestraObj.palestraCarteiraPorcentagem)) {
    if (this.palestraObj.palestraCarteiraPorcentagem.length > 0) {
      var porcentagemTotal = 0.00;
      this.palestraObj.palestraCarteiraPorcentagem.forEach(element => {
        if (!isNullOrUndefined( element.porcentagem)) {
          porcentagemTotal = porcentagemTotal + element.porcentagem;
        }
      })

      if (porcentagemTotal > 100) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'A soma das porcentagens não pode ser maior que 100!'
        });
        return true;
      }

      if (porcentagemTotal !== 100) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'A soma total da porcentagem deve ser 100%. Soma atual é: ' + porcentagemTotal + ' %'
        });
        return true;
      }


    }

  }



    return false;
  }



  salvarPalestra() {

   if (this.validacao()) {
     return;
   }


   this.palestraObj.workshop = this.parametro === 'work';

    this.httpService.wait();
    this.httpService.post('/custom/polozi/salvar-palestra', this.palestraObj)
      .subscribe(result => {

        if (result.error === true) {

          this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: '', detail: result.detail });
          this.hideMessage();
        } else {


          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: '', detail: result.detail });

          this.listar();
          this.modalPalestras = false;
          this.hideMessage();
        }

      },
        error => this.httpService.handleError(error, () => this.salvarPalestra()),
        () => this.httpService.done());


    //this.idEventoView = new Array();
  }



  ngOnDestroy(): void {
    this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);

  }


  onFilterPalestra(event: any) {
    event.filteredValue.forEach(element => {
    });
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return 10;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }


  editarPalestraIngresso(event: any){
    // this.palestraIngresso = new PalestraIngresso();
    // this.tipoIngresso     = new TipoIngresso();


      this.palestraIngresso.id                    = event.id;
      this.palestraIngresso.quantidade            = event.quantidade;
      this.palestraIngresso.tipoIngressoid        = event.tipoIngressoid;
      this.palestraIngresso.tipoIngressoDescricao = event.tipoIngressoDescricao;

      this.tipoIngresso.id                        = event.tipoIngressoid;
      this.editPalestraIngresso = true;

      this.btnTipoIngresso = false;

     //this.palestraObj.palestraIngresso.splice(index, 1);
    // this.adicionarIngresso();




  }

  deletarPalestraIngresso(event: any){

    if (confirm('Deseja excluir este item?')) {
      const index = this.palestraObj.palestraIngresso.findIndex(c => c.id === event.id);
      this.palestraObj.palestraIngresso.splice(index, 1);
      this.editPalestraIngresso = false;
      this.palestraIngresso     = new PalestraIngresso();
      this.tipoIngresso         = new TipoIngresso();
    }



  }

  tabChange(event: any){

    // btnSalvarDisabled         : boolean = false;
    // btnCancelarDisabled       : boolean = false;


    switch (event.index) {
      case 0:
        this.btnSalvarDisabled     = false;
        this.btnCancelarDisabled   = false;

        break;

      default:
          this.btnSalvarDisabled     = true;
          this.btnCancelarDisabled   = true;

        break;
    }




  }

  carregarInformacoesAdicionais(palestraId: number){
    this.httpService.get('/custom/polozi/get-informacoes-adicionais(' + palestraId + ')')
    .subscribe(ref => {

      this.informacoesAdicionais = [];

      ref.forEach(element => {
        this.palestraInformacoesAdicionais = new PalestraInformacoesAdicionais();
        this.palestraInformacoesAdicionais.palestraId = element.palestraId;
        this.palestraInformacoesAdicionais.descricao  = element.descricao;
        this.palestraInformacoesAdicionais.quantidade = element.quantidade;

        this.informacoesAdicionais.push(this.palestraInformacoesAdicionais);


      });
    },
      error =>
      this.httpService.handleError(error, () => this.carregarTipoIngresso()),
      () => this.httpService.done());



  }

  getComboCarteira(){
    this.httpService.wait();
    this.httpService.get('/custom/polozi/get-combo-carteira')
      .subscribe(ref => {

        this.opcoesCarteira =[];
        this.opcoesCarteira.push({ label: 'NENHUMA CARTEIRA SELECIONADA', value: 0 });
        ref.forEach(element => {
          this.opcoesCarteira.push({ label: element.carteiraNome + ' Consultor: ' + element.consultorNome, value: element.id });
        });
      },
        error =>
        this.httpService.handleError(error, () => this.getComboCarteira()),
        () => this.httpService.done());


  }


  adicionarCarteiraPorcentagem(){

    if (this.validarPorcentagemCarteira()) {
      return;
    }



    if (isNullOrUndefined(this.palestraObj.palestraCarteiraPorcentagem)) {
      this.palestraObj.palestraCarteiraPorcentagem = new Array();
    }

   this.palestraCarteiraPorcentagemTabela = new PalestraCarteiraPorcentagem();

   if ( !this.editPalestraCarteiraPorcentagem) {

   this.palestraCarteiraPorcentagemTabela.id                    = (this.palestraObj === undefined ? 1 : this.palestraObj.palestraCarteiraPorcentagem.length + 1);
   this.palestraCarteiraPorcentagemTabela.descricaoCarteira  = this.pegarDescricaoCarteira(this.palestraCarteiraPorcentagem.carteiraId);
   this.palestraCarteiraPorcentagemTabela.carteiraId          = this.palestraCarteiraPorcentagem.carteiraId;

     if (!isNullOrUndefined(this.palestraObj.palestraCarteiraPorcentagem) && this.palestraObj.palestraCarteiraPorcentagem.length >0 ) {
       if (!isNullOrUndefined(this.palestraObj.palestraCarteiraPorcentagem.find(e => e.carteiraId === this.palestraCarteiraPorcentagem.carteiraId)) && this.palestraObj.palestraCarteiraPorcentagem.find(e => e.carteiraId === this.palestraCarteiraPorcentagemTabela.carteiraId).porcentagem > 0) {
         this.palestraCarteiraPorcentagemTabela.porcentagem       = this.palestraCarteiraPorcentagem.porcentagem + this.palestraObj.palestraCarteiraPorcentagem.find(e => e.carteiraId === this.palestraCarteiraPorcentagem.carteiraId).porcentagem;
         let index = this.palestraObj.palestraCarteiraPorcentagem.findIndex(pal => pal.carteiraId === this.palestraCarteiraPorcentagem.carteiraId);
         this.palestraObj.palestraCarteiraPorcentagem.splice(index,1);
        }else{
         this.palestraCarteiraPorcentagemTabela.porcentagem       = this.palestraCarteiraPorcentagem.porcentagem;
        }
      }else{
       this.palestraCarteiraPorcentagemTabela.porcentagem       = this.palestraCarteiraPorcentagem.porcentagem;
      }



   } else {
     let index = this.palestraObj.palestraCarteiraPorcentagem.findIndex( e=> e.id === this.palestraCarteiraPorcentagem.id);
     this.palestraObj.palestraCarteiraPorcentagem.splice(index,1);

     this.palestraCarteiraPorcentagemTabela.id                    = (this.palestraObj === undefined ? 1 : this.palestraObj.palestraCarteiraPorcentagem.length + 1);
     this.palestraCarteiraPorcentagemTabela.descricaoCarteira     = this.pegarDescricaoCarteira(this.palestraCarteiraPorcentagem.carteiraId);
     this.palestraCarteiraPorcentagemTabela.carteiraId            = this.palestraCarteiraPorcentagem.carteiraId;

     if (this.palestraObj.palestraCarteiraPorcentagem.findIndex(pal => pal.carteiraId === this.palestraCarteiraPorcentagem.carteiraId) > -1) {

       let index = this.palestraObj.palestraCarteiraPorcentagem.findIndex(pal => pal.carteiraId === this.palestraCarteiraPorcentagem.carteiraId);
       this.palestraObj.palestraCarteiraPorcentagem.splice(index,1);

     } else {

       this.palestraCarteiraPorcentagemTabela.porcentagem            = this.palestraCarteiraPorcentagem.porcentagem;
     }



   }

   this.palestraObj.palestraCarteiraPorcentagem.push(this.palestraCarteiraPorcentagemTabela);


   this.editPalestraCarteiraPorcentagem = false;

   this.palestraCarteiraPorcentagem     = new PalestraCarteiraPorcentagem();
   //this.tipoIngresso         = new TipoIngresso();


   this.btnCarteira = true;

  }

  pegarDescricaoCarteira(carteiraId: number) :string {

    let strDescricaoCarteira : string = "";
    if (!isNullOrUndefined(carteiraId) &&  carteiraId > 0) {
      let index = this.opcoesCarteira.findIndex(e => e.value === carteiraId )
      //strDescricaoCarteira =   this.opcoesCarteira.find(e => e.value === carteiraId ).label;
      strDescricaoCarteira =   this.opcoesCarteira[index].label;

    }

    return strDescricaoCarteira;
  }


  editarCarteiraPorcentagem(event: any){

      this.palestraCarteiraPorcentagem.id                 = event.id;
      this.palestraCarteiraPorcentagem.descricaoCarteira  = event.descricaoCarteira;
      this.palestraCarteiraPorcentagem.carteiraId         = event.carteiraId;
      this.palestraCarteiraPorcentagem.porcentagem        = event.porcentagem;

      this.editPalestraCarteiraPorcentagem                = true;

      this.btnCarteira = false;

  }

  deletarCarteiraPorcentagem(event: any){

    if (confirm('Deseja excluir este item?')) {
      const index = this.palestraObj.palestraCarteiraPorcentagem.findIndex(c => c.id === event.id);
      this.palestraObj.palestraCarteiraPorcentagem.splice(index, 1);
      this.editPalestraCarteiraPorcentagem = false;
      this.palestraCarteiraPorcentagem     = new PalestraCarteiraPorcentagem();

    }



  }

  validarPorcentagemCarteira(){


    if (isNullOrUndefined(this.palestraCarteiraPorcentagem.porcentagem) || this.palestraCarteiraPorcentagem.porcentagem < 1) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'A porcentagem deve ser maior que zero!'
      });
      return true;

    }


    if (isNullOrUndefined(this.palestraCarteiraPorcentagem.carteiraId) || this.palestraCarteiraPorcentagem.carteiraId === 0) {
      this.messageService.add({
        severity: 'warn',key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Favor selecionar uma carteira!'
      });
      return true;

    }


    return false;


  }

  carregarPalestraPorcentagemId(palestraId : number){


    this.httpService.get('/custom/polozi/get-palestraPorcentagemCarteira-palestraId(' + palestraId + ')')
    .subscribe(ref => {

      //this.palestraIngresso     = new PalestraIngresso();
      this.palestraCarteiraPorcentagem = new PalestraCarteiraPorcentagem();
      ref.forEach(element => {
            if (this.palestraObj.palestraCarteiraPorcentagem === undefined) {
              this.palestraObj.palestraCarteiraPorcentagem = new Array();
          }

          this.palestraCarteiraPorcentagem.id = ( isNullOrUndefined(this.palestraObj)  ? 1 : this.palestraObj.palestraCarteiraPorcentagem.length + 1);
          this.palestraCarteiraPorcentagem.porcentagem = element.porcentagem;
          this.palestraCarteiraPorcentagem.carteiraId  = element.carteiraId;
          this.palestraCarteiraPorcentagem.descricaoCarteira = this.pegarDescricaoCarteira(element.carteiraId);

          this.palestraObj.palestraCarteiraPorcentagem.push(this.palestraCarteiraPorcentagem);
          this.palestraCarteiraPorcentagem     = new PalestraCarteiraPorcentagem();


      });
    },
      error =>
      this.httpService.handleError(error, () => this.carregarPalestraPorcentagemId(palestraId)),
      () => this.httpService.done());



  }


}
