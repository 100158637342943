import { Empresa } from '../model/empresa.model';
import { AuthUser } from 'app/proof/model/auth-user.model';

export class DeptoEmpresa {
  id: number;
  descricao: string;
  empresa : Empresa;
  empresaId : number;
  changeUser: AuthUser;

  constructor(deptoEmpresa?: DeptoEmpresa | number) {
    if (deptoEmpresa !== undefined && deptoEmpresa !== null) {
      if (typeof deptoEmpresa === 'number') {
        this.id = deptoEmpresa;
      } else {
        this.id = deptoEmpresa.id;
        this.descricao = deptoEmpresa.descricao;
        this.empresa   = deptoEmpresa.empresa;
        this.empresaId = deptoEmpresa.empresaId;
        this.changeUser = deptoEmpresa.changeUser;
      }
    }
  }
}
