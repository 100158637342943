import { Component, OnInit, OnDestroy } from '@angular/core';

import { Empresa } from '../../cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TimezonePipe } from 'app/shared/pipes/timezone.pipe';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Message, MessageService } from '../../../../node_modules/primeng/api';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-posicao-estoque',
  templateUrl: './posicao-estoque.component.html',
  styleUrls: ['./posicao-estoque.component.scss'],
})
export class PosicaoEstoqueComponent implements OnInit, OnDestroy {
  posicaoEstoque: any[] = [];
  posicaoTerceiro: any[] = [];

  private sessionSubscription: Subscription;
  pt: any;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  msgs: Message[];
  tipo: number;
  colsPosicaoEstoque : any[] = [];
  colsPosicaoTerceiro : any[] = [];
  statusTelaposicaoEstoque : boolean = false;
  statusTelaposicaoTerceiro : boolean = false;


    constructor(private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService) {
  }

  ngOnInit(): void {

    this.tipo = 9;
    this.colsPosicaoEstoque = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'tp', header: 'TP' },
      { field: 'grupo', header: 'GRUPO' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'um', header: 'UM' },
      { field: 'fl', header: 'fl' },
      { field: 'armazem', header: 'ARMAZEM' },
      { field: 'descricaoArmazem', header: 'DESCRIÇÃO ARMAZEM' },
      { field: 'classFiscal', header: 'CLASS FISCAL' },
      { field: 'sldEstoque', header: 'SALDO ESTOQUE' },
      { field: 'vlEstoque', header: 'VALOR ESTOQUE' },
      { field: 'ctaCont', header: 'CTA CONTÁBIL' }
    ];

    this.colsPosicaoTerceiro = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'armazem', header: 'ARMAZEM' },
      { field: 'tipoCliFor', header: 'Tipo Cli/For' },
      { field: 'clienteForn', header: 'Cliente/Forn' },
      { field: 'razaoSocial', header: 'Razão Social' },
      { field: 'docOriginal', header: 'Documento' },
      { field: 'dtEmissao', header: 'Data Emissão' },
      { field: 'saldo', header: 'SALDO' },
      { field: 'custoProd', header: 'CUSTO PROD' },
      { field: 'ctaCont', header: 'CTA CONTÁBIL' }
    ];


    this.activatedRoute.params.subscribe(params => {

      if(this.tipo === 9 || this.tipo !== params.tipo ){
        this.tipo = params.tipo;
        this.change();
      }
    });

    this.titlePrincipal = 'POSIÇÃO DE ESTOQUE';
    this.titleService.title = 'Linnks - Posição de Estoque';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();
    });

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {

      this.subtitle = (this.tipo == 0 ? 'Estoque Próprio - Período: ' : 'Estoque Terceiros - Período: ') + this.dblinkedSessionService.periodo.month.value + '/' + this.dblinkedSessionService.periodo.year.value;


      this.httpService.wait();
      this.httpService.get('custom/conciliacao/posicao-estoque-relatorio',
        [this.dblinkedSessionService.empresa.id,
        this.dblinkedSessionService.periodo.year.value,
        this.dblinkedSessionService.periodo.month.value,
        this.sessionService.loggedUser.id,
        this.tipo])
        .subscribe(retorno => {
          if(retorno.error){

            this.messageService.add({ severity: 'warn',key: 'messageLinnks', summary: 'MSG: ', detail: retorno.message });
          }else{
            this.posicaoEstoque = new Array();
            this.posicaoTerceiro = new Array();
            if (this.tipo == 0 ) {
              this.statusTelaposicaoEstoque = true;
            } else {
              this.statusTelaposicaoTerceiro = true;
            }

            retorno.value.forEach(element => {
              if(this.tipo == 0){
                this.posicaoEstoque.push(element);
              } else {
                this.posicaoTerceiro.push(element);
              }

            });
          }
        }, error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());

    }
  }

  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {

  }

}
