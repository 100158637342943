import { Component, OnInit, OnDestroy, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, ISubscription } from 'rxjs/Subscription';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';

import { Participante } from '../../cadastro/model/participante.model';

import { promise } from '../../../../node_modules/protractor';
import { isNullOrUndefined } from 'util';
import { Message, MessageService, SelectItem } from 'primeng/api';

import { trigger, state, style, animate, transition } from '@angular/animations'

@Component({
  selector: 'app-gerenciar-boleto',
  templateUrl: './gerenciar-boleto.component.html',
  styleUrls: [
    './gerenciar-boleto.component.scss'
  ],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class GerenciarBoletoComponent implements OnInit, OnDestroy {
  // @ViewChildren(ToolbarService) panels;
  private sessionSubscription: Subscription;
  title: string;
  subtitle: string;

  defaultDate: Date;

  boletosGerados: Array<any> = new Array();
  valorTotal: number;
  visualizarDetalhe: boolean;
  detalheBoleto: any;

  msgs: Message[] = [];
  opcoesStatus : SelectItem[] = [];
  msgsModal: Message[] = [];
  modalPeriodo: boolean;

  // filtros

  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;

  opcoesComboStatus : Array<any> = new Array();
  colsBoletosGerados : any[] = [];

  constructor(
    private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.titleService.title = 'Gerenciar Boletos';
    this.title = 'BOLETOS PAGSEGURO';
    this.subtitle = 'GERENCIE OS BOLETOS GERADOS PARA O PAGSEGURO';
    this.visualizarDetalhe = false;
    this.defaultDate = new Date();
    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;

    this.colsBoletosGerados = [
      { field: 'vencimento', header: 'VENCIMENTO' },
      { field: 'referencia', header: 'REFERÊNCIA' },
      { field: 'cpf', header: 'CPF' },
      { field: 'nome', header: 'NOME' },
      { field: 'valor', header: 'VALOR' },
      { field: 'status', header: 'STATUS' }
    ];

    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        setTimeout(() => {this.inicializar();}, 300  );
      }
    );




  }

  inicializar() {
    if (
      this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected &&
      this.dblinkedSessionService.periodo.month &&
      this.dblinkedSessionService.periodo.year
    ) {



      if (
        this.dblinkedSessionService.periodo.month.value !==
        this.utilService.currentMonth(this.defaultDate) + 1
      ) {
        const mes =
          this.dblinkedSessionService.periodo.month.value.toString.length === 2
            ? this.dblinkedSessionService.periodo.month.value
            : '0' + this.dblinkedSessionService.periodo.month.value;
        const dt =
          this.dblinkedSessionService.periodo.year.value +
          '-' +
          mes +
          '-01 12:00:00';
        this.defaultDate = new Date(dt);
      }
      let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
      // formatando meses com dois digitos
      let mes = this.defaultDate.getMonth() +1 > 9 ?
                  (+this.defaultDate.getMonth() + 1) : '0' + (+this.defaultDate.getMonth() + 1);
      this.dataIniSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-01';
      this.dataFinSearch = this.defaultDate.getFullYear().toString() + '-' + mes + '-' + lastDay.getDate();


    this.httpService.wait();
    this.httpService.get('/custom/pagseguro/buscar-boletos(' + [this.dblinkedSessionService.empresa.id, this.dataIniSearch , this.dataFinSearch ]+ ')')
      .subscribe(boletos => this.loadBoletos(boletos),
        error => this.httpService.handleError(error, () => this.inicializar()),
        () => this.httpService.done());
        this.visualizarDetalhe = false;
        this.detalheBoleto = '';

    }
    setTimeout(() => {
      this.toolbarEnable();
    }, 500);


  }

  loadBoletos(value: Array<any>) {

    this.boletosGerados = new Array();
    this.opcoesStatus = [];
    this.opcoesComboStatus = new Array();
    value.forEach(element => {
      this.boletosGerados.push(element);
      this.valorTotal = this.valorTotal + this.utilService.parseDate(element.valor);

      var index = 0;
      if ((this.opcoesStatus !== null || this.opcoesStatus !== undefined)) {
        index = this.opcoesStatus.findIndex(e => e.value === element.status);
      }

      if (index === -1) {
        this.opcoesStatus.push({label: element.status , value: element.status});
      }

      this.opcoesStatus.sort((a,b) => a.label.localeCompare(b.label));
      if (!isNullOrUndefined(this.opcoesComboStatus)) {
        index = this.opcoesComboStatus.findIndex(e => e.value === element.status);
      }

      if (index === -1) {
        this.opcoesComboStatus.push({label: element.status , value: element.status});
      }

      this.opcoesComboStatus.sort((a,b) => a.label.localeCompare(b.label) );

    });
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  visualizarDetalheBoleto(event: any) {
    this.detalheBoleto = '';
    this.visualizarDetalhe = true;
    this.detalheBoleto = event;
  }


  private toolbarEnable() {
    this.toolbarService.clear();
    // this.toolbarService.alterarStatus('pesquisa',true,'Visualizar Pedido', () => null);
    // this.toolbarService.alterarStatus('edit',true,'Cancela somente leitura',() => null);
    // this.toolbarService.alterarStatus('info',true,'Inserir Dados Contrato', () => null);
    // this.toolbarService.alterarStatus('atualizar',true,'Atualizar pedidos',() => null);
    // this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.inicializar());
    //this.toolbarService.replace('export',true,'class-other-button','file_copy', 'Clonar Pedido');
   // this.toolbarService.replace('export',true,'class-other-button','file_copy', 'Clonar Pedido');
   // this.toolbarService.replace('print', true,'class-first-button','print','Imprimir Pedido', null);
    //this.toolbarService.replace('save', true,'class-other-button','save', 'Salvar alterações', null);
   // this.toolbarService.replace('remove',true,'class-remove-button', 'delete','Excluir');
   this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.inicializar());
   this.toolbarService.replace('edit', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());
    this.toolbarService.replace('add',true,'class-new-button','NOVO','GERAR BOLETO', null );
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  cancelarBoleto(){
    if (confirm('Deseja realmente cancelar este boleto?')) {

      if (this.detalheBoleto.status === 'Cancelado' || this.detalheBoleto.status === 'CANCELADO' ) {

        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Boleto já está cancelado, não é possível cancelar!'
        });

        this.visualizarDetalhe = false;
        this.inicializar();

      } else {
        const listasEnviar = {
          code: this.detalheBoleto.code,
          retorno: this.detalheBoleto.retornoId
        };

        this.httpService.wait();
        this.httpService
          .post('/custom/pagseguro/cancelar-boleto', listasEnviar)
          .subscribe(
            result => {

            if (result.error) {
              this.messageService.add({
                severity: 'warn',key: 'messageLinnks',
                summary: 'MSG: ',
                detail: 'Erro no cancelamento do Boleto, contate o suporte!'
              });

              this.visualizarDetalhe = false;
              this.inicializar();
            } else {
              this.messageService.add({
                severity: 'success',key: 'messageLinnks',
                summary: 'MSG: ',
                detail: 'Boleto Cancelado com Sucesso!'
              });

              this.visualizarDetalhe = false;
              this.inicializar();

            }},
            error => this.httpService.handleError(error, () => this.cancelarBoleto()),
            () => this.httpService.done()
          );
      }




    }
  }

  hideMessage() {

  }

  openModalPeriodo() {
    this.modalPeriodo = true;
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);

  }


  periodoChange() {

    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() +1 > 9 ?
                    (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() +1 > 9 ?
                    (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
                    this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
                    this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }
    const d1 = new Date(this.dataIniSearch);
    const d2 = new Date(this.dataFinSearch);
    if (d1 > d2) {
      this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: 'Data incial maior que a final. Verifique!' });
      return;
    }


    this.httpService.wait();
    this.httpService.get('/custom/pagseguro/buscar-boletos(' + [this.dblinkedSessionService.empresa.id, this.dataIniSearch, this.dataFinSearch ] +')')
      .subscribe(boletos => this.loadBoletos(boletos),
        error => this.httpService.handleError(error, () => this.inicializar()),
        () => this.httpService.done());
        this.visualizarDetalhe = false;
        this.detalheBoleto = '';

    setTimeout(() => {
      this.toolbarEnable();
    }, 500);


  }

  currentState = 'initial';
  changeCardState() {
    this.toggleIconState();
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }

  iconState: boolean = true;
  toggleIconState() {
    this.iconState = !this.iconState;
  }

 }
