import { Component, OnDestroy, OnInit } from '@angular/core'
import { ConfigService } from 'app/shared/services/config.service'
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service'
import { HttpService } from 'app/shared/services/http.service'
import { SessionService } from 'app/shared/services/session.service'
import { ToolbarService } from 'app/shared/services/toolbar.service'
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api'
import { Subscription } from 'rxjs'
import { Conciliation } from '../interfaces/Conciliation'
import { Log } from '../interfaces/Log'
import { API } from '../interfaces/API'
import { Timer } from '../interfaces/Timer'
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel'
import exportExcel from 'app/util/processing/exportExcel'

@Component({
  selector: 'app-monitoramento',
  templateUrl: './monitoramento.component.html',
  styleUrls: ['./monitoramento.component.scss']
})
export class MonitoramentoComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription

  apisList: Array<API> = new Array()
  auxApisList: Array<API> = new Array()
  timerList: Array<Timer> = new Array()
  auxTimerList: Array<Timer> = new Array()
  timerLogs: Array<Log> = new Array()
  conciliationList: Array<Conciliation> = new Array()
  auxconciliationList: Array<Conciliation> = new Array()
  conciliationLogColumns: Array<string> = ['id', 'message', 'valor', 'source']

  timerSelected: Timer
  conciliationSelected: Conciliation

  familyOptions: SelectItem[] = []

  dateIni: Date = new Date()
  dateFin: Date = new Date()

  totalLogs: number
  secondsInterval: number = 120
  empresaId: number

  isLoading: boolean = true
  isLogModal: boolean = false
  isConciliationModal: boolean = false
  isReqTimerHealthError: boolean = false
  isReqConciliationrHealthError: boolean = false
  isFilterModal: boolean = false
  loadingFamilyOptions: boolean = true
  loadingIconFamilyOptions: boolean = false

  familySelected: string
  dtIni: string
  dtFin: string

  interval: any = null

  skeletonConfig = new SkeletonModel({
    columns: [
      { name: 'DATA DE EXECUÇÃO', textAlign: 'left', size: '20%' },
      { name: 'DESCRIÇÃO', textAlign: 'left', size: '70%' },
      { name: 'STATUS', textAlign: 'left', size: '10%' },
    ],
    hasFilter: true,
    hasSort: true,
  })

  constructor(
    private toolbarService: ToolbarService,
    private dblinkedSessionService: DblinkedSessionService,
    private sessionService: SessionService,
    private httpService: HttpService,
    private configService: ConfigService,
    private messageService: MessageService
  ) {
    this.toolbarService.hidden = true
  }

  ngOnInit(): void {
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2)
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      if (this.dblinkedSessionService.hasEmpresaSelected && this.dblinkedSessionService.hasPeriodoSelected) {
        this.initialize()
        clearInterval(this.interval)
      }
    })
  }

  ngOnDestroy(): void {
    this.toolbarService.hidden = false
    this.dblinkedSessionService.hidePeriods()
    this.sessionService.destroySubscribe(this.sessionSubscription)
    clearInterval(this.interval)
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage
  }

  get rows(): number {
    return this.configService.applicationConfig.rows
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks
  }

  async initialize(): Promise<void> {
    this.toolbarService.hidden = true
    this.empresaId = this.dblinkedSessionService.empresa.id

    this.setDates(true)

    await this.getAPIFamilies()
    this.loadingInformations(true)
    this.interval = setInterval(() => this.loadingInformations(false, false), this.secondsInterval * 1000)
  }

  resetVars(): void {
    this.apisList = new Array()
    this.auxApisList = new Array()
    this.timerList = new Array()
    this.auxTimerList = new Array()
    this.conciliationList = new Array()
    this.auxconciliationList = new Array()
  }

  loadingInformations(isForceUpdate: boolean, loading: boolean = true): void {
    if (isForceUpdate) this.resetVars()

    if (this.familySelected !== undefined && this.familySelected !== null) {
      this.isLoading = loading
      let requisitionList: Array<any> = new Array()

      switch (this.familySelected) {
        case 'BS2':
          requisitionList = this.BS2RequisitionGroup()
        break
        case 'EYEMOBILE':
          requisitionList = this.EyeMobileRequisitionGroup()
        break
        case 'PAYLIVRE':
          requisitionList = this.PayLivreRequisitionGroup()
        break
        case 'MATERA':
          requisitionList = this.MateraRequisitionGroup()
        break
        case 'CONCILIADORA':
          requisitionList = this.ConciliadoraRequisitionGroup()
        break
        case 'TRAY':
          requisitionList = this.TrayRequisitionGroup()
        break
        case 'TODOS':
          requisitionList = this.checkingAllApiAccesses()
        break
      }

      Promise.all(requisitionList).then(() => this.isLoading = false)
    }
  }

  BS2RequisitionGroup(): Array<any> {
    return [this.getOctaremoteHealth(), this.getBS2Health(), this.getTimerHealth('BS2'), this.getConciliationListBS2()]
  }

  EyeMobileRequisitionGroup(): Array<any> {
    return [this.getOctaremoteHealth(), this.getEyeMobileHealth(), this.getTimerHealth('EYEMOBILE'), this.getConciliationListEyeMobile()]
  }

  PayLivreRequisitionGroup(): Array<any> {
    return [this.getOctaremoteHealth(), this.getPayLivreHealth(), this.getTimerHealth('PAYLIVRE'), this.getConciliationListPayLivre()]
  }

  MateraRequisitionGroup(): Array<any> {
    return [this.getOctaremoteHealth(), this.getMateraHealth(), this.getTimerHealth('MATERA'), this.getConciliationListMatera()]
  }

  ConciliadoraRequisitionGroup(): Array<any> {
    return [this.getOctaremoteHealth(), this.getConciliadoraHealth(), this.getTimerHealth('CONCILIADORA'), this.getConciliationListConciliadora()]
  }

  TrayRequisitionGroup(): Array<any> {
    return [this.getOctaremoteHealth(), this.getTrayHealth(), this.getTimerHealth('TRAY'), this.getConciliationListTray()]
  }

  async getAPIFamilies(): Promise<void> {
    this.loadingFamilyOptions = true
    this.loadingIconFamilyOptions = true

    try {
      await this.httpService.get(this.configService.octaremoteUrl + `/stats/families?empresaId=${this.dblinkedSessionService.empresa.id}`).toPromise().then(res => {
        this.familyOptions = [{ label: 'TODOS', value: 'TODOS' }]
        res.data.forEach(item => this.familyOptions.unshift({ label: item, value: item }))
        if(!this.familyOptions.map(item => { return item.value }).includes(this.familySelected)) this.familySelected = null
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro as familias de API! Contate o suporte' })
    } finally {
      this.loadingFamilyOptions = false
      this.loadingIconFamilyOptions = false
    }
  }

  async getOctaremoteHealth(): Promise<void> {
    try {
      await this.httpService.get(this.configService.octaremoteUrl + '/stats').toPromise().then(res => this.auxApisList.push(res))
    } catch (err) {
      this.auxApisList.push({ codigo: 500, data: 'Octaremote', message: 'Falha na comunicação com o servidor!' })
    } finally {
      this.apisList = this.clearArr(this.auxApisList, 'data')
    }
  }

  async getTimerHealth(selectedFamily: string): Promise<void> {
    try {
      await this.httpService.get(this.configService.octaremoteUrl + `/stats/schedulers/health?familia=${selectedFamily}&empresaId=${this.empresaId}`).toPromise().then(res => {
        res.data.forEach(item => this.auxTimerList.push(item))
        this.isReqTimerHealthError = false
      })
    } catch (err) {
      this.isReqTimerHealthError = true
    } finally {
      this.timerList = this.clearArr(this.auxTimerList, 'nome')
    }
  }

  async getTimerLog(timerId: string, event: LazyLoadEvent = {}): Promise<void> {
    const payload = {
      empresa_id: this.empresaId,
      limit: event.rows,
      offset: event.first,
      sort_order: event.sortOrder,
      sort_field: event.sortField,
      filters: event.filters,
      cod: timerId
    }

    this.skeletonConfig.startLoad()

    try {
      await this.httpService.post(this.configService.octaremoteUrl + '/stats/schedulers/log', payload).toPromise().then(res => {

        this.timerLogs = res.data.detail.map(item => {
          item.executed_at_str = this.convertTimestamp(item.executed_at)
          return item
        })

        this.totalLogs = res.data.total
      })
    } catch (err) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Erro', detail: 'Houve um erro ao listar o log do timer! Contate o suporte' })
    } finally {
      this.skeletonConfig.endLoad()
    }
  }

  async getBS2Health(): Promise<void> {
    try {
      await this.httpService.get(this.configService.octaremoteUrl + `/stats/bs2/health`).toPromise().then(res => {
        this.auxApisList.push(res)
      })
    } catch (err) {
      this.auxApisList.push({ codigo: 500, data: 'BS2', message: 'Falha na comunicação com o servidor!' })
    } finally {
      this.apisList = this.clearArr(this.auxApisList, 'data')
    }
  }

  async getEyeMobileHealth(): Promise<void> {
    try {
      await this.httpService.get(this.configService.octaremoteUrl + `/stats/eyemobile/health?empresaId=${this.empresaId}`).toPromise().then(res => {
        this.auxApisList.push(res)
      })
    } catch (err) {
      this.auxApisList.push({ codigo: 500, data: 'EyeMobile', message: 'Falha na comunicação com o servidor!' })
    } finally {
      this.apisList = this.clearArr(this.auxApisList, 'data')
    }
  }

  async getPayLivreHealth(): Promise<void> {
    try {
      await this.httpService.get(this.configService.octaremoteUrl + `/stats/paylivre/health?empresaId=${this.empresaId}`).toPromise().then(res => {
        this.auxApisList.push(res)
      })
    } catch (err) {
      this.auxApisList.push({ codigo: 500, data: 'PayLivre', message: 'Falha na comunicação com o servidor!' })
    } finally {
      this.apisList = this.clearArr(this.auxApisList, 'data')
    }
  }


  async getMateraHealth(): Promise<void> {
    try {
      await this.httpService.get(this.configService.octaremoteUrl + `/stats/matera/health`).toPromise().then(res => {
        this.auxApisList.push(res)
      })
    } catch (err) {
      this.auxApisList.push({ codigo: 500, data: 'Matera', message: 'Falha na comunicação com o servidor!' })
    } finally {
      this.apisList = this.clearArr(this.auxApisList, 'data')
    }
  }

  async getConciliadoraHealth(): Promise<void> {
    try {
      await this.httpService.get(this.configService.octaremoteUrl + `/stats/conciliadora/health?empresaId=${this.empresaId}`).toPromise().then(res => {
        this.auxApisList.push(res)
      })
    } catch (err) {
      this.auxApisList.push({ codigo: 500, data: 'Conciliadora', message: 'Falha na comunicação com o servidor!' })
    } finally {
      this.apisList = this.clearArr(this.auxApisList, 'data')
    }
  }

  async getTrayHealth(): Promise<void> {
    try {
      await this.httpService.get(this.configService.octaremoteUrl + `/stats/tray/health?empresaId=${this.empresaId}`).toPromise().then(res => {
        this.auxApisList.push(res)
      })
    } catch (err) {
      this.auxApisList.push({ codigo: 500, data: 'Tray', message: 'Falha na comunicação com o servidor!' })
    } finally {
      this.apisList = this.clearArr(this.auxApisList, 'data')
    }
  }

  async getConciliationListEyeMobile(): Promise<void> {
    try {
      const responseProducts = await this.httpService.get(this.configService.octaremoteUrl + `/stats/eyemobile/products?empresaId=${this.empresaId}`).toPromise()
      const responseCustomers = await this.httpService.get(this.configService.octaremoteUrl + `/stats/eyemobile/customers?empresaId=${this.empresaId}`).toPromise()
      const responseOrders = await this.httpService.get(this.configService.octaremoteUrl + `/stats/eyemobile/orders?empresaId=${this.empresaId}&start=${this.dtIni}&end=${this.dtFin}`).toPromise()

      this.auxconciliationList.push(
        { origin: 'Produtos', data: responseProducts.data, message: 'produtos registrados!', relationship: 'Octalink - EyeMobile', status: responseProducts.data.status },
        { origin: 'Clientes', data: responseCustomers.data, message: 'clientes registrados!', relationship: 'Octalink - EyeMobile', status: responseCustomers.data.status },
        { origin: 'Vendas', data: responseOrders.data, message: 'pedidos registrados!', relationship: 'Octalink - EyeMobile', status: responseOrders.data.status }
      )

      this.isReqConciliationrHealthError = false
    } catch (err) {
      this.isReqConciliationrHealthError = true
    } finally {
      this.conciliationList = this.clearArr(this.auxconciliationList, 'origin')
    }
  }

  async getConciliationListPayLivre(): Promise<void> {
    try {
      const responseBS2 = await this.httpService.get(this.configService.octaremoteUrl + `/stats/paylivre/bs2?empresaId=${this.empresaId}&start=${this.dtIni}&end=${this.dtFin}`).toPromise()

      this.auxconciliationList.push(
        { origin: 'Crédito BS2 (R$)', data: responseBS2.data.credito, message: responseBS2.data.credito.qtde + ' transações', relationship: 'PayLivre - BS2', status: responseBS2.data.credito.status },
        { origin: 'Prejuízo BS2 (R$)', data: responseBS2.data.prejuizo, message: responseBS2.data.prejuizo.qtde + ' transações', relationship: 'PayLivre - BS2', status: responseBS2.data.prejuizo.status }
      )

      this.isReqConciliationrHealthError = false
    } catch (err) {
      this.isReqConciliationrHealthError = true
    } finally {
      this.conciliationList = this.clearArr(this.auxconciliationList, 'origin')
    }
  }

  async getConciliationListBS2(): Promise<void> {
    try {
      const responseBS2 = await this.httpService.get(this.configService.octaremoteUrl + `/stats/bs2/mov?empresaId=${this.empresaId}&start=${this.dtIni}&end=${this.dtFin}`).toPromise()

      this.auxconciliationList.push(
        { origin: 'Movimentação BS2', data: responseBS2.data, message: responseBS2.data.qtde + ' transações', relationship: 'BS2', status: responseBS2.data.status }
      )

      this.isReqConciliationrHealthError = false
    } catch (err) {
      this.isReqConciliationrHealthError = true
    } finally {
      this.conciliationList = this.clearArr(this.auxconciliationList, 'origin')
    }
  }

  async getConciliationListMatera(): Promise<void> {
    try {
      const responsePix = await this.httpService.get(this.configService.octaremoteUrl + `/stats/matera/count-pix?empresaId=${this.empresaId}&start=${this.dtIni}&end=${this.dtFin}`).toPromise()
      const responseTarifas = await this.httpService.get(this.configService.octaremoteUrl + `/stats/matera/tarifas?empresaId=${this.empresaId}&start=${this.dtIni}&end=${this.dtFin}`).toPromise()
      const responseSaldo = await this.httpService.get(this.configService.octaremoteUrl + `/stats/matera/saldo?empresaId=${this.empresaId}`).toPromise()

      this.auxconciliationList.push(
        { origin: 'Saldo', data: responseSaldo.data, message: 'R$ de saldo', relationship: 'Octalink', status: responseSaldo.data.status },
        { origin: 'Pix Emitidos', data: responsePix.data.todos, message: 'R$ emitidos!', relationship: 'Octalink', status: responsePix.data.todos.status },
        { origin: 'Pix Pendentes', data: responsePix.data.pendentes, message: 'R$ pendentes!', relationship: 'Octalink', status: responsePix.data.pendentes.status },
        { origin: 'Pix Pagos', data: responsePix.data.pagos, message: 'R$ pagos!', relationship: 'Octalink', status: responsePix.data.pagos.status },
        { origin: 'Tarifas', data: responseTarifas.data.debito, message: 'R$ pagos em tarifa!', relationship: 'Octalink', status: responseTarifas.data.debito.status }
      )

      if (this.empresaId === 160) this.auxconciliationList.push({ origin: 'Tarifas cobradas', data: responseTarifas.data.credito, message: 'R$ recebidos!', relationship: 'Octalink', status: responseTarifas.data.credito.status })

      this.isReqConciliationrHealthError = false
    } catch (err) {
      this.isReqConciliationrHealthError = true
    } finally {
      this.conciliationList = this.clearArr(this.auxconciliationList, 'origin')
    }
  }

  async getConciliationListConciliadora(): Promise<void> {
    try {
      const responseConciliadora = await this.httpService.get(this.configService.octaremoteUrl + `/stats/conciliadora?empresaId=${this.empresaId}&start=${this.dtIni}&end=${this.dtFin}`).toPromise()

      this.auxconciliationList.push(
        { origin: 'Enviadas', data: responseConciliadora.enviadas, message: 'Vendas enviadas', relationship: 'Conciliadora', status: responseConciliadora.enviadas.status },
        { origin: 'Corretas', data:responseConciliadora.corretas, message: 'Vendas corretas', relationship: 'Conciliadora', status: responseConciliadora.corretas.status },
        { origin: 'Divergentes', data:responseConciliadora.divergentes, message: 'Vendas divergentes', relationship: 'Conciliadora', status: responseConciliadora.divergentes.status },
        { origin: 'Não Conciliadas', data:responseConciliadora.nao_conciliadas, message: 'Vendas não conciliadas', relationship: 'Conciliadora', status: responseConciliadora.nao_conciliadas.status },
        { origin: 'Não Encontradas', data:responseConciliadora.nao_encontradas, message: 'Vendas não encontradas', relationship: 'Conciliadora', status: responseConciliadora.nao_encontradas.status },
        { origin: 'Venda em duplicidade', data:responseConciliadora.duplicidade, message: 'Vendas em duplicidade', relationship: 'Conciliadora', status: responseConciliadora.duplicidade.status },
        { origin: 'Não Enviadas', data:responseConciliadora.nao_enviadas, message: 'Vendas não enviadas', relationship: 'Conciliadora', status: responseConciliadora.nao_enviadas.status },
        { origin: 'Não Enviadas Canceladas', data:responseConciliadora.nao_enviadas_canceladas, message: 'Vendas não enviadas canceladas', relationship: 'Conciliadora', status: responseConciliadora.nao_enviadas_canceladas.status },

        { origin: 'Vendas brutas', data:responseConciliadora.vendas_brutas, message: 'Total de vendas brutas', relationship: 'Conciliadora', status: responseConciliadora.vendas_brutas.status },
        { origin: 'Taxa', data:responseConciliadora.taxa, message: 'Total de taxas', relationship: 'Conciliadora', status: responseConciliadora.taxa.status },
        { origin: 'Crédito', data:responseConciliadora.credito, message: 'Total de crédito', relationship: 'Conciliadora', status: responseConciliadora.credito.status },
        { origin: 'Cancelamento', data:responseConciliadora.cancelamento, message: 'Total de cancelamentos', relationship: 'Conciliadora', status: responseConciliadora.cancelamento.status },
        { origin: 'Vendas Líquidas', data:responseConciliadora.vendas_liquidas, message: 'Total de vendas líquidas', relationship: 'Conciliadora', status: responseConciliadora.vendas_liquidas.status },
        { origin: 'Atrasado', data:responseConciliadora.atrasado, message: 'Total de pagamentos atrasados', relationship: 'Conciliadora', status: responseConciliadora.atrasado.status },
        { origin: 'Débitos', data:responseConciliadora.debitos, message: 'Total de débitos', relationship: 'Conciliadora', status: responseConciliadora.debitos.status },
        { origin: 'A Receber', data:responseConciliadora.a_receber, message: 'Total a receber', relationship: 'Conciliadora', status: responseConciliadora.a_receber.status }
      )

      this.isReqConciliationrHealthError = false
    } catch (err) {
      this.isReqConciliationrHealthError = true
    } finally {
      this.conciliationList = this.clearArr(this.auxconciliationList, 'origin')
    }
  }

  // Conciliacoes da Tray
  async getConciliationListTray(): Promise<void> {
    try {
      const responseProducts = await this.httpService.get(this.configService.octaremoteUrl + `/stats/tray/products?empresaId=${this.empresaId}`).toPromise()
      const responseCustomers = await this.httpService.get(this.configService.octaremoteUrl + `/stats/tray/customers?empresaId=${this.empresaId}`).toPromise()
      const responseOrders = await this.httpService.get(this.configService.octaremoteUrl + `/stats/tray/orders?empresaId=${this.empresaId}&start=${this.dtIni}&end=${this.dtFin}`).toPromise()

      this.auxconciliationList.push(
        { origin: 'Produtos', data: responseProducts.data, message: 'produtos registrados!', relationship: 'Octalink - Tray', status: responseProducts.data.status },
        { origin: 'Clientes', data: responseCustomers.data, message: 'clientes registrados!', relationship: 'Octalink - Tray', status: responseCustomers.data.status },
        { origin: 'Vendas', data: responseOrders.data, message: 'pedidos registrados!', relationship: 'Octalink - Tray', status: responseOrders.data.status }
      )

      this.isReqConciliationrHealthError = false
    } catch (err) {
      this.isReqConciliationrHealthError = true
    } finally {
      this.conciliationList = this.clearArr(this.auxconciliationList, 'origin')
    }
  }

  openingTimerLog(timer?: Timer): void {
    this.isLogModal = !this.isLogModal

    if (this.isLogModal) {
      this.timerSelected = timer
      this.getTimerLog(timer.codigo)
    }
  }

  openingConciliationLog(conciliation?: Conciliation): void {
    this.isConciliationModal = !this.isConciliationModal
    if (this.isConciliationModal) this.conciliationSelected = conciliation
  }

  openingFilterModal(saveNewFilter: boolean): void {
    this.validateDate()

    this.isFilterModal = !this.isFilterModal

    if(saveNewFilter) this.setDates(false), this.loadingInformations(true)
  }

  checkingAllApiAccesses(): Array<any> {
    let allApi: Array<any> = new Array()

    this.familyOptions.forEach(item => {
      console.log(item)
      switch (item.value) {
        case 'BS2':
          allApi.push(...this.BS2RequisitionGroup())
        break
        case 'EYEMOBILE':
          allApi.push(...this.EyeMobileRequisitionGroup())
        break
        case 'PAYLIVRE':
          allApi.push(...this.PayLivreRequisitionGroup())
        break
        case 'MATERA':
          allApi.push(...this.MateraRequisitionGroup())
        break
        case 'CONCILIADORA':
          allApi.push(...this.ConciliadoraRequisitionGroup())
        break
        case 'TRAY':
          allApi.push(...this.TrayRequisitionGroup())
        break
      }
    })

    return allApi
  }

  setDates(isStarting: boolean): void {
    if(isStarting) {
      this.dateIni = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value - 1, 1)
      this.dateFin = new Date(this.dblinkedSessionService.periodo.year.value, this.dblinkedSessionService.periodo.month.value, 0)
    }

    this.dtIni = `${this.dateIni.getFullYear()}-${(((this.dateIni.getMonth() + 1) < 10) ? `0${(this.dateIni.getMonth() + 1)}` : (this.dateIni.getMonth() + 1))}-${((this.dateIni.getDate() < 10) ? `0${this.dateIni.getDate()}` : this.dateIni.getDate())}`
    this.dtFin = `${this.dateFin.getFullYear()}-${(((this.dateFin.getMonth() + 1) < 10) ? `0${(this.dateFin.getMonth() + 1)}` : (this.dateFin.getMonth() + 1))}-${((this.dateFin.getDate() < 10) ? `0${this.dateFin.getDate()}` : this.dateFin.getDate())}`
  }

  validateDate(): boolean {
    if (this.dateIni > this.dateFin) {
      const message: string = 'A data inicial não pode ser maior que a data final. O filtro não foi aplicado!'
      this.messageService.add({ severity: 'info', key: 'messageLinnks', summary: 'Aviso', detail: message })
      throw message
    }

    return true
  }

  clearArr(arr: Array<any>, parameter: string): Array<any> {
    const objectMap = arr.reduce((map, object) => {
      map.set(object[parameter], object)
      return map
    }, new Map())

    return Array.from(objectMap, ([_, value]) => value)
  }

  formatQuantity(value: number): string {
    if (Math.abs(value / 1e9) >= 1) return (Math.round(value / 1e9 * 100) / 100) + 'Bi'
    else if (Math.abs(value / 1e6) >= 1) return (Math.round(value / 1e6 * 100) / 100) + 'Mi'
    else if (Math.abs(value / 1e3) >= 1) return (Math.round(value / 1e3 * 100) / 100) + 'Mil'
    else return (Math.round(value * 100) / 100).toString()
  }

  convertTimestamp(timestamp: Date): String {
    const date = new Date(timestamp)
    date.setHours(date.getHours() + 3)

    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
    const year = date.getFullYear()

    const hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
    const minutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
    const seconds = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()

    const fullDate = `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`
    return fullDate
  }

  expExcel() {
    exportExcel(this.conciliationSelected.data.log, 'Log de conciliação' + this.conciliationSelected.origin, this.conciliationLogColumns)
  }
}

