import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { Subscription } from 'rxjs/Subscription';
import { ToolbarService } from 'app/shared/services/toolbar.service';

@Component({
  selector: 'app-eventos-detalhe',
  templateUrl: './eventos-detalhe.component.html',
  styleUrls: [
    './eventos-detalhe.component.scss'
  ],
  providers: [CrudService]
})
export class ReinfEventosDetalheComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
    private dblinkedSessionService: DblinkedSessionService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private toolbarService: ToolbarService
  ) {}

  private sessionSubscription: Subscription; // change session event;

  // *url
  @Input() ids: 'ids';

  title: string;
  subtitle: string;

  // tabela;
  checkAllBtn: Boolean = false;
  idsSelecionados: any[] = [];
  lista: any[] = [];
  colslista : any[] = [];

  ngOnInit() {
    this.dblinkedSessionService.hidePeriods();
    this.toolbarService.clear();

    Promise.resolve(null).then(() => (this.toolbarService.hidden = false));

    this.title = 'REINF';
    this.subtitle = 'relatório analítico';

    this.activatedRoute.params.subscribe(params => {
      this.idsSelecionados = params['ids'];
    });

    this.colslista = [
      { field: 'periodo', header: 'PERÍODO' },
      { field: 'prestadorNome', header: 'PRESTADOR NOME' },
      { field: 'serie', header: 'SERIE' },
      { field: 'numDocto', header: 'NUM DOCTO' },
      { field: 'dtEmissaoNf', header: 'DT EMISSÃO NF' },
      { field: 'tipoServico', header: 'TIPO SERVIÇO' },
      { field: 'vlrBruto', header: 'VLR BRUTO' },
      { field: 'vlrBaseRet', header: 'VLR BASE RET' },
      { field: 'vlrRetencao', header: 'VLR RETENÇÃO' }
    ];

    // change session event;
    this.sessionSubscription = this.sessionService.initSubscribe(
      this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.changeSession();
        }
      }
    );
  }

  ngOnDestroy() {
    this.dblinkedSessionService.hidePeriods();
    this.toolbarService.clear();
  }

  private changeSession() {
    if (this.dblinkedSessionService.hasEmpresaSelected) {
      this.getR2010Nfs();
    }
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  private getR2010Nfs() {
    const map = { ids: this.idsSelecionados };
    this.httpService.wait();
    this.httpService
      .post('/custom/reinf/get-evt-r2010-nfs', map)
      .subscribe(
        result => (this.lista = result),
        error => this.httpService.handleError(error, () => this.getR2010Nfs()),
        () => this.httpService.done()
      );
  }
}
// console.log('\n--> ' + JSON.stringify(rst, null, '').replace(/"/gim, ''));
