import { Column } from "./Column";
import { DynamicTableConfiguration } from "./DynamicTableConfiguration";

export class DynamicTable {
  // Atributos
  name: string;
  content: Array<any>;
  saveRoute: string;
  buttons: Array<any> = [];
  columns: Array<Column>
  index: number
  key: string
  ObjectState: Array<Object> = undefined;
  selectedItems: Array<any>;
  form: boolean
  lazy: boolean;
  totalRecords: number;
  selectable: boolean;
  deletable: boolean;
  closable: boolean;

  constructor(
    configuration: DynamicTableConfiguration
  ) {
    const { name,
      content,
      columns,
      buttons,
      lazy,
      totalRecords,
      form,
      selectable,
      deletable,
      closable
    } = configuration

    this.name = name
    this.columns = columns
    this.createObjectState(content)
    this.content = content
    this.buttons = buttons
    this.form = form
    this.lazy = lazy
    this.selectable = selectable
    this.closable = closable
    this.deletable = deletable
    if (!this.lazy) {
      this.totalRecords = this.content.length
    } else {
      this.totalRecords = totalRecords
    }
  }

  // Apaga todo o conteúdo, utilize caso queira zerar a tabela.
  clearContent() { this.content = null }

  // Função auxiliar para alterar o estado de content, caso esteja utilizando lazyload, informe o totalRecords para que o número de paginas seja atualizado
  updateContent(content: Array<any>, totalRecords?: number) {
    this.content = content
    if (totalRecords) this.totalRecords = totalRecords
  }

  // Definir novas colunas
  setColumns(columns: Array<Column>): void { this.columns = columns }

  // Utilize para informar que o estado da linha/coluna que estava editando foi modificado
  updateState(status: boolean): void { this.ObjectState[this.index][this.key] = status }

  // Retorna um boolean dizendo se ainda existem elementos na tabela ou não.
  hasAnyElement(): boolean { return this.content.length > 0 ? true : false }

  // Cria um clone do conteúdo enviado para o componente, isto é, a mesma quantidade de objetos, as mesmas keys, mas o valor de todas keys são booleanos, este objeto é utilizado pelo componente
  // Para gerenciamento de estado, se o conteúdo já foi configurado ou não
  createObjectState(content: Array<any>): void {
    if (!this.ObjectState) {
      this.ObjectState = [];

      content.forEach(item => {
        const clone = {};
        const keys = Object.keys(item);

        keys.forEach(key => {
          let isEmptyArray = null;
          if (Array.isArray(item[key])) isEmptyArray = item[key].length < 1 ? true : false;

          clone[key] = item[key] !== null && item[key] !== undefined && !isEmptyArray ? true : false;
        })

        this.ObjectState.push(clone);
      })

      let i = 0;
      for (let item of this.ObjectState) {
        item['index'] = i;
        item['label'] = undefined;
        i++;
      }
    }
  }

  // Utilize quando a coluna utiliza um modal ou qualquer componente que foi feito no componente chamador,
  // basta passar o novo valor, o componente já tem uma lógica para entender qual linha e coluna você estava trabalhando
  updateValue(value: any, label?: string, otherKey?: string): MessageReport {

    const key = otherKey ? otherKey : this.key;

    if (label) label = label.split(' ', 2).join(' ')

    if (Array.isArray(this.content[this.index][key])) {
      this.content[this.index][key].push(value);

      if (label) {
        this.ObjectState[this.index][key] = label;
      } else {
        this.ObjectState[this.index][key] = true;
      }
    } else {
      this.content[this.index][key] = value
      if (label) {
        this.ObjectState[this.index][key] = label;
      } else {
        this.ObjectState[this.index][key] = true;
      }
    }

    return { severity: 'success', message: 'Valor aplicado com sucesso.' }
  }

  removeItens(index: Array<number>) {
    this.content = this.content.filter(item => {
      if (!index.includes(item.index)) return true
    })
  }

  // Verifica a coluna atual, e aplica o valor passado em todas as linhas, alteração em lote
  applyValueInAllRows(value: any, label?: string, otherKey?: string): MessageReport {

    const key = otherKey ? otherKey : this.key;

    if (label) label = label.split(' ', 2).join(' ');

    if (this.selectedItems.length < 1) return { severity: 'error', message: 'É necessário selecionar pelo menos uma linha para aplicar os valores' };

    const selectedIndex = this.selectedItems.map(item => item.index);

    this.content.forEach(item => { if (selectedIndex.includes(item.index)) item[key] = JSON.parse(JSON.stringify(value)) });

    if (value) {
      this.ObjectState.forEach(item => {
        if (selectedIndex.includes(item['index'])) {
          if (label) {
            item[key] = label;
          } else {
            item[key] = true;
          }
        }
      })
    } else {
      this.ObjectState.forEach(item => { if (selectedIndex.includes(item['index'])) item[key] = false });
    }

    return { severity: 'success', message: 'Valor aplicado em todas as linhas selecionadas com sucesso.' };
  }
}

// Interfaces que precisam ser respeitadas ao trabalhar com este componente
type MessageReport = { severity: string, message: string }
