import { Component, OnInit, OnDestroy, ViewChild, ElementRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../app.config';
import { Period } from '../../shared/components/period-select/period.model';
import { Subscription } from 'rxjs/Subscription';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { TimezonePipe } from '../../shared/pipes/timezone.pipe';
import { AppComponent } from '../../app.component';
import { ConfigService } from 'app/shared/services/config.service';
import { CrudService } from 'app/shared/services/crud.service';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Recorrencia } from 'app/util/model/recorrencia.model';
import { TipoDocumento } from 'app/util/model/tipo-documento.model';
import { TipoArquivo } from 'app/util/model/tipo-arquivo.model';
import { Justificativas } from 'app/util/model/justificativas.model';
import { CodigosObrigacoes } from 'app/util/model/codigos-obrigacoes.model';
import { Departamento } from 'app/util/model/departamento.model';
import { Obrigacao } from 'app/centralweb/model/obrigacao.model';
import { Envio } from 'app/centralweb/model/envio.model';
import { GatilhoObrigacao } from 'app/centralweb/model/gatilho-obrigacao.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Usuario } from 'app/cadastro/model/usuario.model';
import { EnvioDestinatarios } from 'app/centralweb/model/envio-destinatarios.model';
import { Relatorios } from 'app/centralweb/model/relatorios.model';
import { RelatorioAgendado } from 'app/centralweb/model/relatorio-agendado.model';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractValueAccessor } from 'app/shared/common/abstract-value-acessor';

@Component({
  selector: 'app-relatorio-agenda-edit',
  templateUrl: './relatorio-agenda-edit.component.html',
  styleUrls: ['./relatorio-agenda-edit.component.scss'],
  providers: [CrudService]
})
export class RelatorioAgendaEditComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  empresas: Array<Empresa> = new Array();
  relatorios: Array<any> = new Array();
  exbirModalObrigacao: boolean;
  obrigacaoEditar: RelatorioAgendado;
  dom: boolean;
  seg: boolean;
  ter: boolean;
  qua: boolean;
  qui: boolean;
  sex: boolean;
  sab: boolean;
  antecipaDiaNaoUtil: boolean;
  diaUtil: boolean;
  diaUtilSabado: boolean;
  dtIni: Date;
  qtdeOcorrencia: number;
  aCada: number;
  diaMes: number;
  dtFin: Date;
  qtdes: any[];
  dias: any[];
  recorrencias: Array<Recorrencia>;
  recorrenciasEditar: Array<Recorrencia>;
  msgs: Message[];
  title: string;
  subtitle: string;
  isCancelarDisabled: boolean;
  isUnicoDisabled: boolean;
  isRecorrenciaDisabled: boolean;
  public selectedIndex: number = 0;

  constructor(private configService: ConfigService,
    private titleService: TitleService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private appComponent: AppComponent,
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.title = 'RELATÓRIOS AGENDADOS';
    this.subtitle = 'RELAÇÃO DOS RELATÓRIOS AGENDADOS PELO USUÁRIO';
    Promise.resolve(null).then(() => this.toolbarService.hidden = true);
    // this.toolbarRefresh();
    // this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.empresas = new Array<Empresa>();
    this.exbirModalObrigacao = false;
    this.obrigacaoEditar = new RelatorioAgendado();
    this.obrigacaoEditar.recorrencia = new Recorrencia();

    this.qtdes = [];
    this.dias = [];
    this.dtIni = new Date();
    this.dtFin = new Date();
    this.qtdeOcorrencia = 0;
    this.aCada = 1;
    this.diaMes = 1;

    this.dom = false;
    this.seg = false;
    this.ter = false;
    this.qua = false;
    this.qui = false;
    this.sex = false;
    this.sab = false;
    this.antecipaDiaNaoUtil = false;
    this.diaUtil = false;
    this.diaUtilSabado = false;
    this.recorrenciasEditar = new Array();
    this.recorrenciasEditar = [];

    this.isCancelarDisabled = false;
    this.isUnicoDisabled = false;
    this.isRecorrenciaDisabled = false;


    this.dias.push({ label: 1, value: 1 });
    this.dias.push({ label: 2, value: 2 });
    this.dias.push({ label: 3, value: 3 });
    this.dias.push({ label: 4, value: 4 });
    this.dias.push({ label: 5, value: 5 });
    this.dias.push({ label: 6, value: 6 });
    this.dias.push({ label: 7, value: 7 });
    this.dias.push({ label: 8, value: 8 });
    this.dias.push({ label: 9, value: 9 });
    this.dias.push({ label: 10, value: 10 });
    this.dias.push({ label: 11, value: 11 });
    this.dias.push({ label: 12, value: 12 });
    this.dias.push({ label: 13, value: 13 });
    this.dias.push({ label: 14, value: 14 });
    this.dias.push({ label: 15, value: 15 });
    this.dias.push({ label: 16, value: 16 });
    this.dias.push({ label: 17, value: 17 });
    this.dias.push({ label: 18, value: 18 });
    this.dias.push({ label: 19, value: 19 });
    this.dias.push({ label: 20, value: 20 });
    this.dias.push({ label: 21, value: 21 });
    this.dias.push({ label: 22, value: 22 });
    this.dias.push({ label: 23, value: 23 });
    this.dias.push({ label: 24, value: 24 });
    this.dias.push({ label: 25, value: 25 });
    this.dias.push({ label: 26, value: 26 });
    this.dias.push({ label: 27, value: 27 });
    this.dias.push({ label: 28, value: 28 });
    this.dias.push({ label: 29, value: 29 });
    this.dias.push({ label: 30, value: 30 });
    this.dias.push({ label: 31, value: 31 });

    this.qtdes.push({ label: 1, value: 1 });
    this.qtdes.push({ label: 2, value: 2 });
    this.qtdes.push({ label: 3, value: 3 });
    this.qtdes.push({ label: 4, value: 4 });
    this.qtdes.push({ label: 5, value: 5 });
    this.qtdes.push({ label: 6, value: 6 });
    this.qtdes.push({ label: 7, value: 7 });
    this.qtdes.push({ label: 8, value: 8 });
    this.qtdes.push({ label: 9, value: 9 });
    this.qtdes.push({ label: 10, value: 10 });

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.empresaChanged(); });
  }

  empresaChanged(): void {
    if (this.dblinkedSessionService.usuario.id) {

      this.httpService.wait();
      this.httpService.get('/custom/centralweb/get-rel-agendado(' + this.dblinkedSessionService.usuario.id + ')')
        .subscribe(rel => this.loadRelatorios(rel),
          error => this.httpService.handleError(error, () => this.empresaChanged()),
          () => this.httpService.done());

    }
  }

  loadRecorrencia(value: Array<Recorrencia>) {
    this.recorrencias = new Array();
    this.recorrenciasEditar = new Array();
    value.forEach(element => {

      this.recorrencias.push(element);
      if (element.id !== 1) {
        this.recorrenciasEditar.push(element);
      }

    });
  }


  atualizarObrigacao() {

    const listasEnviar = {
      vencimento: this.dtIni,
      id: this.obrigacaoEditar.id,
      destinatarios: this.obrigacaoEditar.destinatarios

    };
    this.httpService.wait();
    this.httpService.post('/custom/centralweb/atualizar-relatorio-agendado-unico', listasEnviar)
      .subscribe(result => {

        if (result.error) {
          this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Erro:', detail: result.message });
        } else {
          this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso:', detail: result.message });
        }

        this.ngOnInit();
      },
        error => this.httpService.handleError(error, () => this.atualizarObrigacao()),
        () => this.httpService.done());


  }

  deletarObrigacao(id: number) {
    if (confirm('Deseja realmente remover este agendamento?')) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/deletarrelatorioagendado(' + id + ')')
        .subscribe(result => {

          if (result.error) {
            this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Erro:', detail: result.message });
          } else {
            this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso:', detail: result.message });
          }

          this.ngOnInit();
        },
          error => this.httpService.handleError(error, () => this.deletarObrigacao(id)),
          () => this.httpService.done());
    }
  }

  deletarObrigacoes(id: number) {
    if (confirm('Deseja realmente remover este agendamento e todos os seguintes?')) {
      this.httpService.wait();
      this.httpService.post('/custom/centralweb/deletarrelatoriosagendados(' + id + ')')
        .subscribe(result => {


          result.forEach(element => {
            if (element.error) {
              this.messageService.add({ severity: 'error',key: 'messageLinnks', summary: 'Aviso:', detail: element.message });
            } else {
              this.messageService.add({ severity: 'success',key: 'messageLinnks', summary: 'Sucesso:', detail: element.message });
            }
          });

          this.ngOnInit();
        },
          error => this.httpService.handleError(error, () => this.deletarObrigacoes(id)),
          () => this.httpService.done());
    }
  }

  atualizarRecorrencia() {

    if (confirm('Todas as recorrências seguintes deste agendamento serão alteradas, deseja continuar?')) {

      const listasEnviar = {
        obrigacao: this.obrigacaoEditar,
        recorrencia: this.obrigacaoEditar.recorrencia.id,
        relatorio: this.obrigacaoEditar.relatorio.id,
        usuario: this.dblinkedSessionService.usuario.id,
        dtIni: this.dtIni,
        dtFin: this.dtFin,
        aCada: this.aCada,
        dom: this.dom,
        seg: this.seg,
        ter: this.ter,
        qua: this.qua,
        qui: this.qui,
        sex: this.sex,
        sab: this.sab,
        qtdeOcorrencia: this.qtdeOcorrencia,
        diaMes: this.diaMes,
        antecipaDiaNaoUtil: this.antecipaDiaNaoUtil,
        diaUtil: this.diaUtil,
        diaUtilSabado: this.diaUtilSabado,
        empresa: this.dblinkedSessionService.empresa.id,
        agendamentoId: this.obrigacaoEditar.id,
        destinatarios: this.obrigacaoEditar.destinatarios
      };

      this.httpService.wait();
      this.httpService.post('/custom/centralweb/atualizar-recorrencias-agendamento', listasEnviar)
        .subscribe(result => this.ngOnInit(),
          error => this.httpService.handleError(error, () => this.atualizarRecorrencia()),
          () => this.httpService.done());

    }


  }

  loadObrigEditar(value: RelatorioAgendado) {
    this.obrigacaoEditar = value[0];
    this.exbirModalObrigacao = true;
  }
  onRowSelect(event: any) {

    this.isUnicoDisabled = true;
    this.isRecorrenciaDisabled = false;
    this.isCancelarDisabled = true;

    this.httpService.wait();
    this.httpService.get('/relatorioagendado?$select=id,recorrencia,dtEnvio,destinatarios,relatorio/id' +
      '&$filter=id eq ' + event.data.id +
      '&$orderby=dtEnvio')
      .subscribe(ref => this.loadObrigEditar(ref.value),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/recorrencia?$select=id,cod,descricao&$orderby=id')
      .subscribe(ref => this.loadRecorrencia(ref.value),
        error => this.httpService.handleError(error, () => this.ngOnInit()),
        () => this.httpService.done());
  }



  hideMessage() {

  }


  loadRelatorios(value: Array<any>) {

    this.relatorios = value;

  }

  ngOnDestroy() {
    this.toolbarService.hidden = false;
    // this.toolbarService.clear();

    this.dblinkedSessionService.hidePeriods();

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  /*private toolbarRefresh() {
  this.toolbarService.clear();
   this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
   this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
   this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
   this.toolbarService.add('edit', true, 'class-edit-button', 'edit', 'Editar!');
   this.toolbarService.add('add', true, 'class-new-button', 'NOVO', 'Adicionar');
 }*/


  onTabChanged(event: any) {
    console.log(event);
    console.log(event.index);
    if (event.index === 0) {
      this.isUnicoDisabled = true;
      this.isRecorrenciaDisabled = false;
      this.isCancelarDisabled = true;
    }

    if (event.index === 1) {
      this.isUnicoDisabled = false;
      this.isRecorrenciaDisabled = true;
      this.isCancelarDisabled = true;

    }

    if (event.index === 2) {
      this.isUnicoDisabled = false;
      this.isRecorrenciaDisabled = false;
      this.isCancelarDisabled = true;

    }

  }
}
