import { FormularioVisualizacaoRespostasCamposComponent } from 'app/eventos/formulario-visualizacao-respostas/formulario-visualizacao-respostas.component';
import { Idioma } from './../../util/model/idioma.model';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Empresa } from 'app/cadastro/model/empresa.model';
import { Subscription } from 'rxjs/Subscription';
import { TitleService } from 'app/shared/services/title.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { ConfigService } from 'app/shared/services/config.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { ToolbarButton } from 'app/shared/model/toolbar-button.model';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { isNullOrUndefined } from 'util';
import { promise, element } from 'protractor';
import { OperacaoRegraGrupo } from '../model/operacao-regra-grupo.model';
import { Toolbar } from 'app/shared/model/toolbar.model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-grupo-operacao',
  templateUrl: './grupo-operacao.component.html',
  styleUrls: ['./grupo-operacao.component.scss'],
})

export class GrupoOperacaoComponent implements OnInit, OnDestroy {


  private sessionSubscription: Subscription;
  pt: any;
  defaultDate: Date;
  titlePrincipal: string;
  subtitle: string;
  msgs: Message[];
  msgsModal: Message[];
  private checkAllBtn: boolean;
  boolCriarGrupoOperacao : boolean = false;
  boolEditarGrupoOperacao : boolean = false;
  checkedAll : boolean = false;
  listaIdsSelecionados : Array<any>;
  listaGruposOperacao : Array<any> ;
  operacaoRegraGrupo : OperacaoRegraGrupo;
  itemTipoId : number;
  OpcoesItemTipo : SelectItem[];
  colsGrupoOperacao : any[];

    constructor(
    private titleService: TitleService,
    private configService: ConfigService,
    private toolbarService: ToolbarService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private messageService: MessageService) {
  }

  @ViewChild('dt1') dt1: Table
  exportMov() {
    this.dt1.exportCSV()
  }

  public toolbarGrupoOp = new Toolbar()

  toolbarMainGrupoOp = [
    { icon: 'replay', text: 'Atualizar', tooltip: 'Atualizar', visible: true, disabled: false, color: 'default', onClick: () => this.change() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar Excel', visible: true, disabled: false, color: 'default', onClick: () => this.dt1.exportCSV() },
    { icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.excluirGrupoOperacao() },
    { icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.carregarGrupoOperacao() },
    { icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.showModalGrupoOperacao() },
  ]

  ngOnInit(): void {
    this.toolbarGrupoOp.setAll(this.toolbarMainGrupoOp)
    this.toolbarService.hidden = true
    this.listaIdsSelecionados = new Array<any>();
    this.boolCriarGrupoOperacao = false;
    this.boolEditarGrupoOperacao = false;
    this.checkedAll = false;
    this.titlePrincipal = 'Grupo Operação';
    this.subtitle = '';
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.carregarItensTipo()


    this.operacaoRegraGrupo = new OperacaoRegraGrupo();

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged, () => {
      this.change();

    });

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar'
    };

    this.colsGrupoOperacao = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'descricao', header: 'DESCRIÇÃO' },
      { field: 'codigoItemTipo'+'descricaoItemTipo', header: 'TIPO ITEM' }
  ];

  }

  ngOnDestroy() {
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  change() {
    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year && this.sessionService.loggedUser.id) {

        this.listaIdsSelecionados = new Array<any>();
        this.boolCriarGrupoOperacao = false;
        this.boolEditarGrupoOperacao = false;
        this.checkedAll = false;
        this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
        this.operacaoRegraGrupo = new OperacaoRegraGrupo();

        this.operacaoRegraGrupo.codigo = null;
        this.operacaoRegraGrupo.descricao = null;
        this.operacaoRegraGrupo.vinculaItemTipo = false;
        this.itemTipoId = null;

        this.carregaGruposOperacao();
        this.carregarItensTipo();
        this.boolCriarGrupoOperacao = false;
        this.boolEditarGrupoOperacao  = false;


        this.toolbarInitial()
        }
    }

    toolbarInitial(){
      this.toolbarGrupoOp.setVisible('btn1', true)
      this.toolbarGrupoOp.setVisible('btn2', true)
      this.toolbarGrupoOp.setVisible('btn3', false)
      this.toolbarGrupoOp.setVisible('btn4', false)
      this.toolbarGrupoOp.setVisible('btn5', true)
    }

  carregarItensTipo(){
    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-itens-tipo')
      .subscribe(ref => {
        this.OpcoesItemTipo = [];
        this.OpcoesItemTipo.push({ label: 'Selecione', value: 0 });
        ref.forEach(element => {
          this.OpcoesItemTipo.push( {label: element.codigo + ' - ' + element.descricao, value: element.id });
        });
      },
        error => this.httpService.handleError(error, () => this.carregarItensTipo()),
        () => this.httpService.done());
  }
  carregaGruposOperacao(){
      this.httpService.wait();
      this.httpService.get('/custom/cadastro/get-grupos-operacao(' + this.dblinkedSessionService.empresa.id + ')')
        .subscribe(ref => {
          this.listaGruposOperacao = new Array();
          ref.forEach(element => {
            this.listaGruposOperacao.push(element);
          });
        },
          error => this.httpService.handleError(error, () => this.change()),
          () => this.httpService.done());
    }


  get empresa(): Empresa {
    return this.dblinkedSessionService.empresa;
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  get rows() {
    return this.configService.applicationConfig.rows;
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  hideMessage() {


  }

  private toolbarEnable() {
    const todos = this.buttons.slice();

    this.toolbarService.alterarStatus('atualizar', false, 'Atualizar', () => this.change());
    this.toolbarService.alterarStatus('add', false, 'Novo', () => this.showModalGrupoOperacao());

    this.toolbarService.ocultar('voltar');
    this.toolbarService.ocultar('download');
    this.toolbarService.ocultar('cancel');
    this.toolbarService.ocultar('check');
    this.toolbarService.ocultar('info');
    this.toolbarService.ocultar('msg');
    this.toolbarService.ocultar('more_vert');
    this.toolbarService.ocultar('export');
    this.toolbarService.ocultar('print');
    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('pesquisa');
  }


  criarGrupoOperacao(){

    if (this.validacao()) {
      return;
    }

    let listaEnviar = {
      codigo    : this.operacaoRegraGrupo.codigo,
      descricao : this.operacaoRegraGrupo.descricao,
      vinculaItemTipo : this.operacaoRegraGrupo.vinculaItemTipo = true,
      itemTipoId : this.itemTipoId,
      empresaId : this.empresa.id,
      usuario : this.sessionService.loggedUser.id
    }

   
    this.httpService.post('/custom/cadastro/criar-grupo-operacao', listaEnviar)
      .subscribe(rst => {

        if (rst.error === true) {
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst.detail
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst.detail
          });
        }
        this.change();
      },
        error => this.httpService.handleError(error, () => this.change()),
      );
  }


  changetoolbarEdit(){
    if(this.listaIdsSelecionados.length > 0 && this.listaIdsSelecionados.length < 2){
      this.toolbarGrupoOp.setVisible('btn3', true)
      this.toolbarGrupoOp.setVisible('btn4', true)
    }
    else{
      this.toolbarGrupoOp.setVisible('btn3', false)
      this.toolbarGrupoOp.setVisible('btn4', false)
    } 
  }
  changeToolbarRemove(){
    if(this.listaIdsSelecionados.length > 1){
      this.toolbarGrupoOp.setVisible('btn3', true)
      this.toolbarGrupoOp.setVisible('btn4', false)
    }
    if(this.listaIdsSelecionados.length === 0){
      this.toolbarGrupoOp.setVisible('btn3', false)
      this.toolbarGrupoOp.setVisible('btn4', false)
    }
    if(this.listaIdsSelecionados.length === 1){
      this.toolbarGrupoOp.setVisible('btn3', true)
      this.toolbarGrupoOp.setVisible('btn4', true)
     }
  }

  checkAll(event) {
     this.checkAllBtn = !this.checkAllBtn;

     this.listaIdsSelecionados = new Array();

    if (this.checkAllBtn) {
      this.listaGruposOperacao.forEach(rst => {
        rst.selected = false;
        this.listaIdsSelecionados.push(rst.id);
        this.toolbarGrupoOp.setVisible('btn3', true)
      });
    } else {
      this.listaGruposOperacao.forEach(rst => {
        rst.selected = true;
        this.toolbarGrupoOp.setVisible('btn3', false)
      });
    }
    console.log(this.listaIdsSelecionados)
  }

  selectCheckbox(event: any) {
    this.listaIdsSelecionados.push(event.data.id);
    this.changetoolbarEdit()
    this.changeToolbarRemove()
    !this.checkAllBtn
  }

  unselectCheckbox(event: any){
    const index = this.listaGruposOperacao.findIndex(i => i === event.data.id);
    this.listaIdsSelecionados.splice(index, 1);
    this.changetoolbarEdit()
    this.changeToolbarRemove()
    
  }


  checkToolBar(){

    if (isNullOrUndefined(this.listaIdsSelecionados)) {
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirGrupoOperacao());
      this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.editarGrupoOperacao());
      this.toolbarService.alterarStatus('add', false, 'NOVO',  () => this.showModalGrupoOperacao() );
    } else {

      if (this.listaIdsSelecionados.length === 0) {
        this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.carregarGrupoOperacao());
        this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.excluirGrupoOperacao());
        this.toolbarService.alterarStatus('add', false, 'NOVO',  () => this.showModalGrupoOperacao() );
      } else if (this.listaIdsSelecionados.length === 1) {
        this.toolbarService.alterarStatus('edit', false, 'Editar', () => this.carregarGrupoOperacao());
        this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.excluirGrupoOperacao());
        this.toolbarService.alterarStatus('add', true, 'NOVO',  () => this.showModalGrupoOperacao() );
      } else if(this.listaIdsSelecionados.length > 1){
        this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.carregarGrupoOperacao());
        this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.excluirGrupoOperacao());
        this.toolbarService.alterarStatus('add', true, 'NOVO',  () => this.showModalGrupoOperacao() );
      }
    }
  }


  excluirGrupoOperacao(){
    if (confirm('Deseja excluir o(s) registro(s) selecionado(s)?')) {
      
      const listaEnviar = {
        grupoOperacaoId : this.listaIdsSelecionados
      }

    
      this.httpService.post('/custom/cadastro/excluir-grupo-operacao', listaEnviar)
        .subscribe(rst => {

          if (rst.error) {
            this.messageService.add({
              severity: 'error',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.detail
            });
            this.toolbarInitial()

          } else {
            this.messageService.add({
              severity: 'success',key: 'messageLinnks',
              summary: 'MSG: ',
              detail: rst.detail
            });
            this.toolbarInitial()
            this.change()
          }
        },
          error => this.httpService.handleError(error, () => this.excluirGrupoOperacao()),
           );
    }
  }


  carregarGrupoOperacao(){

    this.boolCriarGrupoOperacao = false;
    this.boolEditarGrupoOperacao  = true;

    this.carregarItensTipo()

    this.httpService.wait();
    this.httpService.get('/custom/cadastro/get-grupo-operacao-selecionado-pelo-id(' + this.listaIdsSelecionados + ')')
      .subscribe(ref => {

       
          this.operacaoRegraGrupo.id = ref.id;
          this.operacaoRegraGrupo.codigo = ref.codigo;
          this.operacaoRegraGrupo.descricao = ref.descricao;
          this.operacaoRegraGrupo.vinculaItemTipo = ref.vinculaItemTipo;
          this.itemTipoId = ref.itemTipoId;
          this.empresa.id = ref.empresaId;
       
          this.operacaoRegraGrupo.id = ref.id;
          this.operacaoRegraGrupo.codigo = ref.codigo;
          this.operacaoRegraGrupo.descricao = ref.descricao;
          this.operacaoRegraGrupo.vinculaItemTipo = ref.vinculaItemTipo;
          this.empresa.id = ref.empresaId;
        }


      ,
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }


  editarGrupoOperacao(){

    if (this.validacao()) {
      return;
    }

    let listaEnviar = {
      grupoOperacaoId : this.operacaoRegraGrupo.id,
      codigo    : this.operacaoRegraGrupo.codigo,
      descricao : this.operacaoRegraGrupo.descricao,
      vinculaItemTipo : this.operacaoRegraGrupo.vinculaItemTipo = true,
      itemTipoId : this.itemTipoId,
      empresaId : this.empresa.id,
      usuario : this.sessionService.loggedUser.id
    }

    this.httpService.wait();
    this.httpService.post('/custom/cadastro/editar-grupo-operacao', listaEnviar)
      .subscribe(rst => {

        if (rst.error === true) {
          this.messageService.add({
            severity: 'error',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst.detail
          });
        } else {
          this.messageService.add({
            severity: 'success',key: 'messageLinnks',
            summary: 'MSG: ',
            detail: rst.detail
          });
        }
        this.change();
      },
        error => this.httpService.handleError(error, () => this.change()),
        () => this.httpService.done());
  }


    cancelar(){
      this.change();
    }

    validacao(){

      if(isNullOrUndefined(this.operacaoRegraGrupo.codigo)){
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor inserir o código!'
        });
        return true;
      }

      if (isNullOrUndefined(this.operacaoRegraGrupo.descricao)) {
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor inserir uma descrição!'
        });
        return true;
      }

      if(this.operacaoRegraGrupo.vinculaItemTipo === true && (isNullOrUndefined(this.itemTipoId) || this.itemTipoId === 0)){
        this.messageService.add({
          severity: 'warn',key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Favor selecionar o Item tipo!'
        });
        return true;
      }

      return false;
    }

    showModalGrupoOperacao(){
       this.boolCriarGrupoOperacao = true;
       this.operacaoRegraGrupo = new OperacaoRegraGrupo();
    }

  }




