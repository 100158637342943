import { Empresa } from 'app/cadastro/model/empresa.model';
import { MunicipioIbge } from 'app/util/model/municipio-ibge.model';
import { Uf } from 'app/util/model/uf.model';
import { AuthUser } from 'app/proof/model/auth-user.model';
import { Pais } from 'app/util/model/pais.model';
import { ParticipanteTipo } from 'app/cadastro/model/participante-tipo.model';
import { ParticipanteEmpresa } from 'app/cadastro/model/participante-empresa.model';
import { ItemParticipante } from 'app/cadastro/model/item-participante.model';
import { ParticipanteEndereco } from 'app/cadastro/model/participante-endereco.model';
import { ParticipanteContato } from 'app/cadastro/model/participante-contato.model';
import { RegimeTributario } from 'app/util/model/regime-tributario.model';
import { ParticipanteDadoBancario } from './participante-dado-bancario.model';

export class Participante {
  id: number;
  cnpj: string;
  cpf: string;
  nome: string;
  fantasia: string;
  ie: string;
  im: string;
  cnae: string;
  suframa: string;
  dtSuframa: Date;
  dtInicial: Date;
  dtFinal: Date;
  participanteAnterior: Participante;
  regimeTributario: RegimeTributario;
  participanteTipos: Array<ParticipanteTipo>;
  participanteEmpresas: Array<ParticipanteEmpresa>;
  itemParticipanteCollection: Array<ItemParticipante>;

  participanteEnderecos: Array<ParticipanteEndereco>;
  participanteContatos: Array<ParticipanteContato>;
  participanteDadosBancarios: Array<ParticipanteDadoBancario>;
  createTimestamp: Date;
  changeUser: AuthUser;
  changeTimestamp: Date;

  cnpjPiped: string;
  cpfPiped: string;
  codigo: string;

  dtNascimento: Date;
  rg: string;
  genero: boolean;
  formaConhecimento: boolean;
  observacoes: string;
  profissao: boolean;
  estadoCivil:string;
  participanteEmpresaId: number

  constructor(participante?: Participante | number) {
    if (participante !== undefined && participante !== null) {
      if (typeof participante === 'number') {
        this.id = participante;
      } else {
        this.id = participante.id;
        this.cnpj = participante.cnpj;
        this.cpf = participante.cpf;
        this.nome = participante.nome;
        this.fantasia = participante.fantasia;
        this.ie = participante.ie;
        this.im = participante.im;
        this.suframa = participante.suframa;
        this.dtInicial = participante.dtInicial;
        this.dtFinal = participante.dtFinal;
        this.participanteAnterior = participante.participanteAnterior;
        this.regimeTributario = participante.regimeTributario;
        this.participanteTipos = participante.participanteTipos;
        this.participanteEmpresas = participante.participanteEmpresas;
        this.itemParticipanteCollection = participante.itemParticipanteCollection;
        this.participanteEnderecos = participante.participanteEnderecos;
        this.participanteContatos = participante.participanteContatos;
        this.participanteDadosBancarios = participante.participanteDadosBancarios;
        this.createTimestamp = participante.createTimestamp;
        this.changeUser = participante.changeUser;
        this.changeTimestamp = participante.changeTimestamp;
        this.codigo = participante.codigo;
        this.dtNascimento = participante.dtNascimento;
        this.rg = participante.rg;
        this.genero = participante.genero;
        this.profissao = participante.profissao;
        this.formaConhecimento = participante.formaConhecimento;
        this.estadoCivil = participante.estadoCivil;
        this.participanteEmpresaId = participante.participanteEmpresaId
      }
    }
  }
}
