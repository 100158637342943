import { MovSaldosContabeis } from 'app/contabil/model/mov-saldos-contabeis.model';
import { CadEcd } from 'app/contabil/model/cad-ecd.model';
import { Usuario } from 'app/cadastro/model/usuario.model';

export class FormalizacaoConciliacao {
  id: number;
  movSaldosContabeis: MovSaldosContabeis;
  vlFinanceiro: number;
  formalizado: boolean;
  homologado: boolean;
  usuarioFormalizado: Usuario;
  usuarioHomologado: Usuario;
  selected = false;

  constructor(formalizacaoconciliacao?: FormalizacaoConciliacao | number) {
    if (formalizacaoconciliacao !== undefined && formalizacaoconciliacao !== null) {
      if (typeof formalizacaoconciliacao === 'number') {
        this.id = formalizacaoconciliacao;
      } else {
        this.id = formalizacaoconciliacao.id;
        this.movSaldosContabeis = formalizacaoconciliacao.movSaldosContabeis;
        this.vlFinanceiro = formalizacaoconciliacao.vlFinanceiro;
        this.formalizado = formalizacaoconciliacao.formalizado;
        this.homologado = formalizacaoconciliacao.homologado;
        this.usuarioFormalizado = formalizacaoconciliacao.usuarioFormalizado;
        this.usuarioHomologado = formalizacaoconciliacao.usuarioHomologado;
        this.selected = formalizacaoconciliacao.selected || false;
      }
    }
  }
}
