import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ConfigService } from './config.service';

@Injectable()
export class TitleService {
  private _concat = ' - ';

  constructor(private _title: Title,
              private configService: ConfigService) {
  }

  set concat(concat: string) {
    this._concat = concat;
  }
  get concat(): string {
    return this._concat;
  }

  get applicationTitle(): string {
    return this.configService.applicationTitle;
  }

  get windowTitle(): string {
    return this._title.getTitle();
  }

  get title(): string {
    const __title = this._title.getTitle();

    if (__title.length > this.applicationTitle.length && __title.indexOf(this.applicationTitle) === 0) {
      return __title.substr(this.applicationTitle.length + this.concat.length);
    }

    return __title;
  }
  set title(title: string) {
    this._title.setTitle(this.applicationTitle + this.concat + title);
  }
}
