import { Selected } from './../../shared/components/data-table/data-table.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { Message, MessageService } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { isNullOrUndefined } from 'util';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Table } from 'primeng/table';
import { Toolbar } from 'app/shared/model/toolbar.model';
declare var $: any;

@Component({
  selector: 'app-cad-nivel',
  templateUrl: './cad-nivel.component.html',
  styleUrls: ['./cad-nivel.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})

export class CadNivelComponent implements OnInit, OnDestroy {
  private sessionSubscription: Subscription;

  msgs: Message[] = null;
  msgsModal: Message[] = null;
  subtitle: string;
  title: string;
  modalNew: boolean = false;
  modalEdit: boolean = false;
  nivelLista: Array<any> = new Array();

  //Variaveis do nivel
  codigoNew: string = null;
  descricaoNew: string = null;
  nivelNew: string = null;
  idEdit: number = null;
  codigoEdit: string = null;
  descricaoEdit: string = null;
  nivelEdit: string = null;

  //export excel
  colsNivelLista: any[];

  constructor(private httpService: HttpService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private toolbarService: ToolbarService,
    private messageService: MessageService) { }

  public toolbarMain = new Toolbar()

  @ViewChild('dt') dt: Table
  exportMov() {
    this.dt.exportCSV()
  }

  toolbarMainIni = [
    { key: 'export', icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.dt.exportCSV() },
    { key: 'edit', icon: 'edit', text: 'Editar', tooltip: 'Editar', visible: false, disabled: false, color: 'default', onClick: () => this.edit() },
    { key: 'delete', icon: 'delete', text: 'Remover', tooltip: 'Remover', visible: false, disabled: false, color: 'default', onClick: () => this.deleteNivel() },
    { key: 'add', icon: 'add', text: 'Novo', tooltip: 'Novo', visible: true, disabled: false, color: 'default', onClick: () => this.openAddCargo() },
    {
      key: 'expand', icon: 'expand_less', text: '', tooltip: 'Ver menos', visible: true, disabled: false, color: 'default', onClick: () => {
        this.ShowHIdeCardBenef()
        this.changeStateBenef()
      }
    },
  ]


  ngOnInit() {
    this.toolbarMain.setAll(this.toolbarMainIni);
    this.toolbarService.hidden = true;
    this.title = 'NÍVEIS';
    this.subtitle = 'Cadastro de níveis';
    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => {
        if (this.dblinkedSessionService.hasEmpresaSelected) {
          this.getNiveis();
          this.toolbarEnable();
          this.toolbarInitial()
        }

      });

    this.colsNivelLista = [
      { field: 'codigo', header: 'CÓDIGO' },
      { field: 'nivel', header: 'NÍVEL' },
      { field: 'descricao', header: 'DESCRIÇÃO' }
    ];
  }

  ngOnDestroy() {
    this.toolbarService.clear();
    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  toolbarInitial(){
    this.toolbarMain.setVisible('export', true)
    this.toolbarMain.setVisible('expand', true)
    this.toolbarMain.setVisible('add', true)
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
  }

  addNivel() {
    if (isNullOrUndefined(this.nivelNew)) {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: "O campo 'Nível' é obrigatório." });
    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.configService.bearerApi,
          'empresaid': this.dblinkedSessionService.empresa.id.toString(),
          'nivel': this.nivelNew.toString(),
          'codigo': this.codigoNew.toString(),
          'descricao': this.descricaoNew.toString(),
          'userid': this.sessionService.loggedUser.id.toString()
        })
      };

      this.httpService.wait();
      this.httpClient.post(this.configService.defaultUrlApiUpload + 'rh/niveis', null, httpOptions)
        .subscribe(
          res => {
            this.exibirMensagem(res);
            this.getNiveis();
            this.httpService.done();
            this.modalNew = false;
            this.toolbarInitial();
          },
          err => {
            this.exibirMensagem(err);
            console.log(err);
            this.httpService.done();
          }
        );
    }
  }

  getNiveis() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'empresaid': this.dblinkedSessionService.empresa.id.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'rh/niveis', httpOptions)
      .subscribe(
        res => {
          let result = res["mensagem"];
          this.nivelLista = new Array();
          for (let line of result) {
            this.nivelLista.push(line);
          }
          this.httpService.done();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  toolbarEnable() {
    this.toolbarService.clear();
    this.toolbarService.add('add', false, 'class-new-button', 'NOVO', 'Novo segmento', () => this.openAddCargo());
  }

  openAddCargo() {
    this.modalNew = true;
    this.codigoNew = null;
    this.descricaoNew = null;
    this.nivelNew = null;
  }

  hideMessage() {


  }

  onRowSelect(row) {
    this.codigoEdit = row.codigo;
    this.descricaoEdit = row.descricao;
    this.nivelEdit = row.nivel;
    this.idEdit = row.id;
    this.toolbarMain.setVisible('edit', true)
    this.toolbarMain.setVisible('delete', true)
    row.selected = false
  }

  onRowUnselect(row) {
    this.toolbarMain.setVisible('edit', false)
    this.toolbarMain.setVisible('delete', false)
    row.selected = true
  }

  edit(){
    this.modalEdit = true
  }

  deleteNivel() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'nivelid': this.idEdit.toString()
      })
    };

    if (confirm('Deseja deletar o registro selecionado? ')) {
    this.httpService.wait();
    this.httpClient.delete(this.configService.defaultUrlApiUpload + 'rh/niveis', httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.modalEdit = false;
          this.getNiveis();
          this.toolbarInitial();
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
    }
  }

  editNivel() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.configService.bearerApi,
        'nivel': this.nivelEdit.toString(),
        'codigo': this.codigoEdit.toString(),
        'descricao': this.descricaoEdit.toString(),
        'userid': this.sessionService.loggedUser.id.toString(),
        'nivelid': this.idEdit.toString()
      })
    };

    this.httpService.wait();
    this.httpClient.put(this.configService.defaultUrlApiUpload + 'rh/niveis', null, httpOptions)
      .subscribe(
        res => {
          this.exibirMensagem(res);
          this.httpService.done();
          this.modalEdit = false;
          this.getNiveis();
          this.toolbarInitial()
        },
        err => {
          this.exibirMensagem(err);
          console.log(err);
          this.httpService.done();
        }
      );
  }

  exibirMensagem(value: any) {
    if (!value.error) {

      this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: value.mensagem });

    } else {

      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro', detail: value.mensagem });
    }
  }

  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  get rows() {
    return 50;
  }

  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }

  /*Show Hide*/
  isCardBenef: boolean = true;
  ShowHIdeCardBenef() {
    this.isCardBenef = !this.isCardBenef;
    this.toolbarMain.setIcon('expand', this.isCardBenef ? 'expand_less' : 'expand_more');
    this.toolbarMain.setTooltip('expand', this.isCardBenef ? 'Ver menos' : 'Ver mais')
  }

  currentStateBenef = 'initial';
  changeStateBenef() {
    this.currentStateBenef = this.currentStateBenef === 'initial' ? 'final' : 'initial';
  }

  fullBenef: boolean = false;
  fullScreenBenef() {
    this.fullBenef = !this.fullBenef;
  }

}
